import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Home.less';
import Header from '../Layouts/Header/Header';
import { homeModuleGridLayout } from '../../utils/property';
import { Layout, Row, Col, Card, Tag } from 'antd';
import { localStorageGet } from '@core/cookie';
import { get } from '@core/request';
const { Content } = Layout;

export default function Home() {
    const navigate = useNavigate();
    const [moduleState, setModuleState] = useState(null);
    useEffect(() => {
        let token;
        token = localStorageGet('token');
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        const arr = localStorage.getItem('infos');
        if (arr) {
            let userName = JSON.parse(JSON.parse(arr).data).phone
            get(`/enterprise/staffs/get-modules`, { userName }).then((res) => {
                if (res.status_code === 200) {
                    setModuleState(res.data)
                }
            })
        }
    }, []);

    return (
        <Layout>
            <Header />

            {/* <Content className="main-content-box">
                <div className="main-content-title">欢迎信息</div>
                <div className="main-content-body welcome">
                    <p>你好 <span className="login-username">WisdomFusion</span>，欢迎回来！</p>
                    <p><span className="label">最近登录</span><span className="content">222.128.9.105  [北京市 联通]  2021-11-09 08:43:18</span></p>
                    <p><span className="label">我的角色</span><span className="content"><Tag>角色1</Tag><Tag>角色2</Tag><Tag>角色3</Tag></span></p>
                </div>
            </Content> */}

            {
                moduleState && <Content className="main-content-box">
                    <div className="main-content-title">业务模块</div>
                    <div className="main-content-body">
                        <Row gutter={[16, 16]}>
                            <Col {...homeModuleGridLayout}>
                                <Link to="/medproject/projects">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">医项目</div>
                                        <p>综合项目管理</p>
                                    </Card>
                                </Link>
                            </Col>

                            <Col {...homeModuleGridLayout}>
                                <Link to="/invitation/groups">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">医邀请</div>
                                        <p>邀请函组件，项目专家邀请</p>
                                    </Card>
                                </Link>
                            </Col>

                            {/* 调研组件模块 */}
                            <Col {...homeModuleGridLayout}>
                                <Link to="/wj/surveys">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">医调研</div>
                                        <p>调研组件，问卷及调研报告</p>
                                    </Card>
                                </Link>
                            </Col>

                            <Col {...homeModuleGridLayout}>
                                <Link to="/conf/conferences">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">医会议</div>
                                        <p>会议管理系统</p>
                                    </Card>
                                </Link>
                            </Col>

                            <Col {...homeModuleGridLayout}>
                                <Link to="/selection/projects">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">医评选</div>
                                        <p>评选项目管理，评分投票管理，案例管理</p>
                                    </Card>
                                </Link>
                            </Col>

                            <Col {...homeModuleGridLayout}>
                                <Link to="/healthclass/CheckOrganizations">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">健学云</div>
                                        <p>组织学习 SaaS 产品运营管理</p>
                                    </Card>
                                </Link>
                            </Col>

                            <Col {...homeModuleGridLayout}>
                                <Link to="/kb/rearch">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">研究型医院</div>
                                        <p>研究型医院评价遴选数据填报管理</p>
                                    </Card>
                                </Link>
                            </Col>

                            <Col {...homeModuleGridLayout}>
                                <Link to="/healthaccount/eha/enterprises">
                                    <Card bordered={false} size="small" className="module cursor-pointer">
                                        <div className="module-title">健康号</div>
                                        <p>健康号，医企+解决方案管理</p>
                                    </Card>
                                </Link>
                            </Col>

                            {moduleState.includes('USER') && <Col {...homeModuleGridLayout}>
                                <Link to="/uc/experts">
                                    <Card bordered={false} size="small" className="module">
                                        <div className="module-title">用户中心</div>
                                        <p>用户分群，用户数据，医疗决策人数据看板</p>
                                    </Card>
                                </Link>
                            </Col>}

                            {moduleState.includes('CHANNEL') && <Col {...homeModuleGridLayout}>
                                <Link to="/operation/channels">
                                    <Card bordered={false} size="small" className="module">
                                        <div className="module-title">运营中心</div>
                                        <p>运营中心，渠道及渠道数据管理</p>
                                    </Card>
                                </Link>
                            </Col>}

                            {(1 || moduleState.includes('FINANCE')) && <Col {...homeModuleGridLayout}>
                                <Link to="/finance/laborfee/projects">
                                    <Card bordered={false} size="small" className="module cursor-pointer" >
                                        <div className="module-title">财务中心</div>
                                        <p>劳务费等管理模块</p>
                                    </Card>
                                </Link>
                            </Col>}

                            {/*<Col {...homeModuleGridLayout}>*/}
                            {/*    <Link to="/finance/laborfee">*/}
                            {/*        <Card bordered={false} size="small" className="module cursor-pointer">*/}
                            {/*            <div className="module-title">劳务模块</div>*/}
                            {/*            <p>专家劳务费管理</p>*/}
                            {/*        </Card>*/}
                            {/*    </Link>*/}
                            {/*</Col>*/}

                            {moduleState.includes('BOSS') && <Col {...homeModuleGridLayout}>
                                <Link to="/enterprise/staffs">
                                    <Card bordered={false} size="small" className="module cursor-pointer" >
                                        <div className="module-title">企业管理</div>
                                        <p>员工、组织架构、权限、角色等管理模块</p>
                                    </Card>
                                </Link>
                            </Col>}

                            {/* 医患通   */}
                            {<Col {...homeModuleGridLayout}>
                                <Link to="/medconnect/projects">
                                    <Card bordered={false} size="small" className="module cursor-pointer" >
                                        <div className="module-title">医患通</div>
                                        <p>医患问答项目</p>
                                    </Card>
                                </Link>
                            </Col>}

                        </Row>
                    </div>
                </Content>
            }



        </Layout>
    );
}
