import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Form,
    Input,
    Layout,
    Space,
    Table,
    Row,
    Col,
    Typography,
    Tooltip
} from 'antd';
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import './OrganizationList.less';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { conferenceOnceApi, sponsorList, delSponsor } from '@services/Conference';
import { conferenceActions } from '../store/conferenceSlice';
import { getAllEnterpriseApi } from '@services/Enterprise';
import AddOrganizationDrawer from './components/AddOrganization';
import { Modal } from 'antd';
import { message } from 'antd';
const { Text } = Typography;
const { Content } = Layout;

const { setGuestFilter: setFilter, setGuestCursor: setCursor } = conferenceActions;

export default function OrganizationList() {
    const params = useParams();

    const conferenceId = params?.id || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const dispatch = useDispatch();
    const filter = useSelector(state => state.conference.guest.filter);
    const cursor = useSelector(state => state.conference.guest.cursor);

    const [form] = Form.useForm();

    const [visibleAddOrgsDrawer, setVisibleAddOrgsDrawer] = useState(false);
    const [enterprisePerson, setEnterprisePerson] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const [currentOrg, setCurrentOrg] = useState(null);

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({id: conferenceId});
        if (res.status_code === 200) {
            setConference(res.data);
        }
    };

    // 企业负责人
    const requestAllEnterprisePerson = async () => {
        const res = await getAllEnterpriseApi({conferenceId, page: 1, pageSize: 9999, role: 3});
        if (res.status_code === 200 && res.data) {
            setEnterprisePerson(res.data.list);
        }
    };

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
            requestAllEnterprisePerson(); // 企业负责人信息
        }
    }, []);

    const showAddOrgsDrawer = (item) => {
        if(item){
            setCurrentOrg(item);
        }else{
            setCurrentOrg(null);
        }
        setVisibleAddOrgsDrawer(true);
    };

    const onFinish = values => {
        dispatch(setFilter({
            ...filter,
            ...values,
            page: 1,
        }));
    };
    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const columns = [
        {
            title: "机构名称",
            dataIndex: "name",
            width: 150,
        },
        {
            title: "本机构在日程展示",
            dataIndex: "hallList",
            width: 280,
            render: (textArr, record) => {
                return <div className="hall-list">
                    {record.hallNameList && record.hallNameList.map((hall,index) => <p key={`hall${index}`}>{hall}</p>)}
                    {!record.hallNameList && <Text type="secondary">&ndash;</Text>}
                </div>
            },
        },
        {
            title: "企业负责人",
            dataIndex: "expertName",
            width: 100,
            render:(data) => {
                 return data ? data : <Text type="secondary">&ndash;</Text> 
            },
        },
        {
            title: "合同权益",
            dataIndex: "contractRights",
            width: 80,
            render:(data) => {
                return data ? <Tooltip title={data}>已填写</Tooltip> : <Text type="secondary">&ndash;</Text> 
           },
        },
        {
            title: "赠送权益",
            dataIndex: "giftBenefits",
            width: 80,
            render:(data) => {
                return data ? <Tooltip title={data}>已填写</Tooltip> : <Text type="secondary">&ndash;</Text> 
           },
        },
        {
            title: '赞助/赠送展位编号',
            dataIndex: 'boothNum',
            width: 110,
            render: (textArr, record) => {
                if(!record.boothNum) return <Text type="secondary">&ndash;</Text> 
                let nums = JSON.parse(record.boothNum).map(item => item.name).join(',');
                return <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{nums}</span>
            },
        },
        {
            title: '备注',
            dataIndex: 'notes',
            width: 80,
            render:(data) => {
                return data ? <Tooltip title={data}>已填写</Tooltip> : <Text type="secondary">&ndash;</Text> 
           },
        },
        {
            title: '添加人',
            dataIndex: 'createdName',
            width: 80,
        },
        {
            title: '添加时间',
            dataIndex: 'createdAt',
            width: 120,
        },
        {
            title: "操作",
            dataIndex: "operations",
            width: 80,
            float: 'right',
            render: (_, record) => {
                return <span className="operation-btns">
                    <Button size="small" type="link" onClick={() => showAddOrgsDrawer(record)}>编辑</Button>
                    <Button size="small" type="link" onClick={() => deleteSponsor(record.id)}>删除</Button>
                </span>
                    
            },
        },
    ];

    const deleteSponsor = async (id) => {
        Modal.confirm({
            title: '确定删除该机构？',
            onOk: async () => {
                let param = [id]
                const res = await delSponsor(param);
                if (res.status_code === 200) {
                    message.success('删除成功');
                    getOrgList();
                }else{
                    message.error(res.message);
                }
            },
        });
        
    }
    const getOrgList = async () => {
        setLoading(true);
        let param = {
            ...filter,
            conferenceId,
            page: filter.page,
            pageSize: filter.pageSize,
        }
        const res = await sponsorList(param);
        if (res.status_code === 200) {
            setData(res.data.list);
            setTotal(res.data.totalCount);
        }
        setLoading(false);
    }
    useEffect(() => {
        form.setFieldsValue(filter);
        getOrgList();
    }, [filter]);

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };
    return (
        <>
            <div className="page-title">
                <Link to="/conf/conferences" className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">机构管理</span>
                <span className="current-subtitle">{conference?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="机构名称" name="name">
                                    <Input allowClear placeholder="机构名称" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="企业负责人" name="expertName">
                                    <Input allowClear placeholder="负责人名字" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="会议" name="hallName">
                                    <Input allowClear placeholder="会议名称" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="展位编号" name="boothNum">
                                    <Input allowClear placeholder="赞助/赠送展位编号" />
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Space>
                                <Button type="primary" onClick={()=>showAddOrgsDrawer(null)}>添加机构</Button>
                            </Space>
                        </div>
                    </div>

                    <Table
                        rowKey="id"
                        size="small"
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                        scroll={{ x: 1300 }}
                    />
                </div>
            </Content>

            <AddOrganizationDrawer
                visibleAddOrgsDrawer={visibleAddOrgsDrawer}
                setVisibleAddOrgsDrawer={setVisibleAddOrgsDrawer}
                enterprisePerson={enterprisePerson}
                sponsorList={getOrgList}
                organization={currentOrg}
                setOrganization={setCurrentOrg}
            />
        </>
    );
}
