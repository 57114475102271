import React, { useEffect, useState } from 'react';
import { Layout, Tabs, Image, Form, Button, Modal, Input, Radio, message, Popover, Table, Typography, Tag, Space } from 'antd';
import { LeftOutlined, MessageOutlined } from "@ant-design/icons";
import { useParams, Link } from 'react-router-dom';
import './CaseDetail.less';
import {
    caseBaseInfoApi,
    caseDetailApi,
    caseFileApiApi,
    caseTeamApi,
    caseOtherFileApi,
    caseContactInfoApi,
    getVodMediaInfoApi,
    caseAuditApi,
    getDicNameApi,
    getProjectTopicFieldsApi,
    caseFileReviewedApi,
    caseFileShowedChangeApi,
    getPeopleInfoApi
} from '@/services/Selection';
import { prepareBCOSSUrl, areasString, dateTimeFormatHMS } from '@utils/utils';
import { isEmpty } from 'lodash';

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

// 视频点播
let player = null;

let allDicCodeList = [];
// 基础信息
const BaseInfo = ({ caseFields, getFieldName, getSecondField }) => {
    const params = useParams();
    const [baseInfo, setBaseInfo] = useState({});
    const [baseFeildInfo, setBaseFeildInfo] = useState({});
    const [fields, setFields] = useState([]);

    const [dicCodeList, setDicCodeList] = useState([]);
    const [personDicList, setPersonDicList] = useState(null);

    const getCaseInfo = async (caseId) => {
        const res = await caseBaseInfoApi({ id: caseId });
        if (res.status_code === 200 && res.data) {
            setBaseInfo(res.data);
            const field = res.data.fieldValue ? JSON.parse(res.data.fieldValue) : null;
            if (field) {
                setBaseFeildInfo(field);
            }
            if (field.person_case_info) {
                setPersonDicList(field.person_case_info);
            }
        }
    };

    // 获取字典名称
    const getDicName = (dicCode) => {
        if (dicCode && dicCode.length === 1) {
            const target = dicCodeList.filter(item => (item.dicCode === dicCode[0]));
            if (target && target.length) {
                return target[0].dicName;
            }
        }
        if (dicCode && dicCode.length === 2) {
            let resName = null;
            const targetFir = dicCodeList.filter(item => (item.dicCode === dicCode[0]));
            if (targetFir && targetFir.length) {
                resName = targetFir[0].dicName;
            }
            const targetSec = dicCodeList.filter(item => (item.dicCode === dicCode[1]));
            if (targetSec && targetSec.length) {
                resName = resName + '/' + targetSec[0].dicName;
            }
            return resName;
        }
    };

    // 获取职务职称字段
    const getCodeName = async () => {
        const res = await getDicNameApi({ identity: 'A01' });
        if (res.status_code === 200 && res.data) {
            setDicCodeList(res.data);
            allDicCodeList = res.data;
        }
    };

    // 获取地区字段
    const getAreaValue = (areaCode) => {
        let val = [];
        const areaList = Object.values(areasString);
        if (areaCode && areaCode.length) {
            val = areaCode.map((code, index) => {
                return areaList[index].find(it => it.value === code)?.label || '';
            });
        }
        if (val && val.length) {
            return val.join(' ');
        }
        return '';
    };

    useEffect(() => {
        getCodeName();
        getCaseInfo(params.id);
    }, []);

    useEffect(() => {
        if (caseFields) {
            let newCaseFields = caseFields.map(item => item.field_name);
            setFields(newCaseFields);
        }
    }, [caseFields]);

    return <>
        <div className="base-info">
            <div className="base-cover-wrap">
                <p className="base-cover"><Image src={prepareBCOSSUrl(baseFeildInfo?.cover)}/></p>
                <div className="cover-info">
                    <p>案例编号：{params.id}</p>
                    {fields.includes('case_org_id') && <p>{getFieldName('case_org_id') || '提交单位'}：{baseInfo.hospitalName}</p>}
                    {fields.includes('region') && <p>{getFieldName('region') || '所属赛区'}：{baseFeildInfo?.region}</p>}
                    {fields.includes('topic_id') && <p>{getFieldName('topic_id') || '案例主题'}：{baseInfo.topicName}</p>}
                    {fields.includes('direction_id') && <p>{getFieldName('direction_id') || '申报方向'}：{baseInfo.directionName}</p>}
                    {fields.includes('area') && <p>{getFieldName('area') || '提报单位所在地'}：{getAreaValue(baseFeildInfo?.area)}</p>}
                </div>
            </div>

            <Form layout="horizontal">

                {fields.includes('party_org_name') && (
                    <Form.Item label={`${getFieldName('party_org_name') || '党组织名称'}：`}>
                        <div>{baseFeildInfo?.party_org_name}</div>
                    </Form.Item>
                )}

                {fields.includes('party_org_basic_info') && (
                    <Form.Item label={`${getFieldName('party_org_basic_info') || '党组织基本情况'}：`}>
                        <div>{baseFeildInfo?.party_org_basic_info}</div>
                    </Form.Item>
                )}

                {fields.includes('party_org_innovation') && (
                    <Form.Item label={`${getFieldName('party_org_innovation') || '党建业务融合创新做法'}：`}>
                        <div>{baseFeildInfo?.party_org_innovation}</div>
                    </Form.Item>
                )}

                {fields.includes('work_effect') && (
                    <Form.Item label={`${getFieldName('work_effect') || '工作效果'}：`}>
                        <div>{baseFeildInfo?.work_effect}</div>
                    </Form.Item>
                )}

                {fields.includes('work_inspiration') && (
                    <Form.Item label={`${getFieldName('work_inspiration') || '工作启示'}：`}>
                        <div>{baseFeildInfo?.work_inspiration}</div>
                    </Form.Item>
                )}

                {fields.includes('introduction') && (
                    <Form.Item label={`${getFieldName('introduction') || '案例简介'}：`}>
                        <div>{baseFeildInfo?.introduction}</div>
                    </Form.Item>
                )}

                {fields.includes('person_case_info') && (
                    <Form.Item label={`${getFieldName('person_case_info') || '人物信息'}：`}>
                        {!!dicCodeList.length && personDicList && (
                            <div className="contact-process">
                                {getSecondField('person_case_info', 'department') && <div><span>科室/部门：</span>{getDicName(personDicList.department)}</div>}
                                {getSecondField('person_case_info', 'post') && <div><span>职务：</span>{getDicName(personDicList.post)}</div>}
                                {getSecondField('person_case_info', 'title') && <div><span>职称：</span>{getDicName(personDicList.title)}</div>}
                                {getSecondField('person_case_info', 'phone') && <div><span>手机：</span>{personDicList.phone}</div>}
                                {getSecondField('person_case_info', 'email') && <div><span>邮箱：</span>{personDicList.email}</div>}
                            </div>
                        )}
                    </Form.Item>
                )}

                {fields.includes('background') && (
                    <Form.Item label={`${getFieldName('background') || '案例背景'}：`}>
                        <div>{baseFeildInfo?.background}</div>
                    </Form.Item>
                )}

                {fields.includes('processes') && (
                    <Form.Item label={`${getFieldName('processes') || '流程结构'}：`}>
                        {baseFeildInfo?.processes && baseFeildInfo.processes.length && baseFeildInfo.processes.map((p, i) => (
                            <div className="base-process" key={i}>{p?.process}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('features') && (
                    <Form.Item label={`${getFieldName('features') || '创新亮点'}：`}>
                        {baseFeildInfo?.features && baseFeildInfo.features.length && baseFeildInfo.features.map((f, i) => (
                            <div className="base-process" key={i}>{f?.feature}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('scenarios') && (
                    <Form.Item label={`${getFieldName('scenarios') || '应用场景'}：`}>
                        {baseFeildInfo?.scenarios && baseFeildInfo.scenarios.length && baseFeildInfo.scenarios.map((s, i) => (
                            <div className="base-process" key={i}>{s?.scenario}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('solution') && (
                    <Form.Item label={`${getFieldName('solution') || '解决方案'}：`}>
                        <div>{baseFeildInfo?.solution}</div>
                    </Form.Item>
                )}

                {fields.includes('benefits') && (
                    <Form.Item label={`${getFieldName('benefits') || '效果效益'}：`}>
                        {baseFeildInfo?.benefits && baseFeildInfo.benefits.length && baseFeildInfo.benefits.map((b, i) => (
                            <div className="base-process" key={i}>{b?.benefits}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('solved_problems') && (
                    <Form.Item label={`${getFieldName('solved_problems') || '痛点堵点'}：`}>
                        {baseFeildInfo?.solved_problems && baseFeildInfo.solved_problems.length && baseFeildInfo.solved_problems.map((p, i) => (
                            <div className="base-process" key={i}>{p?.solved_problems}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('honors') && (
                    <Form.Item label={`${getFieldName('honors') || '曾获荣誉'}：`}>
                        {baseFeildInfo?.honors && baseFeildInfo.honors.length && baseFeildInfo.honors.map((h, i) => (
                            <div className="base-process" key={i}>{h?.honor}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('applied_hospitals') && (
                    <Form.Item label={`${getFieldName('applied_hospitals') || '应用医院'}：`}>
                        {baseFeildInfo?.applied_hospitals && baseFeildInfo.applied_hospitals.length && baseFeildInfo.applied_hospitals.map((h, i) => (
                            <div className="base-process" key={i}>{h?.name}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('technologies') && (
                    <Form.Item label={`${getFieldName('technologies') || '相关技术'}：`}>
                        {baseFeildInfo?.technologies && baseFeildInfo.technologies.length && baseFeildInfo.technologies.map((t, i) => (
                            <div className="base-process" key={i}>{t?.technology}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('organizations') && (
                    <Form.Item label={`${getFieldName('organizations') || '合作机构'}：`}>
                        {baseFeildInfo?.organizations && baseFeildInfo.organizations.length && baseFeildInfo.organizations.map((o, i) => (
                            <div className="base-process" key={i}>{o?.name}</div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('served_times') && (
                    <Form.Item label={`${getFieldName('served_times') || '服务人次'}：`}>
                        <div>{baseFeildInfo?.served_times}</div>
                    </Form.Item>
                )}

                {fields.includes('period') && (
                    <Form.Item label={`${getFieldName('period') || '实施周期'}：`}>
                        {!!baseFeildInfo?.period?.start_date && <>
                            {baseFeildInfo.period?.start_date} &ndash; {!!baseFeildInfo.period?.end_date ? baseFeildInfo.period?.end_date : '至今'}
                        </>}
                    </Form.Item>
                )}

                {fields.includes('exam_indicators') && (
                    <Form.Item label={`${getFieldName('exam_indicators') || '对应国考指标'}：`}>
                        {baseFeildInfo?.exam_indicators && baseFeildInfo.exam_indicators.length && baseFeildInfo.exam_indicators.map((e, i) => (
                            <div className="base-process" key={i}>{e?.exam_indicator ? e?.exam_indicator.join(' / ') : ''}</div>
                        ))}
                    </Form.Item>
                )}
            </Form>
        </div>
    </>
};

// 案例联系人
const CaseContact = ({ caseFields, getFieldName, getSecondField }) => {
    const params = useParams();
    // 字典数据列表
    const [dicCodeList, setDicCodeList] = useState([]);
    const [fields, setFields] = useState([]);

    const [contactLeader, setContactLeader] = useState([]);
    const [contactDicList, setContactDicList] = useState([]);
    const [hospitalContact, setHospitalContact] = useState([]);
    const [enterpriseContact, setEnterpriseContact] = useState([]);

    const getCaseContactInfo = async (caseId) => {
        const res = await caseContactInfoApi({ id: caseId });
        if (res.status_code === 200 && res.data && res.data.fieldValue) {
            const field = JSON.parse(res.data.fieldValue);
            if (field.contacts && field.contacts.length) {
                setContactDicList(field.contacts);
            }
            if (field.case_leader_contacts && field.case_leader_contacts.length) {
                setContactLeader(field.case_leader_contacts);
            }
            if (field.hospital_contacts && field.hospital_contacts.length) {
                setHospitalContact(field.hospital_contacts);
            }
            if (field.enterprise_contacts && field.enterprise_contacts.length) {
                setEnterpriseContact(field.enterprise_contacts);
            }
        }
    };

    // 获取字典名称
    const getDicName = (dicCode) => {
        if (dicCode && dicCode.length === 1) {
            const target = dicCodeList.filter(item => (item.dicCode === dicCode[0]));
            if (target && target.length) {
                return target[0].dicName;
            }
        }
        if (dicCode && dicCode.length === 2) {
            let resName = null;
            const targetFir = dicCodeList.filter(item => (item.dicCode === dicCode[0]));
            if (targetFir && targetFir.length) {
                resName = targetFir[0].dicName;
            }
            const targetSec = dicCodeList.filter(item => (item.dicCode === dicCode[1]));
            if (targetSec && targetSec.length) {
                resName = resName + '/' + targetSec[0].dicName;
            }
            return resName;
        }
    };

    // 获取职务职称字段
    const getCodeName = async () => {
        const res = await getDicNameApi({ identity: 'A01' });
        if (res.status_code === 200 && res.data) {
            setDicCodeList(res.data);
        }
    };

    useEffect(() => {
        getCodeName();
        getCaseContactInfo(params.id);
    }, []);

    useEffect(() => {
        if (caseFields) {
            let newCaseFields = caseFields.map(item => item.field_name);
            setFields(newCaseFields);
        }
    }, [caseFields]);

    return <>
        <div className="contact-info">
            <Form layout="horizontal">
                {fields.includes('contacts') && (
                    <Form.Item label={`${getFieldName('contacts') || '案例主导负责人'}：`}>
                        {dicCodeList.length && contactDicList && contactDicList.length && contactDicList.map((c, i) => (
                            <div className="contact-process" key={i}>
                                {getSecondField('contacts', 'name') && <div><span>姓名：</span>{c.name}</div>}
                                {getSecondField('contacts', 'department') && <div><span>科室/部门：</span>{getDicName(c.department)}</div>}
                                {getSecondField('contacts', 'post') && <div><span>职务：</span>{getDicName(c.post)}</div>}
                                {getSecondField('contacts', 'title') && <div><span>职称：</span>{getDicName(c.title)}</div>}
                                {getSecondField('contacts', 'phone') && <div><span>手机：</span>{c.phone}</div>}
                                {getSecondField('contacts', 'email') && <div><span>邮箱：</span>{c.email}</div>}
                            </div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('case_leader_contacts') && (
                    <Form.Item label={`${getFieldName('case_leader_contacts') || '案例牵头人'}：`}>
                        {dicCodeList.length && contactLeader && contactLeader.length && contactLeader.map((c, i) => (
                            <div className="contact-process" key={i}>
                                {getSecondField('case_leader_contacts', 'name') && <div><span>姓名：</span>{c.name}</div>}
                                {getSecondField('case_leader_contacts', 'department') && <div><span>科室/部门：</span>{getDicName(c.department)}</div>}
                                {getSecondField('case_leader_contacts', 'post') && <div><span>职务：</span>{getDicName(c.post)}</div>}
                                {getSecondField('case_leader_contacts', 'title') && <div><span>职称：</span>{getDicName(c.title)}</div>}
                                {getSecondField('case_leader_contacts', 'phone') && <div><span>手机：</span>{c.phone}</div>}
                                {getSecondField('case_leader_contacts', 'email') && <div><span>邮箱：</span>{c.email}</div>}
                            </div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('hospital_contacts') && (
                    <Form.Item label={`${getFieldName('hospital_contacts') || '医院联系人'}：`}>
                        {dicCodeList.length && hospitalContact && hospitalContact.length && hospitalContact.map((c, i) => (
                            <div className="contact-process" key={i}>
                                {getSecondField('hospital_contacts', 'name') && <div><span>姓名：</span>{c.name}</div>}
                                {getSecondField('hospital_contacts', 'department') && <div><span>科室/部门：</span>{getDicName(c.department)}</div>}
                                {getSecondField('hospital_contacts', 'post') && <div><span>职务：</span>{getDicName(c.post)}</div>}
                                {getSecondField('hospital_contacts', 'title') && <div><span>职称：</span>{getDicName(c.title)}</div>}
                                {getSecondField('hospital_contacts', 'phone') && <div><span>手机：</span>{c.phone}</div>}
                                {getSecondField('hospital_contacts', 'email') && <div><span>邮箱：</span>{c.email}</div>}
                            </div>
                        ))}
                    </Form.Item>
                )}

                {fields.includes('enterprise_contacts') && (
                    <Form.Item label={`${getFieldName('enterprise_contacts') || '企业联系人'}：`}>
                        {dicCodeList.length && enterpriseContact && enterpriseContact.length && enterpriseContact.map((c, i) => (
                            <div className="contact-process" key={i}>
                                {getSecondField('enterprise_contacts', 'name') && <div><span>姓名：</span>{c.name}</div>}
                                {getSecondField('enterprise_contacts', 'department') && <div><span>科室/部门：</span>{getDicName(c.department)}</div>}
                                {getSecondField('enterprise_contacts', 'post') && <div><span>职务：</span>{getDicName(c.post)}</div>}
                                {getSecondField('enterprise_contacts', 'title') && <div><span>职称：</span>{getDicName(c.title)}</div>}
                                {getSecondField('enterprise_contacts', 'phone') && <div><span>手机：</span>{c.phone}</div>}
                                {getSecondField('enterprise_contacts', 'email') && <div><span>邮箱：</span>{c.email}</div>}
                            </div>
                        ))}
                    </Form.Item>
                )}

            </Form>
        </div>
    </>
};

// 案例文件
const CaseFile = ({ caseFields, getFieldName }) => {
    const params = useParams();
    const caseId = params.id;

    console.log(caseFields);

    const [caseFile, setCaseFile] = useState({});

    const [fileFields, setFileFields] = useState([]);

    // 上传演示材料的赛段
    const [stages, setStages] = useState([]);

    // 演示材料所在的赛段ID
    const [stageId, setStageId] = useState(0);

    const [visibleVideoPreview, setVisibleVideoPreview] = useState(false);

    const [visibleImagePreview, setVisibleImagePreview] = useState(false);
    const [imagesToPreview, setImagesToPreview] = useState([]);

    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState(1);

    const ReviewStatus = { 0: '待审核', 1: '审核通过', 2: '驳回' };

    useEffect(() => {
        if (caseFields) {
            let newCaseFields = caseFields.map(item => item.field_name);
            setFileFields(newCaseFields);
        }
    }, [caseFields]);

    // 视频预览
    const videoPreview = async (mediaId) => {
        if (!mediaId) {
            return;
        }
        if (player) {
            player.dispose();
        }

        const playInfo = await getVodMediaInfoApi({ mediaId });
        const resData = playInfo.data;
        if (!!resData && window.Aliplayer) {
            if (resData.status === 'Normal') {
                const playauth = resData.playAuth;
                player = new window.Aliplayer({
                    id: 'J_prismPlayer',
                    width: '720px',
                    height: '405px',
                    vid: mediaId,
                    playauth,
                    autoplay: false,
                    isLive: false,
                    rePlay: false,
                    playsinline: false,
                    preload: true,
                    skinLayout: playerSkinLayout
                }, function (player) {

                });
            }
        }
    };

    const openVideoPreview = videoId => {
        videoPreview(videoId);
        setVisibleVideoPreview(true);
    };

    const closeVideoPreview = () => {
        //

        setVisibleVideoPreview(false);
    };

    const previewDocument = path => {
        if (/\.pdf$/i.test(path)) {
            window.open(prepareBCOSSUrl(path));
        } else {
            let url = `https://view.officeapps.live.com/op/view.aspx?src=${prepareBCOSSUrl(path)}`;
            window.open(url);
        }
    };

    // 在新窗口打开源文件
    const openOriginalFile = path => window.open(prepareBCOSSUrl(path));

    const previewImages = images => {
        setVisibleImagePreview(true);
        setImagesToPreview(images);
    };

    const renderStageCaseFile = row => {
        if (!isEmpty(row.files)) {
            const files = row.files;
            const { doc, video, images, certificates } = files;

            return (
                <>
                    <div className="attach-list-cell">

                        {!isEmpty(doc) && <div className="attach-item doc">
                            <span className="attach-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
                                </svg>
                            </span>
                            <span>文档</span>
                            <Button type="link" size="small" onClick={() => previewDocument(doc?.path)}>预览</Button>
                            <Button type="link" size="small" onClick={() => openOriginalFile(doc?.path)}>下载</Button>
                        </div>}

                        {!isEmpty(video) && <div className="attach-item video">
                            <span className="attach-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-play" viewBox="0 0 16 16">
                                    <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z"/>
                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
                                </svg>
                            </span>
                            <span>视频</span>
                            <Button type="link" size="small" onClick={() => openVideoPreview(video?.videoId)}>预览</Button>
                            <Button type="link" size="small" onClick={() => openOriginalFile(video?.path)}>下载</Button>
                        </div>}

                        {!isEmpty(images) && <div className="attach-item images">
                            <span className="attach-icon">
                                {images.length > 1
                                    ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-images" viewBox="0 0 16 16">
                                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z"/>
                                        </svg>
                                    )
                                    : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
                                        </svg>
                                    )
                                }
                            </span>
                            <span>图片</span>
                            <Button type="link" size="small" onClick={() => previewImages(images)}>预览</Button>
                        </div>}

                    </div>

                    {!isEmpty(certificates) && (
                        <div className="cert-list">
                            <div className="cert-title">证明材料：</div>
                            {certificates.map((item, idx) => (
                                <div className="cert-item" key={`cert_${idx}`}>
                                    <span>{item.title}</span>
                                    <Button type="link" size="small" onClick={() => openOriginalFile(item.url)}>下载</Button>
                                </div>
                            ))}
                        </div>
                    )}

                </>
            );
        } else {
            return <Text type="secondary">&ndash;</Text>
        }
    };

    const columns = [
        { title: '赛段', dataIndex: 'stageName', width: 100, },
        {
            title: '案例文件',
            dataIndex: 'filePreview',
            render: (_, row) => renderStageCaseFile(row)
        },
        {
            title: '更新时间',
            dataIndex: 'updatedAt',
            align: 'center',
            width: 120,
            render: text => <Text type="secondary">{dateTimeFormatHMS(text)}</Text>
        },
        {
            title: '文档展示状态',
            dataIndex: 'showed',
            align: 'center',
            width: 100,
            render: v => !!v ? <Text type="success">默认展示</Text> : <Text type="secondary">&ndash;</Text>,
        },
        {
            title: '审核状态',
            dataIndex: 'reviewedStatus',
            align: 'center',
            width: 80,
            render: (_, row) => row.stageName === '申报赛段' ? <Text type="secondary">&ndash;</Text> : ReviewStatus[row.reviewedStatus]
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 200,
            render: (_, row) => {
                const canExamine = row.stageName !== '申报赛段' && (row.reviewedStatus === 0 || row.reviewedStatus === 1);
                const canShow = !row.showed && row.reviewedStatus === 1;
                return (
                    <span className="operation-btns">
                        {canExamine && <Button type="link" size="small" onClick={() => showReviewModal(row)}>审核</Button>}
                        {canShow && <Button type="link" size="small" onClick={() => showChangeAction(row)}>设为默认展示</Button>}
                    </span>
                );
            }
        },
    ];

    // 视频点播 - 主题配置
    const playerSkinLayout = [
        { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
        { name: "H5Loading", align: "cc" },
        { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
        { name: "infoDisplay" },
        { name: "tooltip", align: "blabs", x: 0, y: 56 },
        { name: "thumbnail" },
        {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                { name: "progress", align: "blabs", x: 0, y: 44 },
                { name: "playButton", align: "tl", x: 15, y: 12 },
                { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                // {name:"subtitle", align:"tr",x:15, y:12},
                { name: "setting", align: "tr", x: 15, y: 12 },
                { name: "volume", align: "tr", x: 5, y: 10 }
            ]
        }
    ];

    // 附件预览
    const attachmentPreview = (doc) => {
        window.open(prepareBCOSSUrl(doc.path));
    };

    // const attachmentRawFile = doc => window.open(prepareBCOSSUrl(doc.path));
    const attachmentRawFile = doc => {
        if (/\.pdf$/i.test(doc.path)) {
            window.open(prepareBCOSSUrl(doc.path));
        } else {
            let officeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${prepareBCOSSUrl(doc.path)}`;
            window.open(officeUrl);
        }
    };

    const showChangeAction = async stage => {
        let param = {
            caseId,
            showed: 1,
            id: stage.stageName === '申报赛段' ? undefined : stage.id
        };
        const res = await caseFileShowedChangeApi(param);
        if (res.status_code === 200) {
            if (res.data === 1 || res.data === 3) {
                message.error(res.message);
            } else {
                message.success('展示状态设置成功');

                getAllStageList();
            }
        }
    }

    const showReviewModal = stageWithAttachmentFiles => {
        setStageId(stageWithAttachmentFiles.id);
        setIsModalOpen(true);
    };

    const submitReview = () => {
        form.validateFields()
            .then(async (values) => {
                const data = {
                    id: stageId,
                    reviewedStatus: values.status,
                    ...values
                };
                const res = await caseFileReviewedApi(data);
                if (res.status_code === 200) {
                    message.success('审核完成');
                    getAllStageList();
                    setIsModalOpen(false);
                }
            });
    };

    const cancelReview = () => {
        setIsModalOpen(false);
    };

    const changeReviewStatus = e => {
        setValue(e.target.value);
    };

    const getAllStageList = () => {

        // 申报赛段
        let startStage = null;
        const p1 = caseFileApiApi({ id: caseId })
            .then((res) => {
                if (res.status_code === 200) {
                    startStage = res.data; // 申报赛段 Object
                    if (!!startStage.fieldValue) {
                        // 统一数据结构
                        startStage['files'] = JSON.parse(startStage.fieldValue) || null;
                    }

                    // 统一数据结构
                    // 操作中会用到这个标识：<code>stage.stageName === '申报赛段' ? undefined : stage.id</code>
                    startStage['stageName'] = '申报赛段';
                }
            });

        // 其他赛段
        let otherStageList = [];
        const p2 = caseOtherFileApi({ caseId })
            .then((res) => {
                if (res.status_code === 200) {
                    let stageList = res.data; // 其他赛段列表 Array<Object>
                    if (stageList && stageList.length) {
                        otherStageList = stageList.map(s => {
                            if (!!s.files) {
                                s.files = JSON.parse(s.files) || null;

                                if (s.files.video && s.playInfos && s.playInfos.length) {
                                    s.files.video.path = s.playInfos[0]['playUrl'];
                                }
                            }

                            return s;
                        });
                    }
                }
            });

        Promise.all([p1, p2])
            .then(() => {
                // 确定哪一个赛段的案例文件默认展示
                let visible = false;
                otherStageList.forEach(s => {
                    if (s.showed) {
                        visible = true;
                    }
                });
                if (!visible) {
                    startStage['showed'] = true; // 统一数据结构
                }

                // 整合申报赛段和其他赛段的数据
                const compactStages = [...[startStage], ...otherStageList];
                setStages(compactStages);
                console.log(compactStages);
            });
    };

    useEffect(() => {
        if (caseId) {
            getAllStageList(caseId);
        }
    }, [caseId]);

    return <>
        <div className="base-info">
            <Table size="small" columns={columns} dataSource={stages} pagination={false}/>
        </div>

        <div style={{ display: 'none' }}>
            {!!imagesToPreview.length && (
                <Image.PreviewGroup
                    preview={{
                        visible: visibleImagePreview,
                        onVisibleChange: v => setVisibleImagePreview(v)
                    }}
                >
                    <>{imagesToPreview.map((img, i) => <Image src={prepareBCOSSUrl(img.url)} key={`img_${i}`}/>)}</>
                </Image.PreviewGroup>
            )}
        </div>

        <Modal
            title="视频预览"
            open={visibleVideoPreview}
            width={768}
            centered
            footer={null}
            onCancel={() => closeVideoPreview()}
        >
            <div id="J_prismPlayer" style={{ width: 720, height: 405, backgroundColor: 'black' }}/>
        </Modal>

        <Modal title="审核案例文件" open={isModalOpen} onOk={submitReview} onCancel={cancelReview}>
            <Form
                name="auditForm"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off"
            >
                <Form.Item
                    label="审核意见"
                    name="status"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group onChange={changeReviewStatus} value={value}>
                        <Radio value={1}>通过</Radio>
                        <Radio value={2}>驳回</Radio>
                    </Radio.Group>
                </Form.Item>

                {value === 2
                    ? (
                        <Form.Item
                            name="rejectReason"
                            label="驳回理由"
                            rules={[
                                { required: true, message: '请输入驳回理由' },
                            ]}
                        >
                            <TextArea rows={4} placeholder="输入驳回理由" showCount maxLength={200}/>
                        </Form.Item>
                    )
                    : null
                }

            </Form>
        </Modal>
    </>
};

// 案例参赛团队
const CaseTeam = ({ caseId ,caseFields,memcard }) => {
    const params = useParams();
    const [data, setData] = useState([]);

    const [stageData, setStageData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [personData, setPersonData] = useState([]);


    const columns = [
        { title: '手机号', dataIndex: 'mobile', key: 'mobile', },
        { title: '姓名', dataIndex: 'username', key: 'username', },
        { title: '科室部门', dataIndex: 'memOfficeText', key: 'memOffice', default: '—' },
        { title: '职务', dataIndex: 'memPostText', key: 'memPost', default: '—' },
        { title: '职称', dataIndex: 'memJobtitleText', key: 'memJobtitle', default: '—' },
        { title: '赛段', dataIndex: 'stageName', key: 'stageName', default: '—' },
        { title: '负责任务', dataIndex: 'memTask', key: 'memTask', default: '—' },
    ];

    const sortData = () => {
        var d = [...contactData, ...personData, ...stageData]
        var d = d.map((item)=>{
            return ({...item,key:`${item.mobile}_${item.stageName}`})
        })
        setData(d)
    };

    // 获取字典名称
    const getDicName = (dicCode) => {
        if (dicCode && dicCode.length === 1) {
            const target = allDicCodeList.filter(item => (item.dicCode === dicCode[0]));
            if (target && target.length) {
                return target[0].dicName;
            }
        }
        if (dicCode && dicCode.length === 2) {
            let resName = null;
            const targetFir = allDicCodeList.filter(item => (item.dicCode === dicCode[0]));
            if (targetFir && targetFir.length) {
                resName = targetFir[0].dicName;
            }
            const targetSec = allDicCodeList.filter(item => (item.dicCode === dicCode[1]));
            if (targetSec && targetSec.length) {
                resName = resName + '/' + targetSec[0].dicName;
            }
            return resName;
        }
    };

    const getCaseTeam = async () => {
        const res = await caseTeamApi({ caseId: params.id });
        if (res.status_code === 200 && res.data) {
            setStageData(res.data)
        }
    };

    const getCaseContactInfo = async (caseId) => {
        const res = await caseContactInfoApi({ id: caseId });
        if (res.status_code === 200 && res.data && res.data.fieldValue) {
            const field = JSON.parse(res.data.fieldValue);
            if (field.contacts && field.contacts.length) {
                var tmpD = field.contacts.map((item)=>{
                    var newItem = {
                        ...item, 
                        stageName: "全部赛段", 
                        memTask: "案例负责人",
                        memOfficeText: getDicName(item.department),
                        memPostText: getDicName(item.post),
                        memJobtitleText: getDicName(item.title),
                        mobile: item.phone,
                        username: item.name,
                    }
                    return newItem;
                })
                setContactData(tmpD);
            }
        }
    };
    const getDeclarationInfo = async (memcard) => {
        const res = await getPeopleInfoApi({ memCard: memcard });
        if (res.data) {
            setPersonData([{
                ...res.data,
                stageName: "申报赛段",
                memTask: "申报人、案例负责人",
                memOfficeText: res.data.qitaMap.memOffice,
                memPostText: res.data.qitaMap.memPost,
                memJobtitleText: getDicName([res.data.qitaMap.memJobtitleCode]),
                mobile: res.data.memMobile,
                username: res.data.memNickname}])
        }
    };

    useEffect(() => {
        sortData()
    }, [stageData,personData,contactData]);

    useEffect(() => {
        getDeclarationInfo(memcard);
        getCaseContactInfo(caseId);
        getCaseTeam();
    }, []);

    return <>
        <div className="base-info">
            <Table size="small" columns={columns} dataSource={data} pagination={false}/>
        </div>
    </>
};

// 案例详情
const CaseDetail = () => {
    const params = useParams();
    const caseId = parseInt(params.id) || 0;

    const [form] = Form.useForm();

    const [caseInfo, setCaseInfo] = useState({});
    const [fieldInfo, setFieldInfo] = useState({});
    const [caseFields, setCaseFields] = useState(null);

    const [visibleCaseReviewModal, setVisibleCaseReviewModal] = useState(false);

    // 字段名称
    const getFieldName = name => caseFields.find(item => item.field_name === name)?.field_display_name;

    // 二级字段内容是否展示
    const getSecondField = (name, secondName) => {
        const fieldItem = caseFields.find(item => item.field_name === name);
        if (fieldItem?.children && fieldItem.children.length > 0) {
            const isShow = fieldItem.children.some(item => item.field_name === secondName && item.checked);
            return isShow;
        }
        return false;
    };

    // 渲染子组件
    const getChildComponent = type => {
        // '基础信息', '案例联系人', '案例文件', '参赛团队'
        switch (type) {
            case 0:
                return (
                    <BaseInfo
                        caseId={params.id}
                        caseFields={caseFields}
                        getFieldName={getFieldName}
                        getSecondField={getSecondField}
                    />
                );
            case 1:
                return (
                    <CaseContact
                        caseId={params.id}
                        caseFields={caseFields}
                        getFieldName={getFieldName}
                        getSecondField={getSecondField}
                    />
                );
            case 2:
                return (
                    <CaseFile
                        caseId={params.id}
                        caseFields={caseFields}
                        caseInfo={caseInfo}
                        getFieldName={getFieldName}
                    />
                );
            case 3:
                return (
                    <CaseTeam
                        caseId={params.id}
                        caseFields={caseFields}
                        getFieldName={getFieldName}
                        memcard={caseInfo.memcard}
                    />
                );
        }
    };

    // 审核状态
    const getAuditStatus = (status) => {
        if (status === 0) {
            return '待审核';
        }
        if (status === 1) {
            return '审核通过';
        }
        if (status === 2) {
            return '审核驳回';
        }
    };

    // 发布状态
    const getReleaseStatus = (status) => {
        if (status === 0) {
            return '草稿';
        }
        if (status === 1) {
            return '未发布';
        }
        if (status === 2) {
            return '已上架';
        }
        if (status === 3) {
            return '已下架';
        }
    };

    // 获取案例详情
    const getCaseDetail = async (caseId) => {
        const res = await caseDetailApi({ id: caseId });
        if (res.status_code === 200 && res.data) {
            setCaseInfo(res.data);
            if (res.data.fieldValue) {
                setFieldInfo(JSON.parse(res.data.fieldValue));
            }
            getProjectTopicFields(res.data.topicId);
        }
    };

    // 获取案例分配字段
    const getProjectTopicFields = async (topicId) => {
        const res = await getProjectTopicFieldsApi({ topicId });
        if (res.status_code === 200 && res.data) {
            let fields = JSON.parse(res.data?.caseFields) ?? [];
            if (!!fields) {
                setCaseFields(fields);
            }
        }
    };

    const openCaseReviewModal = () => setVisibleCaseReviewModal(true);

    const closeCaseReviewModal = () => {
        form.resetFields();
        setVisibleCaseReviewModal(false);
    };

    const submitCaseReview = () => {
        form.validateFields()
            .then(async (values) => {
                const res = await caseAuditApi({ id: caseId, ...values });
                if (res.status_code === 200) {
                    message.success('案例审核完成');
                    setVisibleCaseReviewModal(false);
                    getCaseDetail(params.id);
                }
                ;
            })

    };

    useEffect(() => {
        getCaseDetail(params.id);
    }, []);

    return <>
        <div className="page-title">
            <Link to={`/selection/cases`} className="back"><LeftOutlined/></Link>
            <span className="current-title">案例详情</span>
            {!!fieldInfo.title && <span className="current-subtitle">{fieldInfo.title}</span>}
        </div>
        <Content className="main-content-box">
            <div className="main-content-title">案例信息</div>
            <div className="main-content-body">
                <div className="case-detail">
                    <h2>{fieldInfo?.title}</h2>
                    <p className="detail-header">
                        <span>提交时间：{caseInfo.createdAt}</span>
                        <span>
                            审核状态：{
                            caseInfo.reviewedStatus === 2
                                ? (
                                    <Popover placement="left" content={caseInfo.rejectReason} trigger="hover">
                                        {getAuditStatus(caseInfo.reviewedStatus)} <MessageOutlined/>
                                    </Popover>
                                )
                                : getAuditStatus(caseInfo.reviewedStatus)
                        }
                        </span>
                        {!!caseInfo.reviewedStatus && <><span>审核时间：{caseInfo.reviewedAt}</span><span>审核管理员：{caseInfo.reviewedName}</span></>}
                        <span>发布状态：{getReleaseStatus(caseInfo.status)}</span>
                    </p>
                    {caseFields && (
                        <Tabs
                            type="card"
                            items={['基础信息', '案例联系人', '案例文件', '参赛团队'].map((title, i) => ({ label: title, key: i, children: getChildComponent(i) }))}
                        />
                    )}

                    {caseInfo?.status > 0 && (
                        <div className="fixed-foot-toolbar">
                            <div className="operations">
                                <Space size="large">
                                    <Button type="primary" size="large" onClick={openCaseReviewModal}>审核案例</Button>
                                </Space>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Content>

        <Modal title="审核案例" open={visibleCaseReviewModal} onOk={submitCaseReview} onCancel={closeCaseReviewModal}>
            <Form
                name="auditForm"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off"
            >
                <Form.Item
                    label="审核意见"
                    name="status"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>通过</Radio>
                        <Radio value={2}>驳回</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        return getFieldValue('status') === 2 && (
                            <Form.Item
                                name="rejectReason"
                                label="驳回理由"
                                rules={[
                                    { required: true, message: '请输入驳回理由', },
                                ]}
                            >
                                <TextArea rows={4} placeholder="输入驳回理由" showCount maxLength={200}/>
                            </Form.Item>
                        );
                    }}
                </Form.Item>

            </Form>
        </Modal>

    </>
};

export default CaseDetail;
