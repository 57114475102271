
import axios from 'axios';
import { message } from 'antd';

class HttpClient {
    constructor() {
        this.axiosInstance = axios.create({
            timeout: 30000,
            baseURL: ''
        });

        this.axiosInstance.interceptors.request.use(config => {

            return config;
        });

        this.axiosInstance.interceptors.response.use(resp => {
            if (resp.status === 200) {
                const data = resp.data;

                switch (data.status_code) {
                    case 200:
                        // 200 时，抛弃 message 等字段，只返回 data.data
                        return data.data;
                    case 400:
                        message.error(data.message);
                        break;
                    case 401:
                        // TODO 清理token，跳转至登录页面
                        break;
                    case 403:
                        // 无权访问
                        break;
                    default:
                        // 未知问题
                        return data;
                        break;
                }
            } else {
                return Promise.reject(resp);
            }
        }, err => {
            if (err && err.response) {
                // TODO
            }
        });
    }

    async get(url, params = {}) {
        return await this.axiosInstance.get(url, {params: this.prepareParams(params)});
    }

    async post(url, data, config = {}) {
        return await this.axiosInstance.post(url, data, config);
    }

    async put(url, data, config = {}) {
        return await this.axiosInstance.put(url, data, config);
    }

    async patch(url, data, config = {}) {
        return await this.axiosInstance.patch(url, data, config);
    }

    async delete(url, config = {}) {
        return await this.axiosInstance.delete(url, config);
    }

    // 预处理请求参数
    prepareParams(params) {
        let newParams = {};

        if (params) {
            for (let key in params) {

                // GET 请求的一些优化，空的默认的都不传
                if (params[key] === null || params[key] === undefined) {
                    continue;
                }

                newParams[key] = params[key];
            }
        }

        return newParams;
    }
}

export default new HttpClient();
