import { Link, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from 'react';
import {
    Layout,
    Tabs,
    Badge
} from 'antd';
import './index.less';
import MemberList from './components/MemberList';
import AuditList from './components/AuditList';
import RefuseList from './components/RefuseList';
import {showMedProject, membersCountApi} from '@services/MedProject';

const { Content } = Layout;

export default function RelatedProjectList() {
    const navigate = useNavigate();
    const params = useParams();

    const projectId = (params?.id && Number(params?.id)) || 0; // 项目 ID

    const [project, setProject] = useState(null);
    const [membersCount, setMembersCount] = useState(0);

    const getProject = async id => {
        const res = await showMedProject(id);
        if (res.status_code === 200) {
            const project = res.data;
            setProject(project);
        }
    };

    const getMembersCount = async() => {
        const res = await membersCountApi({ projectId: projectId, status: 0 });
        if (res.status_code === 200) {
            setMembersCount(res.data);
        }
    };

    useEffect(() => {
        if (!!projectId) {
            getProject(projectId);
            getMembersCount();
        }
    }, []);

    const tabsRef = [
        { label: '成员列表', key: '1', children: <MemberList status='1' /> },
        { label: <Badge count={membersCount} offset={[10, 0]}>待审核</Badge>, key: '2', children: <AuditList status='0' getMembersCount={getMembersCount} /> },
        { label: '已拒绝', key: '3', children: <RefuseList status='2' /> },
    ]

    const onChange = async(key) => {
    };


    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">成员管理</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">

                <div className="main-content-body">

                    <Tabs
                        className="members-tabs"
                        defaultActiveKey="1"
                        destroyInactiveTabPane='true'
                        size="large"
                        tabBarGutter={50}
                        onChange={onChange}
                        items={tabsRef}
                    />

                </div>

            </Content>

        </>
    );
}
