import { Button, Drawer, Form, Input, Space, Modal, Typography, Row, Col, message, Select, Empty, Cascader } from "antd";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { drawerFormItemLayout } from "@utils/property";
import { useParams } from "react-router-dom";
import { saveSponsor, updateSponsor, getSponsor, hallList, conferenceOnceApi, conferenceUpdateApi } from "@services/Conference";
import { caseOrganizationApi } from "@services/Conference/sign";
import debounce from "lodash/debounce";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, DragOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import './index.less';

const { Option } = Select;
const { Text } = Typography;
// 会议新增、编辑
const AddOrganizationDrawer = ({ visibleAddOrgsDrawer, setVisibleAddOrgsDrawer, enterprisePerson, sponsorList, organization, setOrganization }) => {

    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID
    const [form] = Form.useForm();

    const [conferenceHallLoading, setConferenceHallLoading] = useState(false); // 新增/编辑会议场次loading
    const [conferenceHallList, setConferenceHallList] = useState([]);
    // 赞助企业
    const [caseOrgValue, setCaseOrgValue] = useState(null);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const [fetching, setFetching] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [currentAddOrg, setCurrentAddOrg] = useState(null);

    // 赞助企业编号
    const [orgForm] = Form.useForm();
    const [currentOrgNum, setCurrentOrgNum] = useState(null); // 当前正在编辑的企业编号
    const [placeConfirmEnabled, setPlaceConfirmEnabled] = useState(false);
    const [orgNumModalOpen, setOrgNumModalOpen] = useState(false); // 修改企业编号弹窗

    const [currentConference, setCurrentConference] = useState(null);
    const [boothNums, setBoothNums] = useState([]); // 该会议下的展位编号
    const [selectedBoothNums, setSelectedBoothNums] = useState([]); // 该会议下的展位编号

    // 选择企业展位编号
    const handleChangeOrgNum = (bnum) => {
        setSelectedBoothNums(bnum.map(item => {return {name: item,key: item}}))
    };

    const onChangeOrgName = (e) => {
        if (orgForm.getFieldValue("name") !== currentOrgNum?.name) {
            setPlaceConfirmEnabled(true);
        }
    };

    const closeAddConferenceHallDrawer = () => {
        setVisibleAddOrgsDrawer(false);
        form.resetFields();
    };


    // 提交会议（场次），新增/编辑
    const submitConferenceOrg = () => {
        form.validateFields().then((values) => {
            let newValues = { ...values };
            if (newValues.expertId && newValues.expertId.length){
                newValues.expertId = newValues.expertId.join(',')
            }else{
                newValues.expertId = ""
            }
            if(selectedBoothNums && selectedBoothNums.length){
                newValues.boothNum = JSON.stringify(selectedBoothNums)
            }else{
                newValues.boothNum = "[]"
            }
            if (organization) {
                updateOrgantion(newValues, organization.id);
            } else {
                saveOrg(newValues);
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    // 新增机构
    const saveOrg = async (values) => {
        let params = { ...values };
        params.conferenceId = conferenceId;
        params.orgId = caseOrgValue
        if(currentAddOrg.bodyType){
            params.orgType = currentAddOrg.bodyType
        }
        params.name = currentAddOrg.label
        if(params.hallList && params.hallList.length>0){
            params.hallList = params.hallList.map((item)=>{
                return {
                    hallId: item.hallId.pop(),
                    sponsorType: item.sponsorType,
                    conferenceId: conferenceId
                }
            })
        }else{
            params.hallList = []
        }

        try {
            setConferenceHallLoading(true);
            const res = await saveSponsor(params);
            if (res.status_code === 200) {
                message.success("机构保存成功");
                closeAddConferenceHallDrawer();
                sponsorList();
                form.resetFields();
            }
            setConferenceHallLoading(false);
            setOrganization(null)
        } catch (e) {
            setConferenceHallLoading(false);
            setOrganization(null)
        }
    };

    // 编辑会议
    const updateOrgantion = async (values, id) => {
        let params = { ...values };
        params.conferenceId = conferenceId;
        params.orgId = caseOrgValue
        if(currentAddOrg.bodyType){
            params.orgType = currentAddOrg.bodyType
        }
        params.name = currentAddOrg.label
        if(params.hallList && params.hallList.length>0){
            params.hallList = params.hallList.map((item)=>{
                return {
                    hallId: item.hallId.pop(),
                    sponsorType: item.sponsorType,
                    conferenceId: conferenceId
                }
            })
        }else{
            params.hallList = []
        }
        params.id = id
        try {
            setConferenceHallLoading(true);
            const res = await updateSponsor(params);
            if (res.status_code === 200) {
                message.success("会议编辑成功");
                closeAddConferenceHallDrawer();
                sponsorList();
            }
            setConferenceHallLoading(false);
            setOrganization(null)
        } catch (e) {
            setConferenceHallLoading(false);
            setOrganization(null)
        }
    };

    const fetchOrgList = async (keyword, type) => {
        setSearchValue(keyword);
        if (!keyword) return [];
        const res = await caseOrganizationApi({ keyword, type: type || undefined });
        if (res.status_code === 200 && res.data && res.data.length) {
            const list = res.data.map((org) => ({
                label: org.hosName,
                value: org.id,
                bodyType: org.bodyType
            }));
            return list;
        }
        return [];
    };

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOrgList(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 500);
    }, [fetchOrgList]);

    const getBoothNumList = () => {
        conferenceOnceApi({id: conferenceId}).then((res) => {
            if(res && res.status_code === 200 && res.data){
                setCurrentConference(res.data)
                if(res.data.boothList){
                    let nums = JSON.parse(res.data.boothList)
                    setBoothNums(nums)
                    console.log("下拉列表", nums)
                }
            }
        })
    };
    // 增加修改企业编号请求
    const handleSubmitPlaceForm = () => {
        orgForm.validateFields().then((value) => {
            if(currentOrgNum){
                selectedBoothNums.forEach((v) => {
                    console.log('0',v);
                    if (v.name === currentOrgNum.name){
                        v.name = value.name;
                        v.key = value.name;
                    }
                });
                setSelectedBoothNums(selectedBoothNums);
                boothNums.forEach((v) => {
                    if (v.name === currentOrgNum.name) {
                        v.name = value.name;
                        v.key = value.name;
                    }
                });
                setBoothNums(boothNums);
                updateConferenceBoothNum('修改', boothNums);
            }else{
                let newItem = {...value, key: value.name}
                let tmp = boothNums;
                tmp.push(newItem);
                setBoothNums(tmp);
                updateConferenceBoothNum('添加', tmp);
            }
            
        })
    };

    const updateConferenceBoothNum = (str, arr) => {
        let values = {...currentConference, createdAt: undefined,updatedAt: undefined}
        let boothNumString = JSON.stringify(arr);
        if (!boothNumString) {
            boothNumString = '[]';
        }
        values.boothList = boothNumString;
        conferenceUpdateApi(values).then((res) => {
            if (res.status_code === 200) {
                message.success(`企业编号${str}成功`);
                setOrgNumModalOpen(false);
            }
        });
    };
        
    // 新增/编辑 企业编号事件
    const handleOrgModal = (item) => {
        if (item) {
            orgForm.setFieldsValue(item);
            setCurrentOrgNum(item);
        } else {
            orgForm.resetFields();
            setCurrentOrgNum(null);
        }
        setOrgNumModalOpen(true);
    };
    // 删除
    const handleDeleteOrgNum = (item) => {
        Modal.confirm({
            title: "提示",
            content: (
                <>
                    <Text type="danger">删除后，对应会议的展位编号会自动清空</Text>，确认删除展位编号<b>{item.name}</b>？
                </>
            ),
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk: () => {
                let newSelect = selectedBoothNums.filter((v) => v.name !== item.name);
                setSelectedBoothNums(newSelect);

                let newArr = boothNums.filter((v) => v.name !== item.name);
                setBoothNums(newArr);
                updateConferenceBoothNum('删除', newArr);
            },
        });
    };


    useEffect(() => {
        
        if (visibleAddOrgsDrawer) {
            getHallsList()
            getBoothNumList()
            if (organization) {
                let values = { ...organization };
                if (values.id) {
                    getSponsor(values.id).then((res) => {
                        if (res.status_code === 200 && res.data) {
                            setOptions([{ label: res.data.name, value: res.data.orgId }]);
                            setCurrentAddOrg({ label: res.data.name, value: res.data.orgId, bodyType: values.orgType});
                            form.setFieldsValue(res.data);
                            if(res.data.boothNum){
                                let numArr = JSON.parse(res.data.boothNum)
                                setSelectedBoothNums(numArr)
                                console.log("已选", numArr)
                            }else{
                                setSelectedBoothNums([])
                            }
                            if(res.data.expertId){
                                form.setFieldValue("expertId", res.data.expertId.split(',').map(i=>parseInt(i)))
                            }else{
                                form.setFieldValue("expertId", [])
                            }
                            if(res.data.hallList && res.data.hallList.length>0){
                                form.setFieldValue("hallList", res.data.hallList.map((item)=>{
                                    let hallId = [item.hallId]
                                    if (item.pid){
                                        hallId.splice(0, 0, item.pid);
                                    }
                                    return {
                                        hallId: hallId,
                                        sponsorType: item.sponsorType
                                    }
                                }))
                            }

                        }
                    });
                }

            }
        } else {
            setOrganization(null);
            setSelectedBoothNums([]);
            form.resetFields();
        }
    }, [visibleAddOrgsDrawer]);

    const taskList = [
        { id: 1, key: 1, name: "主办单位", sort: 1, hide: 1 },
        { id: 4, key: 4, name: "学术支持", sort: 2, hide: 1 },
        { id: 2, key: 2, name: "协办单位", sort: 3, hide: 1 },
        { id: 3, key: 3, name: "承办单位", sort: 4, hide: 1 },
        { id: 5, key: 5, name: "指导单位", sort: 5, hide: 1 },
        { id: 7, key: 7, name: "战略合作", sort: 6, hide: 1 },
        { id: 6, key: 6, name: "特别支持", sort: 7, hide: 1 },
        { id: 8, key: 8, name: "鸣谢", sort: 8, hide: 1 },  
    ];
    const getHallsList =  async () => {
        const res = await hallList({conferenceId})
        if (res.status_code === 200){
            let options = res.data.map(item => ({
                label: item.name,
                value: item.id,
                children: item.secondHallsList.map(i => ({label: i.name, value: i.id, key: i.id})),
                key: item.id
            }))
            setConferenceHallList(options)
        }
    };
    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    const handleHallsChange = (e, index) =>{

    }
    const handleTypeChange = () => {

    };

    // 删除
    const delConference = (index) => {
        let arr = form.getFieldValue("hallList");
        let newArr = [];
        if (arr.length > 1) {
            newArr = [...arr];
            newArr.splice(index, 1);
        }
        form.setFieldValue("hallList", newArr);
    };
    const SortableItem = SortableElement(({ field, index, remove }) => (
        <div className="add-guest-item guest-item">
            <span className="no">{field.name + 1}</span>
            <Space style={{ display: "flex" }} align="center">
                <Form.Item {...field} name={[field.name, "sponsorType"]} rules={[{ required: true, message: "请选择机构类型" }]}>
                    <Select placeholder="机构类型" style={{ width: 120 }} onChange={handleTypeChange} options={taskList} popupClassName="first-conference" fieldNames={{ value: "id", label: "name" }} />
                </Form.Item>
                <Form.Item {...field} name={[field.name, "hallId"]} rules={[{ required: true, message: "选择会议名称" }]}>
                    <Cascader  style={{ width: 500 }} options={conferenceHallList} onChange={(e) => handleHallsChange(e, index)} showSearch={{filter}} changeOnSelect/>
                </Form.Item>

                <MinusCircleOutlined
                    onClick={() => {
                        delConference(field.name);
                    }}
                />
            </Space>
        </div>
    ));

    const SortableList = SortableContainer(({ items, remove, move }) => {
        return (
            <div>
                {items.map((field, index) => (
                    <SortableItem key={`item-${index}`} index={index} field={field} remove={remove} move={move} />
                ))}
            </div>
        );
    });

    return (
        <Drawer
            title={organization ? "编辑机构" : "新增机构"}
            width={880}
            open={visibleAddOrgsDrawer}
            onClose={closeAddConferenceHallDrawer}
            layout="horizontal"
            maskClosable={false}
            footer={
                <div style={{ textAlign: "right" }}>
                    <Space>
                        <Button onClick={closeAddConferenceHallDrawer}>取消</Button>
                        <Button type="primary" loading={conferenceHallLoading} onClick={submitConferenceOrg}>
                            确定
                        </Button>
                    </Space>
                </div>
            }
        >
            <div className="conference-form">
                <Form {...drawerFormItemLayout} layout="horizontal" name="basic" form={form} initialValues={{ hidden: 0 }} autoComplete="off">
                    <Form.Item name="name" label="机构名称" rules={[{ required: true, message: "请选择机构名称" }]}>
                        <Select
                            showSearch
                            allowClear
                            filterOption={false}
                            onSearch={debounceFetcher}
                            value={caseOrgValue}
                            placeholder="输入关键字检索企业"
                            onChange={(newValue) => {
                                setCaseOrgValue(newValue);
                                const targetValue = options && options.length ? options.filter((org) => org.value === newValue) : [];
                                if (targetValue && targetValue.length) {
                                    const currentOrg = { label: targetValue[0].label, value: targetValue[0].value , bodyType: targetValue[0].bodyType};
                                    setCurrentAddOrg(currentOrg);
                                }
                            }}
                            style={{ width: "100%" }}
                            notFoundContent={searchValue ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="请输入关键字检索" />}
                            options={options}
                        ></Select>
                    </Form.Item>
                    <Form.Item label="本机构在日程展示" name="hallList">
                        <Form.List name="hallList">
                            {(fields, { add, remove, move }) => (
                                <>
                                    <Form.Item style={{ marginBottom: 8 }}>
                                        <Button type="link" onClick={() => add()}>
                                            <PlusCircleOutlined />
                                            {"添加"}
                                        </Button>
                                        <span style={{ fontSize: 12, color: "#999" }}>将在用户端日程模块，对应会议下方展示本机构信息和单位类型（同单位类型的机构展示在一起）</span>
                                    </Form.Item>
                                    <SortableList items={fields} remove={remove} move={move} useDragHandle />
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    {(!currentAddOrg || (currentAddOrg && currentAddOrg.bodyType !== 1))  && (
                        <>
                            <Form.Item
                                label="企业负责人"
                                name="expertId"
                                extra={
                                    <>
                                        企业负责人可在【小程序-我的项目】中查看权益内容，请结合业务需求选择企业负责人
                                        <br />
                                        （选择企业负责人之前，需先把该人员添加到本会议【嘉宾管理】的列表中，且身份设置为【企业人员】）
                                    </>
                                }
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="请选择企业负责人"
                                    fieldNames={{ label: "memUsername", value: "id" }}
                                    options={enterprisePerson}
                                    filterOption={(input, option) => {
                                        const { memUsername } = option.props;
                                        return memUsername.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                </Select>
                            </Form.Item>

                            <Form.Item label="合同权益" name="contractRights" extra="机构负责人可以在【小程序-我的项目】中查看合同权益">
                                <Input.TextArea rows={5} placeholder="" maxLength={5000} showCount />
                            </Form.Item>

                            <Form.Item label="赠送权益" name="giftBenefits" extra="机构负责人可以在【小程序-我的项目】中查看赠送权益">
                                <Input.TextArea rows={5} placeholder="" maxLength={5000} showCount />
                            </Form.Item>


                        </>
                    )}
                    <Form.Item label="赞助/赠送展位编号" name="boothNum">
                        <Row gutter={10}>
                            <Col span={20}>
                                <Select
                                    className="place-select-content"
                                    allowClear
                                    showSearch
                                    mode="multiple"
                                    value={selectedBoothNums}
                                    optionLabelProp={"value"}
                                    onChange={handleChangeOrgNum}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        const { name } = option.props.data;
                                        return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                    placeholder="请选择展位编号"
                                >
                                    {boothNums &&
                                        Array.isArray(boothNums) &&
                                        boothNums.map((item) => {
                                            return (
                                                <Option key={item.name} value={item.name} data={item}>
                                                    <div className="place-content" style={{ display: "flex" }}>
                                                        <div className="place-title-name">{item.name}</div>
                                                        <div className="place-right-option">
                                                            <div
                                                                className="right-icon-edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleOrgModal(item);
                                                                }}
                                                            >
                                                                <EditOutlined />
                                                            </div>
                                                            <div
                                                                className="right-icon-delete"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteOrgNum(item);
                                                                }}
                                                            >
                                                                <DeleteOutlined />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </Col>
                            <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                                <Button type="link" onClick={()=>handleOrgModal(null)}>
                                    添加展位编号
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label="备注" name="notes" extra="机构负责人/用户端不可见，方便内部管理">
                        <Input.TextArea rows={5} placeholder="" maxLength={500} showCount />
                    </Form.Item>
                </Form>
            </div>
            <Modal
                title={`${!isEmpty(currentOrgNum) ? "编辑" : "新增"}展位编号`}
                centered
                open={orgNumModalOpen}
                onCancel={() => setOrgNumModalOpen(false)}
                footer={[
                    <Button key="cancel" onClick={() => setOrgNumModalOpen(false)}>
                        取消
                    </Button>,
                    <Button type="primary" key="confirm" disabled={!placeConfirmEnabled} onClick={handleSubmitPlaceForm}>
                        确定
                    </Button>,
                ]}
            >
                <Form form={orgForm}>
                    <Form.Item label="展位编号" name="name" rules={[{ required: true, message: "请输入展位编号" }]}>
                        <Input maxLength={100} placeholder="请输入展位编号" autoComplete="off" onChange={(e) => onChangeOrgName(e)} />
                    </Form.Item>
                </Form>
            </Modal>
        </Drawer>
    );
};
export default AddOrganizationDrawer;
