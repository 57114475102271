import React, { Fragment, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Space, message, Image, Upload,Radio} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { drawerWidth, drawerFormItemLayout } from '@utils/property';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { medProjectFileApi, quickEntriesByNameApi, quickEntriesSaveApi } from '@services/MedProject';
import config from '@config';
import './index.less';
import { uploadFileApi } from '@services/SiteModule';


export default ({
    visibleAddQuickEntriesDrawer,
    setVisibleAddQuickEntriesDrawer,
    currentQuickEntries,
    getQuickEntries,
}) => {
    const params = useParams();
    const projectId = params?.id || 0; // 会议项目 ID
    const [form] = Form.useForm();
    const [radioVal, setRadioVal] = useState(1); // 1-外链，2-文件
    const [docList, setDocList] = useState(null);

    const choseIcons = [
        { img: 'https://mediafiles.cn-healthcare.com/medproject/assets/quick_entry_icons/icon_user%402x.png' },
        { img: 'https://mediafiles.cn-healthcare.com/medproject/assets/quick_entry_icons/icon_users%402x.png' },
        { img: 'https://mediafiles.cn-healthcare.com/medproject/assets/quick_entry_icons/icon_list%402x.png' },
        { img: 'https://mediafiles.cn-healthcare.com/medproject/assets/quick_entry_icons/icon_message%402x.png' },
    ];

    const [imageUrl, setImageUrl] = useState('');

    // 上传图片
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png/jpeg 格式');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不超过2MB');
            return false;
        }
        return isJpgOrPng && isLt2M;
    };
    const radioChange = (e) => {
        setRadioVal(e.target.value);
    };
    // 上传文件路径 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 上传图片
    const upload = async (value) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 21);
        const res = await medProjectFileApi(formData);
        if (res && res.status_code === 200) {
            let icon = res.data;
            setImageUrl(icon);
            form.setFieldValue('iconUrl', icon);
        };
    };
    const uploadFile = async (info) => {
        let formatType = null;
        const isJpgOrPng = info.file.type === "application/vnd.ms-powerpoint" || info.file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || info.file.type === "application/msword" || info.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || info.file.type === "application/pdf" || info.file.type === "application/vnd.ms-excel" || info.file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || info.file.type === "application/zip";
        if (!isJpgOrPng) {
            message.error('只能上传 ppt/excel/pdf/doc/zip格式');
            return false;
        }
        const isLt100M = info.file.size / 1024 / 1024 <= 100;
        if (!isLt100M) {
            message.error("文件大小不能超过100M");
            return;
        }
        let formData = new FormData();
        formData.append("file", info.file);
        formData.append("type", 14);
        const res = await uploadFileApi(formData);
        if (res) {
            let docItem = {
                name: info.file.name,
                path: res.data,
            }
            setDocList([docItem]);
            form.setFieldValue('fileName', docItem.name);
        }
    }
    const onRemoveClick = () => {
        setDocList([]);
        form.setFieldValue('fileName', '');
    }
    // 选择icon
    const changeImageUrl = (url) => {
        setImageUrl(url);
        form.setFieldsValue({
            iconUrl: url
        });
    };

    const closeAddQuickEntriesDrawer = () => {
        form.resetFields();
        setImageUrl('');
        setVisibleAddQuickEntriesDrawer(false);
        setDocList([]);
        setRadioVal(1)
    };

    // 新增/编辑快捷入口
    const onFinish = async (values) => {
        let params = {
            ...values,
            projectId,
            hidden: currentQuickEntries?.hidden===0 ? 0 : 1,
        };
        if(radioVal === 2){
            params.url = docList[0].path;
        }
        params.iconUrl = imageUrl;
        if(currentQuickEntries && currentQuickEntries.id){
            params.id = currentQuickEntries?.id;
        }
        // console.log(params);
        const res = await quickEntriesSaveApi(params);
        if (res.status_code === 200) {
            message.success('快捷入口保存成功');
            setVisibleAddQuickEntriesDrawer(false);
            getQuickEntries();
        }
    };

    useEffect(() => {
        if (currentQuickEntries) {
            console.log(currentQuickEntries);
            let type = currentQuickEntries.newBlankType ?? 1;
            setRadioVal(type);
            form.setFieldsValue({
                title: currentQuickEntries.title,
                iconUrl: currentQuickEntries.iconUrl,
                newBlankType: type,
            });
            if(type === 1 || type === undefined){
                form.setFieldsValue({
                    url: currentQuickEntries.url,
                });
            }else{
                let fileN = currentQuickEntries?.fileName ?? ""
                form.setFieldsValue({
                    fileName: fileN
                });
                let docItem = {
                    name: fileN,
                    path: currentQuickEntries.url,
                }
                setDocList([docItem]);
            }

            setImageUrl(currentQuickEntries.iconUrl);
        }else{
            form.resetFields();
            setImageUrl('');
            setRadioVal(1);
            setDocList([]);
            form.setFieldsValue({
                newBlankType: 1,
                fileName: ""
            });
        }
    }, [visibleAddQuickEntriesDrawer, currentQuickEntries]);

    return (
        <Drawer
            title={currentQuickEntries ? `快捷入口配置：${currentQuickEntries?.title}` :'新增快捷入口'}
            width={drawerWidth}
            open={visibleAddQuickEntriesDrawer}
            onClose={closeAddQuickEntriesDrawer}
            destroyOnClose={true}
            maskClosable={false}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeAddQuickEntriesDrawer}>取消</Button>
                        <Button type="primary" form="quickEntriesForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="quickEntriesForm"
                form={form}
                autoComplete="off"
                {...drawerFormItemLayout}
                onFinish={onFinish}
            >

                <Form.Item
                    name="title"
                    label="快捷入口名称"
                    rules={[
                        { required: true, message: '请填写快捷入口名称' },
                        { min: 2, message: '名称不少于2个字符' },
                        { max: 6, message: '名称不多于6个字符' },
                        {
                            validateTrigger: ['onFinish'],
                            validator: async (_, value) => {
                                if(!value){
                                    return
                                }
                                // console.log('重复验证');
                                let reqParams = {
                                    title: value,
                                    projectId
                                };
                                if(currentQuickEntries?.id){
                                    reqParams.id = currentQuickEntries.id
                                }
                                let res = await quickEntriesByNameApi(reqParams);
                                if (!res.data) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('快捷入口名称重复');
                            },
                        }
                    ]}
                >
                    <Input placeholder="请输入快捷入口名称"  minLength={2} maxLength={6} showCount />
                </Form.Item>

                <Form.Item
                    label='快捷入口icon'
                    name="iconUrl"
                    // help="图片尺寸 30*30px，支持 JPG/PNG格式，大小不超过2MB"
                    rules={[
                        { required: true, message: '请上传/选择快捷入口icon' },
                    ]}
                >
                    <>
                        <div className='uploadIconBox'>
                            <Upload
                                // name="avatar"
                                customRequest={(e) => upload(e)}
                                listType="picture-card"
                                fileList={[]}
                                showUploadList={{ showRemoveIcon: false }}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg, image/png, image/jpg"
                                className='uploadWap'
                            >
                                {
                                    imageUrl ?
                                        <div className="imageWarp">
                                            <img style={{ width: '100%' }} src={getFileUrl(imageUrl)} alt="" />
                                        </div>
                                    : <div>
                                        <PlusOutlined />
                                        <div
                                            style={{
                                                marginTop: 8,
                                            }}
                                        >
                                            上传图片
                                        </div>
                                    </div>
                                }

                            </Upload>

                            <div className='uploadImageTips'>图片尺寸 30*30px，支持 JPG/PNG格式，大小不超过2MB</div>
                        </div>
                        <div className="iconLists">
                            {choseIcons.map((item) => {
                                return (
                                    <div className={item.img===imageUrl ? 'iconAct' : null} onClick={ () => changeImageUrl(item.img) }>
                                        <img src={item.img} alt="icon" />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                </Form.Item>

                <Form.Item
                    name="newBlankType"
                    label="入口跳转设置"
                    rules={[
                        { required: true},
                    ]}
                >
                    <Radio.Group onChange={radioChange} value={radioVal}>
                        <Radio value={1}>链接</Radio>
                        <Radio value={2}>文件</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    radioVal === 1 && <Form.Item
                        name="url"
                        label="链接地址"
                        rules={[
                            { required: true, message: '请填写链接地址' },
                        ]}
                    >
                        <Input placeholder="请填写链接地址"/>
                    </Form.Item>
                }
                {
                    radioVal === 2 && <Form.Item
                    label="上传文件"
                    name="fileName"
                    extra="仅支持上传一个文件，格式支持 Word/Excel/PowerPoint/PDF/Zip，大小不超过 100MB"
                    rules={[
                        { required: true, message: '请填写链接地址' },
                    ]}
                >
                    <>
                        <Upload customRequest={uploadFile} name="fileName" maxCount={1} fileList={docList} onRemove={onRemoveClick}>
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                        </Upload>
                    </>
                </Form.Item>
                }


            </Form>
        </Drawer>
    );
};
