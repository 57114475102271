import React, { useRef, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Input, Form, Checkbox, Space, Button } from 'antd';
import { PlusOutlined, MinusCircleFilled } from '@ant-design/icons';

export default function CheckboxPart({
    index, element, handleClickDel, setInput, inputValue, list, handleDeleteOption, handleAddOption, moveList
}) {
    const dragRef = useRef(null);
    const [, drag] = useDrag({
        type: 'child',
        item: { index, element }
    });
    const [, drop] = useDrop({
        accept: 'child',
        drop: (item) => {
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return
            }
            moveList(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });


    // 选项的input进行删除
    const setOption = useCallback(
        (index, optionIndex) => {
            return (e) => {
                list[index].optionlist[optionIndex].content = e.target.value;
            }
        },
        [list],
    );

    // 点击删除选项
    const handleDelOption = useCallback(
        (index, optionIndex) => {
            return () => {
                handleDeleteOption(index, optionIndex);
            }
        },
        [handleDeleteOption],
    );

    // 点击添加选项
    const handleAddOptions = useCallback(
        (index) => {
            return () => {
                handleAddOption(index);
            }

        },
        [handleAddOption],
    );

    return (
        <div ref={drag(drop(dragRef))} className="checkbox-text">
            <div className="radio-text-top">
                <Form.Item className="radio-text-center">
                    <span className="radio-text-num">{index + 1}、</span>
                    <Input placeholder={inputValue} value={element.name} onChange={setInput(index)} />
                </Form.Item>
                <Space className="radio-text-button">
                    <Button type="primary">拖动</Button>
                    <Button type="primary" onClick={handleClickDel}>删除</Button>
                </Space>
            </div>
            <div className="radio-text-content">
                <Checkbox.Group style={{ width: '100%' }}>
                    <Space direction="vertical">
                        {element.optionlist && element.optionlist.map((elem, optionIndex) => {
                            return (
                                <div key={elem.id} className="radio-text-content-part">
                                    <Checkbox value={optionIndex + 1}>
                                        <Input defaultValue={elem.content} onChange={setOption(index, optionIndex)} placeholder="选项"></Input>
                                    </Checkbox>
                                    {element.optionlist.length > 2 ? <MinusCircleFilled className="delete-icon" onClick={handleDelOption(index, optionIndex)} /> : <></>}
                                </div>

                            )
                        })}
                    </Space>
                </Checkbox.Group>
                <div className="add-option-list" onClick={handleAddOptions(index)}><PlusOutlined /> 添加选项</div>
            </div>
        </div>
    )
}
