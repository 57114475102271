import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Button, Layout, Table, Modal, Space, message, Upload, Form } from 'antd';
import LecturersFilterForm from './LecturersFilterForm';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { get, post, del } from '@/core/request';
import './Lecturers.less';
import data from '@utils/areas';

const { Content } = Layout;

export default function Lecturers() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});
    // 产品线
    const [productIds, setProductId] = useState([]);
    // 所属企业
    const [orgIds, setOrgId] = useState([]);
    // 科室
    const [departmentData, setDepartmentData] = useState([]);
    // 职称
    const [allTitle, setAllTitle] = useState([]);
    const [updatedByStaffIds, setUpdatedByStaffId] = useState([]);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setProductId(res.data);
        })
    }, []);
    // 科室
    useEffect(() => {
        get(`/dms/doctor/tags/allTags`).then((res) => {
            setDepartmentData(res.data);
        })
    }, []);

    // 职称
    useEffect(() => {
        get(`/dms/doctor/title/allTitles`).then((res) => {
            setAllTitle(res.data);
        })
    }, []);

    // 所属企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    // 操作人
    useEffect(() => {
        get('/dms/users/findAll').then((res) => {
            setUpdatedByStaffId(res.data);
        })
    }, []);

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, phone, provinceCode, cityCode, updatedByStaffId, productId, orgId, importStartTime, importEndTime, gender, departmentId, titleId } = filterObj;

    const getData = () => {
        get(`/dms/users/doctor-queryPage`, {
            limit: pageSize,
            page: currPage,
            name: name,
            phone: phone,
            provinceCode: provinceCode,
            cityCode: cityCode,
            updatedByStaffId: updatedByStaffId,
            productId: productId,
            orgId: orgId,
            importStartTime: importStartTime,
            importEndTime: importEndTime,
            gender: gender,
            departmentId: departmentId,
            titleId: titleId
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }
    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, name, phone, provinceCode, cityCode, updatedByStaffId, productId, orgId,
        importStartTime, importEndTime, gender, departmentId, titleId]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const navigate = useNavigate();
    const changePath = (tip, row) => {
        return () => {
            if (tip === 'add') {
                navigate(`/dms/lecturers/new`, { state: { productIds, orgIds, departmentData, allTitle, data, tip } });
            } else {
                navigate(`/dms/lecturers/${row.id}`, { state: { productIds, orgIds, departmentData, allTitle, data, tip, row } });
            }

        }
    };

    const columns = [
        { title: 'ID', dataIndex: 'id' },
        { title: '姓名', dataIndex: 'name' },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '省份', dataIndex: 'provinceName', },
        { title: '城市', dataIndex: 'cityName', },
        { title: '科室', dataIndex: 'departmentName', },
        { title: '职称', dataIndex: 'titleName', },
        { title: '上传时间', dataIndex: 'createdAt', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '操作人', dataIndex: 'updatedByStaffName' },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={changePath('edit', row)}>编辑</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除讲师',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/users/deleteBatch', {
                        ids: [row.id]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中讲师',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/users/deleteBatch', {
                        ids: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何讲师');
                }
            }
        });
    };

    // 下载模板
    const downloadFile = () => {
        get(`/dms/users/downloadFile`, {}, { responseType: 'blob' }).then((res) => {
            if (res.size) {
                message.success('请稍后，模板正在下载');
                const fileName = "导入模板.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            }
        })
    }

    // 导出数据
    const handleExportFile = () => {
        get(`/dms/users/download`, {
            limit: pageSize,
            page: currPage,
            name: name,
            phone: phone,
            provinceCode: provinceCode,
            cityCode: cityCode,
            updatedByStaffId: updatedByStaffId,
            productId: productId,
            orgId: orgId,
            importStartTime: importStartTime,
            importEndTime: importEndTime,
            gender: gender,
            departmentId: departmentId,
            titleId: titleId
        }, { responseType: 'blob' }).then((res) => {
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                const fileName = "讲师数据.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    // 导入相关modal
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setSize(0)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSize(0)
    };

    const [size, setSize] = useState(0);
    // 批量上传文件
    const uploadProps = {
        name: 'file',
        beforeUpload: file => {//控制上传文件格式
            const isFile = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel';
            if (!isFile) {
                message.error('你只能上传Excel格式文件!');
                return;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('文件大小不能超过20M');
            }
            setSize(file.size)
            return isFile && isLt20M;
        },
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {//手动上传
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/dms/users/importExcel", formData, option).then((res) => {
                if (typeof res !== 'string') {
                    if (res.status_code === 500) {
                        message.error(res.data.message, 5);
                    } else if (res.data.size === 0) {
                        message.error(res.data.tips, 5);
                    } else if (res.data.size > 0) {
                        message.success({
                            content: <div>
                                <p>导入成功。共导入{res.data.size}条名单</p>
                                <p>{res.data.tips}</p>
                            </div>
                        }, 8);
                        getData()
                    } else if (res.data.size === -1) {
                        message.success(res.data.tips);
                        getData()
                    }
                }
            })
        },
    };

    return (
        <Fragment>
            <div className="page-title">机构医生管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <LecturersFilterForm
                        handleFilterFinish={handleFilterFinish}
                        updatedByStaffId={updatedByStaffIds}
                        productId={productIds}
                        orgId={orgIds}
                        departmentData={departmentData}
                        allTitle={allTitle}
                        data={data}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">机构医生列表</div>

                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary"><span onClick={changePath('add')}>添加医生</span></Button>
                                <Button type="default" onClick={showModal}>批量导入</Button>
                                <Button type="default" onClick={handleExportFile}>导出</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="playback-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

            {
                isModalVisible && <Modal title="用户名单导入" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Form className="upload-form">
                        <Space>
                            <Upload {...uploadProps}>
                                <Button type="primary">选取文件</Button>
                            </Upload>
                            <div className="download">
                                <div id="download" onClick={downloadFile} className="operate-style">模板下载</div>
                                {size ? <span>总大小：{size}kb</span> : <></>}
                            </div>
                        </Space>
                    </Form>
                    <div>1、仅支持上传Excel格式文件，且大小不能超过20M</div>
                    <div>2、为保证上传成功，请勿对模板中的单元格进行更改</div>
                    <div>3、依次仅支持一份文件上传，多份文件将会替换原文件</div>
                </Modal>
            }


        </Fragment>
    )
}
