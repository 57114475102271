import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Button, Layout, Modal, Tree, Input, message, Dropdown, Menu, Space } from 'antd';
import AddDepartmentList from './AddDepartmentList';
import DepartmentSortDrawer from './DepartmentSortDrawer';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TreeDataSource as TreeDataSource1 } from '@utils/utils';
import { get, put, post, delte } from '@/core/request';
import './DepartmentList.less';

const { Content } = Layout;

export default function DepartmentList() {

    // 新增modal相关
    const [isModalVisible, setIsModalVisible] = useState(false);
    // 渲染分类的树状结构
    const [departmentTree, setDepartmentTree] = useState([]);
    const [oldDepartmentTree, setOldDepartmentTree] = useState([]);

    // 点击删除
    const deleteConfirm = (element) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确认删除？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                let form = new FormData();
                form.append("id", element.id)
                delte('/enterprise/departments/delete', form).then(res => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getData();
                    }
                })
            }
        });
    };

    const [saveKey, setSaveKey] = useState([]);
    const [openKey, setOpenKey] = useState([]);

    // 默认展开和默认关闭
    const handleOpenKeys = (tip) => {
        return () => {
            if (tip === 'open') {
                setOpenKey(saveKey);
            } else {
                setOpenKey([]);
            }
        }
    };

    const handleExpand = (expandedKeys) => {
        setOpenKey(expandedKeys);
    }

    const [modalData, setModalData] = useState({
        tip: '',
        row: {}
    })

    const showModal = (tip, elem) => {
        return () => {
            setModalData({
                tip,
                row: elem
            });
            setIsModalVisible(true);
        }
    }

    // 点击添加子部门和删除
    const handleMenuClick = (elem) => {
        return (e) => {
            if (e.key === 'addChild') {
                setIsModalVisible(true);
                setModalData({
                    tip: 'addChild',
                    row: elem
                });
            } else if (e.key === 'delete') {
                if (elem.children) return message.error('该部门存在子部门或员工，禁止删除！');
                deleteConfirm(elem)
            }
        }
    }

    const handleMouseEnter = (e) => {
        if (e.target.className === 'edit') {
            e.target.classList.add('mystyle')
        }
    }

    const handleMouseLeave = (e) => {
        e.target.classList.remove('mystyle')
    }

    const handleMouseLeaveInner = (e) => {
        e.currentTarget.parentNode.classList.remove('mystyle')
    }

    const rendertitle = (left, element) => {
        const menu = (
            <Menu onClick={handleMenuClick(element)}>
                <Menu.Item key="addChild">添加子部门</Menu.Item>
                <Menu.Item key="delete">删除</Menu.Item>
                <Menu.Item key="show" disabled>部门Id:{element.id}</Menu.Item>
            </Menu>
        );
        return (
            <div className="edit" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {element.name}
                {/* <span className='department-num'>[{element.nums}人]</span> */}
                <div className='tree-edit' style={{ left }} onMouseLeave={handleMouseLeaveInner}>
                    <Dropdown.Button onClick={showModal('edit', element)} overlay={menu} size='small'>
                        编辑
                    </Dropdown.Button>
                </div>
            </div>
        )
    };

    // 递归遍历数组 修改元素
    const TreeDataSource = (arr) => {
        if (!Array.isArray(arr)) { return; }
        return arr.map((item) => {
            saveKey.push(item.id)
            return {
                ...item,
                title: rendertitle('320px', item),
                key: item.id,
                children: item.childList?.length && TreeDataSource(item.childList)
            }
        })
    }

    const [arr, setArr] = useState([])
    const getData = () => {
        let arr = []
        get(`/enterprise/departments/get_all`).then((res) => {
            if (res.status_code === 200) {
                arr = TreeDataSource(res.data);
                setArr(TreeDataSource1(res.data));
                setOpenKey(saveKey);
                setOldDepartmentTree(TreeDataSource1(res.data));
                setDepartmentTree(arr);
            }
        })
    }

    // 渲染treeList数据
    useEffect(() => {
        getData()
    }, []);


    // 新增部门
    const handleAddDepartment = useCallback((value) => {
        post(`/enterprise/departments/save`, value).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    }, [])

    // 编辑/添加子部门
    const handleEditDepartment = useCallback((value) => {
        put(`/enterprise/departments/update`, value).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    }, [])

    const drawer = useRef()
    // 部门排序
    const handleSortDepartment = () => {
        drawer.current.show()
    }

    return (
        <Fragment>
            <div className="page-title">部门管理</div>

            <Content className="main-content-box">

                <div className="main-content-body">

                    <div className="table-caption">
                        <Space>
                            <Button type="primary" onClick={showModal('add')}>新增部门</Button>
                            <Button onClick={handleSortDepartment}>部门排序</Button>
                            <Button onClick={handleOpenKeys('open')}>全部展开</Button>
                            <Button onClick={handleOpenKeys('close')}>全部收起</Button>
                        </Space>

                    </div>

                    {
                        departmentTree.length > 0 && <Tree
                            blockNode
                            expandedKeys={openKey}
                            treeData={departmentTree}
                            onExpand={handleExpand}
                        />
                    }

                </div>
            </Content>

            {/* 点击新增的modal */}
            {
                isModalVisible && <Modal
                    visible={isModalVisible}
                    closable={false}
                    footer={null}>
                    <AddDepartmentList
                        modalData={modalData}
                        oldDepartmentTree={oldDepartmentTree}
                        handleAddDepartment={handleAddDepartment}
                        setIsModalVisible={setIsModalVisible}
                        handleEditDepartment={handleEditDepartment}
                    ></AddDepartmentList>
                </Modal>
            }

            {/* 部门排序drawer */}
            <DepartmentSortDrawer ref={drawer} oldDepartmentTree={oldDepartmentTree} arr={arr} getData={getData}></DepartmentSortDrawer>

        </Fragment>
    )
}
