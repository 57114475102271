import React, { Fragment, useEffect, useState } from "react";
import { Pagination } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined } from "@ant-design/icons";
import ChartsView from "../ChartsView";
import ChartsViewScorll from "../ChartsViewScorll";
import "./index.less";

const RcpCom = ({ data, type, count }) => {
    const pageOptions = [5, 10, 15, 20];
    const [pageData, setPageData] = useState([]);

    const onChange = (pageNumber, pageSize) => {
        if (pageNumber === 1) {
            let show = data.slice(0, pageSize);
            setPageData([...show]);
        } else {
            let show = data.slice(pageSize * (pageNumber - 1), pageSize * pageNumber);
            setPageData([...show]);
        }
    };

    useEffect(() => {
        if (type === 13) {
            let show = data.slice(0, 5);
            setPageData([...show]);
        }
    }, []);

    return (
        <div className="answer_rcp_wrap">
            {type === 8 && <p>非常不满意</p>}
            {type !== 13
                ? data?.length
                    ? data.map((item, index) => (
                            <div className="list_item" key={index}>
                                <p>{item?.content || ""}</p>
                                <span>{item?.num || 0}</span>
                                <span>{count ? parseFloat((((item?.num || 0) / count) * 100).toFixed(2)) + "%" : "0%"}</span>
                            </div>
                        ))
                    : null
                : pageData?.length
                    ? pageData.map((item, index) => (
                        <div className="list_item" key={index}>
                            <p>{item?.content || ""}</p>
                            <span>{item?.num || 0}</span>
                            <span>{count ? parseFloat((((item?.num || 0) / count) * 100).toFixed(2)) + "%" : "0%"}</span>
                        </div>
                    ))
                : null}
            {type === 13 && <Pagination className="select-page" total={data.length} showSizeChanger showQuickJumper showTotal={(total) => `共 ${total} 条`} onChange={onChange}  pageSizeOptions={pageOptions} defaultPageSize={5}/>}
            {type === 8 && <p>非常满意</p>}
            {type === 13 ? <ChartsViewScorll data={data} type={type}></ChartsViewScorll> : <ChartsView data={data} type={type}></ChartsView>}
        </div>
    );
};

export default RcpCom;
