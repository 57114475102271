import React, { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import { drawerWidth } from '@utils/property';
import { Form, Input, Select, Space, Button, Radio, Drawer, message, TreeSelect } from 'antd';
import { get, put, post } from '@/core/request';
import { getTreeObj } from '@utils/utils';
const { TextArea } = Input;

function PermissionAdd({ getData }, ref) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editState, setEditState] = useState({
        tip: '',
        row: null
    })
    // 选择菜单权限时，前端路由为必填项，否则为选填项
    const [radioState, setRadioState] = useState(0)
    const [form] = Form.useForm();
    const [moduleState, setModuleState] = useState({
        moduleList: [],
        groupList: null
    })

    const TreeDataSource = (arr) => {
        if (!Array.isArray(arr)) { return; }
        return arr.map((item) => {
            return {
                ...item,
                title: item.groupName,
                value: item.groupId,
                key: item.groupId,
                children: item.secondGroupList && TreeDataSource(item.secondGroupList)
            }
        })
    }

    const handleModuleChange = (value) => {
        let obj = getTreeObj(moduleState.moduleList, [value])
        if (obj) {
            let arr = TreeDataSource(obj.groupList)
            setModuleState({
                ...moduleState,
                groupList: arr
            })
        }

        form.setFieldsValue({
            groupId: undefined
        })
    }

    useImperativeHandle(ref, () => ({
        show(tip, row) {
            let moduleList = [];
            get(`/enterprise/permissions/get_group_modules`).then((res) => {
                if (res.status_code === 200) {
                    res.data.forEach(item => {
                        moduleList.push(item);
                    })
                    setModuleState({
                        moduleList: moduleList,
                        groupList: []
                    })
                    if (tip === 'edit') {
                        get(`/enterprise/permissions/get`, { id: row.id }).then((res) => {
                            if (res.status_code === 200) {
                                const { name, displayName, description, groupId, type, route, apiRoute, apiMethod, moduleCode } = res.data;
                                let obj = getTreeObj(moduleList, [moduleCode])
                                if (obj) {
                                    let arr = TreeDataSource(obj.groupList)
                                    setModuleState({
                                        ...moduleState,
                                        moduleList: moduleList,
                                        groupList: arr
                                    })
                                }
                                form.setFieldsValue({
                                    name, displayName, description, groupId: groupId === 0 ? undefined : groupId, type, route, apiRoute, apiMethod, moduleCode
                                })
                            }
                        });
                    }
                }
            });
            setEditState({
                tip, row
            })
            setIsModalVisible(true);
        }
    }));

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                values.weight = 0;
                if (editState.tip === 'add') {
                    post(`/enterprise/permissions/save`, values).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else if (editState.tip === 'edit') {
                    values.id = editState.row.id
                    put(`/enterprise/permissions/update`, values).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
                handleClose();
            })
            .catch((err) => { });
    }

    const handleClose = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    const onRadioChange = (e) => {
        setRadioState(e.target.value)
    }
    const drawerFormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
    };
    return (
        <Fragment>
            {
                isModalVisible && <Drawer
                    title={editState.tip === 'add' ? '新增权限' : '编辑权限'}
                    width={drawerWidth}
                    visible={isModalVisible}
                    bodyStyle={{ paddingBottom: 80 }}
                    onClose={handleClose}
                    footer={
                        <div style={{ textAlign: "right" }}>
                            <Space>
                                <Button onClick={handleClose}>取消</Button>
                                <Button type="primary" onClick={handleOk}>
                                    确定
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <Form {...drawerFormItemLayout} form={form}>
                        <Form.Item
                            label="权限名称"
                            name="name"
                            help="权限名称为该权限的唯一识别代号，用于权限控制"
                            rules={[{ required: true, message: '输入权限名称' }]}
                        >
                            <Input placeholder="输入权限名称" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="显示名称"
                            name="displayName"
                            rules={[{ required: true, message: '输入权限显示名称' }]}
                        >
                            <Input placeholder="输入权限显示名称" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item label="权限描述" name="description">
                            <TextArea
                                placeholder="输入权限描述"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                showCount maxLength={50}
                            />
                        </Form.Item>

                        <Form.Item label="所属业务模块" name="moduleCode" rules={[{ required: true, message: '选择所属业务模块' }]}>
                            <Select allowClear placeholder="选择所属业务模块" onChange={handleModuleChange}>
                                {
                                    moduleState.moduleList && moduleState.moduleList.map(item => {
                                        return <Select.Option key={item.moduleId} value={item.moduleId}>{item.moduleName}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label="所属权限分组" name="groupId" rules={[{ required: true, message: '选择权限分组' }]}>
                            {
                                editState.tip === 'add' ? <TreeSelect
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={moduleState.groupList}
                                    placeholder="选择权限分组"
                                /> : moduleState.groupList && <TreeSelect
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={moduleState.groupList}
                                    placeholder="选择权限分组"
                                />
                            }
                        </Form.Item>

                        <Form.Item label="权限类型" name="type"
                            help="菜单权限用于左侧导航菜单及权限控制，功能权限权适用于细粒度权限控制，数据权限适用于业务耦合的数据是否可用的权限控制"
                            rules={[{ required: true, message: '选择权限类型' }]}>
                            <Radio.Group onChange={onRadioChange}>
                                <Radio value={1}>菜单权限</Radio>
                                <Radio value={2}>功能权限</Radio>
                                <Radio value={3}>数据权限</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="前端路由"
                            name="route"
                            required={radioState === 1 && true}
                        >
                            <Input placeholder="输入前端路由" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="后端接口路由"
                            name="apiRoute"
                        >
                            <Input placeholder="输入后端路由" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item label="接口请求方法" name="apiMethod">
                            <Select allowClear placeholder="选择后端接口请求方法">
                                <Select.Option value='GET'>GET</Select.Option>
                                <Select.Option value='POST'>POST</Select.Option>
                                <Select.Option value='PUT'>PUT</Select.Option>
                                <Select.Option value='DELETE'>DELETE</Select.Option>
                            </Select>
                        </Form.Item>

                    </Form>
                </Drawer>
            }
        </Fragment>

    )
}
export default forwardRef(PermissionAdd)
