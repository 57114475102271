import { Link, useNavigate, useParams } from 'react-router-dom';
import { DragOutlined, ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
    Input,
    Layout,
    Space,
    Button,
    Select,
    Table,
    Row,
    Modal,
    Drawer,
    Form,
    Switch,
    Col,
    message,
    Checkbox, Divider
} from 'antd';
import './index.less';
import { getAddTrainingListApi, changeContentModuleContentsState, removeContentModuleContents, fetchContentModuleContents, addContentModuleContents } from '@services/MedProject';
import config from '@config';

const { Content } = Layout;

let initData = []; // 初始列表数据 - 用于页面筛选搜索

export default function ModulesActivities() {
    const navigate = useNavigate();
    const params = useParams();
    const projectId = params?.projectId || 0;
    const moduleId = params?.id || 0;

    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();

    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [addProjectData, setAddProjectData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [listPage, setListPage] = useState(1);
    const [listPageSize, setListPageSize] = useState(10);
    const [listTotal, setListTotal] = useState(0);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const onClick = ({ key }) => {
        window.open(`${window.location.origin}${key}`);
    };

    const items = [
        {
            label: '医会议',
            key: '/conf/conferences',
        },
        {
            label: '医调研',
            key: '/wj/surveys',
        },
        {
            label: '医评选',
            key: '/selection/projects',
        },
    ];

    const columns = [
        {
            title: '学苑名称',
            dataIndex: 'orgName',
            key: 'orgName',
            render: (_, record) => (
                <>{record?.baseVO?.orgName}</>
            ),
        },
        {
            title: '培训项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: '创建人',
            dataIndex: 'createdName',
            key: 'createdName',
            width: 100,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (_, record) => (
                getActivityStatus(record?.baseVO?.status)
            ),
        },
        {
            title: '显示/隐藏',
            key: 'hidden',
            width: 90,
            render: (_, record) => (
                <Switch
                    checked={!record.hidden}
                    onChange={() => onHiddenChange(record)}
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                />
            ),
        },
        {
            title: '推荐到主页',
            key: 'recommended',
            width: 90,
            render: (_, record) => (
                <Switch
                    checked={record.recommended}
                    onChange={() => onRecommendedChange(record)}
                    checkedChildren="推荐"
                    unCheckedChildren="不推荐"
                />
            ),
        },
        {
            title: '操作',
            key: 'action',
            width: 150,
            render: (_, record) => (
                <span className="operation-btns">
                    {/* <Button type="link" size="small" onClick={() => viewDetails(record)}>查看详情</Button>
                    <Divider type="vertical" /> */}
                    <Button type="link" size="small" onClick={() => onDelete(record.id)}>移除</Button>
                </span>
            ),
        },
    ];

    const addColumns = [
        {
            title: '学苑名称',
            dataIndex: 'orgName',
            key: 'orgName',
        },
        {
            title: '培训项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: '创建人',
            dataIndex: 'createdName',
            key: 'createdName',
            width: 100,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (_, record) => (
                getActivityStatus(record?.status)
            ),
        },
    ];

    // 显示/隐藏
    const onHiddenChange = async (record) => {
        if (record.recommended === 1 && record.hidden === 0) {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                centered: true,
                content: '当前培训项目已推荐到主页，设置隐藏后，项目主页上此培训项目也将进行隐藏。',
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    const res = await changeContentModuleContentsState({ id: record.id, hidden: record.hidden ? 0 : 1 });
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getTrainingList();
                    }
                }
            });
        } else {
            const res = await changeContentModuleContentsState({ id: record.id, hidden: record.hidden ? 0 : 1 });
            if (res.status_code === 200) {
                message.success(res.message);
                getTrainingList();
            }
        }
    };


    // 推荐
    const onRecommendedChange = async (record) => {
        if (record.hidden === 1 && record.recommended === 0) {
            return message.error('当前培训项目已隐藏，不能推荐到首页，可以设置显示后再推荐。');
        }
        const res = await changeContentModuleContentsState({ id: record.id, recommended: record.recommended ? 0 : 1 });
        if (res.status_code === 200) {
            message.success(res.message);
            getTrainingList();
        }
    };


    // 查看详情
    const viewDetails = (record) => {
        return window.open(`${record?.baseVO?.jumpUrl}`);
    };

    // 删除培训项目
    const onDelete = (delId) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: '移除后，该培训项目在用户端也将下架，请确认是否移除？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await removeContentModuleContents(delId);
                if (res.status_code === 200) {
                    message.success('移除成功');
                    getTrainingList();
                }
            }
        });
    }

    // 培训项目状态
    const getActivityStatus = (type) => {
        switch (type) {
            case 1:
                return '进行中';
            case 2:
                return '已结束';
            case 3:
                return '未开始';
            case 4:
                return '已删除';
            default:
                return '';
        }
    };

    // 新增项目 - 保存
    const handleOk = async () => {
        if (!selectedRowKeys.length) {
            return message.error('请选择关联项目');
        }

        const params = {
            contentType: 'training',
            itemIds: [...selectedRowKeys],
            moduleId,
            projectId
        };
        const res = await addContentModuleContents(params);
        if (res.status_code === 200) {
            message.success('新增成功');
            handleCancel();
            getTrainingList();
        }
    };

    // 新增项目弹框 - 关闭
    const handleCancel = () => {
        setIsModalOpen(false);
        addForm.resetFields();
        setAddProjectData([]);
        setSelectedRowKeys([]);
        setTotal(0);
    };

    // 新增培训项目 - 查询培训项目类型数据
    const getAddActivitiesList = async (values) => {
        setLoading(true);
        const res = await getAddTrainingListApi({ page, pageSize, moduleId, ...values });
        setLoading(false);
        if (res.status_code === 200 && res.data) {
            setAddProjectData(res.data.list || []);
            setTotal(res.data.totalCount);
        }
    };

    // 添加培训项目 - 搜索
    const onSearch = () => {
        addForm.validateFields().then((values => {
            getAddActivitiesList(values);
        }));
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (nowSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(nowSelectedRowKeys);
        },
        getCheckboxProps: (record) => ({ disabled: record.hasData }) // 禁用
    };

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };

    // 页码改变
    const onListChangePage = (a, b) => {
        setListPage(a);
        setListPageSize(b);
    };

    // 项目列表
    const getTrainingList = async () => {
        const reqParams = {
            moduleId,
            page: listPage,
            pageSize: listPageSize,
            orgName: searchForm.getFieldValue('orgName'),
            projectName: searchForm.getFieldValue('projectName'),
            recommended: searchForm.getFieldValue('recommended'),
            contentType: 'training'
        }
        // console.log(reqParams);
        setListLoading(true);
        const res = await fetchContentModuleContents(reqParams);
        setListLoading(false);
        if (res.status_code === 200 && res.data) {
            setData(res.data.list);
            setListTotal(res.data.totalCount);
        }
    };

    // 回车搜索
    const onPressEnter = (e) => {
        getTrainingList();
    };

    const trainingCheck = (e) => {
        if (e.target.checked) {
            searchForm.setFieldValue('recommended', 1);
        } else {
            searchForm.setFieldValue('recommended', undefined);
        }
        getTrainingList();
    };

    useEffect(() => {
        const values = addForm.getFieldsValue() || {};
        getAddActivitiesList(values);
    }, [page, pageSize]);

    useEffect(() => {
        getTrainingList();
    }, [listPage, listPageSize]);

    useEffect(() => {
        if (isModalOpen) { 
            getAddActivitiesList();
        }
    }, [isModalOpen]);

    return (
        <>
            <Content className="main-content-box">
                <div className="main-content-body">

                    <div className="med-project-module-head">
                        <div className="module-title">
                            <h2>培训学习</h2>
                            <span className="tips">可以添加健学云-培训项目（不是学苑的学员可自主申请成为学员，<em>项目首页最多可推荐10条数据</em></span>
                        </div>
                        <div className="preview">
                            <Button
                                type="link"
                                size="small"
                                ghost
                                style={{ padding: 0 }}
                                onClick={() => window.open(`${config.PROJECT_DOMAIN}/p/${projectId}/trainings/${moduleId}`)}
                                icon={<>
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                        </svg>
                                    </span>
                                </>}
                            >
                                <span>预览</span>
                            </Button>
                        </div>
                    </div>

                    <div className="med-project-module-toolbar">
                        <div className="main-tools">
                            <Form form={searchForm}>
                                <Row>
                                    <Col style={{ marginLeft: '10px' }}>
                                        <Form.Item name="recommended" label="">
                                            <Checkbox onChange={trainingCheck}>只看首页推荐</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="sub-tools">
                            <Space>
                                <Button type="primary" onClick={() => setIsModalOpen(true)}>添加培训项目</Button>
                            </Space>
                        </div>
                    </div>

                    <div className="med-project-module-content">
                        <Table
                            columns={columns}
                            dataSource={data}
                            loading={listLoading}
                            pagination={{
                                total: listTotal,
                                showTotal: () => `共${listTotal}条`,
                                current: listPage,
                                pageSize: listPageSize,
                                onChange: onListChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                        />
                    </div>

                </div>
            </Content>

            <Modal
                title="添加培训项目"
                open={isModalOpen}
                className="add-activities-modal"
                centered
                maskClosable={false}
                width={900}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div style={{ marginBottom: '10px' }}>选择培训项目内容</div>
                <Form
                    form={addForm}
                    name="basic"
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={6} style={{ marginRight: 10 }}>
                            <Form.Item label="" name="orgName">
                                <Input placeholder="请输入学苑名称" />
                            </Form.Item>
                        </Col>

                        <Col span={6} style={{ marginRight: 10 }}>
                            <Form.Item label="" name="projectName">
                                <Input placeholder="请输入培训项目名称" />
                            </Form.Item>
                        </Col>

                        <Col span={2} style={{ marginRight: 10 }}>
                            <Button type="primary" onClick={onSearch}>搜索</Button>
                        </Col>
                    </Row>
                </Form>

                <Table
                    rowKey={record => record.id}
                    rowSelection={{ ...rowSelection }}
                    columns={addColumns}
                    dataSource={addProjectData}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                    loading={loading}
                    size="small"
                    scroll={{
                        y: 240,
                    }}
                />
            </Modal>

        </>
    );
}
