import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { SearchOutlined,ArrowLeftOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Layout, Form, Row, Col, Input, Button, Space, Tooltip, Select, Table,DatePicker } from 'antd';
import { filterFormItemLayout, filterFormGridLayout, pageFormItemWithoutLabelGridLayout, drawerWidth } from '@utils/property';
import { useLocation } from 'react-router-dom';
import { get, del } from '@/core/request';
import moment from 'moment';

const { Content } = Layout;
export default function BiChannelUsers() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const { RangePicker } = DatePicker;
    const [form1] = Form.useForm();
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    let [num, setNum] = useState(0);
    let location = useLocation();
    let pathname = location.state;
    let [falg, setFalg] = useState(true);
    const [dataSource, setDataSource] = useState(null);
    const [formData, setFormData] = useState({ startTime: "", endTime: "", memCard: "", memNickname: "", memMobile: "", memDeviceType: "", contentId: "", contentType: "", contentTitle: "" });
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [showElement,setShowElement] = useState(false)
    const [sourceType,setSourceType] = useState(null);
    useEffect(() => {
        setStart(pathname.startTime)
        setEnd(pathname.endTime)
        setNum(++num)
    }, [])

    const onReset = () => {
        form1.resetFields();
        form1.setFieldsValue({ time: "" })
        setFalg(false)
        setPage(1)
        setNum(++num)
        setFormData({ ...formData, startTime: "", endTime: "", memCard: "", memNickname: "", memMobile: "", memDeviceType: "", contentId: "", contentType: "", contentTitle: "" })
    };
    const columns = [
        { title: '用户唯一标识', dataIndex: 'memCard', },
        {
            title: '用户昵称', dataIndex: 'memNickname',
        },
        {
            title: '手机号', dataIndex: 'memMobile',
        },
        {
            title: '邮箱', dataIndex: 'memEmail',
        },
        {
            title: '注册时间', dataIndex: 'memRegistTime',
        },
        {
            title: '注册端口', dataIndex: 'memDeviceType',
        },
        {
            title: '最后登录时间', dataIndex: 'memLastestLoginTime',
        },
        {
            title: '转化来源类型', dataIndex: 'contentType',
        },
        {
            title:
                <div>实体ID&nbsp;
                    <Tooltip placement='top' title={"对应所选转化来源类型的实体ID，如选PGC文章时，即PGC文章ID"}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
            dataIndex: 'contentId',
        },
        {
            title: "实体标题",
            dataIndex: 'contentTitle',
        },
        {
            title: '来源URL', dataIndex: 'url',
        },
    ];
    const clickBackHandler = () => {
        window.history.back(-1);
    }
    const onFinish = (values) => {
        setFormData({ ...formData, memCard: values.memCard || "", memNickname: values.memNickname || "", memMobile: values.memMobile || "", memDeviceType: values.memDeviceType || "", contentType: values.contentType || "", contentTitle: values.contentTitle || "", contentId: values.contentId || "" })
        setNum(++num)
    }

    const handleChangeTime = (value, dateString) => {
        setFalg(false)
        setFormData({ ...formData, startTime: dateString[0], endTime: dateString[1] })
    }
    useEffect(() => {
        if (pathname && pathname.showTime) {
            form1.resetFields();
            form1.setFieldsValue({ time: "" })
            setFalg(false)
        }
    }, [])
    useEffect(() => {
        if (pathname) {
            let { startTime, endTime, memCard, memNickname, memMobile, memDeviceType, contentType, contentTitle, contentId } = formData;
            get(`/channel/statistics/regUser`, {
                channelId: pathname.channelId,
                startTime: falg ? pathname.startTime : startTime,
                endTime: falg ? pathname.endTime : endTime,
                memCard,
                memNickname,
                memDeviceType,
                page,
                limit,
                memMobile,
                contentType,
                contentTitle,
                contentId
            }).then((res) => {
                if (res.status_code == 200) {
                    setDataSource(res.data)
                }
            }, (err) => {
                console.log(err)
            })
        }

    }, [limit, page, num])
    useEffect(()=>{
        get(`/channel/dictionary/source/type`).then((res) => {
            if (res.status_code == 200) {
                let arr = []
                for(var a in res.data){
                    arr.push(res.data[a])
                }
                setSourceType(arr)
            }
        }, (err) => {
            console.log(err)
        })
    },[])
    const changeCurrPage = useCallback(
        (page) => {
            setPage(page);
        },
        [],
    );
    const changePageSize = useCallback(
        (pageSize) => {
            setLimit(pageSize);
        },
        [],
    );
    const paginationProps = {
        current: page,
        showTotal: () => `共${dataSource.totalCount}条记录`,
        showSizeChanger: true,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100', '1000'],
        size: 'small',
        total: dataSource && dataSource.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) }
    };
    const onChangeSelect = (value) => {
        if (value) { setShowElement(true) } else {
            setShowElement(false)
        }
    }
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title"><ArrowLeftOutlined onClick={clickBackHandler} /> 渠道注册用户   <span className='styleColor'> {pathname.name} [#{pathname.channelId}]</span></div>
                <div className="main-content-body">
                    <Form {...filterFormItemLayout} onFinish={onFinish} form={form1}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="用户唯一标识" name="memCard">
                                    <Input allowClear placeholder="用户唯一标识" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="用户昵称" name="memNickname">
                                    <Input allowClear placeholder="用户昵称" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="memMobile">
                                    <Input allowClear placeholder="手机号" />
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="注册端口" name="memDeviceType">
                                    <Select allowClear placeholder="注册端口" >
                                        <Select.Option value={"pc"}>pc</Select.Option>
                                        <Select.Option value={"app"}>app</Select.Option>
                                        <Select.Option value={"wap"}>wap</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="注册时间" name="time">
                                    {start && end && <RangePicker
                                        format={dateFormat}
                                        showTime
                                        onChange={handleChangeTime}
                                        defaultValue={[moment(start, dateFormat), moment(end, dateFormat)]}
                                    />}

                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="转化来源类型" name="contentType">
                                    <Select allowClear placeholder="转化来源类型" onChange={onChangeSelect}>
                                        {sourceType && sourceType.map((item,i)=>{
                                            return <Select.Option value={i+1}>{item}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {showElement && <Col {...filterFormGridLayout}>
                                <Form.Item  label="实体ID" name="contentId" tooltip={{ title: '对应所选转化来源类型的实体ID，如选PGC文章时，即PGC文章ID', icon: <InfoCircleOutlined /> }}>
                                    <Input allowClear placeholder="转化来源实体ID" />
                                </Form.Item>
                            </Col>}
                            {showElement && <Col {...filterFormGridLayout}>
                                <Form.Item label="实体标题" name="contentTitle" >
                                    <Input allowClear placeholder="实体标题关键字" />
                                </Form.Item>
                            </Col>}
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="table-title">渠道注册用户列表</div>
                    </div>
                    {dataSource && <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataSource.list}
                        pagination={paginationProps}
                    />}
                </div>
            </Content>
        </Fragment>
    )

}
