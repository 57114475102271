import {Fragment, useEffect,useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Content} from "antd/lib/layout/layout";
import {LeftOutlined} from "@ant-design/icons";
import {Steps} from "antd";
import "./MedConnectQADetail.less";
import {getQaDetail} from '@/services/MedConnect';
import {useSelector} from "react-redux";



export default function MedConnectQADetail() {

    let { id, detailId } = useParams();
    id = !!id && parseInt(id) || 0;
    detailId = !!detailId && parseInt(detailId) || 0;

    const projectName = useSelector((state) => state.medconnect.projectName);

    const [qaInfo, setQaInfo] = useState({});
    const [stepList, setStepList] = useState([]);


    const getDetail = () => {
        try {
            getQaDetail(detailId).then(res => {
                if (res && res.success) {
                    const stepData = [];
                    res.data.eventList.length > 0 && res.data.eventList.reverse().forEach((event, index) => {

                        stepData.push({
                            title: event.eventDescription,
                            status: 'finish',
                            description: <>
                                <div>
                                    <div>{event.eventUserName}</div>
                                    <div>{event.eventTime}</div>
                                    <div className="detail-tag">
                                        {event.evaluateLabelDesc && event.evaluateLabelDesc.length > 0 ? event.evaluateLabelDesc.map((item, index) => {
                                            return <div className="tag-item">{item}</div>
                                        }) : null}
                                    </div>
                                    {
                                        event?.content ? <div className="item-content">{event.content}</div> : null
                                    }
                                    {event?.auditAudioOssUrl ? <div className="audit-audio">
                                        <audio
                                            style={{width: '100%'}}
                                            controls
                                            src={event.auditAudioOssUrl}>
                                        </audio>
                                    </div> : null}
                                </div>
                            </>
                        })
                    })

                    setStepList(stepData);
                    setQaInfo(res.data);
                }
            })
        } catch (e) {
            console.log('error', e);
        }
    }

    useEffect(() => {
        getDetail();
    }, [detailId]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects/${id}/answer-list`} className="back"><LeftOutlined/></Link>
                <span className="current-title">回答管理</span>
                <span className="current-subtitle">{projectName}</span>
            </div>

            <Content className="main-content-box">
                <div className="main-content-title">问答详情</div>
                <div className="main-content-body">
                    <div className="aq-detail">
                        <div className="detail-title">
                            <div className="title-name">{qaInfo.questionContent}</div>
                            <div className="times">{qaInfo.questionTime}</div>
                        </div>
                        <div className="detail-userinfo">{qaInfo.questionUserName}  {qaInfo.questionUserInfo}</div>
                        {qaInfo?.questionAudioOssUrl ? <div className="question-audio">
                            <audio
                                style={{width: '100%'}}
                                controls
                                src={qaInfo.questionAudioOssUrl}>
                            </audio>
                        </div> : null}
                    </div>
                    <div className="detail-cotent-title">
                        <div className="title-name">{qaInfo.answerUserName} {qaInfo.answerUserInfo}</div>
                        <div>{qaInfo.answerTime}</div>
                    </div>
                    {/*中间详细内容*/}
                    <div dangerouslySetInnerHTML={{__html: qaInfo.answerContent}}></div>
                    {/*回答录音*/}
                    {qaInfo?.answerAudioOssUrl ? <div className="answer-audio">
                        <audio
                            style={{width: '100%'}}
                            controls
                            src={qaInfo.answerAudioOssUrl}>
                        </audio>
                    </div> : null}
                    {/*问答轨迹*/}
                    <div className="event-content">
                        <Steps
                            direction="vertical"
                            progressDot={true}
                            items={stepList}
                        />
                    </div>

                </div>

            </Content>

        </Fragment>
)
}
