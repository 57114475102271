import EnterpriseStaffList from '../components/Enterprise/Staff/StaffList';
import EnterpriseStaff from '../components/Enterprise/Staff/Staff';
import DepartmentList from '../components/Enterprise/Department/DepartmentList';
import RoleList from '../components/Enterprise/Role/RoleList';
import RolePermissions from '../components/Enterprise/Role/RolePermissions';
import PermissionList from '../components/Enterprise/Permission/PermissionList';
import OperationLogList from '../components/Enterprise/OperationLog/OperationLogList';
import OperationStat from '../components/Enterprise/OperationLog/OperationStat';

// 企业管理相关路由
const enterpriseRoutes = [
    // 企业管理 - 员工管理
    { path: 'staffs', element: <EnterpriseStaffList/> },
    // 企业管理 - 新增员工
    { path: 'staff/new', element: <EnterpriseStaff/> },
    // 企业管理 - 编辑员工
    { path: 'staff/:id', element: <EnterpriseStaff/> },
    // 企业管理 - 组织架构（部门管理）
    { path: 'departments', element: <DepartmentList/> },
    // 企业管理 - 角色管理
    { path: 'roles', element: <RoleList/> },
    // 企业管理 - 角色权限
    { path: 'role/:id/permissions', element: <RolePermissions/> },
    // 企业管理 - 权限管理
    { path: 'permissions', element: <PermissionList/> },
    // 企业管理 - 操作日志
    { path: 'operation/log', element: <OperationLogList/> },

    { path: 'operation/stat', element: <OperationStat/> },
];

export default enterpriseRoutes;
