import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import './KbResearchHospitalFilterFrom.less'

export default function KbResearchHospitalFilterFrom({
    handleFilterFinish
}) {
    const [form] = Form.useForm();

    const filter = useSelector(state => state.researchHospital.filter);

    const onFinish = (values) => {
        if (values) {
            handleFilterFinish(values,'search');
        }
    };


    const onReset = () => {
        form.resetFields();
        form.setFieldValue('year', 2023)
        handleFilterFinish({ ...form.getFieldsValue() }, 'reset');
    };

    useEffect(() => {
        let setValue = { ...filter };

        setValue.flagStatus = filter?.flagStatus || 0;
        setValue.hasError = filter?.hasError || (!(filter?.hasError) && filter?.hasError === 0)
            ? filter?.hasError : -1;
        setValue.dataFillingStatus = filter?.dataFillingStatus || (!(filter?.dataFillingStatus) && filter?.dataFillingStatus === 0)
            ? filter?.dataFillingStatus : -1;
        setValue.dataFillingAuditCompleteStatus = filter?.dataFillingAuditCompleteStatus || (!(filter?.dataFillingAuditCompleteStatus) && filter?.dataFillingAuditCompleteStatus === 0)
            ? filter?.dataFillingAuditCompleteStatus : -1;
        setValue.year = filter?.year || 2023;

        form.setFieldsValue(setValue);
    }, [filter]);

    return (
        <div className='hospital-research'>

        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="申报主题" name="checkType"  >
                        <Select allowClear placeholder="请选择申报主题"  >
                            <Select.Option value={1}>研究型医院</Select.Option>
                            <Select.Option value={2}>研究型学科</Select.Option>
                            <Select.Option value={3}>研究型人才</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="申报医院" name="orgName" >
                        <Input allowClear placeholder="申报医院" autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="申报学科" name="subjectName">
                        <Input allowClear placeholder="申报学科" autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="申报人" name="personName">
                        <Input allowClear placeholder="申报人" autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="审核人" name="updateName">
                        <Input allowClear placeholder="审核人" autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="基础信息状态" name="baseStatus">
                        <Select allowClear placeholder="" >
                            <Select.Option value={0}>审核中</Select.Option>
                            <Select.Option value={1}>审核通过</Select.Option>
                            <Select.Option value={2}>审核不通过</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                {/*<Col {...filterFormGridLayout}>*/}
                {/*    <Form.Item label="基础信息退回状态" name="baseBackStatus">*/}
                {/*        <Select allowClear placeholder="">*/}
                {/*            <Select.Option value={0}>未退回</Select.Option>*/}
                {/*            <Select.Option value={1}>已退回</Select.Option>*/}
                {/*        </Select>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}

                <Col {...filterFormGridLayout}>
                    <Form.Item label="填报数据状态" name="fillStatus">
                        <Select allowClear placeholder="">
                            <Select.Option value={0}>草稿</Select.Option>
                            {/*<Select.Option value={1}>待审核</Select.Option>*/}
                            <Select.Option value={2}>审核中</Select.Option>
                            <Select.Option value={3}>审核通过</Select.Option>
                            <Select.Option value={4}>审核未通过</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                {/*<Col {...filterFormGridLayout}>*/}
                {/*    <Form.Item label="填报数据退回状态" name="fillBackStatus">*/}
                {/*        <Select allowClear placeholder="">*/}
                {/*            <Select.Option value={0}>未退回</Select.Option>*/}
                {/*            <Select.Option value={1}>已退回</Select.Option>*/}
                {/*        </Select>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}

                {/*<Col {...filterFormGridLayout}>*/}
                {/*    <Form.Item label="基础信息二次提交" name="baseSubmitTimes">*/}
                {/*        <Select allowClear placeholder="">*/}
                {/*            <Select.Option value={0}>否</Select.Option>*/}
                {/*            <Select.Option value={1}>是</Select.Option>*/}
                {/*        </Select>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}

                {/*<Col {...filterFormGridLayout}>*/}
                {/*    <Form.Item label="填报信息二次提交" name="fillSubmitTimes">*/}
                {/*        <Select allowClear placeholder="">*/}
                {/*            <Select.Option value={0}>否</Select.Option>*/}
                {/*            <Select.Option value={1}>是</Select.Option>*/}
                {/*        </Select>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
                <Col {...filterFormGridLayout}>
                    <Form.Item label="测试数据" name="flagStatus" >
                        <Select allowClear placeholder="" >
                            <Select.Option value={0}>正式数据</Select.Option>
                            <Select.Option value={1}>测试数据</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="指标异常" name="hasError" >
                        <Select allowClear placeholder="" >
                            <Select.Option value={-1}>全部</Select.Option>
                            <Select.Option value={0}>无异常</Select.Option>
                            <Select.Option value={1}>有异常</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="填报数据完成度" name="dataFillingStatus" >
                        <Select allowClear placeholder="" >
                            <Select.Option value={-1}>全部</Select.Option>
                            <Select.Option value={0}>未完成</Select.Option>
                            <Select.Option value={1}>已完成</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="填报数据审核完成度" name="dataFillingAuditCompleteStatus" >
                        <Select allowClear placeholder="" >
                            <Select.Option value={-1}>全部</Select.Option>
                            <Select.Option value={0}>未完成</Select.Option>
                            <Select.Option value={1}>已完成</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="年份" name="year" >
                        <Select allowClear placeholder="" >
                            <Select.Option value={2022}>2022</Select.Option>
                            <Select.Option value={2023}>2023</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
        </div>
    );
}
