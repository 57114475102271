import React, { useEffect } from 'react';
import * as echarts from 'echarts';

export default function AuthUserDepartmentEcharts({ chartData }) {
    let myChart1;
    function echartsFunction() {
        myChart1 && myChart1.dispose();
        myChart1 = echarts.init(document.getElementById('active-ehcarts'));
        // 指定图表的配置项和数据
        // 注册用户趋势
        const option = {
            tooltip: {//提示
                trigger: 'axis',
                backgroundColor: 'rgba(255,255,255,0.9)',
                extraCssText: 'width:150px;height:60px;',
                axisPointer: {//hover的时候没有虚线
                    type: 'none'
                }
            },
            grid: {
                left: '2%',
                right: '2%',
                bottom: 0,
                top: '5%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: chartData.xData,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        rotate: 45,
                        color: '#000',
                        margin: 17,
                        fontSize: 12,
                        formatter: (params) => {
                            var newParamsName = ''
                            var paramsNameNumber = params.length
                            var provideNumber = 5//超过10个字符折行
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                            for (let row = 0; row < rowNumber; row++) {
                                newParamsName +=
                                    params.substring(
                                        row * provideNumber,
                                        (row + 1) * provideNumber
                                    ) + '\n'
                            }
                            return newParamsName
                        }
                    },
                }
            ],
            yAxis: { type: 'value' },
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    barWidth: '30%',
                    data: chartData.yData,
                }
            ]
        };
        // 使用刚指定的配置项和数据显示图表。 -->
        myChart1.setOption(option);
        window.addEventListener('resize', function () {
            myChart1.resize();
        });
    }

    useEffect(() => {
        setTimeout(() => {
            echartsFunction()
        }, 20)
    }, [echartsFunction])

    return (
        <div className="active-user-ehcarts" id='active-ehcarts'></div>
    )
}
