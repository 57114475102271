import { get, put, post } from '@core/request';
import config from "@config";


// 医企+管理-接口服务
//解决方案分页列表
export const getSolutionsListApi = (params) => {
    return get('/user/admin/eha/solutions', params);

}
export const getUserFindAllApi = (params) => {
    return get('/user/data/findAll', params)
}

// 解决方案详情
export const getSolutionItemApi = (params)=> {
    return get(`/user/admin/eha/solutions/${params.id}`);
};


//解决方案审核
export const reviewSolutionApi = (params) => {
    return post(`/user/admin/eha/solutions/review`,params);
};




/**
 *
 * 编辑解决方案api
 */

// 图片附件等上传
export const uploadAttachApi = (params) => {
    return post('/user/eha/upload/attach', params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

// 文档上传
export const uploadSolutionApi = (params) => {
    return post('/user/eha/upload/solutions', params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

// 阿里云OSS上传凭证
export const getAliyuncred = (data) => {
    return post(config.ALIYUN_API_PREFIX + '/media/video/upload-info', data, {
        timeout: 600000
    });
}

// 阿里云OSS刷新凭证
export const refreshAliyuncred = (data) => {
    return get(config.ALIYUN_API_PREFIX + '/media/video/re-upload-info', data);
}

// 视频点播
export const getVodMediaInfo = (params) => {
    const url = `${config.ALIYUN_API_PREFIX}/media/video/play-info`;
    return get(url, params);
}

// 视频转码处理
export const videoTransCode = (params) => {
    return post(`${config.VIDEOTRANSCODE_PREFIX}/media/video/stream-upload`, params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

// 编辑解决方案
export const editSolutionsApi = (params) => {
    return put('/user/admin/eha/solutions', params)
}

// 解决方案id查询
export const getSolutionDetailApi = (params) => {
    return get(`/user/admin/eha/solutions/${params.id}`)
}

// 批量删除解决方案
export const deleteSolutionByIdsApi = (params) => {
    return post('/user/admin/eha/solutions/deleteByIds', params)
}
// 解决方案上下架
export const updateStatusApi = (params) => {
    return post('/user/admin/eha/solutions/status', params)
}
// 企业管理分页列表
export const getEnterprisesListApi = (params) => {
    return get('/user/admin/eha/enterprises', params)
}
// 修改会员等级
export const updataEnterprisesListApi = (params) => {
    return put('/user/admin/eha/enterprises', params)
}




/**
 *
 * 生成壁报api
 */

// 保存壁报字段
export const savePosterDataApi = (params) => {
    return put('/user/admin/eha/solutions/putPosterData', params)
}

// 查询设置信息
export const getPosterDetailApi = (params) => {
    return get(`/user/admin/eha/solutions/${params.id}`, params)
}