import { message, Drawer, Form, Radio, Input, Space, Button, Upload } from 'antd';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';
import { useState, useEffect, Fragment } from 'react';
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { uploadFileApi } from '@services/SiteModule';
import config from '@config';

export default ({
    drawerState,
    setDrawerState,
    currentModule,
    updateModuleSettings,
}) => {
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    // 上传图片
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png/jpeg 格式');
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('图片大小不超过5MB');
            return false;
        }
        return isJpgOrPng && isLt5M;
    };

    // 上传文件路径 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 上传图片
    const upload = async (value, index) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let list = [{ url: getFileUrl(res.data) }];
            let newList = [...fileList];
            newList[index] = list;

            let currentValue = form.getFieldValue('reviewList');
            let newValue = currentValue;
            newValue[index].cover = list;

            form.setFieldValue('reviewList', newValue);
            setFileList(newList);
        };
    };


    const swapItems = (index1, index2) => {
        let coverArr = [...fileList];
        coverArr[index1] = coverArr.splice(index2, 1, coverArr[index1])[0];
        setFileList(coverArr);
    }

    // 过滤oss前缀
    const delOssUrl = (str) => {
        if (str && str.includes(config.OSS_PREFIX)) {
            let re = new RegExp(config.OSS_PREFIX + "(.*)")
            return str.match(re)[1];
        }
        return '';
    };

    // 处理文件前缀
    const dealFileUrl = (list) => {
        if (list && list.length) {
            let newList = list.map(item => {
                let newItem = JSON.parse(JSON.stringify(item));
                newItem.cover = Array.isArray(newItem.cover) && newItem.cover.length ? delOssUrl(newItem.cover[0].url) : newItem.cover;
                return newItem;
            })
            return newList;
        };
        return [];
    };

    // 图片回显添加前缀
    const addOssUrl = (imgList) => {
        if (imgList && imgList.length) {
            let newList = imgList.map(item => {
                let newItem = { ...item };
                if (newItem.url && !newItem.url.includes(config.OSS_PREFIX)) {
                    newItem.url = config.OSS_PREFIX + newItem.url;
                }
                return newItem;
            });
            return newList;
        }
        return [];
    };

    // 新增模块
    const onFinish = async (values) => {
        let params = {
            ...values,
            id: currentModule.id,
            data: JSON.stringify({ reviewList: dealFileUrl(values.reviewList) })
        };
        delete params.reviewList;
        updateModuleSettings(params);
    };

    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            let reviewList = [];
            if (currentModule.data) {
                let data = JSON.parse(currentModule.data);
                reviewList = data.reviewList || [];

                if (reviewList && reviewList.length) {
                    let coverList = [];
                    reviewList.forEach((item, index) => {
                        coverList[index] = addOssUrl([{url: item.cover}]);
                    });

                    setFileList(coverList);
                };
            };

            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path,
                reviewList
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 8 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="ReviewModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="ReviewModuleForm"
                form={form}
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="displayTitle"
                    label="显示标题"
                    rules={[
                        { required: true, message: '显示标题不能为空' }
                    ]}
                >
                    <Input placeholder="请输入模块显示标题" />
                </Form.Item>

                <Form.Item name="displayTitleEn" label="英文显示标题">
                    <Input placeholder="请输入模块英文显示标题" />
                </Form.Item>

                <Form.Item
                    name="path"
                    label="访问路径"
                    rules={[
                        { required: true, message: '访问路径不能为空' },
                        { pattern: /^[a-z\d_]{2,20}$/, message: '访问路径不符合格式要求' },
                    ]}
                    help={
                        <>
                            填写访问该模块的访问路径，如会议简介访问路径 <b>introduction</b><br />
                            格式合求：小写字母、数字或下划线（_），限2-20个字符
                        </>
                    }
                >
                    <Input placeholder="请输入模块访问路径" minLength={2} maxLength={20} showCount disabled={currentModule?.moduleType <= 10} />
                </Form.Item>
                <Form.Item
                    label="历届回顾"
                    name="reviewList"
                >
                    <Form.List name="reviewList">
                        {(fields, { add, remove, move }) => (
                            <>
                                <Form.Item>
                                    <Button onClick={() => {
                                        add();
                                    }}>
                                        <PlusCircleOutlined />添加回顾
                                    </Button>
                                </Form.Item>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div key={key} className="exhibition-add">
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                            }}
                                            align="center"
                                        >
                                            <div style={{ paddingRight: '20px' }}>
                                                <Form.Item
                                                    label="标题"
                                                    name={[name, 'name']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请输入标题',
                                                        },
                                                    ]}
                                                >
                                                    <Input style={{ width: 300 }} placeholder="请输入标题" maxLength={50} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="封面"
                                                    name={[name, 'cover']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请上传图片',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        customRequest={(e) => upload(e, index)}
                                                        listType="picture-card"
                                                        fileList={fileList[index]}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                        beforeUpload={beforeUpload}
                                                        accept="image/jpeg, image/png, image/jpg"
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div
                                                                style={{
                                                                    marginTop: 8,
                                                                }}
                                                            >
                                                                {fileList[index] && fileList[index].length ? '更改图片' : '上传图片'}
                                                            </div>
                                                        </div>
                                                    </Upload>
                                                </Form.Item>
                                                <Form.Item
                                                    label="链接"
                                                    name={[name, 'link']}
                                                >
                                                    <Input style={{ width: 300 }} placeholder="请输入链接" maxLength={200} />
                                                </Form.Item>
                                            </div>

                                            <MinusCircleOutlined onClick={() => {
                                                remove(name);
                                                let deleteFileList = fileList;
                                                deleteFileList.splice(index, 1);
                                                setFileList(deleteFileList);
                                            }} />
                                            {index !== 0 ? <ArrowUpOutlined onClick={() => {
                                                move(index, index - 1);
                                                swapItems(index, index - 1);
                                            }} /> : null}
                                            {index !== fields.length - 1 ? <ArrowDownOutlined onClick={() => {
                                                move(index, index + 1);
                                                swapItems(index, index + 1);
                                            }} /> : null}
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item
                    name="status"
                    label="导航状态"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>敬请期待</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="hiddenWeb"
                    label="Web端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="hiddenH5"
                    label="H5端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>


        </Drawer>
    );
};
