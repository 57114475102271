import React, { useEffect } from 'react';
import * as echarts from 'echarts';

export default function ActiveUserTrend({chartData}) {
    let myChart1;
    function echartsFunction() {
        myChart1 && myChart1.dispose();
        myChart1 = echarts.init(document.getElementById('active-ehcarts'));
        // 指定图表的配置项和数据
        // 注册用户趋势
        const option1 = {
            tooltip: {//提示
                trigger: 'item',
                backgroundColor: 'rgba(255,255,255,0.9)',
            },
            xAxis: {
                type: 'category',
                data: chartData.xData
            },
            yAxis: { type: 'value' },
            grid: {
                left: '2%',
                right: '2%',
                bottom: '3%',
                top: '5%',
                containLabel: true
            },
            series: [
                {
                    data: chartData.yData,
                    type: 'line',
                    smooth: true
                }
            ]
        };
        // 使用刚指定的配置项和数据显示图表。 -->
        myChart1.setOption(option1);
        window.addEventListener('resize', function () {
            myChart1.resize();
        });
    }

    useEffect(() => {
        setTimeout(() => {
            echartsFunction()
        }, 20)
    }, [echartsFunction])

    return (
        <div className="active-user-ehcarts" id='active-ehcarts'></div>
    )
}
