import React, { Fragment, useEffect, useState } from "react";
import { Button, Layout, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined } from "@ant-design/icons";
import RcpCom from "../RcpCom";
import FillCom from "../FillCom";
import Matrix from "../Matrix";
import "./index.less";

const questionTypeMap = new Map([
    [1, '单选题'],
    [2, '多选题'],
    [3, '判断题'],
    [4, '单项填空'],
    [5, '多项填空'],
    [6, '问答题'],
    [7, '所在地区'],
    [8, '量表题'],
    [9, '排序题'],
    [10, '矩形填空'],
    [11, '文件收集'],
    [12, '电子签名'],
    [13, '选择列表'],

])


const QuestionItemCom = ({ item, index }) => {
    useEffect(() => {
        console.log(item, "item");
    }, []);

    return (
        <div className="question_item_wrap">
            <div className="question_statistics">
                <div>{index + 1 + ". "}</div>
                <div className="title_color" dangerouslySetInnerHTML={{ __html: item?.body || "" }}></div>
                <div className="question_type"> ({questionTypeMap.get(item?.questionTypeId || "")}{item?.questionTypeId===13&&item?.isMultipleChoice===1&&'-多选'})</div>
            </div>
            <span>填写{item?.answers || 0}</span>
            {[1, 2, 3, 8, 13].includes(item.questionTypeId) ? <RcpCom data={item.data} type={item.questionTypeId} count={item?.answers} /> :
            [10].includes(item.questionTypeId) ? <Matrix data={item.data} type={item.questionTypeId} option={item.option} /> :
            <FillCom data={item.data} type={item.questionTypeId} />}
        </div>
    );
};

export default QuestionItemCom;
