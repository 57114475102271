import React, { Fragment, useState, useEffect,useRef,useMemo  } from "react";
import { Radio, Input, Space,message } from "antd";
import './index.less';
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { timesaveTemplateQuestionApi } from "@services/Wj/Template";
import { timesaveSurveryQuestionApi } from "@services/Wj/Question";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Judge = ({ questionId, item, setAnserArea, anserArea,onReset }) => {
    const [questionIndex, setQuestionIndex] = useState();
    const [choiceList, setChoiceList] = useState([])
    const [titleBorder, setTitleBorder] = useState(false);
    const [titleIn,setTitleIn]= useState(false)
    const { TextArea } = Input;
    const quillRef=useRef(null)
    const location = useLocation();
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        //console.log(index);
        setQuestionIndex(index + 1);
        setChoiceList([...item.options])
    }, []);
    const modules = useMemo(() => {
        return {
          toolbar: {
            container: [
              ["bold",  "underline", {
                color: [],
              },],
            ],
          },
        };
      }, []);
      useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
        let c=document.querySelectorAll('.ql-color')
        ;
        setTimeout(() => {
            c.forEach(item=>{
                item.onmousedown=(e)=>{
                    e.preventDefault();
                    //console.log(1);
            }
            })
        }, 1000);


    }, []);

    const changeTitle = (e,i) => {
        let a=document.querySelectorAll('.ql-container')
       a[questionIndex].removeAttribute('style')
        let b=document.querySelectorAll('.ql-toolbar')
        b[questionIndex].removeAttribute('style')
        item.body = quillRef.current.value;
        setTitleBorder(false);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: quillRef.current.value,
        };
        //console.log(quillRef.current.value);
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){
                message.success('保存成功')
            }}))
        }
        // setAnserArea(resArr);
    };
    // const
    const getBorder = (index) => {
        let a=document.querySelectorAll('.ql-container')
        a[index].style.border='1px solid #1a73e8'
        let b=document.querySelectorAll('.ql-toolbar')
        b[index].style.display='block'
        setTitleBorder(true);
        setTitleIn(true)
    };

    //改变选项内容
    const changeOpinion = (e, it) => {
        it.content = e.target.value
        if(choiceList[0].content===''){
            choiceList[0].content='对'
        }else if(choiceList[1].content===''){
            choiceList[1].content='错'
        }
        setChoiceList([...choiceList])
        saveOpinion()

    }
   //保存选项
   const saveOpinion = (sortArr) => {
    const resArr = [...anserArea]
    const index = resArr.findIndex(it => it.id === item.id)
    resArr[index] = {
        ...resArr[index],
        templateId: location.state.id,
        options: sortArr || choiceList
    }
    //console.log(resArr[index]);
    if (location.state.route === 'newCreate') {
        timesaveTemplateQuestionApi(resArr[index]).then(
            (res) => {
                if (res.status_code === 200) {
                   message.success('保存成功')
                    onReset()
                }
            }
        )
    } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
        timesaveSurveryQuestionApi(resArr[index]).then(
            (res) => {
                if (res.status_code === 200) {
                    message.success('保存成功')
                    onReset()
                }
            }
        )
    }
    setAnserArea(resArr)
}

    return (
        <Fragment>
            <div name={questionId} >
                <div className="question-title">
                    <div className="question-id">
                        <span
                            className="required"
                            style={{
                                display: item.required === 1 ? "" : "none",
                            }}
                        >
                            *
                        </span>
                        <b className="question-index">{questionIndex}.</b>
                    </div>
                    <ReactQuill theme="snow" ref={quillRef}
                    modules={modules}
                   placeholder="请输入问题"
                   className="question-title-area"
                    onFocus={()=>getBorder(questionIndex)}
                    defaultValue={item.body}
                        onBlur={()=>changeTitle(questionIndex)}
                        />
                </div>
                <Radio.Group className="judge-option">
                    <Space direction="vertical">
                        {/* <Radio className="judge-option" value={0}>对</Radio> */}
                        <Radio key={item.options[0]?.id} value={item.options[0]?.id}  disabled>
                         <TextArea
                         className='option-item'
                         placeholder={`对`} defaultValue={item.options[0]?.content}
                            onBlur={(e) => changeOpinion(e, item.options[0],0)}
                            bordered={false}
                            maxLength={200}
                            autoSize={{
                                minRows: 1,
                            }}
                        ></TextArea>
                    </Radio>
                    <Radio key={item.options[1]?.id} value={item.options[1]?.id}  disabled>
                         <TextArea
                         className='option-item'
                         placeholder={`错`} defaultValue={item.options[1]?.content}
                            onBlur={(e) => changeOpinion(e, item.options[1])}
                            bordered={false}
                            maxLength={200}
                            autoSize={{
                                minRows: 1,
                            }}
                        ></TextArea>
                    </Radio>
                        {/* <Radio className="judge-option" value={1}>错</Radio> */}
                    </Space>
                </Radio.Group>
            </div>
        </Fragment>
    );
};

export default Judge;
