import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Layout, Card, Tooltip, Radio, DatePicker, message } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import UserOverviewBottomEcharts from './UserOverviewBottomEcharts';
import { lastDays, todayDateString } from '@/utils/utils';
import { get } from '@/core/request';
import './UserOverview.less';
import moment from 'moment';
const { Content } = Layout;
const { RangePicker } = DatePicker;

export default function UserOverview() {

    const userOverviewTooltip = (title, tooltip) => {
        return (
            <div className="tooltip-box"><span className="card-title">{title}</span>
                <Tooltip placement="top" title={tooltip}>
                    <span className="user-overview-tooltip"><InfoCircleFilled /></span>
                </Tooltip>
            </div>
        )
    }

    const dateFormat = 'YYYY-MM-DD';

    let date = new Date();
    // 当天
    let dateString = todayDateString(date);
    // 七天前   30天前
    const [dateStringStart, setDateStringStart] = useState(dateString);
    // 当天
    const [dateStringEnd, setDateStringEnd] = useState(dateString);

    // 上面的card的state
    const [activeCard, setActiveCard] = useState(null);
    const [registerCard, setRegisterCard] = useState(null);
    const [identityCard, setIdentityCard] = useState(null);
    const [doctorCard, setDoctorCard] = useState(null);
    const [personCard, setPersonCard] = useState(null);
    const [teamCard, setTeamCard] = useState(null);
    const [decision, setDecision] = useState(null);

    useEffect(() => {
        var days = (Date.parse(dateStringEnd) - Date.parse(dateStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        // 活跃
        get(`/user/data/statistics/active-users`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setActiveCard(res.data)
            }
        });
        // 注册
        get(`/user/data/statistics/register-users`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setRegisterCard(res.data)
            }
        });
        // 认证
        get(`/user/data/statistics/certified-users`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setIdentityCard(res.data)
            }
        });
        // 医疗结构从业者
        get(`/user/data/statistics/doctors`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setDoctorCard(res.data)
            }
        });
        // 医疗决策人
        get(`/user/data/statistics/medical-decision-users`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setDecision(res.data)
            }
        });
        // 北斗个人
        get(`/user/data/statistics/beidou-personal-users`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setPersonCard(res.data)
            }
        });
        // 北斗团队
        get(`/user/data/statistics/beidou-team-users`, {
            startTime: dateStringStart,
            endTime: dateStringEnd
        }).then((res) => {
            if (res.status_code === 200) {
                setTeamCard(res.data)
            }
        });
    }, [dateStringStart, dateStringEnd])

    const [timeRadio, setTimeRadio] = useState('today')

    // 改变时间
    const handleChangeValue = (e) => {
        setTimeRadio(e.target.value);
        let startdate = '';
        if (e.target.value === 'today') {
            setDateStringStart(dateString)
            setDateStringEnd(dateString)
            return;
        } else if (e.target.value === 'seven') {
            startdate = lastDays(6);
        } else {
            startdate = lastDays(29);
        }
        setDateStringStart(startdate)
        setDateStringEnd(dateString)
    }

    // 搜索的自定义时间 控制为90天

    const disabledDate = (current) => {
        return current > moment().endOf('day');
    }

    const handleChangeTime = (value, dateString) => {
        setDateStringStart(dateString[0]);
        setDateStringEnd(dateString[1]);
        setTimeRadio('');
    }
    const timeBox = (
        <div className="bi-user-overview-time-box">
            <Radio.Group onChange={handleChangeValue} size="small" value={timeRadio} className="bi-user-overview-time-box-left">
                <Radio.Button value="today">今天</Radio.Button>
                <Radio.Button value="seven">最近7天</Radio.Button>
                <Radio.Button value="thirty">最近30天</Radio.Button>
            </Radio.Group>
            {
                dateStringStart && <RangePicker
                    size="small"
                    allowClear={false}
                    value={[moment(dateStringStart, dateFormat), moment(dateStringEnd, dateFormat)]}
                    disabledDate={disabledDate}
                    onChange={handleChangeTime}
                />
            }
        </div>
    )

    return (
        <Fragment>
            <div className="page-title">首页</div>
            <Content className="bi-user-overview">
                <Row gutter={16}>
                    <Col span={24}>
                        <Card size="small" title='数据概括' extra={timeBox}>

                            <Row gutter={[16, 16]} className="bi-user-overview-top">
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('活跃用户', '按用户最后活跃时间计')}>
                                        <p>活跃注册用户<span className="user-overview-num">{activeCard ? activeCard.activeRegisterUsers : 0}</span></p>
                                        <p>活跃认证用户<span className="user-overview-num">{activeCard ? activeCard.activeCertifiedUsers : 0}</span></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('注册用户', '按用户注册时间计')}>
                                        <p>新增注册用户<span className="user-overview-num">{registerCard ? registerCard.increaseNum : 0}</span></p>
                                        <p>累计注册用户<span className="user-overview-num">{registerCard ? registerCard.totalNum : 0}</span></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('认证用户', '按用户认证审核通过时间计')}>
                                        <p>新增认证用户<span className="user-overview-num">{identityCard ? identityCard.increaseNum : 0}</span></p>
                                        <p>累计认证用户<span className="user-overview-num">{identityCard ? identityCard.totalNum : 0}</span></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('医疗机构从业者', '职业认证中医疗机构从业者身份的用户数量')}>
                                        <p>新增医疗机构从业者<span className="user-overview-num">{doctorCard ? doctorCard.increaseNum : 0}</span></p>
                                        <p>累计医疗机构从业者<span className="user-overview-num">{doctorCard ? doctorCard.totalNum : 0}</span></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('医疗决策人', '职务为副主任以上的个人专业认证用户')}>
                                        <p>新增医疗决策人<span className="user-overview-num">{decision ? decision.increaseNum : 0}</span></p>
                                        <p>累计医疗决策人<span className="user-overview-num">{decision ? decision.totalNum : 0}</span></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('北斗个人会员', '北斗个人会员开通时间')}>
                                        <p>新增个人会员<span className="user-overview-num">{personCard ? personCard.increaseNum : 0}</span></p>
                                        <p>累计个人会员<span className="user-overview-num">{personCard ? personCard.totalNum : 0}</span></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small" title={userOverviewTooltip('北斗团队会员', '北斗团队会员开通时间')}>
                                        <p>新增团队会员<span className="user-overview-num">{teamCard ? teamCard.increaseNum : 0}</span></p>
                                        <p>累计团队会员<span className="user-overview-num">{teamCard ? teamCard.totalNum : 0}</span></p>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <UserOverviewBottomEcharts></UserOverviewBottomEcharts>
            </Content>
        </Fragment >
    )
}
