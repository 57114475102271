import React from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import { Routes, Route } from 'react-router-dom';

import indexRoutes from './routes/indexRoutes';

import Home from './components/Home/Home';
import PublicLayout from './components/Layouts/PublicLayout';
import NotFound from './components/Pages/NotFound';
import ServerError from './components/Pages/ServerError';

import './App.less';

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

function App() {
    const nestedRoutes = routes => routes.map(route => {
        if (route.children && route.children.length) {
            return (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                    children={nestedRoutes(route.children)}
                />
            );
        }
        return <Route path={route.path} element={route.element}/>;
    });

    return (
        <ConfigProvider locale={zhCN}>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    {nestedRoutes(indexRoutes)}

                    <Route path="" element={<PublicLayout/>}>
                        <Route path="500" element={<ServerError/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </div>
        </ConfigProvider>
    );
}

export default App;
