import React, { useState, useCallback } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { post } from '@/core/request';

export default function UploadImage({
    size,
    setImgUrl,
    fileList,
    setFileList
}) {
    // 上传图片相关
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
        </div>
    );

    const getBase64 = useCallback(
        (img, callback) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img);
        },
        [],
    );

    const uploadProps = {
        name: "file",
        fileList,
        // showUploadList: false,//设置只上传一张图片，根据实际情况修改
        customRequest: info => {//手动上传
            // const formData = new FormData();
            // formData.append('file', info.file);//名字和后端接口名字对应
            // formData.append('type', 1);//名字和后端接口名字对应
            // const option = {
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //     }
            // }
            // post("/dms/file", formData, option).then((res) => {
            //     if (res.status_code === 200) {
            //         setImgUrl(res.data);
            //         return;
            //     }
            // })
        },
        listType: "picture-card",
        className: 'uploader-img',
        beforeUpload: file => {//控制上传图片格式
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('你只能上传jpg、png格式的图片!');
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < size;
            if (!isLt2M) {
                message.error('图片大小不正确!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange: info => {
            // const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
            // const isLt2M = info.file.size / 1024 / 1024 < size;
            // if(!(isJpgOrPng && isLt2M)) return;
            // setFileList(info.fileList)
            if (info.file.status === 'uploading') {
                setLoading(true);
                const formData = new FormData();
                formData.append('file', info.file.originFileObj);//名字和后端接口名字对应
                formData.append('type', 1);//名字和后端接口名字对应
                const option = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
                post("/dms/file", formData, option).then((res) => {
                    if (res.status_code === 200) {
                        setFileList(info.fileList)
                        setImgUrl(res.data);
                        return;
                    }
                })
                info.fileList[info.fileList.length - 1].status = 'done'
                return;
            }
            if (info.file.status === 'done') {
                getBase64(info.file.originFileObj, imageUrl => {
                    setImageUrl(imageUrl);
                    setLoading(true);
                });
            }
            if (info.file.status === 'removed') {
                setFileList([]);
                setLoading(false);
                setImgUrl("");
            }
        }
    };
    return (
        <Upload
            {...uploadProps}
        >
            {fileList.length >= 1 ? null : uploadButton}
        </Upload>
    )

}




