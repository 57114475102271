import React, { Fragment, useState, useRef, useEffect } from "react";
import { Layout, DatePicker, message, Button, Radio, Divider } from "antd";
import DataOverview from './components/DataOverview';
import PositionOverview from './components/PositionOverview';
import { lastDays } from '@/utils/utils';
import moment from 'moment';
import positions from './position';
import { getDateOverviewApi, getAllMakersApi, getPositionOverviewApi } from '@services/Mdm'
import "./index.less";

const { Content } = Layout;

const AuhtOverview = () => {
    const [dateFirstSelete, setDateFirstSelete] = useState(7);
    const [dateSecondSelete, setDateSecondSelete] = useState(7);
    let date = new Date();
    const dateFormat = 'YYYY-MM-DD';
    let startDate = moment(lastDays(6));
    let endDate = moment(lastDays(0));
    // 七天前   30天前
    const [dateStringStart, setDateStringStart] = useState(startDate);
    const [positionStringStart, setPositionStringStart] = useState(startDate);
    // 当天
    const [dateStringEnd, setDateStringEnd] = useState(endDate);
    const [positionStringEnd, setPositionStringEnd] = useState(endDate);

    const [dataChart,setDateChart] = useState({
        xData: [],
        yData: []
    }
    )
    const [positionData, setPositionData] = useState({
        xData: [],
        yData: []
    })

    const [newAdd, setNewAdd] = useState(0);
    const [allMakers, setAllMakers] = useState(0);

const handleChangeValue = (e,type) => {
    if(type === 'date'){
        setDateFirstSelete(e.target.value);
        let startdateInner = '';
        if (e.target.value === 7) {
            startdateInner = moment(lastDays(6));
        } else if(e.target.value === 14){
            startdateInner = moment(lastDays(13));
        } else{
            startdateInner = moment(lastDays(29));
        }
        setDateStringStart(startdateInner)
        setDateStringEnd(endDate)
    }else{
        setDateSecondSelete(e.target.value);
        let startdateInner = '';
         if (e.target.value === 7) {
            startdateInner = moment(lastDays(6));
        } else if(e.target.value === 14){
            startdateInner = moment(lastDays(13));
        } else{
            startdateInner = moment(lastDays(29));
        }
        setPositionStringStart(startdateInner)
        setPositionStringEnd(endDate)
    }

    }

    const disabledDate = (current) => {
        return current && current > moment().subtract(0, 'days').endOf('day');
    }


    const onDateFirstChange = (value, dateString,type) => {
        if(type==='date') {
            setDateStringStart(value[0]);
            setDateStringEnd(value[1]);
            setDateFirstSelete('');
        }else if(type==='position'){
            setPositionStringStart(value[0]);
            setPositionStringEnd(value[1]);
            setDateSecondSelete('');
        }

    };

    useEffect(() => {
        var days = (Date.parse(dateStringEnd) - Date.parse(dateStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            setDateStringStart(undefined);
            setDateStringEnd(undefined);
            return;
        }
        if(dateStringStart&&dateStringEnd){
            getDateOverviewApi({startTime:moment(dateStringStart).format('YYYY-MM-DD'),endTime:moment(dateStringEnd).format('YYYY-MM-DD')}).then(res=>{
                if(res.status_code===200){
                    let xData=[],yData=[];
                    res.data.map(item=>{
                        xData.push(moment(item.timeDay).format('MM-DD'))
                        yData.push(item.count)
                    })
                    var sum = yData.reduce(function(prev, cur){
                        return prev + cur;
                        });
                    setNewAdd(sum);
                    setDateChart({
                        xData: xData,
                        yData: yData
                    })
                }
            })
            getAllMakersApi().then(res=>{
                if(res.status_code===200){
                    setAllMakers(res.data)
                }
            })
        }
    }, [dateStringStart,dateStringEnd]);
    useEffect(() => {
        var days = (Date.parse(positionStringEnd) - Date.parse(positionStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            setPositionStringStart(undefined);
            setPositionStringEnd(undefined);
            return;
        }
        if(positionStringStart&&positionStringEnd){
            getPositionOverviewApi({startTime:moment(positionStringStart).format('YYYY-MM-DD'),endTime:moment(positionStringEnd).format('YYYY-MM-DD')}).then(res=>{
                if(res.status_code===200){
                    let xData=[],yData=[],allDate=positions;
                    positions.map(item=>{
                        item.count= 0
                        res.data.map(it=>{
                            if(item.memPost===it.memPost){
                                return item.count = it.count
                            }
                        })
                        xData.push(item.memPost)
                        yData.push(item.count)
                    })
                    console.log(positions);
                    xData=xData.map(item=>{
                    let positionName = positions.find(it=>it.memPost===item)
                        return positionName.title
                    })
                    setPositionData({
                        xData: xData,
                        yData: yData
                    })
                }
            })
        }

    }, [positionStringStart,positionStringEnd]);
    return (
        <Fragment>
            <div className="page-title">医疗决策人认证总览</div>

                <div className="main-content-body">
                    <div className="medical-mark-box">
                        <div>
                            <div className="medical-mark-date-title">
                                <div className="medical-mark-date-left">
                                    <div>数据总览</div>
                                </div>
                                <div className="medical-mark-date-right">
                                    <div className="btn-container">
                                        <Radio.Group value={dateFirstSelete} onChange={(e) => handleChangeValue(e,'date')}>
                                            <Radio.Button value={7}>最近7天</Radio.Button>
                                            <Radio.Button value={14}>最近14天</Radio.Button>
                                            <Radio.Button value={30}>最近30天</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <DatePicker.RangePicker
                                    onChange={(v, s)=>onDateFirstChange(v,s,'date')}
                                    allowClear={false}
                                    value={[dateStringStart,dateStringEnd]}
                                    disabledDate={disabledDate}
                                />
                                </div>
                            </div>
                            <Divider />
                            <div className="medical-mark-date-content">
                                <div className="medical-mark-date-content-box">
                                    <p>新增医疗决策人</p>
                                    <p className="total-count">{newAdd}</p>
                                </div>
                                <div className="medical-mark-date-content-box">
                                    <p>累计医疗决策人</p>
                                    <p className="total-count">{Number(allMakers).toLocaleString()}</p>
                                </div>
                            </div>
                            <DataOverview data={dataChart}/>
                        </div>
                    </div>
                    <div className="medical-mark-box">
                        <div>
                            <div className="medical-mark-date-title">
                                <div className="medical-mark-date-left">
                                    <div>职务分布</div>
                                </div>
                                <div className="medical-mark-date-right">
                                    <div className="btn-container">
                                        <Radio.Group value={dateSecondSelete} onChange={(e) => handleChangeValue(e,'position')}>
                                            <Radio.Button value={7}>最近7天</Radio.Button>
                                            <Radio.Button value={14}>最近14天</Radio.Button>
                                            <Radio.Button value={30}>最近30天</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <DatePicker.RangePicker
                                        onChange={(v, s)=>onDateFirstChange(v,s,'position')}
                                        allowClear={false}
                                        value={[positionStringStart,positionStringEnd]}
                                        disabledDate={disabledDate}
                                    />
                                </div>
                            </div>
                            <Divider />
                            <PositionOverview data={positionData}/>
                        </div>
                    </div>
                </div>

        </Fragment>
    );
};

export default AuhtOverview;
