import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Layout, Dropdown, Menu, Badge } from 'antd';
import {
    HomeOutlined,
    ApartmentOutlined,
    HddOutlined,
    CommentOutlined,
    CheckCircleOutlined,
    CaretDownOutlined,
    FileDoneOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { get, post } from '@/core/request';
import { localStorageSet } from '@/core/cookie';
import './Sidebar.less';
import { moduleListApi } from '@services/MedProject';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Sidebar() {
    // useLocation
    // https://reactrouter.com/docs/en/v6/api#uselocation
    let location = useLocation();
    const params = useParams();

    const [moduleState, setModuleState] = useState(null);
    const [count, setCount] = useState(0);
    const [activitiesModuleId, setActivitiesModuleId] = useState(0);

    const dispatch = useDispatch();
    // const filter = useSelector((state) => state.filter);
    useEffect(() => {
        const arr = localStorage.getItem('infos');
        if (arr) {
            let userName = JSON.parse(JSON.parse(arr).data).phone
            get(`/enterprise/staffs/get-modules`, { userName }).then((res) => {
                if (res.status_code === 200) {
                    setModuleState(res.data)
                }
            })
        }

        // 获取医项目 - 模块活动module - id
        if (params?.projectId && !params?.id) {
            moduleListApi({ projectId: params.projectId }).then(res => {
                if (res && res.status_code === 200 && res.data) {
                    const activitiesId = res.data.find(item => item.moduleType === 'activity')?.id;
                    setActivitiesModuleId(activitiesId);
                }
            })
        }
    }, []);

    // 医项目 - 菜单联动 - 模块id
    useEffect(() => {
        if (location.pathname.startsWith('/medproject/projects') && params?.projectId && params?.id) {
            const pathArr = location.pathname.split('/');
            const moduleId = location.pathname.split('/')[pathArr.length - 1];
            setActivitiesModuleId(moduleId);
        }
    }, [location.pathname]);

    // 业务模块列表
    // 默认企业管理模块 active
    const moduleList = [
        { route: '/', icon: <HomeOutlined/>, title: '首页' },
        {
            route: '/medproject/projects',
            title: '医项目',
            icon: <>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-rocket-fill" viewBox="0 0 16 16">
                    <path d="M10.175 1.991c.81 1.312 1.583 3.43 1.778 6.819l1.5 1.83A2.5 2.5 0 0 1 14 12.202V15.5a.5.5 0 0 1-.9.3l-1.125-1.5c-.166-.222-.42-.4-.752-.57-.214-.108-.414-.192-.627-.282l-.196-.083C9.7 13.793 8.85 14 8 14c-.85 0-1.7-.207-2.4-.635-.068.03-.133.057-.198.084-.211.089-.411.173-.625.281-.332.17-.586.348-.752.57L2.9 15.8a.5.5 0 0 1-.9-.3v-3.298a2.5 2.5 0 0 1 .548-1.562l.004-.005L4.049 8.81c.197-3.323.969-5.434 1.774-6.756.466-.767.94-1.262 1.31-1.57a3.67 3.67 0 0 1 .601-.41A.549.549 0 0 1 8 0c.101 0 .17.027.25.064.037.017.086.041.145.075.118.066.277.167.463.315.373.297.85.779 1.317 1.537ZM9.5 6c0-1.105-.672-2-1.5-2s-1.5.895-1.5 2S7.172 8 8 8s1.5-.895 1.5-2"/>
                    <path d="M8 14.5c.5 0 .999-.046 1.479-.139L8.4 15.8a.5.5 0 0 1-.8 0l-1.079-1.439c.48.093.98.139 1.479.139"/>
                </svg>
            </>,
        },
        { route: '/invitation/groups', icon: <FileDoneOutlined/>, title: '医邀请' },
        { route: '/conf/conferences', icon: <TeamOutlined/>, title: '医会议' },
        { route: '/selection/projects', icon: <TeamOutlined/>, title: '医评选' },
        { route: '/wj/surveys', icon: <HddOutlined/>, title: '医调研' },
        { route: '/healthclass/CheckOrganizations', icon: <HddOutlined/>, title: '健学云' },
        { route: '/kb/rearch', icon: <HddOutlined/>, title: '研究型医院' },
        { route: '/healthaccount/eha/enterprises', icon: <TeamOutlined/>, title: '健康号' },
        { route: '/uc/experts', icon: <CommentOutlined/>, title: '用户中心' },
        { route: '/operation', icon: <CommentOutlined/>, title: '运营中心' },
        { route: '/finance/laborfee/projects', icon: <HomeOutlined/>, title: '财务中心'},
        { route: '/enterprise/staffs', icon: <ApartmentOutlined/>, title: '企业管理', active: 1 },
        { route: '/medconnect/projects', icon: <TeamOutlined/>, title: '医患通'},
        // {route: '/dms', icon: <VideoCameraOutlined/>, title: '数字化营销'},
    ];

    const [modules, setModules] = useState(moduleList);

    // 当前业务模块
    const [currentModule, setCurrentModule] = useState(moduleList.find(m => m.active));
    useEffect(() => {
        if (moduleState) {
            modules.forEach(module => {
                if (module.route !== '/' && window.location.pathname.indexOf(module.route) === 0) {
                    module['active'] = 1;
                    setCurrentModule(module);
                } else {
                    delete module['active'];
                }
            });
            setModules(modules);
        }
    }, [location, currentModule, modules, moduleState]);

    const moduleMenu = <Menu className="module-menu">
        {modules.map((module, index) => {
            return (
                <Menu.Item className={`module${module.active ? ' active' : ''}`} key={index}>
                    <Link to={module.route}>
                        <span className="module-icon">{module.icon}</span>{module.title}
                        {!!module.active && (
                            <span className="icon-active"><CheckCircleOutlined/></span>
                        )}
                    </Link>
                </Menu.Item>
            )
        })}
    </Menu>;

    const [subMenuList, setSubMenuList] = useState([]);
    const { pathname } = location;

    useEffect(() => {
        let modulesCode = '';
        let userName = '';

        if (pathname === '/uc') {
            modulesCode = 'USER';

            post("/enterprise/staffs/modular-login", { modulesCode, userName })
                .then((res) => {
                    if (localStorage.getItem('permissionsList')) {
                        localStorage.removeItem('permissionsList')
                    }
                    if (localStorage.getItem('infos')) {
                        localStorage.removeItem('infos')
                    }
                    if (res.status_code === 200) {
                        setSubMenuList(res.data.permissionsList);
                        localStorageSet('token', res.data.token);
                        localStorageSet('infos', JSON.stringify(res.data.infos));
                        localStorageSet('permissionsList', JSON.stringify(res.data.permissionsList));
                    }
                });
        } else if (pathname === '/operation') {
            modulesCode = 'CHANNEL';
            post("/enterprise/staffs/modular-login", { modulesCode, userName })
                .then((res) => {
                if (localStorage.getItem('permissionsList')) {
                    localStorage.removeItem('permissionsList')
                }
                if (localStorage.getItem('infos')) {
                    localStorage.removeItem('infos')
                }
                if (res.status_code === 200) {
                    setSubMenuList(res.data.permissionsList);
                    localStorageSet('token', res.data.token);
                    localStorageSet('infos', JSON.stringify(res.data.infos));
                    localStorageSet('permissionsList', JSON.stringify(res.data.permissionsList));
                }
            });
        } else if (pathname.startsWith('/healthclass')) {
            modulesCode = 'HEALTH';
            post("/enterprise/staffs/modular-login", {
                modulesCode, userName
            }).then((res) => {
                if (localStorage.getItem('permissionsList')) {
                    localStorage.removeItem('permissionsList')
                }
                if (localStorage.getItem('infos')) {
                    localStorage.removeItem('infos')
                }
                if (res.status_code === 200) {
                    setSubMenuList(res.data.permissionsList);
                    localStorageSet('token', res.data.token);
                    localStorageSet('infos', JSON.stringify(res.data.infos));
                    localStorageSet('permissionsList', JSON.stringify(res.data.permissionsList));
                }
            });
        } else if (pathname === 'invitation') {
            // modulesCode = 'INVITATION';
            // post("/enterprise/staffs/modular-login", {
            //     modulesCode, userName
            // }).then((res) => {
            //     if (localStorage.getItem('permissionsList')) {
            //         localStorage.removeItem('permissionsList')
            //     }
            //     if (localStorage.getItem('infos')) {
            //         localStorage.removeItem('infos')
            //     }
            //     if (res.status_code === 200) {
            //         setSubMenuList(res.data.permissionsList);
            //         localStorageSet('token', res.data.token);
            //         localStorageSet('infos', JSON.stringify(res.data.infos));
            //         localStorageSet('permissionsList', JSON.stringify(res.data.permissionsList));
            //     }
            // });
        } else if (pathname === '/conf') {
            modulesCode = 'CONF';
            // post("/enterprise/staffs/modular-login", {
            //     modulesCode, userName
            // }).then((res) => {
            //     if (localStorage.getItem('permissionsList')) {
            //         localStorage.removeItem('permissionsList')
            //     }
            //     if (localStorage.getItem('infos')) {
            //         localStorage.removeItem('infos')
            //     }
            //     if (res.status_code === 200) {
            //         setSubMenuList(res.data.permissionsList);
            //         localStorageSet('token', res.data.token);
            //         localStorageSet('infos', JSON.stringify(res.data.infos));
            //         localStorageSet('permissionsList', JSON.stringify(res.data.permissionsList));
            //     }
            // });
        }
    }, [pathname]);

    useEffect(() => {
        const arr = localStorage.getItem('permissionsList');
        if (arr) {
            setSubMenuList(JSON.parse(JSON.parse(arr).data));
        }
    }, [pathname]);

    useEffect(() => {
        if (pathname.startsWith('/healthclass')) {
            get(`/healthclass/boss/organizations/queryByCount`, {}).then((res) => {
                if (res.status_code === 200) {
                    setCount(res.data)
                }
            })
        }
    }, [pathname]);

    let enterprisePathname = location.pathname.startsWith('/enterprise') || location.pathname.startsWith('/my');

    return (
        <Sider width={200} className="sidebar">
            <div className="module-switcher cursor-pointer">
                <Dropdown overlay={moduleMenu} placement="bottom" arrow>
                    <div className="current-module">
                        <span className="module-icon">{currentModule.icon}</span>
                        <span className="module-name">{currentModule.title}</span>
                        <span className="module-icon-down"><CaretDownOutlined/></span>
                    </div>
                </Dropdown>
            </div>
            {enterprisePathname && !location.pathname.startsWith('/dms') &&
                <Menu mode="inline"
                      selectedKeys={[location.pathname]}
                      defaultOpenKeys={['enterprise-staffs', 'enterprise-roles', 'my', 'operation/log']}
                >
                    <SubMenu title='企业管理' key='enterprise-staffs'>
                        <Menu.Item key='/enterprise/staffs'>
                            <Link to='/enterprise/staffs'>员工管理</Link>
                        </Menu.Item>
                        <Menu.Item key='/enterprise/departments'>
                            <Link to='/enterprise/departments'>部门管理</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title='系统权限' key='enterprise-roles'>
                        <Menu.Item key='/enterprise/roles'>
                            <Link to='/enterprise/roles'>角色管理</Link>
                        </Menu.Item>
                        <Menu.Item key='/enterprise/permissions'>
                            <Link to='/enterprise/permissions'>权限管理</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title='个人中心' key='my'>
                        <Menu.Item key='/my/messages'>
                            <Link to='/my/messages'>个人信息</Link>
                        </Menu.Item>
                        <Menu.Item key='/my/changepassword'>
                            <Link to='/my/changepassword'>密码安全</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title='系统管理' key='operation/log'>
                        <Menu.Item key='/enterprise/operation/log'>
                            <Link to='/enterprise/operation/log'>操作日志</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            }

            {location.pathname === '/medproject/projects' && (
                <Menu mode="inline" defaultSelectedKeys={['/medproject/projects']}>
                    <Menu.Item key="/medproject/projects">
                        <Link to="/medproject/projects">医项目管理</Link>
                    </Menu.Item>
                    {/*<Menu.Item key="/medproj/list">*/}
                    {/*    <Link to="/medproj/list">医项目管理（弃用）</Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            )}

            {location.pathname !== '/medproject/projects' && location.pathname.startsWith('/medproject/projects/') && (
                <Menu
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    defaultSelectedKeys={[`/medproject/projects/${params?.projectId || params?.id}/modules/1`]}
                >
                    <Menu.Item key={`/medproject/projects/${params?.projectId || params?.id}/modules/${activitiesModuleId}`}>
                        <Link to={`/medproject/projects/${params?.projectId || params?.id}/modules/${activitiesModuleId}`}>模块管理</Link>
                    </Menu.Item>
                    <Menu.Item key={`/medproject/projects/${params?.projectId || params?.id}/quick-entries`}>
                        <Link to={`/medproject/projects/${params?.projectId || params?.id}/quick-entries`}>快捷入口</Link>
                    </Menu.Item>
                    <Menu.Item key={`/medproject/projects/${params?.projectId || params?.id}/members`}>
                        <Link to={`/medproject/projects/${params?.projectId || params?.id}/members`}>成员管理</Link>
                    </Menu.Item>
                    <Menu.Item key={`/medproject/projects/${params?.projectId || params?.id}/notices`}>
                        <Link to={`/medproject/projects/${params?.projectId || params?.id}/notices`}>通知管理</Link>
                    </Menu.Item>
                    <Menu.Item key={`/medproject/projects/${params.projectId || params?.id}`}>
                        <Link to={`/medproject/projects/${params.projectId || params?.id}`}>项目信息</Link>
                    </Menu.Item>
                </Menu>
            )}

            {location.pathname.startsWith('/uc') && (
                <Menu mode="inline"
                      selectedKeys={[location.pathname]}
                      defaultOpenKeys={['expert-data', 'user-data', 'uc-mdm', 'consultations-data']}
                >
                    <SubMenu title='专家库' key='expert-data'>
                        <Menu.Item key='/uc/experts'>
                            <Link to='/uc/experts'>专家列表</Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key='/uc/users'>
                        <Link to='/uc/users'>用户管理</Link>
                    </Menu.Item>
                    <Menu.Item key='/uc/user-groups'>
                        <Link to='/uc/user-groups'>用户分群</Link>
                    </Menu.Item>
                    <SubMenu title='用户数据' key='user-data'>
                        <Menu.Item key='/uc/user/overview'>
                            <Link to='/uc/user/overview'>用户数据总览</Link>
                        </Menu.Item>
                        <Menu.Item key='/uc/user/active-user'>
                            <Link to='/uc/user/active-user'>活跃用户统计</Link>
                        </Menu.Item>
                        <Menu.Item key='/uc/user/auth-user-department'>
                            <Link to='/uc/user/auth-user-department'>医务人员科室分布</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title="医疗决策人数据" key="uc-mdm">
                        <Menu.Item key="/uc/mdm/auth-overview">
                            <Link to="/uc/mdm/auth-overview">医疗决策人认证总览</Link>
                        </Menu.Item>
                        <Menu.Item key="/uc/mdm/online-overview">
                            <Link to="/uc/mdm/online-overview">医疗决策人在线总览</Link>
                        </Menu.Item>
                        <Menu.Item key="/uc/mdm/projects">
                            <Link to="/uc/mdm/projects">参与项目统计</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title='用户咨询' key='consultations-data'>
                        <Menu.Item key='/uc/user/consultations'>
                            <Link to='/uc/user/consultations'>用户咨询列表</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            )}

            {location.pathname.startsWith('/operation') && !location.pathname.startsWith('/operation/log') && (
                <Menu mode="inline" selectedKeys={[location.pathname]}
                      defaultOpenKeys={["operation-source", "operation-data"]}>
                    <SubMenu title='渠道来源' key='operation-source'>
                        <Menu.Item key='/operation/channels'>
                            <Link to='/operation/channels'>渠道管理</Link>
                        </Menu.Item>
                        <Menu.Item key='/operation/channel-cates'>
                            <Link to='/operation/channel-cates'>渠道分类</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title='渠道数据' key='operation-data'>
                        <Menu.Item key='/operation/bi/channel/users'>
                            <Link to='/operation/bi/channel/users'>渠道用户数据</Link>
                        </Menu.Item>
                        <Menu.Item key='/operation/bi/channel/view'>
                            <Link to='/operation/bi/channel/view'>渠道浏览数据</Link>
                        </Menu.Item>
                        <Menu.Item key='/operation/bi/channel/surveys'>
                            <Link to='/operation/bi/channel/surveys'>渠道问卷数据</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            )}

            {location.pathname.startsWith('/kb') && (
                <Menu mode="inline"
                      selectedKeys={[location.pathname]}
                      defaultOpenKeys={["operation-source"]}
                >
                    <SubMenu title="研究型医院" key="operation-source">
                        <Menu.Item key="/kb/rearch"><Link to='/kb/rearch'>数据填报管理</Link></Menu.Item>
                    </SubMenu>
                </Menu>
                )
            }

            {location.pathname.startsWith('/healthclass') && (
                <Menu mode="inline" defaultOpenKeys={["healthclass-data"]}>
                    {subMenuList.length !== 0 && subMenuList.map(item => {
                        return (
                            <SubMenu title={item.name} key={item.id}>
                                {item.childList.length !== 0 && item.childList.map(elem => {
                                    return (
                                        <SubMenu title={elem.name} key={elem.id}>
                                            {elem.resourceList.length !== 0 && elem.resourceList.map(element => {
                                                    if (element.type === 1) {
                                                        return (
                                                            <Menu.Item key={element.id}>
                                                                <Link to={element.route}>{element.name}</Link>
                                                            </Menu.Item>
                                                        )
                                                    }
                                                })
                                            }
                                        </SubMenu>
                                    )
                                })}
                                {item.resourceList.length !== 0 && item.resourceList.map(elem => {
                                    if (elem.type === 1) {
                                        return (
                                            <Menu.Item key={elem.name}>
                                                {elem.name === '学苑审核' ?
                                                    (<Link to={elem.route}><Badge count={count} offset={[20, 5]}>{elem.name}</Badge></Link>) :
                                                    (<Link to={elem.route}>{elem.name}</Link>)
                                                }
                                            </Menu.Item>
                                        )
                                    }
                                })}
                            </SubMenu>
                        )
                    })
                    }
                    <SubMenu title='线索管理' key='healthclass-clue'>
                        <Menu.Item key='/healthclass/clue'> <Link to='/healthclass/clue'>咨询用户</Link></Menu.Item>
                    </SubMenu>
                </Menu>
            )}

            {location.pathname.startsWith('/wj') && (
                <Menu
                    mode="inline"
                    defaultOpenKeys={['wj-settings', 'wj-manege']}
                    defaultSelectedKeys={['/wj/surveys']}
                >
                    <SubMenu title='基础配置' key='wj-settings'>
                        <Menu.Item key='/wj/questionbank/settings'>
                            <Link to='/wj/questionbank/settings'>题库配置</Link>
                        </Menu.Item>
                        <Menu.Item key='/wj/templates'>
                            <Link to='/wj/templates'>问卷模版</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu title='问卷管理' key='wj-manege'>
                        <Menu.Item key='/wj/surveys'>
                            <Link to='/wj/surveys'>问卷列表</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            )}

            {location.pathname.startsWith("/invitation") && (
                <Menu mode="inline" defaultSelectedKeys={['/invitation/groups']}>
                    <Menu.Item key="/invitation/groups">
                        <Link to="/invitation/groups">邀请组列表</Link>
                    </Menu.Item>
                </Menu>
            )}

            {location.pathname.startsWith('/conf') && (
                <Menu mode="inline" defaultSelectedKeys={['conf-conference-list']}>
                    <Menu.Item key="conf-conference-list">
                        <Link to="/conf/conferences">会议项目</Link>
                    </Menu.Item>
                    <Menu.Item key="conf-conferences-usage">
                        <Link to="/conf/conferences/usage">应用统计</Link>
                    </Menu.Item>
                    <Menu.Item key="conf/conferences/labor-progress">
                        <Link to="/conf/conferences/labor-progress">项目劳务进度</Link>
                    </Menu.Item>
                </Menu>
            )}

            {location.pathname.startsWith('/bi') && (
                <Menu mode="inline" defaultOpenKeys={["bi-mdm"]} defaultSelectedKeys={[]}>
                    <Menu.Item key="bi">
                        <Link to="/bi">首页</Link>
                    </Menu.Item>

                </Menu>
            )}

            {location.pathname.startsWith('/healthaccount') && (
                <Menu mode="inline" defaultOpenKeys={['eha-enterprises']} defaultSelectedKeys={['/healthaccount/eha/enterprises']}>
                    <SubMenu title='企业健康号' key='eha-enterprises'>
                        <Menu.Item key='/healthaccount/eha/enterprises'>
                            <Link to='/healthaccount/eha/enterprises'>医企+企业管理</Link>
                        </Menu.Item>
                        <Menu.Item key='/healthaccount/eha/solutions'>
                            <Link to='/healthaccount/eha/solutions'>解决方案管理</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            )}

            {location.pathname.startsWith('/selection') && (
                <Menu mode="inline" defaultOpenKeys={['/selection/projects']}
                      defaultSelectedKeys={['/selection/projects']}>
                    <Menu.Item key='/selection/projects'>
                        <Link to='/selection/projects'>评选项目管理</Link>
                    </Menu.Item>
                    <Menu.Item key='/selection/cases'>
                        <Link to='/selection/cases'>案例管理</Link>
                    </Menu.Item>
                </Menu>
            )}

            {location.pathname.startsWith('/finance') && (
                <Menu mode="inline" defaultSelectedKeys={['finance-laborfee-projects']}>
                    <Menu.Item key="finance-laborfee-projects">
                        <Link to="/finance/laborfee/projects">付款项目管理</Link>
                    </Menu.Item>
                </Menu>
            )}

            {location.pathname === '/medconnect/projects' && (
                <Menu mode="inline" defaultSelectedKeys={['medconnect-projects']}>
                    <Menu.Item key='medconnect-projects'>
                        <Link to='/medconnect/projects'>问答项目管理</Link>
                    </Menu.Item>
                </Menu>
            )}

            {location.pathname !== '/medconnect/projects' && location.pathname.startsWith('/medconnect/projects/') && (
                <Menu mode="inline" defaultSelectedKeys={[`/medconnect/projects/${params?.id}`]}>
                    <Menu.Item key={`/medconnect/projects/${params?.id}`}>
                        <Link to={`/medconnect/projects/${params?.id}`}>项目配置</Link>
                    </Menu.Item>
                    <SubMenu title='问答管理' key={`/medconnect/projects/${params?.id}/list`}>
                        <Menu.Item key={`/medconnect/projects/${params?.id}/question-list`}>
                            <Link to={`/medconnect/projects/${params?.id}/question-list`}>问题管理</Link>
                        </Menu.Item>
                        <Menu.Item key={`/medconnect/projects/${params?.id}/answer-list`}>
                            <Link to={`/medconnect/projects/${params?.id}/answer-list`}>回答管理</Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key={`/medconnect/projects/${params?.id}/users`}>
                        <Link to={`/medconnect/projects/${params?.id}/users`}>人员管理</Link>
                    </Menu.Item>
                    <Menu.Item key={`/medconnect/projects/${params?.id}/labfee/batches`}>
                        <Link to={`/medconnect/projects/${params?.id}/labfee/batches`}>劳务管理</Link>
                    </Menu.Item>
                </Menu>
            )}

        </Sider>
    )
};
