import React, { Fragment ,useState, useEffect} from 'react';
import './index.less';
import config from '@config';
const Cumputerpreview = ({content,preview}) => {
    console.log(content);
    console.log(config.SURVEY_DOMAIN);
    useEffect(() => {
        if(preview){
            if(content.route==='newCreate'){
                document.fr.location.href=`${config.SURVEY_DOMAIN}/s/${content.id}/result?template=1`;
            }else{
                document.fr.location.href=`${config.SURVEY_DOMAIN}/s/${content.id}/result?boss=1`;
            }
        }
    }, [content,preview]);



    return (
        <Fragment>
            <div className='cumputer-box'>
                <iframe name="fr" className='iframe-width'/>
            </div>
        </Fragment>
    );
}

export default Cumputerpreview;
