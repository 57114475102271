import med_01 from '@static/images/share/post_bg/med_01.jpg';
import med_02 from '@static/images/share/post_bg/med_02.jpg';
import med_03 from '@static/images/share/post_bg/med_03.jpg';
import med_04 from '@static/images/share/post_bg/med_04.jpg';
import med_05 from '@static/images/share/post_bg/med_05.jpg';
import med_06 from '@static/images/share/post_bg/med_06.jpg';
import med_07 from '@static/images/share/post_bg/med_07.jpg';
import med_08 from '@static/images/share/post_bg/med_08.jpg';
import med_09 from '@static/images/share/post_bg/med_09.jpg';
import med_10 from '@static/images/share/post_bg/med_10.jpg';
import med_11 from '@static/images/share/post_bg/med_11.jpg';
import med_12 from '@static/images/share/post_bg/med_12.jpg';
import med_13 from '@static/images/share/post_bg/med_13.jpg';
import med_14 from '@static/images/share/post_bg/med_14.jpg';
import med_15 from '@static/images/share/post_bg/med_15.jpg';
import med_16 from '@static/images/share/post_bg/med_16.jpg';
import med_17 from '@static/images/share/post_bg/med_17.jpg';
import med_18 from '@static/images/share/post_bg/med_18.jpg';
import med_19 from '@static/images/share/post_bg/med_19.jpg';
import med_20 from '@static/images/share/post_bg/med_20.jpg';
import med_21 from '@static/images/share/post_bg/med_21.jpg';
import med_22 from '@static/images/share/post_bg/med_22.jpg';
import med_23 from '@static/images/share/post_bg/med_23.jpg';
import med_24 from '@static/images/share/post_bg/med_24.jpg';
import med_25 from '@static/images/share/post_bg/med_25.jpg';


import biz_01 from '@static/images/share/post_bg/biz_01.jpg';
import biz_02 from '@static/images/share/post_bg/biz_02.jpg';
import biz_03 from '@static/images/share/post_bg/biz_03.jpg';
import biz_04 from '@static/images/share/post_bg/biz_04.jpg';
import biz_05 from '@static/images/share/post_bg/biz_05.jpg';
import biz_06 from '@static/images/share/post_bg/biz_06.jpg';
import biz_07 from '@static/images/share/post_bg/biz_07.jpg';
import biz_08 from '@static/images/share/post_bg/biz_08.jpg';
import biz_09 from '@static/images/share/post_bg/biz_09.jpg';
import biz_10 from '@static/images/share/post_bg/biz_10.jpg';
import biz_11 from '@static/images/share/post_bg/biz_11.jpg';
import biz_12 from '@static/images/share/post_bg/biz_12.jpg';
import biz_13 from '@static/images/share/post_bg/biz_13.jpg';
import biz_14 from '@static/images/share/post_bg/biz_14.jpg';
import biz_15 from '@static/images/share/post_bg/biz_15.jpg';
import biz_16 from '@static/images/share/post_bg/biz_16.jpg';
import biz_17 from '@static/images/share/post_bg/biz_17.jpg';
import biz_18 from '@static/images/share/post_bg/biz_18.jpg';
import biz_19 from '@static/images/share/post_bg/biz_19.jpg';
import biz_20 from '@static/images/share/post_bg/biz_20.jpg';
import biz_21 from '@static/images/share/post_bg/biz_21.jpg';
import biz_22 from '@static/images/share/post_bg/biz_22.jpg';
import biz_23 from '@static/images/share/post_bg/biz_23.jpg';
import biz_24 from '@static/images/share/post_bg/biz_24.jpg';
import biz_25 from '@static/images/share/post_bg/biz_25.jpg';


import nature_01 from '@static/images/share/post_bg/nature_01.jpg';
import nature_02 from '@static/images/share/post_bg/nature_02.jpg';
import nature_03 from '@static/images/share/post_bg/nature_03.jpg';
import nature_04 from '@static/images/share/post_bg/nature_04.jpg';
import nature_05 from '@static/images/share/post_bg/nature_05.jpg';
import nature_06 from '@static/images/share/post_bg/nature_06.jpg';
import nature_07 from '@static/images/share/post_bg/nature_07.jpg';
import nature_08 from '@static/images/share/post_bg/nature_08.jpg';
import nature_09 from '@static/images/share/post_bg/nature_09.jpg';
import nature_10 from '@static/images/share/post_bg/nature_10.jpg';
import nature_11 from '@static/images/share/post_bg/nature_11.jpg';
import nature_12 from '@static/images/share/post_bg/nature_12.jpg';
import nature_13 from '@static/images/share/post_bg/nature_13.jpg';
import nature_14 from '@static/images/share/post_bg/nature_14.jpg';
import nature_15 from '@static/images/share/post_bg/nature_15.jpg';
import nature_16 from '@static/images/share/post_bg/nature_16.jpg';
import nature_17 from '@static/images/share/post_bg/nature_17.jpg';
import nature_18 from '@static/images/share/post_bg/nature_18.jpg';
import nature_19 from '@static/images/share/post_bg/nature_19.jpg';
import nature_20 from '@static/images/share/post_bg/nature_20.jpg';
import nature_21 from '@static/images/share/post_bg/nature_21.jpg';
import nature_22 from '@static/images/share/post_bg/nature_22.jpg';
import nature_23 from '@static/images/share/post_bg/nature_23.jpg';
import nature_24 from '@static/images/share/post_bg/nature_24.jpg';
import nature_25 from '@static/images/share/post_bg/nature_25.jpg';


import simple_01 from '@static/images/share/post_bg/simple_01.jpg';
import simple_02 from '@static/images/share/post_bg/simple_02.jpg';
import simple_03 from '@static/images/share/post_bg/simple_03.jpg';
import simple_04 from '@static/images/share/post_bg/simple_04.jpg';
import simple_05 from '@static/images/share/post_bg/simple_05.jpg';
import simple_06 from '@static/images/share/post_bg/simple_06.jpg';
import simple_07 from '@static/images/share/post_bg/simple_07.jpg';
import simple_08 from '@static/images/share/post_bg/simple_08.jpg';
import simple_09 from '@static/images/share/post_bg/simple_09.jpg';
import simple_10 from '@static/images/share/post_bg/simple_10.jpg';
import simple_11 from '@static/images/share/post_bg/simple_11.jpg';
import simple_12 from '@static/images/share/post_bg/simple_12.jpg';
import simple_13 from '@static/images/share/post_bg/simple_13.jpg';
import simple_14 from '@static/images/share/post_bg/simple_14.jpg';
import simple_15 from '@static/images/share/post_bg/simple_15.jpg';
import simple_16 from '@static/images/share/post_bg/simple_16.jpg';
import simple_17 from '@static/images/share/post_bg/simple_17.jpg';
import simple_18 from '@static/images/share/post_bg/simple_18.jpg';
import simple_19 from '@static/images/share/post_bg/simple_19.jpg';
import simple_20 from '@static/images/share/post_bg/simple_20.jpg';
import simple_21 from '@static/images/share/post_bg/simple_21.jpg';
import simple_22 from '@static/images/share/post_bg/simple_22.jpg';
import simple_23 from '@static/images/share/post_bg/simple_23.jpg';
import simple_24 from '@static/images/share/post_bg/simple_24.jpg';
import simple_25 from '@static/images/share/post_bg/simple_25.jpg';

//医疗
export const medical=()=>{
    return ([
        med_01,
        med_02,
        med_03,
        med_04,
        med_05,
        med_06,
        med_07,
        med_08,
        med_09,
        med_10,
        med_11,
        med_12,
        med_13,
        med_14,
        med_15,
        med_16,
        med_17,
        med_18,
        med_19,
        med_20,
        med_21,
        med_22,
        med_23,
        med_24,
        med_25,
])}


//商务
export const business=()=>{
    return ([
        biz_01,
        biz_02,
        biz_03,
        biz_04,
        biz_05,
        biz_06,
        biz_07,
        biz_08,
        biz_09,
        biz_10,
        biz_11,
        biz_12,
        biz_13,
        biz_14,
        biz_15,
        biz_16,
        biz_17,
        biz_18,
        biz_19,
        biz_20,
        biz_21,
        biz_22,
        biz_23,
        biz_24,
        biz_25,
])}


//自然
export const nature=()=>{
    return ([
    nature_01,
    nature_02,
    nature_03,
    nature_04,
    nature_05,
    nature_06,
    nature_07,
    nature_08,
    nature_09,
    nature_10,
    nature_11,
    nature_12,
    nature_13,
    nature_14,
    nature_15,
    nature_16,
    nature_17,
    nature_18,
    nature_19,
    nature_20,
    nature_21,
    nature_22,
    nature_23,
    nature_24,
    nature_25,
])}


//简约
export const simple=()=>{
    return ([
    simple_01,
    simple_02,
    simple_03,
    simple_04,
    simple_05,
    simple_06,
    simple_07,
    simple_08,
    simple_09,
    simple_10,
    simple_11,
    simple_12,
    simple_13,
    simple_14,
    simple_15,
    simple_16,
    simple_17,
    simple_18,
    simple_19,
    simple_20,
    simple_21,
    simple_22,
    simple_23,
    simple_24,
    simple_25,
])}
