import React, {Fragment, useState, useCallback, useEffect} from "react";
import {Layout, Table, Modal, message, Switch,Dropdown,Button} from "antd";
import {useNavigate} from "react-router-dom";
import {fullPagerConfig} from "@/utils/property";
import {get, put} from "@/core/request";
import HealthclassFormalAcademyQueryFrom from "./FormalOrganizationsQueryFrom";
import { EllipsisOutlined } from "@ant-design/icons";
import AddPeople from "./components/AddPeople";
import AddLive from "./components/AddLive";
const {Content} = Layout;

export default function FormalOrganizations() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [addPeople, setAddPeople] = useState(false);
    const [addLive, setAddLive] = useState(false);
    const [rowData, setRowData] = useState(undefined);

    let navigate = useNavigate();

    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback((pageSize) => {
        setPageSize(pageSize);
    }, []);

    // 改变第几页
    const changeCurrPage = useCallback((page) => {
        setCurrPage(page);
    }, []);

    //分页数据
    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: "small",
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page);
        },
        current: currPage,
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const {
        code,
        name,
        belongsUnitName,
        adminName,
        state
    } = filterObj;

    const getData = () => {
        get(`/healthclass/boss/organizations/formalList`, {
            page: currPage,
            pageSize: pageSize,
            code,
            name,
            belongsUnitName,
            adminName,
            state
        }).then((res) => {
            if (res.status_code === 200) {
                setTableData(res.data);
            }
        });
    };

    const handleFilterFinish = (value) => {
        setFilterObj(value);
        setCurrPage(1);
    }

    // 渲染table数据
    useEffect(() => {
        getData();
    }, [
        pageSize,
        currPage,
        filterObj
    ]);

    const getFormalInfo=(row)=>{
        return () => {
            navigate(`/healthclass/FormalOrganizationsInfo`, {state: row});
        }
    }

    const onSwitchChange = (key, row) => {
        if (key){
            put('/healthclass/boss/organizations/updateState', {
                id: row.id,
                state: key ? 1 : 2
            }).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        navigate('/healthclass/FormalOrganizations');
                    }
                    getData()
                }
            );
        } else {
            Modal.confirm({
                title: '提示',
                content: '停用后学苑管理员将不能登录，是否确认关停？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    put('/healthclass/boss/organizations/updateState', {
                        id: row.id,
                        state: key ? 1 : 2
                    }).then((res) => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                navigate('/healthclass/FormalOrganizations');
                            }
                            getData()
                        }
                    );
                }
            });
        }

    }


    const closeAdd=()=>{
        setAddPeople(false);
    };
    const closeLive=()=>{
        setAddLive(false);
    };

    const addOperation = (row)=>{
        console.log(row);
        if (row.versionId===56){
            message.warning('当前学苑产品版本为基础版，无直播模块，不支持添加直播时长')
        }else{
            setAddLive(true);
            setRowData(row)
        }
    };

    const columns = [
        {title: "学苑ID", dataIndex: "code"},
        {title: "学苑名称", dataIndex: "name"},
        {title: "所属主体", dataIndex: "belongsUnitName"},
        {
            title: "主体类型", dataIndex: "belongsUnitType", render: (belongsUnitType, row) => (
                <Fragment>
                    {belongsUnitType === 2 && (<span> 机构</span>)}
                    {belongsUnitType === 1 && (<span> 个人</span>)}
                </Fragment>
            )
        },
        {title: "联系人", dataIndex: "adminName"},
        {title: "联系人手机", dataIndex: "phone"},
        {title: "产品版本", dataIndex: "versionId",render: (versionId, row) => (
            <Fragment>
                {versionId === 56 ?<span>基础版</span>:<span>专业版</span>}</Fragment>

        )},
        {title: "人数上限", dataIndex: "maxUserCount",render: (maxUserCount, row) => (
            <Fragment>
                {maxUserCount >=999999 ?<span>不限</span>:<span>{maxUserCount}人</span>}</Fragment>

        )},
        {title: "直播剩余时长", dataIndex: "liveTime",render: (liveTime, row) => (
            <Fragment>
                {<span>{liveTime}小时</span>}
            </Fragment>

        )},
        {title: "联系人手机", dataIndex: "phone"},
        {title: "到期时间", dataIndex: "endAt"},
        {title: "最近登录时间", dataIndex: "lastLoggedinAt"},
        {
            title: "状态", dataIndex: "state", render: (state, row) => (
                <Fragment>
                    <Switch checked={state === 1 ? true : false} checkedChildren="启用" unCheckedChildren="停用"
                            onChange={(key) => onSwitchChange(key, row)}/>
                </Fragment>)
        },
        {
            title: "操作", dataIndex: "state", render: (belongsUnitType, row) => {
                const items = [
                    {
                        label: "增加成员人数",
                        key: "people",
                        onClick: () => {setAddPeople(true);setRowData(row)},
                    },
                    {
                        label: "增加直播时长",
                        key: "live",
                        onClick: () => addOperation(row),
                    }
                ];

            return (
                <Fragment>
                    <span className="operate-style" onClick={getFormalInfo(row)}>管理</span>
                    <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                更多
                            </Button>
                        </Dropdown>
                </Fragment>
            )}
        },
    ]
    return (
        <Fragment>
            <div className="page-title">正式学苑管理</div>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="filter-form-box">
                        <HealthclassFormalAcademyQueryFrom handleFilterFinish={handleFilterFinish}/>
                    </div>
                    <Table
                        className="formalOrg-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                        rowKey={(row) => row.id}
                        scroll={{x: 'max-content'}}
                    />
                </div>
            </Content>
            <AddPeople addPeople={addPeople} closeAdd={closeAdd} rowData={rowData} getData={getData}/>
            <AddLive addLive={addLive} closeLive={closeLive} rowData={rowData} getData={getData}/>
        </Fragment>
    )

}
