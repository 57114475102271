import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Space,
    Table,
    Tooltip,
    message,
    Avatar,
    Typography,
    Drawer,
} from "antd";
import {
    LeftOutlined,
    RightOutlined,
    SearchOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CaretDownOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import { getJudgesListApi, addJudgesApi } from '@services/Selection';
import { uniqBy } from 'lodash';
const { Text } = Typography;

const AddGuest = ({
    closeAddUserDrawer,
    visibleAddUserDrawer,
    setVisibleAddUserDrawer,
    onResetGuest,
    currentTopics,
    sid,
    getJudgesList,
    addJudges,
}) => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [inPageSize, setInPageSize] = useState(10);
    const [inPage, setInPage] = useState(1);
    const [inTotal, setInTotal] = useState(0);
    const [selected, setSelected] = useState([]);
    const [delSelected, setDelSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveMore, setSaveMore] = useState([]);
    const [delMore, setDelMore] = useState([]);
    const isSearchRef = useRef(null);
    const [expertForm] = Form.useForm();
    const [inForm] = Form.useForm();
    const params = useParams();
    const [filObj, setFilObj] = useState({
        name: "",
        mobile: "",
    });

    let { id, pid } = params || {}
    if(sid) {
        id = sid;
    }

    const guestSelectionTableColumns = [
        {
            title: '姓名',
            dataIndex: 'memUsername',
            width: 150,
            render: (_, record) => {
                return <Fragment>
                    <Avatar style={{ marginRight: '4px' }} src={record?.expertAvatar} />
                    <span style={{ minWidth: '36px', display: "inline-block" }}>{record.memUsername}</span>
                </Fragment>;
            }
        },
        {
            title: '性别',
            dataIndex: 'memGender',
            width: 80,
            render: (_, record) =>
                record.memGender === 1
                    ? '男'
                    : record.memGender === 0
                        ? '女'
                        : <Text type="secondary">&ndash;</Text>
        },
        {
            title: "手机号",
            name: "memMobile",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    <span>{record.memMobile}</span>
                </Fragment>
            ),
        },
        {
            title: '单位任职',
            dataIndex: 'ssoMemberWorkExperiencesEntities',
            render: (_, record) => {
                return (
                    record?.ssoMemberWorkExperiencesEntities?.map((item, index) => {
                        return (
                            <div key={item.id}>
                                <span>{index + 1}.</span>
                                <span>{item.unitName} &nbsp;</span>
                                {item.unitOffice && <span>{item.unitOffice} &nbsp;</span>}
                                <span>{item.unitPost}</span>
                            </div>
                        )
                    })
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'selected',
            width: 100,
            render: (_, record) =>
                record.selected === 1
                    ? (<a style={{ color: 'blue' }}>已邀请</a>)
                    : record.selected === 0
                        ? (<a style={{ color: 'red' }}>未邀请</a>)
                        : <Text type="secondary">&ndash;</Text>
        },
    ]

    const [guestsToSelect, setGuestsToSelect] = useState([]);
    const [guestsSelected, setGuestsSelected] = useState([]);
    const [guestsFilter, setGuestsFilter] = useState([]);


    // 添加用户
    const addGuests = () => {
        if(addJudges) {
            addJudges({ judgeIds: guestsSelected.map((item) => item.id), projectId: pid, stageId: id, topicId: params.tid, caseId: params.id });
        } else {
            let expertIds = guestsSelected.map((item) => {return { expertId: item.id }});
            console.log(expertIds, 'expertIds');
            const params = {
                judgesVos: expertIds,
                projectId: pid,
                stageId: id,
                type1: currentTopics[0]?.topicId || "",
                type2: currentTopics[1]?.topicId || "",
                type3: currentTopics[2]?.topicId || "",

            }
            addJudgesApi(params).then(
                (res) => {
                    if (res && res.status_code === 200) {
                        message.success("添加成功");
                        setVisibleAddUserDrawer(false);
                        onResetGuest();
                    }
                }
            );
        }
        // TODO
    };

    const guestToSelectSelection = {
        selectedRowKeys: selected,
        getCheckboxProps: (record) => ({
            disabled: record.selected === 1
        }),
        onSelect: (record, select) => {
            if (select) {
                let originalArray = [...selected, record.id];
                let uniqueArray = Array.from(new Set(originalArray));
                let arr = uniqBy([...saveMore, record], item => item)
                arr = arr.filter(res => res)
                setSelected([...uniqueArray]);
                setSaveMore(arr);
            } else {
                let re = selected.filter((it) => it !== record.id);
                let arr = [...saveMore].filter(item => item.id != record.id)
                setSelected([...re]);
                setSaveMore(arr)

            }
        },
        onSelectAll: (select, selectedRows, changeRows) => {
            if (select) {
                let res = changeRows.map((i) => i.id);
                let originalArray = [...selected, ...res];
                let uniqueArray = Array.from(new Set(originalArray));
                let arr = uniqBy([...saveMore, ...selectedRows].filter(res => res), item => item)
                setSelected([...uniqueArray]);
                setSaveMore(arr);
            } else {
                let newIds = changeRows.map((i) => i.id);
                let originalArray = [...selected, ...newIds];
                let res = originalArray.filter((item) => originalArray.indexOf(item) === originalArray.lastIndexOf(item));
                let arr = [...saveMore].filter(item => {
                    return !changeRows.map(item => item.id).includes(item.id)
                })
                setSelected([...res])
                setSaveMore(arr);
            }
        },
    };

    const guestSelectedSelection = {
        selectedRowKeys: delSelected,
        onSelect: (record, select) => {
            if (select) {
                let originalArray = [...delSelected, record.id];
                let uniqueArray = Array.from(new Set(originalArray));
                setDelSelected([...uniqueArray]);
                setDelMore([...delMore, record]);
            } else {
                let re = delSelected.filter((it) => it !== record.id);
                setDelSelected([...re]);
            }
        },
        onSelectAll: (select, selectedRows, changeRows) => {
            if (select) {
                let res = changeRows.map((i) => i.id);
                let originalArray = [...delSelected, ...res];
                let uniqueArray = Array.from(new Set(originalArray));
                setDelSelected([...uniqueArray]);
                setDelMore([...delMore, ...selectedRows]);
            } else {
                let newIds = changeRows.map((i) => i.id);
                let originalArray = [...delSelected, ...newIds];
                let res = originalArray.filter((item) => originalArray.indexOf(item) === originalArray.lastIndexOf(item))
                setDelSelected([...res])
            }
        },
    };

    const getExpertlist = async (page, pageSize, mobile, name) => {
        setLoading(true);
        isSearchRef.current = false;
        let obj = {
            page,
            pageSize,
            username: name,
            mobile,
            projectId: pid,
            stageId: id
        };

        if(getJudgesList) {
            const parma = { page, limit: pageSize, stageId: id, caseId: params.id, topicId: params.tid }
            if(mobile) {
                parma.mobile = mobile;
            }
            if(name) {
                parma.username = name;
            }
            const list = await getJudgesList(parma)
            setGuestsToSelect(list.list);
            setTotal(list.totalCount);
            setLoading(false);
        } else {
            let res = await getJudgesListApi(obj);
            if (res.status_code === 200) {
                setGuestsToSelect(res.data.list);
                setTotal(res.data.totalCount);
                setLoading(false);
            }
        }
    };

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onChangeInPage = (page, pageSize) => {
        setInPage(page);
        setInPageSize(pageSize);
    };


    const expertFormFinish = (res) => {
        isSearchRef.current = true;
        setFilObj({
            name: expertForm.getFieldValue("name")
                ? expertForm.getFieldValue("name")
                : "",
            mobile: expertForm.getFieldValue("mobile")
                ? expertForm.getFieldValue("mobile")
                : "",
        });
        if (
            (expertForm.getFieldValue("name") === undefined ||
                expertForm.getFieldValue("name") === "") &&
            (expertForm.getFieldValue("mobile") === undefined ||
                expertForm.getFieldValue("mobile") === "")
        ) {
            setPage(1);
        }
        expertForm.resetFields();
    };


    const filterIn = () => {

        let old = [...guestsSelected]
        if (inForm.getFieldValue('name') !== undefined || inForm.getFieldValue('name') !== '') {
            let fil = guestsFilter.filter((item) => {
                return item?.memUsername.indexOf(inForm.getFieldValue('name')) > -1;
            });
            setGuestsSelected([...fil])
            setInTotal(fil.length)
        } else {
            setGuestsSelected([...old])
            setInTotal(old.length)
        }

    }

    const onInsert = () => {
        let old = guestsSelected ? [...guestsSelected] : [];
        let inside = [...old, ...saveMore];
        inside = inside.filter(i => i !== undefined)
        let res = inside.filter(function (item, index, self) {
            return self.findIndex((el) => el.id == item.id) === index;
        });

        setGuestsSelected([...res]);
        setGuestsFilter([...res]);
        setInTotal(res.length)
        setSaveMore([])
    };

    const onDelete = () => {
        let res = guestsSelected.filter(
            (item) => !delSelected.includes(item.id)
        );
        let sel = res.map((i) => i.id);
        setSelected([...sel]);
        setDelSelected([]);
        setGuestsSelected([...res]);
        setGuestsFilter([...res]);
        setInTotal(res.length)
    };
    const onAllInsert = () => {
        let old = guestsSelected ? [...guestsSelected] : [];
        let inside = [...old, ...guestsToSelect];
        let uniqueArray = uniqBy(inside.filter(item => item.selected === 0), item => item.id);
        let uniqueSelectArray = uniqBy(uniqueArray.map(item => item.id), item => item);
        setSelected([...uniqueSelectArray]);
        setGuestsSelected([...uniqueArray]);
        setGuestsFilter([...uniqueArray]);
        setInTotal(uniqueArray.length)
    };

    const onAllDeleted = () => {
        setSelected([]);
        setDelSelected([]);
        setGuestsSelected([]);
        setGuestsFilter([]);
        setInTotal(0)
    };

    useEffect(() => {
        const { mobile, name } = filObj;
        if (visibleAddUserDrawer) {
            if (isSearchRef.current === true) {
                getExpertlist(1, pageSize, mobile, name);
            } else {
                getExpertlist(page, pageSize, mobile, name);
            }
        }
    }, [page, pageSize, filObj, visibleAddUserDrawer]);

    useEffect(() => {
        setGuestsSelected([]);
        setSelected([]);
        setSaveMore([])
    }, [visibleAddUserDrawer])


    return (
        <div>
            <Drawer
                title="添加评委"
                placement="bottom"
                height="100%"
                destroyOnClose
                open={visibleAddUserDrawer}
                onClose={closeAddUserDrawer}
                footer={
                    <div
                        className="custom-drawer-footer"
                        style={{ textAlign: "right" }}
                    >
                        <Space>
                            <Button onClick={closeAddUserDrawer}>取消</Button>
                            <Button type="primary" onClick={addGuests}>
                                确定
                            </Button>
                        </Space>
                    </div>
                }
            >
                <div className="guest-selection-area">
                    <div className="guests-to-select box-hover-shadow">
                        <div className="guest-selection-title">
                            <CaretDownOutlined /> 可选评委
                        </div>
                        <div className="guest-selection-filter">
                            <Form
                                layout="inline"
                                size="small"
                                form={expertForm}
                            >
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="评委姓名" />
                                </Form.Item>
                                <Form.Item label="手机" name="mobile">
                                    <Input placeholder="评委手机号" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        icon={<SearchOutlined />}
                                        onClick={expertFormFinish}
                                    >
                                        检索
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            size="small"
                            columns={guestSelectionTableColumns}
                            dataSource={guestsToSelect}
                            rowKey={(row) => row.id}
                            rowSelection={{
                                type: "checkbox",
                                ...guestToSelectSelection,
                            }}
                            loading={loading}
                            pagination={{
                                total: total,
                                showTotal: () => `共${total}条`,
                                current: page,
                                pageSize: pageSize,
                                onChange: onChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                        />
                    </div>
                    <div className="operations">
                        <Tooltip title="添加当前页全部用户" placement="right">
                            <Button onClick={onAllInsert}>
                                <DoubleRightOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="添加选中用户" placement="right">
                            <Button onClick={onInsert}>
                                <RightOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="移除选中" placement="left">
                            <Button onClick={onDelete}>
                                <LeftOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="移除全部" placement="left">
                            <Button onClick={onAllDeleted}>
                                <DoubleLeftOutlined />
                            </Button>
                        </Tooltip>
                    </div>

                    <div className="guests-selected box-hover-shadow">
                        <div className="guest-selection-title">
                            <CaretDownOutlined /> 已选评委
                        </div>
                        <div className="guest-selection-filter">
                            <Form layout="inline" size="small" form={inForm}>
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="评委姓名" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        onClick={filterIn}
                                        icon={<FilterOutlined />}
                                    >
                                        过滤
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            size="small"
                            columns={guestSelectionTableColumns}
                            dataSource={guestsSelected}
                            rowKey={(rows) => rows.id}
                            pagination={{
                                // total: inTotal,
                                showTotal: () => `共${inTotal}条`,
                                current: inPage,
                                pageSize: inPageSize,
                                onChange: onChangeInPage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                            rowSelection={{
                                type: "checkbox",
                                ...guestSelectedSelection,
                            }}
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AddGuest;
