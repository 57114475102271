import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Form, Input, Divider, Button, Select, Switch, DatePicker, Space, Drawer, Row, Col, message } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { get } from '@/core/request';
import UploadImage from '../../Common/UploadImage';

const { RangePicker } = DatePicker;

function ProductionAddForm({
    orgId,
    onHandlePutFinish,
    onHandleFinish
}, ref) {

    const [form] = Form.useForm();
    const ColItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    const drawerFormItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
    };

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [tip, setTip] = useState('');
    //  产品线LOGO
    const [imgUrl, setImgUrl] = useState('');
    const [rowData, setRowData] = useState({});
    const [fileList, setFileList] = useState([]);

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(tip, row) {
            setTip(tip);
            setVisible(true);
            setFileList([]);
            if (tip === 'edit') {
                get(`/dms/production-lines/findById`, {
                    productId: row.id
                }).then((res) => {
                    if (res.status_code === 200) {
                        const { name, domain, address, orgId, cooperationStartTime, cooperationEndTime, contact, phone, email, wechat, department, duty, isJkjDisplay, isCooperation } = res.data;
                        form.setFieldsValue({
                            name: name,
                            domain: domain,
                            address: address,
                            orgId: orgId,
                            time: [moment(cooperationStartTime, dateFormat), moment(cooperationEndTime, dateFormat)],
                            contact: contact,
                            phone: phone,
                            email: email,
                            wechat: wechat,
                            department: department,
                            duty: duty,
                            isJkjDisplay: isJkjDisplay === 1,
                            isCooperation: isCooperation === 1,
                        });
                        setImgUrl(res.data.logoUrl);
                        setFileList([{
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            url: res.data.logoUrl,
                        }]);
                        setRowData(res.data)
                    }
                })
            }
        }
    }));

    const handleClose = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                if (tip === 'add') {
                    if (imgUrl === "") {
                        message.warning('产品线LOGO不能为空');
                        return
                    }
                }
                values.isCooperation = values.isCooperation === undefined ? false : values.isCooperation;
                values.isJkjDisplay = values.isJkjDisplay === undefined ? false : values.isJkjDisplay;
                if ((values.isCooperation === true && values.isJkjDisplay === false) || (values.isCooperation === false && values.isJkjDisplay === true)) {
                    message.warning('是否在官网显示和是否已合作应同时开启或取消');
                    return
                }
                values.cooperationStartTime = timePicker[0];
                values.cooperationEndTime = timePicker[1];
                delete values.time;
                values.logoUrl = imgUrl;
                values.isCooperation = values.isCooperation === true ? 1 : 0;
                values.isJkjDisplay = values.isJkjDisplay === true ? 1 : 0;
                if (tip === 'add') {
                    onHandleFinish(values);
                } else {
                    values.id = rowData.id;
                    onHandlePutFinish(values)
                }
                handleClose();
            })
            .catch((err) => { });
        setPedding(false);
    };

    return (
        <Fragment>
            {
                visible && <Drawer
                    closable={false}
                    width='800px'
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <div className="main-content-body">
                        <h2>基本信息</h2>
                        <Form {...drawerFormItemLayout} form={form}>

                            <Form.Item
                                name='name'
                                label="产品线名称"
                                rules={[{ required: true, message: '请填写产品线名称' }]}
                            >
                                <Input placeholder="请输入产品线名称" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item
                                name='domain'
                                label="产品线域名"
                                required={true}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!/^[a-zA-Z]+$/.test(value)) {
                                                return Promise.reject('域名只能是英文');
                                            } else if (!value) {
                                                return Promise.reject('请输入域名');
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input placeholder="请输入产品线域名" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item
                                label="产品线LOGO"
                                name="logoUrl"
                                help="建议尺寸240*80px，小于512KB，支持JPG/JPEG/PNG格式"
                                required={true}
                            // rules={[{ validator: checkPic }]}
                            >
                                <UploadImage setFileList={setFileList} fileList={fileList} size={0.5} setImgUrl={setImgUrl}></UploadImage>
                            </Form.Item>

                            <Form.Item
                                style={{ marginBottom: '10px' }}
                                label="所属企业"
                                name="orgId"
                                rules={[{ required: true, message: '请选择所属企业' }]}
                            >
                                <Select allowClear placeholder="请选择所属企业">
                                    {orgId && orgId.map(item => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>

                            </Form.Item>
                            {
                                tip === 'add' ? (<div style={{ marginTop: '10px', marginLeft: '120px' }}>如无所属企业，<Link to="/dms/enterprises">点击新增</Link></div>
                                ) : (
                                    <Fragment></Fragment>
                                )
                            }

                            {
                                tip === 'edit' ? (<Form.Item
                                    style={{ marginTop: '20px' }}
                                    label="企业LOGO"
                                >
                                    <img className="enterprise-img" src={rowData.orgLogoUrl} alt="" />
                                </Form.Item>
                                ) : (
                                    <Fragment></Fragment>
                                )
                            }

                            <Form.Item
                                name="isJkjDisplay"
                                valuePropName="checked"
                                label="是否在官网显示"
                                help="开启后，将在官网合作伙伴中展示"
                                required={true}>
                                <Switch />
                            </Form.Item>

                            <Form.Item
                                name="isCooperation"
                                valuePropName="checked"
                                label="是否已合作"
                                help="开启后，将生成企业帐号和链接。如未确立合作，请勿开启"
                                required={true}>
                                <Switch />
                            </Form.Item>

                            <Form.Item
                                name="time"
                                label="合作时间"
                                rules={[{ required: true, message: '请选择合作时间' }]}
                            >
                                <RangePicker
                                    allowClear
                                    onChange={handleChangeTime}
                                    showTime />
                            </Form.Item>

                            <Form.Item
                                name="address"
                                label="企业地址">
                                <Input placeholder="请输入企业地址" allowClear autoComplete="off" />
                            </Form.Item>

                            {
                                tip === 'add' ? (<Fragment></Fragment>) : (
                                    <Row style={{ marginLeft: '50px' }}>
                                        <Col span={8}>
                                            <Form.Item
                                                {...ColItemLayout}
                                                label="产品线ID">
                                                {rowData.id}
                                            </Form.Item>
                                            <Form.Item
                                                {...ColItemLayout}
                                                label="操作人">
                                                {rowData.createdByStaffName}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                {...ColItemLayout}
                                                label="创建时间">
                                                {rowData.createdAt}
                                            </Form.Item>
                                            <Form.Item
                                                {...ColItemLayout}
                                                label="操作人电话">
                                                {rowData.createdByStaffId}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }

                            <h2>联系方式</h2>

                            <Form.Item
                                name="contact"
                                label="联系人"
                                rules={[{ required: true, message: '请填写联系人' }]}
                            >
                                <Input placeholder="请输入联系人姓名" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="手机号"
                                required={true}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!/^1\d{10}$/.test(value)) {
                                                return Promise.reject('手机号不正确');
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input placeholder="请输入手机号作为管理员账号" disabled={tip === 'edit' ? true : false} allowClear autoComplete="off" />
                            </Form.Item>

                            {
                                tip === 'add' && <Form.Item
                                    name="password"
                                    label="初始密码"
                                    rules={[{ required: true, message: '请输入管理员初始密码' }]}>
                                    <Input placeholder="请输入管理员初始密码" allowClear autoComplete="off" />
                                </Form.Item>
                            }

                            <Form.Item label="邮箱" name="email">
                                <Input placeholder="请输入联系人邮箱" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item label="微信" name="wechat">
                                <Input placeholder="微信号" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item label="所在部门" name="department">
                                <Input placeholder=" 请输入所在部门" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item label="职位" name="duty">
                                <Input placeholder=" 请输入职位" allowClear autoComplete="off" />
                            </Form.Item>

                            <Divider />

                            <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                                <Space>
                                    <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>保存</Button>
                                    <Button type="primary" onClick={handleClose}>取消</Button>
                                </Space>
                            </Form.Item>

                        </Form>

                    </div>
                </Drawer>
            }


        </Fragment>
    );
}

export default forwardRef(ProductionAddForm)
