import OperationChannel from "../components/Operation/Channel/Channel"
import OperationChannelCates from "../components/Operation/ChannelCates/ChannelCates"
import BiChannelUsers from "../components/Bi/BiChannel/Users/Users";
import RegisterUsers from "../components/Bi/BiChannel/RegisterUsers/RegisterUsers";
import BiChannelAuthUsers from "../components/Bi/BiChannel/AuthUsers/AuthUsers";
import BiChannelView from "../components/Bi/BiChannel/View/View";
import BiChannelVip from "../components/Bi/BiChannel/Vip/Vip";
import BiChannelSurveys from "../components/Bi/BiChannel/Surveys/Surveys";
// 运营中心
const operationRoutes = [
    // 渠道管理
    { path: '/operation/channels', element: <OperationChannel /> },
    // 渠道分类
    { path: '/operation/channel-cates', element: <OperationChannelCates /> },
    // 渠道用户总览
    { path: '/operation/bi/channel/users', element: <BiChannelUsers /> },
    // 渠道注册用户
    { path: '/operation/bi/channel/register-users', element: <RegisterUsers /> },
    // 渠道认证用户
    { path: '/operation/bi/channel/auth-users', element: <BiChannelAuthUsers /> },
    // 渠道个人数据
    { path: '/operation/bi/channel/vip', element: <BiChannelVip /> },
    // 渠道浏览数据
    { path: '/operation/bi/channel/view', element: <BiChannelView /> },
    // 渠道问卷数据
    {path: '/operation/bi/channel/surveys',element: <BiChannelSurveys/>}
];

export default operationRoutes;
