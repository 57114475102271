import React, { Fragment } from 'react';
import { Form, Select, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

const Addsurvey = ({ isModalOpen, setIsModalOpen }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const handleOk = async () => {
        const req = await form.validateFields()
        if (req) {
            form.validateFields().then(
                navigate('/wj/surveys/new/list', { state: { type: form.getFieldsValue().surveyTypeId } })
            )
            setIsModalOpen(false);
        }
    };
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false);
    };

    const options = [
        { value: '1', label: '调研问卷', },
        { value: '2', label: '投票', },
        { value: '3', label: '活动报名', }
    ];

    return (
        <Fragment>
            <div>
                <Modal
                    visible={isModalOpen}
                    title={'选择问卷类型'}
                    onOk={handleOk}
                    okText={'下一步'}
                    onCancel={handleCancel}
                >
                    <Form form={form}>
                        <Form.Item
                            name='surveyTypeId'
                            label='问卷类型:'
                            rules={[
                                { required: true, message: '请选择问卷类型', }
                            ]}
                        >
                            <Select placeholder='请选择问卷类型' options={options}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Fragment>
    );
}

export default Addsurvey;
