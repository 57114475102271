import { get, put, post, delet } from '@core/request';
import config from "@config";

export * from './topic';
export * from './vote';

// 案例管理-接口服务
//案例分页列表
export const getSelectionListApi = (params) => {
    return get('/selection/admin/case/cases', params);
}

// 案例上下架
export const updateCasesStatusApi = (params) => {
    return post('/selection/admin/case/cases/pub', params);
}


/**
 * 案例详情api
 */

// id查询案例详情
export const caseDetailApi = (params) => {
    return get(`/selection/admin/case/cases/${params.id}`);
}

// 案例基础信息
export const caseBaseInfoApi = (params) => {
    return get(`/selection/admin/case/cases/${params.id}/baseInfo`);
}

// 案例申报阶段文件信息
export const caseFileApiApi = (params) => {
    return get(`/selection/admin/case/cases/${params.id}/fileInfo`);
}
// 案例其他阶段文件信息
export const caseOtherFileApi = (params) => {
    return get(`/selection/caseattachments/listByCaseId`,params);
}
// 修改案例文件的展示状态
export const caseFileReviewedApi = (params) => {
    return post(`/selection/caseattachments/reviewed`,params,
        {
            headers:{'Content-Type':"application/x-www-form-urlencoded"}
        }
    );
}
// 获取申报人信息
export const getPeopleInfoApi = (params) => {
    const url = `${config.UCENTER_API_PREFIX}/wapi/member/getMemInfo`;
    return get(url, params);
}

// 修改案例文件的展示状态
export const caseFileShowedChangeApi = (params) => {
    return post(`/selection/caseattachments/showedChange`,params,
        {
            headers:{'Content-Type':"application/x-www-form-urlencoded"}
        }
    );
}

// 案例参赛团队人员信息
export const caseTeamApi = (params) => {
    return get(`/selection/caseteammembers/caseTeamMembers`,params);
}
// 根据赛段ID查询团队人员信息列表
export const listByStageIdApi = (params) => {
    return get(`/selection/caseteammembers/listByStageId`,params);
}
// 案例联系人信息
export const caseContactInfoApi = (params) => {
    return get(`/selection/admin/case/cases/${params.id}/linkInfo`);
}

// 获取项目下拉列表
export const getProjectsAllApi=(params)=>{
    return get('/selection/admin/projects/all',params)
}

// 视频点播
export const getVodMediaInfoApi = (params) => {
    const url = `${config.ALIYUN_API_PREFIX}/media/video/play-info`;
    return get(url, params);
}

// 案例审核
export const caseAuditApi = (params) => {
    const url = '/selection/admin/case/cases/reviewed';
    return post(url, params);
}

// 根据身份编号获取所属的字典列表
export const getDicNameApi = (params) => {
    const url = `${config.API_PREFIX}/dataengine/api/authdictionary/list/${params.identity}`;
    return get(url, params);
}



/**
 * 评选项目api
 */

// 获取评选项目列表
export const getProjestsListApi = (params) => {
    return get(`/selection/admin/projects/list`, params)
}

// 新增评选项目
export const addProjestsApi = (params) => {
    return post(`/selection/admin/projects/save`, params)
}

// 编辑评选项目
export const editProjestsApi = (params) => {
    return put(`/selection/admin/projects/update`, params)
}

// 根据id获取评选项目
export const getProjestsApi = (params) => {
    return get(`/selection/admin/projects/info/${params.id}`)
}

// 查询项目分组列表
export const getProjestsGroupListApi = () => {
    return get(`/selection/admin/projectgroups/all`)
}


//主题查询
export const getProjestsTopicsListApi = (params) => {
    return get(`/selection/admin/projecttopics/list`, params)
}

//案例字段
export const getProjestsFiledsListApi = (params) => {
    return get(`/selection/admin/projecttopics/getFields`, params)
}

//新增项目主题
export const saveTopicsApi=(data) =>{
    return post('/selection/admin/projecttopics/save',data)
}

//案例字段
export const getTopicsInfoApi = (params) => {
    return get(`/selection/admin/projecttopics/info/${params.id}`, )
}

//数组排序
export const sortTopicsApi=(data) =>{
    return post('/selection/admin/projecttopics/resort',data)
}

//删除主题
export const delTopicsApi = (params) => {
    return delet('/selection/admin/projecttopics/delete', params);
};

//更新主题
export const updateTopicsApi = (params) => {
    return put('/selection/admin/projecttopics/update', params);
};

//获取已选的填报字段
export const getTopicsSelectedInfoApi = (params) => {
    return get(`/selection/admin/projecttopics/getTopicSelectedFields`,params )
}


//保存填报字段
export const saveFieldApi=(data) =>{
    return post('/selection/admin/projecttopics/saveField',data)
}

//保存申报方向
export const saveDirectionsApi=(data) =>{
    return post('/selection/admin/projecttopicdirections/save',data)
}

//获取主题下的申报方向
export const getDirectionsListApi=(data) =>{
    return get('/selection/admin/projecttopicdirections/list',data)
}

//申报方向排序
export const sortDirectionsApi=(data) =>{
    return post('/selection/admin/projecttopicdirections/resort',data)
}


//删除申报方向
export const delDirectionsApi = (params) => {
    return delet('/selection/admin/projecttopicdirections/delete', params);
};

//查询单个申报方向
export const getDirectionsInfoApi=(params) =>{
    return get(`/selection/admin/projecttopicdirections/info/${params.id}`)
}

// 编辑评选项目
export const updateDirectionsInfoApi = (params) => {
    return put(`/selection/admin/projecttopicdirections/update`, params)
}

// 评选项目主题填报字段列表
export const getProjectTopicFieldsApi = (data) => {
    return get('/dataengine/cases/case-topic-fields/get-base-info', data);
}




/**
 * 评选项目-评分管理api
 */

// 评分环节列表
export const getScoreStagesListApi=(params) =>{
    return get(`/selection/admin/casescoringstages/list`,params)
}

// 新增评分环节
export const addScoreStagestApi=(data) =>{
    return post(`/selection/admin/casescoringstages/save`,data)
}

// 修改评分环节
export const updateScoreStagestApi=(data) =>{
    return put(`/selection/admin/casescoringstages/update`,data)
}

// 删除评分环节
export const deleteScoreStagestApi=(data) =>{
    return delet(`/selection/admin/casescoringstages/delete`,data)
}

// 查询评分环节信息
export const getScoreStagesDetailApi=(params) =>{
    return get(`/selection/admin/casescoringstages/info/${params.id}`)
}

//导入评委意向主题报表模板
export const getTopicsTemplateApi=(params) =>{
    return get(`/selection/admin/download/down`,params,{responseType: "blob"})
}

// 根据项目ID和环节ID查询参评的主题列表
export const getScoreTopicsListApi = (params) => {
    return get(`/selection/admin/casescoringtopics/list`,params)
}

// 环节评委列表
export const getStagesJudgesListApi = (params) => {
    return get(`/selection/admin/casejudges/caseJudgeslist`,params)
}

// 删除环节评委
export const deleteStagestJudgesApi=(data) =>{
    return delet(`/selection/admin/casejudges/deletecaseJudges`,data)
}

// 评委列表查询
export const getJudgesListApi = (params) => {
    return get(`/selection/admin/casejudges/list-experts`,params)
}

// 单独添加评委
export const addJudgesApi = (data) => {
    return post(`/selection/admin/casejudges/save`,data)
}

// 更新评委意向主题
export const updateJudgeTopicsApi = (data) => {
    return put(`/selection/admin/casejudges/update`,data)
}

// 批量导入评委
export const addJudgesListApi = (data) => {
    return post(`/selection/admin/casejudges/import`,data)
}

// 批量导入评委预校验
export const uploadFile = (data,option) => {
    return post(`/selection/admin/casejudges/import-check`,data,option)
}

// 标记项目正式还是测试
export const projectMarkAsTesting = (data,option) => {
    return put(`/selection/admin/projects/markAsTesting`,data,option)
}

// 环节获奖证书
export const updateCerificateApi=(data) =>{
    return put(`/selection/admin/casescoringstages/rankStateUpdate`,data)
}

// 案例评分报告
export const reportStateUpdateApi=(data) =>{
    return put(`/selection/admin/casescoringstages/reportStateUpdate`,data)
}

// 人员管理 - 参赛团队人员
export const caseTeammembersListApi=(data) =>{
    return get(`/selection/caseteammembers/listByStageId`,data)
}

// 人员管理 - 参赛团队人员导出
export const caseTeammembersDownloadApi=(data) =>{
    return get(`/selection/caseteammembers/download`,data, { responseType: 'blob' })
}

export const selectionUploadFile = data => post('/selection/admin/file', data, { headers: { "Content-Type": "multipart/form-data" } });

// 案例 - 弃赛标记
export const caseRetireFlagApi=(data) =>{
    return get(`/selection/admin/casescores/retireFlag`,data)
}

// 查询获奖证书详情
export const fetchCaseAwardCert = params => get('/selection/admin/casescoringstages/awardCertGetByCaseId', params);

// 重置证书
export const resetCaseAwardCert = data => post('/selection/admin/casescoringstages/resetCertificate', data);

