import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Space, message, Image, Upload, Switch, Tooltip, Input } from 'antd';
import { drawerWidth, drawerFormItemLayout } from '@utils/property';
import { fileApi } from '@services/Conference';
import { updateGuestJourneySettingsApi } from '@services/Guest';
import './index.less';
import config from '@config';
import { prepareBCOSSUrl } from '@utils/utils';

const colorList = [
    '#f5222d',
    '#fa541c',
    '#fa8c16',
    '#52c41a',
    '#1677ff',
    '#0327ff',
    '#722ed1',
    '#eb2f96',
    '#333333',
    '#888888',
    '#ffffff',
].map(c => c.toUpperCase());

// 嘉宾行程单设置
const GuestJourneySetting = ({
    visibleGuestJourneySetDrawer,
    setVisibleGuestJourneySetDrawer,
    conference,
    getConferenceInfo
}) => {
    const optionHeaders = { headers: { "Content-Type": "multipart/form-data" } };

    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const [form] = Form.useForm();

    const isAllowCheck = useRef(null);
    const [agendaLoading, setAgendaLoading] = useState(false); // 新增/编辑日程loading
    const [headerImgList, setHeaderImgList] = useState([]);
    const [qrcodeImg, setQrcodeImg] = useState([]);
    const [printHeaderImgList, setPrintHeaderImgList] = useState([]);

    const closeDrawer = () => {
        setVisibleGuestJourneySetDrawer(false);
        form.resetFields();
        setHeaderImgList([]);
        setPrintHeaderImgList([]);
    };

    //上传头像
    const checkout = (file, size) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error('仅支持上传JPG/PNG格式的图片。');
            isAllowCheck.current = true;
            return false;
        } else {
            isAllowCheck.current = false;
        }

        // 判断大小
        const isLt5M = file.size / 1024 / 1024 < size;
        if (!isLt5M) {
            message.error(`图片不能大于${size}MB。`);
            isAllowCheck.current = true;
            return false;
        } else {
            isAllowCheck.current = false;
        }
        return isJpgOrPng && isLt5M
            ;
    };

    const imageUpload = (info) => {
        const formData = new FormData();
        formData.append("file", info.file);
        formData.append('type', 14);
        if (!isAllowCheck.current) {
            fileApi(formData, optionHeaders).then((res) => {
                if (!res) {
                    message.error("上传文件失败！");
                    return false;
                }
                if (res.status_code === 200) {
                    setHeaderImgList([getFileUrl(res.data)]);
                    form.setFieldValue('head_bg', res.data);
                    form.setFields([{ name: 'head_bg', errors: null }]);
                }
            });
        } else {
            form.setFieldValue('head_bg', undefined);
        }
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 背景色
    const changeBgColor = (item, fieldName) => {
        form.setFieldValue(fieldName, item);
    };

    // 提交行程单设置，新增/编辑
    const submitJourney = () => {
        form.validateFields().then(async (values) => {
            const res = await updateGuestJourneySettingsApi({
                id: conferenceId,
                guestJourneySettings: JSON.stringify(values),
            });
            if (res.status_code === 200) {
                message.success('嘉宾行程单设置成功');
                setVisibleGuestJourneySetDrawer(false);
                getConferenceInfo(conferenceId);
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    useEffect(() => {
        if (conference?.guestJourneySettings) {
            const guestJourneySettings = JSON.parse(conference.guestJourneySettings);
            console.log(guestJourneySettings)
            form.setFieldsValue(guestJourneySettings);
            if (guestJourneySettings?.head_bg) {
                setHeaderImgList([getFileUrl(guestJourneySettings?.head_bg)]);
            }
        }
    }, [visibleGuestJourneySetDrawer, conference]);

    return (
        <>
            <Drawer
                title="嘉宾行程单设置"
                height="100%"
                width={drawerWidth}
                layout="horizontal"
                open={visibleGuestJourneySetDrawer}
                onClose={closeDrawer}
                footer={
                    <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={closeDrawer}>取消</Button>
                            <Button type="primary" loading={agendaLoading} onClick={submitJourney}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <div className="guest-journey-form">
                    <Form
                        name="basic"
                        form={form}
                        autoComplete="off"
                    >
                        <Form.Item name="web">
                            <Form.Item
                                name='head_bg'
                                label="头图"
                                rules={[
                                    { required: true, message: '请上传头图' },
                                ]}
                                extra="图片尺寸宽750px高不小于720px，支持JPG/PNG格式，大小不超过2MB"
                                className="head-image"
                            >
                                {headerImgList.length > 0 && <Image width={375} src={headerImgList[0]} className="share-image-area" />}

                                <div>
                                    <Upload
                                        beforeUpload={(file) => checkout(file, 5)}
                                        fileList={[]}
                                        customRequest={imageUpload}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {headerImgList.length > 0 ? '更改图片' : '上传图片'}
                                        </Button>
                                    </Upload>
                                </div>
                            </Form.Item>

                            <Form.Item
                                className="required"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                                label="背景色"
                                extra="背景色需要和背景图配合（融合），用于背景图高度不够时填补背景"
                            >
                                <div className="color-field-wrap">
                                    <div className="color-field">
                                        <Form.Item noStyle shouldUpdate>
                                            {({ getFieldValue }) => {
                                                const color = getFieldValue('bg_color');
                                                return !!color && (
                                                    <>
                                                        <div className="color-previewer" style={{ backgroundColor: `${color}`, borderColor: '#CCCCCC' }} />
                                                    </>
                                                );
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            noStyle
                                            name='bg_color'
                                            rules={[
                                                { required: true, message: '背景色不能为空' },
                                                { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                            ]}
                                        >
                                            <Input style={{ width: 110, border: 'none', boxShadow: 'none' }} maxLength={7} />
                                        </Form.Item>
                                    </div>
                                    <div className="color-list">
                                        {
                                            colorList.map((item, index) => (
                                                <Tooltip placement="top" title='点击使用该颜色'>
                                                    <div
                                                        key={index}
                                                        onClick={() => changeBgColor(item, 'bg_color')}
                                                        className="color-previewer"
                                                        style={{ backgroundColor: `${item}`, borderColor: '#CCCCCC' }}
                                                    ></div>
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Form.Item>

                        </Form.Item>

                        <div className='form-item-custom'>会议地址：显示</div>

                        <div className='form-item-custom'>嘉宾姓名：显示</div>

                        <div className="conference-task">
                            <Form.Item
                                label="会议任务"
                                name='conference_agenda_task_enabled'
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren="启用"
                                    unCheckedChildren="禁用"
                                />
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    const conference_agenda_task_enabled = getFieldValue('conference_agenda_task_enabled');
                                    return !!conference_agenda_task_enabled && (
                                        <div className='task-address'>任务地址：显示</div>
                                    );
                                }}
                            </Form.Item>
                        </div>

                        <Form.Item
                            label="来程信息"
                            name='arrival_info_enabled'
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item
                            label="酒店信息"
                            name='hotel_info_enabled'
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item
                            label="返程信息"
                            name='return_info_enabled'
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item
                            label="联系人信息"
                            name='contacts_enabled'
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                    </Form>
                </div>
            </Drawer>
        </>
    );
};
export default GuestJourneySetting;

