import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import SurveysListFilterForm from './SurveysListFilterForm';
import { Button, Layout, Table, Modal, Space, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { get, put, del } from '@/core/request';
import SurveysPreview from '../SurveysNew/SurveysPreview';
import { fullPagerConfig } from '@/utils/property';
import qs from 'qs';

const { Content } = Layout;

export default function SurveysList() {

    const navigate = useNavigate();

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});
    const [createdByStaffIds, setCreatedByStaffId] = useState([]);

    // 操作人
    useEffect(() => {
        get('/dms/users/findAll').then((res) => {
            setCreatedByStaffId(res.data);
        })
    }, []);

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };


    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, surveysStatus, createdByStaffId, startTime, endTime } = filterObj;

    const getData = () => {
        get(`/dms/surveys`, {
            limit: pageSize,
            page: currPage,
            name: name,
            surveysStatus: surveysStatus,
            createdByStaffId: createdByStaffId,
            startTime: startTime,
            endTime: endTime,
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        });
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [createdByStaffId, endTime, name, startTime, surveysStatus, pageSize, currPage]);

    // 预览drawer
    const drawer = useRef();
    const [previewData, setPreviewData] = useState(null)

    const handleClickPreview = (row) => {
        return () => {
            get(`/dms/surveys/findById`, {
                id: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    setPreviewData(res.data);
                    drawer.current.show();
                }
            });
        }
    }

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除问卷',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/surveys', {
                        ids: [row.id]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中问卷',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/surveys', {
                        ids: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何问卷');
                }
            }
        });
    };

    const changePath = useCallback(
        (row, tip) => {
            return () => {
                if (tip === 'edit') {
                    navigate(`/dms/surveys/${row.id}`, { state: row });
                } else if (tip === 'data') {
                    navigate(`/dms/surveys/${row.id}/data`, { state: row });
                } else {
                    navigate(`/dms/surveys/${row.id}/users`, { state: row });
                }
            }
        },
        [navigate],
    );

    // 结束发布
    const handleFinishPublish = (row) => {
        return () => {
            let data = { id: row.id }
            put(`dms/surveys/endRelease`, qs.stringify(data)).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message)
                    getData();
                }
            })
        }
    };

    // table 数据相关
    const columns = [
        { title: '问卷名称', dataIndex: 'name', key: 'id' },
        { title: '问卷ID', dataIndex: 'id', },
        { title: '答题数量', dataIndex: 'count', },
        { title: '创建人', dataIndex: 'createdByStaffName', },
        { title: '创建时间', dataIndex: 'createdAt', },
        { title: '问卷状态', dataIndex: 'surveysStatus', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handleClickPreview(row)}>预览</span>
                    {
                        row.surveysStatus === "未发布" && <Fragment>
                            <span className="operate-style" onClick={changePath(row, 'edit')}>编辑</span>
                            <span className="operate-style" onClick={changePath(row, 'publish')}>去发布</span>
                        </Fragment>
                    }
                    {
                        row.surveysStatus === "收集中" && <Fragment>
                            <span className="operate-style" onClick={changePath(row, 'data')}>统计</span>
                            <span className="operate-style" onClick={changePath(row, 'publish')}>去发布</span>
                            <span className="operate-style" onClick={handleFinishPublish(row)}>结束发布</span>
                        </Fragment>
                    }
                    {row.surveysStatus === "已发布" && <span className="operate-style" onClick={changePath(row, 'data')}>统计</span>}
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">问卷管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <SurveysListFilterForm
                        handleFilterFinish={handleFilterFinish}
                        createdByStaffId={createdByStaffIds}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">问卷列表</div>

                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary"><Link to="/dms/surveys/new">创建问卷</Link></Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

            <SurveysPreview ref={drawer}
                title={previewData && previewData.name}
                description={previewData && previewData.description}
                endAt={previewData && previewData.endAt}
                list={previewData && previewData.list}></SurveysPreview>




        </Fragment>
    )
}

