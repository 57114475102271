import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { Button, Layout, Table, Space, Menu, Dropdown, Modal, Form, Input, message } from 'antd';
import AddRoleModal from './AddRoleModal';
import MemberMaintainDrawer from './MemberMaintainDrawer';
import { get, post, put, delte } from '@/core/request';
import './RoleList.less'

const { Content } = Layout;

export default function RoleList() {
    const navigate = useNavigate();

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);
    const [roleName, setRoleName] = useState('')

    // 改变一页有多少数据
    const changePageSize = (pageSize) => {
        setPageSize(pageSize)
    };

    // 改变第几页
    const changeCurrPage = (page) => {
        setCurrPage(page);
    };

    // 点击搜索
    const onSearch = (value) => {
        setRoleName(value)
    }

    const getData = () => {
        get(`/enterprise/roles/query_page`, {
            limit: pageSize,
            page: currPage,
            name: roleName
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [currPage, pageSize, roleName]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除企业',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    if (row.disabled === 0 && row.counts > 0) {
                        message.error('该角色下有成员，不能删除')
                    } else {
                        let form = new FormData();
                        form.append("id", row.id)
                        delte('/enterprise/roles/delete', form).then(res => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                            }
                        })
                    }

                }
            });
        }
    };

    // 启用/禁用账号
    const stopConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用该角色',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    if (row.disabled === 0 && row.counts > 0) {
                        message.error('该角色下有成员，不能禁用')
                    } else {
                        let disabled = 1;
                        if (row.disabled === 1) {
                            disabled = 0;
                        }
                        put('/enterprise/roles/status', {
                            id: row.id,
                            disabled
                        }).then(res => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                            }
                        });
                    }

                }
            });
        }
    };

    // drawer成员维护
    const drawer = useRef();
    const memberMaintain = useCallback((row) => {
        return () => {
            drawer.current.show(row)
        }
    }, []
    );

    // 新增modal相关
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState({
        tip: '',
        row: {}
    })

    // 新增弹窗
    const showModal = (tip, row) => {
        return () => {
            setModalData({
                tip, row
            })
            setIsModalVisible(true);
        }
    }
    // 新增角色
    const handleAddRole = useCallback((value) => {
        post(`/enterprise/roles/save`, value).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    }, [])

    // 编辑角色
    const handleEditRole = useCallback((value) => {
        put(`/enterprise/roles/update_info`, value).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    }, [])

    // 点击刷新
    const handleRefresh = () => {
        message.info('正在刷新', 1);
        changeCurrPage(1);
    }

    // 权限维护
    const authorityMaintain = (row) => {
        return () => {
            navigate(`/enterprise/role/${row.id}/permissions`, { state: row })
        }
    };

    // table 数据相关
    const columns = [
        { title: '角色ID', dataIndex: 'id' },
        { title: '角色名称', dataIndex: 'name', },
        { title: '角色描述', dataIndex: 'description', },
        { title: '成员数', dataIndex: 'counts', },
        {
            title: '状态', dataIndex: 'disabled',
            render: (operates) => (<span className={operates === 0 ? "open-status" : "close-status"}>{operates === 0 ? '启用' : '禁用'}</span>)
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="authority" onClick={authorityMaintain(row)}>权限维护</Menu.Item>
                        <Menu.Item key="member" onClick={memberMaintain(row)}>查看成员</Menu.Item>
                        <Menu.Item key="stop" onClick={stopConfirm(row)}>{row.disabled === 0 ? "禁用角色" : "启用角色"}</Menu.Item>
                        {row.disabled === 1 && <Menu.Item key="delete" onClick={deleteConfirm(row)}>删除角色</Menu.Item>}
                    </Menu>
                )
                return (
                    <Dropdown.Button onClick={showModal('edit', row)} overlay={menu} size='small'>
                        编辑
                    </Dropdown.Button>
                )
            }
        },
    ];

    return (
        <Fragment>
            <div className="page-title">角色管理</div>

            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="table-toolbar-role">
                            <Button type="primary" className="add-role-button" onClick={showModal('add')}>新增角色</Button>
                            <Space className="search-role-box">
                                <Input.Search placeholder="输入角色关键字" onSearch={onSearch} style={{ width: 200 }} allowClear />
                                <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}>刷新</Button>
                            </Space>
                        </div>
                    </div>
                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tableData.list}
                        pagination={paginationProps}
                    />
                </div>
            </Content>

            {/* 点击新增的modal */}
            {
                isModalVisible && <Modal
                    visible={isModalVisible}
                    closable={false}
                    footer={null}>
                    <AddRoleModal
                        modalData={modalData}
                        handleAddRole={handleAddRole}
                        setIsModalVisible={setIsModalVisible}
                        handleEditRole={handleEditRole}
                    ></AddRoleModal>
                </Modal>
            }

            <MemberMaintainDrawer ref={drawer} />
        </Fragment>
    );
}
