import React, { Fragment, useState, useEffect } from 'react';
import {Link, useParams, useSearchParams} from "react-router-dom";
import {LeftOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Col, DatePicker, Form, Input, message, Row, Space, Table, Tooltip} from "antd";
import {Content} from "antd/lib/layout/layout";
import {dateTimeFormat} from '@utils/utils';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {getFeeBatchDetail, handleExportData} from '@services/MedConnect';

const { RangePicker } = DatePicker;

export default function LabfeeBatchesDetail() {
    const [params] = useSearchParams();
    const batchNumber = params.getAll("batchNum")[0];

    let { id, batchNo, detailId } = useParams();
    id = !!id && parseInt(id) || 0;
    batchNo = !!batchNo && parseInt(batchNo) || 0;
    detailId = !!detailId && parseInt(detailId) || 0;

    const [form] = Form.useForm();

    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const [detailInfo, setDetailInfo] = useState({});


    const getDetailList = async () => {
        const params = {
            ...filter,
            feeDetailId: detailId,
        }
        getFeeBatchDetail(params).then((res) => {
            if (res && res.success) {
                setDetailInfo(res.data)
                setTableData(res.data.pageResponse.data);
                setTotal(res.data.pageResponse.totalPages);
            }
        })
    }

    // 检索
    const handleFilterFinish = (values) => {
        setFilter({
            ...filter,
            feeDetailId: detailId,
            questionContent: values.questionContent,
            auditedAtFrom: values.timeRange ? dateTimeFormat(values.timeRange[0], 'YYYY-MM-DD 00:00:00') : '',
            auditedAtEnd: values.timeRange ? dateTimeFormat(values.timeRange[1], 'YYYY-MM-DD 23:59:59') : '',
            pageIndex: 1,
        })
    }

    // 重置
    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            auditedAtFrom: '',
            auditedAtEnd: '',
            pageIndex: 1,
        });
    }

    // 导出
    const exportInfo = () => {
        handleExportData({id: detailId}).then(res => {
            if (res.success) {
                message.success('请稍后，数据正在导出');
                const fileName = "明细详情导出.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                a.href = res.data;
                a.dispatchEvent(event);
            }
        })
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        {
            title: '问答标题',
            dataIndex: 'questionContent',
            width: 600,
            render: (text, record) => {
                return (
                    <Fragment>
                        <Tooltip title={<span>{record.questionContent}</span>}>
                            <div className="quest-title">问题：{record.questionContent}</div>
                        </Tooltip>
                        <div className="answer-text" style={{width: '550px'}}>
                            <div className="answer-label">回答：</div>
                            <div className="answer-content" dangerouslySetInnerHTML={{__html: record.answerContent}}></div>
                        </div>
                    </Fragment>
                )
            }
        },
        {title: '问答疾病分类', dataIndex: 'diseaseTypeDesc'},
        {title: '提交时间', dataIndex: 'createdAt'},
        {title: '审核时间', dataIndex: 'auditedAt'},
    ]

    useEffect(() => {
        getDetailList();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects/${id}/labfee/batches/${batchNo}/batch?batchNum=${batchNumber}`} className="back"><LeftOutlined/></Link>
                <span className="current-title">批次查看</span>
                <span className="current-subtitle">{`${batchNumber}明细`}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="问答标题" name="questionContent">
                                    <Input allowClear placeholder="请输入问题标题" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="审核时间" name="timeRange">
                                    <RangePicker/>
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    {/*<div className="main-toolbar">*/}
                    {/*    <div className="main-tools">*/}
                    {/*        <Button type="primary" onClick={exportInfo}>导出</Button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div>{detailInfo.qaNum}个有效问答数</div>
                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>


            </Content>

        </Fragment>
    )
}
