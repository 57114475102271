import React, { useEffect, useState, useRef } from 'react';
import { Button, message, Modal, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move';
import AddQuestionBankModal from './components/AddQuestionBankModal';
import AddQuestionModal from './components/AddQuestionModal';
import {
    getQuestionBankListApi, addQuestionBankApi, delQuestionBankApi,
    updateQuestionBankApi, addQuestionApi, patchQuestionApi, patchQuestionBankApi,
    delQuestionApi, updateQuestionApi
} from '@services/Wj'
import './index.less'

export default function Questionbank() {

    const [data, setData] = useState([])
    // 题库类型
    const [isModalOpen, setIsModalopen] = useState(false)
    const [title, setTitle] = useState('添加')
    // 题目
    const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false)
    const [questionTitle, setQuestionTitle] = useState('添加题目')

    // 当前编辑的题型类
    const [curQesBank, setCurQesbank] = useState(undefined)
    // 当前编辑的题目
    const [curQes, setCurQes] = useState(undefined)

    const questionRef = useRef(null)
    // 添加题库类型
    const onModalOk = async (res, callback) => {
        let result = undefined, params = undefined
        if (title === '添加') {
            params = {
                name: res.name
            }
            result = await addQuestionBankApi(params);
        } else {
            params = {
                id: curQesBank.id,
                name: res.name
            }
            result = await updateQuestionBankApi(params);
        }
        if (result.status_code === 200) {
            message.success(`${title}成功`)
            getQuestionBankListData()
            setIsModalopen(false)
            callback()
        }
    }

    const onQuestionModalOk = async (pa, callback) => {
        let result = undefined
        console.log(curQesBank);
        if (questionTitle === '添加题目') {
            pa.questionCateId = curQesBank.id
            result = await addQuestionApi(pa);
        } else {
            pa = {
                ...curQes,
                ...pa
            }
            result = await updateQuestionApi(pa)
        }

        if (result.status_code === 200) {
            message.success(`${questionTitle}成功`)
            getQuestionBankListData()
            setIsQuestionModalOpen(false)
            questionRef.current.quillRef.current.value = undefined
            callback()
        }


    }

    const handdleClose = () => {
        setIsQuestionModalOpen(false)
    }

    // 题库类型编辑
    const onBankEditClick = (item) => {
        setCurQesbank(item)
        setTitle('编辑')
        setIsModalopen(true)
    }
    const onAddBankClick = () => {
        setTitle('添加')
        setIsModalopen(true)
    }
    const onBankDeleteClick = (item) => {
        Modal.confirm({
            title: '提示',
            content: '是否确认删除',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                let params = {
                    id: item.id
                }
                let res = await delQuestionBankApi(params)
                if (res.status_code === 200) {
                    message.success('删除成功')
                    getQuestionBankListData()
                }
            }
        })
    }

    // 题目编辑以及添加
    const onQuestionAdd = (item) => {
        setCurQesbank(item)
        setCurQes(undefined)
        setQuestionTitle('添加题目')
        setIsQuestionModalOpen(true)
    }
    const onQuestionEdit = (item) => {
        setCurQes(item)
        setQuestionTitle('编辑题目')
        setIsQuestionModalOpen(true)
    }
    const onDeleteClick = (item) => {
        Modal.confirm({
            title: '提示',
            content: '是否确认删除',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                let params = {
                    id: item.id
                }
                let res = await delQuestionApi(params)
                if (res.status_code === 200) {
                    message.success('删除成功')
                    getQuestionBankListData()
                }
            }
        })
    }

    const onSortEndChildHandler = ({ oldIndex, newIndex }, a) => {
        let newData = data, ind = data.findIndex(it => it.id === a)
        let items = data[ind].questionList
        newData[ind].questionList = arrayMoveImmutable(items, oldIndex, newIndex)
        setData(newData.slice())

        patchQuestionApi({ ids: newData[ind].questionList.map(item => item.id) })
    }

    // const Sortable
    const SortableChildElement = SortableElement(({ childData, paSort }) => <div className='item_wrap child_item_wrap'>
        <span>{childData.name || childData.title}</span>
        {paSort !== 0 ? <Space>
            <SortableMainHandle></SortableMainHandle>
            <Button type='link' disabled={childData.questionTypeId === 7} onClick={() => onQuestionEdit(childData)}>编辑</Button>
            <Button type='link' disabled={childData.questionTypeId === 7} style={{ color: childData.questionTypeId === 7 ? '#ccc' : 'red' }} onClick={() => onDeleteClick(childData)}>删除</Button>
        </Space> : null}

    </div>)

    const SortableChildContainer = SortableContainer(({ childItems, paSort }) => {
        return (<div className='child_container_wrap'>
            {childItems?.length ? childItems.map((item, index) =>
                <SortableChildElement childData={item} paSort={paSort} index={index} key={index}></SortableChildElement>
            ) : null}
        </div>)
    })

    const SortableMainHandle = SortableHandle(() => {
        return <Button type='link'><span className="dragicon" style={{ color: '#1890ff' }}>&#xe636;</span>&nbsp;&nbsp;拖动</Button>
    })

    const SortableMainElement = SortableElement(({ itemData, sort }) => {
        let hasAdress = itemData?.questionList?.some(it => it.questionTypeId === 7) || false
        return <div className='main_item_wrap'>
            <div className='item_wrap'>
                <span>{itemData.name}</span>
                {sort !== 0 ? <Space>
                    <Button type='link' onClick={() => onQuestionAdd(itemData)}>添加题目</Button>
                    <SortableMainHandle></SortableMainHandle>
                    <Button type='link' onClick={() => onBankEditClick(itemData)}>编辑</Button>
                    <Button type='link' disabled={hasAdress} style={{ color: hasAdress ? '#ccc' : 'red' }} onClick={() => onBankDeleteClick(itemData)}>删除</Button>
                </Space> : null}

            </div>

            <SortableChildContainer
                useDragHandle
                paSort={sort}
                childItems={itemData.typeList || itemData.questionList}
                distance={10}
                onSortEnd={(res) => onSortEndChildHandler(res, itemData.id)}
            />
        </div>
    })

    const SortableMainContainer = SortableContainer(({ items }) => {
        return (<div className='main_content_wrap'>
            {items.length ? items.map((item, index) =>
                <SortableMainElement
                    itemData={item}
                    index={index}
                    key={index}
                    sort={index}
                />
            ) : null}
        </div>)
    })


    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        if (newIndex === 0) {
            message.info('基础题型位置不能修改')
            return
        }
        let res = arrayMoveImmutable(data, oldIndex, newIndex)
        setData(res)

        patchQuestionBankApi({ ids: res.map(item => item.id) })
    }

    const getQuestionBankListData = async () => {
        let params = {}
        let bankData = await getQuestionBankListApi(params)

        if (bankData.status_code === 200) {
            setData(bankData.data)
        }
    }

    useEffect(() => {
        getQuestionBankListData()
    }, [])

    return (
        <Content className="main-content-box main-content-box-padding questionbank_wrap">
            <Space direction="vertical" size="middle" className='questionbank_wrap_content'>
                <Button type='primary' onClick={() => onAddBankClick()}>添加题库分类</Button>

                <SortableMainContainer
                    useWindowAsScrollContainer={true}
                    useDragHandle
                    distance={10}
                    items={data}
                    onSortEnd={onSortEndHandler}
                    helperClass='testClass'
                />
            </Space>


            <AddQuestionBankModal
                defaultValue={curQesBank?.name || ''}
                title={title}
                isModalVisiable={isModalOpen}
                onClose={() => setIsModalopen(false)}
                onOk={onModalOk}
            />

            <AddQuestionModal
                title={questionTitle}
                defaultValue={curQes}
                isModalVisiable={isQuestionModalOpen}
                onClose={() => handdleClose()}
                onOk={onQuestionModalOk}
                ref={questionRef}
            />
        </Content>
    )
}
