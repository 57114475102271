import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select, Radio, Tag, Checkbox, message} from "antd";
import {qaApprove} from '@services/MedConnect';
import {useProjectDictionaryList} from '@hooks/MedConnect';
import {useParams} from "react-router-dom";

const { CheckableTag } = Tag;
const {TextArea} = Input;

export default function ApproveModal({isOpen,handleClose,record, handleOk}) {
    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    // 通过标签
    const { dictionaryList } = useProjectDictionaryList('audit_pass_label', id);
    const { dictionaryList: dictionaryNoList } = useProjectDictionaryList('audit_refuse_label', id);
    const [form] = Form.useForm();

    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedNoTags, setSelectedNoTags] = useState([]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const params = {
                ...values,
                values: values.state === 1 ? selectedTags : selectedNoTags,
                qaId: record.id,
            }
            qaApprove(params).then(res => {
                if (res && res.success) {
                    message.success(res.data || '审核成功');
                    handleOk && handleOk();
                }
            })
        })
    }

    const handleTagChange = (value, checked) => {
        const tagChecked = checked ? [...selectedTags, value] : selectedTags.filter(v => v !== value);
        setSelectedTags(tagChecked);
    }

    const handleNoTagChange = (value, checked) => {
        const tagChecked = checked ? [...selectedNoTags, value] : selectedNoTags.filter(v => v !== value);
        setSelectedNoTags(tagChecked);
    }

    useEffect(() => {

        return () => {
            setSelectedTags([]);
            form.resetFields();
        }
    }, [isOpen]);

    return (
        <Modal
            visible={isOpen}
            title={'问答审核'}
            onOk={handleSubmit}
            onCancel={handleClose}
        >
            <Form
                form={form}
            >

                <Form.Item
                    name='state'
                    label='审核意见'
                    rules={[
                        {
                            required: true,
                            message: '',
                        }
                    ]}
                    initialValue={1}
                >
                    <Radio.Group>
                        <Radio value={1}>审核通过</Radio>
                        <Radio value={2}>审核驳回</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, cur) => pre.state !== cur.state}
                >
                    {
                        ({getFieldValue}) => {
                            return getFieldValue('state') === 1 ? (
                                <>
                                    <Form.Item
                                        label='审核评价'
                                    >
                                        {dictionaryList && dictionaryList.map((item) => {
                                            return (
                                                <CheckableTag
                                                    key={item.value}
                                                    checked={selectedTags.indexOf(item.value) > -1}
                                                    onChange={(checked) => handleTagChange(item.value, checked)}
                                                >
                                                    {item.label}
                                                </CheckableTag>
                                            )
                                        })}
                                    </Form.Item>
                                    <Form.Item
                                        name='content'
                                        label='审核点评'
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </>
                            ) : (
                                <>
                                    <Form.Item
                                        label='驳回评价'
                                    >
                                        {dictionaryNoList && dictionaryNoList.map((item) => {
                                            return (
                                                <CheckableTag
                                                    key={item.value}
                                                    checked={selectedNoTags.indexOf(item.value) > -1}
                                                    onChange={(checked) => handleNoTagChange(item.value, checked)}
                                                >
                                                    {item.label}
                                                </CheckableTag>
                                            )
                                        })}
                                    </Form.Item>
                                    <Form.Item
                                        name='reson'
                                        label='驳回理由'
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            }
                                        ]}
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </>
                            )
                        }
                    }
                </Form.Item>

            </Form>
        </Modal>
    )
}
