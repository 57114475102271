import { Upload, Drawer, Form, Radio, Input, Space, Button, message } from 'antd';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';
import { useState, useEffect, Fragment } from 'react';
import './index.less';
import { uploadFileApi } from '@services/SiteModule';
import config from "@config";

export default ({
    drawerState,
    setDrawerState,
    currentModule,
    updateModuleSettings,
}) => {
    const [form] = Form.useForm();

    const [docList, setDocList] = useState(null);

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    // 文件上传
    const uploadFile = async (info) => {
        let formatType = null;
        const isJpgOrPng = info.file.type === "application/pdf";

        const isLt30M = info.file.size / 1024 / 1024 < 31;
        if (!isJpgOrPng) {
            message.error("只能上传pdf 格式!");
            return;
        }
        if (!isLt30M) {
            message.error("文件大小不能超过30M");
            return;
        }
        if (info.file.type === "application/pdf") {
            formatType = 'pdf';
        }

        let formData = new FormData();
        formData.append("file", info.file);
        formData.append("type", 14);
        const res = await uploadFileApi(formData);
        if (res) {
            let docItem = {
                name: info.file.name,
                path: res.data,
                ext: formatType
            }
            setDocList(docItem);

            form.setFieldValue('doc', docItem);
            form.setFields([
                { name: 'doc', errors: null }
            ]);
        }
    }

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 新增模块
    const onFinish = async (values) => {
        let params = {
            ...values,
            id: currentModule.id,
            data: JSON.stringify({ doc: docList })
        };
        delete params.doc;
        updateModuleSettings(params);
    };

    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            if (currentModule.data) {
                let data = JSON.parse(currentModule.data);
                setDocList(data.doc);
            };
            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path,
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 3 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="JournalModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="JournalModuleForm"
                form={form}
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="displayTitle"
                    label="显示标题"
                    rules={[
                        { required: true, message: '显示标题不能为空' }
                    ]}
                >
                    <Input placeholder="请输入模块显示标题" />
                </Form.Item>

                <Form.Item name="displayTitleEn" label="英文显示标题">
                    <Input placeholder="请输入模块英文显示标题" />
                </Form.Item>

                {/* <Form.Item
                    name="path"
                    label="访问路径"
                    rules={[
                        { required: true, message: '访问路径不能为空' },
                        { pattern: /^[a-z\d_]{2,20}$/, message: '访问路径不符合格式要求' },
                    ]}
                    help={
                        <>
                            填写访问该模块的访问路径，如会议简介访问路径 <b>introduction</b><br />
                            格式合求：小写字母、数字或下划线（_），限2-20个字符
                        </>
                    }
                >
                    <Input placeholder="请输入模块访问路径" minLength={2} maxLength={20} showCount disabled={currentModule?.moduleType <= 10} />
                </Form.Item> */}
                <Form.Item
                    label="会刊文件"
                    name="doc"
                    extra="支持PDF格式，大小不超过30MB"
                >
                    <>
                        {docList
                            ? <div className="docBox">
                                <div className="docIcon" title="PDF 文件">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                        <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                    </svg>
                                </div>
                                <div className="docName">{docList.name}</div>
                            </div>
                            : null
                        }

                        <Upload customRequest={uploadFile} name="file" maxCount={1} fileList={[]}>
                            <Button
                                className="btnWithIcon"
                                icon={<>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                    </svg>
                                </>}
                            >
                                {docList ? "更换文件" : '上传文件'}
                            </Button>
                        </Upload>
                    </>
                </Form.Item>
                <Form.Item
                    name="status"
                    label="导航状态"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>敬请期待</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="hiddenWeb"
                    label="Web端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="hiddenH5"
                    label="H5端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>


        </Drawer>
    );
};
