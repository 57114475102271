import React, {useEffect, useState} from 'react';
import {Form, Input, message, Modal, Select} from "antd";
import {useParams} from "react-router-dom";
import { useProjectDictionaryList } from "@hooks/MedConnect";
import {handleAllocate} from '@services/MedConnect';


const { Option } = Select;

export default function DistributeModal({distributeIds,isOpen,handleClose,handleOk}) {
    let { id } = useParams();
    id = !!id && parseInt(id) || 0;
    // 获取医生列表
    const {dictionaryList} = useProjectDictionaryList('qa_allocate', id);

    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then(values => {
            const params = {
                ...values,
                ids: distributeIds,
                projectId: id,
            }
            handleAllocate(params).then(res => {
                if (res && res.success) {
                    message.success(res.data || '分配成功');
                    handleOk && handleOk();
                }
            })
        })
    }

    useEffect(() => {

        return () => {
            form.resetFields();
        }
    }, [distributeIds]);

    return (
        <Modal
            visible={isOpen}
            title={'人工分配'}
            onOk={handleSubmit}
            onCancel={handleClose}
        >
            <Form
                form={form}
            >

                <Form.Item
                    name='allocateUser'
                    label='医生姓名'
                    rules={[
                        {
                            required: true,
                            message: '请选择医生姓名',
                        }
                    ]}
                >
                    <Select placeholder='输入手机号或姓名'
                            // onChange={handleChange}
                    >
                        {dictionaryList && dictionaryList.map((item) => {
                            return <Option key={item.value} value={item.value}>
                                {item.label} {item.value}
                            </Option>
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
