import React from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Space, Select } from 'antd';

export default function ProductionBannerFilterForm({ handleFilterFinish, productId, orgId, updatedByStaffId}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            productId: undefined,
            orgId: undefined,
            updatedByStaffId: undefined,
            status: undefined
        };
        handleFilterFinish(values);
    };
    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="广告名称" name="name">
                        <Input allowClear placeholder="输入广告名称" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线" name="productId">
                        <Select allowClear placeholder="请选择产品线">
                            {productId && productId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="操作人" name="updatedByStaffId">
                        <Select allowClear placeholder="请选择操作人">
                            {updatedByStaffId && updatedByStaffId.map(item => {
                                return (
                                    <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="状态" name="status">
                        <Select allowClear placeholder="请选择合作状态" >
                            <Select.Option value={0}>未上架</Select.Option>
                            <Select.Option value={1}>已上架</Select.Option>
                            <Select.Option value={2}>已下架</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
