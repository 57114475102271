import { Form, Modal,Input,InputNumber,message } from 'antd';
import React,{useEffect}from 'react';
import './index.less';
import {updateLiveApi} from '@services/HealthClass';
const AddLive = ({addLive,closeLive,rowData,getData}) => {
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const saveLive =()=>{
        form.validateFields().then(()=>{
            updateLiveApi({...form.getFieldsValue(),id:rowData.id}).then((res)=>{
                if(res.status_code===200){
                    message.success('添加成功')
                    closeLive()
                    getData()
                }
            });
        })
    };

    useEffect(() => {
        if (!addLive) {
            form.resetFields();
        }
    }, [addLive]);

    return (
        <div>
            <Modal
            open={addLive}
            onCancel={closeLive}
            title='增加直播时长'
            width={600}
            onOk={saveLive}
            destroyOnClose
            >
                <Form form={form}>
                    <Form.Item name='live' label="增加直播时长" className='rules-icon'
                    >
                        <Form.Item name="liveTime" noStyle
                        rules={[
                            {
                                required: true,
                                message: "请输入增加的直播时长",
                            },
                            {
                                pattern:  /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                                message:'请输入正数',
                            },
                        ]}
                        >
                        <InputNumber className='add-num'/>
                        </Form.Item>
                        <span className="ant-form-text">小时</span>
                    </Form.Item>

                    <Form.Item name='remark' label='增加理由'
                    rules={[
                        {required: true,
                        message: "请填写增加理由",
                    }]}
                    >
                    <TextArea rows={2} autoSize={{minRows: 2,maxRows: 2}}/>
                    </Form.Item>
                </Form>

            </Modal>
        </div>
    );
}

export default AddLive;
