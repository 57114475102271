export function setCookie(name, value, hours = 2) {
    let str = name + "=" + value;
    const time = new Date(new Date().getTime() + hours * 3600 * 1000).toGMTString();
    str += "; expires=" + time;
    // 写入Cookie
    document.cookie = str;
}

/**
 * 获取cookie
 */
export function getCookie(name) {
    const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    let arr = document.cookie.match(reg);
    if (arr !== null) {
        return arr[2];
    } else {
        return null;
    }
}

/**
 * 清除cookie
 */
export function clearCookie(name) {
    setCookie(name, '', -1)
}

export function localStorageSet(name, data) {
    const obj = {
        data,
    };
    localStorage.setItem(name, JSON.stringify(obj));
}

export function localStorageGet(name) {
    const storage = localStorage.getItem(name);
    const time = new Date().getTime();
    let result = null;
    if (storage) {
        const obj = JSON.parse(storage) || null;
        result = obj.data;
    }
    return result;
}
