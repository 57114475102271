import config from "@config";

// 上传文件路径 - 前缀
export const getFileUrl = (url) => {
    if (url) {
        if (url.startsWith("http")) {
            return url;
        } else {
            return config.JKJ_OSS_URL_PREFIX + "/" + url;
        }
    }

    return null;
};

// 项目类型的 key 值
export const projectKeys = {
    survey: 'survey',
    conference: 'conference',
    selection: 'selection',
    invitation: 'invitation',
    researchhospital: 'researchhospital'
};

// 项目类型
export const projectType = {
    [projectKeys.survey]: '医调研',
    [projectKeys.conference]: '医会议',
    [projectKeys.selection]: '医评选',
    [projectKeys.invitation]: '邀请函',
    [projectKeys.researchhospital]: '研究型医院评价遴选',
};

// 事件类型的 key 值
export const eventKeys = {
    submit_survey: 'submit_survey',
    checkin: 'checkin',
    submit_case: 'submit_case',
    score_case: 'score_case',
    download_judge_cert: 'download_judge_cert',
    download_case_cert: 'download_case_cert',
    confirm_invitation: 'confirm_invitation',
    view_rank: 'view_rank',
    submit_data: 'submit_data',
    enroll: 'enroll',
    confirm_profile: 'confirm_profile',

    view_live: 'view_live',
    view_live_replay: 'view_live_replay',
    view_quick_entry: 'view_quick_entry',
    view_site: 'view_site',
    confirm_actual_agendas: 'confirm_actual_agendas',
    confirm_finance_account: 'confirm_finance_account',
    download_cert: 'download_cert',
    unlock_cert: 'unlock_cert',
    unlock_competition_cert: 'unlock_competition_cert',
    download_competition_cert: 'download_competition_cert',
};

// 事件类型
export const eventType = {
    [eventKeys.submit_survey]: '提交问卷',
    [eventKeys.checkin]: '签到',
    [eventKeys.submit_case]: '提交案例',
    [eventKeys.score_case]: '案例评分',
    [eventKeys.download_judge_cert]: '下载聘书',
    [eventKeys.download_case_cert]: '下载案例证书',
    [eventKeys.confirm_invitation]: '确认邀请',
    [eventKeys.view_rank]: '查看榜单',
    [eventKeys.submit_data]: '填报数据',
    [eventKeys.enroll]: '报名',
    [eventKeys.confirm_profile]: '完善个人信息',

    [eventKeys.view_live]: '观看直播',
    [eventKeys.view_live_replay]: '观看直播回放',
    [eventKeys.view_quick_entry]: '查看快捷导航',
    [eventKeys.view_site]: '查看官网',
    [eventKeys.confirm_actual_agendas]: '确认实际日程',
    [eventKeys.confirm_finance_account]: '确认收款账户',
    [eventKeys.download_cert ]: '下载证书（奖牌）',
    [eventKeys.unlock_cert]: '解锁证书',
    [eventKeys.unlock_competition_cert]: '解锁完赛证书',
    [eventKeys.download_competition_cert]: '下载完赛证书',
};
