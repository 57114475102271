import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Radio,
    Select,
    message,
    Cascader,
    Typography,
    Upload,
    Alert,
    Checkbox,
    Tooltip,
    Modal,
    Progress,
    TreeSelect,
    Space,
    InputNumber,
    Layout
} from 'antd';
import ImgCrop from 'antd-img-crop'
import './CaseEdit.less';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import {
    getAuthDictionary,
    getProjectInfo,
    uploadFileApi,
    getAliyuncred,
    refreshAliyuncred,
    getProjectTopicFields,
    addCaseApi,
    caseDetailsApi,
    getVodMediaInfo,
    caseOrganizationApi,
    caseAddOrganizationApi,
    getZonesApi,
    videoTransCode
} from '@/services/Selection/case';
import config from '@/config';
// import Cookies from 'js-cookie';
import { LeftOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from 'moment';
import debounce from 'lodash/debounce';
import examIndicators from '@/utils/examIndicators';
import areas from "@utils/areas";

const { Content } = Layout;

// 视频上传实例
let add = null;

// 视频点播
let player = null;

// 提交参数getInfo - id
let paramsProjectId = null;

// 应用医院 - 当前新增所在行
let hospitalModalNum = 0;

// 合作机构 - 当前新增所在行
let orgModalNum = 0;

// 当前登录用户认证信息
let authedInfo = null;

const { Text } = Typography;
// const uploadFormOption = {headers: {'Content-Type': 'multipart/form-data'}};
const formItemLayout = {
    labelCol: {
        sm: { span: 4 },
        // lg: { span: 6 },
    },
    wrapperCol: {
        sm: { span: 19 },
        // lg: { span: 12 },
    }
};

const formItemWithoutLabelLayout = {
    wrapperCol: {
        sm: { offset: 4, span: 19 },
        // lg: { offset: 6, span: 12 },
    }
};

// 文件上传类型
const fileTypesMap = {
    "PPT": ["application/vnd.ms-powerpoint"],
    "PPTX": ["application/vnd.openxmlformats-officedocument.presentationml.presentation"],
    "DOC": ["application/msword"],
    "DOCX": ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
    "PDF": ["application/pdf"],
    "JPG": ["image/jpeg", "image/jpg"],
    "PNG": ["image/png"],
    "ZIP": ["application/zip", "application/x-zip-compressed"],
    "RAR": ["application/x-rar"],
    "XLS": ["application/vnd.ms-excel"],
    "XLSX": ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
};

export default function CaseEdit() {
    const [caseForm] = Form.useForm();
    const navigate = useNavigate();
    const urlParams = useParams();
    const location = useLocation();

    const caseId = urlParams.id;

    // 实施周期，结束日期类型：1至今 结束日期为空，2选择日期 结束日程为具体所选日期
    const [endDateType, setEndDateType] = useState(2);

    const [department, setDepartment] = useState([]);

    const [positions, setPositions] = useState([]);

    const [professional, setProfessional] = useState([]);

    const [topics, setTopics] = useState([]);

    const [directions, setDirections] = useState([]);

    const [description, setDescription] = useState(undefined);

    const [fileList, setFileList] = useState(null);
    const [docList, setDocList] = useState(null);
    const [videoList, setVideoList] = useState(null);
    const [materials, setMaterials] = useState([]);

    const [progressPercent, setProgressPercent] = useState(undefined);
    const [showProgress, setShowProgress] = useState(false);

    const [docProgressPercent, setDocProgressPercent] = useState(undefined);
    const [docShowProgress, setDocShowProgress] = useState(false);

    const [materialsProgressPercent, setMaterialsProgressPercent] = useState(undefined);
    const [materialsShowProgress, setMaterialsShowProgress] = useState(false);

    const [previewVideoOpen, setPreviewVideoOpen] = useState(false);

    const [videoId, setVideoId] = useState(undefined);

    const [projectTitle, setProjectTitle] = useState(undefined)

    const [selection, setSelection] = useState(null);

    const [caseFields, setCaseFields] = useState([]);

    const [caseTopicItem, setCaseTopicItem] = useState();

    const agreeCheckRef = useRef(false);
    const [showAgreeTip, setShowAgreeTip] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [imageList, setImageList] = useState(0);

    const [formFieldValues, setFormFieldValues] = useState({});
    const [topicName, setTopicName] = useState('');
    const [directionName, setDirectionName] = useState('');

    // 提报单位
    const [caseOrgValue, setCaseOrgValue] = useState(null);

    // 0 - 案例  1 - 人物
    const [isPeople, setIsPeople] = useState(0);

    // 是否展示所属赛区
    const [isShowZone, setIsShowZone] = useState(false);
    const [zones, setZones] = useState([]);
    const [zoneDirection, setZoneDirection] = useState(null);

    const onChangeEndDateType = ({ target: { value } }) => {
        setEndDateType(value);
        if (value === 1) {
            let newPeriod = caseForm.getFieldValue('period');
            newPeriod.end_date = undefined;
            caseForm.setFieldValue('period', newPeriod);
        }
    };

    // 提交即同意
    const onAgreeChange = (e) => {
        agreeCheckRef.current = e.target.checked;
        if (e.target.checked) {
            setShowAgreeTip(false);
        }
    };

    // 案例提交
    const saveCases = async () => {
        caseForm.validateFields().then(async (values) => {
            // if (!agreeCheckRef.current) {
            //     return setShowAgreeTip(true);
            // }
            let newValue = JSON.parse(JSON.stringify(values));
            if (newValue.period) {
                newValue.period = {
                    start_date: values.period.start_date ? values.period.start_date.format('YYYY-MM-DD') : '',
                    end_date: values.period.end_date ? values.period.end_date.format('YYYY-MM-DD') : '',
                }
            }
            // console.log(caseTopicItem);

            let params = {
                caseTopicFieldId: caseTopicItem?.id,
                memcard: location.state?.memcard,
                fieldValue: JSON.stringify(newValue),
                topicId: caseTopicItem?.topicId,
                id: caseId ? Number(caseId) : 0,
                status: location.state?.status,
                hospitalName: currentAddOrg?.label,
                projectId: paramsProjectId
            };

            const res = await addCaseApi(params);
            if (res && res.status_code === 200 && res.data) {
                message.success('保存成功');
                navigate('/selection/cases');
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    const changeTopic = (value) => {
        caseForm.setFieldValue('direction_id', undefined);

        // 切换主题 - 清除封面
        caseForm.setFieldValue('cover', undefined);
        setFileList(null);

        let topicsChildren = []
        if (topics && topics.length) {
            topics.forEach(item => {
                if (item.children && item.children.length) {
                    topicsChildren = topicsChildren.concat(item.children);
                } else {
                    topicsChildren.push(item);
                }
            })
        }

        let direction = topicsChildren.find(topic => topic.id === value);
        if (direction) {
            setDirections(direction.topicDirectionList);
            setDescription(direction.description);
            setIsPeople(direction.isPeople);
        }

        getProjectTopicFields({ topicId: value }).then((res) => {
            if (res && res.status_code === 200 && res.data) {
                let resData = res.data;
                let caseFields = JSON.parse(resData?.caseFields) ?? [];
                if (!!caseFields) {
                    setCaseFields(caseFields);
                }
                setCaseTopicItem(resData);
            } else {
                setCaseFields([]);
            }
        });
    }

    const hasField = name => caseFields.some(item => item.field_name === name);
    // 是否必填
    const hasRequired = name => caseFields.some(item => item.field_name === name && item.required);
    // 字段说明
    const hasDescriptionField = name => caseFields.find(item => item.field_name === name)?.field_desc;
    // 字段名称
    const getFieldName = name => caseFields.find(item => item.field_name === name)?.field_display_name;
    // 字段内容
    const getFieldValues = name => caseFields.find(item => item.field_name === name);
    // 二级字段内容是否展示
    const getSecondField = (name, secondName) => {
        const fieldItem = caseFields.find(item => item.field_name === name);
        if (fieldItem?.children && fieldItem.children.length > 0) {
            const isShow = fieldItem.children.some(item => item.field_name === secondName && item.checked);
            return isShow;
        }
        return false;
    };

    // 二级字段 - 是否是第一个字段
    const getSecondFieldFirst = (name, secondName) => {
        const fieldItem = caseFields.find(item => item.field_name === name);
        if (fieldItem?.children && fieldItem.children.length > 0) {
            const firstCheck = fieldItem.children.find(item => item.checked)?.field_name;
            return firstCheck === secondName;
        }
        return false;
    };

    // 封面
    const checkout = (file, size) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            return false;
        }
        // 判断大小
        const isLt5M = file.size / 1024 / 1024 < size;
        if (!isLt5M) {
            message.error("图像必须小于" + size + "MB!");
            return false;
        }
        return isJpgOrPng && isLt5M
    }

    // 图片
    const imagesCheckout = (file, size) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            return false;
        }
        // 判断大小
        const isLt5M = file.size / 1024 / 1024 <= (parseInt(getFieldValues('images')?.attachment_max_file_size || 5));
        if (!isLt5M) {
            message.error(`图片大小不能超过${parseInt(getFieldValues('images')?.attachment_max_file_size || 5)}M`);
            return false;
        }
        return isJpgOrPng && isLt5M
    }

    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return false;
        }

        if (file.size > 5 * 1024 * 1024) {
            message.error('图片大小不能超过5MB。');
            return false;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    if (isPeople) {
                        if (imgDom.width < 500 || imgDom.height < 500) {
                            bool && message.warn('案例封面不小于500×500px。');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    } else {
                        if (imgDom.width < 750 || imgDom.height < 422) {
                            bool && message.warn('案例封面不小于750×422px。');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    const headImageFile = {
        name: 'headImageFile',
        customRequest: (info) => {
            const formData = new FormData();
            formData.append('file', info.file); // 名字和后端接口名字对应
            formData.append('type', '13');
            uploadFileApi(formData)
                .then((res) => {
                    if (res && res.status_code === 200 && res.data) {
                        let url = res.data;
                        setFileList(url);
                        caseForm.setFieldValue('cover', url);
                        caseForm.setFields([{ name: 'cover', errors: null }]);
                    }
                });
        }
    };

    const fileProps = {
        name: "file",
        customRequest: async (info) => {
            let formatType = null;
            // TODO:windows上传rar(由于windows的rartype=‘’)
            const isRar = info.file.name.split('.')[1] === "rar";
            const fileType = getFieldValues('doc')?.attachment_type;
            console.log(getFieldValues('doc'));
            console.log(fileType);
            let isRightType = false;
            if (fileType && fileType.length) {
                const attachmentType = fileType.map(file => fileTypesMap[file.toUpperCase()]).flatMap(it => it);
                isRightType = attachmentType.includes(info.file.type) || (fileType.includes('RAR') && isRar);
            }
            const isLt100M = info.file.size / 1024 / 1024 <= (parseInt(getFieldValues('doc')?.attachment_max_file_size || 30));
            if (!isRightType) {
                message.error(`只能上传 ${getFieldValues('doc')?.attachment_type?.join('、') || 'PPT、PPTX、DOC、DOCX、PDF、PNG、JPG、ZIP、RAR、XLS、XLSX'}格式`);
                return;
            }
            if (!isLt100M) {
                message.error(`文件大小不能超过${parseInt(getFieldValues('doc')?.attachment_max_file_size || 30)}M`);
                return;
            }
            if (info.file.type === "application/vnd.ms-powerpoint") {
                formatType = 'ppt';
            }
            if (info.file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
                formatType = 'pptx';
            }
            if (info.file.type === "application/msword") {
                formatType = 'doc';
            }
            if (info.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                formatType = 'docx';
            }
            if (info.file.type === "application/pdf") {
                formatType = 'pdf';
            }

            let formData = new FormData();
            formData.append("file", info.file);
            formData.append("type", 13);

            setDocShowProgress(true);
            let configParams = {
                onUploadProgress: (Progress) => {
                    let percents = Math.round((Progress.loaded * 100) / Progress.total)
                    setDocProgressPercent(percents);  // 更新进度条
                }
            };

            uploadFileApi(formData, configParams)
                .then((res) => {
                    if (res && res.status_code === 200 && res.data) {
                        setDocShowProgress(false);
                        setDocProgressPercent(undefined);
                        let docItem = {
                            name: info.file.name,
                            path: res.data,
                            ext: formatType
                        }
                        setDocList(docItem);

                        caseForm.setFieldValue('doc', docItem);
                        caseForm.setFields([
                            { name: 'doc', errors: null }
                        ]);
                    }
                });
        },
        onChange(info) {
            const isJpgOrPng = info.file.type === "application/vnd.ms-powerpoint"
                || info.file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                || info.file.type === "application/msword"
                || info.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                || info.file.type === "application/pdf";
            const isLt30M = info.file.size / 1024 / 1024 < 31;
            if (!isJpgOrPng) {
                info.file.status = "error";
            }
            if (!isLt30M) {
                info.file.status = "error";
            }
            if (info.file?.id) {
                info.file.status = "error";
            }
            if (info.file.status === "uploading") {
                info.file.status = "done";
            }
        },
    };

    // 上传证明材料
    const materialsProps = {
        name: "file",
        maxCount: 10,
        fileList: [],
        customRequest: async (info) => {
            // TODO:windows上传rar(由于windows的rartype=‘’)
            const isRar = info.file.name.split('.')[1] === "rar";
            const fileType = getFieldValues('certificates')?.attachment_type;
            let isRightType = false;
            if (fileType && fileType.length) {
                const attachmentType = fileType.map(file => fileTypesMap[file.toUpperCase()]).flatMap(it => it);
                isRightType = attachmentType.includes(info.file.type) || (fileType.includes('RAR') && isRar);
                console.log(attachmentType, info.file.type)
            }
            const isLt100M = info.file.size / 1024 / 1024 <= (parseInt(getFieldValues('certificates')?.attachment_max_file_size || 50));
            if (!isRightType) {
                message.error(`只能上传 ${getFieldValues('certificates')?.attachment_type?.join('、') || 'PPT、PPTX、DOC、DOCX、PDF、PNG、JPG、ZIP、RAR、XLS、XLSX'}格式`);
                return;
            }
            if (!isLt100M) {
                message.error(`文件大小不能超过${parseInt(getFieldValues('certificates')?.attachment_max_file_size || 50)}M`);
                return;
            }

            let formData = new FormData();
            formData.append("file", info.file);
            formData.append("type", 13);

            setMaterialsShowProgress(true);
            let configParams = {
                onUploadProgress: (Progress) => {
                    let percents = Math.round((Progress.loaded * 100) / Progress.total)
                    setMaterialsProgressPercent(percents);  // 更新进度条
                }
            };

            uploadFileApi(formData, configParams)
                .then((res) => {
                    if (res) {
                        setMaterialsShowProgress(false);
                        setMaterialsProgressPercent(undefined);
                        let oldList = materials || [];
                        const list = oldList.concat([{ url: res, title: info.file.name, ext: getExt(info.file.type) }]);
                        setMaterials(list);

                        caseForm.setFieldValue('certificates', list);
                        caseForm.setFields([
                            { name: 'certificates', errors: null }
                        ]);
                    }
                });
        },
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        }
    };

    // 获取文件类型ext
    const getExt = (fileType) => {
        if (fileType === "application/vnd.ms-powerpoint") return 'ppt';
        if (fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") return 'pptx';
        if (fileType === "application/msword") return 'doc';
        if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") return 'docx';
        if (fileType === "application/pdf") return 'pdf';
        return 'img';
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.startsWith('http')) {
            return url;
        }
        if (url && !url.startsWith('http')) {
            return `${config.BC_OSS_URL_PREFIX}/${url}`;
        }
        return null;
    };

    // 图片预览弹框关闭
    const handleCancel = () => setPreviewOpen(false);

    // 视频预览弹框关闭
    const handleVideoCancel = () => setPreviewVideoOpen(false);

    // 案例图片上传
    const uploadCaseImage = (info) => {
        const formData = new FormData();
        formData.append('file', info.file); // 名字和后端接口名字对应
        formData.append('type', '13');
        uploadFileApi(formData)
            .then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    let url = res.data;
                    let imagesList = caseForm.getFieldValue('images') || [];
                    let newImages = imagesList.concat([{ description: '', url }]);
                    caseForm.setFieldValue('images', newImages);
                    setImageList(newImages.length);
                    // caseForm.setFields([
                    //     {
                    //         name: 'cover', errors: null
                    //     }
                    // ])
                }
            });
    };

    const videoProps = {
        name: "video",

        customRequest: async (info) => {
            //手动上传
            const isJpgOrPng =
                info.file.type === "video/mp4" ||
                info.file.type === "video/rm" ||
                info.file.type === "video/rmvb";
            if (!isJpgOrPng) {
                message.error("只能上传 mp4/rm/rmvb 格式!");
                return;
            }
            const isLt700M = info.file.size / 1024 / 1024 < 701;
            if (!isLt700M) {
                message.error("文件大小不能超过700M");
                return;
            }
            // if (info.file.type === "video/mp4")
            //     if (info.file.type === "video/rm")
            //         if (info.file.type === "video/rmvb")
            //             add = createUploader()
            add = createUploader();

            var userData = '{"Vod":{}}';
            add.addFile(info.file, null, null, null, userData);
            //   setFileName(info.file.name);
            await add.startUpload();
        },
        onChange(info) {
            const isJpgOrPng =
                info.file.type === "video/mp4" ||
                info.file.type === "video/rm" ||
                info.file.type === "video/rmvb";
            const isLt2G = info.file.size / 1024 / 1024 <= 2048;
            if (!isJpgOrPng) {
                info.file.status = "error";
            }
            if (!isLt2G) {
                info.file.status = "error";
            }
            if (info.file.status === "uploading") {
                info.file.status = "done";
            }
            if (info.file.status === "uploading") {
                info.file.status = "done";
            }
            if (info.file.status === "removed") {
                add.stopUpload()
                setProgressPercent(undefined)
            }

        },
    };

    // 阿里云视频上传实例
    const createUploader = () => {
        let uploader = new window.AliyunUpload.Vod({
            timeout: 1200000,
            partSize: 1048576,
            parallel: 100,
            retryCount: 3,
            retryDuration: 2,
            region: 'cn-shanghai',
            userId: '1303984639806000',

            // 添加文件成功
            addFileSuccess: (uploadInfo) => {
                if (uploadInfo !== null) {
                    uploader.startUpload()
                }

            },

            // 开始上传
            onUploadstarted: async (uploadInfo) => {
                setShowProgress(true);
                if (!uploadInfo.videoId) {
                    let params = {
                        fileName: uploadInfo.file.name,
                        title: uploadInfo.file.name.substring(0, uploadInfo.file.name.lastIndexOf(".")),
                        companyCode: 'CASE',
                        mediaType: 1,
                        cateId: 14131
                    };
                    await getAliyuncred(params)
                        .then(res => {
                            if (res && res.status_code === 200 && res.data) {
                                let data = res.data;
                                let uploadAuth = data.uploadAuth;
                                let uploadAddress = data.uploadAddress;
                                let videoId = data.videoId;
                                setVideoId(data.videoId);
                                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                            }
                        });
                } else {
                    // 如果videoId有值，根据videoId刷新上传凭证
                    refreshAliyuncred({ videoId: uploadInfo.videoId })
                        .then(res => {
                            if (res && res.status_code === 200 && res.data) {
                                let data = res.data;
                                let uploadAuth = data.uploadAuth;
                                let uploadAddress = data.uploadAddress;
                                let videoId = data.videoId;
                                setVideoId(data.videoId);
                                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                            }
                        });
                }
            },
            // 文件上传成功
            onUploadSucceed: function (uploadInfo) {
                // console.log(uploadInfo);
                let videoItem = {
                    name: uploadInfo.file.name,
                    videoId: uploadInfo.videoId,
                };
                setVideoList(videoItem);
                caseForm.setFieldValue('video', videoItem);
                caseForm.setFields([{ name: 'video', errors: null }]);
                setShowProgress(false);
                let formData = new FormData();
                // formData.append("file", null); //名字和后端接口名字对应
                formData.append("companyCode", "SELECTION");
                formData.append("mediaType", '1');
                formData.append("cateId", '15934');
                formData.append("fileName", `${videoItem.name}`);
                formData.append("title", videoItem.name.substring(0, uploadInfo.file.name.lastIndexOf(".")));
                formData.append("videoId", videoItem.videoId);
                videoTransCode(formData)
            },
            // 文件上传失败
            onUploadFailed: function (uploadInfo, code, message) {
                message.error('上传失败');
            },
            // 取消文件上传
            onUploadCanceled: function (uploadInfo, code, message) {
            },
            // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
            onUploadProgress: function (uploadInfo, totalSize, progress) {
                setProgressPercent(Math.ceil(progress * 100));
            },
            // 上传凭证超时
            onUploadTokenExpired: function (uploadInfo) {
                // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口
                refreshAliyuncred({ videoId: uploadInfo.videoId })
                    .then(res => {
                        if (res && res.status_code === 200 && res.data) {
                            let data = res.data;
                            let uploadAuth = data.uploadAuth;
                            setVideoId(data.videoId);
                            uploader.resumeUploadWithAuth(uploadAuth);
                        }
                    });
            },
            // 全部文件上传结束
            onUploadEnd: function (uploadInfo) {
                setProgressPercent(undefined);
            }
        })
        return uploader
    }

    // 视频点播 - 主题配置
    const playerSkinLayout = [
        { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
        { name: "H5Loading", align: "cc" },
        { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
        { name: "infoDisplay" },
        { name: "tooltip", align: "blabs", x: 0, y: 56 },
        { name: "thumbnail" },
        {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                { name: "progress", align: "blabs", x: 0, y: 44 },
                { name: "playButton", align: "tl", x: 15, y: 12 },
                { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                // {name:"subtitle", align:"tr",x:15, y:12},
                { name: "setting", align: "tr", x: 15, y: 12 },
                { name: "volume", align: "tr", x: 5, y: 10 }
            ]
        }
    ];

    // 视频预览
    const videoPreview = async (mediaId) => {
        if (!mediaId) {
            return;
        }
        if (player) {
            player.dispose();
        }
        setPreviewVideoOpen(true);

        const res = await getVodMediaInfo({ mediaId });
        if (res && res.status_code === 200 && window.Aliplayer) {
            const playInfo = res.data;
            if (playInfo.status === 'Normal') {
                const playauth = playInfo.playAuth;
                player = new window.Aliplayer({
                    id: 'J_prismPlayer',
                    width: "760px",
                    height: "510px",
                    vid: mediaId,
                    playauth,
                    autoplay: false,
                    isLive: false,
                    rePlay: false,
                    playsinline: false,
                    preload: true,
                    skinLayout: playerSkinLayout
                }, function (player) {

                });
            }
        }
    };

    // 获取项目信息
    const getProjectInfoList = async (infoData) => {
        const res = await getProjectInfo({
            slug: location.state?.slug,
            // token: Cookies.get('memToken')
        });
        if (res && res.status_code === 200 && res.data) {
            paramsProjectId = res.data?.id;
            setProjectTitle(res.data.name);
            if (res?.status_code === 200 && res.data && res.data.zoneOn) {
                setIsShowZone(true);
                initZones(res.data.id, infoData);
            }

            if (res.data.topicList && res.data.topicList.length) {
                const newList = res.data.topicList.map(item => {
                    let newItem = { ...item };

                    // 分类禁用
                    if (newItem.pid === null) {
                        newItem.disabled = true;
                    }
                    if (newItem.children && newItem.children.length) {
                        newItem.children = newItem.children.filter(child => !child.hide);
                    }
                    return newItem;
                });
                setTopics(newList);
            }

            if (infoData) {
                setTopicName(infoData.topicName);
                setDirectionName(infoData.directionName);

                let topicsChildren = []
                if (res.data.topicList && res.data.topicList.length) {
                    res.data.topicList.forEach(item => {
                        if (item.children && item.children.length) {
                            topicsChildren = topicsChildren.concat(item.children);
                        } else {
                            topicsChildren.push(item);
                        }
                    })
                }

                let direction = topicsChildren.find(topic => topic.id === infoData?.topicId);

                if (direction) {
                    setDirections(direction.topicDirectionList);
                    setDescription(direction.description);
                }

                getProjectTopicFields({ topicId: infoData?.topicId })
                    .then((res) => {
                        if (res && res.status_code === 200 && res.data) {
                            let resField = res.data;
                            let caseFields = JSON.parse(resField?.caseFields) ?? [];
                            if (!!caseFields) {
                                setCaseFields(caseFields);
                            }
                            setCaseTopicItem(resField);
                        }
                    });

                let formData = infoData.fieldValue ? JSON.parse(infoData.fieldValue) : null;
                if (formData) {
                    setFormFieldValues(formData);

                    formData.period = {
                        start_date: moment(formData.period?.start_date),
                        end_date: formData.period?.end_date ? moment(formData.period?.end_date) : undefined,
                    }
                    if (!formData.period?.end_date) {
                        setEndDateType(1);
                    }
                    setFileList(formData?.cover);
                    setDocList(formData?.doc);
                    setVideoList(formData?.video);
                    setMaterials(formData?.certificates);
                    const currentOptions = [{ value: formData.case_org_id, label: infoData.hospitalName }];
                    setOptions(currentOptions);
                    setCurrentAddOrg({ value: formData.case_org_id, label: infoData.hospitalName });

                    // 回显应用医院、合作机构
                    if (formData.applied_hospitals && formData.applied_hospitals.length) {
                        const currentHosAddorg = formData.applied_hospitals.map(item => ({ value: item.applied_hospital, label: item.name }));
                        setHospitalAddOrg(currentHosAddorg);
                        const currentHosOptions = formData.applied_hospitals.map(item => ([{ value: item.applied_hospital, label: item.name }]));
                        setHospitalOptions(currentHosOptions);
                    }
                    if (formData.organizations && formData.organizations.length) {
                        const currentOrgAddorg = formData.organizations.map(item => ({ value: item.organization, label: item.name }));
                        setOrgAddOrg(currentOrgAddorg);
                        const currentOrgOptions = formData.organizations.map(item => ([{ value: item.organization, label: item.name }]));
                        setOrgOptions(currentOrgOptions);
                    }

                    if (formData.images && formData.images.length) {
                        setImageList(formData.images.length);
                    }

                    caseForm.setFieldsValue(formData);
                }
            }

        }
    };

    const disabledStartDate = (current) => {
        let endDate = caseForm.getFieldValue('period')?.end_date;
        if (endDate) {
            return current && current > endDate.endOf('day');
        }
        return null;
    };

    const disabledEndDate = (current) => {
        let startDate = caseForm.getFieldValue('period')?.start_date;
        if (startDate) {
            return current && current <= startDate.endOf('day');
        }
        return null;
    };

    // 初始化部门、科室、职务列表数据
    const initList = () => {
        getAuthDictionary({ identity: 'A01', type: 'KSH' })
            .then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    setDepartment(res.data);
                }
            });
        getAuthDictionary({ identity: 'A01', type: 'ZHW' })
            .then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    setPositions(res.data);
                }
            });
        getAuthDictionary({ identity: 'A01', type: 'ZHCH' })
            .then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    setProfessional(res.data);
                }
            });
    };

    // 初始化赛区
    const initZones = async (proId, detailInfo) => {
        const res = await getZonesApi({ projectId: proId });
        if (res && res.status_code === 200 && res.data && res.data.regions) {
            const zoneInfo = JSON.parse(res.data.regions);
            setZones(zoneInfo);
            // 回显赛区详情
            if (detailInfo && detailInfo.fieldValue) {
                const fieldValues = JSON.parse(detailInfo.fieldValue);
                const currentZone = zoneInfo.filter(item => item.name === fieldValues.region);
                if (currentZone && currentZone.length) {
                    setZoneDirection(currentZone[0].desc);
                }
            }
        }
    };

    // 判断认证状态；请求案例相关信息
    const checkAuthAndLoadCaseInfo = async () => {
        initList();

        if (caseId) {
            caseDetailsApi({ caseId }).then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    getProjectInfoList(res.data);
                }
            });
        }
    }

    useEffect(() => {
        checkAuthAndLoadCaseInfo();
    }, []);

    // 新增机构 modal
    const [visibleOrgModal, setVisibleOrgModal] = useState(false);

    const showOrgModal = (num) => {
        orgModalNum = num;
        setVisibleOrgModal(true);
    };

    const closeOrgModal = () => {
        orgModalForm.resetFields();
        setVisibleOrgModal(false);
    };

    // 新增机构
    const handleOrgModalOk = () => {
        orgModalForm.validateFields().then(async (values) => {
            const res = await caseAddOrganizationApi({
                ...values,
                memCard: location.state?.memcard,
                bodyType: 0
            });
            if (res && res.status_code === 200 && res.data) {
                const currentOrg = { label: res.data.hosName, value: res.data.id };
                // 当前新增数据回显
                let newArr = [...orgAddOrg];
                newArr[orgModalNum] = currentOrg;
                setOrgAddOrg(newArr);

                // 当前新增数据下拉列表回显
                let newArrOptions = [...orgOptions];
                newArrOptions[orgModalNum] = [currentOrg];
                setOrgOptions(newArrOptions);

                // 当前新增所在行回显
                let newForm = caseForm.getFieldValue('organizations');
                newForm[orgModalNum] = { organization: res.data.id, name: res.data.hosName };
                caseForm.setFieldValue('organizations', newForm);
                // caseForm.setFields([
                //     { name: 'case_org_id', errors: null }
                // ]);
            }
            setVisibleOrgModal(false);
            orgModalForm.resetFields();
        });
        // setVisibleOrgModal(false);
    };

    // 新增医院 modal
    const [visibleHospitalModal, setVisibleHospitalModal] = useState(false);

    const showHospitalModal = (num) => {
        hospitalModalNum = num;
        setVisibleHospitalModal(true);
    };

    const closeHospitalModal = () => {
        setVisibleHospitalModal(false);
        hospitalForm.resetFields();
    };

    // 新增医院
    const handleHospitalModalOk = () => {
        hospitalForm.validateFields().then(async (values) => {
            const res = await caseAddOrganizationApi({
                ...values,
                memCard: location.state?.memcard,
                bodyType: 1
            });
            if (res && res.status_code === 200 && res.data) {
                const currentOrg = { label: res.data.hosName, value: res.data.id };
                // 当前新增数据回显
                let newArr = [...hospitalAddOrg];
                newArr[hospitalModalNum] = currentOrg;
                setHospitalAddOrg(newArr);

                // 当前新增数据下拉列表回显
                let newArrOptions = [...hospitalOptions];
                newArrOptions[hospitalModalNum] = [currentOrg];
                setHospitalOptions(newArrOptions);

                // 当前新增所在行回显
                let newForm = caseForm.getFieldValue('applied_hospitals');
                newForm[hospitalModalNum] = { applied_hospital: res.data.id, name: res.data.hosName };
                caseForm.setFieldValue('applied_hospitals', newForm);
            }
            setVisibleHospitalModal(false);
            hospitalForm.resetFields();
        });
    };

    // 参赛声明 modal
    const [visibleCopyrightModal, setVisibleCopyrightModal] = useState(false);

    const showCopyrightModal = () => {
        setVisibleCopyrightModal(true);
    };

    const closeCopyrightModal = () => {
        setVisibleCopyrightModal(false);
    };

    const fetchOrgList = async (keyword, type) => {
        setSearchValue(keyword);
        if (!keyword) return [];
        const res = await caseOrganizationApi({ keyword, type: type || undefined });
        if (res && res.status_code === 200 && res.data?.length) {
            const list = res.data.map(org => ({
                label: org.hosName,
                value: org.id
            }));
            return list;
        }
        return [];
    }

    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const fetchHospitalRef = useRef(0);
    const fetchOrgRef = useRef(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orgForm] = Form.useForm();
    const [currentAddOrg, setCurrentAddOrg] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState([]);

    // 应用医院
    const [hospitalForm] = Form.useForm();
    const [hospitalOptions, setHospitalOptions] = useState([[], [], [], [], [], [], [], [], [], []]);
    const [hospitalAddOrg, setHospitalAddOrg] = useState([null, null, null, null, null, null, null, null, null, null]);

    // 合作机构
    const [orgModalForm] = Form.useForm();
    const [orgOptions, setOrgOptions] = useState([[], [], [], [], [], [], [], [], [], []]);
    const [orgAddOrg, setOrgAddOrg] = useState([null, null, null, null, null, null, null, null, null, null]);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOrgList(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 500);
    }, [fetchOrgList]);

    // 应用医院
    const debounceHospitalFetcher = useMemo(() => {
        const loadOptions = (value, index) => {
            fetchHospitalRef.current += 1;
            const fetchId = fetchHospitalRef.current;
            let newArr = [...hospitalOptions];
            newArr[index] = [];
            setHospitalOptions(newArr);
            setFetching(true);
            fetchOrgList(value, 1).then((newOptions) => {
                if (fetchId !== fetchHospitalRef.current) {
                    return;
                }
                let newArr = [...hospitalOptions];
                newArr[index] = newOptions;
                setHospitalOptions(newArr);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 500);
    }, [fetchOrgList]);

    // 合作机构
    const debounceOrgFetcher = useMemo(() => {
        const loadOptions = (value, index) => {
            fetchOrgRef.current += 1;
            const fetchId = fetchOrgRef.current;
            let newArr = [...orgOptions];
            newArr[index] = [];
            setOrgOptions(newArr);
            setFetching(true);
            fetchOrgList(value).then((newOptions) => {
                if (fetchId !== fetchOrgRef.current) {
                    return;
                }
                let newArr = [...orgOptions];
                newArr[index] = newOptions;
                setOrgOptions(newArr);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 500);
    }, [fetchOrgList]);

    // 应用医院上下移
    const swapItems = (index1, index2) => {
        let newArrOptions = [...hospitalOptions];
        newArrOptions[index1] = newArrOptions.splice(index2, 1, newArrOptions[index1])[0];
        setHospitalOptions(newArrOptions);

        let newArr = [...hospitalAddOrg];
        newArr[index1] = newArr.splice(index2, 1, newArr[index1])[0];
        setHospitalAddOrg(newArr);
    }

    // 应用医院删除
    const removeHospital = (index) => {
        let newArrOptions = [...hospitalOptions];
        newArrOptions[index] = [];
        setHospitalOptions(newArrOptions);

        let newArr = [...hospitalAddOrg];
        newArr[index] = null;
        setHospitalAddOrg(newArr);
    }

    // 合作机构删除
    const removeOrg = (index) => {
        let newArrOptions = [...orgOptions];
        newArrOptions[index] = [];
        setOrgOptions(newArrOptions);

        let newArr = [...orgAddOrg];
        newArr[index] = null;
        setOrgAddOrg(newArr);
    }

    // 合作机构上下移
    const swapOrgItems = (index1, index2) => {
        let newArrOptions = [...orgOptions];
        newArrOptions[index1] = newArrOptions.splice(index2, 1, newArrOptions[index1])[0];
        setOrgOptions(newArrOptions);

        let newArr = [...orgAddOrg];
        newArr[index1] = newArr.splice(index2, 1, newArr[index1])[0];
        setOrgAddOrg(newArr);
    }

    const addCaseOrg = () => {
        setIsModalOpen(true);
    };

    const handleOrgOk = () => {
        orgForm.validateFields().then(async (values) => {
            const res = await caseAddOrganizationApi({
                ...values,
                memCard: location.state?.memcard
            });
            if (res && res.status_code === 200 && res.data) {
                const currentOrg = { label: res.data.hosName, value: res.data.id };
                setCurrentAddOrg(currentOrg);
                setOptions([currentOrg]);
                setCaseOrgValue(res.data.id)
                caseForm.setFieldValue('case_org_id', res.data.id);
                caseForm.setFields([
                    { name: 'case_org_id', errors: null }
                ]);
            }
        });
        setIsModalOpen(false);
    };

    const handleOrgCancel = () => {
        setIsModalOpen(false);
        orgForm.resetFields();
    };

    // 赛区改变
    const changeRegion = (value) => {
        const current = zones.filter(item => item.name === value);
        if (current && current.length) {
            setZoneDirection(current[0].desc);
        }
    };

    // 遍历原始数据，去掉第三层的 title
    const modifiedData = examIndicators.map(hospital => {
        return {
            title: hospital.title,
            children: hospital.children.map(category => {
                return {
                    title: category.title,
                    children: category.children.reduce((acc, item) => {
                        return [...acc, ...item.children];
                    }, [])
                };
            })
        };
    });

    // 证明材料删除
    const removeAttachment = (url) => {
        let oldList = [...materials];
        const newFileList = oldList.filter((file) => file.url !== url);
        console.log(newFileList)
        setMaterials(newFileList);

        caseForm.setFieldValue('certificates', newFileList);
    }

    return (
        <>
            <div className="page-title">
                <Link to={`/selection/cases`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">案例详情</span>
                {/* {!!fieldInfo.title && <span className="current-subtitle">{fieldInfo.title}</span>} */}
            </div>
            <Content className="main-content-box">
                <Form form={caseForm}
                    name="caseForm"
                    className={`page-form page-form-case caseForm`}
                    autoComplete="off"
                    {...formItemLayout}
                >

                    <Form.Item label="征集项目">
                        <span className="projectTitle">{projectTitle}</span>
                    </Form.Item>

                    <Form.Item
                        label={getFieldName('topic_id') || '主题'}
                        name="topic_id"
                        rules={[
                            { required: true, message: `${getFieldName('topic_id') || '主题'}为必选字段` }
                        ]}
                    >
                        <TreeSelect
                            treeDefaultExpandAll={true}
                            // treeExpandedKeys={expandedKeys}
                            placeholder={`请选择${getFieldName('topic_id') || '主题'}`}
                            treeData={topics}
                            onSelect={changeTopic}
                            fieldNames={{ label: 'name', value: 'id' }}
                        ></TreeSelect>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            return !!getFieldValue('topic_id') && description && (
                                <Form.Item {...formItemWithoutLabelLayout}>
                                    <div className="topicDescription">
                                        {description}
                                    </div>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            return !getFieldValue('topic_id') && (
                                <Form.Item {...formItemWithoutLabelLayout}>
                                    <Alert message="选定主题后展示完整填报字段" type="warning" showIcon />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    {
                        hasField('title') &&
                        <Form.Item
                            label={getFieldName('title') || (isPeople ? '人物姓名' : '标题')}
                            name="title"
                            rules={[
                                { required: hasRequired('title'), message: `${getFieldName('title') || (isPeople ? '人物姓名' : '标题')}不能为空` }
                            ]}
                        >
                            <Input placeholder={`请填写${getFieldName('title') || (isPeople ? '人物姓名' : '标题')}`} maxLength={50} showCount />
                        </Form.Item>
                    }

                    {
                        hasField('case_org_id') &&
                        <Form.Item
                            label={getFieldName('case_org_id') || '单位'}
                            name="case_org_id"
                            rules={[
                                { required: hasRequired('case_org_id'), message: `${getFieldName('case_org_id') || '单位'}为必选字段` }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                onSearch={debounceFetcher}
                                value={caseOrgValue}
                                placeholder="输入关键字检索单位"
                                onChange={(newValue) => {
                                    setCaseOrgValue(newValue);
                                    const targetValue = options && options.length ? options.filter(org => org.value === newValue) : [];
                                    if (targetValue && targetValue.length) {
                                        const currentOrg = { label: targetValue[0].label, value: targetValue[0].value };
                                        setCurrentAddOrg(currentOrg);
                                    }
                                }}
                                style={{ width: '100%' }}
                                notFoundContent={searchValue ? <div style={{ textAlign: 'center' }}>未找到的单位名称，<Button type="link" onClick={addCaseOrg}>点击新增单位</Button></div> : null}
                                options={options}
                            >
                            </Select>
                        </Form.Item>
                    }

                    {
                        hasField('area') &&
                        <Form.Item
                            label={getFieldName('area') || '提报单位所在地'}
                            name="area"
                            rules={[
                                { required: hasRequired('area'), message: `${getFieldName('area') || '提报单位所在地'}不能为空` }
                            ]}
                        >
                            <Cascader
                                placeholder={`请选择${getFieldName('area') || '提报单位所在地'}`}
                                options={areas}
                            ></Cascader>
                        </Form.Item>
                    }
                    {
                        isShowZone && <>
                            <Form.Item
                                label={getFieldName('region') || '所属赛区'}
                                name="region"
                                rules={[
                                    { required: hasRequired('region'), message: `${getFieldName('region') || '所属赛区'}不能为空` }
                                ]}
                            >
                                <Select
                                    placeholder={`请选择${getFieldName('region') || '所属赛区'}`}
                                    onChange={changeRegion}
                                    fieldNames={{ label: 'name', value: 'name' }}
                                    options={zones}
                                ></Select>
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    return !!getFieldValue('region') && zoneDirection && (
                                        <Form.Item {...formItemWithoutLabelLayout}>
                                            <div className="topicDescription">
                                                {zoneDirection}
                                            </div>
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        </>
                    }

                    {hasField('party_org_name') &&
                        <Form.Item
                            label={getFieldName('party_org_name') || '党组织名称'}
                            name="party_org_name"
                            rules={[
                                { required: hasRequired('party_org_name'), message: `${getFieldName('party_org_name') || '党组织名称'}不能为空` }
                            ]}
                        >
                            <Input placeholder={`请填写${getFieldName('party_org_name') || '党组织名称'}`} maxLength={50} showCount />
                        </Form.Item>
                    }

                    {hasField('direction_id') && directions && directions.length > 0 &&
                        <Form.Item
                            label={getFieldName('direction_id') || '申报方向'}
                            name="direction_id"
                            rules={[
                                { required: hasRequired('direction_id'), message: `${getFieldName('direction_id') || '申报方向'}为必选字段` }
                            ]}
                        >
                            <Radio.Group style={{ marginTop: '5px' }}>
                                <Space direction="vertical">
                                    {directions && directions.length
                                        ? directions.map(item => <Radio key={item.id} value={item.id}>{item.description}</Radio>)
                                        : null
                                    }
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    }

                    {hasField('cover') &&
                        <Form.Item
                            label={getFieldName('cover') || '封面'}
                            name="cover"
                            rules={[
                                { required: hasRequired('cover'), message: `${getFieldName('cover') || '封面'}不能为空` }
                            ]}
                            extra={isPeople
                                ? '上传人物照片作为封面，JPG/PNG格式，比例1:1，不小于500×500px，不超过5MB'
                                : '可截屏上传PPT封面或关键信息页作为封面，JPG/PNG格式，比例16:9，不小于750×422px，不超过5MB'
                            }
                        >
                            <>
                                {fileList && fileList.length
                                    ? <div className="coverImage" style={{ backgroundImage: `url(${getFileUrl(fileList)})` }} />
                                    : null
                                }

                                <ImgCrop
                                    aspect={isPeople ? (1 / 1) : (16 / 9)}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true);
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) => checkout(file, 5)}
                                        {...headImageFile}
                                        maxCount={1}
                                        fileList={[]}
                                    >
                                        <Button
                                            className="btnWithIcon"
                                            icon={<>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                </svg>
                                            </>}
                                        >
                                            {fileList && fileList.length ? '更换图片' : '上传图片'}
                                        </Button>
                                    </Upload>
                                </ImgCrop>
                            </>
                        </Form.Item>
                    }

                    {hasField('introduction') &&
                        <Form.Item
                            label={getFieldName('introduction') || '简介'}
                            name="introduction"
                            rules={[
                                { required: hasRequired('introduction'), message: `${getFieldName('introduction') || '简介'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={300} showCount placeholder={`请填写${getFieldName('introduction') || '简介'}`} />
                        </Form.Item>
                    }

                    {hasField('person_case_info') && (
                        <>
                            {
                                getSecondField('person_case_info', 'phone') &&
                                <Form.Item
                                    label={getSecondFieldFirst('person_case_info', 'phone') ? (getFieldName('person_case_info') || '人物信息') : ''}
                                    name={['person_case_info', 'phone']}
                                    {...(getSecondFieldFirst('person_case_info', 'phone') ? {} : formItemWithoutLabelLayout)}
                                    rules={[
                                        { required: hasRequired('person_case_info'), message: '未填写手机号' },
                                        { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                                    ]}
                                >
                                    <Input
                                        placeholder="手机号"
                                        maxLength={11}
                                        prefix={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        }
                                    />
                                </Form.Item>
                            }
                            {
                                getSecondField('person_case_info', 'email') &&
                                <Form.Item
                                    label={getSecondFieldFirst('person_case_info', 'email') ? (getFieldName('person_case_info') || '人物信息') : ''}
                                    name={['person_case_info', 'email']}
                                    {...(getSecondFieldFirst('person_case_info', 'email') ? {} : formItemWithoutLabelLayout)}
                                    rules={[
                                        { required: hasRequired('person_case_info'), message: '未填写电子邮箱' },
                                        { pattern: /^.*?@.*?\.[a-z]+$/i, message: '电子邮箱格式不正确' },
                                    ]}
                                >
                                    <Input
                                        placeholder="电子邮箱"
                                        prefix={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                            </svg>
                                        }
                                    />
                                </Form.Item>
                            }
                            {
                                getSecondField('person_case_info', 'department') &&
                                <Form.Item
                                    {...(getSecondFieldFirst('person_case_info', 'department') ? {} : formItemWithoutLabelLayout)}
                                    label={getSecondFieldFirst('person_case_info', 'department') ? (getFieldName('person_case_info') || '人物信息') : ''}
                                    name={['person_case_info', 'department']}
                                    rules={[
                                        { required: hasRequired('person_case_info'), message: '未选择人物科室部门' }
                                    ]}
                                >
                                    <Cascader
                                        placeholder="请选择人物科室部门"
                                        options={department}
                                        fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                    ></Cascader>
                                </Form.Item>
                            }
                            {
                                getSecondField('person_case_info', 'post') &&
                                <Form.Item
                                    label={getSecondFieldFirst('person_case_info', 'post') ? (getFieldName('person_case_info') || '人物信息') : ''}
                                    name={['person_case_info', 'post']}
                                    {...(getSecondFieldFirst('person_case_info', 'post') ? {} : formItemWithoutLabelLayout)}
                                    rules={[
                                        { required: hasRequired('person_case_info'), message: '未选择人物职务' }
                                    ]}
                                >
                                    <Cascader
                                        placeholder="请选择人物职务"
                                        options={positions}
                                        fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                    ></Cascader>
                                </Form.Item>
                            }
                            {
                                getSecondField('person_case_info', 'title') &&
                                <Form.Item
                                    label={getSecondFieldFirst('person_case_info', 'title') ? (getFieldName('person_case_info') || '人物信息') : ''}
                                    name={['person_case_info', 'title']}
                                    {...(getSecondFieldFirst('person_case_info', 'title') ? {} : formItemWithoutLabelLayout)}
                                    rules={[
                                        { required: hasRequired('person_case_info'), message: '未选择人物职称' }
                                    ]}
                                >
                                    <Cascader
                                        placeholder="请选择人物职称"
                                        options={professional}
                                        fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                    ></Cascader>
                                </Form.Item>
                            }

                            {
                                !!hasDescriptionField('person_case_info') &&
                                <Form.Item {...formItemWithoutLabelLayout}>
                                    <Alert message={hasDescriptionField('person_case_info')} type="info" />
                                </Form.Item>
                            }
                        </>
                    )}

                    {hasField('background') && (
                        <Form.Item
                            label={getFieldName('background') || '背景'}
                            name="background"
                            rules={[
                                { required: hasRequired('background'), message: `${getFieldName('background') || '背景'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={300} showCount placeholder={`请填写${getFieldName('background') || '背景'}`} />
                        </Form.Item>
                    )}

                    {hasField('doc') && (
                        <Form.Item
                            label={getFieldName('doc') || '文档'}
                            name="doc"
                            rules={[
                                { required: hasRequired('doc'), message: `未上传${getFieldName('doc') || '文档'}` }
                            ]}
                            extra={`支持${getFieldValues('doc')?.attachment_type?.join('、') || 'PPT、PPTX、PDF'}格式，大小不超过${getFieldValues('doc')?.attachment_max_file_size || 30}MB`}
                        >
                            <>
                                {docList
                                    ? <div className="docBox">
                                        {docList.ext === 'pdf' && <div className="docIcon" title="PDF 文件">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                                <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                            </svg>
                                        </div>}
                                        {!!(docList.ext === 'doc' || docList.ext === 'docx') &&
                                            <div className="docIcon" title="Word 文件">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-word" viewBox="0 0 16 16">
                                                    <path d="M4.879 4.515a.5.5 0 0 1 .606.364l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 7.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 0 1 .364-.606z" />
                                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                                </svg>
                                            </div>}
                                        {!!(docList.ext === 'ppt' || docList.ext === 'pptx') &&
                                            <div className="docIcon" title="PowerPoint 文件">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-ppt" viewBox="0 0 16 16">
                                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                                                    <path d="M6 5a1 1 0 0 1 1-1h1.188a2.75 2.75 0 0 1 0 5.5H7v2a.5.5 0 0 1-1 0V5zm1 3.5h1.188a1.75 1.75 0 1 0 0-3.5H7v3.5z" />
                                                </svg>
                                            </div>}
                                        <div className="docName">{docList.name}</div>
                                    </div>
                                    : null
                                }

                                <Upload {...fileProps} maxCount={1} fileList={[]}>
                                    <Button className="btnWithIcon"
                                        icon={<>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                            </svg>
                                        </>}
                                    >
                                        {docList ? '更换文档' : '上传文档'}
                                    </Button>
                                </Upload>
                                {docShowProgress && <Progress percent={docProgressPercent} />}
                            </>
                        </Form.Item>
                    )}

                    {hasField('video') && (
                        <Form.Item
                            label={getFieldName('video') || '视频'}
                            name="video"
                            rules={[
                                { required: hasRequired('video'), message: `未上传${getFieldName('video') || '视频'}` }
                            ]}
                            extra={`支持MP4格式，建议1080p (1920×1080px），不小于720p（1280×720px），大小不超过${getFieldValues('video')?.attachment_max_file_size || 700}MB`}
                        >
                            {videoList
                                ? <div className="docBox">
                                    <div className="docIcon" title="视频 文件">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-play" viewBox="0 0 16 16">
                                            <path d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z" />
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                    </div>
                                    <div className="docName">{videoList.name}</div>
                                    <Button onClick={() => videoPreview(videoList.videoId)}>视频预览</Button>
                                </div>
                                : null
                            }
                            <Upload {...videoProps} maxCount={1} fileList={[]}>
                                <Button
                                    className="btnWithIcon"
                                    icon={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                        </svg>
                                    </>}
                                >
                                    {videoList ? '更换文件' : '上传文件'}
                                </Button>
                            </Upload>
                            {showProgress && <Progress percent={progressPercent} />}
                        </Form.Item>
                    )}

                    {hasField('images') && (
                        <Form.Item
                            label={getFieldName('images') || '图片'}
                            name="images"
                            rules={[
                                { required: hasRequired('images'), message: `未上传${getFieldName('images') || '图片'}` }
                            ]}
                            extra={`支持JPG/PNG，不超过9张，单个图片文件大小不超过${getFieldValues('images')?.attachment_max_file_size || 5}MB`}
                            shouldUpdate
                        >
                            <>
                                <Form.List name="images">
                                    {(fields, { add, remove, move }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, index) => (
                                                <div className="imgListBox" key={key}>
                                                    <div className="imgWithDesc">
                                                        <div className="imgBox" style={{ backgroundImage: `url(${getFileUrl(caseForm.getFieldValue('images')[index]?.url)})` }}></div>
                                                        <div className="imgDesc">
                                                            <Form.Item
                                                                name={[name, 'description']}
                                                                {...restField}
                                                                rules={[{ required: hasRequired('images'), message: '' }]}
                                                            >
                                                                <Input.TextArea placeholder="图片说明" maxLength={50} showCount />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="fieldOperationsWithPreview">
                                                            <div
                                                                className="fieldOperation previewImg"
                                                                onClick={() => {
                                                                    setPreviewImage(caseForm.getFieldValue('images')[index]?.url);
                                                                    setPreviewOpen(true);
                                                                }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                </svg>
                                                                <span>预览</span>
                                                            </div>
                                                            <div className="fieldOperation remove" onClick={() => { remove(name); setImageList(imageList - 1) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                                </svg>
                                                                <span>移除</span>
                                                            </div>
                                                            {index !== 0 &&
                                                                <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                        <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                                    </svg>
                                                                    <span>上移</span>
                                                                </div>
                                                            }
                                                            {index !== fields.length - 1 && <div
                                                                className="fieldOperation moveDown"
                                                                onClick={() => move(index, index + 1)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                                </svg>
                                                                <span>下移</span>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </Form.List>

                                {
                                    imageList < 9 ?
                                        <Upload
                                            maxCount={9}
                                            customRequest={uploadCaseImage}
                                            beforeUpload={(file) => imagesCheckout(file, 5)}
                                            fileList={[]}
                                        >
                                            <Button
                                                className="btnWithIcon"
                                                icon={<>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                    </svg>
                                                </>}
                                            >
                                                上传文件
                                            </Button>
                                        </Upload> : null
                                }
                            </>

                        </Form.Item>
                    )}

                    {hasField('certificates') && (
                        <Form.Item
                            label={getFieldName('certificates') || '证明材料'}
                            name="certificates"
                            rules={[
                                { required: hasRequired('certificates'), message: `未上传${getFieldName('certificates') || '证明材料'}` }
                            ]}
                            extra={<>
                                <div>格式要求：{getFieldValues('certificates')?.attachment_max_file_size || '50'}M以内的文件，支持格式 {getFieldValues('certificates')?.attachment_type?.join('、') || 'PPT、PPTX、DOC、DOCX、PDF、PNG、JPG、ZIP、RAR、XLS、XLSX'}格式，不超过{getFieldValues('certificates')?.attachment_max_count || 10}个</div>
                                {
                                    !!hasDescriptionField('certificates') &&
                                    <Form.Item>
                                        <Alert message={hasDescriptionField('certificates')} type="info" />
                                    </Form.Item>
                                }
                            </>}
                        >
                            <>
                                <Upload {...materialsProps} maxCount={1} fileList={[]}>
                                    {true ?
                                        <Button className="btnWithIcon"
                                            icon={<>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                </svg>
                                            </>}
                                        >
                                            上传文件
                                        </Button>
                                        : null
                                    }
                                </Upload>
                                {
                                    materials && materials.length ? materials.map((item, index) => (
                                        <p key={index} className="materials">
                                            <span>{item.title}</span>
                                            <span className="fileOperation">
                                                {/* <a onClick={() => attachmentPreview(item.url, item.ext)} ><EyeOutlined />预览</a> */}
                                                <a onClick={() => removeAttachment(item.url)}><MinusCircleOutlined />移除</a>
                                            </span>
                                        </p>
                                    )) : null
                                }
                                {materialsShowProgress && <Progress percent={materialsProgressPercent} />}
                            </>
                        </Form.Item>
                    )}

                    {hasField('processes') && (
                        <Form.Item
                            label={getFieldName('processes') || '流程结构'}
                            name="processes"
                            rules={[
                                { required: hasRequired('processes'), message: `${getFieldName('processes') || '流程结构'}不能为空` }
                            ]}
                            extra={`请填写${getFieldName('processes') || '流程结构'}，最多添加10条`}
                        >
                            <Form.List name="processes">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('processes') || '流程结构'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'process']}
                                                    rules={[{ required: hasRequired('processes'), message: '' }]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('processes') || '流程结构'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove"
                                                        onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown"
                                                            onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('features') && (
                        <Form.Item
                            label={getFieldName('features') || '创新亮点'}
                            name="features"
                            rules={[
                                { required: hasRequired('features'), message: `${getFieldName('features') || '创新亮点'}不能为空` }
                            ]}
                            extra={`请填写${getFieldName('features') || '创新亮点'}，最多添加10个`}
                        >
                            <Form.List name="features">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('features') || '创新亮点'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'feature']}
                                                    rules={[
                                                        { required: hasRequired('features'), message: '' }
                                                    ]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('features') || '创新亮点'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove"
                                                        onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('scenarios') && (
                        <Form.Item
                            label={getFieldName('scenarios') || '应用场景'}
                            name="scenarios"
                            rules={[
                                { required: hasRequired('scenarios'), message: `${getFieldName('scenarios') || '应用场景'}不能为空` }
                            ]}
                            extra={`请填写${getFieldName('scenarios') || '应用场景'}，最多添加10个${getFieldName('scenarios') || '应用场景'}`}
                        >
                            <Form.List name="scenarios">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('scenarios') || '应用场景'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'scenario']}
                                                    rules={[
                                                        { required: hasRequired('scenarios'), message: '' }
                                                    ]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('scenarios') || '应用场景'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove" onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('solution') && (
                        <Form.Item
                            label={getFieldName('solution') || '解决方案'}
                            name="solution"
                            rules={[
                                { required: hasRequired('solution'), message: `${getFieldName('solution') || '解决方案'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={300} showCount placeholder={`请填写${getFieldName('solution') || '解决方案'}`} />
                        </Form.Item>
                    )}

                    {hasField('benefits') && (
                        <Form.Item
                            label={getFieldName('benefits') || '效果效益'}
                            name="benefits"
                            rules={[
                                { required: hasRequired('benefits'), message: `${getFieldName('benefits') || '效果效益'}不能为空` }
                            ]}
                            extra={`请填写${getFieldName('benefits') || '效果效益'}，最多添加10个${getFieldName('benefits') || '效果效益'}`}
                        >
                            <Form.List name="benefits">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('benefits') || '效果效益'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'benefits']}
                                                    rules={[
                                                        { required: hasRequired('benefits'), message: '' }
                                                    ]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('benefits') || '效果效益'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove" onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('solved_problems') && (
                        <Form.Item
                            label={getFieldName('solved_problems') || '痛点堵点'}
                            name="solved_problems"
                            rules={[
                                { required: hasRequired('solved_problems'), message: `${getFieldName('solved_problems') || '痛点堵点'}不能为空` }
                            ]}
                            extra={`请填写${getFieldName('solved_problems') || '痛点堵点'}，最多添加10个${getFieldName('solved_problems') || '痛点堵点'}`}
                        >
                            <Form.List name="solved_problems">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('solved_problems') || '痛点堵点'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'solved_problems']}
                                                    rules={[
                                                        { required: hasRequired('solved_problems'), message: '' }
                                                    ]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('solved_problems') || '痛点堵点'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove" onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('served_times') &&
                        <Form.Item
                            label={getFieldName('served_times') || '服务人次'}
                            name="served_times"
                            rules={[
                                { required: hasRequired('served_times'), message: `${getFieldName('served_times') || '服务人次'}不能为空` }
                            ]}
                        >
                            <InputNumber min={0} precision={0} placeholder={`请填写${getFieldName('served_times') || '服务人次'}`} />
                        </Form.Item>
                    }

                    {hasField('honors') && (
                        <Form.Item
                            label={getFieldName('served_times') || '曾获荣誉'}
                            // className="required"
                            name="honors"
                            extra={`请填写${getFieldName('served_times') || '服务人次'}，最多添加10项${getFieldName('served_times') || '服务人次'}`}
                            rules={[
                                { required: hasRequired('honors'), message: `${getFieldName('served_times') || '服务人次'}不能为空` }
                            ]}
                        >
                            <Form.List name="honors">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('served_times') || '服务人次'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'honor']}
                                                    rules={[
                                                        { required: hasRequired('honors'), message: '' }
                                                    ]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('served_times') || '服务人次'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove" onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('applied_hospitals') && (
                        <Form.Item
                            label={getFieldName('applied_hospitals') || '应用医院'}
                            // className="required"
                            name="applied_hospitals"
                            extra={`请填写${getFieldName('applied_hospitals') || '应用医院'}，最多添加10家${getFieldName('applied_hospitals') || '应用医院'}`}
                            rules={[
                                { required: hasRequired('applied_hospitals'), message: `${getFieldName('applied_hospitals') || '应用医院'}不能为空` }
                            ]}
                        >
                            <Form.List name="applied_hospitals">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('applied_hospitals') || '应用医院'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'applied_hospital']}
                                                    rules={[
                                                        { required: hasRequired('applied_hospitals'), message: '' }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        filterOption={false}
                                                        onSearch={(e) => debounceHospitalFetcher(e, index)}
                                                        placeholder={`输入关键字检索${getFieldName('applied_hospitals') || '应用医院'}`}
                                                        onChange={(newValue) => {
                                                            // setCaseOrgValue(newValue);
                                                            const targetValue = hospitalOptions[index] && hospitalOptions[index].length ? hospitalOptions[index].filter(org => org.value === newValue) : [];
                                                            if (targetValue && targetValue.length) {
                                                                const currentOrg = { label: targetValue[0].label, value: targetValue[0].value };
                                                                // setCurrentAddOrg(currentOrg);
                                                                let newArr = [...hospitalAddOrg];
                                                                newArr[index] = currentOrg;
                                                                setHospitalAddOrg(newArr);

                                                                let newForm = caseForm.getFieldValue('applied_hospitals');
                                                                newForm[index] = { applied_hospital: targetValue[0].value, name: targetValue[0].label };
                                                                caseForm.setFieldValue('applied_hospitals', newForm);
                                                            }
                                                        }}
                                                        style={{ width: '100%' }}
                                                        options={hospitalOptions[index]}
                                                    >
                                                    </Select>
                                                </Form.Item>

                                                <div className="notFoundAddNew">
                                                    <Tooltip title={`列表中找不到，新增${getFieldName('applied_hospitals') || '应用医院'}`}>
                                                        <Button type="link" onClick={() => showHospitalModal(index)}>新增{getFieldName('applied_hospitals') || '应用医院'}</Button>
                                                    </Tooltip>
                                                </div>

                                                <div className="fieldOperations fieldOperationsAdd">
                                                    <div className="fieldOperation remove" onClick={() => { remove(name); removeHospital(index) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => { move(index, index - 1); swapItems(index, index - 1); }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => { move(index, index + 1); swapItems(index, index + 1); }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('technologies') && (
                        <Form.Item
                            label={getFieldName('technologies') || '相关技术'}
                            // className="required"
                            name="technologies"
                            extra={`请填写${getFieldName('technologies') || '相关技术'}，最多添加10项${getFieldName('technologies') || '相关技术'}`}
                            rules={[
                                { required: hasRequired('technologies'), message: `${getFieldName('technologies') || '相关技术'}不能为空` }
                            ]}
                        >
                            <Form.List name="technologies">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('technologies') || '相关技术'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'technology']}
                                                    rules={[
                                                        { required: hasRequired('technologies'), message: '' }
                                                    ]}
                                                >
                                                    <Input showCount maxLength={100} placeholder={`请填写${getFieldName('technologies') || '相关技术'}`} />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    <div className="fieldOperation remove" onClick={() => remove(name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('organizations') && (
                        <Form.Item
                            label={getFieldName('organizations') || '合作机构'}
                            // className="required"
                            name="organizations"
                            extra={`请填写${getFieldName('organizations') || '合作机构'}，最多添加10家${getFieldName('organizations') || '合作机构'}`}
                            rules={[
                                { required: hasRequired('organizations'), message: `${getFieldName('organizations') || '合作机构'}不能为空` }
                            ]}
                        >
                            <Form.List name="organizations">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>添加{getFieldName('organizations') || '合作机构'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'organization']}
                                                    rules={[
                                                        { required: hasRequired('organizations'), message: '' }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        filterOption={false}
                                                        onSearch={(e) => debounceOrgFetcher(e, index)}
                                                        placeholder={`输入关键字检索${getFieldName('organizations') || '合作机构'}`}
                                                        onChange={(newValue) => {
                                                            // setCaseOrgValue(newValue);
                                                            const targetValue = orgOptions[index] && orgOptions[index].length ? orgOptions[index].filter(org => org.value === newValue) : [];
                                                            if (targetValue && targetValue.length) {
                                                                const currentOrg = { label: targetValue[0].label, value: targetValue[0].value };
                                                                // setCurrentAddOrg(currentOrg);
                                                                let newArr = [...hospitalAddOrg];
                                                                newArr[index] = currentOrg;
                                                                setOrgAddOrg(newArr);

                                                                let newForm = caseForm.getFieldValue('organizations');
                                                                newForm[index] = { organization: targetValue[0].value, name: targetValue[0].label };
                                                                caseForm.setFieldValue('organizations', newForm);
                                                            }
                                                        }}
                                                        style={{ width: '100%' }}
                                                        options={orgOptions[index]}
                                                    >
                                                    </Select>
                                                </Form.Item>

                                                <div className="notFoundAddNew">
                                                    <Tooltip title={`列表中找不到，新增${getFieldName('organizations') || '合作机构'}`}>
                                                        <Button type="link" onClick={() => showOrgModal(index)}>新增{getFieldName('organizations') || '合作机构'}</Button>
                                                    </Tooltip>
                                                </div>

                                                <div className="fieldOperations fieldOperationsAdd">
                                                    <div className="fieldOperation remove" onClick={() => { remove(name); removeOrg(index) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                        </svg>
                                                        <span>移除</span>
                                                    </div>

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => { move(index, index - 1); swapOrgItems(index, index - 1); }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => { move(index, index + 1); swapOrgItems(index, index + 1); }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('period') && (
                        <Form.Item
                            name="period"
                            // className="required"
                            label={getFieldName('period') || '实施周期'}
                            extra={<>实施开始时间和结束时间，至今未结束请选择<Text
                                type="warning">至今</Text>，有明确结束日期请选择结束日期</>}
                            rules={[
                                { required: hasRequired('period'), message: `${getFieldName('period') || '实施周期'}不能为空` }
                            ]}
                        >
                            <Form.Item
                                name={['period', "start_date"]}
                                noStyle
                                rules={[
                                    { required: hasRequired('period'), message: '' }
                                ]}
                            >
                                <DatePicker placeholder="开始日期" disabledDate={disabledStartDate} />
                            </Form.Item>

                            <Text style={{ color: '#999', padding: '0 4px' }}>～</Text>

                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                value={endDateType}
                                onChange={onChangeEndDateType}
                            >
                                <Radio value={1}>至今</Radio>
                                <Radio value={2}>选择日期</Radio>
                            </Radio.Group>

                            {endDateType === 2 && (
                                <Form.Item name={['period', "end_date"]} noStyle>
                                    <DatePicker
                                        placeholder="结束日期"
                                        style={{ borderLeft: 'none', marginLeft: '-2px' }}
                                        disabledDate={disabledEndDate}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    )}

                    {hasField('exam_indicators') && (
                        <Form.Item
                            label={getFieldName('exam_indicators') || '对应国考指标'}
                            // className="required"
                            name="exam_indicators"
                            rules={[
                                { required: hasRequired('exam_indicators'), message: `${getFieldName('exam_indicators') || '对应国考指标'}不能为空` }
                            ]}
                            initialValue={[{
                                exam_indicator: '',
                            }]}
                        >
                            <Form.List name="exam_indicators">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 10 && (
                                            <div className="newItem" onClick={() => add()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                                <span>新增{getFieldName('exam_indicators') || '对应国考指标'}</span>
                                            </div>
                                        )}

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} className="fieldListItem">
                                                <div>{index + 1}、</div>
                                                <Form.Item
                                                    noStyle
                                                    {...restFields}
                                                    name={[name, 'exam_indicator']}
                                                    rules={[
                                                        { required: hasRequired('exam_indicators'), message: '' }
                                                    ]}
                                                >
                                                    <Cascader
                                                        className="fieldInputCascader"
                                                        options={modifiedData}
                                                        placeholder={`请选择${getFieldName('exam_indicators') || '对应国考指标'}`}
                                                        fieldNames={{ value: 'title', label: 'title' }}
                                                    />
                                                </Form.Item>

                                                <div className="fieldOperations">
                                                    {index !== 0 &&
                                                        <div className="fieldOperation remove" onClick={() => remove(name)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                            </svg>
                                                            <span>移除</span>
                                                        </div>
                                                    }

                                                    {index !== 0 && (
                                                        <div className="fieldOperation moveUp" onClick={() => move(index, index - 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                            </svg>
                                                            <span>上移</span>
                                                        </div>
                                                    )}

                                                    {index !== fields.length - 1 && (
                                                        <div className="fieldOperation moveDown" onClick={() => move(index, index + 1)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                            </svg>
                                                            <span>下移</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {hasField('party_org_basic_info') &&
                        <Form.Item
                            label={getFieldName('party_org_basic_info') || '党组织基本情况'}
                            name="party_org_basic_info"
                            rules={[
                                { required: hasRequired('party_org_basic_info'), message: `${getFieldName('party_org_basic_info') || '党组织基本情况'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={300} showCount placeholder={`请填写${getFieldName('party_org_basic_info') || '党组织基本情况'}`} />
                        </Form.Item>
                    }

                    {hasField('party_org_innovation') &&
                        <Form.Item
                            label={getFieldName('party_org_innovation') || '党建业务融合创新做法'}
                            name="party_org_innovation"
                            rules={[
                                { required: hasRequired('party_org_innovation'), message: `${getFieldName('party_org_innovation') || '党建业务融合创新做法'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={1000} showCount placeholder={`请填写${getFieldName('party_org_innovation') || '党建业务融合创新做法'}`} />
                        </Form.Item>
                    }

                    {hasField('work_effect') &&
                        <Form.Item
                            label={getFieldName('work_effect') || '工作效果'}
                            name="work_effect"
                            rules={[
                                { required: hasRequired('work_effect'), message: `${getFieldName('work_effect') || '工作效果'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={500} showCount placeholder={`请填写${getFieldName('work_effect') || '工作效果'}`} />
                        </Form.Item>
                    }

                    {hasField('work_inspiration') &&
                        <Form.Item
                            label={getFieldName('work_inspiration') || '工作启示'}
                            name="work_inspiration"
                            rules={[
                                { required: hasRequired('work_inspiration'), message: `${getFieldName('work_inspiration') || '工作启示'}不能为空` }
                            ]}
                        >
                            <Input.TextArea rows={6} maxLength={1500} showCount placeholder={`请填写${getFieldName('work_inspiration') || '工作启示'}`} />
                        </Form.Item>
                    }

                    {hasField('contacts') && (
                        <>
                            <Form.List
                                name="contacts"
                                initialValue={[{
                                    name: '',
                                    phone: '',
                                    email: '',
                                    department: '',
                                    post: '',
                                    title: ''
                                }]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key}>
                                                {
                                                    getSecondField('contacts', 'name') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        label={getFieldName('contacts') || '案例主导负责人'}
                                                        rules={[
                                                            { required: hasRequired('contacts'), message: '未填写姓名' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请填写姓名"
                                                            maxLength={20}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }
                                                {
                                                    getSecondField('contacts', 'phone') &&
                                                    <Form.Item
                                                        {...restField}
                                                        label={getSecondFieldFirst('contacts', 'phone') ? (getFieldName('contacts') || '案例主导负责人') : ''}
                                                        name={[name, 'phone']}
                                                        {...(getSecondFieldFirst('contacts', 'phone') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('contacts'), message: '未填写手机号' },
                                                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="手机号"
                                                            maxLength={11}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('contacts', 'email') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'email']}
                                                        label={getSecondFieldFirst('contacts', 'email') ? (getFieldName('contacts') || '案例主导负责人') : ''}
                                                        {...(getSecondFieldFirst('contacts', 'email') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('contacts'), message: '未填写电子邮箱' },
                                                            { pattern: /^.*?@.*?\.[a-z]+$/i, message: '电子邮箱格式不正确' },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="电子邮箱"
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('contacts', 'department') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'department']}
                                                        label={getSecondFieldFirst('contacts', 'department') ? (getFieldName('contacts') || '案例主导负责人') : ''}
                                                        {...(getSecondFieldFirst('contacts', 'department') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('contacts'), message: '未选择科室部门' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择科室部门"
                                                            options={department}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('contacts', 'post') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'post']}
                                                        label={getSecondFieldFirst('contacts', 'post') ? (getFieldName('contacts') || '案例主导负责人') : ''}
                                                        {...(getSecondFieldFirst('contacts', 'post') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('contacts'), message: '未选择职务' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职务"
                                                            options={positions}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('contacts', 'title') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'title']}
                                                        label={getSecondFieldFirst('contacts', 'title') ? (getFieldName('contacts') || '案例主导负责人') : ''}
                                                        {...(getSecondFieldFirst('contacts', 'title') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('contacts'), message: '未选择职称' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职称"
                                                            options={professional}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    !!hasDescriptionField('contacts') &&
                                                    <Form.Item {...formItemWithoutLabelLayout}>
                                                        <Alert message={hasDescriptionField('contacts')} type="info" />
                                                    </Form.Item>
                                                }
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </>
                    )}

                    {hasField('case_leader_contacts') && (
                        <>
                            <Form.List name="case_leader_contacts" initialValue={[{
                                name: '',
                                phone: '',
                                email: '',
                                department: '',
                                post: '',
                                title: ''
                            }]}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key}>
                                                {
                                                    getSecondField('case_leader_contacts', 'name') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        label={getFieldName('case_leader_contacts') || '案例牵头人'}
                                                        rules={[
                                                            { required: hasRequired('case_leader_contacts'), message: '未填写姓名' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请填写姓名"
                                                            maxLength={20}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }
                                                {
                                                    getSecondField('case_leader_contacts', 'phone') &&
                                                    <Form.Item
                                                        {...restField}
                                                        label={getSecondFieldFirst('case_leader_contacts', 'phone') ? (getFieldName('case_leader_contacts') || '案例牵头人') : ''}
                                                        name={[name, 'phone']}
                                                        {...(getSecondFieldFirst('case_leader_contacts', 'phone') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('case_leader_contacts'), message: '未填写手机号' },
                                                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="手机号"
                                                            maxLength={11}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('case_leader_contacts', 'email') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'email']}
                                                        label={getSecondFieldFirst('case_leader_contacts', 'email') ? (getFieldName('case_leader_contacts') || '案例牵头人') : ''}
                                                        {...(getSecondFieldFirst('case_leader_contacts', 'email') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('case_leader_contacts'), message: '未填写电子邮箱' },
                                                            { pattern: /^.*?@.*?\.[a-z]+$/i, message: '电子邮箱格式不正确' },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="电子邮箱"
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('case_leader_contacts', 'department') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'department']}
                                                        label={getSecondFieldFirst('case_leader_contacts', 'department') ? (getFieldName('case_leader_contacts') || '案例牵头人') : ''}
                                                        {...(getSecondFieldFirst('case_leader_contacts', 'department') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('case_leader_contacts'), message: '未选择科室部门' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择科室部门"
                                                            options={department}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('case_leader_contacts', 'post') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'post']}
                                                        label={getSecondFieldFirst('case_leader_contacts', 'post') ? (getFieldName('case_leader_contacts') || '案例牵头人') : ''}
                                                        {...(getSecondFieldFirst('case_leader_contacts', 'post') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('case_leader_contacts'), message: '未选择职务' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职务"
                                                            options={positions}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('case_leader_contacts', 'title') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'title']}
                                                        label={getSecondFieldFirst('case_leader_contacts', 'title') ? (getFieldName('case_leader_contacts') || '案例牵头人') : ''}
                                                        {...(getSecondFieldFirst('case_leader_contacts', 'title') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('case_leader_contacts'), message: '未选择职称' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职称"
                                                            options={professional}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    !!hasDescriptionField('case_leader_contacts') &&
                                                    <Form.Item {...formItemWithoutLabelLayout}>
                                                        <Alert message={hasDescriptionField('case_leader_contacts')} type="info" />
                                                    </Form.Item>
                                                }
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>

                        </>
                    )}

                    {hasField('hospital_contacts') && (
                        <>
                            <Form.List name="hospital_contacts" initialValue={[{
                                name: '',
                                phone: '',
                                email: '',
                                department: '',
                                post: '',
                                title: ''
                            }]}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key}>
                                                {
                                                    getSecondField('hospital_contacts', 'name') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        label={getFieldName('hospital_contacts') || '医院联系人'}
                                                        rules={[
                                                            { required: hasRequired('hospital_contacts'), message: '未填写姓名' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请填写姓名"
                                                            maxLength={20}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }
                                                {
                                                    getSecondField('hospital_contacts', 'phone') &&
                                                    <Form.Item
                                                        {...restField}
                                                        label={getSecondFieldFirst('hospital_contacts', 'phone') ? (getFieldName('hospital_contacts') || '医院联系人') : ''}
                                                        name={[name, 'phone']}
                                                        {...(getSecondFieldFirst('hospital_contacts', 'phone') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('hospital_contacts'), message: '未填写手机号' },
                                                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="手机号"
                                                            maxLength={11}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('hospital_contacts', 'email') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'email']}
                                                        label={getSecondFieldFirst('hospital_contacts', 'email') ? (getFieldName('hospital_contacts') || '医院联系人') : ''}
                                                        {...(getSecondFieldFirst('hospital_contacts', 'email') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('hospital_contacts'), message: '未填写电子邮箱' },
                                                            { pattern: /^.*?@.*?\.[a-z]+$/i, message: '电子邮箱格式不正确' },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="电子邮箱"
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('hospital_contacts', 'department') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'department']}
                                                        label={getSecondFieldFirst('hospital_contacts', 'department') ? (getFieldName('hospital_contacts') || '医院联系人') : ''}
                                                        {...(getSecondFieldFirst('hospital_contacts', 'department') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('hospital_contacts'), message: '未选择科室部门' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择科室部门"
                                                            options={department}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('hospital_contacts', 'post') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'post']}
                                                        label={getSecondFieldFirst('hospital_contacts', 'post') ? (getFieldName('hospital_contacts') || '医院联系人') : ''}
                                                        {...(getSecondFieldFirst('hospital_contacts', 'post') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('hospital_contacts'), message: '未选择职务' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职务"
                                                            options={positions}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('hospital_contacts', 'title') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'title']}
                                                        label={getSecondFieldFirst('hospital_contacts', 'title') ? (getFieldName('hospital_contacts') || '医院联系人') : ''}
                                                        {...(getSecondFieldFirst('hospital_contacts', 'title') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('hospital_contacts'), message: '未选择职称' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职称"
                                                            options={professional}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    !!hasDescriptionField('hospital_contacts') &&
                                                    <Form.Item {...formItemWithoutLabelLayout}>
                                                        <Alert message={hasDescriptionField('hospital_contacts')} type="info" />
                                                    </Form.Item>
                                                }
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>

                        </>
                    )}


                    {hasField('enterprise_contacts') && (
                        <>
                            <Form.List name="enterprise_contacts" initialValue={[{
                                name: '',
                                phone: '',
                                email: '',
                                post: '',
                            }]}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key}>
                                                {
                                                    getSecondField('enterprise_contacts', 'name') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        label={getFieldName('enterprise_contacts') || '企业联系人'}
                                                        rules={[
                                                            { required: hasRequired('enterprise_contacts'), message: '未填写姓名' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请填写姓名"
                                                            maxLength={20}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }
                                                {
                                                    getSecondField('enterprise_contacts', 'phone') &&
                                                    <Form.Item
                                                        {...restField}
                                                        label={getSecondFieldFirst('enterprise_contacts', 'phone') ? (getFieldName('enterprise_contacts') || '企业联系人') : ''}
                                                        name={[name, 'phone']}
                                                        {...(getSecondFieldFirst('enterprise_contacts', 'phone') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('enterprise_contacts'), message: '未填写手机号' },
                                                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="手机号"
                                                            maxLength={11}
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('enterprise_contacts', 'email') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'email']}
                                                        label={getSecondFieldFirst('enterprise_contacts', 'email') ? (getFieldName('enterprise_contacts') || '企业联系人') : ''}
                                                        {...(getSecondFieldFirst('enterprise_contacts', 'email') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('enterprise_contacts'), message: '未填写电子邮箱' },
                                                            { pattern: /^.*?@.*?\.[a-z]+$/i, message: '电子邮箱格式不正确' },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="电子邮箱"
                                                            prefix={
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16" style={{ color: '#999' }}>
                                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                                </svg>
                                                            }
                                                        />
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('enterprise_contacts', 'department') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'department']}
                                                        label={getSecondFieldFirst('enterprise_contacts', 'department') ? (getFieldName('enterprise_contacts') || '企业联系人') : ''}
                                                        {...(getSecondFieldFirst('enterprise_contacts', 'department') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('enterprise_contacts'), message: '未选择科室部门' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择科室部门"
                                                            options={department}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('enterprise_contacts', 'post') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'post']}
                                                        label={getSecondFieldFirst('enterprise_contacts', 'post') ? (getFieldName('enterprise_contacts') || '企业联系人') : ''}
                                                        {...(getSecondFieldFirst('enterprise_contacts', 'post') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('enterprise_contacts'), message: '未选择职务' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职务"
                                                            options={positions}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    getSecondField('enterprise_contacts', 'title') &&
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'title']}
                                                        label={getSecondFieldFirst('enterprise_contacts', 'title') ? (getFieldName('enterprise_contacts') || '企业联系人') : ''}
                                                        {...(getSecondFieldFirst('enterprise_contacts', 'title') ? {} : formItemWithoutLabelLayout)}
                                                        rules={[
                                                            { required: hasRequired('enterprise_contacts'), message: '未选择职称' }
                                                        ]}
                                                    >
                                                        <Cascader
                                                            placeholder="请选择职称"
                                                            options={professional}
                                                            fieldNames={{ value: 'dicCode', label: 'dicName' }}
                                                        ></Cascader>
                                                    </Form.Item>
                                                }

                                                {
                                                    !!hasDescriptionField('enterprise_contacts') &&
                                                    <Form.Item {...formItemWithoutLabelLayout}>
                                                        <Alert message={hasDescriptionField('enterprise_contacts')} type="info" />
                                                    </Form.Item>
                                                }
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Form.List>

                        </>
                    )}

                    {/* <Form.Item {...formItemWithoutLabelLayout}>
                        <Checkbox onChange={onAgreeChange}>提交即同意</Checkbox>
                        <Button
                            type="link"
                            style={{ paddingLeft: 0 }}
                            onClick={() => showCopyrightModal()}
                        >
                            《参与声明》
                        </Button>
                        {showAgreeTip ? <div style={{ color: '#ff4d4f' }}>提交前请同意《参与声明》</div> : null}
                    </Form.Item> */}

                    <div className="formOperations">
                        {/* <Button
                            size="large"
                            style={{ marginRight: 24, width: 150 }}
                            onClick={() => saveCases(0)}
                        >
                            暂存草稿
                        </Button> */}

                        <Button
                            type="primary"
                            size="large"
                            style={{ width: 150 }}
                            htmlType="submit"
                            onClick={saveCases}
                        >
                            提交
                        </Button>
                    </div>


                    <Modal
                        title="新增医院"
                        open={visibleHospitalModal}
                        onOk={handleHospitalModalOk}
                        onCancel={closeHospitalModal}
                    >
                        <Form layout="vertical" form={hospitalForm} name="hospitalForm">
                            <Form.Item
                                name="orgName"
                                label="医院名称"
                                rules={[
                                    { required: true, message: '未填写医院名称' }
                                ]}
                            >
                                <Input placeholder="请输入医院名称" maxLength={30} showCount />
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title="新增机构"
                        open={visibleOrgModal}
                        onOk={handleOrgModalOk}
                        onCancel={closeOrgModal}
                    >
                        <Form layout="vertical" form={orgModalForm} name="orgModalForm">
                            <Form.Item
                                name="orgName"
                                label="机构名称"
                                rules={[
                                    { required: true, message: '未填写机构名称' }
                                ]}
                            >
                                <Input placeholder="请输入机构名称" maxLength={30} showCount />
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title="参与声明"
                        centered
                        open={visibleCopyrightModal}
                        className="copyrightModal"
                        footer={[
                            <Button key="close" type="primary" onClick={closeCopyrightModal}>我知道了</Button>
                        ]}
                    >
                        <p className="copyright">本案例/解决方案内容的著作权归参与单位所有，参与单位同意将案例著作权中的“信息网络传播权”等授予北京华媒康讯信息技术股份有限公司。案例/解决方案内容版权管理内容详见<a target="_blank" href="http://zk.cn-healthcare.com/about/copyrights">《健康界智库版权声明》</a>、<a target="_blank" href="https://ucenter.cn-healthcare.com/article/revision/artagreement">《企业健康号内容版权声明》</a>，请参与者仔细阅读并遵守前述声明。</p>
                    </Modal>

                    <Modal open={previewOpen} title="预览" footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: '100%', }} src={previewImage} />
                    </Modal>

                    <Modal open={previewVideoOpen} width={800} title="视频预览" footer={null} onCancel={handleVideoCancel}>
                        <div id="J_prismPlayer"></div>
                    </Modal>

                </Form>

                <Modal title="新增单位" open={isModalOpen} onOk={handleOrgOk} onCancel={handleOrgCancel}>
                    <Form form={orgForm} name="orgForm" autoComplete="off">
                        <Form.Item label="单位名称" name="orgName">
                            <Input placeholder="输入单位名称" />
                        </Form.Item>
                    </Form>
                </Modal>

            </Content>
        </>
    )
}
