import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StaffListFilterForm from './StaffListFilterForm';
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { Button, Layout, Table, Space, Menu, Dropdown, Modal, Form, Input, message } from 'antd';
import { get, post, put, del } from '@/core/request';

const { Content } = Layout;

export default function EnterpriseStaffList() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 17 }
    };
    // 渲染table数据的state
    const [tableData, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = (pageSize) => {
        setPageSize(pageSize)
    };

    // 改变第几页
    const changeCurrPage = (page) => {
        setCurrPage(page);
    };

    const { name, phone, email, status } = filterObj;

    const getData = () => {
        get(`/enterprise/staffs/query_page`, {
            limit: pageSize,
            page: currPage,
            name, phone, email, status
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.phone;
                });
                setTableData(res.data);
            }
        })
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [currPage, pageSize, name, phone, email, status]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 点击编辑 新增
    const handleClickEdit = (tip, row) => {
        return () => {
            navigate('/enterprise/staff/new', { state: { tip, id: row?.id } })
        }
    }

    // 冻结账号
    const stopConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: <span style={{ color: '#ff0000' }}>{row.status === 0 ? '冻结账号后，该员工将无法登录系统，确定冻结？' : '确认启用账号？'}</span>,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.status === 1) {
                        status = 0;
                    }
                    put("/enterprise/staffs/update", {
                        id: row.id,
                        status,
                    }).then((res) => {
                        if (res.status_code === 200) {
                            if (row.status === 0) {
                                message.success('已冻结账号！')
                            } else {
                                message.success('启用账号成功！')
                            }
                            getData()
                        }
                    });
                }
            });
        }
    };

    // 点击重置密码
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [passwordModal, setPasswordModal] = useState({
        staffName: '', password: '', id: 0
    });

    const resetPassword = (row) => {
        return () => {
            setPasswordModal({
                ...passwordModal,
                staffName: row.name,
                id: row.id
            })
            setIsModalVisible(true);
        }
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                put("/enterprise/staffs/update", {
                    id: passwordModal.id,
                    password: values.password,
                }).then((res) => {
                    if (res.status_code === 200) {
                        message.success('修改密码成功！')
                        setIsModalVisible(false);
                    }
                });
            })
            .catch((err) => { });
        setPedding(false);

    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    // 点击刷新
    const handleRefresh = () => {
        message.info('正在刷新', 1);
        changeCurrPage(1);
    }

    // table 数据相关
    const columns = [
        { title: '员工ID', dataIndex: 'id' },
        { title: '姓名', dataIndex: 'name', },
        { title: '手机', dataIndex: 'phone', },
        { title: '企业邮箱', dataIndex: 'email', },
        { title: '工号', dataIndex: 'number', },
        {
            title: '部门', dataIndex: 'departments',
            render: (operates) => (
                operates.length > 0 && operates.map(item => {
                    if (item) {
                        return (
                            item.name
                        )
                    }
                }).join('/')
            )
        },
        { title: '职务', dataIndex: 'post', },
        {
            title: '角色', dataIndex: 'roles',
            render: (operates) => {
                let arr = operates.length > 0 && operates.map(item => {
                    if (item) {
                        return (
                            item.name
                        )
                    }
                })
                if (arr) return (<span>{arr.join('/')}</span>);
            }
        },
        {
            title: '状态', dataIndex: 'status',
            render: (operates) => (<span className={operates === 1 ? "close-status" : "open-status"}>{operates === 1 ? "冻结" : "正常"}</span>)
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="reset" onClick={resetPassword(row)}>重置密码</Menu.Item>
                        <Menu.Item key="download" onClick={stopConfirm(row)}>{row.status === 1 ? "启用账号" : "冻结账号"}</Menu.Item>
                        {/* {row.status === 1 && <Menu.Item key="status" onClick={deleteConfirm(row)}>删除账号</Menu.Item>} */}
                    </Menu>
                )
                return (
                    <Dropdown.Button onClick={handleClickEdit('edit', row)} overlay={menu} size='small'>
                        编辑
                    </Dropdown.Button>
                )
            }
        },
    ];

    return (
        <Fragment>
            <div className="page-title">员工管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <StaffListFilterForm handleFilterFinish={handleFilterFinish} />
                </div>

                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="table-title">员工列表</div>
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickEdit('add')}>新增员工</Button>
                                <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}>刷新</Button>
                            </Space>
                        </div>
                    </div>
                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tableData.list}
                        pagination={paginationProps}
                    />
                </div>
            </Content>

            {
                isModalVisible && <Modal title="重置密码" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Form form={form} {...formLayout}>
                        <Form.Item label="员工账号">
                            {passwordModal.staffName}
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="新密码"
                            required={true}
                            help="密码不少于6位，必须包含数字或字母（大写或小写）"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/.test(value)) {
                                            return Promise.reject('密码不少于6位，必须包含数字或字母（大写或小写）');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="为该员工设置新的密码" allowClear autoComplete='off'/>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </Fragment>
    );
}
