import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import { Layout, Table, Space, Button, Modal, message } from 'antd';
import PlaybackWhitelistFilterForm from './PlaybackWhitelistFilterForm'
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import PlaybackWhitelistAddForm from './PlaybackWhitelistAddForm';
import { fullPagerConfig } from '@/utils/property';
import { Link, useLocation } from 'react-router-dom';
import { get, del, post } from '@/core/request';

const { Content } = Layout;

export default function PlaybackWhitelist() {
    const { state } = useLocation();

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = useCallback(
        (value) => {
            setFilterObj(value);
        },
        [],
    );

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, phone } = filterObj;

    const getData = () => {
        get(`/dms/limits/pageInWhitelist`, {
            limit: pageSize,
            page: currPage,
            name: name,
            phone: phone,
            type: 2,
            liveId: state.liveId,
            productId: state.productId,
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        });
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, name, phone, state.liveId, state.productId])

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };


    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除回放白名单',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/limits/batchDel', {
                        ids: [row.id],
                        liveId: state.liveId,
                        type: 2
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中回放白名单',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/limits/batchDel', {
                        ids: selectedRowKeys,
                        liveId: state.liveId,
                        type: 2
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何回放白名单');
                }
            }
        });
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        () => {
            drawer.current.show()
        },
        [],
    );

    const columns = [
        { title: '姓名', dataIndex: 'name', key: 'id' },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '所在城市', dataIndex: 'cityName', },
        { title: '医院名称', dataIndex: 'hospital', },
        { title: '所在科室', dataIndex: 'departmentName', },
        { title: '职称', dataIndex: 'titleName', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operate, row) => (<span className="delete-style" onClick={deleteConfirm(row)}>删除</span>)
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 添加白名单
    const handleAddAwhiteListFinish = (value) => {
        post(`/dms/limits/save`, {
            liveId: state.liveId,
            type: 2,
            userIds: value
        }).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        });
    }

    return (
        <Fragment>
            <div className="page-title"><Link to="/dms/lives/limits" className="back"><LeftOutlined /></Link>回放白名单管理
                <span className="page-title-tip">{state.name} [#{state.liveId}]</span></div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <PlaybackWhitelistFilterForm
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">回放列表</div>
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickAdd}>添加白名单</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="all-live-white-table"
                        pagination={paginationProps}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tabledata.list} />

                </div>
            </Content>

            <PlaybackWhitelistAddForm ref={drawer} handleAddAwhiteListFinish={handleAddAwhiteListFinish} productId={state.productId} liveId={state.liveId}></PlaybackWhitelistAddForm>

        </Fragment>
    )
}
