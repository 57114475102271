import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, DatePicker, Button, Space } from 'antd';
const { RangePicker } = DatePicker;

export default function UserStatisticsFilterForm({
    handleFilterFinish
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (values) => {
        values.viewStartTime = timePicker[0];
        values.viewEndTime = timePicker[1];
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            phone: undefined,
            platform: undefined,
            viewStartTime: undefined,
            viewEndTime: undefined
        };
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户名" name="name">
                        <Input allowClear placeholder="请输入用户名" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="请输入手机号" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="观看终端" name="platform">
                        <Select allowClear placeholder="请选择观看终端">
                            <Select.Option value={1}>手机网页</Select.Option>
                            <Select.Option value={2}>pc网页</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="观看时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}
