import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Space, Drawer } from 'antd';
import TableTransfer from './TableTransfer'

function LiveWhitelistAddForm({
    productId,liveId,handleAddAwhiteListFinish
}, ref) {

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [targetKeys, setTargetKeys] = useState([]);

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show() {
            setVisible(true);
        }
    }));

    const handleClose = () => {
        setVisible(false);
    };

    const handleOk = () => {
        handleAddAwhiteListFinish(targetKeys)
        setVisible(false);
    };

    return (
        <Fragment>
            {
                visible && <Drawer
                    title="添加直播白名单"
                    width="100%"
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <div style={{ textAlign: "right" }}>
                            <Space>
                                <Button onClick={handleClose}>取消</Button>
                                <Button type="primary" onClick={handleOk}>
                                    确定
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <TableTransfer setTargetKeys={setTargetKeys} productId={productId} liveId={liveId}></TableTransfer>
                    {/* <AddWhitelistTransferTable targetKeys={targetKeys} setTargetKeys={setTargetKeys} productId={productId} liveId={liveId}></AddWhitelistTransferTable> */}
                </Drawer>
            }


        </Fragment>
    );
}

export default forwardRef(LiveWhitelistAddForm)
