import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Form, Modal, Button, message, Input, DatePicker, Select } from 'antd';
import { confirmedAgendaQueryListApi, confirmedlaborInfoApi, improvePersionanlInfoApi } from '@services/SereviseConst';
import { sysdictionaryApi } from '@services/Experts';
import './ConfirmBankInfoModal.less';
import moment from 'moment';

// 选择专家
const ConfirmBankInfoModal = ({ openConfirmBankInfoModal, setOpenConfirmBankInfoModal, expertId, updateList }) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false); // 保存按钮loading
    const [data, setData] = useState({});
    const [form1] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({ bankCardNo: "", bankName: "", birthPlace: "", birthday: "", idCard: "", idcardType: "" });
    const [currentSelectType, setCurrentSelectType] = useState(1)
    const [currentSelectTime, setCurrentSelectTime] = useState("")
    const [buttonAble, setButtonAble] = useState(false)
    const [list, setList] = useState([]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    const onCancel = () => {
        setOpenConfirmBankInfoModal(false);
    };
    const onEdit = () => {
        let edit = !isEditing
        setIsEditing(edit);
    };
    const onSelect = (value) => {
        setCurrentSelectType(value)
    }
    const setTime = (_, time) => {
        setCurrentSelectTime(time);
    }
    const checkVail = () => {
        form1.validateFields().then(values => {
            setButtonAble(false);
        }).catch(err => {
            setButtonAble(true);
        })
    }
    const onConfirm = () => {
        Modal.confirm({
            title: '提示',
            width: 480,
            content: `我已和专家确认劳务信息正确无误，专家已确认不用调整。`,
            centered: true,
            okText: '确认',
            cancelText: '未确认',
            onOk: () => {
                confirmedlaborInfoApi({
                    expertId: expertId,
                    projectId: conferenceId
                }).then(res => {
                    message.success("操作成功")
                    setOpenConfirmBankInfoModal(false)
                    updateList()
                });
            }
        });
    };
    const onSave = () => {
        setSaveLoading(true);
        form1.validateFields().then(values => {
            let newValues = {
                bankCardNo: values.bankCardNo,
                bankName: values.bankName,
                birthday: currentSelectTime,
                birthPlace: values.birthPlace,
                idCard: values.idCard,
                idcardType: values.idcardType,
                expertId: expertId,
                conferenceId: conferenceId,
                nationality: values.nationality
            };
            improvePersionanlInfoApi(newValues).then(res => {
                setSaveLoading(false);
                if (res && res.status_code === 200) {
                    message.success("专家信息保存成功")
                    setFormData(newValues);
                    setIsEditing(false);
                }
            }).catch(err => {
                setSaveLoading(false);
            })
        }).catch(err => {
            setSaveLoading(false);
        })
    }
    // 劳务费列表
    const getList = async () => {
        setLoading(true);
        const res = await confirmedAgendaQueryListApi({
            conferenceId: conferenceId,
            expertId: expertId
        });
        if (res.status_code === 200) {
            setData(res.data);
            form1.setFieldValue("bankCardNo", res.data.bankCardNo)
            form1.setFieldValue("bankName", res.data.bankName)
            let time = res.data.birthday ? moment(res.data.birthday, 'YYYY-MM-DD') : null
            form1.setFieldValue("birthday", time)
            form1.setFieldValue("birthPlace", res.data.birthPlace)
            form1.setFieldValue("idCard", res.data.idCard)
            form1.setFieldValue("idcardType", res.data.idcardType)
            form1.setFieldValue("nationality", res.data.nationality)
            setCurrentSelectType(res.data.idcardType)
            setCurrentSelectTime(res.data.birthday)

            setFormData({
                bankCardNo: res.data.bankCardNo,
                bankName: res.data.bankName,
                birthday: res.data.birthday,
                birthPlace: res.data.birthPlace,
                idCard: res.data.idCard,
                idcardType: res.data.idcardType,
                nationality: res.data.nationality
            })
        }
        setLoading(false);
    };
    useEffect(() => {
        setTimeout(() => {
            checkVail()
        }, 500);
    }, [formData])

    useEffect(() => {
        if (expertId > 0 && openConfirmBankInfoModal) {
            setIsEditing(false)
            getList();
            sysdictionaryApi({ dicGroup: 'NATIONALITY' }).then(res => {
                if (res.status_code === 200 && res.data && res.data.length) {
                    setList(res.data);
                }
            })
        } else {
            form1.resetFields()
            setCurrentSelectTime("");
        }
    }, [openConfirmBankInfoModal]);

    return (
        <>
            <Modal
                className='bankInfo'
                title='确认收款信息'
                open={openConfirmBankInfoModal}
                centered
                onCancel={onCancel}
                destroyOnClose={true}
                footer={[
                    !isEditing && <Button disabled={buttonAble} type="primary" onClick={onConfirm}>确认劳务信息</Button>,
                    isEditing && <Button key="submit" type="primary" loading={saveLoading} onClick={onSave}>保存</Button>
                ]}
            >
                {!isEditing && <Button className='editButton' type='link' onClick={onEdit}>编辑</Button>}
                <Form {...formItemLayout} form={form1}>
                    <Form.Item label="专家姓名" name="username">
                        <span>{data.username}</span>
                    </Form.Item>
                    <Form.Item label="证件类型" name="idcardType" rules={[{ required: true, message: isEditing ? '请选择证件类型' : "" }]}>
                        {isEditing
                            ? (
                                <Select placeholder='请选择证件类型' onSelect={onSelect}>
                                    <Select.Option value={1}>身份证</Select.Option>
                                    <Select.Option value={2}>港澳台来往内地通行证</Select.Option>
                                    <Select.Option value={3}>台湾居民来往大陆通行证</Select.Option>
                                    <Select.Option value={4}>外国人永久居留身份证</Select.Option>
                                    <Select.Option value={5}>护照</Select.Option>
                                </Select>
                            )
                            : (
                                <>
                                    {formData.idcardType === 1 && <span>身份证</span>}
                                    {formData.idcardType === 2 && <span>港澳台来往内地通行证</span>}
                                    {formData.idcardType === 3 && <span>台湾居民来往大陆通行证</span>}
                                    {formData.idcardType === 4 && <span>外国人永久居留身份证</span>}
                                    {formData.idcardType === 5 && <span>护照</span>}
                                    {formData.idcardType === null && <span className='placeholderColor'>&ndash;</span>}
                                </>
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        label="证件号"
                        name="idCard"
                        rules={[
                            { required: true, message: isEditing ? '请填写证件号码' : '' },
                            { pattern: /^[0-9a-z]+$/i, message: '证件号格式不正确' },
                        ]}
                    >
                        {isEditing
                            ? <Input allowClear placeholder='请输入证件号码'/>
                            : <>
                                {formData.idCard && <span>{formData.idCard}</span>}
                                {!formData.idCard && <span className='placeholderColor'>&ndash;</span>}
                            </>
                        }
                    </Form.Item>
                    {currentSelectType !== 1 &&
                        <Form.Item label="国籍" name="nationality" rules={[{ required: currentSelectType !== 1, message: isEditing ? '请填写国籍' : '' }]}>
                            {isEditing
                                ? <Select fieldNames={{ label: 'dicName', value: 'dicName' }} options={list} placeholder='请选择国籍'/>
                                : <>
                                    {formData.nationality && <span>{formData.nationality}</span>}
                                    {!formData.nationality && <span className='placeholderColor'>&ndash;</span>}
                                </>
                            }
                        </Form.Item>
                    }
                    {currentSelectType !== 1 &&
                        <Form.Item label="出生日期" name="birthday" rules={[{ required: currentSelectType !== 1, message: isEditing ? '请选择出生日期' : "" }]}>
                            {isEditing
                                ? <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={setTime}/>
                                : <>
                                    {formData.birthday && <span>{formData.birthday}</span>}
                                    {!formData.birthday && <span className='placeholderColor'>&ndash;</span>}
                                </>
                            }
                        </Form.Item>
                    }

                    {currentSelectType !== 1 &&
                        <Form.Item label="出生地" name="birthPlace" rules={[{ required: currentSelectType !== 1, message: isEditing ? '请填写出生地' : '' }]}>
                            {isEditing
                                ? <Input allowClear placeholder='请输入出生地'/>
                                : <>
                                    {formData.birthPlace && <span>{formData.birthPlace}</span>}
                                    {!formData.birthPlace && <span className='placeholderColor'>&ndash;</span>}
                                </>
                            }
                        </Form.Item>

                    }


                    <Form.Item label="开户银行" name="bankName" rules={[{ required: true, message: isEditing ? '请填写开户银行' : '' }]}>
                        {isEditing
                            ? <Input allowClear placeholder='请输入开户行名称'/>
                            : <>
                                {formData.bankName && <span>{formData.bankName}</span>}
                                {!formData.bankName && <span className='placeholderColor'>&ndash;</span>}
                            </>
                        }
                    </Form.Item>
                    <Form.Item
                        label="银行账号"
                        name="bankCardNo"
                        rules={[
                            { required: true, message: isEditing ? '请输入银行账号' : '' },
                            { pattern: /^\d+$/i, message: '银行账号格式不正确' },
                        ]}
                    >
                        {isEditing
                            ? <Input allowClear placeholder='请输入银行账号'/>
                            : <>
                                {formData.bankCardNo && <span>{formData.bankCardNo}</span>}
                                {!formData.bankCardNo && <span className='placeholderColor'>&ndash;</span>}
                            </>
                        }
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
}

export default ConfirmBankInfoModal;
