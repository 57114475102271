import { get, put, post, delet } from '@/core/request';

/*
    接口服务--调研组件
*/

export const previewSurveryApi = (params) => {
    return get('/survey/surveys/preview', params);
};


// 问卷题目排序
export const sortSurveryApi = (data) => {
    return put('', data);
};

// 问卷管理列表 127.0.0.1:9015/survey/surveys/list?name=&surveyTypeId=&status=&page=1&pageSize=10
export const listSurveryApi = (params) => {
    return get('/survey/surveys/list', params);
};

export const surveyMarkAsTesting = data => put('/survey/surveys/markAsTesting', data);

// 发布&暂停
export const releaseSurveryApi = (params) => {
    return put('/survey/surveys/release', params);
};

// 新增问卷
export const saveSurveyApi = (data) => {
    return post('/survey/surveys/save', data);
};

//删除问卷
export const delSurveyApi = (data) => {
    return get('/survey/surveys/delete', data);
};

// 新增问卷题目
export const saveSurveryQuestionApi = (data) => {
    return post('/survey/surveyquestions/save', data);
};
// 新增问卷题目
export const updateSurveryQuestionApi = (data) => {
    return put('', data);
};
// 删除题目选项
export const delSurveyOptionsApi = (data) => {
    return get('/survey/surveysquestionoptions/delete', data);
};

// 删除题目
export const delSurveryQusetionApi = (params) => {
    return get('/survey/surveyquestions/delete', params);
};

//问卷配置
export const saveSettingApi = (data) => {
    return post('/survey/surveysettings/save', data);
};

//实时保存问卷
export const timesaveSurveryQuestionApi = (data) => {
    return put('/survey/surveyquestions/real-time-save', data);
};

//问卷题目排序
export const sortSurveryQuestionApi = (data) => {
    return put('/survey/surveyquestions/sort', data);
};

//切换题目类型
export const changeSurveyTypeApi = (data) => {
    return put('/survey/surveyquestions/change-type', data)
}

//问卷设置查询
export const querySettingApi = (data) => {
    return get('/survey/surveysettings/queryById', data)
}

//添加白名单
export const addSurveyWhitListsApi = (data) => {
    return post('/survey/surveywhitelists/save', data)
}

//获取白名单列表
export const listSurveyWhiteApi = (params) => {
    return get('/survey/surveywhitelists/list', params)
}


//删除白名单
// TODO:删除暂时使用post请求
export const delSurveyWhitListsApi = (data) => {
    return post('/survey/surveywhitelists/delete', data)
}


//问卷引用
export const quoteSurveyApi = (params) => {
    return get('/survey/surveys/quote', params)
}

//查询问卷的使用数量
export const subSurveyApi = (params) => {
    return get('survey/surveyanswersheets/submits', params)
}

//切换删除
export const delAnswerApi = (params) => {
    return get('/survey/surveyanswersheets/del-answer', params)
}


//查询详情列表
export const answerSheetsApi = (params) => {
    return get('/survey/surveyanswersheets/list', params)
}

//删除列表
export const answerDelApi = (params) => {
    return delet('/survey/surveyanswersheets/delete', params)
}


//查看白名单
export const checkWhiteApi = (params) => {
    return get('/survey/surveywhitelists/queryByPhone', params)
}

//导出作答详情
export const downloadAnswerApi = (params, options) => {
    return get('/survey/surveyanswersheets/download', params, { ...options, timeout: 300000 })
}

