import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, DatePicker, Button, Space } from 'antd';
const { RangePicker } = DatePicker;

export default function ProductionFilterForm({ handleFilterFinish, orgId, updatedByStaffId }) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);

    const onFinish = (values) => {
        values.createStartTime = timePicker[0];
        values.createEndTime = timePicker[1];
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            orgId: undefined,
            status: undefined,
            isCooperation: undefined,
            isJkjDisplay: undefined,
            createStartTime: undefined,
            createEndTime: undefined,
            updatedByStaffId: undefined
        }
        handleFilterFinish(values);
    };

    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线名称" name="name">
                        <Input allowClear placeholder="输入产品线名称" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="合作状态" name="isCooperation">
                        <Select allowClear placeholder="请选择合作状态">
                            <Select.Option value={0}>未合作</Select.Option>
                            <Select.Option value={1}>已合作</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="操作人" name="updatedByStaffId">
                        <Select allowClear placeholder="请选择操作人">
                            {updatedByStaffId && updatedByStaffId.map(item => {
                                return (
                                    <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="添加时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="状态" name="status">
                        <Select allowClear placeholder="请选择状态">
                            <Select.Option value={1}>正常</Select.Option>
                            <Select.Option value={2}>禁用</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="官网展示" name="isJkjDisplay">
                        <Select allowClear placeholder="选择官网展示状态">
                            <Select.Option value={0}>不展示</Select.Option>
                            <Select.Option value={1}>展示</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
