import {
    FileEarmarkPpt,
    FileEarmarkWord,
    FileEarmarkImage,
    FileEarmarkPdf,
    FileEarmarkMusic,
    FileEarmarkPlay,
    FileEarmarkExcel,
    FileEarmarkZip,
} from 'react-bootstrap-icons';

// 专家级别
export const expertLevels = [
    { level: 6,  label: '医院VIP★★★★★', desc: '政府正副部级、部级领导（含已退）。' },
    { level: 5,  label: '医院VIP★★★★', desc: '政府正副司局级领导、正副厅级领导；院士；国家级重要学协会会长、驻会副会长、秘书长；TOP20医院院长、书记。' },
    { level: 4,  label: '医院VIP★★★', desc: '政府正副处级、正副科级领导；国家级学协会副会长、秘书长；TOP21-50医院院长、书记；知名高校校长、医学院院长。' },
    { level: 3,  label: '医院VIP★★', desc: 'TOP100医院正副书记、院长；国家级专委会主委、分会会长；普通高校校长、研究院院长或所长；合作医院院长、书记。' },
    { level: 2,  label: '医院VIP★', desc: '政府、国家级学会工作人员；合作医院副院长、副书记、科室主任；省级重要学协会分会/专委会副主任委员；三甲医院院级管理者。' },
    { level: 1,  label: '医院VIP', desc: '除以上条件外的医疗决策人。' },
    { level: 9,  label: '医院人员', desc: '非医疗决策人的医院人员。' },
    { level: 24, label: '企业VIP★★★', desc: '百亿级公司VP及以上' },
    { level: 23, label: '企业VIP★★', desc: '十亿级公司VP及以上 + 百亿级公司中层' },
    { level: 22, label: '企业VIP★', desc: '亿级公司VP及以上 + 十亿级公司中层' },
    { level: 21, label: '企业VIP', desc: '亿级以下合作企业中层' }, // 原 level 10
    { level: 11, label: '企业人员', desc: '除以上条件外的企业人员' },
    { level: 12, label: '健康界工作人员' },
    { level: 0,  label: '普通用户' },
];

// 会议嘉宾身份：1参会嘉宾，2参会代表，3企业人员，4工作人员，0待定
export const ConferenceGuestRoles = [
    { value: 1, label: '参会嘉宾' },
    { value: 2, label: '参会代表' },
    { value: 3, label: '企业人员' },
    { value: 4, label: '工作人员' },
    { value: 0, label: '待定' },
];

// 邀请函项目类型 application_type
// 1医会议，2医评选
export const invitationApplicationTypes = [
    { type: 1, name: '医会议' },
    { type: 2, name: '医评选' },
];

// 邀请函用户称呼
// 用户称呼1=无,2=先生/女士,3=教授,4=嘉宾,5=老师,6=专家职务
export const invitationNameTitles = [
    { type: 1, title: '无' },
    { type: 2, title: '先生/女士' },
    { type: 3, title: '教授' },
    { type: 4, title: '嘉宾' },
    { type: 5, title: '老师' },
    { type: 6, title: '专家职务' },
];

// 劳务付费类型
export const laborFeePaymentMethods = [
    { value: 1, label: '公司付款' },
    // { value: 2, label: '第三方平台付款' },
    { value: 3, label: '无需支付' },
];

// 公司付款-费用承担主体
export const laborFeeCostBearers = [
    { value: 1, label: '华媒康讯' },
    { value: 2, label: '博鳌研究院' },
    { value: 3, label: '尚医文化' },
    { value: 4, label: '尚医咨询' },
    { value: 5, label: '健康界科技' },
    { value: 6, label: '上海分公司' },
    { value: 0, label: '其他' },
];

// 第三方平台付款-费用承担主体
export const laborFee3rdPartyPaymentMethods = [
    { value: 101, label: '福建万古科技发展有限公司' },
];

// 认证职称列表（医疗决策人 Medical decision-maker, mdm）
const authPositions = [
    { code: 'A01-ZHW-B02', name: '党委书记', mdm: 1 },
    { code: 'A01-ZHW-B08', name: '党委副书记', mdm: 1 },
    { code: 'A01-ZHW-B01', name: '院长', mdm: 1 },
    { code: 'A01-ZHW-B09', name: '副院长', mdm: 1 },
    { code: 'A01-ZHW-B10', name: '院长助理', mdm: 1 },
    { code: 'A01-ZHW-B11', name: '纪委书记', mdm: 1 },
    { code: 'A01-ZHW-B12', name: '纪委副书记', mdm: 1 },
    { code: 'A01-ZHW-B13', name: '总会计师', mdm: 1 },
    { code: 'A01-ZHW-B14', name: '工会主席', mdm: 1 },
    { code: 'A01-ZHW-B15', name: '其他院级领导', mdm: 1 },
    { code: 'A01-ZHW-B03', name: '主任', mdm: 1 },
    { code: 'A01-ZHW-B16', name: '副主任', mdm: 1 },
    { code: 'A01-ZHW-B04', name: '部长', mdm: 1 },
    { code: 'A01-ZHW-B17', name: '副部长', mdm: 1 },
    { code: 'A01-ZHW-B05', name: '科长', mdm: 1 },
    { code: 'A01-ZHW-B18', name: '副科长', mdm: 1 },
    { code: 'A01-ZHW-B06', name: '处长', mdm: 1 },
    { code: 'A01-ZHW-B19', name: '副处长', mdm: 1 },
    { code: 'A01-ZHW-B20', name: '护士长' },
    { code: 'A01-ZHW-B21', name: '副护士长' },
    { code: 'A01-ZHW-B22', name: '其他中层领导' },
    { code: 'A01-ZHW-B23', name: '科内组长/副组长' },
    { code: 'A01-ZHW-B07', name: '无领导职务' },
];

// 证件类型
export const cardTypes = [
    { code: 1, name: '身份证号码' },
    { code: 2, name: '港澳居民来往内地通行证' },
    { code: 3, name: '台湾居民来往大陆通行证' },
    { code: 4, name: '外国人永久居留身份证' },
    { code: 5, name: '护照' },
];

// 评分赛段任务类型 - 选手
export const playerStageTaskTypes = [
    { key: "PLAYER_UPLOAD_JOIN_FILE", label: "上传参赛文件" },
    { key: "PLAYER_FILL_TEAM_INFO", label: "填写参赛团队信息" },
    // { key: "PLAYER_VIEW_SCORE_LIST", label: "查看评分榜单" },
    { key: "PLAYER_VIEW_SCORE_REPORT", label: "查看评分报告" },
    { key: "PLAYER_GET_AWARD_CERTIFICATE", label: "领取完赛证书" },
    { key: "TASK_LINK", label: "跳转链接" },
    { key: "TASK_DOCUMENT", label: "文档" },
    { key: "TASK_TEXT", label: "文本" }
];

// 评分赛段任务类型 - 评委
export const judgeStageTaskTypes = [
    { key: "JUDGE_CASE_SCORE", label: "案例评分" },
    { key: "JUDGE_VIEW_COMMENT", label: "查看点评" },
    // { key: "JUDGE_VIEW_SCORE_LIST", label: "查看榜单" },
    { key: "JUDGE_GET_APPOINTMENT_LETTER", label: "领取评委聘书" },
    { key: "TASK_LINK", label: "跳转链接" },
    { key: "TASK_DOCUMENT", label: "文档" },
    { key: "TASK_TEXT", label: "文本" }
];

// 文件类型（带图标）
export const FileTypeWithIconList = [
    { ext: /^pptx?$/i, icon: <FileEarmarkPpt color="#F58A33"/> },
    { ext: /^docx?$/i, icon: <FileEarmarkWord color="#487EFF"/> },
    { ext: /^xlsx?$/i, icon: <FileEarmarkExcel color="#408558"/> },
    { ext: /^png|jpe?g$/i, icon: <FileEarmarkImage color="#569C39"/> },
    { ext: /^pdf$/i, icon: <FileEarmarkPdf color="#F58A33"/> },
    { ext: /^zip|rar$/i, icon: <FileEarmarkZip color="#C18A20"/> },
    { ext: /^mp3$/i, icon: <FileEarmarkMusic color="#33AF8C"/> },
    { ext: /^mp4|rmvb|rm$/i, icon: <FileEarmarkPlay color="#487EFF"/> },
];
