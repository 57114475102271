import React, { Fragment, useEffect, useState, useRef } from "react";
import { Radio, Layout, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined } from "@ant-design/icons";
import * as echarts from "echarts/core";
import { GridComponent, TooltipComponent, LegendComponent } from "echarts/components";
import { LineChart, PieChart } from "echarts/charts";
import { UniversalTransition, LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import "./index.less";

echarts.use([GridComponent, LineChart, UniversalTransition, TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

const ChartsView = ({ data, type }) => {
    const viewRef = useRef();
    const chartRef = useRef();

    const [curType, setCurType] = useState();

    const onBtnChange = (e) => {
        setCurType(e.target.value);
    };

    const formatOptions = (data, type) => {
        let xData = data.map((item) => {
            // if(item.answer.length > 3) return item.answer.slice(0,3)+'...'
            return item.content;
        });
        let valData = data.map((item) => item.num);
        let options = {
            // x轴
            xAxis: {
                type: "category",
                data: xData,
                axisLabel: {
                    rotate: 30,
                    interval: 0,
                    margin: 4,
                    formatter: (value) => {
                        if (value.length > 13) value = value.slice(0, 13) + "...";
                        var ret = "\n\n"; //拼接加 返回的类目项
                        var maxLength = 8; //每项显示文字个数
                        var valLength = value.length; //X轴类目项的文字个数
                        var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                        if (rowN > 1) {
                            //如果类目项的文字大于10,
                            for (var i = 0; i < rowN; i++) {
                                var temp = ""; //每次截取的字符串
                                var start = i * maxLength; //开始截取的位置
                                var end = start + maxLength; //结束截取的位置
                                temp = value.substring(start, end) + "\n";
                                ret += temp; //凭借最终的字符串
                            }
                            return ret;
                        } else {
                            return value;
                        }
                    },
                },
            },
            tooltip: {
                trigger: "axis",
                className: "tooltip_text_wrap",
            },
            // y轴
            yAxis: {
                type: "value",
            },
            series: [
                {
                    data: valData,
                    type: "line", //折线图
                },
            ],
        };
        switch (type) {
            case "1":
                // 饼图
                valData = data.map((item) => ({
                    value: item.num,
                    name: item.content,
                }));
                options = {
                    // 提示框组件
                    tooltip: {
                        trigger: "item",
                        className: "tooltip_text_wrap",
                    },
                    xAxis: null,
                    series: [
                        {
                            data: valData,
                            type: "pie", //折线图
                            radius: ["0%", "60%"],
                            label: {
                                normal: {
                                    show: true,
                                    formatter: "{b}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
                                },
                            },
                        },
                    ],
                };
                break;
            case "2":
                // 柱状体
                options = {
                    ...options,
                    series: [
                        {
                            data: valData,
                            // type: 'line', //折线图
                            type: "bar", // 柱状图
                            barWidth: "20", //---柱形宽度
                            barCategoryGap: "20%", //---柱形间距
                            label: {
                                show: true, //开启显示数值
                                position: "top", //数值在上方显示
                                textStyle: {
                                    //数值样式
                                    fontSize: 14, //字体大小
                                },
                            },
                        },
                    ],
                };
                break;
            case "3":
                // 折线图
                break;

            default:
                break;
        }
        return options;
    };

    const resizeHandler = () => {
        chartRef.current.resize();
    };

    useEffect(() => {
        setTimeout(() => {
            // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
            chartRef.current = echarts.init(viewRef.current);
            [1, 3, 8].includes(type) ? setCurType("1") : setCurType("2");
            window.addEventListener("resize", resizeHandler);
        }, 0);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chartRef.current.dispose();
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    useEffect(() => {
        if (!chartRef.current) return;
        let op = formatOptions(data, curType);
        chartRef.current.clear();
        chartRef.current.setOption(op);
    }, [curType]);

    return (
        <div className="chart_view_wrap">
            <Radio.Group value={curType} onChange={onBtnChange}>
                {[1, 3, 8].includes(type) ? (
                    <>
                        <Radio.Button value="1">饼图</Radio.Button>
                        <Radio.Button value="2">柱状图</Radio.Button>
                        <Radio.Button value="3">折线图</Radio.Button>
                    </>
                ) : (
                    <Radio.Button value="2">柱状图</Radio.Button>
                )}
            </Radio.Group>
            <div ref={viewRef} className="chart_view"></div>
        </div>
    );
};

export default ChartsView;
