// 国考指标
module.exports = [
    {
        "title": "三级公立医院",
        "children": [
            {
                "title": "医疗质量",
                "children": [
                    {
                        "title": "功能定位",
                        "children": [
                            { "title": "门诊人次数与出院人次数比" },
                            { "title": "下转患者人次数（门急诊、住院）" },
                            { "title": "日间手术占择期手术比例" },
                            { "title": "出院患者手术占比" },
                            { "title": "出院患者微创手术占比" },
                            { "title": "出院患者四级手术比例" },
                            { "title": "特需医疗服务占比" }
                        ]
                    },
                    {
                        "title": "质量管理",
                        "children": [
                            { "title": "手术患者并发症发生率" },
                            { "title": "I 类切口手术部位感染率" },
                            { "title": "单病种质量控制" },
                            { "title": "大型医用设备检查阳性率" },
                            { "title": "大型医用设备维修保养及质量控制管理" },
                            { "title": "通过国家室间质量评价的临床检验项目数" },
                            { "title": "低风险组病例死亡率" },
                            { "title": "优质护理服务病房覆盖" }
                        ]
                    },
                    {
                        "title": "合理用药",
                        "children": [
                            { "title": "点评处方占处方总数的比例" },
                            { "title": "抗菌药物使用强度（DDDs）" },
                            { "title": "门诊患者基本药物处方占比" },
                            { "title": "住院患者基本药物使用率" },
                            { "title": "基本药物采购品种数占比" },
                            { "title": "国家组织药品集中采购中标药品使用比" }
                        ]
                    },
                    {
                        "title": "服务流程",
                        "children": [
                            { "title": "门诊患者平均预约诊疗率" },
                            { "title": "门诊患者预约后平均等待时间" },
                            { "title": "电子病历应用功能水平分级" }
                        ]
                    }
                ]
            },
            {
                "title": "运营效率",
                "children": [
                    {
                        "title": "资源效率",
                        "children": [
                            { "title": "每名执业医师日均住院工作负担" },
                            { "title": "每百张病床药师人" }
                        ]
                    },
                    {
                        "title": "收支结构",
                        "children": [
                            { "title": "门诊收入占医疗收入比例" },
                            { "title": "门诊收入中来自医保基金的比例" },
                            { "title": "住院收入占医疗收入比例" },
                            { "title": "住院收入中来自医保基金的比例" },
                            { "title": "医疗服务收入（不含药品、耗材、检查检验收入）占医疗收入比例" },
                            { "title": "辅助用药收入占人员支出占业务支出比重" },
                            { "title": "万元收入能耗支出" },
                            { "title": "收支结余" },
                            { "title": "资产负债率" }
                        ]
                    },
                    {
                        "title": "费用控制",
                        "children": [
                            { "title": "医疗收入增幅" },
                            { "title": "门诊次均费用增幅" },
                            { "title": "门诊次均药品费用增幅" },
                            { "title": "住院次均费用增幅" },
                            { "title": "住院次均药品费用增幅" }
                        ]
                    },
                    {
                        "title": "经济管理",
                        "children": [
                            { "title": "全面预算管理" },
                            { "title": "规范设立总会计" }
                        ]
                    }
                ]
            },
            {
                "title": "持续发展",
                "children": [
                    {
                        "title": "人员结构",
                        "children": [
                            { "title": "卫生技术人员职称结构" },
                            { "title": "麻醉、儿科、重症、病理、中医医师占比" },
                            { "title": "医护比" }
                        ]
                    },
                    {
                        "title": "人才培养",
                        "children": [
                            { "title": "医院接受其他医院（尤其是对口支援医院、医联体内医院）进修并返回原医院独立工作人数占比" },
                            { "title": "医院住院医师首次参加医师资格考试通过率" },
                            { "title": "医院承担培养医学人才的工作成效" },
                            { "title": "每百名卫生技术人员科研项目经费" }
                        ]
                    },
                    {
                        "title": "学科建设",
                        "children": [
                            { "title": "每百名卫生技术人员科研项目经费" },
                            { "title": "每百名卫生技术人员科研成果转化金" }
                        ]
                    },
                    {
                        "title": "信用建设",
                        "children": [
                            { "title": "公共信用综合评价等级" }
                        ]
                    }
                ]
            },
            {
                "title": "满意度",
                "children": [
                    {
                        "title": "患者满意度",
                        "children": [
                            { "title": "门诊患者满意度" },
                            { "title": "住院患者满意度" }
                        ]
                    },
                    {
                        "title": "医务人员满意度",
                        "children": [
                            { "title": "医务人员满意度" }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "title": "二级公立医院",
        "children": [
            {
                "title": "医疗质量",
                "children": [
                    {
                        "title": "功能定位",
                        "children": [
                            { "title": "出院患者手术占比" },
                            { "title": "出院患者微创手术占比" },
                            { "title": "出院患者三级手术占比" }
                        ]
                    },
                    {
                        "title": "质量管理",
                        "children": [
                            { "title": "手术患者并发症发生率" },
                            { "title": "低风险组病例死亡率" }
                        ]
                    },
                    {
                        "title": "合理用药",
                        "children": [
                            { "title": "抗菌药物使用强度（DDDs）" },
                            { "title": "基本药物采购金额占比" },
                            { "title": "国家组织药品集中采购中标药品金额占比" },
                            { "title": "重点监控药品收入占比" },
                            { "title": "重点监控高值医用耗材收入占比" }
                        ]
                    },
                    {
                        "title": "服务流程",
                        "children": [
                            { "title": "电子病历应用功能水平分级" },
                            { "title": "省级室间质量评价临床检验项目参加率与合格率" },
                            { "title": "平均住院日" }
                        ]
                    }
                ]
            },
            {
                "title": "运营效率",
                "children": [
                    {
                        "title": "收支结构",
                        "children": [
                            { "title": "医疗盈余率" },
                            { "title": "资产负债率" },
                            { "title": "人员经费占比" },
                            { "title": "万元收入能耗占比" },
                            { "title": "医疗收入中来自医保基金的比例（包括门诊、住院收入中来自医保基金的比例）" },
                            { "title": "医疗服务收入（不含药品、耗材、检查检验收入）" }
                        ]
                    },
                    {
                        "title": "费用控制",
                        "children": [
                            { "title": "医疗收入增幅" },
                            { "title": "次均费用增幅" },
                            { "title": "次均药品费用增幅" }
                        ]
                    }
                ]
            },
            {
                "title": "持续发展",
                "children": [
                    {
                        "title": "人员结构",
                        "children": [
                            { "title": "医护比" },
                            { "title": "麻醉、儿科、重症、病理、中医医师占比" }
                        ]
                    },
                    {
                        "title": "学科建设",
                        "children": [
                            { "title": "人才培养经费投入占比" },
                            { "title": "专科能力" }
                        ]
                    }
                ]
            },
            {
                "title": "满意度",
                "children": [
                    {
                        "title": "患者满意度",
                        "children": [
                            { "title": "患者满意度" }
                        ]
                    },
                    {
                        "title": "医务人员满意度",
                        "children": [
                            { "title": "医务人员满意度" }
                        ]
                    }
                ]
            }
        ]
    }
];