import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { Layout, Switch, Button, Input, Table, Modal, Space, Form, Radio, Checkbox, message, Tooltip } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "@utils/utils";
import { getSuppliesPageApi, updateSuppliesPageApi, getConferenceIdentitySuppliesApi, updateConferenceIdentitySuppliesApi, deleteConferenceIdentitySuppliesApi } from '@services/Conference';
import './index.less';

const { Content } = Layout;

const pageFormItemLayout = {
    labelCol: {
        sm: { span: 5 },
        lg: { span: 4 },
        xl: { span: 5 },
    },
    wrapperCol: {
        sm: { span: 17 },
        lg: { span: 19 },
        xl: { span: 17 },
    }
};

//报名设置
const SignUpSetting = () => {
    const location = useLocation();
    const params = useParams();
    const cid = ~~params?.id;
    const [form] = Form.useForm();
    const [supplies, setSupplies] = useState([]);
    const [addDentity, setAddDentity] = useState(false);
    const [data, setData] = useState([]);
    const [type, setType] = useState();
    const [init, setInit] = useState([
        {
            status: true,
            id: 1,
            count: 10,
            current: true,
            error: false,
        }, {
            status: false,
            id: 2,
            count: 10,
            current: true,
            error: false,
        }, {
            status: false,
            id: 3,
            count: 10,
            current: true,
            error: false,
        }, {
            status: false,
            id: 4,
            count: 10,
            current: true,
            error: false,
        }
    ]);


    const columns = [
        {
            title: '身份名称',
            dataIndex: 'name',
            key: 'name',
            width: 100

        },
        // {
        //     title: '颜色',
        //     dataIndex: 'color',
        //     key: 'color',
        //     width: 80,
        //     render: (_, row) => {
        //         return row.color ? <div style={{ width: 30, height: 30, background: row.color }}></div> : ''

        //     }
        // },
        {
            title: '身份权益',
            dataIndex: 'registrationSupplies',
            key: 'registrationSupplies',
            width: 200,
            render: (_, row) => {
                return row.registrationSupplies ? JSON?.parse(row.registrationSupplies).map(i => {
                    return <span style={{ paddingRight: 10 }}>{`${i.name}*${i.count}`}</span>
                }) : ''

            }
        },
        {
            title: '说明',
            dataIndex: 'description',
            key: 'description',
            width: 200
        },
        {
            title: '操作',
            dataIndex: 'operations',
            key: 'operations',
            width: 100,
            render: (_, row) => {
                return (

                    <span className="operation-btns" style={{ display: 'flex' }}>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleAdd('edit', row)}
                        >
                            编辑
                        </Button>
                        <Button
                            size="small"
                            type="link"
                            disabled={row?.name === 'VIP嘉宾' || row?.name === '现场报名'}
                            onClick={() => handleDelete(row)}
                        >
                            删除
                        </Button>
                    </span>
                )
            },
        }
    ];

    const getKey = (name) => {
        if (name === '参会嘉宾胸牌') {
            return 'id_badge'
        }
        if (name === '自助餐') {
            return 'meal_ticket'
        }
        if (name === '资料袋') {
            return 'document_bag'
        }
        if (name === '伴手礼') {
            return 'souvenir'
        }
        // if (name === '商务餐券') {
        //     return 'biz_meal_ticket'
        // }
        if (name === '健康界会员') {
            return 'vip'
        }
        if (name === '停车券') {
            return 'car_ticket'
        }
        if (name === '商务简餐') {
            return 'boxed_meal'
        }
        if (name === '参赛选手胸牌（紫绳）') {
            return 'race_badge'
        }
        if (name === '参赛选手胸牌（黄绳）') {
            return 'race_badge_yellow'
        }
        // if (name === 'VIP思享汇冷餐会') {
        //     return 'vip_thought_reunion_buffet'
        // }
        if (name === '全民健康跑T恤') {
            return 'health_run'
        }
        if (name === '参访海南博鳌乐城医疗旅游先行区') {
            return 'hainan_bo_ao_medical_tourism'
        }
        if (name === '参展商胸牌') {
            return 'exhibitor_card'
        }
        if (name === 'VIP胸牌') {
            return 'vip_badge'
        }
        if (name === '工作人员胸牌') {
            return 'worker_badge'
        }
    };

    const getSuppliesPage = (type, value) => {
        getSuppliesPageApi({ conferenceId: cid, status: 1 }).then(res => {
            if (res && res.status_code === 200) {
                if (type === 'add') {
                    const arr = res?.data.map(i => {
                        return {
                            id: i.id,
                            name: i.name,
                            check: true,
                            count: 1,
                            code: getKey(i.name)
                        }
                    })
                    form.setFieldValue('registrationSupplies', arr);
                    setAddDentity(true)
                } else if (type === 'edit') {
                    const arr = res?.data.map(i => {
                        return {
                            id: i.id,
                            name: i.name,
                            check: false,
                            count: 1,
                            code: getKey(i.name)
                        }
                    })

                    const { name, registrationSupplies, description, id } = value;
                    const arr1 = registrationSupplies ? JSON.parse(registrationSupplies).map(i => {
                        return {
                            id: i.id,
                            name: i.name,
                            check: true,
                            count: i.count,
                            code: getKey(i.name)
                        }
                    }) : [];

                    const arr2 = [...arr];
                    arr1.forEach(i => {
                        [...arr].forEach((k, ki) => {
                            if (k.name == i.name) {
                                arr2[ki] = i
                            }
                        })
                    })
                    form.setFieldsValue({
                        name,
                        description,
                        registrationSupplies: arr2,
                        id
                    })
                    setAddDentity(true)
                }
            }
        })
    }

    const getConferenceIdentitySupplies = () => {
        getConferenceIdentitySuppliesApi({ conferenceId: cid }).then(res => {
            if (res && res.status_code === 200) {
                setData(res?.data)
            }
        })
    }

    const handleChangeSwitch = (v, index, item) => {
        const arr = [...init];
        updateSuppliesPageApi({
            conferenceId: cid,
            id: item.id,
            status: v ? 1 : 0,
            type: item.code
        }).then(res => {
            if (res && res.status_code === 200) {
                const { data } = res || {};
                arr[index].status = v;
                arr[index].current = true;
                arr[index].count = data[index].count
                arr[index].error = false
                setInit([...arr])
            }
        })
    }

    const handleChangeInput = (e, index) => {
        const arr = [...init];
        if (e.target.value === '0' || !(/^\+?[0-9]\d*$/).test(e.target.value)) {
            arr[index].error = true
        } else {
            arr[index].error = false
        }
        arr[index].count = e.target.value;
        setInit([...arr])
    }

    const handleClikeEdit = (index) => {
        const arr = [...init];
        arr[index].current = false;
        setInit([...arr])
    }

    const handleClikeSubmit = (index, item) => {
        const arr = [...init];
        updateSuppliesPageApi({
            conferenceId: cid,
            id: item.id,
            status: init[index].status ? 1 : 0,
            type: item.code,
            count: init[index].count
        }).then(res => {
            if (res && res.status_code === 200) {
                arr[index].current = true;
                setInit([...arr])
            }
        })
    }

    const handleAdd = (type, value) => {
        setType(type);
        getSuppliesPage(type, value);

    }

    const handleDelete = (row) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否删除该身份`,
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: () => {
                deleteConferenceIdentitySuppliesApi({ id: row.id }).then(res => {
                    if (res && res.status_code === 200) {
                        message.success('删除成功！');
                        getConferenceIdentitySupplies();
                    }
                })
            }
        });
    }

    const handleCancelAddDentity = () => {
        form.resetFields();
        setAddDentity(false)
    }

    const handleAddDentity = () => {
        form.validateFields().then((value) => {
            const arr1 = value.registrationSupplies ? value.registrationSupplies.filter(i => i.check) : [];
            const query = {
                description: value.description,
                name: value.name,
                registrationSupplies: JSON.stringify(arr1),
                conferenceId: cid
            }
            if (type === 'edit') query.id = ~~form.getFieldValue('id')
            updateConferenceIdentitySuppliesApi(query).then(res => {
                if (res && res.status_code === 200) {
                    message.success(`${type === 'edit' ? '编辑成功！' : '新增成功！'}`);
                    form.resetFields();
                    setAddDentity(false);
                    getConferenceIdentitySupplies();
                }
            })
        })
    }


    useEffect(() => {
        getSuppliesPageApi({ conferenceId: cid }).then(res => {
            if (res && res.status_code === 200) {
                const { data } = res || {};
                setSupplies(data);
                const arr1 = data.map(i => {
                    return {
                        id: i.id,
                        status: i.status ? true : false,
                        count: i.count,
                        current: true,
                        error: false,
                        code: getKey(i.name)
                    }
                })
                setInit([...arr1])
            }
        })

        getConferenceIdentitySupplies();
    }, [])

    return (
        <>
            <div className="main-content-body" style={{ paddingBottom: 100 }}>
                <div className="form-subtitle sign-up-setting-title">权益物料配置</div>
                {
                    !!(supplies && supplies.length) && supplies.map((item, index) => {
                        return <div className='sign-up-setting-equity' key={item.id}>
                            <div ><span className='sign-up-setting-equity-label'>{item.name}</span><Switch checkedChildren="开" unCheckedChildren="关" checked={init[index]?.status} onChange={(e) => handleChangeSwitch(e, index, item)} /></div>
                            {init[index]?.status && <div className='sign-up-setting-equity-input-cover'>
                                <span className='sign-up-setting-equity-label-num'>{item.name}数量</span>
                                <Input className={init[index].error ? 'sign-up-setting-equity-input' : null} disabled={init[index].current} style={{ width: 150 }} precision={0} value={init[index]?.count} onChange={(e) => handleChangeInput(e, index)}></Input>
                                {init[index].current
                                    ? <Button style={{ marginLeft: 20 }} type="default" onClick={() => handleClikeEdit(index)}>编辑</Button>
                                    : <Button style={{ marginLeft: 20 }} disabled={init[index].error} type="primary" onClick={() => handleClikeSubmit(index, item)}>保存</Button>
                                }

                                {init[index]?.error && <div className='sign-up-setting-equity-input-error'>请输入正整数</div>}
                            </div>}
                        </div>
                    })
                }

                <div className="form-subtitle">会场身份及权益配置</div>

                <Button icon={<PlusOutlined />} style={{ marginBottom: 20 }} onClick={() => handleAdd('add')}>添加会场身份</Button>
                <Table rowKey={(r) => r.id} columns={columns} dataSource={data} pagination={false} />
            </div>


            <Modal
                title={type === 'edit' ? '编辑身份' : '新建身份'}
                open={addDentity}
                destroyOnClose={true}
                centered
                width={700}
                maskClosable={false}
                onCancel={handleCancelAddDentity}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleCancelAddDentity}>取消</Button>
                            <Button type="primary" htmlType="submit" onClick={handleAddDentity}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    form={form}
                    {...pageFormItemLayout}
                >
                    <Form.Item
                        name="name"
                        label="身份名称"
                        className='sign-wp-setting-form'
                        rules={[{ required: true, message: "身份名称不能为空" }]}
                        tooltip={form.getFieldValue('name') === 'VIP嘉宾' || form.getFieldValue('name') === '现场报名' ? '此身份名称不支持修改' : ''}
                    >
                        <Input maxLength={10} showCount placeholder='请输入身份名称' disabled={form.getFieldValue('name') === 'VIP嘉宾' || form.getFieldValue('name') === '现场报名'}></Input>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({getFieldValue}) => {
                            return !!form.getFieldValue('registrationSupplies') && !!form.getFieldValue('registrationSupplies').length &&
                                <Form.Item
                                    name="registrationSupplies"
                                    label="身份权益"
                                >

                                    <Form.List
                                        name="registrationSupplies"
                                    >
                                        {(fields, { add, remove }) => (
                                            <Fragment>
                                                {fields.map(({ key, name, ...restFields }, index) => (
                                                    <div key={index} style={{ display: 'flex', marginBottom: 10 }} >
                                                        <Form.Item
                                                            {...restFields}
                                                            name={[name, 'check']}
                                                            style={{
                                                                marginBottom: 0
                                                            }}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox style={{ width: 160 }}>{form.getFieldValue('registrationSupplies')[index].name}</Checkbox>
                                                        </Form.Item>
                                                        {form.getFieldValue('registrationSupplies')[index].check && <Form.Item
                                                            {...restFields}
                                                            className='sign-up-form-item-count'
                                                            name={[name, 'count']}
                                                            label={`${form.getFieldValue('registrationSupplies')[index].name}数量`}
                                                            rules={[{
                                                                required: true,
                                                                validator: async (rule, value) => {
                                                                    if (form.getFieldValue('registrationSupplies')[index].check && (!(/^\+?[0-9]\d*$/).test(value) || value === '0')) {
                                                                        throw '请输入正整数'
                                                                    }
                                                                }
                                                            }]}
                                                            style={{
                                                                marginBottom: 0,
                                                                marginLeft: 30
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" style={{ width: 100 }} />
                                                        </Form.Item>}
                                                    </div>
                                                ))}
                                            </Fragment>
                                        )}
                                    </Form.List>
                                </Form.Item>
                        }}
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="说明"
                    >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={100} showCount></Input.TextArea>
                    </Form.Item>


                </Form>

            </Modal>
        </>
    );
}

export default SignUpSetting;
