import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Button,
    Col,
    DatePicker, Divider,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table, Tooltip,
    Typography,
} from 'antd';
import { MessageOutlined, SearchOutlined } from '@ant-design/icons';
import { filterFormItemLayout } from '@utils/property';
import { getSelectionListApi, updateCasesStatusApi, getProjectsAllApi, getProjestsTopicsListApi } from '@services/Selection'
import './CasesList.less'
import { selectionActions } from '../store/selectionSlice';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;

const { setCaseFilter: setFilter, setCaseCursor: setCursor } = selectionActions;

export default function CasesList() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.selection.case.filter);
    const cursor = useSelector(state => state.selection.case.cursor);

    const [form] = Form.useForm();
    const [topics, setTopics] = useState([]);

    const projectId = Form.useWatch('projectId', form);

    const filterFormGridLayout = {
        sm: { span: 24 },
        md: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 8 },
    };

    // 获取列表数据
    const [data, setData] = useState([]);
    const [projectsListData, setProjectsListData] = useState([]);
    const [total, setTotal] = useState(0);

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize,
        }));
    };

    const onFinish = values => {
        dispatch(setFilter({
            ...filter,
            ...values,
            page: 1
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1
        }));
    };


    const filterOption = (input, option) => {
        const { name } = option.props.data;
        return (
            name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    };

    const goCases = id => {
        if (id !== cursor) {
            dispatch(setCursor(id));
        }

        navigate(`/selection/cases/${id}`);
    };

    // 编辑
    const edit = row => {
        if (row.id !== cursor) {
            dispatch(setCursor(row.id));
        }

        navigate(`/selection/cases/edit/${row.id}`, {
            state: {
                slug: row.slug,
                memcard: row.memcard,
                status: row.status
            }
        });
    };

    useEffect(() => {
        getData();
    }, [filter]);

    useEffect(() => {
        getProjectsAllApi({}).then((res) => {
            if (res && res.status_code === 200) {
                setProjectsListData(res.data);
            }
        });
    }, []);

    const getData = () => {
        let sendValue = { ...filter }
        if (filter?.createdAt) {
            sendValue.createdAtBegin = moment(filter.createdAt[0]).format('YYYY-MM-DD HH:mm:ss');
            if (typeof (sendValue.createdAt[0]) === 'string') {
                sendValue.createdAt[0] = moment(filter.createdAt[0].toString());
            }
        }

        if (filter?.createdAt) {
            sendValue.createdAtEnd = moment(filter.createdAt[1]).format('YYYY-MM-DD HH:mm:ss');
            if (typeof (sendValue.createdAt[1]) === 'string') {
                sendValue.createdAt[1] = moment(filter.createdAt[1].toString());
            }
        }

        if (filter?.reviewedAt) {
            sendValue.reviewedAtBegin = moment(filter.reviewedAt[0]).format('YYYY-MM-DD HH:mm:ss');
            if (typeof (sendValue.reviewedAt[0]) === 'string') {
                sendValue.reviewedAt[0] = moment(filter.reviewedAt[0].toString());
            }
        }

        if (filter?.reviewedAt) {
            sendValue.reviewedAtEnd = moment(filter.reviewedAt[1]).format('YYYY-MM-DD HH:mm:ss');
            if (typeof (sendValue.reviewedAt[1]) === 'string') {
                sendValue.reviewedAt[1] = moment(filter.reviewedAt[1].toString());
            }
        }

        if (filter?.reviewStatus && filter.reviewStatus === -1) {
            delete sendValue.reviewStatus;
        }

        if (filter?.status && filter.status === -1) {
            delete sendValue.status;
        }

        form.setFieldsValue(sendValue);

        getSelectionListApi({ ...sendValue, size: sendValue.pageSize })
            .then((res) => {
                if (res && res.status_code === 200) {
                    setData(res.data.records);
                    setTotal(res.data.total);
                }
            });
    }

    const onUpdateCases = (status, item) => {
        if (item.id !== cursor) {
            dispatch(setCursor(item.id));
        }
        if (status === 2) {
            updateCasesStatusApi({ id: item.id, status: status })
                .then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getData();
                    }
                });
        } else {
            Modal.confirm({
                title: '提示',
                content: '案例下架后用户端将无法查看，确定下架该案例吗？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    updateCasesStatusApi({ id: item.id, status: status })
                        .then((res) => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                            }
                        });
                }
            });
        }
    }

    const columns = [
        {
            title: '案例名称',
            dataIndex: 'fieldValue',
            width: 300,
            render: (fieldValue, row) => (
                <>
                    <span>{JSON.parse(fieldValue).title}</span>
                    <br />
                    <Text type="secondary" style={{ verticalAlign: 'middle' }}>#{row.id}</Text>
                </>
            )
        },
        {
            title: '项目主题',
            dataIndex: 'projectName',
            width: 300,
            render: (_, row) => (
                <>
                    {row.projectName}
                    <br />
                    <Text type="secondary">主题：{row.topicName}</Text>
                </>
            )
        },
        { title: '案例提交机构', dataIndex: 'hospitalName', width: 300 },
        {
            title: '案例提交用户',
            dataIndex: 'nickName',
            width: 220,
            render: (_, row) => (
                <>
                    <Tooltip title={<>用户memcard：{row.memcard}<br />手机：{row.mobile}</>}>
                        <span>{row.nickName}</span>
                    </Tooltip>
                    <br />
                    <Text type="secondary" style={{ verticalAlign: 'middle' }}>
                        <small>提交时间：{row.createdAt}</small>
                    </Text>
                    {row.createdAt !== row.updatedAt && row.updatedAt && <>
                        <br />
                        <Text type="secondary" style={{ verticalAlign: 'middle' }}>
                            <small>最后提交：{row.updatedAt}</small>
                        </Text>
                    </>}
                </>
            )
        },
        {
            title: '审核状态',
            dataIndex: 'reviewedStatus',
            width: 100,
            render: (reviewedStatus, row) => (
                <>
                    {reviewedStatus === 0 && <span>待审核</span>}
                    {reviewedStatus === 1 && <Text type="success">已通过</Text>}
                    {reviewedStatus === 2 && (
                        <Text type="warning">
                            已驳回
                            <Tooltip placement="left" color="#ffffff" title={(<span className="ToolRejectReason">{row.rejectReason}</span>)}>
                                <MessageOutlined />
                            </Tooltip>
                        </Text>
                    )}
                </>
            )
        },
        {
            title: '审核人',
            dataIndex: 'reviewedBy',
            width: 160,
            render: (_, row) => (
                <>
                    {row.reviewedStatus !== 0 &&
                        <>
                            <span>{row.reviewedName}</span>
                            <br />
                            <Text type="secondary" style={{ verticalAlign: 'middle' }}><small>{row.reviewedAt}</small></Text>
                        </>
                    }
                </>
            )
        },
        {
            title: '发布状态',
            dataIndex: 'status',
            width: 100,
            render: (status, row) => (
                <>
                    {status === 0 && <span>草稿</span>}
                    {status === 1 && <span>未发布</span>}
                    {status === 2 && <Text type="success">已上架</Text>}
                    {status === 3 && <Text type="warning">已下架</Text>}
                </>
            )
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 250,
            fixed: 'right',
            render: (_, row) => (
                <Space split={<Divider type="vertical" />}>
                    <Button size="small" type="link" onClick={() => edit(row)}>编辑</Button>
                    <Button size="small" type="link" onClick={() => goCases(row.id)}>案例详情</Button>
                    {row.status === 2 && <Button size="small" type="link" onClick={() => onUpdateCases(3, row)}>下架</Button>}
                    {row.status === 3 && <Button size="small" type="link" onClick={() => onUpdateCases(2, row)}>上架</Button>}
                </Space>
            ),
        }
    ];

    useEffect(() => {
        if (projectId) {
            form.setFieldValue('topicIds', undefined);
            getProjestsTopicsListApi({ projectId: projectId }).then((topics) => {
                if (topics.status_code === 200) {
                    setTopics(topics.data);
                }
            });
        }
    }, [projectId])

    return <>
        <div className="page-title">
            <span className="current-title">案例管理</span>
        </div>

        <Content className="main-content-box">
            <div className="filter-form-box">
                <Form  {...filterFormItemLayout} onFinish={onFinish} form={form}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="案例名称或ID" name="title">
                                <Input allowClear placeholder="案例名称关键字或ID" autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="评选项目" name="projectId">
                                <Select
                                    placeholder='请选择评选项目'
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                >
                                    {projectsListData && projectsListData.map((item) => (
                                        <Option key={item.id} value={item.id} data={item}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            projectId &&
                            <Col {...filterFormGridLayout}>
                                    <Form.Item label="申报主题" name="topicIds">
                                    <Select
                                        placeholder='请选择申报主题'
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                    >
                                        {topics && topics.map((item) => (
                                            <Option key={item.id} value={item.id} data={item}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="案例提交机构" name="hospitalName">
                                <Input allowClear placeholder="投稿机构名称" autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="用户memCard" name="memCard">
                                <Input allowClear placeholder="投稿用户memCard" autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="用户昵称" name="nickName">
                                <Input allowClear placeholder="投稿用户昵称" autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="用户手机" name="mobile">
                                <Input allowClear placeholder="投稿用户手机" autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="案例提交时间" name="createdAt">
                                <RangePicker className="ProjectRangPicker" allowClear showTime />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="审核时间" name="reviewedAt">
                                <RangePicker className="ProjectRangPicker" allowClear showTime />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="审核状态" name="reviewStatus">
                                <Select
                                    placeholder='请选择审核状态'
                                    options={[
                                        { value: -1, label: '全部' },
                                        { value: 0, label: '待审核' },
                                        { value: 1, label: '审核通过' },
                                        { value: 2, label: '审核驳回' }
                                    ]}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="发布状态" name="status">
                                <Select
                                    placeholder='请选择发布状态'
                                    options={[
                                        { value: -1, label: '全部' },
                                        { value: 0, label: '草稿' },
                                        { value: 1, label: '未发布' },
                                        { value: 2, label: '已上架' },
                                        { value: 3, label: '已下架' },
                                    ]}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout} className="filter-form-buttons">
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>
            <div className="main-content-body">
                <div className="table-caption">
                    <div className="table-title">案例列表</div>
                </div>
                <Table
                    size="small"
                    className="enterprises-table"
                    rowKey={item => item.id}
                    columns={columns}
                    dataSource={data}
                    rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                    scroll={{ x: 1300 }}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                />
            </div>
        </Content>
    </>
}
