import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Space,
    Table,
    Tooltip,
    Typography
} from 'antd';
import { LeftOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { getInvitationListApi, deleteInvitationApi } from '@services/Invitation/Invitation';
import { getInvitationGroupOneApi } from '@services/Invitation/InvitationGroup';
import copy from 'copy-to-clipboard';
import config from '@config';
import { convertDateRangeToLocalText } from '@utils/utils';
import { invitationActions } from './store/invitationSlice';

const { Content } = Layout;
const { Text } = Typography;

const { setInvitationFilter: setFilter, setInvitationCursor: setCursor } = invitationActions;

export default function InvitationList() {
    const navigate = useNavigate();

    const params = useParams();
    const { gid } = params;

    const dispatch = useDispatch();
    const filter = useSelector(state => state.invitation.invitation.filter);
    const cursor = useSelector(state => state.invitation.invitation.cursor);
    console.log(filter, cursor);

    const [form] = Form.useForm();

    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [invitationGroupOne, setInvitationGroupOne] = useState('');

    const onChangePage = (page, pageSize) => {
        setData([]);
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };
    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const getInvitations = async () => {
        const params = {
            ...filter,
            groupId: gid,
            limit: filter.pageSize,
        };

        getInvitationListApi(params)
            .then((res) => {
                if (res.status_code === 200) {
                    setData(res.data.list);
                    setTotal(res.data.totalCount);
                }
            });
    };

    useEffect(() => {
        form.setFieldsValue(filter);

        getInvitations();
    }, [filter]);

    useEffect(() => {
        getInvitationGroupOneApi({ id: gid })
            .then((res) => {
            if (res.status_code === 200) {
                setInvitationGroupOne(res.data.name);
            }
        })
    }, []);

    const onFinish = values => {
        dispatch(setFilter({
            ...filter,
            ...values,
            page: 1,
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    // 预览
    const preview = (item) => {
        if (item.invitationGroup.applicationType === 1) {
            window.open(config.INVITATION_PREFIX + "/c/" + item.code, '_blank')
        } else if (item.invitationGroup.applicationType === 2) {
            window.open(config.INVITATION_PREFIX + "/s/" + item.code, '_blank')
        }

    }

    const copyInvitation = (item) => {
        if (item.invitationGroup.applicationType === 1) {
            copy(config.INVITATION_PREFIX + "/c/" + item.code)
            message.success('复制成功')
        } else if (item.invitationGroup.applicationType === 2) {
            copy(config.INVITATION_PREFIX + "/s/" + item.code)
            message.success('复制成功')
        }
    }

    // 删除邀请函
    const onDeleteInvitation = (id) => {
        Modal.confirm({
            title: '提示',
            content: '确定删除当前邀请函？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteInvitationApi({ id }).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                    }

                    getInvitations();
                });
            }
        });

    }
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            width: 60,
            render: (_, record) => <Tooltip title={`邀请函编码：${record.code}`}>{record.id}</Tooltip>
        },
        {
            title: '邀请函名称',
            dataIndex: 'name',
            render: (_, record) => <>
                <span>{record.name}</span>
                {!!record.remarks && (
                    <Text type="secondary">
                        <br/>
                        <span style={{marginLeft: 4}}>{record.remarks}</span>
                    </Text>
                )}
            </>
        },
        {
            title: '类型', dataIndex: 'type', width: 80,
            render: text => {
                return text === 1 ? '统一邀请码' : '一人一码';
            }
        },
        {
            title: '已反馈/总邀请',
            dataIndex: 'count',
            width: 120,
            render: (text, row, index) => {
                return <span><Text type="success">{row.coupleCount}</Text> / {row.totalCount}</span>;
            }
        },
        {
            title: '有效期', dataIndex: 'validDate',
            width: 160,
            render: (text, row, index) => {
                return <small>{convertDateRangeToLocalText(row.startTime, row.endTime)}</small>;
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 240,
            render: (_, record) => {
                let items = record?.type === '1'
                    ? [
                        { label: '预览邀请函', key: 'view', onClick: () => preview(record), },
                        { label: '复制邀请函链接', key: 'link', onClick: () => copyInvitation(record) },
                        {
                            label: '删除邀请函',
                            key: 'delete',
                            onClick: () => onDeleteInvitation(record.id),
                            danger: true
                        },
                    ]
                    : [
                        {
                            label: '删除邀请函',
                            key: 'delete',
                            onClick: () => onDeleteInvitation(record.id),
                            danger: true
                        },
                    ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => { dispatch(setCursor(record.id)); navigate(`/invitation/g/${gid}/invitations/${record.id}/users`); }}
                        >
                            管理邀请用户
                        </Button>
                        <Divider type="vertical"/>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => { dispatch(setCursor(record.id)); navigate(`/invitation/g/${gid}/invitations/${record.id}`); }}
                        >
                            编辑邀请函
                        </Button>
                        <Divider type="vertical"/>
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button size="small" type="link" className="more-operation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path
                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                </svg>
                            </Button>
                        </Dropdown>
                    </span>
                )
            }
        }
    ];

    const handleRefresh = () => getInvitations();

    return <Fragment>

        <div className="page-title">
            <Link to="/invitation/groups" className="back"><LeftOutlined/></Link>
            <span className="current-title">邀请函管理</span>
            <span className="current-subtitle">{invitationGroupOne}</span>
        </div>

        <Content className="main-content-box">
            <div className="filter-form-box">
                <Form onFinish={onFinish} form={form}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item label="名称" name="name">
                                <Input allowClear placeholder="邀请函名称" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Space>
                                <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                <Button type="default" onClick={onReset}>重置</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>

            <div className="main-content-body">

                <div className="table-caption">
                    <div className="table-title">邀请函列表</div>
                    <div className="table-toolbar">
                        <Space>
                            <Button type="primary"
                                    onClick={() => navigate(`/invitation/g/${gid}/invitations/new`)}>新增邀请函</Button>
                            <Button type="default" icon={<ReloadOutlined/>} onClick={handleRefresh}>刷新</Button>
                        </Space>
                    </div>
                </div>

                <Table
                    className="enterprise-table"
                    rowKey={item => item.id}
                    columns={columns}
                    dataSource={data}
                    rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                    size="middle"
                />

            </div>
        </Content>

    </Fragment>
}
