import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Layout, Table, Button, Input, Modal, Form, Row, Col, Space, Divider, message } from "antd";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import './index.less';
import { membersListApi, membersPassApi, getSsoMemberByMemCardsApi, auditDismissedApi, auditThroughApi, auditInfoBymemCardApi, auditSsoMemberAuthApi } from '@services/MedProject';
import ViewAuthInfoDrawer from '../ViewAuthInfoDrawer';
import AuditDismissedModal from '../AuditDismissedModal';

const { TextArea } = Input;

// 成员管理 - 已拒绝成员列表
const RefuseList = (
    memberStatus
) => {
    const location = useLocation();
    const params = useParams();

    const projectId = (params?.id && Number(params?.id)) || 0; // 项目 ID
    const status = Number(memberStatus.status);
    const [isShowFooter, setIsShowFooter] = useState(false); // 查看详情弹框是否展示操作按钮
    const [authStatus, setAuthStatus] = useState(undefined); // 审核驳回、通过
    const identifyOptions = [
        {
            code: 'hospital',
            name: '医院',
            tips: '本项目要求医院成员必须为认证为“医疗机构”用户'
        },
        {
            code: 'person',
            name: '个人',
            tips: '本项目要求个人成员必须为“个人认证”用户'
        },
        {
            code: 'enterprise',
            name: '企业',
            tips: '本项目要求企业成员必须为认证为“企业”的用户'
        },
        {
            code: 'medium',
            name: '媒体',
            tips: '本项目要求媒体成员必须为认证为“媒体”的用户'
        },
        {
            code: 'organization',
            name: '机构',
            tips: '本项目要求机构成员必须为认证为“机构”的用户'
        },
    ];

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    const [isModalRefuseOpen, setIsModalRefuseOpen] = useState(false);

    const [filterForm] = Form.useForm();

    // 驳回理由
    const [refuseReason, setRefuseReason] = useState('');

    const [visibleAuthInfoDrawer, setVisibleAuthInfoDrawer] = useState(false);
    const [visibleAuthDismissedModal, setVisibleAuthDismissedModal] = useState(false);
    const [authDismissedReson, setAuthDismissedReson] = useState(undefined);
    const curMemCard = useRef(undefined);
    const [ssoMember, setSsoMember] = useState();
    const [authInfo, setAuthInfo] = useState({});

    const columns = [
        {
            title: "姓名",
            dataIndex: "memRealName",
            width: 150,
            fixed: 'left'
        },
        {
            title: "手机号",
            dataIndex: "memMobile",
            width: 100,
            fixed: 'left',
            render: (_, row) => (
                <>
                    {row.memMobile?.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")}
                </>
            ),
        },
        {
            title: "加入身份",
            width: 150,
            dataIndex: "identityType",
            render: (_, row) => (
                <>
                    {identifyOptions.find(item => item.code === row.identityType)?.name}
                </>
            ),
        },
        {
            title: "单位名称",
            width: 150,
            dataIndex: "memUnit"
        },
        {
            title: "部门",
            width: 150,
            dataIndex: "memOffice",
        },
        {
            title: "职务",
            width: 150,
            dataIndex: "memPost",
        },
        {
            title: "加入时间",
            width: 200,
            dataIndex: "createdAt",
        },
        {
            title: "来源",
            width: 150,
            dataIndex: "source",
            render: (_, row) => (
                <>
                    {row.source === 1 ? '申请加入' : '手动添加'}
                </>
            ),
        },
        {
            title: "联系人",
            width: 150,
            dataIndex: "contactPerson",
        },
        {
            title: "联系方式",
            width: 150,
            dataIndex: "contactPhone",
            render: (_, row) => (
                <>
                    {row.contactPhone?.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")}
                </>
            ),
        },
        {
            title: "加入审核操作",
            dataIndex: "state",
            key: "state",
            width: 100,
            fixed: 'right',
            render: (_, row) => (
                <>
                    <Button size="small" type='link' onClick={() => memberRefuse(row)}>查看驳回原因</Button>
                    <Button size="small" type='link' onClick={() => memberPass(row.id)}>审核通过</Button>
                </>
            ),
        },
        {
            title: "认证审核操作",
            dataIndex: "state",
            key: "state",
            width: 260,
            fixed: 'right',
            render: (_, row) => {
                const reviewing = row.authStatus === 1; // 审核中
                const approved = row.authStatus === 2; // 已通过
                const rejected = row.authStatus === 3; // 已驳回
                row = { ...row, reviewing, approved, rejected };
                return (
                    <div className="main-table-operations">
                        <span className="operation-btns">
                            {(reviewing || approved || rejected) && (
                                <Button size="small" type="link" onClick={() => viewAuthDetail(row)}>查看详情</Button>
                            )}
                            {reviewing && (
                                <>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link" onClick={() => auditThrough(row.memcard)}>通过申请</Button>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link" onClick={() => auditDismissed(row.memcard)}>驳回申请</Button>
                                </>
                            )}
                            {approved && (
                                <>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link">已审核-通过</Button>
                                </>
                            )}
                            {rejected && (
                                <>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link">已审核-驳回</Button>
                                </>
                            )}
                        </span>
                    </div>
                );
            },
        },
    ];

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setSize(b);
    };

    // 筛选查询
    const onFormFinish = (values) => {
        setSearchParams({ ...values });
    };

    // 重置
    const onReset = () => {
        filterForm.resetFields();
        setSearchParams({});
    };

    // 列表
    const getMembers = async (reqParams) => {
        setLoading(true);
        const res = await membersListApi({ ...reqParams, projectId });
        setLoading(false);
        if (res && res.status_code === 200 && res.data) {
            const memCards = (res.data.list || []).map(item => item.memcard)?.join();
            if (res.data.list && res.data.list.length) {
                getSsoMemberByMemCardsApi({ memCards }).then((authRes) => {
                    if (authRes && authRes.status_code === 200 && authRes.data) {
                        const newList = (res.data.list || []).map(item => ({
                            ...item,
                            memAuthStatus: authRes.data.find(authItem => authItem.memCard === item.memcard)?.memAuthStatus,
                            memIsyijiehui: authRes.data.find(authItem => authItem.memCard === item.memcard)?.memIsyijiehui,
                            memType: authRes.data.find(authItem => authItem.memCard === item.memcard)?.memType,
                        }));
                        setData(newList);
                        setTotal(res.data.totalCount);
                    }
                })
            }
        };
    };

    // 查看驳回
    const memberRefuse = (row) => {
        // console.log(row);
        setRefuseReason(row.refuseReason);
        setIsModalRefuseOpen(true);
    };

    // 成员信息通过
    const memberPass = async (id) => {
        setLoading(true);
        const res = await membersPassApi({ id });
        setLoading(false);
        if (res && res.status_code === 200 && res.data) {
            message.success('审核已通过');
            getMembers({ page, size, status, ...searchParams });
        };
    };

    // 关闭弹窗
    const handleRefuseCancel = () => {
        setIsModalRefuseOpen(false);
    };

    // 查看详情
    const viewAuthDetail = async row => {
        const { memcard, reviewing, authStatus } = row;
        setIsShowFooter(reviewing);
        setAuthStatus(authStatus);
        const res = await auditInfoBymemCardApi({ memCard: memcard });
        if (res.status_code === 200) {
            if (res.data?.ssoMember?.memType === 1) {
                setAuthInfo(res.data?.ssoMemberUpdate || {});
            }
            if (res.data?.ssoMember?.memType === 2) {
                setAuthInfo(res.data?.sysOrganization || {});
            }
            setSsoMember(res.data?.ssoMember);
            setVisibleAuthInfoDrawer(true);
        }
    };

    // 通过申请
    const auditThrough = async (memCard) => {
        const res = await auditThroughApi({ memCard });
        if (res.status_code === 200) {
            message.success('已通过认证审核');
            setVisibleAuthInfoDrawer(false);
            getMembers({ page, size, status, ...searchParams });
        }
    };

    // 驳回申请
    const auditDismissed = (memCard) => {
        curMemCard.current = memCard;
        setVisibleAuthDismissedModal(true);
    };

    useEffect(() => {
        if (authDismissedReson) {
            auditDismissedApi({ memCard: curMemCard.current, failReason: authDismissedReson }).then(res => {
                if (res.status_code === 200) {
                    message.success('已驳回认证审核');
                    setVisibleAuthDismissedModal(false);
                    setVisibleAuthInfoDrawer(false);
                    getMembers({ page, size, status, ...searchParams });
                }
            })
        }
    }, [authDismissedReson]);

    useEffect(() => {
        getMembers({ page, size, status, ...searchParams });
    }, [page, size, status, searchParams]);


    return (
        <>
            <div className="main-content-body">
                <div className="member-detail">
                    <div className="detail-header">
                        <Form
                            form={filterForm}
                            name="filterForm"
                            onFinish={onFormFinish}
                            {...filterFormItemLayout}
                        >
                            <Row>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="username" label="姓名:">
                                        <Input placeholder="请输入姓名" />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="mobile" label="手机号:">
                                        <Input placeholder="请输入手机号" />
                                    </Form.Item>
                                </Col>

                                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">检索</Button>
                                        <Button type="default" onClick={onReset}>重置</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        scroll={{
                            x: 2000,
                        }}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: size,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />

                    <Modal
                        title='驳回理由'
                        open={isModalRefuseOpen}
                        onOk={handleRefuseCancel}
                        onCancel={handleRefuseCancel}
                        className="memberModal"
                        footer={
                            <div className="memberModalFooter" style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleRefuseCancel}>确定</Button>
                            </div>
                        }
                    >
                        <Space>
                            <span className='refuseLable'>驳回理由：</span>
                            <div className='refuseReason'>{refuseReason}</div>
                        </Space>

                    </Modal>

                    <ViewAuthInfoDrawer
                        setVisibleAuthInfoDrawer={setVisibleAuthInfoDrawer}
                        visibleAuthInfoDrawer={visibleAuthInfoDrawer}
                        authInfo={authInfo}
                        ssoMember={ssoMember}
                        auditThrough={auditThrough}
                        auditDismissed={auditDismissed}
                        isShowFooter={isShowFooter}
                        authStatus={authStatus}
                    />

                    <AuditDismissedModal
                        visibleAuthDismissedModal={visibleAuthDismissedModal}
                        setVisibleAuthDismissedModal={setVisibleAuthDismissedModal}
                        setAuthDismissedReson={setAuthDismissedReson}
                    />
                </div>
            </div>
        </>
    );
}

export default RefuseList;
