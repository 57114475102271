import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Layout, DatePicker, Tooltip, Select } from 'antd';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import { fetchOperationStatActiveUserCount, fetchOperationStatActionCount} from '@services/Enterprise';
import './OperationStat.less';

const { Content } = Layout;
const { RangePicker } = DatePicker;

export default function OperationStat() {
    const [activeUserCountDateRangeType, setActiveUserCountDateRangeType] = useState('7d');
    const [activeUserCountParams, setActiveUserCountParams] = useState([
        moment().subtract(7, 'days'),
        moment()
    ]);
    const [activeUserCountData, setActiveUserCountData] = useState([]);

    const [actionCountDateRangeType, setActionCountDateRangeType] = useState('7d');
    const [actionCountParams, setActionCountParams] = useState([
        moment().subtract(7, 'days'),
        moment()
    ]);
    const [actionCountData, setActionCountData] = useState([]);

    const changeDateRangeType = (type, value) => {
        let dateRange = [];
        switch (value) {
            case 'today':
                dateRange = [moment(), moment()];
                break;
            case 'yesterday':
                dateRange = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
                break;
            case '7d':
                dateRange = [moment().subtract(7, 'days'), moment()];
                break;
            case '14d':
                dateRange = [moment().subtract(14, 'days'), moment()];
                break;
            case '30d':
                dateRange = [moment().subtract(30, 'days'), moment()];
                break;
            case 'customized':
                dateRange = type === 'activeUser'
                    ? activeUserCountParams
                    : type === 'actionCount'
                        ? actionCountParams : [];
                break;
            default:
                break;
        }

        if (type === 'activeUser') {
            setActiveUserCountParams(dateRange);
        }

        if (type === 'actionCount') {
            setActionCountParams(dateRange);
        }
    };

    const onChangeActiveUserCountDateRangeType = value => {
        changeDateRangeType('activeUser', value);
        setActiveUserCountDateRangeType(value);
    };

    const onChangeActionCountDateRangeType = value => {
        changeDateRangeType('actionCount', value);
        setActionCountDateRangeType(value);
    };

    const onChangeActiveUserCountDateRange = values => {
        setActiveUserCountDateRangeType('customized');
        setActiveUserCountParams(values);
    };

    const onChangeActionCountDateRange = values => {
        setActionCountDateRangeType('customized');
        setActionCountParams(values);
    };

    const getOperationStatActivityCountData = async () => {
        if (activeUserCountParams && activeUserCountParams.length) {
            const res = await fetchOperationStatActiveUserCount({
                beginTime: activeUserCountParams[0].format('YYYY-MM-DD'),
                endTime: activeUserCountParams[1].format('YYYY-MM-DD'),
            });
            if (res.status_code === 200) {
                setActiveUserCountData(res.data);
            }
        }
    };

    const getOperationStatActionCountData = async () => {
        if (actionCountParams && actionCountParams.length) {
            const res = await fetchOperationStatActionCount({
                beginTime: actionCountParams[0].format('YYYY-MM-DD'),
                endTime: actionCountParams[1].format('YYYY-MM-DD'),
            });
            if (res.status_code === 200) {
                setActionCountData(res.data);
            }
        }
    };

    useEffect(() => {
        getOperationStatActivityCountData();
    }, [activeUserCountParams]);

    useEffect(() => {
        getOperationStatActionCountData();
    }, [actionCountParams]);

    let activeUserChart = null;
    const drawActiveUserChart = () => {
        activeUserChart && activeUserChart.dispose();

        activeUserChart = echarts.init(document.getElementById('chart-active-user-count'));
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '5%',
                top: '15%',
                right: '5%',
                bottom: '5%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: _.map(activeUserCountData, 'date').map(d => moment(d).format('M月D日')),
                boundaryGap: false,
            },
            yAxis: {
                name: '活跃人数',
                type: 'value',
                boundaryGap: false
            },
            series: [
                {
                    data: _.map(activeUserCountData, 'counts'),
                    type: 'line',
                    label: {
                        show: true,
                        position: 'top'
                    },
                    lineStyle: {
                        width: 1
                    },
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgb(0, 221, 255)'
                            },
                            {
                                offset: 1,
                                color: 'rgb(77, 119, 255)'
                            }
                        ])
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    smooth: true
                }
            ]
        };
        activeUserChart.setOption(option);

        window.addEventListener('resize', function () {
            activeUserChart.resize();
        });
    };

    useEffect(() => {
        if (activeUserCountData && activeUserCountData.length) {
            drawActiveUserChart();
        }
    }, [activeUserCountData]);

    let actionCountChart = null;
    const drawActionCountChart = () => {
        actionCountChart && actionCountChart.dispose();

        actionCountChart = echarts.init(document.getElementById('chart-action-count'));

        _.reverse(actionCountData);

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '5%',
                top: 50,
                right: '10%',
                bottom: 50,
                containLabel: true
            },
            xAxis: {
                name: '活跃次数',
                type: 'value',
                boundaryGap: [0, 0.01],
                position: 'top'
            },
            yAxis: {
                type: 'category',
                data: _.map(actionCountData, 'staff_name'),
            },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 10,
                max: 100,
                text: ['高频', '底频'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                    color: ['#65B581', '#FFCE34', '#FD665F']
                }
            },
            series: [
                {
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'right'
                    },
                    data: _.map(actionCountData, 'counts'),
                },
            ]
        };
        actionCountChart.setOption(option);

        actionCountChart.resize({ height: 50 * actionCountData.length + 50 });

        window.addEventListener('resize', function () {
            actionCountChart.resize();
        });
    };

    useEffect(() => {
        console.log(actionCountData);
        if (actionCountData && actionCountData.length) {
            drawActionCountChart();
        }
    }, [actionCountData]);

    const disabledDate = (current) => current > moment().endOf('day');

    return <>

        <div className="page-title">
            <Link to="/enterprise/operation/log" className="back"><LeftOutlined/></Link>
            <span className="current-title">后台操作统计</span>
        </div>

        <Content className="main-content-box no-bg-color">

            <div className="content-block">

                <div className="content-block-head">
                    <div className="content-block-title">
                        活跃人数趋势
                        <Tooltip title="按天统计活跃人数，活跃即后台产生查询或增删改等操作">
                            <span className="tooltip-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                            </span>
                        </Tooltip>
                    </div>
                    <div className="content-block-toolbar">
                        <Select
                            placeholder="统计时段"
                            style={{width: 120, marginRight: 8}}
                            value={activeUserCountDateRangeType}
                            onChange={onChangeActiveUserCountDateRangeType}
                        >
                            <Select.Option value="today">今天</Select.Option>
                            <Select.Option value="yesterday">昨天</Select.Option>
                            <Select.Option value="7d">最近7天</Select.Option>
                            <Select.Option value="14d">最近14天</Select.Option>
                            <Select.Option value="30d">最近30天</Select.Option>
                            <Select.Option value="customized">自定义</Select.Option>
                        </Select>
                        <RangePicker
                            style={{width: 240}}
                            value={activeUserCountParams}
                            format="YYYY-MM-DD"
                            onChange={onChangeActiveUserCountDateRange}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>

                <div className="content-block-body">

                    <div id="chart-active-user-count"
                         className="chart-active-user-count"
                         style={{width: '100%', height: 400}}
                    />

                </div>

            </div>

            <div className="content-block">

                <div className="content-block-head">
                    <div className="content-block-title">
                        活跃次数分析
                        <Tooltip title="统计指定时间范围后台管理员产生查询或增删改等操作次数，倒序排列">
                            <span className="tooltip-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                            </span>
                        </Tooltip>
                    </div>
                    <div className="content-block-toolbar">
                        <Select
                            placeholder="统计时段"
                            style={{width: 120, marginRight: 8}}
                            value={actionCountDateRangeType}
                            onChange={onChangeActionCountDateRangeType}
                        >
                            <Select.Option value="today">今天</Select.Option>
                            <Select.Option value="yesterday">昨天</Select.Option>
                            <Select.Option value="7d">最近7天</Select.Option>
                            <Select.Option value="14d">最近14天</Select.Option>
                            <Select.Option value="30d">最近30天</Select.Option>
                            <Select.Option value="customized">自定义</Select.Option>
                        </Select>
                        <RangePicker
                            style={{width: 240}}
                            value={actionCountParams}
                            format="YYYY-MM-DD"
                            onChange={onChangeActionCountDateRange}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>

                <div className="content-block-body">

                    <div id="chart-action-count"
                         className="chart-action-count"
                         style={{width: '100%', minHeight: 400}}
                    />

                </div>

            </div>

        </Content>
    </>;
}
