import React, { Fragment, useState, useEffect } from "react";
import { Layout, Button, Form, Input, Upload, Image as AntdImage, message } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import TinymceEditor from './TinymceEditor';
import './WallNewspaper.less';
import { uploadAttachApi, uploadSolutionApi, getPosterDetailApi, savePosterDataApi } from '@services/Eha';
import config from "@config";
import ImgCrop from 'antd-img-crop';
import html2canvas from "html2canvas";
import QRCode from 'qrcode.react';
import poster_footer_logo from '@/static/images/poster_footer_logo@2x.png';
import poster_footer_qrcode from '@/static/images/poster_footer_qrcode@2x.png';

const { Content } = Layout;

const { TextArea } = Input;

// 生成海报
const WallNewspaper = () => {
    const params = useParams();
    const [form] = Form.useForm();

    const [posterSolutionBgImg, setPosterSolutionBgImg] = useState('https://mediafiles.cn-healthcare.com/solution/poster/solution_poster_bg.jpg');
    const [posterLogo, setPosterLogo] = useState();
    const [posterSolutionImg, setPosterSolutionImg] = useState();

    const [dataDetail, setDataDetail] = useState(undefined);
    const [posterDetail, setPosterDetail] = useState(undefined);

    const posterSolutionTitle = Form.useWatch('posterSolutionTitle', form);
    const posterSolutionSummary = Form.useWatch('posterSolutionSummary', form);
    const posterSolutionScenarios = Form.useWatch('posterSolutionScenarios', form);
    const posterSolution = Form.useWatch('posterSolution', form);

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    // 上传文件路径 - 前缀
    const getOssFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return `${config.BC_OSS_URL_PREFIX}/${url}`;
        }
        return null;
    };

    const getFileUrl = (url) => {
        if (url) {
            if (url.startsWith("http")) {
                return url;
            } else {
                return config.JKJ_OSS_URL_PREFIX + "/" + url;
            }
        }

        return null;
    };

    // 上传格式校验
    const beforeUpload = (file, type) => {
        const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
        const size = type === 1 ? 5 : 10;
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png格式');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            message.error(`图片大小不超过${size}MB`);
            return false;
        }
        return isJpgOrPng && isLt2M;
    };

    // 图片上传
    const imageUpload = async (value, type) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('path', '');

        let ajaxApi = type === 2 || type === 4 ? uploadSolutionApi : uploadAttachApi;

        const res = await ajaxApi(formData);
        if (res && res.data) {
            switch (type) {
                case 1:
                    setPosterLogo(getOssFileUrl(res.data));
                    form.setFieldValue('posterLogo', getOssFileUrl(res.data));
                    form.setFields([{ name: 'posterLogo', errors: null }]);
                    break;
                case 2:
                    setPosterSolutionImg(getOssFileUrl(res.data));
                    form.setFieldValue('posterSolutionImg', getOssFileUrl(res.data));
                    form.setFields([{ name: 'posterSolutionImg', errors: null }]);
                    break;
                case 3:
                    setPosterSolutionBgImg(getOssFileUrl(res.data));
                    form.setFieldValue('posterSolutionBgImg', getOssFileUrl(res.data));
                    form.setFields([{ name: 'posterSolutionBgImg', errors: null }]);
                    break;
                default:
                    break;
            }
        };
    };

    const beforeCropCheck = (file, bool, type) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 10 * 1024 * 1024) {
            message.error('图片大小不能超过10MB。');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    const width = type === 1 ? 3700 : 4480;
                    const height = type === 1 ? 2084 : 11200;
                    if (imgDom.width < width || imgDom.height < height) {
                        bool && message.warn(`图片不小于${width}x${height}px。`);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    // 解决方案详情
    const getPosterDetail = async (id) => {
        const response = await getPosterDetailApi({ id });
        const res = response?.data;
        const resPosterData = response?.data?.posterData;
        if (res) {
            const posterData = JSON.parse(resPosterData || '{}');
            setDataDetail(res);
            setPosterDetail(posterData);
            let resValues = { ...posterData, posterEnterpriseName: response?.data?.unit };

            if (posterData.posterLogo) {
                const url = posterData.posterLogo.includes('eha') ? getOssFileUrl(posterData.posterLogo) : getFileUrl(posterData.posterLogo);
                setPosterLogo(url);
            }
            if (posterData.posterSolutionImg) {
                setPosterSolutionImg(getOssFileUrl(posterData.posterSolutionImg));
            }
            if (posterData.posterSolutionBgImg) {
                setPosterSolutionBgImg(getOssFileUrl(posterData.posterSolutionBgImg));
            }
            form.setFieldsValue(resValues);
        }
    };

    const parseValue = (value) => {
        return parseInt(value, 10);
    };

    const drawCanvas = async (domId) => {
        const scale = 10;
        // 获取想要转换的 DOM 节点
        const dom = document.getElementById(domId);
        // const dom = document.querySelector('printHtml');
        const box = window.getComputedStyle(dom);
        // DOM 节点计算后宽高
        const width = parseValue(box.width);
        const height = parseValue(box.height);
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scale;
        // canvas.width = 4480
        canvas.height = height * scale;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width / scale}px`;
        canvas.style.height = `${height / scale}px`;

        // 将自定义 canvas 作为配置项传入，开始绘制
        return html2canvas(dom, { canvas, scale, useCORS: true }).then((canvas) => {
            return canvas.toDataURL("image/png", 1.0);
        });
    };

    const downloadPoster = () => {
        form.validateFields().then(async (values) => {
            message.warning('正在生成海报，请稍后...');

            const backgroundImgLoaded = new Promise((resolve) => {
                if (!values.posterSolutionBgImg) { 
                    resolve();
                }
                const img = new Image();
                img.onload = () => resolve();
                img.src = getOssFileUrl(values.posterSolutionBgImg); // posterSolutionBgImg 是背景图片的地址
            });

            const solutionImgLoaded = new Promise((resolve) => {
                if (!values.posterSolutionImg) { 
                    resolve();
                }
                const img = new Image();
                img.onload = () => resolve();
                img.src = getOssFileUrl(values.posterSolutionImg); // posterSolutionImg 是配图的地址
            });

            const logoUrl = form.getFieldValue('posterLogo')?.includes('eha') ? getOssFileUrl(form.getFieldValue('posterLogo')) : getFileUrl(form.getFieldValue('posterLogo'));
            const logoImgLoaded = new Promise((resolve) => {
                if (!logoUrl) { 
                    resolve();
                }
                const img = new Image();
                img.onload = () => resolve();
                img.src = logoUrl;
            });

            // 等待背景图片和封面图片加载完成
            await Promise.all([backgroundImgLoaded, solutionImgLoaded, logoImgLoaded]);

            // 图片加载完成后执行下载壁报的操作
            const res = await drawCanvas("wall-newspaper-preview");
            savePosterData(values);
            downloadBlob(res);
        });
    };

    const downloadBlob = (content) => {
        let aLink = document.createElement("a");
        let blob = base64ToBlob(content); //new Blob([content]);

        aLink.download = "测试壁纸下载";
        const url = URL.createObjectURL(blob);
        aLink.href = url;
        aLink.click();
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 100);
    };

    const base64ToBlob = (code) => {
        let parts = code.split(";base64,");
        let contentType = parts[0].split(":")[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;

        let uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    };

    const saveData = () => { 
        form.validateFields().then(async(values) => {
            const res = await savePosterDataApi({
                id: params.id,
                posterData: JSON.stringify(values)
            });
            if (res.status_code === 200) {
                message.success('保存成功');
            }
        });
    };

    // 保存海报数据
    const savePosterData = async (values) => {
        const res = await savePosterDataApi({
            id: params.id,
            posterData: JSON.stringify(values)
        });
    };

    useEffect(() => {
        if (params.id) {
            getPosterDetail(params.id);
        }
    }, []);

    return <>
        <div className="page-title">
            <Link to={`/healthaccount/eha/solutions`} className="back"><LeftOutlined /></Link>
            <span className="current-title">编辑解决方案</span>
        </div>

        <Content className="main-content-box">
            <div className="main-content-title">解决方案信息</div>
            <div className="main-content-body">
                <div className="wall-newspaper-page">
                    <Form
                        form={form}
                        layout="vertical"
                        className="wallNewspaper-form-solution"
                        name="form"
                        onFinish={onFinish}
                        initialValues={{ posterSolutionBgImg: 'https://mediafiles.cn-healthcare.com/solution/poster/solution_poster_bg.jpg' }}
                    >

                        <Form.Item
                            name="posterLogo"
                            label="企业LOGO"
                            help="图片尺寸：750*750px，支持 JPG/PNG格式，大小不超过5MB"
                            className="share-image"
                        >
                            {posterLogo && <AntdImage height={180} src={posterLogo} className="share-image-area" />}

                            <div>
                                <Upload
                                    beforeUpload={(file) =>
                                        beforeUpload(file, 1)
                                    }
                                    fileList={[]}
                                    customRequest={(e) => imageUpload(e, 1)}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {posterLogo
                                            ? "更改图片"
                                            : "上传图片"}
                                    </Button>
                                </Upload>

                            </div>
                        </Form.Item>

                        <Form.Item
                            name="posterEnterpriseName"
                            label="企业名称"
                        >
                            <Input maxLength={30} placeholder="输入30字内标题" disabled></Input>
                        </Form.Item>


                        <Form.Item
                            name="posterSolutionTitle"
                            label="解决方案标题"
                        >
                            <Input maxLength={30} placeholder="输入30字内标题"></Input>
                        </Form.Item>

                        <Form.Item
                            name="posterSolutionSummary"
                            label="摘要"
                        >
                            <TextArea rows={4} placeholder="简明扼要地介绍解决方案的基本功能和方法，突出解决方案的核心思想" showCount maxLength={120} />
                        </Form.Item>

                        <Form.Item
                            name="posterSolutionScenarios"
                            label={<>场景<span className="wall-tip">建议最少40字，最多60字</span></>}
                        >
                            <TinymceEditor
                                form={form}
                                fieldValue='posterSolutionScenarios'
                                id="posterSolutionScenarios"
                                initialValue={posterDetail?.posterSolutionScenarios}
                                placeholder="描述解决方案在医院内的具体应用场景，比如应用于某科室的某业务"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posterSolution"
                            label={<>解决方案<span className="wall-tip">建议最少120字，最多150字</span></>}
                        >
                            <TinymceEditor
                                form={form}
                                fieldValue='posterSolution'
                                id="posterSolution"
                                initialValue={posterDetail?.posterSolution}
                                placeholder="描述解决方案切实解决了行业哪些痛点，能为医院带来明显质量改进或效率提升等正向价值"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posterSolutionImg"
                            label="解决方案配图"
                            extra={<>
                                <div>支持JPG/PNG格式，用于解决方案展示，单张图片大小不超过10MB，图片尺寸3700*2084px</div>
                                <div>内容区：3950*8200px</div>
                            </>}
                            className="share-image"
                        >
                            {posterSolutionImg && <AntdImage height={180} src={posterSolutionImg} className="share-image-area" />}

                            <div>
                                <ImgCrop
                                    aspect={3700 / 2084}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    quality={1}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true, 1);
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) =>
                                            beforeUpload(file, 2)
                                        }
                                        fileList={[]}
                                        customRequest={(e) => imageUpload(e, 2)}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {posterSolutionImg
                                                ? "更改图片"
                                                : "上传图片"}
                                        </Button>
                                    </Upload>
                                </ImgCrop>

                            </div>
                        </Form.Item>

                        <Form.Item
                            name="posterSolutionBgImg"
                            label="壁报背景图"
                            extra="支持JPG/PNG格式，用于解决方案壁报背景，单张图片大小不超过10MB，背景尺寸4480*11200px"
                            className="share-image"
                        >
                            {posterSolutionBgImg && <AntdImage height={180} src={posterSolutionBgImg} className="share-image-area" />}

                            <div>
                                <ImgCrop
                                    aspect={448 / 1120}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    quality={1}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true, 2);
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) =>
                                            beforeUpload(file, 3)
                                        }
                                        fileList={[]}
                                        customRequest={(e) => imageUpload(e, 3)}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {posterSolutionBgImg
                                                ? "更改图片"
                                                : "上传图片"}
                                        </Button>
                                    </Upload>
                                </ImgCrop>

                            </div>
                        </Form.Item>

                        <div className="wall-download-btn"><Button type="primary" onClick={saveData}>保存</Button></div>

                    </Form>
                    <div className="wall-newspaper-wrap">
                        <div className="wall-newspaper-preview-wrap">
                            <div className="wall-newspaper-preview" id="wall-newspaper-preview" style={{ backgroundImage: `url(${posterSolutionBgImg})` }}>
                                <div className="wall-newspaper-header">
                                    <img className="wall-newspaper-logo" src={posterLogo} alt="" />
                                    <div className="wall-newspaper-title">{dataDetail?.unit}</div>
                                </div>

                                <div className="wall-newspaper-code">
                                    <div className="wall-newspaper-left">
                                        <div className="wall-newspaper-line"></div>
                                        <div className="wall-newspaper-name">{posterSolutionTitle}</div>
                                    </div>
                                    <div className="wall-newspaper-qrcode">
                                        <div className="wall-qrcode">
                                            <QRCode
                                                id="qrCode"
                                                value={`${config.SOLUTION_DOMAIN}/m/solutions/${params.id}`}
                                                size={62} // 二维码的大小
                                                fgColor="#000000" // 二维码的颜色
                                                style={{ margin: 'auto' }}
                                                level="H"
                                            />
                                        </div>
                                        <span>扫码查看电子版</span>
                                    </div>
                                </div>

                                <div className="wall-newspaper-item">
                                    <div className="wall-newspaper-tag">摘要</div>
                                    <div className="wall-newspaper-tag-content">{posterSolutionSummary}</div>
                                </div>

                                <div className="wall-newspaper-item">
                                    <div className="wall-newspaper-tag">场景</div>
                                    <div dangerouslySetInnerHTML={{ __html: posterSolutionScenarios }}></div>
                                </div>

                                <div className="wall-newspaper-item">
                                    <div className="wall-newspaper-tag">解决方案</div>
                                    <div dangerouslySetInnerHTML={{ __html: posterSolution }}></div>
                                </div>

                                {posterSolutionImg && <div className="poster-solution-image-wrap"><img className="poster-solution-image" src={posterSolutionImg} alt="" /></div>}

                                <div className="wall-newspaper-footer-content">
                                    <div className="wall-newspaper-footer-code">
                                        <img className="wall-footer-qrcode" src={poster_footer_qrcode} alt="" />
                                        <span>码上入驻，直达医疗决策人</span>
                                    </div>
                                    <div className="poster-footer-logo-wrap"><img className="poster-footer-logo" src={poster_footer_logo} alt="" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="wall-download-btn"><Button type="primary" onClick={downloadPoster}>下载壁报</Button></div>
                    </div>
                </div>
            </div>
        </Content>
    </>
};

export default WallNewspaper;
