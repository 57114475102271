import EhaDashboard from "../components/Eha/Dashboard/EhaDashboard";
import EnterprisesList from "../components/Eha/Enterprises/EnterprisesList"
import SolutionsList from "../components/Eha/Solutions/SolutionsList"
import Detail from "../components/Eha/Detail"
import SolutionsEdit from "../components/Eha/Solutions/EditSolution"
import WallNewspaper from "../components/Eha/Solutions/WallNewspaper"

// 健康号模块相关路由
const ehaRoutes = [
    // 健康号 - 首页
    { path: '', element: <EhaDashboard /> },
    { path: 'eha/enterprises', element: <EnterprisesList /> },
    { path: 'eha/solutions', element: <SolutionsList /> },
    { path: 'eha/solutions/:id', element: <Detail /> },
    { path: 'eha/solutions/edit/:id', element: <SolutionsEdit /> },
    { path: 'eha/solutions/placard/:id', element: <WallNewspaper /> },
];

export default ehaRoutes;
