import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select, InputNumber, message} from "antd";
import {getUserDetail, createUser, editUser} from '@services/MedConnect';
import {userTypeList} from "../constants";


const { Option } = Select;

export default function UserFormModal({isOpen,handleClose,currentData,handleOk}) {

    const [form] = Form.useForm();

    const getUserDetails = (id) => {
        getUserDetail(id).then((res) => {
            if (res && res.success) {
                form.setFieldsValue(res.data);
            }
        })
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const params = {
                ...values,
                projectId: currentData.projectId,
            };
            if (currentData && currentData.id) {
                params.id = currentData.id;
                editUser(params).then((res) => {
                    if (res && res.success) {
                        message.success(res.data || '修改成功');
                        handleOk && handleOk();
                    }
                })
            } else {
                createUser(params).then((res) => {
                    if (res && res.success) {
                        message.success(res.data || '新增成功');
                        handleOk && handleOk();
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (currentData && currentData.id) {
            getUserDetails(currentData.id);
        }

        return () => {
            form.resetFields();
        }
    }, [currentData]);

    return (
        <Modal
            visible={isOpen}
            title={`${currentData.id ? '修改' : '新增'}医生`}
            onOk={handleSubmit}
            onCancel={handleClose}
        >
            <Form
                form={form}
                labelCol={{span:8}}
            >

                <Form.Item
                    name='userType'
                    label='类型'
                    rules={[
                        {
                            required: true,
                            message: '',
                        }
                    ]}
                    initialValue={1}
                >
                    <Select>
                        {userTypeList && userTypeList.map(item => {
                            return <Option value={item.value} key={item.value}>{item.label}</Option>;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='userName'
                    label='医生姓名'
                    rules={[
                        {
                            required: true,
                            message: '请输入医生姓名',
                        }
                    ]}
                >
                    <Input placeholder="请输入姓名" allowClear autoComplete="off" maxLength={50} showCount />
                </Form.Item>
                <Form.Item
                    name='phone'
                    label='手机号'
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => {
                                if (!/^1\d{10}$/.test(value)) {
                                    return Promise.reject('手机号不正确');
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        }
                    ]}
                >
                    <Input placeholder="请输入手机号" allowClear autoComplete="off" />
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, cur) => pre.userType !== cur.userType}
                >
                    {
                        ({getFieldValue}) => {
                            return getFieldValue('userType') === 1 ? (
                                <>
                                    <Form.Item
                                        name='qaLimit'
                                        label='提交问答数量限额'
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入提交问答数量限额',
                                            }
                                        ]}
                                    >
                                        <InputNumber style={{width: '100%'}} max={9999} />
                                    </Form.Item>
                                </>
                            ) : (
                                <>
                                    <Form.Item
                                        name='auditLimit'
                                        label='审核数量限额'
                                        rules={[
                                            {
                                                required: getFieldValue('userType') === 2,
                                                message: '请输入审核数量限额',
                                            }
                                        ]}
                                    >
                                        <InputNumber style={{width: '100%'}} max={9999} />
                                    </Form.Item>
                                    <Form.Item
                                        name='qaLimit'
                                        label='提交问答数量限额'
                                    >
                                        <InputNumber style={{width: '100%'}} max={9999} />
                                    </Form.Item>
                                </>
                            )
                        }
                    }
                </Form.Item>
                <Form.Item
                    name='hospital'
                    label='医院名称'
                >
                    <Input placeholder="请输入医院名称" allowClear autoComplete="off" maxLength={100} showCount />
                </Form.Item>
                <Form.Item
                    name='department'
                    label='科室名称'
                >
                    <Input placeholder="请输入科室名称" allowClear autoComplete="off" maxLength={100} showCount />
                </Form.Item>
                <Form.Item
                    name='jobTitle'
                    label='职称'
                >
                    <Input placeholder="请输入职称" allowClear autoComplete="off" maxLength={100} showCount />
                </Form.Item>
                <Form.Item
                    name='jobPost'
                    label='职务'
                >
                    <Input placeholder="请输入职务" allowClear autoComplete="off" maxLength={100} showCount />
                </Form.Item>

            </Form>
        </Modal>
    )
}
