import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Select, Space, Table, DatePicker, message} from "antd";
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {dateTimeFormat} from '@utils/utils';
import {getFeeBatchUserList,createFeeBatch} from '@services/MedConnect';
import {useParams} from "react-router-dom";


const { RangePicker } = DatePicker;
const { Option } = Select;

export default function LabfeeModal({isOpen,handleClose}) {
    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const [form] = Form.useForm();

    const [filters, setFilters] = useState({page: 1, pageSize: 10});

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);

    const [detailInfo, setDetailInfo] = useState({});

    // 获取数据
    const getLabfeeList = async () => {
       try {
           setLoading(true);
           const params = {
               ...filters,
               projectId: id,
           }
           getFeeBatchUserList(params).then(res => {
               if (res && res.success) {
                   setDetailInfo(res.data)
                   setTableData(res.data.pageResponse.data);
                   setTotal(res.data.pageResponse.totalPages);
               }
           })
       } catch (e) {
           console.log('error', e);
       } finally {
           setLoading(false);
       }
    }

    // 弹窗检索
    const handleFilterFinish = (values) => {
        setFilters({
            ...filters,
            page: 1,
            auditedAtFrom: values.timeRange ? dateTimeFormat(values.timeRange[0], 'YYYY-MM-DD 00:00:00') : '',
            auditedAtEnd: values.timeRange ? dateTimeFormat(values.timeRange[1], 'YYYY-MM-DD 23:59:59') : '',
            userType: values.userType,
            hospital: values.hospital,
        })
    }

    // 重置
    const onReset = () => {
        form.resetFields();

        setFilters({
            ...filters,
            ...form.getFieldsValue(),
            auditedAtFrom: '',
            auditedAtEnd: '',
            page: 1,
        });
    }

    // 列表移除
    const handleDelete = (record, index) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认移除此项数据吗`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                const newData = tableData.filter((v, num) => num !== index);
                setTableData(newData);
            }
        });
    }

    // 生成劳务付款批次
    const handleOk = () => {
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: `是否生成该批次劳务付款任务`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                const userIds = tableData.map(item => item.id);
                const params = {
                    projectId: id,
                    moveUserIds: userIds,
                };
                createFeeBatch(params).then(res => {
                    if (res && res.success) {
                        message.success(res.data);
                        handleClose && handleClose();
                    }
                })
            }
        });
    }

    const onChangePage = (page, pageSize) => {
        setFilters({
            ...filters,
            page,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        { title: '医生', dataIndex: 'userName' },
        { title: '人员类型', dataIndex: 'userTypeDesc' },
        { title: '手机号', dataIndex: 'phone' },
        { title: '所属科室', dataIndex: 'department' },
        { title: '所属医院', dataIndex: 'hospital' },
        { title: '有效问答数', dataIndex: 'qaNum' },
        { title: '有效审核数', dataIndex: 'auditNum' },
        {
            title: '操作',
            dataIndex: 'operations',
            render: (_, record, index) => {

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDelete(record, index)}
                        >
                            移除
                        </Button>
                    </span>
                );
            }
        }
    ]

    useEffect(() => {
        getLabfeeList();
    }, [filters]);

    return (
        <Modal
            open={isOpen}
            title={'生成劳务付款批次'}
            width={1200}
            onOk={handleOk}
            onCancel={handleClose}
            okText={'生成'}
        >
            {/*<Content className="main-content-box">*/}
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="审核时间" name="timeRange">
                                    <RangePicker/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="人员类型" name="userType">
                                    <Select placeholder="全部">
                                        <Option value={1}>医生</Option>
                                        <Option value={2}>专家</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="所属医院" name="hospital">
                                    <Input allowClear placeholder="请输入医院名称" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div>共{detailInfo.userNum}位医生，{detailInfo.qaNum}个有效问答数</div>

                <div className="main-content-body">
                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filters.page,
                            pageSize: filters.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>
            {/*</Content>*/}

        </Modal>
    )
}
