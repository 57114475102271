import React, { useEffect, useState } from "react";
import { Button, Upload, Switch, message } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined, FileTextOutlined, UploadOutlined } from "@ant-design/icons";
import { uploadFile } from "@services/Wj";
import { putEdititleApi, getCustomReportApi } from "@services/Wj/Statistical";

import {} from "@services/Wj/Statistical";
import moment from "moment/moment";
import config from "@config";
import "./index.less";
const Index = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const option = { headers: { "Content-Type": "multipart/form-data" } };
    const [fileList, setFileList] = useState([]);
    const [fileUrl, setFileUrl] = useState(undefined);

    const [show, setShow] = useState(false);
    const reportProps = {
        name: "report",

        customRequest: async (info) => {
            //手动上传
            const isJpgOrPng = info.file.type === "image/jpeg" || info.file.type === "image/png" || info.file.type === "image/jpg" || info.file.type === "application/pdf";
            if (!isJpgOrPng) {
                message.error({
                    content: "只能上传 PDF/JPG/JPEG/PNG 格式!",
                });
                info.onError();
                return;
            }
            if (info.file.size > 100 * 1024 * 1024) {
                message.error({
                    content: "文件大小不能超过100M",
                });
                info.onError();
                return;
            }
            if (info.file.name.length > 50) {
                message.error({
                    content: "文件名称长度不能超过50字",
                });
                info.onError();
                return;
            }
            const formData = new FormData();
            formData.append("file", info.file);
            formData.append("path", `skins/upload/`);
            console.log(info.file);
            const res = await uploadFile(formData, option);
            if (res.status_code === 200) {
                info.onSuccess(res, info.file);
                setFileUrl(res.data);
                setFileList([{ url: config.OSS_PREFIX + `/${res.data}`, name: info.file.name }]);
            } else {
                info.onError();
            }
        },
    };

    const goChart = () => {
        navigate(`/wj/surveys/${location.state.id}/charts`, { state: location.state });
    };
    const goDetails = () => {
        navigate(`/wj/surveys/${location.state.id}/details`, {
            state: location.state,
        });
    };

    const goCostomReport = () => {
        navigate(`/wj/surveys/${location.state.id}/customreport`, {
            state: location.state,
        });
    };

    const onSelect = (checked) => {
        setShow(checked);
    };

    const onSend = async () => {
        let obj = {
            surveyId: location.state?.id, //问卷id
            reportEnabled: show ? 1 : 2,
            reportFile: fileUrl || "",
            reportFileName: fileList[0]?.name || "",
        };
        if (fileList.length <= 0 && show) {
            message.warning("请上传报告文件");
        } else {
            let res = await putEdititleApi(obj);
            if (res.status_code === 200) {
                message.success("保存成功");
            }
        }
    };
    useEffect(() => {
        getCustomReportApi({ surveyId: location.state?.id }).then((res) => {
            if (res.status_code === 200) {
                setShow(res.data.reportEnabled === 2 ? false : true);
                if (res.data.reportFile) {
                    setFileUrl(res.data?.reportFile);
                    setFileList([
                        {
                            url: config.OSS_PREFIX + `/${res.data?.reportFile}`,
                            name: res.data?.reportFileName,
                        },
                    ]);
                } else {
                    setFileList([]);
                }
            }
        });
    }, []);

    return (
        <div className="survey_charts_wrap">
            <div className="page-title">
                <Link to="/wj/surveys" className="back">
                    <LeftOutlined />
                </Link>
                {location.state?.name || ""}
            </div>
            <div className="main_content">
                <div className="left">
                    <p className=" menu-pointer" onClick={goChart}>
                        <LineChartOutlined /> &nbsp;&nbsp;统计图表
                    </p>
                    <p className="menu-pointer" onClick={goDetails}>
                        <ContainerOutlined />
                        &nbsp;&nbsp;数据详情
                    </p>
                    <p className="active menu-pointer" onClick={goCostomReport}>
                        <FileTextOutlined />
                        &nbsp;&nbsp;调研报告
                    </p>
                </div>
                <div className="custom-right-box">
                    <div className="custom-right">
                        <div>
                            <span className="switch-label">用户端展示调研报告</span>
                            <Switch checkedChildren={<span className="switchtype">开</span>} unCheckedChildren={<span className="switchtype">关</span>} onChange={onSelect} checked={show}></Switch>
                        </div>
                        {show && (
                            <div className="upload-btn">
                                <Upload {...reportProps} maxCount={1} fileList={fileList} onRemove={() => (setFileList([]), setFileUrl(""))}>
                                    <div>
                                        <Button icon={<UploadOutlined />}>上传文件</Button>
                                        <div className="upload-helper">文件格式支持 PDF/JPG/JPEG/PNG，大小不超过 100MB</div>
                                    </div>
                                </Upload>
                            </div>
                        )}

                        <div className="save-btnarea">
                            <Button type="primary" className="save-btn" onClick={onSend}>
                                确定
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
