import InvitationDashboard from '../components/Invitation/InvitationDashboard';
import InvitationGroupList from '../components/Invitation/InvitationGroupList';
import InvitationList from '../components/Invitation/InvitationList';
import InvitationUsers from '../components/Invitation/InvitationUsers';
import Invitation from '../components/Invitation/Invitation';

const invitationRoutes = [
    // 邀请函首页
    {path: '', element: <InvitationDashboard/>},
    // 邀请组
    {path: 'groups', element: <InvitationGroupList/>},
    // 邀请组-邀请函列表
    {path: 'g/:gid/invitations', element: <InvitationList/>},
    // 邀请组-邀请函-邀请用户
    {path: 'g/:gid/invitations/:id/users', element: <InvitationUsers/>},
    {path: 'g/:gid/invitations/:id/users', element: <InvitationUsers/>},
    // 邀请组-邀请函-新增
    {path: 'g/:gid/invitations/new', element: <Invitation/>},
    // 邀请组-邀请函-编辑
    {path: 'g/:gid/invitations/:id', element: <Invitation/>},
];

export default invitationRoutes;
