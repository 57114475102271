import React, { useRef } from 'react';
import useChart from '@/hooks/useCharts';
import "@/core/china_encoded"
import { Space } from 'antd';

export default function ViewArea({
    areaData
}) {
    const mapChartRef = useRef(null);
    const option = {
        tooltip: {//提示
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,0.6)',
            formatter: function (params, ticket, callback) {
                var htmlStr = "";
                var color = "#fff";
                htmlStr += `<span style=color:${color}>${params.name}</span>`;
                htmlStr += `<br/>`;
                htmlStr += `<span style=color:${color}>观看次数 ${Number.isNaN(params.value) ? 0 : params.value} (${params.data === undefined ? "0%" : params.data.percentage})</span>`;
                return htmlStr;
            },
            borderColor: 'none'
        },
        visualMap: {
            inRange: {
                color: ['#df2e1e']
            },
            show: false
        },
        series: [
            {
                type: 'map',
                mapType: 'china',
                zoom: 1.1, // 当前视角的缩放比例（地图的放大比例）
                label: {
                    show: true
                },
                itemStyle: { // 地图区域的多边形 图形样式。
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    // areaColor:'#fff'
                },
                emphasis: {
                    itemStyle: {
                        areaColor: "#df2e1e"
                    }
                },
                data: areaData.list,
            }
        ]
    }
    useChart(mapChartRef, option)
    return (
        <Space align="start">
            <div className="china-map" ref={mapChartRef}></div>
            <ul className="all-ul">
                <li>
                    <ul className="row-ul row-ul-first">
                        <li>省份</li>
                        <li>观看次数</li>
                        <li>占比</li>
                    </ul>
                </li>
                {
                    areaData.list.map(item => {
                        return (
                            <li key={item.name}>
                                <ul className="row-ul">
                                    <li>{item.name}</li>
                                    <li>{item.value}</li>
                                    <li>{item.percentage}</li>
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </Space>

    )
}
