import React, { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import {ExclamationCircleOutlined, LeftOutlined, SearchOutlined} from "@ant-design/icons";
import {Upload, Button, Col, Form, Input, Row, Select, Space, Table, Tooltip, message, Modal} from "antd";
import {Content} from "antd/lib/layout/layout";
import {projectStatusList, stageList, userTypeList} from "../constants";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import UserFormModal from "./UserFormModal";
import TemplateImport from "./TemplateImport";
import {useSelector} from "react-redux";
import {getUserList, deleteUser} from '@services/MedConnect';


export default function MedConnectUsers() {

    const [form] = Form.useForm();

    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const projectName = useSelector((state) => state.medconnect.projectName);
    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const [isOpen, setIsOpen] = useState(false); // 新增/修改弹窗
    const [currentData, setCurrentData] = useState({}); // 修改弹窗数据

    const [isVisible, setIsVisible] = useState(false); // 导入弹窗
    const [importData, setImportData] = useState({}); // 导入数据

    const getUserLists = async () => {
        try {
            setLoading(true);
            getUserList({
                ...filter,
                projectId: id,
            }).then(res => {
                if (res && res.success) {
                    setTotal(res.totalCount);
                    setTableData(res.data);
                }
            })
        } catch (e) {
            console.log('error', e);
        } finally {
            setLoading(false);
        }
    }

    // 检索
    const handleFilterFinish = (value) => {
        setFilter({
            ...filter,
            userName: value.userName,
            hospital: value.hospital,
            // department: value.department,
            userType: value.userType,
            jobTitle: value.jobTitle,
            pageIndex: 1,
        })
    }

    // 重置
    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            pageIndex: 1,
        });
    }

    // 刷新
    const onRefresh = () => getUserLists();

    // 新增
    const handleAdd = () => {
        setCurrentData({projectId: id});
        setIsOpen(true);
    }

    // 编辑
    const handleEdit = (record) => {
        setCurrentData({
            ...record,
            projectId: id,
        });
        setIsOpen(true);
    }

    // 移除
    const handleDelete = (id) => {
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否移除？`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                deleteUser(id).then(res => {
                    if (res && res.success) {
                        message.success(res.data || '删除成功');
                        onRefresh();
                    }
                })
            }
        });
    }

    // 新增/编辑 成功
    const handleUpdataForm = () => {
        closeUserForm();
        onRefresh();
    }

    // 关闭弹窗
    const closeUserForm = () => {
        setIsOpen(false);
    }

    // 导入数据
    const handleImport = () => {
        setIsVisible(true);
    }

    // 导入数据完成
    const handleImportOk = () => {
        closeImport();
        onRefresh();
    }

    // 关闭导入 数据确认弹窗
    const closeImport = () => {
        setIsVisible(false);
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        { title: '医生姓名', dataIndex: 'userName', width: 100, },
        { title: '手机号', dataIndex: 'phone', width: 110, },
        { title: '医院名称', dataIndex: 'hospital', width: 120, },
        { title: '科室名称', dataIndex: 'department', width: 120, },
        { title: '职称', dataIndex: 'jobTitle', width: 120, },
        { title: '职务', dataIndex: 'jobPost', width: 120,},
        { title: '类型', dataIndex: 'userTypeDesc', width: 80,},
        { title: '提交问答数量限额', dataIndex: 'qaLimit', width: 140,},
        {
            title: <><div>提交问答数量限额</div><div>（提交/审核通过）</div></>,
            dataIndex: 'numLimit',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>{record.submitQaNum}/{record.passQaNum}</div>
                    </Fragment>
                )
            }
        },
        { title: '审核数量限额', dataIndex: 'auditLimit', width: 120,},
        {
            title: <><div>审核问题</div><div>（已分配/已审核）</div></>,
            dataIndex: 'approveQuest',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>{record.allocateNum}/{record.auditNum}</div>
                    </Fragment>
                )
            },
        },
        {
            title: <><div>劳务费</div><div>（待发放/已发放）</div></>,
            dataIndex: 'service',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>{record.doingFeeNum}/{record.doneFeeNum}</div>
                    </Fragment>
                )
            }
        },
        {
            title: '操作',
            fixed: 'right',
            dataIndex: 'operations',
            width: 110,
            render: (_, record) => {

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleEdit(record)}
                        >
                            编辑
                        </Button>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDelete(record.id)}
                        >
                            移除
                        </Button>
                    </span>
                );
            }
        },
    ];

    useEffect(() => {
        getUserLists();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">人员管理</span>
                <span className="current-subtitle">{projectName}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="人员姓名" name="userName">
                                    <Input allowClear placeholder="输入人员姓名" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="医院名称" name="hospital">
                                    <Input allowClear placeholder="输入医院名称" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="人员类型" name="userType">
                                    <Select allowClear placeholder="全部">
                                        {userTypeList && userTypeList.map(item => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="职称" name="jobTitle">
                                    <Input allowClear placeholder="输入职称" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Space>
                                <Button type="primary" onClick={handleAdd}>新增</Button>
                                <Button type="default" onClick={handleImport}>批量导入</Button>
                            </Space>
                        </div>

                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        onClick={onRefresh}
                                        icon={<>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                                </svg>
                                            </span>
                                        </>}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />

                </div>
            </Content>

            {/* 新增/编辑 */}
            <UserFormModal isOpen={isOpen} handleClose={closeUserForm} currentData={currentData} handleOk={handleUpdataForm} />

            {isVisible ? <TemplateImport isOpen={isVisible} handleClose={closeImport} currentData={importData} handleOk={handleImportOk}/> : null}

        </Fragment>
    )
}

