import React, {useEffect, useState} from 'react';
import {Form, message, Modal, Select, InputNumber, Table, Space, Button, Upload} from "antd";
import {ExclamationCircleOutlined,UploadOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {userImport,handleDownloadFile,uploadfileApi} from '@services/MedConnect';

export default function TemplateImport({isOpen,handleClose,handleOk}) {
    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);
    const [doctorData, setDoctorData] = useState([]);

    const handleImportFile = () => {
        userImport({
            projectId: id,
            ossKey: doctorData.ossKey, // oss 上传地址
        }).then(res => {
            if (res && res.success) {
                message.success(res.data || "数据导入成功")
            } else {
                message.error(res.data || "数据导入失败")
            }

            handleOk && handleOk();
        })
    }

    //excel校验格式
    const fileCheck = (file) => {
        const isExcel = file.type === ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel');
        if (!isExcel) {
            message.error("请上传Excel格式附件");
            setFileList([]);
            setDoctorData([]);
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("请上传10MB以内的附件!");
            setFileList([]);
            setDoctorData([]);
        }
        return isExcel && isLt10M
    }

    const uploadProps = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        onRemove() {
            setFileList([]);
            setDoctorData([]);
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);
            formData.append('projectId', id);
            formData.append('type', 1);
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            uploadfileApi(formData,option).then((res) => {
                if (res && res.success) {
                    const { data = [] } = res || {};
                    setFileList([info.file])
                    setDoctorData(data)
                } else {
                    setFileList([]);
                    setDoctorData([]);
                }
            })
        },
    };

    // 导入模版下载
    const handleImportTemplate = () => {
        handleDownloadFile().then((res) => {
            if (res.success) {
                message.success('请稍后，模板正在下载');
                const fileName = "导入人员模板.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                a.href = res.data;
                a.dispatchEvent(event);
            }
        })
    }

    return (
        <>
            <Modal
                className='mark-import-modal'
                title='导入医生'
                open={isOpen}
                centered
                maskClosable={false}
                onCancel={handleClose}
                onOk={handleImportFile}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleClose}>取消</Button>
                            <Button type="primary" htmlType="submit" disabled={!(fileList && fileList.length)} onClick={handleImportFile}>提交</Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    className="judge-form2"
                    form={form}
                >
                    <Form.Item
                        name="excel"
                        label="导入文件"
                        help="支持Excel格式,文件大小不超过10MB"
                    >
                        <Upload
                            beforeUpload={(file) => fileCheck(file)}
                            {...uploadProps}
                            maxCount={1}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>选择文件</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label={'模板下载'}>
                        <Button type="default" onClick={handleImportTemplate}>导入模版下载</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
