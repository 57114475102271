import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './PrivateLayout.less';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { Layout } from 'antd';

export default function PrivateLayout() {
    const location = useLocation();
    return (
        <Layout>
            <Header />
            <Layout>
                <Sidebar />
                <Layout>
                    <Outlet />
                </Layout>
            </Layout>
        </Layout>
    );
}
