import React, { Fragment, useState } from "react";
import { Button, Space, Modal, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LeftOutlined, DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import phoneHeader from "@static/images/phoneheader.png";
import "./index.less";
import config from "@config";
const PreviewSurvey = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    console.log(location);
    const [cumputer, setCumputer] = useState(true);
    const [mobile, setMobile] = useState(false);
    const [cumputerCover, setCumputerCover] = useState(false);
    const [mobileCover, setMobileCover] = useState(false);
    const changeCumputer = () => {
        setCumputer(true);
        setMobile(false);
    };

    const changeMobile = () => {
        setCumputer(false);
        setMobile(true);
    };

    const goBack = () => {
        navigate("/wj/surveys/new/create", { state: location.state });
    };

    return (
        <Fragment>
            <div className="page_title_wrap">
                <div className="page-left">
                    <div className="back">
                        <LeftOutlined onClick={goBack} />
                    </div>
                    <span>退出预览</span>
                </div>
                <div className="page-center">
                    <div className="preview-action" onClick={changeCumputer} style={cumputer ? { color: "#1a73e8" } : {}}>
                        <DesktopOutlined className="preview-icon" />
                        <p>电脑</p>
                    </div>
                    <div className="preview-action" onClick={changeMobile} style={mobile ? { color: "#1a73e8" } : {}}>
                        <MobileOutlined className="preview-icon" />
                        <p>手机</p>
                    </div>
                </div>
            </div>
            {/* {
                location.state.route===newCreate

            } */}
            {cumputer && (location.state.route === "newList" || location.state.route === "newTemplate") && <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${location.state.id}/answer?type=1`} />}

            {cumputer && location.state.route === "newCreate" && <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${location.state.id}/answer?type=2`} />}

            {mobile && (location.state.route === "newList" || location.state.route === "newTemplate") && (
                <div className="preview-mobile-box">
                    <div className="mobile-final">
                        <img src={phoneHeader} className="phone-header" />
                        <div className="modal-content">
                            <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${location.state.id}/answer?type=1`} />
                        </div>
                    </div>
                </div>
            )}

            {mobile && location.state.route === "newCreate" && (
                <div className="preview-mobile-box">
                    <div className="mobile-final">
                        <img src={phoneHeader} className="phone-header" />
                        <div className="modal-content">
                            <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${location.state.id}/answer?type=2`} />
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default PreviewSurvey;
