import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Layout,
    message,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
    Popover,
} from 'antd';
import { CopyOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { listConferenceUsage, exportConferenceUsage } from '@services/Conference';
import config from '@config';
import { numberProject } from '@utils/utils';
import moment from 'moment';

const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function ConferenceUsage() {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 10,
    });

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        console.log(values);
        let { id, dates } = values;

        // dates = dates && moment(dates).isValid() ? moment(dates).format('YYYY-MM-DD') : undefined;
        let newFilter = { ...filter, ...values };

        if (dates && dates.length) {
            newFilter['beginDate'] = dates[0].format('YYYY-MM-DD');
            newFilter['endDate'] = dates[0].format('YYYY-MM-DD');
        }

        if (!!id) {
            const matchedId = id.match(/(?:0+)?(\d+)/i);
            console.log(matchedId);
            if (matchedId && matchedId.length) {
                // console.log(matchedId[1]);
                newFilter['id'] = matchedId[1];
            } else {
                delete newFilter['id'];
            }
        }

        Object.keys(newFilter).forEach(p => {
            if (newFilter[p] === '' || newFilter[p] === undefined) {
                delete newFilter[p];
            }
        });

        console.log(newFilter);

        setFilter(newFilter);
    };

    const onReset = () => {
        form.resetFields();
        setFilter({ page: 1, pageSize: 10 });
    };

    const onChangePage = (page, pageSize) => {
        setFilter({
            ...filter,
            page,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const copyClick = msg => {
        copy(msg);
        message.success('已复制到剪贴板');
    };

    const columns = [
        {
            title: '氚云编号',
            dataIndex: 'h3yunNo',
            width: 120,
            render: (data) => {
                return !!data
                    ? <span className="conferenctlist-copy-column-span">
                        {data}
                        <span onClick={() => copyClick(data)}><CopyOutlined/></span>
                    </span>
                    : <Typography.Text type="secondary">&ndash;</Typography.Text>
            }
        },
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 300,
            render: (_, record) => {
                return <>
                    <a target='_blank' href={`${config.HUIYI_DOMAIN}/web/${record.slug}`}>{record.name}</a>
                    <br />
                    <Text type="secondary">{numberProject('YHY', record.id)}</Text>
                </>
            }
        },
        {
            title: <>
                <Tooltip title="邀请函接受人数 / 总邀请人数">
                    会议邀请 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            dataIndex: 'invitation',
            width: 90,
            render: (_, row) => !!row?.invitationNum
                ? <span><Text type="success">{row.accepttedInvitationNum || 0}</Text> / {row.invitationNum || 0}</span>
                : <Text type="secondary">&ndash;</Text>

        },
        {
            title: <>
                <Tooltip title="签到人数 / 报名人数">
                    会议签到 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            dataIndex: 'sign',
            width: 90,
            render: (_, row) => !row.enrollNum && !row.signNum
                ? <Text type="secondary">&ndash;</Text>
                : <span><Text type="success">{row.signNum || 0}</Text> / {row.enrollNum || 0}</span>
        },
        {
            title: <>
                <Tooltip title="已确认实际日程人数 / 需确认实际日程总人数">
                    确认实际日程 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            dataIndex: 'confirmAgendas',
            width: 110,
            render: (_, row) => !!row?.agendaToConfirmNum
                ? <span><Text type="success">{row.agendaConfirmedNum || 0}</Text> / {row.agendaToConfirmNum || 0}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: <>
                <Tooltip title="确认收款账号人数 / 需确认收款账号总人数">
                    确认收款账号 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            width: 110,
            render: (_, row) => !!row?.accountToConfirmNum
                ? <span><Text type="success">{row.accountConfirmedNum || 0}</Text> / {row.accountToConfirmNum || 0}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: <>
                <Tooltip title="查看劳务费通知人数 / 劳务费通知总人数">
                    劳务费通知 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            width: 100,
            render: (_, row) => !!row?.laborFeeNoticeNum
                ? <Popover
                    placement="bottom"
                    title=""
                    content={<>
                        <div>实发劳务费审批：{row.laborFeeCount}人</div>
                        <div>含税劳务费审批：{row.laborFeeTaxCount}人</div>
                        <div>劳务费请款审批：{row.laborFeePayCount}人</div>
                    </>}
                >
                    <span><Text type="success">{row.laborFeeNoticeReadNum || 0}</Text> / {row.laborFeeNoticeNum || 0}</span>
                </Popover>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '会议执行日期',
            dataIndex: 'dates',
            width: 200,
            render: (_, record) => record.dates.split(/,/).map((d, i) => <Tag key={i}>{d}</Tag>),
        },
        {
            title: '创建人',
            dataIndex: 'createdBy',
            width: 80,
            render: (data) => {
                return !!data
                    ? <span>
                        {data}
                    </span>
                    : <Text type="secondary">&ndash;</Text>
            }
        },
    ];

    const onExportConferenceUsageData = async () => {
        message.success('请稍后，正在导出');

        let params = {...filter};
        delete params['page'];
        delete params['pageSize'];

        const res = await exportConferenceUsage(params);
        let blob = new Blob([res], { type: 'application/octet-stream' });

        if ('download' in document.createElement('a')) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.download = `医会议应用数据统计_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
        } else {
            navigator.msSaveBlob(blob);
        }
    };

    const getConferenceUsageData = async () => {
        try {
            setLoading(true);

            const res = await listConferenceUsage(filter);
            if (res.status_code === 200) {
                setTableData(res.data.list);
                setTotal(res.data.totalCount);
                // console.log(res.data);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const onRefresh = () => getConferenceUsageData(filter);

    useEffect(() => {
        getConferenceUsageData(filter);
    }, [filter]);

    return (<>
        <div className="page-title">
            <span className="current-title">会议应用统计</span>
        </div>

        <Content className="main-content-box">

            <div className="filter-form-box">
                <Form
                    {...filterFormItemLayout}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="项目名称" name="name">
                                <Input allowClear placeholder="会议项目名称关键字"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="项目编号" name="id">
                                <Input allowClear placeholder="会议项目编号或ID"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="执行日期" name="dates" className="conferencelist_date">
                                <RangePicker style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="氚云编号" name="h3yunNo">
                                <Input allowClear placeholder="氚云项目编号"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="创建人" name="createdBy">
                                <Input allowClear placeholder="项目创建人"/>
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="main-content-body">
                <div className="main-toolbar">
                    <div className="main-tools">
                        <Button onClick={() => onExportConferenceUsageData()}>导出数据</Button>
                    </div>
                    <div className="sub-tools">
                        <Tooltip title="刷新列表">
                            <Button type="default" icon={<ReloadOutlined/>} onClick={onRefresh}/>
                        </Tooltip>
                    </div>
                </div>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                    scroll={{ x: 1300 }}
                />
            </div>
        </Content>
    </>);
}
