import Reac, { Fragment, useState, useEffect, forwardRef, useRef } from 'react';
import { Button, Layout, Input, DatePicker, Form, Table, Space, Modal,Select } from 'antd';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import ExportJsonExcel from 'js-export-excel';
import './index.less';
import qs from 'qs';
import moment from 'moment'
import { answerSheetsApi, answerDelApi,downloadAnswerApi } from '@services/Wj/Question';
import { secondsToText } from '@utils/utils';
import { getAllChannelApi } from "@services/Wj";
const { RangePicker } = DatePicker;
const date = new Date()
const DataTable = ({ type ,choice,filObj,setFilObj}, ref) => {
    const [form] = Form.useForm();
    const [timeError, setTimeError] = useState(false);
    const [selectionType, setSelectionType] = useState('checkbox');
    const [tableData, setTableData] = useState([])
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [delMore, setDelMore] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [opinionItem,setOpinionItem] = useState([]);
    // const [opinionName, setOpinionName] = useState(undefined);
    const location = useLocation()
    const navigate = useNavigate()
    const isSearchRef = useRef(null);
    const onSearch = () => {
        if (Number(form.getFieldsValue().durationBegin) > Number(form.getFieldsValue().durationEnd)) {
            setTimeError(true)
        } else {
            setTimeError(false)
            isSearchRef.current = true;
            let startChoicetime, endChoicetime
            if (form.getFieldsValue()?.choiceTime === undefined || form.getFieldsValue()?.choiceTime ==='') {
                startChoicetime = ''
                endChoicetime = ''
            } else {
                startChoicetime = moment(form.getFieldsValue()?.choiceTime[0]).format('YYYY-MM-DD');
                endChoicetime = moment(form.getFieldsValue()?.choiceTime[1]).format('YYYY-MM-DD');
            }
            setPage(1)
            setFilObj({
                phone: form.getFieldsValue()?.mobile,
                durationBegin: form.getFieldsValue()?.durationBegin,
                durationEnd: form.getFieldsValue()?.durationEnd,
                submittedBegin: startChoicetime,
                submittedEnd: endChoicetime,
                channelId: form.getFieldsValue()?.channelId
            })
        }

    }
    const rowSelection = {
        onChange: (_, selectedRows) => {
            let res = selectedRows.map(item => {
                return item.id
            })

            setDelMore([...res])

        },
        getCheckboxProps: (record) => ({
            phone: record.id,
        }),
    };



    const check=(row)=>{
        navigate(`/wj/surveys/${location.state.id}/checkdetails`, { state: {...location.state,answerId:row.id} })
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            align: 'center',
            width:100
        },
        {
            title: '手机号',
            dataIndex: 'memMobile',
            align: 'center',
            width:300,
            render: (memMobile, row) => {

                return <>
                 {memMobile?
                        <span>{memMobile}</span>
                        : <span>--</span>}
                </>



            },

        },
        {
            title: '答题时间',
            dataIndex: 'duration',
            align: 'center',
            width:200,
            render: (duration, row) => {

                let text = secondsToText(duration)
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '开始答题时间',
            dataIndex: 'startedAt',
            align: 'center',
            width:300
        },
        {
            title: '结束答题时间',
            dataIndex: 'submittedAt',
            align: 'center',
            width:300
        },
        {
            title: '渠道名称',
            dataIndex: 'channelName',
            align: 'center',
            width:300,
            render:(_,row)  => (
                <span>{row.channelName||'默认渠道'}</span>
            )

        },
        {
            title: '操作',
            key: 'action',
            width:300,
            render: (_, row) => (
                <Fragment>
                    <Button type="link" className='setting-button' onClick={()=>check(row)}>查看</Button>
                    {type === 1 && <Button type="link" danger className='setting-button' onClick={() => delItem(row.id,'del')}>删除</Button>}
                    {type === 2 && <Button type="link" className='setting-button' onClick={() => delItem(row.id,'restore')}>恢复</Button>}
                </Fragment>
            ),
        },
    ];




    const onReset = () => {
        form.resetFields()
        setTimeError(false)
        setFilObj({
            phone: '',
            durationBegin: '',
            durationEnd: '',
            submittedBegin: '',
            submittedEnd: ''
        })
    }

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const delItem = (id,model) => {
        const contenttext = model==='del'?`确认删除本条记录么`:`确认恢复本条记录么`

        Modal.confirm({
            title: '提示',
            content:contenttext ,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                answerDelApi({ ids: id }).then((res) => {
                                setPage(1)
                })
        }
        })
    }

    const delSome = (model) => {
        console.log(JSON.stringify(delMore))
        const contenttext = model==='del'?`确认删除本条记录么`:`确认恢复本条记录么`
        Modal.confirm({
            title: '提示',
            content:contenttext ,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                answerDelApi({ ids: delMore },
                    {
                        paramsSerializer: params => {
                            return qs.stringify(params.ids, { indices: false })
                        }
                    }

                ).then((res) => {
                    if (res.status_code === 200) {
                        setPage(1);
                        setPageSize(10);
                        getData(1, 10, type)

                    }
                })
         }
        })

    }



    const getData =  (page, pageSize, type, phone, durationBegin, durationEnd, submittedBegin, submittedEnd,channelId) => {
        isSearchRef.current = false;
        let obj={
            surveyId: location.state.id,
            page,
            limit: pageSize,
            type,
            phone,
            durationBegin,
            durationEnd,
            submittedBegin,
            submittedEnd,
            channelId
        }
        Object.keys(obj).forEach(
            item=>{
                if (!obj[item]) {
                    delete obj[item];
                }
            }
        )
        // setIsShow(false)
        answerSheetsApi(obj).then(
            (res) => {
                if (res.status_code === 200) {

                    let resArr = res.data?.list.map(item => {
                        return ({
                            ...item,
                            key: item.id
                        })
                    })
                    setTableData(resArr.slice())
                    setTotal(res.data.totalCount);
                }
            }
        )
        }


    const downloadFileToExcel=({phone, durationBegin, durationEnd, submittedBegin, submittedEnd,channelId})=> {
        let obj={
        phone,
        durationBegin,
        durationEnd,
        submittedBegin,
        submittedEnd,
        channelId
        }
        Object.keys(obj).forEach(
            item=>{
                if (!obj[item]) {
                    delete obj[item];
                }
            }
        )
        console.log(obj);
        downloadAnswerApi({
            page:1,
            limit:total,
            surveyId: location.state.id,
            ...obj
        },{responseType: "blob"}).then((res)=>{
            let content = res;
            let blob = new Blob([content], { type: "application/octet-stream" });
            const date=new Date()
            const showTime=moment(date).format("YYYYMMDDTHHmmss").toString();
            console.log(showTime);
            if ("download" in document.createElement("a")) {
                const link = document.createElement("a");
                link.download = `${location.state.id}_${location.state.name}_${showTime}.xlsx`;

                link.href = URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
                } else {
                navigator.msSaveBlob(blob);
                }
            })

        }

    useEffect(() => {
        getAllChannelApi({page:1,limit:99999}).then(res=>{
            if(res.status_code===200){
                let obj=[{
                    value: '',
                    label:'全部'
                },{
                    value: '0',
                    label:'默认渠道'
                }]
                res.data.list&&res.data.list.map(item=>{
                    obj.push(
                        {
                            value: item.id,
                            label:item.title
                        }
                    )

                })
                setOpinionItem([...obj])
            }
        })
    }, []);

    useEffect(() => {
        console.log(page);
        const { phone, durationBegin, durationEnd, submittedBegin, submittedEnd,channelId } = filObj
        if (isSearchRef.current === true) {
            getData(1, pageSize, type, phone, durationBegin, durationEnd, submittedBegin, submittedEnd,channelId)
        } else {
            getData(page, pageSize, type, phone, durationBegin, durationEnd, submittedBegin, submittedEnd,channelId)
        }
    }, [page, pageSize, filObj,type,choice]);

    useEffect(()=>{
        console.log(filObj.phone);
        form.setFieldValue('channelId','');
        form.setFieldValue('mobile','');
        form.setFieldValue('durationBegin', '');
        form.setFieldValue('durationEnd', '');
        form.setFieldValue('choiceTime','')
    },[choice])

    return (
        <div>
            <div className='data-tabs'>
                <Form
                    layout="inline"
                    form={form}
                >
                    <Form.Item name="mobile" label="手机号">
                        <Input placeholder="请输入手机号" />
                    </Form.Item>
                    <div className='time-box'>
                    <Form.Item name="durationBegin" label="答题时间">
                        <Input className='time-area' placeholder="请输入" width={100} />
                    </Form.Item>
                    <span className='to-time'>至</span>
                    <Form.Item name="durationEnd">
                        <Input className='time-area' placeholder="请输入" />
                    </Form.Item>
                    <span className='to-time-last'>秒</span>
                    <div className='error-time' style={{ opacity: timeError ? 1 : 0 }}>答题时间后面的数字需大于等于前面的数字</div>
                    </div>
                    <Form.Item name="choiceTime" label="选择时间">
                        <RangePicker  format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item name='channelId' label="渠道名称" className='channel-slect'>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={opinionItem}
                        width={200}

                    />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" onClick={onSearch}>
                                查询
                            </Button>
                            <Button htmlType="button" onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item>

                    </Form.Item>
                </Form>

            </div>

            <div className='interval'>
            </div>

            <div>

                {type === 1 &&
                    <div className='detail-btn-area'>
                        <Button danger onClick={()=>delSome('del')}>删除</Button>
                        <Button onClick={()=>downloadFileToExcel(filObj)} >导出</Button>
                    </div>

                }
                {type === 2 &&
                    <div className='detail-btn-area'>
                        <Button type="primary" className='' onClick={()=>delSome('restore')}>恢复</Button>
                    </div>

                }

                <Table
                    className='details-table'
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    rowKey={(row) => row.id}
                    pagination={{
                        current: page,
                        total: total,
                        showTotal: () => `共${total}条`,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                    columns={columns}
                    dataSource={tableData}
                />

            </div>
        </div>




    );
}

export default forwardRef(DataTable);
