export const projectTypes = [
    { code: 'conference', noPrefix: 'YHY', label: '医会议-会议项目' },
    { code: 'selection', noPrefix: 'YPX', label: '医评选-评选项目' },
    { code: 'invitation', noPrefix: 'YYQ', label: '医邀请-邀请组' },
    { code: 'survey', noPrefix: 'YDY', label: '医调研-调研问卷' },
];


// 医项目状态：
// 1已上架
// 2已下架
export const projectStatusList = [
    { status: 1, label: '已上架', type: 'success' },
    { status: 2, label: '已下架', type: 'danger' }
];

export const contentTypes = [
    { type: 'article', label: '平台文章' },
    { type: 'article_wm', label: '用户文章' },
    { type: 'case', label: '案例' },
    { type: 'live', label: '直播' },
    { type: 'course', label: '课程' },
    { type: 'solution', label: '解决方案' },
    { type: 'conference_live', label: '直播' },
]
