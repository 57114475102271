import moment from "moment";

export const handleForm2Params = (form) => {
    const params = {
        startedAt: form.time[0].format("YYYY-MM-DD HH:mm"), // 评分起止时间
        endedAt: form.time[1].format("YYYY-MM-DD HH:mm"),
        notification: { rule: form.rule, message: form.message }, // 评分提醒 0不提醒，1每次进入提醒，2仅提醒一次（Cookie记录）
        caseFileViewable: form.caseFileViewable, // 查看案例文件：0不可查看，1可查看
        unitAvailable: form.unitAvailable, // 是否展示案例提报单位
        minScore: form.minScore, // 案例起评分
        decimalPlaceNum: form.decimalPlaceNum, // 案例评分小数位数
        commentEnabled: form.commentEnabled, // 案例评语：0不启用，1启用必填，2启用非必填
        scoreChangingTimes: form.scoreChangingTimes === 0 || form.scoreChangingTimes === -1 ? form.scoreChangingTimes : form.editLimit, // 修改评分：0不可修改，-1不限制修改次数，1以上的整数次
        scoreMode: form.scoreMode, // 打分方式：1按评分维度分别打分，2仅给出总分
        dimensionEnabled: form.dimensionEnabled, // 启用评分维度
        dimensions: form.dimensions, // 评分维度
        scoreAverageType: form.scoreAverageType, // 单个案例平均分计算方式：1单个案例全部评委总分/单个案例全部评委数量，2去掉单个案例最高分和最低分后剩余评委总分/剩余评委数量
        caseFinalScoreDecimalPlaceNum: form.caseFinalScoreDecimalPlaceNum, // 单个案例总分小数位数
        rankForm: form.rankForm, // 榜单展示形式：1按分数排名展示，按奖项名称分组展示
        sameRankAddtionEnabled: form.sameRankAddtionEnabled, // （开启）同分排名进位
    };
    if (form.rankForm === 2) {
        params.awards = form.awards;
    }
    return params;
};

export const handleState2Form = (state) => {
    if (!state) return {};
    const form = {
        time: [moment(state.startedAt), moment(state.endedAt)],
        rule: state.notification.rule,
        message: state.notification.message,
        caseFileViewable: +state.caseFileViewable,
        unitAvailable: +state.unitAvailable,
        minScore: state.minScore,
        decimalPlaceNum: state.decimalPlaceNum,
        commentEnabled: state.commentEnabled,
        scoreChangingTimes: state.scoreChangingTimes === 0 || state.scoreChangingTimes === -1 ? state.scoreChangingTimes : 1,
        scoreMode: state.scoreMode,
        dimensionEnabled: +state.dimensionEnabled,
        dimensions: state.dimensions,
        scoreAverageType: state.scoreAverageType,
        caseFinalScoreDecimalPlaceNum: state.caseFinalScoreDecimalPlaceNum,
        rankForm: state.rankForm,
        awards: state.awards,
        sameRankAddtionEnabled: state.sameRankAddtionEnabled
    };
    if (state.scoreChangingTimes >= 1) {
        form.editLimit = state.scoreChangingTimes;
    }

    return form;
};
