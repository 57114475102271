import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Layout, Table, Tabs, message } from 'antd';
import UserStatisticsFilterForm from './UserStatisticsFilterForm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { get } from '@/core/request';
import './UserStatistics.less'
const { TabPane } = Tabs;

const { Content } = Layout;

export default function UserStatistics() {

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);
    const [currPageReplay, setCurrPageReplay] = useState(1);

    // 改变一页有多少数据
    const changePageSize = (pageSize) => {
        setPageSize(pageSize)
    }

    // Live改变第几页
    const changeCurrPage = (page) => {
        setCurrPage(page);
    }

    const changeCurrPageReplay = (page) => {
        setCurrPageReplay(page);
    }


    const { name, phone, platform, viewStartTime, viewEndTime } = filterObj;

    const getData = () => {
        get(`/dms/live/data/user/live`, {
            limit: pageSize,
            page: currPage,
            liveId: state.liveId,
            name: name,
            phone: phone,
            platform: platform,
            viewStartTime: viewStartTime,
            viewEndTime: viewEndTime
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        });
    };

    const getReplayData = () => {
        get(`/dms/live/data/user/replays`, {
            limit: pageSize,
            page: currPageReplay,
            liveId: state.liveId,
            name: name,
            phone: phone,
            platform: platform,
            viewStartTime: viewStartTime,
            viewEndTime: viewEndTime
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        });
    }

    // 点击直播回放
    const handleChangeIP = (key) => {
        if (key === 'live') {
            navigate(`/dms/lives/${state.liveId}/data/user/live`, { state })
            getData()
        } else {
            navigate(`/dms/lives/${state.liveId}/data/user/replays`, { state })
            getReplayData()
        }
    }

    // 渲染table数据
    useEffect(() => {
        if (pathname.includes('/user/live')) {
            getData()
        }
    }, [pageSize, currPage, name, phone, platform, viewStartTime, viewEndTime]);

    // 渲染Replay table数据
    useEffect(() => {
        if (pathname.includes('/user/replays')) {
            getReplayData()
        }
    }, [pageSize, currPageReplay, name, phone, platform, viewStartTime, viewEndTime]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const paginationPropsReplay = {
        showTotal: () => `共${tabledata.totalCount}条记录`,
        showSizeChanger: true,
        pageSize: pageSize,
        pageSizeOptions: ['10', '20', '50', '100', '1000'],
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPageReplay(page) }
    };

    const columns = [
        { title: '用户ID', dataIndex: 'id', key: 'id' },
        { title: '姓名', dataIndex: 'name', },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '所在省份', dataIndex: 'province', },
        { title: '城市', dataIndex: 'city', },
        { title: '所在医院', dataIndex: 'hospital', },
        { title: '职称', dataIndex: 'title', },
        { title: '科室', dataIndex: 'tagTitle', },
        { title: '最近观看时间', dataIndex: 'viewStartTime', },
        {
            title: '累计观看时长(分)', dataIndex: 'totalViewTime',
            render: (operates, row) => (
                <Fragment>
                    <span>{Math.floor(operates / 3600) === 0 ? '' : `${Math.floor(operates / 3600)}时`}{Math.floor(Math.floor(operates % 3600) / 60) === 0 ? '' : `${Math.floor(Math.floor(operates % 3600) / 60)}分`}{operates % 60 === 0 ? '' : `${operates % 60}秒`}</span>
                </Fragment>
            )
        },
        { title: '观看终端', dataIndex: 'platform', },
        { title: 'IP地址', dataIndex: 'ip', },
    ];


    // 导出数据
    const handleExportFile = () => {
        if (pathname.includes('replays')) {
            get(`/dms/live/data/user/replays/download`, {
                limit: pageSize,
                page: currPage,
                liveId: state.liveId,
                name: name,
                phone: phone,
                platform: platform,
                viewStartTime: viewStartTime,
                viewEndTime: viewEndTime
            }, { responseType: 'blob' }).then((res) => {
                if (res.size !== 0) {
                    message.success('请稍后，正在导出');
                    const fileName = "回放用户统计.xlsx";
                    let a = document.createElement('a');
                    let event = new MouseEvent('click');
                    a.download = fileName;
                    let objectUrl = URL.createObjectURL(res);
                    a.href = objectUrl;
                    a.dispatchEvent(event);
                    URL.revokeObjectURL(objectUrl);
                } else {
                    message.warning('当前列表没有数据，无法导出')
                }
            })
        } else {
            get(`/dms/live/data/user/live/download`, {
                limit: pageSize,
                page: currPageReplay,
                liveId: state.liveId,
                name: name,
                phone: phone,
                platform: platform,
                viewStartTime: viewStartTime,
                viewEndTime: viewEndTime
            }, { responseType: 'blob' }).then((res) => {
                if (res.size !== 0) {
                    message.success('请稍后，正在导出');
                    const fileName = "直播用户统计.xlsx";
                    let a = document.createElement('a');
                    let event = new MouseEvent('click');
                    a.download = fileName;
                    let objectUrl = URL.createObjectURL(res);
                    a.href = objectUrl;
                    a.dispatchEvent(event);
                    URL.revokeObjectURL(objectUrl);
                } else {
                    message.warning('当前列表没有数据，无法导出')
                }
            })
        }
    };

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/dms/lives" className="back"><LeftOutlined /></Link>用户统计<span className="page-title-tip">{state.name} [#{state.liveId}]</span></div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <UserStatisticsFilterForm
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="live-table-title">
                            <Tabs onChange={handleChangeIP} className="live-tab" activeKey={pathname.includes('replays') ? 'replay' : 'live'}>
                                <TabPane tab="直播" key="live"></TabPane>
                                <TabPane tab="回放" key="replay"></TabPane>
                            </Tabs>

                            <Button className="live-post-data" type="primary" onClick={handleExportFile}>导出数据</Button>
                        </div>
                    </div>

                    {/* {pathname.includes('live')} */}

                    <Table className="user-table"
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={pathname.includes('/user/live') ? paginationProps : paginationPropsReplay}
                    />

                </div>
            </Content>
        </Fragment>
    )
}
