import React, { Fragment, useEffect, useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { Form, Row, Col, Input, Button, Space, Select, Table } from 'antd';

export default function LeftTable({
    tableData, paginationProps, handleFilterFinish, selectedRowKeys, setSelectedRowKeys, setSelectedRows, page, delData
}) {

    const [form] = Form.useForm();

    const [doubleArr, setDoubleArr] = useState([])

    const onFinish = (values) => {
        handleFilterFinish(values)
    };
    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            phone: undefined,
            gender: undefined
        }
        handleFilterFinish(values);
    };

    // 二维数组
    const mapRows = (params) => {
        var res = [];
        for (var i = 0; i < params.length; i++) {
            if (Array.isArray(params[i])) {
                res = res.concat(mapRows(params[i]));
            } else {
                res.push(params[i])
            }
        }
        return res.filter(Boolean);   //去掉undefined的情况
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            doubleArr[page ? page - 1 : 0] = selectedRows;
            let filteredArr = mapRows(doubleArr);
            if (delData.length !== 0) {
                for (let i = filteredArr.length - 1; i >= 0; i--) {
                    delData.forEach(item => {
                        if (filteredArr[i]) {
                            if (item === filteredArr[i].id) {
                                filteredArr.splice(i, 1)
                            }
                        }
                        selectedRows.forEach(elem => {
                            if (elem.id === item) {
                                if (!filteredArr.includes(elem)) {
                                    filteredArr.push(elem);
                                }
                            }
                        })
                    })
                };
            }
            let keysArr = [];
            filteredArr.forEach(item => {
                keysArr.push(item.id)
            })
            selectedRowKeys = keysArr;
            setSelectedRows(filteredArr);
            setSelectedRowKeys(selectedRowKeys);
        },
        // getCheckboxProps: (record) => {
        //     const isDisabled = selectedRowKeys.some(item => item === record.id)
        //     return ({
        //         disabled: isDisabled
        //     })
        // },
    };

    const columns = [
        { title: '姓名', dataIndex: 'name' },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '医院名称', dataIndex: 'hospital', },
        { title: '所在科室', dataIndex: 'tagTitle', }
    ];

    return (
        <Fragment>
            <div className="surveys-add-filter">
                <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="姓名" name="name">
                                <Input allowClear placeholder="输入问卷名称" autoComplete="off" />
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="性别" name="gender">
                                <Select allowClear placeholder="选择性别" >
                                    <Select.Option value={1}>男</Select.Option>
                                    <Select.Option value={2}>女</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="手机号" name="phone">
                                <Input allowClear placeholder="请输入手机号" autoComplete="off" />
                            </Form.Item>
                        </Col>

                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" htmlType="submit">检索</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>
            <Table className="surveys-add-table-left"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData.list}
                pagination={paginationProps}
            />
        </Fragment>
    )
}
