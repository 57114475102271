import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Layout, Table, Button, Modal, Input, Select, InputNumber, Pagination, Switch, Form, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import config from '@config';
import AddGuest from "../components/AddGuest";
import { fetchCasescoringassignedjudgesList, updateBanned, updateCasescoringassignedjudgesFinalScore, deleteCasescoringassignedjudges, getCaseJudgesList, assignedJudgeToCase } from "@services/Selection";

import "./index.less";

const { Content } = Layout;

const isScoreSelectOption = [
    {
        value: "0",
        label: "全部",
    },
    {
        value: "1",
        label: "已评分",
    },
    {
        value: "2",
        label: "未评分",
    },
];
const isShieldSelectOption = [
    {
        value: "0",
        label: "全部",
    },
    {
        value: "1",
        label: "已屏蔽",
    },
    {
        value: "2",
        label: "未屏蔽",
    },
];

const ScoreStageTopicList = () => {
    const params = useParams();
    const navigate = useNavigate();
    const local = useLocation();

    const [fractionModal, setFractionModal] = useState(false);
    const [finallyScore, setFinallyScore] = useState({ caseid: "", score: 0 });
    const [inputScore, setInputScore] = useState("");

    const [form] = Form.useForm();
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState({ list: [], totalCount: 0 });
    const [paginat, setPaginat] = useState({ page: 1, size: 10 });

    const [visibleAddUserDrawer, setVisibleAddUserDrawer] = useState(false);

    // 添加评委
    const closeAddUserDrawer = () => {
        setVisibleAddUserDrawer(false);
    };

    const onResetGuest = () => {
        setPaginat((d) => ({ ...d }));
    };

    const getJudgesList = (param) => {
        return getCaseJudgesList(param).then((res) => {
            return { list: res?.data?.list.map((i) => ({ ...i.ssoMemberExpertsVo, ...i })), totalCount: res.data.totalCount };
        });
    };

    const addJudges = (param) => {
        assignedJudgeToCase(param).then((res) => {
            if (res?.status_code === 200) {
                message.success("添加成功");
                setPaginat((draft) => ({ page: 1, size: draft.size }));
                setVisibleAddUserDrawer(false);
            }
        });
    };

    const fetchList = useCallback(
        ({ page, size }) => {
            setTableLoading(true);
            const formData = form.getFieldsValue();
            fetchCasescoringassignedjudgesList({ projectId: params.pid, stageId: params.sid, topicId: params.tid, caseId: params.id, ...formData, page, size })
                .then((res) => {
                    if (res?.status_code === 200) {
                        setDataSource({ list: res.data?.list.map((i, index) => ({ ...i, index })), totalCount: res.data.totalCount });
                    }
                })
                .finally(() => {
                    setTableLoading(false);
                });
        },
        [form, params]
    );

    useEffect(() => {
        fetchList(paginat);
    }, [fetchList, paginat]);

    const onDataSourcePagination = (page, size) => {
        setPaginat({ page, size });
    };

    const handleChangeSwitch = (value, id) => {
        updateBanned({ banned: value ? 1 : 0, id }).then((res) => {
            if (res?.status_code === 200) {
                message.success("修改成功！");
                setPaginat((d) => ({ ...d }));
            }
        });
    };

    const deleteJudges = (id) => {
        deleteCasescoringassignedjudges({ id }).then((res) => {
            if (res?.status_code === 200) {
                message.success("移除评委成功！");
                setPaginat((d) => ({ ...d }));
            }
        });
    };

    const handleReleaseClick = () => {
        // message.success("敬请期待！");
        window.open(`${config.SELECTION_DOMAIN}/rank/topics?caseId=${params.id}&stageId=${params.sid}&topicId=${params.tid}`);
    };

    const columns = [
        {
            title: "评委",
            dataIndex: "username",
            align: "center",
        },
        {
            title: "评委单位",
            dataIndex: "hospitalName",
            align: "center",
            render: (row) => row ?? "-",
        },
        {
            title: "手机号",
            dataIndex: "mobile",
            align: "center",
        },
        {
            title: "评委评分",
            dataIndex: "judgeScore",
            align: "center",
            render: (row) => row ?? "-",
        },
        {
            title: "评委最终分数",
            dataIndex: "finalScore",
            align: "center",
            render: (row, data) => {
                return (
                    <span
                        className="lasttime-span"
                        onClick={() => {
                            setFractionModal(true);
                            setFinallyScore({ score: data.finalScore, id: data.id });
                        }}
                    >
                        {row ?? "-"}
                    </span>
                );
            },
        },
        {
            title: "评委分数屏蔽",
            dataIndex: "banned",
            align: "center",
            render: (row, data) => {
                return (
                    <React.Fragment>
                        <Switch checkedChildren="屏蔽" unCheckedChildren="不屏蔽" checked={!!row} onChange={(e) => handleChangeSwitch(e, data.id)} />
                    </React.Fragment>
                );
            },
        },
        {
            title: "操作",
            dataIndex: "id",
            align: "center",
            render: (row) => {
                return (
                    <React.Fragment>
                        <Button type="link" onClick={() => deleteJudges(row)}>
                            移除评委
                        </Button>
                    </React.Fragment>
                );
            },
        },
    ];

    const header = useMemo(() => {
        const searching = () => {
            setPaginat((draft) => ({ page: 1, size: draft.size }));
        };

        const resetSelect = () => {
            setPaginat((draft) => ({ page: 1, size: draft.size }));
            form.resetFields();
        };

        return (
            <>
                <div className="cases-table-header">
                    <Form form={form} name="form" layout="inline">
                        <Form.Item name="judgeName" label="评委：">
                            <Input placeholder="姓名" style={{ width: 140 }} />
                        </Form.Item>
                        <Form.Item name="mobile" label="手机号">
                            <Input placeholder="手机号" style={{ width: 140 }} />
                        </Form.Item>
                        <Form.Item name="scoring" label="是否评分">
                            <Select style={{ width: 140 }} options={isScoreSelectOption} />
                        </Form.Item>
                        <Form.Item name="banned" label="是否屏蔽评分">
                            <Select style={{ width: 140 }} options={isShieldSelectOption} />
                        </Form.Item>

                        <Button onClick={resetSelect}>重置</Button>
                        <Button type="primary" onClick={searching} className="assignment-buttons">
                            检索
                        </Button>
                    </Form>
                </div>
                <div className="cases-table-header-button">
                    <Button type="primary" onClick={handleReleaseClick}>
                        案例评分榜单
                    </Button>
                    <Button type="primary" onClick={() => setVisibleAddUserDrawer(true)}>
                        添加评委
                    </Button>
                </div>
            </>
        );
    }, [form]);

    const submitInputScore = () => {
        if (!inputScore) {
            return setFractionModal(false);
        }

        updateCasescoringassignedjudgesFinalScore({ id: finallyScore.id, finalScore: inputScore }).then((res) => {
            if (res?.status_code === 200) {
                message.success("修改成功！");
                setFractionModal(false);
                setPaginat((d) => ({ ...d }));
            }
        });
    };

    return (
        <>
            <div className="page-title">
                <span className="back" onClick={() => navigate(-1)}>
                    <LeftOutlined />
                </span>
                <span className="current-title">评委评分管理</span>
            </div>

            <Content className="main-content-box cases-table-content">
                <Table dataSource={dataSource.list} columns={columns} title={() => header} rowKey="index" pagination={false} loading={tableLoading} />
                <Pagination className="my-pagintation-class" showTotal={() => `共${dataSource.totalCount}条 ${Math.ceil(dataSource.totalCount / paginat.size)} 页`} total={dataSource.totalCount} showSizeChanger showQuickJumper onChange={onDataSourcePagination} current={paginat.page} size={paginat.size} />
            </Content>

            <Modal
                centered
                width={400}
                title="修改评委评分"
                open={fractionModal}
                onOk={submitInputScore}
                onCancel={() => {
                    setFractionModal(false);
                }}
                afterClose={() => setInputScore("")}
            >
                <div className="modal-fraction-score">评委评分：{finallyScore.score === 0 || finallyScore.score ? `${finallyScore.score}分` : "无"}</div>
                <div className="modal-flex-div">
                    <span>修改评委最终分：</span>
                    <InputNumber value={inputScore} onChange={setInputScore} className="flex-span-3" min={0} max={100} precision={local?.state?.decimalPlaceNum ?? 2} style={{ width: 160 }} />
                </div>
            </Modal>

            <AddGuest visibleAddUserDrawer={visibleAddUserDrawer} closeAddUserDrawer={closeAddUserDrawer} setVisibleAddUserDrawer={setVisibleAddUserDrawer} onResetGuest={onResetGuest} sid={params.sid} getJudgesList={getJudgesList} addJudges={addJudges} currentTopics={[]} />
        </>
    );
};

export default ScoreStageTopicList;
