import { get, put, post, delet, patch } from '@core/request';

/*
    接口服务--调研组件
*/

// 获取题库设置列表
export const getQuestionBankListApi = (params) => {
    return get('/survey/questioncates/list', params);
};

// 添加题型题目分类
export const addQuestionBankApi = (params) => {
    return post('/survey/questioncates/save', params);
};

// 编辑题型题目分类
export const updateQuestionBankApi = (params) => {
    return put('/survey/questioncates/update', params);
};

// 删除题型题目分类
export const delQuestionBankApi = (params) => {
    return delet('/survey/questioncates/delete', params);
};

//  /survey/questioncates/edit-sort题型排序
export const patchQuestionBankApi = (params) => {
    return patch('/survey/questioncates/edit-sort', params);
};

// 添加题目
export const addQuestionApi = (params) => {
    return post('/survey/samplequestions/save', params);
};

// 编辑题目
export const updateQuestionApi = (params) => {
    return put('/survey/samplequestions/update', params);
};

// 删除题目
export const delQuestionApi = (params) => {
    return delet('/survey/samplequestions/delete', params);
};

// 题目排序
export const patchQuestionApi = (params) => {
    return patch('/survey/samplequestions/edit-sort', params);
};


export const uploadFile = (params, option) => {
    return post('/survey/file', params, option);
};


//导出作答详情
export const getLogicQuestionApi = (params) => {
    return get('/survey/surveyquestions/list', params)
}

//保存逻辑信息
export const saveLogicsApi = (params) => {
    return post('/survey/logics', params);
};
//删除逻辑信息
export const delLogicsApi = (params) => {
    return delet('/survey/logics', params);
};
//更新逻辑信息
export const updateLogicsApi = (params) => {
    return put('/survey/logics', params);
};


//问卷列表逻辑查询
export const getAllLogicApi = (params) => {
    return get('/survey/logics/list', params)
}


export const getAllChannelApi = (params) => {
    return get('/channel/channel/page', params)
}
