import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Layout,
    message,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
    Switch,
    InputNumber,
    Popover
} from 'antd';
import { filterFormItemLayout } from '@utils/property';
import { CopyOutlined, EllipsisOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { filterFormGridLayout } from '@utils/property';
import { projectActions} from '../store/projectSlice';
import { moduleListApi, fetchMedProjects, changeMedProjectStatus } from '@services/MedProject';
import { convertDateTimeToLocalText, numberProject } from '@utils/utils';
import copy from 'copy-to-clipboard';
import { projectStatusList } from '../constants';
import config from '@config';

const { Content } = Layout;
const { Text } = Typography;

const { setProjectFilter: setFilter, setProjectCursor: setCursor } = projectActions;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `排序值不能为空`,
          },
        ]}
      >
        <InputNumber ref={inputRef} min={0} max={9999} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default function MedProjects() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.project.project.filter);
    const cursor = useSelector(state => state.project.project.cursor);

    const [form] = Form.useForm();

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const getProjects = async () => {
        let params = { ...filter };

        try {
            setLoading(true);

            let res = await fetchMedProjects(params);
            if (res.status_code === 200) {
                setTotal(res.data.totalCount);
                setTableData(res.data.list);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const onFinish = values => {
        let idString = values.id;
        if (!!idString && /^\s*[a-z]+\d+\s*$/i.test(idString)) {
            idString = idString.replace(/^\s*[a-z]+0+([1-9]\d*)\s*$/i, '$1');
        }

        dispatch(setFilter({
            ...filter,
            id: idString,
            name: values.name,
            page: 1,
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const onRefresh = () => getProjects();

    const copyProjectNo = projectNo => {
        copy(projectNo);
        message.success('已复制到剪贴板');
    };

    const onChangeProjectStatus = async (projectId, status) => {
        const res = await changeMedProjectStatus({
            id: projectId,
            status
        });
        if (res.status_code === 200) {
            if (status === 1) {
                message.success('项目上架成功');
            } else if (status === 2) {
                message.success('项目下架成功');
            }

            getProjects();

            dispatch(setCursor(projectId));
        }
    };

    const toCreate = () => navigate('/medproject/projects/new');

    // 项目管理
    const toProjects = async id => {
        const res = await moduleListApi({projectId: id});
        if (res.status_code === 200) {
            if (res.data && res.data.length) {
                const moduleId = res.data.find(m => m.moduleType === 'activity')?.id || 0;
                if (!!moduleId) {
                    navigate(`/medproject/projects/${id}/modules/${moduleId}`);
                } else {
                    navigate(`/medproject/projects/${id}`);
                }
            } else {
                navigate(`/medproject/projects/${id}`);
            }

            dispatch(setCursor(id));
        }
    };

    // 医项目显示、隐藏
    const handleChangeShow = async(bool, projectId) => { 
        const res = await changeMedProjectStatus({
            id: projectId,
            hidden: bool ? 0 : 1
        });
        if (res.status_code === 200) {
            if (bool) {
                message.success('显示项目成功');
            } else{
                message.success('隐藏项目成功');
            }

            getProjects();

            dispatch(setCursor(projectId));
        }
    };

    const defaultColumns = [
        {
            title: '项目编号',
            dataIndex: 'id',
            width: 100,
            render: (_, record) => {
                const projectNo = numberProject('YXM', record.id);
                return <span className="conferenctlist-copy-column-span">
                    {projectNo}
                    <span onClick={() => copyProjectNo(projectNo)}><CopyOutlined /></span>
                </span>;
            }
        },
        { title: '项目名称', dataIndex: 'name' },
        {
            title: '项目状态',
            dataIndex: 'status',
            width: 80,
            render: (_, record) => <Text type={projectStatusList.find(s => s.status === record.status)?.type || null}>{projectStatusList.find(s => s.status === record.status)?.label}</Text>
        },
        {
            title: '创建人',
            dataIndex: 'createdName',
            width: 240,
            render: (_, record) => <>
                <span>{record?.createdName}</span><br/>
                <Text type="secondary">{convertDateTimeToLocalText(record?.createdAt)}</Text>
            </>
        },
        {
            title: <>
                医项目列表显示
                <Popover 
                    placement="topRight" 
                    content='显示后，医项目将会在web健康界平台-医项目板块展示；显示/隐藏，不影响web/h5正常访问使用医项目链接'
                    overlayStyle={{maxWidth: '300px'}}
                >
                    <QuestionCircleOutlined />
                </Popover>
            </>, 
            dataIndex: 'rankState', 
            width: 150,
            render: (_, row) => {
                if (row.status === 1) {
                    return <Switch
                        checkedChildren="显示"
                        unCheckedChildren="隐藏"
                        checked={!row.hidden}
                        onChange={(bool) => handleChangeShow(bool, row.id)}
                    />
                }
            }
        },
        {
            title: <>
                排序值
                <Popover 
                    placement="topRight" 
                    content='数值越大越在最上方，允许相同数值'
                    overlayStyle={{maxWidth: '300px'}}
                >
                    <QuestionCircleOutlined />
                </Popover>
            </>, 
            dataIndex: 'sort',
            width: 100,
            editable: true,
            render: (_, row) => {
                if (row.status === 1) {
                    return <>{row.sort}</>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 160,
            render: (_, record) => {
                const items = [
                    {
                        label: record.status === 0 ? '发布' : record.status === 2 ? '上架' : '下架',
                        key: "pub_status",
                        onClick: () => onChangeProjectStatus(record.id, record.status === 0 || record.status === 2 ? 1 : 2),
                        danger: record.status !== 0 && record.status !== 2,
                    },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => toProjects(record.id)}
                        >
                            编辑
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => window.open(`${config.PROJECT_DOMAIN}/p/${record.id}`)}
                        >
                            预览
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type='link'
                            danger={record.status === 1}
                            onClick={() => onChangeProjectStatus(record.id, record.status === 2 ? 1 : 2)}
                        >
                            {record.status === 2 && <>上架<Popover placement="topRight" content='上架后，web/h5正常访问使用医项目链接' overlayStyle={{maxWidth: '300px'}}><QuestionCircleOutlined /></Popover></>}
                            {record.status === 1 && <>下架<Popover placement="topRight" content={<>下架后，若在医项目列表显示将医会在web健康界平台-医项目板块取消展示；<Text type="danger">web/h5无法正常访问使用医项目链接，页面为空并提示已下架</Text>，请您慎重操作</>} overlayStyle={{maxWidth: '300px'}}><QuestionCircleOutlined /></Popover></>}
                        </Button>
                    </span>
                );
            }
        },
    ];

    const handleSave = async(row) => {
        const res = await changeMedProjectStatus({
            id: row.id,
            sort: row.sort
        });
        if (res.status_code === 200) {
            message.success('保存成功');
            getProjects();
            dispatch(setCursor(row.id));
        }

    };

    const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    useEffect(() => {
        form.setFieldsValue(filter);

        getProjects();
    }, [filter]);

    return (
        <>
            <div className="page-title">
                <span className="current-title">医项目管理</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        onFinish={onFinish}
                        form={form}
                        autoComplete="off"
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="项目编号" name="id">
                                    <Input allowClear placeholder="项目编号或ID" />
                                </Form.Item>

                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="项目名称" name="name">
                                    <Input allowClear placeholder="项目名称关键字" />
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="main-content-body medproject-list">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={() => toCreate()}>新增医项目</Button>
                        </div>

                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        onClick={onRefresh}
                                        icon={<>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                                </svg>
                                            </span>
                                        </>}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        components={components}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />

                </div>

            </Content>
        </>
    );
}
