import React, { Fragment, useState } from "react";
import { SketchPicker } from 'react-color';
import './index.less'
const ColorPicker = ({ selectColor,color,handleClick,handleClose,onChangeColor}) => {


    return (
        <Fragment>
            <div>
                <div className='swatch' onClick={handleClick}>
                    <p >A</p>
                    <div className='color' style={{ background:`rgba(${color.r},${color.g},${color.b},${color.a})`}}/>

                </div>
                {selectColor? (
                    <div className='popover'>
                        <div className='cover' onClick={handleClose} />
                        <SketchPicker color={color} onChange={onChangeColor} onChangeComplete={onChangeColor} className="color-picker"/>
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
};

export default ColorPicker;
