import React, { useState } from 'react';
import { Form, message, Modal, Select } from 'antd';
import { projectTypes } from '../../../constants';
import {
    fetchConferenceProjectSimpleList,
    fetchSelectionProjectSimpleList,
    fetchInvitationGroupSimpleList,
    fetchSurveySimpleList,
} from '@services/SimpleList.service';
import { storeRelatedProject } from '@services/LegacyMedProject.service';

export default function AddRelatedProjectModal(props) {
    const {
        visibleAddRelatedProjectModal,
        setVisibleAddRelatedProjectModal,
        relatedProjects,
        medProjectId,
        getRelatedProjects,
    } = props;

    const [conferenceProjects, setConferenceProjects] = useState([]);
    const [selectionProjects, setSelectionProjects] = useState([]);
    const [invitationGroups, setInvitationGroups] = useState([]);
    const [surveys, setSurveys] = useState([]);

    const [currentCode, setCurrentCode] = useState('');
    const [currentList, setCurrentList] = useState([]); // 当前待选择关联项目

    const [addProjectForm] = Form.useForm();

    const addRelatedProject = () => {
        addProjectForm.validateFields()
            .then(async values => {
                console.log(values);

                const res = await storeRelatedProject({
                    medprojectId: medProjectId,
                    projectCode: currentCode,
                    projectId: values.projectId,
                    projectName: currentList.find(p => p.id === values.projectId)?.name,
                });
                if (res.status_code === 200) {
                    message.success('关联项目成功');
                    setVisibleAddRelatedProjectModal(false);
                    addProjectForm.resetFields();
                    getRelatedProjects();
                }
            });
    };

    const closeAddRelatedProjectModal = () => {
        setVisibleAddRelatedProjectModal(false);
        addProjectForm.resetFields();
    };

    // 获取项目列表，并存放在"当前列表 currentList"中，供下载选择用
    const getProjectsSimpleList = async projectCode => {
        let res = null;
        switch (projectCode) {
            case 'conference':
                if (conferenceProjects.length) {
                    setCurrentList(conferenceProjects);
                    break;
                }

                res = await fetchConferenceProjectSimpleList();
                if (res.status_code === 200) {
                    // console.log(res.data);
                    setConferenceProjects(res.data);
                    setCurrentList(res.data);
                }
                break;
            case 'selection':
                if (selectionProjects.length) {
                    setCurrentList(selectionProjects);
                    break;
                }

                res = await fetchSelectionProjectSimpleList();
                if (res.status_code === 200) {
                    // console.log(res.data);
                    setSelectionProjects(res.data);
                    setCurrentList(res.data);
                }
                break;
            case 'invitation':
                if (invitationGroups.length) {
                    setCurrentList(invitationGroups);
                    break;
                }

                res = await fetchInvitationGroupSimpleList();
                if (res.status_code === 200) {
                    // console.log(res.data);
                    setInvitationGroups(res.data);
                    setCurrentList(res.data);
                }
                break;
            case 'survey':
                if (surveys.length) {
                    setCurrentList(surveys);
                    break;
                }

                res = await fetchSurveySimpleList();
                if (res.status_code === 200) {
                    // console.log(res.data);
                    setSurveys(res.data);
                    setCurrentList(res.data);
                }
                break;
            default:
                break;
        }
    }

    const onChangeProjectCode = value => {
        // console.log(value);

        if (!!value) {
            setCurrentCode(value);
            getProjectsSimpleList(value);
        }
    };

    // 禁用已关联项目
    const disableAddedProjects = (projectCode, projectList) => {
        // console.log(projectList);
        let result = [];
        if (projectList && projectList.length) {
            result = projectList.map(p => {
                relatedProjects.forEach(rp => {
                    if (rp.projectCode === projectCode && rp.projectId === p.id) {
                        p.disabled = 1;
                    }
                });

                return p;
            });
        }

        // console.log(result);
        return result;
    };

    return (
        <>
            <Modal
                title="添加关联项目"
                centered
                open={visibleAddRelatedProjectModal}
                onOk={addRelatedProject}
                onCancel={closeAddRelatedProjectModal}
            >
                <Form form={addProjectForm} layout="vertical">
                    <Form.Item
                        label="项目类型"
                        name="projectCode"
                        rules={[
                            {required: true, message: '请选择项目类型'}
                        ]}
                    >
                        <Select
                            placeholder="请选择项目类型"
                            options={projectTypes}
                            fieldNames={{label: 'label', value: 'code'}}
                            onChange={onChangeProjectCode}
                        />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.projectCode !== curValues.projectCode}
                    >
                        {({ getFieldValue }) => {
                            return !!getFieldValue('projectCode') && <>
                                <Form.Item
                                    label="选择关联项目"
                                    name="projectId"
                                    rules={[
                                        {required: true, message: '请选择关联项目'}
                                    ]}
                                >
                                    <Select
                                        placeholder="请选项要关联的项目"
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        {disableAddedProjects(currentCode, currentList).map(p => (
                                            <Select.Option key={p.id} value={p.id} disabled={p.disabled}>
                                                {!!p.isTesting ? '[测试项目]' : ''}{p.name} [#{p.id}]
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </>;
                        }}
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
}
