import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Layout, Table, Button, Modal, Checkbox, message, Form, Input } from "antd";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { fetchCasevotetopicsPage, fetchCandidateTopics, saveCandidateTopics, deleteCasevotetopics } from "@services/Selection";

import "./index.less";

const { Content } = Layout;
const { confirm } = Modal;

const getColumns = ({ params, navigate, removeTopic }) => [
    {
        title: "投票主题",
        dataIndex: "name",
        align: "center",
    },
    {
        title: "投票起止时间",
        key: "id",
        align: "center",
        render: (row) => {
            return row?.topicVoteSettings?.startedAt ? row.topicVoteSettings.startedAt + "-" + row.topicVoteSettings.endedAt : "-";
        },
    },
    {
        title: "案例票数",
        key: "id",
        align: "center",
        render: (row) => {
            if (!row.topicVoteSettings) return "-";
            return row?.topicVoteSettings?.visibleVoteNum ? "展示" : "不展示";
        },
    },
    {
        title: "投票按钮",
        key: "id",
        align: "center",
        render: (row) => {
            if (!row.topicVoteSettings) return "-";
            return row?.topicVoteSettings?.visibleVoteButton ? "展示" : "不展示";
        },
    },
    {
        title: "投票排行榜",
        key: "id",
        align: "center",
        render: (row) => {
            if (!row.topicVoteSettings) return "-";
            return row?.topicVoteSettings?.visibleRank ? "展示" : "不展示";
        },
    },
    {
        title: "操作",
        key: "id",
        align: "center",
        render: (row) => {
            return (
                <React.Fragment>
                    <Button type="link" onClick={() => navigate(`/selection/projects/${params.pid}/vote/topics/${row.id}/settings`, { state: row.topicVoteSettings })}>
                        投票规则
                    </Button>
                    <Button type="link" onClick={() => navigate(`/selection/projects/${params.pid}/vote/topics/${row.topicId}/privileged-users`)}>
                        特权用户
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/selection/projects/${params.pid}/vote/topics/${row.topicId}/cases`);
                        }}
                    >
                        投票案例
                    </Button>
                    <Button type="link" onClick={() => removeTopic(row.id)}>
                        移除投票主题
                    </Button>
                </React.Fragment>
            );
        },
    },
];

const ScoreStageTopicList = () => {
    const params = useParams();
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [topicSource, setTopicSource] = useState([]);
    const [topicModal, setTopicModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [topicName, setTopicName] = useState("");
    const navigate = useNavigate();

    const fetchList = useCallback(
        (name, needLoading = true) => {
            if (needLoading) {
                setTableLoading(true);
            }
            fetchCasevotetopicsPage({ projectId: params.pid, topicName: name })
                .then((result) => {
                    if (result?.status_code === 200) {
                        setDataSource(result.data?.map((item) => ({ ...item, topicVoteSettings: !!item.topicVoteSettings && typeof item.topicVoteSettings === "string" ? JSON.parse(item.topicVoteSettings) : null })) || []);
                    }
                })
                .finally(() => {
                    setTableLoading(false);
                });
        },
        [params]
    );

    useEffect(() => {
        fetchList();
        fetchCandidateTopics({ projectId: params.pid }).then((result) => {
            if (result?.status_code === 200) {
                setTopicSource(result.data?.map((i) => ({ label: i.name, value: i.id })) || []);
            }
        });
    }, [fetchList, params]);

    const columns = useMemo(() => {
        const removeTopic = (id) => {
            confirm({
                icon: <ExclamationCircleOutlined />,
                content: "确定移除所选主题？",
                centered: true,
                maskClosable: true,
                onOk() {
                    deleteCasevotetopics({ id }).then((res) => {
                        if (res.status_code === 200) {
                            message.success("移除成功");
                            fetchList();
                            setTopicName("");
                        }
                    });
                },
            });
        };
        
        return getColumns({ params, navigate, removeTopic });
    }, [fetchList, navigate, params]);

    const header = useMemo(() => {
        const handleAddTopic = () => {
            const newSource = [...topicSource].filter((d) => !dataSource.some((i) => i.topicId === d.value));
            setTopicSource(newSource);
            if (!newSource.length) {
                return message.warn("无待添加投票主题！");
            }
            setTopicModal(true);
        };

        const search = () => {
            fetchList(topicName);
        };

        return (
            <div className="mark-table-header">
                <div className="mark-table-header-search">
                    主题名称：
                    <Input placeholder="名称" value={topicName} onChange={(e) => setTopicName(e.target.value)} />
                    <Button type="primary" onClick={search}>
                        搜索
                    </Button>
                </div>
                <div className="mark-table-header-button">
                    <Button type="primary" onClick={handleAddTopic}>
                        添加投票主题
                    </Button>
                </div>
            </div>
        );
    }, [dataSource, fetchList, topicName, topicSource]);

    const onOk = () => {
        const topicIds = form.getFieldValue("topics");

        if (topicIds?.length) {
            setConfirmLoading(true);
            saveCandidateTopics({ projectId: params.pid, topicIds })
                .then((res) => {
                    if (res?.status_code === 200) {
                        fetchList();
                        message.success("添加成功！");
                        setTopicModal(false);
                        form.resetFields();
                    } else {
                        message.warning("添加失败，请重试！");
                    }
                })
                .finally(() => {
                    setConfirmLoading(false);
                });
        } else {
            message.warning("请选择要添加的投票主题！");
        }
    };

    return (
        <>
            <div className="page-title">
                <Link to="/selection/projects" className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">投票管理</span>
            </div>

            <Content className="main-content-box mark-table-content">
                <Table dataSource={dataSource} columns={columns} rowKey="id" title={() => header} pagination={false} loading={tableLoading} />
            </Content>

            <Modal
                centered
                width={600}
                title="选择参评主题"
                open={topicModal}
                confirmLoading={confirmLoading}
                onOk={onOk}
                onCancel={() => setTopicModal(false)}
                afterClose={() => {
                    setTopicModal(false);
                    form.resetFields();
                }}
            >
                <Form form={form}>
                    <Form.Item
                        name="topics"
                        label="参评主题"
                        labelCol={{
                            sm: { span: 8 },
                        }}
                        wrapperCol={{
                            sm: { offset: 1 },
                        }}
                    >
                        <Checkbox.Group options={topicSource} className="pingwei-checkgroup" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ScoreStageTopicList;
