import React, { useState, useEffect, useRef } from 'react';
import { LeftOutlined } from "@ant-design/icons";
import { useLocation, Link, useParams } from 'react-router-dom';
import { Layout, Table, Button, Input, Modal, Form, Row, Col, Space, Select, message, InputNumber, Radio } from "antd";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import './SignInDetail.less';
import moment from 'moment';
import { authdictionaryApi, registrationsGetOneApi  } from '@services/Conference/sign';
import { getSuppliesPageApi } from '@services/Conference';
import { signInDetailListApi, signInRendSmsApi, signInConfirmApi, signInIdentityListApi, signInUpdateRemarkApi } from '@services/Conference/signIn';
import { get} from '@core/request';
const { Content } = Layout;
const { TextArea } = Input;

// 签到管理 - 签到明细
const SignDetail = () => {
    const location = useLocation();
    const state = location.state;
    const params = useParams();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isIdentity, setIsIdentity] = useState(false);
    const [onlyRemarks, setOnlyRemarks] = useState(false);
    const [identityOptions, setIdentityOptions] = useState([]);
    const [authdictionaryList, setAuthdictionaryList] = useState([]);
    const [identityId, setIdentityId] = useState(0);

    const [filterForm] = Form.useForm();
    const [signInConfirmForm] = Form.useForm();

    const currentRecordRef = useRef(null);
    const [registrationSupplies, setRegistrationSupplies] = useState();

    const columns = [
        {
            title: "序号",
            dataIndex: "registerId",
            width: 80,
            fixed: 'left'
        },
        {
            title: "手机号",
            dataIndex: "phone",
            width: 120,
            fixed: 'left'
        },
        {
            title: "姓名",
            dataIndex: "name",
            width: 120,
            fixed: 'left'
        },
        {
            title: "签到方式",
            dataIndex: "signType",
            width: 120,
            fixed: 'left',
            render: (_, record) => (

                record.signType && <span>{record.signType === 1 ? '工作人员核验签到' : '用户自主签到'}</span>
            )
        },
        // {
        //     title: "校验人",
        //     width: 100,
        //     dataIndex: "personName",
        // },
        // {
        //     title: "签到时间",
        //     width: 200,
        //     dataIndex: "signedAt",
        //     render: (_, record) => (<>
        //             <div>{record.signedAt ? moment(record.signedAt).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
        //             {record.signedAt && <div>{record.checkType === 1 && '本人签到'}</div>}
        //             {record.signedAt && <div>{record.checkType === 2 && <Text type="danger">代签</Text>}</div>}
        //         </>
        //     )
        // },
        {
            title: "会场身份",
            width: 100,
            dataIndex: "identityName",
            render: (_, record) => (
                <span>{!!record.identityName ? record.identityName : '待工作人员确认'}</span>
            )
        },
        // {
        //     title: "报名类型",
        //     width: 100,
        //     dataIndex: "type",
        //     render: (_, record) => (
        //         <span>{record.type === 1 ? '个人' : '团队'}</span>
        //     )
        // },
        {
            title: "参会嘉宾胸牌数量",
            width: 130,
            dataIndex: "idBadge",
            render: (_, record) => (
                <span>{!!record.idBadge ? record.idBadge : 0}</span>
            )
        },
        {
            title: "资料袋数量",
            width: 100,
            dataIndex: "documentBag",
            render: (_, record) => (
                <span>{!!record.documentBag ? record.documentBag : 0}</span>
            )
        },
        {
            title: "自助餐数量",
            width: 100,
            dataIndex: "mealTicket",
            render: (_, record) => (
                <span>{!!record.mealTicket ? record.mealTicket : 0}</span>
            )
        },
        {
            title: "伴手礼数量",
            width: 100,
            dataIndex: "souvenir",
            render: (_, record) => (
                <span>{!!record.souvenir ? record.souvenir : 0}</span>
            )
        },
        // {
        //     title: "商务餐券数量",
        //     width: 100,
        //     dataIndex: "bizMealTicket",
        // },
        {
            title: "健康界会员数量",
            width: 120,
            dataIndex: "vip",
            render: (_, record) => (
                <span>{!!record.vip ? record.vip : 0}</span>
            )
        },
        {
            title: "停车券数量",
            width: 100,
            dataIndex: "carTicket",
            render: (_, record) => (
                <span>{!!record.carTicket ? record.carTicket : 0}</span>
            )
        },
        {
            title: "商务简餐数量",
            width: 100,
            dataIndex: "boxedMeal",
            render: (_, record) => (
                <span>{!!record.boxedMeal ? record.boxedMeal : 0}</span>
            )
        },
        {
            title: "参赛选手胸牌（紫绳）数量",
            width: 100,
            dataIndex: "raceBadge",
            render: (_, record) => (
                <span>{!!record.raceBadge ? record.raceBadge : 0}</span>
            )
        },
        {
            title: "参赛选手胸牌（黄绳）数量",
            width: 100,
            dataIndex: "raceBadgeYellow",
            render: (_, record) => (
                <span>{!!record.raceBadgeYellow ? record.raceBadgeYellow : 0}</span>
            )
        },
        // {
        //     title: "VIP思享汇冷餐会数量",
        //     width: 150,
        //     dataIndex: "vipThoughtReunionBuffet",
        // },
        {
            title: "全民健康跑T恤数量",
            width: 150,
            dataIndex: "healthRun",
            render: (_, record) => (
                <span>{!!record.healthRun ? record.healthRun : 0}</span>
            )
        },
        {
            title: "参访海南博鳌乐城医疗旅游先行区数量",
            width: 150,
            dataIndex: "hainanBoAoMedicalTourism",
            render: (_, record) => (
                <span>{!!record.hainanBoAoMedicalTourism ? record.hainanBoAoMedicalTourism : 0}</span>
            )
        },
        {
            title: "参展商胸牌数量",
            width: 120,
            dataIndex: "exhibitorCard",
            render: (_, record) => (
                <span>{!!record.exhibitorCard ? record.exhibitorCard : 0}</span>
            )
        },
        {
            title: "VIP胸牌数量",
            width: 120,
            dataIndex: "vipBadge",
            render: (_, record) => (
                <span>{!!record.vipBadge ? record.vipBadge : 0}</span>
            )
        },
        {
            title: "工作人员胸牌数量",
            width: 130,
            dataIndex: "workerBadge",
            render: (_, record) => (
                <span>{!!record.workerBadge ? record.workerBadge : 0}</span>
            )
        },
        {
            title: "备注信息",
            width: 200,
            dataIndex: "remarks",
        },
        {
            title: "认证身份",
            width: 200,
            dataIndex: "authIdentity",
            render: (_, row) => (
                <>
                    {authdictionaryList.find(item => item.code === row.authIdentity)?.name}
                </>
            ),
        },
        {
            title: "单位",
            width: 120,
            dataIndex: "unit",
        },
        {
            title: "部门",
            width: 120,
            dataIndex: "officeCode",
            render: (_, row) => (
                <>
                    {row.officeCode && getDicName(row.officeCode.split(','), row.authIdentity, 'KSH')}
                </>
            ),
        },
        {
            title: "职务",
            width: 120,
            dataIndex: "postCode",
            render: (_, row) => (
                <>
                    {row.postCode && getDicName(row.postCode.split(','), row.authIdentity, 'ZHW')}
                </>
            ),
        },
        {
            title: "职称",
            width: 120,
            dataIndex: "jobtitleCode",
            render: (_, row) => (
                <>
                    {row.jobtitleCode && getDicName(row.jobtitleCode.split(','), row.authIdentity, 'ZHCH')}
                </>
            ),
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            width: 160,
            fixed: 'right',
            render: (_, row) => (
                <>
                    <Button type='link' disabled={row.isSign} onClick={() => confirmSign(row)} className="edit-btn">确认签到</Button>
                    <Button type='link' onClick={() => remark(row)}>备注</Button>
                </>
            ),
        },
    ];

    // 认证身份
    const getAuthdictionaryList = async () => {
        const res = await authdictionaryApi();
        if (res.code === 'A' && res.options && res.options.length) {
            setAuthdictionaryList(res.options);
        }
    };

    // 获取字典名称
    const getDicName = (dicCode, orgType, fieldType) => {
        const dicCodeList = authdictionaryList.find(item => item.code === orgType)?.labels || [];
        const dicOptions = dicCodeList.find(item => item.code.includes(fieldType))?.options || [];

        if (dicCode && dicCode.length === 1) {
            const target = dicOptions.find(item => item.code === dicCode[0]);
            if (target && target.name) {
                return target.name;
            }
        }
        if (dicCode && dicCode.length === 2) {
            let resName = null;
            const targetFir = dicOptions.find(item => (item.code === dicCode[0]));
            if (targetFir && targetFir.name) {
                resName = targetFir.name;
                const targetSec = targetFir.options.find(item => (item.code === dicCode[1]));
                if (targetSec && targetSec.name) {
                    resName = resName + '/' + targetSec.name;
                }
            }

            return resName;
        }
    };

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };

    // 新增签到
    const handleOk = () => {
        signInConfirmForm.validateFields().then((async (values) => {
            // console.log(values);
            // return
            const reqApi = onlyRemarks ? signInUpdateRemarkApi : signInConfirmApi;
            const res = await reqApi({
                ...values,
                conferenceId: params.id,
                signId: location.state?.id,
                recordsId: currentRecordRef.current?.recordsId,
                registerId: currentRecordRef.current?.registerId,
                identityId: currentRecordRef.current?.identityId || identityId
                // signedAt: values.signedAt ? moment(values.signedAt).format('YYYY-MM-DD HH:mm:ss') : undefined
            });
            if (res && res.status_code === 200) {
                setIsModalOpen(false);
                message.success('保存成功');
                getList({ page, pageSize, ...searchParams });
            }
        }));
    };

    // 关闭签到弹框
    const handleCancel = () => {
        setIsModalOpen(false);
        signInConfirmForm.resetFields();
    };

    // 确认签到
    const confirmSign = async(row) => {

        setOnlyRemarks(false);
        if(!!row.identityId){
            setIsIdentity(true);
            setIdentityId(row.identityId);

            const res = await registrationsGetOneApi({id: row.registerId});
            if(!!res && res.status_code === 200 && !!res.data){
                signInConfirmForm.setFieldsValue({
                    ...res.data,
                    name: row.name,
                    remarks: row.remarks,
                    identityName: row.identityName
                })
            }else{
                signInConfirmForm.setFieldsValue({
                    name: row.name,
                    remarks: row.remarks
                })
            }
        }else{
            setIsIdentity(false);
            setIdentityId(0);
            signInConfirmForm.setFieldsValue({
                name: row.name,
                remarks: row.remarks
            })
        }

        currentRecordRef.current = row;

        setIsModalOpen(true);
    };

    const onChange = (e) => {
        console.log('radio checked', e.target.value);

        setIdentityId(e.target.value);

        const registrationSupplies = identityOptions.find(item => item.id === e.target.value)?.registrationSupplies;
        // if (registrationSupplies) {
            const supplies = JSON.parse(registrationSupplies);
            const fieldValue = {};
            supplies.map(item=>{
                fieldValue[getKey(item.name)] = item.count;
            });

            // console.log(fieldValue)
            const values = {...signInConfirmForm.getFieldsValue()};
            signInConfirmForm.resetFields();
            signInConfirmForm.setFieldsValue({
                // ...values,
                ...fieldValue,
                name: values.name,
                remarks: values.remarks,
                identityName: values.identityName
            })
        // }
    };

    // 备注
    const remark = (row) => {
        signInConfirmForm.setFieldValue('remarks', row.remarks);
        currentRecordRef.current = row;
        setOnlyRemarks(true);
        setIsModalOpen(true);
    };

    // 发送短信
    const sendMessage = async () => {
        const res = await signInRendSmsApi({ conferenceId: params.id, signId: location.state?.id });
        if (res && res.status_code === 200) {
            message.success('短信已发送');
        }
    };

    // 筛选查询
    const onFormFinish = (values) => {
        setSearchParams({ ...values });
        setPage(1);
    };

    // 重置
    const onReset = () => {
        filterForm.resetFields();
        setSearchParams({});
        setPage(1);
    };

    // 列表
    const getList = async (reqParams) => {
        setLoading(true);
        const res = await signInDetailListApi({ ...reqParams, conferenceId: params.id, signId: location.state?.id });
        setLoading(false);
        if (res && res.status_code === 200 && res.data) {
            setData(res.data.list || []);
            setTotal(res.data.totalCount);
        };
    };

    // 获取身份配置列表
    const getIdentityList = async() => {
        const res = await signInIdentityListApi({conferenceId: params.id});
        if (res && res.status_code === 200) {
            console.log(res)
            setIdentityOptions(res.data);
        };
    };

    const getKey = (name) => {
        if (name === '参会嘉宾胸牌') {
            return 'idBadge'
        }
        if (name === '自助餐') {
            return 'mealTicket'
        }
        if (name === '资料袋') {
            return 'documentBag'
        }
        if (name === '伴手礼') {
            return 'souvenir'
        }
        // if (name === '商务餐券') {
        //     return 'bizMealTicket'
        // }
        if (name === '健康界会员') {
            return 'vip'
        }
        if (name === '停车券') {
            return 'carTicket'
        }
        if (name === '商务简餐') {
            return 'boxedMeal'
        }
        if (name === '参赛选手胸牌（紫绳）') {
            return 'raceBadge'
        }
        if (name === '参赛选手胸牌（黄绳）') {
            return 'raceBadgeYellow'
        }
        // if (name === 'VIP思享汇冷餐会') {
        //     return 'vipThoughtReunionBuffet'
        // }
        if (name === '全民健康跑T恤') {
            return 'healthRun'
        }
        if (name === '参访海南博鳌乐城医疗旅游先行区') {
            return 'hainanBoAoMedicalTourism'
        }
        if (name === '参展商胸牌') {
            return 'exhibitorCard'
        }
        if (name === 'VIP胸牌') {
            return 'vipBadge'
        }
        if (name === '工作人员胸牌') {
            return 'workerBadge'
        }
    };

    // 获取报名设置身份权益
    const getSuppliesPage = () => {
        getSuppliesPageApi({ conferenceId: params.id, status: 1 }).then(res => {
            if (res && res.status_code === 200) {
                const arr = res?.data.map(i => {
                    return {
                        id: i.id,
                        name: i.name,
                        check: true,
                        count: 1,
                        code: getKey(i.name)
                    }
                })
                console.log(arr)
                setRegistrationSupplies(arr);
            }
        })
    }
    const downloadAction = async () => {

        get('/conference/boss/checkin/download', { conferenceId: params.id }, { responseType: 'blob' })
            .then(res => {
                console.log(res)
                message.success('请稍后，正在导出');

                let blob = new Blob([res], { type: 'application/octet-stream' });
                if ('download' in document.createElement('a')) {
                    const fileNameSuffix = '_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.download = '签到明细_' + params.id + '_' + state.conferenceName + fileNameSuffix;
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                } else {
                    navigator.msSaveBlob(blob);
                }

            });
    }

    useEffect(() => {
        // const newParams = { ...searchParams };
        // newParams.page = page;
        getList({ page, pageSize, ...searchParams });
    }, [page, pageSize, searchParams]);

    useEffect(() => {
        getIdentityList(); // 身份配置列表
        getAuthdictionaryList();  // 认证身份列表
        getSuppliesPage();  // 报名设置物料权益列表
    }, []);

    return (
        <>
            <div className="page-title">
                <Link to={`/conf/conferences/${params.id}/signup?type=3`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">签到明细</span>
                <span className="current-subtitle">{location.state?.name}</span>
            </div>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="signin-detail">
                        <div className="detail-header">
                            <Form
                                form={filterForm}
                                name="filterForm"
                                onFinish={onFormFinish}
                                {...filterFormItemLayout}
                            >
                                <Row>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="name" label="姓名">
                                            <Input placeholder="请输入姓名" />
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="phone" label="手机号">
                                            <Input placeholder="请输入手机号" />
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name='identityId' label='身份'>
                                            <Select placeholder='请选择身份'
                                                options={identityOptions}
                                                fieldNames={{
                                                    label: 'name',
                                                    value: 'id'
                                                }}
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="orgName" label="单位">
                                            <Input placeholder="请输入单位" />
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name='isSign' label='是否签到'>
                                            <Select placeholder='请选择是否签到'
                                                options={[
                                                    { value: 1, label: '是' },
                                                    { value: 0, label: '否' }
                                                ]}
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                        <Space>
                                            <Button type="primary" htmlType="submit">检索</Button>
                                            <Button type="default" onClick={onReset}>重置</Button>
                                        </Space>
                                    </Col>
                                    <Col  {...filterFormGridLayout}>
                                        <Space>
                                        </Space>
                                    </Col>
                                    <Col align="right" {...filterFormGridLayout}>
                                        <Button type="primary" onClick={downloadAction}>导出签到明细</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        {/*<div className="detail-message">*/}
                        {/*    <Button type="primary" onClick={sendMessage}>未签到用户短信提醒</Button>*/}
                        {/*    <p className="signin-tip">点击后将会给没有签到人进行短信提醒</p>*/}
                        {/*</div>*/}

                        <Table
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            rowKey="id"
                            scroll={{
                                x: 2800,
                            }}
                            size={'middle'}
                            pagination={{
                                total: total,
                                showTotal: () => `共${total}条`,
                                current: page,
                                pageSize: pageSize,
                                onChange: onChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                        />

                        <Modal
                            title={onlyRemarks ? '备注' : '签到确认'}
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            className="signInModal"
                            width={600}
                        >
                            <Form
                                labelCol={{
                                    span: 7,
                                }}
                                wrapperCol={{
                                    span: 17,
                                }}
                                name="signInConfirmForm"
                                form={signInConfirmForm}
                                size="large"
                                autoComplete="off"
                                // initialValues={{ signedAt: moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss') }}
                            >
                                {
                                    !onlyRemarks &&
                                    <>
                                        {/* <Form.Item name="name" label="签到人" rules={[{ required: true }]}>
                                            <Input disabled={true} />
                                        </Form.Item> */}
                                        {
                                            !isIdentity ? !!identityOptions && !!identityOptions.length && <Form.Item name="identityName" label="身份类型" rules={[{ required: true }]}>
                                                <Radio.Group onChange={onChange}>
                                                    {identityOptions.map((item, index) => (
                                                        <Radio value={item.id} key={index}> {item.name} </Radio>
                                                    ))}
                                                </Radio.Group>
                                            </Form.Item>
                                            : <Form.Item name="identityName" label="身份类型" rules={[{ required: true }]}>
                                                <Input type="text" disabled />
                                            </Form.Item>
                                        }
                                        {
                                            !!identityId && !!registrationSupplies && !!registrationSupplies.length && registrationSupplies.map((item, index) => (
                                                <Form.Item name={getKey(item.name)} label={item.name} key={index}>
                                                    <InputNumber min={0} placeholder={`请输入${item.name}数量`} maxLength={100} />
                                                </Form.Item>
                                            ))
                                        }
                                        {/* <Form.Item name="signedAt" label="签到时间" rules={[{ required: true }]}>
                                            <DatePicker style={{width: '100%'}} placeholder='请选择专家实际签到时间，以免影响专家劳务费付款。' showTime/>
                                        </Form.Item> */}
                                    </>
                                }
                                <Form.Item name="remarks" label="备注">
                                    <TextArea placeholder="请输入备注内容" maxLength={200} rows={4} />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default SignDetail;
