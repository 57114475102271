import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Row, Col, Form, Space, Button, DatePicker, Input, message } from 'antd';
import { PlusCircleFilled, PushpinOutlined, LeftOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import RadioPart from './RadioPart';
import CheckboxPart from './checkboxPart';
import SupplyBlankPart from './SupplyBlankPart';
import SurveysPreview from './SurveysPreview';
import { get, post, put } from '@/core/request';
import moment from 'moment';
import './SurveysNew.less';


export default function SurveysNew() {

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const [form] = Form.useForm();

    const [list, setList] = useState([]);
    const { state } = useLocation();
    const navigate = useNavigate();
    const radioId = useRef(0);
    const optionid = useRef(1);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    // 点击编辑进来渲染页面
    useEffect(() => {
        if (state) {
            get(`/dms/surveys/findById`, {
                id: state.id
            }).then((res) => {
                if (res.status_code === 200) {
                    setList(res.data.list);
                    setTitle(res.data.name);
                    setDescription(res.data.description);
                    setEndAt(res.data.endAt);
                    form.setFieldsValue({
                        endAt: moment(res.data.endAt, dateFormat)
                    })
                }
            })
        }
    }, [state, form]);

    // 问卷标题
    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    // 问卷描述
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
    }

    const subject = useCallback(
        (chooseType) => {
            let ownInput = {
                optionlist: [
                    { content: '', id: 0 }, { content: '', id: 1 }
                ]
            };
            radioId.current = radioId.current + 1;
            ownInput.id = radioId.current;
            ownInput.name = "";
            ownInput.type = chooseType;
            list.push(ownInput);
            setList([...list]);
        },
        [list],
    );

    // 点击单选题
    const radioInput = useCallback(
        () => {
            subject(1)
        },
        [subject],
    );

    // 点击多选题
    const checkboxInput = useCallback(
        () => {
            subject(2);
        },
        [subject],
    );

    // 点击填空题
    const supplyBlank = useCallback(
        () => {
            let inputObj = {};
            radioId.current = radioId.current + 1;
            inputObj.id = radioId.current;
            inputObj.name = "";
            inputObj.type = 3;
            list.push(inputObj);
            setList([...list])
        },
        [list],
    );

    // 输入题目
    const setInput = useCallback(
        (index) => {
            return (e) => {
                list[index].name = e.target.value;
                setList([...list]);
            }
        },
        [list],
    );

    // 点击删除
    const handleClickDel = useCallback((index) => {
        list.splice(index, 1);
        setList([...list]);
    },
        [list],
    );

    // 点击添加选项
    const handleAddOption = useCallback(
        (index) => {
            optionid.current = optionid.current + 1;
            let option = [{ content: '', id: optionid.current }];
            list[index].optionlist = [...list[index].optionlist, ...option];
            setList([...list]);
        },
        [list],
    );

    // 删除选项
    const handleDeleteOption = useCallback(
        (index, optionIndex) => {
            list[index].optionlist.splice(optionIndex, 1)
            setList([...list]);
        },
        [list],
    );

    const formItemLayout = {
        wrapperCol: { span: 18 },
    };

    const moveList = (dragIndex, hoverIndex) => {
        let tmp = list[dragIndex];//临时存储拖拽的文件
        list.splice(dragIndex, 1);
        list.splice(hoverIndex, 0, tmp);
        setList([...list]);
    };

    const [endAt, setEndAt] = useState("");
    // 截止时间选择
    const onTimeChange = useCallback(
        (value, dateString) => {
            setEndAt(dateString);
        },
        [],
    );

    // 预览drawer
    const drawer = useRef();
    const handleClickPreview = () => {
        drawer.current.show();
    }

    // 保存
    const handleSave = useCallback(
        () => {
            list.forEach((item, index) => {
                item.sort = index + 1;
                if (item.optionlist) {
                    item.optionlist.forEach((elem, idx) => {
                        elem.sort = idx + 1;
                    })
                }
            })
            if (endAt === "") {
                message.warning('请填写截止时间');
                return;
            }
            if (title === "") {
                message.warning('请输入问卷标题');
                return;
            };
            if (description === "") {
                message.warning('请输入问卷描述');
                return;
            };
            let obj = { endAt, name: title, list, surveysStatus: 1, description };
            // 编辑
            if (state) {
                obj.id = state.id;
                put(`/dms/surveys`, obj).then((res) => {
                    if (res.status_code === 200) {
                        message.success('保存成功');
                        navigate('/dms/surveys');
                    }
                })
            } else {
                // 新增
                post(`/dms/surveys`, obj).then((res) => {
                    if (res.status_code === 200) {
                        message.success('保存成功');
                        navigate('/dms/surveys');
                    }
                })
            }

        },
        [list, endAt, title, navigate, description, state],
    )

    return (
        <div className="surveys-new-content-box">
            <div className="page-title"><Link to="/dms/surveys" className="back"><LeftOutlined /></Link>
                {state ? '编辑问卷' : '创建问卷'}
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={handleClickPreview}>预览</Button>
                    <Button type="primary" onClick={handleSave}>保存</Button>
                </Space>
            </div>
            <div className="surveys-content">
                <Row gutter={16}>
                    <Col span={3} className="surveys-content-left">
                        <div className="surveys-content-left-top">问卷题型</div>
                        <div className="surveys-content-left-bottom">
                            <p onClick={radioInput} className="click-surveys"><PlusCircleFilled style={{ marginRight: '5px'}} />单选题</p>
                            <p onClick={checkboxInput} className="click-surveys"><PlusCircleFilled style={{ marginRight: '5px' }} />多选题</p>
                            <p onClick={supplyBlank} className="click-surveys"><PlusCircleFilled style={{ marginRight: '5px' }} />填空题</p>
                        </div>
                    </Col>
                    <Col span={20} className="surveys-content-right">
                        <div className="surveys-content-right-title">
                            <Input allowClear placeholder="请输入问卷标题" onChange={handleTitleChange} value={title} />
                            <Input style={{ marginTop: '10px' }} allowClear placeholder="请输入问卷描述" onChange={handleDescriptionChange} value={description} />
                        </div>

                        <div className="surveys-publish-time">
                            <p>问卷发布时间：</p>
                            <Form form={form}>
                                <Form.Item
                                    label="截止时间"
                                    name="endAt"
                                    format="YYYY-MM-DD HH:mm:ss"
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker showTime onChange={onTimeChange} />
                                </Form.Item>
                            </Form>

                        </div>

                        <div className="surveys-content-right-content">

                            <Form {...formItemLayout}>

                                {list.length === 0 && <div className="tip"><PushpinOutlined />点击题目类型进行问卷设计吧</div>}

                                <DndProvider backend={HTML5Backend}>
                                    <div style={{ height: '100%' }}>
                                        {
                                            list.map((item, index) => {
                                                // 单选题
                                                if (item.type === 1) {
                                                    return (
                                                        <RadioPart
                                                            key={item.id}
                                                            list={list}
                                                            index={index}
                                                            element={item}
                                                            moveList={moveList}
                                                            setInput={setInput}
                                                            handleClickDel={handleClickDel}
                                                            handleDeleteOption={handleDeleteOption}
                                                            handleAddOption={handleAddOption}
                                                            inputValue="请输入题目(单选)"></RadioPart>
                                                    )
                                                } else if (item.type === 2) {
                                                    return (
                                                        <CheckboxPart
                                                            key={item.id}
                                                            list={list}
                                                            index={index}
                                                            element={item}
                                                            moveList={moveList}
                                                            setInput={setInput}
                                                            handleClickDel={handleClickDel}
                                                            handleDeleteOption={handleDeleteOption}
                                                            handleAddOption={handleAddOption}
                                                            inputValue="请输入题目(多选)"></CheckboxPart>
                                                    )
                                                } else {
                                                    return (
                                                        <SupplyBlankPart
                                                            key={item.id}
                                                            list={list}
                                                            index={index}
                                                            element={item}
                                                            setInput={setInput}
                                                            moveList={moveList}
                                                            handleClickDel={handleClickDel}
                                                            inputValue="请填写题目(填空题)"
                                                        ></SupplyBlankPart>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </DndProvider>

                            </Form>

                        </div>
                    </Col>
                </Row>
            </div>

            <SurveysPreview ref={drawer} title={title} description={description} endAt={endAt} list={list}></SurveysPreview>


        </div>
    )
}
