import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Input, Layout, Select, Table, Button, Divider, Space, Tag, Typography, message } from 'antd';
import { LaborFeeRequestStatusList } from '../constants';
import { financeActions } from '../store/financeSlice';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { fetchLaborFeeProjects } from '../../../services/Finance/FinanceLaborFee.service';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { convertDateTimeToLocalText, numberProject } from '../../../utils/utils';
import { localStorageGet } from '@core/cookie';
import copy from 'copy-to-clipboard';
import './FinanceLaborFeeProjects.less';

const { setLaborFeeProjectFilter: setFilter, setLaborFeeProjectCursor: setCursor } = financeActions;
const { Content } = Layout;
const { Text } = Typography;

export default function FinanceLaborFeeProjects() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.finance.laborFeeProject.filter);
    const cursor = useSelector(state => state.finance.laborFeeProject.cursor);

    const [form] = Form.useForm();

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        if (!!values?.projectId) {
            values['projectId'] = values.projectId.replace(/^[a-z]+0+/i, '');
        }

        dispatch(setFilter({
            ...filter,
            ...values,
            page: 1,
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    // 含税劳务费总额审批
    const gotoLaborFeeProject = project => {
        dispatch(setCursor(project.id));

        navigate(`/finance/laborfee/projects/${project.projectCode}-${project.projectId}`);
    };

    // 劳务费付款管理
    const gotoLaborFeeBatches = project => {
        dispatch(setCursor(project.id));

        navigate(`/finance/laborfee/projects/${project.projectCode}-${project.projectId}/batches`);
    };

    // 是否拥有财务劳务费付款人员权限
    const isLaborFeePayment = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        // console.log(roleList);
        if (roleList && roleList.length) {
            // console.log('财务劳务费付款人员: ', roleList.some(item => item.name === '财务劳务费付款人员'));
            return roleList.some(item => item.name === '财务劳务费付款人员');
        }
        return false;
    }, []);

    // 是否拥有财务劳务费管理员权限
    const isLaborFeeManager = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '财务劳务费管理员');
        }
        return false;
    }, []);

    const copyClick = msg => {
        copy(msg);
        message.success('已复制到剪贴板');
    };

    const columns = [
        {
            title: '氚云项目编号',
            dataIndex: 'h3yunNo',
            width: 120,
            render: (_, record) => !!record.h3yunNo ? record.h3yunNo : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 200,
            render: (_, record) => (<>
                <div className="projectName">
                    <span>{record.projectName}</span>
                    {!!record.isTesting && <Tag color="#f50">TEST</Tag>}
                </div>
                <div className="projectNo">
                    {numberProject('YHY', record.projectId)}
                    <span onClick={() => copyClick(numberProject('YHY', record.projectId))}><CopyOutlined /></span>
                </div>
            </>)
        },
        {
            title: '执行日期',
            dataIndex: 'projectDates',
            width: 280,
            render: (_, record) => !!record.dates
                ? record.dates.split(',').map((d, i) => <Tag key={i}>{d}</Tag>)
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '项目统筹人',
            dataIndex: 'projectCreatedBy',
            width: 150,
            render: (_, record) => <>
                {!!record.projectCreatedBy && <><span>{record.projectCreatedBy}</span><br/></>}
                <small><Text type="secondary">{convertDateTimeToLocalText(record.projectCreatedAt)}</Text></small>
            </>
        },
        {
            title: '劳务费复核',
            dataIndex: 'examineStatus',
            width: 150,
            render: (_, record) => {
                const statusItem = LaborFeeRequestStatusList.find(s => s.status === record.status) || null;
                return !!statusItem
                    ? <Text type={!!statusItem.textType ? statusItem.textType : ''}>{statusItem.label}</Text>
                    : <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '付款审核',
            dataIndex: 'batchesNum',
            width: 100,
            render: (_, record) => !!record.batches ? record.batches : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '待付款',
            dataIndex: 'payBatches',
            width: 100,
            render: (_, record) => !!record.payBatches ? record.payBatches : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 280,
            fixed: 'right',
            render: (_, record) => (
                <span className="operation-btns">
                    {isLaborFeeManager && <>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoLaborFeeProject(record)}
                        >
                            劳务费复核
                        </Button>
                        <Divider type="vertical"/>
                    </>}
                    {(isLaborFeePayment || isLaborFeeManager) &&
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoLaborFeeBatches(record)}
                        >
                            劳务费付款审核
                        </Button>
                    }
                </span>
            )
        },
    ];

    // 获取付款项目列表
    const getLaborFeeProjects = async () => {
        try {
            setLoading(true);

            const params = {
                ...filter,
                projectCode: 'YHY', // 目前只有医会议
                type: 1,
            };
            let res = await fetchLaborFeeProjects(params);
            if (res.status_code === 200) {
                setTotal(res.data.totalCount);
                setTableData(res.data.list);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };


    useEffect(() => {
        form.setFieldsValue(filter);

        getLaborFeeProjects();
    }, [filter]);

    return <>
        <div className="page-title">
            <span className="current-title">付款项目管理</span>
        </div>

        <Content className="main-content-box">

            <div className="filter-form-box">
                <Form
                    {...filterFormItemLayout}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="项目名称" name="projectName">
                                <Input allowClear placeholder="项目名称关键字"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="氚云项目编号" name="h3yunNo">
                                <Input allowClear placeholder="氚云项目编号"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="项目编号" name="projectId">
                                <Input allowClear placeholder="项目编号"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="劳务费复核" name="examineStatus">
                                <Select
                                    placeholder="劳务费复核状态"
                                    allowClear
                                    options={LaborFeeRequestStatusList}
                                    fieldNames={{ value: 'status', label: 'label' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="付款审核" name="pending">
                                <Select placeholder="是否有付款审核" allowClear>
                                    <Select.Option value={1}>有待审请款批次</Select.Option>
                                    <Select.Option value={0}>无待审请款批次</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="待付款" name="paying">
                                <Select placeholder="是否有待付款" allowClear>
                                    <Select.Option value={1}>有待付请款批次</Select.Option>
                                    <Select.Option value={0}>无待付请款批次</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="正式/测试" name="isTesting" initialValue={0}>
                                <Select placeholder="是否测试项目" allowClear>
                                    <Select.Option value={0}>正式项目</Select.Option>
                                    <Select.Option value={1}>测试项目</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="main-content-body">

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                    scroll={{ x: 1300 }}
                />

            </div>

        </Content>
    </>;
}
