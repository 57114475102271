import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Layout, Table, Button, Input, Modal, Form, Row, Col, Space, Select, message, Tag, Divider } from "antd";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import './index.less';
import { getMedProjectByIdApi, membersListApi, membersInfoByMobileApi, membersSaveApi, membersDeleteApi, membersUpdateApi, auditInfoBymemCardApi, getSsoMemberByMemCardsApi, auditDismissedApi, auditThroughApi, auditSsoMemberAuthApi } from '@services/MedProject';
import ViewAuthInfoDrawer from '../ViewAuthInfoDrawer';
import AuditDismissedModal from '../AuditDismissedModal';

const { TextArea } = Input;

// 成员管理 - 成员列表
const MemberList = (memberStatus) => {

    const location = useLocation();
    const params = useParams();
    const { CheckableTag } = Tag;

    const projectId = params?.id || 0;
    const status = Number(memberStatus.status);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [visibleAuthInfoDrawer, setVisibleAuthInfoDrawer] = useState(false);
    const [visibleAuthDismissedModal, setVisibleAuthDismissedModal] = useState(false);
    const [authDismissedReson, setAuthDismissedReson] = useState(undefined);
    const curMemCard = useRef(undefined);
    const [authInfo, setAuthInfo] = useState({});
    const [ssoMember, setSsoMember] = useState();
    const [isShowFooter, setIsShowFooter] = useState(false); // 查看详情弹框是否展示操作按钮
    const [authStatus, setAuthStatus] = useState(undefined); // 审核驳回、通过

    const [identifyOptions, setIdentifyOptions] = useState([
        {
            code: 'hospital',
            name: '医院',
            tips: '本项目要求医院成员必须为认证为“医疗机构”用户'
        },
        {
            code: 'person',
            name: '个人',
            tips: '本项目要求个人成员必须为“个人认证”用户'
        },
        {
            code: 'enterprise',
            name: '企业',
            tips: '本项目要求企业成员必须为认证为“企业”的用户'
        },
        {
            code: 'medium',
            name: '媒体',
            tips: '本项目要求媒体成员必须为认证为“媒体”的用户'
        },
        {
            code: 'organization',
            name: '机构',
            tips: '本项目要求机构成员必须为认证为“机构”的用户'
        },
    ]);
    const [selectedTag, setSelectedTag] = useState(identifyOptions[0]);

    const [isModalApplyOpen, setIsModalApplyOpen] = useState(false);

    const [filterForm] = Form.useForm();
    const [memberAddForm] = Form.useForm();

    // 当前成员ID
    const [currentId, setCurrentId] = useState(null);
    // 用户信息
    const [personInfo, setPersonInfo] = useState(null);

    const columns = [
        {
            title: "姓名",
            dataIndex: "memRealName",
            width: 150,
            fixed: 'left'
        },
        {
            title: "手机号",
            dataIndex: "memMobile",
            width: 100,
            fixed: 'left',
            render: (_, row) => (
                <>
                    {row.memMobile?.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")}
                </>
            ),
        },
        {
            title: "加入身份",
            width: 150,
            dataIndex: "identityType",
            render: (_, row) => (
                <>
                    {identifyOptions.find(item => item.code === row.identityType)?.name}
                </>
            ),
        },
        {
            title: "单位名称",
            width: 150,
            dataIndex: "memUnit"
        },
        {
            title: "部门",
            width: 150,
            dataIndex: "memOffice",
        },
        {
            title: "职务",
            width: 150,
            dataIndex: "memPost",
        },
        {
            title: "加入时间",
            width: 200,
            dataIndex: "createdAt",
        },
        {
            title: "来源",
            width: 150,
            dataIndex: "source",
            render: (_, row) => (
                <>
                    {row.source === 1 ? '申请加入' : '手动添加'}
                </>
            ),
        },
        {
            title: "联系人",
            width: 150,
            dataIndex: "contactPerson",
        },
        {
            title: "联系方式",
            width: 150,
            dataIndex: "contactPhone",
            render: (_, row) => (
                <>
                    {row.contactPhone?.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")}
                </>
            ),
        },
        {
            title: "加入审核操作",
            dataIndex: "state",
            key: "state",
            width: 100,
            fixed: 'right',
            render: (_, row) => (
                <div className="main-table-operations">
                    <span className="operation-btns">
                        <Button size="small" type="link" onClick={() => editMember(row)}>编辑</Button>
                        <Divider type="vertical" />
                        <Button size="small" type="link" onClick={() => deleteMember(row.id)}>删除</Button>
                    </span>
                </div>
            ),
        },
        {
            title: "认证审核操作",
            dataIndex: "state",
            key: "state",
            width: 260,
            fixed: 'right',
            render: (_, row) => {
                const reviewing = row.authStatus === 1; // 审核中
                const approved = row.authStatus === 2; // 已通过
                const rejected = row.authStatus === 3; // 已驳回
                row = { ...row, reviewing, approved, rejected };
                return (
                    <div className="main-table-operations">
                        <span className="operation-btns">
                            {(reviewing || approved || rejected) && (
                                <Button size="small" type="link" onClick={() => viewAuthDetail(row)}>查看详情</Button>
                            )}
                            {reviewing && (
                                <>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link" onClick={() => auditThrough(row.memcard)}>通过申请</Button>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link" onClick={() => auditDismissed(row.memcard)}>驳回申请</Button>
                                </>
                            )}
                            {approved && (
                                <>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link">已审核-通过</Button>
                                </>
                            )}
                            {rejected && (
                                <>
                                    <Divider type="vertical" />
                                    <Button size="small" type="link">已审核-驳回</Button>
                                </>
                            )}
                        </span>
                    </div>
                );
            },
        },
    ];

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setSize(b);
    };

    // 删除
    const deleteMember = id => {
        Modal.confirm({
            title: '提示',
            content: '移除后，该用户将不再是项目成员，请确认是否移除？',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                // console.log(id);
                const res = await membersDeleteApi({ id });
                if (res && res.status_code === 200) {
                    message.success('移除成功');
                    getMembers({ page, size, status, ...searchParams });
                };
            }
        });

    };

    // 筛选查询
    const onFormFinish = (values) => {
        setSearchParams({ ...values });
    };

    // 重置
    const onReset = () => {
        filterForm.resetFields();
        setSearchParams({});
    };

    // 列表
    const getMembers = async (reqParams) => {
        setLoading(true);
        const res = await membersListApi({ ...reqParams, projectId });
        setLoading(false);
        if (res && res.status_code === 200 && res.data) {
            const memCards = (res.data.list || []).map(item => item.memcard)?.join();
            if (res.data.list && res.data.list.length) {
                getSsoMemberByMemCardsApi({ memCards }).then((authRes) => {
                    if (authRes && authRes.status_code === 200 && authRes.data) {
                        const newList = (res.data.list || []).map(item => ({
                            ...item,
                            memAuthStatus: authRes.data.find(authItem => authItem.memCard === item.memcard)?.memAuthStatus,
                            memIsyijiehui: authRes.data.find(authItem => authItem.memCard === item.memcard)?.memIsyijiehui,
                            memType: authRes.data.find(authItem => authItem.memCard === item.memcard)?.memType,
                        }));
                        setData(newList);
                        setTotal(res.data.totalCount);
                    }
                })
            } else {
                setData([]);
                setTotal(0);
            }
        };
    };

    // 添加成员
    const addMember = () => {
        setIsModalApplyOpen(true);
        memberAddForm.setFieldsValue({
            identityType: selectedTag.code
        });
    };

    // 成员信息查看
    const editMember = (row) => {
        setCurrentId(row.id);
        setIsModalApplyOpen(true);
        identifyOptions.map((tag) => {
            if (tag.code === row.identityType) {
                setSelectedTag(tag);
            }
        });
        memberAddForm.setFieldsValue({
            identityType: selectedTag.code,
            memMobile: row.memMobile,
            memRealName: row.memRealName || row.memNickname,
            unitName: row.memUnit,
            contactPerson: row.contactPerson,
            contactPhone: row.contactPhone
        });

    };

    // 成员信息保存
    const handleApplyOk = () => {

        memberAddForm.validateFields().then(async (values) => {
            // console.log(values);
            let fieldValues = { ...values };
            if (!currentId) {

                const res = await membersSaveApi({ ...fieldValues, memcard: personInfo.memCard, memRealName: selectedTag.code === 'person' ? personInfo.memUsername : personInfo.memNickname, projectId });
                if (res.status_code === 200) {
                    message.success('添加成功');
                    handleApplyCancel();
                    getMembers({ page, size, status, ...searchParams });
                }
            } else {
                const res = await membersUpdateApi({ ...fieldValues, projectId, id: currentId });
                if (res.status_code === 200) {
                    message.success('保存成功');
                    handleApplyCancel();
                    getMembers({ page, size, status, ...searchParams });
                }
            }
        });
    };

    // 添加成员取消
    const handleApplyCancel = () => {
        setCurrentId(null);
        setIsModalApplyOpen(false);
        memberAddForm.resetFields();
    };

    // 加入身份
    const handleTagsChange = (tag, checked) => {
        // console.log('You are choose: ', tag, checked);
        memberAddForm.resetFields();
        memberAddForm.setFieldsValue({
            identityType: tag.code
        });
        setSelectedTag(tag);
    };

    // 查看详情
    const viewAuthDetail = async row => {
        const { memcard, reviewing, authStatus } = row;
        setIsShowFooter(reviewing);
        setAuthStatus(authStatus);
        const res = await auditInfoBymemCardApi({ memCard: memcard });
        if (res.status_code === 200) {
            if (res.data?.ssoMember?.memType === 1) {
                setAuthInfo(res.data?.ssoMemberUpdate || {});
            }
            if (res.data?.ssoMember?.memType === 2) {
                setAuthInfo(res.data?.sysOrganization || {});
            }
            setSsoMember(res.data?.ssoMember);
            setVisibleAuthInfoDrawer(true);
        }
    };

    // 通过申请
    const auditThrough = async (memCard) => {
        const res = await auditThroughApi({ memCard });
        if (res.status_code === 200) {
            message.success('已通过认证审核');
            setVisibleAuthInfoDrawer(false);
            getMembers({ page, size, status, ...searchParams });
        }
    };

    // 驳回申请
    const auditDismissed = useCallback((memCard) => {
        curMemCard.current = memCard;
        setVisibleAuthDismissedModal(true);
    }, []);

    useEffect(() => {
        if (authDismissedReson) {
            auditDismissedApi({ memCard: curMemCard.current, failReason: authDismissedReson }).then(res => {
                if (res.status_code === 200) {
                    message.success('已驳回认证审核');
                    setVisibleAuthDismissedModal(false);
                    setVisibleAuthInfoDrawer(false);
                    getMembers({ page, size, status, ...searchParams });
                }
            })
        }
    }, [authDismissedReson]);

    useEffect(() => {
        getMembers({ page, size, status, ...searchParams });
    }, [page, size, searchParams]);

    return (
        <>
            <div className="main-content-body">
                <div className="member-detail">
                    <div className="detail-header">
                        <Form
                            form={filterForm}
                            name="filterForm"
                            onFinish={onFormFinish}
                            {...filterFormItemLayout}
                        >
                            <Row>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="username" label="姓名:">
                                        <Input placeholder="请输入姓名" />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="mobile" label="手机号:">
                                        <Input placeholder="请输入手机号" />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name='identityType' label='身份类型:'>
                                        <Select placeholder='请选择身份'
                                            options={identifyOptions}
                                            fieldNames={{
                                                label: 'name',
                                                value: 'code'
                                            }}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">检索</Button>
                                        <Button type="default" onClick={onReset}>重置</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <div className="member-message">
                        <div className="member-left">
                            <Button type="primary" onClick={addMember}>添加成员</Button>
                        </div>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        scroll={{
                            x: 2000,
                        }}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: size,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />

                    <Modal
                        width={700}
                        title={currentId ? '编辑成员' : '添加成员'}
                        open={isModalApplyOpen}
                        onOk={handleApplyOk}
                        onCancel={handleApplyCancel}
                        className="memberModal"
                    >
                        <Form
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 19,
                            }}
                            name="memberAddForm"
                            form={memberAddForm}
                            size="large"
                            autoComplete="off"
                            initialValues={selectedTag.code}
                        >

                            <Form.Item name="identityType" label='加入身份:' rules={[{ required: true, message: '请选择加入身份' }]}>
                                <>
                                    <Space size={[0, 8]}>
                                        {identifyOptions.map((tag) => (
                                            <CheckableTag
                                                key={tag.code}
                                                checked={selectedTag.code == tag.code}
                                                onChange={currentId ? () => { return } : (checked) => handleTagsChange(tag, checked)}
                                            >
                                                {tag.name}
                                            </CheckableTag>
                                        ))}
                                    </Space>
                                    <div className='tagTips'> {`说明：${selectedTag.tips}`}</div>
                                </>
                            </Form.Item>

                            <Form.Item
                                name="memMobile"
                                label="手机号"
                                rules={[
                                    { required: true, message: '请填写手机号码' },
                                    { pattern: /^1\d{10}$/, message: '请输入正确的手机号码' },
                                    {
                                        validator: async (rule, value) => {
                                            if (currentId || !value || value.length < 11) {
                                                return
                                            }
                                            let res = await membersInfoByMobileApi({ mobile: value });
                                            if (res.data) {
                                                if (selectedTag.code === 'person' && (res.data.memAuthStatus === 2 && res.data.memIsyijiehui === 1 && res.data.memType === 1)) {
                                                    setPersonInfo(res.data);
                                                    memberAddForm.setFieldsValue({
                                                        unitName: res.data.memUnit,
                                                        memRealName: res.data.memUsername
                                                    });
                                                    // console.log('nickname: ',res.data.memUsername);
                                                    return Promise.resolve();

                                                } else if (selectedTag.code === 'hospital' && (res.data.memAuthStatus === 2 && res.data.orgAuthType === 2 && res.data.memType === 2)) {
                                                    setPersonInfo(res.data);
                                                    memberAddForm.setFieldsValue({
                                                        unitName: res.data.orgname
                                                    });
                                                    return Promise.resolve();

                                                } else if (selectedTag.code === 'enterprise' && (res.data.memAuthStatus === 2 && res.data.orgAuthType === 0 && res.data.memType === 2)) {
                                                    setPersonInfo(res.data);
                                                    memberAddForm.setFieldsValue({
                                                        unitName: res.data.orgname
                                                    });
                                                    return Promise.resolve();

                                                }
                                                else if (selectedTag.code === 'medium' && (res.data.memAuthStatus === 2 && res.data.orgAuthType === 1 && res.data.memType === 2)) {
                                                    setPersonInfo(res.data);
                                                    memberAddForm.setFieldsValue({
                                                        unitName: res.data.orgname
                                                    });
                                                    return Promise.resolve();

                                                }
                                                else if (selectedTag.code === 'organization' && (res.data.memAuthStatus === 2 && res.data.orgAuthType === 3 && res.data.memType === 2)) {
                                                    setPersonInfo(res.data);
                                                    memberAddForm.setFieldsValue({
                                                        unitName: res.data.orgname
                                                    });
                                                    return Promise.resolve();

                                                }
                                            }
                                            return Promise.reject(`添加失败，${selectedTag.tips}`);
                                        },
                                    }

                                ]}>
                                <Input placeholder="请输入手机号" maxLength={11} disabled={currentId ? true : false} />
                            </Form.Item>

                            <Form.Item name="unitName" label="单位名称">
                                <Input placeholder="单位名称" disabled />
                            </Form.Item>

                            {
                                selectedTag.code !== 'person' ? <>
                                    <Form.Item name="contactPerson" label="联系人姓名" rules={[{ required: true }]}>
                                        <Input placeholder="请输入联系人姓名" />
                                    </Form.Item>

                                    <Form.Item
                                        name="contactPhone"
                                        label="联系人手机号"
                                        rules={[
                                            { required: true, message: '请填写联系人手机号码' },
                                            { pattern: /^1\d{10}$/, message: '请输入正确的手机号码' }
                                        ]}>
                                        <Input placeholder="请输入联系人手机号" maxLength={11} />
                                    </Form.Item>
                                </> : <Form.Item name="memRealName" label="姓名">
                                    <Input placeholder="姓名" disabled />
                                </Form.Item>
                            }

                        </Form>
                    </Modal>

                    <ViewAuthInfoDrawer
                        setVisibleAuthInfoDrawer={setVisibleAuthInfoDrawer}
                        visibleAuthInfoDrawer={visibleAuthInfoDrawer}
                        authInfo={authInfo}
                        ssoMember={ssoMember}
                        auditThrough={auditThrough}
                        auditDismissed={auditDismissed}
                        isShowFooter={isShowFooter}
                        authStatus={authStatus}
                    />

                    <AuditDismissedModal
                        visibleAuthDismissedModal={visibleAuthDismissedModal}
                        setVisibleAuthDismissedModal={setVisibleAuthDismissedModal}
                        setAuthDismissedReson={setAuthDismissedReson}
                    />

                </div>
            </div>
        </>
    );
}

export default MemberList;
