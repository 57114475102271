
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Table, Modal, Button,message} from 'antd';
import { confirmedAgendaQueryListApi,confirmedAgendaApi } from '@services/SereviseConst';
import { convertDateTimeRangeToLocalText } from '@utils/utils';
import './ConfirmScheduleModal.less';


// 选择专家
const ConfirmScheduleModal = ({ openConfirmScheduleModal, setOpenConfirmScheduleModal, expertId, updateList }) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [agendasList, setAgendasList] = useState([]);

    const onCancel = () => {
        setOpenConfirmScheduleModal(false);
    };
    const onConfirm = () => {
        Modal.confirm({
            title: '提示',
            width: 480,
            content: `我已和专家确认实际日程出席情况，专家已确认不用调整。`,
            centered: true,
            okText: '确认',
            cancelText: '未确认',
            onOk: () => {
                confirmedAgendaApi({
                    expertId: expertId,
                    projectId: conferenceId
                }).then(res => {
                    message.success("操作成功")
                    setOpenConfirmScheduleModal(false)
                    updateList()
                });
            }
        });
    };
    // 劳务费列表
    const getList = async () => {
        setLoading(true);
        const res = await confirmedAgendaQueryListApi({
            conferenceId: conferenceId,
            expertId: expertId
        });
        if (res.status_code === 200) {
            setData(res.data);
            if(res.data.agendasGuestList){
                setAgendasList(res.data.agendasGuestList)
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if(expertId>0 && openConfirmScheduleModal){
            getList();
        }
    }, [openConfirmScheduleModal]);

    let columns = [
        {
            title: '实际日程',
            dataIndex: 'schedule',
            width: 350,
            render: (_, record) => (<>
                <span>{record.conferenceHallName}{data.conferenceName} / {record.title}</span>
            </>),
        },
        {
            title: '任务',
            dataIndex: 'taskName',
            key: 'taskName',
            align:"center"
        },
        {
            title: '时间',
            align:"center",
            render: (_, record) => (<>
                <span>{convertDateTimeRangeToLocalText(record.startTime, record.endTime, 0)}</span>
            </>),
        }
    ];

    return (
        <>
            <Modal
                title='确认实际日程'
                open={openConfirmScheduleModal}
                centered
                width={800}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="primary" onClick={onConfirm}>确认实际日程</Button>
                ]}
            >
                <div className="name" style={{marginBottom: 12}}>专家姓名: {data.username}</div>
                <Table
                    columns={columns}
                    dataSource={agendasList}
                    loading={loading}
                    rowKey={record => record.expertId}
                    pagination={false}
                />
            </Modal>
        </>
    );
}

export default ConfirmScheduleModal;
