import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Layout, Modal, Tree, Tag, message, Dropdown, Menu } from 'antd';
import './LiveCategories.less'
import LiveCategoriesAddForm from './LiveCategoriesAddForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { get, put, post, del } from '@/core/request';

const { Content } = Layout;

export default function LiveCategories() {

    // 新增modal相关
    const [isModalVisible, setIsModalVisible] = useState(false);
    // 渲染分类的树状结构
    const [categorieTree, setCategorieTree] = useState([]);

    // 点击删除
    const deleteConfirm = (element) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确认是否删除分类',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                del('/dms/lives/categories', {
                    ids: [element.id],
                }).then(res => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getData();
                    }
                })
            }
        });
    };

    // 操作区禁用  modal相关
    const stopConfirm = (element) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要启用/禁用选中标签',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                let status = 1;
                if (element.status === 1) {
                    status = 0;
                }
                put(`/dms/lives/categories/status`, {
                    ids: [element.id],
                    status
                }).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getData();
                    }
                })
            }
        });
    }

    const [modalData, setModalData] = useState({
        tip: '',
        row: {}
    })

    const showModal = (tip, elem) => {
        return () => {
            setModalData({
                tip,
                row: elem
            })
            setIsModalVisible(true);
        }
    }

    // 点击禁用和删除
    const handleMenuClick = (elem) => {
        return (e) => {
            if (e.key === 'status') {
                stopConfirm(elem)
            } else {
                deleteConfirm(elem)
            }
        }
    }

    const handleMouseEnter = (e) => {
        if (e.target.className === 'edit') {
            e.target.classList.add('mystyle')
        }
    }

    const handleMouseLeave = (e) => {
        e.target.classList.remove('mystyle')
    }

    const handleMouseLeaveInner = (e) => {
        e.currentTarget.parentNode.classList.remove('mystyle')
    }

    const rendertitle = (left, element, nameClass) => {
        const menu = (
            <Menu onClick={handleMenuClick(element)}>
                <Menu.Item key="status">{element.status === 0 ? '启用' : '禁用'}</Menu.Item>
                <Menu.Item key="delete">删除</Menu.Item>
            </Menu>
        );
        return (
            <div className="edit" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {element.name}{element.status === 0 && <Tag color="orange">禁用</Tag>}
                <div className={nameClass} style={{ left }} onMouseLeave={handleMouseLeaveInner}>
                    <Dropdown.Button onClick={showModal('edit', element)} overlay={menu} size='small'>
                        编辑
                    </Dropdown.Button>
                </div>
            </div>
        )
    };

    // 控制展开和关闭
    const [openBoolean, setOpenBoolean] = useState(true);
    const [openKey, setOpenkey] = useState([]);

    // 默认展开和默认关闭
    const handleOpenKeys = (tip) => {
        return () => {
            if (tip === 'open') {
                setOpenBoolean(true)
            } else {
                setOpenBoolean(false);
                setOpenkey([]);
            }
        }
    };

    const handleExpand = (expandedKeys) => {
        setOpenkey(expandedKeys);
    }

    const getData = () => {
        get(`/dms/lives/categories`).then((res) => {
            let arr = [];
            let result = [];
            if (res.status_code === 200) {
                result = res.data.map(item => {
                    const { id, children } = item;
                    arr.push(id);
                    return {
                        ...item,
                        title: rendertitle('300px', item, 'tree-edit-first'),
                        key: item.id,
                        children: children?.length && children.map(elem => {
                            return {
                                ...elem,
                                title: rendertitle('150px', elem, 'tree-edit'),
                                key: elem.id
                            }
                        })
                    }
                });
                setOpenkey(arr)
                setCategorieTree(result);
            }
        })
    }

    // 渲染treeList数据
    useEffect(() => {
        getData()
    }, []);


    // 新增分类
    const handleAddCategotie = (value) => {
        post(`/dms/lives/categories`, value).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    };

    // 编辑分类
    const handleEditCategotie = (value) => {
        put(`/dms/lives/categories`, value).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    };

    return (
        <Fragment>
            <div className="page-title">直播分类管理</div>

            <Content className="main-content-box">

                <div className="main-content-body">

                    <div className="table-caption">
                        <Button type="primary" style={{ marginRight: '20px' }} onClick={showModal('add')}>新增分类</Button>
                        <Button style={{ marginRight: '20px' }} onClick={handleOpenKeys('open')}>默认展开</Button>
                        <Button style={{ marginRight: '20px' }} onClick={handleOpenKeys('close')}>默认关闭</Button>
                    </div>

                    {
                        categorieTree.length > 0 && openBoolean && <Tree
                            defaultExpandAll={true}
                            treeData={categorieTree}
                            blockNode
                        />
                    }

                    {
                        categorieTree.length > 0 && openBoolean === false && <Tree
                            expandedKeys={openKey}
                            treeData={categorieTree}
                            blockNode
                            onExpand={handleExpand}
                        />
                    }

                </div>
            </Content>

            {/* 点击新增的modal */}
            {
                isModalVisible && <Modal
                    visible={isModalVisible}
                    closable={false}
                    footer={null}>
                    <LiveCategoriesAddForm
                        modalData={modalData}
                        handleAddCategotie={handleAddCategotie}
                        setIsModalVisible={setIsModalVisible}
                        handleEditCategotie={handleEditCategotie}
                    ></LiveCategoriesAddForm>
                </Modal>
            }

        </Fragment>
    )
}
