// 含税劳务费请款审批状态
export const LaborFeeRequestStatusList = [
    { status: 0, label: '待审批' },
    { status: 1, label: '同意', textType: 'success' },
    { status: 2, label: '不同意', textType: 'danger' },
    { status: 3, label: '撤销审批', textType: 'secondary' },
];

// 劳务费付款状态
export const LaborFeePaymentStatusList = [
    { status: 0, label: '未付款' },
    { status: 1, label: '已付款' },
];
