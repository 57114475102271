module.exports = [
    "授权书中健康界账号填写有误，应填写手机号或邮箱。",
    "医疗机构名称填写有误，请于执业许可证中机构名称保持一致。",
    "认证申请函中运营人员与联系人名字不一致。",
    "认证申请函照片上传有误，请从网站下载模板。",
    "认证申请函缺少公章，请上传带公章版本文件。",
    "真实姓名请与认证照片中的姓名一致，谢谢。",
    "请在真实姓名处填写自己的真实姓名，不要填写身份证号、证件名称、编号等。",
    "您上传的认证照片不清晰，请上传清晰可辨度高的认证照片，谢谢。",
    "您提交的认证资料与系统中其他用户重合，请提交您个人的认证资料，谢谢。",
    "个人资料单位名称请与认证照片中的单位名称一致。",
    "您的单位资料填写不合规，请重新提交资料审核。",
    "您的性别与认证照片中不符。",
    "请您重新上传：执业资格证、工作证、胸牌（三选一）。",
    "请您重新上传：工作证、名片（二选一）。",
    "请您重新上传：学生证。",
    "请您重新上传：请填写身份证号码。",
    "您上传的资料有误，请上传您的工作证、胸牌、职业医师资格证（3选1）。"
  ];
