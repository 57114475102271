import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { drawerFormItemLayout } from '@utils/property';
import { useNavigate } from 'react-router-dom'
import { put, post } from '@/core/request';

export default function ForgetPassword() {

    const navigate = useNavigate();

    let [time, setTime] = useState(59);
    // 获取验证码用
    const [verify, setVerify] = useState({
        title: '获取验证码',
        bool: true
    });
    // 找回密码
    const [information, setInformation] = useState({
        phone: '', verificationCode: '', pwd: '', confirmPwd: ''
    });

    const handlePwdChange = (tip) => {
        return (e) => {
            setInformation({
                ...information,
                [tip]: e.target.value.trim()
            })
        }
    }

    const handleNextClick = () => {
        if (!information.verificationCode) return message.error('验证码不能为空');
        if (!information.confirmPwd) return message.error('确认密码不能为空');
        if (information.pwd === information.confirmPwd) {
            put(`/enterprise/staffs/find-pwd`, {
                phone: information.phone,
                pwd: information.pwd,
                verificationCode: information.verificationCode
            }).then((res) => {
                if (res.status_code === 200) {
                    message.success('重置密码成功！请重新登录。')
                    return navigate('/login');
                }
            })
        } else {
            return message.error("两次输入的密码不一致");
        }

    };

    const countDown = () => {
        setInterval(() => {
            if (time >= 1) {
                setTime(--time)
            } else {
                setVerify({
                    ...verify,
                    bool: true
                })
            }
        }, 1000)
    }

    const getCode = () => {
        post(`/enterprise/staffs/send-sms`, {
            phone: information.phone,
            type: 1
        }).then(res => {
            if (res.status_code === 200) {
                setVerify({
                    ...verify,
                    bool: false
                })
                countDown()
            }
        }
        )
    };

    return (
        <div className='forget-password-box'>
            <Card className="forget-password-content" bordered={false}>
                <h2>找回密码</h2>
                <span>验证码将会发送至你的手机</span>
                <Form {...drawerFormItemLayout}>
                <Input  allowClear placeholder="请输入手机号" autoComplete="off" className='hidden-show-phone'/>
                <Input.Password  allowClear placeholder="请输入手机号" autoComplete="off" className='hidden-show-phone'/>
                    <Form.Item
                        name="phone"
                        label="手机号"
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!/^1\d{10}$/.test(value)) {
                                        return Promise.reject('手机号不正确');
                                    } else {
                                        return Promise.resolve()
                                    }
                                },
                            }
                        ]}
                    >
                        <Input
                            onChange={handlePwdChange('phone')}
                            size='large'
                            allowClear
                            placeholder="请输入手机号"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item size="large" label="验证码">
                        <Input.Group compact >
                            <Input size="large" style={{ width: 'calc(100% - 121px)' }} placeholder="请输入验证码" onChange={handlePwdChange('verificationCode')} />
                            {
                                verify.bool ? <Button size="large" type="default" onClick={getCode} style={{ width: '121px' }}>{verify.title}</Button> :
                                    <Button size="large" type="default" disabled style={{ width: '121px' }}>{time}s重新获取</Button>
                            }
                        </Input.Group>
                    </Form.Item>

                    <Form.Item
                        name="pwd"
                        help="密码不少于6位，必须包含数字或字母（大写或小写）"
                        label="新密码"
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/.test(value)) {
                                        return Promise.reject('密码不少于6位，必须包含数字或字母（大写或小写');
                                    }
                                    return Promise.resolve()
                                },
                            }
                        ]}
                    >
                        <Input.Password placeholder="请输入新密码" allowClear size='large' onChange={handlePwdChange('pwd')} autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        name="confirmPwd"
                        label="确认密码"
                    >
                        <Input.Password placeholder="请重复新密码以确认" allowClear size='large' onChange={handlePwdChange('confirmPwd')} autoComplete="off" />
                    </Form.Item>

                    <Button block type="primary" htmlType="submit" size='large' onClick={handleNextClick}>重置</Button>

                </Form>
            </Card>


        </div>
    )
}
