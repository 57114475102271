import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Checkbox, Modal, Form, Layout, Input, Space, Switch, Radio, message, Typography } from 'antd';
import { LeftOutlined, PlusOutlined, DragOutlined, DeleteOutlined } from '@ant-design/icons';
import { pageFormItemWithoutLabelGridLayout } from '../../../utils/property';
import { liveSettingApi, getLiveInfoApi } from "@services/Conference";
import './index.less';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';

const { Content } = Layout;
const { Text } = Typography;

let registrationId = undefined; // 报名id

// 可拖拽组件
const SortableItem = ({ id, field, remove, getQuestionType }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0) scale(1)`,
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes}>
            <div className="drag-form-item">
                <div className="registration-right-drag-form-head">
                    <div className="head-left">
                        <div className="question-title">{getQuestionType(field.name)}</div>
                        <Form.Item
                            {...field}
                            name={[field.name, 'required']}
                            valuePropName="checked"
                        >
                            <Checkbox value="必填" />
                        </Form.Item>
                        必填
                    </div>
                    <div className="head-right">
                        <Button type="link" {...listeners}><DragOutlined /></Button>
                        <Button type="link" onClick={() => {
                            Modal.confirm({
                                title: '提示',
                                content: '若该问题已有用户填报，删除该问题会导致已填数据丢失确认删除？',
                                cancelText: '取消',
                                okText: '确认',
                                closable: true,
                                onOk: () => remove(field.name)
                            })
                        }}>
                            <DeleteOutlined />
                        </Button>
                    </div>
                </div>
                <div className="registration-question-content">
                    <span className="registration-question-index">{field.name + 1}.</span>
                    <Form.Item
                        {...field}
                        name={[field.name, 'content']}
                        rules={[{ required: true, message: '请输入题目内容' }]}
                    >
                        <Input placeholder='请输入题目内容' />
                    </Form.Item>
                </div>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const isRadio = !!getFieldValue('registrationQuestions') && getFieldValue('registrationQuestions')[field.name]?.questionType === 'radio';
                        const isCheckbox = !!getFieldValue('registrationQuestions') && getFieldValue('registrationQuestions')[field.name]?.questionType === 'checkbox';
                        return (isRadio || isCheckbox) && (
                            <Form.List name={[field.name, 'options']}>
                                {(optionFields, { add, remove }) => (
                                    <>
                                        {optionFields.map((optionField, optionIndex) => (
                                            <div key={optionField.key}>
                                                <div className="registration-right-drag-option-head">
                                                    {isRadio && <Radio disabled />}
                                                    {isCheckbox && <Checkbox disabled />}
                                                    <Form.Item
                                                        {...optionField}
                                                        name={[optionField.name, 'content']}
                                                        rules={[{ required: true, message: '请输入选项内容' }]}
                                                        style={{ width: 'calc(100% - 70px)' }}
                                                    >
                                                        <Input placeholder='请输入选项内容' />
                                                    </Form.Item>
                                                    {
                                                        optionIndex > 1 &&
                                                        <Button type="link" onClick={() => {
                                                            Modal.confirm({
                                                                title: '提示',
                                                                content: '若该问题已有用户填报，删除该选项会导致已填数据丢失确认删除？',
                                                                cancelText: '取消',
                                                                okText: '确认',
                                                                closable: true,
                                                                onOk: () => remove(optionField.name)
                                                            })
                                                        }}>
                                                            <DeleteOutlined />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        <Button type="primary" icon={<PlusOutlined />} ghost onClick={() => add()} disabled={optionFields.length >= 20}>新增选项</Button>
                                    </>
                                )}
                            </Form.List>
                        );
                    }}
                </Form.Item>
            </div>
        </div>
    );
}

export default function LiveLimit() {
    const navigate = useNavigate();
    const CheckboxGroup = Checkbox.Group;
    const params = useParams();
    const location = useLocation();
    const conferenceId = parseInt(params?.cid) || 0; // 会议项目 ID
    const liveId = parseInt(params?.id) || 0; // 直播 ID
    const [form] = Form.useForm();

    const [indeterminate, setIndeterminate] = useState(false);
    const [orgindeterminate, setOrgIndeterminate] = useState(false);
    const [title, setTitle] = useState(undefined);
    const [checkPersonalAll, setCheckPersonalAll] = useState(false);
    const [checkOrganizationAll, setCheckOrganizationAll] = useState(false);
    const [percheckedList, setPerCheckedList] = useState([]);
    const [orgcheckedList, setOrgCheckedList] = useState([]);
    const [switchCase, setSwitchCase] = useState(undefined)
    const plainOptions = [{ label: '医疗机构从业者', value: 1 }, { label: '社会团体人士', value: 2 }, { label: '政府官员', value: 3 }, { label: '教育科研人士', value: 4 }, { label: '商业人士', value: 5 }];
    const orgPlainOptions = [{ label: '医疗机构', value: 6 }, { label: '企业', value: 7 }, { label: '媒体', value: 8 }, { label: '机构', value: 9 }]

    const [person_auth_fields_enabled, setPersonAuthFieldsEnabled] = useState(true); //个人认证字段勾选
    const [mem_identity_path_enabled, setMemIdentityPathEnabled] = useState(true); // 认证资料勾选

    const regQuestions = Form.useWatch('registrationQuestions', form);
    const checkRegistrationEnabled = Form.useWatch('registrationEnabled', form);

    const onCheckAllChange = (e, type) => {
        if (type === 'person') {
            let person = plainOptions.map(item => item.value)
            setPerCheckedList(e.target.checked ? person : []);
            setIndeterminate(false);
            setCheckPersonalAll(e.target.checked);
        } else if (type === 'organization') {
            let organization = orgPlainOptions.map(item => item.value)
            setOrgCheckedList(e.target.checked ? organization : []);
            setOrgIndeterminate(false);
            setCheckOrganizationAll(e.target.checked);
        }
    };
    const onChange = (list, type) => {
        if (type === 'person') {
            setPerCheckedList(list);
            setIndeterminate(!!list.length && list.length < plainOptions.length);
            setCheckPersonalAll(list.length === plainOptions.length);
        } else if (type === 'organization') {
            setOrgCheckedList(list);
            setOrgIndeterminate(!!list.length && list.length < orgPlainOptions.length);
            setCheckOrganizationAll(list.length === orgPlainOptions.length);
        }

    };

    // 个人认证字段勾选
    const personAuthAieldsChange = (e) => {
        setPersonAuthFieldsEnabled(e.target.checked);
        setMemIdentityPathEnabled(e.target.checked);
    };

    // 认证资料字段勾选
    const memIdentityPathChange = (e) => {
        setMemIdentityPathEnabled(e.target.checked);
    };

    const onFinish = () => {
        // 报名观看开启时，（自定义字段 && 个人认证字段勾选） - 不能同时为空
        if (!!checkRegistrationEnabled && !person_auth_fields_enabled && _.isEmpty(regQuestions)) {
            return message.error('请添加报名字段');
        }

        let allList = [...percheckedList, ...orgcheckedList].join()
        if (form.getFieldValue('needLogin') === undefined) form.setFieldValue('needLogin', 0);
        if (form.getFieldValue('authEnabled') === undefined) form.setFieldValue('authEnabled', 0);
        if (form.getFieldValue('whitelistEnabled') === undefined) form.setFieldValue('whitelistEnabled', 0);
        form.setFieldValue('authType', allList);
        let authType = form.getFieldValue('authEnabled') === 0 && '0';

        form.validateFields().then((values) => {
            const params = {
                id: liveId,
                authType,
                ...form.getFieldsValue(),
                registration: {
                    id: registrationId,
                    activityType: 'live',
                    authFieldsSettings: JSON.stringify({
                        person_auth_fields_enabled,
                        mem_identity_path_enabled
                    }),
                    registrationDescription: values.registrationDescription,
                    registrationQuestions: values.registrationQuestions
                }
            };
            delete params.registrationQuestions;
            delete params.registrationDescription;
            console.log(params)

            liveSettingApi(params).then((res) => {
                if (res.status_code === 200) {
                    message.success('保存成功');
                    navigate(`/conf/conferences/${conferenceId}/lives`)
                }
            })
        })

        // console.log(values);
    };

    const onCancel = () => {
        Modal.confirm({
            title: '提示',
            content: '可能有数据变动，确认不保存？',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: () => navigate(`/conf/conferences/${conferenceId}/lives`)
        })
    };

    const goBack = () => {
        Modal.confirm({
            title: '提示',
            content: '可能有数据变动，确认不保存？',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: () => navigate(`/conf/conferences/${conferenceId}/lives`)
        })
    }

    const hideChange = (checked, type) => {
        let obj
        switch (type) {
            case 'needLogin':
                let needLogin = Number(checked);
                if (checked) {
                    form.setFieldValue(type, needLogin);
                    obj = {
                        ...switchCase,
                        needLogin: checked
                    }
                } else {
                    form.setFieldValue(type, needLogin);
                    form.setFieldValue('authEnabled', 0);
                    form.setFieldValue('whitelistEnabled', 0);
                    form.setFieldValue('registrationEnabled', 0);
                    obj = {
                        needLogin: checked,
                        authEnabled: checked,
                        whitelistEnabled: checked,
                        registrationEnabled: checked
                    }
                    setPerCheckedList([])
                    setOrgCheckedList([])
                }

                setSwitchCase({ ...obj })
                break;
            case 'authEnabled':
                let authEnabled = Number(checked);
                form.setFieldValue(type, authEnabled);
                console.log(form.getFieldValue('needLogin'));
                if (form.getFieldValue('needLogin') === 1 && authEnabled === 0) {
                    form.setFieldValue('needLogin', 1);
                } else {
                    form.setFieldValue('needLogin', authEnabled);
                }
                // 开启认证，则关闭报名（互斥）
                if (authEnabled) {
                    form.setFieldValue('registrationEnabled', 0);
                }
                obj = {
                    ...switchCase,
                    needLogin: checked ? true : switchCase.needLogin,
                    authEnabled: checked,
                    registrationEnabled: checked ? false : switchCase.registrationEnabled,
                }
                setSwitchCase({ ...obj })
                setIndeterminate(false)
                setOrgIndeterminate(false)
                break;
            case 'whitelistEnabled':
                let whitelistEnabled = Number(checked);
                form.setFieldValue(type, whitelistEnabled);
                if (form.getFieldValue('needLogin') === 1 && whitelistEnabled === 0) {
                    form.setFieldValue('needLogin', 1);
                } else {
                    form.setFieldValue('needLogin', whitelistEnabled);
                }
                obj = {
                    ...switchCase,
                    needLogin: checked ? true : switchCase.needLogin,
                    whitelistEnabled: checked,
                }
                setSwitchCase({ ...obj })
                setIndeterminate(false)
                setOrgIndeterminate(false)
                break;

            // 开启报名时 - 默认开启登录，关闭认证
            case 'registrationEnabled':
                let registrationEnabled = Number(checked);
                form.setFieldValue(type, registrationEnabled);
                if (registrationEnabled) {
                    form.setFieldValue('needLogin', 1);
                    form.setFieldValue('authEnabled', 0);
                }
                obj = {
                    ...switchCase,
                    needLogin: checked || switchCase.needLogin,
                    authEnabled: checked ? false : switchCase.authEnabled,
                    registrationEnabled: checked
                }
                setSwitchCase({ ...obj })
                setIndeterminate(false)
                setOrgIndeterminate(false)
                break;
            default:
                break;
        }
    }

    const goToWhitelist = () => {
        let allList = [...percheckedList, ...orgcheckedList].join() === '0' ? '' : [...percheckedList, ...orgcheckedList].join()
        navigate(`/conf/conferences/${conferenceId}/lives/${liveId}/whitelist`, { state: { whitelistEnabled: 1, needLogin: 1, allList: allList, title: title } })
    }

    // 添加单选题
    const addRadio = () => {
        form.setFieldsValue({
            registrationQuestions: [
                ...(form.getFieldValue('registrationQuestions') || []),
                { required: true, content: "", questionType: 'radio', options: [{ content: "" }, { content: "" }] }
            ]
        });
    };

    // 添加多选题
    const addCheckbox = () => {
        form.setFieldsValue({
            registrationQuestions: [
                ...(form.getFieldValue('registrationQuestions') || []),
                { required: true, content: "", questionType: 'checkbox', options: [{ content: "" }, { content: "" }] }
            ]
        });
    };

    // 添加填空题
    const addText = () => {
        form.setFieldsValue({
            registrationQuestions: [
                ...(form.getFieldValue('registrationQuestions') || []),
                { required: true, content: "", questionType: 'text' }
            ]
        });
    };

    // 处理拖拽结束
    const handleDragEnd = (event) => {
        const { active, over } = event;
        const registrationQuestions = form.getFieldValue('registrationQuestions');
        if (over && active.id !== over.id) {
            const oldIndex = registrationQuestions.findIndex((_, index) => index === active?.data?.current?.sortable?.index);
            const newIndex = registrationQuestions.findIndex((_, index) => index === over?.data?.current?.sortable?.index);
            const newFields = arrayMove(registrationQuestions, oldIndex, newIndex);
            form.setFieldsValue({ registrationQuestions: newFields });
        }
    };

    // 题目类型名称
    const getQuestionType = (index) => {
        const list = form.getFieldValue('registrationQuestions') || [];
        if (list[index]) {
            if (list[index].questionType === "radio") {
                return "单选题"
            }
            if (list[index].questionType === "checkbox") {
                return "多选题"
            }
            if (list[index].questionType === "text") {
                return "问答题"
            }
        }
        return ""
    };

    // 报名观看 - 处理表单数据回显
    const dealSignForm = (info) => {
        if (info?.registration) {
            registrationId = info.registration.id;
            form.setFieldValue('registrationDescription', info.registration.registrationDescription);
            form.setFieldValue('registrationQuestions', info.registration.registrationQuestions);
            if (info.registration.authFieldsSettings) {
                const authFieldsSettings = JSON.parse(info.registration.authFieldsSettings);
                setPersonAuthFieldsEnabled(authFieldsSettings.person_auth_fields_enabled);
                setMemIdentityPathEnabled(authFieldsSettings.mem_identity_path_enabled);
            }
        }
    }

    const getLiveInfo = () => {
        getLiveInfoApi({ id: liveId }).then(res => {
            if (res.status_code == 200) {
                dealSignForm(res.data);
                setTitle(res.data.name)
                form.setFieldsValue(res.data);
                let obj = {
                    needLogin: res.data.needLogin,
                    authEnabled: res.data.authEnabled,
                    whitelistEnabled: res.data.whitelistEnabled,
                    registrationEnabled: res.data.registrationEnabled
                }
                console.log(obj);
                setSwitchCase(
                    {
                        needLogin: obj.needLogin === 1 ? true : false,
                        authEnabled: obj.authEnabled === 1 ? true : false,
                        whitelistEnabled: obj.whitelistEnabled === 1 ? true : false,
                        registrationEnabled: obj.registrationEnabled === 1 ? true : false
                    }
                )

                if (location.state) {
                    console.log(location.state)

                    form.setFieldValue('whitelistEnabled', 1)
                    form.setFieldValue('needLogin', 1)
                    obj.whitelistEnabled = 1
                    obj.needLogin = 1
                    setSwitchCase(
                        {
                            needLogin: obj.needLogin === 1 ? true : false,
                            authEnabled: obj.authEnabled === 1 ? true : false,
                            whitelistEnabled: obj.whitelistEnabled === 1 ? true : false,
                        }
                    )
                }
                if (Number(res.data.authType) !== 0) {
                    console.log(1);
                    setPerCheckedList([])
                    setOrgCheckedList([])
                }
                let allList = res.data.authType.split(','), pre = [], org = []
                if (location.state) {
                    console.log(location.state.allList.length);
                    if (location.state.allList.length > 0) {
                        form.setFieldValue('authEnabled', 1)
                        obj.authEnabled = 1
                        setSwitchCase(
                            {
                                needLogin: obj.needLogin === 1 ? true : false,
                                authEnabled: obj.authEnabled === 1 ? true : false,
                                whitelistEnabled: obj.whitelistEnabled === 1 ? true : false,
                            }
                        )
                        allList = location.state.allList.length > 0 && location.state.allList !== '0' ? location.state.allList.split(',') : []
                        console.log(allList);
                    }

                }
                allList.map(item => {
                    if (Number(item) <= 5) {
                        pre.push(Number(item))
                    } else {
                        org.push(Number(item))
                    }
                })
                setPerCheckedList([...pre])
                setIndeterminate(!!pre.length && pre.length < plainOptions.length);
                if (pre.length === plainOptions.length) {
                    setCheckPersonalAll(true)
                }
                setOrgCheckedList([...org])
                setOrgIndeterminate(!!org.length && org.length < orgPlainOptions.length);
                if (org.length === orgPlainOptions.length) {
                    setCheckOrganizationAll(true)
                }


            }
        })
    }

    useEffect(() => {
        getLiveInfo();
    }, [])

    return <Fragment>
        <div className="page-title">
            <Link className="back" onClick={() => goBack()}><LeftOutlined /></Link>
            <span className="current-title">观看限制</span>
            {!!liveId && <span className="current-subtitle">{title || ''}</span>}
        </div>

        <Content className="main-content-box live-limit-page">
            <div className="main-content-body">
                <Form form={form}>
                    <Form.Item
                        label="登录观看"
                        name="needLogin"
                        help="开启后用户只有登录才能观看该直播"
                        className='switch-item'
                    >
                        <Switch
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                            checked={switchCase?.needLogin}
                            onChange={(checked) => hideChange(checked, 'needLogin')}
                        />
                    </Form.Item>

                    <Form.Item
                        label="用户认证"
                        name="authEnabled"
                        help="开启用户认证后，只有满足限定认证类型的认证用户才能观看该直播"
                        className='switch-item'
                    >
                        <Switch
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                            checked={switchCase?.authEnabled}
                            onChange={(checked) => hideChange(checked, 'authEnabled')}
                        />
                    </Form.Item>


                    {switchCase?.authEnabled ? <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            return !!getFieldValue('authEnabled') && (
                                <Form.Item
                                    label="限定认证类型"
                                    name="authType"
                                    className='switch-item'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (rule, value) => {
                                                if (value === "" || value === '0') {
                                                    throw "开启用户认证限制时，认证类型为必选字段";
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <>
                                        <div className="personal-auth" style={{ marginBottom: 16 }}>
                                            <Checkbox style={{ width: 130, fontWeight: 600 }} indeterminate={indeterminate} onChange={(e) => onCheckAllChange(e, 'person')} checked={checkPersonalAll}>个人专业认证：</Checkbox>
                                            <CheckboxGroup options={plainOptions} value={percheckedList} onChange={(list) => onChange(list, 'person')} />
                                        </div>
                                        <div className="organization-auth">
                                            <Checkbox style={{ width: 130, fontWeight: 600 }} indeterminate={orgindeterminate} onChange={(e) => onCheckAllChange(e, 'organization')} checked={checkOrganizationAll}>机构认证：</Checkbox>
                                            <CheckboxGroup options={orgPlainOptions} value={orgcheckedList} onChange={(list) => onChange(list, 'organization')} />
                                        </div>
                                    </>
                                </Form.Item>
                            );
                        }}
                    </Form.Item> : null}

                    <Form.Item
                        label="观看白名单"
                        help="开启后可针对该直播添加白名单，白名单所列手机号用户不受其他限制，登录即可观看该直播"
                        className='switch-item'
                    >
                        <>
                            <Form.Item name="whitelistEnabled" noStyle>
                                <Switch
                                    checkedChildren="开启"
                                    unCheckedChildren="关闭"
                                    style={{ verticalAlign: 'middle' }}
                                    checked={switchCase?.whitelistEnabled}
                                    onChange={(checked) => hideChange(checked, 'whitelistEnabled')}
                                />
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    return !!getFieldValue('whitelistEnabled') && (
                                        <Button
                                            onClick={goToWhitelist}
                                            type='link'
                                            style={{ verticalAlign: 'middle', marginLeft: 8 }}
                                        >
                                            设置白名单
                                        </Button>
                                    );
                                }}
                            </Form.Item>

                        </>
                    </Form.Item>

                    <Form.Item
                        label="报名观看"
                        name="registrationEnabled"
                        help={<>
                            <div>开启报名，用户需提交报名信息（含用户认证和自定义字段的表单）才可观看直播，且会强制关闭用户认证功能</div>
                            <Text type="warning">关闭报名，以下报名表单将失效</Text>
                        </>}
                        className='switch-item'
                    >
                        <Switch
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                            checked={switchCase?.registrationEnabled}
                            onChange={(checked) => hideChange(checked, 'registrationEnabled')}
                        />
                    </Form.Item>

                    <div className="registration-wrap">
                        <div className="registration-left">
                            <div className="registration-left-info">
                                <div className="registration-left-info-title">基本信息</div>
                                <div>1. 若未勾选“个人认证”字段，用户不用填写个人信息；</div>
                                <div>2. 个人认证字段，会根据用户所选身份变化</div>
                                <div>3. 若未勾选“认证资料”字段，未认证用户提交报名时，系统将不会同步提交认证申请流程。</div>
                            </div>
                            <div className="registration-left-checkbox">
                                <Checkbox checked={person_auth_fields_enabled} onChange={personAuthAieldsChange}>个人认证字段</Checkbox>
                                <br />
                                <Checkbox defaultChecked disabled>您的身份</Checkbox>
                                <br />
                                <Checkbox defaultChecked disabled>姓名</Checkbox>
                                <br />
                                <Checkbox defaultChecked disabled>单位</Checkbox>
                                <br />
                                <Checkbox defaultChecked disabled>科室</Checkbox>
                                <br />
                                <Checkbox defaultChecked disabled>职务</Checkbox>
                                <br />
                                <Checkbox defaultChecked disabled>职称</Checkbox>
                                <br />
                                <Checkbox checked={mem_identity_path_enabled} onChange={memIdentityPathChange}>认证资料（图片）</Checkbox>
                            </div>
                            <div className="registration-left-add">
                                <div className="registration-left-add-title">自定义字段</div>
                                <div className="registration-left-add-desc">最多可添加20自定义字段</div>
                                <Space direction="vertical">
                                    <Button type="primary" block icon={<PlusOutlined />} ghost onClick={addRadio} disabled={regQuestions && regQuestions.length >= 20}>添加单选题</Button>
                                    <Button type="primary" block icon={<PlusOutlined />} ghost onClick={addCheckbox} disabled={regQuestions && regQuestions.length >= 20}>添加多选题</Button>
                                    <Button type="primary" block icon={<PlusOutlined />} ghost onClick={addText} disabled={regQuestions && regQuestions.length >= 20}>添加问答题</Button>
                                </Space>
                            </div>
                        </div>

                        <div className="registration-right">
                            <div className="registration-right-desc-title">报名表描述</div>
                            <Form.Item name="registrationDescription">
                                <Input.TextArea rows={4} placeholder='请输入报名表描述' maxLength={100} showCount />
                            </Form.Item>

                            <div className="registration-right-drag-form scrollable-container" >
                                <div className="registration-right-drag-form-title">自定义字段</div>
                                <DndContext onDragEnd={handleDragEnd}>
                                    <Form.List name="registrationQuestions">
                                        {(fields, { remove }) => (
                                            <SortableContext items={fields.map((field) => field.key)}>
                                                {fields.map((field, index) => (
                                                    <SortableItem key={field.key} id={field.key} field={field} remove={remove} getQuestionType={getQuestionType} />
                                                ))}
                                            </SortableContext>
                                        )}
                                    </Form.List>
                                </DndContext>
                            </div>
                        </div>
                    </div>

                    <Form.Item {...pageFormItemWithoutLabelGridLayout} className="page-form-foot-operations">
                        <Space size="large">
                            <Button type="primary" size="large" htmlType="submit" onClick={onFinish}>提交</Button>
                            <Button type="default" size="large" onClick={onCancel}>取消</Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
        </Content>

    </Fragment>;
}
