import { get, put, post, patch, delet } from '@core/request';

// 付款项目管理
export const fetchLaborFeeProjects = params => get('/user/laborfeerequestexaminerecords/list', params);

// 劳务费付款明细（列表）
export const fetchLaborFeePaymentList = params => get('/user/laborAudit/pay-batch-infos', params);

// 劳务费请款审批
export const examineLaborFeePayment = data => patch('/user/laborAudit/payment-batch-audit', data);

// 劳务费请款审批结果
export const fetchLaborFeePaymentExaminationResult = params => get('/user/laborAudit/payment-batch-audit-result', params);

// 确认付款
// 付款凭证
export const confirmPaymentOrAddCert = data => post('/user/laborAudit/payment', data);

// 导出请款清单
export const exportLaborFeePaymentList = params => get('/user/laborAudit/pay-batch-infos-download', params, { responseType: 'blob' });

// 付款异常备注
export const remarkPaymentException = data => post('/user/laborAudit/payment-error-record', data);

// 历史异常备注
export const fetchPaymentExceptionRemarks = params => get('/user/laborAudit/list-refuses', params);

// 含税劳务费请款审批（列表）
export const fetchLaborFeeExaminationList = params => get('/user/laborAudit/first-audit-info', params);

// 含税劳务费请款审批（审核）
export const examineLaborFee = data => patch('/user/laborAudit/audit', data);

// 实发劳费审核重置
export const resetLaborFeeConfirmation = data => put('/conference/boss/conferences/laborFeeConfirmedReset', data);

// 含税劳务费请款审批（重置）
export const resetLaborFeeWithTax = data => put('/user/laborfeerequestexaminerecords/successExamineUpdate', data);

// 含税劳务费请款审批（历史审核失败原因）
export const fetchLaborFeeExaminationRefuseReason = params => get('/user/laborAudit/list-refuses', params);

// 含税劳务费请款审批（结果）
export const fetchLaborFeeExaminationResult = data => post('/conference/boss/laborFee/laborFeeAmountResult', data);

// 劳务费付款管理（列表）
export const fetchLaborFeePaymentManageList = params => get('/user/laborfeerequestbatches/list', params);

// 上传附件
// TODO
export const uploadFileAPI = data => post('/user/file', data, { headers: { "Content-Type": "multipart/form-data" } });
