import React, { Fragment, message, useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { fileApi } from '@services/Invitation/Invitation';
import config from '@config';

Quill.register('modules/imageResize', ImageResize);

// 富文本编辑器 react-quill
const Editor = ({ editorContents, setEditorContents }) => {
    const refs = useRef()
    const option = { headers: { "Content-Type": "multipart/form-data" } }

    // 输入内容
    const onEditorChange = (text) => {
        setEditorContents(text);
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 图片上传
    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 13);
            fileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = refs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
            });
        };
    };

    // 富文本配置
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ align: 'right' }, { align: 'center' }, { align: 'justify' }], //文字对齐方式
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    return <Fragment>
        <div>
            <ReactQuill
                ref={refs}
                modules={modules}
                theme="snow"
                value={editorContents}
                onChange={onEditorChange}
            />
        </div>

    </Fragment>
};

export default Editor;
