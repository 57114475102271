import KbDashboard from '../components/Kb/Dashboard/KbDashboard';
import KbResearchHospital from '../components/Kb/ResearchHospital/KbResearchHospital'

import KbResearchBasicInfo from '../components/Kb/ResearchHospital/KbResearchBasicInfo'

import KbResearchHospitalVertifyDataForm from '../components/Kb/ResearchHospital/KbResearchHospitalVertifyDataForm.jsx';


// 知识库模块相关路由
const kbRoutes = [
    //首页
    {path: '', element: <KbDashboard/>},
    {path: '/kb/rearch', element: <KbResearchHospital/>},
    {path: '/kb/rearch/basic-info/:id', element: <KbResearchBasicInfo/>},
    {path: '/kb/vertify/:id', element: <KbResearchHospitalVertifyDataForm/>},
];

export default kbRoutes;
