import React, { Fragment, useState, useEffect } from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {LeftOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, message, Row, Space, Table} from "antd";
import {Content} from "antd/lib/layout/layout";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {queryFeeBatchDetailList, handleExportData} from '@services/MedConnect';

export default function LabfeeBatchesList() {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const batchNumber = params.getAll("batchNum")[0];

    let { id, batchNo } = useParams();
    id = !!id && parseInt(id) || 0;
    batchNo = !!batchNo && parseInt(batchNo) || 0;

    const [form] = Form.useForm();

    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const [detailInfo, setDetailInfo] = useState({});


    const getLabfeeBatchesList = async () => {
        try {
            setLoading(true);
            const params = {
                ...filter,
                batchId: batchNo,
            }
            queryFeeBatchDetailList(params).then(res => {
                if (res && res.success) {
                    setDetailInfo(res.data)
                    setTableData(res.data.pageResponse.data);
                    setTotal(res.data.pageResponse.totalPages);
                }
            })
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        }
    }

    // 检索
    const handleFilterFinish = (values) => {
        setFilter({
            ...filter,
            pageIndex: 1,
            userName: values.userName,
            phone: values.phone,
            hospital: values.hospital,
            department: values.department,
        })
    }

    // 重置
    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            pageIndex: 1,
        });
    }

    // 导出
    const exportInfo = () => {
        handleExportData({id: batchNo}).then(res => {
            if (res.success) {
                message.success('请稍后，数据正在导出');
                const fileName = "批次导出.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                a.href = res.data;
                a.dispatchEvent(event);
            }
        })
    }

    // 查看明细
    const handleDetail = (record) => {
        navigate(`/medconnect/projects/${id}/labfee/batches/${batchNo}/batch/${record.id}/detail?batchNum=${batchNumber}`);
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        { title: '医生', dataIndex: 'userName' },
        { title: '医生类型', dataIndex: 'userTypeDesc' },
        { title: '手机号', dataIndex: 'userPhone' },
        { title: '所属科室', dataIndex: 'department' },
        { title: '所属医院', dataIndex: 'hospital' },
        { title: '有效问答数', dataIndex: 'qaNum' },
        { title: '有效审核数', dataIndex: 'auditNum' },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 100,
            render: (_, record) => {

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDetail(record)}
                        >
                            查看明细
                        </Button>
                    </span>
                );
            }
        }
    ]

    useEffect(() => {
        getLabfeeBatchesList();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects/${id}/labfee/batches`} className="back"><LeftOutlined/></Link>
                <span className="current-title">劳务管理</span>
                <span className="current-subtitle">{`${batchNumber}批次`}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="医生" name="userName">
                                    <Input allowClear placeholder="请输入医生姓名" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="phone">
                                    <Input allowClear placeholder="请输入手机号" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="所属医院" name="hospital">
                                    <Input allowClear placeholder="请输入医院名称" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="科室" name="department">
                                    <Input allowClear placeholder="请输入科室" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={exportInfo}>导出</Button>
                        </div>
                    </div>

                    <div>共{detailInfo.userNum}位医生，{detailInfo.qaNum}个有效问答数</div>
                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>


            </Content>

        </Fragment>
    )

}
