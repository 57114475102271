import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import projects from '@utils/project';

const ProjectChart = ({ chartData }) => {
    let projectChart;

    const userNumData = [];
    chartData && chartData.persons && chartData.persons.forEach(item => {
        const data = {
            value: item.times,
            name: projects.find(p => p.code === item.code)?.name,
        };
        userNumData.push(data);
    });

    const userTimesData = [];
    chartData && chartData.personTimes && chartData.personTimes.forEach(item => {
        const data = {
            value: item.times,
            name: projects.find(p => p.code === item.code)?.name,
        };
        userTimesData.push(data);
    });

    function echartsFunction() {
        projectChart && projectChart.dispose();
        projectChart = echarts.init(document.getElementById('project-chart'));
        const option = {
            title: [
                {
                    text: '参与项目分布',
                    left: 'center'
                },
                {
                    subtext: '参与人数',
                    left: '25%',
                    top: '75%',
                    textAlign: 'center'
                },
                {
                    subtext: '参与人次',
                    left: '75%',
                    top: '75%',
                    textAlign: 'center'
                }
            ],
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    name: '参与人数',
                    type: 'pie',
                    radius: '50%',
                    left: 0,
                    right: '50%',
                    label: {
                        show: true,
                        formatter(param) {
                            return param.name + ' ' + param.value + ' (' + param.percent + '%)';
                        }
                    },
                    data: userNumData
                },
                {
                    name: '参与人数',
                    type: 'pie',
                    radius: '50%',
                    left: '50%',
                    right: 0,
                    label: {
                        show: true,
                        formatter(param) {
                            return param.name + ' ' + param.value + ' (' + param.percent + '%)';
                        }
                    },
                    data: userTimesData
                }
            ]
        };

        projectChart.setOption(option);
        window.addEventListener('resize', function () {
            projectChart.resize();
        });
    }

    useEffect(() => {
        setTimeout(() => {
            echartsFunction();
        }, 100);
    }, [echartsFunction]);

    return <div className="project-chart" id="project-chart"></div>;
};

export default ProjectChart;
