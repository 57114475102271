import CasesList from "../components/Selection/Cases/CasesList";
import CasesDetail from "../components/Selection/Cases/CaseDetail";
import CaseEdit from "../components/Selection/Cases/CaseEdit";
import Project from "../components/Selection/Projects/Project";
import ProjectsList from "../components/Selection/Projects/ProjectsList";
import TopicsList from '../components/Selection/Theme';
import Topics from '../components/Selection/Theme/Topics';
import ScoreStageList from "../components/Selection/ScoreStages";
import PersonManagement from "../components/Selection/ScoreStages/PersonManagement";
import ScoreStageTopicList from "../components/Selection/ScoreStages/Topics";
import ScoringSettings from "../components/Selection/ScoreStages/ScoringSettings";
import Assing from "../components/Selection/ScoreStages/Assign";
import ResetAssing from "../components/Selection/ScoreStages/ResetAssign";
import Cases from "../components/Selection/ScoreStages/Cases";
import Case from "../components/Selection/ScoreStages/Case";
import List from "../components/Selection/ScoreStages/List";
import VoteTopics from "../components/Selection/Vote/Topics";
import Settings from "../components/Selection/Vote/Settings";
import PrivilegedUsers from "../components/Selection/Vote/PrivilegedUsers";
import VoteCases from "../components/Selection/Vote/Cases";
import LabourExpenses from "../components/Selection/LabourExpenses";


//医评选路由
const selectionRoutes = [
    //评选项目管理
    { path: '/selection/projects', element: <ProjectsList/> },
    //评选项目管理-新增
    { path: '/selection/projects/add', element: <Project/> },
    //评选项目管理-编辑
    { path: '/selection/projects/:id', element: <Project/> },
    //评选项目——主题
    { path: '/selection/projects/:pid/topics', element: <TopicsList/> },
    //评选项目管理-新增
    { path: '/selection/projects/:pid/topics/add', element: <Topics/> },
    //评选项目——编辑
    { path: '/selection/projects/:pid/topics/:id', element: <Topics/> },
    //评选项目-评分环节
    { path: '/selection/projects/:pid/score-stages', element: <ScoreStageList/> },
    //评选项目-评分环节-评委管理
    { path: '/selection/projects/:pid/score-stages/:id/personnel-management', element: <PersonManagement/> },
    //评选项目-评分环节-主题管理
    { path: '/selection/projects/:pid/score-stages/:id/topics', element: <ScoreStageTopicList/> },
    //评选项目-评分环节-评分设置
    { path: '/selection/projects/:pid/score-stages/:id/scoring-settings/:tid', element: <ScoringSettings/> },
    //评选项目-评分环节-分配评委
    { path: '/selection/projects/:pid/score-stages/:id/assign', element: <Assing/> },
    //评选项目-评分环节-重置评委
    { path: '/selection/projects/:pid/score-stages/:id/reset-assign', element: <ResetAssing/> },
    //评选项目-评分环节-主题管理-案例评分列表
    { path: '/selection/projects/:pid/score-stages/:sid/topics/:tid/cases', element: <Cases/> },
    //评选项目-评分环节-主题管理-案例评分列表-案例评分详情
    { path: '/selection/projects/:pid/score-stages/:sid/topics/:tid/cases', element: <Cases/> },
    //评选项目-评分环节-主题管理-案例评分列表-案例评分详情-评委评分详情
    { path: '/selection/projects/:pid/score-stages/:sid/topics/:tid/cases/:id', element: <Case/> },
    //评选项目-评分环节-主题管理-案例评分列表-案例评分详情-评委评分详情-核对榜单
    { path: '/selection/projects/:pid/score-stages/:sid/topics/:tid/cases/list', element: <List/> },
    // 评选项目-投票管理
    { path: '/selection/projects/:pid/vote/topics', element: <VoteTopics/> },
    // 评选项目-投票管理-设置
    { path: '/selection/projects/:pid/vote/topics/:tid/settings', element: <Settings/> },
    // 评选项目-投票管理-特权票用户列表
    { path: '/selection/projects/:pid/vote/topics/:tid/privileged-users', element: <PrivilegedUsers/> },
    // 评选项目-投票管理-投票案例列表
    { path: '/selection/projects/:pid/vote/topics/:tid/cases', element: <VoteCases/> },
    { path: '/selection/cases', element: <CasesList/> },
    { path: '/selection/cases/:id', element: <CasesDetail/> },
    { path: '/selection/cases/edit/:id', element: <CaseEdit/> },
    // 劳务费管理
    { path: '/selection/projects/:id/labour-expenses', element: <LabourExpenses/> },
]
export default selectionRoutes;
