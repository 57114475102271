import {Link, useNavigate, useParams} from "react-router-dom";
import {LeftOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {Fragment, useEffect, useState} from "react";
import {Button, Form, Modal, Layout, Space, Table, Input, message} from "antd";
import {pageFormItemLayout} from "../../../utils/property";
import {getConsultationsInfoApi,getConsultationFollowsApi,saveConsultationFollowsApi} from '@services/Uc/Consultations'
import './Consultations.less'

const {Content} = Layout;
const { TextArea } = Input;
export default function Consultations() {

    const params = useParams();
    const id = params?.id || 0;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [modelForm] = Form.useForm();


    const [listData,setListData]=useState([])
    const [contentData,setContentData]=useState('')


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
        modelForm.resetFields();
    };
    const handleOk = () => {
        modelForm.validateFields().then(()=>{
            saveConsultationFollowsApi({consultationId:id,content:contentData}).then((res)=>{
                if (res.status_code === 200) {
                    message.success(res.message);
                    setIsModalOpen(false);
                    navigate('/uc/user/consultations/'+id)
                    getData()
                }
            })
        })

    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onChange = (e) => {
        setContentData(e.target.value)
    };

    useEffect(()=>{
        getData()
    },[]);

    const getData=()=>{
        getConsultationsInfoApi({id:id}).then((res) => {
            if (res.status_code === 200) {
                form.setFieldsValue(res.data.data)
                form.setFieldValue("type",res.data.data.type===1?'解决方案':'')
            }
        })
        getConsultationFollowsApi({consultationId:id}).then((res)=>{
            if (res.status_code === 200) {
                console.log(res.data)
                setListData(res.data)
            }
        })
    }

    const columns = [
        {title: '跟进人', dataIndex: 'createdBy', width: 100},
        {title: '跟进时间', dataIndex: 'createdAt', width: 100},
        {title: '跟进记录内容', dataIndex: 'content', width: 300}
    ];

    return <Fragment>
        <div className="page-title">
            <Link to={`/uc/user/consultations`} className="back"><LeftOutlined/></Link>
            <span className="current-title">用户咨询详情</span>
        </div>
        <Content className="main-content-box">
            <div className="main-content-title">咨询信息</div>
            <div className="consultations-content-body">
                <Form className="consultations-page-form" {...pageFormItemLayout} form={form}>
                    <div className="consultations-form-subtitle">咨询内容</div>
                    <Form.Item name="type" label="咨询类型：">
                        <Input disabled bordered={false}/>
                    </Form.Item>
                    <Form.Item name="content" label="内容信息：">
                        <Input disabled bordered={false}/>
                    </Form.Item>
                    <Form.Item name="message" label="用户留言：">
                        <Input disabled bordered={false}/>
                    </Form.Item>
                    <div className="consultations-form-subtitle">咨询用户</div>
                    <Form.Item name="nickName" label="用户昵称：">
                        <Input disabled bordered={false}/>
                    </Form.Item>
                    <Form.Item name="memMobile" label="手机号：">
                        <Input disabled bordered={false}/>
                    </Form.Item>
                </Form>
            </div>
            <div className="consultations-table-caption">
            <div className="consultations-content-title">跟进记录</div>
            <div className="consultations-content-title-right">
                <Space>
                    <Button type="primary" onClick={showModal}>新增跟进记录</Button>
                </Space>
            </div>
            </div>
            <div className="consultations-content-body">
                <Table
                    className="consultations-table"
                    rowKey={item => item.id}
                    columns={columns}
                    dataSource={listData}
                    pagination={false}
                />
            </div>
        </Content>
        <Modal title="新增跟进记录" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form form={modelForm}>
                <Form.Item name="name" label="跟进记录内容" rules={[{ required: true, message: '请输入跟进记录内容' }]}>
                    <TextArea placeholder="输入跟进记录内容" showCount maxLength={200} style={{height: 200}} onChange={onChange} />
                </Form.Item>
            </Form>
        </Modal>


    </Fragment>
}
