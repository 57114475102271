import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Button,
    Form,
    Input,
    Layout,
    Space,
    Table,
    Tooltip,
    message,
    Modal,
    Divider,
    Dropdown,
    Typography,
    Tag,
    Row,
    Col,
    Select,
    Image,
    Upload,
    Radio,
    Avatar
} from 'antd';
import {
    EllipsisOutlined,
    LeftOutlined,
    ReloadOutlined,
    SearchOutlined,
    UploadOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import {
    guestListApi,
    guestDelApi,
    guestAllSureApi,
    guestMessageChangeApi,
    exportGuestsApi
} from '@services/Guest';
import './GuestList.less';
import AddGuest from './components/AddGuest';
import GuestTask from './components/GuestTask';
import GuestMessage from './components/GuestMessage';
import config from '@config';
import { convertDateTimeRangeToLocalText } from '@utils/utils';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {
    conferenceOnceApi,
    fileApi,
    batchUpdateRoleApi,
    batchUpdateHiddenApi,
    deleteConferenceCache,
    updateGuestsShowTypeApi,
} from '@services/Conference';
import { expertLevels } from '@utils/constants';
import { conferenceActions } from '../store/conferenceSlice';
import { isEmpty } from 'lodash';
import { localStorageGet } from '@core/cookie';

const { Content } = Layout;
const { Text } = Typography;

const { setGuestFilter: setFilter, setGuestCursor: setCursor } = conferenceActions;

const guestIdentityList = [
    { value: 1, label: '参会嘉宾' },
    { value: 2, label: '参会代表' },
    { value: 3, label: '企业人员' },
    { value: 4, label: '工作人员' },
    { value: 0, label: '待定' },
];

export default function GuestList() {
    const params = useParams();
    const navigate = useNavigate();

    const conferenceId = params?.id || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const [laborFeeConfirmed, setLaborFeeConfirmed] = useState(false);

    const dispatch = useDispatch();
    const filter = useSelector(state => state.conference.guest.filter);
    const cursor = useSelector(state => state.conference.guest.cursor);

    const [form] = Form.useForm();

    const [selectedGuests, setSelectedGuests] = useState([]);
    const [selectedExpertsIds, setSelectedExpertsIds] = useState([]);
    const [selectedGuestIds, setSelectedGuestIds] = useState([]);

    const [visibleAddGuestsDrawer, setVisibleAddGuestsDrawer] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const [dateOptions, setDateOptions] = useState([]);

    const [rowItem, setRowItem] = useState(undefined);

    const [showGuestForm] = Form.useForm();
    const [visibleGuestType, setVisibleGuestType] = useState(false);

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({ id: conferenceId });
        if (res.status_code === 200) {
            setConference(res.data);
            setLaborFeeConfirmed(!!res.data?.laborFeeConfirmedAt);
        }
    };

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
        }
    }, []);

    const showAddGuestsDrawer = () => {
        setVisibleAddGuestsDrawer(true);
    };

    // 嘉宾任务管理 Drawer
    const [visibleGuestTasksDrawer, setVisibleGuestTasksDrawer] = useState(false);

    const [isJoureyModalOpen, setIsJoureyModalOpen] = useState(false);
    const [identityModalOpen, setIdentityModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const currentJourney = useRef(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [currImage, setCurrImage] = useState('');
    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [taskContent, setTaskContent] = useState(null);

    const option = { headers: { "Content-Type": "multipart/form-data" } };

    const [journeyForm] = Form.useForm();
    const [identityForm] = Form.useForm();

    const showGuestTasksDrawer = item => {
        dispatch(setCursor(item.id));
        setVisibleGuestTasksDrawer(true);
        setRowItem(item);
    };

    const closeGuestTasksDrawer = () => {
        setVisibleGuestTasksDrawer(false);
    };

    // 嘉宾展示信息 Drawer

    const [visibleGuestDisplayInfoDrawer, setVisibleGuestDisplayInfoDrawer] = useState(false);

    const showGuestDisplayInfoDrawer = item => {
        dispatch(setCursor(item.id));
        setVisibleGuestDisplayInfoDrawer(true);
        setRowItem(item);
    };

    const closeGuestDisplayInfoDrawer = () => {
        setVisibleGuestDisplayInfoDrawer(false);
        // setRowItem(undefined)
    };

    const onFinish = values => {
        let newFilter = {
            ...filter,
            ...values,
            page: 1,
        };
        // 兼容接口未处理 [] 的情况
        if (isEmpty(newFilter?.roles)) {
            delete newFilter.roles;
        }
        if (isEmpty(newFilter?.expertLevels)) {
            delete newFilter.expertLevels;
        }
        dispatch(setFilter(newFilter));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };
    const onDelReset = () => {
        dispatch(setFilter({
            ...filter
        }));
    }


    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const handleRefresh = () => {
        getExpertList();
    };

    const removeGuest = (memCard, expertId) => {
        const data = {
            conferenceId: params.id,
            memCard: memCard,
            expertId
        };
        guestDelApi(data).then(res => {
                if (res.status_code === 200) {
                    onDelReset();
                }
            }
        );
    };

    // 删除行程单
    const delJourneyList = () => {
        journeyForm.setFieldValue('journeyList', '');
        setFileList([]);
    };

    // 确认行程单
    const handleJoureyOk = () => {
        journeyForm.validateFields().then(async (values) => {
            const rowInfo = currentJourney.current;
            const obj = {
                conferenceId: params.id,
                // memCard,
                journeyList: values?.journeyList,
                id: rowInfo?.guestsEntity?.id,
            };
            const res = await guestMessageChangeApi(obj);
            if (res.status_code === 200) {
                getExpertList();
                message.success('行程单保存成功');
            }
        });
        setIsJoureyModalOpen(false);
    };

    // 取消行程单
    const handleJoureyCancel = () => {
        setIsJoureyModalOpen(false);
    };

    // 批量修改嘉宾隐藏显示功能
    const batchUpdateHiddenAction = (isHidden) => {
        if (selectedGuestIds && selectedGuestIds.length > 0) {
            Modal.confirm({
                title: '提示',
                content: `是否确认将所选嘉宾${isHidden ? '隐藏' : '显示'}？`,
                cancelText: '取消',
                okText: '确认',
                closable: true,
                onOk: async () => {
                    let params = {
                        conferenceId,
                        hidden: isHidden ? 1 : 0,
                        ids: selectedGuestIds
                    }
                    const res = await batchUpdateHiddenApi(params);
                    if (res.status_code === 200) {
                        getExpertList();
                    }
                }
            });
        } else {
            message.warning('尚未选择嘉宾');
        }
    };

    // 确认嘉宾身份
    const handleIdentityOk = () => {
        identityForm.validateFields().then(async (values) => {
            const res = await batchUpdateRoleApi({ ids: selectedGuestIds, role: values.role });
            if (res.status_code === 200) {
                setIdentityModalOpen(false);
                message.success('更换身份成功');
                getExpertList();
                setSelectedExpertsIds([]);
                setSelectedGuestIds([]);
            }
        })
    };

    // 取消嘉宾身份
    const handleIdentityCancel = () => {
        setIdentityModalOpen(false);
    };

    // 批量更换身份
    const bulkChangeIdentity = () => {
        if (selectedExpertsIds.length === 0) {
            return message.warn('尚未选择嘉宾');
        }
        setIdentityModalOpen(true);
    };

    // 行程单
    const handleJourneyList = (row) => {
        dispatch(setCursor(row.id));

        currentJourney.current = row;
        if (row?.guestsEntity?.journeyList) {
            setFileList([getFileUrl(row.guestsEntity.journeyList)]);
            journeyForm.setFieldValue('journeyList', row.guestsEntity.journeyList);
        }
        setIsJoureyModalOpen(true);
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 行程单
    const imageFile = {
        name: "imageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 14);
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error("上传文件失败！");
                    return false;
                }
                if (res.status_code === 200) {
                    setFileList([getFileUrl(res.data)]);
                    journeyForm.setFieldValue('journeyList', res.data);
                }
            });
        },
    };

    const checkout = (file, size) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png 格式");
            return false;
        }
        // 判断大小
        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            message.error("图像必须小于" + size + "MB!");
            return false;
        }
        return isJpgOrPng && isLt2M;
    };

    const bulkConfirmTask = () => {
        if (selectedExpertsIds.length === 0) {
            message.warn('尚未选择嘉宾');
        } else {
            let obj = {
                conferenceId: params.id,
                expertIds: [...selectedExpertsIds],
                memCards: [...selectedGuests],
            };

            guestAllSureApi(obj).then((res) => {
                if (res.status_code === 200) {
                    message.success('批量确认完成');
                    onResetGuest('noneChange');
                }
            });
        }
    };

    const onChangeHidden = (memCard, id, hidden) => {
        let obj = {
            conferenceId,
            memCard,
            hidden: Number(!hidden),
            id,
        };

        guestMessageChangeApi(obj)
            .then((res) => {
                if (res.status_code === 200) {
                    getExpertList();
                }
            });
    };

    const prepareAgendaTasksLevel = tasks => {
        let agendaDates = [];

        tasks.forEach(t => {
            let date = moment(t.startTime).isValid() ? moment(t.startTime).format('M月D日') : '';
            let startTime = moment(t.startTime).isValid() ? moment(t.startTime).format('HH:mm') : '';
            const endTime = moment(t.endTime).isValid() ? moment(t.endTime).format('HH:mm') : '';

            const agenda = {
                agendaId: t.agendaId,
                agendaTitle: t.title,
                taskName: t.taskName,
                timeRange: `${startTime}～${endTime}`,
            };

            const agendaDate = agendaDates.find(d => d.date === date);
            if (agendaDate) { // 数据中会议日期已存在
                const hall = agendaDate.conferenceHalls.find(h => h.hallId === t.conferenceHallId);
                if (hall) { // 数据中会议场次已存在
                    hall.agendas.push(agenda);
                } else { // 新增会议场次
                    agendaDate.conferenceHalls.push({
                        hallId: t.conferenceHallId,
                        hallName: t.conferenceHallName,
                        place: t.place,
                        agendas: [agenda]
                    });
                }
            } else {
                agendaDates.push({
                    date,
                    conferenceHalls: [
                        {
                            hallId: t.conferenceHallId,
                            hallName: t.conferenceHallName,
                            place: t.place,
                            agendas: [agenda]
                        }
                    ]
                });

            }

        });

        return agendaDates;
    };

    const openTaskModal = record => {
        dispatch(setCursor(record.id));
        setTaskContent(record);
        setTaskModalOpen(true);
    }

    const copyAgendaTasks = record => {
        let result = '';

        const linebreak = "\r\n";
        const linebreaks = "\r\n\r\n";

        const name = record?.memUsername;
        result += name + linebreaks;

        // const conferenceName = conference?.name;
        // result += conferenceName + linebreaks;

        const agendasGuests = record?.agendasGuestsList?.filter(g => g.invitationAccepted === 1) || [];
        const agendaDates = prepareAgendaTasksLevel(agendasGuests);
        console.log(agendaDates);
        agendaDates.forEach(agendaDate => {
            result += agendaDate.date + linebreak;

            agendaDate.conferenceHalls.forEach(hall => {
                result += `${hall.hallName}${hall.place ? ' / ' + hall.place : ''}` + linebreak;

                hall.agendas.forEach(a => {
                    result += `${a.timeRange} / ${a.taskName} / ${a.agendaTitle}` + linebreak;
                });
            });

            result += linebreak;
        });

        copy(result);
        message.success('复制成功');
    };

    const toggleAgendaTasks = guestAgendaInfo => {
        setData(data.map(item => {
            if (item.id === guestAgendaInfo.id) {
                return {...item, expanded: !item.expanded};
            } else {
                return item;
            }
        }));
    };

    const columns = [
        {
            title: "嘉宾身份",
            dataIndex: "unit",
            width: 100,
            render: (_, record) => (
                getGuestIdentity(record?.guestsEntity?.role)
            ),
        },
        {
            title: "嘉宾姓名",
            dataIndex: "name",
            width: 150,
            render: (_, record) => (<div style={{ display: 'flex', alignItems: 'center' }}>
                {!!record?.expertAvatar
                    ? <Avatar src={record?.expertAvatar} style={{ verticalAlign: 'middle' }}/>
                    : (
                        <Avatar style={{ verticalAlign: 'middle' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                            </svg>
                        </Avatar>
                    )
                }
                <div style={{ marginLeft: '5px' }}>
                    <span style={{ marginRight: 4 }}>{record.memUsername}</span>
                    <span>
                        {record.memGender === 1
                            ? (
                                <svg style={{ color: '#1890ff' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-male" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                                </svg>
                            )
                            : record.memGender === 0
                                ? (
                                    <svg style={{ color: '#eb2f96' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-female" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                                    </svg>
                                )
                                : null}
                    </span>
                    <div className="expert-level">
                        <Text type={record.expertLevel >= 1 && record.expertLevel <= 6 ? 'warning' : 'secondary'}>
                            {expertLevels.find(a => a.level === record.expertLevel)?.label}
                        </Text>
                    </div>
                </div>
            </div>),
        },
        { title: "手机号", dataIndex: "memMobile", width: 120 },
        {
            title: "单位任职",
            name: "units",
            width: 200,
            render: (_, record) => {
                if (record.ssoMemberWorkExperiencesEntities && record.ssoMemberWorkExperiencesEntities.length) {
                    return (
                        record.ssoMemberWorkExperiencesEntities.map((item, idx) => {
                            return (
                                <div key={item.id}>
                                    {record.ssoMemberWorkExperiencesEntities.length > 1 && <span>{idx + 1}. </span>}
                                    {!!item.unitName && <span>{item.unitName}</span>}
                                    {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                    {!!item.unitPost && <span>{item.unitPost}</span>}
                                </div>
                            )
                        })
                    );
                } else {
                    return <Text type="secondary">&ndash;</Text>;
                }
            },
        },
        {
            title: "会议任务",
            dataIndex: "tasks",
            width: 300,
            render: (_, record) => {
                if (record.agendasGuestsList && record.agendasGuestsList.length) {
                    return <>
                        <ul className="task-list">
                            {record.agendasGuestsList.map((item, idx) => {
                                return (
                                    <li key={idx} style={{ display: idx !== 0 && !record.expanded ? 'none' : '' }}>
                                        <span style={{ marginRight: 4 }}>{`${item.conferenceHallName} / ${item.title}`}</span>
                                        <Tag color="blue">{item?.taskName}</Tag>
                                        {item.invitationAccepted === 0 && <Text type="secondary">未反馈</Text>}
                                        {item.invitationAccepted === 1 && <Text type="success">已确认</Text>}
                                        {item.invitationAccepted === 2 && <Tooltip placement="top" trigger="click" title={item?.refusalInfo}><span style={{ color: '#F00', textDecoration: 'underline', cursor: 'pointer' }}>已拒绝</span></Tooltip>}
                                        {item.invitationAccepted === 3 && <Text type="warning">待确认</Text>}
                                        <br/>
                                        <span className="task-time">{convertDateTimeRangeToLocalText(item.startTime, item.endTime, 0)}</span>
                                    </li>
                                );
                            })}
                        </ul>

                        {record.agendasGuestsList.length > 1 && (
                            <div
                                className="toggle-task-expansion"
                                style={{ color: '#1890ff', cursor: 'pointer', fontSize: 12 }}
                                onClick={() => toggleAgendaTasks(record)}
                            >
                                <span className="anticon" style={{transform: !!record?.expanded ? 'rotate(180deg)' : 'none'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16" style={{width: 12, height: 12}}>
                                      <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659"/>
                                    </svg>
                                </span>
                                <span>{!!record?.expanded ? '收起' : `展开${record.agendasGuestsList.length}个任务`}</span>
                            </div>
                        )}
                    </>;
                } else {
                    return <Text type="secondary">未安排任务</Text>;
                }
            },
        },
        {
            title: '一键显示/隐藏',
            dataIndex: 'hidden',
            width: 100,
            render: (_, record) =>
                !record?.guestsEntity?.hidden
                    ? (
                        <Text
                            type="success"
                            className="hidden_pointer"
                            onClick={() => onChangeHidden(record.memCard, record.guestsEntity.id, record.guestsEntity.hidden)}
                        >
                            显示中
                        </Text>
                    )
                    : (
                        <Text
                            type="warning"
                            className="hidden_pointer"
                            onClick={() => onChangeHidden(record.memCard, record.guestsEntity.id, record.guestsEntity.hidden)}
                        >
                            隐藏中
                        </Text>
                    ),
        },
        {
            title: "操作",
            dataIndex: "operations",
            width: 220,
            float: 'right',
            render: (_, record) => {
                const items = [
                    {
                        label: '移除会议嘉宾',
                        key: 'remove',
                        onClick: () => removeGuest(record.memCard, record.id),
                        danger: true,
                    },
                ];

                // 会议进入劳务阶段，禁用代嘉宾确认任务 !!conference?.laborFeeConfirmedAt
                return !laborFeeConfirmed
                    ? (
                        <span className="operation-btns">
                            {record?.agendasGuestsList?.filter(g => g.invitationAccepted === 1)?.length > 0 && (
                                <>
                                    <Button size="small" type="link" onClick={() => openTaskModal(record)}>复制会议任务</Button>
                                    <Divider type="vertical"/>
                                </>
                            )}
                            {conference && !conference?.laborFeeConfirmedAt && (
                                <>
                                    <Button size="small" type="link" onClick={() => showGuestTasksDrawer(record)}>代嘉宾确认任务</Button>
                                    <Divider type="vertical"/>
                                </>
                            )}
                            <Button size="small" type="link" onClick={() => showGuestDisplayInfoDrawer(record)}>嘉宾展示信息</Button>
                            <Divider type="vertical"/>
                            <Dropdown menu={{ items }} placement="bottomRight">
                                <Button type="link" size="small" className="more-operation"><EllipsisOutlined/></Button>
                            </Dropdown>
                        </span>
                    )
                    : (
                        <span className="operation-btns">
                            {record?.agendasGuestsList?.filter(g => g.invitationAccepted === 1)?.length > 0 && (
                                <>
                                    <Button size="small" type="link" onClick={() => openTaskModal(record)}>复制会议任务</Button>
                                </>
                            )}
                        </span>
                    );
            },
        },
    ];

    // 嘉宾身份
    const getGuestIdentity = (role) => {
        if (role === 0) {
            return '待定'
        }
        if (role === 1) {
            return '参会嘉宾'
        }
        if (role === 2) {
            return '参会代表'
        }
        if (role === 3) {
            return '企业人员'
        }
        if (role === 4) {
            return '工作人员'
        }
    };

    const guestSelection = {
        selectedRowKeys: selectedExpertsIds,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0) {
                let memCard = selectedRows.map((item) => {
                    return item.memCard;
                });
                // let expertsIds = selectedRows.map((item) => {
                //     return item.id;
                // });
                let guestIds = selectedRows.map((item) => {
                    return item?.guestsEntity?.id;
                });

                setSelectedGuests([...memCard]);
                setSelectedExpertsIds([...selectedRowKeys]);
                setSelectedGuestIds([...guestIds]);
            } else {
                setSelectedGuests([]);
                setSelectedExpertsIds([]);
                setSelectedGuestIds([]);
            }
        },
    };

    const getExpertList = async () => {
        let params = {
            ...filter,
            conferenceId,
        };

        try {
            setLoading(true);

            let res = await guestListApi(params);
            if (res.status_code === 200) {
                setLoading(false);

                setData(res.data.list);
                setTotal(res.data.totalCount);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }

    };

    useEffect(() => {
        form.setFieldsValue(filter);

        getExpertList();
    }, [filter]);

    const onResetGuest = (type) => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    // 任务日期
    const getDataList = async () => {
        const res = await conferenceOnceApi({ id: conferenceId });
        if (res.status_code === 200 && res.data) {
            const dates = res.data.dates ? res.data.dates.split(',') : [];
            let datesArr = [];
            dates.forEach(item => {
                datesArr.push({ value: `${item} 00:00:00,${item} 12:00:00,AM`, label: `${moment(item).format('YYYY年MM月DD日')} 上午` });
                datesArr.push({ value: `${item} 12:00:00,${item} 23:59:59,PM`, label: `${moment(item).format('YYYY年MM月DD日')} 下午` });
            });
            setDateOptions(datesArr);
        }
    }

    useEffect(() => {
        getDataList();
    }, []);

    const clearConferenceGuestsCache = async () => {
        const data = {
            type: 'conference',
            key: `conference_${conferenceId}_user_guests`
        };
        const res = await deleteConferenceCache(data);
        if (res?.status_code === 200) {
            message.success('更新会议官网嘉宾列表成功！');
        }
    };

    const showGuestType = () => {
        setVisibleGuestType(true);
    };

    // 显示嘉宾类型
    const confirmShowGuestType = () => {
        showGuestForm.validateFields().then(async (values) => {
            const res = await updateGuestsShowTypeApi({ ...values, id: conferenceId });
            if (res.status_code === 200) {
                message.success('所选嘉宾显示成功');
                getExpertList();
                setVisibleGuestType(false);
            }
        })
    };

    const onClearConferenceGuestsCache = () => {
        if (conferenceId) {
            Modal.confirm({
                title: '更新官网嘉宾列表',
                icon: <ExclamationCircleOutlined/>,
                width: 480,
                centered: true,
                content: <>
                    <p>为减轻性能压力，系统自动缓存数据，该功能将更新用户端数据，展现最新设定内容。</p>
                    <p><Typography.Text type="danger">会议进行期间谨慎更新！</Typography.Text></p>
                </>,
                onOk() {
                    clearConferenceGuestsCache();
                },
                cancelText: '取消，暂不更新',
                okText: '知晓，确认更新'
            });
        }
    };

    const exportGuestsExcel = async() => {
        const res = await exportGuestsApi({ conferenceId });
        if (res.type === 'application/json') {
            return message.warning('暂无权限');
        } else {
            message.success('请稍后，正在导出');
            let content = res;
            let blob = new Blob([content], { type: 'application/octet-stream' });
            if ('download' in document.createElement('a')) {
                const fileNameSuffix = '_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';
                const link = document.createElement('a');
                link.style.display = 'none';
                link.download = conference.name + '-嘉宾' + fileNameSuffix;
                link.href = URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                link.remove();
            } else {
                navigator.msSaveBlob(blob);
            }
        }
    }

    // 嘉宾行程管理
    const guestJourneyManage = () => {
        navigate(`/conf/conferences/${conferenceId}/guests/journeys`);
    };

    // 是否拥有嘉宾行程管理权限
    const canConfirmGuestJourney = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '嘉宾行程管理');
        }
        return false;
    }, []);

    // 是否拥有嘉宾行程导出权限
    const canExportGuestInfo = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '导出嘉宾信息');
        }
        return false;
    }, []);

    return (
        <>
            <div className="page-title">
                <Link to="/conf/conferences" className="back">
                    <LeftOutlined/>
                </Link>
                <span className="current-title">嘉宾管理</span>
                <span className="current-subtitle">{conference?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾姓名" name="name">
                                    <Input allowClear placeholder="嘉宾姓名"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="单位" name="unitName">
                                    <Input allowClear placeholder="单位名称关键字"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="科室/部门" name="unitOffices">
                                    <Input allowClear placeholder="所在科室或部门关键字"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="职务" name="unitPost">
                                    <Input allowClear placeholder="职务关键字"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="任务日期" name="agendTime">
                                    <Select options={dateOptions} placeholder="请选择日期"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾身份" name="roles">
                                    <Select
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        options={guestIdentityList}
                                        placeholder="请选择身份"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾级别" name="expertLevels">
                                    <Select
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        options={expertLevels}
                                        fieldNames={{ label: 'label', value: 'level' }}
                                        placeholder="请选择嘉宾级别"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾任务状态" name="invitationAccepted">
                                    <Select
                                        options={[
                                            { value: 0, label: '未反馈' },
                                            { value: 1, label: '已接受' },
                                            { value: 2, label: '已拒绝' },
                                            // { value: 3, label: '待确认' },
                                        ]}
                                        placeholder="请选择嘉宾任务接受状态"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Space>
                                <Button type="primary" onClick={() => showAddGuestsDrawer()} disabled={laborFeeConfirmed}>添加嘉宾</Button>
                                <Button onClick={bulkChangeIdentity} disabled={laborFeeConfirmed}>批量更换身份</Button>
                                <Button onClick={bulkConfirmTask} disabled={laborFeeConfirmed}>批量确认任务</Button>
                                {canConfirmGuestJourney && <Button onClick={guestJourneyManage}>嘉宾行程管理</Button>}
                            </Space>
                        </div>
                        <div className="sub-tools">
                            <Space>
                                <Button onClick={() => batchUpdateHiddenAction(0)} disabled={selectedGuestIds.length <= 0}>批量显示</Button>
                                <Button onClick={() => batchUpdateHiddenAction(1)} disabled={selectedGuestIds.length <= 0}>批量隐藏</Button>
                                <Button onClick={showGuestType}>显示嘉宾类型</Button>
                                <Button type="danger" ghost onClick={() => onClearConferenceGuestsCache()}>更新官网嘉宾列表</Button>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        icon={<span className="anticon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                            </svg>
                                        </span>}
                                        onClick={handleRefresh}
                                    />
                                </Tooltip>
                                {canExportGuestInfo && (
                                    <Tooltip title="导出嘉宾">
                                    <Button
                                        type="default"
                                        icon={<span className="anticon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                            </svg>
                                        </span>}
                                        onClick={exportGuestsExcel}
                                        disabled={laborFeeConfirmed}
                                    />
                                </Tooltip>
                                )}
                            </Space>
                        </div>
                    </div>

                    <Table
                        rowKey="id"
                        size="small"
                        rowSelection={{ type: "checkbox", ...guestSelection }}
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                        scroll={{ x: 1300 }}
                    />
                </div>
            </Content>

            <Modal
                title="嘉宾行程单"
                open={isJoureyModalOpen}
                onOk={handleJoureyOk}
                onCancel={handleJoureyCancel}
                className="journeyListModal"
            >
                <Form
                    name="journeyForm"
                    form={journeyForm}
                    // size="large"
                    autoComplete="off"
                >
                    <Form.Item name="journeyList" label="嘉宾行程单" extra="支持 JPG/PNG 格式，大小不超过5MB">
                        {fileList.length > 0 && (
                            <div className="imageUpload">
                                <Image width={200} src={fileList[0]} className="share-image-area"/>
                                <CloseCircleOutlined onClick={delJourneyList}/>
                            </div>
                        )}
                        <Upload
                            beforeUpload={(file) => checkout(file, 5)}
                            fileList={[]}
                            {...imageFile}
                            maxCount={1}
                        >
                            {fileList.length === 0 && <Button icon={<UploadOutlined/>}>上传嘉宾行程</Button>}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="选择嘉宾身份" open={identityModalOpen} onOk={handleIdentityOk} onCancel={handleIdentityCancel}>
                <Form name="identityForm" form={identityForm} autoComplete="off">
                    <Form.Item name="role" label="选择身份" rules={[{ required: true, message: '请选择身份' }]}>
                        <Select options={guestIdentityList} placeholder="请选择身份"/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="查看行程单"
                open={imageModalOpen}
                footer={null}
                onCancel={() => setImageModalOpen(false)}
                className="journeyImageModal"
            >
                <Image width={600} src={currImage}/>
            </Modal>

            <Modal
                title="嘉宾会议任务"
                open={taskModalOpen}
                footer={null}
                width={1000}
                onCancel={() => {
                    setTaskModalOpen(false);
                    setTaskContent(null);
                }}
                className="taskCopyModal"
            >
                <div className="table-container">
                    <div className='memName'><b>{taskContent?.memUsername}</b>的会议任务如下：</div>
                    <table>
                        <thead>
                        <tr className="top-title">
                            <td className="date">时间</td>
                            <td className="place">地点</td>
                            <td className="topic">主题</td>
                            <td className="task">任务</td>
                        </tr>
                        </thead>
                        <tbody>
                        {taskContent?.agendasGuestsList && !!taskContent?.agendasGuestsList?.length && !!taskContent?.agendasGuestsList?.filter(g => g.invitationAccepted === 1)?.length
                            && taskContent.agendasGuestsList?.filter(g => g.invitationAccepted === 1).map((item, index) => (
                                <tr key={index}>
                                    <td className="date">{convertDateTimeRangeToLocalText(item.startTime, item.endTime, 0)?.slice(5)}</td>
                                    <td className="place">{item.place}</td>
                                    <td className="topic" style={{ textAlign: 'left' }}>
                                        <span style={{ marginRight: 4 }}>{`${item.conferenceHallName} / ${item.title}`}</span>
                                    </td>
                                    <td className="task">{item.taskName}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    {!!taskContent && (
                        <div className='copyBtn'>
                            <Button onClick={() => copyAgendaTasks(taskContent)}>复制会议任务文本</Button>
                        </div>
                    )}
                </div>
            </Modal>

            <Modal
                title="显示嘉宾类型"
                open={visibleGuestType}
                onOk={confirmShowGuestType}
                onCancel={() => setVisibleGuestType(false)}
                centered
            >
                <Form
                    name="basic"
                    form={showGuestForm}
                    layout="vertical"
                    initialValues={{ guestsShowType: conference?.guestsShowType }}
                >
                    <Form.Item
                        label="请选择显示嘉宾类型"
                        name="guestsShowType"
                        rules={[
                            { required: true, message: '请选择显示嘉宾类型' },
                        ]}
                    >
                        <Radio.Group>
                            <Space>
                                <Radio value={1}>仅显示已确认出席嘉宾</Radio>
                                <Radio value={2}>显示已确认出席+拟邀嘉宾</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>

            <AddGuest
                visibleAddGuestsDrawer={visibleAddGuestsDrawer}
                setVisibleAddGuestsDrawer={setVisibleAddGuestsDrawer}
                onResetGuest={onResetGuest}
            />

            <GuestTask
                visibleGuestTasksDrawer={visibleGuestTasksDrawer}
                closeGuestTasksDrawer={closeGuestTasksDrawer}
                rowItem={rowItem}
                getExpertList={getExpertList}
            />

            <GuestMessage
                visibleGuestDisplayInfoDrawer={visibleGuestDisplayInfoDrawer}
                closeGuestDisplayInfoDrawer={closeGuestDisplayInfoDrawer}
                rowItem={rowItem}
            />
        </>
    );
}
