import React, { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import { Button, Layout, Table, Modal, Space, message } from 'antd';
import OperationLogFilterForm from './OperationLogFilterForm';
import OperationLogInfo from './OperationLogInfo';
import { fullPagerConfig } from '@/utils/property';
import { get } from '@/core/request';
import './OperationList.less'
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;

export default function OperationLogList() {
    const navigate = useNavigate();

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    const [updatedByStaffId, setUpdatedByStaffId] = useState([]);

    // 操作人
    useEffect(() => {
        get('/enterprise/staffs/get_all').then((res) => {
            setUpdatedByStaffId(res.data);
        })
    }, []);

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { params, phone, beginTime, endTime } = filterObj;

    const getData = () => {
        get(`/enterprise/logs/query_page`, {
            limit: pageSize,
            page: currPage,
            params,
            phone,
            beginTime,
            endTime
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [currPage, pageSize, phone, params, beginTime, endTime]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page)
        },
        current: currPage,
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickInfo = useCallback(
        (row) => {
            return () => {
                drawer.current.show(row)
            }
        },
        [],
    );

    // table 数据相关
    const columns = [
        { title: '#', dataIndex: 'id' },
        { title: '请求路由', dataIndex: 'route', },
        { title: '请求方法', dataIndex: 'requestMethod', },
        { title: '操作用户', dataIndex: 'staffName', },
        { title: '操作时间', dataIndex: 'createdAt', },
        {
            title: '操作', dataIndex: 'operate',
            render: (_, row) => (
                <Button type="default" size='small' onClick={handleClickInfo(row)}>查看详情</Button>
            )
        },
    ];


    return (
        <Fragment>
            <div className="page-title">后台操作日志</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <OperationLogFilterForm
                        handleFilterFinish={handleFilterFinish}
                        updatedByStaffId={updatedByStaffId}
                    />
                </div>

                <div className="main-content-body">

                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button onClick={() => navigate('/enterprise/operation/stat')}>后台操作统计</Button>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tableData.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

            <OperationLogInfo ref={drawer}></OperationLogInfo>

        </Fragment>
    )
}
