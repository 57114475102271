import { Link, useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined, LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
    Input,
    Layout,
    Space,
    Button,
    Select,
    Table,
    Typography,
    Modal,
    message,
} from 'antd';
import {
    fetchMedProject,
    fetchRelatedProjects,
    removeRelatedProject
} from '@services/LegacyMedProject.service';
import { numberProject } from '@utils/utils';
import { projectTypes } from '../../constants';
import AddRelatedProjectModal from './components/AddRelatedProjectModal';

const { Search } = Input;
const { Content } = Layout;

export default function RelatedProjectList() {
    const navigate = useNavigate();
    const params = useParams();

    const id = params?.id || 0;

    const [project, setProject] = useState(null);

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(null);

    const [visibleAddRelatedProjectModal, setVisibleAddRelatedProjectModal] = useState(false);

    const showAddRelatedProjectModal = () => setVisibleAddRelatedProjectModal(true);

    const getMedProject = async () => {
        if (!!id) {
            const res = await fetchMedProject(id);
            if (res.status_code === 200) {
                const project = res.data;
                setProject(project);
            }
        }
    };

    const getRelatedProjects = async () => {
        try {
            setLoading(true);
            const res = await fetchRelatedProjects(id);
            if (res.status_code === 200) {
                const projects = res.data;
                setTableData(projects);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!!id) {
            getMedProject();
            getRelatedProjects();
        }
    }, []);

    const onRemoveProject = relatedProject => {
        Modal.confirm({
            title: '解除项目关联',
            icon: <ExclamationCircleOutlined />,
            content: `被解除关联的项目产生的数据将不再被统计到医项目下，确定解除关联？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await removeRelatedProject(relatedProject.id);
                if (res.status_code === 200) {
                    message.success('成功解除项目关联');
                    getRelatedProjects();
                }
            }
        });
    };

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'name',
            render: (_, record) => {
                const noPrefix = projectTypes?.find(t => t.code === record.projectCode)?.noPrefix || '';
                const no = !!noPrefix ? numberProject(noPrefix, record.projectId) : '';
                return <>
                    {record.projectName}<br/>
                    {!!no && <Typography.Text type="secondary">{no}</Typography.Text>}
                </>;
            }
        },
        {
            title: '项目类型',
            dataIndex: 'code',
            render: (_, record) => projectTypes?.find(t => t.code === record.projectCode)?.label || <Typography.Text type="secondary">&ndash;</Typography.Text>
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 200,
            render: (_, record) => (
                <span className="operation-btns">
                    <Button size="small" type="link" onClick={() => onRemoveProject(record)}>解除关联</Button>
                </span>
            )
        }
    ];

    const onSearchName = name => setFilter({ ...filter, name });

    const onChangeProjectType = code => setFilter({ ...filter, code });

    useEffect(() => {
        console.log(filter);
        setTableData([
            ...tableData.map(p => {
                const hidden = !(
                    (filter?.code === undefined && (!filter?.name || p.projectName.includes(filter?.name)))
                    || (filter?.code === p.projectCode && (!filter?.name || p.projectName.includes(filter?.name)))
                );
                return { ...p, hidden };
            })
        ]);
    }, [filter]);

    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">关联项目</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">

                <div className="main-content-body">

                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Space>
                                <Search
                                    placeholder="项目名称关键字"
                                    allowClear
                                    style={{ width: 250 }}
                                    onSearch={onSearchName}
                                />

                                <Select
                                    placeholder="项目类型"
                                    style={{ width: 160 }}
                                    onChange={onChangeProjectType}
                                    allowClear
                                >
                                    {projectTypes.map(p => <Select.Option value={p.code} key={p.code}>{p.label}</Select.Option>)}
                                </Select>
                            </Space>
                        </div>
                        <div className="sub-tools">
                            <Button type="primary" onClick={showAddRelatedProjectModal}>添加关联项目</Button>
                        </div>

                    </div>


                    <Table
                        rowKey={item => item.id}
                        columns={columns}
                        dataSource={tableData?.filter(p => !p.hidden)}
                        loading={loading}
                        pagination={false}
                    />
                </div>

            </Content>

            <AddRelatedProjectModal
                visibleAddRelatedProjectModal={visibleAddRelatedProjectModal}
                setVisibleAddRelatedProjectModal={setVisibleAddRelatedProjectModal}
                getRelatedProjects={getRelatedProjects}
                relatedProjects={tableData}
                medProjectId={id}
            />

        </>
    );
}
