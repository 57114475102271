import React, { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import html2canvas from 'html2canvas';
import {
    Button,
    Col, DatePicker,
    Divider, Dropdown,
    Form, Image,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
    Typography, Upload
} from 'antd';
import { EllipsisOutlined, LeftOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { LaborFeePaymentStatusList, LaborFeeRequestStatusList } from '../constants';
import { fetchLaborFeePaymentList } from '@services/Finance/FinanceLaborFee.service';
import { conferenceOnceApi } from '@services/Conference';
import {
    convertDateTimeToLocalText,
    formatCurrency,
    dateTimeFormat,
    prepareBCOSSUrl,
    numberProject
} from '@utils/utils';
import {
    cardTypes,
    laborFeeCostBearers,
    laborFeePaymentMethods,
    laborFee3rdPartyPaymentMethods,
} from '@utils/constants';
import {
    confirmPaymentOrAddCert,
    examineLaborFeePayment,
    fetchLaborFeePaymentExaminationResult,
    fetchPaymentExceptionRemarks,
    remarkPaymentException,
    uploadFileAPI,
} from '@services/Finance/FinanceLaborFee.service';
import './FinanceLaborFeePayment.less';
import { exportLaborFeePaymentList } from '../../../services/Finance/FinanceLaborFee.service';
import { localStorageGet } from '@core/cookie';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

export default function FinanceLaborFeePayment() {
    const navigate = useNavigate();

    const params = useParams();
    const { batchNo } = params;

    // 接收项目类型-项目ID路由参数
    const projectCodeAndId = params.project || '';
    let projectCode = '';
    let projectId = 0;
    if (!!projectCodeAndId && /[a-z]+-\d+/i.test(projectCodeAndId)) {
        [projectCode, projectId] = projectCodeAndId.split('-');
        projectCode = projectCode.toUpperCase() || '';
        projectId = parseInt(projectId, 10) || 0;
    }

    const [confirmPaymentForm] = Form.useForm();

    const [addCertForm] = Form.useForm();

    const [addPaymentExceptionRemarkForm] = Form.useForm();

    const [projectInfo, setProjectInfo] = useState(null);

    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false);

    // 劳务费请款审批状态：0待审核 1审核通过 2审核拒绝
    const [paymentExaminationStatus, setPaymentExaminationStatus] = useState(0);

    // 劳务费请款审批结果
    const [visibleRequestExaminationResult, setVisibleRequestExaminationResult] = useState(false);
    const [examinationResult, setExaminationResult] = useState(null);
    // 确认支付
    const [visibleConfirmPayment, setVisibleConfirmPayment] = useState(false);
    // 付款异常备注
    const [visiblePaymentException, setVisiblePaymentException] = useState(false);
    // 上传付款凭证
    const [visiblePaymentCert, setVisiblePaymentCert] = useState(false);

    // 表格选中（待）付款记录ID列表
    // 注意：record.paymentId
    const [selectedPaymentRecordIdList, setSelectedPaymentRecordIdList] = useState([]);
    // 表格中已付款记录ID列表
    // 注意：record.paymentId
    const [payedRecordIdList, setPayedRecordIdList] = useState([]);

    // 参会凭证图片
    const [visibleCertImage, setVisibleCertImage] = useState(false);
    const [certImage, setCertImage] = useState('');

    // 上传参会凭证
    const [currentPaymentId, setCurrentPaymentId] = useState(0);
    const [currentPaymentCert, setCurrentPaymentCert] = useState('');
    // 付款记录
    const [currentPayment, setCurrentPayment] = useState(null);

    // 历史付款异常备注
    const [currentRemarks, setCurrentRemarks] = useState([]);

    // 是否拥有财务劳务费付款人员权限
    const isLaborFeePayment = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '财务劳务费付款人员');
        }
        return false;
    }, []);

    // 是否拥有财务劳务费管理员权限
    const isLaborFeeManager = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '财务劳务费管理员');
        }
        return false;
    }, []);

    // 付款状态切换
    const onChangePaymentStatus = status => {
        // console.log(status);
        // 全部 status = undefined, hidden: 0
        // 未付 status = 0, hidden: !!item.payedAt
        // 已付 status = 1, hidden: !item.payedAt
        setTableData([...tableData.map(item => {
            return {
                ...item,
                // 隐藏：选择未付款，记录为已付款的；选择已付款，记录为未付款的
                hidden: ((!!item.payedAt && status === 0) || (!item.payedAt && status === 1)) ? 1 : 0
            };
        })]);
    };

    // 劳务费请款审批
    const onExamineLaborFeePayment = () => {
        confirm({
            title: '劳务费请款审批确认',
            content: '审核确认后不允许修改，是否确认提交？',
            centered: true,
            onOk: async () => {
                const data = {
                    batchNo
                };
                const res = await examineLaborFeePayment(data);
                if (res.status_code === 200) {
                    setPaymentExaminationStatus(1);
                    setVisibleConfirmPayment(false);
                }
            },
            onCancel: () => {
                setVisibleConfirmPayment(false);
            },
        });
    };

    // 查看劳务费请款审批结果
    const onViewExaminationResult = async () => {
        const res = await fetchLaborFeePaymentExaminationResult({ batchNo });
        if (res.status_code === 200) {
            // console.log(res.data);
            const result = res.data;
            if (!!result && !!result.auditInfo) {
                result.auditInfo = JSON.parse(result.auditInfo);
            }
            setExaminationResult(result);
            setVisibleRequestExaminationResult(true);
        }
    };

    const exportHtmlDomAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el, { useCORS: true });

        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = imageFileName;
        link.href = canvas.toDataURL('image/png', 1.0);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    // 导出请款清单
    const onExportPaymentList = async () => {
        message.success('请稍后，正在导出');
        const res = await exportLaborFeePaymentList({ batchNo });
        let blob = new Blob([res], { type: 'application/octet-stream' });

        if ('download' in document.createElement('a')) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.download = `劳务费请款批次_${batchNo}_付款明细表.xlsx`;
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
        } else {
            navigator.msSaveBlob(blob);
        }

    };

    // 下载审批结果图片
    const onDownloadExaminationResult = () => {
        const dom = document.getElementById('request-examination-result');
        exportHtmlDomAsImage(dom, '劳务费请款审批结果.png');
    };


    // 打开
    const onViewConfirmPaymentModal = paymentId => {
        if (paymentId) {
            setSelectedPaymentRecordIdList([paymentId]);
        }

        setVisibleConfirmPayment(true);
    };

    const onConfirmPayment = () => {
        confirmPaymentForm.validateFields()
            .then(values => {
                confirm({
                    title: '再次确认支付',
                    content: '提交后不允许修改，是否确认提交？',
                    centered: true,
                    onOk: async () => {
                        let data = {
                            ...values,
                            type: 1, // 1.确认付款，2.上传凭证
                            paymentIds: selectedPaymentRecordIdList,
                        };
                        data.payedAt = moment(data.payedAt).isValid() ? dateTimeFormat(data.payedAt, 'YYYY-MM-DD') : '';
                        console.log(data);

                        const res = await confirmPaymentOrAddCert(data);
                        if (res.status_code === 200) {
                            getPaymentList();
                            message.success('确认支付成功');
                            setVisibleConfirmPayment(false);
                        }
                    },
                    onCancel: () => {
                        setVisibleConfirmPayment(false);
                    },
                });
            });
    };

    const onViewAddCertModal = record => {
        console.log(record);
        setCurrentPayment(record);
        setCurrentPaymentId(record.paymentId);
        setCurrentPaymentCert(record.paymentCert || '');
        setVisiblePaymentCert(true);
    };

    const onViewAddPaymentExceptionRemark = async record => {
        console.log(record);
        setCurrentPaymentId(record.paymentId);
        const params = {
            expertId: record.expertId,
            projectCode,
            projectId,
            type: 3,
        };
        const res = await fetchPaymentExceptionRemarks(params);
        if (res.status_code === 200) {
            setCurrentRemarks(res.data || []);
        }
        setVisiblePaymentException(true);
    };

    const onAddPaymentExceptionRemark = () => {
        addPaymentExceptionRemarkForm.validateFields()
            .then(async values => {
                const res = await remarkPaymentException({
                    id: currentPaymentId,
                    ...values,
                });
                if (res.status_code === 200) {
                    message.success('添加付款异常备注成功');
                    setCurrentPaymentId(0);
                    addPaymentExceptionRemarkForm.resetFields();
                    setVisiblePaymentException(false);
                }
            });
    };

    const onViewJoinCert = cert => {
        setCertImage(prepareBCOSSUrl(cert));
        setVisibleCertImage(true);
    };

    const checkUploadFile = (file, size) => {
        const rightFileTypes = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        if (!rightFileTypes) {
            message.error('请上传 JPG/PNG 格式图片');
        }

        const rightFileSize = file.size / 1024 / 1024 <= size;
        if (!rightFileSize) {
            message.error('请上传' + size + 'MB以内的附件');
        }

        return rightFileTypes && rightFileSize;
    };

    const paymentCertFile = {
        name: 'paymentCertFile',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);
            formData.append('path', moment().format('YYYYMM'));

            uploadFileAPI(formData)
                .then(async res => {
                    if (!res) {
                        message.error('付款凭证上传失败')
                        return false;
                    }

                    if (res.status_code === 200) {
                        setCurrentPaymentCert(res.data);
                        const data = {
                            paymentIds: [currentPaymentId],
                            type: 2,
                            paymentCert: res.data,
                        };
                        const certRes = await confirmPaymentOrAddCert(data);
                        if (certRes.status_code === 200) {
                            message.success('付款凭证上传成功');
                            getPaymentList();
                        }
                    }
                });
        }
    };

    const columns = [
        {
            title: '姓名',
            dataIndex: 'memUsername',
            width: 100,
        },
        {
            title: '手机号',
            dataIndex: 'memMobile',
            width: 100,
        },
        {
            title: '实发劳务费(元)',
            dataIndex: 'afterTaxLaborFee',
            width: 120,
            align: 'right',
            render: (_, record) => record.laborFeeAmount !== null
                ? <span>&yen;{`${formatCurrency(record.laborFeeAmount)}`}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款方式',
            dataIndex: 'paymentMethod',
            width: 150,
            editable: true,
            align: 'center',
            render: (_, record) => {
                const cur = laborFeePaymentMethods.find(m => m.value === record.paymentMethod);
                if (cur) { 
                    if (cur.value === 3) { 
                        return <Text type="warning">{cur?.label}</Text>;
                    }
                    return cur?.label;
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 140,
            render: (_, record) => laborFeeCostBearers.find(b => b.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>
        },
        {
            title: '相关税费(元)',
            dataIndex: 'individualTaxAmount',
            width: 120,
            align: 'right',
            render: (_, record) => {
                if (record.laborFeeAmount === 0) {
                    return <span>&yen;0.00</span>;
                }
                if (!!record.laborFeeAmount && !!record.paymentMethod) {
                    return !!record.individualTaxAmount
                        ? <span>&yen;{`${formatCurrency(record.individualTaxAmount)}`}</span>
                        : <span>&yen;0.00</span>
                }
                return <Text type="secondary">&ndash;</Text>
            }
        },
        {
            title: '含税劳务费(元)',
            dataIndex: 'sumOfLaborFeeCast',
            width: 130,
            align: 'right',
            render: (_, record) => {
                if (record.laborFeeAmount === 0) {
                    return <span>&yen;0.00</span>;
                }
                if (!!record.laborFeeAmount) {
                    let laborFeeAmount = parseFloat(record.laborFeeAmount) || 0.00;
                    let individualTaxAmount = parseFloat(record.individualTaxAmount) || 0.00;

                    return <span>&yen;{`${formatCurrency(laborFeeAmount + individualTaxAmount)}`}</span>;
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '收款信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {
                    (record.idcardType === 1 || !record.idcardType) && <>
                        <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span><br />
                    </>
                }
                {record.idcardType > 1 && <>
                    <span>证件类型：{cardTypes.find(t => t.code === record.idcardType)?.name || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>国籍：{record.nationality || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>出生日期：{(!!record.birthday && moment(record.birthday).format('YYYY-MM-DD')) || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女')) || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span><br />
                </>
                }
                {record.laborInfoConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>}
            </>),
        },
        {
            title: '签到时间',
            dataIndex: 'signedAt',
            width: 120,
            render: (_, record) =>(
                <>
                    {record.signTime ? <span>{convertDateTimeToLocalText(record.signTime)}</span> : <Text type="secondary">&ndash;</Text>}
                    {record.signTime && <div>{record.checkType === 1 && '本人签到'}</div>}
                    {record.signTime && <div>{record.checkType === 2 && <Text type="danger">代签</Text>}</div>}
                </>
            )
        },
        {
            title: '参会凭证',
            dataIndex: 'cert',
            align: 'center',
            width: 100,
            // render: (_, record) => !!record.joinCert
            //     ? <Button type="link" size="small" onClick={() => onViewJoinCert(record.joinCert)}>查看</Button>
            //     : <Text type="secondary">&ndash;</Text>
            render: (_, record) => {
                if (record.joinCert) {
                    return <Image width={100} src={prepareBCOSSUrl(record.joinCert)} />
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '付款日期',
            dataIndex: 'payedAt',
            width: 120,
            render: (_, record) => !!record.payedAt ? dateTimeFormat(record.payedAt, 'YYYY年M月D日') : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 220,
            fixed: 'right',
            hidden: paymentExaminationStatus !== 1,
            render: (_, record) => {
                const items = [
                    {
                        label: '付款异常备注',
                        key: 'remarkPaymentException',
                        onClick: () => onViewAddPaymentExceptionRemark(record),
                    }
                ];

                return (
                    <span className="operation-btns">
                        {!!isLaborFeePayment && !record.payedAt && <>
                            <Button
                                size="small"
                                type="link"
                                onClick={() => onViewConfirmPaymentModal(record.paymentId)}
                                disabled={!!record.payedAt}
                            >
                                付款确认
                            </Button>
                            <Divider type="vertical"/>
                        </>}

                        {!!isLaborFeePayment && !!record.payedAt && <>
                            <Button
                                size="small"
                                type="link"
                                onClick={() => onViewAddCertModal(record)}
                            >
                                {!record.joinCert ? '上传付款凭证' : '修改付款凭证'}
                            </Button>
                            <Divider type="vertical"/>
                        </>}

                        {!!isLaborFeePayment && <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>}
                    </span>
                );
            }
        },
    ];

    const getPaymentList = async () => {
        // if (!batchNo || !/^[a-z]{4}\d{8}\d+$/i.test(batchNo)) {
        //     message.warn('批次号格式不正确');
        //     navigate(`/finance/laborfee/projects/${projectCode}-${projectId}/batches`);
        //     return;
        // }

        try {
            setLoading(true);

            const res = await fetchLaborFeePaymentList({ batchNo });
            if (res.status_code === 200) {
                const paymentList = res?.data?.infos || [];
                setTableData(paymentList);
                let payedIdList = [];
                paymentList.forEach(p => {
                    if (!!p.payedAt) {
                        payedIdList.push(p.paymentId);
                    }
                });
                setPayedRecordIdList(payedIdList);
                if (res?.data?.status && [0, 1, 2].indexOf(res.data.status)) {
                    setPaymentExaminationStatus(res.data.status);
                }
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const getConferenceInfo = async id => {
        const res = await conferenceOnceApi({ id });
        if (res.status_code === 200) {
            return res.data;
        } else {
            return null;
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            if (!selectedRows) {
                setSelectedPaymentRecordIdList([]);
            } else {
                let arr = [];
                !!selectedRows.length && selectedRows.map(r => arr.push(r.paymentId));
                setSelectedPaymentRecordIdList(arr);
                console.log(arr);
            }
        },
        getCheckboxProps: (record) => ({
            disabled: payedRecordIdList.indexOf(record.paymentId) > -1,
        }),
    };

    // 项目信息
    // TODO 目前只有会议项目类型
    const getProjectInfo = async () => {
        switch (projectCode) {
            case 'YHY':
                const conference = await getConferenceInfo(projectId);
                if (!!conference) {
                    setProjectInfo(conference);
                }
                break;
            default:
                break;
        }
    };

    const onRefresh = () => getPaymentList();

    useEffect(() => {
        getProjectInfo();
        getPaymentList();
    }, []);

    return <>
        <div className="page-title">
            <Link to={`/finance/laborfee/projects/${projectCode}-${projectId}/batches`} className="back"><LeftOutlined/></Link>
            <span className="current-title">劳务费付款明细</span>
            <span className="current-subtitle">{projectInfo?.name}</span>
        </div>

        <Content className="main-content-box">

            <div className="main-content-body">
                <div className="main-toolbar">
                    <div className="main-tools">
                        <Space>
                            {isLaborFeeManager && (paymentExaminationStatus === 0
                                ? (
                                    <Tooltip title="劳务费请款审批">
                                        <Button type="primary" onClick={onExamineLaborFeePayment}>劳务费付款审批</Button>
                                    </Tooltip>
                                )
                                : (
                                    <Tooltip title="查看劳务费请款审批结果">
                                        <Button onClick={onViewExaminationResult}>付款审核结果</Button>
                                    </Tooltip>
                                ))
                            }
                            <Select
                                placeholder="付款状态"
                                allowClear
                                options={LaborFeePaymentStatusList}
                                fieldNames={{ value: 'status', label: 'label' }}
                                style={{width: 120}}
                                onChange={onChangePaymentStatus}
                            />
                        </Space>
                    </div>
                    <div className="sub-tools">
                        <Space>
                            {isLaborFeePayment && paymentExaminationStatus === 1 && (
                                <>
                                    <Button onClick={onExportPaymentList}>导出付款申请明细</Button>
                                    <Button disabled={!selectedPaymentRecordIdList.length} onClick={() => onViewConfirmPaymentModal(0)}>批量付款确认</Button>
                                </>
                            )}
                            <Tooltip title="刷新列表">
                                <Button type="default" icon={<ReloadOutlined />} onClick={onRefresh}/>
                            </Tooltip>
                        </Space>
                    </div>
                </div>

                <Image
                    width={200}
                    style={{display: 'none'}}
                    src={certImage}
                    preview={{
                        visible: visibleCertImage,
                        src: certImage,
                        onVisibleChange: value => setVisibleCertImage(value),
                    }}
                />

                <Table
                    rowKey="id"
                    columns={columns.filter(item => !item.hidden)}
                    dataSource={tableData.filter(item => !item.hidden)}
                    loading={loading}
                    pagination={false}
                    scroll={{ x: 1300 }}
                    rowSelection={{type: 'checkbox', ...rowSelection}}
                />
            </div>

            <Modal
                title="劳务费付款审核结果"
                open={visibleRequestExaminationResult}
                width={800}
                onCancel={() => setVisibleRequestExaminationResult(false)}
                footer={null}
            >
                <div id="request-examination-result" className="project-info">
                    <div className="project-title">{projectInfo?.name}</div>
                    <div className="project-examination-info">
                        <p className="project-info-field0">
                            <span>氚云项目编号：{examinationResult?.h3yunNo}</span>
                            <span>项目编号：{numberProject("YHY",examinationResult?.projectId)}</span>
                            <span>项目创建人：{!!examinationResult?.conferenceCreatedName ? examinationResult?.conferenceCreatedName : '—'}</span>
                        </p>
                        <p className="project-info-field">
                            <span>付款申请人：{examinationResult?.batchApply}</span>
                            <span>付款申请：{examinationResult?.batchNo}</span>
                        </p>
                        <p className="project-info-field">
                            <span>付款审核人：{examinationResult?.auditName}</span>
                            <span>付款审核：{examinationResult?.auditTime}</span>
                        </p>
                        <p className="project-sum"><b>{examinationResult?.auditInfo?.conferenceName}</b>付款申请<b>{examinationResult?.batchNo}</b>已由<b>{examinationResult?.auditInfo?.auditUsername}</b>于<b>{examinationResult?.auditInfo?.auditTime}</b>审核确认完毕，共{examinationResult?.auditInfo?.expertCount}位专家需要支付劳务费，其中税后劳务费<b>&yen;{formatCurrency(examinationResult?.auditInfo?.LaborFeeSum)}元</b>，个人所得税总计<b>&yen;{formatCurrency(examinationResult?.auditInfo?.IndividualTaxSum)}元</b>，含税劳务费总计<b>&yen;{!!examinationResult?.auditInfo?.LaborFeeSum ? formatCurrency(parseFloat(examinationResult?.auditInfo?.LaborFeeSum) + parseFloat(examinationResult?.auditInfo?.IndividualTaxSum)) : '0.00'}元</b>。</p>
                        {
                            examinationResult?.costBearers && examinationResult.costBearers.map((item,index) => {
                                return <p className="project-part-list" key={index}>
                                    <b>{laborFeeCostBearers.find(b => b.value === item.costBearer)?.label}</b>
                                    <br/>
                                    <span>
                                        <span>共</span>
                                        <b>{item.expertSize}</b>
                                        <span>位专家需要支付劳务费，其中税后劳务费</span>
                                        <b>￥{item.laborFeeAmount}</b>
                                        <span>元，个人所得税总计</span>
                                        <b>￥{item.individualTaxAmount}</b>
                                        <span>元，含税劳务费总计</span>
                                        <b>￥{item.laborFeeAmount+item.individualTaxAmount}</b>
                                        <span>元。</span>
                                    </span>
                                </p>
                            })
                        }
                    </div>
                </div>
                <div className="project-tools">
                    <Button onClick={onDownloadExaminationResult}>下载审核结果</Button>
                </div>
            </Modal>

            <Modal
                title="确认支付"
                visible={visibleConfirmPayment}
                onOk={onConfirmPayment}
                onCancel={() => setVisibleConfirmPayment(false)}
                centered={true}
                maskClosable={false}
            >
                <Form layout="vertical" form={confirmPaymentForm}>
                    <Form.Item
                        label="付款时间"
                        name="payedAt"
                        rules={[
                            {required: true, message: '付款时间不能为空'}
                        ]}
                    >
                        <DatePicker style={{width: '100%'}}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="上传付款凭证"
                visible={visiblePaymentCert}
                onCancel={() => setVisiblePaymentCert(false)}
                centered={true}
                maskClosable={false}
                footer={null}
            >
                <Form layout="vertical" form={addCertForm}>
                    {!!currentPayment?.memUsername && (
                        <Form.Item label="专家姓名">{currentPayment.memUsername}</Form.Item>
                    )}
                    <Form.Item
                        label="付款凭证"
                        className="required"
                        extra="付款凭证支持 JPG/PNG 格式，大小不超过1MB"
                    >
                        {!!currentPaymentCert && <div style={{marginBottom: 10}}><Image width={200} src={prepareBCOSSUrl(currentPaymentCert)}/></div>}

                        <Upload
                            beforeUpload={file => checkUploadFile(file, 1)}
                            maxCount={1}
                            {...paymentCertFile}
                        >
                            <Button icon={<UploadOutlined/>}>{!currentPaymentCert ? '上传图片' : '更新图片'}</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="付款异常备注"
                visible={visiblePaymentException}
                onOk={onAddPaymentExceptionRemark}
                onCancel={() => setVisiblePaymentException(false)}
                centered={true}
                maskClosable={false}
            >
                <Form layout="vertical" form={addPaymentExceptionRemarkForm}>
                    <Form.Item
                        label="备注"
                        name="refuseReason"
                        rules={[
                            {required: true, message: '备注不能为空'},
                        ]}
                    >
                        <Input.TextArea maxLength={100} rows={3} showCount/>
                    </Form.Item>
                    {!!(currentRemarks && currentRemarks.length) && <>
                        <Form.Item label="历史备注">
                            <div className="payment-exception-remarks">
                                {currentRemarks.map(r => (
                                    <div className="remark">
                                        <div className="remark-time">{r.createdAt}</div>
                                        <div className="remark-content">{r.refuseReason}</div>
                                    </div>
                                ))}
                            </div>
                        </Form.Item>
                    </>}
                </Form>
            </Modal>

        </Content>
    </>;
}
