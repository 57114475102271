import React, {Fragment} from 'react';
import {Layout} from 'antd';
const {Content} = Layout;

export default function MediaDashboard() {
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title">数据总览</div>
                <div className="main-content-body">
                    MediaDashboard
                </div>
            </Content>

            <Content className="main-content-box">
                <div className="main-content-title">功能导航</div>
                <div className="main-content-body">

                </div>
            </Content>
        </Fragment>
    );
}