import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Drawer, Form, Input, Space, Radio, Checkbox, Row, Col, message, Select, Switch, Empty, Upload, Modal, Typography } from 'antd';
import { drawerWidth, drawerFormItemLayout } from '@utils/property';
import { isEmpty } from 'lodash';
import {
    saveConferenceApi, updateConferenceApi, getFirstListConferenceApi, getOrganisationinfoApi, getConferencePlacesList, // 获取会议一览list
    getConferencePlaces, deleteConferencePlaces, addConferencePlaces, updateConferencePlaces,
} from '@services/Agenda';
import { caseOrganizationApi } from '@services/Conference/sign';
import { hallsDetails, sortSponsor, conferenceOssData } from '@services/Conference';
import debounce from 'lodash/debounce';
import { uploadFileApi } from '@services/SiteModule';
import config from '@config';
import { PlusOutlined, ArrowUpOutlined, ArrowDownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import "./index.less";
import UploadComponent from '@/components/Conference/components/Upload/index';

const { Option } = Select;
const { Text } = Typography;

// 会议新增、编辑
const AddConferenceDrawer = (props) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const {
        visibleAddConferenceHallDrawer,
        setVisibleAddConferenceHallDrawer,
        conferenceDetail,
        setConferenceDetail,
        dateList,
        getConferenceList,
        currentDate,
        currentConferenceHall,
        setCurrentConferenceHall,
        staffs,
    } = props;

    const [form] = Form.useForm();

    // console.log(staffs);

    const [firstConferenceList, setFirstConferenceList] = useState([]);
    const [disabledConference, setDisabledConference] = useState(false);

    const [conferenceHallLoading, setConferenceHallLoading] = useState(false); // 新增/编辑会议场次loading

    // 赞助企业
    const [caseOrgValue, setCaseOrgValue] = useState(null);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const [fetching, setFetching] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [currentAddOrg, setCurrentAddOrg] = useState(null);
    const [sponsorsLogos, setSponsorsLogos] = useState([]);
    const [currentConferenceDetails, setCurrentConferenceDetails] = useState({});

    // 会议地点
    const [placeForm] = Form.useForm();
    const [currentPlace, setCurrentPlace] = useState(null); // 当前正在编辑的 place
    const [placeConfirmEnabled, setPlaceConfirmEnabled] = useState(false);
    const [placeArrList, setPlaceArrList] = useState([]); // 会议地点下拉列表
    const [placeModalId, setPlaceModalId] = useState(null); // 会议地点id（弃用）
    const [placeName, setPlaceName] = useState(undefined); // 会议地点表单
    const [placeModalOpen, setPlaceModalOpen] = useState(false); // 会议地点弹窗


    // 上传文件列表
    const [OSSData, setOSSData] = useState({}); // 上传参数
    const [spooneryFileList, setSpooneryFileList] = useState([]); //串场PPT
    const [directFileList, setDirectFileList] = useState([]); //主持稿
    const [speechFileList, setSpeechFileList] = useState([]); // 致辞稿


    const initOSSData = () => {
        conferenceOssData()
            .then((res) => {
                if (window.location.protocol === "http:" && res.data.host.includes("https://")) {
                    res.data.host = res.data.host.replace("https://", "http://");
                }
                setOSSData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = () => {
    };

    // 上传文件路径 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 上传头像格式校验
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png/jpeg 格式');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不超过2MB');
            return false;
        }
        return isJpgOrPng && isLt2M;
    };

    // 上传图片
    const upload = async (value) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let list = [{ url: getFileUrl(res.data) }];
            let logoList = sponsorsLogos.concat(list);
            form.setFieldValue('sponsorsLogos', logoList);
            setSponsorsLogos(logoList);
        }
    };

    // logo remove
    const removeSplashImages = (e) => {
        let h5TargetIndex = sponsorsLogos.findIndex(item => item.url === e.url);
        let h5ResList = [...sponsorsLogos];
        h5ResList.splice(h5TargetIndex, 1);
        setSponsorsLogos(h5ResList);
        form.setFieldValue('sponsorsLogos', [...h5ResList]);
    };

    // 删除文件生成的uid
    const deleteFileUid = (list) => {
        if (list && list.length) {
            let newList = list.map(item => {
                let newItem = { ...item };
                delete newItem.uid;

                if (newItem.url && newItem.url.includes(config.OSS_PREFIX)) {
                    let re = new RegExp(config.OSS_PREFIX + "(.*)")
                    newItem.url = newItem.url.match(re)[1];
                }
                return newItem
            })
            return newList
        }
        return [];
    };

    // 图片回显添加前缀
    const addOssUrl = (imgList) => {
        if (imgList && imgList.length) {
            let newList = imgList.map(item => {
                let newItem = { ...item };
                if (newItem.url && !newItem.url.includes(config.OSS_PREFIX)) {
                    newItem.url = config.OSS_PREFIX + newItem.url;
                }
                return newItem;
            });
            return newList;
        }
        return [];
    };

    const checkAndSetFiles = (values) => {
        const fileLists = [
            { list: spooneryFileList, key: 'presentationDoc' },
            { list: directFileList, key: 'hostDoc' },
            { list: speechFileList, key: 'orationDoc' }
        ];

        // 检查是否有文件正在上传
        const hasUploadingFiles = fileLists.some(({ list }) => list?.filter(file => file.status === "uploading").length > 0);
        if (hasUploadingFiles) {
            message.warn("有文件正在上传中，请等待上传完成");
            return false;
        }

        // 检查是否有文件上传失败
        const hasErrorFiles = fileLists.some(({ list }) => list?.filter(file => file.status === "error").length > 0);
        if (hasErrorFiles) {
            message.warn("有文件上传失败，请删除后重新上传");
            return false;
        }

        // 处理文件列表
        fileLists.forEach(({ list, key }) => {
            if (list && list.length > 0) {
                const tmp = list.map(file => ({
                    uid: file.uid,
                    name: file.name,
                    url: file.url,
                    type: file.type,
                    ext: file.ext,
                    lastModified: file.lastModified,
                    status: "save"
                }));

                values[key] = JSON.stringify(tmp);
            } else {
                values[key] = "[]";
            }
        });
        return true
    };


    // 提交会议（场次），新增/编辑
    const submitConferenceHall = () => {
        form.validateFields().then((values) => {

            // return;
            let newValues = { ...values };
            let isCkeck = checkAndSetFiles(newValues);
            if (!isCkeck) {
                return
            }
            newValues.directors = !!values?.directors && values?.directors.length ? values?.directors.map(d => d.toString()).join(',') : '';
            newValues.implementers = !!values?.implementers && values?.implementers.length ? values?.implementers.map(i => i.toString()).join(',') : '';

            newValues.sponsorsLogos = sponsorsLogos ? JSON.stringify(deleteFileUid(sponsorsLogos)) : undefined;
            if (newValues.partners && newValues.partners.length) {
                newValues.partners = JSON.stringify(values.partners);

            }
            if (conferenceDetail) {
                updateConference(newValues, conferenceDetail.id);
            } else {
                saveConference(newValues);
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    // 新增会议
    const saveConference = async (values) => {
        let params = { ...values };
        if (params.dates && params.dates.length) {
            params.dates = params.dates.toString();
        }
        params.conferenceId = conferenceId;
        params.pid = params.pid ? params.pid : 0;

        try {
            setConferenceHallLoading(true);
            const res = await saveConferenceApi(params);
            if (res.status_code === 200) {
                message.success('会议保存成功');
                closeAddConferenceHallDrawer();
                getConferenceList();
                form.resetFields();
                setPlaceName(undefined);
                setSponsorsLogos([]);
            }
            setConferenceHallLoading(false);
        } catch (e) {
            setConferenceHallLoading(false);
        }

    };

    // 编辑会议
    const updateConference = async (values, id) => {
        let params = { ...values };
        if (params.dates && params.dates.length) {
            params.dates = params.dates.toString();
        }
        params.conferenceId = conferenceId;
        params.pid = params.pid ? params.pid : 0;
        params.id = id;


        try {
            setConferenceHallLoading(true);
            const res = await updateConferenceApi(params);
            if (res.status_code === 200) {
                message.success('会议编辑成功');
                closeAddConferenceHallDrawer();
                getConferenceList();

                if (id === currentConferenceHall.id) {
                    setCurrentConferenceHall({
                        ...currentConferenceHall,
                        ...values,
                    });
                }
                form.resetFields();
                setPlaceName(undefined);
                setSponsorsLogos([]);
            }
            setConferenceHallLoading(false);
        } catch (e) {
            setConferenceHallLoading(false);
        }
    };

    const closeAddConferenceHallDrawer = () => {
        setVisibleAddConferenceHallDrawer(false);
        form.resetFields();
        setPlaceName(undefined);
        setSponsorsLogos([]);
    };

    // 获取上级会议列表
    const getFirstListConference = async () => {
        const res = await getFirstListConferenceApi({ conferenceId: conferenceId });
        if (res.status_code === 200) {
            setFirstConferenceList(res.data);
        }
    };

    const swapItems = (arr, index1, index2) => {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0]
        return arr
    }

    // 上移
    const upData = (index, id) => {
        let arr = currentConferenceDetails.sponsorHallMap[id];
        let newArr = [];
        if (arr.length > 1 && index !== 0) {
            newArr = [...swapItems(arr, index, index - 1)];
        }

        currentConferenceDetails.sponsorHallMap[id] = newArr

        setCurrentConferenceDetails({
            ...currentConferenceDetails
        })
        saveSponsorSort(currentConferenceDetails, newArr, id)
    };

    // 下移
    const downData = (index, id) => {
        let arr = currentConferenceDetails.sponsorHallMap[id];
        let newArr = [];
        if (arr.length > 1 && index !== (arr.length - 1)) {
            newArr = [...swapItems(arr, index, index + 1)];
        }
        currentConferenceDetails.sponsorHallMap[id] = newArr
        setCurrentConferenceDetails({
            ...currentConferenceDetails
        })
        saveSponsorSort(currentConferenceDetails, newArr, id)
    };

    const saveSponsorSort = async (details, arr, sponsorId) => {
        let params = {
            hallId: details.id,
            sponsorIds: arr.map(item => item.sponsorId).join(','),
            sponsorType: sponsorId

        }

        console.log(params)
        const res = await sortSponsor(params)
        if (res.status_code === 200) {
            // message.success('排序成功')
        }
    };

    const fetchOrgList = async (keyword, type) => {
        setSearchValue(keyword);
        if (!keyword) return [];
        const res = await caseOrganizationApi({ keyword, type: type || undefined });
        if (res.status_code === 200 && res.data && res.data.length) {
            const list = res.data.map(org => ({
                label: org.hosName,
                value: org.id
            }));
            return list;
        }
        return [];
    }
    const onSponnerHideChange = (checked, index) => {
        let arr = currentConferenceDetails.partners
        arr[index].hide = checked ? 0 : 1
        setCurrentConferenceDetails({
            ...currentConferenceDetails,
            partners: arr
        })
        form.setFieldsValue({
            partners: arr
        })
        console.log(arr)
    }
    const swapParentItems = () => {
        let arr = form.getFieldValue('partners').map((item, index) => {
            return {
                ...item,
                sort: index + 1
            }
        })
        setCurrentConferenceDetails({
            ...currentConferenceDetails,
            partners: arr
        })
        form.setFieldsValue({
            partners: arr
        })
        console.log(arr)

    }

    const getSponsors = (name) => {
        if (!currentConferenceDetails.sponsorHallMap) {
            return []
        }
        if (!form.getFieldValue(['partners', name, "id"])) {
            return []
        }
        if (!currentConferenceDetails.sponsorHallMap[form.getFieldValue(['partners', name, "id"])]) {
            return []
        }
        return currentConferenceDetails.sponsorHallMap[form.getFieldValue(['partners', name, "id"])]
    }

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOrgList(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 500);
    }, [fetchOrgList]);

    // 新增/编辑 会议地点
    const handlePlaceModal = (item) => {
        if (item) {
            placeForm.setFieldsValue(item);
            setCurrentPlace(item);
            setPlaceModalId(item.id);
        } else {
            placeForm.resetFields();
            setCurrentPlace(item);
            setPlaceModalId(null);
        }
        setPlaceModalOpen(true);
        console.log('=========');
    }

    const onChangePlaceName = e => {
        console.log(e.target.value, placeForm.getFieldValue('name'), currentPlace?.name, placeForm.getFieldValue('name') === currentPlace?.name);
        if (placeForm.getFieldValue('name') !== currentPlace?.name) {
            setPlaceConfirmEnabled(true);
        }
    };

    // 删除
    const handleDeletePlace = (item) => {
        Modal.confirm({
            title: "提示",
            content: <><Text type="danger">删除后，对应会议的会议地点会自动清空</Text>，确认删除会议地点<b>{item.name}</b>？</>,
            okText: "确定",
            cancelText: "取消",
            centered: true, onOk: () => {
                console.log('确认删除', item.name);
                deleteConferencePlaces({ id: item.id })
                    .then(res => {
                        if (res.status_code === 200) {
                            // TODO 删除的是当前会议选中的会议地点时，需要清空当前会议地点字段
                            message.success(res.message);
                            queryPlaceList();
                        }
                    });
            }
        })
    }

    // 选择会议地点
    const handleChangePlace = placeId => {
        const name = placeArrList.find(v => v.id === placeId)?.name || undefined
        console.log(placeId, name);
        setPlaceName(name);
        form.setFieldsValue({ placeId: !placeId ? 0 : placeId });
    };

    // 增加会议
    const handleSubmitPlaceForm = () => {
        placeForm.validateFields().then(value => {
            if (placeModalId) {
                updateConferencePlaces({ id: placeModalId, name: value.name })
                    .then(res => {
                        if (res.status_code === 200) {
                            message.success('会议地点修改成功');
                            setPlaceModalOpen(false);
                            queryPlaceList();
                        }
                    });
            } else {
                addConferencePlaces({ conferenceId, name: value.name })
                    .then(res => {
                        if (res.status_code === 200) {
                            message.success('会议地点添加成功');
                            setPlaceModalOpen(false);
                            queryPlaceList();
                        }
                    });
            }
        });
    }

    // 会议地点接口
    const queryPlaceList = () => {
        getConferencePlaces({ conferenceId })
            .then(res => {
                if (res.status_code === 200) {
                    setPlaceArrList(res.data);
                }
            });
    }

    // 获取会议list
    useEffect(() => {
        queryPlaceList();
        initOSSData();
    }, []);

    useEffect(() => {
        if (visibleAddConferenceHallDrawer) {
            getFirstListConference();
            form.setFieldValue('dates', currentDate);
        } else {
            setConferenceDetail(null);
            form.resetFields();
        }
    }, [visibleAddConferenceHallDrawer]);

    // 编辑数据回显
    useEffect(() => {
        if (conferenceDetail) {
            let cid = conferenceDetail.id;
            hallsDetails({ id: cid })
                .then((res) => {
                    if (res.status_code === 200) {
                        let values = res.data;

                        setCurrentConferenceDetails(values);
                        console.log("start", values)
                        if (values.dates) {
                            values.dates = values.dates.split(',');
                        }
                        if (values.pid === 0) {
                            delete values.pid;
                            setDisabledConference(true);
                        } else {
                            setDisabledConference(false);
                        }

                        values.directors = !!values?.directors
                            ? values?.directors?.split(',')
                                .map(d => parseInt(d, 10) || 0)
                                .filter(d => !!d)
                            : [];
                        values.implementers = !!values?.implementers
                            ? values?.implementers?.split(',')
                                .map(d => parseInt(d, 10) || 0)
                                .filter(d => !!d)
                            : [];

                        if (values.sponsorOrgId) {
                            getOrganisationinfoApi({ id: values.sponsorOrgId }).then(res => {
                                if (res.status_code === 200 && res.data) {
                                    setOptions([{ label: res.data.hosName, value: values.sponsorOrgId }]);
                                    setCurrentAddOrg({ label: res.data.hosName, value: values.sponsorOrgId });
                                }
                            })
                        }
                        if (values.presentationDoc) {
                            setSpooneryFileList(JSON.parse(values.presentationDoc))
                        }
                        if (values.hostDoc) {
                            setDirectFileList(JSON.parse(values.hostDoc))
                        }
                        if (values.orationDoc) {
                            setSpeechFileList(JSON.parse(values.orationDoc))
                        }

                        // 赞助企业logo
                        if (values.sponsorsLogos) {
                            let logoList = addOssUrl(JSON.parse(values.sponsorsLogos)) || [];
                            setSponsorsLogos(logoList);
                        }

                        if (values.partners) {
                            values.partners = JSON.parse(values.partners)
                        }
                        console.log("end", values)

                        setPlaceName(values.place);
                        form.setFieldsValue(values);
                    }
                });
        } else {
            setDisabledConference(false);
            setSpooneryFileList()
            setDirectFileList()
            setSpeechFileList()
        }
    }, [conferenceDetail]);

    return (<Drawer
        title={conferenceDetail ? "编辑会议" : "新增会议"}
        width={drawerWidth}
        open={visibleAddConferenceHallDrawer}
        onClose={closeAddConferenceHallDrawer}
        layout="horizontal"
        maskClosable={false}
        footer={<div style={{ textAlign: 'right' }}>
            <Space>
                <Button onClick={closeAddConferenceHallDrawer}>取消</Button>
                <Button type="primary" loading={conferenceHallLoading} onClick={submitConferenceHall}>确定</Button>
            </Space>
        </div>}
    >
        <div className="conference-form">
            <Form
                {...drawerFormItemLayout}
                layout="horizontal"
                name="basic"
                form={form}
                initialValues={{ hidden: 0 }}
                autoComplete="off"
            >

                <Form.Item
                    label="会议标题"
                    name="name"
                    rules={[{ required: true, message: '请输入会议标题' },]}
                >
                    <Input maxLength={300} placeholder="请输入会议标题"/>
                </Form.Item>

                <Form.Item label="所属会议" name="pid">
                    <Select
                        disabled={disabledConference}
                        allowClear
                        placeholder="请选择上级会议"
                        onChange={handleChange}
                        options={firstConferenceList}
                        popupClassName="first-conference"
                        fieldNames={{ value: 'id', label: 'name' }}
                    />
                </Form.Item>

                <Form.Item
                    label="会议日期"
                    name="dates"
                    rules={[{ required: true, message: '请选择会议日期' },]}
                >
                    <Checkbox.Group style={{ minWidth: '100%' }}>
                        <Row>
                            {dateList && dateList.length ? dateList.map((date, index) => (<Col span={8} key={index}>
                                <Checkbox disabled={true} value={date} style={{ lineHeight: '32px' }}>{date}</Checkbox>
                            </Col>)) : null}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    label="会议地点"
                    name="placeId"
                >
                    <Row gutter={10}>
                        <Col span={20}>
                            <Select
                                className="place-select-content"
                                allowClear
                                showSearch
                                value={placeName}
                                onChange={handleChangePlace}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    const { name } = option.props.data;
                                    return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                                placeholder="请选择会议地点"
                            >
                                {placeArrList && Array.isArray(placeArrList) && placeArrList.map(place => {
                                    return <Option key={place.id} value={place.id} data={place}>
                                        <div className="place-content">
                                            <div className="place-title-name">{place.name}</div>
                                            <div className="place-right-option">
                                                <div
                                                    className="right-icon-edit"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePlaceModal(place);
                                                    }}
                                                >
                                                    <EditOutlined/>
                                                </div>
                                                <div
                                                    className="right-icon-delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeletePlace(place);
                                                    }}
                                                >
                                                    <DeleteOutlined/>
                                                </div>
                                            </div>
                                        </div>
                                    </Option>
                                })}

                            </Select>
                        </Col>
                        <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <a onClick={handlePlaceModal}>添加会议地点</a>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        return (!!getFieldValue('pid') || !conferenceDetail?.secondHallsList?.length) && (<>
                            <Form.Item
                                label="会议概要"
                                name="agendaSummary"
                                extra="会议概要即该会议主题及日程的概括说明，将展示在用户端会议日程及邀请任务处"
                            >
                                <Input.TextArea rows={5} placeholder="" maxLength={200} showCount/>
                            </Form.Item>

                            <Form.Item
                                label="项目负责人"
                                name="directors"
                                rules={[{ required: true, message: '会议日程项目负责人不能为空' }]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="请选择会议日程项目负责人"
                                >
                                    {staffs.map((staff, idx) => (<Select.Option key={idx} value={staff.id} disabled={staff.status === 1}>
                                        {`${staff.name} [${staff.phone}]`}
                                    </Select.Option>))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="项目执行人"
                                name="implementers"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="请选择会议日程项目执行人"
                                >
                                    {staffs.map((staff, idx) => (<Select.Option key={idx} value={staff.id} disabled={staff.status === 1}>
                                        {`${staff.name} [${staff.phone}]`}
                                    </Select.Option>))}
                                </Select>
                            </Form.Item>
                        </>);
                    }}
                </Form.Item>

                <Form.Item
                    label="项目特别说明"
                    name="projectSpecificInstructions"
                >
                    <Input.TextArea rows={5} placeholder="" maxLength={2000} showCount/>
                </Form.Item>
                <Form.Item
                    label="串场PPT"
                    name="presentationDoc">
                    <UploadComponent
                        maxCount={50}
                        files={spooneryFileList}
                        setFiles={setSpooneryFileList}
                        OSSData={OSSData}
                        getOSSData={initOSSData}
                    >
                    </UploadComponent>
                </Form.Item>
                <Form.Item
                    label="主持稿"
                    name="hostDoc">
                    <UploadComponent
                        maxCount={50}
                        files={directFileList}
                        setFiles={setDirectFileList}
                        OSSData={OSSData}
                        getOSSData={initOSSData}
                    >
                    </UploadComponent>
                </Form.Item>
                <Form.Item
                    label="致辞稿"
                    name="orationDoc">
                    <UploadComponent
                        maxCount={50}
                        files={speechFileList}
                        setFiles={setSpeechFileList}
                        OSSData={OSSData}
                        getOSSData={initOSSData}
                    >
                    </UploadComponent>
                </Form.Item>

                <Form.Item
                    name="hidden"
                    label="会议状态"
                    hidden={true}
                >
                    <Radio.Group>
                        <Radio value={0}>展示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="合作机构"
                    // name="partners"
                    rules={[
                        { required: true, message: '请输入赞助企业介绍' }
                    ]}
                >
                    <span style={{ color: '#999999', fontSize: '12px' }}>该模块增删数据请前往机构管理编辑。此位置支持隐藏/显示模块控制、模块顺序调整，及每个模块下的机构顺序调整</span>
                    <Form.List name="partners">
                        {(fields, { add, remove, move }) => (
                            fields.map(({ key, name, ...resetField }, index) => (
                                <Form.Item className='sponsors-item' key={`subItem${key}`}>
                                    <div className='sponsors-item-title'>
                                        <span className='sponsors-item-title-text'>{form.getFieldValue(['partners', name, 'name'])}</span>
                                        <Switch
                                            className='sponsors-item-switch'
                                            checkedChildren="显示"
                                            unCheckedChildren="隐藏"
                                            defaultChecked={form.getFieldValue(['partners', name, 'hide']) === 0}
                                            onChange={(checkd) => onSponnerHideChange(checkd, index)}
                                            style={{ opacity: getSponsors(name).length > 0 ? 1 : 0 }}
                                        />

                                        {index !== 0 ? <ArrowUpOutlined style={{ marginRight: '4px' }} onClick={() => {
                                            move(index, index - 1);
                                            swapParentItems()
                                        }}/> : null}
                                        {index !== fields.length - 1 ? <ArrowDownOutlined onClick={() => {
                                            move(index, index + 1);
                                            swapParentItems()
                                        }}/> : null}
                                    </div>
                                    <>
                                        {getSponsors(name).map((item, idx) => (
                                            <div className='sponsors-item-sub-title'>
                                                <span className='sponsors-item-sub-title-text'>{item.sponsorName}</span>
                                                {idx !== 0 ? <ArrowUpOutlined style={{ marginRight: '4px' }} onClick={() => {
                                                    upData(idx, form.getFieldValue(['partners', name, 'id']))
                                                }}/> : null}
                                                {idx !== getSponsors(name).length - 1 ? <ArrowDownOutlined onClick={() => {
                                                    downData(idx, form.getFieldValue(['partners', name, 'id']))
                                                }}/> : null}
                                            </div>
                                        ))}
                                        {getSponsors(name).length < 1 && <span className='sponsors-no-tip'>暂无数据</span>}
                                    </>
                                </Form.Item>
                            ))
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item
                    label="赞助企业Logo"
                    name="sponsorsLogos"
                    extra="Logo尺寸336x120px，最多上传6个，支持 JPG/PNG 格式"
                >
                    <Upload
                        customRequest={(e) => upload(e)}
                        listType="picture-card"
                        fileList={sponsorsLogos}
                        beforeUpload={beforeUpload}
                        accept="image/jpeg, image/png, image/jpg"
                        onRemove={(e) => removeSplashImages(e)}
                        maxCount={6}
                    >
                        {sponsorsLogos.length >= 6 ? null :
                            <div>
                                <PlusOutlined/>
                                <div>上传图片</div>
                            </div>}
                    </Upload>
                </Form.Item>
            </Form>
        </div>

        <Modal
            title={`${!isEmpty(currentPlace) ? '编辑' : '新增'}会议地点`}
            centered
            open={placeModalOpen}
            onCancel={() => setPlaceModalOpen(false)}
            footer={[
                <Button key="cancel" onClick={() => setPlaceModalOpen(false)}>取消</Button>,
                <Button
                    type="primary"
                    key="confirm"
                    disabled={!placeConfirmEnabled}
                    onClick={handleSubmitPlaceForm}>
                    确定
                </Button>
            ]}
        >
            <Form form={placeForm}>
                <Form.Item
                    label="会议地点"
                    name="name"
                    rules={[{ required: true, message: '请输入会议地点' }]}
                >
                    <Input
                        maxLength={100}
                        placeholder="请输入会议地点"
                        autoComplete="off"
                        onChange={e => onChangePlaceName(e)}
                    />
                </Form.Item>
            </Form>
        </Modal>

    </Drawer>);
};
export default AddConferenceDrawer;
