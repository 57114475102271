import React, { Fragment, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Layout, Input, Select, Form, Table, Space } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import Addwhite from "./Addwhite";
import Seewhite from "./Seewhite";
import "./index.less";
import { listSurveyWhiteApi, delSurveyWhitListsApi, addSurveyWhitListsApi, checkWhiteApi } from "@services/Wj/Question";
const Whitelists = (_, ref) => {
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [selectionType, setSelectionType] = useState("checkbox");
    const [addOpen, setAddOpen] = useState(false);
    const [seeOpen, setSeeOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [mobileList, setMobileList] = useState([]);
    const [phoneArr, setPhoneArr] = useState([]);
    const [actionData, setActionData] = useState([]);
    const [surveyTitle, setSurveyTitle] = useState(undefined);
    const location = useLocation();
    console.log(location);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const onReset = () => {
        form.resetFields();
        getData();
    };
    useImperativeHandle(ref, () => ({
        surveyTitle,
    }));
    const userType = [
        {
            value: 1,
            label: "未访问",
        },
        {
            value: 2,
            label: "已访问",
        },
    ];
    const columns = [
        {
            title: "序号",
            dataIndex: "id",
        },
        {
            title: "手机号",
            dataIndex: "mobile",
        },
        {
            title: "状态",
            dataIndex: "type",
            render: (type, row) => (
                <Fragment>
                    {type === 1 && <span> 未访问</span>}
                    {type === 2 && <span> 已访问</span>}
                </Fragment>
            ),
        },

        {
            title: "操作",
            key: "action",
            render: (_, row) => (
                <Fragment>
                    <Button type="link" className="setting-button" onClick={() => seeItem(row.mobile)} disabled={row.type === 1}>
                        查看
                    </Button>
                    <Button type="link" danger className="setting-button" onClick={() => delItem(row.mobile)}>
                        删除
                    </Button>
                </Fragment>
            ),
        },
    ];
    const rowSelection = {
        onChange: (_, selectedRows) => {
            let mobile = selectedRows.map((item) => {
                return item.mobile;
            });
            setMobileList(mobile);
        },
        getCheckboxProps: (record) => ({
            phone: record.phone,
        }),
    };

    const addWhitelists = () => {
        setAddOpen(true);
    };
    const delWhitelists = () => {
        console.log(mobileList);
        console.log({ phones: mobileList, surveyId: location.state.id });
        let delItem = { phones: mobileList, surveyId: location.state.id };
        delSurveyWhitListsApi(delItem).then((res) => {
            if (res.status_code === 200) {
                getData();
            }
        });
    };
    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const delItem = (mobile) => {
        let phones = [];
        phones.push(mobile);
        let item = { phones, surveyId: location.state.id };
        delSurveyWhitListsApi(item).then((res) => {
            if (res.status_code === 200) {
                getData();
            }
        });
    };

    const seeItem = (mobile) => {
        checkWhiteApi({ id: location.state.id, phone: mobile }).then((res) => {
            if (res.status_code === 200) {
                setActionData(res.data);
            }
        });
        setSeeOpen(true);
    };

    const onSearch = () => {
        console.log(form.getFieldsValue());
        listSurveyWhiteApi({ ...form.getFieldsValue(), surveyId: location.state.id, page, pageSize }).then((res) => {
            if (res.status_code === 200) {
                let resArr = res.data?.list.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });

                setTableData(resArr);
                setTotal(res.data.totalCount);
            }
        });
    };
    const getData = () => {
        listSurveyWhiteApi({ surveyId: location.state.id, page, pageSize }).then((res) => {
            if (res.status_code === 200) {
                let resArr = res.data?.list.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });

                setTableData(resArr);
                setTotal(res.data.totalCount);
            }
        });
    };
    const onOk = (arr) => {
        addSurveyWhitListsApi({
            phones: arr,
            surveyId: location.state.id,
        }).then((res) => {
            if (res.status_code === 200) {
                getData();
            }
        });
        setAddOpen(false);
    };

    useEffect(() => {
        // const { name, type, status } = filObj
        getData();
    }, [phoneArr, page, pageSize]);

    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="setting-title">
                            <Space direction="vertical" size={16}>
                                <div style={{ cursor: "pointer" }} onClick={() => window.history.back()}>
                                    <LeftOutlined />
                                    返回
                                </div>
                                <div>
                                    <Form layout="inline" form={form}>
                                        <Form.Item name="mobile" label="手机号:">
                                            <Input placeholder="请输入手机号" />
                                        </Form.Item>
                                        <Form.Item name="type" label="状态:">
                                            <Select placeholder="请选择状态" options={userType}></Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Space>
                                                <Button type="primary" htmlType="submit" onClick={onSearch}>
                                                    查询
                                                </Button>
                                                <Button htmlType="button" onClick={onReset}>
                                                    重置
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div>
                                    <Space>
                                        <Button type="primary" onClick={addWhitelists}>
                                            添加白名单用户
                                        </Button>
                                        <Button danger onClick={delWhitelists}>
                                            批量删除
                                        </Button>
                                    </Space>
                                </div>
                            </Space>
                            <div className="white_table_wrap">
                                <Table
                                    rowSelection={{
                                        type: selectionType,
                                        ...rowSelection,
                                    }}
                                    pagination={{
                                        total: total,
                                        showTotal: () => `共${total}条`,
                                        onChange: onChangePage,
                                        showQuickJumper: true,
                                        showSizeChanger: true,
                                    }}
                                    columns={columns}
                                    dataSource={tableData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>

            <Addwhite addOpen={addOpen} setAddOpen={setAddOpen} onOk={onOk} />
            <Seewhite seeOpen={seeOpen} setSeeOpen={setSeeOpen} actionData={actionData} />
        </Fragment>
    );
};

export default forwardRef(Whitelists);
