import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import moment from 'moment';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import {
    Button,
    Checkbox,
    Drawer,
    Select,
    Skeleton,
    Space,
    Tooltip,
    TreeSelect,
    Timeline
} from 'antd';
import { getAllAgendasApi } from '@services/Agenda';
import './index.less';
import config from '@config';
import {
    prepareBCOSSUrl,
    getCityNameByCode,
    getProvinceNameByCode
} from '@utils/utils';
import mpCode from '../../../../../static/images/mp_code.png';

const PreviewAgendasDrawer = (props) => {
    const params = useParams();
    let conferenceId = params?.id || 0; // 会议项目 ID
    conferenceId = parseInt(conferenceId, 10) || 0;
    // console.log('conferenceId', conferenceId);

    const {
        visiblePreviewAgendasDrawer,
        setVisiblePreviewAgendasDrawer,
        conference
    } = props;

    const [agendaData, setAgendaData] = useState(null); // 日程数据
    const [currentAgendaDate, setCurrentAgendaDate] = useState(''); // 当前日程日期
    const [currentConferenceHallId, setCurrentConferenceHallId] = useState(); // 当前场次 ID
    const [visibleAgendaOnly, setVisibleAgendaOnly] = useState(true); // 仅看显示日程
    const [conferenceHallTreeData, setConferenceHallTreeData] = useState([]); // 会议场次树形结构数据

    const onChangeVisibleAgendaOnlyCheckbox = e => setVisibleAgendaOnly(e.target.checked);

    const prepareAgendaListGuestInfo = data => {
        // console.log('agendaData', agendaData);
        let agendas = null;

        if (!_.isEmpty(data) && !_.isEmpty(data.conferenceList)) {
            agendas = data.conferenceList;

            // 遍历会议日程数据中的 key，即会议日期
            Object.keys(agendas).forEach(confDate => {
                if (!moment(confDate).isValid()) {
                    return;
                }

                // 改日期 key
                const d = moment(confDate).format('YYYY年M月D日');
                const e = moment(confDate).format('e');
                // console.log(d);
                // console.log(e);
                const weekText = ['一', '二', '三', '四', '五', '六', '日'][e];
                const day = `${d}（周${weekText}）`;
                // console.log(day)
                if (agendas[confDate] && agendas[confDate].length) {
                    agendas[day] = agendas[confDate];
                }
                delete agendas[confDate];

                // 会议日期-会议场次
                const confHalls = agendas[day];
                // console.log(confHalls);
                confHalls && confHalls.length && confHalls.forEach(confHall => {

                    if (confHall.subHallList && confHall.subHallList.length) {
                        // 有二级会场
                        // 遍历二级会场
                        confHall.subHallList.forEach(subHall => {
                            const hosts = [];
                            const speakers = [];
                            let totalStartTime = '';
                            let totalEndTime = '';

                            let agendaList = [];
                            subHall.agendas && subHall.agendas.length
                            && subHall.agendas.forEach((a, idx, arr) => {
                                if (a.type !== 5 || a.type !== 6) { // 1主持, 2演讲, 3讨论, 4仪式, 5赞助企业, 6工作人员
                                    let tempAgenda = { ...a };

                                    let startTime = tempAgenda.startTime || '';
                                    startTime = startTime.slice(0, 5);
                                    tempAgenda['startTime'] = startTime;

                                    let endTime = tempAgenda.endTime || '';
                                    endTime = endTime.slice(0, 5);
                                    tempAgenda['endTime'] = endTime;

                                    if (totalStartTime === '') {
                                        totalStartTime = startTime;
                                    } else {
                                        if (totalStartTime > startTime) {
                                            totalStartTime = startTime;
                                        }
                                    }
                                    if (totalEndTime === '') {
                                        totalEndTime = endTime;
                                    } else {
                                        if (totalEndTime < endTime) {
                                            totalEndTime = endTime;
                                        }
                                    }

                                    // 日程嘉宾列表
                                    if (tempAgenda.guestExtra && tempAgenda.guestExtra.length) {
                                        let guests = [];

                                        const experts = data.guestList || []; // 备用专家信息列表
                                        tempAgenda.guestExtra.filter(g => g.taskName !== '赞助企业' && g.taskName !== '工作人员' && g.invitationAccepted === 1).forEach(guest => {
                                            experts.forEach(expert => {
                                                if (guest.expertId === expert.id) {
                                                    guests.push({ ...guest, ...expert });

                                                    const username = expert.memUsername;
                                                    if (tempAgenda.type === 1) {
                                                        if (hosts.indexOf(username) === -1) {
                                                            hosts.push(username);
                                                        }
                                                    } else {
                                                        if (speakers.indexOf(username) === -1) {
                                                            speakers.push(username);
                                                        }
                                                    }
                                                }
                                            });
                                        });

                                        tempAgenda.guestList = guests; // 查找到嘉宾信息回填到日程嘉宾中
                                    }

                                    agendaList.push(tempAgenda);
                                }
                            });

                            subHall.agendas = agendaList;

                            subHall['totalStartTime'] = totalStartTime;
                            subHall['totalEndTime'] = totalEndTime;

                            // console.log('totalStartTime', totalStartTime)
                            // console.log('totalEndTime', totalEndTime);

                            if (hosts.length) {
                                subHall['hosts'] = hosts.join('、');
                            }
                            if (speakers.length) {
                                subHall['speakers'] = speakers.length > 60
                                    ? speakers.slice(0, 59).join('、') + '等' + speakers.length + '位嘉宾'
                                    : speakers.join('、');
                            }
                        });

                    } else {
                        // 无二级会场
                        // 会议场次下的日程列表
                        const hosts = [];
                        const speakers = [];
                        let totalStartTime = '';
                        let totalEndTime = '';

                        let agendaList = [];
                        const confHallAgendas = confHall.agendas;
                        confHallAgendas && confHallAgendas.length
                        && confHallAgendas.forEach((a, idx, arr) => {
                            if (a.type !== 5 && a.type !== 6) { // 1主持, 2演讲, 3讨论, 4仪式, 5赞助企业, 6工作人员
                                let tempAgenda = { ...a };

                                let startTime = tempAgenda.startTime || '';
                                startTime = startTime.slice(0, 5);
                                tempAgenda['startTime'] = startTime.slice(0, 5);

                                let endTime = tempAgenda.endTime || '';
                                endTime = endTime.slice(0, 5);
                                tempAgenda['endTime'] = endTime;

                                if (totalStartTime === '') {
                                    totalStartTime = startTime;
                                } else {
                                    if (totalStartTime > startTime) {
                                        totalStartTime = startTime;
                                    }
                                }
                                if (totalEndTime === '') {
                                    totalEndTime = endTime;
                                } else {
                                    if (totalEndTime < endTime) {
                                        totalEndTime = endTime;
                                    }
                                }

                                // 日程嘉宾列表
                                if (tempAgenda.guestExtra && tempAgenda.guestExtra.length) {
                                    let guests = [];
                                    // console.log('guestExtra', a.guestExtra);

                                    const experts = data.guestList || []; // 备用专家信息列表
                                    tempAgenda.guestExtra.filter(g => g.taskName !== '赞助企业' && g.taskName !== '工作人员' && g.invitationAccepted === 1).forEach(guest => {
                                        experts.forEach(expert => {
                                            if (guest.expertId === expert.id) {
                                                guests.push({ ...guest, ...expert });

                                                const username = expert.memUsername;
                                                if (tempAgenda.type === 1) {
                                                    if (hosts.indexOf(username) === -1) {
                                                        hosts.push(username);
                                                    }
                                                } else {
                                                    if (speakers.indexOf(username) === -1) {
                                                        speakers.push(username);
                                                    }
                                                }
                                            }
                                        });
                                    });

                                    tempAgenda.guestList = guests; // 查找到嘉宾信息回填到日程嘉宾中
                                }

                                agendaList.push(tempAgenda);
                            }
                        });

                        confHall.agendas = agendaList;

                        confHall['totalStartTime'] = totalStartTime;
                        confHall['totalEndTime'] = totalEndTime;

                        // console.log('totalStartTime', totalStartTime);
                        // console.log('totalEndTime', totalEndTime);

                        if (hosts.length) {
                            confHall['hosts'] = hosts.join('、');
                        }
                        if (speakers.length) {
                            confHall['speakers'] = speakers.length > 60
                                ? speakers.slice(0, 59).join('、') + '等' + speakers.length + '位嘉宾'
                                : speakers.join('、');
                        }
                    }

                });

            });
        }

        console.log('agendas', agendas);
        return agendas;
    };

    const [agendaDataLoading, setAgendaDataLoading] = useState(false);

    const getAgendas = async conferenceId => {
        try {
            setAgendaDataLoading(true);

            const res = await getAllAgendasApi(conferenceId);
            if (res.status_code === 200) {
                const data = res.data;
                // console.log(prepareAgendaListGuestInfo(data));
                setAgendaData(prepareAgendaListGuestInfo(data));
            }

            setAgendaDataLoading(false);
        } catch (e) {
            setAgendaDataLoading(false);
        }
    };

    useEffect(() => {
        if (visiblePreviewAgendasDrawer && conferenceId) {
            getAgendas(conferenceId);
        }
    }, [visiblePreviewAgendasDrawer]);

    const closePreviewAgendasDrawer = () => {
        setCurrentAgendaDate(undefined);
        setCurrentConferenceHallId(undefined);

        setVisiblePreviewAgendasDrawer(false);
    };

    const exportHtmlDomAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el, { useCORS: true });

        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = imageFileName;
        link.href = canvas.toDataURL('image/png', 1.0);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    const downloadAgendaImage = () => {
        const el = document.getElementById('agenda-preview-content');
        const fileName = conference.name + '.png';
        exportHtmlDomAsImage(el, fileName);
    };

    const onChangeAgendaDate = value => {
        console.log('onChangeAgendaDate', value, typeof value);
        setCurrentAgendaDate(value);

        // 切换日程时，清空选择论坛ID
        setCurrentConferenceHallId(undefined);

        let treeData = [];
        const confHalls = agendaData[value];
        confHalls && confHalls.length && confHalls.forEach(confHall => {
            const node = {
                title: `${confHall.name} [YHYC#${confHall.id}]`,
                value: confHall.id,
                children: []
            };

            if (confHall.subHallList && confHall.subHallList.length) {
                confHall.subHallList.forEach(subHall => {
                    node.children.push({
                        title: `${subHall.name} [YHYC#${subHall.id}]`,
                        value: subHall.id
                    });
                });
            }

            treeData.push(node);
        });

        // console.log(treeData)
        setConferenceHallTreeData(treeData);
    };

    const onChangeConferenceHall = value => {
        console.log('onChangeConferenceHall', value);
        setCurrentConferenceHallId(value); // 选中某个会议论坛

        agendaData && Object.keys(agendaData) && Object.keys(agendaData)?.length
        && Object.keys(agendaData).forEach(agendaDate => {
            agendaData[agendaDate].forEach(hall => {
                if (hall.id === value) { // 该会议论坛及其子论坛，都显示
                    hall.previewHidden = 0; // previewHidden 仅用于选择论坛时附加隐藏显示字段

                    hall.subHallList && hall.subHallList.length
                    && hall.subHallList.forEach(subHall => {
                        subHall.previewHidden = 0;
                    });
                } else {
                    hall.previewHidden = 1;
                    hall.subHallList && hall.subHallList.length
                    && hall.subHallList.forEach(subHall => {
                        if (subHall.id === value) {
                            hall.previewHidden = 0; // 子论坛要展示，父论坛也不能隐藏
                            subHall.previewHidden = 0;
                        } else {
                            subHall.previewHidden = 1;
                        }
                    });
                }
            });
        });
    };

    const renderConferenceHall = hall => <>
        {!!hall?.agendaSummary && (
            <tr className="conference-hall-agenda-summary" style={conferenceId === 37 ? { backgroundColor: '#DD174C26' } : null}>
                <td colSpan="4">
                    <div className="conf-extra">
                        <div className="agenda-summary">
                            <b>会议概要：</b>{hall.agendaSummary}
                        </div>
                    </div>
                </td>
            </tr>
        )}
    </>;

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + '/' + url;
        }
        return '';
    };

    const agendaSettings = useMemo(() => {
        if (conference?.agendaSettings) {
            const values = JSON.parse(conference.agendaSettings);
            return values;
        }
        return null;
    }, [conference]);

    return (<>

        <Drawer
            title="预览日程"
            height="100%"
            placement="bottom"
            open={visiblePreviewAgendasDrawer}
            onClose={closePreviewAgendasDrawer}
        >

            <div className="agenda-preview-container">
                <div className="agenda-preview-title">
                    <div className="conference-name">
                        <Select
                            placeholder="选择日程日期"
                            size="small"
                            style={{ width: 150, marginRight: 8 }}
                            onChange={onChangeAgendaDate}
                            defaultValue={0}
                        >
                            <Select.Option value={0}>全部日期</Select.Option>
                            {agendaData && Object.keys(agendaData)
                                && Object.keys(agendaData).map(agendaDate => (
                                    <Select.Option value={agendaDate} key={agendaDate}>
                                        {agendaDate.replace(/（.+?）/, '')}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                        {!!currentAgendaDate && (
                            <TreeSelect
                                size="small"
                                showSearch
                                style={{ width: 200, marginRight: 8 }}
                                dropdownStyle={{ minWidth: 500, overflow: 'auto' }}
                                placement="bottomLeft"
                                allowClear
                                value={currentConferenceHallId}
                                onChange={onChangeConferenceHall}
                                treeNodeFilterProp="title"
                                placeholder="选择会议论坛"
                                treeDefaultExpandAll
                                treeData={conferenceHallTreeData}
                            />
                        )}
                        <Checkbox checked={visibleAgendaOnly} onChange={onChangeVisibleAgendaOnlyCheckbox}>仅看显示日程</Checkbox>

                    </div>
                    <div className="toolbar">
                        <Space>
                            <Button
                                size="small"
                                onClick={() => downloadAgendaImage()}
                                icon={<span className="anticon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                    </svg>
                                </span>}
                            >
                                下载日程图片
                            </Button>
                        </Space>
                    </div>
                </div>

                <div
                    id="agenda-preview-content"
                    className="agenda-preview-content"
                    style={{
                        backgroundColor: !!agendaSettings?.web?.background_color ? agendaSettings?.web?.background_color : 'unset',
                        backgroundImage: !!agendaSettings?.web?.head_img_url ? `url("${prepareBCOSSUrl(agendaSettings?.web?.head_img_url)}")` : 'unset',
                    }}
                >
                    {agendaDataLoading
                        ? <>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                        </>
                        : <>
                            <h1 style={{ height: !!agendaSettings?.web?.head_img_url ? 424 : 100 }}>
                                {!agendaSettings?.web?.head_img_url ? <span className="head-title">{conference?.name}</span> : null}
                            </h1>

                            {agendaData && Object.keys(agendaData)
                                && Object.keys(agendaData)
                                    .filter(d => !currentAgendaDate || d === currentAgendaDate)
                                    .map(agendaDate => (
                                        <div className="agenda-date" key={`agendaDate_${agendaDate}`}>
                                            {(!!conference?.provinceCode || !!conference?.cityCode) && (
                                                <div className="conference-area">
                                                    会议地点：{getProvinceNameByCode(conference?.provinceCode) === getCityNameByCode(conference?.provinceCode, conference?.cityCode)
                                                    ? getProvinceNameByCode(conference?.provinceCode)
                                                    : `${getProvinceNameByCode(conference?.provinceCode)} ${getCityNameByCode(conference?.provinceCode, conference?.cityCode)}`}
                                                </div>
                                            )}

                                            {!!conference?.place && (
                                                <div className="conference-place">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                    </svg>
                                                    <span>{conference.place}</span>
                                                </div>
                                            )}

                                            <h2>{agendaDate}</h2>
                                            {agendaData[agendaDate] && agendaData[agendaDate].length
                                                && agendaData[agendaDate].filter(h => ((visibleAgendaOnly && !h.hidden) || !visibleAgendaOnly) && (!currentConferenceHallId || !h.previewHidden))
                                                    .map(hall => {
                                                        return (
                                                            <div className="conference-hall-box" key={`hall_${hall.id}`}>
                                                                {hall.subHallList && hall.subHallList.filter(h => ((visibleAgendaOnly && !h.hidden) || !visibleAgendaOnly) && (!currentConferenceHallId || !h.previewHidden)).length
                                                                    ? hall.subHallList.filter(h => ((visibleAgendaOnly && !h.hidden) || !visibleAgendaOnly) && (!currentConferenceHallId || !h.previewHidden))
                                                                        .map(subHall => (
                                                                            <table key={`hall_${hall.id}_${subHall.id}`}>
                                                                                <div className="conference-title-wrap">
                                                                                    <div className="conference-hall">
                                                                                        <div className="hall-text" style={{ color: agendaSettings?.web?.conf_hall_title_color }}>{hall.name}</div>
                                                                                        {!!hall.hidden && <div className="conf-display-hidden">会议隐藏 →</div>}
                                                                                    </div>
                                                                                    <div className="conference-sub-hall">
                                                                                        <div className="sub-hall-title" style={{ color: agendaSettings?.web?.conf_hall_title_color }}>{subHall.name}</div>
                                                                                        {!!subHall.place && <div className="hall-place">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                                                                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                                                            </svg>
                                                                                            <span>{subHall.place}</span>
                                                                                        </div>}
                                                                                        {!!subHall.hidden && <div className="conf-display-hidden">会议隐藏 →</div>}
                                                                                    </div>
                                                                                </div>
                                                                                {/* {renderConferenceHall(subHall)} */}
                                                                                {subHall.agendas && subHall.agendas.filter(a => (visibleAgendaOnly && !a.hidden) || !visibleAgendaOnly).length
                                                                                    ? (
                                                                                        <>
                                                                                            <Timeline className="timeline-wrap" mode="left">
                                                                                                {subHall.agendas.filter(a => (visibleAgendaOnly && !a.hidden) || !visibleAgendaOnly).map(agenda => (
                                                                                                    <Timeline.Item label={`${agenda.startTime}～${agenda.endTime}`}>
                                                                                                        <div className="agenda" id={agenda.id} key={agenda.id}>
                                                                                                            <h3 className="subject" style={{ color: agendaSettings?.web?.agenda_task_color }}>{agenda.title}</h3>
                                                                                                            {!agenda.guestsHidden && !!agenda.guestList && agenda.guestList.length === 1 && (
                                                                                                                <table>
                                                                                                                    <tr>
                                                                                                                        <td className="guest-name centered">{agenda.guestList[0]?.memUsername?.replace(/^(.)(.)$/, '$1　$2')}</td>
                                                                                                                        <td className="guest-info">
                                                                                                                            {agenda.guestList[0]?.ssoMemberWorkExperiencesEntities?.map((w, i) => (
                                                                                                                                <div className="guest-work-experience" key={`agenda_${agenda.id}_expert_${w.expertId}_${w.id}_${i}`}>
                                                                                                                                    {!!w.unitName && <span>{w.unitName}</span>}
                                                                                                                                    {!!w.unitOffice && <span>{w.unitOffice}</span>}
                                                                                                                                    {!!w.unitPost && <span>{w.unitPost}</span>}
                                                                                                                                    {!!w.unitJobTitle && <span>{w.unitJobTitle}</span>}
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </table>
                                                                                                            )}
                                                                                                            {!agenda.guestsHidden && !!agenda.guestList && agenda.guestList.length > 1 && (
                                                                                                                <table>
                                                                                                                    {agenda.guestList.map((guest, idx) => (
                                                                                                                        <tr key={`agenda_${agenda.id}_expert_${guest.expertId}_${idx}`}>
                                                                                                                            <td className="guest-name centered">{guest?.memUsername?.replace(/^(.)(.)$/, '$1　$2')}</td>
                                                                                                                            <td className="guest-info">
                                                                                                                                {guest?.ssoMemberWorkExperiencesEntities?.map((w, i) => (
                                                                                                                                    <div className="guest-work-experience" key={`agenda_${agenda.id}_expert_${w.expertId}_${w.id}_${i}`}>
                                                                                                                                        {!!w.unitName && <span>{w.unitName}</span>}
                                                                                                                                        {!!w.unitOffice && <span>{w.unitOffice}</span>}
                                                                                                                                        {!!w.unitPost && <span>{w.unitPost}</span>}
                                                                                                                                        {!!w.unitJobTitle && <span>{w.unitJobTitle}</span>}
                                                                                                                                    </div>
                                                                                                                                ))}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    ))}
                                                                                                                </table>
                                                                                                            )}
                                                                                                            {!agenda.guestList && (
                                                                                                                <>
                                                                                                                    <td className="guest"></td>
                                                                                                                    <td className="guest-info"></td>
                                                                                                                </>
                                                                                                            )}
                                                                                                            {!visibleAgendaOnly && !!agenda.guestsHidden && <div className="guests-display-hidden">← 该日程嘉宾隐藏</div>}
                                                                                                            {!!agenda.hidden && <div className="agenda-display-hidden">日程隐藏 →</div>}
                                                                                                        </div>
                                                                                                    </Timeline.Item>
                                                                                                ))}
                                                                                            </Timeline>
                                                                                        </>
                                                                                    )
                                                                                    : null
                                                                                }
                                                                            </table>
                                                                        ))
                                                                    : (
                                                                        <table key={`hall_${hall.id}`}>
                                                                            <div className="conference-title-wrap">
                                                                                <div className="conference-hall">
                                                                                    <div className="hall-text" style={{ color: agendaSettings?.web?.conf_hall_title_color }}>{hall.name}</div>
                                                                                    {!!hall.place && <div className="hall-place">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                                                                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                                                        </svg>
                                                                                        <span>{hall.place}</span>
                                                                                    </div>}
                                                                                    {!!hall.hidden && <div className="conf-display-hidden">会议隐藏 →</div>}
                                                                                </div>
                                                                            </div>
                                                                            {/* {renderConferenceHall(hall)} */}
                                                                            {hall.agendas && hall.agendas.filter(a => (visibleAgendaOnly && !a.hidden) || !visibleAgendaOnly).length
                                                                                ? (
                                                                                    <>
                                                                                        <Timeline className="timeline-wrap" mode="left">

                                                                                            {hall.agendas.filter(a => (visibleAgendaOnly && !a.hidden) || !visibleAgendaOnly)
                                                                                                .map(agenda => (
                                                                                                    <Timeline.Item label={`${agenda.startTime}～${agenda.endTime}`}>
                                                                                                        <div className="agenda" key={agenda.id}>
                                                                                                            <h3 className="subject" style={{ color: agendaSettings?.web?.agenda_task_color }}>{agenda.title}</h3>
                                                                                                            {!agenda.guestsHidden && !!agenda.guestList && agenda.guestList.length === 1 && (
                                                                                                                <table>
                                                                                                                    <tr>
                                                                                                                        <td className="guest-name centered">{agenda.guestList[0]?.memUsername?.replace(/^(.)(.)$/, '$1　$2')}</td>
                                                                                                                        <td className="guest-info">
                                                                                                                            {agenda.guestList[0]?.ssoMemberWorkExperiencesEntities?.map((w, i) => (
                                                                                                                                <div className="guest-work-experience" key={`agenda_${agenda.id}_expert_${w.expertId}_${w.id}_${i}`}>
                                                                                                                                    {!!w.unitName && <span>{w.unitName}</span>}
                                                                                                                                    {!!w.unitOffice && <span>{w.unitOffice}</span>}
                                                                                                                                    {!!w.unitPost && <span>{w.unitPost}</span>}
                                                                                                                                    {!!w.unitJobTitle && <span>{w.unitJobTitle}</span>}
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </table>
                                                                                                            )}
                                                                                                            {!agenda.guestsHidden && !!agenda.guestList && agenda.guestList.length > 1 && (
                                                                                                                <table>
                                                                                                                    {agenda.guestList.map((guest, idx) => (
                                                                                                                        <tr key={`hall_${hall.id}_agenda_${agenda.id}_expert_${guest.expertId}_${idx}`}>
                                                                                                                            <td className="guest-name centered">{guest?.memUsername?.replace(/^(.)(.)$/, '$1　$2')}</td>
                                                                                                                            <td className="guest-info">
                                                                                                                                {guest?.ssoMemberWorkExperiencesEntities?.map((w, i) => (
                                                                                                                                    <div className="guest-work-experience" key={`agenda_${agenda.id}_expert_${w.expertId}_${w.id}_${i}`}>
                                                                                                                                        {!!w.unitName && <span>{w.unitName}</span>}
                                                                                                                                        {!!w.unitOffice && <span>{w.unitOffice}</span>}
                                                                                                                                        {!!w.unitPost && <span>{w.unitPost}</span>}
                                                                                                                                        {!!w.unitJobTitle && <span>{w.unitJobTitle}</span>}
                                                                                                                                    </div>
                                                                                                                                ))}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    ))}
                                                                                                                </table>
                                                                                                            )}
                                                                                                            {!agenda.guestList && (
                                                                                                                <>
                                                                                                                    <td className="guest-name"></td>
                                                                                                                    <td className="guest-info"></td>
                                                                                                                </>
                                                                                                            )}
                                                                                                            {!visibleAgendaOnly && !!agenda.guestsHidden && <div className="guests-display-hidden">← 该日程嘉宾隐藏</div>}
                                                                                                            {!!agenda.hidden && <div className="agenda-display-hidden">日程隐藏 →</div>}
                                                                                                        </div>
                                                                                                    </Timeline.Item>
                                                                                                ))}
                                                                                        </Timeline>
                                                                                    </>
                                                                                )
                                                                                : null
                                                                            }
                                                                        </table>
                                                                    )
                                                                }
                                                            </div>
                                                        );

                                                    })}
                                        </div>)
                                    )}

                            <div className="code-list">
                                <div className="qrcode-box">
                                    <div className="qrcode">
                                        <QRCode size={150} value={`${config.HUIYI_DOMAIN}/h5/${conference?.slug}`}/>
                                    </div>
                                    <p>长按识别二维码</p>
                                    <p>查看大会官网、全部日程</p>
                                </div>
                                <div className="qrcode-box">
                                    <div className="qrcode">
                                        <img src={mpCode}/>
                                    </div>
                                    <p>长按识别小程序码</p>
                                    <p>查看个性指南、参与活动</p>
                                </div>
                                {!!agendaSettings?.web?.qrcode_img_url && (
                                    <div className="qrcode-box">
                                        <div className="qrcode">
                                            <img src={prepareBCOSSUrl(agendaSettings.web.qrcode_img_url)}/>
                                        </div>
                                        {!!agendaSettings?.web?.qrcode_text_line1 && <p>{agendaSettings.web.qrcode_text_line1}</p>}
                                        {!!agendaSettings?.web?.qrcode_text_line2 && <p>{agendaSettings.web.qrcode_text_line2}</p>}
                                    </div>
                                )}
                            </div>

                            <div className="foot">&copy;{moment().format('YYYY')} 健康界 &bull; 医会议</div>

                        </>
                    }

                </div>
                <div className="agenda-preview-foot">

                </div>
            </div>

        </Drawer>

    </>);
};

export default PreviewAgendasDrawer;
