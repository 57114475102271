import React, { Fragment, useState,useEffect,useImperativeHandle,forwardRef,useRef } from 'react';
import { Button, Layout, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col } from 'antd';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import Facadeaction from './Facadeaction';
import Facadesetting from './Facadesetting'
import './index.less';
import Cumputerpreview from '../../components/Cumputerpreview';
import Mobilpreview from '../../components/Mobilpreview';
const Facade = (_,ref) => {
    const { Content, Sider } = Layout;
    const [cumputer, setCumputer] = useState(true);
    const [mobile, setMobile] = useState(false);
    const [cumputerCover,setCumputerCover] = useState(undefined);
    const [mobileCover, setMobileCover] = useState(undefined);
    const [surveyTitle,setSurveyTitle]=useState(undefined)
    const location =useLocation()
    const [displayTitle,setDisplayTitle] = useState(undefined)
    const [displayCopyright,setDisplayCopyright] = useState(undefined)
    const [displayLogo,setDisplayLogo] = useState(undefined)
    const [setting,setSetting] = useState(undefined)
    const [color, setColor] = useState(
        {
            r: '255',
            g: '255',
            b: '255',
            a: '1',
        }
    )
    const [selectColor, setSelectColor] = useState(false);

    useImperativeHandle(ref, () => ({
        surveyTitle
    }));
    const changeCumputer = () =>{
        setCumputer(true)
        setMobile(false)
    }

    const changeMobile = () =>{
        setCumputer(false)
        setMobile(true)
    }

    const change=(type)=>{
       if(type.includes('pc')){
        setCumputerCover(type)
        setMobileCover(type)
        setCumputer(true)
        setMobile(false)
       }else if(type.includes('app')){
        setCumputerCover(type)
        setMobileCover(type)
        setCumputer(false)
        setMobile(true)
       }
    }

    useEffect(() => {
        setSurveyTitle(location.state.title)
    }, []);

    return (
        <Fragment>
            <Sider className="survey-action vertical-scrollbar" width={220}>
                <Facadeaction   change={change} setMobile={setMobile} setting={setting} setSetting={setSetting}/>
            </Sider>
            <Content className="main-content-box crosswise-scrollbar">
                <div className="main-content-body">
                    <div>
                        <div className='preview-center'>
                            <div className='preview-action' onClick={changeCumputer}
                                style={cumputer ? { color: '#1a73e8' } : {}}
                            >
                                <DesktopOutlined className='preview-icon' />
                                <p>电脑</p>
                            </div>
                            <div className='preview-action' onClick={changeMobile}
                                style={mobile ? { color: '#1a73e8' } : {}}
                            >
                                <MobileOutlined className='preview-icon' />
                                <p>手机</p>
                            </div>
                        </div>


                {cumputer&&<Cumputerpreview
                content={location.state} type={cumputerCover} displayTitle={displayTitle} displayCopyright={displayCopyright} displayLogo={displayLogo} selectColor={selectColor}/>}
                {mobile&&<Mobilpreview   content={location.state} type={mobileCover} displayTitle={displayTitle} displayCopyright={displayCopyright} displayLogo={displayLogo} selectColor={selectColor}/>}
                    </div>
                </div>
            </Content>
            <Sider className="vertical-scrollbar" width={230}>
                    <Facadesetting
                        change={change}
                        setMobile={setMobile}
                        displayTitle={displayTitle}
                        displayCopyright={displayCopyright}
                        displayLogo={displayLogo}
                        setDisplayTitle={setDisplayTitle}
                        setDisplayCopyright={setDisplayCopyright}
                        setDisplayLogo={setDisplayLogo}
                        color={color}
                        setColor={setColor}
                        selectColor={selectColor}
                        setSelectColor={setSelectColor}
                        setting={setting}
                        />
            </Sider>
        </Fragment>
    );
}

export default forwardRef(Facade);
