import React, { useRef, useState, useEffect } from 'react';
import useChart from '@/hooks/useCharts';

export default function StatisticsChart({
    statisticChartData
}) {
    const chartRef = useRef(null);

    const [statisticsData, setStatisticsData] = useState([]);//value的
    const [statisticsX, setStatisticsX] = useState([]);//x轴的

    useEffect(() => {
        let arr = [];//value
        let arr2 = [];//time x轴
        if (statisticChartData) {
            statisticChartData.forEach(item => {
                arr.push(item.value);
                arr2.push(item.time);
            })
            setStatisticsX(arr2);
            setStatisticsData(arr);
        }
    }, [statisticChartData]);

    // echarts 统计图表
    const option = {
        tooltip: {//提示
            trigger: 'axis',
            backgroundColor: 'rgba(255,255,255,0.7)',
            extraCssText: 'width:150px;height:60px;',
            axisPointer: {//hover的时候没有虚线
                type: 'none'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: statisticsX
        },
        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            },
            splitNumber: 5
        },
        series: [
            {
                name: '人数',
                type: 'line',
                stack: 'Total',
                symbolSize: 7,//拐点大小
                symbol: "circle",
                itemStyle: {
                    color: '#1890ff',//拐点颜色
                    borderWidth: 3,//拐点边框大小
                },
                emphasis: {
                    itemStyle: {
                        color: '#1890ff',//hover拐点颜色定义
                        borderColor: '#fff',
                        borderWidth: 1,
                        shadowBlur: 7,
                        shadowColor: '#1890ff',
                    }
                },
                data: statisticsData
            }
        ]
    }

    useChart(chartRef, option);

    return (
        <div className="echarts-module" ref={chartRef}></div>
    )
}
