import React, { Fragment, useState, useEffect } from 'react';
import { pageFormItemWithoutLabelGridLayout, drawerFormItemLayout } from '@utils/property';
import { Form, Tag, Avatar, Button, Space, Layout, Upload, message } from 'antd';
import { localStorageGet } from '@/core/cookie';
import { UploadOutlined } from '@ant-design/icons';
import { post, get, put } from '@/core/request';
import './MyMessages.less'
const { Content } = Layout;

export default function MyMessages() {

    const [form] = Form.useForm();
    const [myMessage, setMyMessage] = useState(null);
    const [imgUrl, setImgUrl] = useState('');

    useEffect(() => {
        get(`/enterprise/staffs/get`).then((res) => {
            if (res.status_code === 200) {
                setMyMessage(res.data);
                setImgUrl(res.data.avatar);
            }
        })
    }, [])

    const props = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        showUploadList: false,
        customRequest: info => {
            const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('你只能上传jpg、png格式的图片!');
                return;
            }
            const isLt2M = info.file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                message.error('图片大小不正确!');
                return;
            }
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 5);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/enterprise/file", formData, option).then((res) => {
                if (res.status_code === 200) {
                    setImgUrl(res.data);
                }
            })
        }
    };

    const onFinish = () => {
        let { id } = JSON.parse(localStorageGet('infos'))
        put("/enterprise/staffs/update", {
            id,
            avatar: imgUrl,
        }).then((res) => {
            if (res.status_code === 200) {
                message.success('修改成功')
            }
        })
    }

    return (
        <Fragment>
            <div className="page-title">个人信息</div>

            <Content className="main-content-box">
                {
                    myMessage && <Form {...drawerFormItemLayout} form={form} onFinish={onFinish} className="my-message-form">

                        <Form.Item label="姓名">
                            {myMessage.name}
                        </Form.Item>

                        <Form.Item label="手机号">
                            {myMessage.phone}
                        </Form.Item>

                        <Form.Item
                            name="logoUrl"
                            label="头像"
                            help="JPG或PNG格式，建议尺寸300*300px（1:1），大小不超过1MB"
                        >
                            <Space direction="vertical" size="middle">
                                <Avatar size={64} src={imgUrl} />
                                <Upload {...props}>
                                    <Button icon={<UploadOutlined />}>更换头像</Button>
                                </Upload>
                            </Space>
                        </Form.Item>

                        <Form.Item label="企业邮箱">
                            {myMessage.email}
                        </Form.Item>

                        <Form.Item label="部门">
                            <Tag>{
                                myMessage.departments.length > 0 && myMessage.departments.map(item => {
                                    return item.name
                                }).join('/')
                            }</Tag>
                        </Form.Item>

                        <Form.Item label="职务">
                            {myMessage.post}
                        </Form.Item>

                        <Form.Item label="工号">
                            {myMessage.number}
                        </Form.Item>

                        <Form.Item label="角色">
                            {
                                myMessage.roles.length > 0 && myMessage.roles.map(item => {
                                    return <Tag key={item.id}>{item.name}</Tag>
                                })
                            }
                        </Form.Item>

                        <Form.Item label="最后登录">
                            {myMessage.lastLoginIp}
                        </Form.Item>

                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Button className="submit-button" type="primary" htmlType="submit">提交</Button>
                        </Form.Item>

                    </Form>
                }

            </Content>

        </Fragment>
    );
}


