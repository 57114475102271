import React, { Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
    getSingleOverviewApi,
    getProjectOverviewApi,
    getMedProjectApi,
    getProjectsOfType,
    fetchInvitationGroupSimpleListApi,
    medProjectOverviewTotalApi,
    singleOverviewTotalApi
 } from '@services/Mdm';
import './index.less';
import { Layout, DatePicker, Select, message, Input, Form, Row, Col, Typography, Table, Button, Empty } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { projectKeys, projectType, eventKeys, eventType } from '../../../Projects/utils';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';

const { Content } = Layout;
const { RangePicker } = DatePicker;

const options = [
    { value: 'month', label: '月度统计' },
    { value: 'quarter', label: '季度统计' },
    { value: 'year', label: '年度统计' },
    { value: 'date', label: '日期范围' },
];

const ProjectStatistics = () => {
    const date = moment(new Date());
    const startDay = moment(date).format('YYYY-MM-01');
    const endDay = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    const [model, setModel] = useState('month');
    const [dates, setDates] = useState(null);
    const [months, setMonths] = useState(date);
    const [timeSpan, setTimeSpan] = useState([startDay, endDay]);
    const [chartDate, setChartDate] = useState(undefined);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [projectChartData, setProjectChartData] = useState(undefined);

    const isClickDetail = useRef(false);  // 是否点击详情统计

    const [form] = Form.useForm();
    const [projects, setProjects] = useState([]);

    const code = Form.useWatch('code', form);
    const projectId = Form.useWatch('projectId', form);
    const event = Form.useWatch('event', form);

    useEffect(() => {
        form.resetFields(['event']);
        if (!isClickDetail.current) {
            form.resetFields(['projectId']);
        }
    }, [code, form]);

    const disabledDate = (current, type) => {
        switch (type) {
            case 'day':
                return current && current > moment().subtract(1, 'days').endOf('day');
            case 'month':
                return current && current > moment().subtract(0, 'month').endOf('month');
            case 'quarter':
                return current && current > moment().subtract(0, 'quarter').endOf('quarter');
            case 'year':
                return current && current > moment().subtract(0, 'year').endOf('year');
            default:
                break;
        }
    };

    const modelChange = (d, ds, type) => {
        console.log(d, ds, type);

        let annualJudgment;

        if (type !== 'date') {
            annualJudgment = Number(d.format('YYYY')) === Number(date.format('YYYY'));
        }

        if (type === 'year') {
            let year = d.format('YYYY'),
                lastDay,
                timePeriod;

            if (annualJudgment) {
                lastDay = endDay;
            } else {
                lastDay = `${year}-12-31`;
            }

            timePeriod = [`${year}-01-01`, lastDay];

            setTimeSpan(timePeriod);
        }

        if (type === 'month') {
            let month = d.format('YYYY-MM'),
                lastDay,
                timePeriod;

            if (d.format('MM') === date.format('MM') && annualJudgment) {
                if (d.format('DD') === '01') {
                    // 月度统计，第一天时，如 2023-08-01
                    // 时间范围处理成 startDate=2023-08-01&endDate=2023-08-01
                    lastDay = d.format('YYYY-MM-01');
                } else {
                    lastDay = endDay;
                }
            } else {
                lastDay = moment(month).add('month', 1).add('days', -1).format('YYYY-MM-DD');
            }

            timePeriod = [`${month}-01`, lastDay];
            setTimeSpan(timePeriod);

            setMonths(d);
        }

        if (type === 'quarter') {
            let quarter = d.format('Q'),
                year = d.format('YYYY'),
                lastDay,
                timePeriod;

            if ((Number(d.format('Q')) === Number(date.format('Q'))) && (annualJudgment)) {
                lastDay = endDay;
            } else {
                lastDay = d.format('Q') === '1'
                    ? `${year}-03-31`
                    : d.format('Q') === '2'
                        ? `${year}-06-30`
                        : d.format('Q') === '3'
                            ? `${year}-09-30`
                            : `${year}-12-31`
            }

            switch (quarter) {
                case '1':
                    timePeriod = [`${year}-01-01`, lastDay];
                    break;
                case '2':
                    timePeriod = [`${year}-04-01`, lastDay];
                    break;
                case '3':
                    timePeriod = [`${year}-07-01`, lastDay];
                    break;
                case '4':
                    timePeriod = [`${year}-10-01`, lastDay];
                    break;
                default:
                    break;
            }

            console.log(timePeriod);
            setTimeSpan(timePeriod);
        }

        if (type === 'date') {
            if (moment(ds[1]).diff(moment(ds[0]), 'days') > 365) {
                message.error('时间区间不得超过365天');
                setDates(null);
                setTimeSpan(undefined);
            } else {
                setDates(d);
                setTimeSpan(ds);
            }
        }

    };

    // 项目改变
    const changeProjectType = val => {
        if (!!val) {
            setData([]);
            form.resetFields(['projectId']);
            isClickDetail.current = false;

            if (val === 'medProject') {
                getMedProjectApi({ page: 1, pageSize: 9999 }).then(res => {
                    if (res && res.status_code === 200) {
                        const newList = res.data.list && res.data.list.length ? res.data.list.map(item => ({projectId: item.id, projectName: item.name})) : [];
                        setProjects(newList);
                    } else {
                        setProjects([]);
                    }
                });
            } else if (val === 'invitation') {
                fetchInvitationGroupSimpleListApi().then(res => {
                    if (res && res.status_code === 200) {
                        const newList = res.data && res.data.length ? res.data.map(item => ({projectId: item.id, projectName: item.name})) : [];
                        setProjects(newList);
                    } else {
                        setProjects([]);
                    }
                });
            } else {
                getProjectsOfType({ code: val }).then(res => {
                    if (res && res.status_code === 200) {
                        setProjects(res.data);
                    } else {
                        setProjects([]);
                    }
                });
            }
        } else {
            setProjects([]);
        }
    };

    // 详细统计
    const detailedStatistics = (record) => {
        form.setFieldValue('code', record.projectType);
        form.setFieldValue('projectId', record.projectId);
        isClickDetail.current = true;
        const val = record.projectType;
        if (!!val) {
            if (val === 'medProject') {
                getMedProjectApi({ page: 1, pageSize: 9999 }).then(res => {
                    if (res && res.status_code === 200) {
                        const newList = res.data.list && res.data.list.length ? res.data.list.map(item => ({projectId: item.id, projectName: item.name})) : [];
                        setProjects(newList);
                    } else {
                        setProjects([]);
                    }
                });
            } else if (val === 'invitation') {
                fetchInvitationGroupSimpleListApi().then(res => {
                    if (res && res.status_code === 200) {
                        const newList = res.data && res.data.length ? res.data.map(item => ({projectId: item.id, projectName: item.name})) : [];
                        setProjects(newList);
                    } else {
                        setProjects([]);
                    }
                });
            } else {
                getProjectsOfType({ code: val }).then(res => {
                    if (res && res.status_code === 200) {
                        setProjects(res.data);
                    } else {
                        setProjects([]);
                    }
                });
            }
        } else {
            setProjects([]);
        }
    };

    const medProjectColumns = [
        { title: '项目名称', dataIndex: 'projectName', key: 'projectName' },
        {
            title: '项目类型',
            dataIndex: 'projectType',
            key: 'projectType',
            render: (_, record) => (<>
                {projectType[record.projectType]}
            </>),
        },
        { title: '在线人次', dataIndex: 'onlineCount', key: 'address', },
        { title: '在线人数', dataIndex: 'onlinePerson', key: 'address', },
        { title: '完成4次在线人数', dataIndex: 'completePersonCount', key: 'address', },
        {
            title: '详细统计',
            key: 'action',
            render: (_, record) => (<>
                {!record.total && <Button type="link" style={{paddingLeft: 0}} onClick={() => detailedStatistics(record)}>详情统计</Button>}
            </>),
        },
    ];

    const columns = [
        {
            title: '在线动作',
            dataIndex: 'event',
            key: 'event',
            render: (_, record) => (<>
                {record.projectName || eventType[record.event]}
            </>),
        },
        { title: '在线人次', dataIndex: 'onlineCount', key: 'address', },
        { title: '在线人数', dataIndex: 'onlinePerson', key: 'address', },
        { title: '完成4次在线人数', dataIndex: 'completePersonCount', key: 'address', }
    ];

    // 获取单个项目数据总览 - 详细统计
    const getSingleOverviewData = async () => {
        setLoading(true);
        let reqParams = {
            startDate: timeSpan[0],
            endDate: timeSpan[1],
            code: code,
            projectId: projectId
        };
        const res = await getSingleOverviewApi(reqParams);
        const singleOverview = await singleOverviewTotalApi(reqParams);
        setLoading(false);
        if (res.status_code === 200 && res.data) {
            if (res.data.length) {
                const onlineCount = res.data.reduce((prev, cur) => (prev + cur.onlineCount), 0);
                const onlinePerson = res.data.reduce((prev, cur) => (prev + cur.onlinePerson), 0);
                let completePersonCount = 0;
                if (singleOverview.status_code === 200) {
                    completePersonCount = singleOverview?.data || 0;
                }
                const lastData = {
                    onlineCount,
                    onlinePerson,
                    completePersonCount,
                    projectName: '本项目综合统计',
                    total: 'total'
                }
                setData([...res.data, lastData]);
            } else {
                setData([]);
            }
        }
    };

    // 获取项目数据总览
    const getProjectOverviewData = async () => {
        setLoading(true);
        let reqParams = {
            startDate: timeSpan[0],
            endDate: timeSpan[1],
            code: code,
            projectId: projectId
        };
        const res = await getProjectOverviewApi(reqParams);
        const medProject = await medProjectOverviewTotalApi(reqParams);
        setLoading(false);
        if (res.status_code === 200 && res.data) {
            if (res.data.length) {
                const onlineCount = res.data.reduce((prev, cur) => (prev + cur.onlineCount), 0);
                const onlinePerson = res.data.reduce((prev, cur) => (prev + cur.onlinePerson), 0);
                let completePersonCount = 0;
                if (medProject.status_code === 200) {
                    completePersonCount = medProject?.data || 0;
                }
                const lastData = {
                    onlineCount,
                    onlinePerson,
                    completePersonCount,
                    projectName: '本项目综合统计',
                    total: 'total'
                }
                setData([...res.data, lastData]);
            } else {
                setData([]);
            }
        }
    };

    useEffect(() => {
        if (!!projectId) {
            if (code === 'medProject') {
                getProjectOverviewData();
            } else {
                getSingleOverviewData();
            }
        }
    }, [timeSpan, code, projectId]);

    return (
        <Fragment>
            <Form form={form}>
                <div className="project-statistics">
                    <div className="online-date-choice">
                        <Form.Item label="统计时间">
                            <Input.Group compact>
                                <Select
                                    style={{ width: 120 }}
                                    options={options}
                                    defaultValue="month"
                                    onSelect={(value) => {
                                        if (value !== 'date') setDates(null);
                                        if (value !== 'month') setMonths(null);
                                        setModel(value);
                                    }}
                                ></Select>
                                {model === "month" &&
                                    <DatePicker
                                        style={{ width: 200 }}
                                        onChange={(v, s) => modelChange(v, s, 'month')}
                                        picker="month"
                                        value={months}
                                        disabledDate={(current) => disabledDate(current, 'month')}
                                    />
                                }
                                {model === "quarter" &&
                                    <DatePicker
                                        style={{ width: 200 }}
                                        onChange={(v, s) => modelChange(v, s, 'quarter')}
                                        picker="quarter" format={"YYYY年-第Q季度"}
                                        disabledDate={(current) => disabledDate(current, 'quarter')}
                                    />
                                }
                                {model === "year" &&
                                    <DatePicker
                                        style={{ width: 200 }}
                                        onChange={(v, s) => modelChange(v, s, 'year')}
                                        picker="year"
                                        disabledDate={(current) => disabledDate(current, 'year')}
                                    />
                                }
                                {model === "date" &&
                                    <RangePicker
                                        style={{ width: 300 }}
                                        allowClear
                                        value={dates}
                                        onChange={(v, s) => modelChange(v, s, 'date')}
                                        disabledDate={(current) => disabledDate(current, 'day')}
                                    />
                                }
                            </Input.Group>
                        </Form.Item>
                    </div>

                    <div className="online-project-choice">
                        <Row>
                            <Form.Item label="参与项目" name="code">
                                <Select
                                    allowClear
                                    placeholder="选择项目类型"
                                    onChange={changeProjectType}
                                >
                                    <Select.Option value='medProject'>医项目</Select.Option>
                                    <Select.Option value={projectKeys.survey}>{projectType.survey}</Select.Option>
                                    <Select.Option value={projectKeys.conference}>{projectType.conference}</Select.Option>
                                    <Select.Option value={projectKeys.selection}>{projectType.selection}</Select.Option>
                                    <Select.Option value={projectKeys.invitation}>{projectType.invitation}</Select.Option>
                                    <Select.Option value={projectKeys.researchhospital}>{projectType.researchhospital}</Select.Option>
                                </Select>
                            </Form.Item>

                            {!!code && (
                                <Form.Item label="" name="projectId">
                                    <Select placeholder="选择项目" showSearch optionFilterProp="children" allowClear>
                                        {projects.map((p, index) => (
                                            <Select.Option key={index} value={p.projectId}>
                                                {`${p.projectName} [#${p.projectId}]`}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Row>
                    </div>
                </div>
            </Form>

            {!!code
                ? (
                    <div className="projectTableWrap">
                        <Table columns={code === 'medProject' ? medProjectColumns : columns} dataSource={data} pagination={false} loading={loading}/>
                    </div>
                )
                : (
                    <div className="emptyProjectWrap">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<><ArrowUpOutlined />请先选择项目</>} />
                    </div>
                )
            }
        </Fragment>
    );
};

export default ProjectStatistics;
