import React, { useRef, useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Input, Form, Space, Button } from 'antd';
const { TextArea } = Input;

export default function SupplyBlankPart({
    index,
    element,
    handleClickDel,
    setInput,
    inputValue,
    moveList
}) {

    const dragRef = useRef(null);

    const [, drag] = useDrag({
        type: 'child',
        item: { index, element }
    });

    const [, drop] = useDrop({
        accept: 'child',
        drop: (item) => {
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return
            }
            moveList(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    const [textAreaValue, setTextAreaValue] = useState("");

    // 填空题填写答案
    const handleTextAreaValueChange = useCallback(
        (e) => {
            setTextAreaValue(e.target.value)
        },
        [],
    );

    return (
        <div ref={drag(drop(dragRef))} className="supply-blank-text">
            <div className="radio-text-top">
                <Form.Item className="radio-text-center">
                    <span className="radio-text-num">{index + 1}、</span>
                    <Input placeholder={inputValue} value={element.name} onChange={setInput(index)} />
                </Form.Item>
                <Space className="radio-text-button">
                    <Button type="primary">拖动</Button>
                    <Button type="primary" onClick={handleClickDel}>删除</Button>
                </Space>
            </div>
            <div className="supply-blank-text-content">
                <TextArea
                    value={textAreaValue}
                    onChange={handleTextAreaValueChange}
                    placeholder="请填写答案"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    showCount maxLength={50}
                />
            </div>
        </div>
    )
}
