import { Drawer, Form, Radio, Input, Space, Button } from 'antd';
import { useState, useEffect } from 'react';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';

export default ({
                    drawerState,
                    setDrawerState,
                    currentModule,
                    updateModuleSettings,
                }) => {
    const [form] = Form.useForm();

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    // 新增模块
    const onFinish = async (values) => {
        updateModuleSettings({
            ...values,
            id: currentModule.id,
            data: JSON.stringify({jkjZhuantiAriticleId : values.jkjZhuantiAriticleId}),
        });
    };

    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            let data = JSON.parse(currentModule.data);
            let jkjZhuantiAriticleId = data?.jkjZhuantiAriticleId || undefined;
            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                jkjZhuantiAriticleId,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 7 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="NewsModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="NewsModuleForm"
                form={form}
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="displayTitle"
                    label="显示标题"
                    rules={[
                        { required: true, message: '显示标题不能为空' }
                    ]}
                >
                    <Input placeholder="请输入模块显示标题"/>
                </Form.Item>

                <Form.Item name="displayTitleEn" label="英文显示标题">
                    <Input placeholder="请输入模块英文显示标题"/>
                </Form.Item>

                <Form.Item
                    name="jkjZhuantiAriticleId"
                    label="平台专题ID"
                    rules={[
                        { required: true, message: '平台专题ID不能为空' },
                        { pattern: /^\d{6,10}$/, message: '平台专题ID不符合格式要求' },
                    ]}
                    help="请准确填写健康界平台创建的当前会议资讯专题文章ID，如 582452"
                >
                    <Input placeholder="平台专题ID"/>
                </Form.Item>
                <Form.Item
                    name="status"
                    label="导航状态"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>敬请期待</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="hiddenWeb"
                    label="Web端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="hiddenH5"
                    label="H5端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>


        </Drawer>
    );
};
