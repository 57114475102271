import React, { useEffect } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { localStorageSet } from '@/core/cookie';
import { post } from '@/core/request';
import './Login.less';

export default function Login() {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.modulesCode = '';
        post("/enterprise/staffs/login", values).then((res) => {
            if (res.status_code === 200) {
                if (localStorage.getItem('infos')) {
                    localStorage.removeItem('infos')
                }

                // 用户权限列表
                if (localStorage.getItem('roleList')) {
                    localStorage.removeItem('roleList')
                }

                localStorageSet('token', res.data.token);
                localStorageSet('infos', JSON.stringify(res.data.staffsEntity));

                localStorageSet('roleList', JSON.stringify(res.data.roleList));

                const search = window.location.search;
                const params = new URLSearchParams(search);
                const returnUrl = params.get('returnurl') || '';

                const url = !!returnUrl ? decodeURIComponent(returnUrl) : '/';
                navigate(url);
            }
        });
    };

    return (
        <div className="login-div">
            <h1>华媒康讯业务运营支撑系统</h1>
            <Card title="欢迎登录" className="login-content">
                <Form onFinish={onFinish} form={form} size="large">

                    <Form.Item name="userName" rules={[{ required: true, message: '手机号/邮箱地址不能为空' }]}>
                        <Input
                            allowClear
                            placeholder="请输入手机号/邮箱地址"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            rules={[{ required: true, message: '手机号/邮箱地址不能为空' }]}
                        />
                    </Form.Item>

                    <Form.Item name="pwd" rules={[{ required: true, message: '密码不能为空' }]}>
                        <Input.Password
                            allowClear
                            placeholder="请输入密码"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button block type="primary" htmlType="submit">登录</Button>
                    </Form.Item>

                </Form>

                <div className="remember-forget">
                    <Link to="/forgetpassword">忘记密码？</Link>
                </div>
            </Card>
            <p><span>&copy;2023 北京华媒康讯 保留所有权利。</span><span>内部系统，严禁他用。</span></p>
        </div >
    );
}
