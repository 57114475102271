import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, DatePicker, Button, Space, Select } from 'antd';
const { RangePicker } = DatePicker;

export default function UserGroupsFilterForm({
    handleFilterFinish, createdPhone
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (values) => {
        values.createdBeginAt = timePicker[0];
        values.createdEndAt = timePicker[1];
        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            createStaffPhone: undefined,
            timePicker: undefined,
            createdAtBegin: undefined,
            createdAtEnd: undefined,
            status: undefined
        }
        handleFilterFinish(values);
    };
    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="关键字" name="name">
                        <Input allowClear placeholder="用户分群名称关键字" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="创建人" name="createStaffPhone">
                        <Select allowClear placeholder="请选择创建人">
                            {createdPhone && createdPhone.map(item => {
                                return (
                                    <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="状态" name="status">
                        <Select allowClear placeholder="请选择状态">
                            <Select.Option value={0}>禁用</Select.Option>
                            <Select.Option value={1}>启用</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="创建时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">搜索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
