import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Button, Layout, Table, Modal, Space, message } from 'antd';
import InteractionChatFilterForm from './InteractionChatFilterForm';
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { fullPagerConfig } from '@/utils/property';
import { get, del } from '@/core/request';

const { Content } = Layout;

export default function InteractionChat() {
    const { state } = useLocation();

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    let navigate = useNavigate();
    const changePath = useCallback(
        () => {
            navigate(`/dms/lives`)
        },
        [navigate],
    );

    // 筛选数据
    const handleFilterFinish = useCallback(
        (value) => {
            setFilterObj(value);
        },
        [],
    );

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { end_time, start_time } = filterObj;

    const getData = () => {
        get(`/dms/live/interaction/chat`, {
            pageSize: pageSize,
            currPage: currPage,
            liveId: state.vhallLiveId,
            // liveId: 413724990,
            start_time: start_time,
            end_time: end_time
        }).then((res) => {
            if (res.status_code === 200) {
                const { content } = res.data;
                if (content) {
                    content.forEach(item => {
                        item.key = item.msgId;
                    });
                    setTableData(res.data);
                }
            }
        });
    };

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, start_time, end_time, state.vhallLiveId])


    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.recordTotal,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除聊天',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/live/interaction/batchDeleteMessage', {
                        webinar_id: state.vhallLiveId,
                        msg_id: row.msgId
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中聊天',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/live/interaction/batchDeleteMessage', {
                        webinar_id: state.vhallLiveId,
                        msg_id: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何聊天');
                }
            }
        });
    };

    // 导出数据
    const handleExportFile = () => {
        get(`/dms/live/interaction/chat/download`, {
            pageSize: pageSize,
            currPage: currPage,
            liveId: state.vhallLiveId,
            start_time: start_time,
            end_time: end_time
        }, { responseType: 'blob' }).then((res) => {
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                const fileName = "聊天统计数据.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    const columns = [
        { title: '昵称', dataIndex: 'name' },
        // { title: '用户Id', dataIndex: 'age', },
        // { title: '身份', dataIndex: 'address', },
        { title: '发送时间', dataIndex: 'dateTime', },
        {
            title: '消息内容', dataIndex: 'data',
            render: (operate, row) => (
                <Fragment>
                    {row.data.type === 'text' && <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{row.data.textContent}</span>}
                    {row.data.type === 'image' && row.data.imageUrls.map(item => {
                        return (
                            <img style={{ width: '100px', height: '50px' }} src={item} />
                        )
                    })}
                </Fragment>

            )
        },
        {
            title: '操作', dataIndex: 'operates',
            render: (operates, row) => (<span className="delete-style" onClick={deleteConfirm(row)}>删除</span>)
        },
    ];

    return (
        <Fragment>
            <div className="page-title"><span className="back" onClick={changePath}><LeftOutlined /></span>聊天统计
                <span className="page-title-tip">{state.name} [#{state.liveId}]</span></div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <InteractionChatFilterForm handleFilterFinish={handleFilterFinish} />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">

                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleExportFile}>导出数据</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="chat-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tabledata.content}
                        pagination={paginationProps}
                    />

                </div>
            </Content>
        </Fragment>
    )
}
