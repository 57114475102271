import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Layout, Form, DatePicker, Radio, Input, InputNumber, Button, Row, Col, message } from "antd";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import { SelectionFormItemLayout } from "../../../../utils/property";
import moment from "moment";
import { setCasescoringtopicsSetting } from "@services/Selection";
import { handleForm2Params, handleState2Form } from "./util";

import "./index.less";

const { Content } = Layout;
const { TextArea } = Input;

const ScoringSettings = () => {
    const params = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const local = useLocation();
    const [loading, setLoading] = useState(false);

    let initialValues = handleState2Form(local.state);
    initialValues.rankShowCounts = local?.state?.rankShowCounts === -1 ? -1 : initialValues.rankShowCounts;

    const rankForm = Form.useWatch("rankForm", form);
    const scoreMode = Form.useWatch("scoreMode", form);
    const rule = Form.useWatch("rule", form);

    const [caseNumber, setCaseNumber] = useState();

    useEffect(() => {
        if (scoreMode === 1) {
            form.setFieldValue("dimensionEnabled", 1);
        }
    }, [form, scoreMode]);

    const handleCancel = () => {
        navigate(-1);
    };

    // 案例数字改变
    const onChangeNumber = (e) => {
        setCaseNumber(e);
    };

    const handleSubmit = () => {
        form.validateFields().then((res) => {

            if ((res.scoreMode === 1 && !res.dimensions?.length) || res.dimensions?.some((item) => !item.name || !item.score || !item.desc)) {
                return message.error("请填写评分维度");
            }
            // 只有在有新增维度的情况下才要判断和是 100
            if (res.dimensions?.length) {
                let sum = 0;
                for (let i = 0; i < res.dimensions.length; i++) {
                    sum += +res.dimensions[i].score || 0;
                }
                if (sum !== 100) {
                    return message.error("评分维度总和应为 100！");
                }
            }

            if (res.rankForm === 2 && (!res.awards?.length || res.awards.some((item) => !item.rankBegin || !item.rankEnd || !item.awardName))) {
                return message.error("请填写完整奖项名称！");
            }

            // 上榜案例数
            if (res.rankShowCounts === 0 && !caseNumber) {
                return message.error("请填写榜单上榜案例数！");
            }


            let param = handleForm2Params(res);
            param.rankShowCounts = res.rankShowCounts === -1 ? -1 : caseNumber;

            setLoading(true);

            setCasescoringtopicsSetting({ ...param, id: params.tid }).then((res) => {
                setLoading(false);
                if (res.status_code === 200) {
                    message.success("设置成功！");
                    handleCancel();
                }
            });
        });
    };

    return (
        <>
            <div className="scoring-setting page-title">
                <span className="back" onClick={() => navigate(-1)}>
                    <LeftOutlined />
                </span>
                <div className="current-title">评分设置</div>
            </div>

            <Content className="main-content-box scoring-setting-content">
                <Form form={form} name="time" {...SelectionFormItemLayout} initialValues={initialValues}>
                    <h2>评分时间设置</h2>
                    <Form.Item label="评分起止时间" name="time" rules={[{ required: true, message: "请选择" }]}>
                        <DatePicker.RangePicker
                            style={{ width: "100%" }}
                            showTime={{
                                hideDisabledOptions: true,
                                defaultValue: [moment("00:00", "HH:mm"), moment("11:59", "HH:mm")],
                            }}
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>

                    <h2>用户端展示配置</h2>
                    <Form.Item label="评分提醒弹窗" name="rule" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={0}>不提醒</Radio>
                            <Radio value={1}>每次进入提醒</Radio>
                            <Radio value={2}>仅提醒一次</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="评分提醒内容" name="message" rules={[{ required: rule !== 0, message: "请输入" }]}>
                        <TextArea maxLength={200} showCount />
                    </Form.Item>

                    <Form.Item label="查看案例文件" name="caseFileViewable" rules={[{ required: true, message: "请选择" }]} extra="可查看：展示案例包含的文档，视频，图片；不可查看：只展示到案例封面图给评委线上打分">
                        <Radio.Group>
                            <Radio value={1}>可查看</Radio>
                            <Radio value={0}>不可查看</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="案例提报单位" name="unitAvailable" rules={[{ required: true, message: "请选择" }]} extra="选择匿名，评委看不到案例提报单位">
                        <Radio.Group>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>匿名</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="案例起评分" name="minScore" rules={[{ required: true, message: "请输入" }]}>
                        <InputNumber placeholder="请输入正整数" min={0} max={99} precision={0} />
                    </Form.Item>

                    <Form.Item label="案例可评小数分" name="decimalPlaceNum" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={0}>不保留</Radio>
                            <Radio value={1}>保留1位</Radio>
                            <Radio value={2}>保留2位</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="案例评语" name="commentEnabled" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={0}>不展示</Radio>
                            <Radio value={1}>必填</Radio>
                            <Radio value={2}>非必填</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="修改评分" name="scoreChangingTimes" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={0}>提交评分不可修改</Radio>
                            <Radio value={-1}>不限制修改次数</Radio>
                            <Radio value={1}>
                                <span className="flex-span">
                                    <span>限制修改</span>{" "}
                                    <Form.Item name="editLimit">
                                        <InputNumber style={{ width: 60 }} min={1} precision={0} />
                                    </Form.Item>
                                </span>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <h2>评分项设置</h2>
                    <Form.Item label="打分方式" name="scoreMode" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={1}>按评分维度分别打分</Radio>
                            <Radio value={2}>仅给出总分</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="评分维度" name="dimensionEnabled" rules={[{ required: scoreMode !== 2, message: "请选择" }]} extra="评分方式为按评分维度打分时，评分维度不可隐藏">
                        <Radio.Group>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0} disabled={scoreMode === 1}>
                                隐藏
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.List label="评分维度项" name="dimensions">
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                <Form.Item label="评分维度项" required={scoreMode !== 2} extra="评分方式为按评分维度打分时，请添加评分维度项，评分维度分值总和不得超过100分">
                                    <Button
                                        onClick={() => {
                                            if (fields.length >= 20) {
                                                return;
                                            }
                                            add();
                                        }}
                                    >
                                        新增维度
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                                {fields.map((field) => (
                                    <Row key={field.key}>
                                        <Col span={7}></Col>
                                        <Col span={17}>
                                            <div className="flex-span margin-span">
                                                <Form.Item noStyle name={[field.name, "name"]}>
                                                    <Input className="flex-span-1" placeholder="评分维度" maxLength={10} showCount />
                                                </Form.Item>
                                                <Form.Item noStyle name={[field.name, "desc"]}>
                                                    <Input className="flex-span-2" placeholder="评分维度说明" maxLength={200} showCount />
                                                </Form.Item>
                                                <Form.Item noStyle name={[field.name, "score"]}>
                                                    <InputNumber className="flex-span-3" placeholder="分值" min={1} max={100} precision={0} />
                                                </Form.Item>
                                                <DeleteOutlined onClick={() => remove(field.name)} />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>

                    <h2>单个案例系统总分设置</h2>
                    <Form.Item label="单个案例平均分计算公式" name="scoreAverageType" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={1}>单个案例全部评委总分/单个案例全部评委数量</Radio>
                            <Radio value={2}>去掉单个案例最高分和最低分后剩余评委总分/剩余评委数量</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="单个案例总分保留小数位" name="caseFinalScoreDecimalPlaceNum" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={0}>不保留</Radio>
                            <Radio value={1}>保留1位</Radio>
                            <Radio value={2}>保留2位</Radio>
                            <Radio value={3}>保留3位</Radio>
                            <Radio value={4}>保留4位</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <h2>奖项设置</h2>
                    <Form.Item label="榜单展示形式" name="rankForm" rules={[{ required: true, message: "请选择" }]} extra="按分数排名展示：案例排名以序号形式展示；按奖项名称分组展示：案例排名以奖项名称分组展示">
                        <Radio.Group>
                            <Radio value={1}>按分数排名展示</Radio>
                            <Radio value={2}>按奖项名称分组展示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="榜单上榜案例数" name="rankShowCounts" rules={[{ required: true, message: "请选择榜单上榜案例数" }]}>
                        <Radio.Group>
                            <Radio value={-1}>全部参数案例上榜</Radio>
                            <Radio value={0}>
                                <div>
                                    取前<InputNumber min={1} onChange={onChangeNumber} value={caseNumber}/>榜单上榜案例数
                                </div>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="同分案例排名向上进位" name="sameRankAddtionEnabled" rules={[{ required: true, message: "请选择" }]} extra="开启示例：1、1、2、3； 关闭示例：1、1、3、4；">
                        <Radio.Group>
                            <Radio value={true}>开启</Radio>
                            <Radio value={false}>关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {rankForm === 2 && (
                        <Form.List label="奖项名称" name="awards">
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Form.Item
                                        label="奖项名称"
                                        extra={
                                            <>
                                                <span>1. 设置奖项名称后，对外榜单公示时，排名序号会以对应奖项名称展示；</span>
                                                <br />
                                                <span>2. 为保证最后一名案例，能进入奖项排名中，设置最后增加的奖项排名时，设定的排名值要大于当前主题案例数</span>
                                            </>
                                        }
                                    >
                                        <Button onClick={() => add()}>新增奖项名称</Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                    {fields.map((field) => (
                                        <Row key={field.key}>
                                            <Col span={7}></Col>
                                            <Col span={17}>
                                                <div className="flex-span margin-span">
                                                    <span>分数排名：</span>
                                                    <Form.Item noStyle name={[field.name, "rankBegin"]}>
                                                        <InputNumber placeholder="输入排名" className="flex-span-3" min={0} precision={0} />
                                                    </Form.Item>
                                                    <span>至</span>
                                                    <Form.Item noStyle name={[field.name, "rankEnd"]}>
                                                        <InputNumber placeholder="输入排名" className="flex-span-3" min={0} precision={0} />
                                                    </Form.Item>
                                                    <span>奖项名称：</span>
                                                    <Form.Item noStyle name={[field.name, "awardName"]}>
                                                        <Input className="flex-span-1" />
                                                    </Form.Item>
                                                    <DeleteOutlined onClick={() => remove(field.name)} />
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    )}
                </Form>

                <div className="operation-buttons">
                    <Button onClick={handleCancel}>取消</Button>
                    <Button type="primary" onClick={handleSubmit} loading={loading}>
                        保存
                    </Button>
                </div>
            </Content>
        </>
    );
};

export default ScoringSettings;
