import React, { Fragment, useEffect, useState } from "react";
import { Button, Space, Row, Col, Modal } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined, FileTextOutlined } from "@ant-design/icons";
import QuestionItemCom from "./components/QuestionItemCom";
import { getStatisticalDataApi, getStatisticalOverviewApi } from "@services/Wj/Statistical";
import moment from "moment/moment";
import "./index.less";

const ChartsSurvey = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [allData, setAllData] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);

    const getAllData = async () => {
        let res = await getStatisticalOverviewApi({
            surveyId: location.state?.id || null,
        });
        if (res.status_code === 200) {
            setAllData(res?.data);
        }
    };

    const getData = async () => {
        let res = await getStatisticalDataApi({
            surveyId: location.state?.id || null,
        });

        if (res.status_code === 200) {
            setData(res?.data);
        }
    };

    useEffect(() => {
        console.log(location, params, "location");
        getAllData();
        getData();
    }, []);

    const goDetails = () => {
        navigate(`/wj/surveys/${location.state.id}/details`, {
            state: location.state,
        });
    };

    const goCostomReport = () => {
        navigate(`/wj/surveys/${location.state.id}/customreport`, {
            state: location.state,
        });
    };
    const handleOk = () => {
        gotoReport();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const gotoReport = () => {
        navigate(`/wj/surveys/${location.state.id}/report`, {
            state: location.state,
        });
    };

    return (
        <div className="survey_charts_wrap">
            <div className="page-title">
                <Link to="/wj/surveys" className="back">
                    <LeftOutlined />
                </Link>
                {location.state?.name || ""}
            </div>
            <div className="main_content">
                <div className="left">
                    <p className="active menu-pointer">
                        <LineChartOutlined /> &nbsp;&nbsp;统计图表
                    </p>
                    <p className="menu-pointer" onClick={goDetails}>
                        <ContainerOutlined />
                        &nbsp;&nbsp;数据详情
                    </p>
                    <p className="menu-pointer" onClick={goCostomReport}>
                        <FileTextOutlined />
                        &nbsp;&nbsp;调研报告
                    </p>
                </div>
                <div className="right">
                    <div className="head">
                        <h2>数据概况</h2>
                        <div>
                            <Row className="data_wrap">
                                <Col span={8}>
                                    <Space direction="vertical">
                                        <span>有效数据</span>
                                        <span className="font_blod">{allData?.effective || 0}</span>
                                    </Space>
                                </Col>
                                <Col span={8}>
                                    <Space direction="vertical">
                                        <span>今日有效数据</span>
                                        <span className="font_blod">{allData?.todayEffective || 0}</span>
                                    </Space>
                                </Col>
                                <Col span={8}>
                                    <Space direction="vertical">
                                        <span>平均完成时间</span>
                                        <p className="time_wrap">
                                            {moment.duration(allData?.avgDuration || 0, "seconds").hours() === 0 ? null : (
                                                <>
                                                    <span>{moment.duration(allData?.avgDuration || 0, "seconds").hours()}</span>时
                                                </>
                                            )}
                                            {moment.duration(allData?.avgDuration || 0, "seconds").minutes() === 0 ? null : (
                                                <>
                                                    <span>{moment.duration(allData?.avgDuration || 0, "seconds").minutes()}</span>分
                                                </>
                                            )}
                                            {moment.duration(allData?.avgDuration || 0, "seconds").seconds() === 0 ? (
                                                0
                                            ) : (
                                                <>
                                                    <span>{moment.duration(allData?.avgDuration || 0, "seconds").seconds()}</span>秒
                                                </>
                                            )}
                                        </p>
                                        {/* <span className='font_blod'>{allData?.avgDuration || 0}</span> */}
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="data_content">
                        <div>
                            <h2>数据详情</h2>
                            <Button size="small" className="data_content_btn" onClick={() => setIsModalOpen(true)}>
                                生成报告
                            </Button>
                            <Modal
                                title="生成报告提示"
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText={"我知道了"}
                                cancelButtonProps={{
                                    style: { display: "none" },
                                }}
                            >
                                <p>报告只支持展示单选、多选、判断、量表题型的数据</p>
                            </Modal>
                        </div>
                        <div className="data_content_main">
                            {data?.length
                                ? data.map((item, index) => {
                                        return <QuestionItemCom key={index} index={index} item={item}></QuestionItemCom>;
                                    })
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartsSurvey;
