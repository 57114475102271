import React, { Fragment, useState,useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Drawer, Form, Input, Tooltip, Space, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { drawerWidth } from '../../../../utils/property';
import copy from 'copy-to-clipboard';
import { getPullUrlApi } from "@services/Conference";
import config from '@config';
import { useLocation } from 'react-router-dom';

const drawerFormItemLayout = {
    labelCol:   { span: 6 },
    wrapperCol: { span: 18 }
};

const LiveReplay = ({liveUrlDrawer,setLiveUrlDrawer,liveId, liveName}) => {
    const location = useLocation();
    const [liveUrlForm] = Form.useForm();
    const [fms,setFms] = useState(undefined)
    const [rtmpUrlVal,setRtmpUrlVal] = useState(undefined)
    const [flvUrl,setFlvUrl] = useState(undefined)
    const [rtmpUrl,setRtmpUrl] = useState(undefined)
    const [hlsUrl,setHlsUrl] = useState(undefined)
    const closeLiveUrlDrawer=()=>{
        setLiveUrlDrawer(false);
    }
    const onCopyClick=(type)=>{
        switch (type) {
            case 'liveName':
                copy(`${liveName} ${fms}/${rtmpUrlVal}`)
                message.success('直播推流地址复制成功')
                break;
            case 'fms':
                copy(fms)
                message.success('复制 FMS URL 成功')
                break;
            case 'rtmpUrlVal':
                copy(rtmpUrlVal)
                message.success('复制播放路径/串码流成功')
                break;
            case 'flvUrl':
                copy(flvUrl)
                message.success('复制拉流地址成功')
                break;
            case 'rtmpUrl':
                copy(rtmpUrl)
                message.success('复制拉流地址(rtmp)成功')
                break;
            case 'hlsUrl':
                copy(hlsUrl)
                message.success('复制拉流地址(m3u8)成功')
                break;
            case 'copyUrl':
                copy(`${config.HUIYI_DOMAIN}/web/${sessionStorage.getItem('confSlug')}/lives/${liveId}/test`)
                message.success('复制测试地址成功')
                break;

            default:
                break;
        }

    }

    useEffect(() => {
        if(liveId&&liveUrlDrawer){
            getPullUrlApi({vid:liveId}).then(res=>{
                if(res.status_code==200){
                    console.log(res.data);
                    setFms(res.data.fms)
                    setRtmpUrlVal(res.data.rtmpUrlVal)
                    setFlvUrl(res.data.flvUrl)
                    setRtmpUrl(res.data.rtmpUrl)
                    setHlsUrl(res.data.hlsUrl)
                    liveUrlForm.setFieldsValue(res.data)
                }
            })
        }
    }, [liveUrlDrawer]);
    return (
        <Drawer
            title="推流/拉流地址"
            width={drawerWidth}
            open={liveUrlDrawer}
            onClose={closeLiveUrlDrawer}
            maskClosable={false}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeLiveUrlDrawer} >取消</Button>
                        <Button type="primary" form="uploadReplayForm" htmlType="submit" onClick={closeLiveUrlDrawer}>确定</Button>
                    </Space>
                </div>
            }
        >
            <Form form={liveUrlForm} layout='vertical'>
                <Form.Item label='直播推流地址' name='liveName'>
                    <div className="copy-live-name">
                        <div className="live-name">{`${liveName} ${fms}/${rtmpUrlVal}`}</div>
                        <Tooltip title="复制直播推流地址">
                            <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('liveName')}/>
                        </Tooltip>
                    </div>
                </Form.Item>

                <Form.Item label='FMS URL' name='fms'>
                    <Row>
                        <Col span={24}>
                        <Input.Group compact>
                        <Input
                            style={{
                                width: 'calc(100% - 40px)',
                            }}
                            readOnly
                            value={fms}
                        />
                        <Tooltip title="复制 FMS URL地址">
                            <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('fms')}/>
                        </Tooltip>
                    </Input.Group>
                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item label='播放路径/串码流' name='rtmpUrlVal'>

                    <Row>
                        <Col span={24}>
                            <Input.Group compact>
                                <Input
                                    style={{
                                        width: 'calc(100% - 40px)',
                                    }}
                                    readOnly
                                    value={rtmpUrlVal}
                                />
                                <Tooltip title=" 复制 播放路径/串码流地址">
                                    <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('rtmpUrlVal')}/>
                                </Tooltip>
                            </Input.Group>
                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item label='拉流地址' name='flvUrl'>
                    <Row>
                        <Col span={24}>
                            <Input.Group compact>
                                <Input
                                    style={{
                                        width: 'calc(100% - 40px)',
                                    }}
                                    readOnly
                                    value={flvUrl}
                                />
                                <Tooltip title=" 复制 拉流地址">
                                    <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('flvUrl')}/>
                                </Tooltip>
                            </Input.Group>
                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item label='拉流地址(rtmp)' name='rtmpUrl'>
                <Row>
                        <Col span={24}>
                            <Input.Group compact>
                                <Input
                                    style={{
                                        width: 'calc(100% - 40px)',
                                    }}
                                    readOnly
                                    value={rtmpUrl}
                                />
                                <Tooltip title=" 复制 拉流地址(rtmp)地址">
                                    <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('rtmpUrl')}/>
                                </Tooltip>
                            </Input.Group>
                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item label='拉流地址(m3u8)' name='hlsUrl'>
                <Row>
                        <Col span={24}>
                            <Input.Group compact>
                                <Input
                                    style={{
                                        width: 'calc(100% - 40px)',
                                    }}
                                    readOnly
                                    value={hlsUrl}
                                />
                                <Tooltip title=" 复制 拉流地址(m3u8)地址">
                                    <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('hlsUrl')}/>
                                </Tooltip>
                            </Input.Group>
                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item label='测试地址' name='hlsUrl'>
                <Row>
                        <Col span={24}>
                            <Input.Group compact>
                                <Input
                                    style={{
                                        width: 'calc(100% - 40px)',
                                    }}
                                    readOnly
                                    value={`${config.HUIYI_DOMAIN}/web/${sessionStorage.getItem('confSlug')}/lives/${liveId}/test`}
                                />
                                <Tooltip title=" 复制 测试地址">
                                    <Button icon={<CopyOutlined />} onClick={()=>onCopyClick('copyUrl')}/>
                                </Tooltip>
                            </Input.Group>
                        </Col>

                    </Row>
                </Form.Item>




            </Form>
        </Drawer>
    );
}

export default LiveReplay;
