import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { Button, Layout, Table, Modal, Space, message } from 'antd';
import ProductionLinesTopicsFilterForm from './ProductionLinesTopicsFilterForm';
import ProductionLinesTopicsAddForm from './ProductionLinesTopicsAddForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { get, put, del } from '@/core/request';

const { Content } = Layout;

export default function ProductionLinesTopics() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        if (value.cateIdArr) {
            value.cateId = value.cateIdArr[0];
            value.labelId = value.cateIdArr[1];
        }
        setFilterObj(value);
    };

    // 直播分类
    const [liveCatesId, setLiveCatesId] = useState([]);

    // 直播分类
    useEffect(() => {
        get('/dms/lives/categories/findCatesTmenu').then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.value = item.id;
                    item.label = item.name;
                    item.children.forEach(elem => {
                        elem.value = elem.id;
                        elem.label = elem.name;
                        delete elem.children;
                    })
                })
                setLiveCatesId(res.data);
            }

        })
    }, []);

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { cateId, labelId, liveId, name, orgId, status } = filterObj;

    const getData = () => {
        get(`/dms/production-lines/topics/queryPage`, {
            limit: pageSize,
            page: currPage,
            cateId: cateId,
            labelId: labelId,
            liveId: liveId,
            name: name,
            orgId: orgId,
            status: status
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [name, cateId, liveId, orgId, status, labelId, pageSize, currPage]);


    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        (row) => {
            return () => {
                drawer.current.show(row);
            }
        },
        [],
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除产品线专题',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/production-lines/topics/delete', {
                        ids: [row.id]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中产品线专题',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/production-lines/topics/delete', {
                        ids: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何产品线专题');
                }
            }
        });
    };

    // 操作区禁用  modal相关
    const stopConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用选中标签',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.status === 1) {
                        status = 0;
                    }
                    put('/dms/production-lines/topics/status', {
                        ids: [row.id],
                        status
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    });

                }
            });
        }
    }

    // 批量启用，批量禁用
    const handleDisable = (status) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用选中标签',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    put('/dms/production-lines/topics/status', {
                        ids: selectedRowKeys,
                        status
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                            setSelectedRowKeys([]);
                        }
                    });
                }
            });
        }
    };

    const columns = [
        { title: '专题ID', dataIndex: 'id', key: 'id' },
        { title: '专题名称', dataIndex: 'name', },
        { title: '产品线', dataIndex: 'productsName', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '分类标签', dataIndex: 'liveCatesTagName', },
        { title: '分类', dataIndex: 'liveCatesName', },
        { title: '专题描述', dataIndex: 'description', },
        {
            title: '状态', dataIndex: 'status',
            render: (operates) => (operates === 1 ? <span>正常</span> : <span>已禁用</span>)
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handleClickAdd(row)}>编辑</span>
                    <span className="operate-style" onClick={stopConfirm(row)}>{row.status === 1 ? '禁用' : '启用'}</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    // 点击编辑
    const handleFinishEdit = (value) => {
        put(`/dms/production-lines/topics/update`, value).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData()
            }
        })
    }

    return (
        <Fragment>
            <div className="page-title">产品线专题管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ProductionLinesTopicsFilterForm
                        handleFilterFinish={handleFilterFinish}
                        liveCatesId={liveCatesId}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">产品线专题列表</div>

                        <div className="table-toolbar">
                            <Space>
                                <Button type="default" onClick={handleDisable(1)}>批量启用</Button>
                                <Button type="default" onClick={handleDisable(0)}>批量禁用</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="production-table" rowSelection={rowSelection} columns={columns}
                        dataSource={tabledata.list} pagination={paginationProps} />

                </div>
            </Content>

            <ProductionLinesTopicsAddForm handleFinishEdit={handleFinishEdit} liveCatesId={liveCatesId} filterObj={filterObj} setTableData={setTableData} ref={drawer}></ProductionLinesTopicsAddForm>

        </Fragment>
    )
}
