import { get, put, post } from '@core/request';

/**邀请函接口服务**/

// 获取邀请函列表
export const getInvitationListApi = (params) => {
    return post('/invitation/boss/invitations/list', params);
};

// 新增邀请函
export const saveInvitationApi = (params) => {
    return post('/invitation/boss/invitations/save', params);
};

// 查询邀请函
export const getInvitationOneApi = (params) => {
    return get('/invitation/boss/invitations/getOne', params);
};
// 修改邀请函
export const updateInvitationOneApi = (params) => {
    return put('/invitation/boss/invitations/update', params);
};

// 删除邀请函
export const deleteInvitationApi = (params) => {
    return get('/invitation/boss/invitations/delete', params);
};

// 上传文件
export const getUserFieldsApi = (params,option) => {
    return get('/invitation/boss/invitationusers/getUserFields', params,option);
};

// 上传文件
export const fileApi = (params,option) => {
    return post('/invitation/file', params,option);
};

// 更改邀请函状态
export const invitationusersStatusApi = (params,option) => {
    return put('/invitation/boss/invitationusers/change-status', params,option);
};

// 设置电子邀请函
export const setupInvitationFile = data => put('/invitation/boss/invitations/setInvitationFile', data);
