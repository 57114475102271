// 医疗机构类别
export const orgTypeMap = {
  0: "综合医院",
  1: "专科医院",
  2: "中医医院",
  3: "诊所",
  4: "中西医结合医院",
  5: "民族医医院",
  6: "康复医院",
  7: "社区卫生服务中心(站)",
  8: "卫生院",
  9: "疗养院",
  10: "门诊部",
  11: "村卫生室(所)",
  12: "急救中心(站)",
  13: "独立新设医疗机构(10类)",
  14: "专科疾病防治院/所/站",
  15: "护理院(站)",
  16: "其他",
  17: "妇幼保健院/妇幼保健计划生育服务中心"
};

// 医疗机构性质
export const orgPropertyMap = {
  "公立医院": "公立医疗机构",
  "民营医院": "民营医疗机构"
}

// 医疗机构级别
export const hoslevelMap = {
  "A01-JT-B01": "三级甲等",
  "A01-JT-B02": "三级乙等",
  "A01-JT-B03": "三级丙等",
  "A01-JT-B04": "二级甲等",
  "A01-JT-B05": "二级乙等",
  "A01-JT-B06": "二级丙等",
  "A01-JT-B07": "一级",
  "A01-JT-B08": "一级以下",
  "A01-JT-B09": "三级未定等",
  "A01-JT-B10": "未定级"
}

