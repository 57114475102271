import React, { Fragment, useState, useEffect } from 'react';
import { Button, Layout, Table, Modal, Radio, Space, Form, message, Input } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { get, del } from '@/core/request';

const { Content } = Layout;

export default function PlayBack() {
    const { state } = useLocation();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    const [tabledata, setTableData] = useState([]);

    const getData = () => {
        get(`/dms/lives/queryPlaybackLive`, {
            liveId: state.liveId
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }
    // 渲染table数据
    useEffect(() => {
        get(`/dms/lives/queryPlaybackLive`, {
            liveId: state.liveId
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        });
    }, [state.liveId]);

    // 下载Modal相关
    const [downloadVisible, setDownloadVisible] = useState(false);
    const [showLinkVisible, setShowLinkVisible] = useState(false);
    const [radioValue, setRadioValue] = useState('360p');
    const [rowData, setRowData] = useState({});

    const onRadioChange = (e) => {
        setRadioValue(e.target.value)
    };

    const showModal = (row) => {
        return () => {
            setRowData(row)
            setDownloadVisible(true);
        }
    };

    // 下载
    const handledownloadOk = (row) => {
        get(`/dms/lives/transcodePlaybackLive`, {
            productId: state.productId,
            quality: radioValue,
            recordId: rowData.id
        }).then(res => {
            if (res.status_code === 200) {
                message.success('请稍等，后台正在处理。。。');
            }
        })
        setDownloadVisible(false);
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除回放',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/lives/deletePlaybackLive', {
                        recordId: rowData.id,
                        productId: state.productId
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    const handleCancel = () => {
        setDownloadVisible(false);
        setShowLinkVisible(false);
    };

    // 查看链接
    const showLinkModal = (row) => {
        return () => {
            setShowLinkVisible(true);
            setRowData(row);
        }
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        {
            title: '直播封面图', dataIndex: 'url',
            render: (operates) => (<img className="banner-img" src={operates} alt="直播封面图" />)
        },
        { title: '直播标题', dataIndex: 'name', },
        { title: '创建时间', dataIndex: 'createdAt', },
        { title: '视频时长', dataIndex: 'duration', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={showModal(row)}>下载</span>
                    {row.downLoad && row.downLoad.length !== 0 && <span className="operate-style" onClick={showLinkModal(row)}>查看链接</span>}
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/dms/lives" className="back"><LeftOutlined /></Link>回放管理<span className="page-title-tip">{state.name} [#{state.liveId}]</span></div>

            <Content className="main-content-box">

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">
                            回放列表</div>

                    </div>

                    <Table className="playback-table" rowSelection={rowSelection} columns={columns}
                        dataSource={tabledata} />

                </div>
            </Content>

            <Modal title="请选择分辨率" visible={downloadVisible} onOk={handledownloadOk} onCancel={handleCancel}>
                <Radio.Group value={radioValue} onChange={onRadioChange}>
                    <Space direction="vertical">
                        <Radio value={'360p'}>360p</Radio>
                        <Radio value={'480p'}>480p</Radio>
                        <Radio value={'720p'}>720p</Radio>
                    </Space>
                </Radio.Group>
            </Modal>

            {
                showLinkVisible && <Modal
                    title="链接"
                    closable={false}
                    visible={showLinkVisible}
                    footer={
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={handleCancel}>取消</Button>
                        </div>
                    }>
                    <Form>
                        {
                            rowData.downLoad && rowData.downLoad.map(item => {
                                return (
                                    <Form.Item
                                        key={item.quality}
                                        label={item.quality}
                                        name={item.quality}
                                        initialValue={item.url}
                                    >
                                        <Input />
                                    </Form.Item>
                                )
                            })
                        }
                    </Form>
                </Modal>
            }



        </Fragment>
    )
}
