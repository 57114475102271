import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Layout, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col, Tabs } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined, FileTextOutlined } from "@ant-design/icons";
import DataTab from "./DataTab";
import "./index.less";

const DetailsSurvey = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filObj, setFilObj] = useState({
        phone: "",
        durationBegin: "",
        durationEnd: "",
        submittedBegin: "",
        submittedEnd: "",
        channelId: null,
    });

    const goChart = () => {
        navigate(`/wj/surveys/${location.state.id}/charts`, { state: location.state });
    };
    const goCostomReport = () => {
        navigate(`/wj/surveys/${location.state.id}/customreport`, { state: location.state });
    };
    return (
        <div className="survey_charts_wrap">
            <div className="page-title">
                <Link to="/wj/surveys" className="back">
                    <LeftOutlined />
                </Link>
                {location.state.name}
            </div>
            <div className="main_content">
                <div className="left">
                    <p className="menu-pointer" onClick={goChart}>
                        <LineChartOutlined /> &nbsp;&nbsp;统计图表
                    </p>
                    <p className="active menu-pointer">
                        <ContainerOutlined />
                        &nbsp;&nbsp;数据详情
                    </p>
                    <p className="menu-pointer" onClick={goCostomReport}>
                        <FileTextOutlined />
                        &nbsp;&nbsp;调研报告
                    </p>
                </div>
                <div className="right">
                    <div className="details">
                        <DataTab filObj={filObj} setFilObj={setFilObj} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsSurvey;
