import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Typography, Divider, Tag } from 'antd';
import { SearchOutlined, CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { GetQueryString } from '@utils/utils';
import { getProjestsListApi, getProjestsGroupListApi, projectMarkAsTesting } from '@services/Selection'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { selectionActions } from '../store/selectionSlice';
import { useDispatch, useSelector } from 'react-redux';

const { Content } = Layout;
const { Text } = Typography;

const { setProjectFilter: setFilter, setProjectCursor: setCursor } = selectionActions;

const ProjectsList = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.selection.project.filter);
    const cursor = useSelector(state => state.selection.project.cursor);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0);

    const [projectsGroupList, setProjectsGroupList] = useState([]);

    const onFinish = values => {
        dispatch(setFilter({
            ...filter,
            ...values,
            page: 1
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1
        }));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize,
        }));
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const filterOption = (input, option) => {
        const { groupName } = option.props.data;
        return (
            groupName.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    };

    useEffect(() => {
        //获取评选项目组
        getProjestsGroupListApi()
            .then(res => {
                if (res && res.status_code === 200) {
                    setProjectsGroupList(res.data)
                }
            });
    }, []);

    // 医项目跳转携带参数
    useEffect(() => {
        const projectName = GetQueryString('projectName');
        if (projectName) {
            form.setFieldValue('name', unescape(projectName));
            let timer = setTimeout(() => {
                getData(unescape(projectName));
                clearTimeout(timer);
            }, 500);
        }
    }, []);

    const getData = async (projectName) => {
        try {
            setLoading(true);
            let params = {
                ...filter,
                limit: filter.pageSize,
            };
            // console.log(params);

            if (projectName) {
                params.name = projectName;
            }

            const res = await getProjestsListApi(params);
            if (res?.status_code === 200) {
                setData(res.data?.list);
                setTotal(res.data?.totalCount);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        form.setFieldsValue(filter);

        getData();
    }, [filter]);

    const copyClick = (mes) => {
        copy(mes);
        message.success('已复制到剪贴板');
    }

    const columns = [
        { title: '氚云编号', dataIndex: 'h3yunNo', width: 150 },
        {
            title: "评选项目ID",
            dataIndex: "id",
            width: 120,
            render: (data) => {
                return <span className="conferenctlist-copy-column-span">
                    {'YPX' + ('000000' + data).slice(-6)}
                    <span onClick={() => copyClick('YPX' + ('000000' + data).slice(-6))}><CopyOutlined /></span>
                </span>
            }
        },
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 250,
            render: (_, row) => (<>
                {row.name}
                {!!row.isTesting && <Tag color="#f50" style={{marginLeft: 4, padding: '0 4px'}}>TEST</Tag>}
            </>)
        },
        {
            title: '项目组',
            dataIndex: 'projectGroup',
            width: 150,
            render: (_, row) => {
                return projectsGroupList?.filter(item => item.id === row?.groupId)[0]?.groupName
            }
        },
        {
            title: '起止日期',
            dataIndex: 'data',
            width: 200,
            render: (_, row) => {
                return `${moment(row.startedAt).format('YYYY-MM-DD')} 至 ${moment(row.endedAt).format('YYYY-MM-DD')}`
            }
        },
        { title: '创建时间', dataIndex: 'createdAt', width: 150 },
        { title: '创建人', dataIndex: 'createdName', width: 150 },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 260,
            render: (_, record) => {
                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoTopics(record)}
                        >
                            申报管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoVoteSettings(record)}
                        >
                            投票管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoScoreStages(record)}
                        >
                            评分管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoEdit(record)}
                        >
                            编辑项目
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => markAsTesting(record)}
                        >
                            {!!record.isTesting ? '标记为正式项目' : '标记为测试项目'}
                        </Button>
                    </span>
                )
            },
        },
    ];

    const markAsTesting = record => {
        Modal.confirm({
            title: '标记为测试项目',
            icon: <ExclamationCircleOutlined />,
            content: '医疗决策人数据统计等数据指标将排除标记为测试的评选项目，确定标记为测试项目？',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                projectMarkAsTesting({
                    id: record.id,
                    isTesting: !!record.isTesting ? 0 : 1,
                }).then(res => {
                    if (res.status_code === 200) {
                        const msg = !!record.isTesting ? '标记为正式项目成功' : '标记为测试项目成功';
                        message.success(msg);

                        getData();
                    }
                });
            }
        });

    };

    const gotoTopics = record => {
        dispatch(setCursor(record.id));
        navigate(`/selection/projects/${record.id}/topics`, { state: { projectName: record.name } });
    };

    const gotoVoteSettings = record => {
        dispatch(setCursor(record.id));
        navigate(`/selection/projects/${record.id}/vote/topics`)
    };

    const gotoScoreStages = record => {
        dispatch(setCursor(record.id));
        navigate(`/selection/projects/${record.id}/score-stages`, { state: { projectName: record.name } });
    };

    const gotoEdit = record => {
        dispatch(setCursor(record.id));
        navigate(`/selection/projects/${record.id}`);
    };

    const gotoLaborFee = record => {
        dispatch(setCursor(record.id));
        navigate(`/selection/projects/${record.id}/labour-expenses`,{state:{code:'YPX',projectId:record.id}});
    };

    return (
        <>
            <div className="page-title">
                <span className="current-title">评选项目管理</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        labelCol={{ md: { span: 7 } }}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="评选项目名称" name="name" >
                                    <Input allowClear placeholder="评选项目名称关键字" autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="评选项目组" name="groupId">
                                    <Select
                                        placeholder='请选择评选项目组'
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        allowClear
                                    >
                                        {projectsGroupList && projectsGroupList.map((item) => (
                                            <Select.Option key={item.id} value={item.id} data={item}>
                                                {item.groupName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="正式/测试" name="isTesting">
                                    <Select placeholder="选择是否测试项目" allowClear>
                                        <Select.Option value={0}>正式项目</Select.Option>
                                        <Select.Option value={1}>测试项目</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="氚云编号" name="h3yunNo" >
                                    <Input allowClear placeholder="氚云项目编号" autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">项目列表</div>
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={() => navigate(`/selection/projects/add`)}>新增评选项目</Button>
                            </Space>
                        </div>
                    </div>

                    <Table
                        size="small"
                        className="projects-table"
                        rowKey={item => item.id}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />

                </div>
            </Content >

        </>



    );
}

export default ProjectsList;
