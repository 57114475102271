import React, { useState, useEffect } from "react";

import {
    getSelectTreeList,
    getProjectLeaderList,
    getEnterpriseList,
    getExpertList,
} from "@services/MedConnect";


/**
 * 获取疾病列表
 * @params type project_disease
 * @return List
 */
export const useProjectDiseaseList = () => {
    const [diseaseList, setDiseaseList] = useState([]);

    const getList = async() => {
        const res = await getSelectTreeList({
            type: 'project_disease',
        });
        if (res && res.success) {
            // 处理只能选择最后一个节点
            res.data && res.data.length > 0 && res.data.map((item) => {
                if (item.children && item.children.length > 0) {
                    item.selectable = false;

                    item.children = item.children.map((sub) => {
                        if (sub.children && sub.children.length > 0) {
                            sub.selectable = false;
                        }
                        return sub;
                    })
                    return item;
                }
            })
            setDiseaseList(res.data);
        }
    };
    useEffect(() => {
        getList();
    }, []);

    return { diseaseList };
};

/**
 * 获取项目负责人
 * @params type:
 *          project_admin   全量数据负责人
 *          qa_allocate   专家分配检索
 *          project_user   项目人员检索
 *          audit_pass_label   审核通过标签
 *          audit_refuse_label   审核拒绝标签
 *          projectId:项目id
 * @return List
 */
export const useProjectDictionaryList = (type, projectId) => {
    const [dictionaryList, setDictionaryList] = useState([]);

    const getList = async() => {
        const res = await getProjectLeaderList({
            type,
            projectId,
        });
        if (res && res.success) {
            setDictionaryList(res.data);
        }
    };
    useEffect(() => {
        getList();
    }, []);

    return { dictionaryList };
};

/**
 * 获取结构企业
 * @params type project_organize
 * @return List
 */
export const useEnterpriseList = (keyword) => {
    const [enterpriseList, setEnterpriseList] = useState([]);

    const getList = async() => {
        const res = await getEnterpriseList({
            type: "project_organize",
            keyword,
        });
        if (res && res.success) {
            setEnterpriseList(res.data);
        }
    };
    useEffect(() => {
        getList();
    }, [keyword]);

    return { enterpriseList };
};

/**
 * 获取专家列表
 * @params type qa_allocate
 * @return List
 */
export const useExpertList = (projectId) => {
    const [expertList, setExpertList] = useState([]);

    const getList = async() => {
        const res = await getExpertList({
            type: "qa_allocate",
            projectId,
        });
        if (res && res.success) {
            setExpertList(res.data);
        }
    };
    useEffect(() => {
        getList();
    }, []);

    return { expertList };
};
