import React, {Fragment, useState, useCallback, useEffect, useRef} from "react";
import {
    Button,
    Layout,
    Table,
    Modal,
    Space,
    message,
    Row,
    Form,
    Upload,
    Radio,
    Tabs,
    Descriptions,
    Image,
    Select,
    DatePicker,
    Input
} from "antd";
import {fullPagerConfig} from "@/utils/property";
import {get, del, post, put} from "@/core/request";
import "./CheckOrgContractsSave.less"
import {useLocation, useNavigate} from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import {getProductApi,getClassPackageApi} from '@services/HealthClass';
export default function CheckOrgContractsSave() {

    const {Content} = Layout;
    let navigate = useNavigate();
    const [value, setValue] = useState(0);
    const {RangePicker} = DatePicker;
    const { state } = useLocation();
    const [fileList,setfileList]=useState([]);
    const [imgFile,setImgFile]=useState(null);
    const [fileUrl,setFileUrl]=useState(null);
    const [startedAt,setstartedAt]=useState(null);
    const [endedAt,setendedAt]=useState(null);
    const [inputValue,setInputValue]=useState(null);
    const [productOpinion, setProductOpinion] = useState([]);
    const [product, setProduct] = useState(56);
    const [people, setPeople] = useState(undefined);
    const [packages, setPackages] = useState(undefined);
    const [packageRadio,setPackageRadio] = useState(0);
    const [packageSelect, setPackageSelect] =useState([])
    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const onRadio = (e) => {
        setValue(e.target.value);
    };

    const onProduct =(e) => {
        if(e.target.value === 56){
            setPeople(null);
        }
        setProduct(e.target.value);
    }

    const packagesSelect =(value)=>{
        let final=packageSelect
        final.push(value)
        let check = packages.filter(item=>item.id!==0)
        console.log(final.length===check.length);
        if(value===0){
            setPackageSelect([0])
        }else if(packageSelect.includes(0)){
            setPackageSelect([0])
        }
        else if(final.length===check.length){
            setPackageSelect([0])
        }
        else{
            setPackageSelect([...final])
        }
    };

    const delPackage=(e)=>{
        setPackageSelect([...e])
    };

    const onChangeInput=(value)=>{
        setInputValue(value.target.value)
    }

    const onChangeDate = (value, dateString) => {
        setstartedAt(dateString[0]);
        setendedAt(dateString[1]);
    };

    const propsfile = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest:info=>{
            const reg = /\.(pdf|doc|docx)$/i
            var isJpgOrPng = info.file?.name.search(reg)
            const isLt5M = info.file?.size / 1024 / 1024 < 30;
            if (isJpgOrPng === -1) {
                message.error('上传文件格式有误，文件只能是pdf、word格式！');
                return;
            }
            if (!isLt5M) {
                message.error('文件大小不能超过30M！');
                return;
            }
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 8);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/healthclass/file", formData, option).then((res) => {
                if (!res) {
                    message.error('上传附件失败！！')
                    return;
                }
                if (res.status_code === 200) {
                    setFileUrl(res.data);
                    console.log(res.data);
                }
            })
        }

    };

    const propsImgfile = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        fileList,
        customRequest:info=>{
            const reg = /\.(jpg|jpeg|png)$/i
            var isJpgOrPng = info.file?.name.search(reg)
            const isLt5M = info.file?.size / 1024 / 1024 < 5;
            if (isJpgOrPng === -1) {
                message.error('上传文件格式有误，文件只能是jpg、jpeg、png格式！');
                document.getElementsByClassName("ant-upload-list-item-name");
                setfileList([])
                console.log()
                return ;
            }
            if (!isLt5M) {
                message.error('文件大小不能超过5M！');
                setfileList([])
                return false;
            }
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 8);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/healthclass/file", formData, option).then((res) => {
                if (!res) {
                    message.error('上传附件失败！！')
                    setfileList([])
                    return false;
                }
                if (res.status_code === 200) {
                    setImgFile(res.data)
                    setfileList([info.file])
                    console.log(res.data);
                }
            })

        }

    };

    const onCancel=()=>{
        navigate('/healthclass/CheckOrganizations');
    }

    const submit=()=>{
        let check
        if (fileUrl==null){
            message.warning("请上传合同附件！！")
            return
        }
        if (startedAt==null){
            message.warning("请填写合同开始时间！！")
            return
        }
        if (endedAt==null){
            message.warning("请填写合同结束时间！！")
            return
        }
        if (imgFile==null){
            message.warning("请上传OA凭证！！")
            return
        }
        // if (inputValue==null){
        //     message.warning("请填写赞助商！！")
        //     return
        // }
        if(product!==56&&people==null){
            message.warning("请选择产品版本的成员人数")
            return
        }
        if(packageRadio!==0&&packageSelect.length===0){
            message.warning("请选择课程授权包")
            return
        }
        if (packageSelect[0]===0) {
            check = packages.filter(item=>item.id!==0)
            check=check.map(item=>item.id)
        }
        post("/healthclass/boss/orgContracts/save",{
            type:1,
            orgCode:state.code,
            attachmentUrl:fileUrl,
            startedAt:startedAt,
            endedAt:endedAt,
            courseAuthorized:value,
            oaCertificate:imgFile,
            sponsor:inputValue,
            versionId:product===56?56:people,
            groupIds:packageRadio===0?[]:packageSelect[0]===0&&check?check:packageSelect
        }).then((res)=>{
            if (res.status_code==200){
                message.success(res.message);
                navigate('/healthclass/CheckOrganizations');
            }
            }
        )
    }

    useEffect(() => {
        getProductApi().then(res=>{
            if (res.status_code==200){
                setProductOpinion(res.data[1].childList);
            }
        })
        getClassPackageApi().then(res=>{
            if (res.status_code==200){
                res.data.unshift({id:0,name:'全部'})
                setPackages(res.data);
            }
        })
    },[])
    return (
        <div>
            <Fragment>
                <div className="page-title">设置学苑信息</div>
                <Content className="main-content-box">
                    <div className="content"><span className="icon">学苑名称</span><input className="checkInput" disabled="disabled"  value={state.name || ""}/></div>
                    <div className="content"><span className="icon">学苑类型</span>
                        <button className="buttonblue">正式</button>
                    </div>
                    <div className="content"><span className="icon">合同有效期</span>
                        <span className="space" >
                            <Space direction="vertical" size={20}>
                            <RangePicker showTime={{format: 'HH:mm',}} format="YYYY-MM-DD HH:mm" onChange={onChangeDate} onOk={onOk}/>
                        </Space>
                        </span>
                    </div>
                    <div className="content">
                        <span className="icon">产品版本</span>
                        <span className="radioClass">
                            <Radio.Group onChange={onProduct}  value={product} className="product">
                            <Space direction="vertical">
                            <Radio value={56}><span>基础版</span><span className="basic-people">{'<=30人'}</span></Radio>
                            <Radio value={0} className="product-choice"
                            ><div>专业版</div>

                            </Radio>
                            </Space>
                        </Radio.Group>
                        </span>
                        {product===0&&
                            <div className="people-choice">
                                <span>成员人数：</span>
                                <Select
                                    className="people-select"
                                    placeholder='请选择'
                                    style={{
                                        width: 120,
                                    }}
                                    allowClear
                                    onSelect={(value)=>{setPeople(value);}}
                                    fieldNames={{label:'name',value:'id'}}
                                    options={productOpinion}
                                />
                            </div>
                        }
                    </div>
                    <div className="content">
                        <span className="icon">课程授权</span>
                        <span className="radioClass">
                            <Radio.Group onChange={onRadio} value={value}>
                            <Radio value={0}>无授权</Radio>
                            <Radio value={1}>授权健康界免费课程</Radio>
                        </Radio.Group>
                        </span>

                    </div>

                    <div className="content">
                        <span className="icon">课程包授权</span>
                        <span className="radioClass">
                            <Radio.Group onChange={(e)=> {setPackageRadio(e.target.value);}} value={packageRadio} className="class-authorization">
                            <Space direction="vertical">
                            <Radio value={0}><span>无授权</span></Radio>
                            <Radio value={1} className="product-choice"
                            ><div>授权课程包</div>

                            </Radio>
                            </Space>
                        </Radio.Group>
                        </span>
                        {packageRadio===1&&
                            <div className="people-choice">
                                <span>选择课程包授权：</span>
                                <Select
                                    mode="multiple"
                                    placeholder='请选择'
                                    value={packageSelect}
                                    style={{
                                        width: 200,
                                    }}
                                    onSelect={packagesSelect}
                                    onChange={delPackage}
                                    fieldNames={{label:'name',value:'id'}}
                                    options={packages}
                                />
                            </div>
                        }
                    </div>

                    <div className="content">
                        <span className="icon">电子合同附件</span>
                        <span className="space">
                            <Upload {...propsfile} maxCount={1}>
                                <Button icon={<UploadOutlined />}>上传附件</Button>
                            </Upload>
                        </span>
                    </div>
                    <div className="content">
                        <span className="iconVery"> 赞助商</span>
                        <Input type="text" onChange={onChangeInput}/>
                    </div>
                    <div className="content">
                        <span className="icon">OA凭证</span>
                        <div className="image">
                            <Image width={300} height={200} src={imgFile} />
                        </div>
                        <span className="uploadButton">
                            <Upload {...propsImgfile} maxCount={1}>
                                <Button icon={<UploadOutlined />}>上传图片</Button>
                            </Upload>
                        </span>
                    </div>
                    <div className="content">
                        <button className="checkbutton" onClick={()=>submit()}>提交</button>
                        <button className="checkbuttonb" onClick={()=>onCancel()}>取消</button>
                    </div>
                </Content>
            </Fragment>
        </div>

    )
}
