import React, { Fragment, useState, useEffect } from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {LeftOutlined, SearchOutlined} from "@ant-design/icons";
import {Content} from "antd/lib/layout/layout";
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tooltip, TreeSelect, Typography} from "antd";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { useSelector } from 'react-redux';
import { questionSourceList, approveStateList} from "../constants";
import {useProjectDiseaseList} from '@hooks/MedConnect';
import {getQaList} from '@services/MedConnect';
import DistributeModal from "./DistributeModal";
import ApproveModal from "./ApproveModal";
import './MedConnectQAList.less';

const { Text } = Typography;

export default function MedConnectQAList() {
    // 获取疾病列表
    const {diseaseList} = useProjectDiseaseList();
    const navigate = useNavigate();

    const [params] = useSearchParams();
    const contentText = params.getAll("content")[0];

    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const [form] = Form.useForm();

    const projectName = useSelector((state) => state.medconnect.projectName);
    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});

    const [isOpen, setIsOpen] = useState(false); // 人工分配弹窗
    const [distributeIds, setDistributeIds] = useState([]);
    const [isApprove, setIsApprove] = useState(false); // 审核弹窗
    const [currentData, setCurrentData] = useState({});

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [audioOpen, setAudioOpen] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');

    const getAqList = async () => {
        try {
            setLoading(true);
            getQaList({
                ...filter,
                projectId: id,
            }).then((res) => {
                if (res && res.success) {
                    setTableData(res.data);
                    setTotal(res.totalCount);
                }
            });
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        }
    }

    const handleFilterFinish = (value) => {
        setFilter({
            ...filter,
            content: value.content,
            diseaseType: value.diseaseType,
            questionSource: value.questionSource,
            state: value.state,
            distributeState: value.distributeState,
            pageIndex: 1,
        })
    }

    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            pageIndex: 1,
        });
    };

    // 刷新
    const onRefresh = () => getAqList();

    // 查看
    const handleReview = (record) => {
        navigate(`/medconnect/projects/${id}/qa-list/${record.id}/detail`);
    }

    // 人工分配
    const handleDistribute = (id) => {
        setDistributeIds([id]);
        setIsOpen(true);
    }

    // 确认分配
    const handleDistributeOk = () => {
        setSelectedRowKeys([]);
        closeDistributeModal();
        onRefresh();
    }

    // 关闭人工分配弹窗
    const closeDistributeModal = () => {
        setIsOpen(false);
    }

    // 批量人工分配
    const toDistribute = () => {
        if (selectedRowKeys.length > 0) {
            setDistributeIds(selectedRowKeys);
            setIsOpen(true);
        } else {
            message.warn("请勾选需要分配的问答")
        }
    };

    // 审核
    const checkApprove = (record) => {
        setCurrentData(record);
        setIsApprove(true);
    }

    // 确认审核
    const handleApproveOk = () => {
        closeApproveModal();
        onRefresh();
    }

    // 关闭审核弹窗
    const closeApproveModal = () => {
        setIsApprove(false);
    }

    const handleChangeDisease = (v) => {
        if (v === undefined) {
            form.setFieldValue('diseaseType', -1);
        }
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    // 点击播放语音
    const handlePlayAudio = (record) => {
        setAudioUrl(record.audioOssUrl);
        setAudioOpen(true);
    }

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.state !== 2,
        })
    };

    const columns = [
        {
            title: '问答',
            dataIndex: 'aqConfig',
            width: 300,
            render: (text, record) => {
                return (
                    <div style={{width:'440px'}}>
                        <Tooltip title={<span>{record.questionContent}</span>}>
                            <div className="quest-title">问题：{record.questionContent}</div>
                        </Tooltip>
                        <div className="answer-text">
                            <div className="answer-label">回答：</div>
                            <div className="answer-content" dangerouslySetInnerHTML={{__html: record.answerContent}}></div>
                        </div>
                    </div>
                )
            }
        },
        { title: '疾病类型', dataIndex: 'diseaseTypeDesc', },
        {
            title: '语音识别',
            dataIndex: 'audioOssUrl',
            width: 80,
            render: (text, record) => {
                if (text) {
                    return <Button type='link' onClick={() => handlePlayAudio(record)}>播放</Button>
                } else {
                    return <div>-</div>
                }
            }
        },
        {
            title: '提交用户',
            dataIndex: 'createUserName',
            width: 150,
            render: (text, record) => {
                return (
                    <Tooltip title={<span>{record.createUserName} {record.createdBy} <br /> {record.post}</span>}>
                        <div>{text}</div>
                        <div>{record.createdAt}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '问答状态',
            dataIndex: 'stateDesc',
            width: 100,
            render: (_, record) => <Text type={null}>{_}</Text>
        },
        {
            title: '审核人',
            dataIndex: 'auditedUserName',
            width: 150,
            render: (text, record) => {
                return (
                    <Tooltip title={<span>{record.auditedUserName} {record.auditedBy} <br /> {record.post}</span>}>
                        <div>{text}</div>
                        <div>{record.auditedAt}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            fixed: 'right',
            width: 200,
            render: (_, record) => {

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleReview(record)}
                        >
                            查看
                        </Button>
                        {record.state !== 3 ? <Button
                            size="small"
                            type="link"
                            onClick={() => handleDistribute(record.id)}
                        >
                            人工分配
                        </Button> : null}
                        {record.state !== 3 ? <Button
                            size="small"
                            type="link"
                            onClick={() => checkApprove(record)}
                        >
                            审核
                        </Button> : null}
                    </span>
                );
            }
        },
    ];

    useEffect(() => {
        if (contentText) {
            form.setFieldsValue({
                content: contentText,
            })
            setFilter({
                ...filter,
                content: contentText,
            })
        }
    }, [contentText])

    useEffect(() => {
        getAqList();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">回答管理</span>
                <span className="current-subtitle">{projectName}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="问答标题" name="content">
                                    <Input allowClear placeholder="输入问答标题" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="疾病分类" name="diseaseType">
                                    <TreeSelect
                                        showSearch
                                        placeholder='请选择疾病分类'
                                        allowClear
                                        multiple
                                        treeNodeFilterProp="title"
                                        onChange={handleChangeDisease}
                                        treeData={diseaseList}
                                        maxTagCount={1}
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="问题来源" name="questionSource">
                                    <Select allowClear placeholder="全部">
                                        {questionSourceList && questionSourceList.map(item => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="问答状态" name="state">
                                    <Select allowClear placeholder="全部">
                                        {approveStateList && approveStateList.map(item => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/*<Col {...filterFormGridLayout}>*/}
                            {/*    <Form.Item label="分配状态" name="distributeState">*/}
                            {/*        <Select allowClear placeholder="全部">*/}
                            {/*            {stageList && stageList.map(item => {*/}
                            {/*                return (*/}
                            {/*                    <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>*/}
                            {/*                )*/}
                            {/*            })}*/}
                            {/*        </Select>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={toDistribute}>批量人工分配</Button>
                        </div>

                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        onClick={onRefresh}
                                        icon={<>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                                </svg>
                                            </span>
                                        </>}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        rowSelection={rowSelection}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>

                {/* 语音播放弹窗 */}
                <Modal
                    open={audioOpen}
                    title={'语音播放'}
                    centered
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setAudioOpen(false);
                    }}
                >
                    <div>
                        <audio
                            style={{width: '100%'}}
                            controls
                            src={audioUrl}>
                        </audio>
                    </div>
                </Modal>
            </Content>

            {/* 人工分配弹窗 */}
            <DistributeModal distributeIds={distributeIds} isOpen={isOpen} handleClose={closeDistributeModal} handleOk={handleDistributeOk} />
            {/* 审核弹窗 */}
            <ApproveModal record={currentData} isOpen={isApprove} handleClose={closeApproveModal} handleOk={handleApproveOk} />

        </Fragment>
    )
}

