import React, { Fragment, useMemo, useState, useRef, useEffect } from "react";
import { Layout, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import { LeftOutlined, EyeOutlined } from "@ant-design/icons";
import ReactQuill, { Quill } from "react-quill";
import { uploadFile } from "@services/Wj";
import FinalPreview from "./FinalPreview";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import "./index.less";
import config from "@config";
import { saveSettingApi, querySettingApi } from "@services/Wj/Question";
Quill.register("modules/imageResize", ImageResize);
const FinalCustom = () => {
    const { Content } = Layout;
    const quillRef = useRef(null);
    const location = useLocation();
    const option = { headers: { "Content-Type": "multipart/form-data" } };
    const [finalValue, setFinalValue] = useState(undefined);
    const [preview, setPreview] = useState(false);

    const getFileUrl = (url) => {
        if (url && url.includes("https")) {
            return url;
        }
        if (url && !url.includes("https")) {
            return config.OSS_PREFIX + `/${url}`;
        }
        return null;
    };

    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append("path", `skins/upload/`);
            const hide = message.loading("上传中...", 0);
            uploadFile(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = quillRef?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                console.log(url);
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                        "image",
                    ],
                ],
                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
            imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
            },
        };
    }, []);

    const fnalChange = (v) => {
        setFinalValue(v);
    };

    const closeFinalPreview = () => {
        setPreview(false);
    };

    const settingStatus = () => {
        querySettingApi({ id: location.state.id, type: location.state.route === "newCreate" ? 1 : 2 }).then((res) => {
            if (res.status_code === 200) {
                setFinalValue(res.data.endPage);
            }
        });
    };

    const onSave = () => {
        if (location.state.route === "newCreate") {
            saveSettingApi({ endPage: quillRef.current.value, surveyId: location.state.id, type: 1 }).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            saveSettingApi({ endPage: quillRef.current.value, surveyId: location.state.id, type: 2 }).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                }
            });
        }
    };

    const onPreview = () => {
        if (location.state.route === "newCreate") {
            saveSettingApi({ endPage: quillRef.current.value, surveyId: location.state.id, type: 1 }).then((res) => {
                if (res.status_code === 200) {
                    setPreview(true);
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            saveSettingApi({ endPage: quillRef.current.value, surveyId: location.state.id, type: 2 }).then((res) => {
                if (res.status_code === 200) {
                    setPreview(true);
                }
            });
        }
    };

    useEffect(() => {
        querySettingApi({ id: location.state.id, type: location.state.route === "newCreate" ? 1 : 2 }).then((res) => {
            if (res.status_code === 200) {
                setFinalValue(res.data.endPage);
            }
        });
    }, []);
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="setting-title">
                            <div className="finalcustom-head">
                                <div style={{ cursor: "pointer" }} className="finalcustom-goback" onClick={() => window.history.back()}>
                                    <LeftOutlined />
                                    返回
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                    <Button type="primary" style={{ marginRight: 10 }} onClick={onSave}>
                                        保存
                                    </Button>
                                    <Button icon={<EyeOutlined />} type="primary" ghost onClick={onPreview}>
                                        预览
                                    </Button>
                                </div>
                            </div>
                            <div className="finalcustom-editorbox">
                                <ReactQuill theme="snow" ref={quillRef} modules={modules} value={finalValue} onChange={fnalChange} className="finalcustom-editor" />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <FinalPreview preview={preview} closeFinalPreview={closeFinalPreview} />
        </Fragment>
    );
};

export default FinalCustom;
