import axios from 'axios';
import { message } from 'antd';
import { localStorageGet } from './cookie.js';
// import history from './history';

import config from '../config';

const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
    timeout: 2 * 60 * 1000,
    baseURL: config.API_PREFIX   //根据自己配置的反向代理去设置不同环境的baeUrl
    // baseURL: 'http://192.168.1.65:9007' // 专家库
    // baseURL: 'http://192.168.1.65:9002'  // 医会议
});

// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/json'

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
        if (config.method === 'delete') {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        let configBool = config.url === '/enterprise/staffs/login' || config.url === '/enterprise/staffs/send-sms';
        if (!configBool) {
            let token;
            token = localStorageGet('token');
            config.headers.Authorization = token;
            if (!token) {
                window.location.href = '/login';
            }
        }
        //将token放到请求头发给服务器
        return config;
    }
)

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
        if (!response.config.url.includes('download')) {
            if (response.data.status_code === 200) {
                return Promise.resolve(response.data);
            } else if (response.data.status_code === 400) {
                message.error(response.data.message);
                return Promise.reject(response.data.message);
            } else if (response.data.status_code === 401) {
                const returnUrl = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
                window.location.href = `/login?returnurl=${returnUrl}`;
                message.warning(response.data.message);
                return Promise.reject(response.data.message);
            } else if (response.data.status_code === 403) {
                window.location.href = '/permission';
                return Promise.reject(response.data.message);
            } else if (response.data.status_code === 422) {
                return Promise.resolve(response.data);
            } else if (response.data.code === 'A') {
                return Promise.reject(response.data);
            } else if(response.config.url.includes('ucenter.cn-healthcare.com')){
                return Promise.resolve(response.data);
            }else {
                message.error(response.data.message);
                return Promise.reject(response.data.message);
            }
        } else {
            return Promise.resolve(response.data)
        }
    },
    error => {
        message.error('请求超时, 请刷新重试');
        return Promise.reject('请求超时, 请刷新重试');
    }
);

/* 统一封装get请求 */
export const get = async (url, params, options) => {
    let urlStr = url + "?";
    for (let i in params) {
        if (params[i] !== undefined) {
            urlStr += `${i}=${params[i]}&`
        }
    }
    urlStr = urlStr.slice(0, urlStr.length - 1);
    // console.log(urlStr,'urlStr');
    try {
        let result = await instance.get(urlStr, options);
        return result;
    } catch (err) {
        return err;
    }
}

/* 统一封装post请求  */
export const post = async (url, data, options) => {
    try {
        let result = await instance.post(url, data, options);
        return result;
    } catch (err) {
        return err;
    }
}

/* 统一封装put请求  */
export const put = async (url, data, options) => {
    try {
        let result = await instance.put(url, data, options);
        return result;
    } catch (err) {
        return err;
    }
}

/* 统一封装delete请求  */
export const del = async (url, data = {}, options) => {
    try {
        let data1 = new FormData();
        data1.append("ids", data.ids);
        if (data.type) {
            data1.append("liveId", data.liveId);
            data1.append("type", data.type);
        }
        if (data.webinar_id) {
            data1.delete('ids')
            data1.append("webinar_id", data.webinar_id);
            data1.append("msg_id", data.msg_id);
        }
        let result = await instance.delete(url, { data: data1 })
        return result;
    } catch (err) {
        return err;
    }
}

/* 统一封装delete请求  */
export const delte = async (url, data, options) => {
    try {
        let result = await instance.delete(url, data, options)
        return result;
    } catch (err) {
        return err;
    }
}


/* 正常的delete请求 */
export const delet = async (url, params, options) => {
    let urlStr = url + "?";
    for (let i in params) {
        if (params[i] !== undefined) {
            urlStr += `${i}=${params[i]}&`
        }
    }
    urlStr = urlStr.slice(0, urlStr.length - 1);
    try {
        let result = await instance.delete(urlStr, options);
        return result;
    } catch (err) {
        return err;
    }
}

export const patch = async (url, data, options) => {
    try {
        let result = await instance.patch(url, data)
        return result;
    } catch (err) {
        return err;
    }
}

