import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Button, Layout, Table, Modal, Space, message, Form, Upload } from 'antd';
import { fullPagerConfig } from '@/utils/property';
import { get, post, put } from '@/core/request';
import KbResearchHospitalFilterFrom from "./KbResearchHospitalFilterFrom";
import './KbResearchHospital.less';
import { researchHospitalActions } from './store/researchHospitalSlice';

const { Content } = Layout;

const { setFilter, setCursor } = researchHospitalActions;

export default function KbResearchHospital() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.researchHospital.filter);
    const cursor = useSelector(state => state.researchHospital.cursor);

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});

    const changePath = useCallback(
        (row, tip) => {

            return () => {
                if (tip === 'basic') {
                    if (row.id !== filter.cursor) {
                        dispatch(setCursor(row.id));
                    }
                    navigate(`/kb/rearch/basic-info/${row.recordId}`, { state: row })
                } else {
                    if (row.id !== filter.cursor) {
                        dispatch(setCursor(row.id));
                    }
                    navigate(`/kb/vertify/${row.recordId}`, { state: row })
                }
            }
        },
        [navigate]
    );

    // 改变第几页
    const changeCurrPage = useCallback((page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize,
        }));
    }, []);

    // 筛选数据
    const handleFilterFinish = values => {
        dispatch(setFilter({ ...filter, ...values }));
    };

    const getData = () => {
        get(`/kb/checkInformations/list`, {
            ...filter,
            flagStatus: filter?.flagStatus || 0,
            year: filter?.year || 2023
        }).then((res) => {
            if (res.status_code === 200 && res.data) {
                setTableData(res.data);
            }
        });
    };

    // 渲染table数据
    useEffect(() => {
        getData();
    }, [filter]);


    //分页数据
    const paginationProps = {
        ...fullPagerConfig,
        size: 'small',
        current: filter.page,
        pageSize: filter.pageSize,
        // onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        total: tableData.total,
        onChange: (page, pageSize) => {
            changeCurrPage(page, pageSize);
        },
    };

    // 导入相关modal
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // 基础信息导出
    const baseInfoDownload = () => {
        if (filter?.year === 2022) {
            message.warning('2022年数据暂不支持导出');
            return;
        }

        try {
            get('/kb/questionsRecords/base-info-download', {
                ...filter,
                flagStatus: filter?.flagStatus || 0,
                year: filter?.year || 2023
            }, {
                responseType: 'blob',
                timeout: 300000
            })
                .then(res => {
                    if (res === '请求超时, 请刷新重试') {
                        // message.warning('请求超时, 请刷新重试');
                        return;
                    }
                    if (res.size !== 0) {
                        message.success('请稍后，正在导出');
                        const fileName = "研究型医院基础信息列表.xlsx";
                        let a = document.createElement('a');
                        let event = new MouseEvent('click');
                        a.download = fileName;
                        let objectUrl = URL.createObjectURL(res);
                        a.href = objectUrl;
                        a.dispatchEvent(event);
                        URL.revokeObjectURL(objectUrl);
                    } else {
                        message.warning('当前列表没有数据，无法导出')
                    }
                })
        } catch (error) {
            message.error('导出失败');
        }
    }

    // 填报数据导出
    const fillDataDownload = () => {
        if (filter?.year === 2022) {
            message.warning('2022年数据暂不支持导出');
            return;
        }
        try {
            get('/kb/questionsRecords/fill-data-download', {
                ...filter, flagStatus: filter?.flagStatus || 0, year: filter?.year || 2023
            }, { responseType: 'blob', timeout: 300000 }).then(res => {
                if (res === '请求超时, 请刷新重试') {
                    // message.warning('请求超时, 请刷新重试');
                    return
                }
                if (res.size !== 0) {
                    message.success('请稍后，正在导出');
                    const fileName = "研究型医院填报信息列表.xlsx";
                    let a = document.createElement('a');
                    let event = new MouseEvent('click');
                    a.download = fileName;
                    let objectUrl = URL.createObjectURL(res);
                    a.href = objectUrl;
                    a.dispatchEvent(event);
                    URL.revokeObjectURL(objectUrl);
                } else {
                    message.warning('当前列表没有数据，无法导出')
                }
            })
        } catch (error) {
            message.error('导出失败');
        }
    }

    // 批量上传文件
    const uploadProps = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {//手动上传
            console.log(1, info);
            const isFile = info.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || info.file.type === 'application/vnd.ms-excel'
            console.log(info.file, 'file');
            if (!isFile) {
                message.error('你只能上传Excel格式文件!');
                return;
            }
            const isLt20M = info.file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('文件大小不能超过20M');
                return
            }
            const formData = new FormData();
            formData.append('filename', info.file);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/kb/checkInformations/import_all", formData, option).then((res) => {
                if (!res) {
                    message.error('导入失败')
                    return;
                }
                if (res.status_code === 200) {
                    if (res.data) {
                        Modal.info({
                            title: '导入',
                            content: <div>
                                {Object.keys(res.data).length > 0 ? Object.keys(res.data).map((item, ind) => (
                                    <div key={ind}><span>{item === 'msg' ? '' : item}</span><p>{res.data[item]}</p>
                                    </div>
                                )) : null}
                            </div>,
                            duration: 100,
                            className: 'reserach_info_wrap'
                        })
                    }
                }
            })
        },
    };

    const handleBack = () => {
        get(`/kb/questionsRecordsChecks/exitCheckByRecordId`, { id: 2, type: 2 })
            .then(res => {
                message.success(res.message);
            });
    };

    // 0正式数据 1测试数据
    const handerChangeFlag = (row, data) => {
        if (row.id !== filter.cursor) {
            dispatch(setCursor(row.id));
        }

        put(`/kb/checkInformations/update-flag`, { id: row.id, flagStatus: data })
            .then(res => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    getData();
                } else {

                }
            });
    };

    const columns = [
        { title: "填报记录ID", dataIndex: "id" },
        { title: "年份", dataIndex: "year" },
        { title: "申报主题", dataIndex: "theme" },
        {
            title: "申报医院",
            dataIndex: "orgName",
            width: 300,
            render: (orgName, row) => <span className="orgNameClass">{orgName}</span>
        },
        { title: "申报学科", dataIndex: "subjectName" },
        {
            title: "申报人",
            dataIndex: "personName",
            width: 100,
            render: (personName, row) => <span className="orgNameClass">{personName}</span>
        },
        {
            title: "填报人",
            dataIndex: "userName",
            width: 100,
            render: (personName, row) => <span className="orgNameClass">{personName}</span>
        },
        { title: '填报人电话', dataIndex: 'phone', },
        {
            title: "基础信息状态",
            dataIndex: "baseInfoStatus",
            render: (baseInfoStatus, row) => (
                <Fragment>
                    {baseInfoStatus === 0 && <span className="operate-style" onClick={changePath(row, 'basic')}>审核中</span>}
                    {baseInfoStatus === 1 && <span className="operate-style" onClick={changePath(row, 'basic')}>审核通过</span>}
                    {baseInfoStatus === 2 && <span className="delete-style" onClick={changePath(row, 'basic')}>审核不通过</span>}
                </Fragment>
            ),
        },
        {
            title: "填报数据状态",
            dataIndex: "recordStatus",
            render: (recordStatus, row) => (
                <Fragment>
                    {recordStatus === 0 && <span className="operate-style" onClick={changePath(row, 'vertify')}>草稿</span>}
                    {recordStatus === 1 && <span className="operate-style" onClick={changePath(row, 'vertify')}>待审核</span>}
                    {recordStatus === 2 && <span className="operate-style" onClick={changePath(row, 'vertify')}>审核中</span>}
                    {recordStatus === 3 && <span className="operate-style" onClick={changePath(row, 'vertify')}>审核通过</span>}
                    {recordStatus === 4 && <span className="delete-style" onClick={changePath(row, 'vertify')}>审核未通过</span>}
                </Fragment>
            ),
        },
        { title: "填报数据进度", dataIndex: "fillDataRate" },
        { title: "指标异常数量", dataIndex: "unusualDataNum" },
        { title: "填报数据审核比例", dataIndex: "fillDataCheckRate" },
        { title: "填报数据赋分比例", dataIndex: "dataScoreRate" },
        { title: "总分", dataIndex: "customerScore" },
        { title: "审核人", dataIndex: "updatedBy" },
        {
            title: "测试数据",
            dataIndex: "flagStatus",
            width: 120,
            render: (flagStatus, row) => (
                <Fragment>
                    {flagStatus === 1 && (<span>测试数据</span>)}
                    {flagStatus === 0 && (<span className="green-text">正式数据</span>)}
                </Fragment>
            ),
        },
        {
            title: "操作",
            dataIndex: "flagStatus",
            fixed: 'right',
            render: (flagStatus, row) => (
                <Fragment>
                    {flagStatus === 0 && <span className="operate-style" onClick={() => handerChangeFlag(row, 1)}>标记为测试</span>}
                    {flagStatus === 1 && <span className="operate-style" onClick={() => handerChangeFlag(row, 0)}>标记为正式</span>}
                </Fragment>
            ),
        },
    ];

    return (
        <>
            <div className="page-title">医院数据填报管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <KbResearchHospitalFilterFrom
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={baseInfoDownload}>基础信息导出</Button>
                                <Button type="primary" onClick={fillDataDownload}>填报数据导出</Button>
                                <Button type="primary" onClick={handleBack}>退出所有审核</Button>
                            </Space>
                        </div>
                    </div>

                    <Table
                        className="researchHospital-table"
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        columns={columns}
                        dataSource={tableData.records}
                        pagination={paginationProps}
                        rowKey={row => row.recordId}
                        scroll={{ x: 'max-content' }}
                    />

                </div>

                {isModalVisible && (
                    <Modal
                        title="医院数据填报导入"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <Form className="upload-form">
                            <Space>
                                <Upload {...uploadProps}>
                                    <Button type="primary">选取文件</Button>
                                </Upload>
                            </Space>
                        </Form>
                    </Modal>
                )}
            </Content>
        </>
    );
}
