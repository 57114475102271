import React, { useState, useEffect } from 'react';
import { Button, Switch, Col, Drawer, Upload, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Typography } from 'antd';
import { SearchOutlined, LeftOutlined } from '@ant-design/icons';
import { filterFormItemLayout } from '@utils/property';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getTopicCasesApi, updateCaseVoteApi } from '@services/Selection/vote.js';
import "./index.less";

const VoteCases = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { Content } = Layout;
    const { pid, tid } = params || 0;
    const { Text } = Typography;
    const [form] = Form.useForm();
    const [form1] = Form.useForm();

    const [data, setData] = useState([]);
    const [queryObj, setQueryObj] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const filterFormGridLayout = {
        sm: { span: 24 },
        md: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 8 },
    }

    const onFinish = (res) => {
        setPage(1);
        setQueryObj(res);
    }

    const onReset = () => {
        form.resetFields();
        setPage(1);
        setQueryObj({});
    }

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const queryParams = {
        ...form.getFieldsValue(),
        page: page,
        pageSize: pageSize,
        topicId: tid
    };

    const getData = () => {
        if (!queryParams.caseName) delete queryParams.caseName;
        if (!queryParams.hospitalName) delete queryParams.hospitalName;
        if (!queryParams.sort) delete queryParams.sort;
        getTopicCasesApi(queryParams).then(res => {
            setLoading(false);
            if (res && res.status_code === 200) {
                const { records = [], total } = res.data || {};
                setData(records);
                setTotal(total)
            }
        })
    }

    const columns = [
        {
            title: '投票案例', dataIndex: 'case', width: 150,
            render: (_, record) => {
                return JSON.parse(record.fieldValue).title
            },
        },
        {
            title: '提报单位', dataIndex: 'hospitalName', width: 150,
        },
        {
            title: '提报用户', dataIndex: 'nickName', width: 100,
        },
        {
            title: '投票数', dataIndex: 'voteNum', width: 50,
        },
        {
            title: '案例票数', dataIndex: 'visibleVoteNum', width: 50,
            render: (_, record) => {
                return record.visibleVoteNum ? <Text>展示</Text> : <Text type="danger">隐藏</Text>
            },
        },
        {
            title: '投票按钮', dataIndex: 'visibleVoteButton', width: 50,
            render: (_, record) => {
                return record.visibleVoteButton ? <Text>展示</Text> : <Text type="danger">隐藏</Text>
            },
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 100,
            render: (_, record) => {
                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleSettings(record)}

                        >
                            案例投票设置
                        </Button>
                    </span>
                )
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        getData();

    }, [page, pageSize, queryObj])

    const handleSettings = (record) => {
        const { visibleVoteButton, visibleVoteNum, voteNum, fieldValue, id } = record || {};
        form1.setFieldsValue({
            visibleVoteButton: visibleVoteButton ? true : false,
            visibleVoteNum: visibleVoteNum ? true : false,
            voteNum,
            caseName: JSON.parse(fieldValue)?.title,
            id,
        });
        setOpenDrawer(true);
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    }

    const handleSettingDrawer = () => {
        form1.validateFields().then((value) => {
            const { visibleVoteButton, visibleVoteNum, voteNum } = value;
            updateCaseVoteApi({
                visibleVoteButton: visibleVoteButton ? 1 : 0,
                visibleVoteNum: visibleVoteNum ? 1 : 0,
                voteNum,
                id: ~~form1.getFieldValue('id'),
                topicId: ~~tid
            }).then(res => {
                if (res && res.status_code === 200) {
                    setLoading(true);
                    getData();
                    setOpenDrawer(false);
                }
            })
        })
    }



    return (
        <>
            <div className="page-title">
                <Link to={`/selection/projects/${pid}/vote/topics`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">投票案例管理</span>
            </div>


            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="投票案例" name="caseName" >
                                    <Input allowClear placeholder="请输入投票案例" autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="提报单位" name="hospitalName" >
                                    <Input allowClear placeholder="请输入提报单位" autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="案例票数排序" name="sort">
                                    <Select placeholder='请选择票数排序'>
                                        <Select.Option value={1}>票数从高到低</Select.Option>
                                        <Select.Option value={2}>票数从低到高</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">
                    <Table
                        className="vote-table"
                        rowKey={item => item.id}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />
                </div>
            </Content >

            <Drawer
                title="案例投票设置"
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                width={500}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleCloseDrawer}>取消</Button>
                            <Button type="primary" htmlType="submit" onClick={handleSettingDrawer}>确认</Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 20 }}
                    onFinish={onFinish}
                    form={form1}
                >
                    <Form.Item label="投票案例" name="caseName" >
                        <Input.TextArea
                            readOnly={true}
                            autoSize={{ minRows: 1 }}
                            style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="voteNum"
                        label="案例票数"
                        rules={
                            [
                                { required: true, message: "请输入案例票数" },
                                { pattern: /^\+?[0-9]\d*$/, message: '请输入0或大于0的正整数' }
                            ]
                        }
                    >
                        <Input placeholder="请输入每次投票票数" allowClear />
                    </Form.Item>
                    <Form.Item
                        name="visibleVoteNum"
                        label="是否展示案例票数"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="展示" unCheckedChildren="隐藏" />
                    </Form.Item>
                    <Form.Item
                        name="visibleVoteButton"
                        label="是否展示投票按钮"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="展示" unCheckedChildren="隐藏" />
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
}

export default VoteCases;


