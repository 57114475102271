import React, { Fragment, useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import userImage from '@/static/images/user.png';
import productionImage from '@/static/images/production.png';
import complianceImage from '@/static/images/compliance.png';
import dataImage from '@/static/images/data.png';
import liveImage from '@/static/images/live.png';
import StatisticsChart from './StatisticsChart';
import { Card, Col, Row, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { get } from '@/core/request';
import './DmsDashboard.less';
const { Content } = Layout;

export default function DmsDashboard() {

    // 七日平均
    const [sevenData, setSevenData] = useState(null);
    // 累计数据
    const [cumulativeData, setCumulativeData] = useState(null);
    // 数据统计
    const [dataStatistics, setDataStatistics] = useState(null);
    // 用量统计
    const [dosageStatistics, setDosageStatistics] = useState(null);

    // 渲染table数据
    useEffect(() => {
        get(`/dms/home/trends`).then((res) => {
            if (res.status_code === 200) {
                const { seven_day_average, cumulative_data, data_statistics, dosage_statistics } = res.data;
                setSevenData(seven_day_average);
                setCumulativeData(cumulative_data);
                setDataStatistics(data_statistics);
                setDosageStatistics(dosage_statistics);
            }
        });
    }, []);

    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title">企业总览</div>
                <div className="main-content-body">
                    <div className="index">
                        <div className="tendency">
                            {sevenData && <h2>整体趋势</h2>}
                            <div className="site-card-wrapper">
                                <Row gutter={24}>
                                    {
                                        sevenData && <Col span={12} className="outside-col-left">
                                            <Card bordered={false}>
                                                <h4>7日平均</h4>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>新增用户</p>
                                                        <p className="tendency-num">{sevenData.addUser.addUserCount}</p>
                                                        {/* <p>同比 {sevenData.addUser.addUserCale}</p> */}
                                                    </Col>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>新增产品线</p>
                                                        <p className="tendency-num">{sevenData.addProduct.addProductCount}</p>
                                                        {/* <p>同比 {sevenData.addProduct.addProductCale}</p> */}
                                                    </Col>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>新增直播</p>
                                                        <p className="tendency-num">{sevenData.addLive.addLiveCount}</p>
                                                        {/* <p>同比 {sevenData.addLive.addLiveCalc}</p> */}
                                                    </Col>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>新增认证用户</p>
                                                        <p className="tendency-num">{sevenData.newAuthenticateHash.calcAuthenticatedCount}</p>
                                                        {/* <p>同比 {sevenData.newAuthenticateHash.newAuthenticatedUserCountCalc}</p> */}
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    }

                                    {
                                        cumulativeData && <Col span={12} className="outside-col-right">
                                            <Card bordered={false}>
                                                <h4>累计数据</h4>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>累计用户</p>
                                                        <p className="tendency-num">{cumulativeData.cumulative_user_count}</p>
                                                    </Col>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>累计产品线</p>
                                                        <p className="tendency-num">{cumulativeData.productCount}</p>
                                                    </Col>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>累计直播</p>
                                                        <p className="tendency-num">{cumulativeData.liveCount}</p>
                                                    </Col>
                                                    <Col className="gutter-row" span={6}>
                                                        <p>累计认证用户</p>
                                                        <p className="tendency-num">{cumulativeData.cumulativeAuthenticatedUsersCount}</p>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </div>
                        <div className="shortcuts all-margin">
                            <ul>
                                <Link to="/dms/lives"><li><img src={liveImage} alt="直播管理" /><p>直播管理</p></li></Link>
                                <Link to="/dms/production-lines"><li><img src={productionImage} alt="产品线管理" /><p>产品线管理</p></li></Link>
                                <Link to="/dms/data/overview"><li><img src={dataImage} alt="数据中心" /><p>数据中心</p></li></Link>
                                <Link to="/dms/users"><li><img src={userImage} alt="用户管理" /><p>用户管理</p></li></Link>
                                <Link to="/dms/compliance/user/lives"><li><img src={complianceImage} alt="合规化统计" /><p>合规化统计</p></li></Link>
                            </ul>
                        </div>

                        {
                            dataStatistics && <div className="data-statistics all-margin">
                                <div className="index-module-top"><h2>数据统计</h2>
                                    <span className="index-module-top-icon"><QuestionCircleOutlined /></span>
                                    <p>统计近7日数据，删除活动不影响已统计的历史数据</p>
                                </div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}>
                                        <p>最高并发量</p>
                                        <p className="tendency-num">{dataStatistics.maxOnlines}</p>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <p>观看次数</p>
                                        <p className="tendency-num">{dataStatistics.watchTimes}</p>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <p>观看人数</p>
                                        <p className="tendency-num">{dataStatistics.watchNumber}</p>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <p>观看时长（分）</p>
                                        <p className="tendency-num">{dataStatistics.watchDuration}</p>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {
                            dosageStatistics && dosageStatistics.dosage.length ? <div className="usage-statistics all-margin">
                                <div className="index-module-top"><h2>用量统计</h2>
                                    <span className="index-module-top-icon"><QuestionCircleOutlined /></span>
                                    <p>统计近7日数据，删除活动不影响已统计的历史数据</p>
                                </div>
                                <StatisticsChart dosageStatistics={dosageStatistics}></StatisticsChart>
                            </div> : <Fragment></Fragment>
                        }

                    </div>
                </div>
            </Content>

            <Content className="main-content-box">
                <div className="main-content-title">功能导航</div>
                <div className="main-content-body">

                </div>
            </Content>
        </Fragment>
    );
}
