import React, { Fragment, useCallback, useEffect, useState, useRef } from "react";
import { Input, Modal, Form } from "antd";

const { Item } = Form;

const AddQuestionBankModal = ({ isModalVisiable, onClose, title, onOk, defaultValue }) => {
    const [form] = Form.useForm();

    const onBtnOk = () => {
        form.validateFields().then((res) => {
            onOk(res, () => {
                form.resetFields();
            });
        });
    };

    const onBtnCancel = () => {
        form.resetFields();
        onClose();
    };

    useEffect(() => {
        if (title === "编辑" && defaultValue && isModalVisiable)
            form.setFieldsValue({
                name: defaultValue,
            });
    }, [defaultValue, title, isModalVisiable]);

    return (
        <Modal open={isModalVisiable} onCancel={onBtnCancel} onOk={onBtnOk} title={title}>
            <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} initialValues={{ name: "" }} autoComplete="off" form={form}>
                <Item name="name" rules={[{ required: true, message: "请输入分类名称" }]} label="分类名称">
                    <Input type="text" placeholder="请输入分类名称" />
                </Item>
            </Form>
        </Modal>
    );
};

export default AddQuestionBankModal;
