import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, LeftOutlined, DragOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './index.less';
import {
    Input,
    Layout,
    Button,
    Modal,
    Form,
    message,
    Radio,
} from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import ActivitiesComponent from './components/Activities';
import ContentComponent from './components/Contents';
import SolutionsComponent from './components/Solutions';
import DetailsComponent from './components/Details';
import FreesComponent from './components/Frees';
import TrainingComponent from './components/Training';
import TinymceEditor from './components/TinymceEditor';

import {
    showMedProject,
    moduleListApi,
    moduleSortApi,
    moduleSaveApi,
    moduleUpdateApi,
    moduleDeleteApi
} from '@services/MedProject';

const { Content } = Layout;

export default function RelatedProjectList() {
    const navigate = useNavigate();

    const params = useParams();
    const id = (params?.id && Number(params?.id)) || 0;
    const projectId = params?.projectId || 0; // 项目id
    // const projectId = 692;
    console.log(id);

    const [form] = Form.useForm();

    const [modalTitle, setModalTitle] = useState('添加模块');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuList, setMenuList] = useState([]);
    const [menuTitle, setMenuTitle] = useState('');
    const [editorContents, setEditorContents] = useState('');
    const [initEditorValue, setInitEditorValue] = useState(null);
    const [currentModule, setCurrentModule] = useState(null);
    const [radioVal, setRadioVal] = useState('link'); // link-外链，rich_text-图文, time_line-时间轴
    const [radioHotVal, setRadioHotVal] = useState(0); // 1-显示，0-隐藏
    const [radioTabVal, setRadioTabVal] = useState(0); // 1-显示，0-隐藏

    const [components, setComponents] = useState(<ActivitiesComponent />);

    const [project, setProject] = useState(null);

    const getProject = async id => {
        const res = await showMedProject(id);
        if (res.status_code === 200) {
            const project = res.data;
            setProject(project);
        }
    };

    useEffect(() => {
        if (!!projectId) {
            getProject(projectId)
        }
    }, []);

    // 切换模块
    const changeModules = (item) => {
        if (item.moduleType !== 'member') {
            setComponents(getComponent(item.moduleType));
            navigate(`/medproject/projects/${projectId}/modules/${item.id}`);
        } else {
            navigate(`/medproject/projects/${projectId}/members`);
        }
    };

    const handleOk = () => {
        form.validateFields().then(async (values) => {
            if (menuTitle) {
                const reqParams = {
                    ...values,
                    id: currentModule.id,
                    moduleType: currentModule.moduleType,
                    projectId,
                    url: currentModule.url,
                    content: currentModule.content
                };
                const res = await moduleUpdateApi(reqParams);
                if (res && res.status_code === 200) {
                    message.success('保存成功');
                    setIsModalOpen(false);
                    getModules();
                    form.resetFields();
                }

            } else {
                const reqParams = {
                    ...values,
                    projectId,
                };
                const res = await moduleSaveApi(reqParams);
                if (res && res.status_code === 200) {
                    message.success('保存成功');
                    setIsModalOpen(false);
                    getModules();
                    form.resetFields();
                }
            }
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const radioChange = (e) => {
        console.log("radio: ", e);
        setRadioVal(e.target.value);
    }
    const radioHotChange = (e) => {
        console.log("radio: ", e);
        setRadioHotVal(e.target.value);
    }
    const radioTabChange = (e) => {
        console.log("radio: ", e);
        setRadioTabVal(e.target.value);
    }

    // 添加模块
    const addModule = () => {
        setModalTitle('添加模块');
        setIsModalOpen(true);
        setMenuTitle('');
        setEditorContents('');
        setInitEditorValue(null);
        setRadioVal('link');
        form.setFieldValue('isShowHot', 0);
        form.setFieldValue('isShowTab', 1);
    };

    // 编辑模块
    const editModule = async (e, item) => {
        // console.log(item)
        e.stopPropagation();
        setModalTitle('修改名称');
        setIsModalOpen(true);
        form.setFieldValue('name', item.name);
        setMenuTitle(item.name);
        setEditorContents('');
        setInitEditorValue(null);
        setRadioVal('link');
        form.setFieldValue('isShowHot', item.isShowHot);
        form.setFieldValue('isShowTab', item.isShowTab);
        setCurrentModule(item);
        // console.log(item);
    };

    // 删除模块
    const deleteModule = (e, item) => {
        setCurrentModule(item);
        Modal.confirm({
            title: '提示',
            content: '删除后，该模块在用户端也一并删除，请确认是否删除？',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                const res = await moduleDeleteApi({ id: item.id });
                if (res && res.status_code === 200) {
                    message.success('删除成功');
                    getModules();
                    setComponents(getComponent(''));
                }
            }
        });
    };

    // 渲染右侧内容组件
    const getComponent = (moduleType) => {
        if (moduleType) {
            switch (moduleType) {
                case 'activity':
                    return <ActivitiesComponent />;  // 全部活动
                case 'content':
                    return <ContentComponent />;   // 精彩内容
                case 'solution':
                    return <SolutionsComponent />;  // 解决方案
                case 'member':
                    return; // 项目成员
                case 'info':
                    return <DetailsComponent />;  // 项目详情
                case 'training':
                    return <TrainingComponent />;  // 培训学习
                case '':
                    return <></>;
                default:
                    return <FreesComponent />; // 自由配置模块
            }
        }
    };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return <DragOutlined />;
    });

    // 排序
    const onSortEndHandler = async ({ oldIndex, newIndex }) => {
        let newData = arrayMoveImmutable(menuList, oldIndex, newIndex);
        const ids = newData.map(item => item.id);
        const res = await moduleSortApi([...ids]);
        if (res.status_code === 200) {
            getModules();
        }
    };

    // 拖拽容器
    const SortableModulesContainer = SortableContainer(({ items, paSort }) => {
        return <div>
            {!!items?.length && items.map((treeNode, index) => {
                return (
                    <SortableMainElement
                        item={treeNode}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    const SortableMainElement = SortableElement(({ item, key }) => {
        return <div key={key}>
            <p className={`module-menu-item ${item.id === id && 'active'}`} onClick={() => changeModules(item)}>
                <div className="menu-item-title-box">
                    <span className="menu-item-name">{item.name}</span>
                    {item.isShowHot === 1 && <span className="menu-item-name-tip hot">HOT</span>}
                    {item.isShowTab === 0 && <span className="menu-item-name-tip hidden">隐</span>}
                </div>

                <div className="menu-item-option">
                    <EditOutlined onClick={(e) => editModule(e, item)} /> {['rich_text', 'link', 'time_line'].includes(item.moduleType) ? <DeleteOutlined onClick={(e) => deleteModule(e, item)} /> : null} <SortableMainHandle />
                </div>
            </p>
        </div>
    });


    const getModules = async () => {
        const res = await moduleListApi({ projectId });
        if (res && res.status_code === 200 && res.data) {
            setMenuList(res.data);

            // 页面刷新 - 渲染对应模块
            if (res.data.length) {
                const currModuleType = res.data.find(item => item.id === id)?.moduleType;
                setComponents(getComponent(currModuleType));
            }
        }
    };

    useEffect(() => {
        getModules();
    }, [projectId]);

    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined /></Link>
                <span className="current-title">模块管理</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="modules-page">
                        <div className="modules-side">
                            <div className="modules-menu-box">
                                <p className="menu-title">项目模块名称</p>
                                <SortableModulesContainer
                                    useWindowAsScrollContainer={true}
                                    useDragHandle
                                    distance={10}
                                    items={menuList}
                                    onSortEnd={onSortEndHandler}
                                />

                            </div>

                            {menuList && menuList.length < 10 &&
                                <div className="menu-item-add">
                                    <Button icon={<PlusOutlined />} onClick={addModule} block>添加模块</Button>
                                </div>
                            }
                        </div>

                        <div className="modules-content">
                            {components}
                        </div>
                    </div>

                    <Modal title={modalTitle} width={940} className="module-modal" open={isModalOpen} centered onOk={handleOk} onCancel={handleCancel}>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            autoComplete="off"
                            initialValues={{ moduleType: 'link' }}
                        >
                            {
                                !menuTitle && <p className='module-tips'>说明：项目除了固定的5个模块，最多还可以添加 5 个，由于导航长度有限及导航名称字数不定，添加模块后请预览查看用户端是否超出了有效区域。</p>
                            }

                            <Form.Item
                                label="模块名称"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: '模块名称不能为空',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入模块名称" maxLength={6} showCount />
                            </Form.Item>

                            <Form.Item
                                label="是否前台显示HOT标"
                                name="isShowHot"
                                rules={[
                                    { required: true },
                                ]}
                            >
                                <Radio.Group onChange={radioHotChange} value={radioHotVal}>
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={0}>隐藏</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="是否前台显示该模块"
                                name="isShowTab"
                                rules={[
                                    { required: true },
                                ]}
                            >
                                <Radio.Group onChange={radioTabChange} value={radioTabVal}>
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={0}>隐藏</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {
                                !menuTitle && <>
                                    <Form.Item
                                        name="moduleType"
                                        label="添加类型"
                                        rules={[
                                            { required: true },
                                        ]}
                                    >
                                        <Radio.Group onChange={radioChange} value={radioVal}>
                                            <Radio value={'link'}>外部链接</Radio>
                                            <Radio value={'rich_text'}>图文模块</Radio>
                                            <Radio value={'time_line'}>时间轴</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {radioVal === 'link' &&
                                        <Form.Item
                                            name="url"
                                            label="链接地址"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '模块访问路径不能为空',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入模块访问路径" />
                                        </Form.Item>
                                    }
                                    {radioVal === 'rich_text' &&
                                        <Form.Item
                                            label="模块内容"
                                            name="content"
                                            rules={[
                                                { required: true },
                                                {
                                                    validator: async (rule, editorContents) => {
                                                        if (editorContents === "") {
                                                            throw "请输入模块内容";
                                                        } else if (editorContents === "<p><br></p>") {
                                                            throw "请输入模块内容";
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <TinymceEditor
                                                initEditorValue={initEditorValue}
                                                setEditorContents={setEditorContents}
                                                editorContents={editorContents}
                                                id="editorContents"
                                                placeholder="编辑内容使用的一级标题‘H1’，会在用户端做为目录导航呈现。如需上传图片，建议图片宽度保持1700px，图片两侧不建议留白。"
                                                form={form}
                                                fieldValue='content'
                                            />
                                        </Form.Item>
                                    }
                                </>
                            }
                        </Form>
                    </Modal>
                </div>
            </Content>

        </>
    );
}
