import React, { Fragment,useState,useEffect } from "react";
import { Button } from "antd";
import "./index.less";
import {medical,business,nature,simple} from "@utils/haibao";
import haibaoLogo from '@static/images/haibao-logo.png';
import qrInside from '@static/images/qr-inside.png';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
const medicalImg = medical();
const businessImg = business();
const natureImg = nature();
const simpleImg = simple();
const HaibaoLib = ({toTwo,url,setHaibaoImage,activeKey}) => {
    const location = useLocation();
    const [more,setMore] = useState([true,true,true,true])
    const handleClick = (e) => {
        e.preventDefault();
    };

    const scrollToAnchor = (anchorName,index) => {
        let item=document.querySelectorAll('.haibao-choice-item')
        item.forEach((it,i)=>{
            if(i===index){
                it.style.color='#0A57DA'
            }else{
                it.style.color='#000000'
            }
        })
        if (anchorName) {
            const tabBar  = document.getElementById('tab-bar').offsetHeight
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({behavior: 'auto', block: 'start', inline: 'nearest'})
            }
        }
    }

    const changeNavBg=()=>{
        console.log(1);
    };

    const showMore=(index)=>{
        let item=document.querySelectorAll(`.anchor`)
        let finial=document.querySelector('.haibao-box-four')
        item.forEach((it,i)=>{
            if(index!==3&&i===index){
                it.style.height='1050px'
            }else if(index === 3&&i === index){
                it.style.height='1050px'
                finial.style.height='1050px'
            }
        })
        let openprop = more;
        openprop[index] = false;
        setMore([...openprop]);

    };

    const cutOut =(index)=>{
        let item=document.querySelectorAll(`.anchor`)
        let finial=document.querySelector('.haibao-box-four')
        item.forEach((it,i)=>{
            if(index!==3&&i===index){
                it.style.height='240px'
            }else if(index === 3&&i === index){
                it.style.height='450px'
                finial.style.height='220px'
            }
        })
        let openprop = more;
        openprop[index] = true;
        setMore([...openprop]);

    };
    useEffect(()=>{
        // setTimeout(() => {
            let item=document.querySelectorAll('.haibao-choice-item')
            item[0].style.color='#0A57DA'
        // }, 100);
    },[]);
    return (
        <>

        <div className="haibao-anchor">
                <div id={"tab-bar"} className="haibao-choice">
                    <span className="haibao-choice-item"  onClick={() => scrollToAnchor("a",0)} name={"#a"}>
                        医疗
                    </span>
                    <span className="haibao-choice-item"  onClick={() => scrollToAnchor("b",1)} name={"#b"}>
                        商务
                    </span>
                    <span className="haibao-choice-item"  onClick={() => scrollToAnchor("c",2)} name={"#c"}>
                        自然
                    </span>
                    <span className="haibao-choice-item"  onClick={() => scrollToAnchor("d",3)} name={"#c"}>
                        简约
                    </span>
                    <div className="clear" />
                </div>
            </div>
        <div className="haibao-lib">
            <div id={"a"} className={"anchor one"}>
                <div className="haibao-head">
                    <div className="haibao-title">医疗</div>
                        {more[0]?<div className="haibao-more" onClick={()=>showMore(0)}>更多<DownOutlined /></div>:
                        <div className="haibao-more" onClick={()=>cutOut(0)}>收起<UpOutlined /></div>
                        }

                </div>
                <div className="haibao-box">
                {medicalImg.map((item,index)=>(
                        <div className="haibao-item" key={`med-${index}`}>
                            <img src={haibaoLogo} className='haibao-icon'/>
                            <img src={item} key={index} className="haibao-img"/>
                            <div className='haibao-fotter'>
                                <div className='haibao-qrcode'>
                                <QRCode
                                                        className='qrCode_wrap'
                                                        id="qrCode3"
                                                        value={url}
                                                        size={38} // 二维码的大小
                                                        fgColor="#000000" // 二维码的颜色
                                                        style={{ margin: 'auto' }}
                                                        level="H"
                                                    />
                                </div>
                                <div className='haibao-elaborate'>
                                    <div className='haibao-title'>{location.state.title}</div>
                                    <div className='haibao-description'>{'希望大家能够踊跃参加'}</div>
                                </div>
                            </div>
                            <div className="haibao-action"></div>
                            <Button onClick={()=>toTwo(item)} type="primary" className="action-btn">使用模版</Button>
                        </div>

                ))
                }
                </div>
            </div>
            <div id={"b"} className={"anchor two"}>
            <div className="haibao-head">
                <div className="haibao-title">商务</div>
                    {more[1]?<div className="haibao-more" onClick={()=>showMore(1)}>更多<DownOutlined /></div>
                        :<div className="haibao-more" onClick={()=>cutOut(1)}>收起<UpOutlined /></div>}
            </div>
            <div className="haibao-box">
            {businessImg.map((item,index)=>(
                        <div className="haibao-item" key={`biz-${index}`}>
                            <img src={haibaoLogo} className='haibao-icon'/>
                            <img src={item} key={index} className="haibao-img"/>
                            <div className='haibao-fotter'>
                                <div className='haibao-qrcode'>
                                <QRCode
                                                        className='qrCode_wrap'
                                                        id="qrCode3"
                                                        value={url}
                                                        size={38} // 二维码的大小
                                                        fgColor="#000000" // 二维码的颜色
                                                        style={{ margin: 'auto' }}
                                                        level="H"
                                                    />
                                </div>
                                <div className='haibao-elaborate'>
                                    <div className='haibao-title'>{location.state.title}</div>
                                    <div className='haibao-description'>{'希望大家能够踊跃参加'}</div>
                                </div>
                            </div>
                            <div className="haibao-action"></div>
                            <Button onClick={()=>toTwo(item)} type="primary" className="action-btn">使用模版</Button>
                        </div>

                ))
                }
            </div>
            </div>
            <div id={"c"} className={"anchor three"}>
            <div className="haibao-head">
                <div className="haibao-title">自然</div>
                {more[2]?<div className="haibao-more" onClick={()=>showMore(2)}>更多<DownOutlined /></div>
                        :<div className="haibao-more" onClick={()=>cutOut(2)}>收起<UpOutlined /></div>}
            </div>
            <div className="haibao-box">
            {natureImg.map((item,index)=>(
                        <div className="haibao-item"  key={`nature-${index}`}>
                            <img src={haibaoLogo} className='haibao-icon'/>
                            <img src={item} key={index} className="haibao-img"/>
                            <div className='haibao-fotter'>
                                <div className='haibao-qrcode'>
                                <QRCode
                                                        className='qrCode_wrap'
                                                        id="qrCode3"
                                                        value={url}
                                                        size={38} // 二维码的大小
                                                        fgColor="#000000" // 二维码的颜色
                                                        style={{ margin: 'auto' }}
                                                        level="H"
                                                    />
                                </div>
                                <div className='haibao-elaborate'>
                                    <div className='haibao-title'>{location.state.title}</div>
                                    <div className='haibao-description'>{'希望大家能够踊跃参加'}</div>
                                </div>
                            </div>
                            <div className="haibao-action"></div>
                            <Button onClick={()=>toTwo(item)} type="primary" className="action-btn">使用模版</Button>
                        </div>

                ))
                }
             </div>
            </div>
            <div id={"d"} className={"anchor four"}>

            <div className="haibao-head">
                <div className="haibao-title">简约</div>
                {more[3]?<div className="haibao-more" onClick={()=>showMore(3)}>更多<DownOutlined /></div>
                        :<div className="haibao-more" onClick={()=>cutOut(3)}>收起<UpOutlined /></div>}
            </div>
            <div className="haibao-box haibao-box-four">
            {simpleImg.map((item,index)=>(
                        <div className="haibao-item" key={`simple-${index}`}>
                            <img src={haibaoLogo} className='haibao-icon'/>
                            <img src={item} key={index} className="haibao-img"/>
                            <div className='haibao-fotter'>
                                <div className='haibao-qrcode'>
                                <QRCode
                                                        className='qrCode_wrap'
                                                        id="qrCode3"
                                                        value={url}
                                                        size={38} // 二维码的大小
                                                        fgColor="#000000" // 二维码的颜色
                                                        style={{ margin: 'auto' }}
                                                        level="H"
                                                    />
                                </div>
                                <div className='haibao-elaborate'>
                                    <div className='haibao-title'>{location.state.title}</div>
                                    <div className='haibao-description'>{'希望大家能够踊跃参加'}</div>
                                </div>
                            </div>
                            <div className="haibao-action"></div>
                            <Button onClick={()=>toTwo(item)} type="primary" className="action-btn">使用模版</Button>
                        </div>

                ))
                }
            </div>
            </div>
        </div>
        </>
    );
};
export default HaibaoLib;
