import { useEffect, useRef, useState } from "react";
import { Layout, Form, Button, Radio, Checkbox, Row, Col, Input, Select, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import { SelectionFormItemLayout, SelectionFormListItemLayout } from "../../../../utils/property";
import { getCasescoringtopicsList, getJudgesList, fenpeicase } from "@services/Selection";

import "./index.less";

const { Content } = Layout;

const defaultValue = { pingweishu: "all" };

const Assing = () => {
    const params = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [topicList, setTopicList] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const selectRef = useRef(new Map());

    const pingweishu = Form.useWatch("pingweishu", form);

    useEffect(() => {
        selectRef.current = new Map();
    }, [pingweishu]);

    useEffect(() => {
        getCasescoringtopicsList({ projectId: params.pid, stageId: params.id }).then((res) => {
            if (res?.status_code === 200) {
                setTopicList(res.data);
                form.setFieldValue("intentTopicsList", res.data?.map((i) => i.topicId) || []);
            }
        });
    }, [params.pid, params.id, form]);

    const handleSearch = (name) => {
        if (name) {
            getJudgesList({ page: 1, limit: 20, stageId: params.id, name }).then((res) => {
                if (res?.status_code === 200) {
                    setData(
                        res.data.list.map((i) => {
                            return { ...i, total: i?.judgesScoringTaskDtoList?.reduce((acc, cur) => acc + cur.assignedTotal, 0) ?? 0 };
                        })
                    );
                }
            });
        } else {
            setData([]);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleSubmit = () => {
        form.validateFields().then((res) => {
            if (!res.intentNo1 && !res.intentNo2 && !res.intentNo3) {
                return message.warning("请填写意向主题分配案例数！");
            }
            if (res.pingweishu === "some" && !selectRef.current.size) {
                return message.warning("请添加指定评委！");
            }
            const param = { stageId: params.id, topicIds: res.intentTopicsList };
            const intentTopicsList = [];
            if (res.intentNo1) {
                intentTopicsList.push(res.intentNo1);
            }
            if (res.intentNo2) {
                intentTopicsList.push(res.intentNo2);
            }
            if (res.intentNo3) {
                intentTopicsList.push(res.intentNo3);
            }
            param.intentTopicsList = intentTopicsList.map((v, i) => ({ intentNo: i + 1, fenpeicount: +v }));
            if (res.pingweishu === "some") {
                param.judgeIds = [...selectRef.current.values()];
            }
            setLoading(true);
            let isHandled = false;

            const timer = setTimeout(() => {
                isHandled = true;
                message.success("案例分配中，请 5 分钟后查看分配结果");
                navigate(-1);
            }, 10000);

            fenpeicase(param).then((res) => {
                clearTimeout(timer);
                setLoading(false);
                if (!isHandled) {
                    if (res?.status_code === 200) {
                        message.success("分配成功！");
                        navigate(-1);
                    }
                }
            });
        });
    };

    const changeSelects = (v, i) => {
        selectRef.current.set(i, v);

        setData([]);
    };

    const deleteSelects = (i) => {
        selectRef.current.delete(i);

        setData([]);
    };

    return (
        <>
            <div className="scoring-setting page-title">
                <span className="back" onClick={() => navigate(-1)}>
                    <LeftOutlined />
                </span>
                <div className="current-title">分配评委</div>
            </div>

            <Content className="main-content-box assign-content">
                <Form form={form} name="assign" {...SelectionFormItemLayout} requiredMark initialValues={defaultValue}>
                    <Form.Item label="分配案例的评委" name="pingweishu" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value="all">本环节全部评委</Radio>
                            <Radio value="some">指定评委分配案例</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {pingweishu === "some" && (
                        <Form.List label="添加指定评委" name="judgeIds" required>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Form.Item label="添加指定评委" required>
                                        <Button onClick={add}>添加评委</Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                    {fields.map((field, index) => (
                                        <Form.Item key={field.key} {...SelectionFormListItemLayout}>
                                            <Form.Item {...field} noStyle>
                                                <div className="flex-span">
                                                    <Select
                                                        showSearch
                                                        placeholder="添加评委，请输入评委全名"
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={handleSearch}
                                                        notFoundContent={null}
                                                        options={data.map((d) => ({
                                                            value: d.id,
                                                            label: `${d.username}[${d.mobile}] | 已分配${d.total}`,
                                                        }))}
                                                        style={{ width: 280 }}
                                                        onSelect={(v) => changeSelects(v, index)}
                                                    />
                                                    {index !== 0 && (
                                                        <span
                                                            onClick={() => {
                                                                remove(field.name);
                                                                deleteSelects(index);
                                                            }}
                                                            className="delete-span"
                                                        >
                                                            删除
                                                        </span>
                                                    )}
                                                </div>
                                            </Form.Item>
                                        </Form.Item>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    )}

                    <Form.Item label="要分配评委的主题案例" name="intentTopicsList" rules={[{ required: true, message: "请选择" }]}>
                        <Checkbox.Group>
                            <Row>
                                {(topicList || []).map((t) => (
                                    <Col span={24} key={t.topicId}>
                                        <Checkbox value={t.topicId}>
                                            {t.topicName} | 参评案例 {t.caseTotal} | {t.judgesTotal ? `已分配${t.judgesTotal}评委` : "未分配评委"}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item label="意向主题分配案例数" required>
                        <div className="flex-from-item">
                            意向主题1：
                            <Form.Item name="intentNo1">
                                <Input placeholder="填写分配案例数" style={{ width: 180 }} />
                            </Form.Item>
                        </div>
                    </Form.Item>
                    <Form.Item label=" " colon={false}>
                        <div className="flex-from-item">
                            意向主题2：
                            <Form.Item name="intentNo2">
                                <Input placeholder="填写分配案例数" style={{ width: 180 }} />
                            </Form.Item>
                        </div>
                    </Form.Item>
                    <Form.Item label=" " colon={false}>
                        <div className="flex-from-item">
                            意向主题3：
                            <Form.Item name="intentNo3">
                                <Input placeholder="填写分配案例数" style={{ width: 180 }} />
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Form>
                <div className="operation-buttons">
                    <Button onClick={handleCancel}>取消</Button>
                    <Button type="primary" onClick={handleSubmit} loading={loading}>
                        开始分配
                    </Button>
                </div>
            </Content>
        </>
    );
};

export default Assing;
