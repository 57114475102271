import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import LivesLimitsFilterForm from './LivesLimitsFilterForm';
import { useNavigate } from 'react-router-dom';
import { fullPagerConfig } from '@/utils/property';
import { get } from '@/core/request';

const { Content } = Layout;

export default function LivesLimits() {

    // 产品线
    const [productIds, setLiveId] = useState([]);
    // 所属企业
    const [orgIds, setOrgId] = useState([]);
    // 直播分类
    const [liveCatesId, setLiveCatesId] = useState([]);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setLiveId(res.data);
        })
    }, []);

    // 所属企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    // 直播分类
    useEffect(() => {
        get('/dms/lives/categories/findCatesTmenu').then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.value = item.id;
                    item.label = item.name;
                    item.children.forEach(elem => {
                        elem.value = elem.id;
                        elem.label = elem.name;
                        delete elem.children;
                    })
                })
                setLiveCatesId(res.data);
            }
        })
    }, []);

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = useCallback(
        (value) => {
            if (value.cateIdArr) {
                value.cateId = value.cateIdArr[1];
            }
            delete value.cateIdArr;
            setFilterObj(value);
        },
        [],
    );

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, orgId, cateId, productId, createdBeginAt, createdEndAt, liveStatus } = filterObj;

    // 渲染table数据
    useEffect(() => {
        get(`/dms/limits`, {
            limit: pageSize,
            page: currPage,
            name: name,
            orgId: orgId,
            cateId: cateId,
            productId: productId,
            createdBeginAt: createdBeginAt,
            createdEndAt: createdEndAt,
            liveStatus: liveStatus,
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.liveId;
                });
                setTableData(res.data);
            }
        });
    }, [pageSize, currPage, name, orgId, cateId, productId, createdBeginAt, createdEndAt, liveStatus])

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 点击数据统计
    const navigate = useNavigate()
    const changePath = useCallback(
        (path, row) => {
            return () => {
                navigate(path, { state: row});
            }
        },
        [navigate],
    );

    const columns = [
        { title: '直播ID', dataIndex: 'liveId', },
        { title: '直播名称', dataIndex: 'name', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgsName', },
        { title: '直播分类', dataIndex: 'cateName', },
        {
            title: '直播状态', dataIndex: 'liveStatus',
            render: (operates) => (
                <Fragment>
                    {operates === 0 && <span>直播预告</span>}
                    {operates === 1 && <span>直播中</span>}
                    {operates === 2 && <span>直播结束</span>}
                    {operates === 3 && <span>直播回放</span>}
                </Fragment>
            )
        },
        {
            title: '直播白名单', dataIndex: 'whiteNum',
            render: (operates) => (<span>{operates}人次</span>)
        },
        {
            title: '回放白名单', dataIndex: 'whiteBackNum',
            render: (operates) => (<span>{operates}人次</span>)
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={changePath(`/dms/lives/${row.liveId}/whitelist`, row)}>查看白名单</span>
                    <span className="operate-style" onClick={changePath(`/dms/lives/${row.liveId}/invitation-codes`, row)}>直播邀请码</span>
                    <span className="operate-style" onClick={changePath(`/dms/lives/${row.liveId}/replays/whitelist`, row)}>回放白名单</span>
                </Fragment>
            )
        },
    ];


    return (
        <Fragment>
            <div className="page-title">观看限制管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <LivesLimitsFilterForm
                        liveCatesId={liveCatesId}
                        productId={productIds}
                        orgId={orgIds}
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <Table className="production-table"
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

        </Fragment>
    )
}
