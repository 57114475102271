import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams, useNavigate} from 'react-router-dom';
import { Layout, Alert, Button, Table, Tag, Typography, message, Input, Modal, Form, Row, Col, Select, Space } from 'antd';
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { conferenceOnceApi } from '@services/Conference';
import { fetchLaborFeePaymentManageList} from '@services/Finance/FinanceLaborFee.service.js';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { localStorageGet } from '@core/cookie';
import { convertDateTimeToLocalText } from '../../../utils/utils';

const { Content } = Layout;
const { Text } = Typography;
export default function FinanceLaborFeePaymentBatches() {

    const navigate = useNavigate();
    const params = useParams();

    // 接收项目类型-项目ID路由参数
    const projectCodeAndId = params.project || '';
    let projectCode = '';
    let projectId = 0;
    if (!!projectCodeAndId && /[a-z]+-\d+/i.test(projectCodeAndId)) {
        [projectCode, projectId] = projectCodeAndId.split('-');
        projectCode = projectCode.toUpperCase() || '';
        projectId = parseInt(projectId, 10) || 0;
    }

    const [conference, setConference] = useState(null);

    const [form] = Form.useForm();

    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});

    // 是否拥有财务劳务费付款人员权限
    const isLaborFeePayment = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '财务劳务费付款人员');
        }
        return false;
    }, []);

    // 是否拥有财务劳务费管理员权限
    const isLaborFeeManager = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '财务劳务费管理员');
        }
        return false;
    }, []);

    const approvalOptions = [
        { value: 0, label: '待审批' },
        { value: 1, label: '同意' }
    ];

    const paymentOptions = [
        { value: 0, label: '待付款' },
        { value: 1, label: '付款中' },
        { value: 2, label: '全部付款' },
    ];

    const columns = [
        {
            title: "付款申请时间",
            width: 200,
            dataIndex: "batchRequestAt",
            render: (_, row) => convertDateTimeToLocalText(row.batchRequestAt)
        },
        {
            title: "实发劳务费(元)",
            dataIndex: "laborFeeAmount",
            width: 150,
            render: (_, row) => (
                <>
                    <div>&yen;{formatCurrency(row.laborFeeAmount)}</div>
                </>
            ),
        },
        {
            title: "相关税费(元)",
            dataIndex: "individualTaxAmount",
            width: 150,
            render: (_, row) => (
                <>
                    <div>&yen;{formatCurrency(row.individualTaxAmount)}</div>
                </>
            ),
        },
        {
            title: "含税劳务费(元)",
            dataIndex: "laborFee",
            width: 150,
            render: (_, row) => (
                <>
                    {<div>&yen;{formatCurrency(parseFloat(row.laborFeeAmount) + parseFloat(row.individualTaxAmount))}</div>  }
                </>
            ),
        },
        {
            title: "申请人",
            dataIndex: "batchRequestBy",
            width: 120,
            render: (_, row) => (
                <>
                    {row.batchRequestBy? <div>{row.batchRequestBy}</div> : <div>-</div>  }
                </>
            ),
        },
        {
            title: "审核状态",
            dataIndex: "examinedStatus",
            key: "examinedStatus",
            width: 120,
            render: (_, row) => (
                <>
                    {row.examinedStatus===0 && <Text type="warning">待审批</Text>}
                    {row.examinedStatus===1 && <Text type="success">同意</Text>}
                </>
            ),
        },
        {
            title: "付款状态",
            dataIndex: "payedStatus",
            key: "payedStatus",
            width: 120,
            render: (_, row) => (
                <>
                    {row.payedStatus===0 && <Text type="warning">待付款</Text>}
                    {row.payedStatus===1 && <Text type="success">付款中</Text>}
                    {row.payedStatus===2 && <Text type="success">全部付款</Text>}
                </>
            ),
        },
        {
            title: "付款进度",
            dataIndex: "payedCount",
            key: "payedCount",
            width: 120,
            render: (_, row) => (
                <>
                    {row.payedCount}/{row.batchCount}人
                </>
            ),
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            width: 200,
            render: (_, row) => (
                <>
                    { (isLaborFeePayment || isLaborFeeManager) &&
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoFinanceLaborFeePayment(row.batchNo)}
                        >
                            查看明细
                        </Button>
                    }
                </>
            ),
        },
    ];

    // 处理金额
    const formatCurrency = value => {
        let result = '';

        const f = parseFloat(value) || 0.00;
        const parts = f.toFixed(2).split('.');
        result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

        return result;
    };

    const onFinish = values => {
        setSearchParams({ ...values });
    };

    // 重置
    const onReset = () => {
        form.resetFields();
        setSearchParams({});
    };

    const getLaborFeePaymentManageList = async () => {
        setLoading(true);
        let newValues = {
            ...form.getFieldsValue()
        };
        const res = await fetchLaborFeePaymentManageList({
            ...newValues,
            projectCode: projectCode,
            projectId: projectId,
        });
        if (res.status_code === 200) {
            setDatas(res.data || []);
        }
        setLoading(false);
    };

    const gotoFinanceLaborFeePayment = batchNo => {
        navigate(`/finance/laborfee/projects/${projectCode}-${projectId}/batches/${batchNo}`);
    }

    // 获取会议信息
    const getConferenceInfo = async projectId => {
        const res = await conferenceOnceApi({id: projectId});
        if (res.status_code === 200 && res.data) {
            setConference(res.data);
        }
    };

    useEffect(() => {
        if (!!projectId) {
            getConferenceInfo(projectId);
        }
    }, []);

    useEffect(() => {
        getLaborFeePaymentManageList();
    }, [searchParams, projectId]);

    return <>
        <div className="page-title">
            <Link to={`/finance/laborfee/projects`} className="back"><LeftOutlined /></Link>
            <span className="current-title">劳务费付款管理</span>
            <span className="current-subtitle">{conference?.name}</span>
        </div>

        <Content className="main-content-box">

            <div className="filter-form-box">
                <Form
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    {...filterFormItemLayout}
                >
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="examinedStatus" label="审核状态:">
                                <Select
                                    placeholder="选择审批状态"
                                    allowClear
                                    options={approvalOptions}
                                    fieldNames={{ value: 'value', label: 'label' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="payedStatus" label="付款状态:">
                                <Select
                                    placeholder="选择付款状态"
                                    allowClear
                                    options={paymentOptions}
                                    fieldNames={{ value: 'value', label: 'label' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="main-content-body">
                <Table
                    columns={columns}
                    dataSource={datas}
                    loading={loading}
                    pagination={null}
                />
            </div>

        </Content>
    </>;
}
