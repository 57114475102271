import { Modal, Form, Space, Input, Select, Button } from 'antd';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { drawerFormItemLayout } from '@utils/property';
import { useParams } from 'react-router-dom';
import config from '@config';
import authRefusalReasons from '@utils/authRefusalReasons';
import { useWatch } from 'antd/lib/form/Form';

// 驳回原因
const ViewAuthInfoDrawer = ({
    visibleAuthDismissedModal,
    setVisibleAuthDismissedModal,
    setAuthDismissedReson
}) => {
    const params = useParams();
    const [form] = Form.useForm();
    const reasons = Form.useWatch('reasons', form);

    const closeAuthDismissedModal = () => {
        form.resetFields();
        setVisibleAuthDismissedModal(false);
    }

    const submitAuthDismissed = () => {
        form.validateFields().then((values) => {
            setAuthDismissedReson(values.failReason);
        }).catch((err) => {
            console.log('提交失败', err);
        });
    }

    useEffect(() => {
        if (reasons) { 
            form.setFieldValue('failReason', reasons);
        }
     }, [reasons]);

    return (
        <Modal
            width={600}
            title="驳回申请"
            open={visibleAuthDismissedModal}
            onClose={() => setVisibleAuthDismissedModal(false)}
            layout="horizontal"
            maskClosable={false}
            footer={
                <div style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeAuthDismissedModal}>取消</Button>
                        <Button type="primary" onClick={submitAuthDismissed}>确定</Button>
                    </Space>
                </div>
            }
        >
            <div className="conference-form">
                <Form
                    {...drawerFormItemLayout}
                    layout="horizontal"
                    name="basic"
                    form={form}
                    initialValues={{ hidden: 1 }}
                    autoComplete="off"
                >

                    <Form.Item
                        label="原因库"
                        name="reasons"
                    >
                        <Select allowClear placeholder="选择原因库" >
                            {authRefusalReasons && authRefusalReasons.map(item => {
                                return <Select.Option value={item}>{item}</Select.Option>
                            })}
                        </Select>

                    </Form.Item>

                    <Form.Item label="驳回原因" name="failReason" rules={[{required: true, message: '请输入驳回原因'}]}>
                        <Input maxLength={300} placeholder="请输入驳回原因" />
                    </Form.Item>

                </Form>
            </div>

        </Modal>
    );
};
export default ViewAuthInfoDrawer;
