import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, DatePicker, Button, Space, Select } from 'antd';
const { RangePicker } = DatePicker;

export default function EnterpriseFilterForm({
    handleFilterFinish, updatedByStaffId
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (values) => {
        values.createdAtBegin = timePicker[0];
        values.createdAtEnd = timePicker[1];
        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            createdByStaffId: undefined,
            createdAtBegin: undefined,
            createdAtEnd: undefined
        }
        handleFilterFinish(values);
    };
    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="企业名称" name="name">
                        <Input allowClear placeholder="输入企业名称" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="操作人" name="createdByStaffId">
                        <Select allowClear placeholder="请选择操作人">
                            {updatedByStaffId && updatedByStaffId.map(item => {
                                return (
                                    <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="添加时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
