import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import { Alert, Button, Checkbox, Col, Dropdown, Empty, Layout, message, Modal, Radio, Row, Space, Spin, Tabs, Tag, Tooltip, TreeSelect, Typography } from 'antd';
import { ArrowLeftOutlined, ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import './AgendaList.less';
import AddConferenceDrawer from './components/AddConferenceDrawer';
import AddAgendaDrawer from './components/AddAgendaDrawer';
import AgendaTemplateDrawer from './components/AgendaTemplateDrawer';
import { changeAgendaAndGuestsStatusApi, changeConferenceHallStatusApi, deleteAgendaApi, deleteConferenceApi, getAgendasListApi, getConferenceListApi, getConferencePlacesOverview, getOrganisationinfoApi, pdfExportDownloadApi, sortAgendaApi, sortConferenceApi } from '@services/Agenda';
import { conferenceOnceApi, deleteConferenceCache } from '@services/Conference';
import { getAllEnterpriseApi, getAllStaffs } from '@services/Enterprise';
import PreviewAgendasDrawer from './components/PreviewAgendasDrawer';
import { get } from '@/core/request';
import config from '@config';
import moment from 'moment';
import { convertDateTimeRangeToLocalText, dateTimeFormat } from '@utils/utils';
import html2canvas from 'html2canvas';

const { Content } = Layout;

export default function AgendaList() {
    const params = useParams();
    let conferenceId = params?.id || 0; // 会议项目 ID
    conferenceId = parseInt(conferenceId, 10) || 0;
    // console.log('conferenceId', conferenceId);

    const [conference, setConference] = useState(null);
    const [laborFeeConfirmed, setLaborFeeConfirmed] = useState(false);

    // 新增/编辑会议（场次）Drawer
    const [currentConferenceHall, setCurrentConferenceHall] = useState(null); // 当前会议
    const [visibleAddConferenceHallDrawer, setVisibleAddConferenceHallDrawer] = useState(false);
    const [conferenceDetail, setConferenceDetail] = useState(null);
    const [conferenceData, setConferenceData] = useState({});
    const [dateList, setDateList] = useState([]);
    const currentDate = useRef(null);
    const [conferenceHallTreeData, setConferenceHallTreeData] = useState([]);
    const [tabActiveKey, setTabActiveKey] = useState('');

    const [staffs, setStaffs] = useState([]);
    const [enterprisePerson, setEnterprisePerson] = useState([]);
    const [sponsorHosName, setSponsorHosName] = useState('');

    // 会议地点一览
    const [placesOverviewData, setPlacesOverviewData] = useState(null);
    const [visiblePlacesOverviewModal, setVisiblePlacesOverviewModal] = useState(false);
    const [loadingPlacesOverview, setLoadingPlacesOverview] = useState(false);
    const [specInstructionEnabled, setSpecInstructionEnabled] = useState(true);

    const [wordExportLoading, setWordExportLoading] = useState(false);

    const onChangeSpecInstruction = e => {
        console.log(e.target.checked);
        setSpecInstructionEnabled(e.target.checked);
    };

    const getPlacesOverview = async () => {
        setLoadingPlacesOverview(true);
        const res = await getConferencePlacesOverview({ conferenceId });
        if (res.status_code === 200) {
            console.log('PlacesOverviewData', res.data);
            const placesData = preparePlacesOverviewData(res.data);
            setPlacesOverviewData(placesData);
            setLoadingPlacesOverview(false);
        } else {
            setLoadingPlacesOverview(false);
        }
    };

    const preparePlacesOverviewData = placesData => {
        console.log('placesData', placesData);
        const data = [...placesData];

        const result = {
            dates: [],
            places: [],
        };

        data.forEach(place => {
            let placeHallNum = 0; // 某个会议地点会议场次的数量
            place.agendas.forEach(agenda => {
                placeHallNum += agenda.halls.filter(h => h.hidden === '0').length;
                if (result.dates.some(d => d.date === agenda.day)) { // 日期已存在
                    result.dates = result.dates.map(d => {
                        if (d.date === agenda.day) {
                            return { ...d, hallNum: d.hallNum + agenda.halls.filter(h => h.hidden === '0').length };
                        } else {
                            return d;
                        }
                    });
                } else { // 新日期
                    result.dates.push({
                        date: agenda.day,
                        hallNum: agenda.halls.filter(h => h.hidden !== '1').length
                    });
                }
            });
            result.places.push({ ...place, hallNum: placeHallNum });
        });

        console.log('preparePlacesOverviewData', result);
        return result;
    };

    const exportHtmlDomAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el, {useCORS: true});

        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = imageFileName;
        link.href = canvas.toDataURL('image/png', 1.0);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    const downloadPlacesOverviewExcel = () => {
        // 项目特别说明 spec：1导出项目特别说明，0不导出
        // 是否导出隐藏会议场次 hidden：0不导出隐藏，1导出隐藏
        let params = {
            conferenceId,
            spec: specInstructionEnabled ? 1 : 0,
            hidden: 0, //
        };

        get('/conference/boss/conferenceplaces/excel-export/download', params, { responseType: 'blob' })
            .then(res => {
                message.success('请稍后，正在导出');

                let blob = new Blob([res], { type: 'application/octet-stream' });
                if ('download' in document.createElement('a')) {
                    const fileNameSuffix = '_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';

                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.download = '会议信息一览_' + conference.name + fileNameSuffix;
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                } else {
                    navigator.msSaveBlob(blob);
                }

            });
    };

    const downloadPlacesOverviewImage = () => {
        const el = document.getElementById('places-overview-table-box');
        const fileName = conference.name + '.png';
        exportHtmlDomAsImage(el, fileName);
    };

    const openPlacesOverviewModal = () => {
        setVisiblePlacesOverviewModal(true);
        getPlacesOverview();
    };

    const closePlaceOverviewModal = () => {
        setVisiblePlacesOverviewModal(false);
    };

    const requestAllStaffs = async () => {
        const res = await getAllStaffs();
        if (res.status_code === 200) {
            setStaffs(res.data);
            console.log('staffs', res.data);
        }
    };

    // 企业负责人
    const requestAllEnterprisePerson = async () => {
        const res = await getAllEnterpriseApi({ conferenceId, page: 1, pageSize: 9999, role: 3 });
        if (res.status_code === 200 && res.data) {
            setEnterprisePerson(res.data.list);
        }
    };

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({ id: conferenceId });
        if (res.status_code === 200) {
            setConference(res.data);
            setLaborFeeConfirmed(!!res.data?.laborFeeConfirmedAt);
        }
    };

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
        }
    }, []);

    const showAddConferenceHallDrawer = (item) => {
        if (item) {
            setConferenceDetail(item);
        } else {
            setConferenceDetail(null);
        }
        setVisibleAddConferenceHallDrawer(true);
    };

    // 新增/编辑日程 Drawer
    const [visibleAddAgendaDrawer, setVisibleAddAgendaDrawer] = useState(false);
    const [agendasData, setAgendasData] = useState([]);
    const [agendaDetail, setAgendaDetail] = useState(null);

    const showAddAgendaDrawer = (item) => {
        if (item) {
            setAgendaDetail(item);
        } else {
            setAgendaDetail(item);
        }

        setVisibleAddAgendaDrawer(true);
    };

    // 当前选中会议
    const checkConference = hall => {
        setCurrentConferenceHall(hall);
        if (hall.sponsorOrgId) {
            getOrganisationinfoApi({ id: hall.sponsorOrgId }).then(res => {
                if (res.status_code === 200 && res.data) {
                    setSponsorHosName(res.data.hosName);
                } else {
                    setSponsorHosName('');
                }
            })
        } else {
            setSponsorHosName('');
        }
    };

    // 会议删除
    const deleteConference = id => {

        getAgendasListApi({ hallsId: id }).then(res => {
            let modalContent = (<span>是否确认删除当前会议</span>);

            if (res.status_code === 200 && res.data && res.data.length > 0) {
                modalContent = (
                    <div>
                        <span>是否确认删除当前会议及所有日程</span>
                        <br/>
                        <span style={{ color: 'red' }}>会议及会议下的所有日程都将被删除，请谨慎操作。</span>
                    </div>
                )
            }

            Modal.confirm({
                title: '提示',
                content: modalContent,
                cancelText: '取消',
                okText: '确认',
                closable: true,
                onOk: async () => {
                    const res = await deleteConferenceApi({ id });
                    if (res.status_code === 200) {
                        message.success('删除成功');
                        getConferenceList();
                    }
                }
            });
        })
    };

    // 日程删除
    const deleteAgenda = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认删除当前日程',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                const res = await deleteAgendaApi({ id });
                if (res.status_code === 200) {
                    message.success('删除成功');
                    getAgendasList(currentConferenceHall?.id);
                }
            }
        });
    };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return <Button type="text" size="small">
            <span className="anticon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-move" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
                </svg>
            </span>
        </Button>;
    });

    const SortableChildElement = SortableElement(({ subNode, paSort }) => {
        let items = [
            {
                label: '编辑会议',
                key: 'editConferenceHall',
                onClick: () => showAddConferenceHallDrawer(subNode)
            },
            {
                label: subNode.hidden ? '显示会议' : '隐藏会议',
                key: 'changeConferenceHallDisplayStatus',
                onClick: () => changeConferenceHallStatus({
                    id: subNode.id,
                    hidden: subNode.hidden ? 0 : 1
                })
            },
            {
                label: '删除会议',
                key: 'delConferenceHall',
                onClick: () => deleteConference(subNode.id),
                danger: true
            },
        ];

        const dateTimesArr = subNode.agendasTime?.split(/-(?=\d{4})/) || [];
        let dateTimeRange = '';
        if (dateTimesArr.length === 2) {
            dateTimeRange = convertDateTimeRangeToLocalText(dateTimesArr[0], dateTimesArr[1], 0);
        }

        let directors = '';
        const directorsStr = subNode.directors;
        if (!!directorsStr) {
            const directorArr = directorsStr.split(',');
            const directorNum = directorArr.length;
            if (directorNum > 0 && !isEmpty(staffs)) {
                let directorName = '';
                const directorId = Number(directorArr[0]) || 0;
                directorName = staffs.find(s => s.id === directorId)?.name || '';
                if (directorNum === 1) {
                    directors = directorName;
                }
                if (directorNum > 1) {
                    directors = `${directorName}等${directorNum}人`;
                }
            }
        }

        return <div className={`halls-hall sub-hall ${subNode.id === currentConferenceHall?.id && 'hall-selected'}`} key={subNode.id}>

            <div className="hall-info">
                <div className="hall-title-box">
                    <div className="hall-radio">
                        <Tooltip title="选择会议场次管理其下日程">
                            <Radio
                                checked={subNode.id === currentConferenceHall?.id}
                                style={{ marginLeft: 4 }}
                                onClick={() => checkConference(subNode)}
                            />
                        </Tooltip>
                    </div>

                    <div className="hall-title" title={subNode.name} onClick={() => checkConference(subNode)}>
                        <span>{subNode.name}<small className="id">YHYC#{subNode.id}</small></span>
                    </div>
                </div>
                <div className="hall-extra" onClick={() => checkConference(subNode)}>
                    {(!!dateTimeRange || !!directors) && (
                        <div className="hall-extra-info">
                            {!!dateTimeRange && <span className="time"><label>时间：</label>{dateTimeRange}</span>}
                            {!!directors && <span className="directors"><label>项目负责人：</label>{directors}</span>}
                        </div>
                    )}

                    {!!subNode?.place && (
                        <div className="hall-extra-info"><span className="place"><label>地点：</label>{subNode.place}</span></div>
                    )}
                </div>
            </div>

            <Tag
                color={!!subNode.hidden ? "warning" : "green"}
                style={{ marginLeft: 4, marginRight: 4, width: 54, textAlign: 'center' }}
                onClick={() => changeConferenceHallStatus({ id: subNode.id, hidden: subNode.hidden ? 0 : 1 })}
            >
                {!!subNode.hidden ? '隐藏中' : '显示中'}
            </Tag>

            {!laborFeeConfirmed && (
                <div className="hall-operations">
                    <Dropdown menu={{ items }} placement="bottomRight">
                        <Button size="small" type="text" className="more-operation">
                        <span role="img" aria-label="holder" className="anticon">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                 <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                             </svg>
                        </span>
                        </Button>
                    </Dropdown>

                    <SortableMainHandle/>
                </div>
            )}

        </div>;
    });

    // 左侧 - 二级会议拖拽容器
    const SortableChildContainer = SortableContainer(({ childItems, paSort }) => {
        return <div className="hall-wrap">
            {childItems?.length ? childItems.map((item, index) =>
                <SortableChildElement
                    subNode={item}
                    paSort={paSort}
                    index={index}
                    key={index}
                />
            ) : null}
        </div>;
    });

    // 显示/隐藏会议
    const changeConferenceHallStatus = async data => {
        const res = await changeConferenceHallStatusApi(data);
        if (res.status_code === 200) {
            getConferenceList();
            message.success(data.hidden ? '隐藏会议成功' : '显示会议成功');
        }
    };

    const SortableMainElement = SortableElement(({ treeNode, sort }) => {
        let items = [
            {
                label: '编辑会议',
                key: 'editConferenceHall',
                onClick: () => showAddConferenceHallDrawer(treeNode)
            },
            {
                label: treeNode.hidden ? '显示会议' : '隐藏会议',
                key: 'changeConferenceHallDisplayStatus',
                onClick: () => changeConferenceHallStatus({
                    id: treeNode.id,
                    hidden: treeNode.hidden ? 0 : 1
                })
            },
        ];
        if (!treeNode.secondHallsList || (treeNode.secondHallsList && !treeNode.secondHallsList.length)) {
            items = [
                ...items,
                {
                    label: '删除会议',
                    key: 'delConferenceHall',
                    onClick: () => deleteConference(treeNode.id),
                    danger: true
                },
            ];
        }

        const dateTimesArr = treeNode.agendasTime?.split(/-(?=\d{4})/) || [];
        let dateTimeRange = '';
        if (dateTimesArr.length === 2) {
            dateTimeRange = convertDateTimeRangeToLocalText(dateTimesArr[0], dateTimesArr[1], 0);
        }

        let directors = '';
        const directorsStr = treeNode.directors;
        if (!!directorsStr) {
            const directorArr = directorsStr.split(',');
            const directorNum = directorArr.length;
            if (directorNum > 0 && !isEmpty(staffs)) {
                let directorName = '';
                const directorId = Number(directorArr[0]) || 0;
                directorName = staffs.find(s => s.id === directorId)?.name || '';
                if (directorNum === 1) {
                    directors = directorName;
                }
                if (directorNum > 1) {
                    directors = `${directorName}等${directorNum}人`;
                }
            }
        }

        return <div key={treeNode.id}>
            <div className={`halls-hall ${treeNode.id === currentConferenceHall?.id && 'hall-selected'}`}>

                <div className="hall-info">
                    <div className="hall-title-box">
                        <div className="hall-radio">
                            {!treeNode.secondHallsList || (treeNode.secondHallsList && !treeNode.secondHallsList.length)
                                ? <Tooltip title="选择会议，管理其下日程">
                                    <Radio
                                        checked={treeNode.id === currentConferenceHall?.id}
                                        style={{ marginLeft: 4 }}
                                        onClick={() => checkConference(treeNode)}
                                    />
                                </Tooltip>
                                : null
                            }
                        </div>

                        <div className="hall-title" title={treeNode.name}>
                            <span onClick={() => checkConference(treeNode)}>{treeNode.name}<small className="id">YHYC#{treeNode.id}</small></span>
                        </div>
                    </div>
                    <div className="hall-extra" onClick={() => checkConference(treeNode)}>
                        <div className="hall-extra-info">
                            {!!dateTimeRange && <span className="time"><label>时间：</label>{dateTimeRange}</span>}
                            {!!directors && <span className="directors"><label>项目负责人：</label>{directors}</span>}
                        </div>
                        {!!treeNode?.place && (
                            <div className="hall-extra-info"><span className="place"><label>地点：</label>{treeNode.place}</span></div>
                        )}
                    </div>
                </div>

                <Tag
                    color={!!treeNode.hidden ? "warning" : "green"}
                    style={{ marginLeft: 4, marginRight: 4, width: 54, textAlign: 'center' }}
                    onClick={() => changeConferenceHallStatus({ id: treeNode.id, hidden: treeNode.hidden ? 0 : 1 })}
                >
                    {!!treeNode.hidden ? '隐藏中' : '显示中'}
                </Tag>

                <div className="hall-operations">
                    <Dropdown menu={{ items }} placement="bottomRight">
                        <Button size="small" type="text" className="more-operation">
                            <span className="anticon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                     <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                 </svg>
                            </span>
                        </Button>
                    </Dropdown>
                    <SortableMainHandle/>
                </div>

            </div>

            <SortableChildContainer
                useDragHandle
                paSort={sort}
                childItems={treeNode.secondHallsList}
                distance={10}
                onSortEnd={(res) => onSortEndChildHandler(res, treeNode.id)}
            />
        </div>;
    });

    // 左侧 - 一级会议拖拽容器
    const SortableMainContainer = SortableContainer(({ items }) => {
        // console.log('SortableMainContainer items', items);
        return <div>
            {items.map((treeNode, index) => {
                return (
                    <SortableMainElement
                        treeNode={treeNode}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    // 右侧日程拖拽容器
    const SortableRightContainer = SortableContainer(({ items }) => {
        // console.log(items);
        return <div>
            {items && items.length ? items.map((agendasItem, index) => {
                return (
                    <SortableRightElement
                        agendasItem={agendasItem}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            }) : null}
        </div>;
    });

    // 显示/隐藏日程/日程嘉宾
    const changeAgendaAndGuestsStatus = async (type, data) => {
        // TODO 需要重构，逻辑有重叠
        // 日程显示检查
        if (type === 'agenda_status') {
            const res = await changeAgendaAndGuestsStatusApi({ ...data, check: 1 });
            const msg = data?.hidden === 1 ? '隐藏日程成功' : '显示日程成功';
            if (res.status_code === 200) {
                message.success(msg);
                getAgendasList(currentConferenceHall?.id);
            } else if (res.status_code === 422) {
                Modal.confirm({
                    title: '提示',
                    icon: <ExclamationCircleOutlined/>,
                    content: `该日程下暂未添加嘉宾，确认更改为显示状态？`,
                    okText: '确认显示',
                    centered: true,
                    cancelText: '暂不显示',
                    onOk: async () => {
                        const res = await changeAgendaAndGuestsStatusApi({ ...data, check: 2 });
                        if (res.status_code === 200) {
                            message.success(msg);
                            getAgendasList(currentConferenceHall?.id);
                        }
                    }
                });
            }
        } else {
            const res = await changeAgendaAndGuestsStatusApi(data);
            if (res.status_code === 200) {
                // 提示信息
                let msg = '';
                if (type === 'agenda_status') {
                    msg = data?.hidden === 1 ? '隐藏日程成功' : '显示日程成功';
                } else if (type === 'guests_status') {
                    msg = data?.guestsHidden === 1 ? '隐藏日程嘉宾成功' : '显示日程嘉宾成功';
                }
                message.success(msg);
                getAgendasList(currentConferenceHall?.id);
            }
        }
    };

    const SortableRightElement = SortableElement(({ agendasItem, sort }) => {
        console.log('SortableRightElement agendasItem', agendasItem);
        let items = [
            {
                label: '编辑日程',
                key: 'editAgenda',
                onClick: () => showAddAgendaDrawer(agendasItem)
            },
            {
                label: agendasItem.hidden ? '显示日程' : '隐藏日程',
                key: 'changeAgendaDisplayStatus',
                onClick: () => changeAgendaAndGuestsStatus('agenda_status', {
                    id: agendasItem.id,
                    hidden: agendasItem.hidden ? 0 : 1
                })
            },
            {
                label: agendasItem.guestsHidden ? '显示日程嘉宾' : '隐藏日程嘉宾',
                key: 'changeAgendaGuestsDisplayStatus',
                onClick: () => changeAgendaAndGuestsStatus('guests_status', {
                    id: agendasItem.id,
                    guestsHidden: agendasItem.guestsHidden ? 0 : 1
                })
            },
            {
                label: '删除日程',
                key: 'deleteAgenda',
                onClick: () => deleteAgenda(agendasItem.id),
                danger: true
            }
        ];

        return <>
            <div className="agendas-agenda" key={agendasItem.id}>
                <div className="agenda-info">
                    <div className="agenda-title">
                        {agendasItem.title}
                        <Tag
                            color={!!agendasItem.hidden ? "warning" : "green"}
                            style={{ marginLeft: 10, marginRight: 0, textAlign: 'center' }}
                            onClick={() => changeAgendaAndGuestsStatus('agenda_status', {
                                id: agendasItem.id,
                                hidden: agendasItem.hidden ? 0 : 1
                            })}
                        >
                            {!!agendasItem.hidden ? '日程隐藏中' : '日程显示中'}
                        </Tag>
                        {!!(agendasItem.guestList && agendasItem.guestList.length) && (
                            <>
                                <Tag
                                    color={!!agendasItem.guestsHidden ? "warning" : "green"}
                                    style={{ marginLeft: 10, marginRight: 0, textAlign: 'center' }}
                                    onClick={() => changeAgendaAndGuestsStatus('guests_status', {
                                        id: agendasItem.id,
                                        guestsHidden: agendasItem.guestsHidden ? 0 : 1
                                    })}
                                >
                                    {!!agendasItem.guestsHidden ? '嘉宾隐藏中' : '嘉宾显示中'}
                                </Tag>
                            </>
                        )}
                    </div>
                    {!!(agendasItem.discussionList && agendasItem.discussionList.length) && (
                        agendasItem.discussionList.map((discuss, index) => (
                            <li key={index}>{discuss}</li>
                        ))
                    )}
                    <div className="agenda-foot">
                        <div className="agenda-datetime">
                            <span className="anticon" style={{ marginRight: 4 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                </svg>
                            </span>
                            {agendasItem?.startTime}&ndash;{agendasItem?.endTime}
                        </div>
                        <div className="agenda-guests">
                            {!!(agendasItem.guestList && agendasItem.guestList.length) && (
                                <>
                                    嘉宾：{agendasItem.guestList.map((guest, index) => {
                                    const invitationAcceptanceState = { 0: '未反馈', 1: '已接受', 2: '已拒绝' };
                                    const agendaGuest = agendasItem?.agendaGuestsList?.find(item => item.expertId === guest.id) || {};
                                    return <>
                                            <span
                                                className="guest"
                                                key={index}
                                                style={{ marginRight: agendaGuest.invitationAccepted > 0 ? 0 : 10, color: agendaGuest.invitationAccepted === 1 ? '#389e0d' : agendaGuest.invitationAccepted === 2 ? '#ff4d4f' : '' }}
                                            >
                                                {guest.memUsername}
                                            </span>
                                        {!isEmpty(agendaGuest) && agendaGuest.invitationAccepted > 0 && (
                                            <>
                                                <Tag color={agendaGuest.invitationAccepted === 1 ? 'green' : 'error'}>
                                                    {invitationAcceptanceState[agendaGuest.invitationAccepted]}
                                                </Tag>
                                            </>
                                        )}
                                    </>;
                                })}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {!laborFeeConfirmed && (
                    <div className="agenda-operations">
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button size="small" type="text" className="more-operation">
                            <span role="img" aria-label="holder" className="anticon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                     <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                 </svg>
                            </span>
                            </Button>
                        </Dropdown>

                        <SortableMainHandle/>
                    </div>
                )}

            </div>
        </>;
    });

    // 会议排序请求接口
    const sortConference = (data) => {
        let ids = [];
        data.forEach((item) => {
            ids.push(item.id);
            if (item.secondHallsList && item.secondHallsList.length) {
                item.secondHallsList.forEach((sec) => {
                    ids.push(sec.id);
                });
            }
        });
        sortConferenceApi({ resList: [{ ids: ids }], conferenceId });
    };

    // 一级会议排序
    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        let newData = conferenceData;
        let items = conferenceData[currentDate.current];
        newData[currentDate.current] = arrayMoveImmutable(items, oldIndex, newIndex);
        console.log(newData, '一级会议');
        setConferenceData({ ...newData });

        sortConference(newData[currentDate.current]);
    };

    // 二级会议排序
    const onSortEndChildHandler = ({ oldIndex, newIndex }, childId) => {
        let newData = conferenceData;
        let items = conferenceData[currentDate.current];
        let ind = items.findIndex(it => it.id === childId);
        let secItems = items[ind].secondHallsList;
        newData[currentDate.current][ind].secondHallsList = arrayMoveImmutable(secItems, oldIndex, newIndex);
        console.log(newData, '二级会议');
        setConferenceData({ ...newData });

        sortConference(newData[currentDate.current]);
    };

    // 日程排序
    const onSortAgendaEndHandler = ({ oldIndex, newIndex }) => {
        let res = arrayMoveImmutable(agendasData, oldIndex, newIndex);
        console.log(res, '日程');
        setAgendasData([...res]);

        let ids = res.map(item => item.id);
        sortAgendaApi({ resList: [{ ids: ids }], conferenceId });
    };

    // 会议论坛列表，用于快速定位会议（场次）
    const prepareConferenceHallTreeData = data => {
        let treeData = [];

        if (Object.keys(data) && Object.keys(data).length) {
            Object.keys(data).forEach(agendaDate => {
                const dateNode = {
                    title: agendaDate,
                    value: agendaDate,
                    selectable: false,
                    hallNum: 0,
                    children: []
                };

                const confHalls = data[agendaDate];
                confHalls && confHalls.length && confHalls.forEach(confHall => {
                    const hallNode = {
                        title: `${confHall.name} [YHYC#${confHall.id}]`,
                        value: confHall.id,
                        children: []
                    };

                    const confSubHalls = confHall.secondHallsList || [];
                    if (confSubHalls && confSubHalls.length) {
                        hallNode.selectable = false;

                        confSubHalls.forEach(subHall => {
                            dateNode.hallNum += 1;

                            hallNode.children.push({
                                title: `${subHall.name} [YHYC#${subHall.id}]`,
                                value: subHall.id
                            });
                        });
                    } else {
                        dateNode.hallNum += 1;
                    }

                    dateNode.children.push(hallNode);
                });

                treeData.push(dateNode);
            });
        }

        console.log('treeData', treeData);
        return treeData;
    };

    // 获取会议列表
    const getConferenceList = async () => {
        const res = await getConferenceListApi({ conferenceId });
        if (res.status_code === 200) {
            const agendaData = res.data;
            console.log('agendaData', agendaData);
            setConferenceData(agendaData);
            setConferenceHallTreeData(prepareConferenceHallTreeData(agendaData));

            if (!_.isEmpty(agendaData)) {
                let agendaDates = Object.keys(agendaData);
                if (!currentDate.current) {

                    if (agendaDates && agendaDates.length) {
                        currentDate.current = agendaDates[0];
                        setTabActiveKey(agendaDates[0]);
                    } else {
                        currentDate.current = null;
                        setTabActiveKey('');
                    }
                }

                console.log('agendaDates', agendaDates);
                setDateList(agendaDates);
            }
        }
    };

    // 会议tab切换
    const onChangeTabs = value => {
        currentDate.current = value;
    };

    // 查询日程列表
    const getAgendasList = async (checkId) => {
        const res = await getAgendasListApi({ hallsId: checkId });
        if (res.status_code === 200) {
            setAgendasData(res.data);
        }
    };

    const isEmptyAgenda = useMemo(() => {
        return !!(currentConferenceHall && (!agendasData || !agendasData.length));
    }, [currentConferenceHall, agendasData]);

    useEffect(() => {
        getConferenceList(); // 会议信息
        requestAllStaffs(); // 员工信息
        requestAllEnterprisePerson(); // 企业负责人信息
    }, []);

    useEffect(() => {
        if (currentConferenceHall) {
            console.log(currentConferenceHall);
            getAgendasList(currentConferenceHall.id);
        }
    }, [currentConferenceHall]);

    const [visiblePreviewAgendasDrawer, setVisiblePreviewAgendasDrawer] = useState(false);
    const previewAgendas = () => {
        setVisiblePreviewAgendasDrawer(true);
    };

    // 通过会议ID获取会议信息
    const getConferenceHallById = id => {
        let conferenceHall = null;

        Object.keys(conferenceData) && Object.keys(conferenceData).length
        && Object.keys(conferenceData).forEach(agendaDate => {
            conferenceData[agendaDate].forEach(hall => {
                if (hall.id === id) {
                    conferenceHall = hall;
                } else {
                    hall.secondHallsList && hall.secondHallsList.length && hall.secondHallsList.forEach(subHall => {
                        if (subHall.id === id) {
                            conferenceHall = subHall;
                        }
                    });
                }
            });
        });

        return conferenceHall;
    };

    // 快速定位论坛-选中论坛
    const onChangeConferenceHall = value => {
        if (!!value) {
            conferenceHallTreeData.forEach(agendaDate => {
                agendaDate.children.forEach(hall => {
                    if (hall.value === value) {
                        currentDate.current = agendaDate.value;

                        // 切换 Tab
                        setTabActiveKey(agendaDate.value);
                        console.log(agendaDate.value);
                    } else {
                        hall.children.forEach(subHall => {
                            if (subHall.value === value) {
                                currentDate.current = agendaDate.value;

                                // 切换 Tab
                                setTabActiveKey(agendaDate.value);
                                console.log(agendaDate.value);
                            }
                        });
                    }
                });
            });

            // 选中论坛
            setCurrentConferenceHall(getConferenceHallById(value));
        } else {
            // setTabActiveKey(conferenceHallTreeData[0].value);
        }

    };

    const onTabClick = key => setTabActiveKey(key);

    const clearConferenceAgendasCache = async () => {
        const data = {
            type: 'conference',
            key: `conference_${conferenceId}_user_agendas`
        };
        const res = await deleteConferenceCache(data);
        if (res?.status_code === 200) {
            message.success('更新会议官网日程成功！');
        }
    };

    const onClearConferenceAgendasCache = () => {
        if (conferenceId) {
            Modal.confirm({
                title: '更新会议官网日程',
                icon: <ExclamationCircleOutlined/>,
                width: 480,
                centered: true,
                content: <>
                    <p>为减轻性能压力，系统自动缓存数据，该功能将更新用户端数据，展现最新设定内容。</p>
                    <p><Typography.Text type="danger">会议进行期间谨慎更新！</Typography.Text></p>
                </>,
                onOk() {
                    clearConferenceAgendasCache();
                },
                cancelText: '取消，暂不更新',
                okText: '知晓，确认更新'
            });
        }
    };

    // 日程模板设置 Drawer
    const [visibleAgendaTemplateDrawer, setVisibleAgendaTemplateDrawer] = useState(false);
    // 日程下载弹框
    const [visibleDownloadAgenda, setVisibleDownloadAgenda] = useState(false);
    const [generatePrintLoading, setGeneratePrintLoading] = useState(false);
    const [showDownloadAgenda, setShowDownloadAgenda] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [printAgendaUrl, setPrintAgendaUrl] = useState('');

    const agendaTemplate = () => {
        setVisibleAgendaTemplateDrawer(true);
    };

    // 生成日程
    const generatePrintAgenda = async () => {
        if (!conference?.agendaSettings) {
            return message.error('请先设置日程模板');
        }

        setGeneratePrintLoading(true);
        const res = await pdfExportDownloadApi({ conferenceId });
        setGeneratePrintLoading(false);
        if (res.status_code === 200) {
            setShowDownloadAgenda(true);
            setPrintAgendaUrl(res.data);
        } else if (res.status_code === 400) {
            message.error(res.message);
        }
    };

    // 下载日程
    const downloadPrintAgenda = async () => {
        let url = getFileUrl(conference?.agendaPrintUrl || printAgendaUrl) + "?t=" + new Date().getTime();
        fetch(url).then(res => {
            res.blob().then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = blobUrl;
                a.download = conference?.name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
            })
        });
    };

    // 重新生成日程
    const redoGenerate = async () => {
        setShowPrintModal(true);
        const res = await pdfExportDownloadApi({ conferenceId });
        await getConferenceInfo(conferenceId);
        setShowPrintModal(false);
        if (res.status_code === 200) {
            setPrintAgendaUrl(res.data);
        }
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + '/' + url;
        }
        return null;
    };

    const exportAgendasWord = () => {
        setWordExportLoading(true);

        let params = { conferenceId };
        get('/conference/boss/agendas/word-export/agenda-download', params, { responseType: 'blob' })
            .then(res => {
                message.success('请稍后，正在导出');

                let blob = new Blob([res], { type: 'application/octet-stream' });
                if ('download' in document.createElement('a')) {
                    const fileNameSuffix = '_' + moment().format('YYYYMMDDHHmmss') + '.docx';

                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.download = '打印版日程_' + conference.name + fileNameSuffix;
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                } else {
                    navigator.msSaveBlob(blob);
                }

                setTimeout(() => setWordExportLoading(false), 2000);
            })
            .catch(err => {
                message.error('导出失败');
                setWordExportLoading(false);
            });

    };

    // 导出Excel
    const exportAgendas = (data = {}) => {
        let params = { ...data, conferenceId };

        get('/conference/boss/agendas/excel-export/download', params, { responseType: 'blob' })
            .then(res => {
                message.success('请稍后，正在导出');

                let content = res;
                let blob = new Blob([content], { type: 'application/octet-stream' });
                if ('download' in document.createElement('a')) {
                    // let fileNameSuffix = '';
                    // if (!!data && 'completed' in data) {
                    //     params = { ...params, completed: data.completed };
                    //     fileNameSuffix = !!data.completed ? '_完备日程' : '_缺失日程';
                    // }
                    const fileNameSuffix = '_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';

                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.download = '日程表格_' + conference.name + fileNameSuffix;
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                } else {
                    navigator.msSaveBlob(blob);
                }

            });
    };

    return <>
        <div className="page-title">
            <Link to="/conf/conferences" className="back"><LeftOutlined/></Link>
            <span className="current-title_conference">会议日程</span>
            <span className="current-subtitle">{conference?.name}</span>
        </div>

        <Content className="main-content-box">

            <div className="main-content-body">
                <div className="conference-header-btn">
                    <Space>
                        <Button onClick={() => openPlacesOverviewModal()}>会议信息一览</Button>
                        <Button onClick={() => onClearConferenceAgendasCache()}>更新官网日程</Button>
                    </Space>
                    <Space>
                        <Button onClick={() => previewAgendas()}>生成H5分享版日程</Button>
                        <Tooltip title="导出打印版日程 Word 文档">
                            <Button onClick={() => exportAgendasWord()}>导出打印版日程</Button>
                        </Tooltip>
                        <Tooltip title="导出全部日程 Excel 表">
                            <Button onClick={() => exportAgendas()}>导出日程表格</Button>
                        </Tooltip>
                        <Button onClick={() => agendaTemplate()}>日程模板设置</Button>
                        {/*{!!conference?.agendaPrintUrl || showDownloadAgenda*/}
                        {/*    ? <Button onClick={() => setVisibleDownloadAgenda(true)} loading={generatePrintLoading}>下载电子版日程</Button>*/}
                        {/*    : <Button onClick={() => generatePrintAgenda()} loading={generatePrintLoading}>生成电子版日程</Button>*/}
                        {/*}*/}
                    </Space>
                </div>

                <Row gutter={24} style={{ height: '100%' }}>
                    <Col span={12}>
                        <div className="conference-halls-area">

                            <div className="conference-halls-title">
                                <h2>
                                    <span style={{ verticalAlign: 'middle' }}>会议</span> {!isEmpty(conferenceHallTreeData) && Array.isArray(conferenceHallTreeData) && <small>({conferenceHallTreeData.reduce((accumulator, currentValue) => accumulator + currentValue.hallNum, 0)}场)</small>}

                                    <Tooltip color="#fa8c16" placement="right" title="所有会议论坛一经创建即生成唯一编号，如 YHYC#123，无论删除与否，该编号均不重复。">
                                        <svg style={{ color: '#fa8c16', marginLeft: 4, verticalAlign: 'middle' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                                        </svg>
                                    </Tooltip>
                                </h2>
                                <div className="btns">
                                    <Space>
                                        <TreeSelect
                                            showSearch
                                            style={{ width: 200 }}
                                            dropdownStyle={{ minWidth: 500, overflow: 'auto' }}
                                            allowClear
                                            treeDefaultExpandAll
                                            placement="bottomLeft"
                                            treeNodeFilterProp="title"
                                            onChange={onChangeConferenceHall}
                                            treeData={conferenceHallTreeData}
                                            placeholder="快速定位会议论坛"
                                        />
                                        {!laborFeeConfirmed && <Button type="primary" onClick={() => showAddConferenceHallDrawer()}>新增会议</Button>}
                                    </Space>
                                </div>
                            </div>

                            <div className="conference-halls">
                                <Tabs
                                    onChange={onChangeTabs}
                                    size="small"
                                    type="card"
                                    activeKey={tabActiveKey}
                                    onTabClick={onTabClick}
                                    items={Object.keys(conferenceData) && Object.keys(conferenceData).length
                                        ? Object.keys(conferenceData).map(item => {
                                            return {
                                                label: `${item} (${conferenceHallTreeData.find(h => h.value === item)?.hallNum}场)`,
                                                key: item,
                                                children: <div className="halls">
                                                    <SortableMainContainer
                                                        useWindowAsScrollContainer={true}
                                                        useDragHandle
                                                        distance={10}
                                                        items={conferenceData[item]}
                                                        onSortEnd={onSortEndHandler}
                                                    />
                                                </div>
                                            };
                                        })
                                        : []}
                                />
                            </div>

                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="agenda-area">
                            <div className="agendas-title">
                                <h2>日程</h2>
                                <div className="agenda-operations">
                                    {!laborFeeConfirmed && !!currentConferenceHall && <>
                                        <Button type="primary" onClick={() => showAddAgendaDrawer()}>新增日程</Button>
                                    </>}
                                </div>
                            </div>

                            {!!currentConferenceHall && <div className="current-hall"><span>{currentConferenceHall?.name} <small>YHYC#{currentConferenceHall?.id}</small></span></div>}

                            {(!!currentConferenceHall?.agendaSummary || !!currentConferenceHall?.directors || !!currentConferenceHall?.implementers || !!currentConferenceHall?.sponsorInfo) && (
                                <Alert type="warning" style={{ marginBottom: 16 }} message={(
                                    <div className="conf-extra">
                                        {!!currentConferenceHall?.agendaSummary && <div className="agenda-summary"><b>会议概要：</b>{currentConferenceHall.agendaSummary}</div>}
                                        {(!!currentConferenceHall?.directors || !!currentConferenceHall?.implementers || !!currentConferenceHall?.sponsorInfo) && (
                                            <div className="extra">
                                                {!!currentConferenceHall?.directors && <span className="directors">
                                                <b>项目负责人：</b>{currentConferenceHall.directors.split(',')
                                                    .filter(d => d !== '0')
                                                    .map(d => staffs.find(s => s.id === parseInt(d)).name)
                                                    .join('、')
                                                }
                                            </span>}
                                                {!!currentConferenceHall?.implementers && <span className="implementers">
                                                <b>项目执行人：</b>{currentConferenceHall.implementers.split(',')
                                                    .filter(d => d !== '0')
                                                    .map(i => staffs.find(s => s.id === parseInt(i)).name)
                                                    .join('、')
                                                }
                                            </span>}
                                                {!!sponsorHosName && <span className="sponsor-info">
                                                <b>赞助企业：</b>{sponsorHosName}
                                            </span>}

                                            </div>
                                        )}
                                    </div>
                                )}/>

                            )}

                            <div className="agendas">
                                {!currentConferenceHall && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span><ArrowLeftOutlined/> 请先新增并选择会议</span>}/>}

                                {isEmptyAgenda && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="该会议下暂无日程"/>}

                                {currentConferenceHall && !isEmptyAgenda
                                    ? <SortableRightContainer
                                        useWindowAsScrollContainer={true}
                                        useDragHandle
                                        distance={10}
                                        items={agendasData}
                                        onSortEnd={onSortAgendaEndHandler}
                                    />
                                    : null
                                }

                            </div>
                        </div>
                    </Col>
                </Row>

            </div>

        </Content>

        <Modal
            width={1200}
            title="会议信息一览"
            centered
            maskClosable={false}
            open={visiblePlacesOverviewModal}
            onCancel={closePlaceOverviewModal}
            footer={[
                <div className="customized-modal-footer">
                    <div className="left-col-tools">
                        <Checkbox key="spec-instruction" checked={specInstructionEnabled} onClick={onChangeSpecInstruction}>展示项目特别说明</Checkbox>
                    </div>
                    <div className="right-col-tools">
                        <Space>
                            <Button
                                key="overview-excel"
                                onClick={downloadPlacesOverviewExcel}
                                icon={
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                                            <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                        </svg>
                                    </span>
                                }
                            >下载会议信息一览表格</Button>
                            <Button
                                key="overview-image"
                                onClick={downloadPlacesOverviewImage}
                                icon={
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-image" viewBox="0 0 16 16">
                                          <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                          <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12z"/>
                                        </svg>
                                    </span>
                                }
                            >下载会议信息一览图</Button>
                        </Space>
                    </div>
                </div>
            ]}
            className="place-overview-modal"
        >
            {loadingPlacesOverview
                ? <Spin/>
                : (
                    <div className="place-overview-container">
                        {!isEmpty(placesOverviewData?.dates) && !isEmpty(placesOverviewData?.places)
                            ? (
                                <div id="places-overview-table-box">
                                    <table style={{ width: 160 + 320 * placesOverviewData.dates.length }}>
                                        <thead>
                                        <tr>
                                            <th className="place">会议地点</th>
                                            {placesOverviewData.dates.map(d => <th key={d.date} className="hall">{dateTimeFormat(d.date, 'YYYY年M月D日')} <small style={{ fontWeight: 'normal' }}>({!d.hallNum ? '暂无场次' : `${d.hallNum}场`})</small></th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {placesOverviewData.places.map((place, index) => (
                                            <tr key={place.id}>
                                                <td className="place">{place.name}<br/><small style={{ fontWeight: 'normal' }}>({!place.hallNum ? '暂无场次' : `${place.hallNum}场`})</small></td>
                                                {!isEmpty(place.agendas)
                                                    ? place.agendas.map((p, idx) => {
                                                        const halls = p.halls?.filter(h => h.hidden === '0') || [];
                                                        return (
                                                            <td key={`place_${idx}`} className="place-hall" valign="top">
                                                                {!isEmpty(halls)
                                                                    ? halls.map((h, i) => {
                                                                        const dateTimesArr = h.agendasTime?.split(/-(?=\d{4})/) || [];
                                                                        let dateTime = '';
                                                                        if (dateTimesArr.length === 2) {
                                                                            dateTime = convertDateTimeRangeToLocalText(dateTimesArr[0], dateTimesArr[1], 0);
                                                                        }
                                                                        return <div className="hall-box" key={`place_${idx}_hall_${i}`}>
                                                                            <div className="hall-time">{dateTime}</div>
                                                                            <div className="hall-name"><b>{h.hallInfo}</b> <small>{`YHYC#${h.hallId}`}</small></div>
                                                                            {(specInstructionEnabled && !!h.projectSpecificInstructions) && <div className="hall-spec-instruction" dangerouslySetInnerHTML={{__html: '项目特别说明：<br/>' + h.projectSpecificInstructions.replace(/\n/g, '<br/>')}}/>}
                                                                        </div>;
                                                                    })
                                                                    : <span className="empty">暂无数据</span>
                                                                }
                                                            </td>
                                                        );
                                                    })
                                                    : dateList.map(d => <td key={d}><span className="empty">暂无数据</span></td>)}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            )
                            : <div className="empty-overview">暂无数据</div>}
                    </div>
                )}
        </Modal>

        <Modal
            title="下载打印版日程"
            centered
            className="printAgendaModal"
            open={visibleDownloadAgenda}
            onOk={() => setVisibleDownloadAgenda(false)}
            onCancel={() => setVisibleDownloadAgenda(false)}
        >
            {showPrintModal
                ? <Spin/>
                : <div className="downloadAgendaModal">
                    <Alert
                        type="warning"
                        message={<>
                            <p>最后生成时间：{!!conference?.agendaPrintCreatedAt && moment(conference.agendaPrintCreatedAt).format('YYYY年MM月DD日 HH:mm:ss')}</p>
                            <p>操作人：{conference?.agendaPrintCreatedName}</p>
                        </>}
                    />
                    {(!!conference?.agendaPrintUrl || !!printAgendaUrl) && <p><Button type="link" onClick={() => downloadPrintAgenda()}>下载A4打印版日程</Button></p>}
                    <p>日程信息有变更，可以点击<Button type="link" onClick={() => redoGenerate()}>重新生成</Button>，以下载最新版日程</p>
                </div>
            }
        </Modal>

        <AddConferenceDrawer
            visibleAddConferenceHallDrawer={visibleAddConferenceHallDrawer}
            setVisibleAddConferenceHallDrawer={setVisibleAddConferenceHallDrawer}
            conferenceDetail={conferenceDetail}
            setConferenceDetail={setConferenceDetail}
            currentDate={currentDate.current}
            currentConferenceHall={currentConferenceHall}
            setCurrentConferenceHall={setCurrentConferenceHall}
            dateList={dateList}
            getConferenceList={getConferenceList}
            staffs={staffs}
            enterprisePerson={enterprisePerson}
        />

        <AddAgendaDrawer
            visibleAddAgendaDrawer={visibleAddAgendaDrawer}
            setVisibleAddAgendaDrawer={setVisibleAddAgendaDrawer}
            currentDate={currentDate.current}
            currentConferenceHall={currentConferenceHall}
            setConferenceDetail={setConferenceDetail}
            agendaDetail={agendaDetail}
            getAgendasList={getAgendasList}
            setAgendaDetail={setAgendaDetail}
        />

        <PreviewAgendasDrawer
            visiblePreviewAgendasDrawer={visiblePreviewAgendasDrawer}
            setVisiblePreviewAgendasDrawer={setVisiblePreviewAgendasDrawer}
            conference={conference}
            staffs={staffs}
        />

        <AgendaTemplateDrawer
            visibleAgendaTemplateDrawer={visibleAgendaTemplateDrawer}
            setVisibleAgendaTemplateDrawer={setVisibleAgendaTemplateDrawer}
            conference={conference}
            getConferenceInfo={getConferenceInfo}
        />

    </>;
}
