import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, DatePicker, Button, Space, Select } from 'antd';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';

const { RangePicker } = DatePicker;

export default function OperationLogFilterForm(props) {
    const { handleFilterFinish, updatedByStaffId } = props;

    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = values => {
        values.beginTime = timePicker[0];
        values.endTime = timePicker[1];

        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            params: undefined,
            phone: undefined,
            beginTime: undefined,
            endTime: undefined
        }
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="操作人" name="phone">
                        <Select showSearch optionFilterProp="children" allowClear placeholder="请选择操作人">
                            {updatedByStaffId && updatedByStaffId.map(item => (
                                <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="时间范围" name="timePicker">
                        <RangePicker allowClear onChange={handleChangeTime} showTime/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="请求方法" name="params">
                        <Select allowClear placeholder="选择请求方法">
                            <Select.Option value='GET'>GET</Select.Option>
                            <Select.Option value='POST'>POST</Select.Option>
                            <Select.Option value='PUT'>PUT</Select.Option>
                            <Select.Option value='PATCH'>PATCH</Select.Option>
                            <Select.Option value='DELETE'>DELETE</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
