import { get, post, patch } from '@core/http_client';

/**
*  医患通
*/
/**
* 下拉框接口
*/
// 获取下拉数据 list
export const getSelectTreeList = (params) => {
    return get(`/mc/boss/select/tree/all`, params);
};
// 项目负责人
export const getProjectLeaderList = (params) => {
    return get(`/mc/boss/select/all`, params);
}
// 企业机构
export const getEnterpriseList = (params) => {
    return get(`/mc/boss/select/keyword`, params);
}
// 专家列表
export const getExpertList = (params) => {
    return get(`/mc/boss/select/all/${params.type}/${params.projectId}`);
}

/**
* 项目管理接口
*/
// 图片上传
export const uploadfileApi = (params, option) => {
    return post(`/mc/boss/file/fileUpload`, params, option);
}
// 创建医患通项目接口
export const createProject = (params) => {
    return post('/mc/boss/project/create', params);
};

// 医患通项目详情页数据查询
export const getProjectDetail = (id) => {
    return get(`/mc/boss/project/detail/${id}`);
};

// 编辑医患通项目接口
export const editProject = (params) => {
    return post('/mc/boss/project/edit', params);
}

// 查询医患通项目列表接口
export const getMedProjectList = (params) => {
    return post(`/mc/boss/project/pageList`, params);
}

/**
 * 更新项目上下线状态
 *  0-待发布 1-上线 2-下线
 * 更新项目测试状态
 *  0-否  1-是
 */
export const updateProjectState = (id, params) => {
    return patch(`/mc/boss/project/update/${id}`, params);
}

/**
 * 问题管理
 */
// 问题列表
export const getQuestionList = (params) => {
    return post(`/mc/boss/question/pageList`, params);
}

// 问题上下架
export const handleChangeQuestionState = (params) => {
    return get(`/mc/boss/question/updateState/${params.id}/${params.state}`);
}

// 问题删除
export const deleteQuestion = (id) => {
    return get(`/mc/boss/question/delete/${id}`);
}

/**
 * 回答管理
 */
// 查询问答列表
export const getQaList = (params) => {
    return post(`/mc/boss/qa/pageList`, params);
}

// 问答详情页数据查询
export const getQaDetail = (id) => {
    return get(`/mc/boss/qa/detail/${id}`);
}

// 问答人员分配
export const handleAllocate = (params) => {
    return post(`/mc/boss/qa/specialist/allocate`, params);
}

// 问答审核
export const qaApprove = (params) => {
    return post(`/mc/boss/qa/aduit`, params);
}

/**
 * 人员管理
 */
// 人员管理列表
export const getUserList = (params) => {
    return post(`/mc/boss/user/pageList`, params);
}

// 创建人员
export const createUser = (params) => {
    return post(`/mc/boss/user/create`, params);
}

// 编辑人员
export const editUser = (params) => {
    return post(`/mc/boss/user/edit`, params);
}

// 删除人员
export const deleteUser = (id) => {
    return get(`/mc/boss/user/delete/${id}`);
}

// 获取人员详情
export const getUserDetail = (id) => {
    return get(`/mc/boss/user/detail/${id}`);
}

// 导入人员数据
export const userImport = (params) => {
    return post(`/mc/boss/user/import/file`, params);
}

// 下载模板
export const handleDownloadFile = () => {
    return get(`/mc/boss/file/download/model/1`);
}

/**
 * 劳务管理
 */
// 批次劳务管理列表
export const getFeeBatchList = (params) => {
    return post(`/mc/boss/fee/batch/pageList`, params);
}

// 劳务批次人员列表
export const getFeeBatchUserList = (params) => {
    return post(`/mc/boss/fee/batch/user/query`, params);
}

// 生成劳务批次
export const createFeeBatch = (params) => {
    return post(`/mc/boss/fee/batch/generate`, params);
}

// 人员问答及审核统计列表
export const queryFeeBatchDetailList = (params) => {
    return post(`/mc/boss/fee/batch/detailList`, params);
}

// 详情页 人员问答
export const getFeeBatchDetail = (params) => {
    return post(`/mc/boss/fee/batch/qaList`, params);
}

// 更新费用批次
// 2 已付款   3 已失效
export const updateFeeBatchState = (params) => {
    return post(`/mc/boss/fee/batch/updateState/${params.id}/${params.state}`, params);
}

// 批次列表、详情页面导出
export const handleExportData = (params) => {
    return get(`/mc/boss/fee/batch/download/${params.id}`, params);
}
