import React, { Fragment, useCallback, useEffect, useState, useRef, useMemo, useImperativeHandle, forwardRef } from "react";
import { Input, Modal, Form, Radio, Space, Button, Select, message } from "antd";
import { CloseOutlined, PlusCircleOutlined, PlusSquareOutlined, PlusOutlined } from "@ant-design/icons";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import "./index.less";
import MatrixContent from "../MatrixContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const { Item } = Form;
const { TextArea } = Input;

const LabelCom = ({ require, title }) => {
    return (
        <div
            className="add_title_wrap"
            style={{
                marginRight: "10px",
                borderRadius: "2px",
                fontSize: "12px",
            }}
        >
            <p
                style={{
                    maxWidth: "50px",
                    whiteSpace: "normal",
                    margin: "0",
                    padding: "2px 5px",
                    textAlign: "center",
                }}
            >
                {require ? <span style={{ color: "red", marginRight: "5px" }}>*</span> : null}
                {title}
            </p>
        </div>
    );
};

const AddQuestionModal = ({ isModalVisiable, onClose, title, onOk, defaultValue }, ref) => {
    const countRef = useRef(5);

    const formRef = useRef();
    const [form] = Form.useForm();
    const [curTab, setCurTab] = useState("1");

    // 光标选中位置
    const [seleteStart, setSeleteStart] = useState(0);
    const [seleteEnd, setSeleteEnd] = useState(0);

    // 是否处于加载状态
    const [isLoading, setIsLoading] = useState(false);

    // 显示
    const [showText, setShowText] = useState("");

    const [bodyValue, setBodyValue] = useState(defaultValue?.body);

    const [moreShow, setMoreShow] = useState("");

    const [answerArr, setAnswerArr] = useState([{ id: 1 }, { id: 2 }]);
    const [scaleArr, setScaleArr] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]);
    const [hasOther, setHasOther] = useState(false);

    const [colValue, setColValue] = useState([]);
    const [rowValue, setRowValue] = useState([]);

    const [moreOpen, setMoreOpen] = useState(false);
    const quillRef = useRef(null);

    const fillBlank = () => {
        let quill = quillRef?.current?.getEditor(); //获取到编辑器本身
        const cursorPosition = quill.getSelection().index; //获取当前光标位置
        let position = cursorPosition ? cursorPosition : 0;
        quill.insertText(position, "______");
        quill.setSelection(cursorPosition + 6); //光标新位置
    };

    const CustomButton = () => <span className="iconfont">插入填空符</span>;

    const CustomToolbar = useCallback(() => {
        return (
            <div id="moreToolbar" className="customize_toolbar">
                <button className="ql-bold"></button>
                <button className="ql-underline"></button>
                <select className="ql-color" defaultValue={[]} onChange={(e) => e.persist()}></select>
                <button className="ql-insertStar ">
                    <CustomButton />
                </button>
            </div>
        );
    }, []);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                    ],
                ],
            },
        };
    }, []);

    const moremodules = useMemo(() => {
        return {
            toolbar: {
                container: `#moreToolbar`,
                handlers: {
                    insertStar: fillBlank,
                },
            },
        };
    }, []);

    const resetData = () => {
        form.resetFields();
        setHasOther(false);
        setCurTab("1");
        countRef.current = 5;
        setAnswerArr([{ id: 1 }, { id: 2 }]);
        setScaleArr([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]);
        setIsLoading(false);
    };

    const onBtnOk = () => {
        form.setFieldValue("qesTitle", quillRef.current.value);
        form.validateFields().then((res) => {
            if(!validate()) return
            else{
                let pa = {
                    body: quillRef.current.value,
                    questionTypeId: res.qesType,
                    title: res.qesName,
                };
                let arr = answerArr.map((item) => ({
                    content: form.getFieldValue(`options_${item.id}`),
                }));
                if (hasOther) arr.push({ content: "其他______", isBlank: 1 });
                if (["1", "2", "9", "13"].includes(res.qesType)) {
                    if(["13"].includes(res.qesType)){
                        let select = arr
                        select = select.filter(item => item.isBlank!==1)
                        if(select.length<2){
                            message.warning('最少添加两个选项')
                            return
                        }else{
                            pa.sampleOptionList = select;
                        }
                    }else{
                        pa.sampleOptionList = arr;
                    }
                }

                if (res.qesType === "3") {
                    pa.sampleOptionList = [{ content: form.getFieldValue("success_op") }, { content: form.getFieldValue("error_op") }];
                }
                if (res.qesType === "8") {
                    let scale = scaleArr.map((item) => ({
                        content: item.id,
                    }));
                    pa.sampleOptionList = scale;
                }
                if (res.qesType === "10") {
                    let col = Object.values(form.getFieldValue("colName"));
                    let row = Object.values(form.getFieldValue("rowName"));
                    pa.sampleOptionList = [{ content: JSON.stringify([[...col], [...row]]), isBlank: 0 }];
                }
                setIsLoading(true);
                onOk(pa, resetData);
            }
        });
    };

    const onBtnCancel = () => {
        resetData();
        onClose();
    };

    const onDelOptionsClick = (ind) => {
        if (answerArr.length <= 2 && curTab!=='13') {
            message.error("不可以再删除了!");
            return;
        }
        let arr = answerArr;
        arr.splice(ind, 1);
        setAnswerArr(arr.slice());
    };

    const onAddOptionsClick = () => {
        form.setFieldValue("qesTitle", quillRef.current.value);
        let arr = answerArr;
        while (answerArr.some((item) => item.id === countRef.current)) countRef.current++;
        arr.push({
            id: countRef.current++,
        });
        setAnswerArr(arr.slice());
    };

    const onOtherClick = () => {
        form.setFieldValue("qesTitle", quillRef.current.value);
        setHasOther(true);
    };

    const formaterShowText = (val) => {
        let arr = val.split(/(\_+)/g);
        arr = arr.map((item) => {
            if (/\_+/.test(item)) {
                return <Input maxLength={item.length} style={{ width: 18 * item.length }} />;
            }
            return item;
        });
        setShowText(arr);
    };

    const onInsetClick = () => {
        let value = form.getFieldValue("qesTitle") || "";
        if (value.length + 6 > 200) return;
        let arr = value.split("");
        arr.splice(seleteStart, seleteEnd - seleteStart, "______");
        form.setFieldValue("qesTitle", arr.join(""));

        formaterShowText(arr.join(""));
    };

    const onTextareaChange = () => {
        if (!quillRef.current) return;
        form.setFieldValue("qesTitle", quillRef.current.value);
        setBodyValue(quillRef.current.value);
        setMoreShow(quillRef.current.value);
    };

    const onformReach = () => {
        if (!quillRef.current) return;
        form.setFieldValue("qesTitle", quillRef.current.value);
        setBodyValue(quillRef.current.value);
    };
    const onTextSelete = (e) => {
        setSeleteStart(e.target.selectionStart);
        setSeleteEnd(e.target.selectionEnd);
        formaterShowText(e.target.value);
    };

    const onAddOptionsModalClick = () => {
        let keys = Object.keys(form.getFieldsValue()).filter(item => item.includes('options_'))||[];
        let values = keys.map(item=>{return {id:Number(item.split('_')[1]),content:form.getFieldValue(item),isBlank:0}});
        setAnswerArr([...values]);
        setMoreOpen(true);
    };

    const onAnswerOk = () => {
        countRef.current++
        let resOld = [...answerArr];
        let str = form.getFieldsValue().opinion + "";
        if (!str) {
            message.info("未输入内容");
            return;
        }
        if (str === "undefined") {
            message.info("未输入内容");
            return;
        }
        let arr = str.split("\n");
        let bool = true,
            index = 0,
            newArr = [];
        arr.forEach((item, ind) => {
            if (!bool) return;
            if (!item) return;
            newArr.push(item);
        });
        let filArr = arr.filter((it) => it !== "");
        console.log(resOld);
        let idArr = resOld.concat(filArr);
        console.log(idArr,countRef.current);
        form.setFieldValue("qesTitle", quillRef.current.value);
        // while (answerArr.some((item) => item.id === countRef.current)) countRef.current++;
        let id
        if(answerArr.length > 0){
            id = answerArr[answerArr.length - 1].id;
            console.log(id);
        }else{
            id = 0
        }
        let final = filArr.map((item, index) => {
            console.log(countRef.current,idArr.length,index);
            return {
                id: id + index + 1,
                content: item,
                isBlank: 0,
            };
        });
        let resArr = resOld.concat(final);
        for (let i = 0; i < resArr.length; i++) {
            for (let j = i + 1; j < resArr.length; j++) {
                if (resArr[i].content === resArr[j].content) {
                    if (i < resOld.length) {
                        message.warning("和已添加内容重复，请校验后提交");
                        return;
                    } else {
                        message.warning("有重复选项，请校验后提交");
                        return;
                    }
                }
            }
        }
        if (resArr.length > 1000) {
            message.warning("最多支持1000个选项");
            return;
        }
        resArr.map((item) => {
            form.setFieldValue(`options_${item.id}`, item.content);
        });
        setMoreOpen(false);
        form.setFieldValue('opinion','')
        setAnswerArr(resArr);
    };

    useImperativeHandle(ref, () => ({
        quillRef,
        form,
    }));

    const validate = () => {
        let keys = Object.keys(form.getFieldsValue()).filter(item => item.includes('options_'))||[];
        let values = keys.map(item=>form.getFieldValue(item))
        for (let i = 0; i < values.length; i++) {
            for (let j = i + 1; j < values.length; j++) {
                if (values[i] === values[j]) {
                        message.warning("有重复选项，请校验后提交");
                        return false;
                }
            }
        }
        return true;
    };

    const SortHandleItem = SortableHandle(() => {
        return (
            <Button type="link">
                <span className="dragicon" style={{ color: "#333" }}>
                    &#xe636;
                </span>
            </Button>
        );
    });

    const SortItem = SortableElement(({ childData, ind, type }) => {
        return (
            <>
            {type === '13' ? (
                <>
                <div className="selete_question_item_wrap">
                    <Item  name={`options_${childData.id}`} rules={[{ required: true, message: "请输入题目名称" }]}>
                        <Input className="selete-item" maxLength={200}  style={{ flex: 1 }} bordered={false} placeholder="请添加选项"
                        onBlur={validate}
                        />
                    </Item>
                    <div className="selete-action">
                    <SortHandleItem />
                    <CloseOutlined className="close_icon" onClick={() => onDelOptionsClick(ind)} />
                    </div>
                </div>
                </>
            ) : (
                <div className="selete_item_wrap">
                    <Item name={`options_${childData.id}`} rules={[{ required: true, message: "请输入题目名称" }]}>
                        <Input maxLength={200} showCount style={{ flex: 1 }} />
                    </Item>
                    <SortHandleItem />
                    <CloseOutlined className="close_icon" onClick={() => onDelOptionsClick(ind)} />
                </div>
            )}
            </>
        );
    });

    const SortContainer = SortableContainer(({ items ,type}) => (
        <div className="selete_container">
            {items.map((item, index) => (
                <SortItem childData={item} ind={index} index={index} key={index} type={type}/>
            ))}
        </div>
    ));

    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        setAnswerArr(arrayMoveImmutable(answerArr, oldIndex, newIndex));
    };

    const changeCurTab = (e) =>  {
        if(answerArr.length===0&&e.target.value!=='13'){
            setAnswerArr([{ id: 1 }, { id: 2 }]);
        }else {
            let keys = Object.keys(form.getFieldsValue()).filter(item => item.includes('options_'))
            let value = keys.map(item =>form.getFieldValue(item))
            if(value.every(item =>(item===undefined||item===''))){
                setAnswerArr([]);
            }
        }
        setCurTab(e.target.value);
    }

    useEffect(() => {
        if (title === "编辑题目" && defaultValue && isModalVisiable) {
            console.log(defaultValue);
            let arr = defaultValue?.questionOptionsList || [],
                hasOther = arr.some((item) => item.isBlank === 1);
            if (hasOther) arr.pop();
            setHasOther(hasOther);
            setCurTab(String(defaultValue?.questionTypeId) || "1");
            form.setFieldsValue({
                qesName: defaultValue?.title || "",
                qesType: String(defaultValue?.questionTypeId) || "1",
                qesTitle: defaultValue?.body || "",
            });
            setBodyValue(defaultValue?.body);
            // 单选多选
            if ([1, 2, 9, 13].includes(defaultValue?.questionTypeId)) {
                arr.forEach((item) => {
                    form.setFieldValue(`options_${item.id}`, item.content);
                });
                setAnswerArr(arr);
            }
            // 判断
            if (defaultValue?.questionTypeId === 3) {
                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    success_op: arr[0]?.content,
                    error_op: arr[1]?.content,
                });
            }
            // 多项填空
            if (defaultValue?.questionTypeId === 5) {
                setMoreShow(defaultValue?.body);
            }
        } else if (title !== "编辑题目") {
            setBodyValue("");
        }
    }, [defaultValue, title, isModalVisiable]);

    useEffect(() => {
        console.log(form.getFieldValue("colName"), form.getFieldValue("rowName"));
    }, [colValue, rowValue]);

    return (
        <>
            <Modal open={isModalVisiable} onCancel={onBtnCancel} onOk={onBtnOk} title={title} className="question_modal_wrap" confirmLoading={isLoading}>
                <Form
                    name="question"
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 21 }}
                    initialValues={{
                        qesName: "",
                        qesType: "1",
                        success_op: "正确",
                        error_op: "错误",
                    }}
                    autoComplete="off"
                    form={form}
                    ref={formRef}
                >
                    <Item name="qesName" rules={[{ required: true, message: "请输入题目名称" }]} label="题目名称" wrapperCol={{ span: 6 }}>
                        <Input type="text" maxLength={5} showCount placeholder="请输入题目名称" />
                    </Item>
                    <Item name="qesType" rules={[{ required: false, message: "请选择题型" }]} label="选择题型">
                        <Radio.Group
                            onChange={(e) =>changeCurTab(e)}
                        >
                            <Space className="radio_group_wrap">
                                <Radio.Button value="1">单选题</Radio.Button>
                                <Radio.Button value="2">多选题</Radio.Button>
                                <Radio.Button value="3">判断题</Radio.Button>
                                <Radio.Button value="4">单项填空</Radio.Button>
                                <Radio.Button value="6">问答题</Radio.Button>
                                <Radio.Button value="5">多项填空</Radio.Button>
                                <Radio.Button value="8">量表题</Radio.Button>
                                <Radio.Button value="9">排序题</Radio.Button>
                                <Radio.Button value="10">矩阵填空</Radio.Button>
                                <Radio.Button value="11">文件收集</Radio.Button>
                                <Radio.Button value="12">电子签名</Radio.Button>
                                <Radio.Button value="13">选择列表</Radio.Button>
                            </Space>
                        </Radio.Group>
                    </Item>
                    <Item
                        // colon={false}
                        name="qesTitle"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: async (rule, value) => {
                                    if (quillRef.current.value === "") {
                                        throw "请输入题干内容";
                                    } else if (quillRef.current.value === "<p><br></p>") {
                                        throw "请输入题干内容";
                                    }
                                },
                            },
                        ]}
                        // label={<LabelCom require={true} title='题干'></LabelCom>}
                        label="题干"
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {curTab === "5" ? (
                                // (
                                //     <Button
                                //         size="small"
                                //         className="small_size_font_wrap"
                                //         onClick={onInsetClick}
                                //     >
                                //         插入一个填空符
                                //     </Button>
                                // )
                                <>
                                    <CustomToolbar />
                                    {/* <TextArea rows={5} onSelect={onTextSelete} onChange={onTextareaChange} maxLength={200} showCount></TextArea> */}
                                    <ReactQuill theme="snow" ref={quillRef} modules={moremodules} placeholder="请输入问题" defaultValue={defaultValue?.body || ""} value={bodyValue} onChange={() => onTextareaChange()} />
                                    <span className="small_size_font_wrap">插入一个填空符支持输入六个中文字符，请根据需要插入合适数量的填空符</span>
                                </>
                            ) : (
                                <>
                                    {/* <TextArea rows={5} onSelect={onTextSelete} onChange={onTextareaChange} maxLength={200} showCount></TextArea> */}
                                    <ReactQuill theme="snow" ref={quillRef} modules={modules} placeholder="请输入问题" defaultValue={defaultValue?.body || ""} value={bodyValue} onChange={() => onformReach()} />
                                </>
                            )}
                        </Space>
                    </Item>
                    {curTab === "1" || curTab === "2" || curTab === "13" ? (
                        <Item
                            // colon={false}
                            required={true}
                            // label={<LabelCom require={true} title='选项'></LabelCom>}
                            label="选项"
                        >
                            {curTab === "13" && <Select open={false} placeholder={"请选择"} />}
                            <div className={curTab === "13" ? `select-scroll` : null}>
                                {answerArr.length ? <SortContainer useDragHandle distance={10} items={answerArr} onSortEnd={onSortEndHandler} type={curTab} /> :
                                curTab === "13" && <span style={{color:"#999"}}> 请添加选项 </span>
                                }
                                {hasOther&&curTab !== "13" ? (
                                    <div className="selete_item_wrap">
                                        <Input disabled value={"其他______"} style={{ flex: 1 }} />
                                        <Button type="link" style={{ opacity: 0 }}>
                                            <span className="dragicon" style={{ color: "#1890ff" }}>
                                                &#xe636;
                                            </span>
                                        </Button>
                                        <CloseOutlined className="close_icon" onClick={() => setHasOther(false)} />
                                    </div>
                                ) : null}
                            </div>
                            <Space>
                                {curTab !== "13" ? (
                                    <Button type="link" onClick={onAddOptionsClick}>
                                        <PlusSquareOutlined />
                                        添加选项
                                    </Button>
                                ) : (
                                    <Button type="link" onClick={onAddOptionsModalClick}>
                                        <PlusSquareOutlined />
                                        添加选项
                                    </Button>
                                )}

                                {curTab !== "13" && (
                                    <Button type="link" onClick={onOtherClick}>
                                        <PlusCircleOutlined />
                                        添加其他
                                    </Button>
                                )}
                            </Space>
                        </Item>
                    ) : null}
                    {curTab === "3" ? (
                        <Item
                            // colon={false}
                            required={true}
                            // label={<LabelCom require={true} title='选项'></LabelCom>}
                            label="选项"
                        >
                            <Space direction="vertical" size={16} className="no_form_item_mab" style={{ width: "100%" }}>
                                <Item name="success_op">
                                    <Input maxLength={200} showCount defaultValue="正确"></Input>
                                </Item>
                                <Item name="error_op">
                                    <Input maxLength={200} showCount defaultValue="错误"></Input>
                                </Item>
                            </Space>
                        </Item>
                    ) : null}
                    {curTab === "5" ? (
                        <Item
                            // colon={false}
                            className="more_title_wrap"
                            // label={<LabelCom require={false} title='用户端预览'></LabelCom>}
                            label="用户端预览"
                        >
                            <div
                                className="more_title_content"
                                dangerouslySetInnerHTML={{
                                    __html: moreShow ? moreShow : null,
                                }}
                            >
                                {/* {showText?.length
                                ? showText.map((item, index) => (
                                      <Fragment key={index}>{item}</Fragment>
                                  ))
                                : null}
                                 */}
                            </div>
                        </Item>
                    ) : null}

                    {curTab === "8" ? (
                        <Item
                            // colon={false}
                            required={true}
                            // label={<LabelCom require={true} title='选项'></LabelCom>}
                            label="内容"
                        >
                            <div className="fill-answer">
                                <div className="scale-rating ratingmod6">
                                    <ul className="onscore">
                                        <li className="on6">1</li>
                                        <li className="on6">2</li>
                                        <li className="on6">3</li>
                                        <li className="on6">4</li>
                                        <li className="off6">5</li>
                                    </ul>
                                    <dl>
                                        <dd>很不满意</dd>
                                        <dd>很满意</dd>
                                    </dl>
                                </div>
                            </div>
                        </Item>
                    ) : null}
                    {curTab === "9" ? (
                        <Item
                            // colon={false}
                            required={true}
                            // label={<LabelCom require={true} title='选项'></LabelCom>}
                            label="选项"
                        >
                            {answerArr.length ? <SortContainer useDragHandle distance={10} items={answerArr} onSortEnd={onSortEndHandler} /> : null}
                            <Space>
                                <Button type="link" onClick={onAddOptionsClick}>
                                    <PlusSquareOutlined />
                                    添加选项
                                </Button>
                            </Space>
                        </Item>
                    ) : null}
                    {curTab === "10" ? (
                        <Item
                            // colon={false}
                            required={true}
                            // label={<LabelCom require={true} title='选项'></LabelCom>}
                            label="内容"
                        >
                            <MatrixContent defaultValue={defaultValue?.questionOptionsList[0]} colValue={colValue} setColValue={setColValue} rowValue={rowValue} setRowValue={setRowValue} arrForm={form} qesTitle={quillRef.current.value} />
                        </Item>
                    ) : null}
                    {curTab === "11" ? (
                        <div className="upload_img">
                            <div className="upload_img_box">
                                <div>
                                    <div className="upload-title"><PlusOutlined className="upload_ion" />上传文件</div>
                                    <div>500M以内的文件，支持格式 PPT、PPTX、DOC、DOCX、PDF、PNG、JPG、XLS、XLSX、MP4、MOV、WMV 格式</div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {curTab === "12" ? (
                        <div className="sign_area">
                            <div className="sign_area_box">
                                <div>
                                    <div className="sign_area_title">请在此处签名</div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Form>
            </Modal>

            <Modal title={"批量添加选项"} open={moreOpen} onCancel={() => (setMoreOpen(false),form.setFieldValue('opinion',''))} onOk={onAnswerOk}>
                <Form form={form} className="add_other">
                    <p>每行代表一个选项，点击回车键换行</p>
                    <Form.Item name="opinion">
                        <TextArea
                            className="vertical-scrollbar"
                            autoSize={{
                                minRows: 6,
                                maxRows: 6,
                            }}
                        ></TextArea>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default forwardRef(AddQuestionModal);
