import React, { Fragment, useEffect } from "react";
import "./index.less";
import config from "@config";

const Cumputerpreview = ({ type, content, displayTitle, displayCopyright, displayLogo, selectColor }) => {
    useEffect(() => {
        if (selectColor === false) {
            if (type !== undefined && type.includes("Header")) {
                if (content.route === "newCreate") {
                    document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}/answer?type=2`;
                } else {
                    document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}/answer?type=1`;
                }
            } else {
                if (content.route === "newCreate") {
                    document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}?template=1`;
                } else {
                    document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}?type=3`;
                }
            }
        }
    }, [type, displayTitle, displayCopyright, displayLogo, selectColor]);

    return (
        <Fragment>
            <div className="cumputer-preview">
                <iframe name="fr" className={`iframe-width ${type !== undefined && type.includes("Header") ? "" : "pointer-none"}`} />
            </div>
        </Fragment>
    );
};

export default Cumputerpreview;
