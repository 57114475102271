import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Layout, Table, Modal, Space, message } from 'antd';
import LiveFilterForm from './LiveFilterForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { get, post, del } from '@/core/request';
import { fullPagerConfig } from '@/utils/property';

const { Content } = Layout;

export default function LiveList() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 产品线
    const [productIds, setProductId] = useState([]);
    // 所属企业
    const [orgIds, setOrgId] = useState([]);
    // 直播分类
    const [liveCatesId, setLiveCatesId] = useState([]);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setProductId(res.data);
        })
    }, []);

    // 合作企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    // 直播分类
    useEffect(() => {
        get('/dms/lives/categories/findCatesTmenu').then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.value = item.id;
                    item.label = item.name;
                    item.children.forEach(elem => {
                        elem.value = elem.id;
                        elem.label = elem.name;
                        delete elem.children;
                    })
                })
                setLiveCatesId(res.data);
            }

        })
    }, []);

    // 筛选数据
    const handleFilterFinish = (value) => {
        if (value.cateIdArr) {
            value.cateId = value.cateIdArr[0];
            value.labelId = value.cateIdArr[1];
        }
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, createdBeginAt, createdEndAt, liveStatus, pubStatus, productId, orgId, cateId, verifyStatus, labelId } = filterObj;

    const getData = () => {
        get(`/dms/lives`, {
            limit: pageSize,
            page: currPage,
            name: name,
            createdBeginAt: createdBeginAt,
            createdEndAt: createdEndAt,
            liveStatus: liveStatus,
            pubStatus: pubStatus,
            productId: productId,
            orgId: orgId,
            cateId: labelId,
            verifyStatus: verifyStatus
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.liveId;
                });
                setTableData(res.data);
            }

        });
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, name, createdBeginAt, createdEndAt, liveStatus, pubStatus, productId, orgId, cateId, verifyStatus]);


    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 操作点击
    const navigate = useNavigate()
    const changePath = useCallback(
        (row, tip) => {
            return () => {
                if (tip === 'edit') {
                    navigate(`/dms/lives/${row.liveId}`, { state: row })
                } else if (tip === 'replay') {
                    navigate(`/dms/lives/${row.liveId}/replays`, { state: row })
                } else if (tip === 'overview') {
                    navigate(`/dms/lives/${row.liveId}/data/overview`, { state: row })
                } else if (tip === 'user') {
                    navigate(`/dms/lives/${row.liveId}/data/user/live`, { state: row })
                } else {
                    navigate(`/dms/lives/${row.liveId}/interaction/chat`, { state: row })
                }
            }
        },
        [navigate],
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除直播',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/lives', {
                        ids: [row.liveId]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中直播',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/lives', {
                        ids: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何直播');
                }
            }
        });
    };

    // 上架
    const grounding = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要上架/下架选中直播',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.pubStatus === 1 || row.pubStatus === 2) {
                        status = 0;
                    }
                    post('/dms/lives/release', {
                        liveId: row.liveId,
                        status
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData()
                        }
                    });
                }
            });
        }
    };

    // 点击审核
    const handleCheckconfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要改为已审核/待审核',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    if (row.verifyStatus === 2) {
                        get('/dms/lives/audit', {
                            liveId: row.liveId,
                            status: 0
                        }).then(res => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                            }
                        });
                    } else {
                        message.warning('直播审核通过，不能更改')
                    }

                }
            });
        }
    }

    // 导出数据
    const handleExportFile = () => {
        get(`/dms/lives/download`, {
            limit: pageSize,
            page: currPage,
            name: name,
            createdBeginAt: createdBeginAt,
            createdEndAt: createdEndAt,
            liveStatus: liveStatus,
            pubStatus: pubStatus,
            productId: productId,
            orgId: orgId,
            cateId: cateId,
            verifyStatus: verifyStatus
        }, { responseType: 'blob' }).then(res => {
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                const fileName = "直播列表数据.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    }

    const columns = [
        {
            title: '直播封面图', dataIndex: 'coverUrl',
            render: (operates) => (<img style={{width:'100px',height:'50px'}} className="banner-img" src={operates} alt="直播封面图" />)
        },
        { title: '直播标题', dataIndex: 'name', },
        { title: '直播ID', dataIndex: 'liveId', },
        { title: '创建时间', dataIndex: 'createdAt', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgsName', },
        { title: '创建人', dataIndex: 'staffName', },
        { title: '直播分类', dataIndex: 'cateName', },
        { title: '直播专题', dataIndex: 'topicName', },
        {
            title: '直播状态', dataIndex: 'liveStatus',
            render: (operates) => (
                <Fragment>
                    {operates === 0 && <span>直播预告</span>}
                    {operates === 1 && <span>直播中</span>}
                    {operates === 2 && <span>直播结束</span>}
                    {operates === 3 && <span>直播回放</span>}
                </Fragment>
            )
        },
        {
            title: '上架状态', dataIndex: 'pubStatus',
            render: (operates) => (
                <Fragment>
                    {operates === 0 && <span>上架</span>}
                    {operates === 1 && <span>下架</span>}
                    {operates === 2 && <span>未上架</span>}
                </Fragment>
            )
        },
        {
            title: '审核', dataIndex: 'verifyStatus',
            render: (operates, row) => (
                <Fragment>
                    {operates === 0 && <span className="operate-style" onClick={handleCheckconfirm(row)}>已审核</span>}
                    {operates === 1 && <span className="delete-style">审核拒绝</span>}
                    {operates === 2 && <span className="operate-style" onClick={handleCheckconfirm(row)}>待审核</span>}
                </Fragment>
            )
        },
        {
            title: '操作', dataIndex: 'operates',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={changePath(row, 'edit')}>编辑</span>
                    <span className="operate-style" onClick={grounding(row)}>{row.pubStatus === 0 ? '下架' : '上架'}</span>
                    {/* <span className="operate-style" onClick={changePath(row, 'replay')}>查看回放</span> */}
                    {row.liveStatus === 3 && <span className="operate-style" onClick={changePath(row, 'replay')}>查看回放</span>}
                    <span className="operate-style" onClick={changePath(row, 'overview')}>数据报告</span>
                    <span className="operate-style" onClick={changePath(row, 'user')}>用户统计</span>
                    <span className="operate-style" onClick={changePath(row, 'interacte')}>互动统计</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">直播管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <LiveFilterForm
                        productId={productIds}
                        orgId={orgIds}
                        liveCatesId={liveCatesId}
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">
                            直播列表
                        </div>

                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleExportFile}>导出数据</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="production-table" rowSelection={rowSelection} columns={columns}
                        dataSource={tabledata.list} pagination={paginationProps} />

                </div>
            </Content>

        </Fragment>
    )
}
