import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { Button, Layout, Table, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ProductionBannerAddForm from './ProductionBannerAddForm';
import ProductionBannerFilterForm from './ProductionBannerFilterForm';
import { get, post, put } from '@/core/request';
import { fullPagerConfig } from '@/utils/property';
import './ProductionBanner.less';

const { Content } = Layout;

export default function ProductionBanner() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );
    // 产品线
    const [productIds, setProductId] = useState([]);
    // 所属企业
    const [orgIds, setOrgId] = useState([]);
    // 操作人
    const [updatedByStaffIds, setUpdatedByStaffId] = useState([]);

    // 操作人
    useEffect(() => {
        get('/dms/users/findAll').then((res) => {
            setUpdatedByStaffId(res.data);
        })
    }, []);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setProductId(res.data);
        })
    }, []);

    // 合作企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    const { name, productId, orgId, updatedByStaffId, status } = filterObj;

    const getData = () => {
        get(`/dms/production-lines/banners`, {
            limit: pageSize,
            page: currPage,
            name: name,
            productId: productId,
            orgId: orgId,
            updatedByStaffId: updatedByStaffId,
            status: status
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }

        })
    }
    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, name, productId, orgId, updatedByStaffId, status]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        (tip, row) => {
            return () => {
                drawer.current.show(tip, row)
            }
        },
        [],
    );

    // 上架下架modal相关
    const UpAndDownConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要下架该广告',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.status === 1) {
                        status = 2;
                    }
                    const { id, endTime, imgUrl, link, name, orgId, productId, startTime, weight } = row;
                    let obj = { id, endTime, imgUrl, link, name, orgId, productId, startTime, status, weight };
                    put('/dms/production-lines/banners', obj).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData()
                        }
                    });
                }
            });
        }
    };

    // table 数据相关
    const columns = [
        { title: '广告ID', dataIndex: 'id', key: 'id' },
        {
            title: '广告图片', dataIndex: 'imgUrl',
            render: (operates) => (<img className="banner-img" src={operates} alt="广告图片" />)
        },
        { title: '广告名称', dataIndex: 'name', },
        { title: '产品线', dataIndex: 'productsName', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '上架时间', dataIndex: 'startTime', },
        { title: '下架时间', dataIndex: 'endTime', },
        { title: '操作人', dataIndex: 'updatedByStaffId', },
        { title: '权重', dataIndex: 'weight', },
        {
            title: '状态', dataIndex: 'status',
            render: (operates) => (
                <Fragment>
                    {operates === 0 && <span>未上架</span>}
                    {operates === 1 && <span>已上架</span>}
                    {operates === 2 && <span>已下架</span>}
                </Fragment>
            )
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handleClickAdd('edit', row)}>详情</span>
                    <span className="operate-style" onClick={UpAndDownConfirm(row)}>{row.status === 1 ? '下架' : '上架'}</span>
                </Fragment>
            )
        },
    ];

    // 点击新增
    const onHandleFinish = (values) => {
        post(`/dms/production-lines/banners`, values).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    };

    // 点击编辑
    const onHandlePutFinish = (values) => {
        put(`/dms/production-lines/banners`, values).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    }

    return (
        <Fragment>
            <div className="page-title">广告管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ProductionBannerFilterForm
                        handleFilterFinish={handleFilterFinish}
                        productId={productIds}
                        orgId={orgIds}
                        updatedByStaffId={updatedByStaffIds}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">广告列表</div>

                        <div className="table-toolbar">
                            <Button type="primary" onClick={handleClickAdd('add')}>新增广告</Button>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

            <ProductionBannerAddForm
                ref={drawer}
                productId={productIds}
                orgId={orgIds}
                filterObj={filterObj}
                pageSize={pageSize}
                currPage={currPage}
                setTableData={setTableData}
                onHandleFinish={onHandleFinish}
                onHandlePutFinish={onHandlePutFinish}
            />

        </Fragment>
    )
}
