import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { Button, Layout, Table, Space, Modal, message } from 'antd';
import SurveysUsersFilterForm from './SurveysUsersFilterForm';
import SurveysUserAddForm from './SurveysUserAddForm/SurveysUserAddForm';
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { get, del, post, put } from '@/core/request';
import { fullPagerConfig } from '@/utils/property';
import qs from 'qs';
import './Surveys.less';

const { Content } = Layout;

export default function SurveysUsers() {

    const { state } = useLocation();
    // 渲染table数据的state
    const [tabledata, setTableData] = useState([]);
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        () => {
            drawer.current.show()
        },
        [],
    );


    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { productId, orgId, name, phone, cityName, hospital, departmentId, titleId } = filterObj;

    const getData = () => {
        get(`/dms/user/surveys/queryUserSurveysPage`, {
            limit: pageSize,
            page: currPage,
            surveyId: state.id,
            productId: productId,
            orgId: orgId,
            name: name,
            phone: phone,
            cityName: cityName,
            hospital: hospital,
            departmentId: departmentId,
            titleId: titleId
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }

        });
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [currPage, cityName, departmentId, hospital, name, orgId, phone, productId, titleId, pageSize, state.id]);

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除用户',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/user/surveys', {
                        ids: [row.id]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const columns = [
        { title: '姓名', dataIndex: 'userName' },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '所在省份', dataIndex: 'provinceName', },
        { title: '所在城市', dataIndex: 'cityName', },
        { title: '医院名称', dataIndex: 'hospital', },
        { title: '所在科室', dataIndex: 'tagTitle', },
        { title: '职称', dataIndex: 'title', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (<span className="delete-style" onClick={deleteConfirm(row)}>删除</span>)
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 添加白名单
    const handleAddAwhiteListFinish = (value) => {
        post(`/dms/user/surveys`, {
            surveyId: state.id,
            surveysSaveListDtoList: value
        }).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        });
    };

    // 发布问卷
    const handlePublish = () => {
        put(`/dms/surveys/release`, qs.stringify({
            id: state.id
        })).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message)
            }
        })
    }
    return (
        <Fragment>
            <div className="page-title"><Link to="/dms/surveys/" className="back"><LeftOutlined /></Link>发布问卷 <span className="page-title-tip">{state.name}问卷调研 [#{state.id}]</span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={handlePublish}>发布</Button>
                </Space>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <SurveysUsersFilterForm handleFilterFinish={handleFilterFinish} />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickAdd}>添加人员</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="production-table" rowSelection={rowSelection} columns={columns}
                        dataSource={tabledata.list} pagination={paginationProps} />

                </div>
            </Content>

            <SurveysUserAddForm ref={drawer} id={state.id} handleAddAwhiteListFinish={handleAddAwhiteListFinish}></SurveysUserAddForm>

        </Fragment>
    )
}
