import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
    ReloadOutlined,
    SearchOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    CopyOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import {
    Button,
    Form,
    Input,
    Layout,
    Space,
    Tooltip,
    DatePicker,
    Table,
    Tag,
    Divider,
    Typography,
    Dropdown,
    Modal,
    message,
    Select,
    Row,
    Col,
    Switch
} from 'antd';
import { conferenceListApi, conferenceDelApi, conferenceMarkAsTesting,conferencetHiddenStateApi } from '@services/Conference';
import { numberProject, GetQueryString } from '@utils/utils';
import { localStorageGet } from '@core/cookie';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { conferenceActions } from '../store/conferenceSlice';
import config from '@config';

const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const { setProjectFilter: setFilter, setProjectCursor: setCursor } = conferenceActions;

export default function ConferenceList() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.conference.project.filter);
    const cursor = useSelector(state => state.conference.project.cursor);

    const [form] = Form.useForm();

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        console.log(values);
        let { dates } = values;

        // dates = dates && moment(dates).isValid() ? moment(dates).format('YYYY-MM-DD') : undefined;
        let newFilter = { ...filter, ...values };

        if (dates && dates.length) {
            newFilter['dateBegin'] = dates[0].format('YYYY-MM-DD');
            newFilter['dateEnd'] = dates[1].format('YYYY-MM-DD');
        } else {
            newFilter['dateBegin'] = undefined;
            newFilter['dateEnd'] = undefined;
        }

        dispatch(setFilter({
            ...newFilter,
            page: 1,
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const updateItemHidden = async (item, checked) => {
        let newValue = checked?0:1
        setLoading(true);
        let params = {
            conferenceId: item.id,
            hidden: newValue,
            healthcarePlatformHidden: item.healthcarePlatformHidden
        }
        let res = await conferencetHiddenStateApi(params)
        if (res.status_code === 200) {
            const newDataSource = tableData.map(data => {
                if (data === item) {
                  return { ...data, hidden: newValue };
                }
                return data;
              });
              setTableData(newDataSource);
        }
        setLoading(false);
    };
    const onPlatformHiddenChange = async (item, checked) => {
        let newValue = checked ? 0 : 1
        setLoading(true);
        let params = {
            conferenceId: item.id,
            hidden: item.hidden,
            healthcarePlatformHidden: newValue,
        }
        let res = await conferencetHiddenStateApi(params)
        if (res.status_code === 200) {
            const newDataSource = tableData.map(data => {
                if (data === item) {
                    return { ...data, healthcarePlatformHidden: newValue };
                }
                return data;
            });
            setTableData(newDataSource);
        }
        setLoading(false);
    }

    const handleRefresh = () => getListData();

    const delConference = (id, name) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否删除${name}`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                conferenceDelApi({ id: id }).then(
                    res => {
                        if (res.status_code === 200) {
                            message.success('删除成功')
                            getListData(1, 10);

                        }
                    }
                );
            }
        });
    }

    const copyClick = msg => {
        copy(msg);
        message.success('已复制到剪贴板');
    };

    const gotoProjectModule = (projectId, moduleName, state = {}) => {
        dispatch(setCursor(projectId));

        navigate(`/conf/conferences/${projectId}${moduleName !== 'edit' ? `/${moduleName}` : ''}`, { state });
    };

    const columns = [
        {
            title: '氚云编号',
            dataIndex: 'h3yunNo',
            width: 120,
            render: (data) => {
                return !!data
                    ? <span className="conferenctlist-copy-column-span">
                        {data}
                        <span onClick={() => copyClick(data)}><CopyOutlined /></span>
                    </span>
                    : <Typography.Text type="secondary">&ndash;</Typography.Text>
            }
        },
        {
            title: '项目编号',
            dataIndex: 'id',
            width: 120,
            render: (data) => {
                return <span className="conferenctlist-copy-column-span">
                    {numberProject('YHY', data)}
                    <span onClick={() => copyClick(numberProject('YHY', data))}><CopyOutlined /></span>
                </span>
            }
        },
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 300,
            render: (_, record) => {
                return <>
                    <a target='_blank' href={`${config.HUIYI_DOMAIN}/web/${record.slug}`}>{record.name}</a>
                    {!!record.isTesting && <Tag color="#f50" style={{marginLeft: 4, padding: '0 4px'}}>TEST</Tag>}
                    {!!record.provice && (
                        <>
                            <br/>
                            <Typography.Text type="secondary">
                                <span>{record.provice}</span>{record.city && <span>/{record.city}</span>}
                            </Typography.Text>
                        </>
                    )}
                </>
            }
        },
        {
            title: "日期",
            dataIndex: "dates",
            width: 220,
            render: (_, record) => record.dates.map((d, i) => <Tag key={i}>{d}</Tag>),
        },
        {
            title: "项目创建人",
            dataIndex: "createdBy",
            width: 100,
            render: (data) => {
                return !!data
                    ? <span className="conferenctlist-copy-column-span">
                        {data}
                    </span>
                    : <Typography.Text type="secondary">&ndash;</Typography.Text>
            }
        },
        {
            title: <>
                <Tooltip title={<>是否显示在<a target="_blank" href="https://huiyi.cn-healthcare.com/">健康界会议频道</a>中</>}>
                    会议聚合列表 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            dataIndex: "status",
            width: 120,
            render: (_, record) => <Switch checkedChildren="显示" unCheckedChildren="隐藏" defaultChecked checked={!record.hidden} onChange={(checked)=> updateItemHidden(record, checked)}/>,
        },
        {
            title: <>
                <Tooltip title={<>是否显示在健康界APP直播或健康界学苑直播模块中</>}>
                    健康界平台 <QuestionCircleOutlined/>
                </Tooltip>
            </>,
            dataIndex: "status",
            width: 110,
            render: (_, record) => <Switch checkedChildren="显示" unCheckedChildren="隐藏" defaultChecked checked={!record.healthcarePlatformHidden} onChange={(checked)=> onPlatformHiddenChange(record, checked)}/>,
        },
        {
            title: "操作",
            dataIndex: "operations",
            width: 300,
            render: (_, record) => {
                const items = [
                    {
                        label: !!record.isTesting ? '标记为正式会议' : '标记为测试会议',
                        key: "markAsTesting",
                        onClick: () => markAsTesting(record),
                    },

                    {
                        label: "删除会议项目",
                        key: "delete",
                        onClick: () => delConference(record.id, record.name),
                        danger: true,
                    },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'site-modules', { name: record.name, slug: record.slug })}
                        >
                            网站模块
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'agendas')}
                        >
                            日程管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'guests')}
                        >
                            嘉宾管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'lives', { id: record.id, slug: record.slug })}
                        >
                            直播管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'edit', { edit: 'edit', id: record.id } )}
                        >
                            编辑会议项目
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'signup')}
                        >
                            报名签到
                        </Button>
                        {/* <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'signin')}
                        >
                            签到管理
                        </Button> */}
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => gotoProjectModule(record.id, 'organizations')}
                        >
                            机构管理
                        </Button>
                        {
                            isShowLaborBtn && <>
                                <Divider type="vertical" />
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => gotoProjectModule(record.id, 'laborfee', { code: 'YHY', projectId: record.id, name: record.name })}
                                >
                                    劳务费管理
                                </Button>
                            </>
                        }
                        <Divider type="vertical" />
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </span>
                );
            },
        },
    ];

    const getListData = async (projectName) => {
        // console.log(filter);
        let { ids, dates } = filter;

        dates = dates && moment(dates).isValid() ? moment(dates).format('YYYY-MM-DD') : undefined;

        let params = { ...filter, dates };

        if (projectName) {
            params.name = projectName;
        }

        if (!!ids) {
            const idList = ids.match(/(?:YHY0+)?([1-9]\d*)/gi);
            if (idList && idList.length) {
                ids = idList.map(id => id.replace(/^YHY0+/i, '')).map(id => parseInt(id) || 0) || [];

                params['ids'] = ids;
            }
        }
        if (_.isEmpty(params['ids'])) {
            params['ids'] = [];
        }

        try {
            setLoading(true);

            let res = await conferenceListApi(params)
            if (res.status_code === 200) {
                setTotal(res.data.totalCount);

                const list = res.data.list.map(item => {
                    return {
                        ...item,
                        key: item.id,
                        dates: item.dates ? item.dates.split(',') : [],
                    };
                });

                setTableData(list);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        form.setFieldsValue(filter);

        getListData();
    }, [filter]);

    // 医项目跳转携带参数
    useEffect(() => {
        const projectName = GetQueryString('projectName');
        if (projectName) {
            form.setFieldValue('name', unescape(projectName));
            let timer = setTimeout(() => {
                getListData(unescape(projectName));
                clearTimeout(timer);
            }, 500);
        }
    }, []);

    const markAsTesting = record => {
        Modal.confirm({
            title: '标记为测试会议',
            icon: <ExclamationCircleOutlined />,
            content: '医疗决策人数据统计等数据指标将排除标记为测试的会议项目，确定标记为测试项目？',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                conferenceMarkAsTesting({
                    id: record.id,
                    isTesting: !!record.isTesting ? 0 : 1,
                }).then(res => {
                    if (res.status_code === 200) {
                        const msg = !!record.isTesting ? '标记为正式会议成功' : '标记为测试会议成功';
                        message.success(msg);

                        getListData();
                    }
                })
            }
        })


    };

    // 是否拥有劳务费管理权限
    const isShowLaborBtn = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            const hasLaborFee = roleList.some(item => item.name === '劳务费录入人员' || item.name === '劳务费管理员');
            return hasLaborFee;
        }
        return false;
    }, []);

    return (
        <Fragment>
            <div className="page-title">
                <span className="current-title">会议项目管理</span>
            </div>

            <Content className="main-content-box">

                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        onFinish={onFinish}
                        form={form}
                        autoComplete="off"
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="名称" name="name">
                                    <Input allowClear placeholder="会议项目名称关键字" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="医会议ID" name="ids">
                                    <Input allowClear placeholder="会议项目编号或ID" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="执行日期" name="dates" className="dates">
                                    <RangePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="氚云编号" name="h3yunNo">
                                    <Input allowClear placeholder="氚云编号" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="创建人" name="createdBy">
                                    <Input allowClear placeholder="项目创建人" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="正式/测试" name="isTesting">
                                    <Select placeholder="选择是否测试会议" allowClear>
                                        <Select.Option value={0}>正式会议</Select.Option>
                                        <Select.Option value={1}>测试会议</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={() => navigate(`/conf/conferences/new`)}>新增会议项目</Button>
                        </div>
                        <div className="sub-tools">
                            <Tooltip title="刷新列表">
                                <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}/>
                            </Tooltip>
                        </div>
                    </div>

                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                        scroll={{ x: 1300 }}
                    />
                </div>
            </Content>
        </Fragment>
    );
}
