import React,{Fragment,useEffect,useState} from 'react';
import { Layout,Alert,Form, Row, Col, Button, Modal, Radio,Input,Image,Tooltip,message,Typography} from "antd";
import {FilePdfOutlined,FilePptOutlined,FileWordOutlined,LeftOutlined} from '@ant-design/icons';
import {getSolutionItemApi,getVodMediaInfo,reviewSolutionApi} from '@services/Eha';
import { Link,useNavigate,useParams } from 'react-router-dom';
import './index.less';
import config from '@config';



const {Content} = Layout;
const {TextArea} = Input;
let player = null;
const {Text} = Typography;

const Detail = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const {Text} = Typography;
    const [solutionContent,seSolutionContent] = useState(undefined)
    const [radioValue,setRadiovalue] = useState(undefined)
    const [audit,setAudit] = useState(false)
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + `/${url}`;
        }
        return null;
    };


    //视频播放器配置
    const playerSkinLayout = [
        {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
        {name: "H5Loading", align: "cc"},
        {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
        {name: "infoDisplay"},
        {name:"tooltip", align:"blabs",x: 0, y: 56},
        {name: "thumbnail"},
        {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                {name: "progress", align: "blabs", x: 0, y: 44},
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                // {name:"subtitle", align:"tr",x:15, y:12},
                {name:"setting", align:"tr",x:15, y:12},
                {name: "volume", align: "tr", x: 5, y: 10}
            ]
        }
    ];


     //播放器播放地址
     const getVideoPlayInfo = async (mediaId) => {
        const playInfo = await getVodMediaInfo({ mediaId });
        const resData = playInfo.data;
        if (!!resData &&  window.Aliplayer) {
            if (resData.status === 'Normal') {
                const playauth = resData.playAuth;
                player = new  window.Aliplayer({
                    id: 'J_prismPlayer',
                    width: '100%',
                    height: '100%',
                    vid:mediaId,
                    playauth:playauth,
                    autoplay: false,
                    preload: true,
                    controlBarVisibility: 'hover',
                    useH5Prism: true,
                    skinLayout: playerSkinLayout
                }, function (player) {
                });
            }else{}
        } else {}
    };

    const showSize = (size) =>{
        return  `${(size / 1024 / 1024).toFixed(2)}MB`

    };

    const changeReason = (e) => {
        setRadiovalue(e.target.value);
        form.setFieldValue('reviewStatus',e.target.value)
    };

    const onReview = () => {
        form.validateFields().then(()=>{
            let obj= {
                ...form.getFieldsValue(),
                id:Number(params.id),
                rejectReason:radioValue===2?form.getFieldValue('rejectReason'):''

            }
            reviewSolutionApi({...obj }).then(res=>{
                if(res.status_code ===200){
                    setAudit(false)
                    form.resetFields()
                    setRadiovalue(undefined)
                    message.success('审核完成');
                    getData()
                }
            })
        })
    };

    const resetValue = () =>{
        setAudit(false)
        form.resetFields()
        setRadiovalue(undefined)
    };

    const onPreview = (ext,path) =>{
        if(ext==='pdf'){
            window.open(getFileUrl(path))
        }else  {
                let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + getFileUrl(path);
                window.open(officeUrl);
            }
    };

    const goEdit = () => {
        navigate(`/healthaccount/eha/solutions/edit/${params.id}`)
    };

    const getData = ()=>{
        getSolutionItemApi({id:Number(params.id)}).then(res=>{
            if(res&&res.status_code===200){
                res.data={
                    ...res.data,
                    docs:res.data.docs&&res.data.docs!=='{}'?JSON.parse(res.data.docs):null,
                    attachment:res.data.attachment&&res.data.attachment!=='{}'?JSON.parse(res.data.attachment):null,
                    images:res.data.images&&res.data.images!=='{}'?JSON.parse(res.data.images):null,
                    video:res.data.video&&res.data.video!=='{}'?JSON.parse(res.data.video):null,
                }
                seSolutionContent(res.data)
                if(res.data.video){
                    getVideoPlayInfo(res.data.video.video_id)
                }

            }
        })
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <Fragment>
        <div className="page-title">
            <Link to={`/healthaccount/eha/solutions`} className="back"><LeftOutlined /></Link>
            <span className="current-title">解决方案详情</span>
        </div>
        <Content className="main-content-box">
            <div className="filter-form-box">
            <div >解决方案详情</div>
            </div>
            <div className="main-content-body">
                <Alert
                    message={
                        <div className='solution-message-info'>
                            <div>
                                <span>提交时间：</span>
                                <span>{solutionContent&&solutionContent.createdAt}</span>
                            </div>
                            <div>
                                <span>审核状态：</span>
                                {solutionContent&&solutionContent.reviewStatus==0&&  <span >待审核</span>}
                                {solutionContent&&solutionContent.reviewStatus==1&&  <span  className='throw-scusses'>已通过</span>}
                                {solutionContent&&solutionContent.reviewStatus==2&&
                                <>
                                 <span  className='none-throw'>
                                    未通过

                                    </span>
                                    <Tooltip title={solutionContent.rejectReason}>
                                        <svg class="bi bi-chat-square-dots" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                        <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>
                                    </Tooltip>



                                </>
                                    }
                            </div>
                            <div>
                                <span>审核时间：</span>
                                <span>{solutionContent&&solutionContent.reviewedAt}</span>
                            </div>
                            <div>
                                <span>审核管理员：</span>
                                <span>{solutionContent&&solutionContent.reviewedBy}</span>
                            </div>
                            <div>
                                <span>发布状态：</span>
                                    <Fragment>
                                        {solutionContent&&solutionContent.reviewStatus!==0&&solutionContent.status === 0 && (<span> 待发布 </span>)}
                                        {solutionContent&&solutionContent.reviewStatus!==0&&solutionContent.status === 1 && (<Text type="success"> 已发布 </Text>)}
                                        {solutionContent&&solutionContent.reviewStatus!==0&&solutionContent.status === 2 && (<Text type="warning"> 已下架 </Text>)}
                                    </Fragment>
                            </div>
                        </div>
                    }
                    type="warning"
                />
                <div className='solution-message-content'>
                    <Form
                    labelCol={{
                        span: 4,
                    }}
                    //   wrapperCol={{
                    //     span: 14,
                    //   }}
                    >
                        <Form.Item label='解决方案名称'>
                            <div>{solutionContent&&solutionContent.title}</div>
                        </Form.Item>
                        <Form.Item label='提交时间'>
                            <div>{solutionContent&&solutionContent.createdAt}</div>
                        </Form.Item>
                        <Form.Item label='封面图'>
                            <div className='solution-cover'>
                                <Image   src={solutionContent&&getFileUrl(solutionContent.cover)}/>
                            </div>
                        </Form.Item>
                        <Form.Item label='摘要'>
                            <div className='description'>{solutionContent&&solutionContent.summary}</div>
                        </Form.Item>
                        <Form.Item label='应用场景'>
                            <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent&&solutionContent.applicationScenarios!==null?solutionContent.applicationScenarios:'' }}></div>
                        </Form.Item>
                        <Form.Item label='解决行业问题'>
                            <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent&&solutionContent.industryProblems!==null?solutionContent.industryProblems:'' }}></div>
                        </Form.Item>
                        <Form.Item label='创新与优势'>
                            {solutionContent&&solutionContent.advantages!==null?
                                <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent&&solutionContent.advantages!==null?solutionContent.advantages:'' }}></div>
                                :<div className='none-data'>用户未填写</div>
                            }
                        </Form.Item>
                        <Form.Item label='解决方案详细说明'>
                            <div className='description'>
                                <div>
                                    技术功能
                                    {solutionContent&&solutionContent.technologies!==null?
                                        <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent&&solutionContent.technologies||'' }}></div>
                                        :<div className='none-data'>用户未填写</div>
                                    }
                                </div>
                                <div>
                                    架构图/流程图
                                    {solutionContent&&solutionContent.flowcharts!==null?
                                        <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent&&solutionContent.flowcharts||'' }}></div>
                                        :<div className='none-data'>用户未填写</div>
                                    }
                                </div>
                                <div>
                                    应用实施步骤
                                    {solutionContent&&solutionContent.processes!==null?
                                        <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent&&solutionContent.processes||'' }}></div>
                                        :<div className='none-data'>用户未填写</div>
                                    }
                                </div>
                            </div>

                        </Form.Item>
                        <Form.Item label='客户效益'>
                            {solutionContent&&solutionContent.benefits!==null?
                                <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent.benefits||'' }}></div>
                                :<div className='none-data'>用户未填写</div>
                            }
                        </Form.Item>
                        <Form.Item label='应用案例'>
                        {solutionContent&&solutionContent.applicationCases!==null?
                                <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent.applicationCases||'' }}></div>
                                :<div className='none-data'>用户未填写</div>
                            }
                        </Form.Item>
                        <Form.Item label='所获荣誉'>
                        {solutionContent&&solutionContent.honors!==null?
                                <div className='description' dangerouslySetInnerHTML={{ __html: solutionContent.honors||'' }}></div>
                                :<div className='none-data'>用户未填写</div>
                            }
                        </Form.Item>
                        <Form.Item label='解决方案附件'>
                        {solutionContent&&solutionContent.attachment!==null?
                                <div className='docs-area'>
                                <div>
                                {solutionContent.attachment.ext==='pdf'&&<FilePdfOutlined /> }
                                {(solutionContent.attachment.ext==='ppt'|| solutionContent.attachment.ext==='pptx')&&<FilePptOutlined /> }
                                {(solutionContent.attachment.ext==='doc'|| solutionContent.attachment.ext==='docx')&&<FileWordOutlined />}
                                <Button type='link' onClick={()=>onPreview(solutionContent.attachment.ext,solutionContent.attachment.path)}>{solutionContent.attachment.fileName}</Button>
                                </div>
                                <div className='docs-size'>
                                    {showSize(solutionContent.attachment.size)}
                                </div>

                            </div>

                                : <div className='none-data'>用户未上传</div>
                            }

                        </Form.Item>
                        <Form.Item label='解决方案视频介绍'>
                        {solutionContent&&solutionContent.video!==null?
                                <div className='player-area' id="J_prismPlayer"></div>
                                : <div className='none-data'>用户未上传</div>
                            }

                        </Form.Item>
                        <Form.Item label='解决方案图集'>
                        {solutionContent&&solutionContent.images!==null?
                        <>
                        <p className='solution-desc'>{solutionContent.images.desc}</p>
                        <Row>
                                    {
                                       solutionContent.images.images&&solutionContent.images.images.map((item,index)=>(

                                            <>
                                            <Col span={7} key={index} className='solution-imgs'>
                                                <Image
                                                src={getFileUrl(item)}
                                                alt=""
                                                />
                                            </Col>
                                            </>

                                        ))
                                    }
                                </Row>
                        </>

                                : <div className='none-data'>用户未上传</div>
                            }



                        </Form.Item>
                        <Form.Item label='解决方案文档'>
                        {solutionContent&&solutionContent.docs!==null?
                                <>
                                <p className='solution-desc'>{solutionContent.docs.desc}</p>
                                {
                                    solutionContent.docs.docs&&solutionContent.docs.docs.map((item,index)=>(
                                        <div key={index} className='docs-area'>
                                            <div>
                                            {item.ext==='pdf'&&<FilePdfOutlined /> }
                                            {(item.ext==='ppt'|| item.ext==='pptx')&&<FilePptOutlined /> }
                                            {(item.ext==='doc'|| item.ext==='docx')&&<FileWordOutlined />}
                                            <Button type='link' onClick={()=>onPreview(item.ext,item.path)}>{item.fileName}</Button>
                                            </div>
                                            <div className='docs-size'>
                                                {showSize(item.size)}
                                            </div>


                                        </div>
                                    ))
                                }
                                </>
                                : <div className='none-data'>用户未上传</div>
                            }
                        </Form.Item>
                    </Form>
                </div>
                <div className='solution-message-footer'>
                    <div className='solution-button-area'>
                        <Button onClick={()=>goEdit()}>编辑解决方案</Button>
                        {solutionContent&&solutionContent.reviewStatus===0&&  <Button type='primary' className='button-audit' onClick={()=>setAudit(true)}>审核解决方案</Button>}
                    </div>
                </div>
            </div>
        </Content>

        <Modal
            title='审核解决方案'
            open={audit}
            onCancel={()=>resetValue()}
            onOk={()=>onReview()}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                label='审核意见'
                name='reviewStatus'
                rules={[
                    {
                        required: true,
                        message: '请选择审核意见',
                    },
                    ]}
                >
                    <Radio.Group onChange={(e)=>changeReason(e)}>
                        <Radio value={1}>通过</Radio>
                        <Radio value={2}>驳回</Radio>
                    </Radio.Group>
                </Form.Item>
               {radioValue===2&& <Form.Item label='驳回理由'
                name='rejectReason'
                rules={[
                    {
                        required: true,
                        message: '请填写驳回理由',
                    },
                    ]}>
                            <TextArea/>
                </Form.Item>}

            </Form>
        </Modal>
        </Fragment>
    );
}

export default Detail;
