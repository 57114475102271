import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpertFilterForm from './ExpertFilterForm';
import { Button, Layout, Table, Space, message, Spin, Modal, Typography, Avatar, Tag } from 'antd';
import { fullPagerConfig } from '@/utils/property';
import { getExpertListApi, deleteExpertApi } from '@services/Experts';
import { expertLevels } from '@utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { expertActions } from './store/expertSlice';
import { isEmpty } from 'lodash';

const { Content } = Layout;
const { Text } = Typography;

const { setFilter, setCursor } = expertActions;

const ExpertOverview = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.expert.filter);
    const cursor = useSelector(state => state.expert.cursor);
    // console.log(filter, cursor);

    // 渲染table数据的state
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);

    // 筛选数据
    const handleFilterFinish = values => {
        let newFilter = {
            ...filter,
            ...values,
            page: 1
        };
        if (isEmpty(newFilter?.expertLevels)) {
            delete newFilter.expertLevels;
        }
        console.log('filter', newFilter);
        dispatch(setFilter(newFilter));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const [loading, setLoading] = useState(false);

    // 获取用户列表
    const getExpertList = async () => {
        setLoading(true);
        const res = await getExpertListApi(filter);
        if (res && res.status_code === 200) {
            setTableData(res.data.list);
            setTotal(res.data.totalCount);
        }
        setLoading(false);
    };

    // 渲染table数据
    useEffect(() => {
        getExpertList();
    }, [filter]);

    const paginationProps = {
        ...fullPagerConfig,
        size: 'small',
        total: total,
        current: filter.page,
        pageSize: filter.pageSize,
        onChange: onChangePage,
    };

    // table 数据相关
    const columns = [
        {
            title: '姓名',
            dataIndex: 'memUsername',
            key: 'memUsername',
            width: 150,
            render: (_, record) => <>
                {!!record?.expertAvatar
                    ? <Avatar src={record?.expertAvatar} style={{ verticalAlign: 'middle' }}/>
                    : (
                        <Avatar style={{ verticalAlign: 'middle' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                            </svg>
                        </Avatar>
                    )}
                <span style={{ marginLeft: 4, marginRight: 4, verticalAlign: 'middle' }}>{record.memUsername}</span>
                <span style={{ verticalAlign: 'middle' }}>
                    {record.memGender === 1
                        ? (
                            <svg style={{ color: '#1890ff' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-male" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                            </svg>
                        )
                        : record.memGender === 0
                            ? (
                                <svg style={{ color: '#eb2f96' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-female" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                                </svg>
                            )
                            : null}
                </span>
                {record.status === 1 && <Tag color="#f50" style={{ marginLeft: 4, marginRight: 0, padding: '0 4px', lineHeight: '18px' }}>禁用</Tag>}
            </>
        },
        {
            title: '专家级别',
            dataIndex: 'expertLevel',
            key: 'expertLevel',
            width: 120,
            render: (state, row) => row.expertLevel >= 1 && row.expertLevel <= 6
                ? <Text type="warning">{expertLevels.find(a => a.level === row.expertLevel).label}</Text>
                : <span>{expertLevels.find(a => a.level === row.expertLevel).label}</span>
        },
        { title: '手机号', dataIndex: 'memMobile', key: 'memMobile', width: 100 },
        {
            title: '单位职务',
            dataIndex: 'ssoMemberWorkExperiencesEntities',
            key: 'ssoMemberWorkExperiencesEntities',
            width: 200,
            render: (state, row) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {row.ssoMemberWorkExperiencesEntities && row.ssoMemberWorkExperiencesEntities.length
                        ? row.ssoMemberWorkExperiencesEntities.map((item, index) => (
                            <span key={index}>{row.ssoMemberWorkExperiencesEntities.length > 1 ? `${index + 1}. ` : ''}{`${item.unitName ? item.unitName + ' ' : ''}${item.unitOffice ? item.unitOffice + ' ' : ''}${item.unitPost ? item.unitPost + ' ' : ''}${item.unitJobTitle ? item.unitJobTitle : ''}`}</span>
                        ))
                        : null
                    }
                </div>
            ),
        },
        // { title: '擅长领域', dataIndex: 'skilledFields', key: 'skilledFields' },
        // { title: '擅长专业', dataIndex: 'specialization', key: 'specialization' },
        {
            title: '平台用户',
            dataIndex: 'authentication',
            key: 'authentication',
            width: 60,
            render: (state, row) => <>{row.memCard ? <span style={{ color: '#52c41a' }}>是</span> : '否'}</>
        },
        {
            title: '认证',
            dataIndex: 'authentication',
            key: 'authentication',
            width: 80,
            render: (state, row) => <span>{getAuthType(row.authentication)}</span>
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            fixed: "right",
            width: 80,
            align: "center",
            render: (state, row) => (
                <Space size="middle" className='antd_btn_padding'>
                    <Button type='link' onClick={() => onEdit(row)}>编辑</Button>
                </Space>
            ),
        },
    ];

    // 认证类型
    const getAuthType = (type) => {
        switch (type) {
            case 'A01':
                return '医务人员';
            case 'A02':
                return '政府官员';
            case 'A03':
                return '商业人士';
            case 'A04':
                return '教育科研人士';
            case 'A06':
                return '社会团体人士';
            default:
                return '无'
        }
    };

    // 点击新增专家
    const handleClickAddExpert = () => {
        navigate('/uc/experts/new');
    };

    // 编辑
    const onEdit = row => {
        // console.log('expert_id', row.id);
        dispatch(setCursor(row.id));
        navigate(`/uc/experts/${row.id}`, { state: { id: row.id, type: 'edit', memCard: row.memCard, } });
    };

    // 删除
    const onDelete = (row) => {
        Modal.confirm({
            title: '提示',
            content: `确认删除用户${row?.memUsername}`,
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                const res = await deleteExpertApi({ id: row.id });
                if (res && res.status_code === 200) {
                    message.success('删除成功');
                    getExpertList();
                }
            }

        });
    };

    return (
        <Fragment>
            <div className="page-title">专家列表</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ExpertFilterForm
                        handleFilterFinish={handleFilterFinish}
                        filter={filter}
                        setFilter={setFilter}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickAddExpert}>新增专家</Button>
                            </Space>
                        </div>
                    </div>

                    <Spin spinning={loading} delay={500}>
                        <Table
                            className="enterprise-table"
                            columns={columns}
                            dataSource={tableData}
                            rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                            pagination={paginationProps}
                            scroll={{
                                x: 1300
                            }}
                        />
                    </Spin>


                </div>
            </Content>

        </Fragment>
    )
};

export default ExpertOverview;
