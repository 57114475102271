import { Avatar, Drawer, Form, Image, Space, Button } from 'antd';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import config from '@config';
import { getDicNameApi } from '@/services/Selection';
import { orgTypeMap, orgPropertyMap, hoslevelMap } from '@/utils/authInfoMap';

const drawerFormItemSiteLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
};

// 认证详情
const ViewAuthInfoDrawer = ({
    visibleAuthInfoDrawer,
    setVisibleAuthInfoDrawer,
    authInfo,
    ssoMember,
    auditThrough,
    auditDismissed,
    isShowFooter,
    authStatus
}) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID
    const [form] = Form.useForm();

    const [dicCodeList, setDicCodeList] = useState([]);

    // 上传文件路径 - 前缀 
    const getFileUrl = (url) => {
        if (url) {
            if (url.startsWith("http")) {
                return url;
            } else {
                return config.JKJ_OSS_URL_PREFIX + "/" + url;
            }
        }

        return null;
    };

    // 获取字典名称
    const getDicName = (dicCode) => {
        if (dicCode) {
            let resName = null;
            const targetSec = dicCodeList.filter(item => (item.dicCode === dicCode));
            if (targetSec && targetSec.length) {
                // level 3 - 有上级存在parentId
                if (targetSec[0].level === 3) {
                    const parentId = targetSec[0].parentId;
                    const targetFir = dicCodeList.filter(item => (item.dicCode === parentId));
                    if (targetFir && targetFir.length) {
                        resName = targetFir[0].dicName + '/' + targetSec[0].dicName;
                    }
                // level 2 - 有上级存在 - 需截取
                } else if (targetSec[0].level === 2) {
                    const parentName = dicCode.split(/-(?!.*-)/)[0];
                    const targetFir = dicCodeList.filter(item => (item.dicCode === parentName));
                    if (targetFir && targetFir.length) {
                        resName = targetFir[0].dicName + '/' + targetSec[0].dicName;
                    }
                } else {
                    resName = targetSec[0].dicName;
                }
            }
            return resName;
        }
        return '';
    };

    // 获取职务职称字段
    const getCodeName = async () => {
        const res = await getDicNameApi({ identity: 'A0' });
        if (res.status_code === 200 && res.data) {
            setDicCodeList(res.data);
        }
    };

    // 机构类型
    const getOrgType = (type) => {
        if (type === 'T01') {
            return '政府机构';
        }
        if (type === 'T02') {
            return '团体组织';
        }
        if (type === 'T03') {
            return '协会';
        }
        if (type === 'T04') {
            return '其他';
        }
        return '';
    };

    // 个人认证身份
    const getPersonType = (type) => {
        if (type === 'A01') {
            return '医疗机构从业者';
        }
        if (type === 'A02') {
            return '政府官员';
        }
        if (type === 'A03') {
            return '商业人士';
        }
        if (type === 'A04') {
            return '教育科研人士';
        }
        if (type === 'A06') {
            return '社会团体人士';
        }
        return '';
    };

    // 机构认证身份
    const getOrginationType = (type) => {
        if (parseInt(type) === 0) {
            return '企业';
        }
        if (parseInt(type) === 1) {
            return '媒体';
        }
        if (parseInt(type) === 2) {
            return '医疗机构';
        }
        if (parseInt(type) === 3) {
            return '机构';
        }
        return '';
    };


    useEffect(() => {
        getCodeName();
    }, []);

    return (
        <Drawer
            width={800}
            title="认证详情"
            open={visibleAuthInfoDrawer}
            onClose={() => setVisibleAuthInfoDrawer(false)}
            layout="horizontal"
            maskClosable={false}
            footer={
                <div style={{ textAlign: 'right' }}>
                    {isShowFooter &&
                        <Space>
                            <Button type="primary" onClick={() => auditThrough(ssoMember.memCard)}>通过申请</Button>
                            <Button onClick={() => auditDismissed(ssoMember.memCard)}>驳回申请</Button>
                        </Space>
                    }
                    {!isShowFooter &&
                        <Space>
                            {authStatus === 2 && <Button type='link'>已审核-通过</Button>}
                            {authStatus === 3 && <Button type='link'>已审核-驳回</Button>}
                        </Space>
                    }
                </div>
            }
        >
            <div className="conference-form">
                <Form
                    {...drawerFormItemSiteLayout}
                    layout="horizontal"
                    name="basic"
                    form={form}
                    initialValues={{ hidden: 1 }}
                    autoComplete="off"
                >
                    {ssoMember?.memType === 1 &&
                        <>
                            <Form.Item label="头像" name="name">
                                <Avatar size="large" src={getFileUrl(ssoMember?.memHeadImg)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="姓名" name="name">{authInfo?.memUsername}</Form.Item>
                            <Form.Item label="单位名称" name="name">{authInfo?.memUnit}</Form.Item>
                            <Form.Item label="身份" name="name">{getPersonType(authInfo?.memIdentity)}</Form.Item>
                            <Form.Item label="职务" name="name">{getDicName(authInfo?.memPost) || authInfo?.memPost}</Form.Item>
                            <Form.Item label="职称" name="name">{getDicName(authInfo?.memJobtitle) || authInfo?.memJobtitle}</Form.Item>
                            <Form.Item label="科室" name="name">{getDicName(authInfo?.memOffice) || authInfo?.memOffice}</Form.Item>
                            <Form.Item label="认证资料" name="name">
                                <Image width={300} src={getFileUrl(authInfo?.memIdentityPath)} className="share-image-area" />
                            </Form.Item>
                        </>
                    }

                    {ssoMember?.memType === 2 && parseInt(ssoMember?.orgType) === 2 &&
                        <>
                            <h3>主体信息登记</h3>
                            <Form.Item label="医疗机构LOGO" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.logopath)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="医疗机构名称" name="name">{authInfo?.memNickname}</Form.Item>
                            <Form.Item label="医疗机构简称" name="name">{authInfo?.orgname}</Form.Item>
                            <Form.Item label="医疗机构地址" name="name">{authInfo?.orgaddress}</Form.Item>
                            <Form.Item label="级别" name="name">{hoslevelMap[authInfo?.hoslevel]}</Form.Item>
                            <Form.Item label="医疗机构类别" name="name">{orgTypeMap[parseInt(authInfo?.orgType)]}</Form.Item>
                            <Form.Item label="医疗机构性质" name="name">{orgPropertyMap[authInfo?.orgProperty]}</Form.Item>
                            <Form.Item label="医疗机构简介" name="name">{authInfo?.orgprofile}</Form.Item>
                            <Form.Item label="重要科室描述" name="name">{authInfo?.departmentDescription}</Form.Item>
                            <Form.Item label="院办电话" name="name">{authInfo?.orgtel}</Form.Item>
                            <Form.Item label="院长姓名" name="name">{authInfo?.dean}</Form.Item>
                            <Form.Item label="登 记 号" name="name">{authInfo?.registerNumber}</Form.Item>
                            <Form.Item label="法人代表" name="name">{authInfo?.legalPerson}</Form.Item>
                            <Form.Item label="传真" name="name">{authInfo?.fax}</Form.Item>
                            <Form.Item label="邮编" name="name">{authInfo?.zipCode}</Form.Item>
                            <Form.Item label="医疗机构执业许可证" name="name">
                                <Image width={300} src={getFileUrl(authInfo?.bussinesslicence)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="在职员工数" name="name">{authInfo?.staffNumber}</Form.Item>
                            <Form.Item label="核定床位数" name="name">{authInfo?.beds}</Form.Item>
                            <Form.Item label="年门急诊量" name="name">{authInfo?.outpatientNum}</Form.Item>
                            <Form.Item label="年住院人次" name="name">{authInfo?.hospitalizationNumber}</Form.Item>
                            <Form.Item label="年业务收入（万元）" name="name">{authInfo?.annualIncome}</Form.Item>
                            <Form.Item label="资产总值（万元）" name="name">{authInfo?.totalAssets}</Form.Item>
                            <Form.Item label="病床使用率（%）" name="name">{authInfo?.bedUtilizationRate}</Form.Item>
                            <Form.Item label="出院患者人均住院日（天）" name="name">{authInfo?.leavehospitalNum}</Form.Item>
                            <Form.Item label="本年度手术量" name="name">{authInfo?.surgeryNumber}</Form.Item>
                            <Form.Item label="本年度三四级手术量" name="name">{authInfo?.yearSurgeryNumber}</Form.Item>
                            <Form.Item label="病床周转次数（次）" name="name">{authInfo?.bedTurnover}</Form.Item>
                            <Form.Item label="急危重患者抢救成功率（%）" name="name">{authInfo?.diseaseRescue}</Form.Item>
                            <Form.Item label="院内感染发生率（%）" name="name">{authInfo?.diseaseInfection}</Form.Item>
                            <Form.Item label="治愈好转率（%）" name="name">{authInfo?.diseaseCure}</Form.Item>
                            <Form.Item label="出入院诊断符合率（%）" name="name">{authInfo?.diagnosticSymbol}</Form.Item>
                            <Form.Item label="药占比（%）" name="name">{authInfo?.drugProportion}</Form.Item>
                            <Form.Item label="每一职工年均业务收入（万元）" name="name">{authInfo?.employeeIncome}</Form.Item>
                            <Form.Item label="出院患者人均住院费用（元）" name="name">{authInfo?.hospitalizationMoney}</Form.Item>
                            <Form.Item label="门诊患者次均医疗费用（元）" name="name">{authInfo?.outpatientMoney}</Form.Item>
                            <h3>运营者信息登记</h3>
                            <Form.Item label="认证申请函" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.authorization)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="标签" name="name">{authInfo?.labels}</Form.Item>
                            <Form.Item label="网站地址" name="name">{authInfo?.orgwebsite}</Form.Item>
                            <Form.Item label="微博地址" name="name">{authInfo?.weibo}</Form.Item>
                            <Form.Item label="微信公众号" name="name">{authInfo?.weixin}</Form.Item>
                            <Form.Item label="联系人姓名" name="name">{authInfo?.contacts}</Form.Item>
                            <Form.Item label="常用邮箱" name="name">{authInfo?.contactemail}</Form.Item>
                            <Form.Item label="联系人手机" name="name">{authInfo?.contacttelphone}</Form.Item>
                        </>
                    }

                    {ssoMember?.memType === 2 && parseInt(ssoMember?.orgType) === 1 &&
                        <>
                            <h3>主体信息登记</h3>
                            <Form.Item label="媒体LOGO" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.logopath)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="媒体名称" name="name">{authInfo?.memNickname}</Form.Item>
                            <Form.Item label="媒体简称" name="name">{authInfo?.orgname}</Form.Item>
                            <Form.Item label="媒体简介" name="name">{authInfo?.orgprofile}</Form.Item>
                            <Form.Item label="所在地" name="name">{authInfo?.orgaddress}</Form.Item>
                            <Form.Item label="统一社会信用代码" name="name">{authInfo?.creditCode}</Form.Item>
                            <Form.Item label="法人代表" name="name">{authInfo?.legalPerson}</Form.Item>
                            <Form.Item label="营业执照" name="name">
                                <Image width={300} src={getFileUrl(authInfo?.bussinesslicence)} className="share-image-area" />
                            </Form.Item>
                            <h3>运营者信息登记</h3>
                            <Form.Item label="运营授权书" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.authorization)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="标签" name="name">{authInfo?.labels}</Form.Item>
                            <Form.Item label="网站地址" name="name">{authInfo?.orgwebsite}</Form.Item>
                            <Form.Item label="微博地址" name="name">{authInfo?.weibo}</Form.Item>
                            <Form.Item label="微信公众号" name="name">{authInfo?.weixin}</Form.Item>
                            <Form.Item label="运营人姓名" name="name">{authInfo?.contacts}</Form.Item>
                            <Form.Item label="常用邮箱" name="name">{authInfo?.contactemail}</Form.Item>
                            <Form.Item label="运营人手机" name="name">{authInfo?.contacttelphone}</Form.Item>
                        </>
                    }

                    {ssoMember?.memType === 2 && parseInt(ssoMember?.orgType) === 0 &&
                        <>
                            <h3>主体信息登记</h3>
                            <Form.Item label="企业LOGO" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.logopath)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="企业名称" name="name">{authInfo?.memNickname}</Form.Item>
                            <Form.Item label="企业简称" name="name">{authInfo?.orgname}</Form.Item>
                            <Form.Item label="企业简介" name="name">{authInfo?.orgprofile}</Form.Item>
                            <Form.Item label="所在地" name="name">{authInfo?.orgaddress}</Form.Item>
                            <Form.Item label="统一社会信用代码" name="name">{authInfo?.creditCode}</Form.Item>
                            <Form.Item label="法人代表" name="name">{authInfo?.legalPerson}</Form.Item>
                            <Form.Item label="经营范围" name="name">{authInfo?.managementScope}</Form.Item>
                            <Form.Item label="营业执照" name="name">
                                <Image width={300} src={getFileUrl(authInfo?.bussinesslicence)} className="share-image-area" />
                            </Form.Item>
                            <h3>运营者信息登记</h3>
                            <Form.Item label="运营授权书" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.authorization)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="标签" name="name">{authInfo?.labels}</Form.Item>
                            <Form.Item label="网站地址" name="name">{authInfo?.orgwebsite}</Form.Item>
                            <Form.Item label="微博地址" name="name">{authInfo?.weibo}</Form.Item>
                            <Form.Item label="微信公众号" name="name">{authInfo?.weixin}</Form.Item>
                            <Form.Item label="联系人姓名" name="name">{authInfo?.contacts}</Form.Item>
                            <Form.Item label="常用邮箱" name="name">{authInfo?.contactemail}</Form.Item>
                            <Form.Item label="联系人手机" name="name">{authInfo?.contacttelphone}</Form.Item>
                        </>
                    }


                    {ssoMember?.memType === 2 && parseInt(ssoMember?.orgType) === 3 &&
                        <>
                            <h3>主体信息登记</h3>
                            <Form.Item label="机构LOGO" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.logopath)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="机构名称" name="name">{authInfo?.memNickname}</Form.Item>
                            <Form.Item label="机构简称" name="name">{authInfo?.orgname}</Form.Item>
                            <Form.Item label="机构简介" name="name">{authInfo?.orgprofile}</Form.Item>
                            <Form.Item label="所在地" name="name">{authInfo?.orgaddress}</Form.Item>
                            <Form.Item label="机构电话" name="name">{authInfo?.creditCode}</Form.Item>
                            <Form.Item label="法人代表" name="name">{authInfo?.legalPerson}</Form.Item>
                            <Form.Item label="机构类型" name="name">{getOrgType(authInfo?.orgType)}</Form.Item>
                            <Form.Item label="机构证件" name="name">
                                <Image width={300} src={getFileUrl(authInfo?.bussinesslicence)} className="share-image-area" />
                            </Form.Item>
                            <h3>运营者信息登记</h3>
                            <Form.Item label="运营授权书" name="name">
                                <Image width={200} src={getFileUrl(authInfo?.authorization)} className="share-image-area" />
                            </Form.Item>
                            <Form.Item label="标签" name="name">{authInfo?.labels}</Form.Item>
                            <Form.Item label="网站地址" name="name">{authInfo?.orgwebsite}</Form.Item>
                            <Form.Item label="微博地址" name="name">{authInfo?.weibo}</Form.Item>
                            <Form.Item label="微信公众号" name="name">{authInfo?.weixin}</Form.Item>
                            <Form.Item label="联系人姓名" name="name">{authInfo?.contacts}</Form.Item>
                            <Form.Item label="常用邮箱" name="name">{authInfo?.contactemail}</Form.Item>
                            <Form.Item label="联系人手机" name="name">{authInfo?.contacttelphone}</Form.Item>
                        </>
                    }

                </Form>
            </div>

        </Drawer>
    );
};
export default ViewAuthInfoDrawer;
