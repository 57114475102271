import React, { Fragment } from "react";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import config from "@config";
import "./index.less";
const AnswerSurvey = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(`/wj/surveys/${location.state.id}/charts`, { state: location.state });
    };

    return (
        <Fragment>
            <div className="page-title">
                <div className="page-left">
                    <div className="back">
                        <div className="back-title">
                            <LeftOutlined onClick={goBack} />
                            <span className="back-text">返回统计图表</span>
                        </div>
                    </div>
                </div>
                <div className="page-center">作答详情</div>
            </div>
            <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${location.state.id}/anwsersheets/${location.state.answerId}`} />
        </Fragment>
    );
};

export default AnswerSurvey;
