import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Space, Drawer } from 'antd';
import TableTransfer from './TableTransfer';

function SurveysUserAddForm({ id, handleAddAwhiteListFinish }, ref) {


    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [surveysSaveListDtoList, setSurveysSaveListDtoList] = useState([]);

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show() {
            setVisible(true);
        }
    }));

    const handleClose = () => {
        setVisible(false);
    };

    const handleOk = () => {
        handleAddAwhiteListFinish(surveysSaveListDtoList);
        setVisible(false);
    };

    return (
        <Fragment>
            {
                visible && <Drawer
                    title="添加问卷人员"
                    width="100%"
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <div style={{ textAlign: "right" }}>
                            <Space>
                                <Button onClick={handleClose}>取消</Button>
                                <Button type="primary" onClick={handleOk}>
                                    确定
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <TableTransfer id={id} setSurveysSaveListDtoList={setSurveysSaveListDtoList}></TableTransfer>
                </Drawer>

            }

        </Fragment>
    );
}

export default forwardRef(SurveysUserAddForm)
