import MyProfiles from '../components/My/MyProfiles';
import MyChangePassword from '../components/My/MyChangePassword';
import MyMessages from '../components/My/MyMessages';

// 医药数字化营销相关路由
const kbRoutes = [
    {path: 'profiles', element: <MyProfiles/>},
    {path: 'changepassword', element: <MyChangePassword/>},
    {path: 'messages', element: <MyMessages/>},
];

export default kbRoutes;
