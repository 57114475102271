import {get, put, post, delet} from '@core/request';


export const getProjectIdApi = (params) => {
    return get(`/user/labourexpencerecords/projectCode`, params);
}

export const getProjectUserApi = (params) => {
    return get(`/user/labourexpencerecords/phone`, params);
}

export const saveSingleApi = (data) => {
    return post(`/user/labourexpencerecords/save`, data);
}

export const getConstApi = (params) => {
    return get(`/user/labourexpencerecords/page`, params);
}

export const getConstDetailApi = (params) => {
    return get(`/user/labourexpencerecords/detail-page`, params);
}

export const getControlsLogApi = (params) => {
    return get(`/user/labouroperationlog/page`, params);
}

export const deleteCasescoresCase = (params) => {
    return delet(`/user/labourexpencerecords/delete`, params)
}

export const upLoadConstApi = (data,option) => {
    return post(`/user/labourexpencerecords/import`, data , option);
}



export const importErrorListApi = (params) => {
    return get(`/user/labourexpencerecords/import-error-list`, params);
}


/**
 * 新版劳务费管理
 */

// 劳务费信息列表
export const laborFeeListApi = (params) => {
    return post(`/conference/boss/laborFee/list`, params);
}

//l 劳务费和个税统计总额
export const statisticalAmountApi = (params) => {
    return post(`/conference/boss/laborFee/statisticalAmount`, params);
}

// 更新个税
export const updateIndividualTaxApi = (params) => {
    return post(`/conference/boss/laborFee/updateIndividualTax`, params);
}

// 更新劳务费
export const updateLaborFeeApi = (params) => {
    return post(`/conference/boss/laborFee/updateLaborFee`, params);
}

// 个人所得税审核确认
export const individualTaxConfirmedApi = (params) => {
    return put(`/conference/boss/conferences/individualTaxConfirmed`, params);
}

// 劳务费审核确认
export const laborFeeConfirmedApi = (params) => {
    return put(`/conference/boss/conferences/laborFeeConfirmed`, params);
}

// 劳务费审核确认
export const exportLaborFeeApi = (params) => {
    return get(`/conference/boss/conferences/projectLaborFeeRecords`, params);
}

// 发送确认实际日程任务
export const sendAgendaConfirmTaskApi = (params) => {
    return post(`/conference/boss/laborFee/sendAgendaConfirmTask`, params);
}

// 待请款申请的专家列表
export const laborFeeExpertsApi = (params) => {
    return post(`/conference/boss/laborFee/experts`, params);
}

// 劳务费请款审批申请
export const laborFeeBatchApplyApi = (params) => {
    return post(`/conference/boss/laborFee/batchApply`, params);
}

// 保存参会凭证
export const savelaborFeeJoinCertApi = (params) => {
    return post(`/conference/boss/laborFee/joinCert`, params);
}

// 项目劳务费调整记录
export const laborFeeAdjustListApi = (params) => {
    return get(`/user/project/laborFee/records/laborFeeAdjustList`, params);
}

// 项目劳务费审核结果
export const laborFeeAmountResultApi = (params) => {
    return post(`/conference/boss/laborFee/laborFeeAmountResult`, params);
}

// 含税劳务费请款审批拒绝原因
export const examineReasonsApi = (params) => {
    return get(`/conference/boss/conferences/examineReasons`, params);
}

// 劳务费请款批次表
export const laborFeeRequestBatchesListApi = (params) => {
    return get(`/user/project/laborFee/records/laborFeeRequestBatchesList`, params);
}

// 专家劳务费信息查询 
export const confirmedAgendaQueryListApi = (params) => {
    return get(`/conference/boss/conferencetaskrecords/confirmed-agenda-query`, params);
}
// 确认专家日程信息 
export const confirmedAgendaApi = (params) => {
    return post(`/user/project/laborFee/records/confirm/agenda`, params);
}
// 更新专家信息
export const improvePersionanlInfoApi = (params) => {
    return post(`/user/ssomemberexperts/api/improve-personal-infoV2`, params);
}
// 确认专家日程信息 
export const confirmedlaborInfoApi = (params) => {
    return post(`/user/project/laborFee/records/confirm/laborInfo`, params);
}
