import React, { Fragment, useEffect, useState, useRef, memo } from "react";
import { Button, Layout, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col, Tabs } from "antd";
import DataTable from "../DataTable";
import "./index.less";

const DataTab = ({ name, filObj, setFilObj }) => {
    const [choice, setcChoice] = useState("1");

    const onChice = (key) => {
        setcChoice(key);
        setFilObj({
            phone: "",
            durationBegin: "",
            durationEnd: "",
            submittedBegin: "",
            submittedEnd: "",
            channelId: "",
        });
    };
    // const onTabClick=()=>{
    //     console.log(1);

    // }
    return (
        <div>
            <Tabs defaultActiveKey="1" onChange={onChice}>
                <Tabs.TabPane tab="有效数据" key="1">
                    <DataTable type={1} choice={choice} filObj={filObj} setFilObj={setFilObj} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="无效数据" key="2">
                    <DataTable type={2} choice={choice} filObj={filObj} setFilObj={setFilObj} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

export default memo(DataTab);
