import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { SearchOutlined, ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Layout, Form, Row, Col, Input, Button, Space, Table, DatePicker, Select, Tooltip } from 'antd';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { useLocation } from 'react-router-dom';
import { get } from '@/core/request';
import moment from 'moment';

const { Content } = Layout;
export default function BiChannelUsers() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const { RangePicker } = DatePicker;
    const [form1] = Form.useForm();
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    let [num, setNum] = useState(0);
    let location = useLocation();
    let pathname = location.state;
    let [falg, setFalg] = useState(true)
    const [dataSource, setDataSource] = useState(null);
    const [formData, setFormData] = useState({ openStartTime: "", openEndTime: "", closeStartTime: "", closeEndTime: "", memCard: "", memNickname: "", memMobile: "", contentId: "", contentType: "", contentTitle: "" });
    const [showElement, setShowElement] = useState(false)
    const [sourceType, setSourceType] = useState(null);
    const onChangeSelect = (value) => {
        if (value) { setShowElement(true) } else {
            setShowElement(false)
        }
    }
    const onReset = () => {
        setFalg(false)
        form1.resetFields();
        form1.setFieldsValue({ startTime: "", time2: "" })
        setFormData({ ...formData, openStartTime: "", openEndTime: "", closeStartTime: "", closeEndTime: "", memCard: "", memNickname: "", memMobile: "", contentId: "", contentType: "", contentTitle: "" })
        setPage(1)
        setNum(++num)
    };
    const columns = [
        { title: '用户唯一标识', dataIndex: 'memCard', },
        {
            title: '用户昵称', dataIndex: 'memNickname',
        },
        {
            title: '手机号', dataIndex: 'memMobile',
        },
        {
            title: '开通时间', dataIndex: 'startTime',
        },
        {
            title: '截止时间', dataIndex: 'endTime',
        },
        {
            title: '剩余天数', dataIndex: 'dayNum',
            render: (_, record) =>
            (
                <div className='channelBox'>
                    {record.dayNum < 0 ? <span className='channelColor'>已过期</span> : record.dayNum}
                </div>
            ),
        },
        {
            title: '转化来源类型', dataIndex: 'contentType',
        },
        {
            title:
                <div>实体ID&nbsp;
                    <Tooltip placement='top' title={"对应所选转化来源类型的实体ID，如选PGC文章时，即PGC文章ID"}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
            dataIndex: 'contentId',
        },
        {
            title: "实体标题",
            dataIndex: 'contentTitle',
        },
        {
            title: '来源URL', dataIndex: 'url',
        },
    ];
    const clickBackHandler = () => {
        window.history.back(-1);
    }
    const onFinish = (values) => {
        setFormData({ ...formData, memCard: values.memCard || "", memNickname: values.memNickname || "", memMobile: values.memMobile || "", contentType: values.contentType || "", contentTitle: values.contentTitle || "", contentId: values.contentId || "" })
        setNum(++num)
    }
    function deepClone(result) {
        if (result && result.length) {
            result.forEach(item => {
                item.title = item.name
                item.value = item.id
                item.key = item.id
                if (item.children.length) {
                    deepClone(item.children)
                }
            })
            return result
        }
    }
    const handleChangeTime = (value, dateString) => {
        setFalg(false)
        setFormData({ ...formData, openStartTime: dateString[0], openEndTime: dateString[1] })
    }
    const handleChangeTime2 = (value, dateString) => {
        // setFalg(false)
        setFormData({ ...formData, closeStartTime: dateString[0], closeEndTime: dateString[1] })
    }
    useEffect(() => {
        if (pathname && pathname.showTime) {
            setFalg(false)
            form1.resetFields();
            form1.setFieldsValue({ startTime: "", time2: "" })
            setNum(++num)
        }
    }, [])
    useEffect(() => {
        if (pathname) {
            let { openStartTime, openEndTime, memCard, memNickname, memMobile, closeEndTime, closeStartTime, contentType, contentTitle, contentId } = formData;
            get(`/channel/statistics/personalUser`, {
                channelId: pathname.channelId,
                openStartTime: falg ? pathname.startTime : openStartTime,
                openEndTime: falg ? pathname.endTime : openEndTime,
                page,
                limit,
                memCard,
                memNickname,
                memMobile,
                closeEndTime,
                closeStartTime,
                contentType,
                contentTitle,
                contentId
            }).then((res) => {
                if (res.status_code == 200) {
                    setDataSource(res.data)
                }
            }, (err) => {
                console.log(err)
            })
        }

    }, [limit, page, num])
    const changeCurrPage = useCallback(
        (page) => {
            setPage(page);
        },
        [],
    );
    const changePageSize = useCallback(
        (pageSize) => {
            setLimit(pageSize);
        },
        [],
    );
    const paginationProps = {
        current: page,
        showTotal: () => `共${dataSource.totalCount}条记录`,
        showSizeChanger: true,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100', '1000'],
        size: 'small',
        total: dataSource && dataSource.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) }
    };
    useEffect(() => {
        get(`/channel/dictionary/source/type`).then((res) => {
            if (res.status_code == 200) {
                let arr = []
                for (var a in res.data) {
                    arr.push(res.data[a])
                }
                setSourceType(arr)
            }
        }, (err) => {
            console.log(err)
        })
    }, [])
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title"><ArrowLeftOutlined onClick={clickBackHandler} /> 渠道会员用户 <span className='styleColor'> {pathname.name} [#{pathname.channelId}]</span></div>
                <div className="main-content-body">
                    <Form {...filterFormItemLayout} onFinish={onFinish} form={form1}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="用户唯一标识" name="memCard">
                                    <Input allowClear placeholder="用户唯一标识" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="用户昵称" name="memNickname">
                                    <Input allowClear placeholder="用户昵称" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="memMobile">
                                    <Input allowClear placeholder="手机号" />
                                </Form.Item>
                            </Col>


                            <Col {...filterFormGridLayout}>
                                <Form.Item label="开通时间" name="startTime">
                                    {pathname && <RangePicker
                                        defaultValue={[moment(pathname.startTime, dateFormat), moment(pathname.endTime, dateFormat)]}
                                        format={dateFormat}
                                        showTime
                                        onChange={handleChangeTime}
                                    />}
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="截止时间" name="time2">
                                    <RangePicker
                                        format={dateFormat}
                                        showTime
                                        onChange={handleChangeTime2}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="转化来源类型" name="contentType">
                                    <Select allowClear placeholder="转化来源类型" onChange={onChangeSelect}>
                                        {sourceType && sourceType.map((item, i) => {
                                            return <Select.Option value={i + 1}>{item}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {showElement && <Col {...filterFormGridLayout}>
                                <Form.Item label="实体ID"  name="contentId" tooltip={{ title: '对应所选转化来源类型的实体ID，如选PGC文章时，即PGC文章ID', icon: <InfoCircleOutlined /> }}>
                                    <Input allowClear placeholder="转化来源实体ID" />
                                </Form.Item>
                            </Col>}
                            {showElement && <Col {...filterFormGridLayout}>
                                <Form.Item label="实体标题" name="contentTitle">
                                    <Input allowClear placeholder="实体标题关键字" />
                                </Form.Item>
                            </Col>}
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="table-title">渠道会员用户列表</div>
                    </div>
                    {dataSource && <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataSource.list}
                        pagination={paginationProps}
                    />}
                </div>
            </Content>
        </Fragment>
    )

}
