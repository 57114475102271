export const applicationScenarios = [
    {
        label:'医疗',
        value:'医疗',
        children: [
            {
                label:'临床',
                value:'临床'
            },
            {
                label:'检验',
                value:'检验'
            },
            {
                label:'检查',
                value:'检查'
            },
            {
                label:'护理',
                value:'护理'
            },
        ]
    },
    {
        label:'后勤',
        value:'后勤',
        children: [
            {
                label:'建筑设计',
                value:'建筑设计'
            },
            {
                label:'洗消污',
                value:'洗消污'
            },
            {
                label:'安保',
                value:'安保'
            },
            {
                label:'停车',
                value:'停车'
            },
            {
                label:'医疗物资管理',
                value:'医疗物资管理'
            },
            {
                label:'膳食',
                value:'膳食'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'康养',
        value:'康养',
        children: [
            {
                label:'康复',
                value:'康复'
            },
            {
                label:'养老',
                value:'养老'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'医院管理',
        value:'医院管理',
        children: [
            {
                label:'人财物管理',
                value:'人财物管理'
            },
            {
                label:'运营',
                value:'运营'
            },
            {
                label:'办公',
                value:'办公'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'教学',
        value:'教学',
        children: [
            {
                label:'医学考试',
                value:'医学考试'
            },
            {
                label:'临床教学',
                value:'临床教学'
            },
            {
                label:'手术示教',
                value:'手术示教'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'医保',
        value:'医保',
        children: [
            {
                label:'DRG/DIP',
                value:'DRG/DIP'
            },
            {
                label:'支付',
                value:'支付'
            },
            {
                label:'监管',
                value:'监管'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'患者服务',
        value:'患者服务',
        children: [
            {
                label:'互联网医疗',
                value:'互联网医疗'
            },
            {
                label:'挂号导诊',
                value:'挂号导诊'
            },
            {
                label:'急救衔接',
                value:'急救衔接'
            },
            {
                label:'院内导航',
                value:'院内导航'
            },
            {
                label:'远程医疗',
                value:'远程医疗'
            },
            {
                label:'家庭服务',
                value:'家庭服务'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'科研',
        value:'科研',
        children: [
            {
                label:'临床试验',
                value:'临床试验'
            },
            {
                label:'真实世界研究',
                value:'真实世界研究'
            },
            {
                label:'科研服务',
                value:'科研服务'
            },
            {
                label:'科研平台',
                value:'科研平台'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'预防',
        value:'预防',
        children: [
            {
                label:'筛查',
                value:'筛查',
            },
            {
                label:'体检',
                value:'体检'
            },
            {
                label:'健康管理',
                value:'健康管理'
            },
            {
                label:'其它',
                value:'其它'
            },
        ]
    },
    {
        label:'其它',
        value:'其它',
        children: [
            {
                label:'其它',
                value:'其它',
            },
        ]
    },
];


export const innovativeTechnologiesList = [
    {
        label:'5G',
        value:'5G'
    },
    {
        label:'区块链',
        value:'区块链'
    },
    {
        label:'AI',
        value:'AI'
    },
    {
        label:'大模型',
        value:'大模型'
    },
    {
        label:'大数据',
        value:'大数据'
    },
    {
        label:'云计算',
        value:'云计算'
    },
    {
        label:'物联网',
        value:'物联网'
    },
    {
        label:'AR',
        value:'AR'
    },
    {
        label:'VR',
        value:'VR'
    }
];

export const enterpriseVolumeList = [
    {
        label:'50人以下',
        value:'50人以下',
    },
    {
        label:'51~100人',
        value:'51~100人',
    },
    {
        label:'101~300人',
        value:'101~300人',
    },
    {
        label:'301~500人',
        value:'301~500人',
    },
    {
        label:'501~1000人',
        value:'501~1000人',
    },
    {
        label:'1000人以上',
        value:'1000人以上',
    }
];

export const isGryList = [
    {
        label:'是',
        value:1,
    },
    {
        label:'否',
        value:0,
    },
];
