import React, { Fragment, useState, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import { pageFormItemWithoutLabelGridLayout, drawerFormItemLayout, drawerWidth } from '@utils/property';
import { Form, Input, Divider, Button, Space, Radio, Select, Drawer, Cascader } from 'antd';
import { get } from '@/core/request';
import './UserList.less';
import data from '@utils/areas'

function UserEdit({ handleFinishEdit }, ref) {

    const [form] = Form.useForm();
    const [departmentData, setDepartmentData] = useState([]);
    const [allTitle, setAllTitle] = useState([]);

    // 科室
    useEffect(() => {
        get(`/dms/doctor/tags/allTags`).then((res) => {
            setDepartmentData(res.data);
        })
    }, []);

    // 职称
    useEffect(() => {
        get(`/dms/doctor/title/allTitles`).then((res) => {
            setAllTitle(res.data);
        })
    }, []);

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    // 拿userDetails返回的数据row
    const [rowData, setRowData] = useState({});

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(row) {
            setVisible(true);
            get(`/dms/userProducts/getOne`, {
                id: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    if (res.data) {
                        const { name, hospital, sex, provinceCode, cityCode, districtCode, departmentId, titleId } = res.data
                        setRowData(res.data);
                        form.setFieldsValue({
                            name: name,
                            hospital: hospital,
                            gender: sex,
                            province: [`${provinceCode}`, `${cityCode}`, `${districtCode}`],
                            departmentId: departmentId,
                            titleId: titleId
                        })
                    }

                }

            });
        }
    }), [form]);

    const handleClose = useCallback(
        () => {
            setVisible(false);
            form.resetFields();
        },
        [form],
    );

    const handleConfimClose = useCallback(
        () => {
            setVisible(false);
        },
        [],
    )

    const handleOk = useCallback(
        () => {
            if (pedding) return;
            setPedding(true);
            form.validateFields()
                .then((values) => {
                    values.provinceCode = Number(values.province[0]);
                    values.cityCode = Number(values.province[1]);
                    values.districtCode = Number(values.province[2]);
                    values.id = rowData.id;
                    delete values.province;
                    handleFinishEdit(values)
                    handleConfimClose();
                })
                .catch((err) => { });
            setPedding(false);
        },
        [form, pedding, rowData, handleConfimClose, handleFinishEdit],
    );

    return (
        <Fragment>
            {
                visible && <Drawer
                    closable={false}
                    width={drawerWidth}
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <div className="main-content-body">
                        <h2>编辑用户</h2>
                        <Form
                            {...drawerFormItemLayout}
                            form={form}
                        >
                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[{ required: true, message: '请输入姓名' }]}>
                                <Input placeholder="请输入姓名" allowClear autoComplete="off"/>
                            </Form.Item>

                            <Form.Item
                                label="性别"
                                name="gender"
                                required={true}>
                                <Radio.Group>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={2}>女</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item label="产品线">
                                {rowData.productName}
                            </Form.Item>

                            <Form.Item label="所属企业">
                                {rowData.orgName}
                            </Form.Item>

                            <Form.Item
                                name="province"
                                label="所在城市"
                                rules={[{ required: true, message: '请选择所在城市' }]}
                            >
                                <Cascader options={data} placeholder="请选择所在城市" />
                            </Form.Item>

                            <Form.Item
                                label="医院名称"
                                name="hospital"
                                rules={[{ required: true, message: '请输入医院名称' }]}>
                                <Input placeholder="请输入医院名称" allowClear autoComplete="off"/>
                            </Form.Item>

                            <Form.Item
                                label="科室"
                                name="departmentId"
                                initialValue={rowData.departmentId}
                                rules={[{ required: true, message: '请选择科室' }]}>
                                <Select allowClear placeholder="请选择科室">
                                    {departmentData && departmentData.map(item => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="职称"
                                name="titleId"
                                initialValue={rowData.titleId}
                                rules={[{ required: true, message: '请选择职称' }]}>
                                <Select allowClear placeholder="请选择职称">
                                    {allTitle && allTitle.map(item => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                            <Divider />

                            <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                                <Space>
                                    <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>保存</Button>
                                    <Button type="primary" onClick={handleClose}>取消</Button>
                                </Space>
                            </Form.Item>

                        </Form>

                    </div>
                </Drawer>
            }


        </Fragment>
    );
}

export default forwardRef(UserEdit)
