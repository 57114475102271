import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Layout, Table, Radio, DatePicker, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import AuthUserDepartmentEcharts from './AuthUserDepartmentEcharts';
import { get } from '@/core/request';
import { lastDays, timeString, todayDateString } from '@/utils/utils';
import moment from 'moment';
import '../ActiveUser/ActiveUser.less'

const { RangePicker } = DatePicker;
const { Content } = Layout;

export default function AuthUserDepartment() {

    // 渲染table数据的state
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState({
        xData: [],
        yData: []
    })

    const dateFormat = 'YYYY-MM-DD';

    let date = new Date();
    // 当天
    let dateString = todayDateString(date);
    let startdate = lastDays(1);
    // 七天前   30天前
    const [dateStringStart, setDateStringStart] = useState(startdate);
    // 当天
    const [dateStringEnd, setDateStringEnd] = useState(startdate);

    const [timeRadio, setTimeRadio] = useState('yesterday');

    useEffect(() => {
        var days = (Date.parse(dateStringEnd) - Date.parse(dateStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        // 渲染table数据
        get(`/user/data/statistics/detail/users-office-detail`, {
            endTime: dateStringEnd,
            startTime: dateStringStart,
        }).then((res) => {
            if (res.status_code === 200) {
                if (res.data) {
                    res.data.forEach(item => {
                        if (!item.children.length) {
                            delete item.children;
                        } else {
                            item.children.forEach(elem => {
                                elem.key = elem.type;
                            })
                        }
                        item.key = item.type;
                    });
                    setTableData(res.data);
                }

            }
        })
        // 渲染echarts数据
        get(`/user/data/statistics/detail/users-detail`, {
            endTime: dateStringEnd,
            startTime: dateStringStart,
            type: 2
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                res.data && res.data.forEach(item => {
                    xArr.push(item.dicName);
                    yArr.push(item.num);
                })
                setChartData({
                    xData: xArr,
                    yData: yArr
                })
            }
        });
    }, [dateStringEnd, dateStringStart]);


    // 改变时间
    const handleChangeValue = (e) => {
        setTimeRadio(e.target.value);
        let startdateInner = '';
        if (e.target.value === 'yesterday') {
            setDateStringStart(startdate)
            setDateStringEnd(startdate)
            return;
        } else if (e.target.value === 'seven') {
            startdateInner = lastDays(6);
        } else {
            startdateInner = lastDays(29);
        }
        setDateStringStart(startdateInner)
        setDateStringEnd(dateString)
    }

    // 搜索的自定义时间 控制为90天

    const disabledDate = (current) => {
        return current > moment(startdate, dateFormat)
    }

    const handleChangeTime = (value, dateString) => {
        setDateStringStart(dateString[0]);
        setDateStringEnd(dateString[1]);
        setTimeRadio('');
    }

    // 导出数据
    const handleExportFile = () => {
        var days = (Date.parse(dateStringEnd) - Date.parse(dateStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/detail/users-office-detail-download`, {
            endTime: dateStringEnd,
            startTime: dateStringStart,
        }, { responseType: 'blob' }).then((res) => {
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                let date = new Date();
                let dateString = timeString(date);
                const fileName = `医务人员科室分布${dateString}.xlsx`;
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    // table 数据相关
    const columns = [
        { title: '科室', dataIndex: 'typeName' },
        { title: '医务人员数', dataIndex: 'num', },
    ];

    return (
        <Fragment>
            <div className="page-title">医务人员科室分布</div>

            <Content className="main-content-box main-content-box-active">

                <div className="main-content-body">
                    <div className="active-user-title">统计时间</div>

                    <div className="bi-user-overview-time-box">
                        <Radio.Group onChange={handleChangeValue} size="middle" value={timeRadio} className="bi-user-overview-time-box-left">
                            <Radio.Button value="yesterday">昨天</Radio.Button>
                            <Radio.Button value="seven">最近7天</Radio.Button>
                            <Radio.Button value="thirty">最近30天</Radio.Button>
                        </Radio.Group>
                        {
                            dateStringStart && <RangePicker
                                size="middle"
                                allowClear={false}
                                value={[moment(dateStringStart, dateFormat), moment(dateStringEnd, dateFormat)]}
                                disabledDate={disabledDate}
                                onChange={handleChangeTime}
                            />
                        }

                    </div>

                    <div className="active-user-title">医务人员科室分布</div>

                    <AuthUserDepartmentEcharts chartData={chartData}></AuthUserDepartmentEcharts>

                    <div className="active-user-title">
                        <span>数据明细</span>
                        <div className="table-toolbar">
                            <Button type="default" onClick={handleExportFile}><DownloadOutlined />导出</Button>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                    />



                </div>
            </Content>

        </Fragment>
    )
}
