import React from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { Form, Row, Col, Input, Button, Space, Select, Table } from 'antd';

export default function RightTable({
    tableData, handleFilterFinish, setSelectedRowKeys, selectedRowKeys
}) {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            phone: undefined,
            gender: undefined
        }
        handleFilterFinish(values);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    const columns = [
        { title: '姓名', dataIndex: 'name' },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '医院名称', dataIndex: 'hospital', },
        { title: '所在科室', dataIndex: 'tagTitle', }
    ];

    return (
        <div>
            <div className="surveys-add-filter">
                <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="姓名" name="name1">
                                <Input allowClear placeholder="输入问卷名称" autoComplete="off" />
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="性别" name="gender1">
                                <Select allowClear placeholder="选择性别" >
                                    <Select.Option value={1}>男</Select.Option>
                                    <Select.Option value={2}>女</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="手机号" name="phone1">
                                <Input allowClear placeholder="请输入手机号" autoComplete="off" />
                            </Form.Item>
                        </Col>

                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" htmlType="submit">检索</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>
            <Table className="surveys-add-table-left"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
                pagination={false}
            />
        </div>
    )
}
