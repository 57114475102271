import { get, post } from '@core/request';

// 全部员工列表（用于后台数据关联员工）
export const getAllStaffs = params => get('/enterprise/staffs/get_all', params);

// 企业负责人列表（用于后台数据关联企业负责人）
export const getAllEnterpriseApi = params => post('/conference/boss/guests/list', params);


// 后台操作统计-活跃人数趋势
export const fetchOperationStatActiveUserCount = params => get('/enterprise/logs/active-person-count', params);

// 后台操作统计-活跃次数排名
export const fetchOperationStatActionCount = params => get('/enterprise/logs/active-count-total', params);
