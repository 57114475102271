import React, { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import EnterpriseFilterForm from './EnterpriseFilterForm';
import { Button, Layout, Table, Modal, Space, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EnterpriseAddForm from './EnterpriseAddForm';
import { fullPagerConfig } from '@/utils/property';
import { get, post, put, del } from '@/core/request';
import './EnterpriseList.less';

const { Content } = Layout;

export default function EnterpriceList() {

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});
    let [sum, setSum] = useState(0);

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    const [updatedByStaffId, setUpdatedByStaffId] = useState([]);

    // 操作人
    useEffect(() => {
        get('/dms/users/findAll').then((res) => {
            setUpdatedByStaffId(res.data);
        })
    }, []);

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, createdByStaffId, createdAtBegin, createdAtEnd } = filterObj;

    // 渲染table数据
    useEffect(() => {
        get(`/dms/enterprises`, {
            limit: pageSize,
            page: currPage,
            name: name,
            createdByStaffId: createdByStaffId,
            createdAtBegin: createdAtBegin,
            createdAtEnd: createdAtEnd
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }, [currPage, pageSize, createdAtBegin, createdAtEnd, createdByStaffId, name, sum]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        (tip, row) => {
            return () => {
                drawer.current.show(tip, row)
            }
        },
        [],
    );

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除企业',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/enterprises', {
                        ids: [row.id]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            setSum(++sum);
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中企业',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/enterprises', {
                        ids: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            setSum(++sum);
                        }
                    })
                } else {
                    message.warning('当前没有选中任何企业');
                }
            }
        });
    };

    // table 数据相关
    const columns = [
        { title: '企业ID', dataIndex: 'id' },
        { title: '企业名称', dataIndex: 'name', },
        { title: '产品线数量', dataIndex: 'productCount', },
        { title: '操作人', dataIndex: 'updatedByStaffName', },
        { title: '创建时间', dataIndex: 'createdAt', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handleClickAdd('edit', row)}>编辑</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击新增
    const onHandleFinish = (values) => {
        post(`/dms/enterprises`, values).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                setSum(++sum);
            }
        })
    };

    // 点击编辑
    const onHandlePutFinish = (values) => {
        put(`/dms/enterprises`, values).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                setSum(++sum);
            }
        })
    }

    return (
        <Fragment>
            <div className="page-title">企业列表</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <EnterpriseFilterForm
                        handleFilterFinish={handleFilterFinish}
                        updatedByStaffId={updatedByStaffId}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickAdd('add')}>新增企业</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                            </Space>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tableData.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

            <EnterpriseAddForm ref={drawer} onHandleFinish={onHandleFinish} onHandlePutFinish={onHandlePutFinish}></EnterpriseAddForm>

        </Fragment>
    )
}
