import React, { Fragment, useState, useEffect } from "react";
import { Switch, Upload, message } from "antd";
import "./index.less";
import {
    LineHeightOutlined,
    PlusOutlined,
    CloseCircleOutlined,
    FontSizeOutlined,
} from "@ant-design/icons";
import { saveSettingApi, querySettingApi } from "@services/Wj/Question";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { uploadFile } from "@services/Wj";
import config from "@config";
const Facadeaction = ({change,setMobile,setting,setSetting}) => {
    const location = useLocation();
    const option = { headers: { "Content-Type": "multipart/form-data" } };
    const [selectedPcHeader, setSelectedPcHeader] = useState("");
    const [selectedAppHeader, setSelectedAppHeader] = useState("");
    const [selectedPcCover, setSelectedPcCover] = useState("pcCover0");
    const [selectedAppCover, setSelectedAppCover] = useState("appCover0");
    const [pcControl, setPcControl] = useState(false);
    const [appControl, setAppControl] = useState(false);
    //用户端展示的样式图片
    const [appCover, setAppCover] = useState([
        config.OSS_PREFIX + "/survey/skins/default/h5_cover_01@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/h5_cover_02@2x.jpg",
    ]);
    const [appHeader, setAppHeader] = useState([
        config.OSS_PREFIX + "/survey/skins/default/h5_head_img_01@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/h5_head_img_02@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/h5_head_img_03@2x.jpg",
    ]);
    const [pcCover, setPcCover] = useState([
        config.OSS_PREFIX + "/survey/skins/default/web_cover_01@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/web_cover_02@2x.jpg",
    ]);
    const [pcHeader, setPcHeader] = useState([
        config.OSS_PREFIX + "/survey/skins/default/web_head_img_01@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/web_head_img_02@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/web_head_img_03@2x.jpg",
    ]);
    //缩略图
    const [appCoverPreview, setAppCoverPreview] = useState([
        config.OSS_PREFIX + "/survey/skins/default/h5_cover_01_preview@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/h5_cover_02_preview@2x.jpg",
    ]);
    const [appHeaderPreview, setAppHeaderPreview] = useState([
        config.OSS_PREFIX +
            "/survey/skins/default/h5_head_img_01_preview@2x.jpg",
        config.OSS_PREFIX +
            "/survey/skins/default/h5_head_img_02_preview@2x.jpg",
        config.OSS_PREFIX +
            "/survey/skins/default/h5_head_img_03_preview@2x.jpg",
    ]);
    const [pcCoverPreview, setPcCoverPreview] = useState([
        config.OSS_PREFIX + "/survey/skins/default/web_cover_01_preview@2x.jpg",
        config.OSS_PREFIX + "/survey/skins/default/web_cover_02_preview@2x.jpg",
    ]);
    const [pcHeaderPreview, setPcHeaderPreview] = useState([
        config.OSS_PREFIX +
            "/survey/skins/default/web_head_img_01_preview@2x.jpg",
        config.OSS_PREFIX +
            "/survey/skins/default/web_head_img_02_preview@2x.jpg",
        config.OSS_PREFIX +
            "/survey/skins/default/web_head_img_03_preview@2x.jpg",
    ]);
    // useEffect(() => {
    //     saveSettingApi({
    //         h5Cover: appCover[0],
    //         webCover: pcCover[0],
    //         surveyId: location.state.id,
    //         type: 2,
    //     });
    // }, []);

// 初始化图片
    useEffect(() => {
        if(location.state.route==='newCreate'){
            querySettingApi({ id: location.state.id, type: 1 }).then((res) => {
                if (res.status_code === 200) {
                    if (!res.data) return;
                    const { h5HeaderCover, webHeaderCover,h5Cover,webCover } = res.data;
                    if (h5HeaderCover) {
                        setAppControl(true);
                        let appHed = [...appHeader];
                        let appheadc = [...appHeaderPreview]
                        let index = appHed.findIndex(
                            (item) => item === h5HeaderCover
                        );

                        if (index === -1) {
                            appHed.push(h5HeaderCover);
                            appheadc.push(h5HeaderCover)
                            setAppHeader(appHed);
                            setAppHeaderPreview(appheadc);
                            setSelectedAppHeader('appHeader4')
                        }else{
                            setSelectedAppHeader(`appHeader${index}`)
                        }
                    } else {
                        setAppControl(false);
                    }
                    if (webHeaderCover) {
                        setPcControl(true);
                        let pcHedPreview = [...pcHeader];
                        let pcheadc = [...pcHeaderPreview]
                        let index = pcHedPreview.findIndex(
                            (item) => item === webHeaderCover
                        );

                        if (index === -1) {
                            pcHedPreview.push(webHeaderCover);
                            pcheadc.push(webHeaderCover)
                            setPcHeader(pcHedPreview);
                            setPcHeaderPreview(pcheadc)
                            setSelectedPcHeader('pcHeader4')
                        }else{
                            setSelectedPcHeader(`pcHeader${index}`)
                        }
                    } else {
                        setPcControl(false);
                    }
                    if (webCover) {
                        let pcCov = [...pcCover];
                        let pcCovPreview = [...pcCoverPreview]
                        let index = pcCov.findIndex(
                            (item) => item === webCover
                        );
                        if (index === -1) {
                            pcCovPreview.push(webCover);
                            pcCov.push(webCover)
                            setPcCover(pcCov);
                            setPcCoverPreview(pcCovPreview)
                            setSelectedPcCover('pcCover2')
                        }else{
                            setSelectedPcCover(`pcCover${index}`)
                        }
                    }else{
                    saveSettingApi({
                            webCover: pcCover[0],
                            surveyId: location.state.id,
                            type: 1,
                        })
                    }
                    if (h5Cover) {
                        let appCov = [...appCover];
                        let appCovPreview = [...appCoverPreview]
                        let index = appCov.findIndex(
                            (item) => item === h5Cover
                        );

                        console.log(webCover);
                        if (index === -1) {
                            appCovPreview.push(h5Cover);
                            appCov.push(h5Cover)
                            setAppCover(appCov);
                            setAppCoverPreview(appCovPreview)
                            setSelectedAppCover('appCover2')
                        }else{
                            setSelectedAppCover(`appCover${index}`)
                        }
                    }else{
                        saveSettingApi({
                            h5Cover: appCover[0],
                            surveyId: location.state.id,
                            type: 1,
                        })
                    }
                }
            });
        }else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            querySettingApi({ id: location.state.id, type: 2 }).then((res) => {
                if (res.status_code === 200) {
                    if (!res.data) return;
                    const { h5HeaderCover, webHeaderCover,h5Cover,webCover } = res.data;
                    if (h5HeaderCover) {
                        setAppControl(true);
                        let appHed = [...appHeader];
                        let appheadc = [...appHeaderPreview]
                        let index = appHed.findIndex(
                            (item) => item === h5HeaderCover
                        );
                        if (index === -1) {
                            appHed.push(h5HeaderCover);
                            appheadc.push(h5HeaderCover)
                            setAppHeader(appHed);
                            setAppHeaderPreview(appheadc);
                            setSelectedAppHeader('appHeader4')
                        }else{
                            setSelectedAppHeader(`appHeader${index}`)
                        }
                    } else {
                        setAppControl(false);
                    }
                    if (webHeaderCover) {
                        setPcControl(true);
                        let pcHedPreview = [...pcHeader];
                        let pcheadc = [...pcHeaderPreview]
                        let index = pcHedPreview.findIndex(
                            (item) => item === webHeaderCover
                        );

                        if (index === -1) {
                            pcHedPreview.push(webHeaderCover);
                            pcheadc.push(webHeaderCover)
                            setPcHeader(pcHedPreview);
                            setPcHeaderPreview(pcheadc)
                            setSelectedPcHeader('pcHeader4')
                        }else{
                            setSelectedPcHeader(`pcHeader${index}`)
                        }
                    } else {
                        setPcControl(false);
                    }
                    if (webCover) {
                        let pcCov = [...pcCover];
                        let pcCovPreview = [...pcCoverPreview]
                        let index = pcCov.findIndex(
                            (item) => item === webCover
                        );
                        if (index === -1) {
                            pcCovPreview.push(webCover);
                            pcCov.push(webCover)
                            setPcCover(pcCov);
                            setPcCoverPreview(pcCovPreview)
                            setSelectedPcCover('pcCover2')
                        }else{
                            setSelectedPcCover(`pcCover${index}`)
                        }
                    }else{
                    saveSettingApi({
                            webCover: pcCover[0],
                            surveyId: location.state.id,
                            type: 2,
                        })
                    }
                    if (h5Cover) {
                        let appCov = [...appCover];
                        let appCovPreview = [...appCoverPreview]
                        let index = appCov.findIndex(
                            (item) => item === h5Cover
                        );
                        if (index === -1) {
                            appCovPreview.push(h5Cover);
                            appCov.push(h5Cover)
                            setAppCover(appCov);
                            setAppCoverPreview(appCovPreview)
                            setSelectedAppCover('appCover2')
                        }else{
                            setSelectedAppCover(`appCover${index}`)
                        }
                    }else{
                        saveSettingApi({
                            h5Cover: appCover[0],
                            surveyId: location.state.id,
                            type: 2,
                        })
                    }
                    // h5cover,webCover
                }
            });
        }


    }, [pcHeaderPreview,appHeaderPreview,pcCoverPreview,appCoverPreview]);

    //上传图片
    const upload =  (value, type) => {
        const formData = new FormData();
        formData.append("file", value.file);
        formData.append("path", `skins/upload/`);
            switch (type) {
                case 'pcHeaderPreview':
                    uploadFile(formData, option).then(
                        (res)=>{
                            if (res.status_code === 200) {
                                setPcHeaderPreview([
                                                ...pcHeaderPreview,
                                                config.OSS_PREFIX + `/${res.data}`,
                                            ]);
                                        setPcHeader([...pcHeader, config.OSS_PREFIX + `/${res.data}`])
                                    }
                        }
                    )
                    break;
                    case 'appHeaderPreview':
                        uploadFile(formData, option).then(
                            (res)=>{
                                if (res.status_code === 200) {
                                    setAppHeaderPreview([
                                                    ...appHeaderPreview,
                                                    config.OSS_PREFIX + `/${res.data}`,
                                                ]);
                                            setAppHeader([
                                                ...appHeader,
                                                config.OSS_PREFIX + `/${res.data}`,
                                            ]);
                                }})
                        break;
                        case 'pcCoverPreview':
                            uploadFile(formData, option).then(
                                (res)=>{
                                    if (res.status_code === 200) {
                                            setPcCoverPreview([
                                                ...pcCoverPreview,
                                                config.OSS_PREFIX + `/${res.data}`,
                                            ]);
                                        setPcCover([...pcCover, config.OSS_PREFIX + `/${res.data}`]);
                                    }})
                            break;
                            case 'appCoverPreview':
                                uploadFile(formData, option).then(
                                    (res)=>{
                                        if (res.status_code === 200) {
                                            setAppCoverPreview([
                                                            ...appCoverPreview,
                                                            config.OSS_PREFIX + `/${res.data}`,
                                                        ]);
                                                    setAppCover([...appCover, config.OSS_PREFIX + `/${res.data}`]);
                                        }})
                                break;

                default:
                    break;

        }
    };
    // 上传图片格式校验
    const beforeUpload = (file,type) => {
        console.log(file);
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error("图片大小不超过5MB");
        }
        return isJpgOrPng && isLt5M;
        // return isJpgOrPng && isLt5M && fn(file, true,type);
    };
//     const fn = (v, bool,type) => {
//         return new Promise((resolve, reject) => {
//             let reader = new FileReader();
//             reader.onload = (e) => {
//                 let src = e.target.result;
//                 const image = new Image();
// if(type==='pc'){
//     image.onload = function () {
//         if (image.width > 1920) {
//             bool && message.warn("图片宽度大于1920像素");
//             reject();
//         } else if (image.height > 1080) {
//             bool && message.warn("图片高度大于1085像素");
//             reject();
//         } else {
//             resolve();
//         }
//     };
//     image.onerror = reject;
//     image.src = src;
// }else if(type==='app'){
//     image.onload = function () {
//         if (image.width > 750) {
//             bool && message.warn("图片宽度大于750像素");
//             reject();
//         } else if (image.height > 1334) {
//             bool && message.warn("图片高度大于1334像素");
//             reject();
//         } else {
//             resolve();
//         }
//     };
//     image.onerror = reject;
//     image.src = src;
// }
//             };
//             reader.readAsDataURL(v);
//         });
//     };
    //打开PC头图
    const onPcheader = (checked) => {
        if (checked) {
            setPcControl(true);
            if (location.state.route === 'newCreate') {
                saveSettingApi({
                    webHeaderCover: pcHeader[0],
                    surveyId: location.state.id,
                    type: 1,
                }).then((res) => {
                    if (res.status_code === 200) {
                        setSelectedPcHeader("pcHeader0");
                    }
                });
            }else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                saveSettingApi({
                    webHeaderCover: pcHeader[0],
                    surveyId: location.state.id,
                    type: 2,
                }).then((res) => {
                    if (res.status_code === 200) {
                        setSelectedPcHeader("pcHeader0");
                    }
                });
            }

        } else {
            setPcControl(false);
            if (location.state.route === 'newCreate') {
                saveSettingApi({
                    webHeaderCover: '',
                    surveyId: location.state.id,
                    type: 1,
                });
            }
            else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                saveSettingApi({
                    webHeaderCover: '',
                    surveyId: location.state.id,
                    type: 2,
                });
            }

        }
    };
    //打开App头图
    const onAppHeader = (checked) => {
        if (checked) {
            setAppControl(true);
            if (location.state.route === 'newCreate') {
                saveSettingApi({
                    h5HeaderCover: appHeader[0],
                    surveyId: location.state.id,
                    type: 1,
                }).then((res) => {
                    if (res.status_code === 200) {
                        setSelectedAppHeader("appHeader0");
                    }
                });
            }
            else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                saveSettingApi({
                    h5HeaderCover: appHeader[0],
                    surveyId: location.state.id,
                    type: 2,
                }).then((res) => {
                    if (res.status_code === 200) {
                        setSelectedAppHeader("appHeader0");
                    }
                });
            }


        } else {
            setAppControl(false);
            if (location.state.route === 'newCreate') {
                saveSettingApi({
                    h5HeaderCover: '',
                    surveyId: location.state.id,
                    type: 1,
                });
            }
            else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                saveSettingApi({
                    h5HeaderCover: '',
                    surveyId: location.state.id,
                    type: 2,
                });
            }

        }
    };
    //选择PC头图
    const choicePcHeader = (item, index) => {
        if (location.state.route === 'newCreate') {
            saveSettingApi({
                webHeaderCover: pcHeader[index],
                surveyId: location.state.id,
                type: 1,
            });
            setSelectedPcHeader(item);
            change(`pcHeader${index}`)
        }
        else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            saveSettingApi({
                webHeaderCover: pcHeader[index],
                surveyId: location.state.id,
                type: 2,
            });
            setSelectedPcHeader(item);
            change(`pcHeader${index}`)
        }

    };
    //选择App头图
    const choiceAppHeader = (item, index) => {
        if (location.state.route === 'newCreate') {
            saveSettingApi({
                h5HeaderCover: appHeader[index],
                surveyId: location.state.id,
                type: 1,
            });
            setSelectedAppHeader(item);
            change(`appHeader${index}`)
        }
        else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            saveSettingApi({
                h5HeaderCover: appHeader[index],
                surveyId: location.state.id,
                type: 2,
            });
            setSelectedAppHeader(item);
            change(`appHeader${index}`)
        }
    };
    //选择pc封面
    const choicePcCover = (item, index) => {
        if (location.state.route === 'newCreate') {
                saveSettingApi({
                    webCover: pcCover[index],
                    surveyId: location.state.id,
                    type: 1,
                }).then(res=>{
                    if(res.status_code === 200){
                        setSetting(!setting)
                    }
                })
                setSelectedPcCover(item);
                change(`pcCover${index}`)
        }
        else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                saveSettingApi({
                    webCover: pcCover[index],
                    surveyId: location.state.id,
                    type: 2,
                }).then(res=>{
                    if(res.status_code === 200){
                        setSetting(!setting)
                    }
                });
                setSelectedPcCover(item);
                change(`pcCover${index}`)
        }


    };
    //选择app封面
    const choiceAppCover = (item, index) => {
        if (location.state.route === 'newCreate') {
                saveSettingApi({
                    h5Cover: appCover[index],
                    surveyId: location.state.id,
                    type: 1,
                });
                setSelectedAppCover(item);
                change(`appCover${index}`)

        }
        else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({
                        h5Cover: appCover[index],
                        surveyId: location.state.id,
                        type: 2,
                    });
                    setSelectedAppCover(item);
                    change(`appCover${index}`)

        }

    };
    //删除图片
    const delimg = (type) => {
        console.log(type);
        switch (type) {
            case "pcHeaderPreview":
                let pcHedpreview = [...pcHeaderPreview];
                let finalPcHeader = [...pcHeader];
                let newPcHeaderpreview = pcHedpreview.slice(
                    0,
                    pcHedpreview.length
                );
                newPcHeaderpreview.pop();
                let newFinalPcHeader = finalPcHeader.slice(
                    0,
                    finalPcHeader.length
                );
                newFinalPcHeader.pop();
                if (location.state.route === 'newCreate') {
                    saveSettingApi({
                        webHeaderCover: pcHeader[0],
                         surveyId: location.state.id,
                         type: 1,
                     }).then(
                         (res)=>{
                             if(res.status_code===200){
                                 setPcHeaderPreview(newPcHeaderpreview);
                                 setPcHeader(newFinalPcHeader);
                                 change('pcHeader0')
                             }})
                }
                else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({
                        webHeaderCover: pcHeader[0],
                         surveyId: location.state.id,
                         type: 2,
                     }).then(
                         (res)=>{
                             if(res.status_code===200){
                                 setPcHeaderPreview(newPcHeaderpreview);
                                 setPcHeader(newFinalPcHeader);
                                 change('pcHeader0')
                             }})
                }

                break;
            case "appHeaderPreview":
                let appHedpreview = [...appHeaderPreview];
                let finalAppHeader = [...appHeader];
                let newAppHeaderpreview = appHedpreview.slice(
                    0,
                    appHedpreview.length
                );
                newAppHeaderpreview.pop();
                let newFinalAppHeader = finalAppHeader.slice(
                    0,
                    finalAppHeader.length
                );
                newFinalAppHeader.pop();
                if (location.state.route === 'newCreate') {
                    saveSettingApi({
                        h5HeaderCover:appHeader[0],
                         surveyId: location.state.id,
                         type: 1,
                     }).then(
                        (res)=>{
                            if(res.status_code===200){
                                setAppHeaderPreview(newAppHeaderpreview);
                                setAppHeader(newFinalAppHeader);
                                change('appHeader0')
                            }
                        }
                     )
                }
                else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({
                        h5HeaderCover:appHeader[0],
                         surveyId: location.state.id,
                         type: 2,
                     }).then(
                        (res)=>{
                            if(res.status_code===200){
                                setAppHeaderPreview(newAppHeaderpreview);
                                setAppHeader(newFinalAppHeader);
                                change('appHeader0')
                            }
                        }
                     )
                }

                break;
            case "pcCoverPreview":
                let pcCovpreview = [...pcCoverPreview];
                let finalPcCorver = [...pcCover];
                let newPcCovpreview = pcCovpreview.slice(
                    0,
                    pcCovpreview.length
                );
                newPcCovpreview.pop();
                let newPcCorver = finalPcCorver.slice(0, finalPcCorver.length);
                newPcCorver.pop();
                if (location.state.route === 'newCreate') {
                    saveSettingApi({
                        webCover:pcCover[0],
                         surveyId: location.state.id,
                         type: 1,
                     }).then(
                        (res)=>{
                        if(res.status_code===200){
                            setPcCoverPreview(newPcCovpreview);
                            setPcCover(newPcCorver);
                            change('pcCover0')
                        }})
                }
                else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({
                        webCover:pcCover[0],
                         surveyId: location.state.id,
                         type: 2,
                     }).then(
                        (res)=>{
                        if(res.status_code===200){
                            setPcCoverPreview(newPcCovpreview);
                            setPcCover(newPcCorver);
                            change('pcCover0')
                        }})
                }


                break;
            case "appCoverPreview":
                let appCovpreview = [...appCoverPreview];
                let finalAppCover = [...appCover];
                let newAppCovpreview = appCovpreview.slice(
                    0,
                    appCovpreview.length
                );
                newAppCovpreview.pop();
                let newAppCover = finalAppCover.slice(0, finalAppCover.length);
                newAppCover.pop();
                if (location.state.route === 'newCreate') {
                    saveSettingApi({
                        h5Cover:appCover[0],
                         surveyId: location.state.id,
                         type: 1,
                     }).then(
                        (res)=>{
                            if(res.status_code===200){
                                setAppCoverPreview(newAppCovpreview);
                                setAppCover(newAppCover);
                                change('appCover0')
                            }}
                     )
                }
                else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({
                        h5Cover:appCover[0],
                         surveyId: location.state.id,
                         type: 2,
                     }).then(
                        (res)=>{
                            if(res.status_code===200){
                                setAppCoverPreview(newAppCovpreview);
                                setAppCover(newAppCover);
                                change('appCover0')
                            }}
                     )
                }


                break;
            default:
                break;
        }
    };
    return (
        <Fragment>
            <div className="facade-action">
                <div className="facade-header">
                    <div>PC头图</div>
                    <Switch
                        onChange={onPcheader}
                        checkedChildren="开"
                        unCheckedChildren="关"
                        checked={pcControl}
                        size="small"
                    />
                </div>
                {pcControl ? (
                    <div className="pc-headerList">
                        {pcHeaderPreview &&
                            pcHeaderPreview.map((item, index) => {
                                return (
                                    <span className="pc-header-box">
                                        <img
                                            className={` ${
                                                selectedPcHeader ===
                                                `pcHeader${index}`
                                                    ? " selected"
                                                    : "pc-width"
                                            }`}
                                            onClick={() =>
                                                choicePcHeader(
                                                    `pcHeader${index}`,
                                                    index
                                                )
                                            }
                                            src={item}
                                        />
                                        <CloseCircleOutlined
                                            className="del-app-img"
                                            onClick={() =>
                                                delimg("pcHeaderPreview")
                                            }
                                        />
                                    </span>
                                );
                            })}

                        {pcHeaderPreview.length === 4 ? null : (
                            <div className="pc-cover">
                                <Upload
                                    beforeUpload={(file)=>beforeUpload(file,'pc')}
                                    customRequest={(value) =>
                                        upload(value, "pcHeaderPreview")
                                    }
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div className="upload-text">
                                支持1920*1080，不超过5M的图片，支持格式JPG、PNG
                                </div>
                                    </div>
                                </Upload>
                            </div>
                        )}
                    </div>
                ) : (
                    ""
                )}

                <div className="facade-header">
                    <div>APP头图</div>
                    <Switch
                        onChange={onAppHeader}
                        checkedChildren="开"
                        unCheckedChildren="关"
                        checked={appControl}
                        size="small"
                    />
                </div>

                {appControl ? (
                    <div className="app-headerList">
                        {appHeaderPreview &&
                            appHeaderPreview.map((item, index) => {
                                return (
                                    <span className="app-header-box">
                                        <img
                                            className={` ${
                                                selectedAppHeader ===
                                                `appHeader${index}`
                                                    ? " selected"
                                                    : "pc-width"
                                            }`}
                                            onClick={() =>
                                                choiceAppHeader(
                                                    `appHeader${index}`,
                                                    index
                                                )
                                            }
                                            src={item}
                                        />
                                        <CloseCircleOutlined
                                            className="del-app-img"
                                            onClick={() =>
                                                delimg("appHeaderPreview")
                                            }
                                        />
                                    </span>
                                );
                            })}

                        {appHeaderPreview.length === 4 ? null : (
                            <div className="pc-cover">
                                <Upload
                                   beforeUpload={(file)=>beforeUpload(file,'pc')}
                                    customRequest={(value) =>
                                        upload(value, "appHeaderPreview")
                                    }
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div className="upload-text">
                                支持750*424，不超过5M的图片，支持格式JPG、PNG
                                </div>
                                    </div>
                                </Upload>
                            </div>
                        )}
                    </div>
                ) : (
                    ""
                )}

                <div className="facade-header">
                    <div>PC封面</div>
                </div>
                <div className="pc-corverList">
                    {pcCoverPreview &&
                        pcCoverPreview.map((item, index) => {
                            return (
                                <span className="pc-img-box">
                                    <img
                                        className={` ${
                                            selectedPcCover ===
                                            `pcCover${index}`
                                                ? " selected"
                                                : "pc-width"
                                        }`}
                                        onClick={() =>
                                            choicePcCover(
                                                `pcCover${index}`,
                                                index
                                            )
                                        }
                                        src={item}
                                    />
                                    <CloseCircleOutlined
                                        className="del-app-img"
                                        onClick={() => delimg("pcCoverPreview")}
                                    />
                                </span>
                            );
                        })}
                </div>
                {pcCoverPreview.length === 3 ? null : (
                    <div className="pc-cover">
                        <Upload
                           beforeUpload={(file)=>beforeUpload(file,'pc')}
                            customRequest={(value) =>
                                upload(value, "pcCoverPreview")
                            }
                        >
                            <div>
                                <PlusOutlined />
                                <div className="upload-text">
                                支持1920*1080，不超过5M的图片，支持格式JPG、PNG
                                </div>
                            </div>
                        </Upload>
                    </div>
                )}

                <div className="facade-header">
                    <div>APP封面</div>
                </div>
                <div className="app-box">
                    {appCoverPreview &&
                        appCoverPreview.map((item, index) => {
                            return (
                                <span className="app-img-box">
                                    <img
                                        className={` ${
                                            selectedAppCover ===
                                            `appCover${index}`
                                                ? " app-selected"
                                                : "app-width"
                                        }`}
                                        onClick={() =>
                                            choiceAppCover(
                                                `appCover${index}`,
                                                index
                                            )
                                        }
                                        src={item}
                                    />
                                    <CloseCircleOutlined
                                        className="del-app-img"
                                        onClick={() =>
                                            delimg("appCoverPreview")
                                        }
                                    />
                                </span>
                            );
                        })}

                    {appCoverPreview.length === 3 ? null : (
                        <div className="app-cover">
                            <Upload
                               beforeUpload={(file)=>beforeUpload(file,'app')}
                                customRequest={(value) =>
                                    upload(value, "appCoverPreview")
                                }
                            >
                                <div>
                                    <PlusOutlined className="add-icon"/>
                                    <div className="upload-text">
                                支持750*1334，不超过5M的图片，支持格式JPG、PNG
                                </div>
                                </div>
                            </Upload>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Facadeaction;
