import React, { useState, useEffect } from 'react';
import { LeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useLocation, Link, useParams } from 'react-router-dom';
import { Layout, Tooltip } from "antd";
import './SignInStatistics.less';
import { signInStatisticsApi } from '@services/Conference/signIn';

const { Content } = Layout;

// 签到管理 - 签到统计
const SignInStatistics = () => {
    const location = useLocation();
    const params = useParams();

    const [infoData, setInfoData] = useState();

    const getStatisticsData = async() => {
        const res = await signInStatisticsApi({ conferenceId: params.id, signId: location.state?.id });
        if (res && res.status_code === 200) {
            setInfoData(res.data);
        };
    };

    // 获取物料统计
    const getMaterialCount = (material, type) => {
        let count = 0;
        let num = 0;
        if (material && material.length) {
            const option = material.filter(item => item.code === type);
            if (option && option.length) {
                count = option[0].count;
                num = option[0].grantNum;
            }
        }
        return num + '/' + count;
    };

    useEffect(() => {
        getStatisticsData();
    }, []);

    return (
        <>
            <div className="page-title">
                <Link to={`/conf/conferences/${params.id}/signup?type=3`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">签到统计</span>
                <span className="current-subtitle">{location.state?.name}</span>
            </div>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="signin-statistics">
                        <h3>签到统计</h3>
                        <div className="statistics-wrap">
                            <div className="statistics-item"><p>签到人数</p><p>{infoData?.sign?.signCount}</p></div>
                            <div className="statistics-item"><p>报名人数</p><p>{infoData?.sign?.registrationCount}</p></div>
                            <div className="statistics-item"><p>决策人签到人数</p><p>{infoData?.sign?.decisionCount}</p></div>
                        </div>

                        <h3>物料统计</h3>
                        <div className="statistics-wrap" style={{marginBottom: '20px'}}>
                            <div className="statistics-item">
                                <p>发放参会嘉宾胸牌数<Tooltip title="前面数字为发放参会嘉宾胸牌数，后面为设定参会嘉宾胸牌数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'id_badge')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放自助餐数<Tooltip title="前面数字为发放餐券数，后面为设定自助餐数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'meal_ticket')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放资料袋数<Tooltip title="前面数字为发放资料袋数，后面为设定资料袋数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'document_bag')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放伴手礼数<Tooltip title="前面数字为发放伴手礼数，后面为设定伴手礼数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'souvenir')}</p>
                            </div>
                            {/* <div className="statistics-item">
                                <p>发放商务餐券数<Tooltip title="前面数字为发放伴手礼数，后面为设定商务餐券数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'biz_meal_ticket')}</p>
                            </div> */}
                            <div className="statistics-item">
                                <p>发放健康界会员数<Tooltip title="前面数字为发放健康界会员数数，后面为设定健康界会员数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'vip')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放停车券数<Tooltip title="前面数字为发放停车券数，后面为设定停车券数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'car_ticket')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放商务简餐数<Tooltip title="前面数字为发放商务简餐数，后面为设定商务简餐数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'boxed_meal')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放参赛选手胸牌（紫绳）数<Tooltip title="前面数字为发放参赛选手胸牌（紫绳）数，后面为设定参赛选手胸牌（紫绳）数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'race_badge')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放参赛选手胸牌（黄绳）数<Tooltip title="前面数字为发放参赛选手胸牌（黄绳）数，后面为设定参赛选手胸牌（黄绳）数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'race_badge_yellow')}</p>
                            </div>
                            {/* <div className="statistics-item">
                                <p>发放VIP思享汇冷餐会数<Tooltip title="前面数字为发放VIP思享汇冷餐会数，后面为设定VIP思享汇冷餐会数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'vip_thought_reunion_buffet')}</p>
                            </div> */}
                            <div className="statistics-item">
                                <p>发放全民健康跑T恤数<Tooltip title="前面数字为发放全民健康跑T恤数，后面为设定全民健康跑T恤数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'health_run')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放参访海南博鳌乐城医疗旅游先行区数<Tooltip title="前面数字为发放参访海南博鳌乐城医疗旅游先行区数，后面为设定参访海南博鳌乐城医疗旅游先行区数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'hainan_bo_ao_medical_tourism')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放参展商胸牌数<Tooltip title="前面数字为发放参展商胸牌数，后面为设定参展商胸牌数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'exhibitor_card')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放VIP胸牌数<Tooltip title="前面数字为发放VIP胸牌数，后面为设定VIP胸牌数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'vip_badge')}</p>
                            </div>
                            <div className="statistics-item">
                                <p>发放工作人员胸牌数<Tooltip title="前面数字为发放工作人员胸牌数，后面为设定工作人员胸牌数"><InfoCircleOutlined /></Tooltip></p>
                                <p>{getMaterialCount(infoData?.material, 'worker_badge')}</p>
                            </div>
                        </div>

                        <h3>会场身份签到统计</h3>
                        <div className="statistics-wrap">
                            {
                                !!infoData?.identity && !!infoData?.identity?.length && infoData.identity.map(item => (
                                    <div className="statistics-item"><p>{item.name}</p><p>{item.signCount}</p></div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default SignInStatistics;
