import { get, put, post } from '@core/request';


// 获取报名物料配置
export const getSuppliesPageApi = (params) => {
    return get('/conference/boss/supplies/list', params);
};

// 获取报名物料配置
export const updateSuppliesPageApi = (data) => {
    return put('/conference/boss/supplies/update', data);
};

// 获取身份权益配置列表
export const getConferenceIdentitySuppliesApi = (params) => {
    return get('/conference/boss/conferenceIdentitySupplies', params);
};

// 保存身份权益配置
export const updateConferenceIdentitySuppliesApi  = (data) => {
    return post('/conference/boss/conferenceIdentitySupplies', data);
};

// 删除身份权益配置
export const deleteConferenceIdentitySuppliesApi  = (params) => {
    return get('/conference/boss/conferenceIdentitySupplies/delete', params);
};


// 报名列表
export const registrationsListApi  = (params) => {
    return get('/conference/boss/registrations/page', params);
};

// 报名列表
export const registrationsGetOneApi  = (params) => {
    return get('/conference/boss/registrations/getOne', params);
};

// 报名列表 - 删除
export const registrationsDeleteApi  = (params) => {
    return get('/conference/boss/registrations/delete', params);
};

// 报名列表 - 设置联系人
export const registrationsSetContactApi  = (params) => {
    return put('/conference/boss/registrations/set-contract', params);
};

// 报名列表 - 导入数据模板
export const importDataCheckApi  = (params) => {
    return post('/conference/boss/registrations/import-check', params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

// 报名列表 - 开始导入数据
export const confirmImportDataApi  = (params) => {
    return post('/conference/boss/registrations/import', params);
};

// 报名列表 - 添加报名
export const addRegistrationsApi  = (params) => {
    return post('/conference/boss/registrations/save', params);
};

// 单位机构列表
export const caseOrganizationApi = (data) => {
    return get(`/dataengine/api/organisationinfo/search`, data);
}

// 单位机构新增
export const caseAddOrganizationApi = (data) => {
    return post(`/dataengine/admin/organisationinfo/save`, data);
}

// 认证身份接口
export const authdictionaryApi = (data) => {
    return get(`/dataengine/api/authdictionary/json/ex`, data);
}

// 认证身份接口
export const sendMpMessageApi = (data) => {
    return get(`/conference/boss/registrations/sendMpMessage`, data);
}

