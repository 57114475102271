import DmsDashboard from '../components/Dms/Dashboard/DmsDashboard';
import ProductionLines from '../components/Dms/Enterprise/ProductionLines/ProductionLines';
import EnterpriseList from '../components/Dms/Enterprise/EnterpriseManage/EnterpriseList';
import ProductionUsage from '../components/Dms/Enterprise/ProdutionUsage/ProductionUsage';
import LiveList from '../components/Dms/Live/LiveList/LiveList';
import EditLive from '../components/Dms/Live/EditLive/EditLive';
import PlayBack from '../components/Dms/Live/Playback/PlayBack';
import UserStatistics from '../components/Dms/Live/UserStatistics/UserStatistics';
import Interaction from '../components/Dms/Live/Interaction/Interaction';
import InteractionChat from '../components/Dms/Live/Interaction/InteractionChat';
import LiveWhiteList from '../components/Dms/Live/LivesLimits/LiveWhitelist/LiveWhitelist';
import InvitationCodes from '../components/Dms/Live/LivesLimits/InvitationCodes/InvitationCodes';
import LiveCategories from '../components/Dms/Live/LiveCategories/LiveCategories';
import ProductionLinesTopics from '../components/Dms/Live/ProductionLinesTopics/ProductionLinesTopics';
import UserList from '../components/Dms/UserManage/UserList/UserList';
import UserDetails from '../components/Dms/UserManage/UserList/UserDetails';
import Lecturers from '../components/Dms/UserManage/Lecturers/Lecturers';
import LecturersAddForm from '../components/Dms/UserManage/Lecturers/LecturersAddForm';
import ProductionBanner from '../components/Dms/ProductionBanner/ProductionBanner';
import ComplianceList from '../components/Dms/Compliance/ComplianceList';
import ComplianceStatistics from '../components/Dms/Compliance/ComplianceStatistics';
import SurveysList from '../components/Dms/Surveys/SurveysList/SurveysList';
import SurveysNew from '../components/Dms/Surveys/SurveysNew/SurveysNew';
import SurveysAnswer from '../components/Dms/Surveys/SurveysAnswer';
import SurveysData from '../components/Dms/Surveys/SurveysData/SurveysData';
import SurveysUsers from '../components/Dms/Surveys/SurveysUsers';
import ShortAnswerDetails from '../components/Dms/Surveys/ShortAnswerDetails';
import PlaybackWhitelist from '../components/Dms/Live/LivesLimits/PlaybackWhiltlist/PlaybackWhitelist';
import LivesLimits from '../components/Dms/Live/LivesLimits/LivesLimits';
import DataOverview from '../components/Dms/Live/DataOverview/DataOverview';
import ResourceDocs from '../components/Dms/ResourceDocs/ResourceDocs';
import CooperationList from '../components/Dms/CooperationList/CooperationList';
import OverviewData from '../components/Dms/DataOverview/OverviewData';

// 医药数字化营销相关路由
const dmsRoutes = [
    // 医药数字化营销 - 首页
    { path: '', element: <DmsDashboard /> },
    // 医药数字化营销 - 企业管理 - 产品线管理
    { path: 'production-lines', element: <ProductionLines /> },
    // 医药数字化营销 - 企业管理 -企业管理
    { path: 'enterprises', element: <EnterpriseList /> },
    // 医药数字化营销 - 企业管理 - 产品线用量统计
    { path: 'production-lines/:id/data/usage', element: <ProductionUsage /> },
    // 医药数字化营销 - 直播管理 - 直播列表
    { path: 'lives', element: <LiveList /> },
    // 医药数字化营销 - 直播管理 - 编辑直播(直播活动详情)   （富文本编辑器）
    { path: 'lives/:id', element: <EditLive /> },
    // 医药数字化营销 - 直播详情 - 互动统计
    { path: 'lives/:id/interaction', element: <Interaction /> },
    // 医药数字化营销 - 直播详情 - 互动统计 - 聊天
    { path: 'lives/:id/interaction/chat', element: <InteractionChat /> },
    // 医药数字化营销 - 直播详情 - 回放管理 - 回放列表
    { path: 'lives/:id/replays', element: <PlayBack /> },
    // 医药数字化营销 - 直播详情 - 用户统计 - 直播
    { path: 'lives/:id/data/user/live', element: <UserStatistics /> },
    // 医药数字化营销 - 直播详情 - 用户统计 - 回放
    { path: 'lives/:id/data/user/replays', element: <UserStatistics /> },
    // 医药数字化营销 - 直播详情 - 直播数据报告
    { path: 'lives/:id/data/overview', element: <DataOverview /> },
    // 医药数字化营销 - 观看限制 - 观看限制管理
    { path: 'lives/limits', element: <LivesLimits /> },
    // 医药数字化营销 - 观看限制 - 直播白名单管理
    { path: 'lives/:id/whitelist', element: <LiveWhiteList /> },
    // 医药数字化营销 - 观看限制 - 直播邀请码管理
    { path: 'lives/:id/invitation-codes', element: <InvitationCodes /> },
    // 医药数字化营销 - 观看限制 - 回放白名单管理
    { path: 'lives/:id/replays/whitelist', element: <PlaybackWhitelist /> },
    // 医药数字化营销 - 直播分类 - 列表
    { path: 'lives/categories', element: <LiveCategories /> },
    // 医药数字化营销 - 直播分类 - 产品线专题管理
    { path: 'production-lines/topics', element: <ProductionLinesTopics /> },
    // 医药数字化营销 - 用户管理 - 用户列表
    { path: 'users', element: <UserList /> },
    // 医药数字化营销 - 用户管理 - 用户详情
    { path: 'users/:id', element: <UserDetails /> },
    // 医药数字化营销 - 用户管理 - 机构讲师管理
    { path: 'lecturers', element: <Lecturers /> },
    // 医药数字化营销 - 用户管理 - 机构讲师新增
    { path: 'lecturers/new', element: <LecturersAddForm /> },
    // 医药数字化营销 - 用户管理 - 机构讲师编辑
    { path: 'lecturers/:id', element: <LecturersAddForm /> },
    // 医药数字化营销 - 后台配置 - 产品线轮播图管理
    { path: 'production-lines/banners', element: <ProductionBanner /> },
    // 医药数字化营销 - 合规化管理 - 合规化列表
    { path: 'compliance/user/lives', element: <ComplianceList /> },
    // 医药数字化营销 - 合规化管理 - 合规化统计
    { path: 'compliance/user/lives/:id', element: <ComplianceStatistics /> },
    // 医药数字化营销 - 问卷管理 - 问卷列表
    { path: 'surveys', element: <SurveysList /> },
    // 医药数字化营销 - 问卷管理 - 创建问卷
    { path: 'surveys/new', element: <SurveysNew /> },
    // 医药数字化营销 - 问卷管理 - 编辑问卷
    { path: 'surveys/:id', element: <SurveysNew /> },
    // 医药数字化营销 - 问卷管理 - 问卷答题详情
    { path: 'surveys/:id/answer/:aid', element: <SurveysAnswer /> },
    // 医药数字化营销 - 问卷管理 - 问卷统计
    { path: 'surveys/:id/data', element: <SurveysData /> },
    // 医药数字化营销 - 问卷管理 - 问卷用户管理(发布问卷)
    { path: 'surveys/:id/users', element: <SurveysUsers /> },
    // 医药数字化营销 - 问卷管理 - 问卷统计 - 简答题详情
    { path: 'surveys/:id/short', element: <ShortAnswerDetails /> },
    // 医药数字化营销 - 资源管理 - 文档管理
    { path: 'resource/docs', element: <ResourceDocs /> },
    // 医药数字化营销 - 数据中心 - 数据总览
    { path: 'data/overview', element: <OverviewData /> },
    // 医药数字化营销 - 合作单位管理 - 合作单位管理
    { path: 'cooperation/list', element: <CooperationList /> }
];

export default dmsRoutes;
