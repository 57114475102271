import ConferenceDashboard from '../components/Conference/Dashboard/ConferenceDashboard';
import ConferenceList from '../components/Conference/Conference/ConferenceList';
import Conference from '../components/Conference/Conference/Conference';
import SiteModules from '../components/Conference/Site/SiteModules';
import AgendaList from '../components/Conference/Agenda/AgendaList';
import GuestList from '../components/Conference/Guest/GuestList';
import LiveList from '../components/Conference/Live/LiveList';
import Live from '../components/Conference/Live/Live';
import LiveLimit from '../components/Conference/Live/LiveLimit';
import LiveWhitelist from '../components/Conference/Live/LiveWhitelist';
import LiveReplays from '../components/Conference/Live/LiveReplays';
import LiveComments from '../components/Conference/Live/LiveComments';
import NewLabourExpenses from '../components/Conference/LaborFeeRecords';
import SignUp from '../components/Conference/SignUp';
import SignIn from '../components/Conference/SignIn';
import SignInSetting from '../components/Conference/SignIn/SignInSetting';
import SignInDetail from '../components/Conference/SignIn/SignInDetail';
import SignInStatistics from '../components/Conference/SignIn/SignInStatistics';
import ConferenceUsage from '../components/Conference/Usage/ConferenceUsage';
import GuestJourneyList from '../components/Conference/Guest/GuestJourneyList';
import OrganizationList from '../components/Conference/Organization/OrganizationList';
import SignUpData from '../components/Conference/Live/LiveSignUp/index.jsx';
import LaborProgress from '../components/Conference/LaborProgress';

const conferenceRoutes = [
    { path: '', element: <ConferenceDashboard/> },
    // 会议项目
    { path: 'conferences', element: <ConferenceList/> },
    // 新增会议项目
    { path: 'conferences/new', element: <Conference/> },
    // 编辑会议项目
    { path: 'conferences/:id', element: <Conference/> },
    // 会议项目-网站模块
    { path: 'conferences/:id/site-modules', element: <SiteModules/> },
    // 会议项目-日程管理
    { path: 'conferences/:id/agendas', element: <AgendaList/> },
    // 会议项目-嘉宾管理
    { path: 'conferences/:id/guests', element: <GuestList/> },
    // 会议项目-嘉宾管理-嘉宾行程
    { path: 'conferences/:id/guests/journeys', element: <GuestJourneyList/>},
    // 会议项目-机构管理
    { path: 'conferences/:id/organizations', element: <OrganizationList/> },
    // 会议项目-直播管理
    { path: 'conferences/:id/lives', element: <LiveList/> },
    // 新增/编辑直播
    { path: 'conferences/:cid/lives/new', element: <Live/> },
    { path: 'conferences/:cid/lives/:id', element: <Live/> },
    // 直播观看限制
    { path: 'conferences/:cid/lives/:id/limit', element: <LiveLimit/> },
    // 直播观看限制-白名单
    { path: 'conferences/:cid/lives/:id/whitelist', element: <LiveWhitelist/> },
    // 直播回话
    { path: 'conferences/:cid/lives/:id/replays', element: <LiveReplays/> },
    // 直播评论
    { path: 'conferences/:cid/lives/:id/comments', element: <LiveComments/> },
    // 劳务费管理
    // { path: 'conferences/:id/labour-expenses', element: <LabourExpenses/> }, // 旧版劳务信息暂时隐藏
    { path: 'conferences/:id/laborfee', element: <NewLabourExpenses/> },

    // 报名管理
    { path: 'conferences/:id/signup', element: <SignUp/> },

    // 签到管理
    { path: 'conferences/:id/signin', element: <SignIn/> },
    // 签到管理 - 签到设置
    { path: 'conferences/:id/signin-setting', element: <SignInSetting/> },
    // 签到管理 - 签到明细
    { path: 'conferences/:id/signin-detail', element: <SignInDetail/> },
    // 签到管理 - 签到统计
    { path: 'conferences/:id/signin-statistics', element: <SignInStatistics/> },

    { path: 'conferences/usage', element: <ConferenceUsage/> },

    // 直播预约报名
    { path: 'conferences/:cid/lives/:id/signup-data', element: <SignUpData/> },
    
    // 项目劳务进度
    { path: 'conferences/labor-progress', element: <LaborProgress/> },
];

export default conferenceRoutes;
