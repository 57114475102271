
import { get, put, post } from '@core/request';

/*
    接口服务--用户库
*/

// 用户列表
export const getExpertListApi = (params) => {
    return get('/user/ssomemberexperts/list', params);
};

//创建
export const saveExpertApi = (data) => {
    return post('/user/ssomemberexperts/save', data);
}
// 删除用户
export const deleteExpertApi = (params) => {
    return get('/user/ssomemberexperts/delete', params);
};
//基本信息
export const getBaseInfoApi = (params) => {
    return get('user/ssomemberexperts/base-info', params);
};
//更多信息
export const getMoreInfoApi = (params) => {
    return get('user/ssomemberexperts/extend-info', params);
};
//劳务信息
export const getAccountInfoApi = (params) => {
    return get('user/ssomemberexperts/account-info', params);
};

//编辑
export const editInfoApi = (params) => {
    return put('user/ssomemberexperts/edit', params);
};

//上传图片

export const postExpertApi = (data,opinion) => {
    return post('/user/file', data,opinion);
}

//删除联系人信息
export const delPeopleApi = (params) => {
    return get('user/ssomembercontacts/delete', params);
};

//删除联系人信息
export const delPoistionApi = (params) => {
    return get('user/ssomemberworkexperiences/delete', params);
};

// 修改专家手机号
export const changeExpertMobile = data => put('/user/ssomemberexperts/update-phone', data);

//国籍列表
export const sysdictionaryApi = (params) => {
    return get('dataengine/api/sysdictionary/getByGroup', params);
};

