import { createSlice } from '@reduxjs/toolkit';

const commonState = {
    filter: {
        page: 1,
        pageSize: 20,
    },
    cursor: 0,
};

const initialState = {
    project: {
        ...commonState,
        // filter: {
        //     ...commonState.filter,
        //     isTesting: 0,
        // }
    },

    // TODO
};

const projectSlice = createSlice({
    name: 'medProject',
    initialState,
    reducers: {
        setProjectFilter: (state, action) => void(state.project.filter = action.payload),
        setProjectCursor: (state, action) => void(state.project.cursor = action.payload),
    }
});

export const projectActions = projectSlice.actions;

export default projectSlice;
