import React, { Fragment, useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, DatePicker, Button, Space, Select } from 'antd';
import moment from 'moment';
import './Users.less'
const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

export default function UsersFilterForm({
    handleFilterFinish, postData, titleData, officeData
}) {
    const [form] = Form.useForm();

    // 展开关闭state
    const [expand, setExpand] = useState(false);

    // 展开 收起事件
    const handleClickExpand = () => {
        setExpand(!expand);
    }

    // 时间
    const [timePicker, setTimePicker] = useState({
        register: [],
        verify: [],
        last: []
    });

    const handleChangeTime = (tip) => {
        return (value, dateString) => {
            setTimePicker({
                ...timePicker,
                [tip]: dateString
            })
        }
    }

    const onFinish = (values) => {
        values.memRegistStartTime = timePicker.register[0];
        values.memRegistEndTime = timePicker.register[1];
        values.memExamineStartTime = timePicker.verify[0];
        values.memExamineEndTime = timePicker.verify[1];
        values.memLastestLoginStartTime = timePicker.last[0];
        values.memLastestLoginEndTime = timePicker.last[1];
        handleFilterFinish(values);
    };

    const disabledDate = (current) => {
        return current > moment().endOf('day');
    }

    const onReset = () => {
        form.resetFields();
        setTimePicker({
            register: [],
            verify: [],
            last: []
        })
        let values = {
            memCard: undefined, memDeviceType: undefined, memEmail: undefined, registerTime: undefined, memIdentity: undefined, memJobtitle: undefined,
            memSex: undefined, memType: undefined, memUnit: undefined, memUsername: undefined, verifyTime: undefined, memMobile: undefined, memNickname: undefined,
            memOffice: undefined, memPost: undefined, lastTime: undefined, memRegistEndTime: undefined, memRegistStartTime: undefined, memState: undefined,
            memLastestLoginEndTime: undefined, memLastestLoginStartTime: undefined, memExamineEndTime: undefined, memExamineStartTime: undefined
        }
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户唯一标识" name="memCard">
                        <Input allowClear placeholder="输入用户唯一标识" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户昵称" name="memNickname">
                        <Input allowClear placeholder="输入用户昵称" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="memMobile">
                        <Input allowClear placeholder="输入手机号" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户身份" name="memIdentity">
                        <Select allowClear placeholder="请选择用户身份">
                            <Select.Option value='A01'>医务人员</Select.Option>
                            <Select.Option value='A02'>政府官员</Select.Option>
                            <Select.Option value='A03'>商业人士</Select.Option>
                            <Select.Option value='A04'>教育科研人士</Select.Option>
                            <Select.Option value='A06'>社会团体人士</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="科室" name="memOffice">
                        <Select allowClear placeholder="请选择科室" showSearch optionFilterProp="children">
                            {
                                officeData && officeData.map(item => {
                                    return (
                                        <OptGroup label={item.dicName} key={item.dicCode}>
                                            {
                                                item.child.length !== 0 && item.child.map(elem => {
                                                    return (
                                                        <Option key={elem.dicCode} value={elem.dicCode}>{elem.dicName}</Option>
                                                    )
                                                })
                                            }
                                        </OptGroup>
                                    )
                                })
                            }
                        </Select>

                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="会员类别" name="memType">
                        <Select allowClear placeholder="请选择会员类别">
                            <Select.Option value='member'>普通用户</Select.Option>
                            <Select.Option value='vip'>北斗个人会员</Select.Option>
                            <Select.Option value='teamvip'>北斗团队会员</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="单位名称" name="memUnit">
                        <Input allowClear placeholder="输入单位名称" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="职务" name="memPost">
                        <Select allowClear placeholder="请选择职务" showSearch optionFilterProp="children">
                            {postData && postData.map(item => {
                                return (
                                    <Select.Option key={item.dicCode} value={item.dicCode}>{item.dicName}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="职称" name="memJobtitle">
                        <Select allowClear placeholder="请选择职称" showSearch optionFilterProp="children">
                            {titleData && titleData.map(item => {
                                return (
                                    <Select.Option key={item.dicCode} value={item.dicCode}>{item.dicName}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="真实姓名" name="memUsername">
                        <Input allowClear placeholder="输入真实姓名" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="性别" name="memSex">
                        <Select allowClear placeholder="选择性别">
                            <Select.Option value={0}>女</Select.Option>
                            <Select.Option value={1}>男</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="邮箱" name="memEmail">
                        <Input allowClear placeholder="输入邮箱" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="注册端口" name="memDeviceType">
                        <Select allowClear placeholder="请选择注册端口">
                            <Select.Option value='app'>APP</Select.Option>
                            <Select.Option value='wap'>WAP</Select.Option>
                            <Select.Option value='pc'>PC</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="注册时间" name="registerTime">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime('register')}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="认证审核时间" name="verifyTime">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime('verify')}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="最后登录" name="lastTime">
                        <RangePicker
                            disabledDate={disabledDate}
                            allowClear
                            onChange={handleChangeTime('last')}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout} className={expand === false && "display-none-dom"}>
                    <Form.Item label="用户状态" name="memState">
                        <Select allowClear placeholder="请选择用户状态">
                            <Select.Option value={0}>未激活</Select.Option>
                            <Select.Option value={1}>正常</Select.Option>
                            <Select.Option value={2}>禁用</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">搜索</Button>
                        <span className="user-open-box" onClick={handleClickExpand}>{expand ? <Fragment><span>收起</span><UpOutlined className="user-open-icon" /></Fragment> : <Fragment><span>展开</span><DownOutlined /></Fragment>}</span>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
