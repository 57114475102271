import React, { Fragment, useEffect, useState } from 'react';
import { drawerFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Form, Input, Select, Space, Button, Switch } from 'antd';
import { get } from '@/core/request';
const { TextArea } = Input;

export default function LiveCategoriesAddForm({
    setIsModalVisible, handleAddCategotie, modalData, handleEditCategotie
}) {

    const [form] = Form.useForm();
    useEffect(() => {
        if (modalData.tip === 'add') {
            form.setFieldsValue({
                status: true
            })
        } else {
            get('/dms/lives/categories/findById', {
                id: modalData.row.id
            }).then(res => {
                if (res.status_code === 200) {
                    const { name, pid, description, status } = res.data
                    form.setFieldsValue({
                        name,
                        pid: pid === 0 ? undefined : pid,
                        description,
                        status: status === 1 ? true : false
                    })
                }
            })
        }
    }, [form, modalData]);

    const [pidList, setPidList] = useState([]);
    useEffect(() => {
        get(`/dms/lives/categories/querylist`).then((res) => {
            setPidList(res.data);
        });
    }, []);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const onFinish = (value) => {
        value.status = value.status === true ? 1 : 0;
        value.pid = value.pid === undefined ? 0 : value.pid;
        if (modalData.tip === 'add') {
            handleAddCategotie(value);
        } else {
            value.id = modalData.row.id;
            handleEditCategotie(value);
        }
        setIsModalVisible(false);
    }

    return (
        <Fragment>
            <div className="main-content-body">
                <h2 style={{ marginBottom: '20px' }}>创建直播分类</h2>
                <Form {...drawerFormItemLayout} form={form} onFinish={onFinish}>
                    {/*
                    <Form.Item label="分类ID">
                        <span>#1024</span>
                    </Form.Item> */}

                    <Form.Item
                        label="分类名称"
                        name="name"
                        rules={[{ required: true, message: '请填写直播分类名称' }]}
                    >
                        <Input placeholder="请输入直播分类名称" allowClear  autoComplete="off"/>
                    </Form.Item>

                    <Form.Item label="上级分类" name="pid">
                        <Select allowClear placeholder="请选择上级分类">
                            {pidList && pidList.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label="分类描述" name="description">
                        <TextArea
                            placeholder="请输入分类描述"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            showCount maxLength={50}
                        />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="status"
                        valuePropName="checked"
                        required={true}
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item {...pageFormItemWithoutLabelGridLayout} style={{ marginTop: '50px' }}>
                        <Space>
                            <Button className="submit-button" type="primary" htmlType="submit">保存</Button>
                            <Button type="primary" onClick={handleClose}>取消</Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>

        </Fragment>
    );
}
