import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    Button,
    Input,
    Layout,
    Space,
    Tooltip,
    Table,
    Select,
    Dropdown,
    Typography,
    Divider,
    message, DatePicker,
    Modal
} from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined, LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { conferenceOnceApi, getConferenceLivesListApi, delLiveOnceApi, liveSettingApi } from "@services/Conference";
import copy from 'copy-to-clipboard';
import config from '@config';
import LiveUrl from './components/LiveUrl';
import './index.less';
import { convertDateTimeRangeToLocalText } from '@utils/utils';
import { deleteConferenceCache } from '../../../services/Conference';
import moment from 'moment';

const { Content } = Layout;
const { Search } = Input;
const { Text } = Typography;

export default function LiveList() {
    const navigate = useNavigate();

    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const [loading, setLoading] = useState(false);
    const [liveListData, setLiveListData] = useState([]);

    const [filter, setFilter] = useState(null);

    const [liveUrlDrawer, setLiveUrlDrawer] = useState(false);
    const [liveId, setLiveId] = useState(null);
    const [liveName, setLiveName] = useState(null);

    // 获取会议项目信息
    useEffect(() => {
        conferenceOnceApi({ id: conferenceId })
            .then((res) => {
                if (res.status_code === 200) {
                    setConference(res.data);
                }
            });
    }, []);

    // 请求直播列表
    const fetchLiveListData = async () => {
        setLoading(true);
        const res = await getConferenceLivesListApi({ conferenceId });
        if (res?.status_code === 200) {
            setLiveListData(res?.data || []);
        }
        setLoading(false);
    };

    // 获取直播列表
    useEffect(() => {
        fetchLiveListData();
    }, []);

    // 刷新列表
    const refreshData = () => fetchLiveListData();

    // 检索直播名称
    const onSearchLiveName = value => setFilter({
        ...filter,
        name: value,
    });

    // 选择直播状态
    const onChangeFilterLiveStatus = value => setFilter({
        ...filter,
        liveStatus: value,
    });

    // 选择直播日期
    const onChangeFilterLiveTime = value => setFilter({
        ...filter,
        liveDay: value,
    });

    useEffect(() => {
        setLiveListData(liveListData.map(live => {
            const liveDay = filter?.liveDay ? moment(filter.liveDay).format('YYYY-MM-DD') : null;
            const startDate = moment(live.startTime).format('YYYY-MM-DD');
            const endDate = moment(live.endTime).format('YYYY-MM-DD');
            
            let isInRange = false;
            if (liveDay) {
                isInRange = liveDay >= startDate && liveDay <= endDate;
            }
        
            const statusCondition = filter?.liveStatus === undefined || filter?.liveStatus === live.status;
            const nameCondition = !filter?.name || live.name.includes(filter?.name);
            const dayCondition = !liveDay || isInRange;
        
            const hidden = !(statusCondition && nameCondition && dayCondition);
            return { ...live, hidden };
        }));
    }, [filter]);

    // 切换直播状态
    const onSelectLiveStatus = (id, status) => {
        liveSettingApi({ id, status })
            .then(res => {
                if (res?.status_code === 200) {
                    setLiveListData(liveListData.map(live => live.id === liveId ? { ...live, status } : live));
                    message.success('切换直播状态成功');
                }
            });
    };

    // 删除直播
    const deleteLive = id => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否确认删除当前直播',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                delLiveOnceApi({ id })
                    .then(res => {
                        if (res.status_code === 200) {
                            setLiveListData(liveListData.filter(live => live.id !== id));
                            message.success('直播删除成功');
                        }
                    });
            }
        });
    };

    // 复制直播链接
    const copyLiveUrl = record => {
        copy(`${config.HUIYI_DOMAIN}/web/${conference?.slug}/lives/${record?.id}`);
        message.success('直播链接复制成功');
    };

    // 直播观看限制
    const gotoSetupLimit = record => navigate(`/conf/conferences/${conferenceId}/lives/${record?.id}/limit`);

    // 直播回放管理
    const gotoReplayList = record => navigate(`/conf/conferences/${conferenceId}/lives/${record?.id}/replays`, { state: { title: record.name } });

    // 直播评论管理
    const gotoCommentList = record => navigate(`/conf/conferences/${conferenceId}/lives/${record?.id}/comments`);

    // 预约报名数据
    const gotoSignUpList = record => navigate(`/conf/conferences/${conferenceId}/lives/${record?.id}/signup-data`);

    const clearConferenceLiveCache = async live => {
        const data = {
            type: 'conference',
            key: `conference_${conferenceId}_user_live_${live?.id}`
        };
        const res = await deleteConferenceCache(data);
        if (res?.status_code === 200) {
            message.success('更新会议官网嘉宾列表成功！');
        }
    };

    // 更新直播详情缓存：废弃
    // const onClearConferenceLiveCache = live => {
    //     if (conferenceId) {
    //         Modal.confirm({
    //             title: '更新官网直播信息',
    //             icon: <ExclamationCircleOutlined />,
    //             width: 480,
    //             centered: true,
    //             content: <>
    //                 <p>为减轻性能压力，系统自动缓存数据，该功能将更新用户端数据，展现最新设定内容。</p>
    //                 <p><Typography.Text type="danger">会议进行期间谨慎更新！</Typography.Text></p>
    //             </>,
    //             onOk() {
    //                 clearConferenceLiveCache(live);
    //             },
    //             cancelText: '取消，暂不更新',
    //             okText: '知晓，确认更新'
    //         });
    //     }
    // };

    const columns = [
        { title: '直播ID', dataIndex: 'id', width: 80 },
        {
            title: '直播名称',
            dataIndex: 'name',
            width: 300,
            render: (_, record) => {
                return <>
                    <a onClick={() => window.open(`${config.HUIYI_DOMAIN}/web/${conference?.slug}/lives/${record?.id}`)}>{record.name}</a>
                    {!!record?.conferenceName && (<>
                        <br />
                        <Text type="secondary">{`[YHYC#${record.hallsId}] ${record.conferenceName}`}</Text>
                    </>)}
                </>;
            }
        },
        {
            title: '直播时间',
            dataIndex: 'datetime',
            width: 240,
            render: (_, record) => convertDateTimeRangeToLocalText(record.startTime, record.endTime)
        },
        {
            title: '直播状态',
            dataIndex: 'status',
            width: 100,
            render: (_, record) => {
                return (
                    <Select
                        defaultValue={record.status}
                        size="small"
                        onSelect={value => onSelectLiveStatus(record.id, value)}
                    >
                        <Select.Option value={0}>未开始</Select.Option>
                        <Select.Option value={1}>直播中</Select.Option>
                        <Select.Option value={2}>已结束</Select.Option>
                    </Select>
                );
            }
        },
        {
            title: '显示状态',
            dataIndex: 'hidden',
            width: 80,
            render: (_, record) => !record.conferenceSiteHidden ? '显示' : <Text type="warning">隐藏</Text>
        },
        {
            title: '观看限制',
            dataIndex: 'lookCheck',
            width: 80
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 200,
            fixed: 'right',
            render: (_, record) => {
                const items = [
                    { label: '复制链接', key: 'link', onClick: () => copyLiveUrl(record) },
                    { label: '观看限制', key: 'limit', onClick: () => gotoSetupLimit(record) },
                    { label: '回放管理', key: 'replays', onClick: () => gotoReplayList(record) },
                    { label: '评论管理', key: 'comments', onClick: () => gotoCommentList(record) },
                    // { label: '更新直播信息', key: 'comments', danger: true, onClick: () => onClearConferenceLiveCache(record) },
                    { label: '预约报名数据', key: 'signUp', onClick: () => gotoSignUpList(record) },
                    { label: '删除直播', key: 'delete', danger: true, onClick: () => deleteLive(record.id) },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => navigate(`/conf/conferences/${conferenceId}/lives/${record.id}`)}
                        >
                            编辑直播
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                setLiveUrlDrawer(true);
                                setLiveId(record.id);
                                setLiveName(record.name);
                            }}
                        >
                            推拉流信息
                        </Button>
                        <Divider type="vertical" />
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </span>
                );
            }
        }
    ];

    return <Fragment>
        <div className="page-title">
            <Link to="/conf/conferences" className="back"><LeftOutlined /></Link>
            <span className="current-title">直播管理</span>
            <span className="current-subtitle">{conference?.name}</span>
        </div>

        <Content className="main-content-box">

            <div className="main-content-body">

                <div className="main-toolbar">
                    <div className="main-tools">
                        <Space>
                            <Button type="primary" onClick={() => navigate(`/conf/conferences/${conferenceId}/lives/new`)}>新增直播</Button>

                            <Search
                                placeholder="直播名称关键字"
                                allowClear
                                style={{ width: 250 }}
                                onSearch={onSearchLiveName}
                            />
                            <DatePicker placeholder="直播日期" onChange={onChangeFilterLiveTime} />
                            <Select
                                placeholder="直播状态"
                                value={filter?.liveStatus}
                                style={{ width: 100 }}
                                onChange={onChangeFilterLiveStatus}
                                allowClear
                            >
                                <Select.Option value={0}>未开始</Select.Option>
                                <Select.Option value={1}>直播中</Select.Option>
                                <Select.Option value={2}>已结束</Select.Option>
                            </Select>
                        </Space>
                    </div>
                    <div className="sub-tools">
                        <Tooltip title="刷新列表">
                            <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}></Button>
                        </Tooltip>
                    </div>
                </div>

                <Table
                    rowKey={item => item.id}
                    columns={columns}
                    dataSource={liveListData.filter(live => !live.hidden)}
                    loading={loading}
                    pagination={false}
                    scroll={{ x: 1300 }}
                />

            </div>
        </Content>

        <LiveUrl
            liveUrlDrawer={liveUrlDrawer}
            liveName={liveName}
            setLiveUrlDrawer={setLiveUrlDrawer}
            liveId={liveId}
        />
    </Fragment>;
}
