import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { get } from '@/core/request';
import './Surveys.less';

const { Content } = Layout;

export default function ShortAnswerDetails() {

    const { state } = useLocation();

    const [tableData, setTableData] = useState({});
    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    // 渲染table数据
    useEffect(() => {
        get(`/dms/survey/records/queryAnswerCount`, {
            limit: pageSize,
            page: currPage,
            questionId: state.id,
            id: state.surveyId
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.surveyRecordsId;
                });
                setTableData(res.data);
            }
        })
    }, [currPage, pageSize, state.id, state.surveyId]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };


    // 点击查看跳转
    const navigate = useNavigate();
    const changePath = (row) => {
        return () => {
            navigate(`/dms/surveys/:id/answer/${row.surveyRecordsId}`,{state:row});
        }
    };

    // 点击返回跳转到问卷统计
    const changeBackPath = () => {
        navigate(`/dms/surveys/${state.surveyId}/data`,{state});
    }

    const columns = [
        { title: '序号', dataIndex: 'surveyRecordsId' },
        { title: '答案详情', dataIndex: 'answer', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operate, row) => (<span className="operate-style" onClick={changePath(row)}>查看</span>)
        },
    ];

    return (
        <Fragment>
            <div className="page-title"><span onClick={changeBackPath} className="back"><LeftOutlined /></span>简答题详情</div>

            <Content className="main-content-box">

                <div className="main-content-body">
                    <div>
                        <p>{state.id}：{state.name}</p>
                        <Table className="short-answer-table" columns={columns} dataSource={tableData.list} pagination={paginationProps} />
                    </div>



                </div>
            </Content>

        </Fragment>
    )
}
