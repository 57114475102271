import { get, put, post, delet, patch } from '@core/request';

/**
 * 医项目 - 模块管理 - 全部活动api
 */

// 活动列表
export const getActivitiesListApi = (params) =>{
    return get('/medproject/boss/activities/list', params);
}

// 删除活动
export const deleteActivitiesApi = (params) =>{
    return delet('/medproject/boss/activities/delete', params);
}

// 新增活动
export const saveActivitiesApi = (params) =>{
    return post('/medproject/boss/activities/save', params);
}

// 活动 - 显示/隐藏 - 是否推荐
export const setStatusActivitiesApi = (params) =>{
    return put('/medproject/boss/activities/setStatus', params);
}

// 活动排序
export const sortActivitiesListApi = (params) =>{
    return patch('/medproject/boss/activities/sort', params);
}

// 课程分类
export const coursesCatelogsListApi = (params) =>{
    return get('/medproject/boss/content/courses/catelogs', params);
}

// 根据条件查询会议列表
export const searchConferencesListApi = (params) =>{
    return get('/medproject/boss/content/conferences', params);
}

// 根据条件查询评选项目列表
export const searchProjectsListApi = (params) =>{
    return get('/medproject/boss/content/selection/projects/search', params);
}

// 根据条件查询问卷列表
export const searchSurveysListApi = (params) =>{
    return get('/medproject/boss/content/surveys', params);
}

// 根据条件查询课程（直播）列表
export const searchCoursesListApi = (params) =>{
    return get('/medproject/boss/content/courses', params);
}

// 医会议直播列表
export const fetchConferenceLive = params => get('/medproject/boss/content/conference/lives', params);















