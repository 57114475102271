import React, { useCallback, useEffect } from 'react';
import { fullPagerConfig } from '@/utils/property';
import { Table } from 'antd';

export default function UserListTable({ showColumn, tableData, pageSize, setPageSize, setCurrPage, refreshBool, setRefreshBool, currPage }) {
    const tableColumns = [
        { title: '用户唯一标识', dataIndex: 'memCard', fixed: 'left', key: 'memCard', width: 150, className: showColumn[0].checked ? "" : 'not-show' },
        { title: '用户昵称', dataIndex: 'memNickname', className: showColumn[1].checked ? "" : 'not-show' },
        { title: '真实姓名', dataIndex: 'memRealName', className: showColumn[2].checked ? "" : 'not-show' },
        { title: '性别', dataIndex: 'memSex', className: showColumn[3].checked ? "" : 'not-show' },
        { title: '手机号', dataIndex: 'memMobile', className: showColumn[4].checked ? "" : 'not-show' },
        { title: '邮箱', dataIndex: 'memEmail', className: showColumn[5].checked ? "" : 'not-show' },
        { title: '用户身份', dataIndex: 'memIdentity', className: showColumn[6].checked ? "" : 'not-show' },
        { title: '会员类别', dataIndex: 'memType', className: showColumn[7].checked ? "" : 'not-show' },
        { title: '单位名称', dataIndex: 'memUnit', className: showColumn[8].checked ? "" : 'not-show' },
        { title: '科室', dataIndex: 'memOffice', className: showColumn[9].checked ? "" : 'not-show' },
        { title: '职务', dataIndex: 'memPost', className: showColumn[10].checked ? "" : 'not-show' },
        { title: '职称', dataIndex: 'memJobtitle', className: showColumn[11].checked ? "" : 'not-show' },
        { title: '注册端口', dataIndex: 'memDeviceType', className: showColumn[12].checked ? "" : 'not-show' },
        { title: '用户状态', dataIndex: 'memState', className: showColumn[13].checked ? "" : 'not-show' },
        { title: '注册时间', dataIndex: 'memRegistTime', className: showColumn[14].checked ? "" : 'not-show' },
        { title: '认证审核时间', dataIndex: 'memExamineTime', className: showColumn[15].checked ? "" : 'not-show' },
        { title: '最后登录时间', dataIndex: 'memLastestLoginTime', className: showColumn[16].checked ? "" : 'not-show' },
    ];

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );
    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData && tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    useEffect(() => {
        if (refreshBool) {
            changeCurrPage(1)
            setRefreshBool(false)
        }
    }, [refreshBool])

    return (
        <Table className="playback-table"
            columns={tableColumns}
            dataSource={tableData && tableData.list}
            pagination={paginationProps}
            rowKey={record => record.memCard}
        />
    )
}
