import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Layout,
    Space,
    Modal,
    Table,
    Checkbox,
    message,
    Avatar,
    Divider,
    Dropdown,
    Typography,
    Drawer,
    Radio,
} from "antd";
import {
    EllipsisOutlined,
    LeftOutlined,
    RightOutlined,
    ReloadOutlined,
    SearchOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CaretDownOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import { isEmpty } from "@utils/utils";
import moment from "moment";
import { drawerWidth } from "@utils/property";
import { guestDateApi, guestDateUpdateApi } from "@services/Guest";
import "../../GuestList.less";

const GuestTask = (props) => {
    const {
        visibleGuestTasksDrawer,
        closeGuestTasksDrawer,
        rowItem,
        getExpertList
    } = props;

    const params = useParams();
    const conferenceId = Number(params.id);

    const [guestData, setGuestData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const dataSelected = useRef([]);

    const [canReset, setCanReset] = useState(false);
    const [confirmDisabled, setConfirmDisabled] = useState(true);

    console.log(rowItem);

    useEffect(() => {
        if (visibleGuestTasksDrawer) {
            guestDateApi({
                conferenceId: Number(params.id),
                memCard: rowItem.memCard,
                expertId: rowItem.id
            })
                .then(res => {
                    if (res.status_code === 200) {
                        const guestTaskData = res.data;
                        setGuestData(guestTaskData);
                        setTotal(guestTaskData?.length);

                        const tasks = [];
                        guestTaskData.forEach(t => {
                            tasks.push({
                                id: t.id,
                                invitationAccepted: t.invitationAccepted,
                                conferenceId
                            });
                        });
                        dataSelected.current = tasks;

                        const canReset = guestTaskData.some(t => t.invitationAccepted !== 0) || false;
                        setCanReset(canReset);
                    }
                });
        } else {
            setGuestData(undefined)
            // console.log(1);
            dataSelected.current = []
        }
    }, [visibleGuestTasksDrawer, page, pageSize]);

    const onClickRadioButton = (task, status, e) => {
        console.log(dataSelected.current);
        console.log('onClickRadioButton', task, status, e.target.value);

        const taskList = [...dataSelected.current];
        dataSelected.current = taskList.map(t => {
            if (t.id === task.id) {
                if (Number(e.target.value) === task.invitationAccepted) {
                    t.invitationAccepted = 0;
                } else {
                    t.invitationAccepted = status;
                }
            }
            return t;
        });

        const guestTaskList = guestData.map(t => {
            if (t.id === task.id) {
                if (Number(e.target.value) === task.invitationAccepted) {
                    t.invitationAccepted = 0;
                } else {
                    t.invitationAccepted = status;
                }
            }
            return t;
        });
        setGuestData(guestTaskList);

    };

    const onChange = (e, id) => {
        console.log(dataSelected.current);
        const status = Number(e.target.value) || 0;

        const tasksData = dataSelected.current.map(t => {
            if (t.id === id) {
                t.invitationAccepted = status;
            }
            return t;
        });

        dataSelected.current = tasksData;

        // setConfirmDisabled(tasksData?.some(t => t.invitationAccepted === 0));
    };

    const onResetGuestTasks = async () => {
        Modal.confirm({
            title: '提示',
            content: '是否确认将嘉宾任务重置为未反馈？',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                let tasks = [...dataSelected.current] || [];
                console.log(tasks);
                if (!isEmpty(tasks)) {
                    tasks = tasks.map(t => ({ ...t, invitationAccepted: 0 }));
                    const res = await guestDateUpdateApi(tasks);
                    if (res.status_code === 200) {
                        closeGuestTasksDrawer();
                        getExpertList();
                        // onResetGuest('noneChange');
                        // console.log(2);
                        dataSelected.current = [];
                    }
                }

            }
        });
    };

    // 嘉宾任务确认
    const confirmTasks = () => {
        console.log(dataSelected);
        guestDateUpdateApi(dataSelected.current)
            .then((res) => {
                if (res.status_code === 200) {
                    closeGuestTasksDrawer();
                    getExpertList();
                    // onResetGuest('noneChange');
                    // console.log(2);
                    dataSelected.current = [];
                }
            });
    };

    const guestDateColumns = [
        { title: '会议', key: 'conferenceHallName', dataIndex: 'conferenceHallName' },
        { title: '日程', key: 'title', dataIndex: 'title' },
        { title: '任务', key: 'taskName', dataIndex: 'taskName', width: 80, },
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            width: 200,
            render: (_, record) => (
                <small>
                    {record.startTime && <span>{record.startTime}</span>}
                    {record.endTime && <span>&ndash;{moment(record.endTime).format('LTS')}</span>}
                </small>
            ),
        },
        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            width: 180,
            render: (_, record) => (
                <Radio.Group value={record.invitationAccepted}>
                    <Radio value={1} onClick={e => onClickRadioButton(record, 1, e)}>接受</Radio>
                    <Radio value={2} onClick={e => onClickRadioButton(record, 2, e)}>拒绝</Radio>
                </Radio.Group>
            ),
        }
    ];

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const confirmGuestTaskModalFooterButtons = [];
    if (canReset) {
        confirmGuestTaskModalFooterButtons.push(<Button key="reset" danger onClick={onResetGuestTasks}>重置嘉宾任务为未反馈</Button>);
    }
    confirmGuestTaskModalFooterButtons.push(<Button type="primary" key="confirm" onClick={confirmTasks}>确认嘉宾任务</Button>);

    return (
        <div>
            <Modal
                title="代嘉宾确认任务"
                width={1000}
                destroyOnClose
                centered
                open={visibleGuestTasksDrawer}
                onClose={closeGuestTasksDrawer}
                onCancel={closeGuestTasksDrawer}
                footer={confirmGuestTaskModalFooterButtons}
            >
                <div className="expert-info" style={{ marginBottom: 16 }}>
                    <span>邀请用户：{rowItem?.memUsername}</span>
                    {rowItem?.ssoMemberWorkExperiencesEntities?.[0] && (
                        <span className="experiences" style={{ marginLeft: 24 }}>
                            <span>单位任职：</span>
                            <span>{rowItem?.ssoMemberWorkExperiencesEntities?.[0]?.unitName}</span>
                            <span>{rowItem?.ssoMemberWorkExperiencesEntities?.[0]?.unitOffice}</span>
                            <span>{rowItem?.ssoMemberWorkExperiencesEntities?.[0]?.unitPost}</span>
                        </span>
                    )}
                </div>
                <Table columns={guestDateColumns} dataSource={guestData} pagination={false}/>
                <div className="table-total">共{total}条</div>
            </Modal>
        </div>
    );
};

export default GuestTask;
