import React, { useRef, useState, useEffect } from 'react';
import useChart from '@/hooks/useCharts';
import { Row, Col, Table } from 'antd';

export default function CheckboxAnswer({ data, index }) {
    const deviceChartRef = useRef(null)

    const [echartsX, setEchartsX] = useState([]);
    const [echartsData, setEchartsData] = useState([]);

    useEffect(() => {
        let arr = [];
        let arr2 = [];
        data.choiceCountVos.forEach(item => {
            arr.push(item.countent);
            arr2.push(item.number);
        })
        setEchartsX(arr)
        setEchartsData(arr2)
    }, [data.choiceCountVos]);

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            // formatter: '{b}: ({d}%)'
        },
        color:'#3fa5fe',
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: echartsX,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    rotate: 45,
                    color: '#000',
                    margin: 17,
                    fontSize: 10,
                    formatter: (params) => {
                        var newParamsName = ''
                        var paramsNameNumber = params.length
                        var provideNumber = 10//超过10个字符折行
                        var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                        for (let row = 0; row < rowNumber; row++) {
                            newParamsName +=
                                params.substring(
                                    row * provideNumber,
                                    (row + 1) * provideNumber
                                ) + '\n'
                        }
                        return newParamsName
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'count',
                type: 'bar',
                barWidth: '30%',
                data: echartsData,
            }
        ]
    };

    const columns = [
        { title: '选项', dataIndex: 'countent', },
        { title: '人数', dataIndex: 'number', },
        {
            title: '选择比例', dataIndex: 'percentage',
            render:(operate)=>(<span>{operate}%</span>)
        },
    ];

    useChart(deviceChartRef, option)
    return (
        <div className="choice-question">
            <p>{index + 1}：{data.questionName}</p>
            <Row className="choice-question-checkbox">
                <Col span={10} className="choice-question-left">
                    <div className="checkbox-chart" ref={deviceChartRef}></div>
                </Col>
                <Col span={12}>
                    <Table className="checkbox-table" columns={columns} dataSource={data.choiceCountVos} pagination={false} />
                    <Row style={{ marginTop: '15px' }}>
                        <Col span={3}>回答人数</Col>
                        <Col span={4}>{data.count}</Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}
