import React, { useEffect, useRef, useState } from 'react';
import useChart from '@/hooks/useCharts';
import { Row, Col, Table } from 'antd';

export default function DeviceChart({ data, index }) {
    const deviceChartRef = useRef(null)

    const [echartsData, setEchartsData] = useState([]);

    useEffect(() => {
        let arr = [];
        data.choiceCountVos.forEach(item => {
            let obj = {};
            obj.name = item.countent;
            obj.value = item.percentage;
            arr.push(obj)
        })
        setEchartsData(arr)
    }, [data.choiceCountVos]);

    const option = {
        tooltip: {//提示
            trigger: 'item',
            backgroundColor: 'rgba(255,255,255,0.9)',
            axisPointer: {//hover的时候没有虚线
                type: 'none'
            },
            formatter: function (params, ticket, callback) {
                var htmlStr = "";
                var color = params.color;
                htmlStr += '<span style="margin-right:5px;display:inline-block;width:6px;height:6px;border-radius:50%;background-color:' + color + ';"></span>';
                htmlStr += params.name + " : " + params.value + "%";
                return htmlStr;
            }
        },
        legend: {
            orient: 'vertical',
            top: 'bottom',
            left: 'left',
            icon: "circle",
            itemWidth: 10,
            formatter: (name) => {
                if (!name) return ''
                if (name.length > 30) {
                    name = name.slice(0, 30) + '...'
                }
                return name
            }
        },
        color: ['#3fa5fe', '#3fe5fe', '#91cc75', '#fac858', '#ee6666'],
        series: [
            {
                type: 'pie',
                radius: ['20%', '40%'],
                center: ['50%', '35%'],
                avoidLabelOverlap: false,
                labelLine: {
                    length: 20,
                    length2: 0
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    itemStyle: {
                        opacity: '0.9'
                    },
                    scale: 0
                },
                label: {
                    fontSize: 12,
                    formatter: (value) => {
                        if (!value.name) return ''
                        if (value.name.length > 10) {
                            value.name = value.name.slice(0, 10) + '...'
                        }
                        return value.name
                    }
                },
                data: echartsData
            }
        ]
    };

    const columns = [
        { title: '选项', dataIndex: 'countent', },
        { title: '人数', dataIndex: 'number', },
        {
            title: '选择比例', dataIndex: 'percentage',
            render:(operate)=>(<span>{operate}%</span>)
        },
    ];

    useChart(deviceChartRef, option)

    return (
        <div className="choice-question">
            <p>{index + 1}：{data.questionName}</p>
            <Row className="choice-question-divide">
                <Col span={10} className="choice-question-left">
                    <div className="radio-chart" ref={deviceChartRef}></div>
                </Col>
                <Col span={12}>
                    <Table className="radio-table" columns={columns} dataSource={data.choiceCountVos} pagination={false} />
                    <Row style={{ marginTop: '15px' }}>
                        <Col span={3}>回答人数</Col>
                        <Col span={4}>{data.count}</Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}
