import React, { Fragment, useEffect } from 'react';
import { Modal } from 'antd';
import * as _ from 'lodash';
import {getVideoUrlApi} from "@services/Conference";
import "../index.less";


let player = null;
const VideoReplay = ({videoOpen,videoClose,liveReplayId,videoName,playTime}) => {
    //视频播放器配置
    const playerSkinLayout = [
        {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
        {name: "H5Loading", align: "cc"},
        {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
        {name: "infoDisplay"},
        {name:"tooltip", align:"blabs",x: 0, y: 56},
        {name: "thumbnail"},
        {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                {name: "progress", align: "blabs", x: 0, y: 44},
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                // {name:"subtitle", align:"tr",x:15, y:12},
                {name:"setting", align:"tr",x:15, y:12},
                {name: "volume", align: "tr", x: 5, y: 10}
            ]
        }
    ];

    //播放器播放地址
    const playMp4WithoutAuth = videoUrl => {
        player = new  window.Aliplayer({
            id: 'J_prismPlayer',//播放器绑定
            width: '100%',
            height: '100%',
            source: videoUrl,//播放来源
            autoplay: true,
            preload: true,
            controlBarVisibility: 'hover',
            useH5Prism: true,
            skinLayout: playerSkinLayout
        }, function (player) {
            player.play();
            // player.seek(time);
        });

        player.on('ready', function (e) {
        });

        player.on('play', function (e) {
        });

        player.on('pause', function (e) {
        });

        player.on('completeSeek', function (e) {
        });

        player.on('error', function (e) {
        });
    };

    const getVideoPlayInfo =  (playauth,videoId,status) => {

        if (!!playauth &&  window.Aliplayer) {
            if (status === 'Normal') {
                player = new  window.Aliplayer({
                    id: 'J_prismPlayer',
                    width: '100%',
                    height: '100%',
                    vid:videoId,
                    playauth:playauth,
                    autoplay: true,
                    preload: true,
                    controlBarVisibility: 'hover',
                    useH5Prism: true,
                    skinLayout: playerSkinLayout
                }, function (player) {
                    if(playTime===null){
                        player.play();
                    }else{
                        player.seek(playTime)
                    }

                });

                player.on('ready', function (e) {
                });

                player.on('play', function (e) {
                });

                player.on('pause', function (e) {
                });

                player.on('completeSeek', function (e) {
                });

                player.on('error', function (e) {
                });
            }else{}
        } else {
            //
        }
    };
    useEffect(() => {
        if(videoOpen){
            setTimeout(()=>{
                getVideoUrlApi({liveReplayId:liveReplayId}).then(res=>{
                    if(res.status_code === 200){
                        if(res.data.type===2)getVideoPlayInfo(res.data.videoUrl.playAuth,res.data.videoId,res.data.videoUrl.status)
                        else playMp4WithoutAuth(res.data.videoUrl)
                    }
                })
            },1000)
        }
    }, [videoOpen]);
    return(

        <Fragment>
            <Modal
                title={`预览视频--${videoName}`}
                width={1000}
                destroyOnClose
                open={videoOpen}
                onClose={videoClose}
                onCancel={videoClose}
                maskClosable={false}
                footer={null}
            >
            <div className='videoBox'>
                <div id="J_prismPlayer"></div>
            </div>
            </Modal>

    </Fragment>
    )

}

export default VideoReplay;
