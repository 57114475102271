import PrivateLayout from '../components/Layouts/PrivateLayout';
import Login from '../components/Login/Login';
import ForgetPassword from '../components/Login/ForgetPassword';
import NoPermission from '../components/Dms/Common/NoPermission';

import enterpriseRoutes from './enterpriseRoutes';
import dmsRoutes from './dmsRoutes';
import kbRoutes from './kbRoutes';
import mediaRoutes from './mediaRoutes';
import myRoutes from './myRoutes';
import operationRoutes from "./operationRoutes";
import biRoutes from './biRoutes';
import ucRoutes from './ucRoutes';
import healthclassRoutes from './healthclassRoutes';
import invitationRoutes from './invitationRoutes';
import conferenceRoutes from './conferenceRoutes';
import ehaRoutes from "./ehaRoutes";
import SelectionRoutes from "./selectionRoutes";
import Wj from './wjRoutes';
import medProjectRoutes from './medProjectRoutes';
import financeRoutes from './financeRoutes';
import medconnectRoutes from "./medconnectRoutes";

const privateRoutes = [
    {
        path: '/',
        element: <PrivateLayout/>,
        children: [
            // 个人中心相关路由
            { path: 'my', children: myRoutes },
            // 企业管理相关路由
            { path: 'enterprise', children: enterpriseRoutes },
            // 医药数字化营销相关路由
            { path: 'dms', children: dmsRoutes },
            // 媒体平台相关路由
            { path: 'media', children: mediaRoutes },
            // 知识库相关路由
            { path: 'kb', children: kbRoutes },
            // 运营中心
            { path: 'operation', children: operationRoutes },
            // 商业智能中心
            { path: 'bi', children: biRoutes },
            // 用户中心
            { path: 'uc', children: ucRoutes },
            //健学云
            { path: 'healthclass', children: healthclassRoutes },
            // 邀请函
            { path: 'invitation', children: invitationRoutes },
            // 会议模块
            { path: 'conf', children: conferenceRoutes },
            { path: 'medproject', children: medProjectRoutes },
            // 健康号模块
            { path: 'healthaccount', children: ehaRoutes },
            // 医评选
            { path: 'selection', children: SelectionRoutes },
            // 财务中心
            { path: 'finance', children: financeRoutes },
            // 医患通
            { path: 'medconnect', children: medconnectRoutes },
        ]
    },
    //调研组件
    { path: 'wj/*', element: <Wj/> },
    { path: 'login', element: <Login/> },
    { path: 'forgetpassword', element: <ForgetPassword/> },
    { path: 'permission', element: <NoPermission/> },
];

export default privateRoutes;
