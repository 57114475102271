import React, {Fragment, useState, useCallback, useEffect, useRef} from "react";
import {
    Button,
    Layout,
    Table,
    Modal,
    Space,
    message,
    Row,
    Form,
    Upload,
    Radio,
    Tabs,
    Descriptions,
    Image,
    Tag,
    Input,
    Select
} from "antd";
import {fullPagerConfig} from "@/utils/property";
import {get, del, post, put} from "@/core/request";
import "./CheckOrganizationsInfo.less"
import {useLocation, useNavigate} from "react-router-dom";
import {info} from "../../../utils/utils";

export default function CheckOrganizationsInfo() {

    const {Content} = Layout;
    const {Option} = Select;
    const {TextArea} = Input
    let navigate = useNavigate();
    const {state} = useLocation();
    const [infoData, setInfoData] = useState({});
    const [rejectTemplates, setRejectTemplates] = useState({});
    const [inputValue, setInputValue] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk = () => {
        if (inputValue == null) {
            message.warning('请输入驳回理由！');
            return
        }
        put('/healthclass/boss/organizations/updateReject', {
            code: infoData.code,
            rejectionReason: inputValue
        }).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    setIsModalVisible(false);
                    navigate('/healthclass/CheckOrganizations');
                }
            }
        );
    }
    const handleChange = (value) => {
        setInputValue(value)
    };
    const afterClose = () => {
        setInputValue(null)
    }
    const getData = () => {
        get(`/healthclass/boss/organizations/queryById`, {
            id: state.id
        }).then((res) => {
            if (res.status_code === 200) {
                setInfoData(res.data)
            }
        });
    }

    const checkpass = useCallback(
        (infoData) => {
            return () => {
                if (infoData.type === 1) {
                    navigate('/healthclass/CheckOrgTrialSave', {state: infoData});
                } else {
                    navigate('/healthclass/CheckOrgContractsSave', {state: infoData});
                }
            }
        },
        [navigate]
    )
    const checkDown = () => {
        setIsModalVisible(true)
        get(`/healthclass/boss/rejectTemplates/list`, {}).then((res) => {
            if (res.status_code === 200) {
                setRejectTemplates(res)
            }
        });
    }
    useEffect(() => {
        getData()
    }, [state])

    const classNames = ['button'];
    const classTrial = ['button'];
    const limitClassk = ['button'];
    const limitClasss = ['button'];
    if (infoData.type == 1) {
        classNames.push('button')
        classTrial.push('buttonblue')
    } else {
        classNames.push('buttonblue')
        classTrial.push('button')
    }
    if (infoData.limitType == 1) {
        limitClassk.push('button')
        limitClasss.push('buttonblue')
    } else {
        limitClassk.push('buttonblue')
        limitClasss.push('button')
    }

    return (
        <div>
            <Fragment>
                <div className="page-title">学苑审核</div>
                {infoData &&
                <Content className="main-content-box">
                    <div className="title">学苑类型</div>
                    <div className="content">
                        <span className="icon">学苑类型</span>
                        <button className={classNames.join(" ")}>正式</button>
                        <button className={classTrial.join(" ")}>试用</button>
                    </div>
                    <div className="title">学苑信息</div>
                    <div className="content">
                        <span className="icon">学苑名称</span>
                        <input className="checkInput" disabled="disabled" value={infoData.name || ""}/>
                    </div>
                    <div className="content">
                        <span className="icon">开放类型</span>
                        <button className={limitClassk.join(" ")}>公开</button>
                        <button className={limitClasss.join(" ")}>私享</button>
                    </div>
                    <div className="content">
                        <span className="icon">学苑封面</span>
                        <div className="image"><Image width={300} height={200} src={infoData.coverUrl}/>
                        </div>
                    </div>
                    <div className="content">
                        <span className="icon nameSpan">学苑介绍</span>
                        <textarea className="checkInputcontent" disabled="disabled" value={infoData.introduce || ""}/>
                    </div>
                    <div className="contentTag">
                        <span className="icon">学苑标签</span>
                        <div className="divTags">{infoData.metaTags && infoData.metaTags.map((tagName) => (
                            <Tag key={tagName.id}>{tagName.name}</Tag>))}</div>
                    </div>
                    <div className="content">
                        <span className="icon nameSpan">申请理由</span>
                        <textarea className="checkInputcontent" disabled="disabled"
                                  value={infoData.applicationReason || ""}/>
                    </div>
                    <div className="title">学苑管理员信息</div>
                    <div className="content">
                        <span className="icon">联系人姓名</span>
                        <input className="checkInputname" disabled="disabled" value={infoData.adminName || ""}/>
                    </div>
                    <div className="content">
                        <span className="icon">联系人手机</span>
                        <input className="checkInputname" disabled="disabled" value={infoData.phone || ""}/>
                    </div>
                    {infoData.unitName != null &&
                        <div>
                            <div className="title">个人认证信息</div>
                            <div className="content">
                                <span className="icon">单位名称</span>
                                <input className="checkInputname" disabled="disabled" value={infoData.unitName || ""}/>
                            </div>
                            <div className="content">
                                <span className="icon">证明材料</span>
                                <div className="image"><Image width={300} height={200} src={infoData.certificate}/>
                                </div>
                            </div>
                        </div>
                    }
                    {infoData.examineStatus != 1 && <div className="bottom"></div>}
                    {infoData.examineStatus == 1 &&
                    <div className="content">
                        <button className="checkbutton" onClick={checkpass(infoData)}>审核通过</button>
                        <button className="checkbuttonb" onClick={() => {
                            checkDown()
                        }}>审核驳回
                        </button>
                    </div>
                    }

                    <Modal title="审核驳回" visible={isModalVisible} destroyOnClose={true} onOk={() => {
                        handleOk()
                    }} onCancel={handleCancel} afterClose={afterClose}>
                        <div className="select">
                            <span>驳回理由（必填，50字以内）</span>
                            <Select defaultValue="选择驳回原因模板" style={{width: 260}} onChange={handleChange}>
                                {rejectTemplates.data && rejectTemplates.data.map((setReject) => (
                                    <Option key={setReject.id} value={setReject.content}>{setReject.content}</Option>))}
                            </Select>
                        </div>
                        <TextArea type="text" style={{width: 450, height: 200}}
                                  onChange={(e) => setInputValue(e.target.value)}
                                  onPressEnter={() => setInputValue('')}
                                  value={inputValue}
                                  maxLength={50}
                                  placeholder="请输入驳回理由"
                        ></TextArea>
                    </Modal>
                </Content>
                }

            </Fragment>
        </div>
    )
}
