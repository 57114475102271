import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "antd";
import { DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import "./index.less";
import Cumputerpreview from "./components/Cumputerpreview";
import Mobilpreview from "./components/Mobilpreview";
import { useLocation } from "react-router-dom";
const FinalPreview = ({ preview, closeFinalPreview }) => {
    const [cumputer, setCumputer] = useState(true);
    const [mobile, setMobile] = useState(false);
    const location = useLocation();
    const changeCumputer = () => {
        setCumputer(true);
        setMobile(false);
    };

    const changeMobile = () => {
        setCumputer(false);
        setMobile(true);
    };

    useEffect(() => {
        if (preview) {
            setCumputer(true);
            setMobile(false);
        }
    }, [preview]);

    return (
        <Fragment>
            <Modal open={preview} destroyOnClose onCancel={closeFinalPreview} width={1000} className="final-preview-modal" style={{ top: 30 }} footer={null}>
                <div className="final-preview">
                    <p>结束页预览</p>
                    <div className="final-preview-btn">
                        <div className="preview-action" onClick={changeCumputer} style={cumputer ? { color: "#1a73e8" } : {}}>
                            <DesktopOutlined className="preview-icon" />
                            <p>电脑</p>
                        </div>
                        <div className="preview-action" onClick={changeMobile} style={mobile ? { color: "#1a73e8" } : {}}>
                            <MobileOutlined className="preview-icon" />
                            <p>手机</p>
                        </div>
                    </div>
                </div>
                <div>
                    {/* === 'newCreate' */}
                    {cumputer && <Cumputerpreview content={location.state} preview={preview} />}
                    {mobile && <Mobilpreview content={location.state} preview={preview} />}
                </div>
            </Modal>
        </Fragment>
    );
};

export default FinalPreview;
