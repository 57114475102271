import {
    Button,
    Col,
    Dropdown,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
    Typography
} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {LeftOutlined, ReloadOutlined, SearchOutlined, QuestionCircleOutlined, MessageOutlined,CopyOutlined} from "@ant-design/icons";
import { filterFormItemLayout } from '@utils/property';
import {getEnterprisesListApi,updataEnterprisesListApi} from '@services/Eha'
import copy from 'copy-to-clipboard';
import iconSVip from '@static/images/icon-svip.png';
import iconVip from '@static/images/icon-vip.png';
import iconV from '@static/images/icon-v.png';
import './EnterprisesList.less'
const {Content} = Layout;
const { TextArea } = Input;
const { Text} = Typography;
export default function EnterprisesList() {

    const navigate = useNavigate();
    const [form] = Form.useForm()
    const [vipForm] = Form.useForm()

    const filterFormGridLayout = {
        sm: { span: 24 },
        md: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 8 },
    }
    // 获取列表数据
    const [data, setData] = useState([])
    const [itemData, setItemData] = useState({})
    const [queryObj, setQueryObj] = useState({})
    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    // 分页
    const onChangePage = (page, pageSize) => {
        console.log(page);
        setPage(page);
        setPageSize(pageSize);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (item) => {
        setIsModalOpen(true);
        setItemData(item)
        vipForm.resetFields()
    };
    const handleOk = () => {
        vipForm.validateFields().then(()=>{
           let vip= vipForm.getFieldValue("vip")
            updataEnterprisesListApi({memCard:itemData.memCard,vip:vip}).then((res)=>{
                if (res.status_code === 200) {
                    message.success(res.message);
                    setIsModalOpen(false);
                    getData()
                }
            })
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onChange = (e) => {
        console.log('Change:', e.target.value);
    };


    const onFinish = (res) => {
        setPage(1)
        setQueryObj(res)
    };
    const onReset = () => {
        form.resetFields();
        setPage(1)
        setQueryObj({})
    };

    const queryParams = {
        ...form.getFieldsValue(),
        page: page,
        pageSize: pageSize
    };
    useEffect(() => {
       getData()
    }, [page, pageSize, queryObj]);

    const getData=()=>{
        if (queryObj.vip === -1) {
            delete queryParams.vip
        }
        getEnterprisesListApi(queryParams).then((res)=>{
            if (res && res.status_code === 200) {
                console.log(res)
                setData(res.data.list)
                setTotal(res.data.totalCount)
            }
        })
    }

    const onSolutionList=(item)=>{
        navigate("/healthaccount/eha/solutions?memCard="+item.memCard)
    }

    const memCardCopy=(memCard)=>{
        copy(memCard)
        message.success("复制memCard成功！")
    }
    const columns = [
        {title: '企业用户昵称', dataIndex: 'nickName', width: 300,
        render:(_,row)=>(
            <Fragment>
                <Tooltip placement="top" title={(<span onClick={()=>memCardCopy(row.memCard)}>{row.memCard}<CopyOutlined /></span>)}>
                    {row.nickName}
                </Tooltip>
            </Fragment>
            )},
        {title: '企业用户手机', dataIndex: 'memMobile', width: 100},
        {title: '医企+会员', dataIndex: 'vip', width: 150,
            render:(_,row)=>(
                <Fragment>
                    {row.vip === 0 && <span>无等级</span>}
                    {row.vip === 1 && <span><img src={iconV} className="icon-vip"/>黄金会员</span>}
                    {row.vip === 2 && <span><img src={iconSVip} className="icon-vip"/>钻石会员</span>}
                    {row.vip === 3 && <span><img src={iconVip} className="icon-vip"/>超级会员</span>}
                </Fragment>
            )
        },
        {title: <span>解决方案数 <Tooltip placement="top" title={'审核通过数 / 总数（待审核+已通过+已驳回）'}>
                         <QuestionCircleOutlined />
                    </Tooltip></span>, dataIndex: 'total', width: 100,
            render:(_,row) => (
                <Fragment>
                    <span className="operate-style"><Text type="success">{row.successTotal}</Text> / <span className="total-style">{row.total}</span></span>
                </Fragment>
            )},
        {title: '最近发布时间', dataIndex: 'lastPubTime', width: 200},
        {
            title: '操作',
            dataIndex: 'operations',
            width: 300,
            render: (_,row) => (
                <Fragment>
                    <span className="operate-style" onClick={()=>onSolutionList(row)}>管理解决方案</span>
                    <span className="operate-style" onClick={()=>showModal(row)}>变更会员等级</span>
                </Fragment>
            ),
        }
    ];

    return <Fragment>
        <div className="page-title">
            <span className="current-title">医企+企业管理</span>
        </div>
        <Content className="main-content-box">
            <div className="filter-form-box">
                <Form  {...filterFormItemLayout} onFinish={onFinish} form={form}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="企业用户昵称" name="nickName">
                                <Input allowClear placeholder="企业用户昵称" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="企业用户手机" name="memMobile">
                                <Input allowClear placeholder="企业用户手机" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="医企+会员" name="vip">
                                <Select placeholder='请选择医企+会员'
                                        options={[
                                            {value: -1, label: '全部'},
                                            {value: 0, label: '无等级'},
                                            {value: 1, label: '黄金会员'},
                                            {value: 2, label: '钻石会员'},
                                            {value: 3, label: '超级会员'},
                                        ]}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout} className="filter-form-buttons">
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>

            <div className="main-content-body">

                <div className="table-caption">
                    <div className="table-title">企业列表</div>
                </div>

                <Table
                    className="enterprises-table"
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                />

            </div>
        </Content>
        <Modal title="变更医企+会员等级" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form form={vipForm}>
                <Form.Item name="vip" label="医企+会员" rules={[{required: true, message: '请选择医企+会员'}]}>
                    <Select placeholder='请选择医企+会员'
                            options={[
                                {value: 0, label: '无等级'},
                                {value: 1, label: '黄金会员'},
                                {value: 2, label: '钻石会员'},
                                {value: 3, label: '超级会员'}
                            ]}
                    >
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    </Fragment>
}
