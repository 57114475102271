import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const PositionOverview = ({data}) => {
    let positionChart;
    function echartsFunction() {
        positionChart && positionChart.dispose();
        positionChart = echarts.init(document.getElementById('position-chart'));
        // 指定图表的配置项和数据
        // 注册用户趋势
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: [
                {
                    name: '职务',
                    type: 'category',
                    data: data.xData,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        rotate: 30,
                    },
                },
            ],
            yAxis: [
                {
                    name: '人数',
                    type: 'value',
                },
            ],
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    barWidth: '60%',
                    data: data.yData,
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}',
                    },
                    itemStyle: {
                        color:'#1677ff',
                    }
                },
            ],
        };

        // 使用刚指定的配置项和数据显示图表。 -->
        positionChart.setOption(option);
        window.addEventListener('resize', function () {
            positionChart.resize();
        });
    }

    useEffect(() => {
        setTimeout(() => {
            echartsFunction();
        }, 20);
    }, [echartsFunction]);
    return <div className='position-chart' id='position-chart'></div>;
};

export default PositionOverview;
