import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.less';
import { UserOutlined, LockOutlined, LogoutOutlined, BellFilled, AppstoreOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { localStorageGet } from '@/core/cookie';

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    const [infos, setInfos] = useState(null);

    useEffect(() => {
        let infos;
        infos = localStorageGet('infos');
        setInfos(infos);

        // console.log(location);
    }, []);

    const handleBack = () => {
        // localStorage.removeItem('permissionsList');
        localStorage.removeItem('infos');
        localStorage.removeItem('token');

        const returnUrl = location.pathname + location.search;
        const url = returnUrl !== '/' ? `/login?returnurl=${returnUrl}` : '/login';
        navigate(url);
    }

    const myMenu = (
        <Menu className="my-menu">
            {
                infos && <Fragment>
                    <div className="username">{infos.name}</div>
                    {/*<Menu.Item key="profiles">*/}
                    {/*    <Link to="/my/profiles">*/}
                    {/*        <span className="icon-my icon-my-profile"><UserOutlined/></span>*/}
                    {/*        个人信息*/}
                    {/*    </Link>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key='password'>
                        <Link to="/my/changepassword">
                            <span className="icon-my icon-my-profile"><LockOutlined/></span>
                            修改密码
                        </Link>
                    </Menu.Item>
                    <Menu.Item key='back' onClick={handleBack}>
                        <span className="icon-my icon-my-profile"><LogoutOutlined/></span>
                        安全退出
                    </Menu.Item>
                </Fragment>
            }
        </Menu>
    );

    return (
        <div className="header">
            <div className="brand">
                <span id="module-mini-menu-trigger" className="cursor-pointer">
                    <AppstoreOutlined/>
                </span>
                <Link to="/" id="platform-title">华媒康讯业务运营支撑系统</Link>
            </div>
            <div className="status-bar">
                <span className="entry entry-my cursor-pointer">
                    {
                        infos && <Dropdown overlay={myMenu} placement="bottomRight" arrow>
                            <Avatar src={infos.avatar} icon={<UserOutlined/>}/>
                        </Dropdown>
                    }
                </span>
            </div>
        </div>
    );
}
