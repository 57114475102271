import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Radio, DatePicker, message } from 'antd';
import { get } from '@/core/request';
import { lastDays, todayDateString } from '@/utils/utils';
import * as echarts from 'echarts';
import moment from 'moment';
const { RangePicker } = DatePicker;

export default function UserOverviewBottomEcharts() {
    const dateFormat = 'YYYY-MM-DD';

    let date = new Date();
    // 当天
    let dateString = todayDateString(date);
    var yesterdaydate = lastDays(1);

    // 点击时间 高亮改变的state
    const [timeRadio, setTimeRadio] = useState({
        registerRadio: 'today',
        professionRadio: 'yesterday',
        officeRadio: 'yesterday',
        postRadio: 'yesterday',
    })

    // 注册认证时间
    const [registerDate, setRegisterDate] = useState({
        registerStartDate: dateString,
        registerEndDate: dateString
    })

    // 专业认证身份时间
    const [professionDate, setProfessionDate] = useState({
        professionStartDate: yesterdaydate,
        professionEndDate: yesterdaydate
    })

    // 医务人员科室分布
    const [officeDate, setOfficeDate] = useState({
        officeStartDate: yesterdaydate,
        officeEndDate: yesterdaydate
    })

    // 医务人员职务分布
    const [postDate, setPostDate] = useState({
        postStartDate: yesterdaydate,
        postEndDate: yesterdaydate
    })


    // 点击今天昨天近7天近30天
    const handleChangeValue = (tip) => {
        return (e) => {
            let startdate = '';
            if (e.target.value === 'today') {
                setRegisterDate({
                    registerEndDate: dateString,
                    registerStartDate: dateString
                })
                setTimeRadio({
                    ...timeRadio,
                    registerRadio: e.target.value
                })
                return
            } else if (e.target.value === 'yesterday') {
                startdate = lastDays(1);
            } else if (e.target.value === 'seven') {
                startdate = lastDays(6);
            } else if (e.target.value === 'thirty') {
                startdate = lastDays(29);
            }
            if (tip === 'register') {
                if (e.target.value === 'yesterday') {
                    setRegisterDate({
                        registerEndDate: startdate,
                        registerStartDate: startdate
                    })
                } else {
                    setRegisterDate({
                        registerEndDate: dateString,
                        registerStartDate: startdate
                    })
                }
                setTimeRadio({
                    ...timeRadio,
                    registerRadio: e.target.value
                })
            } else if (tip === 'profession') {
                if (e.target.value === 'yesterday') {
                    setProfessionDate({
                        professionEndDate: startdate,
                        professionStartDate: startdate
                    })
                } else {
                    setProfessionDate({
                        professionEndDate: dateString,
                        professionStartDate: startdate
                    })
                }
                setTimeRadio({
                    ...timeRadio,
                    professionRadio: e.target.value
                })
            } else if (tip === 'office') {
                if (e.target.value === 'yesterday') {
                    setOfficeDate({
                        officeEndDate: startdate,
                        officeStartDate: startdate
                    })
                } else {
                    setOfficeDate({
                        officeEndDate: dateString,
                        officeStartDate: startdate
                    })
                }
                setTimeRadio({
                    ...timeRadio,
                    officeRadio: e.target.value
                })
            } else if (tip === 'post') {
                if (e.target.value === 'yesterday') {
                    setPostDate({
                        postEndDate: startdate,
                        postStartDate: startdate
                    })
                } else {
                    setPostDate({
                        postEndDate: dateString,
                        postStartDate: startdate
                    })
                }
                setTimeRadio({
                    ...timeRadio,
                    postRadio: e.target.value
                })
            }
        }

    }

    const disabledDate = (tip) => {
        return (current) => {
            if (tip === 'register') {
                return current > moment().endOf('day');
            } else {
                return current > moment(yesterdaydate, dateFormat)
            }
        }
    }

    // 点击timePicker
    const handleChangeTime = (tip) => {
        return (value, dateString) => {
            if (tip === 'register') {
                setRegisterDate({
                    registerStartDate: dateString[0],
                    registerEndDate: dateString[1]
                })
                setTimeRadio({
                    ...timeRadio,
                    registerRadio: '',
                })
            } else if (tip === 'profession') {
                setProfessionDate({
                    professionStartDate: dateString[0],
                    professionEndDate: dateString[1]
                })
                setTimeRadio({
                    ...timeRadio,
                    professionRadio: '',
                })
            } else if (tip === 'office') {
                setOfficeDate({
                    officeEndDate: dateString[1],
                    officeStartDate: dateString[0]
                })
                setTimeRadio({
                    ...timeRadio,
                    officeRadio: '',
                })
            } else if (tip === 'post') {
                setPostDate({
                    postEndDate: dateString[1],
                    postStartDate: dateString[0]
                })
                setTimeRadio({
                    ...timeRadio,
                    postRadio: '',
                })
            }

        }
    }

    const timeBox = (tip) => {
        return (
            <div className="bi-user-overview-time-box">
                <Radio.Group onChange={handleChangeValue(tip)} size="small"
                    value={tip === 'register' ? timeRadio.registerRadio : tip === 'profession' ? timeRadio.professionRadio : tip === 'office' ? timeRadio.officeRadio : timeRadio.postRadio}
                    className="bi-user-overview-time-box-left">
                    {tip === 'register' && <Radio.Button value="today">今天</Radio.Button>}
                    {tip !== 'register' && <Radio.Button value="yesterday">昨天</Radio.Button>}
                    <Radio.Button value="seven">最近7天</Radio.Button>
                    <Radio.Button value="thirty">最近30天</Radio.Button>
                </Radio.Group>
                {
                    tip === 'register' && <RangePicker
                        size="small"
                        allowClear={false}
                        value={[moment(registerDate.registerStartDate, dateFormat), moment(registerDate.registerEndDate, dateFormat)]}
                        disabledDate={disabledDate(tip)}
                        onChange={handleChangeTime(tip)}
                    />
                }
                {
                    tip === 'profession' && <RangePicker
                        size="small"
                        allowClear={false}
                        value={[moment(professionDate.professionStartDate, dateFormat), moment(professionDate.professionEndDate, dateFormat)]}
                        disabledDate={disabledDate(tip)}
                        onChange={handleChangeTime(tip)}
                    />
                }
                {
                    tip === 'office' && <RangePicker
                        size="small"
                        allowClear={false}
                        value={[moment(officeDate.officeStartDate, dateFormat), moment(officeDate.officeEndDate, dateFormat)]}
                        disabledDate={disabledDate(tip)}
                        onChange={handleChangeTime(tip)}
                    />
                }
                {
                    tip === 'post' && <RangePicker
                        size="small"
                        allowClear={false}
                        value={[moment(postDate.postStartDate, dateFormat), moment(postDate.postEndDate, dateFormat)]}
                        disabledDate={disabledDate(tip)}
                        onChange={handleChangeTime(tip)}
                    />
                }
            </div>
        )
    }

    // 趋势和柱状图
    const [registerTrend, setRegisterTrend] = useState({
        xData: [],
        yData: []
    })

    // 注册用户趋势
    const registerFunction = () => {
        var days = (Date.parse(registerDate.registerEndDate) - Date.parse(registerDate.registerStartDate)) / (1 * 24 * 60 * 60 * 1000);
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/register-users-day`, {
            startTime: registerDate.registerStartDate,
            endTime: registerDate.registerEndDate,
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                if (registerDate.registerStartDate === dateString && registerDate.registerEndDate === dateString) {
                    res.data && res.data.forEach(item => {
                        xArr.push(`${item.time}:00`);
                        yArr.push(item.num);
                    })
                } else {
                    res.data && res.data.forEach(item => {
                        xArr.push(item.time);
                        yArr.push(item.num);
                    })
                }
                setRegisterTrend({
                    xData: xArr,
                    yData: yArr
                });
            }
        });
    }
    // 认证用户趋势
    const identityFunction = () => {
        var days = (Date.parse(registerDate.registerEndDate) - Date.parse(registerDate.registerStartDate)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/certified-users-day`, {
            startTime: registerDate.registerStartDate,
            endTime: registerDate.registerEndDate,
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                res.data && res.data.forEach(item => {
                    xArr.push(item.time);
                    yArr.push(item.num);
                })
                setRegisterTrend({
                    xData: xArr,
                    yData: yArr
                });
            }
        });
    }
    // 专业认证身份分布
    const [professionIdentity, setProfessionIdentity] = useState({
        xData: [],
        yData: []
    });
    const professionFunction = () => {
        var days = (Date.parse(professionDate.professionEndDate) - Date.parse(professionDate.professionStartDate)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/detail/users-detail`, {
            startTime: professionDate.professionStartDate,
            endTime: professionDate.professionEndDate,
            type: 1
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                res.data && res.data.forEach(item => {
                    xArr.push(item.dicName);
                    yArr.push(item.num);
                })
                setProfessionIdentity({
                    xData: xArr,
                    yData: yArr
                })
            }
        });
    }

    // 医务人员科室分布
    const [officeDistribute, setOfficeDistribute] = useState({
        xData: [],
        yData: []
    });
    const officeFunction = () => {
        var days = (Date.parse(officeDate.officeEndDate) - Date.parse(officeDate.officeStartDate)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/detail/users-detail`, {
            startTime: officeDate.officeStartDate,
            endTime: officeDate.officeEndDate,
            type: 2
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                res.data && res.data.forEach(item => {
                    xArr.push(item.dicName);
                    yArr.push(item.num);
                })
                setOfficeDistribute({
                    xData: xArr,
                    yData: yArr
                })
            }
        });
    }

    // 医务人员职务分布
    const [postDistribute, setPostDistribute] = useState({
        xData: [],
        yData: []
    });
    const postFunction = () => {
        var days = (Date.parse(postDate.postEndDate) - Date.parse(postDate.postStartDate)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/detail/users-detail`, {
            startTime: postDate.postStartDate,
            endTime: postDate.postEndDate,
            type: 3
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                res.data && res.data.forEach(item => {
                    xArr.push(item.dicName);
                    yArr.push(item.num);
                })
                setPostDistribute({
                    xData: xArr,
                    yData: yArr
                })
            }
        });
    }
    const [redioState, setRedioState] = useState('register')

    // 注册用户趋势
    useEffect(() => {
        if (redioState === 'register') {
            registerFunction()
        } else {
            identityFunction()
        }
    }, [registerDate.registerEndDate, registerDate.registerStartDate, redioState])

    useEffect(() => {
        professionFunction()
    }, [professionDate.professionStartDate, professionDate.professionEndDate])

    useEffect(() => {
        officeFunction()
    }, [officeDate.officeStartDate, officeDate.officeEndDate])

    useEffect(() => {
        postFunction()
    }, [postDate.postStartDate, postDate.postEndDate])

    const onRadioChange = e => {
        setRedioState(e.target.value)
    };

    let myChart1, myChart2, myChart3, myChart4;

    function echartsFunction() {
        myChart1 && myChart1.dispose();
        myChart2 && myChart1.dispose();
        myChart3 && myChart1.dispose();
        myChart4 && myChart1.dispose();
        myChart1 = echarts.init(document.getElementById('register-ehcarts'));
        myChart2 = echarts.init(document.getElementById('identity-echarts'));
        myChart3 = echarts.init(document.getElementById('office-echarts'));
        myChart4 = echarts.init(document.getElementById('post-echarts'));
        // 指定图表的配置项和数据
        // 注册用户趋势
        const option1 = {
            tooltip: {//提示
                trigger: 'item',
                backgroundColor: 'rgba(255,255,255,0.9)',
            },
            xAxis: {
                type: 'category',
                data: registerTrend.xData
            },
            yAxis: { type: 'value' },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '3%',
                top: '25%',
                containLabel: true
            },
            series: [
                {
                    data: registerTrend.yData,
                    type: 'line',
                    smooth: true
                }
            ]
        };

        // 专业认证身份分布
        const option2 = {
            tooltip: {//提示
                trigger: 'axis',
                backgroundColor: 'rgba(255,255,255,0.9)',
                extraCssText: 'width:150px;height:60px;',
                axisPointer: {//hover的时候没有虚线
                    type: 'none'
                }
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '2%',
                top: '15%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: professionIdentity.xData,
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: { type: 'value' },
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    barWidth: '30%',
                    data: professionIdentity.yData,
                }
            ]
        };

        // 医务人员科室分布
        const option3 = {
            tooltip: {//提示
                trigger: 'axis',
                backgroundColor: 'rgba(255,255,255,0.9)',
                extraCssText: 'width:150px;height:60px;',
                axisPointer: {//hover的时候没有虚线
                    type: 'none'
                }
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: 0,
                top: '15%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: officeDistribute.xData,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        rotate: 45,
                        color: '#000',
                        margin: 17,
                        fontSize: 12,
                        formatter: (params) => {
                            var newParamsName = ''
                            var paramsNameNumber = params.length
                            var provideNumber = 5//超过10个字符折行
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                            for (let row = 0; row < rowNumber; row++) {
                                newParamsName +=
                                    params.substring(
                                        row * provideNumber,
                                        (row + 1) * provideNumber
                                    ) + '\n'
                            }
                            return newParamsName
                        }
                    },
                }
            ],
            yAxis: { type: 'value' },
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    barWidth: '30%',
                    data: officeDistribute.yData,
                }
            ]
        };

        // 医务人员职务分布
        const option4 = {
            tooltip: {//提示
                trigger: 'axis',
                backgroundColor: 'rgba(255,255,255,0.9)',
                extraCssText: 'width:150px;height:60px;',
                axisPointer: {//hover的时候没有虚线
                    type: 'none'
                }
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: 0,
                top: '15%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: postDistribute.xData,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        rotate: 45,
                        color: '#000',
                        margin: 17,
                        fontSize: 12,
                        formatter: (params) => {
                            var newParamsName = ''
                            var paramsNameNumber = params.length
                            var provideNumber = 10//超过10个字符折行
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                            for (let row = 0; row < rowNumber; row++) {
                                newParamsName +=
                                    params.substring(
                                        row * provideNumber,
                                        (row + 1) * provideNumber
                                    ) + '\n'
                            }
                            return newParamsName
                        }
                    },
                }
            ],
            yAxis: { type: 'value' },
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    barWidth: '30%',
                    data: postDistribute.yData,
                }
            ]
        };

        // 使用刚指定的配置项和数据显示图表。 -->
        myChart1.setOption(option1);
        myChart2.setOption(option2);
        myChart3.setOption(option3);
        myChart4.setOption(option4);
        window.addEventListener('resize', function () {
            myChart1.resize();
            myChart2.resize();
            myChart3.resize();
            myChart4.resize();
        });
    }
    useEffect(() => {
        setTimeout(() => {
            echartsFunction()
        }, 20)
    }, [registerTrend, professionIdentity, officeDistribute, postDistribute])

    return (
        <Row gutter={[16, 16, 16, 16]} className="bi-user-overview-bottom">
            <Col span={24}>
                <Card size="small" title='用户趋势' extra={timeBox('register')}>
                    <div className="core-data-title echarts-register">
                        <Radio.Group onChange={onRadioChange} size="small" defaultValue="register">
                            <Radio.Button value="register">注册用户趋势</Radio.Button>
                            <Radio.Button value="identity">认证用户趋势</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="register-ehcarts" id='register-ehcarts'></div>
                </Card>
            </Col>
            <Col span={24}>
                <Card size="small" title='专业认证身份分布' extra={timeBox('profession')}>
                    <div className="profession-echarts" id="identity-echarts"></div>
                </Card>
            </Col>
            <Col span={24}>
                <Card size="small" title='医务人员科室分布' extra={timeBox('office')}>
                    <div className="profession-echarts" id="office-echarts"></div>
                </Card>
            </Col>
            <Col span={24}>
                <Card size="small" title='医务人员职务分布' extra={timeBox('post')}>
                    <div className="profession-echarts" id="post-echarts"></div>
                </Card>
            </Col>
        </Row>
    )
}
