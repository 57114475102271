import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    LeftOutlined,
    ReloadOutlined,
    SearchOutlined,
    ExclamationCircleOutlined,
    QuestionCircleTwoTone,
    QuestionCircleOutlined,
    SettingOutlined, UploadOutlined
} from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {
    Layout,
    Button,
    Col,
    Form,
    Input,
    Row,
    Space,
    Table,
    Dropdown,
    Typography,
    Modal,
    message,
    Select,
    Tooltip,
    Divider,
    Popover,
    Tag,
    Switch,
    Drawer,
    Upload,
    Radio,
    Image as AntdImage, Alert,
} from 'antd';
import {
    getInvitationUserListApi,
    deleteInvitationUserApi,
    exportInvitationLink,
    sendMpMessageApi
} from '@services/Invitation/InvitationUser'
import { getInvitationOneApi, invitationusersStatusApi } from '@services/Invitation/Invitation';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import config from '@config';
import { convertDateTimeRangeToLocalText } from '@utils/utils';
import './InvitationUsers.less';
import { expertLevels } from '@utils/constants';
import { invitationNameTitles } from '../../utils/constants';
import { invitationActions } from './store/invitationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { drawerFormItemLayout, drawerWidth } from '../../utils/property';
import AddGuest from './components/AddGuest';
import GuestInfo from './components/GuestInfo';
import ConfirmInvite from './components/ConfirmInvite';
import TinyMCEEditor from '../Conference/components/Editor/TinyMCEEditor';
import { prepareBCOSSUrl } from '../../utils/utils';
import { cloneDeep, isEmpty } from 'lodash';
import { fileApi, setupInvitationFile } from '../../services/Invitation/Invitation';
import { exportInvitationFile, setupUserInvitationFile } from '../../services/Invitation/InvitationUser';
import { getInvitationGroupOneApi } from '../../services/Invitation/InvitationGroup';
import { conferenceOnceApi } from '../../services/Conference';

const { Content } = Layout;
const { Text } = Typography;

const { setUserFilter: setFilter, setUserCursor: setCursor } = invitationActions;

export default function InvitationUsers() {
    const navigate = useNavigate();
    const params = useParams();
    const gid = params?.gid || 0;
    const id = params?.id || 0;
    console.log('gid', gid, 'id', id);

    const dispatch = useDispatch();
    const filter = useSelector(state => state.invitation.user.filter);
    const cursor = useSelector(state => state.invitation.user.cursor);
    // console.log(filter, cursor);

    const [form] = Form.useForm();

    const [visibleAddUserDrawer, setVisibleAddUserDrawer] = useState(false);
    const [guestInfoDrawer, setGuestInfoDrawer] = useState(false);
    const [currentExpertId, setCurrentExpertId] = useState();
    const [currentUserCode, setCurrentUserCode] = useState();

    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [invitationOne, setInvitationOne] = useState({});
    const [confirmInviteOpen, setConfirmInviteOpen] = useState(false);

    const [visibleSetupInvitation, setVisibleSetupInvitation] = useState(false);

    const [setupInvitationForm] = Form.useForm();

    const [invitationFileCoverUrlList, setInvitationFileCoverUrlList] = useState([]);
    const [invitationBodyBgUrlList, setInvitationBodyBgUrlList] = useState([]);

    const [setupInvitationUser, setSetupInvitationUser] = useState(null); // 定制某个邀请用户的电子邀请函

    const [downloadCountDown, setDownloadCountDown] = useState(0);

    const isAllowCheck = useRef(null);

    const optionHeaders = { headers: { 'Content-Type': 'multipart/form-data' } };

    const [conference, setConference] = useState(null);

    // 通过邀请函的 gid 查询会议 ID，进而查询到会议详情
    // 会议进入劳务阶段，禁用代嘉宾确认任务 !!conference?.laborFeeConfirmedAt
    const getInvitationGroupConferenceInfo = async gid => {
        const res = await getInvitationGroupOneApi({ id: gid });
        if (res.status_code === 200) {
            // console.log(res.data);
            const invitationGroup = res.data;
            if (!isEmpty(invitationGroup) && invitationGroup.applicationType === 1) {
                const confRes = await conferenceOnceApi({ id: invitationGroup.applicationItemId });
                if (confRes.status_code === 200){
                    // console.log(confRes.data);
                    const conf = confRes.data;
                    if (!isEmpty(conf)) {
                        // console.log(conf);
                        setConference(conf);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (gid) {
            getInvitationGroupConferenceInfo(gid);
        }
    }, []);

    useEffect(() => {
        let timer;
        if (downloadCountDown > 0) {
            timer = setTimeout(() => {
                setDownloadCountDown(downloadCountDown - 1);
            }, 1000);
        }

        return () => clearTimeout(timer);
    }, [downloadCountDown]);

    // 打开电子邀请函设置表单
    const openSetupInvitation = (invitationUser = null) => {
        if (isEmpty(invitationUser)) { // 统一
            setSetupInvitationUser(null);

            if (!isEmpty(invitationOne?.invitationFileSettings)) {
                const invitationFileSettings = invitationOne.invitationFileSettings;
                const settings = {
                    ...invitationFileSettings,
                    // 统一设置中的字段处理
                    titleEnabled:         !!invitationFileSettings?.titleEnabled,
                    letterEnabled:        !!invitationFileSettings?.letterEnabled,
                    confDetailsEnabled:   !!invitationFileSettings?.confDetailsEnabled,
                    taskEnabled:          !!invitationFileSettings?.taskEnabled,
                    relatedConfHallsOnly: !!invitationFileSettings?.relatedConfHallsOnly,
                };
                console.log(settings);
                setupInvitationForm.setFieldsValue(settings);
            }

        } else { // 定制
            console.log(invitationUser);
            // 浅拷贝，有问题
            // const user = {...invitationUser};
            const user = cloneDeep(invitationUser);
            let invitationFileSettings = null;
            if (user?.invitationUsers?.invitationFileSettings) {
                invitationFileSettings = JSON.parse(user.invitationUsers.invitationFileSettings) || null;
            }
            // console.log(invitationFileSettings);
            if (user?.invitationUsers && !isEmpty(invitationFileSettings)) {
                user.invitationUsers.invitationFileSettings = invitationFileSettings;
            }
            // console.log(invitationUser);
            setSetupInvitationUser(user);

            let settings = {};
            if (!isEmpty(invitationOne?.invitationFileSettings)) {
                settings = { ...invitationOne.invitationFileSettings };
            }
            if (!isEmpty(invitationFileSettings)) {
                settings = {
                    ...settings, // 统一设置
                    ...invitationFileSettings, // 定制设置
                    // 定制设置中的字段处理
                    titleEnabled:         !!invitationFileSettings?.titleEnabled,
                    letterEnabled:        !!invitationFileSettings?.letterEnabled,
                    confDetailsEnabled:   !!invitationFileSettings?.confDetailsEnabled,
                    taskEnabled:          !!invitationFileSettings?.taskEnabled,
                    relatedConfHallsOnly: !!invitationFileSettings?.relatedConfHallsOnly,
                };
                console.log(settings);
            }
            setupInvitationForm.setFieldsValue(settings);
        }

        setVisibleSetupInvitation(true);
    };

    const closeSetupInvitation = () => {
        setSetupInvitationUser(null);
        setVisibleSetupInvitation(false);
    };

    const setupInvitation = () => {
        setupInvitationForm.validateFields()
            .then(async (values) => {
                // console.log(values);
                // return;

                let invitationFileSettings = {
                    ...values,
                    titleEnabled: !!values.titleEnabled ? 1 : 0,
                    letterEnabled: !!values.letterEnabled ? 1 : 0,
                    confDetailsEnabled: !!values.confDetailsEnabled ? 1 : 0,
                    taskEnabled: !!values.taskEnabled ? 1 : 0,
                    relatedConfHallsOnly: !!values.relatedConfHallsOnly ? 1 : 0,
                };

                // 开启大会详情，处理标题和内容
                if (values.confDetailsEnabled) {
                    invitationFileSettings = {
                        ...invitationFileSettings,
                        confDetailsTitle: values.confDetailsTitle,
                        confDetails: values.confDetails,
                    };
                }

                // 开启嘉宾任务，处理任务形式和联系人
                if (values.taskEnabled) {
                    invitationFileSettings = {
                        ...invitationFileSettings,
                        taskForm: values.taskForm,
                        contactFrom: values.contactFrom,
                    };

                    if (values.contactFrom === 2) { // 联系人来源是自定义时
                        invitationFileSettings = {
                            ...invitationFileSettings,
                            contacts: values.contacts,
                        };
                    }
                }
                !!values.coverUrl && (invitationFileSettings['coverUrl'] = values.coverUrl);
                !!values.bodyBgUrl && (invitationFileSettings['bodyBgUrl'] = values.bodyBgUrl);

                console.log(invitationFileSettings);
                // return;

                if (!isEmpty(setupInvitationUser)) {
                    // 定制电子邀请函设置

                    const data = {
                        id: setupInvitationUser.invitationUsers.id, // 邀请用户（关联表的记录）ID
                        invitationFileSettings: JSON.stringify(invitationFileSettings),
                    };
                    const res = await setupUserInvitationFile(data);
                    if (res.status_code === 200) {
                        message.success('定制电子邀请函成功');

                        setSetupInvitationUser(null);
                        setupInvitationForm.resetFields();
                        setVisibleSetupInvitation(false);

                        getListData();
                    }

                } else {
                    // （统一）电子邀请函设置

                    const data = {
                        id, // invitation id
                        invitationFileSettings: JSON.stringify(invitationFileSettings),
                    };
                    const res = await setupInvitationFile(data);
                    if (res.status_code === 200) {
                        message.success('电子邀请函设置成功');

                        setupInvitationForm.resetFields();
                        setVisibleSetupInvitation(false);

                        getInvitationInfo(id);
                    }
                }
            })
            .catch(error => message.warning('请完善电子邀请函设置中的必填项再提交'));
    };

    const checkout = (file, size) => {
        const isJpgOrPng = file.type === "image/jpeg"
            || file.type === "image/png"
            || file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error('仅支持上传JPG/PNG格式的图片');
            isAllowCheck.current = true;
            return false;
        } else {
            isAllowCheck.current = false;
        }

        // 判断大小
        const isLt5M = file.size / 1024 / 1024 < size;
        if (!isLt5M) {
            message.error(`图片不能大于${size}MB`);
            isAllowCheck.current = true;
            return false;
        } else {
            isAllowCheck.current = false;
        }

        return isJpgOrPng && isLt5M;
    };

    const uploadInvitationCover = (info) => {
        const formData = new FormData();
        formData.append('file', info.file);
        formData.append('type', 14);

        fileApi(formData, optionHeaders)
            .then((res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }
                if (res.status_code === 200) {
                    setInvitationFileCoverUrlList([prepareBCOSSUrl(res.data)]);
                    setupInvitationForm.setFieldValue('coverUrl', res.data);
                }
            });
    };

    const uploadInvitationBodyBg = (info) => {
        const formData = new FormData();
        formData.append('file', info.file);
        formData.append('type', 14);

        fileApi(formData, optionHeaders)
            .then((res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }
                if (res.status_code === 200) {
                    setInvitationBodyBgUrlList([prepareBCOSSUrl(res.data)]);
                    setupInvitationForm.setFieldValue('bodyBgUrl', res.data);
                }
            });
    };

    const downloadInvitationFile = async invitationUser => {
        console.log(invitationUser);
        if (!isEmpty(invitationUser)) {
            if (downloadCountDown === 0) {
                setDownloadCountDown(10);

                message.success('请稍后，正在生成文件');

                const params = {
                    invitationId: id,
                    expertId: invitationUser.id
                };
                const res = await exportInvitationFile(params);
                let blob = new Blob([res], { type: 'application/octet-stream' });

                if ('download' in document.createElement('a')) {
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.download = `电子邀请函_${invitationUser.memUsername}.docx`;
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                } else {
                    navigator.msSaveBlob(blob);
                }
            }

        } else {
            // TODO
        }
    };

    // 分页
    const onChangePage = (page, pageSize) => {
        setData([]);
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };
    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const onFinish = values => {
        let newFilter = {
            ...filter,
            ...values,
            page: 1,
        };
        // 兼容接口未处理 [] 的情况
        if (isEmpty(newFilter?.expertLevels)) {
            delete newFilter.expertLevels;
        }
        dispatch(setFilter(newFilter));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };


    const handleRefresh = () => getInvitationUsers();

    const getInvitationUsers = async () => {
        try {
            const params = {
                ...filter,
                limit: filter.pageSize,
                invitationGroupId: gid,
                invitationId: id,
            };

            const res = await getInvitationUserListApi(params);
            if (res.status_code === 200) {
                setData(res.data?.list);
                setTotal(res.data?.totalCount);
            }
        } catch (e) {
            //
        }
    };

    const getInvitationInfo = async id => {
        const res = await getInvitationOneApi({ id });

        if (res.status_code === 200) {
            const invitation = res.data;
            if (!!invitation?.invitationFileSettings) {
                invitation['invitationFileSettings'] = JSON.parse(invitation.invitationFileSettings) || null;

                if (!!invitation.invitationFileSettings?.coverUrl) {
                    setInvitationFileCoverUrlList([prepareBCOSSUrl(invitation.invitationFileSettings.coverUrl)]);
                }
                if (!!invitation.invitationFileSettings?.bodyBgUrl) {
                    setInvitationBodyBgUrlList([prepareBCOSSUrl(invitation.invitationFileSettings.bodyBgUrl)]);
                }
            }
            console.log(invitation);
            setInvitationOne(invitation);
        }
    };


    useEffect(() => {
        form.setFieldsValue(filter);

        getInvitationUsers();
    }, [filter]);

    useEffect(() => {
        if (!!id) {
            getInvitationInfo(id);
        }
    }, []);

    const onResetGuest = () => {
        // TODO
        const queryParams = {
            invitationGroupId: gid,
            invitationId: id,
            limit: 20,
            page: 1,
        };

        getInvitationUserListApi({ ...queryParams, page: 1 })
            .then((res) => {
                if (res.status_code === 200) {
                    setData(res.data?.list);
                    setTotal(res.data?.totalCount);
                }
            });
    }


    // 修改刷新数据列表
    const getListData = () => {
        const data = {
            page: 1,
            limit: 10,
            invitationGroupId: gid,
            invitationId: id,
        };
        getInvitationUserListApi(data)
            .then((res) => {
                if (res.status_code === 200) {
                    setData(res.data?.list);
                    setTotal(res.data?.totalCount);
                }
            });
    };

    // 删除
    const onDeleteInvitationUser = (expertId) => {
        Modal.confirm({
            title: '提示',
            content: '确定删除当前邀请函用户？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteInvitationUserApi({ expertId: expertId, invitationId: invitationOne.id })
                    .then((res) => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                        }

                        navigate('/invitation/g/' + gid + '/invitations/' + id + '/users');
                        getListData();
                    });
            }
        });

    }

    const onComfirmInvitation = (record) => {
        setCurrentUserCode(record.invitationUsers.userCode);
        setConfirmInviteOpen(true);
    }

    const openAddUserDrawer = () => {
        setVisibleAddUserDrawer(true);
    };

    const closeAddUserDrawer = () => {
        setVisibleAddUserDrawer(false);
    };

    const openViewGuestInfoDrawer = id => {
        dispatch(setCursor(id));
        setCurrentExpertId(id);
        setGuestInfoDrawer(true);
    };

    const closeGuestInfoDrawer = () => {
        setGuestInfoDrawer(false);
    }


    const copyInvitationLink = item => {
        let invitationLink = `${config.INVITATION_PREFIX}`;
        invitationLink += `/${item?.invitationUsers?.userCode}`;

        copy(invitationLink);
        message.success('复制成功');

        dispatch(setCursor(item.id));
    };

    const copyInvitationText = item => {
        let invitationLink = `${config.INVITATION_PREFIX}`;
        invitationLink += `/${item?.invitationUsers?.userCode}`;
        // console.log(invitationLink);

        let scriptText = item.smsInfo;
        if (scriptText.indexOf('$邀请链接$') > -1) {
            scriptText = scriptText.replace(/[ 　]*\$邀请链接\$[ 　]*/g, ` ${invitationLink} `);
        } else {
            // 不自动加链接了
            // scriptText += "\n" + invitationLink;
        }

        let nameTitle = '';
        if (invitationOne?.nameTitle) {
            switch (invitationOne.nameTitle) {
                case 1:
                    break;
                case 2: // 0女士 1男士 TODO 取值需要改成 1 2
                    nameTitle = item?.memGender === 0
                        ? '女士'
                        : item?.memGender === 1
                            ? '先生'
                            : '先生/女士';
                    break;
                case 3:
                case 4:
                case 5:
                    nameTitle = invitationNameTitles.find(t => t.type === invitationOne.nameTitle)?.title || '';
                    break;
                case 6:
                    if (item.ssoMemberWorkExperiencesEntities && item.ssoMemberWorkExperiencesEntities.length) {
                        nameTitle = item.ssoMemberWorkExperiencesEntities[0]?.unitPost || '';
                    }
                    break;
                default:
                    break;
            }
        }

        scriptText = `尊敬的${item.memUsername}${nameTitle}：\n${scriptText}`;
        console.log(scriptText);

        copy(scriptText);
        message.success('复制成功');

        dispatch(setCursor(item.id));
    };

    const getInvitationUrl = invitation => `${config.INVITATION_PREFIX}/${invitation?.invitationUsers?.userCode}`;

    const downloadQRCode = invitation => {
        const userCode = invitation?.invitationUsers?.userCode;
        if (!!userCode) {
            const qrCode = document.getElementById(userCode);
            let image = new Image();
            image.src = qrCode.toDataURL("image/png");
            const link = document.getElementById(`link_${userCode}`);
            link.href = image.src;
        }
    };

    const toggleAgendaTasks = guestAgendaInfo => {
        setData(data.map(item => {
            if (item.id === guestAgendaInfo.id) {
                return { ...item, expanded: !item.expanded };
            } else {
                return item;
            }
        }));
    };

    // 发送邀请函通知
    const sendMessage = async item => {
        console.log('send:', item);
        try {
            const params = {
                id: item.invitationUsers?.id,
                conferenceId: item.guestsEntity?.conferenceId,
                conferenceName: item.guestsEntity?.conferenceName
            };
            const res = await sendMpMessageApi(params);
            if (res.status_code === 200) {
                navigate('/invitation/g/' + gid + '/invitations/' + id + '/users');
                getListData();
            }
        } catch (e) {
            //
        }
    }

    const columns = [
        {
            title: '姓名',
            dataIndex: 'memUsername',
            width: 120,
            render: (_, record) => {
                return <>
                    <span style={{ marginRight: 4 }}>{record.memUsername}</span>
                    <span>
                        {record.memGender === 1
                            ? (
                                <svg style={{ color: '#1890ff' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-male" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                                </svg>
                            )
                            : record.memGender === 0
                                ? (
                                    <svg style={{ color: '#eb2f96' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-female" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                                    </svg>
                                )
                                : null}
                    </span>
                    <div className="expert-level">
                        <Text type={record.expertLevel >= 1 && record.expertLevel <=6 ? 'warning' : 'secondary'}>
                            {expertLevels.find(a => a.level === record.expertLevel)?.label}
                        </Text>
                    </div>
                </>;
            }
        },
        {
            title: '单位任职',
            dataIndex: 'ssoMemberWorkExperiencesEntities',
            width: 200,
            render: (_, record) => {
                if (record.ssoMemberWorkExperiencesEntities && record.ssoMemberWorkExperiencesEntities.length) {
                    return (
                        record.ssoMemberWorkExperiencesEntities.map((item, idx) => {
                            return (
                                <div key={item.id}>
                                    {record.ssoMemberWorkExperiencesEntities.length > 1 && <span>{idx + 1}. </span>}
                                    {!!item.unitName && <span>{item.unitName}</span>}
                                    {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                    {!!item.unitPost && <span>{item.unitPost}</span>}
                                </div>
                            )
                        })
                    );
                } else {
                    return <Text type="secondary">&ndash;</Text>;
                }
            }
        },
        {
            title: '邀请函接受状态',
            dataIndex: 'invitationAcceptStatus',
            width: 120,
            render: (_, record) => {
                const invitationAcceptStatus = record?.invitationUsers?.invitationAcceptStatus || 0;
                return (
                    <>
                        {invitationAcceptStatus === 0 && <span>未反馈</span>}
                        {invitationAcceptStatus === 1 && <Text type="success">已接受</Text>}
                        {invitationAcceptStatus === 2 && <>
                            <Text type="danger" style={{ verticalAlign: 'middle' }}>已拒绝</Text>
                            {!!record?.invitationUsers?.refusalInfo && (
                                <Tooltip placement="bottom" trigger="click" title={record?.invitationUsers?.refusalInfo}>
                                    <svg style={{ marginLeft: 4, color: '#888', verticalAlign: 'middle' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                                    </svg>
                                </Tooltip>
                            )}
                        </>}
                        {invitationAcceptStatus === 3 && <Text type="warning">待确认</Text>}
                    </>
                );
            }
        },
        {
            title: '项目任务及接受状态',
            dataIndex: 'tasks',
            width: 400,
            render: (_, record) => {
                if (record.agendasGuestsList && record.agendasGuestsList.length) {
                    return <>
                        <ul className="task-list">
                            {record.agendasGuestsList.map((item, idx) => {
                                return (
                                    <li key={idx} style={{ display: idx !== 0 && !record.expanded ? 'none' : '' }}>
                                        <span style={{ marginRight: 4 }}>{`${item.conferenceHallName} / ${item.title}`}</span>
                                        <Tag color="blue">{item?.taskName}</Tag>
                                        {item.invitationAccepted === 0 && <Text type="secondary">未反馈</Text>}
                                        {item.invitationAccepted === 1 && <Text type="success">已确认</Text>}
                                        {item.invitationAccepted === 2 && <Tooltip placement="top" trigger="click" title={item?.refusalInfo}><span style={{ color: '#F00', textDecoration: 'underline', cursor: 'pointer' }}>已拒绝</span></Tooltip>}
                                        {item.invitationAccepted === 3 && <Text type="warning">待确认</Text>}
                                        <br />
                                        <span className="task-time">{convertDateTimeRangeToLocalText(item.startTime, item.endTime, 0)}</span>
                                    </li>
                                );
                            })}
                        </ul>

                        {record.agendasGuestsList.length > 1 && (
                            <div
                                className="toggle-task-expansion"
                                style={{ color: '#1890ff', cursor: 'pointer', fontSize: 12 }}
                                onClick={() => toggleAgendaTasks(record)}
                            >
                                <span className="anticon" style={{transform: !!record?.expanded ? 'rotate(180deg)' : 'none'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16" style={{width: 12, height: 12}}>
                                      <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659"/>
                                    </svg>
                                </span>
                                <span>{!!record?.expanded ? '收起' : `展开${record.agendasGuestsList.length}个任务`}</span>
                            </div>
                        )}
                    </>;
                } else {
                    return <Text type="secondary">未安排任务</Text>;
                }
            },
        },
        {
            title: '权益形式',
            dataIndex: 'rightForm',
            width: 120,
            render: (_, record) => {
                let text = record?.rightForm
                    ? JSON?.parse(record?.rightFormSetting)?.filter(item => item.id == record?.rightForm)[0]?.name
                    : <span>未反馈</span>;
                return <>{text}</>
            },
            hidden: !invitationOne.rightFormEnabled
        },
        {
            title: '特别活动',
            dataIndex: 'cocreationForm',
            width: 120,
            render: (_, record) => {
                let checkForm = record?.cocreationForm ? JSON?.parse(record?.cocreationForm) : [];
                let FormSet = record?.cocreationFormSetting ? JSON?.parse(record?.cocreationFormSetting) : [];
                let filterForm = FormSet?.filter(item => checkForm?.includes(item.id));
                let text = record?.cocreationForm
                    ? <>
                        {
                            <>
                                {
                                    filterForm && !!filterForm.length && filterForm.map((item, index) => (
                                        <div key={index}>{item.name}</div>
                                    ))
                                }
                                {checkForm?.includes(-1) && <div>暂不参与</div>}
                            </>
                        }
                    </>
                    : <span>未反馈</span>;
                return <>{text}</>
            },
            hidden: !invitationOne.cocreationFormEnabled
        },
        // {
        //     title: '完善信息',
        //     dataIndex: 'userInfoPerfect',
        //     width: 80,
        //     render: (_, record) => record.userInfoPerfect === 1
        //         ? <Text type="success">已提交</Text>
        //         : record.selected === 0
        //             ? <span>未反馈</span>
        //             : <Text type="secondary">&ndash;</Text>
        // },
        {
            title: '开启状态',
            dataIndex: 'enabled',
            fixed: 'right',
            width: 70,
            render: (_, record) => (
                <Switch
                    checked={record?.invitationUsers?.enabled}
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                    onChange={checked => changeStatus(checked, record)}
                />
            )
        },
        {
            title: () => (
                <Tooltip title="该功能设置发送后会给【订阅】了小程序服务通知的用户发送一条服务通知；但若当前嘉宾未订阅小程序服务通知，即使设置了发送，也收不到服务通知。">
                    服务通知 <QuestionCircleOutlined />
                </Tooltip>
            ),
            dataIndex: 'notices',
            width: 90,
            fixed: 'right',
            render: (_, record) => {
                return (
                    <>
                        {record.invitationUsers.messageStatus === 2 &&
                            <Tooltip title={record.invitationUsers.messageResult}>
                                <Text type="danger" style={{ verticalAlign: 'middle' }}>发送失败</Text>
                                <svg style={{ marginLeft: 4, color: '#888', verticalAlign: 'middle' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                                </svg>
                            </Tooltip>}
                        {record.invitationUsers.messageStatus === 1 && <Text type="success">发送成功</Text>}
                        {!record.invitationUsers.messageStatus && <Button size="small" type="primary" disabled={!(record?.invitationUsers?.enabled)} onClick={() => sendMessage(record)}>发送</Button>}
                    </>
                );
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 235,
            fixed: 'right',
            render: (_, record) => {
                const invitationAcceptStatus = record?.invitationUsers?.invitationAcceptStatus || 0;
                let items = [
                    { label: '定制电子邀请函', key: 'setupUserInvitation', onClick: () => openSetupInvitation(record)},
                    { label: '确认受邀用户信息', key: 'confirm', onClick: () => openViewGuestInfoDrawer(record.id) },
                    {
                        label: '删除邀请用户',
                        key: 'delete',
                        onClick: () => onDeleteInvitationUser(record.id),
                        danger: true
                    },

                ];
                // 会议进入劳务阶段，禁用代嘉宾确认任务
                if (conference && !conference?.laborFeeConfirmedAt) {
                    items.push({
                        label: '代嘉宾确认任务',
                        key: 'confirmInvite',
                        onClick: () => onComfirmInvitation(record),
                    });
                }

                return (
                    <span className="operation-btns">
                        {invitationOne?.type === 3
                            ? (
                                <>
                                    <Popover
                                        placement="left"
                                        title="邀请函二维码"
                                        trigger="click"
                                        content={<>
                                            <QRCode
                                                id={record?.invitationUsers?.userCode}
                                                size={180}
                                                value={getInvitationUrl(record)}
                                                fgColor="#000"
                                                bgColor="#fff"
                                                includeMargin={true}
                                            />
                                            <div style={{ paddingLeft: 16, paddingBottom: 10 }}>
                                                <a download={`${record.memUsername}_${record?.invitationUsers?.userCode}`}
                                                   id={`link_${record?.invitationUsers?.userCode}`}
                                                   onClick={() => downloadQRCode(record)}>
                                                    下载二维码
                                                </a>
                                            </div>
                                        </>}
                                    >
                                        <Tooltip title="查看邀请函二维码">
                                            <Button size="small" type="link" onClick={() => dispatch(setCursor(record.id))}>二维码</Button>
                                        </Tooltip>
                                    </Popover>

                                    <Divider type="vertical"/>

                                    <Tooltip title="复制邀请链接">
                                        <Button size="small" type="link" onClick={() => copyInvitationLink(record)}>链接</Button>
                                    </Tooltip>

                                    <Tooltip title="下载电子版邀请函">
                                        <Button
                                            size="small"
                                            type="link"
                                            onClick={() => downloadInvitationFile(record)}
                                            disabled={downloadCountDown > 0}
                                        >
                                            电子邀请函{downloadCountDown > 0 ? `(${downloadCountDown})` : ''}
                                        </Button>
                                    </Tooltip>

                                    <Divider type="vertical"/>
                                    {!!invitationOne.smsInfo && (
                                        <Tooltip title="复制邀请话术">
                                            <Button size="small" type="link" onClick={() => copyInvitationText(record)}>邀请话术</Button>
                                        </Tooltip>
                                    )}
                                    <Divider type="vertical"/>
                                </>
                            )
                            : null
                        }

                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button size="small" type="link" className="more-operation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                </svg>
                            </Button>
                        </Dropdown>
                    </span>
                );
            }
        },

    ].filter(c => !c.hidden); // 有隐藏列

    // 更改状态
    const changeStatus = async (checked, item) => {
        let accepted = false;
        if (item.agendasGuestsList && item.agendasGuestsList.length) {
            accepted = item.agendasGuestsList.some(agenda => agenda.invitationAccepted);
        }

        if (!checked && accepted) {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: `关闭邀请后，用户我的项目中将不再展示该邀请函，确认关闭邀请？`,
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    const res = await invitationusersStatusApi({enabled: checked ? 1 : 0, invitationUserId: item.invitationUsers.id});
                    if (res.status_code === 200) {
                        getInvitationUsers();
                    }
                }
            });
        } else {
            const res = await invitationusersStatusApi({enabled: checked ? 1 : 0, invitationUserId: item.invitationUsers.id});
            if (res.status_code === 200) {
                getInvitationUsers();
            }
        }
    };

    // 导出邀请函链接
    const downloadFileToExcel = () => {
        if (!data) return message.warning('您未添加邀请用户，无法导出');
        exportInvitationLink({
            groupId: invitationOne.groupId,
            invitationId: invitationOne.id,
        }, { responseType: "blob" }).then((res) => {
            let content = res;
            let blob = new Blob([content], { type: "application/octet-stream" });
            const date = new Date()
            const showTime = moment(date).format("YYYYMMDDTHHmmss").toString();
            if ("download" in document.createElement("a")) {
                const link = document.createElement("a");
                // 文件名字
                link.download = `${invitationOne.id}_${invitationOne.name}_${showTime}.xlsx`;

                link.href = URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            } else {
                navigator.msSaveBlob(blob);
            }
        })

    }

    return <>
        <div className="page-title">
            <Link to={`/invitation/g/${gid}/invitations`} className="back"><LeftOutlined/></Link>
            <span className="current-title">邀请用户管理</span>
            {!!id && <span className="current-subtitle">{invitationOne.name}</span>}
        </div>

        <Content className="main-content-box">
            <div className="filter-form-box">
                <Form
                    {...filterFormItemLayout}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="嘉宾姓名" name="username">
                                <Input allowClear placeholder="嘉宾姓名" autoComplete="off"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="单位" name="unitName">
                                <Input allowClear placeholder="单位名称关键字"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="科室/部门" name="unitOffices">
                                <Input allowClear placeholder="所在科室或部门关键字"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="职务" name="unitPost">
                                <Input allowClear placeholder="职务关键字"/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="嘉宾级别" name="expertLevels">
                                <Select
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    options={[
                                        { value: 6, label: 'VIP★★★★★' },
                                        { value: 5, label: 'VIP★★★★' },
                                        { value: 4, label: 'VIP★★★' },
                                        { value: 3, label: 'VIP★★' },
                                        { value: 2, label: 'VIP★' },
                                        { value: 1, label: '专家' },
                                        { value: 10, label: '企业VIP' },
                                        { value: 11, label: '企业人员' },
                                        { value: 12, label: '健康界工作人员' },
                                        { value: 0, label: '普通用户' },
                                    ]}
                                    placeholder="请选择嘉宾级别"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="嘉宾任务状态" name="invitationAcceptStatus">
                                <Select placeholder="请选择嘉宾任务接受状态" allowClear>
                                    <Select.Option value={0}>未反馈</Select.Option>
                                    <Select.Option value={1}>已接受</Select.Option>
                                    <Select.Option value={2}>已拒绝</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                <Button type="default" onClick={onReset}>重置</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>

            <div className="main-content-body">

                <div className="table-caption">
                    <div className="table-title">
                        <Space>
                            <Button type="primary" onClick={openAddUserDrawer}>添加邀请用户</Button>
                            {invitationOne?.invitationGroup?.applicationType === 1 && (
                                <>
                                    <Dropdown.Button
                                        menu={{
                                            items: [
                                                {
                                                    label: '管理会议嘉宾',
                                                    key: 'conf-guests',
                                                    onClick: () => {
                                                        navigate(`/conf/conferences/${invitationOne?.invitationGroup?.applicationItemId}/guests`);
                                                    }
                                                }
                                            ]
                                        }}
                                        onClick={() => {
                                            navigate(`/conf/conferences/${invitationOne?.invitationGroup?.applicationItemId}/agendas`);
                                        }}
                                    >
                                        管理会议日程
                                    </Dropdown.Button>
                                </>
                            )}
                        </Space>
                    </div>
                    <div className="table-toolbar">
                        <Space>
                            <Button onClick={() => openSetupInvitation()}><SettingOutlined />电子邀请函设置</Button>
                            {invitationOne.type === 3 && <Button onClick={downloadFileToExcel}>导出邀请话术</Button>}
                            <Tooltip title="刷新">
                                <Button type="default" icon={<ReloadOutlined/>} onClick={handleRefresh}/>
                            </Tooltip>
                        </Space>
                    </div>
                </div>

                <Table
                    rowKey={item => item.id}
                    columns={columns}
                    dataSource={data}
                    rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                    size="middle"
                    scroll={{ x: 1300 }}
                />
            </div>
        </Content>

        <Drawer
            title={!isEmpty(setupInvitationUser) ? '定制电子邀请函' : '电子邀请函设置'}
            width={drawerWidth}
            layout="horizontal"
            open={visibleSetupInvitation}
            onClose={closeSetupInvitation}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeSetupInvitation}>取消</Button>
                        <Button type="primary" onClick={setupInvitation}>确定</Button>
                    </Space>
                </div>
            }
        >

            <Form
                name="setupInvitation"
                layout="vertical"
                form={setupInvitationForm}
                autoComplete="off"
            >

                {isEmpty(setupInvitationUser) && (
                    <Alert
                        type="warning"
                        message={<>
                            电子邀请函模板设置，为邀请函中所有邀请用户设置统一的生成模板。<br/>
                            如需为某个邀请用户定制生成模板，请使用“<strong>操作-定制电子邀请函</strong>”功能。
                        </>}
                        style={{marginBottom: 16}}
                    />
                )}

                {!isEmpty(setupInvitationUser) && (
                    <Form.Item label="邀请用户">
                        {setupInvitationUser?.memUsername}{`（${setupInvitationUser?.memMobile}）`}
                    </Form.Item>
                )}

                <Form.Item
                    name="titleEnabled"
                    label="自定义会议标题"
                    valuePropName="checked"
                    extra="开启自定义会议标题时，电子邀请函中会议标题按自定义标题和副标题展示；关闭则展示会议项目名称"
                >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        return !!getFieldValue('titleEnabled') && (
                            <div className="sub-form-fields">
                                <Form.Item
                                    name="title"
                                    label="会议标题"
                                    rules={[
                                        { required: true, message: '请填写会议标题' },
                                    ]}
                                >
                                    <Input placeholder="请输入会议标题" allowClear showCount maxLength={30}/>
                                </Form.Item>

                                <Form.Item name="subtitle" label="副标题" style={{marginBottom: 0}}>
                                    <Input placeholder="请输入会议副标题" allowClear showCount maxLength={30}/>
                                </Form.Item>
                            </div>
                        )
                    }}
                </Form.Item>

                <Form.Item
                    name="coverUrl"
                    label="封面图"
                    extra="图片宽210mm高297mm，300PPI，支持JPG/PNG格式，大小不超过5MB"
                >
                    {!!invitationFileCoverUrlList.length && <AntdImage width={200} src={invitationFileCoverUrlList[0]} style={{marginBottom: 8}}/>}

                    <div>
                        <Upload
                            beforeUpload={(file) => checkout(file, 5)}
                            fileList={invitationFileCoverUrlList}
                            customRequest={uploadInvitationCover}
                            maxCount={1}
                            showUploadList={false}
                        >
                            <Button icon={<UploadOutlined/>}>
                                {!invitationFileCoverUrlList.length ? '上传图片' : '更改图片'}
                            </Button>
                        </Upload>
                    </div>
                </Form.Item>

                <Form.Item
                    name="bodyBgUrl"
                    label="正文背景图"
                    extra="图片宽210mm高297mm，页眉元素不高于25mm，300PPI，支持JPG/PNG格式，大小不超过5MB"
                >
                    {!!invitationBodyBgUrlList.length && <AntdImage width={200} src={invitationBodyBgUrlList[0]} style={{marginBottom: 8}}/>}

                    <div>
                        <Upload
                            beforeUpload={(file) => checkout(file, 5)}
                            fileList={invitationBodyBgUrlList}
                            customRequest={uploadInvitationBodyBg}
                            maxCount={1}
                            showUploadList={false}
                        >
                            <Button icon={<UploadOutlined/>}>
                                {!invitationBodyBgUrlList.length ? '上传图片' : '更改图片'}
                            </Button>
                        </Upload>
                    </div>
                </Form.Item>

                <Form.Item
                    name="letterEnabled"
                    label="邀请辞"
                    valuePropName="checked"
                    extra="开启邀请辞则根据邀请函设置展示邀请辞（邀请函未设置邀请辞时该选项不可用），关闭则不展示邀请辞"
                >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
                </Form.Item>

                <Form.Item
                    name="confDetailsEnabled"
                    label="大会详情"
                    valuePropName="checked"
                    extra="展示会议时间、地点、规模、组织架构、温馨提示等信息"
                >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        return !!getFieldValue('confDetailsEnabled') && (
                            <div className="sub-form-fields">
                                <Form.Item
                                    name="confDetailsTitle"
                                    label="大会详情标题"
                                    rules={[
                                        { required: true, message: '请填写大会详情标题' },
                                    ]}
                                >
                                    <Input placeholder="请输入大会详情标题" allowClear showCount maxLength={30}/>
                                </Form.Item>

                                <Form.Item
                                    name="confDetails"
                                    label="大会详情内容"
                                    rules={[
                                        { required: true, message: '请填写大会详情内容' },
                                    ]}
                                >
                                    <TinyMCEEditor
                                        form={setupInvitationForm}
                                        id="confDetails"
                                        fieldValue="confDetails"
                                        initialValue={isEmpty(setupInvitationUser)
                                            ? invitationOne?.invitationFileSettings?.confDetails
                                            : setupInvitationUser?.invitationUsers?.invitationFileSettings?.confDetails}
                                        placeholder="请输入大会详情内容"
                                    />
                                </Form.Item>
                            </div>
                        )
                    }}
                </Form.Item>

                <Form.Item
                    name="taskEnabled"
                    label="嘉宾任务"
                    valuePropName="checked"
                    extra="开启嘉宾任务，可展示嘉宾日程表格或电子邀请函二维码"
                    style={{marginBottom: 10}}
                >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        return !!getFieldValue('taskEnabled') && (
                            <div className="sub-form-fields">
                                <Form.Item
                                    name="taskForm"
                                    label="嘉宾任务形式"
                                    rules={[
                                        { required: true, message: '请选择嘉宾任务形式' },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={1}>嘉宾日程任务表格</Radio>
                                        <Radio value={2}>电子邀请函二维码</Radio>
                                        <Radio value={3}>嘉宾日程任务表格+电子邀请函二维码</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name="contactFrom"
                                    label="联系人来源"
                                    rules={[
                                        { required: true, message: '请选择嘉宾联系人来源' },
                                    ]}
                                    style={{marginBottom: 8}}
                                >
                                    <Radio.Group>
                                        <Radio value={1}>来自邀请函设置</Radio>
                                        <Radio value={2}>自定义联系人</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {getFieldValue('contactFrom') === 2 && (
                                    <div className="customized-contacts">
                                        <Form.List name="contacts">
                                            {(fields, { add, remove, move }) => {
                                                if (!fields.length) {
                                                    add();
                                                }

                                                return (
                                                    <div className="contacts">
                                                        {fields.length < 5 && (
                                                            <Button
                                                                type="link"
                                                                size="small"
                                                                style={{marginBottom: 10}}
                                                                icon={<span className="anticon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                                    </svg>
                                                                </span>}
                                                                onClick={() => add()}
                                                            >
                                                                <span style={{marginLeft: 4}}>新增联系人</span>
                                                            </Button>
                                                        )}

                                                        {fields.map(({ key, name, ...restField }, idx) => (
                                                            <div className="customized-contact" key={key} style={{marginBottom: 16}}>
                                                                <Row gutter={10}>
                                                                    <Col span={8}>
                                                                        <Form.Item
                                                                            name={[name, 'userName']}
                                                                            style={{marginBottom: 0}}
                                                                            rules={[{required: true, message: ''}]}
                                                                        >
                                                                            <Input placeholder="联系人"/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <Form.Item
                                                                            name={[name, 'phone']}
                                                                            style={{marginBottom: 0}}
                                                                            rules={[{required: true, message: ''}]}
                                                                        >
                                                                            <Input placeholder="联系电话"/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                                                        {fields.length > 1 && (
                                                                            <Button
                                                                                type="link"
                                                                                size="small"
                                                                                className="link-btn-operations"
                                                                                icon={<span className="anticon">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                                                    </svg>
                                                                                </span>}
                                                                                onClick={() => remove(name)}
                                                                            >
                                                                                移除
                                                                            </Button>
                                                                        )}

                                                                        {idx !== 0 && (
                                                                            <Button
                                                                                type="link"
                                                                                size="small"
                                                                                className="link-btn-operations"
                                                                                icon={<span className="anticon">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                                                                                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                                                                                    </svg>
                                                                                </span>}
                                                                                onClick={() => move(idx, idx - 1)}
                                                                            >
                                                                                上移
                                                                            </Button>
                                                                        )}

                                                                        {idx !== fields.length - 1 && (
                                                                            <Button
                                                                                type="link"
                                                                                size="small"
                                                                                className="link-btn-operations"
                                                                                icon={<span className="anticon">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                                                                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
                                                                                    </svg>
                                                                                </span>}
                                                                                onClick={() => move(idx, idx + 1)}
                                                                            >
                                                                                下移
                                                                            </Button>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ))}
                                                    </div>
                                                );
                                            }}
                                        </Form.List>

                                    </div>
                                )}
                            </div>
                        )
                    }}
                </Form.Item>

                <Form.Item
                    label="日程详情"
                    extra="开启则仅展示与嘉宾相关的分论坛日程，关闭则展示所有分论坛日程（过滤无日程的分论坛）"
                >
                    <span style={{marginRight: 8}}>仅导出嘉宾出席的分论坛日程</span>
                    <Form.Item name="relatedConfHallsOnly" valuePropName="checked" noStyle>
                        <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
                    </Form.Item>
                </Form.Item>

            </Form>

        </Drawer>

        <ConfirmInvite
            open={confirmInviteOpen}
            setConfirmInviteOpen = {setConfirmInviteOpen}
            userCode={currentUserCode}
            updateList={getInvitationUsers}
        />

        <AddGuest
            closeAddUserDrawer={closeAddUserDrawer}
            visibleAddUserDrawer={visibleAddUserDrawer}
            setVisibleAddUserDrawer={setVisibleAddUserDrawer}
            onResetGuest={onResetGuest}
        />

        <GuestInfo
            guestInfoDrawer={guestInfoDrawer}
            closeGuestInfoDrawer={closeGuestInfoDrawer}
            expertId={currentExpertId}
            invitationId={id}
        />

    </>
}
