import { Table, Layout, Button, message } from "antd";
import { getCaseScoreList } from "@services/Selection";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import config from '@config';

import "./index.less";

const { Content } = Layout;

const columns = [
    {
        title: "奖项",
        dataIndex: "rewardName",
        align: "center",
    },
    {
        title: "排名",
        dataIndex: "caseRank",
        align: "center",
    },
    {
        title: "案例编号",
        dataIndex: "caseId",
        align: "center",
    },
    {
        title: "案例名称",
        dataIndex: "caseName",
        align: "center",
    },
    {
        title: "提报单位",
        dataIndex: "hosName",
        align: "center",
    },
    {
        title: "最终分数",
        dataIndex: "finalScore",
        align: "center",
    },
];

const Lists = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getCaseScoreList({ projectId: params.pid, stageId: params.sid, topicId: params.tid, platformType: 'pc' }).then((res) => {
            if (res?.status_code === 200) {
                setDataSource(res?.data?.list || []);
            }
        });
    }, [params.pid, params.sid, params.tid]);

    const handleReleaseClick = () => {
        // message.success("敬请期待！");
        window.open(`${config.SELECTION_DOMAIN}/rank/cases?projectId=${params.pid}&stageId=${params.sid}&topicId=${params.tid}`);
    };

    return (
        <>
            <div className="scoring-setting page-title">
                <span className="back" onClick={() => navigate(-1)}>
                    <LeftOutlined />
                </span>
                <div className="current-title">评分设置</div>
            </div>

            <Content className="main-content-box list-table-content">
                <Button onClick={handleReleaseClick} type="primary" className="release-rank-button">
                    发布榜单
                </Button>
                <Table dataSource={dataSource} columns={columns} rowKey="caseId" pagination={false} />
            </Content>
        </>
    );
};

export default Lists;
