import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const OnlineChart = ({ chartDate }) => {
    let onlineChart;
    console.log(chartDate);

    function echartsFunction(all) {
        onlineChart && onlineChart.dispose();
        onlineChart = echarts.init(document.getElementById('online-chart'));

        const option = {
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {},
            grid: {
                left: '5%',
                top: '10%',
                right: '5%',
                bottom: '10%',
                containLabel: true,
            },
            xAxis: {
                type: 'value',
                boundaryGap: false,
                position: 'top',
                min: (value, index) => {
                    if (value.min < 1) {
                        return 0
                    } else {
                        return 0
                    }
                },
                max: (value, index) => {
                    if (value.max < 5) {
                        return 5
                    } else {
                        return value.max
                    }
                },
                minInterval: 1,
                axisLabel: {
                    formatter: (value, index) => {
                        return value
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: ['≥4次在线', '3次在线', '2次在线', '1次在线'],
                boundaryGap: [0, 0.01],
            },
            series: [
                {
                    type: 'bar',
                    data: chartDate,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    },
                    itemStyle: {
                        color: '#1677ff',
                    }
                },
            ],
        };

        onlineChart.setOption(option);

        window.addEventListener('resize', function () {
            onlineChart.resize();
        });
    }

    useEffect(() => {
        setTimeout(() => {
            let all = 0
            if (chartDate) {
                chartDate.forEach((item) => {
                    all += item
                })
                // console.log(all);
            }

            console.log(all);

            echartsFunction(all);
        }, 20);
    }, [echartsFunction]);

    return <div className='online-chart' id='online-chart'></div>;
};

export default OnlineChart;
