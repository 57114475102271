import { get, put, post } from '@core/request';

/**邀请函组接口服务**/

// 获取邀请函组列表
export const getInvitationGroupListApi = (params) => {
    return post('/invitation/boss/invitationgroups/list', params);
};

// 根据ID查询邀请函组

export const getInvitationGroupOneApi = (params) => {
    return get('/invitation/boss/invitationgroups/getOne', params);
};

// 新增邀请组
export const saveInvitationGroupApi = (params) => {
    return post('/invitation/boss/invitationgroups/save', params);
};

// 更新邀请组
export const updateInvitationGroupApi = (params) => {
    return put('/invitation/boss/invitationgroups/update', params);
};

// 删除邀请函组
export const deleteInvitationGroupApi = (params) => {
    return get('/invitation/boss/invitationgroups/delete', params);
};

// 查询会议项目下拉列表
export const getConferencesApi = (params) => {
    return post('/invitation/boss/conferences/list', params);
};

// 标记测试邀请组
export const invitationGroupMarkAsTesting = (params) => {
    return put('/invitation/boss/invitationgroups/markAsTesting', params);
};



