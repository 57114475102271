import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Layout, Table } from 'antd';
import UserListFilterForm from './UserListFilterForm';
import { fullPagerConfig } from '@/utils/property';
import { useNavigate } from 'react-router-dom';
import { get } from '@/core/request';
const { Content } = Layout;

export default function UserList() {
    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, phone } = filterObj;

    useEffect(() => {
        get(`/dms/users/user-queryPage`, {
            limit: pageSize,
            page: currPage,
            name: name,
            phone: phone
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }

        })
    }, [name, phone, pageSize, currPage]);


    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };


    // 点击查看跳转
    const navigate = useNavigate()
    const changePath = useCallback(
        (row) => {
            return () => {
                navigate(`/dms/users/${row.id}`, { state: { id: row.id } });
            }
        },
        [navigate],
    )

    const columns = [
        { title: '用户ID', dataIndex: 'id', key: 'id' },
        { title: '用户名', dataIndex: 'name', },
        { title: '手机号', dataIndex: 'phone', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operate, row) => (<span className="operate-style" onClick={changePath(row)}>查看</span>)
        },
    ];


    return (
        <Fragment>
            <div className="page-title">用户管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <UserListFilterForm
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">
                            用户列表</div>
                    </div>

                    <Table className="playback-table" columns={columns}
                        dataSource={tabledata.list} pagination={paginationProps} />

                </div>
            </Content>

        </Fragment>
    )
}
