import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Layout, Form, Input, Divider, Button, Radio, Select, Space, TreeSelect, message } from 'antd';
import { TreeDataSource } from '@utils/utils';
import { post, get, put } from '@/core/request';

const { Content } = Layout;

export default function EnterpriseStaff() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { state: { tip, id } } = useLocation();
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);

    // 请求数据
    useEffect(() => {
        // 部门
        get(`/enterprise/departments/get_all`).then((res) => {
            if (res.status_code === 200) {
                let departments = TreeDataSource(res.data)
                setDepartments(departments)
            }
        })
    }, []);

    useEffect(() => {
        // 角色
        get(`/enterprise/roles/get_all`).then((res) => {
            if (res.status_code === 200) {
                setRoles(res.data)
            }
        })
    }, [])

    useEffect(() => {
        if (tip === 'edit') {
            get(`/enterprise/staffs/get_staff_id`, { id }).then((res) => {
                if (res.status_code === 200) {
                    const { name, departmentIdArray, email, phone, roleIdArray, post, sex, number } = res.data;
                    form.setFieldsValue({
                        name, departments: departmentIdArray, email, phone, roles: roleIdArray ? roleIdArray : undefined, post, sex, number
                    })
                }
            })
        }
    }, [])

    const onFinish = (value) => {
        if (tip === 'edit') {
            value.id = id;
            put(`/enterprise/staffs/update`, value).then(res => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    navigate('/enterprise/staffs');
                }
            })
        } else {
            post(`/enterprise/staffs/save`, value).then(res => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    navigate('/enterprise/staffs');
                }
            })
        }

    }

    const handleReset = () => {
        navigate('/enterprise/staffs')
    }

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/enterprise/staffs" className="back"><LeftOutlined /></Link>
                {tip === 'add' ? '新增员工' : '编辑员工'}
            </div>

            <Content className="main-content-box">
                <div className="main-content-title">员工信息</div>

                <div className="main-content-body">

                    <Form className="page-form" {...pageFormItemLayout} form={form} onFinish={onFinish}>

                        <Form.Item
                            name="name"
                            label="姓名"
                            help="姓名2~16个字符，只允许输入汉字、英文字母"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value || !/^[a-zA-Z\u4e00-\u9fa5]{2,16}$/.test(value)) {
                                            return Promise.reject('姓名2~16个字符，只允许输入汉字、英文字母');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="输入员工姓名" allowClear autoComplete="off" />
                        </Form.Item>

                        {
                            tip === 'add' && <Form.Item
                                name="password"
                                label="初始密码"
                                help="密码不得少于6位，至少包含数字和字母（大写或小写）"
                                required={true}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/.test(value)) {
                                                return Promise.reject('密码不得少于6位，至少包含数字和字母（大写或小写）');
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input placeholder="输入初始密码" allowClear autoComplete="off" />
                            </Form.Item>
                        }

                        <Form.Item
                            name="phone"
                            label="手机号"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!/^1\d{10}$/.test(value)) {
                                            return Promise.reject('手机号不正确');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="输入员工手机号码" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="邮箱地址"
                            help="输入正常的企业邮箱地址（@hmkx.cn）"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value?.includes('@hmkx.cn')) {
                                            return Promise.reject('输入正常的企业邮箱地址（@hmkx.cn）');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="输入员工邮箱地址" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item label="性别" name="sex">
                            <Radio.Group>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="部门" help="可多选添加多个部门" name="departments">
                            {departments && <TreeSelect
                                multiple
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={departments}
                                placeholder="选择添加部门"
                            />}
                        </Form.Item>

                        <Form.Item label="职务" name="post">
                            <Input placeholder="输入员工职务" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item label="工号" name="number">
                            <Input placeholder="输入员工工号" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="roles"
                            label="角色"
                            help="可多选添加多个角色，用于对员工进行后台权限控制"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value === undefined || value.length === 0) {
                                            return Promise.reject('角色不能为空');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="选择添加角色"
                            >
                                {
                                    roles && roles.map(item => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Divider />

                        <Form.Item {...pageFormItemWithoutLabelGridLayout} style={{ marginBottom: 0 }}>
                            <Space>
                                <Button type="primary" htmlType="submit">提交</Button>
                                <Button type="default" onClick={handleReset}>取消</Button>
                            </Space>

                        </Form.Item>

                    </Form>

                </div>

            </Content>
        </Fragment>
    );
}
