import { Divider, Drawer, Form, Radio, Input, Space, Button  } from 'antd';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';
import { useState, useEffect, Fragment } from 'react';

export default ({
    drawerState,
    setDrawerState,
    currentModule,
    updateModuleSettings,
}) => {
    const [form] = Form.useForm();

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    // 新增模块
    const onFinish = async (values) => {
        updateModuleSettings({
            ...values,
            id: currentModule.id
        });
    };

    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 6 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="LiveModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="LiveModuleForm"
                form={form}
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="displayTitle"
                    label="显示标题"
                    rules={[
                        { required: true, message: '显示标题不能为空' }
                    ]}
                >
                    <Input placeholder="请输入模块显示标题" />
                </Form.Item>

                <Form.Item name="displayTitleEn" label="英文显示标题">
                    <Input placeholder="请输入模块英文显示标题" />
                </Form.Item>

                <Form.Item
                    name="path"
                    label="访问路径"
                    rules={[
                        { required: true, message: '访问路径不能为空' },
                        { pattern: /^[a-z\d_]{2,20}$/, message: '访问路径不符合格式要求' },
                    ]}
                    help={
                        <>
                            填写访问该模块的访问路径，如会议简介访问路径 <b>introduction</b><br />
                            格式合求：小写字母、数字或下划线（_），限2-20个字符
                        </>
                    }
                >
                    <Input placeholder="请输入模块访问路径" minLength={2} maxLength={20} showCount disabled={currentModule?.moduleType <= 10} />
                </Form.Item>
                <Form.Item
                    name="status"
                    label="导航状态"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>敬请期待</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="hiddenWeb"
                    label="Web端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="hiddenH5"
                    label="H5端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>


        </Drawer>
    );
};
