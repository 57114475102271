import React, { useEffect, useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Space, Select, DatePicker, Cascader } from 'antd';
import { get } from '@/core/request';
const { RangePicker } = DatePicker;

export default function ComplianceListFilterForm({
    handleFilterFinish
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    // 产品线
    const [liveId, setLiveId] = useState([]);
    // 所属企业
    const [orgId, setOrgId] = useState([]);
    // 直播分类
    const [liveCatesId, setLiveCatesId] = useState([]);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setLiveId(res.data);
        })
    }, []);

    // 合作企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    // 直播分类
    useEffect(() => {
        get('/dms/lives/categories/findCatesTmenu').then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.value = item.id;
                    item.label = item.name;
                    item.children.forEach(elem => {
                        elem.value = elem.id;
                        elem.label = elem.name;
                        delete elem.children;
                    })
                })
                setLiveCatesId(res.data);
            }
        })
    }, []);

    const onFinish = (values) => {
        values.viewStartTime = timePicker[0];
        values.viewEndTime = timePicker[1];
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            userNmae: undefined,
            phone: undefined,
            liveName: undefined,
            liveId: undefined,
            productId: undefined,
            orgId: undefined,
            cateId: undefined,
            identityType: undefined,
            viewStartTime: undefined,
            viewEndTime: undefined,
        }
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户名" name="userNmae">
                        <Input allowClear placeholder="请输入用户名称" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="请输入用户手机号码" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播标题" name="liveName">
                        <Input allowClear placeholder="请输入直播标题" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播ID" name="liveId">
                        <Input allowClear placeholder="请输入直播ID" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线" name="productId">
                        <Select allowClear placeholder="请选择产品线">
                            {liveId && liveId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播分类" name="cateIdArr">
                        <Cascader options={liveCatesId} placeholder="请选择直播分类" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="参与身份" name="identityType">
                        <Select allowClear placeholder="请选择参与身份" >
                            <Select.Option value={1}>嘉宾</Select.Option>
                            <Select.Option value={2}>观众</Select.Option>
                            <Select.Option value={3}>主持人</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="参加时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
