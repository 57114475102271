import React, { useState, useMemo, useRef } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Input, Dropdown, Form } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.less";
// import './index.less';
const MatrixEditcol = ({ item, id, colValue, setColValue, handleDelete }) => {
    const quillRef = useRef(null);
    const { TextArea } = Input;
    const [colBorder, setColBorder] = useState(false);
    const [colIn, setColIn] = useState(false);
    const delCol = () => {
        console.log(id);
        var index = id.lastIndexOf("-");
        let str = Number(id.substring(index + 1, id.length));
        handleDelete(str);
    };
    const items = [
        {
            label: (
                <p className="down_text" onClick={delCol}>
                    删除
                </p>
            ),
            key: "item-1",
        }, // 菜单项务必填写 key
    ];
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                    ],
                ],
            },
        };
    }, []);

    const getBorder = () => {
        setColBorder(true);
        setColIn(true);
    };

    const cancelBorder = (e) => {
        // console.log(colValue);
        let newcol = [...colValue];
        // let index=e.target.id.slice(-1)
        console.log(newcol);
        newcol.push(e.target.value);
        // console.log(newCol);
        setColValue(newcol);
        setColBorder(false);
        setColIn(false);
    };

    return (
        <div className="edit_col">
            {/* <ReactQuill theme="snow" ref={quillRef}
        modules={modules}
       placeholder="请输入问题"
       className="question-title-area"
        // onFocus={()=>getBorder(questionIndex)}
        // defaultValue={item.body}
            // onBlur={()=>changeTitle(questionIndex)}
            /> */}

            <Form.Item name={["colName", id]} rules={[{ required: true, message: "请填写" }]}>
                <TextArea
                    className="col"
                    id={id}
                    bordered={colBorder}
                    defaultValue={item || ""}
                    onFocus={(e) => getBorder()}
                    onBlur={(e) => cancelBorder(e)}
                    placeholder={"请输入标题"}
                    maxLength={30}
                    style={{ border: colIn ? "1px solid #1a73e8" : "" }}
                    autoSize={{
                        mincols: 1,
                    }}
                ></TextArea>
            </Form.Item>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={["click"]}
                placement="bottomLeft"
                arcol={{
                    pointAtCenter: true,
                }}
            >
                <MoreOutlined className="edit_action" onClick={(e) => e.preventDefault()} />
            </Dropdown>
        </div>
    );
};

export default MatrixEditcol;
