import React, { useEffect, useState } from "react";
import { Button, Input, Upload, message, Divider } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import QuestionItemCom from "./components/QuestionItemCom";
import { getGenerateApi, putEditQuestionApi, putEdititleApi } from "@services/Wj/Statistical";
import { uploadFile } from "@services/Wj";
import ImgCrop from "antd-img-crop";
import html2canvas from "html2canvas";
import config from "@config";
import "./index.less";

const ReportSurvey = () => {
    const location = useLocation();
    const params = useParams();

    const option = { headers: { "Content-Type": "multipart/form-data" } };

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const [isDownloading, setIsDownloading] = useState(false);

    const goBack = () => {
        window.history.back();
    };

    const downloadReport = () => {
        setIsDownloading(true);
        setTimeout(() => {
            formHtmlToDownload("main_content_wrap", () => setIsDownloading(false));
        }, 0);
    };

    const formHtmlToDownload = async (domId, callback) => {
        let res = await drawCanvas(domId);
        let linkA = document.createElement("a"); //创建a标签
        linkA.href = res; // 将图片的src赋值给a节点的href
        linkA.download = data.reportTitle || "download"; // 设置a节点的download属性值(设置下载名称)
        let event = new MouseEvent("click"); // 模拟鼠标click点击事件
        linkA.dispatchEvent(event);
        callback();
    };

    const DPR = () => {
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        } else {
            return 1;
        }
    };

    const parseValue = (value) => {
        return parseInt(value, 10);
    };

    const drawCanvas = async (domId) => {
        // 获取想要转换的 DOM 节点
        const dom = document.getElementById(domId);
        // const dom = document.querySelector('printHtml');
        const box = window.getComputedStyle(dom);

        // DOM 节点计算后宽高
        const width = parseValue(box.width);
        const height = parseValue(box.height);
        // 获取像素比-防止模糊
        const scaleBy = DPR();

        console.log(width, height, "======");

        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        // canvas.width = 1334
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        // canvas.style.width = `1334px`;
        canvas.style.height = `${height}px`;
        // 获取画笔
        const context = canvas.getContext("2d");

        // 将所有绘制内容放大像素比倍

        // context.scale(1334/width, 1);

        // 将自定义 canvas 作为配置项传入，开始绘制
        return await html2canvas(dom, { canvas, useCORS: true }).then((canvas) => {
            //document.querySelector("#canvasContainer").appendChild(canvas);
            //return canvas.toDataURL("image/png");
            return canvas.toDataURL("image/png");
        });
    };

    const onChange = (v, key, id) => {
        console.log(v, key, id);
        let obj = {};
        if (key === "questions") {
            let arr = data.questions?.map((item) => {
                if (item.questionId === id) {
                    return {
                        ...item,
                        directions: v,
                    };
                } else {
                    return item;
                }
            });
            obj = {
                ...data,
                questions: arr,
            };
        } else {
            obj = {
                ...data,
                [key]: v,
            };
        }
        setData(obj);
    };

    const onBlur = async (v, key, id) => {
        if (key === "questions") {
            let params = {
                surveyId: location.state?.id, //问卷id
                questionId: id,
                directions: v, //说明
            };
            let res = await putEditQuestionApi(params);
            if (res.status_code === 200) {
                message.success("操作成功");
            }
        } else {
            let params = {
                surveyId: location.state?.id, //问卷id
                [key]: v,
            };
            let res = await putEdititleApi(params);
            if (res.status_code === 200) {
                message.success("操作成功");
            }
        }
    };

    const uploadButton = (
        <div className="upload_img_wrap">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>上传图片</div>
        </div>
    );

    const fn = (v, bool) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                var txt = event.target.result;
                var img = document.createElement("img");
                img.src = txt;
                img.onload = function () {
                    if (img.width < 320) {
                        bool && message.warn("图片宽度小于320像素");
                        resolve(false);
                    } else if (img.height < 180) {
                        bool && message.warn("图片高度小于180像素");
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            };
            reader.readAsDataURL(v);
        });
    };

    const upload = async (value) => {
        const formData = new FormData();
        formData.append("file", value.file);
        formData.append("path", `skins/upload/`);
        const res = await uploadFile(formData, option);
        if (res.status_code === 200) {
            setImageUrl(config.OSS_PREFIX + "/" + res.data);
            let params = {
                surveyId: location.state?.id, //问卷id
                reportLogo: config.OSS_PREFIX + "/" + res.data,
            };
            let result = await putEdititleApi(params);
            if (result.status_code === 200) {
                message.success("操作成功");
            }
        }
    };

    const onImgChange = async (info) => {
        const { fileList: newFileList, file, event } = info;
        if (newFileList.length < 1) {
            setImageUrl("");
            return;
        }
        const res = await fn(file.originFileObj || file);
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            // 设置展示
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                // setImageUrl(url);
            });
            // 设置数据
        }
    };

    const onImgPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("图片格式仅支持jpg，jepg，png");
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error("图片大小需小于5M");
        }
        return isJpgOrPng && isLt5M;
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const getData = async (bool) => {
        let res = await getGenerateApi({
            surveyId: location.state?.id || null,
        });

        if (res.status_code === 200) {
            setData(res?.data);
            setImageUrl(res?.data?.reportLogo);
            if (bool) message.success("数据已更新");
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="report_charts_wrap">
            <div className="page_title_wrap">
                <div className="page-left">
                    <div className="back">
                        <LeftOutlined onClick={goBack} />
                    </div>
                    <span className="share-name">{location.state?.name || ""}</span>
                </div>
                <div className="page-center">问卷报告</div>
                <div className="page-right">
                    <Button type="primary" onClick={() => getData(true)}>
                        更新数据
                    </Button>
                    <Button type="primary" ghost onClick={downloadReport}>
                        导出报告
                    </Button>
                </div>
            </div>
            <div className="main_content">
                {/* <p className='sdclsnvkjnskjdv'>三点擦吧就是代表参加就是代表参加好</p> */}
                <div className="render_wrap" id="main_content_wrap">
                    <div className="head">
                        {/* 我似乎投诉电话长白山赌博就是彼此机会吧 */}
                        {!isDownloading ? <Input className="text_center input_title my_self_input" size="large" placeholder="点击此处输入问卷标题" value={data?.reportTitle || ""} onChange={(e) => onChange(e.target.value, "reportTitle")} onBlur={(e) => onBlur(e.target.value, "reportTitle")} maxLength={50}></Input> : isDownloading && data?.reportTitle ? <h1>{data?.reportTitle}</h1> : null}

                        {!isDownloading ? <Input className="text_center input_title_dec my_self_input" placeholder="点击此处添加副标题或摘要" value={data?.reportSubTitle || ""} onChange={(e) => onChange(e.target.value, "reportSubTitle")} onBlur={(e) => onBlur(e.target.value, "reportSubTitle")} maxLength={200}></Input> : isDownloading && data?.reportSubTitle ? <p className="text_center">{data?.reportSubTitle}</p> : null}
                    </div>

                    <Divider />

                    <div className="data_content">
                        <div className="data_content_main">
                            {data.questions?.length
                                ? data.questions.map((item, index) => {
                                    return <QuestionItemCom key={index} index={index} item={item} type={item.questionTypeId} onChange={(v, id) => onChange(v, "questions", id)} onBlur={(v, id) => onBlur(v, "questions", id)} isDownloading={isDownloading}></QuestionItemCom>;
                                })
                                : null}
                        </div>
                    </div>
                    <div className="footer">
                        {!(isDownloading && !imageUrl) ? (
                            <div className="img_wrap">
                                <ImgCrop
                                    aspect={16 / 9}
                                    rotate
                                    maxZoom={5}
                                    beforeCrop={async (v) => {
                                        const res = await fn(v, true);
                                        return res;
                                    }}
                                >
                                    {/* TODO 修改action地址 */}
                                    <Upload customRequest={upload} showUploadList={false} onChange={onImgChange} onPreview={onImgPreview} beforeUpload={beforeUpload} data={{ type: 8 }}>
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                alt="avatar"
                                                style={{
                                                    width: "200px",
                                                }}
                                            />
                                        ) : (
                                            uploadButton
                                        )}
                                    </Upload>
                                </ImgCrop>
                            </div>
                        ) : null}
                        {!isDownloading ? <Input className="my_self_input text_center" value={data?.reportCreator || ""} placeholder="点击此处添加报告生成方的名字" onChange={(e) => onChange(e.target.value, "reportCreator")} onBlur={(e) => onBlur(e.target.value, "reportCreator")} maxLength={100}></Input> : isDownloading && data?.reportCreator ? <p className="text_center">{data?.reportCreator}</p> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportSurvey;
