import { get, post } from '@core/request';
import config from '@config';

// 科室&职务&职称
export const getAuthDictionary = (params) => {
    return get(`/dataengine/api/authdictionary/${params.identity}/${params.type}`);
};

// 评选项目信息
export const getProjectInfo = params => {
    return get('/selection/api/project/getInfo', params);
};

// 上传文件
export const uploadFileApi = (params, option) => {
    return post('/dataengine/cases/file/save-img', params, {
        ...option,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

// 评选项目主题填报字段列表
export const getProjectTopicFields = (data) => {
    return get('/dataengine/cases/case-topic-fields/get-base-info', data);
}

// 阿里云OSS上传凭证
export const getAliyuncred = (data) => {
    return post(config.VIDEOTRANSCODE_PREFIX + '/media/video/upload-info', data, {
        timeout: 600000
    });
}

// 视频点播
export function getVodMediaInfo(params) {
    const url = `${config.VIDEOTRANSCODE_PREFIX}/media/video/play-info`;
    return get(url, params);
}

// 阿里云OSS刷新凭证
export const refreshAliyuncred = (data) => {
    return get(config.VIDEOTRANSCODE_PREFIX + '/media/video/re-upload-info', data);
}

export const videoTransCode = (params) => {
    return post(`${config.VIDEOTRANSCODE_PREFIX}/media/video/stream-upload`, params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

// 案例新增/修改
export const addCaseApi = (data) => {
    return post('selection/admin/case/update', data);
}

// 案例机构列表
export const caseOrganizationApi = (data) => {
    return get('/dataengine/api/organisationinfo/search', data);
}

// 案例机构新增
export const caseAddOrganizationApi = (data) => {
    return post('/dataengine/api/organisationinfo/apply', data);
}

//获取文档token
export function getDocPreviewToken(data) {
    const url = `${config.VIDEOTRANSCODE_PREFIX}/media/document/get-sign-preshow`;
    return post(url, data);
}

// 案例id获取案例详情
export const caseDetailsApi = (data) => {
    return get(`/selection/admin/case/cases/${data.caseId}`, {});
}

//获取详情
export function getCaseApi(params) {
    return get(`/selection/api/project/case/detail`,params);
}


//科室职务职称
export function getPeopleInfoApi(params) {
    return get(`/dataengine/api/authdictionary/getByCode`,params);
}


//申报单位信息
export function getCompanyInfoApi(params) {
    return get(`/dataengine/api/organisationinfo/${params.id}`);
}

//所属赛区
export function getZonesApi(params) {
    return get('/selection/api/project/zones', params);
}

