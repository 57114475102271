import { createSlice } from '@reduxjs/toolkit';

const commonState = {
    filter: {
        page: 1,
        pageSize: 20,
    },
    cursor: 0,
};

const initialState = {
    project: {
        ...commonState,
        filter: {
            ...commonState.filter,
            isTesting: 0,
        },
    },

    case: {
        ...commonState
    },
};

const selectionSlice = createSlice({
    name: 'selection',
    initialState,
    reducers: {
        setProjectFilter: (state, action) => void(state.project.filter = action.payload),
        setProjectCursor: (state, action) => void(state.project.cursor = action.payload),

        setCaseFilter: (state, action) => void(state.case.filter = action.payload),
        setCaseCursor: (state, action) => void(state.case.cursor = action.payload),
    }
});

export const selectionActions = selectionSlice.actions;

export default selectionSlice;
