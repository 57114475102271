import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Space,
    message,
    Drawer,
    Checkbox,
    Col,
    Cascader,
    Select,
    Upload,
    Modal,
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { getUserInfoApi, getUserBaseInfoApi, putUserBaseInfoApi } from '@services/Invitation/InvitationUser';
import { pageFormItemLayout } from '@/utils/property';
import { uniqBy } from 'lodash';
import ReactQuill from "react-quill";
import areas from '@utils/areas';
import nationalList from '@utils/national';
import { dateTimeFormat, getFileUrl } from '@/utils/utils'
import './GuestInfo.less';
const { TextArea } = Input;

const GuestInfo = ({
    guestInfoDrawer,
    closeGuestInfoDrawer,
    expertId,
    invitationId,
}) => {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);
    const [pagelist, setPagelist] = useState([]);
    const [data, setData] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImg, setPreviewImg] = useState('');
    const [fileList, setFileList] = useState();
    const [userFileList, setUserFileList] = useState();
    const [loading, setLoading] = useState(false);
    const [electronSignSrc, steElectronSignSrc] = useState('')



    const handleChangeCheck = (e) => {
        if (e.target.checked) {
            const list = uniqBy([...checkedList, e.target.label], item => item);
            setCheckedList(list);
        } else {
            const res = [...checkedList].filter(item => item !== e.target.label);
            setCheckedList(res);
        }
    };

    const changAll = () => {
        const list = pagelist.includes('electronSign') ? [...pagelist].filter(item => item !== 'electronSign') : [...pagelist];
        setCheckedList([...list, 'expertAvatar']);
    }
    const changCancelAll = () => {
        setCheckedList([]);
    }

    const handleUpdateUser = () => {
        setLoading(true);
        const {
            idCard,
            bankName,
            bankCardNo,
            memGender,
            wechat,
            email,
            skilledFields,
            specialization,
            member_work_experiences,
            memIntroduction,
            provinceCode,
            cityCode,
            districtCode,
            birthday,
            national,
            school,
            education,
            workingYears,
            professional,
            disposableTime,
            eatingHabits,
            hobby,
            socialPosition,
            academicPosition,
            member_contacts
        } = data || {};
        const nationalText = nationalList.filter(item => {
            return item.id == national
        })[0]?.value
        const experiencesObj = [{
            unitJobTitle: member_work_experiences ? member_work_experiences[0]?.unit_job_title : '',
            unitName: member_work_experiences ? member_work_experiences[0]?.unit_name : '',
            unitOffice: member_work_experiences ? member_work_experiences[0]?.unit_office : '',
            unitPost: member_work_experiences ? member_work_experiences[0]?.unit_post : '',
            childs: [
                {
                    unitJobTitle: member_work_experiences ? member_work_experiences[0]?.unit_job_title : '',
                    unitName: member_work_experiences ? member_work_experiences[0]?.unit_name : '',
                    unitOffice: member_work_experiences ? member_work_experiences[0]?.unit_office : '',
                    unitPost: member_work_experiences ? member_work_experiences[0]?.unit_post : '',
                }
            ]
        }]
        let obj = {
            idCard,
            bankName,
            bankCardNo,
            memGender,
            expertWechat: wechat,
            expertEmail: email,
            skilledFields,
            specialization,
            experiences: experiencesObj,
            memIntroduction,
            provinceCode,
            cityCode,
            districtCode,
            birthday,
            national: nationalText,
            school,
            education,
            workingYears,
            professional,
            disposableTime: disposableTime == 1 ? '较多' : disposableTime == 2 ? '较少' : '',
            eatingHabits,
            hobby,
            socialPosition,
            academicPosition,
            contactsList: [
                {
                    name: member_contacts ? member_contacts[0]?.name : '',
                    phone: member_contacts ? member_contacts[0]?.phone : '',
                    email: member_contacts ? member_contacts[0]?.email : '',
                }
            ],
            expertAvatar: fileList[0]?.url || '',
        }

        let res = {}
        checkedList.forEach(item => {
            if (item === "unitPosition") {
                res.experiences = obj.experiences
            } else if (item === "location") {
                res.provinceCode = obj.provinceCode
                res.cityCode = obj.cityCode
                res.districtCode = obj.districtCode
            } else if (item === "relatedContact") {
                res.contactsList = obj.contactsList
            } else {
                res[item] = obj[item]
            }
        })

        putUserBaseInfoApi({
            id: expertId,
            ...res
        }).then(result => {
            if (result && result.status_code === 200) {
                getUserInfo('edit')
            } else {
                setLoading(false);
            }
        })
    }



    useEffect(() => {
        if (guestInfoDrawer) {
            getUserInfoApi({ expertId, invitationId }).then(res => {
                if (res && res.status_code === 200) {
                    const { fieldValue = "{}", userFields = "[]", avatarPic = '' } = res?.data || {};
                    setData(JSON?.parse(fieldValue));
                    setPagelist(JSON?.parse(userFields));
                    const { provinceCode = '', cityCode = '', districtCode = '', memGender, email, disposableTime, wechat, electronSign } = JSON?.parse(fieldValue) || {};
                    avatarPic ? setFileList([{ url: getFileUrl(avatarPic) }]) : setFileList([]);
                    steElectronSignSrc(getFileUrl(electronSign));
                    form1.setFieldsValue({
                        ...JSON?.parse(fieldValue),
                        memGender: memGender == 1 ? '男' : memGender == 0 ? '女' : '',
                        expertEmail: email,
                        disposableTime: disposableTime == 1 ? '较多' : disposableTime == 2 ? '较少' : '',
                        expertWechat: wechat,
                        location: [provinceCode, cityCode, districtCode],
                    })
                }
            })
            getUserInfo();
        }

    }, [guestInfoDrawer])


    const getUserInfo = (type) => {
        getUserBaseInfoApi({ id: expertId }).then(res => {
            if (type == 'edit') {
                setLoading(false);
                setCheckedList([]);
            }
            if (res && res.status_code === 200) {
                if (type == 'edit') message.success('更新成功');
                const { data } = res || {};
                const { provinceCode, cityCode, districtCode, memGender, expertAvatar, birthday } = data || {};
                expertAvatar ? setUserFileList([{ url: getFileUrl(expertAvatar) }]) : setUserFileList([]);
                form2.setFieldsValue({
                    ...data,
                    memGender: memGender == 1 ? '男' : memGender == 0 ? '女' : '',
                    location: [provinceCode, cityCode, districtCode],
                    birthday: dateTimeFormat(birthday),
                })
            }
        })
    }

    const handlePreview = (res) => {
        setPreviewImg(res?.url || '')
        setPreviewOpen(true);
    }


    return (
        <div>
            <Drawer
                title="嘉宾信息同步"
                className="guest-info-drawer"
                placement="bottom"
                height="100%"
                destroyOnClose
                open={guestInfoDrawer}
                onClose={closeGuestInfoDrawer}
            >

                <div className="drawer-main">
                    <div className="drawer-main-left">
                        <div className="drawer-main-head">
                            <div className="drawer-main-head-title">嘉宾信息</div>
                            <Space >
                                <Button type="primary" onClick={changAll}>全选</Button>
                                <Button type="primary" onClick={changCancelAll}>取消全选</Button>
                                <Button type="primary" disabled={checkedList.length == 0} loading={loading} onClick={handleUpdateUser}>同步选择项</Button>
                            </Space>
                        </div>

                        <Checkbox.Group style={{ width: '100%' }} value={checkedList}>
                            <Form
                                {...pageFormItemLayout}
                                form={form1}
                                className="guest-info-form"
                            >

                                {pagelist.includes('idCard') &&
                                    <>
                                        <div className="form-subtitle">劳务信息</div>
                                        <Col className="form-check-invitation">
                                            <Checkbox label='idCard' onChange={handleChangeCheck} value='idCard' />
                                            <Form.Item name="idCard" label="身份证号" className="form-item-invitation">
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col className="form-check-invitation">
                                            <Checkbox label='bankName' onChange={handleChangeCheck} value='bankName' />
                                            <Form.Item name="bankName" label="开户行名称" className="form-item-invitation">
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col className="form-check-invitation">
                                            <Checkbox label='bankCardNo' onChange={handleChangeCheck} value='bankCardNo' />
                                            <Form.Item name="bankCardNo" label="银行账号" className="form-item-invitation">
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col className="form-check-invitation">
                                            <Checkbox label='electronSign' disabled />
                                            <Form.Item name="electronSign" label="电子签名" className="form-item-invitation">
                                                <img src={electronSignSrc} width={'100%'} alt="" />
                                            </Form.Item>
                                        </Col>

                                    </>
                                }
                                <div className="form-subtitle">个人信息</div>
                                <Col className="form-check-invitation">
                                    <Checkbox label='expertAvatar' onChange={handleChangeCheck} value='expertAvatar' />
                                    <Form.Item name="expertAvatar" label="头像" className="form-item-invitation">
                                        <Upload
                                            disabled
                                            onPreview={handlePreview}
                                            listType='picture-card'
                                            fileList={fileList}
                                        >
                                            {(fileList && fileList.length >= 1) ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        上传头像
                                                    </div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)} className='preview-modal' >
                                            <img
                                                alt="example"
                                                src={previewImg}
                                                className="expert-image"
                                            />
                                        </Modal>
                                    </Form.Item>
                                </Col>
                                {/* <Col className="form-check-invitation">
                                    <Checkbox label='userName' disabled />
                                    <Form.Item name="userName" label="姓名" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col> */}

                                {pagelist.includes('memGender') && <Col className="form-check-invitation">
                                    <Checkbox label='memGender' onChange={handleChangeCheck} value='memGender' />
                                    <Form.Item name="memGender" label="性别" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('expertWechat') && <Col className="form-check-invitation">
                                    <Checkbox label='expertWechat' onChange={handleChangeCheck} value='expertWechat' />
                                    <Form.Item name="expertWechat" label="微信" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('expertEmail') && <Col className="form-check-invitation">
                                    <Checkbox label='expertEmail' onChange={handleChangeCheck} value='expertEmail' />
                                    <Form.Item name="expertEmail" label="电子邮箱" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('skilledFields') && <Col className="form-check-invitation">
                                    <Checkbox label='skilledFields' onChange={handleChangeCheck} value='skilledFields' />
                                    <Form.Item name="skilledFields" label="擅长领域" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('specialization') && <Col className="form-check-invitation">
                                    <Checkbox label='specialization' onChange={handleChangeCheck} value='specialization' />
                                    <Form.Item name="specialization" label="擅长专业" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('unitPosition') && <Col className="form-check-invitation">
                                    <Checkbox label='unitPosition' onChange={handleChangeCheck} value='unitPosition' />
                                    <Form.Item name="unitPosition" label="单位任职" className="form-item-invitation">
                                        <Form.List
                                            name='member_work_experiences'
                                        >
                                            {(fields) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <div className='section-box' key={key}>
                                                            <div className='section-index'>{index + 1}</div>
                                                            <div className='section-content-invitation'>
                                                                <div >
                                                                    <Form.Item
                                                                        className='form-list-work-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'unit_name']}
                                                                        label='单位名称'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className='form-list-work-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'unit_office']}
                                                                        label='科室/部门'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className='form-list-work-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'unit_post']}
                                                                        label='职务'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className='form-list-work-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'unit_job_title']}
                                                                        label='职称'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('memIntroduction') && <Col className="form-check-invitation">
                                    <Checkbox label='memIntroduction' onChange={handleChangeCheck} value='memIntroduction' />
                                    <Form.Item name="memIntroduction" label="个人介绍" className="form-item-invitation">
                                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} disabled />
                                    </Form.Item>
                                </Col>}


                                {pagelist.includes('location') && <Col className="form-check-invitation">
                                    <Checkbox label='location' onChange={handleChangeCheck} value='location' />
                                    <Form.Item name="location" label="所在地" className="form-item-invitation">
                                        <Cascader options={areas} changeOnSelect disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('birthday') && <Col className="form-check-invitation">
                                    <Checkbox label='birthday' onChange={handleChangeCheck} value='birthday' />
                                    <Form.Item name="birthday" label="生日" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('national') && <Col className="form-check-invitation">
                                    <Checkbox label='national' onChange={handleChangeCheck} value='national' />
                                    <Form.Item name="national" label="民族" className="form-item-invitation">
                                        <Select options={nationalList.map(item => { return { label: item.value, value: item.id } })} disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('school') && <Col className="form-check-invitation">
                                    <Checkbox label='school' onChange={handleChangeCheck} value='school' />
                                    <Form.Item name='school' label="毕业院校" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('education') && <Col className="form-check-invitation">
                                    <Checkbox label='education' onChange={handleChangeCheck} value='education' />
                                    <Form.Item name='education' label="最高学历" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('workingYears') && <Col className="form-check-invitation">
                                    <Checkbox label='workingYears' onChange={handleChangeCheck} value='workingYears' />
                                    <Form.Item name='workingYears' label="工作年限" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('professional') && <Col className="form-check-invitation">
                                    <Checkbox label='professional' onChange={handleChangeCheck} value='professional' />
                                    <Form.Item name='professional' label="带教专业" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('disposableTime') && <Col className="form-check-invitation">
                                    <Checkbox label='disposableTime' onChange={handleChangeCheck} value='disposableTime' />
                                    <Form.Item name='disposableTime' label="可支配时间" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('eatingHabits') && <Col className="form-check-invitation">
                                    <Checkbox label='eatingHabits' onChange={handleChangeCheck} value='eatingHabits' />
                                    <Form.Item name='eatingHabits' label="饮食习惯" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('hobby') && <Col className="form-check-invitation">
                                    <Checkbox label='hobby' onChange={handleChangeCheck} value='hobby' />
                                    <Form.Item name='hobby' label="爱好" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('socialPosition') && <Col className="form-check-invitation">
                                    <Checkbox label='socialPosition' onChange={handleChangeCheck} value='socialPosition' />
                                    <Form.Item name='socialPosition' label="社会任职" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('academicPosition') && <Col className="form-check-invitation">
                                    <Checkbox label='academicPosition' onChange={handleChangeCheck} value='academicPosition' />
                                    <Form.Item name='academicPosition' label="学术任职" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>}
                                {pagelist.includes('relatedContact') && <Col className="form-check-invitation">
                                    <Checkbox label='relatedContact' onChange={handleChangeCheck} value='relatedContact' />
                                    <Form.Item name='relatedContact' label="相关联系人" className="form-item-invitation">
                                        <Form.List
                                            name='member_contacts'
                                            disabled
                                        >
                                            {(fields) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <div className='section-box' key={key}>
                                                            <div className='section-index'>{index + 1}</div>
                                                            <div className='section-content-invitation'>

                                                                <div >
                                                                    <Form.Item
                                                                        className='form-list-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'name']}
                                                                        label='姓名'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className='form-list-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'phone']}
                                                                        label='电话'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className='form-list-item-invitation'
                                                                        {...restField}
                                                                        name={[name, 'email']}
                                                                        label='邮箱'
                                                                    >
                                                                        <Input disabled />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>
                                </Col>}


                            </Form>
                        </Checkbox.Group>

                    </div>
                    <div className="drawer-main-right">
                        <div className="drawer-main-head">
                            <div className="drawer-main-head-title">专家库信息</div>
                        </div>
                        <Form
                            {...pageFormItemLayout}
                            form={form2}
                        >

                            {pagelist.includes('idCard') &&
                                <>
                                    <div className="form-subtitle">劳务信息</div>
                                    <Form.Item name="idCard" label="身份证号" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item name="bankName" label="开户行名称" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item name="bankCardNo" label="银行账号" className="form-item-invitation">
                                        <Input disabled />
                                    </Form.Item>
                                </>
                            }
                            <div className="form-subtitle">个人信息</div>
                            <Form.Item name="expertAvatar" label="头像" className="form-item-invitation">
                                <Upload
                                    disabled
                                    onPreview={handlePreview}
                                    listType='picture-card'
                                    fileList={userFileList}
                                >
                                    {(userFileList && userFileList.length >= 1) ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                上传头像
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)} className='preview-modal' >
                                    <img
                                        alt="example"
                                        src={previewImg}
                                        className="expert-image"
                                    />
                                </Modal>
                            </Form.Item>
                            {/* <Form.Item name="userName" label="姓名" className="form-item-invitation">
                                <Input disabled />
                            </Form.Item> */}

                            {pagelist.includes('memGender') &&
                                <Form.Item name="memGender" label="性别" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('expertWechat') &&
                                <Form.Item name="expertWechat" label="微信" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('expertEmail') &&
                                <Form.Item name="expertEmail" label="电子邮箱" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('skilledFields') &&
                                <Form.Item name="skilledFields" label="擅长领域" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('specialization') &&
                                <Form.Item name="specialization" label="擅长专业" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('unitPosition') &&
                                <Form.Item name="unitPosition" label="单位任职" className="form-item-invitation">
                                    <Form.List
                                        name='experiences'
                                    >
                                        {(fields) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }, index) => (
                                                    <div className='section-box' key={`unit-${index}`}>
                                                        <div className='section-index'>{index + 1}</div>
                                                        <div className='section-content-invitation'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'unitName']}
                                                                label='单位名称'
                                                                className='form-list-work-item-invitation form-list-work-title'
                                                            >
                                                                <Input disabled />
                                                            </Form.Item>

                                                            <Form.List
                                                                name={[name, 'childs']}
                                                            >
                                                                {(childs) => (
                                                                    <>
                                                                        {childs.map(({ key: childKey, name: childName, ...restField }, idx) => (<>
                                                                            <div className='form-list-work' key={`childName-${idx}`}>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    label="科室/部门"
                                                                                    name={[childName, 'unitOffice']}
                                                                                    className='form-list-work-item-invitation'
                                                                                >
                                                                                    <Input disabled />
                                                                                </Form.Item>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    label="职务"
                                                                                    name={[childName, 'unitPost']}
                                                                                    className='form-list-work-item-invitation'
                                                                                >
                                                                                    <Input disabled />
                                                                                </Form.Item>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    label="职称"
                                                                                    name={[childName, 'unitJobTitle']}
                                                                                    className='form-list-work-item-invitation'
                                                                                >
                                                                                    <Input disabled />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </>))}
                                                                    </>)}
                                                            </Form.List>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            }

                            {pagelist.includes('memIntroduction') &&
                                <Form.Item name="memIntroduction" label="个人介绍" className="form-item-invitation">
                                    <ReactQuill
                                        readOnly
                                        theme='snow'
                                    />
                                </Form.Item>
                            }
                            {pagelist.includes('location') &&
                                <Form.Item name="location" label="所在地" className="form-item-invitation">
                                    <Cascader options={areas} changeOnSelect disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('birthday') &&
                                <Form.Item name="birthday" label="生日" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('national') &&
                                <Form.Item name="national" label="民族" className="form-item-invitation">
                                    <Select options={nationalList.map(item => { return { label: item.value, value: item.id } })} disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('school') &&
                                <Form.Item name='school' label="毕业院校" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('education') &&
                                <Form.Item name='education' label="最高学历" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('workingYears') &&
                                <Form.Item name='workingYears' label="工作年限" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('professional') &&
                                <Form.Item name='professional' label="带教专业" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('disposableTime') &&
                                <Form.Item name='disposableTime' label="可支配时间" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('eatingHabits') &&
                                <Form.Item name='eatingHabits' label="饮食习惯" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('hobby') &&
                                <Form.Item name='hobby' label="爱好" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('socialPosition') &&
                                <Form.Item name='socialPosition' label="社会任职" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('academicPosition') &&
                                <Form.Item name='academicPosition' label="学术任职" className="form-item-invitation">
                                    <Input disabled />
                                </Form.Item>
                            }
                            {pagelist.includes('relatedContact') &&
                                <Form.Item name='relatedContact' label="相关联系人" className="form-item-invitation">
                                    <Form.List
                                        name='contactsList'
                                        disabled
                                    >
                                        {(fields) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }, index) => (
                                                    <div className='section-box' key={index}>
                                                        <div className='section-index'>{index + 1}</div>
                                                        <div className='section-content-invitation'>

                                                            <div >
                                                                <Form.Item
                                                                    className='form-list-item-invitation'
                                                                    {...restField}
                                                                    name={[name, 'name']}
                                                                    label='姓名'
                                                                >
                                                                    <Input disabled />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    className='form-list-item-invitation'
                                                                    {...restField}
                                                                    name={[name, 'phone']}
                                                                    label='电话'
                                                                >
                                                                    <Input disabled />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    className='form-list-item-invitation'
                                                                    {...restField}
                                                                    name={[name, 'email']}
                                                                    label='邮箱'
                                                                >
                                                                    <Input disabled />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            }
                        </Form>
                    </div>
                </div>

            </Drawer>
        </div>
    );
};


export default GuestInfo;
