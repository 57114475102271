import { get, put, post } from '@core/request';

//获取专家列表
export const getAllExpertsApi = (params) => {
    return get('/conference/boss/guests/expert-list', params);
};


export const saveExpertsApi = (params) => {
    return post('/conference/boss/guests/batchSave', params);
};


export const guestListApi = (params) => {
    return post('/conference/boss/guests/list', params);
};

export const guestDelApi = (params) => {
    return put('/conference/boss/guests/delete', params);
};

export const guestMessageOnceApi = (params) => {
    return get('/conference/boss/guests/getOne', params);
};

export const guestMessageChangeApi = (params) => {
    return put('/conference/boss/guests/update', params);
}
export const guestAllSureApi = (params) => {
    return post('/conference/boss/guests/batchAgendaSave', params);

};

export const guestDateApi = (params) => {
    return post('/conference/boss/agendaguests/getAgendaGuestsList',params);
}

export const guestDateUpdateApi = (params) => {
    return put('/conference/boss/agendaguests/update',params);
}

// 嘉宾行程列表
export const guestjourneysApi = (params) => {
    return get('/conference/boss/guestjourneys/list', params);
};

// 嘉宾行程中获取单个行程信息
export const fetchGuestJourney = params => get('/conference/boss/guestjourneys/getInfo', params);

// 嘉宾行程中保存行程信息
export const saveGuestJourney = data => post('/conference/boss/guestjourneys/save', data);

// 嘉宾行程中更新专家证件信息
export const updateGuestJourneyIdCard = data => post('/user/ssomemberexperts/updateIdcard', data);

// 嘉宾行程单设置
export const updateGuestJourneySettingsApi = data => put('/conference/boss/conferences/update-guest-journey-settings', data);

// 嘉宾行程单导入
export const GuestJourneyImportApi = data => post('/conference/boss/guestjourneys/import', data);

// 嘉行程快捷录入
export const extractGuestJourneyInfo = data => post('/conference/boss/guestjourneys/ai-input', data);


// 嘉宾行程单导入校验
export const GuestJourneyImportCheckApi = data => post('/conference/boss/guestjourneys/import-check', data, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
});

// 嘉宾行程单导出
export const GuestJourneyExportApi = data => get('/conference/boss/guestjourneys/exportGuestJourneysByConferenceId', data);

// 嘉宾行程单合计信息
export const GuestJourneyTotalApi = data => get('/conference/boss/guestjourneys/getTotal', data);

// 嘉宾导出
export const exportGuestsApi = data => get('/conference/boss/guests/download', data, {responseType: 'blob'});
