import MedConnectProjects from '../components/Medconnect/MedConnectProjects/MedConnectProjects';
import MedConnectProject from "../components/Medconnect/MedConnectProject/MedConnectProject";
import MedConnectQAList from "../components/Medconnect/MedConnectQAList/MedConnectQAList";
import MedConnectQuestionList from "../components/Medconnect/MedConnectQAList/MedConnectQuestionList";
import MedConnectQADetail from "../components/Medconnect/MedConnectQAList/MedConnectQADetail";
import MedConnectMembers from "../components/Medconnect/MedConnectUsers/MedConnectUsers";
import MedConnectLabfeeBatches from "../components/Medconnect/MedConnectLabfeeBatches/MedConnectLabfeeBatches";
import LabfeeBatchesList from "../components/Medconnect/MedConnectLabfeeBatches/LabfeeBatchesList";
import LabfeeBatchesDetail from "../components/Medconnect/MedConnectLabfeeBatches/LabfeeBatchesDetail";

const medconnectRoutes = [
    // 问答项目管理
    {path: 'projects', element: <MedConnectProjects />},
    // 项目配置
    {path: 'projects/create', element: <MedConnectProject />},

    {path: 'projects/:id', element: <MedConnectProject />},
    // 问题管理
    {path: 'projects/:id/question-list', element: <MedConnectQuestionList />},
    // 回答管理
    {path: 'projects/:id/answer-list', element: <MedConnectQAList />},
    // 问答问题详情
    {path: 'projects/:id/qa-list/:detailId/detail', element: <MedConnectQADetail />},

    // 人员管理
    {path: 'projects/:id/users', element: <MedConnectMembers />},

    // 劳务管理
    {path: 'projects/:id/labfee/batches', element: <MedConnectLabfeeBatches />},
    // 查看批次
    {path: 'projects/:id/labfee/batches/:batchNo/batch', element: <LabfeeBatchesList />},
    // 查看明细
    {path: 'projects/:id/labfee/batches/:batchNo/batch/:detailId/detail', element: <LabfeeBatchesDetail />},
];

export default medconnectRoutes;
