/**
 * 通用属性
 */

// 首页业务模块栅格布局
export const homeModuleGridLayout = {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 4 },
    xxl: { span: 3 },
};

// 抽屉宽
export const drawerWidth = 800;

// 抽屉中表单控件布局
export const drawerFormItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
};

// 抽屉中只读信息，用表单布局更好处理，同上
export const readonlyFormItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
};

// 页面表单布局（如课新增/编辑的页面级表单）
export const pageFormItemLayout = {
    labelCol: {
        sm: { span: 5 },
        // md: { span: 5 },
        lg: { span: 3 },
        xl: { span: 4 },
        xxl: { span: 6 }
    },
    wrapperCol: {
        sm: { span: 19 },
        // md: { span: 19 },
        lg: { span: 20 },
        xl: { span: 16 },
        xxl: { span: 12 }
    }
};

export const fullWidthPageFormItemLayout = {
    labelCol: {
        sm: { span: 4 },
        md: { span: 4 },
        lg: { span: 4 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        sm: { span: 20 },
        md: { span: 18 },
        lg: { span: 16 },
        xl: { span: 10 },
        xxl: { span: 10 }
    }
};

export const pageFormItemWithoutLabelGridLayout = {
    wrapperCol: {
        sm: { offset: 5, span: 19 },
        // md: { offset:5, span: 19 },
        lg: { offset: 3, span: 20 },
        xl: { offset: 4, span: 16 },
        xxl: { offset: 6, span: 12 }
    }
};

// 筛选窗体栅格布局
export const filterFormGridLayout = {
    sm: { span: 24 },
    md: { span: 12 },
    xl: { span: 8 },
    xxl: { span: 6 },
};

// 筛选窗体表单控件布局
export const filterFormItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 8 },
    },

    wrapperCol: {
        sm: { span: 24 },
        md: { span: 16 },
    }
};

// 完整分页属性配置
export const fullPagerConfig = {
    size: '',
    position: 'bottom',
    showSizeChanger: true,
    hideOnSinglePage: false,
    showQuickJumper: true,
    showTotal: (total, range) => `共${total}条`,
    pageSizeOptions: ['10', '20', '50', '100']
};


export const expertFormItemLayout = {
    labelCol: {
        sm: { span: 5 },
        md: { span: 5 },
        lg: { span: 5 },
        xl: { span: 4 },
        xxl: { span: 4 }
    },
    wrapperCol: {
        sm: { span: 19 },
        md: { span: 19 },
        lg: { span: 19 },
        xl: { span: 20 },
        xxl: { span: 20 }
    }
};

// 抽屉中表单控件布局 - 网站模块抽屉
export const drawerFormItemSiteLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
};

export const SelectionFormItemLayout = {
    labelCol: {
        sm: { span: 6 },
    },
    wrapperCol: {
        sm: { span: 17, offset: 1 },
    },
};

export const SelectionFormListItemLayout = {
    labelCol: {
        sm: { span: 6 },
    },
    wrapperCol: {
        sm: { span: 17, offset: 7 },
    },
};
