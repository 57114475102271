import { get, post, put, delet } from '@core/request';

/**
 * 原关联项目用医项目
 */

export const fetchMedProjectList = params => get('/medproject/projects/list', params);

export const fetchMedProject = id => get('/medproject/projects/info', { id });

export const createMedProject = data => post('/medproject/projects/save', data);

export const editMedProject = data => put('/medproject/projects/edit', data);

export const deleteMedProject = id => delet('/medproject/projects/delete', { id });


export const fetchRelatedProjects = id => get('/medproject/relatedProjects/list', { medprojectId: id });

export const storeRelatedProject = data => post('/medproject/relatedProjects/save', data);

export const removeRelatedProject = id => delet('/medproject/relatedProjects/delete', { id });
