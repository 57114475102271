import React, { Fragment, useEffect } from "react";
import "./index.less";
import phoneHeader from "@static/images/phoneheader.png";
import config from "@config";

const Mobilpreview = ({ type, content, displayTitle, displayCopyright, displayLogo, selectColor }) => {
    useEffect(() => {
        if (type !== undefined && type.includes("Header")) {
            if (content.route === "newCreate") {
                document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}/answer?type=2`;
            } else {
                document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}/answer?type=1`;
            }
        } else {
            if (content.route === "newCreate") {
                document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}?template=1`;
            } else {
                document.fr.location.href = `${config.SURVEY_DOMAIN}/s/${content.id}?type=3`;
            }
        }
    }, [type, displayTitle, displayCopyright, displayLogo, selectColor]);
    return (
        <Fragment>
            <div className="fracde-mobile">
                <div className="mobile-box">
                    <div className="mobile-final">
                        <img src={phoneHeader} className="phone-header" />
                        <div className="modal-content">
                            <iframe name="fr" className={`iframe-width ${type !== undefined && type.includes("Header") ? "" : "pointer-none"}`} id="frame" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Mobilpreview;
