import React,{useEffect,useState,useRef} from 'react';
import { Button, Layout, Table, Space, Form, Input, Select, Row, Col, Modal, message,Tooltip,Spin, Typography } from 'antd';
import { getConsultationApi } from '@services/HealthClass';
const HealthClassClue = () => {
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [data,setData] = useState(undefined)
    const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
    const isSearchRef = useRef(null);
    const [load,setLoad]=useState(false)
    const [filObj, setFilObj] = useState({
        phone:''
    })
    const onFormFinish = () => {
        isSearchRef.current = true;
        setFilObj({
			phone: form.getFieldValue('phone') || '',
		})
    }

    const onReset =() => {
        form.resetFields();
        setFilObj({
			phone: '',
		})
        setPage(1);
        setPageSize(10);

    }


    const columns = [
		{
			title: "姓名",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "手机号",
			dataIndex: "phone",
			key: "phone",
			align: "center",
		},
		{
			title: "单位名称",
			dataIndex: "unitName",
			key: "unitName",
			align: "center",
            render: (_, row) => (
				<>
                {row?.unitName?<div>{row.unitName}</div>:<div>/</div>}
                </>
			),
		},
		{
			title: "职务",
			dataIndex: "dutiesName",
			key: "dutiesName",
			align: "center",
            render: (_, row) => (
				<>
                {row?.dutiesName?<div>{row.dutiesName}</div>:<div>/</div>}
                </>
			),
		},
		{
			title: "团队人数",
			dataIndex: "number",
			key: "number",
            align: "center",
            render: (_, row) => (
				<>
                {row?.number?<div>{row.number}人</div>:<div>/</div>}
                </>
			),
		},
		{
			title: "申请时间",
			dataIndex: "createdAt",
			key: "createdAt",
			align: "center",
		},
	];

    const getListData = async (page, pageSize, phone) => {
        isSearchRef.current = false;
        let params = {
			page,
			pageSize,
			phone
		}
        console.log(params);
        setLoad(true)
        let res = await getConsultationApi(params)
		if(res.status_code === 200){
            setLoad(false)
			setData(res.data.list)
			setTotal(res.data.totalCount)
		}
    }

    const onChangePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	};

    useEffect(() => {
        const { phone } = filObj
        if (isSearchRef.current === true) {
            getListData(1, pageSize, phone)
        } else {
            getListData(page, pageSize, phone)
        }
	}, [page, pageSize, filObj])

    return (
        <div>
        <div className="page-title">问卷列表</div>
			<Content className="main-content-box" id='wj_survey_wrap'>
				<div className="main-content-body">
					<div className="table-caption">
						<div className="table-toolbar-role">
							<Form
								form={form}
								name="control-hooks"
							>
                                <Row  gutter={20}>
                                    <Col span={4}>
                                        <Form.Item name="phone" label="手机号:">
                                            <Input placeholder="请输入手机号" allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                    <Form.Item>
                                        <Button type='primary' htmlType="submit" onClick={onFormFinish}>查询</Button>
                                        <Button onClick={onReset} style={{marginLeft:'10px'}}>重置</Button>
                                    </Form.Item>
                                    </Col>
                                </Row>

							</Form>
						</div>
					<Table
						className="template-table"
						columns={columns}
						dataSource={data}
                        loading={load}
						pagination={{
							total: total,
							showTotal: () => `共${total}条`,
							current: page,
							pageSize: pageSize,
							onChange: onChangePage,
							showQuickJumper: true,
							showSizeChanger: true,
						}}
                        rowKey={(row) => row.id}
                        scroll={{ x: 1000 }}
					/>
					</div>
				</div>
			</Content>
        </div>
    );
}

export default HealthClassClue;
