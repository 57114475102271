import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { CloseOutlined, LeftOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    Image,
    Input,
    Layout,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Switch,
    Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import config from '@config';
import { isEmpty } from "@utils/utils";
import { addProjestsApi, editProjestsApi, getProjestsApi, getProjestsGroupListApi, selectionUploadFile } from '@services/Selection';
import competationImg from '@static/images/competation.png';
import judgeImg from '@static/images/judge.png';
import cerificate from '@static/images/cerificate.jpeg';
import './Project.less';
import { getBannerColor, prepareBCOSSUrl } from '../../../utils/utils';

const { Content } = Layout;

const Project = () => {
    const params = useParams();
    const navigate = useNavigate();
    const id = ~~params?.id || 0;

    const [form] = Form.useForm();

    const option = { headers: { "Content-Type": "multipart/form-data" } };

    const [projectsGroupList, setProjectsGroupList] = useState([]);
    const [coverList, setCoverList] = useState([]);
    const [oneData, setOneData] = useState(false);
    const [switchList, setSwitchList] = useState({ caseFileShow: true, zoneOn: false, caseBaseInfoShow: true });
    const [openRule, setOpenRule] = useState(null);
    const [titleName, setTitleName] = useState('');
    const [topicCount, setTopicCount] = useState();
    const [competitionList, setCompetitionList] = useState([]);
    const [judgesList, setJudgesList] = useState([]);
    const [certifiList, setCertifiList] = useState([]);
    const [isShowTemplate, setIsShowTemplate] = useState(false);
    const templateType = useRef(1);

    const [bannerUrlList, setBannerUrlList] = useState([]);
    const [h5BannerUrlList, setH5BannerUrlList] = useState([]);
    const bannerBgColor = useRef(null);

    const [fileList, setFileList] = useState([]); // 分享图


    const checkImgUploadFile = (file, sizeMb) => {
        const typeList = ['image/jpg', 'image/jpeg', 'image/png'];
        const isImage = typeList.includes(file.type) || false;

        if (!isImage) {
            message.error('仅支持 JPG/PNG 图片格式');
            return false;
        }

        const metSize = file.size / 1024 / 1024 < sizeMb;
        if (!metSize) {
            message.error(`图片文件大小限${sizeMb}MB`);
            return false;
        }

        return isImage && metSize;
    };

    const uploadImageFile = (fileInfo, type) => {
        // type = 13 selection
        const formData = new FormData();
        formData.append('file', fileInfo.file);
        formData.append('type', 13);

        selectionUploadFile(formData)
            .then(async(res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }

                if (res.status_code === 200) {
                    const url = prepareBCOSSUrl(res.data);
                    if (type === 'banner') {
                        setBannerUrlList([url]);
                        form.setFieldValue('banner', res.data);
                        bannerBgColor.current = await getBannerColor(url);
                    } else if (type === 'h5Banner') {
                        setH5BannerUrlList([url]);
                        form.setFieldValue('h5Banner', res.data);
                    }
                }
            });
    };

    const onFinish = (values) => {
        if (values.openRule !== 1) {
            if (isEmpty(values.orgAuthRule) && isEmpty(values.personalAuthRule)) {
                form.setFields([
                    { name: "orgAuthRule", errors: ["职务认证或机构认证，满足认证要求可投稿"] }
                ]);
                return;
            }
        }

        let body = {
            ...values,
            startedAt: moment(values.projectsDate[0]).format('YYYY-MM-DD'),
            endedAt: moment(values.projectsDate[1]).format('YYYY-MM-DD'),
            openStartedAt: moment(values.contributeDate[0]).format('YYYY-MM-DD HH:mm:ss'),
            openEndedAt: moment(values.contributeDate[1]).format('YYYY-MM-DD HH:mm:ss'),
            caseFileShow: values.caseFileShow ? 1 : 0,
            caseBaseInfoShow: values.caseBaseInfoShow ? 1 : 0,
            zoneOn: values.zoneOn ? 1 : 0,
            regions: JSON.stringify(values.regions),
            topicVoteLimit: values.topicVoteLimit === 0 ? 0 : values.edit,
            dailyMultiVoteEnabled: values.dailyMultiVoteEnabled ? 1 : 0,
            bannerBgColor: bannerBgColor.current || '',
        }
        delete body.projectsDate;
        delete body.contributeDate;
        delete body.edit;

        if (values.openRule !== 1) {
            body.orgAuthRule = JSON.stringify(values.orgAuthRule);
            body.personalAuthRule = JSON.stringify(values.personalAuthRule);
        }

        console.log(body);

        if (id) {
            body.id = id;
            editProjestsApi(body)
                .then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        navigate(`/selection/projects`);
                    }

                });
        } else {
            addProjestsApi(body)
                .then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        navigate(`/selection/projects`);
                    }
                });
        }

    }

    const onReset = () => {
        navigate('/selection/projects')
    }

    const filterOption = (input, option) => {
        const { groupName } = option.props.data;
        return (
            groupName.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    }

    useEffect(() => {
        if (id) {

            getProjestsApi({ id })
                .then(async (res) => {
                if (res.status_code === 200) {
                    const { data } = res || {}
                    setSwitchList({
                        caseFileShow: data.caseFileShow,
                        zoneOn: data.zoneOn,
                        caseBaseInfoShow: data.caseBaseInfoShow
                    });
                    setOpenRule(data.openRule);
                    setTitleName(data.name);
                    setTopicCount(data.topicVoteLimit ? 1 : 0);
                    res.data.coverPath ? setCoverList([data.coverPath]) : setCoverList([]);
                    res.data.competitionCertificateUrl ? setCompetitionList([data.competitionCertificateUrl]) : setCompetitionList([]);
                    res.data.judgesAppointmentUrl ? setJudgesList([data.judgesAppointmentUrl]) : setJudgesList([]);
                    res.data.awardCertificateTemplate ? setCertifiList([data.awardCertificateTemplate]) : setCertifiList([]);
                    res.data.sharePic ? setFileList([data.sharePic]) : setFileList([]);

                    if (res.data.banner) {
                        setBannerUrlList([prepareBCOSSUrl(res.data.banner)]);
                    }
                    if (res.data.h5Banner) {
                        setH5BannerUrlList([prepareBCOSSUrl(res.data.h5Banner)]);
                    }
                    if (res.data.banner) {
                        const url = prepareBCOSSUrl(res.data.banner);
                        let bgColor = await getBannerColor(url);
                        bannerBgColor.current = bgColor;
                    }

                    form.setFieldsValue({
                        ...data,
                        projectsDate: [moment(data.startedAt), moment(data.endedAt)],
                        contributeDate: data.openStartedAt && data.openEndedAt ? [moment(data.openStartedAt), moment(data.openEndedAt)] : null,
                        regions: data.zoneOn ? JSON.parse(data?.regions || '[]') : [],
                        topicVoteLimit: data.topicVoteLimit ? 1 : 0,
                        edit: data?.topicVoteLimit ? data.topicVoteLimit : "",
                        voteWaitDuration: data?.voteWaitDuration ? data.voteWaitDuration : 0,
                        dailyVoteLimit: data?.dailyVoteLimit ? data.dailyVoteLimit : 1,
                        dailyMultiVoteEnabled: data?.dailyMultiVoteEnabled ? true : false,
                        orgAuthRule: data.openRule !== 1 ? JSON.parse(data?.orgAuthRule) : '',
                        personalAuthRule: data.openRule !== 1 ? JSON.parse(data?.personalAuthRule) : '',
                    });
                }
            });
        } else {
            form.setFieldsValue(switchList);
        }

        //获取评选项目组
        getProjestsGroupListApi().then((res) => {
            if (res && res.status_code === 200) {
                setProjectsGroupList(res.data)
            }
        })
    }, [])

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    const checkout = (file, size, initWidth, initHeight) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            setOneData(true);
            return null;
        } else {
            setOneData(false);
        }
        // 判断大小
        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            message.error("图像必须小于" + size + "MB!");
            setOneData(true);
            return null;
        } else {
            setOneData(false);
        }
        return isJpgOrPng && isLt2M;
    };


    const beforeCropCheck = (file, bool, size, type) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > size * 1024 * 1024) {
            message.error(`图片大小不能超过${size}MB。`);
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    if (type === 'bg') {
                        if (imgDom.width < 1754 || imgDom.height < 1240) {
                            bool && message.warn('证书背景不小于1754×1240px。');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    } else {
                        if (imgDom.width < 750 || imgDom.height < 422) {
                            bool && message.warn('图片大小不小于750×422px。');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };


    const swapItems = (arr, index1, index2) => {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0]
        return arr
    }

    // 上移
    const upData = (index) => {
        let arr = form.getFieldValue('regions');
        let newArr = [];
        if (arr.length > 1 && index !== 0) {
            newArr = [...swapItems(arr, index, index - 1)];
        }
        form.setFieldValue('regions', newArr);
    };

    // 下移
    const downData = (index) => {
        let arr = form.getFieldValue('regions');
        let newArr = [];
        if (arr.length > 1 && index !== (arr.length - 1)) {
            newArr = [...swapItems(arr, index, index + 1)];
        }
        form.setFieldValue('regions', newArr);
    };

    // 封面上传
    const coverImageFile = {
        name: "coverImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 13);
            if (!oneData) {
                selectionUploadFile(formData)
                    .then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setCoverList([getFileUrl(res.data)]);
                        form.setFieldValue("coverPath", getFileUrl(res.data));
                        form.setFields([{ name: "coverPath", errors: null }]);
                    }
                });
            } else {
                form.setFieldValue("coverPath", undefined);
            }
        },
    };

    // 参赛证书上传
    const competitionImageFile = {
        name: "competitionImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 13);
            if (!oneData) {
                selectionUploadFile(formData)
                    .then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setCompetitionList([getFileUrl(res.data)]);
                        form.setFieldValue("competitionCertificateUrl", getFileUrl(res.data));
                        form.setFields([{ name: "competitionCertificateUrl", errors: null }]);
                    }
                });
            } else {
                form.setFieldValue("competitionCertificateUrl", undefined);
            }
        },
    };


    // 评委证书上传
    const judgesImageFile = {
        name: "judgesImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 13);

            if (!oneData) {
                selectionUploadFile(formData)
                    .then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setJudgesList([getFileUrl(res.data)]);
                        form.setFieldValue("judgesAppointmentUrl", getFileUrl(res.data));
                        form.setFields([{ name: "judgesAppointmentUrl", errors: null }]);
                    }
                });
            } else {
                form.setFieldValue("judgesAppointmentUrl", undefined);
            }
        },
    };

    // 获奖证书上传
    const certifiFile = {
        name: "certifiFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 13);
            if (!oneData) {
                selectionUploadFile(formData)
                    .then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setCertifiList([getFileUrl(res.data)]);
                        form.setFieldValue("awardCertificateTemplate", getFileUrl(res.data));
                        form.setFields([{ name: "awardCertificateTemplate", errors: null }]);
                    }
                });
            } else {
                form.setFieldValue("awardCertificateTemplate", undefined);
            }
        },
    };

    // 查看示例证书
    const viewTemplateImg = (type) => {
        templateType.current = type;
        setIsShowTemplate(true);
    };

    // 查看示例证书
    const getViewImg = () => {
        const type = templateType.current;
        if (type === 1) {
            return competationImg;
        }
        if (type === 2) {
            return judgeImg;
        }
        if (type === 3) {
            return cerificate;
        }
    };

    // 关闭图片
    const closeImg = (type) => {
        if (type === 1) {
            setCompetitionList([]);
            form.setFieldValue('competitionCertificateUrl', '');
        } else if (type === 2) {
            setJudgesList([]);
            form.setFieldValue('judgesAppointmentUrl', '');
        } else {
            setCertifiList([]);
            form.setFieldValue('awardCertificateTemplate', '');
        }
    };

    const handleChangeSwitch = (bool, type) => {
        if (type === 'caseFileShow') {
            setSwitchList({
                ...switchList,
                caseFileShow: bool,
            })
        } else if (type === 'zoneOn') {
            if (!bool) form.setFieldValue('regions', []);
            setSwitchList({
                ...switchList,
                zoneOn: bool,
            });
        } else if (type === 'caseBaseInfoShow') {
            setSwitchList({
                ...switchList,
                caseBaseInfoShow: bool,
            });
        }

    }

    const handleChangeType = (e) => {
        setOpenRule(e.target.value);
        if (e.target.value && e.target.value === 1) {
            form.setFieldValue('authenticate', {});
        }
    }

    const handleChangeRadio = (e) => {
        setTopicCount(e.target.value);
    }

    const handleChangeAuth = () => {
        if (form.getFieldValue('openRule') !== 1) {
            if (isEmpty(form.getFieldValue('orgAuthRule')) && isEmpty(form.getFieldValue('personalAuthRule'))) {
                form.setFields([{ name: "orgAuthRule", errors: ["职务认证或机构认证，满足认证要求可投稿"] }])
            } else {
                form.setFields([{ name: "orgAuthRule", errors: null }])
            }
        }
    }

    // 分享图
    const shareImageFile = {
        name: "shareImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 13);
            if (!oneData) {
                selectionUploadFile(formData, option).then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setFileList([getFileUrl(res.data)]);
                        form.setFieldValue("sharePic", getFileUrl(res.data));
                        form.setFields([{ name: "sharePic", errors: null }]);
                    }
                });
            } else {
                form.setFieldValue("sharePic", undefined);
            }
        },
    };

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/selection/projects`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">{!id ? '新增评选项目' : '编辑评选项目'}</span>
                {/* {!!titleName && id && <span className="current-subtitle">{titleName}</span>} */}
            </div>

            <Content className="main-content-box">
                <div className="main-content-title">评选项目信息</div>

                <div className="main-content-body">
                    <Form
                        className="projects-form"
                        {...pageFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                        initialValues={{
                            voteWaitDuration: 0,
                            dailyVoteLimit: 1,
                            dailyMultiVoteEnabled: false
                        }}
                    >
                        <Form.Item
                            name="h3yunNo"
                            label="氚云编号"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="输入氚云编号" maxLength={100} allowClear />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="评选项目名称"
                            rules={[
                                { required: true, message: '请输入评选项目名称' },
                                { min: 2, message: '请输入评选项目名称，限2-50个字符' }
                            ]}
                        >
                            <Input placeholder="输入评选项目名称" maxLength={50} showCount allowClear />
                        </Form.Item>

                        <Form.Item
                            name="groupId"
                            label="所属评选项目组"
                            rules={[
                                { required: true, message: '请选择所属评选项目组' }
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder='请选择所属评选项目组'
                                filterOption={filterOption}
                            >
                                {projectsGroupList && projectsGroupList.map((item) => (
                                    <Select.Option key={item.id} value={item.id} data={item}>
                                        {item.groupName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="slug"
                            label="短网址"
                            rules={[
                                { required: true, message: '' },
                                { pattern: /^[a-z\d_\d-]{2,20}$/, message: '短网址不符合格式要求' }
                            ]}
                            help="评选项目短网址，如 chc-s8，小写字母、数字、连字符（-）或下划线（_），限2-20个字符"
                        >
                            <Input placeholder="请输入评选项目短网址" maxLength={20} showCount allowClear />
                        </Form.Item>

                        <Form.Item
                            name="projectsDate"
                            label="项目起止日期"
                            rules={[
                                { required: true, message: '请选择项目起止日期' }
                            ]}
                        >
                            <DatePicker.RangePicker />
                        </Form.Item>

                        <Form.Item
                            name="coverPath"
                            label="封面图"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="图片尺寸 750*422px，支持 JPG/PNG格式，大小不超过2MB"
                            className="share-image"
                        >
                            {coverList.length > 0 && <Image width={200} src={coverList[0]} className="share-image-area" />}

                            <div>
                                <ImgCrop
                                    aspect={16 / 9}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    quality={1}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true, 2);
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) =>
                                            checkout(file, 2, 300, 300)
                                        }
                                        fileList={coverList}
                                        {...coverImageFile}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {coverList.length > 0
                                                ? "更改图片"
                                                : "上传图片"}
                                        </Button>
                                    </Upload>
                                </ImgCrop>

                            </div>
                        </Form.Item>

                        <Form.Item
                            name="banner"
                            label="PC端项目主图"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 3840&times;900px（2倍图），文件大小不超过2MB"
                        >
                            {bannerUrlList && bannerUrlList.length > 0 && (
                                <div className="uploaded-image" style={{marginBottom: 8}}>
                                    <Image src={bannerUrlList[0]}/>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 2)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'banner')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {bannerUrlList && bannerUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="h5Banner"
                            label="移动端项目主图"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 750&times;450px（2倍图），文件大小不超1MB"
                        >
                            {h5BannerUrlList && h5BannerUrlList.length > 0 && (
                                <div className="uploaded-image" style={{marginBottom: 8}}>
                                    <Image src={h5BannerUrlList[0]}/>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 1)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'h5Banner')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {h5BannerUrlList && h5BannerUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="项目简介"
                            rules={[
                                { required: true, message: '请输入项目简介' },
                            ]}
                        >
                            <Input.TextArea rows={3} maxLength={200} showCount />
                        </Form.Item>

                        <Form.Item
                            name="zoneOn"
                            label="启用赛区"
                        >
                            <Switch checkedChildren="开" unCheckedChildren="关" checked={switchList.zoneOn} onChange={(e) => handleChangeSwitch(e, 'zoneOn')} />
                        </Form.Item>

                        {switchList.zoneOn && <Form.Item
                            label="划分赛区"
                            rules={[
                                { required: true, message: '请添加赛区' }
                            ]}
                            name="regions"
                        >
                            <Form.List
                                name="regions"
                                className='projects-race-form-list'
                            >
                                {(fields, { add, remove }) => (
                                    <Fragment>
                                        <Form.Item
                                            style={{
                                                marginBottom: '10px'
                                            }}
                                        >
                                            <Button type="link" size="small" disabled={fields?.length >= 20} onClick={() => add()}>
                                                <PlusCircleOutlined />添加赛区
                                            </Button>
                                        </Form.Item>
                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <Space key={key} align='start' className='projects-race-form-list-space'>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'name']}
                                                    rules={[
                                                        { required: true, message: '请输入赛区名称' }
                                                    ]}
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <Input placeholder="赛区名称" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'desc']}
                                                    rules={[
                                                        { required: true, message: '请输入赛区说明' }
                                                    ]}
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <Input placeholder="赛区说明" className='raceDesc-input' />
                                                </Form.Item>
                                                {index !== 0 && <div className='projects-icon-main'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle icon-pointer-projects" viewBox="0 0 16 16" onClick={() => upData(index)}>
                                                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                    </svg>
                                                    <div className='icon-title-projects' onClick={() => upData(index)}>上移</div>
                                                </div>}


                                                {index !== fields.length - 1 &&
                                                    <div className='projects-icon-main'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle icon-pointer-projects" viewBox="0 0 16 16" onClick={() => downData(index)}>
                                                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                        </svg><div className='icon-title-projects' onClick={() => downData(index)}>下移</div>
                                                    </div>
                                                }
                                                <div className='projects-icon-main'>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle icon-pointer-projects" viewBox="0 0 16 16" onClick={() => remove(name)}>
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg><div className='icon-title-projects' onClick={() => remove(index)}>移除</div>
                                                </div>

                                            </Space>
                                        ))}
                                    </Fragment>
                                )}
                            </Form.List>
                        </Form.Item>}


                        <Form.Item
                            name="contributeDate"
                            label="开放投稿时间"
                            rules={[
                                { required: true, message: '请选择开放投稿时间' }
                            ]}
                        >
                            <DatePicker.RangePicker showTime />
                        </Form.Item>

                        <Form.Item
                            name="openRule"
                            label="案例投稿限制"
                            help="默认登录即可投稿，可以选择限制认证方可投稿，“需要登录并提交认证信息”用于既要求认证又无须用户等待认证审核时间差的场景"
                            rules={[
                                { required: true, message: '' },
                            ]}
                        >
                            <Radio.Group value={openRule} onChange={handleChangeType}>
                                <Radio value={1}>需要登录</Radio>
                                <Radio value={2}>需要登录并完成认证</Radio>
                                <Radio value={3}>需要登录并提交认证信息</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {openRule && openRule !== 1 && <Form.Item
                            label="认证类型要求"
                            className='required'
                            // rules={[{ required: true }]}
                            help="职务认证或机构认证，满足认证要求可投稿"
                        >
                            <div className="radio-list">
                                <Form.Item
                                    name="personalAuthRule"
                                    // rules={[{ required: true }]}
                                    noStyle
                                >
                                    <Checkbox.Group onChange={handleChangeAuth}>
                                        <div className="field-group">
                                            <span className="field-title">职务认证:</span>
                                            <Checkbox value={'A01'}>医疗机构从业者</Checkbox>
                                            <Checkbox value={'A06'}>社会团队人士</Checkbox>
                                            <Checkbox value={'A02'}>政府官员</Checkbox>
                                            <Checkbox value={'A04'}>教育科研人士</Checkbox>
                                            <Checkbox value={'A03'}>商业人士</Checkbox>
                                        </div>
                                    </Checkbox.Group>
                                </Form.Item>
                                <Form.Item
                                    name="orgAuthRule"
                                    noStyle
                                // rules={[{ required: true }]}
                                >
                                    <Checkbox.Group onChange={handleChangeAuth}>
                                        <div className="field-group">
                                            <span className="field-title">机构认证:</span>
                                            <Checkbox value={'2'}>医疗机构</Checkbox>
                                            <Checkbox value={'1'}>媒体</Checkbox>
                                            <Checkbox value={'0'}>企业</Checkbox>
                                            <Checkbox value={'3'}>机构</Checkbox>
                                        </div>
                                    </Checkbox.Group>
                                </Form.Item>
                            </div>

                        </Form.Item>}

                        <Form.Item
                            name="caseBaseInfoShow"
                            label="案例基础信息展示"
                            extra="开启基础信息展示后，在案例详情页面，可查看该案例基础信息"
                        >
                            <Switch checkedChildren="开" unCheckedChildren="关" checked={switchList.caseBaseInfoShow} onChange={(e) => handleChangeSwitch(e, 'caseBaseInfoShow')} />
                        </Form.Item>

                        <Form.Item
                            name="caseFileShow"
                            label="开启案例文件展示"
                            extra="开启案例文件展示后，评选项目官网展示方案文件，用户可查看案例文档、案例视频和案例图片"
                        >
                            <Switch checkedChildren="开" unCheckedChildren="关" checked={switchList.caseFileShow} onChange={(e) => handleChangeSwitch(e, 'caseFileShow')} />
                        </Form.Item>

                        <Form.Item
                            className='required'
                            label="投票按钮等待时长"
                            help="默认0秒，不用等待即可点击投票按钮，最小0秒，最大30秒"
                        >
                            <Space>
                                <Form.Item
                                    name="voteWaitDuration"
                                    label="投票按钮等待时长"
                                    noStyle
                                    rules={[
                                        { required: true, pattern: /^30$|^([1-2]\d)$|^\d?$/ },
                                    ]}
                                >
                                    <Input style={{ width: 60 }} />
                                </Form.Item>
                                <span>秒</span>
                            </Space>
                        </Form.Item >


                        <Form.Item
                            className='required'
                            label="每人每天可投票"
                        >
                            <Space>
                                <Form.Item
                                    name="dailyVoteLimit"
                                    noStyle
                                    rules={
                                        [
                                            { required: true, message: "请输入每人每天可投票" },
                                            { pattern: /^\+?[1-9]\d*$/, message: '请输入大于0的正整数' }
                                        ]
                                    }
                                >

                                    <Input style={{ width: 60 }} />
                                </Form.Item>
                                <span>次</span>
                            </Space>
                        </Form.Item >

                        <Form.Item
                            name="dailyMultiVoteEnabled"
                            label="同一案例可重复投票"
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="开" unCheckedChildren="关" />
                        </Form.Item>


                        <Form.Item label="同主题可投票案例数" name="topicVoteLimit" rules={[{ required: true, message: "请选择同主题可投票案例数" }]}>
                            <Radio.Group value={topicCount} onChange={handleChangeRadio}>
                                <Radio value={0}>不限数量</Radio>
                                <Radio value={1}>
                                    <span>
                                        <span>限&nbsp;</span>
                                        <Form.Item name='edit' noStyle rules={[{ required: topicCount == 1, message: "请输入同主题可投票案例数" }, { pattern: /^\+?[1-9]\d*$/, message: '请输入大于0的正整数' }]}>
                                            <Input style={{ width: 60 }} />
                                        </Form.Item>
                                        <span>&nbsp;个</span>
                                    </span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="competitionCertificateUrl"
                            label="参赛证书"
                            help="图片尺寸 1754*1240px，支持 JPG/PNG格式，大小不超过5MB"
                            className="share-image"
                        >
                            {competitionList.length > 0 && <><Image width={200} src={competitionList[0]} className="share-image-area" /><span onClick={() => closeImg(1)} className="close-img"><CloseOutlined /></span></>}
                            <div>
                                <ImgCrop
                                    aspect={1754 / 1240}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    quality={1}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true, 5, 'bg');
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) =>
                                            checkout(file, 5, 300, 300)
                                        }
                                        fileList={judgesList}
                                        {...competitionImageFile}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {competitionList.length > 0
                                                ? "更改背景"
                                                : "上传背景"}
                                        </Button>
                                    </Upload>
                                </ImgCrop>
                                <Button onClick={() => viewTemplateImg(1)} type="link" style={{ marginLeft: '30px' }}>示例模板</Button>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="judgesAppointmentUrl"
                            label="评委证书"
                            help="图片尺寸 1754*1240px，支持 JPG/PNG格式，大小不超过5MB"
                            className="share-image"
                        >
                            {judgesList.length > 0 && <><Image width={200} src={judgesList[0]} className="share-image-area" /><span onClick={() => closeImg(2)} className="close-img"><CloseOutlined /></span></>}
                            <div>
                                <ImgCrop
                                    aspect={1754 / 1240}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    quality={1}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true, 5, 'bg');
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) =>
                                            checkout(file, 5, 300, 300)
                                        }
                                        fileList={judgesList}
                                        {...judgesImageFile}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {judgesList.length > 0
                                                ? "更改背景"
                                                : "上传背景"}
                                        </Button>
                                    </Upload>
                                </ImgCrop>
                                <Button onClick={() => viewTemplateImg(2)} type="link" style={{ marginLeft: '30px' }}>示例模板</Button>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="awardCertificateTemplate"
                            label="完赛证书"
                            help="请按A3大小（420*297毫米）设计背景，支持 JPG/PNG格式，大小不超过10MB"
                            className="share-image"
                        >
                            {certifiList.length > 0 && <><Image width={200} src={certifiList[0]} className="share-image-area" /><span onClick={() => closeImg(3)} className="close-img"><CloseOutlined /></span></>}
                            <div>
                                <Upload
                                    beforeUpload={(file) =>
                                        checkout(file, 10, 300, 300)
                                    }
                                    fileList={certifiList}
                                    {...certifiFile}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {certifiList.length > 0
                                            ? "更改背景"
                                            : "上传背景"}
                                    </Button>
                                </Upload>
                                <Button onClick={() => viewTemplateImg(3)} type="link" style={{ marginLeft: '30px' }}>示例模板</Button>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="shareTitle"
                            label="微信分享标题"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input placeholder="输入分享标题" maxLength={30} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="shareDesc"
                            label="微信分享描述"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input placeholder="输入分享描述" maxLength={30} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="sharePic"
                            label="微信分享图"
                            rules={[
                                { required: true, message: '' }
                            ]}
                            help="支持JPG/PNG格式，尺寸300&times;300px，大小不超过2M"
                            className="share-image"
                        >
                            {fileList.length > 0 && <Image width={200} src={fileList[0]} className="share-image-area"/>}

                            <div>
                                <Upload
                                    beforeUpload={(file) => checkout(file, 2, 300, 300)}
                                    fileList={fileList}
                                    {...shareImageFile}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {fileList.length > 0 ? '更改图片' : '上传图片'}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>

                        <Form.Item
                            {...pageFormItemWithoutLabelGridLayout}
                            style={{ marginTop: 40, marginBottom: 0 }}
                        >
                            <Space>
                                <Button type="primary" size="large" htmlType="submit">提交</Button>
                                <Button type="default" size="large" onClick={onReset}>取消</Button>
                            </Space>
                        </Form.Item>

                    </Form>
                </div>

                <Modal
                    title="示例模板"
                    centered
                    open={isShowTemplate}
                    width={980}
                    footer={null}
                    onCancel={() => setIsShowTemplate(false)}
                    bodyStyle={{ padding: 0 }}

                >
                    <div>
                        <Image
                            width={980}
                            height={695}
                            src={getViewImg()}
                        />
                    </div>
                </Modal>

            </Content>
        </Fragment>
    );
}

export default Project;
