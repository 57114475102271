import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    Input,
    Layout,
    Space,
    Tooltip,
    DatePicker,
    Table,
    Divider,
    Typography,
    Dropdown,
    Modal,
    message,
    Row,
    Col
} from 'antd';
import {
    CopyOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    ReloadOutlined,
    SearchOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { medProjectActions } from '../store/medProjectSlice';
import { fetchMedProjectList, deleteMedProject } from '@services/LegacyMedProject.service';
import { convertDateTimeToLocalText, numberProject } from '@utils/utils';

const { Content } = Layout;
const { Text } = Typography;

const { setProjectFilter: setFilter, setProjectCursor: setCursor } = medProjectActions;

export default function LegacyMedProjectList() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.medProject.project.filter);
    const cursor = useSelector(state => state.medProject.project.cursor);

    const [form] = Form.useForm();

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        let param = values.param;
        if (!!param && /^\s*[a-z]+\d+\s*$/i.test(param)) {
            param = param.replace(/^\s*[a-z]+0+([1-9]\d*)\s*$/i, '$1');
        }

        dispatch(setFilter({
            ...filter,
            param, // 目前只有一个筛选项
            page: 1,
        }));
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const onRefresh = () => getMedProjects();

    const onDeleteMedProject = project => {
        Modal.confirm({
            title: '删除医项目',
            icon: <ExclamationCircleOutlined />,
            content: `确定删除该项目？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await deleteMedProject(project.id);
                if (res.status_code === 200) {
                    message.success('删除项目成功');
                    getMedProjects();
                }
            }
        });
    };

    const copyProjectNo = projectNo => {
        copy(projectNo);
        message.success('已复制到剪贴板');
    };

    const columns = [
        {
            title: '项目编号',
            dataIndex: 'id',
            width: 100,
            render: (_, record) => {
                const projectNo = numberProject('YXM', record.id);
                return <span className="conferenctlist-copy-column-span">
                    {projectNo}
                    <span onClick={() => copyProjectNo(projectNo)}><CopyOutlined /></span>
                </span>;
            }
        },
        { title: '项目名称', dataIndex: 'name' },
        {
            title: '创建人',
            dataIndex: 'createdBy',
            width: 240,
            render: (_, record) => <>
                <span>{record?.createdByName}</span><br/>
                <Text type="secondary">{convertDateTimeToLocalText(record?.createdAt)}</Text>
            </>
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 240,
            render: (_, record) => {
                const items = [
                    {
                        label: "删除医项目",
                        key: "delete",
                        onClick: () => onDeleteMedProject(record),
                        danger: true,
                    },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => toProjects(record.id)}
                        >
                            项目管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => toEdit(record.id)}
                        >
                            编辑医项目
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => toRelateProjects(record.id)}
                        >
                            关联子项目
                        </Button>
                        <Divider type="vertical" />
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </span>
                );
            }
        },
    ];

    const getMedProjects = async () => {
        let params = { ...filter };

        try {
            setLoading(true);

            let res = await fetchMedProjectList(params);
            if (res.status_code === 200) {
                setTotal(res.data.totalCount);
                setTableData(res.data.list);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const toCreate = () => navigate('/medproject/projects/new');

    const toEdit = id => {
        dispatch(setCursor(id));
        navigate(`/medproject/projects/${id}`);
    };

    const toRelateProjects = id => {
        dispatch(setCursor(id));
        navigate(`/medproject/projects/${id}/related-projects`);
    };

    // 项目管理
    const toProjects = id => {
        navigate(`/medproject/projects/${id}/modules/${1}`);
    };

    useEffect(() => {
        form.setFieldsValue(filter);

        getMedProjects();
    }, [filter]);

    return (
        <>
            <div className="page-title">
                <span className="current-title">医项目管理</span>
            </div>

            <Content className="main-content-box">

                <div className="filter-form-box">

                    <Form
                        {...filterFormItemLayout}
                        onFinish={onFinish}
                        form={form}
                        autoComplete="off"
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="项目编号" name="param">
                                    <Input allowClear placeholder="项目编号或项目名称关键字" />
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={() => toCreate()}>新增医项目</Button>
                        </div>
                        <div className="sub-tools">
                            <Tooltip title="刷新列表">
                                <Button type="default" icon={<ReloadOutlined />} onClick={onRefresh}/>
                            </Tooltip>
                        </div>

                    </div>

                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />

                </div>
            </Content>
        </>
    );
}
