import React from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Space } from 'antd';

export default function LiveWhitelistFilterForm({
    handleFilterFinish
}) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            phone: undefined,
        };
        handleFilterFinish(values);
    };
    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="姓名" name="name">
                        <Input allowClear placeholder="请输入直播标题" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="请输入直播标题" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
