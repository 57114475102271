import React, { Fragment, useEffect } from 'react';
import { drawerFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Form, Input, Select, Space, Button, TreeSelect } from 'antd';
import { get } from '@/core/request';

export default function AddDepartmentList({
    setIsModalVisible, handleAddDepartment, modalData, handleEditDepartment, oldDepartmentTree
}) {

    const { row, tip } = modalData;
    const [form] = Form.useForm();
    useEffect(() => {
        if (tip === 'edit' || tip === 'addChild') {
            // 请求
            get('/enterprise/departments/get', { id: row.id }).then(res => {
                if (res.status_code === 200) {
                    if (tip === 'edit') {
                        form.setFieldsValue({
                            name: res.data.name,
                            pid: res.data.pid === 0 ? undefined : res.data.pid
                        })
                    } else {
                        form.setFieldsValue({
                            pid: res.data.id
                        })
                    }

                }
            })
        } else {
            form.setFieldsValue({
                pid: 1
            })
        }
    }, [tip]);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const onFinish = (value) => {
        value.pid = value.pid === undefined ? 1 : value.pid;
        if (row?.name === '集团') {
            value.pid = 0;
        }
        value.weight = 0;
        if (tip === 'add' || tip === 'addChild') {
            handleAddDepartment(value);
        } else {
            value.id = row.id;
            handleEditDepartment(value);
        }
        setIsModalVisible(false);
    };

    return (
        <Fragment>
            <div className="main-content-body">
                <h2 style={{ marginBottom: '20px' }}>{tip === 'add' ? '新增部门' : '编辑部门'}</h2>
                <Form {...drawerFormItemLayout} form={form} onFinish={onFinish}>

                    <Form.Item
                        label="部门名称"
                        name="name"
                        rules={[{ required: true, message: '输入部门名称' }]}
                    >
                        <Input placeholder="输入部门名称" allowClear autoComplete="off" showCount maxLength={20} />
                    </Form.Item>

                    <Form.Item label="上级部门" name="pid">
                        {oldDepartmentTree?.length > 0 && <TreeSelect
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={oldDepartmentTree}
                            placeholder="选择上级部门"
                            disabled={tip === 'addChild' && true}
                        />}
                    </Form.Item>

                    <Form.Item {...pageFormItemWithoutLabelGridLayout} style={{ marginTop: '50px' }}>
                        <Space>
                            <Button className="submit-button" type="primary" htmlType="submit">保存</Button>
                            <Button type="primary" onClick={handleClose}>取消</Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>

        </Fragment>
    );
}
