import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

export default function PublicLayout({component: Component, ...rest}) {
    return (
        <Fragment>
            <h1>PublicLayout</h1>

            <Outlet/>
        </Fragment>
    );
}
