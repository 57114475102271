import { Button, Form, Input, Popconfirm, Table, message, Modal } from "antd";
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { PlusSquareTwoTone } from "@ant-design/icons";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import MatrixEditItem from "../MatrixEditItem";
import MatrixEditRow from "../MatrixEditRow";
import addonce from "@static/images/add-once.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.less";
import { timesaveSurveryQuestionApi } from "@services/Wj/Question";
import { timesaveTemplateQuestionApi } from "@services/Wj/Template";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditTable = ({
    colValue,
    setColValue,
    rowValue,
    setRowValue,
    arrForm,
    defaultValue,
    anserArea,
    setAnserArea,
    qData,
    onReset = { onReset },
}) => {
    const location =useLocation()
    const quillRef = useRef(null);
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                    ],
                ],
            },
        };
    }, []);

    const [tableType, setTableType] = useState(undefined);
    const [addOpen, setAddOpen] = useState(false);
    const [dataSource, setDataSource] = useState(undefined);
    const [count, setCount] = useState(2);
    const rowDel = (i) => {
        let res = Object.values(arrForm.getFieldValue("rowName"));
        if (res.length > 1) {
            res.splice(i, 1);
            arrForm.setFieldValue("rowName", {});
            let delRow = res.map((item, index) => {
                arrForm.setFieldValue(["rowName", `row-${index}`], item);
                return {
                    key: index,
                    question: item,
                };
            });
            setDataSource([...delRow]);
            let cols = Object.values(arrForm.getFieldValue("colName"));
            let rows = Object.values(arrForm.getFieldValue("rowName"));
            qData.options[0].content = JSON.stringify([[...cols], [...rows]]);
            let obj = { ...qData };
            let templateId = qData.surveyId;

            if (location.state.route === "newCreate") {
                timesaveTemplateQuestionApi({ ...obj, templateId ,isTrueModify:true}).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
            } else if (
                location.state.route === "newList" ||
                location.state?.route === "newTemplate"
            ) {
                timesaveSurveryQuestionApi({ ...obj, templateId ,isTrueModify:true}).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                    }
                });
            }
        } else {
            message.error("至少保留1行");
        }
    };

    const handleDelete = (i) => {
        let res = Object.values(arrForm.getFieldValue("colName"));
        if (res.length > 1) {
            res.splice(i, 1);
            arrForm.setFieldValue("colName", {});
            let delCol = res.map((item, index) => {
                arrForm.setFieldValue(["colName", `col-${index}`], item);
                return {
                    title: (
                        <MatrixEditItem
                            item={item}
                            id={`col-${index}`}
                            colValue={colValue}
                            setColValue={setColValue}
                            arrForm={arrForm}
                            handleDelete={handleDelete}
                            anserArea={anserArea}
                            setAnserArea={setAnserArea}
                            qData={qData}
                            onReset={onReset}
                        />
                    ),
                    width: "150px",
                };
            });
            delCol.unshift({
                title: "",
                width: 150,
                fixed: "left",
                render: (_, record) => {
                    return (
                        <MatrixEditRow
                            item={record.question}
                            id={`row-${record.key}`}
                            rowValue={rowValue}
                            setRowValue={setRowValue}
                            arrForm={arrForm}
                            rowDelete={rowDel}
                            anserArea={anserArea}
                            setAnserArea={setAnserArea}
                            qData={qData}
                            onReset={onReset}
                        />
                    );
                },
            });
            setCol([...delCol]);
            let cols = Object.values(arrForm.getFieldValue("colName"));
            let rows = Object.values(arrForm.getFieldValue("rowName"));
            qData.options[0].content = JSON.stringify([[...cols], [...rows]]);
            let obj = { ...qData };
            let templateId = qData.surveyId;
            // timesaveSurveryQuestionApi({ ...obj, templateId ,isTrueModify:true}).then((res) => {
            //     if (res.status_code === 200) {
            //         message.success("保存成功");
            //     }
            // });
            if (location.state.route === "newCreate") {
                timesaveTemplateQuestionApi({ ...obj, templateId ,isTrueModify:true}).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
            } else if (
                location.state.route === "newList" ||
                location.state?.route === "newTemplate"
            ) {
                timesaveSurveryQuestionApi({ ...obj, templateId ,isTrueModify:true}).then(((res)=>{if(res.status_code===200){
                    message.success('保存成功')
                }}))
            }
        } else {
            message.error("至少保留1列");
        }
    };
    const [col, setCol] = useState(undefined);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    useEffect(() => {
        setCol([
            {
                title: "",
                width: 150,
                fixed: "left",
                render: (_, record) => {
                    return (
                        <MatrixEditRow
                            item={record.question}
                            id={`row-${record.key}`}
                            rowValue={rowValue}
                            setRowValue={setRowValue}
                            arrForm={arrForm}
                            rowDelete={rowDel}
                            anserArea={anserArea}
                            setAnserArea={setAnserArea}
                            qData={qData}
                            onReset={onReset}
                        />
                    );
                },
            },
            {
                title: (
                    <MatrixEditItem
                        id={`col-0`}
                        colValue={colValue}
                        setColValue={setColValue}
                        handleDelete={handleDelete}
                        arrForm={arrForm}
                        anserArea={anserArea}
                        setAnserArea={setAnserArea}
                        qData={qData}
                        onReset={onReset}
                    />
                ),
                width: "150px",
            },
            {
                title: (
                    <MatrixEditItem
                        id={`col-1`}
                        colValue={colValue}
                        setColValue={setColValue}
                        handleDelete={handleDelete}
                        arrForm={arrForm}
                        anserArea={anserArea}
                        setAnserArea={setAnserArea}
                        qData={qData}
                        onReset={onReset}
                    />
                ),
                width: "150px",
            },
        ]);
        setDataSource([
            {
                key: "0",
            },
            {
                key: "1",
            },
        ]);
    }, []);
    const handleAdd = (type) => {
        if (type === "col") {
            if (col.length >= 21) {
                message.info("最多不能超过20列");
                setAddOpen(false);
            } else {
                setAddOpen(true);
            }
        } else if (type === "row") {
            if (dataSource.length >= 20) {
                message.info("最多不能超过20行");
                setAddOpen(false);
            } else {
                setAddOpen(true);
            }
        }

        setTableType(type);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };

    const components = {
        body: {
            //   row: EditableRow,
            //   cell: EditableCell,
        },
    };
    const columns =
        col &&
        col.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                }),
            };
        });

    const onOk = () => {
        let colBool = false,
            rowBool = false;
        if (tableType === "row") {
            let resOld = [...dataSource];
            let str = form.getFieldsValue().opinion + "";
            if (!str) {
                message.info("未输入内容");
                return;
            } else if (form.getFieldsValue().opinion === undefined) {
                message.info("未输入内容");
                return;
            }
            let arr = str.split("\n");
            let bool = true,
                index = 0,
                newArr = [];
            arr.forEach((item, ind) => {
                if (!bool) return;
                if (!item) return;
                newArr.push(item);
            });
            let filArr = arr.filter((it) => it !== "");
            if (filArr.length > 19) {
                message.info("最多不能超过20行");
                return;
            } else if (filArr.length + dataSource.length > 20) {
                message.info("最多不能超过20行");
                return;
            }

            filArr.map((it) => {
                if (it.length >= 31) {
                    rowBool = true;
                    return;
                }
            });
            if (tableType === "col" && colBool) {
                message.info("每行不能超过30字");
                 // setAddOpen(false);
                 colBool = false;
                 // form.resetFields();
                return;
            } else if (tableType === "row" && rowBool) {
                message.info("每行不能超过30字");
                // setAddOpen(false);
                // form.resetFields();
                rowBool = false;
                return;
            } else {
                let final = filArr.map((item, index) => {
                    arrForm.setFieldValue(
                        ["rowName", `row-${resOld.length + index}`],
                        item
                    );
                    return {
                        key: resOld.length + index,
                        question: item,
                    };
                });
                let resArr = resOld.concat(final);
                let cols = Object.values(arrForm.getFieldValue("colName"));
                let rows = Object.values(arrForm.getFieldValue("rowName"));
                qData.options[0].content = JSON.stringify([
                    [...cols],
                    [...rows],
                ]);
                let obj = { ...qData };
                let templateId = qData.surveyId;

                setDataSource([...resArr]);
                if (location.state.route === "newCreate") {
                    timesaveTemplateQuestionApi({ ...obj, templateId ,isTrueModify:true}).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
                } else if (
                    location.state.route === "newList" ||
                    location.state?.route === "newTemplate"
                ) {
                    timesaveSurveryQuestionApi({ ...obj, templateId,isTrueModify:true }).then(
                        (res) => {
                            if (res.status_code === 200) {
                                message.success("保存成功");
                                onReset();
                            }
                        }
                    );
                }

                setAddOpen(false);
                form.resetFields();
            }

            // saveOpinion(resArr);
        } else if (tableType === "col") {
            console.log(col);
            let resOld = [...col];
            let str = form.getFieldsValue().opinion + "";
            if (!str) {
                message.info("未输入内容");
                return;
            } else if (form.getFieldsValue().opinion === undefined) {
                message.info("未输入内容");
                return;
            }
            let arr = str.split("\n");
            let bool = true,
                index = 0,
                newArr = [];
            arr.forEach((item, ind) => {
                if (!bool) return;
                if (!item) return;
                newArr.push(item);
            });
            let filArr = arr.filter((it) => it !== "");
            if (filArr.length > 19) {
                message.info("最多不能超过20列");
                return;
            } else if (filArr.length + col.length > 21) {
                message.info("最多不能超过20列");
                return;
            }
            filArr.map((it) => {
                if (it.length >= 31) {
                    colBool = true;
                    return;
                }
            });
            if (tableType === "col" && colBool) {
                message.info("每个标题最多30个字");
                // setAddOpen(false);
                colBool = false;
                // form.resetFields();
                return;
            } else if (tableType === "row" && rowBool) {
                message.info("每个标题最多30个字");
                // setAddOpen(false);
                rowBool = false;
                // form.resetFields();
                return;
            } else {
                let final = filArr.map((item, index) => {
                    console.log(resOld.length);
                    arrForm.setFieldValue(
                        ["colName", `col-${resOld.length + index-1}`],
                        item
                    );
                    return {
                        title: (
                            <MatrixEditItem
                                item={item}
                                id={`col-${resOld.length + index-1}`}
                                colValue={colValue}
                                setColValue={setColValue}
                                handleDelete={handleDelete}
                                arrForm={arrForm}
                                anserArea={anserArea}
                                setAnserArea={setAnserArea}
                                qData={qData}
                                onReset={onReset}
                            />
                        ),
                        width: "150px",
                    };
                });
                let resArr = resOld.concat(final);
                let cols = Object.values(arrForm.getFieldValue("colName"));
                let rows = Object.values(arrForm.getFieldValue("rowName"));
                qData.options[0].content = JSON.stringify([
                    [...cols],
                    [...rows],
                ]);
                let obj = { ...qData };
                let templateId = qData.surveyId;

                setCol([...resArr]);

                if (location.state.route === "newCreate") {
                    timesaveTemplateQuestionApi({ ...obj, templateId ,isTrueModify:true}).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
                } else if (
                    location.state.route === "newList" ||
                    location.state?.route === "newTemplate"
                ) {
                    timesaveSurveryQuestionApi({ ...obj, templateId,isTrueModify:true }).then(
                        (res) => {
                            if (res.status_code === 200) {
                                message.success("保存成功");
                                onReset();
                            }
                        }
                    );
                }


                setAddOpen(false);
                form.resetFields();
            }

            // saveOpinion(resArr);
        }
    };

    const onCancel = () => {
        setAddOpen(false);
        form.resetFields();
    };
    useEffect(() => {
        if (defaultValue) {
            let colRow = JSON.parse(defaultValue.content);
            let cols =
                colRow[0] &&
                colRow[0].map((item, index) => {
                    arrForm.setFieldValue(["colName", `col-${index}`], item);
                    return {
                        title: (
                            <MatrixEditItem
                                item={item}
                                id={`col-${index}`}
                                colValue={colValue}
                                setColValue={setColValue}
                                handleDelete={handleDelete}
                                arrForm={arrForm}
                                anserArea={anserArea}
                                setAnserArea={setAnserArea}
                                qData={qData}
                                onReset={onReset}
                            />
                        ),
                        width: "150px",
                    };
                });
            let rows =
                colRow[1] &&
                colRow[1].map((item, index) => {
                    arrForm.setFieldValue(["rowName", `row-${index}`], item);
                    return {
                        key: index,
                        question: item,
                    };
                });
            cols &&
                cols.unshift({
                    title: "",
                    width: 150,
                    fixed: "left",
                    render: (_, record) => {
                        return (
                            <MatrixEditRow
                                item={record.question}
                                id={`row-${record.key}`}
                                rowValue={rowValue}
                                setRowValue={setRowValue}
                                arrForm={arrForm}
                                rowDelete={rowDel}
                                anserArea={anserArea}
                                setAnserArea={setAnserArea}
                                qData={qData}
                                onReset={onReset}
                            />
                        );
                    },
                });
            rows && setDataSource([...rows]);
            cols && setCol([...cols]);
        }
    }, [defaultValue]);

    return (
        <div>
            <div className="matrix_area">
                <Table
                    className="table_area"
                    components={components}
                    rowClassName={() => "editable-row"}
                    scroll={{
                        x: 200,
                        y: 300,
                    }}
                    bordered
                    dataSource={dataSource}
                    pagination={false}
                    columns={columns}
                />
               <Button
                    onClick={() => handleAdd("col")}
                    type="link"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    <PlusSquareTwoTone />
                    添加列
                </Button>
            </div>
            <Button
                onClick={() => handleAdd("row")}
                type="link"
                style={{
                    marginBottom: 16,
                }}
            >
                <PlusSquareTwoTone /> 添加行
            </Button>

            <Modal
                title={"添加"}
                open={addOpen}
                onCancel={onCancel}
                onOk={onOk}
            >
                <Form form={form} className="add_other">
                    <p>每行代表一个选项，点击回车键换行</p>

                    {tableType && tableType === "col" ? (
                        <span>每个标题最多30个字，表格最多支持20列</span>
                    ) : (
                        <span>每个标题最多30个字，表格最多支持20行</span>
                    )}
                    <Form.Item name="opinion">
                        <TextArea
                            className="vertical-scrollbar"
                            autoSize={{
                                minRows: 6,
                                maxRows: 6,
                            }}
                        ></TextArea>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default EditTable;
