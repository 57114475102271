import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const DataOverview = ({data}) => {
    console.log(data);
    let dataChart;
    function echartsFunction() {
        dataChart && dataChart.dispose();
        dataChart = echarts.init(document.getElementById('data-overview'));
        const option = {
            tooltip: {
                trigger: 'item',
                // axisPointer: { type: 'cross' },
                axisPointer: {//hover的时候没有虚线
                    type: 'none'
                }
            },
            grid: {
                left: '5%',
                right: '10%',
                bottom: '3%',
                top:'12%',
                containLabel: true,
            },
            xAxis: {
                name: '日期',
                type: 'category',
                data: data.xData,
                axisLine: {
                    lineStyle: {
                        type: 'solid',
                        // ...
                    },
                },
                axisLabel: {
                    rotate: 30,
                },
            },
            yAxis: {
                name: '人数',
                type: 'value',
                axisLine: {
                    lineStyle: {
                        type: 'solid',
                    },
                },
            },

            series: [
                {
                    data: data.yData,
                    type: 'line',
                    barWidth: '30%',
                    smooth: true,
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}人',
                    },
                    lineStyle:{
                        color:'#1677ff',
                    }
                },
            ],
        };

        dataChart.setOption(option);
        window.addEventListener('resize', function () {
            dataChart.resize();
        });
    }

    useEffect(() => {
        setTimeout(() => {
            echartsFunction();
        }, 20);
    }, [echartsFunction]);
    return <div className='data-overview' id='data-overview'></div>;
};

export default DataOverview;
