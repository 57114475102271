import { get, post } from '@core/request';

/*
    接口服务--用户中心组件
*/

// 获取用户咨询分页列表
export const getConsultationsListApi = (params) => {
    return get('/user/userconsultations/page', params);
};
// 获取用户咨询详情
export const getConsultationsInfoApi = (params) => {
    return get('/user/userconsultations/info', params);
};
// 获取用户跟进记录列表
export const getConsultationFollowsApi = (params) => {
    return get('/user/userconsultationfollows/list', params);
};
// 新增用户跟进记录
export const saveConsultationFollowsApi = (params) => {
    return post('/user/userconsultationfollows/save', params);
};
