import React, { useState, useEffect } from 'react';
import { Button, Layout, message, Space, Table } from 'antd';
import moment from 'moment';
import {caseTeammembersListApi,caseTeammembersDownloadApi} from '@services/Selection';
import { useParams } from 'react-router-dom';
import "./index.less"

const JudgeList = () => {
    const params = useParams();
    const { Content } = Layout;
    const { id } = params || 0;

    // 列表数据
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getCaseTeammembersList = () => {
        setLoading(true);
        caseTeammembersListApi({ stageId: id }).then((res) => {
            setLoading(false);
            if (res && res.status_code === 200 && res.data) {
                setData(res.data);
            }
        });
    }

    const columns = [
        {
            title: '手机号', dataIndex: 'mobile', width: 120, fixed: 'left',
        },
        {
            title: '姓名', dataIndex: 'username', width: 120, fixed: 'left',
        },
        {
            title: '赛段', dataIndex: 'stageName', width: 100,
        },
        {
            title: '负责任务', dataIndex: 'memTask', width: 100,
        },
        {
            title: '参评案例',
            dataIndex: 'caseName',
            render: (_, record) => {
                return <>
                    <div>{record.caseName}</div>
                    <div>#{record.caseId}</div>
                </>
            }
        },
        {
            title: '申报主题', dataIndex: 'topicName'
        },
        {
            title: '单位', dataIndex: 'memUnit', width: 150,
        },
        {
            title: '科室/部门', dataIndex: 'memOfficeText', width: 100,
        },
        {
            title: '职务', dataIndex: 'memPostText', width: 100,
        },
        {
            title: '职称', dataIndex: 'memJobtitleText', width: 100,
        },
        {
            title: '是否认证', dataIndex: 'authStatus', width: 100,
        },
        {
            title: '添加人', dataIndex: 'createdName', width: 150,
        },
        {
            title: '添加时间', dataIndex: 'createdAt', width: 200,
        },
    ];

    useEffect(() => {
        getCaseTeammembersList();
    }, [])

    // 导出数据
    const exportData = () => { 
        if (data.length === 0) {
            return message.warning('当前列表没有数据，无法导出')
        }
        message.success('请稍后，正在导出');
        caseTeammembersDownloadApi({stageId: id}).then((res) => {
            if (res.size !== 0) {
                const fileName = `参赛团队人员统计_${moment().format('YYYYMMDDTHHmmss')}.xlsx`;
                let a = document.createElement('a');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.click();
                setTimeout(() => {
                    URL.revokeObjectURL(objectUrl);
                }, 1000)
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    return (
        <>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="export-data-btn">
                        <Space size='middle'>
                            <Button type="primary" onClick={exportData}>导出数据</Button>
                        </Space>
                    </div>

                    <Table
                        className="judge-table"
                        rowKey={item => item.id}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        scroll={{
                            x: 1800
                        }}
                        pagination={null}
                    />

                </div>
            </Content >
        </>

    );
}

export default JudgeList;
