import React, { useState } from 'react';
import { Button, message, Upload, Popconfirm } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { UploadOutlined } from '@ant-design/icons';
import config from '@config';
import { FileTypeWithIconList } from '@utils/constants';
import './index.less';

export default function UploadComponent({ OSSData, getOSSData, maxCount = 50, files, setFiles }) {
    const getExtraData = (file) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.accessId,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
    });
    // 文档上传
    const fileProps = {
        name: 'file',
        maxCount: maxCount,
        fileList: files,
        multiple: true,
        accept: '.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.zip',
        showUploadList: false,
        action: OSSData?.host,
        data: getExtraData,
        beforeUpload: (file) => {
            if (files && files.length >= maxCount) {
                message.error(`最多上传${maxCount}个文件`);
                return Upload.LIST_IGNORE;
            }

            const validFileTypes = /\.(?:docx?|xlsx?|pptx?|pdf|zip)$/i.test(file.name);
            if (!validFileTypes) {
                message.error('仅支持上传PPT/PPTX、DOC/DOCX、XLS/XLSX、PDF、ZIP格式');
                return Upload.LIST_IGNORE;
            }

            const validFileSize = file.size / 1024 / 1024 < 512;
            if (!validFileSize) {
                message.error('文件大小不能超过500MB');
                return Upload.LIST_IGNORE;
            }

            let ext = file.name.split('.').pop().toLowerCase();
            let uuidName = `${uuidv4()}.${ext}`;
            file.uuidName = uuidName;
            file.ext = ext;
            if (!OSSData) return Upload.LIST_IGNORE;
            const expire = Number(OSSData.expire) * 1000;
            if (expire < Date.now()) {
                getOSSData();
            }
            file.url = OSSData.dir + uuidName;
            setFiles(files);
            return file;
        },

        onChange(info) {
            setFiles(info.fileList);
        },
    };

    const deleteItem = (file) => {
        let newFileList = files.filter((item) => item.uid !== file.uid);
        setFiles(newFileList);
    };

    const downloadItem = (file) => {
        // 资料下载
        const path = config.BC_OSS_BUCKET + '/' + file.url;
        if (path) {
            fetch(path)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = file.name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                })
                .catch(e => console.error(e));
        } else {
            message.error('文件下载路径不存在');
        }
    };

    const getFileIcon = (file) => {
        const fileExt = file.name.split('.').pop();
        // FileTypeWithIconList 正则中有 i 忽略大小写
        return FileTypeWithIconList.find(t => t.ext.test(fileExt))?.icon || null;
    };

    const renderItem = (file, index) => {
        return (
            <div className="file-item" key={index}>
                <div className="file-info">
                    {getFileIcon(file)}
                    <span className="file-name">{file.name}</span>

                    {file.status === "uploading" && <span className="file-status uploading">上传中{file.percent.toFixed(2)}%</span>}
                    {file.status === "success" && <span className="file-status success">上传成功</span>}
                    {file.status === "error" && <span className="file-status error">上传失败</span>}
                    {file.status === "done" && <span className="file-status done">已上传</span>}
                    {file.status === "save" && <span className="file-status"></span>}
                </div>
                <Popconfirm title="确定删除？" onConfirm={() => deleteItem(file)}>
                    <Button className="operation del" type="link">删除</Button>
                </Popconfirm>
                
                <Button className="operation down" type="link" onClick={() => downloadItem(file)}>下载</Button>
            </div>
        );
    };
    return (
        <div className="module-file-list">
            <div className="module-body">
                <div className="upload-container">
                    <Upload {...fileProps}>
                        <Button icon={<UploadOutlined/>}>上传文件</Button>
                        <br/>
                        <span className="file-tip">支持上传50个文件，格式支持 Word/Excel/PowerPoint/PDF/Zip，单个文件大小不超过 500MB</span>
                    </Upload>
                </div>
            </div>
            <div className="file-list">
                {files && files.length > 0 && files.map((file, index) => renderItem(file, index))}
            </div>
        </div>
    );
}
