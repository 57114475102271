import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { getOnlineDateApi } from '@services/Mdm';
import { getProjectChartData, getProjectsOfType } from '@services/Mdm';
import OnlineChart from './components/OnlineChart';
import ProjectChart from './components/ProjectChart';
import ProjectStatistics from './components/ProjectStatistics';
import './index.less';
import { Layout, DatePicker, Select, message, Input, Form, Row, Col, Typography, Tooltip } from 'antd';
import { projectKeys, projectType, eventKeys, eventType } from '../Projects/utils';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';

const { Content } = Layout;
const { RangePicker } = DatePicker;

const options = [
    { value: 'month', label: '月度统计' },
    { value: 'quarter', label: '季度统计' },
    { value: 'year', label: '年度统计' },
    { value: 'date', label: '日期范围' },
];

const OnlineOverview = () => {
    const date = moment(new Date());
    const startDay = moment(date).format('YYYY-MM-01');
    const endDay = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    const [model, setModel] = useState('month');
    const [dates, setDates] = useState(null);
    const [months, setMonths] = useState(date);
    const [timeSpan, setTimeSpan] = useState([startDay, endDay]);
    const [chartDate, setChartDate] = useState(undefined);

    const [projectChartData, setProjectChartData] = useState(undefined);

    const [form] = Form.useForm();
    const [projects, setProjects] = useState([]);

    const code = Form.useWatch('code', form);
    const projectId = Form.useWatch('projectId', form);
    const event = Form.useWatch('event', form);

    useEffect(() => {
        form.resetFields(['event']);
        form.resetFields(['projectId']);
    }, [code, form]);

    useEffect(() => {
        form.resetFields(['event']);
    }, [projectId]);

    const disabledDate = (current, type) => {
        switch (type) {
            case 'day':
                return current && current > moment().subtract(1, 'days').endOf('day');
            case 'month':
                return current && current > moment().subtract(0, 'month').endOf('month');
            case 'quarter':
                return current && current > moment().subtract(0, 'quarter').endOf('quarter');
            case 'year':
                return current && current > moment().subtract(0, 'year').endOf('year');
            default:
                break;
        }
    };

    const modelChange = (d, ds, type) => {
        console.log(d, ds, type);

        let annualJudgment;

        if (type !== 'date') {
            annualJudgment = Number(d.format('YYYY')) === Number(date.format('YYYY'));
        }

        if (type === 'year') {
            let year = d.format('YYYY'),
                lastDay,
                timePeriod;

            if (annualJudgment) {
                lastDay = endDay;
            } else {
                lastDay = `${year}-12-31`;
            }

            timePeriod = [`${year}-01-01`, lastDay];

            setTimeSpan(timePeriod);
        }

        if (type === 'month') {
            let month = d.format('YYYY-MM'),
                lastDay,
                timePeriod;

            if (d.format('MM') === date.format('MM') && annualJudgment) {
                if (d.format('DD') === '01') {
                    // 月度统计，第一天时，如 2023-08-01
                    // 时间范围处理成 startDate=2023-08-01&endDate=2023-08-01
                    lastDay = d.format('YYYY-MM-01');
                } else {
                    lastDay = endDay;
                }
            } else {
                lastDay = moment(month).add('month', 1).add('days', -1).format('YYYY-MM-DD');
            }

            timePeriod = [`${month}-01`, lastDay];
            setTimeSpan(timePeriod);

            setMonths(d);
        }

        if (type === 'quarter') {
            let quarter = d.format('Q'),
                year = d.format('YYYY'),
                lastDay,
                timePeriod;

            if ((Number(d.format('Q')) === Number(date.format('Q'))) && (annualJudgment)) {
                lastDay = endDay;
            } else {
                lastDay = d.format('Q') === '1'
                    ? `${year}-03-31`
                    : d.format('Q') === '2'
                        ? `${year}-06-30`
                        : d.format('Q') === '3'
                            ? `${year}-09-30`
                            : `${year}-12-31`
            }

            switch (quarter) {
                case '1':
                    timePeriod = [`${year}-01-01`, lastDay];
                    break;
                case '2':
                    timePeriod = [`${year}-04-01`, lastDay];
                    break;
                case '3':
                    timePeriod = [`${year}-07-01`, lastDay];
                    break;
                case '4':
                    timePeriod = [`${year}-10-01`, lastDay];
                    break;
                default:
                    break;
            }

            console.log(timePeriod);
            setTimeSpan(timePeriod);
        }

        if (type === 'date') {
            if (moment(ds[1]).diff(moment(ds[0]), 'days') > 365) {
                message.error('时间区间不得超过365天');
                setDates(null);
                setTimeSpan(undefined);
            } else {
                setDates(d);
                setTimeSpan(ds);
            }
        }

    };

    // 项目改变
    const changeProjectType = val => {
        if (!!val) {
            getProjectsOfType({ code: val })
                .then(res => {
                    if (res && res.status_code === 200) {
                        setProjects(res.data);
                    } else {
                        setProjects([]);
                    }
                });
        } else {
            setProjects([]);
        }
    };

    useEffect(() => {
        getOnlineDateApi({
            startDate: timeSpan[0],
            endDate: timeSpan[1],
            code: code,
            projectId: projectId,
            event: event
        })
            .then(res => {
                if (res.status_code === 200) {
                    setChartDate(res.data);
                }
            });

        if (!code) {
            getProjectChartData({ startDate: timeSpan[0], endDate: timeSpan[1] })
                .then(res => {
                    if (res.status_code === 200) {
                        setProjectChartData(res.data);
                    }
                });
        }

    }, [timeSpan, code, projectId, event]);

    return (
        <>
            <div className="page-title">医疗决策人在线总览</div>

            <Content className="main-content-box no-bg-color">

                <div className="content-block">
                    <Form form={form}>
                        <div className="online-date">
                            <div className="content-block-title">
                                数据概览
                                <Tooltip title="统计医疗决策人1次2次3次4次在线人数">
                                    <span className="tooltip-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                        </svg>
                                    </span>
                                </Tooltip>
                            </div>

                            <div className="online-date-choice">
                                <Form.Item label="统计时间">
                                    <Input.Group compact>
                                        <Select
                                            style={{ width: 120 }}
                                            options={options}
                                            defaultValue="month"
                                            onSelect={(value) => {
                                                if (value !== 'date') setDates(null);
                                                if (value !== 'month') setMonths(null);
                                                setModel(value);
                                            }}
                                        ></Select>

                                        {model === "month" &&
                                            <DatePicker
                                                style={{ width: 200 }}
                                                onChange={(v, s) => modelChange(v, s, 'month')}
                                                picker="month"
                                                value={months}
                                                disabledDate={(current) => disabledDate(current, 'month')}
                                            />
                                        }

                                        {model === "quarter" &&
                                            <DatePicker
                                                style={{ width: 200 }}
                                                onChange={(v, s) => modelChange(v, s, 'quarter')}
                                                picker="quarter" format={"YYYY年-第Q季度"}
                                                disabledDate={(current) => disabledDate(current, 'quarter')}
                                            />
                                        }

                                        {model === "year" &&
                                            <DatePicker
                                                style={{ width: 200 }}
                                                onChange={(v, s) => modelChange(v, s, 'year')}
                                                picker="year"
                                                disabledDate={(current) => disabledDate(current, 'year')}
                                            />}

                                        {model === "date" &&
                                            <RangePicker
                                                style={{ width: 300 }}
                                                allowClear
                                                value={dates}
                                                onChange={(v, s) => modelChange(v, s, 'date')}
                                                disabledDate={(current) => disabledDate(current, 'day')}
                                            />
                                        }
                                    </Input.Group>
                                </Form.Item>
                            </div>

                            <div className="online-project-choice">

                                <Row>
                                    <Form.Item label="参与项目" name="code">
                                        <Select allowClear placeholder="选择项目类型" onChange={changeProjectType}>
                                            <Select.Option value={projectKeys.survey}>{projectType.survey}</Select.Option>
                                            <Select.Option value={projectKeys.conference}>{projectType.conference}</Select.Option>
                                            <Select.Option value={projectKeys.selection}>{projectType.selection}</Select.Option>
                                            <Select.Option value={projectKeys.invitation}>{projectType.invitation}</Select.Option>
                                            <Select.Option value={projectKeys.researchhospital}>{projectType.researchhospital}</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    {!!code && (
                                        <Form.Item label="" name="projectId">
                                            <Select placeholder="选择项目" showSearch optionFilterProp="children" allowClear>
                                                {projects.map((p,index) => <Select.Option key={index} value={p.projectId}>{`${p.projectName} [#${p.projectId}]`}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    )}

                                    {!!projectId && (
                                        <Form.Item label="" name="event">
                                            <Select allowClear placeholder="选择医疗决策人在线动作">
                                                {code === projectKeys.survey && <Select.Option value={eventKeys.submit_survey}>{eventType.submit_survey}</Select.Option>}
                                                {code === projectKeys.conference && <Select.Option value={eventKeys.checkin}>{eventType.checkin}</Select.Option>}
                                                {code === projectKeys.conference && <Select.Option value={eventKeys.enroll}>{eventType.enroll}</Select.Option>}
                                                {code === projectKeys.conference && <Select.Option value={eventKeys.confirm_profile}>{eventType.confirm_profile}</Select.Option>}
                                                {code === projectKeys.selection && <Select.Option value={eventKeys.submit_case}>{eventType.submit_case}</Select.Option>}
                                                {code === projectKeys.selection && <Select.Option value={eventKeys.score_case}>{eventType.score_case}</Select.Option>}
                                                {code === projectKeys.selection && <Select.Option value={eventKeys.download_judge_cert}>{eventType.download_judge_cert}</Select.Option>}
                                                {code === projectKeys.selection && <Select.Option value={eventKeys.download_case_cert}>{eventType.download_case_cert}</Select.Option>}
                                                {code === projectKeys.selection && <Select.Option value={eventKeys.view_rank}>{eventType.view_rank}</Select.Option>}
                                                {code === projectKeys.invitation && <Select.Option value={eventKeys.confirm_invitation}>{eventType.confirm_invitation}</Select.Option>}
                                                {code === projectKeys.researchhospital && <Select.Option value={eventKeys.submit_data}>{eventType.submit_data}</Select.Option>}
                                            </Select>
                                        </Form.Item>
                                    )}

                                </Row>
                            </div>
                        </div>

                    </Form>

                    <div className="content-block-body">

                        <OnlineChart chartDate={chartDate}/>

                        {/*{!code && <p><Typography.Text type="warning">* 2023年度医疗决策人数据补充说明：医会议每位用户在线超过4次的，超出部分“每4次在线”可折算“4次在线人数”增加1人数。</Typography.Text></p>}*/}

                    </div>


                </div>

                <div className="content-block">

                    <div className="content-block-head">
                        <div className="content-block-title">
                            项目统计
                            <Tooltip title="按项目统计医疗决策人在线数据贡献">
                                <span className="tooltip-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                    </svg>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="content-block-toolbar">

                        </div>
                    </div>

                    <div className="content-block-body" style={{minHeight: 500}}>

                        <ProjectStatistics />

                    </div>

                </div>

            </Content>
        </>
    );
};

export default OnlineOverview;
