import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
    Button,
    Layout,
    Table,
    Space,
    Form,
    Input,
    Select,
    Row,
    Col,
    Modal,
    message,
    Tooltip,
    Spin,
    Typography,
    Divider, Tag, Dropdown
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { listSurveryApi, delSurveyApi, releaseSurveryApi, surveyMarkAsTesting } from '@services/Wj/Question'
import './index.less'
import Addsurvey from './Addsurvey';
import config from '@config';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { convertDateTimeToLocalText, GetQueryString } from '@utils/utils';

const { Text } = Typography;

export default function SurveysList() {
    const navigate = useNavigate()
    const [form] = Form.useForm();

    const { Content } = Layout;
    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [load, setLoad] = useState(false)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [curItem, setCurItem] = useState(undefined)
    const [iframeSrc, setIframeSrc] = useState(undefined)
    const [loading, setLoading] = useState(false);
    const isSearchRef = useRef(null);
    const [filObj, setFilObj] = useState({
        isTesting: 0
    });
    const [data, setData] = useState([])

    const onPreviewClick = (item) => {
        setCurItem(item)
        setIsPreviewModalOpen(true)
    }

    const goto = (type, id, title, row) => {
        switch (type) {
            case 'charts':
                navigate(`/wj/surveys/${id}/charts`, { state: { id: id, name: title } })
                break;
            case 'share':
                if (row.status !== 1) {
                    Modal.confirm({
                        title: '提示',
                        icon: <ExclamationCircleOutlined/>,
                        content: '问卷需要发布后再分享，是不是要发布？',
                        okText: '发布',
                        cancelText: '取消',
                        onOk: () => {
                            releaseSurveryApi({ id: row.id, status: 1 }).then((res) => {
                                if (res.status_code === 200) {
                                    navigate(`/wj/surveys/${id}/share`, {
                                        state: {
                                            title,
                                            route: "newList",
                                            id: id,
                                            type: row.surveyTypeId,
                                            surveyStatus: row.status,
                                            action: 'edit'
                                        }
                                    })
                                }
                            })
                        }
                    });
                } else {
                    navigate(`/wj/surveys/${id}/share`, {
                        state: {
                            title,
                            route: "newList", id: id, type: row.surveyTypeId, surveyStatus: row.status, action: 'edit'
                        }
                    })
                }
                break;
            case 'edit':
                if (row.status === 1) {
                    Modal.confirm({
                        title: '提示',
                        icon: <ExclamationCircleOutlined/>,
                        content: '问卷为上架状态不支持修改，需下架问卷后进行编辑',
                        okText: '我知道了',
                        cancelText: '取消',
                    });
                } else {
                    navigate(`/wj/surveys/edit/${id}`, {
                        state: {
                            route: "newList",
                            id: id,
                            type: row.surveyTypeId,
                            surveyStatus: row.status,
                            action: 'edit',
                            title: row.name
                        }
                    })
                }
                break;
            default:
                break;
        }

    }

    //分页
    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onFormFinish = (res) => {
        console.log(res);
        isSearchRef.current = true;
        setFilObj({
            name: res?.name,
            type: res?.type,
            status: res?.status,
            business: res?.business,
            createdName: res?.createdName,
            isTesting: res?.isTesting,
        });
    }

    const onReset = () => {
        form.resetFields()

        setFilObj({
            isTesting: 0
        });
    }

    const newSurvey = () => {
        setIsModalOpen(true)
    }

    const onDelete = (row) => {
        Modal.confirm({
            title: '提示',
            content: `确认删除问卷${row?.name}`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                delSurveyApi({ id: row.id })
                    .then((res) => {
                        if (res.status_code === 200) {
                            listSurveryApi({ page, pageSize, isTesting: filObj.isTesting })
                                .then((res) => {
                                    if (res.status_code === 200) {
                                        setData(res.data.list);
                                        setTotal(res.data.totalCount);
                                    }
                                });

                        }
                    });
            }

        });
    };

    const onChangeStatus = (row) => {
        // console.log(row.status);
        if (row.status === 1) {
            releaseSurveryApi({ id: row.id, status: 2 })
                .then((res) => {
                    if (res.status_code === 200) {
                        setLoad(true);

                        listSurveryApi({ page, pageSize, isTesting: filObj.isTesting })
                            .then((res) => {
                                if (res.status_code === 200) {
                                    setLoad(false);
                                    setData(res.data.list);
                                    setTotal(res.data.totalCount);
                                }
                            });

                    }
                })
        } else {
            releaseSurveryApi({ id: row.id, status: 1 })
                .then((res) => {
                    if (res.status_code === 200) {
                        setLoad(true);

                        listSurveryApi({ page, pageSize, isTesting: filObj.isTesting })
                            .then((res) => {
                                if (res.status_code === 200) {
                                    setLoad(false);
                                    setData(res.data.list);
                                    setTotal(res.data.totalCount);
                                }
                            });

                        message.success('问卷已发布');
                    }
                });
        }
    };

    const getListData = async (page, pageSize, name, type, status, createdName, business, isTesting, projectName) => {
        isSearchRef.current = false;
        // console.log(type, status, '====');

        let params = {
            page,
            pageSize,
            name: projectName || name,
            isTesting,
        };
        if (type !== undefined) params.surveyTypeId = type;
        if (status !== undefined) params.status = status;
        if (business !== undefined) params.business = business;
        if (createdName !== undefined || createdName !== '') params.createdName = createdName;

        let res = await listSurveryApi(params)
        if (res.status_code === 200) {
            setData(res.data.list);
            setTotal(res.data.totalCount);
        }
    }

    const markAsTesting = row => {
        Modal.confirm({
            title: '标记为测试问卷',
            icon: <ExclamationCircleOutlined />,
            content: '医疗决策人数据统计等数据指标将排除标记为测试的问卷项目，确定标记为测试问卷？',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                surveyMarkAsTesting({
                    id: row.id,
                    isTesting: !!row.isTesting ? 0 : 1,
                }).then(res => {
                    if (res.status_code === 200) {
                        const msg = !!row.isTesting ? '标记为正式问卷成功' : '标记为测试问卷成功';
                        message.success(msg);

                        const { name, type, status, createdName, business, isTesting } = filObj;
                        if (isSearchRef.current === true) {
                            getListData(1, pageSize, name, type, status, createdName, business, isTesting);
                        } else {
                            getListData(page, pageSize, name, type, status, createdName, business, isTesting);
                        }
                    }
                });
            }
        });

    };


    const columns = [
        {
            title: "问卷ID",
            dataIndex: "id",
            key: "id",
            width: 60,
            fixed: "left",
        },
        {
            title: "问卷名称",
            dataIndex: "name",
            key: "name",
            width: 300,
            fixed: "left",
            render: (_, record) => (
                <>
                    <Tooltip placement="bottom" title={record.name}>
                        <span>{record.name}</span>
                        {!!record.isTesting && <Tag color="#f50" style={{marginLeft: 4, padding: '0 4px'}}>TEST</Tag>}
                    </Tooltip>
                </>
            ),
        },
        {
            title: "问卷类型",
            dataIndex: "surveyTypeId",
            key: "surveyTypeId",
            width: 68,
            render: (surveyTypeId, row) => (
                <Space size="middle">
                    {surveyTypeId === 1 && <span>调研问卷</span>}
                    {surveyTypeId === 2 && <span>投票</span>}
                    {surveyTypeId === 3 && <span>活动报名</span>}
                </Space>
            ),
        },
        {
            title: "所属业务",
            dataIndex: "business",
            key: "business",
            width: 68,
            render: (business, row) => (
                <Space size="middle">
                    {business === 0
                        ? <span>调研后台</span>
                        : business === 1
                            ? <span>健康界</span>
                            : business === 2
                                ? <span>组织学习</span>
                                : <span>调研后台</span>
                    }
                </Space>
            ),
        },
        {
            title: "题目数量",
            dataIndex: "questionsCount",
            key: "questionsCount",
            width: 68,
        },
        {
            title: "作答人数",
            dataIndex: "userCount",
            key: "userCount",
            width: 68,
        },
        {
            title: "问卷状态",
            dataIndex: "status",
            key: "status",
            width: 68,
            render: (status, row) => (
                <Space size="middle">
                    {status === 0 && <span>未发布</span>}
                    {status === 1 && <Text type="success">已发布</Text>}
                    {status === 2 && <Text type="warning">已下架</Text>}
                </Space>
            ),
        },
        {
            title: "创建时间",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 120,
            render: (_, row) => (<>
                <span>{row.createdByStaffName || '系统创建'}</span><br/>
                <small><Text type="secondary">{convertDateTimeToLocalText(row.createdAt)}</Text></small>
            </>)
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            fixed: "right",
            width: 220,
            render: (state, row) => {
                const items = [
                    {
                        label: !!row.isTesting ? '标记为正式问卷' : '标记为测试问卷',
                        key: "markAsTesting",
                        onClick: () => markAsTesting(row),
                    },
                    {
                        label: "删除问卷",
                        key: "delete",
                        onClick: () => onDelete(row),
                        danger: true,
                    },
                ];

                return (
                    <span className="operation-btns">
                        {(row.status === 0 || row.status === 2) && <Button type='link' size="small" onClick={() => onChangeStatus(row)}>发布</Button>}
                        {row.status === 1 && <Button type='link' size="small" onClick={() => onChangeStatus(row)} danger>下架</Button>}
                        <Divider type="vertical"/>
                        <Button type='link' size="small" onClick={() => onPreviewClick(row)}>预览</Button>
                        <Divider type="vertical"/>
                        <Button type='link' size="small" onClick={() => goto('share', row.id, row.name, row)}>分享</Button>
                        <Divider type="vertical"/>
                        <Button type='link' size="small" onClick={() => goto('charts', row.id, row.name)}>统计</Button>
                        <Divider type="vertical"/>
                        <Button type='link' size="small" onClick={() => goto('edit', row.id, row.name, row)}>编辑</Button>
                        <Divider type="vertical" />
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button type="link" size="small" className="more-operation"><EllipsisOutlined /></Button>
                        </Dropdown>
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        const { name, type, status, createdName, business, isTesting } = filObj;
        if (isSearchRef.current === true) {
            getListData(1, pageSize, name, type, status, createdName, business, isTesting);
        } else {
            getListData(page, pageSize, name, type, status, createdName, business, isTesting);
        }
    }, [page, pageSize, filObj]);

    useEffect(() => {
        if (isPreviewModalOpen) {
            setLoading(true);
            setIframeSrc(`${config.SURVEY_DOMAIN}/s/${curItem?.id}/answer?type=1`);
        } else {
            setIframeSrc(undefined);
        }
    }, [isPreviewModalOpen]);

    useEffect(() => {
        if (iframeSrc !== undefined) {
            setLoading(false);
        }
    }, [iframeSrc]);

    // 医项目跳转携带参数
    useEffect(() => {
        const projectName = GetQueryString('projectName');
        if (projectName) {
            form.setFieldValue('name', unescape(projectName));
            let timer = setTimeout(() => {
                const { name, type, status, createdName, business, isTesting } = filObj;
                getListData(1, pageSize, name, type, status, createdName, business, isTesting, unescape(projectName));
                clearTimeout(timer);
            }, 500);
        }
    }, []);

    return (
        <Fragment>
            <div className="page-title">问卷列表</div>
            <Content className="main-content-box" id='wj_survey_wrap'>
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="table-toolbar-role">
                            <Form
                                {...filterFormItemLayout}
                                form={form}
                                name="control-hooks"
                                onFinish={onFormFinish}
                            >
                                <Row>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="name" label="问卷名称">
                                            <Input placeholder="请输入问卷名称"/>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name='business' label='所属业务'>
                                            <Select
                                                placeholder='请选择所属业务'
                                                className='selete_type_wrap'
                                                options={[
                                                    { value: 0, label: '调研后台' },
                                                    { value: 2, label: '组织学习' },
                                                    { value: 1, label: '健康界' },
                                                ]}
                                                allowClear
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name='type' label='问卷类型'>
                                            <Select
                                                placeholder='请选择问卷类型'
                                                className='selete_type_wrap'
                                                options={[
                                                    { value: 1, label: '调研问卷' },
                                                    { value: 2, label: '投票' },
                                                    { value: 3, label: '活动报名' },
                                                ]}
                                                allowClear
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name='status' label='问卷状态'>
                                            <Select
                                                placeholder='请选择问卷状态'
                                                className='selete_type_wrap'
                                                options={[
                                                    { value: 0, label: '未发布' },
                                                    { value: 1, label: '已发布' },
                                                    { value: 2, label: '已下架' },
                                                ]}
                                                allowClear
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="createdName" label="创建人">
                                            <Input placeholder="请输入创建人名称"/>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item label="正式/测试" name="isTesting" initialValue={0}>
                                            <Select placeholder="选择是否测试问卷" allowClear>
                                                <Select.Option value={0}>正式问卷</Select.Option>
                                                <Select.Option value={1}>测试问卷</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                        <Space>
                                            <Button type="primary" htmlType="submit">查询</Button>
                                            <Button htmlType="button" onClick={onReset}>重置</Button>
                                        </Space>
                                    </Col>
                                </Row>

                            </Form>

                            <div className='new-survey'>
                                <Button type="primary" onClick={newSurvey}>新建问卷</Button>
                            </div>
                        </div>

                        <Table
                            className="template-table"
                            columns={columns}
                            dataSource={data}
                            loading={load}
                            pagination={{
                                total: total,
                                showTotal: () => `共${total}条`,
                                current: page,
                                pageSize: pageSize,
                                onChange: onChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                            rowKey={(row) => row.id}
                            scroll={{ x: 1000 }}
                        />

                        <Addsurvey isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
                    </div>
                </div>

            </Content>

            <Modal
                open={isPreviewModalOpen}
                title={curItem?.name || ''}
                className='share_survey_modal_wrap'
                onCancel={() => setIsPreviewModalOpen(false)}
                destroyOnClose
                footer={null}
            >

                {loading ?
                    <Spin/> :
                    <iframe src={iframeSrc} frameBorder="0" title='share'></iframe>}
            </Modal>
        </Fragment>
    )

}

