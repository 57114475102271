import React, { useState, useEffect } from 'react';
import { LeftOutlined, PlusCircleOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Layout, Form, Input, Switch, Select, Radio, Checkbox, Button, Space } from 'antd';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { getProjestsTopicsListApi,saveTopicsApi,getTopicsInfoApi,updateTopicsApi } from '@services/Selection';
import './index.less';

const Topics = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { Content } = Layout;
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const [selectOpinon, setSelectOpinon] = useState([]);
    const [title, setTitle] = useState('');


    const onReset = () => {
        navigate(`/selection/projects/${params.pid}/topics`);
    };

    const goBack = () => {
        navigate(`/selection/projects/${params.pid}/topics`,{state:{...location.state}})
    };

    useEffect(() => {
        getProjestsTopicsListApi({ projectId: params.pid }).then((topics) => {
            if (topics.status_code === 200) {
                let allTopics = topics?.data || [];
                allTopics = allTopics.filter(item => item.pid === null)
                setSelectOpinon([...allTopics]);
            }
        });
        if(params?.id){
            getTopicsInfoApi({id:params?.id}).then(res=>{
                if(res.status_code === 200) {
                    res.data.pid = res.data.pid===0 ? undefined : res.data.pid
                    setTitle(res.data.name)
                    form.setFieldsValue(res.data);
                }
            })
        }
    }, []);

    const onFinish = (value) => {
        if(params?.id){
            updateTopicsApi({...value,projectId:params.pid,id:Number(params?.id),hide:value.hide||false,isPeople:value.isPeople||0,pid:value.pid===undefined?0:value.pid}).then(res=>{
                if(res.status_code === 200) {
                    navigate(`/selection/projects/${params.pid}/topics`);
                }
            })
        }else{
            saveTopicsApi({...value,projectId:params.pid,hide:value.hide||false,isPeople:value.isPeople||0,pid:value.pid===undefined?0:value.pid}).then(res=>{
                if(res.status_code === 200) {
                    navigate(`/selection/projects/${params.pid}/topics`);
                }
            })
        }


    };
    return (
        <>
            <div className="page-title">
            <div onClick = {goBack} className='back'>
                    <LeftOutlined />
                </div>
                <span className='current-title'>{!params?.id ? '新增主题' : '编辑主题'}</span>
                {/* {!!title && <span className="current-subtitle">{title}</span>} */}
            </div>


            <Content className='main-content-box'>
                <div className='main-content'>
                    <div className='main-title'>
                        <div>评选项目信息</div>
                    </div>
                </div>
                <div className='solution-message-content'>
                    <Form {...pageFormItemLayout} form={form} onFinish={onFinish}>
                        <Form.Item
                            label='主题名称'
                            name={'name'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入主题名称',
                                },
                            ]}
                        >
                            <Input showCount maxLength={30} placeholder='请输入主题名称' />
                        </Form.Item>
                        <Form.Item label='所属主题分类' name={'pid'}>
                            <Select
                            placeholder='请选择所属主题分类'
                            options={selectOpinon}
                            fieldNames={{label:'name',value:'id'}}/>
                        </Form.Item>
                        <Form.Item label='主题类型' name={'isPeople'}>
                            <Radio.Group defaultValue={0}>
                                <Radio value={0}>案例类</Radio>
                                <Radio value={1}>人物类</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label='主题说明' name={'description'}>
                            <TextArea maxLength={200} showCount rows={4} />
                        </Form.Item>
                        <Form.Item label='是否隐藏' name={'hide'} valuePropName={'checked'}>
                            <Switch checkedChildren='是' unCheckedChildren='否' />
                        </Form.Item>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout} style={{ marginTop: 24, marginBottom: 0 }}>
                            <Space>
                                <Button type='primary' size='large' htmlType='submit'>
                                    提交
                                </Button>
                                <Button type='default' size='large' onClick={onReset}>
                                    取消
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        </>
    );
};

export default Topics;
