import React,{useState,useRef,useEffect} from 'react';
import { Modal, Tabs, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import './index.less';
import HaibaoLib from '../HaibaoLib';
import HaibaoOperat from '../HaibaoOperat';
import html2canvas from 'html2canvas';
import config from '@config';
const Haibao = ({ haibaoOpen, setHaibaoOpen,url }) => {
    const location = useLocation();
    const verifyRef = useRef(null);
    const [activeKey, setActiveKey] = useState('1')
    const [haibaoImage, setHaibaoImage] = useState(`${config.OSS_PREFIX}/survey/posters/bg/med_01.jpg`)
    const [channelName,setChannelName] = useState('默认渠道')
    const toTwo = (item) => {
        setHaibaoImage(item)
        setActiveKey('2')
    };

    const onChange = (key) => {
        setActiveKey(key)
    };

    const onHaibaoDownload = (id) => {
        const form=verifyRef.current?.form
        form.validateFields().then(()=>{
            formHtmlToDownload(id)
        })
    };
    const formHtmlToDownload = async (domId, isErweima) => {
        let res = await drawCanvas(domId, isErweima)
        let linkA = document.createElement('a');       //创建a标签
        linkA.href = res;                // 将图片的src赋值给a节点的href
        linkA.download = `${location.state?.title}-${channelName} `|| 'download';     // 设置a节点的download属性值(设置下载名称)
        linkA.target='_blank';
        let event = new MouseEvent('click');           // 模拟鼠标click点击事件
        linkA.dispatchEvent(event);
    }

    const drawCanvas = async (domId, isErweima) => {
        // 获取想要转换的 DOM 节点
        const dom = document.getElementById(domId);
        // const dom = document.querySelector('printHtml');
        const box = window.getComputedStyle(dom);
        // DOM 节点计算后宽高
        const width = parseValue(box.width);
        const height = parseValue(box.height);
        // 获取像素比-防止模糊
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement('canvas');
        //     // 设置固定下载宽度
        //     canvas.width = 620;
        //     canvas.height = 935;
        // // 设定 canvas css宽高为 DOM 节点宽高
        // canvas.style.width = `${width/2}px`;
        // canvas.style.height = `${height/2}px`;
        canvas.width = 615;
        canvas.height = 920;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `615px`;
        canvas.style.height = `920px`;
        // 获取画笔
        const context = canvas.getContext('2d');

        // 将所有绘制内容放大像素比倍
        context.scale(1, 1);

        // 将自定义 canvas 作为配置项传入，开始绘制
        return await html2canvas(dom, {
            canvas,
            useCORS : true,
            scale: 2,
            width: 615,
            height: 920,
            windowHeight: 615,
            windowWidth: 920,
            // allowTaint :true
        }).then(canvas => {
            //document.querySelector("#canvasContainer").appendChild(canvas);
            //return canvas.toDataURL("image/png");
            return canvas.toDataURL("image/png");
        });
    };

    const DPR = () => {
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        } else {
            return 1.8;
        }
    }
    const parseValue = (value) => {
        return parseInt(value, 10);
    }
    const items = [
        { label: '模版预览', key: '1', children: <HaibaoLib toTwo={toTwo}  activeKey={activeKey}  url={url} setHaibaoImage={setHaibaoImage}/>}, // 务必填写 key
        { label: '生成海报', key: '2', children: <HaibaoOperat url={url} haibaoImage={haibaoImage} ref={verifyRef} activeKey={activeKey} setChannelName={setChannelName}/> },
    ];
    // useEffect({
    //     if(haibaoOpen){
    //         setActiveKey('1')
    //     }
    // },[haibaoOpen])
    return (
        <Modal
            open={haibaoOpen}
            onCancel={() => (setHaibaoOpen(false),setActiveKey('1'))}
            className='haibao_modal'
            width={798}
            destroyOnClose
            footer={activeKey==='1'?null:[
                <Button key="submit" className='save-btn' type="primary" onClick={() => onHaibaoDownload('haibao_item_main')} >
                  保存并下载
                </Button>
              ]}
        >
        <div className='haibaoBox'>
            <Tabs className='haibao_tabs' items={items} onChange={onChange} activeKey={activeKey}/>
        </div>

        </Modal>
    );
}

export default Haibao;


