import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _, { get } from 'lodash';
import {
    Button,
    Layout,
    Table,
    Space,
    Form,
    Input,
    Modal,
    message,
    Typography,
    Select,
    Row,
    Col,
    Tag,
    Tooltip,
    Alert, Divider, Steps, Upload, Image, Popover
} from 'antd';
import { LeftOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, CloseOutlined, CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, QuestionCircleFilled } from "@ant-design/icons";
import {
    laborFeeListApi,
    statisticalAmountApi,
    updateLaborFeeApi,
    laborFeeConfirmedApi,
    individualTaxConfirmedApi,
    sendAgendaConfirmTaskApi,
    laborFeeBatchApplyApi,
    savelaborFeeJoinCertApi,
    laborFeeExpertsApi,
    laborFeeAdjustListApi,
    examineReasonsApi,
    laborFeeRequestBatchesListApi
} from '@services/SereviseConst';
import { getAllStaffs } from '@services/Enterprise';
import { conferenceOnceApi, laborAuditFlowsApi } from '@services/Conference';
import { filterFormGridLayout } from '@utils/property';
import { localStorageGet } from '@core/cookie';
import { convertDateTimeRangeToLocalText, convertDateTimeToLocalText, prepareBCOSSUrl } from '@utils/utils';
import config from '@config';
import { laborFeeCostBearers, laborFeePaymentMethods, laborFee3rdPartyPaymentMethods } from '@utils/constants';
import { fileApi } from "@services/Conference";
import './index.less';
import { conferenceActions } from '../store/conferenceSlice';
import moment from 'moment';
import { pageFormItemLayout } from "@utils/property";
import PaymentRecordModal from './components/PaymentRecordModal';
import ChooseExpertModal from './components/ChooseExpertModal';
import IndividualConfirmResult from './components/IndividualConfirmResultModal';
import ConfirmScheduleModal from './components/ConfirmScheduleModal';
import ConfirmBankInfoModal from './components/ConfirmBankInfoModal';
import LaborFeeRequestDrawr from './components/LaborFeeRequestDrawer';
import { resetLaborFeeConfirmation } from '@services/Finance/FinanceLaborFee.service';

const { Content } = Layout;
const { Text } = Typography;

const filterFormItemLayout = {
    labelCol: {
        sm: { span: 20 },
        md: { span: 8 },
    },

    wrapperCol: {
        sm: { span: 24 },
        md: { span: 16 },
    }
};

const { setLaborFeeFilter: setFilter, setLaborFeeCursor: setCursor } = conferenceActions;

const getIsCheck = (value, record, getFieldValue) => {
    if (parseInt(getFieldValue('laborFeeAmount')) === 0 && value !== 3) {
        return true;
    }

    if (value.paymentMethod === 3 && record.laborFeeAmount) {
        return true;
    }

    if (value !== 2) {
        return false;
    }

    // 中国 >= 70岁 不可选, 外籍人员不可选
    let check = false;
    if (record.idcardType === 1) {
        if (record.birthday) {
            const birthDate = moment(record.birthday).format('YYYY-MM-DD');
            const nowDate = moment(new Date()).format('YYYY-MM-DD');
            const age = moment(nowDate).isBefore(moment(birthDate).add(70, 'years'));
            check = !age;
        }
    }
    if (record.idcardType !== null && record.idcardType !== 1) {
        check = true;
    }
    return check;
}

const EditableCell = props => {
    const {
        editing,
        dataIndex,
        title,
        record,
        children,
        ...restProps
    } = props;

    let node = null;
    switch (dataIndex) {
        case 'laborFeeAmount':
            node = (
                <Form.Item
                    noStyle
                    shouldUpdate
                >
                    {({ getFieldValue, setFieldValue }) => {
                        return <Form.Item
                            style={{ margin: 0 }}
                            name="laborFeeAmount"
                            noStyle
                            rules={[
                                { required: true, message: '未填写税后劳务费' },
                                {
                                    validator: async (rule, value) => {
                                        if (value && !(/^\d+(\.\d{1,2})?$/).test(value)) {
                                            throw '税后劳务费需输入最多保留两位小数数字'
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input
                                placeholder="税后劳务费"
                                allowClear
                                onChange={(e) => {
                                    if (e.target.value === '0') {
                                        setFieldValue('paymentMethod', 3);
                                    }
                                }}
                            />
                        </Form.Item>
                    }
                    }
                </Form.Item>
            );
            break;
        case 'paymentMethod':
            node = (
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                        return <Form.Item
                            name="paymentMethod"
                            noStyle
                            initialValue={1}
                            rules={[
                                { required: true, message: '未选择付款方式' },
                            ]}
                        >
                            <Select
                                placeholder="付款方式"
                                style={{ width: 140 }}
                                onChange={(type) => {
                                    setFieldValue('costBearer', 1);
                                    if (type === 3) {
                                        setFieldValue('laborFeeAmount', 0);
                                    }
                                }}
                                allowClear
                            >
                                {laborFeePaymentMethods.map((item, index) => (
                                    <Select.Option
                                        value={item.value}
                                        key={index}
                                        disabled={getIsCheck(item.value, record, getFieldValue)}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }}
                </Form.Item>
            );
            break;
        case 'costBearer':
            node = (
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.paymentMethod !== currentValues.paymentMethod}
                >
                    {({ getFieldValue, setFieldValue }) => {
                        const type = getFieldValue('paymentMethod') === 3;
                        return type
                            ? <Text type="secondary">&ndash;</Text>
                            : <Form.Item
                                name="costBearer"
                                noStyle
                                initialValue={1}
                                rules={[
                                    { required: true, message: '未选择费用承担主体' },
                                ]}
                            >
                                <Select
                                    placeholder="费用承担主体"
                                    style={{ width: 130 }}
                                    options={laborFeeCostBearers}
                                    allowClear
                                />
                            </Form.Item>;
                    }}
                </Form.Item>
            );
            break;
        default:
            break;
    }

    return <td {...restProps}>{editing ? (node) : (children)}</td>;
};

let expertId = null; // 劳务费上传凭证id
let expertName = null; //上传参会凭证选择专家名称

const ConferenceLaborFeeRecords = () => {
    const params = useParams();

    const conferenceId = parseInt(params?.id) || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const dispatch = useDispatch();
    const filter = useSelector(state => state.conference.laborFee.filter);
    const cursor = useSelector(state => state.conference.laborFee.cursor);
    // console.log(filter, cursor);

    const [staffs, setStaffs] = useState([]);

    const [filterForm] = Form.useForm();

    const [uploadForm] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const [statisticalAmount, setStatisticalAmount] = useState(null);

    const [hasNullRecord, setHasNullRecord] = useState(false);
    const [hasNullTax, setHasNullTax] = useState(false);
    const [nullItems, setNullItems] = useState([]);

    const [laborFeeForm] = Form.useForm();

    const [sendForm] = Form.useForm();

    const [editingKey, setEditingKey] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openTips, setOpenTips] = useState(false);

    const [joinCertList, setJoinCertList] = useState([]);
    const [openCertificate, setOpenCertificate] = useState(false);
    const [openPaymentRecord, setOpenPaymentRecord] = useState(false);
    const [chooseExpertIds, setChooseExpertIds] = useState([]);
    const [openChooseExpertModal, setOpenChooseExpertModal] = useState(false);
    const [openIndividualConfirmModal, setOpenIndividualConfirmModal] = useState(false);
    const [paymentRecordList, setPaymentRecordList] = useState([]);
    const [examineReasons, setExamineReasons] = useState([]);
    const [batchNoList, setBatchNoList] = useState([]);

    const [openConfirmScheduleModal, setOpenConfirmScheduleModal] = useState(false);
    const [selectExpertId, setSelectExpertId] = useState(0);
    const [openConfirmBankInfoModal, setOpenConfirmBankInfoModal] = useState(false);
    const [openLaborFeeDrawer, setOpenLaborFeeDrawer] = useState(false);

    const [laborAuditFlows, setLaborAuditFlows] = useState([]);

    const isEditing = record => record.expertId === editingKey;

    const handleOk = () => {
        sendForm.validateFields()
            .then((async (values) => {
                setIsModalOpen(false);
                Modal.confirm({
                    title: '提示',
                    width: 480,
                    icon: <ExclamationCircleOutlined/>,
                    content: '发送任务后无法撤回，是否确认发送？',
                    centered: true,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        const res = await sendAgendaConfirmTaskApi({
                            ...values,
                            conferenceId
                        });
                        if (res && res.status_code === 200) {
                            message.success('发送成功，您也可线下告知嘉宾回到[小程序-我的项目]中查看任务并处理');
                            sendForm.resetFields();
                            getList();
                            if (!!conferenceId) {
                                getConferenceInfo(conferenceId);
                                getLaborAuditFlows();
                            }
                        }
                    }
                });
            }));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const edit = record => {
        laborFeeForm.setFieldsValue({
            laborFeeAmount: record.laborFeeAmount || undefined,
            paymentMethod: record.paymentMethod || 1,
            costBearer: record.costBearer || 1,
        });

        setEditingKey(record.expertId);

        dispatch(setCursor(record.expertId));
    };

    // 更新劳务费、付款信息
    const updateLaborFee = (record, type) => {
        laborFeeForm.validateFields()
            .then(async values => {
                if (values.paymentMethod === 3 && parseInt(values.laborFeeAmount) !== 0) {
                    return message.warning('税后劳务费和付款方式不符，无法保存');
                }
                const res = await updateLaborFeeApi({
                    projectCode: 'YHY',
                    projectId: conference?.id,
                    id: record.id || undefined,
                    expertId: record.expertId,
                    laborFeeAmount: (values.laborFeeAmount || values.laborFeeAmount === 0) ? values.laborFeeAmount : record.laborFeeAmount,
                    paymentMethod: values.paymentMethod,
                    costBearer: values.costBearer,
                });
                if (res.status_code === 200) {
                    message.success(`${type === 1 ? '劳务费保存成功' : '付款信息修改成功'}`);
                    setEditingKey('');

                    getAmountStat();
                    getList();

                    if (!!conferenceId) {
                        getConferenceInfo(conferenceId);
                        getLaborAuditFlows();
                    }
                }
            })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);

                let errors = [];
                errorInfo?.errorFields?.forEach(f => {
                    f.errors.forEach(e => errors.push(e));
                });

                if (errors.length) {
                    message.error(errors.join('；') + '！', 5);
                }
            });
    }

    const save = (record, type) => {
        if (type === 1) {
            updateLaborFee(record, type);
        } else {
            Modal.confirm({
                title: '提示',
                content: '确认修改付款信息',
                cancelText: '取消',
                okText: '确认',
                centered: true,
                closable: true,
                onOk: () => {
                    updateLaborFee(record, type);
                }
            });
        }
    };

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({ id: conferenceId });
        if (res.status_code === 200) {
            setConference(res.data);

            if (!!res?.data?.laborFeeConfirmedAt) {
                const res = await getAllStaffs();
                if (res.status_code === 200) {
                    setStaffs(res.data);
                }
            }
        }
    };

    // 劳务费批次列表
    const laborFeeRequestBatchesList = async () => {
        const res = await laborFeeRequestBatchesListApi({ projectCode: 'YHY', projectId: conferenceId });
        if (res.status_code === 200) {
            setBatchNoList(res.data);
        }
    }

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
            laborFeeRequestBatchesList()
        }
    }, []);

    const onFormFinish = values => {
        let newValues = { ...values };
        dispatch(setFilter({
            ...filter,
            ...newValues,
            page: 1,
        }));
    };
    const onReset = () => {
        filterForm.resetFields();

        dispatch(setFilter({
            ...filter,
            ...filterForm.getFieldsValue(),
            page: 1,
        }));
    };

    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    // 劳务费确认
    const laborConfirm = () => {
        const confirmContent = hasNullRecord
            ? <>
                <p>共{statisticalAmount?.realTotal}位嘉宾。<br/>其中{nullItems.join('，')}。</p>
                <p>是否确认提交？</p>
            </>
            : <span>确认后不允许修改，并提交财务部门审批</span>;

        Modal.confirm({
            title: '实发劳务费审批',
            width: 480,
            icon: <ExclamationCircleOutlined/>,
            content: confirmContent,
            centered: true,
            okText: '同意',
            cancelText: '取消',
            onOk: async () => {
                if (hasNullRecord) {
                    Modal.confirm({
                        title: '实发劳务费审批',
                        icon: <ExclamationCircleOutlined/>,
                        content: `确认后不允许修改，并提交财务部门审批`,
                        centered: true,
                        okText: '确认',
                        cancelText: '取消',
                        onOk: async () => {
                            const res = await individualTaxConfirmedApi({
                                id: params.id,
                                status: 1
                            });
                            if (res.status_code === 200) {
                                message.success('实发劳务费已提交财务部门审批');

                                getList();
                                if (!!conferenceId) {
                                    getConferenceInfo(conferenceId);
                                    getLaborAuditFlows();
                                }
                            }
                        }
                    });
                } else {
                    const res = await individualTaxConfirmedApi({
                        id: params.id,
                        status: 1
                    });
                    if (res.status_code === 200) {
                        message.success('实发劳务费已提交财务部门审批');

                        getList();
                        if (!!conferenceId) {
                            getConferenceInfo(conferenceId);
                            getLaborAuditFlows();
                        }
                    }
                }

            }
        });
    };

    // 查看审核信息
    const viewLaborConfirmInfo = () => {
        const confirmedBy = staffs.find(s => s.phone === conference?.laborFeeConfirmedBy)?.name || '';
        const confirmDateTime = convertDateTimeToLocalText(conference?.laborFeeConfirmedAt) || '';
        let totalNeedLaborFee = 0;
        if (statisticalAmount?.realTotal && (statisticalAmount?.LaborFeeNullSum || statisticalAmount?.LaborFeeNullSum === 0) && (statisticalAmount?.LaborFeeZeroSum || statisticalAmount?.LaborFeeZeroSum === 0)) {
            totalNeedLaborFee = statisticalAmount.realTotal - statisticalAmount.LaborFeeNullSum - statisticalAmount.LaborFeeZeroSum;
        }

        Modal.confirm({
            title: '实发劳务费审批结果',
            width: 600,
            centered: true,
            content: (<div className="labor-fee-confirm-info-box">
                <div className="labor-fee-project" style={{ textAlign: 'center', fontSize: 18, fontWeight: 600, marginBottom: 10 }}>
                    {conference?.name}
                </div>
                <div className="labor-fee-confirm-info">
                    【{conference?.name}】劳务费已由【{confirmedBy}】于【{confirmDateTime}】审核完毕，共{totalNeedLaborFee}位专家需要支付劳务费用，实发劳务费总计：{statisticalAmount?.LaborFeeSum}元。
                </div>
                <div className="labor-fee-confirm-sig" style={{ paddingLeft: 200, marginTop: 20 }}>
                    <div className="sig-name">审批人： {confirmedBy}</div>
                    <div className="confirm-datetime">审批时间：{confirmDateTime}</div>
                </div>
            </div>),
            okText: '我已知晓',
            cancelText: '重新审批',
            onOk() {
            },
            onCancel() {
                // 实发劳务费已审核，
                const { laborFeeConfirmedAt, laborFeeRequestExamineStatus } = conference;
                if (!!laborFeeConfirmedAt && (laborFeeRequestExamineStatus !== 1)) {
                    // 1. 未提交财务复核时，可重新审批
                    // 2. 已提交财务复核，但财务没复核时，可重新审批
                    // 增 已提交财务复核，但财务复核驳回时，可重新审批
                    Modal.confirm({
                        title: '重新审批',
                        centered: true,
                        content: laborFeeRequestExamineStatus === null
                            ? '已审批实发劳务费结果将被作废，确认重新审批？'
                            : '财务部门复核中，是否撤销财务部门复核并重新审批？',
                        okText: '确定',
                        onOk: async () => {
                            const res = await resetLaborFeeConfirmation({ id: conferenceId, status: 0 });
                            if (res.status_code === 200) {
                                switch (res.data) { // 1-修改成功 2-修改失败 3-会议信息不存在 4-不允许重置
                                    case 1:
                                        message.success('实发劳务费重置成功');
                                        getConferenceInfo(conferenceId);
                                        getLaborAuditFlows();
                                        break;
                                    case 2:
                                        message.error('实发劳务费重置失败');
                                        break;
                                    case 3:
                                        message.error('会议信息不存在');
                                        break;
                                    case 4:
                                        message.error('不允许重置');
                                        break;
                                    default:
                                        break;
                                }
                            }
                        },
                        cancelText: '取消',
                        onCancel() {
                            // TODO
                        }
                    });
                } else if (!!laborFeeConfirmedAt && laborFeeRequestExamineStatus === 1) {
                    // 3. 已提交财务复核，且财务已复核通过时，不可重新审批
                    Modal.info({
                        title: '提示信息',
                        centered: true,
                        content: '财务部门已复核，请联系财务部门操作退回。',
                        okText: '知道了',
                        onOk() {
                        }
                    });
                }
            }
        });
    };

    // 含税劳务费财务审批中，撤销审批申请
    const individualConfirmZero = () => {
        Modal.confirm({
            title: '财务部门复核申请撤销',
            icon: <ExclamationCircleOutlined/>,
            content: `撤销申请会影响财务复核，确认撤销？`,
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await individualTaxConfirmedApi({
                    id: params.id,
                    status: 0
                });
                if (res.status_code === 200) {
                    if (res.data) {
                        message.success('财务部门复核申请撤销成功！');
                    } else {
                        message.warning('财务部门已复核，撤销失败！');
                    }

                    getList();
                    if (!!conferenceId) {
                        getConferenceInfo(conferenceId);
                        getLaborAuditFlows();
                    }
                }
            }
        });
    };

    // 含税劳务费请款审批不同意，查看原因重新提交
    const individualConfirmTwo = async () => {
        const res = await examineReasonsApi({ projectId: conferenceId });
        if (res.status_code === 200) {
            Modal.info({
                title: '不同意原因',
                className: "labor-reject-reason-modal",
                icon: null,
                closable: true,
                content: <>
                    <div className="labor-reject-reason">
                        {
                            res.data && !!res.data.length && res.data.map((reason, index) => (
                                <div className="labor-reject-reason-item" key={index}><span>{reason.createdAt}</span><span>{reason.refuseReason}</span></div>
                            ))
                        }
                    </div>
                </>,
                centered: true,
                okText: '已修改重新提交',
                onOk: async () => {
                    const res = await individualTaxConfirmedApi({
                        id: params.id,
                        status: 1
                    });
                    if (res.status_code === 200) {
                        message.success('重新提交成功');

                        getList();
                        if (!!conferenceId) {
                            getConferenceInfo(conferenceId);
                            getLaborAuditFlows();
                        }
                    }
                }
            });
        }
    };

    // 含税劳务费请款审批结果
    const viewIndividualConfirmInfo = () => {
        setOpenIndividualConfirmModal(true);
    };

    // 劳务费列表
    const getList = async () => {
        setLoading(false);
        let newValues = { ...filter };
        const res = await laborFeeListApi({
            ...newValues,
            projectCode: 'YHY',
            projectId: conferenceId,
        });
        if (res.status_code === 200) {
            setData(res?.data?.list || []);
            setTotal(res?.data?.totalCount || 0);
        }
        setLoading(false);
    };


    // 劳务费和个税统计金额
    const getAmountStat = async () => {
        const res = await statisticalAmountApi({ projectCode: 'YHY', projectId: conferenceId });
        if (res.status_code === 200) {
            const amountStat = res?.data || null;
            setStatisticalAmount(amountStat);

            let existNull = false; // 存在未填写的 劳务费、银行账号或身份证号
            let existTaxNull = false; // 存在未填写的付款方式
            let items = [];
            if (!!amountStat?.LaborFeeNullSum) {
                existNull = true;
                items.push(`${amountStat?.LaborFeeNullSum}位劳务费未填写`);
            }
            if (!!amountStat?.bankNoNullSum) {
                existNull = true;
                items.push(`${amountStat?.bankNoNullSum}位无银行账号`);
            }
            if (!!amountStat?.idCardNullSum) {
                existNull = true;
                items.push(`${amountStat?.idCardNullSum}位无身份证号码`);
            }
            if (!!amountStat?.IndividualTaxNullSum) {
                existTaxNull = true;
            }

            setHasNullRecord(existNull);
            setHasNullTax(existTaxNull);
            setNullItems(items);
        }
    };

    // 发送确认实际日程任务
    const sendAgendaTask = () => {
        setIsModalOpen(true);
    };

    const comfirmScheduleAction = (expertId) => {
        setSelectExpertId(expertId);
        setOpenConfirmScheduleModal(true);
    };
    const confirmBankInfoAction = (expertId) => {
        setSelectExpertId(expertId);
        setOpenConfirmBankInfoModal(true);
    };

    useEffect(() => {
        let newFilter = { ...filter };
        filterForm.setFieldsValue(newFilter);
        getList();
    }, [filter]);

    useEffect(() => {
        getAmountStat();
    }, []);

    const formatCurrency = value => {
        let result = '';

        const f = parseFloat(value) || 0.00;
        const parts = f.toFixed(2).split('.');
        result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

        return result;
    };

    // 劳务费请款审批申请
    const laborPaymentRequest = () => {
        getExpertsList();
    };
    // 打开劳务费申请界面
    const laborPaymentListOpen = () => {
        setOpenLaborFeeDrawer(true)
    };

    // 获取专家证件类型
    const getIdcardType = (cardType) => {
        const type = parseInt(cardType);
        if (type === 2) return '港澳居民来往内地通行证';
        if (type === 3) return '台湾居民来往大陆通行证';
        if (type === 4) return '外国人永久居留身份证';
        if (type === 5) return '护照';
        return null;
    };

    const toggleAgendaTasks = guestAgendaInfo => {
        setData(data.map(item => {
            if (item.id === guestAgendaInfo.id) {
                return { ...item, expanded: !item.expanded };
            } else {
                return item;
            }
        }));
    };

    let defaultColumns = [
        { title: '姓名', width: 100, dataIndex: 'memUsername', fixed: 'left' },
        { title: '手机号', dataIndex: 'memMobile', width: 120 },
        {
            title: '单位及职务',
            dataIndex: 'unitPost',
            width: 180,
            // align: 'center',
            render: (_, row) => {
                if (row.workExpList && row.workExpList.length) {
                    return <>
                        {row.workExpList.map((item, idx) => {
                            return <div key={`w_${item.id}`}>
                                {row.workExpList.length > 1 && <span>{idx + 1}. </span>}
                                {!!item.unitName && <span>{item.unitName}</span>}
                                {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                {!!item.unitPost && <span>{item.unitPost}</span>}
                            </div>
                        })}
                    </>
                } else {
                    return '—';
                }
            },
        },
        {
            title: '实际日程',
            dataIndex: 'agendasList',
            width: 400,
            render: (_, record) => {
                if (record.agendasList && record.agendasList.length) {
                    return <>
                        <ul className="task-list">
                            {record.agendasList.map((item, idx) => {
                                return (
                                    <li key={idx} style={{ display: idx !== 0 && !record.expanded ? 'none' : '' }}>
                                        <span style={{ marginRight: 4 }}>{`${item.conferenceHallName} / ${item.title}`}</span>
                                        <Tag color="blue">{item?.taskName}</Tag>
                                        {item.invitationAccepted === 0 && <Text type="secondary">未反馈</Text>}
                                        {item.invitationAccepted === 1 && <Text type="success">已确认</Text>}
                                        {item.invitationAccepted === 2 && <Tooltip placement="top" trigger="click" title={item?.refusalInfo}><span style={{ color: '#F00', textDecoration: 'underline', cursor: 'pointer' }}>已拒绝</span></Tooltip>}
                                        {item.invitationAccepted === 3 && <Text type="warning">待确认</Text>}
                                        <br/>
                                        <span className="task-time">{convertDateTimeRangeToLocalText(item.startTime, item.endTime, 0)}</span>
                                    </li>
                                );
                            })}
                        </ul>

                        {record.agendasList.length > 1 && (
                            <div
                                className="toggle-task-expansion"
                                style={{ color: '#1890ff', cursor: 'pointer', fontSize: 12 }}
                                onClick={() => toggleAgendaTasks(record)}
                            >
                                <span className="anticon" style={{ transform: !!record?.expanded ? 'rotate(180deg)' : 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16" style={{ width: 12, height: 12 }}>
                                      <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659"/>
                                    </svg>
                                </span>
                                <span>{!!record?.expanded ? '收起' : `展开${record.agendasList.length}个任务`}</span>
                            </div>
                        )}
                    </>;
                } else {
                    return <Text type="secondary">未安排任务</Text>;
                }
            },
        },
        {
            title: "签到时间",
            width: 200,
            dataIndex: "signTime",
            render: (_, record) => (<>
                    <div>{record.signTime ? moment(record.signTime).format('YYYY-MM-DD HH:mm:ss') : <Text type="secondary">&ndash;</Text>}</div>
                    {record.signTime && <div>{record.checkType === 1 && '本人签到'}</div>}
                    {record.signTime && <div>{record.checkType === 2 && <Text type="danger">代签</Text>}</div>}
                </>
            )
        },
        {
            title: '实发劳务费(元)',
            dataIndex: 'laborFeeAmount',
            width: 130,
            editable: !conference?.laborFeeConfirmedAt,
            align: 'right',
            render: (_, record) => record.laborFeeAmount !== null
                ? <span>&yen;{`${formatCurrency(record.laborFeeAmount)}`}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款方式',
            dataIndex: 'paymentMethod',
            width: 150,
            editable: true,
            align: 'center',
            render: (_, record) => {
                const cur = laborFeePaymentMethods.find(m => m.value === record.paymentMethod);
                if (cur) {
                    if (cur.value === 3) {
                        return <Text type="warning">{cur?.label}</Text>;
                    }
                    return cur?.label;
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 140,
            editable: true,
            align: 'center',
            render: (_, record) => laborFeeCostBearers.find(b => b.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>
        },
        {
            title: '相关税费(元)',
            dataIndex: 'individualTaxAmount',
            width: 120,
            align: 'right',
            render: (_, record) => {
                if (record.laborFeeAmount === 0) {
                    return <span>&yen;0.00</span>;
                }
                if (!!record.laborFeeAmount && !!record.paymentMethod) {
                    return !!record.individualTaxAmount
                        ? <span>&yen;{`${formatCurrency(record.individualTaxAmount)}`}</span>
                        : <span>&yen;0.00</span>
                }
                return <Text type="secondary">&ndash;</Text>
            }
        },
        {
            title: '含税劳务费(元)',
            dataIndex: 'sumOfLaborFeeCast',
            width: 130,
            align: 'right',
            render: (_, record) => {
                if (record.laborFeeAmount === 0) {
                    return <span>&yen;0.00</span>;
                }
                if (!!record.laborFeeAmount) {
                    let laborFeeAmount = parseFloat(record.laborFeeAmount) || 0.00;
                    let individualTaxAmount = parseFloat(record.individualTaxAmount) || 0.00;

                    return <span>&yen;{`${formatCurrency(laborFeeAmount + individualTaxAmount)}`}</span>;
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '收款信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {(record.idcardType === 1 || !record.idcardType) && <>
                    <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <div>
                        {!!conference?.agendaConfirmContactsName
                            ? (record.laborInfoConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>)
                            : <Text type="secondary">&ndash;</Text>}
                    </div>
                </>
                }
                {record.idcardType > 1 && <>
                    <span>证件类型：{getIdcardType(record.idcardType) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>国籍：{record.nationality || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生日期：{(!!record.birthday && moment(record.birthday).format('YYYY-MM-DD')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    <div>
                        {!!conference?.agendaConfirmContactsName
                            ? (record.laborInfoConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>)
                            : <Text type="secondary">&ndash;</Text>}
                    </div>
                </>
                }
            </>),
        },
        {
            title: '付款申请',
            dataIndex: 'createdAt',
            width: 200,
            align: 'center',
            render: (_, record) => !!record.createdAt ?
                <>
                    <div>{convertDateTimeToLocalText(record.createdAt)}</div>
                    <div>
                        {
                            record.examinedStatus !== null && <>
                                {parseInt(record.examinedStatus) === 0 && <Text type="warning">审批中</Text>}
                                {parseInt(record.examinedStatus) === 1 && <Text type="success">审批通过</Text>}
                                {parseInt(record.examinedStatus) === 2 && <Text type="danger">审批拒绝</Text>}
                            </>
                        }
                    </div>
                </> :
                <>
                    <Text type="secondary">&ndash;</Text>
                    <div>
                        {
                            record.examinedStatus !== null && <>
                                {parseInt(record.examinedStatus) === 0 && <Text type="warning">审批中</Text>}
                                {parseInt(record.examinedStatus) === 1 && <Text type="success">审批通过</Text>}
                                {parseInt(record.examinedStatus) === 2 && <Text type="danger">审批拒绝</Text>}
                            </>
                        }
                    </div>
                </>
        },
        {
            title: '付款日期',
            dataIndex: 'payedAt',
            width: 150,
            align: 'center',
            render: (_, record) => record.payedAt ? moment(record.payedAt).format('YYYY年M月D日') : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款凭证',
            dataIndex: 'paymentCert',
            width: 150,
            align: 'center',
            render: (_, record) => {
                if (record.paymentCert) {
                    return <Image width={100} src={prepareBCOSSUrl(record.paymentCert)}/>
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '付款通知',
            dataIndex: 'noticeSentAt',
            width: 200,
            align: 'center',
            render: (_, record) => <>
                {!!record.noticeSentAt
                    ? convertDateTimeToLocalText(record.noticeSentAt)
                    : <Text type="secondary">&ndash;</Text>
                }
                <div>
                    {record.noticeViewed !== null && <>
                        {record.noticeViewed === 0 && <Text type="danger">未查看</Text>}
                        {record.noticeViewed === 1 && <Text type="success">已查看</Text>}
                    </>}
                </div>
            </>
        },
        {
            title: '参会凭证',
            dataIndex: 'joinCert',
            width: 150,
            align: 'center',
            render: (_, record) => {
                if (record.joinCert) {
                    return <Image width={100} src={prepareBCOSSUrl(record.joinCert)}/>
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 300,
            fixed: 'right',
            render: (_, record) => (<>
                {/* {(canConfirmLaborFeeEntry && !!conference?.laborFeeConfirmedAt && !!conference?.individualTaxConfirmedAt) && (
                    <>
                        <Button size="small" type="link" onClick={() => viewCertificate(record)}>查看付款凭证</Button>
                        <Divider type="vertical" />
                    </>
                )} */}
                {canConfirmLaborFeeEntry && (
                    <Button size="small" type="link" onClick={() => uploadConferenceCert(record)}>
                        {record.joinCert ? '修改参会凭证' : '上传参会凭证'}
                    </Button>
                )}

                {(canConfirmLaborFeeEntry && !conference?.laborFeeConfirmedAt && !record.payedAt) &&
                    <>
                        {isEditing(record)
                            ? <span className="operation-btns">
                                <Button size="small" type="link" onClick={() => save(record, 1)}>保存</Button>
                                <Divider type="vertical"/>
                                <Button size="small" type="link" onClick={() => setEditingKey('')}>取消</Button>
                            </span>
                            : <span className="operation-btns">
                                <Button size="small" type="link" disabled={editingKey !== '' || (!!conference?.laborFeeConfirmedAt && !record.laborFeeAmount)} onClick={() => edit(record)}>填写劳务费</Button>
                            </span>
                        }
                    </>
                }

                {(canConfirmLaborFee && !!conference?.laborFeeConfirmedAt && !record.payedAt) &&
                    <>
                        {isEditing(record)
                            ? <span className="operation-btns">
                                <Button size="small" type="link" onClick={() => save(record, 2)}>保存修改信息</Button>
                                <Divider type="vertical"/>
                                <Button size="small" type="link" onClick={() => setEditingKey('')}>取消</Button>
                            </span>
                            : <span className="operation-btns">
                                <Button size="small" type="link" disabled={editingKey !== '' || (!!conference?.laborFeeConfirmedAt && !record.laborFeeAmount)} onClick={() => edit(record)}>付款信息修改</Button>
                            </span>
                        }
                    </>
                }

                {record.agendaConfirmed !== 1 && (
                    <Button size="small" type="link" onClick={() => comfirmScheduleAction(record.expertId)}>确认实际日程</Button>
                )}

                {record.laborInfoConfirmed !== 1 && (
                    <Button size="small" type="link" onClick={() => confirmBankInfoAction(record.expertId)}>确认收款信息</Button>
                )}
            </>)
        }
    ];

    const columns = defaultColumns
        .map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
            };
        });

    // 获取含税金额
    const getTotalAmount = (LaborFeeSum, IndividualTaxSum) => {
        if (LaborFeeSum !== null && IndividualTaxSum !== null) {
            return (Number(LaborFeeSum) + Number(IndividualTaxSum)).toFixed(2)
        }
    };

    // 导出劳务费信息
    const exportLaborFee = () => {
        window.location.href = `${config.API_PREFIX}/conference/boss/conferences/projectLaborFeeRecords?id=${params.id}`;
    };

    const checkImgUploadFile = (file, sizeMb) => {
        const typeList = ['image/jpg', 'image/jpeg', 'image/png'];
        const isImage = typeList.includes(file.type) || false;

        if (!isImage) {
            message.error('仅支持 JPG/PNG 图片格式');
            return false;
        }

        const metSize = file.size / 1024 / 1024 < sizeMb;
        if (!metSize) {
            message.error(`图片文件大小限${sizeMb}MB`);
            return false;
        }

        return isImage && metSize;
    };

    const uploadImageFile = (fileInfo, type) => {
        const formData = new FormData();
        formData.append('file', fileInfo.file);
        formData.append('type', 14);

        fileApi(formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(async (res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }

                if (res.status_code === 200) {
                    const url = prepareBCOSSUrl(res.data);
                    if (type === 'joinCert') {
                        setJoinCertList([url]);
                        uploadForm.setFieldValue('joinCert', res.data);
                    }
                }
            })
    };

    // 上传/修改参会凭证
    const uploadConferenceCert = (record) => {
        expertId = record.expertId;
        expertName = record.memUsername;
        if (record.joinCert) {
            setJoinCertList([prepareBCOSSUrl(record.joinCert)]);
        }
        setOpenCertificate(true);
    };

    const handleCancelCertificate = () => {
        setOpenCertificate(false);
        setJoinCertList([]);
        expertId = null;
        uploadForm.resetFields();
    };

    // 删除参会凭证
    const deleteImg = () => {
        setJoinCertList([]);
        uploadForm.setFieldValue('joinCert', '');
    };

    // 保存参会凭证
    const handleAddCertificate = () => {
        uploadForm.validateFields()
            .then(async (values) => {
                const res = await savelaborFeeJoinCertApi({
                    projectId: conferenceId,
                    expertId,
                    joinCert: values.joinCert
                });
                if (res.status_code === 200) {
                    message.success('保存成功');
                    setOpenCertificate(false);
                    getList();

                    setJoinCertList([]);
                    expertId = null;
                    uploadForm.resetFields();
                }
            });
    };

    // 查看付款凭证
    const viewCertificate = (record) => {
        if (!record.paymentCert) return message.warning('财务未上传付款凭证');
        Modal.info({
            icon: null,
            closable: true,
            title: '查看付款凭证',
            width: 600,
            centered: true,
            content: (<div className="labor-certificate">
                {!!record.paymentCert && <Image src={prepareBCOSSUrl(record.paymentCert)}/>}
            </div>),
            okButtonProps: { style: { display: 'none' } }
        });
    };

    // 保存劳务批次申请
    const saveLaborFeeBatchApply = async () => {
        const res = await laborFeeBatchApplyApi({
            projectId: conferenceId,
            expertIds: chooseExpertIds,
            h3yunNo: conference.h3yunNo
        });
        if (res.status_code === 200) {
            message.success('提交成功');
            getList();
        }
    };

    // 待申请专家列表
    const getExpertsList = async () => {
        const res = await laborFeeExpertsApi({
            page: 1,
            pageSize: 10,
            batchNo: 0,
            projectId: conferenceId,
        });
        if (res.status_code === 200) {
            if (res?.data?.list && !!res?.data?.list.length) {
                setOpenChooseExpertModal(true);
            } else {
                message.warning('全部专家已申请过劳务费请款');
            }
        }
    };

    // 付款信息修改记录
    const paymentRecord = async () => {
        const res = await laborFeeAdjustListApi({ projectCode: 'YHY', projectId: conferenceId });
        if (res.status_code === 200) {
            // console.log(res.data);
            if (res.data.length) {
                setPaymentRecordList(res.data);
                setOpenPaymentRecord(true);
            } else {
                message.warning('暂无付款信息修改记录');
            }
        }
    };

    // 获取劳务流程信息
    const getLaborAuditFlows = async() => {
        const res = await laborAuditFlowsApi({conferenceId});
        if (res.status_code === 200) {
            if (res.data && res.data.length) {
                const newData = res.data.map(item => {
                    let newItem = {};
                    newItem.title = getCode(item);
                    newItem.description = getStatus(item);
                    if (item.status === 1) {
                        newItem.icon = <></>;
                    }
                    if (item.status === 2) {
                        newItem.icon = <><ClockCircleFilled style={{color: '#faad14'}} /></>;
                    }
                    if (item.status === 3) {
                        newItem.icon = <><CheckCircleFilled style={{color: '#52c41a'}} /></>;
                    }
                    if (item.code === '财务审批' && item.status === 4) {
                        newItem.icon = <><CloseCircleFilled style={{color: '#ff4d4f'}} /></>;
                    }
                    if (item.code === '付款申请' && item.status === 4) {
                        newItem.icon = <><ClockCircleFilled style={{color: '#faad14'}} /></>;
                    }
                    return newItem;
                });
                setLaborAuditFlows(newData);
            }
        }
    };

    // 审批节点 - 名称样式
    const getCode = (item) => {
        if (item.code === '部门审批') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{color: '#faad14'}}>{item.code}</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>{item.code}</span>;
            }
        }
        if (item.code === '财务审批') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{color: '#faad14'}}>{item.code}</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>{item.code}</span>;
                case 4:
                    return <span style={{color: '#ff4d4f'}}>{item.code}</span>;
            }
        }
        if (item.code === '付款申请') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{color: '#faad14'}}>{item.code}</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>{item.code}</span>;
                case 4:
                    return <span style={{color: '#faad14'}}>{item.code}</span>;
            }
        }
        if (item.code === '付款申请审批') { 
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{color: '#faad14'}}>{item.code}</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>{item.code}</span>;
            }
        }
        if (item.code === '出纳付款') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{color: '#faad14'}}>{item.code}</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>{item.code}</span>;
            }
        }
    }

    // 审批节点状态
    const getStatus = (item) => {
        if (item.code === '部门审批') {
            switch (item.status) {
                case 2:
                    return <span style={{color: '#faad14'}}>待审批
                        <Popover placement="topRight" content="请确认专家劳务信息填写无误，再通知部门负责人审批">
                            <QuestionCircleFilled style={{color: '#1890ff', marginLeft: '3px'}}/>  
                        </Popover>
                    </span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>同意({item.approveBy})</span>;
            }
        }
        if (item.code === '财务审批') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{color: '#faad14'}}>待审批({item.approveBy})</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>同意({item.approveBy})</span>;
                case 4:
                    return <span style={{color: '#ff4d4f'}}>不同意({item.approveBy})</span>;
            }
        }
        if (item.code === '付款申请') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{color: '#faad14'}}>未申请
                        <Popover placement="topRight" content="请选择付款专家">
                            <QuestionCircleFilled style={{color: '#1890ff', marginLeft: '3px'}}/>  
                        </Popover>
                    </span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>全部申请</span>;
                case 4:
                    return <span style={{color: '#faad14'}}>部分申请
                        <Popover placement="topRight" content="有未提交付款申请专家">
                            <QuestionCircleFilled style={{color: '#1890ff', marginLeft: '3px'}}/>  
                        </Popover>
                    </span>;
            }
        }
        if (item.code === '付款申请审批') { 
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{color: '#faad14'}}>待审批({item.approveBy})</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>同意({item.approveBy})</span>;
            }
        }
        if (item.code === '出纳付款') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{color: '#faad14'}}>待付款({item.approveBy})</span>;
                case 3:
                    return <span style={{color: '#52c41a'}}>全部付款({item.approveBy})</span>;
            }
        }
    }

    // 是否拥有劳务费管理权限
    const canConfirmLaborFee = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '劳务费管理员');
        }
        return false;
    }, []);

    // 是否拥有劳务费录入人员权限
    const canConfirmLaborFeeEntry = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '劳务费录入人员');
        }
        return false;
    }, []);

    // 勾选专家
    useEffect(() => {
        if (conference && chooseExpertIds && chooseExpertIds.length) {
            saveLaborFeeBatchApply()
        }
    }, [chooseExpertIds, conference]);

    useEffect(() => {
        getLaborAuditFlows();
    }, []);

    return (
        <div className="labor-fee-manage">
            <div className="page-title">
                <Link to="/conf/conferences" className="back"><LeftOutlined/></Link>
                <span className="current-title">劳务费管理<QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={() => setOpenTips(true)}/></span>
                <span className="current-subtitle">{conference?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="labor-form-filter">
                            <Form form={filterForm} name="control-hooks" {...filterFormItemLayout} onFinish={onFormFinish}>
                                <Row>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="memUsername" label="姓名">
                                            <Input placeholder="请输入专家姓名" allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="memMobile" label="手机号">
                                            <Input placeholder="请输入手机号" allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="hasLaborFee" label="实发劳务费">
                                            <Select placeholder="请选择填写情况" allowClear>
                                                <Select.Option value={1}>需发放劳务</Select.Option>
                                                <Select.Option value={2}>无需发放劳务</Select.Option>
                                                <Select.Option value={0}>未填写</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="hasIndividualTax" label="相关税费">
                                            <Select placeholder="请选择填写情况" allowClear>
                                                <Select.Option value={1}>已填写</Select.Option>
                                                <Select.Option value={2}>未填写</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="agendaConfirmed" label="实际日程确认状态">
                                            <Select placeholder="请选择" allowClear>
                                                <Select.Option value={1}>已确认</Select.Option>
                                                <Select.Option value={2}>未反馈</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="laborInfoConfirmed" label="收款信息确认状态">
                                            <Select placeholder="请选择" allowClear>
                                                <Select.Option value={1}>已确认</Select.Option>
                                                <Select.Option value={2}>未反馈</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="expertInfoState" label="收款信息">
                                            <Select placeholder="请选择" allowClear>
                                                <Select.Option value={1}>完整</Select.Option>
                                                <Select.Option value={0}>不完整</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="batchStatus" label="付款申请状态">
                                            <Select placeholder="请选择" allowClear>
                                                <Select.Option value={0}>未申请</Select.Option>
                                                <Select.Option value={1}>审批中</Select.Option>
                                                <Select.Option value={2}>已通过</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="batchNo" label="付款申请时间">
                                            <Select placeholder="请选择" allowClear>
                                                {
                                                    batchNoList.map(item => {
                                                        return <Select.Option key={item.value} value={item.batchNo}>{convertDateTimeToLocalText(item.createdAt)}</Select.Option>
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col {...filterFormGridLayout}>
                                        <Form.Item name="laborPayStatus" label="付款状态">
                                            <Select placeholder="请选择" allowClear>
                                                <Select.Option value={0}>待付款</Select.Option>
                                                <Select.Option value={1}>已付款</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                        <Space>
                                            <Button type="primary" htmlType="submit">检索</Button>
                                            <Button type="default" onClick={onReset}>重置</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                    </div>


                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Space>
                                {canConfirmLaborFeeEntry && (
                                    <Button
                                        type="primary"
                                        disabled={!!conference?.agendaConfirmContactsName}
                                        onClick={sendAgendaTask}
                                    >
                                        {!!conference?.agendaConfirmContactsName && '已'}发送【确认劳务信息】任务
                                    </Button>
                                )}
                                {canConfirmLaborFee && !conference?.laborFeeConfirmedAt && (
                                    <Button type="primary" onClick={laborConfirm}>实发劳务费审批</Button>
                                )}
                                {canConfirmLaborFee && !!conference?.laborFeeConfirmedAt && (
                                    <Button type="primary" onClick={viewLaborConfirmInfo}>实发劳务费审批结果</Button>
                                )}
                                {canConfirmLaborFee && !!conference?.laborFeeConfirmedAt && (
                                    <>
                                        {conference?.laborFeeRequestExamineStatus === 0 && <Button type="primary" disabled onClick={individualConfirmZero}>财务部门审批中</Button>}
                                        {conference?.laborFeeRequestExamineStatus === 1 && <Button type="primary" onClick={viewIndividualConfirmInfo}>财务审批同意，查看结果</Button>}
                                        {conference?.laborFeeRequestExamineStatus === 2 && <Button type="primary" onClick={individualConfirmTwo}>财务审批不同意~查看结果</Button>}
                                    </>
                                )}
                                {/*{canConfirmLaborFeeEntry && conference?.laborFeeRequestExamineStatus === 1 && (*/}
                                {/*    <Button type="primary" onClick={laborPaymentRequest}>劳务费请款审批申请</Button>*/}
                                {/*)}*/}
                                {canConfirmLaborFeeEntry && conference?.laborFeeRequestExamineStatus === 1 && (
                                    <Button type="primary" onClick={laborPaymentListOpen}>提交付款申请</Button>
                                )}
                            </Space>
                        </div>
                        <div className="sub-tools">
                            <Space>
                                {canConfirmLaborFee && <Button type="primary" onClick={paymentRecord}>付款信息修改记录</Button>}
                            </Space>
                        </div>
                    </div>

                    {!_.isEmpty(statisticalAmount) && (
                        <Alert
                            type="info"
                            style={{ marginBottom: 16, fontSize: 12 }}
                            message={<Row>
                                <Col className="summary-ammount" span={24}>
                                    <span style={{ fontSize: 12 }}>实发劳务费总计：&yen;{formatCurrency(statisticalAmount?.LaborFeeSum)}元，相关税费总计：&yen;{formatCurrency(statisticalAmount?.IndividualTaxSum)}元，含税劳务费总计：&yen;{formatCurrency(getTotalAmount(statisticalAmount?.LaborFeeSum, statisticalAmount?.IndividualTaxSum))}元，共{statisticalAmount?.expertSum}位专家，{statisticalAmount?.expertPaySum}位专家已付款</span>
                                </Col>
                            </Row>}
                        />
                    )}

                    <Form form={laborFeeForm} component={false}>
                        <Table
                            size="small"
                            components={{ body: { cell: EditableCell } }}
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            rowClassName={row => row.expertId === cursor ? 'store-cursor' : ''}
                            scroll={{ x: 1500 }}
                            pagination={{
                                total,
                                showTotal: () => `共${total}条`,
                                current: filter.page,
                                pageSize: filter.pageSize,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange: onChangePage,
                                onShowSizeChange,
                            }}
                        />
                    </Form>

                    <Modal
                        title='提示'
                        open={isModalOpen}
                        onOk={handleOk}
                        okText="发送"
                        centered
                        width={600}
                        onCancel={handleCancel}
                        className="sendForm"
                    >
                        <Form
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 19,
                            }}
                            name="sendForm"
                            form={sendForm}
                            size="large"
                            autoComplete="off"
                        >
                            <div style={{ marginBottom: '40px' }}>说明：点击“发送【确认劳务信息】任务”后，将给当前列表中所有嘉宾发送实际日程的确认任务，嘉宾可在【我的项目】中收到1条【确认劳务信息】的任务，便于嘉宾核实实际日程（嘉宾可点击“我有疑问”或“确认实际日程”）。您可在下方添加联系人姓名和联系电话，便于嘉宾点击“我有疑问”后弹窗展示并联络该联系人，以便双方校对核实实际日程是否有出入。</div>
                            <Form.Item name="agendaConfirmContactsName" label="联系人姓名" rules={[{ required: true }]}>
                                <Input placeholder="请输入联系人姓名" maxLength={30}/>
                            </Form.Item>
                            <Form.Item
                                name="agendaConfirmContactsPhone"
                                label="联系电话"
                                rules={[
                                    { required: true, message: '请输入联系电话' },
                                    { pattern: /^1\d{10}$/, message: '请输入正确的联系电话' }
                                ]}>
                                <Input placeholder="请输入联系电话" maxLength={11}/>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        open={openTips}
                        centered
                        width={800}
                        onCancel={() => setOpenTips(false)}
                        footer={null}
                        className="laborTipsModal"
                    >
                        <div>
                            <p>流程简述：</p>
                            <p>会议结束后，根据执行情况在“日程管理”中修正为实际发生的日程 → 进入该会议“嘉宾管理”，选中实际日程有修正的嘉宾对应的“嘉宾任务管理”，点击“确认”进行复核 → 选中该会议“劳务费管理”，查看嘉宾实际日程 →发送“确认实际日程”任务给嘉宾→嘉宾在健康界小程序收到任务通知，点击确认 → 项目经理填写税后劳务费+个人所得税 →内部领导审核税后劳务费→ 财务审核含税劳务总额→ 财务打款→ 线下通知嘉宾。</p>
                            <p style={{ marginTop: '20px' }}>详细步骤：</p>
                            <p>1. 会议结束后，如嘉宾实际发生日程与此前录入日程存在出入，需要在“日程管理”按照VIP嘉宾实际出席日程调整日程模块，并在“嘉宾管理”选中嘉宾，点击“嘉宾任务管理”并“确认”。如实际发生日程无调整可忽略本步骤。</p>
                            <p>2. 点击“发送【确认劳务信息】任务”，给列表中所有嘉宾发送确认实际日程的任务，嘉宾将在健康界小程序“我的项目”看到该任务并可签字、确认、填写劳务信息；</p>
                            <p>3. 根据嘉宾“日程确认状态”，如嘉宾已确认，可填写“税后劳务费”；根据实际需要填写“付款方式+费用承担主体”；</p>
                            <p>4. 由经过系统授权的管理者点击“实发劳务费审批”，审定后“日程管理”模块锁定，不再允许修改，“税后劳务费/元”亦将锁定，不再允许修改；</p>
                            <p>5. 由经过系统授权的财务人员点击“含税劳务费请款审批申请”，审定后“付款方式+费用承担主体”不再允许修改；</p>
                            <p>6. 财务线下打款（暂未上线“线上通知嘉宾打款”）。</p>
                        </div>
                    </Modal>

                    <Modal
                        title="上传参会凭证"
                        open={openCertificate}
                        destroyOnClose={true}
                        centered
                        width={600}
                        maskClosable={false}
                        onCancel={handleCancelCertificate}
                        footer={
                            <div style={{ textAlign: 'right' }}>
                                <Space>
                                    <Button onClick={handleCancelCertificate}>取消</Button>
                                    <Button type="primary" htmlType="submit" onClick={handleAddCertificate}>确定</Button>
                                </Space>
                            </div>
                        }
                    >
                        <Form
                            form={uploadForm}
                            layout="vertical"
                            {...pageFormItemLayout}
                        >
                            <Form.Item
                                name="userNmae"
                                label="专家姓名"
                            >
                                <span>{expertName}</span>
                            </Form.Item>
                            <Form.Item
                                name="joinCert"
                                label="参会凭证"
                                help="支持JPG/PNG格式，文件大小不超过5MB"
                            >
                                {joinCertList && joinCertList.length > 0 && (
                                    <div className="labor-certificate" style={{ marginBottom: 8 }}>
                                        <Image width={200} src={joinCertList[0]}/>
                                        <span onClick={deleteImg} className="close-img"><CloseOutlined/></span>
                                    </div>
                                )}

                                <Upload
                                    beforeUpload={file => checkImgUploadFile(file, 5)}
                                    fileList={[]}
                                    customRequest={fileInfo => uploadImageFile(fileInfo, 'joinCert')}
                                >
                                    <Button
                                        icon={<span className="anticon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                            </svg>
                                        </span>}
                                    >
                                        {joinCertList && joinCertList.length > 0 ? '更改凭证' : '上传凭证'}
                                    </Button>
                                </Upload>
                            </Form.Item>

                        </Form>

                    </Modal>

                    <PaymentRecordModal
                        openPaymentRecord={openPaymentRecord}
                        setOpenPaymentRecord={setOpenPaymentRecord}
                        paymentRecordList={paymentRecordList}
                    />

                    <ChooseExpertModal
                        openChooseExpertModal={openChooseExpertModal}
                        setOpenChooseExpertModal={setOpenChooseExpertModal}
                        chooseExpertIds={chooseExpertIds}
                        setChooseExpertIds={setChooseExpertIds}
                    />

                    <ConfirmScheduleModal
                        openConfirmScheduleModal={openConfirmScheduleModal}
                        setOpenConfirmScheduleModal={setOpenConfirmScheduleModal}
                        expertId={selectExpertId}
                        updateList={getList}
                    />

                    <ConfirmBankInfoModal
                        openConfirmBankInfoModal={openConfirmBankInfoModal}
                        setOpenConfirmBankInfoModal={setOpenConfirmBankInfoModal}
                        expertId={selectExpertId}
                        updateList={getList}
                    />

                    <IndividualConfirmResult
                        openIndividualConfirmModal={openIndividualConfirmModal}
                        setOpenIndividualConfirmModal={setOpenIndividualConfirmModal}
                        staffs={staffs}
                        conference={conference}
                        statisticalAmount={statisticalAmount}
                    />
                    <LaborFeeRequestDrawr
                        isOpen={openLaborFeeDrawer}
                        setIsOpen={setOpenLaborFeeDrawer}
                        conference={conference}
                    ></LaborFeeRequestDrawr>
                </div>
            </Content>

            <div className='labor-step-container'>
                <div className='step-title'>项目劳务进度：</div>
                <Steps
                    current={5}
                    items={laborAuditFlows}
                />
            </div>
        </div>
    );
}

export default ConferenceLaborFeeRecords;
