import { configureStore } from '@reduxjs/toolkit';

import projectSlice from './components/MedProject/store/projectSlice';
import medProjectSlice from './components/MedProject/store/medProjectSlice';
import conferenceSlice from './components/Conference/store/conferenceSlice';
import selectionSlice from './components/Selection/store/selectionSlice';
import invitationSlice from './components/Invitation/store/invitationSlice';
import expertSlice from './components/Expert/ExpertOverview/store/expertSlice';
import researchHospitalSlice from './components/Kb/ResearchHospital/store/researchHospitalSlice';
import financeSlice from './components/Finance/store/financeSlice';
import medconnectSlice from "./components/Medconnect/store/medconnectSlice";

const localStorageMiddleware = ({ getState }) => {
    // console.log('localStorageMiddleware');
    return next => action => {
        const result = next(action);
        sessionStorage.setItem('applicationState', JSON.stringify(getState()));
        return result;
    };
};

const preloadStoreFromLocalStorage = () => {
    // console.log('preloadStoreFromLocalStorage');
    if (sessionStorage.getItem('applicationState') !== null) {
        return JSON.parse(sessionStorage.getItem('applicationState'));
    }
};

const rootReducer = {
    project: projectSlice.reducer,
    medProject: medProjectSlice.reducer, // 原关联项目用医项目
    conference: conferenceSlice.reducer,
    selection: selectionSlice.reducer,
    invitation: invitationSlice.reducer,
    expert: expertSlice.reducer,
    researchHospital: researchHospitalSlice.reducer,
    finance: financeSlice.reducer,
    medconnect: medconnectSlice.reducer,
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: preloadStoreFromLocalStorage(),
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
