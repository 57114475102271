import {useLocation, useNavigate} from "react-router-dom";
import React, {Fragment, useState, useCallback, useEffect} from "react";
import {Image, Layout, Radio, Tabs, Tag, Timeline,Space, Tree, Table, message, Switch, Upload, Button,Input,Select} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {get, put,post} from "@/core/request";
import {fullPagerConfig} from "@/utils/property";
import './FormalOrganizationsInfo.less'
import FormalOrganizationsInfoQueryFrom from "./FormalOrganizationsInfoQueryFrom"
import {getProductApi,getClassPackageApi} from '@services/HealthClass';

export default function FormalOrganizationsInfo() {

    const {Content} = Layout;
    const {TabPane} = Tabs;
    let navigate = useNavigate();
    const {state} = useLocation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filterObj, setFilterObj] = useState({});
    const [infoData, setInfoData] = useState({});
    const [contractData, setContractData] = useState({});
    const [treeData, setTreeData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState("");
    const [tabledata, setTableData] = useState({});
    const [adminTableData, setAdminTableData] = useState({});
    const [departmentId, setDepartmentId] = useState("");
    const [logsData, setlogsData] = useState([]);
    const [imgFile, setImgFile] = useState(null);
    const [inputValue,setInputValue]=useState(null);
    const [fileList,setfileList]=useState([]);

    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [pageSizeTwo, setPageSizeTwo] = useState(10);
    const [currPageTwo, setCurrPageTwo] = useState(1);


    //产品选择
    const [product, setProduct] = useState(undefined);
    const [people, setPeople] = useState(undefined);
    const [productOpinion, setProductOpinion] = useState([]);
    //课程包处理
    const [packages, setPackages] = useState(undefined);
    const [packageRadio,setPackageRadio] = useState(undefined);
    const [packageSelect, setPackageSelect] =useState([])

    // 改变一页有多少数据
    const changePageSize = useCallback((pageSize) => {
        setPageSize(pageSize);
    }, []);
    const changePageSizeTwo = useCallback((pageSizeTwo) => {
        setPageSizeTwo(pageSizeTwo);
    }, []);

    // 改变第几页
    const changeCurrPage = useCallback((page) => {
        setCurrPage(page);
    }, []);
    const changeCurrPageTwo = useCallback((page) => {
        setCurrPageTwo(page);
    }, []);
    //分页数据
    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: "small",
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page);
        },
        current: currPage,
    };

    const paginationPropsTwo = {
        ...fullPagerConfig,
        pageSizeTwo,
        size: "small",
        total: adminTableData.totalCount,
        onShowSizeChange: (currentTwo, pageSizeTwo) => changePageSizeTwo(pageSizeTwo),
        onChange: (page) => {
            changeCurrPageTwo(page);
        },
        currentTwo: currPageTwo,
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const handleFilterFinish = (value) => {
        setFilterObj(value);
        setCurrPage(1);
    }

    const onSelect = (selectedKeys, info) => {
        setSelectedKeys(selectedKeys[0])
    };

    const onCheck = (checkedKeys, info) => {
    };

    const onChange = (key) => {
        if (key == 1) {
            getData()
        } else if (key == 2) {
            getContract()
        } else if (key == 3) {
            getTree()
            getUser()
        } else if (key == 4) {
            getAdmin()
        } else if (key == 6) {
            getLogs()
        }else if (key == 5) {
            setInputValue(state.domain)
            setImgFile(state.logo)
        }
    };

    const getData = (checkList) => {
        get(`/healthclass/boss/organizations/queryById`, {
            id: state.id
        }).then((res) => {
            if (res.status_code === 200) {
                setInfoData(res.data)
            }
        });
    }

    const getContract = () => {
        get(`/healthclass/boss/orgContracts/queryByOrgCode`, {
            code: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setContractData(res.data)
                let check = packages&&packages.filter(item=>item.id!==0)
                if(res.data?.versionId===56){
                    setProduct(56)
                    setPeople(null)
                }else{
                    setProduct(0)
                    setPeople(res.data.versionId)
                }
                console.log(res.data.jkjCourseList);
                if(res.data.jkjCourseList===null){
                    setPackageRadio(0)
                    setPackageSelect([])
                }else{
                    setPackageRadio(1)
                    res.data.jkjCourseList=res.data.jkjCourseList.map(
                        item=>Number(item)
                    )
                    if(res.data.jkjCourseList.length===check.length){
                        setPackageSelect([0])
                    }else{

                        setPackageSelect(res.data.jkjCourseList)
                    }
                }
            }
        });
    }

    const getTree = () => {
        get(`/healthclass/boss/orgDepartments/list-tree`, {
            orgCode: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setTreeData(res.data)
            }
        });
    }

    const getUser = () => {
        get(`/healthclass/boss/orgUsers/list`, {
            page: 1,
            pageSize: 10,
            orgCode: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setTableData(res.data)
            }
        });
    }

    const getAdmin = () => {
        get(`/healthclass/boss/orgAdmins/page`, {
            page: 1,
            pageSize: 10,
            orgCode: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setAdminTableData(res.data)
            }
        });
    }

    const getLogs = () => {
        get(`/healthclass/boss/orgLogs/queryByOrgCode`, {
            code: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setlogsData(res.data)
            }
        });
    }

    const propsImgfile = {

        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        fileList,
        customRequest:info=>{
            const reg = /\.(jpg|jpeg|png)$/i
            var isJpgOrPng = info.file?.name.search(reg)
            const isLt5M = info.file?.size / 1024 / 1024 < 5;
            if (isJpgOrPng === -1) {
                message.error('上传文件格式有误，文件只能是jpg、jpeg、png格式！');
                document.getElementsByClassName("ant-upload-list-item-name");
                setfileList([])
                console.log()
                return ;
            }
            if (!isLt5M) {
                message.error('文件大小不能超过5M！');
                setfileList([])
                return false;
            }
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 8);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/healthclass/file", formData, option).then((res) => {
                if (!res) {
                    message.error('上传附件失败！！')
                    setfileList([])
                    return false;
                }
                if (res.status_code === 200) {
                    setImgFile(res.data)
                    setfileList([info.file])
                    console.log(res.data);
                }
            })

        }

    };

    const onChangeInput=(value)=> {
        setInputValue(value.target.value)
        console.log(inputValue)
    }
    const onSwitchChange = (key, row) => {
        put('/healthclass/boss/orgAdmins/change-status', {
            id: row.id
        }).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message);
                }
                getAdmin()
            }
        );
    }

    const updateButton = () => {
        return () => {
            navigate(`/healthclass/FormalOrgContractsUpdate`, {state: contractData});
        }
    }
    const updateInfoButton =()=>{
        return () => {
            navigate(`/healthclass/FormalOrganizationsInfoUpdate`, {state: infoData});
        }
    }
    const onCancel=()=>{
        navigate('/healthclass/FormalOrganizations');
    }
    const submit=()=>{
        if (inputValue!=null){
            var pattern = /^(?!-)(?!.*--)[A-Za-z0-9][A-Za-z0-9-]{0,18}[A-Za-z0-9]$/
            if (!pattern.test(inputValue)){
                message.warning("请按照规则填写学苑域名！！")
                return
            }
        }
        put("/healthclass/boss/organizations/updateDomain",{
            id: state.id,
            domain:inputValue,
            logo:imgFile
        }).then((res)=>{
                if (res.status_code==200){
                    message.success(res.message);
                    navigate('/healthclass/FormalOrganizations');
                }
            }
        )
    }

    useEffect(() => {
        get(`/healthclass/boss/orgUsers/list`, {
            page: currPage,
            pageSize: pageSize,
            departmentId: selectedKeys,
            name,
            phone,
            orgCode: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setTableData(res.data)
            }
        });
    }, [
        pageSize,
        currPage,
        filterObj,
        selectedKeys
    ])

    useEffect(() => {
        get(`/healthclass/boss/orgAdmins/page`, {
            page: currPageTwo,
            pageSize: pageSizeTwo,
            orgCode: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setAdminTableData(res.data)
            }
        });
    }, [
        currPageTwo,
        pageSizeTwo
    ])

    useEffect(() => {
        getProductApi().then(res=>{
            if (res.status_code==200){
                setProductOpinion(res.data[1].childList);
            }
        })
        getClassPackageApi().then(res=>{
            if (res.status_code==200){
                getData(res.data)
                res.data.unshift({id:0,name:'全部'})
                setPackages(res.data);
            }
        })
        getData()
    }, [state])

    const classNames = ['button'];
    const classTrial = ['button'];
    const limitClassk = ['button'];
    const limitClasss = ['button'];
    if (infoData.type == 1) {
        classNames.push('button')
        classTrial.push('buttonblue')
    } else {
        classNames.push('buttonblue')
        classTrial.push('button')
    }
    if (infoData.limitType == 1) {
        limitClassk.push('button')
        limitClasss.push('buttonblue')
    } else {
        limitClassk.push('buttonblue')
        limitClasss.push('button')
    }
    const {
        name,
        phone
    } = filterObj;

    const columns = [
        {title: "学员ID", dataIndex: "id"},
        {title: "姓名", dataIndex: "name"},
        {title: "手机号", dataIndex: "phone"},
        {title: "科室", dataIndex: "departmentName"},
        {title: "职称", dataIndex: "titleName"},
        {title: "加入时间", dataIndex: "applyAt"},
        {title: "最近登录时间", dataIndex: "lastLoggedinAt"}
    ]
    const adminColumns = [
        {title: "ID", dataIndex: "id"},
        {title: "姓名", dataIndex: "name"},
        {title: "手机号", dataIndex: "phone"},
        {title: "角色", dataIndex: "roleName"},
        {title: "部门", dataIndex: "departmentName"},
        {title: "职称", dataIndex: "titleName"},
        {
            title: "状态", dataIndex: "status", render: (status, row) => (
                <Fragment>
                    <Switch defaultChecked={status === 1 ? true : false} checkedChildren="启用" unCheckedChildren="停用"
                            onChange={(key) => onSwitchChange(key, row)}/>
                </Fragment>)
        }
    ]
    return (
        <div>
            <Fragment>
                <div className="page-title">管理</div>
                <Content className="main-content-box">
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                        {infoData &&
                        <TabPane tab="基本信息" key="1">
                            <div className="content">
                                <div className="content">
                                    <button className="updateButtonyulan" onClick={updateInfoButton()}>编辑信息</button>
                                </div>
                                <span className="icon">所属主体</span>
                                <input className="formalCheckInput" disabled="disabled"
                                       value={infoData.belongsUnitName || ""} onChange={() => {
                                }}/>
                            </div>
                            <div className="title">学苑类型</div>
                            <div className="content">
                                <span className="icon">学苑类型</span>
                                <button className={classNames.join(" ")}>正式</button>
                                <button className={classTrial.join(" ")}>试用</button>
                            </div>
                            <div className="title">学苑信息</div>
                            <div className="content">
                                <span className="icon">学苑名称</span>
                                <input className="formalCheckInput" disabled="disabled" value={infoData.name || ""}
                                       onChange={() => {
                                       }}/>
                            </div>
                            <div className="content">
                                <span className="icon">开放类型</span>
                                <button className={limitClassk.join(" ")}>公开</button>
                                <button className={limitClasss.join(" ")}>私享</button>
                            </div>
                            <div className="content">
                                <span className="icon">学苑封面</span>
                                <div className="image"><Image width={300} height={200} src={infoData.coverUrl || ""}/>
                                </div>
                            </div>
                            <div className="content">
                                <span className="icon nameSpan">学苑介绍</span>
                                <textarea type="text" className="formalInputcontent" disabled="disabled"
                                          value={infoData.introduce || ""}/>
                            </div>
                            <div className="contentTag">
                                <span className="icon">学苑标签</span>
                                <div className="divTags">{infoData.metaTags && infoData.metaTags.map((tagName) => (
                                    <Tag key={tagName.id}>{tagName.name}</Tag>))}</div>
                            </div>
                            <div className="content">
                                <span className="icon nameSpan">申请理由</span>
                                <textarea className="formalInputcontent" disabled="disabled"
                                          value={infoData.applicationReason || ""}/>
                            </div>
                            <div className="title">学苑管理员信息</div>
                            <div className="content">
                                <span className="icon">联系人姓名</span>
                                <input className="formalInputname" value={infoData.adminName || ""} onChange={() => {
                                }}/>
                            </div>
                            <div className="content">
                                <span className="icon">联系人手机</span>
                                <input className="formalInputname" value={infoData.phone || ""} onChange={() => {
                                }}/>
                            </div>
                            {infoData.unitName != null &&
                            <div>
                                <div className="title">个人认证信息</div>
                                <div className="content">
                                    <span className="icon">单位名称</span>
                                    <input className="checkInputname" disabled="disabled" value={infoData.unitName || ""}/>
                                </div>
                                <div className="content">
                                    <span className="icon">证明材料</span>
                                    <div className="image"><Image width={300} height={200} src={infoData.certificate}/>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="classBottom"></div>
                        </TabPane>
                        }
                        <TabPane tab="合同信息" key="2">
                            {contractData &&
                            <div>
                                <div className="content">
                                    <button className="updateButtonyulan" onClick={updateButton()}>编辑合同</button>
                                </div>
                                <div className="content">
                                    <span className="icon">学苑名称</span>
                                    <span className="nametext">{state.name}</span>
                                </div>
                                <div className="content">
                                    <span className="icon">学苑类型</span>
                                    <button className="buttonblue">正式</button>
                                </div>
                                <div className="content"><span className="icon">合同有效期</span>
                                    <span className="contractDatatext">
                                {contractData.startedAt} — {contractData.endedAt}
                                </span>
                                </div>



                                <div className="content">
                                    <span className="icon">产品版本</span>
                                    <span className="radioClass">
                                        <Radio.Group   value={product} className="product">
                                        <Space direction="vertical">
                                        <Radio value={56}><span>基础版</span><span className="basic-people">{'<=30人'}</span></Radio>
                                        <Radio value={0} className="product-choice"
                                        ><div>专业版</div>

                                        </Radio>
                                        </Space>
                                    </Radio.Group>
                                    </span>
                                    {product===0&&
                                        <div className="people-choice">
                                            <span>成员人数：</span>
                                            <Select
                                                className="people-select"
                                                placeholder='请选择'
                                                style={{
                                                    width: 120,
                                                }}
                                                value={people}
                                                fieldNames={{label:'name',value:'id'}}
                                                options={productOpinion}
                                                open={false}
                                            />
                                        </div>
                                    }
                                </div>


                                <div className="content">
                                    <span className="icon">课程授权</span>
                                    <span className="radioClass">
                                <Radio.Group value={contractData.courseAuthorized}>
                                <Radio value={0}>无授权</Radio>
                                <Radio value={1}>授权健康界免费课程</Radio>
                                </Radio.Group>
                                </span>
                                </div>


                                <div className="content">
                                        <span className="icon">课程包授权</span>
                                        <span className="radioClass">
                                            <Radio.Group  value={packageRadio} className="class-authorization">
                                            <Space direction="vertical">
                                            <Radio value={0}><span>无授权</span></Radio>
                                            <Radio value={1} className="product-choice"
                                            ><div>授权课程包</div>

                                            </Radio>
                                            </Space>
                                        </Radio.Group>
                                        </span>
                                        {packageRadio===1&&
                                            <div className="people-choice">
                                                <span>选择课程包授权：</span>
                                                <Select
                                                    mode="multiple"
                                                    placeholder='请选择'
                                                    value={packageSelect}
                                                    style={{
                                                        width: 200,
                                                    }}
                                                    fieldNames={{label:'name',value:'id'}}
                                                    options={packages}
                                                />
                                            </div>
                                        }
                                </div>



                                <div className="content">
                                    <span className="iconVery">赞助商</span>
                                    <span className="sponsortext">
                                {contractData.sponsor}
                                </span>
                                </div>
                                <div className="content">
                                    <span className="icon">电子合同附件</span>
                                    <span className="buttontext">
                                <a href={contractData.attachmentUrl} target="_blank"><button
                                    className="buttonyulan">预览</button></a>
                                        {/*<a href={contractData.attachmentUrl} target="_blank"><button className="buttonyulan">下载附件</button></a>*/}
                                </span>
                                </div>
                                <div className="content">
                                    <span className="icon">OA凭证</span>
                                    <div className="image">
                                        <Image width={300} height={200} src={contractData.oaCertificate}/>
                                    </div>
                                    <span className="uploadButton">
                                {/*<a href={contractData.oaCertificate} download="1.jpg" target="_blank"><button className="buttonyulan">下载</button></a>*/}
                                </span>
                                </div>
                                <div className="classBottom"></div>
                            </div>
                            }
                        </TabPane>

                        <TabPane tab="学员信息" key="3">
                            <div className="classTree">
                                <Tree
                                    checkable
                                    onSelect={onSelect}
                                    onCheck={onCheck}
                                    treeData={treeData}
                                />
                            </div>
                            <div className="contentTab">
                                <div className="filter-form-box"><FormalOrganizationsInfoQueryFrom
                                    handleFilterFinish={handleFilterFinish}/></div>
                                <Table
                                    size="small"
                                    className="formalOrg-table"
                                    columns={columns}
                                    dataSource={tabledata.list}
                                    pagination={paginationProps}
                                    rowKey={(row) => row.id}
                                    scroll={{x: 'max-content'}}
                                />
                            </div>
                            <div className="classBottom"></div>
                        </TabPane>

                        <TabPane tab="管理员信息" key="4">
                            <div className="main-content-body">
                                <Table
                                    className="formalOrg-table"
                                    columns={adminColumns}
                                    dataSource={adminTableData.list}
                                    pagination={paginationPropsTwo}
                                    rowKey={(row) => row.id}
                                    scroll={{x: 'max-content'}}
                                />
                            </div>
                            <div className="classBottom"></div>
                        </TabPane>

                        <TabPane tab="主页信息" key="5">
                            <div className="title">学苑域名</div>
                            <div className="formalContent">
                                <span className="icon">学苑域名</span>
                                <Input type="text" onChange={onChangeInput} value={inputValue}/> .cn-healthclass.com
                                <div className="explain">
                                    说明：1、只能使用英文字母（a~z，不区分大小写）、数字（0~9）以及连接符（-）。<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    2、不支持使用空格及以下字符：！$&？等。<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    3、便于记忆建议使用10个字符以内域名前缀，最多输入不超过20个字符。
                                </div>
                            </div>
                            <div className="title">学苑logo</div>
                            <div className="content">
                                <span className="icon">学苑logo</span>
                                <div className="image">
                                    <Image width={300} height={200} src={imgFile}/>
                                </div>
                                <span className="uploadButton">
                            <Upload {...propsImgfile} maxCount={1}>
                                <Button icon={<UploadOutlined/>}>上传图片</Button>
                            </Upload>
                        </span>
                            </div>
                            <div className="imgExplain">
                                注：请上传学苑logo 支持jpg、jpeg、png文件，最大5M， 像素请大于200*200
                            </div>
                            <div className="content">
                                <button className="checkbuttonb" onClick={()=>onCancel()} >取消</button>
                                <button className="checkbutton" onClick={()=>submit()}>提交</button>
                            </div>
                            <div className="classBottom"></div>
                        </TabPane>

                        <TabPane tab="操作日志" key="6">
                            <div className="content">
                                <Timeline>
                                    {logsData && logsData.map((orgLogs) => (
                                            <Timeline.Item key={orgLogs.id}>
                                                <div>
                                                    <span className="logTimeSpan">{orgLogs.createdAt || ""}</span>
                                                    <div className="divContent"><span>操作： {orgLogs.sign || ""}</span></div>
                                                    <div className="divContent">{orgLogs.content && <span>操作内容:<br/><span
                                                        dangerouslySetInnerHTML={{__html: orgLogs.content}}></span></span>}</div>
                                                    <div className="createdBy"><span>操作人：{orgLogs.operatorName || ""}</span><span
                                                        className="spanRight">手机号：{orgLogs.operatorPhone || ""}</span></div>
                                                </div>
                                            </Timeline.Item>
                                        )
                                    )}
                                </Timeline>
                            </div>
                        </TabPane>

                    </Tabs>
                </Content>
            </Fragment>
        </div>
    )

}
