import FinanceLaborFeeProjects from '../components/Finance/LaborFee/FinanceLaborFeeProjects';
import FinanceLaborFeeProject from '../components/Finance/LaborFee/FinanceLaborFeeProject';
import FinanceLaborFeePaymentBatches from '../components/Finance/LaborFee/FinanceLaborFeePaymentBatches';
import FinanceLaborFeePayment from '../components/Finance/LaborFee/FinanceLaborFeePayment';

const financeLaborFeeRoutes = [
    { path: 'laborfee/projects', element: <FinanceLaborFeeProjects/> },
    // 拿到 :project split('-') 一下
    // :projectCode-:projectId
    { path: 'laborfee/projects/:project', element: <FinanceLaborFeeProject/> },
    { path: 'laborfee/projects/:project/batches', element: <FinanceLaborFeePaymentBatches/> },
    { path: 'laborfee/projects/:project/batches/:batchNo', element: <FinanceLaborFeePayment/> },
];

export default financeLaborFeeRoutes;
