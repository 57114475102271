import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { regRichText } from "@utils/utils";
import { saveLogicsApi, delLogicsApi, updateLogicsApi } from "@services/Wj";
import G6 from "@antv/g6";
import "./index.less";
import { useMemo } from "react";
const LogicItem = ({ id, name, index, edges, flowData, oldOpinion, setEdges, setFlowData, nodes, newLogic, setNewLogic, logicQuestion, item, allquestion, onReset, opId }) => {
    const [choiceOption, setChoiceOption] = useState(undefined);
    const [weight, setWeight] = useState(undefined);
    const [opinionId, setOpinionId] = useState(undefined);
    const [jumpWeight, setJumpWeight] = useState(undefined);
    const [sourceQuestionId, setSourceQuestionId] = useState(undefined);
    const [targetQuestionId, setTargetQuestionId] = useState(undefined);
    const [opin, setOpin] = useState(undefined);
    const [jump, setJump] = useState(undefined);
    const [sourceChoice, setSourceChoice] = useState(false);
    const [opionChoice, setOpionChoice] = useState(false);
    const location = useLocation();
    const [form] = Form.useForm();
    const onSave = (index, type, qid, qvalue) => {
        if (item.sourceQuestionId) {
            let degeIndex = edges.findIndex((it) => it.info === item.id);
            let obj = {
                ...item,
                optionId: type === "select" ? qid : opinionId,
                source: type === "source" ? qvalue - 1 + "" : weight - 1 + "",
                target: type === "target" ? qvalue - 1 + "" : jumpWeight - 1 + "",
                sourceQuestionId: type === "source" ? qid : sourceQuestionId,
                targetQuestionId: type === "target" ? qid : targetQuestionId,
            };
            let updateEdges = [...edges];
            updateEdges[degeIndex] = obj;
            setEdges(updateEdges);
            console.log(obj);
            updateLogicsApi({ ...obj }).then((res) => {
                if (res.status_code === 200) {
                    onReset();
                }
            });
        } else {
            let obj = [
                ...edges,
                {
                    name: `e${weight}-${jumpWeight}`,
                    source: type === "source" ? qvalue - 1 + "" : weight - 1 + "",
                    target: type === "target" ? qvalue - 1 + "" : jumpWeight === undefined ? -1 + "" : jumpWeight - 1 + "",
                },
            ];
            let addlogic = {
                source: type === "source" ? qvalue - 1 + "" : weight - 1 + "",
                target: type === "target" ? qvalue - 1 + "" : jumpWeight - 1 + "",
            };
            setEdges(obj);
            let logic = [...newLogic];
            logic[index] = addlogic;
            setNewLogic([...logic]);
            setFlowData({ nodes: nodes, edges: obj });
            let sendObj = {
                surveyId: location.state.id,
                optionId: type === "select" ? qid : opinionId,
                source: type === "source" ? qvalue - 1 + "" : weight - 1 + "",
                target: type === "target" ? qvalue - 1 + "" : jumpWeight - 1 + "",
                sourceQuestionId: type === "source" ? qid : sourceQuestionId,
                targetQuestionId: type === "target" ? qid : targetQuestionId,
            };
            saveLogicsApi({ ...sendObj }).then((res) => {
                if (res.status_code === 200) {
                    onReset();
                }
            });
        }
    };
    useEffect(() => {
        if (item.sourceQuestionId) {
            let def = logicQuestion.find((it) => it.id === item.sourceQuestionId);
            let arr = def.options.map((item) => {
                return {
                    id: item.id,
                    value: item.content,
                    it: name,
                };
            });
            arr.forEach((item, index) => {
                if (item.id === opId) {
                    arr[index].disabled = false;
                } else if (oldOpinion && oldOpinion.indexOf(item.id) > -1) {
                    arr[index].disabled = true;
                }
            });
            let defVal = `${def.weight}.${regRichText(def.body)}`;
            form.setFieldValue("start", defVal);
            let defJump = allquestion.find((it) => it.id === item.targetQuestionId);
            let deVal;
            if (defJump !== undefined) {
                deVal = `${defJump.weight}.${regRichText(defJump.body)}`;
            } else {
                deVal = `结束`;
            }
            form.setFieldValue("finish", deVal);
            setChoiceOption(arr);
            let op = def.options.find((i) => i.id === item.optionId);
            let opVal = `${op.content}`;
            setOpin(opVal);
            form.setFieldValue("select", opVal);
            setWeight(def.weight);
            let jup;
            if (allquestion.find((it) => it.id === item.targetQuestionId)) {
                jup = allquestion.find((it) => it.id === item.targetQuestionId);
            } else {
                jup = { weight: -1 };
            }
            setJumpWeight(jup.weight);
        }
    }, [edges]);
    const delLogic = (index, id) => {
        id = id + "";
        if (id.includes("local") || id.includes("e")) {
            let logic = [...newLogic];
            const delIndex = edges.findIndex((item) => item.id === newLogic[index].id);
            if (delIndex >= 0) {
                let delEdge = [...edges];
                delEdge.splice(delIndex, 1);
                setEdges([...delEdge]);
                setFlowData({ nodes: nodes, edges: delEdge });
                logic.splice(index, 1);
            } else {
                logic.splice(index, 1);
            }
            //

            setNewLogic([...logic]);
        } else {
            Modal.confirm({
                // title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: "删除后本题目逻辑将不能找回，确定要删除吗？",
                okText: "继续",
                cancelText: "取消",
                onOk: () => {
                    console.log([Number(id)]);
                    delLogicsApi({ idList: [Number(id)] }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                        }
                    });
                },
            });
        }
    };

    const getBorder = (name) => {
        let old = document.querySelectorAll(".logic_item");
        old && old.forEach((item) => item.removeAttribute("style"));
        let a = document.getElementById(name);
        a.style.border = "2px solid #5394ef";
    };

    const ifChoice = () => {
        if (jumpWeight) {
            console.log(jumpWeight);
            let res = [];
            let arr =
                logicQuestion &&
                logicQuestion.map((item, index) => {
                    if (jumpWeight === -1) {
                        res.push({
                            id: `${item.id}`,
                            value: `${item.weight}.${regRichText(item.body)}`,
                            key: `${index + 1}`,
                            item: item,
                            index: item.weight,
                        });
                    } else if (item.weight + 1 >= jumpWeight) {
                        res.push({
                            id: `${item.id}`,
                            value: `${item.weight}.${regRichText(item.body)}`,
                            key: `${index + 1}`,
                            item: item,
                            index: item.weight,
                            disabled: true,
                        });
                    } else {
                        res.push({
                            id: `${item.id}`,
                            value: `${item.weight}.${regRichText(item.body)}`,
                            key: `${index + 1}`,
                            item: item,
                            index: item.weight,
                        });
                    }
                    return res;
                });
            return res;
        } else {
            let arr =
                logicQuestion &&
                logicQuestion.map((item, index) => {
                    return {
                        id: `${item.id}`,
                        value: `${item.weight}.${regRichText(item.body)}`,
                        key: `${index + 1}`,
                        item: item,
                        index: item.weight,
                    };
                });
            return arr;
        }
    };

    const jumptoChoice = () => {
        let res = [];
        allquestion &&
            allquestion.map((item, index) => {
                if (index < weight) {
                    res.push({
                        id: `${item.id}`,
                        value: `${item.weight}.${regRichText(item.body)}`,
                        key: `${index + 1}`,
                        index: item.weight,
                        disabled: true,
                    });
                } else {
                    res.push({
                        id: `${item.id}`,
                        value: `${item.weight}.${regRichText(item.body)}`,
                        key: `${index + 1}`,
                        index: item.weight,
                    });
                }

                // return res;
            });
        res.push({
            id: -1,
            value: `结束`,
            key: `-1`,
            index: 0,
        });
        return res;
    };

    const onChangeSelect = (key, value) => {
        let arr = [];
        value.item.options.map((item) => {
            arr.push({
                id: item.id,
                value: item.content,
                it: name,
            });
        });
        arr.forEach((item, index) => {
            if (item.id === opId) {
                arr[index].disabled = false;
            } else if (oldOpinion.indexOf(item.id) > -1) {
                arr[index].disabled = true;
            }
        });
        setChoiceOption(arr);
        setOpin(undefined);
        form.setFieldValue("select", undefined);
        setWeight(value.item.weight);
        setSourceQuestionId(value.id);
        if (form.getFieldValue("finish") !== undefined) {
            if (form.getFieldValue("start") !== undefined && form.getFieldValue("select") !== undefined) {
                setSourceChoice(false);
                setOpionChoice(false);
                onSave(index, "source", value.id, value.item.weight);
            } else if (form.getFieldValue("start") === undefined) {
                setSourceChoice(true);
                setOpionChoice(true);
            } else if (opin === undefined) {
                setSourceChoice(false);
                setOpionChoice(true);
            }
        }
    };

    const onDefaultSelect = (def) => {
        let arr = def.options.map((item) => {
            return {
                id: item.id,
                value: item.content,
            };
        });
        setChoiceOption(arr);
        setWeight(def.weight);
    };

    const onJumpSelect = (key, value) => {
        setJumpWeight(value.index);
        setTargetQuestionId(value.id);
        setJump(value);
        if (form.getFieldValue("finish") !== undefined) {
            if (form.getFieldValue("start") !== undefined && form.getFieldValue("select") !== undefined) {
                setSourceChoice(false);
                setOpionChoice(false);
                onSave(index, "target", value.id, value.index);
            } else if (form.getFieldValue("start") === undefined) {
                setSourceChoice(true);
                setOpionChoice(true);
            } else if (opin === undefined) {
                setSourceChoice(false);
                setOpionChoice(true);
            }
        }
    };
    const onChoiceSelect = (key, value) => {
        setOpin(value);
        setOpinionId(value.id);

        if (form.getFieldValue("finish") !== undefined) {
            if (form.getFieldValue("start") !== undefined && form.getFieldValue("select") !== undefined) {
                setSourceChoice(false);
                setOpionChoice(false);
                onSave(index, "select", value.id);
            } else if (form.getFieldValue("start") === undefined) {
                setSourceChoice(true);
                setOpionChoice(true);
            } else if (opin === undefined) {
                setSourceChoice(false);
                setOpionChoice(true);
            }
        }
    };

    const choiceDefault = () => {
        if (item.sourceQuestionId) {
            let def = logicQuestion.find((it) => it.id === item.sourceQuestionId);
            let defVal = `${def.weight}.${regRichText(def.body)}`;
            form.setFieldValue("start", defVal);
            return defVal;
        }
    };

    const jumpDefault = () => {
        if (item.targetQuestionId) {
            let def = allquestion.find((it) => it.id === item.targetQuestionId);
            let deVal = `${def.weight}.${regRichText(def.body)}`;
            form.setFieldValue("finish", deVal);
            return deVal;
        }
    };

    const getClear = (type) => {
        if (type === "source") {
            setWeight(undefined);
            setOpin(undefined);
            form.setFieldValue("start", undefined);
            form.setFieldValue("select", undefined);
        } else if (type === "target") {
            setSourceChoice(false);
            setOpionChoice(false);
            setJumpWeight(undefined);
            form.setFieldValue("finish", undefined);
        }
    };
    const getFinish = () => {
        if (form.getFieldValue("finish") !== undefined) {
            if (form.getFieldValue("start") !== undefined && form.getFieldValue("select") !== undefined) {
                setSourceChoice(false);
                setOpionChoice(false);
            } else if (form.getFieldValue("start") === undefined) {
                setSourceChoice(true);
                setOpionChoice(true);
            } else if (opin === undefined) {
                setSourceChoice(false);
                setOpionChoice(true);
            }
        }
    };
    return (
        <div className="logic_item" id={name} onClick={() => getBorder(name)}>
            <Form form={form} onValuesChange={getFinish}>
                <div className="logic_item_top">
                    <Row>
                        <Col span={12}>
                            <Form.Item label="如果" name="start" style={{ marginBottom: sourceChoice ? 0 : 30 }}>
                                <Select
                                    allowClear
                                    className="select_box"
                                    options={ifChoice()}
                                    onSelect={(key, value) => onChangeSelect(key, value)}
                                    // defaultValue={choiceDefault()}
                                    onClear={() => getClear("source")}
                                />
                            </Form.Item>
                            <p
                                className="notice"
                                style={{
                                    display: sourceChoice ? "block" : "none",
                                }}
                            >
                                请先选择要设置逻辑的题目
                            </p>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="选中" name="select">
                                <Select className="select_box" options={choiceOption} onSelect={(key, value) => onChoiceSelect(key, value)} />
                            </Form.Item>
                            <p
                                className="notice"
                                style={{
                                    display: opionChoice ? "block" : "none",
                                }}
                            >
                                请选择要设置逻辑的题目的选项
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className="logic_item_foot">
                    <Row>
                        <Col span={12}>
                            <Form.Item label="跳转" name="finish">
                                <Select
                                    allowClear
                                    className="select_box"
                                    options={jumptoChoice()}
                                    onSelect={(key, value) => onJumpSelect(key, value)}
                                    // defaultValue={jumpDefault()}
                                    value={jump}
                                    onClear={() => getClear("target")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <CheckOutlined
                        className="logic_item_sucess"
                        onClick={() => onSave(index)}
                    /> */}
                    <DeleteOutlined className="logic_item_del" onClick={() => delLogic(index, id)} />
                </div>
            </Form>
        </div>
    );
};

export default LogicItem;
