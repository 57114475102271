import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { Fragment, useState, useEffect } from "react";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { previewSurveryApi } from "@services/Wj/Question";
import { getLogicQuestionApi, getAllLogicApi } from "@services/Wj";
import "./index.less";
import G6 from "@antv/g6";
import LogicItem from "./components/LogicItem";
import { regRichText } from "@utils/utils";
let graph;

const lineDash = [20, 5];
G6.registerEdge(
    "line-dash",
    {
        afterDraw(cfg, group) {
            // get the first shape in the group, it is the edge's path here=
            const shape = group.get("children")[0];
            let index = 0;
            // Define the animation
            shape.animate(
                () => {
                    index++;
                    if (index > 9) {
                        index = 0;
                    }
                    const res = {
                        lineDash,
                        lineDashOffset: -index,
                    };
                    // returns the modified configurations here, lineDash and lineDashOffset here
                    return res;
                },
                {
                    repeat: true, // whether executes the animation repeatly
                    duration: 4000, // the duration for executing once
                }
            );
        },
    },
    "polyline" // extend the built-in edge 'cubic'
);

let id = 1;
const Logic = () => {
    const [nodes, setNodes] = useState(undefined);
    const [edges, setEdges] = useState(undefined);
    const [newLogic, setNewLogic] = useState([]);
    const [flowData, setFlowData] = useState(undefined);
    const [logicQuestion, setLogicQuestion] = useState(undefined);
    const [allquestion, setAllquestion] = useState(undefined);
    const [oldOpinion, setOldOpinion] = useState(undefined);
    const [form] = Form.useForm();
    const location = useLocation();
    console.log(location);
    const onReset = () => {
        previewSurveryApi({ id: location.state.id }).then((res) => {
            let logicnode = res.data?.questions
                ? res.data?.questions.map((item, index) => {
                    return {
                        id: index + "",
                        name: regRichText(`${item.weight}.${item.body}`),
                        type: "sql",
                    };
                })
                : [];
            logicnode &&
                logicnode.length > 0 &&
                logicnode.push({
                    id: Number(-1) + "",
                    name: regRichText(`结束`),
                    type: "sql",
                });
            let logicedge = res.data?.questions
                ? res.data?.questions.map((item, index) => {
                    if (index !== res.data?.questions.length - 1) {
                        return {
                            optionId: 0,
                            surveyId: location.state.id,
                            source: item.weight - 1 + "",
                            target: item.weight + "",
                        };
                    }
                })
                : [];
            if (logicnode.length > 1) {
                logicedge &&
                    logicnode.length > 0 &&
                    logicedge.push({
                        optionId: 0,
                        surveyId: location.state.id,
                        source: logicnode[logicnode.length - 2].id + "",
                        target: -1 + "",
                    });
            }

            let allquestion = res.data?.questions;
            logicedge = logicedge.filter((item) => item !== undefined);
            getAllLogicApi({ surveyId: location.state.id }).then((res) => {
                if (res.status_code === 200) {
                    if (res.data) {
                        if (localStorage.getItem("logic") === "add") {
                            let logic = [...res.data];
                            let logicItem = { i: `local${id++}` };
                            logic.push(logicItem);
                            setNewLogic([...logic]);
                        } else {
                            setNewLogic(res.data);
                        }
                        let final = [];
                        let oldOp = [];
                        res.data.map((item) => {
                            if (item.optionId) oldOp.push(item.optionId);
                            let res = {
                                optionId: item.optionId,
                                surveyId: location.state.id,
                                sourceQuestionId: item.sourceQuestionId || -1,
                                targetQuestionId: item.targetQuestionId || -1,
                                info: item.id,
                            };

                            allquestion.map((it) => {
                                if (it.id === res.sourceQuestionId) {
                                    res.source = it.weight - 1 + "";
                                } else if (it.id === res.targetQuestionId) {
                                    res.target = it.weight - 1 + "";
                                }
                                if (res.targetQuestionId === -1) {
                                    res.target = -1 + "";
                                }
                                final.push(res);
                            });
                        });
                        console.log(final);
                        setOldOpinion([...oldOp]);
                        let resEdge = [...logicedge, ...final];
                        let obj = {
                            nodes: logicnode,
                            edges: resEdge,
                        };
                        setNodes([...logicnode]);
                        setEdges([...resEdge]);
                        setFlowData(obj);
                    } else {
                        let obj = {
                            nodes: logicnode,
                            edges: logicedge,
                        };

                        setNodes([...logicnode]);
                        setEdges([...logicedge]);
                        setFlowData(obj);
                    }
                } else {
                    let obj = {
                        nodes: logicnode,
                        edges: logicedge,
                    };

                    setNodes([...logicnode]);
                    setEdges([...logicedge]);
                    setFlowData(obj);
                }
            });

            if (res.data?.questions) {
                setAllquestion(res.data?.questions);
            }
        });
    };

    useEffect(() => {
        onReset();
        G6.registerNode(
            "sql",
            {
                drawShape(cfg, group) {
                    const rect = group.addShape("rect", {
                        attrs: {
                            x: -200,
                            y: -15,
                            width: 400,
                            height: 50,
                            radius: 5,
                            stroke: "#5B8FF9",
                            textAlign: "center",
                            fill: "#C6E5FF",
                            // lineWidth: 3,
                            overflow: "hidden",
                        },
                        name: "rect-shape",
                    });
                    if (cfg.name) {
                        group.addShape("text", {
                            attrs: {
                                text: fittingString(cfg.name, 400, 20),
                                overflow: "hidden",
                                x: 0,
                                y: 10,
                                width: 400,
                                fill: "#00287E",
                                fontSize: 20,
                                textAlign: "center",
                                textBaseline: "middle",
                                fontWeight: "bold",
                            },
                            style: {
                                color: "red",
                            },
                            name: "text-shape",
                        });
                    }
                    return rect;
                },
            },
            "single-node"
        );
    }, []);

    useEffect(() => {
        let oldOp = [];
        getAllLogicApi({ surveyId: location.state.id }).then((res) => {
            if (res.status_code === 200) {
                if (res.data) {
                    res.data.map((item) => {
                        if (item.optionId) oldOp.push(item.optionId);
                    });
                }
                setOldOpinion([...oldOp]);
            }
        });
    }, []);

    const fittingString = (str, maxWidth, fontSize) => {
        const ellipsis = "...";
        const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
        let currentWidth = 0;
        let res = str;
        const pattern = new RegExp("[\u4E00-\u9FA5]+"); // distinguish the Chinese charactors and letters
        str.split("").forEach((letter, i) => {
            if (currentWidth > maxWidth - ellipsisLength) return;
            if (pattern.test(letter)) {
                // Chinese charactors
                currentWidth += fontSize;
            } else {
                // get the width of single letter according to the fontSize
                currentWidth += G6.Util.getLetterWidth(letter, fontSize);
            }
            if (currentWidth > maxWidth - ellipsisLength) {
                res = `${str.substr(0, i)}${ellipsis}`;
            }
        });
        return res;
    };
    // 获取文本的长度
    const getTextSize = (str, maxWidth, fontSize) => {
        let width = G6.Util.getTextSize(str, fontSize)[0];
        return width > maxWidth ? maxWidth : width;
    };

    const addLogic = () => {
        localStorage.removeItem("logic");
        let logic = [...newLogic];
        let logicItem = { i: `local${id++}` };
        logic.push(logicItem);
        setNewLogic([...logic]);
    };

    useEffect(() => {
        if (flowData) {
            const container = document.getElementById("container");
            const tool = document.getElementById("toolbar");
            const map = document.getElementById("minimap");
            G6.registerEdge(
                "line-dash",
                {
                    afterDraw(cfg, group) {
                        // get the first shape in the group, it is the edge's path here=
                        const shape = group.get("children")[0];
                        let index = 0;
                        // Define the animation
                        shape.animate(
                            () => {
                                index++;
                                if (index > 9) {
                                    index = 0;
                                }
                                const res = {
                                    lineDash,
                                    lineDashOffset: -index,
                                };
                                // returns the modified configurations here, lineDash and lineDashOffset here
                                return res;
                            },
                            {
                                repeat: true, // whether executes the animation repeatly
                                duration: 1000, // the duration for executing once
                            }
                        );
                    },
                },
                "polyline" // extend the built-in edge 'cubic'
            );
            if (graph) {
                graph.destroy();
            }
            setTimeout(() => {
                const width = container.scrollWidth;

                const height = container.scrollHeight || `80%`;
                const minimap = new G6.Minimap({
                    size: [120, 100],
                    // className: 'minimap',
                    container: map,
                    type: "delegate",
                });

                const toolbar = new G6.ToolBar({
                    container: tool,
                    // position: { x: 900, y: 20 },
                });
                graph = new G6.Graph({
                    container: "container",
                    width,
                    height,
                    layout: {
                        type: "dagre",
                        nodesepFunc: (d) => {
                            if (d.id === "3") {
                                return 100;
                            }
                            return 300;
                        },
                        ranksep: 26,
                        controlPoints: true,
                    },
                    defaultNode: {
                        type: "sql",
                    },
                    defaultEdge: {
                        type: "line-dash",
                        style: {
                            radius: 20,
                            offset: 100,
                            endArrow: true,
                            lineWidth: 2,
                            stroke: "#C2C8D5",
                        },
                    },
                    nodeStateStyles: {
                        selected: {
                            stroke: "#d9d9d9",
                            fill: "#5394ef",
                        },
                    },
                    modes: {
                        default: ["drag-canvas", "zoom-canvas", "click-select"],
                    },
                    // fitView: true,
                    // linkCenter:true,
                    fitViewPadding: [20],
                    plugins: [minimap, toolbar],
                });

                graph.zoom(0.1);
                graph.data(flowData);
                graph.render();
                if (typeof window !== "undefined")
                    window.onresize = () => {
                        // if (!graph || graph.get('destroyed')) return;
                        if (!container || !container.scrollWidth || !container.scrollHeight) return;
                        graph.changeSize(container.scrollWidth, container.scrollHeight);
                    };
            }, 0);
        }
    }, [flowData]);

    useEffect(() => {
        getLogicQuestionApi({
            surveyId: location.state.id,
            typeIds: [1, 3, 8],
        }).then((res) => {
            if (res.status_code === 200) {
                setLogicQuestion(res.data);
            }
        });
    }, []);
    const beforeunload = () => {
        localStorage.removeItem("logic");
    };

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);

        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, []);

    useEffect(() => {
        if (newLogic && newLogic.length > 0) {
            const errorList = document.querySelectorAll(".logic_item");
            errorList[errorList.length - 1]?.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }, [newLogic]);

    return (
        <Fragment>
            <div className="logic_box">
                <div className="logic_left">
                    <div className="logic_set">
                        <div className="logic_set_left">
                            <h2>逻辑设置</h2>
                            <span>调整题目顺序，将清空所有逻辑</span>
                        </div>
                        <Button type="primary" onClick={addLogic}>
                            添加逻辑
                        </Button>
                    </div>
                    {newLogic &&
                        newLogic.map((item, index) => {
                            return (
                                //   <LogicItem key={item.id} id={item.id} index={index} flowData={flowData} edges={edges} setEdges={setEdges} setFlowData={setFlowData} nodes={nodes} newLogic={newLogic} setNewLogic={setNewLogic} />
                                <LogicItem key={item.i || item.id} id={item.i || item.id} name={item.i || item.name || item.id} index={index} flowData={flowData} edges={edges} setEdges={setEdges} setFlowData={setFlowData} nodes={nodes} newLogic={newLogic} setNewLogic={setNewLogic} logicQuestion={logicQuestion} allquestion={allquestion} item={item} onReset={onReset} oldOpinion={oldOpinion} opId={item?.optionId} />
                            );
                        })}
                </div>
                <div className="logic_right">
                    <h2>逻辑预览</h2>
                    <div id="container">
                        <div id="toolbar"></div>
                        <div id="minimap"></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Logic;
