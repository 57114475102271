import { get, put, post, delet } from '@core/request';
import config from "@config";

/**
 * 
 * @param {*} params 
 * @returns 
 */

 // 评委 / 选手列表
export const stageTasksApi = (params) => {
    return get('/selection/admin/projectstagetasks/case-fill-list/list', params);
}

 // 保存任务
 export const stageTasksSaveApi = (params) => {
    return post('/selection/admin/projectstagetasks/case-fill-list/save', params);
 }

  // 编辑任务
  export const stageTasksEditApi = (params) => {
    return put('/selection/admin/projectstagetasks/case-fill-list/update', params);
  }

    // 排序任务
    export const stageTasksSortApi = (params) => {
        return put('/selection/admin/projectstagetasks/case-fill-list/sort', params);
    }


    // 删除任务
    export const stageTasksDeleteApi = (params) => {
        return delet('/selection/admin/projectstagetasks/case-fill-list/delete', params);
    }

    // 评委数
    export const stageJudgesApi = (params) => {
        return get('/selection/admin/casejudges/countInfo/stageJudges', params);
    }

    // 案例数
    export const stageCasesApi = (params) => {
        return get('/selection/admin/casejudgeintenttopics/countInfo/stageCases', params);
    }

    // 发送短信
    export const sendMessageApi = (params) => {
        return get('/selection/admin/projectstagetasks/case-fill-list/send-message', params);
    }
