import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Modal } from "antd";
import ReactQuill from "react-quill";
import "./index.less";
import { noticesInfoApi } from '@services/MedProject';

const NoticeDetails = ({ detailsOpen, setDetailsOpen, currentId }) => {

    const id = currentId;
    let refs = useRef(null);
    const [prodDetails, setProdDetails] = useState("");

    const modules = useMemo(() => {
        return {
            toolbar: null,
        };
    }, []);

    const handleCancel = () => {
        setDetailsOpen(false);
    };

    const noticeInit = async () =>{
        const res = await noticesInfoApi({id});
        if (res.status_code === 200) {
            setProdDetails(res.data)
        }
    };

    useEffect(() => {
        if(detailsOpen && currentId){
            noticeInit();
        }
    }, [currentId, detailsOpen]);


    return (
        <div className="notice-detail">

            <Modal
                title="公告详情"
                width={1000}
                onOk={handleCancel}
                onCancel={handleCancel}
                open={detailsOpen}
                className="notice-detail"
                footer={
                    <div className="memberModalFooter" style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={handleCancel}>关闭</Button>
                    </div>
                }
            >
                <h1 className="notice-title">
                    {prodDetails.title}
                </h1>
                <p className="time">
                    {prodDetails.createdAt}
                </p>
                <ReactQuill
                    ref={refs}
                    modules={modules}
                    readOnly
                    theme="snow"
                    value={prodDetails.content}
                />

            </Modal>
        </div>
    );
};

export default NoticeDetails;

