import React, { useEffect, useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, Button, Space, Cascader } from 'antd';
import { get } from '@/core/request';

export default function ProductionLinesTopicsFilterForm({
    handleFilterFinish,liveCatesId
}) {
    const [form] = Form.useForm();

    // 产品线
    const [liveId, setLiveId] = useState([]);
    // 所属企业
    const [orgId, setOrgId] = useState([]);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setLiveId(res.data);
        })
    }, []);

    // 合作企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    const onFinish = (values) => {
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            cateId: undefined,
            labelId: undefined,
            liveId: undefined,
            name: undefined,
            orgId: undefined,
            status: undefined
        };
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="专题名称" name="name">
                        <Input allowClear placeholder="请输入专题名称" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播分类" name="cateIdArr">
                        <Cascader options={liveCatesId} placeholder="请选择直播分类" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线" name="liveId">
                        <Select allowClear placeholder="请选择产品线">
                            {liveId && liveId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="状态" name="status">
                        <Select allowClear placeholder="请选择状态">
                            <Select.Option value={0}>禁用</Select.Option>
                            <Select.Option value={1}>启用</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
