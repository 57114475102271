import React, { useState, useMemo, useRef } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Input, Dropdown, Form,message} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.less";
import { timesaveSurveryQuestionApi } from "@services/Wj/Question";
const MatrixEditRow = ({ item, id, rowValue, setRowValue,rowDelete,arrForm,anserArea,setAnserArea,qData,onReset }) => {
    const quillRef = useRef(null);
    const { TextArea } = Input;
    const [colBorder, setColBorder] = useState(false);
    const [colIn, setColIn] = useState(false);
    const delCol = () => {
        var index=id.lastIndexOf("\-");
        let str=id.substring(index+1,id.length);
      rowDelete(str);
    };
    const items = [
        {
            label: (
                <p className="down_text" onClick={delCol}>
                    删除
                </p>
            ),
            key: "item-1",
        }, // 菜单项务必填写 key
    ];
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                    ],
                ],
            },
        };
    }, []);

    const getBorder = () => {
        setColBorder(true);
        setColIn(true);
    };

    const cancelBorder = (e) => {
      let rowBool
        let oldValue=JSON.parse(qData.options[0].content)[1]
       let newRow=[...rowValue]
       // let index=e.target.id.slice(-1)
       newRow.push(e.target.value)
       // console.log(newCol);
       setRowValue(newRow);
       let col=Object.values(arrForm.getFieldValue('colName'))
       let row=Object.values(arrForm.getFieldValue('rowName'))
       for (let i = 0; i < col.length; i++) {
        if (row[i] != oldValue[i]) {
            rowBool=true;
        }
    }
     if(rowBool===undefined) rowBool=false;
       var index=id.lastIndexOf("\-");
       let str=id.substring(index+1,id.length);
        col=col.map((item)=>{ 
        if(item===''){
           return item=`列标题${Number(str)+1}`
        }else{
            return item
        }
    })
    row=row.map((item)=>{ 
        if(item===''){
           return item=`矩阵行${Number(str)+1}`
        }else{
            return item
        }
    })
       qData.options[0].content=JSON.stringify([[...col],[...row]])
       let obj={...qData}
       let templateId=qData.surveyId
     //    let arrForm.getFieldsValue().colName
     //    arrForm.getFieldsValue().rowName
     //    timesaveSurveryQuestionApi
     timesaveSurveryQuestionApi({...obj,templateId,isTrueModify:rowBool}).then(res=>{
      if(res.status_code===200){
        message.success('保存成功')
          onReset()
    }
     })
        setColBorder(false);
        setColIn(false);
    };

    return (
        <div className="edit_row">
            {/* <ReactQuill theme="snow" ref={quillRef}
        modules={modules}
       placeholder="请输入问题"
       className="question-title-area"
        // onFocus={()=>getBorder(questionIndex)}
        // defaultValue={item.body}
            // onBlur={()=>changeTitle(questionIndex)}
            /> */}

            <Form.Item name={["rowName", id]} 
            //  rules={[{ required: true, message: '请填写' }]}
             >
                <TextArea
                    className="row"
                    id={id}
                    bordered={colBorder}
                    defaultValue={item || ""}
                    onFocus={(e) => getBorder()}
                    onBlur={(e) => cancelBorder(e)}
                    placeholder={"请输入标题"}
                    maxLength={30}
                    style={{ border: colIn ? "1px solid #1a73e8" : "" }}
                    autoSize={{
                        minRows: 1,
                    }}
                ></TextArea>
            </Form.Item>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={["click"]}
                placement="bottomLeft"
                arrow={{
                    pointAtCenter: true,
                }}
            >
                <MoreOutlined
                    className="edit_action"
                    onClick={(e) => e.preventDefault()}
                />
            </Dropdown>
        </div>
    );
};

export default MatrixEditRow;
