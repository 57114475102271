/**
 * moduleType 网站模块类型
 * 模块类型
 * -  1 官网首页 home
 * -  2 组织机构 organizations
 * -  3 会议会刊 journals
 * -  4 会议日程 agendas
 * -  5 会议嘉宾 guests
 * -  6 会议直播 lives
 * -  7 大会资讯 news
 * -  8 历届回顾 reviews
 * -  9 线上展区 exhibitions
 * - 10 合作伙伴 partners
 * - 20 图文模块 rich_text
 * - 21 外部链接 external_link
 * - 22 我的项目 myprojects
 */

/**
 * moduleType: 网站模块类型编号
 * name: 模块名称，英文，唯一
 * title: 默认展示标题，后台可以设置 display_title 用于展示
 * path: 模块访问路径，内置模块不可变
 * builtin: 是否为内置模块类型，0为自定义，1为内置模块类型，自定义模块的 path 需要后台自定义
 * @type Array<Object>
 */
module.exports = [
    {moduleType: 1,  name: 'home',          title: '官网首页', builtin: 1, path: 'home'},
    {moduleType: 2,  name: 'organizations', title: '组织机构', builtin: 1, path: 'organizations'},
    {moduleType: 3,  name: 'journals',      title: '会议会刊', builtin: 1, path: 'journals'},
    {moduleType: 4,  name: 'agendas',       title: '会议日程', builtin: 1, path: 'agendas'},
    {moduleType: 5,  name: 'guests',        title: '会议嘉宾', builtin: 1, path: 'guests'},
    {moduleType: 6,  name: 'lives',         title: '会议直播', builtin: 1, path: 'lives'},
    {moduleType: 7,  name: 'news',          title: '大会资讯', builtin: 1, path: 'news'},
    {moduleType: 8,  name: 'reviews',       title: '历届回顾', builtin: 1, path: 'reviews'},
    {moduleType: 9,  name: 'exhibitions',   title: '线上展区', builtin: 1, path: 'exhibitions'},
    {moduleType: 10, name: 'partners',      title: '合作伙伴', builtin: 1, path: 'partners'},
    {moduleType: 20, name: 'richText',      title: '图文模块', builtin: 0, path: ''}, // path 用户自定义
    {moduleType: 21, name: 'externalLink', title: '外部链接', builtin: 0, path: '' }, // path 用户自定义
    {moduleType: 22, name: 'myprojects',  title: '我的项目', builtin: 1, path: ''}
];

