import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Layout, Table, Button, Switch, Modal, Checkbox, message, Form,Steps } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { fetchCasescoringtopicsList, putCasescoringtopicsUpdate, fetchProjecttopicsList, saveProjecttopicsList } from "@services/Selection";

import "./index.less";
import moment from "moment";

const { Content } = Layout;

const handleTopicsSouce = ({ topicSource = [], dataSource = [] }) => {
    const idMap = {};
    dataSource.forEach((i) => {
        idMap[i.topicId] = i.topicId;
    });
    return topicSource.filter((item) => !idMap[item.value]);
};

const getColumns = ({ params, navigate, handleChangeSwitch }) => [
    {
        title: "主题",
        dataIndex: "topicName",
        align: "center",
    },
    {
        title: "案例数",
        dataIndex: "caseTotal",
        align: "center",
    },
    {
        title: "分配评委数",
        dataIndex: "judgesTotal",
        align: "center",
    },
    {
        title: "评分起止时间",
        key: "id",
        align: "center",
        render: (row) => {
            return (row.scoringStartedAt ? moment(row.scoringStartedAt).format("YYYY-MM-DD HH:mm") : "") + " - " + (row.scoringEndedAt ? moment(row.scoringEndedAt).format("YYYY-MM-DD HH:mm") : "");
        },
    },
    {
        title: "展示状态",
        key: "id",
        align: "center",
        render: (row, _, i) => {
            return <Switch checkedChildren="显示" unCheckedChildren="隐藏" checked={!row.hidden} onChange={(e) => handleChangeSwitch(e, row.id)} />;
        },
    },
    {
        title: "操作",
        key: "id",
        align: "center",
        render: (row) => {
            return (
                <React.Fragment>
                    <Button type="link" onClick={() => navigate(`/selection/projects/${params.pid}/score-stages/${params.id}/scoring-settings/${row.id}`, { state: row.scoringSetting })}>
                        设置规则
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/selection/projects/${params.pid}/score-stages/${params.id}/topics/${row.topicId}/cases`, { state: { decimalPlaceNum: row?.scoringSetting?.decimalPlaceNum, caseFinalScoreDecimalPlaceNum: row?.scoringSetting?.caseFinalScoreDecimalPlaceNum } });
                        }}
                    >
                        评分案例管理
                    </Button>
                </React.Fragment>
            );
        },
    },
];

const ScoreStageTopicList = () => {
    const params = useParams();
    const [form] = Form.useForm();
    const dataRef = useRef({ dataSource: [], topicSource: [] });
    const [dataSource, setDataSource] = useState([]);
    const [topicSource, setTopicSource] = useState([]);
    const [topicModal, setTopicModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        dataRef.current = { dataSource: dataSource, topicSource: topicSource };
    }, [dataSource, topicSource]);

    const fetchList = useCallback(
        (needLoading = true) => {
            if (needLoading) {
                setTableLoading(true);
            }
            fetchCasescoringtopicsList({ projectId: params.pid, stageId: params.id })
                .then((result) => {
                    if (result?.status_code === 200) {
                        setDataSource(result.data || []);
                    }
                })
                .finally(() => {
                    setTableLoading(false);
                });
        },
        [params]
    );

    useEffect(() => {
        fetchList();
        fetchProjecttopicsList({ projectId: params.pid }).then((result) => {
            if (result?.status_code === 200) {
                const list = [],
                    data = result.data;
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (element?.children?.length) {
                        list.push(...element.children.map((item) => ({ label: item.name, value: item.id, ...element })));
                    } else if (element.pid === 0) {
                        list.push({ label: element.name, value: element.id, ...element });
                    }
                }
                setTopicSource(list);
            }
        });
    }, [fetchList, params]);

    const columns = useMemo(() => {
        const handleChangeSwitch = (value, id) => {
            putCasescoringtopicsUpdate({ hidden: value ? 0 : 1, id }).then((res) => {
                if (res?.status_code === 200) {
                    message.success("修改成功！");
                    fetchList();
                }
            });
        };

        return getColumns({ params, navigate, handleChangeSwitch });
    }, [fetchList, navigate, params]);

    const header = useMemo(() => {
        const handleAddTopic = () => {
            const topics = handleTopicsSouce(dataRef.current);
            if (!topics.length) {
                return message.warning("无待添加参评主题！");
            }
            setTopicSource(topics);
            setTopicModal(true);
        };

        const goToAssign = () => {
            navigate(`/selection/projects/${params.pid}/score-stages/${params.id}/assign`);
        };

        const goToResetAssign = () => {
            navigate(`/selection/projects/${params.pid}/score-stages/${params.id}/reset-assign`);
        };

        return (
            <div className="mark-table-header">
                <div className="mark-table-header-button">
                    <Button type="primary" onClick={handleAddTopic}>添加主题</Button>
                    <Button onClick={goToAssign}>分配评委任务</Button>
                    <Button onClick={goToResetAssign}>重置评委任务</Button>
                </div>
            </div>
        );
    }, [navigate, params.id, params.pid]);

    const onOk = () => {
        const topicIds = form.getFieldValue("topics");

        if (topicIds?.length) {
            setConfirmLoading(true);
            saveProjecttopicsList({ projectId: params.pid, stageId: params.id, topicIds })
                .then((res) => {
                    if (res?.status_code === 200) {
                        fetchList();
                        message.success("添加成功！");
                        setTopicModal(false);
                        form.resetFields();
                    } else {
                        message.warning("添加失败，请重试！");
                    }
                })
                .finally(() => {
                    setConfirmLoading(false);
                });
        }
    };

    return (
        <>
            <div className="page-title">
                <span className="back" onClick={() => navigate(-1)}>
                    <LeftOutlined />
                </span>
                <span className="current-title">主题管理</span>
            </div>

            <Content className="main-content-box mark-table-content">

                <div className='step-container'>
                    <Steps
                        current='0'
                        labelPlacement="vertical"
                        items={[
                            { title: '添加主题' },
                            { title: '设置规则' },
                            { title: '评分案例管理' },
                            { title: '分配评委任务' }
                        ]}
                    />
                </div>

                <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="id"
                    title={() => header}
                    pagination={false}
                    loading={tableLoading}
                />

            </Content>

            <Modal centered width={600} title="选择参评主题" open={topicModal} confirmLoading={confirmLoading} onOk={onOk} onCancel={() => setTopicModal(false)} afterClose={() => setTopicModal(false)}>
                <Form form={form}>
                    <Form.Item
                        name="topics"
                        label="参评主题"
                        labelCol={{
                            sm: { span: 8 },
                        }}
                        wrapperCol={{
                            sm: { offset: 1 },
                        }}
                    >
                        <Checkbox.Group options={topicSource} className="pingwei-checkgroup" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ScoreStageTopicList;
