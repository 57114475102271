import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Radio, Tag, Table, message, Space, Typography } from "antd";
import { convertDateTimeRangeToLocalText } from '@utils/utils';
import { getInvitationInfoOneApi, confirmTasksOneApi } from '@services/Invitation/InvitationUser';
import "./ConfirmInvite.less";

const { Text } = Typography;

const ConfirmInvite = ({
                           open,
                           userCode,
                           setConfirmInviteOpen,
                           updateList
                       }) => {

    const [data, setData] = useState({});
    const [pedding, setPending] = useState(false);

    // 邀请任务选择列表
    const [radioValueData, setRadioValueData] = useState([]);
    // 邀请任务选择列表原始数据，用于判断一打开确认框时，是否展示“重置邀请函状态”按钮
    // const [rawInvitationData, setRawInvitationData] = useState([]);
    const [canReset, setCanReset] = useState(false);
    const [buttonAble, setButtonAble] = useState(false);

    const columns = [
        {
            title: '项目任务',
            dataIndex: 'tasks',
            render: (_, record, index) => {
                return <>
                    <span style={{ marginRight: 4 }}>{`${record.conferenceHallName} / ${record.title}`}</span>
                    <Tag color="blue">{record?.taskName}</Tag>
                    <br/>
                    <span className="task-time">{convertDateTimeRangeToLocalText(record.startTime, record.endTime, 0)}</span>
                    {radioValueData?.[index]?.invitationAccepted === 0 && <Text type="warning" style={{marginLeft: 8}}>未反馈</Text>}
                </>;
            }
        },
        {
            title: '操作',
            dataIndex: 'editGroup',
            width: 260,
            render: (_, record, index) => {
                return <>
                    <Radio.Group value={radioValueData?.[index]?.invitationAccepted}>
                        <Radio value={1} onClick={e => onRadioClick(record, index, 1, e)}>接受</Radio>
                        <Radio value={2} onClick={e => onRadioClick(record, index, 2, e)}>拒绝</Radio>
                    </Radio.Group>
                </>;
            }
        },
    ];

    const onRadioClick = (obj, index, num, e) => {
        let radioValues = radioValueData;

        if (Number(e.target.value) === radioValues[index].invitationAccepted) { // 如果点击的radio值和当前值一样，则取消选中
            radioValues[index].invitationAccepted = 0;
        } else {
            radioValues[index].invitationAccepted = num;
        }

        setRadioValueData(radioValues);
        setData({
            ...data,
            agendas: data.agendas.map((a, i) => {
                if (i === index) {
                    return {...a, invitationAccepted: radioValues[index].invitationAccepted};
                } else {
                    return a;
                }
            }),
        });

        // setButtonAble(!!radioValues.every(item => item.invitationAccepted !== 0));
    };

    const resetInvitation = async () => {
        Modal.confirm({
            title: '提示',
            width: 480,
            content: '重置邀请函状态和日程任务状态。确定重置？',
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await confirmTasksOneApi({
                    agendaList: radioValueData.map(a => ({...a, invitationAccepted: 0})),
                    userCode: userCode,
                    invitationAcceptStatus: 0,
                });
                if (res && res.status_code === 200) {
                    message.success('操作成功');
                    setConfirmInviteOpen(false);
                    updateList()
                } else {
                    message.error(res.message);
                }
            }
        });
    };

    const confirmAction = async () => {
        let invitationAcceptStatus = 0;
        const hasNoFeedback = radioValueData.some(item => item.invitationAccepted === 0);
        if (!hasNoFeedback) {
            const acceptedSome = radioValueData.some(item => item.invitationAccepted === 1);
            const rejectedAll = radioValueData.every(item => item.invitationAccepted === 2);
            if (acceptedSome) {
                invitationAcceptStatus = 1;
            }
            if (rejectedAll) {
                invitationAcceptStatus = 2;
            }
        }

        Modal.confirm({
            title: '提示',
            width: 480,
            content: '请和专家确认邀请日程，再代专家确认邀请函状态。确定调整？',
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await confirmTasksOneApi({
                    agendaList: radioValueData,
                    userCode: userCode,
                    invitationAcceptStatus
                });
                if (res && res.status_code === 200) {
                    message.success('操作成功');
                    setConfirmInviteOpen(false);
                    updateList()
                } else {
                    message.error(res.message);
                }
            }
        });

    };

    const getUserInviteInfo = async () => {
        setPending(true);
        const res = await getInvitationInfoOneApi({ userCode });
        if (res && res.status_code === 200) {
            setPending(false);
            setData(res.data);
            let tmpArr = res.data.agendas.map(item => {
                return {
                    agendaId: item.agendaId,
                    id: item.id,
                    invitationAccepted: item.invitationAccepted,
                    refusalInfo: '',
                };
            });
            const canReset = tmpArr.some(item => item.invitationAccepted !== 0) || false;
            setCanReset(canReset);
            // setButtonAble(!!tmpArr.every(item => item.invitationAccepted !== 0));
            // console.log(tmpArr);
            setRadioValueData(tmpArr);
        } else {
            setPending(false);
        }
    };

    useEffect(() => {
        const tmp = radioValueData.find((item) => {
            return item.invitationAccepted === 0
        })
        if (tmp) {
            setButtonAble(false)
        } else {
            setButtonAble(true)
        }
    }, [JSON.stringify(radioValueData)]);

    useEffect(() => {
        if (open) {
            getUserInviteInfo()
            setButtonAble(false)
        }
    }, [open]);

    // 代嘉宾确认邀请函 Modal 底部按钮
    const confirmModalFooterButtons = [];
    if (canReset) {
        confirmModalFooterButtons.push(<Button danger key="reset" onClick={resetInvitation}>重置邀请函状态</Button>);
    }
    confirmModalFooterButtons.push(<Button type="primary" key="confirm" onClick={confirmAction}>确认邀请函</Button>);

    return <>
        <Modal
            title="代嘉宾确认任务"
            className="modal-wrapper"
            width={800}
            open={open}
            centered
            onCancel={() => setConfirmInviteOpen(false)}
            destroyOnClose={true}
            footer={confirmModalFooterButtons}
        >
            <div className="title-container">
                <span>邀请用户：{data.expertInfo?.memUsername}</span>
                {data.expertInfo?.experiences && (
                    <span className="experiences">
                        <span>单位任职：</span>
                        <span>{data.expertInfo?.experiences?.unitName}</span>
                        <span>{data.expertInfo?.experiences?.[0]?.childs?.[0]?.unitOffice}</span>
                        <span>{data.expertInfo?.experiences?.[0]?.childs?.[0]?.unitPost}</span>
                    </span>
                )}
                {/*{data.invitation?.invitationAcceptStatus === 0 && <span>邀请函接受状态：【未反馈】</span>}*/}
                {/*{data.invitation?.invitationAcceptStatus === 1 && <span>邀请函接受状态：【已确认】</span>}*/}
                {/*{data.invitation?.invitationAcceptStatus === 2 && <span>邀请函接受状态：【已拒绝】</span>}*/}
                {/*{data.invitation?.invitationAcceptStatus === 3 && <span>邀请函接受状态：【待确认】</span>}*/}
            </div>

            <Table
                columns={columns}
                dataSource={data.agendas}
                loading={pedding}
                pagination={false}
            />
        </Modal>
    </>
}


export default ConfirmInvite;
