import React, {Fragment} from 'react';
import {Layout} from 'antd';
const {Content} = Layout;

export default function BiDashboard() {
    return (
        <Fragment>
            <div className="page-title">首页</div>
            <Content className="main-content-box">
                <div className="main-content-title">快捷入口</div>
            </Content>

        </Fragment>
    );
}
