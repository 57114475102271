import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle, useRef, useMemo } from "react";
import { Button, Layout, Card, Form, Input, Select, Tooltip, Row, Col, Divider, Modal, Checkbox, Radio, message, Switch } from "antd";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import "./index.less";
import Choiceitem from "./Choiceitem";
import Morecloze from "./Morecloze";
import Answer from "./Answer";
import Fill from "./Fill";
import Judge from "./Judge";
import Region from "./Region";
import Sort from "./Sort";
import Matrix from "./Matrix";
import UploadImage from "./UploadImage";
import Quantization from "./Quantization";
import Elsign from "./Elsign";
import SelectQuestion from "./SelectQuestion";
import { PartitionOutlined, DragOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable, arrayMoveMutable } from "array-move";
import { previewTemplateApi, timesaveTemplateQuestionApi, saveTemplateQuestionApi, changeTypeApi, delTemplateQuestionApi, sortTemplateQuestionApi } from "@services/Wj/Template";
import { getQuestionBankListApi, getAllLogicApi } from "@services/Wj";
import { previewSurveryApi, delAnswerApi, saveSurveryQuestionApi, timesaveSurveryQuestionApi, delSurveryQusetionApi, sortSurveryQuestionApi, changeSurveyTypeApi, subSurveyApi } from "@services/Wj/Question";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
let id = 0;
const Topic = ({ anserArea, setAnserArea }, ref) => {
    const { Content, Sider } = Layout;
    const [form] = Form.useForm();
    const [action, setAction] = useState(undefined);
    const [titleBorder, setTitleBorder] = useState(false);
    const [titleIn, setTitleIn] = useState(false);
    const [introduceBorder, setIntroduceBorder] = useState(false);
    const [introduceIn, setIntroduceIn] = useState(false);
    //问卷标题
    const [surveyTitle, setSurveyTitle] = useState(undefined);
    //问卷介绍
    const [surveyDirections, setSurveyDirections] = useState(undefined);
    //问卷状态
    const [status, setStatus] = useState(undefined);

    //逻辑数据
    const [logicList, setLogicList] = useState(undefined);

    const [minOpinion, setMinOpinion] = useState(undefined);
    const [maxOpinion, setMaxOpinion] = useState(undefined);
    const [minDefault, setMinDefault] = useState(undefined);
    const [maxDefault, setMaxDefault] = useState(undefined);
    const [addId, setAddId] = useState(undefined);
    const { TextArea } = Input;
    const moreRef = useRef(null);
    const quillRef = useRef(null);
    const start = moreRef.current?.seleteStart;
    const end = moreRef.current?.seleteEnd;
    const location = useLocation();
    const navigate = useNavigate();

    //创建题目
    const questionItem = (type, it) => {
        let sign = anserArea.some((it) => it.questionTypeId === 12);
        if (sign && type === 12) return message.warning("已经有电子签名，请勿重复添加");
        else {
            if (addId) {
                insertQuestion(type, it);
            } else {
                newQuestion(type, it);
            }
        }
    };

    const newQuestion = (type, it) => {
        let question;
        if (it) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: it.questionTypeId,
                remark: false,
                isMultipleChoice: it.isMultipleChoice,
                required: it.required,
                maxSelect: it?.maxSelect,
                minSelect: it?.minSelect,
                body: it.body,
                options: it.questionOptionsList,
                weight: anserArea.length + 1,
            };
        } else if (type === 8) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                required: 0,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                body: "请输入问题",
                options: [
                    { id: 0, content: "1", isBlank: 0 },
                    { id: 1, content: "2", isBlank: 0 },
                    { id: 2, content: "3", isBlank: 0 },
                    { id: 3, content: "4", isBlank: 0 },
                    { id: 4, content: "5", isBlank: 0 },
                ],
                weight: anserArea.length + 1,
            };
        } else if (type === 10) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                required: 0,
                body: "请输入问题",
                options: [
                    {
                        id: 0,
                        content: JSON.stringify([
                            ["列标题1", "列标题2"],
                            ["矩阵行1", "矩阵行2"],
                        ]),
                    },
                ],
                weight: anserArea.length + 1,
            };
        } else if (type === 13) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                required: 0,
                body: "请输入问题",
                options: [
                    { id: 0, content: "选项1", isBlank: 0 },
                    { id: 1, content: "选项2", isBlank: 0 },
                ],
                weight: anserArea.length + 1,
            };
        } else {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                required: 0,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                body: "请输入问题",
                options:
                    type === 3
                        ? [
                              { content: "对", isBlank: 0 },
                              { content: "错", isBlank: 0 },
                          ]
                        : [
                              { id: 0, content: "选项1", isBlank: 0 },
                              { id: 1, content: "选项2", isBlank: 0 },
                          ],
                weight: anserArea.length + 1,
            };
        }
        if (location.state.route === "newCreate") {
            saveTemplateQuestionApi({
                ...question,
                templateId: location.state.id,
            }).then((res) => {
                if (res.status_code === 200) {
                    onReset();
                }
            });
            setAnserArea([...anserArea, question]);
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            saveSurveryQuestionApi({
                ...question,
                surveyId: location.state.id,
            }).then((res) => {
                if (res.status_code === 200) {
                    onReset();
                }
            });
            setAnserArea([...anserArea, question]);
        }
    };

    const insertQuestion = (type, it) => {
        console.log(addId);
        const insertIndex = anserArea.findIndex((it) => it.id === addId);

        let idArr = anserArea.map((item) => Number(item.id));
        let question;
        if (it) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: it.questionTypeId,
                remark: false,
                required: it.required,
                isMultipleChoice: it.isMultipleChoice,
                maxSelect: it?.maxSelect,
                minSelect: it?.minSelect,
                body: it.body,
                options: it.questionOptionsList,
                weight: anserArea[insertIndex]?.weight,
            };
        } else if (type === 8) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                required: 0,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                body: "请输入问题",
                options: [
                    { id: 0, content: "1", isBlank: 0 },
                    { id: 1, content: "2", isBlank: 0 },
                    { id: 2, content: "3", isBlank: 0 },
                    { id: 3, content: "4", isBlank: 0 },
                    { id: 4, content: "5", isBlank: 0 },
                ],
                weight: anserArea.length + 1,
            };
        } else if (type === 10) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                required: 0,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                body: "请输入问题",
                options: [
                    {
                        id: 0,
                        content: JSON.stringify([
                            ["列标题1", "列标题2"],
                            ["矩阵行1", "矩阵行2"],
                        ]),
                    },
                ],
                weight: anserArea.length + 1,
            };
        } else if (type === 13) {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                required: 0,
                body: "请输入问题",
                options: [
                    { id: 0, content: "选项1", isBlank: 0 },
                    { id: 1, content: "选项2", isBlank: 0 },
                ],
                weight: anserArea.length + 1,
            };
        } else {
            question = {
                templateName: surveyTitle,
                directions: surveyDirections,
                questionTypeId: type,
                remark: false,
                required: 0,
                isMultipleChoice: 0,
                maxSelect: 0,
                minSelect: 0,
                body: "请输入问题",
                weight: anserArea[insertIndex]?.weight,
                options:
                    type === 3
                        ? [
                            { content: "对", isBlank: 0 },
                            { content: "错", isBlank: 0 },
                        ]
                        : [
                            { id: 0, content: "选项1", isBlank: 0 },
                            { id: 1, content: "选项2", isBlank: 0 },
                        ],
            };
        }
        if (location.state.route === "newCreate") {
            saveTemplateQuestionApi({
                ...question,
                templateId: location.state.id,
            }).then((res) => {
                if (res.status_code === 200) {
                    idArr.splice(insertIndex + 1, 0, res.data.question.id);
                    let newId = res.data.question.id;
                    sortTemplateQuestionApi({ questionIds: idArr }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                            setAddId(newId);
                        }
                    });
                }
            });
            // setAnserArea([...anserArea, question])
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            saveSurveryQuestionApi({
                ...question,
                surveyId: location.state.id,
            }).then((res) => {
                if (res.status_code === 200) {
                    idArr.splice(insertIndex + 1, 0, res.data.question.id);
                    let newId = res.data.question.id;
                    sortSurveryQuestionApi({ questionIds: idArr }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                            setAddId(newId);
                        }
                    });
                }
            });
            // setAnserArea([...anserArea, question])
        }
    };
    useEffect(() => {
        getQuestionBankListApi().then((res) => {
            if (res.status_code === 200) {
                let arr = [];
                res.data.map((item) => {
                    if (item.typeList?.length > 0 || item.questionList?.length > 0) {
                        arr.push(item);
                    }
                });
                setAction([...arr]);
            }
        });

        getAllLogicApi({ surveyId: location.state.id }).then((res) => {
            if (res.status_code === 200) {
                setLogicList(res.data);
            }
        });
    }, []);

    useEffect(() => {
        if (addId) {
            setTimeout(() => {
                let a = document.getElementById(`question-box-${addId}`);
                console.log(a);
                a.style.border = "2px solid #1a73e8";
            }, 500);
        }
    }, [addId]);

    const typechange = [
        {
            value: 1,
            label: "单选题",
        },
        {
            value: 2,
            label: "多选题",
        },
        {
            value: 3,
            label: "判断题",
        },
        {
            value: 4,
            label: "填空题",
        },
        {
            value: 5,
            label: "多项填空题",
        },
        {
            value: 6,
            label: "问答题",
        },
        {
            value: 8,
            label: "量表题",
        },
        {
            value: 9,
            label: "排序题",
        },
        {
            value: 10,
            label: "矩形填空",
        },
        {
            value: 11,
            label: "文件收集",
        },
        {
            value: 12,
            label: "电子签名",
        },
        {
            value: 13,
            label: "选择列表",
        },
    ];
    const onReset = () => {
        if (location.state.route === "newCreate") {
            previewTemplateApi({ id: location.state.id }).then((res) => {
                if (res.status_code === 200) {
                    form.setFieldValue("title", res.data.name);
                    form.setFieldValue("introduce", res.data.directions);
                    setSurveyTitle(res.data.name);
                    setSurveyDirections(res.data.directions);
                    setAnserArea(res.data.questions);
                    if (res.data.questions) {
                        setAnserArea(res.data.questions);
                    } else {
                        setAnserArea([]);
                    }
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            previewSurveryApi({ id: location.state.id }).then((res) => {
                if (res.status_code === 200) {
                    form.setFieldValue("title", res.data.name);
                    form.setFieldValue("introduce", res.data.directions);
                    setSurveyTitle(res.data.name);
                    setSurveyDirections(res.data.directions);
                    setStatus(res.data.status);
                    if (res.data.questions) {
                        setAnserArea(res.data.questions);
                    } else {
                        setAnserArea([]);
                    }
                }
            });
        }
    };
    useEffect(() => {
        onReset();
    }, []);

    const allDoc = (e) => {
        console.log(e.target.className);
        if (e.target?.id) {
            e.target?.id === addId ? setAddId(addId) : setAddId(undefined);
        } else {
            setAddId(undefined);
        }
    };
    const onCopy = (item) => {
        const insertIndex = anserArea.findIndex((it) => it.id === item.id);

        let idArr = anserArea.map((item) => Number(item.id));
        let sendValue = {
            ...item,
            templateId: location.state.id,
        };
        var insertId;
        if (location.state.route === "newCreate") {
            saveTemplateQuestionApi(sendValue).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                    idArr.splice(insertIndex + 1, 0, res.data.question.id);

                    sortTemplateQuestionApi({ questionIds: idArr }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                        }
                    });
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            saveSurveryQuestionApi(sendValue).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                    idArr.splice(insertIndex + 1, 0, res.data.question.id);

                    sortSurveryQuestionApi({ questionIds: idArr }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                        }
                    });
                }
            });
        }
    };

    const addItem = (e, item) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
        console.log(item.id);
        let a = document.getElementById(`question-box-${item.id}`);
        a.style.border = "2px solid #1a73e8";
        setAddId(item.id);
    };

    const onDelete = (key) => {
        Modal.confirm({
            title: "提示",
            content: "删除后本题目将不再能找回，确定要删除吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                if (location.state.route === "newCreate") {
                    delTemplateQuestionApi({ id: key }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                        }
                    });
                } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                    let delIndex = anserArea.findIndex((it) => it.id === key);
                    anserArea.splice(delIndex, 1);
                    let idArr = anserArea.map((it) => it.id);
                    delSurveryQusetionApi({ id: key }).then((res) => {
                        if (res.status_code === 200) {
                            if (idArr.length > 0) {
                                sortSurveryQuestionApi({ questionIds: idArr }).then((res) => {
                                    if (res.status_code === 200) {
                                        onReset();
                                    }
                                });
                            } else {
                                onReset();
                            }
                        }
                    });
                }
            },
        });
    };
    //发布&保存
    // const release = () => {
    //     if(!surveyTitle){
    //         message.error('请输入问卷标题')
    //         return false
    //     }else if(!surveyDirections){
    //         message.error('请输入问卷介绍')
    //         return false
    //     }else {
    //         return true
    //     }

    // }
    useImperativeHandle(ref, () => ({
        surveyTitle,
        allDoc,
    }));

    const changeOpinion = (type, len, size) => {
        if (type === "min") {
            let res = [];
            if (size === null) size = 0;
            let arr = len.map((item, index) => {
                if (index >= size && size !== 0) {
                    res.push({ id: `${index + 1}`, value: `${index + 1}项`, key: `${index + 1}`, disabled: true });
                } else {
                    res.push({ id: `${index + 1}`, value: `${index + 1}项`, key: `${index + 1}` });
                }
                return res;
            });
            res.unshift({
                id: 0,
                key: 0,
                value: "不限",
            });

            return res;
        } else if (type === "max") {
            let res = [];
            if (size === null) size = 0;
            let arr = len.map((item, index) => {
                if (index + 1 < size) {
                    res.push({ id: `${index + 1}`, value: `${index + 1}项`, key: `${index + 1}`, disabled: true });
                } else {
                    res.push({ id: `${index + 1}`, value: `${index + 1}项`, key: `${index + 1}` });
                }
                return res;
            });
            res.unshift({
                id: 0,
                key: 0,
                value: "不限",
            });

            return res;
        }
    };

    const onChangeSelect = (type, key, value, item) => {
        if (type === "min") {
            setMinDefault(value.key);
            let obj = { ...item };
            obj.templateId = obj.surveyId;
            obj.minSelect = Number(value.key);
            if (location.state.route === "newCreate") {
                timesaveTemplateQuestionApi(obj).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                        onReset();
                    }
                });
            } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                timesaveSurveryQuestionApi(obj).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                        onReset();
                    }
                });
            }
        } else if (type === "max") {
            console.log(minOpinion);
            setMaxDefault(value.key);
            let obj = { ...item };
            obj.templateId = obj.surveyId;
            obj.maxSelect = Number(value.key);
            if (location.state.route === "newCreate") {
                timesaveTemplateQuestionApi(obj).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                        onReset();
                    }
                });
            } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                timesaveSurveryQuestionApi(obj).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                        onReset();
                    }
                });
            }
        }
    };

    const onSelectMultipleChoice = (value, item) => {
        console.log(location.state);
        let obj = { ...item };
        obj.isMultipleChoice = value == true ? 1 : 0;
        obj.maxSelect = 0;
        obj.minSelect = 0;
        obj.templateId = obj.surveyId;
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(obj).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                    onReset();
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            timesaveSurveryQuestionApi(obj).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                    onReset();
                }
            });
        }
    };

    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        let sort = arrayMoveImmutable(anserArea, oldIndex, newIndex);
        setAnserArea(sort);
        let idArr = sort.map((item) => Number(item.id));
        if (location.state.route === "newCreate") {
            sortTemplateQuestionApi({ questionIds: idArr }).then((res) => {
                if (res.status_code === 200) {
                    onReset();
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            sortSurveryQuestionApi({ questionIds: idArr, isModify: true }).then((res) => {
                if (res.status_code === 200) {
                    onReset();
                }
            });
        }
    };

    const onRequire = (e, item) => {
        let req = e.target.checked === true ? 1 : 0;
        const requiredArr = [...anserArea];
        const index = requiredArr.findIndex((it) => it.id === item.id);
        requiredArr[index] = {
            ...requiredArr[index],
            templateId: location.state.id,
            required: req,
        };
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(requiredArr[index]);
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            timesaveSurveryQuestionApi(requiredArr[index]);
        }
        setAnserArea(requiredArr);
    };

    const handleChange = (value, item) => {
        let sign = anserArea.some((it) => it.questionTypeId === 12);
        if (value == 12 && sign) return message.warning("已经有电子签名，请勿重复添加"), onReset();
        else {
            let sendValue;
            if (item.questionTypeId > 2) {
                sendValue = {
                    ...item,
                    id: item.id,
                    questionTypeId: value,
                    options: [
                        { content: "选项1", isBlank: 0 },
                        { content: "选项2", isBlank: 0 },
                    ],
                };
            } else {
                sendValue = {
                    ...item,
                    id: item.id,
                    questionTypeId: value,
                };
            }

            if (value === 3) {
                sendValue = {
                    ...item,
                    id: item.id,
                    questionTypeId: value,
                    options: [
                        { content: "对", isBlank: 0 },
                        { content: "错", isBlank: 0 },
                    ],
                };
            }
            if (value === 8) {
                sendValue = {
                    ...item,
                    id: item.id,
                    questionTypeId: value,
                    options: [
                        { id: 0, content: "1", isBlank: 0 },
                        { id: 1, content: "2", isBlank: 0 },
                        { id: 2, content: "3", isBlank: 0 },
                        { id: 3, content: "4", isBlank: 0 },
                        { id: 4, content: "5", isBlank: 0 },
                    ],
                };
            }
            if (value === 9) {
                sendValue = {
                    ...item,
                    id: item.id,
                    questionTypeId: value,
                    options: [
                        { content: "选项1", isBlank: 0 },
                        { content: "选项2", isBlank: 0 },
                    ],
                };
            }
            if (value === 10) {
                sendValue = {
                    ...item,
                    id: item.id,
                    questionTypeId: value,
                    options: [
                        {
                            id: 0,
                            content: JSON.stringify([
                                ["列标题1", "列标题2"],
                                ["矩阵行1", "矩阵行2"],
                            ]),
                        },
                    ],
                };
            }
            if (location.state.route === "newCreate") {
                console.log(sendValue);
                changeTypeApi(sendValue).then((res) => {
                    if (res.status_code === 200) {
                        onReset();
                    }
                });
            } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                if (status === 0) {
                    changeSurveyTypeApi(sendValue).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                        }
                    });
                } else {
                    subSurveyApi({ surveyId: location.state.id }).then((res) => {
                        if (res.status_code === 200) {
                            if (res.data > 0) {
                                Modal.confirm({
                                    title: "提示",
                                    content: (<span>问卷已有{res.data}份回收数据，<span style={{color:'red'}}>切换题型后，题目相关数据将被清空，无法在统计结果中查看，</span>确定切换吗？</span>),
                                    cancelText: "切换",
                                    okText: "取消",
                                    cancelButtonProps:{type:'primary'},
                                    okButtonProps:{type:'default'},
                                    onCancel: () => {
                                        changeSurveyTypeApi(sendValue).then((res) => {
                                            if (res.status_code === 200) {
                                                delAnswerApi({ questionId: item.id });
                                                onReset();
                                            }
                                        });
                                    },
                                    onOk: () => {
                                        onReset();
                                    },
                                });
                            } else {
                                changeSurveyTypeApi(sendValue).then((res) => {
                                    if (res.status_code === 200) {
                                        delAnswerApi({ questionId: item.id });
                                        onReset();
                                    }
                                });
                            }
                        }
                    });
                }
            }
        }
    };
    const goLogic = () => {
        localStorage.setItem("logic", "add");
        navigate("/wj/surveys/new/logic", { state: { ...location.state, title: form.current?.surveyTitle } });
    };
    const SortableMainElement = SortableElement(({ item, index }) => (
        <div className="main_item_wrap" id={`question-box-${item.id}`} onClick={(e) => addItem(e, item, "in")} onMouseDown={(e) => e.stopPropagation()}>
            <Form.Item
                className="question-item"
                name={`question-item-${item.id}`}
                key={item.id}
                rules={[
                    {
                        required: true,
                        message: "",
                    },
                ]}
            >
                <div className="question-action">
                    <div>
                        {item.questionTypeId > 6 && item.questionTypeId !== 8 && item.questionTypeId !== 9 && item.questionTypeId !== 10 && item.questionTypeId !== 11 && item.questionTypeId !== 12 && item.questionTypeId !== 13 ? (
                            <Tooltip placement="topLeft" title="本题不支持切换" arrowPointAtCenter>
                                <Select
                                    defaultValue={item.questionTypeId ? item.questionTypeId : null}
                                    style={{
                                        width: 110,
                                    }}
                                    size={"small"}
                                    onChange={(value) => handleChange(value, item)}
                                    options={[
                                        {
                                            value: 7,
                                            label: "地区",
                                        },
                                    ]}
                                    disabled
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                />
                            </Tooltip>
                        ) : (
                            <Select
                                defaultValue={item.questionTypeId ? item.questionTypeId : null}
                                style={{
                                    width: 110,
                                }}
                                size={"small"}
                                onChange={(value) => handleChange(value, item)}
                                options={typechange}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            />
                        )}

                        <Checkbox className="question-require" onChange={(e) => onRequire(e, item)} checked={item.required}>
                            必答
                        </Checkbox>

                        {location.state.route !== "newCreate" &&
                            (item.questionTypeId === 1 ? (
                                <span className="drop-item" onClick={() => goLogic()} onMouseDown={(e) => e.stopPropagation()}>
                                    <PartitionOutlined /> 逻辑
                                </span>
                            ) : item.questionTypeId === 3 ? (
                                <span className="drop-item" onClick={() => goLogic()} onMouseDown={(e) => e.stopPropagation()}>
                                    <PartitionOutlined /> 逻辑
                                </span>
                            ) : item.questionTypeId === 8 ? (
                                <span className="drop-item" onClick={() => goLogic()} onMouseDown={(e) => e.stopPropagation()}>
                                    <PartitionOutlined /> 逻辑
                                </span>
                            ) : null)}
                        {
                            //  location.state.route !== "newCreate"&&

                            item.questionTypeId === 13 && (
                                <div className="select-question-switch">
                                    支持多选:
                                    <Switch size="small" checkedChildren="开" unCheckedChildren="关" onChange={(value) => onSelectMultipleChoice(value, item)} defaultChecked={item.isMultipleChoice === 0 || item.isMultipleChoice === null ? false : true} />
                                </div>
                            )
                        }
                        {
                            // location.state.route !== "newCreate"&&
                            (item.questionTypeId === 2 || (item.questionTypeId === 13 && item?.isMultipleChoice === 1)) && (
                                <div className="select_range">
                                    可选范围:最少
                                    <Select
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        style={{
                                            width: 80,
                                        }}
                                        options={changeOpinion("min", item.options, item.maxSelect)}
                                        size={"small"}
                                        // defaultValue={}
                                        onSelect={(key, value) => onChangeSelect("min", key, value, item)}
                                        defaultValue={item.minSelect === 0 || item.minSelect === null ? "不限" : `${item.minSelect}项`}
                                        onMouseDown={(e) => e.stopPropagation()}
                                    />
                                    最多
                                    <Select
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        style={{
                                            width: 80,
                                        }}
                                        options={changeOpinion("max", item.options, item.minSelect)}
                                        size={"small"}
                                        // defaultValue={}
                                        onSelect={(key, value) => onChangeSelect("max", key, value, item)}
                                        defaultValue={item.maxSelect === 0 || item.maxSelect === null ? "不限" : `${item.maxSelect}项`}
                                        onMouseDown={(e) => e.stopPropagation()}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className="qusetion-action-right">
                        <div>
                            <span className="drop-item">
                                <DragOutlined onMouseDown={(e) => e.stopPropagation()} />
                                拖动
                            </span>
                        </div>
                        {item.questionTypeId !== 12 ? (
                            <div>
                                <span className="drop-item" onClick={() => onCopy(item)} onMouseDown={(e) => e.stopPropagation()}>
                                    <CopyOutlined />
                                    复制
                                </span>
                            </div>
                        ) : null}
                        <div>
                            <span className="drop-item" onClick={() => onDelete(item.id)} onMouseDown={(e) => e.stopPropagation()}>
                                <DeleteOutlined />
                                删除
                            </span>
                        </div>
                    </div>
                </div>

                <div>
                    {item.questionTypeId === 1 && <Choiceitem questionId={`questionId-${id}`} type="single" item={item} anserArea={anserArea} setAnserArea={setAnserArea} logicList={logicList} onReset={onReset} />}
                    {item.questionTypeId === 2 && <Choiceitem questionId={`questionId-${id}`} type="multiple" item={item} anserArea={anserArea} setAnserArea={setAnserArea} logicList={logicList} onReset={onReset} />}
                    {item.questionTypeId === 3 && <Judge questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 4 && <Fill questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 5 && <Morecloze questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} ref={moreRef} start={start} end={end} onReset={onReset} />}
                    {item.questionTypeId === 6 && <Answer questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 7 && <Region questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}

                    {item.questionTypeId === 8 && <Quantization questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 9 && <Sort questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 10 && <Matrix questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 11 && <UploadImage questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 12 && <Elsign questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                    {item.questionTypeId === 13 && <SelectQuestion questionId={`questionId-${id}`} item={item} anserArea={anserArea} setAnserArea={setAnserArea} onReset={onReset} />}
                </div>
            </Form.Item>
        </div>
    ));

    const SortableMainContainer = SortableContainer(({ items }) => {
        return <div className="main_content_wrap">{items.length ? items.map((item, index) => <SortableMainElement item={item} index={index} key={index}></SortableMainElement>) : null}</div>;
    });

    const getBorder = (e, type) => {
        e.stopPropagation();
        if (type === "title") {
            setTitleBorder(true);
            setTitleIn(true);
        } else if (type === "introduce") {
            setIntroduceBorder(true);
            setIntroduceIn(true);
        }
    };

    const cancelBorder = (type) => {
        if (type === "title") {
            setTitleBorder(false);
            setTitleIn(false);
            let sendValue = {
                templateId: location.state.id,
                templateName: form.getFieldValue("title") === "" ? "问卷标题" : form.getFieldValue("title"),
                directions: form.getFieldValue("introduce"),
            };
            if (location.state.route === "newCreate") {
                timesaveTemplateQuestionApi(sendValue).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                        if (form.getFieldValue("title") === "") {
                            form.setFieldValue("title", "问卷标题");
                        }
                    }
                });
            } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                timesaveSurveryQuestionApi(sendValue).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                        if (form.getFieldValue("title") === "") {
                            form.setFieldValue("title", "问卷标题");
                        }
                    }
                });
            }
            setSurveyTitle(form.getFieldValue("title"));
        } else if (type === "introduce") {
            setIntroduceBorder(false);
            setIntroduceIn(false);
            let sendValue = {
                templateId: location.state.id,
                templateName: form.getFieldValue("title"),
                directions: form.getFieldValue("introduce"),
            };
            if (location.state.route === "newCreate") {
                timesaveTemplateQuestionApi(sendValue).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                    }
                });
            } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                timesaveSurveryQuestionApi(sendValue).then((res) => {
                    if (res.status_code === 200) {
                        message.success("保存成功");
                    }
                });
            }
            setSurveyDirections(form.getFieldValue("introduce"));
        }
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                    ],
                ],
            },
        };
    }, []);

    const getDescBorder = () => {
        let a = document.querySelector(".introduce");
        a.style.border = "1px solid #1a73e8";
        let b = document.querySelectorAll(".ql-toolbar");
        b[0].style.display = "block";
        setIntroduceBorder(true);
        setIntroduceIn(true);
    };
    const cancelDescBorder = () => {
        let a = document.querySelector(".introduce");
        a.removeAttribute("style");
        let b = document.querySelectorAll(".ql-toolbar");
        b[0].style.display = "none";
        let sendValue = {
            templateId: location.state.id,
            templateName: form.getFieldValue("title"),
            directions: form.getFieldValue("introduce"),
        };
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(sendValue).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                }
            });
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            timesaveSurveryQuestionApi(sendValue).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                }
            });
        }
        setSurveyDirections(form.getFieldValue("introduce"));
        setIntroduceBorder(false);
        setIntroduceIn(false);
    };

    return (
        <Fragment>
            <Sider width={180}>
                <div className="question-button-action vertical-scrollbar">
                    {action &&
                        action.map((item) => {
                            return (
                                <div>
                                    <p className="question-action-title">{item.name}</p>
                                    {item.typeList &&
                                        item.typeList.map((it) => {
                                            return (
                                                <Button
                                                    className="basis-question"
                                                    onClick={() => questionItem(it.questionType)}
                                                    // onMouseDown={(e) =>
                                                    //     e.stopPropagation()
                                                    // }
                                                >
                                                    {it.name}
                                                </Button>
                                            );
                                        })}
                                    {item.questionList &&
                                        item.questionList.map((it) => {
                                            return (
                                                <Button className="basis-question" onClick={() => questionItem(it.questionTypeId, it)} onMouseDown={(e) => e.stopPropagation()}>
                                                    {it.title}
                                                </Button>
                                            );
                                        })}
                                </div>
                            );
                        })}
                </div>
            </Sider>
            <Content className="main-content-box" onClick={(e) => allDoc(e)} onMouseDown={(e) => e.stopPropagation()}>
                <div className="question-area vertical-scrollbar">
                    <Form form={form}>
                        <Form.Item
                            name={"title"}
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <Input
                                className="title"
                                bordered={titleBorder}
                                onFocus={(e) => getBorder(e, "title")}
                                onBlur={(e) => cancelBorder("title")}
                                onMouseDown={(e) => e.stopPropagation()}
                                placeholder={"请输入标题"}
                                maxLength={50}
                                style={{
                                    border: titleIn ? "1px solid #1a73e8" : "",
                                }}
                            ></Input>
                        </Form.Item>
                        <Form.Item
                            name={"introduce"}
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            {/* <TextArea
                                        className='introduce vertical-scrollbar'
                                        bordered={introduceBorder}
                                        onFocus={(e) => getBorder('introduce')}
                                        onBlur={(e) => cancelBorder('introduce')}
                                        placeholder={'请输入问卷介绍'}
                                        style={{border:introduceIn ? '1px solid #1a73e8':''}}
                                        autoSize={{
                                            minRows: 2,
                                            maxRows: 5,
                                        }}></TextArea> */}
                            <ReactQuill theme="snow" ref={quillRef} className="introduce" modules={modules} placeholder="请输入问题" onFocus={() => getDescBorder()} onBlur={() => cancelDescBorder()} />
                        </Form.Item>
                        <hr className="division"></hr>
                        <SortableMainContainer items={anserArea} onSortEnd={onSortEndHandler} distance={10}></SortableMainContainer>
                    </Form>
                </div>
            </Content>
        </Fragment>
    );
};

export default forwardRef(Topic);
