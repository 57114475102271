import React, { useRef, useState, useEffect } from 'react';
import useChart from '@/hooks/useCharts';

export default function BrowserChart({
    browserData
}) {
    const deviceChartRef = useRef(null)

    const [echartsData, setEchartsData] = useState([]);

    useEffect(() => {
        let arr = [];
        if (browserData) {
            browserData.forEach(item => {
                arr.push(item)
            })
            setEchartsData(arr)
        }
        setEchartsData(arr)
    }, [browserData]);

    const option = {
        tooltip: {//提示
            trigger: 'item',
            backgroundColor: 'rgba(255,255,255,0.9)',
            axisPointer: {//hover的时候没有虚线
                type: 'none'
            },
            formatter: '{b}: ({d}%)'
        },
        legend: {
            top: 'bottom',
            left: 'center',
            icon: "circle",
            itemWidth: 10
        },
        color: ['#3fa5fe', '#3fe5fe', '#91cc75', '#fac858', '#ee6666'],
        series: [
            {
                type: 'pie',
                radius: ['33%', '60%'],
                center: ['45%', '45%'],
                avoidLabelOverlap: false,
                labelLine: {
                    length: 20,
                    length2: 0
                },
                label:{
                    formatter: '{b}: ({d}%)'
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    itemStyle: {
                        opacity: '0.9'
                    },
                    scale: 0
                },
                data: echartsData
            }
        ]
    };

    useChart(deviceChartRef, option)
    return (
        <div className="device-chart" ref={deviceChartRef}></div>
    )
}
