import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { Form, Input, Button, Layout, Table, Modal, Space, message } from 'antd';
import ProductionFilterForm from './ProductionFilterForm';
import ProductionAddForm from './ProductionAddForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { fullPagerConfig } from '@/utils/property';
import { get, post, put, del } from '@/core/request';
import './ProductionLines.less';

const { Content } = Layout;

export default function ProductionLines() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});
    // 所属企业
    const [orgIds, setOrgId] = useState([]);

    // 所属企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    const [updatedByStaffIds, setUpdatedByStaffId] = useState([]);

    // 操作人
    useEffect(() => {
        get('/dms/users/findAll').then((res) => {
            setUpdatedByStaffId(res.data);
        })
    }, []);

    // 筛选数据
    const handleFilterFinish = useCallback(
        (value) => {
            setFilterObj(value);
        },
        [],
    );

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, orgId, status, isCooperation, isJkjDisplay, createStartTime, createEndTime, updatedByStaffId } = filterObj;

    const getData = () => {
        get(`/dms/production-lines`, {
            limit: pageSize,
            page: currPage,
            name: name,
            orgId: orgId,
            status: status,
            isCooperation: isCooperation,
            isJkjDisplay: isJkjDisplay,
            createStartTime: createStartTime,
            createEndTime: createEndTime,
            updatedByStaffId: updatedByStaffId
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        });
    };

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, name, orgId, status, isCooperation, isJkjDisplay, createStartTime, createEndTime, updatedByStaffId])

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const [form] = Form.useForm();

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        (tip, row) => {
            return () => {
                drawer.current.show(tip, row);
            }
        },
        [],
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除产品线',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/production-lines', {
                        ids: [row.id]
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // 批量删除
    const deleteAllConfirm = () => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除选中产品线',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (selectedRowKeys.length !== 0) {
                    del('/dms/production-lines', {
                        ids: selectedRowKeys
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                } else {
                    message.warning('当前没有选中任何产品线');
                }
            }
        });
    };

    // 点击数据统计
    const navigate = useNavigate();
    const changePath = useCallback(
        (row) => {
            return () => {
                navigate(`/dms/production-lines/${row.id}/data/usage`, { state: row });
            }
        },
        [navigate],
    );

    // 点击操作区禁用
    const stopConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用选中标签',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.status === 1) {
                        status = 2;
                    }
                    put('/dms/production-lines/batchDisableOrEnable', {
                        ids: [row.id],
                        status
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    });
                }
            });
        }
    };

    // 批量启用，批量禁用
    const handleDisable = (status) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用选中标签',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    put('/dms/production-lines/batchDisableOrEnable', {
                        ids: selectedRowKeys,
                        status
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                            setSelectedRowKeys([]);
                        }
                    });
                }
            });
        }
    }

    // 点击重置密码
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [passwordModal, setPasswordModal] = useState({})

    const showModal = (row) => {
        return () => {
            get(`/dms/production-lines/reset-pwd-pre`, {
                productId: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    setPasswordModal(res.data);
                }
            })
            setIsModalVisible(true);
        }
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                values.id = passwordModal.id;
                values.phone = passwordModal.phone;
                put('/dms/production-lines/reset-pwd', values).then(res => {
                    if (res.status_code === 200) {
                        setIsModalVisible(false);
                        message.success('您已成功修改密码');
                        form.resetFields();
                    }
                });
            })
            .catch((err) => { });
        setPedding(false);

    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const columns = [
        { title: '产品线ID', dataIndex: 'id', key: 'id' },
        { title: '所属企业ID', dataIndex: 'orgId', },
        { title: '产品线', dataIndex: 'name', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '域名', dataIndex: 'domain', },
        { title: '联系人', dataIndex: 'contact', },
        { title: '电话号码', dataIndex: 'phone', },
        { title: '操作人', dataIndex: 'createdByStaffName', },
        { title: '创建时间', dataIndex: 'createdAt', },
        {
            title: '合作时间', dataIndex: 'cooperationEndTime',
            render: (operates, row) => (
                <span>{row.cooperationStartTime}至{row.cooperationEndTime}</span>
            )
        },
        {
            title: '状态', dataIndex: 'status',
            render: (operates) => (<span>{operates === 1 ? '正常' : '禁用'}</span>)
        },
        {
            title: '是否已合作', dataIndex: 'isCooperation',
            render: (operates) => (<span>{operates === 1 ? '已合作' : '未合作'}</span>)
        },
        {
            title: '官网展示', dataIndex: 'isJkjDisplay',
            render: (operates) => (<span>{operates === 1 ? '展示' : '不展示'}</span>)
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handleClickAdd('edit', row)}>编辑</span>
                    <span className="operate-style" onClick={stopConfirm(row)}>{row.status === 1 ? '禁用' : '启用'}</span>
                    <span className="operate-style" onClick={changePath(row)}>数据统计</span>
                    <span className="operate-style" onClick={showModal(row)}>重置密码</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 17 }
    };

    // 点击新增
    const onHandleFinish = (values) => {
        post(`/dms/production-lines`, values).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
            }
        })
    };

    // 点击编辑
    const onHandlePutFinish = (values) => {
        put(`/dms/production-lines`, values).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData()
            }
        });
    }

    return (
        <Fragment>
            <div className="page-title">产品线列表</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ProductionFilterForm
                        handleFilterFinish={handleFilterFinish}
                        orgId={orgIds}
                        updatedByStaffId={updatedByStaffIds}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">

                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickAdd('add')}>新增产品线</Button>
                                <Button type="danger" onClick={deleteAllConfirm}>批量删除</Button>
                                <Button type="default" onClick={handleDisable(1)}>批量启用</Button>
                                <Button type="default" onClick={handleDisable(2)}>批量禁用</Button>
                            </Space>
                        </div>
                    </div>

                    <Table className="production-table" rowSelection={rowSelection} columns={columns}
                        dataSource={tabledata.list} pagination={paginationProps} />

                </div>
            </Content>

            <ProductionAddForm ref={drawer} orgId={orgIds} onHandleFinish={onHandleFinish} onHandlePutFinish={onHandlePutFinish}></ProductionAddForm>

            {
                isModalVisible && <Modal title="重置密码" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Form form={form} {...formLayout}>
                        <Form.Item label="产品线">
                            {passwordModal.productName}
                        </Form.Item>

                        <Form.Item label="管理员手机号">
                            {passwordModal.phone}
                        </Form.Item>

                        <Form.Item
                            name="newPwd"
                            label="新密码"
                            required={true}
                            help="密码不少于6位，必须包含数字或字母（大写或小写）"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/.test(value)) {
                                            return Promise.reject('密码不少于6位，必须包含数字或字母（大写或小写');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="为该产品线管理员设置新的密码" allowClear />
                        </Form.Item>
                    </Form>
                </Modal>
            }


        </Fragment>
    )
}
