import React, { Fragment , useState,useEffect} from 'react';
import './index.less';
import phoneHeader from '@static/images/phoneheader.png';
import config from '@config';
const Mobilpreview = ({content,preview}) => {
    console.log(content);
    console.log(config.SURVEY_DOMAIN);
    useEffect(() => {
        if(preview){
            if(content.route==='newCreate'){
                document.fr.location.href=`${config.SURVEY_DOMAIN}/s/${content.id}/result?template=1`;
            }else{
                document.fr.location.href=`${config.SURVEY_DOMAIN}/s/${content.id}/result?boss=1`;
            }
        }
    }, [content,preview]);
    return (
        <Fragment>
            <div className='mobile-box'>
                <div className='mobile-final'>
                    <img src={phoneHeader} className='phone-header'/>
                    <div className='modal-content'>
                        <iframe name="fr" className='iframe-width'  id="frame"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Mobilpreview;
