import { get, put, post } from '@core/request';

/*
    接口服务--调研组件
*/

// 获取问卷统计详情 127.0.0.1:9015/survey/report/statistical-detail?surveyId=199
export const getStatisticalDataApi = (params) => {
    return get('/survey/report/statistical-detail', params);
};

// 获取问卷统计概览 127.0.0.1:9015/survey/report/statistical-overview?surveyId=199
export const getStatisticalOverviewApi = (params) => {
    return get('/survey/report/statistical-overview', params);
};

// 获取生成报告详情 127.0.0.1:9015/survey/report/generate?surveyId=199
export const getGenerateApi = (params) => {
    return get('/survey/report/generate', params);
};

// 编辑报告标题logo 127.0.0.1:9015/survey/report/edit-title
export const putEdititleApi = (params) => {
    return put('/survey/report/edit-title', params);
};

//调研报告详情查看
export const getCustomReportApi = (params) => {
    return get('survey/report/display-report', params);
};
// 编辑报告每个题的说明 127.0.0.1:9015/survey/report/edit-question
export const putEditQuestionApi = (params) => {
    return put('/survey/report/edit-question', params);
};

// 文件上传
export const uploadFile = (params) => {
    return post('/survey/file', params);
};
