import React, { Fragment, useState,useEffect,useRef,useMemo} from "react";
import { Link, useParams,useLocation,useNavigate } from "react-router-dom";
import {
    LeftOutlined,
    UploadOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import {
    pageFormItemLayout,
    pageFormItemWithoutLabelGridLayout,
} from "@utils/property";
import {
    Form,
    Input,
    Layout,
    Calendar,
    Radio,
    Checkbox,
    Dropdown,
    DatePicker,
    Space,
    Button,
    Select,
    Switch,
    Upload,
    Col,
    Row,
    message,
    Image,
    Tag,
    Cascader,
} from "antd";
import moment from "moment";
import "./index.less";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import ImgCrop from 'antd-img-crop';
import areas from "@utils/areas";
import { fileApi, conferenceSaveApi, conferenceOnceApi, conferenceUpdateApi } from "@services/Conference";
import config from '@config';

const { Content } = Layout;

Quill.register('modules/imageResize', ImageResize);
export default function Conference() {
    const [dateValue, setDateValue] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [coverList, setCoverList] = useState([]);
    const [datePicker, setDatePicker] = useState(undefined);
    const [oneData, setOneData] = useState(false);
    const [dateTrues, setDateTrues] = useState(false);
    const [hide,setHide] = useState(false);
    const [titleName,setTitleName] = useState(undefined);
    const [descriptions,setDescriptions] = useState(undefined);
    const navigate = useNavigate();
    const params = useParams();
    const id = params?.id || 0;
    const option = { headers: { "Content-Type": "multipart/form-data" } };
    const location = useLocation();
    const [form] = Form.useForm();
    const refs = useRef()

    const checkout = (file, size, initWidth, initHeight) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            setOneData(true);
            return null;
        } else {
            setOneData(false);
        }
        // 判断大小
        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            message.error("图像必须小于" + size + "MB!");
            setOneData(true);
            return null;
        } else {
            setOneData(false);
        }
        return isJpgOrPng && isLt2M;
        // && getFileWidthAndHeight(file, initWidth, initHeight);
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 分享图
    const shareImageFile = {
        name: "shareImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 14);
            if (!oneData) {
                fileApi(formData, option).then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setFileList([getFileUrl(res.data)]);
                        form.setFieldValue("shareImageUrl", getFileUrl(res.data));
                    }
                });
            } else {
                form.setFieldValue("shareImageUrl", undefined);
            }
        },
    };

    const coverImageFile = {
        name: "coverImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 14);
            if (!oneData) {
                fileApi(formData, option).then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setCoverList([getFileUrl(res.data)]);
                        form.setFieldValue("cover", getFileUrl(res.data));
                    }
                });
            } else {
                form.setFieldValue("cover", undefined);
            }
        },
    };





    const onChange = (date, dateString) => {
        setDatePicker(date);
        // setDateTrues(true);
        let dateList
        if(dateValue===null)dateList=[]
        else dateList = [...dateValue];
        dateList.push(dateString);
        dateList.sort(function (a, b) {
            return new Date(a) - new Date(b);
        });

        form.setFieldValue("dates", dateList);
       if(dateList.length > 0) setDateTrues(false);
        setDateValue(dateList);

    };

    const hideChange=(checked,type)=>{
        if(type === 'healthcarePlatformHidden'){
            setHide({
                ...hide,
                healthcarePlatformHidden:checked

            })
        }
        else if(type === 'hidden'){
            setHide({
                ...hide,
                hidden:checked})
        }

    }



    const disabledDate = (d) => {
        if (dateValue&&dateValue.includes(d.format("YYYY-MM-DD"))) return true;
        else return false || d < moment().subtract(1, "days");
    };


    const onOpenChange = () => {
        setDatePicker(undefined);
    };

    const onFinish = (values) => {
        if(!dateValue||dateValue.length===0)setDateTrues(true)
        form.validateFields().then(() => {
            let addRess = form.getFieldValue("address");
            let provinceCode='',cityCode='',districtCode=''
            if(addRess){
                provinceCode = addRess?.[0] ? addRess[0] : "";
                cityCode = addRess?.[1] ? addRess[1] : "";
                districtCode = addRess?.[2] ? addRess[2] : "";
            }

            let dateString = [...dateValue];
            dateString = dateString.join();
            let obj={
                ...form.getFieldsValue(),
                provinceCode,
                cityCode,
                districtCode,
                dates:dateString,
                hidden:hide.hidden?0:1,
                healthcarePlatformHidden:hide.healthcarePlatformHidden?0:1,
            }
            if(location.state?.edit==='edit'){
                obj.id=location.state.id
                if(dateValue.length>0){
                    conferenceUpdateApi({...obj}).then(res=>{
                        if(res.status_code===200){
                            message.success('修改成功')
                            navigate('/conf/conferences')
                        }
                    })
                }
            } else{

                if(dateValue.length>0){
                    // console.log(1);
                    conferenceSaveApi({...obj}).then(res=>{
                        if(res.status_code===200){
                            message.success('保存成功')
                            navigate('/conf/conferences')
                        }
                    })
                }else{
                    // console.log(1);
                    setDateTrues(true);
                }
            }
        });


    };

    const onReset = () => {
       navigate('/conf/conferences')
    };

    const removeTag = (tag, index) => {
        const dateList = dateValue.filter((item) => item !== tag);
        setDateValue(dateList);
    };

    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 13);
            const hide = message.loading("上传中...", 0);
            fileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = refs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{header: 1}, {header: 2}],
                    [{list: "ordered"}, {list: "bullet"}],
                    [{script: "sub"}, {script: "super"}],
                    [{indent: "-1"}, {indent: "+1"}],
                    [{direction: "rtl"}],
                    [{size: ["small", false, "large", "huge"]}], //字体设置
                    [
                        {
                            color: [],
                        },
                    ],
                    [
                        {
                            background: [],
                        },
                    ],
                    [{font: []}],
                    [{align: []}],
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
                },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    const onDescriptionsChange = (v) => {
            setDescriptions(v);
        };

    const beforeCropCheck = (file, bool) => {
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                message.error('只支持JPG/JPEG/PNG格式。');
                return null;
            }

            if (file.size > 2 * 1024 * 1024) {
                message.error('图片大小不能超过2MB。');
                return null;
            }

            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = function (event) {
                    const res = event.target.result;
                    const imgDom = document.createElement('img');
                    imgDom.src = res;
                    imgDom.onload = function () {
                        if (imgDom.width < 750 || imgDom.height < 422) {
                            bool && message.warn('会议项目封面不小于750×422px。');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    };
                };
                reader.readAsDataURL(file);
            });
        };

    useEffect(() => {
        if(location.state?.edit==='edit'){
            conferenceOnceApi({id:location.state.id}).then((res)=>{
                if(res.status_code==200){
                    let address = []
                    if (res.data.provinceCode === null || res.data.provinceCode === '') {
                        address = []
                    } else if (res.data.cityCode === null || res.data.cityCode === '') {
                        address.push(res.data?.provinceCode)
                    } else if (res.data?.districtCode === null || res.data?.districtCode === '') {
                        address.push(res.data?.provinceCode)
                        address.push(res.data?.cityCode)
                    } else {
                        address.push(res.data?.provinceCode)
                        address.push(res.data?.cityCode)
                        address.push(res.data?.districtCode)
                    }
                    let dates=res.data.dates.split(',')
                    setDateValue([...dates])
                    setFileList([res.data?.shareImageUrl])
                    setCoverList(res.data?.cover?[res.data?.cover]:[])
                    let hidden=res.data.hidden===1?false:true
                    let healthcarePlatformHidden=res.data.healthcarePlatformHidden===1?false:true
                    setHide({hidden:hidden,healthcarePlatformHidden:healthcarePlatformHidden})
                    let obj={...res.data,address,dates}
                    form.setFieldsValue(obj)
                    setDateTrues(false)
                    setTitleName(res.data.name)
                }
            })
        }else{
        }

    }, []);
    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/conf/conferences`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">
                    {!id ? "新增会议项目" : "编辑会议项目"}
                </span>
                {!!titleName && <span className="current-subtitle">{titleName}</span>}
            </div>

            <Content className="main-content-box">
                <div className="main-content-title">会议项目信息</div>

                <div className="main-content-body">
                    <Form
                        className="conference-form"
                        {...pageFormItemLayout}
                        form={form}
                    >
                        <Form.Item
                            name="h3yunNo"
                            label="氚云编号"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="输入氚云编号" maxLength={100} allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="name"
                            label="会议项目名称"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="输入会议项目名称" maxLength={100} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="slug"
                            label="英文简称"
                            rules={[
                                { required: true, message: '' },
                                { pattern: /^[a-z\d_\-]{2,20}$/, message: '英文简称不符合格式要求' }
                            ]}
                            help="英文简称，如 chs2023，小写字母、数字、中划线（-）或下划线（_），限2-20个字符"
                        >
                            <Input placeholder="请输入英文简称" maxLength={20} showCount allowClear/>
                        </Form.Item>

                        <Form.Item name="dates" label="日期" className="conference_date_edit">
                            <div className="date_Btn">
                                <Button onClick={(e) => e.preventDefault()} size="small" type="text">
                                    <PlusCircleOutlined />添加日期
                                </Button>
                                <DatePicker
                                    className="date_Picker"
                                    onChange={onChange}
                                    onOpenChange={onOpenChange}
                                    value={datePicker}
                                />
                            </div>

                            {dateValue&&(!!dateValue.length )&& (
                                <div className="dates" style={{ marginTop: ".5rem" }}>
                                    {dateValue.map(tag => <Tag key={tag} closable={true} onClose={() => removeTag(tag)}>{tag}</Tag>)}
                                </div>
                            )}
                            {console.log(dateValue&&dateValue.length == 0,dateTrues)}
                            {((dateValue&&dateValue.length == 0) || dateTrues) ? <span className="conference_error">请添加日期</span>:''}
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label="会议形式"
                            rules={[{ required: true, message: '请选择会议形式' }]}
                        >
                            <Radio.Group>
                                <Radio value={3}>线上 + 线下</Radio>
                                <Radio value={1}>线上</Radio>
                                <Radio value={2}>线下</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="cover"
                            label="会议项目封面"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 1500*844px（不小于 750*422px，比例16:9），文件大小不超过2MB"
                            className="share-image"
                        >
                            {coverList.length > 0 && <Image width={200} src={coverList[0]} className="share-image-area"/>}

                            <div>
                            <ImgCrop
                            aspect={16 / 9}
                            minZoom={0.1}
                            maxZoom={2}
                            beforeCrop={async (file) => {
                                const res = await beforeCropCheck(file, true);
                                return res;
                            }}
                        >
                                <Upload
                                    beforeUpload={(file) =>
                                        checkout(file, 2, 300, 300)
                                    }
                                    fileList={coverList}
                                    {...coverImageFile}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {coverList.length > 0
                                            ? "更改图片"
                                            : "上传图片"}
                                    </Button>
                                </Upload>
                        </ImgCrop>

                            </div>
                        </Form.Item>

                        <Form.Item
                            name="intro"
                            label="会议项目简介"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="项目简介主要用于会议项目列表处简单介绍性文字"
                        >
                            <Input.TextArea rows={3} maxLength={200} showCount/>
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="会议项目介绍"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入邀请函内容'
                                },
                                {
                                    validator: async (rule, value) => {
                                        if (value === "") {
                                            throw "请输入邀请函内容";
                                        } else if (value === "<p><br></p>") {
                                            throw "请输入邀请函内容";
                                        }
                                    },
                                },
                            ]}
                        >
                            <ReactQuill
                                ref={refs}
                                modules={modules}
                                theme="snow"
                                value={descriptions}
                                onChange={onDescriptionsChange}
                                className="ql-editor  react-editor"
                            />
                        </Form.Item>

                        <Form.Item name="address" label="城市">
                            <Cascader options={areas} placeholder="请选择城市" changeOnSelect/>
                        </Form.Item>

                        <Form.Item name="place" label="场地">
                            <Input placeholder="输入会议举办场地" allowClear />
                        </Form.Item>

                        <Form.Item
                            name="shareTitle"
                            label="微信分享标题"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input placeholder="输入分享标题" maxLength={30} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="shareDesc"
                            label="微信分享描述"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input placeholder="输入分享描述" maxLength={30} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="shareImageUrl"
                            label="微信分享图"
                            rules={[
                                { required: true, message: '' }
                            ]}
                            help="支持JPG/PNG格式，尺寸300&times;300px，大小不超过2M"
                            className="share-image"
                        >
                            {fileList.length > 0 && <Image width={200} src={fileList[0]} className="share-image-area"/>}

                            <div>
                                <Upload
                                    beforeUpload={(file) => checkout(file, 2, 300, 300)}
                                    fileList={fileList}
                                    {...shareImageFile}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {fileList.length > 0 ? '更改图片' : '上传图片'}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>

                        <Form.Item
                            {...pageFormItemWithoutLabelGridLayout}
                            style={{ marginTop: 24, marginBottom: 0 }}
                        >
                            <Space size="large">
                                <Button type="primary" size="large" htmlType="submit" onClick={onFinish}>提交</Button>
                                <Button type="default" size="large" onClick={onReset}>取消</Button>
                            </Space>
                        </Form.Item>

                    </Form>
                </div>

            </Content>
        </Fragment>
    );
}
