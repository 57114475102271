import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Layout, Space, Table, Tooltip, Form, Select, Input, Row, Col, DatePicker, Avatar, Tag, Typography, message, Alert, TreeSelect } from 'antd';
import { LeftOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { conferenceActions } from '../store/conferenceSlice';
import { conferenceOnceApi } from '@services/Conference';
import { guestjourneysApi, GuestJourneyTotalApi } from '@services/Guest';
import { getConferenceListApi } from '@services/Agenda';
import './GuestJourneyList.less';
import GuestJourneyForm from './components/GuestJourney';
import { expertLevels } from '@utils/constants';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { convertDateTimeRangeToLocalText } from '@utils/utils';
import { localStorageGet } from '@core/cookie';
import GuestJourneySetting from './components/GuestJourneySetting';
import GuestJourneyImport from './components/GuestJourneyImport';
import config from '@config';
import { HotelList } from '../constants';

const { Content } = Layout;
const { Text } = Typography;

const { setGuestJourneyFilter: setFilter, setGuestJourneyCursor: setCursor } = conferenceActions;

const guestIdentityList = [
    { value: 1, label: '参会嘉宾' },
    { value: 2, label: '参会代表' },
    { value: 3, label: '企业人员' },
    { value: 4, label: '工作人员' },
    { value: 0, label: '待定' },
];

export default function GuestJourneyList() {
    const params = useParams();
    const navigate = useNavigate();

    const conferenceId = Number(params?.id) || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    // 嘉宾行程筛选条件
    const filter = useSelector(state => state.conference.guestJourney.filter);
    // 标记操作的记录ID
    const cursor = useSelector(state => state.conference.guestJourney.cursor);

    // 嘉宾行程列表
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    // 嘉宾行程表单 Modal
    const [visibleGuestJourney, setVisibleGuestJourney] = useState(false);
    const [currentGuestJourney, setCurrentGuestJourney] = useState(null); // 当前编辑的嘉宾行程

    // 筛选表单项
    const [dateOptions, setDateOptions] = useState([]); //任务日期

    const [selectedExpertsIds, setSelectedExpertsIds] = useState([]);

    // 行程单设置
    const [visibleGuestJourneySetDrawer, setVisibleGuestJourneySetDrawer] = useState(false);

    // 导入行程单
    const [importDataOpen, setImportDataOpen] = useState(false);

    // 合计信息
    const [guestTotalInfo, setGuestTotalInfo] = useState();

    // 会议列表
    const [conferenceHallTreeData, setConferenceHallTreeData] = useState([]);

    // 打开嘉宾行程表单
    const openGuestJourney = () => {
        setVisibleGuestJourney(true);
    };

    // 关闭嘉宾行程表单
    const closeGuestJourney = () => {
        setVisibleGuestJourney(false);
        getGuestJourneyTotal();
    };

    // 获取会议项目信息
    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({ id: conferenceId });
        if (res.status_code === 200) {
            setConference(res.data);

            const dates = res.data.dates ? res.data.dates.split(',') : [];
            let datesArr = [];
            dates.forEach(item => {
                datesArr.push({ value: `${item} 00:00:00,${item} 12:00:00,AM`, label: `${moment(item).format('YYYY年MM月DD日')} 上午` });
                datesArr.push({ value: `${item} 12:00:00,${item} 23:59:59,PM`, label: `${moment(item).format('YYYY年MM月DD日')} 下午` });
            });
            setDateOptions(datesArr);
        }
    };

    // 检索
    const onFinish = values => {
        const newValues = dealDateParams(values);

        let newFilter = {
            ...filter,
            ...newValues,
            page: 1,
        };

        // 兼容接口未处理 [] 的情况
        if (isEmpty(newFilter?.roles)) {
            delete newFilter.roles;
        }
        if (isEmpty(newFilter?.expertLevels)) {
            delete newFilter.expertLevels;
        }
        dispatch(setFilter(newFilter));
    };

    // 重置
    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            page: 1,
            pageSize: 20,
        }));
    };

    // 传参、回显时间范围
    const dealDateParams = (values) => {
        const newValues = { ...values };
        newValues.taskBeginTimeBegin = values.taskBeginTime && moment(values.taskBeginTime[0]).isValid() ? moment(values.taskBeginTime[0]).format('YYYY-MM-DD HH:mm') : undefined;
        newValues.taskBeginTimeEnd = values.taskBeginTime && moment(values.taskBeginTime[1]).isValid() ? moment(values.taskBeginTime[1]).format('YYYY-MM-DD HH:mm') : undefined;
        newValues.taskEndTimeBegin = values.taskEndTime && moment(values.taskEndTime[0]).isValid() ? moment(values.taskEndTime[0]).format('YYYY-MM-DD HH:mm') : undefined;
        newValues.taskEndTimeEnd = values.taskEndTime && moment(values.taskEndTime[1]).isValid() ? moment(values.taskEndTime[1]).format('YYYY-MM-DD HH:mm') : undefined;
        delete newValues.taskBeginTime;
        delete newValues.taskEndTime;

        newValues.pickupDropoffComeBegin = values.pickupDropoffCome && moment(values.pickupDropoffCome[0]).isValid() ? moment(values.pickupDropoffCome[0]).format('YYYY-MM-DD HH:mm') : undefined;
        newValues.pickupDropoffComeEnd = values.pickupDropoffCome && moment(values.pickupDropoffCome[1]).isValid() ? moment(values.pickupDropoffCome[1]).format('YYYY-MM-DD HH:mm') : undefined;
        newValues.pickupDropoffGoBegin = values.pickupDropoffGo && moment(values.pickupDropoffGo[0]).isValid() ? moment(values.pickupDropoffGo[0]).format('YYYY-MM-DD HH:mm') : undefined;
        newValues.pickupDropoffGoEnd = values.pickupDropoffGo && moment(values.pickupDropoffGo[1]).isValid() ? moment(values.pickupDropoffGo[1]).format('YYYY-MM-DD HH:mm') : undefined;
        delete newValues.pickupDropoffCome;
        delete newValues.pickupDropoffGo;

        return newValues;
    };

    // 编辑行程
    const editGuestJourney = (record) => {
        setCurrentGuestJourney({ ...record });
        setVisibleGuestJourney(true);
        dispatch(setCursor(record.id));
    };

    // 预览行程单
    const previewGuestJourney = (record) => {
        window.open(`${config.UCENTER_PREFIX}/m/journey?conferenceId=${conferenceId}&expertId=${record.id}&title=${encodeURIComponent(conference?.name)}`)
    };

    // 嘉宾身份
    const getGuestIdentity = (role) => {
        if (role === 0) {
            return '待定'
        }
        if (role === 1) {
            return '参会嘉宾'
        }
        if (role === 2) {
            return '参会代表'
        }
        if (role === 3) {
            return '企业人员'
        }
        if (role === 4) {
            return '工作人员'
        }
    };

    // 嘉宾列表
    const getExpertList = async () => {
        let params = {
            ...filter,
            conferenceId,
        };

        try {
            setLoading(true);

            let res = await guestjourneysApi(params);
            if (res.status_code === 200) {
                setLoading(false);

                setData(res.data.list);
                setTotal(res.data.totalCount);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }

    };

    const toggleAgendaTasks = guestAgendaInfo => {
        setData(data.map(item => {
            if (item.id === guestAgendaInfo.id) {
                return { ...item, expanded: !item.expanded };
            } else {
                return item;
            }
        }));
    };

    const columns = [
        {
            title: "嘉宾基本信息",
            dataIndex: "name",
            width: 200,
            render: (_, record) => (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!!record?.expertAvatar
                        ? <Avatar src={record?.expertAvatar} style={{ verticalAlign: 'middle' }} />
                        : (
                            <Avatar style={{ verticalAlign: 'middle' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                </svg>
                            </Avatar>
                        )
                    }
                    <div style={{ marginLeft: '5px' }}>
                        <span style={{ marginRight: 4 }}>{record.memUsername}</span>
                        <span>
                            {record.memGender === 1
                                ? (
                                    <svg style={{ color: '#1890ff' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-male" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z" />
                                    </svg>
                                )
                                : record.memGender === 0
                                    ? (
                                        <svg style={{ color: '#eb2f96' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-gender-female" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z" />
                                        </svg>
                                    )
                                    : null}
                        </span>
                        <div className="expert-level">
                            <Text type={record.expertLevel >= 1 && record.expertLevel <= 6 ? 'warning' : 'secondary'}>
                                {expertLevels.find(a => a.level === record.expertLevel)?.label}
                            </Text>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        record.ssoMemberWorkExperiencesEntities && !!record.ssoMemberWorkExperiencesEntities.length && record.ssoMemberWorkExperiencesEntities.map((item, idx) => {
                            return (
                                <div key={item.id}>
                                    {record.ssoMemberWorkExperiencesEntities.length > 1 && <span>{idx + 1}. </span>}
                                    {!!item.unitName && <span>{item.unitName}</span>}
                                    {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                    {!!item.unitPost && <span>{item.unitPost}</span>}
                                </div>
                            )
                        })
                    }
                </div>
                <div>{record.memMobile}</div>
                <div>{record.idCard}</div>
                <div>{getGuestIdentity(record?.guestsEntity?.role)}</div>
            </>),
        },
        {
            title: "项目任务",
            dataIndex: "tasks",
            width: 300,
            render: (_, record) => {
                if (record.agendasGuestsList && record.agendasGuestsList.length) {
                    return <>
                        <ul className="task-list">
                            {record.agendasGuestsList.map((item, idx) => {
                                return (
                                    <li key={idx} style={{ display: idx !== 0 && !record.expanded ? 'none' : '' }}>
                                        <span style={{ marginRight: 4 }}>{`${item.conferenceHallName} / ${item.title}`}</span>
                                        <Tag color="blue">{item?.taskName}</Tag>
                                        {item.invitationAccepted === 0 && <Text type="secondary">未反馈</Text>}
                                        {item.invitationAccepted === 1 && <Text type="success">已确认</Text>}
                                        {item.invitationAccepted === 2 && <Tooltip placement="top" trigger="click" title={item?.refusalInfo}><span style={{ color: '#F00', textDecoration: 'underline', cursor: 'pointer' }}>已拒绝</span></Tooltip>}
                                        {item.invitationAccepted === 3 && <Text type="warning">待确认</Text>}
                                        <br />
                                        <span className="task-time">{convertDateTimeRangeToLocalText(item.startTime, item.endTime, 0)}</span>
                                    </li>
                                );
                            })}
                        </ul>

                        {record.agendasGuestsList.length > 1 && (
                            <div
                                className="toggle-task-expansion"
                                style={{ color: '#1890ff', cursor: 'pointer', fontSize: 12 }}
                                onClick={() => toggleAgendaTasks(record)}
                            >
                                <span className="anticon" style={{ transform: !!record?.expanded ? 'rotate(180deg)' : 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16" style={{ width: 12, height: 12 }}>
                                        <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
                                    </svg>
                                </span>
                                <span>{!!record?.expanded ? '收起' : `展开${record.agendasGuestsList.length}个任务`}</span>
                            </div>
                        )}
                    </>;
                } else {
                    return <Text type="secondary">未安排任务</Text>;
                }
            },
        },
        {
            title: "来程信息",
            dataIndex: "arrivalInfo",
            width: 450,
            render: (_, record) => {
                const arrivalInfo = record?.guestJourneysEntity?.arrivalInfo ? JSON.parse(record?.guestJourneysEntity?.arrivalInfo) : {};
                return <>
                    <div className="system-title">【系统】已接受日程最早开始时间：<span>{record?.minDate || '暂无'}</span></div>
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">交通：</span>
                        <div>{isEmpty(arrivalInfo?.traffic) && '暂无'}</div>
                        {
                            !isEmpty(arrivalInfo?.traffic) &&
                            <div>
                                {!arrivalInfo?.traffic?.traffic_type && '无需交通'}
                                {
                                    !!arrivalInfo?.traffic?.traffic_type &&
                                    <>
                                        <div>{arrivalInfo?.traffic?.traffic_type === 1 ? '飞机' : '火车'} {arrivalInfo?.traffic?.traffic_no}</div>
                                        <div>
                                            {arrivalInfo?.traffic?.departure_place}
                                            {arrivalInfo?.traffic?.departure_place && arrivalInfo?.traffic?.arrival_place && ' 至 '}
                                            {arrivalInfo?.traffic?.arrival_place}
                                        </div>
                                        <div>
                                            {arrivalInfo?.traffic?.departure_datetime}
                                            {arrivalInfo?.traffic?.departure_datetime && arrivalInfo?.traffic?.arrival_datetime && ' 至 '}
                                            {arrivalInfo?.traffic?.arrival_datetime}
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">接送：</span>
                        <div>{isEmpty(arrivalInfo?.pickup_dropoff) && '暂无'}</div>
                        {
                            !isEmpty(arrivalInfo?.pickup_dropoff) &&
                            <div>
                                {!arrivalInfo?.pickup_dropoff?.need_pickup_dropoff && '无需接送'}
                                {
                                    !!arrivalInfo?.pickup_dropoff?.need_pickup_dropoff &&
                                    <>
                                        <div>
                                            {arrivalInfo?.pickup_dropoff?.driver_name && '（司机）'}
                                            {arrivalInfo?.pickup_dropoff?.driver_name && <>{arrivalInfo?.pickup_dropoff?.driver_name}&nbsp;</>}
                                            {arrivalInfo?.pickup_dropoff?.driver_phone && <>{arrivalInfo?.pickup_dropoff?.driver_phone}&nbsp;</>}
                                            {arrivalInfo?.pickup_dropoff?.driver_car_color && <>{arrivalInfo?.pickup_dropoff?.driver_car_color}&nbsp;</>}
                                            {arrivalInfo?.pickup_dropoff?.driver_car_no}
                                        </div>
                                        <div>
                                            {arrivalInfo?.pickup_dropoff?.departure_place}
                                            {arrivalInfo?.pickup_dropoff?.departure_place && arrivalInfo?.pickup_dropoff?.arrival_place && ' 至 '}
                                            {arrivalInfo?.pickup_dropoff?.arrival_place}
                                        </div>
                                        <div>
                                            {arrivalInfo?.pickup_dropoff?.departure_datetime}
                                            {arrivalInfo?.pickup_dropoff?.departure_datetime && arrivalInfo?.pickup_dropoff?.arrival_datetime && ' 至 '}
                                            {arrivalInfo?.pickup_dropoff?.arrival_datetime}
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">备注：</span>
                        <div>{isEmpty(arrivalInfo?.remarks) ? '暂无' : arrivalInfo?.remarks}</div>
                    </div>
                </>
            },
        },
        {
            title: "返程信息",
            dataIndex: "returnInfo",
            width: 450,
            render: (_, record) => {
                const returnInfo = record?.guestJourneysEntity?.returnInfo ? JSON.parse(record?.guestJourneysEntity?.returnInfo) : {};
                return <>
                    <div className="system-title">【系统】已接受日程最晚结束时间：<span>{record?.maxDate || '暂无'}</span></div>
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">交通：</span>
                        <div>{isEmpty(returnInfo?.traffic) && '暂无'}</div>
                        {
                            !isEmpty(returnInfo?.traffic) &&
                            <div>
                                {!returnInfo?.traffic?.traffic_type && '无需交通'}
                                {
                                    !!returnInfo?.traffic?.traffic_type &&
                                    <>
                                        <div>{returnInfo?.traffic?.traffic_type === 1 ? '飞机' : '火车'} {returnInfo?.traffic?.traffic_no}</div>
                                        <div>
                                            {returnInfo?.traffic?.departure_place}
                                            {returnInfo?.traffic?.departure_place && returnInfo?.traffic?.arrival_place && ' 至 '}
                                            {returnInfo?.traffic?.arrival_place}
                                        </div>
                                        <div>
                                            {returnInfo?.traffic?.departure_datetime}
                                            {returnInfo?.traffic?.departure_datetime && returnInfo?.traffic?.arrival_datetime && ' 至 '}
                                            {returnInfo?.traffic?.arrival_datetime}
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">接送：</span>
                        <div>{isEmpty(returnInfo?.pickup_dropoff) && '暂无'}</div>
                        {
                            !isEmpty(returnInfo?.pickup_dropoff) &&
                            <div>
                                {!returnInfo?.pickup_dropoff?.need_pickup_dropoff && '无需接送'}
                                {
                                    !!returnInfo?.pickup_dropoff?.need_pickup_dropoff &&
                                    <>
                                        <div>
                                            {returnInfo?.pickup_dropoff?.driver_name && '（司机）'}
                                            {returnInfo?.pickup_dropoff?.driver_name && <>{returnInfo?.pickup_dropoff?.driver_name}&nbsp;</>}
                                            {returnInfo?.pickup_dropoff?.driver_phone && <>{returnInfo?.pickup_dropoff?.driver_phone}&nbsp;</>}
                                            {returnInfo?.pickup_dropoff?.driver_car_color && <>{returnInfo?.pickup_dropoff?.driver_car_color}&nbsp;</>}
                                            {returnInfo?.pickup_dropoff?.driver_car_no}
                                        </div>
                                        <div>
                                            {returnInfo?.pickup_dropoff?.departure_place}
                                            {returnInfo?.pickup_dropoff?.departure_place && returnInfo?.pickup_dropoff?.arrival_place && ' 至 '}
                                            {returnInfo?.pickup_dropoff?.arrival_place}
                                        </div>
                                        <div>
                                            {returnInfo?.pickup_dropoff?.departure_datetime}
                                            {returnInfo?.pickup_dropoff?.departure_datetime && returnInfo?.pickup_dropoff?.arrival_datetime && ' 至 '}
                                            {returnInfo?.pickup_dropoff?.arrival_datetime}
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">备注：</span>
                        <div>{isEmpty(returnInfo?.remarks) ? '暂无' : returnInfo?.remarks}</div>
                    </div>
                </>
            },
        },
        {
            title: "住宿信息",
            dataIndex: "hotelInfo",
            width: 450,
            render: (_, record) => {
                const hotelInfo = record?.guestJourneysEntity?.hotelInfo ? JSON.parse(record?.guestJourneysEntity?.hotelInfo) : {};
                return <>
                    <div className="system-title">【系统】建议入住日期：<span>{getHotelDate(record)[0] || '暂无'}</span></div>
                    <div className="system-title">【系统】建议退房日期：<span>{getHotelDate(record)[1] || '暂无'}</span></div>
                    {
                        isEmpty(hotelInfo?.hotel) &&
                        <div style={{ display: 'flex' }}>
                            <span className="info-title">酒店：</span>
                            <div>暂无</div>
                        </div>
                    }
                    {
                        !isEmpty(hotelInfo?.hotel) && <>
                            {
                                hotelInfo?.hotel?.need_hotel === 1 &&
                                <div>
                                    <div style={{ display: 'flex' }}><span className="hotel-title">入住日期：</span><div>{hotelInfo?.hotel?.checkin_date || '暂无'}</div></div>
                                    <div style={{ display: 'flex' }}><span className="hotel-title">退房日期：</span><div>{hotelInfo?.hotel?.checkout_date || '暂无'}</div></div>
                                    <div style={{ display: 'flex' }}><span className="hotel-title">酒店名称：</span><div>{hotelInfo?.hotel?.hotel_name || '暂无'}</div></div>
                                    <div style={{ display: 'flex' }}><span className="hotel-title">酒店地址：</span><div>{hotelInfo?.hotel?.hotel_address || '暂无'}</div></div>
                                    <div style={{ display: 'flex' }}><span className="hotel-title">房间类型：</span><div>{hotelInfo?.hotel?.hotel_room_type || '暂无'}</div></div>
                                    <div style={{ display: 'flex' }}><span className="hotel-title">房间号：</span><div>{hotelInfo?.hotel?.hotel_room_no || '暂无'}</div></div>
                                </div>
                            }
                            {hotelInfo?.hotel?.need_hotel === 0 && <div style={{ display: 'flex' }}><span className="info-title">酒店：</span><div>无需入住酒店</div></div>}
                        </>
                    }
                    <div style={{ display: 'flex' }}>
                        <span className="info-title">备注：</span>
                        <div>{isEmpty(hotelInfo?.remarks) ? '暂无' : hotelInfo?.remarks}</div>
                    </div>
                </>
            },
        },
        {
            title: "操作",
            dataIndex: "operations",
            width: 220,
            fixed: 'right',
            render: (_, record) => {
                return <>
                    <Button size="small" type="link" onClick={() => editGuestJourney(record)}>编辑嘉宾行程</Button>
                    {record.guestJourneysEntity && <Button size="small" type="link" onClick={() => previewGuestJourney(record)}>预览行程单</Button>}
                </>
            },
        },
    ];

    const getHotelDate = (record) => {
        let checkinDate = '';
        let checkoutDate = '';

        if (record && record?.minDate && record.maxDate) {
            const minDate = moment(record?.minDate);
            const maxDate = moment(record?.maxDate);
            if (minDate.isValid() && maxDate.isValid()) {
                const diffDays = maxDate.diff(minDate, 'days');
                if (diffDays >= 1) {
                    checkinDate = minDate.format('YYYY-M-D');
                    checkoutDate = maxDate.format('YYYY-M-D');
                }
            }
        }

        return [checkinDate, checkoutDate];
    };

    const guestSelection = {
        selectedRowKeys: selectedExpertsIds,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0) {
                setSelectedExpertsIds([...selectedRowKeys]);
            } else {
                setSelectedExpertsIds([]);
            }
        },
    };

    // 切换页码
    const onChangePage = (page, pageSize) => {
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };

    // 导出行程单
    const exportGuestJourney = () => {
        window.location.href = `${config.API_PREFIX}/conference/boss/guestjourneys/exportGuestJourneysByConferenceId?conferenceId=${conferenceId}`;
    };

    // 导入行程单
    const importGuestJourney = () => {
        setImportDataOpen(true);
    };

    // 下载嘉宾行程模板
    const downTemplate = () => {
        window.location.href = 'https://mediafiles.cn-healthcare.com/template/conference/%E5%98%89%E5%AE%BE%E8%A1%8C%E7%A8%8B%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx';
    };

    // 行程单设置
    const settingGuestJourney = () => {
        setVisibleGuestJourneySetDrawer(true);
    };

    // 获取合计信息
    const getGuestJourneyTotal = async () => {
        const res = await GuestJourneyTotalApi({ conferenceId });
        if (res.status_code === 200 && res.data) {
            setGuestTotalInfo(res.data);
        }
    };

    const getDateRoom = (days) => {
        if (days && days.length) {
            return <>
                （
                {days.map((item, index) => (<span key={index}>{item.day}住宿{item.num}人{index !== days.length - 1 ? '；' : ''}</span>))}
                ）
            </>
        }
        return '';
    }

    // 会议论坛列表 - 筛选
    const prepareConferenceHallTreeData = data => {
        let treeData = [];

        if (Object.keys(data) && Object.keys(data).length) {
            Object.keys(data).forEach(agendaDate => {
                const dateNode = {
                    title: agendaDate,
                    value: agendaDate,
                    selectable: false,
                    hallNum: 0,
                    children: []
                };

                const confHalls = data[agendaDate];
                confHalls && confHalls.length && confHalls.forEach(confHall => {
                    const hallNode = {
                        title: `${confHall.name} [YHYC#${confHall.id}]`,
                        value: confHall.id,
                        children: []
                    };

                    const confSubHalls = confHall.secondHallsList || [];
                    if (confSubHalls && confSubHalls.length) {
                        hallNode.selectable = false;

                        confSubHalls.forEach(subHall => {
                            dateNode.hallNum += 1;

                            hallNode.children.push({
                                title: `${subHall.name} [YHYC#${subHall.id}]`,
                                value: subHall.id
                            });
                        });
                    } else {
                        dateNode.hallNum += 1;
                    }

                    dateNode.children.push(hallNode);
                });

                treeData.push(dateNode);
            });
        }
        return treeData;
    };

    // 获取会议列表
    const getConferenceList = async () => {
        const res = await getConferenceListApi({ conferenceId });
        if (res.status_code === 200 && res.data) {
            const agendaData = res.data;
            setConferenceHallTreeData(prepareConferenceHallTreeData(agendaData));
        }
    };

    useEffect(() => {
        const newFilter = { ...filter }
        newFilter.taskBeginTime = filter.taskBeginTimeBegin && filter.taskBeginTimeEnd ? [moment(filter.taskBeginTimeBegin), moment(filter.taskBeginTimeEnd)] : undefined;
        newFilter.taskEndTime = filter.taskEndTimeBegin && filter.taskEndTimeEnd ? [moment(filter.taskEndTimeBegin), moment(filter.taskEndTimeEnd)] : undefined;
        newFilter.pickupDropoffCome = filter.pickupDropoffComeBegin && filter.pickupDropoffComeEnd ? [moment(filter.pickupDropoffComeBegin), moment(filter.pickupDropoffComeEnd)] : undefined;
        newFilter.pickupDropoffGo = filter.pickupDropoffGoBegin && filter.pickupDropoffGoEnd ? [moment(filter.pickupDropoffGoBegin), moment(filter.pickupDropoffGoEnd)] : undefined;

        form.setFieldsValue(newFilter);
        getExpertList();
    }, [filter]);

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
            getGuestJourneyTotal(conferenceId);
            getConferenceList();
        }

        let isHaveAuth = false;
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        isHaveAuth = roleList.some(item => item.name === '嘉宾行程管理');

        if (!isHaveAuth) {
            message.error('暂无权限');
            navigate(`/conf/conferences/${conferenceId}/guests`);
        }
    }, []);

    return (
        <>
            <div className="page-title">
                <Link to={`/conf/conferences/${conferenceId}/guests`} className="back"><LeftOutlined /></Link>
                <span className="current-title">嘉宾行程管理</span>
                <span className="current-subtitle">{conference?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾姓名" name="name">
                                    <Input allowClear placeholder="嘉宾姓名" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="单位" name="unitName">
                                    <Input allowClear placeholder="单位名称关键字" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="科室/部门" name="unitOffices">
                                    <Input allowClear placeholder="所在科室或部门关键字" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="职务" name="unitPost">
                                    <Input allowClear placeholder="职务关键字" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="任务日期" name="agendTime">
                                    <Select
                                        options={dateOptions}
                                        placeholder="请选择日期"
                                        allowClear
                                    ></Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾身份" name="roles">
                                    <Select
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        options={guestIdentityList}
                                        placeholder="请选择身份"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾级别" name="expertLevels">
                                    <Select
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        options={expertLevels}
                                        fieldNames={{ label: 'label', value: 'level' }}
                                        placeholder="请选择嘉宾级别"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="嘉宾任务状态" name="invitationAccepted">
                                    <Select
                                        options={[
                                            { value: 0, label: '未反馈' },
                                            { value: 1, label: '已接受' },
                                            { value: 2, label: '已拒绝' },
                                            // { value: 3, label: '待确认' },
                                        ]}
                                        placeholder="请选择嘉宾任务接受状态"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="任务所在会议" name="conferenceHallId">
                                    <TreeSelect
                                        showSearch
                                        dropdownStyle={{ minWidth: 500, overflow: 'auto' }}
                                        allowClear
                                        treeDefaultExpandAll
                                        placement="bottomRight"
                                        treeNodeFilterProp="title"
                                        treeData={conferenceHallTreeData}
                                        placeholder="请选择会议"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="任务开始时间" name="taskBeginTime">
                                    <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} placeholder={['开始时间', '结束时间']} />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="任务结束时间" name="taskEndTime">
                                    <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} placeholder={['开始时间', '结束时间']} />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="是否录入行程" name="joinJourney">
                                    <Select
                                        options={[
                                            { value: 0, label: '否' },
                                            { value: 1, label: '是' },
                                        ]}
                                        placeholder="请选择"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item
                                    label={<>
                                        <span style={{ marginRight: 4, verticalAlign: 'middle' }}>接送到达时间</span>
                                        <Tooltip title="来程有接送的人员，到达会场的时间" style={{ verticalAlign: 'middle' }}>
                                            <span style={{ color: '#fa8c16' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                                                </svg>
                                            </span>
                                        </Tooltip>
                                    </>}
                                    name="pickupDropoffCome"
                                >
                                    <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} placeholder={['开始时间', '结束时间']} />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item
                                    label={<>
                                        <span style={{ marginRight: 4, verticalAlign: 'middle' }}>接送离开时间</span>
                                        <Tooltip title="返程有接送的人员，离开会场的时间" style={{ verticalAlign: 'middle' }}>
                                            <span style={{ color: '#fa8c16' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                                                </svg>
                                            </span>
                                        </Tooltip>
                                    </>}
                                    name="pickupDropoffGo"
                                >
                                    <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} placeholder={['开始时间', '结束时间']} />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="是否入住酒店" name="joinHotel">
                                    <Select
                                        options={[
                                            { value: 0, label: '否' },
                                            { value: 1, label: '是' },
                                        ]}
                                        placeholder="请选择"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="入住酒店名称" name="hotelName">
                                    <Select
                                        placeholder="请选择入住酒店"
                                        options={HotelList}
                                        fieldNames={{ value: 'label', label: 'label' }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </div>

                <div className="main-content-body guest-journey-content">
                    <div className="guest-journey-btns">
                        <Space>
                            <Button type="primary" onClick={() => importGuestJourney()}>导入嘉宾行程</Button>
                            <Button type="link" onClick={downTemplate}>下载嘉宾行程表模板</Button>
                        </Space>

                        <Space>
                            <Button onClick={exportGuestJourney}>导出嘉宾行程</Button>
                            <Button onClick={settingGuestJourney}>嘉宾行程单设置</Button>
                        </Space>
                    </div>

                    {
                        !!guestTotalInfo &&
                        <div className='journey-total-info'>
                            <Alert
                                message={<>
                                    统计：已接受邀请{guestTotalInfo?.accepted}人；已录入行程{guestTotalInfo?.journeys}；总{guestTotalInfo?.rooms}间夜、住宿人数{guestTotalInfo?.journeyCheckins}人{getDateRoom(guestTotalInfo?.days)}
                                </>}
                                type="info"
                            />
                        </div>
                    }

                    <Table
                        rowKey="id"
                        size="small"
                        rowSelection={{ type: "checkbox", ...guestSelection }}
                        rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.page,
                            pageSize: filter.pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                        scroll={{ x: 1300 }}
                    />
                </div>
            </Content>

            <GuestJourneyImport
                importDataOpen={importDataOpen}
                setImportDataOpen={setImportDataOpen}
                getExpertList={getExpertList}
            />

            <GuestJourneyForm
                visibleGuestJourney={visibleGuestJourney}
                getGuestJourneyList={getExpertList}
                onClose={closeGuestJourney}
                conferenceId={conferenceId}
                expertId={currentGuestJourney?.id}
                currentGuestJourney={currentGuestJourney}
                setCurrentGuestJourney={setCurrentGuestJourney}
            />

            <GuestJourneySetting
                visibleGuestJourneySetDrawer={visibleGuestJourneySetDrawer}
                setVisibleGuestJourneySetDrawer={setVisibleGuestJourneySetDrawer}
                getConferenceInfo={getConferenceInfo}
                conference={conference}
            />

        </>
    );
}
