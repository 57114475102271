import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Layout, Form, Input, Divider, Button, Radio, Select, Cascader, Space, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { get, post, put } from '@/core/request';

const { Content } = Layout;

export default function LecturersAddForm() {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { state: { productIds, orgIds: orgId, departmentData, allTitle, data, tip, row } } = useLocation();

    const [rowData, setRowData] = useState({});
    // 产品线
    const [productId, setProductId] = useState([]);

    const handleChange = (value) => {
        get('/dms/production-lines/queryProductsOrgId', { orgId: value }).then((res) => {
            if (res.status_code === 200) {
                setProductId(res.data);
            }
        })
        form.setFieldsValue({ productId: undefined })
    };

    useEffect(() => {
        if (tip === 'edit') {
            get(`/dms/userProducts/getOne`, {
                id: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    setRowData(res.data);
                    const { name, sex, phone, orgId, productId, provinceCode, cityCode, districtCode, hospital, departmentId, titleId } = res.data;
                    let obj = productIds.find(item => {
                        return item.id === productId
                    })
                    form.setFieldsValue({
                        name: name,
                        gender: sex,
                        phone: phone,
                        orgId: orgId,
                        productId: obj.name,
                        province: [`${provinceCode}`, `${cityCode}`, `${districtCode}`],
                        hospital: hospital,
                        departmentId: departmentId,
                        titleId: titleId
                    });
                }

            });
        }
    }, [tip, row, form, productIds]);

    const onFinish = (values) => {
        values.provinceCode = values.province[0];
        values.cityCode = values.province[1];
        values.districtCode = values.province[2];
        delete values.province;
        if (tip === 'add') {
            post(`/dms/users/save`, values).then((res) => {
                if (res.status_code === 200) {
                    message.success('添加讲师信息成功');
                    form.resetFields();
                }
            })
        } else {
            values.id = rowData.id;
            delete values.phone;
            delete values.orgId;
            delete values.productId;
            put(`/dms/users/update`, values).then((res) => {
                if (res.status_code === 200) {
                    message.success('修改讲师信息成功');
                    navigate('/dms/lecturers');
                }
            });
        }
    };

    const handleClose = () => {
        navigate('/dms/lecturers');
    }

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/dms/lecturers" className="back"><LeftOutlined /></Link>
                添加讲师
            </div>

            <Content className="main-content-box">
                <div className="main-content-title">讲师信息</div>

                <div className="main-content-body">

                    <Form className="page-form" {...pageFormItemLayout} onFinish={onFinish} form={form}>

                        <Form.Item
                            name="name"
                            label="医生姓名"
                            rules={[{ required: true, message: '请填写医生姓名' }]}
                        >
                            <Input placeholder="请输入讲师姓名" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="gender"
                            label="性别"
                            initialValue={1}
                            required={true}>
                            <Radio.Group>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="手机号码"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!/^1\d{10}$/.test(value)) {
                                            return Promise.reject('手机号不正确');
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}>
                            <Input placeholder="请输入手机号码" allowClear disabled={tip === 'edit' ? true : false} autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="所属企业"
                            name="orgId"
                            rules={[{ required: true, message: '请选择所属企业' }]}>
                            <Select allowClear placeholder="请选择所属企业" onChange={handleChange} disabled={tip === 'edit' ? true : false}>
                                {orgId && orgId.map(item => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="产品线"
                            name="productId"
                            rules={[{ required: true, message: '请选择产品线' }]}>
                            <Select allowClear placeholder="请选择产品线" disabled={tip === 'edit' ? true : false}>
                                {productId && productId.map(item => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="province"
                            label="省份城市"
                            rules={[{ required: true, message: '请选择省份城市' }]}>
                            <Cascader options={data} placeholder="请选择省份城市" />
                        </Form.Item>

                        <Form.Item
                            name="hospital"
                            label="医院名称"
                            rules={[{ required: true, message: '请填写医院名称' }]}>
                            <Input placeholder="请输入医院名称" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="科室"
                            name="departmentId"
                            rules={[{ required: true, message: '请选择科室' }]}>
                            <Select allowClear placeholder="请选择科室">
                                {departmentData && departmentData.map(item => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="职称"
                            name="titleId"
                            rules={[{ required: true, message: '请选择职称' }]}>
                            <Select allowClear placeholder="请选择职称">
                                {allTitle && allTitle.map(item => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Divider />

                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button className="submit-button" type="primary" htmlType="submit">提交</Button>
                                <Button type="primary" onClick={handleClose}>取消</Button>
                            </Space>
                        </Form.Item>

                    </Form>

                </div>

            </Content>
        </Fragment>
    );
}
