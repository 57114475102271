import React, { useRef,useMemo } from 'react';
// import { ImageDrop } from './plugin/quill-image-drop-module';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { post } from '@/core/request';

export default function RichText({
    quillInfo, setRichTextInfo
}) {
    const reactQuillRef = useRef(null);

    const imageHandler = () => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.setAttribute('multiple', 'multiple')
        input.click()
        input.onchange = async () => {
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            const formData = new FormData();
            formData.append('file', input.files[0]);
            formData.append('type', 4);
            post("/dms/file", formData, option).then((res) => {
                if (res.status_code === 200) {
                    let quill = reactQuillRef.current.getEditor();//获取到编辑器本身
                    const cursorPosition = quill.getSelection().index;//获取当前光标位置
                    const link = res.data;
                    quill.insertEmbed(cursorPosition, "image", link);//插入图片
                    quill.setSelection(cursorPosition + 1);//光标位置加1
                }
            })
        }
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                ['image'], // 图片的显示
            ],
            handlers: {
                'image': imageHandler.bind(this), //点击图片标志会调用的方法
            },
        },
    }), [])

    const handleChange = (value)=>{
        setRichTextInfo(value)
    }
    return (
        <ReactQuill
            ref={reactQuillRef}
            defaultValue={quillInfo}
            id="rich-text"
            modules={modules}
            onChange={handleChange}
        />
    )
}
