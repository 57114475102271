import {Button, Col, DatePicker, Dropdown, Form, Input, Layout, Row, Select, Space, Table} from "antd";
import React, {Fragment, useEffect, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import { filterFormItemLayout } from '@utils/property';
import {getConsultationsListApi} from '@services/Uc/Consultations'
import moment from "moment";

const {Content} = Layout;
const { RangePicker } = DatePicker;
export default function ConsultationsList() {

    const [form] = Form.useForm();
    const filterFormGridLayout = {
        sm: { span: 24 },
        md: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 8 },
    }

    // 获取列表数据
    const [data,setData]=useState([])
    const [queryObj,setQueryObj]=useState({})
    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    // 分页
    const onChangePage = (page, pageSize) => {
        console.log(page);
        setPage(page);
        setPageSize(pageSize);
    };

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (res) => {
        console.log(res)
        setPage(1)
        setQueryObj(res)
    };
    const onReset = () => {
        form.resetFields();
        setPage(1)
        setQueryObj({})
    };
    const queryParams = {
        ...form.getFieldsValue(),
        page:page,
        limit:pageSize
    };
    useEffect(() => {
        if (queryObj.time != null) queryParams.startTime = moment(queryObj.time[0]).format('YYYY-MM-DD HH:mm:ss')
        if (queryObj.time != null) queryParams.endTime = moment(queryObj.time[1]).format('YYYY-MM-DD HH:mm:ss')
        if (queryObj.followStatus===-1){
            delete queryParams.followStatus
        }
        delete queryParams.time
        getConsultationsListApi(queryParams).then((res) => {
            if (res.status_code === 200) {
                console.log(res)
                setData(res.data.list)
                setTotal(res.data.totalCount)
            }
        })
    }, [page, pageSize,queryObj]);

    const columns = [
        {title: '用户昵称', dataIndex: 'nickName', width: 200},
        {title: '手机号', dataIndex: 'memMobile', width: 100},
        {title: '咨询内容', dataIndex: 'message', width: 400},
        {title: '咨询类型', dataIndex: 'type', width: 100,
            render:(_,row)=>(
                <Fragment>
                    {row.type === 1 && (<span> 解决方案 </span>)}
                </Fragment>
                )},
        {title: '内容信息', dataIndex: 'content', width: 400},
        {title: '咨询时间', dataIndex: 'createdAt', width: 300},
        {title: '最后跟进', dataIndex: 'time', width: 300,
            render:(_,row)=>(
                <Fragment>
                    {row.followName && (<p>{row.followName}</p>)}
                    {row.followTime && (<p>{row.followTime}</p>)}
                </Fragment>
            )},
        {
            title: '操作',
            dataIndex: 'operations',
            width: 200,
            render: (_,row) => (
                <Fragment>
                    <Link to={`/uc/user/consultations/${row.id}`}>查看详情</Link>
                </Fragment>
            ),
        }
    ];

    return <Fragment>
        <div className="page-title">
            <span className="current-title">用户咨询管理</span>
        </div>
        <Content className="main-content-box">
            <div className="filter-form-box">
                <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="用户memCard" name="memCard">
                                <Input allowClear placeholder="用户memCard" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="用户昵称" name="nickName">
                                <Input allowClear placeholder="用户昵称" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="手机号" name="memMobile">
                                <Input allowClear placeholder="用户手机号" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="咨询时间" name="time">
                                <RangePicker
                                    className="ProjectRangPicker"
                                    allowClear
                                    onChange={handleChangeTime}
                                    showTime />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="跟进状态" name="followStatus">
                                <Select placeholder='请选择跟进状态'
                                        options={[
                                            {value: -1, label: '全部'},
                                            {value: 0, label: '未跟进'},
                                            {value: 1, label: '已跟进'}
                                        ]}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout} className="filter-form-buttons">
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>

            <div className="main-content-body">

                <div className="table-caption">
                    <div className="table-title">咨询列表</div>
                </div>

                <Table
                    className="enterprises-table"
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                />

            </div>
        </Content>

    </Fragment>
}
