import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Space,
    Table,
    Tooltip,
    message,
    Avatar,
    Typography,
    Drawer,
} from "antd";
import {
    LeftOutlined,
    RightOutlined,
    SearchOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CaretDownOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import {
    getUserListApi,
    saveUserApi
} from '@services/Invitation/InvitationUser';
import {getInvitationOneApi} from '@services/Invitation/Invitation';
const {Text} = Typography;

const AddGuest = ({
    closeAddUserDrawer,
    visibleAddUserDrawer,
    setVisibleAddUserDrawer,
    onResetGuest,
}) => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [inPageSize, setInPageSize] = useState(10);
    const [inPage, setInPage] = useState(1);
    const [inTotal, setInTotal] = useState(0);
    const [selected, setSelected] = useState([]);
    const [delSelected, setDelSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveMore, setSaveMore] = useState([]);
    const [delMore, setDelMore] = useState([]);
    const isSearchRef = useRef(null);
    const [expertForm] = Form.useForm();
    const [inForm] = Form.useForm();
    const params = useParams();
    const [filObj, setFilObj] = useState({
        name: "",
    });
    const [btnLoad,setBtnLoad] = useState(false);

    const [invitationOne, setInvitationOne] = useState({})


    const guestSelectionTableColumns = [
        {
            title: '姓名',
            dataIndex: 'memUsername',
            width: 150,
            render: (_, record) => {
                return <Fragment>
                    <Avatar style={{marginRight: '4px'}} src={record?.expertAvatar}/>
                    <span style={{minWidth: '36px', display: "inline-block"}}>{record.memUsername}</span>
                </Fragment>;
            }
        },
        {
            title: '性别',
            dataIndex: 'memGender',
            width: 60,
            render: (_, record) =>
                record.memGender === 1
                    ? '男'
                    : record.memGender === 0
                    ? '女'
                    : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '手机号',
            dataIndex: 'memMobile',
            width: 100,
        },
        {
            title: '单位任职',
            dataIndex: 'ssoMemberWorkExperiencesEntities',
            render: (_, record) => {
                return (
                    record.ssoMemberWorkExperiencesEntities.map((item, index) => {
                        return (
                            <div key={item.id}>
                                <span>{index + 1}.</span>
                                <span>{item.unitName} &nbsp;</span>
                                {item.unitOffice && <span>{item.unitOffice} &nbsp;</span>}
                                <span>{item.unitPost}</span>
                            </div>
                        )
                    })
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'selected',
            width: 100,
            render: (_, record) =>
                record.selected === 1
                    ? (<a style={{color: 'blue'}}>已邀请</a>)
                    : record.selected === 0
                    ? (<a style={{color: 'red'}}>未邀请</a>)
                    : <Text type="secondary">&ndash;</Text>
        },
    ]

    const [guestsToSelect, setGuestsToSelect] = useState([]);
    const [guestsSelected, setGuestsSelected] = useState([]);
    const [guestsFilter,setGuestsFilter] = useState([]);


    // 添加用户
    const addGuests = () => {
        setBtnLoad(true);
        let expertIds = guestsSelected.map((item) => item.id);
        const params= {
            expertIds: expertIds,
            invitationGroupId: invitationOne.groupId,
            invitationId: invitationOne.id
        }
        saveUserApi(params).then(
            (res) => {
                setBtnLoad(false);
                if (res.status_code === 200) {
                    message.success("添加成功");
                    setVisibleAddUserDrawer(false);
                    onResetGuest('change');
                }
            }
        );
        // TODO
    };

    const guestToSelectSelection = {
        selectedRowKeys: selected,
        onSelect: (record, select) => {
            if (select) {
                let originalArray = [...selected, record.id];
                let uniqueArray = Array.from(new Set(originalArray));
                setSelected([...uniqueArray]);
                setSaveMore([...saveMore, record]);
            } else {
                let re = selected.filter((it) => it !== record.id);
                setSelected([...re]);

            }
        },
        onSelectAll: (select, selectedRows, changeRows) => {
            if (select) {
                let res = changeRows.map((i) => i.id);
                let originalArray = [...selected, ...res];
                let uniqueArray = Array.from(new Set(originalArray));
                setSelected([...uniqueArray]);
                setSaveMore([...saveMore, ...selectedRows]);
            } else {
                let newIds = changeRows.map((i) => i.id);
                let originalArray = [...selected, ...newIds];
                let res=originalArray.filter((item) => originalArray.indexOf(item) === originalArray.lastIndexOf(item))
                setSelected([...res])
            }
        },
    };

    const guestSelectedSelection = {
        selectedRowKeys: delSelected,
        onSelect: (record, select) => {
            if (select) {
                let originalArray = [...delSelected, record.id];
                let uniqueArray = Array.from(new Set(originalArray));
                setDelSelected([...uniqueArray]);
                setDelMore([...delMore, record]);
            } else {
                let re = delSelected.filter((it) => it !== record.id);
                setDelSelected([...re]);
            }
        },
        onSelectAll: (select, selectedRows, changeRows) => {
            if (select) {
                let res = changeRows.map((i) => i.id);
                let originalArray = [...delSelected, ...res];
                let uniqueArray = Array.from(new Set(originalArray));
                setDelSelected([...uniqueArray]);
                setDelMore([...delMore, ...selectedRows]);
            } else {
                let newIds = changeRows.map((i) => i.id);
                let originalArray = [...delSelected, ...newIds];
                let res=originalArray.filter((item) => originalArray.indexOf(item) === originalArray.lastIndexOf(item))
                setDelSelected([...res])
            }
        },
    };

    const getExpertlist = async (page, pageSize, name) => {
        setLoading(true);
        isSearchRef.current = false;
        let obj = {
            page,
            limit:pageSize,
            applicationItemId: invitationOne.invitationGroup.applicationItemId,
            applicationType: invitationOne.invitationGroup.applicationType,
            name,
            invitationId: Number(params.id || 0)
        };

        let res = await getUserListApi(obj);
        if (res.status_code === 200) {
            setLoading(false);
            setGuestsToSelect(res.data.list);
            setTotal(res.data.totalCount);
        }
    };

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onChangeInPage = (page, pageSize) => {
        setInPage(page);
        setInPageSize(pageSize);
    };


    const expertFormFinish = (res) => {
        isSearchRef.current = true;
        setFilObj({
            name: expertForm.getFieldValue("name")
                ? expertForm.getFieldValue("name")
                : "",
        });
        if (
            (expertForm.getFieldValue("name") === undefined ||
                expertForm.getFieldValue("name") === "")
        ) {
            setPage(1);
        }
        expertForm.resetFields();
    };


    const filterIn=()=>{

        let old=[...guestsSelected]
        if(inForm.getFieldValue('name')!==undefined||inForm.getFieldValue('name')!==''){
            let fil = guestsFilter.filter((item) => {
                return item?.memUsername.indexOf(inForm.getFieldValue('name')) > -1;
           });
           setGuestsSelected([...fil])
           setInTotal(fil.length)
        }else{
       setGuestsSelected([...old])
       setInTotal(old.length)
        }

    }

    const onInsert = () => {
        let old = guestsSelected ? [...guestsSelected] : [];
        let inside = [...old, ...saveMore];
        inside=inside.filter(i=>i!==undefined)
        let res = inside.filter(function (item, index, self) {
            return self.findIndex((el) => el.id == item.id) === index;
        });

        setGuestsSelected([...res]);
        setGuestsFilter([...res]);
        setInTotal(res.length)
        setSaveMore([])
    };

    const onDelete = () => {
        let res = guestsSelected.filter(
            (item) => !delSelected.includes(item.id)
        );
        let sel = res.map((i) => i.id);
        setSelected([...sel]);
        setDelSelected([]);
        setGuestsSelected([...res]);
        setGuestsFilter([...res]);
        setInTotal(res.length)
    };
    const onAllInsert = () => {
        let old = guestsSelected ? [...guestsSelected] : [];
        let inside = [...old, ...guestsToSelect];
        let uniqueArray = Array.from(new Set(inside));
        let res = guestsToSelect.map((item) => {
            return item.id;
        });
        let originalArray = [...selected, ...res];
        let uniqueSelectArray = Array.from(new Set(originalArray));

        setSelected([...uniqueSelectArray]);
        setGuestsSelected([...uniqueArray]);
        setGuestsFilter([...uniqueArray]);
        setInTotal(uniqueArray.length)
    };

    const onAllDeleted = () => {
        setSelected([]);
        setDelSelected([]);
        setGuestsSelected([]);
        setGuestsFilter([]);
        setInTotal(0)
    };
    useEffect(() => {
        getInvitationOneApi({id: params?.id || 0}).then((res) => {
            if (res.status_code === 200) {
                setInvitationOne(res.data)
            }
        })
    }, []);
    useEffect(() => {
        const { name } = filObj;
        if(visibleAddUserDrawer){
            if (isSearchRef.current === true) {
                getExpertlist(1, pageSize, name);
            } else {
                getExpertlist(page, pageSize, name);
            }
        }
    }, [page, pageSize, filObj, visibleAddUserDrawer]);

    useEffect(()=>{
            setGuestsSelected([]);
            setSelected([]);
            setSaveMore([])
    },[visibleAddUserDrawer])


    return (
        <div>
            <Drawer
                title="添加邀请用户"
                placement="bottom"
                height="100%"
                destroyOnClose
                open={visibleAddUserDrawer}
                onClose={closeAddUserDrawer}
                footer={
                    <div
                        className="custom-drawer-footer"
                        style={{ textAlign: "right" }}
                    >
                        <Space>
                            <Button onClick={closeAddUserDrawer}>取消</Button>
                            <Button type="primary" loading={btnLoad} onClick={addGuests}>
                                确定
                            </Button>
                        </Space>
                    </div>
                }
            >
                <div className="guest-selection-area">
                    <div className="guests-to-select box-hover-shadow">
                        <div className="guest-selection-title">
                            <CaretDownOutlined /> 可选用户
                        </div>
                        <div className="guest-selection-filter">
                            <Form
                                layout="inline"
                                size="small"
                                form={expertForm}
                            >
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="用户姓名" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        icon={<SearchOutlined />}
                                        onClick={expertFormFinish}
                                    >
                                        检索
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            size="small"
                            columns={guestSelectionTableColumns}
                            dataSource={guestsToSelect}
                            rowKey={(row) => row.id}
                            rowSelection={{
                                type: "checkbox",
                                ...guestToSelectSelection,
                            }}
                            loading={loading}
                            pagination={{
                                total: total,
                                showTotal: () => `共${total}条`,
                                current: page,
                                pageSize: pageSize,
                                onChange: onChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                        />
                    </div>
                    <div className="operations">
                        <Tooltip title="添加选中用户" placement="right">
                            <Button onClick={onInsert}>
                                <RightOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="移除选中" placement="left">
                            <Button onClick={onDelete}>
                                <LeftOutlined />
                            </Button>
                        </Tooltip>
                    </div>

                    <div className="guests-selected box-hover-shadow">
                        <div className="guest-selection-title">
                            <CaretDownOutlined /> 已选用户
                        </div>
                        <div className="guest-selection-filter">
                            <Form layout="inline" size="small" form={inForm}>
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="用户姓名" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        onClick={filterIn}
                                        icon={<FilterOutlined />}
                                    >
                                        过滤
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            size="small"
                            columns={guestSelectionTableColumns}
                            dataSource={guestsSelected}
                            rowKey={(rows) => rows.id}
                            pagination={{
                                // total: inTotal,
                                showTotal: () => `共${inTotal}条`,
                                current: inPage,
                                pageSize: inPageSize,
                                onChange: onChangeInPage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                            rowSelection={{
                                type: "checkbox",
                                ...guestSelectedSelection,
                            }}
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AddGuest;
