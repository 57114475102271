import {useLocation, useNavigate} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {Image, Layout, Tag, message, Upload, Button, Input} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {get, put, post} from "@/core/request";
import './TrialOrganizationsInfoUpdate.less'

export default function TrialOrganizationsInfoUpdate() {

    const {Content} = Layout;
    const {Search} = Input;
    let navigate = useNavigate();
    const {state} = useLocation();
    const [infoData, setInfoData] = useState({});
    const [fileList, setFileList] = useState([]);
    const [certificateList, setCertificateList] = useState([]);
    const [belongsUnitName, setBelongsUnitName] = useState("");
    const [name, setName] = useState("");
    const [coverUrl, setCoverUrl] = useState("");
    const [introduce, setIntroduce] = useState("");
    const [metaTags, setMetaTags] = useState([]);
    const [applicationReason, setApplicationReason] = useState("");
    const [unitName, setUnitName] = useState("");
    const [certificate, setCertificate] = useState("");
    const [tags, setTags] = useState([])
    const [limitType, setLimitType] = useState(0)

    const propsImgfile = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        fileList,
        customRequest: info => {
            const reg = /\.(jpg|jpeg|png)$/i
            var isJpgOrPng = info.file?.name.search(reg)
            const isLt5M = info.file?.size / 1024 / 1024 < 5;
            if (isJpgOrPng === -1) {
                message.error('上传文件格式有误，文件只能是jpg、jpeg、png格式！');
                document.getElementsByClassName("ant-upload-list-item-name");
                setFileList([])
                console.log()
                return;
            }
            if (!isLt5M) {
                message.error('文件大小不能超过5M！');
                setFileList([])
                return false;
            }
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 8);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/healthclass/file", formData, option).then((res) => {
                if (!res) {
                    message.error('上传附件失败！！')
                    setFileList([])
                    return false;
                }
                if (res.status_code === 200) {
                    setCoverUrl(res.data)
                    setFileList([info.file])
                }
            })
        }
    }

    const certificatefile = {
        name: 'certificate',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        certificateList,
        customRequest: info => {
            const reg = /\.(jpg|jpeg|png)$/i
            var isJpgOrPng = info.file?.name.search(reg)
            const isLt5M = info.file?.size / 1024 / 1024 < 5;
            if (isJpgOrPng === -1) {
                message.error('上传文件格式有误，文件只能是jpg、jpeg、png格式！');
                document.getElementsByClassName("ant-upload-list-item-name");
                setCertificateList([])
                console.log()
                return;
            }
            if (!isLt5M) {
                message.error('文件大小不能超过5M！');
                setCertificateList([])
                return false;
            }
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 8);//名字和后端接口名字对应
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            post("/healthclass/file", formData, option).then((res) => {
                if (!res) {
                    message.error('上传附件失败！！')
                    setCertificateList([])
                    return false;
                }
                if (res.status_code === 200) {
                    setCertificate(res.data)
                    setCertificateList([info.file])
                }
            })

        }
    }

    const classNames = ['button'];
    const classTrial = ['button'];
    const limitClassk = ['button'];
    const limitClasss = ['button'];

    if (infoData.type == 1) {
        classNames.push('button')
        classTrial.push('buttonblue')
    } else {
        classNames.push('buttonblue')
        classTrial.push('button')
    }
    if (limitType == 1) {
        limitClassk.push('button')
        limitClasss.push('buttonblue')
    } else if (limitType == 2) {
        limitClassk.push('buttonblue')
        limitClasss.push('button')
    }

    const handleClose = (tag) => {
        console.log(tag)
        let filer = metaTags.filter((t) => {
            return t.id !== tag.id})
            setMetaTags(filer)
        console.log(metaTags)
    }

    const submit = () => {
        if (state.id == null) {
            message.warning("参数丢失！！")
            return
        }

        if (name == null || name.length==0) {
            message.warning("请填写学苑名称！！")
            return
        }
        if (coverUrl == null || coverUrl.length==0) {
            message.warning("请上传学苑封面！！")
            return
        }
        if (introduce == null || introduce.length==0) {
            message.warning("请填写学苑介绍！！")
            return
        }
        if (metaTags.length<1) {
            message.warning("请选择学苑标签！！")
            return
        }
        if (applicationReason == null || applicationReason.length==0) {
            message.warning("请填写申请理由！！")
            return
        }
        if (limitType == null || limitType < 1) {
            message.warning("请选择开放类型！！")
            return
        }
        if (infoData.unitName !=null ){
            if (unitName == null || unitName.length==0) {
                message.warning("请填写单位名称！！")
                return
            }
            if (certificate == null || certificate.length==0) {
                message.warning("请上传证明材料！！")
                return
            }
        }
        let arry = []
        metaTags.map((it)=>{arry.push(it.id)})
        post("/healthclass/boss/organizations/updateInfo", {
            id: state.id,
            name:name,

            coverUrl:coverUrl,
            introduce:introduce,
            applicationReason:applicationReason,
            unitName:unitName,
            certificate:certificate,
            limitType:limitType,
            label:arry.join(",")
        }).then((res) => {
                if (res.status_code == 200) {
                    message.success(res.message);
                    navigate('/healthclass/TrialOrganizations');
                }
            }
        )
    }

    const onCancel = () => {
        navigate('/healthclass/FormalOrganizations');
    }


    const onName = (e) => {
        setName(e.target.value)
    }
    const onIntroduce = (e) => {
        setIntroduce(e.target.value)
    }
    const onApplicationReason = (e) => {
        setApplicationReason(e.target.value)
    }
    const onUnitName = (e) => {
        setUnitName(e.target.value)
    }

    const onLimitType = () =>{
        setLimitType(2)
    }

    const onLimitTypePrivate = () =>{
        setLimitType(1)
    }

    const getData = () => {
        get(`/healthclass/boss/organizations/queryById`, {
            id: state.id
        }).then((res) => {
            if (res.status_code === 200) {
                setInfoData(res.data)
                setBelongsUnitName(res.data.belongsUnitName)
                setName(res.data.name)
                setCoverUrl(res.data.coverUrl)
                setIntroduce(res.data.introduce)
                setMetaTags(res.data.metaTags)
                setApplicationReason(res.data.applicationReason)
                setUnitName(res.data.unitName)
                setCertificate(res.data.certificate)
                setLimitType(res.data.limitType)
            }
        });
    }

    const getTags = () => {
        get(`/healthclass/saas/meta-tags/queryAll`, {}).then((res) => {
            if (res.status_code === 200) {
                setTags(res.data)
            }
        });
    }

    const onSearch = (value) => {
        post(`/healthclass/saas/meta-tags/save`, {
            name: value
        }).then((res) => {
            if (res.status_code === 200) {
                let tag = [...metaTags, res.data]
                setMetaTags(tag)
                console.log(res.data)
            }
        })
    }

    const onChangeTags = (tag) => {
        console.log(tag)
        let filer = metaTags.filter((t) => {
            return t.id === tag.id})
        if (!filer.length){
            let onTags = [...metaTags, tag]
            setMetaTags(onTags)
        }else {
            message.warning("标签已添加！！")
        }
    }

    useEffect(() => {
        getData()
        getTags()
    }, [state])

    return (
        <div>
            <Fragment>
                <div className="page-title">更新学苑信息</div>
                <Content className="main-content-box">
                    {infoData &&
                    <div>
                        <div className="content">
                            <span className="icon">所属主体</span>

                            <input className="formalCheckInput" disabled="disabled" value={belongsUnitName}  onChange={() => {}}/>
                        </div>
                        <div className="title">学苑类型</div>
                        <div className="content">
                            <span className="icon">学苑类型</span>
                            <button className={classNames.join(" ")}>正式</button>
                            <button className={classTrial.join(" ")}>试用</button>
                        </div>
                        <div className="title">学苑信息</div>
                        <div className="content">
                            <span className="icon">学苑名称</span>
                            <input className="formalCheckInputUpdate" value={name} onChange={onName}/>
                        </div>
                        <div className="content">
                            <span className="icon">开放类型</span>
                            <button className={limitClassk.join(" ")} onClick={onLimitType}>公开</button>
                            <button className={limitClasss.join(" ")} onClick={onLimitTypePrivate}>私享</button>
                        </div>
                        <div className="content">
                            <span className="icon">学苑封面</span>
                            <div className="image"><Image width={300} height={200} src={coverUrl}/>
                            </div>
                            <span className="uploadButton">
                            <Upload {...propsImgfile} maxCount={1}>
                                <Button icon={<UploadOutlined/>}>上传图片</Button>
                            </Upload>
                        </span>
                        </div>
                        <div className="content">
                            <span className="icon nameSpan">学苑介绍</span>
                            <textarea type="text" className="formalInputcontentUpdate" value={introduce}
                                      onChange={onIntroduce}/>
                        </div>
                        <div className="contentTag">
                            <span className="icon">学苑标签</span>
                            <div className="divTags">{metaTags && metaTags.map((tagName) => (
                                <Tag key={tagName.id} closable onClose={() => handleClose(tagName)}>{tagName.name}</Tag>))}
                            </div>
                        </div>
                        <div className="devContentTag">
                            <div className="divContInputTags">
                                <Search
                                    placeholder="请添加学苑标签"
                                    allowClear
                                    enterButton="添加"
                                    size="14px"
                                    style={{
                                        width: 300,
                                    }}
                                    onSearch={onSearch}
                                />
                            </div>
                        </div>
                        <div className="devContentTag">
                            <div className="divContTags">
                                {tags && tags.map((tagName) => (
                                    <Tag key={tagName.id} onClick={() => onChangeTags(tagName)}>{tagName.name}</Tag>))}
                            </div>
                        </div>
                        <div className="content">
                            <span className="icon nameSpan">申请理由</span>
                            <textarea className="formalInputcontentUpdate" value={applicationReason}
                                      onChange={onApplicationReason}/>
                        </div>
                        {infoData.unitName != null &&
                        <div>
                            <div className="title">个人认证信息</div>
                            <div className="content">
                                <span className="icon">单位名称</span>
                                <input className="formalInputInfoUpdate" value={unitName} onChange={onUnitName}/>
                            </div>
                            <div className="content">
                                <span className="icon">证明材料</span>
                                <div className="image"><Image width={300} height={200} src={certificate}/>
                                </div>
                                <span className="uploadButton">
                            <Upload {...certificatefile} maxCount={1}>
                                <Button icon={<UploadOutlined/>}>上传图片</Button>
                            </Upload>
                            </span>
                            </div>
                        </div>
                        }
                    </div>
                    }
                    <div className="content">
                        <button className="checkbutton" onClick={() => submit()}>提交</button>
                        <button className="checkbuttonb" onClick={() => onCancel()}>取消</button>
                    </div>
                </Content>
            </Fragment>
        </div>
    )
}
