import React, { Fragment, useState, useEffect } from 'react';
import { Button, Switch, Col, DatePicker, Form, Input, Layout, message, Modal, Row, Steps, Space, Table, Typography, Divider } from 'antd';
import { SearchOutlined, EllipsisOutlined, ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {
    getScoreStagesListApi,
    addScoreStagestApi,
    updateScoreStagestApi,
    deleteScoreStagestApi,
    updateCerificateApi,
    reportStateUpdateApi
} from '@services/Selection'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import "./index.less"
import StageTaskModal from './StageTask';

const { RangePicker } = DatePicker;

const ScoreStageList = () => {
    const dateFormat = 'YYYY-MM-DD HH:mm';
    const params = useParams();
    const navigate = useNavigate();
    const { Content } = Layout;
    const pid = ~~params?.pid || 0;
    const { Text } = Typography;

    // 列表数据
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [creatMarkModal, setCreatMarkModal] = useState(false);
    const [markName, setMarkName] = useState('');
    const [currentId, setCurrentId] = useState();

    const [stageId, setStageId] = useState();
    const [title, setTitle] = useState('');

    const [form] = Form.useForm();

    const [openStageTaskModal, setOpenStageTaskModal] = useState(false);

    const getData = () => {
        getScoreStagesListApi({ projectId: pid }).then((res) => {
            setLoading(false);
            if (res && res.status_code === 200) {
                setData(res.data);
            }
        })

    }

    // const getProjestDetail = () => {
    //     getProjestsApi({ id: pid }).then((res) => {
    //         if (res && res.status_code === 200) {
    //             setTitleName(res.data.name);
    //         }
    //     })
    // }

    useEffect(() => {
        setLoading(true);
        // getProjestDetail();
        getData();
    }, [])

    // 点击创建
    const handleClickMark = () => {
        setMarkName('');
        setCreatMarkModal(true);
    }

    // 点击编辑
    const handleEdit = (v) => {
        setMarkName(v.name);
        setCurrentId(v.id);
        setCreatMarkModal(true);
        form.setFieldValue('name', v.name);
    }

    // 点击删除
    const handleDeletd = (row) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否删除环节：${row.name}`,
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                setLoading(true);
                deleteScoreStagestApi({ id: row.id }).then(res => {
                    if (res && res.status_code === 200) {
                        message.success('删除成功')
                        getData();
                    } else {
                        setLoading(false)
                    }
                })
            }
        });
    }

    // 创建&编辑弹窗
    const handleCreatMark = () => {
        form.validateFields().then(values => {
            if (currentId) {
                updateScoreStagestApi({
                    id: currentId,
                    name: values.name,
                    dateTime: values.dateTime ? moment(values.dateTime[0]).format(dateFormat) + '~' + moment(values.dateTime[1]).format(dateFormat) : '',
                    projectId: pid
                }).then(res => {
                    setCurrentId();
                    setLoading(true);
                    if (res && res.status_code === 200) {
                        getData();
                        setCreatMarkModal(false);
                    } else {
                        setLoading(false)
                    }
                })
            } else {
                addScoreStagestApi({
                    name: values.name,
                    dateTime: values.dateTime ? moment(values.dateTime[0]).format(dateFormat) + '~' + moment(values.dateTime[1]).format(dateFormat) : '',
                    projectId: pid
                }).then(res => {
                    setLoading(true);
                    setCurrentId();
                    if (res && res.status_code === 200) {
                        getData();
                        setCreatMarkModal(false);
                    } else {
                        setLoading(false)
                    }
                })
            }
        })
    }


    const handleCancelCreat = () => {
        setCreatMarkModal(false);
        setMarkName('');
        setCurrentId();
    }

    // 赛段评分
    const handleChangeStageScore = (bool, id) => {
        setLoading(true);
        updateScoreStagestApi({
            id: id,
            projectId: pid,
            scoreState: bool ? 1 : 0
        }).then(res => {
            if (res && res.status_code === 200) {
                getData();
            } else {
                setLoading(false)
            }
        })
    }

    // 评委点评
    const handleChangeJudgeReview = (bool, id) => {
        setLoading(true);
        updateScoreStagestApi({
            id: id,
            projectId: pid,
            reviewsState: bool ? 1 : 0
        }).then(res => {
            if (res && res.status_code === 200) {
                getData();
            } else {
                setLoading(false)
            }
        })
    }

    // 评分报告
    const handleChangeCaseReport = (bool, id) => {
        setLoading(true);
        reportStateUpdateApi({
            id: id,
            projectId: pid,
            reportState: bool ? 1 : 0,
        }).then(res => {
            if (res && res.status_code === 200) {
                getData();
            } else {
                setLoading(false)
            }
        })
    }


    // 完赛证书
    const handleChangeCerificate = (bool, id) => {
        setLoading(true);
        updateCerificateApi({
            id: id,
            projectId: pid,
            rankState: bool ? 1 : 0,
        }).then(res => {
            if (res && res.status_code === 200) {
                getData();
            } else {
                setLoading(false)
            }
        })
    }

    // 赛段任务
    const openStageTask = (row) => { 
        setOpenStageTaskModal(true);
        setTitle(row.name);
        setStageId(row.id);
    };

    const columns = [
        {
            title: '评分赛段', dataIndex: 'name',
        },
        {
            title: '评委任务', dataIndex: 'judgeTaskCount',
        },
        {
            title: '选手任务', dataIndex: 'playerTaskCount',
        },
        {
            title: '赛段评分', dataIndex: 'scoreState', width: 100,
            render: (_, row) => {
                return <Switch
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                    checked={row.scoreState}
                    onChange={(bool) => handleChangeStageScore(bool, row.id)}
                />
            }
        },
        {
            title: '评委点评', dataIndex: 'reviewsState', width: 100,
            render: (_, row) => {
                return <Switch
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                    checked={row.reviewsState}
                    onChange={(bool) => handleChangeJudgeReview(bool, row.id)}
                />
            }
        },
        {
            title: '评分报告', dataIndex: 'reportState', width: 100,
            render: (_, row) => {
                return <Switch
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                    checked={row.reportState}
                    onChange={(bool) => handleChangeCaseReport(bool, row.id)}
                />
            }
        },
        {
            title: '完赛证书', dataIndex: 'rankState', width: 100,
            render: (_, row) => {
                return <Switch
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                    checked={row.rankState}
                    onChange={(bool) => handleChangeCerificate(bool, row.id)}
                />
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 320,
            render: (_, row) => {
                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => openStageTask(row)}
                        >
                            赛段任务
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => navigate(`/selection/projects/${pid}/score-stages/${row.id}/personnel-management`)}
                        >
                            人员管理
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => navigate(`/selection/projects/${pid}/score-stages/${row.id}/topics`)}
                        >
                            评分案例
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleEdit(row)}
                        >
                            编辑
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDeletd(row)}
                        >
                            删除
                        </Button>
                    </span>
                )
            },
        },
    ];


    return (
        <>
            <>
                <div className="page-title">
                    <Link to={`/selection/projects`} className="back">
                        <LeftOutlined />
                    </Link>
                    <span className="current-title">评分管理</span>
                    {/* {!!titleName && <span className="current-subtitle">{titleName}</span>} */}
                </div>


                <Content className="main-content-box">
                    <div className='step-container'>
                        <Steps
                            current='0'
                            labelPlacement="vertical"
                            items={[
                                { title: '创建评分赛段' },
                                { title: '设置赛段任务' },
                                { title: '添加人员管理' },
                                { title: '设置评分案例' }
                            ]}
                        />
                    </div>

                    <div className="filter-form-box">
                        <Space>
                            <Button type="primary" onClick={handleClickMark}>创建评分赛段</Button>
                        </Space>
                    </div>

                    <div className="main-content-body">

                        <Table
                            className="marks-table"
                            rowKey={item => item.id}
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                        />

                    </div>
                </Content >

                <Modal
                    className='mark-change-modal'
                    title={currentId ? '编辑评分赛段' : '创建评分赛段'}
                    open={creatMarkModal}
                    centered
                    onOk={handleCreatMark}
                    maskClosable={false}
                    onCancel={handleCancelCreat}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={handleCancelCreat}>取消</Button>
                                <Button type="primary" onClick={handleCreatMark}>确定</Button>
                            </Space>
                        </div>
                    }
                >
                    <Form
                        form={form}
                        name="createForm"
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="评分赛段名称："
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: '评分赛段名称不能为空',
                                },
                            ]}
                        >
                            <Input placeholder='请输入评分赛段名称' maxLength={20} />
                        </Form.Item>
                    </Form>
                </Modal>
            </>

            <StageTaskModal openStageTaskModal={openStageTaskModal} setOpenStageTaskModal={setOpenStageTaskModal} stageId={stageId} title={title} />

        </>
    );
}

export default ScoreStageList;
