import React, { Fragment, useEffect } from 'react';
import { drawerFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Form, Input, Space, Button, Switch } from 'antd';
const { TextArea } = Input;

export default function AddRoleModal({
    setIsModalVisible, handleAddRole, modalData, handleEditRole
}) {

    const { tip, row } = modalData;

    const [form] = Form.useForm();
    useEffect(() => {
        if (tip === 'edit') {
            form.setFieldsValue({
                name:row.name,
                description:row.description
            })
        }
    }, [tip]);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const onFinish = (value) => {
        value.disabled = 0;
        if (tip === 'add') {
            handleAddRole(value);
        } else {
            value.id = row.id;
            handleEditRole(value);
        }
        setIsModalVisible(false);
    }

    return (
        <Fragment>
            <div className="main-content-body">
                <h2 style={{ marginBottom: '20px' }}>{tip === 'add' ? '新增角色' : '编辑角色'}</h2>
                <Form {...drawerFormItemLayout} form={form} onFinish={onFinish}>

                    <Form.Item
                        label="角色名称"
                        name="name"
                        rules={[{ required: true, message: '输入角色名称' }]}
                    >
                        <Input placeholder="输入角色名称" allowClear autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="角色描述" name="description">
                        <TextArea
                            placeholder="输入角色描述"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            showCount maxLength={100}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        label="状态"
                        name="status"
                        valuePropName="checked"
                        required={true}
                    >
                        <Switch />
                    </Form.Item> */}

                    <Form.Item {...pageFormItemWithoutLabelGridLayout} style={{ marginTop: '50px' }}>
                        <Space>
                            <Button className="submit-button" type="primary" htmlType="submit">保存</Button>
                            <Button type="primary" onClick={handleClose}>取消</Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>

        </Fragment>
    );
}
