import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, DatePicker, Button, Space, Select } from 'antd';
const { RangePicker } = DatePicker;

export default function SurveysListFilterForm({
    handleFilterFinish, createdByStaffId
}) {
    const [form] = Form.useForm();
    const [timePicker, setTimePicker] = useState([]);

    const onFinish = (values) => {
        values.startTime = timePicker[0];
        values.endTime = timePicker[1];
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            surveysStatus: undefined,
            createdByStaffId: undefined,
            startTime: undefined,
            endTime: undefined,
        };
        handleFilterFinish(values);
    };

    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    }
    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="问卷名称" name="name">
                        <Input allowClear placeholder="请输入问卷名称" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="创建时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="创建人" name="createdByStaffId">
                        <Select allowClear placeholder="请选择创建人">
                            {createdByStaffId && createdByStaffId.map(item => {
                                return (
                                    <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="问卷状态" name="surveysStatus">
                        <Select allowClear placeholder="选择问卷状态" >
                            <Select.Option value={1}>未发布</Select.Option>
                            <Select.Option value={2}>收集中</Select.Option>
                            <Select.Option value={3}>已结束</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
