import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Space, Select } from 'antd';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { expertLevels } from '../../../utils/constants';

const ExpertFilterForm = ({
    handleFilterFinish,
    filter,
    setFilter
}) => {
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const onFinish = values => {
        console.log(values);
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            page: 1,
            pageSize: filter.pageSize
        }));
    };

    useEffect(() => {
        form.setFieldsValue(filter);
    });

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="姓名" name="memUsername">
                        <Input allowClear placeholder="请输入姓名" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="memMobile">
                        <Input allowClear placeholder="请输入手机号" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="级别" name="expertLevels">
                        <Select
                            mode="multiple"
                            maxTagCount="responsive"
                            allowClear
                            options={expertLevels}
                            fieldNames={{ label: 'label', value: 'level' }}
                            placeholder="请选择专家级别"
                        />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="性别" name="memGender">
                        <Select allowClear placeholder="请选择平台用户">
                            <Select.Option value={1}>男</Select.Option>
                            <Select.Option value={0}>女</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="单位" name="unitName">
                        <Input allowClear placeholder="请输入单位名称" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="科室/部门" name="unitOffices">
                        <Input allowClear placeholder="请选择科室/部门" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="职务" name="unitPost">
                        <Input allowClear placeholder="请选择职务" />
                    </Form.Item>
                </Col>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="职称" name="unitJobTitle">
                        <Input allowClear placeholder="请输入职称" />
                    </Form.Item>
                </Col>
                {/*<Col {...filterFormGridLayout}>*/}
                {/*    <Form.Item label="领域" name="skilledFields">*/}
                {/*        <Input allowClear placeholder="请输入领域" />*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
                {/*<Col {...filterFormGridLayout}>*/}
                {/*    <Form.Item label="专业" name="specialization">*/}
                {/*        <Input allowClear placeholder="请输入专业" />*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
                <Col {...filterFormGridLayout}>
                    <Form.Item label="专家状态" name="status">
                        <Select allowClear placeholder="请选择">
                            <Select.Option value={0}>正常</Select.Option>
                            <Select.Option value={1}>禁用</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
};

export default ExpertFilterForm;
