import React, { Fragment, useState, useEffect } from 'react';
import { Button, Switch, Col, Dropdown, Upload, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Typography, Divider } from 'antd';
import { SearchOutlined, EllipsisOutlined, ExclamationCircleOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout, pageFormItemLayout } from '@utils/property';
import {
    getPrivilegeUsersApi,
    addPrivilegeUserApi,
    deletePrivilegeUsersApi,
    putPrivilegeUserApi,
    checkPrivilegeUsersApi,
    importPrivilegeUsersApi,
} from '@services/Selection/vote.js';
import { useNavigate, useParams, Link } from 'react-router-dom';
import AddPrivilegedUsers from '../components/AddPrivilegedUsers';
import "./index.less"

const PrivilegedUsers = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { Content } = Layout;
    const { tid, pid } = params || 0;
    const { Text } = Typography;
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();

    const [data, setData] = useState([]);
    const [queryObj, setQueryObj] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleAddPrivilegedUsersDrawer, setVisibleAddPrivilegedUsersDrawer] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [settingModal, setSettingModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [userData, setUserData] = useState([]);

    const onFinish = (res) => {
        setPage(1);
        setQueryObj(res);
    }

    const onReset = () => {
        form.resetFields();
        setPage(1);
        setQueryObj({})
    }

    // 分页
    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const queryParams = {
        mobile: form.getFieldValue('phone'),
        page: page,
        limit: pageSize,
        projectId: pid,
        topicId: tid,
    };

    const getData = () => {
        if (!queryParams.mobile) delete queryParams.mobile;
        getPrivilegeUsersApi(queryParams).then(res => {
            setLoading(false);
            if (res && res.status_code === 200) {
                const { list = [], totalCount } = res.data || {};
                setData(list);
                setTotal(totalCount)
            }
        })
    }

    const columns = [
        {
            title: '姓名', dataIndex: 'username', width: 100,
        },
        {
            title: '手机号', dataIndex: 'mobile', width: 100,
        },
        {
            title: '每次投票票数', dataIndex: 'count', width: 100,
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 100,
            render: (_, record) => {
                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleEdit(record)}

                        >
                            设置
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDeletdUsers(true, record.id)}
                        >
                            移除
                        </Button>
                    </span>
                )
            },
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    useEffect(() => {
        setLoading(true);
        getData();

    }, [page, pageSize, queryObj])


    const handleAddUser = () => {
        form1.validateFields().then((value) => {
            console.log(value, 'handleAddUser');
            addPrivilegeUserApi({
                ...value,
                projectId: pid,
                topicId: tid,
            }).then(res => {
                if (res && res.status_code === 200) {
                    setAddUserModal(false);
                    form1.resetFields();
                    setLoading(true);
                    getData();
                }
            })

        })
    }

    const handleCancelAdd = () => {
        setAddUserModal(false);
        form1.resetFields();
    }

    // 导入模版
    const handleImportTemplate = () => {
        message.success('请稍后，模板正在下载');
        let a = document.createElement('a');
        let event = new MouseEvent('click');
        let objectUrl = 'https://battleship-cloud.oss-cn-beijing.aliyuncs.com/template/selection/%E7%89%B9%E6%9D%83%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx';
        a.href = objectUrl;
        a.dispatchEvent(event);
        URL.revokeObjectURL(objectUrl);
    }


    //excel校验格式
    const fileCheck = (file) => {
        const isExcel = file.type === ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel');
        if (!isExcel) {
            message.error("请上传Excel格式附件");
            setFileList([]);
            setUserData([]);
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("请上传10MB以内的附件!");
            setFileList([]);
            setUserData([]);
        }
        return isExcel && isLt10M
    }

    const uploadProps = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        onRemove() {
            setFileList([]);
            setUserData([]);
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('filename', info.file);
            checkPrivilegeUsersApi(formData).then((res) => {
                if (res && res.status_code === 200) {
                    const { data = [] } = res || {};
                    setFileList([info.file])
                    setUserData(data)
                } else {
                    setFileList([]);
                    setUserData([]);
                }
            })
        },
    };

    // 批量导入弹窗
    const handleImport = () => {
        setFileList([]);
        setUserData([]);
        setImportModal(true);
    }

    const handleImportFile = () => {
        setImportModal(false);
        setVisibleAddPrivilegedUsersDrawer(true);
    }

    const handleCancelImportFile = () => {
        setFileList([]);
        setUserData([]);
        setImportModal(false);
    }


    // 设置
    const handleEdit = (row) => {
        const { id, count, username, mobile } = row || {};
        form3.setFieldsValue({ id, count, username, mobile })
        setSettingModal(true);
    }

    const handleSetting = () => {
        form3.validateFields().then((value) => {
            const obj = form3.getFieldValue();
            putPrivilegeUserApi({ ...obj }).then(res => {
                if (res && res.status_code === 200) {
                    setLoading(true);
                    setSettingModal(false);
                    getData();
                }
            })
        })
    }

    const handleCancelSetting = () => {
        setSettingModal(false);
    }


    // 移除用户&批量移除
    const handleDeletdUsers = (type, id) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确定移除特权票用户`,
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: () => {
                let res;
                res = type ? id : selectedRowKeys.join(',');
                deletePrivilegeUsersApi({ ids: res }).then(res => {
                    if (res && res.status_code === 200) {
                        if (res.data) {
                            setLoading(true);
                            if (!type) setSelectedRowKeys([]);
                            message.success('移除成功');
                            if (page == 1) {
                                getData();
                            } else {
                                setPage(1);
                            }
                        } else {
                            message.warning('移除失败')
                        }
                    }
                })
            }
        });
    }


    const closeAddPrivilegedUsersDrawer = () => {
        setVisibleAddPrivilegedUsersDrawer(false);
    }

    const handleAddPrivilegedUserst = () => {
        setBtnLoading(true);
        const { list = [] } = userData || {};
        importPrivilegeUsersApi({
            projectId: pid,
            topicId: tid,
            casePrivilegeUsersDownVos: [...list]
        }).then(res => {
            setBtnLoading(false);
            if (res && res.status_code === 200) {
                setVisibleAddPrivilegedUsersDrawer(false);
                const { total, success } = res?.data || {};
                setLoading(true);
                getData();
                handleAddPrivilegedUserstModal(total, success);
            }
        })
    }

    // 成功导入
    const handleAddPrivilegedUserstModal = (total, success) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `共读取到${total}条数据，成功导入${success}条，未导入${total - success}条`,
            okText: '确认',
            centered: true,
            cancelButtonProps: { style: { display: 'none' } },
        });
    }


    return (
        <>
            <div className="page-title">
                <Link to={`/selection/projects/${pid}/vote/topics`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">特权用户</span>
            </div>


            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="phone" >
                                    <Input allowClear placeholder="请输入手机号" autoComplete='off' maxLength={11} />
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <Space size='middle'>
                            <Button type="primary" onClick={() => setAddUserModal(true)}>添加用户</Button>
                            <Button type="default" onClick={handleImportTemplate}>导入模版下载</Button>
                            <Button type="default" onClick={handleImport}>批量导入</Button>
                            <Button type="default" disabled={!(selectedRowKeys.length > 0)} onClick={() => handleDeletdUsers(false)}>批量移除</Button>
                        </Space>
                    </div>

                    <Table
                        className="vote-table"
                        rowKey={item => item.id}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />

                </div>
            </Content >

            <Modal
                title='添加特权票用户'
                open={addUserModal}
                centered
                maskClosable={false}
                destroyOnClose
                onCancel={handleCancelAdd}
                onOk={handleAddUser}
                footer={
                    <Space>
                        <Button onClick={handleCancelAdd}>取消</Button>
                        <Button type="primary" htmlType="submit" onClick={handleAddUser}>确认添加</Button>
                    </Space>
                }
            >
                <Form
                    {...pageFormItemLayout}
                    labelCol={{ span: 6 }}
                    form={form1}
                >
                    <Form.Item
                        name="username"
                        label="姓名"
                        rules={[
                            { required: true, message: '请输入姓名' },
                        ]}
                    >
                        <Input placeholder="请输入姓名" allowClear />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="手机号"
                        rules={[
                            { required: true, message: '请输入手机号' },
                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                        ]}
                    >
                        <Input placeholder="请输入手机号" maxLength={11} allowClear />
                    </Form.Item>
                    <Form.Item
                        name="count"
                        label="每次投票票数"
                        rules={
                            [
                                { required: true, message: "请输入每次投票票数", trigger: 'blur' },
                                { pattern: /^\+?[1-9]\d*$/, message: '请输入大于0的正整数' }
                            ]
                        }
                    >
                        <Input placeholder="请输入每次投票票数" allowClear />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                className='mark-import-modal'
                title='导入特权票用户'
                open={importModal}
                centered
                maskClosable={false}
                onCancel={handleCancelImportFile}
                onOk={handleImportFile}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleCancelImportFile}>取消</Button>
                            <Button type="primary" htmlType="submit" disabled={!(fileList && fileList.length)} onClick={handleImportFile}>读取数据</Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    className="judge-form2"
                    form={form2}
                >
                    <Form.Item
                        name="excel"
                        label="导入文件"
                        help="支持Excel格式,文件大小不超过10MB"
                    >
                        <Upload
                            beforeUpload={(file) => fileCheck(file)}
                            {...uploadProps}
                            maxCount={1}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>选择文件</Button>
                        </Upload>

                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='设置'
                open={settingModal}
                centered
                maskClosable={false}
                destroyOnClose
                onCancel={handleCancelSetting}
                onOk={handleSetting}
                footer={
                    <Space>
                        <Button onClick={handleCancelSetting}>取消</Button>
                        <Button type="primary" htmlType="submit" onClick={handleSetting}>确认</Button>
                    </Space>
                }
            >
                <Form
                    {...pageFormItemLayout}
                    labelCol={{ span: 6 }}
                    form={form3}
                >
                    <Form.Item
                        name="username"
                        label="姓名"
                        rules={[
                            { required: true, message: '请输入姓名' },
                        ]}
                    >
                        <Input placeholder="请输入姓名" allowClear />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="手机号"
                        rules={[
                            { required: true, message: '请输入手机号' },
                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                        ]}
                    >
                        <Input placeholder="请输入手机号" maxLength={11} allowClear />
                    </Form.Item>
                    <Form.Item
                        name="count"
                        label="每次投票票数"
                        rules={
                            [
                                { required: true, message: "请输入每次投票票数" },
                                { pattern: /^\+?[1-9]\d*$/, message: '请输入大于0的正整数' }
                            ]
                        }
                    >
                        <Input placeholder="请输入每次投票票数" allowClear />
                    </Form.Item>
                </Form>
            </Modal>


            <AddPrivilegedUsers
                visibleAddPrivilegedUsersDrawer={visibleAddPrivilegedUsersDrawer}
                closeAddPrivilegedUsersDrawer={closeAddPrivilegedUsersDrawer}
                handleAddPrivilegedUserst={handleAddPrivilegedUserst}
                userData={userData}
                btnLoading={btnLoading}
            />
        </>

    );
}

export default PrivilegedUsers;

