import { get, put, post } from '@/core/request';

/*
    接口服务--调研组件
*/

// 问卷模版预览
export const previewTemplateApi= (params) => {
    return get('/survey/templatesurveys/preview', params);
};

// 模版列表
export const listTemplateApi = (params) => {
    return get('/survey/templatesurveys/list', params);
};

//模版复制 127.0.0.1:9015/survey/templatesurveys/copy-one?id=1
export const copyTemplateApi = (params) => {
    return get('/survey/templatesurveys/copy-one', params);
};

// 上架&下架 127.0.0.1:9015/survey/templatesurveys/release
export const releaseTemplateApi = (params) => {
    return put('/survey/templatesurveys/release', params);
};

// 添加模版
export const saveTemplateApi = (data) => {
    return post('/survey/templatesurveys/save', data);
};


// 新增模版题目
export const saveTemplateQuestionApi = (data) => {
    return post('/survey/templatesurveyquestions/save', data);
};
//实时保存模版
export const timesaveTemplateQuestionApi = (data) => {
    return put('/survey/templatesurveyquestions/real-time-save', data);
};
// 删除模版题目
export const delTemplateQuestionApi = (params) => {
    return get('/survey/templatesurveyquestions/delete', params);
};

//模版题目排序
export const sortTemplateQuestionApi = (data) => {
    return put('/survey/templatesurveyquestions/sort', data);
};


//删除选项
export const delOptionsApi=(data) =>{
    return get('/survey/templatesurveysquestionoptions/delete',data)
}

//切换题目类型
export const changeTypeApi=(data) =>{
    return put('/survey/templatesurveyquestions/change-type',data)
}

