import React, { Fragment, useState, useEffect, useRef } from "react";
import {Button,Space,Table,Typography,Drawer} from "antd";
const { Text } = Typography;

const AddPrivilegedUsers = ({
    closeAddPrivilegedUsersDrawer,
    visibleAddPrivilegedUsersDrawer,
    handleAddPrivilegedUserst,
    userData,
    btnLoading,
}) => {

    const columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            width: 100,
        },
        {
            title: "手机号",
            name: "mobile",
            width: 100,
            render: (_, record) => (
                <Fragment>
                    {record.mobile ? <Text>{record.mobile}</Text> : <Text type="secondary">&ndash;</Text>}
                </Fragment>
            ),
        },
        {
            title: "每次投票票数",
            name: "count",
            width: 100,
            render: (_, record) => (
                <Fragment>
                    {record.count ? <Text>{record.count}</Text> : <Text type="secondary">&ndash;</Text>}
                </Fragment>
            ),
        },
        {
            title: '提示',
            dataIndex: 'prompt',
            width: 100,
            render: (_, record) =>
                <Fragment>
                    {record.prompt==='数据异常' ? <Text type="danger">{record.prompt}</Text> : <Text>可导入</Text>}
                </Fragment>
        },
    ]

    return (
        <Fragment>
            <Drawer
                title="导入数据确认"
                placement="bottom"
                height="100%"
                destroyOnClose
                open={visibleAddPrivilegedUsersDrawer}
                onClose={closeAddPrivilegedUsersDrawer}
                bodyStyle={{ paddingTop: 0 }}
                footer={
                    <div
                        className="custom-drawer-footer"
                        style={{ textAlign: "right" }}
                    >
                        <Space>
                            <Button onClick={closeAddPrivilegedUsersDrawer}>取消</Button>
                            <Button type="primary" loading={btnLoading} onClick={handleAddPrivilegedUserst}>
                                开始导入
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Space size='middle' style={{ float: 'right', margin: '12px' }}>
                    <Text>共读取到：{userData?.total}条</Text>
                    <Text >可导入：{userData?.download}条</Text>
                    <Text >数据异常：{userData?.total-userData?.download}条</Text>
                </Space>

                <Table
                    columns={columns}
                    dataSource={userData?.list}
                    rowKey={(_,index) => index}
                    pagination={false}
                />
            </Drawer>
        </Fragment>
    );
};

export default AddPrivilegedUsers;
