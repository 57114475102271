import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    Table,
    Modal,
    Typography,
    Alert,
    Row,
    Col,
    Button
} from 'antd';
import { laborFeeAmountResultApi } from '@services/SereviseConst';
import { laborFeeCostBearers, laborFeePaymentMethods, laborFee3rdPartyPaymentMethods } from '@utils/constants';
import { convertDateTimeToLocalText,numberProject } from '@utils/utils';
import './IndividualConfirmResultModal.less';
import moment from 'moment';
import html2canvas from 'html2canvas';

const { Text } = Typography;

// 含税劳务费请款审批结果
const IndividualConfirmResult = ({
    openIndividualConfirmModal,
    setOpenIndividualConfirmModal,
    staffs,
    conference,
    statisticalAmount
}) => {
    const params = useParams();

    const conferenceId = params?.id || 0; // 会议项目 ID

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [laborFeeInfo, setLaborFeeInfo] = useState();

    const handleCancel = () => {
        setOpenIndividualConfirmModal(false);
    };

    const formatCurrency = value => {
        let result = '';

        const f = parseFloat(value) || 0.00;
        const parts = f.toFixed(2).split('.');
        result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

        return result;
    };

    const laborFeeAmountResult = async () => {
        setLoading(true);
        const res = await laborFeeAmountResultApi({
            projectId: conferenceId,
        });
        if (res.status_code === 200) {
            setLaborFeeInfo(res?.data);
            setData(res?.data?.projectLaborFeeRecordsAdjustLogsList || []);
        }
        setLoading(false);
    };

    // 获取含税金额
    const getTotalAmount = (LaborFeeSum, IndividualTaxSum) => {
        if (LaborFeeSum !== null && IndividualTaxSum !== null) {
            return (Number(LaborFeeSum) + Number(IndividualTaxSum)).toFixed(2)
        }
    };

    const getTotalNeedLaborFee = () => {
        let totalNeedLaborFee = 0;
        if (statisticalAmount?.realTotal && (statisticalAmount?.LaborFeeNullSum || statisticalAmount?.LaborFeeNullSum === 0) && (statisticalAmount?.LaborFeeZeroSum || statisticalAmount?.LaborFeeZeroSum === 0)) {
            totalNeedLaborFee = statisticalAmount.realTotal - statisticalAmount.LaborFeeNullSum - statisticalAmount.LaborFeeZeroSum;
        }
        return totalNeedLaborFee;
    };

    const exportHtmlDomAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el, { useCORS: true });

        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = imageFileName;
        link.href = canvas.toDataURL('image/png', 1.0);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    // 下载审批结果截图
    const downConfirmResult = () => {
        const el = document.getElementById('individual-confirm-content');
        const fileName = '含税劳务费请款审批结果' + '.png';
        exportHtmlDomAsImage(el, fileName);
    };

    // 获取专家证件类型
    const getIdcardType = (cardType) => {
        const type = parseInt(cardType);
        if (type === 2) return '港澳居民来往内地通行证';
        if (type === 3) return '台湾居民来往大陆通行证';
        if (type === 4) return '外国人永久居留身份证';
        if (type === 5) return '护照';
        return null;
    };

    useEffect(() => {
        if (openIndividualConfirmModal) {
            laborFeeAmountResult();
        }
    }, [openIndividualConfirmModal]);

    let columns = [
        { title: '专家姓名', width: 100, dataIndex: 'memUsername', fixed: 'left' },
        { title: '手机号', dataIndex: 'memMobile', width: 120, },
        {
            title: '单位任职',
            dataIndex: 'unitPost',
            width: 180,
            // align: 'center',
            render: (_, row) => {
                if (row.workExpList && row.workExpList.length) {
                    return <>
                        {row.workExpList.map((item, idx) => {
                            return <div key={`w_${item.id}`}>
                                {row.workExpList.length > 1 && <span>{idx + 1}. </span>}
                                {!!item.unitName && <span>{item.unitName}</span>}
                                {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                {!!item.unitPost && <span>{item.unitPost}</span>}
                            </div>
                        })}
                    </>
                } else {
                    return '—';
                }
            },
        },
        {
            title: '税后劳务费/元',
            dataIndex: 'laborFeeAmount',
            width: 130,
            align: 'right',
            render: (_, record) => record.laborFeeAmount !== null
                ? <span>&yen;{`${formatCurrency(record.laborFeeAmount)}`}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款方式',
            dataIndex: 'paymentMethod',
            width: 150,
            render: (_, record) => {
                if (record.paymentMethod !== record.paymentMethodAdjust) {
                    const paymentMethodName = laborFeePaymentMethods.find(m => m.value === record.paymentMethod)?.label || <Text type="secondary">&ndash;</Text>;
                    const paymentMethodAdjustName = laborFeePaymentMethods.find(m => m.value === record.paymentMethodAdjust)?.label || <>&ndash;</>;
                    return <>
                        {paymentMethodName} <Text type="danger"> 变更后 {paymentMethodAdjustName}</Text>
                    </>
                } else {
                    return laborFeePaymentMethods.find(m => m.value === record.paymentMethod)?.label || <Text type="secondary">&ndash;</Text>
                }
            }
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 150,
            render: (_, record) => {
                if (record.costBearer !== record.costBearerAdjust) {
                    const costBearerName = laborFeeCostBearers.find(m => m.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>;
                    const costBearerAdjustName = laborFeeCostBearers.find(m => m.value === record.costBearerAdjust)?.label || <>&ndash;</>;
                    return <>
                        {costBearerName} <Text type="danger"> 变更后 {costBearerAdjustName}</Text>
                    </>
                } else {
                    return laborFeeCostBearers.find(b => b.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>
                }
            }
        },
        {
            title: '个人所得税/元',
            dataIndex: 'individualTaxAmount',
            width: 150,
            render: (_, record) => {
                if (!!record.laborFeeAmount && !!record.paymentMethod) {
                    if (record.individualTaxAmount !== record.individualTaxAmountAdjust) {
                        const individualTaxAmount = !!record.individualTaxAmount
                            ? <Text type="secondary">&yen;{`${formatCurrency(record.individualTaxAmount)}`}</Text>
                            : <Text type="secondary">&yen;0.00</Text>
                        const individualTaxAmountAdjust = !!record.individualTaxAmountAdjust
                            ? <>&yen;{`${formatCurrency(record.individualTaxAmountAdjust)}`}</>
                            : <>&yen;0.00</>
                        return <>{individualTaxAmount} <Text type="danger"> 变更后 {individualTaxAmountAdjust}</Text></>
                    }
                    return !!record.individualTaxAmount
                        ? <span>&yen;{`${formatCurrency(record.individualTaxAmount)}`}</span>
                        : <Text type="danger"> &yen;0.00</Text>
                }
                return <Text type="secondary">&ndash;</Text>
            }
        },
        {
            title: '含税劳务费/元',
            dataIndex: 'sumOfLaborFeeCast',
            width: 150,
            render: (_, record) => {
                if (!!record.laborFeeAmount || !!record.individualTaxAmountAdjust) {
                    let laborFeeAmount = parseFloat(record.laborFeeAmount) || 0.00;
                    let individualTaxAmount = parseFloat(record.individualTaxAmount) || 0.00;
                    let individualTaxAmountAdjust = parseFloat(record.individualTaxAmountAdjust) || 0.00;

                    return <>&yen;{`${formatCurrency(laborFeeAmount + individualTaxAmount)}`} <Text type="danger"> 变更后 &yen;{`${formatCurrency(laborFeeAmount + individualTaxAmountAdjust)}`} </Text></>;

                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '劳务信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {
                    (record.idcardType === 1 || !record.idcardType) && <>
                        <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    </>
                }
                {record.idcardType > 1 && <>
                    <span>出生日期：{(!!record.birthday && moment(record.birthday).format('YYYY-MM-DD')) || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女')) || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>证件类型：{getIdcardType(record.idcardType) || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                </>
                }
            </>),
        },
        {
            title: '修改人',
            dataIndex: 'modifiedName',
            width: 100,
        },
        {
            title: '修改时间',
            dataIndex: 'createdAt',
            width: 250,
            render: (_, record) => (<>
                {record.createdAt || <Text type="secondary">&ndash;</Text>}
            </>),
        },
    ];

    return (
        <>
            <Modal
                title='财务部门复核结果'
                open={openIndividualConfirmModal}
                centered
                onCancel={handleCancel}
                className="individual-confirm-modal"
                footer={
                    <Button type="primary" onClick={() => downConfirmResult()}>下载复核结果截图</Button>
                }
            >
                <div id="individual-confirm-content" className="individual-confirm-content">
                    <div className="labor-fee-confirm-info-box">
                        <div className="labor-fee-project">
                            {laborFeeInfo?.name}
                        </div>
                        <div className="labor-fee-confirm-info">
                            【{laborFeeInfo?.name}】含税总额已由【{laborFeeInfo?.individualTaxConfirmedBy}】于【{convertDateTimeToLocalText(laborFeeInfo?.individualTaxConfirmedAt) || ''}】复核完毕，共{getTotalNeedLaborFee()}位专家需要支付劳务费用，其中实发劳务费总计：{laborFeeInfo?.LaborFeeSum}元，相关税费总计：{laborFeeInfo?.IndividualTaxSum}元，含税劳务费总计：{getTotalAmount(laborFeeInfo?.LaborFeeSum, laborFeeInfo?.IndividualTaxSum)}元。
                        </div>
                        <div className="labor-fee-confirm-sig">
                            <div className="labor-fee-confirm-sig-content">
                                <div className="sig-name">氚云项目编号：{laborFeeInfo?.h3yunNo}</div>
                                <div className="sig-name">项目编号：{numberProject("YHY",conferenceId)}</div>
                                <div className="confirm-datetime">项目创建人：{laborFeeInfo?.createdBy}</div>
                            </div>
                            <div className="labor-fee-confirm-sig-content">
                                <div className="sig-name">实发劳务费审核人：{laborFeeInfo?.laborFeeConfirmedBy}</div>
                                <div className="confirm-datetime">实发劳务费审核时间：{convertDateTimeToLocalText(laborFeeInfo?.laborFeeConfirmedAt) || ''}</div>
                            </div>
                            <div className="labor-fee-confirm-sig-content">
                                <div className="sig-name">含税劳务费审核人：{laborFeeInfo?.individualTaxConfirmedBy}</div>
                                <div className="confirm-datetime">含税劳务费审核时间：{convertDateTimeToLocalText(laborFeeInfo?.individualTaxConfirmedAt) || ''}</div>
                            </div>
                        </div>
                    </div>
                    {
                        !!data && !!data.length && <div className="labor-fee-confirm-table">
                        <div className="labor-fee-project">
                            变更记录
                        </div>
                        <Table
                            size="small"
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            pagination={false}
                        />
                    </div>
                    }
                    

                    <div>
                        <div className="labor-fee-project">
                            最终合计结果
                        </div>
                        {!_.isEmpty(statisticalAmount) && (
                            <Alert
                                type="info"
                                style={{ marginBottom: 16, fontSize: 12 }}
                                message={<Row>
                                    <Col className="summary-ammount" span={24}>
                                        <span style={{ fontSize: 12 }}>
                                            实发劳务费总计：&yen;{formatCurrency(statisticalAmount?.LaborFeeSum)}元，
                                            相关税费总计：&yen;{formatCurrency(statisticalAmount?.IndividualTaxSum)}元，
                                            含税劳务费总计：&yen;{formatCurrency(getTotalAmount(statisticalAmount?.LaborFeeSum, statisticalAmount?.IndividualTaxSum))}元，
                                            共{statisticalAmount?.expertSum}位专家
                                        </span>
                                    </Col>
                                </Row>}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default IndividualConfirmResult;
