import React, { Fragment, useEffect } from 'react';
import { Button, Drawer, Form, Input, Select, Space, message } from 'antd';
import definedSiteModules from '../../../site_module_types';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';
import { useParams } from 'react-router-dom';
import { saveSiteModuleApi } from '@services/SiteModule';

export default ({
    visibleAddSiteModuleDrawer,
    setVisibleAddSiteModuleDrawer,
    preparedSiteModules,
    getSiteModuleByType,
    getSiteModules
}) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID
    const [form] = Form.useForm();

    // 已添加的内置模块（内置模块仅能添加一次）
    const addedBuiltinModuleTypes = () => {
        let moduleTypes = [];
        preparedSiteModules.forEach(m => {
            if (m.builtin === 1) {
                moduleTypes.push(m.moduleType);
            }
        });
        // console.log(moduleTypes);
        return moduleTypes;
    };

    const closeAddSiteModuleDrawer = () => {
        form.resetFields();
        setVisibleAddSiteModuleDrawer(false);
    };

    // 新增模块 Drawer 切换模块类型
    const onChangeModuleType = value => {
        let values = {
            displayTitle: undefined,
            path: undefined,
        };

        if (!!value && value <= 10) {
            const moduleType = getSiteModuleByType(value);
            values = {
                displayTitle: moduleType?.title,
                path: moduleType?.path,
            };
        }

        form.setFieldsValue(values);
    };

    // 新增模块
    const onFinish = async (values) => {
        console.log(values)
        let params = {
            ...values,
            conferenceId,
        };
        const res = await saveSiteModuleApi(params);
        if (res.status_code === 200) {
            message.success('新增模块成功');
            setVisibleAddSiteModuleDrawer(false);
            getSiteModules();
        }
    };

    useEffect(() => {
        if (!visibleAddSiteModuleDrawer) {
            form.resetFields();
        }
    }, [visibleAddSiteModuleDrawer]);

    return (
        <Drawer
            title="新增网站模块"
            height="100%"
            placement="bottom"
            open={visibleAddSiteModuleDrawer}
            onClose={closeAddSiteModuleDrawer}
            maskClosable={false}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeAddSiteModuleDrawer}>取消</Button>
                        <Button type="primary" form="addSiteModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="addSiteModuleForm"
                form={form}
                autoComplete="off"
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >

                <Form.Item
                    name="moduleType"
                    label="模块类型"
                    rules={[
                        { required: true, message: '模块类型为必选项' }
                    ]}
                >
                    <Select placeholder="请选择模块类型" onChange={onChangeModuleType} allowClear>
                        {definedSiteModules
                            .filter(m => addedBuiltinModuleTypes().indexOf(m.moduleType) === -1)
                            .map(m => <Select.Option value={m.moduleType}>{m.title}（{m.name}）</Select.Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.moduleType !== curValues.moduleType}>
                    {({ getFieldValue }) => {
                        const selectedModuleType = getFieldValue('moduleType') || 0;
                        return !!selectedModuleType && <Fragment>

                            <Form.Item
                                name="displayTitle"
                                label="显示标题"
                                rules={[
                                    { required: true, message: '显示标题不能为空' }
                                ]}
                            >
                                <Input placeholder="请输入模块显示标题" />
                            </Form.Item>

                            <Form.Item name="displayTitleEn" label="英文显示标题">
                                <Input placeholder="请输入模块英文显示标题" />
                            </Form.Item>

                            {
                                (selectedModuleType !== 21 && selectedModuleType !== 22) && <Form.Item
                                    name="path"
                                    label="访问路径"
                                    rules={[
                                        { required: true, message: '访问路径不能为空' },
                                        { pattern: /^[a-z\d_]{2,20}$/, message: '访问路径不符合格式要求' },
                                    ]}
                                    help={<Fragment>
                                        填写访问该模块的访问路径，如会议简介访问路径 <b>introduction</b><br />
                                   格式合求：小写字母、数字或下划线（_），限2-20个字符
                               </Fragment>}
                                >
                                    <Input placeholder="请输入模块访问路径" minLength={2} maxLength={20} showCount disabled={selectedModuleType <= 10} />
                                </Form.Item>
                            }

                            {selectedModuleType === 21 && (
                                <Form.Item
                                    name="url"
                                    label="外部链接"
                                >
                                    <Input placeholder="请输入外部链接" />
                                </Form.Item>
                            )}

                        </Fragment>
                    }}
                </Form.Item>

            </Form>
        </Drawer>
    );
};
