import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Layout, Descriptions, Row, Col, Space, Checkbox, Card, Tree, Form, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { get, put } from '@/core/request';

const { Content } = Layout;

export default function RolePermissions() {

    const { state } = useLocation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    // 传给后端的角色权限ids
    const [allRole, setAllRole] = useState({
        checkboxArr: []
    });

    const [allPermissions, setAllPermissions] = useState(null);

    let length1 = {
        bossLength: 0,
        channelLength: 0,
        dmsLength: 0,
        userLength: 0,
        kbLength: 0
    };

    let groupArr = [];

    // 数组删除指定元素
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };

    // 具体权限的选中取消
    let checkboxArr = [];
    const onCheckboxChange = (name) => {
        return (e) => {
            if (checkboxArr.includes(e.target.value)) {
                if (name === 'CHANNEL') {
                    length1.channelLength--;
                } else if (name === 'DMS') {
                    length1.dmsLength--;
                } else if (name === 'USER') {
                    length1.userLength--;
                } else if (name === 'BOSS') {
                    length1.bossLength--;
                } else if (name === 'KB') {
                    length1.kbLength--;
                }
                checkboxArr.remove(e.target.value)
            } else {
                if (name === 'CHANNEL') {
                    length1.channelLength++;
                } else if (name === 'DMS') {
                    length1.dmsLength++;
                } else if (name === 'USER') {
                    length1.userLength++;
                } else if (name === 'BOSS') {
                    length1.bossLength++;
                } else if (name === 'KB') {
                    length1.kbLength++;
                }
                checkboxArr.push(e.target.value)
            }
            controlSpanStyle()
            document.getElementsByClassName('title-checkbox-length')[0].innerHTML = length1.bossLength
            document.getElementsByClassName('title-checkbox-length')[1].innerHTML = length1.channelLength
            document.getElementsByClassName('title-checkbox-length')[2].innerHTML = length1.dmsLength
            document.getElementsByClassName('title-checkbox-length')[3].innerHTML = length1.userLength
            setAllRole({ checkboxArr });
        }
    }

    // 控制展开和关闭
    const [openKey, setOpenkey] = useState([]);

    // 默认展开和默认关闭
    const handleOpenKeys = (tip) => {
        return () => {
            if (tip === 'open') {
                setOpenkey(saveKey);
            } else {
                setOpenkey([]);
            }
        }
    };
    const handleExpand = (expandedKeys) => {
        setOpenkey(expandedKeys);
    }

    function controlSpanStyle() {
        if (length1.bossLength === 0) {
            document.getElementsByClassName('title-checkbox-length')[0].classList.add('span-display-none');
        } else {
            document.getElementsByClassName('title-checkbox-length')[0].classList.remove('span-display-none');
        }
        if (length1.channelLength === 0) {
            document.getElementsByClassName('title-checkbox-length')[1].classList.add('span-display-none');
        } else {
            document.getElementsByClassName('title-checkbox-length')[1].classList.remove('span-display-none');
        }
        if (length1.dmsLength === 0) {
            document.getElementsByClassName('title-checkbox-length')[2].classList.add('span-display-none');
        } else {
            document.getElementsByClassName('title-checkbox-length')[2].classList.remove('span-display-none');
        }
        if (length1.userLength === 0) {
            document.getElementsByClassName('title-checkbox-length')[3].classList.add('span-display-none');
        } else {
            document.getElementsByClassName('title-checkbox-length')[3].classList.remove('span-display-none');
        }
        if (length1.kbLength === 0) {
            document.getElementsByClassName('title-checkbox-length')[4].classList.add('span-display-none');
        } else {
            document.getElementsByClassName('title-checkbox-length')[4].classList.remove('span-display-none');
        }
    }

    function deleteRepeatElement(checkboxArr, arr1, tip) {
        for (let i = checkboxArr.length - 1; i >= 0; i--) {
            arr1.forEach(item => {
                if (checkboxArr[i]) {
                    if (item === checkboxArr[i]) {
                        checkboxArr.splice(i, 1)
                    }
                }
            })
        };
        if (tip === 'all') {
            checkboxArr.push(...arr1);
        }
        setAllRole({ checkboxArr });
        if (groupArr.length > 0) {
            groupArr.forEach(item => {
                form.setFieldsValue({
                    [item]: checkboxArr
                })
            });
        }
    }

    // 模块的全选 取消
    const handleClickModuleAllCheck = (tip, item) => {
        return (e) => {
            let arr1 = [];
            item.groupList.forEach(elem => {
                elem.allPermissions.forEach(element => {
                    arr1.push(element.id)
                })
            })
            arr1.sort((a, b) => { return a - b });
            checkboxArr.sort((a, b) => { return a - b });
            deleteRepeatElement(checkboxArr, arr1, tip, item.moduleId)
            if (tip === 'all') {
                if (item.moduleId === 'CHANNEL') {
                    length1.channelLength = arr1.length;
                } else if (item.moduleId === 'DMS') {
                    length1.dmsLength = arr1.length;
                } else if (item.moduleId === 'USER') {
                    length1.userLength = arr1.length;
                } else if (item.moduleId === 'BOSS') {
                    length1.bossLength = arr1.length;
                } else if (item.moduleId === 'KB') {
                    length1.kbLength = arr1.length;
                }
            } else {
                if (item.moduleId === 'CHANNEL') {
                    length1.channelLength = 0;
                } else if (item.moduleId === 'DMS') {
                    length1.dmsLength = 0;
                } else if (item.moduleId === 'USER') {
                    length1.userLength = 0;
                } else if (item.moduleId === 'BOSS') {
                    length1.bossLength = 0;
                } else if (item.moduleId === 'KB') {
                    length1.kbLength = 0;
                }
            }
            controlSpanStyle()
            document.getElementsByClassName('title-checkbox-length')[0].innerHTML = length1.bossLength
            document.getElementsByClassName('title-checkbox-length')[1].innerHTML = length1.channelLength
            document.getElementsByClassName('title-checkbox-length')[2].innerHTML = length1.dmsLength
            document.getElementsByClassName('title-checkbox-length')[3].innerHTML = length1.userLength
            document.getElementsByClassName('title-checkbox-length')[4].innerHTML = length1.kbLength
        }
    }

    // 分组的全选取消
    const handleClickAllCheck = (tip, item, name) => {
        return () => {
            let arr1 = item.allPermissions.map(elem => {
                return elem.id
            }).sort((a, b) => { return a - b });
            checkboxArr.sort((a, b) => { return a - b });
            deleteRepeatElement(checkboxArr, arr1, tip, name);
            let arr2 = checkboxArr.concat();
            if (tip === 'all') {
                // 删除已经选中的里面包含的
                for (let i = arr2.length - 1; i >= 0; i--) {
                    arr1.forEach(item => {
                        if (arr2[i]) {
                            if (item === arr2[i]) {
                                arr2.splice(i, 1)
                            }
                        }
                    })
                };
                if (name === 'CHANNEL') {
                    length1.channelLength = arr2.length - length1.dmsLength - length1.userLength - length1.bossLength - length1.kbLength + arr1.length;
                } else if (name === 'DMS') {
                    length1.dmsLength = arr2.length - length1.channelLength - length1.userLength - length1.bossLength - length1.kbLength + arr1.length;
                } else if (name === 'USER') {
                    length1.userLength = arr2.length - length1.channelLength - length1.dmsLength - length1.bossLength - length1.kbLength + arr1.length;
                } else if (name === 'BOSS') {
                    length1.bossLength = arr2.length - length1.channelLength - length1.dmsLength - length1.userLength - length1.kbLength + arr1.length;
                } else if (name === 'KB') {
                    length1.kbLength = arr2.length - length1.channelLength - length1.dmsLength - length1.userLength - length1.bossLength + arr1.length;
                }
            } else {
                if (name === 'CHANNEL') {
                    length1.channelLength = arr2.length - length1.dmsLength - length1.userLength - length1.bossLength - length1.kbLength;
                } else if (name === 'DMS') {
                    length1.dmsLength = arr2.length - length1.channelLength - length1.userLength - length1.bossLength - length1.kbLength;
                } else if (name === 'USER') {
                    length1.userLength = arr2.length - length1.channelLength - length1.dmsLength - length1.bossLength - length1.kbLength;
                } else if (name === 'BOSS') {
                    length1.bossLength = arr2.length - length1.channelLength - length1.dmsLength - length1.userLength - length1.kbLength;
                } else if (name === 'KB') {
                    length1.kbLength = arr2.length - length1.channelLength - length1.dmsLength - length1.userLength - length1.bossLength;
                }
            }
            controlSpanStyle()
            document.getElementsByClassName('title-checkbox-length')[0].innerHTML = length1.bossLength
            document.getElementsByClassName('title-checkbox-length')[1].innerHTML = length1.channelLength
            document.getElementsByClassName('title-checkbox-length')[2].innerHTML = length1.dmsLength
            document.getElementsByClassName('title-checkbox-length')[3].innerHTML = length1.userLength
            document.getElementsByClassName('title-checkbox-length')[4].innerHTML = length1.kbLength
        }
    }

    const renderModuleTitle = (item) => {
        return (
            <Space>
                <span className="roles-tabs-title">{item.moduleName}
                    <span className="title-checkbox-length">1</span>
                </span>
                <span className="operate-style" onClick={handleClickModuleAllCheck('all', item)}>全选</span>
                <span className="operate-style" onClick={handleClickModuleAllCheck('back', item)}>取消</span>
            </Space>
        )
    };

    const renderGroupTitle = (item, pName) => {
        return (
            <Space>
                <span className="roles-tabs-title">{item.groupName}</span>
                <span className="operate-style" onClick={handleClickAllCheck('all', item, pName)}>全选</span>
                <span className="operate-style" onClick={handleClickAllCheck('back', item, pName)}>取消</span>
            </Space>
        )
    };

    // 提交
    const handleClickSubmit = () => {
        console.log(allRole.checkboxArr)
        put(`/enterprise/permissions/update_role`, {
            id: state.id,
            roleHasPermissions: allRole.checkboxArr
        }).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                navigate('/enterprise/roles')
            }
        })
    }

    const [saveKey, setSaveKey] = useState([]);


    // 渲染页面数据
    useEffect(() => {
        let channelLen = 0; let dmsLen = 0; let userLen = 0; let bossLen = 0; let kbLen = 0;
        get(`/enterprise/permissions/find_role`, { roleId: state.id }).then((res) => {
            if (res.status_code === 200) {
                let openkey = [];
                let defaultChecked = [];
                const { moduleList } = res.data;
                let arr = moduleList.map(item => {
                    openkey.push(item.moduleId);
                    return {
                        ...item,
                        title: renderModuleTitle(item),
                        key: item.moduleId,
                        children: item.groupList && item.groupList.map((elem, index) => {
                            groupArr.push(elem.groupId);
                            openkey.push(elem.groupId);
                            return {
                                ...elem,
                                title: renderGroupTitle(elem, item.moduleId),
                                key: elem.groupId,
                                children: [{
                                    title: (
                                        <Form.Item name={`name${elem.groupId}`}>
                                            <Checkbox.Group className="roles-checkbox">
                                                <Row>
                                                    {
                                                        elem.allPermissions && elem.allPermissions.map((element, i) => {
                                                            if (element.isSelect) {
                                                                defaultChecked.push(element.id);
                                                                if (item.moduleId === 'BOSS') {
                                                                    bossLen++;
                                                                } else if (item.moduleId === 'CHANNEL') {
                                                                    channelLen++;
                                                                } else if (item.moduleId === 'DMS') {
                                                                    dmsLen++;
                                                                } else if (item.moduleId === 'USER') {
                                                                    userLen++;
                                                                } else if (item.moduleId === 'KB') {
                                                                    kbLen++;
                                                                }
                                                            }
                                                            return <Col span={6} key={element.id}><Checkbox value={element.id} onChange={onCheckboxChange(item.moduleId)}>
                                                                {element.name}</Checkbox></Col>
                                                        })
                                                    }
                                                </Row>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    ),
                                    key: `${elem.groupId}${index}`
                                }]
                            }
                        })
                    }
                })
                groupArr = groupArr.map(item => {
                    return `name${item}`
                })
                groupArr.forEach(item => {
                    form.setFieldsValue({
                        [item]: defaultChecked
                    })
                })
                console.log(groupArr);
                checkboxArr.push(...defaultChecked);
                setAllRole({ checkboxArr: defaultChecked })
                length1.channelLength = channelLen;
                length1.dmsLength = dmsLen;
                length1.userLength = userLen;
                length1.bossLength = bossLen;
                length1.kbLength = kbLen;
                setSaveKey(openkey);
                setOpenkey(openkey);
                setAllPermissions(arr);
            }
            controlSpanStyle()
            document.getElementsByClassName('title-checkbox-length')[0].innerHTML = length1.bossLength
            document.getElementsByClassName('title-checkbox-length')[1].innerHTML = length1.channelLength
            document.getElementsByClassName('title-checkbox-length')[2].innerHTML = length1.dmsLength
            document.getElementsByClassName('title-checkbox-length')[3].innerHTML = length1.userLength
            document.getElementsByClassName('title-checkbox-length')[4].innerHTML = length1.kbLength
        })
    }, []);

    const handleClose = () => {
        navigate('/enterprise/roles')
    }

    return (
        <Fragment>
            <div className="page-title"><Link to="/enterprise/roles" className="back"><LeftOutlined /></Link>角色权限维护</div>

            <Content className="main-content-box-role">


                <Card title="角色信息" size="small" className="role-message-card">
                    <Descriptions column={1} style={{ marginLeft: '60px' }}>
                        <Descriptions.Item label="角色名称">{state.name}</Descriptions.Item>
                        <Descriptions.Item label="角色描述">{state.description}</Descriptions.Item>
                        <Descriptions.Item label="角色状态"><span className={state.disabled === 0 ? "open-status" : "close-status"}>{state.disabled === 0 ? '启用' : '禁用'}</span></Descriptions.Item>
                    </Descriptions>
                </Card>

                <Form form={form} className="role-permission-checkbox-form">
                    <Card size="small" title={<div className="role-permission-title-box">
                        <span className="role-permission-title">选择角色权限</span>
                        <Space className="role-permission-title-button">
                            <Button onClick={handleOpenKeys('open')}>全部展开</Button>
                            <Button onClick={handleOpenKeys('close')}>全部收起</Button>
                        </Space>
                    </div>}>
                        {
                            allPermissions && <Tree
                                blockNode
                                expandedKeys={openKey}
                                treeData={allPermissions}
                                selectable={false}
                                onExpand={handleExpand}
                            />
                        }
                    </Card>
                    <Space className="role-permission-bottom-button">
                        <Button type="default" onClick={handleClose}>取消</Button>
                        <Button type="primary" htmlType="submit" onClick={handleClickSubmit}>提交</Button>
                    </Space>
                </Form>


            </Content>



        </Fragment>
    )
}
