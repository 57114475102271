import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    Layout,
    Button,
    List,
    Modal,
    message
} from 'antd';
import {
    LeftOutlined,
    createFromIconfontCN,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import moment from 'moment';
import { regRichText } from "@utils/utils";
import NoticeDetails from "./components/NoticeDetails";
import NoticeAddDrawer from './components/NoticeAddDrawer';
import { noticesListApi } from '@services/MedProject';
import "./index.less";
import {showMedProject, delNoticesInfoApi} from '@services/MedProject';


const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
})

const { Content } = Layout;

export default function RelatedProjectList() {
    const navigate = useNavigate();
    const params = useParams();

    const medProjectId = params?.id || 0; // 项目 ID

    const [project, setProject] = useState(null);

    const getProject = async id => {
        const res = await showMedProject(id);
        if (res.status_code === 200) {
            const project = res.data;
            setProject(project);
        }
    };

    useEffect(() => {
        if (!!medProjectId) {
            getProject(medProjectId)
        }
    }, []);


    // 分页
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };


    // 新增/详情
    const [currentId, setCurrentId] = useState(undefined);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // 通知详情
    const details = (item) => {
        setCurrentId(item)
        setDetailsOpen(true);
    };

    // 删除
    const del = (delId) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `删除后，用户端也会同时删除，是否确认删除？`,
            centered: true,
            onOk: async () => {
                const res = await delNoticesInfoApi({id: delId});
                if (res.status_code === 200) {
                    message.success('删除成功');
                    getNotices();
                }
            }
        });

    };

    // 发布通知弹窗
    const release = () => {
        setIsModalOpen(true);
    };

    const getNotices = async () => {
        setLoading(true);
        const reqParams = {
            medProjectId,
            page,
            pageSize
        };
        const res = await noticesListApi(reqParams);
        setLoading(false);
        if (res.status_code === 200 && res.data) {
            let noticeData = res.data.list || [];
            noticeData.length>0 && noticeData.map(item=>{
                // item.createdAt = item.createdAt ? moment(item.createdAt).format('YYYY年MM月DD日 HH:mm:ss') : null;
                item.content = regRichText(item.content);
            });
            setData(noticeData);
            setTotal(res.data.totalCount);
        }
    };


    useEffect(() => {
        if (!!medProjectId) {
            getNotices();
        }
    }, [page, pageSize, medProjectId]);


    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">通知管理</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">

                <div className="main-content-body notice-body">

                    <div className="main-tools">
                        <Button type="primary" onClick={()=>release()}>发布通知</Button>
                    </div>

                    <div className='main-notices'>
                        <List
                            className="notice-list"
                            itemLayout="vertical"
                            dataSource={data}
                            loading={loading}
                            pagination={{
                                total: total,
                                showTotal: () => `共${total}条`,
                                current: page,
                                pageSize: pageSize,
                                onChange: onChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                            rowKey={(row) => row.id}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                    actions={[
                                        <div>
                                            <IconFont
                                                type="icon-shijian"
                                                text="156"
                                            />
                                            <span> {item.createdAt}</span>
                                        </div>
                                    ]}
                                    extra={
                                        <div className="operationArea">
                                            <Button
                                                type="link"
                                                className="operateButton"
                                                onClick={() => details(item.id)}
                                            >
                                                详情
                                            </Button>
                                            <Button
                                                type="link"
                                                className="operateButton"
                                                onClick={() => del(item.id)}
                                            >
                                                删除
                                            </Button>
                                        </div>
                                    }
                                >
                                    <List.Item.Meta title={item.title} />
                                    <div className="notice-content">
                                        <span>{item.content}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>

                    <NoticeAddDrawer isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getNotices={getNotices}/>
                    <NoticeDetails detailsOpen={detailsOpen} setDetailsOpen={setDetailsOpen} currentId={currentId}/>
                </div>

            </Content>

        </>
    );
}
