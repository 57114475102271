import React, { Fragment, useState, useEffect, useRef, useImperativeHandle, forwardRef, useMemo, useCallback } from "react";
import { Button, Input, message } from "antd";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { timesaveTemplateQuestionApi } from "@services/Wj/Template";
import { timesaveSurveryQuestionApi } from "@services/Wj/Question";
import './index.less';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {regRichText} from '@utils/utils'
const Morecloze = ({ questionId, item, setAnserArea, anserArea, start, end }, ref) => {
    const [clozeTitle, setClozeTitle] = useState();
    const [questionIndex, setQuestionIndex] = useState();
    const [titleBorder, setTitleBorder] = useState(true);
    const [titleIn, setTitleIn] = useState(false)
    const [oldValue, setOldValue] = useState(undefined)
    const quillRef = useRef(null)
    // 光标选中位置
    const [seleteStart, setSeleteStart] = useState(0)
    const [seleteEnd, setSeleteEnd] = useState(0)
    const { TextArea } = Input;
    const location = useLocation();

    const textRef = useRef(null)

    useImperativeHandle(ref, () => ({
        seleteStart,
        seleteEnd
    }))

    const CustomButton = () => <span className="iconfont">插入填空符</span>

    const CustomToolbar = useCallback(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        return ( <div id={`toolbar${index}`}  className="more-toolbar" style={{
            left: item.required === 1 ? '53px' : '44px',
        }}>
            <button className="ql-bold"></button>
            <button className="ql-underline"></button>
            <select
                className="ql-color"
                defaultValue={[]}
                onChange={(e) => e.persist()}
            >
            </select>
            <button className="ql-insertStar ">
            <CustomButton />
            </button>
        </div>)

    }, [])

    const fillBlank = () => {
        let quill = quillRef?.current?.getEditor(); //获取到编辑器本身
        const cursorPosition = quill.getSelection().index; //获取当前光标位置
        console.log(cursorPosition);
        let position = cursorPosition ? cursorPosition : 0;
        quill.insertText(position, '______');
        quill.setSelection(cursorPosition + 6); //光标新位置
    }
    const modules = useMemo(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        return {
            toolbar: {
                container: `#toolbar${index}`,
                handlers: {
                    insertStar: fillBlank,
                },
            },
        };
    }, []);
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
        let c=document.querySelectorAll('.ql-color')
        ;
        setTimeout(() => {
            c.forEach(item=>{
                item.onmousedown=(e)=>{
                    e.preventDefault();
            }
            })
        }, 1000);
        setOldValue(item.body)

    }, []);

    const onTextSelete = (e) => {
        setSeleteStart(e.target.selectionStart)
        setSeleteEnd(e.target.selectionEnd)
    }

    const addHandle = (e) => {
        e.preventDefault();
        let final;
        textRef.current?.focus()
        let value = clozeTitle || ''
        if (value.length + 6 > 200) return
        let arr = value.split('')
        arr.splice(seleteStart, seleteEnd - seleteStart, '______')
        final = arr.join('')
        setClozeTitle(final);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: final,
        };
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res) => {
                if (res.status_code === 200) {
                    message.success('保存成功')
                }
            }))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res) => {
                if (res.status_code === 200) {
                    message.success('保存成功')
                }
            }))
        }
        setAnserArea(resArr);
    }

    const changeTitle = (e,i) => {
        let a=document.querySelectorAll('.ql-container')
       a[questionIndex].removeAttribute('style')
        let b=document.querySelectorAll('.ql-toolbar')
        b[questionIndex].removeAttribute('style')
        item.body = quillRef.current.value;
        setTitleBorder(false);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: quillRef.current.value,
        };
        console.log(quillRef.current.value);
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){
                message.success('保存成功')
            }}))
        }
        // setAnserArea(resArr);
    };
    // const
    const getBorder = (index) => {
        let a = document.querySelectorAll('.ql-container')
        a[index].style.border = '1px solid #1a73e8'
        let b = document.querySelectorAll('.ql-toolbar')
        console.log();
        console.log(b[index - 1]);
        b[index].style.display = 'block'
        setTitleBorder(true);
        setTitleIn(true)
    };


    useEffect(() => {
        setSeleteStart(start)
        setSeleteEnd(end)
    }, []);
    return (
        <Fragment>
            <div name={questionId} onMouseDown={e=>e.stopPropagation()}>

                <div className="question-title">
                    <div className="question-id">
                        <span
                            className="required"
                            style={{
                                display: item.required === 1 ? "" : "none",
                            }}
                        >
                            *
                        </span>
                        <b className="question-index">{questionIndex}.</b>
                    </div>

                    <CustomToolbar />
                    <ReactQuill theme="snow" ref={quillRef}
                        modules={modules}
                        placeholder="请输入问题"
                        className="question-title-area"
                        onFocus={() => getBorder(questionIndex)}
                        defaultValue={item.body}
                        onBlur={() => changeTitle(questionIndex)}
                    />

                </div>
            </div>
        </Fragment>
    );
};

export default forwardRef(Morecloze);
