import React, { useState, useEffect, useRef } from 'react';
import { LeftOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { Layout, Table, Button, Input, Modal, Form, Radio, message, Space, Typography } from "antd";
import './SignInSetting.less';
import { signInSettingsApi, signInSettingInfoApi, updateSignInSettingsApi, addSignInPersonApi, editSignInPersonApi, deleteSignInPersonApi } from '@services/Conference/signIn';

const { Content } = Layout;
const { Text } = Typography;

const formItemLayout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 14,
    },
};

// 签到管理 - 签到设置
const SignInSetting = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const settingsInfo = useRef(null);

    const [signInSettingForm] = Form.useForm();
    const [signInAddForm] = Form.useForm();
    const currentEditId = useRef(null);

    const columns = [
        {
            title: "姓名",
            width: 100,
            dataIndex: "name",
        },
        {
            title: "手机号码",
            width: 150,
            dataIndex: "phone",
        },
        {
            title: "人员类型",
            width: 120,
            dataIndex: "type",
            render: (_, row) => (
                <>
                    {row.type === 2 ? '内部员工' : <Text type="danger">外部人员</Text>}
                </>
            ),
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            width: 100,
            render: (_, row) => (
                <Space>
                    <Button type='link' style={{padding: 0}} onClick={() => handleEdit(row)}>编辑</Button>
                    <Button type='link' style={{padding: 0}} onClick={() => handleDelete(row.id)}>删除</Button>
                </Space>
            ),
        },
    ];

    // 删除
    const handleDelete = (delId) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否删除该工作人员`,
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: async() => {
                const res = await deleteSignInPersonApi({ id: delId });
                if (res && res.status_code === 200) {
                    message.success('删除成功');
                    getSettingInfo();
                }
            }
        });
    };

    // 编辑人员
    const handleEdit = async(row) => {
        currentEditId.current = row.id;
        signInAddForm.setFieldsValue({
            name: row.name,
            phone: row.phone,
            type: row.type
        });
        setIsModalOpen(true);
    };

    // 保存
    const save = () => {
        signInSettingForm.validateFields().then(async (values) => {
            const res = await updateSignInSettingsApi({
                ...values,
                id: settingsInfo.current?.id,
                personsList: settingsInfo.current?.personsList,
                conferenceId: params.id
            });
            if (res && res.status_code === 200) {
                message.success('保存成功');
                navigate(`/conf/conferences/${params.id}/signup?type=3`);
            }
        });
    };

    // 取消
    const cancel = () => { navigate(`/conf/conferences/${params.id}/signup?type=3`) };

    // 新增签到人员
    const handleOk = () => {
        signInAddForm.validateFields().then(async (values) => {
            const reqApi = currentEditId.current ? editSignInPersonApi : addSignInPersonApi;
            const res = await reqApi({
                ...values,
                signSettingId: location.state?.id,
                conferenceId: params.id,
                id: currentEditId.current || undefined
            });
            if (res && res.status_code === 200) {
                message.success(`${currentEditId.current ? '编辑成功' : '保存成功'}`);
                getSettingInfo();
                setIsModalOpen(false);
                signInAddForm.resetFields();
            }
        });
    };

    // 添加人员
    const addPerson = () => {
        currentEditId.current = null;
        setIsModalOpen(true);
    };

    // 关闭签到弹框
    const handleCancel = () => {
        setIsModalOpen(false);
        signInAddForm.resetFields();
    };

    // 获取设置信息
    const getSettingInfo = async () => {
        const res = await signInSettingInfoApi({ id: location.state?.id });
        if (res && res.status_code === 200) {
            const info = res.data;
            signInSettingForm.setFieldsValue({ ...info });
            settingsInfo.current = info;
            setData(info.personsList || []);
        }
    };

    useEffect(() => {
        getSettingInfo();
    }, []);

    return (
        <>
            <div className="page-title">
                <Link to={`/conf/conferences/${params.id}/signup?type=3`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">签到设置</span>
                <span className="current-subtitle">{location.state?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="signin-setting">
                        <h3>签到方式</h3>
                        <div className="setting-type">
                            <span className="setting-type-title">当前签到方式：{settingsInfo.current?.type === 1 ? '工作人员核验签到' : '用户自主签到'}</span>
                            <p className="setting-type-desc">建议：<br/>1. “线上会、无实物权益发放的会”，选择“<span className='setting-type-desc-red'>用户自主签到</span>”方式，不用工作人员扫码核验。<br/>2. “线下会、有实物权益发放的会”，选择“<span className='setting-type-desc-red'>工作人员核验签到</span>”方式，由工作人员扫码核验。
                            </p>
                        </div>
                        <h3>签到信息配置</h3>
                        <div className="setting-info">
                            {/* inletEnabled: 1, sceneEnabled: 1, */}
                            <Form
                                name="signInSettingForm"
                                form={signInSettingForm}
                                size="large"
                                autoComplete="off"
                                initialValues={{ equityEnabled: 1, authEnabled: 1 }}
                            >
                                {/* <Form.Item name="inletEnabled" label="会议入口" extra="展示后，用户能够看到会议入口">
                                    <Radio.Group>
                                        <Radio value={1}>展示</Radio>
                                        <Radio value={0}>不展示</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        return getFieldValue('inletEnabled') === 1 && (
                                            <>
                                                <Form.Item name="sceneEnabled" label="会议入口展示场景">
                                                    <Radio.Group>
                                                        <Radio value={1}>全部</Radio>
                                                        <Radio value={2}>签到前</Radio>
                                                        <Radio value={3}>签到后</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </>
                                        );
                                    }}
                                </Form.Item> */}

                                <Form.Item name="equityEnabled" label="用户权益" extra="展示后，用户能够看到自己的权益是什么">
                                    <Radio.Group>
                                        <Radio value={1}>展示</Radio>
                                        <Radio value={0}>不展示</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item name="authEnabled" label="认证" extra="开启后，报名的未填写信息/未认证用户需要认证，VIP嘉宾除外">
                                    <Radio.Group>
                                        <Radio value={1}>开启</Radio>
                                        <Radio value={0}>关闭</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Form>
                        </div>
                        {
                            settingsInfo.current?.type === 1 && (
                                <>
                                    <h3>签到权益发放工作人员</h3>
                                    <Button type="primary" ghost icon={<PlusOutlined />} onClick={addPerson}>添加人员</Button>

                                    <Table
                                        className="setting-table"
                                        size="small"
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                        rowKey="id"
                                    />



                                    <Modal title={currentEditId.current ? '编辑人员' : '添加人员'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                        <Form
                                            name="signInAddForm"
                                            form={signInAddForm}
                                            size="large"
                                            autoComplete="off"
                                            initialValues={{ type: 2 }}
                                        >
                                            <Form.Item name="type" label="人员类型" rules={[{ required: true }]}>
                                                <Radio.Group>
                                                    <Radio value={2}>内部员工</Radio>
                                                    <Radio value={3}>外部人员</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            <Form.Item name="name" label="人员名称" rules={[{ required: true, message: '请填写人员名称' }]}>
                                                <Input placeholder="请输入人员名称" maxLength={100} disabled={currentEditId.current}/>
                                            </Form.Item>
                                            <Form.Item
                                                name="phone"
                                                label="手机号码"
                                                rules={[
                                                    { required: true, message: '请填写手机号码' },
                                                    { pattern: /^1\d{10}$/, message: '请输入正确的手机号码' }
                                                ]}
                                            >
                                                <Input placeholder="请输入手机号码" maxLength={11} disabled={currentEditId.current}/>
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                </>
                            )
                        }
                        <div className="setting-footer">
                            <Button type='primary' onClick={save}>保存</Button>
                            <Button onClick={cancel}>取消</Button>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default SignInSetting;
