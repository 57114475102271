import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, DatePicker, Button, Space, Cascader } from 'antd';
const { RangePicker } = DatePicker;

export default function LiveFilterForm({
    productId, orgId, liveCatesId, handleFilterFinish
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    }

    const onFinish = (values) => {
        values.createdBeginAt = timePicker[0];
        values.createdEndAt = timePicker[1];
        delete values.timePicker;
        handleFilterFinish(values)
    };


    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            createdBeginAt: undefined,
            createdEndAt: undefined,
            liveStatus: undefined,
            pubStatus: undefined,
            productId: undefined,
            orgId: undefined,
            cateId: undefined,
            verifyStatus: undefined
        };
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播标题" name="name">
                        <Input allowClear placeholder="请输入直播标题" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="创建" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播状态" name="liveStatus">
                        <Select allowClear placeholder="请选择直播状态">
                            <Select.Option value={0}>直播预告</Select.Option>
                            <Select.Option value={1}>直播中</Select.Option>
                            <Select.Option value={2}>直播结束</Select.Option>
                            <Select.Option value={3}>直播回放</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="上架状态" name="pubStatus">
                        <Select allowClear placeholder="请选择上架状态">
                            <Select.Option value={0}>上架</Select.Option>
                            <Select.Option value={1}>下架</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线" name="productId">
                        <Select allowClear placeholder="请选择产品线">
                            {productId && productId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="直播分类" name="cateIdArr">
                        <Cascader options={liveCatesId} placeholder="请选择直播分类" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="审核状态" name="verifyStatus">
                        <Select allowClear placeholder="请选择审核状态">
                            <Select.Option value={0}>审核通过</Select.Option>
                            <Select.Option value={1}>审核拒绝</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}
