import React, { Fragment, useCallback, useState, useEffect } from 'react';
import ResourceDocsFilterForm from './ResourceDocsFilterForm';
import { Layout, Table, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import { get, del } from '@/core/request';

const { Content } = Layout;

export default function ResourceDocs() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 产品线
    const [productIds, setProductId] = useState([]);
    // 所属企业
    const [orgIds, setOrgId] = useState([]);
    // 操作人
    const [updatedByStaffId, setUpdatedByStaffId] = useState([]);

    // 操作人
    useEffect(() => {
        get('/dms/users/findAll').then((res) => {
            setUpdatedByStaffId(res.data);
        })
    }, []);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setProductId(res.data);
        })
    }, []);

    // 所属企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { fileName, orgId, productId, uploadName, startTime, endTime } = filterObj;

    const getData = () => {
        get(`/dms/resource/docs`, {
            limit: pageSize,
            page: currPage,
            fileName: fileName,
            productId: productId,
            orgId: orgId,
            uploadName: uploadName,
            startTime: startTime,
            endTime: endTime
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }

        });
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, fileName, productId, orgId, uploadName, startTime, endTime]);


    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };


    // 预览
    const handlePreview = (row) => {
        return () => {
            get(`/dms/resource/docs/query`, {
                id: row.id
            }).then(res => {
                if (res.status_code === 200) {
                    if (res.data.ext === '.pdf' || res.data.ext === '.jpg' || res.data.ext === '.jpeg' || res.data.ext === '.png') {
                        let a = document.createElement('a');
                        a.target = '_blank';
                        let event = new MouseEvent('click');
                        a.href = res.data.aliFileUrl;
                        a.dispatchEvent(event);
                    } else {
                        let routeUrl = res.data.aliFileUrl;
                        let url = encodeURIComponent(routeUrl);
                        let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + url;
                        window.open(officeUrl, '_target');
                    }
                }
            })
        }
    };

    //下载
    const handleDownload = (row) => {
        return () => {
            get(`/dms/resource/docs/query`, {
                id: row.id
            }).then(res => {
                if (res.status_code === 200) {
                    if (res.data.ext === '.pdf') {
                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.download = res.data.name;
                        let event = new MouseEvent('click');
                        a.href = res.data.aliFileUrl;
                        a.dispatchEvent(event);
                    } else if (res.data.ext === '.jpg' || res.data.ext === '.jpeg' || res.data.ext === '.png') {
                        var image = new Image()
                        // 解决跨域 Canvas 污染问题
                        image.setAttribute('crossOrigin', 'anonymous')
                        image.crossOrigin='Anonymous'
                        image.onload = function () {
                            var canvas = document.createElement('canvas')
                            canvas.width = image.width
                            canvas.height = image.height
                            var context = canvas.getContext('2d')
                            context.drawImage(image, 0, 0, image.width, image.height)
                            var url = canvas.toDataURL('image/png')
                            // 生成一个a元素
                            var a = document.createElement('a')
                            // 创建一个单击事件
                            var event = new MouseEvent('click')
                            // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
                            a.download = res.data.name || 'one' // one是默认的名称
                            // 将生成的URL设置为a.href属性
                            a.href = url
                            // 触发a的单击事件
                            a.dispatchEvent(event)
                        }
                        image.src = res.data.aliFileUrl
                    } else {
                        message.success(res.message);
                        let a = document.createElement('a');
                        a.download = row.name;
                        let event = new MouseEvent('click');
                        a.href = res.data.aliFileUrl;
                        a.dispatchEvent(event);
                    }
                }
            })
        }
    };

    // 点击删除
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除文档',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    del('/dms/resource/docs', {
                        ids: row.id
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    };

    // table 数据相关
    const columns = [
        { title: '文档ID', dataIndex: 'id', },
        { title: '文档名称', dataIndex: 'name', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '上传人', dataIndex: 'staffName', },
        { title: '联系方式', dataIndex: 'phone', },
        { title: '关联直播', dataIndex: 'liveName', },
        { title: '上传时间', dataIndex: 'createdAt', },
        { title: '分类标签', dataIndex: 'cateName', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handlePreview(row)}>预览</span>
                    <span className="operate-style" onClick={handleDownload(row)}>下载</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">文档管理</div>

            <canvas hidden={true} id="canvas" style={{ width: 300, height: 300 }} />

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ResourceDocsFilterForm
                        handleFilterFinish={handleFilterFinish}
                        productId={productIds}
                        updatedByStaffId={updatedByStaffId}
                        orgId={orgIds}
                    />
                </div>

                <div className="main-content-body">

                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

        </Fragment >
    )
}
