import React,{useCallback} from 'react';
import { Table } from 'antd';
import { useNavigate} from 'react-router-dom';

export default function AnswerTable({
    answerData
}) {
    const navigate = useNavigate();
    // 改变一页有多少数据

    const changeCurrPage = useCallback(
        (page) => {
            changeCurrPage(page)
        },
        [],
    )

    const paginationProps = {
        showTotal: () => `共${answerData.totalCount}人答题`,
        size: 'small',
        total:answerData.totalCount,
        onChange: (page)=>{changeCurrPage(page)}
    };

    const changePath = useCallback(
        (row) => {
            return ()=>{
                navigate(`/dms/surveys/:id/answer/${row.id}`,{state:row})
            }
        },
        [navigate],
    )

    const columns = [
        { title: '姓名', dataIndex: 'name' },
        { title: '手机号', dataIndex: 'phone', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '提交时间', dataIndex: 'createdAt', },
        {
            title: '操作', dataIndex: 'operate',
            render: (operate,row) => (<span className="operate-style" onClick={changePath(row)}>查看</span>)
        },
    ];

    return (
        <Table className="answer-table" columns={columns} dataSource={answerData.list} pagination={paginationProps}/>
    )
}
