import axios from 'axios';

class HttpClient {
    async get(url, params, options) {
        let urlStr = url + "?";
        for (let i in params) {
            if (params[i]) {
                urlStr += `${i}=${params[i]}&`
            }
        }
        urlStr = urlStr.slice(0, urlStr.length - 1);
        try {
            return await axios.get(urlStr, options);
        } catch (err) {
            return err;
        }
    }

    async post(url, data, options) {
        try {
            return await axios.post(url, data, options);
        } catch (err) {
            return err;
        }
    }

    async put(url, data, options) {
        try {
            return await axios.put(url, data, options);
        } catch (err) {
            return err;
        }
    }

    async patch(url, data, options) {
        try {
            return await axios.patch(url, data, options);
        } catch (err) {
            return err;
        }
    }

    async delete(url, data, options) {
        try {
            return await axios.delete(url, data, options);
        } catch (err) {
            return err;
        }
    }
}

export default new HttpClient();
