import React, { Fragment, useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {ExclamationCircleOutlined, LeftOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table, Tooltip} from "antd";
import {Content} from "antd/lib/layout/layout";
import {feeBatchStatusList} from "../constants";
import {dateTimeFormat} from '@utils/utils';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {getFeeBatchList, updateFeeBatchState, handleExportData} from '@services/MedConnect';
import LabfeeModal from "./LabfeeModal";
import {useSelector} from "react-redux";

const { RangePicker } = DatePicker;

export default function MedConnectLabfeeBatches() {
    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const projectName = useSelector((state) => state.medconnect.projectName);

    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});

    const [labfeeVisible, setLabfeeVisible] = useState(false); // 劳务付款批次弹窗

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const getLabfeeList = async () => {
        try {
            setLoading(true);
            const params = {
                ...filter,
                projectId: id,
            }
            getFeeBatchList(params).then(res => {
                if (res && res.success) {
                    setTableData(res.data);
                    setTotal(res.totalCount);
                }
            })
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        }
    }

    // 检索
    const handleFilterFinish = (values) => {
        setFilter({
            ...filter,
            batchNo: values.batchNo,
            createdBy: values.createdBy,
            state: values.state,
            createdAtFrom: values.timeRange ? dateTimeFormat(values.timeRange[0], 'YYYY-MM-DD 00:00:00') : '',
            createdAtEnd: values.timeRange ? dateTimeFormat(values.timeRange[1], 'YYYY-MM-DD 23:59:59') : '',
            pageIndex: 1,
        })
    }

    // 重置
    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            createdAtFrom: '',
            createdAtEnd: '',
            pageIndex: 1,
        });
    }

    // 刷新
    const onRefresh = () => getLabfeeList();

    // 生成劳务付款批次
    const createLabfee = () => {
        setLabfeeVisible(true);
    }

    // 关闭劳务付款弹窗
    const closeLabfee = () => {
        setLabfeeVisible(false);
        onRefresh();
    }

    // 标记为失效
    const handleMarkExpire = (record) => {
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否更改为失效状态？`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                const params = {
                    id: record.id,
                    state: 3, // 更新为失效状态
                }
                updateFeeBatchState(params).then(res => {
                    if (res && res.success) {
                        message.success(res.data);
                        onRefresh();
                    }
                })
            }
        });
    }

    // 标记已付款
    const handleMarkPay = (record) => {
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否更改付款状态，更改后该批次的问答明细劳务状态更改为已付款状态，请确认知否已经线下打款。`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                const params = {
                    id: record.id,
                    state: 2, // 更新为已付款状态
                }
                updateFeeBatchState(params).then(res => {
                    if (res && res.success) {
                        message.success(res.data);
                        onRefresh();
                    }
                })
            }
        });
    }

    // 导出
    const exportList = (id) => {
        handleExportData({id}).then(res => {
            if (res.success) {
                message.success('请稍后，数据正在导出');
                const fileName = "批次导出.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                a.href = res.data;
                a.dispatchEvent(event);
            }
        })
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        { title: '劳务付款批次号', dataIndex: 'batchNo', align: 'center' },
        { title: '付款人数', dataIndex: 'userNum', align: 'center' },
        { title: '创建人', dataIndex: 'createUserName', align: 'center' },
        { title: '创建时间', dataIndex: 'createdAt', align: 'center' },
        { title: '劳务状态', dataIndex: 'stateDesc', align: 'center' },
        {
            title: '操作',
            align: 'center',
            dataIndex: 'operations',
            width: 240,
            render: (_, record) => {

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => navigate(`/medconnect/projects/${id}/labfee/batches/${record.id}/batch?batchNum=${record.batchNo}`)}
                        >
                            查看
                        </Button>
                        {
                            record.state === 1 ?
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        handleMarkExpire(record)
                                    }}
                                >
                                    失效
                                </Button> : null
                        }
                        {
                            record.state === 1 ?
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => handleMarkPay(record)}
                                >
                                    标记已付款
                                </Button> : null
                        }
                        {/*<Button*/}
                        {/*    size="small"*/}
                        {/*    type="link"*/}
                        {/*    onClick={() => {exportList(record.id)}}*/}
                        {/*>*/}
                        {/*    导出*/}
                        {/*</Button>*/}
                    </span>
                );
            }
        },
    ];

    useEffect(() => {
        getLabfeeList();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">劳务管理</span>
                <span className="current-subtitle">{projectName}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="任务批次号" name="batchNo">
                                    <Input allowClear placeholder="请输入任务批次号" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="创建人" name="createdBy">
                                    <Input allowClear placeholder="请输入创建人" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="创建时间段" name="timeRange">
                                    <RangePicker/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="劳务状态" name="state">
                                    <Select allowClear placeholder="全部">
                                        {feeBatchStatusList && feeBatchStatusList.map(item => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={createLabfee}>生成劳务付款批次</Button>
                        </div>

                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        onClick={onRefresh}
                                        icon={<>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                                </svg>
                                            </span>
                                        </>}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>


            </Content>

            {/* 生成劳务批次弹窗 */}
            {labfeeVisible && <LabfeeModal isOpen={labfeeVisible} handleClose={closeLabfee} />}

        </Fragment>
    )
}

