import React, { useCallback } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import './SurveysData.less'

export default function ShortAnswer({
    answerData, setCurrPage
}) {

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [setCurrPage],
    );

    const paginationProps = {
        size: 'small',
        onChange: (page) => { changeCurrPage(page) }
    };

    // 点击跳转到简答题详情
    const navigate = useNavigate();
    const changePath = (row)=>{
        return ()=>{
            navigate(`/dms/surveys/${row.id}/short`,{state:row});
        }
    }

    const columns = [
        { title: '题号', dataIndex: 'id', key: 'id' },
        { title: '题目', dataIndex: 'name', },
        {
            title: '答题详情', dataIndex: 'operate',
            render: (operate, row) => (<span className="operate-style" onClick={changePath(row)}>查看</span>)
        },
    ];

    return (
        <Table className="answer-table"
            columns={columns}
            dataSource={answerData.list}
            pagination={false}
            pagination={paginationProps}
        />
    )
}
