import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Layout, Alert, Button, Table, Tag, Typography, message, Input, Modal, Form, Row, Col, List, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { convertDateTimeRangeToLocalText, convertDateTimeToLocalText, dateTimeFormatHMS, numberProject } from '@utils/utils';
import html2canvas from "html2canvas";
import { laborFeeCostBearers, laborFeePaymentMethods, laborFee3rdPartyPaymentMethods } from '@utils/constants';
import { conferenceOnceApi } from '@services/Conference';
import { fetchLaborFeeExaminationList, examineLaborFee, fetchLaborFeeExaminationRefuseReason, fetchLaborFeeExaminationResult } from '@services/Finance/FinanceLaborFee.service.js';
import './FinanceLaborFeeProject.less';
import moment from 'moment';
import { localStorageGet } from '@core/cookie';
import { resetLaborFeeWithTax } from '../../../services/Finance/FinanceLaborFee.service';

const { Content } = Layout;
const { Text } = Typography;
const { TextArea } = Input;
export default function FinanceLaborFeeProject() {
    const params = useParams();

    // 接收项目类型-项目ID路由参数
    const projectCodeAndId = params.project || '';
    let projectCode = '';
    let projectId = 0;
    if (!!projectCodeAndId && /[a-z]+-\d+/i.test(projectCodeAndId)) {
        [projectCode, projectId] = projectCodeAndId.split('-');
        projectCode = projectCode.toUpperCase() || '';
        projectId = parseInt(projectId, 10) || 0;
    }

    const [conference, setConference] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [statisticalAmount, setStatisticalAmount] = useState({});

    const [laborfeeStatus, setLaborfeeStatus] = useState(0); // 0:待审核 1:通过 2:驳回
    const [laborfeeRefuseModal, setLaborfeeRefuseModal] = useState(false);
    const [laborfeeRefuseReasons, setLaborfeeRefuseReasons] = useState([]); // 驳回原因
    const [laborfeeRefuseForm] = Form.useForm();

    const [laborFeeResetting, setLaborFeeResetting] = useState(false);

    const [openPaymentRecord, setOpenPaymentRecord] = useState(false);
    const [paymentRecord, setPaymentRecord] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [visibleLaborFeeConfirmationModal, setVisibleLaborFeeConfirmationModal] = useState(false);

    const openLaborFeeConfirmationModal = () => {
        setVisibleLaborFeeConfirmationModal(true);
    };

    const closeLaborFeeConfirmationModal = () => {
        setVisibleLaborFeeConfirmationModal(false);
    };

    const disagreeLaborFeeConfirmation = () => {
        getRefuseReasons();
        setLaborfeeRefuseModal(true);
    };

    const agreeLaborFeeConfirmation = async () => {
        const res = await examineLaborFee({ projectId, projectCode, status: 1 });
        if (res && res.status_code === 200) {
            message.success('确认成功');
            setLaborfeeStatus(1);
            setVisibleLaborFeeConfirmationModal(false);
        }
    };

    // 是否拥有财务劳务费管理员权限
    const isLaborFeeManager = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '财务劳务费管理员');
        }
        return false;
    }, []);

    let columns = [
        { title: '姓名', width: 100, dataIndex: 'memUsername', fixed: 'left' },
        { title: '手机号', dataIndex: 'memMobile', width: 120, },
        {
            title: '单位及职务',
            dataIndex: 'unitPost',
            width: 180,
            render: (_, row) => {
                if (row.workExpList && row.workExpList.length) {
                    return <>
                        {row.workExpList.map((item, idx) => {
                            return <div key={`w_${item.id}`}>
                                {row.workExpList.length > 1 && <span>{idx + 1}. </span>}
                                {!!item.unitName && <span>{item.unitName}</span>}
                                {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                {!!item.unitPost && <span>{item.unitPost}</span>}
                            </div>
                        })}
                    </>
                } else {
                    return '—';
                }
            },
        },
        {
            title: '实际日程',
            dataIndex: 'agendasList',
            width: 400,
            render: (_, record) => {
                if (record.agendasList && record.agendasList.length) {
                    return <>
                        <ul className="task-list">
                            {record.agendasList.map((item, idx) => (
                                <li key={`agenda_idx_${idx}`}>
                                    <span style={{ marginRight: 4 }}>{`${item.conferenceHallName} / ${item.title}`}</span>
                                    <Tag color="blue">{item?.taskName}</Tag>
                                    <br/>
                                    <span className="task-time">{convertDateTimeRangeToLocalText(item.startTime, item.endTime, 0)}</span>
                                </li>
                            ))}
                        </ul>
                        <div>
                            {!!conference?.agendaConfirmContactsName
                                ? (record.agendaConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>)
                                : <Text type="secondary">&ndash;</Text>
                            }
                        </div>
                    </>;
                } else {
                    return <>
                        <div><Text type="secondary">未安排任务</Text></div>
                        <div>
                            {!!conference?.agendaConfirmContactsName
                                ? (record.agendaConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>)
                                : <Text type="secondary">&ndash;</Text>
                            }
                        </div>
                    </>;
                }
            },
        },
        {
            title: '实发劳务费(元)',
            dataIndex: 'laborFeeAmount',
            width: 130,
            editable: !!conference?.laborFeeConfirmedAt ? false : true,
            render: (_, record) => record.laborFeeAmount !== null
                ? <span>&yen;{`${formatCurrency(record.laborFeeAmount)}`}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款方式',
            dataIndex: 'paymentMethod',
            width: 150,
            editable: true,
            align: 'center',
            render: (_, record) => {
                const cur = laborFeePaymentMethods.find(m => m.value === record.paymentMethod);
                if (cur) {
                    if (cur.value === 3) {
                        return <Text type="warning">{cur?.label}</Text>;
                    }
                    return cur?.label;
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 140,
            editable: true,
            render: (_, record) => laborFeeCostBearers.find(b => b.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>
        },
        {
            title: '相关税费(元)',
            dataIndex: 'individualTaxAmount',
            width: 120,
            align: 'right',
            render: (_, record) => {
                // if (!!record.laborFeeAmount && !!record.paymentMethod) {
                return !!record.individualTaxAmount
                    ? <span>&yen;{`${formatCurrency(record.individualTaxAmount)}`}</span>
                    : <span>&yen;0.00</span>
                // }
                // return <Text type="secondary">&ndash;</Text>
            }
        },
        {
            title: '含税劳务费(元)',
            dataIndex: 'sumOfLaborFeeCast',
            width: 130,
            align: 'right',
            render: (_, record) => {
                // if (!!record.laborFeeAmount) {
                let laborFeeAmount = parseFloat(record.laborFeeAmount) || 0.00;
                let individualTaxAmount = parseFloat(record.individualTaxAmount) || 0.00;

                return <span>&yen;{`${formatCurrency(laborFeeAmount + individualTaxAmount)}`}</span>;
                // }
                // return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '收款信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {
                    (record.idcardType === 1 || !record.idcardType) && <>
                        <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                        <div>
                            {!!conference?.agendaConfirmContactsName
                                ? (record.laborInfoConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>)
                                : <Text type="secondary">&ndash;</Text>}
                        </div>
                    </>
                }
                {record.idcardType > 1 && <>
                    <span>证件类型：{getIdcardType(record.idcardType) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>国籍：{record.nationality || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生日期：{(!!record.birthday && moment(record.birthday).format('YYYY-MM-DD')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    <div>
                        {!!conference?.agendaConfirmContactsName
                            ? (record.laborInfoConfirmed ? <Text type="success">已确认</Text> : <Text type="secondary">未反馈</Text>)
                            : <Text type="secondary">&ndash;</Text>}
                    </div>
                </>
                }
            </>),
        }
    ];

    let modalColumns = [
        { title: '专家姓名', width: 100, dataIndex: 'memUsername', fixed: 'left' },
        { title: '手机号', dataIndex: 'memMobile', width: 120, },
        {
            title: '单位任职',
            dataIndex: 'unitPost',
            width: 180,
            render: (_, row) => {
                if (row.workExpList && row.workExpList.length) {
                    return <>
                        {row.workExpList.map((item, idx) => {
                            return <div key={`w_${item.id}`}>
                                {row.workExpList.length > 1 && <span>{idx + 1}. </span>}
                                {!!item.unitName && <span>{item.unitName}</span>}
                                {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                {!!item.unitPost && <span>{item.unitPost}</span>}
                            </div>
                        })}
                    </>
                } else {
                    return '—';
                }
            },
        },
        {
            title: '税后劳务费(元)',
            dataIndex: 'laborFeeAmount',
            width: 120,
            render: (_, record) => record.laborFeeAmount !== null
                ? <span>&yen;{`${formatCurrency(record.laborFeeAmount)}`}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款方式',
            dataIndex: 'paymentMethod',
            width: 150,
            render: (_, record) => {
                if (record.paymentMethod !== record.paymentMethodAdjust) {
                    const paymentMethodName = laborFeePaymentMethods.find(m => m.value === record.paymentMethod)?.label || <Text type="secondary">&ndash;</Text>;
                    const paymentMethodAdjustName = laborFeePaymentMethods.find(m => m.value === record.paymentMethodAdjust)?.label || <>&ndash;</>;
                    return <>
                        {paymentMethodName} <Text type="danger"> 变更后 {paymentMethodAdjustName}</Text>
                    </>
                } else {
                    return laborFeePaymentMethods.find(m => m.value === record.paymentMethod)?.label || <Text type="secondary">&ndash;</Text>
                }
            }
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 150,
            render: (_, record) => {
                if (record.costBearer !== record.costBearerAdjust) {
                    const costBearerName = laborFeeCostBearers.find(m => m.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>;
                    const costBearerAdjustName = laborFeeCostBearers.find(m => m.value === record.costBearerAdjust)?.label || <>&ndash;</>;
                    return <>
                        {costBearerName} <Text type="danger"> 变更后 {costBearerAdjustName}</Text>
                    </>
                } else {
                    return laborFeeCostBearers.find(b => b.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>
                }
            }
        },
        {
            title: '个人所得税(元)',
            dataIndex: 'individualTaxAmount',
            width: 150,
            render: (_, record) => {
                if (!!record.laborFeeAmount && !!record.paymentMethod) {
                    if (record.individualTaxAmount !== record.individualTaxAmountAdjust) {
                        const individualTaxAmount = !!record.individualTaxAmount
                            ? <Text type="secondary">&yen;{`${formatCurrency(record.individualTaxAmount)}`}</Text>
                            : <Text type="secondary">&yen;0.00</Text>
                        const individualTaxAmountAdjust = !!record.individualTaxAmountAdjust
                            ? <>&yen;{`${formatCurrency(record.individualTaxAmountAdjust)}`}</>
                            : <>&yen;0.00</>
                        return <>{individualTaxAmount} <Text type="danger"> 变更后 {individualTaxAmountAdjust}</Text></>
                    }
                    return !!record.individualTaxAmount
                        ? <span>&yen;{`${formatCurrency(record.individualTaxAmount)}`}</span>
                        : <Text type="danger"> &yen;0.00</Text>
                }
                return <Text type="secondary">&ndash;</Text>
            }
        },
        {
            title: '含税劳务费(元)',
            dataIndex: 'sumOfLaborFeeCast',
            width: 150,
            render: (_, record) => {
                if (!!record.laborFeeAmount || !!record.individualTaxAmountAdjust) {
                    let laborFeeAmount = parseFloat(record.laborFeeAmount) || 0.00;
                    let individualTaxAmount = parseFloat(record.individualTaxAmount) || 0.00;
                    let individualTaxAmountAdjust = parseFloat(record.individualTaxAmountAdjust) || 0.00;

                    return <>&yen;{`${formatCurrency(laborFeeAmount + individualTaxAmount)}`} <Text type="danger"> 变更后 &yen;{`${formatCurrency(laborFeeAmount + individualTaxAmountAdjust)}`} </Text></>;

                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '收款信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {
                    (record.idcardType === 1 || !record.idcardType) && <>
                        <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    </>
                }
                {record.idcardType > 1 && <>
                    <span>证件类型：{getIdcardType(record.idcardType) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>国籍：{record.nationality || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生日期：{(!!record.birthday && moment(record.birthday).format('YYYY-MM-DD')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                </>
                }
            </>),
        },
        {
            title: '修改人',
            dataIndex: 'modifiedName',
            width: 100,
        },
        {
            title: '修改时间',
            dataIndex: 'createdAt',
            width: 120,
            render: (_, record) => (<>
                {record.createdAt || <Text type="secondary">&ndash;</Text>}
            </>),
        },
    ];

    // 获取专家证件类型
    const getIdcardType = (cardType) => {
        const type = parseInt(cardType);
        if (type === 2) return '港澳居民来往内地通行证';
        if (type === 3) return '台湾居民来往大陆通行证';
        if (type === 4) return '外国人永久居留身份证';
        if (type === 5) return '护照';
        return null;
    };

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };

    // 处理金额
    const formatCurrency = value => {
        let result = '';

        const f = parseFloat(value) || 0.00;
        const parts = f.toFixed(2).split('.');
        result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

        return result;
    };

    // 劳务费列表
    const getLaborFeeExaminationList = async () => {
        setLoading(true);
        const res = await fetchLaborFeeExaminationList({
            page,
            pageSize,
            projectCode: projectCode,
            projectId: projectId,
            type: 1
        });
        if (res && res.status_code === 200) {
            setData(res.data?.infos?.list || []);
            setTotal(res.data?.infos?.totalCount || 0);
            setStatisticalAmount(res.data?.totalInfo || {});
            setLaborfeeStatus(res.data?.status);
        }
        setLoading(false);
    };

    // 不同意原因弹窗 提交
    const handleRefuseOk = () => {
        if (laborfeeStatus === 0) { // 待审批
            laborfeeRefuseForm.validateFields()
                .then(async (values) => {
                    let fieldValues = { ...values };
                    const res = await examineLaborFee({ ...fieldValues, projectId, projectCode, status: 2 });
                    if (res && res.status_code === 200) {
                        message.success('审核驳回成功');
                        setLaborfeeStatus(2);
                        setLaborfeeRefuseModal(false);
                        setVisibleLaborFeeConfirmationModal(false);
                        getLaborFeeExaminationList();
                    }
                });
        } else if (laborfeeStatus === 1 && laborFeeResetting) { // 审批通过后重置
            laborfeeRefuseForm.validateFields()
                .then(async (values) => {
                    let fieldValues = { ...values };
                    const res = await resetLaborFeeWithTax({
                        ...fieldValues,
                        projectId,
                        projectCode
                    });
                    if (res && res.status_code === 200) {
                        message.success('审核驳回成功');
                        setLaborfeeStatus(0);
                        laborfeeRefuseForm.resetFields();
                        setLaborfeeRefuseModal(false);
                        setOpenPaymentRecord(false);
                        getLaborFeeExaminationList();
                    }
                });
        } else {
            setLaborfeeRefuseModal(false);
        }
    };

    // 不同意原因弹窗 取消
    const handleRefuseCancel = () => {
        laborfeeRefuseForm.resetFields();
        setLaborfeeRefuseModal(false);
    };

    // 含税劳务费请款审批结果查看
    const laborfeeResultCheck = async () => {
        const res = await fetchLaborFeeExaminationResult({ projectId });
        if (res && res.status_code === 200) {
            setPaymentRecord(res.data || null);
            setOpenPaymentRecord(true);
        }
    };

    // 下载审批结果
    const handleRecordOk = () => {
        setDownloading(true);
        setTimeout(() => {
            formHtmlToDownload("payment_record_wrap", () => setDownloading(false));
        }, 0);
    };

    // 含税劳务费请款审批（重置）
    const onResetLaborFeeWithTax = () => {
        Modal.confirm({
            title: '已复核结果将被作废，并驳回至业务修改，确定重新驳回？',
            width: 480,
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                getRefuseReasons();
                setLaborFeeResetting(true);
                setLaborfeeRefuseModal(true);
            },
            onCancel: () => {
                //
            }
        });
    };

    const formHtmlToDownload = async (domId, callback) => {
        let res = await drawCanvas(domId);
        let linkA = document.createElement("a"); //创建a标签
        linkA.href = res; // 将图片的src赋值给a节点的href
        linkA.download = data.reportTitle || "download"; // 设置a节点的download属性值(设置下载名称)
        let event = new MouseEvent("click"); // 模拟鼠标click点击事件
        linkA.dispatchEvent(event);
        callback();
    };

    const drawCanvas = async (domId) => {
        // 获取想要转换的 DOM 节点
        const dom = document.getElementById(domId);
        // const dom = document.querySelector('printHtml');
        const box = window.getComputedStyle(dom);

        // DOM 节点计算后宽高
        const width = parseValue(box.width);
        const height = parseValue(box.height);
        // 获取像素比-防止模糊
        const scaleBy = DPR();

        console.log(width, height, "======");

        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        // canvas.width = 1334
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        // canvas.style.width = `1334px`;
        canvas.style.height = `${height}px`;
        // 获取画笔
        const context = canvas.getContext("2d");

        // 将所有绘制内容放大像素比倍
        context.scale(1, 1);

        // 将自定义 canvas 作为配置项传入，开始绘制
        return await html2canvas(dom, { canvas, useCORS: true }).then((canvas) => {
            //document.querySelector("#canvasContainer").appendChild(canvas);
            //return canvas.toDataURL("image/png");
            return canvas.toDataURL("image/png");
        });
    };

    const DPR = () => {
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        } else {
            return 1;
        }
    };

    const parseValue = (value) => {
        return parseInt(value, 10);
    };

    // 取消下载审批结果
    const handleRecordCancel = () => {
        setOpenPaymentRecord(false);
    };

    // 含税劳务费请款审批拒绝理由查看
    const laborfeeRejectReason = () => {
        getRefuseReasons();
        setLaborfeeRefuseModal(true);
    };

    // 获取含税劳务费请款审批拒绝理由
    const getRefuseReasons = async () => {
        const res = await fetchLaborFeeExaminationRefuseReason({
            projectId,
            projectCode,
            type: 1,
            status: 2
        });
        if (res.status_code === 200) {
            setLaborfeeRefuseReasons(res.data || []);
        }
    };

    // 获取含税金额
    const getTotalAmount = (LaborFeeSum, IndividualTaxSum) => {
        if (LaborFeeSum !== null && IndividualTaxSum !== null) {
            return (Number(LaborFeeSum) + Number(IndividualTaxSum)).toFixed(2)
        }
    };

    const getTotalNeedLaborFee = () => {
        let totalNeedLaborFee = 0;
        if (statisticalAmount?.realTotal && (statisticalAmount?.LaborFeeNullSum || statisticalAmount?.LaborFeeNullSum === 0) && (statisticalAmount?.LaborFeeZeroSum || statisticalAmount?.LaborFeeZeroSum === 0)) {
            totalNeedLaborFee = statisticalAmount.realTotal - statisticalAmount.LaborFeeNullSum - statisticalAmount.LaborFeeZeroSum;
        }
        return totalNeedLaborFee;
    };

    // 获取会议信息
    const getConferenceInfo = async projectId => {
        const res = await conferenceOnceApi({ id: projectId });
        if (res.status_code === 200 && res.data) {
            setConference(res.data);
        }
    };


    useEffect(() => {
        if (!!projectId) {
            getConferenceInfo(projectId);
        }
    }, []);

    useEffect(() => {
        getLaborFeeExaminationList();
    }, [page, pageSize, projectId]);

    // useEffect(() => {
    //     getAmountStat();
    // }, []);


    return <>
        <div className="page-title">
            <Link to={`/finance/laborfee/projects`} className="back"><LeftOutlined/></Link>
            <span className="current-title">含税劳务费总额审批</span>
            <span className="current-subtitle">{conference?.name}</span>
        </div>

        <Content className="main-content-box">

            <div className="main-content-body finance-labor-fee-project">
                <Alert
                    type="info"
                    style={{ marginBottom: 16, fontSize: 12 }}
                    message={
                        <span style={{ fontSize: 12 }}>实发劳务费总计：&yen;{formatCurrency(statisticalAmount?.LaborFeeSum)}元，相关税费总计：&yen;{formatCurrency(statisticalAmount?.IndividualTaxSum)}元，含税劳务费总计：&yen;{formatCurrency(getTotalAmount(statisticalAmount?.LaborFeeSum, statisticalAmount?.IndividualTaxSum))}元，共{statisticalAmount?.realTotal}位专家</span>
                    }
                />

                <div className="main-toolbar">
                    <div className="main-tools">
                        {isLaborFeeManager && laborfeeStatus === 0 && <Button type="primary" onClick={openLaborFeeConfirmationModal}>劳务费复核</Button>}
                        {laborfeeStatus === 1 && <Button type="primary" onClick={laborfeeResultCheck}>财务审批同意，查看结果</Button>}
                        {laborfeeStatus === 2 && <Button type="primary" onClick={laborfeeRejectReason}>财务审批不同意~查看结果</Button>}
                    </div>
                </div>

                <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ x: 1000 }}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage
                    }}
                />

                <Modal
                    title='不同意原因'
                    open={laborfeeRefuseModal}
                    onOk={handleRefuseOk}
                    onCancel={handleRefuseCancel}
                    className="laborfeeRefuseModal"
                >
                    {(laborfeeStatus === 0 || laborFeeResetting) && <Form
                        name="laborfeeRefuseForm"
                        form={laborfeeRefuseForm}
                        size="large"
                        autoComplete="off"
                    >
                        <Form.Item name="refuseReason" label="驳回原因" rules={[{ required: true }]}>
                            <TextArea
                                showCount
                                maxLength={100}
                                placeholder="驳回原因"
                                style={{
                                    height: 120,
                                    resize: 'none',
                                }}
                            />
                        </Form.Item>
                    </Form>}

                    <div className="historyReasons">
                        {laborfeeStatus === 0 && <span>历史原因：</span>}
                        <List
                            className="reasons-list"
                            itemLayout="vertical"
                            dataSource={laborfeeRefuseReasons}
                            rowKey={(row) => row.id}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                >
                                    <List.Item.Meta title={dateTimeFormatHMS(item.createdAt)}/>
                                    <p>{item.refuseReason}</p>
                                </List.Item>
                            )}
                        />
                    </div>
                </Modal>

                <Modal
                    title='财务部门复核结果'
                    open={openPaymentRecord}
                    centered
                    onCancel={handleRecordCancel}
                    className="payment-record-modal"
                    footer={null}
                    width={1000}
                >
                    <div className="payment-record-wrap" id="payment_record_wrap">
                        <h3 className="payment-record-title">财务部门复核结果</h3>

                        <div className="payment-record-info">
                            <p className="payment-info-text">【{paymentRecord?.name}】含税总额已由【{paymentRecord?.individualTaxConfirmedBy}】于【{convertDateTimeToLocalText(paymentRecord?.individualTaxConfirmedAt) || ''}】复核完毕，共{getTotalNeedLaborFee()}位专家需要支付劳务费用，其中实发劳务费总计：{paymentRecord?.LaborFeeSum}元，相关税费总计：{paymentRecord?.IndividualTaxSum}元，含税劳务费总计：{getTotalAmount(paymentRecord?.LaborFeeSum, paymentRecord?.IndividualTaxSum)}元。</p>
                            <div className="payment-info-laborfee">
                                <span>氚云项目编号：{paymentRecord?.h3yunNo}</span>
                                <span>项目编号：{numberProject("YHY", projectId)}</span>
                                <span>项目创建人：{paymentRecord?.createdBy}</span>
                            </div>
                            <div className="payment-info-laborfee">
                                <span>实发劳务费审核人：{paymentRecord?.laborFeeConfirmedBy}</span>
                                <span>实发劳务费审核时间：{convertDateTimeToLocalText(paymentRecord?.laborFeeConfirmedAt) || ''}</span>
                            </div>
                            <div className="payment-info-laborfee">
                                <span>含税劳务费审核人：{paymentRecord?.individualTaxConfirmedBy}</span>
                                <span>含税劳务费审核时间：{convertDateTimeToLocalText(paymentRecord?.individualTaxConfirmedAt) || ''}</span>
                            </div>
                        </div>

                        {!!paymentRecord?.projectLaborFeeRecordsAdjustLogsList && !!paymentRecord?.projectLaborFeeRecordsAdjustLogsList.length && (
                            <>
                                <h3 className="payment-record-title">变更记录</h3>
                                <Table
                                    size="small"
                                    columns={modalColumns}
                                    dataSource={paymentRecord?.projectLaborFeeRecordsAdjustLogsList}
                                    loading={modalLoading}
                                    pagination={false}
                                    className="payment-record-table"
                                />
                            </>
                        )}


                        <h3 className="payment-record-title">最终合计结果</h3>
                        <Alert
                            type="info"
                            style={{ marginBottom: 16, fontSize: 12 }}
                            message={
                                <span style={{ fontSize: 12 }}>
                                    实发劳务费总计：&yen;{formatCurrency(statisticalAmount?.LaborFeeSum)}元，
                                    相关税费总计：&yen;{formatCurrency(statisticalAmount?.IndividualTaxSum)}元，
                                    含税劳务费总计：&yen;{formatCurrency(getTotalAmount(statisticalAmount?.LaborFeeSum, statisticalAmount?.IndividualTaxSum))}元，
                                    共{statisticalAmount?.expertSum}位专家
                                    {/* {statisticalAmount?.expertPaySum}位专家已付款 */}
                                </span>
                            }
                        />
                    </div>

                    <div className="payment-record-btn">
                        <Space>
                            <Button type="secondary" onClick={() => onResetLaborFeeWithTax()}>驳回劳务费复核</Button>
                            <Button className="download-btn" type="primary" onClick={() => handleRecordOk()}>下载复核结果截图</Button>
                        </Space>
                    </div>
                </Modal>

                <Modal
                    title="含税劳务费复核"
                    width={400}
                    open={visibleLaborFeeConfirmationModal}
                    centered
                    onCancel={() => closeLaborFeeConfirmationModal()}
                    footer={[
                        <Button key="disagree" onClick={() => disagreeLaborFeeConfirmation()}>不同意</Button>,
                        <Button key="agree" type="primary" onClick={() => agreeLaborFeeConfirmation()}>同意</Button>
                    ]}
                >
                    <p>审批确认后不允许修改，是否确认提交？</p>
                </Modal>

            </div>

        </Content>
    </>;
}
