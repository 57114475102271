import React, { useState, useEffect, useRef } from 'react';
import { Modal, Table, Tabs, Button, Form, Input, Upload, DatePicker, Dropdown, Progress, message } from 'antd';
import { MenuOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { uploadFileApi } from '@/services/Selection/case';
import {
    stageTasksApi,
    stageTasksSaveApi,
    stageTasksEditApi,
    stageTasksSortApi,
    stageTasksDeleteApi,
    stageJudgesApi,
    stageCasesApi,
    sendMessageApi
} from '@/services/Selection/stageMask';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { playerStageTaskTypes, judgeStageTaskTypes } from '@utils/constants';
import TinymceEditor from '../../../Editor/TinyMCEEditor';
import "./index.less"

// 赛段任务
const StageTaskModal = ({ openStageTaskModal, setOpenStageTaskModal, stageId, title }) => {
    const params = useParams();
    const projectId = ~~params?.pid || 0;

    const [form] = Form.useForm();
    const [judgeData, setJudgesData] = useState([]);
    const [playerData, setPlayerData] = useState([]);
    const [openAddStageModal, setOpenAddStageModal] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const [currTaskType, setCurrTaskType] = useState({});

    const [docList, setDocList] = useState(null);
    const [docProgressPercent, setDocProgressPercent] = useState(undefined);
    const [docShowProgress, setDocShowProgress] = useState(false);

    const [loading, setLoading] = useState(false);
    const [judgeMaskItems, setJudgeMaskItems] = useState([]);
    const [playerMaskItems, setPlayerMaskItems] = useState([]);

    const [judgeCount, setJudgeCount] = useState(null); //评委数量
    const [casesCount, stageCasesCount] = useState(null); //案例数量

    const editId = useRef(undefined); // 任务id

    const onChange = (key) => {
        setActiveKey(key);
    };

    const handleCancel = () => {
        setOpenAddStageModal(false);
        form.resetFields();
    }

    // 保存任务
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const reqApi = editId.current ? stageTasksEditApi : stageTasksSaveApi;
            const res = await reqApi({
                id: editId.current,
                name: values.name,
                beginTime: values.beginTime ? moment(values.beginTime).format('YYYY-MM-DD HH:mm:ss') : '',
                endTime: values.endTime ? moment(values.endTime).format('YYYY-MM-DD HH:mm:ss') : '',
                code: currTaskType?.key,
                form: activeKey,
                businessId: stageId,
                projectId: projectId,
                url: values.url ? (typeof values.url === 'string' ? values.url : JSON.stringify(values.url)) : undefined,
                content: values.content
            });
            if (res && res.status_code === 200) {
                setOpenAddStageModal(false);
                message.success('保存成功');
                editId.current = undefined;
                form.resetFields();
                getStageTasks(stageId, activeKey);
            }
        })
    }

    const editTask = (row) => {
        editId.current = row.id;
        setOpenAddStageModal(true);
        form.setFieldsValue({
            name: row.name,
            beginTime: row.beginTime ? moment(row.beginTime) : '',
            endTime: row.endTime ? moment(row.endTime) : '',
            url: row.url || undefined,
            content: row.content || undefined
        });
        if (row.code === 'TASK_DOCUMENT' && row.url) {
            form.setFieldValue('url', JSON.parse(row.url));
            setDocList(JSON.parse(row.url));
        }
        const item = (row.form === 1 ? judgeStageTaskTypes : playerStageTaskTypes).find(it => it.key === row.code)
        setCurrTaskType(item);
    }

    const delTask = async (delId) => {
        const res = await stageTasksDeleteApi({ id: delId });
        if (res.status_code === 200) {
            message.success('删除成功');
            getStageTasks(stageId, activeKey);
        }
    }

    const addTask = (item) => {
        editId.current = undefined;
        setCurrTaskType(item);
        setOpenAddStageModal(true);
    }

    const sendMsg = () => {
        if (activeKey === '1') { 
            if (judgeCount === 0) { 
                return Modal.warning({
                    title: '提示',
                    content: '当前赛段您未添加评委名单，请添加后再次发送',
                });
            }
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: `当前赛段已添加“${judgeCount}”位评委，是否发送消息，通知评委查看任务`,
                okText: '确认发送',
                centered: true,
                cancelText: '暂不发送',
                onOk: async () => {
                    const res = await sendMessageApi({ stageId, type: 1 });
                    if (res.status_code === 200) {
                        message.success('发送成功');
                     }
                }
            });
        }
        if (activeKey === '2') { 
            if (casesCount === 0) { 
                return Modal.warning({
                    title: '提示',
                    content: '当前赛段，您未添加参与评分的案例，请添加案例后再次发送。',
                });
            }
            if (playerData.length === 0) { 
                return Modal.warning({
                    title: '提示',
                    content: '当前赛段您未添加任务，请添加任务后再次发送。',
                });
            }
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: `当前赛段已添加“${casesCount}”个案例是否发送消息，通知案例负责人完成赛段任务`,
                okText: '确认发送',
                centered: true,
                cancelText: '暂不发送',
                onOk: async () => {
                    const res = await sendMessageApi({ stageId, type: 2 });
                    if (res.status_code === 200) {
                        message.success('发送成功');
                     }
                }
            });
        }
    }

    const fileProps = {
        name: "file",
        customRequest: async (info) => {
            let formatType = null;
            const isJpgOrPng = info.file.type === "application/vnd.ms-powerpoint"
                || info.file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                || info.file.type === "application/msword"
                || info.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                || info.file.type === "application/pdf";
            if (!isJpgOrPng) {
                message.error('只能上传 ppt/doc/pdf 格式');
                return false;
            }
            const isLt30M = info.file.size / 1024 / 1024 < 31;
            if (!isLt30M) {
                message.error("文件大小不能超过30M");
                return;
            }
            if (info.file.type === "application/vnd.ms-powerpoint") {
                formatType = 'ppt';
            }
            if (info.file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
                formatType = 'pptx';
            }
            if (info.file.type === "application/msword") {
                formatType = 'doc';
            }
            if (info.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                formatType = 'docx';
            }
            if (info.file.type === "application/pdf") {
                formatType = 'pdf';
            }

            let formData = new FormData();
            formData.append("file", info.file);
            formData.append("type", 13);

            setDocShowProgress(true);
            let configParams = {
                onUploadProgress: (Progress) => {
                    let percents = Math.round((Progress.loaded * 100) / Progress.total)
                    setDocProgressPercent(percents);  // 更新进度条
                }
            };

            uploadFileApi(formData, configParams)
                .then((res) => {
                    if (res && res.status_code === 200 && res.data) {
                        setDocShowProgress(false);
                        setDocProgressPercent(undefined);
                        let docItem = {
                            path: res.data,
                            ext: formatType,
                            name: info.file.name,
                        };
                        setDocList(docItem);

                        form.setFieldValue('url', JSON.stringify(docItem));
                        form.setFields([
                            { name: 'url', errors: null }
                        ]);
                    }
                });
        },
        onChange(info) {
            const isJpgOrPng = info.file.type === "application/vnd.ms-powerpoint"
                || info.file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                || info.file.type === "application/msword"
                || info.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                || info.file.type === "application/pdf";
            const isLt30M = info.file.size / 1024 / 1024 < 31;
            if (!isJpgOrPng) {
                info.file.status = "error";
            }
            if (!isLt30M) {
                info.file.status = "error";
            }
            if (info.file?.id) {
                info.file.status = "error";
            }
            if (info.file.status === "uploading") {
                info.file.status = "done";
            }
        },
    };

    // 赛段任务列表
    const getStageTasks = async (id, key) => {
        setLoading(true);
        const res = await stageTasksApi({ form: key, stageId: id });
        setLoading(false);
        if (res.status_code === 200 && res.data) {
            if (key === '1') {
                setJudgesData(res.data);
            } else {
                setPlayerData(res.data);
            }
        }
    };

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const columns = [
        {
            title: '任务名称',
            dataIndex: 'name',
            className: 'drag-visible',
        },
        {
            title: '开始时间',
            dataIndex: 'beginTime',
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 150,
            render: (_, record) => {
                return <>
                    <Button size="small" type="link" onClick={() => editTask(record)}>编辑</Button>
                    <Button size="small" type="link" onClick={() => delTask(record.id)}>删除</Button>
                    <DragHandle />
                </>
            }
        },
    ];

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        const dataSource = activeKey === '1' ? [...judgeData] : [...playerData];
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            if (activeKey === '1') {
                setJudgesData(newData);
            } else {
                setPlayerData(newData);
            }
            const ids = newData.map(item => item.id);
            const res = await stageTasksSortApi([...ids]);
            if (res.status_code === 200) {
                message.success('排序成功');
            }
        }
    };
    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const dataSource = activeKey === '1' ? judgeData : playerData;
        const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    // 过滤已选中评委任务类型
    const filterJudgeStageTaskTypes = (data) => {
        const list = judgeStageTaskTypes.filter(item => {
            if (item.key === 'TASK_LINK' || item.key === 'TASK_DOCUMENT' || item.key === 'TASK_TEXT') {
                return item;
            } else {
                return !data.find(judge => judge.code === item.key);
            }
        });
        return list;
    };

    // 过滤已选中选手任务类型
    const filterPlayerStageTaskTypes = (data) => {
        const list = playerStageTaskTypes.filter(item => {
            if (item.key === 'TASK_LINK' || item.key === 'TASK_DOCUMENT' || item.key === 'TASK_TEXT') {
                return item;
            } else {
                return !data.find(judge => judge.code === item.key);
            }
        });
        return list;
    };

    useEffect(() => {
        if (stageId) {
            getStageTasks(stageId, activeKey);
        }
    }, [activeKey, stageId]);

    useEffect(() => {
        const judge = filterJudgeStageTaskTypes(judgeData);
        const player = filterPlayerStageTaskTypes(playerData);
        setJudgeMaskItems(judge);
        setPlayerMaskItems(player);
    }, [judgeData, playerData]);

    useEffect(() => { 
        if (stageId) { 
            stageJudgesApi({ stageId }).then(res => {
                if (res.status_code === 200) {
                    setJudgeCount(res.data);
                }
            })
            stageCasesApi({ stageId }).then(res => {
                if (res.status_code === 200) {
                    stageCasesCount(res.data);
                }
            })
        }
    }, [stageId]);

    return <>
        <Modal
            className='stage-mask-modal'
            title={title}
            open={openStageTaskModal}
            centered
            width={1000}
            maskClosable={false}
            onCancel={() => setOpenStageTaskModal(false)}
            footer={null}
        >
            <Tabs
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onChange}
                items={[
                    {
                        label: `评委任务（${judgeData.length ? judgeData.length : '未设置'}任务）`,
                        key: '1',
                    },
                    {
                        label: `选手任务（${playerData.length ? playerData.length : '未设置'}任务）`,
                        key: '2',
                    }
                ]}
            />

            <div className='stage-task-tips'>
                <span></span>
                <div>
                    {(judgeCount !== null || casesCount !== null) &&<Button size="small" type="primary" onClick={sendMsg}>短信通知{activeKey === '1' ? '评委' : '负责人'}</Button>}
                    <Dropdown
                        dropdownRender={
                            () => <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#FFFFFF',
                                boxShadow: "0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d"
                            }}>
                                {
                                    (activeKey === '1' ? judgeMaskItems : playerMaskItems).map(item => <Button key={item.key} type="link" onClick={() => addTask(item)}>{item.label}</Button>)
                                }
                            </div>
                        }
                        placement="bottomRight"
                        arrow
                        destroyPopupOnHide={true}
                    >
                        <Button size="small" type="primary" style={{ marginLeft: '10px' }}>添加任务</Button>
                    </Dropdown>
                </div>
            </div>

            <Table
                pagination={false}
                dataSource={activeKey === '1' ? judgeData : playerData}
                columns={columns}
                rowKey="id"
                loading={loading}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
        </Modal>

        <Modal
            wrapClassName='add-stage-task'
            title="新增任务"
            open={openAddStageModal}
            centered
            width={600}
            onOk={handleOk}
            maskClosable={false}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                name="createForm"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 18,
                }}
                autoComplete="off"
            >
                <Form.Item
                    label="任务类型："
                    name="code"
                    className='required'
                >
                    {currTaskType?.label}
                </Form.Item>
                <Form.Item
                    label="任务名称："
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '任务名称不能为空',
                        },
                    ]}
                >
                    <Input placeholder='请输入任务名称' maxLength={20} />
                </Form.Item>
                <Form.Item
                    label="开始时间："
                    name="beginTime"
                    rules={[
                        {
                            required: true,
                            message: '开始时间不能为空',
                        },
                    ]}
                >
                    <DatePicker showTime format='YYYY-MM-DD HH:mm:ss' style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="结束时间："
                    name="endTime"
                >
                    <DatePicker showTime format='YYYY-MM-DD HH:mm:ss' style={{ width: '100%' }} />
                </Form.Item>

                {
                    currTaskType?.key === 'TASK_LINK' &&
                    <Form.Item
                        label="跳转链接："
                        name="url"
                    >
                        <Input placeholder='请输入跳转链接' />
                    </Form.Item>
                }

                {
                    currTaskType?.key === 'TASK_DOCUMENT' &&
                    <Form.Item
                        label="上传文档"
                        name="url"
                        extra="支持ppt、doc、pdf格式，大小不超过30MB"
                    >
                        <>
                            {docList
                                ? <div className="docBox">
                                    {docList.ext === 'pdf' && <div className="docIcon" title="PDF 文件">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                        </svg>
                                    </div>}
                                    {!!(docList.ext === 'doc' || docList.ext === 'docx') &&
                                        <div className="docIcon" title="Word 文件">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-word" viewBox="0 0 16 16">
                                                <path d="M4.879 4.515a.5.5 0 0 1 .606.364l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 7.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 0 1 .364-.606z" />
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            </svg>
                                        </div>}
                                    {!!(docList.ext === 'ppt' || docList.ext === 'pptx') &&
                                        <div className="docIcon" title="PowerPoint 文件">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-ppt" viewBox="0 0 16 16">
                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                                                <path d="M6 5a1 1 0 0 1 1-1h1.188a2.75 2.75 0 0 1 0 5.5H7v2a.5.5 0 0 1-1 0V5zm1 3.5h1.188a1.75 1.75 0 1 0 0-3.5H7v3.5z" />
                                            </svg>
                                        </div>}
                                    <div className="docName">{docList.name}</div>
                                </div>
                                : null
                            }

                            <Upload {...fileProps} maxCount={1} fileList={[]}>
                                <Button className="btnWithIcon"
                                    icon={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                        </svg>
                                    </>}
                                >
                                    {docList ? '更换文档' : '上传文档'}
                                </Button>
                            </Upload>
                            {docShowProgress && <Progress percent={docProgressPercent} />}
                        </>
                    </Form.Item>
                }

                {
                    currTaskType?.key === 'TASK_TEXT' &&
                    <Form.Item
                        label="文本内容："
                        name="content"
                    >
                        <TinymceEditor
                            form={form}
                            fieldValue='content'
                            id="content"
                            initialValue={form.getFieldValue('content')}
                            placeholder=""
                        />
                    </Form.Item>
                }
            </Form>
        </Modal>
    </>
}

export default StageTaskModal;