import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Layout, Space, Tooltip, Row, Col, Input, Select, Form, DatePicker, message } from 'antd';
import { CloseOutlined, PlusCircleFilled, PlusCircleOutlined, InfoCircleFilled, LeftOutlined } from '@ant-design/icons';
import { get, post } from '@/core/request';
import moment from 'moment';
import './UserGroups.less'

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function UserGroupsNew() {
    const navigate = useNavigate();
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    const { state: { tip, row } } = useLocation();
    const [ListLength, setListLength] = useState(0)

    const pageFormItemLayout = {
        labelCol: {
            sm: { span: 5 },
            md: { span: 5 },
            lg: { span: 3 },
            xl: { span: 3 },
            xxl: { span: 3 }
        },
        wrapperCol: {
            sm: { span: 14 },
            md: { span: 14 },
            lg: { span: 13 },
            xl: { span: 13 },
            xxl: { span: 10 }
        }
    };

    const [form] = Form.useForm();

    const [groupList, setGroupList] = useState({
        queryType: 0,
        name: '',
        displayName: '',
        remark: '',
        list: []
    });

    const [registerTime, setRegisterTime] = useState({
        startTime: '',
        endTime: ''
    });
    const [identityTime, setIdentityTime] = useState({
        startTime: '',
        endTime: ''
    });
    const [lastTime, setLastTime] = useState({
        startTime: '',
        endTime: ''
    });

    // 点击编辑进入
    useEffect(() => {
        if (tip === 'edit') {
            get(`/user/data/user-group/info`, {
                id: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    res.data.list && res.data.list.forEach(item => {
                        item.paramMap && item.paramMap.list.forEach(elem => {
                            if (elem.code === '<time>member.mem_regist_time') {
                                let [startTime, endTime] = elem.param.split(/\//);
                                setRegisterTime({
                                    startTime, endTime
                                })
                            } else if (elem.code === '<time>member.mem_examine_time') {
                                let [startTime, endTime] = elem.param.split(/\//);
                                setIdentityTime({
                                    startTime, endTime
                                })
                            } else if (elem.code === '<time>member.mem_lastest_login_time') {
                                let [startTime, endTime] = elem.param.split(/\//);
                                setLastTime({
                                    startTime, endTime
                                })
                            }
                        })
                    })
                    form.setFieldsValue({
                        name: res.data.name,
                        displayName: res.data.displayName,
                        remark: res.data.remark
                    })
                    setGroupList(res.data)
                }
            })
        }
    }, [])

    // 点击添加最外层
    const handleAddOut = () => {
        let outObj = {
            list: [
                { code: undefined, value2: undefined, param: undefined },
            ],
            queryType: 0
        };
        setListLength(ListLength + 1);
        groupList.list.push({ paramMap: outObj })
        setGroupList({
            ...groupList,
            list: groupList.list
        })
    }

    // 点击添加内层
    const handleAddInner = (outIndex) => {
        return () => {
            let outList = groupList.list.map((item, index) => {
                if (index === outIndex) {
                    const { list } = item.paramMap;
                    let innerList = [...list];
                    innerList.splice(innerList.length, 0, {
                        code: undefined,
                        value2: undefined,
                        param: undefined
                    });
                    return {
                        paramMap: {
                            ...item.paramMap,
                            list: innerList
                        }
                    };
                }
                return item
            })
            setListLength(ListLength + 1);
            setGroupList({
                ...groupList,
                list: outList
            })
        }
    }
    // 删除
    const handleDelete = (outIndex, innerIndex) => {
        return () => {
            let arr = []
            groupList.list.forEach((item, index) => {
                if (index === outIndex) {
                    if (item.paramMap.list.length === 1) {
                        arr = groupList.list.filter((item, index) => index !== outIndex);
                    } else {
                        arr = groupList.list.map((item, index) => {
                            if (index === outIndex) {
                                if (item.paramMap.list.length !== 1) {
                                    return {
                                        paramMap: {
                                            ...item.paramMap,
                                            list: item.paramMap.list.filter((item, index) => index !== innerIndex)
                                        }
                                    };
                                }
                            }
                            return item;
                        })
                    }
                }
            })
            setListLength(ListLength - 1);
            setGroupList({
                ...groupList,
                list: arr
            })
        }
    }

    // 外部的且或更改
    const handleChangeOutRelation = () => {
        setGroupList({
            ...groupList,
            queryType: groupList.queryType === 0 ? 1 : 0
        })
    }

    // 内部的且或更改
    const handleChangeInnerRelation = (outIndex) => {
        return () => {
            let arr = groupList.list.map((item, index) => {
                if (index === outIndex) {
                    return {
                        paramMap: {
                            ...item.paramMap,
                            queryType: item.paramMap.queryType === 0 ? 1 : 0
                        }
                    };
                }
                return item
            })
            setGroupList({
                ...groupList,
                list: arr
            })
        }
    }

    // inner内容改变
    const handleChangeValue = (type, outIndex, innerIndex) => {
        return (value, dateString) => {
            let arr = [];
            let data = value;
            // input
            if (Object.prototype.toString.call(value) === '[object Object]') {
                data = value.target.value;
            }
            arr = groupList.list.map((outItem, outI) => {
                if (outI === outIndex) {
                    return {
                        paramMap: {
                            ...outItem.paramMap,
                            list: outItem.paramMap.list.map((innerItem, innerI) => {
                                if (innerI === innerIndex) {
                                    if (innerItem.code === '<time>member.mem_regist_time' || innerItem.code === '<time>member.mem_examine_time' || innerItem.code === '<time>member.mem_lastest_login_time') {
                                        if (type === 'param') {
                                            data = `${dateString[0]}/${dateString[1]}`;
                                        }
                                    }
                                    if (innerItem.code === 'member_detail.mem_office' || innerItem.code === 'member_detail.mem_post' || innerItem.code === 'member_detail.mem_jobtitle'
                                        || innerItem.code === 'member_detail.mem_sex' || innerItem.code === 'member_detail.mem_identity' || innerItem.code === 'member.mem_device_type') {
                                        if (type === 'param') {
                                            var ids = [];
                                            value.forEach(item => {
                                                ids.push(item);
                                            });
                                            data = ids.join(',');
                                        }
                                    }
                                    return {
                                        ...innerItem,
                                        [type]: data
                                    };
                                }
                                return innerItem;
                            })
                        }
                    };
                }
                return outItem;
            })
            // change第一个 第三个置为空
            if (type === 'code') {
                arr = groupList.list.map((outItem, outI) => {
                    if (outI === outIndex) {
                        return {
                            paramMap: {
                                ...outItem.paramMap,
                                list: outItem.paramMap.list.map((innerItem, innerI) => {
                                    if (innerI === innerIndex) {
                                        return {
                                            ...innerItem,
                                            code: data,
                                            value2: undefined,
                                            param: undefined
                                        };
                                    }
                                    return innerItem;
                                })
                            }
                        };
                    }
                    return outItem;
                })
            }
            if (arr) {
                arr.map(item => {
                    item.paramMap.list.map(elem => {
                        switch (elem.code) {
                            case 'member.mem_card':
                                elem.value2 = 'equal'
                                break;
                            case 'member.mem_mobile':
                                elem.value2 = 'equal'
                                break;
                            case 'member.mem_email':
                                elem.value2 = 'equal'
                                break;
                            case 'member_detail.mem_sex':
                                elem.value2 = 'equal'
                                break;
                            case 'member_detail.mem_identity':
                                elem.value2 = 'equal'
                                break;
                            case 'member_detail.mem_office':
                                elem.value2 = 'equal'
                                break;
                            case 'member_detail.mem_post':
                                elem.value2 = 'equal'
                                break;
                            case 'member_detail.mem_jobtitle':
                                elem.value2 = 'equal'
                                break;
                            case 'member.mem_device_type':
                                elem.value2 = 'equal'
                                break;
                            case '<time>member.mem_regist_time':
                                elem.value2 = 'range'
                                break;
                            case '<time>member.mem_examine_time':
                                elem.value2 = 'range'
                                break;
                            case '<time>member.mem_lastest_login_time':
                                elem.value2 = 'range'
                                break;

                        }
                        return elem
                    })
                    return item
                })
            }
            setGroupList({
                ...groupList,
                list: arr
            });
        }
    };

    // 科室
    const [officeData, setOfficeData] = useState([]);
    // 职务
    const [postData, setPostData] = useState([]);
    // 职称
    const [titleData, setTitleData] = useState([]);

    // 科室 职务 职称
    useEffect(() => {
        get('user/dictionary/office/detail', { type: 2 }).then((res) => {
            setOfficeData(res.data)
        })
        get('/user/dictionary/board', { type: 3 }).then((res) => {
            setPostData(res.data)
        })
        get('/user/dictionary/board', { type: 4 }).then((res) => {
            setTitleData(res.data)
        })
    }, []);

    // 点击取消
    const handleClose = () => {
        form.resetFields()
        setGroupList({
            queryType: 0,
            name: '',
            displayName: '',
            remark: '',
            list: []
        });
    }

    // 点击提交
    const onFinish = (values) => {
        let finishBool = true;
        if (groupList.list.length !== 0) {
            groupList.list.forEach(item => {
                item.paramMap.list.forEach(elem => {
                    if (!elem.code || !elem.value2 || !elem.param) {
                        message.error('请检查分群规则是否填全');
                        finishBool = false;
                        return
                    }
                })
            })
            if (finishBool) {
                // groupList.name = `usergroup_${values.name}`;
                groupList.name = `${values.name}`;
                groupList.displayName = values.displayName;
                groupList.remark = values.remark;
                let string = JSON.stringify(groupList);
                post(`/user/data/user-group/save`, string).then(res => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        navigate('/uc/user-groups')
                    }
                });
            }

        } else {
            message.error('分群规则不能为空')
        }
    };

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/uc/user-groups" className="back"><LeftOutlined /></Link>
                新建用户组
            </div>

            <Content className="main-content-box">

                <div className="main-content-title">基本信息</div>
                <Form className="page-form" {...pageFormItemLayout} form={form} onFinish={onFinish}>
                    <div className="basic-message">

                        {
                            tip === 'add' ? <Form.Item
                                name="name"
                                label="分群名称"
                                help=""
                                required={true}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            // if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(value)) {
                                            //     return Promise.reject('以字母开头，可包含数字和下划线');
                                            // }
                                            if (value === undefined) {
                                                return Promise.reject('分群名称不能为空');
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input showCount maxLength={30} placeholder="输入分群名称" allowClear autoComplete="off" />
                            </Form.Item> : <Form.Item
                                name="name"
                                label="分群名称"
                                required={true}
                            >
                                <Input placeholder="输入分群名称" allowClear autoComplete="off" disabled />
                            </Form.Item>
                        }

                        {/* <Form.Item
                            name="displayName"
                            label="分群显示名称"
                            rules={[{ required: true, message: '请填写分群显示名称' }]}>
                            <Input placeholder="输入分群显示名称" allowClear autoComplete="off" disabled={tip === 'edit' ? true : false} showCount maxLength={30} />
                        </Form.Item> */}

                        <Form.Item
                            name="remark"
                            label="备注"
                        >
                            <TextArea rows={3} placeholder="输入备注信息" allowClear autoComplete="off" disabled={tip === 'edit' ? true : false} showCount maxLength={50} />
                        </Form.Item>
                    </div>


                    <div className="main-content-title">分群规则</div>

                    <Row className="user-group-rules-space">
                        <Col span={3} style={{ textAlign: 'right' }}><span>创建规则：</span></Col>
                        <Col span={20}>
                            <div className="user-groups-box">
                                <div className="user-groups-box-header">
                                    <Row>
                                        <Col span={12}><h3>用户属性满足</h3></Col>

                                        {tip === 'add' && ListLength < 5 && <Col span={12} style={{ textAlign: 'right' }}><div onClick={handleAddOut} className="user-add-box"><PlusCircleFilled className="user-add-icon" />添加</div></Col>}
                                    </Row>
                                </div>

                                <div className={groupList.list && groupList.list.length > 1 ? 'edit-condition-box' : 'edit-condition-box-noborder'}>
                                    {
                                        groupList.list && groupList.list.length > 1 && <span className={tip === 'add' ? 'edit-condition-box-queryType' : 'edit-condition-box-queryType disable-click'} onClick={handleChangeOutRelation}>{groupList.queryType === 0 ? '或' : '且'}</span>
                                    }
                                    {
                                        groupList.list && groupList.list.map((outItem, outIndex) => {
                                            const { list, queryType } = outItem.paramMap || {};
                                            return (
                                                <div key={outIndex} className={list && list.length > 1 ? 'edit-condition-box' : 'edit-condition-box-noborder'}>
                                                    {
                                                        list && list.length > 1 && <span className={tip === 'add' ? 'edit-condition-box-queryType' : 'edit-condition-box-queryType disable-click'} onClick={handleChangeInnerRelation(outIndex)}>{queryType === 0 ? '或' : '且'}</span>
                                                    }

                                                    <div className="inner-content-box">
                                                        {
                                                            list && list.map((innerItem, innerIndex) => {
                                                                const { code, value2, param } = innerItem;
                                                                return (
                                                                    <Row key={innerIndex} gutter={16} className="inner-content">
                                                                        <Col className="gutter-row" span={6}>
                                                                            <Select placeholder="请选择用户属性" value={code} style={{ width: '100%' }} disabled={tip === 'edit' ? true : false}
                                                                                onChange={handleChangeValue('code', outIndex, innerIndex)}>
                                                                                <Option value="member.mem_card">用户唯一标识</Option>
                                                                                <Option value="member.mem_nickname">昵称</Option>
                                                                                <Option value="member_detail.mem_username">真实姓名</Option>
                                                                                <Option value="member_detail.mem_sex">性别</Option>
                                                                                <Option value="member.mem_mobile">手机号</Option>
                                                                                <Option value="member.mem_email">邮箱</Option>
                                                                                <Option value="member_detail.mem_identity">身份</Option>
                                                                                <Option value="member_detail.mem_unit">单位名称</Option>
                                                                                <Option value="member_detail.mem_office">科室</Option>
                                                                                <Option value="member_detail.mem_post">职务</Option>
                                                                                <Option value="member_detail.mem_jobtitle">职称</Option>
                                                                                <Option value="<time>member.mem_regist_time">注册时间</Option>
                                                                                <Option value="member.mem_device_type">注册端口</Option>
                                                                                <Option value="<time>member.mem_examine_time">认证审核时间</Option>
                                                                                <Option value="<time>member.mem_lastest_login_time">最后登录时间</Option>
                                                                            </Select>
                                                                        </Col>
                                                                        {/* 用户唯一标识    手机号    邮箱*/}
                                                                        {
                                                                            code === 'member.mem_card' || code === 'member.mem_mobile' || code === 'member.mem_email' ? <Fragment>
                                                                                <Col className="gutter-row" span={4}>
                                                                                    <Select value='equal' disabled style={{ width: '100%' }}>
                                                                                        <Option value='equal'>等于</Option>
                                                                                    </Select>
                                                                                </Col>
                                                                                <Col className="gutter-row" span={7}>
                                                                                    <Input
                                                                                        disabled={tip === 'edit' ? true : false}
                                                                                        allowClear value={param}
                                                                                        placeholder={code === 'member.mem_card' ? "输入用户唯一标识(mem_card)" : code === 'member.mem_mobile' ? "输入用户手机号" : "输入用户邮箱"}
                                                                                        autoComplete="off" onChange={handleChangeValue('param', outIndex, innerIndex)} />
                                                                                    {
                                                                                        tip === 'add' && code === 'member.mem_card' && <Tooltip placement="right" title="单个 mem_card 或英文逗号隔开的 mem_card列表，不超过100个">
                                                                                            <span className="user-group-tooltip"><InfoCircleFilled /></span>
                                                                                        </Tooltip>
                                                                                    }

                                                                                </Col>
                                                                            </Fragment> : <Fragment></Fragment>
                                                                        }

                                                                        {/* 昵称    真实姓名    单位名称*/}
                                                                        {
                                                                            code === 'member.mem_nickname' || code === 'member_detail.mem_username' || code === 'member_detail.mem_unit' ? <Fragment>
                                                                                <Col className="gutter-row" span={4}>
                                                                                    <Select placeholder="请选择条件" value={value2} style={{ width: '100%' }} disabled={tip === 'edit' ? true : false}
                                                                                        onChange={handleChangeValue('value2', outIndex, innerIndex)} >
                                                                                        <Option value='equal'>等于</Option>
                                                                                        <Option value='include'>包含</Option>
                                                                                    </Select>
                                                                                </Col>
                                                                                <Col className="gutter-row" span={7}>
                                                                                    <Input value={param} allowClear autoComplete="off" disabled={tip === 'edit' ? true : false}
                                                                                        placeholder={code === 'member.mem_nickname' ? "输入用户昵称" : code === 'member_detail.mem_username' ? "输入用户真实姓名" : "输入用户单位名称"}
                                                                                        onChange={handleChangeValue('param', outIndex, innerIndex)} />
                                                                                    {
                                                                                        tip === 'add' && <Tooltip placement="right" title={value2 === 'equal' ? "单个昵称或英文逗号隔开的昵称列表，不超过100个" : "昵称中包含该关键词，不含空格"}>
                                                                                            <span className="user-group-tooltip"><InfoCircleFilled /></span>
                                                                                        </Tooltip>
                                                                                    }

                                                                                </Col>
                                                                            </Fragment> : <Fragment></Fragment>
                                                                        }
                                                                        {/* 性别    身份    科室    职务    职称    注册端口*/}
                                                                        {
                                                                            code === 'member_detail.mem_sex' || code === 'member_detail.mem_identity'
                                                                                || code === 'member_detail.mem_office' || code === 'member_detail.mem_post'
                                                                                || code === 'member_detail.mem_jobtitle' || code === 'member.mem_device_type' ? <Fragment>

                                                                                <Col className="gutter-row" span={4}>
                                                                                    <Select value='equal' disabled style={{ width: '100%' }}>
                                                                                        <Option value='equal'>等于</Option>
                                                                                    </Select>
                                                                                </Col>
                                                                                <Col className="gutter-row" span={7}>
                                                                                    {
                                                                                        code === 'member_detail.mem_sex' && <Select value={param ? param.split(/[,]/) : undefined} placeholder="请选择性别" mode="multiple"
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)} disabled={tip === 'edit' ? true : false}>
                                                                                            <Select.Option value='0'>女</Select.Option>
                                                                                            <Select.Option value='1'>男</Select.Option>
                                                                                        </Select>
                                                                                    }
                                                                                    {
                                                                                        code === 'member_detail.mem_identity' && <Fragment>
                                                                                            <Select value={param ? param.split(/[,]/) : undefined} placeholder="请选择身份" mode="multiple"
                                                                                                onChange={handleChangeValue('param', outIndex, innerIndex)} disabled={tip === 'edit' ? true : false}>
                                                                                                <Select.Option value='A01'>医务人员</Select.Option>
                                                                                                <Select.Option value='A02'>政府官员</Select.Option>
                                                                                                <Select.Option value='A03'>商业人士</Select.Option>
                                                                                                <Select.Option value='A04'>教育科研人士</Select.Option>
                                                                                                <Select.Option value='A06'>社会团体人士</Select.Option>
                                                                                            </Select>
                                                                                        </Fragment>
                                                                                    }
                                                                                    {
                                                                                        code === 'member_detail.mem_office' && <Select value={param ? param.split(/[,]/) : undefined} placeholder="请选择科室" mode="multiple"
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)} disabled={tip === 'edit' ? true : false}
                                                                                            showSearch optionFilterProp="children">
                                                                                            {
                                                                                                officeData && officeData.map(item => {
                                                                                                    return (
                                                                                                        <Select.OptGroup label={item.dicName} key={item.dicCode}>
                                                                                                            {
                                                                                                                item.child.length !== 0 && item.child.map(elem => {
                                                                                                                    return (
                                                                                                                        <Select.Option key={elem.dicCode} value={elem.dicCode}>{elem.dicName}</Select.Option>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </Select.OptGroup>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Select>
                                                                                    }
                                                                                    {
                                                                                        code === 'member_detail.mem_post' && <Select value={param ? param.split(/[,]/) : undefined} placeholder="请选择职务" mode="multiple"
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)} disabled={tip === 'edit' ? true : false}
                                                                                            showSearch optionFilterProp="children">
                                                                                            {postData && postData.map(item => {
                                                                                                return (
                                                                                                    <Select.Option key={item.dicCode} value={item.dicCode}>{item.dicName}</Select.Option>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    }
                                                                                    {
                                                                                        code === 'member_detail.mem_jobtitle' && <Select value={param ? param.split(/[,]/) : undefined} placeholder="请选择职称" mode="multiple"
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)} disabled={tip === 'edit' ? true : false}
                                                                                            showSearch optionFilterProp="children">
                                                                                            {titleData && titleData.map(item => {
                                                                                                return (
                                                                                                    <Select.Option key={item.dicCode} value={item.dicCode}>{item.dicName}</Select.Option>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    }
                                                                                    {
                                                                                        code === 'member.mem_device_type' && <Select value={param ? param.split(/[,]/) : undefined} placeholder="请选择注册端口" mode="multiple"
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)} disabled={tip === 'edit' ? true : false}>
                                                                                            <Select.Option value='app'>APP</Select.Option>
                                                                                            <Select.Option value='wap'>WAP</Select.Option>
                                                                                            <Select.Option value='pc'>PC</Select.Option>
                                                                                        </Select>
                                                                                    }
                                                                                    {
                                                                                        tip === 'add' && <Tooltip placement="right" title="单个或英文逗号隔开的多个值">
                                                                                            <span className="user-group-tooltip"><InfoCircleFilled /></span>
                                                                                        </Tooltip>
                                                                                    }

                                                                                </Col>
                                                                            </Fragment> : <Fragment></Fragment>
                                                                        }

                                                                        {/* 注册时间    认证审核时间    最后登录时间*/}
                                                                        {
                                                                            code === '<time>member.mem_regist_time' && <Fragment>
                                                                                <Col className="gutter-row" span={4}>
                                                                                    <Select value='range' disabled style={{ width: '100%' }}>
                                                                                        <Option value='range'>区间</Option>
                                                                                    </Select>
                                                                                </Col>
                                                                                <Col className="gutter-row" span={7}>
                                                                                    {
                                                                                        tip === 'edit' ? <RangePicker
                                                                                            disabled={true}
                                                                                            value={[moment(registerTime.startTime, dateFormat), moment(registerTime.endTime, dateFormat)]}
                                                                                            allowClear={false}
                                                                                            showTime /> : <RangePicker
                                                                                            allowClear={false}
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)}
                                                                                            showTime />
                                                                                    }
                                                                                </Col>
                                                                            </Fragment>
                                                                        }
                                                                        {
                                                                            code === '<time>member.mem_examine_time' && <Fragment>
                                                                                <Col className="gutter-row" span={4}>
                                                                                    <Select value='range' disabled style={{ width: '100%' }}>
                                                                                        <Option value='range'>区间</Option>
                                                                                    </Select>
                                                                                </Col>
                                                                                <Col className="gutter-row" span={7}>
                                                                                    {
                                                                                        tip === 'edit' ? <RangePicker
                                                                                            disabled={true}
                                                                                            value={[moment(identityTime.startTime, dateFormat), moment(identityTime.endTime, dateFormat)]}
                                                                                            allowClear={false}
                                                                                            showTime /> : <RangePicker
                                                                                            allowClear={false}
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)}
                                                                                            showTime />
                                                                                    }
                                                                                </Col>
                                                                            </Fragment>
                                                                        }
                                                                        {
                                                                            code === '<time>member.mem_lastest_login_time' && <Fragment>
                                                                                <Col className="gutter-row" span={4}>
                                                                                    <Select value='range' disabled style={{ width: '100%' }}>
                                                                                        <Option value='range'>区间</Option>
                                                                                    </Select>
                                                                                </Col>
                                                                                <Col className="gutter-row" span={7}>
                                                                                    {
                                                                                        tip === 'edit' ? <RangePicker
                                                                                            disabled={true}
                                                                                            value={[moment(lastTime.startTime, dateFormat), moment(lastTime.endTime, dateFormat)]}
                                                                                            allowClear={false}
                                                                                            showTime /> : <RangePicker
                                                                                            allowClear={false}
                                                                                            onChange={handleChangeValue('param', outIndex, innerIndex)}
                                                                                            showTime />
                                                                                    }</Col>
                                                                            </Fragment>
                                                                        }
                                                                        {
                                                                            tip === 'add' && <CloseOutlined className="user-delete-icon" onClick={handleDelete(outIndex, innerIndex)} />
                                                                        }

                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            tip === 'add' && list.length !== 0 && ListLength < 5 && <div className="inner-add">
                                                                <span onClick={handleAddInner(outIndex)} className="user-add-box"><PlusCircleOutlined className="user-add-icon" />新增</span>
                                                            </div>
                                                        }


                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        tip === 'add' && <Form.Item>
                            <Space className="user-group-submit">
                                <Button className="submit-button" type="primary" htmlType="submit">提交</Button>
                                <Button type="default" onClick={handleClose}>取消</Button>
                            </Space>
                        </Form.Item>
                    }



                </Form>
            </Content>

        </Fragment>
    )
}
