module.exports = [
    {
        value: "110000",
        label: "北京",
        simple_name: "北京",
        hidden: 0,
        children: [
            {
                value: "110100",
                label: "北京市",
                simple_name: "北京",
                hidden: 0,
                children: [
                    {
                        value: "110101",
                        label: "东城区",
                        simple_name: "东城",
                        hidden: 0
                    },
                    {
                        value: "110102",
                        label: "西城区",
                        simple_name: "西城",
                        hidden: 0
                    },
                    {
                        value: "110105",
                        label: "朝阳区",
                        simple_name: "朝阳",
                        hidden: 0
                    },
                    {
                        value: "110106",
                        label: "丰台区",
                        simple_name: "丰台",
                        hidden: 0
                    },
                    {
                        value: "110107",
                        label: "石景山区",
                        simple_name: "石景山",
                        hidden: 0
                    },
                    {
                        value: "110108",
                        label: "海淀区",
                        simple_name: "海淀",
                        hidden: 0
                    },
                    {
                        value: "110109",
                        label: "门头沟区",
                        simple_name: "门头沟",
                        hidden: 0
                    },
                    {
                        value: "110111",
                        label: "房山区",
                        simple_name: "房山",
                        hidden: 0
                    },
                    {
                        value: "110112",
                        label: "通州区",
                        simple_name: "通州",
                        hidden: 0
                    },
                    {
                        value: "110113",
                        label: "顺义区",
                        simple_name: "顺义",
                        hidden: 0
                    },
                    {
                        value: "110114",
                        label: "昌平区",
                        simple_name: "昌平",
                        hidden: 0
                    },
                    {
                        value: "110115",
                        label: "大兴区",
                        simple_name: "大兴",
                        hidden: 0
                    },
                    {
                        value: "110116",
                        label: "怀柔区",
                        simple_name: "怀柔",
                        hidden: 0
                    },
                    {
                        value: "110117",
                        label: "平谷区",
                        simple_name: "平谷",
                        hidden: 0
                    },
                    {
                        value: "110228",
                        label: "密云县",
                        simple_name: "密云",
                        hidden: 0
                    },
                    {
                        value: "110229",
                        label: "延庆县",
                        simple_name: "延庆",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "120000",
        label: "天津",
        simple_name: "天津",
        hidden: 0,
        children: [
            {
                value: "120100",
                label: "天津市",
                simple_name: "天津",
                hidden: 0,
                children: [
                    {
                        value: "120101",
                        label: "和平区",
                        simple_name: "和平",
                        hidden: 0
                    },
                    {
                        value: "120102",
                        label: "河东区",
                        simple_name: "河东",
                        hidden: 0
                    },
                    {
                        value: "120103",
                        label: "河西区",
                        simple_name: "河西",
                        hidden: 0
                    },
                    {
                        value: "120104",
                        label: "南开区",
                        simple_name: "南开",
                        hidden: 0
                    },
                    {
                        value: "120105",
                        label: "河北区",
                        simple_name: "河北",
                        hidden: 0
                    },
                    {
                        value: "120106",
                        label: "红桥区",
                        simple_name: "红桥",
                        hidden: 0
                    },
                    {
                        value: "120110",
                        label: "东丽区",
                        simple_name: "东丽",
                        hidden: 0
                    },
                    {
                        value: "120111",
                        label: "西青区",
                        simple_name: "西青",
                        hidden: 0
                    },
                    {
                        value: "120112",
                        label: "津南区",
                        simple_name: "津南",
                        hidden: 0
                    },
                    {
                        value: "120113",
                        label: "北辰区",
                        simple_name: "北辰",
                        hidden: 0
                    },
                    {
                        value: "120114",
                        label: "武清区",
                        simple_name: "武清",
                        hidden: 0
                    },
                    {
                        value: "120115",
                        label: "宝坻区",
                        simple_name: "宝坻",
                        hidden: 0
                    },
                    {
                        value: "120116",
                        label: "滨海新区",
                        simple_name: "滨海新区",
                        hidden: 0
                    },
                    {
                        value: "120221",
                        label: "宁河县",
                        simple_name: "宁河",
                        hidden: 0
                    },
                    {
                        value: "120223",
                        label: "静海县",
                        simple_name: "静海",
                        hidden: 0
                    },
                    {
                        value: "120225",
                        label: "蓟县",
                        simple_name: "蓟县",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "130000",
        label: "河北省",
        simple_name: "河北",
        hidden: 0,
        children: [
            {
                value: "130100",
                label: "石家庄市",
                simple_name: "石家庄",
                hidden: 0,
                children: [
                    {
                        value: "130102",
                        label: "长安区",
                        simple_name: "长安",
                        hidden: 0
                    },
                    {
                        value: "130104",
                        label: "桥西区",
                        simple_name: "桥西",
                        hidden: 0
                    },
                    {
                        value: "130105",
                        label: "新华区",
                        simple_name: "新华",
                        hidden: 0
                    },
                    {
                        value: "130107",
                        label: "井陉矿区",
                        simple_name: "井陉矿区",
                        hidden: 0
                    },
                    {
                        value: "130108",
                        label: "裕华区",
                        simple_name: "裕华",
                        hidden: 0
                    },
                    {
                        value: "130109",
                        label: "藁城区",
                        simple_name: "藁城",
                        hidden: 0
                    },
                    {
                        value: "130110",
                        label: "鹿泉区",
                        simple_name: "鹿泉",
                        hidden: 0
                    },
                    {
                        value: "130111",
                        label: "栾城区",
                        simple_name: "栾城",
                        hidden: 0
                    },
                    {
                        value: "130121",
                        label: "井陉县",
                        simple_name: "井陉",
                        hidden: 0
                    },
                    {
                        value: "130123",
                        label: "正定县",
                        simple_name: "正定",
                        hidden: 0
                    },
                    {
                        value: "130125",
                        label: "行唐县",
                        simple_name: "行唐",
                        hidden: 0
                    },
                    {
                        value: "130126",
                        label: "灵寿县",
                        simple_name: "灵寿",
                        hidden: 0
                    },
                    {
                        value: "130127",
                        label: "高邑县",
                        simple_name: "高邑",
                        hidden: 0
                    },
                    {
                        value: "130128",
                        label: "深泽县",
                        simple_name: "深泽",
                        hidden: 0
                    },
                    {
                        value: "130129",
                        label: "赞皇县",
                        simple_name: "赞皇",
                        hidden: 0
                    },
                    {
                        value: "130130",
                        label: "无极县",
                        simple_name: "无极",
                        hidden: 0
                    },
                    {
                        value: "130131",
                        label: "平山县",
                        simple_name: "平山",
                        hidden: 0
                    },
                    {
                        value: "130132",
                        label: "元氏县",
                        simple_name: "元氏",
                        hidden: 0
                    },
                    {
                        value: "130133",
                        label: "赵县",
                        simple_name: "赵县",
                        hidden: 0
                    },
                    {
                        value: "130181",
                        label: "辛集市",
                        simple_name: "辛集",
                        hidden: 0
                    },
                    {
                        value: "130183",
                        label: "晋州市",
                        simple_name: "晋州",
                        hidden: 0
                    },
                    {
                        value: "130184",
                        label: "新乐市",
                        simple_name: "新乐",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130200",
                label: "唐山市",
                simple_name: "唐山",
                hidden: 0,
                children: [
                    {
                        value: "130202",
                        label: "路南区",
                        simple_name: "路南",
                        hidden: 0
                    },
                    {
                        value: "130203",
                        label: "路北区",
                        simple_name: "路北",
                        hidden: 0
                    },
                    {
                        value: "130204",
                        label: "古冶区",
                        simple_name: "古冶",
                        hidden: 0
                    },
                    {
                        value: "130205",
                        label: "开平区",
                        simple_name: "开平",
                        hidden: 0
                    },
                    {
                        value: "130207",
                        label: "丰南区",
                        simple_name: "丰南",
                        hidden: 0
                    },
                    {
                        value: "130208",
                        label: "丰润区",
                        simple_name: "丰润",
                        hidden: 0
                    },
                    {
                        value: "130209",
                        label: "曹妃甸区",
                        simple_name: "曹妃甸",
                        hidden: 0
                    },
                    {
                        value: "130223",
                        label: "滦县",
                        simple_name: "滦县",
                        hidden: 0
                    },
                    {
                        value: "130224",
                        label: "滦南县",
                        simple_name: "滦南",
                        hidden: 0
                    },
                    {
                        value: "130225",
                        label: "乐亭县",
                        simple_name: "乐亭",
                        hidden: 0
                    },
                    {
                        value: "130227",
                        label: "迁西县",
                        simple_name: "迁西",
                        hidden: 0
                    },
                    {
                        value: "130229",
                        label: "玉田县",
                        simple_name: "玉田",
                        hidden: 0
                    },
                    {
                        value: "130281",
                        label: "遵化市",
                        simple_name: "遵化",
                        hidden: 0
                    },
                    {
                        value: "130283",
                        label: "迁安市",
                        simple_name: "迁安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130300",
                label: "秦皇岛市",
                simple_name: "秦皇岛",
                hidden: 0,
                children: [
                    {
                        value: "130302",
                        label: "海港区",
                        simple_name: "海港",
                        hidden: 0
                    },
                    {
                        value: "130303",
                        label: "山海关区",
                        simple_name: "山海关",
                        hidden: 0
                    },
                    {
                        value: "130304",
                        label: "北戴河区",
                        simple_name: "北戴河",
                        hidden: 0
                    },
                    {
                        value: "130321",
                        label: "青龙满族自治县",
                        simple_name: "青龙",
                        hidden: 0
                    },
                    {
                        value: "130322",
                        label: "昌黎县",
                        simple_name: "昌黎",
                        hidden: 0
                    },
                    {
                        value: "130323",
                        label: "抚宁县",
                        simple_name: "抚宁",
                        hidden: 0
                    },
                    {
                        value: "130324",
                        label: "卢龙县",
                        simple_name: "卢龙",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130400",
                label: "邯郸市",
                simple_name: "邯郸",
                hidden: 0,
                children: [
                    {
                        value: "130402",
                        label: "邯山区",
                        simple_name: "邯山",
                        hidden: 0
                    },
                    {
                        value: "130403",
                        label: "丛台区",
                        simple_name: "丛台",
                        hidden: 0
                    },
                    {
                        value: "130404",
                        label: "复兴区",
                        simple_name: "复兴",
                        hidden: 0
                    },
                    {
                        value: "130406",
                        label: "峰峰矿区",
                        simple_name: "峰峰矿区",
                        hidden: 0
                    },
                    {
                        value: "130421",
                        label: "邯郸县",
                        simple_name: "邯郸",
                        hidden: 0
                    },
                    {
                        value: "130423",
                        label: "临漳县",
                        simple_name: "临漳",
                        hidden: 0
                    },
                    {
                        value: "130424",
                        label: "成安县",
                        simple_name: "成安",
                        hidden: 0
                    },
                    {
                        value: "130425",
                        label: "大名县",
                        simple_name: "大名",
                        hidden: 0
                    },
                    {
                        value: "130426",
                        label: "涉县",
                        simple_name: "涉县",
                        hidden: 0
                    },
                    {
                        value: "130427",
                        label: "磁县",
                        simple_name: "磁县",
                        hidden: 0
                    },
                    {
                        value: "130428",
                        label: "肥乡县",
                        simple_name: "肥乡",
                        hidden: 0
                    },
                    {
                        value: "130429",
                        label: "永年县",
                        simple_name: "永年",
                        hidden: 0
                    },
                    {
                        value: "130430",
                        label: "邱县",
                        simple_name: "邱县",
                        hidden: 0
                    },
                    {
                        value: "130431",
                        label: "鸡泽县",
                        simple_name: "鸡泽",
                        hidden: 0
                    },
                    {
                        value: "130432",
                        label: "广平县",
                        simple_name: "广平",
                        hidden: 0
                    },
                    {
                        value: "130433",
                        label: "馆陶县",
                        simple_name: "馆陶",
                        hidden: 0
                    },
                    {
                        value: "130434",
                        label: "魏县",
                        simple_name: "魏县",
                        hidden: 0
                    },
                    {
                        value: "130435",
                        label: "曲周县",
                        simple_name: "曲周",
                        hidden: 0
                    },
                    {
                        value: "130481",
                        label: "武安市",
                        simple_name: "武安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130500",
                label: "邢台市",
                simple_name: "邢台",
                hidden: 0,
                children: [
                    {
                        value: "130502",
                        label: "桥东区",
                        simple_name: "桥东",
                        hidden: 0
                    },
                    {
                        value: "130503",
                        label: "桥西区",
                        simple_name: "桥西",
                        hidden: 0
                    },
                    {
                        value: "130521",
                        label: "邢台县",
                        simple_name: "邢台",
                        hidden: 0
                    },
                    {
                        value: "130522",
                        label: "临城县",
                        simple_name: "临城",
                        hidden: 0
                    },
                    {
                        value: "130523",
                        label: "内丘县",
                        simple_name: "内丘",
                        hidden: 0
                    },
                    {
                        value: "130524",
                        label: "柏乡县",
                        simple_name: "柏乡",
                        hidden: 0
                    },
                    {
                        value: "130525",
                        label: "隆尧县",
                        simple_name: "隆尧",
                        hidden: 0
                    },
                    {
                        value: "130526",
                        label: "任县",
                        simple_name: "任县",
                        hidden: 0
                    },
                    {
                        value: "130527",
                        label: "南和县",
                        simple_name: "南和",
                        hidden: 0
                    },
                    {
                        value: "130528",
                        label: "宁晋县",
                        simple_name: "宁晋",
                        hidden: 0
                    },
                    {
                        value: "130529",
                        label: "巨鹿县",
                        simple_name: "巨鹿",
                        hidden: 0
                    },
                    {
                        value: "130530",
                        label: "新河县",
                        simple_name: "新河",
                        hidden: 0
                    },
                    {
                        value: "130531",
                        label: "广宗县",
                        simple_name: "广宗",
                        hidden: 0
                    },
                    {
                        value: "130532",
                        label: "平乡县",
                        simple_name: "平乡",
                        hidden: 0
                    },
                    {
                        value: "130533",
                        label: "威县",
                        simple_name: "威县",
                        hidden: 0
                    },
                    {
                        value: "130534",
                        label: "清河县",
                        simple_name: "清河",
                        hidden: 0
                    },
                    {
                        value: "130535",
                        label: "临西县",
                        simple_name: "临西",
                        hidden: 0
                    },
                    {
                        value: "130581",
                        label: "南宫市",
                        simple_name: "南宫",
                        hidden: 0
                    },
                    {
                        value: "130582",
                        label: "沙河市",
                        simple_name: "沙河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130600",
                label: "保定市",
                simple_name: "保定",
                hidden: 0,
                children: [
                    {
                        value: "130602",
                        label: "新市区",
                        simple_name: "新市",
                        hidden: 0
                    },
                    {
                        value: "130603",
                        label: "北市区",
                        simple_name: "北市",
                        hidden: 0
                    },
                    {
                        value: "130604",
                        label: "南市区",
                        simple_name: "南市",
                        hidden: 0
                    },
                    {
                        value: "130621",
                        label: "满城县",
                        simple_name: "满城",
                        hidden: 0
                    },
                    {
                        value: "130622",
                        label: "清苑县",
                        simple_name: "清苑",
                        hidden: 0
                    },
                    {
                        value: "130623",
                        label: "涞水县",
                        simple_name: "涞水",
                        hidden: 0
                    },
                    {
                        value: "130624",
                        label: "阜平县",
                        simple_name: "阜平",
                        hidden: 0
                    },
                    {
                        value: "130625",
                        label: "徐水县",
                        simple_name: "徐水",
                        hidden: 0
                    },
                    {
                        value: "130626",
                        label: "定兴县",
                        simple_name: "定兴",
                        hidden: 0
                    },
                    {
                        value: "130627",
                        label: "唐县",
                        simple_name: "唐县",
                        hidden: 0
                    },
                    {
                        value: "130628",
                        label: "高阳县",
                        simple_name: "高阳",
                        hidden: 0
                    },
                    {
                        value: "130629",
                        label: "容城县",
                        simple_name: "容城",
                        hidden: 0
                    },
                    {
                        value: "130630",
                        label: "涞源县",
                        simple_name: "涞源",
                        hidden: 0
                    },
                    {
                        value: "130631",
                        label: "望都县",
                        simple_name: "望都",
                        hidden: 0
                    },
                    {
                        value: "130632",
                        label: "安新县",
                        simple_name: "安新",
                        hidden: 0
                    },
                    {
                        value: "130633",
                        label: "易县",
                        simple_name: "易县",
                        hidden: 0
                    },
                    {
                        value: "130634",
                        label: "曲阳县",
                        simple_name: "曲阳",
                        hidden: 0
                    },
                    {
                        value: "130635",
                        label: "蠡县",
                        simple_name: "蠡县",
                        hidden: 0
                    },
                    {
                        value: "130636",
                        label: "顺平县",
                        simple_name: "顺平",
                        hidden: 0
                    },
                    {
                        value: "130637",
                        label: "博野县",
                        simple_name: "博野",
                        hidden: 0
                    },
                    {
                        value: "130638",
                        label: "雄县",
                        simple_name: "雄县",
                        hidden: 0
                    },
                    {
                        value: "130681",
                        label: "涿州市",
                        simple_name: "涿州",
                        hidden: 0
                    },
                    {
                        value: "130682",
                        label: "定州市",
                        simple_name: "定州",
                        hidden: 0
                    },
                    {
                        value: "130683",
                        label: "安国市",
                        simple_name: "安国",
                        hidden: 0
                    },
                    {
                        value: "130684",
                        label: "高碑店市",
                        simple_name: "高碑店",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130700",
                label: "张家口市",
                simple_name: "张家口",
                hidden: 0,
                children: [
                    {
                        value: "130702",
                        label: "桥东区",
                        simple_name: "桥东",
                        hidden: 0
                    },
                    {
                        value: "130703",
                        label: "桥西区",
                        simple_name: "桥西",
                        hidden: 0
                    },
                    {
                        value: "130705",
                        label: "宣化区",
                        simple_name: "宣化",
                        hidden: 0
                    },
                    {
                        value: "130706",
                        label: "下花园区",
                        simple_name: "下花园",
                        hidden: 0
                    },
                    {
                        value: "130721",
                        label: "宣化县",
                        simple_name: "宣化",
                        hidden: 0
                    },
                    {
                        value: "130722",
                        label: "张北县",
                        simple_name: "张北",
                        hidden: 0
                    },
                    {
                        value: "130723",
                        label: "康保县",
                        simple_name: "康保",
                        hidden: 0
                    },
                    {
                        value: "130724",
                        label: "沽源县",
                        simple_name: "沽源",
                        hidden: 0
                    },
                    {
                        value: "130725",
                        label: "尚义县",
                        simple_name: "尚义",
                        hidden: 0
                    },
                    {
                        value: "130726",
                        label: "蔚县",
                        simple_name: "蔚县",
                        hidden: 0
                    },
                    {
                        value: "130727",
                        label: "阳原县",
                        simple_name: "阳原",
                        hidden: 0
                    },
                    {
                        value: "130728",
                        label: "怀安县",
                        simple_name: "怀安",
                        hidden: 0
                    },
                    {
                        value: "130729",
                        label: "万全县",
                        simple_name: "万全",
                        hidden: 0
                    },
                    {
                        value: "130730",
                        label: "怀来县",
                        simple_name: "怀来",
                        hidden: 0
                    },
                    {
                        value: "130731",
                        label: "涿鹿县",
                        simple_name: "涿鹿",
                        hidden: 0
                    },
                    {
                        value: "130732",
                        label: "赤城县",
                        simple_name: "赤城",
                        hidden: 0
                    },
                    {
                        value: "130733",
                        label: "崇礼县",
                        simple_name: "崇礼",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130800",
                label: "承德市",
                simple_name: "承德",
                hidden: 0,
                children: [
                    {
                        value: "130802",
                        label: "双桥区",
                        simple_name: "双桥",
                        hidden: 0
                    },
                    {
                        value: "130803",
                        label: "双滦区",
                        simple_name: "双滦",
                        hidden: 0
                    },
                    {
                        value: "130804",
                        label: "鹰手营子矿区",
                        simple_name: "鹰手营子矿区",
                        hidden: 0
                    },
                    {
                        value: "130821",
                        label: "承德县",
                        simple_name: "承德",
                        hidden: 0
                    },
                    {
                        value: "130822",
                        label: "兴隆县",
                        simple_name: "兴隆",
                        hidden: 0
                    },
                    {
                        value: "130823",
                        label: "平泉县",
                        simple_name: "平泉",
                        hidden: 0
                    },
                    {
                        value: "130824",
                        label: "滦平县",
                        simple_name: "滦平",
                        hidden: 0
                    },
                    {
                        value: "130825",
                        label: "隆化县",
                        simple_name: "隆化",
                        hidden: 0
                    },
                    {
                        value: "130826",
                        label: "丰宁满族自治县",
                        simple_name: "丰宁",
                        hidden: 0
                    },
                    {
                        value: "130827",
                        label: "宽城满族自治县",
                        simple_name: "宽城",
                        hidden: 0
                    },
                    {
                        value: "130828",
                        label: "围场满族蒙古族自治县",
                        simple_name: "围场",
                        hidden: 0
                    }
                ]
            },
            {
                value: "130900",
                label: "沧州市",
                simple_name: "沧州",
                hidden: 0,
                children: [
                    {
                        value: "130902",
                        label: "新华区",
                        simple_name: "新华",
                        hidden: 0
                    },
                    {
                        value: "130903",
                        label: "运河区",
                        simple_name: "运河",
                        hidden: 0
                    },
                    {
                        value: "130921",
                        label: "沧县",
                        simple_name: "沧县",
                        hidden: 0
                    },
                    {
                        value: "130922",
                        label: "青县",
                        simple_name: "青县",
                        hidden: 0
                    },
                    {
                        value: "130923",
                        label: "东光县",
                        simple_name: "东光",
                        hidden: 0
                    },
                    {
                        value: "130924",
                        label: "海兴县",
                        simple_name: "海兴",
                        hidden: 0
                    },
                    {
                        value: "130925",
                        label: "盐山县",
                        simple_name: "盐山",
                        hidden: 0
                    },
                    {
                        value: "130926",
                        label: "肃宁县",
                        simple_name: "肃宁",
                        hidden: 0
                    },
                    {
                        value: "130927",
                        label: "南皮县",
                        simple_name: "南皮",
                        hidden: 0
                    },
                    {
                        value: "130928",
                        label: "吴桥县",
                        simple_name: "吴桥",
                        hidden: 0
                    },
                    {
                        value: "130929",
                        label: "献县",
                        simple_name: "献县",
                        hidden: 0
                    },
                    {
                        value: "130930",
                        label: "孟村回族自治县",
                        simple_name: "孟村",
                        hidden: 0
                    },
                    {
                        value: "130981",
                        label: "泊头市",
                        simple_name: "泊头",
                        hidden: 0
                    },
                    {
                        value: "130982",
                        label: "任丘市",
                        simple_name: "任丘",
                        hidden: 0
                    },
                    {
                        value: "130983",
                        label: "黄骅市",
                        simple_name: "黄骅",
                        hidden: 0
                    },
                    {
                        value: "130984",
                        label: "河间市",
                        simple_name: "河间",
                        hidden: 0
                    }
                ]
            },
            {
                value: "131000",
                label: "廊坊市",
                simple_name: "廊坊",
                hidden: 0,
                children: [
                    {
                        value: "131002",
                        label: "安次区",
                        simple_name: "安次",
                        hidden: 0
                    },
                    {
                        value: "131003",
                        label: "广阳区",
                        simple_name: "广阳",
                        hidden: 0
                    },
                    {
                        value: "131022",
                        label: "固安县",
                        simple_name: "固安",
                        hidden: 0
                    },
                    {
                        value: "131023",
                        label: "永清县",
                        simple_name: "永清",
                        hidden: 0
                    },
                    {
                        value: "131024",
                        label: "香河县",
                        simple_name: "香河",
                        hidden: 0
                    },
                    {
                        value: "131025",
                        label: "大城县",
                        simple_name: "大城",
                        hidden: 0
                    },
                    {
                        value: "131026",
                        label: "文安县",
                        simple_name: "文安",
                        hidden: 0
                    },
                    {
                        value: "131028",
                        label: "大厂回族自治县",
                        simple_name: "大厂",
                        hidden: 0
                    },
                    {
                        value: "131081",
                        label: "霸州市",
                        simple_name: "霸州",
                        hidden: 0
                    },
                    {
                        value: "131082",
                        label: "三河市",
                        simple_name: "三河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "131100",
                label: "衡水市",
                simple_name: "衡水",
                hidden: 0,
                children: [
                    {
                        value: "131102",
                        label: "桃城区",
                        simple_name: "桃城",
                        hidden: 0
                    },
                    {
                        value: "131121",
                        label: "枣强县",
                        simple_name: "枣强",
                        hidden: 0
                    },
                    {
                        value: "131122",
                        label: "武邑县",
                        simple_name: "武邑",
                        hidden: 0
                    },
                    {
                        value: "131123",
                        label: "武强县",
                        simple_name: "武强",
                        hidden: 0
                    },
                    {
                        value: "131124",
                        label: "饶阳县",
                        simple_name: "饶阳",
                        hidden: 0
                    },
                    {
                        value: "131125",
                        label: "安平县",
                        simple_name: "安平",
                        hidden: 0
                    },
                    {
                        value: "131126",
                        label: "故城县",
                        simple_name: "故城",
                        hidden: 0
                    },
                    {
                        value: "131127",
                        label: "景县",
                        simple_name: "景县",
                        hidden: 0
                    },
                    {
                        value: "131128",
                        label: "阜城县",
                        simple_name: "阜城",
                        hidden: 0
                    },
                    {
                        value: "131181",
                        label: "冀州市",
                        simple_name: "冀州",
                        hidden: 0
                    },
                    {
                        value: "131182",
                        label: "深州市",
                        simple_name: "深州",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "140000",
        label: "山西省",
        simple_name: "山西",
        hidden: 0,
        children: [
            {
                value: "140100",
                label: "太原市",
                simple_name: "太原",
                hidden: 0,
                children: [
                    {
                        value: "140105",
                        label: "小店区",
                        simple_name: "小店",
                        hidden: 0
                    },
                    {
                        value: "140106",
                        label: "迎泽区",
                        simple_name: "迎泽",
                        hidden: 0
                    },
                    {
                        value: "140107",
                        label: "杏花岭区",
                        simple_name: "杏花岭",
                        hidden: 0
                    },
                    {
                        value: "140108",
                        label: "尖草坪区",
                        simple_name: "尖草坪",
                        hidden: 0
                    },
                    {
                        value: "140109",
                        label: "万柏林区",
                        simple_name: "万柏林",
                        hidden: 0
                    },
                    {
                        value: "140110",
                        label: "晋源区",
                        simple_name: "晋源",
                        hidden: 0
                    },
                    {
                        value: "140121",
                        label: "清徐县",
                        simple_name: "清徐",
                        hidden: 0
                    },
                    {
                        value: "140122",
                        label: "阳曲县",
                        simple_name: "阳曲",
                        hidden: 0
                    },
                    {
                        value: "140123",
                        label: "娄烦县",
                        simple_name: "娄烦",
                        hidden: 0
                    },
                    {
                        value: "140181",
                        label: "古交市",
                        simple_name: "古交",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140200",
                label: "大同市",
                simple_name: "大同",
                hidden: 0,
                children: [
                    {
                        value: "140202",
                        label: "城区",
                        simple_name: "城区",
                        hidden: 0
                    },
                    {
                        value: "140203",
                        label: "矿区",
                        simple_name: "矿区",
                        hidden: 0
                    },
                    {
                        value: "140211",
                        label: "南郊区",
                        simple_name: "南郊",
                        hidden: 0
                    },
                    {
                        value: "140212",
                        label: "新荣区",
                        simple_name: "新荣",
                        hidden: 0
                    },
                    {
                        value: "140221",
                        label: "阳高县",
                        simple_name: "阳高",
                        hidden: 0
                    },
                    {
                        value: "140222",
                        label: "天镇县",
                        simple_name: "天镇",
                        hidden: 0
                    },
                    {
                        value: "140223",
                        label: "广灵县",
                        simple_name: "广灵",
                        hidden: 0
                    },
                    {
                        value: "140224",
                        label: "灵丘县",
                        simple_name: "灵丘",
                        hidden: 0
                    },
                    {
                        value: "140225",
                        label: "浑源县",
                        simple_name: "浑源",
                        hidden: 0
                    },
                    {
                        value: "140226",
                        label: "左云县",
                        simple_name: "左云",
                        hidden: 0
                    },
                    {
                        value: "140227",
                        label: "大同县",
                        simple_name: "大同",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140300",
                label: "阳泉市",
                simple_name: "阳泉",
                hidden: 0,
                children: [
                    {
                        value: "140302",
                        label: "城区",
                        simple_name: "城区",
                        hidden: 0
                    },
                    {
                        value: "140303",
                        label: "矿区",
                        simple_name: "矿区",
                        hidden: 0
                    },
                    {
                        value: "140311",
                        label: "郊区",
                        simple_name: "郊区",
                        hidden: 0
                    },
                    {
                        value: "140321",
                        label: "平定县",
                        simple_name: "平定",
                        hidden: 0
                    },
                    {
                        value: "140322",
                        label: "盂县",
                        simple_name: "盂县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140400",
                label: "长治市",
                simple_name: "长治",
                hidden: 0,
                children: [
                    {
                        value: "140402",
                        label: "城区",
                        simple_name: "城区",
                        hidden: 0
                    },
                    {
                        value: "140411",
                        label: "郊区",
                        simple_name: "郊区",
                        hidden: 0
                    },
                    {
                        value: "140421",
                        label: "长治县",
                        simple_name: "长治",
                        hidden: 0
                    },
                    {
                        value: "140423",
                        label: "襄垣县",
                        simple_name: "襄垣",
                        hidden: 0
                    },
                    {
                        value: "140424",
                        label: "屯留县",
                        simple_name: "屯留",
                        hidden: 0
                    },
                    {
                        value: "140425",
                        label: "平顺县",
                        simple_name: "平顺",
                        hidden: 0
                    },
                    {
                        value: "140426",
                        label: "黎城县",
                        simple_name: "黎城",
                        hidden: 0
                    },
                    {
                        value: "140427",
                        label: "壶关县",
                        simple_name: "壶关",
                        hidden: 0
                    },
                    {
                        value: "140428",
                        label: "长子县",
                        simple_name: "长子",
                        hidden: 0
                    },
                    {
                        value: "140429",
                        label: "武乡县",
                        simple_name: "武乡",
                        hidden: 0
                    },
                    {
                        value: "140430",
                        label: "沁县",
                        simple_name: "沁县",
                        hidden: 0
                    },
                    {
                        value: "140431",
                        label: "沁源县",
                        simple_name: "沁源",
                        hidden: 0
                    },
                    {
                        value: "140481",
                        label: "潞城市",
                        simple_name: "潞城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140500",
                label: "晋城市",
                simple_name: "晋城",
                hidden: 0,
                children: [
                    {
                        value: "140502",
                        label: "城区",
                        simple_name: "城区",
                        hidden: 0
                    },
                    {
                        value: "140521",
                        label: "沁水县",
                        simple_name: "沁水",
                        hidden: 0
                    },
                    {
                        value: "140522",
                        label: "阳城县",
                        simple_name: "阳城",
                        hidden: 0
                    },
                    {
                        value: "140524",
                        label: "陵川县",
                        simple_name: "陵川",
                        hidden: 0
                    },
                    {
                        value: "140525",
                        label: "泽州县",
                        simple_name: "泽州",
                        hidden: 0
                    },
                    {
                        value: "140581",
                        label: "高平市",
                        simple_name: "高平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140600",
                label: "朔州市",
                simple_name: "朔州",
                hidden: 0,
                children: [
                    {
                        value: "140602",
                        label: "朔城区",
                        simple_name: "朔城",
                        hidden: 0
                    },
                    {
                        value: "140603",
                        label: "平鲁区",
                        simple_name: "平鲁",
                        hidden: 0
                    },
                    {
                        value: "140621",
                        label: "山阴县",
                        simple_name: "山阴",
                        hidden: 0
                    },
                    {
                        value: "140622",
                        label: "应县",
                        simple_name: "应县",
                        hidden: 0
                    },
                    {
                        value: "140623",
                        label: "右玉县",
                        simple_name: "右玉",
                        hidden: 0
                    },
                    {
                        value: "140624",
                        label: "怀仁县",
                        simple_name: "怀仁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140700",
                label: "晋中市",
                simple_name: "晋中",
                hidden: 0,
                children: [
                    {
                        value: "140702",
                        label: "榆次区",
                        simple_name: "榆次",
                        hidden: 0
                    },
                    {
                        value: "140721",
                        label: "榆社县",
                        simple_name: "榆社",
                        hidden: 0
                    },
                    {
                        value: "140722",
                        label: "左权县",
                        simple_name: "左权",
                        hidden: 0
                    },
                    {
                        value: "140723",
                        label: "和顺县",
                        simple_name: "和顺",
                        hidden: 0
                    },
                    {
                        value: "140724",
                        label: "昔阳县",
                        simple_name: "昔阳",
                        hidden: 0
                    },
                    {
                        value: "140725",
                        label: "寿阳县",
                        simple_name: "寿阳",
                        hidden: 0
                    },
                    {
                        value: "140726",
                        label: "太谷县",
                        simple_name: "太谷",
                        hidden: 0
                    },
                    {
                        value: "140727",
                        label: "祁县",
                        simple_name: "祁县",
                        hidden: 0
                    },
                    {
                        value: "140728",
                        label: "平遥县",
                        simple_name: "平遥",
                        hidden: 0
                    },
                    {
                        value: "140729",
                        label: "灵石县",
                        simple_name: "灵石",
                        hidden: 0
                    },
                    {
                        value: "140781",
                        label: "介休市",
                        simple_name: "介休",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140800",
                label: "运城市",
                simple_name: "运城",
                hidden: 0,
                children: [
                    {
                        value: "140802",
                        label: "盐湖区",
                        simple_name: "盐湖",
                        hidden: 0
                    },
                    {
                        value: "140821",
                        label: "临猗县",
                        simple_name: "临猗",
                        hidden: 0
                    },
                    {
                        value: "140822",
                        label: "万荣县",
                        simple_name: "万荣",
                        hidden: 0
                    },
                    {
                        value: "140823",
                        label: "闻喜县",
                        simple_name: "闻喜",
                        hidden: 0
                    },
                    {
                        value: "140824",
                        label: "稷山县",
                        simple_name: "稷山",
                        hidden: 0
                    },
                    {
                        value: "140825",
                        label: "新绛县",
                        simple_name: "新绛",
                        hidden: 0
                    },
                    {
                        value: "140826",
                        label: "绛县",
                        simple_name: "绛县",
                        hidden: 0
                    },
                    {
                        value: "140827",
                        label: "垣曲县",
                        simple_name: "垣曲",
                        hidden: 0
                    },
                    {
                        value: "140828",
                        label: "夏县",
                        simple_name: "夏县",
                        hidden: 0
                    },
                    {
                        value: "140829",
                        label: "平陆县",
                        simple_name: "平陆",
                        hidden: 0
                    },
                    {
                        value: "140830",
                        label: "芮城县",
                        simple_name: "芮城",
                        hidden: 0
                    },
                    {
                        value: "140881",
                        label: "永济市",
                        simple_name: "永济",
                        hidden: 0
                    },
                    {
                        value: "140882",
                        label: "河津市",
                        simple_name: "河津",
                        hidden: 0
                    }
                ]
            },
            {
                value: "140900",
                label: "忻州市",
                simple_name: "忻州",
                hidden: 0,
                children: [
                    {
                        value: "140902",
                        label: "忻府区",
                        simple_name: "忻府",
                        hidden: 0
                    },
                    {
                        value: "140921",
                        label: "定襄县",
                        simple_name: "定襄",
                        hidden: 0
                    },
                    {
                        value: "140922",
                        label: "五台县",
                        simple_name: "五台",
                        hidden: 0
                    },
                    {
                        value: "140923",
                        label: "代县",
                        simple_name: "代县",
                        hidden: 0
                    },
                    {
                        value: "140924",
                        label: "繁峙县",
                        simple_name: "繁峙",
                        hidden: 0
                    },
                    {
                        value: "140925",
                        label: "宁武县",
                        simple_name: "宁武",
                        hidden: 0
                    },
                    {
                        value: "140926",
                        label: "静乐县",
                        simple_name: "静乐",
                        hidden: 0
                    },
                    {
                        value: "140927",
                        label: "神池县",
                        simple_name: "神池",
                        hidden: 0
                    },
                    {
                        value: "140928",
                        label: "五寨县",
                        simple_name: "五寨",
                        hidden: 0
                    },
                    {
                        value: "140929",
                        label: "岢岚县",
                        simple_name: "岢岚",
                        hidden: 0
                    },
                    {
                        value: "140930",
                        label: "河曲县",
                        simple_name: "河曲",
                        hidden: 0
                    },
                    {
                        value: "140931",
                        label: "保德县",
                        simple_name: "保德",
                        hidden: 0
                    },
                    {
                        value: "140932",
                        label: "偏关县",
                        simple_name: "偏关",
                        hidden: 0
                    },
                    {
                        value: "140981",
                        label: "原平市",
                        simple_name: "原平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "141000",
                label: "临汾市",
                simple_name: "临汾",
                hidden: 0,
                children: [
                    {
                        value: "141002",
                        label: "尧都区",
                        simple_name: "尧都",
                        hidden: 0
                    },
                    {
                        value: "141021",
                        label: "曲沃县",
                        simple_name: "曲沃",
                        hidden: 0
                    },
                    {
                        value: "141022",
                        label: "翼城县",
                        simple_name: "翼城",
                        hidden: 0
                    },
                    {
                        value: "141023",
                        label: "襄汾县",
                        simple_name: "襄汾",
                        hidden: 0
                    },
                    {
                        value: "141024",
                        label: "洪洞县",
                        simple_name: "洪洞",
                        hidden: 0
                    },
                    {
                        value: "141025",
                        label: "古县",
                        simple_name: "古县",
                        hidden: 0
                    },
                    {
                        value: "141026",
                        label: "安泽县",
                        simple_name: "安泽",
                        hidden: 0
                    },
                    {
                        value: "141027",
                        label: "浮山县",
                        simple_name: "浮山",
                        hidden: 0
                    },
                    {
                        value: "141028",
                        label: "吉县",
                        simple_name: "吉县",
                        hidden: 0
                    },
                    {
                        value: "141029",
                        label: "乡宁县",
                        simple_name: "乡宁",
                        hidden: 0
                    },
                    {
                        value: "141030",
                        label: "大宁县",
                        simple_name: "大宁",
                        hidden: 0
                    },
                    {
                        value: "141031",
                        label: "隰县",
                        simple_name: "隰县",
                        hidden: 0
                    },
                    {
                        value: "141032",
                        label: "永和县",
                        simple_name: "永和",
                        hidden: 0
                    },
                    {
                        value: "141033",
                        label: "蒲县",
                        simple_name: "蒲县",
                        hidden: 0
                    },
                    {
                        value: "141034",
                        label: "汾西县",
                        simple_name: "汾西",
                        hidden: 0
                    },
                    {
                        value: "141081",
                        label: "侯马市",
                        simple_name: "侯马",
                        hidden: 0
                    },
                    {
                        value: "141082",
                        label: "霍州市",
                        simple_name: "霍州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "141100",
                label: "吕梁市",
                simple_name: "吕梁",
                hidden: 0,
                children: [
                    {
                        value: "141102",
                        label: "离石区",
                        simple_name: "离石",
                        hidden: 0
                    },
                    {
                        value: "141121",
                        label: "文水县",
                        simple_name: "文水",
                        hidden: 0
                    },
                    {
                        value: "141122",
                        label: "交城县",
                        simple_name: "交城",
                        hidden: 0
                    },
                    {
                        value: "141123",
                        label: "兴县",
                        simple_name: "兴县",
                        hidden: 0
                    },
                    {
                        value: "141124",
                        label: "临县",
                        simple_name: "临县",
                        hidden: 0
                    },
                    {
                        value: "141125",
                        label: "柳林县",
                        simple_name: "柳林",
                        hidden: 0
                    },
                    {
                        value: "141126",
                        label: "石楼县",
                        simple_name: "石楼",
                        hidden: 0
                    },
                    {
                        value: "141127",
                        label: "岚县",
                        simple_name: "岚县",
                        hidden: 0
                    },
                    {
                        value: "141128",
                        label: "方山县",
                        simple_name: "方山",
                        hidden: 0
                    },
                    {
                        value: "141129",
                        label: "中阳县",
                        simple_name: "中阳",
                        hidden: 0
                    },
                    {
                        value: "141130",
                        label: "交口县",
                        simple_name: "交口",
                        hidden: 0
                    },
                    {
                        value: "141181",
                        label: "孝义市",
                        simple_name: "孝义",
                        hidden: 0
                    },
                    {
                        value: "141182",
                        label: "汾阳市",
                        simple_name: "汾阳",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "150000",
        label: "内蒙古自治区",
        simple_name: "内蒙古",
        hidden: 0,
        children: [
            {
                value: "150100",
                label: "呼和浩特市",
                simple_name: "呼和浩特",
                hidden: 0,
                children: [
                    {
                        value: "150102",
                        label: "新城区",
                        simple_name: "新城",
                        hidden: 0
                    },
                    {
                        value: "150103",
                        label: "回民区",
                        simple_name: "回民",
                        hidden: 0
                    },
                    {
                        value: "150104",
                        label: "玉泉区",
                        simple_name: "玉泉",
                        hidden: 0
                    },
                    {
                        value: "150105",
                        label: "赛罕区",
                        simple_name: "赛罕",
                        hidden: 0
                    },
                    {
                        value: "150121",
                        label: "土默特左旗",
                        simple_name: "土默特左旗",
                        hidden: 0
                    },
                    {
                        value: "150122",
                        label: "托克托县",
                        simple_name: "托克托",
                        hidden: 0
                    },
                    {
                        value: "150123",
                        label: "和林格尔县",
                        simple_name: "和林格尔",
                        hidden: 0
                    },
                    {
                        value: "150124",
                        label: "清水河县",
                        simple_name: "清水河",
                        hidden: 0
                    },
                    {
                        value: "150125",
                        label: "武川县",
                        simple_name: "武川",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150200",
                label: "包头市",
                simple_name: "包头",
                hidden: 0,
                children: [
                    {
                        value: "150202",
                        label: "东河区",
                        simple_name: "东河",
                        hidden: 0
                    },
                    {
                        value: "150203",
                        label: "昆都仑区",
                        simple_name: "昆都仑",
                        hidden: 0
                    },
                    {
                        value: "150204",
                        label: "青山区",
                        simple_name: "青山",
                        hidden: 0
                    },
                    {
                        value: "150205",
                        label: "石拐区",
                        simple_name: "石拐",
                        hidden: 0
                    },
                    {
                        value: "150206",
                        label: "白云鄂博矿区",
                        simple_name: "白云鄂博矿区",
                        hidden: 0
                    },
                    {
                        value: "150207",
                        label: "九原区",
                        simple_name: "九原",
                        hidden: 0
                    },
                    {
                        value: "150221",
                        label: "土默特右旗",
                        simple_name: "土默特右旗",
                        hidden: 0
                    },
                    {
                        value: "150222",
                        label: "固阳县",
                        simple_name: "固阳",
                        hidden: 0
                    },
                    {
                        value: "150223",
                        label: "达尔罕茂明安联合旗",
                        simple_name: "达茂旗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150300",
                label: "乌海市",
                simple_name: "乌海",
                hidden: 0,
                children: [
                    {
                        value: "150302",
                        label: "海勃湾区",
                        simple_name: "海勃湾",
                        hidden: 0
                    },
                    {
                        value: "150303",
                        label: "海南区",
                        simple_name: "海南",
                        hidden: 0
                    },
                    {
                        value: "150304",
                        label: "乌达区",
                        simple_name: "乌达",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150400",
                label: "赤峰市",
                simple_name: "赤峰",
                hidden: 0,
                children: [
                    {
                        value: "150402",
                        label: "红山区",
                        simple_name: "红山",
                        hidden: 0
                    },
                    {
                        value: "150403",
                        label: "元宝山区",
                        simple_name: "元宝山",
                        hidden: 0
                    },
                    {
                        value: "150404",
                        label: "松山区",
                        simple_name: "松山",
                        hidden: 0
                    },
                    {
                        value: "150421",
                        label: "阿鲁科尔沁旗",
                        simple_name: "阿鲁科尔沁旗",
                        hidden: 0
                    },
                    {
                        value: "150422",
                        label: "巴林左旗",
                        simple_name: "巴林左旗",
                        hidden: 0
                    },
                    {
                        value: "150423",
                        label: "巴林右旗",
                        simple_name: "巴林右旗",
                        hidden: 0
                    },
                    {
                        value: "150424",
                        label: "林西县",
                        simple_name: "林西",
                        hidden: 0
                    },
                    {
                        value: "150425",
                        label: "克什克腾旗",
                        simple_name: "克什克腾旗",
                        hidden: 0
                    },
                    {
                        value: "150426",
                        label: "翁牛特旗",
                        simple_name: "翁牛特旗",
                        hidden: 0
                    },
                    {
                        value: "150428",
                        label: "喀喇沁旗",
                        simple_name: "喀喇沁旗",
                        hidden: 0
                    },
                    {
                        value: "150429",
                        label: "宁城县",
                        simple_name: "宁城",
                        hidden: 0
                    },
                    {
                        value: "150430",
                        label: "敖汉旗",
                        simple_name: "敖汉旗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150500",
                label: "通辽市",
                simple_name: "通辽",
                hidden: 0,
                children: [
                    {
                        value: "150502",
                        label: "科尔沁区",
                        simple_name: "科尔沁",
                        hidden: 0
                    },
                    {
                        value: "150521",
                        label: "科尔沁左翼中旗",
                        simple_name: "科尔沁左翼中旗",
                        hidden: 0
                    },
                    {
                        value: "150522",
                        label: "科尔沁左翼后旗",
                        simple_name: "科尔沁左翼后旗",
                        hidden: 0
                    },
                    {
                        value: "150523",
                        label: "开鲁县",
                        simple_name: "开鲁",
                        hidden: 0
                    },
                    {
                        value: "150524",
                        label: "库伦旗",
                        simple_name: "库伦旗",
                        hidden: 0
                    },
                    {
                        value: "150525",
                        label: "奈曼旗",
                        simple_name: "奈曼旗",
                        hidden: 0
                    },
                    {
                        value: "150526",
                        label: "扎鲁特旗",
                        simple_name: "扎鲁特旗",
                        hidden: 0
                    },
                    {
                        value: "150581",
                        label: "霍林郭勒市",
                        simple_name: "霍林郭勒",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150600",
                label: "鄂尔多斯市",
                simple_name: "鄂尔多斯",
                hidden: 0,
                children: [
                    {
                        value: "150602",
                        label: "东胜区",
                        simple_name: "东胜",
                        hidden: 0
                    },
                    {
                        value: "150621",
                        label: "达拉特旗",
                        simple_name: "达拉特旗",
                        hidden: 0
                    },
                    {
                        value: "150622",
                        label: "准格尔旗",
                        simple_name: "准格尔旗",
                        hidden: 0
                    },
                    {
                        value: "150623",
                        label: "鄂托克前旗",
                        simple_name: "鄂托克前旗",
                        hidden: 0
                    },
                    {
                        value: "150624",
                        label: "鄂托克旗",
                        simple_name: "鄂托克旗",
                        hidden: 0
                    },
                    {
                        value: "150625",
                        label: "杭锦旗",
                        simple_name: "杭锦旗",
                        hidden: 0
                    },
                    {
                        value: "150626",
                        label: "乌审旗",
                        simple_name: "乌审旗",
                        hidden: 0
                    },
                    {
                        value: "150627",
                        label: "伊金霍洛旗",
                        simple_name: "伊金霍洛旗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150700",
                label: "呼伦贝尔市",
                simple_name: "呼伦贝尔",
                hidden: 0,
                children: [
                    {
                        value: "150702",
                        label: "海拉尔区",
                        simple_name: "海拉尔",
                        hidden: 0
                    },
                    {
                        value: "150703",
                        label: "扎赉诺尔区",
                        simple_name: "扎赉诺尔",
                        hidden: 0
                    },
                    {
                        value: "150721",
                        label: "阿荣旗",
                        simple_name: "阿荣旗",
                        hidden: 0
                    },
                    {
                        value: "150722",
                        label: "莫力达瓦达斡尔族自治旗",
                        simple_name: "莫旗",
                        hidden: 0
                    },
                    {
                        value: "150723",
                        label: "鄂伦春自治旗",
                        simple_name: "鄂伦春",
                        hidden: 0
                    },
                    {
                        value: "150724",
                        label: "鄂温克族自治旗",
                        simple_name: "鄂温",
                        hidden: 0
                    },
                    {
                        value: "150725",
                        label: "陈巴尔虎旗",
                        simple_name: "陈巴尔虎旗",
                        hidden: 0
                    },
                    {
                        value: "150726",
                        label: "新巴尔虎左旗",
                        simple_name: "新巴尔虎左旗",
                        hidden: 0
                    },
                    {
                        value: "150727",
                        label: "新巴尔虎右旗",
                        simple_name: "新巴尔虎右旗",
                        hidden: 0
                    },
                    {
                        value: "150781",
                        label: "满洲里市",
                        simple_name: "满洲里",
                        hidden: 0
                    },
                    {
                        value: "150782",
                        label: "牙克石市",
                        simple_name: "牙克石",
                        hidden: 0
                    },
                    {
                        value: "150783",
                        label: "扎兰屯市",
                        simple_name: "扎兰屯",
                        hidden: 0
                    },
                    {
                        value: "150784",
                        label: "额尔古纳市",
                        simple_name: "额尔古纳",
                        hidden: 0
                    },
                    {
                        value: "150785",
                        label: "根河市",
                        simple_name: "根河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150800",
                label: "巴彦淖尔市",
                simple_name: "巴彦淖尔",
                hidden: 0,
                children: [
                    {
                        value: "150802",
                        label: "临河区",
                        simple_name: "临河",
                        hidden: 0
                    },
                    {
                        value: "150821",
                        label: "五原县",
                        simple_name: "五原",
                        hidden: 0
                    },
                    {
                        value: "150822",
                        label: "磴口县",
                        simple_name: "磴口",
                        hidden: 0
                    },
                    {
                        value: "150823",
                        label: "乌拉特前旗",
                        simple_name: "乌拉特前旗",
                        hidden: 0
                    },
                    {
                        value: "150824",
                        label: "乌拉特中旗",
                        simple_name: "乌拉特中旗",
                        hidden: 0
                    },
                    {
                        value: "150825",
                        label: "乌拉特后旗",
                        simple_name: "乌拉特后旗",
                        hidden: 0
                    },
                    {
                        value: "150826",
                        label: "杭锦后旗",
                        simple_name: "杭锦后旗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "150900",
                label: "乌兰察布市",
                simple_name: "乌兰察布",
                hidden: 0,
                children: [
                    {
                        value: "150902",
                        label: "集宁区",
                        simple_name: "集宁",
                        hidden: 0
                    },
                    {
                        value: "150921",
                        label: "卓资县",
                        simple_name: "卓资",
                        hidden: 0
                    },
                    {
                        value: "150922",
                        label: "化德县",
                        simple_name: "化德",
                        hidden: 0
                    },
                    {
                        value: "150923",
                        label: "商都县",
                        simple_name: "商都",
                        hidden: 0
                    },
                    {
                        value: "150924",
                        label: "兴和县",
                        simple_name: "兴和",
                        hidden: 0
                    },
                    {
                        value: "150925",
                        label: "凉城县",
                        simple_name: "凉城",
                        hidden: 0
                    },
                    {
                        value: "150926",
                        label: "察哈尔右翼前旗",
                        simple_name: "察右前旗",
                        hidden: 0
                    },
                    {
                        value: "150927",
                        label: "察哈尔右翼中旗",
                        simple_name: "察右中旗",
                        hidden: 0
                    },
                    {
                        value: "150928",
                        label: "察哈尔右翼后旗",
                        simple_name: "察右后旗",
                        hidden: 0
                    },
                    {
                        value: "150929",
                        label: "四子王旗",
                        simple_name: "四子王旗",
                        hidden: 0
                    },
                    {
                        value: "150981",
                        label: "丰镇市",
                        simple_name: "丰镇",
                        hidden: 0
                    }
                ]
            },
            {
                value: "152200",
                label: "兴安盟",
                simple_name: "兴安盟",
                hidden: 0,
                children: [
                    {
                        value: "152201",
                        label: "乌兰浩特市",
                        simple_name: "乌兰浩特",
                        hidden: 0
                    },
                    {
                        value: "152202",
                        label: "阿尔山市",
                        simple_name: "阿尔山",
                        hidden: 0
                    },
                    {
                        value: "152221",
                        label: "科尔沁右翼前旗",
                        simple_name: "科右前旗",
                        hidden: 0
                    },
                    {
                        value: "152222",
                        label: "科尔沁右翼中旗",
                        simple_name: "科右中旗",
                        hidden: 0
                    },
                    {
                        value: "152223",
                        label: "扎赉特旗",
                        simple_name: "扎赉特旗",
                        hidden: 0
                    },
                    {
                        value: "152224",
                        label: "突泉县",
                        simple_name: "突泉",
                        hidden: 0
                    }
                ]
            },
            {
                value: "152500",
                label: "锡林郭勒盟",
                simple_name: "锡林郭勒盟",
                hidden: 0,
                children: [
                    {
                        value: "152501",
                        label: "二连浩特市",
                        simple_name: "二连浩特",
                        hidden: 0
                    },
                    {
                        value: "152502",
                        label: "锡林浩特市",
                        simple_name: "锡林浩特",
                        hidden: 0
                    },
                    {
                        value: "152522",
                        label: "阿巴嘎旗",
                        simple_name: "阿巴嘎旗",
                        hidden: 0
                    },
                    {
                        value: "152523",
                        label: "苏尼特左旗",
                        simple_name: "苏尼特左旗",
                        hidden: 0
                    },
                    {
                        value: "152524",
                        label: "苏尼特右旗",
                        simple_name: "苏尼特右旗",
                        hidden: 0
                    },
                    {
                        value: "152525",
                        label: "东乌珠穆沁旗",
                        simple_name: "东乌旗",
                        hidden: 0
                    },
                    {
                        value: "152526",
                        label: "西乌珠穆沁旗",
                        simple_name: "西乌旗",
                        hidden: 0
                    },
                    {
                        value: "152527",
                        label: "太仆寺旗",
                        simple_name: "太仆寺旗",
                        hidden: 0
                    },
                    {
                        value: "152528",
                        label: "镶黄旗",
                        simple_name: "镶黄旗",
                        hidden: 0
                    },
                    {
                        value: "152529",
                        label: "正镶白旗",
                        simple_name: "正镶白旗",
                        hidden: 0
                    },
                    {
                        value: "152530",
                        label: "正蓝旗",
                        simple_name: "正蓝旗",
                        hidden: 0
                    },
                    {
                        value: "152531",
                        label: "多伦县",
                        simple_name: "多伦",
                        hidden: 0
                    }
                ]
            },
            {
                value: "152900",
                label: "阿拉善盟",
                simple_name: "阿拉善盟",
                hidden: 0,
                children: [
                    {
                        value: "152921",
                        label: "阿拉善左旗",
                        simple_name: "阿拉善左旗",
                        hidden: 0
                    },
                    {
                        value: "152922",
                        label: "阿拉善右旗",
                        simple_name: "阿拉善右旗",
                        hidden: 0
                    },
                    {
                        value: "152923",
                        label: "额济纳旗",
                        simple_name: "额济纳旗",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "210000",
        label: "辽宁省",
        simple_name: "辽宁",
        hidden: 0,
        children: [
            {
                value: "210100",
                label: "沈阳市",
                simple_name: "沈阳",
                hidden: 0,
                children: [
                    {
                        value: "210102",
                        label: "和平区",
                        simple_name: "和平",
                        hidden: 0
                    },
                    {
                        value: "210103",
                        label: "沈河区",
                        simple_name: "沈河",
                        hidden: 0
                    },
                    {
                        value: "210104",
                        label: "大东区",
                        simple_name: "大东",
                        hidden: 0
                    },
                    {
                        value: "210105",
                        label: "皇姑区",
                        simple_name: "皇姑",
                        hidden: 0
                    },
                    {
                        value: "210106",
                        label: "铁西区",
                        simple_name: "铁西",
                        hidden: 0
                    },
                    {
                        value: "210111",
                        label: "苏家屯区",
                        simple_name: "苏家屯",
                        hidden: 0
                    },
                    {
                        value: "210112",
                        label: "浑南区",
                        simple_name: "浑南",
                        hidden: 0
                    },
                    {
                        value: "210113",
                        label: "沈北新区",
                        simple_name: "沈北新区",
                        hidden: 0
                    },
                    {
                        value: "210114",
                        label: "于洪区",
                        simple_name: "于洪",
                        hidden: 0
                    },
                    {
                        value: "210122",
                        label: "辽中县",
                        simple_name: "辽中",
                        hidden: 0
                    },
                    {
                        value: "210123",
                        label: "康平县",
                        simple_name: "康平",
                        hidden: 0
                    },
                    {
                        value: "210124",
                        label: "法库县",
                        simple_name: "法库",
                        hidden: 0
                    },
                    {
                        value: "210181",
                        label: "新民市",
                        simple_name: "新民",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210200",
                label: "大连市",
                simple_name: "大连",
                hidden: 0,
                children: [
                    {
                        value: "210202",
                        label: "中山区",
                        simple_name: "中山",
                        hidden: 0
                    },
                    {
                        value: "210203",
                        label: "西岗区",
                        simple_name: "西岗",
                        hidden: 0
                    },
                    {
                        value: "210204",
                        label: "沙河口区",
                        simple_name: "沙河口",
                        hidden: 0
                    },
                    {
                        value: "210211",
                        label: "甘井子区",
                        simple_name: "甘井子",
                        hidden: 0
                    },
                    {
                        value: "210212",
                        label: "旅顺口区",
                        simple_name: "旅顺口",
                        hidden: 0
                    },
                    {
                        value: "210213",
                        label: "金州区",
                        simple_name: "金州",
                        hidden: 0
                    },
                    {
                        value: "210224",
                        label: "长海县",
                        simple_name: "长海",
                        hidden: 0
                    },
                    {
                        value: "210281",
                        label: "瓦房店市",
                        simple_name: "瓦房店",
                        hidden: 0
                    },
                    {
                        value: "210282",
                        label: "普兰店市",
                        simple_name: "普兰店",
                        hidden: 0
                    },
                    {
                        value: "210283",
                        label: "庄河市",
                        simple_name: "庄河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210300",
                label: "鞍山市",
                simple_name: "鞍山",
                hidden: 0,
                children: [
                    {
                        value: "210302",
                        label: "铁东区",
                        simple_name: "铁东",
                        hidden: 0
                    },
                    {
                        value: "210303",
                        label: "铁西区",
                        simple_name: "铁西",
                        hidden: 0
                    },
                    {
                        value: "210304",
                        label: "立山区",
                        simple_name: "立山",
                        hidden: 0
                    },
                    {
                        value: "210311",
                        label: "千山区",
                        simple_name: "千山",
                        hidden: 0
                    },
                    {
                        value: "210321",
                        label: "台安县",
                        simple_name: "台安",
                        hidden: 0
                    },
                    {
                        value: "210323",
                        label: "岫岩满族自治县",
                        simple_name: "岫岩",
                        hidden: 0
                    },
                    {
                        value: "210381",
                        label: "海城市",
                        simple_name: "海城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210400",
                label: "抚顺市",
                simple_name: "抚顺",
                hidden: 0,
                children: [
                    {
                        value: "210402",
                        label: "新抚区",
                        simple_name: "新抚",
                        hidden: 0
                    },
                    {
                        value: "210403",
                        label: "东洲区",
                        simple_name: "东洲",
                        hidden: 0
                    },
                    {
                        value: "210404",
                        label: "望花区",
                        simple_name: "望花",
                        hidden: 0
                    },
                    {
                        value: "210411",
                        label: "顺城区",
                        simple_name: "顺城",
                        hidden: 0
                    },
                    {
                        value: "210421",
                        label: "抚顺县",
                        simple_name: "抚顺",
                        hidden: 0
                    },
                    {
                        value: "210422",
                        label: "新宾满族自治县",
                        simple_name: "新宾",
                        hidden: 0
                    },
                    {
                        value: "210423",
                        label: "清原满族自治县",
                        simple_name: "清原",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210500",
                label: "本溪市",
                simple_name: "本溪",
                hidden: 0,
                children: [
                    {
                        value: "210502",
                        label: "平山区",
                        simple_name: "平山",
                        hidden: 0
                    },
                    {
                        value: "210503",
                        label: "溪湖区",
                        simple_name: "溪湖",
                        hidden: 0
                    },
                    {
                        value: "210504",
                        label: "明山区",
                        simple_name: "明山",
                        hidden: 0
                    },
                    {
                        value: "210505",
                        label: "南芬区",
                        simple_name: "南芬",
                        hidden: 0
                    },
                    {
                        value: "210521",
                        label: "本溪满族自治县",
                        simple_name: "本溪",
                        hidden: 0
                    },
                    {
                        value: "210522",
                        label: "桓仁满族自治县",
                        simple_name: "桓仁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210600",
                label: "丹东市",
                simple_name: "丹东",
                hidden: 0,
                children: [
                    {
                        value: "210602",
                        label: "元宝区",
                        simple_name: "元宝",
                        hidden: 0
                    },
                    {
                        value: "210603",
                        label: "振兴区",
                        simple_name: "振兴",
                        hidden: 0
                    },
                    {
                        value: "210604",
                        label: "振安区",
                        simple_name: "振安",
                        hidden: 0
                    },
                    {
                        value: "210624",
                        label: "宽甸满族自治县",
                        simple_name: "宽甸",
                        hidden: 0
                    },
                    {
                        value: "210681",
                        label: "东港市",
                        simple_name: "东港",
                        hidden: 0
                    },
                    {
                        value: "210682",
                        label: "凤城市",
                        simple_name: "凤城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210700",
                label: "锦州市",
                simple_name: "锦州",
                hidden: 0,
                children: [
                    {
                        value: "210702",
                        label: "古塔区",
                        simple_name: "古塔",
                        hidden: 0
                    },
                    {
                        value: "210703",
                        label: "凌河区",
                        simple_name: "凌河",
                        hidden: 0
                    },
                    {
                        value: "210711",
                        label: "太和区",
                        simple_name: "太和",
                        hidden: 0
                    },
                    {
                        value: "210726",
                        label: "黑山县",
                        simple_name: "黑山",
                        hidden: 0
                    },
                    {
                        value: "210727",
                        label: "义县",
                        simple_name: "义县",
                        hidden: 0
                    },
                    {
                        value: "210781",
                        label: "凌海市",
                        simple_name: "凌海",
                        hidden: 0
                    },
                    {
                        value: "210782",
                        label: "北镇市",
                        simple_name: "北镇",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210800",
                label: "营口市",
                simple_name: "营口",
                hidden: 0,
                children: [
                    {
                        value: "210802",
                        label: "站前区",
                        simple_name: "站前",
                        hidden: 0
                    },
                    {
                        value: "210803",
                        label: "西市区",
                        simple_name: "西市",
                        hidden: 0
                    },
                    {
                        value: "210804",
                        label: "鲅鱼圈区",
                        simple_name: "鲅鱼圈",
                        hidden: 0
                    },
                    {
                        value: "210811",
                        label: "老边区",
                        simple_name: "老边",
                        hidden: 0
                    },
                    {
                        value: "210881",
                        label: "盖州市",
                        simple_name: "盖州",
                        hidden: 0
                    },
                    {
                        value: "210882",
                        label: "大石桥市",
                        simple_name: "大石桥",
                        hidden: 0
                    }
                ]
            },
            {
                value: "210900",
                label: "阜新市",
                simple_name: "阜新",
                hidden: 0,
                children: [
                    {
                        value: "210902",
                        label: "海州区",
                        simple_name: "海州",
                        hidden: 0
                    },
                    {
                        value: "210903",
                        label: "新邱区",
                        simple_name: "新邱",
                        hidden: 0
                    },
                    {
                        value: "210904",
                        label: "太平区",
                        simple_name: "太平",
                        hidden: 0
                    },
                    {
                        value: "210905",
                        label: "清河门区",
                        simple_name: "清河门",
                        hidden: 0
                    },
                    {
                        value: "210911",
                        label: "细河区",
                        simple_name: "细河",
                        hidden: 0
                    },
                    {
                        value: "210921",
                        label: "阜新蒙古族自治县",
                        simple_name: "阜新",
                        hidden: 0
                    },
                    {
                        value: "210922",
                        label: "彰武县",
                        simple_name: "彰武",
                        hidden: 0
                    }
                ]
            },
            {
                value: "211000",
                label: "辽阳市",
                simple_name: "辽阳",
                hidden: 0,
                children: [
                    {
                        value: "211002",
                        label: "白塔区",
                        simple_name: "白塔",
                        hidden: 0
                    },
                    {
                        value: "211003",
                        label: "文圣区",
                        simple_name: "文圣",
                        hidden: 0
                    },
                    {
                        value: "211004",
                        label: "宏伟区",
                        simple_name: "宏伟",
                        hidden: 0
                    },
                    {
                        value: "211005",
                        label: "弓长岭区",
                        simple_name: "弓长岭",
                        hidden: 0
                    },
                    {
                        value: "211011",
                        label: "太子河区",
                        simple_name: "太子河",
                        hidden: 0
                    },
                    {
                        value: "211021",
                        label: "辽阳县",
                        simple_name: "辽阳",
                        hidden: 0
                    },
                    {
                        value: "211081",
                        label: "灯塔市",
                        simple_name: "灯塔",
                        hidden: 0
                    }
                ]
            },
            {
                value: "211100",
                label: "盘锦市",
                simple_name: "盘锦",
                hidden: 0,
                children: [
                    {
                        value: "211102",
                        label: "双台子区",
                        simple_name: "双台子",
                        hidden: 0
                    },
                    {
                        value: "211103",
                        label: "兴隆台区",
                        simple_name: "兴隆台",
                        hidden: 0
                    },
                    {
                        value: "211121",
                        label: "大洼县",
                        simple_name: "大洼",
                        hidden: 0
                    },
                    {
                        value: "211122",
                        label: "盘山县",
                        simple_name: "盘山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "211200",
                label: "铁岭市",
                simple_name: "铁岭",
                hidden: 0,
                children: [
                    {
                        value: "211202",
                        label: "银州区",
                        simple_name: "银州",
                        hidden: 0
                    },
                    {
                        value: "211204",
                        label: "清河区",
                        simple_name: "清河",
                        hidden: 0
                    },
                    {
                        value: "211221",
                        label: "铁岭县",
                        simple_name: "铁岭",
                        hidden: 0
                    },
                    {
                        value: "211223",
                        label: "西丰县",
                        simple_name: "西丰",
                        hidden: 0
                    },
                    {
                        value: "211224",
                        label: "昌图县",
                        simple_name: "昌图",
                        hidden: 0
                    },
                    {
                        value: "211281",
                        label: "调兵山市",
                        simple_name: "调兵山",
                        hidden: 0
                    },
                    {
                        value: "211282",
                        label: "开原市",
                        simple_name: "开原",
                        hidden: 0
                    }
                ]
            },
            {
                value: "211300",
                label: "朝阳市",
                simple_name: "朝阳",
                hidden: 0,
                children: [
                    {
                        value: "211302",
                        label: "双塔区",
                        simple_name: "双塔",
                        hidden: 0
                    },
                    {
                        value: "211303",
                        label: "龙城区",
                        simple_name: "龙城",
                        hidden: 0
                    },
                    {
                        value: "211321",
                        label: "朝阳县",
                        simple_name: "朝阳",
                        hidden: 0
                    },
                    {
                        value: "211322",
                        label: "建平县",
                        simple_name: "建平",
                        hidden: 0
                    },
                    {
                        value: "211324",
                        label: "喀喇沁左翼蒙古族自治县",
                        simple_name: "喀喇沁左翼",
                        hidden: 0
                    },
                    {
                        value: "211381",
                        label: "北票市",
                        simple_name: "北票",
                        hidden: 0
                    },
                    {
                        value: "211382",
                        label: "凌源市",
                        simple_name: "凌源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "211400",
                label: "葫芦岛市",
                simple_name: "葫芦岛",
                hidden: 0,
                children: [
                    {
                        value: "211402",
                        label: "连山区",
                        simple_name: "连山",
                        hidden: 0
                    },
                    {
                        value: "211403",
                        label: "龙港区",
                        simple_name: "龙港",
                        hidden: 0
                    },
                    {
                        value: "211404",
                        label: "南票区",
                        simple_name: "南票",
                        hidden: 0
                    },
                    {
                        value: "211421",
                        label: "绥中县",
                        simple_name: "绥中",
                        hidden: 0
                    },
                    {
                        value: "211422",
                        label: "建昌县",
                        simple_name: "建昌",
                        hidden: 0
                    },
                    {
                        value: "211481",
                        label: "兴城市",
                        simple_name: "兴城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "211500",
                label: "金普新区",
                simple_name: "金普新区",
                hidden: 0,
                children: [
                    {
                        value: "211501",
                        label: "金州新区",
                        simple_name: "金州新区",
                        hidden: 0
                    },
                    {
                        value: "211502",
                        label: "普湾新区",
                        simple_name: "普湾新区",
                        hidden: 0
                    },
                    {
                        value: "211503",
                        label: "保税区",
                        simple_name: "保税区",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "220000",
        label: "吉林省",
        simple_name: "吉林",
        hidden: 0,
        children: [
            {
                value: "220100",
                label: "长春市",
                simple_name: "长春",
                hidden: 0,
                children: [
                    {
                        value: "220102",
                        label: "南关区",
                        simple_name: "南关",
                        hidden: 0
                    },
                    {
                        value: "220103",
                        label: "宽城区",
                        simple_name: "宽城",
                        hidden: 0
                    },
                    {
                        value: "220104",
                        label: "朝阳区",
                        simple_name: "朝阳",
                        hidden: 0
                    },
                    {
                        value: "220105",
                        label: "二道区",
                        simple_name: "二道",
                        hidden: 0
                    },
                    {
                        value: "220106",
                        label: "绿园区",
                        simple_name: "绿园",
                        hidden: 0
                    },
                    {
                        value: "220112",
                        label: "双阳区",
                        simple_name: "双阳",
                        hidden: 0
                    },
                    {
                        value: "220113",
                        label: "九台区",
                        simple_name: "九台",
                        hidden: 0
                    },
                    {
                        value: "220122",
                        label: "农安县",
                        simple_name: "农安",
                        hidden: 0
                    },
                    {
                        value: "220182",
                        label: "榆树市",
                        simple_name: "榆树",
                        hidden: 0
                    },
                    {
                        value: "220183",
                        label: "德惠市",
                        simple_name: "德惠",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220200",
                label: "吉林市",
                simple_name: "吉林",
                hidden: 0,
                children: [
                    {
                        value: "220202",
                        label: "昌邑区",
                        simple_name: "昌邑",
                        hidden: 0
                    },
                    {
                        value: "220203",
                        label: "龙潭区",
                        simple_name: "龙潭",
                        hidden: 0
                    },
                    {
                        value: "220204",
                        label: "船营区",
                        simple_name: "船营",
                        hidden: 0
                    },
                    {
                        value: "220211",
                        label: "丰满区",
                        simple_name: "丰满",
                        hidden: 0
                    },
                    {
                        value: "220221",
                        label: "永吉县",
                        simple_name: "永吉",
                        hidden: 0
                    },
                    {
                        value: "220281",
                        label: "蛟河市",
                        simple_name: "蛟河",
                        hidden: 0
                    },
                    {
                        value: "220282",
                        label: "桦甸市",
                        simple_name: "桦甸",
                        hidden: 0
                    },
                    {
                        value: "220283",
                        label: "舒兰市",
                        simple_name: "舒兰",
                        hidden: 0
                    },
                    {
                        value: "220284",
                        label: "磐石市",
                        simple_name: "磐石",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220300",
                label: "四平市",
                simple_name: "四平",
                hidden: 0,
                children: [
                    {
                        value: "220302",
                        label: "铁西区",
                        simple_name: "铁西",
                        hidden: 0
                    },
                    {
                        value: "220303",
                        label: "铁东区",
                        simple_name: "铁东",
                        hidden: 0
                    },
                    {
                        value: "220322",
                        label: "梨树县",
                        simple_name: "梨树",
                        hidden: 0
                    },
                    {
                        value: "220323",
                        label: "伊通满族自治县",
                        simple_name: "伊通",
                        hidden: 0
                    },
                    {
                        value: "220381",
                        label: "公主岭市",
                        simple_name: "公主岭",
                        hidden: 0
                    },
                    {
                        value: "220382",
                        label: "双辽市",
                        simple_name: "双辽",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220400",
                label: "辽源市",
                simple_name: "辽源",
                hidden: 0,
                children: [
                    {
                        value: "220402",
                        label: "龙山区",
                        simple_name: "龙山",
                        hidden: 0
                    },
                    {
                        value: "220403",
                        label: "西安区",
                        simple_name: "西安",
                        hidden: 0
                    },
                    {
                        value: "220421",
                        label: "东丰县",
                        simple_name: "东丰",
                        hidden: 0
                    },
                    {
                        value: "220422",
                        label: "东辽县",
                        simple_name: "东辽",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220500",
                label: "通化市",
                simple_name: "通化",
                hidden: 0,
                children: [
                    {
                        value: "220502",
                        label: "东昌区",
                        simple_name: "东昌",
                        hidden: 0
                    },
                    {
                        value: "220503",
                        label: "二道江区",
                        simple_name: "二道江",
                        hidden: 0
                    },
                    {
                        value: "220521",
                        label: "通化县",
                        simple_name: "通化",
                        hidden: 0
                    },
                    {
                        value: "220523",
                        label: "辉南县",
                        simple_name: "辉南",
                        hidden: 0
                    },
                    {
                        value: "220524",
                        label: "柳河县",
                        simple_name: "柳河",
                        hidden: 0
                    },
                    {
                        value: "220581",
                        label: "梅河口市",
                        simple_name: "梅河口",
                        hidden: 0
                    },
                    {
                        value: "220582",
                        label: "集安市",
                        simple_name: "集安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220600",
                label: "白山市",
                simple_name: "白山",
                hidden: 0,
                children: [
                    {
                        value: "220602",
                        label: "浑江区",
                        simple_name: "浑江",
                        hidden: 0
                    },
                    {
                        value: "220605",
                        label: "江源区",
                        simple_name: "江源",
                        hidden: 0
                    },
                    {
                        value: "220621",
                        label: "抚松县",
                        simple_name: "抚松",
                        hidden: 0
                    },
                    {
                        value: "220622",
                        label: "靖宇县",
                        simple_name: "靖宇",
                        hidden: 0
                    },
                    {
                        value: "220623",
                        label: "长白朝鲜族自治县",
                        simple_name: "长白",
                        hidden: 0
                    },
                    {
                        value: "220681",
                        label: "临江市",
                        simple_name: "临江",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220700",
                label: "松原市",
                simple_name: "松原",
                hidden: 0,
                children: [
                    {
                        value: "220702",
                        label: "宁江区",
                        simple_name: "宁江",
                        hidden: 0
                    },
                    {
                        value: "220721",
                        label: "前郭尔罗斯蒙古族自治县",
                        simple_name: "前郭尔罗斯",
                        hidden: 0
                    },
                    {
                        value: "220722",
                        label: "长岭县",
                        simple_name: "长岭",
                        hidden: 0
                    },
                    {
                        value: "220723",
                        label: "乾安县",
                        simple_name: "乾安",
                        hidden: 0
                    },
                    {
                        value: "220781",
                        label: "扶余市",
                        simple_name: "扶余",
                        hidden: 0
                    }
                ]
            },
            {
                value: "220800",
                label: "白城市",
                simple_name: "白城",
                hidden: 0,
                children: [
                    {
                        value: "220802",
                        label: "洮北区",
                        simple_name: "洮北",
                        hidden: 0
                    },
                    {
                        value: "220821",
                        label: "镇赉县",
                        simple_name: "镇赉",
                        hidden: 0
                    },
                    {
                        value: "220822",
                        label: "通榆县",
                        simple_name: "通榆",
                        hidden: 0
                    },
                    {
                        value: "220881",
                        label: "洮南市",
                        simple_name: "洮南",
                        hidden: 0
                    },
                    {
                        value: "220882",
                        label: "大安市",
                        simple_name: "大安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "222400",
                label: "延边朝鲜族自治州",
                simple_name: "延边",
                hidden: 0,
                children: [
                    {
                        value: "222401",
                        label: "延吉市",
                        simple_name: "延吉",
                        hidden: 0
                    },
                    {
                        value: "222402",
                        label: "图们市",
                        simple_name: "图们",
                        hidden: 0
                    },
                    {
                        value: "222403",
                        label: "敦化市",
                        simple_name: "敦化",
                        hidden: 0
                    },
                    {
                        value: "222404",
                        label: "珲春市",
                        simple_name: "珲春",
                        hidden: 0
                    },
                    {
                        value: "222405",
                        label: "龙井市",
                        simple_name: "龙井",
                        hidden: 0
                    },
                    {
                        value: "222406",
                        label: "和龙市",
                        simple_name: "和龙",
                        hidden: 0
                    },
                    {
                        value: "222424",
                        label: "汪清县",
                        simple_name: "汪清",
                        hidden: 0
                    },
                    {
                        value: "222426",
                        label: "安图县",
                        simple_name: "安图",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "230000",
        label: "黑龙江省",
        simple_name: "黑龙江",
        hidden: 0,
        children: [
            {
                value: "230100",
                label: "哈尔滨市",
                simple_name: "哈尔滨",
                hidden: 0,
                children: [
                    {
                        value: "230102",
                        label: "道里区",
                        simple_name: "道里",
                        hidden: 0
                    },
                    {
                        value: "230103",
                        label: "南岗区",
                        simple_name: "南岗",
                        hidden: 0
                    },
                    {
                        value: "230104",
                        label: "道外区",
                        simple_name: "道外",
                        hidden: 0
                    },
                    {
                        value: "230108",
                        label: "平房区",
                        simple_name: "平房",
                        hidden: 0
                    },
                    {
                        value: "230109",
                        label: "松北区",
                        simple_name: "松北",
                        hidden: 0
                    },
                    {
                        value: "230110",
                        label: "香坊区",
                        simple_name: "香坊",
                        hidden: 0
                    },
                    {
                        value: "230111",
                        label: "呼兰区",
                        simple_name: "呼兰",
                        hidden: 0
                    },
                    {
                        value: "230112",
                        label: "阿城区",
                        simple_name: "阿城",
                        hidden: 0
                    },
                    {
                        value: "230113",
                        label: "双城区",
                        simple_name: "双城",
                        hidden: 0
                    },
                    {
                        value: "230123",
                        label: "依兰县",
                        simple_name: "依兰",
                        hidden: 0
                    },
                    {
                        value: "230124",
                        label: "方正县",
                        simple_name: "方正",
                        hidden: 0
                    },
                    {
                        value: "230125",
                        label: "宾县",
                        simple_name: "宾县",
                        hidden: 0
                    },
                    {
                        value: "230126",
                        label: "巴彦县",
                        simple_name: "巴彦",
                        hidden: 0
                    },
                    {
                        value: "230127",
                        label: "木兰县",
                        simple_name: "木兰",
                        hidden: 0
                    },
                    {
                        value: "230128",
                        label: "通河县",
                        simple_name: "通河",
                        hidden: 0
                    },
                    {
                        value: "230129",
                        label: "延寿县",
                        simple_name: "延寿",
                        hidden: 0
                    },
                    {
                        value: "230183",
                        label: "尚志市",
                        simple_name: "尚志",
                        hidden: 0
                    },
                    {
                        value: "230184",
                        label: "五常市",
                        simple_name: "五常",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230200",
                label: "齐齐哈尔市",
                simple_name: "齐齐哈尔",
                hidden: 0,
                children: [
                    {
                        value: "230202",
                        label: "龙沙区",
                        simple_name: "龙沙",
                        hidden: 0
                    },
                    {
                        value: "230203",
                        label: "建华区",
                        simple_name: "建华",
                        hidden: 0
                    },
                    {
                        value: "230204",
                        label: "铁锋区",
                        simple_name: "铁锋",
                        hidden: 0
                    },
                    {
                        value: "230205",
                        label: "昂昂溪区",
                        simple_name: "昂昂溪",
                        hidden: 0
                    },
                    {
                        value: "230206",
                        label: "富拉尔基区",
                        simple_name: "富拉尔基",
                        hidden: 0
                    },
                    {
                        value: "230207",
                        label: "碾子山区",
                        simple_name: "碾子山",
                        hidden: 0
                    },
                    {
                        value: "230208",
                        label: "梅里斯达斡尔族区",
                        simple_name: "梅里斯",
                        hidden: 0
                    },
                    {
                        value: "230221",
                        label: "龙江县",
                        simple_name: "龙江",
                        hidden: 0
                    },
                    {
                        value: "230223",
                        label: "依安县",
                        simple_name: "依安",
                        hidden: 0
                    },
                    {
                        value: "230224",
                        label: "泰来县",
                        simple_name: "泰来",
                        hidden: 0
                    },
                    {
                        value: "230225",
                        label: "甘南县",
                        simple_name: "甘南",
                        hidden: 0
                    },
                    {
                        value: "230227",
                        label: "富裕县",
                        simple_name: "富裕",
                        hidden: 0
                    },
                    {
                        value: "230229",
                        label: "克山县",
                        simple_name: "克山",
                        hidden: 0
                    },
                    {
                        value: "230230",
                        label: "克东县",
                        simple_name: "克东",
                        hidden: 0
                    },
                    {
                        value: "230231",
                        label: "拜泉县",
                        simple_name: "拜泉",
                        hidden: 0
                    },
                    {
                        value: "230281",
                        label: "讷河市",
                        simple_name: "讷河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230300",
                label: "鸡西市",
                simple_name: "鸡西",
                hidden: 0,
                children: [
                    {
                        value: "230302",
                        label: "鸡冠区",
                        simple_name: "鸡冠",
                        hidden: 0
                    },
                    {
                        value: "230303",
                        label: "恒山区",
                        simple_name: "恒山",
                        hidden: 0
                    },
                    {
                        value: "230304",
                        label: "滴道区",
                        simple_name: "滴道",
                        hidden: 0
                    },
                    {
                        value: "230305",
                        label: "梨树区",
                        simple_name: "梨树",
                        hidden: 0
                    },
                    {
                        value: "230306",
                        label: "城子河区",
                        simple_name: "城子河",
                        hidden: 0
                    },
                    {
                        value: "230307",
                        label: "麻山区",
                        simple_name: "麻山",
                        hidden: 0
                    },
                    {
                        value: "230321",
                        label: "鸡东县",
                        simple_name: "鸡东",
                        hidden: 0
                    },
                    {
                        value: "230381",
                        label: "虎林市",
                        simple_name: "虎林",
                        hidden: 0
                    },
                    {
                        value: "230382",
                        label: "密山市",
                        simple_name: "密山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230400",
                label: "鹤岗市",
                simple_name: "鹤岗",
                hidden: 0,
                children: [
                    {
                        value: "230402",
                        label: "向阳区",
                        simple_name: "向阳",
                        hidden: 0
                    },
                    {
                        value: "230403",
                        label: "工农区",
                        simple_name: "工农",
                        hidden: 0
                    },
                    {
                        value: "230404",
                        label: "南山区",
                        simple_name: "南山",
                        hidden: 0
                    },
                    {
                        value: "230405",
                        label: "兴安区",
                        simple_name: "兴安",
                        hidden: 0
                    },
                    {
                        value: "230406",
                        label: "东山区",
                        simple_name: "东山",
                        hidden: 0
                    },
                    {
                        value: "230407",
                        label: "兴山区",
                        simple_name: "兴山",
                        hidden: 0
                    },
                    {
                        value: "230421",
                        label: "萝北县",
                        simple_name: "萝北",
                        hidden: 0
                    },
                    {
                        value: "230422",
                        label: "绥滨县",
                        simple_name: "绥滨",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230500",
                label: "双鸭山市",
                simple_name: "双鸭山",
                hidden: 0,
                children: [
                    {
                        value: "230502",
                        label: "尖山区",
                        simple_name: "尖山",
                        hidden: 0
                    },
                    {
                        value: "230503",
                        label: "岭东区",
                        simple_name: "岭东",
                        hidden: 0
                    },
                    {
                        value: "230505",
                        label: "四方台区",
                        simple_name: "四方台",
                        hidden: 0
                    },
                    {
                        value: "230506",
                        label: "宝山区",
                        simple_name: "宝山",
                        hidden: 0
                    },
                    {
                        value: "230521",
                        label: "集贤县",
                        simple_name: "集贤",
                        hidden: 0
                    },
                    {
                        value: "230522",
                        label: "友谊县",
                        simple_name: "友谊",
                        hidden: 0
                    },
                    {
                        value: "230523",
                        label: "宝清县",
                        simple_name: "宝清",
                        hidden: 0
                    },
                    {
                        value: "230524",
                        label: "饶河县",
                        simple_name: "饶河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230600",
                label: "大庆市",
                simple_name: "大庆",
                hidden: 0,
                children: [
                    {
                        value: "230602",
                        label: "萨尔图区",
                        simple_name: "萨尔图",
                        hidden: 0
                    },
                    {
                        value: "230603",
                        label: "龙凤区",
                        simple_name: "龙凤",
                        hidden: 0
                    },
                    {
                        value: "230604",
                        label: "让胡路区",
                        simple_name: "让胡路",
                        hidden: 0
                    },
                    {
                        value: "230605",
                        label: "红岗区",
                        simple_name: "红岗",
                        hidden: 0
                    },
                    {
                        value: "230606",
                        label: "大同区",
                        simple_name: "大同",
                        hidden: 0
                    },
                    {
                        value: "230621",
                        label: "肇州县",
                        simple_name: "肇州",
                        hidden: 0
                    },
                    {
                        value: "230622",
                        label: "肇源县",
                        simple_name: "肇源",
                        hidden: 0
                    },
                    {
                        value: "230623",
                        label: "林甸县",
                        simple_name: "林甸",
                        hidden: 0
                    },
                    {
                        value: "230624",
                        label: "杜尔伯特蒙古族自治县",
                        simple_name: "杜尔伯特",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230700",
                label: "伊春市",
                simple_name: "伊春",
                hidden: 0,
                children: [
                    {
                        value: "230702",
                        label: "伊春区",
                        simple_name: "伊春",
                        hidden: 0
                    },
                    {
                        value: "230703",
                        label: "南岔区",
                        simple_name: "南岔",
                        hidden: 0
                    },
                    {
                        value: "230704",
                        label: "友好区",
                        simple_name: "友好",
                        hidden: 0
                    },
                    {
                        value: "230705",
                        label: "西林区",
                        simple_name: "西林",
                        hidden: 0
                    },
                    {
                        value: "230706",
                        label: "翠峦区",
                        simple_name: "翠峦",
                        hidden: 0
                    },
                    {
                        value: "230707",
                        label: "新青区",
                        simple_name: "新青",
                        hidden: 0
                    },
                    {
                        value: "230708",
                        label: "美溪区",
                        simple_name: "美溪",
                        hidden: 0
                    },
                    {
                        value: "230709",
                        label: "金山屯区",
                        simple_name: "金山屯",
                        hidden: 0
                    },
                    {
                        value: "230710",
                        label: "五营区",
                        simple_name: "五营",
                        hidden: 0
                    },
                    {
                        value: "230711",
                        label: "乌马河区",
                        simple_name: "乌马河",
                        hidden: 0
                    },
                    {
                        value: "230712",
                        label: "汤旺河区",
                        simple_name: "汤旺河",
                        hidden: 0
                    },
                    {
                        value: "230713",
                        label: "带岭区",
                        simple_name: "带岭",
                        hidden: 0
                    },
                    {
                        value: "230714",
                        label: "乌伊岭区",
                        simple_name: "乌伊岭",
                        hidden: 0
                    },
                    {
                        value: "230715",
                        label: "红星区",
                        simple_name: "红星",
                        hidden: 0
                    },
                    {
                        value: "230716",
                        label: "上甘岭区",
                        simple_name: "上甘岭",
                        hidden: 0
                    },
                    {
                        value: "230722",
                        label: "嘉荫县",
                        simple_name: "嘉荫",
                        hidden: 0
                    },
                    {
                        value: "230781",
                        label: "铁力市",
                        simple_name: "铁力",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230800",
                label: "佳木斯市",
                simple_name: "佳木斯",
                hidden: 0,
                children: [
                    {
                        value: "230803",
                        label: "向阳区",
                        simple_name: "向阳",
                        hidden: 0
                    },
                    {
                        value: "230804",
                        label: "前进区",
                        simple_name: "前进",
                        hidden: 0
                    },
                    {
                        value: "230805",
                        label: "东风区",
                        simple_name: "东风",
                        hidden: 0
                    },
                    {
                        value: "230811",
                        label: "郊区",
                        simple_name: "郊区",
                        hidden: 0
                    },
                    {
                        value: "230822",
                        label: "桦南县",
                        simple_name: "桦南",
                        hidden: 0
                    },
                    {
                        value: "230826",
                        label: "桦川县",
                        simple_name: "桦川",
                        hidden: 0
                    },
                    {
                        value: "230828",
                        label: "汤原县",
                        simple_name: "汤原",
                        hidden: 0
                    },
                    {
                        value: "230833",
                        label: "抚远县",
                        simple_name: "抚远",
                        hidden: 0
                    },
                    {
                        value: "230881",
                        label: "同江市",
                        simple_name: "同江",
                        hidden: 0
                    },
                    {
                        value: "230882",
                        label: "富锦市",
                        simple_name: "富锦",
                        hidden: 0
                    }
                ]
            },
            {
                value: "230900",
                label: "七台河市",
                simple_name: "七台河",
                hidden: 0,
                children: [
                    {
                        value: "230902",
                        label: "新兴区",
                        simple_name: "新兴",
                        hidden: 0
                    },
                    {
                        value: "230903",
                        label: "桃山区",
                        simple_name: "桃山",
                        hidden: 0
                    },
                    {
                        value: "230904",
                        label: "茄子河区",
                        simple_name: "茄子河",
                        hidden: 0
                    },
                    {
                        value: "230921",
                        label: "勃利县",
                        simple_name: "勃利",
                        hidden: 0
                    }
                ]
            },
            {
                value: "231000",
                label: "牡丹江市",
                simple_name: "牡丹江",
                hidden: 0,
                children: [
                    {
                        value: "231002",
                        label: "东安区",
                        simple_name: "东安",
                        hidden: 0
                    },
                    {
                        value: "231003",
                        label: "阳明区",
                        simple_name: "阳明",
                        hidden: 0
                    },
                    {
                        value: "231004",
                        label: "爱民区",
                        simple_name: "爱民",
                        hidden: 0
                    },
                    {
                        value: "231005",
                        label: "西安区",
                        simple_name: "西安",
                        hidden: 0
                    },
                    {
                        value: "231024",
                        label: "东宁县",
                        simple_name: "东宁",
                        hidden: 0
                    },
                    {
                        value: "231025",
                        label: "林口县",
                        simple_name: "林口",
                        hidden: 0
                    },
                    {
                        value: "231081",
                        label: "绥芬河市",
                        simple_name: "绥芬河",
                        hidden: 0
                    },
                    {
                        value: "231083",
                        label: "海林市",
                        simple_name: "海林",
                        hidden: 0
                    },
                    {
                        value: "231084",
                        label: "宁安市",
                        simple_name: "宁安",
                        hidden: 0
                    },
                    {
                        value: "231085",
                        label: "穆棱市",
                        simple_name: "穆棱",
                        hidden: 0
                    }
                ]
            },
            {
                value: "231100",
                label: "黑河市",
                simple_name: "黑河",
                hidden: 0,
                children: [
                    {
                        value: "231102",
                        label: "爱辉区",
                        simple_name: "爱辉",
                        hidden: 0
                    },
                    {
                        value: "231121",
                        label: "嫩江县",
                        simple_name: "嫩江",
                        hidden: 0
                    },
                    {
                        value: "231123",
                        label: "逊克县",
                        simple_name: "逊克",
                        hidden: 0
                    },
                    {
                        value: "231124",
                        label: "孙吴县",
                        simple_name: "孙吴",
                        hidden: 0
                    },
                    {
                        value: "231181",
                        label: "北安市",
                        simple_name: "北安",
                        hidden: 0
                    },
                    {
                        value: "231182",
                        label: "五大连池市",
                        simple_name: "五大连池",
                        hidden: 0
                    }
                ]
            },
            {
                value: "231200",
                label: "绥化市",
                simple_name: "绥化",
                hidden: 0,
                children: [
                    {
                        value: "231202",
                        label: "北林区",
                        simple_name: "北林",
                        hidden: 0
                    },
                    {
                        value: "231221",
                        label: "望奎县",
                        simple_name: "望奎",
                        hidden: 0
                    },
                    {
                        value: "231222",
                        label: "兰西县",
                        simple_name: "兰西",
                        hidden: 0
                    },
                    {
                        value: "231223",
                        label: "青冈县",
                        simple_name: "青冈",
                        hidden: 0
                    },
                    {
                        value: "231224",
                        label: "庆安县",
                        simple_name: "庆安",
                        hidden: 0
                    },
                    {
                        value: "231225",
                        label: "明水县",
                        simple_name: "明水",
                        hidden: 0
                    },
                    {
                        value: "231226",
                        label: "绥棱县",
                        simple_name: "绥棱",
                        hidden: 0
                    },
                    {
                        value: "231281",
                        label: "安达市",
                        simple_name: "安达",
                        hidden: 0
                    },
                    {
                        value: "231282",
                        label: "肇东市",
                        simple_name: "肇东",
                        hidden: 0
                    },
                    {
                        value: "231283",
                        label: "海伦市",
                        simple_name: "海伦",
                        hidden: 0
                    }
                ]
            },
            {
                value: "232700",
                label: "大兴安岭地区",
                simple_name: "大兴安岭",
                hidden: 0,
                children: [
                    {
                        value: "232701",
                        label: "加格达奇区",
                        simple_name: "加格达奇",
                        hidden: 0
                    },
                    {
                        value: "232702",
                        label: "新林区",
                        simple_name: "新林",
                        hidden: 0
                    },
                    {
                        value: "232703",
                        label: "松岭区",
                        simple_name: "松岭",
                        hidden: 0
                    },
                    {
                        value: "232704",
                        label: "呼中区",
                        simple_name: "呼中",
                        hidden: 0
                    },
                    {
                        value: "232721",
                        label: "呼玛县",
                        simple_name: "呼玛",
                        hidden: 0
                    },
                    {
                        value: "232722",
                        label: "塔河县",
                        simple_name: "塔河",
                        hidden: 0
                    },
                    {
                        value: "232723",
                        label: "漠河县",
                        simple_name: "漠河",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "310000",
        label: "上海",
        simple_name: "上海",
        hidden: 0,
        children: [
            {
                value: "310100",
                label: "上海市",
                simple_name: "上海",
                hidden: 0,
                children: [
                    {
                        value: "310101",
                        label: "黄浦区",
                        simple_name: "黄浦",
                        hidden: 0
                    },
                    {
                        value: "310104",
                        label: "徐汇区",
                        simple_name: "徐汇",
                        hidden: 0
                    },
                    {
                        value: "310105",
                        label: "长宁区",
                        simple_name: "长宁",
                        hidden: 0
                    },
                    {
                        value: "310106",
                        label: "静安区",
                        simple_name: "静安",
                        hidden: 0
                    },
                    {
                        value: "310107",
                        label: "普陀区",
                        simple_name: "普陀",
                        hidden: 0
                    },
                    {
                        value: "310108",
                        label: "闸北区",
                        simple_name: "闸北",
                        hidden: 0
                    },
                    {
                        value: "310109",
                        label: "虹口区",
                        simple_name: "虹口",
                        hidden: 0
                    },
                    {
                        value: "310110",
                        label: "杨浦区",
                        simple_name: "杨浦",
                        hidden: 0
                    },
                    {
                        value: "310112",
                        label: "闵行区",
                        simple_name: "闵行",
                        hidden: 0
                    },
                    {
                        value: "310113",
                        label: "宝山区",
                        simple_name: "宝山",
                        hidden: 0
                    },
                    {
                        value: "310114",
                        label: "嘉定区",
                        simple_name: "嘉定",
                        hidden: 0
                    },
                    {
                        value: "310115",
                        label: "浦东新区",
                        simple_name: "浦东",
                        hidden: 0
                    },
                    {
                        value: "310116",
                        label: "金山区",
                        simple_name: "金山",
                        hidden: 0
                    },
                    {
                        value: "310117",
                        label: "松江区",
                        simple_name: "松江",
                        hidden: 0
                    },
                    {
                        value: "310118",
                        label: "青浦区",
                        simple_name: "青浦",
                        hidden: 0
                    },
                    {
                        value: "310120",
                        label: "奉贤区",
                        simple_name: "奉贤",
                        hidden: 0
                    },
                    {
                        value: "310230",
                        label: "崇明县",
                        simple_name: "崇明",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "320000",
        label: "江苏省",
        simple_name: "江苏",
        hidden: 0,
        children: [
            {
                value: "320100",
                label: "南京市",
                simple_name: "南京",
                hidden: 0,
                children: [
                    {
                        value: "320102",
                        label: "玄武区",
                        simple_name: "玄武",
                        hidden: 0
                    },
                    {
                        value: "320104",
                        label: "秦淮区",
                        simple_name: "秦淮",
                        hidden: 0
                    },
                    {
                        value: "320105",
                        label: "建邺区",
                        simple_name: "建邺",
                        hidden: 0
                    },
                    {
                        value: "320106",
                        label: "鼓楼区",
                        simple_name: "鼓楼",
                        hidden: 0
                    },
                    {
                        value: "320111",
                        label: "浦口区",
                        simple_name: "浦口",
                        hidden: 0
                    },
                    {
                        value: "320113",
                        label: "栖霞区",
                        simple_name: "栖霞",
                        hidden: 0
                    },
                    {
                        value: "320114",
                        label: "雨花台区",
                        simple_name: "雨花台",
                        hidden: 0
                    },
                    {
                        value: "320115",
                        label: "江宁区",
                        simple_name: "江宁",
                        hidden: 0
                    },
                    {
                        value: "320116",
                        label: "六合区",
                        simple_name: "六合",
                        hidden: 0
                    },
                    {
                        value: "320117",
                        label: "溧水区",
                        simple_name: "溧水",
                        hidden: 0
                    },
                    {
                        value: "320118",
                        label: "高淳区",
                        simple_name: "高淳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320200",
                label: "无锡市",
                simple_name: "无锡",
                hidden: 0,
                children: [
                    {
                        value: "320202",
                        label: "崇安区",
                        simple_name: "崇安",
                        hidden: 0
                    },
                    {
                        value: "320203",
                        label: "南长区",
                        simple_name: "南长",
                        hidden: 0
                    },
                    {
                        value: "320204",
                        label: "北塘区",
                        simple_name: "北塘",
                        hidden: 0
                    },
                    {
                        value: "320205",
                        label: "锡山区",
                        simple_name: "锡山",
                        hidden: 0
                    },
                    {
                        value: "320206",
                        label: "惠山区",
                        simple_name: "惠山",
                        hidden: 0
                    },
                    {
                        value: "320211",
                        label: "滨湖区",
                        simple_name: "滨湖",
                        hidden: 0
                    },
                    {
                        value: "320281",
                        label: "江阴市",
                        simple_name: "江阴",
                        hidden: 0
                    },
                    {
                        value: "320282",
                        label: "宜兴市",
                        simple_name: "宜兴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320300",
                label: "徐州市",
                simple_name: "徐州",
                hidden: 0,
                children: [
                    {
                        value: "320302",
                        label: "鼓楼区",
                        simple_name: "鼓楼",
                        hidden: 0
                    },
                    {
                        value: "320303",
                        label: "云龙区",
                        simple_name: "云龙",
                        hidden: 0
                    },
                    {
                        value: "320305",
                        label: "贾汪区",
                        simple_name: "贾汪",
                        hidden: 0
                    },
                    {
                        value: "320311",
                        label: "泉山区",
                        simple_name: "泉山",
                        hidden: 0
                    },
                    {
                        value: "320312",
                        label: "铜山区",
                        simple_name: "铜山",
                        hidden: 0
                    },
                    {
                        value: "320321",
                        label: "丰县",
                        simple_name: "丰县",
                        hidden: 0
                    },
                    {
                        value: "320322",
                        label: "沛县",
                        simple_name: "沛县",
                        hidden: 0
                    },
                    {
                        value: "320324",
                        label: "睢宁县",
                        simple_name: "睢宁",
                        hidden: 0
                    },
                    {
                        value: "320381",
                        label: "新沂市",
                        simple_name: "新沂",
                        hidden: 0
                    },
                    {
                        value: "320382",
                        label: "邳州市",
                        simple_name: "邳州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320400",
                label: "常州市",
                simple_name: "常州",
                hidden: 0,
                children: [
                    {
                        value: "320402",
                        label: "天宁区",
                        simple_name: "天宁",
                        hidden: 0
                    },
                    {
                        value: "320404",
                        label: "钟楼区",
                        simple_name: "钟楼",
                        hidden: 0
                    },
                    {
                        value: "320405",
                        label: "戚墅堰区",
                        simple_name: "戚墅堰",
                        hidden: 0
                    },
                    {
                        value: "320411",
                        label: "新北区",
                        simple_name: "新北",
                        hidden: 0
                    },
                    {
                        value: "320412",
                        label: "武进区",
                        simple_name: "武进",
                        hidden: 0
                    },
                    {
                        value: "320481",
                        label: "溧阳市",
                        simple_name: "溧阳",
                        hidden: 0
                    },
                    {
                        value: "320482",
                        label: "金坛市",
                        simple_name: "金坛",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320500",
                label: "苏州市",
                simple_name: "苏州",
                hidden: 0,
                children: [
                    {
                        value: "320505",
                        label: "虎丘区",
                        simple_name: "虎丘",
                        hidden: 0
                    },
                    {
                        value: "320506",
                        label: "吴中区",
                        simple_name: "吴中",
                        hidden: 0
                    },
                    {
                        value: "320507",
                        label: "相城区",
                        simple_name: "相城",
                        hidden: 0
                    },
                    {
                        value: "320508",
                        label: "姑苏区",
                        simple_name: "姑苏",
                        hidden: 0
                    },
                    {
                        value: "320509",
                        label: "吴江区",
                        simple_name: "吴江",
                        hidden: 0
                    },
                    {
                        value: "320581",
                        label: "常熟市",
                        simple_name: "常熟",
                        hidden: 0
                    },
                    {
                        value: "320582",
                        label: "张家港市",
                        simple_name: "张家港",
                        hidden: 0
                    },
                    {
                        value: "320583",
                        label: "昆山市",
                        simple_name: "昆山",
                        hidden: 0
                    },
                    {
                        value: "320585",
                        label: "太仓市",
                        simple_name: "太仓",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320600",
                label: "南通市",
                simple_name: "南通",
                hidden: 0,
                children: [
                    {
                        value: "320602",
                        label: "崇川区",
                        simple_name: "崇川",
                        hidden: 0
                    },
                    {
                        value: "320611",
                        label: "港闸区",
                        simple_name: "港闸",
                        hidden: 0
                    },
                    {
                        value: "320612",
                        label: "通州区",
                        simple_name: "通州",
                        hidden: 0
                    },
                    {
                        value: "320621",
                        label: "海安县",
                        simple_name: "海安",
                        hidden: 0
                    },
                    {
                        value: "320623",
                        label: "如东县",
                        simple_name: "如东",
                        hidden: 0
                    },
                    {
                        value: "320681",
                        label: "启东市",
                        simple_name: "启东",
                        hidden: 0
                    },
                    {
                        value: "320682",
                        label: "如皋市",
                        simple_name: "如皋",
                        hidden: 0
                    },
                    {
                        value: "320684",
                        label: "海门市",
                        simple_name: "海门",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320700",
                label: "连云港市",
                simple_name: "连云港",
                hidden: 0,
                children: [
                    {
                        value: "320703",
                        label: "连云区",
                        simple_name: "连云",
                        hidden: 0
                    },
                    {
                        value: "320706",
                        label: "海州区",
                        simple_name: "海州",
                        hidden: 0
                    },
                    {
                        value: "320707",
                        label: "赣榆区",
                        simple_name: "赣榆",
                        hidden: 0
                    },
                    {
                        value: "320722",
                        label: "东海县",
                        simple_name: "东海",
                        hidden: 0
                    },
                    {
                        value: "320723",
                        label: "灌云县",
                        simple_name: "灌云",
                        hidden: 0
                    },
                    {
                        value: "320724",
                        label: "灌南县",
                        simple_name: "灌南",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320800",
                label: "淮安市",
                simple_name: "淮安",
                hidden: 0,
                children: [
                    {
                        value: "320802",
                        label: "清河区",
                        simple_name: "清河",
                        hidden: 0
                    },
                    {
                        value: "320803",
                        label: "淮安区",
                        simple_name: "淮安",
                        hidden: 0
                    },
                    {
                        value: "320804",
                        label: "淮阴区",
                        simple_name: "淮阴",
                        hidden: 0
                    },
                    {
                        value: "320811",
                        label: "清浦区",
                        simple_name: "清浦",
                        hidden: 0
                    },
                    {
                        value: "320826",
                        label: "涟水县",
                        simple_name: "涟水",
                        hidden: 0
                    },
                    {
                        value: "320829",
                        label: "洪泽县",
                        simple_name: "洪泽",
                        hidden: 0
                    },
                    {
                        value: "320830",
                        label: "盱眙县",
                        simple_name: "盱眙",
                        hidden: 0
                    },
                    {
                        value: "320831",
                        label: "金湖县",
                        simple_name: "金湖",
                        hidden: 0
                    }
                ]
            },
            {
                value: "320900",
                label: "盐城市",
                simple_name: "盐城",
                hidden: 0,
                children: [
                    {
                        value: "320902",
                        label: "亭湖区",
                        simple_name: "亭湖",
                        hidden: 0
                    },
                    {
                        value: "320903",
                        label: "盐都区",
                        simple_name: "盐都",
                        hidden: 0
                    },
                    {
                        value: "320921",
                        label: "响水县",
                        simple_name: "响水",
                        hidden: 0
                    },
                    {
                        value: "320922",
                        label: "滨海县",
                        simple_name: "滨海",
                        hidden: 0
                    },
                    {
                        value: "320923",
                        label: "阜宁县",
                        simple_name: "阜宁",
                        hidden: 0
                    },
                    {
                        value: "320924",
                        label: "射阳县",
                        simple_name: "射阳",
                        hidden: 0
                    },
                    {
                        value: "320925",
                        label: "建湖县",
                        simple_name: "建湖",
                        hidden: 0
                    },
                    {
                        value: "320981",
                        label: "东台市",
                        simple_name: "东台",
                        hidden: 0
                    },
                    {
                        value: "320982",
                        label: "大丰市",
                        simple_name: "大丰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "321000",
                label: "扬州市",
                simple_name: "扬州",
                hidden: 0,
                children: [
                    {
                        value: "321002",
                        label: "广陵区",
                        simple_name: "广陵",
                        hidden: 0
                    },
                    {
                        value: "321003",
                        label: "邗江区",
                        simple_name: "邗江",
                        hidden: 0
                    },
                    {
                        value: "321012",
                        label: "江都区",
                        simple_name: "江都",
                        hidden: 0
                    },
                    {
                        value: "321023",
                        label: "宝应县",
                        simple_name: "宝应",
                        hidden: 0
                    },
                    {
                        value: "321081",
                        label: "仪征市",
                        simple_name: "仪征",
                        hidden: 0
                    },
                    {
                        value: "321084",
                        label: "高邮市",
                        simple_name: "高邮",
                        hidden: 0
                    }
                ]
            },
            {
                value: "321100",
                label: "镇江市",
                simple_name: "镇江",
                hidden: 0,
                children: [
                    {
                        value: "321102",
                        label: "京口区",
                        simple_name: "京口",
                        hidden: 0
                    },
                    {
                        value: "321111",
                        label: "润州区",
                        simple_name: "润州",
                        hidden: 0
                    },
                    {
                        value: "321112",
                        label: "丹徒区",
                        simple_name: "丹徒",
                        hidden: 0
                    },
                    {
                        value: "321181",
                        label: "丹阳市",
                        simple_name: "丹阳",
                        hidden: 0
                    },
                    {
                        value: "321182",
                        label: "扬中市",
                        simple_name: "扬中",
                        hidden: 0
                    },
                    {
                        value: "321183",
                        label: "句容市",
                        simple_name: "句容",
                        hidden: 0
                    }
                ]
            },
            {
                value: "321200",
                label: "泰州市",
                simple_name: "泰州",
                hidden: 0,
                children: [
                    {
                        value: "321202",
                        label: "海陵区",
                        simple_name: "海陵",
                        hidden: 0
                    },
                    {
                        value: "321203",
                        label: "高港区",
                        simple_name: "高港",
                        hidden: 0
                    },
                    {
                        value: "321204",
                        label: "姜堰区",
                        simple_name: "姜堰",
                        hidden: 0
                    },
                    {
                        value: "321281",
                        label: "兴化市",
                        simple_name: "兴化",
                        hidden: 0
                    },
                    {
                        value: "321282",
                        label: "靖江市",
                        simple_name: "靖江",
                        hidden: 0
                    },
                    {
                        value: "321283",
                        label: "泰兴市",
                        simple_name: "泰兴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "321300",
                label: "宿迁市",
                simple_name: "宿迁",
                hidden: 0,
                children: [
                    {
                        value: "321302",
                        label: "宿城区",
                        simple_name: "宿城",
                        hidden: 0
                    },
                    {
                        value: "321311",
                        label: "宿豫区",
                        simple_name: "宿豫",
                        hidden: 0
                    },
                    {
                        value: "321322",
                        label: "沭阳县",
                        simple_name: "沭阳",
                        hidden: 0
                    },
                    {
                        value: "321323",
                        label: "泗阳县",
                        simple_name: "泗阳",
                        hidden: 0
                    },
                    {
                        value: "321324",
                        label: "泗洪县",
                        simple_name: "泗洪",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "330000",
        label: "浙江省",
        simple_name: "浙江",
        hidden: 0,
        children: [
            {
                value: "330100",
                label: "杭州市",
                simple_name: "杭州",
                hidden: 0,
                children: [
                    {
                        value: "330102",
                        label: "上城区",
                        simple_name: "上城",
                        hidden: 0
                    },
                    {
                        value: "330103",
                        label: "下城区",
                        simple_name: "下城",
                        hidden: 0
                    },
                    {
                        value: "330104",
                        label: "江干区",
                        simple_name: "江干",
                        hidden: 0
                    },
                    {
                        value: "330105",
                        label: "拱墅区",
                        simple_name: "拱墅",
                        hidden: 0
                    },
                    {
                        value: "330106",
                        label: "西湖区",
                        simple_name: "西湖",
                        hidden: 0
                    },
                    {
                        value: "330108",
                        label: "滨江区",
                        simple_name: "滨江",
                        hidden: 0
                    },
                    {
                        value: "330109",
                        label: "萧山区",
                        simple_name: "萧山",
                        hidden: 0
                    },
                    {
                        value: "330110",
                        label: "余杭区",
                        simple_name: "余杭",
                        hidden: 0
                    },
                    {
                        value: "330122",
                        label: "桐庐县",
                        simple_name: "桐庐",
                        hidden: 0
                    },
                    {
                        value: "330127",
                        label: "淳安县",
                        simple_name: "淳安",
                        hidden: 0
                    },
                    {
                        value: "330182",
                        label: "建德市",
                        simple_name: "建德",
                        hidden: 0
                    },
                    {
                        value: "330183",
                        label: "富阳区",
                        simple_name: "富阳",
                        hidden: 0
                    },
                    {
                        value: "330185",
                        label: "临安区",
                        simple_name: "临安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330200",
                label: "宁波市",
                simple_name: "宁波",
                hidden: 0,
                children: [
                    {
                        value: "330203",
                        label: "海曙区",
                        simple_name: "海曙",
                        hidden: 0
                    },
                    {
                        value: "330204",
                        label: "江东区",
                        simple_name: "江东",
                        hidden: 0
                    },
                    {
                        value: "330205",
                        label: "江北区",
                        simple_name: "江北",
                        hidden: 0
                    },
                    {
                        value: "330206",
                        label: "北仑区",
                        simple_name: "北仑",
                        hidden: 0
                    },
                    {
                        value: "330211",
                        label: "镇海区",
                        simple_name: "镇海",
                        hidden: 0
                    },
                    {
                        value: "330212",
                        label: "鄞州区",
                        simple_name: "鄞州",
                        hidden: 0
                    },
                    {
                        value: "330225",
                        label: "象山县",
                        simple_name: "象山",
                        hidden: 0
                    },
                    {
                        value: "330226",
                        label: "宁海县",
                        simple_name: "宁海",
                        hidden: 0
                    },
                    {
                        value: "330281",
                        label: "余姚市",
                        simple_name: "余姚",
                        hidden: 0
                    },
                    {
                        value: "330282",
                        label: "慈溪市",
                        simple_name: "慈溪",
                        hidden: 0
                    },
                    {
                        value: "330283",
                        label: "奉化市",
                        simple_name: "奉化",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330300",
                label: "温州市",
                simple_name: "温州",
                hidden: 0,
                children: [
                    {
                        value: "330302",
                        label: "鹿城区",
                        simple_name: "鹿城",
                        hidden: 0
                    },
                    {
                        value: "330303",
                        label: "龙湾区",
                        simple_name: "龙湾",
                        hidden: 0
                    },
                    {
                        value: "330304",
                        label: "瓯海区",
                        simple_name: "瓯海",
                        hidden: 0
                    },
                    {
                        value: "330322",
                        label: "洞头县",
                        simple_name: "洞头",
                        hidden: 0
                    },
                    {
                        value: "330324",
                        label: "永嘉县",
                        simple_name: "永嘉",
                        hidden: 0
                    },
                    {
                        value: "330326",
                        label: "平阳县",
                        simple_name: "平阳",
                        hidden: 0
                    },
                    {
                        value: "330327",
                        label: "苍南县",
                        simple_name: "苍南",
                        hidden: 0
                    },
                    {
                        value: "330328",
                        label: "文成县",
                        simple_name: "文成",
                        hidden: 0
                    },
                    {
                        value: "330329",
                        label: "泰顺县",
                        simple_name: "泰顺",
                        hidden: 0
                    },
                    {
                        value: "330381",
                        label: "瑞安市",
                        simple_name: "瑞安",
                        hidden: 0
                    },
                    {
                        value: "330382",
                        label: "乐清市",
                        simple_name: "乐清",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330400",
                label: "嘉兴市",
                simple_name: "嘉兴",
                hidden: 0,
                children: [
                    {
                        value: "330402",
                        label: "南湖区",
                        simple_name: "南湖",
                        hidden: 0
                    },
                    {
                        value: "330411",
                        label: "秀洲区",
                        simple_name: "秀洲",
                        hidden: 0
                    },
                    {
                        value: "330421",
                        label: "嘉善县",
                        simple_name: "嘉善",
                        hidden: 0
                    },
                    {
                        value: "330424",
                        label: "海盐县",
                        simple_name: "海盐",
                        hidden: 0
                    },
                    {
                        value: "330481",
                        label: "海宁市",
                        simple_name: "海宁",
                        hidden: 0
                    },
                    {
                        value: "330482",
                        label: "平湖市",
                        simple_name: "平湖",
                        hidden: 0
                    },
                    {
                        value: "330483",
                        label: "桐乡市",
                        simple_name: "桐乡",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330500",
                label: "湖州市",
                simple_name: "湖州",
                hidden: 0,
                children: [
                    {
                        value: "330502",
                        label: "吴兴区",
                        simple_name: "吴兴",
                        hidden: 0
                    },
                    {
                        value: "330503",
                        label: "南浔区",
                        simple_name: "南浔",
                        hidden: 0
                    },
                    {
                        value: "330521",
                        label: "德清县",
                        simple_name: "德清",
                        hidden: 0
                    },
                    {
                        value: "330522",
                        label: "长兴县",
                        simple_name: "长兴",
                        hidden: 0
                    },
                    {
                        value: "330523",
                        label: "安吉县",
                        simple_name: "安吉",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330600",
                label: "绍兴市",
                simple_name: "绍兴",
                hidden: 0,
                children: [
                    {
                        value: "330602",
                        label: "越城区",
                        simple_name: "越城",
                        hidden: 0
                    },
                    {
                        value: "330603",
                        label: "柯桥区",
                        simple_name: "柯桥",
                        hidden: 0
                    },
                    {
                        value: "330604",
                        label: "上虞区",
                        simple_name: "上虞",
                        hidden: 0
                    },
                    {
                        value: "330624",
                        label: "新昌县",
                        simple_name: "新昌",
                        hidden: 0
                    },
                    {
                        value: "330681",
                        label: "诸暨市",
                        simple_name: "诸暨",
                        hidden: 0
                    },
                    {
                        value: "330683",
                        label: "嵊州市",
                        simple_name: "嵊州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330700",
                label: "金华市",
                simple_name: "金华",
                hidden: 0,
                children: [
                    {
                        value: "330702",
                        label: "婺城区",
                        simple_name: "婺城",
                        hidden: 0
                    },
                    {
                        value: "330703",
                        label: "金东区",
                        simple_name: "金东",
                        hidden: 0
                    },
                    {
                        value: "330723",
                        label: "武义县",
                        simple_name: "武义",
                        hidden: 0
                    },
                    {
                        value: "330726",
                        label: "浦江县",
                        simple_name: "浦江",
                        hidden: 0
                    },
                    {
                        value: "330727",
                        label: "磐安县",
                        simple_name: "磐安",
                        hidden: 0
                    },
                    {
                        value: "330781",
                        label: "兰溪市",
                        simple_name: "兰溪",
                        hidden: 0
                    },
                    {
                        value: "330782",
                        label: "义乌市",
                        simple_name: "义乌",
                        hidden: 0
                    },
                    {
                        value: "330783",
                        label: "东阳市",
                        simple_name: "东阳",
                        hidden: 0
                    },
                    {
                        value: "330784",
                        label: "永康市",
                        simple_name: "永康",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330800",
                label: "衢州市",
                simple_name: "衢州",
                hidden: 0,
                children: [
                    {
                        value: "330802",
                        label: "柯城区",
                        simple_name: "柯城",
                        hidden: 0
                    },
                    {
                        value: "330803",
                        label: "衢江区",
                        simple_name: "衢江",
                        hidden: 0
                    },
                    {
                        value: "330822",
                        label: "常山县",
                        simple_name: "常山",
                        hidden: 0
                    },
                    {
                        value: "330824",
                        label: "开化县",
                        simple_name: "开化",
                        hidden: 0
                    },
                    {
                        value: "330825",
                        label: "龙游县",
                        simple_name: "龙游",
                        hidden: 0
                    },
                    {
                        value: "330881",
                        label: "江山市",
                        simple_name: "江山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "330900",
                label: "舟山市",
                simple_name: "舟山",
                hidden: 0,
                children: [
                    {
                        value: "330902",
                        label: "定海区",
                        simple_name: "定海",
                        hidden: 0
                    },
                    {
                        value: "330903",
                        label: "普陀区",
                        simple_name: "普陀",
                        hidden: 0
                    },
                    {
                        value: "330921",
                        label: "岱山县",
                        simple_name: "岱山",
                        hidden: 0
                    },
                    {
                        value: "330922",
                        label: "嵊泗县",
                        simple_name: "嵊泗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "331000",
                label: "台州市",
                simple_name: "台州",
                hidden: 0,
                children: [
                    {
                        value: "331002",
                        label: "椒江区",
                        simple_name: "椒江",
                        hidden: 0
                    },
                    {
                        value: "331003",
                        label: "黄岩区",
                        simple_name: "黄岩",
                        hidden: 0
                    },
                    {
                        value: "331004",
                        label: "路桥区",
                        simple_name: "路桥",
                        hidden: 0
                    },
                    {
                        value: "331021",
                        label: "玉环县",
                        simple_name: "玉环",
                        hidden: 0
                    },
                    {
                        value: "331022",
                        label: "三门县",
                        simple_name: "三门",
                        hidden: 0
                    },
                    {
                        value: "331023",
                        label: "天台县",
                        simple_name: "天台",
                        hidden: 0
                    },
                    {
                        value: "331024",
                        label: "仙居县",
                        simple_name: "仙居",
                        hidden: 0
                    },
                    {
                        value: "331081",
                        label: "温岭市",
                        simple_name: "温岭",
                        hidden: 0
                    },
                    {
                        value: "331082",
                        label: "临海市",
                        simple_name: "临海",
                        hidden: 0
                    }
                ]
            },
            {
                value: "331100",
                label: "丽水市",
                simple_name: "丽水",
                hidden: 0,
                children: [
                    {
                        value: "331102",
                        label: "莲都区",
                        simple_name: "莲都",
                        hidden: 0
                    },
                    {
                        value: "331121",
                        label: "青田县",
                        simple_name: "青田",
                        hidden: 0
                    },
                    {
                        value: "331122",
                        label: "缙云县",
                        simple_name: "缙云",
                        hidden: 0
                    },
                    {
                        value: "331123",
                        label: "遂昌县",
                        simple_name: "遂昌",
                        hidden: 0
                    },
                    {
                        value: "331124",
                        label: "松阳县",
                        simple_name: "松阳",
                        hidden: 0
                    },
                    {
                        value: "331125",
                        label: "云和县",
                        simple_name: "云和",
                        hidden: 0
                    },
                    {
                        value: "331126",
                        label: "庆元县",
                        simple_name: "庆元",
                        hidden: 0
                    },
                    {
                        value: "331127",
                        label: "景宁畲族自治县",
                        simple_name: "景宁",
                        hidden: 0
                    },
                    {
                        value: "331181",
                        label: "龙泉市",
                        simple_name: "龙泉",
                        hidden: 0
                    }
                ]
            },
            {
                value: "331200",
                label: "舟山群岛新区",
                simple_name: "舟山新区",
                hidden: 0,
                children: [
                    {
                        value: "331201",
                        label: "金塘岛",
                        simple_name: "金塘",
                        hidden: 0
                    },
                    {
                        value: "331202",
                        label: "六横岛",
                        simple_name: "六横",
                        hidden: 0
                    },
                    {
                        value: "331203",
                        label: "衢山岛",
                        simple_name: "衢山",
                        hidden: 0
                    },
                    {
                        value: "331204",
                        label: "舟山本岛西北部",
                        simple_name: "舟山",
                        hidden: 0
                    },
                    {
                        value: "331205",
                        label: "岱山岛西南部",
                        simple_name: "岱山",
                        hidden: 0
                    },
                    {
                        value: "331206",
                        label: "泗礁岛",
                        simple_name: "泗礁",
                        hidden: 0
                    },
                    {
                        value: "331207",
                        label: "朱家尖岛",
                        simple_name: "朱家尖",
                        hidden: 0
                    },
                    {
                        value: "331208",
                        label: "洋山岛",
                        simple_name: "洋山",
                        hidden: 0
                    },
                    {
                        value: "331209",
                        label: "长涂岛",
                        simple_name: "长涂",
                        hidden: 0
                    },
                    {
                        value: "331210",
                        label: "虾峙岛",
                        simple_name: "虾峙",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "340000",
        label: "安徽省",
        simple_name: "安徽",
        hidden: 0,
        children: [
            {
                value: "340100",
                label: "合肥市",
                simple_name: "合肥",
                hidden: 0,
                children: [
                    {
                        value: "340102",
                        label: "瑶海区",
                        simple_name: "瑶海",
                        hidden: 0
                    },
                    {
                        value: "340103",
                        label: "庐阳区",
                        simple_name: "庐阳",
                        hidden: 0
                    },
                    {
                        value: "340104",
                        label: "蜀山区",
                        simple_name: "蜀山",
                        hidden: 0
                    },
                    {
                        value: "340111",
                        label: "包河区",
                        simple_name: "包河",
                        hidden: 0
                    },
                    {
                        value: "340121",
                        label: "长丰县",
                        simple_name: "长丰",
                        hidden: 0
                    },
                    {
                        value: "340122",
                        label: "肥东县",
                        simple_name: "肥东",
                        hidden: 0
                    },
                    {
                        value: "340123",
                        label: "肥西县",
                        simple_name: "肥西",
                        hidden: 0
                    },
                    {
                        value: "340124",
                        label: "庐江县",
                        simple_name: "庐江",
                        hidden: 0
                    },
                    {
                        value: "340181",
                        label: "巢湖市",
                        simple_name: "巢湖",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340200",
                label: "芜湖市",
                simple_name: "芜湖",
                hidden: 0,
                children: [
                    {
                        value: "340202",
                        label: "镜湖区",
                        simple_name: "镜湖",
                        hidden: 0
                    },
                    {
                        value: "340203",
                        label: "弋江区",
                        simple_name: "弋江",
                        hidden: 0
                    },
                    {
                        value: "340207",
                        label: "鸠江区",
                        simple_name: "鸠江",
                        hidden: 0
                    },
                    {
                        value: "340208",
                        label: "三山区",
                        simple_name: "三山",
                        hidden: 0
                    },
                    {
                        value: "340221",
                        label: "芜湖县",
                        simple_name: "芜湖",
                        hidden: 0
                    },
                    {
                        value: "340222",
                        label: "繁昌县",
                        simple_name: "繁昌",
                        hidden: 0
                    },
                    {
                        value: "340223",
                        label: "南陵县",
                        simple_name: "南陵",
                        hidden: 0
                    },
                    {
                        value: "340225",
                        label: "无为县",
                        simple_name: "无为",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340300",
                label: "蚌埠市",
                simple_name: "蚌埠",
                hidden: 0,
                children: [
                    {
                        value: "340302",
                        label: "龙子湖区",
                        simple_name: "龙子湖",
                        hidden: 0
                    },
                    {
                        value: "340303",
                        label: "蚌山区",
                        simple_name: "蚌山",
                        hidden: 0
                    },
                    {
                        value: "340304",
                        label: "禹会区",
                        simple_name: "禹会",
                        hidden: 0
                    },
                    {
                        value: "340311",
                        label: "淮上区",
                        simple_name: "淮上",
                        hidden: 0
                    },
                    {
                        value: "340321",
                        label: "怀远县",
                        simple_name: "怀远",
                        hidden: 0
                    },
                    {
                        value: "340322",
                        label: "五河县",
                        simple_name: "五河",
                        hidden: 0
                    },
                    {
                        value: "340323",
                        label: "固镇县",
                        simple_name: "固镇",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340400",
                label: "淮南市",
                simple_name: "淮南",
                hidden: 0,
                children: [
                    {
                        value: "340402",
                        label: "大通区",
                        simple_name: "大通",
                        hidden: 0
                    },
                    {
                        value: "340403",
                        label: "田家庵区",
                        simple_name: "田家庵",
                        hidden: 0
                    },
                    {
                        value: "340404",
                        label: "谢家集区",
                        simple_name: "谢家集",
                        hidden: 0
                    },
                    {
                        value: "340405",
                        label: "八公山区",
                        simple_name: "八公山",
                        hidden: 0
                    },
                    {
                        value: "340406",
                        label: "潘集区",
                        simple_name: "潘集",
                        hidden: 0
                    },
                    {
                        value: "340421",
                        label: "凤台县",
                        simple_name: "凤台",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340500",
                label: "马鞍山市",
                simple_name: "马鞍山",
                hidden: 0,
                children: [
                    {
                        value: "340503",
                        label: "花山区",
                        simple_name: "花山",
                        hidden: 0
                    },
                    {
                        value: "340504",
                        label: "雨山区",
                        simple_name: "雨山",
                        hidden: 0
                    },
                    {
                        value: "340506",
                        label: "博望区",
                        simple_name: "博望",
                        hidden: 0
                    },
                    {
                        value: "340521",
                        label: "当涂县",
                        simple_name: "当涂",
                        hidden: 0
                    },
                    {
                        value: "340522",
                        label: "含山县",
                        simple_name: "含山",
                        hidden: 0
                    },
                    {
                        value: "340523",
                        label: "和县",
                        simple_name: "和县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340600",
                label: "淮北市",
                simple_name: "淮北",
                hidden: 0,
                children: [
                    {
                        value: "340602",
                        label: "杜集区",
                        simple_name: "杜集",
                        hidden: 0
                    },
                    {
                        value: "340603",
                        label: "相山区",
                        simple_name: "相山",
                        hidden: 0
                    },
                    {
                        value: "340604",
                        label: "烈山区",
                        simple_name: "烈山",
                        hidden: 0
                    },
                    {
                        value: "340621",
                        label: "濉溪县",
                        simple_name: "濉溪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340700",
                label: "铜陵市",
                simple_name: "铜陵",
                hidden: 0,
                children: [
                    {
                        value: "340702",
                        label: "铜官山区",
                        simple_name: "铜官山",
                        hidden: 0
                    },
                    {
                        value: "340703",
                        label: "狮子山区",
                        simple_name: "狮子山",
                        hidden: 0
                    },
                    {
                        value: "340711",
                        label: "郊区",
                        simple_name: "郊区",
                        hidden: 0
                    },
                    {
                        value: "340721",
                        label: "铜陵县",
                        simple_name: "铜陵",
                        hidden: 0
                    }
                ]
            },
            {
                value: "340800",
                label: "安庆市",
                simple_name: "安庆",
                hidden: 0,
                children: [
                    {
                        value: "340802",
                        label: "迎江区",
                        simple_name: "迎江",
                        hidden: 0
                    },
                    {
                        value: "340803",
                        label: "大观区",
                        simple_name: "大观",
                        hidden: 0
                    },
                    {
                        value: "340811",
                        label: "宜秀区",
                        simple_name: "宜秀",
                        hidden: 0
                    },
                    {
                        value: "340822",
                        label: "怀宁县",
                        simple_name: "怀宁",
                        hidden: 0
                    },
                    {
                        value: "340823",
                        label: "枞阳县",
                        simple_name: "枞阳",
                        hidden: 0
                    },
                    {
                        value: "340824",
                        label: "潜山县",
                        simple_name: "潜山",
                        hidden: 0
                    },
                    {
                        value: "340825",
                        label: "太湖县",
                        simple_name: "太湖",
                        hidden: 0
                    },
                    {
                        value: "340826",
                        label: "宿松县",
                        simple_name: "宿松",
                        hidden: 0
                    },
                    {
                        value: "340827",
                        label: "望江县",
                        simple_name: "望江",
                        hidden: 0
                    },
                    {
                        value: "340828",
                        label: "岳西县",
                        simple_name: "岳西",
                        hidden: 0
                    },
                    {
                        value: "340881",
                        label: "桐城市",
                        simple_name: "桐城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341000",
                label: "黄山市",
                simple_name: "黄山",
                hidden: 0,
                children: [
                    {
                        value: "341002",
                        label: "屯溪区",
                        simple_name: "屯溪",
                        hidden: 0
                    },
                    {
                        value: "341003",
                        label: "黄山区",
                        simple_name: "黄山",
                        hidden: 0
                    },
                    {
                        value: "341004",
                        label: "徽州区",
                        simple_name: "徽州",
                        hidden: 0
                    },
                    {
                        value: "341021",
                        label: "歙县",
                        simple_name: "歙县",
                        hidden: 0
                    },
                    {
                        value: "341022",
                        label: "休宁县",
                        simple_name: "休宁",
                        hidden: 0
                    },
                    {
                        value: "341023",
                        label: "黟县",
                        simple_name: "黟县",
                        hidden: 0
                    },
                    {
                        value: "341024",
                        label: "祁门县",
                        simple_name: "祁门",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341100",
                label: "滁州市",
                simple_name: "滁州",
                hidden: 0,
                children: [
                    {
                        value: "341102",
                        label: "琅琊区",
                        simple_name: "琅琊",
                        hidden: 0
                    },
                    {
                        value: "341103",
                        label: "南谯区",
                        simple_name: "南谯",
                        hidden: 0
                    },
                    {
                        value: "341122",
                        label: "来安县",
                        simple_name: "来安",
                        hidden: 0
                    },
                    {
                        value: "341124",
                        label: "全椒县",
                        simple_name: "全椒",
                        hidden: 0
                    },
                    {
                        value: "341125",
                        label: "定远县",
                        simple_name: "定远",
                        hidden: 0
                    },
                    {
                        value: "341126",
                        label: "凤阳县",
                        simple_name: "凤阳",
                        hidden: 0
                    },
                    {
                        value: "341181",
                        label: "天长市",
                        simple_name: "天长",
                        hidden: 0
                    },
                    {
                        value: "341182",
                        label: "明光市",
                        simple_name: "明光",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341200",
                label: "阜阳市",
                simple_name: "阜阳",
                hidden: 0,
                children: [
                    {
                        value: "341202",
                        label: "颍州区",
                        simple_name: "颍州",
                        hidden: 0
                    },
                    {
                        value: "341203",
                        label: "颍东区",
                        simple_name: "颍东",
                        hidden: 0
                    },
                    {
                        value: "341204",
                        label: "颍泉区",
                        simple_name: "颍泉",
                        hidden: 0
                    },
                    {
                        value: "341221",
                        label: "临泉县",
                        simple_name: "临泉",
                        hidden: 0
                    },
                    {
                        value: "341222",
                        label: "太和县",
                        simple_name: "太和",
                        hidden: 0
                    },
                    {
                        value: "341225",
                        label: "阜南县",
                        simple_name: "阜南",
                        hidden: 0
                    },
                    {
                        value: "341226",
                        label: "颍上县",
                        simple_name: "颍上",
                        hidden: 0
                    },
                    {
                        value: "341282",
                        label: "界首市",
                        simple_name: "界首",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341300",
                label: "宿州市",
                simple_name: "宿州",
                hidden: 0,
                children: [
                    {
                        value: "341302",
                        label: "埇桥区",
                        simple_name: "埇桥",
                        hidden: 0
                    },
                    {
                        value: "341321",
                        label: "砀山县",
                        simple_name: "砀山",
                        hidden: 0
                    },
                    {
                        value: "341322",
                        label: "萧县",
                        simple_name: "萧县",
                        hidden: 0
                    },
                    {
                        value: "341323",
                        label: "灵璧县",
                        simple_name: "灵璧",
                        hidden: 0
                    },
                    {
                        value: "341324",
                        label: "泗县",
                        simple_name: "泗县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341500",
                label: "六安市",
                simple_name: "六安",
                hidden: 0,
                children: [
                    {
                        value: "341502",
                        label: "金安区",
                        simple_name: "金安",
                        hidden: 0
                    },
                    {
                        value: "341503",
                        label: "裕安区",
                        simple_name: "裕安",
                        hidden: 0
                    },
                    {
                        value: "341521",
                        label: "寿县",
                        simple_name: "寿县",
                        hidden: 0
                    },
                    {
                        value: "341522",
                        label: "霍邱县",
                        simple_name: "霍邱",
                        hidden: 0
                    },
                    {
                        value: "341523",
                        label: "舒城县",
                        simple_name: "舒城",
                        hidden: 0
                    },
                    {
                        value: "341524",
                        label: "金寨县",
                        simple_name: "金寨",
                        hidden: 0
                    },
                    {
                        value: "341525",
                        label: "霍山县",
                        simple_name: "霍山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341600",
                label: "亳州市",
                simple_name: "亳州",
                hidden: 0,
                children: [
                    {
                        value: "341602",
                        label: "谯城区",
                        simple_name: "谯城",
                        hidden: 0
                    },
                    {
                        value: "341621",
                        label: "涡阳县",
                        simple_name: "涡阳",
                        hidden: 0
                    },
                    {
                        value: "341622",
                        label: "蒙城县",
                        simple_name: "蒙城",
                        hidden: 0
                    },
                    {
                        value: "341623",
                        label: "利辛县",
                        simple_name: "利辛",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341700",
                label: "池州市",
                simple_name: "池州",
                hidden: 0,
                children: [
                    {
                        value: "341702",
                        label: "贵池区",
                        simple_name: "贵池",
                        hidden: 0
                    },
                    {
                        value: "341721",
                        label: "东至县",
                        simple_name: "东至",
                        hidden: 0
                    },
                    {
                        value: "341722",
                        label: "石台县",
                        simple_name: "石台",
                        hidden: 0
                    },
                    {
                        value: "341723",
                        label: "青阳县",
                        simple_name: "青阳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "341800",
                label: "宣城市",
                simple_name: "宣城",
                hidden: 0,
                children: [
                    {
                        value: "341802",
                        label: "宣州区",
                        simple_name: "宣州",
                        hidden: 0
                    },
                    {
                        value: "341821",
                        label: "郎溪县",
                        simple_name: "郎溪",
                        hidden: 0
                    },
                    {
                        value: "341822",
                        label: "广德县",
                        simple_name: "广德",
                        hidden: 0
                    },
                    {
                        value: "341823",
                        label: "泾县",
                        simple_name: "泾县",
                        hidden: 0
                    },
                    {
                        value: "341824",
                        label: "绩溪县",
                        simple_name: "绩溪",
                        hidden: 0
                    },
                    {
                        value: "341825",
                        label: "旌德县",
                        simple_name: "旌德",
                        hidden: 0
                    },
                    {
                        value: "341881",
                        label: "宁国市",
                        simple_name: "宁国",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "350000",
        label: "福建省",
        simple_name: "福建",
        hidden: 0,
        children: [
            {
                value: "350100",
                label: "福州市",
                simple_name: "福州",
                hidden: 0,
                children: [
                    {
                        value: "350102",
                        label: "鼓楼区",
                        simple_name: "鼓楼",
                        hidden: 0
                    },
                    {
                        value: "350103",
                        label: "台江区",
                        simple_name: "台江",
                        hidden: 0
                    },
                    {
                        value: "350104",
                        label: "仓山区",
                        simple_name: "仓山",
                        hidden: 0
                    },
                    {
                        value: "350105",
                        label: "马尾区",
                        simple_name: "马尾",
                        hidden: 0
                    },
                    {
                        value: "350111",
                        label: "晋安区",
                        simple_name: "晋安",
                        hidden: 0
                    },
                    {
                        value: "350121",
                        label: "闽侯县",
                        simple_name: "闽侯",
                        hidden: 0
                    },
                    {
                        value: "350122",
                        label: "连江县",
                        simple_name: "连江",
                        hidden: 0
                    },
                    {
                        value: "350123",
                        label: "罗源县",
                        simple_name: "罗源",
                        hidden: 0
                    },
                    {
                        value: "350124",
                        label: "闽清县",
                        simple_name: "闽清",
                        hidden: 0
                    },
                    {
                        value: "350125",
                        label: "永泰县",
                        simple_name: "永泰",
                        hidden: 0
                    },
                    {
                        value: "350128",
                        label: "平潭县",
                        simple_name: "平潭",
                        hidden: 0
                    },
                    {
                        value: "350181",
                        label: "福清市",
                        simple_name: "福清",
                        hidden: 0
                    },
                    {
                        value: "350182",
                        label: "长乐市",
                        simple_name: "长乐",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350200",
                label: "厦门市",
                simple_name: "厦门",
                hidden: 0,
                children: [
                    {
                        value: "350203",
                        label: "思明区",
                        simple_name: "思明",
                        hidden: 0
                    },
                    {
                        value: "350205",
                        label: "海沧区",
                        simple_name: "海沧",
                        hidden: 0
                    },
                    {
                        value: "350206",
                        label: "湖里区",
                        simple_name: "湖里",
                        hidden: 0
                    },
                    {
                        value: "350211",
                        label: "集美区",
                        simple_name: "集美",
                        hidden: 0
                    },
                    {
                        value: "350212",
                        label: "同安区",
                        simple_name: "同安",
                        hidden: 0
                    },
                    {
                        value: "350213",
                        label: "翔安区",
                        simple_name: "翔安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350300",
                label: "莆田市",
                simple_name: "莆田",
                hidden: 0,
                children: [
                    {
                        value: "350302",
                        label: "城厢区",
                        simple_name: "城厢",
                        hidden: 0
                    },
                    {
                        value: "350303",
                        label: "涵江区",
                        simple_name: "涵江",
                        hidden: 0
                    },
                    {
                        value: "350304",
                        label: "荔城区",
                        simple_name: "荔城",
                        hidden: 0
                    },
                    {
                        value: "350305",
                        label: "秀屿区",
                        simple_name: "秀屿",
                        hidden: 0
                    },
                    {
                        value: "350322",
                        label: "仙游县",
                        simple_name: "仙游",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350400",
                label: "三明市",
                simple_name: "三明",
                hidden: 0,
                children: [
                    {
                        value: "350402",
                        label: "梅列区",
                        simple_name: "梅列",
                        hidden: 0
                    },
                    {
                        value: "350403",
                        label: "三元区",
                        simple_name: "三元",
                        hidden: 0
                    },
                    {
                        value: "350421",
                        label: "明溪县",
                        simple_name: "明溪",
                        hidden: 0
                    },
                    {
                        value: "350423",
                        label: "清流县",
                        simple_name: "清流",
                        hidden: 0
                    },
                    {
                        value: "350424",
                        label: "宁化县",
                        simple_name: "宁化",
                        hidden: 0
                    },
                    {
                        value: "350425",
                        label: "大田县",
                        simple_name: "大田",
                        hidden: 0
                    },
                    {
                        value: "350426",
                        label: "尤溪县",
                        simple_name: "尤溪",
                        hidden: 0
                    },
                    {
                        value: "350427",
                        label: "沙县",
                        simple_name: "沙县",
                        hidden: 0
                    },
                    {
                        value: "350428",
                        label: "将乐县",
                        simple_name: "将乐",
                        hidden: 0
                    },
                    {
                        value: "350429",
                        label: "泰宁县",
                        simple_name: "泰宁",
                        hidden: 0
                    },
                    {
                        value: "350430",
                        label: "建宁县",
                        simple_name: "建宁",
                        hidden: 0
                    },
                    {
                        value: "350481",
                        label: "永安市",
                        simple_name: "永安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350500",
                label: "泉州市",
                simple_name: "泉州",
                hidden: 0,
                children: [
                    {
                        value: "350502",
                        label: "鲤城区",
                        simple_name: "鲤城",
                        hidden: 0
                    },
                    {
                        value: "350503",
                        label: "丰泽区",
                        simple_name: "丰泽",
                        hidden: 0
                    },
                    {
                        value: "350504",
                        label: "洛江区",
                        simple_name: "洛江",
                        hidden: 0
                    },
                    {
                        value: "350505",
                        label: "泉港区",
                        simple_name: "泉港",
                        hidden: 0
                    },
                    {
                        value: "350521",
                        label: "惠安县",
                        simple_name: "惠安",
                        hidden: 0
                    },
                    {
                        value: "350524",
                        label: "安溪县",
                        simple_name: "安溪",
                        hidden: 0
                    },
                    {
                        value: "350525",
                        label: "永春县",
                        simple_name: "永春",
                        hidden: 0
                    },
                    {
                        value: "350526",
                        label: "德化县",
                        simple_name: "德化",
                        hidden: 0
                    },
                    {
                        value: "350527",
                        label: "金门县",
                        simple_name: "金门",
                        hidden: 0
                    },
                    {
                        value: "350581",
                        label: "石狮市",
                        simple_name: "石狮",
                        hidden: 0
                    },
                    {
                        value: "350582",
                        label: "晋江市",
                        simple_name: "晋江",
                        hidden: 0
                    },
                    {
                        value: "350583",
                        label: "南安市",
                        simple_name: "南安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350600",
                label: "漳州市",
                simple_name: "漳州",
                hidden: 0,
                children: [
                    {
                        value: "350602",
                        label: "芗城区",
                        simple_name: "芗城",
                        hidden: 0
                    },
                    {
                        value: "350603",
                        label: "龙文区",
                        simple_name: "龙文",
                        hidden: 0
                    },
                    {
                        value: "350622",
                        label: "云霄县",
                        simple_name: "云霄",
                        hidden: 0
                    },
                    {
                        value: "350623",
                        label: "漳浦县",
                        simple_name: "漳浦",
                        hidden: 0
                    },
                    {
                        value: "350624",
                        label: "诏安县",
                        simple_name: "诏安",
                        hidden: 0
                    },
                    {
                        value: "350625",
                        label: "长泰县",
                        simple_name: "长泰",
                        hidden: 0
                    },
                    {
                        value: "350626",
                        label: "东山县",
                        simple_name: "东山",
                        hidden: 0
                    },
                    {
                        value: "350627",
                        label: "南靖县",
                        simple_name: "南靖",
                        hidden: 0
                    },
                    {
                        value: "350628",
                        label: "平和县",
                        simple_name: "平和",
                        hidden: 0
                    },
                    {
                        value: "350629",
                        label: "华安县",
                        simple_name: "华安",
                        hidden: 0
                    },
                    {
                        value: "350681",
                        label: "龙海市",
                        simple_name: "龙海",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350700",
                label: "南平市",
                simple_name: "南平",
                hidden: 0,
                children: [
                    {
                        value: "350702",
                        label: "延平区",
                        simple_name: "延平",
                        hidden: 0
                    },
                    {
                        value: "350703",
                        label: "建阳区",
                        simple_name: "建阳",
                        hidden: 0
                    },
                    {
                        value: "350721",
                        label: "顺昌县",
                        simple_name: "顺昌",
                        hidden: 0
                    },
                    {
                        value: "350722",
                        label: "浦城县",
                        simple_name: "浦城",
                        hidden: 0
                    },
                    {
                        value: "350723",
                        label: "光泽县",
                        simple_name: "光泽",
                        hidden: 0
                    },
                    {
                        value: "350724",
                        label: "松溪县",
                        simple_name: "松溪",
                        hidden: 0
                    },
                    {
                        value: "350725",
                        label: "政和县",
                        simple_name: "政和",
                        hidden: 0
                    },
                    {
                        value: "350781",
                        label: "邵武市",
                        simple_name: "邵武",
                        hidden: 0
                    },
                    {
                        value: "350782",
                        label: "武夷山市",
                        simple_name: "武夷山",
                        hidden: 0
                    },
                    {
                        value: "350783",
                        label: "建瓯市",
                        simple_name: "建瓯",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350800",
                label: "龙岩市",
                simple_name: "龙岩",
                hidden: 0,
                children: [
                    {
                        value: "350802",
                        label: "新罗区",
                        simple_name: "新罗",
                        hidden: 0
                    },
                    {
                        value: "350821",
                        label: "长汀县",
                        simple_name: "长汀",
                        hidden: 0
                    },
                    {
                        value: "350822",
                        label: "永定区",
                        simple_name: "永定",
                        hidden: 0
                    },
                    {
                        value: "350823",
                        label: "上杭县",
                        simple_name: "上杭",
                        hidden: 0
                    },
                    {
                        value: "350824",
                        label: "武平县",
                        simple_name: "武平",
                        hidden: 0
                    },
                    {
                        value: "350825",
                        label: "连城县",
                        simple_name: "连城",
                        hidden: 0
                    },
                    {
                        value: "350881",
                        label: "漳平市",
                        simple_name: "漳平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "350900",
                label: "宁德市",
                simple_name: "宁德",
                hidden: 0,
                children: [
                    {
                        value: "350902",
                        label: "蕉城区",
                        simple_name: "蕉城",
                        hidden: 0
                    },
                    {
                        value: "350921",
                        label: "霞浦县",
                        simple_name: "霞浦",
                        hidden: 0
                    },
                    {
                        value: "350922",
                        label: "古田县",
                        simple_name: "古田",
                        hidden: 0
                    },
                    {
                        value: "350923",
                        label: "屏南县",
                        simple_name: "屏南",
                        hidden: 0
                    },
                    {
                        value: "350924",
                        label: "寿宁县",
                        simple_name: "寿宁",
                        hidden: 0
                    },
                    {
                        value: "350925",
                        label: "周宁县",
                        simple_name: "周宁",
                        hidden: 0
                    },
                    {
                        value: "350926",
                        label: "柘荣县",
                        simple_name: "柘荣",
                        hidden: 0
                    },
                    {
                        value: "350981",
                        label: "福安市",
                        simple_name: "福安",
                        hidden: 0
                    },
                    {
                        value: "350982",
                        label: "福鼎市",
                        simple_name: "福鼎",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "360000",
        label: "江西省",
        simple_name: "江西",
        hidden: 0,
        children: [
            {
                value: "360100",
                label: "南昌市",
                simple_name: "南昌",
                hidden: 0,
                children: [
                    {
                        value: "360102",
                        label: "东湖区",
                        simple_name: "东湖",
                        hidden: 0
                    },
                    {
                        value: "360103",
                        label: "西湖区",
                        simple_name: "西湖",
                        hidden: 0
                    },
                    {
                        value: "360104",
                        label: "青云谱区",
                        simple_name: "青云谱",
                        hidden: 0
                    },
                    {
                        value: "360105",
                        label: "湾里区",
                        simple_name: "湾里",
                        hidden: 0
                    },
                    {
                        value: "360111",
                        label: "青山湖区",
                        simple_name: "青山湖",
                        hidden: 0
                    },
                    {
                        value: "360121",
                        label: "南昌县",
                        simple_name: "南昌",
                        hidden: 0
                    },
                    {
                        value: "360122",
                        label: "新建县",
                        simple_name: "新建",
                        hidden: 0
                    },
                    {
                        value: "360123",
                        label: "安义县",
                        simple_name: "安义",
                        hidden: 0
                    },
                    {
                        value: "360124",
                        label: "进贤县",
                        simple_name: "进贤",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360200",
                label: "景德镇市",
                simple_name: "景德镇",
                hidden: 0,
                children: [
                    {
                        value: "360202",
                        label: "昌江区",
                        simple_name: "昌江",
                        hidden: 0
                    },
                    {
                        value: "360203",
                        label: "珠山区",
                        simple_name: "珠山",
                        hidden: 0
                    },
                    {
                        value: "360222",
                        label: "浮梁县",
                        simple_name: "浮梁",
                        hidden: 0
                    },
                    {
                        value: "360281",
                        label: "乐平市",
                        simple_name: "乐平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360300",
                label: "萍乡市",
                simple_name: "萍乡",
                hidden: 0,
                children: [
                    {
                        value: "360302",
                        label: "安源区",
                        simple_name: "安源",
                        hidden: 0
                    },
                    {
                        value: "360313",
                        label: "湘东区",
                        simple_name: "湘东",
                        hidden: 0
                    },
                    {
                        value: "360321",
                        label: "莲花县",
                        simple_name: "莲花",
                        hidden: 0
                    },
                    {
                        value: "360322",
                        label: "上栗县",
                        simple_name: "上栗",
                        hidden: 0
                    },
                    {
                        value: "360323",
                        label: "芦溪县",
                        simple_name: "芦溪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360400",
                label: "九江市",
                simple_name: "九江",
                hidden: 0,
                children: [
                    {
                        value: "360402",
                        label: "庐山区",
                        simple_name: "庐山",
                        hidden: 0
                    },
                    {
                        value: "360403",
                        label: "浔阳区",
                        simple_name: "浔阳",
                        hidden: 0
                    },
                    {
                        value: "360421",
                        label: "九江县",
                        simple_name: "九江",
                        hidden: 0
                    },
                    {
                        value: "360423",
                        label: "武宁县",
                        simple_name: "武宁",
                        hidden: 0
                    },
                    {
                        value: "360424",
                        label: "修水县",
                        simple_name: "修水",
                        hidden: 0
                    },
                    {
                        value: "360425",
                        label: "永修县",
                        simple_name: "永修",
                        hidden: 0
                    },
                    {
                        value: "360426",
                        label: "德安县",
                        simple_name: "德安",
                        hidden: 0
                    },
                    {
                        value: "360427",
                        label: "星子县",
                        simple_name: "星子",
                        hidden: 0
                    },
                    {
                        value: "360428",
                        label: "都昌县",
                        simple_name: "都昌",
                        hidden: 0
                    },
                    {
                        value: "360429",
                        label: "湖口县",
                        simple_name: "湖口",
                        hidden: 0
                    },
                    {
                        value: "360430",
                        label: "彭泽县",
                        simple_name: "彭泽",
                        hidden: 0
                    },
                    {
                        value: "360481",
                        label: "瑞昌市",
                        simple_name: "瑞昌",
                        hidden: 0
                    },
                    {
                        value: "360482",
                        label: "共青城市",
                        simple_name: "共青城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360500",
                label: "新余市",
                simple_name: "新余",
                hidden: 0,
                children: [
                    {
                        value: "360502",
                        label: "渝水区",
                        simple_name: "渝水",
                        hidden: 0
                    },
                    {
                        value: "360521",
                        label: "分宜县",
                        simple_name: "分宜",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360600",
                label: "鹰潭市",
                simple_name: "鹰潭",
                hidden: 0,
                children: [
                    {
                        value: "360602",
                        label: "月湖区",
                        simple_name: "月湖",
                        hidden: 0
                    },
                    {
                        value: "360622",
                        label: "余江县",
                        simple_name: "余江",
                        hidden: 0
                    },
                    {
                        value: "360681",
                        label: "贵溪市",
                        simple_name: "贵溪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360700",
                label: "赣州市",
                simple_name: "赣州",
                hidden: 0,
                children: [
                    {
                        value: "360702",
                        label: "章贡区",
                        simple_name: "章贡",
                        hidden: 0
                    },
                    {
                        value: "360703",
                        label: "南康区",
                        simple_name: "南康",
                        hidden: 0
                    },
                    {
                        value: "360721",
                        label: "赣县",
                        simple_name: "赣县",
                        hidden: 0
                    },
                    {
                        value: "360722",
                        label: "信丰县",
                        simple_name: "信丰",
                        hidden: 0
                    },
                    {
                        value: "360723",
                        label: "大余县",
                        simple_name: "大余",
                        hidden: 0
                    },
                    {
                        value: "360724",
                        label: "上犹县",
                        simple_name: "上犹",
                        hidden: 0
                    },
                    {
                        value: "360725",
                        label: "崇义县",
                        simple_name: "崇义",
                        hidden: 0
                    },
                    {
                        value: "360726",
                        label: "安远县",
                        simple_name: "安远",
                        hidden: 0
                    },
                    {
                        value: "360727",
                        label: "龙南县",
                        simple_name: "龙南",
                        hidden: 0
                    },
                    {
                        value: "360728",
                        label: "定南县",
                        simple_name: "定南",
                        hidden: 0
                    },
                    {
                        value: "360729",
                        label: "全南县",
                        simple_name: "全南",
                        hidden: 0
                    },
                    {
                        value: "360730",
                        label: "宁都县",
                        simple_name: "宁都",
                        hidden: 0
                    },
                    {
                        value: "360731",
                        label: "于都县",
                        simple_name: "于都",
                        hidden: 0
                    },
                    {
                        value: "360732",
                        label: "兴国县",
                        simple_name: "兴国",
                        hidden: 0
                    },
                    {
                        value: "360733",
                        label: "会昌县",
                        simple_name: "会昌",
                        hidden: 0
                    },
                    {
                        value: "360734",
                        label: "寻乌县",
                        simple_name: "寻乌",
                        hidden: 0
                    },
                    {
                        value: "360735",
                        label: "石城县",
                        simple_name: "石城",
                        hidden: 0
                    },
                    {
                        value: "360781",
                        label: "瑞金市",
                        simple_name: "瑞金",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360800",
                label: "吉安市",
                simple_name: "吉安",
                hidden: 0,
                children: [
                    {
                        value: "360802",
                        label: "吉州区",
                        simple_name: "吉州",
                        hidden: 0
                    },
                    {
                        value: "360803",
                        label: "青原区",
                        simple_name: "青原",
                        hidden: 0
                    },
                    {
                        value: "360821",
                        label: "吉安县",
                        simple_name: "吉安",
                        hidden: 0
                    },
                    {
                        value: "360822",
                        label: "吉水县",
                        simple_name: "吉水",
                        hidden: 0
                    },
                    {
                        value: "360823",
                        label: "峡江县",
                        simple_name: "峡江",
                        hidden: 0
                    },
                    {
                        value: "360824",
                        label: "新干县",
                        simple_name: "新干",
                        hidden: 0
                    },
                    {
                        value: "360825",
                        label: "永丰县",
                        simple_name: "永丰",
                        hidden: 0
                    },
                    {
                        value: "360826",
                        label: "泰和县",
                        simple_name: "泰和",
                        hidden: 0
                    },
                    {
                        value: "360827",
                        label: "遂川县",
                        simple_name: "遂川",
                        hidden: 0
                    },
                    {
                        value: "360828",
                        label: "万安县",
                        simple_name: "万安",
                        hidden: 0
                    },
                    {
                        value: "360829",
                        label: "安福县",
                        simple_name: "安福",
                        hidden: 0
                    },
                    {
                        value: "360830",
                        label: "永新县",
                        simple_name: "永新",
                        hidden: 0
                    },
                    {
                        value: "360881",
                        label: "井冈山市",
                        simple_name: "井冈山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "360900",
                label: "宜春市",
                simple_name: "宜春",
                hidden: 0,
                children: [
                    {
                        value: "360902",
                        label: "袁州区",
                        simple_name: "袁州",
                        hidden: 0
                    },
                    {
                        value: "360921",
                        label: "奉新县",
                        simple_name: "奉新",
                        hidden: 0
                    },
                    {
                        value: "360922",
                        label: "万载县",
                        simple_name: "万载",
                        hidden: 0
                    },
                    {
                        value: "360923",
                        label: "上高县",
                        simple_name: "上高",
                        hidden: 0
                    },
                    {
                        value: "360924",
                        label: "宜丰县",
                        simple_name: "宜丰",
                        hidden: 0
                    },
                    {
                        value: "360925",
                        label: "靖安县",
                        simple_name: "靖安",
                        hidden: 0
                    },
                    {
                        value: "360926",
                        label: "铜鼓县",
                        simple_name: "铜鼓",
                        hidden: 0
                    },
                    {
                        value: "360981",
                        label: "丰城市",
                        simple_name: "丰城",
                        hidden: 0
                    },
                    {
                        value: "360982",
                        label: "樟树市",
                        simple_name: "樟树",
                        hidden: 0
                    },
                    {
                        value: "360983",
                        label: "高安市",
                        simple_name: "高安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "361000",
                label: "抚州市",
                simple_name: "抚州",
                hidden: 0,
                children: [
                    {
                        value: "361002",
                        label: "临川区",
                        simple_name: "临川",
                        hidden: 0
                    },
                    {
                        value: "361021",
                        label: "南城县",
                        simple_name: "南城",
                        hidden: 0
                    },
                    {
                        value: "361022",
                        label: "黎川县",
                        simple_name: "黎川",
                        hidden: 0
                    },
                    {
                        value: "361023",
                        label: "南丰县",
                        simple_name: "南丰",
                        hidden: 0
                    },
                    {
                        value: "361024",
                        label: "崇仁县",
                        simple_name: "崇仁",
                        hidden: 0
                    },
                    {
                        value: "361025",
                        label: "乐安县",
                        simple_name: "乐安",
                        hidden: 0
                    },
                    {
                        value: "361026",
                        label: "宜黄县",
                        simple_name: "宜黄",
                        hidden: 0
                    },
                    {
                        value: "361027",
                        label: "金溪县",
                        simple_name: "金溪",
                        hidden: 0
                    },
                    {
                        value: "361028",
                        label: "资溪县",
                        simple_name: "资溪",
                        hidden: 0
                    },
                    {
                        value: "361029",
                        label: "东乡县",
                        simple_name: "东乡",
                        hidden: 0
                    },
                    {
                        value: "361030",
                        label: "广昌县",
                        simple_name: "广昌",
                        hidden: 0
                    }
                ]
            },
            {
                value: "361100",
                label: "上饶市",
                simple_name: "上饶",
                hidden: 0,
                children: [
                    {
                        value: "361102",
                        label: "信州区",
                        simple_name: "信州",
                        hidden: 0
                    },
                    {
                        value: "361121",
                        label: "上饶县",
                        simple_name: "上饶",
                        hidden: 0
                    },
                    {
                        value: "361122",
                        label: "广丰县",
                        simple_name: "广丰",
                        hidden: 0
                    },
                    {
                        value: "361123",
                        label: "玉山县",
                        simple_name: "玉山",
                        hidden: 0
                    },
                    {
                        value: "361124",
                        label: "铅山县",
                        simple_name: "铅山",
                        hidden: 0
                    },
                    {
                        value: "361125",
                        label: "横峰县",
                        simple_name: "横峰",
                        hidden: 0
                    },
                    {
                        value: "361126",
                        label: "弋阳县",
                        simple_name: "弋阳",
                        hidden: 0
                    },
                    {
                        value: "361127",
                        label: "余干县",
                        simple_name: "余干",
                        hidden: 0
                    },
                    {
                        value: "361128",
                        label: "鄱阳县",
                        simple_name: "鄱阳",
                        hidden: 0
                    },
                    {
                        value: "361129",
                        label: "万年县",
                        simple_name: "万年",
                        hidden: 0
                    },
                    {
                        value: "361130",
                        label: "婺源县",
                        simple_name: "婺源",
                        hidden: 0
                    },
                    {
                        value: "361181",
                        label: "德兴市",
                        simple_name: "德兴",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "370000",
        label: "山东省",
        simple_name: "山东",
        hidden: 0,
        children: [
            {
                value: "370100",
                label: "济南市",
                simple_name: "济南",
                hidden: 0,
                children: [
                    {
                        value: "370102",
                        label: "历下区",
                        simple_name: "历下",
                        hidden: 0
                    },
                    {
                        value: "370103",
                        label: "市中区",
                        simple_name: "市中区",
                        hidden: 0
                    },
                    {
                        value: "370104",
                        label: "槐荫区",
                        simple_name: "槐荫",
                        hidden: 0
                    },
                    {
                        value: "370105",
                        label: "天桥区",
                        simple_name: "天桥",
                        hidden: 0
                    },
                    {
                        value: "370112",
                        label: "历城区",
                        simple_name: "历城",
                        hidden: 0
                    },
                    {
                        value: "370113",
                        label: "长清区",
                        simple_name: "长清",
                        hidden: 0
                    },
                    {
                        value: "370124",
                        label: "平阴县",
                        simple_name: "平阴",
                        hidden: 0
                    },
                    {
                        value: "370125",
                        label: "济阳县",
                        simple_name: "济阳",
                        hidden: 0
                    },
                    {
                        value: "370126",
                        label: "商河县",
                        simple_name: "商河",
                        hidden: 0
                    },
                    {
                        value: "370181",
                        label: "章丘市",
                        simple_name: "章丘",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370200",
                label: "青岛市",
                simple_name: "青岛",
                hidden: 0,
                children: [
                    {
                        value: "370202",
                        label: "市南区",
                        simple_name: "市南",
                        hidden: 0
                    },
                    {
                        value: "370203",
                        label: "市北区",
                        simple_name: "市北",
                        hidden: 0
                    },
                    {
                        value: "370211",
                        label: "黄岛区",
                        simple_name: "黄岛",
                        hidden: 0
                    },
                    {
                        value: "370212",
                        label: "崂山区",
                        simple_name: "崂山",
                        hidden: 0
                    },
                    {
                        value: "370213",
                        label: "李沧区",
                        simple_name: "李沧",
                        hidden: 0
                    },
                    {
                        value: "370214",
                        label: "城阳区",
                        simple_name: "城阳",
                        hidden: 0
                    },
                    {
                        value: "370281",
                        label: "胶州市",
                        simple_name: "胶州",
                        hidden: 0
                    },
                    {
                        value: "370282",
                        label: "即墨市",
                        simple_name: "即墨",
                        hidden: 0
                    },
                    {
                        value: "370283",
                        label: "平度市",
                        simple_name: "平度",
                        hidden: 0
                    },
                    {
                        value: "370285",
                        label: "莱西市",
                        simple_name: "莱西",
                        hidden: 0
                    },
                    {
                        value: "370286",
                        label: "西海岸新区",
                        simple_name: "西海岸",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370300",
                label: "淄博市",
                simple_name: "淄博",
                hidden: 0,
                children: [
                    {
                        value: "370302",
                        label: "淄川区",
                        simple_name: "淄川",
                        hidden: 0
                    },
                    {
                        value: "370303",
                        label: "张店区",
                        simple_name: "张店",
                        hidden: 0
                    },
                    {
                        value: "370304",
                        label: "博山区",
                        simple_name: "博山",
                        hidden: 0
                    },
                    {
                        value: "370305",
                        label: "临淄区",
                        simple_name: "临淄",
                        hidden: 0
                    },
                    {
                        value: "370306",
                        label: "周村区",
                        simple_name: "周村",
                        hidden: 0
                    },
                    {
                        value: "370321",
                        label: "桓台县",
                        simple_name: "桓台",
                        hidden: 0
                    },
                    {
                        value: "370322",
                        label: "高青县",
                        simple_name: "高青",
                        hidden: 0
                    },
                    {
                        value: "370323",
                        label: "沂源县",
                        simple_name: "沂源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370400",
                label: "枣庄市",
                simple_name: "枣庄",
                hidden: 0,
                children: [
                    {
                        value: "370402",
                        label: "市中区",
                        simple_name: "市中区",
                        hidden: 0
                    },
                    {
                        value: "370403",
                        label: "薛城区",
                        simple_name: "薛城",
                        hidden: 0
                    },
                    {
                        value: "370404",
                        label: "峄城区",
                        simple_name: "峄城",
                        hidden: 0
                    },
                    {
                        value: "370405",
                        label: "台儿庄区",
                        simple_name: "台儿庄",
                        hidden: 0
                    },
                    {
                        value: "370406",
                        label: "山亭区",
                        simple_name: "山亭",
                        hidden: 0
                    },
                    {
                        value: "370481",
                        label: "滕州市",
                        simple_name: "滕州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370500",
                label: "东营市",
                simple_name: "东营",
                hidden: 0,
                children: [
                    {
                        value: "370502",
                        label: "东营区",
                        simple_name: "东营",
                        hidden: 0
                    },
                    {
                        value: "370503",
                        label: "河口区",
                        simple_name: "河口",
                        hidden: 0
                    },
                    {
                        value: "370521",
                        label: "垦利县",
                        simple_name: "垦利",
                        hidden: 0
                    },
                    {
                        value: "370522",
                        label: "利津县",
                        simple_name: "利津",
                        hidden: 0
                    },
                    {
                        value: "370523",
                        label: "广饶县",
                        simple_name: "广饶",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370600",
                label: "烟台市",
                simple_name: "烟台",
                hidden: 0,
                children: [
                    {
                        value: "370602",
                        label: "芝罘区",
                        simple_name: "芝罘",
                        hidden: 0
                    },
                    {
                        value: "370611",
                        label: "福山区",
                        simple_name: "福山",
                        hidden: 0
                    },
                    {
                        value: "370612",
                        label: "牟平区",
                        simple_name: "牟平",
                        hidden: 0
                    },
                    {
                        value: "370613",
                        label: "莱山区",
                        simple_name: "莱山",
                        hidden: 0
                    },
                    {
                        value: "370634",
                        label: "长岛县",
                        simple_name: "长岛",
                        hidden: 0
                    },
                    {
                        value: "370681",
                        label: "龙口市",
                        simple_name: "龙口",
                        hidden: 0
                    },
                    {
                        value: "370682",
                        label: "莱阳市",
                        simple_name: "莱阳",
                        hidden: 0
                    },
                    {
                        value: "370683",
                        label: "莱州市",
                        simple_name: "莱州",
                        hidden: 0
                    },
                    {
                        value: "370684",
                        label: "蓬莱市",
                        simple_name: "蓬莱",
                        hidden: 0
                    },
                    {
                        value: "370685",
                        label: "招远市",
                        simple_name: "招远",
                        hidden: 0
                    },
                    {
                        value: "370686",
                        label: "栖霞市",
                        simple_name: "栖霞",
                        hidden: 0
                    },
                    {
                        value: "370687",
                        label: "海阳市",
                        simple_name: "海阳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370700",
                label: "潍坊市",
                simple_name: "潍坊",
                hidden: 0,
                children: [
                    {
                        value: "370702",
                        label: "潍城区",
                        simple_name: "潍城",
                        hidden: 0
                    },
                    {
                        value: "370703",
                        label: "寒亭区",
                        simple_name: "寒亭",
                        hidden: 0
                    },
                    {
                        value: "370704",
                        label: "坊子区",
                        simple_name: "坊子",
                        hidden: 0
                    },
                    {
                        value: "370705",
                        label: "奎文区",
                        simple_name: "奎文",
                        hidden: 0
                    },
                    {
                        value: "370724",
                        label: "临朐县",
                        simple_name: "临朐",
                        hidden: 0
                    },
                    {
                        value: "370725",
                        label: "昌乐县",
                        simple_name: "昌乐",
                        hidden: 0
                    },
                    {
                        value: "370781",
                        label: "青州市",
                        simple_name: "青州",
                        hidden: 0
                    },
                    {
                        value: "370782",
                        label: "诸城市",
                        simple_name: "诸城",
                        hidden: 0
                    },
                    {
                        value: "370783",
                        label: "寿光市",
                        simple_name: "寿光",
                        hidden: 0
                    },
                    {
                        value: "370784",
                        label: "安丘市",
                        simple_name: "安丘",
                        hidden: 0
                    },
                    {
                        value: "370785",
                        label: "高密市",
                        simple_name: "高密",
                        hidden: 0
                    },
                    {
                        value: "370786",
                        label: "昌邑市",
                        simple_name: "昌邑",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370800",
                label: "济宁市",
                simple_name: "济宁",
                hidden: 0,
                children: [
                    {
                        value: "370811",
                        label: "任城区",
                        simple_name: "任城",
                        hidden: 0
                    },
                    {
                        value: "370812",
                        label: "兖州区",
                        simple_name: "兖州",
                        hidden: 0
                    },
                    {
                        value: "370826",
                        label: "微山县",
                        simple_name: "微山",
                        hidden: 0
                    },
                    {
                        value: "370827",
                        label: "鱼台县",
                        simple_name: "鱼台",
                        hidden: 0
                    },
                    {
                        value: "370828",
                        label: "金乡县",
                        simple_name: "金乡",
                        hidden: 0
                    },
                    {
                        value: "370829",
                        label: "嘉祥县",
                        simple_name: "嘉祥",
                        hidden: 0
                    },
                    {
                        value: "370830",
                        label: "汶上县",
                        simple_name: "汶上",
                        hidden: 0
                    },
                    {
                        value: "370831",
                        label: "泗水县",
                        simple_name: "泗水",
                        hidden: 0
                    },
                    {
                        value: "370832",
                        label: "梁山县",
                        simple_name: "梁山",
                        hidden: 0
                    },
                    {
                        value: "370881",
                        label: "曲阜市",
                        simple_name: "曲阜",
                        hidden: 0
                    },
                    {
                        value: "370883",
                        label: "邹城市",
                        simple_name: "邹城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "370900",
                label: "泰安市",
                simple_name: "泰安",
                hidden: 0,
                children: [
                    {
                        value: "370902",
                        label: "泰山区",
                        simple_name: "泰山",
                        hidden: 0
                    },
                    {
                        value: "370911",
                        label: "岱岳区",
                        simple_name: "岱岳",
                        hidden: 0
                    },
                    {
                        value: "370921",
                        label: "宁阳县",
                        simple_name: "宁阳",
                        hidden: 0
                    },
                    {
                        value: "370923",
                        label: "东平县",
                        simple_name: "东平",
                        hidden: 0
                    },
                    {
                        value: "370982",
                        label: "新泰市",
                        simple_name: "新泰",
                        hidden: 0
                    },
                    {
                        value: "370983",
                        label: "肥城市",
                        simple_name: "肥城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371000",
                label: "威海市",
                simple_name: "威海",
                hidden: 0,
                children: [
                    {
                        value: "371002",
                        label: "环翠区",
                        simple_name: "环翠",
                        hidden: 0
                    },
                    {
                        value: "371003",
                        label: "文登区",
                        simple_name: "文登",
                        hidden: 0
                    },
                    {
                        value: "371082",
                        label: "荣成市",
                        simple_name: "荣成",
                        hidden: 0
                    },
                    {
                        value: "371083",
                        label: "乳山市",
                        simple_name: "乳山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371100",
                label: "日照市",
                simple_name: "日照",
                hidden: 0,
                children: [
                    {
                        value: "371102",
                        label: "东港区",
                        simple_name: "东港",
                        hidden: 0
                    },
                    {
                        value: "371103",
                        label: "岚山区",
                        simple_name: "岚山",
                        hidden: 0
                    },
                    {
                        value: "371121",
                        label: "五莲县",
                        simple_name: "五莲",
                        hidden: 0
                    },
                    {
                        value: "371122",
                        label: "莒县",
                        simple_name: "莒县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371200",
                label: "莱芜市",
                simple_name: "莱芜",
                hidden: 0,
                children: [
                    {
                        value: "371202",
                        label: "莱城区",
                        simple_name: "莱城",
                        hidden: 0
                    },
                    {
                        value: "371203",
                        label: "钢城区",
                        simple_name: "钢城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371300",
                label: "临沂市",
                simple_name: "临沂",
                hidden: 0,
                children: [
                    {
                        value: "371302",
                        label: "兰山区",
                        simple_name: "兰山",
                        hidden: 0
                    },
                    {
                        value: "371311",
                        label: "罗庄区",
                        simple_name: "罗庄",
                        hidden: 0
                    },
                    {
                        value: "371312",
                        label: "河东区",
                        simple_name: "河东",
                        hidden: 0
                    },
                    {
                        value: "371321",
                        label: "沂南县",
                        simple_name: "沂南",
                        hidden: 0
                    },
                    {
                        value: "371322",
                        label: "郯城县",
                        simple_name: "郯城",
                        hidden: 0
                    },
                    {
                        value: "371323",
                        label: "沂水县",
                        simple_name: "沂水",
                        hidden: 0
                    },
                    {
                        value: "371324",
                        label: "兰陵县",
                        simple_name: "兰陵",
                        hidden: 0
                    },
                    {
                        value: "371325",
                        label: "费县",
                        simple_name: "费县",
                        hidden: 0
                    },
                    {
                        value: "371326",
                        label: "平邑县",
                        simple_name: "平邑",
                        hidden: 0
                    },
                    {
                        value: "371327",
                        label: "莒南县",
                        simple_name: "莒南",
                        hidden: 0
                    },
                    {
                        value: "371328",
                        label: "蒙阴县",
                        simple_name: "蒙阴",
                        hidden: 0
                    },
                    {
                        value: "371329",
                        label: "临沭县",
                        simple_name: "临沭",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371400",
                label: "德州市",
                simple_name: "德州",
                hidden: 0,
                children: [
                    {
                        value: "371402",
                        label: "德城区",
                        simple_name: "德城",
                        hidden: 0
                    },
                    {
                        value: "371403",
                        label: "陵城区",
                        simple_name: "陵城",
                        hidden: 0
                    },
                    {
                        value: "371422",
                        label: "宁津县",
                        simple_name: "宁津",
                        hidden: 0
                    },
                    {
                        value: "371423",
                        label: "庆云县",
                        simple_name: "庆云",
                        hidden: 0
                    },
                    {
                        value: "371424",
                        label: "临邑县",
                        simple_name: "临邑",
                        hidden: 0
                    },
                    {
                        value: "371425",
                        label: "齐河县",
                        simple_name: "齐河",
                        hidden: 0
                    },
                    {
                        value: "371426",
                        label: "平原县",
                        simple_name: "平原",
                        hidden: 0
                    },
                    {
                        value: "371427",
                        label: "夏津县",
                        simple_name: "夏津",
                        hidden: 0
                    },
                    {
                        value: "371428",
                        label: "武城县",
                        simple_name: "武城",
                        hidden: 0
                    },
                    {
                        value: "371481",
                        label: "乐陵市",
                        simple_name: "乐陵",
                        hidden: 0
                    },
                    {
                        value: "371482",
                        label: "禹城市",
                        simple_name: "禹城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371500",
                label: "聊城市",
                simple_name: "聊城",
                hidden: 0,
                children: [
                    {
                        value: "371502",
                        label: "东昌府区",
                        simple_name: "东昌府",
                        hidden: 0
                    },
                    {
                        value: "371521",
                        label: "阳谷县",
                        simple_name: "阳谷",
                        hidden: 0
                    },
                    {
                        value: "371522",
                        label: "莘县",
                        simple_name: "莘县",
                        hidden: 0
                    },
                    {
                        value: "371523",
                        label: "茌平县",
                        simple_name: "茌平",
                        hidden: 0
                    },
                    {
                        value: "371524",
                        label: "东阿县",
                        simple_name: "东阿",
                        hidden: 0
                    },
                    {
                        value: "371525",
                        label: "冠县",
                        simple_name: "冠县",
                        hidden: 0
                    },
                    {
                        value: "371526",
                        label: "高唐县",
                        simple_name: "高唐",
                        hidden: 0
                    },
                    {
                        value: "371581",
                        label: "临清市",
                        simple_name: "临清",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371600",
                label: "滨州市",
                simple_name: "滨州",
                hidden: 0,
                children: [
                    {
                        value: "371602",
                        label: "滨城区",
                        simple_name: "滨城",
                        hidden: 0
                    },
                    {
                        value: "371603",
                        label: "沾化区",
                        simple_name: "沾化",
                        hidden: 0
                    },
                    {
                        value: "371621",
                        label: "惠民县",
                        simple_name: "惠民",
                        hidden: 0
                    },
                    {
                        value: "371622",
                        label: "阳信县",
                        simple_name: "阳信",
                        hidden: 0
                    },
                    {
                        value: "371623",
                        label: "无棣县",
                        simple_name: "无棣",
                        hidden: 0
                    },
                    {
                        value: "371625",
                        label: "博兴县",
                        simple_name: "博兴",
                        hidden: 0
                    },
                    {
                        value: "371626",
                        label: "邹平县",
                        simple_name: "邹平",
                        hidden: 0
                    },
                    {
                        value: "371627",
                        label: "北海新区",
                        simple_name: "北海新区",
                        hidden: 0
                    }
                ]
            },
            {
                value: "371700",
                label: "菏泽市",
                simple_name: "菏泽",
                hidden: 0,
                children: [
                    {
                        value: "371702",
                        label: "牡丹区",
                        simple_name: "牡丹",
                        hidden: 0
                    },
                    {
                        value: "371721",
                        label: "曹县",
                        simple_name: "曹县",
                        hidden: 0
                    },
                    {
                        value: "371722",
                        label: "单县",
                        simple_name: "单县",
                        hidden: 0
                    },
                    {
                        value: "371723",
                        label: "成武县",
                        simple_name: "成武",
                        hidden: 0
                    },
                    {
                        value: "371724",
                        label: "巨野县",
                        simple_name: "巨野",
                        hidden: 0
                    },
                    {
                        value: "371725",
                        label: "郓城县",
                        simple_name: "郓城",
                        hidden: 0
                    },
                    {
                        value: "371726",
                        label: "鄄城县",
                        simple_name: "鄄城",
                        hidden: 0
                    },
                    {
                        value: "371727",
                        label: "定陶县",
                        simple_name: "定陶",
                        hidden: 0
                    },
                    {
                        value: "371728",
                        label: "东明县",
                        simple_name: "东明",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "410000",
        label: "河南省",
        simple_name: "河南",
        hidden: 0,
        children: [
            {
                value: "410100",
                label: "郑州市",
                simple_name: "郑州",
                hidden: 0,
                children: [
                    {
                        value: "410102",
                        label: "中原区",
                        simple_name: "中原",
                        hidden: 0
                    },
                    {
                        value: "410103",
                        label: "二七区",
                        simple_name: "二七",
                        hidden: 0
                    },
                    {
                        value: "410104",
                        label: "管城回族区",
                        simple_name: "管城",
                        hidden: 0
                    },
                    {
                        value: "410105",
                        label: "金水区",
                        simple_name: "金水",
                        hidden: 0
                    },
                    {
                        value: "410106",
                        label: "上街区",
                        simple_name: "上街",
                        hidden: 0
                    },
                    {
                        value: "410108",
                        label: "惠济区",
                        simple_name: "惠济",
                        hidden: 0
                    },
                    {
                        value: "410122",
                        label: "中牟县",
                        simple_name: "中牟",
                        hidden: 0
                    },
                    {
                        value: "410181",
                        label: "巩义市",
                        simple_name: "巩义",
                        hidden: 0
                    },
                    {
                        value: "410182",
                        label: "荥阳市",
                        simple_name: "荥阳",
                        hidden: 0
                    },
                    {
                        value: "410183",
                        label: "新密市",
                        simple_name: "新密",
                        hidden: 0
                    },
                    {
                        value: "410184",
                        label: "新郑市",
                        simple_name: "新郑",
                        hidden: 0
                    },
                    {
                        value: "410185",
                        label: "登封市",
                        simple_name: "登封",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410200",
                label: "开封市",
                simple_name: "开封",
                hidden: 0,
                children: [
                    {
                        value: "410202",
                        label: "龙亭区",
                        simple_name: "龙亭",
                        hidden: 0
                    },
                    {
                        value: "410203",
                        label: "顺河回族区",
                        simple_name: "顺河",
                        hidden: 0
                    },
                    {
                        value: "410204",
                        label: "鼓楼区",
                        simple_name: "鼓楼",
                        hidden: 0
                    },
                    {
                        value: "410205",
                        label: "禹王台区",
                        simple_name: "禹王台",
                        hidden: 0
                    },
                    {
                        value: "410212",
                        label: "祥符区",
                        simple_name: "祥符",
                        hidden: 0
                    },
                    {
                        value: "410221",
                        label: "杞县",
                        simple_name: "杞县",
                        hidden: 0
                    },
                    {
                        value: "410222",
                        label: "通许县",
                        simple_name: "通许",
                        hidden: 0
                    },
                    {
                        value: "410223",
                        label: "尉氏县",
                        simple_name: "尉氏",
                        hidden: 0
                    },
                    {
                        value: "410225",
                        label: "兰考县",
                        simple_name: "兰考",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410300",
                label: "洛阳市",
                simple_name: "洛阳",
                hidden: 0,
                children: [
                    {
                        value: "410302",
                        label: "老城区",
                        simple_name: "老城",
                        hidden: 0
                    },
                    {
                        value: "410303",
                        label: "西工区",
                        simple_name: "西工",
                        hidden: 0
                    },
                    {
                        value: "410304",
                        label: "瀍河回族区",
                        simple_name: "瀍河",
                        hidden: 0
                    },
                    {
                        value: "410305",
                        label: "涧西区",
                        simple_name: "涧西",
                        hidden: 0
                    },
                    {
                        value: "410306",
                        label: "吉利区",
                        simple_name: "吉利",
                        hidden: 0
                    },
                    {
                        value: "410311",
                        label: "洛龙区",
                        simple_name: "洛龙",
                        hidden: 0
                    },
                    {
                        value: "410322",
                        label: "孟津县",
                        simple_name: "孟津",
                        hidden: 0
                    },
                    {
                        value: "410323",
                        label: "新安县",
                        simple_name: "新安",
                        hidden: 0
                    },
                    {
                        value: "410324",
                        label: "栾川县",
                        simple_name: "栾川",
                        hidden: 0
                    },
                    {
                        value: "410325",
                        label: "嵩县",
                        simple_name: "嵩县",
                        hidden: 0
                    },
                    {
                        value: "410326",
                        label: "汝阳县",
                        simple_name: "汝阳",
                        hidden: 0
                    },
                    {
                        value: "410327",
                        label: "宜阳县",
                        simple_name: "宜阳",
                        hidden: 0
                    },
                    {
                        value: "410328",
                        label: "洛宁县",
                        simple_name: "洛宁",
                        hidden: 0
                    },
                    {
                        value: "410329",
                        label: "伊川县",
                        simple_name: "伊川",
                        hidden: 0
                    },
                    {
                        value: "410381",
                        label: "偃师市",
                        simple_name: "偃师",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410400",
                label: "平顶山市",
                simple_name: "平顶山",
                hidden: 0,
                children: [
                    {
                        value: "410402",
                        label: "新华区",
                        simple_name: "新华",
                        hidden: 0
                    },
                    {
                        value: "410403",
                        label: "卫东区",
                        simple_name: "卫东",
                        hidden: 0
                    },
                    {
                        value: "410404",
                        label: "石龙区",
                        simple_name: "石龙",
                        hidden: 0
                    },
                    {
                        value: "410411",
                        label: "湛河区",
                        simple_name: "湛河",
                        hidden: 0
                    },
                    {
                        value: "410421",
                        label: "宝丰县",
                        simple_name: "宝丰",
                        hidden: 0
                    },
                    {
                        value: "410422",
                        label: "叶县",
                        simple_name: "叶县",
                        hidden: 0
                    },
                    {
                        value: "410423",
                        label: "鲁山县",
                        simple_name: "鲁山",
                        hidden: 0
                    },
                    {
                        value: "410425",
                        label: "郏县",
                        simple_name: "郏县",
                        hidden: 0
                    },
                    {
                        value: "410481",
                        label: "舞钢市",
                        simple_name: "舞钢",
                        hidden: 0
                    },
                    {
                        value: "410482",
                        label: "汝州市",
                        simple_name: "汝州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410500",
                label: "安阳市",
                simple_name: "安阳",
                hidden: 0,
                children: [
                    {
                        value: "410502",
                        label: "文峰区",
                        simple_name: "文峰",
                        hidden: 0
                    },
                    {
                        value: "410503",
                        label: "北关区",
                        simple_name: "北关",
                        hidden: 0
                    },
                    {
                        value: "410505",
                        label: "殷都区",
                        simple_name: "殷都",
                        hidden: 0
                    },
                    {
                        value: "410506",
                        label: "龙安区",
                        simple_name: "龙安",
                        hidden: 0
                    },
                    {
                        value: "410522",
                        label: "安阳县",
                        simple_name: "安阳",
                        hidden: 0
                    },
                    {
                        value: "410523",
                        label: "汤阴县",
                        simple_name: "汤阴",
                        hidden: 0
                    },
                    {
                        value: "410526",
                        label: "滑县",
                        simple_name: "滑县",
                        hidden: 0
                    },
                    {
                        value: "410527",
                        label: "内黄县",
                        simple_name: "内黄",
                        hidden: 0
                    },
                    {
                        value: "410581",
                        label: "林州市",
                        simple_name: "林州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410600",
                label: "鹤壁市",
                simple_name: "鹤壁",
                hidden: 0,
                children: [
                    {
                        value: "410602",
                        label: "鹤山区",
                        simple_name: "鹤山",
                        hidden: 0
                    },
                    {
                        value: "410603",
                        label: "山城区",
                        simple_name: "山城",
                        hidden: 0
                    },
                    {
                        value: "410611",
                        label: "淇滨区",
                        simple_name: "淇滨",
                        hidden: 0
                    },
                    {
                        value: "410621",
                        label: "浚县",
                        simple_name: "浚县",
                        hidden: 0
                    },
                    {
                        value: "410622",
                        label: "淇县",
                        simple_name: "淇县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410700",
                label: "新乡市",
                simple_name: "新乡",
                hidden: 0,
                children: [
                    {
                        value: "410702",
                        label: "红旗区",
                        simple_name: "红旗",
                        hidden: 0
                    },
                    {
                        value: "410703",
                        label: "卫滨区",
                        simple_name: "卫滨",
                        hidden: 0
                    },
                    {
                        value: "410704",
                        label: "凤泉区",
                        simple_name: "凤泉",
                        hidden: 0
                    },
                    {
                        value: "410711",
                        label: "牧野区",
                        simple_name: "牧野",
                        hidden: 0
                    },
                    {
                        value: "410721",
                        label: "新乡县",
                        simple_name: "新乡",
                        hidden: 0
                    },
                    {
                        value: "410724",
                        label: "获嘉县",
                        simple_name: "获嘉",
                        hidden: 0
                    },
                    {
                        value: "410725",
                        label: "原阳县",
                        simple_name: "原阳",
                        hidden: 0
                    },
                    {
                        value: "410726",
                        label: "延津县",
                        simple_name: "延津",
                        hidden: 0
                    },
                    {
                        value: "410727",
                        label: "封丘县",
                        simple_name: "封丘",
                        hidden: 0
                    },
                    {
                        value: "410728",
                        label: "长垣县",
                        simple_name: "长垣",
                        hidden: 0
                    },
                    {
                        value: "410781",
                        label: "卫辉市",
                        simple_name: "卫辉",
                        hidden: 0
                    },
                    {
                        value: "410782",
                        label: "辉县市",
                        simple_name: "辉县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410800",
                label: "焦作市",
                simple_name: "焦作",
                hidden: 0,
                children: [
                    {
                        value: "410802",
                        label: "解放区",
                        simple_name: "解放",
                        hidden: 0
                    },
                    {
                        value: "410803",
                        label: "中站区",
                        simple_name: "中站",
                        hidden: 0
                    },
                    {
                        value: "410804",
                        label: "马村区",
                        simple_name: "马村",
                        hidden: 0
                    },
                    {
                        value: "410811",
                        label: "山阳区",
                        simple_name: "山阳",
                        hidden: 0
                    },
                    {
                        value: "410821",
                        label: "修武县",
                        simple_name: "修武",
                        hidden: 0
                    },
                    {
                        value: "410822",
                        label: "博爱县",
                        simple_name: "博爱",
                        hidden: 0
                    },
                    {
                        value: "410823",
                        label: "武陟县",
                        simple_name: "武陟",
                        hidden: 0
                    },
                    {
                        value: "410825",
                        label: "温县",
                        simple_name: "温县",
                        hidden: 0
                    },
                    {
                        value: "410882",
                        label: "沁阳市",
                        simple_name: "沁阳",
                        hidden: 0
                    },
                    {
                        value: "410883",
                        label: "孟州市",
                        simple_name: "孟州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "410900",
                label: "濮阳市",
                simple_name: "濮阳",
                hidden: 0,
                children: [
                    {
                        value: "410902",
                        label: "华龙区",
                        simple_name: "华龙",
                        hidden: 0
                    },
                    {
                        value: "410922",
                        label: "清丰县",
                        simple_name: "清丰",
                        hidden: 0
                    },
                    {
                        value: "410923",
                        label: "南乐县",
                        simple_name: "南乐",
                        hidden: 0
                    },
                    {
                        value: "410926",
                        label: "范县",
                        simple_name: "范县",
                        hidden: 0
                    },
                    {
                        value: "410927",
                        label: "台前县",
                        simple_name: "台前",
                        hidden: 0
                    },
                    {
                        value: "410928",
                        label: "濮阳县",
                        simple_name: "濮阳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411000",
                label: "许昌市",
                simple_name: "许昌",
                hidden: 0,
                children: [
                    {
                        value: "411002",
                        label: "魏都区",
                        simple_name: "魏都",
                        hidden: 0
                    },
                    {
                        value: "411023",
                        label: "许昌县",
                        simple_name: "许昌",
                        hidden: 0
                    },
                    {
                        value: "411024",
                        label: "鄢陵县",
                        simple_name: "鄢陵",
                        hidden: 0
                    },
                    {
                        value: "411025",
                        label: "襄城县",
                        simple_name: "襄城",
                        hidden: 0
                    },
                    {
                        value: "411081",
                        label: "禹州市",
                        simple_name: "禹州",
                        hidden: 0
                    },
                    {
                        value: "411082",
                        label: "长葛市",
                        simple_name: "长葛",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411100",
                label: "漯河市",
                simple_name: "漯河",
                hidden: 0,
                children: [
                    {
                        value: "411102",
                        label: "源汇区",
                        simple_name: "源汇",
                        hidden: 0
                    },
                    {
                        value: "411103",
                        label: "郾城区",
                        simple_name: "郾城",
                        hidden: 0
                    },
                    {
                        value: "411104",
                        label: "召陵区",
                        simple_name: "召陵",
                        hidden: 0
                    },
                    {
                        value: "411121",
                        label: "舞阳县",
                        simple_name: "舞阳",
                        hidden: 0
                    },
                    {
                        value: "411122",
                        label: "临颍县",
                        simple_name: "临颍",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411200",
                label: "三门峡市",
                simple_name: "三门峡",
                hidden: 0,
                children: [
                    {
                        value: "411202",
                        label: "湖滨区",
                        simple_name: "湖滨",
                        hidden: 0
                    },
                    {
                        value: "411221",
                        label: "渑池县",
                        simple_name: "渑池",
                        hidden: 0
                    },
                    {
                        value: "411222",
                        label: "陕县",
                        simple_name: "陕县",
                        hidden: 0
                    },
                    {
                        value: "411224",
                        label: "卢氏县",
                        simple_name: "卢氏",
                        hidden: 0
                    },
                    {
                        value: "411281",
                        label: "义马市",
                        simple_name: "义马",
                        hidden: 0
                    },
                    {
                        value: "411282",
                        label: "灵宝市",
                        simple_name: "灵宝",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411300",
                label: "南阳市",
                simple_name: "南阳",
                hidden: 0,
                children: [
                    {
                        value: "411302",
                        label: "宛城区",
                        simple_name: "宛城",
                        hidden: 0
                    },
                    {
                        value: "411303",
                        label: "卧龙区",
                        simple_name: "卧龙",
                        hidden: 0
                    },
                    {
                        value: "411321",
                        label: "南召县",
                        simple_name: "南召",
                        hidden: 0
                    },
                    {
                        value: "411322",
                        label: "方城县",
                        simple_name: "方城",
                        hidden: 0
                    },
                    {
                        value: "411323",
                        label: "西峡县",
                        simple_name: "西峡",
                        hidden: 0
                    },
                    {
                        value: "411324",
                        label: "镇平县",
                        simple_name: "镇平",
                        hidden: 0
                    },
                    {
                        value: "411325",
                        label: "内乡县",
                        simple_name: "内乡",
                        hidden: 0
                    },
                    {
                        value: "411326",
                        label: "淅川县",
                        simple_name: "淅川",
                        hidden: 0
                    },
                    {
                        value: "411327",
                        label: "社旗县",
                        simple_name: "社旗",
                        hidden: 0
                    },
                    {
                        value: "411328",
                        label: "唐河县",
                        simple_name: "唐河",
                        hidden: 0
                    },
                    {
                        value: "411329",
                        label: "新野县",
                        simple_name: "新野",
                        hidden: 0
                    },
                    {
                        value: "411330",
                        label: "桐柏县",
                        simple_name: "桐柏",
                        hidden: 0
                    },
                    {
                        value: "411381",
                        label: "邓州市",
                        simple_name: "邓州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411400",
                label: "商丘市",
                simple_name: "商丘",
                hidden: 0,
                children: [
                    {
                        value: "411402",
                        label: "梁园区",
                        simple_name: "梁园",
                        hidden: 0
                    },
                    {
                        value: "411403",
                        label: "睢阳区",
                        simple_name: "睢阳",
                        hidden: 0
                    },
                    {
                        value: "411421",
                        label: "民权县",
                        simple_name: "民权",
                        hidden: 0
                    },
                    {
                        value: "411422",
                        label: "睢县",
                        simple_name: "睢县",
                        hidden: 0
                    },
                    {
                        value: "411423",
                        label: "宁陵县",
                        simple_name: "宁陵",
                        hidden: 0
                    },
                    {
                        value: "411424",
                        label: "柘城县",
                        simple_name: "柘城",
                        hidden: 0
                    },
                    {
                        value: "411425",
                        label: "虞城县",
                        simple_name: "虞城",
                        hidden: 0
                    },
                    {
                        value: "411426",
                        label: "夏邑县",
                        simple_name: "夏邑",
                        hidden: 0
                    },
                    {
                        value: "411481",
                        label: "永城市",
                        simple_name: "永城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411500",
                label: "信阳市",
                simple_name: "信阳",
                hidden: 0,
                children: [
                    {
                        value: "411502",
                        label: "浉河区",
                        simple_name: "浉河",
                        hidden: 0
                    },
                    {
                        value: "411503",
                        label: "平桥区",
                        simple_name: "平桥",
                        hidden: 0
                    },
                    {
                        value: "411521",
                        label: "罗山县",
                        simple_name: "罗山",
                        hidden: 0
                    },
                    {
                        value: "411522",
                        label: "光山县",
                        simple_name: "光山",
                        hidden: 0
                    },
                    {
                        value: "411523",
                        label: "新县",
                        simple_name: "新县",
                        hidden: 0
                    },
                    {
                        value: "411524",
                        label: "商城县",
                        simple_name: "商城",
                        hidden: 0
                    },
                    {
                        value: "411525",
                        label: "固始县",
                        simple_name: "固始",
                        hidden: 0
                    },
                    {
                        value: "411526",
                        label: "潢川县",
                        simple_name: "潢川",
                        hidden: 0
                    },
                    {
                        value: "411527",
                        label: "淮滨县",
                        simple_name: "淮滨",
                        hidden: 0
                    },
                    {
                        value: "411528",
                        label: "息县",
                        simple_name: "息县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411600",
                label: "周口市",
                simple_name: "周口",
                hidden: 0,
                children: [
                    {
                        value: "411602",
                        label: "川汇区",
                        simple_name: "川汇",
                        hidden: 0
                    },
                    {
                        value: "411621",
                        label: "扶沟县",
                        simple_name: "扶沟",
                        hidden: 0
                    },
                    {
                        value: "411622",
                        label: "西华县",
                        simple_name: "西华",
                        hidden: 0
                    },
                    {
                        value: "411623",
                        label: "商水县",
                        simple_name: "商水",
                        hidden: 0
                    },
                    {
                        value: "411624",
                        label: "沈丘县",
                        simple_name: "沈丘",
                        hidden: 0
                    },
                    {
                        value: "411625",
                        label: "郸城县",
                        simple_name: "郸城",
                        hidden: 0
                    },
                    {
                        value: "411626",
                        label: "淮阳县",
                        simple_name: "淮阳",
                        hidden: 0
                    },
                    {
                        value: "411627",
                        label: "太康县",
                        simple_name: "太康",
                        hidden: 0
                    },
                    {
                        value: "411628",
                        label: "鹿邑县",
                        simple_name: "鹿邑",
                        hidden: 0
                    },
                    {
                        value: "411681",
                        label: "项城市",
                        simple_name: "项城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "411700",
                label: "驻马店市",
                simple_name: "驻马店",
                hidden: 0,
                children: [
                    {
                        value: "411702",
                        label: "驿城区",
                        simple_name: "驿城",
                        hidden: 0
                    },
                    {
                        value: "411721",
                        label: "西平县",
                        simple_name: "西平",
                        hidden: 0
                    },
                    {
                        value: "411722",
                        label: "上蔡县",
                        simple_name: "上蔡",
                        hidden: 0
                    },
                    {
                        value: "411723",
                        label: "平舆县",
                        simple_name: "平舆",
                        hidden: 0
                    },
                    {
                        value: "411724",
                        label: "正阳县",
                        simple_name: "正阳",
                        hidden: 0
                    },
                    {
                        value: "411725",
                        label: "确山县",
                        simple_name: "确山",
                        hidden: 0
                    },
                    {
                        value: "411726",
                        label: "泌阳县",
                        simple_name: "泌阳",
                        hidden: 0
                    },
                    {
                        value: "411727",
                        label: "汝南县",
                        simple_name: "汝南",
                        hidden: 0
                    },
                    {
                        value: "411728",
                        label: "遂平县",
                        simple_name: "遂平",
                        hidden: 0
                    },
                    {
                        value: "411729",
                        label: "新蔡县",
                        simple_name: "新蔡",
                        hidden: 0
                    }
                ]
            },
            {
                value: "419000",
                label: "直辖县级",
                simple_name: " ",
                hidden: 0,
                children: [
                    {
                        value: "419001",
                        label: "济源市",
                        simple_name: "济源",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "420000",
        label: "湖北省",
        simple_name: "湖北",
        hidden: 0,
        children: [
            {
                value: "420100",
                label: "武汉市",
                simple_name: "武汉",
                hidden: 0,
                children: [
                    {
                        value: "420102",
                        label: "江岸区",
                        simple_name: "江岸",
                        hidden: 0
                    },
                    {
                        value: "420103",
                        label: "江汉区",
                        simple_name: "江汉",
                        hidden: 0
                    },
                    {
                        value: "420104",
                        label: "硚口区",
                        simple_name: "硚口",
                        hidden: 0
                    },
                    {
                        value: "420105",
                        label: "汉阳区",
                        simple_name: "汉阳",
                        hidden: 0
                    },
                    {
                        value: "420106",
                        label: "武昌区",
                        simple_name: "武昌",
                        hidden: 0
                    },
                    {
                        value: "420107",
                        label: "青山区",
                        simple_name: "青山",
                        hidden: 0
                    },
                    {
                        value: "420111",
                        label: "洪山区",
                        simple_name: "洪山",
                        hidden: 0
                    },
                    {
                        value: "420112",
                        label: "东西湖区",
                        simple_name: "东西湖",
                        hidden: 0
                    },
                    {
                        value: "420113",
                        label: "汉南区",
                        simple_name: "汉南",
                        hidden: 0
                    },
                    {
                        value: "420114",
                        label: "蔡甸区",
                        simple_name: "蔡甸",
                        hidden: 0
                    },
                    {
                        value: "420115",
                        label: "江夏区",
                        simple_name: "江夏",
                        hidden: 0
                    },
                    {
                        value: "420116",
                        label: "黄陂区",
                        simple_name: "黄陂",
                        hidden: 0
                    },
                    {
                        value: "420117",
                        label: "新洲区",
                        simple_name: "新洲",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420200",
                label: "黄石市",
                simple_name: "黄石",
                hidden: 0,
                children: [
                    {
                        value: "420202",
                        label: "黄石港区",
                        simple_name: "黄石港",
                        hidden: 0
                    },
                    {
                        value: "420203",
                        label: "西塞山区",
                        simple_name: "西塞山",
                        hidden: 0
                    },
                    {
                        value: "420204",
                        label: "下陆区",
                        simple_name: "下陆",
                        hidden: 0
                    },
                    {
                        value: "420205",
                        label: "铁山区",
                        simple_name: "铁山",
                        hidden: 0
                    },
                    {
                        value: "420222",
                        label: "阳新县",
                        simple_name: "阳新",
                        hidden: 0
                    },
                    {
                        value: "420281",
                        label: "大冶市",
                        simple_name: "大冶",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420300",
                label: "十堰市",
                simple_name: "十堰",
                hidden: 0,
                children: [
                    {
                        value: "420302",
                        label: "茅箭区",
                        simple_name: "茅箭",
                        hidden: 0
                    },
                    {
                        value: "420303",
                        label: "张湾区",
                        simple_name: "张湾",
                        hidden: 0
                    },
                    {
                        value: "420304",
                        label: "郧阳区",
                        simple_name: "郧阳",
                        hidden: 0
                    },
                    {
                        value: "420322",
                        label: "郧西县",
                        simple_name: "郧西",
                        hidden: 0
                    },
                    {
                        value: "420323",
                        label: "竹山县",
                        simple_name: "竹山",
                        hidden: 0
                    },
                    {
                        value: "420324",
                        label: "竹溪县",
                        simple_name: "竹溪",
                        hidden: 0
                    },
                    {
                        value: "420325",
                        label: "房县",
                        simple_name: "房县",
                        hidden: 0
                    },
                    {
                        value: "420381",
                        label: "丹江口市",
                        simple_name: "丹江口",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420500",
                label: "宜昌市",
                simple_name: "宜昌",
                hidden: 0,
                children: [
                    {
                        value: "420502",
                        label: "西陵区",
                        simple_name: "西陵",
                        hidden: 0
                    },
                    {
                        value: "420503",
                        label: "伍家岗区",
                        simple_name: "伍家岗",
                        hidden: 0
                    },
                    {
                        value: "420504",
                        label: "点军区",
                        simple_name: "点军",
                        hidden: 0
                    },
                    {
                        value: "420505",
                        label: "猇亭区",
                        simple_name: "猇亭",
                        hidden: 0
                    },
                    {
                        value: "420506",
                        label: "夷陵区",
                        simple_name: "夷陵",
                        hidden: 0
                    },
                    {
                        value: "420525",
                        label: "远安县",
                        simple_name: "远安",
                        hidden: 0
                    },
                    {
                        value: "420526",
                        label: "兴山县",
                        simple_name: "兴山",
                        hidden: 0
                    },
                    {
                        value: "420527",
                        label: "秭归县",
                        simple_name: "秭归",
                        hidden: 0
                    },
                    {
                        value: "420528",
                        label: "长阳土家族自治县",
                        simple_name: "长阳",
                        hidden: 0
                    },
                    {
                        value: "420529",
                        label: "五峰土家族自治县",
                        simple_name: "五峰",
                        hidden: 0
                    },
                    {
                        value: "420581",
                        label: "宜都市",
                        simple_name: "宜都",
                        hidden: 0
                    },
                    {
                        value: "420582",
                        label: "当阳市",
                        simple_name: "当阳",
                        hidden: 0
                    },
                    {
                        value: "420583",
                        label: "枝江市",
                        simple_name: "枝江",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420600",
                label: "襄阳市",
                simple_name: "襄阳",
                hidden: 0,
                children: [
                    {
                        value: "420602",
                        label: "襄城区",
                        simple_name: "襄城",
                        hidden: 0
                    },
                    {
                        value: "420606",
                        label: "樊城区",
                        simple_name: "樊城",
                        hidden: 0
                    },
                    {
                        value: "420607",
                        label: "襄州区",
                        simple_name: "襄州",
                        hidden: 0
                    },
                    {
                        value: "420624",
                        label: "南漳县",
                        simple_name: "南漳",
                        hidden: 0
                    },
                    {
                        value: "420625",
                        label: "谷城县",
                        simple_name: "谷城",
                        hidden: 0
                    },
                    {
                        value: "420626",
                        label: "保康县",
                        simple_name: "保康",
                        hidden: 0
                    },
                    {
                        value: "420682",
                        label: "老河口市",
                        simple_name: "老河口",
                        hidden: 0
                    },
                    {
                        value: "420683",
                        label: "枣阳市",
                        simple_name: "枣阳",
                        hidden: 0
                    },
                    {
                        value: "420684",
                        label: "宜城市",
                        simple_name: "宜城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420700",
                label: "鄂州市",
                simple_name: "鄂州",
                hidden: 0,
                children: [
                    {
                        value: "420702",
                        label: "梁子湖区",
                        simple_name: "梁子湖",
                        hidden: 0
                    },
                    {
                        value: "420703",
                        label: "华容区",
                        simple_name: "华容",
                        hidden: 0
                    },
                    {
                        value: "420704",
                        label: "鄂城区",
                        simple_name: "鄂城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420800",
                label: "荆门市",
                simple_name: "荆门",
                hidden: 0,
                children: [
                    {
                        value: "420802",
                        label: "东宝区",
                        simple_name: "东宝",
                        hidden: 0
                    },
                    {
                        value: "420804",
                        label: "掇刀区",
                        simple_name: "掇刀",
                        hidden: 0
                    },
                    {
                        value: "420821",
                        label: "京山县",
                        simple_name: "京山",
                        hidden: 0
                    },
                    {
                        value: "420822",
                        label: "沙洋县",
                        simple_name: "沙洋",
                        hidden: 0
                    },
                    {
                        value: "420881",
                        label: "钟祥市",
                        simple_name: "钟祥",
                        hidden: 0
                    }
                ]
            },
            {
                value: "420900",
                label: "孝感市",
                simple_name: "孝感",
                hidden: 0,
                children: [
                    {
                        value: "420902",
                        label: "孝南区",
                        simple_name: "孝南",
                        hidden: 0
                    },
                    {
                        value: "420921",
                        label: "孝昌县",
                        simple_name: "孝昌",
                        hidden: 0
                    },
                    {
                        value: "420922",
                        label: "大悟县",
                        simple_name: "大悟",
                        hidden: 0
                    },
                    {
                        value: "420923",
                        label: "云梦县",
                        simple_name: "云梦",
                        hidden: 0
                    },
                    {
                        value: "420981",
                        label: "应城市",
                        simple_name: "应城",
                        hidden: 0
                    },
                    {
                        value: "420982",
                        label: "安陆市",
                        simple_name: "安陆",
                        hidden: 0
                    },
                    {
                        value: "420984",
                        label: "汉川市",
                        simple_name: "汉川",
                        hidden: 0
                    }
                ]
            },
            {
                value: "421000",
                label: "荆州市",
                simple_name: "荆州",
                hidden: 0,
                children: [
                    {
                        value: "421002",
                        label: "沙市区",
                        simple_name: "沙市",
                        hidden: 0
                    },
                    {
                        value: "421003",
                        label: "荆州区",
                        simple_name: "荆州",
                        hidden: 0
                    },
                    {
                        value: "421022",
                        label: "公安县",
                        simple_name: "公安",
                        hidden: 0
                    },
                    {
                        value: "421023",
                        label: "监利县",
                        simple_name: "监利",
                        hidden: 0
                    },
                    {
                        value: "421024",
                        label: "江陵县",
                        simple_name: "江陵",
                        hidden: 0
                    },
                    {
                        value: "421081",
                        label: "石首市",
                        simple_name: "石首",
                        hidden: 0
                    },
                    {
                        value: "421083",
                        label: "洪湖市",
                        simple_name: "洪湖",
                        hidden: 0
                    },
                    {
                        value: "421087",
                        label: "松滋市",
                        simple_name: "松滋",
                        hidden: 0
                    }
                ]
            },
            {
                value: "421100",
                label: "黄冈市",
                simple_name: "黄冈",
                hidden: 0,
                children: [
                    {
                        value: "421102",
                        label: "黄州区",
                        simple_name: "黄州",
                        hidden: 0
                    },
                    {
                        value: "421121",
                        label: "团风县",
                        simple_name: "团风",
                        hidden: 0
                    },
                    {
                        value: "421122",
                        label: "红安县",
                        simple_name: "红安",
                        hidden: 0
                    },
                    {
                        value: "421123",
                        label: "罗田县",
                        simple_name: "罗田",
                        hidden: 0
                    },
                    {
                        value: "421124",
                        label: "英山县",
                        simple_name: "英山",
                        hidden: 0
                    },
                    {
                        value: "421125",
                        label: "浠水县",
                        simple_name: "浠水",
                        hidden: 0
                    },
                    {
                        value: "421126",
                        label: "蕲春县",
                        simple_name: "蕲春",
                        hidden: 0
                    },
                    {
                        value: "421127",
                        label: "黄梅县",
                        simple_name: "黄梅",
                        hidden: 0
                    },
                    {
                        value: "421181",
                        label: "麻城市",
                        simple_name: "麻城",
                        hidden: 0
                    },
                    {
                        value: "421182",
                        label: "武穴市",
                        simple_name: "武穴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "421200",
                label: "咸宁市",
                simple_name: "咸宁",
                hidden: 0,
                children: [
                    {
                        value: "421202",
                        label: "咸安区",
                        simple_name: "咸安",
                        hidden: 0
                    },
                    {
                        value: "421221",
                        label: "嘉鱼县",
                        simple_name: "嘉鱼",
                        hidden: 0
                    },
                    {
                        value: "421222",
                        label: "通城县",
                        simple_name: "通城",
                        hidden: 0
                    },
                    {
                        value: "421223",
                        label: "崇阳县",
                        simple_name: "崇阳",
                        hidden: 0
                    },
                    {
                        value: "421224",
                        label: "通山县",
                        simple_name: "通山",
                        hidden: 0
                    },
                    {
                        value: "421281",
                        label: "赤壁市",
                        simple_name: "赤壁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "421300",
                label: "随州市",
                simple_name: "随州",
                hidden: 0,
                children: [
                    {
                        value: "421303",
                        label: "曾都区",
                        simple_name: "曾都",
                        hidden: 0
                    },
                    {
                        value: "421321",
                        label: "随县",
                        simple_name: "随县",
                        hidden: 0
                    },
                    {
                        value: "421381",
                        label: "广水市",
                        simple_name: "广水",
                        hidden: 0
                    }
                ]
            },
            {
                value: "422800",
                label: "恩施土家族苗族自治州",
                simple_name: "恩施",
                hidden: 0,
                children: [
                    {
                        value: "422801",
                        label: "恩施市",
                        simple_name: "恩施",
                        hidden: 0
                    },
                    {
                        value: "422802",
                        label: "利川市",
                        simple_name: "利川",
                        hidden: 0
                    },
                    {
                        value: "422822",
                        label: "建始县",
                        simple_name: "建始",
                        hidden: 0
                    },
                    {
                        value: "422823",
                        label: "巴东县",
                        simple_name: "巴东",
                        hidden: 0
                    },
                    {
                        value: "422825",
                        label: "宣恩县",
                        simple_name: "宣恩",
                        hidden: 0
                    },
                    {
                        value: "422826",
                        label: "咸丰县",
                        simple_name: "咸丰",
                        hidden: 0
                    },
                    {
                        value: "422827",
                        label: "来凤县",
                        simple_name: "来凤",
                        hidden: 0
                    },
                    {
                        value: "422828",
                        label: "鹤峰县",
                        simple_name: "鹤峰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "429000",
                label: "直辖县级",
                simple_name: " ",
                hidden: 0,
                children: [
                    {
                        value: "429004",
                        label: "仙桃市",
                        simple_name: "仙桃",
                        hidden: 0
                    },
                    {
                        value: "429005",
                        label: "潜江市",
                        simple_name: "潜江",
                        hidden: 0
                    },
                    {
                        value: "429006",
                        label: "天门市",
                        simple_name: "天门",
                        hidden: 0
                    },
                    {
                        value: "429021",
                        label: "神农架林区",
                        simple_name: "神农架",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "430000",
        label: "湖南省",
        simple_name: "湖南",
        hidden: 0,
        children: [
            {
                value: "430100",
                label: "长沙市",
                simple_name: "长沙",
                hidden: 0,
                children: [
                    {
                        value: "430102",
                        label: "芙蓉区",
                        simple_name: "芙蓉",
                        hidden: 0
                    },
                    {
                        value: "430103",
                        label: "天心区",
                        simple_name: "天心",
                        hidden: 0
                    },
                    {
                        value: "430104",
                        label: "岳麓区",
                        simple_name: "岳麓",
                        hidden: 0
                    },
                    {
                        value: "430105",
                        label: "开福区",
                        simple_name: "开福",
                        hidden: 0
                    },
                    {
                        value: "430111",
                        label: "雨花区",
                        simple_name: "雨花",
                        hidden: 0
                    },
                    {
                        value: "430112",
                        label: "望城区",
                        simple_name: "望城",
                        hidden: 0
                    },
                    {
                        value: "430121",
                        label: "长沙县",
                        simple_name: "长沙",
                        hidden: 0
                    },
                    {
                        value: "430124",
                        label: "宁乡县",
                        simple_name: "宁乡",
                        hidden: 0
                    },
                    {
                        value: "430181",
                        label: "浏阳市",
                        simple_name: "浏阳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430200",
                label: "株洲市",
                simple_name: "株洲",
                hidden: 0,
                children: [
                    {
                        value: "430202",
                        label: "荷塘区",
                        simple_name: "荷塘",
                        hidden: 0
                    },
                    {
                        value: "430203",
                        label: "芦淞区",
                        simple_name: "芦淞",
                        hidden: 0
                    },
                    {
                        value: "430204",
                        label: "石峰区",
                        simple_name: "石峰",
                        hidden: 0
                    },
                    {
                        value: "430211",
                        label: "天元区",
                        simple_name: "天元",
                        hidden: 0
                    },
                    {
                        value: "430221",
                        label: "株洲县",
                        simple_name: "株洲",
                        hidden: 0
                    },
                    {
                        value: "430223",
                        label: "攸县",
                        simple_name: "攸县",
                        hidden: 0
                    },
                    {
                        value: "430224",
                        label: "茶陵县",
                        simple_name: "茶陵",
                        hidden: 0
                    },
                    {
                        value: "430225",
                        label: "炎陵县",
                        simple_name: "炎陵",
                        hidden: 0
                    },
                    {
                        value: "430281",
                        label: "醴陵市",
                        simple_name: "醴陵",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430300",
                label: "湘潭市",
                simple_name: "湘潭",
                hidden: 0,
                children: [
                    {
                        value: "430302",
                        label: "雨湖区",
                        simple_name: "雨湖",
                        hidden: 0
                    },
                    {
                        value: "430304",
                        label: "岳塘区",
                        simple_name: "岳塘",
                        hidden: 0
                    },
                    {
                        value: "430321",
                        label: "湘潭县",
                        simple_name: "湘潭",
                        hidden: 0
                    },
                    {
                        value: "430381",
                        label: "湘乡市",
                        simple_name: "湘乡",
                        hidden: 0
                    },
                    {
                        value: "430382",
                        label: "韶山市",
                        simple_name: "韶山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430400",
                label: "衡阳市",
                simple_name: "衡阳",
                hidden: 0,
                children: [
                    {
                        value: "430405",
                        label: "珠晖区",
                        simple_name: "珠晖",
                        hidden: 0
                    },
                    {
                        value: "430406",
                        label: "雁峰区",
                        simple_name: "雁峰",
                        hidden: 0
                    },
                    {
                        value: "430407",
                        label: "石鼓区",
                        simple_name: "石鼓",
                        hidden: 0
                    },
                    {
                        value: "430408",
                        label: "蒸湘区",
                        simple_name: "蒸湘",
                        hidden: 0
                    },
                    {
                        value: "430412",
                        label: "南岳区",
                        simple_name: "南岳",
                        hidden: 0
                    },
                    {
                        value: "430421",
                        label: "衡阳县",
                        simple_name: "衡阳",
                        hidden: 0
                    },
                    {
                        value: "430422",
                        label: "衡南县",
                        simple_name: "衡南",
                        hidden: 0
                    },
                    {
                        value: "430423",
                        label: "衡山县",
                        simple_name: "衡山",
                        hidden: 0
                    },
                    {
                        value: "430424",
                        label: "衡东县",
                        simple_name: "衡东",
                        hidden: 0
                    },
                    {
                        value: "430426",
                        label: "祁东县",
                        simple_name: "祁东",
                        hidden: 0
                    },
                    {
                        value: "430481",
                        label: "耒阳市",
                        simple_name: "耒阳",
                        hidden: 0
                    },
                    {
                        value: "430482",
                        label: "常宁市",
                        simple_name: "常宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430500",
                label: "邵阳市",
                simple_name: "邵阳",
                hidden: 0,
                children: [
                    {
                        value: "430502",
                        label: "双清区",
                        simple_name: "双清",
                        hidden: 0
                    },
                    {
                        value: "430503",
                        label: "大祥区",
                        simple_name: "大祥",
                        hidden: 0
                    },
                    {
                        value: "430511",
                        label: "北塔区",
                        simple_name: "北塔",
                        hidden: 0
                    },
                    {
                        value: "430521",
                        label: "邵东县",
                        simple_name: "邵东",
                        hidden: 0
                    },
                    {
                        value: "430522",
                        label: "新邵县",
                        simple_name: "新邵",
                        hidden: 0
                    },
                    {
                        value: "430523",
                        label: "邵阳县",
                        simple_name: "邵阳",
                        hidden: 0
                    },
                    {
                        value: "430524",
                        label: "隆回县",
                        simple_name: "隆回",
                        hidden: 0
                    },
                    {
                        value: "430525",
                        label: "洞口县",
                        simple_name: "洞口",
                        hidden: 0
                    },
                    {
                        value: "430527",
                        label: "绥宁县",
                        simple_name: "绥宁",
                        hidden: 0
                    },
                    {
                        value: "430528",
                        label: "新宁县",
                        simple_name: "新宁",
                        hidden: 0
                    },
                    {
                        value: "430529",
                        label: "城步苗族自治县",
                        simple_name: "城步",
                        hidden: 0
                    },
                    {
                        value: "430581",
                        label: "武冈市",
                        simple_name: "武冈",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430600",
                label: "岳阳市",
                simple_name: "岳阳",
                hidden: 0,
                children: [
                    {
                        value: "430602",
                        label: "岳阳楼区",
                        simple_name: "岳阳楼",
                        hidden: 0
                    },
                    {
                        value: "430603",
                        label: "云溪区",
                        simple_name: "云溪",
                        hidden: 0
                    },
                    {
                        value: "430611",
                        label: "君山区",
                        simple_name: "君山",
                        hidden: 0
                    },
                    {
                        value: "430621",
                        label: "岳阳县",
                        simple_name: "岳阳",
                        hidden: 0
                    },
                    {
                        value: "430623",
                        label: "华容县",
                        simple_name: "华容",
                        hidden: 0
                    },
                    {
                        value: "430624",
                        label: "湘阴县",
                        simple_name: "湘阴",
                        hidden: 0
                    },
                    {
                        value: "430626",
                        label: "平江县",
                        simple_name: "平江",
                        hidden: 0
                    },
                    {
                        value: "430681",
                        label: "汨罗市",
                        simple_name: "汨罗",
                        hidden: 0
                    },
                    {
                        value: "430682",
                        label: "临湘市",
                        simple_name: "临湘",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430700",
                label: "常德市",
                simple_name: "常德",
                hidden: 0,
                children: [
                    {
                        value: "430702",
                        label: "武陵区",
                        simple_name: "武陵",
                        hidden: 0
                    },
                    {
                        value: "430703",
                        label: "鼎城区",
                        simple_name: "鼎城",
                        hidden: 0
                    },
                    {
                        value: "430721",
                        label: "安乡县",
                        simple_name: "安乡",
                        hidden: 0
                    },
                    {
                        value: "430722",
                        label: "汉寿县",
                        simple_name: "汉寿",
                        hidden: 0
                    },
                    {
                        value: "430723",
                        label: "澧县",
                        simple_name: "澧县",
                        hidden: 0
                    },
                    {
                        value: "430724",
                        label: "临澧县",
                        simple_name: "临澧",
                        hidden: 0
                    },
                    {
                        value: "430725",
                        label: "桃源县",
                        simple_name: "桃源",
                        hidden: 0
                    },
                    {
                        value: "430726",
                        label: "石门县",
                        simple_name: "石门",
                        hidden: 0
                    },
                    {
                        value: "430781",
                        label: "津市市",
                        simple_name: "津市",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430800",
                label: "张家界市",
                simple_name: "张家界",
                hidden: 0,
                children: [
                    {
                        value: "430802",
                        label: "永定区",
                        simple_name: "永定",
                        hidden: 0
                    },
                    {
                        value: "430811",
                        label: "武陵源区",
                        simple_name: "武陵源",
                        hidden: 0
                    },
                    {
                        value: "430821",
                        label: "慈利县",
                        simple_name: "慈利",
                        hidden: 0
                    },
                    {
                        value: "430822",
                        label: "桑植县",
                        simple_name: "桑植",
                        hidden: 0
                    }
                ]
            },
            {
                value: "430900",
                label: "益阳市",
                simple_name: "益阳",
                hidden: 0,
                children: [
                    {
                        value: "430902",
                        label: "资阳区",
                        simple_name: "资阳",
                        hidden: 0
                    },
                    {
                        value: "430903",
                        label: "赫山区",
                        simple_name: "赫山",
                        hidden: 0
                    },
                    {
                        value: "430921",
                        label: "南县",
                        simple_name: "南县",
                        hidden: 0
                    },
                    {
                        value: "430922",
                        label: "桃江县",
                        simple_name: "桃江",
                        hidden: 0
                    },
                    {
                        value: "430923",
                        label: "安化县",
                        simple_name: "安化",
                        hidden: 0
                    },
                    {
                        value: "430981",
                        label: "沅江市",
                        simple_name: "沅江",
                        hidden: 0
                    }
                ]
            },
            {
                value: "431000",
                label: "郴州市",
                simple_name: "郴州",
                hidden: 0,
                children: [
                    {
                        value: "431002",
                        label: "北湖区",
                        simple_name: "北湖",
                        hidden: 0
                    },
                    {
                        value: "431003",
                        label: "苏仙区",
                        simple_name: "苏仙",
                        hidden: 0
                    },
                    {
                        value: "431021",
                        label: "桂阳县",
                        simple_name: "桂阳",
                        hidden: 0
                    },
                    {
                        value: "431022",
                        label: "宜章县",
                        simple_name: "宜章",
                        hidden: 0
                    },
                    {
                        value: "431023",
                        label: "永兴县",
                        simple_name: "永兴",
                        hidden: 0
                    },
                    {
                        value: "431024",
                        label: "嘉禾县",
                        simple_name: "嘉禾",
                        hidden: 0
                    },
                    {
                        value: "431025",
                        label: "临武县",
                        simple_name: "临武",
                        hidden: 0
                    },
                    {
                        value: "431026",
                        label: "汝城县",
                        simple_name: "汝城",
                        hidden: 0
                    },
                    {
                        value: "431027",
                        label: "桂东县",
                        simple_name: "桂东",
                        hidden: 0
                    },
                    {
                        value: "431028",
                        label: "安仁县",
                        simple_name: "安仁",
                        hidden: 0
                    },
                    {
                        value: "431081",
                        label: "资兴市",
                        simple_name: "资兴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "431100",
                label: "永州市",
                simple_name: "永州",
                hidden: 0,
                children: [
                    {
                        value: "431102",
                        label: "零陵区",
                        simple_name: "零陵",
                        hidden: 0
                    },
                    {
                        value: "431103",
                        label: "冷水滩区",
                        simple_name: "冷水滩",
                        hidden: 0
                    },
                    {
                        value: "431121",
                        label: "祁阳县",
                        simple_name: "祁阳",
                        hidden: 0
                    },
                    {
                        value: "431122",
                        label: "东安县",
                        simple_name: "东安",
                        hidden: 0
                    },
                    {
                        value: "431123",
                        label: "双牌县",
                        simple_name: "双牌",
                        hidden: 0
                    },
                    {
                        value: "431124",
                        label: "道县",
                        simple_name: "道县",
                        hidden: 0
                    },
                    {
                        value: "431125",
                        label: "江永县",
                        simple_name: "江永",
                        hidden: 0
                    },
                    {
                        value: "431126",
                        label: "宁远县",
                        simple_name: "宁远",
                        hidden: 0
                    },
                    {
                        value: "431127",
                        label: "蓝山县",
                        simple_name: "蓝山",
                        hidden: 0
                    },
                    {
                        value: "431128",
                        label: "新田县",
                        simple_name: "新田",
                        hidden: 0
                    },
                    {
                        value: "431129",
                        label: "江华瑶族自治县",
                        simple_name: "江华",
                        hidden: 0
                    }
                ]
            },
            {
                value: "431200",
                label: "怀化市",
                simple_name: "怀化",
                hidden: 0,
                children: [
                    {
                        value: "431202",
                        label: "鹤城区",
                        simple_name: "鹤城",
                        hidden: 0
                    },
                    {
                        value: "431221",
                        label: "中方县",
                        simple_name: "中方",
                        hidden: 0
                    },
                    {
                        value: "431222",
                        label: "沅陵县",
                        simple_name: "沅陵",
                        hidden: 0
                    },
                    {
                        value: "431223",
                        label: "辰溪县",
                        simple_name: "辰溪",
                        hidden: 0
                    },
                    {
                        value: "431224",
                        label: "溆浦县",
                        simple_name: "溆浦",
                        hidden: 0
                    },
                    {
                        value: "431225",
                        label: "会同县",
                        simple_name: "会同",
                        hidden: 0
                    },
                    {
                        value: "431226",
                        label: "麻阳苗族自治县",
                        simple_name: "麻阳",
                        hidden: 0
                    },
                    {
                        value: "431227",
                        label: "新晃侗族自治县",
                        simple_name: "新晃",
                        hidden: 0
                    },
                    {
                        value: "431228",
                        label: "芷江侗族自治县",
                        simple_name: "芷江",
                        hidden: 0
                    },
                    {
                        value: "431229",
                        label: "靖州苗族侗族自治县",
                        simple_name: "靖州",
                        hidden: 0
                    },
                    {
                        value: "431230",
                        label: "通道侗族自治县",
                        simple_name: "通道",
                        hidden: 0
                    },
                    {
                        value: "431281",
                        label: "洪江市",
                        simple_name: "洪江",
                        hidden: 0
                    }
                ]
            },
            {
                value: "431300",
                label: "娄底市",
                simple_name: "娄底",
                hidden: 0,
                children: [
                    {
                        value: "431302",
                        label: "娄星区",
                        simple_name: "娄星",
                        hidden: 0
                    },
                    {
                        value: "431321",
                        label: "双峰县",
                        simple_name: "双峰",
                        hidden: 0
                    },
                    {
                        value: "431322",
                        label: "新化县",
                        simple_name: "新化",
                        hidden: 0
                    },
                    {
                        value: "431381",
                        label: "冷水江市",
                        simple_name: "冷水江",
                        hidden: 0
                    },
                    {
                        value: "431382",
                        label: "涟源市",
                        simple_name: "涟源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "433100",
                label: "湘西土家族苗族自治州",
                simple_name: "湘西",
                hidden: 0,
                children: [
                    {
                        value: "433101",
                        label: "吉首市",
                        simple_name: "吉首",
                        hidden: 0
                    },
                    {
                        value: "433122",
                        label: "泸溪县",
                        simple_name: "泸溪",
                        hidden: 0
                    },
                    {
                        value: "433123",
                        label: "凤凰县",
                        simple_name: "凤凰",
                        hidden: 0
                    },
                    {
                        value: "433124",
                        label: "花垣县",
                        simple_name: "花垣",
                        hidden: 0
                    },
                    {
                        value: "433125",
                        label: "保靖县",
                        simple_name: "保靖",
                        hidden: 0
                    },
                    {
                        value: "433126",
                        label: "古丈县",
                        simple_name: "古丈",
                        hidden: 0
                    },
                    {
                        value: "433127",
                        label: "永顺县",
                        simple_name: "永顺",
                        hidden: 0
                    },
                    {
                        value: "433130",
                        label: "龙山县",
                        simple_name: "龙山",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "440000",
        label: "广东省",
        simple_name: "广东",
        hidden: 0,
        children: [
            {
                value: "440100",
                label: "广州市",
                simple_name: "广州",
                hidden: 0,
                children: [
                    {
                        value: "440103",
                        label: "荔湾区",
                        simple_name: "荔湾",
                        hidden: 0
                    },
                    {
                        value: "440104",
                        label: "越秀区",
                        simple_name: "越秀",
                        hidden: 0
                    },
                    {
                        value: "440105",
                        label: "海珠区",
                        simple_name: "海珠",
                        hidden: 0
                    },
                    {
                        value: "440106",
                        label: "天河区",
                        simple_name: "天河",
                        hidden: 0
                    },
                    {
                        value: "440111",
                        label: "白云区",
                        simple_name: "白云",
                        hidden: 0
                    },
                    {
                        value: "440112",
                        label: "黄埔区",
                        simple_name: "黄埔",
                        hidden: 0
                    },
                    {
                        value: "440113",
                        label: "番禺区",
                        simple_name: "番禺",
                        hidden: 0
                    },
                    {
                        value: "440114",
                        label: "花都区",
                        simple_name: "花都",
                        hidden: 0
                    },
                    {
                        value: "440115",
                        label: "南沙区",
                        simple_name: "南沙",
                        hidden: 0
                    },
                    {
                        value: "440117",
                        label: "从化区",
                        simple_name: "从化",
                        hidden: 0
                    },
                    {
                        value: "440118",
                        label: "增城区",
                        simple_name: "增城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440200",
                label: "韶关市",
                simple_name: "韶关",
                hidden: 0,
                children: [
                    {
                        value: "440203",
                        label: "武江区",
                        simple_name: "武江",
                        hidden: 0
                    },
                    {
                        value: "440204",
                        label: "浈江区",
                        simple_name: "浈江",
                        hidden: 0
                    },
                    {
                        value: "440205",
                        label: "曲江区",
                        simple_name: "曲江",
                        hidden: 0
                    },
                    {
                        value: "440222",
                        label: "始兴县",
                        simple_name: "始兴",
                        hidden: 0
                    },
                    {
                        value: "440224",
                        label: "仁化县",
                        simple_name: "仁化",
                        hidden: 0
                    },
                    {
                        value: "440229",
                        label: "翁源县",
                        simple_name: "翁源",
                        hidden: 0
                    },
                    {
                        value: "440232",
                        label: "乳源瑶族自治县",
                        simple_name: "乳源",
                        hidden: 0
                    },
                    {
                        value: "440233",
                        label: "新丰县",
                        simple_name: "新丰",
                        hidden: 0
                    },
                    {
                        value: "440281",
                        label: "乐昌市",
                        simple_name: "乐昌",
                        hidden: 0
                    },
                    {
                        value: "440282",
                        label: "南雄市",
                        simple_name: "南雄",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440300",
                label: "深圳市",
                simple_name: "深圳",
                hidden: 0,
                children: [
                    {
                        value: "440303",
                        label: "罗湖区",
                        simple_name: "罗湖",
                        hidden: 0
                    },
                    {
                        value: "440304",
                        label: "福田区",
                        simple_name: "福田",
                        hidden: 0
                    },
                    {
                        value: "440305",
                        label: "南山区",
                        simple_name: "南山",
                        hidden: 0
                    },
                    {
                        value: "440306",
                        label: "宝安区",
                        simple_name: "宝安",
                        hidden: 0
                    },
                    {
                        value: "440307",
                        label: "龙岗区",
                        simple_name: "龙岗",
                        hidden: 0
                    },
                    {
                        value: "440308",
                        label: "盐田区",
                        simple_name: "盐田",
                        hidden: 0
                    },
                    {
                        value: "440309",
                        label: "光明新区",
                        simple_name: "光明新区",
                        hidden: 0
                    },
                    {
                        value: "440310",
                        label: "坪山新区",
                        simple_name: "坪山新区",
                        hidden: 0
                    },
                    {
                        value: "440311",
                        label: "大鹏新区",
                        simple_name: "大鹏新区",
                        hidden: 0
                    },
                    {
                        value: "440312",
                        label: "龙华新区",
                        simple_name: "龙华新区",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440400",
                label: "珠海市",
                simple_name: "珠海",
                hidden: 0,
                children: [
                    {
                        value: "440402",
                        label: "香洲区",
                        simple_name: "香洲",
                        hidden: 0
                    },
                    {
                        value: "440403",
                        label: "斗门区",
                        simple_name: "斗门",
                        hidden: 0
                    },
                    {
                        value: "440404",
                        label: "金湾区",
                        simple_name: "金湾",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440500",
                label: "汕头市",
                simple_name: "汕头",
                hidden: 0,
                children: [
                    {
                        value: "440507",
                        label: "龙湖区",
                        simple_name: "龙湖",
                        hidden: 0
                    },
                    {
                        value: "440511",
                        label: "金平区",
                        simple_name: "金平",
                        hidden: 0
                    },
                    {
                        value: "440512",
                        label: "濠江区",
                        simple_name: "濠江",
                        hidden: 0
                    },
                    {
                        value: "440513",
                        label: "潮阳区",
                        simple_name: "潮阳",
                        hidden: 0
                    },
                    {
                        value: "440514",
                        label: "潮南区",
                        simple_name: "潮南",
                        hidden: 0
                    },
                    {
                        value: "440515",
                        label: "澄海区",
                        simple_name: "澄海",
                        hidden: 0
                    },
                    {
                        value: "440523",
                        label: "南澳县",
                        simple_name: "南澳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440600",
                label: "佛山市",
                simple_name: "佛山",
                hidden: 0,
                children: [
                    {
                        value: "440604",
                        label: "禅城区",
                        simple_name: "禅城",
                        hidden: 0
                    },
                    {
                        value: "440605",
                        label: "南海区",
                        simple_name: "南海",
                        hidden: 0
                    },
                    {
                        value: "440606",
                        label: "顺德区",
                        simple_name: "顺德",
                        hidden: 0
                    },
                    {
                        value: "440607",
                        label: "三水区",
                        simple_name: "三水",
                        hidden: 0
                    },
                    {
                        value: "440608",
                        label: "高明区",
                        simple_name: "高明",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440700",
                label: "江门市",
                simple_name: "江门",
                hidden: 0,
                children: [
                    {
                        value: "440703",
                        label: "蓬江区",
                        simple_name: "蓬江",
                        hidden: 0
                    },
                    {
                        value: "440704",
                        label: "江海区",
                        simple_name: "江海",
                        hidden: 0
                    },
                    {
                        value: "440705",
                        label: "新会区",
                        simple_name: "新会",
                        hidden: 0
                    },
                    {
                        value: "440781",
                        label: "台山市",
                        simple_name: "台山",
                        hidden: 0
                    },
                    {
                        value: "440783",
                        label: "开平市",
                        simple_name: "开平",
                        hidden: 0
                    },
                    {
                        value: "440784",
                        label: "鹤山市",
                        simple_name: "鹤山",
                        hidden: 0
                    },
                    {
                        value: "440785",
                        label: "恩平市",
                        simple_name: "恩平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440800",
                label: "湛江市",
                simple_name: "湛江",
                hidden: 0,
                children: [
                    {
                        value: "440802",
                        label: "赤坎区",
                        simple_name: "赤坎",
                        hidden: 0
                    },
                    {
                        value: "440803",
                        label: "霞山区",
                        simple_name: "霞山",
                        hidden: 0
                    },
                    {
                        value: "440804",
                        label: "坡头区",
                        simple_name: "坡头",
                        hidden: 0
                    },
                    {
                        value: "440811",
                        label: "麻章区",
                        simple_name: "麻章",
                        hidden: 0
                    },
                    {
                        value: "440823",
                        label: "遂溪县",
                        simple_name: "遂溪",
                        hidden: 0
                    },
                    {
                        value: "440825",
                        label: "徐闻县",
                        simple_name: "徐闻",
                        hidden: 0
                    },
                    {
                        value: "440881",
                        label: "廉江市",
                        simple_name: "廉江",
                        hidden: 0
                    },
                    {
                        value: "440882",
                        label: "雷州市",
                        simple_name: "雷州",
                        hidden: 0
                    },
                    {
                        value: "440883",
                        label: "吴川市",
                        simple_name: "吴川",
                        hidden: 0
                    }
                ]
            },
            {
                value: "440900",
                label: "茂名市",
                simple_name: "茂名",
                hidden: 0,
                children: [
                    {
                        value: "440902",
                        label: "茂南区",
                        simple_name: "茂南",
                        hidden: 0
                    },
                    {
                        value: "440904",
                        label: "电白区",
                        simple_name: "电白",
                        hidden: 0
                    },
                    {
                        value: "440981",
                        label: "高州市",
                        simple_name: "高州",
                        hidden: 0
                    },
                    {
                        value: "440982",
                        label: "化州市",
                        simple_name: "化州",
                        hidden: 0
                    },
                    {
                        value: "440983",
                        label: "信宜市",
                        simple_name: "信宜",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441200",
                label: "肇庆市",
                simple_name: "肇庆",
                hidden: 0,
                children: [
                    {
                        value: "441202",
                        label: "端州区",
                        simple_name: "端州",
                        hidden: 0
                    },
                    {
                        value: "441203",
                        label: "鼎湖区",
                        simple_name: "鼎湖",
                        hidden: 0
                    },
                    {
                        value: "441223",
                        label: "广宁县",
                        simple_name: "广宁",
                        hidden: 0
                    },
                    {
                        value: "441224",
                        label: "怀集县",
                        simple_name: "怀集",
                        hidden: 0
                    },
                    {
                        value: "441225",
                        label: "封开县",
                        simple_name: "封开",
                        hidden: 0
                    },
                    {
                        value: "441226",
                        label: "德庆县",
                        simple_name: "德庆",
                        hidden: 0
                    },
                    {
                        value: "441283",
                        label: "高要市",
                        simple_name: "高要",
                        hidden: 0
                    },
                    {
                        value: "441284",
                        label: "四会市",
                        simple_name: "四会",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441300",
                label: "惠州市",
                simple_name: "惠州",
                hidden: 0,
                children: [
                    {
                        value: "441302",
                        label: "惠城区",
                        simple_name: "惠城",
                        hidden: 0
                    },
                    {
                        value: "441303",
                        label: "惠阳区",
                        simple_name: "惠阳",
                        hidden: 0
                    },
                    {
                        value: "441322",
                        label: "博罗县",
                        simple_name: "博罗",
                        hidden: 0
                    },
                    {
                        value: "441323",
                        label: "惠东县",
                        simple_name: "惠东",
                        hidden: 0
                    },
                    {
                        value: "441324",
                        label: "龙门县",
                        simple_name: "龙门",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441400",
                label: "梅州市",
                simple_name: "梅州",
                hidden: 0,
                children: [
                    {
                        value: "441402",
                        label: "梅江区",
                        simple_name: "梅江",
                        hidden: 0
                    },
                    {
                        value: "441403",
                        label: "梅县区",
                        simple_name: "梅县",
                        hidden: 0
                    },
                    {
                        value: "441422",
                        label: "大埔县",
                        simple_name: "大埔",
                        hidden: 0
                    },
                    {
                        value: "441423",
                        label: "丰顺县",
                        simple_name: "丰顺",
                        hidden: 0
                    },
                    {
                        value: "441424",
                        label: "五华县",
                        simple_name: "五华",
                        hidden: 0
                    },
                    {
                        value: "441426",
                        label: "平远县",
                        simple_name: "平远",
                        hidden: 0
                    },
                    {
                        value: "441427",
                        label: "蕉岭县",
                        simple_name: "蕉岭",
                        hidden: 0
                    },
                    {
                        value: "441481",
                        label: "兴宁市",
                        simple_name: "兴宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441500",
                label: "汕尾市",
                simple_name: "汕尾",
                hidden: 0,
                children: [
                    {
                        value: "441502",
                        label: "城区",
                        simple_name: "城区",
                        hidden: 0
                    },
                    {
                        value: "441521",
                        label: "海丰县",
                        simple_name: "海丰",
                        hidden: 0
                    },
                    {
                        value: "441523",
                        label: "陆河县",
                        simple_name: "陆河",
                        hidden: 0
                    },
                    {
                        value: "441581",
                        label: "陆丰市",
                        simple_name: "陆丰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441600",
                label: "河源市",
                simple_name: "河源",
                hidden: 0,
                children: [
                    {
                        value: "441602",
                        label: "源城区",
                        simple_name: "源城",
                        hidden: 0
                    },
                    {
                        value: "441621",
                        label: "紫金县",
                        simple_name: "紫金",
                        hidden: 0
                    },
                    {
                        value: "441622",
                        label: "龙川县",
                        simple_name: "龙川",
                        hidden: 0
                    },
                    {
                        value: "441623",
                        label: "连平县",
                        simple_name: "连平",
                        hidden: 0
                    },
                    {
                        value: "441624",
                        label: "和平县",
                        simple_name: "和平",
                        hidden: 0
                    },
                    {
                        value: "441625",
                        label: "东源县",
                        simple_name: "东源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441700",
                label: "阳江市",
                simple_name: "阳江",
                hidden: 0,
                children: [
                    {
                        value: "441702",
                        label: "江城区",
                        simple_name: "江城",
                        hidden: 0
                    },
                    {
                        value: "441704",
                        label: "阳东区",
                        simple_name: "阳东",
                        hidden: 0
                    },
                    {
                        value: "441721",
                        label: "阳西县",
                        simple_name: "阳西",
                        hidden: 0
                    },
                    {
                        value: "441781",
                        label: "阳春市",
                        simple_name: "阳春",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441800",
                label: "清远市",
                simple_name: "清远",
                hidden: 0,
                children: [
                    {
                        value: "441802",
                        label: "清城区",
                        simple_name: "清城",
                        hidden: 0
                    },
                    {
                        value: "441803",
                        label: "清新区",
                        simple_name: "清新",
                        hidden: 0
                    },
                    {
                        value: "441821",
                        label: "佛冈县",
                        simple_name: "佛冈",
                        hidden: 0
                    },
                    {
                        value: "441823",
                        label: "阳山县",
                        simple_name: "阳山",
                        hidden: 0
                    },
                    {
                        value: "441825",
                        label: "连山壮族瑶族自治县",
                        simple_name: "连山",
                        hidden: 0
                    },
                    {
                        value: "441826",
                        label: "连南瑶族自治县",
                        simple_name: "连南",
                        hidden: 0
                    },
                    {
                        value: "441881",
                        label: "英德市",
                        simple_name: "英德",
                        hidden: 0
                    },
                    {
                        value: "441882",
                        label: "连州市",
                        simple_name: "连州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "441900",
                label: "东莞市",
                simple_name: "东莞",
                hidden: 0,
                children: [
                    {
                        value: "441901",
                        label: "莞城区",
                        simple_name: "莞城",
                        hidden: 0
                    },
                    {
                        value: "441902",
                        label: "南城区",
                        simple_name: "南城",
                        hidden: 0
                    },
                    {
                        value: "441904",
                        label: "万江区",
                        simple_name: "万江",
                        hidden: 0
                    },
                    {
                        value: "441905",
                        label: "石碣镇",
                        simple_name: "石碣",
                        hidden: 0
                    },
                    {
                        value: "441906",
                        label: "石龙镇",
                        simple_name: "石龙",
                        hidden: 0
                    },
                    {
                        value: "441907",
                        label: "茶山镇",
                        simple_name: "茶山",
                        hidden: 0
                    },
                    {
                        value: "441908",
                        label: "石排镇",
                        simple_name: "石排",
                        hidden: 0
                    },
                    {
                        value: "441909",
                        label: "企石镇",
                        simple_name: "企石",
                        hidden: 0
                    },
                    {
                        value: "441910",
                        label: "横沥镇",
                        simple_name: "横沥",
                        hidden: 0
                    },
                    {
                        value: "441911",
                        label: "桥头镇",
                        simple_name: "桥头",
                        hidden: 0
                    },
                    {
                        value: "441912",
                        label: "谢岗镇",
                        simple_name: "谢岗",
                        hidden: 0
                    },
                    {
                        value: "441913",
                        label: "东坑镇",
                        simple_name: "东坑",
                        hidden: 0
                    },
                    {
                        value: "441914",
                        label: "常平镇",
                        simple_name: "常平",
                        hidden: 0
                    },
                    {
                        value: "441915",
                        label: "寮步镇",
                        simple_name: "寮步",
                        hidden: 0
                    },
                    {
                        value: "441916",
                        label: "大朗镇",
                        simple_name: "大朗",
                        hidden: 0
                    },
                    {
                        value: "441917",
                        label: "麻涌镇",
                        simple_name: "麻涌",
                        hidden: 0
                    },
                    {
                        value: "441918",
                        label: "中堂镇",
                        simple_name: "中堂",
                        hidden: 0
                    },
                    {
                        value: "441919",
                        label: "高埗镇",
                        simple_name: "高埗",
                        hidden: 0
                    },
                    {
                        value: "441920",
                        label: "樟木头镇",
                        simple_name: "樟木头",
                        hidden: 0
                    },
                    {
                        value: "441921",
                        label: "大岭山镇",
                        simple_name: "大岭山",
                        hidden: 0
                    },
                    {
                        value: "441922",
                        label: "望牛墩镇",
                        simple_name: "望牛墩",
                        hidden: 0
                    },
                    {
                        value: "441923",
                        label: "黄江镇",
                        simple_name: "黄江",
                        hidden: 0
                    },
                    {
                        value: "441924",
                        label: "洪梅镇",
                        simple_name: "洪梅",
                        hidden: 0
                    },
                    {
                        value: "441925",
                        label: "清溪镇",
                        simple_name: "清溪",
                        hidden: 0
                    },
                    {
                        value: "441926",
                        label: "沙田镇",
                        simple_name: "沙田",
                        hidden: 0
                    },
                    {
                        value: "441927",
                        label: "道滘镇",
                        simple_name: "道滘",
                        hidden: 0
                    },
                    {
                        value: "441928",
                        label: "塘厦镇",
                        simple_name: "塘厦",
                        hidden: 0
                    },
                    {
                        value: "441929",
                        label: "虎门镇",
                        simple_name: "虎门",
                        hidden: 0
                    },
                    {
                        value: "441930",
                        label: "厚街镇",
                        simple_name: "厚街",
                        hidden: 0
                    },
                    {
                        value: "441931",
                        label: "凤岗镇",
                        simple_name: "凤岗",
                        hidden: 0
                    },
                    {
                        value: "441932",
                        label: "长安镇",
                        simple_name: "长安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "442000",
                label: "中山市",
                simple_name: "中山",
                hidden: 0,
                children: [
                    {
                        value: "442001",
                        label: "石岐区",
                        simple_name: "石岐",
                        hidden: 0
                    },
                    {
                        value: "442004",
                        label: "南区",
                        simple_name: "南区",
                        hidden: 0
                    },
                    {
                        value: "442005",
                        label: "五桂山区",
                        simple_name: "五桂山",
                        hidden: 0
                    },
                    {
                        value: "442006",
                        label: "火炬开发区",
                        simple_name: "火炬",
                        hidden: 0
                    },
                    {
                        value: "442007",
                        label: "黄圃镇",
                        simple_name: "黄圃",
                        hidden: 0
                    },
                    {
                        value: "442008",
                        label: "南头镇",
                        simple_name: "南头",
                        hidden: 0
                    },
                    {
                        value: "442009",
                        label: "东凤镇",
                        simple_name: "东凤",
                        hidden: 0
                    },
                    {
                        value: "442010",
                        label: "阜沙镇",
                        simple_name: "阜沙",
                        hidden: 0
                    },
                    {
                        value: "442011",
                        label: "小榄镇",
                        simple_name: "小榄",
                        hidden: 0
                    },
                    {
                        value: "442012",
                        label: "东升镇",
                        simple_name: "东升",
                        hidden: 0
                    },
                    {
                        value: "442013",
                        label: "古镇镇",
                        simple_name: "古镇",
                        hidden: 0
                    },
                    {
                        value: "442014",
                        label: "横栏镇",
                        simple_name: "横栏",
                        hidden: 0
                    },
                    {
                        value: "442015",
                        label: "三角镇",
                        simple_name: "三角",
                        hidden: 0
                    },
                    {
                        value: "442016",
                        label: "民众镇",
                        simple_name: "民众",
                        hidden: 0
                    },
                    {
                        value: "442017",
                        label: "南朗镇",
                        simple_name: "南朗",
                        hidden: 0
                    },
                    {
                        value: "442018",
                        label: "港口镇",
                        simple_name: "港口",
                        hidden: 0
                    },
                    {
                        value: "442019",
                        label: "大涌镇",
                        simple_name: "大涌",
                        hidden: 0
                    },
                    {
                        value: "442020",
                        label: "沙溪镇",
                        simple_name: "沙溪",
                        hidden: 0
                    },
                    {
                        value: "442021",
                        label: "三乡镇",
                        simple_name: "三乡",
                        hidden: 0
                    },
                    {
                        value: "442022",
                        label: "板芙镇",
                        simple_name: "板芙",
                        hidden: 0
                    },
                    {
                        value: "442023",
                        label: "神湾镇",
                        simple_name: "神湾",
                        hidden: 0
                    },
                    {
                        value: "442024",
                        label: "坦洲镇",
                        simple_name: "坦洲",
                        hidden: 0
                    }
                ]
            },
            {
                value: "445100",
                label: "潮州市",
                simple_name: "潮州",
                hidden: 0,
                children: [
                    {
                        value: "445102",
                        label: "湘桥区",
                        simple_name: "湘桥",
                        hidden: 0
                    },
                    {
                        value: "445103",
                        label: "潮安区",
                        simple_name: "潮安",
                        hidden: 0
                    },
                    {
                        value: "445122",
                        label: "饶平县",
                        simple_name: "饶平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "445200",
                label: "揭阳市",
                simple_name: "揭阳",
                hidden: 0,
                children: [
                    {
                        value: "445202",
                        label: "榕城区",
                        simple_name: "榕城",
                        hidden: 0
                    },
                    {
                        value: "445203",
                        label: "揭东区",
                        simple_name: "揭东",
                        hidden: 0
                    },
                    {
                        value: "445222",
                        label: "揭西县",
                        simple_name: "揭西",
                        hidden: 0
                    },
                    {
                        value: "445224",
                        label: "惠来县",
                        simple_name: "惠来",
                        hidden: 0
                    },
                    {
                        value: "445281",
                        label: "普宁市",
                        simple_name: "普宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "445300",
                label: "云浮市",
                simple_name: "云浮",
                hidden: 0,
                children: [
                    {
                        value: "445302",
                        label: "云城区",
                        simple_name: "云城",
                        hidden: 0
                    },
                    {
                        value: "445303",
                        label: "云安区",
                        simple_name: "云安",
                        hidden: 0
                    },
                    {
                        value: "445321",
                        label: "新兴县",
                        simple_name: "新兴",
                        hidden: 0
                    },
                    {
                        value: "445322",
                        label: "郁南县",
                        simple_name: "郁南",
                        hidden: 0
                    },
                    {
                        value: "445381",
                        label: "罗定市",
                        simple_name: "罗定",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "450000",
        label: "广西壮族自治区",
        simple_name: "广西",
        hidden: 0,
        children: [
            {
                value: "450100",
                label: "南宁市",
                simple_name: "南宁",
                hidden: 0,
                children: [
                    {
                        value: "450102",
                        label: "兴宁区",
                        simple_name: "兴宁",
                        hidden: 0
                    },
                    {
                        value: "450103",
                        label: "青秀区",
                        simple_name: "青秀",
                        hidden: 0
                    },
                    {
                        value: "450105",
                        label: "江南区",
                        simple_name: "江南",
                        hidden: 0
                    },
                    {
                        value: "450107",
                        label: "西乡塘区",
                        simple_name: "西乡塘",
                        hidden: 0
                    },
                    {
                        value: "450108",
                        label: "良庆区",
                        simple_name: "良庆",
                        hidden: 0
                    },
                    {
                        value: "450109",
                        label: "邕宁区",
                        simple_name: "邕宁",
                        hidden: 0
                    },
                    {
                        value: "450122",
                        label: "武鸣县",
                        simple_name: "武鸣",
                        hidden: 0
                    },
                    {
                        value: "450123",
                        label: "隆安县",
                        simple_name: "隆安",
                        hidden: 0
                    },
                    {
                        value: "450124",
                        label: "马山县",
                        simple_name: "马山",
                        hidden: 0
                    },
                    {
                        value: "450125",
                        label: "上林县",
                        simple_name: "上林",
                        hidden: 0
                    },
                    {
                        value: "450126",
                        label: "宾阳县",
                        simple_name: "宾阳",
                        hidden: 0
                    },
                    {
                        value: "450127",
                        label: "横县",
                        simple_name: "横县",
                        hidden: 0
                    },
                    {
                        value: "450128",
                        label: "埌东新区",
                        simple_name: "埌东",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450200",
                label: "柳州市",
                simple_name: "柳州",
                hidden: 0,
                children: [
                    {
                        value: "450202",
                        label: "城中区",
                        simple_name: "城中",
                        hidden: 0
                    },
                    {
                        value: "450203",
                        label: "鱼峰区",
                        simple_name: "鱼峰",
                        hidden: 0
                    },
                    {
                        value: "450204",
                        label: "柳南区",
                        simple_name: "柳南",
                        hidden: 0
                    },
                    {
                        value: "450205",
                        label: "柳北区",
                        simple_name: "柳北",
                        hidden: 0
                    },
                    {
                        value: "450221",
                        label: "柳江县",
                        simple_name: "柳江",
                        hidden: 0
                    },
                    {
                        value: "450222",
                        label: "柳城县",
                        simple_name: "柳城",
                        hidden: 0
                    },
                    {
                        value: "450223",
                        label: "鹿寨县",
                        simple_name: "鹿寨",
                        hidden: 0
                    },
                    {
                        value: "450224",
                        label: "融安县",
                        simple_name: "融安",
                        hidden: 0
                    },
                    {
                        value: "450225",
                        label: "融水苗族自治县",
                        simple_name: "融水",
                        hidden: 0
                    },
                    {
                        value: "450226",
                        label: "三江侗族自治县",
                        simple_name: "三江",
                        hidden: 0
                    },
                    {
                        value: "450227",
                        label: "柳东新区",
                        simple_name: "柳东",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450300",
                label: "桂林市",
                simple_name: "桂林",
                hidden: 0,
                children: [
                    {
                        value: "450302",
                        label: "秀峰区",
                        simple_name: "秀峰",
                        hidden: 0
                    },
                    {
                        value: "450303",
                        label: "叠彩区",
                        simple_name: "叠彩",
                        hidden: 0
                    },
                    {
                        value: "450304",
                        label: "象山区",
                        simple_name: "象山",
                        hidden: 0
                    },
                    {
                        value: "450305",
                        label: "七星区",
                        simple_name: "七星",
                        hidden: 0
                    },
                    {
                        value: "450311",
                        label: "雁山区",
                        simple_name: "雁山",
                        hidden: 0
                    },
                    {
                        value: "450312",
                        label: "临桂区",
                        simple_name: "临桂",
                        hidden: 0
                    },
                    {
                        value: "450321",
                        label: "阳朔县",
                        simple_name: "阳朔",
                        hidden: 0
                    },
                    {
                        value: "450323",
                        label: "灵川县",
                        simple_name: "灵川",
                        hidden: 0
                    },
                    {
                        value: "450324",
                        label: "全州县",
                        simple_name: "全州",
                        hidden: 0
                    },
                    {
                        value: "450325",
                        label: "兴安县",
                        simple_name: "兴安",
                        hidden: 0
                    },
                    {
                        value: "450326",
                        label: "永福县",
                        simple_name: "永福",
                        hidden: 0
                    },
                    {
                        value: "450327",
                        label: "灌阳县",
                        simple_name: "灌阳",
                        hidden: 0
                    },
                    {
                        value: "450328",
                        label: "龙胜各族自治县",
                        simple_name: "龙胜",
                        hidden: 0
                    },
                    {
                        value: "450329",
                        label: "资源县",
                        simple_name: "资源",
                        hidden: 0
                    },
                    {
                        value: "450330",
                        label: "平乐县",
                        simple_name: "平乐",
                        hidden: 0
                    },
                    {
                        value: "450331",
                        label: "荔浦县",
                        simple_name: "荔浦",
                        hidden: 0
                    },
                    {
                        value: "450332",
                        label: "恭城瑶族自治县",
                        simple_name: "恭城",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450400",
                label: "梧州市",
                simple_name: "梧州",
                hidden: 0,
                children: [
                    {
                        value: "450403",
                        label: "万秀区",
                        simple_name: "万秀",
                        hidden: 0
                    },
                    {
                        value: "450405",
                        label: "长洲区",
                        simple_name: "长洲",
                        hidden: 0
                    },
                    {
                        value: "450406",
                        label: "龙圩区",
                        simple_name: "龙圩",
                        hidden: 0
                    },
                    {
                        value: "450421",
                        label: "苍梧县",
                        simple_name: "苍梧",
                        hidden: 0
                    },
                    {
                        value: "450422",
                        label: "藤县",
                        simple_name: "藤县",
                        hidden: 0
                    },
                    {
                        value: "450423",
                        label: "蒙山县",
                        simple_name: "蒙山",
                        hidden: 0
                    },
                    {
                        value: "450481",
                        label: "岑溪市",
                        simple_name: "岑溪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450500",
                label: "北海市",
                simple_name: "北海",
                hidden: 0,
                children: [
                    {
                        value: "450502",
                        label: "海城区",
                        simple_name: "海城",
                        hidden: 0
                    },
                    {
                        value: "450503",
                        label: "银海区",
                        simple_name: "银海",
                        hidden: 0
                    },
                    {
                        value: "450512",
                        label: "铁山港区",
                        simple_name: "铁山港",
                        hidden: 0
                    },
                    {
                        value: "450521",
                        label: "合浦县",
                        simple_name: "合浦",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450600",
                label: "防城港市",
                simple_name: "防城港",
                hidden: 0,
                children: [
                    {
                        value: "450602",
                        label: "港口区",
                        simple_name: "港口",
                        hidden: 0
                    },
                    {
                        value: "450603",
                        label: "防城区",
                        simple_name: "防城",
                        hidden: 0
                    },
                    {
                        value: "450621",
                        label: "上思县",
                        simple_name: "上思",
                        hidden: 0
                    },
                    {
                        value: "450681",
                        label: "东兴市",
                        simple_name: "东兴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450700",
                label: "钦州市",
                simple_name: "钦州",
                hidden: 0,
                children: [
                    {
                        value: "450702",
                        label: "钦南区",
                        simple_name: "钦南",
                        hidden: 0
                    },
                    {
                        value: "450703",
                        label: "钦北区",
                        simple_name: "钦北",
                        hidden: 0
                    },
                    {
                        value: "450721",
                        label: "灵山县",
                        simple_name: "灵山",
                        hidden: 0
                    },
                    {
                        value: "450722",
                        label: "浦北县",
                        simple_name: "浦北",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450800",
                label: "贵港市",
                simple_name: "贵港",
                hidden: 0,
                children: [
                    {
                        value: "450802",
                        label: "港北区",
                        simple_name: "港北",
                        hidden: 0
                    },
                    {
                        value: "450803",
                        label: "港南区",
                        simple_name: "港南",
                        hidden: 0
                    },
                    {
                        value: "450804",
                        label: "覃塘区",
                        simple_name: "覃塘",
                        hidden: 0
                    },
                    {
                        value: "450821",
                        label: "平南县",
                        simple_name: "平南",
                        hidden: 0
                    },
                    {
                        value: "450881",
                        label: "桂平市",
                        simple_name: "桂平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "450900",
                label: "玉林市",
                simple_name: "玉林",
                hidden: 0,
                children: [
                    {
                        value: "450902",
                        label: "玉州区",
                        simple_name: "玉州",
                        hidden: 0
                    },
                    {
                        value: "450903",
                        label: "福绵区",
                        simple_name: "福绵",
                        hidden: 0
                    },
                    {
                        value: "450904",
                        label: "玉东新区",
                        simple_name: "玉东",
                        hidden: 0
                    },
                    {
                        value: "450921",
                        label: "容县",
                        simple_name: "容县",
                        hidden: 0
                    },
                    {
                        value: "450922",
                        label: "陆川县",
                        simple_name: "陆川",
                        hidden: 0
                    },
                    {
                        value: "450923",
                        label: "博白县",
                        simple_name: "博白",
                        hidden: 0
                    },
                    {
                        value: "450924",
                        label: "兴业县",
                        simple_name: "兴业",
                        hidden: 0
                    },
                    {
                        value: "450981",
                        label: "北流市",
                        simple_name: "北流",
                        hidden: 0
                    }
                ]
            },
            {
                value: "451000",
                label: "百色市",
                simple_name: "百色",
                hidden: 0,
                children: [
                    {
                        value: "451002",
                        label: "右江区",
                        simple_name: "右江",
                        hidden: 0
                    },
                    {
                        value: "451021",
                        label: "田阳县",
                        simple_name: "田阳",
                        hidden: 0
                    },
                    {
                        value: "451022",
                        label: "田东县",
                        simple_name: "田东",
                        hidden: 0
                    },
                    {
                        value: "451023",
                        label: "平果县",
                        simple_name: "平果",
                        hidden: 0
                    },
                    {
                        value: "451024",
                        label: "德保县",
                        simple_name: "德保",
                        hidden: 0
                    },
                    {
                        value: "451025",
                        label: "靖西县",
                        simple_name: "靖西",
                        hidden: 0
                    },
                    {
                        value: "451026",
                        label: "那坡县",
                        simple_name: "那坡",
                        hidden: 0
                    },
                    {
                        value: "451027",
                        label: "凌云县",
                        simple_name: "凌云",
                        hidden: 0
                    },
                    {
                        value: "451028",
                        label: "乐业县",
                        simple_name: "乐业",
                        hidden: 0
                    },
                    {
                        value: "451029",
                        label: "田林县",
                        simple_name: "田林",
                        hidden: 0
                    },
                    {
                        value: "451030",
                        label: "西林县",
                        simple_name: "西林",
                        hidden: 0
                    },
                    {
                        value: "451031",
                        label: "隆林各族自治县",
                        simple_name: "隆林",
                        hidden: 0
                    }
                ]
            },
            {
                value: "451100",
                label: "贺州市",
                simple_name: "贺州",
                hidden: 0,
                children: [
                    {
                        value: "451102",
                        label: "八步区",
                        simple_name: "八步",
                        hidden: 0
                    },
                    {
                        value: "451121",
                        label: "昭平县",
                        simple_name: "昭平",
                        hidden: 0
                    },
                    {
                        value: "451122",
                        label: "钟山县",
                        simple_name: "钟山",
                        hidden: 0
                    },
                    {
                        value: "451123",
                        label: "富川瑶族自治县",
                        simple_name: "富川",
                        hidden: 0
                    },
                    {
                        value: "451124",
                        label: "平桂管理区",
                        simple_name: "平桂",
                        hidden: 0
                    }
                ]
            },
            {
                value: "451200",
                label: "河池市",
                simple_name: "河池",
                hidden: 0,
                children: [
                    {
                        value: "451202",
                        label: "金城江区",
                        simple_name: "金城江",
                        hidden: 0
                    },
                    {
                        value: "451221",
                        label: "南丹县",
                        simple_name: "南丹",
                        hidden: 0
                    },
                    {
                        value: "451222",
                        label: "天峨县",
                        simple_name: "天峨",
                        hidden: 0
                    },
                    {
                        value: "451223",
                        label: "凤山县",
                        simple_name: "凤山",
                        hidden: 0
                    },
                    {
                        value: "451224",
                        label: "东兰县",
                        simple_name: "东兰",
                        hidden: 0
                    },
                    {
                        value: "451225",
                        label: "罗城仫佬族自治县",
                        simple_name: "罗城",
                        hidden: 0
                    },
                    {
                        value: "451226",
                        label: "环江毛南族自治县",
                        simple_name: "环江",
                        hidden: 0
                    },
                    {
                        value: "451227",
                        label: "巴马瑶族自治县",
                        simple_name: "巴马",
                        hidden: 0
                    },
                    {
                        value: "451228",
                        label: "都安瑶族自治县",
                        simple_name: "都安",
                        hidden: 0
                    },
                    {
                        value: "451229",
                        label: "大化瑶族自治县",
                        simple_name: "大化",
                        hidden: 0
                    },
                    {
                        value: "451281",
                        label: "宜州市",
                        simple_name: "宜州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "451300",
                label: "来宾市",
                simple_name: "来宾",
                hidden: 0,
                children: [
                    {
                        value: "451302",
                        label: "兴宾区",
                        simple_name: "兴宾",
                        hidden: 0
                    },
                    {
                        value: "451321",
                        label: "忻城县",
                        simple_name: "忻城",
                        hidden: 0
                    },
                    {
                        value: "451322",
                        label: "象州县",
                        simple_name: "象州",
                        hidden: 0
                    },
                    {
                        value: "451323",
                        label: "武宣县",
                        simple_name: "武宣",
                        hidden: 0
                    },
                    {
                        value: "451324",
                        label: "金秀瑶族自治县",
                        simple_name: "金秀",
                        hidden: 0
                    },
                    {
                        value: "451381",
                        label: "合山市",
                        simple_name: "合山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "451400",
                label: "崇左市",
                simple_name: "崇左",
                hidden: 0,
                children: [
                    {
                        value: "451402",
                        label: "江州区",
                        simple_name: "江州",
                        hidden: 0
                    },
                    {
                        value: "451421",
                        label: "扶绥县",
                        simple_name: "扶绥",
                        hidden: 0
                    },
                    {
                        value: "451422",
                        label: "宁明县",
                        simple_name: "宁明",
                        hidden: 0
                    },
                    {
                        value: "451423",
                        label: "龙州县",
                        simple_name: "龙州",
                        hidden: 0
                    },
                    {
                        value: "451424",
                        label: "大新县",
                        simple_name: "大新",
                        hidden: 0
                    },
                    {
                        value: "451425",
                        label: "天等县",
                        simple_name: "天等",
                        hidden: 0
                    },
                    {
                        value: "451481",
                        label: "凭祥市",
                        simple_name: "凭祥",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "460000",
        label: "海南省",
        simple_name: "海南",
        hidden: 0,
        children: [
            {
                value: "460100",
                label: "海口市",
                simple_name: "海口",
                hidden: 0,
                children: [
                    {
                        value: "460105",
                        label: "秀英区",
                        simple_name: "秀英",
                        hidden: 0
                    },
                    {
                        value: "460106",
                        label: "龙华区",
                        simple_name: "龙华",
                        hidden: 0
                    },
                    {
                        value: "460107",
                        label: "琼山区",
                        simple_name: "琼山",
                        hidden: 0
                    },
                    {
                        value: "460108",
                        label: "美兰区",
                        simple_name: "美兰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "460200",
                label: "三亚市",
                simple_name: "三亚",
                hidden: 0,
                children: [
                    {
                        value: "460202",
                        label: "海棠区",
                        simple_name: "海棠",
                        hidden: 0
                    },
                    {
                        value: "460203",
                        label: "吉阳区",
                        simple_name: "吉阳",
                        hidden: 0
                    },
                    {
                        value: "460204",
                        label: "天涯区",
                        simple_name: "天涯",
                        hidden: 0
                    },
                    {
                        value: "460205",
                        label: "崖州区",
                        simple_name: "崖州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "460300",
                label: "三沙市",
                simple_name: "三沙",
                hidden: 0,
                children: [
                    {
                        value: "460321",
                        label: "西沙群岛",
                        simple_name: "西沙",
                        hidden: 0
                    },
                    {
                        value: "460322",
                        label: "南沙群岛",
                        simple_name: "南沙",
                        hidden: 0
                    },
                    {
                        value: "460323",
                        label: "中沙群岛",
                        simple_name: "中沙",
                        hidden: 0
                    }
                ]
            },
            {
                value: "469000",
                label: "直辖县级",
                simple_name: " ",
                hidden: 0,
                children: [
                    {
                        value: "469001",
                        label: "五指山市",
                        simple_name: "五指山",
                        hidden: 0
                    },
                    {
                        value: "469002",
                        label: "琼海市",
                        simple_name: "琼海",
                        hidden: 0
                    },
                    {
                        value: "469003",
                        label: "儋州市",
                        simple_name: "儋州",
                        hidden: 0
                    },
                    {
                        value: "469005",
                        label: "文昌市",
                        simple_name: "文昌",
                        hidden: 0
                    },
                    {
                        value: "469006",
                        label: "万宁市",
                        simple_name: "万宁",
                        hidden: 0
                    },
                    {
                        value: "469007",
                        label: "东方市",
                        simple_name: "东方",
                        hidden: 0
                    },
                    {
                        value: "469021",
                        label: "定安县",
                        simple_name: "定安",
                        hidden: 0
                    },
                    {
                        value: "469022",
                        label: "屯昌县",
                        simple_name: "屯昌",
                        hidden: 0
                    },
                    {
                        value: "469023",
                        label: "澄迈县",
                        simple_name: "澄迈",
                        hidden: 0
                    },
                    {
                        value: "469024",
                        label: "临高县",
                        simple_name: "临高",
                        hidden: 0
                    },
                    {
                        value: "469025",
                        label: "白沙黎族自治县",
                        simple_name: "白沙",
                        hidden: 0
                    },
                    {
                        value: "469026",
                        label: "昌江黎族自治县",
                        simple_name: "昌江",
                        hidden: 0
                    },
                    {
                        value: "469027",
                        label: "乐东黎族自治县",
                        simple_name: "乐东",
                        hidden: 0
                    },
                    {
                        value: "469028",
                        label: "陵水黎族自治县",
                        simple_name: "陵水",
                        hidden: 0
                    },
                    {
                        value: "469029",
                        label: "保亭黎族苗族自治县",
                        simple_name: "保亭",
                        hidden: 0
                    },
                    {
                        value: "469030",
                        label: "琼中黎族苗族自治县",
                        simple_name: "琼中",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "500000",
        label: "重庆",
        simple_name: "重庆",
        hidden: 0,
        children: [
            {
                value: "500100",
                label: "重庆市",
                simple_name: "重庆",
                hidden: 0,
                children: [
                    {
                        value: "500101",
                        label: "万州区",
                        simple_name: "万州",
                        hidden: 0
                    },
                    {
                        value: "500102",
                        label: "涪陵区",
                        simple_name: "涪陵",
                        hidden: 0
                    },
                    {
                        value: "500103",
                        label: "渝中区",
                        simple_name: "渝中",
                        hidden: 0
                    },
                    {
                        value: "500104",
                        label: "大渡口区",
                        simple_name: "大渡口",
                        hidden: 0
                    },
                    {
                        value: "500105",
                        label: "江北区",
                        simple_name: "江北",
                        hidden: 0
                    },
                    {
                        value: "500106",
                        label: "沙坪坝区",
                        simple_name: "沙坪坝",
                        hidden: 0
                    },
                    {
                        value: "500107",
                        label: "九龙坡区",
                        simple_name: "九龙坡",
                        hidden: 0
                    },
                    {
                        value: "500108",
                        label: "南岸区",
                        simple_name: "南岸",
                        hidden: 0
                    },
                    {
                        value: "500109",
                        label: "北碚区",
                        simple_name: "北碚",
                        hidden: 0
                    },
                    {
                        value: "500110",
                        label: "綦江区",
                        simple_name: "綦江",
                        hidden: 0
                    },
                    {
                        value: "500111",
                        label: "大足区",
                        simple_name: "大足",
                        hidden: 0
                    },
                    {
                        value: "500112",
                        label: "渝北区",
                        simple_name: "渝北",
                        hidden: 0
                    },
                    {
                        value: "500113",
                        label: "巴南区",
                        simple_name: "巴南",
                        hidden: 0
                    },
                    {
                        value: "500114",
                        label: "黔江区",
                        simple_name: "黔江",
                        hidden: 0
                    },
                    {
                        value: "500115",
                        label: "长寿区",
                        simple_name: "长寿",
                        hidden: 0
                    },
                    {
                        value: "500116",
                        label: "江津区",
                        simple_name: "江津",
                        hidden: 0
                    },
                    {
                        value: "500117",
                        label: "合川区",
                        simple_name: "合川",
                        hidden: 0
                    },
                    {
                        value: "500118",
                        label: "永川区",
                        simple_name: "永川",
                        hidden: 0
                    },
                    {
                        value: "500119",
                        label: "南川区",
                        simple_name: "南川",
                        hidden: 0
                    },
                    {
                        value: "500120",
                        label: "璧山区",
                        simple_name: "璧山",
                        hidden: 0
                    },
                    {
                        value: "500151",
                        label: "铜梁区",
                        simple_name: "铜梁",
                        hidden: 0
                    },
                    {
                        value: "500223",
                        label: "潼南县",
                        simple_name: "潼南",
                        hidden: 0
                    },
                    {
                        value: "500226",
                        label: "荣昌县",
                        simple_name: "荣昌",
                        hidden: 0
                    },
                    {
                        value: "500228",
                        label: "梁平县",
                        simple_name: "梁平",
                        hidden: 0
                    },
                    {
                        value: "500229",
                        label: "城口县",
                        simple_name: "城口",
                        hidden: 0
                    },
                    {
                        value: "500230",
                        label: "丰都县",
                        simple_name: "丰都",
                        hidden: 0
                    },
                    {
                        value: "500231",
                        label: "垫江县",
                        simple_name: "垫江",
                        hidden: 0
                    },
                    {
                        value: "500232",
                        label: "武隆县",
                        simple_name: "武隆",
                        hidden: 0
                    },
                    {
                        value: "500233",
                        label: "忠县",
                        simple_name: "忠县",
                        hidden: 0
                    },
                    {
                        value: "500234",
                        label: "开县",
                        simple_name: "开县",
                        hidden: 0
                    },
                    {
                        value: "500235",
                        label: "云阳县",
                        simple_name: "云阳",
                        hidden: 0
                    },
                    {
                        value: "500236",
                        label: "奉节县",
                        simple_name: "奉节",
                        hidden: 0
                    },
                    {
                        value: "500237",
                        label: "巫山县",
                        simple_name: "巫山",
                        hidden: 0
                    },
                    {
                        value: "500238",
                        label: "巫溪县",
                        simple_name: "巫溪",
                        hidden: 0
                    },
                    {
                        value: "500240",
                        label: "石柱土家族自治县",
                        simple_name: "石柱",
                        hidden: 0
                    },
                    {
                        value: "500241",
                        label: "秀山土家族苗族自治县",
                        simple_name: "秀山",
                        hidden: 0
                    },
                    {
                        value: "500242",
                        label: "酉阳土家族苗族自治县",
                        simple_name: "酉阳",
                        hidden: 0
                    },
                    {
                        value: "500243",
                        label: "彭水苗族土家族自治县",
                        simple_name: "彭水",
                        hidden: 0
                    }
                ]
            },
            {
                value: "500300",
                label: "两江新区",
                simple_name: "两江新区",
                hidden: 0,
                children: [
                    {
                        value: "500301",
                        label: "北部新区",
                        simple_name: "北部新区",
                        hidden: 0
                    },
                    {
                        value: "500302",
                        label: "保税港区",
                        simple_name: "保税港区",
                        hidden: 0
                    },
                    {
                        value: "500303",
                        label: "工业园区",
                        simple_name: "工业园区",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "510000",
        label: "四川省",
        simple_name: "四川",
        hidden: 0,
        children: [
            {
                value: "510100",
                label: "成都市",
                simple_name: "成都",
                hidden: 0,
                children: [
                    {
                        value: "510104",
                        label: "锦江区",
                        simple_name: "锦江",
                        hidden: 0
                    },
                    {
                        value: "510105",
                        label: "青羊区",
                        simple_name: "青羊",
                        hidden: 0
                    },
                    {
                        value: "510106",
                        label: "金牛区",
                        simple_name: "金牛",
                        hidden: 0
                    },
                    {
                        value: "510107",
                        label: "武侯区",
                        simple_name: "武侯",
                        hidden: 0
                    },
                    {
                        value: "510108",
                        label: "成华区",
                        simple_name: "成华",
                        hidden: 0
                    },
                    {
                        value: "510112",
                        label: "龙泉驿区",
                        simple_name: "龙泉驿",
                        hidden: 0
                    },
                    {
                        value: "510113",
                        label: "青白江区",
                        simple_name: "青白江",
                        hidden: 0
                    },
                    {
                        value: "510114",
                        label: "新都区",
                        simple_name: "新都",
                        hidden: 0
                    },
                    {
                        value: "510115",
                        label: "温江区",
                        simple_name: "温江",
                        hidden: 0
                    },
                    {
                        value: "510121",
                        label: "金堂县",
                        simple_name: "金堂",
                        hidden: 0
                    },
                    {
                        value: "510122",
                        label: "双流县",
                        simple_name: "双流",
                        hidden: 0
                    },
                    {
                        value: "510124",
                        label: "郫县",
                        simple_name: "郫县",
                        hidden: 0
                    },
                    {
                        value: "510129",
                        label: "大邑县",
                        simple_name: "大邑",
                        hidden: 0
                    },
                    {
                        value: "510131",
                        label: "蒲江县",
                        simple_name: "蒲江",
                        hidden: 0
                    },
                    {
                        value: "510132",
                        label: "新津县",
                        simple_name: "新津",
                        hidden: 0
                    },
                    {
                        value: "510181",
                        label: "都江堰市",
                        simple_name: "都江堰",
                        hidden: 0
                    },
                    {
                        value: "510182",
                        label: "彭州市",
                        simple_name: "彭州",
                        hidden: 0
                    },
                    {
                        value: "510183",
                        label: "邛崃市",
                        simple_name: "邛崃",
                        hidden: 0
                    },
                    {
                        value: "510184",
                        label: "崇州市",
                        simple_name: "崇州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510300",
                label: "自贡市",
                simple_name: "自贡",
                hidden: 0,
                children: [
                    {
                        value: "510302",
                        label: "自流井区",
                        simple_name: "自流井",
                        hidden: 0
                    },
                    {
                        value: "510303",
                        label: "贡井区",
                        simple_name: "贡井",
                        hidden: 0
                    },
                    {
                        value: "510304",
                        label: "大安区",
                        simple_name: "大安",
                        hidden: 0
                    },
                    {
                        value: "510311",
                        label: "沿滩区",
                        simple_name: "沿滩",
                        hidden: 0
                    },
                    {
                        value: "510321",
                        label: "荣县",
                        simple_name: "荣县",
                        hidden: 0
                    },
                    {
                        value: "510322",
                        label: "富顺县",
                        simple_name: "富顺",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510400",
                label: "攀枝花市",
                simple_name: "攀枝花",
                hidden: 0,
                children: [
                    {
                        value: "510402",
                        label: "东区",
                        simple_name: "东区",
                        hidden: 0
                    },
                    {
                        value: "510403",
                        label: "西区",
                        simple_name: "西区",
                        hidden: 0
                    },
                    {
                        value: "510411",
                        label: "仁和区",
                        simple_name: "仁和",
                        hidden: 0
                    },
                    {
                        value: "510421",
                        label: "米易县",
                        simple_name: "米易",
                        hidden: 0
                    },
                    {
                        value: "510422",
                        label: "盐边县",
                        simple_name: "盐边",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510500",
                label: "泸州市",
                simple_name: "泸州",
                hidden: 0,
                children: [
                    {
                        value: "510502",
                        label: "江阳区",
                        simple_name: "江阳",
                        hidden: 0
                    },
                    {
                        value: "510503",
                        label: "纳溪区",
                        simple_name: "纳溪",
                        hidden: 0
                    },
                    {
                        value: "510504",
                        label: "龙马潭区",
                        simple_name: "龙马潭",
                        hidden: 0
                    },
                    {
                        value: "510521",
                        label: "泸县",
                        simple_name: "泸县",
                        hidden: 0
                    },
                    {
                        value: "510522",
                        label: "合江县",
                        simple_name: "合江",
                        hidden: 0
                    },
                    {
                        value: "510524",
                        label: "叙永县",
                        simple_name: "叙永",
                        hidden: 0
                    },
                    {
                        value: "510525",
                        label: "古蔺县",
                        simple_name: "古蔺",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510600",
                label: "德阳市",
                simple_name: "德阳",
                hidden: 0,
                children: [
                    {
                        value: "510603",
                        label: "旌阳区",
                        simple_name: "旌阳",
                        hidden: 0
                    },
                    {
                        value: "510623",
                        label: "中江县",
                        simple_name: "中江",
                        hidden: 0
                    },
                    {
                        value: "510626",
                        label: "罗江县",
                        simple_name: "罗江",
                        hidden: 0
                    },
                    {
                        value: "510681",
                        label: "广汉市",
                        simple_name: "广汉",
                        hidden: 0
                    },
                    {
                        value: "510682",
                        label: "什邡市",
                        simple_name: "什邡",
                        hidden: 0
                    },
                    {
                        value: "510683",
                        label: "绵竹市",
                        simple_name: "绵竹",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510700",
                label: "绵阳市",
                simple_name: "绵阳",
                hidden: 0,
                children: [
                    {
                        value: "510703",
                        label: "涪城区",
                        simple_name: "涪城",
                        hidden: 0
                    },
                    {
                        value: "510704",
                        label: "游仙区",
                        simple_name: "游仙",
                        hidden: 0
                    },
                    {
                        value: "510722",
                        label: "三台县",
                        simple_name: "三台",
                        hidden: 0
                    },
                    {
                        value: "510723",
                        label: "盐亭县",
                        simple_name: "盐亭",
                        hidden: 0
                    },
                    {
                        value: "510724",
                        label: "安县",
                        simple_name: "安县",
                        hidden: 0
                    },
                    {
                        value: "510725",
                        label: "梓潼县",
                        simple_name: "梓潼",
                        hidden: 0
                    },
                    {
                        value: "510726",
                        label: "北川羌族自治县",
                        simple_name: "北川",
                        hidden: 0
                    },
                    {
                        value: "510727",
                        label: "平武县",
                        simple_name: "平武",
                        hidden: 0
                    },
                    {
                        value: "510781",
                        label: "江油市",
                        simple_name: "江油",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510800",
                label: "广元市",
                simple_name: "广元",
                hidden: 0,
                children: [
                    {
                        value: "510802",
                        label: "利州区",
                        simple_name: "利州",
                        hidden: 0
                    },
                    {
                        value: "510811",
                        label: "昭化区",
                        simple_name: "昭化",
                        hidden: 0
                    },
                    {
                        value: "510812",
                        label: "朝天区",
                        simple_name: "朝天",
                        hidden: 0
                    },
                    {
                        value: "510821",
                        label: "旺苍县",
                        simple_name: "旺苍",
                        hidden: 0
                    },
                    {
                        value: "510822",
                        label: "青川县",
                        simple_name: "青川",
                        hidden: 0
                    },
                    {
                        value: "510823",
                        label: "剑阁县",
                        simple_name: "剑阁",
                        hidden: 0
                    },
                    {
                        value: "510824",
                        label: "苍溪县",
                        simple_name: "苍溪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "510900",
                label: "遂宁市",
                simple_name: "遂宁",
                hidden: 0,
                children: [
                    {
                        value: "510903",
                        label: "船山区",
                        simple_name: "船山",
                        hidden: 0
                    },
                    {
                        value: "510904",
                        label: "安居区",
                        simple_name: "安居",
                        hidden: 0
                    },
                    {
                        value: "510921",
                        label: "蓬溪县",
                        simple_name: "蓬溪",
                        hidden: 0
                    },
                    {
                        value: "510922",
                        label: "射洪县",
                        simple_name: "射洪",
                        hidden: 0
                    },
                    {
                        value: "510923",
                        label: "大英县",
                        simple_name: "大英",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511000",
                label: "内江市",
                simple_name: "内江",
                hidden: 0,
                children: [
                    {
                        value: "511002",
                        label: "市中区",
                        simple_name: "市中区",
                        hidden: 0
                    },
                    {
                        value: "511011",
                        label: "东兴区",
                        simple_name: "东兴",
                        hidden: 0
                    },
                    {
                        value: "511024",
                        label: "威远县",
                        simple_name: "威远",
                        hidden: 0
                    },
                    {
                        value: "511025",
                        label: "资中县",
                        simple_name: "资中",
                        hidden: 0
                    },
                    {
                        value: "511028",
                        label: "隆昌县",
                        simple_name: "隆昌",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511100",
                label: "乐山市",
                simple_name: "乐山",
                hidden: 0,
                children: [
                    {
                        value: "511102",
                        label: "市中区",
                        simple_name: "市中区",
                        hidden: 0
                    },
                    {
                        value: "511111",
                        label: "沙湾区",
                        simple_name: "沙湾",
                        hidden: 0
                    },
                    {
                        value: "511112",
                        label: "五通桥区",
                        simple_name: "五通桥",
                        hidden: 0
                    },
                    {
                        value: "511113",
                        label: "金口河区",
                        simple_name: "金口河",
                        hidden: 0
                    },
                    {
                        value: "511123",
                        label: "犍为县",
                        simple_name: "犍为",
                        hidden: 0
                    },
                    {
                        value: "511124",
                        label: "井研县",
                        simple_name: "井研",
                        hidden: 0
                    },
                    {
                        value: "511126",
                        label: "夹江县",
                        simple_name: "夹江",
                        hidden: 0
                    },
                    {
                        value: "511129",
                        label: "沐川县",
                        simple_name: "沐川",
                        hidden: 0
                    },
                    {
                        value: "511132",
                        label: "峨边彝族自治县",
                        simple_name: "峨边",
                        hidden: 0
                    },
                    {
                        value: "511133",
                        label: "马边彝族自治县",
                        simple_name: "马边",
                        hidden: 0
                    },
                    {
                        value: "511181",
                        label: "峨眉山市",
                        simple_name: "峨眉山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511300",
                label: "南充市",
                simple_name: "南充",
                hidden: 0,
                children: [
                    {
                        value: "511302",
                        label: "顺庆区",
                        simple_name: "顺庆",
                        hidden: 0
                    },
                    {
                        value: "511303",
                        label: "高坪区",
                        simple_name: "高坪",
                        hidden: 0
                    },
                    {
                        value: "511304",
                        label: "嘉陵区",
                        simple_name: "嘉陵",
                        hidden: 0
                    },
                    {
                        value: "511321",
                        label: "南部县",
                        simple_name: "南部",
                        hidden: 0
                    },
                    {
                        value: "511322",
                        label: "营山县",
                        simple_name: "营山",
                        hidden: 0
                    },
                    {
                        value: "511323",
                        label: "蓬安县",
                        simple_name: "蓬安",
                        hidden: 0
                    },
                    {
                        value: "511324",
                        label: "仪陇县",
                        simple_name: "仪陇",
                        hidden: 0
                    },
                    {
                        value: "511325",
                        label: "西充县",
                        simple_name: "西充",
                        hidden: 0
                    },
                    {
                        value: "511381",
                        label: "阆中市",
                        simple_name: "阆中",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511400",
                label: "眉山市",
                simple_name: "眉山",
                hidden: 0,
                children: [
                    {
                        value: "511402",
                        label: "东坡区",
                        simple_name: "东坡",
                        hidden: 0
                    },
                    {
                        value: "511403",
                        label: "彭山区",
                        simple_name: "彭山",
                        hidden: 0
                    },
                    {
                        value: "511421",
                        label: "仁寿县",
                        simple_name: "仁寿",
                        hidden: 0
                    },
                    {
                        value: "511423",
                        label: "洪雅县",
                        simple_name: "洪雅",
                        hidden: 0
                    },
                    {
                        value: "511424",
                        label: "丹棱县",
                        simple_name: "丹棱",
                        hidden: 0
                    },
                    {
                        value: "511425",
                        label: "青神县",
                        simple_name: "青神",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511500",
                label: "宜宾市",
                simple_name: "宜宾",
                hidden: 0,
                children: [
                    {
                        value: "511502",
                        label: "翠屏区",
                        simple_name: "翠屏",
                        hidden: 0
                    },
                    {
                        value: "511503",
                        label: "南溪区",
                        simple_name: "南溪",
                        hidden: 0
                    },
                    {
                        value: "511521",
                        label: "宜宾县",
                        simple_name: "宜宾",
                        hidden: 0
                    },
                    {
                        value: "511523",
                        label: "江安县",
                        simple_name: "江安",
                        hidden: 0
                    },
                    {
                        value: "511524",
                        label: "长宁县",
                        simple_name: "长宁",
                        hidden: 0
                    },
                    {
                        value: "511525",
                        label: "高县",
                        simple_name: "高县",
                        hidden: 0
                    },
                    {
                        value: "511526",
                        label: "珙县",
                        simple_name: "珙县",
                        hidden: 0
                    },
                    {
                        value: "511527",
                        label: "筠连县",
                        simple_name: "筠连",
                        hidden: 0
                    },
                    {
                        value: "511528",
                        label: "兴文县",
                        simple_name: "兴文",
                        hidden: 0
                    },
                    {
                        value: "511529",
                        label: "屏山县",
                        simple_name: "屏山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511600",
                label: "广安市",
                simple_name: "广安",
                hidden: 0,
                children: [
                    {
                        value: "511602",
                        label: "广安区",
                        simple_name: "广安",
                        hidden: 0
                    },
                    {
                        value: "511603",
                        label: "前锋区",
                        simple_name: "前锋",
                        hidden: 0
                    },
                    {
                        value: "511621",
                        label: "岳池县",
                        simple_name: "岳池",
                        hidden: 0
                    },
                    {
                        value: "511622",
                        label: "武胜县",
                        simple_name: "武胜",
                        hidden: 0
                    },
                    {
                        value: "511623",
                        label: "邻水县",
                        simple_name: "邻水",
                        hidden: 0
                    },
                    {
                        value: "511681",
                        label: "华蓥市",
                        simple_name: "华蓥",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511700",
                label: "达州市",
                simple_name: "达州",
                hidden: 0,
                children: [
                    {
                        value: "511702",
                        label: "通川区",
                        simple_name: "通川",
                        hidden: 0
                    },
                    {
                        value: "511703",
                        label: "达川区",
                        simple_name: "达川",
                        hidden: 0
                    },
                    {
                        value: "511722",
                        label: "宣汉县",
                        simple_name: "宣汉",
                        hidden: 0
                    },
                    {
                        value: "511723",
                        label: "开江县",
                        simple_name: "开江",
                        hidden: 0
                    },
                    {
                        value: "511724",
                        label: "大竹县",
                        simple_name: "大竹",
                        hidden: 0
                    },
                    {
                        value: "511725",
                        label: "渠县",
                        simple_name: "渠县",
                        hidden: 0
                    },
                    {
                        value: "511781",
                        label: "万源市",
                        simple_name: "万源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511800",
                label: "雅安市",
                simple_name: "雅安",
                hidden: 0,
                children: [
                    {
                        value: "511802",
                        label: "雨城区",
                        simple_name: "雨城",
                        hidden: 0
                    },
                    {
                        value: "511803",
                        label: "名山区",
                        simple_name: "名山",
                        hidden: 0
                    },
                    {
                        value: "511822",
                        label: "荥经县",
                        simple_name: "荥经",
                        hidden: 0
                    },
                    {
                        value: "511823",
                        label: "汉源县",
                        simple_name: "汉源",
                        hidden: 0
                    },
                    {
                        value: "511824",
                        label: "石棉县",
                        simple_name: "石棉",
                        hidden: 0
                    },
                    {
                        value: "511825",
                        label: "天全县",
                        simple_name: "天全",
                        hidden: 0
                    },
                    {
                        value: "511826",
                        label: "芦山县",
                        simple_name: "芦山",
                        hidden: 0
                    },
                    {
                        value: "511827",
                        label: "宝兴县",
                        simple_name: "宝兴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "511900",
                label: "巴中市",
                simple_name: "巴中",
                hidden: 0,
                children: [
                    {
                        value: "511902",
                        label: "巴州区",
                        simple_name: "巴州",
                        hidden: 0
                    },
                    {
                        value: "511903",
                        label: "恩阳区",
                        simple_name: "恩阳",
                        hidden: 0
                    },
                    {
                        value: "511921",
                        label: "通江县",
                        simple_name: "通江",
                        hidden: 0
                    },
                    {
                        value: "511922",
                        label: "南江县",
                        simple_name: "南江",
                        hidden: 0
                    },
                    {
                        value: "511923",
                        label: "平昌县",
                        simple_name: "平昌",
                        hidden: 0
                    }
                ]
            },
            {
                value: "512000",
                label: "资阳市",
                simple_name: "资阳",
                hidden: 0,
                children: [
                    {
                        value: "512002",
                        label: "雁江区",
                        simple_name: "雁江",
                        hidden: 0
                    },
                    {
                        value: "512021",
                        label: "安岳县",
                        simple_name: "安岳",
                        hidden: 0
                    },
                    {
                        value: "512022",
                        label: "乐至县",
                        simple_name: "乐至",
                        hidden: 0
                    },
                    {
                        value: "512081",
                        label: "简阳市",
                        simple_name: "简阳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "513200",
                label: "阿坝藏族羌族自治州",
                simple_name: "阿坝",
                hidden: 0,
                children: [
                    {
                        value: "513221",
                        label: "汶川县",
                        simple_name: "汶川",
                        hidden: 0
                    },
                    {
                        value: "513222",
                        label: "理县",
                        simple_name: "理县",
                        hidden: 0
                    },
                    {
                        value: "513223",
                        label: "茂县",
                        simple_name: "茂县",
                        hidden: 0
                    },
                    {
                        value: "513224",
                        label: "松潘县",
                        simple_name: "松潘",
                        hidden: 0
                    },
                    {
                        value: "513225",
                        label: "九寨沟县",
                        simple_name: "九寨沟",
                        hidden: 0
                    },
                    {
                        value: "513226",
                        label: "金川县",
                        simple_name: "金川",
                        hidden: 0
                    },
                    {
                        value: "513227",
                        label: "小金县",
                        simple_name: "小金",
                        hidden: 0
                    },
                    {
                        value: "513228",
                        label: "黑水县",
                        simple_name: "黑水",
                        hidden: 0
                    },
                    {
                        value: "513229",
                        label: "马尔康县",
                        simple_name: "马尔康",
                        hidden: 0
                    },
                    {
                        value: "513230",
                        label: "壤塘县",
                        simple_name: "壤塘",
                        hidden: 0
                    },
                    {
                        value: "513231",
                        label: "阿坝县",
                        simple_name: "阿坝",
                        hidden: 0
                    },
                    {
                        value: "513232",
                        label: "若尔盖县",
                        simple_name: "若尔盖",
                        hidden: 0
                    },
                    {
                        value: "513233",
                        label: "红原县",
                        simple_name: "红原",
                        hidden: 0
                    }
                ]
            },
            {
                value: "513300",
                label: "甘孜藏族自治州",
                simple_name: "甘孜",
                hidden: 0,
                children: [
                    {
                        value: "513321",
                        label: "康定县",
                        simple_name: "康定",
                        hidden: 0
                    },
                    {
                        value: "513322",
                        label: "泸定县",
                        simple_name: "泸定",
                        hidden: 0
                    },
                    {
                        value: "513323",
                        label: "丹巴县",
                        simple_name: "丹巴",
                        hidden: 0
                    },
                    {
                        value: "513324",
                        label: "九龙县",
                        simple_name: "九龙",
                        hidden: 0
                    },
                    {
                        value: "513325",
                        label: "雅江县",
                        simple_name: "雅江",
                        hidden: 0
                    },
                    {
                        value: "513326",
                        label: "道孚县",
                        simple_name: "道孚",
                        hidden: 0
                    },
                    {
                        value: "513327",
                        label: "炉霍县",
                        simple_name: "炉霍",
                        hidden: 0
                    },
                    {
                        value: "513328",
                        label: "甘孜县",
                        simple_name: "甘孜",
                        hidden: 0
                    },
                    {
                        value: "513329",
                        label: "新龙县",
                        simple_name: "新龙",
                        hidden: 0
                    },
                    {
                        value: "513330",
                        label: "德格县",
                        simple_name: "德格",
                        hidden: 0
                    },
                    {
                        value: "513331",
                        label: "白玉县",
                        simple_name: "白玉",
                        hidden: 0
                    },
                    {
                        value: "513332",
                        label: "石渠县",
                        simple_name: "石渠",
                        hidden: 0
                    },
                    {
                        value: "513333",
                        label: "色达县",
                        simple_name: "色达",
                        hidden: 0
                    },
                    {
                        value: "513334",
                        label: "理塘县",
                        simple_name: "理塘",
                        hidden: 0
                    },
                    {
                        value: "513335",
                        label: "巴塘县",
                        simple_name: "巴塘",
                        hidden: 0
                    },
                    {
                        value: "513336",
                        label: "乡城县",
                        simple_name: "乡城",
                        hidden: 0
                    },
                    {
                        value: "513337",
                        label: "稻城县",
                        simple_name: "稻城",
                        hidden: 0
                    },
                    {
                        value: "513338",
                        label: "得荣县",
                        simple_name: "得荣",
                        hidden: 0
                    }
                ]
            },
            {
                value: "513400",
                label: "凉山彝族自治州",
                simple_name: "凉山",
                hidden: 0,
                children: [
                    {
                        value: "513401",
                        label: "西昌市",
                        simple_name: "西昌",
                        hidden: 0
                    },
                    {
                        value: "513422",
                        label: "木里藏族自治县",
                        simple_name: "木里",
                        hidden: 0
                    },
                    {
                        value: "513423",
                        label: "盐源县",
                        simple_name: "盐源",
                        hidden: 0
                    },
                    {
                        value: "513424",
                        label: "德昌县",
                        simple_name: "德昌",
                        hidden: 0
                    },
                    {
                        value: "513425",
                        label: "会理县",
                        simple_name: "会理",
                        hidden: 0
                    },
                    {
                        value: "513426",
                        label: "会东县",
                        simple_name: "会东",
                        hidden: 0
                    },
                    {
                        value: "513427",
                        label: "宁南县",
                        simple_name: "宁南",
                        hidden: 0
                    },
                    {
                        value: "513428",
                        label: "普格县",
                        simple_name: "普格",
                        hidden: 0
                    },
                    {
                        value: "513429",
                        label: "布拖县",
                        simple_name: "布拖",
                        hidden: 0
                    },
                    {
                        value: "513430",
                        label: "金阳县",
                        simple_name: "金阳",
                        hidden: 0
                    },
                    {
                        value: "513431",
                        label: "昭觉县",
                        simple_name: "昭觉",
                        hidden: 0
                    },
                    {
                        value: "513432",
                        label: "喜德县",
                        simple_name: "喜德",
                        hidden: 0
                    },
                    {
                        value: "513433",
                        label: "冕宁县",
                        simple_name: "冕宁",
                        hidden: 0
                    },
                    {
                        value: "513434",
                        label: "越西县",
                        simple_name: "越西",
                        hidden: 0
                    },
                    {
                        value: "513435",
                        label: "甘洛县",
                        simple_name: "甘洛",
                        hidden: 0
                    },
                    {
                        value: "513436",
                        label: "美姑县",
                        simple_name: "美姑",
                        hidden: 0
                    },
                    {
                        value: "513437",
                        label: "雷波县",
                        simple_name: "雷波",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "520000",
        label: "贵州省",
        simple_name: "贵州",
        hidden: 0,
        children: [
            {
                value: "520100",
                label: "贵阳市",
                simple_name: "贵阳",
                hidden: 0,
                children: [
                    {
                        value: "520102",
                        label: "南明区",
                        simple_name: "南明",
                        hidden: 0
                    },
                    {
                        value: "520103",
                        label: "云岩区",
                        simple_name: "云岩",
                        hidden: 0
                    },
                    {
                        value: "520111",
                        label: "花溪区",
                        simple_name: "花溪",
                        hidden: 0
                    },
                    {
                        value: "520112",
                        label: "乌当区",
                        simple_name: "乌当",
                        hidden: 0
                    },
                    {
                        value: "520113",
                        label: "白云区",
                        simple_name: "白云",
                        hidden: 0
                    },
                    {
                        value: "520115",
                        label: "观山湖区",
                        simple_name: "观山湖",
                        hidden: 0
                    },
                    {
                        value: "520121",
                        label: "开阳县",
                        simple_name: "开阳",
                        hidden: 0
                    },
                    {
                        value: "520122",
                        label: "息烽县",
                        simple_name: "息烽",
                        hidden: 0
                    },
                    {
                        value: "520123",
                        label: "修文县",
                        simple_name: "修文",
                        hidden: 0
                    },
                    {
                        value: "520181",
                        label: "清镇市",
                        simple_name: "清镇",
                        hidden: 0
                    }
                ]
            },
            {
                value: "520200",
                label: "六盘水市",
                simple_name: "六盘水",
                hidden: 0,
                children: [
                    {
                        value: "520201",
                        label: "钟山区",
                        simple_name: "钟山",
                        hidden: 0
                    },
                    {
                        value: "520203",
                        label: "六枝特区",
                        simple_name: "六枝",
                        hidden: 0
                    },
                    {
                        value: "520221",
                        label: "水城县",
                        simple_name: "水城",
                        hidden: 0
                    },
                    {
                        value: "520222",
                        label: "盘县",
                        simple_name: "盘县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "520300",
                label: "遵义市",
                simple_name: "遵义",
                hidden: 0,
                children: [
                    {
                        value: "520302",
                        label: "红花岗区",
                        simple_name: "红花岗",
                        hidden: 0
                    },
                    {
                        value: "520303",
                        label: "汇川区",
                        simple_name: "汇川",
                        hidden: 0
                    },
                    {
                        value: "520321",
                        label: "遵义县",
                        simple_name: "遵义",
                        hidden: 0
                    },
                    {
                        value: "520322",
                        label: "桐梓县",
                        simple_name: "桐梓",
                        hidden: 0
                    },
                    {
                        value: "520323",
                        label: "绥阳县",
                        simple_name: "绥阳",
                        hidden: 0
                    },
                    {
                        value: "520324",
                        label: "正安县",
                        simple_name: "正安",
                        hidden: 0
                    },
                    {
                        value: "520325",
                        label: "道真仡佬族苗族自治县",
                        simple_name: "道真",
                        hidden: 0
                    },
                    {
                        value: "520326",
                        label: "务川仡佬族苗族自治县",
                        simple_name: "务川",
                        hidden: 0
                    },
                    {
                        value: "520327",
                        label: "凤冈县",
                        simple_name: "凤冈",
                        hidden: 0
                    },
                    {
                        value: "520328",
                        label: "湄潭县",
                        simple_name: "湄潭",
                        hidden: 0
                    },
                    {
                        value: "520329",
                        label: "余庆县",
                        simple_name: "余庆",
                        hidden: 0
                    },
                    {
                        value: "520330",
                        label: "习水县",
                        simple_name: "习水",
                        hidden: 0
                    },
                    {
                        value: "520381",
                        label: "赤水市",
                        simple_name: "赤水",
                        hidden: 0
                    },
                    {
                        value: "520382",
                        label: "仁怀市",
                        simple_name: "仁怀",
                        hidden: 0
                    }
                ]
            },
            {
                value: "520400",
                label: "安顺市",
                simple_name: "安顺",
                hidden: 0,
                children: [
                    {
                        value: "520402",
                        label: "西秀区",
                        simple_name: "西秀",
                        hidden: 0
                    },
                    {
                        value: "520421",
                        label: "平坝区",
                        simple_name: "平坝",
                        hidden: 0
                    },
                    {
                        value: "520422",
                        label: "普定县",
                        simple_name: "普定",
                        hidden: 0
                    },
                    {
                        value: "520423",
                        label: "镇宁布依族苗族自治县",
                        simple_name: "镇宁",
                        hidden: 0
                    },
                    {
                        value: "520424",
                        label: "关岭布依族苗族自治县",
                        simple_name: "关岭",
                        hidden: 0
                    },
                    {
                        value: "520425",
                        label: "紫云苗族布依族自治县",
                        simple_name: "紫云",
                        hidden: 0
                    }
                ]
            },
            {
                value: "520500",
                label: "毕节市",
                simple_name: "毕节",
                hidden: 0,
                children: [
                    {
                        value: "520502",
                        label: "七星关区",
                        simple_name: "七星关",
                        hidden: 0
                    },
                    {
                        value: "520521",
                        label: "大方县",
                        simple_name: "大方",
                        hidden: 0
                    },
                    {
                        value: "520522",
                        label: "黔西县",
                        simple_name: "黔西",
                        hidden: 0
                    },
                    {
                        value: "520523",
                        label: "金沙县",
                        simple_name: "金沙",
                        hidden: 0
                    },
                    {
                        value: "520524",
                        label: "织金县",
                        simple_name: "织金",
                        hidden: 0
                    },
                    {
                        value: "520525",
                        label: "纳雍县",
                        simple_name: "纳雍",
                        hidden: 0
                    },
                    {
                        value: "520526",
                        label: "威宁彝族回族苗族自治县",
                        simple_name: "威宁",
                        hidden: 0
                    },
                    {
                        value: "520527",
                        label: "赫章县",
                        simple_name: "赫章",
                        hidden: 0
                    }
                ]
            },
            {
                value: "520600",
                label: "铜仁市",
                simple_name: "铜仁",
                hidden: 0,
                children: [
                    {
                        value: "520602",
                        label: "碧江区",
                        simple_name: "碧江",
                        hidden: 0
                    },
                    {
                        value: "520603",
                        label: "万山区",
                        simple_name: "万山",
                        hidden: 0
                    },
                    {
                        value: "520621",
                        label: "江口县",
                        simple_name: "江口",
                        hidden: 0
                    },
                    {
                        value: "520622",
                        label: "玉屏侗族自治县",
                        simple_name: "玉屏",
                        hidden: 0
                    },
                    {
                        value: "520623",
                        label: "石阡县",
                        simple_name: "石阡",
                        hidden: 0
                    },
                    {
                        value: "520624",
                        label: "思南县",
                        simple_name: "思南",
                        hidden: 0
                    },
                    {
                        value: "520625",
                        label: "印江土家族苗族自治县",
                        simple_name: "印江",
                        hidden: 0
                    },
                    {
                        value: "520626",
                        label: "德江县",
                        simple_name: "德江",
                        hidden: 0
                    },
                    {
                        value: "520627",
                        label: "沿河土家族自治县",
                        simple_name: "沿河",
                        hidden: 0
                    },
                    {
                        value: "520628",
                        label: "松桃苗族自治县",
                        simple_name: "松桃",
                        hidden: 0
                    }
                ]
            },
            {
                value: "522300",
                label: "黔西南布依族苗族自治州",
                simple_name: "黔西南",
                hidden: 0,
                children: [
                    {
                        value: "522301",
                        label: "兴义市 ",
                        simple_name: "兴义",
                        hidden: 0
                    },
                    {
                        value: "522322",
                        label: "兴仁县",
                        simple_name: "兴仁",
                        hidden: 0
                    },
                    {
                        value: "522323",
                        label: "普安县",
                        simple_name: "普安",
                        hidden: 0
                    },
                    {
                        value: "522324",
                        label: "晴隆县",
                        simple_name: "晴隆",
                        hidden: 0
                    },
                    {
                        value: "522325",
                        label: "贞丰县",
                        simple_name: "贞丰",
                        hidden: 0
                    },
                    {
                        value: "522326",
                        label: "望谟县",
                        simple_name: "望谟",
                        hidden: 0
                    },
                    {
                        value: "522327",
                        label: "册亨县",
                        simple_name: "册亨",
                        hidden: 0
                    },
                    {
                        value: "522328",
                        label: "安龙县",
                        simple_name: "安龙",
                        hidden: 0
                    }
                ]
            },
            {
                value: "522600",
                label: "黔东南苗族侗族自治州",
                simple_name: "黔东南",
                hidden: 0,
                children: [
                    {
                        value: "522601",
                        label: "凯里市",
                        simple_name: "凯里",
                        hidden: 0
                    },
                    {
                        value: "522622",
                        label: "黄平县",
                        simple_name: "黄平",
                        hidden: 0
                    },
                    {
                        value: "522623",
                        label: "施秉县",
                        simple_name: "施秉",
                        hidden: 0
                    },
                    {
                        value: "522624",
                        label: "三穗县",
                        simple_name: "三穗",
                        hidden: 0
                    },
                    {
                        value: "522625",
                        label: "镇远县",
                        simple_name: "镇远",
                        hidden: 0
                    },
                    {
                        value: "522626",
                        label: "岑巩县",
                        simple_name: "岑巩",
                        hidden: 0
                    },
                    {
                        value: "522627",
                        label: "天柱县",
                        simple_name: "天柱",
                        hidden: 0
                    },
                    {
                        value: "522628",
                        label: "锦屏县",
                        simple_name: "锦屏",
                        hidden: 0
                    },
                    {
                        value: "522629",
                        label: "剑河县",
                        simple_name: "剑河",
                        hidden: 0
                    },
                    {
                        value: "522630",
                        label: "台江县",
                        simple_name: "台江",
                        hidden: 0
                    },
                    {
                        value: "522631",
                        label: "黎平县",
                        simple_name: "黎平",
                        hidden: 0
                    },
                    {
                        value: "522632",
                        label: "榕江县",
                        simple_name: "榕江",
                        hidden: 0
                    },
                    {
                        value: "522633",
                        label: "从江县",
                        simple_name: "从江",
                        hidden: 0
                    },
                    {
                        value: "522634",
                        label: "雷山县",
                        simple_name: "雷山",
                        hidden: 0
                    },
                    {
                        value: "522635",
                        label: "麻江县",
                        simple_name: "麻江",
                        hidden: 0
                    },
                    {
                        value: "522636",
                        label: "丹寨县",
                        simple_name: "丹寨",
                        hidden: 0
                    }
                ]
            },
            {
                value: "522700",
                label: "黔南布依族苗族自治州",
                simple_name: "黔南",
                hidden: 0,
                children: [
                    {
                        value: "522701",
                        label: "都匀市",
                        simple_name: "都匀",
                        hidden: 0
                    },
                    {
                        value: "522702",
                        label: "福泉市",
                        simple_name: "福泉",
                        hidden: 0
                    },
                    {
                        value: "522722",
                        label: "荔波县",
                        simple_name: "荔波",
                        hidden: 0
                    },
                    {
                        value: "522723",
                        label: "贵定县",
                        simple_name: "贵定",
                        hidden: 0
                    },
                    {
                        value: "522725",
                        label: "瓮安县",
                        simple_name: "瓮安",
                        hidden: 0
                    },
                    {
                        value: "522726",
                        label: "独山县",
                        simple_name: "独山",
                        hidden: 0
                    },
                    {
                        value: "522727",
                        label: "平塘县",
                        simple_name: "平塘",
                        hidden: 0
                    },
                    {
                        value: "522728",
                        label: "罗甸县",
                        simple_name: "罗甸",
                        hidden: 0
                    },
                    {
                        value: "522729",
                        label: "长顺县",
                        simple_name: "长顺",
                        hidden: 0
                    },
                    {
                        value: "522730",
                        label: "龙里县",
                        simple_name: "龙里",
                        hidden: 0
                    },
                    {
                        value: "522731",
                        label: "惠水县",
                        simple_name: "惠水",
                        hidden: 0
                    },
                    {
                        value: "522732",
                        label: "三都水族自治县",
                        simple_name: "三都",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "530000",
        label: "云南省",
        simple_name: "云南",
        hidden: 0,
        children: [
            {
                value: "530100",
                label: "昆明市",
                simple_name: "昆明",
                hidden: 0,
                children: [
                    {
                        value: "530102",
                        label: "五华区",
                        simple_name: "五华",
                        hidden: 0
                    },
                    {
                        value: "530103",
                        label: "盘龙区",
                        simple_name: "盘龙",
                        hidden: 0
                    },
                    {
                        value: "530111",
                        label: "官渡区",
                        simple_name: "官渡",
                        hidden: 0
                    },
                    {
                        value: "530112",
                        label: "西山区",
                        simple_name: "西山",
                        hidden: 0
                    },
                    {
                        value: "530113",
                        label: "东川区",
                        simple_name: "东川",
                        hidden: 0
                    },
                    {
                        value: "530114",
                        label: "呈贡区",
                        simple_name: "呈贡",
                        hidden: 0
                    },
                    {
                        value: "530122",
                        label: "晋宁县",
                        simple_name: "晋宁",
                        hidden: 0
                    },
                    {
                        value: "530124",
                        label: "富民县",
                        simple_name: "富民",
                        hidden: 0
                    },
                    {
                        value: "530125",
                        label: "宜良县",
                        simple_name: "宜良",
                        hidden: 0
                    },
                    {
                        value: "530126",
                        label: "石林彝族自治县",
                        simple_name: "石林",
                        hidden: 0
                    },
                    {
                        value: "530127",
                        label: "嵩明县",
                        simple_name: "嵩明",
                        hidden: 0
                    },
                    {
                        value: "530128",
                        label: "禄劝彝族苗族自治县",
                        simple_name: "禄劝",
                        hidden: 0
                    },
                    {
                        value: "530129",
                        label: "寻甸回族彝族自治县 ",
                        simple_name: "寻甸",
                        hidden: 0
                    },
                    {
                        value: "530181",
                        label: "安宁市",
                        simple_name: "安宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530300",
                label: "曲靖市",
                simple_name: "曲靖",
                hidden: 0,
                children: [
                    {
                        value: "530302",
                        label: "麒麟区",
                        simple_name: "麒麟",
                        hidden: 0
                    },
                    {
                        value: "530321",
                        label: "马龙县",
                        simple_name: "马龙",
                        hidden: 0
                    },
                    {
                        value: "530322",
                        label: "陆良县",
                        simple_name: "陆良",
                        hidden: 0
                    },
                    {
                        value: "530323",
                        label: "师宗县",
                        simple_name: "师宗",
                        hidden: 0
                    },
                    {
                        value: "530324",
                        label: "罗平县",
                        simple_name: "罗平",
                        hidden: 0
                    },
                    {
                        value: "530325",
                        label: "富源县",
                        simple_name: "富源",
                        hidden: 0
                    },
                    {
                        value: "530326",
                        label: "会泽县",
                        simple_name: "会泽",
                        hidden: 0
                    },
                    {
                        value: "530328",
                        label: "沾益县",
                        simple_name: "沾益",
                        hidden: 0
                    },
                    {
                        value: "530381",
                        label: "宣威市",
                        simple_name: "宣威",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530400",
                label: "玉溪市",
                simple_name: "玉溪",
                hidden: 0,
                children: [
                    {
                        value: "530402",
                        label: "红塔区",
                        simple_name: "红塔",
                        hidden: 0
                    },
                    {
                        value: "530421",
                        label: "江川县",
                        simple_name: "江川",
                        hidden: 0
                    },
                    {
                        value: "530422",
                        label: "澄江县",
                        simple_name: "澄江",
                        hidden: 0
                    },
                    {
                        value: "530423",
                        label: "通海县",
                        simple_name: "通海",
                        hidden: 0
                    },
                    {
                        value: "530424",
                        label: "华宁县",
                        simple_name: "华宁",
                        hidden: 0
                    },
                    {
                        value: "530425",
                        label: "易门县",
                        simple_name: "易门",
                        hidden: 0
                    },
                    {
                        value: "530426",
                        label: "峨山彝族自治县",
                        simple_name: "峨山",
                        hidden: 0
                    },
                    {
                        value: "530427",
                        label: "新平彝族傣族自治县",
                        simple_name: "新平",
                        hidden: 0
                    },
                    {
                        value: "530428",
                        label: "元江哈尼族彝族傣族自治县",
                        simple_name: "元江",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530500",
                label: "保山市",
                simple_name: "保山",
                hidden: 0,
                children: [
                    {
                        value: "530502",
                        label: "隆阳区",
                        simple_name: "隆阳",
                        hidden: 0
                    },
                    {
                        value: "530521",
                        label: "施甸县",
                        simple_name: "施甸",
                        hidden: 0
                    },
                    {
                        value: "530522",
                        label: "腾冲县",
                        simple_name: "腾冲",
                        hidden: 0
                    },
                    {
                        value: "530523",
                        label: "龙陵县",
                        simple_name: "龙陵",
                        hidden: 0
                    },
                    {
                        value: "530524",
                        label: "昌宁县",
                        simple_name: "昌宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530600",
                label: "昭通市",
                simple_name: "昭通",
                hidden: 0,
                children: [
                    {
                        value: "530602",
                        label: "昭阳区",
                        simple_name: "昭阳",
                        hidden: 0
                    },
                    {
                        value: "530621",
                        label: "鲁甸县",
                        simple_name: "鲁甸",
                        hidden: 0
                    },
                    {
                        value: "530622",
                        label: "巧家县",
                        simple_name: "巧家",
                        hidden: 0
                    },
                    {
                        value: "530623",
                        label: "盐津县",
                        simple_name: "盐津",
                        hidden: 0
                    },
                    {
                        value: "530624",
                        label: "大关县",
                        simple_name: "大关",
                        hidden: 0
                    },
                    {
                        value: "530625",
                        label: "永善县",
                        simple_name: "永善",
                        hidden: 0
                    },
                    {
                        value: "530626",
                        label: "绥江县",
                        simple_name: "绥江",
                        hidden: 0
                    },
                    {
                        value: "530627",
                        label: "镇雄县",
                        simple_name: "镇雄",
                        hidden: 0
                    },
                    {
                        value: "530628",
                        label: "彝良县",
                        simple_name: "彝良",
                        hidden: 0
                    },
                    {
                        value: "530629",
                        label: "威信县",
                        simple_name: "威信",
                        hidden: 0
                    },
                    {
                        value: "530630",
                        label: "水富县",
                        simple_name: "水富",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530700",
                label: "丽江市",
                simple_name: "丽江",
                hidden: 0,
                children: [
                    {
                        value: "530702",
                        label: "古城区",
                        simple_name: "古城",
                        hidden: 0
                    },
                    {
                        value: "530721",
                        label: "玉龙纳西族自治县",
                        simple_name: "玉龙",
                        hidden: 0
                    },
                    {
                        value: "530722",
                        label: "永胜县",
                        simple_name: "永胜",
                        hidden: 0
                    },
                    {
                        value: "530723",
                        label: "华坪县",
                        simple_name: "华坪",
                        hidden: 0
                    },
                    {
                        value: "530724",
                        label: "宁蒗彝族自治县",
                        simple_name: "宁蒗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530800",
                label: "普洱市",
                simple_name: "普洱",
                hidden: 0,
                children: [
                    {
                        value: "530802",
                        label: "思茅区",
                        simple_name: "思茅",
                        hidden: 0
                    },
                    {
                        value: "530821",
                        label: "宁洱哈尼族彝族自治县",
                        simple_name: "宁洱",
                        hidden: 0
                    },
                    {
                        value: "530822",
                        label: "墨江哈尼族自治县",
                        simple_name: "墨江",
                        hidden: 0
                    },
                    {
                        value: "530823",
                        label: "景东彝族自治县",
                        simple_name: "景东",
                        hidden: 0
                    },
                    {
                        value: "530824",
                        label: "景谷傣族彝族自治县",
                        simple_name: "景谷",
                        hidden: 0
                    },
                    {
                        value: "530825",
                        label: "镇沅彝族哈尼族拉祜族自治县",
                        simple_name: "镇沅",
                        hidden: 0
                    },
                    {
                        value: "530826",
                        label: "江城哈尼族彝族自治县",
                        simple_name: "江城",
                        hidden: 0
                    },
                    {
                        value: "530827",
                        label: "孟连傣族拉祜族佤族自治县",
                        simple_name: "孟连",
                        hidden: 0
                    },
                    {
                        value: "530828",
                        label: "澜沧拉祜族自治县",
                        simple_name: "澜沧",
                        hidden: 0
                    },
                    {
                        value: "530829",
                        label: "西盟佤族自治县",
                        simple_name: "西盟",
                        hidden: 0
                    }
                ]
            },
            {
                value: "530900",
                label: "临沧市",
                simple_name: "临沧",
                hidden: 0,
                children: [
                    {
                        value: "530902",
                        label: "临翔区",
                        simple_name: "临翔",
                        hidden: 0
                    },
                    {
                        value: "530921",
                        label: "凤庆县",
                        simple_name: "凤庆",
                        hidden: 0
                    },
                    {
                        value: "530922",
                        label: "云县",
                        simple_name: "云县",
                        hidden: 0
                    },
                    {
                        value: "530923",
                        label: "永德县",
                        simple_name: "永德",
                        hidden: 0
                    },
                    {
                        value: "530924",
                        label: "镇康县",
                        simple_name: "镇康",
                        hidden: 0
                    },
                    {
                        value: "530925",
                        label: "双江拉祜族佤族布朗族傣族自治县",
                        simple_name: "双江",
                        hidden: 0
                    },
                    {
                        value: "530926",
                        label: "耿马傣族佤族自治县",
                        simple_name: "耿马",
                        hidden: 0
                    },
                    {
                        value: "530927",
                        label: "沧源佤族自治县",
                        simple_name: "沧源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "532300",
                label: "楚雄彝族自治州",
                simple_name: "楚雄",
                hidden: 0,
                children: [
                    {
                        value: "532301",
                        label: "楚雄市",
                        simple_name: "楚雄",
                        hidden: 0
                    },
                    {
                        value: "532322",
                        label: "双柏县",
                        simple_name: "双柏",
                        hidden: 0
                    },
                    {
                        value: "532323",
                        label: "牟定县",
                        simple_name: "牟定",
                        hidden: 0
                    },
                    {
                        value: "532324",
                        label: "南华县",
                        simple_name: "南华",
                        hidden: 0
                    },
                    {
                        value: "532325",
                        label: "姚安县",
                        simple_name: "姚安",
                        hidden: 0
                    },
                    {
                        value: "532326",
                        label: "大姚县",
                        simple_name: "大姚",
                        hidden: 0
                    },
                    {
                        value: "532327",
                        label: "永仁县",
                        simple_name: "永仁",
                        hidden: 0
                    },
                    {
                        value: "532328",
                        label: "元谋县",
                        simple_name: "元谋",
                        hidden: 0
                    },
                    {
                        value: "532329",
                        label: "武定县",
                        simple_name: "武定",
                        hidden: 0
                    },
                    {
                        value: "532331",
                        label: "禄丰县",
                        simple_name: "禄丰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "532500",
                label: "红河哈尼族彝族自治州",
                simple_name: "红河",
                hidden: 0,
                children: [
                    {
                        value: "532501",
                        label: "个旧市",
                        simple_name: "个旧",
                        hidden: 0
                    },
                    {
                        value: "532502",
                        label: "开远市",
                        simple_name: "开远",
                        hidden: 0
                    },
                    {
                        value: "532503",
                        label: "蒙自市",
                        simple_name: "蒙自",
                        hidden: 0
                    },
                    {
                        value: "532504",
                        label: "弥勒市",
                        simple_name: "弥勒",
                        hidden: 0
                    },
                    {
                        value: "532523",
                        label: "屏边苗族自治县",
                        simple_name: "屏边",
                        hidden: 0
                    },
                    {
                        value: "532524",
                        label: "建水县",
                        simple_name: "建水",
                        hidden: 0
                    },
                    {
                        value: "532525",
                        label: "石屏县",
                        simple_name: "石屏",
                        hidden: 0
                    },
                    {
                        value: "532527",
                        label: "泸西县",
                        simple_name: "泸西",
                        hidden: 0
                    },
                    {
                        value: "532528",
                        label: "元阳县",
                        simple_name: "元阳",
                        hidden: 0
                    },
                    {
                        value: "532529",
                        label: "红河县",
                        simple_name: "红河县",
                        hidden: 0
                    },
                    {
                        value: "532530",
                        label: "金平苗族瑶族傣族自治县",
                        simple_name: "金平",
                        hidden: 0
                    },
                    {
                        value: "532531",
                        label: "绿春县",
                        simple_name: "绿春",
                        hidden: 0
                    },
                    {
                        value: "532532",
                        label: "河口瑶族自治县",
                        simple_name: "河口",
                        hidden: 0
                    }
                ]
            },
            {
                value: "532600",
                label: "文山壮族苗族自治州",
                simple_name: "文山",
                hidden: 0,
                children: [
                    {
                        value: "532601",
                        label: "文山市",
                        simple_name: "文山",
                        hidden: 0
                    },
                    {
                        value: "532622",
                        label: "砚山县",
                        simple_name: "砚山",
                        hidden: 0
                    },
                    {
                        value: "532623",
                        label: "西畴县",
                        simple_name: "西畴",
                        hidden: 0
                    },
                    {
                        value: "532624",
                        label: "麻栗坡县",
                        simple_name: "麻栗坡",
                        hidden: 0
                    },
                    {
                        value: "532625",
                        label: "马关县",
                        simple_name: "马关",
                        hidden: 0
                    },
                    {
                        value: "532626",
                        label: "丘北县",
                        simple_name: "丘北",
                        hidden: 0
                    },
                    {
                        value: "532627",
                        label: "广南县",
                        simple_name: "广南",
                        hidden: 0
                    },
                    {
                        value: "532628",
                        label: "富宁县",
                        simple_name: "富宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "532800",
                label: "西双版纳傣族自治州",
                simple_name: "西双版纳",
                hidden: 0,
                children: [
                    {
                        value: "532801",
                        label: "景洪市",
                        simple_name: "景洪",
                        hidden: 0
                    },
                    {
                        value: "532822",
                        label: "勐海县",
                        simple_name: "勐海",
                        hidden: 0
                    },
                    {
                        value: "532823",
                        label: "勐腊县",
                        simple_name: "勐腊",
                        hidden: 0
                    }
                ]
            },
            {
                value: "532900",
                label: "大理白族自治州",
                simple_name: "大理",
                hidden: 0,
                children: [
                    {
                        value: "532901",
                        label: "大理市",
                        simple_name: "大理",
                        hidden: 0
                    },
                    {
                        value: "532922",
                        label: "漾濞彝族自治县",
                        simple_name: "漾濞",
                        hidden: 0
                    },
                    {
                        value: "532923",
                        label: "祥云县",
                        simple_name: "祥云",
                        hidden: 0
                    },
                    {
                        value: "532924",
                        label: "宾川县",
                        simple_name: "宾川",
                        hidden: 0
                    },
                    {
                        value: "532925",
                        label: "弥渡县",
                        simple_name: "弥渡",
                        hidden: 0
                    },
                    {
                        value: "532926",
                        label: "南涧彝族自治县",
                        simple_name: "南涧",
                        hidden: 0
                    },
                    {
                        value: "532927",
                        label: "巍山彝族回族自治县",
                        simple_name: "巍山",
                        hidden: 0
                    },
                    {
                        value: "532928",
                        label: "永平县",
                        simple_name: "永平",
                        hidden: 0
                    },
                    {
                        value: "532929",
                        label: "云龙县",
                        simple_name: "云龙",
                        hidden: 0
                    },
                    {
                        value: "532930",
                        label: "洱源县",
                        simple_name: "洱源",
                        hidden: 0
                    },
                    {
                        value: "532931",
                        label: "剑川县",
                        simple_name: "剑川",
                        hidden: 0
                    },
                    {
                        value: "532932",
                        label: "鹤庆县",
                        simple_name: "鹤庆",
                        hidden: 0
                    }
                ]
            },
            {
                value: "533100",
                label: "德宏傣族景颇族自治州",
                simple_name: "德宏",
                hidden: 0,
                children: [
                    {
                        value: "533102",
                        label: "瑞丽市",
                        simple_name: "瑞丽",
                        hidden: 0
                    },
                    {
                        value: "533103",
                        label: "芒市",
                        simple_name: "芒市",
                        hidden: 0
                    },
                    {
                        value: "533122",
                        label: "梁河县",
                        simple_name: "梁河",
                        hidden: 0
                    },
                    {
                        value: "533123",
                        label: "盈江县",
                        simple_name: "盈江",
                        hidden: 0
                    },
                    {
                        value: "533124",
                        label: "陇川县",
                        simple_name: "陇川",
                        hidden: 0
                    }
                ]
            },
            {
                value: "533300",
                label: "怒江傈僳族自治州",
                simple_name: "怒江",
                hidden: 0,
                children: [
                    {
                        value: "533321",
                        label: "泸水县",
                        simple_name: "泸水",
                        hidden: 0
                    },
                    {
                        value: "533323",
                        label: "福贡县",
                        simple_name: "福贡",
                        hidden: 0
                    },
                    {
                        value: "533324",
                        label: "贡山独龙族怒族自治县",
                        simple_name: "贡山",
                        hidden: 0
                    },
                    {
                        value: "533325",
                        label: "兰坪白族普米族自治县",
                        simple_name: "兰坪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "533400",
                label: "迪庆藏族自治州",
                simple_name: "迪庆",
                hidden: 0,
                children: [
                    {
                        value: "533421",
                        label: "香格里拉市",
                        simple_name: "香格里拉",
                        hidden: 0
                    },
                    {
                        value: "533422",
                        label: "德钦县",
                        simple_name: "德钦",
                        hidden: 0
                    },
                    {
                        value: "533423",
                        label: "维西傈僳族自治县",
                        simple_name: "维西",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "540000",
        label: "西藏自治区",
        simple_name: "西藏",
        hidden: 0,
        children: [
            {
                value: "540100",
                label: "拉萨市",
                simple_name: "拉萨",
                hidden: 0,
                children: [
                    {
                        value: "540102",
                        label: "城关区",
                        simple_name: "城关",
                        hidden: 0
                    },
                    {
                        value: "540121",
                        label: "林周县",
                        simple_name: "林周",
                        hidden: 0
                    },
                    {
                        value: "540122",
                        label: "当雄县",
                        simple_name: "当雄",
                        hidden: 0
                    },
                    {
                        value: "540123",
                        label: "尼木县",
                        simple_name: "尼木",
                        hidden: 0
                    },
                    {
                        value: "540124",
                        label: "曲水县",
                        simple_name: "曲水",
                        hidden: 0
                    },
                    {
                        value: "540125",
                        label: "堆龙德庆县",
                        simple_name: "堆龙德庆",
                        hidden: 0
                    },
                    {
                        value: "540126",
                        label: "达孜县",
                        simple_name: "达孜",
                        hidden: 0
                    },
                    {
                        value: "540127",
                        label: "墨竹工卡县",
                        simple_name: "墨竹工卡",
                        hidden: 0
                    }
                ]
            },
            {
                value: "540200",
                label: "日喀则市",
                simple_name: "日喀则",
                hidden: 0,
                children: [
                    {
                        value: "540202",
                        label: "桑珠孜区",
                        simple_name: "桑珠孜",
                        hidden: 0
                    },
                    {
                        value: "540221",
                        label: "南木林县",
                        simple_name: "南木林",
                        hidden: 0
                    },
                    {
                        value: "540222",
                        label: "江孜县",
                        simple_name: "江孜",
                        hidden: 0
                    },
                    {
                        value: "540223",
                        label: "定日县",
                        simple_name: "定日",
                        hidden: 0
                    },
                    {
                        value: "540224",
                        label: "萨迦县",
                        simple_name: "萨迦",
                        hidden: 0
                    },
                    {
                        value: "540225",
                        label: "拉孜县",
                        simple_name: "拉孜",
                        hidden: 0
                    },
                    {
                        value: "540226",
                        label: "昂仁县",
                        simple_name: "昂仁",
                        hidden: 0
                    },
                    {
                        value: "540227",
                        label: "谢通门县",
                        simple_name: "谢通门",
                        hidden: 0
                    },
                    {
                        value: "540228",
                        label: "白朗县",
                        simple_name: "白朗",
                        hidden: 0
                    },
                    {
                        value: "540229",
                        label: "仁布县",
                        simple_name: "仁布",
                        hidden: 0
                    },
                    {
                        value: "540230",
                        label: "康马县",
                        simple_name: "康马",
                        hidden: 0
                    },
                    {
                        value: "540231",
                        label: "定结县",
                        simple_name: "定结",
                        hidden: 0
                    },
                    {
                        value: "540232",
                        label: "仲巴县",
                        simple_name: "仲巴",
                        hidden: 0
                    },
                    {
                        value: "540233",
                        label: "亚东县",
                        simple_name: "亚东",
                        hidden: 0
                    },
                    {
                        value: "540234",
                        label: "吉隆县",
                        simple_name: "吉隆",
                        hidden: 0
                    },
                    {
                        value: "540235",
                        label: "聂拉木县",
                        simple_name: "聂拉木",
                        hidden: 0
                    },
                    {
                        value: "540236",
                        label: "萨嘎县",
                        simple_name: "萨嘎",
                        hidden: 0
                    },
                    {
                        value: "540237",
                        label: "岗巴县",
                        simple_name: "岗巴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "540300",
                label: "昌都市",
                simple_name: "昌都",
                hidden: 0,
                children: [
                    {
                        value: "540302",
                        label: "卡若区",
                        simple_name: "昌都",
                        hidden: 0
                    },
                    {
                        value: "540321",
                        label: "江达县",
                        simple_name: "江达",
                        hidden: 0
                    },
                    {
                        value: "540322",
                        label: "贡觉县",
                        simple_name: "贡觉",
                        hidden: 0
                    },
                    {
                        value: "540323",
                        label: "类乌齐县",
                        simple_name: "类乌齐",
                        hidden: 0
                    },
                    {
                        value: "540324",
                        label: "丁青县",
                        simple_name: "丁青",
                        hidden: 0
                    },
                    {
                        value: "540325",
                        label: "察雅县",
                        simple_name: "察雅",
                        hidden: 0
                    },
                    {
                        value: "540326",
                        label: "八宿县",
                        simple_name: "八宿",
                        hidden: 0
                    },
                    {
                        value: "540327",
                        label: "左贡县",
                        simple_name: "左贡",
                        hidden: 0
                    },
                    {
                        value: "540328",
                        label: "芒康县",
                        simple_name: "芒康",
                        hidden: 0
                    },
                    {
                        value: "540329",
                        label: "洛隆县",
                        simple_name: "洛隆",
                        hidden: 0
                    },
                    {
                        value: "540330",
                        label: "边坝县",
                        simple_name: "边坝",
                        hidden: 0
                    }
                ]
            },
            {
                value: "542200",
                label: "山南地区",
                simple_name: "山南",
                hidden: 0,
                children: [
                    {
                        value: "542221",
                        label: "乃东县",
                        simple_name: "乃东",
                        hidden: 0
                    },
                    {
                        value: "542222",
                        label: "扎囊县",
                        simple_name: "扎囊",
                        hidden: 0
                    },
                    {
                        value: "542223",
                        label: "贡嘎县",
                        simple_name: "贡嘎",
                        hidden: 0
                    },
                    {
                        value: "542224",
                        label: "桑日县",
                        simple_name: "桑日",
                        hidden: 0
                    },
                    {
                        value: "542225",
                        label: "琼结县",
                        simple_name: "琼结",
                        hidden: 0
                    },
                    {
                        value: "542226",
                        label: "曲松县",
                        simple_name: "曲松",
                        hidden: 0
                    },
                    {
                        value: "542227",
                        label: "措美县",
                        simple_name: "措美",
                        hidden: 0
                    },
                    {
                        value: "542228",
                        label: "洛扎县",
                        simple_name: "洛扎",
                        hidden: 0
                    },
                    {
                        value: "542229",
                        label: "加查县",
                        simple_name: "加查",
                        hidden: 0
                    },
                    {
                        value: "542231",
                        label: "隆子县",
                        simple_name: "隆子",
                        hidden: 0
                    },
                    {
                        value: "542232",
                        label: "错那县",
                        simple_name: "错那",
                        hidden: 0
                    },
                    {
                        value: "542233",
                        label: "浪卡子县",
                        simple_name: "浪卡子",
                        hidden: 0
                    }
                ]
            },
            {
                value: "542400",
                label: "那曲地区",
                simple_name: "那曲",
                hidden: 0,
                children: [
                    {
                        value: "542421",
                        label: "那曲县",
                        simple_name: "那曲",
                        hidden: 0
                    },
                    {
                        value: "542422",
                        label: "嘉黎县",
                        simple_name: "嘉黎",
                        hidden: 0
                    },
                    {
                        value: "542423",
                        label: "比如县",
                        simple_name: "比如",
                        hidden: 0
                    },
                    {
                        value: "542424",
                        label: "聂荣县",
                        simple_name: "聂荣",
                        hidden: 0
                    },
                    {
                        value: "542425",
                        label: "安多县",
                        simple_name: "安多",
                        hidden: 0
                    },
                    {
                        value: "542426",
                        label: "申扎县",
                        simple_name: "申扎",
                        hidden: 0
                    },
                    {
                        value: "542427",
                        label: "索县",
                        simple_name: "索县",
                        hidden: 0
                    },
                    {
                        value: "542428",
                        label: "班戈县",
                        simple_name: "班戈",
                        hidden: 0
                    },
                    {
                        value: "542429",
                        label: "巴青县",
                        simple_name: "巴青",
                        hidden: 0
                    },
                    {
                        value: "542430",
                        label: "尼玛县",
                        simple_name: "尼玛",
                        hidden: 0
                    },
                    {
                        value: "542431",
                        label: "双湖县",
                        simple_name: "双湖",
                        hidden: 0
                    }
                ]
            },
            {
                value: "542500",
                label: "阿里地区",
                simple_name: "阿里",
                hidden: 0,
                children: [
                    {
                        value: "542521",
                        label: "普兰县",
                        simple_name: "普兰",
                        hidden: 0
                    },
                    {
                        value: "542522",
                        label: "札达县",
                        simple_name: "札达",
                        hidden: 0
                    },
                    {
                        value: "542523",
                        label: "噶尔县",
                        simple_name: "噶尔",
                        hidden: 0
                    },
                    {
                        value: "542524",
                        label: "日土县",
                        simple_name: "日土",
                        hidden: 0
                    },
                    {
                        value: "542525",
                        label: "革吉县",
                        simple_name: "革吉",
                        hidden: 0
                    },
                    {
                        value: "542526",
                        label: "改则县",
                        simple_name: "改则",
                        hidden: 0
                    },
                    {
                        value: "542527",
                        label: "措勤县",
                        simple_name: "措勤",
                        hidden: 0
                    }
                ]
            },
            {
                value: "542600",
                label: "林芝地区",
                simple_name: "林芝",
                hidden: 0,
                children: [
                    {
                        value: "542621",
                        label: "林芝县",
                        simple_name: "林芝",
                        hidden: 0
                    },
                    {
                        value: "542622",
                        label: "工布江达县",
                        simple_name: "工布江达",
                        hidden: 0
                    },
                    {
                        value: "542623",
                        label: "米林县",
                        simple_name: "米林",
                        hidden: 0
                    },
                    {
                        value: "542624",
                        label: "墨脱县",
                        simple_name: "墨脱",
                        hidden: 0
                    },
                    {
                        value: "542625",
                        label: "波密县",
                        simple_name: "波密",
                        hidden: 0
                    },
                    {
                        value: "542626",
                        label: "察隅县",
                        simple_name: "察隅",
                        hidden: 0
                    },
                    {
                        value: "542627",
                        label: "朗县",
                        simple_name: "朗县",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "610000",
        label: "陕西省",
        simple_name: "陕西",
        hidden: 0,
        children: [
            {
                value: "610100",
                label: "西安市",
                simple_name: "西安",
                hidden: 0,
                children: [
                    {
                        value: "610102",
                        label: "新城区",
                        simple_name: "新城",
                        hidden: 0
                    },
                    {
                        value: "610103",
                        label: "碑林区",
                        simple_name: "碑林",
                        hidden: 0
                    },
                    {
                        value: "610104",
                        label: "莲湖区",
                        simple_name: "莲湖",
                        hidden: 0
                    },
                    {
                        value: "610111",
                        label: "灞桥区",
                        simple_name: "灞桥",
                        hidden: 0
                    },
                    {
                        value: "610112",
                        label: "未央区",
                        simple_name: "未央",
                        hidden: 0
                    },
                    {
                        value: "610113",
                        label: "雁塔区",
                        simple_name: "雁塔",
                        hidden: 0
                    },
                    {
                        value: "610114",
                        label: "阎良区",
                        simple_name: "阎良",
                        hidden: 0
                    },
                    {
                        value: "610115",
                        label: "临潼区",
                        simple_name: "临潼",
                        hidden: 0
                    },
                    {
                        value: "610116",
                        label: "长安区",
                        simple_name: "长安",
                        hidden: 0
                    },
                    {
                        value: "610122",
                        label: "蓝田县",
                        simple_name: "蓝田",
                        hidden: 0
                    },
                    {
                        value: "610124",
                        label: "周至县",
                        simple_name: "周至",
                        hidden: 0
                    },
                    {
                        value: "610125",
                        label: "户县",
                        simple_name: "户县",
                        hidden: 0
                    },
                    {
                        value: "610126",
                        label: "高陵区",
                        simple_name: "高陵",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610200",
                label: "铜川市",
                simple_name: "铜川",
                hidden: 0,
                children: [
                    {
                        value: "610202",
                        label: "王益区",
                        simple_name: "王益",
                        hidden: 0
                    },
                    {
                        value: "610203",
                        label: "印台区",
                        simple_name: "印台",
                        hidden: 0
                    },
                    {
                        value: "610204",
                        label: "耀州区",
                        simple_name: "耀州",
                        hidden: 0
                    },
                    {
                        value: "610222",
                        label: "宜君县",
                        simple_name: "宜君",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610300",
                label: "宝鸡市",
                simple_name: "宝鸡",
                hidden: 0,
                children: [
                    {
                        value: "610302",
                        label: "渭滨区",
                        simple_name: "渭滨",
                        hidden: 0
                    },
                    {
                        value: "610303",
                        label: "金台区",
                        simple_name: "金台",
                        hidden: 0
                    },
                    {
                        value: "610304",
                        label: "陈仓区",
                        simple_name: "陈仓",
                        hidden: 0
                    },
                    {
                        value: "610322",
                        label: "凤翔县",
                        simple_name: "凤翔",
                        hidden: 0
                    },
                    {
                        value: "610323",
                        label: "岐山县",
                        simple_name: "岐山",
                        hidden: 0
                    },
                    {
                        value: "610324",
                        label: "扶风县",
                        simple_name: "扶风",
                        hidden: 0
                    },
                    {
                        value: "610326",
                        label: "眉县",
                        simple_name: "眉县",
                        hidden: 0
                    },
                    {
                        value: "610327",
                        label: "陇县",
                        simple_name: "陇县",
                        hidden: 0
                    },
                    {
                        value: "610328",
                        label: "千阳县",
                        simple_name: "千阳",
                        hidden: 0
                    },
                    {
                        value: "610329",
                        label: "麟游县",
                        simple_name: "麟游",
                        hidden: 0
                    },
                    {
                        value: "610330",
                        label: "凤县",
                        simple_name: "凤县",
                        hidden: 0
                    },
                    {
                        value: "610331",
                        label: "太白县",
                        simple_name: "太白",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610400",
                label: "咸阳市",
                simple_name: "咸阳",
                hidden: 0,
                children: [
                    {
                        value: "610402",
                        label: "秦都区",
                        simple_name: "秦都",
                        hidden: 0
                    },
                    {
                        value: "610403",
                        label: "杨陵区",
                        simple_name: "杨陵",
                        hidden: 0
                    },
                    {
                        value: "610404",
                        label: "渭城区",
                        simple_name: "渭城",
                        hidden: 0
                    },
                    {
                        value: "610422",
                        label: "三原县",
                        simple_name: "三原",
                        hidden: 0
                    },
                    {
                        value: "610423",
                        label: "泾阳县",
                        simple_name: "泾阳",
                        hidden: 0
                    },
                    {
                        value: "610424",
                        label: "乾县",
                        simple_name: "乾县",
                        hidden: 0
                    },
                    {
                        value: "610425",
                        label: "礼泉县",
                        simple_name: "礼泉",
                        hidden: 0
                    },
                    {
                        value: "610426",
                        label: "永寿县",
                        simple_name: "永寿",
                        hidden: 0
                    },
                    {
                        value: "610427",
                        label: "彬县",
                        simple_name: "彬县",
                        hidden: 0
                    },
                    {
                        value: "610428",
                        label: "长武县",
                        simple_name: "长武",
                        hidden: 0
                    },
                    {
                        value: "610429",
                        label: "旬邑县",
                        simple_name: "旬邑",
                        hidden: 0
                    },
                    {
                        value: "610430",
                        label: "淳化县",
                        simple_name: "淳化",
                        hidden: 0
                    },
                    {
                        value: "610431",
                        label: "武功县",
                        simple_name: "武功",
                        hidden: 0
                    },
                    {
                        value: "610481",
                        label: "兴平市",
                        simple_name: "兴平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610500",
                label: "渭南市",
                simple_name: "渭南",
                hidden: 0,
                children: [
                    {
                        value: "610502",
                        label: "临渭区",
                        simple_name: "临渭",
                        hidden: 0
                    },
                    {
                        value: "610521",
                        label: "华县",
                        simple_name: "华县",
                        hidden: 0
                    },
                    {
                        value: "610522",
                        label: "潼关县",
                        simple_name: "潼关",
                        hidden: 0
                    },
                    {
                        value: "610523",
                        label: "大荔县",
                        simple_name: "大荔",
                        hidden: 0
                    },
                    {
                        value: "610524",
                        label: "合阳县",
                        simple_name: "合阳",
                        hidden: 0
                    },
                    {
                        value: "610525",
                        label: "澄城县",
                        simple_name: "澄城",
                        hidden: 0
                    },
                    {
                        value: "610526",
                        label: "蒲城县",
                        simple_name: "蒲城",
                        hidden: 0
                    },
                    {
                        value: "610527",
                        label: "白水县",
                        simple_name: "白水",
                        hidden: 0
                    },
                    {
                        value: "610528",
                        label: "富平县",
                        simple_name: "富平",
                        hidden: 0
                    },
                    {
                        value: "610581",
                        label: "韩城市",
                        simple_name: "韩城",
                        hidden: 0
                    },
                    {
                        value: "610582",
                        label: "华阴市",
                        simple_name: "华阴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610600",
                label: "延安市",
                simple_name: "延安",
                hidden: 0,
                children: [
                    {
                        value: "610602",
                        label: "宝塔区",
                        simple_name: "宝塔",
                        hidden: 0
                    },
                    {
                        value: "610621",
                        label: "延长县",
                        simple_name: "延长",
                        hidden: 0
                    },
                    {
                        value: "610622",
                        label: "延川县",
                        simple_name: "延川",
                        hidden: 0
                    },
                    {
                        value: "610623",
                        label: "子长县",
                        simple_name: "子长",
                        hidden: 0
                    },
                    {
                        value: "610624",
                        label: "安塞县",
                        simple_name: "安塞",
                        hidden: 0
                    },
                    {
                        value: "610625",
                        label: "志丹县",
                        simple_name: "志丹",
                        hidden: 0
                    },
                    {
                        value: "610626",
                        label: "吴起县",
                        simple_name: "吴起",
                        hidden: 0
                    },
                    {
                        value: "610627",
                        label: "甘泉县",
                        simple_name: "甘泉",
                        hidden: 0
                    },
                    {
                        value: "610628",
                        label: "富县",
                        simple_name: "富县",
                        hidden: 0
                    },
                    {
                        value: "610629",
                        label: "洛川县",
                        simple_name: "洛川",
                        hidden: 0
                    },
                    {
                        value: "610630",
                        label: "宜川县",
                        simple_name: "宜川",
                        hidden: 0
                    },
                    {
                        value: "610631",
                        label: "黄龙县",
                        simple_name: "黄龙",
                        hidden: 0
                    },
                    {
                        value: "610632",
                        label: "黄陵县",
                        simple_name: "黄陵",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610700",
                label: "汉中市",
                simple_name: "汉中",
                hidden: 0,
                children: [
                    {
                        value: "610702",
                        label: "汉台区",
                        simple_name: "汉台",
                        hidden: 0
                    },
                    {
                        value: "610721",
                        label: "南郑县",
                        simple_name: "南郑",
                        hidden: 0
                    },
                    {
                        value: "610722",
                        label: "城固县",
                        simple_name: "城固",
                        hidden: 0
                    },
                    {
                        value: "610723",
                        label: "洋县",
                        simple_name: "洋县",
                        hidden: 0
                    },
                    {
                        value: "610724",
                        label: "西乡县",
                        simple_name: "西乡",
                        hidden: 0
                    },
                    {
                        value: "610725",
                        label: "勉县",
                        simple_name: "勉县",
                        hidden: 0
                    },
                    {
                        value: "610726",
                        label: "宁强县",
                        simple_name: "宁强",
                        hidden: 0
                    },
                    {
                        value: "610727",
                        label: "略阳县",
                        simple_name: "略阳",
                        hidden: 0
                    },
                    {
                        value: "610728",
                        label: "镇巴县",
                        simple_name: "镇巴",
                        hidden: 0
                    },
                    {
                        value: "610729",
                        label: "留坝县",
                        simple_name: "留坝",
                        hidden: 0
                    },
                    {
                        value: "610730",
                        label: "佛坪县",
                        simple_name: "佛坪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610800",
                label: "榆林市",
                simple_name: "榆林",
                hidden: 0,
                children: [
                    {
                        value: "610802",
                        label: "榆阳区",
                        simple_name: "榆阳",
                        hidden: 0
                    },
                    {
                        value: "610821",
                        label: "神木县",
                        simple_name: "神木",
                        hidden: 0
                    },
                    {
                        value: "610822",
                        label: "府谷县",
                        simple_name: "府谷",
                        hidden: 0
                    },
                    {
                        value: "610823",
                        label: "横山县",
                        simple_name: "横山",
                        hidden: 0
                    },
                    {
                        value: "610824",
                        label: "靖边县",
                        simple_name: "靖边",
                        hidden: 0
                    },
                    {
                        value: "610825",
                        label: "定边县",
                        simple_name: "定边",
                        hidden: 0
                    },
                    {
                        value: "610826",
                        label: "绥德县",
                        simple_name: "绥德",
                        hidden: 0
                    },
                    {
                        value: "610827",
                        label: "米脂县",
                        simple_name: "米脂",
                        hidden: 0
                    },
                    {
                        value: "610828",
                        label: "佳县",
                        simple_name: "佳县",
                        hidden: 0
                    },
                    {
                        value: "610829",
                        label: "吴堡县",
                        simple_name: "吴堡",
                        hidden: 0
                    },
                    {
                        value: "610830",
                        label: "清涧县",
                        simple_name: "清涧",
                        hidden: 0
                    },
                    {
                        value: "610831",
                        label: "子洲县",
                        simple_name: "子洲",
                        hidden: 0
                    }
                ]
            },
            {
                value: "610900",
                label: "安康市",
                simple_name: "安康",
                hidden: 0,
                children: [
                    {
                        value: "610902",
                        label: "汉滨区",
                        simple_name: "汉滨",
                        hidden: 0
                    },
                    {
                        value: "610921",
                        label: "汉阴县",
                        simple_name: "汉阴",
                        hidden: 0
                    },
                    {
                        value: "610922",
                        label: "石泉县",
                        simple_name: "石泉",
                        hidden: 0
                    },
                    {
                        value: "610923",
                        label: "宁陕县",
                        simple_name: "宁陕",
                        hidden: 0
                    },
                    {
                        value: "610924",
                        label: "紫阳县",
                        simple_name: "紫阳",
                        hidden: 0
                    },
                    {
                        value: "610925",
                        label: "岚皋县",
                        simple_name: "岚皋",
                        hidden: 0
                    },
                    {
                        value: "610926",
                        label: "平利县",
                        simple_name: "平利",
                        hidden: 0
                    },
                    {
                        value: "610927",
                        label: "镇坪县",
                        simple_name: "镇坪",
                        hidden: 0
                    },
                    {
                        value: "610928",
                        label: "旬阳县",
                        simple_name: "旬阳",
                        hidden: 0
                    },
                    {
                        value: "610929",
                        label: "白河县",
                        simple_name: "白河",
                        hidden: 0
                    }
                ]
            },
            {
                value: "611000",
                label: "商洛市",
                simple_name: "商洛",
                hidden: 0,
                children: [
                    {
                        value: "611002",
                        label: "商州区",
                        simple_name: "商州",
                        hidden: 0
                    },
                    {
                        value: "611021",
                        label: "洛南县",
                        simple_name: "洛南",
                        hidden: 0
                    },
                    {
                        value: "611022",
                        label: "丹凤县",
                        simple_name: "丹凤",
                        hidden: 0
                    },
                    {
                        value: "611023",
                        label: "商南县",
                        simple_name: "商南",
                        hidden: 0
                    },
                    {
                        value: "611024",
                        label: "山阳县",
                        simple_name: "山阳",
                        hidden: 0
                    },
                    {
                        value: "611025",
                        label: "镇安县",
                        simple_name: "镇安",
                        hidden: 0
                    },
                    {
                        value: "611026",
                        label: "柞水县",
                        simple_name: "柞水",
                        hidden: 0
                    }
                ]
            },
            {
                value: "611100",
                label: "西咸新区",
                simple_name: "西咸",
                hidden: 0,
                children: [
                    {
                        value: "611101",
                        label: "空港新城",
                        simple_name: "空港",
                        hidden: 0
                    },
                    {
                        value: "611102",
                        label: "沣东新城",
                        simple_name: "沣东",
                        hidden: 0
                    },
                    {
                        value: "611103",
                        label: "秦汉新城",
                        simple_name: "秦汉",
                        hidden: 0
                    },
                    {
                        value: "611104",
                        label: "沣西新城",
                        simple_name: "沣西",
                        hidden: 0
                    },
                    {
                        value: "611105",
                        label: "泾河新城",
                        simple_name: "泾河",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "620000",
        label: "甘肃省",
        simple_name: "甘肃",
        hidden: 0,
        children: [
            {
                value: "620100",
                label: "兰州市",
                simple_name: "兰州",
                hidden: 0,
                children: [
                    {
                        value: "620102",
                        label: "城关区",
                        simple_name: "城关",
                        hidden: 0
                    },
                    {
                        value: "620103",
                        label: "七里河区",
                        simple_name: "七里河",
                        hidden: 0
                    },
                    {
                        value: "620104",
                        label: "西固区",
                        simple_name: "西固",
                        hidden: 0
                    },
                    {
                        value: "620105",
                        label: "安宁区",
                        simple_name: "安宁",
                        hidden: 0
                    },
                    {
                        value: "620111",
                        label: "红古区",
                        simple_name: "红古",
                        hidden: 0
                    },
                    {
                        value: "620121",
                        label: "永登县",
                        simple_name: "永登",
                        hidden: 0
                    },
                    {
                        value: "620122",
                        label: "皋兰县",
                        simple_name: "皋兰",
                        hidden: 0
                    },
                    {
                        value: "620123",
                        label: "榆中县",
                        simple_name: "榆中",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620200",
                label: "嘉峪关市",
                simple_name: "嘉峪关",
                hidden: 0,
                children: [
                    {
                        value: "620201",
                        label: "雄关区",
                        simple_name: "雄关",
                        hidden: 0
                    },
                    {
                        value: "620202",
                        label: "长城区",
                        simple_name: "长城",
                        hidden: 0
                    },
                    {
                        value: "620203",
                        label: "镜铁区",
                        simple_name: "镜铁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620300",
                label: "金昌市",
                simple_name: "金昌",
                hidden: 0,
                children: [
                    {
                        value: "620302",
                        label: "金川区",
                        simple_name: "金川",
                        hidden: 0
                    },
                    {
                        value: "620321",
                        label: "永昌县",
                        simple_name: "永昌",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620400",
                label: "白银市",
                simple_name: "白银",
                hidden: 0,
                children: [
                    {
                        value: "620402",
                        label: "白银区",
                        simple_name: "白银",
                        hidden: 0
                    },
                    {
                        value: "620403",
                        label: "平川区",
                        simple_name: "平川",
                        hidden: 0
                    },
                    {
                        value: "620421",
                        label: "靖远县",
                        simple_name: "靖远",
                        hidden: 0
                    },
                    {
                        value: "620422",
                        label: "会宁县",
                        simple_name: "会宁",
                        hidden: 0
                    },
                    {
                        value: "620423",
                        label: "景泰县",
                        simple_name: "景泰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620500",
                label: "天水市",
                simple_name: "天水",
                hidden: 0,
                children: [
                    {
                        value: "620502",
                        label: "秦州区",
                        simple_name: "秦州",
                        hidden: 0
                    },
                    {
                        value: "620503",
                        label: "麦积区",
                        simple_name: "麦积",
                        hidden: 0
                    },
                    {
                        value: "620521",
                        label: "清水县",
                        simple_name: "清水",
                        hidden: 0
                    },
                    {
                        value: "620522",
                        label: "秦安县",
                        simple_name: "秦安",
                        hidden: 0
                    },
                    {
                        value: "620523",
                        label: "甘谷县",
                        simple_name: "甘谷",
                        hidden: 0
                    },
                    {
                        value: "620524",
                        label: "武山县",
                        simple_name: "武山",
                        hidden: 0
                    },
                    {
                        value: "620525",
                        label: "张家川回族自治县",
                        simple_name: "张家川",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620600",
                label: "武威市",
                simple_name: "武威",
                hidden: 0,
                children: [
                    {
                        value: "620602",
                        label: "凉州区",
                        simple_name: "凉州",
                        hidden: 0
                    },
                    {
                        value: "620621",
                        label: "民勤县",
                        simple_name: "民勤",
                        hidden: 0
                    },
                    {
                        value: "620622",
                        label: "古浪县",
                        simple_name: "古浪",
                        hidden: 0
                    },
                    {
                        value: "620623",
                        label: "天祝藏族自治县",
                        simple_name: "天祝",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620700",
                label: "张掖市",
                simple_name: "张掖",
                hidden: 0,
                children: [
                    {
                        value: "620702",
                        label: "甘州区",
                        simple_name: "甘州",
                        hidden: 0
                    },
                    {
                        value: "620721",
                        label: "肃南裕固族自治县",
                        simple_name: "肃南",
                        hidden: 0
                    },
                    {
                        value: "620722",
                        label: "民乐县",
                        simple_name: "民乐",
                        hidden: 0
                    },
                    {
                        value: "620723",
                        label: "临泽县",
                        simple_name: "临泽",
                        hidden: 0
                    },
                    {
                        value: "620724",
                        label: "高台县",
                        simple_name: "高台",
                        hidden: 0
                    },
                    {
                        value: "620725",
                        label: "山丹县",
                        simple_name: "山丹",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620800",
                label: "平凉市",
                simple_name: "平凉",
                hidden: 0,
                children: [
                    {
                        value: "620802",
                        label: "崆峒区",
                        simple_name: "崆峒",
                        hidden: 0
                    },
                    {
                        value: "620821",
                        label: "泾川县",
                        simple_name: "泾川",
                        hidden: 0
                    },
                    {
                        value: "620822",
                        label: "灵台县",
                        simple_name: "灵台",
                        hidden: 0
                    },
                    {
                        value: "620823",
                        label: "崇信县",
                        simple_name: "崇信",
                        hidden: 0
                    },
                    {
                        value: "620824",
                        label: "华亭县",
                        simple_name: "华亭",
                        hidden: 0
                    },
                    {
                        value: "620825",
                        label: "庄浪县",
                        simple_name: "庄浪",
                        hidden: 0
                    },
                    {
                        value: "620826",
                        label: "静宁县",
                        simple_name: "静宁",
                        hidden: 0
                    }
                ]
            },
            {
                value: "620900",
                label: "酒泉市",
                simple_name: "酒泉",
                hidden: 0,
                children: [
                    {
                        value: "620902",
                        label: "肃州区",
                        simple_name: "肃州",
                        hidden: 0
                    },
                    {
                        value: "620921",
                        label: "金塔县",
                        simple_name: "金塔",
                        hidden: 0
                    },
                    {
                        value: "620922",
                        label: "瓜州县",
                        simple_name: "瓜州",
                        hidden: 0
                    },
                    {
                        value: "620923",
                        label: "肃北蒙古族自治县",
                        simple_name: "肃北",
                        hidden: 0
                    },
                    {
                        value: "620924",
                        label: "阿克塞哈萨克族自治县",
                        simple_name: "阿克塞",
                        hidden: 0
                    },
                    {
                        value: "620981",
                        label: "玉门市",
                        simple_name: "玉门",
                        hidden: 0
                    },
                    {
                        value: "620982",
                        label: "敦煌市",
                        simple_name: "敦煌",
                        hidden: 0
                    }
                ]
            },
            {
                value: "621000",
                label: "庆阳市",
                simple_name: "庆阳",
                hidden: 0,
                children: [
                    {
                        value: "621002",
                        label: "西峰区",
                        simple_name: "西峰",
                        hidden: 0
                    },
                    {
                        value: "621021",
                        label: "庆城县",
                        simple_name: "庆城",
                        hidden: 0
                    },
                    {
                        value: "621022",
                        label: "环县",
                        simple_name: "环县",
                        hidden: 0
                    },
                    {
                        value: "621023",
                        label: "华池县",
                        simple_name: "华池",
                        hidden: 0
                    },
                    {
                        value: "621024",
                        label: "合水县",
                        simple_name: "合水",
                        hidden: 0
                    },
                    {
                        value: "621025",
                        label: "正宁县",
                        simple_name: "正宁",
                        hidden: 0
                    },
                    {
                        value: "621026",
                        label: "宁县",
                        simple_name: "宁县",
                        hidden: 0
                    },
                    {
                        value: "621027",
                        label: "镇原县",
                        simple_name: "镇原",
                        hidden: 0
                    }
                ]
            },
            {
                value: "621100",
                label: "定西市",
                simple_name: "定西",
                hidden: 0,
                children: [
                    {
                        value: "621102",
                        label: "安定区",
                        simple_name: "安定",
                        hidden: 0
                    },
                    {
                        value: "621121",
                        label: "通渭县",
                        simple_name: "通渭",
                        hidden: 0
                    },
                    {
                        value: "621122",
                        label: "陇西县",
                        simple_name: "陇西",
                        hidden: 0
                    },
                    {
                        value: "621123",
                        label: "渭源县",
                        simple_name: "渭源",
                        hidden: 0
                    },
                    {
                        value: "621124",
                        label: "临洮县",
                        simple_name: "临洮",
                        hidden: 0
                    },
                    {
                        value: "621125",
                        label: "漳县",
                        simple_name: "漳县",
                        hidden: 0
                    },
                    {
                        value: "621126",
                        label: "岷县",
                        simple_name: "岷县",
                        hidden: 0
                    }
                ]
            },
            {
                value: "621200",
                label: "陇南市",
                simple_name: "陇南",
                hidden: 0,
                children: [
                    {
                        value: "621202",
                        label: "武都区",
                        simple_name: "武都",
                        hidden: 0
                    },
                    {
                        value: "621221",
                        label: "成县",
                        simple_name: "成县",
                        hidden: 0
                    },
                    {
                        value: "621222",
                        label: "文县",
                        simple_name: "文县",
                        hidden: 0
                    },
                    {
                        value: "621223",
                        label: "宕昌县",
                        simple_name: "宕昌",
                        hidden: 0
                    },
                    {
                        value: "621224",
                        label: "康县",
                        simple_name: "康县",
                        hidden: 0
                    },
                    {
                        value: "621225",
                        label: "西和县",
                        simple_name: "西和",
                        hidden: 0
                    },
                    {
                        value: "621226",
                        label: "礼县",
                        simple_name: "礼县",
                        hidden: 0
                    },
                    {
                        value: "621227",
                        label: "徽县",
                        simple_name: "徽县",
                        hidden: 0
                    },
                    {
                        value: "621228",
                        label: "两当县",
                        simple_name: "两当",
                        hidden: 0
                    }
                ]
            },
            {
                value: "622900",
                label: "临夏回族自治州",
                simple_name: "临夏",
                hidden: 0,
                children: [
                    {
                        value: "622901",
                        label: "临夏市",
                        simple_name: "临夏",
                        hidden: 0
                    },
                    {
                        value: "622921",
                        label: "临夏县",
                        simple_name: "临夏",
                        hidden: 0
                    },
                    {
                        value: "622922",
                        label: "康乐县",
                        simple_name: "康乐",
                        hidden: 0
                    },
                    {
                        value: "622923",
                        label: "永靖县",
                        simple_name: "永靖",
                        hidden: 0
                    },
                    {
                        value: "622924",
                        label: "广河县",
                        simple_name: "广河",
                        hidden: 0
                    },
                    {
                        value: "622925",
                        label: "和政县",
                        simple_name: "和政",
                        hidden: 0
                    },
                    {
                        value: "622926",
                        label: "东乡族自治县",
                        simple_name: "东乡族",
                        hidden: 0
                    },
                    {
                        value: "622927",
                        label: "积石山保安族东乡族撒拉族自治县",
                        simple_name: "积石山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "623000",
                label: "甘南藏族自治州",
                simple_name: "甘南",
                hidden: 0,
                children: [
                    {
                        value: "623001",
                        label: "合作市",
                        simple_name: "合作",
                        hidden: 0
                    },
                    {
                        value: "623021",
                        label: "临潭县",
                        simple_name: "临潭",
                        hidden: 0
                    },
                    {
                        value: "623022",
                        label: "卓尼县",
                        simple_name: "卓尼",
                        hidden: 0
                    },
                    {
                        value: "623023",
                        label: "舟曲县",
                        simple_name: "舟曲",
                        hidden: 0
                    },
                    {
                        value: "623024",
                        label: "迭部县",
                        simple_name: "迭部",
                        hidden: 0
                    },
                    {
                        value: "623025",
                        label: "玛曲县",
                        simple_name: "玛曲",
                        hidden: 0
                    },
                    {
                        value: "623026",
                        label: "碌曲县",
                        simple_name: "碌曲",
                        hidden: 0
                    },
                    {
                        value: "623027",
                        label: "夏河县",
                        simple_name: "夏河",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "630000",
        label: "青海省",
        simple_name: "青海",
        hidden: 0,
        children: [
            {
                value: "630100",
                label: "西宁市",
                simple_name: "西宁",
                hidden: 0,
                children: [
                    {
                        value: "630102",
                        label: "城东区",
                        simple_name: "城东",
                        hidden: 0
                    },
                    {
                        value: "630103",
                        label: "城中区",
                        simple_name: "城中",
                        hidden: 0
                    },
                    {
                        value: "630104",
                        label: "城西区",
                        simple_name: "城西",
                        hidden: 0
                    },
                    {
                        value: "630105",
                        label: "城北区",
                        simple_name: "城北",
                        hidden: 0
                    },
                    {
                        value: "630121",
                        label: "大通回族土族自治县",
                        simple_name: "大通",
                        hidden: 0
                    },
                    {
                        value: "630122",
                        label: "湟中县",
                        simple_name: "湟中",
                        hidden: 0
                    },
                    {
                        value: "630123",
                        label: "湟源县",
                        simple_name: "湟源",
                        hidden: 0
                    }
                ]
            },
            {
                value: "630200",
                label: "海东市",
                simple_name: "海东",
                hidden: 0,
                children: [
                    {
                        value: "630202",
                        label: "乐都区",
                        simple_name: "乐都",
                        hidden: 0
                    },
                    {
                        value: "630221",
                        label: "平安县",
                        simple_name: "平安",
                        hidden: 0
                    },
                    {
                        value: "630222",
                        label: "民和回族土族自治县",
                        simple_name: "民和",
                        hidden: 0
                    },
                    {
                        value: "630223",
                        label: "互助土族自治县",
                        simple_name: "互助",
                        hidden: 0
                    },
                    {
                        value: "630224",
                        label: "化隆回族自治县",
                        simple_name: "化隆",
                        hidden: 0
                    },
                    {
                        value: "630225",
                        label: "循化撒拉族自治县",
                        simple_name: "循化",
                        hidden: 0
                    }
                ]
            },
            {
                value: "632200",
                label: "海北藏族自治州",
                simple_name: "海北",
                hidden: 0,
                children: [
                    {
                        value: "632221",
                        label: "门源回族自治县",
                        simple_name: "门源",
                        hidden: 0
                    },
                    {
                        value: "632222",
                        label: "祁连县",
                        simple_name: "祁连",
                        hidden: 0
                    },
                    {
                        value: "632223",
                        label: "海晏县",
                        simple_name: "海晏",
                        hidden: 0
                    },
                    {
                        value: "632224",
                        label: "刚察县",
                        simple_name: "刚察",
                        hidden: 0
                    }
                ]
            },
            {
                value: "632300",
                label: "黄南藏族自治州",
                simple_name: "黄南",
                hidden: 0,
                children: [
                    {
                        value: "632321",
                        label: "同仁县",
                        simple_name: "同仁",
                        hidden: 0
                    },
                    {
                        value: "632322",
                        label: "尖扎县",
                        simple_name: "尖扎",
                        hidden: 0
                    },
                    {
                        value: "632323",
                        label: "泽库县",
                        simple_name: "泽库",
                        hidden: 0
                    },
                    {
                        value: "632324",
                        label: "河南蒙古族自治县",
                        simple_name: "河南",
                        hidden: 0
                    }
                ]
            },
            {
                value: "632500",
                label: "海南藏族自治州",
                simple_name: "海南",
                hidden: 0,
                children: [
                    {
                        value: "632521",
                        label: "共和县",
                        simple_name: "共和",
                        hidden: 0
                    },
                    {
                        value: "632522",
                        label: "同德县",
                        simple_name: "同德",
                        hidden: 0
                    },
                    {
                        value: "632523",
                        label: "贵德县",
                        simple_name: "贵德",
                        hidden: 0
                    },
                    {
                        value: "632524",
                        label: "兴海县",
                        simple_name: "兴海",
                        hidden: 0
                    },
                    {
                        value: "632525",
                        label: "贵南县",
                        simple_name: "贵南",
                        hidden: 0
                    }
                ]
            },
            {
                value: "632600",
                label: "果洛藏族自治州",
                simple_name: "果洛",
                hidden: 0,
                children: [
                    {
                        value: "632621",
                        label: "玛沁县",
                        simple_name: "玛沁",
                        hidden: 0
                    },
                    {
                        value: "632622",
                        label: "班玛县",
                        simple_name: "班玛",
                        hidden: 0
                    },
                    {
                        value: "632623",
                        label: "甘德县",
                        simple_name: "甘德",
                        hidden: 0
                    },
                    {
                        value: "632624",
                        label: "达日县",
                        simple_name: "达日",
                        hidden: 0
                    },
                    {
                        value: "632625",
                        label: "久治县",
                        simple_name: "久治",
                        hidden: 0
                    },
                    {
                        value: "632626",
                        label: "玛多县",
                        simple_name: "玛多",
                        hidden: 0
                    }
                ]
            },
            {
                value: "632700",
                label: "玉树藏族自治州",
                simple_name: "玉树",
                hidden: 0,
                children: [
                    {
                        value: "632701",
                        label: "玉树市",
                        simple_name: "玉树",
                        hidden: 0
                    },
                    {
                        value: "632722",
                        label: "杂多县",
                        simple_name: "杂多",
                        hidden: 0
                    },
                    {
                        value: "632723",
                        label: "称多县",
                        simple_name: "称多",
                        hidden: 0
                    },
                    {
                        value: "632724",
                        label: "治多县",
                        simple_name: "治多",
                        hidden: 0
                    },
                    {
                        value: "632725",
                        label: "囊谦县",
                        simple_name: "囊谦",
                        hidden: 0
                    },
                    {
                        value: "632726",
                        label: "曲麻莱县",
                        simple_name: "曲麻莱",
                        hidden: 0
                    }
                ]
            },
            {
                value: "632800",
                label: "海西蒙古族藏族自治州",
                simple_name: "海西",
                hidden: 0,
                children: [
                    {
                        value: "632801",
                        label: "格尔木市",
                        simple_name: "格尔木",
                        hidden: 0
                    },
                    {
                        value: "632802",
                        label: "德令哈市",
                        simple_name: "德令哈",
                        hidden: 0
                    },
                    {
                        value: "632821",
                        label: "乌兰县",
                        simple_name: "乌兰",
                        hidden: 0
                    },
                    {
                        value: "632822",
                        label: "都兰县",
                        simple_name: "都兰",
                        hidden: 0
                    },
                    {
                        value: "632823",
                        label: "天峻县",
                        simple_name: "天峻",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "640000",
        label: "宁夏回族自治区",
        simple_name: "宁夏",
        hidden: 0,
        children: [
            {
                value: "640100",
                label: "银川市",
                simple_name: "银川",
                hidden: 0,
                children: [
                    {
                        value: "640104",
                        label: "兴庆区",
                        simple_name: "兴庆",
                        hidden: 0
                    },
                    {
                        value: "640105",
                        label: "西夏区",
                        simple_name: "西夏",
                        hidden: 0
                    },
                    {
                        value: "640106",
                        label: "金凤区",
                        simple_name: "金凤",
                        hidden: 0
                    },
                    {
                        value: "640121",
                        label: "永宁县",
                        simple_name: "永宁",
                        hidden: 0
                    },
                    {
                        value: "640122",
                        label: "贺兰县",
                        simple_name: "贺兰",
                        hidden: 0
                    },
                    {
                        value: "640181",
                        label: "灵武市",
                        simple_name: "灵武",
                        hidden: 0
                    }
                ]
            },
            {
                value: "640200",
                label: "石嘴山市",
                simple_name: "石嘴山",
                hidden: 0,
                children: [
                    {
                        value: "640202",
                        label: "大武口区",
                        simple_name: "大武口",
                        hidden: 0
                    },
                    {
                        value: "640205",
                        label: "惠农区",
                        simple_name: "惠农",
                        hidden: 0
                    },
                    {
                        value: "640221",
                        label: "平罗县",
                        simple_name: "平罗",
                        hidden: 0
                    }
                ]
            },
            {
                value: "640300",
                label: "吴忠市",
                simple_name: "吴忠",
                hidden: 0,
                children: [
                    {
                        value: "640302",
                        label: "利通区",
                        simple_name: "利通",
                        hidden: 0
                    },
                    {
                        value: "640303",
                        label: "红寺堡区",
                        simple_name: "红寺堡",
                        hidden: 0
                    },
                    {
                        value: "640323",
                        label: "盐池县",
                        simple_name: "盐池",
                        hidden: 0
                    },
                    {
                        value: "640324",
                        label: "同心县",
                        simple_name: "同心",
                        hidden: 0
                    },
                    {
                        value: "640381",
                        label: "青铜峡市",
                        simple_name: "青铜峡",
                        hidden: 0
                    }
                ]
            },
            {
                value: "640400",
                label: "固原市",
                simple_name: "固原",
                hidden: 0,
                children: [
                    {
                        value: "640402",
                        label: "原州区",
                        simple_name: "原州",
                        hidden: 0
                    },
                    {
                        value: "640422",
                        label: "西吉县",
                        simple_name: "西吉",
                        hidden: 0
                    },
                    {
                        value: "640423",
                        label: "隆德县",
                        simple_name: "隆德",
                        hidden: 0
                    },
                    {
                        value: "640424",
                        label: "泾源县",
                        simple_name: "泾源",
                        hidden: 0
                    },
                    {
                        value: "640425",
                        label: "彭阳县",
                        simple_name: "彭阳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "640500",
                label: "中卫市",
                simple_name: "中卫",
                hidden: 0,
                children: [
                    {
                        value: "640502",
                        label: "沙坡头区",
                        simple_name: "沙坡头",
                        hidden: 0
                    },
                    {
                        value: "640521",
                        label: "中宁县",
                        simple_name: "中宁",
                        hidden: 0
                    },
                    {
                        value: "640522",
                        label: "海原县",
                        simple_name: "海原",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "650000",
        label: "新疆维吾尔自治区",
        simple_name: "新疆",
        hidden: 0,
        children: [
            {
                value: "650100",
                label: "乌鲁木齐市",
                simple_name: "乌鲁木齐",
                hidden: 0,
                children: [
                    {
                        value: "650102",
                        label: "天山区",
                        simple_name: "天山",
                        hidden: 0
                    },
                    {
                        value: "650103",
                        label: "沙依巴克区",
                        simple_name: "沙依巴克",
                        hidden: 0
                    },
                    {
                        value: "650104",
                        label: "新市区",
                        simple_name: "新市",
                        hidden: 0
                    },
                    {
                        value: "650105",
                        label: "水磨沟区",
                        simple_name: "水磨沟",
                        hidden: 0
                    },
                    {
                        value: "650106",
                        label: "头屯河区",
                        simple_name: "头屯河",
                        hidden: 0
                    },
                    {
                        value: "650107",
                        label: "达坂城区",
                        simple_name: "达坂城",
                        hidden: 0
                    },
                    {
                        value: "650109",
                        label: "米东区",
                        simple_name: "米东",
                        hidden: 0
                    },
                    {
                        value: "650121",
                        label: "乌鲁木齐县",
                        simple_name: "乌鲁木齐",
                        hidden: 0
                    }
                ]
            },
            {
                value: "650200",
                label: "克拉玛依市",
                simple_name: "克拉玛依",
                hidden: 0,
                children: [
                    {
                        value: "650202",
                        label: "独山子区",
                        simple_name: "独山子",
                        hidden: 0
                    },
                    {
                        value: "650203",
                        label: "克拉玛依区",
                        simple_name: "克拉玛依",
                        hidden: 0
                    },
                    {
                        value: "650204",
                        label: "白碱滩区",
                        simple_name: "白碱滩",
                        hidden: 0
                    },
                    {
                        value: "650205",
                        label: "乌尔禾区",
                        simple_name: "乌尔禾",
                        hidden: 0
                    }
                ]
            },
            {
                value: "652100",
                label: "吐鲁番地区",
                simple_name: "吐鲁番",
                hidden: 0,
                children: [
                    {
                        value: "652101",
                        label: "吐鲁番市",
                        simple_name: "吐鲁番",
                        hidden: 0
                    },
                    {
                        value: "652122",
                        label: "鄯善县",
                        simple_name: "鄯善",
                        hidden: 0
                    },
                    {
                        value: "652123",
                        label: "托克逊县",
                        simple_name: "托克逊",
                        hidden: 0
                    }
                ]
            },
            {
                value: "652200",
                label: "哈密地区",
                simple_name: "哈密",
                hidden: 0,
                children: [
                    {
                        value: "652201",
                        label: "哈密市",
                        simple_name: "哈密",
                        hidden: 0
                    },
                    {
                        value: "652222",
                        label: "巴里坤哈萨克自治县",
                        simple_name: "巴里坤",
                        hidden: 0
                    },
                    {
                        value: "652223",
                        label: "伊吾县",
                        simple_name: "伊吾",
                        hidden: 0
                    }
                ]
            },
            {
                value: "652300",
                label: "昌吉回族自治州",
                simple_name: "昌吉",
                hidden: 0,
                children: [
                    {
                        value: "652301",
                        label: "昌吉市",
                        simple_name: "昌吉",
                        hidden: 0
                    },
                    {
                        value: "652302",
                        label: "阜康市",
                        simple_name: "阜康",
                        hidden: 0
                    },
                    {
                        value: "652323",
                        label: "呼图壁县",
                        simple_name: "呼图壁",
                        hidden: 0
                    },
                    {
                        value: "652324",
                        label: "玛纳斯县",
                        simple_name: "玛纳斯",
                        hidden: 0
                    },
                    {
                        value: "652325",
                        label: "奇台县",
                        simple_name: "奇台",
                        hidden: 0
                    },
                    {
                        value: "652327",
                        label: "吉木萨尔县",
                        simple_name: "吉木萨尔",
                        hidden: 0
                    },
                    {
                        value: "652328",
                        label: "木垒哈萨克自治县",
                        simple_name: "木垒",
                        hidden: 0
                    }
                ]
            },
            {
                value: "652700",
                label: "博尔塔拉蒙古自治州",
                simple_name: "博尔塔拉",
                hidden: 0,
                children: [
                    {
                        value: "652701",
                        label: "博乐市",
                        simple_name: "博乐",
                        hidden: 0
                    },
                    {
                        value: "652702",
                        label: "阿拉山口市",
                        simple_name: "阿拉山口",
                        hidden: 0
                    },
                    {
                        value: "652722",
                        label: "精河县",
                        simple_name: "精河",
                        hidden: 0
                    },
                    {
                        value: "652723",
                        label: "温泉县",
                        simple_name: "温泉",
                        hidden: 0
                    }
                ]
            },
            {
                value: "652800",
                label: "巴音郭楞蒙古自治州",
                simple_name: "巴音郭楞",
                hidden: 0,
                children: [
                    {
                        value: "652801",
                        label: "库尔勒市",
                        simple_name: "库尔勒",
                        hidden: 0
                    },
                    {
                        value: "652822",
                        label: "轮台县",
                        simple_name: "轮台",
                        hidden: 0
                    },
                    {
                        value: "652823",
                        label: "尉犁县",
                        simple_name: "尉犁",
                        hidden: 0
                    },
                    {
                        value: "652824",
                        label: "若羌县",
                        simple_name: "若羌",
                        hidden: 0
                    },
                    {
                        value: "652825",
                        label: "且末县",
                        simple_name: "且末",
                        hidden: 0
                    },
                    {
                        value: "652826",
                        label: "焉耆回族自治县",
                        simple_name: "焉耆",
                        hidden: 0
                    },
                    {
                        value: "652827",
                        label: "和静县",
                        simple_name: "和静",
                        hidden: 0
                    },
                    {
                        value: "652828",
                        label: "和硕县",
                        simple_name: "和硕",
                        hidden: 0
                    },
                    {
                        value: "652829",
                        label: "博湖县",
                        simple_name: "博湖",
                        hidden: 0
                    }
                ]
            },
            {
                value: "652900",
                label: "阿克苏地区",
                simple_name: "阿克苏",
                hidden: 0,
                children: [
                    {
                        value: "652901",
                        label: "阿克苏市",
                        simple_name: "阿克苏",
                        hidden: 0
                    },
                    {
                        value: "652922",
                        label: "温宿县",
                        simple_name: "温宿",
                        hidden: 0
                    },
                    {
                        value: "652923",
                        label: "库车县",
                        simple_name: "库车",
                        hidden: 0
                    },
                    {
                        value: "652924",
                        label: "沙雅县",
                        simple_name: "沙雅",
                        hidden: 0
                    },
                    {
                        value: "652925",
                        label: "新和县",
                        simple_name: "新和",
                        hidden: 0
                    },
                    {
                        value: "652926",
                        label: "拜城县",
                        simple_name: "拜城",
                        hidden: 0
                    },
                    {
                        value: "652927",
                        label: "乌什县",
                        simple_name: "乌什",
                        hidden: 0
                    },
                    {
                        value: "652928",
                        label: "阿瓦提县",
                        simple_name: "阿瓦提",
                        hidden: 0
                    },
                    {
                        value: "652929",
                        label: "柯坪县",
                        simple_name: "柯坪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "653000",
                label: "克孜勒苏柯尔克孜自治州",
                simple_name: "克孜勒苏",
                hidden: 0,
                children: [
                    {
                        value: "653001",
                        label: "阿图什市",
                        simple_name: "阿图什",
                        hidden: 0
                    },
                    {
                        value: "653022",
                        label: "阿克陶县",
                        simple_name: "阿克陶",
                        hidden: 0
                    },
                    {
                        value: "653023",
                        label: "阿合奇县",
                        simple_name: "阿合奇",
                        hidden: 0
                    },
                    {
                        value: "653024",
                        label: "乌恰县",
                        simple_name: "乌恰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "653100",
                label: "喀什地区",
                simple_name: "喀什",
                hidden: 0,
                children: [
                    {
                        value: "653101",
                        label: "喀什市",
                        simple_name: "喀什",
                        hidden: 0
                    },
                    {
                        value: "653121",
                        label: "疏附县",
                        simple_name: "疏附",
                        hidden: 0
                    },
                    {
                        value: "653122",
                        label: "疏勒县",
                        simple_name: "疏勒",
                        hidden: 0
                    },
                    {
                        value: "653123",
                        label: "英吉沙县",
                        simple_name: "英吉沙",
                        hidden: 0
                    },
                    {
                        value: "653124",
                        label: "泽普县",
                        simple_name: "泽普",
                        hidden: 0
                    },
                    {
                        value: "653125",
                        label: "莎车县",
                        simple_name: "莎车",
                        hidden: 0
                    },
                    {
                        value: "653126",
                        label: "叶城县",
                        simple_name: "叶城",
                        hidden: 0
                    },
                    {
                        value: "653127",
                        label: "麦盖提县",
                        simple_name: "麦盖提",
                        hidden: 0
                    },
                    {
                        value: "653128",
                        label: "岳普湖县",
                        simple_name: "岳普湖",
                        hidden: 0
                    },
                    {
                        value: "653129",
                        label: "伽师县",
                        simple_name: "伽师",
                        hidden: 0
                    },
                    {
                        value: "653130",
                        label: "巴楚县",
                        simple_name: "巴楚",
                        hidden: 0
                    },
                    {
                        value: "653131",
                        label: "塔什库尔干塔吉克自治县",
                        simple_name: "塔什库尔干塔吉克",
                        hidden: 0
                    }
                ]
            },
            {
                value: "653200",
                label: "和田地区",
                simple_name: "和田",
                hidden: 0,
                children: [
                    {
                        value: "653201",
                        label: "和田市",
                        simple_name: "和田市",
                        hidden: 0
                    },
                    {
                        value: "653221",
                        label: "和田县",
                        simple_name: "和田县",
                        hidden: 0
                    },
                    {
                        value: "653222",
                        label: "墨玉县",
                        simple_name: "墨玉",
                        hidden: 0
                    },
                    {
                        value: "653223",
                        label: "皮山县",
                        simple_name: "皮山",
                        hidden: 0
                    },
                    {
                        value: "653224",
                        label: "洛浦县",
                        simple_name: "洛浦",
                        hidden: 0
                    },
                    {
                        value: "653225",
                        label: "策勒县",
                        simple_name: "策勒",
                        hidden: 0
                    },
                    {
                        value: "653226",
                        label: "于田县",
                        simple_name: "于田",
                        hidden: 0
                    },
                    {
                        value: "653227",
                        label: "民丰县",
                        simple_name: "民丰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "654000",
                label: "伊犁哈萨克自治州",
                simple_name: "伊犁",
                hidden: 0,
                children: [
                    {
                        value: "654002",
                        label: "伊宁市",
                        simple_name: "伊宁",
                        hidden: 0
                    },
                    {
                        value: "654003",
                        label: "奎屯市",
                        simple_name: "奎屯",
                        hidden: 0
                    },
                    {
                        value: "654004",
                        label: "霍尔果斯市",
                        simple_name: "霍尔果斯",
                        hidden: 0
                    },
                    {
                        value: "654021",
                        label: "伊宁县",
                        simple_name: "伊宁",
                        hidden: 0
                    },
                    {
                        value: "654022",
                        label: "察布查尔锡伯自治县",
                        simple_name: "察布查尔锡伯",
                        hidden: 0
                    },
                    {
                        value: "654023",
                        label: "霍城县",
                        simple_name: "霍城",
                        hidden: 0
                    },
                    {
                        value: "654024",
                        label: "巩留县",
                        simple_name: "巩留",
                        hidden: 0
                    },
                    {
                        value: "654025",
                        label: "新源县",
                        simple_name: "新源",
                        hidden: 0
                    },
                    {
                        value: "654026",
                        label: "昭苏县",
                        simple_name: "昭苏",
                        hidden: 0
                    },
                    {
                        value: "654027",
                        label: "特克斯县",
                        simple_name: "特克斯",
                        hidden: 0
                    },
                    {
                        value: "654028",
                        label: "尼勒克县",
                        simple_name: "尼勒克",
                        hidden: 0
                    }
                ]
            },
            {
                value: "654200",
                label: "塔城地区",
                simple_name: "塔城",
                hidden: 0,
                children: [
                    {
                        value: "654201",
                        label: "塔城市",
                        simple_name: "塔城",
                        hidden: 0
                    },
                    {
                        value: "654202",
                        label: "乌苏市",
                        simple_name: "乌苏",
                        hidden: 0
                    },
                    {
                        value: "654221",
                        label: "额敏县",
                        simple_name: "额敏",
                        hidden: 0
                    },
                    {
                        value: "654223",
                        label: "沙湾县",
                        simple_name: "沙湾",
                        hidden: 0
                    },
                    {
                        value: "654224",
                        label: "托里县",
                        simple_name: "托里",
                        hidden: 0
                    },
                    {
                        value: "654225",
                        label: "裕民县",
                        simple_name: "裕民",
                        hidden: 0
                    },
                    {
                        value: "654226",
                        label: "和布克赛尔蒙古自治县",
                        simple_name: "和布克赛尔",
                        hidden: 0
                    }
                ]
            },
            {
                value: "654300",
                label: "阿勒泰地区",
                simple_name: "阿勒泰",
                hidden: 0,
                children: [
                    {
                        value: "654301",
                        label: "阿勒泰市",
                        simple_name: "阿勒泰",
                        hidden: 0
                    },
                    {
                        value: "654321",
                        label: "布尔津县",
                        simple_name: "布尔津",
                        hidden: 0
                    },
                    {
                        value: "654322",
                        label: "富蕴县",
                        simple_name: "富蕴",
                        hidden: 0
                    },
                    {
                        value: "654323",
                        label: "福海县",
                        simple_name: "福海",
                        hidden: 0
                    },
                    {
                        value: "654324",
                        label: "哈巴河县",
                        simple_name: "哈巴河",
                        hidden: 0
                    },
                    {
                        value: "654325",
                        label: "青河县",
                        simple_name: "青河",
                        hidden: 0
                    },
                    {
                        value: "654326",
                        label: "吉木乃县",
                        simple_name: "吉木乃",
                        hidden: 0
                    }
                ]
            },
            {
                value: "659000",
                label: "直辖县级",
                simple_name: " ",
                hidden: 0,
                children: [
                    {
                        value: "659001",
                        label: "石河子市",
                        simple_name: "石河子",
                        hidden: 0
                    },
                    {
                        value: "659002",
                        label: "阿拉尔市",
                        simple_name: "阿拉尔",
                        hidden: 0
                    },
                    {
                        value: "659003",
                        label: "图木舒克市",
                        simple_name: "图木舒克",
                        hidden: 0
                    },
                    {
                        value: "659004",
                        label: "五家渠市",
                        simple_name: "五家渠",
                        hidden: 0
                    },
                    {
                        value: "659005",
                        label: "北屯市",
                        simple_name: "北屯",
                        hidden: 0
                    },
                    {
                        value: "659006",
                        label: "铁门关市",
                        simple_name: "铁门关",
                        hidden: 0
                    },
                    {
                        value: "659007",
                        label: "双河市",
                        simple_name: "双河",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "710000",
        label: "台湾",
        simple_name: "台湾",
        hidden: 0,
        children: [
            {
                value: "710100",
                label: "台北市",
                simple_name: "台北",
                hidden: 0,
                children: [
                    {
                        value: "710101",
                        label: "松山区",
                        simple_name: "松山",
                        hidden: 0
                    },
                    {
                        value: "710102",
                        label: "信义区",
                        simple_name: "信义",
                        hidden: 0
                    },
                    {
                        value: "710103",
                        label: "大安区",
                        simple_name: "大安",
                        hidden: 0
                    },
                    {
                        value: "710104",
                        label: "中山区",
                        simple_name: "中山",
                        hidden: 0
                    },
                    {
                        value: "710105",
                        label: "中正区",
                        simple_name: "中正",
                        hidden: 0
                    },
                    {
                        value: "710106",
                        label: "大同区",
                        simple_name: "大同",
                        hidden: 0
                    },
                    {
                        value: "710107",
                        label: "万华区",
                        simple_name: "万华",
                        hidden: 0
                    },
                    {
                        value: "710108",
                        label: "文山区",
                        simple_name: "文山",
                        hidden: 0
                    },
                    {
                        value: "710109",
                        label: "南港区",
                        simple_name: "南港",
                        hidden: 0
                    },
                    {
                        value: "710110",
                        label: "内湖区",
                        simple_name: "内湖",
                        hidden: 0
                    },
                    {
                        value: "710111",
                        label: "士林区",
                        simple_name: "士林",
                        hidden: 0
                    },
                    {
                        value: "710112",
                        label: "北投区",
                        simple_name: "北投",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710200",
                label: "高雄市",
                simple_name: "高雄",
                hidden: 0,
                children: [
                    {
                        value: "710201",
                        label: "盐埕区",
                        simple_name: "盐埕",
                        hidden: 0
                    },
                    {
                        value: "710202",
                        label: "鼓山区",
                        simple_name: "鼓山",
                        hidden: 0
                    },
                    {
                        value: "710203",
                        label: "左营区",
                        simple_name: "左营",
                        hidden: 0
                    },
                    {
                        value: "710204",
                        label: "楠梓区",
                        simple_name: "楠梓",
                        hidden: 0
                    },
                    {
                        value: "710205",
                        label: "三民区",
                        simple_name: "三民",
                        hidden: 0
                    },
                    {
                        value: "710206",
                        label: "新兴区",
                        simple_name: "新兴",
                        hidden: 0
                    },
                    {
                        value: "710207",
                        label: "前金区",
                        simple_name: "前金",
                        hidden: 0
                    },
                    {
                        value: "710208",
                        label: "苓雅区",
                        simple_name: "苓雅",
                        hidden: 0
                    },
                    {
                        value: "710209",
                        label: "前镇区",
                        simple_name: "前镇",
                        hidden: 0
                    },
                    {
                        value: "710210",
                        label: "旗津区",
                        simple_name: "旗津",
                        hidden: 0
                    },
                    {
                        value: "710211",
                        label: "小港区",
                        simple_name: "小港",
                        hidden: 0
                    },
                    {
                        value: "710212",
                        label: "凤山区",
                        simple_name: "凤山",
                        hidden: 0
                    },
                    {
                        value: "710213",
                        label: "林园区",
                        simple_name: "林园",
                        hidden: 0
                    },
                    {
                        value: "710214",
                        label: "大寮区",
                        simple_name: "大寮",
                        hidden: 0
                    },
                    {
                        value: "710215",
                        label: "大树区",
                        simple_name: "大树",
                        hidden: 0
                    },
                    {
                        value: "710216",
                        label: "大社区",
                        simple_name: "大社",
                        hidden: 0
                    },
                    {
                        value: "710217",
                        label: "仁武区",
                        simple_name: "仁武",
                        hidden: 0
                    },
                    {
                        value: "710218",
                        label: "鸟松区",
                        simple_name: "鸟松",
                        hidden: 0
                    },
                    {
                        value: "710219",
                        label: "冈山区",
                        simple_name: "冈山",
                        hidden: 0
                    },
                    {
                        value: "710220",
                        label: "桥头区",
                        simple_name: "桥头",
                        hidden: 0
                    },
                    {
                        value: "710221",
                        label: "燕巢区",
                        simple_name: "燕巢",
                        hidden: 0
                    },
                    {
                        value: "710222",
                        label: "田寮区",
                        simple_name: "田寮",
                        hidden: 0
                    },
                    {
                        value: "710223",
                        label: "阿莲区",
                        simple_name: "阿莲",
                        hidden: 0
                    },
                    {
                        value: "710224",
                        label: "路竹区",
                        simple_name: "路竹",
                        hidden: 0
                    },
                    {
                        value: "710225",
                        label: "湖内区",
                        simple_name: "湖内",
                        hidden: 0
                    },
                    {
                        value: "710226",
                        label: "茄萣区",
                        simple_name: "茄萣",
                        hidden: 0
                    },
                    {
                        value: "710227",
                        label: "永安区",
                        simple_name: "永安",
                        hidden: 0
                    },
                    {
                        value: "710228",
                        label: "弥陀区",
                        simple_name: "弥陀",
                        hidden: 0
                    },
                    {
                        value: "710229",
                        label: "梓官区",
                        simple_name: "梓官",
                        hidden: 0
                    },
                    {
                        value: "710230",
                        label: "旗山区",
                        simple_name: "旗山",
                        hidden: 0
                    },
                    {
                        value: "710231",
                        label: "美浓区",
                        simple_name: "美浓",
                        hidden: 0
                    },
                    {
                        value: "710232",
                        label: "六龟区",
                        simple_name: "六龟",
                        hidden: 0
                    },
                    {
                        value: "710233",
                        label: "甲仙区",
                        simple_name: "甲仙",
                        hidden: 0
                    },
                    {
                        value: "710234",
                        label: "杉林区",
                        simple_name: "杉林",
                        hidden: 0
                    },
                    {
                        value: "710235",
                        label: "内门区",
                        simple_name: "内门",
                        hidden: 0
                    },
                    {
                        value: "710236",
                        label: "茂林区",
                        simple_name: "茂林",
                        hidden: 0
                    },
                    {
                        value: "710237",
                        label: "桃源区",
                        simple_name: "桃源",
                        hidden: 0
                    },
                    {
                        value: "710238",
                        label: "那玛夏区",
                        simple_name: "那玛夏",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710300",
                label: "基隆市",
                simple_name: "基隆",
                hidden: 0,
                children: [
                    {
                        value: "710301",
                        label: "中正区",
                        simple_name: "中正",
                        hidden: 0
                    },
                    {
                        value: "710302",
                        label: "七堵区",
                        simple_name: "七堵",
                        hidden: 0
                    },
                    {
                        value: "710303",
                        label: "暖暖区",
                        simple_name: "暖暖",
                        hidden: 0
                    },
                    {
                        value: "710304",
                        label: "仁爱区",
                        simple_name: "仁爱",
                        hidden: 0
                    },
                    {
                        value: "710305",
                        label: "中山区",
                        simple_name: "中山",
                        hidden: 0
                    },
                    {
                        value: "710306",
                        label: "安乐区",
                        simple_name: "安乐",
                        hidden: 0
                    },
                    {
                        value: "710307",
                        label: "信义区",
                        simple_name: "信义",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710400",
                label: "台中市",
                simple_name: "台中",
                hidden: 0,
                children: [
                    {
                        value: "710401",
                        label: "中区",
                        simple_name: "中区",
                        hidden: 0
                    },
                    {
                        value: "710402",
                        label: "东区",
                        simple_name: "东区",
                        hidden: 0
                    },
                    {
                        value: "710403",
                        label: "南区",
                        simple_name: "南区",
                        hidden: 0
                    },
                    {
                        value: "710404",
                        label: "西区",
                        simple_name: "西区",
                        hidden: 0
                    },
                    {
                        value: "710405",
                        label: "北区",
                        simple_name: "北区",
                        hidden: 0
                    },
                    {
                        value: "710406",
                        label: "西屯区",
                        simple_name: "西屯",
                        hidden: 0
                    },
                    {
                        value: "710407",
                        label: "南屯区",
                        simple_name: "南屯",
                        hidden: 0
                    },
                    {
                        value: "710408",
                        label: "北屯区",
                        simple_name: "北屯",
                        hidden: 0
                    },
                    {
                        value: "710409",
                        label: "丰原区",
                        simple_name: "丰原",
                        hidden: 0
                    },
                    {
                        value: "710410",
                        label: "东势区",
                        simple_name: "东势",
                        hidden: 0
                    },
                    {
                        value: "710411",
                        label: "大甲区",
                        simple_name: "大甲",
                        hidden: 0
                    },
                    {
                        value: "710412",
                        label: "清水区",
                        simple_name: "清水",
                        hidden: 0
                    },
                    {
                        value: "710413",
                        label: "沙鹿区",
                        simple_name: "沙鹿",
                        hidden: 0
                    },
                    {
                        value: "710414",
                        label: "梧栖区",
                        simple_name: "梧栖",
                        hidden: 0
                    },
                    {
                        value: "710415",
                        label: "后里区",
                        simple_name: "后里",
                        hidden: 0
                    },
                    {
                        value: "710416",
                        label: "神冈区",
                        simple_name: "神冈",
                        hidden: 0
                    },
                    {
                        value: "710417",
                        label: "潭子区",
                        simple_name: "潭子",
                        hidden: 0
                    },
                    {
                        value: "710418",
                        label: "大雅区",
                        simple_name: "大雅",
                        hidden: 0
                    },
                    {
                        value: "710419",
                        label: "新社区",
                        simple_name: "新社",
                        hidden: 0
                    },
                    {
                        value: "710420",
                        label: "石冈区",
                        simple_name: "石冈",
                        hidden: 0
                    },
                    {
                        value: "710421",
                        label: "外埔区",
                        simple_name: "外埔",
                        hidden: 0
                    },
                    {
                        value: "710422",
                        label: "大安区",
                        simple_name: "大安",
                        hidden: 0
                    },
                    {
                        value: "710423",
                        label: "乌日区",
                        simple_name: "乌日",
                        hidden: 0
                    },
                    {
                        value: "710424",
                        label: "大肚区",
                        simple_name: "大肚",
                        hidden: 0
                    },
                    {
                        value: "710425",
                        label: "龙井区",
                        simple_name: "龙井",
                        hidden: 0
                    },
                    {
                        value: "710426",
                        label: "雾峰区",
                        simple_name: "雾峰",
                        hidden: 0
                    },
                    {
                        value: "710427",
                        label: "太平区",
                        simple_name: "太平",
                        hidden: 0
                    },
                    {
                        value: "710428",
                        label: "大里区",
                        simple_name: "大里",
                        hidden: 0
                    },
                    {
                        value: "710429",
                        label: "和平区",
                        simple_name: "和平",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710500",
                label: "台南市",
                simple_name: "台南",
                hidden: 0,
                children: [
                    {
                        value: "710501",
                        label: "东区",
                        simple_name: "东区",
                        hidden: 0
                    },
                    {
                        value: "710502",
                        label: "南区",
                        simple_name: "南区",
                        hidden: 0
                    },
                    {
                        value: "710504",
                        label: "北区",
                        simple_name: "北区",
                        hidden: 0
                    },
                    {
                        value: "710506",
                        label: "安南区",
                        simple_name: "安南",
                        hidden: 0
                    },
                    {
                        value: "710507",
                        label: "安平区",
                        simple_name: "安平",
                        hidden: 0
                    },
                    {
                        value: "710508",
                        label: "中西区",
                        simple_name: "中西",
                        hidden: 0
                    },
                    {
                        value: "710509",
                        label: "新营区",
                        simple_name: "新营",
                        hidden: 0
                    },
                    {
                        value: "710510",
                        label: "盐水区",
                        simple_name: "盐水",
                        hidden: 0
                    },
                    {
                        value: "710511",
                        label: "白河区",
                        simple_name: "白河",
                        hidden: 0
                    },
                    {
                        value: "710512",
                        label: "柳营区",
                        simple_name: "柳营",
                        hidden: 0
                    },
                    {
                        value: "710513",
                        label: "后壁区",
                        simple_name: "后壁",
                        hidden: 0
                    },
                    {
                        value: "710514",
                        label: "东山区",
                        simple_name: "东山",
                        hidden: 0
                    },
                    {
                        value: "710515",
                        label: "麻豆区",
                        simple_name: "麻豆",
                        hidden: 0
                    },
                    {
                        value: "710516",
                        label: "下营区",
                        simple_name: "下营",
                        hidden: 0
                    },
                    {
                        value: "710517",
                        label: "六甲区",
                        simple_name: "六甲",
                        hidden: 0
                    },
                    {
                        value: "710518",
                        label: "官田区",
                        simple_name: "官田",
                        hidden: 0
                    },
                    {
                        value: "710519",
                        label: "大内区",
                        simple_name: "大内",
                        hidden: 0
                    },
                    {
                        value: "710520",
                        label: "佳里区",
                        simple_name: "佳里",
                        hidden: 0
                    },
                    {
                        value: "710521",
                        label: "学甲区",
                        simple_name: "学甲",
                        hidden: 0
                    },
                    {
                        value: "710522",
                        label: "西港区",
                        simple_name: "西港",
                        hidden: 0
                    },
                    {
                        value: "710523",
                        label: "七股区",
                        simple_name: "七股",
                        hidden: 0
                    },
                    {
                        value: "710524",
                        label: "将军区",
                        simple_name: "将军",
                        hidden: 0
                    },
                    {
                        value: "710525",
                        label: "北门区",
                        simple_name: "北门",
                        hidden: 0
                    },
                    {
                        value: "710526",
                        label: "新化区",
                        simple_name: "新化",
                        hidden: 0
                    },
                    {
                        value: "710527",
                        label: "善化区",
                        simple_name: "善化",
                        hidden: 0
                    },
                    {
                        value: "710528",
                        label: "新市区",
                        simple_name: "新市",
                        hidden: 0
                    },
                    {
                        value: "710529",
                        label: "安定区",
                        simple_name: "安定",
                        hidden: 0
                    },
                    {
                        value: "710530",
                        label: "山上区",
                        simple_name: "山上",
                        hidden: 0
                    },
                    {
                        value: "710531",
                        label: "玉井区",
                        simple_name: "玉井",
                        hidden: 0
                    },
                    {
                        value: "710532",
                        label: "楠西区",
                        simple_name: "楠西",
                        hidden: 0
                    },
                    {
                        value: "710533",
                        label: "南化区",
                        simple_name: "南化",
                        hidden: 0
                    },
                    {
                        value: "710534",
                        label: "左镇区",
                        simple_name: "左镇",
                        hidden: 0
                    },
                    {
                        value: "710535",
                        label: "仁德区",
                        simple_name: "仁德",
                        hidden: 0
                    },
                    {
                        value: "710536",
                        label: "归仁区",
                        simple_name: "归仁",
                        hidden: 0
                    },
                    {
                        value: "710537",
                        label: "关庙区",
                        simple_name: "关庙",
                        hidden: 0
                    },
                    {
                        value: "710538",
                        label: "龙崎区",
                        simple_name: "龙崎",
                        hidden: 0
                    },
                    {
                        value: "710539",
                        label: "永康区",
                        simple_name: "永康",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710600",
                label: "新竹市",
                simple_name: "新竹",
                hidden: 0,
                children: [
                    {
                        value: "710601",
                        label: "东区",
                        simple_name: "东区",
                        hidden: 0
                    },
                    {
                        value: "710602",
                        label: "北区",
                        simple_name: "北区",
                        hidden: 0
                    },
                    {
                        value: "710603",
                        label: "香山区",
                        simple_name: "香山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710700",
                label: "嘉义市",
                simple_name: "嘉义",
                hidden: 0,
                children: [
                    {
                        value: "710701",
                        label: "东区",
                        simple_name: "东区",
                        hidden: 0
                    },
                    {
                        value: "710702",
                        label: "西区",
                        simple_name: "西区",
                        hidden: 0
                    }
                ]
            },
            {
                value: "710800",
                label: "新北市",
                simple_name: "新北",
                hidden: 0,
                children: [
                    {
                        value: "710801",
                        label: "板桥区",
                        simple_name: "板桥",
                        hidden: 0
                    },
                    {
                        value: "710802",
                        label: "三重区",
                        simple_name: "三重",
                        hidden: 0
                    },
                    {
                        value: "710803",
                        label: "中和区",
                        simple_name: "中和",
                        hidden: 0
                    },
                    {
                        value: "710804",
                        label: "永和区",
                        simple_name: "永和",
                        hidden: 0
                    },
                    {
                        value: "710805",
                        label: "新庄区",
                        simple_name: "新庄",
                        hidden: 0
                    },
                    {
                        value: "710806",
                        label: "新店区",
                        simple_name: "新店",
                        hidden: 0
                    },
                    {
                        value: "710807",
                        label: "树林区",
                        simple_name: "树林",
                        hidden: 0
                    },
                    {
                        value: "710808",
                        label: "莺歌区",
                        simple_name: "莺歌",
                        hidden: 0
                    },
                    {
                        value: "710809",
                        label: "三峡区",
                        simple_name: "三峡",
                        hidden: 0
                    },
                    {
                        value: "710810",
                        label: "淡水区",
                        simple_name: "淡水",
                        hidden: 0
                    },
                    {
                        value: "710811",
                        label: "汐止区",
                        simple_name: "汐止",
                        hidden: 0
                    },
                    {
                        value: "710812",
                        label: "瑞芳区",
                        simple_name: "瑞芳",
                        hidden: 0
                    },
                    {
                        value: "710813",
                        label: "土城区",
                        simple_name: "土城",
                        hidden: 0
                    },
                    {
                        value: "710814",
                        label: "芦洲区",
                        simple_name: "芦洲",
                        hidden: 0
                    },
                    {
                        value: "710815",
                        label: "五股区",
                        simple_name: "五股",
                        hidden: 0
                    },
                    {
                        value: "710816",
                        label: "泰山区",
                        simple_name: "泰山",
                        hidden: 0
                    },
                    {
                        value: "710817",
                        label: "林口区",
                        simple_name: "林口",
                        hidden: 0
                    },
                    {
                        value: "710818",
                        label: "深坑区",
                        simple_name: "深坑",
                        hidden: 0
                    },
                    {
                        value: "710819",
                        label: "石碇区",
                        simple_name: "石碇",
                        hidden: 0
                    },
                    {
                        value: "710820",
                        label: "坪林区",
                        simple_name: "坪林",
                        hidden: 0
                    },
                    {
                        value: "710821",
                        label: "三芝区",
                        simple_name: "三芝",
                        hidden: 0
                    },
                    {
                        value: "710822",
                        label: "石门区",
                        simple_name: "石门",
                        hidden: 0
                    },
                    {
                        value: "710823",
                        label: "八里区",
                        simple_name: "八里",
                        hidden: 0
                    },
                    {
                        value: "710824",
                        label: "平溪区",
                        simple_name: "平溪",
                        hidden: 0
                    },
                    {
                        value: "710825",
                        label: "双溪区",
                        simple_name: "双溪",
                        hidden: 0
                    },
                    {
                        value: "710826",
                        label: "贡寮区",
                        simple_name: "贡寮",
                        hidden: 0
                    },
                    {
                        value: "710827",
                        label: "金山区",
                        simple_name: "金山",
                        hidden: 0
                    },
                    {
                        value: "710828",
                        label: "万里区",
                        simple_name: "万里",
                        hidden: 0
                    },
                    {
                        value: "710829",
                        label: "乌来区",
                        simple_name: "乌来",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712200",
                label: "宜兰县",
                simple_name: "宜兰",
                hidden: 0,
                children: [
                    {
                        value: "712201",
                        label: "宜兰市",
                        simple_name: "宜兰",
                        hidden: 0
                    },
                    {
                        value: "712221",
                        label: "罗东镇",
                        simple_name: "罗东",
                        hidden: 0
                    },
                    {
                        value: "712222",
                        label: "苏澳镇",
                        simple_name: "苏澳",
                        hidden: 0
                    },
                    {
                        value: "712223",
                        label: "头城镇",
                        simple_name: "头城",
                        hidden: 0
                    },
                    {
                        value: "712224",
                        label: "礁溪乡",
                        simple_name: "礁溪",
                        hidden: 0
                    },
                    {
                        value: "712225",
                        label: "壮围乡",
                        simple_name: "壮围",
                        hidden: 0
                    },
                    {
                        value: "712226",
                        label: "员山乡",
                        simple_name: "员山",
                        hidden: 0
                    },
                    {
                        value: "712227",
                        label: "冬山乡",
                        simple_name: "冬山",
                        hidden: 0
                    },
                    {
                        value: "712228",
                        label: "五结乡",
                        simple_name: "五结",
                        hidden: 0
                    },
                    {
                        value: "712229",
                        label: "三星乡",
                        simple_name: "三星",
                        hidden: 0
                    },
                    {
                        value: "712230",
                        label: "大同乡",
                        simple_name: "大同",
                        hidden: 0
                    },
                    {
                        value: "712231",
                        label: "南澳乡",
                        simple_name: "南澳",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712300",
                label: "桃园县",
                simple_name: "桃园",
                hidden: 0,
                children: [
                    {
                        value: "712301",
                        label: "桃园市",
                        simple_name: "桃园",
                        hidden: 0
                    },
                    {
                        value: "712302",
                        label: "中坜市",
                        simple_name: "中坜",
                        hidden: 0
                    },
                    {
                        value: "712303",
                        label: "平镇市",
                        simple_name: "平镇",
                        hidden: 0
                    },
                    {
                        value: "712304",
                        label: "八德市",
                        simple_name: "八德",
                        hidden: 0
                    },
                    {
                        value: "712305",
                        label: "杨梅市",
                        simple_name: "杨梅",
                        hidden: 0
                    },
                    {
                        value: "712306",
                        label: "芦竹市",
                        simple_name: "芦竹",
                        hidden: 0
                    },
                    {
                        value: "712321",
                        label: "大溪镇",
                        simple_name: "大溪",
                        hidden: 0
                    },
                    {
                        value: "712324",
                        label: "大园乡",
                        simple_name: "大园",
                        hidden: 0
                    },
                    {
                        value: "712325",
                        label: "龟山乡",
                        simple_name: "龟山",
                        hidden: 0
                    },
                    {
                        value: "712327",
                        label: "龙潭乡",
                        simple_name: "龙潭",
                        hidden: 0
                    },
                    {
                        value: "712329",
                        label: "新屋乡",
                        simple_name: "新屋",
                        hidden: 0
                    },
                    {
                        value: "712330",
                        label: "观音乡",
                        simple_name: "观音",
                        hidden: 0
                    },
                    {
                        value: "712331",
                        label: "复兴乡",
                        simple_name: "复兴",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712400",
                label: "新竹县",
                simple_name: "新竹",
                hidden: 0,
                children: [
                    {
                        value: "712401",
                        label: "竹北市",
                        simple_name: "竹北",
                        hidden: 0
                    },
                    {
                        value: "712421",
                        label: "竹东镇",
                        simple_name: "竹东",
                        hidden: 0
                    },
                    {
                        value: "712422",
                        label: "新埔镇",
                        simple_name: "新埔",
                        hidden: 0
                    },
                    {
                        value: "712423",
                        label: "关西镇",
                        simple_name: "关西",
                        hidden: 0
                    },
                    {
                        value: "712424",
                        label: "湖口乡",
                        simple_name: "湖口",
                        hidden: 0
                    },
                    {
                        value: "712425",
                        label: "新丰乡",
                        simple_name: "新丰",
                        hidden: 0
                    },
                    {
                        value: "712426",
                        label: "芎林乡",
                        simple_name: "芎林",
                        hidden: 0
                    },
                    {
                        value: "712427",
                        label: "横山乡",
                        simple_name: "横山",
                        hidden: 0
                    },
                    {
                        value: "712428",
                        label: "北埔乡",
                        simple_name: "北埔",
                        hidden: 0
                    },
                    {
                        value: "712429",
                        label: "宝山乡",
                        simple_name: "宝山",
                        hidden: 0
                    },
                    {
                        value: "712430",
                        label: "峨眉乡",
                        simple_name: "峨眉",
                        hidden: 0
                    },
                    {
                        value: "712431",
                        label: "尖石乡",
                        simple_name: "尖石",
                        hidden: 0
                    },
                    {
                        value: "712432",
                        label: "五峰乡",
                        simple_name: "五峰",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712500",
                label: "苗栗县",
                simple_name: "苗栗",
                hidden: 0,
                children: [
                    {
                        value: "712501",
                        label: "苗栗市",
                        simple_name: "苗栗",
                        hidden: 0
                    },
                    {
                        value: "712521",
                        label: "苑里镇",
                        simple_name: "苑里",
                        hidden: 0
                    },
                    {
                        value: "712522",
                        label: "通霄镇",
                        simple_name: "通霄",
                        hidden: 0
                    },
                    {
                        value: "712523",
                        label: "竹南镇",
                        simple_name: "竹南",
                        hidden: 0
                    },
                    {
                        value: "712524",
                        label: "头份镇",
                        simple_name: "头份",
                        hidden: 0
                    },
                    {
                        value: "712525",
                        label: "后龙镇",
                        simple_name: "后龙",
                        hidden: 0
                    },
                    {
                        value: "712526",
                        label: "卓兰镇",
                        simple_name: "卓兰",
                        hidden: 0
                    },
                    {
                        value: "712527",
                        label: "大湖乡",
                        simple_name: "大湖",
                        hidden: 0
                    },
                    {
                        value: "712528",
                        label: "公馆乡",
                        simple_name: "公馆",
                        hidden: 0
                    },
                    {
                        value: "712529",
                        label: "铜锣乡",
                        simple_name: "铜锣",
                        hidden: 0
                    },
                    {
                        value: "712530",
                        label: "南庄乡",
                        simple_name: "南庄",
                        hidden: 0
                    },
                    {
                        value: "712531",
                        label: "头屋乡",
                        simple_name: "头屋",
                        hidden: 0
                    },
                    {
                        value: "712532",
                        label: "三义乡",
                        simple_name: "三义",
                        hidden: 0
                    },
                    {
                        value: "712533",
                        label: "西湖乡",
                        simple_name: "西湖",
                        hidden: 0
                    },
                    {
                        value: "712534",
                        label: "造桥乡",
                        simple_name: "造桥",
                        hidden: 0
                    },
                    {
                        value: "712535",
                        label: "三湾乡",
                        simple_name: "三湾",
                        hidden: 0
                    },
                    {
                        value: "712536",
                        label: "狮潭乡",
                        simple_name: "狮潭",
                        hidden: 0
                    },
                    {
                        value: "712537",
                        label: "泰安乡",
                        simple_name: "泰安",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712700",
                label: "彰化县",
                simple_name: "彰化",
                hidden: 0,
                children: [
                    {
                        value: "712701",
                        label: "彰化市",
                        simple_name: "彰化市",
                        hidden: 0
                    },
                    {
                        value: "712721",
                        label: "鹿港镇",
                        simple_name: "鹿港",
                        hidden: 0
                    },
                    {
                        value: "712722",
                        label: "和美镇",
                        simple_name: "和美",
                        hidden: 0
                    },
                    {
                        value: "712723",
                        label: "线西乡",
                        simple_name: "线西",
                        hidden: 0
                    },
                    {
                        value: "712724",
                        label: "伸港乡",
                        simple_name: "伸港",
                        hidden: 0
                    },
                    {
                        value: "712725",
                        label: "福兴乡",
                        simple_name: "福兴",
                        hidden: 0
                    },
                    {
                        value: "712726",
                        label: "秀水乡",
                        simple_name: "秀水",
                        hidden: 0
                    },
                    {
                        value: "712727",
                        label: "花坛乡",
                        simple_name: "花坛",
                        hidden: 0
                    },
                    {
                        value: "712728",
                        label: "芬园乡",
                        simple_name: "芬园",
                        hidden: 0
                    },
                    {
                        value: "712729",
                        label: "员林镇",
                        simple_name: "员林",
                        hidden: 0
                    },
                    {
                        value: "712730",
                        label: "溪湖镇",
                        simple_name: "溪湖",
                        hidden: 0
                    },
                    {
                        value: "712731",
                        label: "田中镇",
                        simple_name: "田中",
                        hidden: 0
                    },
                    {
                        value: "712732",
                        label: "大村乡",
                        simple_name: "大村",
                        hidden: 0
                    },
                    {
                        value: "712733",
                        label: "埔盐乡",
                        simple_name: "埔盐",
                        hidden: 0
                    },
                    {
                        value: "712734",
                        label: "埔心乡",
                        simple_name: "埔心",
                        hidden: 0
                    },
                    {
                        value: "712735",
                        label: "永靖乡",
                        simple_name: "永靖",
                        hidden: 0
                    },
                    {
                        value: "712736",
                        label: "社头乡",
                        simple_name: "社头",
                        hidden: 0
                    },
                    {
                        value: "712737",
                        label: "二水乡",
                        simple_name: "二水",
                        hidden: 0
                    },
                    {
                        value: "712738",
                        label: "北斗镇",
                        simple_name: "北斗",
                        hidden: 0
                    },
                    {
                        value: "712739",
                        label: "二林镇",
                        simple_name: "二林",
                        hidden: 0
                    },
                    {
                        value: "712740",
                        label: "田尾乡",
                        simple_name: "田尾",
                        hidden: 0
                    },
                    {
                        value: "712741",
                        label: "埤头乡",
                        simple_name: "埤头",
                        hidden: 0
                    },
                    {
                        value: "712742",
                        label: "芳苑乡",
                        simple_name: "芳苑",
                        hidden: 0
                    },
                    {
                        value: "712743",
                        label: "大城乡",
                        simple_name: "大城",
                        hidden: 0
                    },
                    {
                        value: "712744",
                        label: "竹塘乡",
                        simple_name: "竹塘",
                        hidden: 0
                    },
                    {
                        value: "712745",
                        label: "溪州乡",
                        simple_name: "溪州",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712800",
                label: "南投县",
                simple_name: "南投",
                hidden: 0,
                children: [
                    {
                        value: "712801",
                        label: "南投市",
                        simple_name: "南投市",
                        hidden: 0
                    },
                    {
                        value: "712821",
                        label: "埔里镇",
                        simple_name: "埔里",
                        hidden: 0
                    },
                    {
                        value: "712822",
                        label: "草屯镇",
                        simple_name: "草屯",
                        hidden: 0
                    },
                    {
                        value: "712823",
                        label: "竹山镇",
                        simple_name: "竹山",
                        hidden: 0
                    },
                    {
                        value: "712824",
                        label: "集集镇",
                        simple_name: "集集",
                        hidden: 0
                    },
                    {
                        value: "712825",
                        label: "名间乡",
                        simple_name: "名间",
                        hidden: 0
                    },
                    {
                        value: "712826",
                        label: "鹿谷乡",
                        simple_name: "鹿谷",
                        hidden: 0
                    },
                    {
                        value: "712827",
                        label: "中寮乡",
                        simple_name: "中寮",
                        hidden: 0
                    },
                    {
                        value: "712828",
                        label: "鱼池乡",
                        simple_name: "鱼池",
                        hidden: 0
                    },
                    {
                        value: "712829",
                        label: "国姓乡",
                        simple_name: "国姓",
                        hidden: 0
                    },
                    {
                        value: "712830",
                        label: "水里乡",
                        simple_name: "水里",
                        hidden: 0
                    },
                    {
                        value: "712831",
                        label: "信义乡",
                        simple_name: "信义",
                        hidden: 0
                    },
                    {
                        value: "712832",
                        label: "仁爱乡",
                        simple_name: "仁爱",
                        hidden: 0
                    }
                ]
            },
            {
                value: "712900",
                label: "云林县",
                simple_name: "云林",
                hidden: 0,
                children: [
                    {
                        value: "712901",
                        label: "斗六市",
                        simple_name: "斗六",
                        hidden: 0
                    },
                    {
                        value: "712921",
                        label: "斗南镇",
                        simple_name: "斗南",
                        hidden: 0
                    },
                    {
                        value: "712922",
                        label: "虎尾镇",
                        simple_name: "虎尾",
                        hidden: 0
                    },
                    {
                        value: "712923",
                        label: "西螺镇",
                        simple_name: "西螺",
                        hidden: 0
                    },
                    {
                        value: "712924",
                        label: "土库镇",
                        simple_name: "土库",
                        hidden: 0
                    },
                    {
                        value: "712925",
                        label: "北港镇",
                        simple_name: "北港",
                        hidden: 0
                    },
                    {
                        value: "712926",
                        label: "古坑乡",
                        simple_name: "古坑",
                        hidden: 0
                    },
                    {
                        value: "712927",
                        label: "大埤乡",
                        simple_name: "大埤",
                        hidden: 0
                    },
                    {
                        value: "712928",
                        label: "莿桐乡",
                        simple_name: "莿桐",
                        hidden: 0
                    },
                    {
                        value: "712929",
                        label: "林内乡",
                        simple_name: "林内",
                        hidden: 0
                    },
                    {
                        value: "712930",
                        label: "二仑乡",
                        simple_name: "二仑",
                        hidden: 0
                    },
                    {
                        value: "712931",
                        label: "仑背乡",
                        simple_name: "仑背",
                        hidden: 0
                    },
                    {
                        value: "712932",
                        label: "麦寮乡",
                        simple_name: "麦寮",
                        hidden: 0
                    },
                    {
                        value: "712933",
                        label: "东势乡",
                        simple_name: "东势",
                        hidden: 0
                    },
                    {
                        value: "712934",
                        label: "褒忠乡",
                        simple_name: "褒忠",
                        hidden: 0
                    },
                    {
                        value: "712935",
                        label: "台西乡",
                        simple_name: "台西",
                        hidden: 0
                    },
                    {
                        value: "712936",
                        label: "元长乡",
                        simple_name: "元长",
                        hidden: 0
                    },
                    {
                        value: "712937",
                        label: "四湖乡",
                        simple_name: "四湖",
                        hidden: 0
                    },
                    {
                        value: "712938",
                        label: "口湖乡",
                        simple_name: "口湖",
                        hidden: 0
                    },
                    {
                        value: "712939",
                        label: "水林乡",
                        simple_name: "水林",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713000",
                label: "嘉义县",
                simple_name: "嘉义",
                hidden: 0,
                children: [
                    {
                        value: "713001",
                        label: "太保市",
                        simple_name: "太保",
                        hidden: 0
                    },
                    {
                        value: "713002",
                        label: "朴子市",
                        simple_name: "朴子",
                        hidden: 0
                    },
                    {
                        value: "713023",
                        label: "布袋镇",
                        simple_name: "布袋",
                        hidden: 0
                    },
                    {
                        value: "713024",
                        label: "大林镇",
                        simple_name: "大林",
                        hidden: 0
                    },
                    {
                        value: "713025",
                        label: "民雄乡",
                        simple_name: "民雄",
                        hidden: 0
                    },
                    {
                        value: "713026",
                        label: "溪口乡",
                        simple_name: "溪口",
                        hidden: 0
                    },
                    {
                        value: "713027",
                        label: "新港乡",
                        simple_name: "新港",
                        hidden: 0
                    },
                    {
                        value: "713028",
                        label: "六脚乡",
                        simple_name: "六脚",
                        hidden: 0
                    },
                    {
                        value: "713029",
                        label: "东石乡",
                        simple_name: "东石",
                        hidden: 0
                    },
                    {
                        value: "713030",
                        label: "义竹乡",
                        simple_name: "义竹",
                        hidden: 0
                    },
                    {
                        value: "713031",
                        label: "鹿草乡",
                        simple_name: "鹿草",
                        hidden: 0
                    },
                    {
                        value: "713032",
                        label: "水上乡",
                        simple_name: "水上",
                        hidden: 0
                    },
                    {
                        value: "713033",
                        label: "中埔乡",
                        simple_name: "中埔",
                        hidden: 0
                    },
                    {
                        value: "713034",
                        label: "竹崎乡",
                        simple_name: "竹崎",
                        hidden: 0
                    },
                    {
                        value: "713035",
                        label: "梅山乡",
                        simple_name: "梅山",
                        hidden: 0
                    },
                    {
                        value: "713036",
                        label: "番路乡",
                        simple_name: "番路",
                        hidden: 0
                    },
                    {
                        value: "713037",
                        label: "大埔乡",
                        simple_name: "大埔",
                        hidden: 0
                    },
                    {
                        value: "713038",
                        label: "阿里山乡",
                        simple_name: "阿里山",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713300",
                label: "屏东县",
                simple_name: "屏东",
                hidden: 0,
                children: [
                    {
                        value: "713301",
                        label: "屏东市",
                        simple_name: "屏东",
                        hidden: 0
                    },
                    {
                        value: "713321",
                        label: "潮州镇",
                        simple_name: "潮州",
                        hidden: 0
                    },
                    {
                        value: "713322",
                        label: "东港镇",
                        simple_name: "东港",
                        hidden: 0
                    },
                    {
                        value: "713323",
                        label: "恒春镇",
                        simple_name: "恒春",
                        hidden: 0
                    },
                    {
                        value: "713324",
                        label: "万丹乡",
                        simple_name: "万丹",
                        hidden: 0
                    },
                    {
                        value: "713325",
                        label: "长治乡",
                        simple_name: "长治",
                        hidden: 0
                    },
                    {
                        value: "713326",
                        label: "麟洛乡",
                        simple_name: "麟洛",
                        hidden: 0
                    },
                    {
                        value: "713327",
                        label: "九如乡",
                        simple_name: "九如",
                        hidden: 0
                    },
                    {
                        value: "713328",
                        label: "里港乡",
                        simple_name: "里港",
                        hidden: 0
                    },
                    {
                        value: "713329",
                        label: "盐埔乡",
                        simple_name: "盐埔",
                        hidden: 0
                    },
                    {
                        value: "713330",
                        label: "高树乡",
                        simple_name: "高树",
                        hidden: 0
                    },
                    {
                        value: "713331",
                        label: "万峦乡",
                        simple_name: "万峦",
                        hidden: 0
                    },
                    {
                        value: "713332",
                        label: "内埔乡",
                        simple_name: "内埔",
                        hidden: 0
                    },
                    {
                        value: "713333",
                        label: "竹田乡",
                        simple_name: "竹田",
                        hidden: 0
                    },
                    {
                        value: "713334",
                        label: "新埤乡",
                        simple_name: "新埤",
                        hidden: 0
                    },
                    {
                        value: "713335",
                        label: "枋寮乡",
                        simple_name: "枋寮",
                        hidden: 0
                    },
                    {
                        value: "713336",
                        label: "新园乡",
                        simple_name: "新园",
                        hidden: 0
                    },
                    {
                        value: "713337",
                        label: "崁顶乡",
                        simple_name: "崁顶",
                        hidden: 0
                    },
                    {
                        value: "713338",
                        label: "林边乡",
                        simple_name: "林边",
                        hidden: 0
                    },
                    {
                        value: "713339",
                        label: "南州乡",
                        simple_name: "南州",
                        hidden: 0
                    },
                    {
                        value: "713340",
                        label: "佳冬乡",
                        simple_name: "佳冬",
                        hidden: 0
                    },
                    {
                        value: "713341",
                        label: "琉球乡",
                        simple_name: "琉球",
                        hidden: 0
                    },
                    {
                        value: "713342",
                        label: "车城乡",
                        simple_name: "车城",
                        hidden: 0
                    },
                    {
                        value: "713343",
                        label: "满州乡",
                        simple_name: "满州",
                        hidden: 0
                    },
                    {
                        value: "713344",
                        label: "枋山乡",
                        simple_name: "枋山",
                        hidden: 0
                    },
                    {
                        value: "713345",
                        label: "三地门乡",
                        simple_name: "三地门",
                        hidden: 0
                    },
                    {
                        value: "713346",
                        label: "雾台乡",
                        simple_name: "雾台",
                        hidden: 0
                    },
                    {
                        value: "713347",
                        label: "玛家乡",
                        simple_name: "玛家",
                        hidden: 0
                    },
                    {
                        value: "713348",
                        label: "泰武乡",
                        simple_name: "泰武",
                        hidden: 0
                    },
                    {
                        value: "713349",
                        label: "来义乡",
                        simple_name: "来义",
                        hidden: 0
                    },
                    {
                        value: "713350",
                        label: "春日乡",
                        simple_name: "春日",
                        hidden: 0
                    },
                    {
                        value: "713351",
                        label: "狮子乡",
                        simple_name: "狮子",
                        hidden: 0
                    },
                    {
                        value: "713352",
                        label: "牡丹乡",
                        simple_name: "牡丹",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713400",
                label: "台东县",
                simple_name: "台东",
                hidden: 0,
                children: [
                    {
                        value: "713401",
                        label: "台东市",
                        simple_name: "台东",
                        hidden: 0
                    },
                    {
                        value: "713421",
                        label: "成功镇",
                        simple_name: "成功",
                        hidden: 0
                    },
                    {
                        value: "713422",
                        label: "关山镇",
                        simple_name: "关山",
                        hidden: 0
                    },
                    {
                        value: "713423",
                        label: "卑南乡",
                        simple_name: "卑南",
                        hidden: 0
                    },
                    {
                        value: "713424",
                        label: "鹿野乡",
                        simple_name: "鹿野",
                        hidden: 0
                    },
                    {
                        value: "713425",
                        label: "池上乡",
                        simple_name: "池上",
                        hidden: 0
                    },
                    {
                        value: "713426",
                        label: "东河乡",
                        simple_name: "东河",
                        hidden: 0
                    },
                    {
                        value: "713427",
                        label: "长滨乡",
                        simple_name: "长滨",
                        hidden: 0
                    },
                    {
                        value: "713428",
                        label: "太麻里乡",
                        simple_name: "太麻里",
                        hidden: 0
                    },
                    {
                        value: "713429",
                        label: "大武乡",
                        simple_name: "大武",
                        hidden: 0
                    },
                    {
                        value: "713430",
                        label: "绿岛乡",
                        simple_name: "绿岛",
                        hidden: 0
                    },
                    {
                        value: "713431",
                        label: "海端乡",
                        simple_name: "海端",
                        hidden: 0
                    },
                    {
                        value: "713432",
                        label: "延平乡",
                        simple_name: "延平",
                        hidden: 0
                    },
                    {
                        value: "713433",
                        label: "金峰乡",
                        simple_name: "金峰",
                        hidden: 0
                    },
                    {
                        value: "713434",
                        label: "达仁乡",
                        simple_name: "达仁",
                        hidden: 0
                    },
                    {
                        value: "713435",
                        label: "兰屿乡",
                        simple_name: "兰屿",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713500",
                label: "花莲县",
                simple_name: "花莲",
                hidden: 0,
                children: [
                    {
                        value: "713501",
                        label: "花莲市",
                        simple_name: "花莲",
                        hidden: 0
                    },
                    {
                        value: "713521",
                        label: "凤林镇",
                        simple_name: "凤林",
                        hidden: 0
                    },
                    {
                        value: "713522",
                        label: "玉里镇",
                        simple_name: "玉里",
                        hidden: 0
                    },
                    {
                        value: "713523",
                        label: "新城乡",
                        simple_name: "新城",
                        hidden: 0
                    },
                    {
                        value: "713524",
                        label: "吉安乡",
                        simple_name: "吉安",
                        hidden: 0
                    },
                    {
                        value: "713525",
                        label: "寿丰乡",
                        simple_name: "寿丰",
                        hidden: 0
                    },
                    {
                        value: "713526",
                        label: "光复乡",
                        simple_name: "光复",
                        hidden: 0
                    },
                    {
                        value: "713527",
                        label: "丰滨乡",
                        simple_name: "丰滨",
                        hidden: 0
                    },
                    {
                        value: "713528",
                        label: "瑞穗乡",
                        simple_name: "瑞穗",
                        hidden: 0
                    },
                    {
                        value: "713529",
                        label: "富里乡",
                        simple_name: "富里",
                        hidden: 0
                    },
                    {
                        value: "713530",
                        label: "秀林乡",
                        simple_name: "秀林",
                        hidden: 0
                    },
                    {
                        value: "713531",
                        label: "万荣乡",
                        simple_name: "万荣",
                        hidden: 0
                    },
                    {
                        value: "713532",
                        label: "卓溪乡",
                        simple_name: "卓溪",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713600",
                label: "澎湖县",
                simple_name: "澎湖",
                hidden: 0,
                children: [
                    {
                        value: "713601",
                        label: "马公市",
                        simple_name: "马公",
                        hidden: 0
                    },
                    {
                        value: "713621",
                        label: "湖西乡",
                        simple_name: "湖西",
                        hidden: 0
                    },
                    {
                        value: "713622",
                        label: "白沙乡",
                        simple_name: "白沙",
                        hidden: 0
                    },
                    {
                        value: "713623",
                        label: "西屿乡",
                        simple_name: "西屿",
                        hidden: 0
                    },
                    {
                        value: "713624",
                        label: "望安乡",
                        simple_name: "望安",
                        hidden: 0
                    },
                    {
                        value: "713625",
                        label: "七美乡",
                        simple_name: "七美",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713700",
                label: "金门县",
                simple_name: "金门",
                hidden: 0,
                children: [
                    {
                        value: "713701",
                        label: "金城镇",
                        simple_name: "金城",
                        hidden: 0
                    },
                    {
                        value: "713702",
                        label: "金湖镇",
                        simple_name: "金湖",
                        hidden: 0
                    },
                    {
                        value: "713703",
                        label: "金沙镇",
                        simple_name: "金沙",
                        hidden: 0
                    },
                    {
                        value: "713704",
                        label: "金宁乡",
                        simple_name: "金宁",
                        hidden: 0
                    },
                    {
                        value: "713705",
                        label: "烈屿乡",
                        simple_name: "烈屿",
                        hidden: 0
                    },
                    {
                        value: "713706",
                        label: "乌丘乡",
                        simple_name: "乌丘",
                        hidden: 0
                    }
                ]
            },
            {
                value: "713800",
                label: "连江县",
                simple_name: "连江",
                hidden: 0,
                children: [
                    {
                        value: "713801",
                        label: "南竿乡",
                        simple_name: "南竿",
                        hidden: 0
                    },
                    {
                        value: "713802",
                        label: "北竿乡",
                        simple_name: "北竿",
                        hidden: 0
                    },
                    {
                        value: "713803",
                        label: "莒光乡",
                        simple_name: "莒光",
                        hidden: 0
                    },
                    {
                        value: "713804",
                        label: "东引乡",
                        simple_name: "东引",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "810000",
        label: "香港特别行政区",
        simple_name: "香港",
        hidden: 0,
        children: [
            {
                value: "810100",
                label: "香港岛",
                simple_name: "香港岛",
                hidden: 0,
                children: [
                    {
                        value: "810101",
                        label: "中西区",
                        simple_name: "中西区",
                        hidden: 0
                    },
                    {
                        value: "810102",
                        label: "湾仔区",
                        simple_name: "湾仔区",
                        hidden: 0
                    },
                    {
                        value: "810103",
                        label: "东区",
                        simple_name: "东区",
                        hidden: 0
                    },
                    {
                        value: "810104",
                        label: "南区",
                        simple_name: "南区",
                        hidden: 0
                    }
                ]
            },
            {
                value: "810200",
                label: "九龙",
                simple_name: "九龙",
                hidden: 0,
                children: [
                    {
                        value: "810201",
                        label: "油尖旺区",
                        simple_name: "油尖旺",
                        hidden: 0
                    },
                    {
                        value: "810202",
                        label: "深水埗区",
                        simple_name: "深水埗",
                        hidden: 0
                    },
                    {
                        value: "810203",
                        label: "九龙城区",
                        simple_name: "九龙城",
                        hidden: 0
                    },
                    {
                        value: "810204",
                        label: "黄大仙区",
                        simple_name: "黄大仙",
                        hidden: 0
                    },
                    {
                        value: "810205",
                        label: "观塘区",
                        simple_name: "观塘",
                        hidden: 0
                    }
                ]
            },
            {
                value: "810300",
                label: "新界",
                simple_name: "新界",
                hidden: 0,
                children: [
                    {
                        value: "810301",
                        label: "荃湾区",
                        simple_name: "荃湾",
                        hidden: 0
                    },
                    {
                        value: "810302",
                        label: "屯门区",
                        simple_name: "屯门",
                        hidden: 0
                    },
                    {
                        value: "810303",
                        label: "元朗区",
                        simple_name: "元朗",
                        hidden: 0
                    },
                    {
                        value: "810304",
                        label: "北区",
                        simple_name: "北区",
                        hidden: 0
                    },
                    {
                        value: "810305",
                        label: "大埔区",
                        simple_name: "大埔",
                        hidden: 0
                    },
                    {
                        value: "810306",
                        label: "西贡区",
                        simple_name: "西贡",
                        hidden: 0
                    },
                    {
                        value: "810307",
                        label: "沙田区",
                        simple_name: "沙田",
                        hidden: 0
                    },
                    {
                        value: "810308",
                        label: "葵青区",
                        simple_name: "葵青",
                        hidden: 0
                    },
                    {
                        value: "810309",
                        label: "离岛区",
                        simple_name: "离岛",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "820000",
        label: "澳门特别行政区",
        simple_name: "澳门",
        hidden: 0,
        children: [
            {
                value: "820100",
                label: "澳门半岛",
                simple_name: "澳门半岛",
                hidden: 0,
                children: [
                    {
                        value: "820101",
                        label: "花地玛堂区",
                        simple_name: "花地玛堂区",
                        hidden: 0
                    },
                    {
                        value: "820102",
                        label: "圣安多尼堂区",
                        simple_name: "圣安多尼堂区",
                        hidden: 0
                    },
                    {
                        value: "820103",
                        label: "大堂区",
                        simple_name: "大堂",
                        hidden: 0
                    },
                    {
                        value: "820104",
                        label: "望德堂区",
                        simple_name: "望德堂区",
                        hidden: 0
                    },
                    {
                        value: "820105",
                        label: "风顺堂区",
                        simple_name: "风顺堂区",
                        hidden: 0
                    }
                ]
            },
            {
                value: "820200",
                label: "氹仔岛",
                simple_name: "氹仔岛",
                hidden: 0,
                children: [
                    {
                        value: "820201",
                        label: "嘉模堂区",
                        simple_name: "嘉模堂区",
                        hidden: 0
                    }
                ]
            },
            {
                value: "820300",
                label: "路环岛",
                simple_name: "路环岛",
                hidden: 0,
                children: [
                    {
                        value: "820301",
                        label: "圣方济各堂区",
                        simple_name: "圣方济各堂区",
                        hidden: 0
                    }
                ]
            }
        ]
    },
    {
        value: "900000",
        label: "钓鱼岛",
        simple_name: "钓鱼岛",
        hidden: 0,
        children: []
    }
];
