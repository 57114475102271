import React, { Fragment, useState, useEffect } from "react";
import { Button, Layout, Card, Space, Form, Input, Pagination, Row, Col } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import Previewtemplate from "./Previewtemplate";
import { listTemplateApi } from "@services/Wj/Template";
import { saveSurveyApi, quoteSurveyApi } from "@services/Wj/Question";
import "./index.less";
const Createsurvey = () => {
    const { Content } = Layout;
    const { Meta } = Card;
    const [form] = Form.useForm();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [isPreview, setIsPreview] = useState(false);
    const [data, setData] = useState([]);
    const [filObj, setFilObj] = useState({});
    // 分页设置
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [templateId, setTemplateId] = useState(undefined);
    const [templateType, setTemplateType] = useState(undefined);
    const navigate = useNavigate();

    console.log(location);
    //预览模版
    const onPreview = (id, typename) => {
        setIsPreview(true);
        setTemplateId(id);
        typename === "调研问卷" && setTemplateType(1);
        typename === "投票" && setTemplateType(2);
        typename === "活动报名" && setTemplateType(3);
    };

    //搜索
    const onSearch = () => {
        setFilObj(form.getFieldValue());
    };
    const pageOptions = [12, 24, 60, 100];

    const getListData = async (page, pageSize, name, type) => {
        let params = {
            page,
            pageSize,
            name,
            type: location.state.type,
            status: 1,
        };
        let res = await listTemplateApi(params);
        if (res.status_code === 200) {
            setData(res.data.list);
            setTotal(res.data.totalCount);
        }
    };
    useEffect(() => {
        const { name, type, status } = filObj;
        getListData(page, pageSize, name, type, status);
    }, [page, pageSize, filObj]);

    //分页
    const onChangePage = (pageNumber, pagelength) => {
        setPage(pageNumber);
        setPageSize(pagelength);
    };

    const createNewSurvey = () => {
        saveSurveyApi({
            surveyTypeId: location.state.type,
            name: "问卷标题",
            business: 0,
            // ,directions:'为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧!'
        }).then((res) => {
            if (res.status_code === 200) {
                navigate("/wj/surveys/new/create", { state: { route: "newList", id: res.data, type: location.state.type } });
            }
        });
    };

    const onQuote = (id, typename) => {
        let type;
        typename === "调研问卷" && (type = 1);
        typename === "投票" && (type = 2);
        typename === "活动报名" && (type = 3);
        let sendValue = {
            route: "newTemplate",
            type,
        };
        quoteSurveyApi({ id }).then((res) => {
            if (res.status_code === 200) {
                navigate("/wj/surveys/new/create", { state: { ...sendValue, id: res.data.id } });
            }
        });
    };

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/wj/surveys" className="back">
                    <LeftOutlined />
                </Link>
            </div>
            <Content className="main-content-box" id="wj_template_wrap">
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="custom-create">
                            <h1>自主创建</h1>
                            <div className="create-area">
                                <div className="create-center">
                                    <p>从空白创建</p>
                                    <Button className="create-button" onClick={createNewSurvey}>
                                        立即创建
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="question-template">
                            <div className="template-header">
                                <h1>使用问卷模版</h1>
                                <Form onFinish={onSearch} form={form}>
                                    <Form.Item name="name">
                                        <Input placeholder="请输入模版名称" suffix={<SearchOutlined onClick={onSearch} />} />
                                    </Form.Item>
                                </Form>
                            </div>
                            <div>
                                <Row gutter={[16, 16]}>
                                    {data &&
                                        data.map((item) => {
                                            return (
                                                <Col span={6}>
                                                    <Card className="template-item">
                                                        <p className="template-title">{item.name}</p>

                                                        <div className="template-operate">
                                                            <div>
                                                                <span>共{item.questions}题</span>
                                                                <span> | </span>
                                                                <span>{item.typeName}</span>
                                                            </div>
                                                            <Button size="small" type="primary" ghost onClick={() => onPreview(item.id, item.typeName)}>
                                                                预览
                                                            </Button>
                                                            {/* <Button size='small' type="primary" ghost onClick={()=>onQuote(item.id,item.typeName)}>引用</Button> */}
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                                <div className="page-area">
                                    <div className="page-total">共 {total} 条</div>
                                    <Pagination total={total} showSizeChanger current={page} pageSize={pageSize} pageSizeOptions={pageOptions} showQuickJumper onChange={onChangePage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <Previewtemplate isPreview={isPreview} setIsPreview={setIsPreview} id={templateId} type={templateType} />
        </Fragment>
    );
};

export default Createsurvey;
