import moment from 'moment';
import _ from 'lodash';
import {message} from 'antd';
import areas from '@utils/areas';
import config from '@config';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const success = msg => message.success(msg); // 成功提示
export const error = msg => message.error(msg);   // 错误提示
export const warning = msg => message.warning(msg); // 警告提示
export const info = msg => message.info(msg);    // 一般提示

// 安全 JSON 解析
export const safeParseJSON = (jsonString, defaultValue = null) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        console.error('解析 JSON 失败:', error.message);
        return defaultValue;
    }
};

// 省份
export const getProvinceNameByCode = provinceCode => {
    let name = '';
    const province = areas.find(p => p.value === provinceCode) || null;
    if (province) {
        name = province.simple_name || province.label || '';
    }
    return name;
};

// 城市
export const getCityNameByCode = (provinceCode, cityCode) => {
    let name = '';
    const province = areas.find(p => p.value === provinceCode) || null;
    if (province && province.children) {
        const city = province.children.find(c => c.value === cityCode) || null;
        if (city) {
            name = city.simple_name || city.label || '';
        }
    }
    return name;
};

export const dateTimeFormat = (datetime, formatString = 'YYYY-MM-DD') => {
    return moment(datetime).format(formatString);
};

export const dateTimeFormatHMS = (datetime, formatString = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(datetime).format(formatString);
};

/**
 * 秒 转为 时分秒
 * @param seconds
 * @returns {string}
 */
export const secondsToHHMMSS = seconds => new Date(seconds * 1000).toISOString().substr(11, 8);

export const secondsToText = seconds => {
    if (!seconds) {
        return '';
    }

    let text = '';
    const time = new Date(seconds * 1000).toISOString().substr(11, 8).split(':');
    const hour = Number(time[0]);
    const minute = Number(time[1]);
    const second = Number(time[2]);

    if (hour) {
        text = hour + '小时';
    }
    if (minute) {
        text = text + minute + '分';
    }
    if (second) {
        text = text + second + '秒';
    }

    return text;
}

export const convertTimeRangeToText = (startTime, endTime) => {
    let result = '';

    if (!!startTime && !!endTime && moment(startTime).isValid() && moment(endTime).isValid()) {
        result = moment(startTime).format('HH:mm') + '～' + moment(endTime).format('HH:mm');
    }

    return result;
};

export const convertDateTimeToLocalText = (dateTime, enableSecond = 1) => {
    let result = '';

    if (!!dateTime && moment(dateTime).isValid()) {
        result = moment(dateTime).format('YYYY年M月D日 HH:mm:ss');

        if (!enableSecond) {
            result = result.slice(0, -3);
        }
    }

    return result;
};

/**
 * 人性化展示日期范围
 * @param startDate
 * @param endDate
 * @returns {string}
 */
export const convertDateRangeToLocalText = (startDate, endDate) => {
    let result = '';

    if (!!startDate && !!endDate && moment(startDate).isValid() && moment(endDate).isValid()) {
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const formattedStartDate = startDateMoment.format('YYYY年M月D日');
        if (startDateMoment.format('YYYY') === endDateMoment.format('YYYY')) {
            // 同年
            if (startDateMoment.format('YYYYMM') === endDateMoment.format('YYYYMM')) {
                // 同月
                result = `${formattedStartDate}～${endDateMoment.format('D日')}`;
            } else {
                // 不同月
                result = `${formattedStartDate}～${endDateMoment.format('M月D日')}`;
            }
        } else {
            // 不同年
            result = `${formattedStartDate}～${endDateMoment.format('YYYY年M月D日')}`;
        }
    }

    return result;
}

/**
 * 人性化展示日期时间范围
 * @param startDateTime
 * @param endDateTime
 * @param enableSecond
 * @returns {string}
 */
export const convertDateTimeRangeToLocalText = (startDateTime, endDateTime, enableSecond = 1) => {
    let result = '';

    if (!!startDateTime && !!endDateTime && moment(startDateTime).isValid() && moment(endDateTime).isValid()) {
        const startDateTimeMoment = moment(startDateTime);
        const endDateTimeMoment = moment(endDateTime);

        let formattedStartDateTime = startDateTimeMoment.format('YYYY年M月D日 HH:mm:ss');
        if (!enableSecond) {
            formattedStartDateTime = formattedStartDateTime.slice(0, -3);
        }

        if (startDateTimeMoment.format('YYYY') === endDateTimeMoment.format('YYYY')) {
            // 同年
            if (startDateTimeMoment.format('YYYYMM') === endDateTimeMoment.format('YYYYMM')) {
                // 同日
                result = `${formattedStartDateTime}～${endDateTimeMoment.format('HH:mm:ss')}`;
            } else {
                // 不同日

            }
        } else {
            // 不同年
            result = `${formattedStartDateTime}～${endDateTimeMoment.format('YYYY年M月D日 HH:mm:ss')}`;
        }
    }

    if (!enableSecond) {
        result = result.slice(0, -3);
    }

    return result;
}

export const isEmpty = value => _.isEmpty(value);  // 是否为空对象、数组等
export const isArray = value => _.isArray(value);  // 是否为数组
export const isObject = value => _.isObject(value); // 是否为对象

export const lastDays = num => {
    let date = new Date();
    let lw = new Date(date - 1000 * 60 * 60 * 24 * num);//最近30天（num=29）
    let lastY = lw.getFullYear();
    let lastM = lw.getMonth() + 1;
    let lastD = lw.getDate();
    let startdate = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD);
    return startdate;
}

export const timeString = date => {
    let yearDate = date.getFullYear();
    let monthDate = (date.getMonth() + 1).toString().padStart(2, '0');
    let dayDate = date.getDate().toString().padStart(2, '0');
    let hourDate = date.getHours();
    let minDate = date.getMinutes();
    let secDate = date.getSeconds();
    let dateString = `${yearDate}年${monthDate}月${dayDate}日${hourDate}时${minDate}分${secDate}秒`;
    return dateString;
}

export const todayDateString = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

// 获取背景颜色
export const getBannerColor = (url) => {
    return new Promise((resolve, reject) => {
        if (!url) {
            reject("url不能为空")
        }
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous')
        image.crossOrigin='Anonymous'
        image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            const imgData = ctx.getImageData(0, 0, 1, 1);
            const data = imgData.data;
            resolve(`rgb(${data[0]},${data[1]},${data[2]})`)
        };
        image.onerror = () => {
            reject("图片加载失败")
        }
        image.src = `${url}?tamp=${new Date().valueOf()}`
    })
}

// 校验文件大小
//最大
export const getMaxFileWidthAndHeight = (file, initWidth, initHeight) => {
    return new Promise((resolve, reject) => {
        let width = initWidth;
        let height = initHeight;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
            console.log(1);
            if (img.width > width || img.height > height) {
                message.error(`上传尺寸最大${initWidth}px * ${initHeight}px!`);
                reject();
            } else {
                resolve();
            }
        };
    });
};
//最小
export const getMinFileWidthAndHeight = (file, initWidth, initHeight) => {
    return new Promise((resolve, reject) => {
        let width = initWidth;
        let height = initHeight;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
            console.log(1);
            if (img.width < width || img.height < height) {
                message.error(`上传尺寸最小${initWidth}px * ${initHeight}px!`);
                reject();
            } else {
                resolve();
            }
        };
    });
};

// 递归遍历数组 修改元素
export const TreeDataSource = (arr) => {
    if (!Array.isArray(arr)) {
        return;
    }
    return arr.map((item) => {
        return {
            ...item,
            title: item.name,
            value: item.id,
            key: item.id,
            children: item.childList && TreeDataSource(item.childList)
        }
    });
};

// 递归treedata找到指定对象
export const getTreeObj = (data, key) => {
    for (let i in data) {
        if (data[i].moduleId === key) {
            return data[i];
        }
        if (data[i].children) {
            let node = getTreeObj(data[i].children, key);
            if (node !== undefined) {
                return node;
            }
        }
    }
};

// 排序树找到该节点的所有父节点
export const getParentIdList = (list, id) => {
    if (!list || !id) {
        return ''
    }
    let arr = [];
    let findParent = (data, nodeId, parentId) => {
        for (var i = 0, length = data.length; i < length; i++) {
            let node = data[i];
            //由于后端返回的树状结构数组⾥⾯的value值为string类型,所以为了避免判断全等有问题,所以将传⼊的城市id使⽤toString进⾏了类型转换
            if (node.key === nodeId) {
                arr.unshift(nodeId);
                if (nodeId === list[0].key) {
                    break
                }
                findParent(list, parentId);
                break
            } else {
                if (node.children) {
                    findParent(node.children, nodeId, node.key);
                }
                continue;
            }
        }
        return arr;
    }
    return findParent(list, id);
};

export const regRichText = (data) => {
    let imgreg = /<img.*?\/?>/gi;
    let reg = /<\/?.+?\/?>/g;
    data = data.replace(imgreg, "[图片]").replace(reg, "");
    return data;
};

export const emptyRichText = (data) => {
    let content
    if(!data) return data;
    content = data.replace(/[ 　\t\r\n]+/g, '').replace(/<br *\/?>/g, '').replace(/<\/?p>/g, '')
    if(content === '') return content;
    else return data;
};


export const areasString = (() => {
    let province = [],
        city = [],
        district = [];

    areas.forEach(item => {
        province.push({value: item.value, label: item.label});
        if (item.children) {
            item.children.forEach(it => {
                city.push({value: it.value, label: it.label});
                if (it.children) {
                    it.children.forEach(i => {
                        district.push({value: i.value, label: i.label});
                    });
                }
            });
        }
    });

    return {
        province,
        city,
        district,
    };
})();

// 文件上传前缀
export const getFileUrl = (url) => {
    if (url && url.startsWith('http')) {
        return url;
    }
    if (url && !url.startsWith('http')) {
        return config.OSS_PREFIX + url;
    }
    return null;
};

// 健康界资源前缀
export const getJklFileUrl = (url) => {
    if (url && url.startsWith('http')) {
        return url;
    }
    if (url && !url.startsWith('http')) {
        return config.FILE_PREFIX + url;
    }
    return null;
};

// 基于 BC_OSS_URL_PREFIX 拼接完整 URL
export const prepareBCOSSUrl = path => {
    let url = '';

    if (!!path && _.isString(path)) {
        if (path.startsWith('http')) {
            url = path;
        } else if (path.startsWith('/')) {
            url = `${config.BC_OSS_URL_PREFIX}${path}`;
        } else {
            url = `${config.BC_OSS_URL_PREFIX}/${path}`;
        }

        url = url.replace(`${config.BC_OSS_BUCKET}`, config.BC_OSS_URL_PREFIX);
    }

    return url;
};


// 获取文件blob格式
const getFileBlob = (url) => {
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = "blob";
        request.onload = (res) => {
            if (res.target.status === 200) {
                resolve(res.target.response);
            } else {
                reject(res);
            }
        }
        request.send();
    })
};

// 多个文件下载(合并文件夹)
export const downLoadMultiFile = (files, filesName) => {
    message.warn('下载中');

    const zip = new JSZip();
    let result = [];
    for (let i in files) {
        let promise = getFileBlob(files[i].url).then((res) => {
            zip.file(files[i].name, res, { binary: true });
        });
        result.push(promise);
    }
    Promise.all(result).then(() => {
        zip.generateAsync({ type: "blob" }).then((res) => {
            saveAs(res, filesName);
            message.success('下载完成，请在浏览器下载内容处查看');
        })
    })
};

// 截取地址栏参数
export function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

// 项目编号
// 医项目 YXM000001，即 YXM 后加“前位补0的6位数字”
// 医评选 YPX000001，同上
// 医会议 YHY000001，同上
export const numberProject = (projectType, no) => projectType.toUpperCase() + ('000000' + no).slice(-6);

// 格式化金额
export const formatCurrency = value => {
    let result = '';

    const f = parseFloat(value) || 0.00;
    const parts = f.toFixed(2).split('.');
    result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

    return result;
};

// 滚动到表单中的第1处错误位置
export const scrollToFirstFormItemHasError = () => {
    const errorElements = document.getElementsByClassName('ant-form-item-has-error');
    if (errorElements.length) {
        const ele = errorElements[0];
        const eleTop = ele.getBoundingClientRect().top;
        const headerOffset = 48;
        const offsetPosition = eleTop + window.scrollY - headerOffset;
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
};
