import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { SearchOutlined, ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Layout, Form, Row, Col, Input, Button, Space, Select, Table, TreeSelect, DatePicker,Tooltip } from 'antd';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { useLocation } from 'react-router-dom';
import "./AuthUsers.less"
import { get, } from '@/core/request';
import moment from 'moment';

const { Content } = Layout;
export default function BiChannelUsers() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const { RangePicker } = DatePicker;
    const [form1] = Form.useForm();
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    let [num, setNum] = useState(0);
    let location = useLocation();
    let pathname = location.state;
    const [channelType, setChannelType] = useState("");
    const [treeData, setTreeData] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [memJobtitleData, setMemJobtitleData] = useState(null);//职称
    const [memPostData, setMemPostData] = useState(null);//职务
    const [showElement, setShowElement] = useState(false)
    const [sourceType, setSourceType] = useState(null);
    const [formData, setFormData] = useState({ startTime: "", endTime: "", memCard: "", memNickname: "", memMobile: "", memUnit: "", memOffice: "", memPost: "", memJobtitle: "", contentId: "", contentType: "", contentTitle: "" });
    const onChangeSelect = (value) => {
        if (value) { setShowElement(true) } else {
            setShowElement(false)
        }
    }
    useEffect(() => {
        get(`/channel/dictionary/office/detail`).then(res => {
            const result = deepCloneDisabled(res.data);
            setTreeData(result)
        }, (err) => {
            console.log(err)
        })
        get(`/channel/dictionary/source/type`).then((res) => {
            if (res.status_code == 200) {
                let arr = []
                for (var a in res.data) {
                    arr.push(res.data[a])
                }
                setSourceType(arr)
            }
        }, (err) => {
            console.log(err)
        })
    }, [])
    let [falg, setFalg] = useState(true)
    const onReset = () => {
        setFalg(false)
        setFormData({ ...formData, memCard: "", memNickname: "", memMobile: "", memUnit: "", memOffice: "", memPost: "", memJobtitle: "", contentId: "", contentType: "", contentTitle: "" })

        setPage(1)
        form1.resetFields();
        form1.setFieldsValue({ time: "" })
        setNum(++num)
    };
    const columns = [
        { title: '用户唯一标识', dataIndex: 'memCard', },
        {
            title: '用户昵称', dataIndex: 'memNickname',
        },
        {
            title: '手机号', dataIndex: 'memMobile',
        },
        {
            title: '用户身份', dataIndex: 'memIdentityName',
        },
        {
            title: '会员类别', dataIndex: 'vipLevel',
        },
        {
            title: '单位名称', dataIndex: 'memUnit',
        },
        {
            title: '科室', dataIndex: 'memOfficeName',
        },
        {
            title: '职务', dataIndex: 'memPostName',
        },
        {
            title: '职称', dataIndex: 'memJobtitleName',
        },
        {
            title: '认证提审时间', dataIndex: 'authTime',
        },
        {
            title: '认证审核时间', dataIndex: 'examineTime',
        },
        {
            title: '转化来源类型', dataIndex: 'contentType',
        },
        {
            title:
                <div>实体ID&nbsp;
                    <Tooltip placement='top' title={"对应所选转化来源类型的实体ID，如选PGC文章时，即PGC文章ID"}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
            dataIndex: 'contentId',
        },
        {
            title: "实体标题",
            dataIndex: 'contentTitle',
        },
        {
            title: '来源URL', dataIndex: 'url',
        },
    ];
    const onChangeTreeSelect = value => {
        console.log(value)
        setChannelType(value)
    };
    const clickBackHandler = () => {
        window.history.back(-1);
    }
    const onFinish = (values) => {
        setFormData({ ...formData, memCard: values.memCard || "", memNickname: values.memNickname || "", memMobile: values.memMobile || "", memUnit: values.memUnit || "", memOffice: values.memOffice || "", memPost: values.memPost || "", memJobtitle: values.memJobtitle || "", contentType: values.contentType || "", contentTitle: values.contentTitle || "", contentId: values.contentId || "" })

        setNum(++num)
    }
    function deepCloneDisabled(result) {
        for (let i = 0; i < result.length; i++) {
            result[i].title = result[i].dicName
            result[i].value = result[i].dicCode
            result[i].disabled = true
            if (result[i].children.length) {
                result[i].children.forEach(item => {
                    item.title = item.dicName
                    item.value = item.dicCode
                })
            }
        }
        return result
    }
    const handleChangeTime = (value, dateString) => {
        setFalg(false)
        setFormData({ ...formData, startTime: dateString[0], endTime: dateString[1] })
    }
    useEffect(() => {
        if (pathname && pathname.showTime) {
            onReset()
        }
    }, [])
    useEffect(() => {
        if (pathname) {
            let { startTime, endTime, memCard, memNickname, memMobile, memUnit, memOffice, memPost, memJobtitle, contentType, contentTitle, contentId } = formData;
            get(`/channel/statistics/authUser`, {
                channelId: pathname.channelId,
                startTime: falg ? pathname.startTime : startTime,
                endTime: falg ? pathname.endTime : endTime,
                page,
                limit,
                memCard,
                memNickname,
                memMobile,
                memUnit,
                memOffice,
                memPost,
                memJobtitle,
                contentType,
                contentTitle,
                contentId
            }).then((res) => {
                if (res.status_code == 200) {
                    setDataSource(res.data)
                }
            }, (err) => {
                console.log(err)
            })
        }
    }, [limit, page, num])
    useEffect(() => {
        get(`/channel/dictionary/board?type=3`).then(res => {
            setMemPostData(res.data)
        })
        get(`/channel/dictionary/board?type=4`).then(res => {
            setMemJobtitleData(res.data)
        })
    }, [])
    const changeCurrPage = useCallback(
        (page) => {
            setPage(page);
        },
        [],
    );
    const changePageSize = useCallback(
        (pageSize) => {
            setLimit(pageSize);
        },
        [],
    );
    const paginationProps = {
        current: page,
        showTotal: () => `共${dataSource.totalCount}条记录`,
        showSizeChanger: true,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100', '1000'],
        size: 'small',
        total: dataSource && dataSource.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) }
    };
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title"><ArrowLeftOutlined onClick={clickBackHandler} /> 渠道认证用户<span className='styleColor'> {pathname.name} [#{pathname.channelId}]</span> </div>
                <div className="main-content-body">
                    <Form {...filterFormItemLayout} onFinish={onFinish} form={form1}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="用户唯一标识" name="memCard">
                                    <Input allowClear placeholder="用户唯一标识" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="用户昵称" name="memNickname">
                                    <Input allowClear placeholder="用户昵称" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="memMobile">
                                    <Input allowClear placeholder="手机号" />
                                </Form.Item>
                            </Col>


                            <Col {...filterFormGridLayout}>
                                <Form.Item label="认证审核时间" name="time">
                                    {pathname && <RangePicker
                                        defaultValue={[moment(pathname.startTime, dateFormat), moment(pathname.endTime, dateFormat)]}
                                        format={dateFormat}
                                        showTime
                                        onChange={handleChangeTime}
                                    />}
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="单位名称" name="memUnit">
                                    <Input allowClear placeholder="单位名称关键字" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="科室" name="memOffice">
                                    {treeData && <TreeSelect
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={treeData}
                                        placeholder="选择渠道分类"
                                        onChange={onChangeTreeSelect}
                                    />}
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="职务" name="memPost">
                                    <Select allowClear placeholder="选择职务" >
                                        {memPostData && memPostData.map(item => {
                                            return <Select.Option value={item.dicCode}>{item.dicName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="职称" name="memJobtitle">
                                    <Select allowClear placeholder="选择职称" >
                                        {memJobtitleData && memJobtitleData.map(item => {
                                            return <Select.Option value={item.dicCode}>{item.dicName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="转化来源类型" name="contentType">
                                    <Select allowClear placeholder="转化来源类型" onChange={onChangeSelect}>
                                        {sourceType && sourceType.map((item, i) => {
                                            return <Select.Option value={i + 1}>{item}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {showElement && <Col {...filterFormGridLayout}>
                                <Form.Item label="实体ID"  name="contentId" tooltip={{ title: '对应所选转化来源类型的实体ID，如选PGC文章时，即PGC文章ID', icon: <InfoCircleOutlined /> }}>
                                    <Input allowClear placeholder="转化来源实体ID" />
                                </Form.Item>
                            </Col>}
                            {showElement && <Col {...filterFormGridLayout}>
                                <Form.Item label="实体标题"  name="contentTitle">
                                    <Input allowClear placeholder="实体标题关键字" />
                                </Form.Item>
                            </Col>}
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="table-title">渠道认证用户列表</div>
                    </div>
                    {dataSource && <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataSource.list}
                        pagination={paginationProps}
                    />}
                </div>
            </Content>
        </Fragment>
    )

}
