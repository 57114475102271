import React, { Fragment, useState, useEffect } from 'react';
import { SearchOutlined, ReloadOutlined, AudioOutlined, DownloadOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Layout, Form, Row, Col, Input, Button, Space, Tree, Select, Menu, Dropdown, Table, message, Modal, TreeSelect, Drawer } from 'antd';
import { filterFormItemLayout, filterFormGridLayout, pageFormItemWithoutLabelGridLayout, drawerWidth } from '@utils/property';
import './ChannelCates.less';
import QRCode from 'qrcode.react';
import { get, del, post, delte } from '@/core/request';
import TreeElemen from "./Tree.jsx"
import { values } from 'lodash';
import myContextContext from './createContext'; //公共组件
const { Content } = Layout;
export default function OperationChannelCates() {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [typeID, setTypeID] = useState("")
    const [formSort] = Form.useForm();
    const [isShowAddType, setIsShowAddType] = useState(false);
    const [isShowEdit, setIsShowEdit] = useState(false);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [isShowChildren, setIsShowChildren] = useState(false);
    const [valueSelectChannel, setValueSelectChannel] = useState("");
    const [valueSelectType, setValueSelectType] = useState("");
    const [gData, setGData] = useState(null);
    const [listTypeTree, setListTypeTree] = useState([]);
    let [num, setNum] = useState(0)


    function deepType(arr) {
        let data = [];
        for (let i = 0; i < arr.length; i++) {
            arr[i].title = arr[i].name
            arr[i].key = arr[i].id
            let children = arr[i]
            let obj = { title: arr[i].name, key: arr[i].id, pid: arr[i].pid, id: arr[i].id, name: arr[i].name, value: arr[i].id }
            let a = []
            if (children.children.length) {
                for (let k = 0; k < children.children.length; k++) {
                    a.push({ title: children.children[k].name, key: children.children[k].id, value: children.children[k].id, pid: children.children[k].pid, id: children.children[k].id, name: children.children[k].name })
                    obj.children = a
                }
            }
            data.push(obj)
        }
        return data
    }
    const [openElement, setOpenElement] = useState(true)
    // 全部展开
    const handleOpen = () => {
        setOpenElement(true)
    }
    // 全部收起
    const handlePutAway = () => {
        setOpenElement(false)
    }
    // Menu点击
    const handleMenuClick = (value, key) => {
        if (key.key === "status") {
            setIsShowChildren(true)
            form3.setFieldsValue({ pid: value.id || "" })
        } else {
            Modal.confirm({
                content: '确定要删除该分类？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let form = new FormData();
                    form.append("id", value.id)
                    delte("/channel/channel-group/delete", form).then(res => {
                        if (res.status_code == 200) {
                            message.info("渠道分类删除成功！")
                            setNum(++num)
                        }
                    })
                }
            });


        }
    }
    const handleMouseEnter = (e) => {
        if (e.target.className === 'edit') {
            e.target.classList.add('mystyle')
        }
    }
    const handleMouseLeave = (e) => {
        e.target.classList.remove('mystyle')
    }
    const handleMouseLeaveInner = (e) => {
        e.currentTarget.parentNode.classList.remove('mystyle')
    }
    const rendertitle = (item, flag, num, nameClass, isDel) => {
        const menu = (
            <Menu onClick={handleMenuClick.bind(this, item)}>
                {flag && <Menu.Item key="status">{"新增子类"}</Menu.Item>}
                {!isDel && <Menu.Item key="delete">删除</Menu.Item>}
            </Menu>
        );
        return (
            <div className="edit" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                {item.name}
                {<div style={{ left: num }} className={nameClass} onMouseLeave={handleMouseLeaveInner}>
                    <Dropdown.Button overlay={menu} size='small' onClick={handleEdit.bind(this, item)}>
                        编辑
                    </Dropdown.Button>
                </div>}
            </div>
        )
    };
    useEffect(() => {
        get(`/channel/channel-group/listTree`).then(res => {
            if (res.status_code == 200) {
                res.data.forEach(item => {
                    item.key = item.id
                    if (item.children.length) {
                        item.title = rendertitle(item, true, 200, 'tree-edit', true)
                        item.children.forEach(list => {
                            list.key = list.id;
                            if (list.children.length) {
                                list.title = rendertitle(list, true, 200, 'tree-edit', true);
                                list.children.forEach(obj => {
                                    obj.key = obj.id
                                    if (obj.children.length) {
                                        obj.title = rendertitle(obj, false, 300, 'tree-edit', true)
                                    } else {
                                        obj.title = rendertitle(obj, false, 300, 'tree-edit', false)
                                    }
                                })
                            } else {
                                list.title = rendertitle(list, true, 200, 'tree-edit', false);
                            }

                        })
                    } else {
                        item.title = rendertitle(item, true, 300, 'tree-edit', false)
                    }

                })
                setGData(res.data)
            }
        })
    }, [num])
    const onChangeTreeSelectChannel = (value, a) => {
        console.log(listTypeTree)
        console.log("新增", value, a)
        setValueSelectChannel(value)
    }
    const onChangeTreeSelectType = (value, a) => {
        console.log(listTypeTree)
        console.log("编辑分类", value, a)
        setValueSelectType(value)
    }
    // 编辑
    const handleEdit = (item) => {
        setTypeID(item.id)
        setIsShowEdit(true)
        form2.setFieldsValue({ name: item.name, pid: item.pid || "" })
    }
    // 新增分类
    const addType = () => {
        setIsShowAddType(true)
    }
    const handleCancelAddType = () => {
        setIsShowAddType(false)
        setIsShowEdit(false)
        setIsShowChildren(false)
        form1.resetFields()
        form2.resetFields()
        form3.resetFields()
    }
    const onCloseDrawer = () => {
        setIsShowDrawer(false)
    }
    const onFinishAddType = (values) => {
        post("/channel/channel-group/save", values).then(res => {
            if (res.status_code == 200) {
                message.info(res.message)
                setIsShowAddType(false)
                form1.resetFields()
                setNum(++num)
            }
        })
    }
    const onFinishChildren = (values) => {
        post("/channel/channel-group/save", values).then(res => {
            if (res.status_code == 200) {
                message.info(res.message)
                setIsShowChildren(false)
                form3.resetFields()
                setNum(++num)
            }
        })
    }
    const onFinishEdit = (values) => {
        values.id = typeID
        post("/channel/channel-group/edit", values).then(res => {
            if (res.status_code == 200) {
                message.info(res.message)
                setIsShowEdit(false)
                form2.resetFields()
                setNum(++num)
            }
        })
    }
    const onFinishSort = () => {
        console.log("编辑渠道")
    }
    const showDrawerSort = () => {
        setIsShowDrawer(true)
    }
    const getClose = (value) => {
        setIsShowDrawer(value)
    }
    useEffect(() => {
        get(`/channel/channel-group/listTree`).then(res => {
            if (res.status_code == 200) {
                let list = deepType(res.data)
                setListTypeTree(list)
            }
        })
    }, [num])
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title">渠道分类</div>
                <div className="main-content-body">
                    <Form {...filterFormItemLayout}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item >
                                    <Space>
                                        <Button type="primary" onClick={addType}>新增分类</Button>
                                        <Button type="default" onClick={handleOpen}>全部展开</Button>
                                        <Button type="default" onClick={handlePutAway}>全部收起</Button>
                                        <Button type="default" onClick={showDrawerSort}>分类排序</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {gData && openElement && <Tree
                        blockNode
                        defaultExpandAll={openElement}
                        treeData={gData}
                    // selectable={false}
                    />}
                    {gData && openElement === false && <Tree
                        blockNode
                        defaultExpandAll={openElement}
                        treeData={gData}
                    // selectable={false}
                    />}

                </div>
                {isShowAddType && <Modal title="新增分类" visible={isShowAddType} onCancel={handleCancelAddType} footer={null}>
                    <Form {...filterFormItemLayout} onFinish={onFinishAddType} form={form1}>
                        <Form.Item label="分类名称"
                            rules={[{ required: true, message: '输入渠道名称' }]} name="name">
                            <Input allowClear placeholder="输入渠道名称" />
                        </Form.Item>

                        <Form.Item label="上级分类" rules={[{ required: false, message: '选择所属上级渠道分类' }]} name="pid">
                            {listTypeTree && <TreeSelect
                                style={{ width: '100%' }}
                                value={valueSelectChannel}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={listTypeTree}
                                placeholder="选择渠道分类"
                                treeDefaultExpandAll={true}
                                onChange={onChangeTreeSelectChannel}
                            />}
                        </Form.Item>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button onClick={handleCancelAddType}>取消</Button>
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>}
                {isShowChildren && <Modal title="新增子类" visible={isShowChildren} onCancel={handleCancelAddType} footer={null}>
                    <Form {...filterFormItemLayout} onFinish={onFinishChildren} form={form3}>
                        <Form.Item label="分类名称"
                            rules={[{ required: true, message: '输入渠道名称' }]} name="name">
                            <Input allowClear placeholder="输入渠道名称" />
                        </Form.Item>

                        <Form.Item label="上级分类" rules={[{ required: true, message: '选择所属上级渠道分类' }]} name="pid">
                            {listTypeTree && <TreeSelect
                                style={{ width: '100%' }}
                                value={valueSelectChannel}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={listTypeTree}
                                placeholder="选择渠道分类"
                                treeDefaultExpandAll={true}
                                onChange={onChangeTreeSelectChannel}
                            />}
                        </Form.Item>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button onClick={handleCancelAddType}>取消</Button>
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>}
                {isShowEdit && <Modal title="编辑分类" visible={isShowEdit} onCancel={handleCancelAddType} footer={null}>
                    <Form {...filterFormItemLayout} onFinish={onFinishEdit} form={form2}>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <p>分类ID：{typeID}</p>
                        </Form.Item>
                        <Form.Item label="分类名称"
                            rules={[{ required: true, message: '输入渠道名称' }]} name="name">
                            <Input allowClear placeholder="输入渠道名称" />
                        </Form.Item>

                        <Form.Item label="上级分类" rules={[{ required: true, message: '选择所属上级渠道分类' }]} name="pid">
                            {listTypeTree && <TreeSelect
                                style={{ width: '100%' }}
                                value={valueSelectType}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={listTypeTree}
                                placeholder="选择渠道分类"
                                treeDefaultExpandAll={true}
                                onChange={onChangeTreeSelectType}
                            />}
                        </Form.Item>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button onClick={handleCancelAddType}>取消</Button>
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>}
                {isShowDrawer && <Drawer title="渠道分类排序" placement="right" onClose={onCloseDrawer} visible={isShowDrawer} width={drawerWidth}>

                    <myContextContext.Provider>
                        {/* 接收子组件传过来的值 */}
                        <TreeElemen setClose={getClose} />
                    </myContextContext.Provider>
                </Drawer>}
            </Content>
        </Fragment>
    );
}
