module.exports = [
    {id: '01', value: '汉族'},
    {id: '02', value: '蒙古族'},
    {id: '03', value: '满族'},
    {id: '04', value: '朝鲜族'},
    {id: '05', value: '赫哲族'},
    {id: '06', value: '达斡尔族'},
    {id: '07', value: '鄂温克族'},
    {id: '08', value: '鄂伦春族'},
    {id: '09', value: '回族'},
    {id: '10', value: '东乡族'},
    {id: '11', value: '土族'},
    {id: '12', value: '撒拉族'},
    {id: '13', value: '保安族'},
    {id: '14', value: '裕固族'},
    {id: '15', value: '维吾尔族'},
    {id: '16', value: '哈萨克族'},
    {id: '17', value: '柯尔克孜族'},
    {id: '18', value: '锡伯族'},
    {id: '19', value: '塔吉克族'},
    {id: '20', value: '乌孜别克族'},
    {id: '21', value: '俄罗斯族'},
    {id: '22', value: '塔塔尔族'},
    {id: '23', value: '藏族'},
    {id: '24', value: '门巴族'},
    {id: '25', value: '珞巴族'},
    {id: '26', value: '羌族'},
    {id: '27', value: '彝族'},
    {id: '28', value: '白族'},
    {id: '29', value: '哈尼族'},
    {id: '30', value: '傣族'},
    {id: '31', value: '傈僳族'},
    {id: '32', value: '佤族'},
    {id: '33', value: '拉祜族'},
    {id: '34', value: '纳西族'},
    {id: '35', value: '景颇族'},
    {id: '36', value: '布朗族'},
    {id: '37', value: '阿昌族'},
    {id: '38', value: '普米族'},
    {id: '39', value: '怒族'},
    {id: '40', value: '德昂族'},
    {id: '41', value: '独龙族'},
    {id: '42', value: '基诺族'},
    {id: '43', value: '苗族'},
    {id: '44', value: '布依族'},
    {id: '45', value: '侗族'},
    {id: '46', value: '水族'},
    {id: '47', value: '仡佬族'},
    {id: '48', value: '壮族'},
    {id: '49', value: '瑶族'},
    {id: '50', value: '仫佬族'},
    {id: '51', value: '毛南族'},
    {id: '52', value: '京族'},
    {id: '53', value: '土家族'},
    {id: '54', value: '黎族'},
    {id: '55', value: '畲族'},
    {id: '56', value: '高山族'},
];
