import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { Button, Layout, Table, Space, Form, Input, Select, Tooltip, Modal, message } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fullPagerConfig } from '@/utils/property';
import { listTemplateApi, releaseTemplateApi, copyTemplateApi } from '@services/Wj/Template'
import Createtemplate from './Createtemplate';
import './index.less'
import config from '@config';
const { Option } = Select

export default function Templates() {

	const [form] = Form.useForm();
	const { Content } = Layout;
	const navigate = useNavigate()
	// 分页设置
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);

	const [filObj, setFilObj] = useState({});
	const [data, setData] = useState([])

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
	const [curItem, setCurItem] = useState(undefined)

	//分页
	const onChangePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	};

	const onPreviewClick = (item) => {
		setCurItem(item)
		setIsPreviewModalOpen(true)
	}

	const onCopyClick =async (id) => {
		let res = await copyTemplateApi({id})


		if(res.status_code === 200){
			message.success('模版复制成功，在首页第一条数据')
			const {name, type, status} = filObj
			getListData(page, pageSize, name, type, status)
		}
	}

	const onFormFinish = (res) => {
		setFilObj({
			name: res?.name || '',
			type: res?.type || '',
			status: res?.status || '',
		})
	}

	const onReset = () => {
		form.resetFields()
		setFilObj({
			name:  '',
			type:  0,
			status: 0,
		})
	}

	const showModal = () => {
		setIsModalOpen(true);
	};

	const changeStatus =async (status, id) => {
		let res = await releaseTemplateApi({
			id,
			status
		})

		console.log(res, '=====');

		if(res.status_code === 200){
			const {name, type, status} = filObj
			getListData(page, pageSize, name, type, status)
		}
		if(res.status_code === 200){
			if(status === 1){
				message.success('上架成功')
			}else{
				message.success('下架成功')
			}
		}
	}

	const gotoEdit = (row) => {
		navigate(`/wj/surveys/edit/${row.id}`,{state:{route:"newCreate",id:row.id,type:row.surveyTypeId,surveyStatus:row.status,action:'edit'}})
	}

	const columns = [
		{
			title: "模版ID",
			dataIndex: "id",
			key: "id",
			width: 100,
			align: "center",
		},
		{
			title: '模版名称',
			dataIndex: 'name',
			key: 'name',
			width: 400,
			align: 'center',
		},
		{
			title: '问卷类型',
			dataIndex: 'typeName',
			key: 'typeName',
			width: 150,
			align: 'center',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			width: 150,
			align: 'center',
			render: (status, row) => (
				<Space size='middle'>
					{status === 0 && <span>未上架</span>}
					{status === 1 && <span>已上架</span>}
					{status === 2 && <span>已下架</span>}
				</Space>
			),
		},
		{
			title: '创建人',
			dataIndex: 'createdByStaffName',
			key: 'createdByStaffName',
			width: 150,
			align: 'center',
		},
		{
			title: '题目数量',
			dataIndex: 'questions',
			key: 'questions',
			width: 150,
			align: 'center',
		},
		{
			title: '引用次数',
			dataIndex: 'referenceTimes',
			key: 'referenceTimes',
			width: 150,
			align: 'center',
		},
		{
			title: '创建时间',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 150,
			align: 'center',
		},
		{
			title: '操作',
			dataIndex: 'status',
			key: 'action',
			fixed: 'right',
			width: 350,
			align: 'center',
			render: (state, row) => (
				<Space size='middle'>
					<Button type='link' onClick={() => onPreviewClick(row)}>预览</Button>
					<Button type='link'  onClick={() => onCopyClick(row.id)}>复制</Button>
					<Button type='link'  onClick={() => gotoEdit(row)}>编辑</Button>
					{(state === 0 || state === 2) && <Button type='link' onClick={() => changeStatus(1, row.id)} >上架</Button>}
					{state === 1 && <Button type='link' onClick={() => changeStatus(2, row.id)} >下架</Button>}
				</Space>
			),
		},
	];

	const getListData =async (page, pageSize, name, type, status) => {
		let params = {
			page,
			pageSize,
			name,
			type,
			status
		}

		let res = await listTemplateApi(params)
		if(res.status_code === 200){
			setData(res.data.list)
			setTotal(res.data.totalCount)
		}
	}

	useEffect(() => {
		const {name, type, status} = filObj
		getListData(page, pageSize, name, type, status)
	}, [page, pageSize, filObj])

	return (
		<Fragment>
			<div className='page-title'>问卷模版</div>
			<Content className='main-content-box' id='wj_template_wrap'>
				<div className='main-content-body'>
					<div className='table-caption'>
						<div className='table-toolbar-role'>
							<Form
								layout='inline'
								form={form}
								onFinish={onFormFinish}
								onReset={onReset}
							>
								<Form.Item name='name' label='模版名称:'>
									<Input placeholder='请输入模版名称' />
								</Form.Item>
								<Form.Item name='type' label='问卷类型:'>
									<Select placeholder='请选择问卷类型'
										className='selete_type_wrap'
										options={[
											{value: 0, label: '全部'},
											{value: 1, label: '调研问卷'},
											{value: 2, label: '投票'},
											{value: 3, label: '活动报名'},
										]}
									>
									</Select>
								</Form.Item>
								<Form.Item name='status' label='问卷状态:'>
									<Select placeholder='请选择问卷状态'
										className='selete_type_wrap'
										options={[
											{value: 0, label: '全部'},
											{value: 1, label: '上架'},
											{value: 2, label: '未上架'},
										]}
									>
									</Select>
								</Form.Item>
								<Form.Item>
									<Space>
										<Button type='primary' htmlType='submit'>
											查询
										</Button>
										<Button htmlType='button' onClick={onReset}>
											重置
										</Button>
									</Space>
								</Form.Item>
							</Form>
							<div className='add-template'>
								<Button type='primary' onClick={showModal}>添加模版</Button>
							</div>
						</div>
					</div>
					<Table
						className='template-table'
                        size="small"
						columns={columns}
						dataSource={data}
						pagination={{
							total: total,
							showTotal: () => `共${total}条`,
							current: page,
							pageSize: pageSize,
							onChange: onChangePage,
							showQuickJumper: true,
							showSizeChanger: true,
						}}
					/>
				</div>
				<Createtemplate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
			</Content>

			<Modal
                open={isPreviewModalOpen}
                title={curItem?.name || ''}
                className='share_survey_modal_wrap'
                onCancel={() => setIsPreviewModalOpen(false)}
                footer={null}
            >
                <iframe src={`${config.SURVEY_DOMAIN}/s/${curItem?.id}/answer?type=2 `} frameBorder="0" title='share'></iframe>
            </Modal>
		</Fragment>
	)

}
