import UserGroupsNew from "../components/Uc/UserGroups/UserGroupsNew";
import UserGroups from "../components/Uc/UserGroups/UserGroups";
import Users from "../components/Uc/Users/Users";
import UserOverview from "../components/Bi/UserOverView/UserOverview";
import ActiveUser from "../components/Bi/ActiveUser/ActiveUser";
import AuthUserDepartment from "../components/Bi/AuthUserDepartment/AuthUserDepartment";
import ExpertOverview from '../components/Expert/ExpertOverview';
import Expert from '../components/Expert/Expert';
import AuthOverview from '../components/Bi/Mdm/AuthOverview';
import OnlineOverview from '../components/Bi/Mdm/OnlineOverview';
import Projects from '../components/Bi/Mdm/Projects';
import ConsultationsList from '../components/Uc/Consultations/ConsultationsList';
import Consultations from '../components/Uc/Consultations/Consultations';

// 企业管理相关路由
const ucRoutes = [
    //专家列表
    {path: 'experts', element: <ExpertOverview/>},

    {path: 'experts/new', element: <Expert/>},
    {path: 'experts/:id', element: <Expert/>},

    // 用户分群
    {path: 'user-groups', element: <UserGroups/>},
    // 用户管理
    {path: 'users', element: <Users/>},
    // 新增用户分群
    {path: 'user-groups/new', element: <UserGroupsNew/>},
    // 用户数据总览
    {path: 'user/overview', element: <UserOverview/>},
    // 活跃用户统计
    {path: 'user/active-user', element: <ActiveUser/>},
    // 医务人员科室分布
    {path: 'user/auth-user-department', element: <AuthUserDepartment/>},

    // 决策人认证总览
    {path: 'mdm/auth-overview', element: <AuthOverview/>},
    // 决策人在线总览
    {path: 'mdm/online-overview', element: <OnlineOverview/>},
    // 参与项目统计
    {path: 'mdm/projects', element: <Projects/>},
    // 用户咨询管理
    {path: 'user/consultations', element: <ConsultationsList/>},
    // 用户咨询详情
    {path: 'user/consultations/:id', element: <Consultations/>}
];

export default ucRoutes;
