import { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Drawer,
    Form,
    Space,
    Button,
    Input,
    Select,
    Upload,
    Typography,
    Tooltip,
    message,
    Switch,
    Radio, Popover
} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined, CaretUpOutlined,
    DesktopOutlined,
    MinusCircleOutlined,
    MobileOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    SettingOutlined,
    UploadOutlined
} from '@ant-design/icons';
import './index.less';
import { uploadFileApi } from '@services/SiteModule';
import example1 from '@static/assets/img/example1.jpg';
import example2 from '@static/assets/img/example2.jpg';
import example3 from '@static/assets/img/example3.jpg';
import example4 from '@static/assets/img/example4.jpg';
import example5 from '@static/assets/img/example5.jpg';
import example6 from '@static/assets/img/example6.jpg';
import example7 from '@static/assets/img/example7.jpg';
import example8 from '@static/assets/img/example8.jpg';
import example9 from '@static/assets/img/example9.jpg';
import example10 from '@static/assets/img/example10.jpg';
import example11 from '@static/assets/img/example11.jpg';
import example12 from '@static/assets/img/example12.jpg';
import example13 from '@static/assets/img/example13.jpg';
import example14 from '@static/assets/img/example14.jpg';
import config from "@config";

const { Text, Link } = Typography;

const examples = [example1, example2, example3, example4, example5, example6, example7, example8, example9, example10, example11, example12, example13, example14];

export default ({
    drawerState,
    setDrawerState,
    currentModule,
    updateModuleSettings,
}) => {
    const [form] = Form.useForm();

    // 上传图片list
    const [logoList, setLogoList] = useState([]);
    const [h5LogoList, setH5LogoList] = useState([]);
    const [pcKvList, setPcKvList] = useState([]);
    const [webSplashImagesList, setWebSplashImagesList] = useState([]);
    const [h5SplashImagesList, setH5SplashImagesList] = useState([]);
    const [h5NavBgList, setH5NavBgList] = useState([]);
    const [h5HomeBannerList, setH5HomeBannerList] = useState([]);
    const [webKvSlideImagesList, setWebKvSlideImagesList] = useState([]);
    const [h5BannerSlideImagesList, setH5BannerSlideImagesList] = useState([]);

    // 宫格图片list - 默认6个宫格
    const [gridImages, setGridImages] = useState([{}, {}, {}, {}, {}, {}]);

    // 图片配置宫格必填校验
    const [showGridImageRule, setShowGridImageRule] = useState(false);

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    const formItemLayout = {
        labelCol: {
            sm: { span: 5 },
            // md: { span: 5 },
            lg: { span: 4 },
            xl: { span: 6 },
            // xxl: { span: 2 }
        },
        wrapperCol: {
            sm: { span: 19 },
            // md: { span: 19 },
            lg: { span: 18 },
            xl: { span: 16 },
            // xxl: { span: 22 }
        }
    };

    // 上传文件路径 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 上传头像格式校验
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png/jpeg 格式');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不超过2MB');
            return false;
        }
        return isJpgOrPng && isLt2M;
    };

    // 上传图片(1 - logo，2 - 主视觉图，3 - web开屏广告图，4 - 页面主背景，5 - 首页banner，6 - h5开屏广告，7 - web主图轮播，8 - h5Banner轮播, 9 - h5Logo )
    const upload = async (value, index) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let list = [{ url: getFileUrl(res.data) }];
            switch (index) {
                case 1:
                    form.setFieldValue('logo', list);
                    setLogoList(list);
                    break;
                case 2:
                    form.setFieldValue('pc_kv', list);
                    setPcKvList(list);
                    break;
                case 3:
                    let webSplashList = webSplashImagesList.concat(list);
                    form.setFieldValue('web_splash_images', webSplashList);
                    setWebSplashImagesList(webSplashList);
                    break;
                case 4:
                    form.setFieldValue('h5_nav_bg', list);
                    setH5NavBgList(list);
                    break;
                case 5:
                    form.setFieldValue('h5_home_banner', list);
                    setH5HomeBannerList(list);
                    break;
                case 6:
                    let h5SplashList = h5SplashImagesList.concat(list);
                    form.setFieldValue('h5_splash_images', h5SplashList);
                    setH5SplashImagesList(h5SplashList);
                    break;
                case 7:
                    let webKvSlideList = webKvSlideImagesList.concat(list);
                    form.setFieldValue('web_kv_slide_images', webKvSlideList);
                    setWebKvSlideImagesList(webKvSlideList);
                    break;
                case 8:
                    let h5BannerSlideList = h5BannerSlideImagesList.concat(list);
                    form.setFieldValue('h5_banner_slide_images', h5BannerSlideList);
                    setH5BannerSlideImagesList(h5BannerSlideList);
                    break;
                case 9:
                    form.setFieldValue('h5_logo', list);
                    setH5LogoList(list);
                    break;
                default:
                    return;
            };
        };
    };

    // 开屏广告图remove
    const removeSplashImages = (e, type) => {
        switch (type) {
            case 1:
                let webTargetIndex = webSplashImagesList.findIndex(item => item.url === e.url);
                let webResList = [...webSplashImagesList];
                webResList.splice(webTargetIndex, 1);
                setWebSplashImagesList(webResList);
                form.setFieldValue('web_splash_images', webResList);
                break;
            case 2:
                let h5TargetIndex = h5SplashImagesList.findIndex(item => item.url === e.url);
                let h5ResList = [...h5SplashImagesList];
                h5ResList.splice(h5TargetIndex, 1);
                setH5SplashImagesList(h5ResList);
                form.setFieldValue('h5_splash_images', h5ResList);
                break;
            default:
                return;
        }
    };

    // banner轮播图remove
    const removeKvSlideImages = (e, type) => {
        switch (type) {
            case 1:
                let webTargetIndex = webKvSlideImagesList.findIndex(item => item.url === e.url);
                let webResList = [...webKvSlideImagesList];
                webResList.splice(webTargetIndex, 1);
                setWebKvSlideImagesList(webResList);
                form.setFieldValue('web_kv_slide_images', webResList);
                break;
            case 2:
                let h5TargetIndex = h5BannerSlideImagesList.findIndex(item => item.url === e.url);
                let h5ResList = [...h5BannerSlideImagesList];
                h5ResList.splice(h5TargetIndex, 1);
                setH5BannerSlideImagesList(h5ResList);
                form.setFieldValue('h5_banner_slide_images', h5ResList);
                break;
            default:
                return;
        }
    };

    // logo图 remove
    const removeLogo = (e) => {
        setLogoList([]);
        form.setFieldValue('logo', []);
    };

    // h5 - logo图 remove
    const removeH5Logo = (e) => {
        setH5LogoList([]);
        form.setFieldValue('h5_logo', []);
    };

    // 导航图 remove
    const removeNavBg = (e) => {
        setH5NavBgList([]);
        form.setFieldValue('h5_nav_bg', []);
    };

    // 图片配置 - 宫格上传 (index - 宫格顺序)
    const giridUpload = async (value, index) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let list = [...gridImages];
            console.log(index, gridImages)
            if (index > gridImages.length) {
                list = gridImages.concat([{ url: getFileUrl(res.data) }]);
                setGridImages(list);
            } else {
                list.splice(index - 1, 1, { url: getFileUrl(res.data) });
                setGridImages(list);
            };

            form.setFieldValue('h5_home_grid_images', list);

            let imagesCount = list.every(item => item.url);
            if (imagesCount) {
                setShowGridImageRule(false);
            };
        };
    };

    // 宫格数量变化 - 清空图片配置
    const gridNumberChange = (number) => {
        let initGridImages = [...Array(number)].map(num => ({}));
        setGridImages(initGridImages);
        form.setFieldValue('h5_home_grid_images', initGridImages);
    };

    // 表单提交
    const onFinishSumit = () => {
        form.validateFields().then(values => {
            let params = {
                id: currentModule.id,
                displayTitle: values.displayTitle,
                displayTitleEn: values.displayTitleEn,
                hiddenWeb: values.hiddenWeb,
                hiddenH5: values.hiddenH5,
                data: JSON.stringify({
                    h5_home_banner: deleteFileUid(values.h5_home_banner),
                    h5_home_grid_mode: values.h5_home_grid_mode,
                    h5_home_grid_images: deleteFileUid(values.h5_home_grid_images),
                    h5_home_grid_number: values.h5_home_grid_number,
                    h5_nav_bg: deleteFileUid(values.h5_nav_bg),
                    logo: deleteFileUid(values.logo),
                    h5_logo: deleteFileUid(values.h5_logo),
                    pc_kv: deleteFileUid(values.pc_kv),
                    pc_home_quick_links: values.pc_home_quick_links,
                    web_splash_images: deleteFileUid(webSplashImagesList),
                    h5_splash_images: deleteFileUid(values.h5_splash_images),
                    web_splash_enabled: values.web_splash_enabled,
                    h5_splash_enabled: values.h5_splash_enabled,
                    web_kv_slide_images: deleteFileUid(webKvSlideImagesList),
                    h5_banner_slide_images: deleteFileUid(values.h5_banner_slide_images),
                    web_kv_slide_enabled: values.web_kv_slide_enabled,
                    h5_banner_slide_enabled: values.h5_banner_slide_enabled,
                    web_main_bg_color: values.web_main_bg_color,
                    web_button_color: values.web_button_color,
                    web_foot_bg_color: values.web_foot_bg_color,
                    h5_main_bg_color: values.h5_main_bg_color,
                    h5_button_color: values.h5_button_color,
                    h5_foot_bg_color: values.h5_foot_bg_color,
                    linkType: values.linkType,
                    zh_link: values.zh_link,
                    en_link: values.en_link
                })
            };
            console.log(params)
            updateModuleSettings(params);
        }).catch(err => { console.log(err) });
    };

    // 删除文件生成的uid
    const deleteFileUid = (list) => {
        if (list && list.length) {
            let newList = list.map(item => {
                let newItem = { ...item };
                delete newItem.uid;

                if (newItem.url && newItem.url.includes(config.OSS_PREFIX)) {
                    let re = new RegExp(config.OSS_PREFIX + "(.*)")
                    newItem.url = newItem.url.match(re)[1];
                }
                return newItem
            })
            return newList
        };
        return [];
    };

    // 图片回显添加前缀
    const addOssUrl = (imgList) => {
        if (imgList && imgList.length) {
            let newList = imgList.map(item => {
                let newItem = { ...item };
                if (newItem.url && !newItem.url.includes(config.OSS_PREFIX)) {
                    newItem.url = config.OSS_PREFIX + newItem.url;
                }
                return newItem;
            });
            return newList;
        }
        return [];
    };

    // 表单数据回显
    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            let newData = {};
            if (currentModule.data) {
                newData = { ...JSON.parse(currentModule.data) };
            };
            console.log(newData)
            let logo = addOssUrl(newData.logo) || [];
            let h5_logo = addOssUrl(newData.h5_logo) || [];
            let pc_kv = addOssUrl(newData.pc_kv) || [];
            let web_splash_images = addOssUrl(newData.web_splash_images) || [];
            let h5_splash_images = addOssUrl(newData.h5_splash_images) || [];
            let h5_nav_bg = addOssUrl(newData.h5_nav_bg) || [];
            let h5_home_banner = addOssUrl(newData.h5_home_banner) || [];
            let h5_home_grid_images = addOssUrl(newData.h5_home_grid_images) || [];
            let web_kv_slide_images = addOssUrl(newData.web_kv_slide_images) || [];
            let h5_banner_slide_images = addOssUrl(newData.h5_banner_slide_images) || [];
            setLogoList(logo);
            setH5LogoList(h5_logo);
            setPcKvList(pc_kv);
            setWebSplashImagesList(web_splash_images);
            setH5SplashImagesList(h5_splash_images);
            setH5NavBgList(h5_nav_bg);
            setH5HomeBannerList(h5_home_banner);
            setGridImages(h5_home_grid_images);
            setWebKvSlideImagesList(web_kv_slide_images);
            setH5BannerSlideImagesList(h5_banner_slide_images);

            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path,
                ...newData
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            className="site-module site-module-home"
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 1 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" onClick={onFinishSumit}>确定</Button>
                    </Space>
                </div>
            }
        >

            <Row>
                <Col
                    xs={{ span: 24 }}
                    lg={{ offset: 2, span: 20 }}
                    xl={{ offset: 4, span: 16 }}
                    xxl={{ offset: 5, span: 14 }}
                >

                    <Form
                        name="form"
                        form={form}
                        {...formItemLayout}
                        initialValues={{
                            'web_main_bg_color': '#1677ff',
                            'web_button_color': '#1677ff',
                            'web_foot_bg_color': '#1677ff',
                            'h5_main_bg_color': '#1677ff',
                            'h5_button_color': '#1677ff',
                            'h5_foot_bg_color': '#1677ff',
                            'linkType': 0,
                        }}
                    >

                        <div className="form-subtitle"><SettingOutlined /> 基本配置</div>

                        <Form.Item label="模块类型">
                            {`${currentModule?.title}（${currentModule?.name}）`}
                        </Form.Item>

                        <Form.Item
                            name="displayTitle"
                            label="显示标题"
                            rules={[
                                { required: true, message: '显示标题不能为空' }
                            ]}
                        >
                            <Input placeholder="请输入模块显示标题" />
                        </Form.Item>

                        <Form.Item
                            name="displayTitleEn"
                            label="英文显示标题"
                        >
                            <Input placeholder="请输入模块英文显示标题" />
                        </Form.Item>

                        <Form.Item
                            name="hiddenWeb"
                            label="Web端是否隐藏"
                            rules={[
                                { required: true, },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={0}>显示</Radio>
                                <Radio value={1}>隐藏</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="hiddenH5"
                            label="H5端是否隐藏"
                            rules={[
                                { required: true, },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={0}>显示</Radio>
                                <Radio value={1}>隐藏</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="linkType"
                            label="中英官网切换"
                            rules={[
                                { required: true, },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={0}>关闭</Radio>
                                <Radio value={1}>切换中文官网</Radio>
                                <Radio value={2}>切换英文官网</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) => prevValues.linkType !== curValues.linkType}
                        >
                            {({ getFieldValue }) => {
                                return <>
                                    {getFieldValue('linkType') === 1 && <>
                                        <Form.Item
                                            name='zh_link'
                                            wrapperCol={{
                                                sm: { offset: 5, span: 19 },
                                                lg: { offset: 4, span: 18 },
                                                xl: { offset: 6, span: 16 }
                                            }}
                                            rules={[{ required: true, message: '请输入官网链接' }]}
                                        >
                                            <Input placeholder="请输入中文官网链接" />
                                        </Form.Item>
                                    </>}
                                    {getFieldValue('linkType') === 2 && <>
                                        <Form.Item
                                            name='en_link'
                                            wrapperCol={{
                                                sm: { offset: 5, span: 19 },
                                                lg: { offset: 4, span: 18 },
                                                xl: { offset: 6, span: 16 }
                                            }}
                                            rules={[{ required: true, message: '请输入官网链接' }]}
                                        >
                                            <Input placeholder="请输入英文官网链接" />
                                        </Form.Item>
                                    </>}
                                </>;
                            }}
                        </Form.Item>

                        <div className="form-subtitle"><DesktopOutlined /> Web端配置</div>

                        <Form.Item
                            name="logo"
                            label="Logo"
                            extra="Logo图片，用于主导航展示，JPG/PNG格式，建议高度144px（2倍图，1倍图高度72px）"
                        >
                            <Upload
                                customRequest={(e) => upload(e, 1)}
                                listType="picture-card"
                                fileList={logoList}
                                onRemove={removeLogo}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg, image/png, image/jpg"
                            >

                                <div>
                                    <PlusOutlined />
                                    <div>{logoList && logoList.length ? '更改图片' : '上传图片'}</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            className="required"
                            label="主背景色"
                            extra="用于色块等元素，16进制主元素前景色，如 #1890ff"
                        >
                            <div className="color-field">
                                <Form.Item
                                    noStyle
                                    name="web_main_bg_color"
                                    rules={[
                                        { required: true, message: 'Web端主背景色不能为空' },
                                        { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                    ]}
                                >
                                    <Input style={{ width: 110 }} maxLength={7} />
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const color = getFieldValue('web_main_bg_color');
                                        return !!color && /^#[0-9a-f]{6}$/i.test(color) && (
                                            <div className="color-previewer" style={{ backgroundColor: `${getFieldValue('web_main_bg_color')}` }}></div>
                                        );
                                    }}
                                </Form.Item>
                            </div>

                        </Form.Item>

                        <Form.Item
                            className="required"
                            label="按钮颜色"
                            extra="用于按钮等元素，16进制主元素前景色，如 #1890ff"
                        >
                            <div className="color-field">
                                <Form.Item
                                    noStyle
                                    name="web_button_color"
                                    rules={[
                                        { required: true, message: 'Web端主前景色不能为空' },
                                        { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                    ]}
                                >
                                    <Input style={{ width: 110 }} maxLength={7} />
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const color = getFieldValue('web_button_color');
                                        return !!color && /^#[0-9a-f]{6}$/i.test(color) && (
                                            <div className="color-previewer" style={{ backgroundColor: `${getFieldValue('web_button_color')}` }}></div>
                                        );
                                    }}
                                </Form.Item>
                            </div>

                        </Form.Item>

                        <Form.Item
                            className="required"
                            label="页脚背景色"
                            extra="用于页脚等元素，16进制主元素前景色，如 #1890ff"
                        >
                            <div className="color-field">
                                <Form.Item
                                    noStyle
                                    name="web_foot_bg_color"
                                    rules={[
                                        { required: true, message: 'Web端主前景色不能为空' },
                                        { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                    ]}
                                >
                                    <Input style={{ width: 110 }} maxLength={7} />
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const color = getFieldValue('web_foot_bg_color');
                                        return !!color && /^#[0-9a-f]{6}$/i.test(color) && (
                                            <div className="color-previewer" style={{ backgroundColor: `${getFieldValue('web_foot_bg_color')}` }}></div>
                                        );
                                    }}
                                </Form.Item>
                            </div>

                        </Form.Item>

                        <Form.Item
                            label="主图"
                            name="pc_kv"
                            extra="支持JPG/PNG格式，尺寸1920&times;1080px（版心1200&times;500px，其顶部距图片顶部50px），文件大小不超过2MB"
                            rules={[
                                { required: true, message: 'Web端主图不能为空' }
                            ]}
                        >
                            <Upload
                                customRequest={(e) => upload(e, 2)}
                                listType="picture-card"
                                fileList={pcKvList}
                                showUploadList={{ showRemoveIcon: false }}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg, image/png, image/jpg"
                            >

                                <div>
                                    <PlusOutlined />
                                    <div>{pcKvList && pcKvList.length ? '更改图片' : '上传图片'}</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="快速入口"
                            extra="快速入口展示在PC官网首页主图突出位置，便于快速导航、活动触达，最多添加3个"
                        >
                            <Form.List name="pc_home_quick_links">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        {fields.length < 3 && (
                                            <Button type="link" size="small" icon={<PlusCircleOutlined />} onClick={() => add()}>新增快速入口</Button>
                                        )}

                                        <div className="quick-links">
                                            {fields.map(({ key, name, ...restFields }, index) => (
                                                <div className="quick-link">
                                                    <div className="quick-link-title">
                                                        <Form.Item
                                                            {...restFields}
                                                            name={[name, 'link_title']}
                                                            noStyle
                                                            className="quick-link-title"
                                                        >
                                                            <Input placeholder="快速入口标题" />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="quick-link-url">
                                                        <Form.Item
                                                            {...restFields}
                                                            name={[name, 'link_url']}
                                                            noStyle
                                                        >
                                                            <Input placeholder="快速入口链接" />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="operations">
                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                        {index !== 0 && <ArrowUpOutlined onClick={() => move(index + 1)} />}
                                                        {index < fields.length - 1 && <ArrowDownOutlined onClick={() => move(index - 1)} />}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>

                        <Form.Item
                            label="启用主图轮播"
                            name="web_kv_slide_enabled"
                            valuePropName="checked"
                            extra="Web端主图轮播，启用后上传不超过3张轮播图，和主图一起轮播"
                        >
                            <Switch
                                defaultChecked={form.getFieldValue('web_kv_slide_enabled')}
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                return !!getFieldValue('web_kv_slide_enabled') && (
                                    <Form.Item
                                        label="主图轮播"
                                        name="web_kv_slide_images"
                                        extra="支持JPG/PNG格式，尺寸1920&times;1080px（版心1200&times;500px，其顶部距图片顶部50px），最多上传3张，单文件大小不超过2MB"
                                    >
                                        <Upload
                                            customRequest={(e) => upload(e, 7)}
                                            listType="picture-card"
                                            fileList={webKvSlideImagesList}
                                            beforeUpload={beforeUpload}
                                            accept="image/jpeg, image/png, image/jpg"
                                            onRemove={(e) => removeKvSlideImages(e, 1)}
                                            maxCount={5}
                                        >
                                            {webKvSlideImagesList.length >= 3 ? null :
                                                <div>
                                                    <PlusOutlined />
                                                    <div>上传图片</div>
                                                </div>}
                                        </Upload>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>

                        <Form.Item
                            label="启用开屏广告"
                            name="web_splash_enabled"
                            valuePropName="checked"
                            extra="Web端开屏广告图，打开Web端官网首页，随机展示一个广告图，3s后自动跳过，也可手动立即跳过"
                        >
                            <Switch
                                defaultChecked={form.getFieldValue('web_splash_enabled')}
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                return !!getFieldValue('web_splash_enabled') && (
                                    <Form.Item
                                        label="开屏广告图"
                                        name="web_splash_images"
                                        extra="支持JPG/PNG格式，尺寸1920&times;1080px，最多上传3张图片，单张图片不大于2MB"
                                    >
                                        <Upload
                                            customRequest={(e) => upload(e, 3)}
                                            listType="picture-card"
                                            fileList={webSplashImagesList}
                                            beforeUpload={beforeUpload}
                                            accept="image/jpeg, image/png, image/jpg"
                                            onRemove={(e) => removeSplashImages(e, 1)}
                                            maxCount={5}
                                        >
                                            {webSplashImagesList.length >= 3 ? null :
                                                <div>
                                                    <PlusOutlined />
                                                    <div>上传图片</div>
                                                </div>}
                                        </Upload>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>

                        <div className="form-subtitle"><MobileOutlined /> H5端配置</div>

                        <Form.Item
                            name="h5_logo"
                            label="Logo"
                            extra="Logo图片，用于主导航展示，JPG/PNG格式，建议高度144px（2倍图，1倍图高度72px）"
                        >
                            <Upload
                                customRequest={(e) => upload(e, 9)}
                                listType="picture-card"
                                fileList={h5LogoList}
                                onRemove={removeH5Logo}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg, image/png, image/jpg"
                            >

                                <div>
                                    <PlusOutlined />
                                    <div>{logoList && logoList.length ? '更改图片' : '上传图片'}</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            className="required"
                            label="主背景色"
                            extra="用于顶部导航条背景等元素，16进制主元素背景色，如 #1890ff"
                        >
                            <div className="color-field">
                                <Form.Item
                                    noStyle
                                    name="h5_main_bg_color"
                                    rules={[
                                        { required: true, message: 'H5端主元素背景色' },
                                        { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                    ]}
                                >
                                    <Input style={{ width: 110 }} maxLength={7} />
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const color = getFieldValue('h5_main_bg_color');
                                        return !!color && /^#[0-9a-f]{6}$/i.test(color) && (
                                            <div className="color-previewer" style={{ backgroundColor: `${getFieldValue('h5_main_bg_color')}` }}></div>
                                        );
                                    }}
                                </Form.Item>
                            </div>

                        </Form.Item>

                        <Form.Item
                            className="required"
                            label="按钮颜色"
                            extra="用于按钮等元素，16进制主元素前景色，如 #1890ff"
                        >
                            <div className="color-field">
                                <Form.Item
                                    noStyle
                                    name="h5_button_color"
                                    rules={[
                                        { required: true, message: 'H5端主前景色不能为空' },
                                        { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                    ]}
                                >
                                    <Input style={{ width: 110 }} maxLength={7} />
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const color = getFieldValue('h5_button_color');
                                        return !!color && /^#[0-9a-f]{6}$/i.test(color) && (
                                            <div className="color-previewer" style={{ backgroundColor: `${getFieldValue('h5_button_color')}` }}></div>
                                        );
                                    }}
                                </Form.Item>
                            </div>

                        </Form.Item>

                        <Form.Item
                            className="required"
                            label="页脚背景色"
                            extra="用于页脚等元素，16进制主元素前景色，如 #1890ff"
                        >
                            <div className="color-field">
                                <Form.Item
                                    noStyle
                                    name="h5_foot_bg_color"
                                    rules={[
                                        { required: true, message: 'H5端主前景色不能为空' },
                                        { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                    ]}
                                >
                                    <Input style={{ width: 110 }} maxLength={7} />
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const color = getFieldValue('h5_foot_bg_color');
                                        return !!color && /^#[0-9a-f]{6}$/i.test(color) && (
                                            <div className="color-previewer" style={{ backgroundColor: `${getFieldValue('h5_foot_bg_color')}` }}></div>
                                        );
                                    }}
                                </Form.Item>
                            </div>

                        </Form.Item>

                        <Form.Item
                            label="导航背景图"
                            name="h5_nav_bg"
                            extra="H5端抽屉导航背景图，支持JPG/PNG格式，尺寸750&times;1500，建议使用2倍图，文件大小不超过2MB"
                        >
                            <Upload
                                customRequest={(e) => upload(e, 4)}
                                listType="picture-card"
                                fileList={h5NavBgList}
                                onRemove={removeNavBg}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg, image/png, image/jpg"
                            >

                                <div>
                                    <PlusOutlined />
                                    <div>{h5NavBgList && h5NavBgList.length ? '更改图片' : '上传图片'}</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="首页背景图"
                            name="h5_home_banner"
                            extra={<>
                                请<a href="https://mediafiles.cn-healthcare.com/conference/assets/h5_grid_template/%E5%8C%BB%E4%BC%9A%E8%AE%AEH5%E5%AE%98%E7%BD%91%E5%AE%AB%E6%A0%BC%E6%A8%A1%E6%9D%BF20240510.sketch">下载宫格模板</a>，参照设计首页背景图，文件大小不超过2MB，支持JPG/PNG格式
                            </>}
                            rules={[
                                { required: true, message: '首页背景图不能为空' }
                            ]}
                        >
                            <Upload
                                customRequest={(e) => upload(e, 5)}
                                listType="picture-card"
                                fileList={h5HomeBannerList}
                                showUploadList={{ showRemoveIcon: false }}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg, image/png, image/jpg"
                            >
                                <div>
                                    <PlusOutlined />
                                    <div>{h5HomeBannerList && h5HomeBannerList.length ? '更改图片' : '上传图片'}</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="宫格数量"
                            name="h5_home_grid_number"
                            extra={<>除“首页”外，请按添加的模块数，选择对应宫格数量</>}
                            rules={[
                                { required: true, message: 'H5官网首页宫格数量为必选字段' }
                            ]}
                            shouldUpdate
                        >
                            <Select placeholder="请选择宫格数量" onChange={gridNumberChange}>
                                {[...Array(14)]
                                    .map((_, i) => 1 + i)
                                    .map(i => <Select.Option key={i} value={i}>{i}个宫格</Select.Option>)
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="宫格样式"
                            name="h5_home_grid_mode"
                            initialValue={1}
                            extra={<>
                                “默认样式”，系统生成透明样式宫格，无需上传宫格背景图（<Popover placement="top" title="" content={<><img style={{width: 240}} src="https://mediafiles.cn-healthcare.com/conference/assets/h5_grid_template/h5_grid_nobg_demo.jpg"/></>}><Link>默认样式示例</Link></Popover>）<br/>
                                “自定义背景”，需上传带宫格背景的<Text type="warning">首页背景图</Text>（上述首页背景图字段）
                            </>}
                        >
                            <Radio.Group>
                                <Radio value={1}>默认样式</Radio>
                                <Radio value={2}>自定义背景</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                return !!getFieldValue('h5_home_grid_number') && <>
                                    <Form.Item
                                        className="required"
                                        label="宫格布局展示"
                                        name="h5_home_grid_images"
                                        rules={[
                                            { required: true, message: '' }
                                        ]}
                                    >
                                        <div className="phone-webview-box">
                                            <div className="phone-webview-inner-box">
                                                <div className="phone-webview" style={h5HomeBannerList && h5HomeBannerList.length ? { backgroundImage: `url("${h5HomeBannerList[0].url}")` } : null}>
                                                    <div className="navigation">
                                                        <div className="brand-logo">
                                                            {logoList && logoList.length ? <img src={logoList[0].url} alt="" /> : null}
                                                        </div>
                                                        <div className="nav-trigger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                                            </svg>
                                                        </div>
                                                    </div>

                                                    <div className={
                                                        `banner
                                                         ${(getFieldValue('h5_home_grid_number') <= 5 && getFieldValue('h5_home_grid_number') > 2) && 'banner-five'}
                                                         ${getFieldValue('h5_home_grid_number') <= 2 && 'banner-two'}
                                                        `
                                                    }></div>

                                                    {getFieldValue('h5_home_grid_number') === 1 && (
                                                        <div className="matrix grid_number_one">
                                                            <div className="matrix-row rectangle need-upload"></div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 2 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div>
                                                                    <div className="rectangle need-upload"></div>
                                                                </div>
                                                                <div>
                                                                    <div className="rectangle need-upload"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 3 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div>
                                                                    <div className="square need-upload"></div>
                                                                </div>
                                                                <div>
                                                                    <div className="rectangle need-upload margin-bottom"></div>
                                                                    <div className="rectangle need-upload"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 4 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div>
                                                                    <div className="square need-upload"></div>
                                                                </div>
                                                                <div>
                                                                    <div className="rectangle need-upload margin-bottom"></div>
                                                                    <div className="rectangle need-upload"></div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row block need-upload"></div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 5 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row block need-upload"></div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 6 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 7 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 8 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 9 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 10 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row block need-upload">
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col">
                                                                    <div className="rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 11 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row block need-upload">
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 12 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>

                                                            </div>

                                                            <div className="matrix-row">
                                                                <div className="matrix-col rectangle need-upload">
                                                                </div>
                                                                <div className="matrix-col rectangle need-upload">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 13 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row rectangle need-upload">
                                                                    </div>
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>

                                                            </div>

                                                            <div className="matrix-row">
                                                                <div className="matrix-col rectangle">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col rectangle">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}

                                                    {getFieldValue('h5_home_grid_number') === 14 && (
                                                        <div className="matrix">
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square need-upload">
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row rectangle need-upload">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matrix-row">
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>

                                                                    <div className="matrix-row rectangle need-upload">
                                                                    </div>

                                                                </div>
                                                                <div className="matrix-col square need-upload">
                                                                </div>

                                                            </div>

                                                            <div className="matrix-row">
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>
                                                                    <div className="matrix-row rectangle need-upload">
                                                                    </div>
                                                                </div>
                                                                <div className="matrix-col square">
                                                                    <div className="matrix-row rectangle need-upload">
                                                                    </div>

                                                                    <div className="matrix-row">
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                        <div className="matrix-col square-small need-upload">
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}

                                                </div>

                                                <div className="matrix-tips">
                                                    <img src={examples[getFieldValue('h5_home_grid_number') - 1]} alt="" />
                                                    <p><CaretUpOutlined />关健元素尺寸标注说明</p>
                                                </div>
                                            </div>{/*//.phone-webview-inner-box*/}

                                            {/* {showGridImageRule && <div style={{ color: '#ff4d4f' }}>{`${getFieldValue('h5_home_grid_number')}个宫格图片均不能为空`}</div>} */}
                                        </div>


                                    </Form.Item>
                                </>;
                            }}
                        </Form.Item>

                        <Form.Item
                            label="启用主图轮播"
                            name="h5_banner_slide_enabled"
                            valuePropName="checked"
                            extra="启用主图轮播后，上传不超过3张轮播图，和默认主图一起轮播"
                        >
                            <Switch
                                defaultChecked={form.getFieldValue('h5_banner_slide_enabled')}
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                return !!getFieldValue('h5_banner_slide_enabled') && (
                                    <Form.Item
                                        label="主图轮播"
                                        name="h5_banner_slide_images"
                                        extra="支持JPG/PNG格式，尺寸750&times;520px，最多上传3张，单文件大小不超过2MB"
                                    >
                                        <Upload
                                            customRequest={(e) => upload(e, 8)}
                                            listType="picture-card"
                                            fileList={h5BannerSlideImagesList}
                                            beforeUpload={beforeUpload}
                                            accept="image/jpeg, image/png, image/jpg"
                                            onRemove={(e) => removeKvSlideImages(e, 2)}
                                            maxCount={5}
                                        >
                                            {h5BannerSlideImagesList.length >= 3 ? null :
                                                <div>
                                                    <PlusOutlined />
                                                    <div>上传图片</div>
                                                </div>}
                                        </Upload>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>

                        <Form.Item
                            label="启用开屏广告"
                            name="h5_splash_enabled"
                            valuePropName="checked"
                            extra="开屏广告图，打开H5端官网首页，随机展示一个广告图，3s后自动跳过，也可手动立即跳过"
                        >
                            <Switch
                                defaultChecked={form.getFieldValue('h5_splash_enabled')}
                                checkedChildren="启用"
                                unCheckedChildren="禁用"
                            />
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                return !!getFieldValue('h5_splash_enabled') && (
                                    <Form.Item
                                        label="开屏广告图"
                                        name="h5_splash_images"
                                        extra="支持JPG/PNG格式，尺寸1125&times;1964px，最多上传3张图片，单张图片不大于2MB"
                                    >
                                        <Upload
                                            customRequest={(e) => upload(e, 6)}
                                            listType="picture-card"
                                            fileList={h5SplashImagesList}
                                            beforeUpload={beforeUpload}
                                            accept="image/jpeg, image/png, image/jpg"
                                            onRemove={(e) => removeSplashImages(e, 2)}
                                            maxCount={5}
                                        >
                                            {h5SplashImagesList.length >= 3 ? null :
                                                <div>
                                                    <PlusOutlined />
                                                    <div>上传图片</div>
                                                </div>}
                                        </Upload>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>

                    </Form>

                </Col>
            </Row>

        </Drawer>
    );
};
