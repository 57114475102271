import { get, put } from '@core/request';

export const updatePeopleApi = (params) => {
    return put('/healthclass/boss/organizations/addUserCount', params);
};

export const updateLiveApi = (params) => {
    return put('/healthclass/boss/organizations/addLiveTime', params);
};

export const getProductApi = (params) => {
    return get('/healthclass/saas/versions/list-tree', params);
};

export const getClassPackageApi = (params) => {
    return get('/healthclass/boss/jkjcoursegroups/list', params);
};

export const getConsultationApi = (params) => {
    return get('/healthclass/boss/consultation/page', params);
};
