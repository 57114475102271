import React, { Fragment } from "react";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import config from "@config";
const AnswerSurvey = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/wj/surveys/new/whitelists", { state: location.state });
    };

    return (
        <Fragment>
            <div className="page-title">
                <div className="page-left">
                    <div className="back">
                        <LeftOutlined onClick={goBack} />
                    </div>
                    <span>退出查看</span>
                </div>
                <div className="page-center"></div>
            </div>
            <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${location.state.surveyId}/anwsersheets/${location.state.answerId}`} />
        </Fragment>
    );
};

export default AnswerSurvey;
