const config = {
    API_PREFIX: process.env.REACT_APP_API_PREFIX || '',
    MC_API_PREFIX: process.env.REACT_APP_MC_API_PREFIX || '',
    OSS_PREFIX: process.env.REACT_APP_OSS_URL_PREFIX || '',
    UCENTER_API_PREFIX: process.env.REACT_APP_UCENTER_PREFIX || '',
    PROJECT_DOMAIN: process.env.REACT_APP_PROJECT_DOMAIN || '',
    SURVEY_DOMAIN: process.env.REACT_APP_SURVEY_DOMAIN || '',
    INVITATION_PREFIX: process.env.REACT_APP_INVITATION_PREFIX || '',
    FILE_PREFIX: process.env.REACT_APP_FILE_URL_PREFIX || '',
    ALIYUN_API_PREFIX: process.env.REACT_APP_ALIYUN_API_PREFIX || '',
    HUIYI_DOMAIN: process.env.REACT_APP_HUIYI_DOMAIN || '',
    VIDEOTRANSCODE_PREFIX: process.env.REACT_APP_VIDEOTRANSCODE_PREFIX || '',
    SOLUTION_DOMAIN: process.env.REACT_APP_SOLUTION_DOMAIN || '',
    SELECTION_DOMAIN: process.env.REACT_APP_SELECTION_DOMAIN || '',
    LIVE_RECORD_OSS_URL_PREFIX: process.env.REACT_APP_LIVE_RECORD_OSS_URL_PREFIX || '',
    JKJ_OSS_URL_PREFIX: process.env.REACT_APP_JKJ_OSS_URL_PREFIX || '', // 健康界平台 OSS URL 前缀
    BC_OSS_BUCKET: process.env.REACT_APP_BC_OSS_BUCKET || '', // Battleship Cloud 上传用
    BC_OSS_URL_PREFIX: process.env.REACT_APP_BC_OSS_URL_PREFIX || '', // Battleship Cloud 文件查看前缀
    BC_OSS_URI: process.env.REACT_APP_BC_OSS_URI || '', // Battleship Cloud 文档预览 OSS URI
    UCENTER_PREFIX: process.env.REACT_APP_UCENTER_PREFIX || '',
};

export default config;
