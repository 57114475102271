import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { LeftOutlined, CloseOutlined, DragOutlined } from '@ant-design/icons';
import {
    Input,
    Layout,
    Button,
    Modal,
    Form,
    message,
    DatePicker,
    Radio,
    Space, Image, Checkbox, Row, Upload, Col, Select, Tooltip, Typography,Switch
} from 'antd';
import {
    pageFormItemLayout,
    pageFormItemWithoutLabelGridLayout,
} from '@utils/property';
import { dateTimeFormat, prepareBCOSSUrl, getBannerColor } from '@utils/utils';
import {showMedProject, createMedProject, editMedProject, medProjectFileApi} from '@services/MedProject';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import './MedProject.less';

const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Text } = Typography;

const personOptions = [{ value: 1, label: '医疗机构从业者' },{ value: 2, label: '政府官员' }, { value: 3, label: '教育科研人士' }, { value: 4, label: '商业人士' },{ value: 5, label: '社会团体人士' }];
const orgOptions = [{value: 6, label: '企业'},{value: 7, label: '媒体'},{value: 8, label: '医疗机构'},{value: 9, label: '机构'}];

export default function MedProject() {
    const navigate = useNavigate();

    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const [form] = Form.useForm();

    const [coverUrlList, setCoverUrlList] = useState([]);
    const [bannerUrlList, setBannerUrlList] = useState([]);
    const [h5BannerUrlList, setH5BannerUrlList] = useState([]);
    const [wxShareImgUrlList, setWxShareImgUrlList] = useState([]);
    const [posterUrlList, setPosterUrlList] = useState([]);
    const [projectQrcodeUrlList, setProjectQrcodeUrlList] = useState([]);

    const [project, setProject] = useState();
    const bannerBgColor = useRef(null);

    const [scheduleData, setScheduleData] = useState(null);

    const [visibleScheduleModal, setVisibleScheduleModal] = useState(false);

    const [timelineForm] = Form.useForm();
    const [currentTimelineNode, setCurrentTimelineNode] = useState(null); // 时间轴-当前节点
    const [currentTimelineNodeIndex, setCurrentTimelineNodeIndex] = useState(undefined); // 时间轴-当前节点索引
    const [visibleTimelineModal, setVisibleTimelineModal] = useState(false); // 是否显示新增/编辑时间轴对话框

    const personCheckedListRef = useRef([]);
    const [personCheckedList, setPersonCheckedList] = useState([]);
    const [personIndeterminate, setPersonIndeterminate] = useState(false);
    const [personCheckAll, setPersonCheckAll] = useState(false);

    const orgCheckedListRef = useRef([]);
    const [orgCheckedList, setOrgCheckedList] = useState([]);
    const [orgIndeterminate, setOrgIndeterminate] = useState(false);
    const [orgCheckAll, setOrgCheckAll] = useState(false);

    const datePickerTypes = [
        {label: '按日期选择', value: 'date'},
        {label: '按月份选择', value: 'month'},
        {label: '按年份选择', value: 'year'},
    ];

    const showScheduleModal = () => {
        setVisibleScheduleModal(true);
    };

    // 删除时间轴
    const delScheduleModal = () => { 
        Modal.confirm({
            title: '提示',
            content: '请确认是否删除主页时间轴，删除后不可恢复',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: () => {
                setScheduleData(null);
                message.success('时间轴已删除');
            }
        });
    };

    const showTimelineModal = (node = null, sort = 0) => {
        console.log(node);
        if (!isEmpty(node) && sort > -1) {
            setCurrentTimelineNode(node);
            setCurrentTimelineNodeIndex(sort);

            const formData = { ...node };
            formData.start_time = moment(formData.start_time) || undefined;
            if (!!formData.end_time) {
                formData.end_time = moment(formData.end_time) || undefined;
            }
            if (!formData.end_time_type) {
                formData.end_time_type = 'date';
            }

            timelineForm.setFieldsValue(formData);
        } else {
            setCurrentTimelineNode(null);
            setCurrentTimelineNodeIndex(undefined);
        }

        setVisibleTimelineModal(true);
    };

    const formatDateByPickerTypeForSave = (date, pickerType) => {
        let result = '';

        if (date && moment(date).isValid()) {
            switch (pickerType) {
                case 'date':
                    result = moment(date).format('YYYY-MM-DD');
                    break;
                case 'month':
                    result = moment(date).format('YYYY-MM');
                    break;
                case 'year':
                    result = moment(date).format('YYYY');
                    break;
                default:
                    result = moment(date).format('YYYY-MM-DD');
                    break;
            }
        }

        return result;
    };

    const onSaveTimelineNode = () => {
        timelineForm.validateFields()
            .then(values => {
                const node = {...values};

                node.start_time = formatDateByPickerTypeForSave(node.start_time, node.start_time_type);
                if (!!node.end_time) {
                    node.end_time = formatDateByPickerTypeForSave(node.end_time, node.end_time_type);
                }

                const newScheduleData = {...scheduleData};
                if (currentTimelineNodeIndex !== undefined && currentTimelineNodeIndex > -1) {
                    // 编辑
                    newScheduleData.nodes[currentTimelineNodeIndex] = node;
                } else {
                    // 新增
                    if (!newScheduleData.nodes) {
                        newScheduleData.nodes = [];
                    }
                    newScheduleData.nodes.push(node);
                }

                setScheduleData(newScheduleData);

                timelineForm.resetFields();

                setVisibleTimelineModal(false);
            })
            .catch(err => {
                //
            });
    };

    const onCancelTimelineNode = () => {
        timelineForm.resetFields();
        setVisibleTimelineModal(false);
    };

    const changeScheduleTitle = e => setScheduleData({ ...scheduleData, title: e.target.value });

    const confirmScheduleData = () => {
        if (!(scheduleData && scheduleData.nodes && scheduleData.nodes.length)) {
            Modal.confirm({
                title: '时间轴列表不允许为空',
                content: '时间点为空，无法保存。是否确认放弃编辑时间轴？',
                centered: true,
                onOk: () => {
                    setVisibleScheduleModal(false);
                },
                onCancel: () => {
                    //
                },
                okText: '确认，放弃更改',
                okType: 'danger',
                cancelText: '取消，立即完善',
            })
        } else {
            message.warn('时间轴已更改，请不要忘记保存项目信息！');
            setVisibleScheduleModal(false);
        }
    };

    const formatDateByPickerTypeForDisplay = (date, pickerType) => {
        let result = '';

        if (date && moment(date).isValid()) {
            switch (pickerType) {
                case 'date':
                    result = moment(date).format('YYYY年M月D日');
                    break;
                case 'month':
                    result = moment(date).format('YYYY年M月');
                    break;
                case 'year':
                    result = moment(date).format('YYYY年');
                    break;
                default:
                    result = moment(date).format('YYYY年M月D日');
                    break;
            }
        }

        return result;
    };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return (<>
            <Tooltip title="时间点排序">
                <Button type="text" size="small"><DragOutlined/></Button>
            </Tooltip>
        </>);
    });

    const SortableMainElement = SortableElement(({ node, sort }) => {
        return <div className="timeline-node" style={{position: 'relative', zIndex: 99999999}}>
            <div className="schedule-table-tr" key={sort}>
                <div className="schedule-table-start">
                    {formatDateByPickerTypeForDisplay(node.start_time, node.start_time_type)}
                </div>
                <div className="schedule-table-end">
                    {!!node.end_time
                        ? formatDateByPickerTypeForDisplay(node.end_time, node.end_time_type)
                        : <Text type="secondary">&ndash;</Text>
                    }
                </div>
                <div className="schedule-table-name">
                    {!!node.url
                        ? <a target="_blank" href={node.url}>{node.title}</a>
                        : node.title
                    }
                </div>
                <div className="schedule-table-text">
                    {node.desc || <Text type="secondary">&ndash;</Text>}
                </div>
                <div className="schedule-table-settings">
                    <span className="operation-btns">
                        <Button size="small" type="link" onClick={() => showTimelineModal(node, sort)}>编辑</Button>
                        <Button size="small" type="link" onClick={() => deleteTimelineNode(sort)}>删除</Button>
                    </span>
                    <SortableMainHandle/>
                </div>
            </div>
        </div>
    });

    const SortableMainContainer = SortableContainer(({ items }) => {
        return <div className="schedule-table-body">
            {items && items.map((node, idx) => {
                return (
                    <SortableMainElement
                        node={node}
                        index={idx}
                        key={idx}
                        sort={idx}
                    />
                );
            })}
        </div>;
    });

    // 时间点排序
    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        let newData = arrayMoveImmutable(scheduleData.nodes, oldIndex, newIndex);
        const newScheduleData = {...scheduleData};
        newScheduleData.nodes = newData;
        setScheduleData(newScheduleData);
    };

    const deleteTimelineNode = idx => {
        const newScheduleData = {...scheduleData};
        newScheduleData.nodes.splice(idx, 1);
        setScheduleData(newScheduleData);
    };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        let startTime = timelineForm.getFieldValue('start_time');
        if (!!startTime) {
            let startTimeType = timelineForm.getFieldValue('start_time_type');
            switch (startTimeType) {
                case 'month':
                    startTime = startTime.endOf('month');
                    break;
                case 'year':
                    startTime = startTime.startOf('year');
                    break;
            }

            return current && current < startTime;
        } else {
            return false;
        }
    };

    const getProject = async id => {
        const res = await showMedProject(id);
        if (res.status_code === 200) {
            const project = res.data;
            setProject(project);

            const formData = {...project};
            formData.joinSettingsJson = JSON.parse(formData.joinSettings);
            // console.log(formData);
            if (formData.timeRangeType === 1) {
                formData.timeRange = [moment(formData.startedAt), moment(formData.endedAt)];
            }
            if (formData.joinSettingsJson.signRangeType === 1) {
                formData.joinSettingsJson.signTimeRange = [moment(formData.joinSettingsJson.signStartedAt), moment(formData.joinSettingsJson.signEndedAt)];
            }

            // 个人认证回显
            if (formData.joinSettingsJson.personIdentityType && formData.joinSettingsJson.personIdentityType) { 
                onPersonChange(formData.joinSettingsJson.personIdentityType);
            }
            // 机构认证回显
            if (formData.joinSettingsJson.orgIdentityType && formData.joinSettingsJson.orgIdentityType.length) { 
                onOrgChange(formData.joinSettingsJson.orgIdentityType);
            }
  
            setCoverUrlList([prepareBCOSSUrl(formData.coverUrl)]);
            if (formData.banner) {
                setBannerUrlList([prepareBCOSSUrl(formData.banner)]);
            }
            if (formData.h5Banner) {
                setH5BannerUrlList([prepareBCOSSUrl(formData.h5Banner)]);
            }
            setWxShareImgUrlList([prepareBCOSSUrl(formData.wxShareImgUrl)]);
            setPosterUrlList([prepareBCOSSUrl(formData.posterUrl)]);
            if(formData.projectQrcode){
                setProjectQrcodeUrlList([prepareBCOSSUrl(formData.projectQrcode)]);
            }
            // console.log(formData);

            form.setFieldsValue(formData);
            if (project.banner) {
                if (project.bannerBgColor) {
                    bannerBgColor.current = project.bannerBgColor;
                } else {
                    const url = prepareBCOSSUrl(project.banner);
                    let bgColor = await getBannerColor(url);
                    bannerBgColor.current = bgColor;
                }
            }

            // 时间轴数据
            if (!isEmpty(project?.scheduleData)) {
                // console.log(project.scheduleData);
                setScheduleData(JSON.parse(project.scheduleData) || null);
            }
        }
    };

    useEffect(() => {
        if (!!id) {
            getProject(id);
        }
    }, []);

    const onFinish = async values => {
        if (!bannerBgColor.current) {
            return;
        }

        if (values?.joinSettingsJson?.identityLimit && !orgCheckedList.length && !personCheckedList.length) { 
            return message.warning('报名身份不能为空');
        };

        console.log(values);
        const data = {...values};

        if (data.timeRangeType === 1) {
            data.startedAt = dateTimeFormat(data.timeRange[0], 'YYYY-MM-DD 00:00:00');
            data.endedAt = dateTimeFormat(data.timeRange[1], 'YYYY-MM-DD 23:59:59');
        }

        if (data.joinSettingsJson.signRangeType === 1) {
            data.joinSettingsJson.signStartedAt = dateTimeFormat(data.joinSettingsJson.signTimeRange[0], 'YYYY-MM-DD 00:00:00');
            data.joinSettingsJson.signEndedAt = dateTimeFormat(data.joinSettingsJson.signTimeRange[1], 'YYYY-MM-DD 23:59:59');
        }

        // 个人认证
        data.joinSettingsJson.personIdentityType = data.joinSettingsJson.identityLimit ? [...personCheckedList] : [];
        // 机构认证
        data.joinSettingsJson.orgIdentityType = data.joinSettingsJson.identityLimit ? [...orgCheckedList] : [];

        data.bannerBgColor = bannerBgColor.current || '';

        if (scheduleData && !!scheduleData?.nodes?.length) {
            data.scheduleData = JSON.stringify(scheduleData);
        }

        // console.log(data);

        if (!id) {
            // console.log('create med project');
            const res = await createMedProject(data);
            if (res.status_code === 200) {
                message.success('成功新增医项目');
                navigate('/medproject/projects');
            }
        } else {
            // console.log('edit med project');
            const res = await editMedProject({id, ...data});
            if (res.status_code === 200) {
                message.success('成功编辑医项目');
                navigate('/medproject/projects');
            }
        }
    };

    const onFinishFailed = ({ values, errorFields}) => console.log(values, errorFields);

    const onReset = () => {
        form.resetFields();
        navigate('/medproject/projects');
    };

    // 删除客服二维码
    const closeImg = () => {
        const newItem = {...project};
        delete newItem.projectQrcode;
        setProjectQrcodeUrlList([]);
        form.setFieldValue('projectQrcode', '');
    };

    const checkImgUploadFile = (file, sizeMb) => {
        const typeList = ['image/jpg', 'image/jpeg', 'image/png'];
        const isImage = typeList.includes(file.type) || false;

        if (!isImage) {
            message.error('仅支持 JPG/PNG 图片格式');
            return false;
        }

        const metSize = file.size / 1024 / 1024 < sizeMb;
        if (!metSize) {
            message.error(`图片文件大小限${sizeMb}MB`);
            return false;
        }

        return isImage && metSize;
    };

    const uploadImageFile = (fileInfo, type) => {
        // type = 21 medproject
        const formData = new FormData();
        formData.append('file', fileInfo.file);
        formData.append('type', 21);

        medProjectFileApi(formData)
            .then(async(res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }

                if (res.status_code === 200) {
                    const url = prepareBCOSSUrl(res.data);
                    if (type === 'coverUrl') {
                        setCoverUrlList([url]);
                        form.setFieldValue('coverUrl', res.data);
                    } else if (type === 'wxShareImgUrl') {
                        setWxShareImgUrlList([url]);
                        form.setFieldValue('wxShareImgUrl', res.data);
                    } else if (type === 'posterUrl') {
                        setPosterUrlList([url]);
                        form.setFieldValue('posterUrl', res.data);
                    } else if (type === 'banner') {
                        setBannerUrlList([url]);
                        form.setFieldValue('banner', res.data);
                        let bgColor = await getBannerColor(url);
                        bannerBgColor.current = bgColor;
                    } else if (type === 'h5Banner') {
                        setH5BannerUrlList([url]);
                        form.setFieldValue('h5Banner', res.data);
                    }else if (type === 'projectQrcode') {
                        setProjectQrcodeUrlList([url]);
                        form.setFieldValue('projectQrcode', res.data);
                    }
                }
            })
    };

    // 个人全选
    const onCheckPersonAllChange = (e) => {
        const list = e.target.checked ? personOptions.map(item => item.value) : [];
        setPersonCheckedList(list);
        personCheckedListRef.current = list;
        setPersonIndeterminate(false);
        setPersonCheckAll(e.target.checked);
    };

    // 机构全选
    const onCheckOrgAllChange = (e) => {
        const list = e.target.checked ? orgOptions.map(item => item.value) : [];
        setOrgCheckedList(list);
        orgCheckedListRef.current = list;
        setOrgIndeterminate(false);
        setOrgCheckAll(e.target.checked);
    };
    
    // 个人认证
    const onPersonChange = (list) => { 
        setPersonCheckedList(list);
        setPersonIndeterminate(!!list.length && list.length < personOptions.length);
        setPersonCheckAll(list.length === personOptions.length);
    };

    // 机构认证
    const onOrgChange = (list) => { 
        setOrgCheckedList(list);
        setOrgIndeterminate(!!list.length && list.length < orgOptions.length);
        setOrgCheckAll(list.length === orgOptions.length);
    };

    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">{!id ? '新增医项目' : '项目信息'}</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">
                <div className="main-content-title">项目信息</div>
                <div className="main-content-body">

                    <Form
                        {...pageFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <div className="form-subtitle">基本信息</div>

                        <Form.Item
                            name="name"
                            label="项目名称"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="请输入项目名称" maxLength={50} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="项目简介"
                            rules={[
                                { required: true, message: '' }
                            ]}
                            help="一句话介绍，200 字以内"
                        >
                            <Input placeholder="请输入项目简介" maxLength={200} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="timeRangeType"
                            label="项目时间"
                            rules={[{ required: true, message: '' }]}
                            initialValue={1}
                        >
                            <Radio.Group>
                                <Radio value={1}>限期项目</Radio>
                                <Radio value={2}>长期项目</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) => prevValues.timeRangeType !== curValues.timeRangeType}
                        >
                            {({ getFieldValue }) => {
                                return getFieldValue('timeRangeType') === 1 && <>
                                    <Form.Item
                                        name="timeRange"
                                        {...pageFormItemWithoutLabelGridLayout}
                                        rules={[{ required: true, message: '请选择项目时间' }]}
                                    >
                                        <RangePicker/>
                                    </Form.Item>
                                </>;
                            }}
                        </Form.Item>

                        <Form.Item
                            name="banner"
                            label="PC端项目主图"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 3840&times;900px（2倍图），文件大小不超过2MB"
                        >
                            {bannerUrlList && bannerUrlList.length > 0 && (
                                <div className="uploaded-image" style={{marginBottom: 8}}>
                                    <Image src={bannerUrlList[0]}/>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 2)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'banner')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {bannerUrlList && bannerUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="h5Banner"
                            label="移动端项目主图"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 750&times;450px（2倍图），文件大小不超1MB"
                        >
                            {h5BannerUrlList && h5BannerUrlList.length > 0 && (
                                <div className="uploaded-image" style={{marginBottom: 8}}>
                                    <Image src={h5BannerUrlList[0]}/>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 1)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'h5Banner')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {h5BannerUrlList && h5BannerUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="bannerUrl"
                            label="项目主图链接"
                        >
                            <Input placeholder="项目主图链接" />
                        </Form.Item>

                        <Form.Item
                            name="coverUrl"
                            label="封面图"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 560&times;315px（2倍图），文件大小不超过1MB"
                        >
                            {coverUrlList && coverUrlList.length > 0 && (
                                <div className="uploaded-image" style={{marginBottom: 8}}>
                                    <Image src={coverUrlList[0]}/>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 1)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'coverUrl')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {coverUrlList && coverUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item label="主页时间轴">
                            <Button onClick={showScheduleModal}>{!isEmpty(project?.scheduleData) ? '编辑时间轴' : '添加时间轴'}</Button>
                            { !isEmpty(project?.scheduleData) && <Button type="link" onClick={delScheduleModal}>删除时间轴</Button>}
                        </Form.Item>

                        <div className="form-subtitle">申请加入/报名设置</div>

                        <Form.Item name="joinSettingsJson" noStyle>

                            <Form.Item
                                name={['joinSettingsJson', 'signRangeType']}
                                label="报名时间设置"
                                rules={[{ required: true, message: '' }]}
                                initialValue={1}
                            >
                                <Radio.Group>
                                    <Radio value={1}>一段时间</Radio>
                                    <Radio value={2}>项目结束前均可报名</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => prevValues.joinSettingsJson.signRangeType !== curValues.joinSettingsJson.signRangeType}
                            >
                                {({ getFieldValue }) => {
                                    return getFieldValue(['joinSettingsJson', 'signRangeType']) === 1 && <>
                                        <Form.Item
                                            name={['joinSettingsJson', 'signTimeRange']}
                                            {...pageFormItemWithoutLabelGridLayout}
                                            rules={[{ required: true, message: '请选择报名时间' }]}
                                            help="用于控制当前项目的报名时间，时间范围外不允许报名"
                                        >
                                            <RangePicker/>
                                        </Form.Item>
                                    </>;
                                }}
                            </Form.Item>


                            <Form.Item
                                className='required'
                                name={['joinSettingsJson', 'identityLimit']}
                                label="加入认证要求"
                                valuePropName="checked"
                                getValueProps={(value) => ({
                                    checked: value === 1,
                                })}
                                getValueFromEvent={(value) => (value ? 1 : 0)}
                            >
                                <Switch
                                    checkedChildren="开启"
                                    unCheckedChildren="关闭"
                                />
                            </Form.Item>

                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => prevValues.joinSettingsJson.identityLimit !== curValues.joinSettingsJson.identityLimit}
                            >
                                {({ getFieldValue }) => {
                                    return !!getFieldValue(['joinSettingsJson', 'identityLimit']) && <>
                                        <Form.Item label="报名身份" className="required">
                                            <div className="medproject-auth-type" style={{marginBottom: 8}}>
                                                <Form.Item
                                                    className='required'
                                                    rules={[
                                                        {required: true, message: '请选择报名身份'}
                                                    ]}
                                                >
                                                    <Space style={{ marginBottom: '5px' }}>
                                                        <Checkbox indeterminate={personIndeterminate} onChange={onCheckPersonAllChange} checked={personCheckAll} style={{width: '130px'}}>
                                                            个人专业认证：
                                                        </Checkbox>
                                                        <Checkbox.Group value={personCheckedList} onChange={onPersonChange} >
                                                            {personOptions.map((item, index) => (<Checkbox value={item.value} key={index}>{item.label}</Checkbox>))}
                                                        </Checkbox.Group>
                                                    </Space>
                                                    <Space>
                                                        <Checkbox indeterminate={orgIndeterminate} onChange={onCheckOrgAllChange} checked={orgCheckAll} style={{width: '130px'}}>
                                                            机构认证：
                                                        </Checkbox>
                                                        <Checkbox.Group value={orgCheckedList} onChange={onOrgChange} >
                                                            {orgOptions.map((item, index) => (<Checkbox value={item.value} key={index}>{item.label}</Checkbox>))}
                                                        </Checkbox.Group>
                                                    </Space>
                                                </Form.Item>

                                            </div>

                                        </Form.Item>
                                    </>;
                                }}
                            </Form.Item>

                            <Form.Item
                                name={['joinSettingsJson', 'rights']}
                                label="权益介绍"
                                rules={[
                                    {required: true, message: '请填写权益介绍'}
                                ]}
                            >
                                <Input.TextArea rows={8} maxLength={2000} showCount allowClear/>
                            </Form.Item>

                        </Form.Item>


                        <div className="form-subtitle">分享设置</div>

                        <Form.Item
                            name="wxShareTitle"
                            label="微信分享标题"
                            rules={[{ required: true, message: '请填写微信分享标题' }]}
                        >
                            <Input placeholder="请填写微信分享标题" maxLength={20} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="wxShareDescription"
                            label="微信分享描述"
                            rules={[{ required: true, message: '请填写微信分享描述' }]}
                        >
                            <Input placeholder="请填写微信分享描述" maxLength={20} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="wxShareImgUrl"
                            label="微信分享图"
                            rules={[
                                { required: true, message: '请上传微信分享图' }
                            ]}
                            help="支持JPG/PNG格式，尺寸300&times;300px，大小不超过1M"
                        >

                            {wxShareImgUrlList && wxShareImgUrlList.length > 0 && (
                                <div className="uploaded-image" style={{marginBottom: 8}}>
                                    <Image src={wxShareImgUrlList[0]}/>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 1)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'wxShareImgUrl')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {wxShareImgUrlList && wxShareImgUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="projectQrcode"
                            label="项目社群二维码"
                            rules={[
                                { required: false, message: '请上传项目社群二维码' }
                            ]}
                            help="“添加微信顾问”默认展示统一社群二维码，如需展示项目独立二维码，请上传二维码，图片尺寸不小于300x300px，支持JPG/PNG格式，大小不超过1M
                            "
                        >

                            {projectQrcodeUrlList && projectQrcodeUrlList.length > 0 && (
                                <div style={{marginBottom: 8,width: 200,height: 200}}>
                                    <Image src={projectQrcodeUrlList[0]}/>
                                    <span onClick={closeImg} className="close-img"
                                    style={ {position: 'absolute',top: 0,left:180,cursor: 'pointer',backgroundColor:'rgba(0, 0, 0, 0.6)',
                                    color: '#FFFFFF',borderRadius:'50%',width: 20,height:20,textAlign:'center'} }
                                    ><CloseOutlined /></span>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 1)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo, 'projectQrcode')}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {projectQrcodeUrlList && projectQrcodeUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="seoTitle"
                            label="SEO 标题"
                            rules={[{ required: false, message: '请填写SEO 标题' }]}
                        >
                            <Input placeholder="请填写SEO 标题" maxLength={60} showCount allowClear/>
                        </Form.Item>
                        <Form.Item
                            name="seoDescription"
                            label="SEO 描述"
                            rules={[{ required: false, message: '请填写SEO 描述' }]}
                        >
                            <Input placeholder="请填写SEO 描述" maxLength={150} showCount allowClear/>
                        </Form.Item>
                        <Form.Item
                            name="seoKeywords"
                            label="SEO 关键词"
                            rules={[{ required: false, message: '请填写SEO 关键词' }]}
                        >
                            <Input placeholder="请填写SEO 关键词" maxLength={60} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            {...pageFormItemWithoutLabelGridLayout}
                            style={{ paddingTop: 24, marginBottom: 0 }}
                        >
                            <Space size="large">
                                <Button type="primary" size="large" htmlType="submit">提交</Button>
                                <Button type="default" size="large" onClick={onReset}>取消</Button>
                            </Space>
                        </Form.Item>


                    </Form>

                </div>
            </Content>

            <Modal
                title="主页时间轴"
                centered
                width={1000}
                maskClosable={false}
                open={visibleScheduleModal}
                onOk={() => confirmScheduleData()}
                onCancel={() => setVisibleScheduleModal(false)}
            >
                <div className="main-schedule">
                    <Row style={{display: 'flex', marginBottom: 16}}>
                        <Col style={{display: 'flex', alignItems: 'center'}}>主页时间轴名称：</Col>
                        <Col style={{flex: 1}}>
                            <Input
                                style={{width: '100%'}}
                                placeholder="请输入主页时间轴名称，不超过50字"
                                value={scheduleData && !!scheduleData?.title ? scheduleData?.title : undefined}
                                maxLength={50}
                                showCount
                                allowClear
                                onChange={changeScheduleTitle}
                            />
                        </Col>
                    </Row>

                    <div className="schedule-content">
                        <div className="schedule-head">
                            <span className="schedule-label red-asterisk-before">主页时间轴列表：</span>
                            <Button type="primary" onClick={() => showTimelineModal()}>添加时间点</Button>
                        </div>

                        <div className="schedule-table">
                            <div className="schedule-table-head">
                                <span className="schedule-head-start">开始时间</span>
                                <span className="schedule-head-end">结束时间</span>
                                <span className="schedule-head-name">时间点名称</span>
                                <span className="schedule-head-text">时间点描述</span>
                                <span className="schedule-head-settings">操作</span>
                            </div>

                            {scheduleData && scheduleData.nodes && scheduleData.nodes.length
                                ? (
                                    <SortableMainContainer
                                        helperClass="row-dragging"
                                        useWindowAsScrollContainer={true}
                                        useDragHandle
                                        distance={10}
                                        items={scheduleData.nodes}
                                        onSortEnd={onSortEndHandler}>
                                    </SortableMainContainer>
                                )
                                : (
                                    <div className="ant-empty ant-empty-normal">
                                        <div className="ant-empty-image">

                                            <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g className="ant-empty-img-simple-g" fillRule="nonzero">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
                                                    </g>
                                                </g>
                                            </svg>

                                        </div>
                                        <div className="ant-empty-description">暂无数据</div>
                                    </div>
                                )

                            }


                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                title={!isEmpty(currentTimelineNode) ? '编辑时间点' : '新增时间点'}
                width={800}
                centered
                onOk={onSaveTimelineNode}
                onCancel={onCancelTimelineNode}
                open={visibleTimelineModal}
            >
                <Form
                    form={timelineForm}
                    autoComplete="off"
                    defaultValue={currentTimelineNode}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >

                    <Form.Item label="开始时间" className="required">
                        <Row gutter={8}>
                            <Col>
                                <Form.Item
                                    name="start_time_type"
                                    noStyle
                                    initialValue="date"
                                >
                                    <Select options={datePickerTypes}/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldValue}) => {
                                        return (
                                            <Form.Item
                                                name="start_time"
                                                noStyle
                                                rules={[
                                                    {required: true, message: '开始时间不能为空'}
                                                ]}
                                            >
                                                <DatePicker picker={getFieldValue('start_time_type') || 'date'}/>
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item label="结束时间">
                        <Row gutter={8}>
                            <Col>
                                <Form.Item
                                    name="end_time_type"
                                    noStyle
                                    initialValue="date"
                                >
                                    <Select options={datePickerTypes}/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldValue}) => {
                                        return (
                                            <Form.Item name="end_time" noStyle>
                                                <DatePicker
                                                    picker={getFieldValue('end_time_type') || 'date'}
                                                    disabledDate={disabledDate}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        label="时间点名称"
                        name="title"
                        rules={[{ required: true, message: '请输入时间点名称' }]}
                    >
                        <Input placeholder="请输入时间点名称，不超过20字" maxLength={20} showCount allowClear/>
                    </Form.Item>

                    <Form.Item
                        label="跳转链接"
                        name="url"
                        rules={[
                            {pattern: /^https?:\/\//i, message: '跳转链接格式不正确'}
                        ]}
                    >
                        <Input placeholder="请输入名称跳转链接" allowClear/>
                    </Form.Item>

                    <Form.Item label="时间点描述" name="desc">
                        <Input.TextArea placeholder="请输入时间点描述，不超过100字" rows={5} maxLength={100} showCount allowClear/>
                    </Form.Item>
                </Form>

            </Modal>

        </>
    );
}
