import { get, put, post } from '@core/request';

/*
    医会议--网站模块
*/

/**
 * 网站模块api
 */

// 获取网站列表
export const getSiteModulesApi = (params) => {
    return get('/conference/boss/sitemodules/list', params);
};

// 新增网站模块
export const saveSiteModuleApi = (params) => {
    return post('/conference/boss/sitemodules/save', params);
};

// 修改网站模块
export const updateSiteModulesApi = (params) => {
    return put('/conference/boss/sitemodules/update', params);
};

// 模块排序
export const sortSiteModulesApi = (params) => {
    return put('/conference/boss/sitemodules/sort', params);
};

// 删除网站模块
export const deleteSiteModuleApi = (params) => {
    return get('/conference/boss/sitemodules/delete', params);
};

// 设置默认访问
export const setDefaultShowApi = (params) => {
    return put('/conference/boss/sitemodules/set-default-show', params);
};

// 上传
export const uploadFileApi = (params) => {
    return post('/conference/file', params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};
