import React, { Fragment, useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { Content } from "antd/lib/layout/layout";
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tag, Tooltip, Typography} from "antd";
import {CopyOutlined, ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {projectActions} from "../store/medconnectSlice";
import { numberProject } from '@utils/utils';
import {stageList} from "../constants";
import copy from 'copy-to-clipboard';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { getMedProjectList, updateProjectState } from '@services/MedConnect';


const { setProjectId, setProjectName } = projectActions;
const { Text } = Typography;

export default function MedConnectProjects() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const getProjects = async () => {
        try {
            setLoading(true);
            const params = {
                ...filter,
            }
            // 请求接口
            getMedProjectList(params).then((res) => {
                if (res && res.success) {
                    setTotal(res.totalCount);
                    setTableData(res.data);
                }
            })
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        }
    };

    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            pageIndex: 1,
        });
    };

    // 检索
    const handleFilterFinish = (values) => {
        setFilter({
            ...filter,
            projectName: values.projectName,
            testingFlag: values.testingFlag,
            h3yunNo: values.h3yunNo,
            pageIndex: 1,
        });
    };

    // 新建
    const toCreate = () => {
        navigate(`/medconnect/projects/create`);
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    const onShowSizeChange = (pageIndex, pageSize) => onChangePage(1, pageSize);
    // 刷新
    const onRefresh = () => getProjects();

    const copyProjectNo = projectNo => {
        copy(projectNo);
        message.success('已复制到剪贴板');
    };

    // 项目管理
    const toProjects = (record) => {
        dispatch(setProjectId(record.id));
        dispatch(setProjectName(record.projectName))
        navigate(`/medconnect/projects/${record.id}`);
    }

    // 标记为测试项目
    const handleMark = (record) => {
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: `请确认是否标记为${record.testing ? '正式' : '测试'}项目？`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                updateProjectState(record.id, {
                    testing: record.testing === 1 ? 0 : 1,
                }).then(res => {
                    if (res && res.success) {
                        message.success(res.data || '修改成功');
                        onRefresh();
                    }
                });
            }
        })
    };

    // 切换状态
    const handleChangeState = (record) => {
        const params = {};
        let messageTxt = '';
        if (record.stateDesc === '待发布' || record.stateDesc === '下架' || record.stateDesc === '已结束') {
            params.state = 1;
            messageTxt = '请确认是否上架此项目?';
        } else if (record.stateDesc === '上架') {
            params.state = 2;
            messageTxt = '请确认是否下架此项目?';
        }
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: messageTxt,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                updateProjectState(record.id, params).then(res => {
                    if (res && res.success) {
                        message.success(res.data || '修改成功');
                        onRefresh();
                    }
                });
            }
        });
    }

    const columns = [
        { title: '氚云编号', dataIndex: 'h3yunNo', width: 100 },
        {
            title: '项目ID',
            dataIndex: 'id',
            width: 160,
            render: (_, record) => {
                const projectNo = numberProject('YXM', record.id);
                return <span className="conferenctlist-copy-column-span">
                    {projectNo}
                    <span onClick={() => copyProjectNo(projectNo)}><CopyOutlined /></span>
                </span>;
            }
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            render: (_, record) => {
                return (
                    <div>
                        <span>{_}</span>
                        {!!record.testing && <Tag color="#f50" style={{marginLeft: 4, padding: '0 4px'}}>TEST</Tag>}
                    </div>
                )
            }
        },
        {
            title: '起止日期',
            dataIndex: 'times',
            width: 200,
            render: (_, record) => {
                return <div>{record.startedAt} 至 {record.endedAt}</div>
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 160,
        },
        {
            title: '创建人',
            dataIndex: 'createUserName',
            width: 80,
        },
        {
            title: '项目状态',
            dataIndex: 'stateDesc',
            width: 100,
            render: (_, record) => <Text>{record.stateDesc}</Text>
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 280,
            fixed: 'right',
            render: (_, record) => {

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => toProjects(record)}
                        >
                            项目管理
                        </Button>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleMark(record)}
                        >
                            { record.testing ? '标记为正式项目' : '标记为测试项目' }
                        </Button>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleChangeState(record)}
                        >
                            { record.stateDesc === '待发布' ? '发布项目' : record.stateDesc === '上架' ? '下架' : '上架' }
                        </Button>
                    </span>
                );
            }
        },
    ];

    useEffect(() => {
        form.setFieldsValue(filter);

        getProjects();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">问答项目管理</div>

            <Content className="main-content-box">
                {/*检索*/}
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="项目名称" name="projectName">
                                    <Input allowClear placeholder="输入项目名称" autoComplete="off" />
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="氚云编号" name="h3yunNo">
                                    <Input allowClear placeholder="氚云项目编号" autoComplete="off" />
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="正式/测试" name="testingFlag">
                                    <Select allowClear placeholder="请选择环境">
                                        {stageList && stageList.map(item => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={() => toCreate()}>新建</Button>
                        </div>

                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        onClick={onRefresh}
                                        icon={<>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                                </svg>
                                            </span>
                                        </>}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>
            </Content>

        </Fragment>
    )
}

