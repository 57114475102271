import React, { forwardRef, Fragment, useImperativeHandle, useState, useEffect } from 'react';
import { drawerWidth } from '@utils/property';
import { Button, Space, Drawer, message, Tree } from 'antd';
import { getParentIdList } from '@utils/utils';
import { patch } from '@/core/request';

function DepartmentSortDrawer({ oldDepartmentTree, arr, getData }, ref) {

    const [visible, setVisible] = useState(false);
    const [treeData, setTreeData] = useState(null);

    useEffect(() => {
        setTreeData(oldDepartmentTree)
    }, [oldDepartmentTree]);

    useImperativeHandle(ref, () => ({
        show() {
            setVisible(true);
        }
    }));

    const onDrop = info => {
        let dropPosArr = info.node.pos.split('-');

        let dragPosArr = info.dragNode.pos.split('-');

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPosition = info.dropPosition - Number(dropPosArr[dropPosArr.length - 1]);
        let dragAllParent = getParentIdList(treeData, dragKey)
        let dropAllParent = getParentIdList(treeData, dropKey)
        let dragParent = '';
        let dropParent = '';
        if (dragAllParent.length > 1) {
            dragParent = dragAllParent[dragAllParent.length - 2]
        }
        if (dropAllParent.length >= 1) {
            dropParent = dropAllParent[dropAllParent.length - 2]
            if (!info.dropToGap) {
                dropParent = dropAllParent[dropAllParent.length - 1]
            }
        }

        // console.log(dragAllParent, dropAllParent, info.dropToGap, dragParent, dropParent);

        let bool = (dropPosition === 0 && dropPosArr.length + 1 === dragPosArr.length);
        if (dragParent !== dropParent) {
            return message.error("不可拖入其他权限分组");
        }

        if (dropPosArr.length !== dragPosArr.length) {
            if (bool) { }
            else {
                return message.error("列表禁止跨级拖拽");
            }
        }

        // info.dropToGap 代表移动到非最顶级组第一个位置

        if (!info.dropToGap) {
            if (bool) { }
            else {
                return message.error("同级列表只能互换顺序");
            }
        }

        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...treeData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }
        setTreeData(data)
    };

    // 遍历得到所有的id
    const getAllNodeId = (expandedKeys, moduleDataList) => {
        moduleDataList.forEach(item => {
            expandedKeys.push(item.id)
            if (item.children) {
                expandedKeys = getAllNodeId(expandedKeys, item.children)
            }
        })
        return expandedKeys
    }

    const handleClose = () => {
        setTreeData(arr);
        setVisible(false);
    };

    const handleOk = () => {
        // 传给后端的ids
        let ids = getAllNodeId([], treeData);
        patch(`/enterprise/departments/edit-sort`, { ids }).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData();
                setVisible(false);
            }
        })
    };


    return (
        <Fragment>
            {
                visible && <Drawer
                    title="部门排序"
                    closable={false}
                    width={drawerWidth}
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <div style={{ textAlign: "right" }}>
                            <Space>
                                <Button onClick={handleClose}>取消</Button>
                                <Button type="primary" onClick={handleOk}>
                                    确定
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <div className="main-content-body">
                        <div className='tree-sort-title'>为<span>部门管理</span>进行排序</div>
                        <div className="drawerTip">
                            <p>拖动排序后，保存。</p>
                            <p>注意：排序只改变同级分类之间的顺序，不改变从属关系。</p>
                        </div>
                        {
                            treeData && <Tree
                                defaultExpandAll
                                className="draggable-tree"
                                draggable
                                blockNode
                                onDrop={onDrop}
                                treeData={treeData}
                            />
                        }



                    </div>
                </Drawer>
            }
        </Fragment>
    )
}
export default forwardRef(DepartmentSortDrawer)
