import React, { useEffect } from 'react';
import { message } from 'antd';
import { prepareBCOSSUrl } from '@utils/utils';
import { fileApi } from '@services/Conference';

// 富文本编辑
const TinymceEditor = ({ form, fieldValue, initialValue, id, placeholder }) => {
    // 图片上传
    const imageUpload = async (value) => {
        console.log(value.blob())
        const formData = new FormData();
        formData.append('file', value.blob());
        formData.append('path', '');

        const res = await fileApi(formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (res && res.data) {
            return prepareBCOSSUrl(res.data);
        }
    };

    const initEditor = () => {
        window.tinymce?.remove(`#${id}`);
        window.tinymce?.init({
            initialValue: initialValue,
            selector: `#${id}`,
            height: '300px',
            branding: false,
            elementpath: false,
            placeholder: placeholder,
            menubar: false,
            language: 'zh-Hans',
            language_url: 'https://files.cn-healthcare.com/lib/tinymce/langs/zh-Hans.js',
            plugins: 'importcss autolink save code fullscreen image link codesample table charmap advlist lists charmap table',
            toolbar: 'undo redo forecolor bold italic underline alignleft aligncenter alignright alignjustify image numlist bullist backcolor removeformat charmap fullscreen insertfile link table',
            //removeformat清除格式
            formats: {
                removeformat: [ //清除所选内容格式
                    {
                        selector: 'b,strong,em,i,font,u,strike,div,a,code,pre',
                        remove: 'all',
                        split: true,
                        expand: false,
                        block_expand: true,
                        deep: true
                    }
                ]
            },
            // 清除粘贴格式
            paste_auto_cleanup_on_paste: true,
            paste_remove_styles: true,
            paste_remove_styles_if_webkit: true,
            paste_strip_class_attributes: true,
            // paste_as_text: true,
            paste_data_images: false,
            paste_preprocess: function (plugin, args) {
                if (args.content.includes('src="blob')) {
                    args.content = '';
                    message.warn('不支持直接将截图粘贴到输入框');
                }
            },
            images_upload_handler: (e) => imageUpload(e),
            setup: function (editor) {
                // 初始化回显默认值
                editor.on('init', function (e) {
                    if (initialValue) {
                        editor.setContent(initialValue);
                    }
                });
                // 实时监听内容改变
                editor.on('input', function (e) {
                    form.setFieldValue(fieldValue, editor.getContent());
                    if (editor.getContent()) {
                        form.setFields([{ name: fieldValue, errors: null }]);
                    }
                    if (editor.getContent() === '<p><br></p>') {
                        form.setFieldValue(fieldValue, undefined);
                    }
                });
                editor.on('ExecCommand', (e) => {
                    form.setFieldValue(fieldValue, editor.getContent());
                    if (editor.getContent()) {
                        form.setFields([{ name: fieldValue, errors: null }]);
                    }
                    if (editor.getContent() === '<p><br></p>') {
                        form.setFieldValue(fieldValue, undefined);
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (id) {
            initEditor();
            form.setFieldValue(fieldValue, initialValue);
        }
    }, [id, initialValue]);

    return (
        <>
            {!!id && <div id={id}></div>}
        </>
    )
};

export default TinymceEditor;
