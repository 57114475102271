import { message, Drawer, Form, Radio, Input, Space, Button, Upload, Image  } from 'antd';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';
import { useState, useEffect, Fragment } from 'react';
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { uploadFileApi } from '@services/SiteModule';
import config from '@config';
import '../../SiteModules.less';

export default ({
    drawerState,
    setDrawerState,
    currentModule,
    updateModuleSettings,
}) => {
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    // 上传图片
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png/jpeg 格式');
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('图片大小不超过5MB');
            return false;
        }
        return isJpgOrPng && isLt5M;
    };

    // 上传文件路径 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 上传图片
    const upload = async (value, index, subIndex) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let cover = res.data;
            let currentValue = form.getFieldValue('partner_groups');
            let newValue = currentValue || [];
            let partnerList = newValue[index].partnerList;
            if (partnerList[subIndex]) {
                partnerList[subIndex].cover = cover;
            } else {
                partnerList[subIndex] = { cover: cover }
            }

            form.setFieldValue('partner_groups', newValue);
        };
    };

    // 过滤oss前缀
    const delOssUrl = (str) => {
        if (str && str.includes(config.OSS_PREFIX)) {
            let re = new RegExp(config.OSS_PREFIX + "(.*)")
            return str.match(re)[1];
        }
        return '';
    };

    // 处理文件前缀
    const dealFileUrl = (list) => {
        if (list && list.length) {
            let newList = list.map(item => {
                let newItem = JSON.parse(JSON.stringify(item));
                newItem.cover = Array.isArray(newItem.cover) && newItem.cover.length ? delOssUrl(newItem.cover[0].url) : newItem.cover;
                return newItem;
            })
            return newList;
        };
        return [];
    };

    // 图片回显添加前缀
    const addOssUrl = (imgList) => {
        if (imgList && imgList.length) {
            let newList = imgList.map(item => {
                let newItem = { ...item };
                if (newItem.url && !newItem.url.includes(config.OSS_PREFIX)) {
                    newItem.url = config.OSS_PREFIX + newItem.url;
                }
                return newItem;
            });
            return newList;
        }
        return [];
    };

    // 新增模块
    const onFinish = async (values) => {
        let params = {
            ...values,
            id: currentModule.id,
            data: JSON.stringify({ partner_groups: values.partner_groups })
        };
        delete params.partner_groups;
        updateModuleSettings(params);
    };

    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            let partner_groups = [];
            if (currentModule.data) {
                let data = JSON.parse(currentModule.data);
                partner_groups = data.partner_groups || [];
            };

            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path,
                partner_groups
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 10 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="PartnerModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="PartnerModuleForm"
                form={form}
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="displayTitle"
                    label="显示标题"
                    rules={[
                        { required: true, message: '显示标题不能为空' }
                    ]}
                >
                    <Input placeholder="请输入模块显示标题" />
                </Form.Item>

                <Form.Item name="displayTitleEn" label="英文显示标题">
                    <Input placeholder="请输入模块英文显示标题" />
                </Form.Item>

                <Form.Item
                    name="path"
                    label="访问路径"
                    rules={[
                        { required: true, message: '访问路径不能为空' },
                        { pattern: /^[a-z\d_]{2,20}$/, message: '访问路径不符合格式要求' },
                    ]}
                    help={
                        <>
                            填写访问该模块的访问路径，如会议简介访问路径 <b>introduction</b><br />
                            格式合求：小写字母、数字或下划线（_），限2-20个字符
                        </>
                    }
                >
                    <Input placeholder="请输入模块访问路径" minLength={2} maxLength={20} showCount disabled={currentModule?.moduleType <= 10} />
                </Form.Item>

                <Form.Item
                    label="合作伙伴"
                    name="partner_groups"
                >
                    <Form.List name="partner_groups">
                        {(fields, { add, remove, move }) => (
                            <>
                                <Form.Item>
                                    <Button onClick={() => {
                                        add();
                                    }}>
                                        <PlusCircleOutlined />添加合作分组
                                    </Button>
                                </Form.Item>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div key={key} className="exhibition-add">
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                            }}
                                            align="center"
                                        >
                                            <div style={{ paddingRight: '20px' }}>
                                                <Form.Item
                                                    label="分组名称"
                                                    name={[name, 'group_name']}
                                                >
                                                    <Input style={{ width: 300 }} placeholder="请输入分组名称" maxLength={50} />
                                                </Form.Item>
                                                <Form.List name={[name, 'partnerList']}>
                                                    {(partnerList, { add, remove, move }) => (
                                                        <>
                                                            <Form.Item>
                                                                <Button onClick={() => {
                                                                    add();
                                                                }}>
                                                                    <PlusCircleOutlined />添加伙伴
                                                                </Button>
                                                            </Form.Item>
                                                            {partnerList.map(({ key, name, ...restField }, subIndex) => (
                                                                <div key={key} className="exhibition-add">
                                                                    <Space
                                                                        key={key}
                                                                        style={{
                                                                            display: 'flex',
                                                                        }}
                                                                        align="center"
                                                                    >
                                                                        <div style={{ paddingRight: '20px' }}>
                                                                            <Form.Item
                                                                                label="企业名称"
                                                                                name={[name, 'name']}
                                                                            >
                                                                                <Input style={{ width: 300 }} placeholder="请输入企业名称" maxLength={50} />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                label={<><span style={{color: '#ff4d4f', marginRight: '4px'}}>*</span><span>LOGO</span></>}
                                                                                name={[name, 'cover']}
                                                                                className="organizationUploadWrap"
                                                                            >
                                                                                {
                                                                                    form.getFieldValue('partner_groups')[index]?.partnerList[subIndex]?.cover ?
                                                                                        <div className="imageWarp">
                                                                                            <Image width={102} src={getFileUrl(form.getFieldValue('partner_groups')[index]?.partnerList[subIndex]?.cover)} alt="" />
                                                                                        </div> : null
                                                                                }
                                                                                <Upload
                                                                                    customRequest={(e) => upload(e, index, subIndex)}
                                                                                    listType="picture-card"
                                                                                    fileList={[]}
                                                                                    showUploadList={{ showRemoveIcon: false }}
                                                                                    beforeUpload={beforeUpload}
                                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                                >
                                                                                    <div>
                                                                                        <PlusOutlined />
                                                                                        <div
                                                                                            style={{
                                                                                                marginTop: 8,
                                                                                            }}
                                                                                        >
                                                                                            {form.getFieldValue('partner_groups')[index]?.partnerList[subIndex]?.cover ? '更改图片' : '上传图片'}
                                                                                        </div>
                                                                                    </div>
                                                                                </Upload>
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                label="官网链接"
                                                                                name={[name, 'link']}
                                                                            >
                                                                                <Input style={{ width: 300 }} placeholder="请输入官网链接" maxLength={500} showCount />
                                                                            </Form.Item>
                                                                        </div>

                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                        {subIndex !== 0 ? <ArrowUpOutlined onClick={() => { move(subIndex, subIndex - 1); }} /> : null}
                                                                        {subIndex !== partnerList.length - 1 ? <ArrowDownOutlined onClick={() => {
                                                                            move(subIndex, subIndex + 1);
                                                                        }} /> : null}
                                                                    </Space>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>

                                            <MinusCircleOutlined onClick={() => {
                                                remove(name);
                                            }} />
                                            {index !== 0 ? <ArrowUpOutlined onClick={() => {
                                                move(index, index - 1);
                                            }} /> : null}
                                            {index !== fields.length - 1 ? <ArrowDownOutlined onClick={() => {
                                                move(index, index + 1);
                                            }} /> : null}
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item
                    name="status"
                    label="导航状态"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>敬请期待</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="hiddenWeb"
                    label="Web端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="hiddenH5"
                    label="H5端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>


        </Drawer>
    );
};
