import React, { Fragment, useState, useEffect,useRef,useMemo } from 'react';
import { Input, Radio, Button, Space, Checkbox, Form, Modal, message,Divider } from 'antd';
import { DeleteOutlined, DragOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import './index.less';
import { arrayMoveImmutable } from 'array-move';
import addonce from '@static/images/add-once.png';
import addmore from '@static/images/add-more.png';
import addother from '@static/images/add-other.png';
import { timesaveTemplateQuestionApi, delOptionsApi } from '@services/Wj/Template';
import { timesaveSurveryQuestionApi, delSurveyOptionsApi } from '@services/Wj/Question';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const SortItem = ({ questionId, type, item, setAnserArea, anserArea, onReset }) => {
    const [modal, contextHolder] = Modal.useModal();
    const [choiceList, setChoiceList] = useState([])
    const [moreOpen, setMoreOpen] = useState(false)
    const [questionIndex, setQuestionIndex] = useState()
    const [otherMore, setOtherMore] = useState([])
    const [titleBorder, setTitleBorder] = useState(false)
    const [remark, setRemark] = useState(false)
    const [titleIn,setTitleIn]= useState(false)
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const quillRef=useRef(null)
    const location = useLocation();
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
        let c=document.querySelectorAll('.ql-color')
        ;
        setTimeout(() => {
            c.forEach(item=>{
                item.onmousedown=(e)=>{
                    e.preventDefault();
                    console.log(1);
            }
            })
        }, 1000);


    }, []);

    const modules = useMemo(() => {
        return {
          toolbar: {
            container: [
              ["bold",  "underline", {
                color: [],
              },],
            //   ["blockquote", "code-block"],
            //   [{ header: 1 }, { header: 2 }],
            //   [{ list: "ordered" }, { list: "bullet" }],
            //   [{ script: "sub" }, { script: "super" }],
            //   [{ indent: "-1" }, { indent: "+1" }],
            //   [{ direction: "rtl" }],
            //   [{ size: ["small", false, "large", "huge"] }], //字体设置
            //   [
            //   ],
            //   [
            //     {
            //       background: [],
            //     },
            //   ],
            //   [{ font: [] }],
            //   [{ align: [] }],
            //   ["link", "image"], // a链接和图片的显示
            ],
            // handlers: {
            //     color: colorHander,
            // },
          },
          // toolbar:null,
        };
      }, []);

    //题目题号
    useEffect(() => {
        const index = anserArea.findIndex(it => (it.id === item.id))
        setQuestionIndex(index + 1)
    }, []);

    //选项排序
    const onSortEnd = ({ oldIndex, newIndex }) => {
        var sortArr = arrayMoveImmutable(choiceList, oldIndex, newIndex)
        setChoiceList([...sortArr]);
        saveOpinion(sortArr)
    };

    //设置选项中是否包含其他
    useEffect(() => {
        setChoiceList([...item.options])
        if (item.options.every(it => it.isBlank === 0 || it.isBlank === null)) setRemark(false)
        else setRemark(true)
    }, [])

    //删除选项
    const deleteRadio = (item,content) => {
        let arr=choiceList.filter(it=>it.isBlank===0)
        if(arr.length<=2){
            message.error('不可以再删除了!')
            return
        }else{
            Modal.confirm({
                title: '提示',
                content: `确认删除选项${content}`,
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    if (location.state.route === 'newCreate') {
                        delOptionsApi({ id: item }).then((res) => {
                            if (res.status_code === 200) {
                                onReset()
                            }
                        })
                    } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                        delSurveyOptionsApi({ id: item }).then((res) => {
                            if (res.status_code === 200) {
                                onReset()
                            }
                        })
                    }
                    setChoiceList([...choiceList])
             }
            })
        }
    }

    // 添加其他
    const addRemark = () => {
        setRemark(true)
        let addList = [...choiceList]
        addList[addList.length] = { content: '其他', isBlank: 1 }
        setChoiceList([...addList]);
        saveOpinion(addList)

    }
    // // 删除其他
    const deleteRemark = () => {
        setRemark(false)
        console.log(choiceList);
        let delOther
        choiceList.map(it => {
            if (it.isBlank === 1) {
                delOther = it
            }
        })
        if (location.state.route === 'newCreate') {
            delOptionsApi({ id: delOther.id }).then((res) => {
                if (res.status_code === 200) {
                    onReset()
                }
            })
        } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            delSurveyOptionsApi({ id: delOther.id }).then((res) => {
                if (res.status_code === 200) {
                    onReset()
                }
            })
        }
    }
    //批量添加modal
    const addMore = () => {
        setMoreOpen(true)
    }
    // 修改标题
    const changeTitle = (e,i) => {
        let a=document.querySelectorAll('.ql-container')
       a[questionIndex].removeAttribute('style')
        let b=document.querySelectorAll('.ql-toolbar')
        b[questionIndex].removeAttribute('style')
        item.body = quillRef.current.value;
        setTitleBorder(false);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: quillRef.current.value,
        };
        console.log(quillRef.current.value);
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){
                message.success('保存成功')
            }}))
        }
        // setAnserArea(resArr);
    };
    //触碰显示边框
    const getBorder = (index) => {
        let a=document.querySelectorAll('.ql-container')
        a[index].style.border='1px solid #1a73e8'
        let b=document.querySelectorAll('.ql-toolbar')
        b[index].style.display='block'
        setTitleBorder(true);
        setTitleIn(true)
    };
    //改变选项内容
    const changeOpinion = (e, it) => {
        it.content = e.target.value
        setChoiceList([...choiceList])
        console.log(it, item);
        saveOpinion()

    }
    //保存选项
    const saveOpinion = (sortArr) => {
        const resArr = [...anserArea]
        const index = resArr.findIndex(it => it.id === item.id)
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            options: sortArr || choiceList
        }
        console.log(resArr[index]);
        if (location.state.route === 'newCreate') {
            timesaveTemplateQuestionApi(resArr[index]).then(
                (res) => {
                    if (res.status_code === 200) {
                       message.success('保存成功')
                    }
                }
            )
        } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            timesaveSurveryQuestionApi(resArr[index]).then(
                (res) => {
                    if (res.status_code === 200) {
                        message.success('保存成功')
                        onReset()
                    }
                }
            )
        }
        setAnserArea(resArr)
    }
    //添加选项
    const addChoice = () => {
        let id = choiceList[choiceList.length - 1].id
        id++
        let addList = [...choiceList, { content: '选项', isBlank: 0 }]
        setChoiceList([...addList]);
        saveOpinion(addList)
    }
    //关闭批量添添加modal
    const onCancel = () => {
        setMoreOpen(false)
        form.resetFields()
        setOtherMore([])
    }
    //批量添加完成
    const onOk = () => {
        let resOld = [...choiceList]
        let str = form.getFieldsValue().opinion+''
        if(!str){
            message.info('未输入内容')
            return
        }
        if(str==='undefined') {
            message.info('未输入内容')
            return
        }
        let arr = str.split('\n')
        let bool = true, index = 0, newArr = []
        arr.forEach((item, ind) => {
            if(!bool) return
            if(!item) return
            newArr.push(item)
        })
        let filArr = arr.filter(it=>it!=='')
        // setMoreOpen(false)
        let final=filArr.map(item=>{
            return ({
                content:item,
                isBlank:0
            })

        })
        let resArr = resOld.concat(final)
        saveOpinion(resArr)
    }

    const SortableMain = SortableContainer(({ children }) => {
        return <ul>{children}</ul>;
    });
    const SortableItem = SortableElement(({ item, num }) => (
        <li >
                    <Checkbox key={item.id} value={item.id} className='options-item' disabled>
                        <TextArea
                        className='option-item'
                        placeholder={`选项`} defaultValue={item.content}
                            onBlur={(e) => changeOpinion(e, item)}
                            bordered={false}
                            maxLength={200}
                             autoSize={{
                                minRows: 1,
                            }}
                        ></TextArea>
                        <div className='opion-action'>
                            <DragHandle />
                            <CloseOutlined className='del-opinion' onClick={() => { deleteRadio(item.id,item.content) }} />
                        </div>
                    </Checkbox>
        </li>

    ));
    const DragHandle = SortableHandle(() => <DragOutlined className='drop-opinion' />);

    return (
        <Fragment>
            <div name={questionId}>
                <div className='question-title'>
                    <div className='question-id'>
                        <span className='required' style={{ display: item.required === 1 ? '' : 'none' }}>*</span>
                        <b className='question-index'>{questionIndex}.</b>
                    </div>
                    {/* <TextArea
                        className='question-title-area'
                        placeholder='请输入问题'
                        defaultValue={item.body}
                        onFocus={getBorder}
                        onBlur={(e) => changeTitle(e)}
                        bordered={titleBorder}
                        style={{border:titleIn ? '1px solid #1a73e8':''}}
                        maxLength={200}
                        autoSize={{
                            minRows: 1,
                        }}
                    >
                    </TextArea> */}
                      <ReactQuill theme="snow" ref={quillRef}
                    modules={modules}
                   placeholder="请输入问题"
                   className="question-title-area"
                    onFocus={()=>getBorder(questionIndex)}
                    defaultValue={item.body}
                        onBlur={()=>changeTitle(questionIndex)}
                        />

                </div>
                    <div>
                        <Checkbox.Group style={{ display: 'block' }}>
                            <SortableMain onSortEnd={onSortEnd} distance={10}  >
                                {
                                    choiceList.map((item, index) => {
                                        if (item.isBlank === 0) {
                                            return (
                                                <SortableItem key={`item-${item.id}`} index={index} item={item} num={index} />
                                            )
                                        }
                                    })
                                }
                            </SortableMain>
                        </Checkbox.Group>
                        <hr className='question-division'></hr>
                        <div className='choice-action'>
                            <Button type='link' onClick={addChoice} ><img className='icon-width'  src={addonce} />添加选项</Button>
                            <Button type='link' onClick={addMore}><img className='icon-width' src={addmore} />批量添加选项</Button>
                        </div>
                    </div>
            </div>

            <Modal
                title={'批量添加选项'}
                open={moreOpen}
                onCancel={onCancel}
                onOk={onOk}
            >

                    <Form form={form} className='add_other'>
                    <p>每行代表一个选项，点击回车键换行</p>
                    <Form.Item name="opinion" >
                        <TextArea
                        className='vertical-scrollbar'
                        autoSize={{
                            minRows: 6,
                            maxRows: 6,
                        }}></TextArea>
                    </Form.Item>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default SortItem;
