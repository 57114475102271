import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, DatePicker, Button, Space, Cascader, InputNumber } from 'antd';
const { RangePicker } = DatePicker;

export default function FormalOrganizationsQueryFrom({handleFilterFinish}) {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (values) {
            handleFilterFinish(values)
        }
    };

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };


    const onReset = () => {
        form.resetFields();
        let values = {
            //写 所有的筛选数据
            code: undefined,
            name: undefined,
            belongsUnitName: undefined,
            adminName: undefined,
            state: undefined
        };
        handleFilterFinish(values);
    };


    return (
        <div className='formalOrg-research'>

            <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
                <Row>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="学苑ID" name="code" >
                            <Input allowClear placeholder="学苑ID" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="学苑名称" name="name">
                            <Input allowClear placeholder="学苑名称" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="管理员" name="adminName">
                            <Input allowClear placeholder="管理员" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="所属主体" name="belongsUnitName">
                            <Input allowClear placeholder="所属主体" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="状态" name="state"  >
                            <Select allowClear placeholder="请选择"  >
                                <Select.Option value={1}>正常</Select.Option>
                                <Select.Option value={2}>已停用</Select.Option>
                                <Select.Option value={3}>已取消</Select.Option>
                                <Select.Option value={4}>已过期</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="filter-form-buttons" {...filterFormGridLayout}>
                        <Space>
                            <Button type="default" onClick={onReset}>重置</Button>
                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                        </Space>
                    </Col>

                </Row>
            </Form>
        </div>
    );
}
