import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Layout, Table, Button, Modal, Input, Select, InputNumber, Pagination, Form, message, Tag, Dropdown, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import {
    fetchCasescoresList,
    deleteCasescoresCase,
    fetchSelectCaseList,
    saveSelectCases,
    updateFinalScore,
    updateSort,
    getCaseRegion,
    caseTeamApi,
    caseRetireFlagApi,
    fetchCaseAwardCert,
    resetCaseAwardCert,
} from "@services/Selection";
import { getAuthDictionary } from '@/services/Selection/case';
import "./index.less";

const { Content } = Layout;

const assignmentSelect = [
    { label: '全部', value: 0 },
    { label: '已分配评委案例', value: 1 },
    { label: '未分配评委案例', value: 2 },
];
const filesSelect = [
    { label: '全部', value: 0 },
    { label: '已上传', value: 1 },
    { label: '未上传', value: 2 },
];
const teamsSelect = [
    { label: '全部', value: 0 },
    { label: '已添加', value: 1 },
    { label: '未添加', value: 2 },
];

const casesColumns = [
    {
        title: "案例名称",
        dataIndex: "key",
        align: "topicName",
        render: (_, row) => {
            let fieldValue;
            if (row.fieldValue) {
                fieldValue = JSON.parse(row.fieldValue);
            }
            return <div>
                <div>{fieldValue.title}</div>
                <div>#{row.id}</div>
            </div> ?? "-";
        },
    },
    {
        title: "申报机构",
        dataIndex: "hospitalName",
        align: "center",
    },
    {
        title: "提报用户",
        dataIndex: "nickName",
        align: "center",
    },
    {
        title: "提报时间",
        dataIndex: "createdAt",
        align: "center",
    },
];

const ScoreStageTopicList = () => {
    const params = useParams();
    const navigate = useNavigate();
    const local = useLocation();

    const [form] = Form.useForm();
    const [paginat, setPaginat] = useState({ page: 1, size: 10 });
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState({ list: [], totalCount: 0 });
    const [currentSort, setCurrentSort] = useState(null);

    const [topicForm] = Form.useForm();
    const [casesModal, setCasesModal] = useState(false);
    const [selectTableLoading, setSelectTableLoading] = useState(false);
    const [casesPaginat, setCasesPaginat] = useState({ page: 1, size: 10 });
    const [casesDataSource, setCasesDataSource] = useState({ list: [], totalCount: 0 });
    const [casesRegions, setCasesRegion] = useState([{ value: "全部赛区", label: "全部赛区" }]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [memberModalOpen, setMemberModalOpen] = useState(false);
    const [currentCase, setCurrentCase] = useState(undefined);
    const [memberDatas, setMemberDatas] = useState([]);
    const [memberLoading, setMemberLoading] = useState(false);


    const [departments, setDepartments] = useState(undefined); // ksh
    const [posts, setPosts] = useState(undefined); // zhw
    const [titles, setTitles] = useState(undefined); // zhch


    const initList = () => {
        getAuthDictionary({ identity: 'A01', type: 'KSH' })
            .then((res) => {
                if (res) {
                    setDepartments(res.data);
                }
            });
        getAuthDictionary({ identity: 'A01', type: 'ZHW' })
            .then((res) => {
                if (res) {
                    setPosts(res.data);
                }
            });
        getAuthDictionary({ identity: 'A01', type: 'ZHCH' })
            .then((res) => {
                if (res) {
                    setTitles(res.data);
                }
            });
    };

    // 获取字典名称
    const getDicName = (datas, dicCode) => {
        console.log(datas);
        let name = '';
        datas.forEach(item => {
            if (item.dicCode === dicCode) {
                name = item.dicName;
            } else {
                item.children && item.children.forEach(i => {
                    if (i.dicCode === dicCode) {
                        name = i.dicName;
                    }
                });
            }
        });

        return name;
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
    };

    useEffect(() => {
        getCaseRegion({ projectId: params.pid }).then((res) => {
            if (res.status_code === 200) {
                const regions = JSON.parse(res?.data?.regions || "[]");
                setCasesRegion((draft) => [...draft, ...regions.map((item) => ({ value: item.name, label: item.name }))]);
            }
        });
    }, [params.pid]);

    const [fractionModal, setFractionModal] = useState(false);
    const [finallyScore, setFinallyScore] = useState({ caseid: "", score: 0 });
    const [inputScore, setInputScore] = useState("");

    const fetchList = useCallback(
        ({ page, size }) => {
            setTableLoading(true);
            const formData = form.getFieldsValue();
            fetchCasescoresList({ projectId: params.pid, stageId: params.sid, topicId: params.tid, ...formData, page, size })
                .then((res) => {
                    if (res?.status_code === 200) {
                        setDataSource({ list: res.data?.list.map((i, index) => ({ ...i, index })), totalCount: res.data.totalCount });
                    }
                })
                .finally(() => {
                    setTableLoading(false);
                });
        },
        [form, params]
    );

    useEffect(() => {
        initList();
        fetchList(paginat);
    }, [fetchList, paginat]);

    const fetchSelectCasesList = useCallback(
        ({ page, size }) => {
            setSelectTableLoading(true);
            const formData = topicForm.getFieldsValue();
            if (formData.region === '全部赛区') {
                delete formData.region;
            }

            fetchSelectCaseList({ stageId: params.sid, topicId: params.tid, ...formData, page, size })
                .then((res) => {
                    if (res?.status_code === 200) {
                        setCasesDataSource({ list: res.data?.records.map((i, index) => ({ ...i, index })), totalCount: res.data.total });
                    }
                })
                .finally(() => {
                    setSelectTableLoading(false);
                });
        },
        [params.sid, params.tid, topicForm]
    );

    useEffect(() => {
        fetchSelectCasesList(casesPaginat);
    }, [casesPaginat, fetchSelectCasesList]);

    const onClickMenu = async (e, row) => {
        console.log(e, row);
        switch (e.key) {
            case 'reset-cert':
                const res = await resetCaseAwardCert({
                    projectId: params.pid,
                    topicId: params.tid,
                    stageId: params.sid,
                    caseId: row.caseId,
                });
                if (res.status_code === 200) {
                    message.success('证书重置成功');
                    fetchList(paginat);
                }
                break;
            default:
                break;
        }
    };

    const onViewCert = async row => {
        // console.log(row);
        const res = await fetchCaseAwardCert({
            projectId: params.pid,
            stageId: params.sid,
            caseId: row.caseId,
        });
        if (res.status_code === 200) {
            console.log(res);
            const url = res.data?.imageUrl || '';
            if (/^https?:\/\//i.test(url)) {
                window.open(url, '_target');
            }
        }
    };

    const columns = useMemo(() => {
        const handleSortBlur = (value, current) => {
            setCurrentSort(null);
            if (!value || current === value) {
                return;
            }
            updateSort({ projectId: params.pid, stageId: params.sid, topicId: params.tid, caseId: currentSort, sort: +value }).then((res) => {
                if (res.status_code === 200) {
                    setPaginat((d) => ({ page: 1, size: d.size }));
                }
            });
        };

        const handleShowInput = (caseId) => {
            setCurrentSort(caseId);
        };

        return [
            {
                title: "排序",
                dataIndex: "sort",
                align: "center",
                width: 120,
                render: (row, data) => {
                    return currentSort === data.caseId ? <InputNumber onBlur={(e) => handleSortBlur(e.target.value)} autoFocus precision={0}/> : <span onClick={() => handleShowInput(data.caseId, row)}>{row ?? "-"}</span>;
                },
            },
            {
                title: "申报名称",
                dataIndex: "caseName",
                render: (row, data) => {
                    return (<>
                            <div
                                className="lasttime-span"
                                onClick={() => {
                                    navigate(`/selection/cases/${data.caseId}`);
                                }}
                            >
                                {!!data.retireFlag && <Tag color="#f50" style={{ padding: '0 4px' }}>弃赛</Tag>}{row}
                            </div>
                            <div>#{data.caseId}</div>
                        </>
                    );
                },
            },
            {
                title: "申报机构",
                dataIndex: "hospitalName",
                align: "center",
                render: (row) => row ?? "-",
            },
            {
                title: "参赛文件",
                dataIndex: "caseAttachmentStatus",
                align: "center",
                render: (row) => {
                    return (
                        <>
                            {row === undefined && <span className="redSpan">未上传</span>}
                            {row === 0 && <><span className="lasttime-span">已上传</span><br/><small>审核中</small></>}
                            {row === 1 && <><span className="lasttime-span">下载</span><br/><small>审核通过</small></>}
                            {row === 2 && <><span className="lasttime-span">已上传</span><br/><small>驳回</small></>}
                        </>
                    );
                },
            },
            {
                title: "参赛团队",
                dataIndex: "teamMembers",
                align: "center",
                render: (row, data) => {
                    return <span className="lasttime-span" onClick={() => openMemberManager(data)}>{row === 0 ? "未参加" : `${row}人`}</span>
                },
            },
            {
                title: "评委/已评",
                key: "id",
                align: "center",
                render: (row) => {
                    return (
                        <span className="lasttime-span" onClick={() => navigate(`/selection/projects/${params.pid}/score-stages/${params.sid}/topics/${params.tid}/cases/${row.caseId}`, { state: { decimalPlaceNum: local?.state?.decimalPlaceNum } })}>
                            {row.judgesNumber ?? "-"}/{row.scoredNumber ?? "-"}
                        </span>
                    );
                },
            },
            {
                title: "平均得分",
                dataIndex: "score",
                align: "center",
                render: (row, data) => {
                    return (!data.scoredNumber && data.scoredNumber !== 0) ? "-" : row ?? "-";
                },
            },
            {
                title: "最终分数",
                dataIndex: "finalScore",
                align: "center",
                render: (row, data) => {
                    return (
                        <span
                            className="lasttime-span"
                            onClick={() => {
                                setFractionModal(true);
                                setFinallyScore({ score: data.finalScore, id: data.caseId });
                            }}
                        >
                            {(!data.scoredNumber && data.scoredNumber !== 0) && data.score === data.finalScore ? "-" : row ?? "-"}
                        </span>
                    );
                },
            },
            {
                title: '完赛证书',
                dataIndex: 'finishCert',
                width: 110,
                render: (_, row) => {
                    const menuProps = {
                        items: [
                            { label: '重置证书', key: 'reset-cert', danger: true }
                        ],
                        onClick: e => onClickMenu(e, row),
                    };
                    return !!row?.caseAwardCertificateStatus
                        ? <Dropdown.Button size="small" menu={menuProps} onClick={() => onViewCert(row)}>查看证书</Dropdown.Button>
                        : <Typography.Text type="secondary">未生成</Typography.Text>
                }
            },
            {
                title: "操作",
                width: 200,
                dataIndex: "id",
                align: "center",
                render: (row, record) => {
                    return (
                        <React.Fragment>
                            <Button
                                type="link"
                                onClick={() => {
                                    caseRetireFlagApi({ id: row }).then((res) => {
                                        if (res?.status_code === 200) {
                                            message.success("操作成功！");
                                            fetchList(paginat);
                                        }
                                    });
                                }}
                            >
                                {record.retireFlag ? "恢复为参赛" : "标记为弃赛"}
                            </Button>
                            <Button
                                type="link"
                                onClick={() => {
                                    deleteCasescoresCase({ id: row }).then((res) => {
                                        if (res?.status_code === 200) {
                                            message.success("删除成功！");
                                            setPaginat((draft) => ({ ...draft }));
                                            setCasesPaginat({ page: 1, size: 10 });
                                        }
                                    });
                                }}
                            >
                                移除案例
                            </Button>
                        </React.Fragment>
                    );
                },
            },
        ];
    }, [currentSort, local?.state?.decimalPlaceNum, navigate, params.pid, params.sid, params.tid]);

    const teamStageColumns = [
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '科室部门',
            key: 'memOffice',
            dataIndex: 'memOffice',
            render: (_, record) => (
                <>
                    {!!record.memOffice && getDicName(departments, record.memOffice)}
                </>
            ),
        },
        {
            title: '职务',
            key: 'memPost',
            dataIndex: 'memPost',
            render: (_, record) => (
                <>
                    {!!record.memPost && getDicName(posts, record.memPost)}
                </>
            ),
        },
        {
            title: '职称',
            key: 'memJobtitle',
            dataIndex: 'memJobtitle',
            render: (_, record) => (
                <>
                    {!!record.memJobtitle && getDicName(titles, record.memJobtitle)}
                </>
            ),
        },
        {
            title: '任务',
            dataIndex: 'memTask',
            key: 'memTask'
        },
    ];

    const header = useMemo(() => {
        const checkList = () => {
            navigate(`/selection/projects/${params.pid}/score-stages/${params.sid}/topics/${params.tid}/cases/list`);
        };

        const selectList = () => {
            if (!casesDataSource.totalCount) {
                return message.warning("无参评案例!");
            }
            setCasesModal(true);
        };

        const searching = () => {
            setPaginat((draft) => ({ page: 1, size: draft.size }));
        };

        const resetSelect = () => {
            form.resetFields();
            setPaginat((draft) => ({ page: 1, size: draft.size }));
        };

        return (
            <>
                <div className="cases-table-header">
                    <Form form={form} name="cases-form" initialValues={{ assignedState: 0 }} layout="inline">
                        <Form.Item name="caseName" label="申报名称">
                            <Input placeholder="名称" style={{ width: 140 }}/>
                        </Form.Item>
                        <Form.Item name="caseId" label="申报ID">
                            <Input placeholder="申报ID" style={{ width: 140 }}/>
                        </Form.Item>
                        <Form.Item name="assignedState" label="案例分配">
                            <Select style={{ width: 140 }} options={assignmentSelect}/>
                        </Form.Item>
                        <Form.Item name="filesState" label="参赛文件">
                            <Select style={{ width: 140 }} options={filesSelect}/>
                        </Form.Item>
                        <Form.Item name="teamsState" label="参赛团队">
                            <Select style={{ width: 140 }} options={teamsSelect}/>
                        </Form.Item>
                    </Form>

                    <div className="assignment-buttons">
                        <Button onClick={resetSelect}>重置</Button>
                        <Button type="primary" onClick={searching}>
                            检索
                        </Button>
                    </div>
                </div>
                <div className="cases-table-header-button">
                    <Button type="primary" onClick={checkList}>
                        核对榜单
                    </Button>
                    <Button type="primary" onClick={selectList}>
                        选择参评案例
                    </Button>
                </div>
            </>
        );
    }, [casesDataSource.totalCount, form, navigate, params.pid, params.sid, params.tid]);

    const casesHeader = useMemo(() => {
        const submit = () => {
            setCasesPaginat({ page: 1, size: 10 });
        };

        return (
            <div className="cases-table-casesHeader">
                <Form form={topicForm} name="cases-form" initialValues={{ region: "全部赛区" }} layout="inline">
                    <div className="form-flex-wrapper">
                        <Form.Item name="region" label="赛区">
                            <Select style={{ width: 140 }} options={casesRegions}/>
                        </Form.Item>
                        <Form.Item name="caseId" label="申报ID">
                            <Input placeholder="申报ID" style={{ width: 140 }}/>
                        </Form.Item>
                        <Form.Item name="caseName" label="案例名称">
                            <Input placeholder="名称" style={{ width: 140 }}/>
                        </Form.Item>
                        <Form.Item name="hospitalName" label="提报单位">
                            <Input style={{ width: 140 }}/>
                        </Form.Item>

                        <Button type="primary" onClick={submit}>
                            检索
                        </Button>
                    </div>

                    <div className="cases-table-casesHeader-count">
                        <span>共{casesDataSource.totalCount}案例</span>
                        <span>已选{selectedRowKeys.length}案例</span>
                        <span>未选{casesDataSource.totalCount - selectedRowKeys.length}案例</span>
                    </div>
                </Form>
            </div>
        );
    }, [casesDataSource.totalCount, casesRegions, selectedRowKeys.length, topicForm]);
    const openMemberManager = (data) => {
        setMemberDatas([]);

        setCurrentCase(data);
        setMemberModalOpen(true);
        getlistByStageId(data.caseId, data.stageId);
    };
    const getlistByStageId = (caseId, stageId) => {
        setMemberLoading(true)
        caseTeamApi({
            caseId,
            stageId
        }).then((res) => {
            setMemberLoading(false)
            if (res?.status_code === 200) {
                setMemberDatas(res.data)
            }
        }).catch(err => {
            setMemberLoading(false)
        });
    };

    const onDataSourcePagination = (page, size) => {
        setPaginat({ page, size });
    };

    const selectCasesPaginationChange = (page, size) => {
        setCasesPaginat({ page, size });
    };

    const casesFooter = useMemo(() => {
        const submitSelect = () => {
            if (selectedRowKeys.length) {
                saveSelectCases({ projectId: params.pid, stageId: params.sid, topicId: params.tid, caseIds: selectedRowKeys }).then((res) => {
                    if (res?.status_code === 200) {
                        message.success("添加成功！");
                        setCasesModal(false);
                        setPaginat((draft) => ({ page: 1, size: draft.size }));
                    }
                });
            } else {
                setCasesModal(false);
            }
        };

        return (
            <div className="cases-table-footer">
                <Pagination className="my-pagintation-class" showTotal={() => `共${casesDataSource.totalCount}条 ${Math.ceil(casesDataSource.totalCount / casesPaginat.size)} 页`} total={casesDataSource.totalCount} showSizeChanger showQuickJumper onChange={selectCasesPaginationChange} current={casesPaginat.page} size={casesPaginat.size}/>
                <Button type="primary" onClick={submitSelect}>
                    确定
                </Button>
            </div>
        );
    }, [casesDataSource.totalCount, casesPaginat.page, casesPaginat.size, params.pid, params.sid, params.tid, selectedRowKeys]);

    const closeCasesModal = () => {
        setCasesPaginat((draft) => ({ page: 1, size: draft.size }));
        setSelectedRowKeys([]);
        topicForm.resetFields();
    };

    const submitInputScore = () => {
        if (!inputScore) {
            return setFractionModal(false);
        }

        updateFinalScore({ projectId: params.pid, stageId: params.sid, topicId: params.tid, caseId: finallyScore.id, finalScore: inputScore }).then((res) => {
            if (res?.status_code === 200) {
                message.success("修改成功！");
                setFractionModal(false);
                setPaginat((draft) => ({ ...draft }));
            }
        });
    };

    return (
        <>
            <div className="page-title">
                <span className="back" onClick={() => navigate(-1)}>
                    <LeftOutlined/>
                </span>
                <span className="current-title">评分案例管理</span>
            </div>

            <Content className="main-content-box cases-table-content">
                <Table dataSource={dataSource.list} columns={columns} title={() => header} rowKey="index" pagination={false} loading={tableLoading}/>
                <Pagination className="my-pagintation-class" showTotal={() => `共${dataSource.totalCount}条 ${Math.ceil(dataSource.totalCount / paginat.size)} 页`} total={dataSource.totalCount} showSizeChanger showQuickJumper onChange={onDataSourcePagination} current={paginat.page} size={paginat.size}/>
            </Content>

            <Modal centered width={1200} title="参评案例选择" footer={casesFooter} open={casesModal} onCancel={() => setCasesModal(false)} afterClose={closeCasesModal} className="cases-modal-wrapper">
                <Table dataSource={casesDataSource.list} columns={casesColumns} title={() => casesHeader} rowKey="id" pagination={false} loading={selectTableLoading} rowSelection={rowSelection}/>
            </Modal>

            <Modal
                centered
                width={400}
                title="平均得分"
                open={fractionModal}
                onOk={submitInputScore}
                onCancel={() => {
                    setFractionModal(false);
                }}
                afterClose={() => setInputScore("")}
            >
                <div className="modal-fraction-score">平均得分：{finallyScore.score === 0 || finallyScore.score ? `${finallyScore.score}分` : "无"}</div>
                <div className="modal-flex-div">
                    <span>修改案例最终分：</span>
                    <InputNumber value={inputScore} onChange={setInputScore} className="flex-span-3" min={0} max={100} precision={local?.state?.caseFinalScoreDecimalPlaceNum ?? 2} style={{ width: 160 }}/>
                </div>
            </Modal>

            <Modal
                open={memberModalOpen}
                title='管理人员'
                footer={null}
                onCancel={() => setMemberModalOpen(false)}
                centered
                closable
                width={'1000px'}
                bodyStyle={{
                    width: '100%',
                    padding: '20px 30px',
                    minHeight: "600px",
                    zIndex: '100000000'
                }}
            >
                <div className="teamMemberHeader">
                    <h2>
                        <Tag color='#FF6A00'>赛段</Tag>
                        {currentCase?.stageName}
                    </h2>
                    <p>项目：<span>{currentCase?.projectName}</span></p>
                    <p>案例：<span>{currentCase?.caseName}</span></p>
                    <div>
                        <p>编号：<span>{currentCase?.id}</span></p>
                        {currentCase?.region && <p>赛区：<span>{currentCase?.region}</span></p>}
                        <p>主题：<span>{currentCase?.topicName}</span></p>
                    </div>
                </div>
                <Table columns={teamStageColumns} dataSource={memberDatas} pagination={false} loading={memberLoading}/>
            </Modal>
        </>
    );
};

export default ScoreStageTopicList;
