import React, { Fragment, useState, useEffect } from 'react';
import UsersFilterForm from './UsersFilterForm';
import UserListTable from './UserListTable';
import { Button, Layout, Space, Checkbox, Row, Col, Popover, message } from 'antd';
import { RedoOutlined, DownloadOutlined, SettingFilled } from '@ant-design/icons';
import { timeString } from '@/utils/utils';
import { get } from '@/core/request';

const { Content } = Layout;

export default function Users() {
    // 复选框要筛选的列
    const initialColumn = [
        { name: '用户唯一标识', checked: true, value: 0 }, { name: '昵称', checked: true, value: 1 },
        { name: '真实姓名', checked: false, value: 2 }, { name: '性别', checked: false, value: 3 },
        { name: '手机号', checked: true, value: 4 }, { name: '邮箱', checked: false, value: 5 },
        { name: '用户身份', checked: true, value: 6 }, { name: '会员类别', checked: true, value: 7 }, { name: '单位名称', checked: true, value: 8 },
        { name: '科室', checked: true, value: 9 }, { name: '职务', checked: true, value: 10 },
        { name: '职称', checked: true, value: 11 }, { name: '注册端口', checked: false, value: 12 },
        { name: '用户状态', checked: false, value: 13 }, { name: '注册时间', checked: false, value: 14 },
        { name: '认证审核时间', checked: false, value: 15 }, { name: '最后登录时间', checked: false, value: 16 }
    ];

    // 要展示的列
    const [showColumn, setShowColumn] = useState(initialColumn);
    const AllCheckedList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    const defaultCheckedList = [0, 1, 4, 6, 7, 8, 9, 10, 11];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    // 单机选项
    const handelShowColumn = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < AllCheckedList.length);
        setCheckAll(checkedValues.length === AllCheckedList.length);
        let res = initialColumn;
        res.forEach(item => {
            item.checked = checkedValues.includes(item.value);
        });
        setShowColumn([...res]);
    }

    // 点击全选
    const handleChangeField = (e) => {
        setCheckedList(e.target.checked ? AllCheckedList : [0]);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        let res = initialColumn;
        if (e.target.checked) {
            res.forEach(item => {
                item.checked = AllCheckedList.includes(item.value);
            });
        } else {
            res.forEach(item => {
                item.checked = false;
                if (item.name === '用户唯一标识') item.checked = true;
            });
        }
        setShowColumn([...res]);
    }

    // 点击重置
    const handleChangeReset = () => {
        let res = initialColumn;
        res.forEach(item => {
            item.checked = defaultCheckedList.includes(item.value);
        });
        setCheckedList(defaultCheckedList);
        setShowColumn([...res]);
        setIndeterminate(true);
        setCheckAll(false);
    }

    // 字段设置样式
    const pupupContent = (
        <div className="field-change-box">
            <div className="field-change-header">
                <Checkbox indeterminate={indeterminate} onChange={handleChangeField} checked={checkAll} className="field-change-header-left">
                    显示字段
                </Checkbox>
                <span className="field-change-header-right" onClick={handleChangeReset}>重置</span>
            </div>
            <Checkbox.Group
                value={checkedList}
                style={{ width: '100%' }}
                onChange={handelShowColumn}
                className="field-change-bottom"
            >
                {
                    showColumn.map(item => (
                        <Row key={item.value} style={{ marginBottom: '5px' }}>
                            <Col span={24}>
                                <Checkbox value={item.value} disabled={item.value === 0 ? true : false}>{item.name}</Checkbox>
                            </Col>
                        </Row>
                    ))
                }
            </Checkbox.Group>
        </div>
    );

    // 科室
    const [officeData, setOfficeData] = useState([]);
    // 职务
    const [postData, setPostData] = useState([]);
    // 职称
    const [titleData, setTitleData] = useState([]);

    // 科室 职务 职称
    useEffect(() => {
        get('user/dictionary/office/detail', { type: 2 }).then((res) => {
            setOfficeData(res.data)
        })
        get('/user/dictionary/board', { type: 3 }).then((res) => {
            setPostData(res.data)
        })
        get('/user/dictionary/board', { type: 4 }).then((res) => {
            setTitleData(res.data)
        })
    }, []);
    // 渲染table数据的state
    const [tableData, setTableData] = useState(null);
    const [filterObj, setFilterObj] = useState({});

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
        setCurrPage(1);
    };

    const { memCard, memDeviceType, memEmail, memExamineEndTime, memExamineStartTime, memIdentity, memJobtitle, memSex, memType, memUnit, memUsername, memState,
        memLastestLoginEndTime, memLastestLoginStartTime, memMobile, memNickname, memOffice, memPost, memRegistEndTime, memRegistStartTime, memIdentityType
    } = filterObj;

    const getData = () => {
        get(`/user/data/statistics/users-detail`, {
            limit: pageSize,
            page: currPage,
            memCard, memDeviceType, memEmail, memExamineEndTime, memExamineStartTime, memIdentity, memJobtitle, memSex, memType, memUnit, memUsername, memState,
            memLastestLoginEndTime, memLastestLoginStartTime, memMobile, memNickname, memOffice, memPost, memRegistEndTime, memRegistStartTime, memIdentityType
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                if (list) {
                    list.forEach(item => {
                        item.key = item.memCard;
                    });
                    setTableData(res.data);
                }

            }
        })
    };

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, memCard, memDeviceType, memEmail, memExamineEndTime, memExamineStartTime, memIdentity, memJobtitle, memSex, memType, memUnit, memUsername, memState,
        memLastestLoginEndTime, memLastestLoginStartTime, memMobile, memNickname, memOffice, memPost, memRegistEndTime, memRegistStartTime, memIdentityType]);

    // 导出数据
    const handleExportFile = () => {
        if (tableData && tableData.totalCount > 10000) {
            message.error('当前筛选数据超过1万条，无法导出!')
            return;
        }
        get(`/user/data/statistics/users-detail-download`, {
            limit: pageSize,
            page: currPage,
            memCard, memDeviceType, memEmail, memExamineEndTime, memExamineStartTime, memIdentity, memIdentity, memJobtitle, memSex, memType, memUnit,
            memUsername, memLastestLoginEndTime, memLastestLoginStartTime, memMobile, memNickname, memOffice, memPost, memRegistEndTime, memRegistStartTime, memIdentityType
        }, { responseType: 'blob' }).then((res) => {
            if (res.size !== 0) {
                let date = new Date();
                let dateString = timeString(date);
                message.success('请稍后，正在导出');
                const fileName = `用户列表${dateString}.xlsx`;
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }

        })
    };

    const [refreshBool, setRefreshBool] = useState(false);

    // 点击刷新
    const handleRefresh = () => {
        message.info('正在刷新', 1);
        setRefreshBool(true);
    }

    return (
        <Fragment>
            <div className="page-title">用户管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <UsersFilterForm
                        setCurrPage={setCurrPage}
                        officeData={officeData}
                        postData={postData}
                        titleData={titleData}
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-toolbar">
                            <Space className="user-table-toolbar">
                                <Button type="default" onClick={handleExportFile}><DownloadOutlined />导出</Button>
                                <RedoOutlined className="refresh-icon" onClick={handleRefresh} />
                                <Popover placement="bottomRight" content={pupupContent} trigger="click">
                                    <SettingFilled className="refresh-icon" />
                                </Popover>
                            </Space>
                        </div>
                    </div>

                    <UserListTable
                        setRefreshBool={setRefreshBool}
                        refreshBool={refreshBool}
                        setCurrPage={setCurrPage}
                        currPage={currPage}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        tableData={tableData}
                        showColumn={showColumn}></UserListTable>

                </div>
            </Content>

        </Fragment>
    )
}
