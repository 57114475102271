import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filter: {
        page: 1,
        pageSize: 20,
    },
    cursor: 0,
};

const expertSlice = createSlice({
    name: 'expert',
    initialState,
    reducers: {
        setFilter: (state, action) => void(state.filter = action.payload),
        setCursor: (state, action) => {
            state.cursor = action.payload;
        },
    }
});

export const expertActions = expertSlice.actions;

export default expertSlice;
