import {
    get,
    put,
    post,
    delet,
} from '@core/request';

/**
 * 投票管理-特权用户api
 */

//  获取特权用户列表
export const getPrivilegeUsersApi = (params) => {
    return get(`/selection/boss/casePrivilegeUsers/pageList`, params);
}

// 新增特权用户
export const addPrivilegeUserApi = (data) => {
    return post(`/selection/boss/casePrivilegeUsers/save`, data);
}

// 删除特权用户
export const deletePrivilegeUsersApi = (data) => {
    return delet(`/selection/boss/casePrivilegeUsers/delete`, data);
}

// 修改特权用户
export const putPrivilegeUserApi = (data) => {
    return put(`/selection/boss/casePrivilegeUsers/update`, data);
}

// 批量导入特权用户-预校验
export const checkPrivilegeUsersApi = (data) => {
    return post(`/selection/boss/casePrivilegeUsers/import-check`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

// 批量导入特权用户
export const importPrivilegeUsersApi = (data) => {
    return post(`/selection/boss/casePrivilegeUsers/import`, data);
}

/**
 * 投票管理-案例管理api
 */

// 投票主题案例列表
export const getTopicCasesApi = (params) => {
    return get(`/selection/admin/case/selectSelectionCaseList`, params);
}

// 修改案例配置
export const updateCaseVoteApi = (data) => {
    const url = `/selection/admin/case/updateSelectionCaseVote`;
    // return put(`/selection/admin/case/updateSelectionCaseVote`, data);
    return put(url, data);
}

// 获取投票主题列表
export const fetchCasevotetopicsPage = (params) => {
    return get(`/selection/casevotetopics/page`, params);
}

// 待选择的主题列表
export const fetchCandidateTopics = (params) => {
    return get(`/selection/casevotetopics/candidateTopics`, params);
}

// 新增参与投票的主题
export const saveCandidateTopics = (params) => {
    return post(`/selection/casevotetopics/save`, params);
}

// 修改参与投票的主题
export const updataVoteSetting = (params) => put('/selection/casevotetopics/update', params)

// 删除参与投票的主题
export const deleteCasevotetopics = (data) => {
    return delet(`/selection/casevotetopics/delete`, data);
}