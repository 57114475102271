import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Layout, Input, Table, Row, Col, Space, Tree, Menu, Dropdown, Modal, Form, Select, Tag, Radio, message } from 'antd';
import { PlusCircleOutlined, ReloadOutlined, ExclamationCircleOutlined, SearchOutlined, MoreOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import PermissionAdd from './PermissionAdd';
import PermissionSort from './PermissionSort';
import { get, post, put, delte } from '@/core/request';
import { getTreeObj } from '@utils/utils';
import { throttle, debounce } from 'lodash';
import './PermissionList.less'

const { Content } = Layout;

export default function PermissionList() {
    const [form] = Form.useForm();
    const [treeState, setTreeState] = useState({
        searchValue: '', expandedKeys: [], autoExpandParent: true, selectedKeys: []
    });
    const [tagString, setTagString] = useState('');
    const [treeData, setTreeData] = useState([]);
    // 编辑分组信息
    const [editGroup, setEditGroup] = useState(null);
    const [moduleState, setModuleState] = useState({
        moduleList: [],
        groupList: []
    });

    // 新增权限分组的所属业务模块修改
    const handleModuleChange = (value) => {
        if (value) {
            let obj = getTreeObj(moduleState.moduleList, [value])
            setModuleState({
                ...moduleState,
                groupList: obj.groupList
            })
            form.setFieldsValue({
                pid: undefined
            })
        }
    }
    // 传给后端的权限分组state
    const [groupState, setGroupState] = useState({
        moduleCode: '',
        groupId: undefined,
        type: undefined
    })

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = (pageSize) => {
        setPageSize(pageSize)
    };

    // 改变第几页
    const changeCurrPage = (page) => {
        setCurrPage(page);
    };

    // 渲染table数据
    const getData = () => {
        get(`/enterprise/permissions/query_page`, {
            limit: pageSize,
            page: currPage,
            moduleCode: groupState.moduleCode,
            groupId: groupState.groupId,
            type: groupState.type
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }

    useEffect(() => {
        getData()
    }, [currPage, pageSize, groupState.moduleCode, groupState.groupId, groupState.type]);

    // 渲染 Tree数据
    const getTreeData = () => {
        let arr = [];
        let moduleArr = [];
        get(`/enterprise/permissions/get_group_modules`).then((res) => {
            if (res.status_code === 200) {
                arr = res.data.map(item => {
                    moduleArr.push(item);
                    setModuleState({
                        moduleList: moduleArr,
                        groupList: []
                    })
                    return {
                        ...item,
                        title: item.moduleName,
                        key: item.moduleId,
                        children: item.groupList && item.groupList.map((elem) => {
                            return {
                                ...elem,
                                title: elem.groupName,
                                key: elem.groupId,
                                children: elem.secondGroupList && elem.secondGroupList.map(element => {
                                    return {
                                        ...element,
                                        title: element.groupName,
                                        key: element.groupId,
                                    }
                                })
                            }
                        })
                    }
                })
                setTreeData(arr);
            }
        })
    }

    useEffect(() => {
        getTreeData()
    }, []);

    // 新增权限分组
    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [tip, setTip] = useState('');

    const handleAddPermissionGroup = (tip, row) => {
        return () => {
            setTip(tip)
            setIsModalVisible(true);
        }
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                if (!values.moduleCode) return message.warning('所属业务模块不能为空')
                if (!values.name) return message.warning('权限分组名称不能为空')
                if (tip === 'add') {
                    values.weight = 0;
                    post(`/enterprise/groups/save-group`, values).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getTreeData();
                        }
                    })
                } else {
                    values.id = editGroup.id
                    put(`/enterprise/groups/update`, values).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getTreeData();
                        }
                    })
                }
                handleCancel();
            })
            .catch((err) => { });
        setPedding(false);

    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    // 删除权限分组
    const deleteGroupConfirm = (element) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确认是否删除该权限分组',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                let form = new FormData();
                form.append("id", element.groupId)
                delte('/enterprise/groups/delete', form).then(res => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getTreeData();
                        setTagString('');
                        setTreeState({
                            ...treeState,
                            selectedKeys: []
                        })
                        setGroupState({
                            ...groupState,
                            moduleCode: undefined,
                            groupId: undefined
                        })
                    }
                })
            }
        });
    };

    const permissionSortModal = useRef();
    // tree一级菜单点击
    const handleParentMenuClick = (elem) => {
        return (e) => {
            permissionSortModal.current.show(elem);
        }
    }

    // tree二级菜单点击
    const handleChildrenMenuClick = (elem) => {
        return (e) => {
            if (e.key === 'edit') {
                setTip('edit')
                setIsModalVisible(true);
                get(`/enterprise/groups/get`, { id: elem.groupId }).then(res => {
                    if (res.status_code === 200) {
                        setEditGroup(res.data)
                        let obj = getTreeObj(moduleState.moduleList, [res.data.modeleCode])
                        if (obj) {
                            setModuleState({
                                ...moduleState,
                                groupList: obj.groupList
                            })
                            form.setFieldsValue({
                                moduleCode: res.data.modeleCode,
                                pid: res.data.pid,
                                name: res.data.name
                            })
                        }

                    }
                })
            } else {
                let bool = elem.children && elem.children.length > 0
                if (bool || elem.allPermissions) {
                    return message.error('该分组下有子分组或权限，禁止删除！');
                }
                deleteGroupConfirm(elem)
            }
        }
    }

    const handleMouseEnter = (e) => {
        if (e.target.className === 'edit') {
            e.target.classList.add('mystyle')
        }
    }

    const handleMouseLeave = (e) => {
        e.target.classList.remove('mystyle')
    }

    const handleMouseLeaveInner = (e) => {
        e.currentTarget.parentNode.classList.remove('mystyle')
    }


    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const onExpand = expandedKeys => {
        setTreeState({
            ...treeState,
            expandedKeys,
            autoExpandParent: false
        });
    };
    const handleClickSearch = debounce(e => {
        const { value } = e.target;
        const getAllNode = (expandedKeys, moduleDataList) => {
            moduleDataList.forEach(item => {
                expandedKeys.push({ key: item.key, title: item.title })
                if (item.children) {
                    expandedKeys = getAllNode(expandedKeys, item.children)
                }
            })
            return expandedKeys
        }
        let dataList1 = getAllNode([], treeData)
        const expandedKeys = dataList1
            .map(item => {
                if (item.title.indexOf(value) > -1) {
                    return getParentKey(item.key, treeData);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        if (expandedKeys.length === 0) {
            message.info('没有搜索到对应的权限分组')
        }
        setTreeState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    }, 500);

    // 搜索数据
    const loop = data =>
        data.map(item => {
            let arrFirst = [];
            let arrSecond = [];
            let arrThird = [];
            treeData.forEach(item => {
                arrFirst.push(item.key)
                item.children?.forEach(elem => {
                    arrSecond.push(elem.key)
                    elem.children?.forEach(element => {
                        arrThird.push(element.key)
                    })
                })
            });
            let level = 0;
            let left = '';
            if (arrFirst.indexOf(item.key) !== -1) {
                level = 1;
                left = '185px'
            }
            if (arrSecond.indexOf(item.key) !== -1) {
                level = 2;
                left = '160px'
            }
            if (arrThird.indexOf(item.key) !== -1) {
                level = 3;
                left = '135px'
            }
            const index = item.title.indexOf(treeState.searchValue);
            const beforeStr = item.title.substring(0, index);
            const afterStr = item.title.slice(index + treeState.searchValue.length);
            const menu = (
                <Menu onClick={handleParentMenuClick(item)}>
                    <Menu.Item key="sort">权限排序</Menu.Item>
                </Menu>
            );
            const childrenMenu = (
                <Menu onClick={handleChildrenMenuClick(item)}>
                    <Menu.Item key="edit">编辑权限分组</Menu.Item>
                    <Menu.Item key="delete">删除权限分组</Menu.Item>
                </Menu>
            );
            const title =
                index > -1 ? (
                    <div className="edit" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <span>
                            {beforeStr}
                            <span className="site-tree-search-value">{treeState.searchValue}</span>
                            {afterStr}
                        </span>
                        <div className='tree-edit' onMouseLeave={handleMouseLeaveInner} style={{ left }}>
                            <Dropdown overlay={level === 1 ? menu : childrenMenu} size='small' className='permission-more-icon' placement="bottomRight">
                                <MoreOutlined />
                            </Dropdown>
                        </div>
                    </div>
                ) : (
                    <div className="edit" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <span>{item.title}</span>
                        <div className='tree-edit' onMouseLeave={handleMouseLeaveInner} style={{ left }}>
                            <Dropdown overlay={level === 1 ? menu : childrenMenu} size='small' className='permission-more-icon' placement="bottomRight">
                                <MoreOutlined />
                            </Dropdown>
                        </div>
                    </div>
                );
            if (item.children) {
                return { title, key: item.key, children: loop(item.children) };
            }

            return {
                title,
                key: item.key,
            };
            // if (level === 1 || level === 2) {
            //     return <TreeNode dataRef={item} key={item.key} title={title} />;
            // } else {
            //     return <TreeNode dataRef={item} key={item.key} title={title} selectable={false} />;
            // }
        });

    // 新增权限
    const permissionModal = useRef();
    const handleAddPermission = (tip, row) => {
        return () => {
            permissionModal.current.show(tip, row);
        }
    };

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 删除权限
    const deleteConfirm = (element) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除权限',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let form = new FormData();
                    form.append("id", element.id)
                    delte('/enterprise/permissions/delete', form).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                        }
                    })
                }
            });
        }
    }

    // table 数据相关
    const columns = [
        { title: '权限ID', dataIndex: 'id', width: 50, },
        { title: '权限名称', dataIndex: 'name', width: 150, },
        { title: '显示名称', dataIndex: 'displayName', width: 150, },
        { title: '权限描述', dataIndex: 'description', width: 150, },
        {
            title: '权限类型', dataIndex: 'type', width: 100,
            render: (operates) => (<span>{operates === 1 ? '菜单权限' : operates === 2 ? '功能权限' : '数据权限'}</span>)
        },
        { title: '所属业务模块', dataIndex: 'moduleName', width: 100, },
        { title: '所属权限分组', dataIndex: 'groupName', width: 150, },
        { title: '前端路由', dataIndex: 'route', width: 150, },
        { title: '接口路由', dataIndex: 'apiRoute', width: 150, },
        // { title: '接口请求方法', dataIndex: 'apiMethod', },
        // {
        //     title: '显示状态', dataIndex: 'hidden',
        //     render: (operates) => (<span className={operates === 0 ? "close-status" : "open-status"}>{operates === 0 ? '显示' : '隐藏'}</span>)
        // },
        {
            title: '操作', dataIndex: 'operate', fixed: 'right', width: 100,
            render: (operates, row) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="delete" onClick={deleteConfirm(row)}>删除权限</Menu.Item>
                    </Menu>
                )
                return (
                    <Dropdown.Button onClick={handleAddPermission('edit', row)} overlay={menu} size='small'>
                        编辑
                    </Dropdown.Button>
                )
            }
        },
    ];

    // tree选择
    const onSelect = (selectedKeys, info) => {
        let arr = treeData.map(item => {
            return item.moduleId;
        })
        if (arr.includes(selectedKeys[0])) {
            setGroupState({
                ...groupState,
                moduleCode: selectedKeys[0],
                groupId: undefined
            })
        } else {
            setGroupState({
                ...groupState,
                moduleCode: undefined,
                groupId: selectedKeys[0]
            })
        }
        let treeData1 = treeData && treeData.map(item => {
            return {
                ...item,
                children: item.children && item.children.map(elem => {
                    return {
                        ...elem,
                        moduleId: elem.groupId,
                        children: elem.children && elem.children.map(element => {
                            return {
                                ...element,
                                moduleId: element.groupId
                            }
                        })
                    }
                })
            }
        })
        let obj = getTreeObj(treeData1, selectedKeys)
        setTreeState({
            ...treeState,
            selectedKeys
        })
        if (obj) {
            setTagString(obj.title);
        } else {
            setTagString('');
        }
    };

    // 点击刷新
    const handleRefresh = () => {
        changeCurrPage(1);
    }

    // 关闭tag
    const handleTagClick = (e) => {
        setTagString('');
        setTreeState({
            ...treeState,
            selectedKeys: []
        })
        setGroupState({
            ...groupState,
            moduleCode: undefined,
            groupId: undefined
        })
    }

    const onTypeChange = (e) => {
        setGroupState({
            ...groupState,
            type: e.target.value
        })
    }

    return (
        <Fragment>
            <div className="page-title">权限管理</div>

            <Content className="main-content-box">

                <div className="main-content-body">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" span={4}>
                            <Space className="permission-left-top">
                                <Input placeholder="输入权限分组关键字" allowClear onChange={handleClickSearch} suffix={<SearchOutlined />} />
                                <PlusCircleOutlined className='permission-icon' onClick={handleAddPermissionGroup('add')} />
                            </Space>
                            {
                                treeData.length > 0 && <Tree
                                    blockNode
                                    onSelect={onSelect}
                                    onExpand={onExpand}
                                    selectedKeys={treeState.selectedKeys}
                                    expandedKeys={treeState.expandedKeys}
                                    autoExpandParent={treeState.autoExpandParent}
                                    treeData={loop(treeData)}
                                />
                            }

                        </Col>
                        <Col className="gutter-row" span={20}>
                            <div className="table-caption">
                                <div className="table-toolbar-role">
                                    <Button type="primary" onClick={handleAddPermission('add')}>新增权限</Button>
                                    <Button className="search-role-box" type="default" icon={<ReloadOutlined />} onClick={handleRefresh}>刷新</Button>
                                </div>
                            </div>

                            <div className="table-tag-filter">
                                {tagString && <Tag closable onClose={handleTagClick}>{tagString}</Tag>}
                                <Radio.Group onChange={onTypeChange} defaultValue={undefined} size='small'>
                                    <Radio.Button value={undefined}>全部类型</Radio.Button>
                                    <Radio.Button value={1}>菜单权限</Radio.Button>
                                    <Radio.Button value={2}>功能权限</Radio.Button>
                                    <Radio.Button value={3}>数据权限</Radio.Button>
                                </Radio.Group>
                            </div>

                            <Table
                                className="enterprise-table"
                                columns={columns}
                                dataSource={tableData.list}
                                pagination={paginationProps}
                                scroll={{
                                    x: 1500,
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                {/* 新增权限modal */}
                <PermissionAdd ref={permissionModal} getData={getData}></PermissionAdd>

                {/* 权限排序 */}
                <PermissionSort ref={permissionSortModal} getTreeData={getTreeData}></PermissionSort>

                {/* 新增权限分组 */}
                {
                    isModalVisible && <Modal title={tip === 'add' ? '新增权限分组' : '编辑权限分组'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Form form={form} {...formLayout}>

                            <Form.Item
                                name="moduleCode"
                                label="所属业务模块"
                                required={true}
                            >
                                <Select allowClear placeholder="选择所属业务模块" onChange={handleModuleChange}>
                                    {
                                        moduleState.moduleList && moduleState.moduleList.map(item => {
                                            return <Select.Option key={item.moduleId} value={item.moduleId}>{item.moduleName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="pid"
                                label="所属权限分组"
                            >
                                <Select allowClear placeholder="选择所属权限分组">
                                    {
                                        moduleState.groupList && moduleState.groupList.map(item => {
                                            return <Select.Option key={item.groupId} value={item.groupId}>{item.groupName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="name"
                                label="权限分组名称"
                                required={true}
                            >
                                <Input placeholder="输入权限分组名称" allowClear autoComplete='off' />
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </Content>

        </Fragment>
    )
}
