import { Link, useNavigate } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Layout,
    Table,
    Form,
    Dropdown,
    Row,
    Col,
    Input,
    Space,
    Button,
    Modal,
    message,
    Radio,
    Drawer,
    Typography, Divider, Tooltip, Tag, Select
} from 'antd';
import {
    getInvitationGroupListApi,
    deleteInvitationGroupApi,
    getConferencesApi,
    saveInvitationGroupApi,
    updateInvitationGroupApi,
    invitationGroupMarkAsTesting
} from '@services/Invitation/InvitationGroup';
import './InvitationGroupList.less';
import { filterFormItemLayout, filterFormGridLayout, drawerWidth } from '@utils/property';
import { invitationApplicationTypes } from '@utils/constants';
import { numberProject } from '@utils/utils';
import { invitationActions } from './store/invitationSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;

const { setGroupFilter: setFilter, setGroupCursor: setCursor } = invitationActions;

export default function InvitationGroupList() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.invitation.group.filter);
    const cursor = useSelector(state => state.invitation.group.cursor);
    // console.log(filter, cursor);

    const [form] = Form.useForm();

    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);

    const [conferencesPage, setConferencesPage] = useState(1);
    const [conferencesPageSize, setConferencesPageSize] = useState(5);
    const [conferencesTotal, setConferencesTotal] = useState(0);
    const [conferencesData, setConferencesData] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleTitle, setIsModalVisibleTitle] = useState('');
    const [invitationGroupName, setInvitationGroupName] = useState('');
    const [typeValue, setTypeValue] = useState(1);
    const [conferencesValue, setConferencesValue] = useState('');
    const [invitationGroupId, setInvitationGroupId] = useState(null);
    const [visibleViewUserDrawer, setVisibleViewUserDrawer] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('');
    const closeViewUserDrawer = () => {
        setVisibleViewUserDrawer(false);
        setInvitationGroupName(null)
        setTypeValue(1)
        setSelectedRowKeys([])
        setConferencesData([])
        setConferencesTotal(0)
        setInvitationGroupId(null)
    };

    // 分页
    const onChangePage = (page, pageSize) => {
        setData([]);
        dispatch(setFilter({
            ...filter,
            page,
            pageSize
        }));
    };
    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    // 搜索
    const onFormFinish = values => {
        dispatch(setFilter({
            ...filter,
            ...values,
            applicationType: 1,
            page: 1,
        }));
    };

    // 重置
    const onReset = () => {
        form.resetFields();

        dispatch(setFilter({
            ...filter,
            ...form.getFieldsValue(),
            page: 1,
        }));
    };

    // 新增
    const news = () => {
        setVisibleViewUserDrawer(true)
    }

    const onInvitationGroupName = (value) => {
        setInvitationGroupName(value.target.value)
    }
    // Radio
    const onTypeChange = (value) => {
        setTypeValue(value.target.value)
    }

    // 项目搜索
    const onConferencesSearch = (value) => {
        const params = {
            page: 1,
            pageSize: 5
        }
        setConferencesValue(value)
        params.name = value
        params.applicationType = typeValue
        getConferencesApi(params).then((res) => {
            if (res.status_code === 200) {
                setConferencesData(res.data.list)
                setConferencesTotal(res.data.totalCount)
            }
        })
    }
    const onChangeConferencesPage = (page, pageSize) => {
        console.log(page)
        setConferencesPage(page)
        setConferencesPageSize(pageSize)
        const params = {
            page: page,
            pageSize: pageSize
        }
        params.name = conferencesValue
        getConferencesApi(params).then((res) => {
            if (res.status_code === 200) {
                setConferencesData(res.data.list)
                setConferencesTotal(res.data.totalCount)
            }
        })
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys)
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 编辑邀请组确定
    const handleOk = () => {
        const params = {
            id: invitationGroupId,
            name: invitationGroupName
        };

        updateInvitationGroupApi(params)
            .then((res) => {
                if (res.status_code === 200) {
                    setInvitationGroupName(null);
                    setTypeValue(1);
                    setSelectedRowKeys([]);
                    setConferencesData([]);
                    setInvitationGroupId(null);
                    setUpdateStatus('update');
                    setIsModalVisible(false);
                    message.success(res.message);

                    getInvitationGroups();
                }
            });
    };

    const okVisibleViewUserDrawer = () => {
        if (!invitationGroupName) {
            message.warn("请输入邀请组名称！")
            return
        }

        if (selectedRowKeys[0] == null) {
            message.warn("请选择关联项目！")
            return
        }

        const params = {
            applicationItemId: selectedRowKeys[0],
            applicationType: typeValue,
            hidden: 0,
            name: invitationGroupName
        };
        saveInvitationGroupApi(params)
            .then((res) => {
            if (res.status_code === 200) {
                setInvitationGroupName(null);
                setTypeValue(1);
                setSelectedRowKeys([]);
                setConferencesData([]);
                setUpdateStatus("save");
                setConferencesTotal(0);
                setVisibleViewUserDrawer(false);
                message.success(res.message);

                getInvitationGroups();
            }
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setInvitationGroupName(null);
        setTypeValue(1);
        setSelectedRowKeys([]);
        setConferencesData([]);
        setInvitationGroupId(null);
    };

    // 新建取消
    const afterCloset = () => {
        setIsModalVisible(false);
        setInvitationGroupName(null);
        setTypeValue(1);
        setSelectedRowKeys([]);
        setConferencesData([]);
        setInvitationGroupId(null);
    };

    const closeVisibleViewUserDrawer = () => {
        setVisibleViewUserDrawer(false);
        setInvitationGroupName(null);
        setTypeValue(1);
        setSelectedRowKeys([]);
        setConferencesData([]);
        setConferencesTotal(0);
        setInvitationGroupId(null);
    };

    // 编辑邀请组
    const onUpdateInvitation = (item) => {
        dispatch(setCursor(item.id));
        setIsModalVisible(true);
        setIsModalVisibleTitle("编辑邀请组");
        setInvitationGroupName(item.name);
        setInvitationGroupId(item.id);
    };

    // 删除邀请函组
    const onDeleteInvitation = (id) => {
        Modal.confirm({
            title: '提示',
            content: '确定删除当前邀请组？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteInvitationGroupApi({ id }).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                    }
                    setUpdateStatus("del")

                    getInvitationGroups();
                });
            }
        });
    }

    const getInvitationGroups = async () => {
        try {
            const params = {
                ...filter,
                limit: filter.pageSize, // 该接口的分页 pageSize 为 limit
            };
            const res = await getInvitationGroupListApi(params);
            if (res.status_code === 200) {
                setData(res.data.list);
                setTotal(res.data.totalCount);
            }
        } catch (e) {
            //
        }
    };

    const markAsTesting = record => {
        Modal.confirm({
            title: '标记为测试邀请组',
            icon: <ExclamationCircleOutlined />,
            content: '医疗决策人数据统计等数据指标将排除标记为测试的邀请组，确定标记为测试邀请组？',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                invitationGroupMarkAsTesting({
                    id: record.id,
                    isTesting: !!record.isTesting ? 0 : 1,
                }).then(res => {
                    if (res.status_code === 200) {
                        const msg = !!record.isTesting ? '标记为正式邀请组成功' : '标记为测试邀请组成功';
                        message.success(msg);

                        getInvitationGroups();
                    }
                });
            }
        });

    };

    useEffect(() => {
        form.setFieldsValue(filter);

        getInvitationGroups();
    }, [filter]);

    const columns = [
        { title: '#', dataIndex: 'id', width: 60 },
        {
            title: '邀请组名称',
            dataIndex: 'name',
            width: 300,
            render: (_, record) => (<>
                {record.name}
                {!!record.isTesting && <Tag color="#f50" style={{marginLeft: 4, padding: '0 4px'}}>TEST</Tag>}
            </>)
        },
        {
            title: '关联项目',
            dataIndex: 'applicationItemName',
            render: (_, record) => (<>
                {record.applicationItemName}<br/><small style={{color: '#888'}}>{invitationApplicationTypes.find(t => t.type === record.applicationType).name}项目：{numberProject('YHY', record.applicationItemId)}</small>
            </>)
        },
        {
            title: '邀请函数量',
            dataIndex: 'count',
            width: 100,
            render: (_, record) => !!record.count
                ? (
                    <Tooltip title="点击进入邀请函管理" placement="left">
                        <Link to={`/invitation/g/${record.id}/invitations`} style={{marginRight: 8}}>{record.count}</Link>
                    </Tooltip>
                )
                : <Text type="secondary">暂无</Text>
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 240,
            render: (_, record) => {
                const items = [
                    {
                        label: !!record.isTesting ? '标记为正式邀请组' : '标记为测试邀请组',
                        key: "markAsTesting",
                        onClick: () => markAsTesting(record),
                    },

                    {
                        label: '删除邀请组',
                        key: 'delete',
                        onClick: () => onDeleteInvitation(record.id),
                        danger: true
                    },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            type="link"
                            size="small"
                            onClick={() => { dispatch(setCursor(record.id)); navigate(`/invitation/g/${record.id}/invitations`); }}>
                            管理邀请函
                        </Button>
                        <Divider type="vertical"/>
                        <Button type="link" size="small" onClick={() => onUpdateInvitation(record)}>编辑邀请组</Button>
                        <Divider type="vertical"/>
                        <Dropdown menu={{items}} placement="bottomRight">
                            <Button size="small" type="link" className="more-operation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                </svg>
                            </Button>
                        </Dropdown>
                    </span>
                );
            }
        }
    ];

    const conferencesColumns = [
        { title: '项目ID', dataIndex: 'id', width: 100 },
        { title: '项目名称', dataIndex: 'name' },
    ];

    return <Fragment>

        <div className="page-title">邀请组管理</div>

        <Content className="main-content-box">
            <div className="main-content-body">

                <div className="table-toolbar-role">
                    <Form
                        {...filterFormItemLayout}
                        form={form}
                        name="control-hooks"
                        onFinish={onFormFinish}
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item name="applicationItemName" label="医会议名称">
                                    <Input placeholder="请输入医会议名称"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item name="name" label="邀请组">
                                    <Input placeholder="请输入邀请组"/>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="正式/测试" name="isTesting">
                                    <Select placeholder="选择是否测试邀请组" allowClear>
                                        <Select.Option value={0}>正式邀请组</Select.Option>
                                        <Select.Option value={1}>测试邀请组</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="primary" htmlType="submit">检索</Button>
                                    <Button htmlType="button" onClick={onReset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                    <div className='new-survey'>
                        <Button type="primary" onClick={news}>新增邀请组</Button>
                    </div>
                </div>

                <Table
                    className="enterprise-table"
                    columns={columns}
                    dataSource={data}
                    rowKey={item => item.id}
                    rowClassName={row => row.id === cursor ? 'store-cursor' : ''}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                    size="middle"
                />
            </div>

            <Modal
                title={isModalVisibleTitle}
                open={isModalVisible}
                destroyOnClose={true}
                onOk={() => {
                    handleOk()
                }}
                onCancel={handleCancel}
                afterClose={afterCloset}
            >
                <div>
                    <span className="icon">邀请组：</span>
                    <Input style={{ width: 400 }} onChange={onInvitationGroupName} value={invitationGroupName}/>
                </div>
            </Modal>
        </Content>

        <Drawer
            title="新增邀请组"
            width={drawerWidth}
            open={visibleViewUserDrawer}
            onClose={closeViewUserDrawer}
            footer={
                <div
                    className="custom-drawer-footer"
                    style={{ textAlign: "right" }}
                >
                    <Space>
                        <Button onClick={closeVisibleViewUserDrawer}>取消</Button>
                        <Button type="primary" onClick={okVisibleViewUserDrawer}>
                            确定
                        </Button>
                    </Space>
                </div>
            }
        >
            <div>
                <span className="icon">邀请组：</span>
                <Input placeholder="输入邀请组名称" maxLength={100} showCount allowClear style={{ width: 520 }}
                       onChange={onInvitationGroupName} value={invitationGroupName}/>
            </div>
            <div className="postponeDiv">
                <span className="postpone icon">项目类型：</span>
                <Radio.Group onChange={onTypeChange} value={typeValue}>
                    <Radio value={1}>会议</Radio>
                    {/*<Radio value={2}>评选</Radio>*/}
                    {/*<Radio value={3}>活动</Radio>*/}
                </Radio.Group>
                <Search placeholder="关联项目" enterButton="搜索" onSearch={onConferencesSearch}/>
                <Table
                    className="enterprise-table"
                    columns={conferencesColumns}
                    dataSource={conferencesData}
                    rowKey={item => item.id}
                    rowSelection={rowSelection}
                    pagination={{
                        total: conferencesTotal,
                        showTotal: () => `共${conferencesTotal}条`,
                        current: conferencesPage,
                        pageSize: conferencesPageSize,
                        onChange: onChangeConferencesPage
                    }}
                />
            </div>
        </Drawer>
    </Fragment>
}
