import React, {Fragment, useState,useEffect} from "react";
import {Layout, message, DatePicker, Select, Radio,Space} from "antd";
import {get, del, post, put} from "@/core/request";
import {useLocation, useNavigate} from "react-router-dom";
import "./CheckOrgTrialSave.less"
import {getProductApi} from '@services/HealthClass';
export default function CheckOrgTrialSave() {

    const {Content} = Layout;
    const {RangePicker} = DatePicker;
    let navigate = useNavigate();
    const {state} = useLocation();
    const [sevenclassType, setSevenClassType] = useState(false)
    const [thirtyclassType, setThirtyClassType] = useState(false)
    const [ninetyclassType, setNinetyClassType] = useState(false)
    const [thisTime, setthisTime] = useState("")
    const [thisDay, setthisDay] = useState(null)

    const [productOpinion, setProductOpinion] = useState([]);
    const [product, setProduct] = useState(56);
    const [people, setPeople] = useState(undefined);

    const onThisDay = (type, day) => {
        if (type == 1) {
            setSevenClassType(true)
            setThirtyClassType(false)
            setNinetyClassType(false)
        } else if (type == 2) {
            setSevenClassType(false)
            setThirtyClassType(true)
            setNinetyClassType(false)
        } else {
            setSevenClassType(false)
            setThirtyClassType(false)
            setNinetyClassType(true)

        }
        var now = new Date()
        let todayStr = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
        let targetDay = new Date(now.getTime() + day * 24 * 3600 * 1000);
        let tarDayStr = targetDay.getFullYear() + "-" + (targetDay.getMonth() + 1) + "-" + targetDay.getDate();
        setthisTime(tarDayStr)
        setthisDay(day)
    }
    const submit = () => {
        if (thisDay==null){
            message.warning("请选择试用时间！")
            return
        }
        if(product!==56&&people==null){
            message.warning("请选择产品版本的成员人数")
            return
        }
        post("/healthclass/boss/organizations/update", {
            code: state.code,
            totalTrialDays: thisDay,
            versionId:product===56?56:people,
        }).then((res) => {
            if (res.status_code == 200) {
                message.success(res.message);
                navigate('/healthclass/CheckOrganizations');
            }
        })

    }
    const onCancel=()=>{
        navigate('/healthclass/CheckOrganizations');
    }

    const onProduct =(e) => {
        if(e.target.value === 56){
            setPeople(null);
        }
        setProduct(e.target.value);
    }

    useEffect(() => {
        getProductApi().then(res=>{
            if (res.status_code==200){
                setProductOpinion(res.data[1].childList);
            }
        })
    },[])
    return (
        <div>
            <Fragment>
                <div className="page-title">设置学苑信息</div>
                <Content className="main-content-box">
                    <div className="content">
                        <span className="icon">当前学苑</span>
                        <input className="checkInput" disabled="disabled" value={state.name || ""}/>
                    </div>
                    <div className="content">
                        <span className="icon">学苑类型</span>
                        <button className="buttonblue">试用</button>
                    </div>

                    <div className="content">
                        <span className="icon">产品版本</span>
                        <span className="radioClass">
                            <Radio.Group onChange={onProduct}  value={product} className="product">
                            <Space direction="vertical">
                            <Radio value={56}><span>基础版</span><span className="basic-people">{'<=30人'}</span></Radio>
                            <Radio value={0} className="product-choice"
                            ><div>专业版</div>

                            </Radio>
                            </Space>
                        </Radio.Group>
                        </span>
                        {product===0&&
                            <div className="people-choice">
                                <span>成员人数：</span>
                                <Select
                                    className="people-select"
                                    placeholder='请选择'
                                    style={{
                                        width: 120,
                                    }}
                                    allowClear
                                    onSelect={(value)=>{setPeople(value);}}
                                    fieldNames={{label:'name',value:'id'}}
                                    options={productOpinion}
                                />
                            </div>
                        }
                    </div>

                    <div className="content">
                        <span className="icon">试用时间</span>
                        <button className={sevenclassType ? "buttonblue" : "button"} onClick={() => onThisDay(1, 7)}>7天</button>
                        <button className={thirtyclassType ? "buttonblue" : "button"} onClick={() => onThisDay(2, 30)}>30天</button>
                        <button className={ninetyclassType ? "buttonblue" : "button"} onClick={() => onThisDay(3, 90)}>90天</button>
                    </div>
                    <div className="content">
                        <span className="icon">试用到期时间</span>
                        <input className="checkInput" disabled="disabled"  value={thisTime || ""}/>
                    </div>
                    <div className="content">
                        <button className="checkbutton" onClick={() => submit()}>提交</button>
                        <button className="checkbuttonb"onClick={()=>onCancel()}>取消</button>
                    </div>
                </Content>
            </Fragment>
        </div>

    )
}
