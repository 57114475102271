import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, message, Input } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import './index.less';
import config from '@config';
import shareHead from '@static/images/share/shareHead.png';
import weixin from '@static/images/share/weixinLogo.png';
import { querySettingApi, releaseSurveryApi, previewSurveryApi } from '@services/Wj/Question';
import Haibao from './components/Haibao';
import Weixin from './components/Weixin';

const ShareSurvey = () => {
    const [fileList, setFileList] = useState([])
    const [dire, setDire] = useState('希望大家能够踊跃参加')
    const [shareTitle, setShareTitle] = useState(undefined)
    const [haibaoOpen, setHaibaoOpen] = useState(undefined)
    const [weixinOpen, setWeixinOpen] = useState(undefined)
    const [title, setTitle] = useState(undefined)
    const navigate = useNavigate()
    const location = useLocation()
    console.log(location);
    const params = useParams()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const url = `${config.SURVEY_DOMAIN}/s/${params.id}`
    const previewUrl = `${config.SURVEY_DOMAIN}/s/${params.id}/answer?type=1 `


    const getFileUrl = (url) => {
        if (url && url.includes('static')) {
            return url;
        } else if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + `/${url}`;
        }
        return null;
    };

    const settingStatus = () => {
        querySettingApi({
            id: location.state.id,
            type: location.state.route === 'newCreate' ? 1 : 2
        }).then(
            (res) => {
                if (res.status_code === 200) {
                    setShareTitle(res.data.shareTitle);
                    setDire(res.data.shareDirections || '希望大家能够踊跃参加');
                    setFileList([{ url: res.data.shareLogoUrl || weixin }]);
                }
            });
    };


    useEffect(() => {
        settingStatus();
    }, [weixinOpen]);


    const goBack = () => {
        // window.history.back()
        navigate('/wj/surveys');
    };

    const onPreviewClick = () => {
        setIsModalOpen(true);
    };

    const onBtnClick = () => {
        Modal.confirm({
            title: '提示',
            content: '在您修改问卷时，问卷会暂停收集。修改完成后再次发布问卷才可继续收集',
            cancelText: '修改',
            okText: '取消',
            closable: true,
            onCancel: () => {
                releaseSurveryApi({ id: location.state.id, status: 2 }).then((res) => {
                    if (res.status_code === 200) {
                        navigate(`/wj/surveys/edit/${location.state?.id || ''}`, { state: location.state })
                    }
                })
                // navigate(`/wj/surveys/edit/${id}`,{state:{route:"newList",id:id,type:row.surveyTypeId,surveyStatus:row.status,action:'edit'}})

            }
        })
    }

    const onCopyClick = () => {
        copy(url);
        message.success('复制成功');
    };

    const onOpenclick = () => {
        window.open(url, '_blank');
    };

    const onErweimaClick = async () => {
        formHtmlToDownload('erweima_wrap', true);
    };

    const onHaibaoDownload = (id) => {
        formHtmlToDownload(id);
    };

    const formHtmlToDownload = async (domId, isErweima) => {
        let res = await drawCanvas(domId, isErweima)
        let linkA = document.createElement('a');       //创建a标签
        linkA.href = res;                // 将图片的src赋值给a节点的href
        linkA.download = location.state?.title || 'download';     // 设置a节点的download属性值(设置下载名称)
        let event = new MouseEvent('click');           // 模拟鼠标click点击事件
        linkA.dispatchEvent(event);
    };

    const DPR = () => {
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        } else {
            return 4;
        }
    };

    const parseValue = (value) => {
        return parseInt(value, 10);
    };

    const drawCanvas = async (domId, isErweima) => {
        // 获取想要转换的 DOM 节点
        const dom = document.getElementById(domId);
        // const dom = document.querySelector('printHtml');
        const box = window.getComputedStyle(dom);
        // DOM 节点计算后宽高
        const width = parseValue(box.width);
        const height = parseValue(box.height);
        // 获取像素比-防止模糊
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement('canvas');
        // 设置固定下载宽度
        canvas.width = 500;
        canvas.height = 500;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width / 2}px`;
        canvas.style.height = `${height / 2}px`;
        // 获取画笔
        const context = canvas.getContext('2d');

        // 将所有绘制内容放大像素比倍
        context.scale(1, 1);

        // 将自定义 canvas 作为配置项传入，开始绘制
        return await html2canvas(dom, {
            canvas,
            scale: 4
        }).then(canvas => {
            //document.querySelector("#canvasContainer").appendChild(canvas);
            //return canvas.toDataURL("image/png");
            return canvas.toDataURL("image/png");
        });
    };


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };


    const changeHaibao = () => {
        setHaibaoOpen(true);
    };

    const openWeixin = () => {
        setWeixinOpen(true);
    };

    const getTitle = () => {
        previewSurveryApi({ id: params.id }).then(res => {
            if (res.status_code === 200) {
                setTitle(res.data.name);
            }
        });

    };

    useEffect(() => {
        getTitle('init');
    }, []);

    return (

        <Fragment>
            <div className="page_title_wrap">
                <div className='page-left'>
                    <div className="back"><LeftOutlined onClick={goBack}/>
                    </div>
                    <span className='share-name'>{title}</span>
                </div>
                <div className='page-center'>
                    <span>编辑</span>
                    <ArrowRightOutlined className='process'/>
                    <span style={{ color: '#1890ff' }}>分享</span>
                </div>
                <div className='page-right'>
                    <Button type="primary" onClick={onBtnClick}>上一步</Button>
                    <Button type="primary" ghost onClick={onPreviewClick}>预览</Button>
                </div>
            </div>
            <div className='share_survey_wrap'>
                <div className='head'>
                    <h2>分享方式</h2>
                </div>

                <div className='row_item_wrap'>
                    <div className='col_item_wrap'>
                        <h3>链接分享</h3>
                        <Input className='url_area' value={url} disabled/>
                        <div className='url_button_area'>
                            <div className='url_button_left'>
                                <Button onClick={onOpenclick} type="primary" ghost>打开</Button>
                            </div>
                            <div className='url_button_right'>
                                <Button type='primary' onClick={onCopyClick}>复制</Button>
                            </div>
                        </div>
                    </div>
                    <div className='col_item_wrap'>
                        <h3>二维码分享</h3>
                        <div className='erweima_wrap' id='erweima_wrap'>
                            <QRCode
                                className='qrCode_wrap'
                                id="qrCode3"
                                value={url}
                                size={114}
                                fgColor="#000000"
                                style={{ margin: 'auto' }}
                                level="H"
                            />
                        </div>
                        <div className='qrcode_button_area'>
                            <Button onClick={onErweimaClick} type="primary" ghost>下载</Button>
                            <Button type='primary' onClick={changeHaibao}>生成海报</Button>
                        </div>
                    </div>

                    <div className='col_item_wrap'>
                        <h3>分享到微信</h3>
                        <div className='share_wexin'>
                            <img className='enjoy_avatar' src={shareHead}/>
                            <div className='wexin_message'>
                                <div className='wexin_message_left'>
                                    <p className='share_title'>{shareTitle}</p>
                                    <p className='share_dire'>{dire}</p>
                                </div>
                                <div className='wexin_message_right'>
                                    <img className='weixin_share' src={getFileUrl(fileList[0]?.url) || weixin}/>
                                </div>
                            </div>
                        </div>
                        <Button type='primary' onClick={openWeixin}>自定义内容</Button>
                    </div>
                </div>
            </div>

            <Modal
                open={isModalOpen}
                title={location.state?.title || ''}
                className='share_survey_modal_wrap'
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <iframe src={previewUrl} frameBorder="0" title='share'></iframe>
            </Modal>


            <Haibao haibaoOpen={haibaoOpen} setHaibaoOpen={setHaibaoOpen} url={url}/>
            <Weixin weixinOpen={weixinOpen} setWeixinOpen={setWeixinOpen} shareTitle={shareTitle}
                    setShareTitle={setShareTitle}/>
        </Fragment>

    );
}

export default ShareSurvey;
