import {useLocation, useNavigate} from "react-router-dom";
import React, {Fragment, useState, useEffect} from "react";
import {Image, Layout, Tabs, Tag, Timeline,Radio,Space,Select} from "antd";
import './TrialOrganizationsInfo.less'
import {get} from "@/core/request";
import {getProductApi} from '@services/HealthClass';

export default function TrialOrganizationsInfo() {

    const {Content} = Layout;
    let navigate = useNavigate();
    const {TabPane} = Tabs;
    const {state} = useLocation();
    const [infoData, setInfoData] = useState({});
    const [logsData, setlogsData] = useState([]);
    const [product, setProduct] = useState(undefined);
    const [people, setPeople] = useState(undefined);
    const [productOpinion, setProductOpinion] = useState([]);
    const onChange = (key) => {
        if (key == 1) {
            getData()
        } else if (key == 2) {
            getData()
        } else {
            getLogs()
        }
    };

    const getLogs = () => {
        get(`/healthclass/boss/orgLogs/queryByOrgCode`, {
            code: state.code
        }).then((res) => {
            if (res.status_code === 200) {
                setlogsData(res.data)
            }
        });
    }
    const getData = () => {
        get(`/healthclass/boss/organizations/queryById`, {
            id: state.id
        }).then((res) => {
            if (res.status_code === 200) {
                setInfoData(res.data)
                if(res.data.versionId===56){
                    setProduct(56)
                    setPeople(null)
                }else{
                    setProduct(0)
                    setPeople(res.data.versionId)
                }
            }
        });
    }
    useEffect(() => {
        getData()
    }, [state])

    const classNames = ['button'];
    const classTrial = ['button'];
    const limitClassk = ['button'];
    const limitClasss = ['button'];
    if (infoData.type == 1) {
        classNames.push('button')
        classTrial.push('buttonblue')
    } else {
        classNames.push('buttonblue')
        classTrial.push('button')
    }
    if (infoData.limitType == 1) {
        limitClassk.push('button')
        limitClasss.push('buttonblue')
    } else {
        limitClassk.push('buttonblue')
        limitClasss.push('button')
    }
    const updateInfoButton=()=>{
        return () => {
            navigate(`/healthclass/TrialOrganizationsInfoUpdate`, {state: infoData});
        }
    }
    useEffect(()=>{
        getProductApi().then(res=>{
            if (res.status_code==200){
                setProductOpinion(res.data[1].childList);
            }
        })
    },[])
    return (
        <div>
            <Fragment>
                <div className="page-title">管理</div>

                <Content className="main-content-box">
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                        {infoData &&
                        <TabPane tab="基本信息" key="1">
                            <div className="content">
                                <div className="content">
                                    <button className="updateButtonyulan" onClick={updateInfoButton()}>编辑信息</button>
                                </div>
                                <span className="icon">所属主体</span>
                                <input className="TrialInput" disabled="disabled" value={infoData.belongsUnitName || ""}/>
                            </div>
                            <div className="title">学苑类型</div>
                            <div className="content">
                                <span className="icon">学苑类型</span>
                                <button className={classNames.join(" ")}>正式</button>
                                <button className={classTrial.join(" ")}>试用</button>
                            </div>
                            <div className="title">学苑信息</div>
                            <div className="content">
                                <span className="icon">学苑名称</span>
                                <input className="TrialInput" disabled="disabled" value={infoData.name || ""}/>
                            </div>
                            <div className="content">
                                <span className="icon">开放类型</span>
                                <button className={limitClassk.join(" ")}>公开</button>
                                <button className={limitClasss.join(" ")}>私享</button>
                            </div>
                            <div className="content">
                                <span className="icon">学苑封面</span>
                                <div className="image"><Image width={300} height={200} src={infoData.coverUrl || ""}/>
                                </div>
                            </div>
                            <div className="content">
                                <span className="icon nameSpan">学苑介绍</span>
                                <textarea className="TrialInputcontent" disabled="disabled" value={infoData.introduce || ""}/>
                            </div>
                            <div className="contentTag">
                                <span className="icon">学苑标签</span>
                                <div className="divTags">{infoData.metaTags && infoData.metaTags.map((tagName) => (
                                    <Tag key={tagName.id}>{tagName.name}</Tag>))}</div>
                            </div>
                            <div className="content">
                                <span className="icon nameSpan">申请理由</span>
                                <textarea className="TrialInputcontent" disabled="disabled" value={infoData.applicationReason || ""}/>
                            </div>
                            <div className="title">学苑管理员信息</div>
                            <div className="content">
                                <span className="icon">联系人姓名</span>
                                <input className="TrialInputname" disabled="disabled" value={infoData.adminName || ""}/>
                            </div>
                            <div className="content">
                                <span className="icon">联系人手机</span>
                                <input className="TrialInputname" disabled="disabled" value={infoData.phone || ""}/>
                            </div>
                            {infoData.unitName != null &&
                            <div>
                                <div className="title">个人认证信息</div>
                                <div className="content">
                                    <span className="icon">单位名称</span>
                                    <input className="checkInputname" disabled="disabled" value={infoData.unitName || ""}/>
                                </div>
                                <div className="content">
                                    <span className="icon">证明材料</span>
                                    <div className="image"><Image width={300} height={200} src={infoData.certificate}/>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="classBottom"></div>
                        </TabPane>
                        }
                        {infoData &&
                        <TabPane tab="合同信息" key="2">
                            <div className="content">
                                <span className="icon">当前学苑</span>
                                <input className="TrialInput" disabled="disabled" value={state.name || ""} onChange={()=>{}}/>
                            </div>
                            <div className="content"><span className="icon">学苑类型</span>
                                <button className="buttonblue">试用</button>
                            </div>
                            <div className="content">
                        <span className="icon">产品版本</span>
                        <span className="radioClass">
                            <Radio.Group  value={product} className="product">
                            <Space direction="vertical">
                            <Radio value={56}><span>基础版</span><span className="basic-people">{'<=30人'}</span></Radio>
                            <Radio value={0} className="product-choice"
                            ><div>专业版</div>

                            </Radio>
                            </Space>
                        </Radio.Group>
                        </span>
                        {product===0&&
                            <div className="people-choice">
                                <span>成员人数：</span>
                                <Select
                                    className="people-select"
                                    placeholder='请选择'
                                    style={{
                                        width: 120,
                                    }}
                                    allowClear
                                    value={people}
                                    onSelect={(value)=>{setPeople(value);}}
                                    fieldNames={{label:'name',value:'id'}}
                                    options={productOpinion}
                                />
                            </div>
                        }
                    </div>
                            <div className="content"><span className="icon">累计天数</span>
                                <input className="TrialInput" disabled="disabled" value={infoData.totalTrialDays +' 天' || ""} onChange={()=>{}}/>
                            </div>
                            <div className="content">
                                <span className="icon">试用到期时间</span>
                                <input className="TrialInput" disabled="disabled" value={infoData.tryEndedAt || ""} onChange={()=>{}}/>
                            </div>
                            <div className="classBottom"></div>
                        </TabPane>
                        }
                        <TabPane tab="操作日志" key="3">
                            <div className="content">
                                <Timeline>
                                    {logsData && logsData.map((orgLogs) => (
                                            <Timeline.Item key={orgLogs.id}>
                                                <div>
                                                    <span className="logTimeSpan">{orgLogs.createdAt}</span>
                                                    <div className="divContent"><span>操作： {orgLogs.sign}</span></div>
                                                    <div className="divContent">{orgLogs.content &&
                                                    <span>操作内容：{orgLogs.content}</span>}</div>
                                                    <div className="createdBy"><span>操作人：{orgLogs.operatorName}</span><span
                                                        className="spanRight">手机号：{orgLogs.operatorPhone}</span></div>
                                                </div>
                                            </Timeline.Item>
                                        )
                                    )}
                                </Timeline>
                            </div>
                        </TabPane>
                    </Tabs>
                </Content>
            </Fragment>
        </div>
    )

}
