import React, { Fragment, useEffect, useMemo } from "react";
import { Table, Button, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col, Image } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { LeftOutlined, LineChartOutlined, ContainerOutlined } from "@ant-design/icons";
import "./index.less";
import config from "@config";
import { downLoadMultiFile } from '@/utils/utils';

const FillCom = ({ data, type }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const onCheck = (row) => {
        navigate(`/wj/surveys/${location.state.id}/check`, { state: { ...location.state, answerId: row.answersheetId } });
    };

    const getFileUrl = (url) => {
        if (url && url.includes("https")) {
            return url;
        }
        if (url && !url.includes("https")) {
            console.log(url);
            return config.OSS_PREFIX + `/${url}`;
        }
        return null;
    };

    const columns = useMemo(() => {
        if (type === 5) {
            let arr = [
                {
                    title: "序号",
                    dataIndex: "index",
                    key: "index",
                    width: 100,
                    render: (item, row, c) => {
                        return c + 1;
                    },
                },
            ];

            JSON.parse(data[0]?.answer || "[]").forEach((it, ind) => {
                arr.push({
                    title: `第 ${ind + 1} 个空回答`,
                    dataIndex: `answer${ind}`,
                    key: `answer${ind}`,
                });
            });

            arr.push({
                title: "操作",
                key: "action",
                render: (_, record) => (
                    <Button type="link" onClick={() => onCheck(record)}>
                        查看详情
                    </Button>
                ),
                width: 100,
            });
            return arr;
        } else
            return [
                {
                    title: "序号",
                    dataIndex: "index",
                    key: "index",
                    width: 100,
                    render: (item, row, c) => {
                        return c + 1;
                    },
                },
                {
                    title: "回答详情",
                    dataIndex: "answer",
                    key: "answer",
                    render: (_, record) => {
                        return (
                            <>
                                {type === 11 ? (
                                    <div className="images-topic">
                                        {record.answer && JSON.parse(record.answer || '[]').length && Array.isArray(JSON.parse(record.answer)) &&
                                            JSON.parse(record.answer || '[]').length <= 1 ? JSON.parse(record.answer || '[]').map((file, index) => (
                                                <div className="file-item" key={index}>
                                                    {/* <Image src={getFileUrl(url)} /> */}
                                                    <span>{file.name}</span>
                                                </div>
                                            ))
                                            : <div className="file-item">
                                                <span>{getZipName(JSON.parse(record.answer || '[]'))}</span>
                                            </div>
                                        }
                                    </div>
                                ) : type === 12 ? (
                                    <div className="images-topic">
                                        {record.answer && JSON.parse(record.answer || '[]').length && Array.isArray(JSON.parse(record.answer))
                                            ? JSON.parse(record.answer || '[]').map((url, index) => (
                                                <div className="images-item" key={index}>
                                                    <Image src={getFileUrl(url)} />
                                                </div>
                                            ))
                                            : null}
                                    </div>
                                ) : (
                                            <span>{record.answer}</span>
                                        )}
                            </>
                        );
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    render: (_, record) => (
                        <>
                            {type === 11 && <Button type="link" onClick={() => onDownFile(record)}>下载</Button>}
                            <Button type="link" onClick={() => onCheck(record)}>
                                查看详情
                            </Button>
                        </>
                    ),
                    width: 200,
                },
            ];
    }, [type, data]);

    // 下载文件（文件收集题型）
    const onDownFile = (record) => {
        if (record?.answer) {
            let fileList = JSON.parse(record?.answer);
            if (fileList && fileList.length === 1 && fileList[0].name) {
                let filesName = fileList[0].name.slice(0, fileList[0].name.lastIndexOf('.'));
                downLoadMultiFile(fileList, `${filesName}.zip`);
            }
            if (fileList && fileList.length > 1 && fileList[0].name) {
                let filesName = fileList[0].name.slice(0, fileList[0].name.lastIndexOf('.'));
                downLoadMultiFile(fileList, `${filesName}.zip`);
            }
        }
    };

    // 多个文件展示一个文件夹
    const getZipName = (fileList) => {
        let filesName = '';
        if (fileList && fileList.length && fileList[0].name) {
            filesName = fileList[0].name.slice(0, fileList[0].name.lastIndexOf('.'));
            return `${filesName}.zip`;
        }
        return null;
    };

    const dataSource = useMemo(() => {
        console.log(data, type, "data, type");
        if (type === 4) {
            return data.map((item) => ({
                ...item,
                answer: JSON.parse(item.answer)[0] || "",
            }));
        } else if (type === 5) {
            return data.map((item) => {
                let obj = item;
                JSON.parse(item?.answer || "[]").forEach((it, ind) => (obj[`answer${ind}`] = it));
                return obj;
            });
        } else return data;
    }, [data, type]);

    useEffect(() => { }, []);

    return (
        <div className="answer_fill_wrap">
            <Table columns={columns} dataSource={dataSource} size="small" />
        </div>
    );
};

export default FillCom;
