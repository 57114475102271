import React, { Fragment, useState, useEffect,useRef,useMemo } from "react";
import { Input,message } from "antd";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import "./index.less";
import { timesaveTemplateQuestionApi } from "@services/Wj/Template";
import { timesaveSurveryQuestionApi } from "@services/Wj/Question";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Fill = ({ questionId, item, setAnserArea, anserArea }) => {
    const [questionIndex, setQuestionIndex] = useState();
    const [titleBorder, setTitleBorder] = useState(false);
    const [titleIn,setTitleIn]= useState(false)
    const { TextArea } = Input;
    const location = useLocation();
    const quillRef=useRef(null)
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
        let c=document.querySelectorAll('.ql-color')
        ;
        setTimeout(() => {
            c.forEach(item=>{
                item.onmousedown=(e)=>{
                    e.preventDefault();
                    //console.log(1);
            }
            })
        }, 1000);


    }, []);

    const changeTitle = (e,i) => {
        let a=document.querySelectorAll('.ql-container')
       a[questionIndex].removeAttribute('style')
        let b=document.querySelectorAll('.ql-toolbar')
        b[questionIndex].removeAttribute('style')
        item.body = quillRef.current.value;
        setTitleBorder(false);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: quillRef.current.value,
        };
        //console.log(quillRef.current.value);
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){
                message.success('保存成功')
            }}))
        }
        // setAnserArea(resArr);
    };
    // const
    // const colorHander=()=>{
    //     //console.log(1);
    //     let quill = quillRef?.current?.getEditor(); //获取到编辑器本身
    //     quillRef.getEditor().focus()
    //     const cursorPosition = quill.getSelection().index; //获取当前光标位置
    //     // quill.insertEmbed(cursorPosition, "image", url); //插入图片
    //     quill.setSelection(cursorPosition); //光标位置加1
    // }
    const modules = useMemo(() => {
        return {
          toolbar: {
            container: [
              ["bold",  "underline", {
                color: [],
              },],
            ],

          },
        };
      }, []);
    const getBorder = (index) => {
        let a=document.querySelectorAll('.ql-container')
        a[index].style.border='1px solid #1a73e8'
        let b=document.querySelectorAll('.ql-toolbar')
        b[index].style.display='block'
        setTitleBorder(true);
        setTitleIn(true)
    };


    return (
        <Fragment>
            <div name={questionId} className="fill-area">
                <div className="question-title">
                    <div className="question-id">
                        <span
                            className="required"
                            style={{
                                display: item.required === 1 ? "" : "none",
                            }}
                        >
                            *
                        </span>
                        <b className="question-index">{questionIndex}.</b>
                    </div>
                    {/* <TextArea
                        className="question-title-area"
                        placeholder="请输入问题"
                        defaultValue={item.body}
                        onFocus={getBorder}
                        onBlur={(e) => changeTitle(e)}
                        bordered={titleBorder}
                        maxLength={200}
                        style={{border:titleIn ? '1px solid #1a73e8':''}}
                        autoSize={{
                            minRows: 1,
                        }}
                        // readOnly = {isEdit == true ? '':'none'}
                    // ></TextArea>*/}
                    <ReactQuill theme="snow" ref={quillRef}
                    modules={modules}
                   placeholder="请输入问题"
                   className="question-title-area"
                    onFocus={()=>getBorder(questionIndex)}
                    defaultValue={item.body}
                        onBlur={()=>changeTitle(questionIndex)}
                        />


                </div>
                <div className="fill-answer">
                    <Input disabled />
                </div>
            </div>
        </Fragment>
    );
};

export default Fill;
