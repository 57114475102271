import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { Form, Row, Col, DatePicker, Layout, Tooltip, Radio } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import BrowserChart from './BrowserChart';
import DeviceChart from './DeviceChart';
import StatisticsChart from './StatisticsChart';
import ViewArea from './ViewArea';
import './OverviewData.less';
import { get } from '@/core/request';

const { RangePicker } = DatePicker;
const { Content } = Layout;

export default function OverviewData() {

    // 搜索的自定义时间 控制为一个月
    const [dates, setDates] = useState([]);

    const [hackValue, setHackValue] = useState();

    const [value, setValue] = useState();

    const disabledDate = useCallback((current) => {
        if (!dates || dates.length === 0) {
            return false;
        };
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
        return tooEarly || tooLate;
    }, [dates]);

    const onOpenChange = useCallback((open) => {
        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        };
    }, []);

    const handleCalendarChange = (val) => {
        setDates(val)
    }

    const [timePicker, setTimePicker] = useState([])
    const handleChangeTime = (val, dataString) => {
        setValue(val);
        setTimePicker(dataString);
    };

    const [allData, setAllData] = useState({
        keyData: null, statisticChartData: null, areaData: null, deviceData: null, browserData: null
    });

    const { keyData, statisticChartData, areaData, deviceData, browserData } = allData;

    // 渲染table数据
    useEffect(() => {
        get(`/dms/production-lines/data/usage`, {
            start_time: timePicker[1],
            end_time: timePicker[0]
        }).then((res) => {
            if (res.status_code === 200) {
                const { key_date, statistical_chart_date, area_date: { area_date }, device_date: { device_date }, browser_date: { browser_date } } = res.data;
                setAllData({
                    keyData: key_date, statisticChartData: statistical_chart_date, areaData: area_date, deviceData: device_date, browserData: browser_date
                })
            }
        });
    }, [timePicker]);

    const options = [
        { label: '直播', value: 'live' },
        { label: '回放', value: 'replay' }
    ];

    const [redioState, setRadioState] = useState('live');
    const onRadioChange = e => {
        setRadioState(e.target.value)
    };

    return (
        <Fragment>
            <div className="page-title">数据总览</div>

            <Content className="main-content-box">

                <div className="filter-form-box">
                    <Form {...filterFormItemLayout}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="自定义时间" name="timePicker">
                                    <RangePicker
                                        value={hackValue || value}
                                        disabledDate={disabledDate}
                                        onCalendarChange={handleCalendarChange}
                                        onChange={handleChangeTime}
                                        onOpenChange={onOpenChange}
                                        allowClear
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">

                    {
                        keyData && <div className="core-data">
                            <h2>关键数据</h2>
                            <Row gutter={24} className="core-data-all">
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">直播总数
                                        <Tooltip placement="right" title="筛选条件内创建的活动总数，删除活动不影响已统计的历史数据">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.webinarCount}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">最高并发（方）
                                        <Tooltip placement="right" title="观看直播的连接数，即1个人同时打开3个观看页计作3个并发，筛选条件内显示当前活动的最高并发">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.maxOnlines}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">观看人数
                                        <Tooltip placement="right" title="统计进入观看页面的观看人数，用户真实观看了视频，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchNumber}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">观看次数
                                        <Tooltip placement="right" title="统计进入观看页面的观看次数，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchTimes}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">人均观看次数
                                        <Tooltip placement="right" title="人均观看次数 = 观看次数 / 观看人数，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchTimesAvg}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">观看时长（分）
                                        <Tooltip placement="right" title="统计进入观看页面的观看次数，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchDuration}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">人均 观看时长（分）
                                        <Tooltip placement="right" title="筛选条件内的观看平均时长，将活动数据进行相加，数据不去重。人均观看时长 = 观看时长 / 观看人数">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchDurationAvg}</p>
                                </Col>
                            </Row>
                        </div>
                    }

                    {
                        statisticChartData && statisticChartData.live_broadcast.length ? <div className="statistics-chart">
                            <h2>统计图表</h2>
                            <div className="core-data-title echarts-p">观看人数趋势
                                <Tooltip placement="right" title="筛选条件内，观看人数随时间变化的趋势图">
                                    <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                </Tooltip>
                                <Radio.Group
                                    options={options}
                                    onChange={onRadioChange}
                                    value={redioState}
                                    optionType="button"
                                    buttonStyle="solid"
                                    className="change-radio"
                                />
                            </div>
                            <StatisticsChart statisticChartData={statisticChartData} redioState={redioState}></StatisticsChart>
                        </div> : <Fragment></Fragment>
                    }

                    {
                        areaData && areaData.list.length ? <div className="view-area">
                            <h2>观看区域TOP10占比<Tooltip placement="right" title="统计观看地域TOP10占比情况">
                                <span className="data-hover-icon"><QuestionCircleFilled /></span>
                            </Tooltip></h2>
                            <ViewArea areaData={areaData}></ViewArea>
                        </div> : <Fragment></Fragment>
                    }

                    <div className="view-area">
                        <Row>
                            {
                                deviceData && deviceData.list.length ? <Col span={10}>
                                    <h2>终端设备占比<Tooltip placement="right" title="统计观看终端占比情况">
                                        <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                    </Tooltip></h2>
                                    <DeviceChart deviceData={deviceData}></DeviceChart>
                                </Col> : <Fragment></Fragment>
                            }

                            {
                                browserData && browserData.list.length ? <Col span={14}><h2>浏览器占比<Tooltip placement="right" title="统计观看浏览器占比情况">
                                    <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                </Tooltip></h2>
                                    <BrowserChart browserData={browserData}></BrowserChart>
                                </Col> : <Fragment></Fragment>
                            }

                        </Row>

                    </div>

                </div>

            </Content>

        </Fragment>
    )
}
