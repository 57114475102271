// 医项目状态：
// 0未发布
// 1已发布（3进行中，4已结束，5未开始）
// 2已下架
export const projectStatusList = [
    { status: 0, label: '待发布' },
    { status: 1, label: '已发布' },
    { status: 2, label: '已下架', type: 'warning' },
    { status: 3, label: '进行中' },
    { status: 4, label: '已结束', type: 'secondary' },
    { status: 5, label: '未开始' },
];


export const stageList = [
    {label: '测试项目', value: 1},
    {label: '正式项目', value: 0},
];

export const questionSourceList = [
    {label: '平台创建', value: 1},
    {label: '用户创建', value: 2},
];

export const approveStateList = [
    {label: '待分配', value: 1},
    {label: '待审核', value: 2},
    {label: '审核通过', value: 3},
    {label: '已驳回', value: 4},
    {label: '付费中', value: 5},
    {label: '已核销', value: 6},
];

// 人员类型
export const userTypeList = [
    {label: '医生', value: 1},
    {label: '专家', value: 2},
    {label: '运营', value: 3},
];

// 劳务状态
export const feeBatchStatusList = [
    {label: '待付款', value: 1},
    {label: '已付款', value: 2},
    {label: '已失效', value: 3},
]
