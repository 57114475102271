import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Layout,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
    Steps,
    Popover
} from 'antd';
import { SearchOutlined, CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { laborProgressApi } from '@services/Conference';
import config from '@config';
import { numberProject } from '@utils/utils';

const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function ConferenceUsage() {
    const [form] = Form.useForm();

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 10,
        isTesting: 0,
    });

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        console.log(values);
        let { dates } = values;

        let newFilter = { ...filter, ...values };

        if (dates && dates.length) {
            newFilter['startTime'] = dates[0].format('YYYY-MM-DD');
            newFilter['endTime'] = dates[1].format('YYYY-MM-DD');
        } else {
            newFilter['startTime'] = undefined;
            newFilter['endTime'] = undefined;
        }
        delete newFilter.dates;

        setFilter(newFilter);
    };

    const onReset = () => {
        form.resetFields();
        setFilter({ page: 1, pageSize: 10, isTesting: 0 });
    };

    const onChangePage = (page, pageSize) => {
        setFilter({
            ...filter,
            page,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 300,
            render: (_, record) => {
                return <>
                    <a target='_blank' onClick={() => window.location.href = `/conf/conferences/${record.conferenceId}/laborfee`}>{record.name}</a>
                    {!!record.isTesting && <Tag color="#f50" style={{marginLeft: 4, padding: '0 4px'}}>TEST</Tag>}
                    <div>氚云项目编号：<Text type="secondary">{record.h3yunNo || <>&ndash;</>}</Text></div>
                    <div>医会议ID：<Text type="secondary">{numberProject('YHY', record.conferenceId)}</Text></div>
                </>
            }
        },
        {
            title: '执行日期',
            dataIndex: 'dates',
            width: 200,
            render: (_, record) => record.dates.split(/,/).map((d, i) => <Tag key={i}>{d}</Tag>),
        },
        {
            title: '项目执行进度',
            dataIndex: 'dates',
            width: 100,
            render: (_, record) => {
                return <>
                    {record.conferenceStatus === 0 && <Text type="secondary">未开始</Text>}
                    {record.conferenceStatus === 1 && <Text type="warning">进行中</Text>}
                    {record.conferenceStatus === 2 && <Text type="success">已完成</Text>}
                </>
            },
        },
        {
            title: '项目劳务进度',
            dataIndex: 'dates',
            width: 700,
            render: (_, record) => {
                let newData = [];
                if (record.flows && !!record.flows.length) {
                    newData = record.flows.map(item => {
                        let newItem = {};
                        newItem.title = getCode(item);
                        newItem.description = getStatus(item);
                        if (item.status === 1) {
                            newItem.icon = <></>;
                        }
                        if (item.status === 2) {
                            newItem.icon = <><ClockCircleFilled style={{ color: '#faad14' }} /></>;
                        }
                        if (item.status === 3) {
                            newItem.icon = <><CheckCircleFilled style={{ color: '#52c41a' }} /></>;
                        }
                        if (item.code === '财务审批' && item.status === 4) {
                            newItem.icon = <><CloseCircleFilled style={{ color: '#ff4d4f' }} /></>;
                        }
                        if (item.code === '付款申请' && item.status === 4) {
                            newItem.icon = <><ClockCircleFilled style={{ color: '#faad14' }} /></>;
                        }
                        return newItem;
                    });
                }
                return <>
                    {
                        newData && !!newData.length ? <Steps
                            current={5}
                            items={newData}
                        /> : <Text type="secondary">&ndash;</Text>
                    }
                </>
            },
        },
        {
            title: '创建人',
            dataIndex: 'createdBy',
            width: 100,
            render: (data) => {
                return !!data
                    ? <span>
                        {data}
                    </span>
                    : <Text type="secondary">&ndash;</Text>
            }
        },
    ];

    // 审批节点 - 名称样式
    const getCode = (item) => {
        if (item.code === '部门审批') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{ color: '#faad14' }}>{item.code}</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>{item.code}</span>;
            }
        }
        if (item.code === '财务审批') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{ color: '#faad14' }}>{item.code}</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>{item.code}</span>;
                case 4:
                    return <span style={{ color: '#ff4d4f' }}>{item.code}</span>;
            }
        }
        if (item.code === '付款申请') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{ color: '#faad14' }}>{item.code}</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>{item.code}</span>;
                case 4:
                    return <span style={{ color: '#faad14' }}>{item.code}</span>;
            }
        }
        if (item.code === '付款申请审批') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{ color: '#faad14' }}>{item.code}</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>{item.code}</span>;
            }
        }
        if (item.code === '出纳付款') {
            switch (item.status) {
                case 1:
                    return <>{item.code}</>;
                case 2:
                    return <span style={{ color: '#faad14' }}>{item.code}</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>{item.code}</span>;
            }
        }
    }

    // 审批节点状态
    const getStatus = (item) => {
        if (item.code === '部门审批') {
            switch (item.status) {
                case 2:
                    return <span style={{ color: '#faad14' }}>待审批
                        <Popover placement="topRight" content="请确认专家劳务信息填写无误，再通知部门负责人审批">
                            <QuestionCircleFilled style={{ color: '#1890ff', marginLeft: '3px' }} />
                        </Popover>
                    </span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>同意({item.approveBy})</span>;
            }
        }
        if (item.code === '财务审批') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{ color: '#faad14' }}>待审批({item.approveBy})</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>同意({item.approveBy})</span>;
                case 4:
                    return <span style={{ color: '#ff4d4f' }}>不同意({item.approveBy})</span>;
            }
        }
        if (item.code === '付款申请') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{ color: '#faad14' }}>未申请
                        <Popover placement="topRight" content="请选择付款专家">
                            <QuestionCircleFilled style={{ color: '#1890ff', marginLeft: '3px' }} />
                        </Popover>
                    </span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>全部申请</span>;
                case 4:
                    return <span style={{ color: '#faad14' }}>部分申请
                        <Popover placement="topRight" content="有未提交付款申请专家">
                            <QuestionCircleFilled style={{ color: '#1890ff', marginLeft: '3px' }} />
                        </Popover>
                    </span>;
            }
        }
        if (item.code === '付款申请审批') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{ color: '#faad14' }}>待审批({item.approveBy})</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>同意({item.approveBy})</span>;
            }
        }
        if (item.code === '出纳付款') {
            switch (item.status) {
                case 1:
                    return '未开始';
                case 2:
                    return <span style={{ color: '#faad14' }}>待付款({item.approveBy})</span>;
                case 3:
                    return <span style={{ color: '#52c41a' }}>全部付款({item.approveBy})</span>;
            }
        }
    }

    const getLaborProgress = async () => {
        try {
            setLoading(true);

            const res = await laborProgressApi(filter);
            if (res.status_code === 200) {
                setTableData(res.data.list);
                setTotal(res.data.totalCount);
                // console.log(res.data);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLaborProgress(filter);
    }, [filter]);

    return (<>
        <div className="page-title">
            <span className="current-title">项目劳务进度</span>
        </div>

        <Content className="main-content-box">

            <div className="filter-form-box">
                <Form
                    {...filterFormItemLayout}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="项目名称" name="name">
                                <Input allowClear placeholder="会议项目名称关键字" />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="医会议ID" name="conferenceId">
                                <Input allowClear placeholder="会议项目编号或ID" />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="执行日期" name="dates" className="conferencelist_date">
                                <RangePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="氚云编号" name="h3yunNo">
                                <Input allowClear placeholder="氚云项目编号" />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="创建人" name="createdBy">
                                <Input allowClear placeholder="项目创建人" />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="conferenceStatus" label="项目执行进度">
                                <Select placeholder="请选择" allowClear>
                                    <Select.Option value={0}>未开始</Select.Option>
                                    <Select.Option value={1}>进行中</Select.Option>
                                    <Select.Option value={2}>已完成</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="deptStatus" label="部门审批">
                                <Select placeholder="请选择" allowClear>
                                    <Select.Option value={2}>待审批</Select.Option>
                                    <Select.Option value={3}>同意</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="financeStatus" label="财务审批">
                                <Select placeholder="请选择" allowClear>
                                    <Select.Option value={1}>未开始</Select.Option>
                                    <Select.Option value={2}>待审批</Select.Option>
                                    <Select.Option value={3}>同意</Select.Option>
                                    <Select.Option value={4}>不同意</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="paymentRequestStatus" label="付款申请">
                                <Select placeholder="请选择" allowClear>
                                    <Select.Option value={1}>未开始</Select.Option>
                                    <Select.Option value={2}>未申请</Select.Option>
                                    <Select.Option value={4}>部分申请</Select.Option>
                                    <Select.Option value={3}>全部申请</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="paymentApprovalStatus" label="付款申请审批">
                                <Select placeholder="请选择" allowClear>
                                    <Select.Option value={1}>未开始</Select.Option>
                                    <Select.Option value={2}>待审批</Select.Option>
                                    <Select.Option value={3}>同意</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="paymentStatus" label="出纳付款">
                                <Select placeholder="请选择" allowClear>
                                    <Select.Option value={1}>未开始</Select.Option>
                                    <Select.Option value={2}>待付款</Select.Option>
                                    <Select.Option value={3}>全部付款</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="正式/测试" name="isTesting" initialValue={0}>
                                <Select placeholder="是否测试项目" allowClear>
                                    <Select.Option value={0}>正式项目</Select.Option>
                                    <Select.Option value={1}>测试项目</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="main-content-body">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: filter.page,
                        pageSize: filter.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                    scroll={{ x: 1500 }}
                />
            </div>
        </Content>
    </>);
}
