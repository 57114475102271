import React, { Fragment, useState } from 'react';
import { Form, Input, Button, Layout, message } from 'antd';
import { put } from '@/core/request';
const { Content } = Layout;

export default function MyChangePassword() {

    const [form] = Form.useForm();

    const pageFormItemLayout = {
        labelCol: {
            span: 7
        },
        wrapperCol: {
            span: 12
        }
    }

    const pageFormItemWithoutLabelGridLayout = {
        wrapperCol: {
            offset: 7, span: 22
        }
    };

    const onFinish = (value) => {
        if (value.newPwd !== value.confirmPassword) {
            message.error('确认密码与新密码不一致')
            return;
        }
        put(`/enterprise/staffs/reset-pwd`, {
            newPwd: value.newPwd,
            oldPwd: value.oldPwd
        }).then(res => {
            if(res.status_code === 200){
                form.resetFields();
                message.success('修改成功')
            }
        })
    }

    return (
        <Fragment>
            <div className="page-title">个人信息</div>

            <Content className="main-content-box">
                <Form {...pageFormItemLayout} form={form} onFinish={onFinish} className="my-message-form">

                    <Form.Item
                        label="原密码"
                        name="oldPwd"
                        rules={[{ required: true, message: '请输入原密码' }]}
                    >
                        <Input.Password placeholder="输入原密码" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="newPwd"
                        help="密码不得少于6位，至少包含数字和字母（大写或小写）"
                        required
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/.test(value)) {
                                        return Promise.reject('密码不得少于6位，至少包含数字和字母（大写或小写）');
                                    } else {
                                        return Promise.resolve()
                                    }
                                },
                            }
                        ]}
                    >
                        <Input.Password placeholder="输入新密码" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        label="确认密码"
                        name="confirmPassword"
                        help="确认密码需与新密码保持一致"
                        rules={[{ required: true, message: '请输入确认密码' }]}
                    >
                        <Input.Password placeholder="输入确认密码" autoComplete="off" />
                    </Form.Item>


                    <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                        <Button className="password-button" type="primary" htmlType="submit">提交</Button>
                    </Form.Item>

                </Form>
            </Content>

        </Fragment >
    );
}


