import { Link, useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined, LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
    Layout,
    Space,
    Button,
    message,
    Input,
    Typography,
    Tooltip,
    Modal,
    Form,
    Select,
    DatePicker,
    Row,
    Col,
} from 'antd';
import './index.less';
import TinymceEditor from '../TinymceEditor';
import { moduleUpdateApi, moduleGetByIdApi, putScheduleData } from '@services/MedProject';
import config from '@config';
import { DragOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { isEmpty } from 'lodash';
import moment from 'moment';

const { Content } = Layout;
const { Text } = Typography;

export default function ModulesFrees() {
    const params = useParams();

    const id = params?.id || 0;
    const projectId = params?.projectId || 0;

    const [details, setDetails] = useState(null);
    const [path, setPath] = useState('');
    const [editorContents, setEditorContents] = useState('');
    const [initEditorValue, setInitEditorValue] = useState(null);
    const [moduleType, setModuleType] = useState('link');  // link-链接，rich_text-图文，time_line-时间轴

    // 时间轴数据
    const [scheduleData, setScheduleData] = useState({ title: undefined, nodes: [] });
    const [currentTimelineNode, setCurrentTimelineNode] = useState(null); // 时间轴-当前节点
    const [currentTimelineNodeIndex, setCurrentTimelineNodeIndex] = useState(undefined); // 时间轴-当前节点索引
    const [visibleTimelineModal, setVisibleTimelineModal] = useState(false); // 是否显示新增/编辑时间轴对话框

    const datePickerTypes = [
        {label: '按日期选择', value: 'date'},
        {label: '按月份选择', value: 'month'},
        {label: '按年份选择', value: 'year'},
    ];

    const [form] = Form.useForm();

    const formatDateByPickerTypeForDisplay = (date, pickerType) => {
        let result = '';

        if (date && moment(date).isValid()) {
            switch (pickerType) {
                case 'date':
                    result = moment(date).format('YYYY年M月D日');
                    break;
                case 'month':
                    result = moment(date).format('YYYY年M月');
                    break;
                case 'year':
                    result = moment(date).format('YYYY年');
                    break;
                default:
                    result = moment(date).format('YYYY年M月D日');
                    break;
            }
        }

        return result;
    };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return (<>
            <Tooltip title="时间点排序">
                <Button type="text" size="small"><DragOutlined/></Button>
            </Tooltip>
        </>);
    });

    const SortableMainElement = SortableElement(({ node, sort }) => {
        return <>
            <div className="schedule-table-tr" key={sort}>
                <div className="schedule-table-start">
                    {formatDateByPickerTypeForDisplay(node.start_time, node.start_time_type)}
                </div>
                <div className="schedule-table-end">
                    {!!node.end_time
                        ? formatDateByPickerTypeForDisplay(node.end_time, node.end_time_type)
                        : <Text type="secondary">&ndash;</Text>
                    }
                </div>
                <div className="schedule-table-name">
                    {!!node.url
                        ? <a target="_blank" href={node.url}>{node.title}</a>
                        : node.title
                    }
                </div>
                <div className="schedule-table-text">
                    {node.desc || <Text type="secondary">&ndash;</Text>}
                </div>
                <div className="schedule-table-settings">
                    <span className="operation-btns">
                        <Button size="small" type="link" onClick={() => showTimelineModal(node, sort)}>编辑</Button>
                        <Button size="small" type="link" onClick={() => deleteTimelineNode(sort)}>删除</Button>
                    </span>
                    <SortableMainHandle/>
                </div>

            </div>
        </>
    });

    const SortableMainContainer = SortableContainer(({ items }) => {
        return <div className="schedule-table-body">
            {items && items.map((node, idx) => {
                return (
                    <SortableMainElement
                        node={node}
                        index={idx}
                        key={idx}
                        sort={idx}
                    />
                );
            })}
        </div>;
    });

    // 时间点排序
    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        let newData = arrayMoveImmutable(scheduleData.nodes, oldIndex, newIndex);
        const newScheduleData = {...scheduleData};
        newScheduleData.nodes = newData;
        setScheduleData(newScheduleData);
    };

    const preview = () => {
        if (moduleType === 'link') {
            return window.open(path);
        } else {
            return window.open(`${config.PROJECT_DOMAIN}/p/${projectId}/modules/${id}`);
        }
    };

    const pathChange = (e) => {
        console.log(e);
        setPath(e.target.value);
    };

    const onUpdateModule = async () => {
        if (moduleType === 'time_line') {
            if (!(scheduleData && scheduleData.nodes && scheduleData.nodes.length)) {
                message.error('时间轴列表不允许为空，无法保存！');
                return;
            }

            const data = {
                id: details.id,
                scheduleData: JSON.stringify(scheduleData)
            };
            // console.log(data);
            const res = await putScheduleData(data);
            if (res?.status_code === 200) {
                message.success('保存时间轴成功');
            }

        } else {
            const data = {
                id: details.id,
                moduleType: details.moduleType,
                name: details.name,
                projectId: details.projectId,
                content: editorContents,
                url: path,
                isShowHot: details.isShowHot,
                isShowTab: details.isShowTab
            };
            const res = await moduleUpdateApi(data);
            if (res && res.status_code === 200) {
                message.success('保存成功');
            }
        }

    };

    const showTimelineModal = (node = null, sort = 0) => {
        console.log(node);
        if (!isEmpty(node) && sort > -1) {
            setCurrentTimelineNode(node);
            setCurrentTimelineNodeIndex(sort);

            const formData = { ...node };
            formData.start_time = moment(formData.start_time) || undefined;
            if (!!formData.end_time) {
                formData.end_time = moment(formData.end_time) || undefined;
            }
            if (!formData.end_time_type) {
                formData.end_time_type = 'date';
            }

            form.setFieldsValue(formData);
        } else {
            setCurrentTimelineNode(null);
            setCurrentTimelineNodeIndex(undefined);
        }

        setVisibleTimelineModal(true);
    };

    const changeScheduleTitle = e => setScheduleData({...scheduleData, title: e.target.value});

    const formatDateByPickerTypeForSave = (date, pickerType) => {
        let result = '';

        if (!!date) {
            switch (pickerType) {
                case 'date':
                    result = date.format('YYYY-MM-DD');
                    break;
                case 'month':
                    result = date.format('YYYY-MM');
                    break;
                case 'year':
                    result = date.format('YYYY');
                    break;
                default:
                    result = date.format('YYYY-MM-DD');
                    break;
            }
        }

        return result;
    };

    const onSaveTimelineNode = () => {
        form.validateFields()
            .then(values => {
                const node = {...values};

                node.start_time = formatDateByPickerTypeForSave(node.start_time, node.start_time_type);
                if (!!node.end_time) {
                    node.end_time = formatDateByPickerTypeForSave(node.end_time, node.end_time_type);
                }

                const newScheduleData = {...scheduleData};
                if (currentTimelineNodeIndex !== undefined && currentTimelineNodeIndex > -1) {
                    // 编辑
                    newScheduleData.nodes[currentTimelineNodeIndex] = node;
                } else {
                    // 新增
                    if (!newScheduleData.nodes) {
                        newScheduleData.nodes = [];
                    }
                    newScheduleData.nodes.push(node);
                }

                setScheduleData(newScheduleData);

                form.resetFields();

                setVisibleTimelineModal(false);
            })
            .catch(err => {
                //
            });
    };

    const onCancel = () => {
        form.resetFields();
        setVisibleTimelineModal(false);
    };

    const deleteTimelineNode = idx => {
        const newScheduleData = {...scheduleData};
        newScheduleData.nodes.splice(idx, 1);
        setScheduleData(newScheduleData);
    };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        let startTime = form.getFieldValue('start_time');
        if (!!startTime) {
            let startTimeType = form.getFieldValue('start_time_type');
            switch (startTimeType) {
                case 'month':
                    startTime = startTime.endOf('month');
                    break;
                case 'year':
                    startTime = startTime.startOf('year');
                    break;
            }

            return current && current < startTime;
        } else {
            return false;
        }
    };

    const getFrees = async () => {
        const res = await moduleGetByIdApi({ id });
        if (res.status_code === 200) {
            setDetails(res.data);
            setPath(res.data.url);
            setEditorContents(res.data.content);
            setInitEditorValue(res.data.content);
            setModuleType(res.data.moduleType);

            if (res.data.moduleType === 'time_line') {
                setScheduleData(JSON.parse(res.data.content) || {});
            }
        }
    };

    useEffect(() => {
        getFrees();
    }, [id]);


    return (
        <>
            <Content className="main-content-box">

                <div className="main-content-body module-free-body">

                    <div className="med-project-module-head">
                        <div className="module-title">
                            {moduleType === 'link' && <h2>模块链接</h2>}
                            {moduleType === 'rich_text' && <>
                                <h2>模块内容</h2>
                                <span className="tips">编辑内容使用的一级标题‘H1’，会在用户端做为目录导航呈现。如需上传图片，建议图片宽度保持1700 px，图片两侧不建议留白。</span>
                            </>}
                            {moduleType === 'time_line' && <h2>时间轴</h2>}
                        </div>
                        <div className="preview">
                            <Button
                                type="link"
                                size="small"
                                ghost
                                style={{ padding: 0 }}
                                onClick={() => preview()}
                                icon={<>
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                                        </svg>
                                    </span>
                                </>}
                            >
                                <span>预览</span>
                            </Button>
                        </div>
                    </div>

                    {moduleType === 'link' &&
                        <div className="main-frees">
                            <span className="detail-label">链接地址：</span>
                            <Input className="free-input" placeholder="链接地址" defaultValue={path} onChange={(e) => pathChange(e)}/>
                        </div>
                    }

                    {moduleType === 'rich_text' &&
                        <Space className="main-editor">
                            <TinymceEditor
                                initEditorValue={initEditorValue}
                                setEditorContents={setEditorContents}
                                editorContents={editorContents}
                                id="editorContents"
                                placeholder="编辑内容使用的一级标题‘H1’，会在用户端做为目录导航呈现。如需上传图片，建议图片宽度保持1700px，图片两侧不建议留白。"
                            />
                        </Space>
                    }

                    {moduleType === 'time_line' &&
                        <div className="main-schedule">
                            <Row style={{display: 'flex', marginBottom: 16}}>
                                <Col style={{display: 'flex', alignItems: 'center'}}>模块时间轴名称：</Col>
                                <Col style={{flex: 1}}>
                                    <Input
                                        style={{width: '100%'}}
                                        placeholder="请输入模块时间轴名称，不超过50字"
                                        value={scheduleData?.title || undefined}
                                        maxLength={50}
                                        showCount
                                        allowClear
                                        onChange={changeScheduleTitle}
                                    />
                                </Col>
                            </Row>

                            <div className="schedule-content">
                                <div className="schedule-head">
                                    <span className="schedule-label red-asterisk-before">模块时间轴列表：</span>
                                    <Button type="primary" onClick={() => showTimelineModal()}>添加时间点</Button>
                                </div>

                                <div className="schedule-table">
                                    <div className="schedule-table-head">
                                        <span className="schedule-head-start">开始时间</span>
                                        <span className="schedule-head-end">结束时间</span>
                                        <span className="schedule-head-name">时间点名称</span>
                                        <span className="schedule-head-text">时间点描述</span>
                                        <span className="schedule-head-settings">操作</span>
                                    </div>

                                    {scheduleData.nodes && scheduleData.nodes.length
                                        ? (
                                            <SortableMainContainer
                                                helperClass="row-dragging"
                                                useWindowAsScrollContainer={true}
                                                useDragHandle
                                                distance={10}
                                                items={scheduleData.nodes}
                                                onSortEnd={onSortEndHandler}>
                                            </SortableMainContainer>
                                        )
                                        : (
                                            <div className="ant-empty ant-empty-normal">
                                                <div className="ant-empty-image">

                                                    <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                            <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                            <g className="ant-empty-img-simple-g" fillRule="nonzero">
                                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
                                                            </g>
                                                        </g>
                                                    </svg>

                                                </div>
                                                <div className="ant-empty-description">暂无数据</div>
                                            </div>
                                        )

                                    }


                                </div>
                            </div>
                        </div>
                    }

                    <div className="free-footer">
                        <Button type="primary" onClick={() => onUpdateModule()}>保存</Button>
                    </div>

                </div>

            </Content>

            <Modal
                title={!isEmpty(currentTimelineNode) ? '编辑时间点' : '新增时间点'}
                width={800}
                centered
                onOk={onSaveTimelineNode}
                onCancel={onCancel}
                open={visibleTimelineModal}
                className="addSchedule"
            >
                <Form
                    form={form}
                    autoComplete="off"
                    defaultValue={currentTimelineNode}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >

                    <Form.Item label="开始时间" className="required">
                        <Row gutter={8}>
                            <Col>
                                <Form.Item
                                    name="start_time_type"
                                    noStyle
                                    initialValue="date"
                                >
                                    <Select options={datePickerTypes}/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldValue}) => {
                                        return (
                                            <Form.Item
                                                name="start_time"
                                                noStyle
                                                rules={[
                                                    {required: true, message: '开始时间不能为空'}
                                                ]}
                                            >
                                                <DatePicker picker={getFieldValue('start_time_type') || 'date'}/>
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item label="结束时间">
                        <Row gutter={8}>
                            <Col>
                                <Form.Item
                                    name="end_time_type"
                                    noStyle
                                    initialValue="date"
                                >
                                    <Select options={datePickerTypes}/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldValue}) => {
                                        return (
                                            <Form.Item name="end_time" noStyle>
                                                <DatePicker
                                                    picker={getFieldValue('end_time_type') || 'date'}
                                                    disabledDate={disabledDate}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        label="时间点名称"
                        name="title"
                        rules={[{ required: true, message: '请输入时间点名称' }]}
                    >
                        <Input placeholder="请输入时间点名称，不超过20字" maxLength={20} showCount allowClear/>
                    </Form.Item>

                    <Form.Item
                        label="跳转链接"
                        name="url"
                        rules={[
                            {pattern: /^https?:\/\//i, message: '跳转链接格式不正确'}
                        ]}
                    >
                        <Input placeholder="请输入名称跳转链接" allowClear/>
                    </Form.Item>

                    <Form.Item label="时间点描述" name="desc">
                        <Input.TextArea placeholder="请输入时间点描述，不超过100字" rows={5} maxLength={100} showCount allowClear/>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    );
}
