import React, { useEffect, useState } from 'react';
import { filterFormItemLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Space, Select, DatePicker } from 'antd';
import { projectKeys, projectType, eventKeys, eventType } from './utils'
import './index.less';
import { getProjectsOfType } from '../../../../services/Mdm';

const { RangePicker } = DatePicker;

const filterFormGridLayout = {
    sm: { span: 24 },
    md: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 8 },
}

const ProjectsFilterForm = ({ handleFilterFinish }) => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        handleFilterFinish({});
    };

    const code = Form.useWatch('code', form);
    const projectId = Form.useWatch('projectId', form);

    useEffect(() => {
        form.resetFields(['event']);
        form.resetFields(['projectId']);
    }, [code, form]);

    useEffect(() => {
        form.resetFields(['event']);
    }, [projectId]);

    const [projects, setProjects] = useState([]);

    const changeProjectType = val => {
        // console.log(val);
        if (!!val) {
            getProjectsOfType({ code: val })
                .then(res => {
                    if (res && res.status_code === 200) {
                        setProjects(res.data);
                    } else {
                        setProjects([]);
                    }
                });
        } else {
            setProjects([]);
        }
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户唯一标识" name="memcard">
                        <Input allowClear placeholder="用户唯一标识" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="用户昵称" name="nickName">
                        <Input allowClear placeholder="用户昵称" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="用户手机号" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="事件记录时间" name="onlineTime">
                        <RangePicker showTime className="ProjectRangPicker" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="项目类型" name="code">
                        <Select allowClear placeholder="选择项目类型" onChange={changeProjectType}>
                            <Select.Option value={projectKeys.survey}>{projectType.survey}</Select.Option>
                            <Select.Option value={projectKeys.conference}>{projectType.conference}</Select.Option>
                            <Select.Option value={projectKeys.selection}>{projectType.selection}</Select.Option>
                            <Select.Option value={projectKeys.invitation}>{projectType.invitation}</Select.Option>
                            <Select.Option value={projectKeys.researchhospital}>{projectType.researchhospital}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                {!!code && (
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="项目" name="projectId">
                            <Select placeholder="选择项目" showSearch optionFilterProp="children" allowClear>
                                {projects.map((p, index) => <Select.Option key={index} value={p.projectId}>{`${p.projectName} [${p.projectId}]`}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                {!!projectId && (
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="用户事件" name="event">
                            <Select allowClear placeholder="选择医疗决策人在线动作">
                                {code === projectKeys.survey && <Select.Option value={eventKeys.submit_survey}>{eventType.submit_survey}</Select.Option>}
                                {code === projectKeys.conference && <Select.Option value={eventKeys.checkin}>{eventType.checkin}</Select.Option>}
                                {code === projectKeys.conference && <Select.Option value={eventKeys.enroll}>{eventType.enroll}</Select.Option>}
                                {code === projectKeys.conference && <Select.Option value={eventKeys.confirm_profile}>{eventType.confirm_profile}</Select.Option>}
                                {code === projectKeys.selection && <Select.Option value={eventKeys.submit_case}>{eventType.submit_case}</Select.Option>}
                                {code === projectKeys.selection && <Select.Option value={eventKeys.score_case}>{eventType.score_case}</Select.Option>}
                                {code === projectKeys.selection && <Select.Option value={eventKeys.download_judge_cert}>{eventType.download_judge_cert}</Select.Option>}
                                {code === projectKeys.selection && <Select.Option value={eventKeys.download_case_cert}>{eventType.download_case_cert}</Select.Option>}
                                {code === projectKeys.selection && <Select.Option value={eventKeys.view_rank}>{eventType.view_rank}</Select.Option>}
                                {code === projectKeys.invitation && <Select.Option value={eventKeys.confirm_invitation}>{eventType.confirm_invitation}</Select.Option>}
                                {code === projectKeys.researchhospital && <Select.Option value={eventKeys.submit_data}>{eventType.submit_data}</Select.Option>}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col className="filter-form-buttons" offset={2}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
};

export default ProjectsFilterForm;
