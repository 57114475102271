import CheckOrganizations from '../components/Healthclass/CheckOrganizations/CheckOrganizations';
import CheckOrganizationsInfo from '../components/Healthclass/CheckOrganizations/CheckOrganizationsInfo';
import CheckOrgContractsSave from '../components/Healthclass/CheckOrganizations/CheckOrgContractsSave';
import CheckOrgTrialSave from '../components/Healthclass/CheckOrganizations/CheckOrgTrialSave';
import TrialOrganizations from '../components/Healthclass/TrialOrganizations/TrialOrganizations';
import TrialOrganizationsInfo from '../components/Healthclass/TrialOrganizations/TrialOrganizationsInfo'
import TrialOrgContractsSave from '../components/Healthclass/TrialOrganizations/TrialOrgContractsSave'
import FormalOrganizations from '../components/Healthclass/FormalOrganizations/FormalOrganizations'
import FormalOrganizationsInfo from '../components/Healthclass/FormalOrganizations/FormalOrganizationsInfo'
import FormalOrgContractsUpdate from '../components/Healthclass/FormalOrganizations/FormalOrgContractsUpdate'
import FormalOrganizationsInfoUpdate from '../components/Healthclass/FormalOrganizations/FormalOrganizationsInfoUpdate'
import TrialOrganizationsInfoUpdate from '../components/Healthclass/TrialOrganizations/TrialOrganizationsInfoUpdate'
import HealthClassClue from '../components/Healthclass/FormalOrganizations/HealthClassClue';

// 健学云模块相关路由
const healthclassRoutes = [
    {path: '/healthclass/CheckOrganizations',element: <CheckOrganizations/>},
    {path: '/healthclass/CheckOrganizationsInfo',element: <CheckOrganizationsInfo/>},
    {path: '/healthclass/CheckOrgContractsSave',element: <CheckOrgContractsSave/>},
    {path: '/healthclass/CheckOrgTrialSave',element: <CheckOrgTrialSave/>},
    {path: '/healthclass/TrialOrganizations',element: <TrialOrganizations/>},
    {path: '/healthclass/TrialOrganizationsInfo',element: <TrialOrganizationsInfo/>},
    {path: '/healthclass/TrialOrganizationsInfoUpdate',element: <TrialOrganizationsInfoUpdate/>},
    {path: '/healthclass/TrialOrgContractsSave',element: <TrialOrgContractsSave/>},
    {path: '/healthclass/FormalOrganizations',element: <FormalOrganizations/>},
    {path: '/healthclass/FormalOrganizationsInfo',element: <FormalOrganizationsInfo/>},
    {path: '/healthclass/FormalOrganizationsInfoUpdate',element: <FormalOrganizationsInfoUpdate/>},
    {path: '/healthclass/FormalOrgContractsUpdate',element: <FormalOrgContractsUpdate/>},
    {path: '/healthclass/clue',element: <HealthClassClue/>},
];

export default healthclassRoutes;
