import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, message, Table, Upload, Modal, Progress, Typography } from 'antd';
import { GuestJourneyImportApi, GuestJourneyImportCheckApi } from '@services/Guest';
import './index.less';
import config from '@config';
import { prepareBCOSSUrl } from '@utils/utils';

const {Text} = Typography;

// 导入嘉宾行程单
const GuestJourneyImport = ({
    importDataOpen,
    setImportDataOpen,
    getExpertList
}) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [docList, setDocList] = useState(null);
    const [docProgressPercent, setDocProgressPercent] = useState(undefined);
    const [docShowProgress, setDocShowProgress] = useState(false);

    const importDataRef = useRef(null);

    const [confirmData, setConfirmData] = useState([]);
    const [confirmDataOpen, setConfirmDataOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const columnsConfirm = [
        {
            title: "提示",
            width: 200,
            dataIndex: "msg",
            fixed: 'left',
            render: (_, record) => (<div>{record.msg ? <Text type="danger">{record.msg}</Text> : '—'}</div>)
        },
        {
            title: '嘉宾',
            children: [
                {
                    title: '手机号',
                    dataIndex: 'phone',
                    key: 'phone',
                    width: 200,
                }
            ],
        },
        {
            title: '联系人',
            children: [
                {
                    title: '联系人姓名1',
                    dataIndex: 'contractsName1',
                    key: 'contractsName1',
                    width: 200,
                },
                {
                    title: '联系人手机号1',
                    dataIndex: 'contractsPhone1',
                    key: 'contractsPhone1',
                    width: 200,
                },
                {
                    title: '联系人姓名2',
                    dataIndex: 'contractsName2',
                    key: 'contractsName2',
                    width: 200,
                },
                {
                    title: '联系人手机号2',
                    dataIndex: 'contractsPhone2',
                    key: 'contractsPhone2',
                    width: 200,
                },
                {
                    title: '联系人姓名3',
                    dataIndex: 'contractsName3',
                    key: 'contractsName3',
                    width: 200,
                },
                {
                    title: '联系人手机号3',
                    dataIndex: 'contractsPhone3',
                    key: 'contractsPhone3',
                    width: 200,
                },
                {
                    title: '联系人姓名4',
                    dataIndex: 'contractsName4',
                    key: 'contractsName4',
                    width: 200,
                },
                {
                    title: '联系人手机号4',
                    dataIndex: 'contractsPhone4',
                    key: 'contractsPhone4',
                    width: 200,
                },
                {
                    title: '联系人姓名5',
                    dataIndex: 'contractsName5',
                    key: 'contractsName5',
                    width: 200,
                },
                {
                    title: '联系人手机号5',
                    dataIndex: 'contractsPhone5',
                    key: 'contractsPhone5',
                    width: 200,
                },
            ],
        },
        {
            title: '嘉宾来程信息-交通',
            children: [
                {
                    title: '来程交通方式',
                    dataIndex: 'arrivalInfoTrafficTrafficType',
                    key: 'arrivalInfoTrafficTrafficType',
                    width: 200,
                },
                {
                    title: '来程交通班次/列次',
                    dataIndex: 'arrivalInfoTrafficTrafficNo',
                    key: 'arrivalInfoTrafficTrafficNo',
                    width: 200,
                },
                {
                    title: '来程交通出发时间',
                    dataIndex: 'arrivalInfoPickupDropoffDepartureDatetime',
                    key: 'arrivalInfoPickupDropoffDepartureDatetime',
                    width: 200,
                },
                {
                    title: '来程交通出发地点',
                    dataIndex: 'arrivalInfoTrafficDeparturePlace',
                    key: 'arrivalInfoTrafficDeparturePlace',
                    width: 200,
                },
                {
                    title: '来程交通抵达时间',
                    dataIndex: 'arrivalInfoTrafficArrivalDatetime',
                    key: 'arrivalInfoTrafficArrivalDatetime',
                    width: 200,
                },
                {
                    title: '来程交通抵达地点',
                    dataIndex: 'arrivalInfoTrafficArrivalPlace',
                    key: 'arrivalInfoTrafficArrivalPlace',
                    width: 200,
                }
            ],
        },
        {
            title: '嘉宾来程信息-接送',
            children: [
                {
                    title: '来程是否需要接送',
                    dataIndex: 'arrivalInfoPickupDropoffNeedPickupDropoff',
                    key: 'arrivalInfoPickupDropoffNeedPickupDropoff',
                    width: 200,
                },
                {
                    title: '来程司机姓名',
                    dataIndex: 'arrivalInfoPickupDropoffDriverName',
                    key: 'arrivalInfoPickupDropoffDriverName',
                    width: 200,
                },
                {
                    title: '来程司机手机号',
                    dataIndex: 'arrivalInfoPickupDropoffDriverPhone',
                    key: 'arrivalInfoPickupDropoffDriverPhone',
                    width: 200,
                },
                {
                    title: '来程司机车辆颜色',
                    dataIndex: 'arrivalInfoPickupDropoffDriverCarColor',
                    key: 'arrivalInfoPickupDropoffDriverCarColor',
                    width: 200,
                },
                {
                    title: '来程司机车辆车牌号',
                    dataIndex: 'arrivalInfoPickupDropoffDriverCarNo',
                    key: 'arrivalInfoPickupDropoffDriverCarNo',
                    width: 200,
                },
                {
                    title: '来程接送出发时间',
                    dataIndex: 'arrivalInfoPickupDropoffDepartureDatetime',
                    key: 'arrivalInfoPickupDropoffDepartureDatetime',
                    width: 200,
                },
                {
                    title: '来程接送出发地点',
                    dataIndex: 'arrivalInfoPickupDropoffDeparturePlace',
                    key: 'arrivalInfoPickupDropoffDeparturePlace',
                    width: 200,
                },

                {
                    title: '来程接送抵达时间',
                    dataIndex: 'arrivalInfoPickupDropoffArrivalDatetime',
                    key: 'arrivalInfoPickupDropoffArrivalDatetime',
                    width: 200,
                },
                {
                    title: '来程接送抵达地点',
                    dataIndex: 'arrivalInfoPickupDropoffArrivalPlace',
                    key: 'arrivalInfoPickupDropoffArrivalPlace',
                    width: 200,
                },
                {
                    title: '来程备注',
                    dataIndex: 'arrivalInfoRemarks',
                    key: 'arrivalInfoRemarks',
                    width: 400,
                },
            ],
        },
        {
            title: '嘉宾返程信息-交通',
            children: [
                {
                    title: '返程交通方式',
                    dataIndex: 'returnInfoTrafficTrafficType',
                    key: 'returnInfoTrafficTrafficType',
                    width: 200,
                },
                {
                    title: '返程交通班次/列次',
                    dataIndex: 'returnInfoTrafficTrafficNo',
                    key: 'returnInfoTrafficTrafficNo',
                    width: 200,
                },
                {
                    title: '返程交通出发时间',
                    dataIndex: 'returnInfoPickupDropoffDepartureDatetime',
                    key: 'returnInfoPickupDropoffDepartureDatetime',
                    width: 200,
                },
                {
                    title: '返程交通出发地点',
                    dataIndex: 'returnInfoTrafficDeparturePlace',
                    key: 'returnInfoTrafficDeparturePlace',
                    width: 200,
                },
                {
                    title: '返程交通抵达时间',
                    dataIndex: 'returnInfoTrafficArrivalDatetime',
                    key: 'returnInfoTrafficArrivalDatetime',
                    width: 200,
                },
                {
                    title: '返程交通抵达地点',
                    dataIndex: 'returnInfoTrafficArrivalPlace',
                    key: 'returnInfoTrafficArrivalPlace',
                    width: 200,
                }
            ],
        },
        {
            title: '嘉宾返程信息-接送',
            children: [
                {
                    title: '返程是否需要接送',
                    dataIndex: 'returnInfoPickupDropoffNeedPickupDropoff',
                    key: 'returnInfoPickupDropoffNeedPickupDropoff',
                    width: 200,
                },
                {
                    title: '返程司机姓名',
                    dataIndex: 'returnInfoPickupDropoffDriverName',
                    key: 'returnInfoPickupDropoffDriverName',
                    width: 200,
                },
                {
                    title: '返程司机手机号',
                    dataIndex: 'returnInfoPickupDropoffDriverPhone',
                    key: 'returnInfoPickupDropoffDriverPhone',
                    width: 200,
                },
                {
                    title: '返程司机车辆颜色',
                    dataIndex: 'returnInfoPickupDropoffDriverCarColor',
                    key: 'returnInfoPickupDropoffDriverCarColor',
                    width: 200,
                },
                {
                    title: '返程司机车辆车牌号',
                    dataIndex: 'returnInfoPickupDropoffDriverCarNo',
                    key: 'returnInfoPickupDropoffDriverCarNo',
                    width: 200,
                },
                {
                    title: '返程接送出发时间',
                    dataIndex: 'returnInfoPickupDropoffDepartureDatetime',
                    key: 'returnInfoPickupDropoffDepartureDatetime',
                    width: 200,
                },
                {
                    title: '返程接送出发地点',
                    dataIndex: 'returnInfoPickupDropoffDeparturePlace',
                    key: 'returnInfoPickupDropoffDeparturePlace',
                    width: 200,
                },
        
                {
                    title: '返程接送抵达时间',
                    dataIndex: 'returnInfoPickupDropoffArrivalDatetime',
                    key: 'returnInfoPickupDropoffArrivalDatetime',
                    width: 200,
                },
                {
                    title: '返程接送抵达地点',
                    dataIndex: 'returnInfoPickupDropoffArrivalPlace',
                    key: 'returnInfoPickupDropoffArrivalPlace',
                    width: 200,
                },
                {
                    title: '返程备注',
                    dataIndex: 'returnInfoRemarks',
                    key: 'returnInfoRemarks',
                    width: 400,
                },
            ],
        },
        {
            title: '嘉宾住宿信息',
            children: [
                {
                    title: '是否入住',
                    dataIndex: 'hotelInfoHotelNeedHotel',
                    key: 'hotelInfoHotelNeedHotel',
                    width: 100,
                },
                {
                    title: '入住日期',
                    dataIndex: 'hotelInfoHotelCheckinDate',
                    key: 'hotelInfoHotelCheckinDate',
                    width: 200,
                },
                {
                    title: '退房日期',
                    dataIndex: 'hotelInfoHotelCheckoutDate',
                    key: 'hotelInfoHotelCheckoutDate',
                    width: 200,
                },
                {
                    title: '酒店名称',
                    dataIndex: 'hotelInfoHotelHotelName',
                    key: 'hotelInfoHotelHotelName',
                    width: 200,
                },
                {
                    title: '酒店地址',
                    dataIndex: 'hotelInfoHotelHotelAddress',
                    key: 'hotelInfoHotelHotelAddress',
                    width: 200,
                },
                {
                    title: '房间类型',
                    dataIndex: 'hotelInfoHotelHotelRoomType',
                    key: 'hotelInfoHotelHotelRoomType',
                    width: 200,
                },
                {
                    title: '房间号',
                    dataIndex: 'hotelInfoHotelHotelRoomNo',
                    key: 'hotelInfoHotelHotelRoomNo',
                    width: 100,
                },
        
                {
                    title: '住宿备注',
                    dataIndex: 'hotelInfoRemarks',
                    key: 'hotelInfoRemarks',
                    width: 200,
                },
            ],
        },
    ];

    // 读取数据
    const handleImportDataOk = () => {
        setConfirmDataOpen(true);
        setImportDataOpen(false);
        setConfirmData(importDataRef.current?.data || []);
    };

    // 关闭导入数据弹框
    const handleImportDataCancel = () => {
        setImportDataOpen(false);
    };

    // 开始导入数据
    const handleConfirmDataOk = async () => {
        setBtnLoading(true);
        const res = await GuestJourneyImportApi({
            data: importDataRef.current?.data || [],
            conferenceId: params.id,
        });
        setBtnLoading(false);
        if (res && res.status_code === 200) {
            setConfirmDataOpen(false);
            message.success(`共读取${importDataRef.current?.total}条，成功导入${importDataRef.current?.right}条，未导入${importDataRef.current?.error}条`);
            getExpertList();
        }
    };

    // 关闭导入确认数据弹框
    const handleConfirmDataCancel = () => {
        setConfirmDataOpen(false);
    };

    const fileProps = {
        name: "file",
        customRequest: async (info) => {
            const isJpgOrPng = info.file.type === "application/vnd.ms-excel" || info.file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

            const isLt30M = info.file.size / 1024 / 1024 <= 20;
            if (!isJpgOrPng) {
                message.error("只能上传 xls、xlsx 格式!");
                return;
            }
            if (!isLt30M) {
                message.error("文件大小不能超过20M");
                return;
            }

            let formData = new FormData();
            formData.append("fileName", info.file);
            formData.append("conferenceId", params.id);

            setDocShowProgress(true);
            let configParams = {
                onUploadProgress: (Progress) => {
                    let percents = Math.round((Progress.loaded * 200) / Progress.total)
                    setDocProgressPercent(percents);  // 更新进度条
                }
            };

            GuestJourneyImportCheckApi(formData, configParams).then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    setDocShowProgress(false);
                    setDocProgressPercent(undefined);
                    let docItem = { name: info.file.name };
                    setDocList(docItem);
                    importDataRef.current = res.data;
                }
            });
        }
    };

    return (
        <>
            <Modal
                title='导入嘉宾行程'
                open={importDataOpen}
                onOk={handleImportDataOk}
                onCancel={handleImportDataCancel}
                className="importDataModal"
                centered
                okText="读取数据"
                okButtonProps={{
                    disabled: !docList?.name,
                }}
            >
                <div>导入文件：
                    <Upload {...fileProps} maxCount={1} fileList={[]}>
                        <Button className="btnWithIcon"
                            icon={<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                </svg>
                            </>}
                        >
                            {docList?.name ? '更换文件' : '选择文件'}
                        </Button>
                    </Upload>
                    <div className="fileInfo">
                        {docList && <p>{docList?.name}</p>}
                        {docShowProgress && <Progress percent={docProgressPercent} />}
                        <p>支持Excel格式，文件大小不超过20MB</p>
                    </div>
                </div>
            </Modal>

            <Modal
                title='导入嘉宾行程数据确认'
                open={confirmDataOpen}
                onCancel={handleConfirmDataCancel}
                className="confirmImportDataModal"
                centered
                footer={<>
                    <Button onClick={handleConfirmDataCancel}>取消</Button>
                    <Button type="primary" onClick={handleConfirmDataOk} loading={btnLoading}>开始导入</Button>
                </>}
            >
                <div>
                    <p>共读取到：{importDataRef.current?.total}条，可导入：{importDataRef.current?.right}条</p>
                    <Table
                        columns={columnsConfirm}
                        dataSource={confirmData}
                        pagination={false}
                        scroll={{x: 'max-content', y: true}}
                    />
                </div>
            </Modal>
        </>
    );
};
export default GuestJourneyImport;

