import React, { useState, useEffect } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, Button, Space } from 'antd';
import { get } from '@/core/request';

export default function SurveysUsersFilterForm({
    handleFilterFinish
}) {
    const [form] = Form.useForm();
    // 产品线
    const [liveId, setLiveId] = useState([]);
    // 所属企业
    const [orgId, setOrgId] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [allTitle, setAllTitle] = useState([]);

    // 产品线
    useEffect(() => {
        get('/dms/production-lines/queryAll').then((res) => {
            setLiveId(res.data);
        })
    }, []);

    // 合作企业
    useEffect(() => {
        get('/dms/enterprises/queryAll').then((res) => {
            setOrgId(res.data);
        })
    }, []);

    // 科室
    useEffect(() => {
        get(`/dms/doctor/tags/allTags`).then((res) => {
            setDepartmentData(res.data);
        })
    }, []);

    // 职称
    useEffect(() => {
        get(`/dms/doctor/title/allTitles`).then((res) => {
            setAllTitle(res.data);
        })
    }, []);

    const onFinish = (values) => {
        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name:undefined,
            phone:undefined,
            cityName:undefined,
            orgId:undefined,
            productId:undefined,
            hospital:undefined,
            departmentId:undefined,
            titleId:undefined
        }
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="姓名" name="name">
                        <Input allowClear placeholder="请输入姓名" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="请输入手机号" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所在城市" name="cityName">
                        <Input allowClear placeholder="请输入所在城市" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线" name="productId">
                        <Select allowClear placeholder="请选择产品线">
                            {liveId && liveId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="医院" name="hospital">
                        <Input allowClear placeholder="请输入所在医院" autoComplete="off"/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所在科室" name="departmentId">
                        <Select allowClear placeholder="请选择科室">
                            {departmentData && departmentData.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="职称" name="titleId">
                        <Select allowClear placeholder="请选择职称">
                            {allTitle && allTitle.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
