import React, { Fragment, useState,useEffect,useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Form, Layout, Space, Tooltip, Drawer, Table, message } from 'antd';
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { drawerWidth } from '../../../utils/property';
import { addWhitListsApi,getWhitListsApi,delWhitListsApi } from "@services/Conference";

const { Content } = Layout;
const { Search } = Input;

export default function LiveWhitelist() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const conferenceId = parseInt(params?.cid) || 0; // 会议项目 ID
    const liveId = parseInt(params?.id) || 0; // 直播 ID

    const [page,setPage]=useState(1)
    const [pageSize,setPageSize]=useState(10)
    const [total,setTotal]=useState(null)
    const [list,setList]=useState([])
    const [whitelistSearch,setWhitelistSearch]=useState(null)

    const [loading, setLoading] = useState(false); // loading 状态
    
    const searchRef=useRef()

    const onWhitListsSearch =(value)=>{
        setPage(1)
        setPageSize(10)
        getWhitListsApi({liveId:liveId,page:1,pageSize:10,phone:value||''}).then(res=>{
            if(res.status_code==200) {
                setTotal(res.data.totalCount);
                setList(res.data.list);
            
            }
        })
    }

    const handleRefresh = () => {
        setPage(1)
        setPageSize(10)
        getWhitListsApi({liveId:liveId,page:1,pageSize:10}).then(res=>{
            if(res.status_code ===200){
                setTotal(res.data.totalCount)
                setList(res.data.list)
                setWhitelistSearch(null);
            }
        })
    };

    const onWhitelistChange=(e)=>{
        setWhitelistSearch(e.target.value)
    }

    const delWhitList=(id)=>{
        delWhitListsApi({id:id}).then(res=>{
            if(res.status_code===200){
                getWhitListsApi({liveId:liveId,page,pageSize}).then(res=>{
                    if(res.status_code ===200){
                        setTotal(res.data.totalCount)
                        setList(res.data.list)
                        setWhitelistSearch(null);
                    }
                })
            }
        })
    }

    const columns = [
        { title: '记录ID', dataIndex: 'id', width: 80 },
        { title: '手机号', dataIndex: 'phone' },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 100,
            render: (_, record) => {
                return <Button size="small" type="link" onClick={()=>delWhitList(record.id)}>删除</Button>
            }
        },
    ];


    const [addWhitelistForm] = Form.useForm();

    const [visibleAddWhitelistDrawer, setVisibleAddWhitelistDrawer] = useState(false);

    const showAddWhitelistDrawer = () => {
        setVisibleAddWhitelistDrawer(true);
    };

    const closeAddWhitelistDrawer = () => {
        setVisibleAddWhitelistDrawer(false);
        addWhitelistForm.resetFields();
    };

    const onOkBtn = () => {
        let str = addWhitelistForm.getFieldsValue().data
        if(!str) {
            message.info('未输入内容')
            return
        }
        let arr = str.split('\n')
        let bool = true, index = 0, newArr = []
        const reg=/^1[3-9]\d{9}$/
        arr.forEach((item, ind) => {
            if(!bool) return
            if(!item) return
            if(!reg.test(item)){
                bool = false
                index = ind + 1
            }
            newArr.push(item)
        })
        let filArr = arr.filter((it) => it !== "");
        if(filArr.length>1001){
            message.info(`一次最多添加 1000 个`)
            return
        }
        if(!bool){
            message.info(`第 ${index} 行输入有误，请检查后再提交`)
            return
        }
        addWhitListsApi({
			phones: filArr,
            liveId:liveId
		}).then(
			(res) => {
				if (res.status_code === 200) {
					handleRefresh()
				}
			}
		)
		closeAddWhitelistDrawer()
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const onChangePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
        getWhitListsApi({liveId:liveId,page,pageSize}).then(res=>{
            if(res.status_code ===200){
                setTotal(res.data.totalCount)
                setList(res.data.list)
                setWhitelistSearch(null);
            }
        })
	};

    //`/conf/conferences/${conferenceId}/lives/${liveId}/limit`
    const goBack=() => {
        navigate(`/conf/conferences/${conferenceId}/lives/${liveId}/limit`,{state:{...location.state}})
    }

    useEffect(()=>{
        console.log(location);
        handleRefresh()
    },[])

    return <Fragment>
        <div className="page-title">
            <span onClick={goBack} className="back"><LeftOutlined/></span>
            <span className="current-title">观看限制&ndash;白名单</span>
            {!!liveId && <span className="current-subtitle">{location.state.title||''}</span>}
        </div>

        <Content className="main-content-box">

            <div className="main-content-body">

                <div className="main-toolbar">
                    <div className="main-tools">
                        <Space>
                            <Button type="primary" onClick={() => showAddWhitelistDrawer()}>添加白名单</Button>

                            <Search
                                placeholder="手机号"
                                allowClear
                                style={{ width: 200 }}
                                onSearch={onWhitListsSearch}
                                value={whitelistSearch}
                                onChange={onWhitelistChange}
                                ref={searchRef}
                            />
                        </Space>
                    </div>
                    <div className="sub-tools">
                        <Tooltip title="刷新列表">
                            <Button type="default" icon={<ReloadOutlined/>} onClick={handleRefresh}></Button>
                        </Tooltip>
                    </div>
                </div>

                <Table
                    rowKey="id"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection
                    }}
                    columns={columns}
                    dataSource={list}
                    loading={loading}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                />

            </div>

            <Drawer
                title="添加白名单"
                width={drawerWidth}
                open={visibleAddWhitelistDrawer}
                onClose={closeAddWhitelistDrawer}
                destroyOnClose
                footer={
                    <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={closeAddWhitelistDrawer}>取消</Button>
                            <Button type="primary" form="addWhitelistForm" htmlType="submit" onClick={onOkBtn}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <Form name="addWhitelistForm" form={addWhitelistForm} layout="vertical">
                    <Form.Item
                        label="白名单手机号"
                        name="data"
                        rules={[
                            {required: true, message: '手机号不能为空'}
                        ]}
                        help="一行一个白名单手机号，一次最多添加 1000 个"
                    >
                        <Input.TextArea rows={15}/>
                    </Form.Item>
                </Form>
            </Drawer>

        </Content>

    </Fragment>;
}
