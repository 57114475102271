import { Form, Modal,Input,InputNumber, message } from 'antd';
import React,{useEffect} from 'react';
import './index.less';
import {updatePeopleApi} from '@services/HealthClass';
const AddPeople = ({addPeople,closeAdd,rowData,getData}) => {
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const savePeople =()=>{
        console.log(form.getFieldsValue())
        form.validateFields().then(()=>{
            updatePeopleApi({...form.getFieldsValue(),id:rowData.id}).then((res)=>{
                if(res.status_code===200){
                    message.success('添加成功')
                    closeAdd()
                    getData()
                }
            });
        });
    };

    useEffect(() => {
        if (!addPeople) {
            form.resetFields();
        }
    }, [addPeople]);
    return (
        <div>
            <Modal
            open={addPeople}
            onCancel={closeAdd}
            title='增加成员人数'
            width={600}
            onOk={savePeople}
            >
                <Form form={form}>
                    <Form.Item label='产品版本'>
                        {rowData?.versionName}
                    </Form.Item>

                    <Form.Item name="user" label="增加成员人数" className='rules-icon'
                   >
                        <Form.Item name="userCount" noStyle
                        rules={[
                            {
                                required: true,
                                message: "请输入增加的成员人数",
                            },
                            {
                                pattern: /^\+?[1-9][0-9]*$/,//输入内容进行正则校验
                                message:'请输入正整数',
                            },

                        ]}
                        >
                        <InputNumber className='add-num'/>
                        </Form.Item>
                        <span className="ant-form-text">人</span>
                    </Form.Item>

                    <Form.Item name='remark' label='增加理由'
                     rules={[
                        {required: true,
                        message: "请填写增加理由",
                    }]}
                    >
                    <TextArea rows={2} autoSize={{minRows: 2,maxRows: 2}}/>
                    </Form.Item>
                </Form>

            </Modal>
        </div>
    );
}

export default AddPeople;
