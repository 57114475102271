import React, { Fragment, useCallback, useState, useEffect, useMemo, useRef } from "react";
import moment from 'moment';
import ExpertFilterForm from "./ProjectsFilterForm";
import { Layout, Table, Spin, Avatar, Button, message } from "antd";
import { fullPagerConfig } from "@/utils/property";
import { getOnlineDailyApi, downloadStatisticsData } from "@services/Mdm";
import { UserOutlined, DownloadOutlined } from "@ant-design/icons";
import { getFileUrl, projectType, eventType } from './utils'

const { Content } = Layout;

const Projects = () => {
    // 渲染table数据的state
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    const [filterObj, setFilterObj] = useState({});

    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const lastParamsRef = useRef();

    // 筛选数据
    const handleFilterFinish = (value) => {
        let newValue = { ...value };
        if (newValue.onlineTime) {
            newValue.startDate = newValue.onlineTime[0].format('YYYY-MM-DD HH:mm:ss');
            newValue.endDate = newValue.onlineTime[1].format('YYYY-MM-DD HH:mm:ss');
            delete newValue.onlineTime;
        }
        setFilterObj(newValue);
        setCurrPage(1);
    };

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const [pedding, setPedding] = useState(false);

    // 获取用户列表
    const getOnlineDailyList = async () => {
        setPedding(true);
        const params = {
            ...filterObj,
            pageSize,
            page: currPage
        }
        const res = await getOnlineDailyApi(params);
        lastParamsRef.current = params;
        setPedding(false);
        if (res && res.status_code === 200) {
            setTableData(res.data.list);
            setTotal(res.data.totalCount);
        }
    };

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: total,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page)
        },
        current: currPage,
    };

    // 导出数据
    const handleExportFile = () => {
        if (tableData.length === 0) {
            return message.warning('当前列表没有数据，无法导出')
        }
        message.success('请稍后，正在导出');
        downloadStatisticsData(lastParamsRef.current).then((res) => {
            if (res.size !== 0) {
                const fileName = `参与项目统计_${moment().format('YYYYMMDDTHHmmss')}.xlsx`;
                let a = document.createElement('a');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.click();
                setTimeout(() => {
                    URL.revokeObjectURL(objectUrl);
                }, 1000)
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    // table 数据相关
    const columns = useMemo(() => {
        return [
            {
                title: "用户昵称",
                key: "id",
                render: (state, row) => (
                    <>
                        <Avatar src={getFileUrl(row.avatar)} icon={!row.avatar && <UserOutlined/>}/>
                        <span style={{ marginLeft: "5px" }}>{row.nickName}</span>
                    </>
                ),
            },
            { title: "手机号", dataIndex: "phone" },
            {
                title: "项目类型",
                key: "code",
                render: (state, row) => projectType[row.code],
            },
            { title: '项目名称', dataIndex: 'projectName' },
            { title: "项目ID", dataIndex: "projectId" },
            { title: "用户事件", key: "event", render: (_, row) => eventType[row.event] },
            { title: "事件记录时间", dataIndex: "onlineAt" },
        ];
    }, []);

    const getTitle = () => (
        <div className="projects_table_title">
            <p>数据明细</p>
            <Button icon={<DownloadOutlined/>} onClick={handleExportFile}>导出</Button>
        </div>
    );

    // 渲染table数据
    useEffect(() => {
        getOnlineDailyList();
    }, [pageSize, currPage, filterObj]);

    return (
        <Fragment>
            <div className="page-title">医疗决策人参与项目统计</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ExpertFilterForm handleFilterFinish={handleFilterFinish}/>
                </div>

                <div className="main-content-body">
                    <Spin spinning={pedding} delay={500}>
                        <Table
                            className="enterprise-table"
                            columns={columns}
                            dataSource={tableData}
                            pagination={paginationProps}
                            rowKey="id"
                            title={getTitle}
                            scroll={{ x: 800 }}
                        />
                    </Spin>
                </div>
            </Content>
        </Fragment>
    );
};

export default Projects;
