import { get, put, post, delet } from '@core/request';

/**邀请函接口服务**/

// 获取定向邀请用户列表
export const getInvitationUserListApi = (params) => {
    return post('/invitation/boss/invitationusers/userList', params);
};

// 删除定向邀请用户
export const deleteInvitationUserApi = (params) => {
    return delet('/invitation/boss/invitationusers/delete', params);
};
// 获取定向邀请用户列表
export const getUserListApi = (params) => {
    return post('/invitation/boss/invitationusers/guestList', params);
};

// 获取定向邀请用户列表
export const saveUserApi = (params) => {
    return post('/invitation/boss/invitationusers/bathSave', params);
};

// 导出邀请函链接
export const exportInvitationLink = (params, options) => {
    return get('/invitation/boss/invitationusers/download', params, options)
}


// 获取邀请用户信息
export const getUserInfoApi = (params) => {
    return get('/invitation/boss/users/getUserInfo', params);
}

// 获取邀请用户专家库信息
export const getUserBaseInfoApi = (params) => {
    return get('/user/ssomemberexperts/api/base-info', params)
}

// 更新专家库信息
export const putUserBaseInfoApi = (params) => {
    return put('/user/ssomemberexperts/api/edit', params)
}

// 发送服务通知
export const sendMpMessageApi = (params) => {
    return post('/invitation/boss/invitationusers/sendMpMessage', params)
}


// 获取邀请函一人一码
export const getInvitationInfoOneApi = (params) => {
    return get('/invitation/boss/invitations/getInvitationInfoOne', params)
}
// 确认邀请函一人一码
export const confirmTasksOneApi = (params) => {
    return put('/invitation/boss/invitations/confirmTasksOne', params)
}

// 设置用户电子邀请函
export const setupUserInvitationFile = data => put('/invitation/boss/invitationusers/setInvitationFile', data);

export const exportInvitationFile = params => get('/invitation/boss/invitations/word-export/download', params, { responseType: 'blob' });
