import React,{Fragment}from 'react';
import { Button, Layout, Table, Space, Form, Input, Select, Tooltip, Modal} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {saveTemplateApi} from '@services/Wj/Template';
const Createtemplate = ({isModalOpen,setIsModalOpen}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const handleOk = () => {
        // setIsModalOpen(false);
        form.validateFields().then(
            saveTemplateApi(form.getFieldsValue()).then(
                (res)=>{
                    if (res.status_code === 200) {
                        navigate('/wj/surveys/new/create',{state:{route:'newCreate',id:res.data}})
                      }
                }
            )
        )


      };
      const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false);
      };
      const  options=[
        {
          value: '1',
          label: '调研问卷',
        },
        {
          value: '2',
          label: '投票',
        },
        {
          value: '3',
          label: '活动报名',
        }
      ]

const handleChange=()=>{
    console.log(1);
}
    return (
        <Fragment>
            <div>
            <Modal
                    visible={isModalOpen}
                    title={'选择问卷类型'}
                    onOk={handleOk}
                    okText={'下一步'}
                    onCancel={handleCancel}
                    >
                    <Form
            form={form}
          >

            <Form.Item name='type' label='问卷类型:'
            rules={[
              {
                required: true,
                message: '请选择问卷类型',
              }
            ]}
            >
              <Select placeholder='请选择问卷类型'
              onChange={handleChange}
              options={options}
              >

              </Select>
            </Form.Item>
            <Form.Item name='name' label='模版名称:'
            rules={[
              {
                required: true,
                message: '请填写模版名称',
              }
            ]}
            >
              <Input placeholder='请输入模版名称' showCount maxLength={50}/>
            </Form.Item>
          </Form>
                </Modal>
            </div>
        </Fragment>
    );
}

export default Createtemplate;
