import { Link, useNavigate, useParams } from 'react-router-dom';
import { DragOutlined, ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
    Input,
    Layout,
    Space,
    Button,
    Select,
    Table,
    Row,
    Modal,
    Drawer,
    Form,
    Switch,
    TreeSelect,
    Radio,
    Col,
    message,
    Dropdown, Divider
} from 'antd';
import './index.less';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {
    getActivitiesListApi,
    deleteActivitiesApi,
    saveActivitiesApi,
    setStatusActivitiesApi,
    sortActivitiesListApi,
    coursesCatelogsListApi,
    searchProjectsListApi,
    searchConferencesListApi,
    searchSurveysListApi,
    searchCoursesListApi,
    fetchConferenceLive
} from '@services/MedProject/activities';
import config from '@config';

const { Content } = Layout;

let initData = []; // 初始列表数据 - 用于页面筛选搜索
let currSelectCatalogType = {}; // 课程分类 - 搜索选中所属层级（catalogId1、catalogId2、catalogId3）

export default function ModulesActivities() {
    const navigate = useNavigate();
    const params = useParams();
    const projectId = params?.projectId || 0;
    const moduleId = params?.id || 0;

    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();

    const radioType = Form.useWatch('type', addForm);
    const activityType = Form.useWatch('activityType', searchForm);

    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [addProjectData, setAddProjectData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const onClick = ({ key }) => {
        window.open(`${window.location.origin}${key}`);
    };

    const items = [
        {
            label: '医会议',
            key: '/conf/conferences',
        },
        {
            label: '医调研',
            key: '/wj/surveys',
        },
        {
            label: '医评选',
            key: '/selection/projects',
        },
    ];

    const columns = [
        {
            title: '活动名称',
            dataIndex: 'projectName',
            key: 'projectName'
        },
        {
            title: '活动类型',
            dataIndex: 'activityType',
            key: 'activityType',
            width: 80,
            render: (_, record) => (
                getActivityType(record.activityType)
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (_, record) => (
                getActivityStatus(record?.baseVO?.status, record?.activityType)
            ),
        },
        {
            title: '显示/隐藏',
            key: 'hidden',
            width: 90,
            render: (_, record) => (
                <Switch
                    checked={!record.hidden}
                    onChange={() => onHiddenChange(record)}
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                />
            ),
        },
        {
            title: '推荐到主页',
            key: 'recommended',
            width: 90,
            render: (_, record) => (
                <Switch
                    checked={record.recommended}
                    onChange={() => onRecommendedChange(record)}
                    checkedChildren="推荐"
                    unCheckedChildren="不推荐"
                />
            ),
        },
        {
            title: '操作',
            key: 'action',
            width: 150,
            render: (_, record) => (
                <span className="operation-btns">
                    <Button type="link" size="small" onClick={() => viewDetails(record)}>查看详情</Button>
                    <Divider type="vertical" />
                    <Button type="link" size="small" onClick={() => onDelete(record.id)}>移除</Button>
                </span>
            ),
        },
    ];

    const addColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
            align: 'center',
            key: 'id'
        },
        {
            title: '活动名称',
            dataIndex: 'name',
            align: 'center',
            key: 'name'
        }
    ];

    // 显示/隐藏
    const onHiddenChange = async (record) => {
        if (record.recommended === 1 && record.hidden === 0) {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                centered: true,
                content: '当前活动已推荐到主页，设置隐藏后，项目主页上此活动也将进行隐藏。',
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    const res = await setStatusActivitiesApi({ id: record.id, hidden: record.hidden ? 0 : 1 });
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getActivitiesList();
                    }
                }
            });
        } else {
            const res = await setStatusActivitiesApi({ id: record.id, hidden: record.hidden ? 0 : 1 });
            if (res.status_code === 200) {
                message.success(res.message);
                getActivitiesList();
            }
        }
    };


    // 推荐
    const onRecommendedChange = async (record) => {
        if (record.hidden === 1 && record.recommended === 0) {
            return message.error('当前活动已隐藏，不能推荐到首页，可以设置显示后再推荐。');
        }
        const res = await setStatusActivitiesApi({ id: record.id, recommended: record.recommended ? 0 : 1 });
        if (res.status_code === 200) {
            message.success(res.message);
            getActivitiesList();
        }
    };


    // 查看详情
    const viewDetails = (record) => {
        if (record.activityType === 'conference') {
            return window.open(`${window.location.origin}/conf/conferences?projectName=${escape(record.projectName)}`);
        }
        if (record.activityType === 'survey') {
            return window.open(`${window.location.origin}/wj/surveys?projectName=${escape(record.projectName)}`);
        }
        if (record.activityType === 'selection') {
            return window.open(`${window.location.origin}/selection/projects?projectName=${escape(record.projectName)}`);
        } else {
            return window.open(`${record?.baseVO?.jumpUrl}`);
        }
    };

    // 删除活动
    const onDelete = (delId) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: '移除后，该活动在用户端也将下架，请确认是否移除？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await deleteActivitiesApi({ activityId: delId });
                if (res.status_code === 200) {
                    message.success('移除成功');
                    getActivitiesList();
                }
            }
        });
    }

    // 活动类型
    const getActivityType = (type) => {
        switch (type) {
            case 'conference':
                return '会议';
            case 'course':
                return '课程';
            case 'live':
                return '直播';
            case 'conference_live':
                return '直播';
            case 'selection':
                return '评选';
            case 'survey':
                return '问卷';
            default:
                return '';
        }
    };

    // 活动状态
    const getActivityStatus = (type, activityType) => {
        switch (type) {
            case 1:
                return '进行中';
            case 2:
                return activityType === 'survey' ? '已下架' : '已结束';
            case 3:
                return '未开始';
            case 4:
                return '已删除';
            default:
                return '';
        }
    };

    // 新增活动 - 活动类型
    const getAddActivityType = (type) => {
        switch (type) {
            case 1:
                return 'conference';
            case 2:
                return 'survey';
            case 3:
                return 'live';
            case 4:
                return 'course';
            case 5:
                return 'selection';
            case 6:
                return 'conference_live';
            default:
                return '';
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    // 新增项目 - 保存
    const handleOk = async () => {
        if (!selectedRowKeys.length) {
            return message.error('请选择关联活动');
        }

        const type = addForm.getFieldValue('type');
        const params = {
            activityType: getAddActivityType(type),
            itemIds: [...selectedRowKeys],
            moduleId,
            projectId
        };
        const res = await saveActivitiesApi(params);
        if (res.status_code === 200) {
            message.success('新增成功');
            handleCancel();
            getActivitiesList();
        }
    };

    // 新增项目弹框 - 关闭
    const handleCancel = () => {
        setIsModalOpen(false);
        addForm.resetFields();
        setAddProjectData([]);
        setSelectedRowKeys([]);
        setTotal(0);
        currSelectCatalogType = {};
    };

    // 获取课程分类
    const getCoursesCatelogsList = async () => {
        const res = await coursesCatelogsListApi();
        if (res.status_code === 200 && res.data && res.data.length) {
            setCoursesList(res.data);
        }
    };

    // 课程分类选择
    const onTreeSelectChange = (_, node) => {
        const fid = node?.fid;  // 一级分类
        const fid2 = node?.fid2; // 二级分类
        if (!fid && !fid2) {
            currSelectCatalogType = {
                catalogId1: node.id,
                catalogId2: undefined,
                catalogId3: undefined,
            }
        }
        if (fid && !fid2) {
            currSelectCatalogType = {
                catalogId1: fid,
                catalogId2: node.id,
                catalogId3: undefined,
            }
        }
        if (fid && fid2) {
            currSelectCatalogType = {
                catalogId1: fid,
                catalogId2: fid2,
                catalogId3: node.id,
            }
        }
    };

    // 新增活动 - 查询活动类型数据
    const getAddActivitiesList = (values) => {
        // 会议
        if (values.type === 1) {
            searchConferencesListApi({ id: values.id, name: values.name, moduleId, page, pageSize }).then(res => {
                setLoading(false);
                if (res.status_code === 200 && res.data && res.data.list) {
                    setAddProjectData(res.data.list);
                    setTotal(res.data.totalCount);
                }
            })
        }
        // 调研
        if (values.type === 2) {
            searchSurveysListApi({ id: values.id, name: values.name, moduleId, page, pageSize }).then(res => {
                setLoading(false);
                if (res.status_code === 200 && res.data && res.data.list) {
                    console.log(res.data.list)
                    setAddProjectData(res.data.list);
                    setTotal(res.data.totalCount);
                }
            })
        }
        // 直播 || 课程
        if (values.type === 3 || values.type === 4) {
            let params = {
                moduleId,
                isActivity: 1,
                ids: values.ids ? [values.ids] : undefined,
                courseName: values.courseName || undefined,
                islive: values.type === 3 ? 1 : 0,
                page,
                size: pageSize,
                courseStatus: 1,
                ...currSelectCatalogType
            };
            searchCoursesListApi(params).then(res => {
                setLoading(false);
                if (res.status_code === 200 && res.data && res.data.list) {
                    const list = res.data.list.map(item => ({ id: item.id, name: item.courseName, hasData: item.hasData }));
                    setAddProjectData(list);
                    setTotal(res.data.totalCount);
                }
            });
        }
        // 医会议直播
        if (values.type === 6) {
            fetchConferenceLive({
                liveId: values.liveId,
                name: values.name,
                moduleId,
                page,
                size: pageSize,
                moduleId,
                isActivity: 1,
            }).then(res => {
                setLoading(false);
                if (res.status_code === 200 && res.data && res.data.list) {
                    setAddProjectData(res.data.list);
                    setTotal(res.data.totalCount);
                }
            })
        }
        // 评选
        if (values.type === 5) {
            searchProjectsListApi({ id: values.id, name: values.name, moduleId, page, limit: pageSize }).then(res => {
                setLoading(false);
                if (res.status_code === 200 && res.data && res.data.list) {
                    setAddProjectData(res.data.list);
                    setTotal(res.data.totalCount);
                }
            })
        }
    };

    // 添加活动 - 搜索
    const onSearch = () => {
        addForm.validateFields().then((values => {
            setLoading(true);
            getAddActivitiesList(values);
        }));
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (nowSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(nowSelectedRowKeys);
        },
        getCheckboxProps: (record) => ({ disabled: record.hasData }) // 禁用
    };

    // 排序
    const onSortEndHandler = async ({ oldIndex, newIndex }) => {
        const newData = arrayMoveImmutable(data, oldIndex, newIndex);
        const sordIds = newData.map(item => item.id);
        const res = await sortActivitiesListApi([...sordIds]);
        if (res.status_code === 200) {
            message.success('排序成功');
            initData = [...newData];
            setData([...newData]);
        }

    };

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };

    // 活动列表
    const getActivitiesList = async () => {
        setListLoading(true);
        const res = await getActivitiesListApi({ moduleId });
        setListLoading(false);
        if (res.status_code === 200 && res.data) {
            setData(res.data);
            initData = [...res.data];
        }
    };

    // 回车搜索
    const onPressEnter = (e) => {
        const selectActivityType = searchForm.getFieldValue('activityType');
        if (e.target.value && initData && initData.length) {
            let filterData = [];
            if (selectActivityType) {
                filterData = initData.filter(item => item.projectName.includes(e.target.value) && item.activityType === selectActivityType);
            } else {
                filterData = initData.filter(item => item.projectName.includes(e.target.value));
            }
            setData(filterData);
        } else {
            let filterData = [];
            if (selectActivityType) {
                filterData = initData.filter(item => item.activityType === selectActivityType);
            } else {
                filterData = [...initData];
            }
            setData([...filterData]);
        }
    };

    useEffect(() => {
        const values = addForm.getFieldsValue() || {};
        getAddActivitiesList(values);
    }, [page, pageSize]);

    useEffect(() => {
        if (radioType) {
            setPage(1);
            setPageSize(10);
            setSelectedRowKeys([]);
            getAddActivitiesList({type: radioType});
        }
    }, [radioType]);

    // 下拉筛选
    useEffect(() => {
        const inputProjectName = searchForm.getFieldValue('projectName');
        if (activityType && initData && initData.length) {
            let filterData = [];
            if (inputProjectName) {
                filterData = initData.filter(item => item.activityType === activityType && item.projectName.includes(inputProjectName));
            } else {
                filterData = initData.filter(item => item.activityType === activityType);
            }
            setData(filterData);
        } else {
            let filterData = [];
            if (inputProjectName) {
                filterData = initData.filter(item => item.projectName.includes(inputProjectName));
            } else {
                filterData = [...initData];
            }
            setData([...filterData]);
        }
    }, [activityType]);

    useEffect(() => {
        getActivitiesList();
        getCoursesCatelogsList();
    }, []);

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return <DragOutlined />;
    });

    // 拖拽容器
    const SortableModulesContainer = SortableContainer(({ items }) => {
        return <div>
            {!!items?.length && items.map((treeNode, index) => {
                return (
                    <SortableMainElement
                        item={treeNode}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    const SortableMainElement = SortableElement(({ item, key }) => {
        return <div key={key} style={{ position: 'relative', zIndex: 99999999 }}>
            <p className="activities-name-sort">
                <span className="activities-item-name">{item.projectName}</span>
                <span className="activities-item-option"><SortableMainHandle /></span>
            </p>
        </div>
    });

    return (
        <>
            <Content className="main-content-box">
                <div className="main-content-body">

                    <div className="med-project-module-head">
                        <div className="module-title">
                            <h2>全部活动</h2>
                            <span className="tips">可添加平台上已有会议、评选、调研、课程、直播等作为全部活动，<em>项目首页最多可推荐5条数据</em></span>
                        </div>
                        <div className="preview">
                            <Button
                                type="link"
                                size="small"
                                ghost
                                style={{ padding: 0 }}
                                onClick={() => window.open(`${config.PROJECT_DOMAIN}/p/${projectId}/activities/${moduleId}`)}
                                icon={<>
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                        </svg>
                                    </span>
                                </>}
                            >
                                <span>预览</span>
                            </Button>
                        </div>
                    </div>

                    <div className="med-project-module-toolbar">
                        <div className="main-tools">
                            <Form form={searchForm}>
                                <Row>
                                    <Col style={{ marginRight: '10px' }}>
                                        <Form.Item
                                            name="projectName"
                                            label=""
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input placeholder="请输入活动名称" onPressEnter={onPressEnter} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label=""
                                            name="activityType"
                                            style={{ width: 120, marginBottom: 0 }}
                                        >
                                            <Select placeholder="活动类型" allowClear>
                                                <Select.Option value={'conference'}>会议</Select.Option>
                                                <Select.Option value={'course'}>课程</Select.Option>
                                                <Select.Option value={'live'}>直播</Select.Option>
                                                <Select.Option value={'conference_live'}>医会议直播</Select.Option>
                                                <Select.Option value={'selection'}>评选</Select.Option>
                                                <Select.Option value={'survey'}>问卷</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="sub-tools">
                            <Space>
                                <Button onClick={() => setOpen(true)}>排序</Button>
                                <Button type="primary" onClick={() => setIsModalOpen(true)}>添加活动</Button>
                                <Dropdown
                                    menu={{ items, onClick }}
                                >
                                    <div className="addDropDown" onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <Button style={{ padding: '5px' }} type="primary" onClick={() => setIsModalOpen(true)}><DownOutlined /></Button>
                                        </Space>
                                    </div>
                                </Dropdown>
                            </Space>
                        </div>
                    </div>

                    <div className="med-project-module-content">
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            loading={listLoading}
                        />
                    </div>

                </div>
            </Content>

            <Drawer
                title="活动排序"
                onClose={onClose}
                open={open}
                maskClosable={false}
                width={600}
                footer={null}
            >
                <div>
                    <SortableModulesContainer
                        useWindowAsScrollContainer={true}
                        useDragHandle
                        distance={10}
                        items={data}
                        onSortEnd={onSortEndHandler}
                    />
                </div>
            </Drawer>

            <Modal
                title="添加项目活动"
                open={isModalOpen}
                className="add-activities-modal"
                centered
                maskClosable={false}
                width={900}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    form={addForm}
                    name="basic"
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    initialValues={{ type: 1 }}
                >
                    <Form.Item
                        label="活动类型"
                        name="type"
                        rules={[{ required: true, message: '活动类型不能为空' }]}
                    >
                        <Radio.Group>
                            <Radio value={1}>会议</Radio>
                            <Radio value={2}>调研/招募</Radio>
                            <Radio value={3}>直播</Radio>
                            <Radio value={6}>医会议直播</Radio>
                            <Radio value={4}>课程</Radio>
                            <Radio value={5}>评选</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="选择关联活动"
                        name=""
                        className="relate-activities-form"
                    >
                        <Row>
                            {(radioType === 1 || radioType === 2 || radioType === 5) &&
                                <>
                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="id">
                                            <Input placeholder="项目编号" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="name">
                                            <Input placeholder="项目名称" />
                                        </Form.Item>
                                    </Col>
                                </>
                            }

                            {(radioType === 3 || radioType === 4) &&
                                <>
                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="ids">
                                            <Input placeholder="ID" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="courseName">
                                            <Input placeholder="课程名称" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="catalogId">
                                            <TreeSelect
                                                allowClear
                                                onChange={(e) => {
                                                    if (!e) {
                                                        currSelectCatalogType = {}
                                                    }
                                                }}
                                                style={{ width: '100%' }}
                                                placeholder="课程分类"
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                treeData={coursesList}
                                                fieldNames={{
                                                    label: 'name',
                                                    value: 'id',
                                                    children: 'sonCatalogList',
                                                }}
                                                treeDefaultExpandAll
                                                onSelect={onTreeSelectChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            }

                            {(radioType === 6) &&
                                <>
                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="liveId">
                                            <Input placeholder="直播ID" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ marginRight: 10 }}>
                                        <Form.Item label="" name="name">
                                            <Input placeholder="直播名称关键字" />
                                        </Form.Item>
                                    </Col>
                                </>
                            }

                            <Col span={2} style={{ marginRight: 10 }}>
                                <Button type="primary" onClick={onSearch}>搜索</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>

                <Table
                    rowKey={record => record.id}
                    rowSelection={{ ...rowSelection }}
                    columns={addColumns}
                    dataSource={addProjectData}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                    loading={loading}
                    size="small"
                    scroll={{
                        y: 240,
                    }}
                />
            </Modal>

        </>
    );
}
