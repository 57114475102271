import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Input, Button, Space, Drawer, Descriptions } from 'antd';
import { drawerWidth } from '@utils/property';
import { convertDateTimeToLocalText } from '@utils/utils';

const { TextArea } = Input;

function OperationLogInfo({ }, ref) {

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState(null);

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(row) {
            setRowData(row);
            setVisible(true);
        }
    }));

    const handleClose = () => {
        setVisible(false);
    };

    const handleOk = () => {
        setVisible(false);
    };

    return (
        <Fragment>
            {
                visible && <Drawer
                    title={<span style={{ fontWeight: 600 }}>操作详情</span>}
                    closable={false}
                    width={drawerWidth}
                    onClose={handleClose}
                    visible={visible}
                    footer={<Space className="operation-info-footer">
                        <Button type="default" onClick={handleClose}>取消</Button>
                        <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>确定</Button>
                    </Space>}
                >
                    <div className="main-content-body">
                        {
                            rowData && <Descriptions className="operation-info-content" column={1} >
                                <Descriptions.Item label="记录ID">{rowData.id}</Descriptions.Item>
                                <Descriptions.Item label="请求路由">{rowData.route}</Descriptions.Item>
                                <Descriptions.Item label="请求参数"><TextArea rows={6} disabled value={rowData.params}/></Descriptions.Item>
                                <Descriptions.Item label="IP地址">{rowData.ip}</Descriptions.Item>
                                <Descriptions.Item label="操作用户">{rowData.staffName}</Descriptions.Item>
                                <Descriptions.Item label="操作时间">{convertDateTimeToLocalText(rowData.createdAt)}</Descriptions.Item>
                            </Descriptions>
                        }

                    </div>
                </Drawer>
            }


        </Fragment>
    );
}

export default forwardRef(OperationLogInfo)

