import { get, put, post, patch, delet, del, delte } from '@core/request';

export * from './sign';

export const fileApi = (params, option) => {
    return post('/conference/file', params, option);
};

//会议列表
export const conferenceSaveApi = (params) => {
    return post('/conference/boss/conferences/save', params);

}

export const conferenceListApi = (params) => {
    return post('/conference/boss/conferences/list', params);

}
// 更新会议的聚合列表状态、健康界平台状态
// conferenceId
// hidden 是否隐藏：0不隐藏，1隐藏
// healthcarePlatformHidden 健康界平台是否隐藏：0显示，1隐藏
export const conferencetHiddenStateApi = (params) => {
    return patch('/conference/boss/conferences/setHiddenState', params);

}

export const conferenceMarkAsTesting = data => put('/conference/boss/conferences/markAsTesting', data);

export const conferenceDelApi = (params) => {
    return get('/conference/boss/conferences/delete', params);
};


export const conferenceOnceApi = (params) => {
    return get('/conference/boss/conferences/getOneById', params);
};


export const conferenceUpdateApi = (params) => {
    return put('/conference/boss/conferences/update', params);
};

//直播列表
export const getConferenceLivesListApi = (params) => {
    return get('/conference/boss/lives/list', params)
}

export const getLiveOneByIdApi = (params) => {
    return get('/conference/boss/lives/getOneById', params)
}

export const getConferenceLiveCommentsListApi = (params) => {
    return get('/conference/boss/livecomments/list', params)
}
export const deleteConferenceLiveCommentIdApi = (params) => {
    return get('/conference/boss/livecomments/delete', params)
}

export const saveLiveCommentApi = (params) => {
    return post('/conference/boss/livecomments/save', params)
}

export const liveConferencehallsApi = (params) => {
    return get('/conference/boss/conferencehalls/firstList', params)
}

export const saveLiveApi = (params) => {
    return post('/conference/boss/lives/save', params)
}

export const getLiveInfoApi = (params) => {
    return get('/conference/boss/lives/getOneById', params)
}

export const delLiveOnceApi = (params) => {
    return get('/conference/boss/lives/delete', params)
}

export const editLiveApi = (params) => {
    return put('/conference/boss/lives/update', params)
}

export const liveSettingApi = (params) => {
    return put('/conference/boss/lives/changeType', params)
}

//白名单
export const addWhitListsApi = (params) => {
    return post('/conference/boss/livewhitelist/save', params)
}

export const getWhitListsApi = (params) => {
    return get('/conference/boss/livewhitelist/list', params)
}

export const delWhitListsApi = (params) => {
    return post('/conference/boss/livewhitelist/delete', params)
}

//推拉流地址
export const getPullUrlApi = (params) => {
    return get('/conference/boss/liveReplays/getPullUrl', params)
}

//视频上传
//获取阿里云上传凭证
export const getAliyuncred = (data) => {
    return post('/conference/boss/liveReplays/upload-info', data, { timeout: 600000 });
}

//阿里云OSS刷新凭证
export const refreshAliyuncred = (data) => {
    return get('/conference/boss/liveReplays/re-upload-info', data);
}

export const upLoadVideoApi = (data) => {
    return post('/conference/boss/liveReplays/saveLiveReplay', data, { timeout: 600000 });
}


//回放直播列表
export const liveReplaysListApi = (data) => {
    return get('/conference/boss/liveReplays/list', data);
}

//删除直播回放
export const delVideoApi = (data) => {
    return post('/conference/boss/liveReplays/deleteLive', data, { timeout: 600000 });
}

export const fetchReplayVideoList = params => get('/conference/boss/liveReplays/replays-mp4', params);

//设为默认
export const defaultVideoApi = (data) => {
    return post('/conference/boss/liveReplays/defaultPlay', data, { timeout: 600000 });
}

// 医会议数据看板
export const listConferenceUsage = params => get('/conference/boss/conf-usage/list-data-total', params);

// 医会议数据看板数据导出
export const exportConferenceUsage = params => get('/conference/boss/conf-usage/list-data-total/download', params, { responseType: 'blob' });

//获取上传凭证
export const getVideoUrlApi = (data) => {
    return get('/conference/boss/liveReplays/getVideoUrl', data);
}

//获取上传凭证
export const updateLiveStartTimeApi = (data) => {
    return put('/conference/boss/liveReplays/updateLiveStartTime', data);
}

/**
 * @description: 获取随机评论数量
 * @return {Promise<any>}
 */
export const getFakeCommentCount = () => get('/conference/user/lives/getFakeCommentCount')

/**
 * @description: 发送随机评论
 * @return {Promise<any>}
 */
export const sendFakeComments = (params) => post('/conference/user/lives/sendFakeComments', params)

// 修改嘉宾身份
export const batchUpdateRoleApi = (data) => {
    return post('/conference/boss/guests/batchUpdateRole', data);
}
// 批量修改嘉宾身份
export const batchUpdateHiddenApi = (data) => {
    return put('/conference/boss/guests/batchUpdateHidden', data);
}

/**
 * 删除会议相关缓存
 * @param data
 * @returns {boolean}
 *
 * {type: '', key: ''}
 *
 * type 缓存类型
 * key 缓存键值
 *
 * type: conference, agendas, live, guests 限制在这个范围，不是这些取值时， 400 缓存类型不存在，清除缓存失败！
 *
 * key:
 * conference_<conferenceId>_site_modules
 * conference_<conferenceId>_user_agendas
 * conference_<conferenceId>_user_live_<liveId>
 * conference_<conferenceId>_user_guests
 */
export const deleteConferenceCache = data => delet('/conference/boss/conferences/cache', data);


// 更新嘉宾显示类型
export const updateGuestsShowTypeApi = data => put('/conference/boss/conferences/update-guests-show-type', data);



// 获取赞助企业列表 
export const sponsorList = params => get('/conference/boss/sponsor/list', params);
// 根据ID获取赞助企业信息
export const getSponsor = params => get(`/conference/boss/sponsor/info/${params}`);
// 增加赞助企业
export const saveSponsor = params => post('/conference/boss/sponsor/save', params);
// 更新赞助企业
export const updateSponsor = params => put('/conference/boss/sponsor/update', params);
// 删除赞助企业
export const delSponsor = params => {
    return post('/conference/boss/sponsor/delete', params);
}

// 排序赞助企业
export const sortSponsor = params => get('/conference/boss/sponsor/sort', params);
    
// 根据大会ID查询二级子会议
export const hallList = params => get('/conference/boss/conferencehalls/getHallList', params);
// 根据大会ID查询会议详情
export const hallsDetails = params => get('/conference/boss/conferencehalls/getOneById', params);


// 获取上传数据的参数
export const conferenceOssData = params => get('/dataengine/api/oss/conference/uploadSignature');

// 劳务审批流程
export const laborAuditFlowsApi = params => get('/conference/boss/conferences/auditFlows', params);

// 预约数据
export const liveReservationsApi = params => get('/conference/livereservations/page', params);

// 预约数据导出
export const liveReservationsExportApi = params => get('/conference/livereservations/download', params, {responseType: "blob"});

// 报名数据
export const liveSignUpApi = params => get('/conference/activityregistragtionusers/page', params);

// 预约数据导出
export const liveSignUpExportApi = params => get('/conference/activityregistragtionusers/download', params, {responseType: "blob"});

// 预约数据 - 题目配置列表
export const getLiveQuestionListApi = params => get('/conference/activityregistragtionusers/listByLiveId', params);

// 项目劳务进度
export const laborProgressApi = params => get('/conference/boss/conferences/labor-progress', params);
