import React, { Fragment, useState, useEffect, useRef } from "react";
import {Button,Space,Table,Typography,Drawer} from "antd";
const { Text } = Typography;

const AddJudgeList = ({
    closeAddJudgeListDrawer,
    visibleAddJudgeListDrawer,
    handleAddJudgesList,
    judgeData,
    btnLoading,
}) => {

    const columns = [
        {
            title: '评委姓名',
            dataIndex: 'username',
            width: 150,
        },
        {
            title: "手机号",
            name: "mobile",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    {record.mobile ? <Text>{record.mobile}</Text> : <Text type="secondary">&ndash;</Text>}
                </Fragment>
            ),
        },
        {
            title: "第1意向主题",
            name: "type1",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    {record.type1 ? <Text>{record.type1}</Text> : <Text type="secondary">&ndash;</Text>}
                </Fragment>
            ),
        },
        {
            title: "第2意向主题",
            name: "type2",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    {record.type2 ? <Text>{record.type2}</Text> : <Text type="secondary">&ndash;</Text>}
                </Fragment>
            ),
        },
        {
            title: "第3意向主题",
            name: "type3",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    {record.type3 ? <Text>{record.type3}</Text> : <Text type="secondary">&ndash;</Text>}
                </Fragment>
            ),
        },

        {
            title: '提示',
            dataIndex: 'prompt',
            width: 100,
            render: (_, record) =>
                <Fragment>
                    {record.prompt==='专家库未找到' ? <Text type="danger">{record.prompt}</Text> : <Text>{record.prompt}</Text>}
                </Fragment>
        },
    ]

    return (
        <Fragment>
            <Drawer
                title="导入评委数据确认"
                placement="bottom"
                height="100%"
                destroyOnClose
                open={visibleAddJudgeListDrawer}
                onClose={closeAddJudgeListDrawer}
                bodyStyle={{ paddingTop: 0 }}
                footer={
                    <div
                        className="custom-drawer-footer"
                        style={{ textAlign: "right" }}
                    >
                        <Space>
                            <Button onClick={closeAddJudgeListDrawer}>取消</Button>
                            <Button type="primary" loading={btnLoading} onClick={handleAddJudgesList}>
                                开始导入
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Space size='middle' style={{ float: 'right', margin: '12px' }}>
                    <Text>共读取到：{judgeData?.total}条</Text>
                    <Text >专家库未找到：{judgeData?.total-judgeData?.download}条</Text>
                    <Text >可导入：{judgeData?.download}条</Text>
                </Space>

                <Table
                    columns={columns}
                    dataSource={judgeData?.list}
                    rowKey={(_,index) => index}
                    pagination={false}
                />
            </Drawer>
        </Fragment>
    );
};

export default AddJudgeList;
