import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Layout, Form, Row, Col, Button, Space, Table, TreeSelect, Radio, DatePicker, message } from 'antd';
import { filterFormItemLayout, filterFormGridLayout, } from '@utils/property';
import { useNavigate } from 'react-router-dom';
import { timeString } from '@/utils/utils';
import "./View.less";
import { fullPagerConfig } from '@/utils/property';
import moment from 'moment';
import { get } from '@/core/request';
const { Content } = Layout;
export default function BiChannelUsers() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    const [form1] = Form.useForm();
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    let [num, setNum] = useState(0);
    const [channelType, setChannelType] = useState("");
    const [treeData, setTreeData] = useState(null);
    const [startTime, setStartTime] = useState(" ");
    const [endTime, setEndTime] = useState("");
    const [dataSource, setDataSource] = useState(null);
    let [radioID, setRadioID] = useState("1");
    const [yesterday, setYesterday] = useState("");
    const [otherDay7, setOtherDay7] = useState("");
    const [otherDay30, setOtherDay30] = useState("");
    function getBeforeDate(days) {
        var now = new Date().getTime();
        var ago = now - 86400000 * days;//一天的毫秒数为86400000
        var agoData = new Date(ago);
        var year = agoData.getFullYear();
        var mon = agoData.getMonth() + 1;
        var day = agoData.getDate();
        mon = mon < 10 ? '0' + mon : mon;
        day = day < 10 ? '0' + day : day;
        var date = year + '-' + mon + '-' + day;
        return date;
    };
    useEffect(() => {
        //   今天
        let day2 = new Date();
        day2.setTime(day2.getTime());
        let s2 = day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate();
        setYesterday(getBeforeDate(0))
        setOtherDay7(getBeforeDate(7))
        setOtherDay30(getBeforeDate(30))
        //昨天的时间
        let s1 = getBeforeDate(1)
        setStartTime(s1 + " 00:00:00")
        setEndTime(s1 + " 23:59:59")
        setNum(++num)
    }, [])
    const onChangeTreeSelect = value => {
        setChannelType(value || "")
    };
    const onReset = () => {
        setStartTime(getBeforeDate(1) + " 00:00:00")
        setEndTime(getBeforeDate(1) + " 23:59:59")
        setRadioID("1")
        setChannelType("")
        form1.resetFields();
        setPage(1)
        setNum(++num)
    };
    const columns = [
        { title: '渠道ID', dataIndex: 'channelId', key: 'channelId' },
        {
            title: '渠道名称', dataIndex: 'title',
            render: (_, record) =>
            (
                <div className='channelBox'>
                    <p className='channelBoxTitle'>{record.title}</p>
                    <p style={{ color: "#666" }}>{record.channelTypeName1} / {record.channelTypeName2} / {record.channelTypeName3}</p>
                </div>
            ),
        },
        {
            title: 'PV', dataIndex: 'pv',
        },
        {
            title: 'UV', dataIndex: 'uv',
        },
    ];
    const onFinish = (value) => {
        setNum(++num)
        console.log(value)
    }
    function deepClone(result) {
        if (result && result.length) {
            result.forEach(item => {
                item.title = item.name
                item.value = item.id
                item.key = item.id
                if (item.children.length) {
                    deepClone(item.children)
                }
            })
            return result
        }
    }
    function getDaysBetween(dateString1, dateString2) {
        var startDate = Date.parse(dateString1)
        var endDate = Date.parse(dateString2)
        console.log(dateString1, dateString2)
        var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
        return days
    }
    const handleChangeTime = (value, dateString) => {
        setRadioID(5)
        let day = getDaysBetween(dateString[0], dateString[1]);
        if (day >= 90) {
            message.info("时间区间不得超过90天")
        } else {
            setStartTime(dateString[0])
            setEndTime(dateString[1])
        }
    }
    const handleChangeDate = (value, key) => {
        setRadioID(value.target.value)
        if (value.target.value == 1) {
            console.log("昨天")
            let s1 = getBeforeDate(1)
            setStartTime(s1 + " 00:00:00")
            setEndTime(s1 + " 23:59:59")
        } else if (value.target.value == 2) {
            setStartTime(otherDay7 + " 00:00:00")
            setEndTime(yesterday + " 23:59:59")
        } else {
            setStartTime(otherDay30 + " 00:00:00")
            setEndTime(yesterday + " 23:59:59")
        }
        console.log(startTime, endTime)
    }
    useEffect(() => {
        get('/channel/channel-group/listTree').then((res) => {
            const result = deepClone(res.data);
            setTreeData(result)
        }, (err) => {
            console.log(err)
        })
    }, [])
    useEffect(() => {
        if (startTime && endTime) {
            get(`/channel/statistics/pvUv?channelType=${channelType}&limit=${limit}&page=${page}&startTime=${startTime}&endTime=${endTime}`).then((res) => {
                if (res.status_code == 200) {
                    setDataSource(res.data)
                }
            })
        }
    }, [limit, page, num])
    const changeCurrPage = useCallback(
        (page) => {
            setPage(page);
        },
        [],
    );
    const changePageSize = useCallback(
        (pageSize) => {
            setLimit(pageSize);
        },
        [],
    );
    const paginationProps = {
        ...fullPagerConfig,
        current: page,
        // showTotal: () => `共${dataSource.totalCount}条记录`,
        // showSizeChanger: true,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100', '1000'],
        size: 'small',
        total: dataSource && dataSource.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) }
    };
    const exportFun = () => {
        get(`/channel/statistics/pvUv-download`, {
            channelType,
            endTime: endTime,
            startTime: startTime,
            page,
            limit,
            export: 1
        }, { responseType: 'blob' }).then((res) => {
            console.log(res)
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                let date = new Date();
                let dateString = timeString(date);
                const fileName = `渠道浏览数据${dateString}.xlsx`;
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    }
    const disabledDate = (current) => {
        // return current > moment().endOf('day');
    }
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title">渠道浏览数据</div>
                <div className="main-content-body">
                    <Form onFinish={onFinish} form={form1}>
                        <Row>
                            <Col>
                                <Form.Item label="统计时间">
                                    <div className='main-content-time'>
                                        <Radio.Group onChange={handleChangeDate} value={radioID}>
                                            <Radio.Button value="1">昨日</Radio.Button>
                                            <Radio.Button value="2">最近7天</Radio.Button>
                                            <Radio.Button value="3">最近30天</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <div className='main-content-time2'>
                                        {<RangePicker
                                            showTime
                                            format={dateFormat}
                                            onChange={handleChangeTime}
                                            allowClear={false}
                                            disabledDate={disabledDate}
                                            value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                                        />}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Form.Item label="渠道分类" name="channelType">
                                    {treeData && <TreeSelect
                                        allowClear = {true}
                                        style={{ width: 400 }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={treeData}
                                        placeholder="选择渠道分类"
                                        treeDefaultExpandAll={true}
                                        onChange={onChangeTreeSelect}
                                    />}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Space>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                    <div className="table-caption">
                        <div className="table-title">渠道列表</div>
                        <div className="table-toolbar">
                            <Space>
                                <Button type="default" icon={<DownloadOutlined />} onClick={exportFun}>导出</Button>
                            </Space>
                        </div>
                    </div>
                    {dataSource && <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataSource.list}
                        pagination={paginationProps}
                    />}
                </div>
            </Content>
        </Fragment>
    )

}
