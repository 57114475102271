import React, {Fragment, useCallback, useEffect, useState, useRef} from 'react';
import {Button, Layout, Table, Tabs, message, Modal, Descriptions, Form, Input, Image} from 'antd';
// import UserStatisticsFilterForm from './UserStatisticsFilterForm';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import {fullPagerConfig} from '@/utils/property';
import {get, put, post} from '@/core/request';
import './KbResearchBasicInfo.less'
import {Content} from 'antd/lib/layout/layout';
//医院类别
const orgLevelList = [
    '综合医院',
    '专科医院',
    '中医医院',
    '诊所',
    '中西医结合医院',
    '民族医医院',
    '康复医院',
    '社区卫生服务中心（站）',
    '卫生院',
    '疗养院',
    '门诊部',
    '村卫生室（所）',
    '急救中心（站）',
    '独立新设医疗机构（10类）',
    '专科疾病防治院/所/站',
    '护理院（站）',
    '其他',
    '妇幼儿保健院/妇幼保健计划生育服务中心',
]

export default function KbResearchBasicInfo() {

    const auditstatus = ['待审核', '审核通过', '审核不通过']
    // 审核状态
    const {state, pathname} = useLocation();
    let navigate = useNavigate();
    // 基础信息数据
    const [basicData, setBasicData] = useState({});
    const [orgLevelData, setOrgLevelData] = useState();
    const [typeData, setTypeData] = useState();
    const [backData, setBackData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isImage, setImage] = useState(true)
    const inputRef = useRef(null)

    const showModal = (type) => {
        setTypeData(type)
        setIsModalVisible(true);
    };
    const changePath = () => {
        navigate(`/kb/rearch`)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    //基础信息数据
    const getData = () => {
        get(`/kb/questionsRecords/basic-information`, {
            id: state.recordId,
            year: state.year
        }).then((res) => {
            setBasicData(res.data)
        });
    };
    //退回按钮数据
    const getBackData = () => {
        get(`/kb/checkInformations/show-back-btn`, {
            memCard: state.memCard,
            checkId: state.id
        }).then((res) => {
            setBackData(res.data)
        });
    };
    useEffect(() => {
        getData()
        // getBackData()
    }, [state])
    // 医院类别
    useEffect(() => {
        if (basicData.orgInfo) {
            setOrgLevelData(basicData.orgInfo.orgLevel)
        }
    }, [basicData])

    const changeAuditstatus = (data, year, type) => {
        Modal.confirm({
            title: '提示',
            content: '是否审核通过',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (year === '2022') {
                    switch (type) {
                        case 'org':
                            // console.log(data, url, 'asdasdas');
                            put('/kb/checkOrgs/check', {
                                id: data,
                                checkStatus: 1,
                            }).then(res => {
                                // console.log(res);
                                if (res.status_code === 200) {
                                    message.success(res.message);
                                    getData();
                                    // getBackData()

                                }
                            })
                        case 'signMemberInfos':
                            // console.log(data, url, 'asdasdas');
                            put('/kb/signMemberInfos/check', {
                                id: data,
                                checkStatus: 1,
                            }).then(res => {
                                // console.log(res);
                                if (res.status_code === 200) {
                                    message.success(res.message);
                                    getData();
                                    // getBackData()

                                }
                            })
                        case 'checkInformations':
                            // console.log(data, url, 'asdasdas');
                            put('/kb/signMemberInfos/check', {
                                id: data,
                                checkStatus: 1,
                            }).then(res => {
                                // console.log(res);
                                if (res.status_code === 200) {
                                    message.success(res.message);
                                    getData();
                                    // getBackData()
                                }
                            })
                        case 'checkDisciplineInformations':
                            // console.log(data, url, 'asdasdas');
                            put('/kb/signMemberInfos/check', {
                                id: data,
                                checkStatus: 1,
                            }).then(res => {
                                // console.log(res);
                                if (res.status_code === 200) {
                                    message.success(res.message);
                                    getData();
                                    // getBackData()
                                }
                            })
                    }
                }else {
                    if (type==='signMemberInfos'){
                        put('kb/research/declarations/check-base-info', {
                            id: data,
                            status: 3,
                            type:4
                        }).then(res => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                                // getBackData()
                            }
                        })
                    }else if (type==='checkInformations'){
                        put('kb/research/declarations/check-base-info', {
                            id: data,
                            status: 3,
                            type:3
                        }).then(res => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                                // getBackData()
                            }
                        })
                    }else if (type==='checkDisciplineInformations'){
                        put('kb/research/declarations/check-base-info', {
                            id: data,
                            status: 3,
                            type:2
                        }).then(res => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                                // getBackData()
                            }
                        })
                    }
                }
            }
        });
    };
    const info = () => {
        message.info('原因不能为空');
    };
    //modal提示框
    const handleOk = () => {
        if (inputRef.current.input.value) {
            if (state.year==='2022'){
                switch (typeData) {
                    case 'org':
                        put('/kb/checkOrgs/check', {
                            id: basicData.orgInfo.id,
                            checkStatus: 2,
                            reason: inputRef.current.input.value
                        }).then(res => {
                            // console.log(res);
                            if (res.status_code === 200) {
                                message.success(res.message);
                                // getBackData()
                                getData();
                            }
                        })
                        setIsModalVisible(false);
                        break;
                    case 'signMemberInfo':
                        put('/kb/signMemberInfos/check', {
                            id: basicData.signMemberInfo.id,
                            checkStatus: 2,
                            reason: inputRef.current.input.value
                        }).then(res => {
                            // console.log(res);
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                                // getBackData()
                            }
                        })
                        setIsModalVisible(false);
                        break;
                    case 'information':
                        put('/kb/checkInformations/check', {
                            id: basicData.information.id,
                            checkStatus: 2,
                            reason: inputRef.current.input.value
                        }).then(res => {
                            // console.log(res);
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                                // getBackData()
                            }
                        })
                        setIsModalVisible(false);
                        break;
                    case 'subjectName':
                        put('/kb/checkInformations/check', {
                            id: basicData.information.id,
                            checkStatus: 2,
                            reason: inputRef.current.input.value
                        }).then(res => {
                            // console.log(res);
                            if (res.status_code === 200) {
                                message.success(res.message);
                                getData();
                                // getBackData()
                            }
                        })
                        setIsModalVisible(false);
                        break;

                    default:
                        break;
                }
            }else {
                if (typeData === 'signMemberInfo') {
                    put('/kb/research/declarations/check-base-info', {
                        id: state.id,
                        status: 4,
                        type: 4,
                        rejectReason: inputRef.current.input.value
                    }).then(res => {
                        // console.log(res);
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                            // getBackData()
                        }
                    })
                    setIsModalVisible(false);
                } else if (typeData === 'information') {
                    put('/kb/research/declarations/check-base-info', {
                        id: state.id,
                        status: 4,
                        type: 3,
                        rejectReason: inputRef.current.input.value
                    }).then(res => {
                        // console.log(res);
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                            // getBackData()
                        }
                    })
                    setIsModalVisible(false);
                } else if (typeData === 'subjectName') {
                    put('/kb/research/declarations/check-base-info', {
                        id: state.id,
                        status: 4,
                        type: 2,
                        rejectReason: inputRef.current.input.value
                    }).then(res => {
                        // console.log(res);
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData();
                            // getBackData()
                        }
                    })
                    setIsModalVisible(false);
                }
            }
        } else {
            info()
        }
        // console.log(typeData, inputRef.current.input.value);
    };

    //退回按钮
    const handBack = () => {
        Modal.confirm({
            title: '提示',
            content: '是否退回让用户修改',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                // console.log(data, url, 'asdasdas');
                post(`/kb/checkInformations/send-message`, {
                    checkId: state.id,
                    type: 1,
                    phone: state.phone
                }).then(res => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getData();
                        // getBackData()
                    }
                })
            }
        });
    }
    //图片加载失败时展示
    const checkIsImage = () => {
        setImage(false)
    }


    return (
        <div className='basic-wrap'>
            <div className="basic-page-title">
                <Link to="/kb/rearch" className="back" onClick={changePath}><LeftOutlined/></Link>基础信息审核
            </div>
            <div className='basic-content'>
                <Fragment>
                    <div className='header-title'>
                        <Descriptions column={1} labelStyle={{fontWeight: "bold", fontSize: 18}}
                                      contentStyle={{fontWeight: "bold", fontSize: 18}}>
                            {state.theme && <Descriptions.Item label="申报主题">{state.theme}</Descriptions.Item>}
                            {state.orgName && <Descriptions.Item label="申报医院">{state.orgName}</Descriptions.Item>}
                            {state.subjectName &&
                            <Descriptions.Item label="申报学科">{state.subjectName}</Descriptions.Item>}
                            {state.personName && <Descriptions.Item label="申报人">{state.personName}</Descriptions.Item>}
                        </Descriptions>
                        {/*{backData.backStatus !== 1 && backData.status === 2 && <Button type='danger' onClick={handBack}> 退回</Button>}*/}
                    </div>
                    <Content className='basic-content'>
                        {basicData.orgInfo && <div className='basic-content-div'>
                            <Descriptions column={1} title='机构信息'>
                                {state.year==='2023' && <Descriptions.Item label="机构ID">{basicData.orgInfo.id}</Descriptions.Item>}
                                <Descriptions.Item label="审核状态">{basicData.orgInfo.checkStatus === 1 ?
                                    <span className="operate-style">审核通过</span>
                                    : basicData.orgInfo.checkStatus === 2 ? basicData.orgInfo.checkStatus === 2 &&
                                        <span className='delete-style'>审核不通过</span> :
                                        <span className="operate-style">待审核</span>}</Descriptions.Item>
                                {basicData.orgInfo.reason && basicData.orgInfo.checkStatus === 2 &&
                                <Descriptions.Item label="不通过理由"><span
                                    className='delete-style'>{basicData.orgInfo.reason}</span></Descriptions.Item>}
                                <Descriptions.Item label="医疗机构名称">{basicData.orgInfo.orgName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="医疗机构级别">{state.year==='2022'?basicData.orgInfo.orgCategoryName:basicData.orgInfo.orgLevelName}</Descriptions.Item>
                                <Descriptions.Item label="医疗机构类别">{state.year==='2022'?orgLevelList[orgLevelData]:basicData.orgInfo.orgCategoryName}</Descriptions.Item>
                                <Descriptions.Item label="医疗机构性质">{state.year==='2022'?basicData.orgInfo.orgNature:basicData.orgInfo.orgNatureName}</Descriptions.Item>
                                {state.year==='2022'?<Descriptions.Item label="医疗机构执业许可证">
                                    {isImage ? <Image src={basicData.orgInfo.orgFileUrl} width={150} alt={'pic'} onError={checkIsImage}></Image> : null}
                                </Descriptions.Item>:''}
                            </Descriptions>
                            {basicData.orgInfo.checkStatus === 0 && state.year==='2022' && <Button type='primary' onClick={() => {changeAuditstatus(state.id, state.year, 'org')}}>审核通过</Button>}
                            {basicData.orgInfo.checkStatus === 0 && state.year==='2022' && <><Button type='danger' onClick={() => {showModal('org')}}>审核不通过</Button></>}
                        </div>}

                        {basicData.signMemberInfo && <div className='basic-content-div'>
                            <Descriptions column={1} title='联系人信息'>
                                <Descriptions.Item label="审核状态">{basicData.signMemberInfo.checkStatus === 1 ? <span className="operate-style">审核通过</span> : basicData.signMemberInfo.checkStatus === 2 ? <span className='delete-style'>审核不通过</span> : <span className="operate-style">待审核</span>}</Descriptions.Item>
                                {basicData.signMemberInfo.reason && basicData.signMemberInfo.checkStatus === 2 &&
                                <Descriptions.Item label="不通过理由"><span
                                    className='delete-style'>{basicData.signMemberInfo.reason}</span></Descriptions.Item>}
                                <Descriptions.Item label="联系人姓名">{basicData.signMemberInfo.userName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="联系人科室">{basicData.signMemberInfo.userDepartmentName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="联系人职称">{basicData.signMemberInfo.userDutyName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="联系人职务">{basicData.signMemberInfo.userHtitleName}</Descriptions.Item>
                                <Descriptions.Item label="联系人认证资料">

                                    <Image src={basicData.signMemberInfo.fileUrl} width={150} alt={'pic'}></Image>

                                </Descriptions.Item>
                            </Descriptions>
                            {basicData.signMemberInfo.checkStatus === 0 && <Button type='primary' onClick={() => {changeAuditstatus(state.id, state.year, 'signMemberInfos')}}> 审核通过</Button>}
                            {basicData.signMemberInfo.checkStatus === 0 && <><Button type='danger' onClick={() => {showModal('signMemberInfo')}}>审核不通过</Button></>}
                        </div>}

                        {basicData.personName && <div className='basic-content-div'>
                            <Descriptions column={1} title='申报人信息'>
                                <Descriptions.Item label="审核状态">{basicData.information.checkStatus === 1 ?
                                    <span className="operate-style">审核通过</span>
                                    : basicData.information.checkStatus === 2 ?
                                        <span className='delete-style'>审核不通过</span> :
                                        <span className="operate-style">待审核</span>}</Descriptions.Item>
                                {basicData.information.reason && basicData.information.checkStatus === 2 &&
                                <Descriptions.Item label="不通过理由"><span
                                    className='delete-style'>{basicData.information.reason}</span></Descriptions.Item>}
                                <Descriptions.Item label="申报人姓名">{basicData.information.personName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="申报人职务">{basicData.information.personHtitleName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="申报人职称">{basicData.information.personDutyName}</Descriptions.Item>
                                <Descriptions.Item
                                    label="申报人所在科室">{basicData.information.personDepartmentName}</Descriptions.Item>
                                <Descriptions.Item label="申报人电话">{basicData.information.personPhone}</Descriptions.Item>
                                <Descriptions.Item label="申报人认证资料">

                                    <Image src={basicData.information.personFileUrl} width={150} alt={'pic'}></Image>

                                </Descriptions.Item>
                            </Descriptions>
                            {basicData.information.checkStatus === 0 && <Button type='primary' onClick={() => {
                                changeAuditstatus(state.id, state.year, 'checkInformations')
                            }}> 审核通过</Button>}
                            {basicData.information.checkStatus === 0 && <>
                                <Button type='danger' onClick={() => {
                                    showModal('information')
                                }}>审核不通过</Button>
                            </>}
                        </div>}
                        {basicData.subjectName && <div className='basic-content-div'>
                            <Descriptions column={1} title='申报学科信息'>
                                <Descriptions.Item label="审核状态">
                                    {basicData.information.checkStatus === 1 ? <span
                                        className="operate-style">审核通过</span> : basicData.information.checkStatus === 2 ?
                                        <span className='delete-style'>审核不通过</span> :
                                        <span className="operate-style">待审核</span>}</Descriptions.Item>
                                {basicData.information.reason && basicData.information.checkStatus === 2 &&
                                <Descriptions.Item label="不通过理由"><span
                                    className='delete-style'>{basicData.information.reason}</span></Descriptions.Item>}
                                <Descriptions.Item label="申报学科">{basicData.subjectName}</Descriptions.Item>
                            </Descriptions>
                            {basicData.information.checkStatus === 0 && <Button type='primary' onClick={() => {
                                changeAuditstatus(state.id, state.year, 'checkDisciplineInformations')
                            }}> 审核通过</Button>}
                            {basicData.information.checkStatus === 0 && <>
                                <Button type='danger' onClick={() => {
                                    showModal('subjectName')
                                }}>审核不通过</Button>
                            </>}
                        </div>}
                        <Modal title="审核不通过原因" visible={isModalVisible} destroyOnClose={true} onOk={() => {
                            handleOk()
                        }} onCancel={handleCancel}>
                            <Input showCount maxLength={50} type="text" ref={inputRef}/>
                        </Modal>
                    </Content>
                </Fragment>

            </div>
        </div>
    );
}
