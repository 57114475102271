import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, DatePicker, Button, Space, Cascader } from 'antd';
const { RangePicker } = DatePicker;

export default function LecturersFilterForm({
    handleFilterFinish, updatedByStaffId, orgId, productId, departmentData, allTitle, data
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (values) => {
        values.importStartTime = timePicker[0];
        values.importEndTime = timePicker[1];
        if (values.province) {
            values.provinceCode = values.province[0];
            values.cityCode = values.province[1];
        }
        delete values.province;
        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            phone: undefined,
            provinceCode: undefined,
            cityCode: undefined,
            updatedByStaffId: undefined,
            productId: undefined,
            orgId: undefined,
            importStartTime: undefined,
            importEndTime: undefined,
            gender: undefined,
            departmentId: undefined,
            titleId: undefined
        };
        handleFilterFinish(values);
    };


    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="姓名" name="name">
                        <Input allowClear placeholder="输入医生名称" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="输入手机号" autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item
                        name="province"
                        label="省份城市"
                    >
                        <Cascader options={data} placeholder="请选择省份城市" />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="操作人" name="updatedByStaffId">
                        <Select allowClear placeholder="请选择操作人">
                            {updatedByStaffId && updatedByStaffId.map(item => {
                                return (
                                    <Select.Option key={item.phone} value={item.phone}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="产品线" name="productId">
                        <Select allowClear placeholder="请选择产品线">
                            {productId && productId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="所属企业" name="orgId">
                        <Select allowClear placeholder="请选择所属企业">
                            {orgId && orgId.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="上传时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="性别" name="gender">
                        <Select allowClear placeholder="选择性别" >
                            <Select.Option value={1}>男</Select.Option>
                            <Select.Option value={2}>女</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item
                        label="科室"
                        name="departmentId">
                        <Select allowClear placeholder="请选择科室">
                            {departmentData && departmentData.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item
                        label="职称"
                        name="titleId">
                        <Select allowClear placeholder="请选择职称">
                            {allTitle && allTitle.map(item => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
