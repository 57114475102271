import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Cascader, DatePicker, Form, Input, Layout, message, Modal, Popconfirm, Radio, Select, Tabs, Typography, Upload, Divider, Row, Col, Space, Tag } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, DeleteOutlined, LeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import ImgCrop from 'antd-img-crop';
import ReactQuill from 'react-quill';
import {
    saveExpertApi,
    getBaseInfoApi,
    getMoreInfoApi,
    getAccountInfoApi,
    editInfoApi,
    postExpertApi,
    delPoistionApi,
    getExpertListApi,
    sysdictionaryApi,
    delPeopleApi,
    changeExpertMobile,
} from '@services/Experts';
import config from '@config';
import areas from '@utils/areas';
import national from '@utils/national';
import { expertLevels, cardTypes } from '@utils/constants';
import { pageFormItemLayout } from '@utils/property';
import './index.less';
import { scrollToFirstFormItemHasError } from '@utils/utils';

const { Text } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

const Expert = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    let expertId = params.id || 0;
    expertId = parseInt(expertId) || 0;

    const [expertForm] = Form.useForm();

    const option = { headers: { "Content-Type": "multipart/form-data" } };

    // 滚动页面 tab 浮动，scroll spy
    const [fixed, setFixed] = useState(false);
    const toolbarRef = useRef(null);
    const [tabActiveKey, setTabActiveKey] = useState('section-basic');

    const [changeExpertMobileForm] = Form.useForm();
    const [visibleChangeExpertMobile, setVisibleChangeExpertMobile] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setTimeout(() => {
                const toolbar = toolbarRef.current;
                if (toolbar) {
                    const toolbarTop = toolbar.getBoundingClientRect().top;
                    setFixed(toolbarTop <= 0);
                }

                const sectionBasicEle = document.getElementById('section-basic');
                const sectionPaymentEle = document.getElementById('section-payment');
                const sectionMoreEle = document.getElementById('section-more');

                if (sectionBasicEle && sectionPaymentEle && sectionMoreEle) {
                    const headerOffset = 48;
                    const sectionBasicTop = sectionBasicEle.getBoundingClientRect().top - headerOffset;
                    const sectionPaymentTop = sectionPaymentEle.getBoundingClientRect().top - headerOffset;
                    const sectionMoreTop = sectionMoreEle.getBoundingClientRect().top - headerOffset;
                    // console.log(sectionBasicTop, sectionPaymentTop, sectionMoreTop);

                    if (sectionMoreTop < 0) {
                        setTabActiveKey('section-more');
                    } else if (sectionPaymentTop < 0) {
                        setTabActiveKey('section-payment');
                    } else {
                        setTabActiveKey('section-basic');
                    }
                }

            }, 300);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // 切换 tab 滚动到页面指定位置
    const onChangeTabKey = key => {
        const ele = document.getElementById(key);
        const eleTop = ele.getBoundingClientRect().top;
        const headerOffset = 30;
        const offsetPosition = eleTop + window.scrollY - headerOffset;
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    };

    //图片列表
    const [fileList, setFileList] = useState([]);
    const [publicityPhotoList, setPublicityPhotoList] = useState([]); // 宣传照片
    const [previewOpen, setPreviewOpen] = useState(false);
    const [publicPreviewOpen, setPublicPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [size, setSize] = useState(false)
    const [big, setBig] = useState(false)
    const [mode, setMode] = useState(false)
    const baseRef = useRef(null)
    const laborRef = useRef(null)
    const moreRef = useRef(null)
    const accountRef = useRef(null)
    const [prodDetails, setProdDetails] = useState('');

    const [list, setList] = useState([]);

    // 原手机号
    const [formalMobile, setFormalMobile] = useState('');
    const [otherMobile, setOtherMobile] = useState('');

    const [canViewMobile, setCanViewMobile] = useState(false);

    const [someNameList, setSomeNameList] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (expertId) {
            setLoading(true);

            const p1 = getBaseInfoApi({ id: expertId })
                .then((res) => {
                    if (res.status_code === 200) {
                        let data = { ...res.data };

                        data.birthday = !!data.birthday && moment(data.birthday, 'YYYY-MM-DD').isValid()
                            ? moment(data.birthday, 'YYYY-MM-DD')
                            : undefined;

                        const fileUrl = data.expertAvatar ? [{ url: data.expertAvatar }] : [];
                        setFileList(fileUrl);

                        const publicityPhoto = data.publicityPhoto ? [{ url: data.publicityPhoto }] : [];
                        setPublicityPhotoList(publicityPhoto);

                        // let mobiles = [{ memMobile: '' }];
                        // if (data.memMobile) {
                        //     mobiles[0] = { memMobile: data.memMobile };
                        // }
                        // if (data.otherMobile) {
                        //     mobiles[1] = { otherMobile: data.otherMobile };
                        // }
                        // data.mobiles = mobiles;

                        if (/^1\d{10}$/.test(data.memMobile)) {
                            setFormalMobile(data.memMobile);
                        }

                        expertForm.setFieldsValue(data);
                    }
                });

            const p2 = getAccountInfoApi({ id: expertId })
                .then((res) => {
                    if (res.status_code === 200) {
                        let data = { ...res.data };
                        data.birthday = !!data.birthday && moment(data.birthday, 'YYYY-MM-DD').isValid()
                            ? moment(data.birthday, 'YYYY-MM-DD')
                            : undefined;
                        expertForm.setFieldsValue(data);
                    }
                });

            const p3 = getMoreInfoApi({ id: expertId })
                .then((res) => {
                    if (res.status_code === 200) {
                        let data = { ...res.data };
                        data.birthday = !!data.birthday && moment(data.birthday, 'YYYY-MM-DD').isValid()
                            ? moment(data.birthday, 'YYYY-MM-DD')
                            : undefined;

                        let area;
                        if (data.provinceCode === null || data.provinceCode === '') {
                            area = [];
                        } else if (data?.cityCode === null || data?.cityCode === '') {
                            area = [data.provinceCode];
                        } else if (data?.districtCode === null || data?.districtCode === '') {
                            area = [data.provinceCode, data.cityCode]
                        } else {
                            area = [data.provinceCode, data.cityCode, data.districtCode];
                        }
                        data = { ...data, area };

                        expertForm.setFieldsValue(data);
                    }
                });

            // 等待3个接口都成功
            Promise.all([p1, p2, p3])
                .then((res) => {
                    setLoading(false);
                });

        }
    }, []);

    useEffect(() => {

        const privilegedAdmins = [
            19, // 胡志飞
            28, // 赵红
            48, // 王娜
            56, // 王新桐
            71, // 南兴君
            77, // 孙浩
            92, // 闫荣伟
        ];
        let can = false;
        const adminInfo = localStorage.getItem('infos');
        let adminId = 0;
        if (adminInfo && JSON.parse(adminInfo)) {
            const info = JSON.parse(adminInfo)?.data;
            adminId = JSON.parse(info)?.id;
        }
        if (privilegedAdmins.indexOf(adminId) > -1) {
            can = true;
        } else {
            if (!formalMobile) {
                can = true;
            }
        }
        setCanViewMobile(can);
    }, [formalMobile]);

    const imageHandler = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append("path", `upload/`);
            const hide = message.loading("上传中...", 0);

            postExpertApi(formData)
                .then((res) => {
                    const url = `${config.OSS_PREFIX}/${res.data}`; // 获取url,图片预览地址
                    let quill = baseRef.current?.refs?.current?.getEditor(); //获取到编辑器本身
                    const cursorPosition = quill.getSelection().index; //获取当前光标位置
                    quill.insertEmbed(cursorPosition, "image", url); //插入图片
                    quill.setSelection(cursorPosition + 1); //光标位置加1
                    hide();
                });
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    [{ color: [], },],
                    [{ background: [], },],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"], // a链接和图片的显示
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        };
    }, []);

    //头像裁剪
    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式');
            return null;
        }

        if (file.size > 10 * 1024 * 1024) {
            message.error('图片大小不能超过10MB');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    console.log(imgDom.width, imgDom.height);
                    if (imgDom.width < 300 || imgDom.height < 300) {
                        bool && message.warn('头像尺寸不能小于300x300px');
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    const viewDetail = (row) => {
        navigate(`/uc/experts/${row.id}`, { state: { id: row.id, type: 'edit', memCard: row.memCard } });

        window.location.reload();
    };

    // 搜索姓名
    const searchName = async (e) => {
        if (!e.target.value) {
            setSomeNameList([]);
            return;
        }
        const res = await getExpertListApi({
            memUsername: e.target.value,
            page: 1,
            pageSize: 20
        });
        if (res && res.status_code === 200) {
            setSomeNameList(res.data.list);
        }
    };

    // 移除图片
    const onRemove = (value) => {
        const newFileList = fileList.filter(file => file.url !== value.url);
        setFileList(newFileList);
    };

    const handlePreview = (file) => {
        if (mode === true) {
            message.error("只能上传 jpg/png/jpeg 格式")
        } else if (size === true) {
            message.error("头像大小不超过5MB");
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 10;
        if (!isLt5M) {
            message.error("头像大小不超过10MB");
            return false;
        }
        return isJpgOrPng && isLt5M;
        // return isJpgOrPng && isLt5M && fn(file, true);
    };


    const upload = async value => {
        const formData = new FormData();
        formData.append("file", value.file);
        formData.append("path", `avatar/`);
        const res = await postExpertApi(formData, option);
        console.log(res);

        if (res.status_code === 200) {
            setFileList([{ url: config.OSS_PREFIX + `/${res.data}` }]);
        }
    };

    // 移除图片
    const onPublicRemove = value => {
        const newFileList = publicityPhotoList.filter((file) => file.url !== value.url);
        setPublicityPhotoList(newFileList);
    };

    const handlePublicPreview = file => {
        if (mode === true) {
            message.error("只能上传 jpg/png/jpeg 格式")
        } else if (size === true) {
            message.error("宣传照片大小不超过20MB");
        } else {
            setPreviewImage(file.url || file.preview);
            setPublicPreviewOpen(true);
        }
    };

    const publicBeforeUpload = file => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 50;
        if (!isLt5M) {
            message.error("宣传照片大小不超过50MB");
            return false;
        }
        return isJpgOrPng && isLt5M;
        // return isJpgOrPng && isLt5M && fn(file, true);
    };

    const publicUpload = async value => {
        const formData = new FormData();
        formData.append("file", value.file);
        formData.append("path", `avatar/`);
        const res = await postExpertApi(formData, option);
        console.log(res);
        if (res.status_code === 200) {
            setPublicityPhotoList([{ url: config.OSS_PREFIX + `/${res.data}` }]);
        }
    };

    const previewCancel = () => {
        setPreviewOpen(false);
    };

    const publicPreviewCancel = () => {
        setPublicPreviewOpen(false);
    };

    const onProdDetailsChange = v => setProdDetails(v);

    const openChangeExpertMobileModal = () => {
        setVisibleChangeExpertMobile(true);
    };

    const closeChangeExpertMobileModal = () => {
        changeExpertMobileForm.resetFields();
        setVisibleChangeExpertMobile(false);
    };

    const onChangeExpertMobile = () => {
        changeExpertMobileForm.validateFields()
            .then(async values => {
                console.log(values, formalMobile);
                const { newMobile: memMobile } = values;
                if (memMobile !== formalMobile) {
                    const res = await changeExpertMobile({ id: expertId, memMobile });
                    if (res.status_code === 200) {
                        message.success('手机号更换成功');
                        setFormalMobile(memMobile);
                        expertForm.setFieldsValue({ memMobile });
                        setVisibleChangeExpertMobile(false);
                    }
                } else {
                    message.warning('新手机号不能与原手机号一致，无需更换！');
                }
            })
            .catch(e => console.log(e));
    };

    // 表单切换 - 回显页面
    useEffect(() => {
        sysdictionaryApi({ dicGroup: 'NATIONALITY' })
            .then(res => {
                if (res.status_code === 200 && res.data && res.data.length) {
                    setList(res.data);
                }
            });
    }, []);

    const onCancel = () => navigate('/uc/experts');

    const onFinish = () => {
        expertForm.validateFields()
            .then(values => {
                const expertData = { ...values };

                let birthday = expertData?.birthday ? expertData?.birthday.format('YYYY-MM-DD') : '';
                let provinceCode = expertData?.area?.[0] ? expertData.area[0] : '';
                let cityCode = expertData?.area?.[1] ? expertData.area[1] : '';
                let districtCode = expertData?.area?.[2] ? expertData.area[2] : '';
                let expertAvatar = fileList?.[0]?.url ? fileList?.[0].url : '';
                let publicityPhoto = publicityPhotoList?.[0]?.url ? publicityPhotoList?.[0].url : '';

                let newArr;
                let moreArr;

                if (expertData?.experiences) {

                    newArr = expertData?.experiences?.filter(v => {
                        if (!v?.unitName) {
                            delete v?.unitName;
                        } else if (v.childs) {
                            v.childs = v?.childs?.filter(it => {
                                if (it) {
                                    let o = Object.values(it).filter(i => i);
                                    if (o.length) {
                                        return it !== undefined;
                                    }
                                }
                            });

                        }

                        if (JSON.stringify(v) !== "{}") {
                            return v;
                        }
                    })
                    newArr.filter(it => it !== undefined);
                }

                if (expertData?.contactsList) {
                    moreArr = expertData.contactsList.filter(v => {
                            if (v) {
                                let o = Object.values(v).filter(i => i);
                                if (o.length) {
                                    return v;
                                }
                            }
                        }
                    );

                }

                // 新增 - 处理手机号
                if (expertData.mobiles) {
                    expertData.memMobile = expertData.mobiles[0]?.memMobile || undefined;
                    expertData.otherMobile = expertData.mobiles[1] ? expertData.mobiles[1]?.otherMobile : undefined;
                    expertData.mobiles = undefined;
                }

                if (expertData?.experiences) expertData.experiences = newArr;
                if (expertData?.contactsList) expertData.contactsList = moreArr;

                let data = {
                    ...expertData,
                    birthday,
                    provinceCode,
                    cityCode,
                    districtCode,
                    expertAvatar,
                    publicityPhoto
                };
                console.log(data);

                if (expertId) {
                    data = { ...data, id: expertId };

                    editInfoApi(data)
                        .then((res) => {
                            if (res.status_code === 200) {
                                navigate('/uc/experts');
                            }
                        });
                } else {
                    saveExpertApi(data)
                        .then((res) => {
                            if (res.status_code === 200) {
                                navigate('/uc/experts');
                            }
                        });
                }

            })
            .catch(err => {
                scrollToFirstFormItemHasError();
                message.error('专家信息不完整，保存失败！');
            });
    };

    return <>

        <div className="page-title">
            <div className='menu-pointer'>
                <div onClick={() => navigate('/uc/experts')}><LeftOutlined className='go-back'/>返回</div>
            </div>
        </div>

        <Content className="main-content-box" id="expert-container">

            <div className="expert-fixed-toolbar" ref={toolbarRef}>

                <div className={fixed ? 'expert-info-indicator-box fixed' : 'expert-info-indicator-box'}>
                    <div className="expert-info-indicator">
                        <Tabs
                            activeKey={tabActiveKey}
                            onChange={key => onChangeTabKey(key)}
                            items={[
                                { label: '基本信息', key: 'section-basic' },
                                { label: '专业信息', key: 'section-pro' },
                                { label: '收款信息', key: 'section-payment' },
                                { label: '更多信息', key: 'section-more' },
                            ]}
                        />
                    </div>

                </div>

            </div>

            <div className="form-container">

                <Form form={expertForm} name="expertForm" {...pageFormItemLayout}>

                    <div id="section-basic" className="form-subtitle">基本信息</div>

                    <Form.Item
                        name="memUsername"
                        label="姓名"
                        rules={[
                            { required: true, message: '姓名不能为空' },
                            { pattern: /^.{2,50}$/, message: '姓名2-50个字符' }
                        ]}
                        extra={someNameList && !!someNameList.length && <Alert type="warning" style={{ margin: '8px 0' }} message={<>
                            <div className="some-name-title">专家库已存在以下同名专家：</div>
                            <div className="some-name-wrap">
                                {someNameList.map((row, rowIndex) => (
                                    <div className="some-name-list" key={rowIndex}>
                                        <div className="name">{row.memUsername}</div>
                                        <div className="mobile">{row.memMobile}</div>
                                        <div className="post">
                                            {row.ssoMemberWorkExperiencesEntities && row.ssoMemberWorkExperiencesEntities.length
                                                ? row.ssoMemberWorkExperiencesEntities.map((item, index) => (
                                                    <span key={index}>{`${item.unitName ? item.unitName + ' ' : ''}${item.unitOffice ? item.unitOffice + ' ' : ''}${item.unitPost ? item.unitPost + ' ' : ''}${item.unitJobTitle ? item.unitJobTitle : ''}`}</span>
                                                ))
                                                : null
                                            }
                                        </div>
                                        <Button type="link" onClick={() => viewDetail(row)}>查看详情</Button>
                                    </div>
                                ))}
                            </div>
                        </>}/>}
                    >
                        <Input placeholder='请输入姓名' maxLength={50} onBlur={searchName} showCount/>
                    </Form.Item>

                    {canViewMobile && (
                        <>
                            <Form.Item
                                label="手机号"
                                name="memMobile"
                                rules={[
                                    { required: true, whitespace: true, message: "手机号不能为空", },
                                    { pattern: new RegExp(/^1\d{10}$/), message: '填写的手机号格式不正确' }
                                ]}
                                extra={<span>该手机号为“主手机号”，“我的项目”相关活动及信息关联该手机号，如有出入，请<Button type="link" onClick={() => openChangeExpertMobileModal()} style={{ margin: 0, padding: 0 }}>更换手机号</Button></span>}
                            >
                                <Input placeholder="请输入手机号" maxLength={11} style={{ width: 200 }} disabled={!!formalMobile}/>
                            </Form.Item>

                            <Form.Item
                                label="其他手机号"
                                name="otherMobile"
                                rules={[
                                    { pattern: new RegExp(/^1\d{10}$/), message: '填写的手机号格式不正确' }
                                ]}
                            >
                                <Input placeholder='请输入手机号' maxLength={11} style={{ width: 200 }}></Input>
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        name="expertLevel"
                        label="专家级别"
                        rules={[
                            { required: true, message: '未选专家级别' },
                        ]}
                        extra={<div className="expert-level-instruction vertical-scrollbar">
                            {expertLevels.filter(el => el.level !== 0 || el.level !== 12).map((el, idx) => (
                                <div key={idx} className="expert-level">
                                    <b><Text type="warning">{el.label}</Text></b> {el.desc}{idx < expertLevels.length - 2 ? <br/> : ''}
                                </div>
                            ))}
                        </div>}
                    >
                        <Select
                            placeholder="请选择专家级别"
                            showSearch
                            dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
                            optionFilterProp="children"
                        >
                            {expertLevels.map((item, index) => (
                                <Select.Option key={index} value={item.level}>{item.label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const level = getFieldValue('expertLevel');
                            // 医院专家级别
                            const isHospitalLevel = level >= 1 && level <= 9;
                            // 企业
                            const isEnterpriseLevel = level === 10 || (level >= 21 && level <= 24);
                            // 普通用户
                            const isNormalStaff = level === 0;

                            let postTypeLabels = ['高管职务', '部门职务'];
                            if (isHospitalLevel) {
                                postTypeLabels = ['院级职务', '科室职务'];
                            }
                            if (isEnterpriseLevel) {
                                postTypeLabels = ['企业高管职务', '企业中层职务'];
                            }

                            const officeLabel = isHospitalLevel ? '科室' : '部门';

                            return (
                                <Form.Item label="单位任职">
                                    <Form.List
                                        name="experiences"
                                        initialValue={[{
                                            childs: [{ postType: 2, unitOffice: '', unitPost: '', unitJobTitle: '' }]
                                        }]}
                                    >
                                        {(fields, { add, remove, move }) => (
                                            <>
                                                <Button
                                                    type="link"
                                                    onClick={() => add()}
                                                    disabled={fields.length >= 10}
                                                    icon={<span className="anticon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                        </svg>
                                                    </span>}
                                                    style={{ padding: 4 }}
                                                >
                                                    新增单位任职
                                                </Button>

                                                {fields.map(({ key, name, ...restField }, index) => (
                                                    <div className="unit-employment-item" key={key}>

                                                        <div className="unit-employment-body">

                                                            <Row gutter={10}>
                                                                <Col span={16}>
                                                                    <Form.Item
                                                                        className="section"
                                                                        {...restField}
                                                                        name={[name, 'unitName']}
                                                                        label="单位名称"
                                                                        rules={[{ required: index === 0, message: '单位名称不能为空' }]}
                                                                    >
                                                                        <Input placeholder="请输入单位全称"/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8} className="actions">
                                                                    <div className="move-btn">

                                                                        {index !== 0 && (
                                                                            <Button
                                                                                type="link"
                                                                                onClick={() => move(index, index - 1)}
                                                                                icon={<span className="ant-icon">
                                                                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                                                                                       <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                                                                                   </svg>
                                                                                </span>}
                                                                            >上移</Button>
                                                                        )}

                                                                        {index !== fields.length - 1 && (
                                                                            <Button
                                                                                type="link"
                                                                                onClick={() => move(index, index + 1)}
                                                                                icon={<span className="ant-icon">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                                                                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
                                                                                    </svg>
                                                                                </span>}
                                                                            >下移</Button>
                                                                        )}

                                                                        {index !== 0 && <>
                                                                            {expertId && expertForm.getFieldsValue()?.experiences?.[index]?.id
                                                                                ? (
                                                                                    <Popconfirm
                                                                                        title="确定删除该单位任职么？"
                                                                                        onConfirm={() => {
                                                                                            const id = expertForm.getFieldsValue().experiences[index].id;
                                                                                            delPoistionApi({ id })
                                                                                                .then(res => {
                                                                                                    if (res.status_code === 200) {
                                                                                                        remove(name);
                                                                                                        message.success('删除单位任职成功');
                                                                                                    }
                                                                                                });
                                                                                        }}
                                                                                        okText="确定"
                                                                                        cancelText="取消"
                                                                                    >
                                                                                        <Button
                                                                                            type="link"
                                                                                            icon={<span className="ant-icon">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                                                        </svg>
                                                                                    </span>}
                                                                                        >移除</Button>
                                                                                    </Popconfirm>
                                                                                )
                                                                                : <Button
                                                                                    type="link"
                                                                                    onClick={() => remove(name)}
                                                                                    icon={<span className="ant-icon">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                                                    </svg>
                                                                                </span>}
                                                                                >移除</Button>
                                                                            }
                                                                        </>}

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Form.List
                                                                name={[name, 'childs']}
                                                                initialValue={[{
                                                                    postType: 2, unitOffice: '', unitPost: '', unitJobTitle: ''
                                                                }]}
                                                            >
                                                                {(childs, { add: addtest, remove: removetest, move: moveChild }) => (
                                                                    <>
                                                                        <Button
                                                                            type="link"
                                                                            className="dark"
                                                                            onClick={() => addtest({ postType: 2 }, childs.length)}
                                                                            disabled={childs.length >= 5}
                                                                            icon={<span className="anticon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                                            </svg>
                                                                        </span>}
                                                                            style={{ padding: 4 }}
                                                                        >添加职务</Button>

                                                                        {childs.map(({ key: childKey, name: childName, ...restField }, idx) => (

                                                                            <div className="add-post-container" key={childKey}>
                                                                                <div className="position-type">
                                                                                    <Row>

                                                                                        <Col span={16}>
                                                                                            <Form.Item
                                                                                                label="职务类型"
                                                                                                {...restField}
                                                                                                name={[childName, 'postType']}
                                                                                                rules={[
                                                                                                    { required: true, message: '请选择职务类型' },
                                                                                                ]}
                                                                                                style={{ marginBottom: 8 }}
                                                                                            >
                                                                                                <Radio.Group>
                                                                                                    <Radio value={2}>{postTypeLabels[0]}</Radio>
                                                                                                    <Radio value={1}>{postTypeLabels[1]}</Radio>
                                                                                                </Radio.Group>
                                                                                            </Form.Item>
                                                                                        </Col>

                                                                                        <Col span={8} className="actions">

                                                                                            {idx !== 0 && (
                                                                                                <Button
                                                                                                    type="link"
                                                                                                    className="dark"
                                                                                                    onClick={() => moveChild(idx, idx - 1)}
                                                                                                    icon={<span className="ant-icon">
                                                                                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                                                                                                           <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                                                                                                       </svg>
                                                                                                    </span>}
                                                                                                >上移</Button>
                                                                                            )}

                                                                                            {idx !== childs.length - 1 && (
                                                                                                <Button
                                                                                                    type="link"
                                                                                                    className="dark"
                                                                                                    onClick={() => moveChild(idx, idx + 1)}
                                                                                                    icon={<span className="ant-icon">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                                                                                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
                                                                                                        </svg>
                                                                                                    </span>}
                                                                                                >下移</Button>
                                                                                            )}

                                                                                            {idx !== 0 && <>
                                                                                                {expertId && expertForm.getFieldsValue()?.experiences?.[index]?.childs?.[idx]?.id
                                                                                                    ? (
                                                                                                        <Popconfirm
                                                                                                            title="确定删除该职称么？"
                                                                                                            onConfirm={() => {
                                                                                                                const id = expertForm.getFieldsValue().experiences[index].childs[idx].id;
                                                                                                                delPoistionApi({ id })
                                                                                                                    .then((res) => {
                                                                                                                        if (res.status_code === 200) {
                                                                                                                            removetest(childName);
                                                                                                                            message.success('删除职称成功');
                                                                                                                        }
                                                                                                                    });
                                                                                                            }}
                                                                                                            okText="确定"
                                                                                                            cancelText="取消"
                                                                                                        >
                                                                                                            <Button
                                                                                                                type="link"
                                                                                                                className="dark"
                                                                                                                icon={<span className="ant-icon">
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                                                                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                                                                                    </svg>
                                                                                                                </span>}
                                                                                                            >移除</Button>
                                                                                                        </Popconfirm>
                                                                                                    )
                                                                                                    : <Button
                                                                                                        type="link"
                                                                                                        className="dark"
                                                                                                        onClick={() => removetest(childName)}
                                                                                                        icon={<span className="ant-icon">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                                                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                                                                            </svg>
                                                                                                        </span>}
                                                                                                    >移除</Button>
                                                                                                }
                                                                                            </>}

                                                                                        </Col>

                                                                                    </Row>
                                                                                </div>

                                                                                <div className="position">
                                                                                    <Row gutter={10}>

                                                                                        <Form.Item noStyle shouldUpdate>
                                                                                            {({ getFieldValue }) => {
                                                                                                const childsFields = getFieldValue('experiences') ? getFieldValue('experiences')[index]?.childs : [];
                                                                                                return childsFields && !!childsFields.length && childsFields[idx]?.postType === 1 && (
                                                                                                    <>
                                                                                                        <Col span={8}>
                                                                                                            <Form.Item
                                                                                                                {...restField}
                                                                                                                label={officeLabel}
                                                                                                                labelCol={{ span: 24 }}
                                                                                                                wrapperCol={{ span: 24 }}
                                                                                                                name={[childName, 'unitOffice']}
                                                                                                                className='position-item'
                                                                                                                rules={[
                                                                                                                    { required: true, message: `${officeLabel}不能为空` },
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input/>
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                );
                                                                                            }}
                                                                                        </Form.Item>

                                                                                        <Col span={8}>
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                label="职务"
                                                                                                labelCol={{ span: 24 }}
                                                                                                wrapperCol={{ span: 24 }}
                                                                                                name={[childName, 'unitPost']}
                                                                                                className='position-item'
                                                                                                rules={[
                                                                                                    { required: true, message: '职务不能为空' },
                                                                                                ]}
                                                                                            >
                                                                                                <Input/>
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                        <Col span={8}>
                                                                                            {!isEnterpriseLevel && (
                                                                                                <Form.Item
                                                                                                    {...restField}
                                                                                                    label="职称"
                                                                                                    labelCol={{ span: 24 }}
                                                                                                    wrapperCol={{ span: 24 }}
                                                                                                    name={[childName, 'unitJobTitle']}
                                                                                                    className='position-item'
                                                                                                >
                                                                                                    <Input/>
                                                                                                </Form.Item>
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>

                                                                            </div>

                                                                        ))}
                                                                    </>
                                                                )}
                                                            </Form.List>

                                                        </div>

                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    <Form.Item name="memGender" label="性别">
                        <Radio.Group>
                            <Radio value={1}>男</Radio>
                            <Radio value={0}>女</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="个人介绍" name="memIntroduction">
                        <ReactQuill modules={modules} theme="snow" value={prodDetails} onChange={onProdDetailsChange}/>
                    </Form.Item>

                    <Form.Item
                        label="个人头像"
                        name="avatar"
                        extra={<>用于项目嘉宾头像、邀请函头像展示等场景；支持JPG/PNG格式，建议不小于500&times;500px，大小不超过10MB</>}
                    >

                        <div className="expert-avatar-container">
                            <div className="expert-avatar-box">
                                <ImgCrop
                                    aspect={1 / 1}
                                    rotationSlider
                                    minZoom={0.1}
                                    maxZoom={2}
                                    beforeCrop={async file => await beforeCropCheck(file, true)}
                                >
                                    <Upload
                                        customRequest={upload}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onRemove={onRemove}
                                        onPreview={handlePreview}
                                        beforeUpload={beforeUpload}
                                    >
                                        {!fileList.length && (
                                            <div>
                                                <PlusOutlined/>
                                                <div style={{ marginTop: 8 }}>上传头像</div>
                                            </div>
                                        )}
                                    </Upload>
                                </ImgCrop>
                            </div>

                            <div className="expert-avatar-extra">
                                {/*{!!fileList.length && <Button type="link">查看原图</Button>}*/}
                            </div>
                        </div>

                        <Modal
                            open={previewOpen}
                            footer={null}
                            onCancel={previewCancel}
                            className="preview-modal"
                        >
                            <img alt="example" src={previewImage} className="expert-image"/>
                        </Modal>
                    </Form.Item>

                    <Form.Item
                        label="宣传照片"
                        name="publicityPhoto"
                        extra={<>专家在“我的项目”上传个人宣传照，用于海报、封面图制作等场景<br/>请上传个人高清照片（半身），建议正装；支持JPG/PNG格式，大小不超过50MB</>}
                    >
                        <Upload
                            customRequest={publicUpload}
                            listType="picture-card"
                            fileList={publicityPhotoList}
                            onRemove={onPublicRemove}
                            onPreview={handlePublicPreview}
                            beforeUpload={publicBeforeUpload}
                        >
                            {publicityPhotoList.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined/>
                                    <div style={{ marginTop: 8 }}>上传宣传照片</div>
                                </div>
                            )}
                        </Upload>

                        <Modal width={650} open={publicPreviewOpen} footer={null} onCancel={publicPreviewCancel} className='public-preview-modal'>
                            <img alt="example" src={previewImage} className="public-image"/>
                        </Modal>
                    </Form.Item>

                    {/*<Form.Item name="skilledFields" label="擅长领域">*/}
                    {/*    <TextArea*/}
                    {/*        placeholder="请输入领域，多领域用；分开"*/}
                    {/*        className="fill-item"*/}
                    {/*        autoSize={{ minRows: 1 }}*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item name="specialization" label="个人专业">*/}
                    {/*    <TextArea*/}
                    {/*        placeholder="请输入专业，多专业用；分开"*/}
                    {/*        className="fill-item"*/}
                    {/*        autoSize={{ minRows: 1 }}*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}

                    <Form.Item
                        name="status"
                        label="是否禁用"
                        rules={[
                            { required: true, message: '请选择用户状态' },
                        ]}
                        initialValue={0}
                        extra="“正常”状态，为未禁用，专家可被其他系统调用；“禁用”状态，则不可被其他系统调用"
                    >
                        <Radio.Group>
                            <Radio value={0}>正常</Radio>
                            <Radio value={1} style={{ color: '#f50' }}>禁用</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <div id="section-pro" className="form-subtitle">专业信息</div>

                    <Form.Item
                        name="academicPosition"
                        label="学术任职"
                        extra="如学协会主委、秘书长、杂志评审等，多条用分号（；）隔开"
                    >
                        <TextArea placeholder="请输入学术任职" autoSize={{ minRows: 1, maxRows: 5 }} maxLength={1000} showCount/>
                    </Form.Item>

                    <Form.Item
                        name="researchAchievements"
                        label="研究成果"
                        extra="如著作、获奖、主持课题等，多条用分号（；）隔开"
                    >
                        <TextArea placeholder="请输入研究成果" autoSize={{ minRows: 1, maxRows: 5 }} maxLength={2000} showCount/>
                    </Form.Item>

                    <Form.Item
                        name="managementExpertises"
                        label="管理专长"
                        extra="擅长医疗管理领域，多条用分号（；）隔开"
                    >
                        <TextArea placeholder="请输入管理专长" autoSize={{ minRows: 1, maxRows: 5 }} maxLength={2000} showCount/>
                    </Form.Item>

                    <Form.Item
                        name="medicalExpertises"
                        label="医学专长"
                        extra="擅长医学学术领域，多条用分号（；）隔开"
                    >
                        <TextArea placeholder="请输入医学专长" autoSize={{ minRows: 1, maxRows: 5 }} maxLength={2000} showCount/>
                    </Form.Item>

                    <Form.Item
                        name="honoraryTitles"
                        label="荣誉头衔"
                        extra="如优秀院长、名医、入选人才计划等，多条用分号（；）隔开"
                    >
                        <TextArea placeholder="请输入荣誉头衔" autoSize={{ minRows: 1, maxRows: 5 }} maxLength={2000} showCount/>
                    </Form.Item>

                    <div id="section-payment" className="form-subtitle">收款信息</div>

                    <Form.Item name="idcardType" label="证件类型">
                        <Select placeholder="请选择证件类型">
                            {cardTypes.map((item, index) => (
                                <Select.Option key={index} value={item.code}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="idCard"
                        className="daoyou-item"
                        label="证件号"
                        rules={[
                            { pattern: /^[0-9a-z]+$/i, message: '证件号格式不正确' },
                        ]}
                    >
                        <Input placeholder="请输入证件号"/>
                    </Form.Item>

                    <Form.Item label="出生日期" name="birthday">
                        <DatePicker placement="bottomLeft" style={{ width: '100%' }}/>
                    </Form.Item>

                    <Form.Item name="birthPlace" label="出生地">
                        <Input placeholder="请输入出生地"/>
                    </Form.Item>

                    <Form.Item name="nationality" label="国籍">
                        <Select
                            fieldNames={{ label: 'dicName', value: 'dicName' }}
                            options={list}
                            placeholder="请选择国籍"
                        />
                    </Form.Item>

                    <Form.Item name="bankName" label="开户银行">
                        <Input placeholder="请输入开户行名称"/>
                    </Form.Item>

                    <Form.Item
                        name="bankCardNo"
                        label="银行账号"
                        rules={[
                            { pattern: /^\d+$/i, message: '银行账号格式不正确' },
                        ]}
                    >
                        <Input
                            placeholder="请输入银行账号"
                            onChange={e => /[^0-9]/.test(e.target.value) && expertForm.setFieldValue('bankCardNo', e.target.value.replace(/[^0-9]/ig, ''))}
                        />
                    </Form.Item>

                    <div id="section-more" className="form-subtitle">更多信息</div>

                    <Form.Item name="expertWechat" label="微信号">
                        <Input placeholder="请输入微信号"/>
                    </Form.Item>

                    <Form.Item name="expertEmail" className="daoyou-item" label="邮箱">
                        <Input placeholder='请输入邮箱'/>
                    </Form.Item>

                    <Form.Item name="area" label="所在地">
                        <Cascader options={areas} changeOnSelect placeholder="请选择所在地"/>
                    </Form.Item>

                    <Form.Item label="民族" name="national">
                        <Select
                            showSearch
                            placeholder="请选择民族"
                            // optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').includes(input)
                            }
                            options={national}
                        />
                    </Form.Item>

                    <Form.Item label="毕业院校" name="school">
                        <Input placeholder="请输入毕业院校"/>
                    </Form.Item>

                    <Form.Item label="最高学历" name="education">
                        <Input placeholder='请输入最高学历'/>
                    </Form.Item>

                    <Form.Item name="workingYears" label="工作年限">
                        <Input placeholder="请输入工作年限"/>
                    </Form.Item>

                    {/*<Form.Item name="professional" label="带教专业">*/}
                    {/*    <Input placeholder="请输入带教专业" />*/}
                    {/*</Form.Item>*/}

                    <Form.Item name="eatingHabits" label="饮食习惯">
                        <Input placeholder='请输入饮食习惯'/>
                    </Form.Item>

                    <Form.Item name="hobby" label="爱好">
                        <Input placeholder='请输入爱好'/>
                    </Form.Item>

                    {/*<Form.Item name="socialPosition" label="社会任职">*/}
                    {/*    <Input placeholder="请输入社会任职" />*/}
                    {/*</Form.Item>*/}

                    <Form.Item label="相关联系人">
                        <Form.List name="contacts">
                            {(fields, { add, remove }, { errors }) => (
                                <>

                                    <Button
                                        type="link"
                                        onClick={() => add()}
                                        disabled={fields.length >= 5}
                                        icon={<span className="anticon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                            </svg>
                                        </span>}
                                        style={{ padding: 4 }}
                                    >添加联系人</Button>

                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <div className="contact-container" key={key}>

                                            <Row gutter={10}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        className="section"
                                                        {...restField}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'name']}
                                                        label="姓名"
                                                        rules={[
                                                            { required: true, message: '姓名不能为空' }
                                                        ]}
                                                    >
                                                        <Input placeholder="请输入姓名"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        className="section"
                                                        {...restField}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'phone']}
                                                        label="手机"
                                                        rules={[
                                                            { required: true, message: '手机不能为空' },
                                                            { pattern: /^1\d{10}$/, message: '手机号码格式不正确' }
                                                        ]}
                                                    >
                                                        <Input placeholder="请输入电话"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        className="section"
                                                        {...restField}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'email']}
                                                        label="邮箱"
                                                    >
                                                        <Input placeholder="请输入邮箱"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <div className="actions">
                                                {expertId && (expertForm.getFieldsValue()?.contacts?.[index]?.id)
                                                    ? (
                                                        <Popconfirm
                                                            title="确定删除这个联系人么？"
                                                            onConfirm={() => {
                                                                const id = expertForm.getFieldsValue().contacts[index].id;
                                                                delPeopleApi({ id })
                                                                    .then((res) => {
                                                                        if (res.status_code === 200) {
                                                                            remove(name);
                                                                            message.success('删除联系人成功');
                                                                        }
                                                                    });
                                                            }}
                                                            okText="确定"
                                                            cancelText="取消"
                                                        >
                                                            <Button
                                                                type="link"
                                                                className="dark"
                                                                icon={<span className="ant-icon">
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                                                               </svg>
                                                            </span>}
                                                            />
                                                        </Popconfirm>
                                                    )
                                                    : <Button
                                                        type="link"
                                                        className="dark"
                                                        icon={<span className="ant-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                                                               </svg>
                                                            </span>}
                                                        onClick={() => remove(name)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item name="remarks" label="备注">
                        <TextArea placeholder="请输入备注" className="fill-item" autoSize={{ minRows: 6, maxRows: 6 }}/>
                    </Form.Item>

                </Form>

            </div>

            <div className="fixed-foot-toolbar">
                {expertForm.getFieldValue('status') === 1 && <div className="expert-status"><span>专家状态：</span><Tag color="#f50">禁用</Tag></div>}

                <div className="operations">
                    <Space size="large">
                        <Button size="large" onClick={onCancel}>取消</Button>
                        <Button type="primary" htmlType="submit" size="large" onClick={onFinish} loading={loading}>保存</Button>
                    </Space>
                </div>
            </div>

        </Content>

        <Modal
            title="更换手机号"
            open={visibleChangeExpertMobile}
            onOk={onChangeExpertMobile}
            onCancel={closeChangeExpertMobileModal}
            centered
        >
            <Form form={changeExpertMobileForm} layout="vertical">
                <Form.Item
                    name="newMobile"
                    label="新手机号"
                    rules={[
                        { required: true, message: '请输入手机号' },
                        { pattern: /^1\d{10}$/, message: '手机号格式错误' },
                    ]}
                >
                    <Input placeholder="请输入手机号" maxLength={11}/>
                </Form.Item>
            </Form>
        </Modal>

    </>;
};

export default Expert;
