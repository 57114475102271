import React, { Fragment, useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {ExclamationCircleOutlined, LeftOutlined, SearchOutlined} from "@ant-design/icons";
import {Content} from "antd/lib/layout/layout";
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table, Tooltip, TreeSelect, Typography} from "antd";
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import {questionSourceList} from "../constants";
import {useSelector} from "react-redux";
import {getQuestionList, deleteQuestion, handleChangeQuestionState} from '@services/MedConnect';
import {useProjectDiseaseList} from '@hooks/MedConnect';
import "./MedConnectQuestionList.less";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function MedConnectQAList() {
    const navigate = useNavigate();
    // 获取疾病列表
    const {diseaseList} = useProjectDiseaseList();

    let { id } = useParams();
    id = !!id && parseInt(id) || 0;

    const [form] = Form.useForm();

    const projectName = useSelector((state) => state.medconnect.projectName);
    const [filter, setFilter] = useState({pageIndex: 1, pageSize: 10});

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const [audioOpen, setAudioOpen] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');

    const getAqList = async () => {
        try {
            setLoading(true);
            getQuestionList({
                ...filter,
                projectId: id,
            }).then(res => {
                if (res && res.success) {
                    setTableData(res.data);
                    setTotal(res.totalCount);
                }
            })
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        }
    }

    const handleFilterFinish = (value) => {
        setFilter({
            ...filter,
            content: value.content,
            diseaseType: value.diseaseType,
            questionSource: value.questionSource,
            phone: value.phone,
            createUserName: value.createUserName,
            // times: value.times,
            pageIndex: 1,
        })
    }

    const onReset = () => {
        form.resetFields();

        setFilter({
            ...filter,
            ...form.getFieldsValue(),
            pageIndex: 1,
        });
    };

    // 刷新
    const onRefresh = () => getAqList();

    // 删除
    const handleDel = (record) => {
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: `请确认是否删除此项问题？`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                deleteQuestion(record.id).then(res => {
                    if (res && res.success) {
                        message.success(res.data || '删除成功');
                        onRefresh();
                    }
                });
            }
        })
    };

    // 上下架
    const changeStatus = (record) => {
        let messageTxt = '';
        const params = {id: record.id};
        if (record.stateDesc === '待发布' || record.stateDesc === '下架') {
            messageTxt = '请确认是否上架此项问题？';
            params.state = 1;
        } else if (record.stateDesc === '上架') {
            messageTxt = '请确认是否下架此项问题？';
            params.state = 2;
        }
        Modal.confirm({
            title: '信息提示',
            icon: <ExclamationCircleOutlined />,
            content: messageTxt,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                handleChangeQuestionState(params).then(res => {
                    if (res && res.success) {
                        message.success(res.data || '修改成功');
                        onRefresh();
                    }
                });
            }
        })
    }

    // 点击跳转到回答管理列表
    const handleGoAnswerList = (record) => {
        navigate(`/medconnect/projects/${id}/answer-list?content=${encodeURIComponent(record.content)}`);
    }

    // 点击播放语音
    const handlePlayAudio = (record) => {
        setAudioUrl(record.audioOssUrl);
        setAudioOpen(true);
    }

    const handleChangeDisease = (v) => {
        if (v === undefined) {
            form.setFieldValue('diseaseType', -1);
        }
    }

    const onChangePage = (pageIndex, pageSize) => {
        setFilter({
            ...filter,
            pageIndex,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const columns = [
        {
            title: '问题标题',
            dataIndex: 'content',
            ellipsis: true,
            width: 360,
            render: (text, record) => {
                return (
                    <Tooltip title={<span>{text}</span>}>
                        <div className="quest-title">{text}</div>
                    </Tooltip>
                )
            }
        },
        { title: '疾病类型', dataIndex: 'diseaseTypDesc', width: 120, },
        {
            title: '语音识别',
            dataIndex: 'audioOssUrl',
            width: 60,
            render: (text, record) => {
                if (text) {
                    return <Button type='link' onClick={() => handlePlayAudio(record)}>播放</Button>
                } else {
                    return <div>-</div>
                }
            }
        },
        {
            title: '回答数',
            dataIndex: 'answerNum',
            width: 80,
            render: (_, record) => {
                return (
                    <Button type="link" onClick={() => handleGoAnswerList(record)}>
                        {_}
                    </Button>
                )
            }
        },
        { title: '问题来源', dataIndex: 'questionSourceDesc', width: 120, },
        {
            title: '提交人',
            dataIndex: 'createUserName',
            width: 120,
            render: (text, record) => {
                return (
                    <Tooltip title={<span>{record.createUserName} {record.createdBy} <br /> {record.post}</span>}>
                        <div>{text}</div>
                        <div>{record.createAt}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '问题状态',
            dataIndex: 'stateDesc',
            width: 140,
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>{text}</div>
                        <div>{record.updatedAt}</div>
                    </Fragment>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            fixed: 'right',
            width: 100,
            render: (_, record) => {

                return (
                    <span className="operation-btns">
                        {/*<Button*/}
                        {/*    size="small"*/}
                        {/*    type="link"*/}
                        {/*    onClick={() => {}}*/}
                        {/*>*/}
                        {/*    编辑*/}
                        {/*</Button>*/}
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDel(record)}
                        >
                            删除
                        </Button>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => changeStatus(record)}
                        >
                            {record.stateDesc === '待发布' || record.stateDesc === '下架' ? '上架' : '下架'}
                        </Button>
                    </span>
                );
            }
        },
    ];

    useEffect(() => {
        getAqList();
    }, [filter]);

    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">问题管理</span>
                <span className="current-subtitle">{projectName}</span>
            </div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form {...filterFormItemLayout} onFinish={handleFilterFinish} form={form}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="问题标题" name="content">
                                    <Input allowClear placeholder="输入问题标题" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="疾病分类" name="diseaseType">
                                    <TreeSelect
                                        showSearch
                                        placeholder='请选择疾病分类'
                                        allowClear
                                        multiple
                                        treeNodeFilterProp="title"
                                        onChange={handleChangeDisease}
                                        treeData={diseaseList}
                                        maxTagCount={1}
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="问题来源" name="questionSource">
                                    <Select allowClear placeholder="全部">
                                        {questionSourceList && questionSourceList.map(item => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="phone">
                                    <Input allowClear placeholder="请输入提交人手机号" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="提交医生" name="createUserName">
                                    <Input allowClear placeholder="请输入医生姓名" autoComplete="off"/>
                                </Form.Item>
                            </Col>

                            {/*<Col {...filterFormGridLayout}>*/}
                            {/*    <Form.Item label="提交时间" name="times">*/}
                            {/*        <RangePicker/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}

                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            {/*<Button type="primary" onClick={() => {}}>新增</Button>*/}
                        </div>

                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="刷新列表">
                                    <Button
                                        type="default"
                                        onClick={onRefresh}
                                        icon={<>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                                </svg>
                                            </span>
                                        </>}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            total,
                            showTotal: () => `共${total}条`,
                            current: filter.pageIndex,
                            pageSize: filter.pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: onChangePage,
                            onShowSizeChange,
                        }}
                    />
                </div>

                {/* 语音播放弹窗 */}
                <Modal
                    open={audioOpen}
                    title={'语音播放'}
                    centered
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setAudioOpen(false);
                    }}
                >
                    <div>
                        <audio
                            style={{width: '100%'}}
                            controls
                            src={audioUrl}>
                        </audio>
                    </div>
                </Modal>
            </Content>

        </Fragment>
    )
}

