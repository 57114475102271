import { get, put, post, delet } from "@core/request";

/**
 * @description: 获取已增加的参评的主题列表
 * @param {projectId, stageId} params
 * @return {Promise<any>}
 */
export const fetchCasescoringtopicsList = (params) => get(`/selection/admin/casescoringtopics/list`, params);

/**
 * @description: 已选的参评主题设置隐藏/展示
 * @param {id, hidden} params
 * @return {Promise<any>}
 */
export const putCasescoringtopicsUpdate = (params) => put(`/selection/admin/casescoringtopics/update`, params);

/**
 * @description: 获取待选择的主题列表接口
 * @param {projectId} params
 * @return {Promise<any>}
 */
export const fetchProjecttopicsList = (params) => get(`/selection/admin/casescoringtopics/candidateTopics`, params);

/**
 * @description: 给指定环节下增加参评主题
 * @param {projectId, stageId} params
 * @return {Promise<any>}
 */
export const saveProjecttopicsList = (params) => post(`/selection/admin/casescoringtopics/save`, params);

/**
 * @description: 评分设置接口
 * @return {Promise<any>}
 */
export const setCasescoringtopicsSetting = (params) => post(`/selection/admin/casescoringtopics/setting`, params);

/**
 * @description: 参评案例列表
 * @return {Promise<any>}
 */
export const fetchCasescoresList = (params) => post(`/selection/admin/casescores/list`, params);

/**
 * @description: 移除参评案例
 * @return {Promise<any>}
 */
export const deleteCasescoresCase = (params) => delet(`/selection/admin/casescores/delete`, params);

/**
 * @description: 分页查询待选择的案例
 * @return {Promise<any>}
 */
export const fetchSelectCaseList = (params) => post(`/selection/admin/casescores/selectCaseList`, params);

/**
 * @description: 将选中的案例添加到参评案例列表
 * @return {Promise<any>}
 */
export const saveSelectCases = (params) => post(`/selection/admin/casescores/save`, params);

/**
 * @description: 修改最终得分
 * @return {Promise<any>}
 */
export const updateFinalScore = (params) => post(`/selection/admin/casescores/updateFinalScore`, params);

/**
 * @description: 修改排序
 * @return {Promise<any>}
 */
export const updateSort = (params) => post(`/selection/admin/casescores/updateSort`, params);

/**
 * @description: 指定案例的评委评分列表
 * @return {Promise<any>}
 */
export const fetchCasescoringassignedjudgesList = (params) => post(`/selection/admin/casescoringassignedjudges/list`, params);

/**
 * @description: 修改屏蔽状态
 * @return {Promise<any>}
 */
export const updateBanned = (params) => post(`/selection/admin/casescoringassignedjudges/updateBanned`, params);

/**
 * @description: 修改评委最终得分
 * @return {Promise<any>}
 */
export const updateCasescoringassignedjudgesFinalScore = (params) => post(`/selection/admin/casescoringassignedjudges/updateFinalScore`, params);

/**
 * @description: 移除评委
 * @return {Promise<any>}
 */
export const deleteCasescoringassignedjudges = (params) => delet(`/selection/admin/casescoringassignedjudges/delete`, params);

/**
 * @description: 根据环节ID查询评分环节信息
 * @return {Promise<any>}
 */
export const getCasescoringstagesInfo = (id) => get(`/selection/admin/casescoringstages/info/${id}`);

/**
 * @description: 环节评委列表
 * @return {Promise<any>}
 */
export const getCaseJudgesList = (params) => get(`/selection/admin/casejudges/judgesLibByStage`, params);

/**
 * @description: 分配页面评委列表
 * @return {Promise<any>}
 */
export const getJudgesList = (params) => get(`/selection/admin/casejudges/caseJudgeslist`, params);

/**
 * @description: 给指定案例分配评委
 * @return {Promise<any>}
 */
export const assignedJudgeToCase = (params) => post(`/selection/admin/casescoringassignedjudges/assignedJudgeToCase`, params);

/**
 * @description: 案例分配接口
 * @return {Promise<any>}
 */
export const fenpeicase = (params) => post(`/selection/admin/casescoringassignedjudges/fenpeicase`, params);

/**
 * @description: 重置分配的评委
 * @return {Promise<any>}
 */
export const deleteFenpeicase = (params) => post(`/selection/admin/casescoringassignedjudges/deleteByTopics`, params);

/**
 * @description: 根据项目ID和环节ID查询参评的主题列表
 * @return {Promise<any>}
 */
export const getCasescoringtopicsList = (params) => get(`/selection/admin/casescoringtopics/list`, params);

/**
 * @description: 主题案例排名
 * @return {Promise<any>}
 */
export const getCaseScoreList = (params) => get(`/selection/admin/casescores/case-score-list`, params);

/**
 * @description: 根据环节ID和主题ID统计评委数
 * @return {Promise<any>}
 */
export const getCasejudgesCount = ({ stageId, topicId }) => get(`/selection/admin/casejudges/count/${stageId}/${topicId}`);

/**
 * @description: 根据项目ID查询相关赛区列表
 * @return {Promise<any>}
 */
export const getCaseRegion = (params) => get(`/selection/api/project/zones`, params);