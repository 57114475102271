import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { pageFormItemWithoutLabelGridLayout, drawerFormItemLayout, drawerWidth } from '@utils/property';
import { Form, Input, Divider, Button, Space, Drawer, Descriptions, message } from 'antd';
import UploadImage from '../../Common/UploadImage';
import { get } from '@/core/request';

function EnterpriseAddForm({ onHandleFinish, onHandlePutFinish }, ref) {

    const [form] = Form.useForm();

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [tip, setTip] = useState('');
    const [rowData, setRowData] = useState({});
    const [imgUrl, setImgUrl] = useState('');
    const [fileList, setFileList] = useState([]);
    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(tip, row) {
            setTip(tip);
            setVisible(true);
            setFileList([]);
            if (tip === 'edit') {
                get(`/dms/enterprises/queryOrg`, {
                    orgId: row.id
                }).then((res) => {
                    if (res.status_code === 200) {
                        form.setFieldsValue({
                            name: res.data[0].name,
                        })
                        setImgUrl(res.data[0].logoUrl);
                        setFileList([{
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            url: res.data[0].logoUrl,
                        }]);
                        setRowData(res.data[0]);
                    }
                })
            }
        }
    }));

    const handleClose = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                if (imgUrl === "") {
                    message.warning('企业LOGO不能为空');
                    return
                }
                values.logoUrl = imgUrl;
                if (tip === 'add') {
                    onHandleFinish(values);
                } else {
                    values.id = rowData.id;
                    onHandlePutFinish(values)
                }
                handleClose();
            })
            .catch((err) => { });
        setPedding(false);
    };

    // const checkPic = (rule, value) => {
    //     console.log(rule,imgUrl);
    //     return new Promise((resolve, reject) => {
    //         if (!imgUrl) {
    //             reject(new Error('请上传图片'))
    //         } else {
    //             resolve()
    //         }
    //     })
    // }

    return (
        <Fragment>
            {
                visible && <Drawer
                    closable={false}
                    width={drawerWidth}
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <div className="main-content-body">
                        <h2>{tip === "add" ? '新增企业' : "编辑企业"}</h2>
                        <Form {...drawerFormItemLayout} form={form}>

                            <Form.Item
                                name="name"
                                label="企业名称"
                                rules={[{ required: true, message: '请填写企业名称' }]}
                            >
                                <Input placeholder="请输入合作企业名称" allowClear autoComplete="off" />
                            </Form.Item>

                            <Form.Item
                                name="logoUrl"
                                label="企业LOGO"
                                help="建议尺寸360*92px，小于512kb，支持JPG/JPEG/PNG格式"
                                required={true}
                            // rules={[{ validator: checkPic }]}
                            >
                                <UploadImage size={0.5} setImgUrl={setImgUrl} setFileList={setFileList} fileList={fileList}></UploadImage>
                            </Form.Item>

                            {
                                tip === 'edit' && (
                                    <Fragment>
                                        <Descriptions column={2} style={{ marginLeft: '20px' }}>
                                            <Descriptions.Item label="产品线数量">{rowData.productCount}</Descriptions.Item>
                                            <Descriptions.Item label="企业ID">{rowData.id}</Descriptions.Item>
                                            <Descriptions.Item label="创建时间">{rowData.createdAt}</Descriptions.Item>
                                            <Descriptions.Item label="操作人">{rowData.updatedByStaffIdName}</Descriptions.Item>
                                            <Descriptions.Item label="操作人电话">{rowData.updatedByStaffId}</Descriptions.Item>
                                        </Descriptions>
                                    </Fragment>

                                )
                            }

                            <Divider />

                            <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                                <Space>
                                    <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>保存</Button>
                                    <Button type="primary" onClick={handleClose}>取消</Button>
                                </Space>
                            </Form.Item>

                        </Form>

                    </div>
                </Drawer>
            }


        </Fragment>
    );
}

export default forwardRef(EnterpriseAddForm)

