import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Space,
    Table,
    Tooltip,
    message,
    Avatar,
    Drawer,
    Select
} from 'antd';
import {
    LeftOutlined,
    RightOutlined,
    SearchOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CaretDownOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import {
    getAllExpertsApi,
    saveExpertsApi,
} from "@services/Guest";
import "../../GuestList.less";
import './index.less'
import {expertLevels} from '@utils/constants';

const AddGuest = ({
    visibleAddGuestsDrawer,
    setVisibleAddGuestsDrawer,
    onResetGuest,
}) => {

    const navigate = useNavigate();

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const [inPageSize, setInPageSize] = useState(10);
    const [inPage, setInPage] = useState(1);
    const [inTotal, setInTotal] = useState(0);

    const [selected, setSelected] = useState([]);
    const [delSelected, setDelSelected] = useState([]);

    const [loading, setLoading] = useState(false);
    const [saveMore, setSaveMore] = useState([]);
    const [delMore, setDelMore] = useState([]);
    const isSearchRef = useRef(null);
    const [expertForm] = Form.useForm();
    const [inForm] = Form.useForm();
    const params = useParams();

    const [filObj, setFilObj] = useState({
        mobile: "",
        name: "",
    });

    const guestSelectionTableColumns = [
        {
            title: "专家等级",
            name: "expertLevel",
            width: 100,
            render: (_, record) => (
                <Fragment>
                    <span>{expertLevels.find(el => el.level === record.expertLevel)?.label}</span>
                </Fragment>
            ),
        },
        {
            title: "姓名",
            name: "name",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    <Avatar style={{ marginRight: "4px" }} src={record?.expertAvatar}/>
                    <span>{record.memUsername}</span>
                </Fragment>
            ),
        },
        {
            title: "手机号",
            name: "memMobile",
            width: 150,
            render: (_, record) => (
                <Fragment>
                    <span>{record.memMobile}</span>
                </Fragment>
            ),
        },
        {
            title: "单位任职",
            name: "units",
            render: (_, record) => {
                return (
                    <Fragment>
                        {record.ssoMemberWorkExperiencesEntities &&
                            record.ssoMemberWorkExperiencesEntities.length >
                                0 &&
                            record.ssoMemberWorkExperiencesEntities.map(
                                (item, index) => {
                                    return (
                                        <div key={item.id}>
                                            {item.unitName && <span>{index + 1}.</span>}
                                            {item.unitName && (
                                                <span>
                                                    {item.unitName}&nbsp;
                                                </span>
                                            )}
                                            {item.unitOffice && (
                                                <span>
                                                    {item.unitOffice}&nbsp;
                                                </span>
                                            )}
                                            {item.unitPost && (
                                                <span>
                                                    {item.unitPost}&nbsp;
                                                </span>
                                            )}
                                        </div>
                                    );
                                }
                            )}
                    </Fragment>
                );
            },
        },
        {
            title: "个人信息",
            name: "personalInfo",
            render: (_, record) => {
                return (
                    <Fragment>
                        {record.skilledFields && <span>{record.skilledFields},</span>}
                        {record.specialization && (
                            <span>{record.specialization}</span>
                        )}
                    </Fragment>
                );
            },
        },
    ];

    const [guestsToSelect, setGuestsToSelect] = useState([]);
    const [guestsSelected, setGuestsSelected] = useState([]);
    const [guestsFilter,setGuestsFilter] = useState([]);

    // 添加嘉宾
    const addGuests = () => {
        let expertsIds = guestsSelected.map((item) => item.memCard);
        let expertsId = guestsSelected.map((item) => item.id);
        saveExpertsApi({ conferenceId: params.id, memCards: expertsIds,expertIds:expertsId }).then(
            (res) => {
                if (res.status_code === 200) {
                    message.success("添加成功");
                    setVisibleAddGuestsDrawer(false);
                    onResetGuest('change');
                }
            }
        );
        // TODO
    };

    const guestToSelectSelection = {
        selectedRowKeys: selected,
        onSelect: (record, select) => {
            if (select) {
                let originalArray = [...selected, record.id];
                let uniqueArray = Array.from(new Set(originalArray));
                setSelected([...uniqueArray]);
                setSaveMore([...saveMore, record]);
            } else {
                let re = selected.filter((it) => it !== record.id);
                setSelected([...re]);

            }
        },
        onSelectAll: (select, selectedRows, changeRows) => {
            if (select) {
                let res = changeRows.map((i) => i.id);
                let originalArray = [...selected, ...res];
                let uniqueArray = Array.from(new Set(originalArray));
                setSelected([...uniqueArray]);
                setSaveMore([...saveMore, ...selectedRows]);
            } else {
                let newIds = changeRows.map((i) => i.id);
                let originalArray = [...selected, ...newIds];
                let res=originalArray.filter((item) => originalArray.indexOf(item) === originalArray.lastIndexOf(item))
                setSelected([...res])
            }
        },
    };

    const guestSelectedSelection = {
        selectedRowKeys: delSelected,
        onSelect: (record, select) => {
            if (select) {
                let originalArray = [...delSelected, record.id];
                let uniqueArray = Array.from(new Set(originalArray));
                setDelSelected([...uniqueArray]);
                setDelMore([...delMore, record]);
            } else {
                let re = delSelected.filter((it) => it !== record.id);
                setDelSelected([...re]);
            }
        },
        onSelectAll: (select, selectedRows, changeRows) => {
            if (select) {
                let res = changeRows.map((i) => i.id);
                let originalArray = [...delSelected, ...res];
                let uniqueArray = Array.from(new Set(originalArray));
                setDelSelected([...uniqueArray]);
                setDelMore([...delMore, ...selectedRows]);
            } else {
                let newIds = changeRows.map((i) => i.id);
                let originalArray = [...delSelected, ...newIds];
                let res=originalArray.filter((item) => originalArray.indexOf(item) === originalArray.lastIndexOf(item))
                setDelSelected([...res])
            }
        },
    };

    const getExpertlist = async (page, pageSize, mobile, name, expertLevel) => {
        setLoading(true);
        isSearchRef.current = false;
        let obj = {
            page,
            pageSize,
            mobile,
            name,
            expertLevel,
            conferenceId: params.id
        };

        let res = await getAllExpertsApi(obj);
        if (res.status_code === 200) {
            setLoading(false);
            setGuestsToSelect(res.data.list);
            setTotal(res.data.totalCount);
        }
    };

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onChangeInPage = (page, pageSize) => {
        setInPage(page);
        setInPageSize(pageSize);
    };


    const expertFormFinish = (res) => {
        isSearchRef.current = true;
        setFilObj({
            name: expertForm.getFieldValue("name")
                ? expertForm.getFieldValue("name")
                : "",
            mobile: expertForm.getFieldValue("mobile")
                ? expertForm.getFieldValue("mobile")
                : "",
            expertLevel: (expertForm.getFieldValue("expertLevel") || expertForm.getFieldValue("expertLevel") === 0)
                ? expertForm.getFieldValue("expertLevel")
                : "",
        });
        if (
            (expertForm.getFieldValue("name") === undefined ||
                expertForm.getFieldValue("name") === "") &&
            (expertForm.getFieldValue("mobile") === undefined ||
                expertForm.getFieldValue("mobile") === "") &&
            (expertForm.getFieldValue("expertLevel") === undefined ||
                expertForm.getFieldValue("expertLevel") === "")
        ) {
            setPage(1);
        }
        expertForm.resetFields();
    };


    const filterIn=()=>{

        let old=[...guestsSelected]
        if(inForm.getFieldValue('name')!==undefined||inForm.getFieldValue('name')!==''){
            let fil = guestsFilter.filter((item) => {
                return item?.memUsername.indexOf(inForm.getFieldValue('name')) > -1;
           });
           console.log(fil);
           setGuestsSelected([...fil])
           setInTotal(fil.length)
        }else{
       setGuestsSelected([...old])
       setInTotal(old.length)
        }

    }

    const onInsert = () => {
        let old = guestsSelected ? [...guestsSelected] : [];
        let inside = [...old, ...saveMore];
        inside=inside.filter(i=>i!==undefined)
        let res = inside.filter(function (item, index, self) {
            return self.findIndex((el) => el.id == item.id) === index;
        });

        setGuestsSelected([...res]);
        setGuestsFilter([...res]);
        setInTotal(res.length)
        setSaveMore([])
    };

    const onDelete = () => {
        let res = guestsSelected.filter(
            (item) => !delSelected.includes(item.id)
        );
        let sel = res.map((i) => i.id);
        setSelected([...sel]);
        setDelSelected([]);
        setGuestsSelected([...res]);
        setGuestsFilter([...res]);
        setInTotal(res.length)
    };
    const onAllInsert = () => {
        let old = guestsSelected ? [...guestsSelected] : [];
        let inside = [...old, ...guestsToSelect];
        let uniqueArray = Array.from(new Set(inside));
        let res = guestsToSelect.map((item) => {
            return item.id;
        });
        let originalArray = [...selected, ...res];
        let uniqueSelectArray = Array.from(new Set(originalArray));

        setSelected([...uniqueSelectArray]);
        setGuestsSelected([...uniqueArray]);
        setGuestsFilter([...uniqueArray]);
        setInTotal(uniqueArray.length)
    };

    const onAllDeleted = () => {
        setSelected([]);
        setDelSelected([]);
        setGuestsSelected([]);
        setGuestsFilter([]);
        setInTotal(0)
    };

    const closeAddGuestsDrawer = () => {
        setVisibleAddGuestsDrawer(false);
        setFilObj({
            mobile: "",
            name: "",
            expertLevel: ""
        })
        setSelected([]);
        setDelSelected([]);
        setGuestsSelected([]);
        setGuestsFilter([]);
        setInTotal(0)
    };

    useEffect(() => {
        const { mobile, name, expertLevel } = filObj;
        if(visibleAddGuestsDrawer){
            if (isSearchRef.current === true) {
                getExpertlist(1, pageSize, mobile, name, expertLevel);
            } else {
                getExpertlist(page, pageSize, mobile, name, expertLevel);
            }
        }
    }, [page, pageSize, filObj,visibleAddGuestsDrawer]);

    useEffect(()=>{
            setGuestsSelected([]);
            setSelected([]);
            setSaveMore([])
    },[visibleAddGuestsDrawer])

    const toAddExpert = () => {
        navigate('/uc/experts/new');
    };


    return (
        <div>
            <Drawer
                title="添加嘉宾"
                placement="bottom"
                height="100%"
                destroyOnClose
                open={visibleAddGuestsDrawer}
                onClose={closeAddGuestsDrawer}
                footer={
                    <div className="custom-drawer-footer" style={{ textAlign: "right" }}>
                        <Space>
                            <Button onClick={closeAddGuestsDrawer}>取消</Button>
                            <Button type="primary" onClick={addGuests}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <div className="guest-selection-area">
                    <div className="guests-to-select box-hover-shadow">
                        <div className="guest-selection-title">
                            <span><CaretDownOutlined /> 可选嘉宾</span>
                            <div className="toolbar">
                                <Button type="primary" size="small" onClick={toAddExpert}>新增专家</Button>
                            </div>
                        </div>
                        <div className="guest-selection-filter form-guest-item">
                            <Form
                                layout="inline"
                                size="small"
                                form={expertForm}
                            >
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="嘉宾姓名" />
                                </Form.Item>
                                <Form.Item label="手机" name="mobile">
                                    <Input placeholder="嘉宾手机号" />
                                </Form.Item>
                                <Form.Item label="专家级别" name="expertLevel">
                                    <Select
                                        placeholder="请选择专家级别"
                                        options={expertLevels}
                                        fieldNames={{ value: 'level', label: 'label' }}
                                        style={{width: '170px'}}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        icon={<SearchOutlined />}
                                        onClick={expertFormFinish}
                                    >
                                        检索
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                        <Table
                            size="small"
                            columns={guestSelectionTableColumns}
                            dataSource={guestsToSelect}
                            rowKey={(row) => row.id}
                            rowSelection={{
                                type: "checkbox",
                                ...guestToSelectSelection,
                            }}
                            loading={loading}
                            pagination={{
                                total: total,
                                showTotal: () => `共${total}条`,
                                current: page,
                                pageSize: pageSize,
                                onChange: onChangePage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                        />
                    </div>
                    <div className="operations">
                        <Tooltip title="添加选中嘉宾" placement="top">
                            <Button onClick={onInsert}>
                                <RightOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="移除选中嘉宾" placement="bottom">
                            <Button onClick={onDelete}>
                                <LeftOutlined />
                            </Button>
                        </Tooltip>
                    </div>

                    <div className="guests-selected box-hover-shadow">
                        <div className="guest-selection-title">
                            <CaretDownOutlined /> 已选嘉宾
                        </div>
                        <div className="guest-selection-filter">
                            <Form layout="inline" size="small" form={inForm}>
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="嘉宾姓名" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        onClick={filterIn}
                                        icon={<FilterOutlined />}
                                    >
                                        过滤
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            size="small"
                            columns={guestSelectionTableColumns}
                            dataSource={guestsSelected}
                            rowKey={(rows) => rows.id}
                            pagination={{
                                // total: inTotal,
                                showTotal: () => `共${inTotal}条`,
                                current: inPage,
                                pageSize: inPageSize,
                                onChange: onChangeInPage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                            rowSelection={{
                                type: "checkbox",
                                ...guestSelectedSelection,
                            }}
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AddGuest;
