import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Layout, Table, Radio, DatePicker, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import ActiveUserTrend from './ActiveUserTrend';
import { get } from '@/core/request';
import { lastDays, timeString, todayDateString } from '@/utils/utils';
import moment from 'moment';
import './ActiveUser.less';

const { RangePicker } = DatePicker;
const { Content } = Layout;

export default function ActiveUser() {

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});
    const [chartData, setChartData] = useState({
        xData: [],
        yData: []
    })

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    const dateFormat = 'YYYY-MM-DD';

    let date = new Date();
    // 当天
    let dateString = todayDateString(date);
    // 七天前   30天前
    const [dateStringStart, setDateStringStart] = useState(dateString);
    // 当天
    const [dateStringEnd, setDateStringEnd] = useState(dateString);

    const [timeRadio, setTimeRadio] = useState('today')

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    // 渲染table数据
    useEffect(() => {
        var days = (Date.parse(dateStringEnd) - Date.parse(dateStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/active-users-detail`, {
            endTime: dateStringEnd,
            startTime: dateStringStart,
            page: currPage,
            limit: pageSize
        }).then((res) => {
            if (res.status_code === 200) {
                let xArr = [];
                let yArr = [];
                const { chartData, pageData } = res.data
                const { list } = pageData;
                chartData && chartData.forEach(item => {
                    xArr.push(item.time);
                    yArr.push(item.num);
                })
                let arr = list && list.map(item => {
                    return {
                        ...item,
                        key: item.time
                    }
                });
                setTableData({
                    ...pageData,
                    list: arr
                });
                setChartData({
                    xData: xArr,
                    yData: yArr
                });
            }
        })
    }, [dateStringEnd, dateStringStart, currPage, pageSize]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 改变时间
    const handleChangeValue = (e) => {
        setTimeRadio(e.target.value);
        let startdate = '';
        if (e.target.value === 'today') {
            setDateStringStart(dateString)
            setDateStringEnd(dateString)
            changeCurrPage(1)
            return;
        } else if (e.target.value === 'seven') {
            startdate = lastDays(6);
        } else {
            startdate = lastDays(29);
        };
        setDateStringStart(startdate)
        setDateStringEnd(dateString)
        changeCurrPage(1)
    }

    // 搜索的自定义时间 控制为90天

    const disabledDate = (current) => {
        return current > moment().endOf('day');
    }

    const handleChangeTime = (value, dateString) => {
        setDateStringStart(dateString[0]);
        setDateStringEnd(dateString[1]);
        setTimeRadio('');
        changeCurrPage(1)
    }

    // 导出数据
    const handleExportFile = () => {
        var days = (Date.parse(dateStringEnd) - Date.parse(dateStringStart)) / (1 * 24 * 60 * 60 * 1000)
        if (days > 90) {
            message.error('时间区间不得超过90天')
            return;
        }
        get(`/user/data/statistics/active-users-download`, {
            endTime: dateStringEnd,
            startTime: dateStringStart,
            page: currPage,
            limit: pageSize
        }, { responseType: 'blob' }).then((res) => {
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                let date = new Date();
                let dateString = timeString(date);
                const fileName = `活跃用户统计${dateString}.xlsx`;
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    // table 数据相关
    const columns = [
        { title: '日期', dataIndex: 'time', key: 'time' },
        {
            title: '活跃用户数', dataIndex: 'num',
            render: (operates, row) => (
                <span>{operates === null ? 0 : operates}</span>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">活跃用户统计</div>

            <Content className="main-content-box main-content-box-active">

                <div className="main-content-body">
                    <div className="active-user-title">统计时间</div>

                    <div className="bi-user-overview-time-box">
                        <Radio.Group onChange={handleChangeValue} size="middle" value={timeRadio} className="bi-user-overview-time-box-left">
                            <Radio.Button value="today">今天</Radio.Button>
                            <Radio.Button value="seven">最近7天</Radio.Button>
                            <Radio.Button value="thirty">最近30天</Radio.Button>
                        </Radio.Group>
                        {
                            dateStringStart && <RangePicker
                                size="middle"
                                allowClear={false}
                                value={[moment(dateStringStart, dateFormat), moment(dateStringEnd, dateFormat)]}
                                disabledDate={disabledDate}
                                onChange={handleChangeTime}
                            />
                        }

                    </div>

                    <div className="active-user-title">活跃用户趋势</div>

                    <ActiveUserTrend chartData={chartData}></ActiveUserTrend>

                    <div className="active-user-title">
                        <span>数据明细</span>
                        <div className="table-toolbar">
                            <Button type="default" onClick={handleExportFile}><DownloadOutlined />导出</Button>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        columns={columns}
                        dataSource={tableData.list}
                        pagination={paginationProps}
                    />

                </div>
            </Content>

        </Fragment>
    )
}
