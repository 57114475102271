import React, { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Form, Layout, Space, Tooltip, Table, Select, Drawer, Modal, message, Typography, InputNumber, Image } from 'antd';
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { drawerWidth } from '../../../utils/property';
import {
    getLiveOneByIdApi,
    getConferenceLiveCommentsListApi,
    deleteConferenceLiveCommentIdApi,
    fileApi,
    saveLiveCommentApi,
    getFakeCommentCount,
    sendFakeComments
} from "@services/Conference";
import ReactQuill, { Quill } from "react-quill";
import config from '@config';

const { Content } = Layout;
const { Text } = Typography;

export default function LiveComments() {
    const navigate = useNavigate();

    const [modalForm] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const commentCountRef = useRef([{ type: 1, commentCount: 0 }, { type: 2, commentCount: 0 }, { type: 3, commentCount: 0 }])
    const [commentCount, setCommentCount] = useState(0);

    useEffect(() => {
        getFakeCommentCount().then(res => {
            if (res.status_code === 200 && Array.isArray(res.data)) {
                commentCountRef.current = res.data;
                setCommentCount(res.data.find(i => i.type === 1).commentCount);
            }
        })
    }, [])

    const commentType = Form.useWatch('type', modalForm);
    useEffect(() => {
        if (isModalOpen) {
            setCommentCount(commentCountRef.current.find(i => i.type === (commentType || 1)).commentCount);
        }
    }, [commentType, isModalOpen])

    const params = useParams();
    const conferenceId = parseInt(params?.cid) || 0; // 会议项目 ID
    const liveId = parseInt(params?.id) || 0; // 直播 ID
    const [form] = Form.useForm();
    const refs = useRef()
    const [prodDetails, setProdDetails] = useState(undefined)
    const option = { headers: { "Content-Type": "multipart/form-data" } }
    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [queryObj, setQueryObj] = useState({ name: '' })
    const [liveOneData, setLiveOneData] = useState({})
    // 分页
    const onChangePage = (page, pageSize) => {
        console.log(page);
        setPage(page);
        setPageSize(pageSize);
    };

    const onFinish = (res) => {
        setPage(1)
        setQueryObj({
            commentType: res?.commentType || '',
            memCard: res?.memCard || '',
            content: res?.content || ''
        }
        )
    };

    const onReset = () => {
        form.resetFields();
        setQueryObj({
            commentType: '',
            memCard: '',
            content: ''
        })
        setPage(1)
    };

    const handleRefresh = () => {
        form.resetFields();
        setQueryObj({
            commentType: '',
            memCard: '',
            content: ''
        })
        setPage(1)
    };

    // 获取直播信息
    useEffect(() => {
        getLiveOneByIdApi({ id: liveId }).then((res) => {
            if (res.status_code === 200) {
                setLiveOneData(res.data)
            }
        })
    }, [])

    const queryParams = {
        liveId: liveId,
        page: page,
        pageSize: pageSize
    };
    // 获取评论列表
    useEffect(() => {
        if (queryObj.memCard !== '') queryParams.memCard = queryObj.memCard
        if (queryObj.content !== '') queryParams.content = queryObj.content
        if (queryObj.commentType !== '') queryParams.commentType = queryObj.commentType
        getConferenceLiveCommentsListApi(queryParams).then((res) => {
            if (res.status_code === 200) {
                setData(res.data.list)
                setTotal(res.data.totalCount)
            }
        })
    }, [page, pageSize, queryObj])


    const onLiveCommentDelete = (liveCommentId) => {
        Modal.confirm({
            title: '提示',
            content: '确定删除当前评论？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteConferenceLiveCommentIdApi({ id: liveCommentId }).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                    }
                    navigate('/conf/conferences/' + conferenceId + '/lives/' + liveId + '/comments')
                    getListData()
                })
            }
        });
    }
    // 修改刷新数据列表
    const getListData = () => {
        getConferenceLiveCommentsListApi({ page: 1, pageSize: 10, liveId: liveId }).then((res) => {
            if (res.status_code === 200) {
                setData(res.data.list)
                setTotal(res.data.totalCount)
            }
        })
    };

    const extractImageUrls = (html) => {
        // 定义正则表达式来匹配 <img> 标签的 src 属性
        const regex = /<img\s+[^>]*src=["'](.*?)["']/gi;
      
        // 使用 matchAll 方法获取所有匹配结果
        const matches = [...html.matchAll(regex)];
      
        // 提取每个匹配结果中的 URL
        const urls = matches.map(match => match[1]);
      
        return urls;
      }

      
    const columns = [
        { title: '评论ID', dataIndex: 'id', width: 100 },
        {
            title: '用户昵称', dataIndex: 'nickname', width: 200, render: (_, record) => {
                if (record.commentType == 2) {
                    return (
                        <span style={{ color: '#1890ff' }}>系统发布</span>
                    )
                } else {
                    return (<span>{record.nickname}</span>)
                }
            }
        },
        {
            title: '评论内容', dataIndex: 'content',
            render: (_, record) => {
                const imgList = extractImageUrls(record.content) || [];
                return (
                    <>
                        <div>{record.content.replace(/\<img.*?\/?\>/gi, '').replace(/<\/?[^>]+?\/?>/g, '')}</div>
                        <div>
                            {imgList && !!imgList.length && imgList.map((item, index) => <Image key={index} width={60} height={60} src={item}/>)}
                        </div>
                    </>
                )
            }
        },
        { title: '评论时间', dataIndex: 'createdAt', width: 300 },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 100,
            render: (_, record) => {
                return (
                    <Button type="link" size="small" danger onClick={() => onLiveCommentDelete(record.id)}>删除</Button>
                );
            }
        }
    ];


    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };


    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 13);
            const hide = message.loading("上传中...", 0);
            fileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = refs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };
    const onProdDetailsChange = (v) => {
        setProdDetails(v);
    };
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    [
                        {
                            color: [],
                        },
                    ],
                    [
                        {
                            background: [],
                        },
                    ],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    const [addAnnouncementForm] = Form.useForm();

    const [visibleAddAnnouncementDrawer, setVisibleAddAnnouncementDrawer] = useState(false);

    const showAddAnnouncementDrawer = () => {
        setVisibleAddAnnouncementDrawer(true);
    };

    const okVisibleViewDrawer = () => {
        console.log(addAnnouncementForm.getFieldsValue())
        let saveObj = {
            ...addAnnouncementForm.getFieldsValue(),
            liveId: liveId,
            commentType: 2
        }
        saveLiveCommentApi(saveObj).then((res) => {
            if (res.status_code == 200) {
                addAnnouncementForm.resetFields();
                setVisibleAddAnnouncementDrawer(false);
                message.success(res.message);
                navigate('/conf/conferences/' + conferenceId + '/lives/' + liveId + '/comments')
                getListData()

            }
        })

    }
    const closeAddAnnouncementDrawer = () => {
        addAnnouncementForm.resetFields();
        setVisibleAddAnnouncementDrawer(false);
    };

    const handleOk = () => {
        modalForm.validateFields().then(res => {
            setConfirmLoading(true);
            sendFakeComments({ ...res, liveId }).then(r => {
                if (r.status_code === 200) {
                    handleRefresh();
                    message.success('生成成功');
                    handleCancel();
                }
            }).finally(() => {
                setConfirmLoading(false);
            })
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        modalForm.resetFields();
    };

    return <Fragment>
        <div className="page-title">
            <Link to={`/conf/conferences/${conferenceId}/lives`} className="back"><LeftOutlined /></Link>
            <span className="current-title">评论管理</span>
            {!!liveId && <span className="current-subtitle">{liveOneData.name}</span>}
        </div>

        <Content className="main-content-box">

            <div className="filter-form-box">
                <Form form={form} layout="inline" autoComplete="off" onFinish={onFinish}>
                    <Form.Item name="content">
                        <Input
                            placeholder="评论内容"
                            allowClear
                            style={{ width: 200 }}
                        />
                    </Form.Item>

                    <Form.Item name="commentType">
                        <Select defaultValue={0} style={{ width: 100 }}>
                            <Select.Option value={0}>全部评论</Select.Option>
                            <Select.Option value={1}>用户评论</Select.Option>
                            <Select.Option value={2}>公告</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="memCard">
                        <Input placeholder="用户唯一标识" />
                    </Form.Item>

                    <Space>
                        <Button type="primary" htmlType="submit">检索</Button>
                        <Button onClick={onReset}>重置</Button>
                    </Space>
                </Form>
            </div>

            <div className="main-content-body">

                <div className="main-toolbar">
                    <Space>
                        <Button type="primary" onClick={() => showAddAnnouncementDrawer()}>发布公告</Button>
                        <Button type="primary" onClick={() => setIsModalOpen(true)}>生成随机评论</Button>
                    </Space>
                    <div className="sub-tools">
                        <Tooltip title="刷新列表">
                            <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}></Button>
                        </Tooltip>
                    </div>
                </div>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                />

            </div>

        </Content>

        <Drawer
            title="发布公告"
            width={drawerWidth}
            open={visibleAddAnnouncementDrawer}
            onClose={closeAddAnnouncementDrawer}
            maskClose={false}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeAddAnnouncementDrawer}>取消</Button>
                        <Button type="primary" form="addAnnouncementForm" htmlType="submit"
                            onClick={okVisibleViewDrawer}>确定</Button>
                    </Space>
                </div>
            }
        >
            <Form layout="vertical" form={addAnnouncementForm}>
                <Form.Item
                    label="公告内容"
                    name="content"
                    rules={[
                        { required: true, message: '公告内容不能为空' }
                    ]}
                >
                    <ReactQuill
                        ref={refs}
                        modules={modules}
                        theme="snow"
                        value={prodDetails}
                        onChange={onProdDetailsChange}
                        className="ql-editor  react-editor"
                    />
                </Form.Item>
            </Form>
        </Drawer>

        <Modal title="生成随机评论" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered confirmLoading={confirmLoading}>
            <Form form={modalForm} name='commit' labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} initialValues={{ type: 1 }}>
                <Form.Item name="type" label="类型" required>
                    <Select>
                        <Select.Option value={1}>直播前</Select.Option>
                        <Select.Option value={2}>直播中</Select.Option>
                        <Select.Option value={3}>直播后</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="sendNums" label="评论数量" rules={[{ required: true, message: '请输入评论数量！' }]} extra={`请输入数字，范围为1-${commentCount}`}>
                    <InputNumber min={1} max={commentCount} step={1} precision={0} style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>

    </Fragment>;
}
