import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { pageFormItemWithoutLabelGridLayout, drawerFormItemLayout, drawerWidth } from '@utils/property';
import { Form, Input, Divider, Button, Space, Select, DatePicker, Drawer, message } from 'antd';
import UploadImage from '../Common/UploadImage';
import moment from 'moment';
import { get } from '@/core/request';
const { RangePicker } = DatePicker;

function ProductionBannerAddForm({ orgId, filterObj, onHandleFinish, onHandlePutFinish }, ref) {

    const [form] = Form.useForm();

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [tip, setTip] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [rowData, setRowData] = useState({});
    const [fileList, setFileList] = useState([]);
    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    // 产品线
    const [productId, setProductId] = useState([]);

    const handleChange = (value) => {
        get('/dms/production-lines/queryProductsOrgId', { orgId: value }).then((res) => {
            if (res.status_code === 200) {
                setProductId(res.data);
            }
        })
        form.setFieldsValue({ productId: undefined })
    };

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(tip, row) {
            setRowData(row);
            setTip(tip)
            setVisible(true);
            setFileList([]);
            if (tip === 'edit') {
                get(`/dms/production-lines/banners/findById`, {
                    id: row.id
                }).then((res) => {
                    if (res.status_code === 200) {
                        setImgUrl(res.data.imgUrl)
                        setFileList([{
                            uid: 'rc-upload-1640766007923-3',
                            name: '52.jpg',
                            status: 'done',
                            url: res.data.imgUrl,
                        }]);
                        form.setFieldsValue({
                            name: row.name,
                            orgId: row.orgId,
                            productId: row.productId,
                            link: row.link,
                            time: [moment(res.data.startTime, dateFormat), moment(res.data.endTime, dateFormat)],
                            status: row.status,
                            weight: row.weight
                        })
                    }

                })
            }

        }
    }));

    const handleClose = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                values.startTime = timePicker[0];
                values.endTime = timePicker[1];
                delete values.time;
                values.imgUrl = imgUrl;
                values.updatedByStaffId = filterObj.updatedByStaffId;
                if (!imgUrl) {
                    message.warning('请添加广告图片');
                    return
                }
                if (tip === 'add') {
                    onHandleFinish(values);
                } else {
                    values.id = rowData.id;
                    onHandlePutFinish(values)
                }
                handleClose();
            })
            .catch((err) => { });
        setPedding(false);
    };

    return (
        <Fragment>
            {visible && <Drawer
                closable={false}
                width={drawerWidth}
                onClose={handleClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <div className="main-content-body">
                    <h2>{tip === 'add' ? '新增广告' : '编辑广告'}</h2>
                    <Form {...drawerFormItemLayout} form={form}>
                        <Form.Item
                            name="name"
                            label="广告名称"
                            rules={[{ required: true, message: '请填写广告名称' }]}
                        >
                            <Input placeholder="请输入合作企业名称" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="imgUrl"
                            label="广告图片"
                            help="建议尺寸2400*880px，不小于1200*440px，小于2MB，支持JPG/JPEG/PNG格式"
                            required={true}>
                            <UploadImage name="banner-uploader" size={2} setImgUrl={setImgUrl} fileList={fileList} setFileList={setFileList}></UploadImage>
                        </Form.Item>

                        <Form.Item name="orgId" label="所属企业" rules={[{ required: true, message: '请填写所属企业' }]}>
                            <Select allowClear placeholder="请选择所属企业" onChange={handleChange}>
                                {orgId && orgId.map(item => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item name="productId" label="产品线" rules={[{ required: true, message: '请填写产品线' }]}>
                            <Select allowClear placeholder="请选择产品线">
                                {productId && productId.map(item => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="link"
                            label="跳转链接"
                            rules={[{ required: true, message: '请填写跳转链接' }]}>
                            <Input placeholder="请输入跳转链接" allowClear autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="time"
                            label="上下架时间"
                            rules={[{ required: true, message: '请填写上下架时间' }]}>
                            <RangePicker
                                style={{ width: '100%' }}
                                onChange={handleChangeTime}
                                format="YYYY-MM-DD HH:mm:ss"
                                allowClear
                                showTime />
                        </Form.Item>

                        <Form.Item
                            name="status"
                            label="状态"
                            rules={[{ required: true, message: '请填写状态' }]}>
                            <Select allowClear placeholder="请选择合作状态" >
                                <Select.Option value={0}>未上架</Select.Option>
                                <Select.Option value={1}>已上架</Select.Option>
                                <Select.Option value={2}>已下架</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="weight"
                            label="权重"
                            rules={[{ required: true, message: '请填写权重' }]}>
                            <Input placeholder="请输入权重" allowClear autoComplete="off" />
                        </Form.Item>

                        <Divider />

                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>保存</Button>
                                <Button type="primary" onClick={handleClose}>取消</Button>
                            </Space>
                        </Form.Item>

                    </Form>

                </div>
            </Drawer>}


        </Fragment>
    );
}

export default forwardRef(ProductionBannerAddForm)
