import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _, { values } from 'lodash';
import {
    Table,
    Modal,
    Typography,
    Button,
    Space,
    Input,
    Form,
    Select,
    Image,
    message,
    Upload,
    Alert
} from 'antd';
import { laborFeeExpertsApi, savelaborFeeJoinCertApi } from '@services/SereviseConst';
import './ChooseExpertModal.less';
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { laborFeeCostBearers } from '@utils/constants';
import { pageFormItemLayout } from "@utils/property";
import { prepareBCOSSUrl, convertDateTimeToLocalText } from '@utils/utils';
import { localStorageGet } from '@core/cookie';
import { fileApi } from "@services/Conference";

const { Text } = Typography;

let expertId = null; // 劳务费上传凭证id
let expertName = null; //上传参会凭证选择专家名称

// 选择专家
const ChooseExpertModal = ({ openChooseExpertModal, setOpenChooseExpertModal, setChooseExpertIds }) => {

    const params = useParams();

    const conferenceId = params?.id || 0; // 会议项目 ID

    const [filterForm] = Form.useForm();
    const [uploadForm] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [searchParams, setSearchParams] = useState({ page: 1, pageSize: 10 });

    const [openTips, setOpenTips] = useState(false);
    const [joinCertList, setJoinCertList] = useState([]);
    const [openCertificate, setOpenCertificate] = useState(false);

    const handleCancel = () => {
        setOpenChooseExpertModal(false);
        setSelectedRowKeys([]);
        filterForm.resetFields();
    };

    const handleOk = () => {
        Modal.confirm({
            title: '提示',
            width: 480,
            icon: <ExclamationCircleOutlined />,
            content: `确认为选择的${selectedRowKeys.length}位专家，申请劳务费请款？`,
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                setChooseExpertIds([...selectedRowKeys]);
                setSelectedRowKeys([]);
                filterForm.resetFields();
                setOpenChooseExpertModal(false);
            }
        });
    };

    // 重置
    const onReset = () => {
        filterForm.resetFields();
        setSearchParams({ ...searchParams, ...filterForm.getFieldsValue() });
    };

    // 搜索
    const onFormFinish = () => {
        setSearchParams({ ...searchParams, page: 1, ...filterForm.getFieldsValue() });
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (nowSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(nowSelectedRowKeys);
        },
        getCheckboxProps: (record) => ({ disabled: checkDisableExpert(record) }) // 禁用
    };

    // 检查专家劳务信息是否填写完整
    const checkDisableExpert = (record) => {
        let isEmpty = true;
        let mainland = ['bankCardNo', 'bankName', 'idCard'];  // 大陆
        let noMainland = ['bankCardNo', 'bankName', 'idCard', 'memGender', 'birthPlace', 'birthday', 'idcardType']; // 非大陆
        if (record.idcardType > 1) {
            isEmpty = noMainland.some(item => (item !== 'memGender' && !record[item]) || (item === 'memGender' && record[item] === null)); // 女居然是 0
        } else {
            isEmpty = mainland.some(item => !record[item]);
        }
        if (!record.signTime && !record.joinCert) {
            isEmpty = true;
        }else if(record.signTime && record.checkType === 2 && !record.joinCert){
            isEmpty = true;
        }
        return isEmpty;
    };

    const onChangePage = (page, pageSize) => {
        setSearchParams({ page, pageSize, ...filterForm.getFieldsValue() });
    };

    const onShowSizeChange = (_, pageSize) => onChangePage(1, pageSize);

    // 劳务费列表
    const getList = async () => {
        setLoading(true);
        const res = await laborFeeExpertsApi({
            ...searchParams,
            batchNo: 0,
            projectId: conferenceId,
        });
        if (res.status_code === 200) {
            setData(res?.data?.list || []);
            setTotal(res?.data?.totalCount);
        }
        setLoading(false);
    };

    // 获取专家证件类型
    const getIdcardType = (type) => {
        if (type === 2) return '港澳居民来往内地通行证';
        if (type === 3) return '台湾居民来往大陆通行证';
        if (type === 4) return '外国人永久居留身份证';
        if (type === 5) return '护照';
        return null;
    };

    useEffect(() => {
        if (openChooseExpertModal) {
            getList();
        }
    }, [openChooseExpertModal, searchParams]);

    let columns = [
        { title: '姓名', width: 100, dataIndex: 'memUsername', fixed: 'left' },
        { title: '手机号', dataIndex: 'memMobile', width: 120, },
        {
            title: '单位及职务',
            dataIndex: 'unitPost',
            width: 180,
            // align: 'center',
            render: (_, row) => {
                if (row.workExpList && row.workExpList.length) {
                    return <>
                        {row.workExpList.map((item, idx) => {
                            return <div key={`w_${item.id}`}>
                                {row.workExpList.length > 1 && <span>{idx + 1}. </span>}
                                {!!item.unitName && <span>{item.unitName}</span>}
                                {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                {!!item.unitPost && <span>{item.unitPost}</span>}
                            </div>
                        })}
                    </>
                } else {
                    return '—';
                }
            },
        },
        {
            title: '收款信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {
                    (record.idcardType === 1 || !record.idcardType) && <>
                        <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    </>
                }
                {
                    record.idcardType > 1 && <>
                        <span>证件类型：{getIdcardType(record.idcardType) || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>国籍：{record.nationality || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>出生年月日：{record.birthday || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女')) || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    </>
                }
            </>),
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 120,
            render: (costBearer, record) => (<>
                {costBearer === 1 && <span>华媒康讯</span>}
                {costBearer === 2 && <span>博鳌研究院</span>}
                {costBearer === 3 && <span>尚医文化</span>}
                {costBearer === 4 && <span>尚医咨询</span>}
                {costBearer === 5 && <span>健康界科技</span>}
                {costBearer === 6 && <span>上海分公司</span>}
                {costBearer === 0 && <span>其他</span>}
            </>),
        },
        {
            title: '签到时间',
            dataIndex: 'signedAt',
            width: 120,
            render: (_, record) => (
                <>
                    {record.signTime ? <span>{convertDateTimeToLocalText(record.signTime)}</span> : <Text type="secondary">&ndash;</Text>}
                    {record.signTime && <div>{record.checkType === 1 && '本人签到'}</div>}
                    {record.signTime && <div>{record.checkType === 2 && <Text type="danger">代签</Text>}</div>}
                </>
            )
        },
        {
            title: '参会凭证',
            dataIndex: 'cert',
            align: 'center',
            width: 100,
            render: (_, record) => {
                if (record.joinCert) {
                    return <Image width={100} src={prepareBCOSSUrl(record.joinCert)} />
                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 150,
            fixed: 'right',
            render: (_, record) => (<>
                {canConfirmLaborFeeEntry && <Button size="small" type="link" onClick={() => uploadConferenceCert(record)}>{record.joinCert ? '修改参会凭证' : '上传参会凭证'}</Button>}
            </>)
        }
    ];

    const checkImgUploadFile = (file, sizeMb) => {
        const typeList = ['image/jpg', 'image/jpeg', 'image/png'];
        const isImage = typeList.includes(file.type) || false;

        if (!isImage) {
            message.error('仅支持 JPG/PNG 图片格式');
            return false;
        }

        const metSize = file.size / 1024 / 1024 < sizeMb;
        if (!metSize) {
            message.error(`图片文件大小限${sizeMb}MB`);
            return false;
        }

        return isImage && metSize;
    };

    const uploadImageFile = (fileInfo, type) => {
        const formData = new FormData();
        formData.append('file', fileInfo.file);
        formData.append('type', 14);

        fileApi(formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(async (res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }

                if (res.status_code === 200) {
                    const url = prepareBCOSSUrl(res.data);
                    if (type === 'joinCert') {
                        setJoinCertList([url]);
                        uploadForm.setFieldValue('joinCert', res.data);
                    }
                }
            })
    };

    // 上传/修改参会凭证
    const uploadConferenceCert = (record) => {
        expertId = record.expertId;
        expertName = record.memUsername;
        if (record.joinCert) {
            setJoinCertList([prepareBCOSSUrl(record.joinCert)]);
        }
        setOpenCertificate(true);
    };

    const handleCancelCertificate = () => {
        setOpenCertificate(false);
        setJoinCertList([]);
        expertId = null;
        uploadForm.resetFields();
    };

    // 删除参会凭证
    const deleteImg = () => {
        setJoinCertList([]);
        uploadForm.setFieldValue('joinCert', '');
    };

    // 保存参会凭证
    const handleAddCertificate = () => {
        uploadForm.validateFields()
            .then(async (values) => {
                const res = await savelaborFeeJoinCertApi({
                    projectId: conferenceId,
                    expertId,
                    joinCert: values.joinCert
                });
                if (res.status_code === 200) {
                    message.success('保存成功');
                    setOpenCertificate(false);
                    getList();

                    setJoinCertList([]);
                    expertId = null;
                    uploadForm.resetFields();
                }
            });
    };

    // 是否拥有劳务费录入人员权限
    const canConfirmLaborFeeEntry = useMemo(() => {
        const roleList = localStorageGet('roleList') ? JSON.parse(localStorageGet('roleList')) : [];
        if (roleList && roleList.length) {
            return roleList.some(item => item.name === '劳务费录入人员');
        }
        return false;
    }, []);

    return (
        <>
            <Modal
                title='选择专家'
                open={openChooseExpertModal}
                onOk={handleOk}
                centered
                onCancel={handleCancel}
                className="labor-expert-modal"
            >
                <div className="labor-form-filter">
                    <Form form={filterForm} name="control-hooks" onFinish={onFormFinish}>
                        <Form.Item name="memUsername" label="姓名">
                            <Input placeholder="请输入专家姓名" allowClear />
                        </Form.Item>
                        <Form.Item name="memMobile" label="手机号">
                            <Input placeholder="请输入手机号" allowClear />
                        </Form.Item>
                        <Form.Item name="costBearer" label="费用承担主体">
                            <Select options={laborFeeCostBearers} placeholder="请选择费用承担主体" style={{ width: 180 }} allowClear />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">检索</Button>
                            <Button type="default" onClick={onReset}>重置</Button>
                        </Space>
                    </Form>
                    <div>已选<span className="expert-count">{selectedRowKeys.length}</span>位专家</div>
                </div>

                <Alert
                    type="info"
                    style={{ marginBottom: 16, fontSize: 12 }}
                    message={<span style={{ fontSize: 12 }}>
                        提示：1. 收款信息不完整；2. 签到时间非本人签到，且未上传“参会凭证”的专家不可勾选；
                    </span>}
                />

                <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ x: 1500 }}
                    rowKey={record => record.expertId}
                    rowSelection={{ ...rowSelection }}
                    pagination={{
                        total,
                        showTotal: () => `共${total}条`,
                        current: searchParams.page,
                        pageSize: searchParams.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: onChangePage,
                        onShowSizeChange,
                    }}
                />
            </Modal>

            <Modal
                open={openTips}
                centered
                width={800}
                onCancel={() => setOpenTips(false)}
                footer={null}
                className="laborTipsModal"
            >
                <div>
                    <p>流程简述：</p>
                    <p>会议结束后，根据执行情况在“日程管理”中修正为实际发生的日程 → 进入该会议“嘉宾管理”，选中实际日程有修正的嘉宾对应的“嘉宾任务管理”，点击“确认”进行复核 → 选中该会议“劳务费管理”，查看嘉宾实际日程 →发送“确认实际日程”任务给嘉宾→嘉宾在健康界小程序收到任务通知，点击确认 → 项目经理填写税后劳务费+个人所得税 →内部领导审核税后劳务费→ 财务审核含税劳务总额→ 财务打款→ 线下通知嘉宾。</p>
                    <p style={{ marginTop: '20px' }}>详细步骤：</p>
                    <p>1. 会议结束后，如嘉宾实际发生日程与此前录入日程存在出入，需要在“日程管理”按照VIP嘉宾实际出席日程调整日程模块，并在“嘉宾管理”选中嘉宾，点击“嘉宾任务管理”并“确认”。如实际发生日程无调整可忽略本步骤。</p>
                    <p>2. 点击“发送【确认劳务信息】任务”，给列表中所有嘉宾发送确认实际日程的任务，嘉宾将在健康界小程序“我的项目”看到该任务并可签字、确认、填写劳务信息；</p>
                    <p>3. 根据嘉宾“日程确认状态”，如嘉宾已确认，可填写“税后劳务费”；根据实际需要填写“付款方式+费用承担主体”；</p>
                    <p>4. 由经过系统授权的管理者点击“实发劳务费审批”，审定后“日程管理”模块锁定，不再允许修改，“税后劳务费/元”亦将锁定，不再允许修改；</p>
                    <p>5. 由经过系统授权的财务人员点击“含税劳务费请款审批申请”，审定后“付款方式+费用承担主体”不再允许修改；</p>
                    <p>6. 财务线下打款（暂未上线“线上通知嘉宾打款”）。</p>
                </div>
            </Modal>

            <Modal
                title="上传参会凭证"
                open={openCertificate}
                destroyOnClose={true}
                centered
                width={600}
                maskClosable={false}
                onCancel={handleCancelCertificate}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleCancelCertificate}>取消</Button>
                            <Button type="primary" htmlType="submit" onClick={handleAddCertificate}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    form={uploadForm}
                    layout="vertical"
                    {...pageFormItemLayout}
                >
                    <Form.Item
                        name="userNmae"
                        label="专家姓名"
                    >
                        <span>{expertName}</span>
                    </Form.Item>
                    <Form.Item
                        name="joinCert"
                        label="参会凭证"
                        help="支持JPG/PNG格式，文件大小不超过5MB"
                    >
                        {joinCertList && joinCertList.length > 0 && (
                            <div className="labor-certificate" style={{ marginBottom: 8 }}>
                                <Image width={200} src={joinCertList[0]} />
                                <span onClick={deleteImg} className="close-img"><CloseOutlined /></span>
                            </div>
                        )}

                        <Upload
                            beforeUpload={file => checkImgUploadFile(file, 5)}
                            fileList={[]}
                            customRequest={fileInfo => uploadImageFile(fileInfo, 'joinCert')}
                        >
                            <Button
                                icon={<span className="anticon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                    </svg>
                                </span>}
                            >
                                {joinCertList && joinCertList.length > 0 ? '更改凭证' : '上传凭证'}
                            </Button>
                        </Upload>
                    </Form.Item>

                </Form>

            </Modal>
        </>
    );
}

export default ChooseExpertModal;
