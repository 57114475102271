import { Form, Input, Pagination } from 'antd';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import './index.less';

const Matrix = ({ data, type, option }) => {
    console.log(data);
    const [form] = Form.useForm();
    const [rows, setRows] = useState(undefined);
    const [cols, setCols] = useState(undefined);
    const [total, setTotal] = useState(undefined);
    const [def, setDef] = useState(1);
    const [number, setNumber] = useState(1);
    const [answer, setAnswer] = useState([]);
    useEffect(() => {
        let colRow = JSON.parse(option|| '[]');
        setCols(colRow[1]);
        setRows(colRow[0]);
        let res = data.map((item) => {
            return JSON.parse(item.answer||'[]');
        });
        setTotal(res.length);
        setAnswer(res);
        setDef(1);
    }, []);

    const onChange = (pageNumber) => {
        setDef(pageNumber);
        let num = pageNumber - 1;
        form.setFieldValue('tableRow', answer[num]);
        setNumber(num);
    };
    useLayoutEffect(() => {
        let res = data.map((item) => {
            return JSON.parse(item.answer||'[]');
        });
        console.log(number);
        if (answer.length == 0) {
            form.setFieldValue('tableRow', res[0]);
        } else {
            form.setFieldValue('tableRow', res[number]);
        }
    }, [number]);

    return (
        <div className='martrix_data_box'>
            <div className='martrix-pc'>
                <Form form={form}>
                    <table>
                        <tbody>
                            <tr>
                                <th className='fix_col'></th>
                                {rows && rows.length
                                    ? rows.map((row, rowIndex) => (
                                            <th className='fix_col' key={rowIndex}>
                                                {row}
                                            </th>
                                    ))
                                    : null}
                            </tr>
                            {cols && cols.length
                                ? cols.map((col, colIndex) => (
                                        <tr key={colIndex}>
                                            <td className='fix_row'>{col}</td>
                                            {rows && rows.length
                                                ? rows.map((row, rowIndex) => (
                                                        <td key={rowIndex}>
                                                            <Form.Item name={[`tableRow`, colIndex * rows.length + rowIndex]} label=''>
                                                                <Input maxLength={200} placeholder='请输入' disabled />
                                                            </Form.Item>
                                                        </td>
                                                    ))
                                                : null}
                                        </tr>
                                ))
                                : null}
                        </tbody>
                    </table>
                </Form>
            </div>
            <Pagination className='table_page' current={def} total={total} defaultPageSize={1} showQuickJumper onChange={onChange} />
        </div>
    );
};

export default Matrix;
