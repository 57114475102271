import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { Typography, Drawer, Button, message, Divider, Table, Modal, Tooltip } from 'antd';
import {
    fetchLaborFeePaymentManageList,
    fetchLaborFeePaymentExaminationResult
} from '@services/Finance/FinanceLaborFee.service.js';
import {
    laborFeeExpertsApi,
    laborFeeBatchApplyApi
} from '@services/SereviseConst';
import './LaborFeeRequestDrawer.less';
import { convertDateTimeToLocalText, numberProject } from '@utils/utils';
import { laborFeeCostBearers } from '@utils/constants';
import { get } from '@/core/request';
import config from "@config";
import ChooseExpertModal from './ChooseExpertModal';
import moment from 'moment/moment';

const { Text } = Typography;
const LaborFeeRequestDrawr = ({ isOpen, setIsOpen, conference }) => {
    const navigate = useNavigate();
    const params = useParams();
    let projectCode = 'YHY';
    let projectId = params?.id || 0;

    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openChooseExpertModal, setOpenChooseExpertModal] = useState(false);
    const [chooseExpertIds, setChooseExpertIds] = useState([]);

    // 劳务费请款审批结果
    const [visibleRequestExaminationResult, setVisibleRequestExaminationResult] = useState(false);
    const [examinationResult, setExaminationResult] = useState(null);

    const columns = [
        {
            title: "付款申请",
            width: 200,
            dataIndex: "batchRequestAt",
            render: (_, row) => convertDateTimeToLocalText(row.batchRequestAt)
        },
        {
            title: "实发劳务费(元)",
            dataIndex: "laborFeeAmount",
            width: 150,
            render: (_, row) => (
                <>
                    <div>&yen;{formatCurrency(row.laborFeeAmount)}</div>
                </>
            ),
        },
        {
            title: "相关税费(元)",
            dataIndex: "individualTaxAmount",
            width: 150,
            render: (_, row) => (
                <>
                    <div>&yen;{formatCurrency(row.individualTaxAmount)}</div>
                </>
            ),
        },
        {
            title: "含税劳务费(元)",
            dataIndex: "laborFee",
            width: 150,
            render: (_, row) => (
                <>
                    {<div>&yen;{formatCurrency(parseFloat(row.laborFeeAmount) + parseFloat(row.individualTaxAmount))}</div>}
                </>
            ),
        },
        {
            title: "申请人",
            dataIndex: "batchRequestBy",
            width: 120,
            render: (_, row) => (
                <>
                    {row.batchRequestBy ? <div>{row.batchRequestBy}</div> : <div>-</div>}
                </>
            ),
        },
        {
            title: "审批状态",
            dataIndex: "examinedStatus",
            key: "examinedStatus",
            width: 120,
            render: (_, row) => (
                <>
                    {row.examinedStatus === 0 && <Text type="warning">待审批</Text>}
                    {row.examinedStatus === 1 && <Text type="success">同意</Text>}
                </>
            ),
        },
        {
            title: "付款状态",
            dataIndex: "payedStatus",
            key: "payedStatus",
            width: 120,
            render: (_, row) => (
                <>
                    {row.payedStatus === 0 && <Text type="warning">待付款</Text>}
                    {row.payedStatus === 1 && <Text type="success">付款中</Text>}
                    {row.payedStatus === 2 && <Text type="success">全部付款</Text>}
                </>
            ),
        },
        {
            title: "付款进度",
            dataIndex: "payedCount",
            key: "payedCount",
            width: 120,
            render: (_, row) => (
                <>
                    {row.payedCount}/{row.batchCount}人
                </>
            ),
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            width: 200,
            render: (_, row) => (
                <>
                    {row.examinedStatus === 1 && <Button
                        size="small"
                        type="link"
                        onClick={() => {
                            onViewExaminationResult(row.batchNo)
                        }}
                    >
                        付款审核结果
                    </Button>}
                    {row.examinedStatus !== 1 &&
                        <Tooltip placement="bottom" title="请款审批中，审批通过后再查看">
                            <Button size="small" type="link" disabled>审批结果</Button>
                        </Tooltip>
                    }
                    <Button
                        size="small"
                        type="link"
                        onClick={() => exportLaborAuditPayBatch(row.batchNo)}
                    >
                        导出付款申请明细
                    </Button>
                </>
            ),
        },
    ];

    const exportLaborAuditPayBatch = (batchNo) => {
        const url = `${config.API_PREFIX}/user/laborAudit/pay-batch-infos-download`;
        get(url, {batchNo}, {responseType: "blob"})
            .then(res => {
                if (res.size !== 0) {
                    message.success('请稍后，正在导出');
                    const fileNameSuffix = '_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';
                    let a = document.createElement('a');
                    let event = new MouseEvent('click');
                    a.download = '付款申请明细_' + conference.name + fileNameSuffix;
                    let objectUrl = URL.createObjectURL(res);
                    a.href = objectUrl;
                    a.dispatchEvent(event);
                    URL.revokeObjectURL(objectUrl);
                } else {
                    message.warning('当前列表没有数据，无法导出')
                }
            });

    };

    // 处理金额
    const formatCurrency = value => {
        let result = '';

        const f = parseFloat(value) || 0.00;
        const parts = f.toFixed(2).split('.');
        result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

        return result;
    };


    const onClose = () => {
        setIsOpen(false);
    }

    // 待申请专家列表
    const getExpertsList = async () => {
        const res = await laborFeeExpertsApi({
            page: 1,
            pageSize: 10,
            batchNo: 0,
            projectId: projectId,
        });
        if (res.status_code === 200) {
            if (res?.data?.list && !!res?.data?.list.length) {
                setOpenChooseExpertModal(true);
            } else {
                message.warning('全部专家已申请劳务费付款');
            }
        }
    };
    // 查看劳务费请款审批结果
    const onViewExaminationResult = async (batchNo) => {
        const res = await fetchLaborFeePaymentExaminationResult({ batchNo });
        if (res.status_code === 200) {
            // console.log(res.data);
            const result = res.data;
            if (!!result && !!result.auditInfo) {
                result.auditInfo = JSON.parse(result.auditInfo);
            }
            setExaminationResult(result);
            setVisibleRequestExaminationResult(true);
        }
    };
    // 保存劳务批次申请
    const saveLaborFeeBatchApply = async () => {
        const res = await laborFeeBatchApplyApi({
            projectId: projectId,
            expertIds: chooseExpertIds,
            h3yunNo: conference.h3yunNo
        });
        if (res.status_code === 200) {
            message.success('提交成功');
            getList();
        }
    };
    const getList = async () => {
        setLoading(true);
        fetchLaborFeePaymentManageList({
            projectCode: projectCode,
            projectId: projectId
        }).then(res => {
            if (res.status_code === 200) {
                setDatas(res.data || []);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    }
    // 下载审批结果图片
    const onDownloadExaminationResult = () => {
        const dom = document.getElementById('request-examination-result');
        exportHtmlDomAsImage(dom, '劳务费请款审批结果.png');
    };
    const exportHtmlDomAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el, { useCORS: true });

        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = imageFileName;
        link.href = canvas.toDataURL('image/png', 1.0);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    // 勾选专家
    useEffect(() => {
        if (conference && chooseExpertIds && chooseExpertIds.length) {
            saveLaborFeeBatchApply()
        }
    }, [chooseExpertIds, conference]);

    useEffect(() => {
        if (isOpen) {
            getList()
        }
    }, [isOpen]);

    return (<>
            <Drawer title="提交付款申请" placement="bottom" onClose={onClose} open={isOpen} height={"100%"} width={"100%"}>
                <div className="main-content-body">
                    <Button type="primary" onClick={getExpertsList}>选择本次付款专家范围</Button>
                    <Divider/>
                    <Table
                        columns={columns}
                        dataSource={datas}
                        loading={loading}
                        pagination={null}
                    />
                </div>

            </Drawer>
            <ChooseExpertModal
                openChooseExpertModal={openChooseExpertModal}
                setOpenChooseExpertModal={setOpenChooseExpertModal}
                chooseExpertIds={chooseExpertIds}
                setChooseExpertIds={setChooseExpertIds}
            />
            <Modal
                title="劳务费付款审核结果"
                open={visibleRequestExaminationResult}
                width={800}
                onCancel={() => setVisibleRequestExaminationResult(false)}
                footer={null}
            >
                <div id="request-examination-result" className="project-info">
                    <div className="project-title">{conference?.name}</div>
                    <div className="project-examination-info">
                        <p className="project-info-field0">
                            <span>氚云项目编号：{examinationResult?.h3yunNo}</span>
                            <span>项目编号：{numberProject("YHY", examinationResult?.projectId)}</span>
                            <span>项目创建人：{!!examinationResult?.conferenceCreatedName ? examinationResult?.conferenceCreatedName : '—'}</span>
                        </p>
                        <p className="project-info-field">
                            <span>付款申请人：{examinationResult?.batchApply}</span>
                            <span>付款申请：{examinationResult?.batchNo}</span>
                        </p>
                        <p className="project-info-field">
                            <span>付款审核人：{examinationResult?.auditName}</span>
                            <span>付款审核时间：{examinationResult?.auditTime}</span>
                        </p>
                        <p className="project-sum"><b>{examinationResult?.auditInfo?.conferenceName}</b>付款申请<b>{examinationResult?.batchNo}</b>已由<b>{examinationResult?.auditInfo?.auditUsername}</b>于<b>{examinationResult?.auditInfo?.auditTime}</b>审核确认完毕，共{examinationResult?.auditInfo?.expertCount}位专家需要支付劳务费，其中实发劳务费<b>&yen;{formatCurrency(examinationResult?.auditInfo?.LaborFeeSum)}元</b>，相关税费总计<b>&yen;{formatCurrency(examinationResult?.auditInfo?.IndividualTaxSum)}元</b>，含税劳务费总计<b>&yen;{!!examinationResult?.auditInfo?.LaborFeeSum ? formatCurrency(parseFloat(examinationResult?.auditInfo?.LaborFeeSum) + parseFloat(examinationResult?.auditInfo?.IndividualTaxSum)) : '0.00'}元</b>。</p>
                        {
                            examinationResult?.costBearers && examinationResult.costBearers.map((item, index) => {
                                return <p className="project-part-list" key={index}>
                                    <b>{laborFeeCostBearers.find(b => b.value === item.costBearer)?.label}</b>
                                    <br/>
                                    <span>
                                    <span>共</span>
                                    <b>{item.expertSize}</b>
                                    <span>位专家需要支付劳务费，其中实发劳务费</span>
                                    <b>￥{item.laborFeeAmount}</b>
                                    <span>元，相关税费总计</span>
                                    <b>￥{item.individualTaxAmount}</b>
                                    <span>元，含税劳务费总计</span>
                                    <b>￥{item.laborFeeAmount + item.individualTaxAmount}</b>
                                    <span>元。</span>
                                </span>
                                </p>
                            })
                        }
                    </div>
                </div>
                <div className="project-tools">
                    <Button onClick={onDownloadExaminationResult}>下载审核结果</Button>
                </div>
            </Modal>
        </>
    );
}

export default LaborFeeRequestDrawr;
