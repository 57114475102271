import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { Form, Input, Layout, Button, Divider, Descriptions, Row, Col, Space, Tag, message } from 'antd';
import UploadImage from '../../Common/UploadImage';
import { LeftOutlined } from '@ant-design/icons';
import { get, put } from '@/core/request';
import RichText from './RichText';
import './EditLive.less';

const { Content } = Layout;

export default function EditLive() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const descriptionsLabelGridLayout = {
        wrapperCol: {
            sm: { offset: 1, span: 19 },
            md: { offset: 1, span: 23 },
            lg: { offset: 1, span: 23 },
            xl: { offset: 1, span: 23 },
            xxl: { offset: 1, span: 23 }
        }
    };

    const [form] = Form.useForm();
    const [editData, setEditData] = useState(null);
    // 邀请卡
    const [inviteUrl, setInvitationUrl] = useState('');
    const [inviteFileList, setInviteFileList] = useState([]);
    const [pedding, setPedding] = useState(false);
    // 直播简介
    const [introductionInfo, setIntroductionInfo] = useState("");
    // 嘉宾简介
    const [guestInfo, setGuestInfo] = useState("");
    // 日程介绍
    const [scheduleInfo, setScheduleInfo] = useState("");

    // 渲染table数据
    useEffect(() => {
        get(`/dms/lives/liveDetail`, {
            id: state.liveId,
        }).then((res) => {
            if (res.status_code === 200) {
                const { invitationUrl, name, description, guestIntroduction, schedule } = res.data;
                setEditData(res.data);
                form.setFieldsValue({
                    name: name,
                });
                setInvitationUrl(invitationUrl);//邀请卡
                setIntroductionInfo(description);//直播简介
                setGuestInfo(guestIntroduction);//嘉宾简介
                setScheduleInfo(schedule);//日程介绍
                setInviteFileList([{
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: invitationUrl,
                }]);
            }

        })
    }, [state.liveId, form]);


    const handleClose = () => {
        form.resetFields();
        navigate('/dms/lives');
    };

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((values) => {
                values.id = state.liveId;
                values.invitationUrl = inviteUrl;
                values.guestIntroduction = guestInfo;
                values.schedule = scheduleInfo;
                values.description = introductionInfo;
                if(introductionInfo==='<p><br></p>'){
                    message.warning('请输入直播简介');
                    return
                }
                put('/dms/lives', values).then(res => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        navigate('/dms/lives');
                    }
                });
            })
            .catch((err) => { });
        setPedding(false);
    };

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/dms/lives" className="back"><LeftOutlined /></Link>
                直播详情<span className="page-title-tip">{state.name} [#{state.liveId}]</span>
            </div>

            <Content className="main-content-box">
                {
                    editData && <div className="main-content-body">

                        <Form {...pageFormItemLayout} form={form}>
                            <h2>基本信息</h2>
                            <Form.Item
                                name="name"
                                label="直播标题"
                                rules={[{ required: true, message: '请输入直播标题' }]}>
                                <Input placeholder="请输入直播标题" allowClear  autoComplete="off"/>
                            </Form.Item>

                            <Form.Item {...descriptionsLabelGridLayout} style={{ marginBottom: 0 }}>
                                <Descriptions className="basic-information" column={2}>
                                    <Descriptions.Item label="直播ID">{editData.vhallLiveId}</Descriptions.Item>
                                    <Descriptions.Item label="创建时间">{editData.createdAt}</Descriptions.Item>
                                    <Descriptions.Item label="直播模式">{editData.mode}</Descriptions.Item>
                                    <Descriptions.Item label="直播状态">
                                        {editData.liveStatus === 0 && <span>直播预告</span>}
                                        {editData.liveStatus === 1 && <span>直播中</span>}
                                        {editData.liveStatus === 2 && <span>直播结束</span>}
                                        {editData.liveStatus === 3 && <span>直播回放</span>}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="直播时间">{editData.startTime} ~ {editData.endTime}</Descriptions.Item>
                                    <Descriptions.Item label="直播分类">{editData.parentCateName}</Descriptions.Item>
                                    <Descriptions.Item label="直播标签">{editData.cateName}</Descriptions.Item>
                                    <Descriptions.Item label="直播专题">{editData.topicName}</Descriptions.Item>
                                    <Descriptions.Item label="直播封面" >
                                        <Space direction="vertical" >
                                            <img src={editData.coverUrl} alt="" style={{ width: '350px', height: '212px' }} />
                                            {/* <UploadImage setFileList={setLiveFileList} fileList={liveFileList} size={2} setImgUrl={setCoverImgUrl}></UploadImage> */}
                                            <span className="mock-block">建议尺寸：750*424px，比例16:9，小于2M，支持jpg、png格式</span>
                                        </Space>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Form.Item>

                            <Form.Item
                                label="直播简介"
                                required={true}>
                                {
                                    introductionInfo !== "" && <RichText setRichTextInfo={setIntroductionInfo} quillInfo={introductionInfo}></RichText>
                                }
                            </Form.Item>

                            <Form.Item
                                label="嘉宾简介">
                                {
                                    guestInfo !== "" && <RichText setRichTextInfo={setGuestInfo} quillInfo={guestInfo}></RichText>
                                }
                            </Form.Item>

                            <Form.Item
                                label="日程介绍">
                                {
                                    scheduleInfo !== "" && <RichText setRichTextInfo={setScheduleInfo} quillInfo={scheduleInfo}></RichText>
                                }
                            </Form.Item>

                            <Form.Item
                                name="invitationUrl"
                                help="建议尺寸：750*1334px，小于2M ，支持jpg、png格式"
                                label="邀请卡">
                                <UploadImage setFileList={setInviteFileList} fileList={inviteFileList} size={2} setImgUrl={setInvitationUrl}></UploadImage>
                            </Form.Item>

                            <div className="live-teather">
                                <h2>直播讲师</h2>
                                <Row>
                                    <Col style={{ textAlign: 'right' }} span={3}></Col>
                                    <Col span={21}>
                                        {
                                            editData.lecturers && editData.lecturers.map(item => {
                                                return (
                                                    <Tag key={item.id}>{item.name}<span>{item.phone.replace(/^(\d{4})\d{4}(\d+)/, "$1****$2")}</span></Tag>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </div>

                            <div className="cooperate-unit">
                                <h2>合作单位</h2>
                                <Row>
                                    <Col style={{ textAlign: 'right' }} span={3}></Col>
                                    <Col span={21}>
                                        <Descriptions column={3}>
                                            <Descriptions.Item label="主办单位" >
                                                {
                                                    editData.zunits && editData.zunits.map((item, index) => {
                                                        return (
                                                            <p key={item.id}>{item.name}</p>
                                                        )
                                                    })
                                                }
                                            </Descriptions.Item>
                                            <Descriptions.Item label="协办单位" >
                                                {
                                                    editData.xunits && editData.xunits.map((item, index) => {
                                                        return (
                                                            <p key={item.id}>{item.name}</p>
                                                        )
                                                    })
                                                }
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </div>

                            <div className="user-message">
                                <h2>用户信息</h2>
                                <Descriptions column={4} style={{ paddingLeft: '60px' }}>
                                    <Descriptions.Item label="产品线">{editData.productName}</Descriptions.Item>
                                    <Descriptions.Item label="产品线ID">{editData.productId}</Descriptions.Item>
                                    <Descriptions.Item label="所属企业">{editData.orgName}</Descriptions.Item>
                                    <Descriptions.Item label="企业ID">{editData.orgId}</Descriptions.Item>
                                    <Descriptions.Item label="创建人">{editData.createName}</Descriptions.Item>
                                    <Descriptions.Item label="联系电话">{editData.phone}</Descriptions.Item>
                                    <Descriptions.Item label="微信">{editData.weChat}</Descriptions.Item>
                                    <Descriptions.Item label="邮箱">{editData.mail}</Descriptions.Item>
                                    <Descriptions.Item label="所在部门">{editData.tagName}</Descriptions.Item>
                                    <Descriptions.Item label="职位">{editData.titleName}</Descriptions.Item>
                                </Descriptions>
                            </div>


                            <Divider />

                            <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                                <Space>
                                    <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>保存</Button>
                                    <Button type="primary" onClick={handleClose}>取消</Button>
                                </Space>
                            </Form.Item>

                        </Form>

                    </div>
                }



            </Content>
        </Fragment>
    );
}
