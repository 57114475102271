import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Checkbox, Space, Radio, Modal, Input } from 'antd';
const { TextArea } = Input;

function SurveysPreview({ title, description, endAt, list }, ref) {

    // 新增drawer相关
    const [visible, setVisible] = useState(false);

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show() {
            setVisible(true);
        }
    }));

    const handleClose = () => {
        setVisible(false);
    };

    const handleOk = () => {
        setVisible(false);
    };

    return (
        <Fragment>
            {
                visible && <Modal
                    width="900px"
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    onOk={handleOk}
                    onCancel={handleClose}
                    footer={null}
                >
                    <p className="tip-title">提示：当前页面为预览页面，答题不被记录</p>
                    <div className="preview-content">
                        <h3 className="preview-title">{title}</h3>
                        <p>{description}</p>
                        <p className="preview-time">截止时间：{endAt}</p>
                        {
                            list && list.map((item, index) => {
                                // 单选题
                                if (item.type === 1) {
                                    return (
                                        <div key={item.id} className="radio-preview">
                                            <div className="radio-preview-center">
                                                <span className="radio-preview-num">{index + 1}、{item.name}</span>
                                            </div>
                                            <div className="radio-preview-content">
                                                <Radio.Group>
                                                    <Space direction="vertical">
                                                        {
                                                            item.optionlist && item.optionlist.map((elem, optionIndex) => {
                                                                return (
                                                                    <Radio key={elem.id} value={optionIndex + 1}>{elem.content}</Radio>
                                                                )
                                                            })
                                                        }
                                                    </Space>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    )
                                } else if (item.type === 2) {
                                    return (
                                        <div key={item.id} className="radio-preview">
                                            <div className="radio-preview-center">
                                                <span className="radio-preview-num">{index + 1}、{item.name}</span>
                                            </div>
                                            <div className="radio-preview-content">
                                                <Checkbox.Group style={{ width: '100%' }}>
                                                    <Space direction="vertical">
                                                        {
                                                            item.optionlist && item.optionlist.map((elem, optionIndex) => {
                                                                return (
                                                                    <Checkbox key={elem.id} value={optionIndex + 1}>{elem.content}</Checkbox>
                                                                )
                                                            })
                                                        }
                                                    </Space>
                                                </Checkbox.Group>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={item.id} className="radio-preview">
                                            <div className="radio-preview-center">
                                                <span className="radio-preview-num">{index + 1}、{item.name}</span>
                                            </div>
                                            <div className="radio-preview-content">
                                                <TextArea
                                                    placeholder="请填写答案"
                                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                                    showCount maxLength={50}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </Modal>

            }

        </Fragment>
    );
}

export default forwardRef(SurveysPreview)
