import React, { useEffect, useMemo, useRef, useState } from 'react';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation, useParams } from 'react-router-dom';
import {
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    Modal,
    Progress,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
    Typography,
    Upload
} from "antd";
import { filterFormGridLayout, filterFormItemLayout } from '@utils/property';
import './index.less';
import { signInIdentityListApi } from '@services/Conference/signIn';
import {
    addRegistrationsApi,
    authdictionaryApi,
    caseAddOrganizationApi,
    caseOrganizationApi,
    confirmImportDataApi,
    importDataCheckApi,
    registrationsDeleteApi,
    registrationsListApi,
    registrationsGetOneApi,
    registrationsSetContactApi,
    sendMpMessageApi
} from '@services/Conference/sign';
import debounce from 'lodash/debounce';

const { TextArea } = Input;
const { Text } = Typography;

let initAuthCode = null; // 初始默认选择认证身份

// 报名管理 - 报名列表
const SignUpList = () => {
    const params = useParams();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [identityOptions, setIdentityOptions] = useState([]);
    const [isAddUnitOpen, setIsAddUnitOpen] = useState(false);

    const [isModalContactOpen, setIsModalContactOpen] = useState(false);
    const [importDataOpen, setImportDataOpen] = useState(false);
    const [confirmDataOpen, setConfirmDataOpen] = useState(false);

    const [isModalApplyOpen, setIsModalApplyOpen] = useState(false);

    const [filterForm] = Form.useForm();
    const [settingContactForm] = Form.useForm();
    const [signInAddForm] = Form.useForm();
    const [orgForm] = Form.useForm();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    // 已勾选
    const selectedRowKeysRef = useRef(null);

    const [docList, setDocList] = useState(null);
    const [docProgressPercent, setDocProgressPercent] = useState(undefined);
    const [docShowProgress, setDocShowProgress] = useState(false);

    const importDataRef = useRef(null);

    const [confirmData, setConfirmData] = useState([]);
    const currentEditId = useRef(null);

    // 单位
    const [caseOrgValue, setCaseOrgValue] = useState(null);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const [fetching, setFetching] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [currentAddOrg, setCurrentAddOrg] = useState(null);
    const [authdictionaryList, setAuthdictionaryList] = useState([]);
    const [hasField, setHasField] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);

    const signOptions = [
        {id: 1, name: '是'},
        {id: 0, name: '否'}
    ];

    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            width: 80,
            fixed: 'left'
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: 120,
            fixed: 'left'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 120,
            fixed: 'left'
        },
        {
            title: '会场身份',
            width: 100,
            dataIndex: 'identityName',
            fixed: 'left',
            render: (_, record) => (
                <span>{!!record.identityName ? record.identityName : '待工作人员确认'}</span>
            )
        },
        {
            title: "是否签到",
            width: 80,
            dataIndex: "isSign",
            render: (_, record) => (
                <span>{!!record.isSign ? '是' : '否'}</span>
            ),
        },
        {
            title: '参会嘉宾胸牌数量',
            width: 130,
            dataIndex: 'idBadge',
            render: (_, record) => (
                <span>{!!record.idBadge ? record.idBadge : 0}</span>
            )
        },
        {
            title: '资料袋数量',
            width: 100,
            dataIndex: 'documentBag',
            render: (_, record) => (
                <span>{!!record.documentBag ? record.documentBag : 0}</span>
            )
        },
        {
            title: '自助餐数量',
            width: 100,
            dataIndex: 'mealTicket',
            render: (_, record) => (
                <span>{!!record.mealTicket ? record.mealTicket : 0}</span>
            )
        },
        {
            title: '伴手礼数量',
            width: 100,
            dataIndex: 'souvenir',
            render: (_, record) => (
                <span>{!!record.souvenir ? record.souvenir : 0}</span>
            )
        },
        // {
        //     title: "商务餐券数量",
        //     width: 100,
        //     dataIndex: "bizMealTicket",
        // },
        {
            title: "健康界会员数量",
            width: 120,
            dataIndex: "vip",
            render: (_, record) => (
                <span>{!!record.vip ? record.vip : 0}</span>
            )
        },
        {
            title: "停车券数量",
            width: 100,
            dataIndex: "carTicket",
            render: (_, record) => (
                <span>{!!record.carTicket ? record.carTicket : 0}</span>
            )
        },
        {
            title: "商务简餐数量",
            width: 100,
            dataIndex: "boxedMeal",
            render: (_, record) => (
                <span>{!!record.boxedMeal ? record.boxedMeal : 0}</span>
            )
        },
        {
            title: "参赛选手胸牌（紫绳）数量",
            width: 100,
            dataIndex: "raceBadge",
            render: (_, record) => (
                <span>{!!record.raceBadge ? record.raceBadge : 0}</span>
            )
        },
        {
            title: "参赛选手胸牌（黄绳）数量",
            width: 100,
            dataIndex: "raceBadgeYellow",
            render: (_, record) => (
                <span>{!!record.raceBadgeYellow ? record.raceBadgeYellow : 0}</span>
            )
        },
        // {
        //     title: "VIP思享汇冷餐会数量",
        //     width: 120,
        //     dataIndex: "vipThoughtReunionBuffet",
        // },
        {
            title: "全民健康跑T恤数量",
            width: 150,
            dataIndex: "healthRun",
            render: (_, record) => (
                <span>{!!record.healthRun ? record.healthRun : 0}</span>
            )
        },
        {
            title: "参访海南博鳌乐城医疗旅游先行区数量",
            width: 150,
            dataIndex: "hainanBoAoMedicalTourism",
            render: (_, record) => (
                <span>{!!record.hainanBoAoMedicalTourism ? record.hainanBoAoMedicalTourism : 0}</span>
            )
        },
        {
            title: "参展商胸牌数量",
            width: 120,
            dataIndex: "exhibitorCard",
            render: (_, record) => (
                <span>{!!record.exhibitorCard ? record.exhibitorCard : 0}</span>
            )
        },
        {
            title: "VIP胸牌数量",
            width: 120,
            dataIndex: "vipBadge",
            render: (_, record) => (
                <span>{!!record.vipBadge ? record.vipBadge : 0}</span>
            )
        },
        {
            title: "工作人员胸牌数量",
            width: 130,
            dataIndex: "workerBadge",
            render: (_, record) => (
                <span>{!!record.workerBadge ? record.workerBadge : 0}</span>
            )
        },
        {
            title: "认证身份",
            width: 120,
            dataIndex: "authIdentity",
            render: (_, record) => (
                <>
                    {authdictionaryList.find(item => item.code === record.authIdentity)?.name}
                </>
            ),
        },
        {
            title: "单位",
            width: 120,
            dataIndex: "unit",
        },
        {
            title: "部门",
            width: 120,
            dataIndex: "officeCode",
            render: (_, record) => (
                <>
                    {record.officeCode && getDicName(record.officeCode.split(','), record.authIdentity, 'KSH')}
                </>
            ),
        },
        {
            title: "职务",
            dataIndex: "postCode",
            width: 120,
            render: (_, record) => (
                <>
                    {record.postCode && getDicName(record.postCode.split(','), record.authIdentity, 'ZHW')}
                </>
            ),
        },
        {
            title: "职称",
            width: 120,
            dataIndex: "jobtitleCode",
            render: (_, record) => (
                <>
                    {record.jobtitleCode && getDicName(record.jobtitleCode.split(','), record.authIdentity, 'ZHCH')}
                </>
            ),
        },
        {
            title: "联系人",
            width: 120,
            dataIndex: "contractName",
        },
        {
            title: "联系方式",
            width: 150,
            dataIndex: "contractPhone",
        },
        {
            title: "录入人",
            width: 100,
            dataIndex: "createdBy",
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            width: 140,
            fixed: 'right',
            render: (_, record) => (
                <>
                    <Button type='link' onClick={() => edit(record)} className="edit-btn">编辑</Button>
                    <Button type='link' onClick={() => deleteSignup(record.id)}>删除</Button>
                </>
            ),
        },
        {
            title: () => (
                <Tooltip title="该功能设置发送后会给【订阅】了小程序服务通知的用户发送一条服务通知；但若当前嘉宾未订阅小程序服务通知，即使设置了发送，也收不到服务通知。">
                    发送服务通知 <QuestionCircleOutlined />
                </Tooltip>
            ),
            dataIndex: 'notices',
            width: 120,
            fixed: 'right',
            render: (_, record) => {
                return (
                    <>
                        {record.messageStatus === 2 &&
                            <Tooltip title={record.messageResult}>
                                <Text type="danger" style={{ verticalAlign: 'middle' }}>发送失败</Text>
                                <svg style={{ marginLeft: 4, color: '#888', verticalAlign: 'middle' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                                </svg>
                            </Tooltip>}
                        {record.messageStatus === 1 && <Text type="success">发送成功</Text>}
                        {!record.messageStatus && <span>暂未发送</span>}
                    </>
                );
            }
        }
    ];

    const columnsConfirm = [
        {
            title: "手机号",
            dataIndex: "phone",
            width: 120,
            fixed: 'left'
        },
        {
            title: "姓名",
            dataIndex: "name",
            width: 20,
            fixed: 'left'
        },
        {
            title: "会场身份",
            width: 120,
            dataIndex: "identityName",
            fixed: 'left',
            render: (_, record) => (
                <span>{!!record.identityName ? record.identityName : '待工作人员确认'}</span>
            )
        },
        {
            title: "参会嘉宾胸牌数量",
            width: 130,
            dataIndex: "idBadge",
        },
        {
            title: "资料袋数量",
            width: 100,
            dataIndex: "documentBag",
        },
        {
            title: "自助餐数量",
            width: 100,
            dataIndex: "mealTicket",
        },
        {
            title: "伴手礼数量",
            width: 100,
            dataIndex: "souvenir",
        },
        // {
        //     title: "商务餐券数量",
        //     width: 100,
        //     dataIndex: "bizMealTicket",
        // },
        {
            title: "健康界会员数量",
            width: 120,
            dataIndex: "vip",
        },
        {
            title: "停车券数量",
            width: 100,
            dataIndex: "carTicket",
        },
        {
            title: "商务简餐数量",
            width: 100,
            dataIndex: "boxedMeal",
        },
        {
            title: "参赛选手胸牌（紫绳）数量",
            width: 100,
            dataIndex: "raceBadge",
        },
        {
            title: "参赛选手胸牌（黄绳）数量",
            width: 100,
            dataIndex: "raceBadgeYellow",
        },
        // {
        //     title: "VIP思享汇冷餐会数量",
        //     width: 120,
        //     dataIndex: "vipThoughtReunionBuffet",
        // },
        {
            title: "全民健康跑T恤数量",
            width: 150,
            dataIndex: "healthRun",
        },
        {
            title: "参访海南博鳌乐城医疗旅游先行区数量",
            width: 150,
            dataIndex: "hainanBoAoMedicalTourism",
        },
        {
            title: "参展商胸牌数量",
            width: 120,
            dataIndex: "exhibitorCard",
        },
        {
            title: "VIP胸牌数量",
            width: 120,
            dataIndex: "vipBadge",
        },
        {
            title: "工作人员胸牌数量",
            width: 130,
            dataIndex: "workerBadge",
        },
        {
            title: "认证身份",
            width: 100,
            dataIndex: "authIdentity"
        },
        {
            title: "单位",
            width: 120,
            dataIndex: "unit",
        },
        {
            title: "部门",
            width: 120,
            dataIndex: "officeCode",
        },
        {
            title: "职务",
            width: 120,
            dataIndex: "postCode",
        },
        {
            title: "职称",
            width: 120,
            dataIndex: "jobtitleCode",
        },
        {
            title: "提示",
            width: 150,
            dataIndex: "msg",
        }
    ];

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };

    // 发送服务通知
    const sendMpMessage = async () => {
        const res = await sendMpMessageApi({conferenceId: params.id});
        if(res && res.status_code === 200){
            message.success("操作成功，服务通知正在发送中...");
            getList({ page, pageSize, ...searchParams });
        }
    };

    // 设定联系人
    const settingContact = () => {
        if (selectedRowKeysRef.current && selectedRowKeysRef.current.length) {
            setIsModalContactOpen(true);
        } else {
            return message.error('请先勾选需要设定用户');
        }
    };

    // 设定联系人弹框
    const handleContactOk = () => {
        settingContactForm.validateFields().then((async (values) => {
            const res = await registrationsSetContactApi({
                ...values,
                ids: selectedRowKeysRef.current
            });
            if (res && res.status_code === 200) {
                setIsModalContactOpen(false);
                message.success('保存成功');
                settingContactForm.resetFields();
                getList({ page, pageSize, ...searchParams });
            }
        }));
    };

    // 关闭设定联系人弹框
    const handleContactCancel = () => {
        setIsModalContactOpen(false);
        settingContactForm.resetFields();
    };

    // 删除
    const deleteSignup = async (delId) => {
        const res = await registrationsDeleteApi({ id: delId });
        if (res && res.status_code === 200) {
            message.success('删除成功');
            getList({ page, pageSize, ...searchParams });
        }
    };

    // 筛选查询
    const onFormFinish = (values) => {
        setSearchParams({ ...values});
        setPage(1);
    };

    // 重置
    const onReset = () => {
        filterForm.resetFields();
        setSearchParams({});
        setPage(1);
    };

    // 列表
    const getList = async (reqParams) => {
        setLoading(true);
        const res = await registrationsListApi({ ...reqParams, conferenceId: params.id });
        setLoading(false);
        if (res && res.status_code === 200 && res.data) {
            setData(res.data.list || []);
            setTotal(res.data.totalCount);
        }
    };

    // 获取身份配置列表
    const getIdentityList = async () => {
        const res = await signInIdentityListApi({ conferenceId: params.id });
        if (res && res.status_code === 200) {
            if (res.data && res.data.length) {
                setIdentityOptions(res.data);
            }
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        selectedRowKeysRef.current = newSelectedRowKeys;
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // 模板下载
    const downTemplate = () => {
        window.location.href = 'https://battleship-cloud.oss-cn-beijing.aliyuncs.com/template/conference/%E6%8A%A5%E5%90%8D%E6%A8%A1%E6%9D%BF.xlsx';
    };


    // 导入报名数据
    const importData = () => {
        setImportDataOpen(true);
    };

    // 读取数据
    const handleImportDataOk = () => {
        setConfirmDataOpen(true);
        setImportDataOpen(false);
        setConfirmData(importDataRef.current?.data || []);
    };

    // 关闭导入数据弹框
    const handleImportDataCancel = () => {
        setImportDataOpen(false);
    };

    // 开始导入数据
    const handleConfirmDataOk = async () => {
        setBtnLoading(true);
        const res = await confirmImportDataApi({
            data: importDataRef.current?.data || [],
            conferenceId: params.id,
        });
        setBtnLoading(false);
        if (res && res.status_code === 200) {
            setConfirmDataOpen(false);
            message.success(`共读取${importDataRef.current?.total}条，成功导入${importDataRef.current?.right}条，未导入${importDataRef.current?.error}条`);
            getList({ page, pageSize, ...searchParams });
        }
    };

    // 关闭导入确认数据弹框
    const handleConfirmDataCancel = () => {
        setConfirmDataOpen(false);
    };

    const fileProps = {
        name: "file",
        customRequest: async (info) => {
            const isJpgOrPng = info.file.type === "application/vnd.ms-excel" || info.file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

            const isLt30M = info.file.size / 1024 / 1024 <= 10;
            if (!isJpgOrPng) {
                message.error("只能上传 xls、xlsx 格式!");
                return;
            }
            if (!isLt30M) {
                message.error("文件大小不能超过10M");
                return;
            }

            let formData = new FormData();
            formData.append("fileName", info.file);
            formData.append("conferenceId", params.id);

            setDocShowProgress(true);
            let configParams = {
                onUploadProgress: (Progress) => {
                    let percents = Math.round((Progress.loaded * 100) / Progress.total)
                    setDocProgressPercent(percents);  // 更新进度条
                }
            };

            importDataCheckApi(formData, configParams).then((res) => {
                if (res && res.status_code === 200 && res.data) {
                    setDocShowProgress(false);
                    setDocProgressPercent(undefined);
                    let docItem = { name: info.file.name };
                    setDocList(docItem);
                    importDataRef.current = res.data;
                }
            });
        }
    };

    // 添加报名
    const addApply = () => {
        setIsModalApplyOpen(true);
        // addRegistrationsApi();
    };

    const getKey = (name) => {
        if (name === '参会嘉宾胸牌') {
            return 'idBadge'
        }
        if (name === '自助餐') {
            return 'mealTicket'
        }
        if (name === '资料袋') {
            return 'documentBag'
        }
        if (name === '伴手礼') {
            return 'souvenir'
        }
        // if (name === '商务餐券') {
        //     return 'bizMealTicket'
        // }
        if (name === '健康界会员') {
            return 'vip'
        }
        if (name === '停车券') {
            return 'carTicket'
        }
        if (name === '商务简餐') {
            return 'boxedMeal'
        }
        if (name === '参赛选手胸牌（紫绳）') {
            return 'raceBadge'
        }
        if (name === '参赛选手胸牌（黄绳）') {
            return 'raceBadgeYellow'
        }
        // if (name === 'VIP思享汇冷餐会') {
        //     return 'vipThoughtReunionBuffet'
        // }
        if (name === '全民健康跑T恤') {
            return 'healthRun'
        }
        if (name === '参访海南博鳌乐城医疗旅游先行区') {
            return 'hainanBoAoMedicalTourism'
        }
        if (name === '参展商胸牌') {
            return 'exhibitorCard'
        }
        if (name === 'VIP胸牌') {
            return 'vipBadge'
        }
        if (name === '工作人员胸牌') {
            return 'workerBadge'
        }
    };

    // 会场身份切换 - 身份权益
    const identityChange = (_, row) => {
        const registrationSupplies = identityOptions.find(item => item.id === row.id)?.registrationSupplies;
        if (registrationSupplies) {
            const supplies = JSON.parse(registrationSupplies);
            const fieldValue = supplies.map(item => ({ ...item, code: getKey(item.name), check: true }));
            console.log('fieldValue: ', fieldValue)
            signInAddForm.setFieldValue('registrationSupplies', fieldValue);
        }
    };

    // 获取字典名称
    const getDicName = (dicCode, orgType, fieldType) => {
        const dicCodeList = authdictionaryList.find(item => item.code === orgType)?.labels || [];
        const dicOptions = dicCodeList.find(item => item.code.includes(fieldType))?.options || [];

        if (dicCode && dicCode.length === 1) {
            const target = dicOptions.find(item => item.code === dicCode[0]);
            if (target && target.name) {
                return target.name;
            }
        }
        if (dicCode && dicCode.length === 2) {
            let resName = null;
            const targetFir = dicOptions.find(item => (item.code === dicCode[0]));
            if (targetFir && targetFir.name) {
                resName = targetFir.name;
                const targetSec = targetFir.options.find(item => (item.code === dicCode[1]));
                if (targetSec && targetSec.name) {
                    resName = resName + '/' + targetSec.name;
                }
            }

            return resName;
        }
    };

    // 报名信息编辑
    const edit = async(row) => {
        currentEditId.current = row.id;

        const res = await registrationsGetOneApi({id: row.id});
        if(!!res && res.status_code === 200 && !!res.data){

            setIsModalApplyOpen(true);
            let fieldValues = [];
            let newRow = { ...res.data };
            newRow.jobtitleCode = newRow.jobtitleCode ? newRow.jobtitleCode.split(',') : undefined;
            newRow.officeCode = newRow.officeCode ? newRow.officeCode.split(',') : undefined;
            newRow.postCode = newRow.postCode ? newRow.postCode.split(',') : undefined;
            newRow.phone = res.data.phone;

            // 单位回显
            if (newRow.orgId) {
                setOptions([{ label: newRow.unit, value: newRow.orgId }]);
                setCurrentAddOrg({ label: newRow.unit, value: newRow.orgId });
            }

            // 认证身份 - 展示字段回显
            const labels = authdictionaryList.find(item => item.code === newRow.authIdentity);
            if (labels && labels.length) {
                const fields = labels.map(field => field.name);
                setHasField(fields);
            }

            // 身份权益 - 回显
            const registrationSupplies = identityOptions.find(item => item.id === newRow.identityId)?.registrationSupplies;
            if (registrationSupplies) {
                const supplies = JSON.parse(registrationSupplies);
                const fieldValue = supplies.map(item => ({
                        ...item,
                        code: getKey(item.name),
                        count: newRow[getKey(item.name)] === 0 ? 1 : newRow[getKey(item.name)],
                        check: !!newRow[getKey(item.name)]
                    })
                );
                fieldValues = [...fieldValue];
            }
            signInAddForm.setFieldsValue({ ...newRow, registrationSupplies: fieldValues });
        }

    };

    // 报名信息保存
    const handleApplyOk = () => {
        signInAddForm.validateFields().then(async (values) => {
            let fieldValues = { ...values };
            fieldValues.jobtitleCode = fieldValues.jobtitleCode
                ? fieldValues.jobtitleCode[fieldValues.jobtitleCode.length - 1]
                : null;
            fieldValues.officeCode = fieldValues.officeCode
                ? fieldValues.officeCode[fieldValues.officeCode.length - 1]
                : null;
            fieldValues.postCode = fieldValues.postCode
                ? fieldValues.postCode[fieldValues.postCode.length - 1]
                : null;
            fieldValues.orgId = fieldValues.orgId
                ? fieldValues.orgId
                : null;

            if (fieldValues.registrationSupplies && fieldValues.registrationSupplies.length) {
                fieldValues.registrationSupplies.forEach(item => {
                    if (item.check) {
                        fieldValues[item.code] = item.count;
                    }
                });
            }
            delete fieldValues.registrationSupplies;

            const res = await addRegistrationsApi({
                ...fieldValues,
                conferenceId: params.id,
                id: currentEditId.current || undefined
            });
            if (res.status_code === 200 && res.data) {
                message.success('保存成功');
                setIsModalApplyOpen(false);
                getList({ page, pageSize, ...searchParams });
            }
        })
    };

    const addCaseOrg = () => {
        setIsAddUnitOpen(true);
    };

    const fetchOrgList = async (keyword, type) => {
        setSearchValue(keyword);
        if (!keyword) return [];
        const res = await caseOrganizationApi({ keyword, type: type || undefined });
        if (res.status_code === 200 && res.data && res.data.length) {
            return res.data.map(org => ({
                label: org.hosName,
                value: org.id
            }));
        }
        return [];
    }

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOrgList(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 500);
    }, [fetchOrgList]);

    const handleOrgOk = () => {
        orgForm.validateFields().then(async (values) => {
            const res = await caseAddOrganizationApi({
                ...values,
                // memCard: Cookies.get('authentication_member_card')
            });
            if (res.status_code === 200 && res.data) {
                const currentOrg = { label: res.data.hosName, value: res.data.id };
                setCurrentAddOrg(currentOrg);
                setOptions([currentOrg]);
                setCaseOrgValue(res.data.id);
                signInAddForm.setFieldValue('orgId', res.data.id);

                orgForm.resetFields();
            }
        });
        setIsAddUnitOpen(false);
    };

    const handleOrgCancel = () => {
        setIsAddUnitOpen(false);
        orgForm.resetFields();
    };

    // 报名信息取消
    const handleApplyCancel = () => {
        setIsModalApplyOpen(false);
    };

    // 查询报名配置物料
    // const getSuppliesPage = async () => {
    //     const res = await getSuppliesPageApi({ conferenceId: params.id });
    //     if (res.status_code === 200 && res.data) {
    //         const fieldValue = res.data.filter(item => item.status).map(it => ({ ...it, check: true }));
    //         signInAddForm.setFieldValue('registrationSupplies', fieldValue);
    //     }
    // };

    // 认证身份
    const getAuthdictionaryList = async () => {
        const res = await authdictionaryApi();
        if (res.code === 'A' && res.options && res.options.length) {
            setAuthdictionaryList(res.options);
            initAuthCode = res.options[0].code;
            signInAddForm.setFieldValue('authIdentity', res.options[0].code);
            if (res.options[0]) {
                const fields = res.options[0].labels.map(field => field.name);
                setHasField(fields);
            }
        }
    };

    // 认证身份切换 - 展示不同字段
    const authIdentityChange = (_, val) => {
        signInAddForm.setFieldValue('officeCode', undefined);
        signInAddForm.setFieldValue('postCode', undefined);
        signInAddForm.setFieldValue('jobtitleCode', undefined);
        if (val.labels && val.labels.length) {
            const fields = val.labels.map(field => field.name);
            setHasField(fields);
        }
    };

    // 科室、职务、职称数据列表
    const getCodeOptions = (code) => {
        const orgCode = signInAddForm.getFieldValue('authIdentity');
        if (authdictionaryList && authdictionaryList.length && orgCode) {
            const labels = authdictionaryList.find(item => item.code === orgCode).labels;
            const options = !!labels && labels.find(item => item.code.includes(code))?.options;
            return options || [];
        }
        return [];
    };

    useEffect(() => {
        getList({ page, pageSize, ...searchParams });
    }, [page, pageSize, searchParams]);

    useEffect(() => {
        if (!isModalApplyOpen) {
            currentEditId.current = null;
            signInAddForm && signInAddForm.resetFields();
            signInAddForm.setFieldValue('authIdentity', initAuthCode);
        }
        // 添加报名时，查询配置物料
        if (isModalApplyOpen) {
            getIdentityList();
        }
    }, [isModalApplyOpen]);

    useEffect(() => {
        getAuthdictionaryList();
        getIdentityList();
    }, []);

    return (
        <>
            <div className="main-content-body">

                <div className="signin-detail">
                    <div className="detail-header">
                        <Form form={filterForm} name="filterForm" {...filterFormItemLayout} onFinish={onFormFinish}>
                            <Row>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="name" label="姓名">
                                        <Input placeholder="请输入姓名" />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="phone" label="手机号">
                                        <Input placeholder="请输入手机号" />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name='identityId' label="身份">
                                        <Select placeholder='请选择身份'
                                            options={identityOptions}
                                            fieldNames={{ label: 'name', value: 'id' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name="orgName" label="单位">
                                        <Input placeholder="请输入单位" />
                                    </Form.Item>
                                </Col>
                                <Col {...filterFormGridLayout}>
                                    <Form.Item name='isSign' label="是否签到">
                                        <Select placeholder='请选择是否签到'
                                            options={signOptions}
                                            fieldNames={{ label: 'name', value: 'id' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">检索</Button>
                                        <Button type="default" onClick={onReset}>重置</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <div className="signup-message">
                        <div className="signup-left">
                            <Button type="primary" onClick={addApply}>添加报名</Button>
                            <Button type="primary" ghost onClick={importData}>导入报名数据</Button>
                            <Button type="link" onClick={downTemplate}>下载报名表模板</Button>
                        </div>
                        <div className="signup-right">
                            <Button type="primary" onClick={sendMpMessage}>发送服务通知</Button>
                            <Button type="primary" ghost onClick={settingContact}>设定联系人</Button>
                        </div>
                    </div>

                    <Table
                        rowSelection={rowSelection}
                        rowKey="id"
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        scroll={{ x: 2800 }}
                        size={'middle'}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />

                    <Modal
                        title='设定联系人'
                        open={isModalContactOpen}
                        onOk={handleContactOk}
                        onCancel={handleContactCancel}
                        className="signInModal"
                    >
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 19 }}
                            name="settingContactForm"
                            form={settingContactForm}
                            size="large"
                            autoComplete="off"
                        >
                            <Form.Item name="name" label="联系人" rules={[{ required: true }]}>
                                <Input placeholder="请输入联系人" maxLength={30} />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label="手机号码"
                                rules={[
                                    { required: true, message: '请填写手机号码' },
                                    { pattern: /^1\d{10}$/, message: '请输入正确的手机号码' }
                                ]}>
                                <Input placeholder="请输入手机号" maxLength={11} />
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title='导入报名数据'
                        open={importDataOpen}
                        onOk={handleImportDataOk}
                        onCancel={handleImportDataCancel}
                        className="importDataModal"
                        centered
                        okText="读取数据"
                        okButtonProps={{
                            disabled: !docList?.name,
                        }}
                    >
                        <div>导入文件：
                            <Upload {...fileProps} maxCount={1} fileList={[]}>
                                <Button className="btnWithIcon"
                                    icon={<>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                        </svg>
                                    </>}
                                >
                                    {docList?.name ? '更换文件' : '选择文件'}
                                </Button>
                            </Upload>
                            <div className="fileInfo">
                                {docList && <p>{docList?.name}</p>}
                                {docShowProgress && <Progress percent={docProgressPercent} />}
                                <p>支持Excel格式，文件大小不超过10MB</p>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        title='导入报名数据确认'
                        open={confirmDataOpen}
                        onCancel={handleConfirmDataCancel}
                        className="confirmImportDataModal"
                        centered
                        footer={<>
                            <Button onClick={handleConfirmDataCancel}>取消</Button>
                            <Button type="primary" onClick={handleConfirmDataOk} loading={btnLoading}>开始导入</Button>
                        </>}
                    >
                        <div>
                            <p>共读取到：{importDataRef.current?.total}条，可导入：{importDataRef.current?.right}条</p>
                            <Table
                                columns={columnsConfirm}
                                dataSource={confirmData}
                                scroll={{ x: 2800 }}
                                pagination={false}
                                size={'middle'}
                            />
                        </div>
                    </Modal>

                    <Modal
                        title={currentEditId.current ? '编辑报名' : '添加报名'}
                        open={isModalApplyOpen}
                        onOk={handleApplyOk}
                        onCancel={handleApplyCancel}
                        className="signInModal"
                        width={700}
                    >
                        <Form
                            form={signInAddForm}
                            name="signInAddForm"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 19 }}
                            size="large"
                            autoComplete="off"
                        >
                            <Form.Item name="name" label="姓名" rules={[{ required: true }]}>
                                <Input placeholder="请输入姓名" />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="手机号"
                                rules={[
                                    { required: true, message: '请填写手机号码' },
                                    { pattern: /^1\d{10}$/, message: '请输入正确的手机号码' }
                                ]}>
                                <Input placeholder="请输入手机号" maxLength={11} />
                            </Form.Item>

                            <Form.Item name='identityId' label='会场身份:' rules={[{ required: true }]}>
                                <Select
                                    placeholder='请选择身份'
                                    options={identityOptions}
                                    onChange={identityChange}
                                    fieldNames={{ label: 'name', value: 'id' }}
                                    disabled={currentEditId.current && !!signInAddForm.getFieldValue('isSign')}
                                />
                            </Form.Item>

                            <Form.Item name='authIdentity' label='认证身份:' rules={[{ required: true }]}>
                                <Select
                                    placeholder='请选择身份'
                                    options={authdictionaryList}
                                    onChange={authIdentityChange}
                                    fieldNames={{ label: 'name', value: 'code' }}
                                />
                            </Form.Item>

                            <Form.Item name="orgId" label="单位">
                                <Select
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    onSearch={debounceFetcher}
                                    value={caseOrgValue}
                                    placeholder="输入关键字检索单位"
                                    onChange={(newValue) => {
                                        setCaseOrgValue(newValue);
                                        const targetValue = options && options.length ? options.filter(org => org.value === newValue) : [];
                                        if (targetValue && targetValue.length) {
                                            const currentOrg = { label: targetValue[0].label, value: targetValue[0].value };
                                            setCurrentAddOrg(currentOrg);
                                        }
                                    }}
                                    style={{ width: '100%' }}
                                    notFoundContent={searchValue ? <div style={{ textAlign: 'center' }}>未找到的单位名称，<Button type="link" onClick={addCaseOrg}>点击新增单位</Button></div> : null}
                                    options={options}
                                />
                            </Form.Item>

                            {!!hasField.includes('科室') &&
                                <Form.Item name="officeCode" label="部门">
                                    <Cascader
                                        placeholder='请选择部门'
                                        showSearch
                                        options={getCodeOptions('KSH')}
                                        fieldNames={{ label: 'name', value: 'code', children: 'options' }}
                                    />
                                </Form.Item>
                            }

                            {!!hasField.includes('职务') &&
                                <Form.Item name="postCode" label="职务">
                                    <Cascader
                                        placeholder='请选择职务'
                                        showSearch
                                        options={getCodeOptions('ZHW')}
                                        fieldNames={{ label: 'name', value: 'code', children: 'options' }}
                                    />
                                </Form.Item>
                            }

                            {!!hasField.includes('职称') &&
                                <Form.Item name="jobtitleCode" label="职称">
                                    <Cascader
                                        placeholder='请选择职称'
                                        showSearch
                                        options={getCodeOptions('ZHCH')}
                                        fieldNames={{ label: 'name', value: 'code', children: 'options' }}
                                    />
                                </Form.Item>
                            }

                            <Form.Item noStyle shouldUpdate>
                                {({getFieldValue}) => {
                                    return  !!signInAddForm.getFieldValue('registrationSupplies') && !!signInAddForm.getFieldValue('registrationSupplies').length && <>
                                        <Form.Item
                                            name="registrationSupplies"
                                            label="身份权益"
                                        >

                                            <Form.List name="registrationSupplies">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restFields }, index) => (
                                                            <div key={index} style={{ display: 'flex', marginBottom: 10 }} >
                                                                <Form.Item
                                                                    {...restFields}
                                                                    name={[name, 'check']}
                                                                    style={{ marginBottom: 0 }}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Checkbox style={{ width: 120 }} disabled={currentEditId.current && !!signInAddForm.getFieldValue('isSign')}>{signInAddForm.getFieldValue('registrationSupplies')[index]?.name}</Checkbox>
                                                                </Form.Item>
                                                                {signInAddForm.getFieldValue('registrationSupplies')[index]?.check && <Form.Item
                                                                    {...restFields}
                                                                    className='sign-up-form-item-count'
                                                                    name={[name, 'count']}
                                                                    label={`${signInAddForm.getFieldValue('registrationSupplies')[index]?.name}数量`}
                                                                    rules={[{
                                                                        required: true,
                                                                        validator: async (rule, value) => {
                                                                            if (signInAddForm.getFieldValue('registrationSupplies')[index]?.check && (!(/^\+?[0-9]\d*$/).test(value) || value === '0')) {
                                                                                throw '请输入正整数'
                                                                            }
                                                                        }
                                                                    }]}
                                                                    style={{ marginBottom: 0 }}
                                                                >
                                                                    <Input placeholder="请输入" style={{ width: 100 }} disabled={currentEditId.current && !!signInAddForm.getFieldValue('isSign')}/>
                                                                </Form.Item>}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Form.Item>
                                    </>
                                }}
                            </Form.Item>

                        </Form>
                    </Modal>

                    <Modal title="新增单位" open={isAddUnitOpen} onOk={handleOrgOk} onCancel={handleOrgCancel}>
                        <Form form={orgForm} name="orgForm" autoComplete="off">
                            <Form.Item label="单位名称" name="hosName">
                                <Input placeholder="输入单位名称" />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>

            </div>
        </>
    );
}

export default SignUpList;
