import React, { Fragment, useState, useEffect,forwardRef,useImperativeHandle} from 'react';
import { Switch } from 'antd';
import ColorPicker from './ColorPicker';
import { saveSettingApi, querySettingApi } from "@services/Wj/Question";
import { useLocation } from 'react-router-dom';
import config from '@config';
import './index.less';
const opinions = [
    {
        value: '12',
        label: '12',
    },
    {
        value: '14',
        label: '14',
    },
    {
        value: '15',
        label: '15',
    },
    {
        value: '16',
        label: '16',
    },
    {
        value: '18',
        label: '18',
    },
    {
        value: '20',
        label: '20',
    },
    {
        value: '22',
        label: '22',
    },
    {
        value: '24',
        label: '24',
    },
    {
        value: '26',
        label: '26',
    },
    {
        value: '28',
        label: '28',
    },
];
const Facadesetting = ({change,displayTitle,setDisplayTitle,displayCopyright,setDisplayCopyright,displayLogo,setDisplayLogo,color,setColor,selectColor,setSelectColor,setting}) => {
    const location=useLocation()

    const [send,setSend]=useState({})


    const handleClick = () => {
        setSelectColor(!selectColor);
    };

    const handleClose = () => {
        setSelectColor( false );
        let titleColor=JSON.stringify(send);
        if (location.state.route === 'newCreate') {
            saveSettingApi({titleColor:titleColor,surveyId:location.state.id, type: 1})
        } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            saveSettingApi({titleColor:titleColor,surveyId:location.state.id, type: 2})
        }

    };

    const onChangeColor = (colorObj) => {
        setSend({hex:colorObj.hex,color:colorObj.rgb})
        setColor(colorObj.rgb)

    }

    const settingStatus = () => {
        querySettingApi({ id: location.state.id, type: location.state.route === 'newCreate' ? 1: 2 }).then(
            (res) => {
                if (res.status_code === 200) {
                    res.data.titleColor=JSON.parse(res.data.titleColor);
                    res.data.titleColor&&setColor(res.data.titleColor.color|| {
                        r: '255',
                        g: '255',
                        b: '255',
                        a: '1',
                    });
                    setDisplayTitle(res.data.isDisplayTitle);
                    setDisplayLogo(res.data.isDisplayLogo);
                    setDisplayCopyright(res.data.isDisplayCopyright);
                }
            })
    }

    const changeOptions=(type,checked)=>{
        switch(type){
            case 'isDisplayTitle':
                if (!checked){
                setColor(
                    {
                        r: '255',
                        g: '255',
                        b: '255',
                        a: '1',
                    }
                )

                let obj={hex:'#FFFFFF',color: {
                    r: '255',
                    g: '255',
                    b: '255',
                    a: '1',
                }}
                let titleColor=JSON.stringify(obj);
                if (location.state.route === 'newCreate') {
                    saveSettingApi({isDisplayTitle:Number(checked),titleColor:titleColor,surveyId:location.state.id, type: 1}).then(res=>{
                        if(res.status_code === 200) {
                            settingStatus()
                        }
                    })
                } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({isDisplayTitle:Number(checked),titleColor:titleColor,surveyId:location.state.id, type: 2}).then(res=>{
                        if(res.status_code === 200) {
                            settingStatus()
                        }})
                }

                }else{
                    if (location.state.route === 'newCreate') {
                        saveSettingApi({isDisplayTitle:Number(checked),surveyId:location.state.id, type: 1}).then(res=>{
                            if(res.status_code === 200) {
                                settingStatus()
                            }})
                    } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                        saveSettingApi({isDisplayTitle:Number(checked),surveyId:location.state.id, type: 2}).then(res=>{
                            if(res.status_code === 200) {
                                settingStatus()
                            }})
                    }
                }
                setDisplayTitle(checked)
                break;
            case 'isDisplayLogo':
                if (location.state.route === 'newCreate') {
                    saveSettingApi({isDisplayLogo:Number(checked),surveyId:location.state.id, type: 1}).then(res=>{
                        if(res.status_code === 200) {
                            settingStatus()
                        }})
                } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({isDisplayLogo:Number(checked),surveyId:location.state.id, type: 2}).then(res=>{
                        if(res.status_code === 200) {
                            settingStatus()
                        }})
                }
                setDisplayLogo(checked);
                break;
            case 'isDisplayCopyright':
                if (location.state.route === 'newCreate') {
                    saveSettingApi({isDisplayCopyright:Number(checked),surveyId:location.state.id, type: 1}).then(res=>{
                        if(res.status_code === 200) {
                            settingStatus()
                        }})
                } else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
                    saveSettingApi({isDisplayCopyright:Number(checked),surveyId:location.state.id, type: 2}).then(res=>{
                        if(res.status_code === 200) {
                            settingStatus()
                        }})
                }
                setDisplayCopyright(checked);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if(location.state.route==='newCreate'){
            querySettingApi({ id: location.state.id, type: 1 }).then((res) => {
                if(res.status_code==200){
                    res.data.titleColor=JSON.parse(res.data.titleColor);
                    res.data.titleColor&&setColor(res.data.titleColor.color|| {
                        r: '255',
                        g: '255',
                        b: '255',
                        a: '1',
                    });
                    setDisplayTitle(res.data.isDisplayTitle);
                    setDisplayLogo(res.data.isDisplayLogo);
                    setDisplayCopyright(res.data.isDisplayCopyright);
                }
            })
        }else if (location.state.route === 'newList' || location.state?.route === 'newTemplate') {
            querySettingApi({ id: location.state.id, type: 2 }).then((res) => {
                if(res.status_code==200){
                    res.data.titleColor=JSON.parse(res.data.titleColor);
                    res.data.titleColor&&setColor(res.data.titleColor.color|| {
                        r: '255',
                        g: '255',
                        b: '255',
                        a: '1',
                    });
                    setDisplayTitle(res.data.isDisplayTitle);
                    setDisplayLogo(res.data.isDisplayLogo);
                    setDisplayCopyright(res.data.isDisplayCopyright);
                }
            })

        }
        },[setting]);
    return (
        <Fragment>
            <div className='cover-setting'>
                <div className='settiing-title'>封面设置</div>
                <div>
                    <div className='setting-box'>
                        <div className='has-title'>
                        <span>标题显示在封面</span>
                        <Switch className='bg-switch' checked={displayTitle}  checkedChildren='开' unCheckedChildren='关'  onChange={(checked) => changeOptions('isDisplayTitle',checked)}/>
                        </div>
                        {displayTitle?
                            <div className='font-group'>
                                {/* <Input.Group compact > */}
                                    <ColorPicker className='color-picker-box'
                                        selectColor={selectColor}
                                        color={color}
                                        handleClick={handleClick}
                                        handleClose={handleClose}
                                        onChangeColor={onChangeColor}
                                        />
                                    {/* <Select options={opinions} defaultValue={26} className='cover-fontsize'></Select> */}
                                {/* </Input.Group> */}
                            </div>:''
                       }

                        <div className='has-logo'>
                        <span>logo显示在封面</span>
                        <Switch className='bg-switch' checked={displayLogo}  checkedChildren='开' unCheckedChildren='关'  onChange={(checked) => changeOptions('isDisplayLogo',checked)}/>
                        </div>
                        <div className='has-copyright'>
                        <span>版权说明显示在封面</span>
                        <Switch className='bg-switch' checked={displayCopyright}  checkedChildren='开' unCheckedChildren='关'  onChange={(checked) => changeOptions('isDisplayCopyright',checked)}/>
                        </div>
                        {/* <div className='has-covercolor'>
                            <span>封面按钮使用颜色</span>
                        </div>
                        <div className='has-covercolor-setting' onClick={changeColor}></div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default forwardRef(Facadesetting);
