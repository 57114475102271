import { message, Drawer, Form, Radio, Input, Space, Button, Upload } from 'antd';
import { drawerWidth, fullWidthPageFormItemLayout } from '@utils/property';
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { useState, useEffect, Fragment } from 'react';
import { uploadFileApi } from '@services/SiteModule';
import '../../SiteModules.less';
import config from '@config';

// 线上展区
export default ({
    drawerState,
    setDrawerState,
    currentModule,
    updateModuleSettings,
}) => {
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);
    const [fileImgList, setFileImgList] = useState([]);

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 上传头像 - logo
    const upload = async (value, index) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let list = [{ url: getFileUrl(res.data) }];
            let newList = [...fileList];
            newList[index] = list;

            let currentValue = form.getFieldValue('exhibitionList');
            console.log(form.getFieldValue('exhibitionList'));
            let newValue = currentValue;
            console.log(newValue, index)
            newValue[index].logo = list;

            form.setFieldValue('exhibitionList', newValue);
            setFileList(newList);
        };
    };

    // 上传图片 - 展区
    const uploadImg = async (value, index) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('type', 14);
        const res = await uploadFileApi(formData);
        if (res && res.status_code === 200) {
            let list = [{ url: getFileUrl(res.data) }];
            let newList = [...fileImgList];
            newList[index] = list;

            let currentValue = form.getFieldValue('exhibitionList');
            let newValue = currentValue;
            newValue[index].img = list;

            form.setFieldValue('exhibitionList', newValue);
            setFileImgList(newList);
        };
    };

    // 上传头像格式校验
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('只能上传 jpg/png/jpeg 格式');
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('图片大小不超过5MB');
            return false;
        }
        return isJpgOrPng && isLt5M;
    };

    const closeDrawer = () => setDrawerState({ ...drawerState, visible: false });

    // 新增模块
    const onFinish = async (values) => {
        console.log(values);
        let params = {
            ...values,
            id: currentModule.id,
            data: JSON.stringify({ exhibitionList: dealFileUrl(values.exhibitionList) })
        };
        delete params.exhibitionList;
        updateModuleSettings(params);
    };

    // 过滤oss前缀
    const delOssUrl = (str) => {
        if (str && str.includes(config.OSS_PREFIX)) {
            let re = new RegExp(config.OSS_PREFIX + "(.*)")
            return str.match(re)[1];
        }
        return '';
    };

    // 处理文件前缀
    const dealFileUrl = (list) => {
        if (list && list.length) {
            let newList = list.map(item => {
                let newItem = JSON.parse(JSON.stringify(item));
                newItem.logo = Array.isArray(newItem.logo) && newItem.logo.length ? delOssUrl(newItem.logo[0].url) : newItem.logo;
                newItem.img = Array.isArray(newItem.img) && newItem.img.length ? delOssUrl(newItem.img[0].url) : newItem.img;
                return newItem;
            })
            return newList;
        }
        return [];
    };

    // 图片回显添加前缀
    const addOssUrl = (imgList) => {
        if (imgList && imgList.length) {
            let newList = imgList.map(item => {
                let newItem = { ...item };
                if (newItem.url && !newItem.url.includes(config.OSS_PREFIX)) {
                    newItem.url = config.OSS_PREFIX + newItem.url;
                }
                return newItem;
            });
            return newList;
        }
        return [];
    };

    const swapItems = (index1, index2) => {
        let logoArr = [...fileList];
        logoArr[index1] = logoArr.splice(index2, 1, logoArr[index1])[0];
        setFileList(logoArr);

        let imgArr = [...fileImgList];
        imgArr[index1] = imgArr.splice(index2, 1, imgArr[index1])[0];
        setFileImgList(imgArr);
    }

    useEffect(() => {
        if (currentModule && drawerState?.visible) {
            let exhibitionList = [];
            if (currentModule.data) {
                let data = JSON.parse(currentModule.data);
                exhibitionList = data.exhibitionList || [];

                if (exhibitionList && exhibitionList.length) {
                    let logoList = [];
                    let imgList = [];
                    exhibitionList.forEach((item, index) => {
                        logoList[index] = addOssUrl([{url: item.logo}]);
                        imgList[index] = addOssUrl([{url: item.img}]);
                    });

                    setFileList(logoList);
                    setFileImgList(imgList);
                }
            }
            form.setFieldsValue({
                displayTitle: currentModule.displayTitle,
                displayTitleEn: currentModule.displayTitleEn,
                hiddenWeb: currentModule.hiddenWeb,
                hiddenH5: currentModule.hiddenH5,
                status: currentModule.status,
                path: currentModule.path,
                exhibitionList
            });
        }
    }, [currentModule]);

    return (
        <Drawer
            title={`模块配置：${currentModule?.displayTitle}`}
            height="100%"
            placement="bottom"
            open={drawerState?.moduleType === 9 && drawerState.visible}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="ExhibitionModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="ExhibitionModuleForm"
                form={form}
                {...fullWidthPageFormItemLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="displayTitle"
                    label="显示标题"
                    rules={[
                        { required: true, message: '显示标题不能为空' }
                    ]}
                >
                    <Input placeholder="请输入模块显示标题" />
                </Form.Item>

                <Form.Item name="displayTitleEn" label="英文显示标题">
                    <Input placeholder="请输入模块英文显示标题" />
                </Form.Item>

                <Form.Item
                    name="path"
                    label="访问路径"
                    rules={[
                        { required: true, message: '访问路径不能为空' },
                        { pattern: /^[a-z\d_]{2,20}$/, message: '访问路径不符合格式要求' },
                    ]}
                    help={
                        <>
                            填写访问该模块的访问路径，如会议简介访问路径 <b>introduction</b><br />
                            格式合求：小写字母、数字或下划线（_），限2-20个字符
                        </>
                    }
                >
                    <Input placeholder="请输入模块访问路径" minLength={2} maxLength={20} showCount disabled={currentModule?.moduleType <= 10} />
                </Form.Item>
                <Form.Item
                    label="线上展区"
                    name="exhibitionList"
                >
                    <Form.List name="exhibitionList">
                        {(fields, { add, remove, move }) => (
                            <>
                                <Form.Item>
                                    <Button onClick={() => add()}>
                                        <PlusCircleOutlined />添加展区
                                    </Button>
                                </Form.Item>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div key={key} className="exhibition-add">
                                        <Space key={key} style={{ display: 'flex' }} align="center">
                                            <div style={{ paddingRight: '20px' }}>
                                                <Form.Item
                                                    label="企业名称"
                                                    name={[name, 'name']}
                                                    rules={[
                                                        { required: true, message: '请输入企业名称' },
                                                    ]}
                                                >
                                                    <Input style={{ width: 300 }} placeholder="请输入企业名称" maxLength={50} />
                                                </Form.Item>
                                                <Form.Item label="官网链接" name={[name, 'link']}>
                                                    <Input style={{ width: 300 }} placeholder="请输入官网链接" maxLength={500} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="LOGO"
                                                    name={[name, 'logo']}
                                                    rules={[
                                                        { required: true, message: '请上传logo' },
                                                    ]}
                                                >
                                                    <Upload
                                                        customRequest={(e) => upload(e, index)}
                                                        listType="picture-card"
                                                        fileList={fileList[index]}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                        beforeUpload={beforeUpload}
                                                        accept="image/jpeg, image/png, image/jpg"
                                                    >

                                                        <div>
                                                            <PlusOutlined />
                                                            <div style={{ marginTop: 8 }}>
                                                                {fileList[index] && fileList[index].length ? '更改图片' : '上传图片'}
                                                            </div>
                                                        </div>
                                                    </Upload>
                                                </Form.Item>
                                                <Form.Item
                                                    label="展区图"
                                                    name={[name, 'img']}
                                                    rules={[
                                                        { required: true, message: '请上传展区图' },
                                                    ]}
                                                >
                                                    <Upload
                                                        customRequest={(e) => uploadImg(e, index)}
                                                        listType="picture-card"
                                                        fileList={fileImgList[index]}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                        beforeUpload={beforeUpload}
                                                        accept="image/jpeg, image/png, image/jpg"
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div style={{ marginTop: 8 }}>
                                                                {fileImgList[index] && fileImgList[index].length ? '更改图片' : '上传图片'}
                                                            </div>
                                                        </div>
                                                    </Upload>
                                                </Form.Item>
                                            </div>

                                            <MinusCircleOutlined onClick={() => {
                                                remove(name);

                                                let deleteImgList = fileImgList;
                                                deleteImgList.splice(index, 1);
                                                setFileImgList(deleteImgList);

                                                let deleteFileList = fileList;
                                                deleteFileList.splice(index, 1);
                                                setFileList(deleteFileList);
                                            }} />
                                            {index !== 0 ? <ArrowUpOutlined onClick={() => {
                                                move(index, index - 1);
                                                swapItems(index, index - 1);
                                            }} /> : null}
                                            {index !== fields.length - 1 ? <ArrowDownOutlined onClick={() => {
                                                move(index, index + 1);
                                                swapItems(index, index + 1);
                                            }} /> : null}
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item
                    name="status"
                    label="导航状态"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>敬请期待</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="hiddenWeb"
                    label="Web端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="hiddenH5"
                    label="H5端是否隐藏"
                    rules={[
                        { required: true, },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>显示</Radio>
                        <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>


        </Drawer >
    );
};
