import { get, put, post } from '@core/request';

/*
    医会议--日程管理
*/

/**
 * 日程api
 */

// 获取日程列表
export const getAgendasListApi = (params) => {
    return get('/conference/boss/agendas/list', params);
};

// 获取整个会议项目日程
export const getAllAgendasApi = conferenceId => {
    return get('/conference/boss/agendas/all', { conferenceId });
};

// 新增日程
export const saveAgendaApi = (params) => {
    return post('/conference/boss/agendas/save', params);
};

// 修改日程
export const updateAgendaApi = (params) => {
    return put('/conference/boss/agendas/update', params);
};

export const updateAgendaSettings = data => put('/conference/boss/conferences/update-sgendaSetting', data);

// 日程排序
export const sortAgendaApi = (params) => {
    return put('/conference/boss/agendas/sort', params);
};

// 获取单个日程信息
export const getAgendaDetailApi = (params) => {
    return get('/conference/boss/agendas/getOneById', params);
};

// 删除日程
export const deleteAgendaApi = (params) => {
    return get('/conference/boss/agendas/delete', params);
};

// 日程/日程嘉宾的显示状态
// 日程显示状态 hidden 0, 1
// 日程嘉宾显示状态 guestsHidden 0,1
export const changeAgendaAndGuestsStatusApi = data => {
    return put('/conference/boss/agendas/status', data);
}

/**
 * 会议api
 */

// 获取会议列表
export const getConferenceListApi = (params) => {
    return get('/conference/boss/conferencehalls/list', params);
};

// 新增会议
export const saveConferenceApi = (params) => {
    return post('/conference/boss/conferencehalls/save', params);
};

// 修改会议
export const updateConferenceApi = (params) => {
    return put('/conference/boss/conferencehalls/update', params);
};

// 会议排序
export const sortConferenceApi = (params) => {
    return put('/conference/boss/conferencehalls/sort', params);
};

// 获取单个会议信息
export const getConferenceDetailApi = (params) => {
    return get('/conference/boss/conferencehalls/getOneById', params);
};

// 删除会议
export const deleteConferenceApi = (params) => {
    return get('/conference/boss/conferencehalls/delete', params);
};

// 显示/隐藏会议
export const changeConferenceHallStatusApi = data => {
    return put('/conference/boss/conferencehalls/status', data);
}

// 查询上级会议下拉列表
export const getFirstListConferenceApi = (params) => {
    return get('/conference/boss/conferencehalls/firstList', params);
};

// 查询嘉宾下拉列表
export const getGuestsListApi = (params) => {
    return post('/conference/boss/guests/list', params);
};

// 查询任务下拉列表
export const getAgendaTaskListApi = (params) => {
    return get('/conference/boss/agendatasktypes/list', params);
};


// 获取专家下的日程列表
export const getAgendaguestsApi = (params) => {
    return get('/conference/boss/agendaguests/tasks', params);
};

// 根据机构id返回机构信息
export const getOrganisationinfoApi = (params) => {
    return get(`/dataengine/api/organisationinfo/${params.id}`);
};

// 用户会议导出pdf
export const pdfExportDownloadApi = (params) => {
    return get('/conference/boss/agendas/pdf-export/download', params);
};

// 更新会议
export const updateConferencesApi = (params) => {
    return put('/conference/boss/conferences/update', params);
};

// 获取会议一览list
export const getConferencePlacesOverview = params => get('/conference/boss/conferenceplaces/conferenceList', params);

// 获取会议list
export const getConferencePlaces = (params) => {
    return get('/conference/boss/conferenceplaces/list', params);
}

// 删除会议地点
export const deleteConferencePlaces = (params) => {
    return get('/conference/boss/conferenceplaces/delete', params);
}

// 新增会议地点
export const addConferencePlaces = (params) => {
    return post('/conference/boss/conferenceplaces/save', params);
}

// 修改会议地点
export const updateConferencePlaces = (params) => {
    return put('/conference/boss/conferenceplaces/update', params);
}
