import { Drawer, Form, Input, Space, Button, message, Radio } from 'antd';
import { drawerWidth, drawerFormItemSiteLayout } from '@utils/property';
import TinymceEditor from '../TinymceEditor';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { noticesSaveApi } from '@services/MedProject';
import "./index.less"

export default ({
    isModalOpen,
    setIsModalOpen,
    getNotices
}) => {

    const params = useParams();
    const projectId = params?.id || 0; // 项目 ID

    const [form] = Form.useForm();
    const noticePersonType = Form.useWatch('noticePersonType', form);
    const [editorContents, setEditorContents] = useState('');
    const [initEditorValue, setInitEditorValue] = useState(null);

    const closeDrawer = () => setIsModalOpen(false);

    // 新增通知
    const onFinish = async (values) => {
        console.log(values)
        let reqParam = {
            ...values,
            projectId,
            data: JSON.stringify({ content: editorContents })
        };
        // console.log(reqParam);
        const res = await noticesSaveApi(reqParam);
        if (res.status_code === 200) {
            message.success('新增通知成功');
            setIsModalOpen(false);
            getNotices();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            setEditorContents(null);
            setInitEditorValue(null);
            form.resetFields();
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (noticePersonType === 2 && form.getFieldValue('noticeType') === 2) {
            form.setFieldValue('noticeType', undefined);
        }
    }, [noticePersonType]);

    return (
        <Drawer
            title='发布通知'
            width={drawerWidth}
            open={isModalOpen}
            onClose={closeDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button type="primary" form="RichTextModuleForm" htmlType="submit">确定</Button>
                    </Space>
                </div>
            }
        >
            <Form
                name="RichTextModuleForm"
                form={form}
                {...drawerFormItemSiteLayout}
                onFinish={onFinish}
            >
                <Form.Item
                    name="title"
                    label="通知标题"
                    rules={[
                        { required: true, message: '请输入通知标题' },
                        { max: 20, message: '标题最多为20字' }
                    ]}
                >
                    <Input placeholder="请输入通知标题" minLength={1} maxLength={20} showCount />
                </Form.Item>

                <Form.Item
                    label="通知内容"
                    name="content"
                    className="notice-content"
                    rules={[
                        { required: true },
                        // {
                        //     validator: async (rule, editorContents) => {
                        //         if (editorContents === "") {
                        //             throw "请输入通知内容";
                        //         } else if (editorContents === "<p><br></p>") {
                        //             throw "请输入通知内容";
                        //         }
                        //     },
                        // },
                    ]}
                >
                    {/* <Editor setEditorContents={setEditorContents} editorContents={editorContents} form={form}></Editor> */}
                    <TinymceEditor
                        initEditorValue={initEditorValue}
                        setEditorContents={setEditorContents}
                        editorContents={editorContents}
                        id="editorContents"
                        placeholder="通知内容"
                        form={form}
                        fieldValue='content'
                    />
                </Form.Item>

                <Form.Item
                    name="noticePersonType"
                    label="通知人群"
                    rules={[
                        {
                            required: true,
                            message: '请选择通知人群',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>已加入项目人员</Radio>
                        <Radio value={2}>已加入项目人员+未加入项目人员（未申请加入+加入审核中+申请加入失败）</Radio>
                    </Radio.Group>
                </Form.Item>

                
                <Form.Item
                    name="noticeType"
                    label="通知形式"
                    rules={[
                    {
                        required: true,
                        message: '请选择通知形式',
                    },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={1}>消息通知</Radio>
                        {noticePersonType !== 2 && <Radio value={2}>弹框显示 + 消息通知</Radio>}
                    </Radio.Group>
                </Form.Item>
            </Form>

        </Drawer>
    );
};
