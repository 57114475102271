import React, { Fragment, useState, useEffect } from 'react';
import { Layout, Form, Row, Col, Input, Button, Space, Tree, Select, Menu, Dropdown, Table, message, Modal, TreeSelect, Drawer } from 'antd';
import { get, del, post, patch } from '@/core/request';
import myContextContext from './createContext'; //公共组件


export default function TreeElement(props) {
    const { setClose } = props;
    const x = 3;
    const y = 2;
    const z = 1;
    const [gData, setGData] = useState();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const onCloseDrawer = () => {
        setClose(false)
    }
    const onDragEnter = info => {
        // console.log(info);
    };
    let [num, setNum] = useState(0)
    // const parameter = [];
    const [parameter, setParameter] = useState([]);
    useEffect(() => {
        get(`/channel/channel-group/listTree`).then(res => {
            if (res.status_code == 200) {
                res.data.forEach(element => {
                    element.title = element.name;
                    element.key = element.id
                    element.children.forEach((item => {
                        item.title = item.name;
                        item.key = item.id
                        item.children.forEach((list) => {
                            list.title = list.name;
                            list.key = list.id
                        })
                    }))
                });
                setGData(res.data)
            }
        })
    }, [num])
    const onDrop = info => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...gData];
        let dragObj;
        let dragNodeLen = info.dragNode.pos.split("-").length;
        let nodeLen = info.node.pos.split("-").length;
        let count = dragNodeLen - nodeLen;
        if (dragNodeLen !== nodeLen) {
            if (count === 1 && !info.dropToGap) {
                console.log("第一个位置")
            } else {
                if (parameter.length) {
                    patch("/channel/channel-group/edit-sort", {
                        ids: parameter
                    }).then(res => {
                        if (res.status_code == 200) {
                            setNum(++num)
                        }
                    })
                }
                return
            }
        } else {
            console.log("同级", info)
        }
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            if (count === 1) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.children.unshift(dragObj);
                    setParameter(parameter.concat([...item.children.map((item)=>item.id)]))
                });
                setGData(data)
            }
        } else if (
            (info.node.props.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            console.log(222)
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            console.log(333)
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                console.log("+++")
                ar.splice(i, 0, dragObj);
            } else {
                console.log("===")
                ar.splice(i + 1, 0, dragObj);
                let result = ar.map(item => {
                    return item.id
                })
                setParameter(parameter.concat([...result]))
            }
        }
        setGData(data)
    };
    const allowDrop = (a, b) => {
        console.log(a, b)
        // return !a.dropNode.children
    }
    const save = () => {
        if (parameter.length) {
            patch("/channel/channel-group/edit-sort", {
                ids: parameter
            }).then(res => {
                if (res.status_code == 200) {
                    setClose(false)
                }
            })
        } else {
            setClose(false)
        }
    }
    return (
        <div>
            <Form>
                <div className="drawerTip">
                    <p>拖动排序后，保存。</p>
                    <p>注意：排序只改变同级分类之间的顺序，不改变从属关系。</p>
                </div>
                {gData && <Tree
                    className="draggable-tree"
                    defaultExpandedKeys={expandedKeys}
                    draggable
                    blockNode
                    onDragEnter={onDragEnter}
                    onDrop={onDrop}
                    treeData={gData}
                // allowDrop={allowDrop}
                />}
                <Form.Item>
                    <Space>
                        <Button type="" onClick={onCloseDrawer}>
                            取消
                        </Button>
                        <Button type="primary" onClick={save}>
                            保存
                        </Button>
                    </Space>
                </Form.Item>
            </Form>

        </div>

    )


}
