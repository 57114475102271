import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { drawerWidth } from '@utils/property';
import { Form, Button, Space, Drawer, Descriptions } from 'antd';
import { get } from '@/core/request';

function MemberMaintainDrawer({ }, ref) {
    const [form] = Form.useForm();

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState(null)

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(row) {
            get(`/enterprise/roles/get_staffs`, {
                id: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    setRowData(res.data)
                }
            })

            setVisible(true);
        }
    }));

    const handleClose = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <Fragment>
            {
                visible && <Drawer
                    title={<span style={{ fontWeight: 600 }}>查看成员</span>}
                    closable={false}
                    width={drawerWidth}
                    onClose={handleClose}
                    visible={visible}
                    footer={<Button style={{ float: 'right' }} type="default" onClick={handleClose}>取消</Button>}
                >
                    <div className="main-content-body">
                        {
                            rowData && <Descriptions className="member-information" column={1}>
                                <Descriptions.Item label="角色名称">{rowData.name}</Descriptions.Item>
                                <Descriptions.Item label="角色成员">
                                    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                        {
                                            rowData.staffAllVos && rowData.staffAllVos.map(item => {
                                                if (item.isSelect) {
                                                    return <span key={item.id}>{item.name}<span style={{marginLeft:'5px'}}>[{item.email}]</span></span>
                                                }
                                            })
                                        }
                                    </Space>
                                </Descriptions.Item>
                            </Descriptions>
                        }


                    </div>
                </Drawer>
            }
        </Fragment>

    )
}

export default forwardRef(MemberMaintainDrawer)
