import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Table, Form, Row, Col, InputNumber, Select, message, Modal, Radio, Input, Layout} from 'antd'
import {LeftOutlined} from '@ant-design/icons';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {fullPagerConfig} from '@utils/property';
import {get, post, put} from '@core/request';
import './KbResearchHospitalVertifyDataForm.less'

const {TextArea} = Input;
const {Content} = Layout;

const filterFormGridLayout = {
    sm: {span: 20},
    md: {span: 10},
    xl: {span: 4},
    xxl: {span: 4},
};
const filterFormItemLayout = {
    labelCol: {
        sm: {span: 12},
        md: {span: 10},
    },

    wrapperCol: {
        sm: {span: 12},
        md: {span: 14},
    }
}
// 0草稿 1 已提交待审核 2 审核中 3 审核通过 4 审核未通过
const recordStatusMap = new Map([
    [0, '草稿'],
    [1, '草稿'],
    [2, '审核中'],
    [3, '审核通过'],
    [4, '审核未通过'],
])
// 复核信息类型（1：医院，2：学科，3：人才）====>  18：人才，19：医院，20：学科
const checktypeMap = new Map([
    [1, 19],
    [2, 20],
    [3, 18],
])
// {/* 1 单选 2 多选 3 判断 4 填空 5 问答 */}
const typeMap = new Map([
    [1, '单选题'],
    [2, '多选题'],
    [3, '判断题'],
    [4, '填空题'],
    [5, '问答题'],
    [6, '问答题'],
])


const FillFormHead = (props) => {
    const {
        topic = '', hospital = '', subject = '', personal = '', isPass = undefined, id,
        fillDataBackStatus = 0, phone, checkStatus, recordId , year , allExamineColumns
    } = props
    const navigate = useNavigate()
    const [backText, setBackText] = useState('退回')
    const [isDisabled, setIsDisabled] = useState(false)
    useEffect(() => {
        if (fillDataBackStatus) {
            setBackText('已退回')
            setIsDisabled(true)
        }
    }, [])
    const sendBack = () => {
        Modal.confirm({
            onOk: () => getreback({checkId: id, type: 2, phone}),
            content: '退回让用户修改',
            maskClosable: false
        })
    }

    const getback = (data) => {
        get('/kb/questionsRecordsChecks/exitCheckByRecordId', data).then(res => {
            if (res.status_code === 200) {
                navigate('/kb/rearch')
            }
        })
    }
    const getreback = (data) => {
        post('/kb/checkInformations/send-message', data).then(res => {
            if (res.status_code === 200) {
                setBackText('已退回')
                setIsDisabled(true)
            }
        })
    }

    const allExamine = () =>{
        let data = {
            checkStatus: 1, //审核状态（0：不通过，1：通过）
            recordId: year==='2022'?recordId:id, //答题记录ID
        }
        Modal.confirm({
            content:
                <p>
                    <b>确认后所有未审核的题目全部审核通过，</b>
                    <span style={{color:'red'}}>并且审核状态不能修改，</span>你还要继续吗？
                </p>,
            cancelText: '继续',
            okText: '取消',
            // closable: true,
            onCancel:  () => {
                if(checkStatus > 2){
                    message.error('暂无未审核的题目')
                }else{
                    put('/kb/questionsRecordsChecks/batch-check', data).then(res => {
                        if (res.status_code === 200) {
                            message.success('未审核的题目已全部审核通过')
                            allExamineColumns()
                        }
                    })
                }
            }
        });


    }
    return <div className='F-head wrap'>
        <div className='left'>
            {topic ? <p>申报主题：{topic}</p> : ''}
            {hospital ? <p>申报医院：{hospital}</p> : ''}
            {subject ? <p>申报学科：{subject}</p> : ''}
            {personal ? <p>申报人：{personal}</p> : ''}
        </div>
        <div className='right'>
           {checkStatus > 1 && <Button type='primary' ghost onClick={() => allExamine()}>未审核批量审核通过</Button> }
            <Button type='primary' onClick={() => getback({id, type: 1})}>退出审核</Button>
            {isPass ? <Button type='danger' disabled={isDisabled} onClick={sendBack}>{backText}</Button> : ''}
        </div>

    </div>
}

const KbResearchHospitalVertifyDataForm = () => {

    // 每页多少条
    const [pageSize, setPageSize] = useState(10);
    // 当前页码
    const [currPage, setCurrPage] = useState(1);
    // 表格数据
    const [tableData, setTableData] = useState({});
    // 分组数据
    const [firstIndexList, setFirstIndexList] = useState([]);
    const [secondIndexList, setSecondIndexList] = useState([]);
    // 筛选条件
    const [firstIndex, setFirstIndex] = useState('')
    const [secondIndex, setSecondIndex] = useState('')
    const [checkTypeParams, setCheckTypeParams] = useState('')
    const [type, setType] = useState('')
    const [markType, setMarkType] = useState('')
    // 已审核题目数量
    const [checkNum, setCheckNum] = useState(0)
    // 是否显示退回按钮
    const [isPass, setIsPass] = useState(0)
    // 当前点击的是通过还是不通过
    const [currenClickBtn, setCurrenClickBtn] = useState(1)
    // 当前选择的行
    const [row, setRow] = useState({})
    // 驳回原因文本内容
    const [textAreaValue, setTextAreaValue] = useState('')
    // 是否显示弹框
    const [checkVertifyVisible, setCheckVertifyVisible] = useState(false)
    // form
    const [form] = Form.useForm();
    // 跳转函数
    const navigate = useNavigate()
    // 本地location数据
    const {state} = useLocation()
    const {
        theme, orgName, subjectName, personName, recordStatus,
        recordId, checkType, fillDataBackStatus, memCard, phone, id
    } = state

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemList, setItemList] = useState([]);

    const [checkStatus,setCheckStatus] = useState(undefined);
    const showModal = (item) => {
        setIsModalOpen(true);
        setItemList(JSON.parse(item));
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 首次获取数据
    useEffect(() => {
        // console.log(state, 'state');
        // getIsShowBack({id: recordId})
        getCheckNum()
        getFilterCondition({examId: state.year === '2022' ? checktypeMap.get(checkType) : state.examPaperId, type: 1});
        getFilterCondition({examId: state.year === '2022' ? checktypeMap.get(checkType) : state.examPaperId, type: 2});
    }, [])

    // 接口数据处理函数
    const getData = useCallback((data) => {
        get('/kb/questionsRecords/questions-list', data).then(res => {
            if (res.status_code === 200) {
                const {list} = res.data;
                list.forEach(item => {
                    item.key = item.questionId;
                    if (!item.extra) item.extra = '/'
                });
                setTableData(res.data);
            } else {
                setTimeout(() => {
                    navigate('/kb/rearch')
                }, 2000)
            }
        })
    }, [navigate])
    // const getIsShowBack = (data) => {
    //     get('/kb/questionsRecordsChecks/queryCheckByRecordId', data).then(res => {
    //         if (res.status_code === 200) {
    //             setIsPass(res.data)
    //         }
    //     })
    // }
    const getFilterCondition = (data) => {
        get('/exam/group/query-groups', data).then(res => {
            if (res.status_code === 200) {
                if (data.type === 1) {
                    setFirstIndexList(res.data)
                } else {
                    setSecondIndexList(res.data)
                }
            }
        })
    }
    const getCheckNum = () => {
        get('/kb/questionsRecordsChecks/getCheckCount', {recordId: state.year === '2022' ? recordId : id}).then(res => {
            if (res.status_code === 200) {
                setCheckNum(res.data.count)
                setCheckStatus(res.data.dataFillingAuditStatus)
            }
        })
    }
    const PostCheckVertify = (data, r) => {
        // console.log(r);
        if (!data.checkStatus && !data.rejectCause) {
            message.error('驳回原因不能为空')
            return
        }
        if (!data.checkStatus && data.rejectCause.length > 500) {
            message.error('驳回原因字数不能超过500字')
            return
        }
        post('/kb/questionsRecordsChecks/save', data).then(res => {
            if (res.status_code === 200) {
                // 处理回调
                setTextAreaValue('')
                setCheckVertifyVisible(false)
                getCheckNum()
                // getIsShowBack({id: recordId})
                getData({
                    id: state.year === '2022' ? recordId : state.id,
                    firstIndex,
                    secondIndex,
                    markType,
                    checkType: checkTypeParams,
                    type,
                    year: state.year,
                    limit: pageSize,
                    page: currPage
                })
            }
        })
    }
    const PostScore = (data, status) => {
        post('/exam/examine-answer/score', data).then(res => {
            if (res.status_code === 200) {
                switch (status) {
                    case 'big':
                        message.error('赋分值不能大于满分')
                        break;
                    case 'low':
                        message.error('赋分值不能小于零')
                        break;
                    default:
                        message.success('打分成功')
                        break;
                }
            }
        })
    }
    const getback = (data) => {
        get('/kb/questionsRecordsChecks/exitCheckByRecordId', data).then(res => {
            if (res.status_code === 200) {
                navigate('/kb/rearch')
            }
        })
    }


    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
            setCurrPage(1)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    // 查看更多
    const seeMore = (data, title) => [
        Modal.info({
            content: data,
            maskClosable: true,
            centered: true,
            title: title,
            icon: '',
        })
    ]

    // 失去焦点保存数据
    const onBlurHandler = (val, row) => {
        // console.log(val, row);
        // if(row.checkStatus !== 1){
        //     message.error('题目审核未通过，不可进行赋分')
        //     return
        // }
        if (!val) return
        let status = 'normal'
        let score = val.replace(/([0-9]+\.[0-9]{2})[0-9]*/g, '$1').replace(/\.$/, '')
        if (score > row.weight) {
            status = 'big'
            score = row.weight
        } else if (score < 0) {
            status = 'low'
            score = 0
        }
        PostScore({
            examId: state.year === '2022' ? checktypeMap.get(checkType) : state.examPaperId,
            extraSubject: state.year === '2022' ? recordId : state.id,
            memcard: memCard,
            questionId: row.questionId,
            score: Number(score)
        }, status)
    }
    const downloadFile = (data) => {
        let x = new XMLHttpRequest();
        x.open("GET", data.url, true);
        x.responseType = 'blob';

        x.onload = function (e) {
            var url = window.URL.createObjectURL(x.response)
            var a = document.createElement('a');
            a.href = url
            a.download = data.title;
            a.click()
        }
        x.send();
    };
    // 单选处理
    const radioChangeHandler = (e) => {
        setCurrenClickBtn(e.target.value)
    }
    const examine=(e)=>{
        setCurrenClickBtn(0)
        setCheckVertifyVisible(true)
        setRow(e)
        setTextAreaValue('')
    }
    const itemColumns = [
        {title: '附件名称', dataIndex: 'title',width: 400},
        {title: '操作', dataIndex: 'ex',width:200,render: (ex,row)=>(
                <Fragment>
                    {row.title.split('.').pop()==='zip' ||row.title.split('.').pop()==='ppt' || row.title.split('.').pop() === 'pptx' || row.title.split('.').pop()==='docx' || row.title.split('.').pop()==='doc' ?<a className="operate-style" onClick={()=>downloadFile(row)} target="_blank">下载</a>
                        :<Fragment>
                            <a className="operate-style" href={row.url} target="_blank">查看</a>
                            <a className="operate-style" onClick={()=>downloadFile(row)} target="_blank">下载</a>
                        </Fragment>
                    }
                </Fragment>
            )
        },
    ]
    const columns = [
        {title: '一级指标', dataIndex: 'firstIndex',render:(firstIndex,row)=>(
                <Fragment>
                    {firstIndex !=null ? <span>{firstIndex}</span>:<span>/</span>}
                </Fragment>
            )},
        {title: '二级指标', dataIndex: 'secondIndex',render:(secondIndex,row)=>(
                <Fragment>
                    {secondIndex !=null ? <span>{secondIndex}</span>:<span>/</span>}
                </Fragment>
            )},
        {title: '三级指标', dataIndex: 'threeIndex',render:(dataIndex,row)=>(
                <Fragment>
                    {dataIndex !=null ? <span>{dataIndex}</span>:<span>/</span>}
                </Fragment>
            )},
        {
            title: '填报指标', dataIndex: 'question', render: res => {
                return <p dangerouslySetInnerHTML={{__html: res}}></p>
            }
        },
        {
            title: '用户答案', dataIndex: 'questionType', render: (res, row) => {
                let result = ''
                if (res === 1 || res === 2 || res === 3) {
                    result = row?.optionContent || '/'
                } else {
                    result = row?.content || '/'
                }
                if (result?.length >= 10) {
                    return <span className='seeMore' onClick={() => seeMore(result, '答案')}>查看更多</span>
                } else {
                    return result
                }
            }
        },
        {
            title: '证明材料', dataIndex: 'extra', width: 70, render: (res) => {
                if (res === '/') return '/'
                else if (res.length < 5) return '/'
                else return <a onClick={() => showModal(res)}>点击查看</a>
            }
        },
        {
            title: '题型', dataIndex: 'questionType', width: 60, render: (res) => {
                return typeMap.get(res)
            }
        },
        {
            title: '审核操作', dataIndex: 'checkStatus', width: 94, render: (res, r) => {
                if (res === null) {
                    return (<div className='checkBtn'><span onClick={() => {
                        setTextAreaValue('')
                        setCurrenClickBtn(1)
                        // setCheckVertifyVisible(true)
                        setRow(r)
                        PostCheckVertify({
                            answersId: r.answersId, //答题详情ID
                            checkStatus: 1, //审核状态（0：不通过，1：通过）
                            questionId: r.questionId, //题号ID
                            recordId: state.year==='2022'?recordId:state.id, //答题记录ID
                        }, r)
                    }}>通过</span><span onClick={() => examine(r)}>不通过</span></div>)
                } else if (res) {
                    return '通过'
                } else {
                    return '不通过'
                }
            }
        },
        {
            title: '退回原因', dataIndex: 'rejectCause', render: (res) => {
                if (res?.length >= 10) {
                    return <span className='seeMore' onClick={() => seeMore(res, '退回原因')}>查看更多</span>
                } else {
                    return res
                }
            }
        },
        {title: '填报指标满分分值', dataIndex: 'weight',},
        {
            title: '赋分', dataIndex: 'answerScore', render: (res, row) => {
                return (<InputNumber className='answerScore_input' type="number" min={0} max={row.weight}
                                        defaultValue={res === null ? undefined : res}
                                        formatter={v => v.replace(/([0-9]+\.[0-9]{2})[0-9]*/g, '$1').replace(/\.$/, '')}
                                        onBlur={(e) => onBlurHandler(e.target.value, row)}
                />)
            }
        }
    ];

    const filterColumns = (arr, cols) => {
        return cols.reduce((v, i) => {
            if (!Array.from(arr).includes(i.dataIndex)) v.push(i)
            return v
        }, [])
    }

    //分页数据
    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page)
        },
        current: currPage,
    };

    // 审核全部
    const allExamineColumns = () => {
        getData({
            id: state.year === '2022' ? recordId : id,
            firstIndex,
            secondIndex,
            markType,
            checkType: checkTypeParams,
            type,
            limit: pageSize,
            page: currPage,
            year: state.year
        })
        getCheckNum()
    };

    useEffect(() => {
        setCurrPage(1)
    }, [firstIndex, secondIndex, markType, checkTypeParams, type, pageSize])

    // 处理筛选数据
    useMemo(() => {
        getData({
            id: state.year === '2022' ? recordId : id,
            firstIndex,
            secondIndex,
            markType,
            checkType: checkTypeParams,
            type,
            limit: pageSize,
            page: currPage,
            year: state.year
        })
    }, [firstIndex, secondIndex, markType, checkTypeParams, type, pageSize, currPage, recordId, getData])

    return <div className='fillForm-wrap'>
        <div className="page-title">
            <Link to="/kb/rearch" className="back"
                  onClick={() => getback({id: recordId, type: 1})}><LeftOutlined/></Link>数据填报审核
        </div>
        <Content className="main-content-box">
            <FillFormHead
                topic={theme}
                hospital={orgName}
                subject={subjectName}
                personal={checkType === 3 ? personName : null}
                isPass={isPass}
                id={id}
                fillDataBackStatus={fillDataBackStatus}
                phone={phone}
                recordId={recordId}
                checkStatus={checkStatus}
                year={state.year}
                allExamineColumns={allExamineColumns}
            ></FillFormHead>

            {/* 填报数据状态 */}
            <div className='fillDataStatus-wrap wrap'>
                <p>填报数据状态：<span>{recordStatusMap.get(checkStatus)}</span></p>
                <p>已审核题目数：<span>{checkNum}</span></p>
            </div>

            {/* 筛选数据 */}
            <div className='filterData-wrap wrap'>
                <Form {...filterFormItemLayout} form={form}>
                    <Row gutter={16}>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="一级指标" name="firstIndex">
                                <Select allowClear placeholder="全部" value={firstIndex}
                                        onChange={(v) => setFirstIndex(v)}>
                                    <Select.Option value={''}>全部</Select.Option>
                                    {firstIndexList.length ? firstIndexList.map((item, ind) => {
                                        return <Select.Option value={item} key={ind}>{item}</Select.Option>
                                    }) : <Select.Option value={2}>全部</Select.Option>}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="二级指标" name="secondIndex">
                                <Select allowClear placeholder="全部" value={secondIndex}
                                        onChange={(v) => setSecondIndex(v)}>
                                    <Select.Option value={''}>全部</Select.Option>
                                    {secondIndexList.length ? secondIndexList.map((item, ind) => {
                                        return <Select.Option value={item} key={ind}>{item}</Select.Option>
                                    }) : <Select.Option value={2}>全部</Select.Option>}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="题型" name="questionType">
                                <Select allowClear placeholder="全部" value={type} onChange={(v) => setType(v)}>
                                    {/* 1 单选 2 多选 3 判断 4 填空 5 问答 */}
                                    <Select.Option value={''}>全部</Select.Option>
                                    <Select.Option value={1}>单选题</Select.Option>
                                    <Select.Option value={2}>多选题</Select.Option>
                                    <Select.Option value={3}>判断题</Select.Option>
                                    <Select.Option value={4}>填空题</Select.Option>
                                    <Select.Option value={5}>问答题</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="审核状态" name="checkStatus">
                                <Select allowClear placeholder="全部" value={checkTypeParams}
                                        onChange={(v) => setCheckTypeParams(v)}>
                                    <Select.Option value={''}>全部</Select.Option>
                                    <Select.Option value={3}>未审核</Select.Option>
                                    <Select.Option value={4}>已审核</Select.Option>

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="赋分状态" name="answerScoreStaus">
                                <Select allowClear placeholder="全部" value={markType} onChange={(v) => setMarkType(v)}>
                                    <Select.Option value={''}>全部</Select.Option>
                                    <Select.Option value={0}>未打分</Select.Option>
                                    <Select.Option value={1}>已打分</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </div>

            {/* 填报数据展示 */}
            <Table className="chat-table wrap"
                    columns={recordStatus === 0 ? filterColumns(['checkStatus', 'answerScore', 'rejectCause'], columns) : columns}
                    dataSource={tableData.list}
                    pagination={paginationProps}
                    bordered={true}
            />

            {/* 审核是否通过弹窗 */}
            {checkVertifyVisible ? <Modal
                visible={checkVertifyVisible}
                onCancel={() => setCheckVertifyVisible(false)}
                onOk={() => PostCheckVertify({
                    answersId: row.answersId, //答题详情ID
                    checkStatus: currenClickBtn, //审核状态（0：不通过，1：通过）
                    questionId: row.questionId, //题号ID
                    recordId: state.year === '2022' ? recordId : state.id, //答题记录ID
                    rejectCause: textAreaValue //驳回原因
                })}
            >
                <div className='checkModal_wrap'>
                    <p>审核</p>
                    <div className='content'>
                        {/* <div className='btn'>
                            <Radio.Group onChange={(e) => radioChangeHandler(e)} value={currenClickBtn}>
                                <Radio value={1}>审核通过</Radio>
                                <Radio value={0}>审核不通过</Radio>
                            </Radio.Group>
                        </div> */}
                        {!currenClickBtn ? <div className='con'>
                            <span>驳回原因</span>
                            <TextArea maxLength={50} style={{width: 200,height:100}} rows={6} placeholder="请输入驳回原因（必填）最多输入50个字！" value={textAreaValue}
                                      onChange={(e) => setTextAreaValue(e.target.value)}/>
                        </div> : null}
                    </div>
                </div>
            </Modal> : null}
            <Modal title="附件列表" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Table
                    rowKey={item=>item.url}
                    columns={itemColumns}
                    dataSource={itemList}
                    pagination={false}
                />
            </Modal>
        </Content>
    </div>
}

export default KbResearchHospitalVertifyDataForm


