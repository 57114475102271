import React,{useState} from 'react';
import { HolderOutlined } from '@ant-design/icons';
import { Button,Space } from 'antd';
import {arrayMoveImmutable} from 'array-move';
import { SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import { sortTopicsApi } from "@services/Selection";
import './SortTopic.less';

const SortTopic = ({tableData,onReset}) => {
    const [data,setData] = useState(tableData);
    const SortableMainContainer = SortableContainer(({items}) => {
        return (<div className='main_content_wrap'>
            { items.length ? items.map((item,index) =>
                <SortableMainElement itemData={item} index={index} key={index} sort={index}></SortableMainElement>
            ) : null }
        </div>)
    })
    const SortableMainHandle = SortableHandle(() => {
        return <Button type="text" size="small"><HolderOutlined /></Button>
    })
    const SortableChildContainer = SortableContainer(({childItems, paSort}) => {
        return (<div className='child_container_wrap'>
            { childItems?.length ? childItems.map((item,index) =>
                <SortableChildElement childData={item} paSort={paSort} index={index} key={index}></SortableChildElement>
            ) : null }
        </div>)
    })
    const SortableMainElement = SortableElement(({itemData, sort}) => {
        let hasAdress = itemData?.questionList?.some(it => it.questionTypeId === 7) || false
        return <div className='topics_item_wrap'>
        <div className='topics_wrap'>
            <Space>
                <SortableMainHandle></SortableMainHandle>
            </Space>
            <span>{itemData.name}</span>

        </div>
        <SortableChildContainer useDragHandle paSort={sort} childItems={itemData.children || itemData.children} distance={10} onSortEnd={(res) => onSortEndChildHandler(res,itemData.id)}></SortableChildContainer>
    </div>
    })

    const SortableChildElement = SortableElement(({childData, paSort}) => <div className='topics_wrap topics_child_item_wrap'>
        <Space>
            <SortableMainHandle></SortableMainHandle>
        </Space>
        <span>{childData.name || childData.title}</span>

    </div>)

    const onSortEndHandler = ({oldIndex, newIndex}) => {
        let res = arrayMoveImmutable(data, oldIndex, newIndex)
        console.log(res);
        setData(res)
        sortTopicsApi(res).then((sort) =>{
            if(sort.status_code === 200){
                onReset()
            }
        })
    }

    const onSortEndChildHandler = ({oldIndex, newIndex}, a) => {
        let newData = data, ind = data.findIndex(it => it.id === a)
        let items = data[ind].children
        newData[ind].children = arrayMoveImmutable(items, oldIndex, newIndex)
        setData(newData.slice())
        sortTopicsApi(newData.slice()).then((res) =>{
            if(res.status_code === 200){
                onReset()
            }
        })
    }
    return (
        <div>
            <SortableMainContainer useWindowAsScrollContainer={false} useDragHandle distance={10} items={data} onSortEnd={onSortEndHandler} helperClass='testClass'>
            </SortableMainContainer>
        </div>
    );
}

export default SortTopic;
