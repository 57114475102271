import React, { useState, useEffect, useRef } from 'react';
import { LeftOutlined } from "@ant-design/icons";
import { useLocation, Link, useParams, useSearchParams } from 'react-router-dom';
import { Layout, Tabs } from "antd";
import SignUpList from './components/List';
import SignUpSetting from './components/Setting';
import SignIn from '../SignIn';
import { conferenceOnceApi } from '@services/Conference';
import './index.less';

const { Content } = Layout;

// 报名管理
const SignUp = () => {
    const location = useLocation();

    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const conferenceId = params?.id || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({id: conferenceId});
        if (res.status_code === 200) {
            setConference(res.data);
        }
    };

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
        }
    }, []);

    const tabsRef = useRef([
        { label: '报名设置', key: '1', children: <SignUpSetting/> },
        { label: '报名列表', key: '2', children: <SignUpList/> },
        { label: '签到管理', key: '3', children: <SignIn/> },
        // { label: '报名统计', key: 'item-2', children: '内容 2' },
    ]);

    const onChange = (key) => {
        console.log(key);
    };

    return (
        <>
            <div className="page-title">
                <Link to="/conf/conferences" className="back"><LeftOutlined/></Link>
                <span className="current-title">报名签到</span>
                <span className="current-subtitle">{conference?.name}</span>
            </div>

            <Content className="main-content-box">
                <Tabs
                    className="sign-up-tabs"
                    defaultActiveKey={!!searchParams.get('type') ? searchParams.get('type') : '1'}
                    size="large"
                    tabBarGutter={50}
                    onChange={onChange}
                    items={tabsRef.current}
                />
            </Content>
        </>
    );
}

export default SignUp;
