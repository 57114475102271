import React, { Fragment } from 'react';
import { Input, Button, Form, Modal, message } from 'antd';

const { TextArea } = Input;

const Addwhite = ({ addOpen, setAddOpen,getData,phoneArr,setPhoneArr,onOk}) => {
    const [form] = Form.useForm();

    const resetData = () => {
        form.resetFields()
    }

    const onCancel = () => {
        setAddOpen(false)
        resetData()
    }
    const deleteItem = (item) =>{
        let delarr=[...phoneArr]
        let delitem=phoneArr.findIndex(it=>it===item)
        delarr.splice(delitem,1)
        setPhoneArr(delarr)  
    }

    const onOkBtn = () => {
        let str = form.getFieldsValue().opinion
        if(!str) {
            message.info('未输入内容')
            return
        }
        let arr = str.split('\n')
        let bool = true, index = 0, newArr = []
        const reg=/^1[3-9]\d{9}$/
        arr.forEach((item, ind) => {
            if(!bool) return
            if(!item) return
            if(!reg.test(item)){
                bool = false
                index = ind + 1
            }
            newArr.push(item)
        })
        if(!bool){
            message.info(`第 ${index} 行输入有误，请检查后再提交`)
            return
        }
        onOk(newArr)
        resetData()
    }

    return (
        <Fragment>
            <Modal
                open={addOpen}
                title='添加白名单'
                onCancel={onCancel}
                onOk={onOkBtn}
            >
                <Form form={form} className='add_white_personal'>
                    <p>每行代表一个用户，请输入用户手机号码，点击回车键换行</p>
                    <Form.Item name="opinion" >
                        <TextArea rows={6} bordered={false}></TextArea>
                    </Form.Item>
                    {/* <Button type="primary" onClick={onFinish}>添加</Button> */}
                </Form>
                {phoneArr&&phoneArr.map((item,index)=>{
                    return (
                        <div className='more-list' key={index}>
                            <div>
                                {item}
                            </div>
                            <Button type='link' onClick={() => deleteItem(item)}>删除</Button>
                        </div>
                    )
                })}
            </Modal>
        </Fragment>
    );
}

export default Addwhite;
