import { Button, Drawer, Form, Input, Space, Radio, message, TimePicker, Select, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    DragOutlined
} from "@ant-design/icons";
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
    getGuestsListApi,
    getAgendaDetailApi,
    getAgendaTaskListApi,
    saveAgendaApi,
    updateAgendaApi,
    getFirstListConferenceApi,
    getAgendaguestsApi
} from '@services/Agenda';
import { expertLevels } from '@utils/constants';
import './index.less';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import cnchar from 'cnchar';
import {pinyin} from 'pinyin-pro';
import { parse } from 'path';

export const drawerFormItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
};

// 日程新增、编辑
const AddAgendaDrawer = ({
    visibleAddAgendaDrawer,
    setVisibleAddAgendaDrawer,
    currentDate,
    currentConferenceHall,
    agendaDetail,
    setAgendaDetail,
    getAgendasList,
    setConferenceDetail
}) => {
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const [form] = Form.useForm();
    const [firstConferenceList, setFirstConferenceList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [guestsList, setGuestsList] = useState([]);
    const [type, setType] = useState(1);

    const [conflictAgendaData, setConflictAgendaData] = useState([]);

    const [agendaLoading, setAgendaLoading] = useState(false); // 新增/编辑日程loading

    const closeAddAgendaDrawer = () => setVisibleAddAgendaDrawer(false);

    const [openTaskList, setOpenTaskList] = useState(false); // 查看当前嘉宾所有日程弹框
    const [taskAgendas, setTaskAgendas] = useState([]); // 专家下日程查询

    const [currentExpertId, setCurrentExpertId] = useState('');
    const [changeCount, setChangeCount] = useState(1);

    // 提交日程，新增/编辑
    const submitAgenda = () => {
        form.validateFields().then((values) => {
            if (agendaDetail) {
                updateAgenda(values, agendaDetail.id);
            } else {
                saveAgenda(values);
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    // 提交日程，新增并继续增加新日程
    const submitAndAddNextAgenda = () => {
        form.validateFields().then((values) => {
            saveAgenda(values, 1, true);
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    const confirmedToSubmitAgenda = () => {
        form.validateFields().then((values) => {
            if (agendaDetail) {
                updateAgenda(values, agendaDetail.id, 2);
            } else {
                saveAgenda(values, 2);
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    // 新增日程
    // confirmed 1日程冲突校验，2继续添加 3继续增加下一条日程
    const saveAgenda = async (values, confirm = 1, isNext = false) => {
        let params = { ...values, confirm };
        params.hallsId = currentConferenceHall?.id || 0;
        if (params.time && params.time.length) {
            params.startTime = params.date + ' ' + params.time[0].format('HH:mm');
            params.endTime = params.date + ' ' + params.time[1].format('HH:mm');
            delete params.time;
        }
        delete params.date;
        delete params.hallsName;
        if (params.agendaGuestsList && params.agendaGuestsList.length) {
            params.agendaGuestsList = params.agendaGuestsList.map((item => {
                let newItem = { ...item };
                newItem.conferenceId = conferenceId;
                newItem.invitationAccepted = 0;
                return newItem;
            }));
        }

        try {
            setAgendaLoading(true);
            const res = await saveAgendaApi(params);
            if (res.status_code === 200) {
                message.success('日程保存成功');
                if (isNext) {
                    form.resetFields();
                    form.setFieldValue('date', currentDate);
                    if (currentConferenceHall?.name) {
                        form.setFieldValue('hallsName', currentConferenceHall?.name);
                    }
                    getAgendasList(currentConferenceHall?.id);
                } else {
                    closeAddAgendaDrawer();
                    closeConfirmAgendaConflicts();
                    getAgendasList(currentConferenceHall?.id);
                }

            } else if (res.status_code === 422) {
                // 目前默认 422 状态是有日程冲突
                setConflictAgendaData(res.data);
                setVisibleConfirmAgendaConflicts(true);
            }
            setAgendaLoading(false);
        } catch (e) {
            setAgendaLoading(false);
        }
    };

    // 编辑日程
    // confirmed 1日程冲突校验，2继续添加
    const updateAgenda = async (values, id, confirm = 1) => {
        let params = { ...values, confirm };
        params.id = id;
        params.hallsId = currentConferenceHall?.id || 0;
        if (params.time && params.time.length) {
            params.startTime = params.date + ' ' + params.time[0].format('HH:mm');
            params.endTime = params.date + ' ' + params.time[1].format('HH:mm');
            delete params.time;
        }
        delete params.date;
        delete params.hallsName;
        if (params.agendaGuestsList && params.agendaGuestsList.length) {
            params.agendaGuestsList = params.agendaGuestsList.map((item => {
                let newItem = {
                    expertId: item.expertId,
                    taskId: item.taskId,
                    discussion: item.discussion,
                    id: item.id,
                    invitationAccepted: item.invitationAccepted,
                    refusalInfo: item.refusalInfo,
                };
                newItem.conferenceId = conferenceId;
                newItem.agendaId = id;
                return newItem;
            }))
        }

        try {
            setAgendaLoading(true);
            const res = await updateAgendaApi(params);
            if (res.status_code === 200) {
                message.success('日程编辑成功');
                closeAddAgendaDrawer();
                closeConfirmAgendaConflicts();
                getAgendasList(currentConferenceHall?.id);
            } else if (res.status_code === 422) {
                // 目前默认 422 状态是有日程冲突
                setConflictAgendaData(res.data);
                setVisibleConfirmAgendaConflicts(true);
            }
            setAgendaLoading(false);
        } catch (e) {
            setAgendaLoading(false);
        }

    };

    // 获取上级会议列表
    const getFirstListConference = async () => {
        const res = await getFirstListConferenceApi({ conferenceId: conferenceId });
        if (res.status_code === 200) {
            setFirstConferenceList(res.data);
        }
    };

    // 查询任务列表
    const getAgendaTaskList = async () => {
        const res = await getAgendaTaskListApi();
        if (res.status_code === 200) {
            setTaskList(res.data);
        }
    };

    // 查询嘉宾列表
    const getGuestsList = async () => {
        const res = await getGuestsListApi({ conferenceId, page: 1, pageSize: -1 });
        if (res.status_code === 200) {
            if (res.data.list && res.data.list.length) {
                let newRes = res.data.list.map(item => {
                    const disabled = new RegExp(`^${moment().format('YY')}1\\*{4}\\d{4}$`).test(item.memMobile);
                    let nameLabel = `${item.memUsername} [${!!item.memMobile ? item.memMobile : '无手机号'}]`;
                    nameLabel += ` ${expertLevels.find(el => el.level === item.expertLevel)?.label}`;
                    if (item?.ssoMemberWorkExperiencesEntities && item.ssoMemberWorkExperiencesEntities.length) {
                        const expert = item.ssoMemberWorkExperiencesEntities[0];
                        const office = !!expert?.unitOffice ? expert.unitOffice !== 'null' ? expert.unitOffice : '' : '';
                        nameLabel += ` ${expert?.unitName}${office}${expert?.unitPost}`;
                    }
                    return {
                        ...item,
                        disabled,
                        name: nameLabel,
                    };
                });
                setGuestsList(newRes);
            }
        }
    };

    // 日程类型切换
    const typeChange = (e) => {
        setType(e.target.value);
    };

    const onChange = () => {
    };

    const handleChange = () => {
    };

    const handleExpertChange = (e, key) => {
        // const keys = form.getFieldValue('agendaGuestsList')[key]
        // console.log(keys, '----------')
        // setChangeCount(changeCount + 1);
    };

    const swapItems = (arr, index1, index2) => {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0]
        return arr
    }

    // 删除
    const moveAgendaGuest = (index) => {
        let arr = form.getFieldValue('agendaGuestsList');
        let newArr = [];
        if (arr.length > 1) {
            newArr = [...arr];
            newArr.splice(index, 1);
        }
        form.setFieldValue('agendaGuestsList', newArr);
    };

    // 日程编辑回显
    const getEditAgenda = async (agendaId) => {
        const res = await getAgendaDetailApi({ id: agendaId });
        if (res.status_code === 200) {
            const data = res.data;
            setType(data.type);
            form.setFieldsValue({
                agendaGuestsList: dealEditGuest(data.agendaGuestsList),
                type: data.type,
                title: data.title,
                time: [moment(data.startTime, 'HH:mm'), moment(data.endTime, 'HH:mm')],
                hidden: data.hidden,
            });
            console.log(form.getFieldsValue())
        }
    };

    // 处理嘉宾列表回显数据
    const dealEditGuest = (list) => {
        let newList = [];
        if (list && list.length) {
            newList = list.map(item => {
                let newItem = { ...item };
                if (newItem.memCard === '0' || !newItem.memCard) {
                    delete newItem.memCard;
                }
                if (!newItem.taskId) {
                    delete newItem.taskId;
                }
                return newItem;
            });
        }
        return newList;
    };

    // 获取嘉宾对应存在任务
    const getIsShowTaskCount = (field) => {
        const fieldAgenda = guestsList.find(item => item.id === field?.expertId);
        const count = fieldAgenda?.agendasGuestsList ? fieldAgenda?.agendasGuestsList.length : 0;
        return count;
    };

    useEffect(() => {
        if (visibleAddAgendaDrawer) {
            getFirstListConference();
            getAgendaTaskList();
            getGuestsList();
            form.setFieldValue('date', currentDate);
            if (currentConferenceHall?.name) {
                form.setFieldValue('hallsName', currentConferenceHall?.name);
            }
        } else {
            setAgendaDetail(null);
            setConferenceDetail(null);
            form.resetFields();
        }
    }, [visibleAddAgendaDrawer]);

    useEffect(() => {
        if (agendaDetail) {
            getEditAgenda(agendaDetail.id);
        } else {
            setType(1);
        }
    }, [agendaDetail]);

    const [visibleConfirmAgendaConflicts, setVisibleConfirmAgendaConflicts] = useState(false);

    const closeConfirmAgendaConflicts = () => {
        setConflictAgendaData([]);
        setVisibleConfirmAgendaConflicts(false);
    };

    // 查询专家下的日程
    const viewTaskAgendas = async (info) => {
        const expertId = info?.expertId;
        setCurrentExpertId(expertId);
        setOpenTaskList(true);
        const res = await getAgendaguestsApi({ conferenceId, expertId });
        if (res.status_code === 200 && res.data) {
            setTaskAgendas(res.data);
        };
    };

    const DragHandle = SortableHandle(() => (
        <DragOutlined style={{ cursor: 'grab' }} />
    ));

    const SortableItem = SortableElement(({ field, index, remove }) => (
        <div className="add-guest-item guest-item">
            <span className="no">{field.name + 1}</span>
            {type === 3 && (
                <Space style={{ display: 'flex' }} align="center">
                    <Form.Item
                        label="标题"
                        style={{ width: 600 }}
                        name={[field.name, 'discussion']}
                        rules={[
                            { required: true, message: '请输入环节标题' },
                        ]}
                    >
                        <Input placeholder="请输入环节标题" maxLength={100} />
                    </Form.Item>
                </Space>
            )}
            <Space style={{ display: 'flex' }} align="center">
                <Form.Item
                    {...field}
                    name={[field.name, 'taskId']}
                    rules={[
                        { required: type !== 3, message: '请选择任务类型' },
                    ]}
                >
                    <Select
                        placeholder="任务类型"
                        style={{ width: 100 }}
                        onChange={handleChange}
                        options={taskList}
                        popupClassName="first-conference"
                        fieldNames={{ value: 'id', label: 'name' }}
                    />
                </Form.Item>
                <Form.Item
                    {...field}
                    name={[field.name, 'expertId']}
                    rules={[
                        { required: type !== 3, message: '选择人员' },
                    ]}
                >
                    <Select
                        placeholder="请选择人员"
                        showSearch
                        style={{ width: 540 }}
                        onChange={(e) => handleExpertChange(e, index)}
                        options={guestsList}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.name ?? '').includes(input)}
                        popupClassName="first-conference"
                        fieldNames={{ value: 'id', label: 'name' }}
                    />
                </Form.Item>

                <div className="agenda-task-count">
                    {
                        !!getIsShowTaskCount(form.getFieldValue('agendaGuestsList')[field.name]) &&
                        <Button type="link" onClick={() => viewTaskAgendas(form.getFieldValue('agendaGuestsList')[field.name])}>
                            {getIsShowTaskCount(form.getFieldValue('agendaGuestsList')[field.name])}个任务
                        </Button>
                    }
                </div>
                <MinusCircleOutlined onClick={() => {
                    moveAgendaGuest(field.name)
                }} />
                <DragHandle />

            </Space>
        </div>
    ));

    const SortableList = SortableContainer(({ items, remove, move }) => {
        return (
            <div>
                {items.map((field, index) => (
                    <SortableItem
                        key={`item-${field.name}`}
                        index={index}
                        field={field}
                        remove={remove}
                        move={move}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const fields = form.getFieldValue('agendaGuestsList');
        const newFields = arrayMoveImmutable(fields, oldIndex, newIndex);
        form.setFieldsValue({
            agendaGuestsList: newFields
        });
    };

    // 获取姓名的拼音
    const getNamePinyin = (name) => {
        return pinyin(name, {surname: 'all'});
    }

    // 获取姓名的总笔画数
    const getNameStrokeCount = (name) => {
        let totalStrokes = 0;
        for (let char of name) {
            totalStrokes += cnchar.stroke(char);
        }
        return totalStrokes;
    }

    // 拼音首字母排序
    const pinyinSort = () => {
        const fields = form.getFieldValue('agendaGuestsList') || [];
        const nameFields = fields.map(item => ({ ...item, memUsername: guestsList.find(item2 => item2.id === item.expertId)?.memUsername || '' }));

        const sortedNames = nameFields.sort((a, b) => getNamePinyin(a.memUsername).localeCompare(getNamePinyin(b.memUsername)));

        const updateFields = [...sortedNames].map(item => {
            const newItem = { ...item };
            delete newItem.memUsername;
            return newItem;
        });

        form.setFieldsValue({
            agendaGuestsList: updateFields
        });
    };

    // 笔画排序
    const bihuaSort = () => {
        const fields = form.getFieldValue('agendaGuestsList') || [];
        const nameFields = fields.map(item => ({ ...item, memUsername: guestsList.find(item2 => item2.id === item.expertId)?.memUsername || '' }));

        const sortedNames = nameFields.sort((a, b) => getNameStrokeCount(a.memUsername) - getNameStrokeCount(b.memUsername));
        const updateFields = [...sortedNames].map(item => {
            const newItem = { ...item };
            delete newItem.memUsername;
            return newItem;
        });

        form.setFieldsValue({
            agendaGuestsList: updateFields
        });
    };

    return (
        <>
            <Drawer
                title={agendaDetail ? "编辑日程" : "新增日程"}
                height="100%"
                placement="bottom"
                open={visibleAddAgendaDrawer}
                onClose={closeAddAgendaDrawer}
                footer={
                    <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={closeAddAgendaDrawer}>取消</Button>
                            <Button type="primary" loading={agendaLoading} onClick={submitAgenda}>确定</Button>
                            {!agendaDetail && <Button type="primary" loading={agendaLoading} onClick={submitAndAddNextAgenda}>确定并添加下一个日程</Button>}
                        </Space>
                    </div>
                }
            >
                <div className="agenda-form">
                    <Form
                        {...drawerFormItemLayout}
                        name="basic"
                        form={form}
                        initialValues={{ type: 1, hidden: 0 }}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="日程标题"
                            name="title"
                            rules={[
                                { required: true, message: '请输入日程标题' },
                            ]}
                        >
                            <Input placeholder="请输入日程标题" maxLength={300} />
                        </Form.Item>
                        <Form.Item label="所属会议" name="hallsName">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="日程日期" name="date">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            name="type"
                            label="日程类型"
                            rules={[
                                { required: true, message: '日程类型' },
                            ]}
                        >
                            <Radio.Group onChange={typeChange} value={type}>
                                <Radio value={1}>主持</Radio>
                                <Radio value={2}>演讲</Radio>
                                <Radio value={3}>讨论</Radio>
                                <Radio value={4}>仪式</Radio>
                                <Radio value={5}>赞助企业</Radio>
                                <Radio value={6}>工作人员</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="日程时间"
                            name="time"
                            rules={[
                                { required: true, message: '请选择日程时间' },
                            ]}
                        >
                            <TimePicker.RangePicker onChange={onChange} format='HH:mm' />
                        </Form.Item>
                        <Form.Item label={type === 3 ? '讨论内容' : "日程任务"} name="agendaGuestsList">
                            <Form.List name="agendaGuestsList">
                                {(fields, { add, remove, move }) => (
                                    <>
                                        <Form.Item style={{ marginBottom: 8 }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button type="link" onClick={() => add()}>
                                                    <PlusCircleOutlined />{type === 3 ? '添加问题' : "添加"}
                                                </Button>
                                                <div>
                                                    <Button type="link" onClick={() => pinyinSort()}>按拼音字母排序</Button>
                                                    <Button type="link" onClick={() => bihuaSort()}>按姓名笔画排序</Button>
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <SortableList items={fields} remove={remove} move={move} onSortEnd={onSortEnd} useDragHandle />
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>

                        <Form.Item
                            name="hidden"
                            label="日程状态"
                            rules={[
                                { required: true },
                            ]}
                            hidden={true}
                        >
                            <Radio.Group>
                                <Radio value={0}>展示</Radio>
                                <Radio value={1}>隐藏</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </div>
            </Drawer>

            <Modal
                open={visibleConfirmAgendaConflicts}
                title="嘉宾日程冲突提醒"
                closable={false}
                centered
                width={640}
                footer={[
                    <Button danger key="continueToAdd" onClick={() => confirmedToSubmitAgenda()}>知晓冲突，确认提交</Button>,
                    <Button type="primary" key="cancel" onClick={() => closeConfirmAgendaConflicts()}>暂不提交，调整日程</Button>
                ]}
            >
                {!!(!_.isEmpty(conflictAgendaData) && conflictAgendaData.length) && (
                    <div className="conflicting-guests">
                        {conflictAgendaData.map(expert => (
                            <div className="guest" key={expert.expertId}>
                                <div className="guest-name"><b>{guestsList.find(g => g.id === expert.expertId)?.name}</b> 有以下日程与当前日程冲突：</div>
                                <div className="conflicting-agendas">
                                    {!_.isEmpty(expert.conflicts) && expert.conflicts.length && expert.conflicts.map((c, idx) => (
                                        <div className="conflicting-agenda" key={`c_${idx}`}>
                                            <div className="agenda-conf">{c?.conferenceName} / {c?.hallsName}</div>
                                            <div className="agenda-task">
                                                <span>{c?.startTime}～{c?.endTime}</span>
                                                <span>{c?.title}</span>
                                                <span className="conflictRed">{c?.hallsId === currentConferenceHall?.id ? "【相同会议】" : "【不同会议】"}日程冲突</span>
                                            </div>
                                        </div>
                                    ))}
                                    {!_.isEmpty(expert.near) && expert.near.length && expert.near.map((c, idx) => (
                                        <div className="conflicting-agenda" key={`n_${idx}`}>
                                            <div className="agenda-conf">{c?.conferenceName} / {c?.hallsName}</div>
                                            <div className="agenda-task">
                                                <span>{c?.startTime}～{c?.endTime}</span>
                                                <span>{c?.title}</span>
                                                <span className="conflict">{c?.hallsId === currentConferenceHall?.id  ? "【相同会议】" : "【不同会议】"}日程时间间隔不足{c?.timeDiff}分钟</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

            </Modal>

            <Modal
                open={openTaskList}
                title="任务详情"
                closable={false}
                centered
                onCancel={() => setOpenTaskList(false)}
                onOk={() => setOpenTaskList(false)}
                width={640}
            >
                {!!(!_.isEmpty(taskAgendas) && taskAgendas.length) && (
                    <div className="conflicting-guests">
                        <div className="guest">
                            <div className="guest-name"><b>{guestsList.find(g => g.id === currentExpertId)?.name}</b> 在该项目下已安排如下日程任务：</div>
                            <div className="conflicting-agendas">
                                {
                                    taskAgendas.map(agenda => (
                                        <div className="conflicting-agenda" key={agenda.id}>
                                            <div className="agenda-conf">{agenda?.conferenceHallName}</div>
                                            <div className="agenda-task">
                                                <span>{agenda?.startTime}～{agenda?.endTime}</span>
                                                <span style={{ wordBreak: 'break-all' }}>{agenda?.title}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )}

            </Modal>
        </>
    );
};
export default AddAgendaDrawer;
