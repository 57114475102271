import React, { useState, useEffect, useRef } from 'react';
import { LeftOutlined, ExclamationCircleOutlined , QuestionCircleOutlined} from "@ant-design/icons";
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { Layout, Table, Button, Input, Modal, Form, message, Tooltip } from "antd";
import './index.less';
import config from '@config';
import { conferenceOnceApi } from '@services/Conference';
import { signInSettingsApi, signInListApi, updateSignInSettingsApi, deleteSignInSettingsApi, downloadWxCodeApi} from '@services/Conference/signIn';

const { Content } = Layout;

// 签到管理
const SignIn = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const params = useParams();

    const conferenceId = params?.id || 0; // 会议项目 ID
    const [conference, setConference] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([{ name: '中国医院管理奖决赛签到领取权益', id: 88 }]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 编辑id
    const editId = useRef(undefined);

    const [signInForm] = Form.useForm();

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({id: conferenceId});
        if (res.status_code === 200) {
            setConference(res.data);
        }
    };

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
        }
    }, []);

    const columns = [
        {
            title: "签到名称",
            dataIndex: "name",
        },
        {
            title: () => (
                <Tooltip title="建议：
                1. “线上会、无实物权益发放的会”，选择“用户自主签到”方式，不用工作人员扫码核验。

                2. “线下会、有实物权益发放的会”，选择“工作人员核验签到”方式，由工作人员扫码核验。">
                    签到类型 <QuestionCircleOutlined />
                </Tooltip>
            ),
            dataIndex: "type",
            render: (data) => {
                return  <span>{data === 1 ? '工作人员核验签到' : '用户自主签到'}</span>
            }
        },
        {
            title: "操作",
            dataIndex: "state",
            key: "state",
            width: 550,
            render: (_, row) => (
                <>
                    <Button type='link' onClick={() => handleEdit(row)} className="edit-btn">编辑</Button>
                    <Button type='link' onClick={() => downloadCode(row)}>下载签到小程序码</Button>
                    <Button type='link' onClick={() => handleSet(row)}>签到设置</Button>
                    <Button type='link' onClick={() => handleDetail(row)}>签到明细</Button>
                    <Button type='link' onClick={() => handleStatistics(row)}>签到统计</Button>
                    {/* <Button type='link' onClick={() => handleDelete(row.id)}>删除</Button> */}
                </>
            ),
        },
    ];

    // 页码改变
    const onChangePage = () => {

    };

    // 新增/编辑签到
    const handleOk = () => {
        signInForm.validateFields().then(async (values) => {
            const reqParams = {
                ...values,
                conferenceId: params?.id,
                id: editId.current
            }
            const apiType = editId.current ? updateSignInSettingsApi : signInSettingsApi;
            const res = await apiType(reqParams);
            if (res && res.status_code === 200) {
                message.success('保存成功');
                setIsModalOpen(false);
                getSignInList();
            }
        });
    };

    // 关闭签到弹框
    const handleCancel = () => {
        setIsModalOpen(false);
        editId.current = undefined;
    };

    // 编辑
    const handleEdit = (row) => {
        editId.current = row.id;
        signInForm.setFieldsValue({name: row.name});
        setIsModalOpen(true);
    };

    // 下载签到二维码
    const downloadCode = async(row) => {
        let query = `pages/signup/index?conferenceId=${params.id}&signType=${row.type}&from=2`
        let en = encodeURIComponent(query);
        let url = `${config.API_PREFIX}/dataengine/api/mp/wxacode/dowload?env_version=${window.location.origin.includes('stage-') ? 'trial' : 'release'}&path=${en}`;
        window.open(url);
    };

    // 签到设置
    const handleSet = (record) => {
        navigate(`/conf/conferences/${params.id}/signin-setting`, { state: { name: record.name, id: record.id } });
    };

    // 签到明细
    const handleDetail = (record) => {
        navigate(`/conf/conferences/${params.id}/signin-detail`, { state: { name: record.name, id: record.id, conferenceName: conference.name } });
    };

    // 签到统计
    const handleStatistics = (record) => {
        navigate(`/conf/conferences/${params.id}/signin-statistics`, { state: { name: record.name, id: record.id } });
    };

    // 删除
    const handleDelete = (delId) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否删除该权益`,
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: async() => {
                const res = await deleteSignInSettingsApi({id: delId});
                if (res && res.status_code === 200) {
                    message.success('删除成功');
                    getSignInList();
                }
            }
        });
    };

    // 列表
    const getSignInList = async() => {
        const res = await signInListApi({ conferenceId: params?.id });
        if (res && res.status_code === 200) {
            setData(res.data);
        }
    };

    useEffect(() => {
        getSignInList();
    }, []);

    return (
        <>
            {/* <div className="page-title">
                <Link to="/conf/conferences" className="back"><LeftOutlined /></Link>
                <span className="current-title">签到管理</span>
                <span className="current-subtitle">{conference?.name}</span>
            </div> */}
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="signin-wrap">
                        {/* <div className="signin-header">
                            <Tooltip title={data && !!data.length ? '只能创建一个新增权益签到' : ''}>
                                <Button type="primary" onClick={() => setIsModalOpen(true)} disabled={data && !!data.length}>新增权益签到</Button>
                            </Tooltip>
                            <p className="signin-tip">权益签到是指用户签到时可以领取权益</p>
                        </div> */}

                        <Table
                            rowKey="id"
                            size="small"
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            pagination={false}
                        />

                        <Modal title="签到" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <Form
                                name="signInForm"
                                form={signInForm}
                                size="large"
                                autoComplete="off"
                            >
                                <Form.Item name="name" label="签到名称" rules={[{required: true, message: '请填写签到名称'}]}>
                                    <Input placeholder="请输入签到名称" maxLength={100} showCount />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </Content>
        </>
    );
}

export default SignIn;
