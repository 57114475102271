import React, { useEffect, useState } from "react";
import { getDirectionsListApi, delDirectionsApi, sortDirectionsApi } from "@services/Selection";
import { Button } from "antd";
import "./Declare.less";
const Declare = ({ topicId,setDeclareModal,setDeclareId,declareModal }) => {
    const [declare, setDeclare] = useState([]);
    const changeFile = (index, item, type) => {
        let olidFile = [...declare];
        let currentFile = olidFile[index];
        if (type === "del") {
            delDirectionsApi({ id: item.id }).then((res) => {
                if(res.status_code === 200) {
                    onReset()
                }
            });
        } else if (type === "up") {
            let preFile = olidFile[index - 1];
            let midSave;
            midSave = currentFile;
            console.log(midSave);
            olidFile[index] = preFile;
            olidFile[index - 1] = midSave;
            let sendValue = olidFile.map(item => item.id)
            sortDirectionsApi([...sendValue])
            setDeclare([...olidFile]);
        } else if (type === "down") {
            let nextFile = olidFile[index + 1];
            let midSave;
            midSave = currentFile;
            olidFile[index] = nextFile;
            olidFile[index + 1] = midSave;
            let sendValue = olidFile.map(item => item.id)
            sortDirectionsApi([...sendValue])
            setDeclare([...olidFile]);
        }else if(type === 'edit'){
            setDeclareModal(true);
            setDeclareId(item.id);
        }
    };
    const onReset = ()=>{
        getDirectionsListApi({ topicId }).then((res) => {
            if (res.status_code === 200) {
                setDeclare(res.data);
            }
        });
    }
    useEffect(() => {
        onReset()
    }, [declareModal]);
    return (
        <div>
            {declare &&
                declare.map((item, index) => {
                    return (
                        <div className="declare_area" key={index}>
                            <div className="declare_content">{item.description}</div>
                            <div className="declare_button">
                                <Button type="link" className="icon-name" onClick={() => changeFile(index, item, "edit")}>
                                    编辑
                                </Button>
                                {index !== 0 && (
                                    <>
                                        <Button type="link" className="icon-name" onClick={() => changeFile(index, item, "up")}>
                                            上移
                                        </Button>
                                    </>
                                )}
                                {index !== declare.length - 1 && (
                                    <>
                                        <Button type="link" className="icon-name" onClick={() => changeFile(index, item, "down")}>
                                            下移
                                        </Button>
                                    </>
                                )}
                                <Button type="link" className="icon-name" onClick={() => changeFile(index, item, "del")}>
                                    移除
                                </Button>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default Declare;
