import React, { Fragment, useState, useEffect } from 'react';
import { Descriptions, Row, Col, Space, Layout } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import './ComplianceStatistics.less';
import { get } from '@/core/request';
const { Content } = Layout;

export default function ComplianceStatistics() {

    const { state } = useLocation();

    const [userDetailVo, setUserDetailVo] = useState({});
    const [liveDetailVo, setLiveDetailVo] = useState({});
    const [liveUnitsDetailVo, setLiveUnitsDetailVo] = useState([]);
    const [orgDetailVo, setOrgDetailVo] = useState({});
    const [visible, setVisible] = useState(false)

    // 渲染table数据
    useEffect(() => {
        get(`/dms/compliance/details`, {
            userId: state.userId,
            liveId: state.liveId,
            productId: state.productId,
            orgId: state.orgId,
            viewType: state.viewType
        }).then((res) => {
            if (res.status_code === 200) {
                setVisible(true);
                setUserDetailVo(res.data.userDetailVo);
                setLiveDetailVo(res.data.liveDetailVo);
                setLiveUnitsDetailVo(res.data.liveUnitsDetailVo);
                setOrgDetailVo(res.data.orgDetailVo);
            }
        })
    }, [state.userId, state.liveId, state.productId, state.orgId, state.viewType]);

    return (
        <Fragment>
            <div className="page-title"><Link to="/dms/compliance/user/lives" className="back"><LeftOutlined /></Link>
                合规化统计详情</div>

            <Content className="main-content-box">

                {
                    visible && <div className="main-content-body">

                        <div className="table-caption">
                            <h2 className="compliance-title">基本信息</h2>
                            <Descriptions className="basic-information" column={4}>
                                <Descriptions.Item label="姓名">{userDetailVo.name}</Descriptions.Item>
                                <Descriptions.Item label="用户ID">{userDetailVo.userId}</Descriptions.Item>
                                <Descriptions.Item label="性别">{userDetailVo.gender}</Descriptions.Item>
                                <Descriptions.Item label="手机号">{userDetailVo.phone}</Descriptions.Item>
                                <Descriptions.Item label="所在城市">{userDetailVo.province}</Descriptions.Item>
                                <Descriptions.Item label="所在医院">{userDetailVo.hospital}</Descriptions.Item>
                                <Descriptions.Item label="所在科室">{userDetailVo.tagStr}</Descriptions.Item>
                                <Descriptions.Item label="职称">{userDetailVo.titleStr}</Descriptions.Item>
                            </Descriptions>
                        </div>

                        <div className="table-caption">
                            <h2 className="compliance-title">参与直播信息</h2>
                            <Descriptions className="basic-information" column={3}>
                                <Descriptions.Item label="直播标题">{liveDetailVo.liveName}</Descriptions.Item>
                                <Descriptions.Item label="直播ID">{liveDetailVo.liveId}</Descriptions.Item>
                                <Descriptions.Item label="直播分类">{liveDetailVo.cateName}</Descriptions.Item>
                                <Descriptions.Item label="参与方式">{liveDetailVo.identityType}</Descriptions.Item>
                                <Descriptions.Item label="直播时间"><div style={{ width: '160px' }}>{liveDetailVo.liveTime}</div></Descriptions.Item>
                                <Descriptions.Item label="参与时间">{liveDetailVo.viewStartTime}</Descriptions.Item>
                                <Descriptions.Item label="参与时长">{liveDetailVo.viewTimes}</Descriptions.Item>
                            </Descriptions>
                        </div>

                        <div className="table-caption">
                            <h2 className="compliance-title">合作单位</h2>
                            <Row>
                                <Col style={{ textAlign: 'right' }} span={2}></Col>
                                <Col span={22}>
                                    <Descriptions>
                                        <Descriptions.Item label="主办单位">
                                            <Space direction="vertical" className="compliance-unit">
                                                {liveUnitsDetailVo && liveUnitsDetailVo.map(item => {
                                                    if (item.unitType === '1') {
                                                        return (
                                                            <p key={item.name}>{item.name}</p>
                                                        )
                                                    }
                                                })}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="协办单位">
                                            <Space direction="vertical" className="compliance-unit">
                                                {liveUnitsDetailVo && liveUnitsDetailVo.map(item => {
                                                    if (item.unitType === '2') {
                                                        return (
                                                            <p key={item.name}>{item.name}</p>
                                                        )

                                                    }
                                                })}
                                            </Space>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>

                        <div className="table-caption">
                            <h2 className="compliance-title">参与直播信息</h2>
                            <Descriptions className="basic-information" column={3}>
                                <Descriptions.Item label="产品线">{orgDetailVo.productName}</Descriptions.Item>
                                <Descriptions.Item label="产品线ID">{orgDetailVo.productId}</Descriptions.Item>
                                <Descriptions.Item label="所属企业">{orgDetailVo.orgName}</Descriptions.Item>
                                <Descriptions.Item label="企业ID">{orgDetailVo.orgId}</Descriptions.Item>
                            </Descriptions>
                        </div>

                    </div>
                }

            </Content>


        </Fragment>
    )
}
