import { get } from '@core/request';

//医疗决策人在线总览
export const getOnlineDateApi = (params) => {
    return get('user/datamedicaldecisionmakersdaily/online-overview', params);
};

// 医疗决策人在线总览-参与项目分布
export const getProjectChartData = params => {
    return get('user/datamedicaldecisionmakersdaily/proportion-person-project', params);
};

//医疗决策人认证总览
export const getDateOverviewApi = (params) => {
    return get('user/data/statistics/detail/decision-maker-day-count', params);
};

//医疗决策人职务分布图
export const getAllMakersApi = (params) => {
    return get('user/data/statistics/medical-decision-makers', params);
};

//所有医疗决策人数
export const getPositionOverviewApi = (params) => {
    return get('user/data/statistics/detail/decision-maker-post-count', params);
};

// 决策人在线明细列表
export const getOnlineDailyApi = (params) => {
    return get('user/datamedicaldecisionmakersdaily/page', params);
};

// 获取指定 code 项目类型下的项目列表
export const getProjectsOfType = params => {
    return get('user/datamedicaldecisionmakersdaily/projects', params);
}

// 导出项目统计数据
export const downloadStatisticsData = (params) => get('/user/datamedicaldecisionmakersdaily/download', params, { responseType: 'blob' });



//医疗决策 - 人数据总览
export const getProjectOverviewApi = (params) => {
    return get('user/datamedicaldecisionmakersdaily/med-project-overview', params);
};

//医疗决策 - 单个项目数据总览
export const getSingleOverviewApi = (params) => {
    return get('user/datamedicaldecisionmakersdaily/single-overview', params);
};

//医疗决策 - 医项目列表
export const getMedProjectApi = (params) => {
    return get('medProject/projects/list', params);
};

//医疗决策 - 邀请组列表
export const fetchInvitationGroupSimpleListApi = (params) => {
    return get('invitation/boss/invitationgroups/simplelist', params);
};

//医项目数据总览 - 4次在线总计
export const medProjectOverviewTotalApi = (params) => {
    return get('user/datamedicaldecisionmakersdaily/med-project-overview-total', params);
};

//单项目数据总览 - 4次在线总计
export const singleOverviewTotalApi = (params) => {
    return get('user/datamedicaldecisionmakersdaily/single-overview-total', params);
};
