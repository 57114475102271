import React, {Fragment, useState, useCallback, useEffect} from "react";
import {Button, Layout, Table, Modal, Space, message, Row, Form, Upload,Radio, Tabs} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {fullPagerConfig} from "@/utils/property";
import {get, del, post, put} from "@/core/request";
import CheckOrganizationsQueryFrom from "./CheckOrganizationsQueryFrom";
import './CheckOrganizations.less';


const {Content} = Layout;
const { TabPane } = Tabs;

export default function CheckOrganizations() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [tabledataTwo, setTableDataTwo] = useState({});
    const [tabledataThree, setTableDataThree] = useState({});
    const [filterObj, setFilterObj] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [orgType,setOrgType] =useState(1)

    let navigate = useNavigate();

    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [pageSizeTwo, setPageSizeTwo] = useState(10);
    const [currPageTwo, setCurrPageTwo] = useState(1);
    const [pageSizeThree, setPageSizeThree] = useState(10);
    const [currPageThree, setCurrPageThree] = useState(1);
    // 改变一页有多少数据
    const changePageSize = useCallback((pageSize) => {setPageSize(pageSize);}, []);
    const changePageSizeTwo = useCallback((pageSizeTwo) => {setPageSizeTwo(pageSizeTwo);}, []);
    const changePageSizeThree = useCallback((pageSizeThree) => {setPageSizeThree(pageSizeThree);}, []);
    // 改变第几页
    const changeCurrPage = useCallback((page) => {
        setCurrPage(page);}, []);
    const changeCurrPageTwo = useCallback((page) => {
        setCurrPageTwo(page);}, []);
    const changeCurrPageThree = useCallback((page) => {
        setCurrPageThree(page);}, []);
    //分页数据
    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: "small",
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page);
        },
        current: currPage,
    };
    //分页数据
    const paginationPropsTwo = {
        ...fullPagerConfig,
        pageSizeTwo,
        size: "small",
        total: tabledataTwo.totalCount,
        onShowSizeChange: (currentTwo, pageSizeTwo) => changePageSizeTwo(pageSizeTwo),
        onChange: (page) => {
            changeCurrPageTwo(page);
        },
        currentTwo: currPageTwo,
    };
    //分页数据
    const paginationPropsThree = {
        ...fullPagerConfig,
        pageSizeThree,
        size: "small",
        total: tabledataThree.totalCount,
        onShowSizeChange: (currentThree, pageSizeThree) => changePageSizeThree(pageSizeThree),
        onChange: (page) => {
            changeCurrPageThree(page);
        },
        currentThree: currPageThree,
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const {
        code,
        name,
        belongsUnitName,
        type,
        createdStartAt,
        createdEndAt,
        examineStatus,
        adminName,
    } = filterObj;

    const getData = () => {
        let thisPages = '1'
        let thisPageSize='10'
        if (orgType==2){
            thisPages=currPageTwo
            thisPageSize=pageSizeTwo
        }else if (orgType==3){
            thisPages=currPageThree
            thisPageSize=pageSizeThree
        }else if (orgType==1) {
            thisPages=currPage
            thisPageSize=pageSize
        }

        get(`/healthclass/boss/organizations/list`, {
            page: thisPages,
            pageSize: thisPageSize,
            code,
            name,
            belongsUnitName,
            type,
            createdStartAt,
            createdEndAt,
            examineStatus:orgType,
            adminName,
        }).then((res) => {
            if (res.status_code === 200) {
                if (orgType==2){
                    setTableDataTwo(res.data);
                }else if (orgType==3){
                    setTableDataThree(res.data);
                }else if (orgType==1) {
                    setTableData(res.data);
                }

            }
        });
    };



   const checkOrg=useCallback(
       (row)=>{
            return ()=>{
                navigate(`/healthclass/CheckOrganizationsInfo`,{state:row});
            }
       },
       [navigate]
   )

   const onChange=(key)=>{
       setOrgType(key)
   };
    const handleFilterFinish=(value)=>{
        setFilterObj(value);
       const {
           code,
           name,
           belongsUnitName,
           type,
           createdStartAt,
           createdEndAt,
       } = value;
       get(`/healthclass/boss/organizations/list`, {
           code,
           name,
           belongsUnitName,
           type,
           createdStartAt,
           createdEndAt,
           examineStatus:orgType,
           adminName,
           page: 1,
           pageSize: pageSize,
       }).then((res) => {
           if (res.status_code === 200) {
               if (orgType==2){
                   setTableDataTwo(res.data);
                   setCurrPageTwo(1);
               }else if (orgType==3){
                   setTableDataThree(res.data);
                   setCurrPageThree(1);
               }else if (orgType==1) {
                   setTableData(res.data);
                   setCurrPage(1);
               }

           }
       });
   }
    // 渲染table数据
    useEffect(() => {
        getData();
    }, [
        pageSize,
        currPage,
        orgType,
        currPageTwo,
        currPageThree,
        pageSizeTwo,
        pageSizeThree
    ]);
    const columns = [
        {title: "学苑ID", dataIndex: "code"},
        {title: "学苑名称", dataIndex: "name"},
        {title: "所属主体", dataIndex: "belongsUnitName"},
        {title: "主体类型", dataIndex: "belongsUnitType",render: (belongsUnitType, row) => (
                <Fragment>
                    {belongsUnitType === 2 && (<span> 机构</span>)}
                    {belongsUnitType === 1 && (<span> 个人</span>)}
                </Fragment>
            )},
        {title: "学苑类型", dataIndex: "type",render: (type, row) => (
                <Fragment>
                    {type === 1 && (<span> 试用</span>)}
                    {type === 2 && (<span> 正式</span>)}
                </Fragment>
            )},
        {title: "开放类型", dataIndex: "limitType",render: (limitType, row) => (
                <Fragment>
                    {limitType === 1 && (<span> 私享</span>)}
                    {limitType === 2 && (<span> 公开</span>)}
                </Fragment>
            )},
        {title: "管理员", dataIndex: "adminName"},
        {title: "管理员手机号", dataIndex: "phone"},
        {title: "申请时间", dataIndex: "createdAt"},
        {title: "操作", dataIndex: "examineStatus",render: (examineStatus, row) => (
                <Fragment>
                    {examineStatus === 1 && (<span className="operate-style" onClick={checkOrg(row)}>审核</span>)}
                    {examineStatus === 2 && (<span className="operate-style" onClick={checkOrg(row)}>详情</span>)}
                    {examineStatus === 3 && (<span className="operate-style" onClick={checkOrg(row)}>详情</span>)}
                </Fragment>
            ),
        },
    ]

    return (
        <Fragment>
            <div className="page-title">学苑审核管理</div>
            <Content className="main-content-box content-nei">
            <Tabs defaultActiveKey="1" onChange ={onChange}>
                <TabPane tab="待审核" key="1">
                    <Content className="main-content-box">
                        <div className="main-content-body">
                            <div className="filter-form-box"><CheckOrganizationsQueryFrom handleFilterFinish={handleFilterFinish}/></div>
                            <Table
                                size="small"
                                className="checkorg-table"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={tabledata.list}
                                pagination={paginationProps}
                                rowKey={(row) => row.id}
                                scroll={{x: 'max-content'}}
                            />
                        </div>
                    </Content>
                </TabPane>
                <TabPane tab="审核通过" key="2">
                    <Content className="main-content-box">
                        <div className="main-content-body">
                            <div className="filter-form-box"><CheckOrganizationsQueryFrom handleFilterFinish={handleFilterFinish}/></div>
                            <Table
                                size="small"
                                className="checkaorg-table"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={tabledataTwo.list}
                                pagination={paginationPropsTwo}
                                rowKey={(row) => row.id}
                                scroll={{x: 'max-content'}}
                            />
                        </div>
                    </Content>
                </TabPane>
                <TabPane tab="审核驳回" key="3">
                    <Content className="main-content-box">
                        <div className="main-content-body">
                            <div className="filter-form-box"><CheckOrganizationsQueryFrom handleFilterFinish={handleFilterFinish}/></div>
                            <Table
                                className="checkaorg-table"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={tabledataThree.list}
                                pagination={paginationPropsThree}
                                rowKey={(row) => row.id}
                                scroll={{x: 'max-content'}}
                            />
                        </div>
                    </Content>
                </TabPane>
            </Tabs>
            </Content>
        </Fragment>
    )

}
