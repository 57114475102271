import { useRef, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Layout, Form, DatePicker, Radio, InputNumber, Button, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import ReactQuill, { Quill } from "react-quill";

import { SelectionFormItemLayout } from "../../../../utils/property";
import moment from "moment";
import { updataVoteSetting } from "@services/Selection";
import { fileApi } from "@services/Conference";
import config from "@config";
import { handleVoteSettingDefaultValue, option, handleForm2Params, keys } from "./util";

import "./index.less";

const { Content } = Layout;

const Settings = () => {
    const params = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const local = useLocation();
    const [loading, setLoading] = useState(false);
    const [descriptions, setDescriptions] = useState("");

    const [currentValues, setCurrentValues] = useState(() => handleVoteSettingDefaultValue(local.state));

    const refs = useRef();

    const rule = Form.useWatch("rule", form);

    const onDescriptionsChange = (v) => {
        setDescriptions(v);
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes("https")) {
            return url;
        }
        if (url && !url.includes("https")) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    const modules = useMemo(() => {
        const imageHander = async () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
            input.onchange = () => {
                const file = input.files[0];
                let formData = new FormData();
                formData.append("file", file);
                formData.append("type", 13);
                const hide = message.loading("上传中...", 0);
                fileApi(formData, option).then((res) => {
                    const url = getFileUrl(res.data); // 获取url,图片预览地址
                    let quill = refs?.current?.getEditor(); //获取到编辑器本身
                    const cursorPosition = quill.getSelection().index; //获取当前光标位置
                    quill.insertEmbed(cursorPosition, "image", url); //插入图片
                    quill.setSelection(cursorPosition + 1); //光标位置加1
                    hide();
                });
            };
        };

        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    [
                        {
                            color: [],
                        },
                    ],
                    [
                        {
                            background: [],
                        },
                    ],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
            imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
            },
        };
    }, []);

    const handleCancel = () => {
        navigate(-1);
    };

    const handleSubmit = () => {
        form.validateFields().then((res) => {
            const topicVoteSettings = handleForm2Params(res);
            setLoading(true);

            updataVoteSetting({ topicVoteSettings: JSON.stringify(topicVoteSettings), id: params.tid }).then((res) => {
                setLoading(false);
                if (res.status_code === 200) {
                    message.success("设置成功！");
                    handleCancel();
                }
            });
        });
    };

    const onValuesChange = (v) => {
        if (keys.includes(Object.keys(v)[0])) {
            setCurrentValues((d) => ({ ...d, ...v }));
        }
    };

    return (
        <>
            <div className="scoring-setting page-title">
                <Link to={`/selection/projects/${params.pid}/vote/topics`} className="back">
                    <LeftOutlined />
                </Link>
                <div className="current-title">投票规则设置</div>
            </div>

            <Content className="main-content-box scoring-setting-content">
                <Form form={form} name="time" {...SelectionFormItemLayout} initialValues={currentValues} onValuesChange={onValuesChange}>
                    <h2>投票起止时间</h2>
                    <Form.Item label="投票起止时间" name="time" rules={[{ required: true, message: "请选择" }]}>
                        <DatePicker.RangePicker
                            style={{ width: 400 }}
                            showTime={{
                                hideDisabledOptions: true,
                                defaultValue: [moment("00:00", "HH:mm"), moment("11:59", "HH:mm")],
                            }}
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>

                    <h2>投票阶段提醒</h2>
                    <Form.Item label="提醒弹窗" name="rule" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={0}>不提醒</Radio>
                            <Radio value={1}>每次进入提醒</Radio>
                            <Radio value={2}>仅提醒一次</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="message"
                        label="提醒内容"
                        rules={[
                            {
                                required: rule !== 0,
                                message: "请输入",
                            },
                            {
                                validator: async (_, value) => {
                                    if (value === "") {
                                        throw new Error("请输入");
                                    } else if (value === "<p><br></p>") {
                                        throw new Error("请输入");
                                    }
                                },
                            },
                        ]}
                    >
                        <ReactQuill ref={refs} modules={modules} theme="snow" value={descriptions} onChange={onDescriptionsChange} className="ql-editor  react-editor" />
                    </Form.Item>

                    <h2>职务认证用户每次投票票数</h2>
                    <Form.Item label="注册用户" name="reggedUserTicket" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="reggedUserTicketCount" style={{ opacity: currentValues.reggedUserTicket }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="医疗机构从业者" name="A01" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="A01Count" style={{ opacity: currentValues.A01 }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="社会团体人士" name="A06" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="A06Count" style={{ opacity: currentValues.A06 }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="政府官员" name="A02" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="A02Count" style={{ opacity: currentValues.A02 }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="教育科研人士" name="A04" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="A04Count" style={{ opacity: currentValues.A04 }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="商业人士" name="A03" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="A03Count" style={{ opacity: currentValues.A03 }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <h2>机构认证每次投票票数</h2>
                    <Form.Item label="医疗机构" name="2" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="2Count" style={{ opacity: currentValues[2] }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="企业" name="0" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="0Count" style={{ opacity: currentValues[0] }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="媒体" name="1" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="1Count" style={{ opacity: currentValues[1] }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="机构" name="3" rules={[{ required: true, message: "请选择" }]} className="form-group-item">
                        <Radio.Group>
                            <Radio value={1}>可投票</Radio>
                            <Radio value={0}>不可投票</Radio>
                            <Form.Item label="每次投票加" name="3Count" style={{ opacity: currentValues[3] }}>
                                <InputNumber style={{ width: 100 }} min={1} precision={0} addonAfter="票" />
                            </Form.Item>
                        </Radio.Group>
                    </Form.Item>

                    <h2>投票阶段展示设置</h2>
                    <Form.Item label="投票按钮" name="visibleVoteButton" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>隐藏</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="案例票数" name="visibleVoteNum" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>隐藏</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="案例投票排行榜" name="visibleRank" rules={[{ required: true, message: "请选择" }]}>
                        <Radio.Group>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>隐藏</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="投票排行案例个数" name="rankCaseNum" rules={[{ required: true, message: "请输入" }]} extra="同票案例显示相同排名，顺位案例会进位展示排名；人气排行榜按设定的上榜案例个数展示，设定范围10-20；">
                        <InputNumber style={{ width: 320 }} min={1} precision={0} addonBefore="当前主题前" addonAfter="个案例展示在榜单上" />
                    </Form.Item>

                    <Form.Item
                        label="投票完整榜单查看限制"
                        name="isAuth"
                        rules={[{ required: true, message: "请选择" }]}
                        extra="开启完整榜单查看限制，未认证用户可查看榜单前3名案例"
                    >
                        <Radio.Group>
                            <Radio value={1}>认证可看</Radio>
                            <Radio value={0}>无需认证</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>

                <div className="operation-buttons">
                    <Button onClick={handleCancel}>取消</Button>
                    <Button type="primary" onClick={handleSubmit} loading={loading}>
                        保存
                    </Button>
                </div>
            </Content>
        </>
    );
};

export default Settings;
