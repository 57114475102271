import { get, put, post, delet } from '@core/request';


// 签到配置 - 保存信息
export const signInSettingsApi = (params) => {
    return post('/conference/boss/settings', params);
};

// 签到配置 - 修改信息
export const updateSignInSettingsApi = (params) => {
    return put('/conference/boss/settings', params);
};

// 签到管理 - 删除
export const deleteSignInSettingsApi = (params) => {
    return get('/conference/boss/settings', params);
};

// 签到管理列表
export const signInListApi = (params) => {
    return get('/conference/boss/settings/list', params);
};

// 签到配置信息查询
export const signInSettingInfoApi = (params) => {
    return get(`/conference/boss/settings/${params.id}`);
};

// 签到发放工作人员 - 新增
export const addSignInPersonApi = (params) => {
    return post(`/conference/boss/persons`, params);
};

// 签到发放工作人员 - 编辑
export const editSignInPersonApi = (params) => {
    return put(`/conference/boss/persons`, params);
};

// 签到发放工作人员 - 删除
export const deleteSignInPersonApi = (params) => {
    return delet(`/conference/boss/persons`, params);
};

// 签到明细列表
export const signInDetailListApi = (params) => {
    return get(`/conference/boss/checkin/list`, params);
};

// 未签到用户短信提醒
export const signInRendSmsApi = (params) => {
    return get(`/conference/boss/checkin/rendSms`, params);
};

// 确认签到
export const signInConfirmApi = (params) => {
    return post(`/conference/boss/checkin/save`, params);
};

// 获取身份配置列表
export const signInIdentityListApi = (params) => {
    return get(`/conference/boss/conferenceIdentitySupplies`, params);
};

// 签到明细新增备注
export const signInUpdateRemarkApi = (params) => {
    return put(`/conference/boss/checkin/update`, params);
};


// 签到统计
export const signInStatisticsApi = (params) => {
    return get(`/conference/boss/checkin/statistics`, params);
};


// // 下载签到二维码小程序
// export const downloadWxCodeApi = (params) => {
//     return get(`/dataengine/api/mp/wxacode/dowload`, params, {
//         responseType: 'blob'
//     });
// };
