import React, { Fragment, useState, useEffect } from 'react';
import { Button, Layout, Table, Space, Form, Input, Breadcrumb, Modal, message, Tooltip, Typography, InputNumber, DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getConstDetailApi, deleteCasescoresCase } from '@services/SereviseConst';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './index.less';

const LabourExpenses = () => {
    const { Content } = Layout;
    const [form] = Form.useForm();
    const location = useLocation()
    const navigate = useNavigate();

    const [load, setLoad] = useState(false);
    const [data, setData] = useState(undefined);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [num, setNum] = useState(undefined);
    const [filter, setFilter] = useState({});
    const onFormFinish = (res) => {
        setFilter({ ...res })
        setPage(1)
        // dispatch(setFilterPage({page:1, pageSize:filter.filter.pageSize}));
        // dispatch(setFilterForm(res));
    };
    const onReset = () => {
        form.resetFields();
        setPage(1)
        setFilter({});
        // dispatch(setFilterPage({page:1, pageSize:filter.filter.pageSize}));
        // dispatch(setFilterForm(form.getFieldsValue()));
    };

    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const delItem = (id) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `删除后该条数据在嘉宾在个人中心也会同时删除，是否确认删除？`,
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                setLoad(true);
                deleteCasescoresCase({ id: id }).then((res) => {
                    if (res && res.status_code === 200) {
                        message.success('删除成功')
                        getData();
                    } else {
                        setLoad(false)
                    }
                });
            }
        });
    };



    const columns = [
        {
            title: "嘉宾姓名",
            width: 100,
            dataIndex: "name",
            align: "center",
        },
        {
            title: "嘉宾手机号",
            dataIndex: "phone",
            width: 200,
            align: "center",
        },
        {
            title: "劳务费金额（元）",
            dataIndex: "amount",
            width: 100,
            align: "center",
            render: (_, row) => (
                <>
                    {row.amount ? <div>{row.amount}</div> : <div>/</div>}
                </>
            ),
        },
        {
            title: "是否支付",
            dataIndex: "is_paid",
            key: "is_paid",
            width: 100,
            align: "center",
            render: (_, row) => (
                <>
                    {row.paidAt ? <div>已支付</div> : <div>未支付</div>}
                </>
            ),
        },
        {
            title: "支付时间",
            dataIndex: "paidAt",
            key: "paidAt",
            width: 100,
            align: "center",
            render: (_, row) => (
                <>
                    {row.paidAt ? <div>{row.paidAt}</div> : <div>--</div>}
                </>
            ),
        },
        {
            title: "操作时间",
            dataIndex: "updatedAt",
            key: "updatedAt",
            width: 200,
            align: "center",
            render: (_, row) => (
                <>
                    {row.updatedAt ? <div>{row.updatedAt}</div> : <div>--</div>}
                </>
            ),
        },
        // {
        //     title: "操作",
        //     dataIndex: "state",
        //     key: "state",
        //     width: 400,
        //     align: "center",
        //     render: (_, row) => (
        //         <>
        //             {row.id ? <Button type='link' onClick={() => delItem(row.id)}>删除</Button> : <></>}
        //         </>
        //     ),
        // },
    ];

    const options = [
        {
            value: 0,
            label: '未支付'
        }, {
            value: 1,
            label: '已支付'
        }
    ]

    const getData = () => {
        const { code, projectId } = location?.state || {};
        getConstDetailApi({ code, projectId, ...filter, page, pageSize }).then(res => {
            setLoad(false);
            if (res.status_code === 200) {
                setData([...res.data.records])
                setTotal(res.data.total)
                setNum(res.data.num)
            }
        })
    };


    useEffect(() => {
        setLoad(true);
        getData()
    }, [page, pageSize, filter])

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/selection/projects" className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">劳务费管理</span>
            </div>
            <Content className="main-content-box" id='wj_survey_wrap'>
                <div className="main-content-body">
                    <div className="table-caption">
                        <div className="constlist-toolbar">
                            <Form
                                form={form}
                                name="control-hooks"
                                className='constlist-form'
                                onFinish={onFormFinish}
                            >
                                <Form.Item name="name" label="嘉宾姓名:">
                                    <Input placeholder="请输入嘉宾姓名" />
                                </Form.Item>
                                <Form.Item name="is_paid" label="是否支付:">
                                    <Select placeholder="请选择" options={options} style={{ width: '200px' }} />
                                </Form.Item>
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            查询
                                        </Button>
                                        <Button htmlType="button"
                                            onClick={onReset}
                                        >
                                            重置
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </div>
                        <div>
                            已支付/总嘉宾人数：{num || '0/0'}
                        </div>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={load}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />
                </div>
            </Content>
        </Fragment>
    );
}

export default LabourExpenses;
