import React, { Fragment ,useCallback} from 'react';
import { Layout, Row, Col, Tag, Space } from 'antd';
import { Link,useLocation ,useNavigate} from 'react-router-dom';
import './Interaction.less';
// import interaction from './interaction.png';
import { LeftOutlined, CommentOutlined } from '@ant-design/icons';

const { Content } = Layout;

export default function Interaction() {
    const { state } = useLocation();

    let navigate = useNavigate();
    const changePath = useCallback(
        () => {
            navigate(`/dms/lives/${state.id}/interaction/chat`,{state:state})
        },
        [navigate,state],
    );

    return (
        <Fragment>
            <div className="page-title"><Link to="/dms/lives" className="back"><LeftOutlined /></Link>互动统计<span className="page-title-tip">{state.name} [#{state.liveId}]</span></div>

            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="table-caption">
                        <Space>
                            {/* <img src={interaction} alt="" /> */}
                            <div><Tag style={{ marginBottom: '10px' }}>结束</Tag>
                                <p style={{ marginBottom: '10px' }}>数字化营销数字化营销</p>
                                <span>直播时间：2021-07-14 11:11</span></div>
                        </Space>
                    </div>
                </div>
            </Content>
            <div className="interaction">
                <div className="main-content-body">
                    <h2>互动统计</h2>
                    <Row>
                        <Col span={5} className="interaction-part">
                            <Space align="center">
                                <p className="chat-icon"><CommentOutlined /></p>
                                <div>
                                    <p>聊天(条)</p>
                                    <p className="interaction-num">10</p>
                                </div>
                            </Space>
                            <span className="look-link" onClick={changePath}>
                                查看
                            </span>
                        </Col>
                        {/* <Col offset={1} span={5} className="interaction-part">
                            <Space align="center">
                                放图片
                                <img src="" alt="" />
                                <div>
                                    <p>连麦(条)</p>
                                    <p className="interaction-num">10</p>
                                </div>
                            </Space>
                            <span>
                                <Link to="/dms/lives/:id/interaction/mike">查看</Link>
                            </span>
                        </Col> */}
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}
