import React, { Fragment, useState, useEffect } from 'react';
import { Button, Switch, Col, Dropdown, Upload, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Typography, Divider } from 'antd';
import { SearchOutlined, EllipsisOutlined, ExclamationCircleOutlined, UploadOutlined, LeftOutlined } from '@ant-design/icons';
import { filterFormItemLayout, filterFormGridLayout, pageFormItemLayout } from '@utils/property';
import {
    getTopicsTemplateApi,
    getScoreTopicsListApi,
    getStagesJudgesListApi,
    deleteStagestJudgesApi,
    updateJudgeTopicsApi,
    getProjestsApi,
    getScoreStagesDetailApi,
    uploadFile,
    addJudgesListApi,
    getProjestsTopicsListApi,
} from '@services/Selection';
import { useNavigate, useParams, Link } from 'react-router-dom';
import AddGuest from '../../components/AddGuest';
import AddJudgeList from '../../components/AddJudgeList';
import "./index.less"

const JudgeList = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { Content } = Layout;
    const { id, pid } = params || 0;
    const { Text } = Typography;
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    // 列表数据
    const [data, setData] = useState([]);
    const [queryObj, setQueryObj] = useState({});
    // 分页配置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const [topicsList, setTopicsList] = useState([]);
    const [topicsModal, setTopicsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [currentTopics, setCurrentTopics] = useState([]);

    const [visibleAddUserDrawer, setVisibleAddUserDrawer] = useState(false);
    const [visibleAddJudgeListDrawer, setVisibleAddJudgeListDrawer] = useState(false);

    const [importModal, setImportModal] = useState(false);
    const [projectTitleName, setProjectTitleName] = useState('');
    const [stageTitleName, setStageTitleName] = useState('');

    const [btnLoading, setBtnLoading] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [judgeData, setJudgesData] = useState([]);

    // 筛选
    const onFinish = (res) => {
        setPage(1);
        setQueryObj(res);
    }

    const onReset = () => {
        form.resetFields();
        setPage(1);
        setQueryObj({})
    }

    // 分页
    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const queryParams = {
        ...form.getFieldsValue(),
        page: page,
        limit: pageSize,
        stageId: id
    };

    const getProjectsTopicsList = () => {
        getProjestsTopicsListApi({ projectId: pid }).then((res) => {
            if (res && res.status_code === 200) {
                const { data = [] } = res || {};
                const list = [];
                data.forEach(item => {
                    if (item.pid == null) {
                        if (!item.hide && item.children && item.children.length) {
                            item.children.forEach(k => {
                                if (!k.hide) list.push(k)
                            })
                        }
                    } else {
                        if (!item.hide) list.push(item)
                    }
                })
                console.log(list, 'list');
                setTopicsList(list);
            }
        });
    }

    // const getProjestDetail = () => {
    //     getProjestsApi({ id: pid }).then((res) => {
    //         if (res && res.status_code === 200) {
    //             setProjectTitleName(res.data.name);
    //         }
    //     })
    // }
    // const getStageDetail = () => {
    //     getScoreStagesDetailApi({ id: id }).then((res) => {
    //         if (res && res.status_code === 200) {
    //             setStageTitleName(res.data.name);
    //         }
    //     })
    // }



    const getData = () => {
        if (!queryParams.name) delete queryParams.name;
        if (!queryParams.topic) delete queryParams.topic;
        if (!queryParams.pingfenStatus) delete queryParams.pingfenStatus;
        getStagesJudgesListApi(queryParams).then(res => {
            setLoading(false);
            if (res && res.status_code === 200) {
                const { list = [], totalCount } = res.data || {};
                setData(list);
                setTotal(totalCount)
            }
        })
    }

    const columns = [
        {
            title: '姓名', dataIndex: 'username', width: 100,
        },
        {
            title: '手机号', dataIndex: 'mobile', width: 100,
        },
        {
            title: '单位任职',
            dataIndex: 'ssoMemberWorkExperiencesEntities',
            width: 250,
            render: (_, record) => {
                return (
                    <Fragment>
                        {record?.ssoMemberExpertsVo && record?.ssoMemberExpertsVo?.ssoMemberWorkExperiencesEntities && record?.ssoMemberExpertsVo?.ssoMemberWorkExperiencesEntities.length ?
                            record?.ssoMemberExpertsVo?.ssoMemberWorkExperiencesEntities.map((item, index) => {
                                return <div key={item.id}>
                                    <span>{index + 1}.</span>
                                    <span>{item.unitName} &nbsp;</span>
                                    {item.unitOffice && <span>{item.unitOffice} &nbsp;</span>}
                                    <span>{item.unitPost}</span>
                                </div>
                            }) : <Text type="secondary">&ndash;</Text>}
                    </Fragment>
                )
            }
        },
        {
            title: '意向主题', dataIndex: 'caseJudgeIntentTopicsEntityList', width: 200,
            render: (_, record) => {
                return (
                    <Fragment>
                        {record?.caseJudgeIntentTopicsEntityList && record?.caseJudgeIntentTopicsEntityList.length ?
                            record?.caseJudgeIntentTopicsEntityList.map((item, index) => {
                                return <div key={item.id}>
                                    <span>{`第${item.intentNo}意向主题：`}</span>
                                    <span>{getMapTopics(item.topicId)}</span>
                                </div>
                            }) : <Text type="secondary">&ndash;</Text>}
                    </Fragment>
                )
            }
        },
        {
            title: '评分任务', dataIndex: 'judgesScoringTaskDtoList', width: 200,
            render: (_, record) => {
                return (
                    <Fragment>
                        {record?.judgesScoringTaskDtoList && record?.judgesScoringTaskDtoList.length ?
                            record?.judgesScoringTaskDtoList.map((item, index) => {
                                return <div key={index}>
                                    <span>{getMapTopics(item.topicId)}：</span>
                                    <span>分配{item.assignedTotal}&nbsp;|&nbsp;</span>
                                    <span>已评{item.scoringTotal}</span>
                                </div>
                            }) : <Text type="secondary">&ndash;</Text>}
                    </Fragment>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 200,
            render: (_, record) => {
                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleEdit(record)}

                        >
                            编辑
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => handleDeletd(record)}
                        >
                            移除评委
                        </Button>
                    </span>
                )
            },
        },
    ];

    useEffect(() => {
        // getProjestDetail();
        // getStageDetail();
        getProjectsTopicsList();
    }, [])

    useEffect(() => {
        setLoading(true);
        getData();

    }, [page, pageSize, queryObj])


    // 查找主题id对应关系
    const getMapTopics = (id) => {
        let obj = topicsList.filter(item => {
            return item.id == id;
        })
        return obj[0]?.name || "-";
    }

    // 模糊搜索意向主题
    const filterOption = (input, option) => {
        console.log(option,input);
        const { name } = option.data || {};
        return (
            name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    }

    //excel校验格式
    const fileCheck = (file) => {
        const isExcel = file.type === ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel');
        if (!isExcel) {
            message.error("请上传Excel格式附件");
            setFileList([]);
            setJudgesData([]);
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("请上传10MB以内的附件!");
            setFileList([]);
            setJudgesData([]);
        }
        return isExcel && isLt10M
    }

    const uploadProps = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        onRemove() {
            setFileList([]);
            setJudgesData([]);
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('filename', info.file);
            formData.append('projectId', pid);
            const option = {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            uploadFile(formData,option).then((res) => {
                if (res && res.status_code === 200) {
                    const { data = [] } = res || {};
                    setFileList([info.file])
                    setJudgesData(data)
                } else {
                    setFileList([]);
                    setJudgesData([]);
                }
            })
        },
    };



    // 批量导入弹窗
    const handleImport = () => {
        setFileList([]);
        setJudgesData([]);
        setImportModal(true);
    }

    const handleImportFile = () => {
        setImportModal(false);
        setVisibleAddJudgeListDrawer(true);
    }

    const handleCancelImportFile = () => {
        setFileList([]);
        setJudgesData([]);
        setImportModal(false);
    }

    // 移除评委
    const handleDeletd = (row) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确认是否移除评委：${row.username}`,
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: () => {
                setLoading(true);
                deleteStagestJudgesApi({ id: row.id }).then(res => {
                    if (res && res.status_code === 200) {
                        if (res.data) {
                            message.success('移除成功');
                            if (page == 1) {
                                getData();
                            } else {
                                setPage(1);
                            }
                        } else {
                            message.warning(res.message)
                            getData();
                        }
                    } else {
                        setLoading(false)
                    }
                })
            }
        });
    }

    // 成功导入
    const handleAddJudgesListModal = (total, success) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `共读取到${total}条数据，成功导入${success}条，未导入${total - success}条`,
            okText: '确认',
            centered: true,
            cancelButtonProps: { style: { display: 'none' } },
            onOk: () => {
                setLoading(true);
                getData();
            }
        });
    }

    // 编辑
    const handleEdit = (row) => {
        setCurrentStatus(row.id);
        let editArr = new Array(3).fill("");
        row?.caseJudgeIntentTopicsEntityList.forEach(item => {
            editArr[item.intentNo - 1] = item
        })
        form1.setFieldValue('topicIds', editArr);
        setTopicsModal(true);
    }

    // 添加
    const addJudge = () => {
        form1.setFieldValue('topicIds', ["", "", ""]);
        setTopicsModal(true);
        setCurrentStatus(0);
        setCurrentTopics([]);
    }


    const handleEditTopics = () => {
        form1.validateFields().then((value) => {
            if (value.topicIds.every(item => item === '')) {
                return message.warning('意向主题最少选择1个')
            }
            if (currentStatus) {
                // 编辑
                setLoading(true);
                updateJudgeTopicsApi({
                    id: currentStatus,
                    type1: value.topicIds[0]?.topicId || "",
                    type2: value.topicIds[1]?.topicId || "",
                    type3: value.topicIds[2]?.topicId || "",
                }).then(res => {
                    if (res && res.status_code === 200) {
                        getData();
                    } else {
                        setLoading(false)
                    }
                })
                setTopicsModal(false);
            } else {
                // 新增
                setCurrentTopics([...value.topicIds]);
                setTopicsModal(false);
                setVisibleAddUserDrawer(true);
            }
        })

    }

    const handleCancelEdit = () => {
        setTopicsModal(false);
    }

    // 添加评委
    const closeAddUserDrawer = () => {
        setVisibleAddUserDrawer(false);
    }

    const onResetGuest = () => {
        setLoading(true);
        getData();
    }

    const closeAddJudgeListDrawer = () => {
        setVisibleAddJudgeListDrawer(false);
    }

    const handleImportTemplate = () => {
        getTopicsTemplateApi({
            projectId: pid,
            stageId: id,
        }).then((res) => {
            if (res.size !== 0) {
                message.success('请稍后，模板正在下载');
                const fileName = "导入评委意向主题报表模板.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            }
        })

    }

    const handleAddJudgesList = () => {
        setBtnLoading(true);
        const { list = [] } = judgeData || {};

        addJudgesListApi({
            projectId: pid,
            stageId: id,
            caseJudgesDownVos: [...list]
        }).then(res => {
            setBtnLoading(false);
            setVisibleAddJudgeListDrawer(false);
            if (res && res.status_code === 200) {
                const { total, success } = res?.data || {};
                handleAddJudgesListModal(total, success);
            }
        })
    }


    return (
        <>
            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Form
                        {...filterFormItemLayout}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="评委姓名" name="name" >
                                    <Input allowClear placeholder="请输入评委姓名" autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="手机号" name="mobile" >
                                    <Input allowClear placeholder="请输入手机号" autoComplete='off' />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="意向主题" name="topic">
                                    <Select placeholder='请选择意向主题'
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                    >
                                        {topicsList && topicsList.map((item) => (
                                            <Select.Option key={item.id} value={item.id} data={item}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="评分任务" name="pingfenStatus">
                                    <Select placeholder='请选择评分任务' defaultValue={0}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        <Select.Option value={1}>全部评完</Select.Option>
                                        <Select.Option value={2}>未评完</Select.Option>
                                        <Select.Option value={3}>已分配</Select.Option>
                                        <Select.Option value={4}>未分配</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <Space size='middle'>
                            <Button type="primary" onClick={addJudge}>添加评委</Button>
                            <Button type="default" onClick={handleImportTemplate}>导入模版下载</Button>
                            <Button type="default" onClick={handleImport}>批量导入</Button>
                        </Space>
                    </div>

                    <Table
                        className="judge-table"
                        rowKey={item => item.id}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        pagination={{
                            total: total,
                            showTotal: () => `共${total}条`,
                            current: page,
                            pageSize: pageSize,
                            onChange: onChangePage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                        }}
                    />

                </div>
            </Content >

            <Modal
                className='mark-change-modal'
                title='选择意向主题'
                open={topicsModal}
                destroyOnClose={true}
                centered
                maskClosable={false}
                onCancel={handleCancelEdit}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleCancelEdit}>取消</Button>
                            {currentStatus ? <Button type="primary" htmlType="submit" onClick={handleEditTopics}>确定</Button> :
                                <Button type="primary" htmlType="submit" onClick={handleEditTopics}>添加邀请评委</Button>}
                        </Space>
                    </div>
                }
            >
                <Form
                    className="judge-form"
                    form={form1}
                >
                    <Form.List
                        name="topicIds"
                    >
                        {(fields, { add, remove }) => (
                            <Fragment>
                                <Form.Item
                                    style={{
                                        marginBottom: '10px'
                                    }}
                                >
                                    <div className='judges-modal-topics-tips-box'>
                                        <span className='judges-modal-topics-tips-box-title'>评委意向主题: </span>
                                        <Text className='judges-modal-topics-tips-box-text' type="secondary">意向主题最少选择1个；如果3个意向主题相同，请分别选择相同意向主题；</Text>
                                    </div>
                                </Form.Item>
                                {fields.map(({ key, name, ...restFields }, index) => (
                                    <Space key={key} align='start' className='judge-form-space'>
                                        <Form.Item
                                            {...restFields}
                                            label={`意向主题${index + 1}`}
                                            name={[name, 'topicId']}
                                        >
                                            <Select placeholder='请选择意向主题'
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={filterOption}
                                                className='judge-form-space-select'
                                            >
                                                {topicsList && topicsList.map((item) => (
                                                    <Select.Option key={item.id} value={item.id} data={item}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>

                                        </Form.Item>
                                    </Space>
                                ))}
                            </Fragment>
                        )}
                    </Form.List>
                </Form>
            </Modal>

            <Modal
                className='mark-import-modal'
                title='导入评委'
                open={importModal}
                centered
                maskClosable={false}
                onCancel={handleCancelImportFile}
                onOk={handleImportFile}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={handleCancelImportFile}>取消</Button>
                            <Button type="primary" htmlType="submit" disabled={!(fileList && fileList.length)} onClick={handleImportFile}>读取数据</Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    className="judge-form2"
                    form={form2}
                >
                    <Form.Item
                        name="excel"
                        label="导入文件"
                        help="支持Excel格式,文件大小不超过10MB"
                    >
                        <Upload
                            beforeUpload={(file) => fileCheck(file)}
                            {...uploadProps}
                            maxCount={1}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>选择文件</Button>
                        </Upload>

                    </Form.Item>
                </Form>
            </Modal>

            <AddGuest
                visibleAddUserDrawer={visibleAddUserDrawer}
                closeAddUserDrawer={closeAddUserDrawer}
                setVisibleAddUserDrawer={setVisibleAddUserDrawer}
                onResetGuest={onResetGuest}
                currentTopics={currentTopics}
            />
            <AddJudgeList
                visibleAddJudgeListDrawer={visibleAddJudgeListDrawer}
                closeAddJudgeListDrawer={closeAddJudgeListDrawer}
                setVisibleAddJudgeListDrawer={setVisibleAddJudgeListDrawer}
                handleAddJudgesList={handleAddJudgesList}
                judgeData={judgeData}
                btnLoading={btnLoading}

            />
        </>

    );
}

export default JudgeList;
