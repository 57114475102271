import { get, put, post, patch, delet } from '@core/request';
import ucenterHttp from '@core/ucenterHttp';
import config from '@config';

// 医项目列表
export const fetchMedProjects = params => get('/medproject/boss/getProjects', params);
// 医项目详情
export const showMedProject = id => get('/medproject/boss/getById', { id });
// 编辑医项目
export const createMedProject = data => post('/medproject/boss/save', data);
// 更改医项目状态（上下架）
export const changeMedProjectStatus = data => patch('/medproject/boss/setStatus', data);
// 编辑医项目
export const editMedProject = data => put('/medproject/boss/update', data);
// 精彩内容模块-内容列表
export const fetchContentModuleContents = params => get('/medproject/boss/modulecontentmap/list', params);
// 精彩内容模块-添加内容
export const addContentModuleContents = data => post('/medproject/boss/modulecontentmap/save', data);
// 精彩内容模块-设置内容状态
export const changeContentModuleContentsState = data => patch('/medproject/boss/modulecontentmap/setState', data);
// 精彩内容模块-排序
export const sortContentModuleContents = data => patch('/medproject/boss/modulecontentmap/sort', data);
// 精彩内容模块-移除内容
export const removeContentModuleContents = id => delet(`/medproject/boss/modulecontentmap/delete`, { contentId: id });
// 精彩内容模块-显示/隐藏
export const changeContentModuleStatus = data => patch('/medproject/boss/modulecontentmap/setState', data);


// 平台文章列表
export const fetchArticles = params => get('/medproject/boss/content/articles', params);
// 平台课程分类
export const fetchCourseCategories = () => get('/medproject/boss/content/courses/catelogs');
// 平台课程/直播列表
export const fetchVideoAndLiveCourses = params => get('/medproject/boss/content/courses', params);
// 案例列表
export const fetchCases = params => get('/medproject/boss/content/selection/cases/search', params);

// 医会议直播列表
export const fetchConferenceLive = params => get('/medproject/boss/content/conference/lives', params);


// medProject文件上传  type-21
export const medProjectFileApi = data => post('/medproject/boss/file', data, { headers: { "Content-Type": "multipart/form-data" } });

// 获取项目信息
export const getMedProjectByIdApi = (params) => {
    return get('/medproject/boss/getById', params);
}


// 快捷入口 新增-名字查重
export const quickEntriesByNameApi = (params) => {
    return get('/medproject/boss/quickentries/getByName', params);
}

// 快捷入口 新增/修改
export const quickEntriesSaveApi = (params) => {
    return post('/medproject/boss/quickentries/saveOrUpdate', params);
}

// 快捷入口 列表
export const quickEntriesListApi = (params) => {
    return get('/medproject/boss/quickentries/list', params);
}

// 快捷入口 列表-更新状态
export const quickEntriesStatusApi = (params) => {
    return put('/medproject/boss/quickentries/setStatus', params);
}

// 快捷入口 列表-更新排序
export const quickEntriesSortApi = (params) => {
    return put('/medproject/boss/quickentries/sort', params);
}

// 快捷入口 列表-删除
export const quickEntriesDeleteApi = (params) => {
    return get('/medproject/boss/quickentries/delete', params);
}


// 通知管理 列表
export const noticesListApi = (params) => {
    return get('/medproject/boss/notices/list', params);
}

// 通知管理 新增
export const noticesSaveApi = (params) => {
    return post('/medproject/boss/notices/save', params);
}

// 通知管理 更新
export const noticesUpdateApi = (params) => {
    return put('/medproject/boss/notices/update', params);
}

// 通知管理 详情
export const noticesInfoApi = (params) => {
    return get('/medproject/boss/notices/info', params);
}

// 通知管理 删除
export const delNoticesInfoApi = (params) => {
    return delet('/medproject/boss/notices/delete', params);
}


// 成员管理 成员列表
export const membersListApi = (params) => {
    return post('/medproject/boss/members/list', params);
}

// 成员管理 根据id查成员信息
export const membersInfoApi = (params) => {
    return get('/medproject/boss/members/info', params);
}

// 成员管理 根据手机号查成员信息
export const membersInfoByMobileApi = (params) => {
    return get('/medproject/boss/members/memInfoByMobile', params);
}

// 成员管理 成员新增
export const membersSaveApi = (params) => {
    return post('/medproject/boss/members/save', params);
}

// 成员管理 成员更新
export const membersUpdateApi = (params) => {
    return put('/medproject/boss/members/update', params);
}

// 成员管理 成员通过审核
export const membersPassApi = (params) => {
    return get('/medproject/boss/members/review/pass', params);
}

// 成员管理 成员驳回审核
export const membersRejectApi = (params) => {
    return post('/medproject/boss/members/review/reject', params);
}

// 成员管理 成员移除
export const membersDeleteApi = (params) => {
    return delet('/medproject/boss/members/delete', params);
}

// 成员管理 待审核数量
export const membersCountApi = (params) => {
    return get('/medproject/boss/members/countByStatus', params);
}


// 项目模块 左侧模块列表
export const moduleListApi = (params) => {
    return get('/medproject/boss/modules/getModules', params);
}

// 项目模块 模块排序
export const moduleSortApi = (params) => {
    return put('/medproject/boss/modules/sort', params);
}

// 项目模块 模块新增
export const moduleSaveApi = (params) => {
    return post('/medproject/boss/modules/save', params);
}

// 项目模块 模块修改
export const moduleUpdateApi = (params) => {
    return put('/medproject/boss/modules/update', params);
}

// 项目模块 模块根据ID查询（项目详情/自由配置模块）
export const moduleGetByIdApi = (params) => {
    return get('/medproject/boss/modules/getById', params);
}

// 项目模块 模块删除
export const moduleDeleteApi = params => get('/medproject/boss/modules/delete', params);

// 项目模块 解决方案列表
export const solutionListApi = params => get('/medproject/boss/content/solutions/search', params);

export const putScheduleData = data => put('/medproject/boss/modules/updateScheduleData', data);

// 根据memCards查询用户信息
export const getSsoMemberByMemCardsApi = (params) => {
    return get('/medproject/boss/members/getSsoMemberByMemCards', params);
}

// 根据 memCard 查询用户认证信息
// 审核中，获取最新的认证提交信息（sso_member_update）
export const auditInfoBymemCardApi = (params) => {
    return get('/medproject/boss/members/auditInfoBymemCard', params);
}

// 查询用户认证信息（已通过 或 已驳回，获取认证信息的结果）
export const auditSsoMemberAuthApi = (params) => {
    return ucenterHttp.get(`${config.UCENTER_PREFIX}/wapi/member6/findMemInfoByMemcards`, params);
}

// 驳回申请
export const auditDismissedApi = (params) => {
    return post('/medproject/boss/members/auditDismissed', params);
}

// 用户通过审核
export const auditThroughApi = (params) => {
    return post('/medproject/boss/members/auditThrough', params);
}

// 活动列表
export const getAddTrainingListApi = (params) => {
    return get('/medproject/boss/content/healthclass/trainingProjects', params);
}

