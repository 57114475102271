import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Form, Space, Tooltip, Table, Select, Row, Col, Typography, DatePicker } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import config from '@config';
import '../index.less';
import { filterFormGridLayout, filterFormItemLayout } from '@utils/property';
import { liveReservationsApi } from "@services/Conference";

const { RangePicker } = DatePicker;
const { Text } = Typography

export default function Appointment() {
    const params = useParams();
    const liveId = parseInt(params?.id) || 0; // 直播 ID
    const [form] = Form.useForm();

    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [queryObj, setQueryObj] = useState({});
    // 分页
    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onFinish = (res) => {
        setPage(1);
        const dateRange = res?.dateRange || [];
        const startTime = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined;
        const endTime = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined;
        setQueryObj({
            phone: res?.phone || undefined,
            startTime,
            endTime,
            isRegister: res?.isRegister
        })
    };

    // 重置
    const onReset = () => {
        setPage(1);
        form.resetFields();
        setQueryObj({
            phone: undefined,
            startTime: undefined,
            endTime: undefined,
            isRegister: undefined
        });
    };

    // 刷新
    const handleRefresh = () => {
        setPage(1);
        form.resetFields();
        setQueryObj({
            phone: undefined,
            startTime: undefined,
            endTime: undefined,
            isRegister: undefined
        });
    };

    // 修改刷新数据列表
    const getListData = async (page, pageSize, queryObj) => {
        const res = await liveReservationsApi({ liveId, page, pageSize, ...queryObj });
        if (res.status_code === 200 && res.data) {
            const list = res.data.list || [];
            const newList = list.map(item => {
                const newItem = { ...item, ...item.member };
                delete newItem.member;
                return newItem;
            })
            setData(newList);
            setTotal(res.data.totalCount);
        }
    };

    const getStatusStyle = (str) => {
        if (str === '已认证') {
            return <Text type="success">已认证</Text>;
        }
        if (str === '审核中') {
            return <Text type="warning">审核中</Text>;
        }
        if (str === '已驳回') {
            return <Text type="danger">已驳回</Text>;
        }
        return '-';

    };

    const columns = [
        { title: '预约时间', dataIndex: 'reservationTime', width: 300 },
        {
            title: '当前认证身份',
            width: 300,
            render: (_, record) => {
                return <>
                    <div>身份：{record.memIdentity || '-'}</div>
                    <div>类型：{record.memType || '-'}</div>
                    <div>状态：{getStatusStyle(record.memAuthStatus)}</div>
                </>;
            }
        },
        {
            title: '手机号',
            width: 200,
            render: (_, record) => {
                return <>
                    <div>{record.memMobile || '-'}</div>
                    <div>昵称：{record.memNickname || '-'}</div>
                </>;
            }
        },
        {
            title: '来源医项目',
            width: 300,
            render: (_, record) => {
                return <>
                    <div>{record.mpName || '-'}</div>
                    <div>医项目ID：{record.mpid || '-'}</div>
                </>;
            }
        },
        { title: '姓名', dataIndex: 'memUsername', width: 200, render: (_, record) => (record.memUsername || '-')  },
        { title: '单位', dataIndex: 'memUnit', width: 200, render: (_, record) => (record.memUnit || '-')  },
        { title: '科室', dataIndex: 'memOffice', width: 200, render: (_, record) => (record.memOffice || '-')  },
        { title: '职务', dataIndex: 'memPost', width: 200, render: (_, record) => (record.memPost || '-')  },
        { title: '职称', dataIndex: 'memJobtitle', width: 200, render: (_, record) => (record.memJobtitle || '-') },
        {
            title: '是否提交报名',
            width: 200,
            render: (_, record) => {
                return <>
                    <div>{record.isRegister === 1 ? '已提交' : '未提交'}</div>
                </>;
            }
        },
    ];

    // 导出数据
    const exportData = async () => {
        window.location.href = `${config.API_PREFIX}/conference/livereservations/download?liveId=${liveId}`;
    };

    useEffect(() => {
        getListData(page, pageSize, queryObj);
    }, [queryObj, page, pageSize])

    return <Fragment>
        <div className="main-content-body">
            <div className="live-signup-search">
                <Form form={form} autoComplete="off" name="form" {...filterFormItemLayout} onFinish={onFinish}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="phone" label="手机号">
                                <Input
                                    placeholder="请输入手机号"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item
                                name="dateRange"
                                label="预约日期"
                            >
                                <RangePicker allowClear={false} />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="isRegister" label="是否报名">
                                <Select placeholder="请选择">
                                    <Select.Option value={0}>未报名</Select.Option>
                                    <Select.Option value={1}>已报名</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="primary" htmlType="submit">检索</Button>
                                <Button type="default" onClick={onReset}>重置</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="signup-toolbar">
                <div className="sub-tools">
                    <Button type="primary" onClick={exportData}>导出数据</Button>
                    <div className="refresh-btn">
                        <Tooltip title="刷新列表">
                            <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}></Button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: total,
                    showTotal: () => `共${total}条`,
                    current: page,
                    pageSize: pageSize,
                    onChange: onChangePage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                }}
                scroll={{ x: 1400 }}
            />

        </div>
    </Fragment>;
}
