import React from 'react';
import {Form, Row, Col, Input, Select, Space, Button} from 'antd';
import {filterFormItemLayout, filterFormGridLayout} from '@utils/property';
import {SearchOutlined} from '@ant-design/icons';

export default function StaffListFilterForm({handleFilterFinish}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        handleFilterFinish(values);
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            name: undefined,
            phone: undefined,
            email: undefined,
            status: undefined
        }
        handleFilterFinish(values);
    };

    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>
                <Col {...filterFormGridLayout}>
                    <Form.Item label="姓名" name="name">
                        <Input allowClear placeholder="员工姓名" autoComplete='off'/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="手机号" name="phone">
                        <Input allowClear placeholder="员工手机号" autoComplete='off'/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="邮箱地址" name="email">
                        <Input allowClear placeholder="员工邮箱地址" autoComplete='off'/>
                    </Form.Item>
                </Col>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="状态" name="status">
                        <Select allowClear placeholder="选择账号状态">
                            <Select.Option value={0}>正常</Select.Option>
                            <Select.Option value={1}>冻结</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
