import React, { Fragment, useCallback, useRef, useEffect, useState } from 'react';
import { Layout, Table, Modal, Descriptions, message } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import UserEdit from './UserEdit';
import { get, put } from '@/core/request';

const { Content } = Layout;

export default function UserDetails() {

    const { state } = useLocation();
    const [tabledata, setTableData] = useState([]);
    const [message1, setMessage] = useState({});

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const getData = () => {
        get(`/dms/users/user-detailPage`, {
            limit: pageSize,
            page: currPage,
            userId: state.id
        }).then((res) => {
            if (res.status_code === 200) {
                const { page, phone, userId } = res.data
                setMessage({ phone: phone, userId: userId })
                page.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(page);
            }
        })
    };

    useEffect(() => {
        getData()
    }, [state.id, pageSize, currPage]);


    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 新增drawer相关
    const drawer = useRef();
    const handleClickAdd = useCallback(
        (row) => {
            return () => {
                drawer.current.show(row)
            }
        },
        [],
    );

    // 点击操作区禁用
    const stopConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用选中标签',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.status === '正常') {
                        status = 2;
                    }
                    get('/dms/users/user-updateStatus', {
                        id: row.id,
                        status
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData()
                        }
                    })
                }
            });
        }
    };

    // 点击编辑
    const handleFinishEdit = (values) => {
        put(`/dms/users/update`, values).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                getData()
            }
        })
    }

    const columns = [
        { title: '姓名', dataIndex: 'name' },
        { title: '性别', dataIndex: 'gender', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgName', },
        { title: '所在医院', dataIndex: 'hospital', },
        { title: '所在城市', dataIndex: 'cityName', },
        { title: '所在科室', dataIndex: 'departmentName', },
        { title: '职称', dataIndex: 'titleName', },
        { title: '注册时间', dataIndex: 'registeredAt', },
        { title: '认证时间', dataIndex: 'authedAt', },
        { title: '注册来源', dataIndex: 'platform', },
        { title: '用户状态', dataIndex: 'status' },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={handleClickAdd(row)}>编辑</span>
                    <span className="operate-style" onClick={stopConfirm(row)}>{row.status === '正常' ? '禁用' : '启用'}</span>
                </Fragment>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">
                <Link to="/dms/users" className="back"><LeftOutlined /></Link>用户详情</div>

            <Content className="main-content-box">
                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">基本信息</div>
                        <Descriptions column={4} style={{ marginTop: '20px' }}>
                            <Descriptions.Item label="手机号">{message1.phone}</Descriptions.Item>
                            <Descriptions.Item label="用户ID">{message1.userId}</Descriptions.Item>
                        </Descriptions>
                    </div>

                    <div className="table-caption">
                        <div className="table-title">产品线信息</div>
                    </div>

                    <Table className="playback-table" columns={columns}
                        dataSource={tabledata.list} pagination={paginationProps} />

                </div>
            </Content>

            <UserEdit setTableData={setTableData} ref={drawer} handleFinishEdit={handleFinishEdit}></UserEdit>

        </Fragment>
    )
}
