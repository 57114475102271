import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Layout, Tabs } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { getLiveOneByIdApi } from "@services/Conference";
import './index.less';
import Appointment from './components/Appointment';
import Signup from './components/SignUp';

const { Content } = Layout;

export default function LiveComments() {
    const params = useParams();
    const conferenceId = parseInt(params?.cid) || 0; // 会议项目 ID
    const liveId = parseInt(params?.id) || 0; // 直播 ID

    const [liveOneData, setLiveOneData] = useState({});
    const [tableComponents, setTableComponents] = useState(<Appointment />);

    // tab切换
    const onTabChange = (key) => {
        if (key === '1') {
            setTableComponents(<Appointment />);
        }
        if (key === '2') {
            setTableComponents(<Signup />);
        }
    };

    // 获取直播信息
    useEffect(() => {
        getLiveOneByIdApi({ id: liveId }).then((res) => {
            if (res.status_code === 200) {
                setLiveOneData(res.data)
            }
        })
    }, [])

    return <Fragment>
        <div className="page-title">
            <Link to={`/conf/conferences/${conferenceId}/lives`} className="back"><LeftOutlined /></Link>
            <span className="current-title">预约报名数据</span>
            {!!liveId && <span className="current-subtitle">{liveOneData.name}</span>}
        </div>

        <Content className="main-content-box live-signup-page">

            <Tabs
                defaultActiveKey="1"
                onChange={onTabChange}
                items={[
                    {
                        label: '预约数据',
                        key: '1'
                    },
                    {
                        label: '报名数据',
                        key: '2'
                    }
                ]}
            />

            <div className="table-components">{tableComponents}</div>

        </Content>
    </Fragment>;
}
