import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import AnswerTable from './AnswerTable';
import ShortAnswer from './ShortAnswer';
import RadioAnswer from './RadioAnswer';
import CheckboxAnswer from './CheckboxAnswer';
import { Link, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { get } from '@/core/request';
import './SurveysData.less';

export default function SurveysData() {
    const { state } = useLocation();
    const [dataGeneralize, setDataGeneralize] = useState({})

    // 渲染数据概括
    useEffect(() => {
        get(`/dms/survey/records/queryDataOverview`, {
            id: state.surveyId
        }).then((res) => {
            if (res.status_code === 200) {
                setDataGeneralize(res.data)
            }
        })
    }, [state.surveyId]);


    // 渲染答题统计
    const [answerDataCurrPage, setAnswerDataCurrPage] = useState(1);
    const [answerData, setAnswerData] = useState({});
    useEffect(() => {
        get(`/dms/survey/records/queryUserCount`, {
            id: state.surveyId,
            page: answerDataCurrPage
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setAnswerData(res.data);
            }
        })
    }, [state.surveyId, answerDataCurrPage]);


    // 统计报表 - 简答题
    const [shortAnswer, setShortAnswer] = useState({});
    const [shortAnswerCurrPage, setShortAnswerCurrPage] = useState(1);
    useEffect(() => {
        get(`/dms/survey/records/queryCompletionCount`, {
            id: state.surveyId,
            page: shortAnswerCurrPage
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setShortAnswer(res.data);
            }
        })
    }, [state.surveyId, shortAnswerCurrPage]);


    // 统计报表 - 选择题
    const [choiceQuestion, setChoiceQuestion] = useState({});
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        get(`/dms/survey/records/queryChoiceCount`, {
            id: state.surveyId
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.forEach(item => {
                    item.key = item.questionId;
                    item.choiceCountVos.forEach(elem=>{
                        elem.key = elem.id;
                    })
                });
                setChoiceQuestion(res.data)
                setVisible(true)
            }
        })
    }, [state.surveyId]);


    return (
        <Fragment>
            <div className="page-title"><Link to="/dms/surveys" className="back"><LeftOutlined /></Link>问卷统计</div>
            <div className="data-common">
                <h2>数据概括</h2>
                <div className="data-content">
                    <Row>
                        <Col span={6}>
                            <p>今日新增数据</p>
                            <div>{dataGeneralize.daySave}</div>
                        </Col>
                        <Col span={6}>
                            <p>数据总量</p>
                            <div>{dataGeneralize.count}</div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="data-common">
                <h2>答题统计</h2>
                <div className="data-content">
                    <AnswerTable setCurrPage={setAnswerDataCurrPage} answerData={answerData} currPage={answerDataCurrPage}></AnswerTable>
                </div>
            </div>

            <div className="data-common">
                <h2>统计报表</h2>
                <div className="data-content">
                    <h2>简答题</h2>
                    <ShortAnswer setCurrPage={setShortAnswerCurrPage} answerData={shortAnswer} currPage={shortAnswerCurrPage}></ShortAnswer>
                    <h2>选择题</h2>
                    {
                        visible && choiceQuestion.map((item,index) => {
                            if (item.type === 1) {
                                return (
                                    <RadioAnswer key={item.questionId} data={item} index={index}></RadioAnswer>
                                )
                            } else {
                                return (
                                    <CheckboxAnswer key={item.questionId} data={item} index={index}></CheckboxAnswer>
                                )
                            }
                        })
                    }
                </div>
            </div>

        </Fragment>
    )
}
