import React, {Fragment, useState,useEffect} from 'react';
import {Link, useNavigate,useParams} from 'react-router-dom';
import {
    Button,
    Form,
    Input,
    Layout,
    Space,
    Row,
    Col,
    Drawer,
    Popconfirm,
    message,
    Radio,
    Modal,
} from 'antd';
import { PlusOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import {isEmpty} from '@utils/utils';
import {drawerWidth} from '@utils/property';
import { guestMessageChangeApi,guestMessageOnceApi }  from "@services/Guest";
import '../../GuestList.less';
import './index.less';
const GuestMessage = ({visibleGuestDisplayInfoDrawer,closeGuestDisplayInfoDrawer,rowItem}) => {
    const [form]=Form.useForm()
    const { TextArea } = Input;
    const [guestMessage, setGuestMessage] = useState(undefined);
    const params=useParams()
 // 保存嘉宾展示信息
 const saveGuestDisplayInfo = () => {
    // TODO
    let newArr=[]
   if(form.getFieldValue('displayUnits')!==undefined){
    let experiences=form.getFieldValue('displayUnits')
         newArr = experiences?.filter(v => {
            if (!v?.unitName) {
                delete v?.unitName;
            }else if (v.childs) {
                v.childs = v?.childs?.filter(it => {
                    if (it) {
                        let o = Object.values(it).filter(
                            i => i
                        )
                        if (o.length) return it!==undefined
                    }
                })

            } else if (!v.childs) {
                v.childs = [];
            }
            if (JSON.stringify(v) !== "{}") {
                return v;
             }
        })
        newArr.filter(it=>it!==undefined)
   }


   let obj={
    ...form.getFieldsValue(),
    displayUnits:JSON.stringify(newArr),
    conferenceId:params.id,
    memCard:rowItem.memCard,
    id:guestMessage.id
   }
   guestMessageChangeApi(obj).then(
    res=>{
        if(res.status_code === 200){
            form.resetFields()
            closeGuestDisplayInfoDrawer()
        }
    }
   )
};
    useEffect(() => {
        if(visibleGuestDisplayInfoDrawer){
            guestMessageOnceApi({
                conferenceId:params.id,
                memCard:rowItem.memCard,
                expertId:rowItem.id
            }).then(res=>{
                let message=res.data
                if(message){
                    res.data.displayUnits=res.data?.displayUnits?JSON.parse(res.data.displayUnits):undefined;
                    form.setFieldsValue(res.data);
                    setGuestMessage(res.data);
                }
            })
        }
    }, [visibleGuestDisplayInfoDrawer]);

    return (
        <div>
           <Modal
            title="嘉宾展示信息"
            width={1000}
            open={visibleGuestDisplayInfoDrawer}
            onClose={closeGuestDisplayInfoDrawer}
            onCancel={closeGuestDisplayInfoDrawer}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={closeGuestDisplayInfoDrawer}>取消</Button>
                        <Button type="primary" onClick={saveGuestDisplayInfo}>确定</Button>
                    </Space>
                </div>
            }
        >
            <div>
            <h1>真实信息展示</h1>
            <Row>
                            <Col span={1}>
                            姓名:
                            </Col>
                            <Col>
                            <p>{rowItem?.memUsername}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col span={2}>
                            单位任职:
                            </Col>
                            {rowItem?.ssoMemberWorkExperiencesEntities &&
                            rowItem.ssoMemberWorkExperiencesEntities.length >
                                0 ?(
                                    rowItem.ssoMemberWorkExperiencesEntities.map(
                                        (item, index) => {
                                            return (
                                                item?.unitName?
                                               ( <p key={item.id}>
                                                    {item.unitName && <span>{index + 1}.</span>}
                                                    {item.unitName && (
                                                        <span>
                                                            {item.unitName}&nbsp;
                                                        </span>
                                                    )}
                                                    {item.unitOffice && (
                                                        <span>
                                                            {item.unitOffice}&nbsp;
                                                        </span>
                                                    )}
                                                    {item.unitPost && (
                                                        <span>
                                                            {item.unitPost}&nbsp;
                                                        </span>
                                                    )}
                                                </p>)
                                                :'暂无'
                                            );
                                        }
                                    )
                                )
                                :'暂无'
                                }
                                </Row>
                                <Row>
                            <Col span={2}>
                                    个人介绍:
                            </Col>
                            <Col>
                            <p>
                            {rowItem?.skilledFields && <span>{rowItem.skilledFields},</span>}
                            {rowItem?.specialization &&  <span>{rowItem.specialization}</span>}
                            {rowItem?.specialization===null&&rowItem?.specialization===null&&'暂无'}
                            </p>
                            </Col>
                            </Row>
            </div>
       <div>
        <h1>虚拟嘉宾信息</h1>
        <Form form={form}>
            <Form.Item
                            name="enableDisplayInfo"
                            label="状态"
                        >
                            <Radio.Group>
                                <Radio value={1}>启用</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="displayName"
                            label="姓名"
                        >
                          <Input/>
                        </Form.Item>
                        <Form.Item
                    label='单位任职'
                    className='experiences'
                >
                    <Form.List
                        name='displayUnits'

                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                <Button
                                    className="add-section-btn"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                    新增单位任职
                                </Button>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div className='section-box' key={key}>
                                        <div className='section-index'>{index + 1}</div>
                                        <div className='others-context'>
                                            <Form.Item
                                                className='section'
                                                {...restField}
                                                name={[name, 'unitName']}
                                                label='单位名称'
                                            >
                                                <Input placeholder='请输入单位全称' />
                                            </Form.Item>

                                            <Form.List
                                                name={[name, 'childs']}
                                            >
                                                {(childs, { add: addtest, remove: removetest }) => (
                                                    <>
                                                        <Button
                                                            className='add-section-btn'
                                                            onClick={() => addtest()}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            添加职称
                                                        </Button>
                                                        {childs.map(({ key: childKey, name: childName, ...restField }, idx) => (<>
                                                            <div className='position' key={childKey}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="科室/部门"
                                                                    name={[childName, 'unitOffice']}
                                                                    className='position-item'
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="职务"
                                                                    name={[childName, 'unitPost']}
                                                                    className='position-item'
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="职称"
                                                                    name={[childName, 'unitJobTitle']}
                                                                    className='position-item'
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                                {/* {(guestMessage.displayUnits&&form.getFieldValue()?.experiences?.[index]?.childs?.[0] !== undefined) ?
                                                                <Popconfirm
                                                                    title={form.getFieldValue()?.experiences?.[index]?.childs?.[idx]?.unitJobTitle?`确定删除该${form.getFieldValue()?.experiences?.[index]?.childs?.[idx]?.unitJobTitle}职称么`:`确定删除该职称么`}
                                                                    onConfirm={() => {
                                                                        if (!form.getFieldValue()?.experiences?.[index].childs?.[idx]) {
                                                                            removetest(childName)
                                                                        }
                                                                    }}
                                                                    onCancel={() => { }}
                                                                    okText="确定"
                                                                    cancelText="取消"
                                                                >
                                                                    <Button
                                                                        type='text'

                                                                        icon={<DeleteOutlined className='del-section' />}
                                                                        danger
                                                                    >
                                                                    </Button>
                                                                </Popconfirm>
                                                                 : */}
                                                                    <Button
                                                                        type='text'
                                                                        onClick={() => removetest(childName)}
                                                                        icon={<DeleteOutlined className='del-section' />}
                                                                        danger
                                                                    >
                                                                    </Button>
                                                                {/* } */}
                                                            </div>
                                                        </>))}
                                                    </>)}
                                            </Form.List>
                                        </div>
                                        <div >
                                            {/* {
                                            guestMessage.displayUnits && (form.getFieldValue()?.experiences?.[0] !== undefined) ?
                                            <Popconfirm
                                                title={form.getFieldValue()?.experiences?.[index]?.unitName?`确定删除该${form.getFieldValue()?.experiences?.[index]?.unitName}单位职称么`:`确定删除该单位职称么`}
                                                onConfirm={() => {
                                                    if (!form.getFieldValue()?.experiences?.[index]) {
                                                        remove(name)
                                                    }
                                                }}
                                                onCancel={() => { }}
                                                okText="确定"
                                                cancelText="取消"
                                            >
                                                <Button
                                                    type='text'
                                                    icon={<CloseOutlined className='del-section' />}
                                                >
                                                </Button>
                                            </Popconfirm>
                                             :  */}
                                                <Button
                                                    type='text'
                                                    onClick={() => remove(name)}
                                                    icon={<CloseOutlined className='del-section' />}
                                                >
                                                </Button>
                                             {/* }  */}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item
                            name="displayIntro"
                            label="个人介绍"
                        >
                         <TextArea rows={4} />
                        </Form.Item>

        </Form>
       </div>


        </Modal>
        </div>
    );
}

export default GuestMessage;
