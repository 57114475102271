/**
 * 医疗决策人认证职务 memPost
 *
 * A01-ZHW-B02,党委书记
 * A01-ZHW-B08,党委副书记
 * A01-ZHW-B01,院长
 * A01-ZHW-B09,副院长
 * A01-ZHW-B10,院长助理
 * A01-ZHW-B11,纪委书记
 * A01-ZHW-B12,纪委副书记
 * A01-ZHW-B13,总会计师
 * A01-ZHW-B14,工会主席
 * A01-ZHW-B15,其他院级领导
 * A01-ZHW-B03,主任
 * A01-ZHW-B16,副主任
 *A01-ZHW-B04,部长
 *A01-ZHW-B17,副部长
 *A01-ZHW-B05,科长
 *A01-ZHW-B18,副科长
 *A01-ZHW-B06,处长
 *A01-ZHW-B19,副处长
 */


module.exports = [
    {memPost: 'A01-ZHW-B02',title:'党委书记'},
    {memPost: 'A01-ZHW-B08',title:'党委副书记'},
    {memPost: 'A01-ZHW-B01',title:'院长'},
    {memPost: 'A01-ZHW-B09',title:'副院长'},
    {memPost: 'A01-ZHW-B10',title:'院长助理'},
    {memPost: 'A01-ZHW-B11',title:'纪委书记'},
    {memPost: 'A01-ZHW-B12',title:'纪委副书记'},
    {memPost: 'A01-ZHW-B13',title:'总会计师'},
    {memPost: 'A01-ZHW-B14',title:'工会主席'},
    {memPost: 'A01-ZHW-B15',title:'其他院级领导'},
    {memPost: 'A01-ZHW-B03',title:'主任'},
    {memPost: 'A01-ZHW-B16',title:'副主任'},
    {memPost: 'A01-ZHW-B04',title:'部长'},
    {memPost: 'A01-ZHW-B17',title:'副部长'},
    {memPost: 'A01-ZHW-B05',title:'科长'},
    {memPost: 'A01-ZHW-B18',title:'副科长'},
    {memPost: 'A01-ZHW-B06',title:'处长'},
    {memPost: 'A01-ZHW-B19',title:'副处长'},
];

