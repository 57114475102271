import Questionbank from '../components/Wj/BaseSettings/Questionbank';
import Templates from '../components/Wj/BaseSettings/Templates';
import SurveysList from '../components/Wj/WjManege/SurveysList';
import CreatesurveyList from '../components/Wj/WjManege/Createsurvey';
import Editsurvey from '../components/Wj/WjManege/Editsurvey';
import Facade from '../components/Wj/WjManege/Editsurvey/Facade';
import Topic from '../components/Wj/WjManege/Editsurvey/Topic';
import Logic from '../components/Wj/WjManege/Editsurvey/Logic';
import Setting from '../components/Wj/WjManege/Editsurvey/Setting';
import Whitelists from '../components/Wj/WjManege/Editsurvey//Setting/Whitelists';
import ChartsSurvey from '../components/Wj/WjManege/ChartsSurvey';
import ReportSurvey from '../components/Wj/WjManege/ReportSurvey';
import DetailsSurvey from '../components/Wj/WjManege/DetailsSurvey';
import ShareSurvey from '../components/Wj/WjManege/ShareSurvey';
import PreviewSurvey from '../components/Wj/WjManege/PreviewSurvey';
import { Layout } from 'antd';
import Header from '../components/Layouts/Header/Header';
import Sidebar from '../components/Layouts/Sidebar/Sidebar';
import { Outlet, useRoutes } from 'react-router-dom';
import AnswerSurvey from '../components/Wj/WjManege/AnswerSurvey';
import CheckSurvey from '../components/Wj/WjManege/CheckSurvey';
import CheckDetails from '../components/Wj/WjManege/DetailsSurvey/CheckDetails';
import FinalCustom from '../components/Wj/WjManege/Editsurvey/Setting/FinalCustom';
import CustomReport from '../components/Wj/WjManege/CustomReport';

const SiderLayout = () => {
    return (<Layout className='main_survey_content_wrap'>
        <Header/>
        <Layout>
            <Sidebar/>
            <Layout>
                <Outlet/>
            </Layout>
        </Layout>
    </Layout>)
};

const HeadderLayout = () => {
    return (<Layout className='main_survey_content_wrap'>
        <Header/>
        <Layout>
            <Outlet/>
        </Layout>
    </Layout>)
};

// 问卷
const Wj = () => {
    const element = useRoutes([
        //首页
        {
            path: '',
            element: <SiderLayout/>,
            children: [
                { path: 'questionbank/settings', element: <Questionbank/> },
                { path: 'templates', element: <Templates/> },
                { path: 'surveys', element: <SurveysList/> },
            ]
        },

        {
            path: '',
            element: <HeadderLayout/>,
            children: [
                { path: 'surveys/new/list', element: <CreatesurveyList/> },
                {
                    path: 'surveys/new/',
                    element: <Editsurvey/>,
                    children: [
                        { path: 'create', element: <Topic/> },
                        { path: 'logic', element: <Logic/> },
                        { path: 'setting', element: <Setting/> },
                        { path: 'whitelists', element: <Whitelists/> },
                        { path: 'FinalCustom', element: <FinalCustom/> },
                        { path: 'facade', element: <Facade/> },
                    ]
                },
                {
                    path: 'surveys/edit/:id',
                    element: <Editsurvey/>,
                    children: [
                        { path: '', element: <Topic/> },

                    ]
                },

                { path: 'surveys/:id/charts', element: <ChartsSurvey/> },
                { path: 'surveys/:id/check', element: <CheckSurvey/> },
                { path: 'surveys/:id/report', element: <ReportSurvey/> },
                { path: 'surveys/:id/details', element: <DetailsSurvey/> },
                { path: 'surveys/:id/checkdetails', element: <CheckDetails/> },
                { path: 'surveys/:id/share', element: <ShareSurvey/> },
                { path: 'surveys/:id/preview', element: <PreviewSurvey/> },
                { path: 'surveys/:id/answer', element: <AnswerSurvey/> },
                { path: 'surveys/:id/customreport', element: <CustomReport/> },
            ]
        }

    ]);

    return element;
};

export default Wj;
