import { createSlice } from '@reduxjs/toolkit';

const commonState = {
    filter: {
        pageIndex: 1,
        pageSize: 20,
    },
    projectId: null,
    projectName: null,
};

const initialState = {
    ...commonState,
};

const projectSlice = createSlice({
    name: 'medconnect',
    initialState,
    reducers: {
        setProjectFilter: (state, action) => void(state.filter = action.payload),
        setProjectId: (state, action) => void(state.projectId = action.payload),
        setProjectName: (state, action) => void(state.projectName = action.payload),
    }
});

export const projectActions = projectSlice.actions;

export default projectSlice;
