import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
    Layout,
    Row,
    Col,
    Button,
    Tooltip,
    Modal,
    message, Alert, Space,
    Typography
} from 'antd';
import {
    DeleteOutlined,
    SettingOutlined,
    LeftOutlined,
    HolderOutlined, DesktopOutlined, MobileOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import './SiteModules.less';
import definedSiteModules from '../site_module_types'; // 系统定义的网站模块
import HomeModuleDrawer from './components/HomeModuleDrawer';
import AddSiteModuleDrawer from './components/AddSiteModuleDrawer';
import OrganizationModuleDrawer from './components/OrganizationModuleDrawer';
import JournalModuleDrawer from './components/JournalModuleDrawer';
import AgendaModuleDrawer from './components/AgendaModuleDrawer';
import GuestModuleDrawer from './components/GuestModuleDrawer';
import LiveModuleDrawer from './components/LiveModuleDrawer';
import NewsModuleDrawer from './components/NewsModuleDrawer';
import ReviewModuleDrawer from './components/ReviewModuleDrawer';
import ExhibitionModuleDrawer from './components/ExhibitionModuleDrawer';
import PartnerModuleDrawer from './components/PartnerModuleDrawer';
import RichTextModuleDrawer from './components/RichTextModuleDrawer';
import ExternalLinkModuleDrawer from './components/ExternalLinkModuleDrawer';
import MyprojectsModuleDrawer from './components/MyprojectsModuleDrawer';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {
    getSiteModulesApi,
    updateSiteModulesApi,
    sortSiteModulesApi,
    deleteSiteModuleApi,
    setDefaultShowApi
} from '@services/SiteModule';
import { conferenceOnceApi } from '@services/Conference';
import config from '@config';
import { deleteConferenceCache } from '../../../services/Conference';

const { Content } = Layout;

export default function SiteModules() {
    const location = useLocation();
    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const [conference, setConference] = useState(null);

    const getConferenceInfo = async conferenceId => {
        const res = await conferenceOnceApi({ id: conferenceId });
        if (res.status_code === 200) {
            setConference(res.data);
        }
    };

    useEffect(() => {
        if (!!conferenceId) {
            getConferenceInfo(conferenceId);
        }
    }, []);

    /**
     * @author WisdomFusion<at>gmail<dot>com
     * @date 2023/4/2
     *
     * 网站模块：
     * 1. 网站模块列表全部展示，无须分页
     * 2. 新增网站模块
     * 2.0 新增模块，导航状态（status）默认为2敬请期待，默认隐藏状态hidden=1
     * 2.1 会议项目时，默认增加“官网首页”模块
     * 2.2 内置模块（builtin=1）访问路径不可能修改，非内置模块（图文模块、外部链接模块）访问路径自定义
     * 2.3 外部链接模块，多一个“外部链接”字段
     * 3. 可对所有模块进行编辑（模块配置）
     * 4. 官网首页不可排序不可删除，其他模块可
     * 5. 内置模块（非自定义模块）在同一个会议项目中，只能添加一次，前端逻辑已处理，比如，直播模块只能添加一次
     * 6. 配置网站模块
     * 6.1 ...
     *
     */

        // 从系统预定义的网站模块列表中，把 title, name 和 builtin 字段补全
    const prepareSiteModules = siteModules => siteModules.map(module => {
            definedSiteModules.forEach(dm => {
                if (dm.moduleType === module.moduleType) {
                    module = {
                        ...module,
                        title: dm.title,
                        name: dm.name,
                        builtin: dm.builtin,
                    };
                }
            });
            return module;
        });

    // 通过 moduleType 获取预定义网站模块
    const getSiteModuleByType = moduleType => {
        let siteModule = null;
        definedSiteModules.forEach(m => {
            if (m.moduleType === moduleType) {
                siteModule = m;
            }
        });
        return siteModule;
    };

    // 当前选中模块
    const [currentModule, setCurrentModule] = useState(null);

    // 新增模块 Drawer
    const [visibleAddSiteModuleDrawer, setVisibleAddSiteModuleDrawer] = useState(false);

    const showAddSiteModuleDrawer = () => {
        setVisibleAddSiteModuleDrawer(true);
    };

    // drawerState: {moduleType: 1, visible: true}
    // moduleType 模块类型
    // visible: 显示
    const [drawerState, setDrawerState] = useState(null);

    // 根据 drawerState 设定，显示不同模块类型的配置 drawer
    const showModuleSettingDrawer = module => {
        if (!isEmpty(module)) {
            console.log(module);
            setCurrentModule(module);

            setDrawerState({ moduleType: module.moduleType, visible: true });
        }
    }

    // 网站模块列表数据
    const [siteModuleList, setSiteModuleList] = useState([]);

    const [visibleModuleQrcode, setVisibleModuleQrcode] = useState(false);

    const showModuleQrCode = module => {
        if (!isEmpty(module)) {
            console.log(module);
            setCurrentModule(module);
            setVisibleModuleQrcode(true);
        }
    };

    const closeModuleQrcode = () => setVisibleModuleQrcode(false);

    const downloadQRCode = () => {
        const qrCode = document.getElementById(`qrcode_conf_${conference.id}`);
        let image = new Image();
        image.src = qrCode.toDataURL('image/png');
        const link = document.getElementById(`link_qrcode_conf_${conference.id}`);
        link.href = image.src;
    };

    const copyText = text => {
        copy(text);
        message.success('复制成功');
    };

    // 网站模块刪除
    const deleteSiteModule = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认删除当前模块',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                const res = await deleteSiteModuleApi({ id });
                if (res.status_code === 200) {
                    message.success('删除成功');
                    getSiteModules();
                }
            }
        });
    };

    // 编辑配置模块
    const updateModuleSettings = async (module) => {
        console.log(module);
        let params = {
            ...module,
            conferenceId,
        };
        const res = await updateSiteModulesApi(params);
        if (res.status_code === 200) {
            message.success('配置模块成功');
            setDrawerState({ moduleType: module.moduleType, visible: false });
            getSiteModules();
        }
    };

    // 网站模块排序
    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        let newData = arrayMoveImmutable(siteModuleList, oldIndex, newIndex);
        console.log(newData, '排序');
        setSiteModuleList(newData);

        let ids = newData.map(item => item.id);
        sortSiteModulesApi({ resList: [{ ids: ids }] });
    };

    // 设置默认访问
    const defaultAccessSetting = async (item) => {
        if (item.defaultShow === 1) {
            return;
        }
        const res = await setDefaultShowApi({
            defaultShow: 1,
            siteModuleId: item.id,
            conferenceId: conferenceId
        });
        if (res.status_code === 200) {
            message.success('设置成功');
            getSiteModules();
        }
    };

    const getPageLink = (platform, module) => {
        let link = '';

        if (module.moduleType === 1) {
            link = `${config.HUIYI_DOMAIN}/${platform}/${conference?.slug}`;
        } else if (module.moduleType === 21 && module.url) {
            link = module.url;
        } else {
            link = `${config.HUIYI_DOMAIN}/${platform}/${conference?.slug}/${module.path}`;
        }

        return link;
    };

    // // web端预览页面
    // const goWebLink = (module) => {
    //     if (!module.hiddenWeb) {
    //         // 首页
    //         if (module.moduleType === 1) {
    //             window.open(`${config.HUIYI_DOMAIN}/web/${conference?.slug}`, '_blank', 'noopener,noreferrer');
    //         }
    //         // 外部链接
    //         if (module.moduleType === 21 && module.url) {
    //             window.open(module.url);
    //         }
    //         // 一般模块
    //         window.open(`${config.HUIYI_DOMAIN}/web/${conference?.slug}/${module.path}`, '_blank', 'noopener,noreferrer');
    //     }
    // };
    //
    // // h5端预览页面
    // const goH5Link = (module) => {
    //     if (!module.hiddenH5) {
    //         // 首页
    //         if (module.moduleType === 1) {
    //             window.open(`${config.HUIYI_DOMAIN}/h5/${conference?.slug}`, '_blank', 'noopener,noreferrer');
    //         }
    //         // 外部链接
    //         if (module.moduleType === 21 && module.url) {
    //             window.open(module.url);
    //         }
    //         // 一般模块
    //         window.open(`${config.HUIYI_DOMAIN}/h5/${conference?.slug}/${module.path}`, '_blank', 'noopener,noreferrer');
    //     }
    // };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return <Button type="text" size="small"><HolderOutlined/></Button>;
    });

    const SortableMainElement = SortableElement(({ module, sort }) => {
        return <>
            <div className="conference-site_module" key={module.id}>
                {module.moduleType !== 1 && <>
                    <Tooltip title="网站模块排序">
                        <SortableMainHandle/>
                    </Tooltip>
                </>}

                {/*<div className="site-module-visible">*/}
                {/*    <Tooltip title={!module.hiddenWeb ? 'Web端显示' : 'Web端隐藏'}>*/}
                {/*        <DesktopOutlined onClick={() => goWebLink(module)} className={!module.hiddenWeb ? '' : 'invisible'}/>*/}
                {/*    </Tooltip>*/}
                {/*    <Tooltip title={!module.hiddenH5 ? 'H5端显示' : 'H5端隐藏'}>*/}
                {/*        <MobileOutlined onClick={() => goH5Link(module)} className={!module.hiddenH5 ? '' : 'invisible'}/>*/}
                {/*    </Tooltip>*/}
                {/*</div>*/}

                <div className="site-module-title">
                    <span dangerouslySetInnerHTML={{ __html: module.displayTitle }}></span>
                </div>

                <div className="site-module-operations">
                    <Button
                        className={`access-btn ${module.defaultShow === 1 && 'text-btn'}`}
                        type={module.defaultShow === 1 ? 'primary' : 'default'}
                        size="small"
                        onClick={() => defaultAccessSetting(module)}
                    >
                        {module.defaultShow === 1 ? '当前默认访问' : '设置默认访问'}
                    </Button>

                    <div className="link-btns">
                        <Tooltip title="网站模块配置">
                            <Button type="link" size="small" onClick={() => showModuleSettingDrawer(module)}>模块配置</Button>
                        </Tooltip>

                        <Tooltip title="复制PC链接">
                            <Button type="link" size="small" onClick={() => copyText(getPageLink('web', module))}>PC链接</Button>
                        </Tooltip>
                        <Tooltip title="复制H5链接">
                            <Button type="link" size="small" onClick={() => copyText(getPageLink('h5', module))}>H5链接</Button>
                        </Tooltip>

                        <Tooltip title="下载官网模块二维码">
                            <Button type="link" size="small" onClick={() => showModuleQrCode(module)}>二维码</Button>
                        </Tooltip>

                        {module.moduleType !== 1 && <>
                            <Tooltip title="删除网站模块">
                                <Button type="link" danger size="small" onClick={() => deleteSiteModule(module.id)}>删除模块</Button>
                            </Tooltip>
                        </>}
                    </div>

                </div>
            </div>
        </>;
    });

    // 网站模块拖拽容器
    const SortableMainContainer = SortableContainer(({ items }) => {
        return <div>
            {items.map((module, index) => {
                return (
                    <SortableMainElement
                        module={module}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    // 获取网站列表
    const getSiteModules = async () => {
        const res = await getSiteModulesApi({ conferenceId: conferenceId });
        if (res.status_code === 200) {
            let newList = prepareSiteModules(res.data);
            setSiteModuleList(newList);
        }
    };

    useEffect(() => {
        getSiteModules();
    }, []);

    const clearConferenceSiteModuleCache = async () => {
        const data = {
            type: 'conference',
            key: `conference_${conferenceId}_site_modules`
        };
        const res = await deleteConferenceCache(data);
        if (res?.status_code === 200) {
            message.success('更新会议官网模块成功！');
        }
    };

    const onClearConferenceSiteModuleCache = () => {
        if (conferenceId) {
            Modal.confirm({
                title: '更新会议官网',
                icon: <ExclamationCircleOutlined/>,
                width: 480,
                centered: true,
                content: <>
                    <p>为减轻性能压力，系统自动缓存数据，该功能将更新用户端数据，展现最新设定内容。</p>
                    <p><Typography.Text type="danger">会议进行期间谨慎更新！</Typography.Text></p>
                </>,
                onOk() {
                    clearConferenceSiteModuleCache();
                },
                cancelText: '取消，暂不更新',
                okText: '知晓，确认更新'
            });
        }
    };

    return <>
        <div className="page-title">
            <Link to="/conf/conferences" className="back"><LeftOutlined/></Link>
            <span className="current-title">网站模块</span>
            <span className="current-subtitle">{conference?.name}</span>
        </div>

        <Content className="main-content-box">

            <div className="main-content-body">

                <div className="main-toolbar">
                    <div className="main-tools">
                        <Space>
                            <Button type="primary" onClick={() => showAddSiteModuleDrawer()}>新增模块</Button>
                        </Space>

                    </div>

                    <div className="sub-tools">
                        <Space>
                            {/*<Button onClick={() => showSiteQrcode()}>会议官网二维码</Button>*/}
                            <Button onClick={() => onClearConferenceSiteModuleCache()}>更新会议官网模块</Button>
                        </Space>
                    </div>
                </div>

                <Alert
                    style={{ marginBottom: 16 }}
                    message={<>
                        <div>1. 会议官网初次建站时，请完成“首页”模块配置，再进行其他模块创建及配置，首页未配置官网无法正常显示；</div>
                        <div>2. 如果希望用户在访问官网时，默认访问指定“模块”，请将该“模块”设为默认访问。</div>
                    </>}
                />

                <div className="site-modules">
                    <SortableMainContainer
                        useWindowAsScrollContainer={true}
                        useDragHandle
                        distance={10}
                        items={siteModuleList}
                        onSortEnd={onSortEndHandler}
                    />
                </div>

            </div>

        </Content>

        <Modal
            title="下载官网模块二维码"
            centered
            open={visibleModuleQrcode}
            onCancel={closeModuleQrcode}
            footer={null}
        >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {conference && conference.slug && !isEmpty(currentModule) && <>
                    <QRCode
                        id={`qrcode_conf_${conference?.id}`}
                        size={300}
                        value={getPageLink('h5', currentModule)}
                    />
                </>}
                <div style={{ textAlign: 'center', paddingTop: 10 }}>
                    <a
                        id={`link_qrcode_conf_${conference?.id}`}
                        download={`会议官网二维码_${conference?.name}_${currentModule?.displayTitle}`}
                        onClick={() => downloadQRCode()}>
                        {`下载${currentModule?.displayTitle}二维码`}
                    </a>
                </div>
            </div>
        </Modal>

        <AddSiteModuleDrawer
            visibleAddSiteModuleDrawer={visibleAddSiteModuleDrawer}
            setVisibleAddSiteModuleDrawer={setVisibleAddSiteModuleDrawer}
            preparedSiteModules={siteModuleList}
            getSiteModuleByType={getSiteModuleByType}
            getSiteModules={getSiteModules}
        />

        <HomeModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <OrganizationModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <JournalModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <AgendaModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <GuestModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <LiveModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <NewsModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <ReviewModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <ExhibitionModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <PartnerModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <RichTextModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <ExternalLinkModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

        <MyprojectsModuleDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            currentModule={currentModule}
            updateModuleSettings={updateModuleSettings}
        />

    </>;
}
