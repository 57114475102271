import React, { Fragment, useState, useEffect } from 'react';
import { Tabs, Layout } from 'antd';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import "./index.less"
import Judges from './Judges';
import ParticipatingTeam from './ParticipatingTeam';

const { Content } = Layout;
const PersonnelManagement = () => {
    const params = useParams();
    const { id, pid } = params || 0;

    const onChange = () => { };

    return (
        <>
            <div className="page-title">
                <Link to={`/selection/projects/${pid}/score-stages`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">评委管理</span>
                {/* {!!projectTitleName && !!stageTitleName && <span className="current-subtitle">{`${projectTitleName} | ${stageTitleName}`}</span>} */}
            </div>


            <Content className="main-content-box">
                <div className='tabs-container'>
                <Tabs
                    defaultActiveKey="1"
                    onChange={onChange}
                    items={[
                        {
                            label: `评委名单`,
                            key: '1',
                            children: <Judges />,
                        },
                        {
                            label: `参赛团队`,
                            key: '2',
                            children: <ParticipatingTeam />,
                        },
                    ]}
                />
                </div>
            </Content >
        </>

    );
}

export default PersonnelManagement;
