import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    Input,
    Layout,
    Space,
    Button,
    Select,
    Table,
    Typography,
    Modal,
    Form,
    message,
    Tooltip,
    Checkbox,
    Drawer,
    Radio,
    Divider,
    Dropdown,
    Switch,
    TreeSelect,
} from 'antd';
import {
    DragOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    LeftOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {
    showMedProject,

    fetchContentModuleContents,
    addContentModuleContents,
    changeContentModuleContentsState,
    sortContentModuleContents,
    removeContentModuleContents,

    fetchArticles,
    fetchCourseCategories,
    fetchVideoAndLiveCourses,
    fetchCases,
    fetchConferenceLive
} from '@services/MedProject';
import { getProjectsAllApi } from '@services/Selection';
import { drawerWidth } from '@utils/property';
import './index.less';
import '../../../../styles.less';
import { contentTypes } from '../../../../constants';
import config from '@config';
import { set } from 'lodash';

const { Content } = Layout;

export default function ModulesContents() {
    const navigate = useNavigate();

    let { projectId, id: moduleId } = useParams();
    projectId = !!projectId && parseInt(projectId) || 0;
    moduleId = !!moduleId && parseInt(moduleId) || 0;

    const [filterForm] = Form.useForm();

    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({ page: 1, pageSize: 10 });
    const [loading, setLoading] = useState(false);

    const [recommendedContents, setRecommendedContents] = useState([]);

    const [visibleSortContentDrawer, setVisibleSortContentDrawer] = useState(false);

    const [courseCatalogs, setCourseCatalogs] = useState([]);
    const [courseCatalog, setCourseCatalog] = useState(null);

    const [selectionProjects, setSelectionProjects] = useState([]);

    const [visibleAddContentDrawer, setVisibleAddContentDrawer] = useState(false);

    const [platformTableFilterForm] = Form.useForm();
    const [platformTableRowKey, setPlatformTableRowKey] = useState('id');
    const [platformContentColumns, setPlatformContentColumns] = useState([]);
    const [platformTableData, setPlatformTableData] = useState([]);
    const [platformTableTotal, setPlatformTableTotal] = useState(0);
    const [platformTableFilter, setPlatformTableFilter] = useState({ page: 1, size: 10 });
    const [platformTableLoading, setPlatformTableLoading] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onFinish = values => {
        // console.log(values);
        getContents();
    };

    const onReset = () => {
        filterForm.resetFields();
        getContents();
    };

    const onChangePage = (page, pageSize) => {
        setFilter({
            ...filter,
            page,
            pageSize
        });
    };

    const onShowSizeChange = (page, pageSize) => onChangePage(1, pageSize);

    const getContents = async () => {
        const formData = filterForm.getFieldsValue();
        const params = {
            projectId,
            moduleId,
            contentType: formData.contentType,
            name: formData.name,
            page: filter.page,
            pageSize: filter.pageSize
        };
        if (formData.recommended?.length) {
            params.recommended = 1;
        }

        setLoading(true);
        const res = await fetchContentModuleContents(params);
        if (res.status_code === 200) {
            setTableData(res.data.list);
            setTotal(res.data.totalCount);
        }
        setLoading(false);
    };

    const onChangeDisplayStatus = async record => {
        const data = {
            id: record.id,
            hidden: !record.hidden ? 1 : 0,
        };
        if (!record.hidden) {
            data.recommended = 0;
        }
        const res = await changeContentModuleContentsState(data);
        if (res.status_code === 200) {
            const msg = !record.hidden ? '隐藏内容成功' : '显示内容成功';
            message.success(msg);

            getContents();
        }
    };

    const onChangeRecommendation = async record => {
        const data = {
            id: record.id,
            recommended: !record.recommended ? 1 : 0,
        };
        const res = await changeContentModuleContentsState(data);
        if (res.status_code === 200) {
            const msg = !!record.recommended ? '成功把内容从首页推荐中移除' : '首页推荐内容成功';
            message.success(msg);

            getContents();
        }
    };

    const onRemoveContet = async id => {
        const res = await removeContentModuleContents(id);
        if (res.status_code === 200) {
            message.success('移除内容成功');

            getContents();
        }
    }

    const columns = [
        {
            title: '内容类型',
            dataIndex: 'contentType',
            width: 100,
            render: (_, record) => contentTypes.find(t => t.type === record.contentType)?.label,
        },
        { title: '内容标题', dataIndex: 'projectName' },
        {
            title: '显示/隐藏',
            dataIndex: 'show',
            width: 100,
            render: (_, record) => (
                <Switch
                    checked={!record.hidden}
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                    onChange={() => onChangeDisplayStatus(record)}
                />
            )
        },
        {
            title: '推荐到主页',
            dataIndex: 'recommend',
            width: 100,
            render: (_, record) => (
                <Switch
                    checked={record.recommended}
                    checkedChildren="推荐"
                    unCheckedChildren="不推荐"
                    disabled={!!record.hidden}
                    onChange={() => onChangeRecommendation(record)}
                />
            )
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 150,
            render: (_, record) => {
                const items = [
                    {
                        label: '移除内容',
                        key: 'remove',
                        onClick: () => onRemoveContet(record.id),
                    },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => window.open(`${record.baseVO.jumpUrl}`)}
                        >
                            查看详情
                        </Button>

                        <Divider type="vertical" />

                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </span>
                );
            }
        }
    ];

    useEffect(() => {
        getContents();
    }, []);

    useEffect(() => {
        getContents();
    }, [filter]);

    const showSortContentDrawer = async () => {
        const params = {
            projectId,
            moduleId,
            recommended: 1,
            page: filter.page,
            pageSize: filter.pageSize
        };

        const res = await fetchContentModuleContents(params);
        if (res.status_code === 200) {
            if (res.data?.list?.length) {
                // console.log(res.data.list);
                setRecommendedContents(res.data.list);
                setVisibleSortContentDrawer(true);
            } else {
                message.warning('暂无首页推荐内容，无需排序')
            }
        }
    };

    const closeSortContentDrawer = () => {
        setVisibleSortContentDrawer(false);
        setRecommendedContents([]);
    };

    const onSortEndHandler = async ({ oldIndex, newIndex }) => {
        const newData = arrayMoveImmutable(recommendedContents, oldIndex, newIndex);
        const sortIds = newData.map(item => item.id);

        const res = await sortContentModuleContents([...sortIds]);
        if (res.status_code === 200) {
            message.success('排序成功');

            setRecommendedContents(newData);
            getContents();
        }

    };

    // 拖拽容器
    const SortableModulesContainer = SortableContainer(({ items }) => {
        return <div>
            {!!items?.length && items.map((treeNode, index) => {
                return (
                    <SortableMainElement
                        item={treeNode}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return <DragOutlined />;
    });

    const SortableMainElement = SortableElement(({ item, key }) => {
        return <div key={key} style={{ position: 'relative', zIndex: 99999 }}>
            <p className="activities-name-sort">
                <span className="activities-item-name">{item.projectName}</span>
                <span className="activities-item-option"><SortableMainHandle /></span>
            </p>
        </div>
    });

    const showAddContentDrawer = () => {
        setVisibleAddContentDrawer(true);
    };

    const closeAddContentDrawer = () => {
        setVisibleAddContentDrawer(false);
    };

    const prepareCourseCatalogs = data => {
        const catalogs = [];

        data && data.length && data.forEach(courseCatalog => {
            const preparedCourseCatalog = {
                id: courseCatalog.id,
                name: courseCatalog.name,
                level: 1,
                catalogId1: courseCatalog.id,
                catalogId2: undefined,
                catalogId3: undefined,
                children: [],
            };

            if (courseCatalog.sonCatalogList && courseCatalog.sonCatalogList.length) {
                courseCatalog.sonCatalogList.forEach(catalog => {
                    const preparedCatalog = {
                        id: catalog.id,
                        name: catalog.name,
                        level: 2,
                        catalogId1: courseCatalog.id,
                        catalogId2: catalog.id,
                        children: [],
                    };

                    if (catalog.sonCatalogList && catalog.sonCatalogList.length) {
                        catalog.sonCatalogList.forEach(c => {
                            preparedCatalog.children.push({
                                id: c.id,
                                name: c.name,
                                level: 3,
                                catalogId1: courseCatalog.id,
                                catalogId2: catalog.id,
                                catalogId3: c.id,
                            });
                        })
                    }

                    preparedCourseCatalog.children.push(preparedCatalog);
                });
            }

            catalogs.push(preparedCourseCatalog);
        });

        return catalogs;
    };

    const getCourseCategories = async () => {
        const res = await fetchCourseCategories();
        if (res.status_code === 200) {
            // console.log(res.data);
            // console.log(prepareCourseCatalogs(res.data));
            setCourseCatalogs(prepareCourseCatalogs(res.data));
        }
    };

    const getSelectionProjects = async () => {
        const res = await getProjectsAllApi();
        if (res.status_code === 200) {
            // console.log(res.data);
            setSelectionProjects(res.data);
        }
    };

    const onChangeContentType = e => {
        const contentType = e.target.value;
        console.log(contentType);

        setSelectedRowKeys([]);
        setPlatformTableData([]);
        setPlatformTableFilter({ page: 1, size: platformTableFilter.size });
        setPlatformTableTotal(0);

        switch (contentType) {
            case 'article':
                break;
            case 'live':
            case 'course':
                if (!courseCatalogs.length) {
                    getCourseCategories();
                }
                break;
            case 'case':
                if (!selectionProjects.length) {
                    getSelectionProjects();
                }
                break;
        }

    };

    const onSelectCourseCatalog = (value, node, extra) => {
        console.log(value, node, extra);
        setCourseCatalog(node);
    };

    const onPlatformTableChangePage = (page, pageSize) => {
        setPlatformTableFilter({
            page,
            size: pageSize
        });
    };

    const requestPlatformContent = async () => {
        let formData = platformTableFilterForm.getFieldsValue();

        let params = {
            moduleId,
            authType: formData.authType,
            page: platformTableFilter.page,
            size: platformTableFilter.size,
        };

        let res = null;
        let columns = [];

        switch (formData?.type) {
            case 'article':
                setPlatformTableRowKey('artId');
                columns = [
                    { title: '文章ID', dataIndex: 'artId' },
                    { title: '文章标题', dataIndex: 'title' },
                ];
                setPlatformContentColumns(columns);

                // params = {
                //     moduleId,
                //     authType: formData.authType,
                //     page: platformTableFilter.page,
                //     size: platformTableFilter.size,
                // };
                if (formData.artId) {
                    params.artId = formData.artId;
                }
                if (formData.title) {
                    params.title = formData.title;
                }

                setPlatformTableLoading(true);
                res = await fetchArticles(params);
                if (res.status_code === 200) {
                    setPlatformTableData(res.data.list);
                    setPlatformTableTotal(res.data.totalCount);
                }
                setPlatformTableLoading(false);
                break;
            case 'live':
                setPlatformTableRowKey('id');
                columns = [
                    { title: '直播ID', dataIndex: 'id' },
                    { title: '直播名称', dataIndex: 'courseName' },
                ];
                setPlatformContentColumns(columns);
                params = {
                    ...params,
                    isActivity: 0,
                    islive: 1,
                    courseStatus: 1,
                    courseName: formData.courseName,
                };
                if (!!courseCatalog) {
                    params = {
                        ...params,
                        catalogId1: courseCatalog.catalogId1,
                        catalogId2: courseCatalog.catalogId2,
                        catalogId3: courseCatalog.catalogId3,
                    }
                }
                if (!!formData.ids) {
                    let liveIds = [];
                    let liveIdStrList = formData.ids.split(/\s*[,，]\s*/);
                    liveIdStrList && liveIdStrList.length && liveIdStrList.forEach(liveId => {
                        const id = !!liveId && parseInt(liveId) || 0;
                        if (!!id) {
                            liveIds.push(id);
                        }
                    })
                    if (liveIds.length) {
                        params.ids = liveIds;
                    }
                }

                setPlatformTableLoading(true);
                res = await fetchVideoAndLiveCourses(params);
                if (res.status_code === 200) {
                    setPlatformTableData(res.data.list);
                    setPlatformTableTotal(res.data.totalCount);
                }
                setPlatformTableLoading(false);
                break;
            case 'conference_live':
                setPlatformTableRowKey('id');
                columns = [
                    { title: '直播ID', dataIndex: 'id' },
                    { title: '直播名称', dataIndex: 'name' },
                ];
                setPlatformContentColumns(columns);

                params = {
                    ...params,
                    moduleId,
                    isActivity: 0, //内容
                    liveId: formData.liveId,
                    name: formData.name
                };

                setPlatformTableLoading(true);
                res = await fetchConferenceLive(params);
                if (res.status_code === 200) {
                    setPlatformTableData(res.data.list);
                    setPlatformTableTotal(res.data.totalCount);
                }
                setPlatformTableLoading(false);
                break;
            case 'course':
                setPlatformTableRowKey('id');
                columns = [
                    { title: '课程ID', dataIndex: 'id' },
                    { title: '课程名称', dataIndex: 'courseName' },
                ];
                setPlatformContentColumns(columns);
                params = {
                    ...params,
                    isActivity: 0,
                    islive: 0,
                    courseStatus: 1,
                    courseName: formData.courseName,
                };
                if (!!courseCatalog) {
                    params = {
                        ...params,
                        catalogId1: courseCatalog.catalogId1,
                        catalogId2: courseCatalog.catalogId2,
                        catalogId3: courseCatalog.catalogId3,
                    }
                }
                if (!!formData.ids) {
                    let courseIds = [];
                    let courseIdStrList = formData.ids.split(/\s*[,，]\s*/);
                    console.log(courseIdStrList)
                    courseIdStrList && courseIdStrList.length && courseIdStrList.forEach(courseId => {
                        const id = !!courseId && parseInt(courseId) || 0;
                        if (!!id) {
                            courseIds.push(id);
                        }
                    });
                    console.log(courseIds);
                    if (courseIds.length) {
                        params.ids = courseIds;
                    }
                }

                setPlatformTableLoading(true);
                res = await fetchVideoAndLiveCourses(params);
                if (res.status_code === 200) {
                    setPlatformTableData(res.data.list);
                    setPlatformTableTotal(res.data.totalCount);
                }
                setPlatformTableLoading(false);
                break;
            case 'case':
                setPlatformTableRowKey('id');
                columns = [
                    { title: '案例编号', dataIndex: 'id', width: 100 },
                    { title: '案例名称', dataIndex: 'title' },
                    { title: '案例提报人', dataIndex: 'nickName', width: 140 },
                ];
                setPlatformContentColumns(columns);
                params = {
                    ...params,
                    reviewStatus: 1, // 审核状态0-待审核 1-审核通过 2-审核驳回
                    status: 2, // 发布状态0 草稿 1.提交审核 2.已上架 3.下架
                    projectId: formData.projectId,
                    caseId: parseInt(formData.caseId?.replace(/^\s*(\d+).*?$/, '$1')) || undefined,
                    title: formData.title,
                    nickName: formData.nickName,
                };

                setPlatformTableLoading(true);
                res = await fetchCases(params);
                if (res.status_code === 200) {
                    let cases = res.data.records;
                    console.log(cases);
                    cases = cases.map(c => {
                        return { ...c, title: JSON.parse(c.fieldValue)?.title || '' };
                    });
                    console.log(cases);
                    setPlatformTableData(cases);
                    setPlatformTableTotal(res.data.total);
                }
                setPlatformTableLoading(false);
        }
    };

    const onPlatformTableShowSizeChange = (page, pageSize) => onPlatformTableChangePage(1, pageSize);

    const onFinishPlatformTableFilterForm = values => {
        console.log(values);
        setPlatformTableFilter({
            ...platformTableFilter,
            page: 1,
        });
        // requestPlatformContent();
    };

    useEffect(() => {
        requestPlatformContent();
    }, [platformTableFilter]);

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (nowSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(nowSelectedRowKeys);
            console.log(nowSelectedRowKeys);
        },
        getCheckboxProps: (record) => ({ disabled: record.hasData }) // 禁用
    };

    const onAddContent = async () => {
        const formData = platformTableFilterForm.getFieldsValue();
        // console.log(formData);

        let contentType = formData.type;
        if (contentType === 'article' && formData.authType === 2) {
            contentType = 'article_wm';
        }

        const data = {
            projectId,
            moduleId,
            contentType,
            itemIds: [...selectedRowKeys]
        };

        const res = await addContentModuleContents(data);
        if (res.status_code === 200) {
            setSelectedRowKeys([]);
            platformTableFilterForm.resetFields();
            setPlatformTableFilter({ ...platformTableFilter, page: 1 });
            setVisibleAddContentDrawer(false);

            getContents();
        }
    };

    useEffect(() => {
        if (visibleAddContentDrawer) {
            requestPlatformContent();
        }
    }, [visibleAddContentDrawer]);

    return (
        <>
            <Content className="main-content-box">

                <div className="main-content-body">

                    <div className="med-project-module-head">
                        <div className="module-title">
                            <h2>精彩内容</h2>
                            <span className="tips">可添加平台已有文章、案例、课程、直播作为项目内容，<em>项目首页最多可推荐9条数据</em></span>
                        </div>
                        <div className="preview">
                            <Button
                                type="link"
                                size="small"
                                ghost
                                style={{ padding: 0 }}
                                onClick={() => window.open(`${config.PROJECT_DOMAIN}/p/${projectId}/contents/${moduleId}`)}
                                icon={<>
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                        </svg>
                                    </span>
                                </>}
                            >
                                <span>预览</span>
                            </Button>
                        </div>
                    </div>

                    <div className="med-project-module-toolbar">
                        <div className="main-tools">
                            <Form layout="inline" form={filterForm} onFinish={onFinish}>
                                <Form.Item name="contentType" style={{ marginRight: 8 }}>
                                    <Select placeholder="内容类型" style={{ width: 100 }} allowClear>
                                        <Select.Option value="article">平台文章</Select.Option>
                                        <Select.Option value="aritlce_wm">用户文章</Select.Option>
                                        <Select.Option value="course">课程</Select.Option>
                                        <Select.Option value="live">直播</Select.Option>
                                        <Select.Option value="case">案例</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.contentType !== curValues.contentType}>
                                    {({ getFieldValue }) => {
                                        return !!getFieldValue('contentType') && (<>
                                            <Form.Item name="name" style={{ width: 220, marginRight: 8 }}>
                                                <Input placeholder="内容标题" style={{ flex: 1 }} allowClear />
                                            </Form.Item>
                                        </>);
                                    }}
                                </Form.Item>

                                <Form.Item name="recommended" style={{ marginRight: 0 }}>
                                    <Checkbox.Group>
                                        <Checkbox value={1}>只看首页推荐</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>

                                <Form.Item style={{ marginRight: 8 }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">查询</Button>
                                        <Button onClick={onReset}>重置</Button>
                                    </Space>
                                </Form.Item>

                            </Form>
                        </div>
                        <div className="sub-tools">
                            <Space>
                                <Tooltip title="为推荐首页内容排序">
                                    <Button onClick={showSortContentDrawer}>排序</Button>
                                </Tooltip>
                                <Button type="primary" onClick={showAddContentDrawer}>添加内容</Button>
                            </Space>
                        </div>
                    </div>

                    <div className="med-project-module-content">
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={tableData}
                            loading={loading}
                            pagination={{
                                total,
                                showTotal: () => `共${total}条`,
                                current: filter.page,
                                pageSize: filter.pageSize,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange: onChangePage,
                                onShowSizeChange,
                            }}
                        />
                    </div>

                </div>

            </Content>

            <Drawer
                title="添加精彩内容"
                width={drawerWidth}
                open={visibleAddContentDrawer}
                onClose={closeAddContentDrawer}
                footer={
                    <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={closeAddContentDrawer}>取消</Button>
                            <Button type="primary" onClick={onAddContent}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <div className="filter-form">
                    <Form form={platformTableFilterForm} onFinish={onFinishPlatformTableFilterForm}>
                        <label>内容类型</label>
                        <div className="">
                            <Form.Item name="type" initialValue="article">
                                <Radio.Group onChange={onChangeContentType}>
                                    <Radio value="article">文章</Radio>
                                    <Radio value="live">直播</Radio>
                                    <Radio value="conference_live">医会议直播</Radio>
                                    <Radio value="course">课程</Radio>
                                    <Radio value="case">案例</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <label>选择项目内容</label>

                        <div className="search-box" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', margin: '8px 0 16px' }}>

                            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>

                                {({ getFieldValue }) => {
                                    switch (getFieldValue('type')) {
                                        case 'article':
                                            return (<>
                                                <div className="content-type-article" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Item name="authType" style={{ marginBottom: 0, marginRight: 8 }} initialValue={1}>
                                                        <Select placeholder="文章类型">
                                                            <Select.Option value={1}>平台文章</Select.Option>
                                                            <Select.Option value={2}>用户文章</Select.Option>
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item name="artId" style={{ marginBottom: 0, marginRight: 8 }}>
                                                        <Input placeholder="文章ID" allowClear />
                                                    </Form.Item>

                                                    <Form.Item name="title" style={{ marginBottom: 0, marginRight: 8 }}>
                                                        <Input placeholder="文章标题关键字" allowClear />
                                                    </Form.Item>
                                                </div>
                                            </>);
                                        case 'live':
                                            return (<>
                                                <div className="content-type-live-course" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Item
                                                        name="cateId"
                                                        style={{ marginBottom: 0, marginRight: 8, width: 180 }}
                                                        initialValue={courseCatalog?.id}
                                                    >
                                                        <TreeSelect
                                                            treeData={courseCatalogs}
                                                            fieldNames={{ label: 'name', value: 'id' }}
                                                            treeDefaultExpandAll
                                                            placeholder="选择直播分类"
                                                            dropdownStyle={{
                                                                maxHeight: 400,
                                                                overflow: 'auto',
                                                            }}
                                                            showSearch
                                                            allowClear
                                                            onChange={(e) => {
                                                                if (!e) {
                                                                    setCourseCatalog({})
                                                                }
                                                            }}
                                                            onSelect={onSelectCourseCatalog}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item name="ids" style={{ marginBottom: 0, marginRight: 8, width: 120 }}>
                                                        <Input placeholder="直播ID" allowClear />
                                                    </Form.Item>
                                                    <Form.Item name="courseName" style={{ marginBottom: 0, marginRight: 8 }}>
                                                        <Input placeholder="直播标题" allowClear />
                                                    </Form.Item>
                                                </div>
                                            </>);
                                        case 'conference_live':
                                            return (<>
                                                <div className="content-type-live-course" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Item name="liveId" style={{ marginBottom: 0, marginRight: 8, width: 120 }}>
                                                        <Input placeholder="直播ID" allowClear />
                                                    </Form.Item>
                                                    <Form.Item name="name" style={{ marginBottom: 0, marginRight: 8 }}>
                                                        <Input placeholder="直播标题" allowClear />
                                                    </Form.Item>
                                                </div>
                                            </>);
                                        case 'course':
                                            return (<>
                                                <div className="content-type-video-course" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Item
                                                        name="cateId"
                                                        style={{ marginBottom: 0, marginRight: 8, width: 180 }}
                                                        initialValue={courseCatalog?.id}
                                                    >
                                                        <TreeSelect
                                                            treeData={courseCatalogs}
                                                            fieldNames={{ label: 'name', value: 'id' }}
                                                            treeDefaultExpandAll
                                                            placeholder="选择直播分类"
                                                            dropdownStyle={{ minWidth: 300, overflow: 'auto' }}
                                                            showSearch
                                                            allowClear
                                                            onChange={(e) => {
                                                                if (!e) {
                                                                    setCourseCatalog({})
                                                                }
                                                            }}
                                                            onSelect={onSelectCourseCatalog}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item name="ids" style={{ marginBottom: 0, marginRight: 8, width: 120 }}>
                                                        <Input placeholder="课程ID" allowClear />
                                                    </Form.Item>
                                                    <Form.Item name="courseName" style={{ marginBottom: 0, marginRight: 8 }}>
                                                        <Input placeholder="课程标题" allowClear />
                                                    </Form.Item>
                                                </div>
                                            </>);
                                        case 'case':
                                            return (<>
                                                <div className="content-type-case" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Item name="projectId" style={{ marginBottom: 0, marginRight: 8, width: 180 }}>
                                                        <Select
                                                            optionFilterProp="children"
                                                            options={selectionProjects}
                                                            fieldNames={{ label: 'name', value: 'id' }}
                                                            placeholder="选择评选项目"
                                                            dropdownStyle={{ minWidth: 300, overflow: 'auto' }}
                                                            showSearch
                                                            allowClear
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="caseId" style={{ marginBottom: 0, marginRight: 8, width: 120 }}>
                                                        <Input placeholder="案例编号" allowClear />
                                                    </Form.Item>
                                                    <Form.Item name="title" style={{ marginBottom: 0, marginRight: 8 }}>
                                                        <Input placeholder="案例标题" allowClear />
                                                    </Form.Item>
                                                    <Form.Item name="nickName" style={{ marginBottom: 0, marginRight: 8, width: 150 }}>
                                                        <Input placeholder="案例提报人" allowClear />
                                                    </Form.Item>
                                                </div>
                                            </>);
                                    }
                                }}

                            </Form.Item>

                            <Button htmlType="submit">检索</Button>
                        </div>

                        <Table
                            size="small"
                            rowKey={platformTableRowKey}
                            columns={platformContentColumns}
                            dataSource={platformTableData}
                            loading={platformTableLoading}
                            rowSelection={{ ...rowSelection }}
                            pagination={{
                                total: platformTableTotal,
                                showTotal: () => `共${platformTableTotal}条`,
                                current: platformTableFilter.page,
                                pageSize: platformTableFilter.size,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange: onPlatformTableChangePage,
                                onShowSizeChange: onPlatformTableShowSizeChange,
                            }}
                        />

                    </Form>
                </div>
            </Drawer>

            <Drawer
                title="精彩内容排序"
                width={drawerWidth}
                open={visibleSortContentDrawer}
                maskClosable={false}
                onClose={closeSortContentDrawer}
                footer={null}
            >
                <SortableModulesContainer
                    useWindowAsScrollContainer={true}
                    useDragHandle
                    distance={10}
                    items={recommendedContents}
                    onSortEnd={onSortEndHandler}
                />
            </Drawer>

        </>
    );
}
