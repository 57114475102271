import React, { Fragment, useState, useEffect } from 'react';
import { Descriptions, Layout } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from '@/core/request';
import './Surveys.less';
const { Content } = Layout;

export default function SurveysAnswer() {

    const { state } = useLocation();
    const navigate = useNavigate();

    // 点击返回跳转到问卷统计
    const changeBackPath = () => {
        navigate(`/dms/surveys/${state.surveyId}/data`, { state });
    };

    const [surveysData, setSurveysData] = useState({});
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        get(`/dms/survey/records/queryUserDetails`, {
            id: state.id
        }).then((res) => {
            if (res.status_code === 200) {
                setSurveysData(res.data)
                setVisible(true)
            }
        })
    }, [state.id]);

    const { id, count, name, phone, productsName, orgName, districtName, hospital, titleName, departmentName, surveyQuestionsRecordVoList, updatedAt } = surveysData;
    return (
        <Fragment>
            <div className="page-title"><span onClick={changeBackPath} className="back"><LeftOutlined /></span>
                答题详情</div>

            <Content className="main-content-box">

                {
                    visible && <div className="main-content-body">

                        <div className="table-caption">
                            <h2 className="compliance-title">答题信息</h2>
                            <Descriptions className="basic-information" column={4}>
                                <Descriptions.Item label="答题序号">{id}</Descriptions.Item>
                                <Descriptions.Item label="答题数量">{count}</Descriptions.Item>
                                <Descriptions.Item label="提交时间">{updatedAt}</Descriptions.Item>
                            </Descriptions>
                        </div>

                        <div className="table-caption">
                            <h2 className="compliance-title">用户信息</h2>
                            <Descriptions className="basic-information" column={4}>
                                <Descriptions.Item label="用户名">{name}</Descriptions.Item>
                                <Descriptions.Item label="手机号">{phone}</Descriptions.Item>
                                <Descriptions.Item label="产品线">{productsName}</Descriptions.Item>
                                <Descriptions.Item label="所属医疗">{orgName}</Descriptions.Item>
                                <Descriptions.Item label="所在城市">{districtName}</Descriptions.Item>
                                <Descriptions.Item label="所在医院">{hospital}</Descriptions.Item>
                                <Descriptions.Item label="职称">{titleName}</Descriptions.Item>
                                <Descriptions.Item label="科室">{departmentName}</Descriptions.Item>
                            </Descriptions>
                        </div>

                        <div className="table-caption">
                            <h2 className="compliance-title">答题详情</h2>
                            {
                                surveyQuestionsRecordVoList.map(item => {
                                    if (item.type === 1 || item.type === 3) {
                                        return (
                                            <div key={item.id} className="answer-text">
                                                <p>Q{item.sort}：{item.name}【{item.type === 1 ? '单选题' : '填空题'}】</p>
                                                <p>答：{item.contents}</p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={item.id} className="answer-text">
                                                <p>Q{item.sort}：{item.name}【多选题】</p>
                                                <p>答：{item.contents}</p>
                                            </div>
                                        )
                                    }

                                })
                            }
                        </div>

                    </div>
                }


            </Content>


        </Fragment>
    )
}
