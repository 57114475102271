import React, { useState, useEffect } from "react";
import { LeftOutlined, QuestionCircleOutlined, CloseOutlined, EllipsisOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Layout, Space, Button, Table, Tooltip, Drawer, Alert, Divider, Dropdown, Modal, Form, Input, message, Switch } from "antd";
import { getProjestsTopicsListApi, delTopicsApi, saveFieldApi, saveTopicsApi, saveDirectionsApi, getDirectionsInfoApi, updateDirectionsInfoApi, getTopicsInfoApi, updateTopicsApi } from "@services/Selection";
import "./index.less";
import SortTopic from "./components/SortTopic";
import FieldsList from "./components/FieldsList";
import Declare from "./components/Declare";

const TopicsList = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const { Content } = Layout;
    const [firstForm] = Form.useForm();
    const [directionForm] = Form.useForm();

    const [data, setData] = useState([]);
    const [allFields, setAllFields] = useState([]);
    const [fieldsSelect, setFieldsSelect] = useState([]);

    const [firstShow, setFirstShow] = useState(false);
    const [fieldsShow, setFieldsShow] = useState(false);
    const [declareShow, setDeclareShow] = useState(false);
    const [sortShow, setSortShow] = useState(false);
    const [declareModal, setDeclareModal] = useState(false);

    const [topicsName, setTopicsName] = useState(undefined);
    const [topicId, setTopicId] = useState(undefined);
    const [topicIds, setTopicIds] = useState(undefined);
    const [declareId, setDeclareId] = useState(undefined);
    const [directions, setDirections] = useState(undefined);

    const columns = [
        { title: "申报主题", dataIndex: "name", width: 200 },
        {
            title: "申报主题说明",
            dataIndex: "description",
            ellipsis: {
                showTitle: false,
            },
            width: 200,
            render: (_, row) => {
                return <>
                {row?.pid === null ? null :
                    <>
                    {row?.description ?
                    <Tooltip title={row?.description} placement="bottomLeft">
                    <span className="topics-description">{row?.description}</span>
                    </Tooltip>
                    : "-"}
                    </>}
                    </>;
            },
        },
        {
            title: "申报方向",
            dataIndex: "topicDirectionList",
            width: 100,
            render: (_, row) => {
                return <>{row?.pid === null ? null : <>{row?.topicDirectionList && row?.topicDirectionList.length > 0 ? <span>{row?.topicDirectionList.length}个</span> : "-"}</>}</>;
            },
        },
        {
            title: (
                <div>
                    <span>申报案例数</span>
                    <Tooltip className="case-num-box" title="审核通过数 / 总数(待审核+已通过+已驳回)">
                        <QuestionCircleOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: "countInfo",
            width: 150,
            render: (_, row) => {
                return (
                    <>
                        {row?.pid === null ? null : (
                            <>
                                <span style={{ color: "#76c372" }}>{row.passTotal || 0}</span>/<span>{row.caseTotal || 0}</span>
                            </>
                        )}
                    </>
                );
            },
        },
        {
            title: "显示状态",
            dataIndex: "hide",
            width: 100,
            render: (_, row) => {
                return <>{row?.hide !== null && row?.hide === true ? <span style={{ color: "#febe88" }}>隐藏</span> : <span style={{ color: "#76c372" }}>展示</span>}</>;
            },
        },
        {
            title: "操作",
            dataIndex: "operations",
            width: 200,
            render: (_, row) => {
                const items = [
                    {
                        label: "删除申报主题",
                        key: "delete",
                        onClick: () => {
                            delTopic(row);
                        },
                        danger: true,
                    },
                ];
                return (
                    <span className="operation-btns">
                        {row?.pid === null ? (
                            <Button size="small" type="link" onClick={() => (setFirstShow(true), setTopicIds(row.id))}>
                                编辑申报分类
                            </Button>
                        ) : (
                            <Button size="small" type="link" onClick={() => navigate(`/selection/projects/${params.pid}/topics/${row.id}`,{state:{...location.state}})}>
                                编辑申报主题
                            </Button>
                        )}

                        {row?.pid === null ? (
                            ""
                        ) : (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        setFieldsShow(true);
                                        setTopicId(row.id);
                                    }}
                                >
                                    申报字段
                                </Button>
                                <Divider type="vertical" />
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        setDeclareShow(true);
                                        setTopicId(row.id);
                                        setTopicsName(row.name);
                                    }}
                                >
                                    申报方向
                                </Button>
                            </>
                        )}
                        {row?.children && row?.children.length > 0 ? (
                            ""
                        ) : (
                            <>
                                <Divider type="vertical" />
                                <Dropdown menu={{ items }} placement="bottomRight">
                                    <Button type="link" size="small" className="more-operation">
                                        <EllipsisOutlined />
                                    </Button>
                                </Dropdown>
                            </>
                        )}
                    </span>
                );
            },
        },
    ];

    const onClose = (type) => {
        console.log(1);
        switch (type) {
            case "sort":
                setSortShow(false);
                break;
            case "fields":
                setAllFields([]);
                setFieldsSelect([]);
                setFieldsShow(false);
                break;
            case "declare":
                setDeclareShow(false);
                onReset()
            default:
                break;
        }
    };

    const onSaveField = () => {
        console.log(allFields)
        saveFieldApi({ caseFields: JSON.stringify(allFields), topicId }).then((res) => {
            if (res.status_code === 200) {
                setFieldsShow(false);
                onReset();
                message.success('保存成功');
            }
        });
    };

    //保存主题分类
    const onSaveFirst = () => {
        firstForm.validateFields().then(() => {
            if (topicIds) {
                        updateTopicsApi({ ...firstForm.getFieldsValue(), projectId: params.pid, id: topicIds }).then((res) => {
                            if (res.status_code === 200) {
                                onReset();
                                setFirstShow(false);
                            }
                        });
            } else {
                        saveTopicsApi({ ...firstForm.getFieldsValue(), projectId: params.pid, hide: firstForm.getFieldValue("hide") || false }).then((res) => {
                            if (res.status_code === 200) {
                                onReset();
                                setFirstShow(false);
                            }
                });
            }
        })
    };

    //保存申报方向
    const onSaveDirections = () => {
        if (declareId) {
            updateDirectionsInfoApi({ ...directions, ...directionForm.getFieldsValue() }).then((res) => {
                if (res.status_code === 200) {
                    setDeclareModal(false);
                }
            });
        } else {
            directionForm.validateFields().then(() => {
                saveDirectionsApi({ ...directionForm.getFieldsValue(), projectId: params?.pid, topicId }).then((res) => {
                    if (res.status_code === 200) {
                        setDeclareModal(false);
                    }
                });
            });
        }
    };

    //删除主题
    const delTopic = (delid) => {
        if(delid.caseTotal>0){
            message.error('该主题下存在案例数据，无法删除！');
        }else{
            Modal.confirm({
                title: '提示',
                content: '确认删除该主题',
                cancelText: '取消',
                okText: '确认',
                closable: true,
                onOk: () => {
                    delTopicsApi({ topicId: delid.id }).then((res) => {
                        if (res.status_code === 200) {
                            onReset();
                        }
                    });
                }
            });
        }
    };
    const onReset = () => {
        getProjestsTopicsListApi({ projectId: params.pid }).then((topics) => {
            if (topics.status_code === 200) {
                setData(topics.data);
            }
        });
    };
    useEffect(() => {
        onReset();
    }, []);
    //申报方向
    useEffect(() => {
        if (declareModal) {
            if (declareId !== undefined) {
                getDirectionsInfoApi({ id: declareId }).then((res) => {
                    if (res.status_code === 200) {
                        directionForm.setFieldsValue(res.data);
                        setDirections(res?.data);
                    }
                });
            } else {
                directionForm.setFieldValue("description", "");
            }
        }
    }, [declareModal]);
    //一级主题分类
    useEffect(() => {
        if (firstShow) {
            if (topicIds !== undefined) {
                getTopicsInfoApi({ id: topicIds }).then((res) => {
                    if (res.status_code === 200) {
                        firstForm.setFieldsValue(res.data);
                        // setDirections(res?.data);
                    }
                });
            } else {
                firstForm.setFieldValue("name", "");
                firstForm.setFieldValue("hide", false);
            }
        }
    }, [firstShow]);
    return (
        <>
            <div className="page-title">
                <Link to="/selection/projects" className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">主题管理</span>
                {/* <span className="current-subtitle">{location.state?.projectName}</span> */}
            </div>
            <Content className="main-content-box">
                <div className="filter-form-box">
                    <Space>
                        <Button type="primary" onClick={() => navigate(`/selection/projects/${params.pid}/topics/add`)}>
                            新增申报主题
                        </Button>
                        <Button
                            onClick={() => {
                                setFirstShow(true);
                                setTopicIds(undefined);
                            }}
                        >
                            新增申报分类
                        </Button>
                        <Button
                            onClick={() => {
                                setSortShow(true);
                            }}
                        >
                            申报主题排序
                        </Button>
                    </Space>
                </div>

                <div className="main-content-body">
                    <Table
                        className="topics-table"
                        rowKey={(item) => item.id}
                        columns={columns}
                        dataSource={data}
                        key={data}
                        expandable={{
                            defaultExpandAllRows: true,
                        }}
                        pagination={false}
                    />
                </div>
            </Content>

            <Drawer
                title="申报主题排序"
                placement="right"
                open={sortShow}
                destroyOnClose
                closeIcon={null}
                width={500}
                extra={
                    <>
                        <CloseOutlined className="drawer-close-icon" onClick={() => onClose("sort")} />
                    </>
                }
            >
                <Alert
                    message={
                        <>
                            <div>把主题拖动到合适顺序的位置，完成排序。</div>
                            <div>注意：主题排序限同级，不能改变主题从属关系。</div>
                        </>
                    }
                    type="warning"
                ></Alert>
                <SortTopic tableData={data} onReset={onReset} />
            </Drawer>
            <Drawer
                title={<div style={{textAlign:'center'}}>申报字段</div>}
                placement="right"
                open={fieldsShow}
                destroyOnClose
                closeIcon={null}
                width={850}
                extra={
                    <>
                        <CloseOutlined className="drawer-close-icon" onClick={() => onClose("fields")} />
                    </>
                }
                footer={
                    <div className="topics-drawer-footer">
                        <Space>
                            <Button onClick={() => onClose("fields")}>取消</Button>
                            <Button type="primary" onClick={onSaveField}>
                                确认
                            </Button>
                        </Space>
                    </div>
                }
            >
                <FieldsList topicId={topicId} allFields={allFields} setAllFields={setAllFields} fieldsSelect={fieldsSelect} setFieldsSelect={setFieldsSelect} />
            </Drawer>
            <Drawer
                title="申报方向"
                placement="right"
                open={declareShow}
                destroyOnClose
                closeIcon={null}
                width={500}
                extra={
                    <>
                        <CloseOutlined className="drawer-close-icon" onClick={() => onClose("declare")} />
                    </>
                }
            >
                <div>主题：{topicsName}</div>
                <div>申报方向：</div>
                <div>
                    <Button type="link" onClick={() => (setDeclareModal(true), setDeclareId(undefined))}>
                        <PlusCircleOutlined />
                        新增申报方向
                    </Button>
                </div>
                <Declare topicId={topicId} setDeclareModal={setDeclareModal} setDeclareId={setDeclareId} declareModal={declareModal} />
            </Drawer>
            <Modal className="topicModalClassName" title={declareId === undefined ? "新增申报方向" : "编辑申报方向"} open={declareModal} onCancel={() => setDeclareModal(false)} destroyOnClose zIndex={9000} maskClosable={false} onOk={onSaveDirections}>
                <Form layout="vertical" form={directionForm}>
                    <Form.Item
                        label="申报方向"
                        name={"description"}
                        rules={[
                            {
                                required: true,
                                message: "请填写申报方向",
                            },
                        ]}
                    >
                        <Input showCount maxLength={30} placeholder="请添加申报方向" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title={topicIds === undefined ? "新增申报分类" : "编辑申报分类"} open={firstShow} onCancel={() => setFirstShow(false)} destroyOnClose zIndex={9000} maskClosable={false} onOk={onSaveFirst}>
                <Form layout="vertical" form={firstForm}>
                    <Form.Item
                        label="主题分类名称"
                        name={"name"}
                        rules={[
                            {
                                required: true,
                                message: "请输入主题分类名称",
                            },
                            {
                                pattern: /^.{2,30}$/,
                                message: "主题分类名称2-30个字符",
                            },
                        ]}
                    >
                        <Input showCount minLength={2} maxLength={30} placeholder="请输入主题分类名称" />
                    </Form.Item>
                    <Form.Item label="是否隐藏" name={"hide"} valuePropName={"checked"}>
                        <Switch checkedChildren="是" unCheckedChildren="否" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default TopicsList;
