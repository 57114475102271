import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Button, Layout, Table, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ComplianceListFilterForm from './ComplianceListFilterForm';
import { useNavigate } from 'react-router-dom';
import { fullPagerConfig } from '@/utils/property';
import HttpClient from '@/core/http';
import { get } from '@/core/request';

const { Content } = Layout;

export default function ComplianceList() {

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 筛选数据
    const handleFilterFinish = (value) => {
        if (value.cateIdArr) {
            value.cateId = value.cateIdArr[0];
            value.labelId = value.cateIdArr[1];
        }
        delete value.cateIdArr;
        setFilterObj(value);
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { userNmae, phone, liveName, liveId, productId, orgId, cateId, identityType, viewStartTime, viewEndTime } = filterObj;

    const getData = () => {
        get(`/dms/compliance/list`, {
            limit: pageSize,
            page: currPage,
            userNmae: userNmae,
            phone: phone,
            liveName: liveName,
            liveId: liveId,
            productId: productId,
            orgId: orgId,
            cateId: cateId,
            identityType: identityType,
            viewStartTime: viewStartTime,
            viewEndTime: viewEndTime,
        }).then((res) => {
            if (res.status_code === 200) {
                res.data.list.forEach(item => {
                    item.key = item.id;
                });
                setTableData(res.data);
            }
        })
    }

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, userNmae, phone, liveName, liveId, productId, orgId, cateId, identityType, viewStartTime, viewEndTime]);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 删除modal相关
    const deleteConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认是否删除用户',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    HttpClient.delete('http://192.168.0.239:9003/dms/compliance/delete/' + `${row.id}`).then(res => {
                        if (res.status === 200) {
                            getData();
                        }
                    })
                }
            });
        }
    }

    // table的
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    // 点击查看跳转
    const navigate = useNavigate()
    const changePath = useCallback(
        (row) => {
            return () => {
                navigate(`/dms/compliance/user/lives/${row.id}`, { state: row });
            }
        },
        [navigate],
    );

    // 导出数据
    const handleExportFile = () => {
        get(`/dms/compliance/download`, {
            limit: pageSize,
            page: currPage,
            userNmae: userNmae,
            phone: phone,
            liveName: liveName,
            liveId: liveId,
            productId: productId,
            orgId: orgId,
            cateId: cateId,
            identityType: identityType,
            viewStartTime: viewStartTime,
            viewEndTime: viewEndTime,
        }, { responseType: 'blob' }).then((res) => {
            if (res.size !== 0) {
                message.success('请稍后，正在导出');
                const fileName = "合规化列表数据.xlsx";
                let a = document.createElement('a');
                let event = new MouseEvent('click');
                a.download = fileName;
                let objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.dispatchEvent(event);
                URL.revokeObjectURL(objectUrl);
            } else {
                message.warning('当前列表没有数据，无法导出')
            }
        })
    };

    // table 数据相关
    const columns = [
        { title: '用户ID', dataIndex: 'id', key: 'id' },
        { title: '用户名', dataIndex: 'userName', },
        { title: '性别', dataIndex: 'gender', },
        { title: '手机号', dataIndex: 'phone', },
        { title: '所在城市', dataIndex: 'provinceName', },
        { title: '所在医院', dataIndex: 'hospital', },
        { title: '科室', dataIndex: 'tagStr', },
        { title: '职称', dataIndex: 'titleStr', },
        { title: '直播分类', dataIndex: 'cateStr', },
        { title: '参与活动名称', dataIndex: 'liveName', },
        { title: '参与活动ID', dataIndex: 'liveId', },
        { title: '参与身份', dataIndex: 'identityType', },
        { title: '参与活动时间', dataIndex: 'liveStartTime', },
        { title: '参与时长', dataIndex: 'viewTimes', },
        { title: '产品线', dataIndex: 'productName', },
        { title: '所属企业', dataIndex: 'orgName', },
        {
            title: '观看类型', dataIndex: 'viewType',
            render: (operates) => (
                <span>{operates === 1 ? '直播' : '回放'}</span>
            )
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => (
                <Fragment>
                    <span className="operate-style" onClick={changePath(row)}>查看</span>
                    <span className="delete-style" onClick={deleteConfirm(row)}>删除</span>
                </Fragment>
            )
        },
    ];

    return (
        <Fragment>
            <div className="page-title">合规化管理</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <ComplianceListFilterForm
                        handleFilterFinish={handleFilterFinish}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-title">合规化列表</div>

                        <div className="table-toolbar">
                            <Button type="primary" onClick={handleExportFile}>导出数据</Button>
                        </div>
                    </div>

                    <Table
                        className="enterprise-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        pagination={paginationProps}
                        dataSource={tableData.list}
                    />
                </div>
            </Content>

        </Fragment>
    )
}
