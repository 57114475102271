import React,{useState,useEffect,forwardRef, useImperativeHandle} from 'react';
import ImgCrop from 'antd-img-crop';
import { Form, Upload, Input, Button, Switch, message, Select} from "antd";
import { useLocation } from 'react-router-dom';
import './index.less';
import QRCode from 'qrcode.react';
import haibaoLogo from '@static/images/haibao-logo.png';
import qrInside from '@static/images/qr-inside.png';
import { uploadFile,getAllChannelApi } from "@services/Wj";
import config from "@config";
const HaibaoOperat = ({url,haibaoImage,activeKey,setChannelName},ref) => {
    console.log(haibaoImage);
    const location = useLocation();
    const option = { headers: { "Content-Type": "multipart/form-data" } };
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [icon, setIcon] = useState(true);
    const [title, setTitle] = useState(location.state.title);
    const [description,setDescription] = useState('希望大家能够踊跃参加');
    const [channelUrl,setChannelUrl] = useState(undefined);
    const [opinionItem,setOpinionItem] = useState([]);
    const { TextArea } = Input;
    useImperativeHandle(ref, () => ({
        form
    }));
    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式")
        }
        // const isLt5M = file.size / 1024 / 1024 < 5;
        // if (!isLt5M) {
        //     message.error("头像大小不超过5MB");
        // }
        return isJpgOrPng;
        // && isLt5M;
    };
    const upload = async (value) => {
        const formData = new FormData();
        formData.append("file", value.file);
        formData.append("path", `skins/upload/`);
        console.log(value);
        const res = await uploadFile(formData, option);
        if (res.status_code === 200) {
            setFileList([{ url: config.OSS_PREFIX + `/${res.data}`, }]);
        }
    };

    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 4 * 1024 * 1024) {
            message.error('图片大小不能超过4MB。');
            return null;
        }
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    // if (imgDom.width < 750 || imgDom.height < 422) {
                    //     bool && message.warn('案例封面不小于750×422px。');
                    //     resolve(false);
                    // } else {
                        resolve(true);
                    // }
                };
            };
            reader.readAsDataURL(file);
        });
    };
    const showIcon = (checked) => {
        setIcon(checked)
    };
    const onChange = (value,valueString) => {
        console.log(valueString.label);
        if(value==='0'){
            setChannelUrl(`${url}`);
        }else{
            setChannelUrl(`${url}?channelId=${value}`);
        }
        setChannelName(valueString.label)
    };

    const textChange=(value,type)=>{
        if(type === 'title'){
            if (value&&value.length > 25) value= value.slice(0, 25) + "...";
        }else if(type === 'description'){}
            return value
    };

    useEffect(()=>{
        setTitle(location.state.title)
        setDescription('希望大家能够踊跃参加')
        form.setFieldValue('title',location.state.title)
        form.setFieldValue('description','希望大家能够踊跃参加')
        getAllChannelApi({page:1,limit:99999}).then(res=>{
            if(res.status_code===200){
                console.log(res);
                let obj=[{
                    value: '0',
                    label:'默认渠道'
                }]
                res.data.list&&res.data.list.map(item=>{
                    obj.push(
                        {
                            value: item.id,
                            label:item.title
                        }
                    )

                })
                setOpinionItem([...obj])
            }
        })
    },[])
    useEffect(() => {
        setFileList([])
    }, [activeKey]);
    return (
        <div className='opert-box'>
            <div className='opert-left'>
                <div className='opert-left-box' id='haibao_item_main'>
                    {icon?<img src={haibaoLogo} className='haibao-icon'/>:''}
                    <img src={fileList[0]?.url||haibaoImage} className='haibao-img' crossOrigin="anonymous"/>
                    <div className='haibao-fotter'>
                        <div className='haibao-qrcode'>
                        <QRCode
                                                className='qrCode_wrap'
                                                id="qrCode3"
                                                value={channelUrl||url}
                                                size={89} // 二维码的大小
                                                fgColor="#000000" // 二维码的颜色
                                                style={{ margin: 'auto' }}
                                                level="H"
                                            />
                        </div>
                        <div className='haibao-elaborate'>
                            <div className='haibao-title'>{textChange(title,'title')}</div>
                            <div className='haibao-description'>{textChange(description,'description')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='opert-right'>
                <Form layout="vertical" form={form}>
                    <Form.Item
                    label='海报标题'
                    name="title"
                    rules={[
                    {
                        required: true,
                        message: '请填写海报标题',
                    },
                    ]}
                    >
                        <Input value={title} onChange={(e)=>setTitle(e.target.value)} maxLength={50} showCount/>
                    </Form.Item>
                    <Form.Item
                    label='海报文本'
                    name="description"
                    rules={[
                    {
                        required: true,
                        message: '请填写海报文本',
                    },
                    ]}
                    >
                    <TextArea rows={2} autoSize={{minRows: 2,maxRows: 2}} value={description} onChange={(e)=>setDescription(e.target.value)} maxLength={40} showCount/>
                    </Form.Item>
                    <Form.Item label='选择渠道'>
                    <Select
                        showSearch
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        defaultValue={'0'}
                        options={opinionItem}
                    />
                    </Form.Item>
                </Form>
                <div>
                    <p className='set-size'>自定义背景</p>
                    <div className='upload-haibao'>
                        <div className='set-size'>背景图片</div>
                        <div>
                            <span className='upload-reset set-size' onClick={()=>setFileList([])}>重置</span>
                            <span className='upload-segmentation' >|</span>
                            <ImgCrop
                                aspect={9 / 16}
                                width={308}
                                height={408}
                                rotationSlider
                                minZoom={0.1}
                                maxZoom={2}
                                beforeCrop={async (file) => {
                                    const res = await beforeCropCheck(file, true);
                                    return res;
                                }}
                            >
                        <Upload
                            customRequest={upload}
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                        >
                                    <div>
                                        <Button type='link' className='haibao-custom set-size'>上传图片</Button>
                                    </div>
                        </Upload>

                    </ImgCrop>
                        </div>
                    </div>
                    <div className='logo-switch'>
                        <div className='set-size'>展示医调研logo</div>
                        <div>
                        <Switch className='bg-switch'  onChange={showIcon} checked={icon}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(HaibaoOperat);
