import React, { Fragment, useState } from "react";
import { Button, Layout, Card, Space, Form, Input, Tabs, Tooltip, Modal, Divider } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import "./index.less";
import { quoteSurveyApi } from "@services/Wj/Question";
import config from "@config";
import phoneHeader from "@static/images/phoneheader.png";
const Previewtemplate = ({ isPreview, setIsPreview, id, type }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [cumputer, setCumputer] = useState(true);
    const [mobile, setMobile] = useState(false);
    const handleOk = () => {
        setIsPreview(false);
        setCumputer(true);
        setMobile(false);
        let sendValue = {
            route: "newTemplate",
            type,
        };
        quoteSurveyApi({ id }).then((res) => {
            if (res.status_code === 200) {
                navigate("/wj/surveys/new/create", { state: { ...sendValue, id: res.data.id } });
            }
        });
    };
    const handleCancel = () => {
        setIsPreview(false);
        setCumputer(true);
        setMobile(false);
    };
    const changeCumputer = () => {
        setCumputer(true);
        setMobile(false);
    };

    const changeMobile = () => {
        setCumputer(false);
        setMobile(true);
    };
    return (
        <Fragment>
            <Modal
                visible={isPreview}
                onCancel={handleCancel}
                className="templete-preview"
                width={1200}
                title={[
                    <div className="reference-header">
                        <p className="preview-title">模版预览</p>
                        <div className="preview-center">
                            <div className="preview-action" onClick={changeCumputer} style={cumputer ? { color: "#1a73e8" } : {}}>
                                <DesktopOutlined className="preview-icon" />
                                <p>电脑</p>
                            </div>
                            <div className="preview-action" onClick={changeMobile} style={mobile ? { color: "#1a73e8" } : {}}>
                                <MobileOutlined className="preview-icon" />
                                <p>手机</p>
                            </div>
                        </div>
                    </div>,
                ]}
                footer={[
                    <div className="reference-footer">
                        <Button key="submit" type="primary" onClick={handleOk}>
                            引用
                        </Button>
                    </div>,
                ]}
            >
                {cumputer && <iframe className="quote-iframe-width" src={`${config.SURVEY_DOMAIN}/s/${id}/answer?type=2`} />}
                {mobile && (
                    <div className="quote-mobile-box">
                        <div className="mobile-final">
                            <img src={phoneHeader} className="phone-header" />
                            <div className="modal-content">
                                <iframe className="iframe-width" src={`${config.SURVEY_DOMAIN}/s/${id}/answer?type=2`} />
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </Fragment>
    );
};

export default Previewtemplate;
