import React, { Fragment, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Layout, Switch, List, Form, Input, message } from "antd";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import "./index.less";
import { saveSettingApi, querySettingApi } from "@services/Wj/Question";
const Setting = (_, ref) => {
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [status, setStatus] = useState(undefined);
    const location = useLocation();
    const [surveyTitle, setSurveyTitle] = useState(undefined);
    const nagvigate = useNavigate();
    const goWhitelists = () => {
        nagvigate("/wj/surveys/new/whitelists", { state: { ...location.state } });
    };
    const finalCustom = () => {
        nagvigate("/wj/surveys/new/FinalCustom", { state: { ...location.state } });
    };

    useEffect(() => {
        settingStatus();
        setSurveyTitle(location.state.title);
    }, []);
    useImperativeHandle(ref, () => ({
        surveyTitle,
    }));
    const settingStatus = () => {
        querySettingApi({ id: location.state.id, type: location.state.route === "newCreate" ? 1 : 2 }).then((res) => {
            if (res.status_code === 200) {
                form.setFieldValue("changenum", res.data?.answerTimes);
                if (res.data === null) {
                    setStatus({
                        isAnswerOnce: 0,
                        isNeedLogin: 0,
                        hasWhitelist: 0,
                        isEndPage: 0,
                    });
                } else {
                    if (res.data.isEndPage === null) {
                        setStatus({
                            ...res.data,
                            isEndPage: 0,
                        });
                    } else {
                        setStatus(res.data);
                    }
                }
            }
        });
    };

    const onAnswer = (checked) => {
        if (location.state.route === "newCreate") {
            if (checked) {
                saveSettingApi({ isAnswerOnce: 1, answerTimes: 1, surveyId: location.state.id, type: 1, isNeedLogin: 1 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            } else {
                saveSettingApi({ isAnswerOnce: 0, answerTimes: 1, surveyId: location.state.id, type: 1 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            }
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            if (checked) {
                saveSettingApi({ isAnswerOnce: 1, answerTimes: 1, surveyId: location.state.id, type: 2, isNeedLogin: 1 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            } else {
                saveSettingApi({ isAnswerOnce: 0, answerTimes: 1, surveyId: location.state.id, type: 2 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            }
        }
    };
    const onNeedLogin = (checked) => {
        if (location.state.route === "newCreate") {
            if (checked) {
                saveSettingApi({ isNeedLogin: 1, surveyId: location.state.id, type: 1 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            } else {
                saveSettingApi({ isNeedLogin: 0, surveyId: location.state.id, type: 1, isNeedAuth: 0, isNeedAuthInfo: 0, hasWhitelist: 0, isAnswerOnce: 0 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            }
        } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
            if (checked) {
                saveSettingApi({ isNeedLogin: 1, surveyId: location.state.id, type: 2 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            } else {
                saveSettingApi({ isNeedLogin: 0, surveyId: location.state.id, type: 2, isNeedAuth: 0, isNeedAuthInfo: 0, hasWhitelist: 0, isAnswerOnce: 0 }).then((res) => {
                    if (res.status_code === 200) {
                        settingStatus();
                    }
                });
            }
        }
    };

    const saveSettingMethod = (params) => {
        saveSettingApi(params).then((res) => {
            if (res.status_code === 200) {
                settingStatus();
            }
        });
    };
    const changeOptions = (type, checked) => {
        let params = {};
        switch (type) {
            case "isNeedAuth":
                params = {
                    isNeedAuth: checked ? 1 : 0,
                    surveyId: location.state.id,
                    type: location.state.route === "newCreate" ? 1 : 2,
                    isNeedLogin: 1,
                };
                break;
            case "isNeedAuthInfo":
                params = {
                    isNeedAuthInfo: checked ? 1 : 0,
                    surveyId: location.state.id,
                    type: location.state.route === "newCreate" ? 1 : 2,
                    isNeedLogin: 1,
                };
                break;
            case "hasWhitelist":
                params = {
                    hasWhitelist: checked ? 1 : 0,
                    surveyId: location.state.id,
                    type: location.state.route === "newCreate" ? 1 : 2,
                    isNeedLogin: 1,
                };
                break;
            case "isEndPage":
                params = {
                    isEndPage: checked ? 1 : 0,
                    surveyId: location.state.id,
                    type: location.state.route === "newCreate" ? 1 : 2,
                    isNeedLogin: status.isNeedLogin,
                };
                break;
            default:
                break;
        }

        saveSettingMethod(params);
    };

    const ansewrTime = (e) => {
        form.setFieldValue("changenum", e.target.value);
        form.validateFields()
            .then((res) => {
                if (location.state.route === "newCreate") {
                    saveSettingApi({ answerTimes: e.target.value, surveyId: location.state.id, type: 1 }).then((res) => {
                        if (res.status_code === 200) {
                            settingStatus();
                        }
                    });
                } else if (location.state.route === "newList" || location.state?.route === "newTemplate") {
                    saveSettingApi({ answerTimes: e.target.value, surveyId: location.state.id, type: 2 }).then((res) => {
                        if (res.status_code === 200) {
                            settingStatus();
                        }
                    });
                }
            })
            .catch((err) => {
                message.error("请填写最多提交次数");
            });
    };
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="setting-title">
                        <h1>回收设置</h1>
                    </div>
                    <div className="setting-content setting-first">
                        <List itemLayout="horizontal">
                            <List.Item actions={[<Switch onChange={onNeedLogin} checked={status?.isNeedLogin} className="bg-switch" checkedChildren="开" unCheckedChildren="关" />]}>
                                <List.Item.Meta description="答题需要登录验证" />
                            </List.Item>
                            <List.Item
                                actions={[
                                    <div className="submit-num">
                                        <Form form={form}>
                                            {status?.isAnswerOnce ? (
                                                <Form.Item
                                                    name="changenum"
                                                    label="最多提交次数："
                                                    className="change-num"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "",
                                                        },
                                                    ]}
                                                >
                                                    <Input onBlur={(e) => ansewrTime(e)} />
                                                </Form.Item>
                                            ) : (
                                                <></>
                                            )}
                                        </Form>
                                        <Switch onChange={onAnswer} checked={status?.isAnswerOnce} className="bg-switch" checkedChildren="开" unCheckedChildren="关" />
                                    </div>,
                                ]}
                            >
                                <List.Item.Meta description={status?.isAnswerOnce ? "用户提交次数限制" : "每个用户只能回答一次"} />
                            </List.Item>
                            <List.Item
                                actions={[
                                    <Button
                                        className="hasWhitelist"
                                        style={{
                                            display: status?.hasWhitelist === 0 ? "none" : "",
                                        }}
                                        type="link"
                                        onClick={goWhitelists}
                                    >
                                        设置
                                    </Button>,
                                    <Switch onChange={(checked) => changeOptions("hasWhitelist", checked)} checked={status?.hasWhitelist} className="bg-switch" checkedChildren="开" unCheckedChildren="关" />,
                                ]}
                            >
                                <List.Item.Meta description="白名单用户限定答题" />
                            </List.Item>
                            <List.Item actions={[<Switch onChange={(checked) => changeOptions("isNeedAuthInfo", checked)} checked={status?.isNeedAuthInfo} className="bg-switch" checkedChildren="开" unCheckedChildren="关" />]}>
                                <List.Item.Meta description="要求完成平台专业认证或提交认证信息" />
                            </List.Item>
                            <List.Item actions={[<Switch onChange={(checked) => changeOptions("isNeedAuth", checked)} checked={status?.isNeedAuth} className="bg-switch" checkedChildren="开" unCheckedChildren="关" />]}>
                                <List.Item.Meta description="要求完成平台专业认证" />
                            </List.Item>
                        </List>
                    </div>
                    <div className="setting-title">
                        <h1>答题结束页面设置</h1>
                    </div>
                    <div className="setting-content setting-first">
                        <List itemLayout="horizontal">
                            <List.Item
                                actions={[
                                    <Button
                                        className="finalCustom"
                                        style={{
                                            display: status?.isEndPage === 0 ? "none" : "",
                                        }}
                                        type="link"
                                        onClick={finalCustom}
                                    >
                                        设置
                                    </Button>,
                                    <Switch onChange={(checked) => changeOptions("isEndPage", checked)} checked={status?.isEndPage} className="bg-switch" checkedChildren="开" unCheckedChildren="关" />,
                                ]}
                            >
                                <List.Item.Meta description="结束页面自定义" />
                            </List.Item>
                        </List>
                    </div>
                </div>
            </Content>
        </Fragment>
    );
};

export default forwardRef(Setting);
