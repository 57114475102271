import { useEffect, useCallback, useRef } from 'react';
import * as echarts from 'echarts';

function useCharts(chartRef, options) {
    let myChart = useRef(null);
    const renderChart = useCallback(() => {
        const chart = echarts.getInstanceByDom(chartRef.current);
        if (chart) {
            myChart.current = chart;
        } else {
            myChart.current = echarts.init(chartRef.current);
        }
        myChart.current.setOption(options);
    }, [chartRef, options]);

    useEffect(() => {
        renderChart()
    }, [renderChart]);

    useEffect(() => {
        return (
            () => {
                myChart.current && myChart.current.dispose();
            }
        )
    }, []);
}


export default useCharts;
