import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Divider, Drawer, Form, Input, InputNumber, Layout, Modal, Space, Table, Tooltip, Typography } from "antd";
import { LeftOutlined, ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import UploadVideo from "./components/UploadVideo";
import { liveReplaysListApi, delVideoApi, defaultVideoApi, updateLiveStartTimeApi } from "@services/Conference";
import moment from "moment";
import VideoReplay from "./components/VideoReplay";
import { fetchReplayVideoList } from '@services/Conference';
import config from '@config';
import './index.less';
import './LiveReplays.less';

const { Content } = Layout;
const { Text } = Typography;

export default function LiveReplays() {
    const [form] = Form.useForm();
    const params = useParams();
    const location = useLocation();
    const conferenceId = parseInt(params?.cid) || 0; // 会议项目 ID
    const liveId = parseInt(params?.id) || 0; // 直播 ID
    const [list, setList] = useState(undefined);
    const [videoOpen, setVideoOpen] = useState(false);
    const [liveReplayId, setLiveReplayId] = useState(undefined);
    const [liveStartTimeOpen, setliveStartTimeOpen] = useState(false);
    const [videoName, setVideoName] = useState(undefined);
    const [liveAllTime, setLiveAllTime] = useState(undefined);
    const [helper, setHelper] = useState(undefined);
    const [playTime, setPlayTime] = useState(undefined);

    const [visibleReplayVideoDrawer, setVisibleReplayVideoDrawer] = useState(false);
    const [replayVideoList, setReplayVideoList] = useState([]);

    const showReplayVideoDrawer = async () => {
        const res = await fetchReplayVideoList({ liveId });
        if (res.status_code === 200) {
            let videos = res.data;
            if (videos && videos.length) {
                videos = videos.map(v => {
                    const fileSize = v?.size
                        ? v.size / 1024 / 1024 / 1024 > 1
                            ? `${(v.size / 1024 / 1024 / 1024).toFixed(2)}GB`
                            : `${(v.size / 1024 / 1024).toFixed(2)}MB`
                        : <Text type="secondary">&ndash;</Text>;
                    const prefix = config.LIVE_RECORD_OSS_URL_PREFIX;
                    const mp4 = v?.key?.startsWith('/') ? `${prefix}${v.key}` : `${prefix}/${v.key}`;
                    const matches = v.key.match(/mp4\/(\d{4}\-\d\d\-\d\d)\-(\d\d:\d\d:\d\d)_(\d{4}\-\d\d\-\d\d)\-(\d\d:\d\d:\d\d)\.mp4/i);
                    const startTime = matches[1] + ' ' + matches[2];
                    const endTime = matches[3] + ' ' + matches[4];
                    const duration = moment.utc(moment.duration(moment(endTime).diff(moment(startTime))).asMilliseconds()).format('HH:mm:ss');

                    return {
                        ...v,
                        fileSize,
                        mp4,
                        startTime,
                        endTime,
                        duration,
                    };
                });
            }
            console.log(videos);
            setReplayVideoList(videos);
        }

        setVisibleReplayVideoDrawer(true)
    };

    const delReplay = (id) => {
        delVideoApi({ id: id }).then((res) => {
            if (res.status_code === 200) {
                handleRefresh();
            }
        });
    };

    const defaultReplay = (id) => {
        defaultVideoApi({ id: id }).then((res) => {
            if (res.status_code === 200) {
                handleRefresh();
            }
        });
    };

    const secondsToFormatTime = (seconds,type) => {
        var hour, minute, second;
        hour = parseInt(seconds / 3600); // 转换小时
        if (hour < 9) {
            hour = `0${hour}`;
        }
        minute = parseInt((seconds - hour * 3600) / 60); // 转换分
        if (minute < 9) {
            minute = `0${minute}`;
        }
        second = seconds % 60; // 转换秒
        if (second < 9) {
            second = `0${second}`;
        }
        let obj={ hour: hour, minute: minute, second: second}
        if(type === 'table')return `${hour}:${minute}:${second}`;
        else if(type === 'form')return `${hour}时${minute}分${second}秒`;
        else return obj
    };

    const openPlayback = (record) => {
        form.setFieldsValue(secondsToFormatTime(record.liveStartTime));
        setliveStartTimeOpen(true);
        setLiveReplayId(record.id);
        setLiveAllTime(record.videoDuration)
        // setHelper(()=>{
        //     return `视频总时长${secondsToFormatTime(record.videoDuration,'form')}`
        // })
    }

    const downloadPlayback = (record) =>{
        // console.log(record.playInfos[0].playUrl);
        window.open(record.playInfos[0].playUrl, '_blank', 'noopener,noreferrer');
    }

    const columns = [
        {
            title: "文件名",
            dataIndex: "title",
            fixed: "left",
            width: 300,
            ellipsis: {
                showTitle: false,
            },
            render: (title) => <Tooltip placement="bottomLeft" title={title}>{title}</Tooltip>,
        },
        {
            title: "大小",
            dataIndex: "size",
            width: 120,
            render: (_, record) => <>
                {record.size
                    ? record?.size / 1024 / 1024 > 1
                        ? <span>{(record?.size / 1024 / 1024).toFixed(2)}GB</span>
                        : <span>{(record?.size / 1024).toFixed(2)}MB</span>
                    : <Text type="secondary">&ndash;</Text>
                }
            </>,
        },
        {
            title: "生成时间",
            dataIndex: "created_at",
            width: 200,
            render: (_, record) => (record.createdAt ? <span>{moment.utc(record.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span> : <Text type="secondary">&ndash;</Text>),
        },
        {
            title: "默认回放",
            dataIndex: "default",
            width: 120,
            render: (_, record) => (!!record.defaultPlay ? "默认回放" : <Text type="secondary">&ndash;</Text>),
        },
        {
            title: "回放状态",
            dataIndex: "videoStatus",
            width: 100,
            render: (_, record) => {
                return (
                    <>
                        {record.videoStatus === 0 && <span>转码中</span>}
                        {record.videoStatus === 1 && <span>转换成功</span>}
                        {record.videoStatus === 2 && <span>转换失败</span>}
                    </>
                );
            },
        },
        {
            title: "回放来源",
            dataIndex: "source",
            width: 120,
            render: (_, record) => (record.source === 1 ? <Text type="warning">系统生成</Text> : record.source === 2 ? "手动上传" : <Text type="secondary">&ndash;</Text>),
        },
        {
            title: "回放开始播放时间",
            dataIndex: "liveStartTime",
            width: 120,
            render: (_, record) => <Text>{secondsToFormatTime(record.liveStartTime,'table')}</Text>,
        },
        {
            title: "操作",
            dataIndex: "operations",
            width: 240,
            fixed: "right",
            render: (_, record) => {
                return (
                    <span className="operation-btns">
                        {!record.defaultPlay
                            ? <>
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => defaultReplay(record.id)} disabled={record.videoStatus !== 1}
                                >
                                    设为默认
                                </Button>
                                <Divider type="vertical" />
                            </>
                            : null
                        }
                        <Button
                            size="small"
                            type="link"
                            onClick={() => openPlayback(record)}
                        >
                            回放开始时间
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                setVideoOpen(true);
                                setLiveReplayId(record.id);
                                setVideoName(record.title);
                                setPlayTime(record.liveStartTime);
                            }}
                            disabled={record.videoStatus !== 1}
                        >
                            预览视频
                        </Button>
                        <Divider type="vertical" />
                        {record.playInfos && record.playInfos.length>0 && record.playInfos[0].playUrl ? (
                            <>
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => downloadPlayback(record)}
                                >
                                    下载回放
                                </Button>
                                <Divider type="vertical" />
                            </>
                        ) : (
                            null
                        )}
                        <Button size="small" type="link" danger onClick={() => delReplay(record.id)}>
                            删除回放
                        </Button>
                    </span>
                );
            },
        },
    ];

    const [visibleUploadReplayDrawer, setVisibleUploadReplayDrawer] = useState(false);

    const videoClose = () => {
        setVideoOpen(false);
    };

    const showUploadReplayDrawer = () => {
        setVisibleUploadReplayDrawer(true);
    };

    const handleRefresh = () => {
        liveReplaysListApi({ liveId: liveId }).then((res) => {
            if (res.status_code === 200) {
                setList(res.data);
            }
        });
    };

    const getTime = () => {
        let hour =form.getFieldValue('hour') || 0;
        let minute =form.getFieldValue('minute') || 0;
        let second =form.getFieldValue('second') || 0;
        let time = (Number(hour)*3600) + (Number(minute)*60) + Number(second);
        // if(time === 0){
        //     // setHelper('请输入')
        //     form.setFields([{ name: 'time', errors: [''] }]);
        // }else{
            if(second>60 || minute>60) return
            updateLiveStartTimeApi({id:liveReplayId,liveStartTime:time}).then(res=>{
                if(res.status_code === 200){
                    setliveStartTimeOpen(false)
                    handleRefresh();
                }
            })
        // }
    };
    const changeTime = (value,type) => {
        // setHelper(`视频总时长${secondsToFormatTime(liveAllTime,'form')}`)
        form.setFields([{ name: 'time', errors: null }]);
        form.setFields([{ name: type, errors: null }]);
        switch(type){
            case 'minute':
                if(value>60){
                    form.setFields([{ name: type, errors: ['请输入0-60以内的数字']}]);
                }
                break;
            case 'second':
                if(value>60){
                    form.setFields([{ name: type, errors: ['请输入0-60以内的数字'] }]);
                }
                break;
            default:
                break;
    }
    };
    const showTime =(value) => {
        if (value < 9) {
            return value = `0${value}`;
        }else{
            return value;
        }
    };
    useEffect(() => {
        handleRefresh();
    }, []);

    return (
        <>
            <div className="page-title">
                <Link to={`/conf/conferences/${conferenceId}/lives`} className="back">
                    <LeftOutlined />
                </Link>
                <span className="current-title">回放管理</span>
                {!!liveId && <span className="current-subtitle">{location.state?.title || ""}</span>}
            </div>

            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="main-toolbar">
                        <div className="main-tools">
                            <Button type="primary" onClick={() => showUploadReplayDrawer()}>上传回放视频</Button>
                        </div>
                        <div className="sub-tools">
                            <Space>
                                <Button onClick={() => showReplayVideoDrawer()}>下载回放视频</Button>
                                <Tooltip title="刷新列表">
                                    <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}></Button>
                                </Tooltip>
                            </Space>
                        </div>
                    </div>

                    <Table columns={columns} dataSource={list} pagination={false} scroll={{ x: 1300 }} />
                </div>
            </Content>

            <Modal title="回放开始时间" open={liveStartTimeOpen} onCancel={()=>setliveStartTimeOpen(false)} onOk={getTime} destroyOnClose>
                <Form form={form}>
                    <Form.Item label="开始时间" name='time' className="live-start-time" help={helper}>
                        <Form.Item name="hour" className="time-input-box">
                            <InputNumber className="time-input"  min={0} precision={0} onChange={(v)=>changeTime(v,'hour')} formatter={showTime}/>
                        </Form.Item>
                        <span className="time-unit">时</span>
                        <Form.Item name="minute" className="time-input-box">
                            <InputNumber className="time-input" min={0} precision={0} onChange={(v)=>changeTime(v,'minute')} formatter={showTime}/>
                        </Form.Item>
                        <span className="time-unit">分</span>
                        <Form.Item name="second" className="time-input-box">
                            <InputNumber className="time-input"  min={0} precision={0} onChange={(v)=>changeTime(v,'second')} formatter={showTime}/>
                        </Form.Item>
                        <span className="time-unit">秒</span>
                    </Form.Item>
                </Form>
            </Modal>

            <Drawer
                title="下载回放视频"
                placement="right"
                width={600}
                open={visibleReplayVideoDrawer}
                onClose={() => setVisibleReplayVideoDrawer(false)}
            >
                <div className="replay-mp4-list">
                    {replayVideoList.map((r, idx) => <>
                        <div className="replay">
                            <div className="replay-info">
                                <div className="replay-title">回放视频{idx + 1}</div>
                                <div className="range">时间段：<span>{r.startTime}～{r.endTime}</span></div>
                                <div className="extra">
                                    <div className="duration">时长：{r.duration}</div>
                                    <div className="file-size">文件大小：{r.fileSize}</div>
                                </div>
                            </div>
                            <a className="link" target="_blank" href={r.mp4}>下载视频</a>
                        </div>
                    </>)}
                </div>
            </Drawer>

            <UploadVideo
                visibleUploadReplayDrawer={visibleUploadReplayDrawer}
                setVisibleUploadReplayDrawer={setVisibleUploadReplayDrawer}
                liveId={liveId}
                conferenceId={conferenceId}
                handleRefresh={handleRefresh}
            />

            <VideoReplay
                videoOpen={videoOpen}
                videoClose={videoClose}
                liveReplayId={liveReplayId}
                videoName={videoName}
                playTime={playTime}
            />
        </>
    );
}
