export const HotelList = [
    {
        key: '海口万豪酒店',
        label: '海口万豪酒店',
        address: '海南省海口市秀英区滨海大道292号',
        roomTypes: [
            '奢丽海景大床房',
            '奢丽海景双床房',
            '行政海景大床房',
            '行政海景双床房',
            '豪华海景套房',
            '尊贵海景套房',
        ]
    },
    {
        key: '海口国宾馆酒店',
        label: '海口国宾馆酒店',
        address: '海南省海口市秀英区滨海大道256号',
        roomTypes: [
            '高级大床房',
            '高级双床房',
            '高级海景大床房',
            '高级海景双床房',
            '豪华海景大床房'
        ]
    },
    {
        key: '海口喜来登',
        label: '海口喜来登',
        address: '海南省海口市秀英区滨海路136号',
        roomTypes: [
            '花园大床房',
            '花园双床房'
        ]
    },
    {
        key: '良智海景大酒店',
        label: '良智海景大酒店',
        address: '海南省海口市秀英区滨海大道278号',
        roomTypes: [
            '大床房',
            '标间'
        ]
    },
    {
        key: '巨制国际酒店',
        label: '巨制国际酒店',
        address: '海南省海口市秀英区长滨东三街',
        roomTypes: [
            '大床房',
            '标间'
        ]
    },
];
