import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Row, Col, Button, Space } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { fullPagerConfig } from '@/utils/property';
import LeftTable from './LeftTable';
import RightTable from './RightTable';
import { get } from '@/core/request';

export default function TableTransfer({ productId, setTargetKeys, liveId }) {

    const [filterObj, setFilterObj] = useState({});
    // 筛选数据
    const handleFilterLeftFinish = useCallback(
        (value) => {
            setFilterObj(value);
        },
        [],
    );

    // 右边筛选的情况
    const [filters, setFilters] = useState({})

    const handleFilterRightFinish = (value) => {
        value.name1 = value.name1 === undefined ? [] : [value.name1];
        value.gender1 = value.gender1 === undefined ? [] : [value.gender1 === 1 ? '男' : '女'];
        value.phone1 = value.phone1 === undefined ? [] : [value.phone1];
        let filters = {
            name: value.name1,
            gender: value.gender1,
            phone: value.phone1
        }
        setFilters(filters)
    }


    //根据名字和年龄多元素筛选
    const multiFilter = (array, filters) => {
        const filterKeys = Object.keys(filters)
        return array.filter((item) => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true
                return !!~filters[key].indexOf(item[key])
            })
        })
    };

    // 分页设置
    const [pageSize, setPageSize] = useState(5);
    const [currPage, setCurrPage] = useState(1);
    // 右边数据
    const [leftData, setLeftData] = useState([]);
    const [rightData, setRightData] = useState([]);
    const [rightFilterData, setRightFilterData] = useState([]);

    useEffect(() => {
        let arr = multiFilter(rightFilterData, filters);
        setRightData(arr)
    }, [filters, rightFilterData])

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: leftData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    // 左边选中的key
    const [selectedLeftRowKeys, setSelectedLeftRowKeys] = useState([]);
    // 左边选中的数据
    const [selectedRows, setSelectedRows] = useState([]);
    // 右边选中的key
    const [selectedRightRowKeys, setSelectedRightRowKeys] = useState([]);

    const hasLeftSelected = selectedLeftRowKeys.length > 0;
    const hasRightSelected = selectedRightRowKeys.length > 0;

    const { gender, name, phone } = filterObj;

    // 渲染左边列表table数据
    useEffect(() => {
        get(`/dms/limits/pageNotInWhitelist`, {
            limit: pageSize,
            page: currPage,
            productId: productId,
            liveId: liveId,
            type: 1,
            name: name,
            phone: phone,
            gender: gender
        }).then((res) => {
            if (res.status_code === 200) {
                const { list } = res.data;
                list.forEach((item) => {
                    item.key = item.userId;
                });
                setLeftData(res.data);
            }
        });
    }, [productId, pageSize, currPage, gender, name, phone, liveId]);


    // 点击添加按钮
    const buttonMoveToRight = () => {
        setRightData(selectedRows);
        setRightFilterData(selectedRows);
    }

    const [delData,setDelData] = useState([])
    // 点击移除按钮
    const buttonMoveToLeft = () => {
        selectedRightRowKeys.sort((a, b) => {
            return a - b
        })
        setDelData(selectedRightRowKeys)
        for (let i = rightData.length - 1; i >= 0; i--) {
            selectedRightRowKeys.forEach(item => {
                if (rightData[i]) {
                    if (item === rightData[i].key) {
                        rightData.splice(i, 1)
                    }
                }

            })
        };
        selectedLeftRowKeys.sort((a, b) => {
            return a - b
        })
        for (let i = selectedLeftRowKeys.length - 1; i >= 0; i--) {
            selectedRightRowKeys.forEach(item => {
                if (item === selectedLeftRowKeys[i]) {
                    selectedLeftRowKeys.splice(i, 1)
                }
            })
        };
        setSelectedRows([...rightData]);
        setRightData([...rightData]);
        setRightFilterData([...rightData]);
        setSelectedRightRowKeys([]);
        setSelectedLeftRowKeys([...selectedLeftRowKeys]);
    };

    useEffect(() => {
        let arr = [];
        rightData.forEach(item => {
            arr.push(item.userId)
        });
        setTargetKeys(arr);
    }, [rightData, setTargetKeys]);

    return (
        <Fragment>
            <Row gutter={16} className="surveys-add">
                <Col className="gutter-row" span={11}>
                    <p>可选用户列表</p>
                    <LeftTable
                        delData={delData}
                        page={currPage}
                        setSelectedRows={setSelectedRows}
                        selectedRowKeys={selectedLeftRowKeys}
                        setSelectedRowKeys={setSelectedLeftRowKeys}
                        tableData={leftData}
                        handleFilterFinish={handleFilterLeftFinish}
                        paginationProps={paginationProps}>
                    </LeftTable>
                </Col>
                <Col span={2} className="surveys-add-center">
                    <Space direction="vertical">
                        <Button onClick={buttonMoveToRight} disabled={!hasLeftSelected}>添加<RightOutlined /></Button>
                        <Button onClick={buttonMoveToLeft} disabled={!hasRightSelected}><LeftOutlined />移除</Button>
                    </Space>
                </Col>
                <Col span={11}>
                    <div>
                        <p>已选用户列表</p>
                        <RightTable
                            setSelectedRowKeys={setSelectedRightRowKeys}
                            selectedRowKeys={selectedRightRowKeys}
                            tableData={rightData}
                            handleFilterFinish={handleFilterRightFinish}>
                        </RightTable>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
