import React, { Fragment, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { pageFormItemWithoutLabelGridLayout, drawerFormItemLayout, drawerWidth } from '@utils/property';
import { Form, Input, Divider, Button, Space, Switch, Drawer, Cascader } from 'antd';
import { get } from '@/core/request';
const { TextArea } = Input;

function ProductionLinesTopicsAddForm({ handleFinishEdit, liveCatesId }, ref) {

    const [form] = Form.useForm();

    // 新增drawer相关
    const [visible, setVisible] = useState(false);
    const [pedding, setPedding] = useState(false);
    const [rowData, setRowData] = useState({});

    // 暴露给父组件show方法
    useImperativeHandle(ref, () => ({
        show(row) {
            get(`/dms/production-lines/topics/findById`, {
                id: row.id
            }).then((res) => {
                if (res.status_code === 200) {
                    setRowData(res.data);
                    const { status, name, description, cateId, labelId } = res.data;
                    form.setFieldsValue({
                        status: status === 1 ? true : false,
                        name: name,
                        description: description,
                        cateIdArr: [`${cateId}`, `${labelId}`]
                    })
                }
            });
            setVisible(true);
        }
    }));

    const handleClose = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleConfimClose = useCallback(
        () => {
            setVisible(false);
        },
        [],
    );

    const handleOk = () => {
        if (pedding) return;
        setPedding(true);
        form.validateFields()
            .then((value) => {
                if (value.cateIdArr) {
                    value.cateId = Number(value.cateIdArr[0]);
                    value.labelId = Number(value.cateIdArr[1]);
                }
                delete value.cateIdArr
                value.status = value.status === true ? 1 : 0;
                value.id = rowData.id;
                handleFinishEdit(value)
                handleConfimClose();
            })
            .catch((err) => { });
        setPedding(false);
    };

    return (
        <Fragment>
            {
                visible && <Drawer
                    closable={false}
                    width={drawerWidth}
                    onClose={handleClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <div className="main-content-body">
                        <h2>专题详情</h2>
                        <Form
                            {...drawerFormItemLayout}
                            form={form}
                        >
                            <Form.Item
                                name="name"
                                label="专题名称"
                                rules={[{ required: true, message: '请输入专题名称' }]}>
                                <Input placeholder="请输入专题名称" allowClear autoComplete="off"/>
                            </Form.Item>

                            <Form.Item label="产品线">
                                {rowData.productsName}
                            </Form.Item>

                            <Form.Item label="所属企业">
                                {rowData.orgName}
                            </Form.Item>

                            <Form.Item
                                label="直播分类"
                                name="cateIdArr"
                                rules={[{ required: true, message: '请选择直播分类' }]}>
                                <Cascader options={liveCatesId} placeholder="请选择直播分类" />
                            </Form.Item>

                            <Form.Item label="专题描述" name="description">
                                <TextArea
                                    placeholder="请输入分类描述"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    showCount maxLength={50}
                                />
                            </Form.Item>

                            <Form.Item label="状态" name="status" valuePropName="checked">
                                <Switch />
                            </Form.Item>

                            <Divider />

                            <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                                <Space>
                                    <Button className="submit-button" type="primary" htmlType="submit" onClick={handleOk}>保存</Button>
                                    <Button type="primary" onClick={handleClose}>取消</Button>
                                </Space>
                            </Form.Item>

                        </Form>

                    </div>
                </Drawer>
            }


        </Fragment>

    );
}

export default forwardRef(ProductionLinesTopicsAddForm)
