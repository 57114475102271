import React, { Fragment, useMemo, useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, DatePicker, Form, Input, Layout, Radio, TreeSelect, Space, Switch, TimePicker, Image, message, InputNumber } from 'antd';
import { InfoCircleOutlined, LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import Upload from 'antd/lib/upload/Upload';
import ReactQuill, { Quill } from 'react-quill';
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { fileApi, liveConferencehallsApi, saveLiveApi, getLiveInfoApi, editLiveApi } from "@services/Conference";
import './index.less'
import FormItem from 'antd/es/form/FormItem';
import ImgCrop from 'antd-img-crop';
import config from '@config';
import { emptyRichText } from '@/utils/utils';

Quill.register('modules/imageResize', ImageResize);

const { Content } = Layout;
const option = { headers: { "Content-Type": "multipart/form-data" } }

export default function Live() {
    const navigate = useNavigate();

    const refs = useRef();

    const params = useParams();
    const conferenceId = parseInt(params?.cid) || 0; // 会议项目 ID

    const liveId = parseInt(params?.id) || 0; // 直播 ID

    const [form] = Form.useForm();

    const [title, setTitle] = useState(undefined);

    const [introduce, setIntroduce] = useState(undefined);

    const [conferenceList, setConferenceList] = useState([]);

    const [fileList, setFileList] = useState([])
    const [coverList, setCoverList] = useState([])
    const [wxCoverList, setWxCoverList] = useState([])
    const [oneData, setOneData] = useState(false);
    const [days, setDays] = useState(undefined)
    const [startTime, setStartTime] = useState(undefined)
    const [endTime, setEndTime] = useState(undefined)
    const [switchCase, setSwitchCase] = useState(undefined)
    const [pull, setPull] = useState(false);

    const onFinish = values => {
        if (values.conferenceSiteHidden === undefined) values.conferenceSiteHidden = 0;
        if (values.healthcarePlatformHidden === undefined) values.healthcarePlatformHidden = 1;
        if (values.replayEnabled === undefined) values.replayEnabled = 1;
        if (values.hiddenComment === undefined) values.hiddenComment = 0;
        if (values.commentEnabled === undefined) values.commentEnabled = 1;
        if (values.shareInfoEnabled === undefined) values.shareInfoEnabled = 0;
        if (values.hallsId === null) values.hallsId = 0;
        values.pullStreamLive = Number(values.pullStreamLive) === 1 ? 1 : 0;
        values.startTime = days + startTime
        values.endTime = days + endTime
        values.conferenceId = Number(params.cid)
        values.description = emptyRichText(values.description)
        console.log(moment(`${days} ${endTime}`).valueOf(), new Date().getTime());
        if (endTime && moment(`${days} ${endTime}`).isBefore(moment(`${days} ${startTime}`))) {
            message.warning('结束时间不得早于开始时间')
        }
        // else if (moment(`${days} ${endTime}`).valueOf() < new Date().getTime()) {
        //     message.warn('结束时间不得早于当前时间')

        // }
        else {
            if (liveId) {
                values.id = liveId
                editLiveApi(values).then(res => {
                    if (res.status_code === 200) {
                        navigate(`/conf/conferences/${conferenceId}/lives`)
                    }
                })
            } else {
                saveLiveApi(values).then(res => {
                    if (res.status_code === 200) {
                        navigate(`/conf/conferences/${conferenceId}/lives`)
                    }
                })
            }
        }
    };

    const onReset = () => {
        navigate(`/conf/conferences/${conferenceId}/lives`)
    };

    const disabledDate = (d) => {
        return false || d < moment().subtract(1, "days");
    };

    const onIntroduceChange = (v) => {
        setIntroduce(v)
    }

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 14);
            const hide = message.loading("上传中...", 0);
            fileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = refs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    [
                        {
                            color: [],
                        },
                    ],
                    [
                        {
                            background: [],
                        },
                    ],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    const checkout = (file, size) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            setOneData(true);
            return null;
        } else {
            setOneData(false);
        }
        // 判断大小
        const isLtM = file.size / 1024 / 1024 < size;
        if (!isLtM) {
            message.error("图像必须小于" + size + "MB!");
            setOneData(true);
            return null;
        } else {
            setOneData(false);
        }
        return isJpgOrPng && isLtM
    };

    // 分享图
    const shareImageFile = {
        name: "shareImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 14);
            if (!oneData) {
                fileApi(formData, option).then((res) => {
                    if (!res) {
                        message.error("上传文件失败！！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setFileList([getFileUrl(res.data)]);
                        form.setFieldValue("shareImageUrl", getFileUrl(res.data));
                    }
                });
            } else {
                form.setFieldValue("shareImageUrl", undefined);
            }
        },
    };

    //封面
    const beforeCropCheck = (v, bool, type) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                var txt = event.target.result;
                var img = document.createElement("img");
                img.src = txt;
                img.onload = function () {
                    if (type === 3) {
                        if (img.width < 500) {
                            bool && message.warn('图片宽度小于500像素')
                            resolve(false)
                        } else if (img.height < 400) {
                            bool && message.warn('图片高度小于400像素')
                            resolve(false)
                        } else {
                            resolve(true)
                        }
                    } else {
                        if (img.width < 750) {
                            bool && message.warn('图片宽度小于750像素')
                            resolve(false)
                        } else if (img.height < 422) {
                            bool && message.warn('图片高度小于422像素')
                            resolve(false)
                        } else {
                            resolve(true)
                        }
                    }
                }
            };
            reader.readAsDataURL(v);
        })
    }

    // 图片上传
    const fileUpload = (info, type) => {
        const formData = new FormData();
        formData.append("file", info.file); //名字和后端接口名字对应
        formData.append('type', 14);
        if (!oneData) {
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error("上传文件失败！！");
                    return false;
                }
                if (res.status_code === 200) {
                    // pc封面
                    if (type === 1) {
                        setCoverList([getFileUrl(res.data)]);
                        form.setFieldValue("cover", getFileUrl(res.data));
                        form.setFields([{ name: 'cover', errors: null }]);
                    }
                    // 移动端封面
                    if (type === 2) {
                        setWxCoverList([getFileUrl(res.data)]);
                        form.setFieldValue("wxCover", getFileUrl(res.data));
                        form.setFields([{ name: 'wxCover', errors: null }]);
                    }
                }
            });
        } else {
            // pc封面
            if (type === 1) {
                form.setFieldValue("cover", undefined);
            }
            // 移动端封面
            if (type === 2) {
                form.setFieldValue("wxCover", undefined);
            }
        }
    };
    const dateChange = (value, valueString, type) => {
        if (type === "day") {
            setDays(valueString)
        } else if (type === "minute") {
            setStartTime(valueString[0] + ":00")
            setEndTime(valueString[1] + ":00")
        }
    };

    const hideChange = (checked, type) => {
        let obj
        switch (type) {
            case 'conferenceSiteHidden':
                let conferenceSiteHidden = Number(!checked);
                form.setFieldValue(type, conferenceSiteHidden);
                obj = {
                    ...switchCase,
                    conferenceSiteHidden: checked
                }
                setSwitchCase({ ...obj })
                break;
            case 'healthcarePlatformHidden':
                let healthcarePlatformHidden = Number(!checked);
                form.setFieldValue(type, healthcarePlatformHidden);
                obj = {
                    ...switchCase,
                    healthcarePlatformHidden: checked
                }
                setSwitchCase({ ...obj })
                break;
            case 'replayEnabled':
                let replayEnabled = Number(checked);
                form.setFieldValue(type, replayEnabled);
                obj = {
                    ...switchCase,
                    replayEnabled: checked
                }
                setSwitchCase({ ...obj })
                break;
            case 'hiddenComment':
                let hiddenComment = Number(!checked);
                form.setFieldValue(type, hiddenComment);
                obj = {
                    ...switchCase,
                    hiddenComment: checked
                }
                setSwitchCase({ ...obj })
                break;
            case 'commentEnabled':
                let commentEnabled = Number(checked);
                form.setFieldValue(type, commentEnabled);
                obj = {
                    ...switchCase,
                    commentEnabled: checked
                }
                setSwitchCase({ ...obj })
                break;
            default:
                break;
        }
    }

    const changehalls = (v) => {
        if (v === undefined) {
            form.setFieldValue('hallsId', null)
        }
    };

    const changePullStreamLive = (value) => {
        setPull(value);
    };

    useEffect(() => {
        liveConferencehallsApi({ conferenceId: params.cid, hidden: 1 }).then(res => {
            if (res.status_code === 200) {
                res.data = res.data.map(item => {
                    let newItem = { ...item };
                    // 被选中 || 存在子会议时，当前会议禁止选择
                    if (item.isSelect || (item.secondHallsList && item.secondHallsList.length)) {
                        newItem.disabled = true;
                    }
                    if (item.secondHallsList && item.secondHallsList.length) {
                        newItem.secondHallsList = item.secondHallsList.map(i => ({ ...i, disabled: i.isSelect }));
                    }
                    return newItem;
                })
                console.log(res.data);
                setConferenceList(res.data);
            }
        })
    }, []);

    useEffect(() => {
        if (liveId) {
            getLiveInfoApi({ id: liveId }).then(res => {
                if (res.status_code === 200) {
                    setTitle(res.data.name)
                    form.setFieldsValue(res.data);
                    setPull(res.data.pullStreamLive)
                    const start = moment(res.data.startTime);
                    const end = moment(res.data.endTime);
                    const days = moment(moment(res.data.startTime).format('YYYY-MM-DD'), 'YYYY-MM-DD');
                    const minutes = [start, end];
                    form.setFieldValue('time', minutes);
                    form.setFieldValue('date', days);
                    form.setFieldValue('cover', res.data.cover);
                    if (res.data.hallsId !== 0) {
                        form.setFieldValue('hallsId', res.data.hallsId)
                    } else {
                        form.setFieldValue('hallsId', null)
                    }
                    setDays(days.format('YYYY-MM-DD'));
                    setStartTime(start.format('HH:mm:00'));
                    setEndTime(end.format('HH:mm:00'));
                    if (res.data?.shareImageUrl) {
                        setFileList([res.data.shareImageUrl]);
                        form.setFieldValue("shareImageUrl", res.data.shareImageUrl);
                    }

                    if (res.data.cover) {
                        setCoverList([res.data.cover])
                        form.setFieldValue("cover", res.data.cover);
                    }
                    if (res.data.wxCover) {
                        setWxCoverList([res.data.wxCover])
                        form.setFieldValue("wxCover", res.data.wxCover);
                    }

                    setSwitchCase(
                        {
                            conferenceSiteHidden: res.data.conferenceSiteHidden === 1 ? false : true,
                            healthcarePlatformHidden: res.data.healthcarePlatformHidden === 1 ? false : true,

                            replayEnabled: res.data.replayEnabled === 1 ? true : false,
                            hiddenComment: res.data.hiddenComment === 1 ? false : true,

                            commentEnabled: res.data.commentEnabled === 1 ? true : false
                        }
                    )


                }
            });
        } else {
            setSwitchCase(
                {
                    conferenceSiteHidden: true,
                    healthcarePlatformHidden: false,

                    replayEnabled: true,
                    hiddenComment: true,

                    commentEnabled: true
                }
            )
        }
    }, []);

    return <Fragment>
        <div className="page-title">
            <Link to={`/conf/conferences/${conferenceId}/lives`} className="back"><LeftOutlined /></Link>
            <span className="current-title">{liveId ? '编辑直播' : '新增直播'}</span>
            {!!liveId && <span className="current-subtitle">{title || ''}</span>}
        </div>

        <Content className="main-content-box">

            <div className="main-content-title">直播信息</div>

            <div className="main-content-body">

                <Form
                    form={form}
                    {...pageFormItemLayout}
                    onFinish={onFinish}
                    className='conference-live-form'
                    initialValues={{
                        virtualPv: 99
                    }}
                >

                    <div className="form-subtitle">基本信息</div>

                    <Form.Item
                        label="直播名称"
                        name="name"
                        rules={[
                            { required: true, message: '直播名称不能为空' },
                        ]}
                    >
                        <Input placeholder="输入直播名称" maxLength={100} showCount />
                    </Form.Item>

                    <Form.Item label="关联会议" name="hallsId" extra="每场会议仅能绑定一场直播，若当前会议已被其他直播绑定将无法选择。">
                        <TreeSelect
                            placeholder="请选择直播所属会议场次"
                            treeData={conferenceList}
                            fieldNames={{
                                value: 'id',
                                label: 'name',
                                children: 'secondHallsList'
                            }}
                            onChange={changehalls}
                            treeDefaultExpandAll
                            allowClear>
                        </TreeSelect>
                    </Form.Item>

                    <Form.Item
                        label="直播时间"
                        className="required liveminute"
                    >
                        <Row>
                            <Col>
                                <FormItem name='date' rules={[
                                    { required: true, message: '直播日期不能为空' },
                                ]}>
                                    <DatePicker
                                        style={{ marginRight: 8 }}
                                        // disabledDate={disabledDate}
                                        onChange={(value, valueString) => dateChange(value, valueString, 'day')}
                                    />
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem name='time' rules={[
                                    { required: true, message: '直播时间不能为空' },
                                ]}>
                                    <TimePicker.RangePicker format="HH:mm:ss" onChange={(value, valueString) => dateChange(value, valueString, 'minute')} />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        label="PC端直播封面"
                        name="cover"
                        rules={[
                            { required: true, message: "请上传PC端直播封面" },
                        ]}
                        extra="支持JPG/PNG格式，建议尺寸 1500*844px（不小于 750*422px，比例16:9），文件大小不超过2MB"
                    >
                        {coverList.length > 0 ? (
                            <Image
                                width={200}
                                src={coverList[0]}
                                className="share-image-area"
                            />
                        ) : (
                            ""
                        )}
                        <div>
                            <Upload
                                customRequest={(e) => fileUpload(e, 1)}
                                maxCount={1}
                                fileList={coverList}
                                beforeUpload={(file) =>
                                    checkout(file, 2)
                                }
                            >
                                <Button icon={<UploadOutlined/>}>
                                    {coverList.length > 0 ? "更改封面" : "上传封面"}
                                </Button>
                            </Upload>
                        </div>
                    </Form.Item>

                    <Form.Item label='初始观看人数' name='virtualPv'>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item label='第三方拉流' name='pullStreamLive' valuePropName="checked">
                        <Switch checkedChildren="开" unCheckedChildren="关" onChange={changePullStreamLive} />
                    </Form.Item>

                    {pull
                        ? <Form.Item label='第三方拉流链接' name='pullStreamLiveUrl' extra='支持RTMP、FLV、HLS、SRT格式直播地址' rules={[{ required: true, message: '直播名称不能为空' }]}>
                            <Input />
                        </Form.Item>
                        : <></>
                    }

                    <Form.Item label="直播简介" name="description" className='invitationContent'>
                        <ReactQuill
                            ref={refs}
                            modules={modules}
                            theme="snow"
                            value={introduce}
                            onChange={onIntroduceChange}
                            className="ql-editor react-editor"
                        />
                    </Form.Item>

                    <div className="form-subtitle">展示设置</div>

                    <Form.Item
                        label="会议官网展示"
                        name='conferenceSiteHidden'
                        extra="控制该直播是否在会议官网展示"
                    >
                        <Switch
                            checked={switchCase?.conferenceSiteHidden}
                            onChange={(checked) => hideChange(checked, 'conferenceSiteHidden')}
                            checkedChildren="显示"
                            unCheckedChildren="隐藏"
                        />
                    </Form.Item>

                    <Form.Item
                        label="健康界平台展示"
                        name="healthcarePlatformHidden"
                        extra="控制该直播是否在健康界平台展示"
                    >
                        <Switch
                            checked={switchCase?.healthcarePlatformHidden}
                            onChange={(checked) => hideChange(checked, 'healthcarePlatformHidden')}
                            checkedChildren="显示"
                            unCheckedChildren="隐藏"
                        />
                    </Form.Item>

                    <Form.Item
                        label="开启回放"
                        name='replayEnabled'
                        extra="控制该直播是否能观看回放，关闭时会议直播不能观看回放，但直播系统正常生成回放"
                    >
                        <Switch
                            checked={switchCase?.replayEnabled}
                            onChange={(checked) => hideChange(checked, 'replayEnabled')}
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                        />
                    </Form.Item>

                    <Form.Item
                        label="直播评论展示"
                        name='hiddenComment'
                        extra="控制直播评论区是否在会议直播中展示"
                    >
                        <Switch
                            checked={switchCase?.hiddenComment}
                            onChange={(checked) => hideChange(checked, 'hiddenComment')}
                            checkedChildren="显示"
                            unCheckedChildren="隐藏"
                        />
                    </Form.Item>

                    <Form.Item
                        label="开启用户评论"
                        name='commentEnabled'
                        extra="控制直播评论区用户是否可添加新评论"
                    >
                        <Switch
                            checked={switchCase?.commentEnabled}
                            onChange={(checked) => hideChange(checked, 'commentEnabled')}
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                        />
                    </Form.Item>

                    <Form.Item
                        label="微信小程序分享卡片"
                        name="wxCover"
                        extra="支持JPG/PNG格式，建议尺寸 1000*800px（不小于 500*400px，比例5:4），文件大小不超过2MB"
                    >
                        {wxCoverList.length > 0 ? (
                            <Image
                                width={200}
                                src={wxCoverList[0]}
                                className="share-image-area"
                            />
                        ) : (
                            ""
                        )}
                        <div>
                            <Upload
                                customRequest={(e) => fileUpload(e, 2)}
                                maxCount={1}
                                fileList={wxCoverList}
                                beforeUpload={(file) =>
                                    checkout(file, 2)
                                }
                            >
                                <Button icon={<UploadOutlined/>}>
                                    {wxCoverList.length > 0 ? "更改封面" : "上传封面"}
                                </Button>
                            </Upload>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="分享信息"
                        name="shareInfoEnabled"
                    >
                        <Radio.Group defaultValue={0}>
                            <Radio value={0}>官网默认</Radio>
                            <Radio value={1}>单独配置</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            return getFieldValue('shareInfoEnabled') === 1 && <>
                                <Form.Item
                                    label="分享标题"
                                    name="shareTitle"
                                    rules={[
                                        { required: true }
                                    ]}
                                >
                                    <Input placeholder="请输入分享标题" />
                                </Form.Item>

                                <Form.Item
                                    label="分享描述"
                                    name='shareDescription'
                                >
                                    <Input placeholder="请输入分享描述" />
                                </Form.Item>

                                <Form.Item
                                    name="shareImageUrl"
                                    label="分享图"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                    extra="支持JPG/PNG格式，尺寸300&times;300px，大小不超过1M"
                                    className="share-image"
                                >
                                    {fileList.length > 0 ? (
                                        <Image
                                            width={200}
                                            src={fileList[0]}
                                            className="share-image-area"
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <div>
                                        <Upload
                                            beforeUpload={(file) =>
                                                checkout(file, 1)
                                            }
                                            fileList={fileList}
                                            {...shareImageFile}
                                            maxCount={1}
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                {fileList.length > 0
                                                    ? "更改图片"
                                                    : "上传图片"}
                                            </Button>
                                        </Upload>
                                    </div>
                                </Form.Item>

                            </>;
                        }}
                    </Form.Item>

                    <Form.Item {...pageFormItemWithoutLabelGridLayout} className="page-form-foot-operations">
                        <Space size="large">
                            <Button type="primary" size="large" htmlType="submit">提交</Button>
                            <Button type="default" size="large" onClick={onReset}>取消</Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>

        </Content>

    </Fragment>;
}
