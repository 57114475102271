import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, DatePicker, Button, Space, Cascader, InputNumber } from 'antd';
import './CheckOrganizationsQueryFrom.less'
const { RangePicker } = DatePicker;

export default function CheckOrganizationsQueryFrom({handleFilterFinish}) {

    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (values) => {
        if (values) {
            values.createdStartAt = timePicker[0];
            values.createdEndAt = timePicker[1];
            handleFilterFinish(values)
        }
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            //写 所有的筛选数据
            code: undefined,
            name: undefined,
            belongsUnitName: undefined,
            type: undefined,
            createdStartAt: undefined,
            createdEndAt: undefined,
        };
        handleFilterFinish(values);
    };


    return (
        <div className='checkorg-research'>
            <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
                <Row>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="学苑ID" name="code" >
                            <Input allowClear placeholder="学苑ID" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="学苑名称" name="name">
                            <Input allowClear placeholder="学苑名称" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="所属主体" name="belongsUnitName">
                            <Input allowClear placeholder="所属主体" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="学苑类型" name="type"  >
                            <Select allowClear placeholder="请选择"  >
                                <Select.Option value={2}>正式</Select.Option>
                                <Select.Option value={1}>试用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...filterFormGridLayout}>
                        <Form.Item label="申请时间" name="timePicker">
                            <RangePicker
                                allowClear
                                onChange={handleChangeTime}
                                showTime />
                        </Form.Item>
                    </Col>

                    <Col className="filter-form-buttons" {...filterFormGridLayout}>
                        <Space>
                            <Button type="default" onClick={onReset}>重置</Button>
                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                        </Space>
                    </Col>

                </Row>
            </Form>
        </div>
    );
}
