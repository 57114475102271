import React, { Fragment, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import {CloseOutlined, LeftOutlined, UploadOutlined} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import {Button, DatePicker, Form, Image as AntdImage, Input, message, Radio, Select, Space, Upload, TreeSelect} from "antd";
import {
    pageFormItemLayout,
    pageFormItemWithoutLabelGridLayout,
} from '@utils/property';
import ReactQuill, {Quill} from "react-quill";
import QRCode from 'qrcode.react';
import copy from "copy-to-clipboard";
import { dateTimeFormat, prepareBCOSSUrl } from '@utils/utils';
import { useProjectDiseaseList, useProjectDictionaryList, useEnterpriseList } from "@hooks/MedConnect";
import { createProject, editProject, uploadfileApi, getProjectDetail } from '@services/MedConnect';
import moment from "moment/moment";
import config from '@config';
import {useSelector} from "react-redux";
import ImgCrop from "antd-img-crop";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function MedConnectProject() {
    let { id } = useParams();
    id = !!id && parseInt(id) || 0;
    // 获取疾病列表
    const {diseaseList} = useProjectDiseaseList();
    // 获取项目负责人列表
    const {dictionaryList} = useProjectDictionaryList('project_admin', id);
    // 获取企业列表
    const {enterpriseList} = useEnterpriseList("公司");

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const refs = useRef()

    const projectName = useSelector((state) => state.medconnect.projectName);
    const [descriptions,setDescriptions] = useState(undefined);
    const [wxQrcodeUrlList, setWxQrcodeUrlList] = useState([]);
    const option = { headers: { "Content-Type": "multipart/form-data" } };

    const [codeHome, setCodeHome] = useState("");
    const [codeAudit, setCodeAudit] = useState("");

    const [coverList, setCoverList] = useState([]);

    const [selectValues, setSelectValues] = useState([]);

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 2);
            const hide = message.loading("上传中...", 0);
            uploadfileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data?.ossUrl); // 获取url,图片预览地址
                let quill = refs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{header: 1}, {header: 2}],
                    [{list: "ordered"}, {list: "bullet"}],
                    [{script: "sub"}, {script: "super"}],
                    [{indent: "-1"}, {indent: "+1"}],
                    [{direction: "rtl"}],
                    [{size: ["small", false, "large", "huge"]}], //字体设置
                    [
                        {
                            color: [],
                        },
                    ],
                    [
                        {
                            background: [],
                        },
                    ],
                    [{font: []}],
                    [{align: []}],
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    const onDescriptionsChange = (v) => {
        setDescriptions(v);
    };

    const onFinish = (values) => {
        const data = {...values};
        data.startedAt = dateTimeFormat(data.timeRange[0], 'YYYY-MM-DD 00:00:00');
        data.endedAt = dateTimeFormat(data.timeRange[1], 'YYYY-MM-DD 23:59:59');
        data.values = selectValues;

        if (id) {
            // 编辑
            editProject({
                ...data,
                id: id,
            }).then((res) => {
                if (res.success) {
                    message.success(res.data || '编辑成功');
                    navigate(`/medconnect/projects`);
                }
            })
        } else {
            // 创建
            createProject(data).then(res => {
                if (res.success) {
                    message.success(res.data || '创建成功');
                    navigate(`/medconnect/projects`);
                }
            })
        }
    }

    const onFinishFailed = ({ values, errorFields}) => console.log(values, errorFields);

    const onReset = () => {
        form.resetFields();
        navigate('/medconnect/projects');
    };

    const handleChangeDisease = (v, label) => {
        setSelectValues(v);
    }

    // 复制链接
    const copyUrl = (name) => {
        const values = form.getFieldValue(name);
        copy(values);
        message.success('已复制到剪贴板');
    }

    // 删除客服二维码
    const closeImg = () => {
        setWxQrcodeUrlList([]);
        form.setFieldValue('wechatOssKey', '');
    };

    // 上传图片
    const uploadImageFile = (fileInfo) => {
        const formData = new FormData();
        formData.append('file', fileInfo.file);
        formData.append('type', 2);

        uploadfileApi(formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(async (res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }
                if (res.success) {
                    const url = prepareBCOSSUrl(res.data?.ossUrl);
                    setWxQrcodeUrlList([url]);
                    form.setFieldValue('wechatOssKey', res.data?.ossKey);
                }
            })
    }

    const checkImgUploadFile = (file, sizeMb) => {
        const typeList = ['image/jpg', 'image/jpeg', 'image/png'];
        const isImage = typeList.includes(file.type) || false;

        if (!isImage) {
            message.error('仅支持 JPG/PNG 图片格式');
            return false;
        }

        const metSize = file.size / 1024 / 1024 < sizeMb;
        if (!metSize) {
            message.error(`图片文件大小限${sizeMb}MB`);
            return false;
        }

        return isImage && metSize;
    };

    const coverImageFile = {
        name: "coverImageFile",
        customRequest: (info) => {
            const formData = new FormData();
            formData.append("file", info.file); //名字和后端接口名字对应
            formData.append('type', 2);

            uploadfileApi(formData, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
                if (!res) {
                    message.error('文件上传失败');
                    return false;
                }
                if (res.success) {
                    const url = prepareBCOSSUrl(res.data?.ossUrl);
                    setCoverList([getFileUrl(url)]);
                    form.setFieldValue("coverOssKey", res.data?.ossKey);
                }
            });
        },
    };

    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 2 * 1024 * 1024) {
            message.error('图片大小不能超过2MB。');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    if (imgDom.width < 750 || imgDom.height < 422) {
                        bool && message.warn('项目封面不小于750×422px。');
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    const handleChangeHome = (e) => {
        setCodeHome(e.target.value);
    }

    const handleChangeAuditUrl = (e) => {
        setCodeAudit(e.target.value);
    }

    // 二维码
    const downloadQRCode = (name) => {
        const values = form.getFieldValue(name);
        if (!values) return;

        const qrCode = document.getElementById(`qrcode_conf_${name}`);
        // new Image() 与antd中Image组件冲突，报错时排查引入antd是否别名
        let image = new Image();
        image.src = qrCode.toDataURL('image/png');
        const link = document.getElementById(`link_qrcode_${name}`);
        link.href = image.src;
    };

    const queryProjectDetail = (id) => {
        getProjectDetail(id).then(res => {
            if (res && res.success) {
                const formData = {
                    ...res.data,
                };
                formData.timeRange = [moment(formData.startedAt), moment(formData.endedAt)];

                if (formData.questionUrl) {
                    setCodeHome(formData.questionUrl)
                }
                if (formData.auditUrl) {
                    setCodeAudit(formData.auditUrl)
                }
                if (formData.coverOssUrl) {
                    setCoverList([formData.coverOssUrl]);
                }
                if (formData.wechatOssUrl) {
                    setWxQrcodeUrlList([formData.wechatOssUrl]);
                }

                if (formData.values.length > 0) {
                    const values = formData.values.map(v => v.toString())
                    setSelectValues(values);
                }

                form.setFieldsValue(formData);
            }
        })
    };

    useEffect(() => {
        if (!id) return;
        queryProjectDetail(id);
    }, [id]);


    return (
        <Fragment>
            <div className="page-title">
                <Link to={`/medconnect/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">{!id ? '新增问答项目' : '项目信息'}</span>
                <span className="current-subtitle">{projectName}</span>
            </div>

            <Content className="main-content-box">
                <div className="main-content-body">

                    <Form
                        {...pageFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="form-subtitle">基本信息</div>
                        <Form.Item
                            name="h3yunNo"
                            label="氚云编号"
                            rules={[
                                {required: false, message: ''}
                            ]}
                        >
                            <Input placeholder="输入氚云编号" allowClear autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            name="projectName"
                            label="项目名称"
                            rules={[
                                {required: true, message: '请输入项目名称'}
                            ]}
                        >
                            <Input placeholder="请输入项目名称" maxLength={50} showCount allowClear autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            name="timeRange"
                            label="项目起止日期"
                            rules={[{required: true, message: '请选择起止日期和时间'}]}
                        >
                            <RangePicker/>
                        </Form.Item>
                        <Form.Item
                            name="coverOssKey"
                            label="项目封面"
                            rules={[
                                { required: true, message: '' },
                            ]}
                            help="支持JPG/PNG格式，建议尺寸 1500*844px（不小于 750*422px，比例16:9），文件大小不超过2MB"
                            className="share-image"
                        >
                            {coverList.length > 0 && <AntdImage width={200} src={coverList[0]} className="share-image-area"/>}

                            <div>
                                <ImgCrop
                                    aspect={16 / 9}
                                    minZoom={0.1}
                                    maxZoom={2}
                                    beforeCrop={async (file) => {
                                        const res = await beforeCropCheck(file, true);
                                        return res;
                                    }}
                                >
                                    <Upload
                                        beforeUpload={(file) =>
                                            checkImgUploadFile(file, 2)
                                        }
                                        fileList={coverList}
                                        {...coverImageFile}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {coverList.length > 0
                                                ? "更改图片"
                                                : "上传图片"}
                                        </Button>
                                    </Upload>
                                </ImgCrop>

                            </div>
                        </Form.Item>
                        <Form.Item
                            name="introduction"
                            label="项目介绍"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入项目介绍'
                                },
                                {
                                    validator: async (rule, value) => {
                                        if (value === "") {
                                            throw "请输入项目介绍";
                                        } else if (value === "<p><br></p>") {
                                            throw "请输入项目介绍";
                                        }
                                    },
                                },
                            ]}
                        >
                            <ReactQuill
                                ref={refs}
                                modules={modules}
                                theme="snow"
                                value={descriptions}
                                onChange={onDescriptionsChange}
                                className="ql-editor react-editor"
                            />
                        </Form.Item>
                        <Form.Item label="项目负责人" name="projectOwner">
                            <Select placeholder='请选择项目负责人'
                                    showSearch
                                    allowClear
                                    optionFilterProp={'children'}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={dictionaryList}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item label="合作企业" name="enterprise">
                            <Select placeholder='请选择合作企业'
                                    showSearch
                                    allowClear
                                    optionFilterProp={'children'}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={enterpriseList}
                            >
                            </Select>
                        </Form.Item>

                        <div className="form-subtitle">项目配置</div>
                        <Form.Item
                            label="项目关联疾病配置"
                            rules={[
                                {required: true, message: '请选择关联疾病'}
                            ]}
                        >
                            <TreeSelect
                                showSearch
                                placeholder='请选择关联疾病'
                                allowClear
                                multiple
                                treeNodeFilterProp="title"
                                value={selectValues}
                                onChange={handleChangeDisease}
                                treeData={diseaseList}
                            />
                        </Form.Item>
                        <Form.Item
                            label="医生关键信息展示"
                            name="doctorKeyShow"
                            rules={[
                                {required: true, message: ''}
                            ]}
                            initialValue={1}
                            help="用于控制用户端页面医生信息的展示，开启后展示:医生姓名、职称、所属单位、科室，关闭后展示:医生姓名、科室 "
                        >
                            <Radio.Group>
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="审核专家信息展示"
                            name="kolKeyShow"
                            rules={[
                                {required: true, message: ''}
                            ]}
                            initialValue={1}
                            help="用于控制问答详情页是否展示审核人信息 "
                        >
                            <Radio.Group>
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <div className="form-subtitle">分享配置</div>
                        <Form.Item
                            name="questionUrl"
                            label="项目问答首页"
                            rules={[
                                {required: false, message: ''}
                            ]}
                        >
                            <Space>
                                <Input placeholder="请输入链接" value={codeHome} allowClear autoComplete="off" onChange={handleChangeHome} style={{width: '500px'}} />
                                <span style={{cursor: 'pointer'}} onClick={() => copyUrl('questionUrl')}>复制链接</span>
                                <QRCode
                                    style={{display: 'none'}}
                                    id={`qrcode_conf_questionUrl`}
                                    size={300}
                                    value={codeHome}
                                />
                                <a
                                    id={`link_qrcode_questionUrl`}
                                    download={'项目问答首页'}
                                    onClick={() => downloadQRCode('questionUrl')}>
                                    下载二维码
                                </a>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            name="auditUrl"
                            label="专家审核页面"
                            rules={[
                                {required: false, message: ''}
                            ]}
                        >
                            <Space>
                                <Input placeholder="请输入链接" value={codeAudit} allowClear autoComplete="off" onChange={handleChangeAuditUrl} style={{width: '500px'}} />
                                <span style={{cursor: 'pointer'}} onClick={() => copyUrl('auditUrl')}>复制链接</span>
                                <QRCode
                                    style={{display: 'none'}}
                                    id={`qrcode_conf_auditUrl`}
                                    size={300}
                                    value={codeAudit}
                                />
                                <a
                                    id={`link_qrcode_auditUrl`}
                                    download={'专家审核页面'}
                                    onClick={() => downloadQRCode('auditUrl')}>
                                    下载二维码
                                </a>
                            </Space>
                        </Form.Item>

                        <div className="form-subtitle">微信客服</div>
                        <Form.Item
                            name="wechatOssKey"
                            label="微信二维码"
                            rules={[
                                { required: true, message: '请上传微信二维码' }
                            ]}
                            help="支持JPG/PNG格式，尺寸300×300px，大小不超过2M"
                        >

                            {wxQrcodeUrlList && wxQrcodeUrlList.length > 0 && (
                                <div style={{marginBottom: 8,width: 200,height: 200}}>
                                    <AntdImage src={wxQrcodeUrlList[0]}/>
                                    <span onClick={closeImg} className="close-img"
                                          style={ {position: 'absolute',top: 0,left:180,cursor: 'pointer',backgroundColor:'rgba(0, 0, 0, 0.6)',
                                              color: '#FFFFFF',borderRadius:'50%',width: 20,height:20,textAlign:'center'} }
                                    ><CloseOutlined /></span>
                                </div>
                            )}

                            <Upload
                                beforeUpload={file => checkImgUploadFile(file, 2)}
                                fileList={[]}
                                customRequest={fileInfo => uploadImageFile(fileInfo)}
                            >
                                <Button
                                    icon={<span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                                        </svg>
                                    </span>}
                                >
                                    {wxQrcodeUrlList && wxQrcodeUrlList.length > 0 ? '更改图片' : '上传图片'}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            {...pageFormItemWithoutLabelGridLayout}
                            style={{paddingTop: 24, marginBottom: 0}}
                        >
                            <Space size="large">
                                <Button type="primary" size="large" htmlType="submit">提交</Button>
                                <Button type="default" size="large" onClick={onReset}>取消</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </Content>


        </Fragment>
    );
}

