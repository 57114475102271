import React, { Fragment, useState, useEffect,useRef,useMemo } from "react";
import { Input, Upload,message ,Form} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { timesaveTemplateQuestionApi } from "@services/Wj/Template";
import { timesaveSurveryQuestionApi } from "@services/Wj/Question";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import EditTable from './components/EditTable'
const Matrix = ({ questionId, item, setAnserArea, anserArea,onReset}) => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [questionIndex, setQuestionIndex] = useState();
    const [titleBorder, setTitleBorder] = useState(false);
    const [titleIn,setTitleIn]= useState(false)
    const [colValue, setColValue] = useState([]);
    const [rowValue, setRowValue] = useState([]);
    const location = useLocation();
    const quillRef=useRef(null)
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
        let c=document.querySelectorAll('.ql-color')
        ;
        setTimeout(() => {
            c.forEach(item=>{
                item.onmousedown=(e)=>{
                    e.preventDefault();
            }
            })
        }, 1000);


    }, []);

    const changeTitle = (e,i) => {
        let a=document.querySelectorAll('.ql-container')
       a[questionIndex].removeAttribute('style')
        let b=document.querySelectorAll('.ql-toolbar')
        b[questionIndex].removeAttribute('style')
        item.body = quillRef.current.value;
        setTitleBorder(false);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: quillRef.current.value,
        };
        console.log(quillRef.current.value);
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){
                message.success('保存成功')
            }}))
        }
        // setAnserArea(resArr);
    };
    // const

    const modules = useMemo(() => {
        return {
          toolbar: {
            container: [
              ["bold",  "underline", {
                color: [],
              },],
            ],

          },
        };
      }, []);
    const getBorder = (index) => {
        let a=document.querySelectorAll('.ql-container')
        a[index].style.border='1px solid #1a73e8'
        let b=document.querySelectorAll('.ql-toolbar')
        b[index].style.display='block'
        setTitleBorder(true);
        setTitleIn(true)
    };
    return (
        <Fragment>
            <div name={questionId}>
                <div className="question-title">
                    <div className="question-id">
                        <span
                            className="required"
                            style={{
                                display: item.required === 1 ? "" : "none",
                            }}
                        >
                            *
                        </span>
                        <b className="question-index">{questionIndex}.</b>
                    </div>
                    <ReactQuill theme="snow" ref={quillRef}
                    modules={modules}
                   placeholder="请输入问题"
                   className="question-title-area"
                    onFocus={()=>getBorder(questionIndex)}
                    defaultValue={item.body}
                        onBlur={()=>changeTitle(questionIndex)}
                        />
                </div>
                <Form form={form}>
                <EditTable defaultValue={item?.options[0]} arrForm={form} colValue={colValue} setColValue={setColValue} rowValue={rowValue} setRowValue={setRowValue} setAnserArea={setAnserArea} anserArea={anserArea} qData={item} onReset={onReset}/>
                </Form>
            </div>
        </Fragment>
    );
};

export default Matrix;
