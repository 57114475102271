
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    LeftOutlined,
    DeleteOutlined,
    FormOutlined,
    DragOutlined
} from '@ant-design/icons';
import {
    Layout,
    Alert,
    Space,
    Button,
    Tooltip,
    Modal,
    Switch,
    message, Divider
} from 'antd';
import config from '@config';
import { isEmpty } from 'lodash';
import './index.less';
import AddQuickEntriesDrawer from './components/AddQuickEntriesDrawer';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { quickEntriesListApi, quickEntriesStatusApi, quickEntriesSortApi, quickEntriesDeleteApi, showMedProject } from '@services/MedProject';


const { Content } = Layout;

export default function RelatedProjectList() {
    const navigate = useNavigate();
    const params = useParams();

    const projectId = params?.id || 0; // 项目 ID

    // 快捷入口列表数据
    const [quickEntriesList, setQuickEntriesList] = useState([]);
    // 新增快捷入口 Drawer
    const [visibleAddQuickEntriesDrawer, setVisibleAddQuickEntriesDrawer] = useState(false);
    // 当前选中的快捷入口
    const [currentQuickEntries, setCurrentQuickEntries] = useState(false);

    const [project, setProject] = useState(null);

    const getProject = async id => {
        const res = await showMedProject(id);
        if (res.status_code === 200) {
            const project = res.data;
            setProject(project);
        }
    };

    useEffect(() => {
        if (!!projectId) {
            getProject(projectId)
        }
    }, []);

    // 快捷入口刪除
    const deleteQuickEntries = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认删除当前快捷入口',
            cancelText: '取消',
            okText: '确认',
            closable: true,
            onOk: async () => {
                const res = await quickEntriesDeleteApi({ id });
                if (res.status_code === 200) {
                    message.success('删除成功');
                    getQuickEntries();
                }
            }
        });
    };

    // 显示/隐藏
    const quickEntriesSwitchChange = async (checked, id) => {
        // console.log(checked, id);
        let reqParams = {
            hidden: checked ? 0 : 1,
            id: id
        }
        const res = await quickEntriesStatusApi(reqParams);
        if (res.status_code === 200) {
            // console.log(res.data);
            getQuickEntries();
        }
    };

    const showAddQuickEntriesDrawer = ()=>{
        setCurrentQuickEntries(null);
        setVisibleAddQuickEntriesDrawer(true);
    };

    const showEditQuickEntriesDrawer = module => {
        if (!isEmpty(module)) {
            console.log(module);
            setCurrentQuickEntries(module);

            setVisibleAddQuickEntriesDrawer(true);
        }
    };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return (<>
            <Tooltip title="快捷入口排序">
                <Button type="text" size="small"><DragOutlined /></Button>
            </Tooltip>
        </>);
    });

    const SortableMainElement = SortableElement(({ module, sort }) => {
        return <>
            <div className="main-table-tr main-quick-tr" key={module.id}>
                <div className="main-table-title">
                    <span dangerouslySetInnerHTML={{__html: module.title}}></span>
                </div>
                <div className="main-table-blank">
                    <span>{module.newBlankType === 2 ? '文件' : '链接'}</span>
                </div>

                <div className="main-table-switch">
                    <Switch
                        checked={!module.hidden}
                        onChange={(checked) => quickEntriesSwitchChange(checked, module.id)}
                        checkedChildren="显示"
                        unCheckedChildren="隐藏"
                    />
                </div>

                <div className="main-table-operations">
                    <span className="operation-btns">
                        <Button size="small" type="link" onClick={() => showEditQuickEntriesDrawer(module)}>编辑</Button>
                        <Divider type="vertical" />
                        <Button size="small" type="link" onClick={() => deleteQuickEntries(module.id)}>删除</Button>
                    </span>

                    <SortableMainHandle></SortableMainHandle>
                </div>

            </div>
        </>
    });

    const SortableMainContainer = SortableContainer(({ items }) => {
        return <div className="main-table-body">
            {items.map((module, index) => {
                return (
                    <SortableMainElement
                        module={module}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    // 快捷入口排序
    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        let newData = arrayMoveImmutable(quickEntriesList, oldIndex, newIndex);
        setQuickEntriesList(newData);

        let ids = newData.map(item => item.id);
        quickEntriesSortApi(ids);
    };

    // 获取项目的快捷入口
    const getQuickEntries = async () => {
        const res = await quickEntriesListApi({projectId});
        if (res.status_code === 200) {
            setQuickEntriesList(res.data);
        }
    };

    useEffect(() => {
        if(!!projectId){
            getQuickEntries();
        }
    }, [projectId]);

    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined/></Link>
                <span className="current-title">快捷入口</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">

                <div className="main-content-body quick-enter-body">

                    <div className="main-tools">
                        <Button type="primary" onClick={() => showAddQuickEntriesDrawer()}>添加快捷入口</Button>
                    </div>

                    <Alert
                        style={{ marginBottom: 26, marginTop: 16 }}
                        type="warning"
                        message="该处功能类似金刚位，可以添加如联系我们，加入社群等快捷入口，该处添加的内容，将展示在主页右侧靠上位置"
                    />

                    <div className="main-table">
                        <div className="main-table-head">
                            <span className="main-table-name">快捷入口名称</span>
                            <span className="main-table-blank">入口跳转类型</span>
                            <span className="main-table-onoff">显示/隐藏</span>
                            <span className="main-table-setting">操作</span>
                        </div>
                        <SortableMainContainer
                            useWindowAsScrollContainer={true}
                            useDragHandle
                            distance={10}
                            items={quickEntriesList}
                            onSortEnd={onSortEndHandler}>
                        </SortableMainContainer>
                    </div>

                </div>

            </Content>

            <AddQuickEntriesDrawer
                visibleAddQuickEntriesDrawer={visibleAddQuickEntriesDrawer}
                setVisibleAddQuickEntriesDrawer={setVisibleAddQuickEntriesDrawer}
                currentQuickEntries={currentQuickEntries}
                getQuickEntries={getQuickEntries}
            />

        </>
    );
}
