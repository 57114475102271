import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    Table,
    Modal,
    Typography,
} from 'antd';
import { laborFeeListApi } from '@services/SereviseConst';
import { laborFeeCostBearers, laborFeePaymentMethods, laborFee3rdPartyPaymentMethods } from '@utils/constants';
import './PaymentRecordModal.less';
import moment from 'moment';

const { Text } = Typography;

// 付款请款审批记录
const PaymentRecordModal = ({ openPaymentRecord, setOpenPaymentRecord, paymentRecordList }) => {
    const params = useParams();

    const conferenceId = params?.id || 0; // 会议项目 ID

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const handleCancel = () => {
        setOpenPaymentRecord(false);
    };

    const formatCurrency = value => {
        let result = '';

        const f = parseFloat(value) || 0.00;
        const parts = f.toFixed(2).split('.');
        result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? '.' + parts[1] : '');

        return result;
    };

    // 获取专家证件类型
    const getIdcardType = (type) => {
        if (type === 2) return '港澳居民来往内地通行证';
        if (type === 3) return '台湾居民来往大陆通行证';
        if (type === 4) return '外国人永久居留身份证';
        if (type === 5) return '护照';
        return null;
    };

    let columns = [
        { title: '专家姓名', width: 100, dataIndex: 'memUsername', fixed: 'left' },
        { title: '手机号', dataIndex: 'memMobile', width: 120, },
        {
            title: '单位任职',
            dataIndex: 'unitPost',
            width: 180,
            // align: 'center',
            render: (_, row) => {
                if (row.workExpList && row.workExpList.length) {
                    return <>
                        {row.workExpList.map((item, idx) => {
                            return <div key={`w_${item.id}`}>
                                {row.workExpList.length > 1 && <span>{idx + 1}. </span>}
                                {!!item.unitName && <span>{item.unitName}</span>}
                                {!!item.unitOffice && <span>{item.unitOffice}</span>}
                                {!!item.unitPost && <span>{item.unitPost}</span>}
                            </div>
                        })}
                    </>
                } else {
                    return '—';
                }
            },
        },
        {
            title: '税后劳务费/元',
            dataIndex: 'laborFeeAmount',
            width: 130,
            align: 'right',
            render: (_, record) => record.laborFeeAmount !== null
                ? <span>&yen;{`${formatCurrency(record.laborFeeAmount)}`}</span>
                : <Text type="secondary">&ndash;</Text>
        },
        {
            title: '付款方式',
            dataIndex: 'paymentMethod',
            width: 150,
            render: (_, record) => {
                if (record.paymentMethod !== record.paymentMethodAdjust) {
                    const paymentMethodName = laborFeePaymentMethods.find(m => m.value === record.paymentMethod)?.label || <Text type="secondary">&ndash;</Text>;
                    const paymentMethodAdjustName = laborFeePaymentMethods.find(m => m.value === record.paymentMethodAdjust)?.label || <>&ndash;</>;
                    return <>
                        {paymentMethodName} <Text type="danger"> 变更后 {paymentMethodAdjustName}</Text>
                    </>
                } else {
                    return laborFeePaymentMethods.find(m => m.value === record.paymentMethod)?.label || <Text type="secondary">&ndash;</Text>
                }
            }
        },
        {
            title: '费用承担主体',
            dataIndex: 'costBearer',
            width: 150,
            render: (_, record) => {
                if (record.costBearer !== record.costBearerAdjust) {
                    const costBearerName = laborFeeCostBearers.find(m => m.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>;
                    const costBearerAdjustName = laborFeeCostBearers.find(m => m.value === record.costBearerAdjust)?.label || <>&ndash;</>;
                    return <>
                        {costBearerName} <Text type="danger"> 变更后 {costBearerAdjustName}</Text>
                    </>
                } else {
                    return laborFeeCostBearers.find(b => b.value === record.costBearer)?.label || <Text type="secondary">&ndash;</Text>
                }
            }
        },
        {
            title: '个人所得税/元',
            dataIndex: 'individualTaxAmount',
            width: 150,
            render: (_, record) => {
                if (!!record.laborFeeAmount && !!record.paymentMethod) {
                    if (record.individualTaxAmount !== record.individualTaxAmountAdjust) {
                        const individualTaxAmount = !!record.individualTaxAmount
                            ? <Text type="secondary">&yen;{`${formatCurrency(record.individualTaxAmount)}`}</Text>
                            : <Text type="secondary">&yen;0.00</Text>
                        const individualTaxAmountAdjust = !!record.individualTaxAmountAdjust
                            ? <>&yen;{`${formatCurrency(record.individualTaxAmountAdjust)}`}</>
                            : <>&yen;0.00</>
                        return <>{individualTaxAmount} <Text type="danger"> 变更后 {individualTaxAmountAdjust}</Text></>
                    }
                    return !!record.individualTaxAmount
                        ? <span>&yen;{`${formatCurrency(record.individualTaxAmount)}`}</span>
                        : <Text type="danger"> &yen;0.00</Text>
                }
                return <Text type="secondary">&ndash;</Text>
            }
        },
        {
            title: '含税劳务费/元',
            dataIndex: 'sumOfLaborFeeCast',
            width: 150,
            render: (_, record) => {
                if (!!record.laborFeeAmount || !!record.individualTaxAmountAdjust) {
                    let laborFeeAmount = parseFloat(record.laborFeeAmount) || 0.00;
                    let individualTaxAmount = parseFloat(record.individualTaxAmount) || 0.00;
                    let individualTaxAmountAdjust = parseFloat(record.individualTaxAmountAdjust) || 0.00;

                    return <>&yen;{`${formatCurrency(laborFeeAmount + individualTaxAmount)}`} <Text type="danger"> 变更后 &yen;{`${formatCurrency(laborFeeAmount + individualTaxAmountAdjust)}`} </Text></>;

                }
                return <Text type="secondary">&ndash;</Text>;
            }
        },
        {
            title: '劳务信息',
            dataIndex: 'bankName',
            width: 250,
            render: (_, record) => (<>
                {
                    (record.idcardType === 1 || !record.idcardType) && <>
                        <span>身份证号码：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br />
                        <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                    </>
                }
                {record.idcardType > 1 && <>
                    <span>出生日期：{(!!record.birthday && moment(record.birthday).format('YYYY-MM-DD')) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>出生地：{record.birthPlace || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>性别：{(record.memGender !== null && (Number(record.memGender) === 1 ? '男' : '女') ) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>证件类型：{getIdcardType(record.idcardType) || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>证件号：{record.idCard || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>银行账号：{record.bankCardNo || <Text type="secondary">&ndash;</Text>}</span><br/>
                    <span>开户银行：{record.bankName || <Text type="secondary">&ndash;</Text>}</span>
                </>
                }
            </>),
        },
        {
            title: '修改人',
            dataIndex: 'modifiedName',
            width: 250,
        },
        {
            title: '修改时间',
            dataIndex: 'createdAt',
            width: 250,
            render: (_, record) => (<>
                {record.createdAt || <Text type="secondary">&ndash;</Text>}
            </>),
        },
    ];

    return (
        <>
            <Modal
                title='付款信息修改历史记录'
                visible={openPaymentRecord}
                centered
                onCancel={handleCancel}
                className="payment-record-modal"
                footer={null}
            >
                <Table
                    size="small"
                    columns={columns}
                    dataSource={paymentRecordList}
                    loading={loading}
                    scroll={{ x: 1500 }}
                    pagination={false}
                />
            </Modal>
        </>
    );
}

export default PaymentRecordModal;
