import React, { useState } from 'react';
import { filterFormItemLayout, filterFormGridLayout } from '@utils/property';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, DatePicker, Button, Space } from 'antd';
const { RangePicker } = DatePicker;

export default function InteractionChatFilterForm({
    handleFilterFinish
}) {
    const [form] = Form.useForm();

    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };

    const onFinish = (values) => {
        values.start_time = timePicker[0];
        values.end_time = timePicker[1];
        handleFilterFinish(values)
    };

    const onReset = () => {
        form.resetFields();
        let values = {
            start_time: undefined,
            end_time: undefined
        };
        handleFilterFinish(values);
    };
    return (
        <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
            <Row>

                <Col {...filterFormGridLayout}>
                    <Form.Item label="参加时间" name="timePicker">
                        <RangePicker
                            allowClear
                            onChange={handleChangeTime}
                            showTime />
                    </Form.Item>
                </Col>

                <Col className="filter-form-buttons" {...filterFormGridLayout}>
                    <Space>
                        <Button type="default" onClick={onReset}>重置</Button>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                    </Space>
                </Col>

            </Row>
        </Form>
    );
}
