import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Space, message, Image, Upload, Switch, Tooltip, Input } from 'antd';
import { drawerWidth, drawerFormItemLayout } from '@utils/property';
import { fileApi } from '@services/Conference';
import { updateAgendaSettings } from '@services/Agenda';
import './index.less';
import config from '@config';
import { prepareBCOSSUrl } from '@utils/utils';

const colorList = [
    '#f5222d',
    '#fa541c',
    '#fa8c16',
    '#52c41a',
    '#1677ff',
    '#0327ff',
    '#722ed1',
    '#eb2f96',
    '#333333',
    '#888888',
    '#ffffff',
].map(c => c.toUpperCase());
console.log(colorList);

// 日程模板设置
const AddAgendaDrawer = ({
    visibleAgendaTemplateDrawer,
    setVisibleAgendaTemplateDrawer,
    conference,
    getConferenceInfo
}) => {
    const optionHeaders = { headers: { "Content-Type": "multipart/form-data" } };

    const params = useParams();
    const conferenceId = params?.id || 0; // 会议项目 ID

    const [form] = Form.useForm();

    const isAllowCheck = useRef(null);
    const [agendaLoading, setAgendaLoading] = useState(false); // 新增/编辑日程loading
    const [headerImgList, setHeaderImgList] = useState([]);
    const [qrcodeImg, setQrcodeImg] = useState([]);
    const [printHeaderImgList, setPrintHeaderImgList] = useState([]);

    const closeAgendaTemplateDrawer = () => {
        setVisibleAgendaTemplateDrawer(false);
        form.resetFields();
        setHeaderImgList([]);
        setPrintHeaderImgList([]);
    };

    //上传头像
    const checkout = (file, size) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error('仅支持上传JPG/PNG格式的图片。');
            isAllowCheck.current = true;
            return false;
        } else {
            isAllowCheck.current = false;
        }

        // 判断大小
        const isLt5M = file.size / 1024 / 1024 < size;
        if (!isLt5M) {
            message.error(`图片不能大于${size}MB。`);
            isAllowCheck.current = true;
            return false;
        } else {
            isAllowCheck.current = false;
        }
        return isJpgOrPng && isLt5M
            ;
    };

    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 2 * 1024 * 1024) {
            message.error('图片大小不能超过2MB。');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    resolve(true);
                };
            };
            reader.readAsDataURL(file);
        });
    };

    const printImageUpload = (info, type) => {
        const formData = new FormData();
        formData.append("file", info.file);
        formData.append('type', 14);
        if (!isAllowCheck.current) {
            fileApi(formData, optionHeaders).then((res) => {
                if (!res) {
                    message.error("上传文件失败！！");
                    return false;
                }
                if (res.status_code === 200) {
                    if (type === 'head_img_url') {
                        setPrintHeaderImgList([getFileUrl(res.data)]);
                        form.setFields([{ name: ['print', 'head_img_url'], errors: null }]);
                    }
                    form.setFieldValue(['print', type], getFileUrl(res.data));
                }
            });
        } else {
            form.setFieldValue(['print', type], undefined);
        }
    };

    const imageUpload = (info) => {
        const formData = new FormData();
        formData.append("file", info.file);
        formData.append('type', 14);
        if (!isAllowCheck.current) {
            fileApi(formData, optionHeaders).then((res) => {
                if (!res) {
                    message.error("上传文件失败！");
                    return false;
                }
                if (res.status_code === 200) {
                    setHeaderImgList([getFileUrl(res.data)]);
                    form.setFieldValue(['web', 'head_img_url'], getFileUrl(res.data));
                    form.setFields([{ name: ['web', 'head_img_url'], errors: null }]);
                }
            });
        } else {
            form.setFieldValue(['web', 'head_img_url'], undefined);
        }
    };

    const qrcodeUpload = (info) => {
        const formData = new FormData();

        formData.append("file", info.file);
        formData.append('type', 14);

        if (!isAllowCheck.current) {
            fileApi(formData, optionHeaders)
                .then((res) => {
                    if (!res) {
                        message.error("上传文件失败！");
                        return false;
                    }
                    if (res.status_code === 200) {
                        setQrcodeImg([prepareBCOSSUrl(res.data)]);
                        form.setFieldValue(['web', 'qrcode_img_url'], prepareBCOSSUrl(res.data));
                    }
                });
        } else {
            form.setFieldValue(['web', 'qrcode_img_url'], undefined);
        }
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    // 背景色
    const changeBgColor = (item, fieldName) => {
        // console.log(fieldName)
        form.setFieldValue([...fieldName], item);
    };

    // 提交日程模板，新增/编辑
    const submitAgenda = () => {
        form.validateFields().then(async (values) => {
            // console.log(values)
            const res = await updateAgendaSettings({
                id: conferenceId,
                agendaSettings: JSON.stringify(values),
            });
            if (res.status_code === 200) {
                message.success('模板设置成功');
                setVisibleAgendaTemplateDrawer(false);
                getConferenceInfo(conferenceId);
            }
        }).catch((err) => {
            console.log('提交失败', err);
        });
    };

    useEffect(() => {
        if (conference?.agendaSettings) {
            const agendaSettings = JSON.parse(conference.agendaSettings)
            form.setFieldsValue(agendaSettings);
            if (agendaSettings?.web?.head_img_url) {
                setHeaderImgList([agendaSettings.web.head_img_url]);
            }
            if (agendaSettings?.web?.qrcode_img_url) {
                setQrcodeImg(([agendaSettings.web.qrcode_img_url]));
            }
            if (agendaSettings?.print?.head_img_url) {
                setPrintHeaderImgList([agendaSettings.print.head_img_url]);
            }
        }
    }, [visibleAgendaTemplateDrawer, conference]);

    return (
        <>
            <Drawer
                title="日程模板设置"
                height="100%"
                width={drawerWidth}
                layout="horizontal"
                open={visibleAgendaTemplateDrawer}
                onClose={closeAgendaTemplateDrawer}
                footer={
                    <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={closeAgendaTemplateDrawer}>取消</Button>
                            <Button type="primary" loading={agendaLoading} onClick={submitAgenda}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <div className="agenda-template-form">
                    <Form
                        {...drawerFormItemLayout}
                        name="basic"
                        layout="vertical"
                        form={form}
                        initialValues={{
                            web: {
                                background_color: '#1677FF',
                                conf_hall_title_color: '#1677FF',
                                agenda_task_color: '#1677FF',
                            },
                            print: {
                                conf_hall_title_background_color: '#1677FF',
                                conf_hall_title_secondary_background_color: '#1677FF',
                                new_page_per_conf_hall: false
                            }
                        }}
                        autoComplete="off"
                    >
                        <div>
                            <h3>H5分享版日程模板设置</h3>
                            <p>日程预览，按以下模板样式设置展示日程，并且可以生成分享版日程图片。</p>
                        </div>

                        <Form.Item name="web">
                            <Form.Item
                                name={['web', 'head_img_url']}
                                label="头图"
                                rules={[
                                    { required: true, message: '请上传头图' },
                                ]}
                                extra="图片尺寸宽1500px高不小于1000px，支持JPG/PNG格式，大小不超过2MB"
                                className="share-image"
                            >
                                {headerImgList.length > 0 && <Image width={375} src={headerImgList[0]} className="share-image-area" />}

                                <div>
                                    <Upload
                                        beforeUpload={(file) => checkout(file, 5)}
                                        fileList={headerImgList}
                                        customRequest={imageUpload}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {headerImgList.length > 0 ? '更改图片' : '上传图片'}
                                        </Button>
                                    </Upload>
                                </div>
                            </Form.Item>

                            <Form.Item
                                className="required"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                                label="背景色"
                                extra="背景色需要和背景图配合（融合），用于背景图高度不够时填补背景"
                            >
                                <div className="color-field-wrap">
                                    <div className="color-field">
                                        <Form.Item noStyle shouldUpdate>
                                            {({ getFieldValue }) => {
                                                const color = getFieldValue(['web', 'background_color']);
                                                return !!color && (
                                                    <>
                                                        <div className="color-previewer" style={{ backgroundColor: `${color}`, borderColor: '#CCCCCC' }} />
                                                    </>
                                                );
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            noStyle
                                            name={['web', 'background_color']}
                                            rules={[
                                                { required: true, message: '背景色不能为空' },
                                                { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                            ]}
                                        >
                                            <Input style={{ width: 110, border: 'none', boxShadow: 'none' }} maxLength={7} />
                                        </Form.Item>
                                    </div>
                                    <div className="color-list">
                                        {
                                            colorList.map((item, index) => (
                                                <Tooltip placement="top" title='点击使用该颜色'>
                                                    <div
                                                        key={index}
                                                        onClick={() => changeBgColor(item, ['web', 'background_color'])}
                                                        className="color-previewer"
                                                        style={{ backgroundColor: `${item}`, borderColor: '#CCCCCC' }}
                                                    ></div>
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                className="required"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                                label="论坛名称文字颜色"
                                extra="该颜色用于会议论坛名称的展示"
                            >
                                <div className="color-field-wrap">
                                    <div className="color-field">
                                        <Form.Item noStyle shouldUpdate>
                                            {({ getFieldValue }) => {
                                                const color = getFieldValue(['web', 'conf_hall_title_color']);
                                                return !!color && (
                                                    <>
                                                        <div className="color-previewer" style={{ backgroundColor: `${color}`, borderColor: '#CCCCCC' }} />
                                                    </>
                                                );
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            noStyle
                                            name={['web', 'conf_hall_title_color']}
                                            rules={[
                                                { required: true, message: '论坛名称文字颜色不能为空' },
                                                { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                            ]}
                                        >
                                            <Input style={{ width: 110, border: 'none', boxShadow: 'none' }} maxLength={7} />
                                        </Form.Item>
                                    </div>
                                    <div className="color-list">
                                        {
                                            colorList.map((item, index) => (
                                                <Tooltip placement="top" title='点击使用该颜色'>
                                                    <div
                                                        key={index}
                                                        onClick={() => changeBgColor(item, ['web', 'conf_hall_title_color'])}
                                                        className="color-previewer"
                                                        style={{ backgroundColor: `${item}`, borderColor: '#CCCCCC' }}
                                                    />
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                className="required"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                                label="日程任务文字颜色"
                                extra="该颜色用于日程任务的展示"
                            >
                                <div className="color-field-wrap">
                                    <div className="color-field">
                                        <Form.Item noStyle shouldUpdate>
                                            {({ getFieldValue }) => {
                                                const color = getFieldValue(['web', 'agenda_task_color']);
                                                return !!color && (
                                                    <>
                                                        <div className="color-previewer" style={{ backgroundColor: `${color}`, borderColor: '#CCCCCC' }} />
                                                    </>
                                                );
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            noStyle
                                            name={['web', 'agenda_task_color']}
                                            rules={[
                                                { required: true, message: '日程任务文字颜色不能为空' },
                                                { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                            ]}
                                        >
                                            <Input style={{ width: 110, border: 'none', boxShadow: 'none' }} maxLength={7} />
                                        </Form.Item>
                                    </div>
                                    <div className="color-list">
                                        {
                                            colorList.map((item, index) => (
                                                <Tooltip placement="top" title='点击使用该颜色'>
                                                    <div key={index} onClick={() => changeBgColor(item, ['web', 'agenda_task_color'])} className="color-previewer" style={{ backgroundColor: `${item}`, borderColor: '#CCCCCC' }}></div>
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name={['web', 'qrcode_img_url']}
                                label="社群二维码"
                                extra={<span>图片尺寸300&times;300px，支持JPG/PNG格式，大小不超过1MB</span>}
                                className="share-image"
                            >
                                {qrcodeImg.length > 0 && <Image width={150} height={150} src={qrcodeImg[0]} className="share-image-area" />}

                                <div>
                                    <Upload
                                        beforeUpload={(file) => checkout(file, 1)}
                                        fileList={qrcodeImg}
                                        customRequest={qrcodeUpload}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {qrcodeImg.length > 0 ? '更改图片' : '上传图片'}
                                        </Button>
                                    </Upload>
                                </div>
                            </Form.Item>

                            <Form.Item name={['web', 'qrcode_text_line1']}>
                                <Input placeholder="第1行社群二维码文案" maxLength={12} allowClear showCount />
                            </Form.Item>

                            <Form.Item name={['web', 'qrcode_text_line2']}>
                                <Input placeholder="第2行社群二维码文案" maxLength={12} allowClear showCount />
                            </Form.Item>

                        </Form.Item>

                        <div>
                            <h3>A4打印版日程模板设置</h3>
                            <p>按以下模板样式设置，生成A4打印版日程。</p>
                        </div>
                        <Form.Item name="print">
                            <Form.Item
                                className="required"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                                label="论坛标题背景色"
                                extra="该颜色用于打印版日程中会议论坛名称背景颜色"
                            >
                                <div className="color-field-wrap">
                                    <div className="color-field">
                                        <Form.Item noStyle shouldUpdate>
                                            {({ getFieldValue }) => {
                                                const color = getFieldValue(['print', "conf_hall_title_background_color"]);
                                                return !!color && (
                                                    <>
                                                        <div className="color-previewer" style={{ backgroundColor: `${color}`, borderColor: '#CCCCCC' }}></div>
                                                    </>
                                                );
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            noStyle
                                            name={['print', 'conf_hall_title_background_color']}
                                            rules={[
                                                { required: true, message: '论坛名称背景色不能为空' },
                                                { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                            ]}
                                        >
                                            <Input style={{ width: 110, border: 'none', boxShadow: 'none' }} maxLength={7} />
                                        </Form.Item>
                                    </div>
                                    <div className="color-list">
                                        {
                                            colorList.map((item, index) => (
                                                <Tooltip placement="top" title='点击使用该颜色'>
                                                    <div key={index} onClick={() => changeBgColor(item, ['print', 'conf_hall_title_background_color'])} className="color-previewer" style={{ backgroundColor: `${item}`, borderColor: '#CCCCCC' }}></div>
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                className="required"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                                label="论坛次标题背景色"
                                extra="该颜色用于打印版日程中会议论坛中次标题背景颜色"
                            >
                                <div className="color-field-wrap">
                                    <div className="color-field">
                                        <Form.Item noStyle shouldUpdate>
                                            {({ getFieldValue }) => {
                                                const color = getFieldValue(['print', "conf_hall_title_secondary_background_color"]);
                                                return !!color && (
                                                    <>
                                                        <div className="color-previewer" style={{ backgroundColor: `${color}`, borderColor: '#CCCCCC' }}></div>
                                                    </>
                                                );
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            noStyle
                                            name={['print', 'conf_hall_title_secondary_background_color']}
                                            rules={[
                                                { required: true, message: '论坛名称背景色不能为空' },
                                                { pattern: /^#[0-9a-f]{6}$/i, message: '色值不符合格式要求' },
                                            ]}
                                        >
                                            <Input style={{ width: 110, border: 'none', boxShadow: 'none' }} maxLength={7} />
                                        </Form.Item>
                                    </div>
                                    <div className="color-list">
                                        {
                                            colorList.map((item, index) => (
                                                <Tooltip placement="top" title='点击使用该颜色'>
                                                    <div key={index} onClick={() => changeBgColor(item, ['print', 'conf_hall_title_secondary_background_color'])} className="color-previewer" style={{ backgroundColor: `${item}`, borderColor: '#CCCCCC' }}></div>
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Form.Item>

                            {/*<Form.Item*/}
                            {/*    name={['print', "head_img_url"]}*/}
                            {/*    label="页眉图片"*/}
                            {/*    extra="页眉图片宽210mm高297mm，300PPI，支持JPG/PNG格式，大小不超过5MB"*/}
                            {/*    className="share-image"*/}
                            {/*>*/}
                            {/*    {printHeaderImgList.length > 0 && <Image width={200} src={printHeaderImgList[0]} className="share-image-area" />}*/}

                            {/*    <div>*/}
                            {/*        <Upload*/}
                            {/*            beforeUpload={(file) =>*/}
                            {/*                checkout(file, 5)*/}
                            {/*            }*/}
                            {/*            fileList={printHeaderImgList}*/}
                            {/*            customRequest={(e) => printImageUpload(e, 'head_img_url')}*/}
                            {/*            maxCount={1}*/}
                            {/*        >*/}
                            {/*            <Button icon={<UploadOutlined />}>*/}
                            {/*                {headerImgList.length > 0*/}
                            {/*                    ? "更改图片"*/}
                            {/*                    : "上传图片"}*/}
                            {/*            </Button>*/}
                            {/*        </Upload>*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}

                            <Form.Item
                                label="每个论坛新起一页"
                                name={['print', "new_page_per_conf_hall"]}
                                valuePropName="checked"
                                extra="开启后每个论坛新页输出，关闭并跨页输出，单论坛日程任务多时建议开启"
                            >
                                <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
                            </Form.Item>
                        </Form.Item>

                    </Form>
                </div>
            </Drawer>
        </>
    );
};
export default AddAgendaDrawer;
