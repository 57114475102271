import React, { useImperativeHandle, forwardRef, useEffect, Fragment, useState } from 'react';
import { drawerWidth } from '@utils/property';
import { Space, Button, Drawer, message, Tree } from 'antd';
import { patch } from '@/core/request';
import { getParentIdList } from '@utils/utils';

function PermissionSort({ getTreeData }, ref) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [title,setTitle] = useState('')
    let clonetreeData = []

    useImperativeHandle(ref, () => ({
        show(elem) {
            setIsModalVisible(true);
            setTitle(elem.moduleName)
            let treeData = elem.children.map(item => {
                return {
                    ...item,
                    parent: elem.moduleId,
                    children: item.secondGroupList && item.secondGroupList.map(element => {
                        return {
                            ...element,
                            title: element.groupName,
                            key: element.groupId,
                            parent: item.groupName
                        }
                    })
                }
            })
            setTreeData(treeData);
            clonetreeData = elem.children.concat()
        }
    }));
    const onDrop = info => {
        let dropPosArr = info.node.pos.split('-');

        let dragPosArr = info.dragNode.pos.split('-');

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPosition = info.dropPosition - Number(dropPosArr[dropPosArr.length - 1]);

        let firstBool = info.dragNode.parent === info.node.parent;
        // let secondBool = !info.dropToGap && typeof info.node.parent === 'string'
        if (!firstBool) {
            // console.log(info.dragNode.parent, info.node.parent, info.dropToGap);
            if (!info.dropToGap) {
                if (info.dragNode.parent === info.node.title) {
                    // 可以移动
                }else{
                    return message.error('排序只改变同级分类之间的顺序，不改变从属关系。')
                }
            }else{
                if(info.dragNode.parent!==info.node.parent){
                    return message.error('排序只改变同级分类之间的顺序，不改变从属关系。')
                }

            }
        }

        let bool = (dropPosition === 0 && dropPosArr.length + 1 === dragPosArr.length);

        if (dropPosArr.length !== dragPosArr.length) {
            if (bool) { }
            else {
                return message.error("列表禁止跨级拖拽");
            }
        }

        // info.dropToGap 代表移动到非最顶级组第一个位置

        if (!info.dropToGap) {
            if (bool) { }
            else {
                return message.error("同级列表只能互换顺序");
            }
        }

        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...treeData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }
        setTreeData(data)
    };

    // 遍历得到所有的id
    const getAllNodeId = (expandedKeys, moduleDataList) => {
        moduleDataList.forEach(item => {
            expandedKeys.push(item.groupId)
            if (item.children) {
                expandedKeys = getAllNodeId(expandedKeys, item.children)
            }
        })
        return expandedKeys
    }

    const handleClose = () => {
        setTreeData(clonetreeData);
        setIsModalVisible(false);
    };

    const handleOk = () => {
        // console.log(treeData);
        // 传给后端的ids
        let ids = getAllNodeId([], treeData);
        patch(`/enterprise/groups/edit-sort`, { ids }).then(res => {
            if (res.status_code === 200) {
                message.success(res.message);
                getTreeData();
                setIsModalVisible(false);
            }
        })
    };
    return (
        <Fragment>
            {
                isModalVisible && <Drawer title='权限排序' width={drawerWidth}
                    visible={isModalVisible} bodyStyle={{ paddingBottom: 80 }} onClose={handleClose}
                    footer={
                        <div style={{ textAlign: "right" }}>
                            <Space>
                                <Button onClick={handleClose}>取消</Button>
                                <Button type="primary" onClick={handleOk}>
                                    确定
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <div className='tree-sort-title'>为<span>{title}</span>模块下分组进行排序</div>
                    <div className="drawerTip">
                        <p>拖动排序后，保存。</p>
                        <p>注意：排序只改变同级分类之间的顺序，不改变从属关系。</p>
                    </div>
                    <Tree
                        defaultExpandAll
                        className="draggable-tree"
                        draggable
                        blockNode
                        onDrop={onDrop}
                        treeData={treeData}
                    />
                </Drawer>
            }
        </Fragment>
    )
}

export default forwardRef(PermissionSort)
