import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { SearchOutlined, ReloadOutlined, AudioOutlined, DownloadOutlined } from '@ant-design/icons';
import { Layout, Form, Row, Col, Input, Button, Space, Select, Menu, Dropdown, Table, message, Modal, TreeSelect, Drawer } from 'antd';
import { filterFormItemLayout, filterFormGridLayout, pageFormItemWithoutLabelGridLayout, drawerWidth } from '@utils/property';
import './Channel.less';
import { fullPagerConfig } from '@/utils/property';
import QRCode from 'qrcode.react';
import { get, post, put } from '@/core/request';
const { Content } = Layout;


export default function OperationChannel() {
    const { Search } = Input;
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [isShowEdit, setIsShowEdit] = useState(false);
    const [isShowNewChannel, setIsShowNewChannel] = useState(false);
    const [valueTreeSelect, setValueTreeSelect] = useState(undefined);
    const [treeData, setTreeData] = useState(null);
    const [treeDataDisabled, setTreeDataDisabled] = useState(null);
    const [list, setList] = useState(null);
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [formUrl] = Form.useForm();
    const [isShowCode, setIsShowCode] = useState(false);
    const [codeValue, setCodeValue] = useState("");
    let [ulrList, setUrlList] = useState([
        "https://www.cn-healthcare.com/z/speciallist/auth/auth.html",
        "https://ucenter.cn-healthcare.com/rlogin/tologin?redirectUrl=https://www.cn-healthcare.com/",
        "https://www.cn-healthcare.com/z/membership_center/pc/buy_vip.html"
    ])
    const [title, setTitle] = useState("");
    const [id, setId] = useState("");
    const [channelType, setChannelType] = useState("");
    const [status, setStatus] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [channelInformation, setChannelInformation] = useState("");
    let [num, setNum] = useState(0);
    const [recordId, setRecordId] = useState(null);
    const [dataList, setDataList] = useState(null);
    const [createdByStaffId, setCreatedByStaffId] = useState("");
    useEffect(() => {
        get(`/channel/channel/page?status=${status}&id=${id}&title=${title}&channelType=${channelType}&page=${page}&limit=${limit}&createdByStaffId=${createdByStaffId}`).then((res) => {
            setList(res.data)
        }, (err) => {
            console.log(err)
        })
    }, [page, limit, num])
    // 检索
    const onFinish = (values) => {
        setTitle(values.title || "")
        setId(values.id || "")
        setChannelType(values.channelType || "")
        setStatus(values.status == undefined ? "" : values.status)
        setCreatedByStaffId(values.createdByStaffId || "")
        setPage(1)
        setNum(++num)
    };
    // 编辑渠道分类默认值
    const [valueSelectEdit, setValueSelectEdit] = useState(undefined);
    // 新增渠道分类默认值
    const [valueSelectChannel, setValueSelectChannel] = useState(undefined);

    const onChangeTreeSelectEdit = (value) => {
        setValueSelectEdit(value)
    }
    const onChangeTreeSelectChannel = (value) => {
        setValueSelectChannel(value)
    }
    useEffect(() => {
        get('/channel/channel-group/listTree').then((res) => {
            if (res.status_code == 200) {
                setTreeData(deepClone(res.data))
            }
        }, (err) => {
            console.log(err)
        })
        get('/channel/user/findAll').then((res) => {
            if (res.status_code == 200) {
                setDataList(res.data)
            }
        }, (err) => {
            console.log(err)
        })
    }, [])
    function deepCloneDisabled(result) {
        if (result == null) {
            return
        }
        for (let i = 0; i < result.length; i++) {
            result[i].title = result[i].name
            result[i].value = result[i].id
            result[i].disabled = true
            if (result[i].children.length) {
                result[i].children.forEach(item => {
                    item.title = item.name
                    item.value = item.id
                    item.disabled = true
                    if (item.children.length) {
                        for (let m = 0; m < item.children.length; m++) {
                            item.children[m].title = item.children[m].name
                            item.children[m].value = item.children[m].id
                        }
                    }
                })
            }
        }
        return result
    }
    function deepClone(result) {
        if (result == null) {
            return
        }
        if (result && result.length) {
            return result.map(item => {
                item.title = item.name
                item.value = item.id
                if (item.children.length) {
                    deepClone(item.children)
                }
                return item
            })
        }
    }
    useEffect(() => {
        get('/channel/channel-group/listTree').then((res) => {
            if (res.status_code == 200) {
                const result1 = deepCloneDisabled(res.data);
                setTreeDataDisabled(result1)
            }
        }, (err) => {
            console.log(err)
        })
    }, [])
    const onChangeTreeSelect = value => {
        setValueTreeSelect(value);
    };

    const handleCancelEdit = () => {
        setIsShowEdit(false);
        setValueSelectEdit(undefined)
        form2.resetFields()
    };
    //新增渠道取消按钮
    const handleCancelChannel = () => {
        setIsShowNewChannel(false);
        setValueSelectChannel(undefined)
        form3.resetFields();
    };
    //新增渠道完成按钮
    const onFinishChannel = (values) => {
        post("/channel/channel/save", values).then((res) => {
            if (res.status_code == 200) {
                setIsShowNewChannel(false);
                form3.resetFields();
                setNum(++num)
            }
        })
    }
    // 新增渠道按钮
    const newChannels = () => {
        setIsShowNewChannel(true);
    }
    // 编辑按钮
    const handleEditClick = (record) => {
        setRecordId(record.id)
        setIsShowEdit(true);
        form2.setFieldsValue({ position2: record.position2, title: record.title })
    }

    // 编辑确定按钮
    const onFinishEdit = (values) => {
        put("/channel/channel/edit-info", {
            id: recordId,
            title: values.title,
            position2: values.position2
        }).then(res => {
            if (res.status_code == 200) {
                form2.resetFields();
                setNum(++num);
                setIsShowEdit(false);
            }
        })
    };
    const handleMenuClick = (record, key) => {
        if (key.key == 1) {
            setChannelInformation(record)
            setIsShowDrawer(true)
        } else {
            Modal.confirm({
                title: '禁用渠道',
                content: '禁用渠道后，推广效果将不再收集统计，确定禁用？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    put(`/channel/channel/edit-status`, {
                        id: record.id,
                        deleteStatus: 1
                    }).then(res => {
                        if (res.status_code == 200) {
                            message.info(res.message)
                            setNum(++num)
                        }
                    })
                }
            });
        }
    }
    const menu = (record) => {
        return (
            <Menu onClick={handleMenuClick.bind(this, record)}>
                <Menu.Item key="1">
                    生成推广链接
                </Menu.Item>
                <Menu.Item key="2">
                    禁用渠道
                </Menu.Item>
            </Menu>
        );
    }
    const handleMenuClick2 = (record) => {
        put(`/channel/channel/edit-status`, {
            id: record.id,
            deleteStatus: 0
        }).then(res => {
            if (res.status_code == 200) {
                message.info(res.message)
                setNum(++num)
            }
        })
    }
    const menu2 = (record) => {
        return (
            <Menu onClick={handleMenuClick2.bind(this, record)}>
                <Menu.Item key="2">
                    启用渠道
                </Menu.Item>
            </Menu>
        );
    }
    const columns = [
        { title: '渠道ID', dataIndex: 'id', key: 'id' },
        { title: '渠道名称', dataIndex: 'title', },
        {
            title: '渠道分类', dataIndex: 'deleteStatus',
            render: (_, record) =>
            (
                <div className='channelBox'>
                    <p style={{ color: "#666" }}>{record.typeName} / {record.position1Name} / {record.position2Name}</p>
                </div>
            ),
        },
        {
            title: '创建人', dataIndex: 'deleteStatus',
            render: (_, record) =>
            (
                <div>
                    <p>{record.createdByStaffName}</p>
                    <p>{record.createTime}</p>
                </div>
            ),
        },
        {
            title: '状态', dataIndex: 'type',
            render: (_, record) =>
            (
                <div>
                    {record.deleteStatus === 0 ? <p className='enable'>启用</p> : <p className='disable'>禁用</p>}
                </div>
            ),
        },
        {
            title: '操作', dataIndex: 'operation',
            render: (_, record) =>
            (
                <div className="personBox">
                    {record.deleteStatus === 0 ? <Dropdown.Button onClick={handleEditClick.bind(this, record)} overlay={menu.bind(this, record)} size="small">
                        编辑
                    </Dropdown.Button> : <Dropdown.Button onClick={handleEditClick.bind(this, record)} overlay={menu2.bind(this, record)} size="small">
                        编辑
                    </Dropdown.Button>}
                </div>
            ),
        },
    ];
    const onReset = () => {
        form1.resetFields();
        setPage(1)
        setTitle("")
        setId("")
        setChannelType("")
        setStatus("")
        setCreatedByStaffId("")
        setNum(++num)
    };
    const refresh = () => {
        changeCurrPage(1)
    }
    const changeCurrPage = useCallback(
        (page) => {
            setPage(page);
        },
        [],
    );
    const changePageSize = useCallback(
        (pageSize) => {
            setLimit(pageSize);
        },
        [],
    );
    const paginationProps = {
        ...fullPagerConfig,
        current: page,
        // showTotal: () => `共${list.totalCount}条记录`,
        // showSizeChanger: true,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100', '1000'],
        size: 'small',
        total: list && list.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) }
    };
    const onCloseDrawer = () => {
        setIsShowDrawer(false)
        setIsShowCode(false)
    }
    const onFinishUrl = (values) => {
        let str = /^https?:\/\/.+?\.cn\-healthcare\.com/i
        if (str.test(values.url)) {
            if (values.url.includes("?")) {
                setCodeValue(`${values.url}&chid=${channelInformation.id}`)
            } else {
                setCodeValue(`${values.url}?chid=${channelInformation.id}`)
            }
            setIsShowCode(true)
        } else {
            setIsShowCode(false)
            message.info("链接验校失败，目前只支持 cn-healthcare.com 域名链接")
        }
    }
    const changeInput = () => {
        setIsShowCode(false)
    }
    const addUrl = (i) => {
        let value = ulrList[i]
        formUrl.setFieldsValue({ url: value });
        setIsShowCode(false)
    }
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const onSearch = value => {
        var input = document.createElement('input');
        input.setAttribute('readonly', 'readonly');
        input.setAttribute('value', value);
        document.body.appendChild(input);
        input.select();//选中文本
        document.execCommand('copy');
        document.body.removeChild(input);
        message.info("复制成功");
    }
    const clickDownLoad = () => {
        var Qr = document.getElementById('qrid');
        let image = new Image();
        image.src = Qr.toDataURL("image/png");
        var a_link = document.getElementById('aId');
        a_link.href = image.src;
    }
    return (
        <Fragment>
            <Content className="main-content-box">
                <div className="main-content-title">渠道管理</div>
                <div className="main-content-body">
                    <Form {...filterFormItemLayout} onFinish={onFinish} form={form1}>
                        <Row>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="关键字" name="title">
                                    <Input allowClear placeholder="渠道名称关键字" />
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="渠道ID" name="id">
                                    <Input allowClear placeholder="渠道ID" />
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="渠道分类" name="channelType">
                                    {treeData && <TreeSelect
                                        style={{ width: '100%' }}
                                        value={valueTreeSelect}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={treeData}
                                        placeholder="选择渠道分类"
                                        treeDefaultExpandAll={true}
                                        onChange={onChangeTreeSelect}
                                    />}
                                </Form.Item>
                            </Col>

                            <Col {...filterFormGridLayout}>
                                <Form.Item label="状态" name="status">
                                    <Select allowClear placeholder="选择渠道状态" >
                                        <Select.Option value={0}>启用</Select.Option>
                                        <Select.Option value={1}>禁用</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...filterFormGridLayout}>
                                <Form.Item label="创建人" name="createdByStaffId">
                                    <Select allowClear placeholder="选择创建人" >
                                        {dataList && dataList.map(item=>{
                                            return <Select.Option value={item.phone}>{item.name}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col className="filter-form-buttons" {...filterFormGridLayout}>
                                <Space>
                                    <Button type="default" onClick={onReset}>重置</Button>
                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit">检索</Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                    <div className="table-caption">
                        <div className="table-title">渠道列表</div>
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={newChannels}>新增渠道</Button>
                                <Button type="default" icon={<ReloadOutlined />} onClick={refresh}>刷新</Button>
                            </Space>
                        </div>
                    </div>
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={list && list.list}
                        pagination={paginationProps}
                    />
                </div>
                {isShowEdit && <Modal title="编辑渠道" visible={isShowEdit} onCancel={handleCancelEdit} footer={null}>
                    <Form {...filterFormItemLayout} onFinish={onFinishEdit} form={form2}>
                        <Form.Item label="渠道名称"
                            name="title"
                            rules={[{ required: true, message: '输入渠道名称' }]}>
                            <Input allowClear placeholder="输入渠道名称" />
                        </Form.Item>

                        <Form.Item label="渠道分类" name="position2" rules={[{ required: true, message: '选择所属渠道分类' }]}>
                            {treeDataDisabled && <TreeSelect
                                style={{ width: '100%' }}
                                value={valueSelectEdit}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={treeDataDisabled}
                                placeholder="选择渠道分类"
                                treeDefaultExpandAll={true}
                                onChange={onChangeTreeSelectEdit}
                            />}
                        </Form.Item>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button onClick={handleCancelEdit}>取消</Button>
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>}
                {isShowNewChannel && <Modal title="新增渠道" visible={isShowNewChannel} onCancel={handleCancelChannel} footer={null}>
                    <Form {...filterFormItemLayout} onFinish={onFinishChannel} form={form3}>
                        <Form.Item label="渠道名称"
                            name="title"
                            rules={[{ required: true, message: '输入渠道名称' }]}>
                            <Input allowClear placeholder="输入渠道名称" />
                        </Form.Item>

                        <Form.Item label="渠道分类" rules={[{ required: true, message: '选择所属渠道分类' }]} name="position2">
                            {treeDataDisabled && <TreeSelect
                                style={{ width: '100%' }}
                                value={valueSelectChannel}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={treeDataDisabled}
                                placeholder="选择渠道分类"
                                treeDefaultExpandAll={true}
                                onChange={onChangeTreeSelectChannel}
                            />}
                        </Form.Item>
                        <Form.Item {...pageFormItemWithoutLabelGridLayout}>
                            <Space>
                                <Button onClick={handleCancelChannel}>取消</Button>
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>}
                {isShowDrawer && <Drawer title="生成渠道链接" placement="right" onClose={onCloseDrawer} visible={isShowDrawer} width={drawerWidth}>
                    <Form form={formUrl} onFinish={onFinishUrl} {...filterFormItemLayout}>
                        <Form.Item label="渠道信息" {...filterFormGridLayout}>
                            {channelInformation.title} [#{channelInformation.id}]
                        </Form.Item>
                        <Form.Item label="常用链接" {...filterFormGridLayout}>
                            <a onClick={addUrl.bind(this, 0)}>认证链接</a> <a onClick={addUrl.bind(this, 1)}>注册链接</a> <a onClick={addUrl.bind(this, 2)}>会员开通链接</a>
                        </Form.Item>                        <Form.Item label="原始链接" name="url" rules={[{ required: true, message: '原始链接不能为空' }]} {...filterFormGridLayout}>
                            <Input allowClear placeholder="请输入要推广的原始链接" onChange={changeInput} />
                        </Form.Item>
                        <Form.Item {...filterFormGridLayout} label="" >
                            {/* <Space> */}
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 150 }}>
                                生成渠道链接
                            </Button>
                            {/* </Space> */}
                        </Form.Item>
                    </Form>
                    {isShowCode && <Form {...filterFormItemLayout}>
                        <Form.Item label="渠道链接" {...filterFormGridLayout}>
                            <div>
                                <Input allowClear value={codeValue} disabled style={{ width: "80%" }} />
                                <span className='copyElement' onClick={onSearch.bind(this, codeValue)}>复制</span>
                            </div>

                        </Form.Item>
                        <Form.Item label="渠道二维码" {...filterFormGridLayout}>
                            <QRCode
                                id='qrid'
                                value={codeValue}   //value参数为生成二维码的链接
                                size={300} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                                bgColor="#fff"
                                includeMargin={true}
                            />
                            <div>
                                <a download id='aId'>
                                    <Button onClick={clickDownLoad}><DownloadOutlined />
                                        下载
                                    </Button>
                                </a>
                            </div>
                        </Form.Item>
                    </Form>}
                </Drawer>}
            </Content>

        </Fragment>
    );
}
