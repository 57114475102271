import moment from "moment";

export const option = { headers: { "Content-Type": "multipart/form-data" } };

export const keys = ["reggedUserTicket", "A01", "A06", "A02", "A04", "A03", "0", "1", "2", "3"];

export const handleVoteSettingDefaultValue = (params) => {
    if (!params || typeof params !== "object")
        return {
            rule: 0,
            reggedUserTicket: 1,
            reggedUserTicketCount: 1,
            A01: 1,
            A01Count: 15,
            A06: 1,
            A06Count: 1,
            A02: 1,
            A02Count: 1,
            A04: 1,
            A04Count: 1,
            A03: 1,
            A03Count: 1,
            0: 1,
            "0Count": 1,
            1: 1,
            "1Count": 1,
            2: 1,
            "2Count": 50,
            3: 1,
            "3Count": 1,
            visibleVoteButton: 1,
            visibleVoteNum: 1,
            visibleRank: 1,
            rankCaseNum: 15,
            isAuth: 1,
        };

    const state = { ...params };
    params?.voteTicketCountSettings?.authedUser.forEach((i) => {
        state[i.mem_identity] = !i.count ? 0 : 1;
        if (i.mem_identity === "A01") {
            state[i.mem_identity + "Count"] = !i.count ? 15 : i.count;
        } else {
            state[i.mem_identity + "Count"] = !i.count ? 1 : i.count;
        }
    });
    params?.voteTicketCountSettings?.authedOrg.forEach((i) => {
        state[i.org_type] = !i.count ? 0 : 1;
        if (i.org_type === 2) {
            state[i.org_type + "Count"] = !i.count ? 50 : i.count;
        } else {
            state[i.org_type + "Count"] = !i.count ? 1 : i.count;
        }
    });
    return {
        time: [moment(params.startedAt), moment(params.endedAt)],
        rule: params.notification?.rule, // 投票提醒 0不提醒，1每次进入提醒，2仅提醒一次（Cookie记录）
        message: params.notification?.message,
        visibleVote: params.visibleVote, // 是否开启投票：0不开启，1开启
        visibleVoteButton: params.visibleVoteButton, // 是否展示投票按钮：0不展示，1展示
        visibleVoteNum: params.visibleVoteNum, // 是否展示案例票数：0不展示，1展示
        visibleRank: params.visibleRank, // 是否展示投票排行榜：0不展示，1展示
        rankCaseNum: params.rankCaseNum, // 投票排行案例个数
        isAuth: params.isAuth, // 投票完整榜单查看限制
        reggedUserTicket: !params.voteTicketCountSettings?.reggedUserTicketCount ? 0 : 1,
        reggedUserTicketCount: params.voteTicketCountSettings.reggedUserTicketCount, // 注册用户投票数
        ...state,
    };
};

export const handleForm2Params = (value) => {
    const keysObj = {};
    keys.forEach((k) => (keysObj[k] = value[k] ? value[k + "Count"] : value[k]));

    return {
        startedAt: value.time[0].format("YYYY-MM-DD HH:mm"),
        endedAt: value.time[1].format("YYYY-MM-DD HH:mm"),
        notification: { rule: value.rule, message: value.message }, // 投票提醒 0不提醒，1每次进入提醒，2仅提醒一次（Cookie记录）
        voteTicketCountSettings: {
            // 不同身份用户每次投票计数配置（投票一次计几票）
            reggedUserTicketCount: keysObj.reggedUserTicket, // 注册用户投票计数，0为不可投票，n为投票一次计n票，下同

            // （个人）职务认证（mem_identity）
            authedUser: [
                { mem_identity: "A01", count: keysObj["A01"] }, // 医疗机构从业者
                { mem_identity: "A06", count: keysObj["A06"] }, // 社会团队人士
                { mem_identity: "A02", count: keysObj["A02"] }, // 政府官员
                { mem_identity: "A04", count: keysObj["A04"] }, // 教育科研人士
                { mem_identity: "A03", count: keysObj["A03"] }, // 商业人士
            ],

            // 机构认证（sso_member.org_type）
            authedOrg: [
                { org_type: 2, count: keysObj[2] }, // 医疗机构
                { org_type: 1, count: keysObj[1] }, // 媒体
                { org_type: 0, count: keysObj[0] }, // 企业
                { org_type: 3, count: keysObj[3] }, // 机构
            ],
        },
        visibleVoteButton: value.visibleVoteButton, // 是否展示投票按钮：0不展示，1展示
        visibleVoteNum: value.visibleVoteNum, // 是否展示案例票数：0不展示，1展示
        visibleRank: value.visibleRank, // 是否展示投票排行榜：0不展示，1展示
        rankCaseNum: value.rankCaseNum, // 投票排行案例个数
        isAuth: value.isAuth, // 投票完整榜单查看限制
    };
};
