import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button, Layout, Card, Space, Form, Input, Skeleton, Tooltip, Row, Col } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LeftOutlined, PartitionOutlined, SkinOutlined, SettingOutlined, AppstoreOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "./index.less";
import Topic from "./Topic";
import Facade from "./Facade";
import Logic from "./Logic";
import Setting from "./Setting";
import Whitelists from "./Setting/Whitelists";
import FinalCustom from "./Setting/FinalCustom";
import { previewTemplateApi } from "@services/Wj/Template";
import { releaseSurveryApi } from "@services/Wj/Question";

const Editsurvey = () => {
    const { Sider } = Layout;
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);
    const { pathname } = location;
    const [topic, setTopic] = useState(true);
    const [logic, setLogic] = useState(false);
    const [setting, setSetting] = useState(false);
    const [facade, setFacade] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [anserArea, setAnserArea] = useState([]);
    const [preview, setPreview] = useState(undefined);
    const formRef = useRef(null);
    const logicRef = useRef(null);
    const settingRef = useRef(null);
    const facadeRef = useRef(null);
    const whitelistsRef = useRef(null);
    useEffect(() => {
        switch (pathname) {
            case "/wj/surveys/new/create":
                setTopic(true);
                setLogic(false);
                setSetting(false);
                setFacade(false);
                break;
            case `/wj/surveys/edit/${location.state.id}`:
                setTopic(true);
                setLogic(false);
                setSetting(false);
                setFacade(false);
                break;

            case "/wj/surveys/new/logic":
                setTopic(false);
                setLogic(true);
                setSetting(false);
                setFacade(false);
                break;
            case "/wj/surveys/new/setting":
                setTopic(false);
                setLogic(false);
                setSetting(true);
                setFacade(false);
                break;
            case "/wj/surveys/new/whitelists":
                setTopic(false);
                setLogic(false);
                setSetting(true);
                setFacade(false);
                break;
            case "/wj/surveys/new/whitelists":
                setTopic(false);
                setLogic(false);
                setSetting(true);
                setFacade(false);
                break;
            case "/wj/surveys/new/facade":
                setTopic(false);
                setLogic(false);
                setSetting(false);
                setFacade(true);
                break;
            default:
                break;
        }
    }, [pathname]);
    const onTopic = () => {
        if (location.state?.action === "edit") {
            navigate(`/wj/surveys/edit/${location.state.id}`, { state: location.state });
        } else {
            navigate("/wj/surveys/new/create", { state: location.state });
        }
    };
    const onLogic = () => {
        navigate("/wj/surveys/new/logic", { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
    };
    const onSetting = () => {
        navigate("/wj/surveys/new/setting", { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
    };
    const onfacade = () => {
        navigate("/wj/surveys/new/facade", { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
    };
    const goBack = () => {
        if (location.pathname.includes("/wj/surveys/edit") && location.state.route !== "newCreate") {
            navigate("/wj/surveys");
        } else if (location.state.route === "newList" || location.state.route === "newTemplate") {
            navigate("/wj/surveys");
        } else if (location.state.route === "newCreate") {
            navigate("/wj/templates");
        }
    };
    const getQuestion = () => {
        releaseSurveryApi({ id: location.state.id, status: 1 });
        if (location.pathname.includes("new/whitelists")) {
            navigate(`/wj/surveys/${location.state.id}/share`, { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
        } else if (location.pathname.includes("new/setting")) {
            navigate(`/wj/surveys/${location.state.id}/share`, { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
        } else if (location.pathname.includes("new/facade")) {
            navigate(`/wj/surveys/${location.state.id}/share`, { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
        } else {
            navigate(`/wj/surveys/${location.state.id}/share`, { state: { ...location.state, title: formRef.current?.surveyTitle || location.state.title } });
        }
    };
    const onPreview = () => {
        navigate(`/wj/surveys/${location.state.id}/preview`, { state: { ...location.state, title: formRef.current?.surveyTitle } });
    };

    const saveTemplate = () => {
        navigate("/wj/templates");
    };

    useEffect(() => {
        if (location.state?.route === "newCreate") {
            previewTemplateApi({ id: location.state.id }).then((res) => {
                if (res.status_code === 200) {
                    console.log(res.data.name);
                    setTemplateName(res.data.name);
                }
            });
        }
    }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (anserArea && anserArea.length) {
    //             console.log(anserArea);
    //             let a=document.querySelectorAll('.ql-container')
    //             console.log(a)
    //             if(a.length){
    //                 a.forEach(item=>{
    //                     // console.log(item);
    //                     item.addEventListener('mouseenter', (e) => {
    //                        item.style.border='1px dashed #1a73e8'
    //                        })
    //                 })
    //             }
    //         }
    //     }, 1000);

    // }, [anserArea]);

    const allDoc = (e) => {
        if (formRef.current) {
            formRef.current.allDoc(e);
        }
    };

    return (
        <div onClick={(e) => allDoc(e)} onMouseDown={(e) => e.stopPropagation()}>
            {(location.pathname.includes("/wj/surveys/new") || location.pathname.includes("/wj/surveys/edit")) && (
                <>
                    <div className="page_title_wrap">
                        <div className="page-left">
                            <div className="back">
                                <LeftOutlined onClick={goBack} />
                            </div>
                            <span className="survey-title">{templateName}</span>
                        </div>
                        {location.state?.route === "newCreate" ? (
                            <div className="page-center"></div>
                        ) : (
                            <div className="page-center">
                                <span style={{ color: "#1890ff" }}>编辑</span>
                                <ArrowRightOutlined className="process" style={{ color: "#1890ff" }} />
                                <span>分享</span>
                            </div>
                        )}

                        <div className="page-right">
                            <Button type="primary" onClick={onPreview} ghost>
                                预览
                            </Button>
                            {location.state?.route === "newCreate" ? (
                                <Button type="primary" onClick={saveTemplate}>
                                    保存
                                </Button>
                            ) : (
                                <Button type="primary" onClick={getQuestion}>
                                    发布
                                </Button>
                            )}
                        </div>
                    </div>
                    <Layout className="create-box">
                        <Sider className="survey-action" width={70}>
                            <div className="action-area">
                                <div className="action-item" onClick={onTopic} style={topic ? { color: "#1a73e8" } : {}}>
                                    <AppstoreOutlined />
                                    <p>题型</p>
                                </div>
                                {location.state.route !== "newCreate" && (
                                    <div className="action-item" onClick={onLogic} style={logic ? { color: "#1a73e8" } : {}}>
                                        <PartitionOutlined />
                                        <p>逻辑</p>
                                    </div>
                                )}
                                <div className="action-item" onClick={onSetting} style={setting ? { color: "#1a73e8" } : {}}>
                                    <SettingOutlined />
                                    <p>设置</p>
                                </div>
                                <div className="action-item" onClick={onfacade} style={facade ? { color: "#1a73e8" } : {}}>
                                    <SkinOutlined />
                                    <p>外观</p>
                                </div>
                            </div>
                        </Sider>
                        {location.pathname.includes("new/create") && <Topic anserArea={anserArea} setAnserArea={setAnserArea} ref={formRef} preview={preview} />}
                        {location.pathname.includes("edit") && <Topic anserArea={anserArea} setAnserArea={setAnserArea} ref={formRef} preview={preview} />}
                        {location.pathname.includes("new/logic") && <Logic />}
                        {location.pathname.includes("new/whitelists") && <Whitelists />}
                        {location.pathname.includes("new/FinalCustom") && <FinalCustom />}
                        {location.pathname.includes("new/setting") && <Setting />}
                        {location.pathname.includes("new/facade") && <Facade />}
                    </Layout>
                </>
            )}
        </div>
    );
};

export default Editsurvey;
