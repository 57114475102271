import { createSlice } from '@reduxjs/toolkit';

const commonState = {
    filter: {
        page: 1,
        pageSize: 20,
    },
    cursor: 0,
};

const initialState = {
    project: { // 会议项目列表
        ...commonState,
        filter: {
            ...commonState.filter,
            isTesting: 0,
        }
    },

    guest: { // 嘉宾列表
        ...commonState
    },

    guestJourney: { // 嘉宾行程列表
        ...commonState
    },

    laborFee: {
        ...commonState
    }
};

const conferenceSlice = createSlice({
    name: 'conference',
    initialState,
    reducers: {
        setProjectFilter: (state, action) => void(state.project.filter = action.payload),
        setProjectCursor: (state, action) => void(state.project.cursor = action.payload),

        setGuestFilter: (state, action) => void(state.guest.filter = action.payload),
        setGuestCursor: (state, action) => void(state.guest.cursor = action.payload),

        setGuestJourneyFilter: (state, action) => void(state.guestJourney.filter = action.payload),
        setGuestJourneyCursor: (state, action) => void(state.guestJourney.cursor = action.payload),

        setLaborFeeFilter: (state, action) => void(state.laborFee.filter = action.payload),
        setLaborFeeCursor: (state, action) => void(state.laborFee.cursor = action.payload),
    }
});

export const conferenceActions = conferenceSlice.actions;

export default conferenceSlice;
