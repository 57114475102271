import axios from 'axios'
import { message } from 'antd';
import { localStorageGet } from './cookie.js';
import config from "../config";

const baseUrl = process.env.NODE_ENV === "development" ? "" : config.MC_API_PREFIX;

const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
    timeout: 2 * 60 * 1000,
    baseURL: baseUrl,
});

// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/json'

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
        if (config.method === 'delete') {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        let configBool = config.url === '/enterprise/staffs/login' || config.url === '/enterprise/staffs/send-sms';
        if (!configBool) {
            let token;
            token = localStorageGet('token');
            config.headers.Authorization = token;
            if (!token) {
                window.location.href = '/login';
            }
        }
        //将token放到请求头发给服务器
        return config;
    }
)

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
        if (!response.config.url.includes('download')) {
            if (response.data.success) {
                return Promise.resolve(response.data);
            }else {
                message.error(response.data.errMessage);
                return Promise.reject(response.data.errMessage);
            }
        } else {
            return Promise.resolve(response.data)
        }
    },
    error => {
        message.error('请求超时, 请刷新重试');
        return Promise.reject('请求超时, 请刷新重试');
    }
);

export const get = async(url, params, options) => {
    let urlStr = url + "?";
    for (let i in params) {
        if (params[i]) {
            urlStr += `${i}=${params[i]}&`
        }
    }
    urlStr = urlStr.slice(0, urlStr.length - 1);
    try {
        return await instance.get(urlStr, options);
    } catch (err) {
        return err;
    }
};

export const post = async(url, data, options) => {
    try {
        return await instance.post(url, data, options);
    } catch (err) {
        return err;
    }
};

export const put = async(url, data, options) => {
    try {
        return await instance.put(url, data, options);
    } catch (err) {
        return err;
    }
};

export const patch = async(url, data, options) => {
    try {
        return await instance.patch(url, data, options);
    } catch (err) {
        return err;
    }
};

export const delte = async(url, data, options) => {
    try {
        return await instance.delete(url, data, options)
    } catch (err) {
        return err;
    }
}

