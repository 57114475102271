import React, { useState, useEffect, useRef } from 'react';
import { getProjestsFiledsListApi } from "@services/Selection";
import { Checkbox, InputNumber, Button, Input, Modal, Form, Switch, Row, Col, Space } from 'antd';
import './FieldsList.less';
import { getTopicsSelectedInfoApi } from "@services/Selection";
import { useCallback } from 'react';
import { useForm } from 'antd/lib/form/Form';

const secondField = [
    { field_name: 'name', field_display_name: '姓名' },
    { field_name: 'phone', field_display_name: '手机号' },
    { field_name: 'email', field_display_name: '电子邮箱' },
    { field_name: 'department', field_display_name: '科室部门' },
    { field_name: 'post', field_display_name: '职务' },
    { field_name: 'title', field_display_name: '职称' }
]
const personSecondField = [
    { field_name: 'phone', field_display_name: '手机号' },
    { field_name: 'email', field_display_name: '电子邮箱' },
    { field_name: 'department', field_display_name: '科室部门' },
    { field_name: 'post', field_display_name: '职务' },
    { field_name: 'title', field_display_name: '职称' }
]

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 }
};


const FieldsList = ({ topicId, allFields, setAllFields, fieldsSelect, setFieldsSelect }) => {
    const [fieles, setFieles] = useState([]);
    const [fieldDisplayModal, setFieldDisplayModal] = useState(false);
    const [fieldDisplay] = Form.useForm();
    const currentField = useRef();  // 当前编辑的自定义说明 - 字段
    const [currentFieldData, setCurrentFieldData] = useState(false); // 当前编辑 - 字段

    const getFiled = (e, item) => {
        if (item.cateId === 1) {
            let firstFieles = [...fieles]
            let changeIndex = fieles[0].findIndex(it => it.id === item.id)
            firstFieles[0][changeIndex].required = e.target.checked ? true : '';
            firstFieles[0][changeIndex].isDefault = e.target.checked ? 2 : 0;
            setFieles([...firstFieles])
        } else if (item.cateId === 2) {
            let firstFieles = [...fieles]
            let changeIndex = fieles[1].findIndex(it => it.id === item.id)
            firstFieles[1][changeIndex].required = e.target.checked ? true : '';
            firstFieles[1][changeIndex].isDefault = e.target.checked ? 2 : 0;
            setFieles([...firstFieles])
        } else if (item.cateId === 3) {
            let firstFieles = [...fieles]
            let changeIndex = fieles[2].findIndex(it => it.id === item.id)
            firstFieles[2][changeIndex].required = e.target.checked ? true : '';
            firstFieles[2][changeIndex].isDefault = e.target.checked ? 2 : 0;
            setFieles([...firstFieles])
        }
        let allSelect = [...fieldsSelect]
        let all = [...allFields];
        let index = allSelect.findIndex(it => (it.value === e.target.value))
        if (index > -1) {
            allSelect = allSelect.filter(it => it.value !== e.target.value)
            all = all.filter(its => its.field_name !== e.target.value)
            setFieldsSelect([...allSelect]);
            setAllFields([...all]);
        } else {
            allSelect.push({ value: e.target.value, name: e.target.checkname })
            all.push({ field_name: e.target.value, required: true, pid: item.cateId })
            setFieldsSelect([...allSelect])
            setAllFields([...all]);
        }

    };

    const getItem = (e, item) => {
        if (item.cateId === 1) {
            let firstFieles = [...fieles]
            let changeIndex = fieles[0].findIndex(it => it.id === item.id)
            firstFieles[0][changeIndex].required = e ? 1 : 0;
            setFieles([...firstFieles])
        } else if (item.cateId === 2) {
            let firstFieles = [...fieles]
            let changeIndex = fieles[1].findIndex(it => it.id === item.id)
            firstFieles[1][changeIndex].required = e ? 1 : 0;
            setFieles([...firstFieles])
        } else if (item.cateId === 3) {
            let firstFieles = [...fieles]
            let changeIndex = fieles[2].findIndex(it => it.id === item.id)
            firstFieles[2][changeIndex].required = e ? 1 : 0;
            setFieles([...firstFieles])
        }
        let all = [...allFields];
        // if(fieldsSelect.findIndex(it=>it.field_name === e.target.name)>-1){
        if (allFields.findIndex(it => it.field_name === item.fieldName) > -1) {
            all[allFields.findIndex(it => it.field_name === item.fieldName)].required = e ? 1 : 0
            setAllFields([...all]);
        }
        // }
    };

    // 编辑
    const fieldEdit = (e, item) => {
        console.log(item)
        e.stopPropagation();
        let values = {
            displayName: item.displayName,
            field_display_name: item.field_display_name,
            field_desc: item.field_desc,
            attachment_max_file_size: item.attachment_max_file_size,
            attachment_max_count: item.attachment_max_count,
            attachment_type: item.attachment_type, 
            children: item.children
        };
        fieldDisplay.setFieldsValue(values);
        currentField.current = item;
        setCurrentFieldData(item);
        setFieldDisplayModal(true);
    };

    // 自定义保存
    const fieldDisplayOk = () => {
        fieldDisplay.validateFields().then((values) => {
            console.log(values)
            const curItem = currentField.current;
            if (curItem?.cateId) {
                let firstFieles = [...fieles];
                const indx = curItem.cateId - 1;
                let changeIndex = fieles[indx].findIndex(it => it.id === curItem.id);
                firstFieles[indx][changeIndex].field_display_name = values.field_display_name;
                firstFieles[indx][changeIndex].field_desc = values.field_desc;
                firstFieles[indx][changeIndex].attachment_max_file_size = values.attachment_max_file_size;
                firstFieles[indx][changeIndex].attachment_max_count = values.attachment_max_count;
                firstFieles[indx][changeIndex].attachment_type = values.attachment_type;
                firstFieles[indx][changeIndex].children = values.children;
                setFieles([...firstFieles]);

                let all = [...allFields];
                all = all.map(its => {
                    let newIts = { ...its };
                    if (its.field_name === curItem.fieldName) {
                        newIts.field_display_name = values.field_display_name;
                        newIts.field_desc = values.field_desc;
                        newIts.attachment_max_file_size = values.attachment_max_file_size;
                        newIts.attachment_max_count = values.attachment_max_count;
                        newIts.attachment_type = values.attachment_type;
                        newIts.children = values.children && values.children.length ? values.children.map((it, index) => ({
                            ...it,
                            field_name: (curItem.fieldName === 'person_case_info' ? personSecondField : secondField)[index].field_name,
                            field_display_name: (curItem.fieldName === 'person_case_info' ? personSecondField : secondField)[index].field_display_name
                        })) : undefined;
                    }
                    return newIts;
                });
                setAllFields([...all]);
            }

            setFieldDisplayModal(false);
        });
    };

    const fieldDisplayCancel = () => {
        fieldDisplay.resetFields();
        setFieldDisplayModal(false);
    };

    const FirstFieles = useCallback(() => {
        return (
            fieles[0] && fieles[0].map((item, index) => (
                <div className='fieles-choice'>
                    <Checkbox checked={item.isDefault === 1 || item.isDefault === 2} onChange={(e) => getFiled(e, item)} value={item.fieldName} checkname={item.displayName}>{item.displayName}</Checkbox>
                    <div className="field-name">{item.field_display_name || '-'}</div>
                    <div className="field-description">{item.field_desc || '-'}</div>
                    <div className="field-required">
                        是否必填：
                        <Switch
                            checkedChildren="必填"
                            unCheckedChildren="非必填"
                            checked={item.required}
                            onChange={(e) => getItem(e, item)}
                        />
                    </div>
                    <Button type="link" onClick={(e) => fieldEdit(e, item)} disabled={!item.isDefault}>编辑</Button>

                </div>
            ))
        )
    }, [fieles]);

    const SecondFieles = useCallback(() => {
        return (
            fieles[2] && fieles[2].map((item, index) => (
                <div className='fieles-choice'>
                    <Checkbox checked={item.isDefault === 1 || item.isDefault === 2} disabled={item.isDefault === 1} onChange={(e) => getFiled(e, item)} value={item.fieldName} checkname={item.displayName}>{item.displayName}</Checkbox>
                    <div className="field-name">{item.field_display_name || '-'}</div>
                    <div className="field-description">{item.field_desc || '-'}</div>
                    <div className="field-required">
                        是否必填：
                        <Switch
                            checkedChildren="必填"
                            unCheckedChildren="非必填"
                            checked={item.required}
                            onChange={(e) => getItem(e, item)}
                        />
                    </div>
                    <Button type="link" onClick={(e) => fieldEdit(e, item)} disabled={!item.isDefault}>编辑</Button>
                </div>
            ))
        )
    }, [fieles]);

    const ThirddFieles = useCallback(() => {
        return (
            fieles[1] && fieles[1].map((item, index) => (
                <div className='fieles-choice'>
                    <Checkbox checked={item.isDefault === 1 || item.isDefault === 2} disabled={item.isDefault === 1} onChange={(e) => getFiled(e, item)} value={item.fieldName} checkname={item.displayName}>{item.displayName}</Checkbox>
                    <div className="field-name">{item.field_display_name || '-'}</div>
                    <div className="field-description">{item.field_desc || '-'}</div>
                    <div className="field-required">
                        是否必填：
                        <Switch
                            checkedChildren="必填"
                            unCheckedChildren="非必填"
                            checked={item.required}
                            onChange={(e) => getItem(e, item)}
                        />
                    </div>
                    <Button type="link" onClick={(e) => fieldEdit(e, item)} disabled={!item.isDefault}>编辑</Button>
                </div>
            ))
        )
    }, [fieles]);

    useEffect(() => {
        getProjestsFiledsListApi().then(res => {
            if (res.status_code === 200) {
                let firstArr = Object.values(res.data)[0].filter(item => item.isDefault === 1)
                Object.values(res.data)[0].forEach(el => {
                    if (el.isDefault === 1) {
                        el.required = true
                    }
                })
                let secondArr = Object.values(res.data)[1].filter(item => item.isDefault === 1)
                Object.values(res.data)[1].forEach(el => {
                    if (el.isDefault === 1) {
                        el.required = true
                    }
                })
                let thirdArr = Object.values(res.data)[2].filter(item => item.isDefault === 1)
                Object.values(res.data)[2].forEach(el => {
                    if (el.isDefault === 1) {
                        el.required = true
                    }
                })
                let final = firstArr.concat(secondArr).concat(thirdArr).map(item => { return { field_name: item.fieldName, required: true, pid: item.cateId } });
                getTopicsSelectedInfoApi({ topicId }).then(select => {
                    if (select.status_code === 200) {
                        if (select.data) {
                            JSON.parse(select.data.caseFields).map(item => {
                                if (item.pid === 1) {
                                    Object.values(res.data)[0].forEach(el => {
                                        if (el.fieldName === item.field_name) {
                                            el.required = item.required;
                                            el.field_display_name = item.field_display_name;
                                            if (el.fieldName === 'person_case_info') { 
                                                el.children = item.children;
                                                el.field_desc = item.field_desc;
                                            }
                                            if (el.isDefault !== 1) el.isDefault = 2
                                        }
                                    })
                                }
                                if (item.pid === 2) {
                                    Object.values(res.data)[1].forEach(el => {
                                        if (el.fieldName === item.field_name) {
                                            el.required = item.required;
                                            el.field_display_name = item.field_display_name;
                                            el.field_desc = item.field_desc;
                                            el.attachment_max_file_size = item.attachment_max_file_size;
                                            el.attachment_max_count = item.attachment_max_count;
                                            el.attachment_type = item.attachment_type;
                                            if (el.isDefault !== 1) el.isDefault = 2
                                        }
                                    })
                                }
                                if (item.pid === 3) {
                                    Object.values(res.data)[2].forEach(el => {
                                        if (el.fieldName === item.field_name) {
                                            el.required = item.required;
                                            el.field_display_name = item.field_display_name;
                                            el.field_desc = item.field_desc;
                                            el.children = item.children;
                                            if (el.isDefault !== 1) el.isDefault = 2
                                        }
                                    })
                                }
                            })
                        }
                        if (!select.data || JSON.parse(select.data.caseFields).length == 0) {
                            setAllFields([...final])
                            let selects = final.map(item => {
                                return { value: item.field_name }
                            })
                            setFieldsSelect([...selects])
                        }
                        else {
                            setAllFields(JSON.parse(select.data.caseFields))
                            let selects = JSON.parse(select.data.caseFields).map(item => {
                                return { value: item.field_name }
                            })
                            setFieldsSelect([...selects])
                        }
                        setFieles(Object.values(res.data))
                    }
                })

            }
        })
    }, []);

    const geta = (res) => {
        console.log(res)
    }

    return (
        <div>
            <div className="field-list-table-title" >
                <span>默认字段名</span>
                <span>自定义字段名</span>
                <span>自定义字段说明</span>
                <span>是否必填</span>
                <span>操作</span>
            </div>
            <div>基础信息</div>
            {fieles[0] && <FirstFieles />}
            <div>联系人信息</div>
            {fieles[2] && <SecondFieles />}
            <div>案例上传</div>
            {fieles[2] && <ThirddFieles />}

            <Modal title="编辑" open={fieldDisplayModal} onOk={fieldDisplayOk} onCancel={fieldDisplayCancel} width={700}>
                <Form
                    form={fieldDisplay}
                    autoComplete="off"
                    {...formItemLayout}
                >
                    <Form.Item
                        label="默认字段名："
                        name="displayName"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="自定义字段名："
                        name="field_display_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入自定义字段名'
                            }
                        ]}
                    >
                        <Input placeholder="请输入自定义字段名" maxLength={10} showCount />
                    </Form.Item>
                    {
                        currentFieldData && (currentFieldData.cateId !== 1 || currentFieldData.fieldName === 'person_case_info') && (
                            <Form.Item
                                label="自定义字段说明："
                                name="field_desc"
                            >
                                <Input.TextArea rows={4} placeholder="请输入自定义字段说明" maxLength={100} showCount />
                            </Form.Item>
                        )
                    }
                    {
                        currentFieldData && (currentFieldData.fieldName === 'doc' || currentFieldData.fieldName === 'certificates') && (
                            <Form.Item
                                label="支持文件格式："
                                name="attachment_type"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择文件格式'
                                    }
                                ]}
                            >
                                <Checkbox.Group
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Row>

                                        <Col span={4}>
                                            <Checkbox value="PPT">PPT</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <Checkbox value="PPTX">PPTX</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <Checkbox value="DOC">DOC</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <Checkbox value="DOCX">DOCX</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <Checkbox value="PDF">PDF</Checkbox>
                                        </Col>

                                        {currentFieldData && currentFieldData.fieldName === 'certificates' &&
                                            <>
                                                <Col span={4}>
                                                    <Checkbox value="XLS">XLS</Checkbox>
                                                </Col>
                                                <Col span={4}>
                                                    <Checkbox value="XLSX">XLSX</Checkbox>
                                                </Col>
                                                <Col span={4}>
                                                    <Checkbox value="ZIP">ZIP</Checkbox>
                                                </Col>
                                                <Col span={4}>
                                                    <Checkbox value="RAR">RAR</Checkbox>
                                                </Col>
                                                <Col span={4}>
                                                    <Checkbox value="PNG">PNG</Checkbox>
                                                </Col>
                                                <Col span={4}>
                                                    <Checkbox value="JPG">JPG</Checkbox>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        )
                    }
                    {
                        currentFieldData && currentFieldData.fieldName === 'certificates' && (
                            <Form.Item
                                label="总文件上传数量："
                                name="attachment_max_count"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入总文件上传数量'
                                    }
                                ]}
                            >
                                <InputNumber style={{ width: '260px' }} placeholder="请输入正整数" addonAfter="个" addonBefore="最多上传" min={1} max={10} precision={0} />
                            </Form.Item>
                        )
                    }
                    {
                        currentFieldData && currentFieldData.cateId === 2 && (
                            <Form.Item
                                label="单文件上传大小："
                                name="attachment_max_file_size"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入单文件上传大小'
                                    }
                                ]}
                            >
                                <InputNumber style={{ width: '260px' }} placeholder="请输入正整数" addonAfter="MB" addonBefore="最大上传" min={1} precision={0} />
                            </Form.Item>
                        )
                    }
                    {
                        currentFieldData && (currentFieldData.cateId === 3 || currentFieldData.fieldName === 'person_case_info') &&

                        <Form.Item
                            name="children"
                            label="二级申报字段："
                        >
                            {(currentFieldData.fieldName === 'person_case_info' ? personSecondField : secondField).map((item, index) => (
                                <Space
                                    key={index}
                                    style={{
                                        display: 'flex',
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        name={['children',index, 'checked']}
                                        valuePropName="checked"
                                        style={{marginBottom: '10px'}}
                                    >
                                        <Checkbox style={{width:'100px'}}>{item.field_display_name}</Checkbox>
                                    </Form.Item>
                                </Space>
                            ))}
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </div>
    );
}

export default FieldsList;
