import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Typography,
    Tooltip
} from "antd";
import React, {Fragment, useEffect, useState} from "react";
import {SearchOutlined, MessageOutlined} from "@ant-design/icons";
import {filterFormItemLayout} from '@utils/property';
import {getSolutionsListApi, getUserFindAllApi, deleteSolutionByIdsApi, updateStatusApi} from '@services/Eha'
import './SolutionsList.less'
import {useNavigate, useParams,useLocation } from 'react-router-dom';
import moment from "moment";
import config from '@config'
const {Content} = Layout;
const {RangePicker} = DatePicker;
const {Option} = Select;
const {Text, Link} = Typography;
export default function SolutionsList() {
    const navigate = useNavigate()

    const [form] = Form.useForm()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const memCard = params.get('memCard');
    const filterFormGridLayout = {
        sm: {span: 24},
        md: {span: 12},
        xl: {span: 12},
        xxl: {span: 8},
    }
    // 获取列表数据
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])
    const [queryObj, setQueryObj] = useState({})
    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    // 分页
    const onChangePage = (page, pageSize) => {
        console.log(page);
        setPage(page);
        setPageSize(pageSize);
    };

    const onFinish = (res) => {
        setPage(1)
        setQueryObj(res)
    };
    const onReset = () => {
        form.resetFields();
        setPage(1)
        setQueryObj({})
    };
    const [timePicker, setTimePicker] = useState([]);
    const handleChangeTime = (value, dateString) => {
        setTimePicker(dateString);
    };


    const onDelete = (row) => {
        console.log(row)
        Modal.confirm({
            title: '提示',
            content: '解决方案删除后用户端将不再展示，确定要删除该解决方案吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteSolutionByIdsApi([row.id]).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getData()
                    }
                })
            }
        });
    }
    const onDownShelves = (status, row) => {
        if (status ===1){
            updateStatusApi({id: row.id, status: status}).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    getData()
                }
            })
        }else {
            Modal.confirm({
                title: '提示',
                content: '解决方案下架后用户端将不再展示，确定要下架该解决方案吗？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    updateStatusApi({id: row.id, status: status}).then((res) => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData()
                        }
                    })
                }
            });
        }
    }

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    const getDetail = (row) => {
        navigate(`/healthaccount/eha/solutions/edit/${row.id}`)
    };

    // 生成壁报
    const generatePoster = (row) => {
        navigate(`/healthaccount/eha/solutions/placard/${row.id}`)
    };

    const previewDetail = (row) => {
        console.log(config);
        window.open(`${config.SOLUTION_DOMAIN}/solutions/${row.id}?preview=1`)
    }
    const queryParams = {
        ...form.getFieldsValue(),
        page: page,
        limit: pageSize
    };
    const filterOption = (input, option) => {
        const {name, phone} = option.props.data;
        return (
            name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            phone.indexOf(input) >= 0
        );
    }
    useEffect(() => {
        getData()
    }, [page, pageSize, queryObj]);

    const getData = () => {
        if (memCard){
            queryParams.memCard=memCard
        }
        if (queryObj.createdAt != null) queryParams.createAtBegin = moment(queryObj.createdAt[0]).format('YYYY-MM-DD HH:mm:ss')
        if (queryObj.createdAt != null) queryParams.createAtEnd = moment(queryObj.createdAt[1]).format('YYYY-MM-DD HH:mm:ss')
        if (queryObj.reviewedAt != null) queryParams.reviewedAtBegin = moment(queryObj.reviewedAt[0]).format('YYYY-MM-DD HH:mm:ss')
        if (queryObj.reviewedAt != null) queryParams.reviewedAtEnd = moment(queryObj.reviewedAt[1]).format('YYYY-MM-DD HH:mm:ss')
        if (queryObj.reviewStatus === -1) {
            delete queryParams.reviewStatus
        }
        delete queryParams.createdAt
        delete queryParams.reviewedAt
        getSolutionsListApi(queryParams).then((res) => {
            if (res && res.status_code === 200) {
                console.log(res)
                setData(res.data.list)
                setTotal(res.data.totalCount)
            }
        })
    }

    useEffect(() => {
        getUserFindAllApi({}).then((res) => {
            if (res && res.status_code === 200) {
                console.log(res)
                setUserData(res.data)
            }
        })
    }, [])


    const columns = [
        {title: '解决方案名称', dataIndex: 'title', width: 300},
        {title: '发布者昵称', dataIndex: 'nickName', width: 100},
        {title: '发布者手机', dataIndex: 'mobile', width: 100},
        {title: '提交时间', dataIndex: 'createdAt', width: 200},
        {
            title: '审核状态', dataIndex: 'reviewStatus', width: 100,
            render: (reviewStatus, row) => (
                <Fragment>
                    {reviewStatus === 0 && (<span> 待审核 </span>)}
                    {reviewStatus === 1 && (<Text type="success"> 审核通过 </Text>)}
                    {reviewStatus === 2 && (<Text type="warning"> 审核驳回 <Tooltip placement="left" color="#ffffff"
                                                                                title={(<span
                                                                                    className="ToolRejectReason">{row.rejectReason}</span>)}>
                        <MessageOutlined/>
                    </Tooltip></Text>)}
                </Fragment>
            )
        },
        {title: '累计 PV/UV', dataIndex: 'pv',align: "center", width: 150,render:(_,row)=>(
                <Fragment>
                    {row.pv} / {row.uv}
                </Fragment>
            )},
        {
            title: '审核时间', dataIndex: 'reviewedAt', width: 200,
            render: (_, row) => (
                <Fragment>
                    {row.reviewedName && (<p className="reviewedNameClass"> {row.reviewedName} </p>)}
                    {row.reviewedAt && (<p className="reviewedAtClass"> {row.reviewedAt} </p>)}
                </Fragment>
            )
        },
        {
            title: '发布状态', dataIndex: 'status', width: 100,
            render: (status, row) => (
                <Fragment>
                    {status === 0 && (<span> 待发布 </span>)}
                    {status === 1 && (<Text type="success"> 已发布 </Text>)}
                    {status === 2 && (<Text type="warning"> 已下架 </Text>)}
                </Fragment>
            )
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 350,
            render: (_, row) => (
                <Fragment>
                    <span className="operate-style" onClick={() => generatePoster(row)}>生成壁报</span>
                    <span className="operate-style" onClick={() => previewDetail(row)}>预览</span>
                    <span className="operate-style" onClick={() => getDetail(row)}>审核</span>
                    {row.status === 1 &&
                    <span className="operate-style" onClick={() => onDownShelves(2, row)}>下架</span>}
                    {row.status === 2 &&
                    <span className="operate-style" onClick={() => onDownShelves(1, row)}>上架</span>}
                    <span className="operate-red-style" onClick={() => onDelete(row)}>删除</span>
                </Fragment>
            ),
        }
    ];

    return <Fragment>
        <div className="page-title">
            <span className="current-title">解决方案管理</span>
        </div>
        <Content className="main-content-box">
            <div className="filter-form-box">
                <Form {...filterFormItemLayout} onFinish={onFinish} form={form}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="解决方案名称" name="title">
                                <Input allowClear placeholder="解决方案名称关键字" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="发布者昵称" name="nickName">
                                <Input allowClear placeholder="企业用户昵称" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="发布者手机" name="mobile">
                                <Input allowClear placeholder="企业用户手机" autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="提交时间" name="createdAt">
                                <RangePicker
                                    className="ProjectRangPicker"
                                    allowClear
                                    onChange={handleChangeTime}
                                    showTime/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="审核时间" name="reviewedAt">
                                <RangePicker
                                    className="ProjectRangPicker"
                                    allowClear
                                    onChange={handleChangeTime}
                                    showTime/>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item label="审核状态" name="reviewStatus">
                                <Select placeholder='请选择审核状态'
                                        options={[
                                            {value: -1, label: '全部'},
                                            {value: 0, label: '待审核'},
                                            {value: 1, label: '审核通过'},
                                            {value: 2, label: '审核驳回'},
                                        ]}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col {...filterFormGridLayout}>
                            <Form.Item label="审核管理员" name="reviewedBy">
                                <Select placeholder='请选择审核管理员'
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                >
                                    {userData && userData.map((item) => (
                                        <Option key={item.phone} value={item.phone} data={item}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout} className="filter-form-buttons">
                            <Space>
                                <Button type="default" onClick={onReset}>重置</Button>
                                <Button type="primary" icon={<SearchOutlined/>} htmlType="submit">检索</Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </div>

            <div className="main-content-body">

                <div className="table-caption">
                    <div className="table-title">解决方案列表</div>
                </div>

                <Table
                    className="enterprises-table"
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        total: total,
                        showTotal: () => `共${total}条`,
                        current: page,
                        pageSize: pageSize,
                        onChange: onChangePage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                    }}
                />

            </div>
        </Content>

    </Fragment>
}
