import { Link, useNavigate, useParams } from 'react-router-dom';
import { DragOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
    Input,
    Layout,
    Space,
    Button,
    Table,
    Row,
    Modal,
    Drawer,
    Form,
    Switch,
    Checkbox,
    message,
    Col,
    Divider,
    Dropdown,
    Tooltip
} from 'antd';
import './index.less';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {
    solutionListApi,
    fetchContentModuleContents,
    changeContentModuleStatus,
    addContentModuleContents,
    removeContentModuleContents,
    sortContentModuleContents
} from '@services/MedProject';
import config from '@config';

const { Content } = Layout;
const { Search } = Input;

export default function ModulesSolutions() {
    const navigate = useNavigate();
    const params = useParams();
    const projectId = params?.projectId || 0;
    const moduleId = params?.id || 0;

    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recommendData, setRecommendData] = useState([]);

    const [addPage, setAddPage] = useState(1);
    const [addSize, setAddSize] = useState(10);
    const [addTotal, setAddTotal] = useState(0);
    const [addSolutionData, setAddSolutionData] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const columns = [
        {
            title: '解决方案名称',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '发布者昵称',
            key: 'nickName',
            render: (_, record) => (
                <>{record.baseVO?.nickName}</>
            ),
        },
        {
            title: '显示/隐藏',
            key: 'hidden',
            render: (_, record) => (
                <Switch
                    checked={!record.hidden}
                    checkedChildren="显示"
                    unCheckedChildren="隐藏"
                    onChange={() => onHiddenChange(record)}
                />
            ),
        },
        {
            title: '推荐到主页',
            key: 'recommended',
            render: (_, record) => (
                <Switch
                    checked={record.recommended}
                    checkedChildren="推荐"
                    unCheckedChildren="不推荐"
                    onChange={() => onRecommendedChange(record)}
                />
            ),
        },
        {
            title: '操作',
            dataIndex: 'operations',
            width: 150,
            render: (_, record) => {
                const items = [
                    {
                        label: '移除',
                        key: 'remove',
                        onClick: () => onDelete(record.id),
                    },
                ];

                return (
                    <span className="operation-btns">
                        <Button
                            size="small"
                            type="link"
                            onClick={() => viewSolution(record)}
                        >
                            查看详情
                        </Button>

                        <Divider type="vertical" />

                        <Dropdown menu={{ items }} placement="bottomRight">
                            <Button
                                type="link"
                                size="small"
                                className="more-operation"
                            >
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    </span>
                );
            }
        }
    ];

    const addColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
            align: 'center',
            key: 'id'
        },
        {
            title: '解决方案名称',
            dataIndex: 'title',
            align: 'center',
            key: 'title'
        }
    ];

    // 页码改变
    const onChangePage = (a, b) => {
        setPage(a);
        setPageSize(b);
    };

    // 项目解决方案名称搜索
    const solutionsSearch = (e) => {
        if(e && e.target && e.target.value){
            form.setFieldValue('name', e.target.value);
            getSolutionsList();
        }else{
            getSolutionsList();
        }
    };

    // 项目解决方案推荐搜索
    const solutionsCheck = (e) => {
        if(e.target.checked){
            form.setFieldValue('recommended', 1);
        }else{
            form.setFieldValue('recommended', undefined);
        }
        getSolutionsList();
    };

    // 显示/隐藏
    const onHiddenChange = async(record) => {
        if (record.recommended === 1 && record.hidden === 0) {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                centered: true,
                content: '当前解决方案已推荐到主页，设置隐藏后，项目主页上此解决方案也将进行隐藏。',
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    const res = await changeContentModuleStatus({ id: record.id, hidden: record.hidden ? 0 : 1 });
                    if (res.status_code === 200) {
                        message.success(res.message);
                        getSolutionsList();
                    }
                }
            });
        } else {
            const res = await changeContentModuleStatus({ id: record.id, hidden: record.hidden ? 0 : 1 });
            if (res.status_code === 200) {
                message.success(res.message);
                getSolutionsList();
            }
        }
    };

    // 推荐主页
    const onRecommendedChange = async (record) => {
        if (record.hidden === 1 && record.recommended === 0) {
            return message.error('当前解决方案已隐藏，不能推荐到首页，可以设置显示后再推荐。');
        }
        const res = await changeContentModuleStatus({ id: record.id, recommended: record.recommended ? 0 : 1 });
        if (res.status_code === 200) {
            message.success(res.message);
            getSolutionsList();
        }
    };

    // 移除
    const onDelete = (contentId) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: '移除后，该解决方案在用户端也将下架，请确认是否移除？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await removeContentModuleContents(contentId);
                if (res.status_code === 200) {
                    message.success('移除成功');
                    getSolutionsList();
                }
            }
        });
    };

    // 跳转详情
    const viewSolution = (record) => {
        return window.open(`${record?.baseVO?.jumpUrl}`);
    };

    // 添加解决方案显示
    const showAddModal = () => {
        setIsModalOpen(true);
        onSearch();
    };

    const handleOk = async () => {
        if (!selectedRowKeys.length) {
            return message.error('请选择关联解决方案');
        }
        const params = {
            contentType: 'solution',
            itemIds: [...selectedRowKeys],
            moduleId,
            projectId
        };
        const res = await addContentModuleContents(params);
        if (res.status_code === 200) {
            message.success('新增成功');
            handleCancel();
            getSolutionsList();
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        addForm.resetFields();
        setSelectedRowKeys([]);
    };

    // 添加解决方案 - 搜索
    const onSearch = () => {
        addForm.validateFields().then(async values => {
            setListLoading(true);
            // console.log('addvalues:', values);
            const addPramas = {
                ...values,
                page: addPage,
                size: addSize,
                moduleId
            };
            const res = await solutionListApi(addPramas);
            setListLoading(false);
            if (res && res.status_code === 200 && res.data) {
                setAddSolutionData(res.data.records || []);
                setAddTotal(res.data.total);
            }
        });
    };

    // 添加解决方案 - 页码改变
    const onChangeAddPage = (a, b) => {
        setAddPage(a);
        setAddSize(b);
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (nowSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(nowSelectedRowKeys);
        },
        getCheckboxProps: (record) => ({disabled: record.hasData}) // 禁用
    };


    const onSortModal = () => {
        getSolutionsList(1);
    };

    const onClose = () => {
        form.resetFields();
        getSolutionsList();
        setOpen(false);
    };

    // 拖拽按钮
    const SortableMainHandle = SortableHandle(() => {
        return <DragOutlined />;
    });

    // 排序
    const onSortEndHandler = async ({ oldIndex, newIndex }) => {
        const newData = arrayMoveImmutable(recommendData, oldIndex, newIndex);
        const ids = newData.map(item => item.id);
        const res = await sortContentModuleContents([...ids]);
        if (res.status_code === 200) {
            message.success('排序成功');
            setRecommendData([ ...newData ]);
        }
    };

    // 拖拽容器
    const SortableModulesContainer = SortableContainer(({ items }) => {
        return <div>
            {!!items?.length && items.map((treeNode, index) => {
                return (
                    <SortableMainElement
                        item={treeNode}
                        index={index}
                        key={index}
                        sort={index}
                    />
                );
            })}
        </div>;
    });

    const SortableMainElement = SortableElement(({ item, key }) => {
        return <div key={key} style={{position: 'relative', zIndex: 99999999}}>
            <p className="solutions-name-sort">
                <span className="solutions-item-name">{item.projectName}</span>
                <span className="solutions-item-option"><SortableMainHandle/></span>
            </p>
        </div>
    });


    // 解决方案列表
    const getSolutionsList = async (recommend) => {
        const reqParams = {
            moduleId,
            page: recommend ? 1 : page,
            pageSize,
            name: form.getFieldValue('name'),
            recommended: recommend ? recommend : form.getFieldValue('recommended'),
            contentType: 'solution'
        }
        // console.log(reqParams);
        setLoading(true);
        const res = await fetchContentModuleContents(reqParams);
        setLoading(false);
        if (res.status_code === 200 && res.data) {
            if(recommend){
                if(res.data?.list?.length){
                    setRecommendData(res.data.list);
                    setOpen(true);
                }else{
                    message.warning('暂无首页推荐内容，无需排序');
                }
            }else{
                setData(res.data.list || []);
                setTotal(res.data.totalCount);
            }

        }
    };

    useEffect(() => {
        getSolutionsList();
    }, [page, pageSize, moduleId]);

    useEffect(() => {
        onSearch();
    }, [addPage, addSize, moduleId]);

    return (
        <>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="solutions-page">
                        <div className="med-project-module-head">
                            <div className="module-title">
                                <h2>解决方案</h2>
                                <span className="tips">可以添加平台解决方案，<em>项目首页最多可推荐10条数据</em></span>
                            </div>
                            <div className="preview">
                                <Button
                                    type="link"
                                    size="small"
                                    ghost
                                    style={{padding: 0}}
                                    onClick={() => window.open(`${config.PROJECT_DOMAIN}/p/${projectId}/solutions/${moduleId}`)}
                                    icon={<>
                                        <span className="anticon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                                                <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                                            </svg>
                                        </span>
                                    </>}
                                >
                                    <span>预览</span>
                                </Button>
                            </div>
                        </div>

                        <div className="med-project-module-toolbar">
                            <div className="main-tools">
                                <Form form={form} layout="inline">
                                    <Form.Item name="name" label="">
                                        <Input placeholder="请输入解决方案名称" onPressEnter={solutionsSearch}/>
                                    </Form.Item>

                                    <Form.Item name="recommended" label="">
                                        <Checkbox onChange={solutionsCheck}>只看首页推荐</Checkbox>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="sub-tools">
                                <Space>
                                    <Tooltip title="为推荐首页内容排序">
                                        <Button onClick={() => onSortModal()}>排序</Button>
                                    </Tooltip>
                                    <Button type="primary" onClick={() => showAddModal()}>添加解决方案</Button>
                                </Space>
                            </div>
                        </div>
                        <div className="solutions-content">
                            <Table
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                pagination={{
                                    total: total,
                                    showTotal: () => `共${total}条`,
                                    current: page,
                                    pageSize: pageSize,
                                    onChange: onChangePage,
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                }}
                            />
                        </div>

                        <Drawer
                            title="解决方案排序"
                            onClose={onClose}
                            open={open}
                            maskClosable={false}
                            width={600}
                            footer={null}
                        >
                            <div>
                                <SortableModulesContainer
                                    useWindowAsScrollContainer={true}
                                    useDragHandle
                                    distance={10}
                                    items={recommendData}
                                    onSortEnd={onSortEndHandler}
                                />
                            </div>
                        </Drawer>

                        <Modal
                            title="添加解决方案"
                            open={isModalOpen}
                            className="add-solutions-modal"
                            centered
                            width={900}
                            onOk={handleOk}
                            onCancel={handleCancel}
                        >
                            <Form
                                form={addForm}
                                name="basic"
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 24 }}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col span={6} style={{marginRight: '10px'}}>
                                        <Form.Item
                                            label=""
                                            name="solutionId"
                                        >
                                            <Input placeholder="解决方案ID"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{marginRight: '10px'}}>
                                        <Form.Item
                                            label=""
                                            name="title"
                                        >
                                            <Input placeholder="解决方案名称"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{marginRight: '10px'}}>
                                        <Button type="primary" onClick={onSearch}>搜索</Button>
                                    </Col>
                                </Row>
                            </Form>

                            <Table
                                rowKey={record => record.id}
                                rowSelection={{ ...rowSelection }}
                                columns={addColumns}
                                dataSource={addSolutionData}
                                loading={listLoading}
                                size="small"
                                pagination={{
                                    total: addTotal,
                                    showTotal: () => `共${addTotal}条`,
                                    current: addPage,
                                    pageSize: addSize,
                                    onChange: onChangeAddPage,
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                }}
                            />
                        </Modal>
                    </div>
                </div>
            </Content>

        </>
    );
}
