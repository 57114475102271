import React, { Fragment, useState, useEffect } from "react";
import { Layout, Button, Form, Input, Checkbox, Upload, Image, message, Space, Progress, Modal, Spin, Alert, Tooltip, Typography, Radio, Cascader, Select } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined, UploadOutlined, EyeOutlined, MinusCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import TinymceEditor from './TinymceEditor';
import './EditSolution.less';
import { getSolutionItemApi, uploadAttachApi, uploadSolutionApi, getVodMediaInfo, getAliyuncred, refreshAliyuncred, editSolutionsApi, getSolutionDetailApi, videoTransCode, reviewSolutionApi } from '@services/Eha';
import { innovativeTechnologiesList, enterpriseVolumeList, isGryList, applicationScenarios } from '@utils/solution';
import config from "@config";
import ImgCrop from 'antd-img-crop';

const { Content } = Layout;

const { TextArea } = Input;

const { Text } = Typography;

const { SHOW_CHILD } = Cascader;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

// 视频上传实例
let add = null;

// 视频点播
let player = null;

// 编辑解决方案
const EditSolution = () => {
    const params = useParams();
    const [form] = Form.useForm();
    const [auditForm] = Form.useForm();
    const navigate = useNavigate();

    // const [checkNick, setCheckNick] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showInfo, setShowInfo] = useState(false);

    // 宣传图
    const [cover, setCover] = useState(undefined);
    const [coverList, setCoverList] = useState([]);
    // 附件
    const [attachment, setAttachment] = useState(undefined);
    const [progressPercent, setProgressPercent] = useState(undefined);
    const [showProgress, setShowProgress] = useState(false);
    const [videoList, setVideoList] = useState(null);
    const [videoId, setVideoId] = useState(undefined);

    const [dataDetail, setDataDetail] = useState(undefined);
    const [images, setImages] = useState([]);
    const [docs, setDocs] = useState([]);

    const [spinLoading, setSpinLoading] = useState(false);

    const [solutionContent, seSolutionContent] = useState(undefined)

    const [radioValue, setRadiovalue] = useState(undefined)
    const [audit, setAudit] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    // 加载更多
    const loadMore =
        !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    margin: '30px 0',
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button type="primary" ghost onClick={() => setLoading(true)}>点击可填写更多内容</Button>
            </div>
        ) : <div className="no-require-info">
                <p><i></i><span>以下为非必填内容</span><i></i></p>
                <p>温馨提示：填写的越丰富，解决方案价值越大</p>
            </div>;

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    const handleCancel = () => setIsModalOpen(false);

    // 内容版权
    const onInfo = () => {
        Modal.info({
            className: "copyrightModal",
            icon: null,
            title: <p style={{ textAlign: 'center' }}>内容版权声明</p>,
            width: '500px',
            centered: true,
            content: (
                <div>
                    <p>本单位为解决方案内容模版所有者，对解决方案内容拥有全部著作权，我单位同意该解决方案内容授予健康界及健康界“医企+”使用。为提高解决方案内容使用效果，我单位理解并授权同意健康界及健康界“医企+”对解决方案内容进行非实质性的编辑工作和轻度修改包括文字、句子段落、图表和其他案例要素内容。</p>
                    <p>特此声明。</p>
                </div>
            ),
            okText: '我知道了',
            onOk() { },
        });
    };

    // 预览 - 本地缓存表单内容
    const onPreview = () => {
        let values = form.getFieldsValue();
        let newValues = { ...values };
        newValues.docs = newValues.docs ? JSON.stringify(newValues.docs) : undefined;
        newValues.attachment = newValues.attachment ? JSON.stringify(newValues.attachment) : undefined;
        newValues.images = newValues.images ? JSON.stringify(newValues.images) : undefined;
        newValues.video = newValues.video ? JSON.stringify(newValues.video) : undefined;
        newValues.id = params.id;
        newValues.unit = dataDetail?.unit;
        newValues.nickName = dataDetail?.nickName;
        newValues.headImg = dataDetail?.headImg;

        localStorage.setItem('localSolutionValues', JSON.stringify(newValues));
        setIsModalOpen(true);
    };

    // 保存
    const onSave = () => {
        form.validateFields().then(async (values) => {
            console.log(values,'values');
            // if (!checkNick) {
                //     return setShowInfo(true);
                // };
            let newValues = { ...values };
            newValues.docs = newValues.docs ? JSON.stringify(newValues.docs) : undefined;
            newValues.attachment = newValues.attachment ? JSON.stringify(newValues.attachment) : undefined;
            newValues.images = newValues.images ? JSON.stringify(newValues.images) : undefined;
            newValues.video = newValues.video ? JSON.stringify(newValues.video) : undefined;
            newValues.id = params.id;
            newValues.applicationScenarios = JSON?.stringify(ArrToTree(values?.applicationScenarios));
            newValues.innovativeTechnologies = (values.innovativeTechnologies && values.innovativeTechnologies.length) ? JSON.stringify(values.innovativeTechnologies.map(item=>{return {name:item}})) : null;
            console.log(newValues,'a');

            setSpinLoading(true);
            const res = await editSolutionsApi(newValues);
            setSpinLoading(false);
            if (res) {
                message.success('编辑成功');
                getData();
                // navigate('/healthaccount/eha/solutions');
            }
        }).catch(err => {
            console.log(err)
        })
    };

    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    // const onCheckboxChange = (e) => {
    //     setCheckNick(e.target.checked);
    // };

    // 上传文件路径 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return `${config.OSS_PREFIX}/${url}`;
        }
        return null;
    };

    // 附件预览
    const attachmentPreview = (path, ext) => {
        if (ext === 'pdf') {
            window.open(getFileUrl(path));
        } else {
            let officeUrl = `http://view.officeapps.live.com/op/view.aspx?src=${getFileUrl(path)}`;
            window.open(officeUrl);
        }
    };

    // 上传格式校验
    const beforeUpload = (file, type) => {
        if (type === 1) {
            const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('只能上传 jpg/png格式');
                return false;
            }
            const isLt2M = file.size / 1024 < 500;
            if (!isLt2M) {
                message.error('图片大小不超过500KB');
                return false;
            }
            return isJpgOrPng && isLt2M;
        } else if (type === 2) {
            const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('只能上传 jpg/png格式');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片大小不超过2MB');
                return false;
            }
            return isJpgOrPng && isLt2M;
        } else {

            const isJpgOrPng = file.type === "application/vnd.ms-powerpoint" || file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/pdf"
            if (!isJpgOrPng) {
                message.error('只能上传 ppt/pdf/doc 格式');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 30;
            if (!isLt2M) {
                message.error('文件大小不超过30MB');
                return false;
            }
            return isJpgOrPng && isLt2M;
        }
    };

    // 获取文件类型ext
    const getExt = (fileType) => {
        if (fileType === "application/vnd.ms-powerpoint") return 'ppt';
        if (fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") return 'pptx';
        if (fileType === "application/msword") return 'doc';
        if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") return 'docx';
        if (fileType === "application/pdf") return 'pdf';
    };


    // 图片、文档上传
    const imageUpload = async (value, type) => {
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('path', '');

        let ajaxApi = type === 2 || type === 4 ? uploadSolutionApi : uploadAttachApi;

        const res = await ajaxApi(formData);
        if (res && res.data) {
            switch (type) {
                case 1:
                    // setCover(res.data);
                    setCoverList([getFileUrl(res.data)]);
                    form.setFieldValue('cover', getFileUrl(res.data));
                    form.setFields([{ name: 'cover', errors: null }]);
                    break;
                case 2:
                    const obj = {
                        path: res.data,
                        fileName: value.file.name,
                        ext: getExt(value.file.type),
                        size: value.file.size
                    }
                    setAttachment(obj);
                    form.setFieldValue('attachment', obj);
                    break;
                case 3:
                    let fieldValues = form.getFieldValue('images') || {};
                    let imageObj = [res.data];
                    fieldValues.images = fieldValues.images ? fieldValues.images.concat(imageObj) : [].concat(imageObj);
                    setImages(fieldValues.images);
                    form.setFieldValue('images', { ...fieldValues });
                    break;
                case 4:
                    let fieldDocValues = form.getFieldValue('docs') || {};
                    let docObj = [{
                        path: res.data,
                        fileName: value.file.name,
                        ext: getExt(value.file.type),
                        size: value.file.size
                    }]
                    fieldDocValues.docs = fieldDocValues.docs ? fieldDocValues.docs.concat(docObj) : [].concat(docObj);
                    setDocs(fieldDocValues.docs);
                    form.setFieldValue('docs', { ...fieldDocValues });
                    break;
                default:
                    break;
            }
        };
    };

    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 500 * 1024) {
            message.error('图片大小不能超过500KB。');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    if (imgDom.width < 500 || imgDom.height < 350) {
                        bool && message.warn('封面不小于500x350px。');
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    // 附件名称改变
    const attachmentChange = (e) => {
        const newObj = { ...attachment };
        newObj.fileName = e.target.value;
        setAttachment(newObj);
        form.setFieldValue('attachment', newObj);
    };

    // 视频名称改变
    const videoTitleChange = (e) => {
        const newObj = { ...videoList };
        newObj.title = e.target.value;
        setVideoList(newObj);
        form.setFieldValue('video', newObj);
    };

    // 附件删除
    const removeAttachment = () => {
        setAttachment(undefined);
        form.setFieldValue('attachment', {});
    };

    // 视频删除
    const removeVideo = () => {
        setVideoList(undefined);
        form.setFieldValue('video', {});
        setProgressPercent(0);
    };

    const videoProps = {
        name: "video",

        customRequest: async (info) => {
            //手动上传
            const isJpgOrPng =
                info.file.type === "video/mp4" ||
                info.file.type === "video/rm" ||
                info.file.type === "video/rmvb";
            if (!isJpgOrPng) {
                message.error("只能上传 mp4/rm/rmvb 格式!");
                return;
            }
            const isLt800M = info.file.size / 1024 / 1024 < 200;
            if (!isLt800M) {
                message.error("文件大小不能超过200M");
                return;
            }
            add = createUploader()

            var userData = '{"Vod":{}}';
            add.addFile(info.file, null, null, null, userData);
            await add.startUpload();
        },
        onChange(info) {
            const isJpgOrPng =
                info.file.type === "video/mp4" ||
                info.file.type === "video/rm" ||
                info.file.type === "video/rmvb";
            const isLt2G = info.file.size / 1024 / 1024 <= 200;
            if (!isJpgOrPng) {
                info.file.status = "error";
            }
            if (!isLt2G) {
                info.file.status = "error";
            }
            if (info.file.status === "uploading") {
                info.file.status = "done";
            }
            if (info.file.status === "uploading") {
                info.file.status = "done";
            }
            if (info.file.status === "removed") {
                add.stopUpload()
                setProgressPercent(undefined)
            }

        },
    };

    // 阿里云视频上传实例
    const createUploader = () => {
        let uploader = new window.AliyunUpload.Vod({
            timeout: 1200000,
            partSize: 1048576,
            parallel: 100,
            retryCount: 3,
            retryDuration: 2,
            region: 'cn-shanghai',
            userId: '1303984639806000',
            // 添加文件成功
            addFileSuccess: (uploadInfo) => {
                if (uploadInfo !== null) {
                    uploader.startUpload()
                }

            },
            // 开始上传
            onUploadstarted: async (uploadInfo) => {
                setShowProgress(true);
                if (!uploadInfo.videoId) {
                    let params = {
                        fileName: uploadInfo.file.name,
                        title: uploadInfo.file.name.substring(0, uploadInfo.file.name.lastIndexOf(".")),
                        companyCode: 'CASE',
                        mediaType: 1,
                        cateId: 14131
                    }
                    await getAliyuncred(params).then(res => {
                        if (res) {
                            let data = res.data;
                            let uploadAuth = data.uploadAuth;
                            let uploadAddress = data.uploadAddress;
                            let videoId = data.videoId;
                            setVideoId(data.videoId);
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                        }
                    })
                } else {
                    // 如果videoId有值，根据videoId刷新上传凭证
                    refreshAliyuncred({ videoId: uploadInfo.videoId }).then(res => {
                        if (res) {
                            let data = res.data;
                            let uploadAuth = data.uploadAuth;
                            let uploadAddress = data.uploadAddress;
                            let videoId = data.videoId;
                            setVideoId(data.videoId);
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                        }
                    })
                }
            },
            // 文件上传成功
            onUploadSucceed: function (uploadInfo) {
                console.log(uploadInfo)
                let videoItem = {
                    title: uploadInfo.file.name,
                    video_id: uploadInfo.videoId,
                    size: uploadInfo.file.size,
                }
                setVideoList(videoItem);
                form.setFieldValue('video', videoItem);
                form.setFields([
                    { name: 'video', errors: null }
                ]);
                setShowProgress(false);
                videoPreview(videoItem.video_id);
                let formData = new FormData();
                // formData.append("file", null); //名字和后端接口名字对应
                formData.append("companyCode", "SOLUTION");
                formData.append("mediaType", 1);
                formData.append("cateId", 14810);
                formData.append("fileName", videoItem.title);
                formData.append("title", videoItem.title.substring(0, uploadInfo.file.name.lastIndexOf(".")));
                formData.append("videoId", videoItem.video_id);
                videoTransCode(formData)
            },
            // 文件上传失败
            onUploadFailed: function (uploadInfo, code, message) {
                message.error('上传失败')
            },
            // 取消文件上传
            onUploadCanceled: function (uploadInfo, code, message) {
            },
            // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
            onUploadProgress: function (uploadInfo, totalSize, progress) {
                setProgressPercent(Math.ceil(progress * 100));
            },
            // 上传凭证超时
            onUploadTokenExpired: function (uploadInfo) {
                // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口
                refreshAliyuncred({ videoId: uploadInfo.videoId }).then(res => {
                    if (res) {
                        let data = res.data;
                        let uploadAuth = data.uploadAuth;
                        setVideoId(data.videoId);
                        uploader.resumeUploadWithAuth(uploadAuth);
                    }
                })
            },
            // 全部文件上传结束
            onUploadEnd: function (uploadInfo) {
            }
        })
        return uploader
    }

    // 视频点播 - 主题配置
    const playerSkinLayout = [
        { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
        { name: "H5Loading", align: "cc" },
        { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
        { name: "infoDisplay" },
        { name: "tooltip", align: "blabs", x: 0, y: 56 },
        { name: "thumbnail" },
        {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                { name: "progress", align: "blabs", x: 0, y: 44 },
                { name: "playButton", align: "tl", x: 15, y: 12 },
                { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                // {name:"subtitle", align:"tr",x:15, y:12},
                { name: "setting", align: "tr", x: 15, y: 12 },
                { name: "volume", align: "tr", x: 5, y: 10 }
            ]
        }
    ];

    // 视频预览
    const videoPreview = async (mediaId) => {
        if (!mediaId) {
            return;
        }
        if (player) {
            player.dispose();
        }

        const playInfo = await getVodMediaInfo({ mediaId });
        const resData = playInfo.data;
        if (!!resData && window.Aliplayer) {
            if (resData.status === 'Normal') {
                const playauth = resData.playAuth;
                player = new window.Aliplayer({
                    id: 'J_prismPlayer',
                    width: "450px",
                    height: "270px",
                    vid: mediaId,
                    playauth,
                    autoplay: false,
                    isLive: false,
                    rePlay: false,
                    playsinline: false,
                    preload: true,
                    skinLayout: playerSkinLayout
                }, function (player) {

                });
            }
        }
    };

    // 解决方案详情
    const getSolutionDetail = async (id) => {
        const response = await getSolutionDetailApi({ id });
        const res = response?.data;
        if (res) {
            setDataDetail(res);
            const resValues = {
                title: res.title,
                cover: res.cover,
                summary: res.summary,
                flowcharts: res.flowcharts,
                honors: res.honors,
                industryProblems: res.industryProblems,
                advantages: res.advantages,
                applicationCases: res.applicationCases,
                bizScenarios: res.bizScenarios,
                benefits: res.benefits,
                technologies: res.technologies,
                processes: res.processes,
                attachment: res.attachment ? JSON.parse(res.attachment) : undefined,
                docs: res.docs ? JSON.parse(res.docs) : undefined,
                images: res.images ? JSON.parse(res.images) : undefined,
                video: res.video ? JSON.parse(res.video) : undefined,
                applicationScenarios: res.applicationScenarios ? TreeToArr(JSON?.parse(res?.applicationScenarios)) : [],
                innovativeTechnologies: res.innovativeTechnologies ? JSON?.parse(res?.innovativeTechnologies).map(item=>item.name) : [],
                isGry : res?.isGry,
                enterpriseVolume: res?.enterpriseVolume

            };

            if (res.cover) {
                // setCover(res.cover);
                setCoverList([getFileUrl(res.cover)]);
            }
            if (res.attachment && res.attachment !== '{}') {
                const resAttachment = JSON.parse(res.attachment);
                setAttachment(resAttachment);
            }
            if (res.video && res.video !== '{}') {
                const resVideo = JSON.parse(res.video);
                setVideoList(resVideo);
                videoPreview(resVideo.video_id);
            }
            if (res.images && res.images !== '{}') {
                const resImages = JSON.parse(res.images);
                if (resImages && resImages.images && resImages.images.length) {
                    setImages(resImages.images);
                } else {
                    form.setFieldValue('images', []);
                }
            }
            if (res.docs && res.docs !== '{}') {
                const resDocs = JSON.parse(res.docs);
                if (resDocs && resDocs.docs && resDocs.docs.length) {
                    setDocs(resDocs.docs);
                } else {
                    form.setFieldValue('docs', []);
                }
            }
            form.setFieldsValue(resValues);
        }
    };

    const onReview = () => {
        auditForm.validateFields().then((values) => {
            let obj = {
                ...form.getFieldsValue(),
                id: Number(params.id),
                reviewStatus: values.reviewStatus,
                rejectReason: radioValue === 2 ? auditForm.getFieldValue('rejectReason') : '',
                applicationScenarios : JSON?.stringify(ArrToTree(form.getFieldsValue()?.applicationScenarios)),
                innovativeTechnologies : (form.getFieldsValue().innovativeTechnologies && form.getFieldsValue().innovativeTechnologies.length) ? JSON.stringify(form.getFieldsValue().innovativeTechnologies.map(item=>{return {name:item}})) : null,

            }
            reviewSolutionApi({ ...obj }).then(res => {
                if (res.status_code === 200) {
                    setAudit(false);
                    auditForm.resetFields();
                    setRadiovalue(undefined);
                    message.success('审核完成');
                    getData();
                    navigate('/healthaccount/eha/solutions');
                }
            })
        })
    };

    const getData = () => {
        getSolutionItemApi({ id: Number(params.id) }).then(res => {
            if (res && res.status_code === 200) {
                seSolutionContent(res.data)
                form.setFieldValue(res.data)
            }
        })
    };
    const resetValue = () => {
        setAudit(false)
        auditForm.setFieldValue('reviewStatus',  '')
        auditForm.setFieldValue('rejectReason',  '')
        setRadiovalue(undefined)
    };

    const changeReason = (e) => {
        setRadiovalue(e.target.value);
        auditForm.setFieldValue('reviewStatus', e.target.value)
    };

    // 勾选阅读并同意
    // useEffect(() => {
    //     if (checkNick) {
    //         return setShowInfo(false);
    //     }
    // }, [checkNick]);

    // 二维数组转树
    const ArrToTree = (arr) => {
        let result =[]
        let concatStr = "====="
        let map = new Map()
        function addList(list) {
            let path = []
            let node
            list.forEach((v,i) => {
                path.push(v)
                let key = path.join(concatStr)
                let item = i == 0 ? { name: v, child: [] } : { name: v }
                if(map.has(key)) {
                    item = map.get(key)
                } else {
                    map.set(key, item)
                    if(node) {
                        node.child = (node.child || []).concat(item)
                    } else {
                        result.push(item)
                    }
                }
                node = item
            })
        }
        arr.forEach(v => addList(v))
        return result
    }

    // 树转二维数组
    const TreeToArr = (tree) => {
        let result = []
        function getPath(node, arr) {
            arr.push(node.name)
            if(node.child && node.child.length > 0) {
                node.child.forEach(v2 =>  getPath(v2, [...arr]))
            } else {
                result.push(arr)
            }
        }
        tree.forEach(v => getPath(v, []))
        return result
    }

    useEffect(() => {
        if (params.id) {
            getSolutionDetail(params.id);
        }
    }, []);

    useEffect(() => {
        getSolutionItemApi({ id: Number(params.id) }).then(res => {
            if (res && res.status_code === 200) {
                seSolutionContent(res.data)
            }
        });
    }, [])

    useEffect(() => {
        if (isModalOpen) {
            const iframeContainer = document.getElementById('preview');
            iframeContainer.onload = () => {
                let timer = setTimeout(() => {
                    iframeContainer.contentWindow.postMessage(localStorage.getItem('localSolutionValues'), '*');
                    clearTimeout(timer);
                },100);
            }
            iframeContainer.contentWindow.postMessage(localStorage.getItem('localSolutionValues'), '*');
        }
    }, [isModalOpen]);

    return <>
        <div className="page-title">
            <Link to={`/healthaccount/eha/solutions`} className="back"><LeftOutlined /></Link>
            <span className="current-title">编辑解决方案</span>
        </div>

        <Content className="main-content-box">
            <div className="main-content-title">解决方案信息</div>
            <div className="main-content-body">
                <Alert
                    className="solution-message-box"
                    message={
                        <div className='solution-message-info'>
                            <div>
                                <span>提交时间：</span>
                                <span>{solutionContent && solutionContent.createdAt}</span>
                            </div>
                            <div>
                                <span>审核状态：</span>
                                {solutionContent && solutionContent.reviewStatus == 0 && <span >待审核</span>}
                                {solutionContent && solutionContent.reviewStatus == 1 && <span className='throw-scusses'>已通过</span>}
                                {solutionContent && solutionContent.reviewStatus == 2 &&
                                    <>
                                        <span className='none-throw'>
                                            未通过

                                    </span>
                                        <Tooltip title={solutionContent.rejectReason}>
                                            <svg class="bi bi-chat-square-dots" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                            </svg>
                                        </Tooltip>



                                    </>
                                }
                            </div>
                            <div>
                                <span>审核时间：</span>
                                <span>{solutionContent && solutionContent.reviewedAt}</span>
                            </div>
                            <div>
                                <span>审核管理员：</span>
                                <span>{solutionContent && solutionContent.reviewedBy}</span>
                            </div>
                            <div>
                                <span>发布状态：</span>
                                <Fragment>
                                    {solutionContent && solutionContent.reviewStatus !== 0 && solutionContent.status === 0 && (<span> 待发布 </span>)}
                                    {solutionContent && solutionContent.reviewStatus !== 0 && solutionContent.status === 1 && (<Text type="success"> 已发布 </Text>)}
                                    {solutionContent && solutionContent.reviewStatus !== 0 && solutionContent.status === 2 && (<Text type="warning"> 已下架 </Text>)}
                                </Fragment>
                            </div>
                        </div>
                    }
                    type="warning"
                />
                <Form
                    form={form}
                    className="edit-solution"
                    name="form"
                    {...formItemLayout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="title"
                        label="标题"
                        rules={[{ required: true, message: '标题不能为空' }]}
                    >
                        <Input maxLength={30} placeholder="输入30字内标题"></Input>
                    </Form.Item>

                    {/* <Form.Item
                        name="cover"
                        label="封面图"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra="图片尺寸：500*350px，支持 JPG/PNG格式，大小不超过500KB"
                        rules={[{ required: true, message: '宣传图不能为空' }]}
                    >
                        {!!cover && <div className="solution-cover">
                            <Image
                                width={240}
                                src={getFileUrl(cover)}
                            />
                        </div>}
                        <Upload
                            customRequest={(e) => imageUpload(e, 1)}
                            beforeUpload={(e) => beforeUpload(e, 1)}
                            listType="picture"
                            fileList={[]}
                        >
                            <Button type="primary" ghost icon={<UploadOutlined />}>{cover ? '重新上传' : '本地上传'}</Button>
                        </Upload>
                    </Form.Item> */}

                <Form.Item
                    name="cover"
                    label="封面图"
                    rules={[
                        { required: true, message: '' },
                    ]}
                    help="图片尺寸：500*350px，支持 JPG/PNG格式，大小不超过500KB"
                    className="share-image"
                    >
                        {coverList.length > 0 && <Image height={180} src={coverList[0]} className="share-image-area" />}

                        <div>
                            <ImgCrop
                                aspect={10/7}
                                minZoom={0.1}
                                maxZoom={2}
                                beforeCrop={async (file) => {
                                    const res = await beforeCropCheck(file, true);
                                    return res;
                                }}
                            >
                                <Upload
                                    beforeUpload={(file) =>
                                        beforeUpload(file, 1)
                                    }
                                    fileList={coverList}
                                    customRequest={(e) => imageUpload(e, 1)}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {coverList.length > 0
                                            ? "更改图片"
                                            : "上传图片"}
                                    </Button>
                                </Upload>
                            </ImgCrop>

                        </div>
                    </Form.Item>

                    <Form.Item
                        name="summary"
                        label="摘要"
                        rules={[{ required: true, message: '内容不能为空' }]}
                    >
                        <TextArea rows={4} placeholder="简明扼要地介绍解决方案的基本功能和方法，突出解决方案的核心思想" showCount maxLength={300} />
                    </Form.Item>

                    <Form.Item
                        name="bizScenarios"
                        label="业务场景"
                        rules={[{ required: true, message: '内容不能为空' }]}
                    >
                        <TinymceEditor
                            form={form}
                            fieldValue='bizScenarios'
                            id="bizScenarios"
                            initialValue={dataDetail?.bizScenarios}
                            placeholder="描述解决方案在医院内的具体应用场景，比如应用于某科室的某业务"
                        />
                    </Form.Item>

                    <Form.Item
                        name="industryProblems"
                        label="主要解决的行业问题"
                        rules={[{ required: true, message: '内容不能为空' }]}
                    >
                        <TinymceEditor
                            form={form}
                            fieldValue='industryProblems'
                            id="industryProblems"
                            initialValue={dataDetail?.industryProblems}
                            placeholder="描述解决方案切实解决了行业哪些痛点，能为医院带来明显质量改进或效率提升等正向价值"
                        />
                    </Form.Item>

                    {
                        loading && <>
                            <Form.Item name="advantages" label="创新与优势">
                                <TinymceEditor
                                    form={form}
                                    fieldValue='advantages'
                                    id="advantages"
                                    initialValue={dataDetail?.advantages}
                                    placeholder="描述解决方案在技术、模式等环节具备创新性，譬如国际/国内先进、填补国际/国内空白，领先国内同类技术和产品"
                                />
                            </Form.Item>

                            <Form.Item
                                label="解决方案详细说明"
                            >
                                <h4 style={{ marginTop: '5px' }}>技术功能</h4>
                                <Form.Item name="technologies">
                                    <TinymceEditor
                                        form={form}
                                        fieldValue='technologies'
                                        id="technologies"
                                        initialValue={dataDetail?.technologies}
                                        placeholder="描述解决方案通过一系列功能模块来解决医院客户的痛点问题"
                                    />
                                </Form.Item>

                                <h4>架构图/流程图</h4>
                                <Form.Item name="flowcharts">
                                    <TinymceEditor
                                        form={form}
                                        fieldValue='flowcharts'
                                        id="flowcharts"
                                        initialValue={dataDetail?.flowcharts}
                                        placeholder="可制作解决方案应用架构图，将方案各功能以图形的方式展示出来，使医院更直观的地了解解决方案的结构和功能"
                                    />
                                </Form.Item>

                                <h4>应用实施步骤</h4>
                                <Form.Item name="processes">
                                    <TinymceEditor
                                        form={form}
                                        fieldValue='processes'
                                        id="processes"
                                        initialValue={dataDetail?.processes}
                                        placeholder="解释各个模块的具体实施步骤和注意事项，确保医院客户能够清晰理解解决方案的接入实施"
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item name="benefits" label="客户效益">
                                <TinymceEditor
                                    form={form}
                                    fieldValue='benefits'
                                    id="benefits"
                                    initialValue={dataDetail?.benefits}
                                    placeholder="解决方案应用到医院后，预期可为医院带来的效益和价值，比如促进医院运行效率、提升医疗质量或患者满意度等"
                                />
                            </Form.Item>

                            <Form.Item name="applicationCases" label="应用案例">
                                <TinymceEditor
                                    form={form}
                                    fieldValue='applicationCases'
                                    id="applicationCases"
                                    initialValue={dataDetail?.applicationCases}
                                    placeholder="选取1-3家重点合作的医院客户，展现解决方案在医院的阶段性使用情况，量化医院客户因此所取得的效益。案例结构可参考：1. 医院简介和规模；2. 医院业务需求和痛点；3. 所设计的解决方案思路；4. 医院运行现状；5. 医院获益"
                                />
                            </Form.Item>

                            <Form.Item name="honors" label="所获荣誉">
                                <TinymceEditor
                                    form={form}
                                    fieldValue='honors'
                                    id="honors"
                                    initialValue={dataDetail?.honors}
                                    placeholder="列举企业机构以往获得的行业荣誉，含产品注册认证等，需有可查询的链接"
                                />
                            </Form.Item>
                        </>
                    }

                    {/* <Form.Item
                        name="attachment"
                        label="解决方案附件"
                        valuePropName="fileList"
                        extra="附件文件供用户下载查看快速了解该解决方案，支持 PDF/PPT/PPTX/DOC/DOCX 格式，大小不超过30MB"
                    >
                        {attachment &&
                            <Form.Item name={['attachment', 'fileName']} rules={[{ required: true, message: '附件标题不能为空' }]} style={{ marginBottom: 0 }}>
                                <div className="solution-attachment">
                                    {!!(attachment.ext === 'pdf') && <div className="docIcon" title="PDF 文件">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                        </svg>
                                    </div>}
                                    {!!(attachment.ext === 'doc' || attachment.ext === 'docx') &&
                                        <div className="docIcon" title="Word 文件">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-word" viewBox="0 0 16 16">
                                                <path d="M4.879 4.515a.5.5 0 0 1 .606.364l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 7.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 0 1 .364-.606z" />
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            </svg>
                                        </div>}
                                    {!!(attachment.ext === 'ppt' || attachment.ext === 'pptx') &&
                                        <div className="docIcon" title="PowerPoint 文件">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-ppt" viewBox="0 0 16 16">
                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                                                <path d="M6 5a1 1 0 0 1 1-1h1.188a2.75 2.75 0 0 1 0 5.5H7v2a.5.5 0 0 1-1 0V5zm1 3.5h1.188a1.75 1.75 0 1 0 0-3.5H7v3.5z" />
                                            </svg>
                                        </div>}
                                    <Input defaultValue={attachment.fileName} style={{ width: '385px', marginRight: '5px' }} onChange={attachmentChange} showCount maxLength={30} />
                                    <a onClick={() => attachmentPreview(attachment.path, attachment.ext)} ><EyeOutlined />预览</a>
                                    <a onClick={removeAttachment}><MinusCircleOutlined />移除</a>
                                </div>
                            </Form.Item>
                        }
                        <Upload
                            customRequest={(e) => imageUpload(e, 2)}
                            fileList={[]}
                            beforeUpload={(e) => beforeUpload(e, 3)}
                        >
                            {!attachment && <Button type="primary" ghost icon={<UploadOutlined />}>上传文件</Button>}
                        </Upload>
                    </Form.Item> */}

                    <Form.Item
                        name="video"
                        label="解决方案视频介绍"
                        valuePropName="fileList"
                        extra="支持 MP4 格式，大小不超过200MB"
                    >
                        {videoList && <div id="J_prismPlayer"></div>}
                        {videoList &&
                            <Form.Item name={["video", "title"]} style={{ marginBottom: 0 }} rules={[{ required: true, message: '视频描述不能为空' }]}>
                                <div className="solution-video">
                                    <Input defaultValue={videoList.title} style={{ width: '400px', marginRight: '5px' }} onChange={videoTitleChange} showCount maxLength={30} />
                                    <a onClick={removeVideo}><MinusCircleOutlined />移除</a>
                                </div>
                            </Form.Item>
                        }
                        {!videoList && <Upload {...videoProps} maxCount={1} fileList={[]}>
                            <Button type="primary" ghost icon={<UploadOutlined />}>上传视频</Button>
                        </Upload>}
                        {showProgress && <Progress percent={progressPercent} />}
                    </Form.Item>

                    <Form.Item
                        name="images"
                        label="解决方案图集"
                        valuePropName="fileList"
                        extra="支持 JPG/PNG 格式，最多上传6张图片，单张图片大小不超过2MB"
                    >
                        {
                            images && images.length ?
                                <Form.Item name={["images", "desc"]} style={{ marginBottom: 0 }} rules={[{ required: false, message: '图片描述不能为空' }]}>
                                    <Input style={{ width: '450px', marginBottom: '10px' }} placeholder="输入解决方案图集整体说明" showCount maxLength={30} />
                                </Form.Item> : null
                        }
                        <Form.List name={["images", "images"]}>
                            {(fields, { add, remove, move }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <div key={key} className="images-add">
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                }}
                                                align="center"
                                            >
                                                <div className="image-container">
                                                    <Image
                                                        src={getFileUrl(images[index])}
                                                    />
                                                </div>
                                                {index !== 0 ? <a onClick={() => {
                                                    move(index, index - 1);
                                                    let fieldValues = form.getFieldValue('images') || {};
                                                    setImages(fieldValues.images);
                                                }}>
                                                    <ArrowUpOutlined />上移
                                            </a> : null}
                                                {index !== fields.length - 1 ? <a onClick={() => {
                                                    move(index, index + 1);
                                                    let fieldValues = form.getFieldValue('images') || {};
                                                    setImages(fieldValues.images);
                                                }}>
                                                    <ArrowDownOutlined />下移
                                            </a> : null}
                                                <a onClick={() => {
                                                    remove(name);
                                                    let fieldValues = form.getFieldValue('images') || {};
                                                    setImages(fieldValues.images);
                                                }}>
                                                    <MinusCircleOutlined />移除
                                            </a>
                                            </Space>
                                        </div>
                                    ))}
                                    {
                                        !images || (images && images.length < 6) ?
                                            <Upload
                                                customRequest={(e) => imageUpload(e, 3)}
                                                beforeUpload={(e) => beforeUpload(e, 2)}
                                                listType="picture"
                                                fileList={[]}
                                            >
                                                <Button type="primary" ghost icon={<UploadOutlined />}>上传图片</Button>
                                            </Upload> : null
                                    }
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item
                        name="docs"
                        label="解决方案文档"
                        valuePropName="fileList"
                        extra="解决方案详细说明文档，用于平台展示，支持 PDF/PPT/PPTX/DOC/DOCX 格式，最多上传3个文档，单个文档大小不超过30MB"
                    >
                        {
                            docs && docs.length ?
                                <Form.Item name={["docs", "desc"]} style={{ marginBottom: 0 }} rules={[{ required: false, message: '文档描述不能为空' }]}>
                                    <Input style={{ width: '450px', marginBottom: '10px' }} showCount placeholder="输入解决方案文档整体说明" maxLength={30} />
                                </Form.Item> : null
                        }
                        <Form.List name={["docs", "docs"]}>
                            {(fields, { add, remove, move }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <div key={key} className="docs-add">
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                }}
                                                align="center"
                                            >
                                                <Form.Item name={[name, "fileName"]} style={{ marginBottom: 0 }} rules={[{ required: true, message: '文档标题不能为空' }]}>
                                                    <div className="docIconBox">
                                                        {!!(docs[index]?.ext === 'pdf') && <div className="docIcon" title="PDF 文件">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
                                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                                                <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                                            </svg>
                                                        </div>}
                                                        {!!(docs[index]?.ext === 'doc' || docs[index]?.ext === 'docx') &&
                                                            <div className="docIcon" title="Word 文件">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-word" viewBox="0 0 16 16">
                                                                    <path d="M4.879 4.515a.5.5 0 0 1 .606.364l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 7.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 0 1 .364-.606z" />
                                                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                                                </svg>
                                                            </div>}
                                                        {!!(docs[index]?.ext === 'ppt' || docs[index]?.ext === 'pptx') &&
                                                            <div className="docIcon" title="PowerPoint 文件">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-ppt" viewBox="0 0 16 16">
                                                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                                                                    <path d="M6 5a1 1 0 0 1 1-1h1.188a2.75 2.75 0 0 1 0 5.5H7v2a.5.5 0 0 1-1 0V5zm1 3.5h1.188a1.75 1.75 0 1 0 0-3.5H7v3.5z" />
                                                                </svg>
                                                            </div>}
                                                        <Input defaultValue={docs[index]?.fileName} style={{ width: '385px' }} placeholder="请输入文档标题" showCount maxLength={30} />
                                                        <a onClick={() => attachmentPreview(docs[index]?.path, docs[index]?.ext)}><EyeOutlined />预览</a>
                                                        <a onClick={() => {
                                                            remove(name);
                                                            let fieldDocValues = form.getFieldValue('docs') || {};
                                                            setDocs(fieldDocValues.docs)
                                                        }}>
                                                            <MinusCircleOutlined />移除
                                                    </a>
                                                    </div>
                                                </Form.Item>
                                            </Space>
                                        </div>
                                    ))}
                                    {
                                        !docs || (docs && docs.length < 3) ?
                                            <Upload
                                                customRequest={(e) => imageUpload(e, 4)}
                                                beforeUpload={(e) => beforeUpload(e, 3)}
                                                fileList={[]}
                                            >
                                                <Button type="primary" ghost icon={<UploadOutlined />}>上传文档</Button>
                                            </Upload> : null
                                    }
                                </>
                            )}
                        </Form.List>
                    </Form.Item>


                    <Form.Item
                        name="applicationScenarios"
                        label="应用场景"
                        rules={[{ required: true, message: '请选择' }]}
                    >
                        <Cascader
                            placeholder='请选择'
                            multiple
                            options={applicationScenarios}
                            showCheckedStrategy={SHOW_CHILD}
                            showArrow
                            showSearch={false}
                            allowClear
                            displayRender={(v,l)=>{
                                return `${v[0]}-${v[1]}`
                            }}
                        ></Cascader>
                    </Form.Item>
                    <Form.Item
                        name="innovativeTechnologies"
                        label="创新技术"
                    >
                        <Select mode="multiple" optionLabelProp="label" options={innovativeTechnologiesList} placeholder='请选择' showArrow showSearch={false} allowClear></Select>
                    </Form.Item>
                    <Form.Item
                        name="enterpriseVolume"
                        label="企业规模"
                        rules={[{ required: true, message: '请选择' }]}
                    >
                        <Select optionLabelProp="label" options={enterpriseVolumeList} placeholder='请选择'></Select>
                    </Form.Item>
                    <Form.Item
                        name="isGry"
                        label="金如意奖"
                        rules={[{ required: true, message: '请选择' }]}
                    >
                        <Select optionLabelProp="label" options={isGryList} placeholder='请选择'></Select>
                    </Form.Item>

                    {/* 管理端不用添加（产品确定） */}
                    {/* <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Checkbox checked={checkNick} onChange={onCheckboxChange}>我已阅读并同意</Checkbox>
                        <Button type="link" className="copyright-notice" onClick={onInfo}>内容版权声明</Button>
                        {showInfo && <p className="show-info">请先勾选我已阅读并同意内容版权声明</p>}
                    </Form.Item> */}

                    <div className="footer-btn">
                        <Button style={{ marginRight: '20px' }} onClick={onPreview}>预览</Button>
                        <Button style={{ marginRight: '20px' }} onClick={() => navigate(-1)}>取消</Button>
                        <Spin spinning={spinLoading}>
                            <Button type="primary" onClick={onSave} style={{ marginRight: '20px' }}>保存</Button>
                        </Spin>
                        {solutionContent && solutionContent.reviewStatus === 0 && <Button type="primary" style={{ marginRight: '20px' }} onClick={() => setAudit(true)}>审核</Button>}
                    </div>

                </Form>
            </div>
        </Content>

        <Modal
            title='审核解决方案'
            open={audit}
            onCancel={() => resetValue()}
            onOk={() => onReview()}
            destroyOnClose
        >
            <Form
                form={auditForm}
                layout="vertical"
            >
                <Form.Item
                    label='审核意见'
                    name='reviewStatus'
                    rules={[
                        {
                            required: true,
                            message: '请选择审核意见',
                        },
                    ]}
                >
                    <Radio.Group onChange={(e) => changeReason(e)}>
                        <Radio value={1}>通过</Radio>
                        <Radio value={2}>驳回</Radio>
                    </Radio.Group>
                </Form.Item>
                {radioValue === 2 && <Form.Item label='驳回理由'
                    name='rejectReason'
                    rules={[
                        {
                            required: true,
                            message: '请填写驳回理由',
                        },
                    ]}>
                    <TextArea />
                </Form.Item>}

            </Form>
        </Modal>

        <Modal
            title="预览"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            centered
            width='100vw'
            style={{ maxWidth: '1600px', minWidth: '800px' }}
        >
            <iframe
                id="preview"
                src={`${config.SOLUTION_DOMAIN}/solutions/000?preview=1&initId=${params.id}`}
                style={{ width: '100%', height: '80vh' }}
                frameBorder="0"
            />
        </Modal>
    </>
};

export default EditSolution;
