import React,{useState,useEffect}from "react";
import { Form, Modal, Upload,Input,message, Image} from "antd";
import { PlusOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import closeIcon from "@static/images/wj-close.png";
import './index.less';
import { uploadFile } from "@services/Wj";
import config from "@config";
import weixin from '@static/images/share/weixinLogo.png';
import { useLocation } from 'react-router-dom';
import { saveSettingApi, querySettingApi } from '@services/Wj/Question';

const Weixin = ({ weixinOpen, setWeixinOpen, shareTitle,setShareTitle}) => {
    const [fileList, setFileList] = useState([]);
    const [shareDescription,setShareDescription] = useState(undefined);
    const [change, setChange] = useState(true);
    const option = { headers: { "Content-Type": "multipart/form-data" } };
    const [form] = Form.useForm();
    const location = useLocation();
    const { TextArea } = Input;

    const getFileUrl = (url) => {
        if(url && url.includes('static')){
            return url;
        }else if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + `/${url}`;
        }
        return null;
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式")
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error("头像大小不超过5MB");
        }
        return isJpgOrPng && isLt5M;
        // return isJpgOrPng && isLt5M && fn(file, true);
    };


    const upload = async (value) => {
        const formData = new FormData();
        formData.append("file", value.file);
        formData.append("path", `skins/upload/`);
        const res = await uploadFile(formData, option);
        if (res.status_code === 200) {
            form.setFieldValue('shareLogoUrl',res.data)
            setFileList([{ url: config.OSS_PREFIX + `/${res.data}`, }]);
           form.setFields([
                {
                    name: 'shareLogoUrl', errors: null
                }
            ])
        }
    };
    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 4 * 1024 * 1024) {
            message.error('图片大小不能超过4MB。');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    // if (imgDom.width < 750 || imgDom.height < 422) {
                    //     bool && message.warn('案例封面不小于750×422px。');
                    //     resolve(false);
                    // } else {
                        resolve(true);
                    // }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    const delCustom=()=>{
        setFileList([])
    };

    const setShare=()=>{
        form.validateFields().then(res=>{
            saveSettingApi({ ...form.getFieldsValue(),surveyId: location.state.id, type: location.state.route === 'newCreate' ? 1: 2 }).then((res) => {
                if (res.status_code === 200) {
                    settingStatus()
                    setWeixinOpen(false)
                    setShareTitle(form.getFieldValue('shareTitle'))
                }
            })
        })
    };
    const settingStatus =()=>{
        querySettingApi({ id: location.state.id, type: location.state.route === 'newCreate' ? 1: 2 }).then(
            (res) => {
                if (res.status_code === 200) {
                    setShareTitle(res.data.shareTitle)
                    setShareDescription(res.data.shareDirections||'希望大家能够踊跃参加')
                    setFileList([{url:res.data.shareLogoUrl||weixin}])
                    form.setFieldValue('shareTitle',res.data.shareTitle)
                    form.setFieldValue('shareDirections',res.data.shareDirections||'希望大家能够踊跃参加')
                    form.setFieldValue('shareLogoUrl',res.data.shareLogoUrl||weixin)
                }
            })
            setChange(false)
    };


    useEffect(()=>{
        if(weixinOpen){
            settingStatus()
        }
    },[weixinOpen])
    return (
        <Modal
            title="分享到微信"
            open={weixinOpen}
            onOk={setShare}
            onCancel={() => setWeixinOpen(false)}
        >
            <Form  layout="vertical" form={form}>
            <Form.Item
                    label="自定义图标"
                    className="custom-icon"
                    name="shareLogoUrl"
                rules={[
                {
                    required: true,
                    message: '',
                },
                    {
                    validator: async (rule, value) => {
                        if (fileList.length === 0) {
                        throw "请添加图标";
                        }
                    },
                    },
                ]}
                >
                    {fileList.length === 1 ?
                        <div className="upload-area">
                            <Image className="upload-image" src={getFileUrl(fileList[0]?.url) || weixin}/>
                            <img className='del-upload-area' src={closeIcon} onClick={delCustom}/>
                        </div>:
                        <ImgCrop
                        aspect={1 / 1}
                        rotate
                        minZoom={0.1}
                        maxZoom={2}
                        beforeCrop={async (file) => {
                            const res = await beforeCropCheck(file, true);
                            return res;
                        }}
                    >
                        <Upload
                            customRequest={upload}
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                        >

                                <div className="upload-area">
                                    <div>
                                    <PlusOutlined className="upload-icon"/>
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        上传图标
                                    </div>
                                    </div>
                                </div>


                        </Upload>

                    </ImgCrop>
                    }

                    <span className="custom-icon-helper">(图片支持png和jpg格式，建议尺寸 80*80，不超过4M)</span>
                </Form.Item>
                <Form.Item label="微信分享标题"
                name="shareTitle"
                rules={[
                {
                    required: true,
                    message: '请输入微信分享标题',
                },
                ]}
                >
                    <Input value={shareTitle} onChange={()=>{setChange(true)}}  maxLength={50} showCount/>
                </Form.Item>
                <Form.Item label="微信分享描述"
                name="shareDirections"
                rules={[
                {
                    required: true,
                    message: '请输入微信分享描述',
                },
                ]}
                >
                <TextArea rows={2} autoSize={{minRows: 2,maxRows: 2}} value={shareDescription} onChange={(e)=>setShareDescription(e.target.value)} maxLength={40} showCount/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Weixin;
