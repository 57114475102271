import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    Layout,
    Space,
    Button,
    message
} from 'antd';
import './index.less';
import TinymceEditor from '../TinymceEditor';
import { moduleUpdateApi, moduleGetByIdApi } from '@services/MedProject';
import config from '@config';


const { Content } = Layout;

export default function ModulesDetails() {
    const navigate = useNavigate();
    const params = useParams();

    const id = params?.id || 0;
    const projectId = params?.projectId || 0;

    const [details, setDetails] = useState(null);
    const [editorContents, setEditorContents] = useState('');
    const [initEditorValue, setInitEditorValue] = useState(null);

    const handleOk = async () => {
        const reqParams = {
            id: details.id,
            moduleType: details.moduleType,
            name: details.name,
            content: editorContents,
            projectId: details.projectId,
            isShowHot: details.isShowHot,
            isShowTab: details.isShowTab
        };
        const res = await moduleUpdateApi(reqParams);
        if(res && res.status_code === 200){
            message.success('保存成功');
        }
    };

    const getDetails = async () => {
        const res = await moduleGetByIdApi({id});
        if(res && res.status_code === 200){
            setDetails(res.data);
            setEditorContents(res.data.content);
            setInitEditorValue(res.data.content);
        }
    };


    useEffect(() => {
        getDetails();
    }, [id]);


    return (
        <>
            <Content className="main-content-box">

                <div className="main-content-body module-detail-body">

                    <div className="med-project-module-head">
                        <div className="module-title">
                            <h2>项目详情</h2>
                            <span className="tips">编辑内容使用的一级标题‘H1’，会在用户端做为目录导航呈现。如需上传图片，建议图片宽度保持1700 px，图片两侧不建议留白。</span>
                        </div>
                        <div className="preview">
                            <Button
                                type="link"
                                size="small"
                                ghost
                                style={{padding: 0}}
                                onClick={() => window.open(`${config.PROJECT_DOMAIN}/p/${projectId}/info/${details.id}`)}
                                icon={<>
                                    <span className="anticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                                        </svg>
                                    </span>
                                </>}
                            >
                                <span>预览</span>
                            </Button>
                        </div>
                    </div>

                    <Space className='main-details'>
                        <TinymceEditor
                            initEditorValue={initEditorValue}
                            setEditorContents={setEditorContents}
                            editorContents={editorContents}
                            id="editorContents"
                            placeholder="编辑内容使用的一级标题‘H1’，会在用户端做为目录导航呈现。如需上传图片，建议图片宽度保持1700px，图片两侧不建议留白。"
                        />
                    </Space>

                    <div className='main-footer'>
                        <Button type="primary" onClick={()=>handleOk()}>保存</Button>
                    </div>

                </div>

            </Content>

        </>
    );
}
