import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserGroupsFilterForm from './UserGroupsFilterForm';
import { Button, Layout, Table, Modal, Space, message, Dropdown, Menu, Spin } from 'antd';
import { ExclamationCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { timeString } from '@/utils/utils';
import { fullPagerConfig } from '@/utils/property';
import { get, put } from '@/core/request';

const { Content } = Layout;

export default function UserGroups() {
    const navigate = useNavigate()

    // 渲染table数据的state
    const [tableData, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});

    // 分页设置
    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    // 筛选数据
    const handleFilterFinish = (value) => {
        setFilterObj(value);
        setCurrPage(1);
    };

    // 改变一页有多少数据
    const changePageSize = useCallback(
        (pageSize) => {
            setPageSize(pageSize)
        },
        [],
    );

    // 改变第几页
    const changeCurrPage = useCallback(
        (page) => {
            setCurrPage(page);
        },
        [],
    );

    const { name, createStaffPhone, status, createdBeginAt, createdEndAt } = filterObj;

    const [pedding, setPedding] = useState(true);
    const getData = () => {
        setPedding(true)
        get(`/user/data/user-group/page`, {
            limit: pageSize,
            page: currPage,
            name, createStaffPhone, status, createdBeginAt, createdEndAt
        }).then((res) => {
            if (res.status_code === 200) {
                if (res.data) {
                    res.data.list.forEach(item => {
                        item.key = item.id;
                    });
                }
                setPedding(false)
                setTableData(res.data);
            }
        })
    };

    // 渲染table数据
    useEffect(() => {
        getData()
    }, [pageSize, currPage, name, createStaffPhone, status, createdBeginAt, createdEndAt]);

    // 创建人
    const [createdPhone, setCreatedPhone] = useState([])
    // 创建人
    useEffect(() => {
        get('/user/data/findAll').then((res) => {
            setCreatedPhone(res.data)
        })
    }, []);

    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: 'small',
        total: tableData.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => { changeCurrPage(page) },
        current: currPage,
    };

    const [refreshBool, setRefreshBool] = useState(false);
    // 点击刷新
    const handleRefresh = () => {
        message.info('正在刷新', 1);
        setRefreshBool(true);
    }

    useEffect(() => {
        if (refreshBool) {
            changeCurrPage(1);
            setRefreshBool(false);
        }
    }, [refreshBool])

    // 点击操作区禁用
    const stopConfirm = (row) => {
        return () => {
            Modal.confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '是否要启用/禁用选中标签',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let status = 1;
                    if (row.status === 1) {
                        status = 0;
                    }
                    put('/user/data/user-group/status', {
                        id: row.id,
                        status,
                        name: row.name
                    }).then(res => {
                        if (res.status_code === 200) {
                            message.success(res.message);
                            getData()
                        }
                    });
                }
            });
        }
    };

    // 点击新增用户分群
    const handleClickAddUserGroups = () => {
        navigate('/uc/user-groups/new', { state: { tip: 'add' } });
    }

    // 点击新增用户分群
    const handleClickEditUserGroups = (row) => {
        return () => {
            navigate('/uc/user-groups/new', { state: { tip: 'edit', row } });
        }
    }

    // 导出数据
    const handleExportFile = (row) => {
        return () => {
            if (row.number >= 100000) {
                message.info("只能导出10万条数据")
            }
            get(`/user/data/user-group/download`, {
                id: row.id
            }, { responseType: 'blob' }).then((res) => {
                if (res.size !== 0) {
                    let date = new Date();
                    let dateString = timeString(date);
                    message.success('请稍后，正在导出');
                    const fileName = `${row.name}${dateString}.xlsx`;
                    let a = document.createElement('a');
                    let event = new MouseEvent('click');
                    a.download = fileName;
                    let objectUrl = URL.createObjectURL(res);
                    a.href = objectUrl;
                    a.dispatchEvent(event);
                    URL.revokeObjectURL(objectUrl);
                } else {
                    message.warning('当前列表没有数据，无法导出')
                }
            })
        }
    };

    // table 数据相关
    const columns = [
        { title: '分群ID', dataIndex: 'id', key: 'id' },
        {
            title: '分群名称', dataIndex: 'name',
            render: (operates, row) => (<Fragment>
                <div>{row.name}</div>
            </Fragment>)
        },
        { title: '用户数', dataIndex: 'number', },
        { title: '创建人', dataIndex: 'createdByStaffName', },
        {
            title: '状态', dataIndex: 'status',
            render: (operates) => (<span className={operates === 0 ? "close-status" : "open-status"}>{operates === 0 ? '禁用' : '启用'}</span>)
        },
        {
            title: '操作', dataIndex: 'operate',
            render: (operates, row) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="download" onClick={handleExportFile(row)}>导出分群用户</Menu.Item>
                        <Menu.Item key="status" onClick={stopConfirm(row)}>{row.status === 0 ? '启用' : '禁用'}</Menu.Item>
                    </Menu>
                )
                return (
                    <Dropdown.Button onClick={handleClickEditUserGroups(row)} overlay={menu} size='small'>
                        详情
                    </Dropdown.Button>
                )
            }
        },
    ];
    return (
        <Fragment>
            <div className="page-title">用户分群</div>

            <Content className="main-content-box">
                <div className="filter-form-box">
                    <UserGroupsFilterForm
                        handleFilterFinish={handleFilterFinish}
                        createdPhone={createdPhone}
                    />
                </div>

                <div className="main-content-body">

                    <div className="table-caption">
                        <div className="table-toolbar">
                            <Space>
                                <Button type="primary" onClick={handleClickAddUserGroups}>新增用户分群</Button>
                                <RedoOutlined className="refresh-icon" onClick={handleRefresh} />
                            </Space>
                        </div>
                    </div>

                    <Spin spinning={pedding} delay={500}>
                        <Table
                            className="enterprise-table"
                            columns={columns}
                            dataSource={tableData && tableData.list}
                            pagination={paginationProps}
                        />
                    </Spin>


                </div>
            </Content>

        </Fragment>
    )
}
