import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Button,
    Row,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Radio,
    Select,
    Space,
    Drawer,
    Tag,
    Tooltip,
    Typography, Dropdown, Modal,
} from 'antd';
import { isEmpty } from 'lodash';
import { fetchGuestJourney, saveGuestJourney, updateGuestJourneyIdCard, extractGuestJourneyInfo } from '@services/Guest';
import { dateTimeFormat, safeParseJSON, convertDateTimeRangeToLocalText } from '@utils/utils';
import { expertLevels, cardTypes } from '@utils/constants';
import './index.less';
import { HotelList } from '../../../constants';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function GuestJourney(props) {
    const {
        visibleGuestJourney,
        getGuestJourneyList,
        currentGuestJourney,
        setCurrentGuestJourney,
        onClose,
        conferenceId,
        expertId,
    } = props;
    console.log(conferenceId, expertId);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [expert, setExpert] = useState(null);
    const [agendaTasks, setAgendaTasks] = useState(null);

    const [hotels, setHotels] = useState(HotelList);
    const [roomTypes, setRoomTypes] = useState(['双床房', '大床房', '套房']);

    const [roomNightNum, setRoomNightNum] = useState(0);

    const [visibleAIModal, setVisibleAIModal] = useState(false);

    const [aiInputForm] = Form.useForm();
    const [loadingAiInput, setLoadingAiInput] = useState(false);

    const [aiOutputInfo, setAiOutputInfo] = useState(null);
    const [aiOutputErr, setAiOutputErr] = useState('');

    const emptyText = () => <Text type="secondary">&ndash;</Text>

    const onOpenAIModal = () => {
        console.log(aiOutputInfo);
        setVisibleAIModal(true);
    };

    const onCloseAIModal = () => {
        setVisibleAIModal(false);
        aiInputForm.resetFields();
        setAiOutputInfo(null);
        setAiOutputErr('');

        setLoadingAiInput(false);
    };

    const extractJourneyInfo = async () => {
        const journeyInfo = aiInputForm.getFieldValue('input-text');
        if (!isEmpty(journeyInfo)) {
            setLoadingAiInput(true);

            try {
                /**
                 * {
                 *     "status_code": 200,
                 *     "message": "操作成功",
                 *     "data": "{\"choices\":[{\"message\":{\"role\":\"assistant\",\"content\":\"```json\\n{\\n    \\\"guestInfo\\\": {\\n        \\\"name\\\": \\\"唐熠达\\\"\\n    },\\n    \\\"arrivalInfo\\\": {},\\n    \\\"returnInfo\\\": {\\n        \\\"traffic\\\": {\\n            \\\"traffic_type\\\": 1,\\n            \\\"traffic_no\\\": \\\"CA1352\\\",\\n            \\\"departure_datetime\\\": \\\"2024-12-06 12:40\\\",\\n            \\\"arrival_datetime\\\": \\\"2024-12-06 15:40\\\",\\n            \\\"departure_place\\\": \\\"广州白云国际机场T1\\\",\\n            \\\"arrival_place\\\": \\\"北京首都国际机场T3\\\"\\n        },\\n        \\\"pickup_dropoff\\\": {\\n            \\\"need_pickup_dropoff\\\": 0\\n        },\\n        \\\"remarks\\\": null\\n    },\\n    \\\"hotelInfo\\\": {}\\n}\\n```\"},\"finish_reason\":\"stop\",\"index\":0,\"logprobs\":null}],\"object\":\"chat.completion\",\"usage\":{\"prompt_tokens\":1389,\"completion_tokens\":166,\"total_tokens\":1555},\"created\":1734768999,\"system_fingerprint\":null,\"model\":\"qwen-turbo-latest\",\"id\":\"chatcmpl-80e74099-82e4-949b-bd65-1c647d1e663c\"}"
                 * }
                 */
                const res = await extractGuestJourneyInfo({ content: journeyInfo });
                if (res.status_code === 200) {
                    // debugger;
                    const journeyInfo = res.data;
                    const outputJson = JSON.parse(journeyInfo) || {};
                    console.log(outputJson);
                    let journeyJsonStr = outputJson?.choices?.[0]?.message?.content || '';
                    journeyJsonStr = journeyJsonStr?.replace(/.*```(?:json|js)(.*)```.*/isg, '$1') || '';
                    const journeyJson = JSON.parse(journeyJsonStr) || {};
                    console.log(journeyJson);
                    setAiOutputInfo(journeyJson);

                    if (isEmpty(journeyJson)) {
                        setAiOutputErr('暂无有效信息。');
                    } else {
                        setAiOutputErr('');
                    }

                    setLoadingAiInput(false);
                } else {
                    console.log(res);
                    setLoadingAiInput(false);
                    setAiOutputErr('解析失败，请完善嘉宾信息文字描述后重试。')
                }
            } catch (e) {
                setLoadingAiInput(false);
            }
        } else {
            message.warning('没有要解析的嘉宾行程信息');
        }
    };

    const updateGuestJourneyInfo = () => {
        console.log(aiOutputInfo);
        const data = safeParseJSON(JSON.stringify(aiOutputInfo));

        delete data.guestInfo;

        if (data?.arrivalInfo?.traffic?.traffic_type === 0) {
            delete data?.arrivalInfo?.traffic?.traffic_no;
            delete data?.arrivalInfo?.traffic?.departure_datetime;
            delete data?.arrivalInfo?.traffic?.departure_place;
            delete data?.arrivalInfo?.traffic?.arrival_datetime;
            delete data?.arrivalInfo?.traffic?.arrival_place;
        }

        if (data?.arrivalInfo?.pickup_dropoff?.need_pickup_dropoff === 0) {
            delete data?.arrivalInfo?.pickup_dropoff?.driver_name;
            delete data?.arrivalInfo?.pickup_dropoff?.driver_phone;
            delete data?.arrivalInfo?.pickup_dropoff?.driver_car_color;
            delete data?.arrivalInfo?.pickup_dropoff?.driver_car_no;
            delete data?.arrivalInfo?.pickup_dropoff?.departure_datetime;
            delete data?.arrivalInfo?.pickup_dropoff?.departure_place;
            delete data?.arrivalInfo?.pickup_dropoff?.arrival_datetime;
            delete data?.arrivalInfo?.pickup_dropoff?.arrival_place;
        }

        if (data?.returnInfo?.traffic?.traffic_type === 0) {
            delete data?.returnInfo?.traffic?.traffic_no;
            delete data?.returnInfo?.traffic?.departure_datetime;
            delete data?.returnInfo?.traffic?.departure_place;
            delete data?.returnInfo?.traffic?.arrival_datetime;
            delete data?.returnInfo?.traffic?.arrival_place;
        }

        if (data?.returnInfo?.pickup_dropoff?.need_pickup_dropoff === 0) {
            delete data?.returnInfo?.pickup_dropoff?.driver_name;
            delete data?.returnInfo?.pickup_dropoff?.driver_phone;
            delete data?.returnInfo?.pickup_dropoff?.driver_car_color;
            delete data?.returnInfo?.pickup_dropoff?.driver_car_no;
            delete data?.returnInfo?.pickup_dropoff?.departure_datetime;
            delete data?.returnInfo?.pickup_dropoff?.departure_place;
            delete data?.returnInfo?.pickup_dropoff?.arrival_datetime;
            delete data?.returnInfo?.pickup_dropoff?.arrival_place;
        }

        if (data?.hotelInfo?.hotel?.need_hotel === 0) {
            delete data?.hotelInfo?.hotel?.checkin_date;
            delete data?.hotelInfo?.hotel?.checkout_date;
            delete data?.hotelInfo?.hotel?.hotel_name;
            delete data?.hotelInfo?.hotel?.hotel_address;
            delete data?.hotelInfo?.hotel?.hotel_room_type;
            delete data?.hotelInfo?.hotel?.hotel_room_no;
        }

        // 来程交通-出发-抵达时间
        if (data?.arrivalInfo?.traffic?.departure_datetime && moment(data.arrivalInfo.traffic.departure_datetime).isValid()) {
            data.arrivalInfo.traffic.departure_datetime = moment(data.arrivalInfo.traffic.departure_datetime);
        }
        if (data?.arrivalInfo?.traffic?.arrival_datetime && moment(data.arrivalInfo.traffic.arrival_datetime).isValid()) {
            data.arrivalInfo.traffic.arrival_datetime = moment(data.arrivalInfo.traffic.arrival_datetime);
        }

        // 来程接送-出发-抵达时间
        if (data?.arrivalInfo?.pickup_dropoff?.departure_datetime && moment(data.arrivalInfo.pickup_dropoff.departure_datetime).isValid()) {
            data.arrivalInfo.pickup_dropoff.departure_datetime = moment(data.arrivalInfo.pickup_dropoff.departure_datetime);
        }
        if (data?.arrivalInfo?.pickup_dropoff?.arrival_datetime && moment(data.arrivalInfo.pickup_dropoff.arrival_datetime).isValid()) {
            data.arrivalInfo.pickup_dropoff.arrival_datetime = moment(data.arrivalInfo.pickup_dropoff.arrival_datetime);
        }

        // 返程交通-出发-抵达时间
        if (data?.returnInfo?.traffic?.departure_datetime && moment(data.returnInfo.traffic.departure_datetime).isValid()) {
            data.returnInfo.traffic.departure_datetime = moment(data.returnInfo.traffic.departure_datetime);
        }
        if (data?.returnInfo?.traffic?.arrival_datetime && moment(data.returnInfo.traffic.arrival_datetime).isValid()) {
            data.returnInfo.traffic.arrival_datetime = moment(data.returnInfo.traffic.arrival_datetime);
        }

        // 返程接送-出发-抵达时间
        if (data?.returnInfo?.pickup_dropoff?.departure_datetime && moment(data.returnInfo.pickup_dropoff.departure_datetime).isValid()) {
            data.returnInfo.pickup_dropoff.departure_datetime = moment(data.returnInfo.pickup_dropoff.departure_datetime);
        }
        if (data?.returnInfo?.pickup_dropoff?.arrival_datetime && moment(data.returnInfo.pickup_dropoff.arrival_datetime).isValid()) {
            data.returnInfo.pickup_dropoff.arrival_datetime = moment(data.returnInfo.pickup_dropoff.arrival_datetime);
        }

        // 住宿信息-入住-退房-日期
        if (data?.hotelInfo?.hotel?.checkin_date && moment(data.hotelInfo.hotel.checkin_date).isValid()) {
            data.hotelInfo.hotel.checkin_date = moment(data.hotelInfo.hotel.checkin_date);
        }
        if (data?.hotelInfo?.hotel?.checkout_date && moment(data.hotelInfo.hotel.checkout_date).isValid()) {
            data.hotelInfo.hotel.checkout_date = moment(data.hotelInfo.hotel.checkout_date);
        }

        console.log(data);
        form.setFieldsValue(data);

        aiInputForm.resetFields();
        setVisibleAIModal(false);
        setAiOutputInfo(null);

        Modal.success({
            centered: true,
            content: 'AI解析的嘉宾行程信息已成功填写，请确认信息后点击“确定”完成信息更新。'
        });
    };

    const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };

    let checkinDate = '';
    let checkoutDate = '';
    if (currentGuestJourney && currentGuestJourney?.minDate && currentGuestJourney.maxDate) {
        const minDate = moment(currentGuestJourney?.minDate);
        const maxDate = moment(currentGuestJourney?.maxDate);
        if (minDate.isValid() && maxDate.isValid()) {
            const diffDays = maxDate.diff(minDate, 'days');
            if (diffDays >= 1) {
                checkinDate = minDate.format('YYYY年M月D日');
                checkoutDate = maxDate.format('YYYY年M月D日');
            }
        }
    }

    const onChangeHotelDates = (date, dateStr) => {
        const checkinDate = form.getFieldValue(['hotelInfo', 'hotel', 'checkin_date']);
        const checkoutDate = form.getFieldValue(['hotelInfo', 'hotel', 'checkout_date']);
        console.log(checkinDate, checkoutDate);

        let num = 0;
        if (checkinDate && checkinDate.isValid() && checkoutDate && checkoutDate.isValid()) {
            num = checkoutDate.diff(checkinDate, 'days');
        }
        setRoomNightNum(num);
    };

    const onChangeArrivalTrafficDepartureDateTime = (date, dateStr) => {
        console.log(date);
        if (!form.getFieldValue(['hotelInfo', 'hotel', 'checkin_date'])) {
            form.setFieldValue(['hotelInfo', 'hotel', 'checkin_date'], date);

            onChangeHotelDates();
        }
    };

    const onChangeReturnTrafficDepartureDateTime = (date, dateStr) => {
        console.log(date);
        if (!form.getFieldValue(['hotelInfo', 'hotel', 'checkout_date'])) {
            form.setFieldValue(['hotelInfo', 'hotel', 'checkout_date'], date);

            onChangeHotelDates();
        }
    };

    const onClickHotel = ({key}) => {
        setRoomTypes(hotels.find(h => h.key === key).roomTypes);

        form.setFieldsValue({
            hotelInfo: {
                hotel: {
                    hotel_name: hotels.find(item => item.key === key).label,
                    hotel_address: hotels.find(item => item.key === key).address,
                },
            },
        });
    };

    // 获取嘉宾行程总信息
    const getGuestJourneyInfo = async () => {
        setLoading(true);
        const res = await fetchGuestJourney({ expertId, conferenceId });
        if (res.status_code === 200) {
            // 嘉宾基本信息
            const expertData = res.data.expertInfo;
            if (!!expertData?.idCardStr && !expertData?.idcardType) {
                expertData.idcardType = 1;
            }
            form.setFieldsValue({
                idcardType: expertData.idcardType,
                idCard: expertData.idCardStr
            });
            setExpert(expertData);

            // 嘉宾行程
            const guestJourneyData = res.data.guestJourneys || {};
            processGuestJourneyData(guestJourneyData);

            // 嘉宾日程任务
            let agendas = res.data.agendas;
            if (!isEmpty(agendas)) {
                setAgendaTasks(prepareAgendaTasks(agendas));
            } else {
                setAgendaTasks(null);
            }

            setLoading(false);
        } else {
            setAgendaTasks(null);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (conferenceId && expertId) {
            getGuestJourneyInfo();
        }
    }, [expertId]);

    // 预处理日程任务数据
    const prepareAgendaTasks = agendaTaskList => {
        let agendaDatesAndTasks = {};

        agendaTaskList.forEach(agendaTask => {
            const agendaDate = moment(agendaTask.startTime).format('YYYY年M月D日');
            if (agendaDate in agendaDatesAndTasks) {
                agendaDatesAndTasks[agendaDate].push(agendaTask);
            } else {
                agendaDatesAndTasks[agendaDate] = [agendaTask];
            }
        });

        console.log(agendaDatesAndTasks);

        return agendaDatesAndTasks;
    };

    // 处理 guestJourney 数据，表单数据回显
    const processGuestJourneyData = guestJourneyData => {
        // console.log('guestJourneyData', guestJourneyData);

        if (!isEmpty(guestJourneyData)) {
            const guestJourneyInfo = {
                arrivalInfo: !isEmpty(guestJourneyData?.arrivalInfo) ? safeParseJSON(guestJourneyData?.arrivalInfo) || {} : {},
                returnInfo:  !isEmpty(guestJourneyData?.returnInfo)  ? safeParseJSON(guestJourneyData?.returnInfo) || {}  : {},
                hotelInfo:   !isEmpty(guestJourneyData?.hotelInfo)   ? safeParseJSON(guestJourneyData?.hotelInfo) || {}   : {},
                contracts:   !isEmpty(guestJourneyData?.contracts)   ? safeParseJSON(guestJourneyData?.contracts) || []   : [],
            };

            // 来程交通-出发-抵达时间
            if (guestJourneyInfo?.arrivalInfo?.traffic?.departure_datetime && moment(guestJourneyInfo.arrivalInfo.traffic.departure_datetime).isValid()) {
                guestJourneyInfo.arrivalInfo.traffic.departure_datetime = moment(guestJourneyInfo.arrivalInfo.traffic.departure_datetime);
            }
            if (guestJourneyInfo?.arrivalInfo?.traffic?.arrival_datetime && moment(guestJourneyInfo.arrivalInfo.traffic.arrival_datetime).isValid()) {
                guestJourneyInfo.arrivalInfo.traffic.arrival_datetime = moment(guestJourneyInfo.arrivalInfo.traffic.arrival_datetime);
            }

            // 来程接送-出发-抵达时间
            if (guestJourneyInfo?.arrivalInfo?.pickup_dropoff?.departure_datetime && moment(guestJourneyInfo.arrivalInfo.pickup_dropoff.departure_datetime).isValid()) {
                guestJourneyInfo.arrivalInfo.pickup_dropoff.departure_datetime = moment(guestJourneyInfo.arrivalInfo.pickup_dropoff.departure_datetime);
            }
            if (guestJourneyInfo?.arrivalInfo?.pickup_dropoff?.arrival_datetime && moment(guestJourneyInfo.arrivalInfo.pickup_dropoff.arrival_datetime).isValid()) {
                guestJourneyInfo.arrivalInfo.pickup_dropoff.arrival_datetime = moment(guestJourneyInfo.arrivalInfo.pickup_dropoff.arrival_datetime);
            }

            // 返程交通-出发-抵达时间
            if (guestJourneyInfo?.returnInfo?.traffic?.departure_datetime && moment(guestJourneyInfo.returnInfo.traffic.departure_datetime).isValid()) {
                guestJourneyInfo.returnInfo.traffic.departure_datetime = moment(guestJourneyInfo.returnInfo.traffic.departure_datetime);
            }
            if (guestJourneyInfo?.returnInfo?.traffic?.arrival_datetime && moment(guestJourneyInfo.returnInfo.traffic.arrival_datetime).isValid()) {
                guestJourneyInfo.returnInfo.traffic.arrival_datetime = moment(guestJourneyInfo.returnInfo.traffic.arrival_datetime);
            }

            // 返程接送-出发-抵达时间
            if (guestJourneyInfo?.returnInfo?.pickup_dropoff?.departure_datetime && moment(guestJourneyInfo.returnInfo.pickup_dropoff.departure_datetime).isValid()) {
                guestJourneyInfo.returnInfo.pickup_dropoff.departure_datetime = moment(guestJourneyInfo.returnInfo.pickup_dropoff.departure_datetime);
            }
            if (guestJourneyInfo?.returnInfo?.pickup_dropoff?.arrival_datetime && moment(guestJourneyInfo.returnInfo.pickup_dropoff.arrival_datetime).isValid()) {
                guestJourneyInfo.returnInfo.pickup_dropoff.arrival_datetime = moment(guestJourneyInfo.returnInfo.pickup_dropoff.arrival_datetime);
            }

            // 住宿信息-入住-退房-日期
            if (guestJourneyInfo?.hotelInfo?.hotel?.checkin_date && moment(guestJourneyInfo.hotelInfo.hotel.checkin_date).isValid()) {
                guestJourneyInfo.hotelInfo.hotel.checkin_date = moment(guestJourneyInfo.hotelInfo.hotel.checkin_date);
            }
            if (guestJourneyInfo?.hotelInfo?.hotel?.checkout_date && moment(guestJourneyInfo.hotelInfo.hotel.checkout_date).isValid()) {
                guestJourneyInfo.hotelInfo.hotel.checkout_date = moment(guestJourneyInfo.hotelInfo.hotel.checkout_date);
            }
            let nightNum = 0;
            if (guestJourneyInfo?.hotelInfo?.hotel?.checkin_date && moment(guestJourneyInfo.hotelInfo.hotel.checkin_date).isValid()
                && guestJourneyInfo?.hotelInfo?.hotel?.checkout_date && moment(guestJourneyInfo.hotelInfo.hotel.checkout_date).isValid()) {
                nightNum = moment(guestJourneyInfo.hotelInfo.hotel.checkout_date).diff(moment(guestJourneyInfo.hotelInfo.hotel.checkin_date), 'days');
            }
            setRoomNightNum(nightNum);

            console.log('guestJourneyInfo', guestJourneyInfo);
            form.setFieldsValue(guestJourneyInfo);

            console.log('traffic_type', guestJourneyInfo.arrivalInfo?.traffic?.traffic_type);

            // 处理空数据
            if (guestJourneyInfo.arrivalInfo?.traffic?.traffic_type === undefined) {
                form.setFieldsValue({ arrivalInfo: { traffic: { traffic_type: 0 } } });
            }
            if (guestJourneyInfo.arrivalInfo?.pickup_dropoff?.need_pickup_dropoff === undefined) {
                form.setFieldsValue({ arrivalInfo: { pickup_dropoff: { need_pickup_dropoff: 0 } } });
            }
            if (guestJourneyInfo.returnInfo?.traffic?.traffic_type === undefined) {
                form.setFieldsValue({ returnInfo: { traffic: { traffic_type: 0 } } });
            }
            if (guestJourneyInfo.returnInfo?.pickup_dropoff?.need_pickup_dropoff === undefined) {
                form.setFieldsValue({ returnInfo: { pickup_dropoff: { need_pickup_dropoff: 0 } } });
            }
            if (guestJourneyInfo.hotelInfo?.hotel?.need_hotel === undefined) {
                form.setFieldsValue({ hotelInfo: { hotel: { need_hotel: 0 } } });
            }
        } else {
            form.resetFields();
            form.setFieldsValue({ // 处理空数据
                arrivalInfo: {
                    traffic: { traffic_type: 0 },
                    pickup_dropoff: { need_pickup_dropoff: 0 }
                },
                returnInfo: {
                    traffic: { traffic_type: 0 },
                    pickup_dropoff: { need_pickup_dropoff: 0 }
                },
                hotelInfo: {
                    hotel: { need_hotel: 0 }
                }
            });
            setRoomNightNum(0);
            // setCurrentGuestJourney(null);
        }
    };

    const onSaveGuestJourney = () => {
        form.validateFields()
            .then(async values => {
                console.log('values', values);
                // return;

                const formData = JSON.parse(JSON.stringify(values));

                if (formData.idcardType || formData.idCard) {
                    if (!!formData.idcardType && !formData.idCard) {
                        message.error('选择嘉宾证件类型后，需要填写证件号！');
                        return;
                    }
                    if (!formData.idcardType && !!formData.idCard) {
                        message.error('填写证件号后，需要选择证件类型！');
                        return;
                    }

                    const res = await updateGuestJourneyIdCard({
                        idcardType: formData.idcardType,
                        idCard: formData.idCard,
                        id: expertId,
                    });
                    if (res.status_code === 200) {
                        //
                    } else {
                        message.error('嘉宾证件信息更新失败！');
                    }
                }

                console.log('expertId', expertId, 'conferenceId', conferenceId);

                if (expertId && conferenceId) {
                    // 来程交通-出发-抵达时间
                    if (formData.arrivalInfo?.traffic?.departure_datetime) {
                        formData.arrivalInfo.traffic.departure_datetime = dateTimeFormat(formData.arrivalInfo.traffic.departure_datetime, 'YYYY-MM-DD HH:mm');
                    }
                    if (formData.arrivalInfo?.traffic?.arrival_datetime) {
                        formData.arrivalInfo.traffic.arrival_datetime = dateTimeFormat(formData.arrivalInfo.traffic.arrival_datetime, 'YYYY-MM-DD HH:mm');
                    }

                    // 来程接送-出发-抵达时间
                    if (formData.arrivalInfo?.pickup_dropoff?.departure_datetime) {
                        formData.arrivalInfo.pickup_dropoff.departure_datetime = dateTimeFormat(formData.arrivalInfo.pickup_dropoff.departure_datetime, 'YYYY-MM-DD HH:mm');
                    }
                    if (formData.arrivalInfo?.pickup_dropoff?.arrival_datetime) {
                        formData.arrivalInfo.pickup_dropoff.arrival_datetime = dateTimeFormat(formData.arrivalInfo.pickup_dropoff.arrival_datetime, 'YYYY-MM-DD HH:mm');
                    }

                    // 返程交通-出发-抵达时间
                    if (formData.returnInfo?.traffic?.departure_datetime) {
                        formData.returnInfo.traffic.departure_datetime = dateTimeFormat(formData.returnInfo.traffic.departure_datetime, 'YYYY-MM-DD HH:mm');
                    }
                    if (formData.returnInfo?.traffic?.arrival_datetime) {
                        formData.returnInfo.traffic.arrival_datetime = dateTimeFormat(formData.returnInfo.traffic.arrival_datetime, 'YYYY-MM-DD HH:mm');
                    }

                    // 返程接送-出发-抵达时间
                    if (formData.returnInfo?.pickup_dropoff?.departure_datetime) {
                        formData.returnInfo.pickup_dropoff.departure_datetime = dateTimeFormat(formData.returnInfo.pickup_dropoff.departure_datetime, 'YYYY-MM-DD HH:mm');
                    }
                    if (formData.returnInfo?.pickup_dropoff?.arrival_datetime) {
                        formData.returnInfo.pickup_dropoff.arrival_datetime = dateTimeFormat(formData.returnInfo.pickup_dropoff.arrival_datetime, 'YYYY-MM-DD HH:mm');
                    }

                    // 住宿信息-入住-退房-日期
                    if (formData.hotelInfo?.hotel?.checkin_date) {
                        formData.hotelInfo.hotel.checkin_date = dateTimeFormat(formData.hotelInfo.hotel.checkin_date, 'YYYY-MM-DD');
                    }
                    if (formData.hotelInfo?.hotel?.checkout_date) {
                        formData.hotelInfo.hotel.checkout_date = dateTimeFormat(formData.hotelInfo.hotel.checkout_date, 'YYYY-MM-DD');
                    }

                    let data = {
                        conferenceId: conferenceId,
                        expertId: expertId,
                        contracts: JSON.stringify(formData.contracts),
                        arrivalInfo: JSON.stringify(formData.arrivalInfo),
                        returnInfo: JSON.stringify(formData.returnInfo),
                        hotelInfo: JSON.stringify(formData.hotelInfo),
                    };
                    console.log('data', data);
                    // return;

                    const res = await saveGuestJourney(data);
                    if (res.status_code === 200) {
                        message.success('嘉宾行程保存成功');
                        form.resetFields();
                        setCurrentGuestJourney(null);
                        setAgendaTasks(null);
                        setExpert(null);
                        onClose();

                        getGuestJourneyList();
                    }
                } else {
                    // TODO
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const onCloseGuestJourney = () => {
        form.resetFields();
        setCurrentGuestJourney(null);
        setAgendaTasks(null);
        onClose();
    };

    return <>
        <Drawer
            title="编辑嘉宾行程"
            className="guest-journey-modal"
            open={visibleGuestJourney}
            centered
            height="100%"
            placement="bottom"
            onClose={onCloseGuestJourney}
            footer={
                <div className="custom-drawer-footer" style={{ textAlign: 'right' }}>
                    <Space>
                        <Button onClick={onCloseGuestJourney}>取消</Button>
                        <Button type="primary" loading={loading} onClick={onSaveGuestJourney}>确定</Button>
                    </Space>
                </div>
            }
        >

            <Form className="guest-journey-from" name="guestJourneyForm" form={form}>

                <div className="guest-journey-subtitle">嘉宾基本信息</div>
                <div className="guest-info">
                    <span className="guest-name">{expert?.memUsername}</span>
                    <span className="expert-level">
                        <Text type={expert?.expertLevel >= 1 && expert?.expertLevel <= 6 ? 'warning' : 'secondary'}>
                            {expertLevels.find(el => el.level === expert?.expertLevel)?.label}
                        </Text>
                    </span>
                    <span className="guest-phone">{expert?.memMobile}</span>
                    <br/>
                    单位任职：
                    {expert?.ssoMemberWorkExperiencesEntities?.length > 1
                        ? (
                            <ol className="unit-post-list">
                                {expert?.ssoMemberWorkExperiencesEntities.map(item => (
                                    <li className="unit-position" key={item.id}>{item?.unitName}{item?.unitOffice}{item?.unitPost}</li>
                                ))}
                            </ol>
                        )
                        : <span className="unit-position">{expert?.ssoMemberWorkExperiencesEntities?.[0]?.unitName}{expert?.ssoMemberWorkExperiencesEntities?.[0]?.unitOffice}{expert?.ssoMemberWorkExperiencesEntities?.[0]?.unitPost}</span>
                    }

                </div>

                <div className="guest-identity-info">
                    <Row gutter={24}>
                        <Col>
                            <Form.Item label="证件类型" name="idcardType" style={{ width: 250 }}>
                                <Select placeholder="证件类型" allowClear>
                                    {cardTypes.map((item, index) => (
                                        <Select.Option key={index} value={item.code}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="证件号" name="idCard" style={{ width: 300 }}>
                                <Input maxLength={50} placeholder="请输入证件号" showCount/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div className="guest-journey-subtitle">嘉宾任务及接受状态</div>

                {!isEmpty(agendaTasks)
                    ? (<>
                        <div className="guest-task-table-box">
                            <table className="guest-task-table" style={{width: 350 * Object.keys(agendaTasks).length}}>
                                <thead>
                                <tr>
                                    {Object.keys(agendaTasks).map((date, index) => <th key={`date_${index}`}>{date}会议任务</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {Object.keys(agendaTasks).map((date, index) => (
                                        <td key={`date_${index}_tasks`} className="agendas" valign="top">
                                            {agendaTasks[date].map(agenda => (
                                                <div className="agenda-box" key={agenda.id}>
                                                    <div className="agenda">
                                                        {agenda.conferenceHallName} / {agenda.title} <Tag color="blue">{agenda?.taskName}</Tag>
                                                        {agenda.invitationAccepted === 0 && <Text type="secondary">未反馈</Text>}
                                                        {agenda.invitationAccepted === 1 && <Text type="success">已确认</Text>}
                                                        {agenda.invitationAccepted === 2 && (
                                                            <Tooltip placement="top" trigger="click" title={agenda?.refusalInfo}>
                                                                <span style={{ color: '#F00', textDecoration: 'underline', cursor: 'pointer' }}>已拒绝</span>
                                                            </Tooltip>
                                                        )}
                                                        {agenda.invitationAccepted === 3 && <Text type="warning">待确认</Text>}
                                                    </div>
                                                    <div className="agenda-datetime">{convertDateTimeRangeToLocalText(agenda.startTime, agenda.endTime, 0)}</div>
                                                </div>
                                            ))}
                                        </td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </>)
                    : <div className="empty-task">暂无任务</div>
                }

                <div className="guest-journey-subtitle"><span>联系人信息</span><small className="visible-to-user">此信息对用户可见</small></div>

                <div className="contacts-box">
                    <Form.List name="contracts">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.length < 5 && (
                                    <Button type="link" size="small" onClick={() => add()} style={{ marginBottom: 16 }}>
                                            <span className="anticon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                </svg>
                                            </span>
                                        <span>新增联系人</span>
                                    </Button>
                                )}

                                <div className="contact-list">
                                    {fields.map(({ key, name, ...restFields }, index) => (
                                        <div className="contact">
                                            <Row gutter={20}>
                                                <Col>
                                                    <Form.Item
                                                        name={[name, 'name']}
                                                        label="联系人姓名"
                                                        rules={[
                                                            { required: true, message: '联系人姓名不能为空' },
                                                            { min: 2, message: '姓名不能少于2个字符' }
                                                        ]}
                                                    >
                                                        <Input placeholder="联系人姓名"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item
                                                        name={[name, 'phone']}
                                                        label="手机号"
                                                        rules={[
                                                            { required: true, message: '手机号不能为空' },
                                                            { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                                                        ]}
                                                    >
                                                        <Input placeholder="联系人手机号码"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Button type="link" onClick={() => remove(name)}>
                                                        <span className="anticon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle icon-pointer-projects" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                            </svg>
                                                        </span>
                                                        <span>移除</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>

                            </>
                        )}
                    </Form.List>
                </div>

                <div className="guest-journey-subtitle">
                    <span>嘉宾行程</span>
                    <span className="ai-link-text" onClick={onOpenAIModal}>AI快捷录入</span>
                </div>

                <div className="guest-journey-details">
                    <table width="100%">
                        <thead>
                        <tr>
                            <th width="35%"><span>嘉宾来程信息</span><small className="visible-to-user">此信息对用户可见</small></th>
                            <th width="35%"><span>嘉宾返程信息</span><small className="visible-to-user">此信息对用户可见</small></th>
                            <th width="30%"><span>嘉宾住宿信息</span><small className="visible-to-user">此信息对用户可见</small></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="system-assertion">
                            <td valign="top">
                                <p>【系统】已接受日程最早开始时间：{!!currentGuestJourney?.minDate ? currentGuestJourney.minDate : <Text type="secondary">暂无</Text>}</p>
                            </td>
                            <td valign="top">
                                <p>【系统】已接受日程最晚结束时间：{!!currentGuestJourney?.maxDate ? currentGuestJourney.maxDate : <Text type="secondary">暂无</Text>}</p>
                            </td>
                            <td valign="top">
                                <p>【系统】建议入住日期：{!!checkinDate ? checkinDate : <Text type="secondary">暂无</Text>}<br/>【系统】建议退房日期：{!!checkoutDate ? checkoutDate : <Text type="secondary">暂无</Text>}</p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <Divider>来程交通</Divider>

                                <Form.Item {...formItemLayout} label="交通方式" name={['arrivalInfo', 'traffic', 'traffic_type']}>
                                    <Radio.Group>
                                        <Radio value={1}>飞机</Radio>
                                        <Radio value={2}>火车</Radio>
                                        <Radio value={0}>无需交通</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.arrivalInfo?.traffic?.traffic_type !== curValues.arrivalInfo?.traffic?.traffic_type}>
                                    {({ getFieldValue }) => {
                                        let trafficType = getFieldValue(['arrivalInfo', 'traffic', 'traffic_type']);
                                        if (trafficType !== undefined) {
                                            trafficType = Number(trafficType) || 0;
                                        }
                                        const trafficTypeLabel = trafficType === undefined ? '班次/列次' : trafficType === 1 ? '班次' : '列次';
                                        return trafficType !== 0
                                            ? (<>
                                                <Form.Item
                                                    {...formItemLayout}
                                                    label={trafficTypeLabel}
                                                    name={['arrivalInfo', 'traffic', 'traffic_no']}
                                                >
                                                    <Input maxLength={50} showCount placeholder={`请输入交通${trafficTypeLabel}`}/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发-抵达">
                                                    <Input.Group>
                                                        <Form.Item noStyle name={['arrivalInfo', 'traffic', 'departure_datetime']}>
                                                            <DatePicker
                                                                placeholder="出发日期时间"
                                                                showTime={{ format: 'HH:mm' }}
                                                                format="YYYY-MM-DD HH:mm"
                                                                style={{width: 150}}
                                                                onChange={(date, dateStr) => onChangeArrivalTrafficDepartureDateTime(date, dateStr)}
                                                            />
                                                        </Form.Item>
                                                        <Text type="secondary"> &ndash; </Text>
                                                        <Form.Item noStyle name={['arrivalInfo', 'traffic', 'arrival_datetime']}>
                                                            <DatePicker placeholder="抵达日期时间" showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 150}}/>
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发地点" name={['arrivalInfo', 'traffic', 'departure_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入出发地点"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="抵达地点" name={['arrivalInfo', 'traffic', 'arrival_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入抵达地点"/>
                                                </Form.Item>
                                            </>)
                                            : null;
                                    }}
                                </Form.Item>
                            </td>
                            <td valign="top">
                                <Divider>返程交通</Divider>

                                <Form.Item {...formItemLayout} label="交通方式" name={['returnInfo', 'traffic', 'traffic_type']}>
                                    <Radio.Group>
                                        <Radio value={1}>飞机</Radio>
                                        <Radio value={2}>火车</Radio>
                                        <Radio value={0}>无需交通</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.returnInfo?.traffic?.traffic_type !== curValues.returnInfo?.traffic?.traffic_type}>
                                    {({ getFieldValue }) => {
                                        let trafficType = getFieldValue(['returnInfo', 'traffic', 'traffic_type']);
                                        if (trafficType !== undefined) {
                                            trafficType = Number(trafficType) || 0;
                                        }
                                        const trafficTypeLabel = trafficType === undefined ? '班次/列次' : trafficType === 1 ? '班次' : '列次';
                                        return trafficType !== 0
                                            ? (<>
                                                <Form.Item
                                                    {...formItemLayout}
                                                    label={trafficTypeLabel}
                                                    name={['returnInfo', 'traffic', 'traffic_no']}
                                                >
                                                    <Input maxLength={50} showCount placeholder={`请输入交通${trafficTypeLabel}`}/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发-抵达">
                                                    <Input.Group>
                                                        <Form.Item noStyle name={['returnInfo', 'traffic', 'departure_datetime']}>
                                                            <DatePicker
                                                                placeholder="出发日期时间"
                                                                showTime={{ format: 'HH:mm' }}
                                                                format="YYYY-MM-DD HH:mm"
                                                                style={{width: 150}}
                                                                onChange={(date, dateStr) => onChangeReturnTrafficDepartureDateTime(date, dateStr)}
                                                            />
                                                        </Form.Item>
                                                        <Text type="secondary"> &ndash; </Text>
                                                        <Form.Item noStyle name={['returnInfo', 'traffic', 'arrival_datetime']}>
                                                            <DatePicker placeholder="抵达日期时间" showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 150}}/>
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发地点" name={['returnInfo', 'traffic', 'departure_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入出发地点"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="抵达地点" name={['returnInfo', 'traffic', 'arrival_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入抵达地点"/>
                                                </Form.Item>
                                            </>)
                                            : null;
                                    }}
                                </Form.Item>

                            </td>
                            <td valign="top" rowSpan={2}>
                                <Divider>住宿信息</Divider>

                                <Form.Item {...formItemLayout} label="住宿需求" name={['hotelInfo', 'hotel', 'need_hotel']}>
                                    <Radio.Group>
                                        <Radio value={1}>需要入住酒店</Radio>
                                        <Radio value={0}>无需入住酒店</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.hotelInfo?.hotel?.need_hotel !== curValues.hotelInfo?.hotel?.need_hotel}>
                                    {({ getFieldValue }) => {
                                        let needHotel = getFieldValue(['hotelInfo', 'hotel', 'need_hotel']);
                                        if (needHotel !== undefined) {
                                            needHotel = Number(needHotel) || 0;
                                        }
                                        return needHotel !== 0
                                            ? (<>
                                                <Form.Item
                                                    {...formItemLayout}
                                                    label="入住-退房"
                                                    extra={roomNightNum > 0 ? `${roomNightNum}间夜` : null}
                                                >
                                                    <Input.Group>
                                                        <Form.Item noStyle name={['hotelInfo', 'hotel', 'checkin_date']}>
                                                            <DatePicker
                                                                placeholder="入住日期"
                                                                style={{width: 122}}
                                                                onChange={(date, dateStr) => onChangeHotelDates(date, dateStr)}
                                                            />
                                                        </Form.Item>
                                                        <Text type="secondary"> &ndash; </Text>
                                                        <Form.Item noStyle name={['hotelInfo', 'hotel', 'checkout_date']}>
                                                            <DatePicker
                                                                placeholder="退房日期"
                                                                style={{width: 122}}
                                                                onChange={(date, dateStr) => onChangeHotelDates(date, dateStr)}
                                                            />
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>

                                                {/*<Form.Item {...formItemLayout} label="酒店列表">*/}
                                                {/*    <Select placeholder="选择以快捷填写酒店信息" onChange={value => onChangeHotel(value)}>*/}
                                                {/*        {HotelList.map((hotel, index) => <Select.Option key={hotel.name} value={hotel.name}>{hotel.name}</Select.Option>)}*/}
                                                {/*    </Select>*/}
                                                {/*</Form.Item>*/}

                                                <Form.Item {...formItemLayout} label="酒店名称" style={{marginBottom: 0}}>
                                                    <Row gutter={8}>
                                                        <Col span={18}>
                                                            <Form.Item name={['hotelInfo', 'hotel', 'hotel_name']}>
                                                                <Input maxLength={50} showCount placeholder="请输入酒店名称"/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6} style={{paddingTop: 6}}>
                                                            <Dropdown menu={{items: hotels, onClick: onClickHotel}} placement="bottomRight">
                                                                <a onClick={e => e.preventDefault()}>选择酒店</a>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="酒店地址" name={['hotelInfo', 'hotel', 'hotel_address']}>
                                                    <Input maxLength={100} showCount placeholder="请输入酒店地址"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="房间类型" name={['hotelInfo', 'hotel', 'hotel_room_type']}>
                                                    <Select placeholder="请选择房间类型">
                                                        {roomTypes.map(item => <Select.Option key={item} value={item}>{item}</Select.Option>)}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="房间号" name={['hotelInfo', 'hotel', 'hotel_room_no']}>
                                                    <Input maxLength={50} showCount placeholder="请输入房间号"/>
                                                </Form.Item>
                                            </>)
                                            : null;
                                    }}
                                </Form.Item>

                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <Divider>来程接送</Divider>

                                <Form.Item {...formItemLayout} label="接送需求" name={['arrivalInfo', 'pickup_dropoff', 'need_pickup_dropoff']}>
                                    <Radio.Group>
                                        <Radio value={1}>需要接送</Radio>
                                        <Radio value={0}>无需接送</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.arrivalInfo?.pickup_dropoff?.need_pickup_dropoff !== curValues.arrivalInfo?.pickup_dropoff?.need_pickup_dropoff}>
                                    {({ getFieldValue }) => {
                                        let needPickupDropoff = getFieldValue(['arrivalInfo', 'pickup_dropoff', 'need_pickup_dropoff']);
                                        if (needPickupDropoff !== undefined) {
                                            needPickupDropoff = Number(needPickupDropoff) || 0;
                                        }
                                        return needPickupDropoff !== 0
                                            ? (<>
                                                <Form.Item {...formItemLayout} label="司机姓名" name={['arrivalInfo', 'pickup_dropoff', 'driver_name']}>
                                                    <Input maxLength={50} showCount placeholder="请输入司机姓名"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="司机手机号" name={['arrivalInfo', 'pickup_dropoff', 'driver_phone']}>
                                                    <Input maxLength={11} showCount placeholder="请输入司机手机号"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="车辆颜色" name={['arrivalInfo', 'pickup_dropoff', 'driver_car_color']}>
                                                    <Input maxLength={10} showCount placeholder="请输入司机车辆颜色"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="车牌号" name={['arrivalInfo', 'pickup_dropoff', 'driver_car_no']}>
                                                    <Input maxLength={10} showCount placeholder="请输入司机车辆车牌号"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发-抵达">
                                                    <Input.Group>
                                                        <Form.Item noStyle name={['arrivalInfo', 'pickup_dropoff', 'departure_datetime']}>
                                                            <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 150}}/>
                                                        </Form.Item>
                                                        <Text type="secondary"> &ndash; </Text>
                                                        <Form.Item noStyle name={['arrivalInfo', 'pickup_dropoff', 'arrival_datetime']}>
                                                            <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 150}}/>
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发地点" name={['arrivalInfo', 'pickup_dropoff', 'departure_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入出发地点"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="抵达地点" name={['arrivalInfo', 'pickup_dropoff', 'arrival_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入抵达地点"/>
                                                </Form.Item>
                                            </>)
                                            : null;
                                    }}
                                </Form.Item>

                            </td>
                            <td valign="top">
                                <Divider>返程接送</Divider>

                                <Form.Item {...formItemLayout} label="接送需求" name={['returnInfo', 'pickup_dropoff', 'need_pickup_dropoff']}>
                                    <Radio.Group>
                                        <Radio value={1}>需要接送</Radio>
                                        <Radio value={0}>无需接送</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.returnInfo?.pickup_dropoff?.need_pickup_dropoff !== curValues.returnInfo?.pickup_dropoff?.need_pickup_dropoff}>
                                    {({ getFieldValue }) => {
                                        let needPickupDropoff = getFieldValue(['returnInfo', 'pickup_dropoff', 'need_pickup_dropoff']);
                                        if (needPickupDropoff !== undefined) {
                                            needPickupDropoff = Number(needPickupDropoff) || 0;
                                        }
                                        return needPickupDropoff !== 0
                                            ? (<>
                                                <Form.Item {...formItemLayout} label="司机姓名" name={['returnInfo', 'pickup_dropoff', 'driver_name']}>
                                                    <Input maxLength={50} showCount placeholder="请输入司机姓名"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="司机手机号" name={['returnInfo', 'pickup_dropoff', 'driver_phone']}>
                                                    <Input maxLength={11} showCount placeholder="请输入司机手机号"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="车辆颜色" name={['returnInfo', 'pickup_dropoff', 'driver_car_color']}>
                                                    <Input maxLength={10} showCount placeholder="请输入司机车辆颜色"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="车牌号" name={['returnInfo', 'pickup_dropoff', 'driver_car_no']}>
                                                    <Input maxLength={10} showCount placeholder="请输入司机车辆车牌号"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发-抵达">
                                                    <Input.Group>
                                                        <Form.Item noStyle name={['returnInfo', 'pickup_dropoff', 'departure_datetime']}>
                                                            <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 150}}/>
                                                        </Form.Item>
                                                        <Text type="secondary"> &ndash; </Text>
                                                        <Form.Item noStyle name={['returnInfo', 'pickup_dropoff', 'arrival_datetime']}>
                                                            <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 150}}/>
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="出发地点" name={['returnInfo', 'pickup_dropoff', 'departure_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入出发地点"/>
                                                </Form.Item>

                                                <Form.Item {...formItemLayout} label="抵达地点" name={['returnInfo', 'pickup_dropoff', 'arrival_place']}>
                                                    <Input maxLength={50} showCount placeholder="请输入抵达地点"/>
                                                </Form.Item>
                                            </>)
                                            : null;
                                    }}
                                </Form.Item>

                            </td>
                        </tr>
                        <tr>
                            <th><span>来程备注</span><small className="visible-to-user">此备注信息对用户可见</small></th>
                            <th><span>返程备注</span><small className="visible-to-user">此备注信息对用户可见</small></th>
                            <th><span>住宿备注</span><small className="invisible-to-user">此备注信息对用户不可见</small></th>
                        </tr>
                        <tr>
                            <td>
                                <Form.Item name={['arrivalInfo', 'remarks']}>
                                    <TextArea rows={5} maxLength={500} showCount/>
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item name={['returnInfo', 'remarks']}>
                                    <TextArea rows={5} maxLength={500} showCount/>
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item name={['hotelInfo', 'remarks']}>
                                    <TextArea rows={5} maxLength={500} showCount/>
                                </Form.Item>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </Form>
        </Drawer>

        <Modal
            className="guest-journey-ai-input-modal"
            title="AI快捷录入"
            width={600}
            centered
            open={visibleAIModal}
            onCancel={onCloseAIModal}
            footer={[
                <Button key="back" onClick={onCloseAIModal}>取消</Button>,
                <Button key="submit" type="primary" onClick={updateGuestJourneyInfo} disabled={isEmpty(aiOutputInfo)}>填写嘉宾行程</Button>
            ]}
        >
            <Form form={aiInputForm} layout="vertical">
                <div className="ai-input-text">
                    <Form.Item
                        name="input-text"
                        label={<span>嘉宾行程文字描述<Text type="warning">（无相关信息请留空或填写“无”）</Text></span>}
                        rules={[{ required: true, message: '请输入要解析的行程信息'}]}
                        initialValue={`【来程交通】\n\n【来程接送】\n\n【返程交通】\n\n【返程接送】\n\n【酒店住宿】\n\n`}
                    >
                        <TextArea rows={10} maxLength={500} showCount placeholder="请用文字描述嘉宾行程（来程交通、来程接送、返程交通、返程接送、酒店住宿）"/>
                    </Form.Item>
                </div>
                <div className="ai-input-btn">
                    <Button type="primary" htmlType="submit" block ghost loading={loadingAiInput} onClick={extractJourneyInfo}>解析嘉宾行程信息</Button>
                </div>
            </Form>
            <div className="ai-output-title">解析结果：</div>
            <div className="ai-output-text">
                {!aiOutputErr
                    ? <>
                        {!isEmpty(aiOutputInfo?.guestInfo) && <p><strong>嘉宾姓名：</strong>{aiOutputInfo?.guestInfo?.name}</p>}
                        {!!aiOutputInfo?.arrivalInfo?.traffic?.traffic_type && <>
                            <div className="guest-info-title">来程交通信息</div>
                            <div className="guest-info">
                                <p><strong>交通方式：</strong>{aiOutputInfo?.arrivalInfo?.traffic?.traffic_type === 1 ? '飞机' : '火车'}</p>
                                <p><strong>{aiOutputInfo?.arrivalInfo?.traffic?.traffic_type === 1 ? '航班' : '车次'}号：</strong>{aiOutputInfo?.arrivalInfo?.traffic?.traffic_no || emptyText()}</p>
                                <p><strong>出发时间：</strong>{aiOutputInfo?.arrivalInfo?.traffic?.departure_datetime || emptyText()}</p>
                                <p><strong>出发地点：</strong>{aiOutputInfo?.arrivalInfo?.traffic?.departure_place || emptyText()}</p>
                                <p><strong>抵达时间：</strong>{aiOutputInfo?.arrivalInfo?.traffic?.arrival_datetime || emptyText()}</p>
                                <p><strong>抵达地点：</strong>{aiOutputInfo?.arrivalInfo?.traffic?.arrival_place || emptyText()}</p>
                            </div>
                        </>}
                        {aiOutputInfo?.arrivalInfo?.pickup_dropoff?.need_pickup_dropoff === 1 && <>
                            <div className="guest-info-title">来程接送信息</div>
                            <div className="guest-info">
                                <p><strong>司机姓名：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.driver_name || emptyText()}</p>
                                <p><strong>司机手机号：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.driver_phone || emptyText()}</p>
                                <p><strong>司机车辆颜色：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.driver_car_color || emptyText()}</p>
                                <p><strong>司机车辆车牌号：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.driver_car_no || emptyText()}</p>
                                <p><strong>出发日期时间：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.departure_datetime || emptyText()}</p>
                                <p><strong>出发地点：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.departure_place || emptyText()}</p>
                                <p><strong>抵达日期时间：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.arrival_datetime || emptyText()}</p>
                                <p><strong>抵达地点：</strong>{aiOutputInfo?.arrivalInfo?.pickup_dropoff?.arrival_place || emptyText()}</p>
                            </div>
                        </>}
                        {!!aiOutputInfo?.returnInfo?.traffic?.traffic_type && <>
                            <div className="guest-info-title">返程交通信息</div>
                            <div className="guest-info">
                                <p><strong>交通方式：</strong>{aiOutputInfo?.returnInfo?.traffic?.traffic_type === 1 ? '飞机' : '火车'}</p>
                                <p><strong>{aiOutputInfo?.returnInfo?.traffic?.traffic_type === 1 ? '航班' : '车次'}号：</strong>{aiOutputInfo?.returnInfo?.traffic?.traffic_no || emptyText()}</p>
                                <p><strong>出发时间：</strong>{aiOutputInfo?.returnInfo?.traffic?.departure_datetime || emptyText()}</p>
                                <p><strong>出发地点：</strong>{aiOutputInfo?.returnInfo?.traffic?.departure_place || emptyText()}</p>
                                <p><strong>抵达时间：</strong>{aiOutputInfo?.returnInfo?.traffic?.arrival_datetime || emptyText()}</p>
                                <p><strong>抵达地点：</strong>{aiOutputInfo?.returnInfo?.traffic?.arrival_place || emptyText()}</p>
                            </div>
                        </>}
                        {aiOutputInfo?.returnInfo?.pickup_dropoff?.need_pickup_dropoff === 1 && <>
                            <div className="guest-info-title">返程接送信息</div>
                            <div className="guest-info">
                                <p><strong>司机姓名：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.driver_name || emptyText()}</p>
                                <p><strong>司机手机号：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.driver_phone || emptyText()}</p>
                                <p><strong>司机车辆颜色：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.driver_car_color || emptyText()}</p>
                                <p><strong>司机车辆车牌号：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.driver_car_no || emptyText()}</p>
                                <p><strong>出发日期时间：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.departure_datetime || emptyText()}</p>
                                <p><strong>出发地点：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.departure_place || emptyText()}</p>
                                <p><strong>抵达日期时间：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.arrival_datetime || emptyText()}</p>
                                <p><strong>抵达地点：</strong>{aiOutputInfo?.returnInfo?.pickup_dropoff?.arrival_place || emptyText()}</p>
                            </div>
                        </>}
                        {aiOutputInfo?.hotelInfo?.hotel?.need_hotel === 1 && <>
                            <div className="guest-info-title">住宿信息</div>
                            <div className="guest-info">
                                <p><strong>入住日期：</strong>{aiOutputInfo?.hotelInfo?.hotel?.checkin_date || emptyText()}</p>
                                <p><strong>退房日期：</strong>{aiOutputInfo?.hotelInfo?.hotel?.checkout_date || emptyText()}</p>
                                <p><strong>酒店名称：</strong>{aiOutputInfo?.hotelInfo?.hotel?.hotel_name || emptyText()}</p>
                                <p><strong>酒店地址：</strong>{aiOutputInfo?.hotelInfo?.hotel?.hotel_address || emptyText()}</p>
                                <p><strong>房间类型：</strong>{aiOutputInfo?.hotelInfo?.hotel?.hotel_room_type || emptyText()}</p>
                                <p><strong>房间号：</strong>{aiOutputInfo?.hotelInfo?.hotel?.hotel_room_no || emptyText()}</p>
                            </div>
                        </>}
                    </>
                    : <p><Text type="warning">{aiOutputErr}</Text></p>
                }

            </div>
        </Modal>
    </>;
}
