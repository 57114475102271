import { createSlice } from '@reduxjs/toolkit';

const commonState = {
    filter: {
        page: 1,
        pageSize: 20,
    },
    cursor: 0,
};

const initialState = {
    laborFeeProject: { // 劳务费付款项目
        ...commonState,
    },
};

const financeSlice = createSlice({
    name: 'finance',
    initialState,
    reducers: {
        // 劳务费付款项目
        setLaborFeeProjectFilter: (state, action) => void(state.laborFeeProject.filter = action.payload),
        setLaborFeeProjectCursor: (state, action) => void(state.laborFeeProject.cursor = action.payload),
    }
});

export const financeActions = financeSlice.actions;

export default financeSlice;
