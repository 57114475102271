import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Form, Space, Tooltip, Table, Row, Col, Typography, DatePicker, Image } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import config from '@config';
import '../index.less';
import { filterFormGridLayout, filterFormItemLayout } from '@utils/property';
import { liveSignUpApi, getLiveQuestionListApi } from "@services/Conference";
import { getJklFileUrl } from '@utils/utils';

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function SignUp() {
    const params = useParams();
    const liveId = parseInt(params?.id) || 0; // 直播 ID
    const [form] = Form.useForm();
    const [columns, setColumns] = useState([]);

    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [queryObj, setQueryObj] = useState({});
    // 分页
    const onChangePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onFinish = (res) => {
        setPage(1);
        const dateRange = res?.dateRange || [];
        const startTime = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined;
        const endTime = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined;
        setQueryObj({
            phone: res?.phone || undefined,
            startTime,
            endTime
        })
    };

    // 重置
    const onReset = () => {
        setPage(1);
        form.resetFields();
        setQueryObj({
            phone: undefined,
            startTime: undefined,
            endTime: undefined
        });
    };

    // 刷新
    const handleRefresh = () => {
        setPage(1);
        form.resetFields();
        setQueryObj({
            phone: undefined,
            startTime: undefined,
            endTime: undefined
        });
    };

    const getStatusStyle = (str) => {
        if (str === '已认证') {
            return <Text type="success">已认证</Text>;
        }
        if (str === '审核中') {
            return <Text type="warning">审核中</Text>;
        }
        if (str === '已驳回') {
            return <Text type="danger">已驳回</Text>;
        }
        return '-';

    };

    const inintColumns = [
        { title: '报名时间', dataIndex: 'registrationTime', width: 300 },
        {
            title: '当前认证身份',
            width: 300,
            render: (_, record) => {
                return <>
                    <div>身份：{record.memIdentity || '-'}</div>
                    <div>类型：{record.memType || '-'}</div>
                    <div>状态：{getStatusStyle(record.memAuthStatus)}</div>
                </>;
            }
        },
        {
            title: '手机号',
            width: 200,
            render: (_, record) => {
                return <>
                    <div>{record.memMobile || '-'}</div>
                    <div>昵称：{record.memNickname || '-'}</div>
                </>;
            }
        },
        {
            title: '来源医项目',
            width: 300,
            render: (_, record) => {
                return <>
                    <div>{record.mpName || '-'}</div>
                    <div>医项目ID：{record.mpid || '-'}</div>
                </>;
            }
        },
        {
            title: '是否同意更新认证信息',
            dataIndex: 'authInfoSync',
            width: 200,
            render: (_, record) => {
                return <>
                    <div>{record.authInfoSync === 1 ? '是' : '否'}</div>
                </>;
            }
        },
        { title: '报名身份', dataIndex: 'memIdentityName', width: 200, render: (_, record) => (record.memIdentityName || '-') },
        { title: '姓名', dataIndex: 'memUsername', width: 200, render: (_, record) => (record.memUsername || '-') },
        { title: '单位', dataIndex: 'memUnitName', width: 200, render: (_, record) => (record.memUnitName || '-') },
        { title: '科室', dataIndex: 'memOfficeName', width: 200, render: (_, record) => (record.memOfficeName || '-') },
        { title: '职务', dataIndex: 'memPostName', width: 200, render: (_, record) => (record.memPostName || '-') },
        { title: '职称', dataIndex: 'memJobtitleName', width: 200, render: (_, record) => (record.memJobtitleName || '-') },
        {
            title: '认证资料',
            dataIndex: 'memIdentityPath',
            width: 200,
            render: (_, record) => {
                if (record.memIdentityPath) {
                    return <Image width={100} height={100} src={getJklFileUrl(record.memIdentityPath)} />
                }
                return '-';
            }
        }
    ];

    // 修改刷新数据列表
    const getListData = async (page, pageSize, queryObj) => {
        const res = await liveSignUpApi({ liveId, page, pageSize, ...queryObj });
        if (res.status_code === 200 && res.data) {
            const list = res.data.list || [];
            const newList = list.map(item => {
                let answers = {};
                if (item.answers && item.answers.length) {
                    item.answers.forEach(question => {
                        answers[question.questionId] = question.content || '-';
                    });
                }
                if (item?.authFields?.memIdentity) {
                    delete item.authFields.memIdentity;
                }
                let newItem = { ...item, ...item.authFields, ...answers };
                delete newItem.member;
                delete newItem.authFields;

                return newItem;
            })
            setData(newList);
            setTotal(res.data.totalCount);
        }
    };

    // 获取题目配置数据 - 动态表头
    const getQuestionList = async () => {
        const res = await getLiveQuestionListApi({ liveId });
        if (res.status_code === 200 && res.data) {
            if (res.data.length) {
                const questionColumns = res.data.map(item => {
                    return {
                        title: item.content,
                        dataIndex: item.id,
                        width: 300
                    }
                });
                setColumns([...inintColumns, ...questionColumns]);
            } else {
                setColumns([...inintColumns]);
            }
        } else {
            setColumns([...inintColumns]);
        }
    };

    // 导出数据
    const exportData = async () => {
        window.location.href = `${config.API_PREFIX}/conference/activityregistragtionusers/download?liveId=${liveId}`;
    };

    useEffect(() => {
        getListData(page, pageSize, queryObj);
        getQuestionList();
    }, [queryObj, page, pageSize]);

    return <Fragment>
        <div className="main-content-body">
            <div className="live-signup-search">
                <Form form={form} autoComplete="off" name="form" {...filterFormItemLayout} onFinish={onFinish}>
                    <Row>
                        <Col {...filterFormGridLayout}>
                            <Form.Item name="phone" label="手机号">
                                <Input
                                    placeholder="请输入手机号"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col {...filterFormGridLayout}>
                            <Form.Item
                                name="dateRange"
                                label="报名日期"
                            >
                                <RangePicker />
                            </Form.Item>
                        </Col>
                        <Col className="filter-form-buttons" {...filterFormGridLayout}>
                            <Space>
                                <Button type="primary" htmlType="submit">检索</Button>
                                <Button type="default" onClick={onReset}>重置</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="signup-toolbar">
                <div className="sub-tools">
                    <Button type="primary" onClick={exportData}>导出数据</Button>
                    <div className="refresh-btn">
                        <Tooltip title="刷新列表">
                            <Button type="default" icon={<ReloadOutlined />} onClick={handleRefresh}></Button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: total,
                    showTotal: () => `共${total}条`,
                    current: page,
                    pageSize: pageSize,
                    onChange: onChangePage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                }}
                scroll={{ x: 2000 }}
            />

        </div>
    </Fragment>;
}
