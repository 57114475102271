import { createSlice } from '@reduxjs/toolkit';

const commonState = {
    filter: {
        page: 1,
        pageSize: 20,
    },
    cursor: 0,
};

const initialState = {
    group: {
        ...commonState,
        filter: {
            ...commonState.filter,
            isTesting: 0,
        },
    },

    invitation: {
        ...commonState
    },

    user: {
        ...commonState
    },
};

const invitationSlice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {
        setGroupFilter: (state, action) => void(state.group.filter = action.payload),
        setGroupCursor: (state, action) => void(state.group.cursor = action.payload),

        setInvitationFilter: (state, action) => void(state.invitation.filter = action.payload),
        setInvitationCursor: (state, action) => void(state.invitation.cursor = action.payload),

        setUserFilter: (state, action) => void(state.user.filter = action.payload),
        setUserCursor: (state, action) => void(state.user.cursor = action.payload),
    }
});

export const invitationActions = invitationSlice.actions;

export default invitationSlice;
