import React, { Fragment, useState, useEffect, useRef, useMemo } from "react";
import {
    Input,
    Radio,
    Button,
    Space,
    Select,
    Form,
    Modal,
    message,
    Divider,
} from "antd";
import {
    DeleteOutlined,
    DragOutlined,
    CloseOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import "./index.less";
import { arrayMoveImmutable } from "array-move";
import addonce from "@static/images/add-once.png";

import {
    timesaveTemplateQuestionApi,
    delOptionsApi,
} from "@services/Wj/Template";
import {
    timesaveSurveryQuestionApi,
    delSurveyOptionsApi,
} from "@services/Wj/Question";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const SelectQuestion = ({
    questionId,
    type,
    item,
    setAnserArea,
    anserArea,
    logicList,
    onReset,
}) => {
    const [modal, contextHolder] = Modal.useModal();
    const [choiceList, setChoiceList] = useState([]);
    const [moreOpen, setMoreOpen] = useState(false);
    const [questionIndex, setQuestionIndex] = useState();
    const [otherMore, setOtherMore] = useState([]);
    const [titleBorder, setTitleBorder] = useState(false);
    const [remark, setRemark] = useState(false);
    const [titleIn, setTitleIn] = useState(false);
    const [remarkValue, setRemarkValue] = useState(undefined);

    const [form] = Form.useForm();
    const { TextArea } = Input;
    const quillRef = useRef(null);
    const location = useLocation();
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
        let c = document.querySelectorAll(".ql-color");
        setTimeout(() => {
            c.forEach((item) => {
                item.onmousedown = (e) => {
                    e.preventDefault();
                };
            });
        }, 1000);
    }, []);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        "bold",
                        "underline",
                        {
                            color: [],
                        },
                    ],
                    //   ["blockquote", "code-block"],
                    //   [{ header: 1 }, { header: 2 }],
                    //   [{ list: "ordered" }, { list: "bullet" }],
                    //   [{ script: "sub" }, { script: "super" }],
                    //   [{ indent: "-1" }, { indent: "+1" }],
                    //   [{ direction: "rtl" }],
                    //   [{ size: ["small", false, "large", "huge"] }], //字体设置
                    //   [
                    //   ],
                    //   [
                    //     {
                    //       background: [],
                    //     },
                    //   ],
                    //   [{ font: [] }],
                    //   [{ align: [] }],
                    //   ["link", "image"], // a链接和图片的显示
                ],
                // handlers: {
                //     color: colorHander,
                // },
            },
            // toolbar:null,
        };
    }, []);

    //题目题号
    useEffect(() => {
        const index = anserArea.findIndex((it) => it.id === item.id);
        setQuestionIndex(index + 1);
    }, []);

    //选项排序
    const onSortEnd = ({ oldIndex, newIndex }) => {
        var sortArr = arrayMoveImmutable(choiceList, oldIndex, newIndex);
        setChoiceList([...sortArr]);
        saveOpinion(sortArr);
    };

    //设置选项中是否包含其他
    useEffect(() => {
        console.log(item);
        setChoiceList([...item.options]);
        if (item.options.every((it) => it.isBlank === 0 || it.isBlank === null))
            setRemark(false);
        else {
            setRemark(true);
            let otherIndex = item.options.findIndex(
                (item) => item.isBlank === 1
            );
            let res = item.options[otherIndex]?.content;
            res = res + "";
            setRemarkValue(res);
        }
    }, []);

    //删除选项
    const deleteRadio = (item, content) => {
            // let arr = choiceList.filter((it) => it.isBlank === 0);
            if (choiceList.length <= 2) {
                message.error("不可以再删除了!");
                return;
            } else {
                Modal.confirm({
                    title: "提示",
                    content: `确认删除选项${content}`,
                    okText: "确定",
                    cancelText: "取消",
                    onOk: () => {
                        if (location.state.route === "newCreate") {
                            delOptionsApi({ id: item }).then((res) => {
                                if (res.status_code === 200) {
                                    onReset();
                                }
                            });
                        } else if (
                            location.state.route === "newList" ||
                            location.state?.route === "newTemplate"
                        ) {
                            delSurveyOptionsApi({ id: item }).then((res) => {
                                if (res.status_code === 200) {
                                    onReset();
                                }
                            });
                        }
                        setChoiceList([...choiceList]);
                    },
                });
        }
    };



    //批量添加modal
    const addMore = () => {
        setMoreOpen(true);
    };
    // 修改标题
    const changeTitle = (e,i) => {
        let a=document.querySelectorAll('.ql-container')
        a[questionIndex].removeAttribute('style')
        let b=document.querySelectorAll('.ql-toolbar')
        b[questionIndex].removeAttribute('style')
        item.body = quillRef.current.value;
        setTitleBorder(false);
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            body: quillRef.current.value,
        };
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){message.success('保存成功')}}))
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then(((res)=>{if(res.status_code===200){
                message.success('保存成功')
            }}))
        }
        // setAnserArea(resArr);
    };
    //触碰显示边框
    const getBorder = (index) => {
        let a = document.querySelectorAll(".ql-container");
        a[index].style.border = "1px solid #1a73e8";
        let b = document.querySelectorAll(".ql-toolbar");
        b[index].style.display = "block";
        setTitleBorder(true);
        setTitleIn(true);
    };
    //改变选项内容
    const changeOpinion = (e, it) => {
        it.content = e.target.value;
        choiceList.map((item, index) => {
            if (item.content === "") {
                return (item.content = `选项${index + 1}`);
            } else {
                return item;
            }
        });
        setChoiceList([...choiceList]);
        saveOpinion();
    };
    //保存选项
    const saveOpinion = (sortArr) => {
        const resArr = [...anserArea];
        const index = resArr.findIndex((it) => it.id === item.id);
        resArr[index] = {
            ...resArr[index],
            templateId: location.state.id,
            options: sortArr || choiceList,
        };
        if (location.state.route === "newCreate") {
            timesaveTemplateQuestionApi(resArr[index]).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                    onReset();
                }
            });
        } else if (
            location.state.route === "newList" ||
            location.state?.route === "newTemplate"
        ) {
            timesaveSurveryQuestionApi(resArr[index]).then((res) => {
                if (res.status_code === 200) {
                    message.success("保存成功");
                    onReset();
                }
            });
        }
        setAnserArea(resArr);
    };
    //关闭批量添添加modal
    const onCancel = () => {
        setMoreOpen(false);
        form.resetFields();
        setOtherMore([]);
    };
    //批量添加完成
    const onOk = () => {

        let resOld = [...choiceList];
        let str = form.getFieldsValue().opinion + "";
        if (!str) {
            message.info("未输入内容");
            return;
        }
        if(str==='undefined') {
            message.info('未输入内容')
            return
        }
        let arr = str.split("\n");
        let bool = true,
            index = 0,
            newArr = [];
        arr.forEach((item, ind) => {
            if (!bool) return;
            if (!item) return;
            newArr.push(item);
        });
        let filArr = arr.filter((it) => it !== "");

        let final = filArr.map((item,index) => {
            return {
                content: item,
                isBlank: 0,
            };
        });
        let resArr = resOld.concat(final);
        console.log(resArr);
        for(let i = 0; i < resArr.length; i++) {
            for(let j=i+1;j< resArr.length;j++) {
                if(resArr[i].content === resArr[j].content) {
                    if (i < resOld.length) {
                        message.warning("和已添加内容重复，请校验后提交");
                        return;
                    } else {
                        message.warning("有重复选项，请校验后提交");
                        return;
                    }
            }
        }
    }
    if(resArr.length>1000){
        message.warning('最多支持1000个选项')
        return
    }
    saveOpinion(resArr);


    };

    const SortableMain = SortableContainer(({ children }) => {
        return <ul className="sort-all">{children}</ul>;
    });
    const SortableItem = SortableElement(({ item, num }) => (
        <li>
                <div
                    key={item.id}
                    value={item.id}
                    className="options-item"
                    disabled
                >
                    <TextArea
                        className="option-item"
                        placeholder={`选项`}
                        defaultValue={item.content}
                        onBlur={(e) => changeOpinion(e, item)}
                        bordered={false}
                        maxLength={200}
                        autoSize={{
                            minRows: 1,
                        }}
                    ></TextArea>
                    <div className="select-opion-action">
                        <DragHandle  className='drop-opinion'/>
                        <CloseOutlined
                            className="del-opinion"
                            onClick={() => {
                                deleteRadio(item.id, item.content);
                            }}
                        />
                    </div>
                </div>
        </li>
    ));
    const DragHandle = SortableHandle(() => (
        <DragOutlined className="drop-opinion" />
    ));

    return (
        <Fragment>
            <div name={questionId}>
                <div className="question-title">
                    <div className="question-id">
                        <span
                            className="required"
                            style={{
                                display: item.required === 1 ? "" : "none",
                            }}
                        >
                            *
                        </span>
                        <b className="question-index">{questionIndex}.</b>
                    </div>
                    <ReactQuill
                        theme="snow"
                        ref={quillRef}
                        modules={modules}
                        placeholder="请输入问题"
                        className="question-title-area"
                        onFocus={() => getBorder(questionIndex)}
                        defaultValue={item.body}
                        onBlur={() => changeTitle(questionIndex)}
                    />
                </div>

                    <div>
                    <Select open={false} placeholder={'请选择'}/>
                        <div className="select-question-area">
                            {choiceList&&choiceList.length>0?
                            <>
                            <SortableMain onSortEnd={onSortEnd} distance={10}>
                                {choiceList.map((item, index) => {
                                    if (item.isBlank === 0) {
                                        return (
                                            <SortableItem
                                                key={`item-${item.id}`}
                                                index={index}
                                                item={item}
                                                num={index}
                                            />
                                        );
                                    }
                                })}
                            </SortableMain>
                            </>:
                            <div>请添加选项</div>
                            }
                        </div>

                        {/* </Checkbox.Group> */}
                        {/* <hr className="question-division"></hr> */}
                        <div className="choice-action">
                            <Button type="link" onClick={addMore}>
                                <img className="icon-width" src={addonce} />
                                批量添加选项
                            </Button>
                        </div>
                    </div>
            </div>

            <Modal
                title={"批量添加选项"}
                open={moreOpen}
                onCancel={onCancel}
                onOk={onOk}
            >
                <Form form={form} className="add_other">
                    <p>每行代表一个选项，点击回车键换行</p>
                    <Form.Item name="opinion">
                        <TextArea
                            className="vertical-scrollbar"
                            autoSize={{
                                minRows: 6,
                                maxRows: 6,
                            }}
                        ></TextArea>
                    </Form.Item>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default SelectQuestion;
