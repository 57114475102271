import LegacyMedProjectList from '../components/MedProject/LegacyProjects/LegacyMedProjectList';
import LegacyMedProject from '../components/MedProject/LegacyProjects/LegacyMedProject';
import RelatedProjList from '../components/MedProject/LegacyProjects/RelatedProjects/RelatedProjects';

import MedProjects from '../components/MedProject/MedProjects/MedProjects';
import MedProject from '../components/MedProject/MedProjects/MedProject';
import Modules from '../components/MedProject/Projects/Modules';
import Members from '../components/MedProject/Projects/Members';
import Notices from '../components/MedProject/Projects/Notices';
import QuickEntries from '../components/MedProject/Projects/QuickEntries';

const medProjectRoutes = [
    // 原关联项目用医项目
    { path: 'proj/list', element: <LegacyMedProjectList/> },
    { path: 'proj/new', element: <LegacyMedProject/> },
    { path: 'proj/:id', element: <LegacyMedProject/> },
    { path: 'proj/:id/related-projects', element: <RelatedProjList/> },

    // 正式版医项目
    { path: 'projects', element: <MedProjects/> },
    { path: 'projects/new', element: <MedProject/> },
    { path: 'projects/:id', element: <MedProject/> },

    // { path: 'projects/:id', element: <Info/> },
    { path: 'projects/:projectId/modules/:id', element: <Modules/> },
    { path: 'projects/:id/members', element: <Members/> },
    { path: 'projects/:id/notices', element: <Notices/> },
    { path: 'projects/:id/quick-entries', element: <QuickEntries/> },
];

export default medProjectRoutes;
