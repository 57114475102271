import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    LeftOutlined,
    UploadOutlined,
    PaperClipOutlined,
    DeleteOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PlusCircleOutlined,
    CloseOutlined
} from '@ant-design/icons';
import './Invitation.less';
import ReactQuill, { Quill } from 'react-quill';
import {
    Form,
    Input,
    Layout,
    DatePicker,
    Radio,
    Checkbox,
    Space,
    Button,
    Select,
    Switch,
    Upload,
    Col,
    Row,
    message,
    Image,
    Typography
} from 'antd';
import './Invitation.less';
import {
    fileApi,
    getUserFieldsApi,
    saveInvitationApi,
    getInvitationOneApi,
    updateInvitationOneApi
} from '@services/Invitation/Invitation';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '../../utils/property';
import { getMaxFileWidthAndHeight } from '../../utils/utils';
import moment from 'moment'
import { isEmpty } from "@utils/utils";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import config from '@config';
import ImgCrop from 'antd-img-crop';

Quill.register('modules/imageResize', ImageResize);;

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Invitation() {
    const navigate = useNavigate();

    const params = useParams();
    const gid = params?.gid || 0;
    const id = params?.id || 0;

    const [form] = Form.useForm();

    const refs = useRef()
    const topsRefs = useRef()
    const [invitationType, setInvitationType] = useState(3);
    const [oneData, setOneData] = useState({})
    const [bossList, setBossList] = useState([]);
    const [baseList, setBaseList] = useState([]);
    const [moreList, setMoreList] = useState([]);
    const [prodDetails, setProdDetails] = useState(undefined)
    const [topProdDetails, setTopProdDetails] = useState(undefined)
    const [onConfirmPersonalInfoSwitch, setOnConfirmPersonalInfoSwitch] = useState(false);
    const [onEnableTipsSwitch, setOnEnableTipsSwitchChange] = useState(false);
    const [onEnableContentSwitch, setOnEnableContentSwitchChange] = useState(false);
    const [onEnableLaborSwitch, setOnEnableLaborSwitchChange] = useState(false);
    const [onEnableContactsSwitch, setOnEnableContactsSwitchChange] = useState(false);
    const [onRightFormSwitch, setOnRightFormSwitch] = useState(false);
    const [onCocreationFormSwitch, setOnCocreationFormSwitch] = useState(false);

    const [fileList, setFileList] = useState([]);
    const option = { headers: { "Content-Type": "multipart/form-data" } }

    const [textAreaValue, setTextAreaValue] = useState('');
    const textAreaRef = useRef();
    const timeOutRef = useRef(null);

    const inputTimeOutRef = useRef(null);
    const inputShareTitleRef = useRef(null);
    const [shareTitle, setShareTitle] = useState('');

    // 文件上传
    //前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };
    // 校验文件格式
    const checkout = (file, size, initWidth, initHeight) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
        }
        // 判断大小
        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            message.error("图像必须小于" + size + "MB!");
        }
        return isJpgOrPng && isLt2M && getMaxFileWidthAndHeight(file, initWidth, initHeight);
    }

    //pdf校验格式
    const fileCheck = (file, size) => {
        const isPdf = file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isPdf) {
            message.error("请上传 JPG/PNG/PDF 格式附件");
        }
        const isLt5M = file.size / 1024 / 1024 < size;
        if (!isLt5M) {
            message.error("请上传" + size + "MB以内的附件!");
        }
        return isPdf && isLt5M
    }

    // 分享图
    const shareImageFile = {
        name: 'shareImageFile',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 15);
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error('上传文件失败！！')
                    return false;
                }
                if (res.status_code === 200) {
                    setOneData({ ...oneData, shareImage: getFileUrl(res.data) })
                    form.setFieldValue("shareImage", res.data)
                }
            })
        }
    }

    const coverForListFile = {
        name: 'coverForListFile',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 15);
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error('上传文件失败！！')
                    return false;
                }
                if (res.status_code === 200) {
                    setOneData({ ...oneData, coverForList: getFileUrl(res.data) })
                    form.setFieldValue("coverForList", getFileUrl(res.data))
                }
            })
        }
    }

    // H5封面
    const h5CoverFile = {
        name: 'h5CoverFile',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 15);
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error('上传文件失败！！')
                    return false;
                }
                if (res.status_code === 200) {
                    setOneData({ ...oneData, h5CoverUrl: getFileUrl(res.data) })
                    form.setFieldValue("h5Cover", res.data)
                }
            })
        }
    }

    // 顶部背景图
    const backgroundImageFile = {
        name: 'backgroundImageFile',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);//名字和后端接口名字对应
            formData.append('type', 15);
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error('上传文件失败！！')
                    return false;
                }
                if (res.status_code === 200) {
                    setOneData({ ...oneData, backgroundImage: getFileUrl(res.data) })
                    form.setFieldValue("backgroundImage", res.data)
                }
            })
        }
    }

    // 删除邀请函背景
    const closeImg = () => {
        const newItem = { ...oneData };
        delete newItem.backgroundImage;
        setOneData(newItem);
        form.setFieldValue('backgroundImage', '');
    };

    const pdfFile = {
        name: 'pdfFile',
        onChange(info) {
            if (info.file.status === 'uploading') {
                info.file.status = 'done'
            }
        },
        customRequest: info => {
            const formData = new FormData();
            formData.append('file', info.file);
            formData.append('type', 16);
            fileApi(formData, option).then((res) => {
                if (!res) {
                    message.error('上传文件失败！！')
                    return false;
                }
                if (res.status_code === 200) {
                    let obj = {
                        path: res.data,
                        name: info.file.name
                    }
                    setFileList([...fileList, obj])
                }
            });
        }
    }

    useEffect(() => {
        if (fileList.length > 0) {
            fileList.map((item, index) => {
                form.setFieldValue(["attachments", `path${index}`], item.path);
                form.setFieldValue(["attachments", `name${index}`], item.name);
            })
        }

    }, [fileList]);

    const onProdDetailsChange = (v) => {
        setProdDetails(v);
    };

    const onTopProdDetailsChange = (v) => {
        setTopProdDetails(v);
    };

    // 新增邀请函
    const onFinish = values => {
        // console.log(values);
        // return;

        if (values.rightFormEnabled) {
            const arr = (values.rightForm || []).filter(item => item.check);
            if (isEmpty(arr)) {
                return message.error('请至少勾选一项权益形式！');
            }
        }
        if (values.cocreationForm) {
            const arr = (values.cocreationForm || []).filter(item => item.check);
            if (isEmpty(arr)) {
                return message.error('请至少勾选一项特别活动！');
            }
        }

        const infoList = [];
        if (values.confirmPersonalInfo) infoList.push(...values.userFields);
        if (values.laborEnabled) infoList.push(...values.laborFields);
        const dto = {
            name: values.name,
            remarks: values.remarks,
            // type: values.direct,
            type: 3,
            startTime: moment(values.expiration[0]).format('YYYY-MM-DD 00:00:00'),
            endTime: moment(values.expiration[1]).format('YYYY-MM-DD 23:59:59'),
            nameTitle: values.userTitle,
            contentEnabled: values.contentEnabled ? 1 : 0,
            content: values.invitationContent,
            agendaEnabled: values.agenda ? 1 : 0,
            unconfirmedExpertHidden: values.unconfirmedExpertDisplayStatus ? 0 : 1, // 待确认邀请专家，hidden=0 为显示
            laborEnabled: values.laborEnabled ? 1 : 0,
            userInfoEnabled: values.confirmPersonalInfo ? 1 : 0,
            userFieldList: infoList,
            contactsEnabled: values.contactsEnabled ? 1 : 0,
            contactInfos: JSON.stringify(values.contactInfos),
            tipsEnabled: values.enableTips ? 1 : 0,
            tips: values.tips,
            shareTitle: values.shareTitle,
            shareDescription: values.shareDesc,
            shareImage: values.shareImage,
            coverForList: values.coverForList,
            // pcCoverUrl: values?.pcCover,
            h5CoverUrl: values?.h5Cover,
            // pcBannerUrl: values.pcBanner,
            // h5BannerUrl: values.h5Banner,
            backgroundImage: values.backgroundImage || '',
            groupId: gid,
            attachments: fileList ? JSON.stringify(fileList) : '',
            smsInfo: values.invitationDesc,
            projectCardEnabled: values.projectCardEnabled ? 1 : 0,
            rightFormEnabled: values.rightFormEnabled ? 1 : 0,
            rightForm: JSON.stringify(values.rightForm),
            cocreationFormEnabled: values.cocreationFormEnabled ? 1 : 0,
            cocreationForm: JSON.stringify(values.cocreationForm),
        };
        console.log('dto', dto);

        if (!!id) {
            dto.id = id
            updateInvitationOneApi(dto).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    navigate('/invitation/g/' + gid + '/invitations')
                }

            });
        } else {
            saveInvitationApi(dto).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    navigate('/invitation/g/' + gid + '/invitations')
                }

            });
        }
    };

    const onReset = () => {
        navigate('/invitation/g/' + gid + '/invitations')
    };

    const imageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 15);
            const hide = message.loading("上传中...", 0);
            fileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = refs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };

    const topImageHander = async (action, v) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append('type', 15);
            const hide = message.loading("上传中...", 0);
            fileApi(formData, option).then((res) => {
                const url = getFileUrl(res.data); // 获取url,图片预览地址
                let quill = topsRefs?.current?.getEditor(); //获取到编辑器本身
                const cursorPosition = quill.getSelection().index; //获取当前光标位置
                quill.insertEmbed(cursorPosition, "image", url); //插入图片
                quill.setSelection(cursorPosition + 1); //光标位置加1
                hide();
            });
        };
    };

    useEffect(() => {
        getUserFieldsApi({}).then((res) => {
            if (res.status_code === 200) {
                setBaseList(res.data.basic);
                setBossList(res.data.labour);
                setMoreList(res.data.more);
            }
        });

        const arr = [
            { id: 1, check: false, name: '劳务费' },
            { id: 2, check: false, name: '' },
            { id: 3, check: false, name: '' },
            { id: 4, check: false, name: '' },
            { id: 5, check: false, name: '' }
        ];

        const arr1 = [
            { id: 1, check: false, name: '' },
            { id: 2, check: false, name: '' },
            { id: 3, check: false, name: '' },
            { id: 4, check: false, name: '' },
            { id: 5, check: false, name: '' }];

        if (!id) {
            // form.setFieldValue('direct', invitationType);
            form.setFieldValue('contentEnabled', true);
            form.setFieldValue('projectCardEnabled', true);
            setOnEnableContentSwitchChange(true);
            form.setFieldValue('rightForm', arr);
            form.setFieldValue('cocreationForm', arr1);
        }
        if (!!id) {
            getInvitationOneApi({ id }).then((res) => {
                if (res.status_code === 200) {
                    console.log(res.data);
                    setOneData(res.data)

                    let pdfList = res.data.attachments === '' ? [] :
                        JSON.parse(res.data.attachments)
                            .map(item => {
                                return ({
                                    ...item,
                                    path: getFileUrl(item.path)
                                });
                            });

                    setFileList(res.data.attachments ? [...pdfList] : []);
                    const list = res.data.userFieldList.filter(i => {
                        return i !== 'bankName' && i !== 'bankCardNo' && i !== 'idCard' && i !== 'electronSign'
                    });

                    form.setFieldsValue({
                        name: res.data.name,
                        remarks: res.data.remarks,
                        direct: res.data.type,
                        expiration: [moment(res.data.startTime), moment(res.data.endTime)],
                        userTitle: res.data.nameTitle,
                        contentEnabled: res.data.contentEnabled,
                        invitationContent: res.data.content,
                        agenda: res.data.agendaEnabled,
                        unconfirmedExpertDisplayStatus: !res.data.unconfirmedExpertHidden,
                        laborEnabled: res.data.laborEnabled,
                        laborFields: ['bankName', 'bankCardNo', 'idCard', 'electronSign'],
                        confirmPersonalInfo: res.data.userInfoEnabled,
                        userFields: list,
                        contactsEnabled: res.data.contactsEnabled,
                        contactInfos: res.data.contactInfos ? JSON?.parse(res.data.contactInfos) : [{}],
                        enableTips: res.data.tipsEnabled,
                        tips: res.data.tips,
                        shareTitle: res.data.shareTitle,
                        shareDesc: res.data.shareDescription,
                        shareImage: res.data.shareImage,
                        coverForList: res.data.coverForList,
                        backgroundImage: res.data.backgroundImage,
                        invitationDesc: res.data.smsInfo,
                        projectCardEnabled: res.data.projectCardEnabled,
                        h5Cover: res.data?.h5CoverUrl,
                        rightFormEnabled: res?.data?.rightFormEnabled,
                        rightForm: res.data?.rightForm === null ? arr : JSON?.parse(res.data.rightForm),
                        cocreationFormEnabled: res?.data?.cocreationFormEnabled,
                        cocreationForm: res.data?.cocreationForm === null ? arr1 : JSON.parse(res?.data?.cocreationForm),
                    })
                    setTextAreaValue(res.data.smsInfo);
                    setInvitationType(res.data.type);
                }
            });
        }

        return () => {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        }
    }, [])

    const onConfirmPersonalInfoSwitchChange = (value) => {
        setOnConfirmPersonalInfoSwitch(value);
        setOneData({ ...oneData, userInfoEnabled: value ? 1 : 0 });
    };

    const onEnableTipsSwitchChange = (value) => {
        setOnEnableTipsSwitchChange(value);
        setOneData({ ...oneData, tipsEnabled: value ? 1 : 0 });
    };

    const onEnableContentSwitchChange = (value) => {
        setOnEnableContentSwitchChange(value);
        setOneData({ ...oneData, contentEnabled: value ? 1 : 0 });
    };

    const onLaborEnabledSwitchChange = (value) => {
        setOnEnableLaborSwitchChange(value);
        setOneData({ ...oneData, laborEnabled: value ? 1 : 0 });
    };

    const onContactsEnabledSwitchChange = (value) => {
        setOnEnableContactsSwitchChange(value);
        setOneData({ ...oneData, contactsEnabled: value ? 1 : 0 });
    };

    const onRightFormEnabledSwitchChange = (value) => {
        setOnRightFormSwitch(value);
        setOneData({ ...oneData, rightFormEnabled: value ? 1 : 0 });
    };

    const onCocreationFormEnabledSwitchChange = (value) => {
        setOnCocreationFormSwitch(value);
        setOneData({ ...oneData, cocreationFormEnabled: value ? 1 : 0 });
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    [{ color: [] }],
                    [{ background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"], // a链接和图片的显示
                ],

                handlers: {
                    image: imageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    const topModules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }], //字体设置
                    [{ color: [] }],
                    [{ background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"], // a链接和图片的显示
                ],
                handlers: {
                    image: topImageHander,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        };
    }, []);

    const changeFileName = (e, index) => {
        let olidFile = [...fileList];
        olidFile[index].name = e.target.value;
        setFileList([...olidFile]);
    }

    const changeFile = (index, type) => {
        let olidFile = [...fileList];
        let currentFile = olidFile[index];
        if (type === 'del') {
            olidFile.splice(index, 1);
            setFileList([...olidFile]);
        } else if (type === 'up') {
            let preFile = olidFile[index - 1];
            let midSave;
            midSave = currentFile;
            olidFile[index] = preFile;
            olidFile[index - 1] = midSave;
            setFileList([...olidFile]);
        } else if (type === 'down') {
            let nextFile = olidFile[index + 1];
            let midSave;
            midSave = currentFile;
            olidFile[index] = nextFile;
            olidFile[index + 1] = midSave;
            setFileList([...olidFile]);
        }
    }

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e?.target?.value);
        form.setFieldValue('invitationDesc', e?.target?.value);
    }

    // 获取光标位置
    const getPositionForInput = ctrl => {
        let caretPos = { start: 0, end: 0 };
        if (ctrl.selectionStart) {
            caretPos.start = ctrl.selectionStart;
        }
        if (ctrl.selectionEnd) {
            caretPos.end = ctrl.selectionEnd;
        }
        return (caretPos);
    }

    // 设置光标位置
    const setCursorPosition = (ctrl, start, end) => {
        ctrl.focus();
        ctrl.setSelectionRange(start, end);
    }

    // 插入信息
    const handleInsert = () => {
        const txt = '$邀请链接$';
        if (textAreaValue?.length + txt.length > 200) {
            return;
        }

        const textarea = textAreaRef.current.resizableTextArea.textArea;
        const position = getPositionForInput(textarea);
        const insertStart = position.start;
        const insertEnd = position.end + txt.length;
        const newText = textAreaValue.slice(0, insertStart) + txt + textAreaValue.slice(insertStart);
        timeOutRef.current = setTimeout(() => {
            setCursorPosition(textarea, insertStart, insertEnd);
        }, 200);
        setTextAreaValue(newText);
        form.setFieldValue('invitationDesc', newText);
    };

    const insertNameTitleToShareTitle = () => {
        const txt = '$姓名称谓$';

        const input = inputShareTitleRef.current.input;
        const position = getPositionForInput(input);
        const insertStart = position.start;
        const insertEnd = position.end + txt.length;
        const newText = shareTitle.slice(0, insertStart) + txt + shareTitle.slice(insertStart);
        console.log(newText);
        inputTimeOutRef.current = setTimeout(() => {
            setCursorPosition(input, insertStart, insertEnd);
        }, 200);

        setShareTitle(newText);
        form.setFieldValue('shareTitle', newText);
    };

    const changeShareTitle = e => {
        console.log(e.target.value);
        setShareTitle(e.target.value);
        form.setFieldValue('shareTitle', e.target.value);
    };

    const beforeCropCheck = (file, bool) => {
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            message.error('只支持JPG/JPEG/PNG格式。');
            return null;
        }

        if (file.size > 1 * 1024 * 1024) {
            message.error('图片大小不能超过1MB。');
            return null;
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                const res = event.target.result;
                const imgDom = document.createElement('img');
                imgDom.src = res;
                imgDom.onload = function () {
                    resolve(true);
                };
            };
            reader.readAsDataURL(file);
        });
    };

    return <Fragment>
        <div className="page-title">
            <Link to={`/invitation/g/${gid}/invitations`} className="back"><LeftOutlined /></Link>
            <span className="current-title">{!id ? '新增邀请函' : '编辑邀请函'}</span>
            {!!id && <span className="guest_edit">{oneData.name}</span>}
        </div>

        <Content className="main-content-box">
            <div className="main-content-title">邀请函信息</div>
            <div className="main-content-body">

                <Form
                    className="page-form projects-form"
                    {...pageFormItemLayout}
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        // contentEnabled: true,
                        userTitle: 6,
                        laborFields: ['bankName', 'bankCardNo', 'idCard', 'electronSign'],
                        contactInfos: [{}],
                        agenda: 1,
                        unconfirmedExpertDisplayStatus: 1,
                    }}
                >

                    <div className="form-subtitle">基本信息</div>

                    <Form.Item name="name" label="邀请函名称" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="输入邀请函名称" allowClear />
                    </Form.Item>

                    <Form.Item name="remarks" label="备注信息">
                        <Input placeholder="输入邀请函备注信息" allowClear />
                    </Form.Item>

                    {/* <Form.Item
                        name="direct"
                        label="邀请函类型"
                        rules={[{ required: true, message: '' }]}
                        help="一人一码：任何人可查看邀请函，不登录；统一邀请码：仅限被邀请用户，手机号验证通过查看邀请，需登录 "
                    >
                        <Radio.Group value={invitationType} onChange={handleRadioChange}>
                            <Radio value={3}>一人一码</Radio>
                            <Radio value={1}>统一邀请码</Radio>
                        </Radio.Group>
                    </Form.Item> */}

                    <Form.Item
                        name="expiration"
                        label="有效期"
                        rules={[
                            { required: true, message: '' }
                        ]}
                        help="邀请函仅在有效期内，可提交邀请函反馈；超过有效期，用户可查看，但不可提交邀请函反馈。"
                    >
                        <RangePicker />
                    </Form.Item>

                    <Form.Item
                        name="userTitle"
                        label="邀请称呼"
                        rules={[
                            { required: true, message: '' }
                        ]}
                    >
                        <Select placeholder="选择邀请称呼">
                            <Select.Option key={1} value={1}>无</Select.Option>
                            <Select.Option key={2} value={2}>先生/女士</Select.Option>
                            <Select.Option key={3} value={3}>教授</Select.Option>
                            <Select.Option key={4} value={4}>嘉宾</Select.Option>
                            <Select.Option key={5} value={5}>老师</Select.Option>
                            <Select.Option key={6} value={6}>专家职务</Select.Option>
                        </Select>
                    </Form.Item>

                    <div className="form-subtitle">模块设置</div>

                    <Form.Item name="projectCardEnabled" label="项目官网" valuePropName="checked">
                        <Switch checkedChildren="显示" unCheckedChildren="隐藏" />
                    </Form.Item>

                    <Form.Item name="contentEnabled" label="启用邀请词" valuePropName="checked">
                        <Switch checkedChildren="启用" unCheckedChildren="关闭" onChange={onEnableContentSwitchChange} />
                    </Form.Item>

                    {(oneData.contentEnabled === 1 || onEnableContentSwitch) && (
                        <Form.Item
                            name="invitationContent"
                            className='invitationContent'
                            label="邀请词内容"
                            rules={[
                                { required: true, message: '' },
                                {
                                    validator: async (rule, value) => {
                                        if (value === "") {
                                            throw "请输入邀请词内容";
                                        } else if (value === "<p><br></p>") {
                                            throw "请输入邀请词内容";
                                        }
                                    },
                                },
                            ]}
                        >
                            <ReactQuill
                                ref={refs}
                                modules={modules}
                                theme="snow"
                                value={prodDetails}
                                onChange={onProdDetailsChange}
                                className="ql-editor  react-editor"
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        label="附件"
                        help="支持 JPG/PNG/PDF 文件格式，最多上传5个5MB以内的附件"
                        className='upload-invations-form'
                    >
                        {fileList && fileList.map((item, index) =>
                            <div className="doc_area" key={index}>
                                <PaperClipOutlined className="icon1" />
                                <Form.Item
                                    name={['attachments', `path${index}`]}
                                    style={{ marginBottom: 0 }}
                                    className="path_area"
                                >
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item
                                    name={['attachments', `name${index}`]}
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        { required: true, message: '显示文件名不能为空' }
                                    ]}
                                >
                                    <Input onChange={(e) => changeFileName(e, index)} placeholder='请输入显示文件名' />
                                </Form.Item>
                                <DeleteOutlined className="icon1 delicon" onClick={() => changeFile(index, 'del')} />
                                {index !== 0 && <ArrowUpOutlined className="icon1" onClick={() => changeFile(index, 'up')} />}
                                {index !== fileList.length - 1 && <ArrowDownOutlined className="icon1" onClick={() => changeFile(index, 'down')} />}
                            </div>
                        )}
                        <Upload
                            beforeUpload={(file) => fileCheck(file, 5)}
                            {...pdfFile}
                            maxCount={5}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />} disabled={fileList.length > 4}>上传附件</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="agenda" label="启用日程安排" valuePropName="checked">
                        <Switch checkedChildren="启用" unCheckedChildren="关闭" />
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            return !!getFieldValue('agenda') && (
                                <Form.Item name="unconfirmedExpertDisplayStatus" label="待确认邀请专家" valuePropName="checked">
                                    <Switch checkedChildren="显示" unCheckedChildren="隐藏" />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    <Form.Item name="rightFormEnabled" label="启用权益形式" valuePropName="checked">
                        <Switch checkedChildren="启用" unCheckedChildren="关闭" onChange={onRightFormEnabledSwitchChange} />
                    </Form.Item>

                    {(oneData.rightFormEnabled === 1 || onRightFormSwitch) && (
                        <Form.Item label="权益形式" required>
                            <Form.List name="rightForm" className='projects-race-form-list'>
                                {(fields, { add, remove }) => (
                                    <Fragment>
                                        <Form.Item style={{ marginBottom: 10 }}>
                                            <div style={{ color: '#00000073' }}>最多可勾选5个权益供用户选择</div>
                                        </Form.Item>


                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} style={{ display: 'flex', marginBottom: 10 }}>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'check']}
                                                    style={{ marginBottom: 0 }}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox label='权益名称' style={{ marginRight: 20 }}></Checkbox>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'name']}
                                                    rules={[{
                                                        required: true,
                                                        validator: async (rule, value) => {
                                                            if (form.getFieldValue('rightForm')[index].check && !value) {
                                                                throw '权益名称不能为空'
                                                            }
                                                        }
                                                    }]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input placeholder="请输入" style={{ width: 400 }} disabled={index === 0} />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </Fragment>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    <Form.Item name="cocreationFormEnabled" label="启用特别活动" valuePropName="checked">
                        <Switch checkedChildren="启用" unCheckedChildren="关闭" onChange={onCocreationFormEnabledSwitchChange} />
                    </Form.Item>

                    {(oneData.cocreationFormEnabled === 1 || onCocreationFormSwitch) && (
                        <Form.Item label="特别活动" required>
                            <Form.List name="cocreationForm" className='projects-race-form-list'>
                                {(fields, { add, remove }) => (
                                    <Fragment>
                                        <Form.Item style={{ marginBottom: 10 }}>
                                            <div style={{ color: '#00000073' }}>最多可勾选30个特别活动供用户选择</div>
                                        </Form.Item>

                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <div key={key} style={{ display: 'flex', marginBottom: 10 }}>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'check']}
                                                    style={{ marginBottom: 0 }}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox label='特别活动' style={{ marginRight: 20 }}></Checkbox>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'name']}
                                                    rules={[{
                                                        required: true,
                                                        validator: async (rule, value) => {
                                                            if (form.getFieldValue('cocreationForm')[index].check && !value) {
                                                                throw '特别活动不能为空'
                                                            }
                                                        }
                                                    }]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input placeholder="请输入" style={{ width: 400 }} />
                                                </Form.Item>
                                            </div>
                                        ))}
                                        {
                                            fields.length < 30 &&
                                            <Form.Item style={{ marginLeft: 20 }}>
                                                <Button
                                                    type='link'
                                                    onClick={() => {
                                                        // add();
                                                        const values = form.getFieldValue('cocreationForm');
                                                        const newValues = values.concat({ id: fields.length + 1, name: '', check: false });
                                                        // console.log(values, fields.length)
                                                        form.setFieldValue('cocreationForm', newValues);
                                                    }}
                                                >
                                                    添加</Button>
                                            </Form.Item>
                                        }
                                    </Fragment>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    {/*<Form.Item name="confirmPersonalInfo" label="个人信息" valuePropName="checked">*/}
                    {/*    <Switch checkedChildren="显示" unCheckedChildren="隐藏" onChange={onConfirmPersonalInfoSwitchChange}/>*/}
                    {/*</Form.Item>*/}

                    {(oneData.userInfoEnabled === 1 || onConfirmPersonalInfoSwitch) && (
                        <Form.Item
                            name="userFields"
                            label="个人信息字段"
                            rules={[
                                { required: true, message: '请勾选信息选项' }
                            ]}
                        >
                            <Checkbox.Group>
                                <div className="field-group">
                                    <div className="field-title">基本信息</div>
                                    <Row>
                                        {baseList != null && baseList.map((base) => (
                                            <Col key={base.fieldName} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                                                <Checkbox value={base.fieldName}>{base.displayName}</Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                                <div className="field-group">
                                    <div className="field-title">更多信息</div>
                                    <Row>
                                        {moreList != null && moreList.map((more) => (
                                            <Col key={more.fieldName} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                                                <Checkbox value={more.fieldName}>{more.displayName}</Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Checkbox.Group>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="enableTips"
                        label="启用温馨提示"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="启用" unCheckedChildren="关闭" onChange={onEnableTipsSwitchChange} />
                    </Form.Item>
                    {oneData.tipsEnabled === 1 || onEnableTipsSwitch
                        ? (
                            <Form.Item
                                name="tips"
                                label="温馨提示内容"
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        validator: async (rule, value) => {
                                            if (value === "") {
                                                throw "请输入温馨提示内容";
                                            } else if (value === "<p><br></p>") {
                                                throw "请输入温馨提示内容";
                                            }
                                        },
                                    },
                                ]}
                            >
                                <ReactQuill
                                    ref={topsRefs}
                                    modules={topModules}
                                    theme="snow"
                                    value={topProdDetails}
                                    onChange={onTopProdDetailsChange}
                                    className="ql-editor react-editor"
                                />
                            </Form.Item>
                        )
                        : null
                    }

                    <Form.Item name="contactsEnabled" label="启用联系人" valuePropName="checked">
                        <Switch checkedChildren="启用" unCheckedChildren="关闭" onChange={onContactsEnabledSwitchChange} />
                    </Form.Item>

                    {(oneData.contactsEnabled === 1 || onEnableContactsSwitch) && (
                        <Form.Item label="联系人信息" required>
                            <Form.List name="contactInfos" className='projects-race-form-list'>
                                {(fields, { add, remove }) => (
                                    <Fragment>
                                        <Form.Item style={{ marginBottom: 10 }}>
                                            <Button type="link" size="small" disabled={fields?.length >= 10} onClick={() => add()}>
                                                <PlusCircleOutlined /> 新增联系人
                                            </Button>
                                        </Form.Item>
                                        {fields.map(({ key, name, ...restFields }, index) => (
                                            <Space key={key} align='start' className='projects-race-form-list-space'>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'userName']}
                                                    label='联系人'
                                                    rules={[
                                                        { required: true, message: '请输入联系人姓名' }
                                                    ]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input placeholder="联系人姓名" style={{ width: 160 }} />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restFields}
                                                    name={[name, 'phone']}
                                                    label='联系电话'
                                                    rules={[
                                                        { required: true, message: '请输入联系人电话' },
                                                        { pattern: /^1\d{10}$/, message: '电话格式不正确' }
                                                    ]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input placeholder="联系人电话" style={{ width: 160 }} />
                                                </Form.Item>

                                                {fields?.length > 1 && <div className='projects-icon-main'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle icon-pointer-projects" viewBox="0 0 16 16" onClick={() => remove(name)}>
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </div>}

                                            </Space>
                                        ))}
                                    </Fragment>
                                )}
                            </Form.List>
                        </Form.Item>
                    )}

                    <div className="form-subtitle">外观设置</div>

                    <Form.Item
                        name="shareTitle"
                        label="分享标题"
                        rules={[
                            { required: true, message: '分享标题不能为空' }
                        ]}
                        extra={<>
                            <Text type="warning">如须在分享标题中体现专家姓名称谓，请将光标置于上方输入框合适的位置，点击</Text>
                            <Button type="link" size="small" onClick={insertNameTitleToShareTitle}>插入姓名称谓</Button>
                        </>}
                    >
                        <Input ref={inputShareTitleRef} value={shareTitle} onChange={changeShareTitle} showCount />
                    </Form.Item>

                    <Form.Item
                        name="shareDesc"
                        label="分享描述"
                        rules={[
                            { required: true, message: '分享描述不能为空' }
                        ]}
                    >
                        <Input showCount />
                    </Form.Item>

                    <Form.Item
                        name="shareImage"
                        label="分享图"
                        className='image-box'
                        rules={[
                            { required: true, message: '' }
                        ]}
                        help="支持JPG/PNG格式，尺寸300&times;300px，文件大小不超过1MB"
                    >
                        {!!oneData.shareImage && (
                            <div className='invationImg'>
                                <Image width={200} src={getFileUrl(oneData.shareImage)} />
                            </div>
                        )}
                        <ImgCrop
                            aspect={1 / 1}
                            minZoom={0.1}
                            maxZoom={2}
                            quality={1}
                            beforeCrop={async (file) => {
                                const res = await beforeCropCheck(file, true);
                                return res;
                            }}
                        >
                            <Upload beforeUpload={(file) => checkout(file, 3, 300, 300)} {...shareImageFile} maxCount={1}>
                                <Button icon={<UploadOutlined />}>{!!oneData.shareImage ? '更改图片' : '上传图片'}</Button>
                            </Upload>
                        </ImgCrop>
                    </Form.Item>

                    <Form.Item
                        name="coverForList"
                        label="列表封面"
                        className='image-box'
                        help="用于列表展示，支持JPG/PNG格式，建议尺寸 1500*844px（不小于 750*422px，比例16:9），文件大小不超过2MB"
                        rules={[
                            { required: true, message: '' }
                        ]}
                    >
                        {!!oneData.coverForList && (
                            <div className='invationImg'><Image width={200} src={getFileUrl(oneData.coverForList)} /></div>
                        )}
                        <Upload beforeUpload={(file) => checkout(file, 2, 1500, 844)} {...coverForListFile} maxCount={1}>
                            <Button icon={<UploadOutlined />}>{!!oneData.coverForList ? '更改图片' : '上传图片'}</Button>
                        </Upload>
                    </Form.Item>

                    {/* {invitationType === 1 && (
                        <Form.Item
                            name="h5Cover"
                            label="H5封面图"
                            className='image-box'
                            rules={[
                                { required: true, message: '' }
                            ]}
                            help="支持JPG/PNG格式，尺寸750&times;1500px（版心700&times;1000px，其顶部距图片顶部50px），文件大小不超过1MB">
                            {!!oneData.h5CoverUrl && (
                                <div className='invationImg'><Image width={200} src={getFileUrl(oneData.h5CoverUrl)}/></div>
                            )}
                            <Upload beforeUpload={(file) => checkout(file, 1, 750, 1500)} {...h5CoverFile} maxCount={1}>
                                <Button icon={<UploadOutlined/>}>{!!oneData.h5CoverUrl ? '更改图片' : '上传图片'}</Button>
                            </Upload>
                        </Form.Item>
                    )} */}

                    <Form.Item
                        name="backgroundImage"
                        label="邀请函背景"
                        className='image-box'
                        help="支持JPG/PNG格式，尺寸750&times;900px，文件大小不超过1MB">
                        {!!oneData.backgroundImage && (
                            <div className='invationImg'>
                                <Image width={200} src={getFileUrl(oneData.backgroundImage)} />
                                <span onClick={closeImg} className="close-img"><CloseOutlined /></span>
                            </div>
                        )}

                        <Upload beforeUpload={(file) => checkout(file, 1, 750, 900)} {...backgroundImageFile} maxCount={1}>
                            <Button icon={<UploadOutlined />}>{!!oneData.backgroundImage ? '更改图片' : '上传图片'}</Button>
                        </Upload>
                    </Form.Item>

                    <div className="form-subtitle">导出邀请话术设置</div>
                    <div className="form-subtitle-desc">
                        <div className="form-subtitle-desc-item">1. 嘉宾姓名，系统会默认填充，示例：尊敬的 “嘉宾姓名”“邀请称呼”。</div>
                        <div className="form-subtitle-desc-item">2. 请在“管理邀请用户”模块操作导出邀请码，如果设置了邀请话术，则会按邀请话术内容导出。</div>
                    </div>
                    <Form.Item
                        name="invitationDesc"
                        label="邀请话术"
                        extra={<>
                            <Text type="warning">如须在邀请话术中体现邀请链接，请将光标置于上方输入框合适的位置，点击</Text>
                            <Button type="link" size="small" onClick={handleInsert}>插入邀请链接</Button>
                        </>}
                    >
                        <Input.TextArea
                            ref={textAreaRef}
                            placeholder="示例：诚邀您参与本大会，我们为您安排了部分任务，点击 $邀请链接$ 进行确认，会务联系人 黄先生 18612015770"
                            autoSize={{ minRows: 5 }}
                            maxLength={500}
                            value={textAreaValue}
                            showCount
                            onChange={handleTextAreaChange}
                        />
                    </Form.Item>


                    <Form.Item
                        name='btnarea'
                        {...pageFormItemWithoutLabelGridLayout}
                        style={{ marginTop: 24, marginBottom: 0 }}
                    >
                        <Space size="large">
                            <Button type="primary" size="large" htmlType="submit">提交</Button>
                            <Button type="default" size="large" onClick={onReset}>取消</Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
        </Content>

    </Fragment>
}
