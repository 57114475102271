import React, { Fragment } from 'react';
import { Input, Button, Table, Modal, message } from 'antd';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
const { TextArea } = Input;

const Addwhite = ({ seeOpen, setSeeOpen,actionData}) => {
    const location=useLocation()
   const navigate=useNavigate()

    const onCancel = () => {
        setSeeOpen(false)
    }

    const onOk =()=>{
        setSeeOpen(false)
    }

    const onAnswer =(row) =>{
        navigate(`/wj/surveys/${location.state.id}/answer`,{state:{...location.state,answerId:row.id,surveyId:row.surveyId}})
    }
 const columns=[
    {
        title:'提交时间',
        dataIndex:'submittedAt'
    },
    {
        title: '操作',
        key: 'action',
        width:80,
        render: (_, row) => (
            <Fragment>
                <Button type="link" className='setting-button' onClick={()=>onAnswer(row)}  disabled={row.type === 1}>查看</Button>
            </Fragment>
        ),
    },
 ]

    return (
        <Fragment>
            <Modal
                open={seeOpen}
                title='作答记录'
                onCancel={onCancel}
                onOk={onOk}
            >
              <Table
              columns={columns}
              dataSource={actionData}
              scroll={{
                y: 240,
              }}
              pagination={false} 
              >

              </Table>
            </Modal>
        </Fragment>
    );
}

export default Addwhite;
