import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Layout, Tooltip, Space, Tag } from 'antd';
import { QuestionCircleFilled, LeftOutlined } from '@ant-design/icons';
import { todayDateString } from '@/utils/utils';
import { Link, useLocation } from 'react-router-dom';
import BrowserChart from './BrowserChart';
import DeviceChart from './DeviceChart';
import StatisticsChart from './StatisticsChart';
import ViewArea from './ViewArea';
import { get } from '@/core/request';
import './DataOverview.less';

const { Content } = Layout;

export default function DataOverview() {

    const { state } = useLocation();

    const [allData, setAllData] = useState({
        liveData: null, keyData: null, statisticChartData: null, areaData: null, deviceData: null, browserData: null
    });

    // 直播信息  关键数据  统计图表  观看区域  终端设备  浏览器占比
    const { liveData, keyData, statisticChartData, areaData, deviceData, browserData } = allData;

    // 渲染table数据
    useEffect(() => {
        let date = new Date();
        let dateString = todayDateString(date);
        get(`/dms/live/data/overview`, {
            liveId: state.liveId,
            start_time: dateString,
            end_time: dateString
        }).then((res) => {
            if (res.status_code === 200) {
                const { live_date: { liveVo }, key_date, statistical_chart_date: { live_broadcast }, area_date: { area_date }, device_date: { device_date }, browser_date: { browser_date } } = res.data;
                setAllData({
                    liveData: liveVo,
                    keyData: key_date,
                    statisticChartData: live_broadcast,
                    areaData: area_date,
                    deviceData: device_date,
                    browserData: browser_date
                })
            }
        });
    }, [state.liveId]);

    return (
        <Fragment>
            <div className="page-title"><Link to="/dms/lives" className="back"><LeftOutlined /></Link>数据报告<span className="page-title-tip">{state.name} [#{state.liveId}]</span></div>

            <Content className="main-content-box">

                <div className="main-content-body" style={{ paddingBottom: 0 }}>
                    {
                        liveData && <Space>
                            <img src={liveData.coverUrl} alt="直播封面图" className="data-overview-img" />
                            <div>
                                <Tag style={{ marginBottom: '5px' }}>{liveData.status}</Tag>
                                <p style={{ marginBottom: '5px' }}>{liveData.name}</p>
                                <span>直播时间：{liveData.startTime}</span>
                            </div>
                        </Space>
                    }

                </div>

                <div className="main-content-body">

                    {
                        keyData && <div className="core-data">
                            <h2>关键数据</h2>
                            <Row gutter={24} className="core-data-all">
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">直播总时长
                                        <Tooltip placement="right" title="筛选条件内创建的活动总数，删除活动不影响已统计的历史数据">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.play_duration_sec}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">最高并发（方）
                                        <Tooltip placement="right" title="观看直播的连接数，即1个人同时打开3个观看页计作3个并发，筛选条件内显示当前活动的最高并发">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.maxOnlines}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">观看人数
                                        <Tooltip placement="right" title="统计进入观看页面的观看人数，用户真实观看了视频，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchNumber}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">观看次数
                                        <Tooltip placement="right" title="统计进入观看页面的观看次数，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchTimes}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">人均观看次数
                                        <Tooltip placement="right" title="人均观看次数 = 观看次数 / 观看人数，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchTimesAvg}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">观看时长（分）
                                        <Tooltip placement="right" title="统计进入观看页面的观看次数，筛选条件内将活动数据进行相加，数据不去重">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchDuration}</p>
                                </Col>
                                <Col span={6} className="core-data-part">
                                    <p className="core-data-title">人均 观看时长（分）
                                        <Tooltip placement="right" title="筛选条件内的观看平均时长，将活动数据进行相加，数据不去重。人均观看时长 = 观看时长 / 观看人数">
                                            <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                        </Tooltip></p>
                                    <p className="tendency-num">{keyData.watchDurationAvg}</p>
                                </Col>
                            </Row>
                        </div>
                    }

                    {
                        statisticChartData && statisticChartData.length ? <div className="statistics-chart">
                            <h2>统计图表</h2>
                            <p className="core-data-title echarts-p">观看人数趋势
                                <Tooltip placement="right" title="筛选条件内，观看人数随时间变化的趋势图">
                                    <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                </Tooltip></p>
                            <StatisticsChart statisticChartData={statisticChartData}></StatisticsChart>
                        </div> : <Fragment></Fragment>
                    }

                    {
                        areaData && areaData.list.length ? <div className="view-area">
                            <h2>观看区域TOP10占比<Tooltip placement="right" title="统计观看地域TOP10占比情况">
                                <span className="data-hover-icon"><QuestionCircleFilled /></span>
                            </Tooltip></h2>
                            <ViewArea areaData={areaData}></ViewArea>
                        </div> : <Fragment></Fragment>
                    }

                    <div className="view-area">
                        <Row>
                            {
                                deviceData && deviceData.length ? <Col span={10}>
                                    <h2>终端设备占比<Tooltip placement="right" title="统计观看终端占比情况">
                                        <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                    </Tooltip></h2>
                                    <DeviceChart deviceData={deviceData}></DeviceChart>
                                </Col> : <Fragment></Fragment>
                            }

                            {
                                browserData && browserData.length ? <Col span={14}><h2>浏览器占比<Tooltip placement="right" title="统计观看浏览器占比情况">
                                    <span className="data-hover-icon"><QuestionCircleFilled /></span>
                                </Tooltip></h2>
                                    <BrowserChart browserData={browserData}></BrowserChart>
                                </Col> : <Fragment></Fragment>
                            }
                        </Row>

                    </div>


                </div>
            </Content>

        </Fragment>
    )
}
