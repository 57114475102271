import React, {Fragment, useState, useCallback, useEffect,useRef} from "react";
import {Button, Layout, Table, Modal, Space, message, Row, Form, Upload, Select, Input, InputNumber,} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {fullPagerConfig} from "@/utils/property";
import {get, del, post, put} from "@/core/request";
import HealthclassTrialAcademyQueryFrom from "./TrialOrganizationsQueryFrom";
import {color} from "echarts/core";
import './TrialOrganizations.less';

const {Content} = Layout;
const {TextArea} = Input
export default function TrialOrganizations() {

    // 渲染table数据的state
    const [tabledata, setTableData] = useState({});
    const [filterObj, setFilterObj] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibles, setIsModalVisibles] = useState(false);
    const [isModalVisibless, setIsModalVisibless] = useState(false);
    const [rejectTemplates, setRejectTemplates] = useState({});
    const [thisTime, setThisTime] = useState(null)
    const [thisDay, setthisDay] = useState(null)
    const [thisObj, setThisObj] = useState({})
    const [inputValue, setInputValue] = useState(null);
    let navigate = useNavigate();
    const {Option} = Select;
    // 分页设置
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);

    // 改变一页有多少数据
    const changePageSize = useCallback((pageSize) => {
        setPageSize(pageSize);
    }, []);

    // 改变第几页
    const changeCurrPage = useCallback((page) => {
        setCurrPage(page);
    }, []);

    //分页数据
    const paginationProps = {
        ...fullPagerConfig,
        pageSize,
        size: "small",
        total: tabledata.totalCount,
        onShowSizeChange: (current, pageSize) => changePageSize(pageSize),
        onChange: (page) => {
            changeCurrPage(page);
        },
        current: currPage,
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };
    const onInput = (value) => {
        setthisDay(value)
        var now = new Date()
        var thisObjnow = new Date(thisObj.tryEndedAt);
        if (now.getTime()>thisObjnow.getTime()){
            let todayStr = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
            let targetDay = new Date(now.getTime() + value * 24 * 3600 * 1000);
            let tarDayStr = targetDay.getFullYear() + "-" + (targetDay.getMonth() + 1) + "-" + targetDay.getDate();
            setThisTime(tarDayStr)
        }else {
            let todayStr = thisObjnow.getFullYear() + "-" + (thisObjnow.getMonth() + 1) + "-" + thisObjnow.getDate();
            let targetDay = new Date(thisObjnow.getTime() + value * 24 * 3600 * 1000);
            let tarDayStr = targetDay.getFullYear() + "-" + (targetDay.getMonth() + 1) + "-" + targetDay.getDate();
            setThisTime(tarDayStr)
        }

    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleCancels = () => {
        setIsModalVisibles(false);
    };
    const handleChange = (value) => {
        setInputValue(value)
    };
    const handleOk = () => {
        if (thisObj==null){
            message.warning("参数丢失！！")
            return
        }
        if (thisDay==null){
            message.warning("请填写延期天数！！")
            return
        }
        put('/healthclass/boss/organizations/updateDate', {
            id: thisObj.id,
            code: thisObj.code,
            totalTrialDays: thisDay
        }).then((res) => {
            if (res.status_code === 200) {
                message.success(res.message);
                navigate('/healthclass/TrialOrganizations');
            }
            setIsModalVisible(false);
            setThisObj("")
            getData()
        }
    );
    }
    const updateStatus = (type, row) => {

        if (type == 1) {
            return () => {
                navigate(`/healthclass/TrialOrganizationsInfo`, {state: row});
            }
        } else if (type == 2) {
            setThisObj(row)
            setIsModalVisible(true)
        }else if (type==3){
            return () => {
                navigate(`/healthclass/TrialOrgContractsSave`, {state: row});
            }
        }else if (type==4){
            setThisObj(row)
            checkDown(row)
        }else if (type==5){
            Modal.confirm({
                title: '提示',
                content: '停用后学苑管理员将不能登录，是否确认关停？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    put('/healthclass/boss/organizations/updateState', {
                        id: row.id,
                        state: 2
                    }).then((res) => {
                            if (res.status_code === 200) {
                                message.success(res.message);
                                navigate('/healthclass/TrialOrganizations');
                            }
                            getData()
                        }
                    );
                }
            });
        }else if (type==6){
            put('/healthclass/boss/organizations/updateState', {
                id: row.id,
                state: 1
            }).then((res) => {
                    if (res.status_code === 200) {
                        message.success(res.message);
                        navigate('/healthclass/TrialOrganizations');
                    }
                    getData()
                }
            );
        }
    }
    const afterClose=()=>{
        setInputValue(null)
    }
    const afterClosetTime=()=>{
        setthisDay(null)
        setThisTime(null)
    }
    const handleOks = () => {
        if (thisObj==null){
            message.warning("参数丢失！！")
            return
        }
        if (inputValue==null){
            message.warning('请输入取消理由！');
            return
        }
        put('/healthclass/boss/organizations/cancelRequest', {
            id: thisObj.id,
            code: thisObj.code,
            cancelReason: inputValue
        }).then((res) => {
                if (res.status_code === 200) {
                    message.success(res.message);
                    navigate('/healthclass/TrialOrganizations');
                }
                setThisObj("")
                setIsModalVisibles(false);
                getData()
            }
        );
    }

    const {
        code,
        name,
        belongsUnitName,
        adminName,
        state
    } = filterObj;

    const getData = () => {
        get(`/healthclass/boss/organizations/trialList`, {
            page: currPage,
            pageSize: pageSize,
            code,
            name,
            belongsUnitName,
            adminName,
            state
        }).then((res) => {
            if (res.status_code === 200) {
                setTableData(res.data);
            }
        });
    };
    const checkDown = (row) => {
        get(`/healthclass/boss/rejectTemplates/list`, {}).then((res) => {
            if (res.status_code === 200) {
                setRejectTemplates(res)
            }
        });
        setIsModalVisibles(true)
    }
    const handleFilterFinish = (value) => {
        setFilterObj(value);
        const {
            code,
            name,
            belongsUnitName,
            adminName,
            state
        } = value;
        get(`/healthclass/boss/organizations/trialList`, {
            code,
            name,
            belongsUnitName,
            adminName,
            state,
            page: 1,
            pageSize: pageSize,
        }).then((res) => {
            if (res.status_code === 200) {
                setTableData(res.data);
                setCurrPage(1);
            }
        });
    }

    // 渲染table数据
    useEffect(() => {
        getData();
    }, [
        pageSize,
        currPage
    ]);
    const columns = [
        {title: "学苑ID", dataIndex: "code"},
        {title: "学苑名称", dataIndex: "name"},
        {title: "管理员", dataIndex: "adminName"},
        {title: "管理员手机号", dataIndex: "phone"},
        {title: "所属主体", dataIndex: "belongsUnitName"},
        {title: "申请时间", dataIndex: "createdAt"},
        {title: "最后登录时间", dataIndex: "lastLoggedinAt"},
        {
            title: "到期剩余时间", dataIndex: "day", render: (day, row) => (
                <Fragment>
                    {day <= 7 && day > 0 && (<span className="span-red">{day}天</span>)}
                    {day == 0 && (<span className="span-red">{day}天</span>)}
                    {day < 0 && (<span className="span-red">已过期{Math.abs(day)}天</span>)}
                    {day > 7 && (<span className="span-black">{day}天</span>)}
                </Fragment>),
        },
        {
            title: "状态", dataIndex: "state", render: (state, row) => (
                <Fragment>
                    {state === 1 && (<span>正常</span>)}
                    {state === 2 && (<span>已停用</span>)}
                    {state === 3 && (<span>已取消</span>)}
                    {state === 4 && (<span>已过期</span>)}
                </Fragment>),
        },
        {
            title: "操作", dataIndex: "state", render: (state, row) => (
                <Fragment>
                    {state === 1 && (
                        <div>
                            <span className="operate-style" onClick={updateStatus(1, row)}>管理</span>
                            <span className="operate-style" onClick={() => updateStatus(2, row)}>延期</span>
                            <span className="operate-style" onClick={updateStatus(3, row)}>转正式</span>
                            <span className="operate-style" onClick={()=>updateStatus(4, row)}>取消申请</span>
                            <span className="operate-style" onClick={()=>updateStatus(5, row)}>停用</span>
                        </div>
                    )}
                    {state === 2 && (
                        <div>
                            <span className="operate-style" onClick={updateStatus(1, row)}>管理</span>
                            <span className="operate-style" onClick={()=>updateStatus(6, row)}>启用</span>
                        </div>
                    )}
                    {state === 4 && (
                        <div>
                            <span className="operate-style" onClick={updateStatus(1, row)}>管理</span>
                            <span className="operate-style" onClick={() =>updateStatus(2, row)}>延期</span>
                            <span className="operate-style" onClick={updateStatus(3, row)}>转正式</span>
                            <span className="operate-style" onClick={()=>updateStatus(4, row)}>取消申请</span>
                            <span className="operate-style" onClick={()=>updateStatus(5, row)}>停用</span>
                        </div>
                    )}
                </Fragment>)
        }]
    return (
        <Fragment>
            <div className="page-title">试用学苑管理</div>
            <Content className="main-content-box">
                <div className="main-content-body">
                    <div className="filter-form-box">
                        <HealthclassTrialAcademyQueryFrom handleFilterFinish={handleFilterFinish}/>
                    </div>
                    <Table
                        className="trialOrg-table"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={tabledata.list}
                        pagination={paginationProps}
                        rowKey={(row) => row.id}
                        scroll={{x: 'max-content'}}
                    />
                </div>
                <Modal title="延期设置" visible={isModalVisible} destroyOnClose={true} onOk={() => {handleOk()}} onCancel={handleCancel} afterClose={afterClosetTime}>
                    <div>
                        <span className="postpone">试用延期天数：</span>
                        <InputNumber style={{width: 300}} min={1} max={90} onChange={onInput}/>
                    </div>
                    <div className="postponeDiv">
                        <span className="postpone">试用到期时间：</span>
                        <Input style={{width: 300}} type="text" value={thisTime}/>
                    </div>
                </Modal>
                <Modal title="取消申请" visible={isModalVisibles} destroyOnClose={true} onOk={() => {handleOks()}} onCancel={handleCancels} afterClose={afterClose}>
                    <div className="select">
                        <span>驳回理由（必填，50字以内）</span>
                        <Select defaultValue="选择驳回原因模板" style={{width: 260}} onChange={handleChange}>
                            {rejectTemplates.data && rejectTemplates.data.map((setReject)=>(<Option key={setReject.id} value={setReject.content}>{setReject.content}</Option>))}
                        </Select>
                    </div>
                    <TextArea type="text" style={{width: 450, height: 200}}
                            onChange={(e) => setInputValue(e.target.value)}
                            onPressEnter={() => setInputValue('')}
                            value={inputValue}
                            maxLength={50}
                            placeholder="请输入取消理由"
                    ></TextArea>
                </Modal>
            </Content>
        </Fragment>
    )

}
