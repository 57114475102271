import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Form,
    Input,
    Layout,
    Space,
    Button,
    message,
    Upload,
    Image,
    Radio,
    DatePicker
} from 'antd';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { fetchMedProject, createMedProject, editMedProject } from '@services/LegacyMedProject.service';
import { pageFormItemLayout, pageFormItemWithoutLabelGridLayout } from '@utils/property';
import { fileApi } from "@services/Conference";
import config from "@config";

const { Content } = Layout;

export default function LegacyMedProject() {
    const navigate = useNavigate();
    const params = useParams();

    const option = { headers: { "Content-Type": "multipart/form-data" } };

    const id = params?.id || 0;

    const [form] = Form.useForm();

    const [project, setProject] = useState(null);
    const [shareImageList, setShareImageList] = useState([]);
    const [sharePosterList, setSharePosterList] = useState([]);

    const getProject = async id => {
        const res = await fetchMedProject(id);
        if (res.status_code === 200) {
            console.log(res.data);
            const project = res.data;
            setProject(project);
            form.setFieldsValue({
                name: project?.name,
                slug: project?.slug,
            });
        }
    };

    const checkout = (file, size, initWidth, initHeight) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        // 判断类型
        if (!isJpgOrPng) {
            message.error("只能上传 jpg/png/jpeg 格式");
            return null;
        }
        // 判断大小
        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            message.error("图像必须小于" + size + "MB!");
            return null;
        }
        return isJpgOrPng && isLt2M;
    };

    // 上传图片
    const uploadImageFile = (info, type) => {
        const formData = new FormData();
        formData.append("file", info.file);
        formData.append('type', 14);
        fileApi(formData, option).then((res) => {
            if (!res) {
                message.error("上传文件失败！！");
                return false;
            }
            if (res.status_code === 200) {
                if (type === 1) {
                    setShareImageList([getFileUrl(res.data)]);
                    form.setFieldValue("shareImageUrl", getFileUrl(res.data));
                } else {
                    setSharePosterList([getFileUrl(res.data)]);
                    form.setFieldValue("sharePosterUrl", getFileUrl(res.data));
                }
            }
        });
    };

    // 获取上传文件 - 前缀
    const getFileUrl = (url) => {
        if (url && url.includes('https')) {
            return url;
        }
        if (url && !url.includes('https')) {
            return config.OSS_PREFIX + url;
        }
        return null;
    };

    useEffect(() => {
        if (!!id) {
            getProject(id);
        }
    }, []);

    const onFinish = async values => {
        console.log(values);
        try {
            let data = { ...values };

            let res = null;
            if (!id) {
                res = await createMedProject(data);
            } else {
                data = { ...data, id };
                res = await editMedProject(data);
            }
            if (res.status_code === 200) {
                message.success(!id ? '创建项目成功' : '编辑项目成功');
                navigate('/medproject/projects');
            }
        } catch (e) {
            //
        }
    };

    const onCancel = () => navigate('/medproject/projects');

    return (
        <>
            <div className="page-title">
                <Link to={`/medproject/projects`} className="back"><LeftOutlined /></Link>
                <span className="current-title">{!id ? "新增医项目" : "编辑医项目"}</span>
                <span className="current-subtitle">{project?.name}</span>
            </div>

            <Content className="main-content-box">
                {/* <div className="main-content-title">项目信息</div> */}

                <div className="main-content-body">

                    <Form
                        {...pageFormItemLayout}
                        form={form}
                        onFinish={onFinish}
                    >
                        <p><h1>基本信息</h1></p>
                        <Form.Item
                            name="name"
                            label="项目名称"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="请输入项目名称" maxLength={50} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="name"
                            label="项目简介"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="请输入项目简介" maxLength={50} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label="项目时间"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Space direction="vertical">
                                <Radio.Group>
                                    <Radio value="a">短期项目</Radio>
                                    <Radio value="b">长期项目</Radio>
                                </Radio.Group>
                                <DatePicker.RangePicker showTime />
                            </Space>
                        </Form.Item>

                        <Form.Item
                            name="logo"
                            label="项目logo"
                            rules={[
                                { required: true, message: '请上传项目logo' }
                            ]}
                            extra="支持JPG/PNG格式，尺寸300&times;300px，大小不超过2M"
                            className="share-image"
                        >
                            {shareImageList.length > 0 && <Image width={200} src={shareImageList[0]} className="share-image-area"/>}

                            <div>
                                <Upload
                                    beforeUpload={(file) => checkout(file, 2, 300, 300)}
                                    fileList={shareImageList}
                                    customRequest={(e) => uploadImageFile(e, 1)}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {shareImageList.length > 0 ? '更改图片' : '上传图片'}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="cover"
                            label="封面图"
                            rules={[
                                { required: true, message: '请上传封面图' }
                            ]}
                            extra="支持JPG/PNG格式，尺寸300&times;300px，大小不超过2M"
                            className="share-image"
                        >
                            {sharePosterList.length > 0 && <Image width={200} src={sharePosterList[0]} className="share-image-area"/>}

                            <div>
                                <Upload
                                    beforeUpload={(file) => checkout(file, 2, 300, 300)}
                                    fileList={sharePosterList}
                                    customRequest={(e) => uploadImageFile(e, 2)}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {sharePosterList.length > 0 ? '更改图片' : '上传图片'}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>

                        <p><h1>申请加入/报名设置</h1></p>
                        <Form.Item
                            name="name"
                            label="报名时间设置"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="请输入项目名称" maxLength={100} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="name"
                            label="报名身份"
                            rules={[
                                { required: true, message: '' }
                            ]}
                        >
                            <Input placeholder="输入项目名称" maxLength={100} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="introduce"
                            label="权益介绍"
                            rules={[
                                { required: true, message: '请输入权益介绍' }
                            ]}
                        >
                            <Input.TextArea placeholder="请输入权益介绍" showCount maxLength={2000} rows={6} />
                        </Form.Item>

                        <p><h1>分享设置</h1></p>
                        <Form.Item
                            name="shareTitle"
                            label="微信分享标题"
                            rules={[{ required: true, message: '请输入微信分享标题' }]}
                        >
                            <Input placeholder="请输入微信分享标题" maxLength={20} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="shareDesc"
                            label="微信分享描述"
                            rules={[{ required: true, message: '请输入微信分享描述' }]}
                        >
                            <Input placeholder="请输入微信分享描述" maxLength={20} showCount allowClear/>
                        </Form.Item>

                        <Form.Item
                            name="shareImageUrl"
                            label="微信分享图"
                            rules={[
                                { required: true, message: '请上传微信分享图' }
                            ]}
                            extra="支持JPG/PNG格式，尺寸300&times;300px，大小不超过2M"
                            className="share-image"
                        >
                            {shareImageList.length > 0 && <Image width={200} src={shareImageList[0]} className="share-image-area"/>}

                            <div>
                                <Upload
                                    beforeUpload={(file) => checkout(file, 2, 300, 300)}
                                    fileList={shareImageList}
                                    customRequest={(e) => uploadImageFile(e, 1)}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {shareImageList.length > 0 ? '更改图片' : '上传图片'}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="sharePosterUrl"
                            label="详情海报"
                            rules={[
                                { required: true, message: '请上传详情海报' }
                            ]}
                            extra="支持JPG/PNG格式，尺寸300&times;300px，大小不超过2M"
                            className="share-image"
                        >
                            {sharePosterList.length > 0 && <Image width={200} src={sharePosterList[0]} className="share-image-area"/>}

                            <div>
                                <Upload
                                    beforeUpload={(file) => checkout(file, 2, 300, 300)}
                                    fileList={sharePosterList}
                                    customRequest={(e) => uploadImageFile(e, 2)}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {sharePosterList.length > 0 ? '更改图片' : '上传图片'}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>

                        <Form.Item
                            {...pageFormItemWithoutLabelGridLayout}
                            style={{ marginTop: 24, marginBottom: 0 }}
                        >
                            <Space size="large">
                                <Button type="primary" size="large" htmlType="submit">提交</Button>
                                <Button type="default" size="large" onClick={onCancel}>取消</Button>
                            </Space>
                        </Form.Item>

                    </Form>
                </div>
            </Content>
        </>
    );
}
